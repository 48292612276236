import { isEmpty, pick } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_COMPARISON_VALUE_NUMBER } from '../../default/number.default';
import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE,
  DEFAULT_KEY_NAME_FILE_PROPERTIES,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_SALES_ORDER_PRODUCT_API_REF,
  DEFAULT_KEY_NAME_UUID_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';
import arrHelp from '../../helpers/array.helpers';
import formatHelp from '../../helpers/format.helpers';
import formulaHelpers from '../../helpers/formula.helpers';
import objHelper from '../../helpers/object.helper';
import {
  DEFAULT_CALCULATION_NUMBER,
  DEFAULT_EMPTY_CALCULATIONS_NUMBER,
} from '../default/number.default';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import {
  allowKeysProductSalesDeliveryData,
  allowKeysSalesDeliveryData,
  initialProductSalesDeliveryData,
  initialProductSalesDeliveryValidation,
  initialSalesDeliveryData,
  initialSalesDeliveryValidation,
  productSalesDeliveryCalculationNumberKeyNames,
  salesDeliveryCalculationNumberKeyNames,
} from './initial-data-sales-delivery';

/**
 *
 * @param { number } amount amount data and validation that want to generated
 * @param { string } customDataKey name key of return data
 * @param { string } customValidationKey name key return validation
 * @returns
 *      generate data and validation for sales delivery on menu add
 */
export const generateDataNValidationAddSalesDelivery = (
  amount = 1,
  customDataKey = 'salesDeliveryData',
  customValidationKey = 'salesDeliveryValidation',
) => {
  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    initialSalesDeliveryData,
    initialSalesDeliveryValidation,
    customDataKey,
    customValidationKey,
  );
};

/**
 *
 * @param { number } amount amount data and validation that want to generated
 * @param { object } companyUserData        data user company
 * @param { string } customDataKey name key of return data
 * @param { string } customValidationKey name key return validation
 * @returns
 *      generate data and validation for sales delivery on menu add
 */
// export const generateDataNValidationAddSalesDeliveryV2 = ({
//     amount = 1,
//     companyUserData={},
//     customDataKey = 'salesDeliveryData',
//     customValidationKey = 'salesDeliveryValidation'
// }) => {
//     let accounting_period = null

//     if (!isEmpty(companyUserData)) {
//         accounting_period = companyUserData.accounting_periode
//     }

//     // overriding on accounting period
//     const wrapperInitialSalesDeliveryData = (id) => {
//         return {
//             ...initialSalesDeliveryData(id),
//             accounting_period,
//         }
//     }

//     return objHelper.creatorGenerateDataNValidationV2(
//         amount,
//         wrapperInitialSalesDeliveryData,
//         initialSalesDeliveryValidation,
//         customDataKey,
//         customValidationKey,
//     )
// }

/**
 *
 * @param { number } amount amount data and validation that want to generated
 * @param { string } customDataKey name key of return data
 * @param { string } customValidationKey name key return validation
 * @returns
 *      enhacement version generate data and validation for sales delivery on menu add
 */
export const generateDataNValidationAddSalesDeliveryV2 = ({
  amount = 1,
  companyUserData = {},
  customDataKey = 'salesDeliveryData',
  customValidationKey = 'salesDeliveryValidation',
}) => {
  let accounting_period = null;

  if (!isEmpty(companyUserData)) {
    accounting_period = companyUserData.accounting_periode;
  }

  // overriding on accounting period
  const wrapperInitialSalesDeliveryData = (id) => {
    return {
      ...initialSalesDeliveryData(id),
      accounting_period,
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
    };
  };

  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    wrapperInitialSalesDeliveryData,
    initialSalesDeliveryValidation,
    customDataKey,
    customValidationKey,
  );
};

/**
 *
 * @param { number } amount amount data and validation that want to generated
 * @param { string } customDataKey name key of return data
 * @param { string } customValidationKey name key return validation
 * @returns
 *      generate data and validation for product of sales delivery on menu add
 */
export const generateDataNValidationAddProductSalesDelivery = (
  amount = 2,
  customDataKey = 'productSalesDeliveryData',
  customValidationKey = 'productSalesDeliveryValidation',
) => {
  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    initialProductSalesDeliveryData,
    initialProductSalesDeliveryValidation,
    customDataKey,
    customValidationKey,
  );
};

/**
 *
 * @param { number } amount amount data and validation that want to generated
 * @param { string } customDataKey name key of return data
 * @param { string } customValidationKey name key return validation
 * @returns
 *      ehancement version generate data and validation for product of sales delivery on menu add
 */
export const generateDataNValidationAddProductSalesDeliveryV2 = ({
  amount = 2,
  customDataKey = 'productSalesDeliveryData',
  customValidationKey = 'productSalesDeliveryValidation',
}) => {
  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    initialProductSalesDeliveryData,
    initialProductSalesDeliveryValidation,
    customDataKey,
    customValidationKey,
  );
};

/**
 *
 * @param { object } salesOrderDetails      data from sales order details
 * @param { string } customDataKey          return key of data sales invoice
 * @param { string } customValidationKey    return key of validation sales invoice
 * @returns
 *      generated data and validation for sales delivery from sales order details
 */
export const generateDataNValidationSalesDeliveryFromSalesOrderDetails = (
  salesOrderDetails,
  customDataKey = 'salesDeliveryData',
  customValidationKey = 'salesDeliveryValidation',
) => {
  if (isEmpty(salesOrderDetails)) {
    return generateDataNValidationAddSalesDelivery(1, customDataKey, customValidationKey);
  }

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const {
    sales_order_id,
    sales_order_no,
    contact_code,
    customer_id: contact_id,
    customer_name: contact_name,
    customer_address,
  } = salesOrderDetails;

  // some variable is inherit from initial data, so this assign variable follow inherit from
  // for example on sales_order_id and sales_order_id_container, where assigned from sales_order_id and sales_order_no
  const salesDeliveryData = usedID.map((id) => {
    return {
      ...initialSalesDeliveryData(id),
      ...salesOrderDetails,
      customer_id_container: {
        contact_id,
        contact_code,
        contact_name,
        customer_address,
      },
      sales_order_id,
      sales_order_id_container: {
        sales_order_no,
      },
      expired_date: null,
      description: '',
    };
  });

  const salesDeliveryValidation = usedID.map((id) => {
    return initialSalesDeliveryValidation(id);
  });

  return {
    [customDataKey]: salesDeliveryData,
    [customValidationKey]: salesDeliveryValidation,
  };
};

/**
 *
 * @param { object } salesOrderDetails      data from sales order details
 * @param { string } customDataKey          return key of data sales invoice
 * @param { string } customValidationKey    return key of validation sales invoice
 * @returns
 *      enhancement version generated data and validation for sales delivery from sales order details
 */
export const generateDataNValidationSalesDeliveryFromSalesOrderDetailsV2 = ({
  salesOrderDetails,
  currentSalesDeliveryData = [],
  companyUserData = {},
  customDataKey = 'salesDeliveryData',
  customValidationKey = 'salesDeliveryValidation',
}) => {
  if (isEmpty(salesOrderDetails) && isEmpty(currentSalesDeliveryData)) {
    return generateDataNValidationAddSalesDelivery(1, customDataKey, customValidationKey);
  } else if (isEmpty(salesOrderDetails) && !isEmpty(currentSalesDeliveryData)) {
    const generatedDataNvalidationSalesDelivery = generateDataNValidationAddSalesDelivery(
      1,
      customDataKey,
      customValidationKey,
    );

    const { reference_number, reference_number_container } = currentSalesDeliveryData[0];

    const newSalesDeliveryData = [
      {
        ...generatedDataNvalidationSalesDelivery[customDataKey][0],
        reference_number,
        reference_number_container,
        customer_address: '',
      },
    ];

    return {
      [customDataKey]: newSalesDeliveryData,
      [customValidationKey]: generatedDataNvalidationSalesDelivery[customValidationKey],
    };
  }

  salesOrderDetails = objHelper.changeFormatValue(
    salesOrderDetails,
    salesDeliveryCalculationNumberKeyNames,
    [(value) => Number(value)],
  );

  let revertCurrentSalesDeliveryData = {};

  if (!isEmpty(currentSalesDeliveryData) && Array.isArray(currentSalesDeliveryData)) {
    const {
      reference_number,
      reference_number_container,
      accounting_period,
      uuid_attachment,
      attachment,
    } = currentSalesDeliveryData[0];

    revertCurrentSalesDeliveryData = {
      accounting_period,
      reference_number,
      reference_number_container,
      uuid_attachment,
      attachment,
    };
  }

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const {
    sales_order_id,
    sales_order_no,
    contact_code,
    customer_id: contact_id,
    customer_name: contact_name,
    customer_address,
    warehouse_id,
    warehouse_name,
    amount,
  } = salesOrderDetails;

  // some variable is inherit from initial data, so this assign variable follow inherit from
  // for example on sales_order_id and sales_order_id_container, where assigned from sales_order_id and sales_order_no
  const salesDeliveryData = usedID.map((id) => {
    return {
      ...initialSalesDeliveryData(id),
      ...salesOrderDetails,
      ...revertCurrentSalesDeliveryData,
      warehouse_id_container: {
        warehouse_id,
        warehouse_name,
      },
      amount_const: Number(amount) || DEFAULT_CALCULATION_NUMBER,
      sales_agent_id_container: {
        contact_id: salesOrderDetails.sales_agent_id || '',
        contact_code: salesOrderDetails.sales_agent_code || '',
        contact_name: salesOrderDetails.sales_agent_name || '',
      },
      accounting_period: isEmpty(companyUserData)
        ? null
        : companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE],
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
          documentData: salesOrderDetails,
        }),
      customer_id_container: {
        contact_id,
        contact_code,
        contact_name,
        customer_address,
      },
      sales_order_id,
      sales_order_id_container: {
        sales_order_no,
      },
      description: '',
    };
  });

  const salesDeliveryValidation = usedID.map((id) => {
    return initialSalesDeliveryValidation(id);
  });

  return {
    [customDataKey]: salesDeliveryData,
    [customValidationKey]: salesDeliveryValidation,
  };
};

/**
 *
 * @param { object } salesOrderDetails      data from sales delivery details
 * @param { string } customDataKey          return key of data sales delivery
 * @param { string } customValidationKey    return key of validation sales delivery
 * @returns
 *      generated data and validation for product in sales delivery from sales order details
 */
export const generateDataNValidationProductSalesDeliveryFromSalesOrderDetails = (
  salesOrderDetails,
  customDataKey = 'productSalesDeliveryData',
  customValidationKey = 'productSalesDeliveryValidation',
) => {
  if (isEmpty(salesOrderDetails)) {
    return generateDataNValidationAddProductSalesDelivery(2, customDataKey, customValidationKey);
  }

  // remove row when not existed product (quantity available is zero)
  const salesOrderProducts = arrHelp.filterExistedValueOnObjItem(
    salesOrderDetails.sales_order_product,
    ['quantity_available'],
    [],
    DEFAULT_EMPTY_CALCULATIONS_NUMBER,
  );

  const usedID = arrHelp.generateArrWithFunc(salesOrderProducts.length, uuidv4);

  const productSalesDeliveryData = usedID.map((id, index) => {
    const {
      product_id,
      product_name,
      product_code,
      quantity_available,
      description,
      product_unit,
      unit_destination,
      unit_origin,
      quantity,
      quantity_origin,
    } = salesOrderProducts[index];

    let quantity_available_const = formatHelp.reverseCurrencyFormatWithRegex(quantity_available);

    if (Number.isNaN(quantity_available_const)) {
      quantity_available_const = DEFAULT_CALCULATION_NUMBER;
    }

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    return {
      ...initialProductSalesDeliveryData(id),
      ...salesOrderProducts[index],
      product_description: description || '',
      product_id_container: {
        product_id,
        product_code,
        product_name,
        ...salesOrderProducts[index],
      },
      quantity: quantity_available,
      quantity_available: DEFAULT_CALCULATION_NUMBER,
      quantity_available_const,
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantity_available_const),
        value_comparison: quantityComparison,
      },
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      quantity_available_with_unit: `${Number(quantity_available_const)} ${
        unit_origin || product_unit
      }`,
    };
  });

  const productSalesDeliveryValidation = usedID.map((id) => {
    return initialProductSalesDeliveryValidation(id);
  });

  return {
    [customDataKey]: productSalesDeliveryData,
    [customValidationKey]: productSalesDeliveryValidation,
  };
};

/**
 *
 * @param { object } salesOrderDetails      data from sales delivery details
 * @param { string } customDataKey          return key of data sales delivery
 * @param { string } customValidationKey    return key of validation sales delivery
 * @returns
 *      generated data and validation for product in sales delivery from sales order details
 */
export const generateDataNValidationProductSalesDeliveryFromSalesOrderDetailsV2 = ({
  salesOrderDetails,
  customDataKey = 'productSalesDeliveryData',
  customValidationKey = 'productSalesDeliveryValidation',
}) => {
  if (isEmpty(salesOrderDetails)) {
    return generateDataNValidationAddProductSalesDelivery(2, customDataKey, customValidationKey);
  }

  // remove row when not existed product (quantity available is zero)
  const salesOrderProducts = arrHelp.filterExistedValueOnObjItem(
    salesOrderDetails[DEFAULT_KEY_NAME_SALES_ORDER_PRODUCT_API_REF],
    ['quantity_available'],
    [],
    DEFAULT_EMPTY_CALCULATIONS_NUMBER,
  );

  const usedID = arrHelp.generateArrWithFunc(salesOrderProducts.length, uuidv4);

  const productSalesDeliveryData = usedID.map((id, index) => {
    let selectedSalesOrderProduct = salesOrderProducts[index];

    if (!isEmpty(selectedSalesOrderProduct)) {
      selectedSalesOrderProduct = objHelper.changeFormatValue(
        selectedSalesOrderProduct,
        productSalesDeliveryCalculationNumberKeyNames,
        [(value) => Number(value)],
      );
    }

    const { product_id, product_name, product_code } = selectedSalesOrderProduct;

    return {
      ...initialProductSalesDeliveryData(id),
      ...salesOrderProducts[index],
      product_id_container: {
        ...selectedSalesOrderProduct,
        product_id,
        product_code,
        product_name,
      },
      ...formulaHelpers.parseProductAPI2display({
        product: salesOrderProducts[index],
      }),
    };
  });

  const productSalesDeliveryValidation = usedID.map((id) => {
    return initialProductSalesDeliveryValidation(id);
  });

  return {
    [customDataKey]: productSalesDeliveryData,
    [customValidationKey]: productSalesDeliveryValidation,
  };
};

/**
 *
 * @param { array } salesDeliveryData bunch data sales delivery that inputted by user on menu add
 * @param { array } productSalesDeliveryData data product of sales delivery that inputted by user on menu add
 * @returns
 *      generalize data sales delivery that inputted by user, follow requirement from API
 */
export const generalizeDataAddSalesDelivery = (salesDeliveryData, productSalesDeliveryData) => {
  if (Array.isArray(salesDeliveryData) && Array.isArray(productSalesDeliveryData)) {
    salesDeliveryData = salesDeliveryData.map((singleSalesDeliveryData) => {
      const { customer_id_container, delivery_date, attachment } = singleSalesDeliveryData;

      let customer_name = '';

      if (customer_id_container) {
        const { contact_name, customer_name: customer_name_gen } = customer_id_container;

        customer_name = contact_name || customer_name_gen;
      }

      const uuidAttachment =
        Array.isArray(attachment) && attachment.length
          ? attachment[0][DEFAULT_KEY_NAME_FILE_PROPERTIES][DEFAULT_KEY_NAME_UUID_API_REF] || ''
          : '';

      return objHelper.filteringExistedValue(
        pick(
          {
            ...singleSalesDeliveryData,
            [DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF]: uuidAttachment,
            customer_name,
            delivery_date: delivery_date ? moment(delivery_date).toISOString(true) : '',
          },
          allowKeysSalesDeliveryData,
        ),
        [],
        true,
      );
    });

    productSalesDeliveryData = productSalesDeliveryData.map((singleProductSalesDelivery) => {
      const { quantity_available_const, description, quantity } = singleProductSalesDelivery;

      return pick(
        {
          ...singleProductSalesDelivery,
          quantity_available: quantity_available_const,
          description: description || '',
          quantity: quantity ? Number(quantity) : 0,
        },
        allowKeysProductSalesDeliveryData,
      );
    });

    productSalesDeliveryData = arrHelp.filterExistedValueOnObjItem(
      productSalesDeliveryData,
      allowKeysProductSalesDeliveryData,
      [],
      DEFAULT_CALCULATION_NUMBER,
    );

    return {
      product: productSalesDeliveryData,
      ...salesDeliveryData[0],
    };
  }
};
