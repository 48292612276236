import { isEmpty } from 'lodash';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF,
  DEFAULT_KEY_NAME_FILE_PROPERTIES,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
  DEFAULT_KEY_NAME_UUID_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import arrHelp from '../../helpers/array.helpers';
import formatHelp from '../../helpers/format.helpers';
import objHelper from '../../helpers/object.helper';
import { DEFAULT_CALCULATION_NUMBER } from '../default/number.default';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import {
  allowKeysBodySalesPayment,
  allowKeysBodySalesPaymentOnInvoice,
  allowKeysInvoiceData,
  initialInvoiceData,
  initialInvoiceValidation,
  initialPaymentData,
  initialPaymentValidation,
  invoiceSalesPaymentCalculationNumberKeyNames,
  plainInvoiceDataWithoutID,
  plainInvoiceValidationWithoutID,
} from './initial-data-sales-payment';

/**
 *
 * @param { number } amount how many data that generate
 * @param { string } customDataKey name key of returning data
 * @param { string } customValidationKey name key of returning validation
 * @returns
 *      generated data and validation for adding sales payment
 *      on payment
 */
export const generateDataNValidationAddPaymentSalesPayment = (
  amount = 1,
  customDataKey = 'paymentData',
  customValidationKey = 'paymentValidation',
) => {
  return objHelper.creatorGenerateDataNValidation(
    amount,
    initialPaymentData,
    initialPaymentValidation,
    customDataKey,
    customValidationKey,
  );
};

/**
 *
 * @param { object } param0     configuration as below
 *
 * @param   { number }      amount                  number amount of generated data and validation
 * @param   { object }      companyUserData         data where company user that used
 * @param   { string }      customDataKey           name return key of data
 * @param   { string }      customValidationKey     name return key of validation
 *
 * @returns
 *  generated data and validation for sales invoice basic
 */
// export const generateDataNValidationAddPaymentSalesPaymentV2 = ({
//     amount=1,
//     companyUserData={},
//     customDataKey='paymentData',
//     customValidationKey='paymentValidation',
// }) => {
//     let accounting_period = null

//     if (!isEmpty(companyUserData)) {
//         accounting_period = companyUserData.accounting_periode
//     }

//     // overriding on accounting period
//     const wrapperInitialSalesPaymentData = (id) => {
//         return {
//             ...initialPaymentData(id),
//             accounting_period,
//         }
//     }

//     return objHelper.creatorGenerateDataNValidation(
//         amount,
//         wrapperInitialSalesPaymentData,
//         initialPaymentValidation,
//         customDataKey,
//         customValidationKey,
//     )
// }

/**
 *
 * @param { number } amount how many data that generate
 * @param { string } customDataKey name key of returning data
 * @param { string } customValidationKey name key of returning validation
 * @returns
 *      generated data and validation for adding sales payment
 *      on payment
 */
export const generateDataNValidationAddPaymentSalesPaymentV2 = ({
  amount = 1,
  companyUserData = {},
  customDataKey = 'paymentData',
  customValidationKey = 'paymentValidation',
}) => {
  return objHelper.creatorGenerateDataNValidation(
    amount,
    (notID, id) => {
      return {
        ...initialPaymentData(notID, id),
        accounting_period: !isEmpty(companyUserData) ? companyUserData.accounting_periode : null,
        [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
          getAccountingPeriodCompareWithDocumentTransactionDate({
            companyUserData,
          }),
      };
    },
    initialPaymentValidation,
    customDataKey,
    customValidationKey,
  );
};

/**
 *
 * @param { number } amount amount of data generated
 * @param { string } customDataKey name key of returning generated data
 * @param { string } customValidationKey name key of returning generated validation
 * @returns
 *      generated data and validation for adding sales payment
 *      on invoice
 */
export const generateDataNValidationAddInvoiceSalesPayment = (
  amount = 0,
  customDataKey = 'invoiceData',
  customValidationKey = 'invoiceValidation',
) => {
  return objHelper.creatorGenerateDataNValidation(
    amount,
    initialInvoiceData,
    initialInvoiceValidation,
    customDataKey,
    customValidationKey,
  );
};

/**
 *
 * @param { number } amount amount of data generated
 * @param { string } customDataKey name key of returning generated data
 * @param { string } customValidationKey name key of returning generated validation
 * @returns
 *      generated data and validation for adding sales payment
 *      on invoice
 */
export const generateDataNValidationAddInvoiceSalesPaymentV2 = ({
  amount = 0,
  customDataKey = 'invoiceData',
  customValidationKey = 'invoiceValidation',
}) => {
  return objHelper.creatorGenerateDataNValidation(
    amount,
    initialInvoiceData,
    initialInvoiceValidation,
    customDataKey,
    customValidationKey,
  );
};

/**
 *
 * @param { array } salesInvoiceData selected sales invoice data
 * @param { string } customDataKey name key of returning generate data invoice
 * @param { string } customValidationKey name key of returning generated data validation invoice
 * @returns
 *      generate data and validation for invoice
 */
export const generateDataNValidationAddInvoiceFromListSI = (
  salesInvoiceData,
  customDataKey = 'invoiceData',
  customValidationKey = 'invoiceValidation',
  setToZeroAmountInput = false,
) => {
  const usedID = arrHelp.generateArrWithFunc(salesInvoiceData.length, uuidV4);

  const invoiceData = salesInvoiceData.map((data, index) => {
    const { amount_available, sales_invoice_no } = data;

    return {
      id: usedID[index],
      ...plainInvoiceDataWithoutID,
      ...data,
      amount_available_const: formatHelp.reverseCurrencyFormatWithRegex(amount_available),
      amount_input: !setToZeroAmountInput ? Number(amount_available) || '' : '',
      sales_invoice_id_container: {
        sales_invoice_no,
      },
    };
  });

  const invoiceValidation = usedID.map((id) => {
    return {
      id,
      ...plainInvoiceValidationWithoutID,
    };
  });

  return {
    [customDataKey]: invoiceData,
    [customValidationKey]: invoiceValidation,
  };
};

/**
 *
 * @param { array } salesInvoiceData selected sales invoice data
 * @param { string } customDataKey name key of returning generate data invoice
 * @param { string } customValidationKey name key of returning generated data validation invoice
 * @returns
 *      generate data and validation for invoice
 */
export const generateDataNValidationAddInvoiceFromListSIv2 = ({
  salesInvoiceList,
  customDataKey = 'invoiceData',
  customValidationKey = 'invoiceValidation',
}) => {
  if (isEmpty(salesInvoiceList) || !Array.isArray(salesInvoiceList)) {
    return {
      [customDataKey]: [],
      [customValidationKey]: [],
    };
  }

  const usedID = arrHelp.generateArrWithFunc(salesInvoiceList.length, uuidV4);

  const invoiceData = salesInvoiceList.map((currentSalesInvoice, index) => {
    currentSalesInvoice = objHelper.changeFormatValue(
      currentSalesInvoice,
      invoiceSalesPaymentCalculationNumberKeyNames,
      [(value) => Number(value) || ''],
    );

    const { amount_available, sales_invoice_no } = currentSalesInvoice;

    return {
      id: usedID[index],
      ...plainInvoiceDataWithoutID,
      ...currentSalesInvoice,
      amount_available_const: formatHelp.reverseCurrencyFormatWithRegex(amount_available),
      amount_input: Number(amount_available) || '',
      sales_invoice_id_container: {
        sales_invoice_no,
      },
    };
  });

  const invoiceValidation = usedID.map((id) => {
    return {
      id,
      ...plainInvoiceValidationWithoutID,
    };
  });

  return {
    [customDataKey]: invoiceData,
    [customValidationKey]: invoiceValidation,
  };
};

/**
 *
 * @param { object } salesInvoiceDetails get detail from sales invoice detail
 * @param { string } customDataKey returning key name for data
 * @param { string } customValidationKey returnning key name for validation
 * @returns
 *      data and validation for sales payment from sales invoice details
 */
export const generateDataNValidationSalesPaymentFromSalesInvoiceDetails = (
  salesInvoiceDetails,
  customDataKey = 'paymentData',
  customValidationKey = 'paymentValidation',
) => {
  if (isEmpty(salesInvoiceDetails)) {
    return generateDataNValidationAddPaymentSalesPayment(1, customDataKey, customValidationKey);
  }

  const usedID = arrHelp.generateArrWithFunc(1, uuidV4);

  const { contact_code, customer_id, customer_name, customer_address } = salesInvoiceDetails;

  const paymentData = usedID.map((id) => {
    return {
      ...initialPaymentData('', id),
      ...salesInvoiceDetails,
      customer_id_container: {
        contact_code,
        contact_id: customer_id,
        contact_name: customer_name,
        customer_address,
      },
      transaction_date: moment(),
      expired_date: null,
      description: '',
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData: { [DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF]: null },
          documentData: salesInvoiceDetails,
        }),
      // overrider from getting minimum date from accounting periode
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]: salesInvoiceDetails[
        DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF
      ]
        ? new Date(salesInvoiceDetails[DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF])
        : null,
    };
  });

  const paymentValidation = usedID.map((id) => {
    return initialPaymentValidation('', id);
  });

  return {
    [customDataKey]: paymentData,
    [customValidationKey]: paymentValidation,
  };
};

/**
 *
 * @param { object } salesInvoiceDetails data details sales invoice
 * @param { string } customDataKey name key for returning data
 * @param { string } customValidationKey name key for returning validation
 * @returns
 *
 */
export const generateDataNValidationInvoiceFromSalesInvoiceDetails = (
  salesInvoiceDetails,
  customDataKey = 'invoiceData',
  customValidationKey = 'invoiceValidation',
) => {
  if (isEmpty(salesInvoiceDetails)) {
    return generateDataNValidationAddInvoiceSalesPayment(0, customDataKey, customValidationKey);
  }

  const {
    amount_available,
    expired_date,
    sales_invoice_no,
    sales_invoice_id,
    amount = 0,
    discount_nominal = 0,
    expense_value = 0,
    down_payment_value = 0,
  } = salesInvoiceDetails;

  const salesInvoiceProductData = salesInvoiceDetails['product'];

  const usedID = arrHelp.generateArrWithFunc(1, uuidV4);

  /**
   * amount, last user input on add sales payment
   * amount_available, total remaining amount that must bill by user
   * amount_available_const maximum input amount user
   *      on update sales payment, amount_available_const result sum from amount_available by amount
   * amount_input from inputting amount by user
   *
   */
  const invoiceData = usedID.map((id, index) => {
    let amount_available_const = Number(amount_available),
      amountView =
        Number(amount) -
        Number(discount_nominal) +
        Number(expense_value) -
        Number(down_payment_value);

    if (Number.isNaN(amount_available_const)) {
      amount_available_const = DEFAULT_CALCULATION_NUMBER;
    }

    return {
      ...initialInvoiceData('', id),
      ...salesInvoiceProductData[index],
      ...salesInvoiceDetails,
      amount_input: '',
      amount: Number(amountView),
      amount_available: amount_available || DEFAULT_CALCULATION_NUMBER,
      amount_available_const,
      expired_date: expired_date ? new Number(expired_date) : null,
      sales_invoice_no_container: {
        sales_invoice_id,
        sales_invoice_no,
      },
    };
  });

  const invoiceValidation = usedID.map((id) => {
    return initialInvoiceValidation('', id);
  });

  return {
    [customDataKey]: invoiceData,
    [customValidationKey]: invoiceValidation,
  };
};

/**
 *
 * @param { array } dataAddPayment data input payment from form
 * @param { array } dataAddInvoice data input invoice form input user
 * @returns
 *      generalized data sales payment before hitting BE
 */
export const generalizeDataAddSalesPayment = (dataAddPayment, dataAddInvoice) => {
  if (!Array.isArray(dataAddPayment) || !Array.isArray(dataAddInvoice)) {
    return {
      dataAddPayment,
      dataAddInvoice,
    };
  }

  dataAddPayment = dataAddPayment.map((dataPayment) => {
    const {
      customer_id,
      customer_id_container,
      customer_address,
      sales_payment_no,
      sales_payment_no_container,
      transaction_date,
      account_id,
      account_id_container,
      expired_date,
      attachment,
    } = dataPayment;

    // get all amount for inputting amount user
    let amountInputPayment = arrHelp.mapWithExistedValue(dataAddInvoice, 'amount_input');
    amountInputPayment = arrHelp.sumValueArr(amountInputPayment);

    let customerAddress = '';

    const { billing_address, contact_name: customer_name } = customer_id_container;

    if (customer_address) customerAddress = customer_address;
    else if (billing_address) customerAddress = billing_address;

    const uuidAttachment =
      Array.isArray(attachment) && attachment.length
        ? attachment[0][DEFAULT_KEY_NAME_FILE_PROPERTIES][DEFAULT_KEY_NAME_UUID_API_REF] || ''
        : '';

    dataPayment = {
      ...dataPayment,
      [DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF]: uuidAttachment,
      customer_address: customerAddress || '',
      customer_name,
      customer_id:
        customer_id_container && customer_id_container.contact_id
          ? customer_id_container.contact_id
          : customer_id,
      sales_payment_no:
        sales_payment_no_container && sales_payment_no_container.reference_number_id
          ? sales_payment_no_container.reference_number_id
          : sales_payment_no,
      transaction_date: transaction_date ? moment(transaction_date).toISOString(true) : '',
      account_id:
        account_id_container && account_id_container.account_id
          ? account_id_container.account_id
          : account_id,
      amount: amountInputPayment
        ? formatHelp.reverseCurrencyFormatWithRegex(amountInputPayment)
        : DEFAULT_CALCULATION_NUMBER,
    };

    if (expired_date) {
      return objHelper.filteringExistedValue(
        {
          ...dataPayment,
          expired_date: expired_date ? moment(expired_date).toISOString(true) : '',
        },
        [],
        true,
      );
    }

    return objHelper.filteringExistedValue(
      objHelper.filterKeyObj(dataPayment, ['expired_date']),
      [],
      true,
    );
  });

  dataAddInvoice = arrHelp.filterExistedValueOnObjItem(
    dataAddInvoice,
    allowKeysInvoiceData,
    [],
    [DEFAULT_CALCULATION_NUMBER],
  );

  dataAddInvoice = dataAddInvoice.map((dataInvoice) => {
    const { amount_input: amount } = dataInvoice;

    dataInvoice = {
      ...dataInvoice,
      amount: amount
        ? formatHelp.reverseCurrencyFormatWithRegex(amount)
        : DEFAULT_CALCULATION_NUMBER,
    };

    return objHelper.filterKeyObj(dataInvoice, [], allowKeysBodySalesPaymentOnInvoice);
  });

  const bodyAddSalesPaymentData = {
    ...dataAddPayment[0],
    invoice: dataAddInvoice,
  };

  return objHelper.filterKeyObj(bodyAddSalesPaymentData, [], allowKeysBodySalesPayment);
};
