import * as React from 'react';
function SvgIconMenuSettingReferenceNumber(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.857 68.143a.429.429 0 11-.857 0 .429.429 0 01.857 0zM6.857 67.714h58.286a.428.428 0 110 .857H6.857a.428.428 0 110-.857zM2.571 67.714h2.143a.428.428 0 110 .857H2.571a.428.428 0 110-.857zM69.429 67.714h-2.143a.428.428 0 100 .857h2.143a.428.428 0 100-.857zM72 68.143a.429.429 0 11-.857 0 .429.429 0 01.857 0zM36 40.835c-1.193 0-2.36.348-3.352 1a5.957 5.957 0 00-2.222 2.664 5.844 5.844 0 00-.343 3.428 5.904 5.904 0 001.65 3.039 6.066 6.066 0 003.09 1.624 6.127 6.127 0 003.486-.338 6.013 6.013 0 002.708-2.186 5.866 5.866 0 001.016-3.296 5.892 5.892 0 00-1.769-4.195A6.092 6.092 0 0036 40.835zm0 10.473a4.668 4.668 0 01-2.563-.765 4.555 4.555 0 01-1.7-2.037 4.469 4.469 0 01-.262-2.622 4.516 4.516 0 011.262-2.323 4.639 4.639 0 012.363-1.242 4.685 4.685 0 012.666.258 4.598 4.598 0 012.07 1.671c.507.747.778 1.624.778 2.522a4.505 4.505 0 01-1.353 3.207A4.658 4.658 0 0136 51.307zM19.32 36.297c1.192 0 2.359-.348 3.351-1a5.956 5.956 0 002.222-2.663 5.844 5.844 0 00.344-3.429 5.905 5.905 0 00-1.651-3.038 6.066 6.066 0 00-3.09-1.624 6.128 6.128 0 00-3.486.337 6.014 6.014 0 00-2.708 2.186 5.866 5.866 0 00-1.016 3.297 5.892 5.892 0 001.769 4.194 6.092 6.092 0 004.264 1.74zm0-10.472a4.67 4.67 0 012.563.765 4.554 4.554 0 011.699 2.036c.349.83.44 1.742.262 2.622a4.516 4.516 0 01-1.262 2.324 4.639 4.639 0 01-2.363 1.242 4.685 4.685 0 01-2.665-.258 4.598 4.598 0 01-2.071-1.672 4.486 4.486 0 01-.778-2.521 4.505 4.505 0 011.353-3.207 4.658 4.658 0 013.261-1.331zm39.394 4.538a5.866 5.866 0 00-1.017-3.297 6.014 6.014 0 00-2.707-2.186 6.128 6.128 0 00-3.486-.337 6.066 6.066 0 00-3.09 1.624 5.904 5.904 0 00-1.65 3.038 5.844 5.844 0 00.343 3.429 5.956 5.956 0 002.222 2.663 6.105 6.105 0 007.616-.74 5.892 5.892 0 001.77-4.194zm-10.647 0c0-.898.27-1.775.777-2.521a4.6 4.6 0 012.071-1.672 4.686 4.686 0 012.666-.258 4.638 4.638 0 012.362 1.242 4.515 4.515 0 011.263 2.324c.178.88.087 1.792-.263 2.621-.349.83-.94 1.539-1.699 2.037a4.668 4.668 0 01-5.824-.566 4.506 4.506 0 01-1.353-3.207z'
        fill='#000'
      />
      <path
        d='M23.933 49.213h.986c.287 1.254.791 2.45 1.49 3.535l-.698.686a1.387 1.387 0 00-.415.988c0 .37.149.725.415.987l1.505 1.48a1.433 1.433 0 002.008 0l.698-.686a11.518 11.518 0 003.594 1.466v.97c0 .37.15.724.416.986s.627.41 1.003.41h2.13c.376 0 .737-.148 1.003-.41.266-.262.416-.617.416-.987v-.97a11.516 11.516 0 003.594-1.465l.698.687c.266.261.627.408 1.004.408.376 0 .737-.147 1.004-.408l1.505-1.481c.266-.262.415-.617.415-.987s-.149-.726-.415-.988l-.698-.686a11.124 11.124 0 001.49-3.535h.986c.376 0 .737-.148 1.003-.41.267-.261.416-.616.417-.986v-2.095c0-.37-.15-.725-.417-.986a1.433 1.433 0 00-1.003-.41h-.986a11.122 11.122 0 00-1.49-3.535l.698-.686c.259-.266.404-.62.404-.987 0-.368-.145-.722-.404-.988l-1.505-1.48a1.433 1.433 0 00-2.008 0l-.698.686a11.517 11.517 0 00-3.594-1.466v-.97c0-.37-.15-.724-.416-.986a1.434 1.434 0 00-1.003-.41h-2.13c-.376.001-.737.148-1.003.41a1.386 1.386 0 00-.416.987v.97c-1.275.282-2.49.777-3.594 1.465l-.698-.687a1.433 1.433 0 00-2.008 0l-1.505 1.481c-.26.266-.404.62-.404.987 0 .369.145.722.404.988l.698.687a11.122 11.122 0 00-1.49 3.534h-.986c-.376 0-.737.148-1.003.41a1.386 1.386 0 00-.417.986v2.095c0 .37.15.725.417.987.266.262.627.409 1.003.41zm0-3.49h2.17l.1-.583a9.565 9.565 0 011.698-4.031l.349-.481-1.536-1.51 1.506-1.481 1.535 1.51.49-.343a9.894 9.894 0 014.098-1.67l.592-.098V34.9h2.13v2.135l.592.097a9.894 9.894 0 014.099 1.671l.489.343 1.535-1.51 1.506 1.48-1.536 1.51.349.482a9.563 9.563 0 011.698 4.03l.1.583h2.17v2.095h-2.17l-.1.582a9.567 9.567 0 01-1.698 4.032l-.349.48 1.536 1.51-1.506 1.482-1.535-1.51-.49.342a9.903 9.903 0 01-4.098 1.67l-.592.099v2.134h-2.13v-2.134l-.592-.098a9.903 9.903 0 01-4.099-1.671l-.489-.343-1.535 1.51-1.506-1.48 1.536-1.51-.349-.481a9.566 9.566 0 01-1.698-4.032l-.1-.582h-2.17v-2.095z'
        fill='#000'
      />
      <path
        d='M56.56 67.714a5.924 5.924 0 001.95-3.01 5.842 5.842 0 00-.16-3.565 5.948 5.948 0 00-2.209-2.83 6.103 6.103 0 00-3.46-1.074 6.103 6.103 0 00-3.46 1.073 5.947 5.947 0 00-2.21 2.831 5.842 5.842 0 00-.159 3.565c.32 1.176 1 2.227 1.949 3.01H23.199a5.924 5.924 0 001.948-3.01 5.842 5.842 0 00-.159-3.565 5.948 5.948 0 00-2.209-2.83 6.103 6.103 0 00-3.46-1.074 6.103 6.103 0 00-3.46 1.073 5.947 5.947 0 00-2.209 2.831 5.842 5.842 0 00-.16 3.565c.321 1.176 1.001 2.227 1.95 3.01h41.12zm-41.855-4.538c0-.897.271-1.775.778-2.52a4.598 4.598 0 012.07-1.672 4.685 4.685 0 012.666-.259 4.639 4.639 0 012.363 1.242 4.516 4.516 0 011.262 2.324c.178.88.087 1.793-.262 2.622-.35.83-.94 1.538-1.7 2.037a4.67 4.67 0 01-5.824-.566 4.505 4.505 0 01-1.353-3.208zm33.362 0c0-.897.27-1.775.777-2.52a4.598 4.598 0 012.071-1.672 4.685 4.685 0 012.666-.259 4.639 4.639 0 012.362 1.242 4.516 4.516 0 011.263 2.324c.178.88.087 1.793-.263 2.622-.349.83-.94 1.538-1.699 2.037a4.67 4.67 0 01-5.824-.566 4.505 4.505 0 01-1.353-3.208z'
        fill='#000'
      />
      <path
        d='M18.429 28.751l1.285-.894V33M51.118 28.642c.081-.165.202-.314.355-.436.152-.123.333-.217.53-.276a1.734 1.734 0 011.211.082c.184.086.345.203.473.345.128.142.22.305.27.48.05.174.056.355.02.531a1.19 1.19 0 01-.233.494v0L51 33v0h3M51.429 60.429h2.57L52.5 62.572a1.5 1.5 0 11-1.06 2.56M19.286 65.571c.828 0 1.5-1.15 1.5-2.571 0-1.42-.672-2.571-1.5-2.571-.829 0-1.5 1.15-1.5 2.571 0 1.42.671 2.571 1.5 2.571z'
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
export default SvgIconMenuSettingReferenceNumber;
