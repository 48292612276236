export const DEFAULT_CLASSNAME_TABLE_CONTAINER_MD_SIZE = 'tableContainerMDSize';
export const DEFAULT_CLASSNAME_TABLE_CONTAINER_MMD_SIZE = 'tableContainerMMDSize';

export const DEFAULT_CLASSNAME_BOX_GRID_ROW = 'boxGridRow';
export const DEFAULT_CLASSNAME_TABLE_CONTAINER_CONSTTANT_HEIGHT_MD_SIZE =
  'tableContainerConstantHeightMDSize';
export const DEFAULT_CLASSNAME_CALCULATION_INPUT = 'calculation-input';
export const DEFAULT_CLASSNAME_INPUT_CENTER = 'input-center';

export const DEFAULT_CLASSNAME_PAPER_PRIMARY_SM_HEIGHT_LAYOUT_PRIMARY =
  'paperPrimarySMheightLayoutPrimary';
export const DEFAULT_CLASSNAME_PAPER_PRIMARY_MD_HEIGHT_LAYOUT_PRIMARY =
  'paperPrimaryMDheightLayoutPrimary';
export const DEFAULT_CLASSNAME_PAPER_SECONDARY_SM_HEIGHT_LAYOUT_PRIMARY =
  'paperSecondarySMheightLayoutPrimary';
export const DEFAULT_CLASSNAME_PAPER_SECONDARY_MD_HEIGHT_LAYOUT_PRIMARY =
  'paperSecondaryMDheightLayoutPrimary';
