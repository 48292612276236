import axios from 'axios';
import { isEmpty } from 'lodash';

import {
  REACT_APP_API_URL_CONTACT,
  REACT_APP_API_URL_CONTACT_PURCHASE_INVOCE,
  REACT_APP_API_URL_CONTACT_SALES_INVOICE,
} from '../../../utils/configs/api.config';
import {
  allowKeysBankData as allowKeysDataContactBank,
  allowKeysDataContact,
} from '../../../utils/data/display/initial-contact-data';
import {
  DEFAULT_ALLOW_PARAMS_CONTACT_DATA_LISTING,
  DEFAULT_DETAIL_CONTACT_DATA_PARAMS,
} from '../../../utils/default/params.default';
import { DEFAULT_CONTACT_ID_ON_PURCHASE_INVOICE_LIST_PARAMS } from '../../../utils/default/params.default';
import { DEFAULT_CONTACT_ID_ON_SALES_INVOICE_LIST_PARAMS } from '../../../utils/default/params.default';
import arrHelp from '../../../utils/helpers/array.helpers';
import { authHeader } from '../../../utils/helpers/auth-header.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import strHelp from '../../../utils/helpers/string.helpers';
import {
  creatorDetailService,
  creatorListService,
} from '../creator-services/creator-services.helper';

function generalizeDataAddUpdateContact(dataContact) {
  if (typeof dataContact !== 'object') {
    return dataContact;
  }

  dataContact = objHelper.filterKeyObj(dataContact, [], allowKeysDataContact);

  if (Array.isArray(dataContact.bank) && !isEmpty(dataContact)) {
    dataContact.bank = dataContact.bank.map((contactBank) => {
      const isDataContactBankExist = objHelper.filteringExistedValue(contactBank, ['id']);

      if (isDataContactBankExist) {
        return objHelper.filterKeyObj(contactBank, [], allowKeysDataContactBank);
      }
    });

    dataContact.bank = arrHelp.filteringExistedValue(dataContact.bank);
  }

  if (Array.isArray(dataContact.products)) {
    dataContact.products = arrHelp.filteringExistedValue(dataContact.products);
  }

  return objHelper.filteringExistedValue(dataContact, [], true);
}

/**
 *
 * contact services
 *
 */

const contactList = async (params) => {
  return await creatorListService(
    REACT_APP_API_URL_CONTACT,
    params,
    DEFAULT_ALLOW_PARAMS_CONTACT_DATA_LISTING,
  );
};

// detail of single contact
const getDetailContact = async (paramsGetDetailContact) => {
  const { contact_id } = paramsGetDetailContact;
  return await creatorDetailService(
    REACT_APP_API_URL_CONTACT,
    contact_id,
    DEFAULT_DETAIL_CONTACT_DATA_PARAMS,
  );
};

const addContact = async (dataAddContact) => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  dataAddContact = generalizeDataAddUpdateContact(dataAddContact);

  return await axios({
    method: 'POST',
    url: REACT_APP_API_URL_CONTACT,
    headers: {
      Authorization,
    },
    data: {
      ...dataAddContact,
    },
  });
};

const updateContact = async (dataUpdateContact) => {
  const { Authorization } = authHeader();

  const { contact_id } = dataUpdateContact;

  if (!Authorization || !contact_id) {
    return null;
  }

  dataUpdateContact = generalizeDataAddUpdateContact(dataUpdateContact);

  return await axios({
    method: 'PUT',
    url: `${REACT_APP_API_URL_CONTACT}/${contact_id}`,
    headers: {
      Authorization,
    },
    data: {
      ...dataUpdateContact,
    },
  });
};

// delete contact
const deleteContact = async ({ contact_id }) => {
  const { Authorization } = authHeader();

  if (!Authorization || !contact_id) {
    return null;
  }

  return await axios({
    method: 'DELETE',
    url: `${REACT_APP_API_URL_CONTACT}/${contact_id}`,
    headers: {
      Authorization,
    },
  });
};

// purchase invoice in contact endpoint
const getListPurchaseInvoiceWithContactID = async (paramsListPurchaseInvoice) => {
  const { contact_id } = paramsListPurchaseInvoice;

  const endpoint = strHelp.templateString(REACT_APP_API_URL_CONTACT_PURCHASE_INVOCE, {
    contact_id,
  });

  return await creatorListService(
    endpoint,
    paramsListPurchaseInvoice,
    DEFAULT_CONTACT_ID_ON_PURCHASE_INVOICE_LIST_PARAMS,
    {},
  );
};

// sales invoice in contact endpoint
const getListSalesInvoiceWithContactID = async (paramsListSalesInvoice) => {
  const { contact_id } = paramsListSalesInvoice;

  const endpoint = strHelp.templateString(REACT_APP_API_URL_CONTACT_SALES_INVOICE, { contact_id });

  return await creatorListService(
    endpoint,
    paramsListSalesInvoice,
    DEFAULT_CONTACT_ID_ON_SALES_INVOICE_LIST_PARAMS,
    {},
  );
};

const contactServices = {
  contactList,
  getDetailContact,
  addContact,
  updateContact,
  deleteContact,
  getListPurchaseInvoiceWithContactID,
  getListSalesInvoiceWithContactID,
};

export default contactServices;
