import React from 'react';
import { Button } from '@mui/material';

import { DEFAULT_ELEMENT_ID_HIDDEN_BUTTON } from '../../../utils/default/html-element-identity.default';

/**
 * @param { object } param0
 *
 * @param   { string }      id          identity for hidden button
 * @param   { function }    onClick     handling trigger clicked button
 *
 * @returns
 */
export default function HiddenButton({
  id = DEFAULT_ELEMENT_ID_HIDDEN_BUTTON,
  onClick = () => null,
}) {
  return (
    <Button
      id={id}
      onClick={typeof onClick === 'function' ? (event) => onClick(event) : null}
      sx={{ display: 'none' }}
    />
  );
}
