import {
  REACT_APP_API_URL_NOTIFICATION,
  REACT_APP_API_URL_UNREAD_NOTIFICATION,
} from '../../../utils/configs/api.config';
import { DEFAULT_NOTIFICATION_LIST_PARAMS } from '../../../utils/default/params.default';
import {
  creatorDetailServiceV2,
  creatorListService,
} from '../creator-services/creator-services.helper';

// service getting unread notification
export const getUnreadNotifications = async (paramsGetUnreadNotifications) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_UNREAD_NOTIFICATION,
    paramsGetUnreadNotifications,
  );
};

// service get notification list
export const getNotificationList = async (paramsGetNotificationList) => {
  return await creatorListService(
    REACT_APP_API_URL_NOTIFICATION,
    paramsGetNotificationList,
    DEFAULT_NOTIFICATION_LIST_PARAMS,
  );
};

const notificationServices = {
  getUnreadNotifications,
  getNotificationList,
};

export default notificationServices;
