export const DEFAULT_PURCHASE_STATUS_OPEN = 'open';
export const DEFAULT_PURCHASE_STATUS_PARTIALLY_SENT = 'partially_sent';
export const DEFAULT_PURCHASE_STATUS_REJECT = 'reject';
export const DEFAULT_PURCHASE_STATUS_CLOSED = 'closed';

/**
 * purchase invoice default status
 */
export const DEFAULT_PURCHASE_INVOICE_STATUS_OPEN = 'open';
export const DEFAULT_PURCHASE_INVOICE_STATUS_PARTIALLY_PAID = 'partially_paid';
export const DEFAULT_PURCHASE_INVOICE_STATUS_PAID = 'paid';
export const DEFAULT_PURCHASE_INVOICE_STATUS_OVERDUE = 'overdue';

/**
 * purchase invoice for chart basic default status
 */
export const DEFAULT_PURCHASE_INVOICE_CHART_BASIC_STATUS_OPEN = 'open';
export const DEFAULT_PURCHASE_INVOICE_CHART_BASIC_STATUS_PARTIALLY_PAID = 'partially_paid';
export const DEFAULT_PURCHASE_INVOICE_CHART_BASIC_STATUS_PAID = 'paid';

/**
 * default status purchase invoice
 */
export const DEFAULT_STATUS_PURCHASE_INVOICE_ALL = 'all';
export const DEFAULT_STATUS_PURCHASE_INVOICE_OPEN = 'open';
export const DEFAULT_STATUS_PURCHASE_INVOICE_PARTIALLY_PAID = 'partially_paid';
export const DEFAULT_STATUS_PURCHASE_INVOICE_PAID = 'paid';
export const DEFAULT_STATUS_PURCHASE_INVOICE_OVERDUE = 'overdue';

/*
 * status purchase order
 */
export const DEFAULT_ADDITIONAL_STATUS_PURCHASE_ORDER_ALL = 'all';
export const DEFAULT_STATUS_PURCHASE_ORDER_OPEN = 'open';
export const DEFAULT_STATUS_PURCHASE_ORDER_PARTIALLY_SENT = 'partially_sent';
export const DEFAULT_STATUS_PURCHASE_ORDER_REJECT = 'reject';
export const DEFAULT_STATUS_PURCHASE_ORDER_CLOSED = 'closed';

/**
 * status purchase order
 */
export const DEFAULT_PURCHASE_ORDER_STATUS_OPEN = 'open';
export const DEFAULT_PURCHASE_ORDER_STATUS_PARTIALLY_SENT = 'partially_sent';
export const DEFAULT_PURCHASE_ORDER_STATUS_REJECT = 'reject';
export const DEFAULT_PURCHASE_ORDER_STATUS_CLOSED = 'closed';

/**
 * status purchase delivery
 */
export const DEFAULT_ADDITIONAL_STATUS_PURCHASE_DELIVERY_ALL = 'all';
export const DEFAULT_STATUS_PURCHASE_DELIVERY_OPEN = 'open';
export const DEFAULT_STATUS_PURCHASE_DELIVERY_PARTIALLY_SENT = 'partially_sent';
export const DEFAULT_STATUS_PURCHASE_DELIVERY_REJECT = 'reject';
export const DEFAULT_STATUS_PURCHASE_DELIVERY_CLOSED = 'closed';

/**
 * status purchase quotation request
 */
export const DEFAULT_ADDITIONAL_STATUS_PURCHASE_QUOTATION_REQUEST_ALL = 'all';
export const DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_OPEN = 'open';
export const DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_CLOSE = 'close';
export const DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_REJECT = 'reject';

/**
 * status purchase return status
 */
export const ADDITIONAL_PURCHASE_RETURN_STATUS_ALL = 'all';
export const PURCHASE_RETURN_STATUS_OPEN = 'open';
export const DEFAULT_STATUS_PURCHASE_RETURN_OPEN = 'open';
export const PURCHASE_RETURN_STATUS_CLOSED = 'closed';

/**
 * status purchase debit note
 */
export const DEFAULT_ADDITIONAL_STATUS_PURCHASE_DEBIT_NOTE_ALL = 'all';
export const DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_OPEN = 'open';
export const DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_PAID = 'paid';
export const DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_CLOSED = 'closed';
export const DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_PARTIALLY_PAID = 'partially_paid';
