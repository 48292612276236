import { pick } from 'lodash';

import {
  DEFAULT_ACTION_MENU_ADD,
  DEFAULT_ACTION_MENU_CREATE,
} from '../../../utils/default/action-menu.default';
import {
  getHandoverConfiguration,
  MINUTES_OF_HANDOVER_TYPE_PURCHASE_DELIVERY,
} from '../../../utils/default/minutes-of-handover-config.default';
import strHelp from '../../../utils/helpers/string.helpers';
import {
  creatorAddService,
  creatorDetailServiceV2,
  creatorMultipartFormDataService,
  creatorUpdateServiceWithTemplateURL,
} from '../creator-services/creator-services.helper';

// add handover, purchase delivery or sales delivery
export const addMinutesOfHandover = async ({
  actionName = DEFAULT_ACTION_MENU_ADD,
  minutesHandoverType = MINUTES_OF_HANDOVER_TYPE_PURCHASE_DELIVERY,
  bodyAddMinutesOfHandover,
}) => {
  actionName = actionName === DEFAULT_ACTION_MENU_ADD ? 'create' : actionName;

  const handoverConfiguration = getHandoverConfiguration({
    minutesHandoverType,
    actionName,
  });

  const { url, useMultipartFormDataRequest = true } = handoverConfiguration;

  let endpoint = url;
  if (actionName !== DEFAULT_ACTION_MENU_ADD && actionName !== DEFAULT_ACTION_MENU_CREATE) {
    const dataParseJSON =
      bodyAddMinutesOfHandover instanceof FormData
        ? JSON.parse(bodyAddMinutesOfHandover.getAll('data'))
        : bodyAddMinutesOfHandover;

    endpoint = strHelp.templateString(url, dataParseJSON);
  }

  if (useMultipartFormDataRequest) {
    return await creatorMultipartFormDataService({
      endpoint,
      bodyData: bodyAddMinutesOfHandover,
    });
  } else if (!useMultipartFormDataRequest && actionName === DEFAULT_ACTION_MENU_CREATE) {
    return await creatorAddService(endpoint, bodyAddMinutesOfHandover);
  }

  return await creatorUpdateServiceWithTemplateURL(endpoint, bodyAddMinutesOfHandover);
};

// detail handover, purchase delivery or sales delivery
export const minutesOfHandoverDetails = async ({
  minutesHandoverType = MINUTES_OF_HANDOVER_TYPE_PURCHASE_DELIVERY,
  paramsMinutesOfHandoverDetails,
  actionName = 'detail',
}) => {
  const { url, uniqueIDKey } = getHandoverConfiguration({
    minutesHandoverType,
    actionName,
  });

  const uniqueKey = pick(paramsMinutesOfHandoverDetails, [uniqueIDKey]);

  return await creatorDetailServiceV2(url, uniqueKey);
};

const minutesOfHandoverServices = {
  addMinutesOfHandover,
  minutesOfHandoverDetails,
};

export default minutesOfHandoverServices;
