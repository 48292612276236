// initial search sales invoice
export const initialSearchSalesInvoice = () => {
  return {
    from_date: null,
    to_date: null,
    sales_invoice_no: '',
    customer_name: '',
    customer_name_container: null,
    status: [],
    status_container: [],
    status_delivery: [],
    status_delivery_container: [],
  };
};
