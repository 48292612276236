import React, { useRef } from 'react';
import { Popper } from '@mui/material';

const PopperAutoWidth = function (props) {
  const popperRef = useRef();

  let style = {
    width: 'fit-content',
    minWidth: props.style ? props.style.width : 206,
  };

  if (popperRef.current && props) {
    const { width: widthProps } = props.style;
    const { offsetWidth } = popperRef.current;

    if (offsetWidth < widthProps) {
      style.width = widthProps;
    } else if (offsetWidth >= widthProps) {
      style.width = 'auto';
    }
  }

  return <Popper {...props} style={style} placement='bottom-start' ref={popperRef} />;
};

export default PopperAutoWidth;
