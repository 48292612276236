import { createAsyncThunk } from '@reduxjs/toolkit';

import provinceServices from '../../services/API/province/province.service';
import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_PROVINCE } from '../../utils/constants/storage.constant';

// listing indo regional list
export const provinceList = createAsyncThunk(
  'province/list',
  async (dataProvinceList, thunkAPI) => {
    const { isRewriteAll } = dataProvinceList;
    // console.log( dataIndoRegionalList )
    try {
      const response = await provinceServices.provinceList(dataProvinceList);

      // console.log( response )

      if (!response) {
        return thunkAPI.rejectWithValue(response.data);
      }

      let { data: provinceData, sucess } = response.data;

      if (!sucess || !Array.isArray(provinceData.data)) {
        provinceData = {
          ...provinceData,
          data: [],
        };
      }

      const currentProvince = LocalStorage.get(LOCAL_STORAGE_PROVINCE);

      if (!currentProvince || isRewriteAll) {
        // set into local storage
        LocalStorage.set(LOCAL_STORAGE_PROVINCE, provinceData);

        return { provinceData };
      }

      // combine data from previous
      const newListDataProvince = currentProvince.data.concat(provinceData.data);

      // adding data from previos
      const newDataProvince = Object.assign(currentProvince, provinceData, {
        data: newListDataProvince,
      });

      LocalStorage.set(LOCAL_STORAGE_PROVINCE, newDataProvince);

      return { provinceData: newDataProvince };
    } catch (error) {
      if (!error.response) {
        return thunkAPI.rejectWithValue(error.respnse);
      }

      const { data } = error.response;

      return thunkAPI.rejectWithValue(data);
    }
  },
);
