import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Button, SvgIcon } from '@mui/material';
import { Box } from '@mui/system';
import { cloneDeep, isEmpty } from 'lodash';

import useModifyTranslation from '../../../hooks/useModifyTranslation';
import usePermission, { permissionFeatureType } from '../../../hooks/usePermission';
import arrHelp from '../../../utils/helpers/array.helpers';
import formatHelp from '../../../utils/helpers/format.helpers';
import { Excel, IconPdfFileTypeV2, IconXlsFileTypeV2, Pdf } from '../../icons';
import MenuIconButtons from '../../menu/MenuIconButtons';

export const commonButtonType = 'commonButton';
export const menuIconButtonsType = 'menuIconButtons';
export const excelIconButtonType = 'excelIconButton';
export const pdfIconButtonType = 'pdfIconButton';
export const excelBoxIconType = 'excelBoxIcon';
export const pdfBoxIconType = 'pdfBoxIcon';
export const childrenType = 'children';

const mappingReserveIconButton = {
  [excelIconButtonType]: {
    Icon: IconXlsFileTypeV2,
  },
  [pdfIconButtonType]: {
    Icon: IconPdfFileTypeV2,
  },
};

const defaultBoxIconContainerProps = {
  boxContainerProps: {
    height: '32px',
    width: '26px',
    sx: {
      ':hover': {
        cursor: 'pointer',
      },
    },
  },
};

const defaultBoxIconSvgProps = {
  svgIconProps: {
    sx: {
      height: 32,
      width: 26,
    },
    viewBox: '0 0 26 32',
  },
};

const mappingReservedBoxIcon = {
  [excelBoxIconType]: {
    Icon: Excel,
    usageOnClick: true,
    ...defaultBoxIconContainerProps,
    ...defaultBoxIconSvgProps,
  },
  [pdfBoxIconType]: {
    Icon: Pdf,
    usageOnClick: true,
    ...defaultBoxIconContainerProps,
    ...defaultBoxIconSvgProps,
  },
};

const defaultPermissionProps = {
  permissionType: permissionFeatureType,
  permissionsRequired: [],
};

export default function ButtonCollections({
  buttonConfigs = [],
  setPageStatus = () => null,
  onClickButtonActions = [],
  onClickButtonAction = () => null,
  disabled = false,
  children = null,
  inheritedData = null,
}) {
  if (isEmpty(buttonConfigs) || !Array.isArray(buttonConfigs)) return null;

  const { t } = useTranslation();
  const location = useLocation();

  const { combineTranslation } = useModifyTranslation();

  const { isPermissionsGranted } = usePermission();

  let lastIndex = 0;

  return buttonConfigs.map((buttonConfig, index) => {
    const {
      identity,
      type,
      childrenItems = [],
      indexClick = '',
      name,
      translationName,
      translationNameOptions = {},
      additionalProps = {},
      permissionProps = {},
      groupingWrapID,
      minimumWrapCount,
      isLinkComponent = false,
      generateLink,
      linkDestination,
      linkSearchParams = {},
      isSetPageStatusAction = false,
      newPageStatus = '',
    } = buttonConfig;

    let clickHandler =
      typeof onClickButtonAction === 'function'
        ? (event) => onClickButtonAction(event, identity, index)
        : null;

    if (!isEmpty(onClickButtonActions) && Array.isArray(onClickButtonActions)) {
      const selectedIndex = indexClick !== undefined ? indexClick : lastIndex;

      clickHandler =
        typeof onClickButtonActions[selectedIndex] === 'function'
          ? (event) => onClickButtonActions[selectedIndex](event, identity, index)
          : clickHandler;
    }

    const showButtonWithPermissions = isPermissionsGranted({
      ...defaultPermissionProps,
      ...permissionProps,
    });

    const additionalLinkComponentProps = isLinkComponent
      ? {
          component: Link,
          to:
            typeof generateLink !== 'function'
              ? formatHelp.combineBaseUrlWithSearchParamsV2(
                  linkDestination || location.pathname,
                  linkSearchParams,
                )
              : generateLink({ location, inheritedData }),
        }
      : {};

    const additionalPageStatusActionComponentProps =
      typeof setPageStatus === 'function' && isSetPageStatusAction && newPageStatus
        ? {
            component: Button,
            to: null,
            onClick: () => setPageStatus(newPageStatus),
          }
        : {};

    let currentWrapCount = 0,
      isMenuIconButtonsWrapped = false;
    if (type === menuIconButtonsType) {
      const selectedGroupingWrap = arrHelp.filterObjDataWithID(
        buttonConfigs,
        'groupingWrapID',
        groupingWrapID,
        0,
        true,
      );

      currentWrapCount = Array.isArray(selectedGroupingWrap)
        ? selectedGroupingWrap.reduce((result, curr) => {
            const { childrenItems } = curr;

            const additionalAmount = childrenItems ? childrenItems.length : 1;

            return result + additionalAmount;
          }, 0)
        : 0;

      isMenuIconButtonsWrapped = minimumWrapCount >= currentWrapCount;
    }

    if (type === commonButtonType) {
      lastIndex += 1;

      if (!showButtonWithPermissions) return null;

      return (
        <Button
          key={`${identity}-button-${t(translationName) || name}`}
          onClick={isLinkComponent ? null : clickHandler}
          disabled={disabled}
          className='primaryButtonV3'
          variant='contained'
          disableElevation
          {...additionalLinkComponentProps}
          {...additionalPageStatusActionComponentProps}
          {...additionalProps}
        >
          {combineTranslation(translationName, translationNameOptions) || name}
        </Button>
      );
    } else if (type === menuIconButtonsType) {
      const childrenItemsLength = childrenItems.length;
      const onMenuItemsClick = cloneDeep(
        onClickButtonActions.slice(lastIndex, lastIndex + childrenItemsLength),
      );

      lastIndex += childrenItemsLength - 1;

      return (
        <MenuIconButtons
          key={`${identity}-button-collection-${index}`}
          onMenuItemsClick={onMenuItemsClick}
          onMenuItemClick={onClickButtonAction}
          menuListItems={childrenItems}
          disabled={disabled}
          isWrapped={isMenuIconButtonsWrapped}
          {...additionalProps}
        />
      );
    } else if (Object.keys(mappingReserveIconButton).includes(type)) {
      lastIndex += 1;

      if (!showButtonWithPermissions) return null;

      const { Icon } = mappingReserveIconButton[type];

      return (
        <Button
          key={`${identity}-reserve-icon-button-${index}`}
          onClick={clickHandler}
          disabled={disabled}
          className='primaryButtonV2'
          variant='outlined'
          disableElevation
          {...additionalLinkComponentProps}
          {...additionalPageStatusActionComponentProps}
          {...additionalProps}
        >
          <SvgIcon viewBox='0 0 20 20' component={Icon} />
        </Button>
      );
    } else if (Object.keys(mappingReservedBoxIcon).includes(type)) {
      lastIndex += 1;
      if (!showButtonWithPermissions) return null;

      const { Icon, boxContainerProps, svgIconProps, usageOnClick } = mappingReservedBoxIcon[type];

      return (
        <Box
          key={`${identity}-reserved-box-icon-${index}`}
          onClick={usageOnClick && !disabled && !isLinkComponent ? clickHandler : null}
          {...additionalLinkComponentProps}
          {...boxContainerProps}
        >
          <SvgIcon {...svgIconProps} component={Icon} />
        </Box>
      );
    }

    if (childrenType) return children;

    return null;
  });
}
