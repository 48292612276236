import * as React from 'react';
function SvgIconPayInvoice(props) {
  return (
    <svg width={21} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M4.229 12.7l.247.572a6.782 6.782 0 002.857 3.189 6.915 6.915 0 008.132-.932 6.728 6.728 0 002.042-3.75 6.677 6.677 0 00-.637-4.211 6.8 6.8 0 00-3.062-2.998 6.92 6.92 0 00-4.27-.594 6.867 6.867 0 00-3.783 2.047l-.908 1.22m-.13-2.883l.13 2.882m0 0l2.605.005'
        stroke='#667085'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.184 6.625v8.105m1.71-6.631H10.33c-.318 0-.622.136-.847.378-.224.241-.35.57-.35.911 0 .342.126.67.35.912.225.242.53.378.847.378h1.71c.318 0 .622.135.847.377.225.242.35.57.35.912 0 .342-.126.67-.35.912a1.155 1.155 0 01-.847.377H9.132'
        stroke='#667085'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
export default SvgIconPayInvoice;
