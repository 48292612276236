import axios from 'axios';

import { REACT_APP_API_URL_REFERENCE_NUMBER } from '../../../utils/configs/api.config';
import { authHeader } from '../../../utils/helpers/auth-header.helpers';

// listing reference number
const referenceNumberList = async ({
  page = 1,
  paginate = 20,
  module,
  code,
  format,
  current_number,
  is_default,
}) => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  let paramsGetListReferenceNumber = {
    page,
    paginate,
  };

  if (module) {
    paramsGetListReferenceNumber.module = module;
  }

  if (code) {
    paramsGetListReferenceNumber.code = code;
  }

  if (format) {
    paramsGetListReferenceNumber.format = format;
  }

  if (current_number) {
    paramsGetListReferenceNumber.current_number = current_number;
  }

  if (is_default) {
    paramsGetListReferenceNumber.is_default = is_default;
  }

  return await axios({
    method: 'GET',
    url: REACT_APP_API_URL_REFERENCE_NUMBER,
    params: {
      ...paramsGetListReferenceNumber,
    },
    headers: {
      Authorization,
    },
  });
};

// add reference number
const addReferenceNumber = async ({ module, code, format, current_number }) => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  let dataAddReferenceNumber = {
    module,
    code,
    format,
  };

  if (current_number) {
    dataAddReferenceNumber.current_number = current_number;
  }

  return await axios({
    method: 'POST',
    url: REACT_APP_API_URL_REFERENCE_NUMBER,
    headers: {
      Authorization,
    },
    data: {
      ...dataAddReferenceNumber,
    },
  });
};

// update reference number
const updateReferenceNumber = async ({ reference_number_id, code, format, current_number }) => {
  const { Authorization } = authHeader();

  if (!Authorization || !reference_number_id) {
    return null;
  }

  let dataUpdateReferenceNumber = {
    code,
  };

  if (format) {
    dataUpdateReferenceNumber.format = format;
  }

  if (current_number) {
    dataUpdateReferenceNumber.current_number = current_number;
  }

  return await axios({
    method: 'PUT',
    url: `${REACT_APP_API_URL_REFERENCE_NUMBER}/${reference_number_id}`,
    headers: {
      Authorization,
    },
    data: {
      ...dataUpdateReferenceNumber,
    },
  });
};

// delete reference number
const deleteReferenceNumber = async ({ reference_number_id }) => {
  const { Authorization } = authHeader();

  if (!Authorization || !reference_number_id) {
    return null;
  }

  return await axios({
    method: 'DELETE',
    url: `${REACT_APP_API_URL_REFERENCE_NUMBER}/${reference_number_id}`,
    headers: {
      Authorization,
    },
  });
};

const referenceNumberServices = {
  referenceNumberList,
  addReferenceNumber,
  updateReferenceNumber,
  deleteReferenceNumber,
};

export default referenceNumberServices;
