export const styles = () => {
  return {
    boxContainerScrollTop: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '& .MuiTablePagination-actions': {
        flexGrow: 0,
      },
    },
  };
};
