import { isEmpty } from 'lodash';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import { DEFAULT_COMPARISON_VALUE_NUMBER } from '../../default/number.default';
import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF,
  DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_PRODUCT_SALES_QUOTATION_DETAILS,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import { DEFAULT_TRANSACTION_NUMBER_SALES_QUOTATION } from '../../default/transaction-number.default';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';
import objHelper from '../../helpers/object.helper';
import parseResponseHelpers from '../../helpers/parse-response.helpers';
import { DEFAULT_CALCULATION_NUMBER } from '../default/number.default';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import { generalizeAttachmentFromDetails } from './initial-add-attachment';
import {
  generalizeDataAddSalesQuotation,
  generateDataNValidationAddProductSQ,
} from './initial-add-sales-quotation';
import {
  initialDataSalesQuotation,
  initialValidationProduct,
  initialValidationSalesQuotation,
  productSalesQuotationCalculationNumberKeyNames,
  salesQuotationCalculationNumberKeyNames,
} from './initial-data-sales-quotation';

// data and validation sales quotation generate from data selected
export const generateDataNValidationUpdateSalesQuotation = (
  salesQuotationDataSelected,
  customID = null,
) => {
  let idHasUsed = customID || [];

  let dataUpdateSalesQuotation = objHelper.filterKeyObj(
    salesQuotationDataSelected,
    [],
    [
      'customer_id',
      'contact_code',
      'customer_name',
      'customer_address',
      'transaction_date',
      'expired_date',
      'transaction_no',
      'description',
      'sales_quotation_id',
      // 'status',
    ],
  );

  idHasUsed = idHasUsed.concat(uuidV4());

  dataUpdateSalesQuotation = idHasUsed.map((id) => {
    return {
      id,
      ...dataUpdateSalesQuotation,
      customer_id_container: {
        contact_id: dataUpdateSalesQuotation.customer_id || null,
        contact_code: dataUpdateSalesQuotation.contact_code || '',
        contact_name: dataUpdateSalesQuotation.customer_name || '',
      },
      customer_address: dataUpdateSalesQuotation.customer_address,
      reference_number_container: {
        code: dataUpdateSalesQuotation.transaction_no,
      },
      description: dataUpdateSalesQuotation.description,
      transaction_date: dataUpdateSalesQuotation.transaction_date
        ? moment(dataUpdateSalesQuotation.transaction_date)
        : null,
      expired_date: dataUpdateSalesQuotation.expired_date
        ? moment(dataUpdateSalesQuotation.expired_date)
        : null,
    };
  });

  const validationUpdateSalesQuotation = idHasUsed.map((id) => {
    return initialValidationSalesQuotation('', id);
  });

  return {
    updateSalesQuotationData: dataUpdateSalesQuotation,
    updateSalesQuotationValidation: validationUpdateSalesQuotation,
  };
};

// data and validation sales quotation generate from data selected
export const generateDataNValidationUpdateSalesQuotationV2 = ({
  salesQuotationDataSelected,
  customID = null,
  companyUserData = {},
  customDataKey = 'updateSalesQuotationData',
  customValidationKey = 'updateSalesQuotationValidation',
}) => {
  let idHasUsed = customID || [];

  if (!isEmpty(salesQuotationDataSelected)) {
    salesQuotationDataSelected = objHelper.changeFormatValue(
      salesQuotationDataSelected,
      salesQuotationCalculationNumberKeyNames,
      [(value) => Number(value) || ''],
    );
  }

  let dataUpdateSalesQuotation = objHelper.filterKeyObj(
    salesQuotationDataSelected,
    [],
    [
      'customer_id',
      'contact_code',
      'customer_name',
      'customer_address',
      'transaction_date',
      'expired_date',
      'amount',
      'transaction_no',
      'description',
      'sales_quotation_id',
      'status',
      'tag',
    ],
  );

  idHasUsed = idHasUsed.concat(uuidV4());

  dataUpdateSalesQuotation = idHasUsed.map((id) => {
    const { sales_agent_code, sales_agent_name, sales_agent_id } = salesQuotationDataSelected;

    return {
      id,
      ...initialDataSalesQuotation('', id),
      ...dataUpdateSalesQuotation,
      ...salesQuotationDataSelected,
      attachment: generalizeAttachmentFromDetails({
        dataDetails: salesQuotationDataSelected,
      }),
      amount_const: Number(dataUpdateSalesQuotation.amount) || DEFAULT_CALCULATION_NUMBER,
      discount_account_id_container: {
        account_id: salesQuotationDataSelected.discount_account_id || '',
        account_name: salesQuotationDataSelected.discount_account_name || '',
        account_code: salesQuotationDataSelected.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: salesQuotationDataSelected.expense_account_id || '',
        account_name: salesQuotationDataSelected.expense_account_name || '',
        account_code: salesQuotationDataSelected.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: salesQuotationDataSelected.down_payment_account_id || '',
        account_name: salesQuotationDataSelected.down_payment_account_name || '',
        account_code: salesQuotationDataSelected.down_payment_account_code || '',
      },
      accounting_period: !isEmpty(companyUserData)
        ? companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF]
        : null,
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
      customer_id_container: {
        contact_id: dataUpdateSalesQuotation.customer_id || null,
        contact_code: dataUpdateSalesQuotation.contact_code || '',
        contact_name: dataUpdateSalesQuotation.customer_name || '',
      },
      customer_address: dataUpdateSalesQuotation.customer_address,
      reference_number:
        dataUpdateSalesQuotation.transaction_no || DEFAULT_TRANSACTION_NUMBER_SALES_QUOTATION,
      reference_number_container: {
        code: dataUpdateSalesQuotation.transaction_no || DEFAULT_TRANSACTION_NUMBER_SALES_QUOTATION,
      },
      description: dataUpdateSalesQuotation.description,
      transaction_date: dataUpdateSalesQuotation.transaction_date
        ? moment(dataUpdateSalesQuotation.transaction_date)
        : null,
      expired_date: dataUpdateSalesQuotation.expired_date
        ? moment(dataUpdateSalesQuotation.expired_date)
        : null,
      sales_agent_id_container: {
        contact_id: sales_agent_id || '',
        contact_code: sales_agent_code || '',
        contact_name: sales_agent_name || '',
      },
      ...parseResponseHelpers.tagContainerInputFromDetails(dataUpdateSalesQuotation),
    };
  });

  const validationUpdateSalesQuotation = idHasUsed.map((id) => {
    return initialValidationSalesQuotation('', id);
  });

  return {
    [customDataKey]: dataUpdateSalesQuotation,
    [customValidationKey]: validationUpdateSalesQuotation,
  };
};

// generate data and validation for product in sales quotation
export const generateDataNValidationUpdateProductSQ = (
  salesQuotationDataSelected,
  customID = null,
  useAddOneRow = true,
  keynameSalesQuotationProduct = DEFAULT_KEY_NAME_PRODUCT_SALES_QUOTATION_DETAILS,
) => {
  let idHasUsed = customID || [];

  const productUpdateData = objHelper.filterKeyObj(
    salesQuotationDataSelected,
    [],
    [keynameSalesQuotationProduct],
  );

  let allProductUpdateData = productUpdateData[keynameSalesQuotationProduct];

  allProductUpdateData = allProductUpdateData.map((dataProduct, index) => {
    if (!isEmpty(dataProduct)) {
      dataProduct = objHelper.changeFormatValue(
        dataProduct,
        productSalesQuotationCalculationNumberKeyNames,
        [(value) => Number(value)],
      );
    }

    const {
      unit_origin,
      unit_destination,
      product_unit,
      quantity,
      quantity_origin,
      price,
      modul_quantity_available,
    } = dataProduct;

    const quantityAvailable = Number(modul_quantity_available);

    idHasUsed = idHasUsed.concat(uuidV4());

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    return {
      ...dataProduct,
      id: idHasUsed[index],
      product_id_container: {
        product_id: dataProduct.product_id,
        product_name: dataProduct.product_name,
        ...dataProduct,
      },
      product_description: dataProduct.description,
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      quantity: dataProduct.quantity,
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantity),
        value_comparison: quantityComparison,
      },
      quantity_available_with_unit: `${quantityAvailable} ${unit_origin || product_unit}`,
      price: dataProduct.price,
      amount: Number(price) * Number(quantity),
      quantity_const: quantityAvailable,
    };
  });

  let validationUpdateProduct = allProductUpdateData.map((dataProduct, index) => {
    return initialValidationProduct('', idHasUsed[index]);
  });

  if (useAddOneRow) {
    const { addProductData, addProductValidation } = generateDataNValidationAddProductSQ(1);

    allProductUpdateData = allProductUpdateData.concat(addProductData);
    validationUpdateProduct = validationUpdateProduct.concat(addProductValidation);
  }

  return {
    updateProductData: allProductUpdateData,
    updateProductValidation: validationUpdateProduct,
  };
};

// generalize data for update sales quotation
export const generalizeDataUpdateSalesQuotation = (salesQuotationData, productData) => {
  const generalizedDataSalesQuotation = generalizeDataAddSalesQuotation(
    salesQuotationData,
    productData,
  );

  const { sales_quotation_id } =
    Array.isArray(salesQuotationData) && salesQuotationData.length
      ? salesQuotationData[0]
      : salesQuotationData;

  return {
    sales_quotation_id,
    ...generalizedDataSalesQuotation,
  };
};
