import * as React from 'react';
function SvgIconPdfFileTypeV2(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M13.762 15.286h-1.684l-1.67-3.165.87-1.505a1.475 1.475 0 10-2.556 0l.87 1.505-1.67 3.165H6.237a1.464 1.464 0 101.274 2.186L8.345 16h3.31l.834 1.472a1.465 1.465 0 101.273-2.186zM6.89 17.12A.75.75 0 116.237 16h1.286l-.633 1.12zm2.451-6.861a.76.76 0 111.318 0L10 11.399l-.659-1.14zm-.612 5.027L10 12.879l1.27 2.407H8.73zm5.686 1.834a.75.75 0 01-1.305 0L12.476 16h1.285a.75.75 0 01.653 1.12h.001zM4.821 1.429h-1.25a.357.357 0 00-.357.357v3.571a.357.357 0 00.715 0V3.93h.892a1.25 1.25 0 000-2.5zm0 1.785H3.93V2.143h.892a.536.536 0 010 1.071zM7.857 1.43h-.714a.357.357 0 00-.357.357v3.571a.357.357 0 00.357.357h.714A1.788 1.788 0 009.643 3.93v-.715A1.788 1.788 0 007.857 1.43zm1.072 2.5A1.071 1.071 0 017.857 5H7.5V2.143h.357A1.071 1.071 0 018.93 3.214v.715zm3.928-2.5h-2.143a.357.357 0 00-.357.357v3.571a.357.357 0 10.714 0V3.93H12.5a.357.357 0 000-.715h-1.429V2.143h1.786a.357.357 0 000-.714zM18.214 13.571a.357.357 0 100-.714.357.357 0 000 .714z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.786 0a.357.357 0 00-.357.357v19.286a.357.357 0 00.357.357h16.428a.357.357 0 00.357-.357v-5a.357.357 0 10-.714 0v4.643H2.143V7.143h15.714v4.643a.358.358 0 00.714 0V3.929a.357.357 0 00-.104-.253L14.895.105A.357.357 0 0014.643 0H1.786zm12.5.714H2.143V6.43h15.714V4.286h-2.5a1.071 1.071 0 01-1.071-1.072v-2.5zM15 1.22v1.995a.357.357 0 00.357.357h1.995L15 1.22z'
        fill='#000'
      />
    </svg>
  );
}
export default SvgIconPdfFileTypeV2;
