// suffix access indicate for menu
// another suffix such as create, delete, update inidicate for actions

export const PERMISSION_ACCOUNT_ACCESS = 'account.access';
export const PERMISSION_ACCOUNT_CREATE = 'account.create';
export const PERMISSION_ACCOUNT_DELETE = 'account.delete';
export const PERMISSION_ACCOUNT_UPDATE = 'account.update';

export const PERMISSION_ACCOUNT_CATEGORY_ACCESS = 'account.category.access';
export const PERMISSION_ACCOUNT_CATEGORY_CREATE = 'account.category.create';
export const PERMISSION_ACCOUNT_CATEGORY_DELETE = 'account.category.delete';
export const PERMISSION_ACCOUNT_CATEGORY_UPDATE = 'account.category.update';

export const PERMISSION_MANAGEMENT_ROLE_ACCESS = 'management.role.access';

export const PERMISSION_MANAGEMENT_USER_ACCESS = 'management.user.access';
export const PERMISSION_MANAGEMENT_USER_DELETE = 'management.user.delete';
export const PERMISSION_MANAGEMENT_USER_DETAIL = 'management.user.detail';
export const PERMISSION_MANAGEMENT_USER_MENU = 'management.user.menu';
export const PERMISSION_MANAGEMENT_USER_ROLE = 'management.user.role';
export const PERMISSION_MANAGEMENT_USER_UPDATE = 'management.user.update';

export const PERMISSION_MANAGEMENT_COMPANY_ACCESS = 'management.company.access';

export const PERMISSION_PRODUCT_ACCESS = 'product.access';
export const PERMISSION_PRODUCT_CREATE = 'product.create';
export const PERMISSION_PRODUCT_DELETE = 'product.delete';
export const PERMISSION_PRODUCT_UPDATE = 'product.update';

export const PERMISSION_PRODUCT_CATEGORY_ACCESS = 'product.category.access';
export const PERMISSION_PRODUCT_CATEGORY_CREATE = 'product.category.create';
export const PERMISSION_PRODUCT_CATEGORY_DELETE = 'product.category.delete';
export const PERMISSION_PRODUCT_CATEGORY_UPDATE = 'product.category.update';

export const PERMISSION_USER_ACCESS = 'user.access';
export const PERMISSION_USER_LOGOUT = 'user.logout';
export const PERMISSION_USER_UPDATE = 'user.update';

export const PERMISSION_USER_COMPANY_ACCCESS = 'user.company.access';
export const PERMISSION_USER_COMPANY_MOVE = 'user.company.move';

export const PERMISSION_CONTACT_ACCESS = 'contact.access';
export const PERMISSION_CONTACT_CREATE = 'contact.create';
export const PERMISSION_CONTACT_DELETE = 'contact.delete';
export const PERMISSION_CONTACT_UPDATE = 'contact.update';

export const PERMISSION_REFERENCE_NUMBER_ACCESS = 'reference.number.access';
export const PERMISSION_REFERENCE_NUMBER_CREATE = 'reference.number.create';
export const PERMISSION_REFERENCE_NUMBER_DELETE = 'reference.number.delete';
export const PERMISSION_REFERENCE_NUMBER_UPDATE = 'reference.number.update';

export const PERMISSION_CLOSING_BOOK_ACCESS = 'closing.book.access';
export const PERMISSION_CLOSING_BOOK_PROCESS = 'closing.book.process';
export const PERMISSION_CLOSING_BOOK_REVERT = 'closing.book.revert';
export const PERMISSION_CLOSING_BOOK_LOCK = 'closing.book.lock';

export const PERMISSION_BEGINNING_BALANCE_CREATE = 'beginning.balance.create';

export const PERMISSION_CASH_N_BANK_RECEIPT_CASH_ACCESS = 'receipt.cash.access';
export const PERMISSION_CASH_N_BANK_RECEIPT_CASH_CREATE = 'receipt.cash.create';
export const PERMISSION_CASH_N_BANK_ACCESS = 'cash.bank.access';
export const PERMISSION_CASH_N_BANK_TRANSACTION_ACCOUNT_ACCESS = 'cash.bank.transaction.account';

export const PERMISSION_CASH_N_BANK_PAYMENT_CASH_ACCESS = 'payment.cash.access';
export const PERMISSION_CASH_N_BANK_PAYMENT_CASH_CREATE = 'payment.cash.create';
export const PERMISSION_CASH_N_BANK_PAYMENT_CASH_DELETE = 'payment.cash.delete';
export const PERMISSION_CASH_N_BANK_PAYMENT_CASH_UPDATE = 'payment.cash.update';

export const PERMISSION_CASH_N_BANK_CASH_TRANSFER_ACCESS = 'transfer.cash.access';
export const PERMISSION_CASH_N_BANK_CASH_TRANSFER_CREATE = 'transfer.cash.create';
export const PERMISSION_CASH_N_BANK_CASH_TRANSFER_DELETE = 'transfer.cash.delete';
export const PERMISSION_CASH_N_BANK_CASH_TRANSFER_UPDATE = 'transfer.cash.update';

export const PERMISSION_JOURNAL_ENTRY_ACCESS = 'journal.entry.access';
export const PERMISSION_JOURNAL_ENTRY_CREATE = 'journal.entry.create';
export const PERMISSION_JOURNAL_ENTRY_UPDATE = 'journal.entry.update';
export const PERMISSION_JOURNAL_ENTRY_DELETE = 'journal.entry.delete';

export const PERMISSION_PURCHASE_QUOTATION_REQUEST_ACCESS = 'quotation.request.access';
export const PERMISSION_PURCHASE_QUOTATION_REQUEST_CREATE = 'quotation.request.create';
export const PERMISSION_PURCHASE_QUOTATION_REQUEST_UPDATE = 'quotation.request.update';
export const PERMISSION_PURCHASE_QUOTATION_REQUEST_DELETE = 'quotation.request.delete';

export const PERMISSION_PURCHASE_ORDER_REQUEST_ACCESS = 'purchase.order.access';
export const PERMISSION_PURCHASE_ORDER_REQUEST_CREATE = 'purchase.order.create';
export const PERMISSION_PURCHASE_ORDER_REQUEST_UPDATE = 'purchase.order.update';
export const PERMISSION_PURCHASE_ORDER_REQUEST_DELETE = 'purchase.order.delete';

export const PERMISSION_PURCHASE_DELIVERY_ACCESS = 'purchase.delivery.access';
export const PERMISSION_PURCHASE_DELIVERY_CREATE = 'purchase.delivery.create';
export const PERMISSION_PURCHASE_DELIVERY_UPDATE = 'purchase.delivery.update';
export const PERMISSION_PURCHASE_DELIVERY_DELETE = 'purchase.delivery.delete';

export const PERMISSION_PURCHASE_PAYMENT_ACCESS = 'purchase.payment.access';
export const PERMISSION_PURCHASE_PAYMENT_CREATE = 'purchase.payment.create';
export const PERMISSION_PURCHASE_PAYMENT_UPDATE = 'purchase.payment.update';
export const PERMISSION_PURCHASE_PAYMENT_DELETE = 'purchase.payment.delete';

export const PERMISSION_PURCHASE_INVOICE_ACCESS = 'purchase.invoice.access';
export const PERMISSION_PURCHASE_INVOICE_CREATE = 'purchase.invoice.create';
export const PERMISSION_PURCHASE_INVOICE_DELETE = 'purchase.invoice.delete';
export const PERMISSION_PURCHASE_INVOICE_DETAIL = 'purchase.invoice.detail';
export const PERMISSION_PURCHASE_INVOICE_UPDATE = 'purchase.invoice.update';

export const PERMISSION_SALES_QUOTATION_ACCESS = 'sales.quotation.access';
export const PERMISSION_SALES_QUOTATION_CREATE = 'sales.quotation.create';
export const PERMISSION_SALES_QUOTATION_UPDATE = 'sales.quotation.update';
export const PERMISSION_SALES_QUOTATION_DELETE = 'sales.quotation.delete';

export const PERMISSION_SALES_INVOICE_ACCESS = 'sales.invoice.access';
export const PERMISSION_SALES_INVOICE_CREATE = 'sales.invoice.create';
export const PERMISSION_SALES_INVOICE_UPDATE = 'sales.invoice.update';
export const PERMISSION_SALES_INVOICE_DELETE = 'sales.invoice.delete';

export const PERMISSION_SALES_ORDER_ACCESS = 'sales.order.access';
export const PERMISSION_SALES_ORDER_DETAIL = 'sales.order.detail';
export const PERMISSION_SALES_ORDER_HISTORY = 'sales.order.history';
export const PERMISSION_SALES_ORDER_CREATE = 'sales.order.create';
export const PERMISSION_SALES_ORDER_UPDATE = 'sales.order.update';
export const PERMISSION_SALES_ORDER_DELETE = 'sales.order.delete';

export const PERMISSION_SALES_DELIVERY_ACCESS = 'sales.delivery.access';
export const PERMISSION_SALES_DELIVERY_DETAIL = 'sales.delivery.detail';
export const PERMISSION_SALES_DELIVERY_HISTORY = 'sales.delivery.history';
export const PERMISSION_SALES_DELIVERY_CREATE = 'sales.delivery.create';
export const PERMISSION_SALES_DELIVERY_UPDATE = 'sales.delivery.update';
export const PERMISSION_SALES_DELIVERY_DELETE = 'sales.delivery.delete';

export const PERMISSION_SALES_PAYMENT_ACCESS = 'sales.payment.access';
export const PERMISSION_SALES_PAYMENT_CREATE = 'sales.payment.create';
export const PERMISSION_SALES_PAYMENT_UPDATE = 'sales.payment.update';
export const PERMISSION_SALES_PAYMENT_DELETE = 'sales.payment.delete';

export const PERMISSION_ASSET_CATEGORY_ACCESS = 'asset.category.access';
export const PERMISSION_ASSET_CATEGORY_CREATE = 'asset.category.create';
export const PERMISSION_ASSET_CATEGORY_UPDATE = 'asset.category.update';
export const PERMISSION_ASSET_CATEGORY_DELETE = 'asset.category.delete';

export const PERMISSION_ASSET_MANAGEMENT_ACCESS = 'asset.management.access';
export const PERMISSION_ASSET_MANAGEMENT_CREATE = 'asset.management.create';
export const PERMISSION_ASSET_MANAGEMENT_UPDATE = 'asset.management.update';
export const PERMISSION_ASSET_MANAGEMENT_DELETE = 'asset.management.delete';

export const PERMISSION_REPORT_LIST_ACCESS = 'report.access';
export const PERMISSION_REPORT_JOURNAL_ACCESS = 'report.journal.access';
export const PERMISSION_REPORT_BALANCE_SHEET = 'report.balance.sheet.access';
export const PERMISSION_REPORT_CAPITAL_CHANGE = 'report.change.capital.access';
export const PERMISSION_REPORT_CASH_FLOW = 'report.cash.flow.access';
export const PERMISSION_REPORT_GENERAL_LEDGER = 'report.ledger.access';
export const PERMISSION_REPORT_TRIAL_BALANCE = 'report.trial.balance.access';

export const PERMISSION_EXPORT_DOCUMENT_REPORT_GENERAL_JOURNAL = 'report.export.journal';
export const PERMISSION_EXPORT_DOCUMENT_REPORT_INCOME_STATEMENT = 'report.export.income.statement';
export const PERMISSION_EXPORT_DOCUMENT_BALANCE_SHEET = 'report.export.balance.sheet';
export const PERMISSION_EXPORT_DOCUMENT_CAPITAL_CHANGE = 'report.export.change.capital';
export const PERMISSION_EXPORT_DOCUMENT_CASH_FLOW = 'report.export.cash.flow';
export const PERMISSION_EXPORT_DOCUMENT_GENERAL_LEDGER = 'report.export.ledger';
export const PERMISSION_EXPORT_DOCUMENT_TRIAL_BALANCE = 'report.export.trial.balance';

export const PERMISSION_SETTINGS_WAREHOUSE_ACCESS = 'warehouse.access';

export const PERMISSION_STOCK_ADJUSTMENT_ACCESS = 'stock.adjustment.access';
export const PERMISSION_STOCK_ADJUSTMENT_DETAIL = 'stock.adjustment.detail';
export const PERMISSION_STOCK_ADJUSTMENT_DELETE = 'stock.adjustment.delete';
export const PERMISSION_STOCK_ADJUSTMENT_CREATE = 'stock.adjustment.create';
export const PERMISSION_STOCK_ADJUSTMENT_UPDATE = 'stock.adjustment.update';

export const PERMISSION_STOCK_TRANSFER_ACCESS = 'transfer.stock.access';
export const PERMISSION_STOCK_TRANSFER_DETAIL = 'transfer.stock.detail';
export const PERMISSION_STOCK_TRANSFER_DELETE = 'transfer.stock.delete';
export const PERMISSION_STOCK_TRANSFER_CREATE = 'transfer.stock.create';
export const PERMISSION_STOCK_TRANSFER_UPDATE = 'transfer.stock.update';

// updated permission for writing new flow
// how to write: [menu-name]_[sub-menu]_[permission-name]_permission

export const APPLICATION_BASIC_PERMISSION = 'application.basic';
export const APPLICATION_PRO_PERMISSION = 'application.pro';

export const ASSET_MANAGEMENT_ACCESS_PERMISSION = 'asset.management.access';
export const ASSET_MANAGEMENT_CREATE_PERMISSION = 'asset.management.create';
export const ASSET_MANAGEMENT_DELETE_PERMISSION = 'asset.management.delete';
export const ASSET_MANAGEMENT_DETAIL_PERMISSION = 'asset.management.detail';
export const ASSET_MANAGEMENT_REVERT_PERMISSION = 'asset.management.revert';
export const ASSET_MANAGEMENT_SALE_PERMISSION = 'asset.management.sale';
export const ASSET_MANAGEMENT_UPDATE_PERMISSION = 'asset.management.update';

export const ASSET_TRANSACTION_ACCESS_PERMISSION = 'asset.transaction.access';

export const ASSET_CATEGORY_ACCESS_PERMISSION = 'asset.category.access';
export const ASSET_CATEGORY_CREATE_PERMISSION = 'asset.category.create';
export const ASSET_CATEGORY_DELETE_PERMISSION = 'asset.category.delete';
export const ASSET_CATEGORY_DETAIL_PERMISSION = 'asset.category.detail';
export const ASSET_CATEGORY_UPDATE_PERMISSION = 'asset.category.update';

export const ATTACHMENT_CREATE_PERMISSION = 'attachment.create';
export const ATTACHMENT_DELETE_PERMISSION = 'attachment.delete';
export const ATTACHMENT_DELETE_BATCH_PERMISSION = 'attachment.delete.batch';
export const ATTACHMENT_DETAIL_PERMISSION = 'attachment.detail';
export const ATTACHMENT_UPDATE_PERMISSION = 'attachment.update';

export const BEGINNING_BALANCE_ACCESS_PERMISSION = 'beginning.balance.access';
export const BEGINNING_BALANCE_CREATE_PERMISSION = 'beginning.balance.create';
export const BEGINNING_BALANCE_UPDATE_PERMISSION = 'beginning.balance.update';

export const CASH_BANK_ACCESS_PERMISSION = 'cash.bank.access';
export const CASH_BANK_CALCULATE_PERMISSION = 'cash.bank.calculate';
export const CASH_BANK_TRANSACTION_PERMISSION = 'cash.bank.transaction';
export const CASH_BANK_TRANSACTION_ACCOUNT_PERMISSION = 'cash.bank.transaction.account';

export const PAYMENT_CASH_ACCESS_PERMISSION = 'payment.cash.access';
export const PAYMENT_CASH_CREATE_PERMISSION = 'payment.cash.create';
export const PAYMENT_CASH_DELETE_PERMISSION = 'payment.cash.delete';
export const PAYMENT_CASH_DETAIL_PERMISSION = 'payment.cash.detail';
export const PAYMENT_CASH_UPDATE_PERMISSION = 'payment.cash.update';

export const RECEIPT_CASH_ACCESS_PERMISSION = 'receipt.cash.access';
export const RECEIPT_CASH_CREATE_PERMISSION = 'receipt.cash.create';
export const RECEIPT_CASH_DELETE_PERMISSION = 'receipt.cash.delete';
export const RECEIPT_CASH_DETAIL_PERMISSION = 'receipt.cash.detail';
export const RECEIPT_CASH_UPDATE_PERMISSION = 'receipt.cash.update';

export const RECEIVE_CASH_CREATE_PERMISSION = 'receive.cash.create';

export const TRANSFER_CASH_ACCESS_PERMISSION = 'transfer.cash.access';
export const TRANSFER_CASH_CREATE_PERMISSION = 'transfer.cash.create';
export const TRANSFER_CASH_DELETE_PERMISSION = 'transfer.cash.delete';
export const TRANSFER_CASH_DETAIL_PERMISSION = 'transfer.cash.detail';
export const TRANSFER_CASH_UPDATE_PERMISSION = 'transfer.cash.update';

export const CHART_COMPARISON_SALES_PERMISSION = 'chart.comparison.sales';
export const CHART_INCOME_STATEMENT_PERMISSION = 'chart.income.statement';
export const CHART_PAYABLE_PERMISSION = 'chart.payable';
export const CHART_PRODUCT_STOCK_PERMISSION = 'chart.product.stock';
export const CHART_PURCHASE_PERMISSION = 'chart.purchase';
export const CHART_PURCHASE_INVOICE_PERMISSION = 'chart.purchase.invoice';
export const CHART_RECEIVABLE_PERMISSION = 'chart.receivable';
export const CHART_SALES_PERMISSION = 'chart.sales';
export const CHART_SALES_INVOICE_PERMISSION = 'chart.sales.invoice';
export const CHART_SALES_PERSON_PERMISSION = 'chart.sales.person';
export const CHART_TOP_SALES_PERMISSION = 'chart.top.sales';
export const CHART_TOP_SALES_COMPARISON_PERMISSION = 'chart.top.sales.comparison';
export const CHART_TRANSACTION_SALES_PERMISSION = 'chart.transaction.sales';

export const ACCOUNT_ACCESS_PERMISSION = 'account.access';
export const ACCOUNT_CREATE_PERMISSION = 'account.create';
export const ACCOUNT_DELETE_PERMISSION = 'account.delete';
export const ACCOUNT_DETAIL_PERMISSION = 'account.detail';
export const ACCOUNT_TRANSACTION_PERMISSION = 'account.transaction';
export const ACCOUNT_UPDATE_PERMISSION = 'account.update';

export const ACCOUNT_SUB_ACCESS_PERMISSION = 'account.sub.access';
export const ACCOUNT_SUB_CREATE_PERMISSION = 'account.sub.create';
export const ACCOUNT_SUB_DELETE_PERMISSION = 'account.sub.delete';
export const ACCOUNT_SUB_UPDATE_PERMISSION = 'account.sub.update';

export const ACCOUNT_CATEGORY_ACCESS_PERMISSION = 'account.category.access';
export const ACCOUNT_CATEGORY_CREATE_PERMISSION = 'account.category.create';
export const ACCOUNT_CATEGORY_DELETE_PERMISSION = 'account.category.delete';
export const ACCOUNT_CATEGORY_UPDATE_PERMISSION = 'account.category.update';

export const CLOSING_BOOK_ACCESS_PERMISSION = 'closing.book.access';
export const CLOSING_BOOK_CREATE_PERMISSION = 'closing.book.create';
export const CLOSING_BOOK_DETAIL_PERMISSION = 'closing.book.detail';
export const CLOSING_BOOK_LOCK_PERMISSION = 'closing.book.lock';
export const CLOSING_BOOK_PROCESS_PERMISSION = 'closing.book.process';
export const CLOSING_BOOK_REVERT_PERMISSION = 'closing.book.revert';

export const CONTACT_ACCESS_PERMISSION = 'contact.access';
export const CONTACT_CREATE_PERMISSION = 'contact.create';
export const CONTACT_DELETE_PERMISSION = 'contact.delete';
export const CONTACT_DETAIL_PERMISSION = 'contact.detail';
export const CONTACT_INVOICE_PERMISSION = 'contact.invoice';
export const CONTACT_INVOICE_PURCHASE_PERMISSION = 'contact.invoice.purchase';
export const CONTACT_INVOICE_SALES_PERMISSION = 'contact.invoice.sales';
export const CONTACT_PAYEE_PERMISSION = 'contact.payee';
export const CONTACT_UPDATE_PERMISSION = 'contact.update';

export const EXPENSE_ACCESS_PERMISSION = 'expense.access';
export const EXPENSE_CREATE_PERMISSION = 'expense.create';
export const EXPENSE_DELETE_PERMISSION = 'expense.delete';
export const EXPENSE_DETAIL_PERMISSION = 'expense.detail';
export const EXPENSE_UPDATE_PERMISSION = 'expense.update';

export const MANAGEMENT_INVITE_CREATE_PERMISSION = 'management.invite.create';

export const JOURNAL_ENTRY_ACCESS_PERMISSION = 'journal.entry.access';
export const JOURNAL_ENTRY_CREATE_PERMISSION = 'journal.entry.create';
export const JOURNAL_ENTRY_DELETE_PERMISSION = 'journal.entry.delete';
export const JOURNAL_ENTRY_DETAIL_PERMISSION = 'journal.entry.detail';
export const JOURNAL_ENTRY_UPDATE_PERMISSION = 'journal.entry.update';

export const BANK_ACCESS_PERMISSION = 'bank.access';

export const POSTAL_CODE_ACCESS_PERMISSION = 'postal-code.access';
export const POSTAL_CODE_CITY_ACCESS_PERMISSION = 'postal-code.city';
export const POSTAL_CODE_SUB_DISTRICT_ACCESS_PERMISSION = 'postal-code.sub-district';
export const POSTAL_CODE_URBAN_ACCESS_PERMISSION = 'postal-code.urban';
export const PROVINCE_ACCESS_PERMISSION = 'province.access';

export const PRODUCT_ACCESS_PERMISSION = 'product.access';
export const PRODUCT_ACCESS_WITH_HPP_PERMISSION = 'product.access-with-hpp';
export const PRODUCT_BATCH_PERMISSION = 'product.batch';
export const PRODUCT_CREATE_PERMISSION = 'product.create';
export const PRODUCT_DELETE_PERMISSION = 'product.delete';
export const PRODUCT_DETAIL_PERMISSION = 'product.detail';
export const PRODUCT_SUPPLIER_PERMISSION = 'product.supplier';
export const PRODUCT_UPDATE_PERMISSION = 'product.update';

export const PRODUCT_CATEGORY_ACCESS_PERMISSION = 'product.category.access';
export const PRODUCT_CATEGORY_CREATE_PERMISSION = 'product.category.create';
export const PRODUCT_CATEGORY_DELETE_PERMISSION = 'product.category.delete';
export const PRODUCT_CATEGORY_UPDATE_PERMISSION = 'product.category.update';
export const PRODUCT_BATCH_INPROGRESS_PERMISSION = 'product.batchInProgress';
export const PRODUCT_UPLOAD_PERMISSION = 'product.upload';

export const PRODUCT_UNIT_ACCESS_PERMISSION = 'product.unit.access';
export const PRODUCT_UNIT_CREATE_PERMISSION = 'product.unit.create';
export const PRODUCT_UNIT_DELETE_PERMISSION = 'product.unit.delete';
export const PRODUCT_UNIT_DETAIL_PERMISSION = 'product.unit.detail';
export const PRODUCT_UNIT_UPDATE_PERMISSION = 'product.unit.update';

export const PURCHASE_DELIVERY_ACCESS_PERMISSION = 'purchase.delivery.access';
export const PURCHASE_DELIVERY_CREATE_PERMISSION = 'purchase.delivery.create';
export const PURCHASE_DELIVERY_DELETE_PERMISSION = 'purchase.delivery.delete';
export const PURCHASE_DELIVERY_DETAIL_PERMISSION = 'purchase.delivery.detail';
export const PURCHASE_DELIVERY_HISTORY_PERMISSION = 'purchase.delivery.history';
export const PURCHASE_DELIVERY_UPDATE_PERMISSION = 'purchase.delivery.update';

export const HAND_OVER_PURCHASE_CREATE_PERMISSION = 'hand.over.purchase.create';
export const HAND_OVER_PURCHASE_DELETE_PERMISSION = 'hand.over.purchase.delete';
export const HAND_OVER_PURCHASE_DETAIL_PERMISSION = 'hand.over.purchase.detail';
export const HAND_OVER_PURCHASE_UPDATE_PERMISSION = 'hand.over.purchase.update';

export const PURCHASE_INVOICE_ACCESS_PERMISSION = 'purchase.invoice.access';
export const PURCHASE_INVOICE_CREATE_PERMISSION = 'purchase.invoice.create';
export const PURCHASE_INVOICE_DELETE_PERMISSION = 'purchase.invoice.delete';
export const PURCHASE_INVOICE_DETAIL_PERMISSION = 'purchase.invoice.detail';
export const PURCHASE_INVOICE_HISTORY_PERMISSION = 'purchase.invoice.history';
export const PURCHASE_INVOICE_UPDATE_PERMISSION = 'purchase.invoice.update';

export const PURCHASE_ORDER_ACCESS_PERMISSION = 'purchase.order.access';
export const PURCHASE_ORDER_CREATE_PERMISSION = 'purchase.order.create';
export const PURCHASE_ORDER_DELETE_PERMISSION = 'purchase.order.delete';
export const PURCHASE_ORDER_DETAIL_PERMISSION = 'purchase.order.detail';
export const PURCHASE_ORDER_HISTORY_PERMISSION = 'purchase.order.history';
export const PURCHASE_ORDER_UPDATE_PERMISSION = 'purchase.order.update';

export const PURCHASE_PAYMENT_ACCESS_PERMISSION = 'purchase.payment.access';
export const PURCHASE_PAYMENT_CREATE_PERMISSION = 'purchase.payment.create';
export const PURCHASE_PAYMENT_DELETE_PERMISSION = 'purchase.payment.delete';
export const PURCHASE_PAYMENT_DETAIL_PERMISSION = 'purchase.payment.detail';
export const PURCHASE_PAYMENT_HISTORY_PERMISSION = 'purchase.payment.history';
export const PURCHASE_PAYMENT_UPDATE_PERMISSION = 'purchase.payment.update';

export const PURCHASE_RETURN_ACCESS_PERMISSION = 'purchase.return.access';
export const PURCHASE_RETURN_CREATE_PERMISSION = 'purchase.return.create';
export const PURCHASE_RETURN_DELETE_PERMISSION = 'purchase.return.delete';
export const PURCHASE_RETURN_UPDATE_PERMISSION = 'purchase.return.update';

export const QUOTATION_REQUEST_ACCESS_PERMISSION = 'quotation.request.access';
export const QUOTATION_REQUEST_CREATE_PERMISSION = 'quotation.request.create';
export const QUOTATION_REQUEST_DELETE_PERMISSION = 'quotation.request.delete';
export const QUOTATION_REQUEST_DETAIL_PERMISSION = 'quotation.request.detail';
export const QUOTATION_REQUEST_HISTORY_PERMISSION = 'quotation.request.history';
export const QUOTATION_REQUEST_UPDATE_PERMISSION = 'quotation.request.update';

export const REPORT_BALANCE_SHEET_ACCESS_PERMISSION = 'report.balance.sheet.access';
export const REPORT_CASH_FLOW_ACCESS_PERMISSION = 'report.cash.flow.access';
export const REPORT_CHANGE_CAPITAL_ACCESS_PERMISSION = 'report.change.capital.access';
export const REPORT_JOURNAL_ACCESS_PERMISSION = 'report.journal.access';
export const REPORT_LEDGER_ACCESS_PERMISSION = 'report.ledger.access';
export const REPORT_TRIAL_BALANCE_ACCESS_PERMISSION = 'report.trial.balance.access';
export const REPORT_PRICE_LIST_ACCESS_PERMISSION = 'report.price.list.access';
export const REPORT_STOCK_ACCESS_PERMISSION = 'report.stock.access';
export const REPORT_EXTRACT_SALES_ACCESS_PERMISSION = 'report.extract.sales.access';
export const REPORT_INCOME_STATEMENT_ACCESS_PERMISSION = 'report.income.statement.access';
export const REPORT_PURCHASE_ACCESS_PERMISSION = 'report.purchase.access';
export const REPORT_SALES_ACCESS_PERMISSION = 'report.sales.access';

export const REPORT_EXPORT_BALANCE_SHEET_PERMISSION = 'report.export.balance.sheet';
export const REPORT_EXPORT_CASH_FLOW_PERMISSION = 'report.export.cash.flow';
export const REPORT_EXPORT_CHANGE_CAPITAL_PERMISSION = 'report.export.change.capital';
export const REPORT_EXPORT_EXCEL_JOURNAL_PERMISSION = 'report.export.excel.journal';
export const REPORT_EXPORT_INCOME_STATEMENT_PERMISSION = 'report.export.income.statement';
export const REPORT_EXPORT_JOURNAL_PERMISSION = 'report.export.journal';
export const REPORT_EXPORT_LEDGER_PERMISSION = 'report.export.ledger';
export const REPORT_EXPORT_TRIAL_BALANCE_PERMISSION = 'report.export.trial.balance';

export const SALES_DELIVERY_ACCESS_PERMISSION = 'sales.delivery.access';
export const SALES_DELIVERY_AND_ORDER_ACCESS_PERMISSION = 'sales.delivery.and.order.access';
export const SALES_DELIVERY_CREATE_PERMISSION = 'sales.delivery.create';
export const SALES_DELIVERY_DELETE_PERMISSION = 'sales.delivery.delete';
export const SALES_DELIVERY_DETAIL_PERMISSION = 'sales.delivery.detail';
export const SALES_DELIVERY_HISTORY_PERMISSION = 'sales.delivery.history';
export const SALES_DELIVERY_UPDATE_PERMISSION = 'sales.delivery.update';

export const HAND_OVER_SALES_CREATE_PERMISSION = 'hand.over.sales.create';
export const HAND_OVER_SALES_DELETE_PERMISSION = 'hand.over.sales.delete';
export const HAND_OVER_SALES_DETAIL_PERMISSION = 'hand.over.sales.detail';
export const HAND_OVER_SALES_UPDATE_PERMISSION = 'hand.over.sales.update';

export const SALES_INVOICE_ACCESS_PERMISSION = 'sales.invoice.access';
export const SALES_INVOICE_CREATE_PERMISSION = 'sales.invoice.create';
export const SALES_INVOICE_DELETE_PERMISSION = 'sales.invoice.delete';
export const SALES_INVOICE_DETAIL_PERMISSION = 'sales.invoice.detail';
export const SALES_INVOICE_HISTORY_PERMISSION = 'sales.invoice.history';
export const SALES_INVOICE_UPDATE_PERMISSION = 'sales.invoice.update';

export const SALES_ORDER_ACCESS_PERMISSION = 'sales.order.access';
export const SALES_ORDER_CREATE_PERMISSION = 'sales.order.create';
export const SALES_ORDER_DELETE_PERMISSION = 'sales.order.delete';
export const SALES_ORDER_DETAIL_PERMISSION = 'sales.order.detail';
export const SALES_ORDER_HISTORY_PERMISSION = 'sales.order.history';
export const SALES_ORDER_UPDATE_PERMISSION = 'sales.order.update';

export const SALES_PAYMENT_ACCESS_PERMISSION = 'sales.payment.access';
export const SALES_PAYMENT_CREATE_PERMISSION = 'sales.payment.create';
export const SALES_PAYMENT_DELETE_PERMISSION = 'sales.payment.delete';
export const SALES_PAYMENT_DETAIL_PERMISSION = 'sales.payment.detail';
export const SALES_PAYMENT_HISTORY_PERMISSION = 'sales.payment.history';
export const SALES_PAYMENT_UPDATE_PERMISSION = 'sales.payment.update';

export const SALES_QUOTATION_ACCESS_PERMISSION = 'sales.quotation.access';
export const SALES_QUOTATION_CREATE_PERMISSION = 'sales.quotation.create';
export const SALES_QUOTATION_DELETE_PERMISSION = 'sales.quotation.delete';
export const SALES_QUOTATION_DETAIL_PERMISSION = 'sales.quotation.detail';
export const SALES_QUOTATION_HISTORY_PERMISSION = 'sales.quotation.history';
export const SALES_QUOTATION_UPDATE_PERMISSION = 'sales.quotation.update';

export const MANAGEMENT_COMPANY_ACCESS_PERMISSION = 'management.company.access';
export const MANAGEMENT_COMPANY_BRANCH_PERMISSION = 'management.company.branch';
export const MANAGEMENT_COMPANY_DELETE_PERMISSION = 'management.company.delete';
export const MANAGEMENT_COMPANY_DETAIL_PERMISSION = 'management.company.detail';
export const MANAGEMENT_COMPANY_UPDATE_PERMISSION = 'management.company.update';

export const REFERENCE_NUMBER_ACCESS_PERMISSION = 'reference.number.access';
export const REFERENCE_NUMBER_CREATE_PERMISSION = 'reference.number.create';
export const REFERENCE_NUMBER_DELETE_PERMISSION = 'reference.number.delete';
export const REFERENCE_NUMBER_UPDATE_PERMISSION = 'reference.number.update';
export const REFERENCE_NUMBER_USE_PERMISSION = 'reference.number.use';

export const MANAGEMENT_USER_ACCESS_PERMISSION = 'management.user.access';
export const MANAGEMENT_USER_CREATE_PERMISSION = 'management.user.create';
export const MANAGEMENT_USER_DELETE_PERMISSION = 'management.user.delete';
export const MANAGEMENT_USER_DETAIL_PERMISSION = 'management.user.detail';
export const MANAGEMENT_USER_UPDATE_PERMISSION = 'management.user.update';
export const MANAGEMENT_USER_UPLOAD_IMAGE_PERMISSION = 'management.user.upload.image';

export const USER_ACCESS_PERMISSION = 'user.access';
export const USER_COMPANY_ACCESS_PERMISSION = 'user.company.access';
export const USER_COMPANY_MOVE_PERMISSION = 'user.company.move';
export const USER_LOGOUT_PERMISSION = 'user.logout';
export const USER_UPDATE_PERMISSION = 'user.update';
export const USER_PERMISSION_ACCESS_PERMISSION = 'user.permission.access';
export const USER_PERMISSION_CREATE_PERMISSION = 'user.permission.create';

export const WAREHOUSE_ACCESS_PERMISSION = 'warehouse.access';
export const WAREHOUSE_CREATE_PERMISSION = 'warehouse.create';
export const WAREHOUSE_DELETE_PERMISSION = 'warehouse.delete';
export const WAREHOUSE_DETAIL_PERMISSION = 'warehouse.detail';
export const WAREHOUSE_LIST_PRODUCT_PERMISSION = 'warehouse.list.product';
export const WAREHOUSE_UPDATE_PERMISSION = 'warehouse.update';

export const BILLING_INVOICE_ACCESS_PERMISSION = 'billing.invoice.access';
export const BILLING_INVOICE_CREATE_PERMISSION = 'billing.invoice.create';

export const STOCK_ADJUSTMENT_ACCESS_PERMISSION = 'stock.adjustment.access';
export const STOCK_ADJUSTMENT_CREATE_PERMISSION = 'stock.adjustment.create';
export const STOCK_ADJUSTMENT_DELETE_PERMISSION = 'stock.adjustment.delete';
export const STOCK_ADJUSTMENT_DETAIL_PERMISSION = 'stock.adjustment.detail';
export const STOCK_ADJUSTMENT_UPDATE_PERMISSION = 'stock.adjustment.update';

export const TRANSACTION_ACCESS_PERMISSION = 'transaction.access';
export const TRANSACTION_DETAIL_PERMISSION = 'transaction.detail';

export const TRANSFER_STOCK_ACCESS_PERMISSION = 'transfer.stock.access';
export const TRANSFER_STOCK_CREATE_PERMISSION = 'transfer.stock.create';
export const TRANSFER_STOCK_DELETE_PERMISSION = 'transfer.stock.delete';
export const TRANSFER_STOCK_DETAIL_PERMISSION = 'transfer.stock.detail';
export const TRANSFER_STOCK_UPDATE_PERMISSION = 'transfer.stock.update';

export const TAG_ACCESS_PERMISSION = 'tag.access';

export const TRANSACTION_DOCUMENT_PERMISSION = 'transaction.document';

/**
 *
 * permission version 2
 * some permission might commented cause collision with other permission
 *
 */

/** menu permission API */
export const APPLICATION_PERMISSION_V2 = 'application';
export const ASSET_PERMISSION_V2 = 'asset';
export const ASSET_CATEGORY_PERMISSION_V2 = 'asset_category';
export const BEGINING_BALANCE_PERMISSION_V2 = 'begining_balance';
export const BILLING_PERMISSION_V2 = 'billing';
export const CASH_AND_BANK_PERMISSION_V2 = 'cash_and_bank';
export const CASH_PAYMENT_PERMISSION_V2 = 'cash_payment';
export const CASH_RECEIPT_PERMISSION_V2 = 'cash_receipt';
export const CASH_TRANSFER_PERMISSION_V2 = 'cash_transfer';
export const CHART_PERMISSION_V2 = 'chart';
export const CHART_OF_ACCOUNT_PERMISSION_V2 = 'chart_of_account';
export const CHART_OF_ACCOUNT_CATEGORY_PERMISSION_V2 = 'chart_of_account_category';
export const CLOSING_BOOK_PERMISSION_V2 = 'closing_book';
export const CONTACT_PERMISSION_V2 = 'contact';
export const EXPENSE_PERMISSION_V2 = 'expense';
export const JOURNAL_PERMISSION_V2 = 'journal';
export const PRODUCT_PERMISSION_V2 = 'product';
export const PRODUCT_CATEGORY_PERMISSION_V2 = 'product_category';
export const PURCHASE_DELIVERY_PERMISSION_V2 = 'purchase_delivery';
export const PURCHASE_HANDOVER_PERMISSION_V2 = 'purchase_handover';
export const PURCHASE_INVOICE_PERMISSION_V2 = 'purchase_invoice';
export const PURCHASE_ORDER_PERMISSION_V2 = 'purchase_order';
export const PURCHASE_PAYMENT_PERMISSION_V2 = 'purchase_payment';
export const QUOTATION_REQUEST_PERMISSION_V2 = 'quotation_request';
export const PURCHASE_RETURN_PERMISSION_V2 = 'purchase_return';
export const DEBIT_NOTE_PERMISSION_V2 = 'debit_note';
export const RECEIVABLE_PAYMENT_PERMISSION_V2 = 'receivable_payment';
export const REPORT_ACCOUNTING_PERMISSION_V2 = 'report_accounting';
export const REPORT_STOCK_PERMISSION_V2 = 'report_stock';
export const REPORT_TRANSACTION_PERMISSION_V2 = 'report_transaction';
export const SALES_DELIVERY_PERMISSION_V2 = 'sales_delivery';
export const SALES_HANDOVER_PERMISSION_V2 = 'sales_handover';
export const SALES_INVOICE_PERMISSION_V2 = 'sales_invoice';
export const SALES_ORDER_PERMISSION_V2 = 'sales_order';
export const SALES_PAYMENT_PERMISSION_V2 = 'sales_payment';
export const SALES_QUOTATION_PERMISSION_V2 = 'sales_quotation';
export const SALES_RETURN_PERMISSION_V2 = 'sales_return';
export const CREDIT_NOTE_PERMISSION_V2 = 'credit_note';
export const PAYABLE_PAYMENT_PERMISSION_V2 = 'payable_payment';
export const SETTING_COMPANY_PERMISSION_V2 = 'setting_company';
export const SETTING_REFERENCE_NUMBER_PERMISSION_V2 = 'setting_reference_number';
export const SETTING_USER_PERMISSION_V2 = 'setting_user';
export const SETTING_USER_PERMISSION_PERMISSION_V2 = 'setting_user_permission';
export const SETTING_WAREHOUSE_PERMISSION_V2 = 'setting_warehouse';
export const STOCK_ADJUSTMENT_PERMISSION_V2 = 'stock_adjustment';
export const STOCK_TRANSFER_PERMISSION_V2 = 'stock_transfer';

/** menu group permission API */
// export const APPLICATION_PERMISSION_V2                      = 'application'
export const DATA_PERMISSION_V2 = 'data';
export const SETTING_PERMISSION_V2 = 'setting';
// export const CASH_AND_BANK_PERMISSION_V2                    = 'cash_and_bank'
export const DASHBOARD_PERMISSION_V2 = 'dashboard';
// export const EXPENSE_PERMISSION_V2                          = 'expense'
export const PURCHASE_PERMISSION_V2 = 'purchase';
export const REPORT_PERMISSION_V2 = 'report';
export const SALES_PERMISSION_V2 = 'sales';

/** permission API */
export const APPLICATION_BASIC_PERMISSION_V2 = 'application_basic';
export const APPLICATION_PROFESIONAL_PERMISSION_V2 = 'application_profesional';
export const ASSET_CREATE_PERMISSION_V2 = 'asset_create';
export const ASSET_DELETE_PERMISSION_V2 = 'asset_delete';
export const ASSET_READ_PERMISSION_V2 = 'asset_read';
export const ASSET_SELL_PERMISSION_V2 = 'asset_sell';
export const ASSET_UPDATE_PERMISSION_V2 = 'asset_update';
export const ASSET_CATEGORY_CREATE_PERMISSION_V2 = 'asset_category_create';
export const ASSET_CATEGORY_DELETE_PERMISSION_V2 = 'asset_category_delete';
export const ASSET_CATEGORY_READ_PERMISSION_V2 = 'asset_category_read';
export const ASSET_CATEGORY_UPDATE_PERMISSION_V2 = 'asset_category_update';
export const BEGINING_BALANCE_CREATE_PERMISSION_V2 = 'begining_balance_create';
export const BEGINING_BALANCE_READ_PERMISSION_V2 = 'begining_balance_read';
export const BEGINING_BALANCE_UPDATE_PERMISSION_V2 = 'begining_balance_update';
export const BILLING_READ_PERMISSION_V2 = 'billing_read';
export const CASH_AND_BANK_READ_PERMISSION_V2 = 'cash_and_bank_read';
export const CASH_PAYMENT_CREATE_PERMISSION_V2 = 'cash_payment_create';
export const CASH_PAYMENT_DELETE_PERMISSION_V2 = 'cash_payment_delete';
export const CASH_PAYMENT_READ_PERMISSION_V2 = 'cash_payment_read';
export const CASH_PAYMENT_UPDATE_PERMISSION_V2 = 'cash_payment_update';
export const CASH_RECEIPT_CREATE_PERMISSION_V2 = 'cash_receipt_create';
export const CASH_RECEIPT_DELETE_PERMISSION_V2 = 'cash_receipt_delete';
export const CASH_RECEIPT_READ_PERMISSION_V2 = 'cash_receipt_read';
export const CASH_RECEIPT_UPDATE_PERMISSION_V2 = 'cash_receipt_update';
export const CASH_TRANSFER_CREATE_PERMISSION_V2 = 'cash_transfer_create';
export const CASH_TRANSFER_DELETE_PERMISSION_V2 = 'cash_transfer_delete';
export const CASH_TRANSFER_READ_PERMISSION_V2 = 'cash_transfer_read';
export const CASH_TRANSFER_UPDATE_PERMISSION_V2 = 'cash_transfer_update';
export const CHART_CHART_COMPARISON_SALES_PERMISSION_V2 = 'chart_chart_comparison_sales';
export const CHART_CHART_COMPARISON_TOP_SALES_PERMISSION_V2 = 'chart_chart_comparison_top_sales';
export const CHART_CHART_INCOME_STATEMENT_PERMISSION_V2 = 'chart_chart_income_statement';
export const CHART_CHART_PAYABLE_PERMISSION_V2 = 'chart_chart_payable';
export const CHART_CHART_PRODUCT_STOCK_PERMISSION_V2 = 'chart_chart_product_stock';
export const CHART_CHART_PURCHASE_INVOICE_PERMISSION_V2 = 'chart_chart_purchase_invoice';
export const CHART_CHART_RECEIVABLE_PERMISSION_V2 = 'chart_chart_receivable';
export const CHART_CHART_SALES_INVOICE_PERMISSION_V2 = 'chart_chart_sales_invoice';
export const CHART_CHART_SALES_PERSON_PERMISSION_V2 = 'chart_chart_sales_person';
export const CHART_CHART_TOP_SALES_PERMISSION_V2 = 'chart_chart_top_sales';
export const CHART_CHART_TOTAL_PURCHASE_PERMISSION_V2 = 'chart_chart_total_purchase';
export const CHART_CHART_TOTAL_SALES_PERMISSION_V2 = 'chart_chart_total_sales';
export const CHART_CHART_TRANSACTION_SALES_PERMISSION_V2 = 'chart_chart_transaction_sales';
export const CHART_CHART_PIVOT_TABLE_V2 = 'chart_chart_pivot_table';
export const CHART_OF_ACCOUNT_CREATE_PERMISSION_V2 = 'chart_of_account_create';
export const CHART_OF_ACCOUNT_DELETE_PERMISSION_V2 = 'chart_of_account_delete';
export const CHART_OF_ACCOUNT_READ_PERMISSION_V2 = 'chart_of_account_read';
export const CHART_OF_ACCOUNT_UPDATE_PERMISSION_V2 = 'chart_of_account_update';
export const CHART_OF_ACCOUNT_CATEGORY_CREATE_PERMISSION_V2 = 'chart_of_account_category_create';
export const CHART_OF_ACCOUNT_CATEGORY_DELETE_PERMISSION_V2 = 'chart_of_account_category_delete';
export const CHART_OF_ACCOUNT_CATEGORY_READ_PERMISSION_V2 = 'chart_of_account_category_read';
export const CHART_OF_ACCOUNT_CATEGORY_UPDATE_PERMISSION_V2 = 'chart_of_account_category_update';
export const CLOSING_BOOK_CREATE_PERMISSION_V2 = 'closing_book_create';
export const CLOSING_BOOK_READ_PERMISSION_V2 = 'closing_book_read';
export const CONTACT_CREATE_PERMISSION_V2 = 'contact_create';
export const CONTACT_DELETE_PERMISSION_V2 = 'contact_delete';
export const CONTACT_READ_PERMISSION_V2 = 'contact_read';
export const CONTACT_UPDATE_PERMISSION_V2 = 'contact_update';
export const EXPENSE_CREATE_PERMISSION_V2 = 'expense_create';
export const EXPENSE_DELETE_PERMISSION_V2 = 'expense_delete';
export const EXPENSE_READ_PERMISSION_V2 = 'expense_read';
export const EXPENSE_UPDATE_PERMISSION_V2 = 'expense_update';
export const JOURNAL_CREATE_PERMISSION_V2 = 'journal_create';
export const JOURNAL_DELETE_PERMISSION_V2 = 'journal_delete';
export const JOURNAL_READ_PERMISSION_V2 = 'journal_read';
export const JOURNAL_UPDATE_PERMISSION_V2 = 'journal_update';
export const PRODUCT_CREATE_PERMISSION_V2 = 'product_create';
export const PRODUCT_DELETE_PERMISSION_V2 = 'product_delete';
export const PRODUCT_IMPORT_PRODUCT_PERMISSION_V2 = 'product_import_product';
export const PRODUCT_READ_PERMISSION_V2 = 'product_read';
export const PRODUCT_READ_WITH_HPP_PERMISSION_V2 = 'product_read_with_hpp';
export const PRODUCT_READ_PURCHASE_DATA_PERMISSION_V2 = 'product_read_purchase_data';
export const PRODUCT_UPDATE_PERMISSION_V2 = 'product_update';
export const PRODUCT_DETAIL_PERMISSION_V2 = 'product_detail';
export const PRODUCT_CATEGORY_CREATE_PERMISSION_V2 = 'product_category_create';
export const PRODUCT_CATEGORY_DELETE_PERMISSION_V2 = 'product_category_delete';
export const PRODUCT_CATEGORY_READ_PERMISSION_V2 = 'product_category_read';
export const PRODUCT_CATEGORY_UPDATE_PERMISSION_V2 = 'product_category_update';
export const PURCHASE_DELIVERY_CREATE_PERMISSION_V2 = 'purchase_delivery_create';
export const PURCHASE_DELIVERY_DELETE_PERMISSION_V2 = 'purchase_delivery_delete';
export const PURCHASE_DELIVERY_READ_PERMISSION_V2 = 'purchase_delivery_read';
export const PURCHASE_DELIVERY_UPDATE_PERMISSION_V2 = 'purchase_delivery_update';
export const PURCHASE_HANDOVER_CREATE_PERMISSION_V2 = 'purchase_handover_create';
export const PURCHASE_HANDOVER_DELETE_PERMISSION_V2 = 'purchase_handover_delete';
export const PURCHASE_HANDOVER_DETAIL_PERMISSION_V2 = 'purchase_handover_detail';
export const PURCHASE_HANDOVER_UPDATE_PERMISSION_V2 = 'purchase_handover_update';
export const PURCHASE_INVOICE_CREATE_PERMISSION_V2 = 'purchase_invoice_create';
export const PURCHASE_INVOICE_DELETE_PERMISSION_V2 = 'purchase_invoice_delete';
export const PURCHASE_INVOICE_READ_PERMISSION_V2 = 'purchase_invoice_read';
export const PURCHASE_INVOICE_UPDATE_PERMISSION_V2 = 'purchase_invoice_update';
export const PURCHASE_ORDER_CREATE_PERMISSION_V2 = 'purchase_order_create';
export const PURCHASE_ORDER_DELETE_PERMISSION_V2 = 'purchase_order_delete';
export const PURCHASE_ORDER_READ_PERMISSION_V2 = 'purchase_order_read';
export const PURCHASE_ORDER_UPDATE_PERMISSION_V2 = 'purchase_order_update';
export const PURCHASE_PAYMENT_CREATE_PERMISSION_V2 = 'purchase_payment_create';
export const PURCHASE_PAYMENT_DELETE_PERMISSION_V2 = 'purchase_payment_delete';
export const PURCHASE_PAYMENT_READ_PERMISSION_V2 = 'purchase_payment_read';
export const PURCHASE_PAYMENT_UPDATE_PERMISSION_V2 = 'purchase_payment_update';
export const QUOTATION_REQUEST_CREATE_PERMISSION_V2 = 'quotation_request_create';
export const QUOTATION_REQUEST_DELETE_PERMISSION_V2 = 'quotation_request_delete';
export const QUOTATION_REQUEST_READ_PERMISSION_V2 = 'quotation_request_read';
export const QUOTATION_REQUEST_UPDATE_PERMISSION_V2 = 'quotation_request_update';
export const REPORT_ACCOUNTING_BALANCE_SHEET_PERMISSION_V2 = 'report_accounting_balance_sheet';
export const REPORT_ACCOUNTING_CASH_FLOW_PERMISSION_V2 = 'report_accounting_cash_flow';
export const REPORT_ACCOUNTING_CHANGE_CAPITAL_PERMISSION_V2 = 'report_accounting_change_capital';
export const REPORT_ACCOUNTING_JOURNAL_PERMISSION_V2 = 'report_accounting_journal';
export const REPORT_ACCOUNTING_LEDGER_PERMISSION_V2 = 'report_accounting_ledger';
export const REPORT_ACCOUNTING_TRIAL_BALANCE_PERMISSION_V2 = 'report_accounting_trial_balance';
export const REPORT_STOCK_PRICE_LIST_PERMISSION_V2 = 'report_stock_price_list';
export const REPORT_STOCK_STOCK_PERMISSION_V2 = 'report_stock_stock';
export const REPORT_STOCK_STOCK_ADJUSTMENT_PERMISSION_V2 = 'report_stock_stock_adjustment';
export const REPORT_STOCK_STOCK_CARD_PERMISSION_V2 = 'report_stock_stock_card';
export const REPORT_STOCK_STOCK_MUTATION_PERMISSION_V2 = 'report_stock_stock_mutation';
export const REPORT_STOCK_STOCK_PURCHASE_PERMISSION_V2 = 'report_stock_stock_purchase';
export const REPORT_STOCK_STOCK_SALES_PERMISSION_V2 = 'report_stock_stock_sales';
export const REPORT_TRANSACTION_EXTRACT_SALES_PERSON_PERMISSION_V2 =
  'report_transaction_extract_sales_person';
export const REPORT_TRANSACTION_INCOME_STATEMENT_PERMISSION_V2 =
  'report_transaction_income_statement';
export const REPORT_TRANSACTION_PURCHASE_PERMISSION_V2 = 'report_transaction_purchase';
export const REPORT_TRANSACTION_SALES_PERMISSION_V2 = 'report_transaction_sales';
export const SALES_DELIVERY_CREATE_PERMISSION_V2 = 'sales_delivery_create';
export const SALES_DELIVERY_DELETE_PERMISSION_V2 = 'sales_delivery_delete';
export const SALES_DELIVERY_READ_PERMISSION_V2 = 'sales_delivery_read';
export const SALES_DELIVERY_UPDATE_PERMISSION_V2 = 'sales_delivery_update';
export const SALES_HANDOVER_CREATE_PERMISSION_V2 = 'sales_handover_create';
export const SALES_HANDOVER_DELETE_PERMISSION_V2 = 'sales_handover_delete';
export const SALES_HANDOVER_DETAIL_PERMISSION_V2 = 'sales_handover_detail';
export const SALES_HANDOVER_UPDATE_PERMISSION_V2 = 'sales_handover_update';
export const SALES_INVOICE_CREATE_PERMISSION_V2 = 'sales_invoice_create';
export const SALES_INVOICE_DELETE_PERMISSION_V2 = 'sales_invoice_delete';
export const SALES_INVOICE_READ_PERMISSION_V2 = 'sales_invoice_read';
export const SALES_INVOICE_UPDATE_PERMISSION_V2 = 'sales_invoice_update';
export const SALES_ORDER_CREATE_PERMISSION_V2 = 'sales_order_create';
export const SALES_ORDER_DELETE_PERMISSION_V2 = 'sales_order_delete';
export const SALES_ORDER_READ_PERMISSION_V2 = 'sales_order_read';
export const SALES_ORDER_UPDATE_PERMISSION_V2 = 'sales_order_update';
export const SALES_PAYMENT_CREATE_PERMISSION_V2 = 'sales_payment_create';
export const SALES_PAYMENT_DELETE_PERMISSION_V2 = 'sales_payment_delete';
export const SALES_PAYMENT_READ_PERMISSION_V2 = 'sales_payment_read';
export const SALES_PAYMENT_UPDATE_PERMISSION_V2 = 'sales_payment_update';
export const SALES_QUOTATION_CREATE_PERMISSION_V2 = 'sales_quotation_create';
export const SALES_QUOTATION_DELETE_PERMISSION_V2 = 'sales_quotation_delete';
export const SALES_QUOTATION_READ_PERMISSION_V2 = 'sales_quotation_read';
export const SALES_QUOTATION_UPDATE_PERMISSION_V2 = 'sales_quotation_update';
export const SETTING_COMPANY_CREATE_PERMISSION_V2 = 'setting_company_create';
export const SETTING_COMPANY_DELETE_PERMISSION_V2 = 'setting_company_delete';
export const SETTING_COMPANY_READ_PERMISSION_V2 = 'setting_company_read';
export const SETTING_COMPANY_UPDATE_PERMISSION_V2 = 'setting_company_update';
export const SETTING_REFERENCE_NUMBER_CREATE_PERMISSION_V2 = 'setting_reference_number_create';
export const SETTING_REFERENCE_NUMBER_DELETE_PERMISSION_V2 = 'setting_reference_number_delete';
export const SETTING_REFERENCE_NUMBER_READ_PERMISSION_V2 = 'setting_reference_number_read';
export const SETTING_REFERENCE_NUMBER_UPDATE_PERMISSION_V2 = 'setting_reference_number_update';
export const SETTING_USER_CREATE_PERMISSION_V2 = 'setting_user_create';
export const SETTING_USER_DELETE_PERMISSION_V2 = 'setting_user_delete';
export const SETTING_USER_DETAIL_PERMISSION_V2 = 'setting_user_detail';
export const SETTING_USER_INVITE_USER_PERMISSION_V2 = 'setting_user_invite_user';
export const SETTING_USER_READ_PERMISSION_V2 = 'setting_user_read';
export const SETTING_USER_UPDATE_PERMISSION_V2 = 'setting_user_update';
export const SETTING_USER_PERMISSION_ASSIGN_PERMISSION_V2 = 'setting_user_permission_assign';
export const SETTING_USER_PERMISSION_READ_PERMISSION_V2 = 'setting_user_permission_read';
export const SETTING_WAREHOUSE_CREATE_PERMISSION_V2 = 'setting_warehouse_create';
export const SETTING_WAREHOUSE_DELETE_PERMISSION_V2 = 'setting_warehouse_delete';
export const SETTING_WAREHOUSE_DETAIL_PERMISSION_V2 = 'setting_warehouse_detail';
export const SETTING_WAREHOUSE_READ_PERMISSION_V2 = 'setting_warehouse_read';
export const SETTING_WAREHOUSE_UPDATE_PERMISSION_V2 = 'setting_warehouse_update';
export const STOCK_ADJUSTMENT_CREATE_PERMISSION_V2 = 'stock_adjustment_create';
export const STOCK_ADJUSTMENT_DELETE_PERMISSION_V2 = 'stock_adjustment_delete';
export const STOCK_ADJUSTMENT_READ_PERMISSION_V2 = 'stock_adjustment_read';
export const STOCK_ADJUSTMENT_UPDATE_PERMISSION_V2 = 'stock_adjustment_update';
export const STOCK_TRANSFER_CREATE_PERMISSION_V2 = 'stock_transfer_create';
export const STOCK_TRANSFER_DELETE_PERMISSION_V2 = 'stock_transfer_delete';
export const STOCK_TRANSFER_READ_PERMISSION_V2 = 'stock_transfer_read';
export const STOCK_TRANSFER_UPDATE_PERMISSION_V2 = 'stock_transfer_update';

export const PURCHASE_RETURN_ACCESS_PERMISSION_V2 = 'purchase.return.access';
export const PURCHASE_RETURN_DETAIL_PERMISSION_V2 = 'purchase.return.detail';
export const PURCHASE_RETURN_CREATE_PERMISSION_V2 = 'purchase.return.create';
export const PURCHASE_RETURN_UPDATE_PERMISSION_V2 = 'purchase.return.update';
export const PURCHASE_RETURN_DELETE_PERMISSION_V2 = 'purchase.return.delete';

export const DEBIT_NOTE_ACCESS_PERMISSION_V2 = 'debit.note.access';
export const DEBIT_NOTE_DETAIL_PERMISSION_V2 = 'debit.note.detail';

export const RECEIVABLE_PAYMENT_ACCESS_PERMISSION_V2 = 'receivable.payment.access';
export const RECEIVABLE_PAYMENT_CREATE_PERMISSION_V2 = 'receivable.payment.create';
export const RECEIVABLE_PAYMENT_DETAIL_PERMISSION_V2 = 'receivable.payment.detail';
export const RECEIVABLE_PAYMENT_UPDATE_PERMISSION_V2 = 'receivable.payment.update';
export const RECEIVABLE_PAYMENT_DELETE_PERMISSION_V2 = 'receivable.payment.delete';

export const SALES_RETURN_ACCESS_PERMISSION_V2 = 'sales.return.access';
export const SALES_RETURN_CREATE_PERMISSION_V2 = 'sales.return.create';
export const SALES_RETURN_DETAIL_PERMISSION_V2 = 'sales.return.detail';
export const SALES_RETURN_UPDATE_PERMISSION_V2 = 'sales.return.update';
export const SALES_RETURN_DELETE_PERMISSION_V2 = 'sales.return.delete';

export const CREDIT_NOTE_ACCESS_PERMISSION_V2 = 'credit.note.access';
export const CREDIT_NOTE_DETAIL_PERMISSION_V2 = 'credit.note.detail';

export const PAYABLE_PAYMENT_ACCESS_PERMISSION_V2 = 'payable.payment.access';
export const PAYABLE_PAYMENT_DETAIL_PERMISSION_V2 = 'payable.payment.detail';
export const PAYABLE_PAYMENT_CREATE_PERMISSION_V2 = 'payable.payment.create';
export const PAYABLE_PAYMENT_UPDATE_PERMISSION_V2 = 'payable.payment.update';
export const PAYABLE_PAYMENT_DELETE_PERMISSION_V2 = 'payable.payment.delete';

export const PRODUCT_UNIT_CREATE_PERMISSION_V2 = 'product.unit.create';
export const PRODUCT_UNIT_UPDATE_PERMISSION_V2 = 'product.unit.update';
export const PRODUCT_UNIT_DELETE_PERMISSION_V2 = 'product.unit.delete';

export const PRODUCT_PRICE_ACCESS_PERMISSION_V2 = 'product_access product price';
