import {
  FORMAT_COMMON_COLUMN,
  FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
  FORMAT_DATE_COLUMN,
} from '../../../components/table-row/TableRowPureMarkup/TableRowPureMarkup';

import { tersierParagraph } from './additional-styles';

// column report general ledger list that handling table head with translation
export const columnsTableReportGeneralLedger = (t) => {
  return [
    {
      name: 'detail',
      label: t('dashboard.report.general-ledger.table.header-column.detail'),
      format: 'normal',
      width: 80,
    },
    {
      name: 'transaction',
      label: t('dashboard.report.general-ledger.table.header-column.transaction'),
      format: 'normal-generalized',
      width: 275,
    },
    {
      name: 'description',
      label: t('dashboard.report.general-ledger.table.header-column.description'),
      format: 'normal',
      width: 450,
    },
    {
      name: 'debit_amount',
      align: 'right',
      label: t('dashboard.report.general-ledger.table.header-column.debit-amount'),
      format: 'normal-currency',
      width: 200,
    },
    {
      name: 'credit_amount',
      align: 'right',
      label: t('dashboard.report.general-ledger.table.header-column.credit-amount'),
      format: 'normal-currency',
      width: 200,
    },
    {
      name: 'journal_balance',
      align: 'right',
      label: t('dashboard.report.general-ledger.table.header-column.balance'),
      format: 'normal-currency',
      width: 200,
      maxWidth: 250,
    },
  ];
};

export const rowTableTransactionFinalBalanceAccount = [
  'debit_amount',
  'credit_amount',
  'total_amount',
];

export const rowTableTransactionFinalBalanceAccountV2 = [
  'after_debit_amount',
  'after_credit_amount',
  'after_total',
];

export const unusedKeyNamesReportGeneralLedger = [
  'data',
  'search_balance_null',
  'search_from_date',
  'search_isShowMessage',
  'search_to_date',
];

export const correspondGroupingFinalBalaceReportGeneralLedger = {
  final_balance: ['after_credit_amount', 'after_debit_amount', 'after_total'],
};

// column header when usage table row pure markup
export const columnsTableReportGeneralLedgerForTableRowPureMarkup = (t) => {
  return [
    {
      name: 'transaction_date',
      label: t('dashboard.report.general-ledger.table.header-column.detail'),
      style: {
        minWidth: 10,
        width: 10,
        maxWidth: 15,
      },
      formatData: FORMAT_DATE_COLUMN,
      isUseFormatStyleOnRow: true,
      substituteNotExistValue: '',
    },
    {
      name: 'transaction_no',
      label: t('dashboard.report.general-ledger.table.header-column.transaction'),
      style: {
        minWidth: 10,
        width: 10,
        maxWidth: 15,
      },
      styleParagraph: tersierParagraph,
      formatData: FORMAT_COMMON_COLUMN,
      isUseFormatStyleOnRow: true,
      substituteNotExistValue: '',
    },
    {
      name: 'transaction_description',
      label: t('dashboard.report.general-ledger.table.header-column.description'),
      style: {
        minWidth: 105,
        width: 105,
        maxWidth: 110,
      },
      formatData: FORMAT_COMMON_COLUMN,
      isUseFormatStyleOnRow: true,
    },
    {
      name: 'debit_amount',
      label: t('dashboard.report.general-ledger.table.header-column.debit-amount'),
      style: {
        minWidth: 10,
        width: 10,
        maxWidth: 15,
      },
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      isUseZeroNumberWhenNull: false,
      align: 'right',
      isUseFormatStyleOnRow: true,
      substituteNotExistValue: '',
    },
    {
      name: 'credit_amount',
      label: t('dashboard.report.general-ledger.table.header-column.credit-amount'),
      style: {
        minWidth: 10,
        width: 10,
        maxWidth: 15,
      },
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      isUseZeroNumberWhenNull: false,
      align: 'right',
      isUseFormatStyleOnRow: true,
      substituteNotExistValue: '',
    },
    {
      name: 'balance',
      label: t('dashboard.report.general-ledger.table.header-column.balance'),
      align: 'right',
      style: {
        minWidth: 10,
        width: 10,
        maxWidth: 15,
      },
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      substituteNotExistValue: 0,
      isUseFormatStyleOnRow: true,
    },
  ];
};

export const correspondKeynameForTitleOfGeneralLedgerReport = {
  before_total: 'balance',
};

export const correspondKeynameForSubTotalOfGeneralLedgerReport = {
  after_credit_amount: 'credit_amount',
  after_debit_amount: 'debit_amount',
  diffBalance: 'balance',
};

export const tableRowTotalAccountGeneralLedgerReport = [
  'after_credit_amount',
  'after_debit_amount',
  'after_total',
];
