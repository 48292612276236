import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { isEmpty } from 'lodash';

import BoxTableNotFound from '../../../components/box/BoxTableNotFound/BoxTableNotFound';
import TablePaginationMain from '../../../components/table-pagination/TablePaginationMain/TablePaginationMain';
import TableRowLoading from '../../../components/table-row/TableRowLoading/TableRowLoading';
import TypographyLinkPrimary from '../../../components/typography/TypographyLinkPrimary/TypographyLinkPrimary';
import { withRouter } from '../../../components/withRouter/withRouter';
import { decryptData } from '../../../services/modules/Crypto/Crypto.service';
import messageHelper from '../../../store/message/message.helper';
import { getListPurchaseDelivery } from '../../../store/purchase/purchase.action';
import {
  REACT_APP_PURCHASE_PURCHASE_DELIVERY_CREATE_NEW_URL,
  REACT_APP_PURCHASE_PURCHASE_DELIVERY_DETAILS_TEMPLATE_URL,
} from '../../../utils/configs/url.config';
import { STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PENDING } from '../../../utils/constants/store.constant';
import {
  calculationColumns,
  columnsTableHeaderPurchaseDelivery,
} from '../../../utils/data/display/table-list-purchase-delivery';
import { minutesHandoverStatus } from '../../../utils/data/label-value/minutes-of-handover-status';
import { purchaseDeliveryStatus } from '../../../utils/data/label-value/purchase-status';
import {
  DEFAULT_PURCHASE_DELIVERY_SEARCH_FE_PARAMS,
  TERSIER_DEFAULT_LIST_PARAMS,
} from '../../../utils/default/params.default';
import arrHelp from '../../../utils/helpers/array.helpers';
import formatHelp from '../../../utils/helpers/format.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import strHelp from '../../../utils/helpers/string.helpers';

import TableHeadSearchPurchaseDelivery from './TableHeadSearchPurchaseDelivery/TableHeadSearchPurchaseDelivery';
import { styles } from './PurchaseDeliveryList.styles';

class PurchaseDeliveryList extends Component {
  constructor(props) {
    super(props);

    const { generateLinkDetails, handleSelectedStatusPage } = props;

    this.isHandleSelectedStatusPageFunction = typeof handleSelectedStatusPage === 'function';
    this.isGenerateLinkDetailsFunction = typeof generateLinkDetails === 'function';

    this.state = {
      rowsPerPage: TERSIER_DEFAULT_LIST_PARAMS.paginate,
    };

    this.getSearchParamsPurchaseDelivery = this.getSearchParamsPurchaseDelivery.bind(this);

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

    this.handleClickActionPurchaseDelivery = this.handleClickActionPurchaseDelivery.bind(this);
  }

  handleClickActionPurchaseDelivery(event) {
    const { name: newStatusPage, value } = event.currentTarget;

    const decryptedValue = decryptData(value, false);

    if (isEmpty(decryptedValue)) return;

    if (this.isGenerateLinkDetailsFunction) {
      if (!this.props.isUseChangedPageOnDetails) return;

      const linkDetails = this.props.generateLinkDetails(decryptedValue);
      if (!linkDetails) return;

      this.props.navigate(linkDetails, { replace: true });
    }

    if (this.isHandleSelectedStatusPageFunction) {
      this.props.handleSelectedStatusPage(newStatusPage, decryptedValue);
    }
  }

  getSearchParamsPurchaseDelivery() {
    const { purchase } = this.props;
    const { purchaseDeliveryData } = purchase;

    let searchParamsPurchaseDelivery = objHelper.filterKeyObj(
      purchaseDeliveryData,
      [],
      DEFAULT_PURCHASE_DELIVERY_SEARCH_FE_PARAMS,
    );

    if (!isEmpty(searchParamsPurchaseDelivery)) {
      searchParamsPurchaseDelivery = objHelper.changeSuffixKey(
        searchParamsPurchaseDelivery,
        'search_',
        true,
      );
    }

    return searchParamsPurchaseDelivery;
  }

  async handleChangePage(event, newPage) {
    event.preventDefault();

    const { purchase } = this.props;
    const { purchaseDeliveryData } = purchase;

    if (!purchaseDeliveryData) {
      return;
    }

    const { next_page_url, current_page, per_page: paginate } = purchaseDeliveryData;

    if (!next_page_url && current_page < newPage + 1) {
      return;
    }

    let searchParamsPurchaseDelivery = this.getSearchParamsPurchaseDelivery();

    await this.props.getListPurchaseDelivery({
      paginate,
      page: newPage + 1,
      ...searchParamsPurchaseDelivery,
    });
  }

  async handleChangeRowsPerPage(event) {
    const { value: newRowsPerPage } = event.target;

    const { t, purchase } = this.props;

    const { purchaseDeliveryData } = purchase;

    if (!purchaseDeliveryData) {
      return;
    }

    const { next_page_url, current_page, per_page: paginate } = purchaseDeliveryData;

    if (Number(paginate) === Number(newRowsPerPage)) {
      return;
    }

    if (!next_page_url && paginate < newRowsPerPage && current_page === 1) {
      this.props.setMessageFailed(
        t('dashboard.purchase.purchase-delivery.message.max-data.primary'),
        t('dashboard.purchase.purchase-delivery.message.max-data.secondary'),
      );

      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      rowsPerPage: newRowsPerPage,
    }));

    let searchParamsPurchaseDelivery = this.getSearchParamsPurchaseDelivery();

    await this.props.getListPurchaseDelivery({
      paginate: newRowsPerPage,
      ...searchParamsPurchaseDelivery,
    });
  }

  async componentDidMount() {
    const { rowsPerPage: paginate } = this.state;

    await this.props.getListPurchaseDelivery({
      paginate,
      isShowMessage: false,
    });
  }

  render() {
    const { rowsPerPage } = this.state;

    const { t, classes, purchase } = this.props;

    // const { language: languageI18n } = i18n

    const { purchaseDeliveryData } = purchase;

    let purchaseDeliveryList = [];

    let currPagination = rowsPerPage;

    if (purchaseDeliveryData) {
      if (purchaseDeliveryData.data) {
        if (Array.isArray(purchaseDeliveryData.data)) {
          purchaseDeliveryList = purchaseDeliveryData.data;
        }
      }

      if (purchaseDeliveryData.per_page) {
        currPagination = Number(purchaseDeliveryData.per_page);
      }
    }

    const tableHeaderPurchaseDelivery = columnsTableHeaderPurchaseDelivery(t);

    return (
      <Box className={classes.boxContainerTable}>
        <Paper elevation={0}>
          <TableContainer className={'tableContainerConstantHeightMDSizeConstantLayoutPrimary'}>
            <Table stickyHeader className='tableMain' aria-label='table-purchase-delivery-list'>
              <TableHeadSearchPurchaseDelivery dataHeaders={tableHeaderPurchaseDelivery} />
              <TableBody className='primaryTableBody'>
                {purchase.statusRequest === STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PENDING && (
                  <TableRowLoading
                    rowsAmount={currPagination}
                    columnsAmount={tableHeaderPurchaseDelivery.length}
                  />
                )}
                {!isEmpty(purchaseDeliveryList) &&
                  Array.isArray(purchaseDeliveryList) &&
                  purchase.statusRequest !== STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PENDING &&
                  purchaseDeliveryList.map((singlePurchaseDelivery, index) => {
                    const baseUrl = strHelp.templateString(
                      REACT_APP_PURCHASE_PURCHASE_DELIVERY_DETAILS_TEMPLATE_URL,
                      singlePurchaseDelivery,
                    );

                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        className={'hoverEntireRow'}
                        onDoubleClick={() => this.props.navigate(baseUrl)}
                      >
                        {tableHeaderPurchaseDelivery.map((columnTableHeader, indexColumnHeader) => {
                          let valueData = singlePurchaseDelivery[columnTableHeader.name];

                          // formating date following localzone
                          if (columnTableHeader.name === 'delivery_date') {
                            valueData = formatHelp.getReadableDateV2(valueData);
                          }

                          if (columnTableHeader.name === 'purchase_delivery_no') {
                            return (
                              <TableCell key={indexColumnHeader}>
                                <TypographyLinkPrimary
                                  baseUrl={baseUrl}
                                  typographyValue={valueData}
                                />
                              </TableCell>
                            );
                          }

                          // formating value of nominal column
                          if (calculationColumns.includes(columnTableHeader.name)) {
                            valueData = formatHelp.currencyFormatWithRegex(valueData);
                          }

                          // container value status with Chip
                          if (
                            columnTableHeader.name === 'status' ||
                            columnTableHeader.name === 'purchase_handover_status'
                          ) {
                            let defaultStatus = purchaseDeliveryStatus(t);

                            if (columnTableHeader.name === 'purchase_handover_status')
                              defaultStatus = minutesHandoverStatus(t);

                            let label = defaultStatus[0].label,
                              colorChip = defaultStatus[0].colorChip;

                            const selectedDefaultPurchaseDeliveryStatus =
                              arrHelp.filterObjDataWithID(defaultStatus, 'value', valueData);

                            if (!isEmpty(selectedDefaultPurchaseDeliveryStatus)) {
                              label = selectedDefaultPurchaseDeliveryStatus.label;
                              colorChip = selectedDefaultPurchaseDeliveryStatus.colorChip;
                            }

                            return (
                              <TableCell key={indexColumnHeader}>
                                <Chip size='small' label={label} color={colorChip} />
                              </TableCell>
                            );
                          }

                          return (
                            <TableCell key={indexColumnHeader} align={columnTableHeader.align}>
                              {valueData}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {!purchaseDeliveryList.length &&
              purchase.statusRequest !== STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PENDING && (
                <BoxTableNotFound
                  textNotFound={t('dashboard.purchase.purchase-delivery.table.body-not-found')}
                  textButton={t(
                    'dashboard.purchase.purchase-delivery.button.add-purchase-delivery',
                  )}
                  handleClickedButton={() => {
                    this.props.navigate(REACT_APP_PURCHASE_PURCHASE_DELIVERY_CREATE_NEW_URL);
                  }}
                  isExistButton={true}
                />
              )}
          </TableContainer>
          <TablePaginationMain
            page={purchaseDeliveryData ? purchaseDeliveryData.current_page - 1 : 0}
            prev_page={purchaseDeliveryData ? purchaseDeliveryData.prev_page_url : null}
            next_page={purchaseDeliveryData ? purchaseDeliveryData.next_page_url : null}
            rowsPerPage={currPagination}
            isLoadingData={
              purchase.statusRequest === STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PENDING ||
              !purchaseDeliveryList.length
            }
            labelRowsPerPage={t('label.table.pagination.primary.rows-per-page')}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    );
  }
}

PurchaseDeliveryList.defaultProps = {
  isUseChangedPageOnDetails: false,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    purchase: state.purchase,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListPurchaseDelivery: (paramsGetListPurchaseDelivery) =>
      dispatch(getListPurchaseDelivery(paramsGetListPurchaseDelivery)),
    setMessageFailed: (primaryMessage, secondaryMessage) =>
      dispatch(messageHelper.failedMessage(dispatch, primaryMessage, secondaryMessage, false)),
  };
};

// adding style into component
const stylingPurchaseDeliveryList = withStyles(styles)(PurchaseDeliveryList);

// adding translation into component
const PurchaseDeliveryListTranslate = withTranslation()(stylingPurchaseDeliveryList);

const PurchaseDeliveryListWithRouter = withRouter(PurchaseDeliveryListTranslate);

// connect component with action and state
const ConnectedPurchaseDeliveryList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PurchaseDeliveryListWithRouter);

export default ConnectedPurchaseDeliveryList;
