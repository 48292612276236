import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/system';

import BoxTableNotFound from '../../../components/box/BoxTableNotFound/BoxTableNotFound';
import TablePaginationMain from '../../../components/table-pagination/TablePaginationMain/TablePaginationMain';
import TableRowLoading from '../../../components/table-row/TableRowLoading/TableRowLoading';
import TypographyLinkPrimary from '../../../components/typography/TypographyLinkPrimary/TypographyLinkPrimary';
import { withRouter } from '../../../components/withRouter/withRouter';
import { decryptData } from '../../../services/modules/Crypto/Crypto.service';
import messageHelper from '../../../store/message/message.helper';
import { getListPurchasePayment } from '../../../store/purchase/purchase.action';
import {
  REACT_APP_PURCHASE_PURCHASE_PAYMENT_CREATE_NEW_URL,
  REACT_APP_PURCHASE_PURCHASE_PAYMENT_DETAILS_TEMPLATE_URL,
} from '../../../utils/configs/url.config';
import { STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PENDING } from '../../../utils/constants/store.constant';
import {
  calculationColumns,
  columnsTableHeaderPurchasePayment,
} from '../../../utils/data/display/table-purchase-payment-list';
import { TERSIER_DEFAULT_LIST_PARAMS } from '../../../utils/default/params.default';
import formatHelp from '../../../utils/helpers/format.helpers';
import strHelp from '../../../utils/helpers/string.helpers';

import TableHeadSearchPurchasePayment from './TableHeadSearchPurchasePayment/TableHeadSearchPurchasePayment';
import { styles } from './PurchasePaymentList.styles';

class PurchasePaymentList extends Component {
  constructor(props) {
    super(props);

    const { handleSelectedStatusPage } = props;

    this.isHandleSelectedStatusPageFunction = typeof handleSelectedStatusPage === 'function';

    this.state = {
      rowsPerPage: TERSIER_DEFAULT_LIST_PARAMS.paginate,
    };

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

    this.handleClickActionPurchasePayment = this.handleClickActionPurchasePayment.bind(this);
  }

  handleClickActionPurchasePayment(event) {
    const { name: newStatusPage, value } = event.currentTarget;

    const newSelectedPurchasePaymentData = decryptData(value);

    if (this.isHandleSelectedStatusPageFunction) {
      this.props.handleSelectedStatusPage(newStatusPage, newSelectedPurchasePaymentData);
    }
  }

  async handleChangePage(event, newPage) {
    event.preventDefault();

    const { purchase } = this.props;
    const { purchasePaymentData } = purchase;

    if (!purchasePaymentData) {
      return;
    }

    const { next_page_url, current_page, per_page: paginate } = purchasePaymentData;

    if (!next_page_url && current_page < newPage + 1) {
      return;
    }

    await this.props.getListPurchasePayment({
      paginate,
      page: newPage + 1,
    });
  }

  async handleChangeRowsPerPage(event) {
    const { value: newRowsPerPage } = event.target;

    const { t, purchase } = this.props;

    const { purchasePaymentData } = purchase;

    if (!purchasePaymentData) {
      return;
    }

    const { next_page_url, current_page, per_page: paginate } = purchasePaymentData;

    if (Number(paginate) === Number(newRowsPerPage)) {
      return;
    }

    if (!next_page_url && paginate < newRowsPerPage && current_page === 1) {
      this.props.setMessageFailed(
        t('dashboard.purchase.purchase-payment.message.max-data.primary'),
        t('dashboard.purchase.purchase-payment.message.max-data.secondary'),
      );

      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      rowsPerPage: newRowsPerPage,
    }));

    await this.props.getListPurchasePayment({
      paginate: newRowsPerPage,
    });
  }

  async componentDidMount() {
    const { rowsPerPage: paginate } = this.state;

    await this.props.getListPurchasePayment({
      isShowMessage: false,
      paginate,
    });
  }

  render() {
    const { rowsPerPage } = this.state;

    const { t, i18n, classes, purchase } = this.props;

    const { language: languageI18n } = i18n;

    const { purchasePaymentData } = purchase;

    let purchasePaymentList = [];

    let currPagination = rowsPerPage;

    if (purchasePaymentData) {
      if (purchasePaymentData.data) {
        if (Array.isArray(purchasePaymentData.data)) {
          purchasePaymentList = purchasePaymentData.data;
        }
      }

      if (purchasePaymentData.per_page) {
        currPagination = Number(purchasePaymentData.per_page);
      }
    }

    const tableHeaderPurchasePayment = columnsTableHeaderPurchasePayment(t);

    return (
      <Box className={classes.boxContainerTable}>
        <Paper elevation={0}>
          <TableContainer className={'tableContainerConstantHeightMDSizeConstantLayoutPrimary'}>
            <Table stickyHeader className='tableMain' aria-label='table-purchase-payment-list'>
              <TableHeadSearchPurchasePayment dataHeaders={tableHeaderPurchasePayment} />
              <TableBody className='primaryTableBody'>
                {purchase.statusRequest === STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PENDING && (
                  <TableRowLoading
                    rowsAmount={rowsPerPage}
                    columnsAmount={tableHeaderPurchasePayment.length}
                  />
                )}
                {purchasePaymentList &&
                  purchase.statusRequest !== STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PENDING &&
                  purchasePaymentList.map((singlePurchasePayment, index) => {
                    const baseUrl = strHelp.templateString(
                      REACT_APP_PURCHASE_PURCHASE_PAYMENT_DETAILS_TEMPLATE_URL,
                      singlePurchasePayment,
                    );

                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        className={'hoverEntireRow'}
                        onDoubleClick={() => this.props.navigate(baseUrl)}
                      >
                        {tableHeaderPurchasePayment.map((columnTableHeader, indexColumnHeader) => {
                          let valueData = singlePurchasePayment[columnTableHeader.name];

                          // formating date following localzone
                          if (columnTableHeader.name === 'transaction_date') {
                            valueData = formatHelp.getReadableDateV2(valueData, languageI18n);
                          }

                          if (columnTableHeader.name === 'purchase_payment_no') {
                            return (
                              <TableCell key={indexColumnHeader}>
                                <TypographyLinkPrimary
                                  baseUrl={baseUrl}
                                  typographyValue={valueData}
                                />
                              </TableCell>
                            );
                          }

                          // formating value of nominal column
                          if (calculationColumns.includes(columnTableHeader.name)) {
                            valueData = formatHelp.currencyFormatWithRegex(Number(valueData), 0);
                          }

                          // container value status with Chip
                          if (columnTableHeader.name === 'status') {
                            return (
                              <TableCell key={indexColumnHeader}>
                                <Chip
                                  size='small'
                                  label={valueData}
                                  className={classes[valueData]}
                                />
                              </TableCell>
                            );
                          }

                          return (
                            <TableCell key={indexColumnHeader} align={columnTableHeader.align}>
                              {valueData}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {!purchasePaymentList.length &&
              purchase.statusRequest !== STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PENDING && (
                <BoxTableNotFound
                  textNotFound={t('dashboard.purchase.purchase-payment.table.body-not-found')}
                  textButton={t('dashboard.purchase.purchase-payment.button.add-purchase-payment')}
                  handleClickedButton={() => {
                    this.props.navigate(REACT_APP_PURCHASE_PURCHASE_PAYMENT_CREATE_NEW_URL);
                  }}
                  isExistButton={true}
                />
              )}
          </TableContainer>
          <TablePaginationMain
            page={purchasePaymentData ? purchasePaymentData.current_page - 1 : 0}
            prev_page={purchasePaymentData ? purchasePaymentData.prev_page_url : null}
            next_page={purchasePaymentData ? purchasePaymentData.next_page_url : null}
            rowsPerPage={currPagination}
            isLoadingData={
              purchase.statusRequest === STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PENDING ||
              !purchasePaymentList.length
            }
            labelRowsPerPage={t('label.table.pagination.primary.rows-per-page')}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    );
  }
}

PurchasePaymentList.defaultProps = {
  isUseChangedPageOnDetails: false,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    purchase: state.purchase,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListPurchasePayment: (paramsgetListPurchasePayment) =>
      dispatch(getListPurchasePayment(paramsgetListPurchasePayment)),
    setMessageFailed: (primaryMessage, secondaryMessage) =>
      dispatch(messageHelper.failedMessage(dispatch, primaryMessage, secondaryMessage, false)),
  };
};

// adding style into component
const stylingPurchasePaymentList = withStyles(styles)(PurchasePaymentList);

// adding translation into component
const PurchasePaymentListTranslate = withTranslation()(stylingPurchasePaymentList);

const PurchasePaymentListWithRouter = withRouter(PurchasePaymentListTranslate);

// connect component with action and state
const ConnectedPurchasePaymentList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PurchasePaymentListWithRouter);

export default ConnectedPurchasePaymentList;
