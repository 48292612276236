import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { isEmpty } from 'lodash';

import BoxTableNotFound from '../../../components/box/BoxTableNotFound/BoxTableNotFound';
import TablePaginationMain from '../../../components/table-pagination/TablePaginationMain/TablePaginationMain';
import TableRowLoading from '../../../components/table-row/TableRowLoading/TableRowLoading';
import TypographyLinkPrimary from '../../../components/typography/TypographyLinkPrimary/TypographyLinkPrimary';
import { withRouter } from '../../../components/withRouter/withRouter';
import { decryptData } from '../../../services/modules/Crypto/Crypto.service';
import messageHelper from '../../../store/message/message.helper';
import { getListPurchaseOrder } from '../../../store/purchase/purchase.action';
import {
  REACT_APP_PURCHASE_PURCHASE_ORDER_CREATE_NEW_URL,
  REACT_APP_PURCHASE_PURCHASE_ORDER_DETAILS_TEMPLATE_URL,
} from '../../../utils/configs/url.config';
import { STATUS_REQUEST_LIST_PURCHASE_ORDER_PENDING } from '../../../utils/constants/store.constant';
import {
  calculationColumns,
  columnsTableHeaderPurchaseOrder,
} from '../../../utils/data/display/table-purchase-order';
import {
  purchaseInvoiceStatus,
  purchaseOrderStatus,
} from '../../../utils/data/label-value/purchase-status';
import {
  DEFAULT_PURCHASE_ORDER_SEARCH_FE_PARAMS,
  TERSIER_DEFAULT_LIST_PARAMS,
} from '../../../utils/default/params.default';
import arrHelp from '../../../utils/helpers/array.helpers';
import formatHelp from '../../../utils/helpers/format.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import strHelp from '../../../utils/helpers/string.helpers';

import TableHeadSearchPurchaseOrder from './TableHeadSearchPurchaseOrder/TableHeadSearchPurchaseOrder';
import { styles } from './PurchaseOrderList.styles';

class PurchaseOrderList extends Component {
  constructor(props) {
    super(props);

    const { handleChangeStatusPage } = props;

    this.state = {
      rowsPerPage: TERSIER_DEFAULT_LIST_PARAMS.paginate,
    };

    this.isHandleChangeStatusPageFunction = typeof handleChangeStatusPage === 'function';

    this.getSearchParamsPurchaseOrder = this.getSearchParamsPurchaseOrder.bind(this);

    this.handleClickActionPurchaseOrder = this.handleClickActionPurchaseOrder.bind(this);

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  handleClickActionPurchaseOrder(event) {
    const { name: newStatusPage, value } = event.currentTarget;

    const decryptedValue = decryptData(value, false);

    if (isEmpty(decryptedValue)) return;

    if (this.isGenerateLinkDetailsFunction || this.props.isUseChangedPageOnDetails) return;

    if (this.isHandleChangeStatusPageFunction) {
      this.props.handleChangeStatusPage(newStatusPage, decryptedValue);
    }
  }

  getSearchParamsPurchaseOrder() {
    const { purchase } = this.props;
    const { purchaseOrderData } = purchase;

    let searchParamsPurchaseOrder = objHelper.filterKeyObj(
      purchaseOrderData,
      [],
      DEFAULT_PURCHASE_ORDER_SEARCH_FE_PARAMS,
    );

    if (!isEmpty(searchParamsPurchaseOrder)) {
      searchParamsPurchaseOrder = objHelper.changeSuffixKey(
        searchParamsPurchaseOrder,
        'search_',
        true,
      );
    }

    return searchParamsPurchaseOrder;
  }

  async handleChangePage(event, newPage) {
    event.preventDefault();

    const { purchase } = this.props;
    const { purchaseOrderData } = purchase;

    if (!purchaseOrderData) return;

    const { next_page_url, current_page, per_page: paginate } = purchaseOrderData;

    if (!next_page_url && current_page < newPage + 1) return;

    let searchParamsPurchaseOrder = this.getSearchParamsPurchaseOrder();

    await this.props.getPurchaseOrderList({
      paginate,
      page: newPage + 1,
      ...searchParamsPurchaseOrder,
    });
  }

  async handleChangeRowsPerPage(event) {
    const { value: newRowsPerPage } = event.target;

    const { t, purchase } = this.props;

    const { purchaseOrderData } = purchase;

    if (isEmpty(purchaseOrderData)) return;

    const { next_page_url, current_page, per_page: paginate } = purchaseOrderData;

    if (Number(paginate) === Number(newRowsPerPage)) return;

    if (!next_page_url && paginate < newRowsPerPage && current_page === 1) {
      this.props.setMessageFailed(
        t('dashboard.purchase.purchase-order.message.max-data.primary'),
        t('dashboard.purchase.purchase-order.message.max-data.secondary'),
      );

      return;
    }

    let searchParamsPurchaseOrder = this.getSearchParamsPurchaseOrder();

    this.setState((prevState) => ({
      ...prevState,
      rowsPerPage: newRowsPerPage,
    }));

    await this.props.getPurchaseOrderList({
      paginate: newRowsPerPage,
      ...searchParamsPurchaseOrder,
    });
  }

  async componentDidMount() {
    const { rowsPerPage: paginate } = this.state;

    await this.props.getPurchaseOrderList({
      paginate,
      isShowMessage: false,
    });
  }

  render() {
    const { rowsPerPage } = this.state;

    const { t, classes, purchase } = this.props;

    const { purchaseOrderData } = purchase;

    let currPagination = rowsPerPage;

    let purchaseOrderDataList = [];

    if (!isEmpty(purchaseOrderData)) {
      if (!isEmpty(purchaseOrderData.data)) {
        if (Array.isArray(purchaseOrderData.data)) {
          purchaseOrderDataList = purchaseOrderData.data;
        }

        if (purchaseOrderData.per_page) {
          currPagination = Number(purchaseOrderData.per_page);
        }
      }
    }

    const tableHeaderPurchaseOrder = columnsTableHeaderPurchaseOrder(t);
    const isPurchaseOrderLoadingData =
      purchase.statusRequest === STATUS_REQUEST_LIST_PURCHASE_ORDER_PENDING;

    return (
      <Box className={classes.boxContainerTable}>
        <Paper elevation={0}>
          <TableContainer className={'tableContainerConstantHeightMDSizeConstantLayoutPrimary'}>
            <Table stickyHeader className='tableMain' aria-label='table-purchase-order-list'>
              <TableHeadSearchPurchaseOrder dataHeaders={tableHeaderPurchaseOrder} />

              <TableBody className='primaryTableBody'>
                {isPurchaseOrderLoadingData && (
                  <TableRowLoading
                    rowsAmount={currPagination}
                    columnsAmount={tableHeaderPurchaseOrder.length}
                  />
                )}

                {!isEmpty(purchaseOrderDataList) &&
                  !isPurchaseOrderLoadingData &&
                  purchaseOrderDataList.map((singlePurchaseOrder, index) => {
                    const baseUrl = strHelp.templateString(
                      REACT_APP_PURCHASE_PURCHASE_ORDER_DETAILS_TEMPLATE_URL,
                      singlePurchaseOrder,
                    );

                    return (
                      <TableRow
                        key={index}
                        hover
                        tabIndex={-1}
                        className={'hoverEntireRow'}
                        onDoubleClick={() => this.props.navigate(baseUrl)}
                      >
                        {tableHeaderPurchaseOrder.map((columnTableHeader, indexColumnHeader) => {
                          let valuePurchaseOrder = singlePurchaseOrder[columnTableHeader.name];

                          if (calculationColumns.includes(columnTableHeader.name)) {
                            valuePurchaseOrder = formatHelp.currencyFormatWithRegex(
                              Number(valuePurchaseOrder),
                              0,
                            );
                          }

                          if (columnTableHeader.name === 'transaction_date') {
                            valuePurchaseOrder = formatHelp.getReadableDateV2(valuePurchaseOrder);
                          }

                          if (columnTableHeader.name === 'purchase_order_no') {
                            return (
                              <TableCell key={indexColumnHeader}>
                                <TypographyLinkPrimary
                                  baseUrl={baseUrl}
                                  typographyValue={valuePurchaseOrder}
                                />
                              </TableCell>
                            );
                          }

                          if (
                            columnTableHeader.name === 'status' ||
                            columnTableHeader.name === 'invoice_status'
                          ) {
                            let purchaseStatus = purchaseOrderStatus(t);

                            if (columnTableHeader.name === 'invoice_status')
                              purchaseStatus = purchaseInvoiceStatus(t);

                            let colorChip = purchaseStatus[0].colorChip,
                              label = purchaseStatus[0].label;

                            const selectedDefaultPurchaseStatus = arrHelp.filterObjDataWithID(
                              purchaseStatus,
                              'value',
                              valuePurchaseOrder,
                            );

                            if (!isEmpty(selectedDefaultPurchaseStatus)) {
                              label = selectedDefaultPurchaseStatus.label;
                              colorChip = selectedDefaultPurchaseStatus.colorChip;
                            }

                            return (
                              <TableCell key={indexColumnHeader}>
                                <Chip size='small' color={colorChip} label={label} />
                              </TableCell>
                            );
                          }

                          return (
                            <TableCell key={indexColumnHeader} align={columnTableHeader.align}>
                              {valuePurchaseOrder}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {(isEmpty(purchaseOrderData) || isEmpty(purchaseOrderDataList)) &&
              !isPurchaseOrderLoadingData && (
                <BoxTableNotFound
                  textNotFound={t('dashboard.purchase.purchase-order.table.body-not-found')}
                  textButton={t('dashboard.purchase.purchase-order.button.add-purchase-order')}
                  handleClickedButton={() => {
                    this.props.navigate(REACT_APP_PURCHASE_PURCHASE_ORDER_CREATE_NEW_URL);
                  }}
                  isExistButton={true}
                />
              )}
          </TableContainer>
          <TablePaginationMain
            page={purchaseOrderData ? purchaseOrderData.current_page - 1 : 0}
            prev_page={purchaseOrderData ? purchaseOrderData.prev_page_url : null}
            next_page={purchaseOrderData ? purchaseOrderData.next_page_url : null}
            rowsPerPage={currPagination}
            isLoadingData={isPurchaseOrderLoadingData || isEmpty(purchaseOrderData)}
            labelRowsPerPage={t('label.table.pagination.primary.rows-per-page')}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    );
  }
}

PurchaseOrderList.defaultProps = {
  isUseChangedPageOnDetails: false,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    purchase: state.purchase,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPurchaseOrderList: (paramsGetPurchaseOrderList) =>
      dispatch(getListPurchaseOrder(paramsGetPurchaseOrderList)),
    setFailedMessage: (primaryMessage, secondaryMessage) =>
      dispatch(messageHelper.failedMessage(dispatch, primaryMessage, secondaryMessage, false)),
  };
};

const stylingPurchaseOrderList = withStyles(styles)(PurchaseOrderList);

const PurchaseOrderListTranslate = withTranslation()(stylingPurchaseOrderList);

const PurchaseOrderListWithRouter = withRouter(PurchaseOrderListTranslate);

const ConnectedPurchaseOrderList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PurchaseOrderListWithRouter);

export default ConnectedPurchaseOrderList;
