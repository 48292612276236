import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Autocomplete,
  FormControl,
  OutlinedInput,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { cloneDeep, isEmpty, isEqual, omit } from 'lodash';

import AutocompleteCustom from '../../../../components/custom/CustomAutocomplete/CustomAutocomplete';
import DateTimePicker from '../../../../components/date-time/DateTimePicker/DateTimePicker';
import InputAdornmentClear from '../../../../components/input-adorment/InputAdornmentClear/InputAdornmentClear';
import InputAdornmentLoading from '../../../../components/input-adorment/InputAdornmentLoading/InputAdornmentLoading';
import PopperAutoWidth from '../../../../components/popper/PopperAutoWidth/PopperAutoWidth';
import { contactList } from '../../../../store/contact/contact.action';
import { clearContactData } from '../../../../store/contact/contact.slice';
import messageHelper from '../../../../store/message/message.helper';
import { getListSalesOrder } from '../../../../store/sales/sales.action';
import {
  SLICE_NAME_SALES_ORDER_DATA,
  STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
  STATUS_REQUEST_LIST_SALES_ORDER_PENDING,
} from '../../../../utils/constants/store.constant';
import { initialSearchSalesOrder } from '../../../../utils/data/display/initial-search-sales-order';
import {
  columnsSearchFormatCustomer,
  columnsSearchFormatStatus,
  columnsSearchSalesOrderFormatDate,
} from '../../../../utils/data/display/table-list-sales-order';
import { salesOrderStatus } from '../../../../utils/data/label-value/sales-status';
import { DEFAULT_FORMAT_DATE_DAY_MONTH_YEAR_SLASH } from '../../../../utils/default/format.default';
import { TERSIER_DEFAULT_LIST_PARAMS } from '../../../../utils/default/params.default';
import { DEFAULT_DELAY_TIMEOUT_IN_MS } from '../../../../utils/default/timeout.default';
import autocompleteHelper from '../../../../utils/helpers/component/autocomplete.helpers';
import dateHelpers from '../../../../utils/helpers/date.helpers';
import formatHelp from '../../../../utils/helpers/format.helpers';
import objHelper from '../../../../utils/helpers/object.helper';

import { styles } from './TableHeadSearchSalesOrder.styles';

class TableHeadSearchSalesOrder extends Component {
  constructor(props) {
    super(props);

    const { t } = props;

    this.state = {
      searchParams: initialSearchSalesOrder(t),
      focusedInputName: null,
    };

    this.searchSalesOrderTimeoutID = null;
    this.searchContactDataTimeoutID = null;

    this.checkIsStatusOnlyStatusAll = this.checkIsStatusOnlyStatusAll.bind(this);

    this.handleClearSingleFilter = this.handleClearSingleFilter.bind(this);

    this.handleOnChangeInput = this.handleOnChangeInput.bind(this);
    this.handleChangeAutocomplete = this.handleChangeAutocomplete.bind(this);

    this.handleOnScrollAutocomplete = this.handleOnScrollAutocomplete.bind(this);

    this.handleOnOpenAutocomplete = this.handleOnOpenAutocomplete.bind(this);
    this.handleOnCloseAutocomplete = this.handleOnCloseAutocomplete.bind(this);
  }

  checkIsStatusOnlyStatusAll(checkingSearchParams) {
    const { status } = checkingSearchParams;

    if (isEmpty(status)) return false;

    return status.includes('all') && status.length === 1;
  }

  async handleClearSingleFilter(event) {
    event.stopPropagation();

    const { name } = event.currentTarget;

    const { searchParams: currSearchParams } = this.state;

    const newSearchParamsForState = {
      ...currSearchParams,
      [name]: '',
    };

    this.setState((prevState) => ({
      ...prevState,
      searchParams: newSearchParamsForState,
    }));

    const { sales } = this.props;
    const salesOrderData = sales[SLICE_NAME_SALES_ORDER_DATA];

    if (sales.statusRequest === STATUS_REQUEST_LIST_SALES_ORDER_PENDING) return;

    const paginate = salesOrderData
      ? salesOrderData.per_page
      : TERSIER_DEFAULT_LIST_PARAMS.paginate;

    let searchParamsForHitEndpoint = {
      ...cloneDeep(newSearchParamsForState),
      paginate,
    };

    searchParamsForHitEndpoint = objHelper.filteringExistedValue(searchParamsForHitEndpoint);

    const isStatusOnlyStatusAll = this.checkIsStatusOnlyStatusAll(searchParamsForHitEndpoint);

    if (isStatusOnlyStatusAll) {
      searchParamsForHitEndpoint = omit(searchParamsForHitEndpoint, 'status');
    }

    await this.props.getListSalesOrder(searchParamsForHitEndpoint);
  }

  async handleOnOpenAutocomplete(event, name) {
    event.preventDefault();

    if (name) {
      this.setState((prevState) => ({
        ...prevState,
        focusedInputName: name,
      }));
    }

    const { contact } = this.props;

    const isCanHitContactAPI =
      name === 'customer_name' &&
      contact.statusRequest !== STATUS_REQUEST_LIST_CONTACT_DATA_PENDING;

    if (isCanHitContactAPI) {
      await this.props.getListContact({
        ...TERSIER_DEFAULT_LIST_PARAMS,
        type: ['is_customer'],
      });
    }
  }

  async handleOnScrollAutocomplete(name) {
    if (name === 'customer_name') {
      const { contactData } = this.props.contact;

      if (isEmpty(contactData)) return;

      const {
        current_page,
        next_page_url,
        per_page: paginate,
        search_contact_name: contact_name,
      } = contactData;

      if (!next_page_url) return;

      await this.props.getListContact({
        page: current_page + 1,
        paginate,
        type: ['is_customer'],
        contact_name,
        isRewriteAll: false,
      });
    }
  }

  async handleOnCloseAutocomplete(event, name) {
    if (name === 'customer_name') {
      await this.props.clearContactData();
    }

    this.setState((prevState) => ({
      ...prevState,
      focusedInputName: '',
    }));
  }

  handleOnChangeDate(newValueDate) {
    const { searchParams: currSearchParams } = this.state;

    const isDateValueNotChanged = isEqual(
      newValueDate,
      objHelper.filterKeyObj(currSearchParams, [], columnsSearchSalesOrderFormatDate),
    );

    if (isDateValueNotChanged) return;

    if (!isEmpty(newValueDate)) {
      const { from_date, to_date } = newValueDate;

      if (
        !dateHelpers.isValidDateV2(new Date(from_date)) ||
        !dateHelpers.isValidDateV2(new Date(to_date))
      ) {
        const { t } = this.props;

        this.props.setFailedMessage(
          t('dashboard.sales.sales-order.message.invalid-date-format.primary'),
          t('dashboard.sales.sales-order.message.invalid-date-format.secondary'),
        );

        return;
      }
    }

    const newSearchParamsForState = {
      ...currSearchParams,
      ...newValueDate,
    };

    let searchParamsForHitEndpoint = objHelper.filteringExistedValue(
      cloneDeep(newSearchParamsForState),
    );

    this.setState((prevState) => ({
      ...prevState,
      searchParams: newSearchParamsForState,
    }));

    if (this.searchSalesOrderTimeoutID) {
      clearTimeout(this.searchSalesOrderTimeoutID);
    }

    this.searchSalesOrderTimeoutID = setTimeout(async () => {
      const { sales } = this.props;
      const salesOrderData = sales[SLICE_NAME_SALES_ORDER_DATA];

      const paginate = salesOrderData
        ? salesOrderData.per_page
        : TERSIER_DEFAULT_LIST_PARAMS.paginate;

      searchParamsForHitEndpoint = {
        ...searchParamsForHitEndpoint,
        paginate,
      };

      const isStatusOnlyStatusAll = this.checkIsStatusOnlyStatusAll(searchParamsForHitEndpoint);

      if (isStatusOnlyStatusAll) {
        searchParamsForHitEndpoint = omit(searchParamsForHitEndpoint, 'status');
      }

      return await this.props.getListSalesOrder(searchParamsForHitEndpoint);
    }, DEFAULT_DELAY_TIMEOUT_IN_MS);
  }

  handleOnChangeInput(event) {
    const { name, value } = event.target;

    const { searchParams: currSearchParams } = this.state;

    let newSearchParamsForState = {
      ...currSearchParams,
      [name]: value,
    };

    if (name === 'customer_name') {
      newSearchParamsForState = {
        ...newSearchParamsForState,
        customer_name_container: {
          customer_name: value,
          contact_name: value,
        },
      };
    }

    this.setState((prevState) => ({
      ...prevState,
      searchParams: newSearchParamsForState,
      focusedInputName: name,
    }));

    if (name === 'customer_name') {
      if (this.props.contact.statusRequest === STATUS_REQUEST_LIST_CONTACT_DATA_PENDING) return;
      if (this.searchContactDataTimeoutID) clearTimeout(this.searchContactDataTimeoutID);

      this.searchContactDataTimeoutID = setTimeout(async () => {
        return await this.props.getListContact({
          contact_name: value,
          type: ['is_customer'],
          paginate: TERSIER_DEFAULT_LIST_PARAMS.paginate,
        });
      }, DEFAULT_DELAY_TIMEOUT_IN_MS);

      return;
    }

    if (this.searchSalesOrderTimeoutID) clearTimeout(this.searchSalesOrderTimeoutID);

    this.searchSalesOrderTimeoutID = setTimeout(async () => {
      const { sales } = this.props;
      const salesOrderData = sales[SLICE_NAME_SALES_ORDER_DATA];

      const paginate = salesOrderData
        ? salesOrderData.per_page
        : TERSIER_DEFAULT_LIST_PARAMS.paginate;

      let searchParamsForHitEndpoint = {
        paginate,
        ...cloneDeep(newSearchParamsForState),
      };

      searchParamsForHitEndpoint = objHelper.filteringExistedValue(searchParamsForHitEndpoint);

      const isStatusOnlyStatusAll = this.checkIsStatusOnlyStatusAll(searchParamsForHitEndpoint);

      if (isStatusOnlyStatusAll) {
        searchParamsForHitEndpoint = omit(searchParamsForHitEndpoint, 'status');
      }

      return await this.props.getListSalesOrder(searchParamsForHitEndpoint);
    }, DEFAULT_DELAY_TIMEOUT_IN_MS);
  }

  /**
   *
   * @param { object } event built-in event data
   * @param { array } newValue selected new value autocomplete
   * @param { string } containerName name of container data, usually contain all data before ge data in secondary name
   * @param { string } secondaryName name container for handling store data for send to BE
   * @param { string } valueSecondaryKey name key of container data value
   * @returns
   *      set new state and hit purchase order again
   */
  async handleChangeAutocomplete(event, newValue, containerName, secondaryName, valueSecondaryKey) {
    event.preventDefault();

    const { searchParams: currSearchParams } = this.state;

    let newValueSecondaryContainer = newValue ? newValue[valueSecondaryKey] : newValue;

    if (containerName === 'statusContainer') {
      // check is current params is contained 'all' status
      // when current params contained and incoming data contained another status, then change into incoming data
      // check is new value is contained 'all' status
      // then contained change new value into 'all' status
      if (!isEmpty(currSearchParams[containerName])) {
        const isCurrentStatusContainValueAll =
          currSearchParams[containerName].some((nv) => nv.value === 'all') && newValue.length > 1;

        const isNewValueContainedValueAll = newValue.some((nv) => nv.value === 'all');

        if (isCurrentStatusContainValueAll) {
          newValue = newValue.filter((nv) => nv.value !== 'all');
        } else if (isNewValueContainedValueAll) {
          newValue = newValue.filter((nv) => nv.value === 'all');
        }
      }

      newValueSecondaryContainer = valueSecondaryKey
        ? newValue.map((value) => value[valueSecondaryKey])
        : newValue;
    }

    const newSearchParamsForState = {
      ...currSearchParams,
      [containerName]: newValue,
      [secondaryName]: newValueSecondaryContainer,
    };

    this.setState((prevState) => ({
      ...prevState,
      searchParams: newSearchParamsForState,
    }));

    const salesOrderData = this.props.sales[SLICE_NAME_SALES_ORDER_DATA];

    const paginate = salesOrderData
      ? salesOrderData.per_page
      : TERSIER_DEFAULT_LIST_PARAMS.paginate;

    // search params is clean version from params search state
    let searchParamsForHitEndpoint = {
      paginate,
      ...cloneDeep(newSearchParamsForState),
    };

    const isCanRemoveStatusKeyOnSearchParams =
      (Array.isArray(currSearchParams.status) &&
        currSearchParams.status.includes('all') &&
        containerName !== 'statusContainer') ||
      (!isEmpty(newValue) &&
        newValueSecondaryContainer.includes('all') &&
        containerName === 'statusContainer');

    // only when data status is 'all' status
    if (isCanRemoveStatusKeyOnSearchParams) {
      searchParamsForHitEndpoint = objHelper.filterKeyObj(searchParamsForHitEndpoint, ['status']);
    }

    return await this.props.getListSalesOrder(searchParamsForHitEndpoint);
  }

  render() {
    const { searchParams, focusedInputName } = this.state;

    const { statusContainer: currStatusContainer, customer_name_container } = searchParams;

    const { t, i18n, classes, sales, contact, columnsTableHeaderSearchSalesOrder } = this.props;

    const { language: currLanguage } = i18n;

    // get sales order status, value and label
    const salesOrderStatusList = salesOrderStatus(t);

    const isSalesOrderLoadingData = sales.statusRequest === STATUS_REQUEST_LIST_SALES_ORDER_PENDING;

    // set temporary status sales order for handle when user changed translation
    const valueOnCurrentSearchStatusContainer = currStatusContainer.map((status) => {
      return status.value;
    });

    const tempStatusSalesOrderStatus = salesOrderStatus(t).filter((status) => {
      if (valueOnCurrentSearchStatusContainer.includes(status.value)) {
        return status;
      }
    });

    const { contactData } = contact;

    let contactDataList = [];
    let paginateContactData = TERSIER_DEFAULT_LIST_PARAMS.paginate;

    if (!isEmpty(contactData)) {
      if (Array.isArray(contactData.data)) {
        contactDataList = contactData.data;
      }

      paginateContactData = contactData.per_page;
    }

    return (
      <TableHead className={classes.TableHeadMainContainer}>
        <TableRow>
          {!isEmpty(columnsTableHeaderSearchSalesOrder) &&
            columnsTableHeaderSearchSalesOrder.map((dataHeader, index) => {
              const nameInputDataHeader = dataHeader.name_input;

              if (!nameInputDataHeader) {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{
                      verticalAlign: 'top',
                      alignItems: 'flex-end',
                      width: dataHeader.width,
                      minWidth: dataHeader.minWidth,
                      maxWidth: dataHeader.maxWidth,
                    }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                  </TableCell>
                );
              }

              if (columnsSearchSalesOrderFormatDate.includes(nameInputDataHeader)) {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{ width: dataHeader.width }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                    <DateTimePicker
                      startDate={searchParams['from_date']}
                      endDate={searchParams['to_date']}
                      textCancel={t('button.cancel')}
                      textApply={t('button.apply')}
                      placeHolderInput={dataHeader.placeholder}
                      onSelectedChange={(value) => this.handleOnChangeDate(value)}
                      locale={currLanguage}
                      defaultKeyStartDate={'from_date'}
                      defaultKeyEndDate={'to_date'}
                      isDisabledInput={isSalesOrderLoadingData}
                      overrideFormatDate={DEFAULT_FORMAT_DATE_DAY_MONTH_YEAR_SLASH}
                    />
                  </TableCell>
                );
              }

              if (columnsSearchFormatStatus.includes(nameInputDataHeader)) {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{ width: dataHeader.width, maxWidth: dataHeader.maxWidth }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                    <FormControl fullWidth>
                      <Autocomplete
                        id='multiple-limit-tages-sales-status'
                        size='small'
                        className='minimumInput'
                        multiple
                        limitTags={1}
                        name={nameInputDataHeader}
                        value={tempStatusSalesOrderStatus}
                        options={salesOrderStatusList}
                        disabled={isSalesOrderLoadingData}
                        getOptionLabel={(option) =>
                          autocompleteHelper.getOptionLabel(option, 'label')
                        }
                        isOptionEqualToValue={(option, value) =>
                          autocompleteHelper.isOptionEqualToValue(option, value, 'value')
                        }
                        onChange={(event, newValue) => {
                          this.handleChangeAutocomplete(
                            event,
                            newValue,
                            'statusContainer',
                            'status',
                            'value',
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled={isSalesOrderLoadingData}
                            placeholder={
                              isEmpty(searchParams.statusContainer) ? dataHeader.placeholder : ''
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                );
              }

              if (columnsSearchFormatCustomer.includes(nameInputDataHeader)) {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{ width: dataHeader.width, maxWidth: dataHeader.maxWidth }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                    <FormControl fullWidth>
                      <AutocompleteCustom
                        id='customer_name'
                        name='customer_name'
                        size='small'
                        options={contactDataList}
                        value={customer_name_container}
                        autoHighlight
                        isPaginated={true}
                        totalOptions={autocompleteHelper.getOptionPaginatedAutocomplete(
                          contactDataList,
                          paginateContactData,
                          3,
                        )}
                        onOpen={(event) => this.handleOnOpenAutocomplete(event, 'customer_name')}
                        onClose={(event) => this.handleOnCloseAutocomplete(event, 'customer_name')}
                        onChange={(event, newValue) =>
                          this.handleChangeAutocomplete(
                            event,
                            newValue,
                            'customer_name_container',
                            'customer_name',
                            'contact_name',
                          )
                        }
                        getOptionLabel={(options) =>
                          autocompleteHelper.getInputValue(
                            options,
                            ['contact_code', 'contact_name'],
                            ['contact_name'],
                            formatHelp.contactCodeWithName,
                          )
                        }
                        isOptionEqualToValue={(option, value) =>
                          autocompleteHelper.isOptionEqualToValue(option, value, 'contact_id')
                        }
                        ListboxProps={{
                          onScroll: (event) => {
                            // i need scroll into bottom view when api was hitted
                            const { offsetHeight, scrollHeight, scrollTop } = event.target;

                            const isEndOfScroll = offsetHeight + scrollTop >= scrollHeight;

                            if (isEndOfScroll) {
                              this.handleOnScrollAutocomplete('customer_name');
                            }
                          },
                        }}
                        PopperComponent={PopperAutoWidth}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id='customer_name'
                            name='customer_name'
                            placeholder={dataHeader.placeholder}
                            onChange={(event) => this.handleOnChangeInput(event)}
                            readOnly={
                              contact.statusRequest === STATUS_REQUEST_LIST_CONTACT_DATA_PENDING
                            }
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <InputAdornmentLoading
                                  isLoading={
                                    focusedInputName === 'customer_name' &&
                                    contact.statusRequest ===
                                      STATUS_REQUEST_LIST_CONTACT_DATA_PENDING
                                  }
                                  defaultInputAdornment={params.InputProps.endAdornment}
                                />
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                );
              }

              return (
                <TableCell
                  key={index}
                  align={dataHeader.align}
                  style={{ width: dataHeader.width }}
                  className={classes.tableCellMain}
                >
                  <p>{dataHeader.label}</p>
                  <FormControl fullWidth>
                    <OutlinedInput
                      name={nameInputDataHeader}
                      autoFocus={nameInputDataHeader === focusedInputName}
                      readOnly={isSalesOrderLoadingData}
                      size='small'
                      placeholder={dataHeader.placeholder}
                      onChange={this.handleOnChangeInput}
                      value={searchParams[nameInputDataHeader]}
                      {...(searchParams[nameInputDataHeader] && {
                        endAdornment: (
                          <InputAdornmentClear
                            isShowIcon={searchParams[nameInputDataHeader]}
                            iconName={nameInputDataHeader}
                            isLoadingSearch={isSalesOrderLoadingData}
                            onClickClearButton={this.handleClearSingleFilter}
                          />
                        ),
                      })}
                    />
                  </FormControl>
                </TableCell>
              );
            })}
        </TableRow>
      </TableHead>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contact: state.contact,
    sales: state.sales,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListSalesOrder: (paramsGetListSalesOrder) =>
      dispatch(getListSalesOrder(paramsGetListSalesOrder)),
    getListContact: (paramsGetListContactData) => dispatch(contactList(paramsGetListContactData)),
    clearContactData: () => dispatch(clearContactData()),
    setFailedMessage: (primaryText, secondaryText) =>
      messageHelper.failedMessage(dispatch, primaryText, secondaryText, false),
  };
};

const stylingTableHeadSearchSalesOrder = withStyles(styles)(TableHeadSearchSalesOrder);

const TableHeadPurchaseOrderTranslate = withTranslation()(stylingTableHeadSearchSalesOrder);

const ConnectedTableHeadSearchSalesOrder = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TableHeadPurchaseOrderTranslate);

export default ConnectedTableHeadSearchSalesOrder;
