export const styles = (theme) => {
  return {
    boxContainerTable: {
      borderRadius: '8px',
      // marginTop: '18px',
      // boxShadow: theme.shadows[3],
    },
    paperContainerTable: {
      width: '100%',
      borderRadius: '8px',
      boxShadow: theme.shadows[3],
    },
    removeBoxShadowContainerTable: {
      boxShadow: 'none',
    },
    tableContainerCashBankList: {
      height: 'calc(100vh - 260px)',
      minHeight: '275px',
      borderRadius: '8px',
    },
    iconButtonContainer: {
      '&:hover': {
        backgroundColor: 'rgba(222, 235, 255, 0.6)',
        '& svg': {
          fill: '#0052CC',
        },
      },
    },
    // open: {
    // },
    reject: {
      fontWeight: 500,
      color: '#B42318',
      backgroundColor: '#FEF3F2',
    },
    closed: {
      fontWeight: 500,
      color: '#027A48',
      backgroundColor: '#ECFDF3',
    },
  };
};
