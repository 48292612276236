import axios from 'axios';
import { pick } from 'lodash';

import {
  REACT_APP_API_URL_MANAGEMENT_COMPANY,
  REACT_APP_API_URL_MANAGEMENT_COMPANY_LOGIN_SETUP,
  REACT_APP_API_URL_USER,
} from '../../../utils/configs/api.config';
import { authHeader } from '../../../utils/helpers/auth-header.helpers';
import {
  creatorAddServiceWithTemplateURL,
  creatorDeleteService,
  creatorUpdateService,
} from '../creator-services/creator-services.helper';

const listCompany = async ({ page = 1, paginate = 20 }) => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  return await axios({
    method: 'GET',
    url: `${REACT_APP_API_URL_USER}/company`,
    headers: {
      Authorization,
    },
    params: {
      page,
      paginate,
    },
  });
};

// add new company with form data
const addCompany = async (bodyAddCompany) => {
  const { Authorization } = authHeader();

  if (!Authorization) return null;

  return await axios.post(REACT_APP_API_URL_MANAGEMENT_COMPANY, bodyAddCompany, {
    headers: {
      Authorization,
      'Content-Type': 'multipart/form-data',
    },
  });
};

// service update company management
const updateCompany = async ({ company_id, formDataUpdateCompany, isCompanyLogoFile }) => {
  const { Authorization } = authHeader();

  if (!Authorization || !company_id) return null;

  if (!isCompanyLogoFile) {
    return await creatorUpdateService(
      company_id,
      REACT_APP_API_URL_MANAGEMENT_COMPANY,
      formDataUpdateCompany,
    );
  }

  const url = `${REACT_APP_API_URL_MANAGEMENT_COMPANY}/${company_id}`;

  return await axios.put(url, formDataUpdateCompany, {
    headers: {
      Authorization,
      'Content-Type': 'multipart/form-data',
    },
  });
};

const setupCompany = async (params) => {
  return await creatorAddServiceWithTemplateURL(
    REACT_APP_API_URL_MANAGEMENT_COMPANY_LOGIN_SETUP,
    params,
    [],
    (param) => pick(param, ['progress']),
  );
};

// deleting company you can pass minimum company_id on params
const deleteCompany = async (paramsDeleteCompany) => {
  const { company_id } = paramsDeleteCompany;

  return await creatorDeleteService(company_id, REACT_APP_API_URL_MANAGEMENT_COMPANY);
};

const managementCompanyServices = {
  listCompany,
  addCompany,
  updateCompany,
  setupCompany,
  deleteCompany,
};

export default managementCompanyServices;
