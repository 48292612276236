import * as React from 'react';
function SvgIconMenuReportIncomeStatements(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.857 68.464c0 .415-.192.75-.428.75-.237 0-.429-.335-.429-.75 0-.414.192-.75.429-.75.236 0 .428.336.428.75zM6.857 67.714h58.286c.114 0 .222.08.303.22.08.14.125.331.125.53 0 .2-.045.39-.125.53-.08.141-.19.22-.303.22H6.857c-.114 0-.223-.079-.303-.22a1.094 1.094 0 01-.125-.53c0-.199.045-.39.125-.53s.19-.22.303-.22zM2.571 67.714h2.143c.114 0 .223.08.303.22.08.14.126.331.126.53 0 .2-.045.39-.126.53-.08.141-.19.22-.303.22H2.571c-.113 0-.222-.079-.303-.22a1.094 1.094 0 01-.125-.53c0-.199.045-.39.125-.53s.19-.22.303-.22zM69.429 67.714h-2.143c-.114 0-.223.08-.303.22-.08.14-.126.331-.126.53 0 .2.045.39.126.53.08.141.19.22.303.22h2.143c.113 0 .222-.079.303-.22.08-.14.125-.33.125-.53 0-.199-.045-.39-.125-.53s-.19-.22-.303-.22zM72 68.464c0 .415-.192.75-.429.75-.236 0-.428-.335-.428-.75 0-.414.192-.75.428-.75.237 0 .429.336.429.75zM45.297 24.75H18.339a.902.902 0 00-.657.289 1.022 1.022 0 00-.272.7c0 .262.097.513.272.699.174.185.41.29.657.29h26.958a.902.902 0 00.657-.29c.175-.186.273-.437.273-.7 0-.262-.098-.514-.273-.7a.902.902 0 00-.657-.289zM18.34 31.673h20.45a.902.902 0 00.657-.29c.175-.185.272-.437.272-.699 0-.262-.097-.514-.272-.7a.902.902 0 00-.657-.29H18.34a.902.902 0 00-.658.29 1.022 1.022 0 00-.272.7c0 .262.097.514.272.7.174.185.41.29.657.29z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.34 34.64H41v1.979H18.34a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.7c0-.261.097-.513.272-.699a.902.902 0 01.657-.29z'
        fill='#000'
      />
      <path
        d='M45.297 36.619a.902.902 0 00.657-.29c.175-.185.273-.437.273-.7 0-.261-.098-.513-.273-.699a.902.902 0 00-.657-.29H41v1.979h4.297zM18.34 41.565h20.45a.902.902 0 00.657-.29c.175-.185.272-.437.272-.7 0-.262-.097-.513-.272-.699a.902.902 0 00-.657-.29H18.34a.902.902 0 00-.658.29 1.022 1.022 0 00-.272.7c0 .262.097.514.272.699.174.186.41.29.657.29z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.34 44.532H36v1.979H18.34a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.7c0-.262.097-.514.272-.7a.902.902 0 01.657-.289zM34.5 51.456H18.34a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.699c0-.262.097-.514.272-.7a.902.902 0 01.657-.29H34.5v1.98zM18.34 54.424H33v1.978H18.34a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.7c0-.261.097-.513.272-.699a.902.902 0 01.657-.29zM18.34 59.37H33v1.978H18.34a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.7c0-.262.097-.513.272-.699a.902.902 0 01.657-.29z'
        fill='#000'
      />
      <path
        d='M45.297 14.858H18.339a.902.902 0 00-.657.29 1.022 1.022 0 00-.272.699c0 .262.097.514.272.7.174.185.41.289.657.289h26.958a.902.902 0 00.657-.29c.175-.185.273-.437.273-.7 0-.261-.098-.513-.273-.699a.902.902 0 00-.657-.29zM18.34 21.782h20.45a.902.902 0 00.657-.29c.175-.186.272-.437.272-.7 0-.262-.097-.513-.272-.699a.902.902 0 00-.657-.29H18.34a.902.902 0 00-.658.29 1.022 1.022 0 00-.272.7c0 .262.097.514.272.699.174.185.41.29.657.29z'
        fill='#000'
      />
      <path
        d='M35 69v-2.34H12.263a2.807 2.807 0 01-1.86-.685c-.494-.439-.771-1.034-.771-1.654V11.679c0-.62.277-1.215.77-1.654a2.807 2.807 0 011.861-.685h39.474c.698 0 1.367.246 1.86.685.494.439.771 1.034.771 1.654V40H57V11.68c-.002-1.241-.557-2.43-1.543-3.308-.987-.877-2.325-1.37-3.72-1.372H12.263c-1.395.001-2.733.495-3.72 1.372C7.557 9.25 7.002 10.44 7 11.68v52.642c.002 1.24.557 2.43 1.543 3.307.987.877 2.325 1.37 3.72 1.372H35z'
        fill='#000'
      />
      <path
        d='M69.439 64.905h-.673c0-.236-.068-.466-.196-.655a.53.53 0 00-.086-.098s-1.36-1.226-1.46-1.287a.589.589 0 00.02-.142V43.636c0-.434-.157-.85-.434-1.156a1.412 1.412 0 00-1.045-.48H49.22c.56.072 1.083.352 1.482.792l.27.299h14.593c.13 0 .256.057.349.16.092.102.144.24.144.385v19.087h-3.696v-15.27c0-.289-.104-.566-.289-.77a.94.94 0 00-.696-.32h-1.971a.94.94 0 00-.697.32 1.154 1.154 0 00-.289.77v15.27h-.985V50.726c0-.29-.104-.567-.29-.771a.94.94 0 00-.696-.32h-1.97a.941.941 0 00-.697.32 1.153 1.153 0 00-.29.77v11.998h-.985v-7.635c0-.289-.104-.566-.289-.77a.941.941 0 00-.696-.32H49.55a.94.94 0 00-.697.32 1.154 1.154 0 00-.289.77v7.635h-.985v-4.362c0-.29-.105-.567-.29-.771a.94.94 0 00-.696-.32h-1.97a.94.94 0 00-.697.32 1.154 1.154 0 00-.29.77v4.363h-3.695V43.636c0-.145.052-.283.144-.385a.471.471 0 01.349-.16h1.288l.27-.3c.4-.44.923-.718 1.483-.791h-3.041c-.392 0-.768.173-1.045.48a1.731 1.731 0 00-.434 1.156v19.087c.001.048.007.096.02.142-.1.061-1.46 1.287-1.46 1.287a.53.53 0 00-.086.098 1.164 1.164 0 00-.196.655h-.673a.451.451 0 00-.209.019.486.486 0 00-.183.113.55.55 0 00-.125.186.6.6 0 000 .454c.03.071.072.135.125.186.053.052.116.09.183.113.068.023.14.03.21.02h32.877c.07.01.141.003.209-.02a.485.485 0 00.183-.113.551.551 0 00.125-.186.601.601 0 000-.454.55.55 0 00-.125-.186.486.486 0 00-.183-.113.451.451 0 00-.21-.02zM59.406 47.453h1.97v15.27h-1.97v-15.27zm-4.928 3.273h1.971v11.997h-1.97V50.726zm-4.927 4.362h1.97v7.635h-1.97v-7.635zm-4.928 3.273h1.971v4.362h-1.97v-4.362zm-6.38 6.543s1.182-1.053 1.207-1.09h27.1c.025.037 1.207 1.09 1.207 1.09H38.243z'
        fill='#000'
      />
      <path
        d='M46.5 51a5.5 5.5 0 10-5.5-5.5 5.506 5.506 0 005.5 5.5zm0-9.9a4.4 4.4 0 110 8.801 4.4 4.4 0 010-8.801z'
        fill='#000'
      />
      <path
        d='M46.5 47a.5.5 0 01-.5-.5.5.5 0 00-1 0 1.5 1.5 0 001 1.408v.592a.5.5 0 001 0v-.592A1.496 1.496 0 0046.5 45a.5.5 0 11.5-.5.5.5 0 001 0 1.5 1.5 0 00-1-1.408V42.5a.5.5 0 00-1 0v.592A1.496 1.496 0 0046.5 46a.5.5 0 010 1z'
        fill='#000'
      />
    </svg>
  );
}
export default SvgIconMenuReportIncomeStatements;
