import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF,
  DEFAULT_KEY_NAME_UUID_API_REF,
} from '../../default/object-keyname.default';
import arrHelp from '../../helpers/array.helpers';
import formatHelp from '../../helpers/format.helpers';
import objHelper from '../../helpers/object.helper';

import { getUUIDattachmentRequest } from './initial-add-attachment';
import {
  allowKeysDestinationAccountData,
  allowKeysExpenseData,
  expenseType,
  formatCalculationDestinationAccountData,
  initialDestinationAccountData,
  initialDestinationAccountValidation,
  initialExpenseData,
  initialExpenseValidation,
} from './initial-data-expense';

/**
 *
 * @param { number } amount                 amount data generate
 * @param { string } customDataKey          name key return for data
 * @param { object } companyUserData        data user company
 * @param { string } customValidationKey    name key return for validation
 * @returns
 *     generated data and validation for expense
 */
export const generateDataNValidationAddExpense = ({
  amount = 1,
  companyUserData = {},
  customDataKey = 'expenseData',
  customValidationKey = 'expenseValidation',
}) => {
  const usedID = arrHelp.generateArrWithFunc(amount, uuidV4);

  const addExpenseData = usedID.map((id) => {
    return {
      ...initialExpenseData('', id),
      accounting_period: companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF],
    };
  });

  const addExpenseValidation = usedID.map((id) => {
    return initialExpenseValidation('', id);
  });

  return {
    [customDataKey]: addExpenseData,
    [customValidationKey]: addExpenseValidation,
  };
};

/**
 *
 * @param { number } amount amount data and validation for generate
 * @param { string } customDataKey name key for returning data
 * @param { string } customValidationKey name key for validation data
 * @returns
 *      generated data and validation for adding destination account on expense
 */
export const generateDataNValidationAddDestinationAccount = (
  amount = 2,
  customDataKey = 'destinationAccountData',
  customValidationKey = 'destinationAccountValidation',
) => {
  const usedID = arrHelp.generateArrWithFunc(amount, uuidV4);

  const addDestinationAccountData = usedID.map((id) => {
    return initialDestinationAccountData('', id);
  });

  const addDestinationAccountValidation = usedID.map((id) => {
    return initialDestinationAccountValidation('', id);
  });

  return {
    [customDataKey]: addDestinationAccountData,
    [customValidationKey]: addDestinationAccountValidation,
  };
};

/**
 *
 * @param { array } expenseData contain all data expense
 * @param { array } destinationAccountData contain all data for destination account
 * @returns
 *      generalized data for expense add
 */
export const generalizeDataAddExpense = (expenseData, destinationAccountData) => {
  if (Array.isArray(expenseData)) {
    expenseData = expenseData.map((expense) => {
      const {
        // supplier_id_container,
        transaction_date,
        discount_percentage,
        discount_nominal,
        discount_type,
        discount_account_id,
        type_module_id,
        attachment,
      } = expense;

      // const {
      //     contact_name: supplier_name,
      // } = supplier_id_container

      let generalizedDataExpense = objHelper.filterKeyObj(
        cloneDeep(expense),
        [],
        allowKeysExpenseData,
      );

      generalizedDataExpense = {
        ...generalizedDataExpense,
        // supplier_name,
        transaction_date: transaction_date ? moment(transaction_date).toISOString(true) : null,
        discount_percentage: discount_percentage == 0 ? '' : discount_percentage,
        discount_nominal: discount_nominal == 0 ? '' : discount_nominal,
        discount_type: discount_nominal == 0 || discount_percentage == 0 ? '' : discount_type,
        discount_account_id:
          discount_nominal == 0 || discount_percentage == 0 || isEmpty(discount_type)
            ? ''
            : discount_account_id,
        type: type_module_id ? expenseType.linked : expenseType.detached,
        [DEFAULT_KEY_NAME_UUID_API_REF]: getUUIDattachmentRequest({
          attachment,
        }),
      };

      return (generalizedDataExpense = objHelper.filteringExistedValue(generalizedDataExpense));
    });
  }

  if (Array.isArray(destinationAccountData)) {
    destinationAccountData = destinationAccountData.map((destinationAccount) => {
      const { amount } = destinationAccount;

      let generalizedDataDestinationAccount = objHelper.filterKeyObj(
        destinationAccount,
        [],
        allowKeysDestinationAccountData,
      );

      generalizedDataDestinationAccount = objHelper.changeFormatValueV2(
        generalizedDataDestinationAccount,
        formatCalculationDestinationAccountData,
        '',
        formatHelp.reverseCurrencyFormatWithRegex,
      );

      return {
        ...generalizedDataDestinationAccount,
        amount: amount ? amount : 0,
      };
    });

    destinationAccountData = arrHelp.filteringExistedObjValueWithUniqueKey(
      destinationAccountData,
      'account_destination_id',
    );
  }

  return {
    ...expenseData[0],
    account_destination: destinationAccountData,
  };
};
