import * as React from 'react';
function SvgIconMenuReportReportList(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.857 68.464c0 .415-.192.75-.428.75-.237 0-.429-.335-.429-.75 0-.414.192-.75.429-.75.236 0 .428.336.428.75zM6.857 67.714h58.286c.114 0 .222.08.303.22.08.14.125.331.125.53 0 .2-.045.39-.125.53-.08.141-.19.22-.303.22H6.857c-.114 0-.223-.079-.303-.22a1.094 1.094 0 01-.125-.53c0-.199.045-.39.125-.53s.19-.22.303-.22zM2.571 67.714h2.143c.114 0 .223.08.303.22.08.14.126.331.126.53 0 .2-.045.39-.126.53-.08.141-.19.22-.303.22H2.571c-.113 0-.222-.079-.303-.22a1.094 1.094 0 01-.125-.53c0-.199.045-.39.125-.53s.19-.22.303-.22zM69.429 67.714h-2.143c-.114 0-.223.08-.303.22-.08.14-.126.331-.126.53 0 .2.045.39.126.53.08.141.19.22.303.22h2.143c.113 0 .222-.079.303-.22.08-.14.125-.33.125-.53 0-.199-.045-.39-.125-.53s-.19-.22-.303-.22zM72 68.464c0 .415-.192.75-.429.75-.236 0-.428-.335-.428-.75 0-.414.192-.75.428-.75.237 0 .429.336.429.75zM45.297 24.75H18.339a.902.902 0 00-.657.289 1.022 1.022 0 00-.272.7c0 .262.097.513.272.699.174.185.41.29.657.29h26.958a.902.902 0 00.657-.29c.175-.186.273-.437.273-.7 0-.262-.098-.514-.273-.7a.902.902 0 00-.657-.289zM18.34 31.673h20.45a.902.902 0 00.657-.29c.175-.185.272-.437.272-.699 0-.262-.097-.514-.272-.7a.902.902 0 00-.657-.29H18.34a.902.902 0 00-.658.29 1.022 1.022 0 00-.272.7c0 .262.097.514.272.7.174.185.41.29.657.29z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.34 34.64H41v1.979H18.34a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.7c0-.261.097-.513.272-.699a.902.902 0 01.657-.29z'
        fill='#000'
      />
      <path
        d='M45.297 36.619a.902.902 0 00.657-.29c.175-.185.273-.437.273-.7 0-.261-.098-.513-.273-.699a.902.902 0 00-.657-.29H41v1.979h4.297zM18.34 41.565h20.45a.902.902 0 00.657-.29c.175-.185.272-.437.272-.7 0-.262-.097-.513-.272-.699a.902.902 0 00-.657-.29H18.34a.902.902 0 00-.658.29 1.022 1.022 0 00-.272.7c0 .262.097.514.272.699.174.186.41.29.657.29z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.34 44.532H36v1.979H18.34a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.7c0-.262.097-.514.272-.7a.902.902 0 01.657-.289zM34.5 51.456H18.34a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.699c0-.262.097-.514.272-.7a.902.902 0 01.657-.29H34.5v1.98zM18.34 54.424H33v1.978H18.34a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.7c0-.261.097-.513.272-.699a.902.902 0 01.657-.29zM18.34 59.37H33v1.978H18.34a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.7c0-.262.097-.513.272-.699a.902.902 0 01.657-.29z'
        fill='#000'
      />
      <path
        d='M45.297 14.858H18.339a.902.902 0 00-.657.29 1.022 1.022 0 00-.272.699c0 .262.097.514.272.7.174.185.41.289.657.289h26.958a.902.902 0 00.657-.29c.175-.185.273-.437.273-.7 0-.261-.098-.513-.273-.699a.902.902 0 00-.657-.29zM18.34 21.782h20.45a.902.902 0 00.657-.29c.175-.186.272-.437.272-.7 0-.262-.097-.513-.272-.699a.902.902 0 00-.657-.29H18.34a.902.902 0 00-.658.29 1.022 1.022 0 00-.272.7c0 .262.097.514.272.699.174.185.41.29.657.29z'
        fill='#000'
      />
      <path
        d='M35 69v-2.34H12.263a2.807 2.807 0 01-1.86-.685c-.494-.439-.771-1.034-.771-1.654V11.679c0-.62.277-1.215.77-1.654a2.807 2.807 0 011.861-.685h39.474c.698 0 1.367.246 1.86.685.494.439.771 1.034.771 1.654V35H57V11.68c-.002-1.241-.557-2.43-1.543-3.308-.987-.877-2.325-1.37-3.72-1.372H12.263c-1.395.001-2.733.495-3.72 1.372C7.557 9.25 7.002 10.44 7 11.68v52.642c.002 1.24.557 2.43 1.543 3.307.987.877 2.325 1.37 3.72 1.372H35z'
        fill='#000'
      />
      <path
        d='M62.77 44.603c-.779 0-1.553.133-2.292.396-.434-1.968-1.473-3.725-2.952-4.993-1.478-1.268-3.312-1.973-5.21-2.005-1.898-.032-3.752.613-5.267 1.83-1.515 1.218-2.604 2.94-3.096 4.893-1.777.34-3.37 1.374-4.466 2.9-1.097 1.528-1.617 3.436-1.46 5.351.159 1.915.983 3.697 2.313 4.997 1.33 1.3 3.068 2.024 4.873 2.028h4.648c.274 0 .536-.116.73-.322.194-.206.302-.486.302-.778 0-.291-.108-.571-.302-.777a1.002 1.002 0 00-.73-.323h-4.648a4.883 4.883 0 01-1.99-.349 5.127 5.127 0 01-1.711-1.133c-1-.997-1.588-2.377-1.633-3.835-.045-1.459.455-2.876 1.392-3.942.936-1.065 2.232-1.69 3.601-1.739.232-.016.452-.115.624-.28.172-.166.287-.389.326-.633.258-1.674 1.088-3.188 2.332-4.25 1.243-1.062 2.81-1.596 4.402-1.5 1.591.095 3.093.814 4.216 2.018 1.123 1.204 1.788 2.808 1.867 4.502.01.188.066.369.161.527.095.158.227.288.382.377a.983.983 0 001.006-.018 4.883 4.883 0 014.562-.326c.733.322 1.387.82 1.91 1.454a5.621 5.621 0 011.107 2.204c.206.816.232 1.67.077 2.499a5.674 5.674 0 01-.97 2.277 5.246 5.246 0 01-1.816 1.583 4.906 4.906 0 01-2.288.564h-4.647c-.274 0-.537.116-.73.323a1.137 1.137 0 00-.303.777c0 .292.109.572.303.778.193.206.456.322.73.322h4.648c1.917 0 3.756-.811 5.111-2.255C69.238 56.301 70 54.343 70 52.301c0-2.041-.762-4-2.118-5.443-1.355-1.444-3.194-2.255-5.112-2.255z'
        fill='#000'
      />
      <path
        d='M56.894 61.192l-1.696 1.285v-8.344c0-.3-.13-.589-.365-.801a1.313 1.313 0 00-.881-.332c-.33 0-.648.12-.882.332-.234.212-.365.5-.365.801v8.344l-1.695-1.285a1.338 1.338 0 00-.898-.23c-.321.031-.616.175-.823.4-.206.225-.309.515-.286.807.023.293.17.566.41.762l3.74 2.832.057.034.081.051.118.057.088.034.137.04h.08c.155.028.314.028.468 0h.081l.138-.04.087-.034.118-.057.081-.05.056-.035 3.74-2.832c.139-.091.255-.207.343-.34.087-.134.143-.282.164-.436.022-.154.008-.31-.04-.46a1.103 1.103 0 00-.238-.407 1.233 1.233 0 00-.396-.287 1.344 1.344 0 00-.991-.052c-.16.053-.307.136-.431.243z'
        fill='#000'
      />
    </svg>
  );
}
export default SvgIconMenuReportReportList;
