import { LibraryBooks } from '@mui/icons-material';

import { PERMISSION_SALES_ORDER_DETAIL } from '../../constants/permissions.constant';
import { DEFAULT_STATUS_PAGE_DETAIL_SALES_ORDER } from '../../default/status-page.default';

/**
 *
 * @param { function } t translation function
 * @returns
 *      tooltip of list sales order that handle permission also
 */
export const tooltipListSalesOrder = (t) => {
  return [
    {
      name: DEFAULT_STATUS_PAGE_DETAIL_SALES_ORDER,
      title: t('base.tooltip.detail'),
      permission: PERMISSION_SALES_ORDER_DETAIL,
    },
  ];
};

export const iconListSalesOrderData = [LibraryBooks];
