// column account expense
export const columnsTableHeaderAccountInExpense = (t) => {
  return [
    {
      multipleNames: [
        {
          name: 'account_name',
        },
        {
          name: 'description',
        },
      ],
      isUseMultipleNames: true,
      label: t('table.header-column.destination-account'),
      width: 600,
    },
    {
      name: 'amount',
      label: t('table.header-column.amount'),
      width: 200,
      align: 'right',
    },
  ];
};
