import { isEqual } from 'lodash';

import { getAccountListAlternative } from '../../../store/account/account.action';
import { clearDataAccountSlice } from '../../../store/account/account.slice';
import {
  NAME_STORE,
  SLICE_NAME_ACCOUNT_DATA_ALTERNATIVE,
  STATUS_REQUEST_LIST_ACCOUNT_DATA_ALTERNATIVE_PENDING,
} from '../../constants/store.constant';
import {
  ACCOUNT_CATEGORY,
  DEFAULT_ACCOUNT_CATEGORY_HARGA_POKOK_PENJUALAN,
} from '../../default/account-category.default';
import { ACCOUNT_CATEGORY_NAME_OTHER_INCOME } from '../../default/account-category-name.default';
import { DEFAULT_GRID_ACTION_REMOVE_TYPE } from '../../default/grid-type.default';
import {
  DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
  DEFAULT_INPUT_TYPE_CALCULATION_NUMBER_INPUT,
  DEFAULT_INPUT_TYPE_COMMON_INPUT,
} from '../../default/input-type.default';
import {
  DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
  DEFAULT_KEY_NAME_ACCOUNT_NAME_API_REF,
  DEFAULT_KEY_NAME_AMOUNT_REF_API,
  DEFAULT_KEY_NAME_CATEGORY_NAME_API_REF,
  DEFAULT_KEY_NAME_DESTINATION_ACCOUNT_ID_API_REF,
  DEFAULT_KEY_NAME_DESTINATION_ACCOUNT_ID_CONTAINER,
  DEFAULT_KEY_NAME_STATUS_REQUEST,
} from '../../default/object-keyname.default';
import autocompleteHelper from '../../helpers/component/autocomplete.helpers';
import formatHelp from '../../helpers/format.helpers';

// grid header destination account on expense add update
export const gridHeaderDestinationAccountOnExpense = [
  {
    label: 'table.header-column.destination-account',
    placeholder: 'placeholder.select-account',
    isUseMultipleNames: true,
    width: 250,
    minWidth: 150,
    gridProps: {
      xs: 3.5,
      display: 'flex',
      justifyContent: 'start',
    },
    isInput: true,
    storeName: NAME_STORE.ACCOUNT,
    childrenStoreName: SLICE_NAME_ACCOUNT_DATA_ALTERNATIVE,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
    keyInputName: DEFAULT_KEY_NAME_DESTINATION_ACCOUNT_ID_API_REF,
    keyInputContainerName: DEFAULT_KEY_NAME_DESTINATION_ACCOUNT_ID_CONTAINER,
    statusRequestListPending: STATUS_REQUEST_LIST_ACCOUNT_DATA_ALTERNATIVE_PENDING,
    inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
    autocompleteProps: {
      fetchAction: getAccountListAlternative,
      additionalParamsForAction: {
        [DEFAULT_KEY_NAME_CATEGORY_NAME_API_REF]: [
          ACCOUNT_CATEGORY.EXPENSE,
          ACCOUNT_CATEGORY.OTHER_EXPENSE,
          DEFAULT_ACCOUNT_CATEGORY_HARGA_POKOK_PENJUALAN,
          ACCOUNT_CATEGORY_NAME_OTHER_INCOME,
        ],
      },
      removeDataAction: clearDataAccountSlice,
      additionalParamsForScrollAction: {
        [DEFAULT_KEY_NAME_CATEGORY_NAME_API_REF]: [
          ACCOUNT_CATEGORY.EXPENSE,
          ACCOUNT_CATEGORY.OTHER_EXPENSE,
          DEFAULT_ACCOUNT_CATEGORY_HARGA_POKOK_PENJUALAN,
          ACCOUNT_CATEGORY_NAME_OTHER_INCOME,
        ],
        isRewriteAll: false,
      },
      getOptionLabel: (options) =>
        autocompleteHelper.getInputValue(
          options,
          ['account_name', 'account_code'],
          ['account_name'],
          formatHelp.formatAccountNameWithAccountCode,
        ),
      isOptionEqualToValue: (option, value) => isEqual(option, value),
      isUseFetchOnInput: true,
      keyNameFetchInput: DEFAULT_KEY_NAME_ACCOUNT_NAME_API_REF,
    },
  },
  {
    label: 'table.header-column.description',
    name: 'description',
    placeholder: 'label.description',
    inputName: 'description',
    keyInputName: 'description',
    isInput: true,
    inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
    gridProps: {
      xs: 6,
      display: 'flex',
      justifyContent: 'start',
    },
  },
  {
    name: 'amount',
    label: 'table.header-column.amount',
    placeholder: 'placeholder.calculation-number-default',
    align: 'right',
    width: 75,
    minWidth: 75,
    gridProps: {
      xs: 2,
      display: 'flex',
      justifyContent: 'end',
    },
    isInput: true,
    inputType: DEFAULT_INPUT_TYPE_CALCULATION_NUMBER_INPUT,
    keyInputName: DEFAULT_KEY_NAME_AMOUNT_REF_API,
  },
  {
    name: DEFAULT_GRID_ACTION_REMOVE_TYPE,
    label: '',
    align: 'right',
    width: 25,
    gridProps: {
      xs: 0.75,
    },
    isButton: true,
  },
];
