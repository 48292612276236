import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';

import BoxTableNotFound from '../../../components/box/BoxTableNotFound/BoxTableNotFound';
import TablePaginationMain from '../../../components/table-pagination/TablePaginationMain/TablePaginationMain';
import TableRowLoading from '../../../components/table-row/TableRowLoading/TableRowLoading';
import TypographyLinkPrimary from '../../../components/typography/TypographyLinkPrimary/TypographyLinkPrimary';
import { withRouter } from '../../../components/withRouter/withRouter';
import { decryptData } from '../../../services/modules/Crypto/Crypto.service';
import messageHelper from '../../../store/message/message.helper';
import { getListPurchaseInvoice } from '../../../store/purchase/purchase.action';
import {
  REACT_APP_PURCHASE_PURCHASE_INVOICE_CREATE_NEW_URL,
  REACT_APP_PURCHASE_PURCHASE_INVOICE_DETAILS_TEMPLATE_URL,
} from '../../../utils/configs/url.config';
import { STATUS_REQUEST_LIST_PURCHASE_INVOICE_PENDING } from '../../../utils/constants/store.constant';
import {
  columnsCalculationNumberPurchaseInvoice,
  columnsTableHeaderPurchaseInvoice,
} from '../../../utils/data/display/table-list-purchase-invoice';
import { purchaseInvoiceStatus } from '../../../utils/data/label-value/purchase-status';
import {
  DEFAULT_PURCHASE_INVOICE_LIST_PARAMS,
  DEFAULT_PURCHASE_INVOICE_SEARCH_FE_PARAMS,
  TERSIER_DEFAULT_LIST_PARAMS,
} from '../../../utils/default/params.default';
import arrHelp from '../../../utils/helpers/array.helpers';
import dateHelpers from '../../../utils/helpers/date.helpers';
import formatHelp from '../../../utils/helpers/format.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import strHelp from '../../../utils/helpers/string.helpers';

import TableHeadSearchPurchaseInvoice from './TableHeadSearchPurchaseInvoice/TableHeadSearchPurchaseInvoice';
import { styles } from './PurchaseInvoiceList.styles';

class PurchaseInvoiceList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage: TERSIER_DEFAULT_LIST_PARAMS.paginate,
    };

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

    this.handleClickActionPurchaseInvoice = this.handleClickActionPurchaseInvoice.bind(this);
  }

  handleClickActionPurchaseInvoice(event) {
    const { name: newStatusPage, value } = event.currentTarget;

    const newSelectedPurchaseInvoice = JSON.stringify(decryptData(value));

    this.props.handleChangeStatusPage(newStatusPage, newSelectedPurchaseInvoice);
  }

  async handleChangePage(event, newPage) {
    event.preventDefault();

    const { purchase } = this.props;
    const { purchaseInvoiceData } = purchase;

    if (!purchaseInvoiceData) return;

    const { next_page_url, current_page } = purchaseInvoiceData;

    const isCannotNextPage = !next_page_url && current_page < newPage + 1;

    if (isCannotNextPage) return;

    let searchParams = {
      page: Number(newPage) + 1,
    };

    let currentSearchParams = objHelper.filterKeyObj(
      purchaseInvoiceData,
      [],
      DEFAULT_PURCHASE_INVOICE_SEARCH_FE_PARAMS,
    );

    if (currentSearchParams) {
      // removing previx on key search and filter by existed params
      currentSearchParams = objHelper.changeSuffixKey(currentSearchParams, 'search_', true);

      const { per_page: paginate } = currentSearchParams;

      currentSearchParams = objHelper.filterKeyObj(
        currentSearchParams,
        [],
        DEFAULT_PURCHASE_INVOICE_LIST_PARAMS,
      );

      searchParams = {
        ...searchParams,
        ...currentSearchParams,
        paginate,
      };
    }

    await this.props.getListPurchaseInvoice(searchParams);
  }

  async handleChangeRowsPerPage(event) {
    const { value: newRowsPerPage } = event.target;

    const { t, purchase } = this.props;

    const { purchaseInvoiceData } = purchase;

    if (isEmpty(purchaseInvoiceData)) return;

    const { next_page_url, current_page, per_page: paginate } = purchaseInvoiceData;

    if (isEqual(paginate, newRowsPerPage)) return;

    const isNotExistedNextData = !next_page_url && paginate < newRowsPerPage && current_page === 1;

    if (isNotExistedNextData) {
      this.props.setFailedMessage(
        t('dashboard.purchase.purchase-invoice.message.max-data.primary'),
        t('dashboard.purchase.purchase-invoice.message.max-data.secondary'),
      );

      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      rowsPerPage: newRowsPerPage,
    }));

    let searchParams = {
      current_page,
      paginate: newRowsPerPage,
    };

    let currentSearchParams = objHelper.filterKeyObj(
      purchaseInvoiceData,
      [],
      DEFAULT_PURCHASE_INVOICE_SEARCH_FE_PARAMS,
    );

    if (currentSearchParams) {
      // removing previx on key search and filtering not existed on params
      currentSearchParams = objHelper.changeSuffixKey(currentSearchParams, 'search_', true);

      currentSearchParams = objHelper.filterKeyObj(
        currentSearchParams,
        [],
        DEFAULT_PURCHASE_INVOICE_LIST_PARAMS,
      );

      searchParams = {
        ...searchParams,
        ...currentSearchParams,
      };
    }

    await this.props.getListPurchaseInvoice(searchParams);
  }

  async componentDidMount() {
    const { rowsPerPage: paginate } = this.state;

    await this.props.getListPurchaseInvoice({
      paginate,
      isShowMessage: false,
    });
  }

  render() {
    const { t, classes, purchase } = this.props;

    const { rowsPerPage } = this.state;

    const { purchaseInvoiceData } = purchase;

    let purchaseInvoiceList = [];
    let currPagination = rowsPerPage;

    if (!isEmpty(purchaseInvoiceData)) {
      if (Array.isArray(purchaseInvoiceData.data)) {
        purchaseInvoiceList = purchaseInvoiceData.data;
      }

      if (purchaseInvoiceData.per_page) {
        currPagination = Number(purchaseInvoiceData.per_page);
      }
    }

    const tableHeaderPurchaseInvoice = columnsTableHeaderPurchaseInvoice(t);

    return (
      <Box className={classes.boxContainerTable}>
        <Paper elevation={0}>
          <TableContainer className={'tableContainerConstantHeightMDSizeConstantLayoutPrimary'}>
            <Table stickyHeader className='tableMain' aria-label='table-purchase-invoice-list'>
              <TableHeadSearchPurchaseInvoice dataHeaders={tableHeaderPurchaseInvoice} />
              <TableBody className='primaryTableBody'>
                {purchase.statusRequest === STATUS_REQUEST_LIST_PURCHASE_INVOICE_PENDING && (
                  <TableRowLoading
                    rowsAmount={rowsPerPage}
                    columnsAmount={tableHeaderPurchaseInvoice.length}
                  />
                )}
                {!isEmpty(purchaseInvoiceList) &&
                  purchase.statusRequest !== STATUS_REQUEST_LIST_PURCHASE_INVOICE_PENDING &&
                  purchaseInvoiceList.map((singlePurchaseInvoiceData, index) => {
                    const baseUrl = strHelp.templateString(
                      REACT_APP_PURCHASE_PURCHASE_INVOICE_DETAILS_TEMPLATE_URL,
                      singlePurchaseInvoiceData,
                    );

                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        className={'hoverEntireRow'}
                        onDoubleClick={() => this.props.navigate(baseUrl)}
                      >
                        {tableHeaderPurchaseInvoice.map((columnTableHeader, indexColumnHeader) => {
                          const { name: columnName } = columnTableHeader;

                          let valueData = singlePurchaseInvoiceData[columnName];

                          if (columnName === 'purchase_invoice_no') {
                            return (
                              <TableCell key={indexColumnHeader}>
                                <TypographyLinkPrimary
                                  baseUrl={baseUrl}
                                  typographyValue={valueData}
                                />
                              </TableCell>
                            );
                          }

                          // formatting date following localzone
                          if (columnName === 'transaction_date') {
                            if (dateHelpers.isValidDateV2(new Date(valueData))) {
                              valueData = formatHelp.getReadableDateV2(valueData);
                            }
                          }

                          if (columnsCalculationNumberPurchaseInvoice.includes(columnName)) {
                            valueData = formatHelp.currencyFormatWithRegex(Number(valueData), 0);
                          }

                          if (columnName === 'status') {
                            const selectedPurchaseInvoiceData = arrHelp.filterObjDataWithID(
                              purchaseInvoiceStatus(t),
                              'value',
                              valueData,
                            );

                            let colorChip = 'chipStatusSecondary';
                            let label = t('base.purchase-invoice-status.open');

                            // getting color chip from selected purchase invoice data
                            if (!isEmpty(selectedPurchaseInvoiceData)) {
                              colorChip = selectedPurchaseInvoiceData.colorChip;
                              label = selectedPurchaseInvoiceData.label;
                            }

                            return (
                              <TableCell key={indexColumnHeader}>
                                <Chip size='small' color={colorChip} label={label} />
                              </TableCell>
                            );
                          }

                          return (
                            <TableCell key={indexColumnHeader} align={columnTableHeader.align}>
                              {valueData}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {isEmpty(purchaseInvoiceList) &&
              purchase.statusRequest !== STATUS_REQUEST_LIST_PURCHASE_INVOICE_PENDING && (
                <BoxTableNotFound
                  textNotFound={t('dashboard.purchase.purchase-invoice.table.body-not-found')}
                  textButton={t('dashboard.purchase.purchase-invoice.button.add-purchase-invoice')}
                  isExistButton={true}
                  handleClickedButton={() => {
                    this.props.navigate(REACT_APP_PURCHASE_PURCHASE_INVOICE_CREATE_NEW_URL);
                  }}
                />
              )}
          </TableContainer>
          <TablePaginationMain
            page={purchaseInvoiceData ? purchaseInvoiceData.current_page - 1 : 0}
            prev_page={purchaseInvoiceData ? purchaseInvoiceData.prev_page_url : null}
            next_page={purchaseInvoiceData ? purchaseInvoiceData.next_page_url : null}
            rowsPerPage={currPagination}
            isLoadingData={
              purchase.statusRequest === STATUS_REQUEST_LIST_PURCHASE_INVOICE_PENDING ||
              !purchaseInvoiceList.length
            }
            labelRowsPerPage={t('label.table.pagination.primary.rows-per-page')}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    );
  }
}

PurchaseInvoiceList.defaultProps = {
  isUseChangedPageOnDetails: false,
};

PurchaseInvoiceList.propsTypes = {
  handleChangeStatusPage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    purchase: state.purchase,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListPurchaseInvoice: (paramsGetListPurchaseInvoice) =>
      dispatch(getListPurchaseInvoice(paramsGetListPurchaseInvoice)),
    setFailedMessage: (primaryText, secondaryText) =>
      messageHelper.failedMessage(dispatch, primaryText, secondaryText, false),
  };
};

const stylingPurchaseInvoiceList = withStyles(styles)(PurchaseInvoiceList);

const PurchaseInvoiceListTranslate = withTranslation()(stylingPurchaseInvoiceList);

const PurchaseInvoiceListWithRouter = withRouter(PurchaseInvoiceListTranslate);

const ConnectedPurchaseInvoiceList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PurchaseInvoiceListWithRouter);

export default ConnectedPurchaseInvoiceList;
