import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { v4 as uuidV4 } from 'uuid';

import { styles } from './LoadingSpinner.styles';

class LoadingSpinner extends Component {
  render() {
    const { classes } = this.props;

    // loading components
    const childrenDiv = [];

    for (let i = 0; i <= 8; i++) {
      childrenDiv.push(<div key={uuidV4()}></div>);
    }

    return (
      <div className={classes.root}>
        <div className={classes.loadingSpinner}>{childrenDiv}</div>
      </div>
    );
  }
}

export default withStyles(styles)(LoadingSpinner);
