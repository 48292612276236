import * as React from 'react';
function SvgUnlockChangeProfile(props) {
  return (
    <svg width={20} height={23} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M5 11V7a5 5 0 019.9-1M3 11h14a2 2 0 012 2v7a2 2 0 01-2 2H3a2 2 0 01-2-2v-7a2 2 0 012-2z'
        stroke='#1570EF'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
export default SvgUnlockChangeProfile;
