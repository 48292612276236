import { useSelector } from 'react-redux';
import { difference, isEmpty } from 'lodash';

import { ROOT_STORE_NAME_AUTH, SLICE_NAME_AUTH_DATA } from '../utils/constants/store.constant';
import objHelper from '../utils/helpers/object.helper';

export const permissionMenuType = 'menu';
export const permissionMenuGroupType = 'menuGroup';
export const permissionFeatureType = 'feature';

export default function usePermission({
  storeProps = {
    rootKeyname: ROOT_STORE_NAME_AUTH,
    sliceKeyname: SLICE_NAME_AUTH_DATA,
  },
  mappingPermissionType = {
    [permissionMenuType]: {
      keyname: 'menu',
    },
    [permissionMenuGroupType]: {
      keyname: 'menu_group',
    },
    [permissionFeatureType]: {
      keyname: 'permission',
    },
  },
} = {}) {
  const { rootKeyname, sliceKeyname } = storeProps;

  const permissionStore = useSelector((state) =>
    state[rootKeyname] ? state[rootKeyname][sliceKeyname] : {},
  );

  function getCurrentUserPermissions(permissionType = permissionMenuGroupType) {
    if (!permissionType || isEmpty(permissionStore) || !permissionStore) return [];

    const selectedPermissionTypeItem = objHelper.getDefaultChildrenObjItem({
      obj: mappingPermissionType,
      keynameTarget: permissionType,
    });

    if (isEmpty(selectedPermissionTypeItem)) return [];

    return objHelper.getChildrenOfObjItemByKeynameStr({
      obj: permissionStore,
      keyname: selectedPermissionTypeItem.keyname,
    });
  }

  /**
   *
   * @param { object } param0
   * @param { string | array }    permissionsRequired
   * @param { boolean }           allowEmptyPermission
   * @param { string }            permissionType
   * @param { boolean }           mustIncludesAll             determine for returning permission must all, not exception
   * @returns
   */
  function isPermissionsGranted({
    permissionsRequired,
    allowEmptyPermission = true,
    mustIncludesAll = false,
    permissionType = permissionMenuGroupType,
  }) {
    if (isEmpty(permissionsRequired) || permissionsRequired === undefined)
      return allowEmptyPermission;

    if (!Array.isArray(permissionsRequired)) return false;

    if (typeof permissionsRequired === 'string') {
      permissionsRequired = [permissionsRequired];
    }

    const diffPermissionsRequired = difference(
      permissionsRequired,
      getCurrentUserPermissions(permissionType),
    );

    // must all permission that no exception
    if (mustIncludesAll) return !diffPermissionsRequired.length;

    // only one for granting permission
    return diffPermissionsRequired.length !== permissionsRequired.length;
  }

  return {
    getCurrentUserPermissions,
    isPermissionsGranted,
  };
}
