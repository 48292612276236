import { isEmpty } from 'lodash';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import { DEFAULT_DISCOUNT_PERCENTAGE_TYPE } from '../../default/discount-type.default';
import { DEFAULT_COMPARISON_VALUE_NUMBER } from '../../default/number.default';
import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF,
  DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_QUOTATION_REQUEST_PRODUCT_REF_API,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';
import formatHelp from '../../helpers/format.helpers';
import objHelper from '../../helpers/object.helper';
import parseResponseHelpers from '../../helpers/parse-response.helpers';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import { generalizeAttachmentFromDetails } from './initial-add-attachment';
import {
  generalizeDataAddQuotationRequest,
  generateDataNValidationAddProductQR,
} from './initial-add-quotation-request';
import {
  initialDataQuotationRequest,
  initialValidationProduct,
  initialValidationQuotationRequest,
  purchaseQuotationRequestCalculationNumberKeyNames,
} from './initial-data-quotation-request';

// data and validation quotation request generate from data selected
export const generateDataNValidationUpdateQuotationRequest = (
  quotationRequestDataSelected,
  customID = null,
) => {
  let idHasUsed = customID || [];

  let dataUpdateQuotationRequest = objHelper.filterKeyObj(
    quotationRequestDataSelected,
    [],
    [
      'supplier_id',
      'contact_code',
      'supplier_name',
      'supplier_address',
      'transaction_date',
      'expired_date',
      'transaction_no',
      'description',
      'quotation_request_id',
      'status',
      'discount_account_id',
      'discount_account_name',
      'discount_account_code',
      'expense_account_id',
      'expense_account_name',
      'expense_account_code',
      'down_payment_account_id',
      'down_payment_account_name',
      'down_payment_account_code',
    ],
  );

  idHasUsed = idHasUsed.concat(uuidV4());

  dataUpdateQuotationRequest = idHasUsed.map((id) => {
    return {
      id,
      ...dataUpdateQuotationRequest,
      supplier_id_container: {
        contact_id: dataUpdateQuotationRequest.supplier_id || null,
        contact_code: dataUpdateQuotationRequest.contact_code || '',
        contact_name: dataUpdateQuotationRequest.supplier_name || '',
      },
      supplier_address: dataUpdateQuotationRequest.supplier_address,
      reference_number_container: {
        code: dataUpdateQuotationRequest.transaction_no,
      },
      description: dataUpdateQuotationRequest.description,
      transaction_date: dataUpdateQuotationRequest.transaction_date
        ? moment(dataUpdateQuotationRequest.transaction_date).toISOString(true)
        : null,
      expired_date: dataUpdateQuotationRequest.expired_date
        ? moment(dataUpdateQuotationRequest.expired_date).toISOString(true)
        : null,
      discount_account_id_container: {
        account_id: dataUpdateQuotationRequest.discount_account_id || '',
        account_name: dataUpdateQuotationRequest.discount_account_name || '',
        account_code: dataUpdateQuotationRequest.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: dataUpdateQuotationRequest.expense_account_id || '',
        account_name: dataUpdateQuotationRequest.expense_account_name || '',
        account_code: dataUpdateQuotationRequest.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: dataUpdateQuotationRequest.down_payment_account_id || '',
        account_name: dataUpdateQuotationRequest.down_payment_account_name || '',
        account_code: dataUpdateQuotationRequest.down_payment_account_code || '',
      },
    };
  });

  const validationUpdateQuotationRequest = idHasUsed.map((id) => {
    return initialValidationQuotationRequest('', id);
  });

  return {
    updateQuotationRequestData: dataUpdateQuotationRequest,
    updateQuotationRequestValidation: validationUpdateQuotationRequest,
  };
};

// data and validation quotation request generate from data selected
export const generateDataNValidationUpdateQuotationRequestV2 = ({
  quotationRequestDataSelected,
  customID = null,
  companyUserData = {},
  customDataKey = 'updateQuotationRequestData',
  customValidationKey = 'updateQuotationRequestValidation',
}) => {
  let idHasUsed = customID || [];

  let dataUpdateQuotationRequest = objHelper.filterKeyObj(
    quotationRequestDataSelected,
    [],
    [
      'supplier_id',
      'contact_code',
      'supplier_name',
      'supplier_address',
      'transaction_date',
      'expired_date',
      'transaction_no',
      'description',
      'quotation_request_id',
      'status',
      'discount_type',
      'discount_percentage',
      'discount_nominal',
      'expense_value',
      'down_payment_value',
      'on_behalf',
      'discount_account_id',
      'discount_account_name',
      'discount_account_code',
      'expense_account_id',
      'expense_account_name',
      'expense_account_code',
      'down_payment_account_id',
      'down_payment_account_name',
      'down_payment_account_code',
      'tag',
    ],
  );

  dataUpdateQuotationRequest = objHelper.changeFormatValue(
    dataUpdateQuotationRequest,
    purchaseQuotationRequestCalculationNumberKeyNames,
    [(value) => Number(value) || ''],
  );

  idHasUsed = idHasUsed.concat(uuidV4());

  dataUpdateQuotationRequest = idHasUsed.map((id) => {
    return {
      id,
      ...initialDataQuotationRequest('', id),
      ...dataUpdateQuotationRequest,
      attachment: generalizeAttachmentFromDetails({
        dataDetails: quotationRequestDataSelected,
      }),
      accounting_period: !isEmpty(companyUserData)
        ? companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF]
        : null,
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
      supplier_id_container: {
        contact_id: dataUpdateQuotationRequest.supplier_id || null,
        contact_code: dataUpdateQuotationRequest.contact_code || '',
        contact_name: dataUpdateQuotationRequest.supplier_name || '',
      },
      supplier_address: dataUpdateQuotationRequest.supplier_address,
      reference_number_container: {
        code: dataUpdateQuotationRequest.transaction_no,
      },
      description: dataUpdateQuotationRequest.description,
      transaction_date: dataUpdateQuotationRequest.transaction_date
        ? moment(dataUpdateQuotationRequest.transaction_date).toISOString(true)
        : null,
      expired_date: dataUpdateQuotationRequest.expired_date
        ? moment(dataUpdateQuotationRequest.expired_date).toISOString(true)
        : null,
      discount_type: dataUpdateQuotationRequest.discount_type || DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
      discount_account_id_container: {
        account_id: dataUpdateQuotationRequest.discount_account_id || '',
        account_name: dataUpdateQuotationRequest.discount_account_name || '',
        account_code: dataUpdateQuotationRequest.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: dataUpdateQuotationRequest.expense_account_id || '',
        account_name: dataUpdateQuotationRequest.expense_account_name || '',
        account_code: dataUpdateQuotationRequest.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: dataUpdateQuotationRequest.down_payment_account_id || '',
        account_name: dataUpdateQuotationRequest.down_payment_account_name || '',
        account_code: dataUpdateQuotationRequest.down_payment_account_code || '',
      },
      ...parseResponseHelpers.tagContainerInputFromDetails(dataUpdateQuotationRequest),
    };
  });

  const validationUpdateQuotationRequest = idHasUsed.map((id) => {
    return initialValidationQuotationRequest('', id);
  });

  return {
    [customDataKey]: dataUpdateQuotationRequest,
    [customValidationKey]: validationUpdateQuotationRequest,
  };
};

// generate data and validation for product in quotation request
export const generateDataNValidationUpdateProductQuotationRequest = (
  quotationRequestDataSelected,
  customID = null,
) => {
  let idHasUsed = customID || [];

  const productUpdateData = objHelper.filterKeyObj(
    quotationRequestDataSelected,
    [],
    ['quotation_request_product'],
  );

  let allProductUpdateData = productUpdateData.quotation_request_product;

  allProductUpdateData = allProductUpdateData.map((dataProduct, index) => {
    idHasUsed = idHasUsed.concat(uuidV4());

    const {
      product_id,
      product_name,
      description,
      quantity,
      quantity_destination,
      quantity_origin,
      unit_destination,
      unit_origin,
      price,
    } = dataProduct;

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    return {
      ...dataProduct,
      id: idHasUsed[index],
      product_id_container: {
        product_id: product_id,
        product_name: product_name,
        ...dataProduct,
      },
      product_description: description || '',
      quantity: Number(quantity) || '',
      quantity_destination: Number(quantity_destination) || '',
      unit_destination: unit_destination || '',
      unit_origin: unit_origin || '',
      price: price ? Number(price) : '',
      price_amount: price * quantity,
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantity),
        value_comparison: quantityComparison,
      },
    };
  });

  const validationUpdateProduct = allProductUpdateData.map((dataProduct, index) => {
    return initialValidationProduct('', idHasUsed[index]);
  });

  return {
    updateProductData: allProductUpdateData,
    updateProductValidation: validationUpdateProduct,
  };
};

// generate data and validation for product in quotation request
export const generateDataNValidationUpdateProductQuotationRequestV2 = (
  quotationRequestDataSelected,
  customID = null,
  addNewProductSalesQuotation = true,
  keynameProductQuotationRequest = DEFAULT_KEY_NAME_QUOTATION_REQUEST_PRODUCT_REF_API,
) => {
  let idHasUsed = customID || [];

  const productUpdateData = objHelper.filterKeyObj(
    quotationRequestDataSelected,
    [],
    [keynameProductQuotationRequest],
  );

  let allProductUpdateData = productUpdateData[keynameProductQuotationRequest];

  allProductUpdateData = allProductUpdateData.map((dataProduct, index) => {
    idHasUsed = idHasUsed.concat(uuidV4());

    const {
      quantity,
      price,
      product_id,
      product_name,
      description,
      quantity_origin,
      quantity_destination,
      product_unit,
      unit_destination,
      unit_origin,
    } = dataProduct;

    const amount = Number(quantity) * Number(price);

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    return {
      ...dataProduct,
      id: idHasUsed[index],
      product_id_container: {
        product_id: product_id,
        product_name: product_name,
        ...dataProduct,
      },
      product_description: description || '',
      quantity: Number(quantity) || '',
      quantity_destination: Number(quantity_destination) || '',
      quantity_comparison: quantityComparison,
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      price: price ? Number(price) : '',
      amount: Number(amount),
      quantity_available: Number(quantity),
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantity),
        value_comparison: quantityComparison,
      },
    };
  });

  const { addProductData, addProductValidation } = generateDataNValidationAddProductQR(1);

  let validationUpdateProduct = allProductUpdateData.map((dataProduct, index) => {
    return initialValidationProduct('', idHasUsed[index]);
  });

  if (addNewProductSalesQuotation) {
    allProductUpdateData = allProductUpdateData.concat(addProductData);
    validationUpdateProduct = validationUpdateProduct.concat(addProductValidation);
  }

  return {
    updateProductData: allProductUpdateData,
    updateProductValidation: validationUpdateProduct,
  };
};

// generalize data for update quotation request
export const generalizeDataUpdateQuotationRequest = (quotationRequestData, productData) => {
  const { transaction_date, expired_date, amount } = quotationRequestData[0];

  return {
    ...quotationRequestData[0],
    product: productData,
    transaction_date: transaction_date ? moment(transaction_date).toISOString(true) : '',
    expired_date: expired_date ? moment(expired_date).toISOString(true) : '',
    amount: formatHelp.reverseCurrencyFormatWithRegex(amount),
  };
};

export const generalizeDataUpdateQuotationRequestV2 = (quotationRequestData, productData) => {
  const { quotation_request_id } =
    Array.isArray(quotationRequestData) && quotationRequestData.length
      ? quotationRequestData[0]
      : quotationRequestData;

  const generalizedForUpdate = generalizeDataAddQuotationRequest(quotationRequestData, productData);

  return {
    ...generalizedForUpdate,
    quotation_request_id,
  };
};
