import { isEmpty, pick } from 'lodash';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import { DEFAULT_COMPARISON_VALUE_NUMBER } from '../../default/number.default';
import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF,
  DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_PRODUCT_API,
  DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_AVAILABLE_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';
import objHelper from '../../helpers/object.helper';
import parseResponseHelpers from '../../helpers/parse-response.helpers';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import { generalizeAttachmentFromDetails } from './initial-add-attachment';
import { generalizeDataAddPurchaseDelivery } from './initial-add-purchase-delivery';
import {
  initialValidationProduct,
  initialValidationPurchaseDelivery,
} from './initial-data-purchase-delivery';

// data and validation purchase delivery generate from data selected
export const generateDataNValidationUpdatePurchaseDelivery = (
  purchaseDeliveryDataSelected,
  customID = null,
) => {
  let idHasUsed = customID || [];

  idHasUsed = idHasUsed.concat(uuidV4());

  const dataUpdatePurchaseDelivery = idHasUsed.map((id) => {
    return {
      id,
      ...purchaseDeliveryDataSelected,
      purchase_order_id_container: {
        purchase_order_no: purchaseDeliveryDataSelected.purchase_order_no,
      },
      supplier_id_container: {
        contact_id: purchaseDeliveryDataSelected.supplier_id || null,
        contact_name: purchaseDeliveryDataSelected.supplier_name || '',
      },
      supplier_address: purchaseDeliveryDataSelected.supplier_address,
      reference_number_container: {
        code: purchaseDeliveryDataSelected.purchase_delivery_no,
      },
      description: purchaseDeliveryDataSelected.description,
      delivery_date: purchaseDeliveryDataSelected.delivery_date
        ? moment(purchaseDeliveryDataSelected.delivery_date).toISOString(true)
        : moment().toISOString(true),
      discount_account_id_container: {
        account_id: purchaseDeliveryDataSelected.discount_account_id || '',
        account_name: purchaseDeliveryDataSelected.discount_account_name || '',
        account_code: purchaseDeliveryDataSelected.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: purchaseDeliveryDataSelected.expense_account_id || '',
        account_name: purchaseDeliveryDataSelected.expense_account_name || '',
        account_code: purchaseDeliveryDataSelected.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: purchaseDeliveryDataSelected.down_payment_account_id || '',
        account_name: purchaseDeliveryDataSelected.down_payment_account_name || '',
        account_code: purchaseDeliveryDataSelected.down_payment_account_code || '',
      },
    };
  });

  const validationUpdatePurchaseDelivery = idHasUsed.map((id) => {
    return initialValidationPurchaseDelivery('', id);
  });

  return {
    updatePurchaseDeliveryData: dataUpdatePurchaseDelivery,
    updatePurchaseDeliveryValidation: validationUpdatePurchaseDelivery,
  };
};

// data and validation purchase delivery generate from data selected
export const generateDataNValidationUpdatePurchaseDeliveryV2 = ({
  purchaseDeliveryDataSelected,
  customID = null,
  companyUserData = {},
  customDataKey = 'updatePurchaseDeliveryData',
  customValidationKey = 'updatePurchaseDeliveryValidation',
}) => {
  let idHasUsed = customID || [];

  idHasUsed = idHasUsed.concat(uuidV4());

  const dataUpdatePurchaseDelivery = idHasUsed.map((id) => {
    return {
      id,
      ...purchaseDeliveryDataSelected,
      attachment: generalizeAttachmentFromDetails({
        dataDetails: purchaseDeliveryDataSelected,
      }),
      accounting_period: !isEmpty(companyUserData)
        ? companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF]
        : null,
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
      purchase_order_id_container: {
        purchase_order_no: purchaseDeliveryDataSelected.purchase_order_no,
      },
      supplier_id_container: {
        contact_id: purchaseDeliveryDataSelected.supplier_id || null,
        contact_name: purchaseDeliveryDataSelected.supplier_name || '',
      },
      supplier_address: purchaseDeliveryDataSelected.supplier_address,
      reference_number: purchaseDeliveryDataSelected.purchase_delivery_id,
      reference_number_container: {
        value: purchaseDeliveryDataSelected.purchase_delivery_no || '',
      },
      description: purchaseDeliveryDataSelected.description,
      delivery_date: purchaseDeliveryDataSelected.delivery_date
        ? moment(purchaseDeliveryDataSelected.delivery_date).toISOString(true)
        : moment().toISOString(true),
      discount_account_id_container: {
        account_id: purchaseDeliveryDataSelected.discount_account_id || '',
        account_name: purchaseDeliveryDataSelected.discount_account_name || '',
        account_code: purchaseDeliveryDataSelected.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: purchaseDeliveryDataSelected.expense_account_id || '',
        account_name: purchaseDeliveryDataSelected.expense_account_name || '',
        account_code: purchaseDeliveryDataSelected.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: purchaseDeliveryDataSelected.down_payment_account_id || '',
        account_name: purchaseDeliveryDataSelected.down_payment_account_name || '',
        account_code: purchaseDeliveryDataSelected.down_payment_account_code || '',
      },
      warehouse_id_container: pick(purchaseDeliveryDataSelected, [
        'warehouse_id',
        'warehouse_name',
      ]),
      ...parseResponseHelpers.tagContainerInputFromDetails(purchaseDeliveryDataSelected),
    };
  });

  const validationUpdatePurchaseDelivery = idHasUsed.map((id) => {
    return initialValidationPurchaseDelivery('', id);
  });

  return {
    [customDataKey]: dataUpdatePurchaseDelivery,
    [customValidationKey]: validationUpdatePurchaseDelivery,
  };
};

// generate data and validation for product in purchase delivery
export const generateDataNValidationUpdateProductPurchaseDelivery = (
  purchaseDeliveryDataSelected,
  customID = null,
) => {
  let idHasUsed = customID || [];

  const productUpdateData = objHelper.filterKeyObj(purchaseDeliveryDataSelected, [], ['product']);

  let allProductUpdateData = productUpdateData.product;

  allProductUpdateData = allProductUpdateData.map((dataProduct, index) => {
    idHasUsed = idHasUsed.concat(uuidV4());

    const { quantity_origin, quantity } = dataProduct;

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    const perQuantityComparison = Number(1 / quantityComparison);

    const purchaseOrderProductAvailableWithPerComparison = Number(
      dataProduct[DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_AVAILABLE_API_REF] *
        perQuantityComparison,
    );

    return {
      ...dataProduct,
      id: idHasUsed[index],
      product_id_container: {
        product_id: dataProduct.product_id,
        product_name: dataProduct.product_name,
        ...dataProduct,
      },
      description: dataProduct.description,
      quantity: dataProduct.quantity,
      quantity_available: dataProduct.quantity_available - dataProduct.quantity,
      quantity_available_const:
        Number(dataProduct.quantity_available) + purchaseOrderProductAvailableWithPerComparison,
      price: dataProduct.price,
      price_amount: dataProduct.price * dataProduct.quantity,
      quantity_comparison: Number(quantityComparison),
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available:
          Number(dataProduct.quantity_available) + purchaseOrderProductAvailableWithPerComparison,
        value_comparison: Number(quantityComparison),
      },
    };
  });

  const validationUpdateProduct = allProductUpdateData.map((dataProduct, index) => {
    return initialValidationProduct('', idHasUsed[index]);
  });

  return {
    updateProductData: allProductUpdateData,
    updateProductValidation: validationUpdateProduct,
  };
};

// generate data and validation for product in purchase delivery
export const generateDataNValidationUpdateProductPurchaseDeliveryV2 = (
  purchaseDeliveryDataSelected,
  customID = null,
  customDataKey = 'updateProductData',
  customValidationKey = 'updateProductValidation',
) => {
  let idHasUsed = customID || [];

  const productUpdateData = objHelper.filterKeyObj(purchaseDeliveryDataSelected, [], ['product']);

  let allProductUpdateData = productUpdateData.product;

  allProductUpdateData = allProductUpdateData.map((dataProduct, index) => {
    const { product_unit, unit_destination, unit_origin, quantity, quantity_origin } = dataProduct;

    idHasUsed = idHasUsed.concat(uuidV4());

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    const perQuantityComparison = Number(1 / quantityComparison);

    const purchaseOrderProductAvailableWithPerComparison = Number(
      dataProduct[DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_AVAILABLE_API_REF] *
        perQuantityComparison,
    );

    const quantityAvailable =
      Number(dataProduct.quantity_available) +
      Number(purchaseOrderProductAvailableWithPerComparison);

    return {
      ...dataProduct,
      id: idHasUsed[index],
      product_id_container: {
        product_id: dataProduct.product_id,
        product_name: dataProduct.product_name,
        ...dataProduct,
      },
      description: dataProduct.description,
      quantity: Number(dataProduct.quantity),
      quantity_available: dataProduct.quantity_available - dataProduct.quantity,
      quantity_available_const: quantityAvailable,
      price: dataProduct.price,
      price_amount: dataProduct.price * dataProduct.quantity,
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: quantityAvailable,
        value_comparison: quantityComparison,
      },
      quantity_available_with_unit: `${Number(quantityAvailable)} ${unit_origin || product_unit}`,
    };
  });

  const validationUpdateProduct = allProductUpdateData.map((dataProduct, index) => {
    return initialValidationProduct('', idHasUsed[index]);
  });

  return {
    [customDataKey]: allProductUpdateData,
    [customValidationKey]: validationUpdateProduct,
  };
};

// generate data and validation for product in purchase delivery
export const generateDataNValidationUpdateProductPurchaseDeliveryV3 = ({
  purchaseDeliveryDataSelected,
  customID = null,
  customDataKey = 'updateProductData',
  customValidationKey = 'updateProductValidation',
  keynameProductPurchaseDelivery = DEFAULT_KEY_NAME_PRODUCT_API,
}) => {
  let idHasUsed = customID || [];

  const productUpdateData = objHelper.filterKeyObj(
    purchaseDeliveryDataSelected,
    [],
    [keynameProductPurchaseDelivery],
  );

  let allProductUpdateData = productUpdateData[keynameProductPurchaseDelivery];

  allProductUpdateData = allProductUpdateData.map((dataProduct, index) => {
    const { product_unit, unit_destination, unit_origin, quantity, quantity_origin } = dataProduct;

    idHasUsed = idHasUsed.concat(uuidV4());

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    const perQuantityComparison = Number(1 / quantityComparison);

    const purchaseOrderProductAvailableWithPerComparison = Number(
      dataProduct[DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_AVAILABLE_API_REF] *
        perQuantityComparison,
    );

    return {
      ...dataProduct,
      id: idHasUsed[index],
      product_id_container: {
        product_id: dataProduct.product_id,
        product_name: dataProduct.product_name,
        ...dataProduct,
      },
      description: dataProduct.description,
      quantity: Number(dataProduct.quantity),
      quantity_available: dataProduct.quantity_available - dataProduct.quantity,
      quantity_available_const:
        Number(dataProduct.quantity_available) +
        Number(purchaseOrderProductAvailableWithPerComparison),
      price: dataProduct.price,
      price_amount: dataProduct.price * dataProduct.quantity,
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available:
          Number(dataProduct.quantity_available) +
          Number(purchaseOrderProductAvailableWithPerComparison),
        value_comparison: quantityComparison,
      },
    };
  });

  const validationUpdateProduct = allProductUpdateData.map((dataProduct, index) => {
    return initialValidationProduct('', idHasUsed[index]);
  });

  return {
    [customDataKey]: allProductUpdateData,
    [customValidationKey]: validationUpdateProduct,
  };
};

// generalize data for update purchase delivery
export const generalizeDataUpdatePurchaseDelivery = (purchaseDeliveryData, productData) => {
  const { reference_number } =
    Array.isArray(purchaseDeliveryData) && purchaseDeliveryData.length
      ? purchaseDeliveryData[0]
      : purchaseDeliveryData;

  const generalizePurchaseDeliveryData = generalizeDataAddPurchaseDelivery(
    purchaseDeliveryData,
    productData,
  );

  return {
    ...generalizePurchaseDeliveryData,
    reference_number,
  };
};
