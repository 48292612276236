import {
  FORMAT_COMMON_TYPOGRAPHY_COLUMN,
  FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
  FORMAT_DATE_TIME_READABLE,
  FORMAT_MANUAL_FORMATTER_DATA,
  REPRESENTATION_DATA_CHIP_WITH_SELECT_ON_ITEM_LIST_OBJECT_KEYNAME_TYPE,
} from '../../../components/table-row/TableRowPrimary/TableRowPrimary';
import { purchaseInvoiceChartBasicStatus } from '../label-value/purchase-status';
import { salesInvoiceChartBasicStatus } from '../label-value/sales-status';

export const columnsTableChartSalesInvoiceDashboard = (t) => {
  return [
    {
      name: 'sales_invoice_no',
      label: t('table.header-column.transaction-no-dot'),
      width: 360,
      formatData: FORMAT_COMMON_TYPOGRAPHY_COLUMN,
      isSortColumn: true,
      sortKeyName: 'transaction_id',
    },
    {
      name: 'transaction_date',
      label: t('table.header-column.date'),
      width: 90,
      formatData: FORMAT_DATE_TIME_READABLE,
      isSortColumn: true,
      sortKeyName: 'transaction_date',
    },
    {
      name: 'amount',
      label: t('table.header-column.nominal'),
      width: 110,
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      isSortColumn: true,
      sortKeyName: 'amount',
      align: 'right',
    },
    {
      name: 'status',
      label: t('table.header-column.status'),
      width: 100,
      formatData: FORMAT_COMMON_TYPOGRAPHY_COLUMN,
      isSortColumn: false,
      representationDataType: REPRESENTATION_DATA_CHIP_WITH_SELECT_ON_ITEM_LIST_OBJECT_KEYNAME_TYPE,
      generateListWithTranslationForSelectItems: (t) => {
        if (t === undefined) return [];

        return salesInvoiceChartBasicStatus(t);
      },
    },
  ];
};

export const columnsTableChartPurchaseInvoiceDashboard = (t) => {
  return [
    {
      name: 'purchase_invoice_no',
      label: t('table.header-column.transaction-no-dot'),
      width: 360,
      formatData: FORMAT_COMMON_TYPOGRAPHY_COLUMN,
      isSortColumn: true,
      sortKeyName: 'transaction_id',
    },
    {
      name: 'transaction_date',
      label: t('table.header-column.date'),
      width: 90,
      formatData: FORMAT_DATE_TIME_READABLE,
      isSortColumn: true,
      sortKeyName: 'transaction_date',
    },
    {
      name: 'amount',
      label: t('table.header-column.nominal'),
      width: 110,
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      isSortColumn: true,
      sortKeyName: 'amount',
      align: 'right',
    },
    {
      name: 'status',
      label: t('table.header-column.status'),
      width: 100,
      formatData: 'normal-chip',
      isSortColumn: false,
      representationDataType: REPRESENTATION_DATA_CHIP_WITH_SELECT_ON_ITEM_LIST_OBJECT_KEYNAME_TYPE,
      generateListWithTranslationForSelectItems: (t) => {
        if (t === undefined) return [];

        return purchaseInvoiceChartBasicStatus(t);
      },
    },
  ];
};

export const columnsTableChartTopSalesDashboard = (t) => {
  return [
    {
      name: 'case',
      label: t('table.header-column.name'),
      width: 300,
      formatData: FORMAT_COMMON_TYPOGRAPHY_COLUMN,
      isSortColumn: false,
      sortKeyName: 'case',
    },
    {
      name: 'total',
      label: t('table.header-column.total'),
      width: 45,
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      isSortColumn: false,
      sortKeyName: 'total',
      align: 'right',
    },
  ];
};

export const columnsTableChartTopSalesProductDashboard = (t) => {
  return [
    {
      name: 'product_name',
      label: t('table.header-column.name'),
      width: 300,
      formatData: FORMAT_COMMON_TYPOGRAPHY_COLUMN,
      isSortColumn: false,
      sortKeyName: 'product_name',
    },
    {
      name: 'total',
      label: t('table.header-column.total'),
      width: 45,
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      isSortColumn: false,
      sortKeyName: 'total',
      align: 'right',
    },
  ];
};

export const columnsTableChartTopSalesComparisonDashboard = (t) => {
  return [
    {
      name: 'name',
      label: t('table.header-column.name'),
      width: 300,
      formatData: FORMAT_COMMON_TYPOGRAPHY_COLUMN,
      isSortColumn: false,
      sortKeyName: 'name',
    },
    {
      name: 'previous_month_amount',
      label: t('table.header-column.last-month'),
      width: 110,
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      isSortColumn: false,
      sortKeyName: 'previous_month_amount',
      align: 'right',
    },
    {
      name: 'current_month_amount',
      label: t('table.header-column.this-month'),
      width: 110,
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      isSortColumn: false,
      sortKeyName: 'current_month_amount',
      align: 'right',
    },
    {
      name: 'percentage',
      label: t('table.header-column.percentage-symbol'),
      minWidth: 55,
      formatData: FORMAT_MANUAL_FORMATTER_DATA,
      valueFormatter: ({ value }) => {
        if (Number.isNaN(Number(value))) return 0;

        return Number(value).toFixed(2) + '%';
      },
      isSortColumn: false,
      sortKeyName: 'percentage',
    },
  ];
};
