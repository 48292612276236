import {
  REACT_APP_API_URL_TRANSACTION,
  REACT_APP_API_URL_TRANSACTION_DETAILS,
} from '../../../utils/configs/api.config';
import { DEFAULT_TRANSACTION_LIST_PARAMS } from '../../../utils/default/params.default';
import {
  creatorDetailServiceV2,
  creatorListService,
} from '../creator-services/creator-services.helper';

/**
 *
 * @param { object } paramsGetTransactionList params for getting transaction list
 * @returns
 *      service transaction list
 */
const getTransactionList = async (paramsGetTransactionList) => {
  return await creatorListService(
    REACT_APP_API_URL_TRANSACTION,
    paramsGetTransactionList,
    DEFAULT_TRANSACTION_LIST_PARAMS,
  );
};

/**
 * @param { object }    paramsGetTransactionDetails     minimum is transaction id
 * @returns
 *      service transaction details
 */
const getTransactionDetails = async (paramsGetTransactionDetails) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_TRANSACTION_DETAILS,
    paramsGetTransactionDetails,
  );
};

const transactionServices = {
  getTransactionList,
  getTransactionDetails,
};

export default transactionServices;
