import * as React from 'react';
function SvgAccountDataMenu(props) {
  return (
    <svg width={85} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#account-data-menu_svg__filter0_d_5537_189139)'>
        <path d='M54.546 1H1.167v67.952h53.379V1z' fill='#B97850' />
        <path d='M54.546 1h-26.69v67.952h26.69V1z' fill='#935635' />
        <path d='M51.31 3.574H4.402v62.804H51.31V3.574z' fill='#fff' />
        <path d='M51.31 3.574H27.857v62.804h23.455V3.574z' fill='#E6F3FF' />
        <path d='M41.201 1h-26.69v5.663h26.69V1z' fill='#734B46' />
        <path d='M41.201 1H27.856v5.663h13.345V1z' fill='#57442F' />
        <path
          d='M14.512 17.988H7.839v5.663h6.673v-5.663zM14.512 29.313H7.839v5.663h6.673v-5.663zM14.512 40.639H7.839V46.3h6.673v-5.663zM14.512 51.964H7.839v5.662h6.673v-5.662z'
          fill='#84DBFF'
        />
        <path
          d='M47.873 22.621H21.184v1.03h26.69v-1.03zM47.873 33.946H21.184v1.03h26.69v-1.03zM47.873 45.272H21.184v1.03h26.69v-1.03zM47.873 56.597H21.184v1.03h26.69v-1.03zM47.873 20.219H21.184v1.03h26.69v-1.03zM47.873 31.544H21.184v1.03h26.69v-1.03zM47.873 42.87H21.184v1.029h26.69v-1.03zM47.873 54.195H21.184v1.03h26.69v-1.03z'
          fill='#CFDBE6'
        />
        <path
          d='M72.853 69H47.418c-2.383 0-4.315-1.739-4.315-3.884V28.714c0-2.145 1.932-3.884 4.315-3.884h25.435c2.382 0 4.314 1.739 4.314 3.884v36.403c0 2.144-1.931 3.883-4.314 3.883z'
          fill='#324A5E'
        />
        <path
          d='M72.853 24.83H60.045V69h12.808c2.382 0 4.314-1.739 4.314-3.883V28.714c0-2.145-1.931-3.884-4.314-3.884z'
          fill='#2B3B4E'
        />
        <path d='M73.517 28.055H46.753v8.03h26.764v-8.03z' fill='#B5F1F4' />
        <path
          d='M73.518 28.055H60.046v8.03h13.472v-8.03zM66.353 38.884h-4.866V42.9h4.866v-4.015z'
          fill='#84DBFF'
        />
        <path d='M58.784 38.884h-4.867V42.9h4.867v-4.016z' fill='#B5F1F4' />
        <path d='M73.517 38.884h-4.46V42.9h4.46v-4.016z' fill='#84DBFF' />
        <path
          d='M51.214 38.884h-4.46V42.9h4.46v-4.016zM58.784 44.467h-4.867v4.015h4.867v-4.015z'
          fill='#B5F1F4'
        />
        <path d='M66.353 44.467h-4.866v4.015h4.866v-4.015z' fill='#84DBFF' />
        <path d='M51.214 44.467h-4.46v4.015h4.46v-4.015z' fill='#B5F1F4' />
        <path
          d='M73.517 44.467h-4.46v4.015h4.46v-4.015zM66.353 50.047h-4.866v4.015h4.866v-4.015z'
          fill='#84DBFF'
        />
        <path
          d='M58.784 50.047h-4.867v4.016h4.867v-4.016zM51.214 50.047h-4.46v4.016h4.46v-4.016z'
          fill='#B5F1F4'
        />
        <path d='M66.353 55.63h-4.866v4.015h4.866V55.63z' fill='#84DBFF' />
        <path
          d='M58.784 55.63h-4.867v4.015h4.867V55.63zM51.214 55.63h-4.46v4.015h4.46V55.63z'
          fill='#B5F1F4'
        />
        <path d='M66.353 61.212h-4.866v4.016h4.866v-4.016z' fill='#84DBFF' />
        <path d='M58.784 61.212h-4.867v4.016h4.867v-4.016z' fill='#B5F1F4' />
        <path d='M73.517 50.047h-4.46v15.178h4.46V50.047z' fill='#FC6F58' />
        <path d='M51.214 61.212h-4.46v4.016h4.46v-4.016z' fill='#B5F1F4' />
      </g>
      <defs>
        <filter
          id='account-data-menu_svg__filter0_d_5537_189139'
          x={0.167}
          y={0}
          width={84}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5537_189139' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5537_189139' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgAccountDataMenu;
