const defaultOptions = {
  valuePassword: '',
  testCases: {
    onceUpperCase: /(?=.*[A-Z])/,
    onceLowerCase: /(?=.*[a-z])/,
    onceNumber: /(?=.*\d)/,
    minEigthChar: /[^!.?\s]{8,}/,
  },
};

// this class input options as above
// - template
// {
// 	    valuePassword: [your-password-char],
//      testCase: {
// 		    [name-test-case]: [regex-test-case],
// 		    ...[other-test-case]
// 	    }
// }
// - for example, look above
//
// then output is
// - template
// {
//     score: 0 until [length-of-test-case],
//     testResult: {
//          [testCase]: [false || true],
//          ...[another-test-case-result],
//     }
// }
//
// - example
// {
//     score: 1,
//     testResult: {
//         onceUpperCase: true,
//         onceLowerCase: false,
//         onceNumber: false,
//         minEigthChar: true,
//     }
// }

class PasswordStrengthManager {
  constructor(options) {
    this.options = Object.assign(defaultOptions, options);

    this.result = {
      score: 0,
      testResult: {},
    };

    this._testPassword();
  }

  _getKeysTestCase() {
    return Object.keys(this.options.testCases);
  }

  _testPassword() {
    // looping all in test case
    for (let testCase of this._getKeysTestCase()) {
      // get regex for test case
      let regexTestCase = this.options.testCases[testCase];

      // result regex is satisfy or not
      let resultTest = regexTestCase.test(this.options.valuePassword);

      // adding score when regex is satisfy
      if (resultTest) {
        this.result = {
          ...this.result,
          score: this.result.score + 1,
        };
      }

      // adding every test result to main result
      this.result = {
        ...this.result,
        testResult: {
          ...this.result.testResult,
          [testCase]: resultTest,
        },
      };
    }
  }
}

export default PasswordStrengthManager;
