import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { STOCK_TRANSFER_BASE_ACTION_TYPE } from '../../utils/constants/action-type.constant';
import {
  LOCAL_STORAGE_STOCK_TRANSFER_LIST,
  LOCAL_STORAGE_STOCK_TRANSFER_PRINT_LIST,
} from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  SLICE_NAME_STOCK_TRANSFER_DETAILS,
  SLICE_NAME_STOCK_TRANSFER_LIST,
  SLICE_NAME_STOCK_TRANSFER_PRINT_LIST,
  STATUS_REQUEST_ADD_TRANSFER_WAREHOUSE_FAILED,
  STATUS_REQUEST_ADD_TRANSFER_WAREHOUSE_PENDING,
  STATUS_REQUEST_ADD_TRANSFER_WAREHOUSE_SUCCESS,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_DELETE_STOCK_TRANSFER_FAILED,
  STATUS_REQUEST_DELETE_STOCK_TRANSFER_PENDING,
  STATUS_REQUEST_DELETE_STOCK_TRANSFER_SUCCESS,
  STATUS_REQUEST_DETAIL_STOCK_TRANSFER_FAILED,
  STATUS_REQUEST_DETAIL_STOCK_TRANSFER_PENDING,
  STATUS_REQUEST_DETAIL_STOCK_TRANSFER_SUCCESS,
  STATUS_REQUEST_LIST_STOCK_TRANSFER_FAILED,
  STATUS_REQUEST_LIST_STOCK_TRANSFER_PENDING,
  STATUS_REQUEST_LIST_STOCK_TRANSFER_SUCCESS,
  STATUS_REQUEST_UPDATE_TRANSFER_WAREHOUSE_FAILED,
  STATUS_REQUEST_UPDATE_TRANSFER_WAREHOUSE_PENDING,
  STATUS_REQUEST_UPDATE_TRANSFER_WAREHOUSE_SUCCESS,
} from '../../utils/constants/store.constant';
import { stockTransferAsyncThunkTypeWithActionProps } from '../../utils/data/mapping/async-thunk-type-with-action-props.mapping';
import {
  bodyBuilderFulfilledAction,
  bodyBuilderPendingAction,
  bodyBuilderRejectedAction,
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
  sliceReduceHelper,
} from '../../utils/helpers/slice-reducer.helper';
import { getSliceNameFromDynamicStore } from '../creator-action/creator-action.helper';

import stockTransferActions from './stock-transfer.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  [SLICE_NAME_STOCK_TRANSFER_DETAILS]: null,
  [SLICE_NAME_STOCK_TRANSFER_LIST]: LocalStorage.get(LOCAL_STORAGE_STOCK_TRANSFER_LIST),
  [SLICE_NAME_STOCK_TRANSFER_PRINT_LIST]: LocalStorage.get(LOCAL_STORAGE_STOCK_TRANSFER_PRINT_LIST),
};

const stockTransferSlice = createSlice({
  name: NAME_STORE.STOCK_TRANSFER,
  initialState,
  reducers: {
    stockTransferActions,
    clearDataStockTransferSlice: (state, action) => {
      const { sliceName, localStorageName } = action.payload;

      if (localStorageName && LocalStorage.get(localStorageName)) {
        LocalStorage.remove(localStorageName);
      }

      return {
        ...state,
        [sliceName]: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    clearStockTransferSliceItem: (state, action) => {
      sliceReduceHelper.clearSliceItem({
        state,
        action,
      });
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      [SLICE_NAME_STOCK_TRANSFER_LIST]: LOCAL_STORAGE_STOCK_TRANSFER_LIST,
      [SLICE_NAME_STOCK_TRANSFER_PRINT_LIST]: LOCAL_STORAGE_STOCK_TRANSFER_PRINT_LIST,
    }),
  },
  extraReducers: (builder) => {
    // list stock transfer with dynamic store data
    builder.addCase(
      stockTransferActions.getStockTransferListWithDynamicStore.fulfilled,
      (state, action) => {
        const sliceName = getSliceNameFromDynamicStore(action.payload);

        if (!sliceName) return;

        state[sliceName] = action.payload[sliceName];
        state.statusRequest = STATUS_REQUEST_LIST_STOCK_TRANSFER_SUCCESS;
      },
    ),
      builder.addCase(
        stockTransferActions.getStockTransferListWithDynamicStore.pending,
        (state) => {
          state.statusRequest = STATUS_REQUEST_LIST_STOCK_TRANSFER_PENDING;
        },
      ),
      builder.addCase(
        stockTransferActions.getStockTransferListWithDynamicStore.rejected,
        (state, action) => {
          const sliceName = getSliceNameFromDynamicStore(action.payload);

          if (!sliceName) return;

          state[sliceName] = null;
          state.statusRequest = STATUS_REQUEST_LIST_STOCK_TRANSFER_FAILED;
        },
      ),
      // list refresh stock transfer with dynamic store data
      builder.addCase(
        stockTransferActions.getStockTransferListRefreshWithDynamicStore.fulfilled,
        (state, action) => {
          const sliceName = getSliceNameFromDynamicStore(action.payload);

          if (!sliceName) return;

          state[sliceName] = action.payload[sliceName];
          state.statusRequest = STATUS_REQUEST_LIST_STOCK_TRANSFER_SUCCESS;
        },
      ),
      builder.addCase(
        stockTransferActions.getStockTransferListRefreshWithDynamicStore.pending,
        (state) => {
          state.statusRequest = STATUS_REQUEST_LIST_STOCK_TRANSFER_PENDING;
        },
      ),
      builder.addCase(
        stockTransferActions.getStockTransferListRefreshWithDynamicStore.rejected,
        (state, action) => {
          const sliceName = getSliceNameFromDynamicStore(action.payload);

          if (!sliceName) return;

          state[sliceName] = null;
          state.statusRequest = STATUS_REQUEST_LIST_STOCK_TRANSFER_FAILED;
        },
      ),
      // stock transfer details
      builder.addCase(stockTransferActions.getStockTransferDetails.fulfilled, (state, action) => {
        state[SLICE_NAME_STOCK_TRANSFER_DETAILS] =
          action.payload[SLICE_NAME_STOCK_TRANSFER_DETAILS];
        state.statusRequest = STATUS_REQUEST_DETAIL_STOCK_TRANSFER_SUCCESS;
      }),
      builder.addCase(stockTransferActions.getStockTransferDetails.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_STOCK_TRANSFER_PENDING;
      }),
      builder.addCase(stockTransferActions.getStockTransferDetails.rejected, (state) => {
        state[SLICE_NAME_STOCK_TRANSFER_DETAILS] = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_STOCK_TRANSFER_FAILED;
      });
    // delete stock transfer
    builder.addCase(stockTransferActions.deleteStockTransfer.fulfilled, (state) => {
      state.statusRequest = STATUS_REQUEST_DELETE_STOCK_TRANSFER_SUCCESS;
    }),
      builder.addCase(stockTransferActions.deleteStockTransfer.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_STOCK_TRANSFER_PENDING;
      }),
      builder.addCase(stockTransferActions.deleteStockTransfer.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_STOCK_TRANSFER_FAILED;
      }),
      // add stock transfer
      builder.addCase(stockTransferActions.addTransferStock.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_TRANSFER_WAREHOUSE_SUCCESS;
      }),
      builder.addCase(stockTransferActions.addTransferStock.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_TRANSFER_WAREHOUSE_PENDING;
      }),
      builder.addCase(stockTransferActions.addTransferStock.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_TRANSFER_WAREHOUSE_FAILED;
      }),
      // add stock transfer version 2
      builder.addCase(stockTransferActions.addTransferStockV2.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_TRANSFER_WAREHOUSE_SUCCESS;
      }),
      builder.addCase(stockTransferActions.addTransferStockV2.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_TRANSFER_WAREHOUSE_PENDING;
      }),
      builder.addCase(stockTransferActions.addTransferStockV2.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_TRANSFER_WAREHOUSE_FAILED;
      }),
      // update transfer stock
      builder.addCase(stockTransferActions.updateTransferStock.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_TRANSFER_WAREHOUSE_SUCCESS;
      }),
      builder.addCase(stockTransferActions.updateTransferStock.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_TRANSFER_WAREHOUSE_PENDING;
      }),
      builder.addCase(stockTransferActions.updateTransferStock.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_TRANSFER_WAREHOUSE_FAILED;
      }),
      // enhancement for matching request status action on stock transfer
      // current: only include list
      builder.addMatcher(
        (action) => isFulfilledAction({ action, baseType: STOCK_TRANSFER_BASE_ACTION_TYPE }),
        (state, action) => {
          bodyBuilderFulfilledAction({
            state,
            action,
            mappingAsyncThunkTypeWithActionProps: stockTransferAsyncThunkTypeWithActionProps,
          });
        },
      ),
      builder.addMatcher(
        (action) => isPendingAction({ action, baseType: STOCK_TRANSFER_BASE_ACTION_TYPE }),
        (state, action) => {
          bodyBuilderPendingAction({
            state,
            action,
            mappingAsyncThunkTypeWithActionProps: stockTransferAsyncThunkTypeWithActionProps,
          });
        },
      ),
      builder.addMatcher(
        (action) => isRejectedAction({ action, baseType: STOCK_TRANSFER_BASE_ACTION_TYPE }),
        (state, action) => {
          bodyBuilderRejectedAction({
            state,
            action,
            mappingAsyncThunkTypeWithActionProps: stockTransferAsyncThunkTypeWithActionProps,
          });
        },
      );
  },
});

export const { actions, reducer } = stockTransferSlice;

export const { clearDataStockTransferSlice, clearStockTransferSliceItem } = actions;

export default reducer;
