export const DEFAULT_USER_ROLE_ALL = 'all';
export const DEFAULT_USER_ROLE_ADMINISTRATOR = 'administrator';
export const DEFAULT_USER_ROLE_OWNER = 'owner';
export const DEFAULT_USER_ROLE_USER = 'user';
export const DEFAULT_USER_ROLE_CASHIER = 'cashier';
export const DEFAULT_USER_ROLE_BANKER = 'banker';
export const DEFAULT_USER_ROLE_EXPENSE = 'expense';
export const DEFAULT_USER_ROLE_SALES = 'sales';
export const DEFAULT_USER_ROLE_PURCHASING = 'purchasing';
export const DEFAULT_USER_ROLE_INVENTORY = 'inventory';

export const DEFAULT_USER_ROLE_ADMINISTRATOR_CAPITALIZE = 'Administrator';
export const DEFAULT_USER_ROLE_OWNER_CAPITALIZE = 'Owner';
export const DEFAULT_USER_ROLE_USER_CAPITALIZE = 'User';
export const DEFAULT_USER_ROLE_CASHIER_CAPITALIZE = 'Cashier';

export const DEFAULT_USER_ROLE = 'User';
export const DEFAULT_CASHIER_ROLE = 'Cashier';
