import { isEmpty } from 'lodash';

import { PRODUCT_PRICE_ACCESS_PERMISSION_V2 } from '../../constants/permissions.constant';
import { DEFAULT_ACTION_MENU_UPDATE } from '../../default/action-menu.default';
import {
  DEFAULT_GRID_ACTION_COLLAPSE_TYPE,
  DEFAULT_GRID_ACTION_REMOVE_TYPE,
} from '../../default/grid-type.default';
import {
  DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
  DEFAULT_INPUT_TYPE_CALCULATION_NUMBER_INPUT,
  DEFAULT_INPUT_TYPE_COMMON_INPUT,
} from '../../default/input-type.default';
import {
  DEFAULT_KEY_NAME_PRICE_API_REF,
  DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
  DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER,
  DEFAULT_KEY_NAME_PRODUCT_NAME_API_REF,
  DEFAULT_KEY_NAME_QUANTITY_AVAILABLE_CONST,
  DEFAULT_KEY_NAME_QUANTITY_AVAILABLE_WITH_UNIT,
} from '../../default/object-keyname.default';
import { DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE } from '../../default/representation-data-type.default';
import { DEFAULT_CLASSNAME_INPUT_CENTER } from '../../default/styles-classname.default';
import autocompleteHelper from '../../helpers/component/autocomplete.helpers';

import { getUnitProductAutocompleteTransactionPropertiesPrimary } from './unit-product-properties';

// grid header product purchase return add and update
export const gridHeaderProductPurchaseReturn = [
  {
    name: DEFAULT_GRID_ACTION_COLLAPSE_TYPE,
    label: '',
    align: 'left',
    width: 20,
    gridProps: {
      xs: 0.75,
    },
    isButton: true,
  },
  {
    label: 'table.header-column.product-name',
    placeholder: 'placeholder.select-product',
    isUseMultipleNames: true,
    width: 250,
    minWidth: 150,
    gridProps: {
      xs: 4.5,
      display: 'flex',
      justifyContent: 'start',
    },
    isInput: true,
    keyInputName: DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
    keyInputContainerName: DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER,
    inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
    keyUniqueName: DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
    autocompleteProps: {
      getOptionLabel: (options) =>
        autocompleteHelper.getOptionLabel(options, DEFAULT_KEY_NAME_PRODUCT_NAME_API_REF),
    },
    isDisabled: ({ actionMenu }) => {
      return !!actionMenu || true;
    },
  },
  {
    name: 'quantity',
    label: 'table.header-column.product-quantity',
    placeholder: 'placeholder.calculation-number-default',
    align: 'left',
    width: 50,
    minWidth: 50,
    gridProps: {
      xs: 1,
      display: 'flex',
      justifyContent: 'center',
    },
    isInput: true,
    inputType: DEFAULT_INPUT_TYPE_CALCULATION_NUMBER_INPUT,
    keyInputName: 'quantity',
    keyMaximumInput: DEFAULT_KEY_NAME_QUANTITY_AVAILABLE_CONST,
    isUseFooterInput: true,
    footerInputLabel: 'typography.quantity-available',
    footerInputKeyName: DEFAULT_KEY_NAME_QUANTITY_AVAILABLE_WITH_UNIT,
    additionalOutlinedInput: {
      className: DEFAULT_CLASSNAME_INPUT_CENTER,
    },
    isReadOnly: ({ rowSelectedContainerData }) => {
      return isEmpty(rowSelectedContainerData[DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER]);
    },
  },
  {
    name: 'unit_destination',
    label: 'table.header-column.unit',
    placeholder: 'placeholder.product-unit',
    align: 'left',
    width: 50,
    minWidth: 50,
    gridProps: {
      xs: 1.5,
      display: 'flex',
      justifyContent: 'start',
    },
    ...getUnitProductAutocompleteTransactionPropertiesPrimary({}),
    isDisabled: ({ actionMenu }) => {
      return !actionMenu || false;
    },
  },
  {
    name: 'price',
    label: 'table.header-column.at-price',
    placeholder: 'placeholder.calculation-number-default',
    align: 'right',
    width: 75,
    minWidth: 75,
    gridProps: {
      xs: 1.75,
      display: 'flex',
      justifyContent: 'end',
    },
    isInput: true,
    inputType: DEFAULT_INPUT_TYPE_CALCULATION_NUMBER_INPUT,
    keyInputName: DEFAULT_KEY_NAME_PRICE_API_REF,
    isDisabled: ({ actionMenu, permissions }) => {
      return (
        !!actionMenu ||
        true ||
        (Array.isArray(permissions) && !permissions.includes(PRODUCT_PRICE_ACCESS_PERMISSION_V2))
      );
    },
  },
  {
    name: 'amount',
    label: 'table.header-column.amount',
    align: 'right',
    width: 100,
    minWidth: 80,
    gridProps: {
      xs: 1.75,
      display: 'block',
      overflow: 'auto',
      textAlign: 'right',
    },
    isTypography: true,
    representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
  },
  {
    name: DEFAULT_GRID_ACTION_REMOVE_TYPE,
    label: '',
    align: 'right',
    width: 25,
    gridProps: {
      xs: 0.75,
    },
    isButton: true,
    isDisabled: ({ actionMenu }) => {
      return actionMenu === DEFAULT_ACTION_MENU_UPDATE;
    },
  },
];

export const gridCollapseProductPurchaseReturn = [
  {
    gridProps: {
      xs: 0.75,
    },
  },
  {
    placeholder: 'placeholder.description',
    inputName: 'description',
    keyInputName: 'description',
    inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
    gridProps: {
      xs: 10.5,
    },
  },
  {
    gridProps: {
      xs: 0.75,
    },
  },
];
