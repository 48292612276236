import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_REFERENCE_NUMBER_DATA } from '../../utils/constants/storage.constant';
import {
  NAME_STATE_DETAIL_REFERENCE_NUMBER,
  NAME_STORE,
  STATUS_REQUEST_ADD_REFERENCE_NUMBER_DATA_FAILED,
  STATUS_REQUEST_ADD_REFERENCE_NUMBER_DATA_PENDING,
  STATUS_REQUEST_ADD_REFERENCE_NUMBER_DATA_SUCCESS,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_DELETE_REFERENCE_NUMBER_DATA_FAILED,
  STATUS_REQUEST_DELETE_REFERENCE_NUMBER_DATA_PENDING,
  STATUS_REQUEST_DELETE_REFERENCE_NUMBER_DATA_SUCCESS,
  STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_FAILED,
  STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_PENDING,
  STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_SUCCESS,
  STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_FAILED,
  STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_PENDING,
  STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_SUCCESS,
  STATUS_REQUEST_UPDATE_REFERENCE_NUMBER_DATA_FAILED,
  STATUS_REQUEST_UPDATE_REFERENCE_NUMBER_DATA_PENDING,
  STATUS_REQUEST_UPDATE_REFERENCE_NUMBER_DATA_SUCCESS,
} from '../../utils/constants/store.constant';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import {
  addReferenceNumber,
  deleteReferenceNumber,
  getDetailReferenceNumber,
  referenceNumberList,
  updateReferenceNumber,
} from './reference-number.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  referenceNumberData: LocalStorage.get(LOCAL_STORAGE_REFERENCE_NUMBER_DATA),
  [NAME_STATE_DETAIL_REFERENCE_NUMBER]: null,
};

const referenceNumberSlice = createSlice({
  name: NAME_STORE.REFERENCE_NUMBER,
  initialState,
  reducers: {
    referenceNumberList,
    getDetailReferenceNumber,
    addReferenceNumber,
    updateReferenceNumber,
    deleteReferenceNumber,
    clearReferenceNumber: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_REFERENCE_NUMBER_DATA);

      return {
        ...state,
        referenceNumberData: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      referenceNumberData: LOCAL_STORAGE_REFERENCE_NUMBER_DATA,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(referenceNumberList.fulfilled, (state, action) => {
      state.statusRequest = STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_SUCCESS;
      state.referenceNumberData = action.payload.referenceNumberData;
    }),
      builder.addCase(referenceNumberList.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_FAILED;
      }),
      builder.addCase(referenceNumberList.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_PENDING;
      }),
      builder.addCase(getDetailReferenceNumber.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_SUCCESS;

        state[NAME_STATE_DETAIL_REFERENCE_NUMBER] =
          action.payload[NAME_STATE_DETAIL_REFERENCE_NUMBER];
      }),
      builder.addCase(getDetailReferenceNumber.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_FAILED;
      }),
      builder.addCase(getDetailReferenceNumber.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_PENDING;
      }),
      builder.addCase(addReferenceNumber.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_REFERENCE_NUMBER_DATA_SUCCESS;
      }),
      builder.addCase(addReferenceNumber.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_REFERENCE_NUMBER_DATA_FAILED;
      }),
      builder.addCase(addReferenceNumber.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_REFERENCE_NUMBER_DATA_PENDING;
      }),
      builder.addCase(updateReferenceNumber.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_REFERENCE_NUMBER_DATA_SUCCESS;
      }),
      builder.addCase(updateReferenceNumber.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_REFERENCE_NUMBER_DATA_FAILED;
      }),
      builder.addCase(updateReferenceNumber.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_REFERENCE_NUMBER_DATA_PENDING;
      }),
      builder.addCase(deleteReferenceNumber.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_REFERENCE_NUMBER_DATA_SUCCESS;
      }),
      builder.addCase(deleteReferenceNumber.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_REFERENCE_NUMBER_DATA_FAILED;
      }),
      builder.addCase(deleteReferenceNumber.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_REFERENCE_NUMBER_DATA_PENDING;
      });
  },
});

export const { actions, reducer } = referenceNumberSlice;

export const { clearReferenceNumber } = actions;

export default reducer;
