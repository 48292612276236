// initial search transaction data
export const initialSearchQuotationRequest = () => {
  return {
    from_date: null,
    to_date: null,
    transaction_no: '',
    supplier_name: '',
    supplier_name_container: null,
    status: [],
    statusContainer: [],
  };
};
