import { LibraryBooks } from '@mui/icons-material';

import { PERMISSION_SALES_PAYMENT_ACCESS } from '../../constants/permissions.constant';

export const tooltipSalesPayment = (t) => {
  return [
    {
      name: 'detail-sales-payment',
      title: t('base.tooltip.detail'),
      permission: PERMISSION_SALES_PAYMENT_ACCESS,
    },
  ];
};

export const iconSalesPaymentList = [LibraryBooks];
