export const DEFAULT_TRANSACTION_TYPE_MANUAL = 'manual';
export const DEFAULT_TRANSACTION_TYPE_JOURNAL_ENTRY = 'journal_entry';

export const DEFAULT_TRANSACTION_TYPE_PURCHASE_DELIVERY = 'purchase_delivery';
export const DEFAULT_TRANSACTION_TYPE_PURCHASE_INVOICE = 'purchase_invoice';
export const DEFAULT_TRANSACTION_TYPE_PURCHASE_PAYMENT = 'purchase_payment';

// reference from API
export const DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_API_REF_TYPE = 'quotation_request';
export const DEFAULT_TRANSACTION_PURCHASE_ORDER_API_REF_TYPE = 'purchase_order';
export const DEFAULT_TRANSACTION_PURCHASE_DELIVERY_API_REF_TYPE = 'purchase_delivery';
export const DEFAULT_TRANSACTION_PURCHASE_INVOICE_API_REF_TYPE = 'purchase_invoice';
export const DEFAULT_TRANSACTION_PURCHASE_PAYMENT_API_REF_TYPE = 'purchase_payment';
export const DEFAULT_TRANSACTION_PURCHASE_RECEIVABLE_PAYMENT_API_REF_TYPE =
  'purchase_receivable_payment';
export const DEFAULT_TRANSACTION_PURCHASE_RETURN_API_REF_TYPE = 'purchase_return';

export const DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_TYPE = 'purchase-quotation-request';
export const DEFAULT_TRANSACTION_PURCHASE_PURCHASE_ORDER_TYPE = 'purchase-purchase-order';
export const DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE = 'purchase-purchase-delivery';
export const DEFAULT_TRANSACTION_PURCHASE_PURCHASE_INVOICE_TYPE = 'purchase-purchase-invoice';
export const DEFAULT_TRANSACTION_PURCHASE_PURCHASE_PAYMENT_TYPE = 'purchase-purchase-payment';
export const DEFAULT_TRANSACTION_PURCHASE_DEBIT_NOTE_TYPE = 'purchase-debit-note';
export const DEFAULT_TRANSACTION_PURCHASE_PURCHASE_RETURN_TYPE = 'purchase-purchase-return';
export const DEFAULT_TRANSACTION_PURCHASE_RECEIVABLE_PAYMENT_TYPE = 'purchase-receivable-payment';

// api ref where getting from api reference
export const DEFAULT_TRANSACTION_SALES_QUOTATION_API_REF_TYPE = 'sales_quotation';
export const DEFAULT_TRANSACTION_SALES_ORDER_API_REF_TYPE = 'sales_order';
export const DEFAULT_TRANSACTION_SALES_DELIVERY_API_REF_TYPE = 'sales_delivery';
export const DEFAULT_TRANSACTION_SALES_INVOICE_API_REF_TYPE = 'sales_invoice';
export const DEFAULT_TRANSACTION_SALES_PAYMENT_API_REF_TYPE = 'sales_payment';
export const DEFAULT_TRANSACTION_SALES_PAYABLE_PAYMENT_API_REF_TYPE = 'sales_payable_payment';
export const DEFAULT_TRANSACTION_SALES_RETURN_API_REF_TYPE = 'sales_return';

export const DEFAULT_TRANSACTION_SALES_SALES_QUOTATION_TYPE = 'sales-sales-quotation';
export const DEFAULT_TRANSACTION_SALES_SALES_ORDER_TYPE = 'sales-sales-order';
export const DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE = 'sales-sales-delivery';
export const DEFAULT_TRANSACTION_SALES_SALES_INVOICE_TYPE = 'sales-sales-invoice';
export const DEFAULT_TRANSACTION_SALES_SALES_PAYMENT_TYPE = 'sales-sales-payment';
export const DEFAULT_TRANSACTION_SALES_SALES_RETURN_TYPE = 'sales-sales-return';
export const DEFAULT_TRANSACTION_SALES_CREDIT_NOTE_TYPE = 'sales-credit-note';
export const DEFAULT_TRANSACTION_SALES_PAYABLE_PAYMENT_TYPE = 'sales-payable-payment';

export const DEFAULT_TRANSACTION_TYPE_SALES_DELIVERY = 'sales_delivery';
export const DEFAULT_TRANSACTION_TYPE_SALES_INVOICE = 'sales_invoice';
export const DEFAULT_TRANSACTION_TYPE_SALES_PAYMENT = 'sales_payment';

export const DEFAULT_TRANSACTION_TYPE_TRANFER_CASH = 'transfer_cash';
export const DEFAULT_TRANSACTION_TYPE_RECEIPT_CASH = 'receipt_cash';
export const DEFAULT_TRANSACTION_TYPE_PAYMENT_CASH = 'payment_cash';

export const DEFAULT_TRANSACTION_TYPE_ASSET_ENTRY = 'asset_entry';
export const DEFAULT_TRANSACTION_TYPE_ASSET_RESIDUAL = 'asset_residual';
export const DEFAULT_TRANSACTION_TYPE_ASSET_DEPRECIATION = 'asset_depreciation';

export const DEFAULT_TRANSACTION_ASSET_DEPRECIATION_API_REF_TYPE = 'asset_depreciation';
export const DEFAULT_TRANSACTION_ASSET_ENTRY_API_REF_TYPE = 'asset_entry';
export const DEFAULT_TRANSACTION_ASSET_RESIDUAL_API_REF_TYPE = 'asset_residual';

export const DEFAULT_TRANSACTION_BEGINNING_BALANCE_API_REF = 'beginning_balance';
export const DEFAULT_TRANSACTION_CLOSING_BOOK_API_REF_TYPE = 'closing_book';

export const DEFAULT_TRANSACTION_EXPENSE_API_REF_TYPE = 'expense';
export const DEFAULT_TRANSACTION_JOURNAL_ENTRY_API_REF_TYPE = 'journal_entry';

export const DEFAULT_TRANSACTION_RECEIPT_CASH_API_REF_TYPE = 'receipt_cash';
export const DEFAULT_TRANSACTION_PAYMENT_CASH_API_REF_TYPE = 'payment_cash';
export const DEFAULT_TRANSACTION_TRANSFER_CASH_API_REF_TYPE = 'transfer_cash';

export const DEFAULT_TRANSACTION_PURCHASE_HANDOVER_API_REF_TYPE = 'purchase_handover';
export const DEFAULT_TRANSACTION_SALES_HANDOVER_API_REF_TYPE = 'sales_handover';

export const DEFAULT_TRANSACTION_STOCK_ADJUSTMENT_API_REF_TYPE = 'stock_adjustment';

export const DEFAULT_TRANSACTION_TYPE_PURCHASE_HANDOVER = 'purchase_handover';
export const DEFAULT_TRANSACTION_TYPE_SALES_HANDOVER = 'sales_handover';

export const DEFAULT_TRANSACTION_PURCHASE_TYPE = 'purchase';
export const DEFAULT_TRANSACTION_SALES_TYPE = 'sales';

// basic transaction invoice type
export const transactionInvoiceTypeSalesInvoice = 'sales-invoice';
export const transactionInvoiceTypePurchaseInvoice = 'purchase-invoice';
