import { createAsyncThunk } from '@reduxjs/toolkit';

import emailServices from '../../services/API/email/email.service';
import messageHelper from '../message/message.helper';

// user hitting email resend
export const resendEmailUser = createAsyncThunk('email/resend', async (email, thunkAPI) => {
  try {
    const response = await emailServices.resendEmailUser(email);

    if (!response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue(response);
    }

    const { data } = response;

    const responseMessage = messageHelper.getMessageFromResponseData(data);

    if (!responseMessage) {
      messageHelper.successMessageUnauthorizated(
        thunkAPI.dispatch,
        'success.email.resend',
        'success.email.resend',
      );
    } else {
      messageHelper.successMessageUnauthorizated(
        thunkAPI.dispatch,
        responseMessage,
        'success.email.resend',
      );
    }

    return thunkAPI.fulfillWithValue(data);
  } catch (error) {
    const { response } = error;

    if (!response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue(response);
    }

    const { data } = response;

    const responseMessage = messageHelper.getMessageFromResponseData(data);

    if (!responseMessage) {
      messageHelper.failedMessage(thunkAPI.dispatch, 'error.email.resend', 'error.email.resend');
    } else {
      messageHelper.failedMessage(thunkAPI.dispatch, responseMessage, '');
    }

    return thunkAPI.rejectWithValue(data);
  }
});
