import {
  FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN,
  FORMAT_DATE_COLUMN,
} from '../../../components/table-row/TableRowMultipleTableDataPureMarkup/TableRowMultipleTableDataPureMarkup';
import { FORMAT_CURRENCY_AUTO_COMMA_COLUMN } from '../../../components/table-row/TableRowPrimary/TableRowPrimary';

import { primaryTableDataCalculationNumber } from './additional-styles';

// columns key value table
export const columnsTableKeyValuePrintPurchasePaymentDetails = [
  {
    headerTranslated: 'No.',
    valueKeyName: 'purchase_payment_no',
  },
  {
    header: 'table.header-column.date',
    valueKeyName: 'transaction_date',
    formatValue: FORMAT_DATE_COLUMN,
  },
  {
    header: 'table.header-column.supplier',
    valueKeyName: 'supplier_name',
  },
  {
    header: 'table.header-column.address',
    valueKeyName: 'supplier_address',
  },
];

export const columnsTableInvoicePurchasePaymentDetails = (t) => {
  return [
    {
      isAutoIncrementColumn: true,
      label: t('No.'),
      align: 'center',
      style: {
        width: 10,
        maxWidth: 20,
      },
    },
    {
      name: 'purchase_invoice_no',
      label: t('table.header-column.purchase-invoice-no'),
      style: {
        minWidth: 'auto',
        width: 50,
        maxWidth: 55,
      },
    },
    {
      name: 'expired_date',
      label: t('table.header-column.due-date'),
      style: {
        minWidth: 'auto',
        width: 50,
        maxWidth: 55,
      },
      align: 'right',
      formatData: FORMAT_DATE_COLUMN,
    },
    {
      name: 'purchase_invoice_amount',
      label: t('table.header-column.total-bill'),
      style: {
        minWidth: 'auto',
        width: 192,
        maxWidth: 300,
      },
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'amount_available',
      label: t('table.header-column.remaining-bill'),
      style: {
        minWidth: 'auto',
        width: 192,
        maxWidth: 300,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'amount',
      label: t('table.header-column.amount-paid'),
      style: {
        minWidth: 'auto',
        width: 192,
        maxWidth: 300,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
  ];
};

export const columnsTableFooterInvoicePurchasePaymentDetails = (t) => {
  return [
    [
      {
        isEmptyTableData: true,
        colSpan: 4,
      },
      {
        isLabel: true,
        label: t('typography.total'),
      },
      {
        isValue: true,
        valueKeyName: 'amount',
        formatValue: FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN,
        tableDataStyle: primaryTableDataCalculationNumber,
      },
    ],
  ];
};

export const columnsTableFooterPurchasePaymentDetails = (t) => {
  return [
    {
      name: 'description',
      label: t('table.header-column.memo-colon'),
      style: {
        minWidth: 100,
        width: 360,
        maxWidth: 365,
        height: 56,
        verticalAlign: 'top',
      },
      isUseTableHeaderStyle: false,
    },
  ];
};
