import {
  messageConstants,
  TYPE_MESSAGES,
  VARIANT_MESSAGES,
} from '../../utils/constants/message.constant';

import { setMessage } from './message.slice';

function getMessageFromResponseData(responseData) {
  const { CONTAINER_MESSAGE, TRANSLATION_MESSAGE } = messageConstants;

  if (!responseData[CONTAINER_MESSAGE] || typeof responseData[CONTAINER_MESSAGE] === 'string') {
    if (typeof responseData[CONTAINER_MESSAGE] === 'string') return responseData[CONTAINER_MESSAGE];

    // checking response data is string
    // not an object or an array
    if (typeof responseData !== 'object') {
      return responseData;
    }

    return responseData[0];
  }

  // getting user message from main response container message
  const userMessage = responseData[CONTAINER_MESSAGE];

  if (Array.isArray(userMessage) && userMessage.length) return userMessage[0];

  // key object on user message
  const getKeysUserMessage = Object.keys(userMessage);

  // checking is keys message contained translation message
  const isKeysMessageOnTranslationMessage = getKeysUserMessage.some((userMsg) =>
    TRANSLATION_MESSAGE.includes(userMsg),
  );

  // search user message that provide translation and return them
  if (isKeysMessageOnTranslationMessage) {
    return userMessage;
  }

  return getMessageFromResponseData(userMessage[getKeysUserMessage[0]]);
}

function createMessage(
  primaryText,
  secondaryText,
  code,
  isShowingImage,
  variant,
  typeMessage,
  paramsTextPrimary,
  paramsTextSecondary,
  isShowPreviewButton = false,
  linkNavigatePreviewButton = '',
  isUseLimitTimeDialogMessageAppear = true,
  dispatchAttribute = {},
) {
  return {
    primaryText,
    secondaryText,
    code,
    isShowingImage,
    variant,
    typeMessage,
    paramsTextPrimary,
    paramsTextSecondary,
    isShowPreviewButton,
    linkNavigatePreviewButton,
    isUseLimitTimeDialogMessageAppear,
    dispatchAttribute,
  };
}

// authorizated message, only with snackbar and not contain secondary text
// authorizated mean user has been logged in
function successMessageAuthorizated(
  dispatch,
  primaryText,
  secondaryText = '',
  paramsTextPrimary,
  paramsTextSecondary,
) {
  return dispatch(
    setMessage(
      createMessage(
        primaryText,
        secondaryText,
        200,
        false,
        VARIANT_MESSAGES.SUCCESS,
        TYPE_MESSAGES.SNACKBAR,
        paramsTextPrimary,
        paramsTextSecondary,
      ),
    ),
  );
}

/**
 *
 * @param   { function }     dispatch redux function for triggering action
 * @param   { string }       primaryText translation message for primary text
 * @param   { string }       secondaryText translation message for secondary text
 * @param   { object }       paramsTextPrimary params text for passing on primary text
 * @param   { object }       paramsTextSecondary params text for passing on secondary text
 * @param   { string }       linkNavigatePreviewButton navigation link on button preview
 * @param   { bool }         isShowPreviewButton decession of showing preview button or not
 * @returns
 *      action for set message with create message
 */
function successMessageAuthorizatedWithPreviewButton(
  dispatch,
  primaryText,
  secondaryText = '',
  paramsTextPrimary,
  paramsTextSecondary,
  linkNavigatePreviewButton,
  isShowPreviewButton = true,
) {
  return dispatch(
    setMessage(
      createMessage(
        primaryText,
        secondaryText,
        200,
        false,
        VARIANT_MESSAGES.SUCCESS,
        TYPE_MESSAGES.SNACKBAR,
        paramsTextPrimary,
        paramsTextSecondary,
        isShowPreviewButton,
        linkNavigatePreviewButton,
      ),
    ),
  );
}

// dialog message when user was logged in
function successMessageUnauthorizated(
  dispatch,
  primaryText,
  secondaryText,
  paramsTextPrimary,
  paramsTextSecondary,
) {
  return dispatch(
    setMessage(
      createMessage(
        primaryText,
        secondaryText,
        200,
        true,
        VARIANT_MESSAGES.SUCCESS,
        TYPE_MESSAGES.DIALOG,
        paramsTextPrimary,
        paramsTextSecondary,
      ),
    ),
  );
}

// failed mesaage for all situation
function failedMessage(
  dispatch,
  primaryText,
  secondaryText,
  isShowingImage = true,
  paramsTextPrimary = {},
  paramsTextSecondary = {},
) {
  return dispatch(
    setMessage(
      createMessage(
        primaryText,
        secondaryText,
        400,
        isShowingImage,
        VARIANT_MESSAGES.ERROR,
        secondaryText && isShowingImage ? TYPE_MESSAGES.DIALOG : TYPE_MESSAGES.SNACKBAR,
        paramsTextPrimary,
        paramsTextSecondary,
      ),
    ),
  );
}

function unauthorizedMessage(dispatch, primaryText, secondaryText, isShowingImage = true) {
  return dispatch(
    setMessage(
      createMessage(
        primaryText,
        secondaryText,
        401,
        isShowingImage,
        VARIANT_MESSAGES.FORBIDDEN,
        TYPE_MESSAGES.DIALOG,
      ),
    ),
  );
}

function forbiddenMessage(dispatch, primaryText, secondaryText, isShowingImage = true) {
  return dispatch(
    setMessage(
      createMessage(
        primaryText,
        secondaryText,
        403,
        isShowingImage,
        VARIANT_MESSAGES.FORBIDDEN,
        TYPE_MESSAGES.DIALOG,
      ),
    ),
  );
}

function forbiddenMessageWithoutTimeLimitAppear(
  dispatch,
  primaryText,
  secondaryText,
  isShowingImage = true,
) {
  return dispatch(
    setMessage(
      createMessage(
        primaryText,
        secondaryText,
        403,
        isShowingImage,
        VARIANT_MESSAGES.FORBIDDEN,
        TYPE_MESSAGES.DIALOG,
        {},
        {},
        false,
        '',
        false,
      ),
    ),
  );
}

function forbiddenMessageWithoutTimeLimitAppearUsingDispatchAttribute(
  dispatch,
  primaryText,
  secondaryText,
  isShowingImage = true,
  dispatchAttribute,
) {
  return dispatch(
    setMessage(
      createMessage(
        primaryText,
        secondaryText,
        403,
        isShowingImage,
        VARIANT_MESSAGES.FORBIDDEN,
        TYPE_MESSAGES.DIALOG,
        {},
        {},
        false,
        '',
        false,
        dispatchAttribute,
      ),
    ),
  );
}

function serverInternalError(dispatch) {
  return dispatch(
    setMessage(
      createMessage(
        'error.internal-server-error',
        'error.internal-server-error',
        500,
        true,
        VARIANT_MESSAGES.ERROR,
        TYPE_MESSAGES.DIALOG,
      ),
    ),
  );
}

const messageHelper = {
  getMessageFromResponseData,
  successMessageAuthorizated,
  successMessageAuthorizatedWithPreviewButton,
  successMessageUnauthorizated,
  failedMessage,
  unauthorizedMessage,
  forbiddenMessage,
  forbiddenMessageWithoutTimeLimitAppear,
  forbiddenMessageWithoutTimeLimitAppearUsingDispatchAttribute,
  serverInternalError,
};

export default messageHelper;
