import {
  FORMAT_COMMON_COLUMN,
  FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
  FORMAT_DATE_COLUMN,
} from '../../../components/table-row/TableRowPureMarkup/TableRowPureMarkup';

import { tersierParagraph } from './additional-styles';

// column report journal list that handling table head with translation
export const columnsTableReportJournal = (t) => {
  return [
    {
      name: 'transaction_no',
      label: t('dashboard.report.general-journal.table.header-column.transaction'),
      format: 'normal',
      width: 1400,
    },
    {
      name: 'debit_amount',
      align: 'right',
      label: t('dashboard.report.general-journal.table.header-column.debit'),
      format: 'normal-currency',
      width: 200,
      maxWidth: 250,
    },
    {
      name: 'credit_amount',
      align: 'right',
      label: t('dashboard.report.general-journal.table.header-column.credit'),
      format: 'normal-currency',
      width: 200,
      maxWidth: 250,
    },
  ];
};

// column header when usage table row pure markup
export const columnsTableReportJournalForTableRowPureMarkup = (t) => {
  return [
    {
      name: 'transaction_date',
      label: t('dashboard.report.general-journal.table.header-column.transaction'),
      style: {
        minWidth: 5,
        width: 5,
        maxWidth: 10,
      },
      tableDataBodyStyle: {
        borderWidth: '1px 0px 1px 1px',
      },
      formatData: FORMAT_DATE_COLUMN,
      isUseFormatStyleOnRow: true,
      hideTableHeaderHorizontalBorder: true,
      substituteNotExistValue: '',
    },
    {
      name: 'transaction_no',
      style: {
        minWidth: 5,
        width: 5,
        maxWidth: 10,
      },
      tableDataBodyStyle: {
        borderWidth: '1px 0px',
      },
      styleParagraph: tersierParagraph,
      formatData: FORMAT_COMMON_COLUMN,
      isUseFormatStyleOnRow: true,
      hideTableHeaderHorizontalBorder: true,
      substituteNotExistValue: '',
    },
    {
      name: 'transaction_description',
      style: {
        minWidth: 105,
        width: 105,
        maxWidth: 110,
      },
      tableDataBodyStyle: {
        borderWidth: '1px 1px 1px 0px',
      },
      formatData: FORMAT_COMMON_COLUMN,
      isUseFormatStyleOnRow: true,
      hideTableHeaderHorizontalBorder: true,
    },
    {
      name: 'debit_amount',
      label: t('dashboard.report.general-journal.table.header-column.debit'),
      style: {
        minWidth: 10,
        width: 10,
        maxWidth: 15,
      },
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      isUseZeroNumberWhenNull: false,
      align: 'right',
      isUseFormatStyleOnRow: true,
      substituteNotExistValue: '',
    },
    {
      name: 'credit_amount',
      label: t('dashboard.report.general-journal.table.header-column.credit'),
      style: {
        minWidth: 10,
        width: 10,
        maxWidth: 15,
      },
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      isUseZeroNumberWhenNull: false,
      align: 'right',
      isUseFormatStyleOnRow: true,
      substituteNotExistValue: '',
    },
  ];
};

export const correspondChangeKeynameJournalEntryToTransaction = {
  account_code: 'transaction_no',
  account_name: 'transaction_description',
};
