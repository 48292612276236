import * as React from 'react';
function SvgIconMenuReportGeneralLedger(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.857 68.464c0 .415-.192.75-.428.75-.237 0-.429-.335-.429-.75 0-.414.192-.75.429-.75.236 0 .428.336.428.75zM6.857 67.714h58.286c.114 0 .222.08.303.22.08.14.125.331.125.53 0 .2-.045.39-.125.53-.08.141-.19.22-.303.22H6.857c-.114 0-.223-.079-.303-.22a1.094 1.094 0 01-.125-.53c0-.199.045-.39.125-.53s.19-.22.303-.22zM2.571 67.714h2.143c.114 0 .223.08.303.22.08.14.126.331.126.53 0 .2-.045.39-.126.53-.08.141-.19.22-.303.22H2.571c-.113 0-.222-.079-.303-.22a1.094 1.094 0 01-.125-.53c0-.199.045-.39.125-.53s.19-.22.303-.22zM69.429 67.714h-2.143c-.114 0-.223.08-.303.22-.08.14-.126.331-.126.53 0 .2.045.39.126.53.08.141.19.22.303.22h2.143c.113 0 .222-.079.303-.22.08-.14.125-.33.125-.53 0-.199-.045-.39-.125-.53s-.19-.22-.303-.22zM72 68.464c0 .415-.192.75-.429.75-.236 0-.428-.335-.428-.75 0-.414.192-.75.428-.75.237 0 .429.336.429.75zM45.297 24.75H18.339a.902.902 0 00-.657.289 1.022 1.022 0 00-.272.7c0 .262.097.513.272.699.174.185.41.29.657.29h26.958a.902.902 0 00.657-.29c.175-.186.273-.437.273-.7 0-.262-.098-.514-.273-.7a.902.902 0 00-.657-.289zM18.34 31.673h20.45a.902.902 0 00.657-.29c.175-.185.272-.437.272-.699 0-.262-.097-.514-.272-.7a.902.902 0 00-.657-.29H18.34a.902.902 0 00-.658.29 1.022 1.022 0 00-.272.7c0 .262.097.514.272.7.174.185.41.29.657.29z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.34 34.64H41v1.979H18.34a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.7c0-.261.097-.513.272-.699a.902.902 0 01.657-.29z'
        fill='#000'
      />
      <path
        d='M45.297 36.619a.902.902 0 00.657-.29c.175-.185.273-.437.273-.7 0-.261-.098-.513-.273-.699a.902.902 0 00-.657-.29H41v1.979h4.297zM18.34 41.565h20.45a.902.902 0 00.657-.29c.175-.185.272-.437.272-.7 0-.262-.097-.513-.272-.699a.902.902 0 00-.657-.29H18.34a.902.902 0 00-.658.29 1.022 1.022 0 00-.272.7c0 .262.097.514.272.699.174.186.41.29.657.29z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.34 44.532H34.5V46.5l-16.16.01a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.699c0-.262.097-.514.272-.7a.902.902 0 01.657-.289zM34.5 51.456H18.34a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.699c0-.262.097-.514.272-.7a.902.902 0 01.657-.29H34.5v1.98zM18.34 54.424H33v1.978H18.34a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.7c0-.261.097-.513.272-.699a.902.902 0 01.657-.29zM18.34 59.37H33v1.978H18.34a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.7c0-.262.097-.513.272-.699a.902.902 0 01.657-.29z'
        fill='#000'
      />
      <path
        d='M45.297 14.858H18.339a.902.902 0 00-.657.29 1.022 1.022 0 00-.272.699c0 .262.097.514.272.7.174.185.41.289.657.289h26.958a.902.902 0 00.657-.29c.175-.185.273-.437.273-.7 0-.261-.098-.513-.273-.699a.902.902 0 00-.657-.29zM18.34 21.782h20.45a.902.902 0 00.657-.29c.175-.186.272-.437.272-.7 0-.262-.097-.513-.272-.699a.902.902 0 00-.657-.29H18.34a.902.902 0 00-.658.29 1.022 1.022 0 00-.272.7c0 .262.097.514.272.699.174.185.41.29.657.29z'
        fill='#000'
      />
      <path
        d='M35 69v-2.34H12.263a2.807 2.807 0 01-1.86-.685c-.494-.439-.771-1.034-.771-1.654V11.679c0-.62.277-1.215.77-1.654a2.807 2.807 0 011.861-.685h39.474c.698 0 1.367.246 1.86.685.494.439.771 1.034.771 1.654V40H57V11.68c-.002-1.241-.557-2.43-1.543-3.308-.987-.877-2.325-1.37-3.72-1.372H12.263c-1.395.001-2.733.495-3.72 1.372C7.557 9.25 7.002 10.44 7 11.68v52.642c.002 1.24.557 2.43 1.543 3.307.987.877 2.325 1.37 3.72 1.372H35z'
        fill='#000'
      />
      <path
        d='M48.286 48c-.232 0-.464.167-.464.5v1c-.764 0-1.393.678-1.393 1.5 0 .823.63 1.5 1.393 1.5h.928c.266 0 .465.215.465.5 0 .286-.2.5-.465.5h-1.857c-.31 0-.464.25-.464.5s.154.5.464.5h.929v1c0 .334.232.5.464.5s.464-.166.464-.5v-1c.764 0 1.393-.677 1.393-1.5 0-.822-.629-1.497-1.393-1.5h-.928c-.265.001-.465-.214-.465-.5 0-.285.2-.5.465-.5h1.857s.464 0 .464-.5-.464-.5-.464-.5h-.929v-1c0-.333-.232-.5-.464-.5zm7.893-6c-.764 0-1.393.678-1.393 1.5 0 .23.054.446.142.642l-1.87 2.01a1.287 1.287 0 00-.593-.152c-.764 0-1.393.678-1.393 1.5 0 .823.63 1.5 1.393 1.5.764 0 1.393-.677 1.393-1.5 0-.23-.053-.445-.14-.64l1.866-2.011c.182.094.382.151.595.151.214 0 .414-.058.596-.152l1.867 2.01a1.565 1.565 0 00-.141.642c0 .823.629 1.5 1.393 1.5s1.393-.677 1.393-1.5c0-.23-.054-.446-.142-.641l1.867-2.011c.182.094.383.152.596.152.764 0 1.393-.677 1.393-1.5 0-.822-.629-1.5-1.393-1.5s-1.393.678-1.393 1.5c0 .23.054.447.142.643l-1.868 2.009a1.288 1.288 0 00-.595-.152c-.213 0-.414.057-.595.152l-1.867-2.011c.087-.196.14-.411.14-.64 0-.823-.629-1.5-1.393-1.5V42zm0 1c.262 0 .465.218.465.5 0 .282-.203.5-.465.5s-.464-.218-.464-.5c0-.282.202-.5.464-.5zm7.43 0c.261 0 .464.218.464.5 0 .282-.203.5-.465.5s-.464-.218-.464-.5c0-.282.202-.5.464-.5zm-11.144 4c.262 0 .464.218.464.5 0 .282-.202.5-.464.5S52 47.782 52 47.5c0-.282.202-.5.464-.5zm7.429 0c.262 0 .464.218.464.5 0 .282-.202.5-.464.5s-.465-.218-.465-.5c0-.282.203-.5.465-.5zM48.286 63.5c0 .5.464.5.464.5h4.644c.31 0 .464-.25.464-.5s-.155-.5-.464-.5H48.75s-.464 0-.464.5z'
        fill='#000'
      />
      <path
        d='M42.25 42C40.458 42 39 43.57 39 45.5v19c0 1.931 1.458 3.501 3.25 3.501h16.715c.258.001.465-.222.464-.5v-17c0-.277-.207-.5-.464-.5-.256 0-.464.223-.464.5V67h-2.786v-5h1.393c.256 0 .463-.224.464-.5v-14c0-.277-.208-.5-.464-.5s-.464.223-.464.5V61H42.25c-.91 0-1.731.405-2.321 1.056V45.5c0-1.394 1.027-2.5 2.321-2.5h11.144c.256 0 .464-.224.464-.5s-.208-.5-.464-.5H42.25zm0 20h12.536v5.001H42.25c-1.293 0-2.32-1.105-2.32-2.5-.001-1.394 1.026-2.5 2.32-2.5z'
        fill='#000'
      />
      <path
        d='M41.786 65.5c0 .501.464.5.464.5h4.643c.31 0 .465-.249.465-.5 0-.25-.155-.5-.465-.5H42.25s-.464 0-.464.5zM47.822 65.5c0 .501.464.5.464.5l4.179.001c.31 0 .464-.25.464-.5s-.155-.5-.464-.5h-4.18s-.463 0-.463.5zM47.822 63.5c0 .501-.465.5-.465.5H43.18c-.31 0-.465-.249-.465-.5 0-.25.155-.5.465-.5h4.178s.465 0 .465.5z'
        fill='#000'
      />
    </svg>
  );
}
export default SvgIconMenuReportGeneralLedger;
