import { createAsyncThunk } from '@reduxjs/toolkit';

import authService from '../../services/API/auth/auth.service';
import managementCompanyServices from '../../services/API/management-company/management-company.service';
import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import tokenServices from '../../services/modules/Token/Token.service';
import {
  LOCAL_STORAGE_PRIMARY_TOKEN,
  LOCAL_STORAGE_SECONDARY_TOKEN,
  LOCAL_STORAGE_USER,
  storageConstants,
} from '../../utils/constants/storage.constant';
import {
  SLICE_NAME_APPLICATION_TYPE,
  STATUS_REQUEST_EMAIL_RESEND_PENDING,
} from '../../utils/constants/store.constant';
import {
  DEFAULT_APPLICATION_TYPE_COMPANY_SETUP,
  DEFAULT_APPLICATION_TYPE_PROFESSIONAL,
} from '../../utils/default/application-configs.default';
import { companySetupFinishStep } from '../../utils/default/company-setup-steps';
import {
  DEFAULT_KEY_NAME_CONTAINER_COMBINE_RESPONSE,
  DEFAULT_KEY_NAME_LOGIN_SETUP_API_REF,
} from '../../utils/default/object-keyname.default';
import { promiseExecutionHelper } from '../../utils/helpers/promise-execution.helper';
import { clearAccountCategory, clearAccountData } from '../account/account.slice';
import { actions as accountActions } from '../account/account.slice';
import { actions as subAccountActions } from '../account/sub-account.slice';
import { switchApplication } from '../application/application.slice';
import { actions as assetActions } from '../asset/asset.slice';
import { actions as attachmentActions } from '../attachment/attachment.slice';
import { actions as bankActions } from '../bank/bank.slice';
import { actions as beginningBalanceActions } from '../beginning-balance/beginning-balance.slice';
import { actions as billingActions } from '../billing/billing.slice';
import { actions as cashBankActions } from '../cash-bank/cash-bank.slice';
import { actions as chartActions } from '../chart/chart.slice';
import { actions as closingBookActions } from '../closing-book/closing-book.slice';
import { clearCompany } from '../company/company.slice';
import { actions as contactActions } from '../contact/contact.slice';
import { creatorAddActionWithStoreDataOnSlice } from '../creator-action/creator-action.helper';
import { actions as expenseActions } from '../expense/expense.slice';
import { actions as indoRegionalActions } from '../indo-regional/indo-regional.slice';
import { actions as journalEntryActions } from '../journal-entry/journal-entry.slice';
import { clearManagementCompany } from '../management-company/management-company.slice';
import { actions as managementCompanyActions } from '../management-company/management-company.slice';
import { clearManagementRoles } from '../management-role/management-role.slice';
import { actions as managementRoleActions } from '../management-role/management-role.slice';
import { listUsers } from '../management-user/management-user.action';
import { clearManagementUsersData } from '../management-user/management-user.slice';
import { actions as managementUserActions } from '../management-user/management-user.slice';
import messageHelper from '../message/message.helper';
import { actions as minutesOfActions } from '../minutes-of-handover/minutes-of-handover.slice';
import { actions as notificationActions } from '../notification/notification.slice';
import { clearProductCategory, clearProductData } from '../product/product.slice';
import { actions as productActions } from '../product/product.slice';
import { actions as provinceActions } from '../province/province.slice';
import { actions as purchaseActions } from '../purchase/purchase.slice';
import { actions as referenceNumberActions } from '../reference-number/reference-number.slice';
import { actions as regionalActions } from '../regional/regional.slice';
import { actions as reportActions } from '../report/report.slice';
import { actions as salesActions } from '../sales/sales.slice';
import { actions as stockAdjustmentActions } from '../stock-adjustment/stock-adjustment.slice';
import { actions as stockTransferActions } from '../stock-transfer/stock-transfer.slice';
import { actions as tagActions } from '../tag/tag.slice';
import { actions as transactionActions } from '../transaction/transaction.slice';
import { clearUserCompany, clearUserProfile } from '../user/user.slice';
import { actions as userActions } from '../user/user.slice';
import { actions as userPermissionActions } from '../user-permission/user-permission.slice';
import { actions as warehouseActions } from '../warehouse/warehouse.slice';

// handling login user to server
export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password, signalAborted }, thunkAPI) => {
    try {
      const response = await authService.login(email, password, signalAborted);

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      // parsing data response
      const { data: responseData, token } = response.data;
      let data = { ...responseData };

      // adding permission into data
      if (tokenServices.parseJwt(token)) {
        data.permissions = tokenServices.parseJwt(token).permission;
      }

      if (!data.permissions && responseData) {
        data.permissions = responseData.role_menu;
      }

      // setting token and user data
      if (token) {
        LocalStorage.set(LOCAL_STORAGE_PRIMARY_TOKEN, token);
        LocalStorage.set(LOCAL_STORAGE_SECONDARY_TOKEN, token);
      }

      if (data) {
        LocalStorage.set(LOCAL_STORAGE_USER, data);
      }

      return { data, token };
    } catch (error) {
      const { response } = error;

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      const { data, status } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      // response message from front-end
      if (!responseMessage) {
        messageHelper.failedMessage(thunkAPI.dispatch, 'error.login', 'error.login');
      }

      // showing failed message when login is forbidden
      if (status === 403 && responseMessage.ID.includes('Verifikasi')) {
        messageHelper.forbiddenMessageWithoutTimeLimitAppearUsingDispatchAttribute(
          thunkAPI.dispatch,
          responseMessage,
          'error.login-forbidden',
          true,
          {
            rootState: 'email',
            actionName: 'resendEmailUser',
            statusRequestLoading: STATUS_REQUEST_EMAIL_RESEND_PENDING,
            paramsAction: email,
          },
        );
      }

      // show message failed from server
      else {
        messageHelper.failedMessage(thunkAPI.dispatch, responseMessage, 'error.login');
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// move company user
export const moveCompany = createAsyncThunk('auth/moveCompany', async (company_id, thunkAPI) => {
  try {
    const response = await authService.moveCompany(company_id);

    if (!response) {
      return thunkAPI.rejectWithValue(response);
    }

    // changing token to refresh_token
    const { data: newData, refresh_token: token } = response.data;

    let data = { ...newData };

    if (tokenServices.parseJwt(token)) {
      data.permissions = tokenServices.parseJwt(token).permission;
    }

    if (!data.permissions && newData) {
      data.permissions = newData.role_menu;
    }

    // set email, that for avoiding crash on menu and company
    if (LocalStorage.get(LOCAL_STORAGE_USER)) {
      const { email } = LocalStorage.get(LOCAL_STORAGE_USER).user;

      if (email) {
        data.user.email = email;
      }
    }

    // setting token and user data
    if (token) {
      LocalStorage.set(LOCAL_STORAGE_PRIMARY_TOKEN, token);
    }

    if (data) {
      LocalStorage.set(LOCAL_STORAGE_USER, data);
    }

    await thunkAPI.dispatch(
      listUsers({
        page: 1,
        isRewriteAll: true,
      }),
    );

    return { data, token };
  } catch (error) {
    const { response } = error;
    if (!response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue(response);
    }

    const { data } = error.response;

    const responseMessage = messageHelper.getMessageFromResponseData(data);

    // handling email error has already taken from fe
    if (!responseMessage) {
      messageHelper.failedMessage(thunkAPI.dispatch, 'error.move.company', '', false);
    } else {
      messageHelper.failedMessage(thunkAPI.dispatch, responseMessage, '', false);
    }

    return thunkAPI.rejectWithValue(data);
  }
});

// handling user registration
export const register = createAsyncThunk('auth/register', async (data, thunkAPI) => {
  try {
    const response = await authService.register({ ...data });

    if (!response) {
      return thunkAPI.rejectWithValue(response);
    }

    const { userMessage, sucess } = response.data;

    if (!sucess && !userMessage) {
      return thunkAPI.rejectWithValue(response.data);
    }

    if (!userMessage) {
      messageHelper.successMessageUnauthorizated(
        thunkAPI.dispatch,
        'success.register',
        'success.register',
      );
    } else {
      messageHelper.successMessageUnauthorizated(
        thunkAPI.dispatch,
        userMessage,
        'success.register',
      );
    }

    return thunkAPI.fulfillWithValue(response.data);
  } catch (error) {
    // check whether response is property of error object
    const { response } = error;

    if (!response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue(error.toString());
    }

    const { data } = error.response;

    const responseMessage = messageHelper.getMessageFromResponseData(data);

    // handling email error has already taken from fe
    if (!responseMessage) {
      messageHelper.failedMessage(
        thunkAPI.dispatch,
        'error.register.email-has-taken',
        'error.register.email-has-taken',
      );
    } else {
      messageHelper.failedMessage(thunkAPI.dispatch, responseMessage, '');
    }

    return thunkAPI.rejectWithValue(data);
  }
});

// local storage remove from app data
export const removeLocalStorageAppData = createAsyncThunk(
  'auth/removeLocalStorageAppData',
  async (_, thunkAPI) => {
    const removeActions = storageConstants.LOCAL_STORAGE_KEY.map(
      (localStorageKeyname) => async () =>
        await thunkAPI.dispatch(LocalStorage.remove(localStorageKeyname)),
    );

    await Promise.all(
      removeActions.map((action) => {
        return action();
      }),
    );

    return thunkAPI.fulfillWithValue();
  },
);

// handling delete all data in global state
export const removeAllDataState = createAsyncThunk(
  'auth/removeAllDataState',
  async (_, thunkAPI) => {
    await promiseExecutionHelper.sequentialRunnerPromises(
      promiseExecutionHelper.transform2executablePromise({
        dispatchers: Array(10).fill(thunkAPI.dispatch),
        actions: [
          clearAccountData,
          clearAccountCategory,
          clearCompany,
          clearManagementCompany,
          clearManagementRoles,
          clearManagementUsersData,
          clearUserProfile,
          clearUserCompany,
          clearProductData,
          clearProductCategory,
        ],
        params: Array(10).fill({ removeLocalStorageOnly: true }),
      }),
    );

    await promiseExecutionHelper.sequentialRunnerPromises(
      promiseExecutionHelper.transform2executablePromise({
        dispatchers: Array(32).fill(thunkAPI.dispatch),
        actions: [
          accountActions.reInitializeStateAndLocalStorage,
          subAccountActions.reInitializeStateAndLocalStorage,
          assetActions.reInitializeStateAndLocalStorage,
          attachmentActions.reInitializeStateAndLocalStorage,
          bankActions.reInitializeStateAndLocalStorage,
          beginningBalanceActions.reInitializeStateAndLocalStorage,
          billingActions.reInitializeStateAndLocalStorage,
          cashBankActions.reInitializeStateAndLocalStorage,
          chartActions.reInitializeStateAndLocalStorage,
          closingBookActions.reInitializeStateAndLocalStorage,
          contactActions.reInitializeStateAndLocalStorage,
          expenseActions.reInitializeStateAndLocalStorage,
          indoRegionalActions.reInitializeStateAndLocalStorage,
          journalEntryActions.reInitializeStateAndLocalStorage,
          managementCompanyActions.reInitializeStateAndLocalStorage,
          managementRoleActions.reInitializeStateAndLocalStorage,
          managementUserActions.reInitializeStateAndLocalStorage,
          minutesOfActions.reInitializeStateAndLocalStorage,
          notificationActions.reInitializeStateAndLocalStorage,
          productActions.reInitializeStateAndLocalStorage,
          provinceActions.reInitializeStateAndLocalStorage,
          purchaseActions.reInitializeStateAndLocalStorage,
          referenceNumberActions.reInitializeStateAndLocalStorage,
          regionalActions.reInitializeStateAndLocalStorage,
          reportActions.reInitializeStateAndLocalStorage,
          salesActions.reInitializeStateAndLocalStorage,
          stockAdjustmentActions.reInitializeStateAndLocalStorage,
          stockTransferActions.reInitializeStateAndLocalStorage,
          tagActions.reInitializeStateAndLocalStorage,
          transactionActions.reInitializeStateAndLocalStorage,
          userActions.reInitializeStateAndLocalStorage,
          userPermissionActions.reInitializeStateAndLocalStorage,
          warehouseActions.reInitializeStateAndLocalStorage,
        ],
        params: Array(32).fill({}),
      }),
    );

    return thunkAPI.fulfillWithValue();
  },
);

// hanlding user logout
export const logout = createAsyncThunk('auth/logout', async (isInactiveUser, thunkAPI) => {
  try {
    const response = await authService.logout();

    if (!response) return thunkAPI.rejectWithValue(response);

    if (isInactiveUser) {
      messageHelper.successMessageUnauthorizated(
        thunkAPI.dispatch,
        'success.logout-inactive',
        'success.logout-inactive',
      );
    }

    return thunkAPI.fulfillWithValue(response.data);
  } catch (error) {
    if (!error.response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.fulfillWithValue(null);
    }

    const { data: responseData } = error.response;

    // when user is inactive
    if (isInactiveUser) {
      messageHelper.failedMessage(
        thunkAPI.dispatch,
        'error.logout-inactive',
        'error.logout-inactive',
      );

      return thunkAPI.fulfillWithValue(null);
    }

    const messageResponse = messageHelper.getMessageFromResponseData(responseData);

    if (!messageResponse) {
      messageHelper.failedMessage(thunkAPI.dispatch, 'error.logout', 'error.logout');
    } else {
      messageHelper.failedMessage(thunkAPI.dispatch, messageResponse, 'error.logout');
    }

    return thunkAPI.fulfillWithValue(responseData);
  } finally {
    await thunkAPI.dispatch(removeAllDataState());

    await thunkAPI.dispatch(removeLocalStorageAppData());
  }
});

// handling forgot password user
export const forgotPassword = createAsyncThunk('auth/forgotPassword', async (email, thunkAPI) => {
  try {
    const response = await authService.forgotPassword(email);

    if (!response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue(response);
    }

    const { data } = response;

    const responseMessage = messageHelper.getMessageFromResponseData(data);

    if (!responseMessage) {
      messageHelper.successMessageUnauthorizated(
        thunkAPI.dispatch,
        'success.forgot-password',
        'success.forgot-password',
      );
    } else {
      messageHelper.successMessageUnauthorizated(
        thunkAPI.dispatch,
        responseMessage,
        'success.forgot-password',
      );
    }

    return thunkAPI.fulfillWithValue(data);
  } catch (error) {
    // check error has response
    const { response } = error;

    // showing error internal server
    if (!response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue(error);
    }

    const { data } = response;

    // checking response message that from server
    const responseMessage = messageHelper.getMessageFromResponseData(data);

    if (!responseMessage) {
      messageHelper.failedMessage(
        thunkAPI.dispatch,
        'error.forgot-password',
        'error.forgot-password',
      );
    } else {
      messageHelper.failedMessage(thunkAPI.dispatch, responseMessage, 'error.forgot-password');
    }

    return thunkAPI.rejectWithValue();
  }
});

// handling verification email user
export const verifyEmail = createAsyncThunk('auth/verifyEmail', async ({ token }, thunkAPI) => {
  try {
    const response = await authService.verifyEmail(token);

    if (!response) {
      messageHelper.failedMessage(thunkAPI.dispatch, 'error.verify.email', 'error.verify.email');

      return thunkAPI.rejectWithValue(response);
    }

    const { data } = response;

    // get message in response
    const responseMessage = messageHelper.getMessageFromResponseData(data);

    if (!responseMessage) {
      messageHelper.successMessageUnauthorizated(
        thunkAPI.dispatch,
        'success.verify.email',
        'success.verify.email',
      );
    } else {
      messageHelper.successMessageUnauthorizated(
        thunkAPI.dispatch,
        responseMessage,
        'success.verify.email',
      );
    }

    return thunkAPI.fulfillWithValue(data);
  } catch (error) {
    const { response } = error;

    if (!response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue(error);
    }

    const { data } = response;

    const responseMessage = messageHelper.getMessageFromResponseData(data);

    // handling response message from fe
    if (!responseMessage) {
      messageHelper.failedMessage(thunkAPI.dispatch, 'error.verify.email', 'error.verify.email');
    } else {
      messageHelper.failedMessage(thunkAPI.dispatch, responseMessage);
    }

    return thunkAPI.rejectWithValue(data);
  }
});

// handling new password / change password user
export const newPasswordRequest = createAsyncThunk(
  'auth/newPassword',
  async (dataRequest, thunkAPI) => {
    try {
      const response = await authService.newPassword(dataRequest);

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'successfully.change-password',
          false,
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      const { response } = error;

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (responseMessage) {
        messageHelper.failedMessage(thunkAPI.dispatch, responseMessage, '', false);
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// action for changing user company
export const changeCompany = createAsyncThunk(
  'auth/change-company',
  async (bodyChangeCompany, thunkAPI) => {
    return await creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      bodyChangeCompany,
      authService.changeCompany,
      null,
      null,
      'error.change-company',
      {},
      {},
      false,
      [],
      '',
      DEFAULT_KEY_NAME_CONTAINER_COMBINE_RESPONSE,
      [
        ({ data }) => {
          const companySetupProgress = data?.company[DEFAULT_KEY_NAME_LOGIN_SETUP_API_REF];

          const params = {
            [SLICE_NAME_APPLICATION_TYPE]:
              companySetupProgress === companySetupFinishStep
                ? DEFAULT_APPLICATION_TYPE_PROFESSIONAL
                : DEFAULT_APPLICATION_TYPE_COMPANY_SETUP,
          };

          return switchApplication(params);
        },
      ],
    );
  },
);

// where for handling change company on auth portal
export const changeCompanyByAuthPortal = createAsyncThunk(
  'auth/change-company-by-auth-portal',
  async (bodyChangeCompany, thunkAPI) => {
    return await creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      bodyChangeCompany,
      authService.changeCompany,
      null,
      null,
      'error.change-company',
    );
  },
);

export const setupCompany = createAsyncThunk(
  'auth/setup-company-progess',
  async (body, thunkAPI) => {
    return await creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      body,
      managementCompanyServices.setupCompany,
      'data',
      '',
      '',
      {},
      {},
      true,
      ['progress'],
      DEFAULT_KEY_NAME_CONTAINER_COMBINE_RESPONSE,
    );
  },
);
