import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { TRANSACTION_BASE_ACTION_TYPE } from '../../utils/constants/action-type.constant';
import {
  LOCAL_STORAGE_TRANSACTION_LIST,
  LOCAL_STORAGE_TRANSACTION_PRINT_LIST,
} from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  SLICE_NAME_TRANSACTION_DATA_LIST,
  SLICE_NAME_TRANSACTION_DETAILS,
  SLICE_NAME_TRANSACTION_PRINT_DATA_LIST,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_DETAILS_TRANSACTION_FAILED,
  STATUS_REQUEST_DETAILS_TRANSACTION_PENDING,
  STATUS_REQUEST_DETAILS_TRANSACTION_SUCCESS,
  STATUS_REQUEST_LIST_TRANSACTION_FAILED,
  STATUS_REQUEST_LIST_TRANSACTION_PENDING,
  STATUS_REQUEST_LIST_TRANSACTION_SUCCESS,
} from '../../utils/constants/store.constant';
import { transactionAsyncThunkTypeWithActionProps } from '../../utils/data/mapping/async-thunk-type-with-action-props.mapping';
import { DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS } from '../../utils/default/params.default';
import formatHelp from '../../utils/helpers/format.helpers';
import objHelper from '../../utils/helpers/object.helper';
import {
  bodyBuilderFulfilledAction,
  bodyBuilderPendingAction,
  bodyBuilderRejectedAction,
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
  sliceReduceHelper,
} from '../../utils/helpers/slice-reducer.helper';

import transactionActions from './transaction.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  [SLICE_NAME_TRANSACTION_DATA_LIST]: LocalStorage.get(LOCAL_STORAGE_TRANSACTION_LIST),
  [SLICE_NAME_TRANSACTION_DETAILS]: null,
  [SLICE_NAME_TRANSACTION_PRINT_DATA_LIST]: LocalStorage.get(LOCAL_STORAGE_TRANSACTION_PRINT_LIST),
};

const transactionSlice = createSlice({
  name: NAME_STORE.TRANSACTION,
  initialState,
  reducers: {
    transactionActions,
    clearDataTransactionSlice: (state, action) => {
      const { sliceName, localStorageName } = action.payload;

      if (localStorageName && LocalStorage.get(localStorageName)) {
        LocalStorage.remove(localStorageName);
      }

      return {
        ...state,
        [sliceName]: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    clearTransactionSliceItem: (state, action) => {
      sliceReduceHelper.clearSliceItem({
        state,
        action,
      });
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      [SLICE_NAME_TRANSACTION_DATA_LIST]: LOCAL_STORAGE_TRANSACTION_LIST,
      [SLICE_NAME_TRANSACTION_PRINT_DATA_LIST]: LOCAL_STORAGE_TRANSACTION_PRINT_LIST,
    }),
  },
  extraReducers: (builder) => {
    // list transaction refresh
    builder.addCase(transactionActions.getTransactionListRefresh.fulfilled, (state, action) => {
      state[SLICE_NAME_TRANSACTION_DATA_LIST] = action.payload[SLICE_NAME_TRANSACTION_DATA_LIST];
      state.statusRequest = STATUS_REQUEST_LIST_TRANSACTION_SUCCESS;
    }),
      builder.addCase(transactionActions.getTransactionListRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_TRANSACTION_PENDING;
      }),
      builder.addCase(transactionActions.getTransactionListRefresh.rejected, (state) => {
        state[SLICE_NAME_TRANSACTION_DATA_LIST] = null;
        state.statusRequest = STATUS_REQUEST_LIST_TRANSACTION_FAILED;
      }),
      // transaction details
      builder.addCase(transactionActions.getTransactionDetails.fulfilled, (state, action) => {
        if (!isEmpty(action.payload[SLICE_NAME_TRANSACTION_DETAILS])) {
          action.payload[SLICE_NAME_TRANSACTION_DETAILS] = objHelper.changeFormatValueV2(
            action.payload[SLICE_NAME_TRANSACTION_DETAILS],
            DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
            null,
            formatHelp.changeFormatAccountName,
          );
        }

        state[SLICE_NAME_TRANSACTION_DETAILS] = action.payload[SLICE_NAME_TRANSACTION_DETAILS];
        state.statusRequest = STATUS_REQUEST_DETAILS_TRANSACTION_SUCCESS;
      }),
      builder.addCase(transactionActions.getTransactionDetails.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAILS_TRANSACTION_PENDING;
      }),
      builder.addCase(transactionActions.getTransactionDetails.rejected, (state) => {
        state[SLICE_NAME_TRANSACTION_DETAILS] = null;
        state.statusRequest = STATUS_REQUEST_DETAILS_TRANSACTION_FAILED;
      }),
      // enhancement for matching request status action on transaction
      // current: only include list
      builder.addMatcher(
        (action) => isFulfilledAction({ action, baseType: TRANSACTION_BASE_ACTION_TYPE }),
        (state, action) => {
          bodyBuilderFulfilledAction({
            state,
            action,
            mappingAsyncThunkTypeWithActionProps: transactionAsyncThunkTypeWithActionProps,
          });
        },
      ),
      builder.addMatcher(
        (action) => isPendingAction({ action, baseType: TRANSACTION_BASE_ACTION_TYPE }),
        (state, action) => {
          bodyBuilderPendingAction({
            state,
            action,
            mappingAsyncThunkTypeWithActionProps: transactionAsyncThunkTypeWithActionProps,
          });
        },
      ),
      builder.addMatcher(
        (action) => isRejectedAction({ action, baseType: TRANSACTION_BASE_ACTION_TYPE }),
        (state, action) => {
          bodyBuilderRejectedAction({
            state,
            action,
            mappingAsyncThunkTypeWithActionProps: transactionAsyncThunkTypeWithActionProps,
          });
        },
      );
  },
});

export const { reducer, actions } = transactionSlice;

export const { clearDataTransactionSlice } = actions;

export default reducer;
