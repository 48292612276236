import {
  REACT_APP_API_URL_EXPORT_BALANCE_SHEET_REPORT,
  REACT_APP_API_URL_EXPORT_CAPITAL_CHANGE_REPORT,
  REACT_APP_API_URL_EXPORT_CASH_FLOW_REPORT,
  REACT_APP_API_URL_EXPORT_GENERAL_JOURNAL_REPORT,
  REACT_APP_API_URL_EXPORT_GENERAL_LEDGER_REPORT,
  REACT_APP_API_URL_EXPORT_INCOME_STATEMENT_REPORT,
  REACT_APP_API_URL_EXPORT_TRIAL_BALANCE_REPORT,
} from '../configs/api.config';
import {
  PERMISSION_EXPORT_DOCUMENT_BALANCE_SHEET,
  PERMISSION_EXPORT_DOCUMENT_CAPITAL_CHANGE,
  PERMISSION_EXPORT_DOCUMENT_CASH_FLOW,
  PERMISSION_EXPORT_DOCUMENT_GENERAL_LEDGER,
  PERMISSION_EXPORT_DOCUMENT_REPORT_GENERAL_JOURNAL,
  PERMISSION_EXPORT_DOCUMENT_REPORT_INCOME_STATEMENT,
  PERMISSION_EXPORT_DOCUMENT_TRIAL_BALANCE,
} from '../constants/permissions.constant';

export const DEFAULT_REPORT_TYPE_JOURNAL_REPORT = 'journal_report';
export const DEFAULT_REPORT_TYPE_INCOME_STATEMENT = 'income_statement';
export const DEFAULT_REPORT_TYPE_BALANCE_SHEET = 'balance_sheet';
export const DEFAULT_REPORT_TYPE_CAPITAL_CHANGE = 'capital_change';
export const DEFAULT_REPORT_TYPE_CASH_FLOW = 'cash_flow';
export const DEFAULT_REPORT_TYPE_LEDGER_REPORT = 'ledger_report';
export const DEFAULT_REPORT_TYPE_TRIAL_BALANCE = 'trial_balance';

export const documentReportExportUtils = [
  {
    type: DEFAULT_REPORT_TYPE_JOURNAL_REPORT,
    url: REACT_APP_API_URL_EXPORT_GENERAL_JOURNAL_REPORT,
    permission: PERMISSION_EXPORT_DOCUMENT_REPORT_GENERAL_JOURNAL,
  },
  {
    type: DEFAULT_REPORT_TYPE_INCOME_STATEMENT,
    url: REACT_APP_API_URL_EXPORT_INCOME_STATEMENT_REPORT,
    permission: PERMISSION_EXPORT_DOCUMENT_REPORT_INCOME_STATEMENT,
  },
  {
    type: DEFAULT_REPORT_TYPE_BALANCE_SHEET,
    url: REACT_APP_API_URL_EXPORT_BALANCE_SHEET_REPORT,
    permission: PERMISSION_EXPORT_DOCUMENT_BALANCE_SHEET,
  },
  {
    type: DEFAULT_REPORT_TYPE_CAPITAL_CHANGE,
    url: REACT_APP_API_URL_EXPORT_CAPITAL_CHANGE_REPORT,
    permission: PERMISSION_EXPORT_DOCUMENT_CAPITAL_CHANGE,
  },
  {
    type: DEFAULT_REPORT_TYPE_CASH_FLOW,
    url: REACT_APP_API_URL_EXPORT_CASH_FLOW_REPORT,
    permission: PERMISSION_EXPORT_DOCUMENT_CASH_FLOW,
  },
  {
    type: DEFAULT_REPORT_TYPE_LEDGER_REPORT,
    url: REACT_APP_API_URL_EXPORT_GENERAL_LEDGER_REPORT,
    permission: PERMISSION_EXPORT_DOCUMENT_GENERAL_LEDGER,
  },
  {
    type: DEFAULT_REPORT_TYPE_TRIAL_BALANCE,
    url: REACT_APP_API_URL_EXPORT_TRIAL_BALANCE_REPORT,
    permission: PERMISSION_EXPORT_DOCUMENT_TRIAL_BALANCE,
  },
];
