import { FORMAT_CURRENCY_AUTO_COMMA_COLUMN } from '../../../components/table-row/TableRowPrimary/TableRowPrimary';
import { DEFAULT_GRID_ACTION_COLLAPSE_TYPE } from '../../default/grid-type.default';
import { DEFAULT_INPUT_TYPE_CALCULATION_NUMBER_INPUT } from '../../default/input-type.default';
import {
  DEFAULT_KEY_NAME_AMOUNT_AVAILABLE_CONST,
  DEFAULT_KEY_NAME_AMOUNT_INPUT,
} from '../../default/object-keyname.default';
import {
  DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
  DEFAULT_REPRESENTATION_DATA_READABLE_DATE_TYPE,
} from '../../default/representation-data-type.default';

// column invoice for in add update purchase payment
export const columnsTableHeaderInvoicePurchasePayment = (t) => {
  return [
    {
      name: 'purchase_invoice_no',
      label: t('dialog.base-add-update-purchase-payment.table.header-column.purchase-invoice'),
      placeholder: t('dialog.base-add-update-purchase-payment.input.invoice-no.placeholder'),
      width: 300,
    },
    {
      name: 'description',
      label: t('dialog.base-add-update-purchase-payment.table.header-column.purchase-description'),
      width: 300,
    },
    {
      name: 'expired_date',
      label: t('dialog.base-add-update-purchase-payment.table.header-column.due-date'),
      width: 300,
      align: 'left',
    },
    {
      name: 'purchase_invoice_amount',
      label: t('dialog.base-add-update-purchase-payment.table.header-column.total-bill'),
      align: 'right',
      width: 200,
    },
    {
      name: 'amount_available',
      label: t('dialog.base-add-update-purchase-payment.table.header-column.remaining-bill'),
      align: 'right',
      width: 200,
    },
    {
      name: 'amount',
      name_input: 'amount_input',
      label: t('dialog.base-add-update-purchase-payment.table.header-column.amount'),
      placeholder: t('dialog.base-add-update-purchase-payment.input.amount.placeholder'),
      width: 200,
      align: 'right',
    },
  ];
};

// column invoice for in add update sales payment on enhancement v2
export const columnsTableHeaderInvoicePurchasePaymentV2 = (t) => {
  return [
    {
      multipleNames: [
        {
          name: 'purchase_invoice_no',
        },
        {
          name: 'description',
        },
      ],
      isUseMultipleNames: true,
      label: t('table.header-column.purchase-invoice'),
      width: 300,
    },
    {
      name: 'expired_date',
      label: t('table.header-column.due-date'),
      width: 300,
      align: 'left',
    },
    {
      name: 'purchase_invoice_amount',
      label: t('table.header-column.total-bill'),
      align: 'right',
      width: 200,
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'amount_available',
      label: t('table.header-column.remaining-bill'),
      align: 'right',
      width: 200,
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      substituteNotExistValue: 0,
    },
    {
      name: 'amount',
      label: t('table.header-column.amount-paid'),
      width: 200,
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
  ];
};

// only for case update purchase payment
export const additionalColumnsHeaderInvoicePurchasePayment = [
  {
    name: 'action',
    label: '',
    align: 'right',
    width: '10px',
    maxWidth: '10px',
  },
];

// column that representation for calculation
export const calculationColumnsInvoicePurchasePayment = [
  'amount',
  'amount_available',
  'purchase_invoice_amount',
];

export const dateColumnsInvoicePurchasePayment = ['expired_date'];

// additional props on table head main
export const additionalPropsPurchasePaymentDetail = {
  sx: {
    backgroundColor: '#EFF8FF!important',
  },
};

export const columnDataTotalDetailPurchasePayment = ['amount_available', 'amount'];

// grid header invoice on purchase payment add update
export const gridHeaderInvoiceOnPurchasePayment = [
  {
    name: DEFAULT_GRID_ACTION_COLLAPSE_TYPE,
    label: '',
    align: 'left',
    width: 20,
    gridProps: {
      xs: 0.5,
    },
    isButton: true,
  },
  {
    name: 'purchase_invoice_no',
    label: 'table.header-column.purchase-invoice-no',
    isUseMultipleNames: true,
    width: 200,
    minWidth: 150,
    gridProps: {
      xs: 2.5,
      display: 'flex',
      justifyContent: 'start',
    },
    isTypography: true,
  },
  {
    name: 'expired_date',
    label: 'table.header-column.due-date-v2',
    align: 'left',
    width: 100,
    minWidth: 80,
    gridProps: {
      xs: 2,
      display: 'flex',
      justifyContent: 'start',
    },
    isTypography: true,
    representationDataType: DEFAULT_REPRESENTATION_DATA_READABLE_DATE_TYPE,
  },
  {
    name: 'amount',
    label: 'table.header-column.total-bill',
    align: 'right',
    width: 120,
    minWidth: 80,
    gridProps: {
      xs: 2.3,
      display: 'flex',
      justifyContent: 'end',
    },
    isTypography: true,
    representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
  },
  {
    name: 'amount_available_const',
    label: 'table.header-column.remaining-bill',
    align: 'right',
    width: 120,
    minWidth: 80,
    gridProps: {
      xs: 2.3,
      display: 'flex',
      justifyContent: 'end',
    },
    isTypography: true,
    representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
  },
  {
    name: 'amount_input',
    label: 'table.header-column.amount-paid',
    placeholder: '',
    align: 'right',
    width: 120,
    minWidth: 80,
    gridProps: {
      xs: 2.3,
      display: 'flex',
      justifyContent: 'end',
    },
    isInput: true,
    keyInputName: DEFAULT_KEY_NAME_AMOUNT_INPUT,
    inputType: DEFAULT_INPUT_TYPE_CALCULATION_NUMBER_INPUT,
    isDisabled: () => {
      return false;
    },
    keyMaximumInput: DEFAULT_KEY_NAME_AMOUNT_AVAILABLE_CONST,
  },
];

export const gridCollapseInvoiceOnPurchasePayment = [
  {
    gridProps: {
      xs: 0.75,
    },
  },
  {
    placeholder: 'label.memo',
    name: 'description',
    gridProps: {
      xs: 10.5,
    },
    isTypography: true,
  },
  {
    gridProps: {
      xs: 0.75,
    },
  },
];
