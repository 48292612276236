import { createAsyncThunk } from '@reduxjs/toolkit';
import { isArray, isEmpty } from 'lodash';

import accountServices from '../../services/API/account/account.service';
import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  ACCOUNT_ASYNC_THUNK_TYPE,
  ACCOUNT_BASE_ACTION_TYPE,
  ACCOUNT_CATEGORY_ASYNC_THUNK_TYPE,
} from '../../utils/constants/action-type.constant';
import {
  LOCAL_STORAGE_ACCOUNT_CATEGORY,
  LOCAL_STORAGE_ACCOUNT_DATA,
  LOCAL_STORAGE_ACCOUNT_DATA_ALTERNATIVE,
  LOCAL_STORAGE_ACCOUNT_DETAILS,
  LOCAL_STORAGE_ACCOUNT_PARENT,
  LOCAL_STORAGE_ACCOUNT_TRANSACTION,
} from '../../utils/constants/storage.constant';
import {
  SLICE_NAME_ACCOUNT_CATEGORY,
  SLICE_NAME_ACCOUNT_DATA,
  SLICE_NAME_ACCOUNT_DETAILS,
  SLICE_NAME_ACCOUNT_TRANSACTION,
  STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_FAILED,
  STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_PENDING,
  STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_SUCCESS,
  STATUS_REQUEST_LIST_ACCOUNT_DATA_FAILED,
  STATUS_REQUEST_LIST_ACCOUNT_DATA_PENDING,
  STATUS_REQUEST_LIST_ACCOUNT_DATA_SUCCESS,
} from '../../utils/constants/store.constant';
import { DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF } from '../../utils/default/object-keyname.default';
import { DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION } from '../../utils/default/params.default';
import objHelper from '../../utils/helpers/object.helper';
import {
  creatorDetailAction,
  creatorListAction,
  creatorListActionDynamicSliceNameNproperties,
} from '../creator-action/creator-action.helper';
import messageHelper from '../message/message.helper';

/**
 * account category actions
 *
 */

export const accountCategoryList = createAsyncThunk(
  ACCOUNT_CATEGORY_ASYNC_THUNK_TYPE.list,
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_FAILED,
      slicePayloadKeyname = SLICE_NAME_ACCOUNT_CATEGORY,
      localStorageName = LOCAL_STORAGE_ACCOUNT_CATEGORY,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...params
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params,
      apiService: accountServices.accountCategoryList,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: 'account_category_id',
      slicePayloadKeyname,
      errorMessage: 'error.list-data.account-category',
    });
  },
);

// listing data account category when refresh data
export const accountCategoryListRefresh = createAsyncThunk(
  ACCOUNT_CATEGORY_ASYNC_THUNK_TYPE.listRefresh,
  async (discardKeySearch = [], thunkAPI) => {
    const currentDataAccountCategory = LocalStorage.get(LOCAL_STORAGE_ACCOUNT_CATEGORY);

    // get filtering data from prev search
    let filterDataSearch = objHelper.filterKeyObj(currentDataAccountCategory, discardKeySearch, [
      'search_category_name',
      'search_category_type',
      'search_default_code',
      'current_page',
      'per_page',
    ]);

    // data for next search
    const filterDataNextSearch = objHelper.filterKeyObj(filterDataSearch, [
      'per_page',
      'current_page',
    ]);

    filterDataSearch = {
      ...filterDataSearch,
      page: filterDataSearch.current_page,
      paginate: filterDataSearch.per_page,
      category_name: filterDataSearch.search_category_name,
      category_type: filterDataSearch.search_category_type,
      default_code: filterDataSearch.search_default_code,
    };

    // get existed data only
    filterDataSearch = objHelper.filteringExistedValue(filterDataSearch);

    // removing unused key for search data
    filterDataSearch = objHelper.filterKeyObj(filterDataSearch, [
      'current_page',
      'search_category_type',
      'search_default_code',
      'search_category_name',
    ]);

    try {
      const response = await accountServices.accountCategoryList(filterDataSearch);

      if (!response) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.account-category',
          'error.list-data.account-category',
          false,
        );

        return thunkAPI.rejectWithValue(response);
      }

      let { data: dataResponseAccountCategory, sucess } = response.data;

      if (!sucess || !Array.isArray(dataResponseAccountCategory.data)) {
        dataResponseAccountCategory = {
          ...dataResponseAccountCategory,
          data: [],
        };
      }

      // adding data for caching search from previous data
      const dataResWithSearch = Object.assign(dataResponseAccountCategory, {
        ...filterDataNextSearch,
      });

      LocalStorage.set(LOCAL_STORAGE_ACCOUNT_CATEGORY, dataResWithSearch);

      return { category: dataResWithSearch };
    } catch (error) {
      const { response } = error;

      if (!response) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.account-category',
          'error.list-data.account-category',
          false,
        );

        return thunkAPI.rejectWithValue(error);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.account-category',
          'error.list-data.account-category',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.list-data.account-category',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// adding account category of company
export const addAccountCategory = createAsyncThunk(
  ACCOUNT_CATEGORY_ASYNC_THUNK_TYPE.add,
  async (dataAddAccountCategory, thunkAPI) => {
    const { category_name } = dataAddAccountCategory;

    try {
      const response = await accountServices.addAccountCategory(dataAddAccountCategory);

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.add.account-category',
          'success.add.account-category',
          {},
          { category_name },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.add.account-category',
          {},
          { category_name },
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.failedMessage(thunkAPI.dispatch, 'error.internal-server-error', '', false);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.add.account-category',
          'error.add.account-category',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.add.account-category',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// updating account category of company
export const updateAccountCategory = createAsyncThunk(
  ACCOUNT_CATEGORY_ASYNC_THUNK_TYPE.update,
  async (dataUpdateAccountCategory, thunkAPI) => {
    const { category_name } = dataUpdateAccountCategory;

    try {
      const response = await accountServices.updateAccountCategory(dataUpdateAccountCategory);

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.update.account-category',
          'success.update.account-category',
          {},
          { category_name },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.update.account-category',
          {},
          { category_name },
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.failedMessage(thunkAPI.dispatch, 'error.internal-server-error', '', false);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.update.account-category',
          'error.update.account-category',
          false,
          { category_name },
          {},
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.update.account-category',
          false,
          { category_name },
          {},
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// deleting account category of company
export const deleteAccountCategory = createAsyncThunk(
  ACCOUNT_CATEGORY_ASYNC_THUNK_TYPE.delete,
  async (dataDeleteAccountCategory, thunkAPI) => {
    const { category_name } = dataDeleteAccountCategory;

    try {
      const response = await accountServices.deleteAccountCategory(dataDeleteAccountCategory);

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.delete.account-category',
          'success.delete.account-category',
          {},
          { category_name },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.delete.account-category',
          {},
          { category_name },
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.failedMessage(thunkAPI.dispatch, 'error.internal-server-error', '', false);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.delete.account-category',
          'error.delete.account-category',
          false,
          { category_name },
          {},
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.delete.account-category',
          false,
          { category_name },
          {},
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

/**
 * account actions
 *
 */

export const getAccountList = createAsyncThunk(
  ACCOUNT_ASYNC_THUNK_TYPE.list,
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_ACCOUNT_DATA_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_ACCOUNT_DATA_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_ACCOUNT_DATA_FAILED,
      slicePayloadKeyname = SLICE_NAME_ACCOUNT_DATA,
      localStorageName = LOCAL_STORAGE_ACCOUNT_DATA,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...params
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params: params,
      apiService: accountServices.getAccountList,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
      slicePayloadKeyname,
      errorMessage: 'error.list-data.account-data',
    });
  },
);

// listing account company
export const getAccountListAlternative = createAsyncThunk(
  ACCOUNT_ASYNC_THUNK_TYPE.listAlternative,
  async (
    { page, paginate, account_name, category_name, account_code, isRewriteAll = true },
    thunkAPI,
  ) => {
    try {
      const response = await accountServices.getAccountList({
        page,
        paginate,
        account_name,
        account_code,
        category_name,
      });

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      let { data: accountDataAlternative, sucess } = response.data;

      if (!sucess || !Array.isArray(accountDataAlternative.data)) {
        accountDataAlternative = {
          ...accountDataAlternative,
          data: [],
        };
      }

      // cache for search data
      if (account_name) {
        accountDataAlternative.search_account_name = account_name;
      }

      if (category_name) {
        accountDataAlternative.search_category_name = category_name;
      }

      if (account_code) {
        accountDataAlternative.search_account_code = account_code;
      }

      if (isRewriteAll) {
        LocalStorage.set(LOCAL_STORAGE_ACCOUNT_DATA_ALTERNATIVE, accountDataAlternative);

        return { accountDataAlternative };
      }

      const currentAccountData = LocalStorage.get(LOCAL_STORAGE_ACCOUNT_DATA_ALTERNATIVE);

      // combine data list account data
      const currentAccountDataList = currentAccountData.data;
      const accountDataResponseList = accountDataAlternative.data;

      const newAccountDataListResponse = currentAccountDataList.concat(accountDataResponseList);

      // rewrite data list account
      const newAccountDataResponse = Object.assign(accountDataAlternative, {
        data: newAccountDataListResponse,
      });

      LocalStorage.set(LOCAL_STORAGE_ACCOUNT_DATA_ALTERNATIVE, newAccountDataResponse);

      return { accountDataAlternative: newAccountDataResponse };
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.account-data',
          'error.list-data.account-data',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.list-data.account-data',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// get refresh data list with current search
export const getAccountListRefresh = createAsyncThunk(
  ACCOUNT_ASYNC_THUNK_TYPE.listRefresh,
  async (discardKeySearch = [], thunkAPI) => {
    const currentDataAccount = LocalStorage.get(LOCAL_STORAGE_ACCOUNT_DATA);

    // get filtering data from prev search
    let filterDataSearch = objHelper.filterKeyObj(currentDataAccount, discardKeySearch, [
      'per_page',
      'current_page',
      'search_account_name',
      'search_category_name',
      'search_account_code',
    ]);

    // get data for next search
    const filterDataNextSearch = objHelper.filterKeyObj(filterDataSearch, [
      'per_page',
      'current_page',
    ]);

    // formalize data for search in service
    filterDataSearch = {
      ...filterDataSearch,
      page: filterDataSearch.current_page,
      paginate: filterDataSearch.per_page,
      account_name: filterDataSearch.search_account_name,
      account_code: filterDataSearch.search_account_code,
      category_name: filterDataSearch.search_category_name,
    };

    // get data that only existed value
    filterDataSearch = objHelper.filteringExistedValue(filterDataSearch);

    // removing unused key for now search data
    filterDataSearch = objHelper.filterKeyObj(filterDataSearch, [
      'current_page',
      'search_category_name',
      'search_account_name',
      'search_account_code',
    ]);

    try {
      const response = await accountServices.getAccountList(filterDataSearch);

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      let { data: accountData, sucess } = response.data;

      if (!sucess || !Array.isArray(accountData.data)) {
        accountData = {
          ...accountData,
          data: [],
        };
      }

      // adding search cache data from previous search
      const dataResponseAccountWithSearch = Object.assign(accountData, { ...filterDataNextSearch });

      // adding data into local storage
      LocalStorage.set(LOCAL_STORAGE_ACCOUNT_DATA, dataResponseAccountWithSearch);

      return { accountData: dataResponseAccountWithSearch };
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.account-data',
          'error.list-data.account-data',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.list-data.account-data',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// action account details
export const getAccountDetails = createAsyncThunk(
  ACCOUNT_ASYNC_THUNK_TYPE.detail,
  async (paramsGetAccountDetails, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      paramsGetAccountDetails,
      accountServices.getAccountDetails,
      LOCAL_STORAGE_ACCOUNT_DETAILS,
      SLICE_NAME_ACCOUNT_DETAILS,
      'error.detail.account',
    );
  },
);

// adding account company
export const addAccount = createAsyncThunk(
  ACCOUNT_ASYNC_THUNK_TYPE.add,
  async (addAccountData, thunkAPI) => {
    const getAccountNameFromDataAdd = addAccountData.map((data) => data.account_name).join(', ');

    try {
      const response = await accountServices.addAccount(addAccountData);

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.add.account-data',
          'success.add.account-data',
          {},
          { account_name: getAccountNameFromDataAdd },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.add.account-data',
          {},
          { account_name: getAccountNameFromDataAdd },
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.failedMessage(thunkAPI.dispatch, 'error.internal-server-error', '', false);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.add.account-data',
          'error.add.account-data',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.add.account-data',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// update account company
export const updateAccount = createAsyncThunk(
  ACCOUNT_ASYNC_THUNK_TYPE.update,
  async (updateAccountData, thunkAPI) => {
    try {
      const response = await accountServices.updateAccount(updateAccountData);

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      const { data: dataResponse } = response;

      //  getting name of data update
      const { data: dataAccountUpdate } = dataResponse;

      const { account_name } = dataAccountUpdate;

      const responseMessage = messageHelper.getMessageFromResponseData(dataResponse);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.update.account-data',
          'success.update.account-data',
          {},
          { account_name },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.update.account-data',
          {},
          { account_name },
        );
      }

      return thunkAPI.fulfillWithValue(dataResponse);
    } catch (error) {
      if (!error.response) {
        messageHelper.failedMessage(thunkAPI.dispatch, 'error.internal-server-error', '', false);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data: dataResponse } = error.response;

      // get account name from update response error
      const { data: dataAccountUpdate } = dataResponse;
      const { account_name } = dataAccountUpdate;

      const responseMessage = messageHelper.getMessageFromResponseData(dataResponse);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.update.account-data',
          'error.update.account-data',
          false,
          { account_name },
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.update.account-data',
          false,
          {},
        );
      }

      return thunkAPI.rejectWithValue(dataResponse);
    }
  },
);

// delete account data company
export const deleteAccountData = createAsyncThunk(
  ACCOUNT_ASYNC_THUNK_TYPE.delete,
  async ({ account_id, account_name }, thunkAPI) => {
    try {
      const response = await accountServices.deleteAccountData({ account_id });

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.delete.account-data',
          'success.delete.account-data',
          {},
          { account_name },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.delete.account-data',
          {},
          { account_name },
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.delete.account-data',
          'error.delete.account-data',
          false,
          {},
          { account_name },
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.delete.account-data',
          false,
          {},
          { account_name },
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// list on account parent in company
export const getListAccountParent = createAsyncThunk(
  `${ACCOUNT_BASE_ACTION_TYPE}/parent-list`,
  async ({ isRewriteAll = false, ...paramsGetAccountParent }, thunkAPI) => {
    try {
      const response = await accountServices.getListAccountParent(paramsGetAccountParent);

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      let { data: dataAccountParent, sucess } = response.data;

      if (!sucess || !Array.isArray(dataAccountParent.data)) {
        dataAccountParent = [];
      }

      // adding search parameter for next search
      if (!isEmpty(paramsGetAccountParent) && !Array.isArray(dataAccountParent)) {
        const searchParams = objHelper.changeSuffixKey(paramsGetAccountParent, 'search_', false);

        dataAccountParent = {
          ...dataAccountParent,
          ...searchParams,
        };
      }

      const currentAccountParent = LocalStorage.get(LOCAL_STORAGE_ACCOUNT_PARENT);

      if (isRewriteAll || !currentAccountParent || isArray(dataAccountParent)) {
        LocalStorage.set(LOCAL_STORAGE_ACCOUNT_PARENT, dataAccountParent);

        return { accountParent: dataAccountParent };
      }

      let newListAccountParentData = dataAccountParent.data;

      const { current_page: new_current_page } = dataAccountParent;
      const { current_page: old_current_page } = currentAccountParent;

      if (currentAccountParent.data) {
        if (new_current_page > old_current_page) {
          newListAccountParentData = newListAccountParentData.concat(currentAccountParent.data);
        } else {
          newListAccountParentData = currentAccountParent.data.concat(newListAccountParentData);
        }
      }

      const newAccountParentData = Object.assign(currentAccountParent, dataAccountParent, {
        data: newListAccountParentData,
      });

      LocalStorage.set(LOCAL_STORAGE_ACCOUNT_PARENT, newAccountParentData);

      return { accountParent: newAccountParentData };
    } catch (error) {
      const { response } = error;

      if (!response) {
        return thunkAPI.rejectWithValue(error);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.account-parent-data',
          'error.list-data.account-parent-data',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.list-data.account-parent-data',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// action getting transaction list
export const getAccountTransactionList = createAsyncThunk(
  `${ACCOUNT_BASE_ACTION_TYPE}/transaction-list`,
  async (paramsGetAccountTransactionList, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      paramsGetAccountTransactionList,
      accountServices.getListAccountTransaction,
      LOCAL_STORAGE_ACCOUNT_TRANSACTION,
      SLICE_NAME_ACCOUNT_TRANSACTION,
      'account_id',
      'error.list-data.account-transaction',
    );
  },
);
