import * as React from 'react';
function SvgIconCopy(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#icon-copy_svg__clip0_764_22635)'>
        <path
          d='M4.167 12.5h-.834a1.667 1.667 0 01-1.666-1.667v-7.5a1.667 1.667 0 011.666-1.666h7.5A1.667 1.667 0 0112.5 3.333v.834M9.167 7.5h7.5c.92 0 1.666.746 1.666 1.667v7.5c0 .92-.746 1.666-1.666 1.666h-7.5c-.92 0-1.667-.746-1.667-1.666v-7.5c0-.92.746-1.667 1.667-1.667z'
          stroke='#EFF8FF'
          strokeWidth={2}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='icon-copy_svg__clip0_764_22635'>
          <path fill='#000' d='M0 0h20v20H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgIconCopy;
