import {
  FORMAT_MAPPING_TRANSLATION_COLUMN as FORMAT_MAPPING_TRANSLATION_COLUMN_TABLE_KEY_VALUE,
  FORMAT_PERIOD_DATE_COLUMN,
} from '../../../components/table/TableKeyValuePureMarkup/TableKeyValuePureMarkup';
import {
  FORMAT_COMMON_COLUMN,
  FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
  FORMAT_DATE_COLUMN,
  FORMAT_MAPPING_TRANSLATION_COLUMN,
} from '../../../components/table-row/TableRowPureMarkup/TableRowPureMarkup';
import { mappingSalesOrderStatus } from '../label-value/sales-status';

// column sales order list that handling table head with translation
export const columnsTableSalesOrder = (t) => {
  return [
    {
      name: 'transaction_date',
      name_input: 'from_date',
      label: t('dashboard.sales.sales-order.table.header-column.transaction-date'),
      placeholder: t('dashboard.sales.sales-order.input.search-transaction-date.placeholder'),
      width: 150,
    },
    {
      name: 'sales_order_no',
      name_input: 'sales_order_no',
      label: t('dashboard.sales.sales-order.table.header-column.transaction-no'),
      placeholder: t('dashboard.sales.sales-order.input.search-transaction-no.placeholder'),
      width: 200,
    },
    {
      name: 'customer_name',
      name_input: 'customer_name',
      label: t('dashboard.sales.sales-order.table.header-column.customer'),
      placeholder: t('dashboard.sales.sales-order.input.search-customer.placeholder'),
      width: 200,
    },
    {
      name: 'amount',
      label: t('dashboard.sales.sales-order.table.header-column.nominal'),
      align: 'right',
      width: 150,
    },
    {
      name: 'status',
      name_input: 'status',
      label: t('dashboard.sales.sales-order.table.header-column.status'),
      placeholder: t('dashboard.sales.sales-order.input.search-status.placeholder'),
      width: 180,
      maxWidth: 185,
    },
  ];
};

// column sales order list for print pdf
export const columnsTableSalesOrderPDFprint = (t) => {
  return [
    {
      name: 'transaction_date',
      label: t('dashboard.sales.sales-order.table.header-column.transaction-date'),
      style: {
        width: 150,
        position: 'unset',
      },
      formatData: FORMAT_DATE_COLUMN,
    },
    {
      name: 'sales_order_no',
      label: t('dashboard.sales.sales-order.table.header-column.transaction-no'),
      style: {
        width: 200,
        position: 'unset',
      },
      formatData: FORMAT_COMMON_COLUMN,
    },
    {
      name: 'customer_name',
      label: t('dashboard.sales.sales-order.table.header-column.customer'),
      style: {
        width: 200,
        position: 'unset',
      },
      formatData: FORMAT_COMMON_COLUMN,
    },
    {
      name: 'amount',
      label: t('dashboard.sales.sales-order.table.header-column.nominal'),
      style: {
        width: 150,
        position: 'unset',
      },
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      isUseZeroNumberWhenNull: true,
      align: 'right',
    },
    {
      name: 'status',
      label: t('dashboard.sales.sales-order.table.header-column.status'),
      style: {
        width: 180,
        maxWidth: 185,
        position: 'unset',
      },
      formatData: FORMAT_MAPPING_TRANSLATION_COLUMN,
      mappingTranslation: mappingSalesOrderStatus,
    },
  ];
};

// columns table sales order print
export const columnsTableKeyValuePrintIndexSalesOrder = [
  {
    header: 'dashboard.sales.sales-order.table.header-column.transaction-date',
    valueKeyName: 'search_from_date',
    valueKeyNameSecondary: 'search_to_date',
    formatValue: FORMAT_PERIOD_DATE_COLUMN,
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'dashboard.sales.sales-order.table.header-column.transaction-no',
    valueKeyName: 'search_sales_order_no',
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'dashboard.sales.sales-order.table.header-column.customer',
    valueKeyName: 'search_customer_name',
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'dashboard.sales.sales-order.table.header-column.status',
    valueKeyName: 'search_status',
    formatValue: FORMAT_MAPPING_TRANSLATION_COLUMN_TABLE_KEY_VALUE,
    mappingTranslation: mappingSalesOrderStatus,
    showOnlyWhenExistedValue: true,
  },
];

export const columnsSearchSalesOrderFormatDate = ['from_date', 'to_date', 'transaction_date'];

export const columnsSearchFormatStatus = ['status'];

export const columnsSearchFormatCustomer = ['customer_name'];

export const columnsSearchFormatAction = ['action'];

export const columnsSearchFormatLink = ['sales_order_no'];

export const columnsDataSalesOrderFormatCalculationNumber = ['amount'];
