import { isEmpty } from 'lodash';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import {
  DEFAULT_KEY_NAME_FILE_PROPERTIES,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
  DEFAULT_KEY_NAME_UUID_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import arrHelp from '../../helpers/array.helpers';
import formatHelp from '../../helpers/format.helpers';
import objHelper from '../../helpers/object.helper';
import { DEFAULT_CALCULATION_NUMBER } from '../default/number.default';

import {
  allowKeysBodyPurchasePayment,
  allowKeysBodyPurchasePaymentOnInvoice,
  allowKeysInvoiceData,
  initialInvoiceData,
  initialInvoiceValidation,
  initialPaymentData,
  initialPaymentValidation,
  plainInvoiceDataWithoutID,
  plainInvoiceValidationWithoutID,
} from './initial-data-purchase-payment';

/**
 *
 * @param { number } amount how many data that generate
 * @param { string } customDataKey name key of returning data
 * @param { string } customValidationKey name key of returning validation
 * @returns
 *      generated data and validation for adding purchase payment
 *      on payment
 */
export const generateDataNValidationAddPaymentPP = (
  amount = 1,
  customDataKey = 'paymentData',
  customValidationKey = 'paymentValidation',
) => {
  return objHelper.creatorGenerateDataNValidation(
    amount,
    initialPaymentData,
    initialPaymentValidation,
    customDataKey,
    customValidationKey,
  );
};

/**
 *
 * @param { number } amount how many data that generate
 * @param { string } customDataKey name key of returning data
 * @param { string } customValidationKey name key of returning validation
 * @returns
 *      generated data and validation for adding purchase payment
 *      on payment
 */
export const generateDataNValidationAddPurchasePaymentV2 = ({
  amount = 1,
  // companyUserData = {},
  customDataKey = 'paymentData',
  customValidationKey = 'paymentValidation',
}) => {
  return objHelper.creatorGenerateDataNValidation(
    amount,
    (notID, id) => {
      return {
        ...initialPaymentData(notID, id),
        // accounting_period: !isEmpty(companyUserData) ?
        //     companyUserData.accounting_periode: null,
        todays_date: new Date(),
        [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]: new Date(),
      };
    },
    initialPaymentValidation,
    customDataKey,
    customValidationKey,
  );
};

/**
 *
 * @param { object } param0     object as function params
 *
 * @param { object }    purchaseInvoiceDetails                  get details purchase invoice
 * @param { object }    currentPurchasePaymentData              current data purchase payment
 * @param { object }    currentPurchasePaymentValidation        current validation purchase payment
 * @param { object }    dataKeyname                             returning data keyname for purchase payment
 * @param { object }    validationKeyname                       returning validation keyname for purchase payment
 *
 * @returns
 */
export const generateDataNvalidationAddPurchasePaymentFromPurchaseInvoiceDetails = ({
  purchaseInvoiceDetails = {},
  currentPurchasePaymentData,
  currentPurchasePaymentValidation,
  dataKeyname = 'paymentData',
  validationKeyname = 'paymentValidation',
}) => {
  if (isEmpty(purchaseInvoiceDetails)) {
    return generateDataNValidationAddPurchasePaymentV2({
      customDataKey: dataKeyname,
      customValidationKey: validationKeyname,
    });
  }

  const modifiedPurchasePaymentData = currentPurchasePaymentData.map(
    (singlePurchasePaymentData) => {
      const minDateInputRef =
        purchaseInvoiceDetails[DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF] &&
        moment(new Date(purchaseInvoiceDetails[DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF])).isAfter(
          new Date(),
          'D',
        )
          ? purchaseInvoiceDetails[DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF] || null
          : new Date() || null;

      return {
        ...singlePurchasePaymentData,
        [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]: minDateInputRef,
      };
    },
  );

  return {
    [dataKeyname]: modifiedPurchasePaymentData,
    [validationKeyname]: currentPurchasePaymentValidation,
  };
};

/**
 *
 * @param { number } amount amount of data generated
 * @param { string } customDataKey name key of returning generated data
 * @param { string } customValidationKey name key of returning generated validation
 * @returns
 *      generated data and validation for adding purchase payment
 *      on invoice
 */
export const generateDataNValidationAddInvoicePP = (
  amount = 0,
  customDataKey = 'invoiceData',
  customValidationKey = 'invoiceValidation',
) => {
  return objHelper.creatorGenerateDataNValidation(
    amount,
    initialInvoiceData,
    initialInvoiceValidation,
    customDataKey,
    customValidationKey,
  );
};

/**
 *
 * @param { array } purchaseInvoiceData selected purchase invoice data
 * @param { string } customDataKey name key of returning generate data invoice
 * @param { string } customValidationKey name key of returning generated data validation invoice
 * @returns
 *      generate validation for add purchase invoice
 */
export const generateDataNValidationAddInvoiceFromListPI = (
  purchaseInvoiceData,
  customDataKey = 'invoiceData',
  customValidationKey = 'invoiceValidation',
) => {
  const usedID = arrHelp.generateArrWithFunc(purchaseInvoiceData.length, uuidV4);

  const invoiceData = purchaseInvoiceData.map((data, index) => {
    const { amount_available, purchase_payment_no } = data;

    return {
      id: usedID[index],
      ...plainInvoiceDataWithoutID,
      ...data,
      amount_available_const: formatHelp.reverseCurrencyFormatWithRegex(amount_available),
      purchase_invoice_id_container: {
        purchase_payment_no,
      },
    };
  });

  const invoiceValidation = usedID.map((id) => {
    return {
      id,
      ...plainInvoiceValidationWithoutID,
    };
  });

  return {
    [customDataKey]: invoiceData,
    [customValidationKey]: invoiceValidation,
  };
};

/**
 *
 * @param { array } purchaseInvoiceData selected purchase invoice data
 * @param { string } customDataKey name key of returning generate data invoice
 * @param { string } customValidationKey name key of returning generated data validation invoice
 * @returns
 *      generate validation for add purchase invoice
 */
export const generateDataNValidationAddInvoiceFromListPIV2 = ({
  purchaseInvoiceData,
  customDataKey = 'invoiceData',
  customValidationKey = 'invoiceValidation',
}) => {
  if (Array.isArray(purchaseInvoiceData)) {
    if (!purchaseInvoiceData.length) {
      const newID = uuidV4();

      return {
        [customDataKey]: [initialInvoiceData('', newID)],
        [customValidationKey]: [initialInvoiceValidation('', newID)],
      };
    }
  }

  const usedID = arrHelp.generateArrWithFunc(purchaseInvoiceData.length, uuidV4);

  const invoiceData = purchaseInvoiceData.map((data, index) => {
    const { amount_available, purchase_payment_no } = data;

    return {
      id: usedID[index],
      ...plainInvoiceDataWithoutID,
      ...data,
      amount_input: Number(amount_available),
      amount_available_const: formatHelp.reverseCurrencyFormatWithRegex(amount_available),
      purchase_invoice_id_container: {
        purchase_payment_no,
      },
    };
  });

  const invoiceValidation = usedID.map((id) => {
    return {
      id,
      ...plainInvoiceValidationWithoutID,
    };
  });

  return {
    [customDataKey]: invoiceData,
    [customValidationKey]: invoiceValidation,
  };
};

/**
 *
 * @param { object } purchaseInvoiceDetails get detail from purchase invoice detail
 * @param { string } customDataKey returning key name for data
 * @param { string } customValidationKey returnning key name for validation
 * @returns
 *      data and validation for purchase payment from purchase invoice details
 */
export const generateDataNValidationPurchasePaymentFromPurchaseInvoiceDetails = (
  purchaseInvoiceDetails,
  customDataKey = 'paymentData',
  customValidationKey = 'paymentValidation',
) => {
  if (isEmpty(purchaseInvoiceDetails)) {
    return generateDataNValidationAddPaymentPP(1, customDataKey, customValidationKey);
  }

  const usedID = arrHelp.generateArrWithFunc(1, uuidV4);

  const { contact_code, supplier_id, supplier_name, supplier_address } = purchaseInvoiceDetails;

  const paymentData = usedID.map((id) => {
    const minDateInputRef = purchaseInvoiceDetails[DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF]
      ? new Date(purchaseInvoiceDetails[DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF])
      : null;

    return {
      ...initialPaymentData('', id),
      ...purchaseInvoiceDetails,
      supplier_id_container: {
        contact_code,
        contact_id: supplier_id,
        contact_name: supplier_name,
        supplier_address,
      },
      transaction_date: moment(),
      expired_date: null,
      description: '',
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]: minDateInputRef,
    };
  });

  const paymentValidation = usedID.map((id) => {
    return initialPaymentValidation('', id);
  });

  return {
    [customDataKey]: paymentData,
    [customValidationKey]: paymentValidation,
  };
};

/**
 *
 * @param { object } purchaseInvoiceDetails data details purchase invoice
 * @param { string } customDataKey name key for returning data
 * @param { string } customValidationKey name key for returning validation
 * @returns
 *
 */
export const generateDataNValidationInvoiceFromPurchaseInvoiceDetails = (
  purchaseInvoiceDetails,
  customDataKey = 'invoiceData',
  customValidationKey = 'invoiceValidation',
) => {
  if (isEmpty(purchaseInvoiceDetails)) {
    return generateDataNValidationAddInvoicePP(0, customDataKey, customValidationKey);
  }

  const {
    amount_available,
    expired_date,
    purchase_payment_no,
    purchase_invoice_id,
    amount = 0,
    discount_nominal = 0,
    expense_value = 0,
    down_payment_value = 0,
  } = purchaseInvoiceDetails;

  const purchaseInvoiceProductData = purchaseInvoiceDetails['product'];

  const usedID = arrHelp.generateArrWithFunc(1, uuidV4);

  /**
   * amount, last user input on add purchase payment
   * amount_available, total remaining amount that must bill by user
   * amount_available_const maximum input amount user
   *      on update purchase payment, amount_available_const result sum from amount_available by amount
   * amount_input from inputting amount by user
   *
   */
  const invoiceData = usedID.map((id, index) => {
    let amount_available_const = Number(amount_available),
      amountView =
        Number(amount) -
        Number(discount_nominal) +
        Number(expense_value) -
        Number(down_payment_value);

    if (Number.isNaN(amount_available_const)) {
      amount_available_const = DEFAULT_CALCULATION_NUMBER;
    }

    return {
      ...initialInvoiceData('', id),
      ...purchaseInvoiceProductData[index],
      ...purchaseInvoiceDetails,
      amount: amountView,
      amount_input: '',
      amount_available: amount_available || DEFAULT_CALCULATION_NUMBER,
      amount_available_const,
      expired_date: expired_date ? new Number(expired_date) : null,
      purchase_payment_no_container: {
        purchase_invoice_id,
        purchase_payment_no,
      },
    };
  });

  const invoiceValidation = usedID.map((id) => {
    return initialInvoiceValidation('', id);
  });

  return {
    [customDataKey]: invoiceData,
    [customValidationKey]: invoiceValidation,
  };
};

/**
 *
 * @param { array } dataAddPayment data input payment from form
 * @param { array } dataAddInvoice data input invoice form input user
 * @returns
 *      generalized data purchase payment before hitting BE
 */
export const generalizeDataAddPurchasePayment = (dataAddPayment, dataAddInvoice) => {
  if (!Array.isArray(dataAddPayment) || !Array.isArray(dataAddInvoice)) {
    return {
      dataAddPayment,
      dataAddInvoice,
    };
  }

  dataAddPayment = dataAddPayment.map((dataPayment) => {
    const {
      supplier_id,
      supplier_id_container,
      purchase_payment_no,
      purchase_payment_no_container,
      transaction_date,
      account_id,
      account_id_container,
      expired_date,
      attachment,
    } = dataPayment;

    // get all amount for inputting amount user
    let amountInputPayment = arrHelp.mapWithExistedValue(dataAddInvoice, 'amount_input');
    amountInputPayment = arrHelp.sumValueArr(amountInputPayment);

    const { contact_name: supplier_name } = supplier_id_container;

    const uuidAttachment =
      Array.isArray(attachment) && attachment.length
        ? attachment[0][DEFAULT_KEY_NAME_FILE_PROPERTIES][DEFAULT_KEY_NAME_UUID_API_REF] || ''
        : '';

    dataPayment = {
      ...dataPayment,
      supplier_name,
      supplier_id:
        supplier_id_container && supplier_id_container.contact_id
          ? supplier_id_container.contact_id
          : supplier_id,
      purchase_payment_no:
        purchase_payment_no_container && purchase_payment_no_container.reference_number_id
          ? purchase_payment_no_container.reference_number_id
          : purchase_payment_no,
      transaction_date: transaction_date ? moment(transaction_date).toISOString(true) : '',
      account_id:
        account_id_container && account_id_container.account_id
          ? account_id_container.account_id
          : account_id,
      amount: amountInputPayment
        ? formatHelp.reverseCurrencyFormatWithRegex(amountInputPayment)
        : DEFAULT_CALCULATION_NUMBER,
      [DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF]: uuidAttachment,
    };

    if (expired_date) {
      return {
        ...dataPayment,
        expired_date: expired_date ? moment(expired_date).toISOString(true) : '',
      };
    }

    return objHelper.filterKeyObj(dataPayment, ['expired_date']);
  });

  dataAddInvoice = arrHelp.filterExistedValueOnObjItem(
    dataAddInvoice,
    allowKeysInvoiceData,
    [],
    [DEFAULT_CALCULATION_NUMBER],
  );

  dataAddInvoice = dataAddInvoice.map((dataInvoice) => {
    const { amount_input: amount } = dataInvoice;

    dataInvoice = {
      ...dataInvoice,
      amount: amount
        ? formatHelp.reverseCurrencyFormatWithRegex(amount)
        : DEFAULT_CALCULATION_NUMBER,
    };

    return objHelper.filterKeyObj(dataInvoice, [], allowKeysBodyPurchasePaymentOnInvoice);
  });

  const bodyAddPurchasePaymentData = {
    ...dataAddPayment[0],
    invoice: dataAddInvoice,
  };

  return objHelper.filterKeyObj(bodyAddPurchasePaymentData, [], allowKeysBodyPurchasePayment);
};
