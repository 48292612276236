import * as React from 'react';
function SvgPenEdit(props) {
  return (
    <svg width={13} height={13} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.148.852a.502.502 0 01.708.004l2.288 2.288a.5.5 0 01.004.708L5 11 2 8 9.148.852zM1 9l3 3-3.51.877c-.27.068-.436-.092-.367-.367L1 9z'
        fill='#42526E'
      />
    </svg>
  );
}
export default SvgPenEdit;
