import { useTranslation } from 'react-i18next';

import objHelper from '../utils/helpers/object.helper';

/**
 * provide function for modifyTranslation
 * @returns
 */
export default function useModifyTranslation() {
  const { t } = useTranslation();

  /**
   *
   * @param { object } options options for targeting each value for translate
   * @returns
   */
  function translateOptions(options) {
    return objHelper.changeFormatValueV2(options, Object.keys(options), '', t);
  }

  /**
   *
   * @param { string } key        key for target translation
   * @param { object } options    option translate, then translate each value
   *
   * combine key of translation and also generate translate each options
   *
   * @returns
   */
  function combineTranslation(key, options) {
    const translatedOptions = translateOptions(options);
    return t(key, translatedOptions);
  }

  return {
    translateOptions,
    combineTranslation,
  };
}
