import { cloneDeep, omit } from 'lodash';

import {
  REACT_APP_API_URL_DEBIT_NOTE,
  REACT_APP_API_URL_DEBIT_NOTE_DETAILS,
  REACT_APP_API_URL_DEBIT_NOTE_HISTORY,
  REACT_APP_API_URL_PURCHASE_DELIVERY,
  REACT_APP_API_URL_PURCHASE_DELIVERY_HISTORY,
  REACT_APP_API_URL_PURCHASE_INVOICE,
  REACT_APP_API_URL_PURCHASE_INVOICE_DETAILS_TEMPLATE,
  REACT_APP_API_URL_PURCHASE_INVOICE_HISTORY,
  REACT_APP_API_URL_PURCHASE_INVOICE_UPLOAD,
  REACT_APP_API_URL_PURCHASE_INVOICE_UPLOAD_PROGRESS,
  REACT_APP_API_URL_PURCHASE_ORDER,
  REACT_APP_API_URL_PURCHASE_ORDER_HISTORY,
  REACT_APP_API_URL_PURCHASE_PAYMENT,
  REACT_APP_API_URL_PURCHASE_PAYMENT_HISTORY,
  REACT_APP_API_URL_PURCHASE_RETURN,
  REACT_APP_API_URL_PURCHASE_RETURN_DETAIL,
  REACT_APP_API_URL_PURCHASE_RETURN_HISTORY,
  REACT_APP_API_URL_QUOTATION_REQUEST,
  REACT_APP_API_URL_QUOTATION_REQUEST_HISTORY,
  REACT_APP_API_URL_RECEIVABLE_PAYMENT,
  REACT_APP_API_URL_RECEIVABLE_PAYMENT_DETAILS,
  REACT_APP_API_URL_RECEIVABLE_PAYMENT_HISTORY,
} from '../../../utils/configs/api.config';
import { generalizeDataAddUpdatePurchaseDelivery } from '../../../utils/data/display/initial-add-purchase-delivery';
import { generalizeDataAddUpdatePurchaseOrder } from '../../../utils/data/display/initial-add-purchase-order';
import { generalizeDataAddUpdateQuotationRequest } from '../../../utils/data/display/initial-add-quotation-request';
import { DEFAULT_FILE_TYPE_CSV } from '../../../utils/default/file-type.default';
import {
  DEFAULT_ALLOW_PARAMS_PURCHASE_DEBIT_NOTE_LIST,
  DEFAULT_ALLOW_PARAMS_PURCHASE_RECEIVABLE_PAYMENT_LIST,
  DEFAULT_ALLOW_PARAMS_PURCHASE_RETURN_DETAILS,
  DEFAULT_ALLOW_PARAMS_PURCHASE_RETURN_LIST,
  DEFAULT_PURCHASE_DELIVERY_LIST_PARAMS,
  DEFAULT_PURCHASE_INVOICE_LIST_PARAMS,
  DEFAULT_PURCHASE_ORDER_LIST_PARAMS,
  DEFAULT_PURCHASE_PAYMENT_LIST_PARAMS,
  DEFAULT_PURCHASE_QR_LIST_PARAMS,
} from '../../../utils/default/params.default';
import {
  creatorAddService,
  creatorDeleteService,
  creatorDeleteServiceWithTemplateUrl,
  creatorDetailService,
  creatorDetailServiceV2,
  creatorDetailServiceWithParams,
  creatorListService,
  creatorMultipartFormDataService,
  creatorUpdateService,
  creatorUpdateServiceWithTemplateURL,
} from '../creator-services/creator-services.helper';

/**
 *
 * quotation request services
 *
 */
const getListQuotationRequest = async (paramsGetListQuotationRequest) => {
  return await creatorListService(
    REACT_APP_API_URL_QUOTATION_REQUEST,
    paramsGetListQuotationRequest,
    DEFAULT_PURCHASE_QR_LIST_PARAMS,
  );
};

const getDetailQuotationRequest = async (quotationRequestID) => {
  return await creatorDetailService(REACT_APP_API_URL_QUOTATION_REQUEST, quotationRequestID);
};

const getHistoryQuotationRequest = async (paramsGetQuotationRequestHistory) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_QUOTATION_REQUEST_HISTORY,
    paramsGetQuotationRequestHistory,
  );
};

const addQuotationRequest = async (bodyAddQuotationRequest) => {
  return creatorAddService(
    REACT_APP_API_URL_QUOTATION_REQUEST,
    bodyAddQuotationRequest,
    generalizeDataAddUpdateQuotationRequest,
  );
};

const updateQuotationRequest = async (bodyUpdateQuotationRequest) => {
  const { quotation_request_id } = bodyUpdateQuotationRequest;

  return creatorUpdateService(
    quotation_request_id,
    REACT_APP_API_URL_QUOTATION_REQUEST,
    bodyUpdateQuotationRequest,
    generalizeDataAddUpdateQuotationRequest,
  );
};

const deleteQuotationRequest = async (paramsDeleteQuotationRequest) => {
  const { quotation_request_id } = paramsDeleteQuotationRequest;

  return await creatorDeleteService(quotation_request_id, REACT_APP_API_URL_QUOTATION_REQUEST);
};

/**
 *
 * purchase order services
 *
 */

const getListPurchaseOrder = async (paramsGetListPurchaseOrder) => {
  return await creatorListService(
    REACT_APP_API_URL_PURCHASE_ORDER,
    paramsGetListPurchaseOrder,
    DEFAULT_PURCHASE_ORDER_LIST_PARAMS,
  );
};

const getDetailPurchaseOrder = async (paramsGetDetailPurchaseOrder) => {
  const { purchase_order_id } = paramsGetDetailPurchaseOrder;

  return await creatorDetailService(REACT_APP_API_URL_PURCHASE_ORDER, purchase_order_id);
};

const getDetailPurchaseOrderV2 = async (purchaseOrderID) => {
  return await creatorDetailService(REACT_APP_API_URL_PURCHASE_ORDER, purchaseOrderID);
};

// params required include purchase_order_id
const getHistoryPurchaseOrder = async (paramsGetPurchaseOrderHistory) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_PURCHASE_ORDER_HISTORY,
    paramsGetPurchaseOrderHistory,
  );
};

const addPurchaseOrder = async (paramsAddPurchaseOrder) => {
  return await creatorAddService(
    REACT_APP_API_URL_PURCHASE_ORDER,
    paramsAddPurchaseOrder,
    generalizeDataAddUpdatePurchaseOrder,
  );
};

const updatePurchaseOrder = async (bodyUpdatePurchaseOrder) => {
  const { purchase_order_id } = bodyUpdatePurchaseOrder;

  return await creatorUpdateService(
    purchase_order_id,
    REACT_APP_API_URL_PURCHASE_ORDER,
    bodyUpdatePurchaseOrder,
    generalizeDataAddUpdatePurchaseOrder,
  );
};

const deletePurchaseOrder = async (paramsDeletePurchaseOrder) => {
  const { purchase_order_id } = paramsDeletePurchaseOrder;

  return await creatorDeleteService(purchase_order_id, REACT_APP_API_URL_PURCHASE_ORDER);
};

/**
 *
 * purchase delivery services
 *
 */

const getListPurchaseDelivery = async (paramsGetListPurchaseDelivery) => {
  return await creatorListService(
    REACT_APP_API_URL_PURCHASE_DELIVERY,
    paramsGetListPurchaseDelivery,
    DEFAULT_PURCHASE_DELIVERY_LIST_PARAMS,
  );
};

const getDetailPurchaseDelivery = async (purchaseDeliveryID) => {
  return await creatorDetailService(REACT_APP_API_URL_PURCHASE_DELIVERY, purchaseDeliveryID);
};

// params required include purchase_delivery_id
const getPurchaseDeliveryHistoryDetails = async (paramsGetPurchaseDeliveryHistoryDetails) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_PURCHASE_DELIVERY_HISTORY,
    paramsGetPurchaseDeliveryHistoryDetails,
  );
};

const addPurchaseDelivery = async (bodyAddPurchaseDelivery) => {
  return creatorAddService(
    REACT_APP_API_URL_PURCHASE_DELIVERY,
    bodyAddPurchaseDelivery,
    generalizeDataAddUpdatePurchaseDelivery,
  );
};

const updatePurchaseDelivery = async (bodyUpdatePurchaseDelivery) => {
  const { purchase_delivery_id } = bodyUpdatePurchaseDelivery;

  return creatorUpdateService(
    purchase_delivery_id,
    REACT_APP_API_URL_PURCHASE_DELIVERY,
    bodyUpdatePurchaseDelivery,
    generalizeDataAddUpdatePurchaseDelivery,
  );
};

const deletePurchaseDelivery = async (paramsDeletePurchaseDelivery) => {
  const { purchase_delivery_id } = paramsDeletePurchaseDelivery;

  return await creatorDeleteService(purchase_delivery_id, REACT_APP_API_URL_PURCHASE_DELIVERY);
};

/**
 *
 * purchase payment services
 *
 */

const getListPurchasePayment = async (paramsGetListPurchasePayment) => {
  return await creatorListService(
    REACT_APP_API_URL_PURCHASE_PAYMENT,
    paramsGetListPurchasePayment,
    DEFAULT_PURCHASE_PAYMENT_LIST_PARAMS,
  );
};

const detailPurchasePayment = async (purchasePaymentID) => {
  return await creatorDetailService(REACT_APP_API_URL_PURCHASE_PAYMENT, purchasePaymentID);
};

// params required include purchase_payment_id
const getHistoryPurchasePayment = async (paramsGetPurchasePaymentHistory) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_PURCHASE_PAYMENT_HISTORY,
    paramsGetPurchasePaymentHistory,
  );
};

const addPurchasePayment = async (bodyAddPurchasePayment) => {
  return await creatorAddService(REACT_APP_API_URL_PURCHASE_PAYMENT, bodyAddPurchasePayment);
};

const updatePurchasePayment = async (bodyUpdatePurchasePayment) => {
  const { purchase_payment_id } = bodyUpdatePurchasePayment;

  return await creatorUpdateService(
    purchase_payment_id,
    REACT_APP_API_URL_PURCHASE_PAYMENT,
    bodyUpdatePurchasePayment,
  );
};

const deletePurchasePayment = async (paramsDeletePurchasePayment) => {
  const { purchase_payment_id } = paramsDeletePurchasePayment;

  return await creatorDeleteService(purchase_payment_id, REACT_APP_API_URL_PURCHASE_PAYMENT);
};

/**
 *
 * purchase invoice services
 *
 */

const getListPurchaseInvoice = async (paramsGetListPurchaseInvoice) => {
  return await creatorListService(
    REACT_APP_API_URL_PURCHASE_INVOICE,
    paramsGetListPurchaseInvoice,
    DEFAULT_PURCHASE_INVOICE_LIST_PARAMS,
  );
};

const getDetailPurchaseInvoice = async (paramsGetDetailPurchaseInvoice) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_PURCHASE_INVOICE_DETAILS_TEMPLATE,
    paramsGetDetailPurchaseInvoice,
  );
};

// params required include purchase_invoice_id
const getHistoryPurchaseInvoice = async (paramsGetPurchaseInvoiceHistory) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_PURCHASE_INVOICE_HISTORY,
    paramsGetPurchaseInvoiceHistory,
  );
};

const addPurchaseInvoice = async (bodyAddPurchaseInvoice) => {
  return await creatorAddService(REACT_APP_API_URL_PURCHASE_INVOICE, bodyAddPurchaseInvoice, null);
};

const importPurchaseInvoice = async (params) => {
  return await creatorMultipartFormDataService({
    endpoint: REACT_APP_API_URL_PURCHASE_INVOICE_UPLOAD,
    bodyData: params,
    grabToNewFormData: true,
    formDataKeyname: DEFAULT_FILE_TYPE_CSV,
  });
};

const getImportPurchaseInvoiceProgress = async (params) => {
  return await creatorDetailServiceV2(REACT_APP_API_URL_PURCHASE_INVOICE_UPLOAD_PROGRESS, params);
};

const updatePurchaseInvoice = async (bodyUpdatePurchaseInvoice) => {
  return await creatorUpdateService(
    cloneDeep(bodyUpdatePurchaseInvoice.purchase_invoice_id),
    REACT_APP_API_URL_PURCHASE_INVOICE,
    omit(bodyUpdatePurchaseInvoice, 'purchase_invoice_id'),
    null,
  );
};

const deletePurchaseInvoice = async (paramsDeletePurchaseInvoice) => {
  const { purchase_invoice_id } = paramsDeletePurchaseInvoice;

  return await creatorDeleteService(purchase_invoice_id, REACT_APP_API_URL_PURCHASE_INVOICE);
};

/**
 *
 * purchase debit note services
 *
 */

const getPurchaseDebitNoteList = async (paramsGetPurchaseDebitNote) => {
  return await creatorListService(
    REACT_APP_API_URL_DEBIT_NOTE,
    paramsGetPurchaseDebitNote,
    DEFAULT_ALLOW_PARAMS_PURCHASE_DEBIT_NOTE_LIST,
    {},
  );
};

const getPurchaseDebitNoteDetails = async (params) => {
  return await creatorDetailServiceV2(REACT_APP_API_URL_DEBIT_NOTE_DETAILS, params);
};

const getPurchaseDebitNoteHistory = async (params) => {
  return await creatorDetailServiceV2(REACT_APP_API_URL_DEBIT_NOTE_HISTORY, params);
};

/**
 *
 * purchase return services
 *
 */

const getPurchaseReturnList = async (paramsGetPurchaseReturnList) => {
  return await creatorListService(
    REACT_APP_API_URL_PURCHASE_RETURN,
    paramsGetPurchaseReturnList,
    DEFAULT_ALLOW_PARAMS_PURCHASE_RETURN_LIST,
  );
};

const getPurchaseReturnDetails = async (paramsGetPurchaseReturnDetails) => {
  return await creatorDetailServiceWithParams({
    url: REACT_APP_API_URL_PURCHASE_RETURN_DETAIL,
    identityToCombineOnUrl: paramsGetPurchaseReturnDetails,
    params: paramsGetPurchaseReturnDetails,
    allowParams: DEFAULT_ALLOW_PARAMS_PURCHASE_RETURN_DETAILS,
  });
};

const getPurchaseReturnHistory = async (params) => {
  return await creatorDetailServiceV2(REACT_APP_API_URL_PURCHASE_RETURN_HISTORY, params);
};

const createPurchaseReturn = async (bodyCreatePurchaseReturn) => {
  return await creatorAddService(REACT_APP_API_URL_PURCHASE_RETURN, bodyCreatePurchaseReturn);
};

const updatePurchaseReturn = async (bodyUpdatePurchaseReturn) => {
  return await creatorUpdateServiceWithTemplateURL(
    REACT_APP_API_URL_PURCHASE_RETURN_DETAIL,
    bodyUpdatePurchaseReturn,
  );
};

const deletePurchaseReturn = async (paramsDeletePurchaseReturn) => {
  return await creatorDeleteServiceWithTemplateUrl(
    REACT_APP_API_URL_PURCHASE_RETURN_DETAIL,
    paramsDeletePurchaseReturn,
  );
};

/**
 *
 * purchase recivable payment services
 *
 */

const getPurchaseReceivablePaymentList = async (params) => {
  return await creatorListService(
    REACT_APP_API_URL_RECEIVABLE_PAYMENT,
    params,
    DEFAULT_ALLOW_PARAMS_PURCHASE_RECEIVABLE_PAYMENT_LIST,
  );
};

const getPurchaseReceivablePaymentDetails = async (params) => {
  return await creatorDetailServiceV2(REACT_APP_API_URL_RECEIVABLE_PAYMENT_DETAILS, params);
};

const getPurchaseReceivablePaymentHistory = async (params) => {
  return await creatorDetailServiceV2(REACT_APP_API_URL_RECEIVABLE_PAYMENT_HISTORY, params);
};

const createPurchaseReceivablePayment = async (body) => {
  return await creatorAddService(REACT_APP_API_URL_RECEIVABLE_PAYMENT, body);
};

const updatePurchaseReceivablePayment = async (bodyUpdatePurchaseReceivablePayment) => {
  return await creatorUpdateServiceWithTemplateURL(
    REACT_APP_API_URL_RECEIVABLE_PAYMENT_DETAILS,
    bodyUpdatePurchaseReceivablePayment,
  );
};

const deletePurchaseReceivablePayment = async (paramsDeletePurchaseReceivablePayment) => {
  return await creatorDeleteServiceWithTemplateUrl(
    REACT_APP_API_URL_RECEIVABLE_PAYMENT_DETAILS,
    paramsDeletePurchaseReceivablePayment,
  );
};

const purchaseServices = {
  getListQuotationRequest,
  getDetailQuotationRequest,
  getHistoryQuotationRequest,
  addQuotationRequest,
  updateQuotationRequest,
  deleteQuotationRequest,
  getListPurchaseOrder,
  getDetailPurchaseOrder,
  getDetailPurchaseOrderV2,
  getHistoryPurchaseOrder,
  addPurchaseOrder,
  updatePurchaseOrder,
  deletePurchaseOrder,
  getListPurchaseDelivery,
  getDetailPurchaseDelivery,
  getPurchaseDeliveryHistoryDetails,
  addPurchaseDelivery,
  updatePurchaseDelivery,
  deletePurchaseDelivery,
  getListPurchasePayment,
  detailPurchasePayment,
  getHistoryPurchasePayment,
  addPurchasePayment,
  updatePurchasePayment,
  deletePurchasePayment,
  getListPurchaseInvoice,
  getDetailPurchaseInvoice,
  getHistoryPurchaseInvoice,
  addPurchaseInvoice,
  importPurchaseInvoice,
  getImportPurchaseInvoiceProgress,
  updatePurchaseInvoice,
  deletePurchaseInvoice,
  getPurchaseReturnList,
  getPurchaseReturnDetails,
  getPurchaseReturnHistory,
  createPurchaseReturn,
  updatePurchaseReturn,
  deletePurchaseReturn,
  getPurchaseDebitNoteList,
  getPurchaseDebitNoteDetails,
  getPurchaseDebitNoteHistory,
  getPurchaseReceivablePaymentList,
  getPurchaseReceivablePaymentDetails,
  getPurchaseReceivablePaymentHistory,
  createPurchaseReceivablePayment,
  updatePurchaseReceivablePayment,
  deletePurchaseReceivablePayment,
};

export default purchaseServices;
