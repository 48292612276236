import moment from 'moment';

import PasswordStrengthManager from '../../services/modules/PasswordStrengthManager';
import {
  DEFAULT_MAX_DEBIT_CREDIT_NUMBER,
  DEFAULT_MIN_DEBIT_CREDIT_NUMBER,
} from '../data/default/number.default';

import dateHelpers from './date.helpers';
import numberHelper from './number.helper';
import strHelp from './string.helpers';

const validation = {
  isNotEmpty: (value) => {
    if (typeof value !== 'string') {
      return Boolean(String(value).replace(/\s+/g, '').length);
    }

    return Boolean(value.replace(/\s+/g, '').length);
  },
  notnull: (value) => {
    return !!value;
  },
  nullable: (value) => {
    if (!value) {
      return true;
    }

    return Boolean(value);
  },
  isNumber: (value) => {
    if (typeof value !== 'string') {
      return false;
    }

    return new RegExp(/^\d+$/).test(value);
  },
  isNumberPhone: (value) => {
    return new RegExp(/^[+]?[0-9]{9,15}$/).test(value);
  },
  isNumberPhoneWithoutPlusPrefix: (value) => {
    return new RegExp(/[0-9]{9,15}/).test(value);
  },
  isStrongPasswordWithRegex: (value, minResultScore = 3) => {
    return (
      new PasswordStrengthManager({
        valuePassword: value,
      }).result.score >= minResultScore
    );
  },
  isLengthGreaterThan: (value, minLength = 8) => {
    if (typeof value === 'string') {
      return value.replace(/\s+/g, '').trim().length >= minLength;
    }

    return false;
  },
  isEmail: (value) => {
    return new RegExp(/\S+@\S+\.\S+/).test(value);
  },
  isWebsite: (value) => {
    if (typeof value !== 'string') {
      return value;
    }

    return new RegExp(
      /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/,
    ).test(value);
  },
  isBeforeDate: (value) => {
    return (
      validation.notnull(value) &&
      dateHelpers.isValidDateV2(new Date(value)) &&
      moment(new Date(value)).isBefore(new Date())
    );
  },
  isNotEmptyBalance: (value) => {
    return (
      validation.notnull(value) &&
      !strHelp.isEmpty(value, ['0.00', '0', '0.0']) &&
      numberHelper.isNumberBetween(
        value,
        DEFAULT_MIN_DEBIT_CREDIT_NUMBER,
        DEFAULT_MAX_DEBIT_CREDIT_NUMBER,
      )
    );
  },
  isNPWPNumber: (value) => {
    if (!value || typeof value !== 'string') return false;

    return [...value].filter((v) => /\d/.test(v)).length === 15;
  },
  isEqualValue: (value, anotherValue) => {
    if (typeof value === 'string' && typeof anotherValue === 'string') {
      return value === anotherValue;
    }

    return false;
  },
  isOptionEqualToValueAutoComplete: (option, value) => {
    if (!option || !value) {
      return false;
    }

    if (typeof value === 'string') {
      return option.value === value || option.label === value;
    }

    if (value.value) {
      return option.value === value.value;
    }

    return (
      option.label === value.label || option.value === value.value || option.value === value.label
    );
  },
  nullableButSometimes: (value, funcCallback) => {
    if (!value) return validation.nullable(value);

    return funcCallback(value);
  },
  isBeforeThanNow: (value) => {
    return (
      validation.notnull(value) &&
      dateHelpers.isValidDateV2(new Date(value)) &&
      moment(new Date(value)).isBefore(new Date())
    );
  },
  isBeforeThanMidnightTommorow: (value) => {
    return (
      validation.notnull(value) &&
      dateHelpers.isValidDateV2(new Date(value)) &&
      moment(new Date(value)).isBefore(moment().add(1, 'day').startOf('day'))
    );
  },
  isNumberGreaterOrEqualThan: (value, minVal = 1) => {
    if (typeof value === 'string') {
      return Number(value) >= minVal;
    }

    if (typeof value !== 'number') {
      return false;
    }

    return value >= minVal;
  },
};

export default validation;
