import { DEFAULT_GRID_ACTION_COLLAPSE_TYPE } from '../../default/grid-type.default';
import { DEFAULT_INPUT_TYPE_CALCULATION_NUMBER_INPUT } from '../../default/input-type.default';
import {
  DEFAULT_KEY_NAME_AMOUNT_AVAILABLE_CONST,
  DEFAULT_KEY_NAME_AMOUNT_INPUT,
} from '../../default/object-keyname.default';
import { DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE } from '../../default/representation-data-type.default';

// grid header credit note sales payable payment add and update
export const gridHeaderCreditNoteSalesPayablePayment = [
  {
    name: DEFAULT_GRID_ACTION_COLLAPSE_TYPE,
    label: '',
    align: 'left',
    width: 20,
    gridProps: {
      xs: 0.75,
    },
    isButton: true,
  },
  {
    name: 'transaction_no',
    label: 'table.header-column.credit-note-no',
    isUseMultipleNames: true,
    width: 250,
    minWidth: 150,
    gridProps: {
      xs: 4.5,
      display: 'flex',
      justifyContent: 'start',
    },
    isTypography: true,
  },
  {
    name: 'amount',
    label: 'table.header-column.total-bill',
    align: 'left',
    width: 50,
    minWidth: 50,
    gridProps: {
      xs: 2.25,
      display: 'flex',
      justifyContent: 'end',
    },
    isTypography: true,
    representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
  },
  {
    name: DEFAULT_KEY_NAME_AMOUNT_AVAILABLE_CONST,
    label: 'table.header-column.remaining-bill',
    align: 'left',
    width: 50,
    minWidth: 50,
    gridProps: {
      xs: 2.25,
      display: 'flex',
      justifyContent: 'end',
    },
    isTypography: true,
    representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
  },
  {
    name: 'amount_input',
    placeholder: 'placeholder.calculation-number-default',
    label: 'table.header-column.amount-paid',
    align: 'right',
    width: 100,
    minWidth: 80,
    gridProps: {
      xs: 2.25,
      display: 'flex',
      justifyContent: 'end',
    },
    isInput: true,
    inputType: DEFAULT_INPUT_TYPE_CALCULATION_NUMBER_INPUT,
    keyInputName: DEFAULT_KEY_NAME_AMOUNT_INPUT,
    isDisabled: () => {
      return false;
    },
    keyMaximumInput: DEFAULT_KEY_NAME_AMOUNT_AVAILABLE_CONST,
  },
];

export const gridCollapseCreditNoteSalesPayablePayment = [
  {
    gridProps: {
      xs: 0.75,
    },
  },
  {
    placeholder: 'label.memo',
    name: 'description',
    gridProps: {
      xs: 10.5,
    },
    isTypography: true,
  },
  {
    gridProps: {
      xs: 0.75,
    },
  },
];
