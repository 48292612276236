export const DEFAULT_MAX_LENGTH_DATA_DESCRIPTION_PRIMARY = 200;
export const DEFAULT_MAX_LENGTH_DATA_DESCRIPTION_SECONDARY = 100;
export const DEFAULT_MAX_LENGTH_DATA_DESCRIPTION_TERSIER = 50;
export const DEFAULT_MAX_LENGTH_DATA_MEMO_PRIMARY = 20;
export const DEFAULT_MAX_LENGTH_DATA_MEMO_SECONDARY = 30;
export const DEFAULT_MAX_LENGTH_NUMBER_REFERENCE = 4;
export const DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE = Infinity;
export const DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_UNSTABLE = 10;
export const DEFAULT_MAX_LENGTH_INPUT_DESCRIPTION_PRIMARY = 200;
export const DEFAULT_MAX_LENGTH_INPUT_DESCRIPTION_SECONDARY = 50;
export const DEFAULT_MAX_LENGTH_OPTIONS_AUTOCOMPLETE = 100000;
export const DEFAULT_MAX_LENGTH_DATA_PRIMARY = 255;
export const DEFAULT_MAX_LENGTH_PHONE_NUMBER = 14;
export const DEFAULT_DATA_MAX_LENGTH_ON_LOCAL_STORAGE = 25;
export const DEFAULT_DATA_MAX_LENGTH_FILENAME = 13;
export const DEFAULT_DATA_MAX_LENGTH_FOR_PRODUCT_CODE = 30;
export const DEFAULT_DATA_MAX_LENGTH_CHART_Y_AXIS_VALUE = 12;
export const DEFAULT_DATA_MAX_LENGTH_SPLITTED_CHART_Y_AXIS_VALUE = 8;
export const DEFAULT_DATA_MAX_LENGTH_ASSET_NUMBER = 6;
export const DEFAULT_DATA_MAX_CURRENT_COMPANY_NAME_LENGTH = 17;
