// about key name for determine of chart dashboard type

// top sales
export const CHART_PRO_DASHBOARD_TOP_SALES_MAIN_TYPE = 'chart-pro-dashboard-top-sales-main';
export const CHART_PRO_DASHBOARD_TOP_SALES_SALES_AGENT_TYPE =
  'chart-pro-dashboard-top-sales-sales-agent';
export const CHART_PRO_DASHBOARD_TOP_SALES_CUSTOMER_TYPE = 'chart-pro-dashboard-top-sales-customer';
export const CHART_PRO_DASHBOARD_TOP_SALES_PRODUCT_TYPE = 'chart-pro-dashboard-top-sales-product';
export const CHART_PRO_DASHBOARD_TOP_SALES_PRODUCT_CATEGORY_TYPE =
  'chart-pro-dashboard-top-sales-product-category';

// top sales comparison
export const CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_MAIN_TYPE =
  'chart-pro-dashboard-top-sales-comparison-main';
export const CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_SALES_AGENT_TYPE =
  'chart-pro-dashboard-top-sales-comparison-sales-agent';
export const CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_CUSTOMER_TYPE =
  'chart-pro-dashboard-top-sales-comparison-customer';
export const CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_PRODUCT_TYPE =
  'chart-pro-dashboard-top-sales-comparison-product';
export const CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_PRODUCT_CATEGORY_TYPE =
  'chart-pro-dashboard-top-sales-comparison-product-category';
