import { grey } from '@mui/material/colors';

import { blue, success, warning } from '../../../assets/colors';
import gray from '../../../assets/colors/gray';

export const primaryParagraph = {
  margin: 0,
  fontSize: '12px',
  fontFamily: 'MariupolReguler',
  color: 'black',
};

export const interParagraph = {
  ...primaryParagraph,
  fontFamily: 'Inter',
};

export const interParagraphUppercase = {
  ...interParagraph,
  textTransform: 'uppercase',
};

export const primaryParagraphUppercase = {
  ...primaryParagraph,
  textTransform: 'uppercase',
};

export const primaryParagraphWithMarginBottom = {
  ...primaryParagraph,
  marginBottom: 2,
};

export const primaryParagraphWithMarginBottomUppercase = {
  ...primaryParagraphWithMarginBottom,
  textTransform: 'uppercase',
};

export const secondaryParagraph = {
  ...primaryParagraph,
  fontSize: '14px',
  fontWeight: 700,
};

export const tersierParagraph = {
  ...primaryParagraph,
  fontSize: '14px',
  fontWeight: 600,
};

export const centerParagraph = {
  ...primaryParagraph,
  textAlign: 'center',
};

export const primaryTitleParagraph = {
  ...centerParagraph,
  fontSize: '18px',
  fontWeight: 700,
};

export const primaryTitleParagraphWithoutCentering = {
  ...primaryParagraph,
  fontSize: '18px',
  fontWeight: 700,
};

export const secondaryTitleParagraph = {
  ...centerParagraph,
  fontSize: '14px',
};

export const primaryLabelParagraph = {
  ...primaryParagraph,
  fontWeight: 600,
  marginBottom: 8,
  color: '#6B778C',
};

export const secondaryLabelParagraph = {
  ...primaryLabelParagraph,
  fontWeight: 500,
  marginBottom: 0,
};

export const headSignatureParagraph = {
  ...centerParagraph,
  fontSize: '12px',
  fontWeight: 600,
  marginBottom: 64,
  color: '#6B778C',
};

export const secondarySubtitleCalculationNumberParagraph = {
  ...secondaryTitleParagraph,
  fontSize: 14,
  fontWeight: 700,
  textAlign: 'right',
};

export const secondarySubtitleCalculationNumberParagraphReduceFontWight = {
  ...secondarySubtitleCalculationNumberParagraph,
  fontSize: '12px',
  fontWeight: 600,
};

export const primaryStack = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 16,
};

export const primaryStackHalfMB = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 8,
};

export const primaryStackFourMB = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 4,
};

export const primaryStackNoneMB = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 0,
};

export const primaryStackNoneMarginCenter = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 0,
};

export const secondaryStack = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 16,
};

export const secondaryStackNoneMargin = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 0,
};

export const secondaryStartStack = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  marginBottom: 4,
};

export const secondaryStartStackNoneMB = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  marginBottom: 0,
};

export const stackColumnEndAlign = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
};

export const stackColumnStartAlign = {
  ...stackColumnEndAlign,
  alignItems: 'start',
  gap: '4px',
};

export const primaryFullWidthStack = {
  width: '100%',
  marginBottom: 16,
};

export const tableBorderCollapse = {
  borderCollapse: 'collapse',
};

export const primaryTable = {
  width: '100%',
  borderCollapse: 'collapse',
};

export const primaryTableWithMarginBottom = {
  ...primaryTable,
  marginBottom: '8px',
};

export const primaryTableWithMarginBottomV2 = {
  ...primaryTableWithMarginBottom,
  marginBottom: '12px',
};

export const secondaryTable = {
  width: '100%',
  borderCollapse: 'separate',
  borderSpacing: 0,
};

export const secondaryTableWithMarginBottom = {
  ...secondaryTable,
  marginBottom: '8px',
};

export const primaryTableHead = {
  textAlign: 'left',
  color: 'white',
  padding: '8px 16px',
  background: '#667085',
  fontSize: '12px',
  fontWeight: '500',
  border: '1px solid #667085',
};

export const primaryTableSubHead = {
  color: 'black',
  background: grey[200],
};

export const primaryTableKeyValueTable = {
  minWidth: '208px',
  borderCollapse: 'collapse',
};

export const primaryTableKeyValueTableHead = {
  textAlign: 'left',
  color: 'white',
  padding: '8px 16px',
  borderStyle: 'solid',
  fontSize: '12px',
  fontWeight: '500',
  borderColor: 'rgb(102, 112, 133)',
  borderWidth: '1px',
  borderBottomColor: 'white',
  background: '#667085',
  minWidth: 'calc(98px - 32px)',
};

export const primaryTableKeyValueTableHeadLastOrder = {
  ...primaryTableKeyValueTableHead,
  borderBottomColor: 'rgb(102, 112, 133)',
};

export const secondaryTableKeyValueTableHead = {
  ...primaryTableKeyValueTableHead,
  color: 'black',
  fontWeight: '500!important',
  padding: '4px 8px',
  background: 'white',
  borderWidth: '0px',
};

export const primaryTableDataSingleDividerTableHeadTableBody = {
  padding: '0.5px',
  border: '1px solid #667085',
  background: 'white',
};

export const secondaryTableDataSingleDividerTableHeadTableBody = {
  padding: '0.5px',
  border: '1px solid #667085',
  background: 'white',
};

export const primaryTableData = {
  textAlign: 'left',
  padding: '4px 8px',
  border: '1px solid white',
};

export const secondaryTableData = {
  textAlign: 'left',
  padding: '4px 8px',
  border: '1px solid #667085',
  minWidth: '216px',
  maxWidth: '220px',
};

export const tersierTableData = {
  ...secondaryTableData,
  border: '0px',
  minWidth: '56px',
  maxWidth: 'unset',
};

export const primaryTableDataReducePadding = {
  padding: '4px 8px',
  border: '1px solid #667085',
};

export const secondaryTableDataReducePadding = {
  border: '1px solid #667085',
  padding: '4px 8px 4px 16px',
};

export const noBorderTableData = {
  border: 0,
};

export const primaryTableDataCalculationNumber = {
  ...primaryTableData,
  textAlign: 'right',
};

export const primaryTableDataGreyBackgroundNgreyTopBottomBorder = {
  textAlign: 'left',
  padding: '6px 8px',
  borderWidth: '1px 0px',
  borderStyle: 'solid',
  borderColor: grey[200],
  backgroundColor: grey[50],
};

export const primaryTableDataGreyBackgroundNgreyOnlyTopBorder = {
  ...primaryTableDataGreyBackgroundNgreyTopBottomBorder,
  borderWidth: '1px 0px 0px 0px',
};

export const primaryTableDataGreyBackgroundNgreyOnlyTopBottom = {
  ...primaryTableDataGreyBackgroundNgreyTopBottomBorder,
  borderWidth: '0px 0px 1px 0px',
};

export const primaryBoxContainerNotFoundData = {
  width: '100%',
  height: 'auto',
  display: 'flex',
  minHeight: '20vh',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

export const primaryBoxContainerSignature = {
  minWidth: '200px',
  width: '20%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

export const secondaryContainerSignature = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  width: '128px',
};

export const primaryHorizontalRow = {
  margin: 0,
};

export const dottedHorizontalRow = {
  margin: '8px 0px',
  color: 'black',
  border: 'dotted 1px',
};

export const dashedHorizontalRow = {
  margin: '8px 0px',
  color: 'black',
  border: 'dashed 1px',
  borderStyle: 'none none dashed',
};

export const solidBlackHorizontalRow = {
  margin: '0px',
  marginBottom: '4px',
  borderStyle: 'solid',
  borderColor: 'white',
  borderTopColor: 'black',
};

export const solidGreyHorizontalRow = {
  margin: '0px',
  borderTop: '2px',
  borderStyle: 'solid',
  borderColor: 'white',
  borderTopColor: gray['B300'],
};

export const primarySubtitle = {
  margin: 0,
  fontSize: '14px',
  fontFamily: 'MariupolReguler',
};

export const secondarySubtitle = {
  margin: 0,
  fontSize: '12px',
  fontFamily: 'MariupolReguler',
  color: '#667085',
};

export const divDisplayBlock = {
  display: 'block',
};

export const divMarginRightSixteen = {
  marginRight: 16,
};

export const typographyBreakAllWordBreak = {
  wordBreak: 'break-all',
};

export const textAlignLeft = {
  textAlign: 'left',
};

export const tableDataZeroColSpan = {
  colSpan: 0,
};

export const tableDataOneColSpan = {
  colSpan: 1,
};

export const displayFlexAndFlexGrow = {
  display: 'flex',
  flexGrow: 1,
};

export const interPrimaryParagraph = {
  ...interParagraph,
  fontWeight: 500,
};

export const interSecondaryParagraph = {
  ...interPrimaryParagraph,
  color: gray['B500'],
};

export const interTersierParagraph = {
  ...interPrimaryParagraph,
  color: gray['B900'],
};

export const interFourthParagraph = {
  ...interPrimaryParagraph,
  color: gray['B800'],
};

export const interSubtitlePrimaryParagraph = {
  ...interSecondaryParagraph,
  fontSize: 16,
};

export const interSubtitlePrimaryParagraphV2 = {
  ...interPrimaryParagraph,
  fontWeight: 400,
};

export const interUppercaseTitleTersierParagraph = {
  ...interFourthParagraph,
  fontSize: 16,
  textTransform: 'uppercase',
};

export const interMediumSecondaryParagraph = {
  ...interSecondaryParagraph,
  fontWeight: 400,
  fontSize: 12,
};

export const interMediumFourthParagraph = {
  ...interFourthParagraph,
  fontWeight: 400,
};

export const interBoldFourthParagraph = {
  ...interFourthParagraph,
  fontWeight: 700,
};

export const interUppercaseMediumFourthParagraph = {
  ...interMediumFourthParagraph,
  textTransform: 'uppercase',
};

export const wordBreakAll = {
  wordBreak: 'break-all',
};

export const wordBreakWord = {
  wordBreak: 'break-word',
};

export const primayAnchorTag = {
  ...primaryParagraph,
  color: blue['B700'],
  textDecoration: 'none',
};

export const paddingTopfullPaperLayoutPrimaryOnBorderTop = {
  paddingTop: '2px!important',
  borderTop: '6px solid',
};

export const borderTopPrimaryFullPaperLayoutPrimary = {
  ...paddingTopfullPaperLayoutPrimaryOnBorderTop,
  borderTopColor: blue['B552'],
};

export const borderTopSuccesFullPaperLayoutPrimary = {
  ...paddingTopfullPaperLayoutPrimaryOnBorderTop,
  borderTopColor: success['B478'],
};

export const borderTopWarningFullPaperLayoutPrimary = {
  ...paddingTopfullPaperLayoutPrimaryOnBorderTop,
  borderTopColor: warning['B577'],
};
