import { isEmpty } from 'lodash';

/**
 * transforming dispatcher actions and params to executable promise
 * @param { object } param0
 * @returns
 */
function transform2executablePromise({
  dispatchers = [],
  actions = [],
  params = [],
  isSequential = true,
} = {}) {
  if (!Array.isArray(dispatchers) || !Array.isArray(actions) || !Array.isArray(params)) return [];

  let useDispatcher = true;

  if (isEmpty(dispatchers)) useDispatcher = false;

  const promiseFuncTarget = useDispatcher ? dispatchers : actions;

  return promiseFuncTarget.reduce((result, currPromiseFunc, index) => {
    const actionTarget = actions[index];

    if (actionTarget === undefined) return result;

    const dispatcherTarget = dispatchers[index];
    const paramsTarget = params[index];

    return [
      ...result,
      isSequential
        ? () => {
            if (!useDispatcher) {
              return currPromiseFunc(paramsTarget);
            }

            return dispatcherTarget(actionTarget(paramsTarget));
          }
        : new Promise((resolve) => {
            if (!useDispatcher) {
              return resolve(currPromiseFunc(paramsTarget));
            }

            return resolve(dispatcherTarget(actionTarget(paramsTarget)));
          }),
    ];
  }, []);
}

/**
 * handle parallel promises
 *
 * @param { array } promises
 * @returns
 */
async function parallelRunnerPromises(promises = []) {
  if (!Array.isArray(promises) || isEmpty(promises)) return;

  return await Promise.all(promises.map((promise) => promise()));
}

/**
 * handle sequential promises
 *
 * @param { array } promises
 * @returns
 */
async function sequentialRunnerPromises(promises = []) {
  if (!Array.isArray(promises) || isEmpty(promises)) return;

  return await promises.reduce((res, promise) => res.then(() => promise()), Promise.resolve(null));
}

export const promiseExecutionHelper = {
  transform2executablePromise,
  parallelRunnerPromises,
  sequentialRunnerPromises,
};
