export const styles = () => {
  return {
    inputAdornmentClearSingleFilter: {
      width: '30px',
    },
    iconButtonContainerClear: {
      right: '20px',
      top: '9px',
      padding: '1px',
      borderRadius: '50%',
      position: 'absolute',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      '& svg': {
        fill: 'white',
        width: '12px',
        height: '12px',
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  };
};
