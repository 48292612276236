import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { MULTIPLE_BASE_TYPES_ACCOUNT_SLICE } from '../../utils/constants/action-type.constant';
import {
  LOCAL_STORAGE_ACCOUNT_CATEGORY,
  LOCAL_STORAGE_ACCOUNT_CATEGORY_PRINT,
  LOCAL_STORAGE_ACCOUNT_DATA,
  LOCAL_STORAGE_ACCOUNT_DATA_ALTERNATIVE,
  LOCAL_STORAGE_ACCOUNT_DATA_PRINT,
  LOCAL_STORAGE_ACCOUNT_DETAILS,
  LOCAL_STORAGE_ACCOUNT_PARENT,
  LOCAL_STORAGE_ACCOUNT_SUB,
  LOCAL_STORAGE_ACCOUNT_TRANSACTION,
} from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  SLICE_NAME_ACCOUNT_CATEGORY_PRINT,
  SLICE_NAME_ACCOUNT_DETAILS,
  SLICE_NAME_ACCOUNT_PRINT_DATA,
  SLICE_NAME_ACCOUNT_TRANSACTION,
  STATUS_REQUEST_ADD_ACCOUNT_CATEGORY_FAILED,
  STATUS_REQUEST_ADD_ACCOUNT_CATEGORY_PENDING,
  STATUS_REQUEST_ADD_ACCOUNT_CATEGORY_SUCCESS,
  STATUS_REQUEST_ADD_ACCOUNT_DATA_FAILED,
  STATUS_REQUEST_ADD_ACCOUNT_DATA_PENDING,
  STATUS_REQUEST_ADD_ACCOUNT_DATA_SUCCESS,
  STATUS_REQUEST_ADD_SUB_ACCOUNT_FAILED,
  STATUS_REQUEST_ADD_SUB_ACCOUNT_PENDING,
  STATUS_REQUEST_ADD_SUB_ACCOUNT_SUCCESS,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_DELETE_ACCOUNT_CATEGORY_FAILED,
  STATUS_REQUEST_DELETE_ACCOUNT_CATEGORY_PENDING,
  STATUS_REQUEST_DELETE_ACCOUNT_CATEGORY_SUCCESS,
  STATUS_REQUEST_DELETE_ACCOUNT_DATA_FAILED,
  STATUS_REQUEST_DELETE_ACCOUNT_DATA_PENDING,
  STATUS_REQUEST_DELETE_ACCOUNT_DATA_SUCCESS,
  STATUS_REQUEST_DETAILS_ACCOUNT_DATA_FAILED,
  STATUS_REQUEST_DETAILS_ACCOUNT_DATA_PENDING,
  STATUS_REQUEST_DETAILS_ACCOUNT_DATA_SUCCESS,
  STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_FAILED,
  STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_PENDING,
  STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_SUCCESS,
  STATUS_REQUEST_LIST_ACCOUNT_DATA_ALTERNATIVE_FAILED,
  STATUS_REQUEST_LIST_ACCOUNT_DATA_ALTERNATIVE_PENDING,
  STATUS_REQUEST_LIST_ACCOUNT_DATA_ALTERNATIVE_SUCCESS,
  STATUS_REQUEST_LIST_ACCOUNT_DATA_FAILED,
  STATUS_REQUEST_LIST_ACCOUNT_DATA_PENDING,
  STATUS_REQUEST_LIST_ACCOUNT_DATA_SUCCESS,
  STATUS_REQUEST_LIST_ACCOUNT_PARENT_FAILED,
  STATUS_REQUEST_LIST_ACCOUNT_PARENT_PENDING,
  STATUS_REQUEST_LIST_ACCOUNT_PARENT_SUCCESS,
  STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_FAILED,
  STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_PENDING,
  STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_SUCCESS,
  STATUS_REQUEST_UPDATE_ACCOUNT_CATEGORY_FAILED,
  STATUS_REQUEST_UPDATE_ACCOUNT_CATEGORY_PENDING,
  STATUS_REQUEST_UPDATE_ACCOUNT_CATEGORY_SUCCESS,
  STATUS_REQUEST_UPDATE_ACCOUNT_DATA_FAILED,
  STATUS_REQUEST_UPDATE_ACCOUNT_DATA_PENDING,
  STATUS_REQUEST_UPDATE_ACCOUNT_DATA_SUCCESS,
} from '../../utils/constants/store.constant';
import { accountAsyncThunkTypeWithActionProps } from '../../utils/data/mapping/async-thunk-type-with-action-props.mapping';
import { DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL } from '../../utils/default/object-keyname.default';
import { DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS } from '../../utils/default/params.default';
import arrHelp from '../../utils/helpers/array.helpers';
import formatHelp from '../../utils/helpers/format.helpers';
import objHelper from '../../utils/helpers/object.helper';
import {
  bodyBuilderFulfilledAction,
  bodyBuilderPendingAction,
  bodyBuilderRejectedAction,
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
  sliceReduceHelper,
} from '../../utils/helpers/slice-reducer.helper';

import {
  accountCategoryList,
  accountCategoryListRefresh,
  addAccount,
  addAccountCategory,
  deleteAccountCategory,
  deleteAccountData,
  getAccountDetails,
  getAccountList,
  getAccountListAlternative,
  getAccountListRefresh,
  getAccountTransactionList,
  getListAccountParent,
  updateAccount,
  updateAccountCategory,
} from './account.action';
import { addSubAccount } from './sub-account.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  // account category
  category: LocalStorage.get(LOCAL_STORAGE_ACCOUNT_CATEGORY),
  [SLICE_NAME_ACCOUNT_CATEGORY_PRINT]: LocalStorage.get(LOCAL_STORAGE_ACCOUNT_CATEGORY_PRINT),
  // account data
  accountData: LocalStorage.get(LOCAL_STORAGE_ACCOUNT_DATA),
  [SLICE_NAME_ACCOUNT_PRINT_DATA]: LocalStorage.get(LOCAL_STORAGE_ACCOUNT_DATA_PRINT),
  // additional account slice
  accountParentData: LocalStorage.get(LOCAL_STORAGE_ACCOUNT_PARENT),
  accountDataAlternative: LocalStorage.get(LOCAL_STORAGE_ACCOUNT_DATA_ALTERNATIVE),
  accountSub: LocalStorage.get(LOCAL_STORAGE_ACCOUNT_SUB),
  [SLICE_NAME_ACCOUNT_TRANSACTION]: LocalStorage.get(LOCAL_STORAGE_ACCOUNT_TRANSACTION),
  [SLICE_NAME_ACCOUNT_DETAILS]: LocalStorage.get(LOCAL_STORAGE_ACCOUNT_DETAILS),
  [DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL]: [],
};

const accountSlice = createSlice({
  name: NAME_STORE.ACCOUNT,
  initialState,
  reducers: {
    getAccountList,
    getAccountListAlternative,
    addAccount,
    updateAccount,
    deleteAccountData,
    accountCategoryList,
    accountCategoryListRefresh,
    addAccountCategory,
    updateAccountCategory,
    deleteAccountCategory,
    getListAccountParent,
    addSubAccount,
    getAccountDetails,
    clearAccountData: (state, action) => {
      LocalStorage.remove(LOCAL_STORAGE_ACCOUNT_DATA);

      if (action && action.payload && action.payload.removeLocalStorageOnly) return state;

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        accountData: null,
      };
    },
    clearAccountCategory: (state, action) => {
      LocalStorage.remove(LOCAL_STORAGE_ACCOUNT_CATEGORY);

      if (action && action.payload && action.payload.removeLocalStorageOnly) return state;

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        category: null,
      };
    },
    clearAccountParent: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_ACCOUNT_PARENT);

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        accountParentData: null,
      };
    },
    clearDataAccountSlice: (state, action) => {
      const { localStorageName, sliceName } = action.payload;

      if (localStorageName && LocalStorage.get(localStorageName)) {
        LocalStorage.remove(localStorageName);
      }

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        [sliceName]: null,
      };
    },
    clearAccountSliceItem: (state, action) => {
      sliceReduceHelper.clearSliceItem({
        state,
        action,
      });
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      category: LOCAL_STORAGE_ACCOUNT_CATEGORY,
      [SLICE_NAME_ACCOUNT_CATEGORY_PRINT]: LOCAL_STORAGE_ACCOUNT_CATEGORY_PRINT,
      accountData: LOCAL_STORAGE_ACCOUNT_DATA,
      [SLICE_NAME_ACCOUNT_PRINT_DATA]: LOCAL_STORAGE_ACCOUNT_DATA_PRINT,
      accountParentData: LOCAL_STORAGE_ACCOUNT_PARENT,
      accountDataAlternative: LOCAL_STORAGE_ACCOUNT_DATA_ALTERNATIVE,
      accountSub: LOCAL_STORAGE_ACCOUNT_SUB,
      [SLICE_NAME_ACCOUNT_TRANSACTION]: LOCAL_STORAGE_ACCOUNT_TRANSACTION,
      [SLICE_NAME_ACCOUNT_DETAILS]: LOCAL_STORAGE_ACCOUNT_DETAILS,
    }),
  },
  extraReducers: (builder) => {
    // account list alternative
    builder.addCase(getAccountListAlternative.fulfilled, (state, action) => {
      state.statusRequest = STATUS_REQUEST_LIST_ACCOUNT_DATA_ALTERNATIVE_SUCCESS;

      // if( !isEmpty( action.payload.accountDataAlternative )) {
      //     action.payload.accountDataAlternative = objHelper.changeFormatValueV2(
      //         action.payload.accountDataAlternative,
      //         DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
      //         null,
      //         formatHelp.changeFormatAccountName,
      //     )
      // }

      state.accountDataAlternative = action.payload.accountDataAlternative;
    }),
      builder.addCase(getAccountListAlternative.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_ACCOUNT_DATA_ALTERNATIVE_FAILED;
      }),
      builder.addCase(getAccountListAlternative.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_ACCOUNT_DATA_ALTERNATIVE_PENDING;
      }),
      builder.addCase(getAccountListRefresh.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_ACCOUNT_DATA_SUCCESS;

        // if( !isEmpty( action.payload.accountData )) {
        //     action.payload.accountData = objHelper.changeFormatValueV2(
        //         action.payload.accountData,
        //         DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
        //         null,
        //         formatHelp.changeFormatAccountName,
        //     )
        // }

        state.accountData = action.payload.accountData;
      }),
      builder.addCase(getAccountListRefresh.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_ACCOUNT_DATA_FAILED;
      }),
      builder.addCase(getAccountListRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_ACCOUNT_DATA_PENDING;
      }),
      // account details
      builder.addCase(getAccountDetails.fulfilled, (state, action) => {
        const { accountDetails } = action.payload;

        state.statusRequest = STATUS_REQUEST_DETAILS_ACCOUNT_DATA_SUCCESS;

        state[DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL] = arrHelp.removeItemsAndSubtitue({
          arr: state[DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL],
          itemsRemove: [
            STATUS_REQUEST_DETAILS_ACCOUNT_DATA_FAILED,
            STATUS_REQUEST_DETAILS_ACCOUNT_DATA_PENDING,
          ],
          itemsSubtitue: STATUS_REQUEST_DETAILS_ACCOUNT_DATA_SUCCESS,
        });

        state[SLICE_NAME_ACCOUNT_DETAILS] = accountDetails;
      }),
      builder.addCase(getAccountDetails.rejected, (state) => {
        state[DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL] = arrHelp.removeItemsAndSubtitue({
          arr: state[DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL],
          itemsRemove: [
            STATUS_REQUEST_DETAILS_ACCOUNT_DATA_SUCCESS,
            STATUS_REQUEST_DETAILS_ACCOUNT_DATA_PENDING,
          ],
          itemsSubtitue: STATUS_REQUEST_DETAILS_ACCOUNT_DATA_FAILED,
        });

        state.statusRequest = STATUS_REQUEST_DETAILS_ACCOUNT_DATA_FAILED;
      }),
      builder.addCase(getAccountDetails.pending, (state) => {
        state[DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL] = arrHelp.removeItemsAndSubtitue({
          arr: state[DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL],
          itemsRemove: [
            STATUS_REQUEST_DETAILS_ACCOUNT_DATA_SUCCESS,
            STATUS_REQUEST_DETAILS_ACCOUNT_DATA_FAILED,
          ],
          itemsSubtitue: STATUS_REQUEST_DETAILS_ACCOUNT_DATA_PENDING,
        });

        state.statusRequest = STATUS_REQUEST_DETAILS_ACCOUNT_DATA_PENDING;
      }),
      builder.addCase(addAccount.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_ACCOUNT_DATA_SUCCESS;
      }),
      builder.addCase(addAccount.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_ACCOUNT_DATA_FAILED;
      }),
      builder.addCase(addAccount.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_ACCOUNT_DATA_PENDING;
      }),
      builder.addCase(updateAccount.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_ACCOUNT_DATA_SUCCESS;
      }),
      builder.addCase(updateAccount.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_ACCOUNT_DATA_FAILED;
      }),
      builder.addCase(updateAccount.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_ACCOUNT_DATA_PENDING;
      }),
      builder.addCase(deleteAccountData.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_ACCOUNT_DATA_SUCCESS;
      }),
      builder.addCase(deleteAccountData.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_ACCOUNT_DATA_FAILED;
      }),
      builder.addCase(deleteAccountData.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_ACCOUNT_DATA_PENDING;
      }),
      // account category list refresh
      builder.addCase(accountCategoryListRefresh.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_SUCCESS;

        // if( !isEmpty( action.payload.category )) {
        //     action.payload.category = objHelper.changeFormatValueV2(
        //         action.payload.category,
        //         DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
        //         null,
        //         formatHelp.changeFormatAccountName,
        //     )
        // }

        state.category = action.payload.category;
      }),
      builder.addCase(accountCategoryListRefresh.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_FAILED;
      }),
      builder.addCase(accountCategoryListRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_PENDING;
      }),
      builder.addCase(addAccountCategory.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_ACCOUNT_CATEGORY_SUCCESS;
      }),
      builder.addCase(addAccountCategory.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_ACCOUNT_CATEGORY_FAILED;
      }),
      builder.addCase(addAccountCategory.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_ACCOUNT_CATEGORY_PENDING;
      }),
      builder.addCase(updateAccountCategory.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_ACCOUNT_CATEGORY_SUCCESS;
      }),
      builder.addCase(updateAccountCategory.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_ACCOUNT_CATEGORY_FAILED;
      }),
      builder.addCase(updateAccountCategory.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_ACCOUNT_CATEGORY_PENDING;
      }),
      builder.addCase(deleteAccountCategory.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_ACCOUNT_CATEGORY_SUCCESS;
      }),
      builder.addCase(deleteAccountCategory.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_ACCOUNT_CATEGORY_FAILED;
      }),
      builder.addCase(deleteAccountCategory.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_ACCOUNT_CATEGORY_PENDING;
      }),
      builder.addCase(getListAccountParent.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_ACCOUNT_PARENT_SUCCESS;

        if (!isEmpty(action.payload.accountParent)) {
          action.payload.accountParent = objHelper.changeFormatValueV2(
            action.payload.accountParent,
            DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
            null,
            formatHelp.changeFormatAccountName,
          );
        }

        state.accountParentData = action.payload.accountParent;
      }),
      builder.addCase(getListAccountParent.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_ACCOUNT_PARENT_FAILED;
      }),
      builder.addCase(getListAccountParent.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_ACCOUNT_PARENT_PENDING;
      }),
      builder.addCase(addSubAccount.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SUB_ACCOUNT_SUCCESS;
      }),
      builder.addCase(addSubAccount.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SUB_ACCOUNT_FAILED;
      }),
      builder.addCase(addSubAccount.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SUB_ACCOUNT_PENDING;
      }),
      builder.addCase(getAccountTransactionList.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_SUCCESS;

        state[DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL] = arrHelp.removeItemsAndSubtitue({
          arr: state[DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL],
          itemsRemove: [
            STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_FAILED,
            STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_PENDING,
          ],
          itemsSubtitue: STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_SUCCESS,
        });

        state[SLICE_NAME_ACCOUNT_TRANSACTION] = action.payload[SLICE_NAME_ACCOUNT_TRANSACTION];
      }),
      builder.addCase(getAccountTransactionList.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_FAILED;

        state[DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL] = arrHelp.removeItemsAndSubtitue({
          arr: state[DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL],
          itemsRemove: [
            STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_SUCCESS,
            STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_PENDING,
          ],
          itemsSubtitue: STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_FAILED,
        });
      }),
      builder.addCase(getAccountTransactionList.pending, (state, action) => {
        const { arg } = action.meta;
        state.statusRequest = STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_PENDING;

        state[DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL] = arrHelp.removeItemsAndSubtitue({
          arr: state[DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL],
          itemsRemove: [
            STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_SUCCESS,
            STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_FAILED,
          ],
          itemsSubtitue: STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_PENDING,
        });

        state[SLICE_NAME_ACCOUNT_TRANSACTION] = {
          data: [],
          ...objHelper.changeSuffixKey(arg, 'search_', false),
        };
      }),
      // enhancement for matching request status action on account and account category
      // current: only include list
      builder.addMatcher(
        (action) =>
          isFulfilledAction({ action, multipleBaseTypes: MULTIPLE_BASE_TYPES_ACCOUNT_SLICE }),
        (state, action) => {
          bodyBuilderFulfilledAction({
            state,
            action,
            useMultipeStateRequestStatus: true,
            mappingAsyncThunkTypeWithActionProps: accountAsyncThunkTypeWithActionProps,
          });
        },
      ),
      builder.addMatcher(
        (action) =>
          isPendingAction({ action, multipleBaseTypes: MULTIPLE_BASE_TYPES_ACCOUNT_SLICE }),
        (state, action) => {
          bodyBuilderPendingAction({
            state,
            action,
            useMultipeStateRequestStatus: true,
            mappingAsyncThunkTypeWithActionProps: accountAsyncThunkTypeWithActionProps,
          });
        },
      ),
      builder.addMatcher(
        (action) =>
          isRejectedAction({ action, multipleBaseTypes: MULTIPLE_BASE_TYPES_ACCOUNT_SLICE }),
        (state, action) => {
          bodyBuilderRejectedAction({
            state,
            action,
            useMultipeStateRequestStatus: true,
            mappingAsyncThunkTypeWithActionProps: accountAsyncThunkTypeWithActionProps,
          });
        },
      );
  },
});

export const { reducer, actions } = accountSlice;

export const {
  clearAccountData,
  clearAccountCategory,
  clearAccountParent,
  clearDataAccountSlice,
  clearAccountSliceItem,
} = actions;

export default reducer;
