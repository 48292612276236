import { DEFAULT_USER_ROLE_OWNER_CAPITALIZE } from '../default/user-roles.default';

import { ACCOUNT_CATEGORY_NAME_KAS_N_BANK } from './account-category-name.default';
import {
  NOTIFICATION_ALL_TYPE,
  NOTIFICATION_INVITATION_TYPE,
  NOTIFICATION_INVITATION_TYPE_API_REF,
} from './notification-type.default';
import {
  DEFAULT_KEY_NAME_IS_SHOW_MESSAGE,
  DEFAULT_KEY_NAME_NOTIFICATION_TYPE,
  DEFAULT_KEY_NAME_NOTIFICATION_TYPE_API_REF,
} from './object-keyname.default';

// params for list company only for owner role
export const additionalParamsListCompanyOnlyForOwnerRoleName = {
  role_name: DEFAULT_USER_ROLE_OWNER_CAPITALIZE,
  isShowMessage: false,
};

// params for all notifications list
export const additionalParamsAllNotificationsList = {
  [DEFAULT_KEY_NAME_NOTIFICATION_TYPE]: NOTIFICATION_ALL_TYPE,
};

// params for invitation notification list
export const additionalParamsInvitationNotificationList = {
  [DEFAULT_KEY_NAME_NOTIFICATION_TYPE_API_REF]: NOTIFICATION_INVITATION_TYPE_API_REF,
  [DEFAULT_KEY_NAME_NOTIFICATION_TYPE]: NOTIFICATION_INVITATION_TYPE,
};

export const additionalParamsUnShowMessage = {
  [DEFAULT_KEY_NAME_IS_SHOW_MESSAGE]: false,
};

export const additionalParamsGetContactSupplier = {
  type: ['is_supplier'],
};

export const additionalParamsNotRewriteAll = {
  isRewriteAll: false,
};

export const additionalParamsGetContactCustomer = {
  type: ['is_customer'],
};

export const additionalParamsAccountCashAndBankCategory = {
  category_name: ACCOUNT_CATEGORY_NAME_KAS_N_BANK,
};
