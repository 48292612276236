import * as React from 'react';
function SvgIconMenuPurchasePurchasePayment(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.857 68.143a.429.429 0 11-.857 0 .429.429 0 01.857 0zM6.857 67.714h58.286a.428.428 0 110 .857H6.857a.428.428 0 110-.857zM2.571 67.714h2.143a.428.428 0 110 .857H2.571a.428.428 0 110-.857zM69.429 67.714h-2.143a.428.428 0 100 .857h2.143a.428.428 0 100-.857zM72 68.143a.429.429 0 11-.857 0 .429.429 0 01.857 0zM39.6 40.428h-8.4V42h8.4v-1.572zM39.6 31.647h-8.38v1.68h8.38v-1.68zM39.6 34.428h-8.38V36h8.38v-1.572zM39.6 37.258h-8.38v1.572h8.38v-1.572z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M53.472 26.251a3.86 3.86 0 00-1.195-2.78 4.16 4.16 0 00-2.885-1.151H23.28a4.16 4.16 0 00-2.885 1.151 3.86 3.86 0 00-1.195 2.78v7.862c0 .209.086.409.239.556.153.148.36.23.577.23h5.712v6.735h1.632V26.25a4.052 4.052 0 00-.816-2.359h22.848c.65 0 1.272.249 1.731.691.46.443.717 1.043.717 1.668V30h1.632v-3.749zM25.728 63.989v-3.734h1.632v3.734c0 .626.258 1.225.717 1.668.143.138.302.257.472.355v1.716a4.11 4.11 0 01-1.626-.96 3.86 3.86 0 01-1.195-2.779zM38.4 67.92h17.52a4.16 4.16 0 002.885-1.151A3.86 3.86 0 0060 63.989v-4.717a.765.765 0 00-.122-.414.805.805 0 00-.33-.29.843.843 0 00-.854.075l-3.867 2.79-1.355-.982v-8.47H51.84v7.29l-.881-.636a.837.837 0 00-.971 0l-3.86 2.799-3.868-2.791a.837.837 0 00-.979 0L38.4 60.726v1.966l3.37-2.438 3.86 2.791a.837.837 0 00.98 0l3.867-2.79 3.86 2.79a.836.836 0 00.98 0l3.051-2.2v3.144c0 .626-.258 1.225-.717 1.668-.46.442-1.082.69-1.731.69H38.4v1.573zM20.832 26.251v7.076h4.896V26.25c0-.626-.258-1.225-.717-1.668a2.496 2.496 0 00-1.731-.69c-.65 0-1.272.248-1.731.69a2.316 2.316 0 00-.717 1.668z'
        fill='#000'
      />
      <path
        d='M16.767 67.68a4.502 4.502 0 01-.825-2.097 4.886 4.886 0 01.25-2.304c.265-.723.698-1.336 1.245-1.767a2.965 2.965 0 011.838-.66c.652 0 1.291.23 1.838.66.547.43.98 1.044 1.245 1.767.266.722.353 1.523.25 2.304a4.502 4.502 0 01-.824 2.097h-2.508c.596 0 1.169-.288 1.59-.8.422-.51.66-1.204.66-1.927 0-.54-.133-1.067-.38-1.515a2.407 2.407 0 00-1.01-1.005 1.887 1.887 0 00-1.3-.155 2.162 2.162 0 00-1.152.746 2.945 2.945 0 00-.616 1.396 3.267 3.267 0 00.128 1.576c.17.498.459.924.828 1.224.37.3.805.46 1.25.46h-2.507zM30.267 67.68a4.503 4.503 0 01-.825-2.097 4.886 4.886 0 01.25-2.304c.265-.723.698-1.336 1.245-1.767a2.965 2.965 0 011.838-.66c.653 0 1.291.23 1.838.66.547.43.98 1.044 1.245 1.767.266.722.353 1.523.25 2.304a4.502 4.502 0 01-.824 2.097h-2.508c.596 0 1.169-.288 1.59-.8.422-.51.66-1.204.66-1.927 0-.54-.133-1.067-.38-1.515a2.407 2.407 0 00-1.01-1.005 1.887 1.887 0 00-1.3-.155 2.162 2.162 0 00-1.151.746c-.315.381-.53.867-.616 1.396a3.268 3.268 0 00.127 1.576c.17.498.459.924.829 1.224.37.3.805.46 1.25.46h-2.508z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.487 37.997a.822.822 0 00-.073.514c.016.088.046.17.089.244a.625.625 0 00.159.183c.062.048.132.08.205.095l4.514.941 6.095 18.26c.127.377.345.7.625.925.28.225.61.344.947.34h21.665c.149 0 .292-.073.398-.2a.761.761 0 00.164-.483c0-.18-.059-.354-.164-.482a.518.518 0 00-.398-.2H15.048a.504.504 0 01-.317-.11.677.677 0 01-.215-.302l-.461-1.388 21.294-.981c.245-.012.48-.12.67-.308a1.43 1.43 0 00.382-.736l1.968-9.831c.04-.2.041-.41.005-.611a1.508 1.508 0 00-.216-.552 1.2 1.2 0 00-.392-.383.97.97 0 00-.49-.139H9.535l-1.23-3.681a.693.693 0 00-.17-.275.528.528 0 00-.257-.141L3.058 37.69a.464.464 0 00-.22.007.519.519 0 00-.2.107.644.644 0 00-.151.193zm8.412 8.887l.456 1.364h25.102l.273-1.364H10.9zm-.908-2.727l.453 1.364h26.558l.273-1.364H9.991zm3.614 10.834l-1.794-5.38h24.373l-.878 4.378-21.701 1.002z'
        fill='#000'
      />
      <path
        d='M61.333 43.754c-.435 0-.86.13-1.222.373s-.644.59-.81.994a2.23 2.23 0 00.477 2.414 2.186 2.186 0 002.397.48c.402-.167.746-.45.988-.815a2.227 2.227 0 00-.274-2.797 2.192 2.192 0 00-1.556-.65zm0 2.954a.73.73 0 01-.677-.456.743.743 0 01.534-1.007.729.729 0 01.753.314.743.743 0 01-.091.932.731.731 0 01-.519.217z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M63.533 50.4c.973 0 1.905-.39 2.593-1.081a3.705 3.705 0 001.074-2.611V34.892c0-.979-.386-1.918-1.074-2.61a3.654 3.654 0 00-2.593-1.082H44.467c-.973 0-1.905.389-2.593 1.081a3.706 3.706 0 00-1.074 2.611v11.816c0 .979.386 1.918 1.074 2.61a3.654 3.654 0 002.593 1.082h19.066zm2.2-13.292v-2.216c0-.587-.232-1.15-.644-1.566a2.192 2.192 0 00-1.556-.65H44.467c-.584 0-1.143.234-1.556.65a2.223 2.223 0 00-.644 1.566v2.216h23.466zM42.267 40.8h23.466v-2.215H42.267V40.8zm2.2 8.123h19.066c.584 0 1.143-.234 1.556-.65.412-.415.644-.978.644-1.565v-4.431H42.267v4.43c0 .588.231 1.151.644 1.567.413.415.972.65 1.556.65z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M60.111 44.127a2.189 2.189 0 012.778.276 2.223 2.223 0 01-.714 3.613 2.186 2.186 0 01-2.397-.48 2.22 2.22 0 01-.477-2.415c.166-.405.448-.75.81-.994zm.815 2.456a.73.73 0 00.926-.092.74.74 0 00-.238-1.204.729.729 0 00-.8.16.74.74 0 00.112 1.136z'
        fill='#000'
      />
    </svg>
  );
}
export default SvgIconMenuPurchasePurchasePayment;
