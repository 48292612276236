export const DEFAULT_BUTTON_NAME_CREATE_SALES_QUOTATION = ' create-sales-quotation';
export const DEFAULT_BUTTON_NAME_CREATE_SALES_ORDER = 'create-sales-order';
export const DEFAULT_BUTTON_NAME_CREATE_SALES_DELIVERY = 'create-sales-delivery';
export const DEFAULT_BUTTON_NAME_CREATE_SALES_INVOICE = 'create-sales-invoice';
export const DEFAULT_BUTTON_NAME_CREATE_SALES_PAYMENT = 'create-sales-payment';

export const DEFAULT_BUTTON_NAME_CREATE_PURCHASE_QUOTATION_REQUEST =
  ' create-purchase-quotation-request';
export const DEFAULT_BUTTON_NAME_CREATE_PURCHASE_ORDER = 'create-purchase-order';
export const DEFAULT_BUTTON_NAME_CREATE_PURCHASE_DELIVERY = 'create-purchase-delivery';
export const DEFAULT_BUTTON_NAME_CREATE_PURCHASE_INVOICE = 'create-purchase-invoice';
export const DEFAULT_BUTTON_NAME_CREATE_PURCHASE_PAYMENT = 'create-purchase-payment';

export const DEFAULT_BUTTON_NAME_VIEW_HISTORY = 'view-history';
export const DEFAULT_BUTTON_NAME_VIEW_JOURNAL = 'view-journal';

export const DEFAULT_BUTTON_NAME_ACTION_PAY = 'action-pay';

export const DEFAULT_BUTTON_NAME_PRINT_PDF = 'print-pdf';
export const DEFAULT_BUTTON_NAME_PRINT_EXCEL = 'print-excel';
