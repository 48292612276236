// see documentation
// https://cryptojs.gitbook.io/docs/#ciphers
// https://github.com/brix/crypto-js

import CryptoJS from 'crypto-js';

import { REACT_APP_SECRET_KEY_ENCRYPT } from '../../../utils/configs/env.config';
import LocalStorage from '../../modules/LocalStorage/LocalStorage.service';

export const encryptData = (data) => {
  let encryptData = null;

  // encrypt data with secret key
  if (typeof data === 'string') {
    encryptData = CryptoJS.AES.encrypt(data, REACT_APP_SECRET_KEY_ENCRYPT);
  }

  // convert into string when data is not string
  else {
    encryptData = CryptoJS.AES.encrypt(JSON.stringify(data), REACT_APP_SECRET_KEY_ENCRYPT);
  }

  return encryptData.toString();
};

export const decryptData = (cipherText, isRemoveLocalStorageWhenFailed = true) => {
  let bytesData = CryptoJS.AES.decrypt(cipherText, REACT_APP_SECRET_KEY_ENCRYPT);

  let decryptData = null;

  try {
    // decrypt data based utf-8
    decryptData = bytesData.toString(CryptoJS.enc.Utf8);

    // original data is object, return with object
    if (decryptData.startsWith('{') && decryptData.endsWith('}')) {
      return JSON.parse(decryptData);
    }

    return decryptData;
  } catch (error) {
    if (!isRemoveLocalStorageWhenFailed) {
      return null;
    }

    // removing all data in localStorage when user is inputting data for keys of localStorage
    LocalStorage.clear();

    // and force reloading window
    if (window.location) {
      setTimeout(() => {
        return window.location.reload();
      }, 10000);
    }
  }

  return decryptData;
};
