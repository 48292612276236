import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import managementUsersService from '../../services/API/management-user/management-user.service';
import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { ROLE_NAME_OWNER } from '../../utils/constants/role.user';
import {
  LOCAL_STORAGE_MANAGEMENT_USERS,
  LOCAL_STORAGE_MANAGEMENT_USERS_DETAILS,
  LOCAL_STORAGE_MANAGEMENT_USERS_FOR_AUTOCOMPLETE,
} from '../../utils/constants/storage.constant';
import {
  SLICE_NAME_MANAGEMENT_USER_DETAILS,
  SLICE_NAME_MANAGEMENT_USERS_FOR_AUTOCOMPLETE,
} from '../../utils/constants/store.constant';
import { DEFAULT_MANAGEMENT_USER_SEARCH_FE_PARAMS } from '../../utils/default/params.default';
import objHelper from '../../utils/helpers/object.helper';
import {
  creatorAddAction,
  creatorDetailAction,
  creatorListActionWithDynamicSliceName,
  creatorListRefreshAction,
} from '../creator-action/creator-action.helper';
import messageHelper from '../message/message.helper';

export const listUsers = createAsyncThunk(
  'management-user/list',
  async (
    { page, paginate, user_fullname, email, role, company_id, isRewriteAll = true },
    thunkAPI,
  ) => {
    try {
      const response = await managementUsersService.listUsers({
        page,
        paginate,
        user_fullname,
        email,
        role,
        company_id,
      });

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      // response data
      let { data: freshDataResponse, sucess } = response.data;

      // returning always data object in store and provide key data
      // later for handling search cache key
      if (!sucess || !Array.isArray(freshDataResponse.data)) {
        freshDataResponse = {
          ...freshDataResponse,
          data: [],
        };
      }

      // adding data for search parameter
      if (user_fullname) {
        freshDataResponse.search_user_fullname = user_fullname;
      }

      if (company_id) {
        freshDataResponse.search_company_id = company_id;
      }

      if (email) {
        freshDataResponse.search_email = email;
      }

      if (role) {
        if (!isEmpty(role)) {
          freshDataResponse.search_role = role;
        }
      }

      // current data that store in local storage
      const currentDataManagementUsers = LocalStorage.get(LOCAL_STORAGE_MANAGEMENT_USERS);

      if (!currentDataManagementUsers || isRewriteAll) {
        LocalStorage.set(LOCAL_STORAGE_MANAGEMENT_USERS, freshDataResponse);

        return { users: freshDataResponse };
      }

      // actualy not new data list user
      let newDataListUsers = currentDataManagementUsers.data;

      // get first data user id, that represent user id contained on response data
      let firstUserIDDataList = null;

      if (freshDataResponse.data) {
        firstUserIDDataList = freshDataResponse.data[0].user_id;
      }

      // and check that user id in include on current data list user (old data)
      const isNewDataContainFirstCompanyID = newDataListUsers
        .map((fDataRes) => fDataRes.user_id)
        .includes(firstUserIDDataList);

      if (!isNewDataContainFirstCompanyID) {
        // adding on first index list, when page is lower than last current page
        if (currentDataManagementUsers.current_page > page) {
          newDataListUsers = freshDataResponse.data.concat(newDataListUsers);
        } else {
          newDataListUsers = newDataListUsers.concat(freshDataResponse.data);
        }
      }

      // rewrite data that not user list data
      let newDataUsers = Object.assign(currentDataManagementUsers, freshDataResponse, {
        data: newDataListUsers,
      });

      LocalStorage.set(LOCAL_STORAGE_MANAGEMENT_USERS, newDataUsers);

      return { users: newDataUsers };
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }
      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.management-user',
          'error.list-data.management-user',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.list-data.management-user',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// get refresh data from current search list user
export const refreshListUsers = createAsyncThunk(
  'management-user/list-refresh',
  async (dataIgnoreRefresh = [], thunkAPI) => {
    const currentDataManagementUsers = LocalStorage.get(LOCAL_STORAGE_MANAGEMENT_USERS);

    // get filtering data from prev search
    let filterDataSearch = objHelper.filterKeyObj(currentDataManagementUsers, dataIgnoreRefresh, [
      'search_role',
      'search_email',
      'search_user_fullname',
      'current_page',
      'per_page',
      'search_company_id',
    ]);

    // get data for next search
    const filterDataNextSearch = objHelper.filterKeyObj(filterDataSearch, [
      'per_page',
      'current_page',
    ]);

    // formalize data for search in service
    filterDataSearch = {
      ...filterDataSearch,
      page: filterDataSearch.current_page,
      paginate: filterDataSearch.per_page,
      email: filterDataSearch.search_email,
      company_id: filterDataSearch.search_company_id,
      role: filterDataSearch.search_role,
      user_fullname: filterDataSearch.search_user_fullname,
    };

    // get data that existed on value object
    filterDataSearch = objHelper.filteringExistedValue(filterDataSearch);

    // removing unused key this using for search data
    filterDataSearch = objHelper.filterKeyObj(filterDataSearch, [
      'current_page',
      'search_role',
      'search_email',
      'search_user_fullname',
      'search_company_id',
    ]);

    try {
      const response = await managementUsersService.listUsers(filterDataSearch);

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      let { data: dataResponseManagementUser, sucess } = response.data;

      // returning always object and provide data key in store
      // and later adding search key
      if (!sucess || !Array.isArray(dataResponseManagementUser.data)) {
        dataResponseManagementUser = {
          ...dataResponseManagementUser,
          data: [],
        };
      }

      // adding data for caching search
      const dataResWithSearch = Object.assign(dataResponseManagementUser, {
        ...filterDataNextSearch,
      });

      // changing data management users to new list refresh data
      LocalStorage.set(LOCAL_STORAGE_MANAGEMENT_USERS, dataResWithSearch);

      return { users: dataResWithSearch };
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }
      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.management-user-data',
          'error.list-data.management-user-data',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.list-data.management-user-data',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// getting user list with returing dynamic store
// passing storeName for where data for store key on slice and paramsGetUserList
export const getUsersListWithDynamicStore = createAsyncThunk(
  'management-user/list-dynamic-store',
  async (
    {
      sliceName = SLICE_NAME_MANAGEMENT_USERS_FOR_AUTOCOMPLETE,
      localStorageName = LOCAL_STORAGE_MANAGEMENT_USERS_FOR_AUTOCOMPLETE,
      paramsGetUsersList,
    },
    thunkAPI,
  ) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      paramsGetUsersList,
      managementUsersService.getUsersList,
      localStorageName,
      sliceName,
      'user_id',
      'error.list-data.management-user',
    );
  },
);

// getting user list with returing dynamic store
// passing localStorageName for where data store on localstorage and discardKeySearch
export const getUsersListRefreshWithDynamicStore = createAsyncThunk(
  'management-user/list-refresh-with-dynamic-store',
  async (
    {
      sliceName = SLICE_NAME_MANAGEMENT_USERS_FOR_AUTOCOMPLETE,
      localStorageName = LOCAL_STORAGE_MANAGEMENT_USERS_FOR_AUTOCOMPLETE,
      ignoreKeysRefresh = [],
    },
    thunkAPI,
  ) => {
    return await creatorListRefreshAction(
      thunkAPI,
      managementUsersService.getUsersList,
      localStorageName,
      sliceName,
      ignoreKeysRefresh,
      DEFAULT_MANAGEMENT_USER_SEARCH_FE_PARAMS,
    );
  },
);

export const addUserCompany = createAsyncThunk(
  'management-user/add',
  async (dataUserCompanyAdd, thunkAPI) => {
    try {
      const response = await managementUsersService.addUserCompany(dataUserCompanyAdd);

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.add.management-user',
          'success.add.management-user',
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.add.management-user',
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.add.management-user',
          'error.add.management-user',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.add.management-user',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// update user with id in company not profile for self
export const updateUserWithID = createAsyncThunk(
  'management-user/update',
  async (dataForUpdate, thunkAPI) => {
    try {
      const response = await managementUsersService.updateUserWithID(dataForUpdate);

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      // get data user fullname from response data
      const { data: responseUserDetailUpdate } = data;

      const { user_fullname } = responseUserDetailUpdate;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.update.management-user',
          'success.update.management-user',
          {},
          { user_fullname },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.update.management-user',
          {},
          { user_fullname },
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      const { response } = error;

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.update.management-user',
          'error.update.management-user',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.update.management-user',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// check formality owner delete
export const deleteUser = createAsyncThunk(
  'management-user/delete',
  async (dataUserDeleted, thunkAPI) => {
    try {
      const { user_id, user_role, user_fullname } = dataUserDeleted;

      // checking user is owner or not
      const roleNameUserDelete = user_role.map((usr_role) => usr_role.role_name);
      const isOwnerUser = roleNameUserDelete.includes(ROLE_NAME_OWNER);

      if (isOwnerUser) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.delete.user-owner',
          'error.delete.user-owner',
          true,
        );

        return thunkAPI.rejectWithValue();
      }

      return thunkAPI.dispatch(
        deleteUserWithID({
          user_id,
          user_fullname,
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
);

export const deleteUserWithID = createAsyncThunk(
  'management-user/delete',
  async ({ user_id, user_fullname, company_id }, thunkAPI) => {
    try {
      const response = await managementUsersService.deleteUserWithID({ user_id, company_id });

      if (isEmpty(response) || isEmpty(response.data)) {
        return thunkAPI.rejectWithValue(response);
      }

      const { data, sucess } = response.data;

      if (!sucess) throw { response };

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.delete.management-user',
          'success.delete.management-user',
          {},
          { user_fullname },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.delete.management-user',
          {},
          { user_fullname },
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      const { response } = error;

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      // get message response from server
      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.delete.management-user',
          'error.delete.management-user',
          false,
          {},
          { user_fullname },
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.delete.management-user',
          false,
          {},
          { user_fullname },
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// action for add role into user
export const assignmentRoleToUser = createAsyncThunk(
  'management-user/assignment-role',
  async (bodyAssignmentRoleToUser, thunkAPI) => {
    return await creatorAddAction(
      thunkAPI,
      bodyAssignmentRoleToUser,
      managementUsersService.assignmentRoleToUser,
      'success.add.assignment-role-user',
      'error.add.assignment-role-user',
    );
  },
);

// action approval management invite user
export const managementApprovalInviteUser = createAsyncThunk(
  'management-invite/approval-invitation-user',
  async (bodyManagementApprovalInvitationUser, thunkAPI) => {
    return await creatorAddAction(
      thunkAPI,
      bodyManagementApprovalInvitationUser,
      managementUsersService.managementApprovalInviteUser,
      '',
      '',
      {},
      {},
      false,
      true,
    );
  },
);

// action for management user invitation
export const managementInviteUser = createAsyncThunk(
  'management-invite/user',
  async (bodyManagementInviteUser, thunkAPI) => {
    return await creatorAddAction(
      thunkAPI,
      bodyManagementInviteUser,
      managementUsersService.managementInviteUser,
      'success.invite-user',
      'error.invite-user',
    );
  },
);

// action get management user details
export const getManagementUserDetails = createAsyncThunk(
  'management-user/details',
  async (paramsGetManagementUserDetails, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      paramsGetManagementUserDetails,
      managementUsersService.getManagementUserDetails,
      LOCAL_STORAGE_MANAGEMENT_USERS_DETAILS,
      SLICE_NAME_MANAGEMENT_USER_DETAILS,
      'error.detail.management-user',
    );
  },
);
