import * as React from 'react';
function SvgShip(props) {
  return (
    <svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fill='#fff' fillOpacity={0.01} d='M0 0h24v24H0z' />
      <path
        d='M6 12h8v-2H6v2zM4 8.99C4 8.445 4.456 8 5.002 8h9.996C15.55 8 16 8.451 16 8.99V14H4V8.99z'
        fill='#42526E'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 7.005C6 5.898 6.898 5 7.998 5h2.004C11.106 5 12 5.894 12 7.005V10H6V7.005zm4 0V7H7.999c.005 0 .002.003.002.005V8h2v-.995H10z'
        fill='#42526E'
      />
      <path
        d='M4.5 17h13.994l1.002-3H4.14l.36 3zm-2.495-4.012A.862.862 0 012.883 12h18.393c.55 0 .857.417.681.944l-1.707 5.112c-.174.521-.758.944-1.315.944H3.725a1.15 1.15 0 01-1.118-.988l-.602-5.024z'
        fill='#42526E'
      />
    </svg>
  );
}
export default SvgShip;
