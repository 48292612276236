import { defaultStaticRanges } from 'react-date-range';
import { isEqual } from 'lodash';
import moment from 'moment';

import arrHelp from '../../../utils/helpers/array.helpers';

export const DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MOBILE = 'mobile';
export const DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MINI_DEVICE = 'mini-device';
export const DEFAULT_DISPLAY_STATIC_WRAPPER_AS_DESKTOP = 'desktop';

export const DEFAULT_LABEL_NAME_THIS_MONTH_ID = 'Bulan ini';
export const DEFAULT_LABEL_NAME_THIS_MONTH_EN = 'This month';

export const DEFAULT_LABEL_NAME_THIS_MONTH = [
  DEFAULT_LABEL_NAME_THIS_MONTH_EN,
  DEFAULT_LABEL_NAME_THIS_MONTH_ID,
];

export const DEFAULT_LABEL_NAME_CUSTOM_EN = 'Custom';
export const DEFAULT_LABEL_NAME_CUSTOM_ID = 'Sesuaikan';

const labelDefaultStaticRangesLangID = [
  'Hari ini',
  'Kemarin',
  'Minggu ini',
  'Minggu lalu',
  DEFAULT_LABEL_NAME_THIS_MONTH_ID,
  'Bulan lalu',
  'Tahun ini',
  'Tahun lalu',
  'Sepanjang waktu',
];

const labelMobileDefaultStaticRangesID = [
  ...arrHelp.simplifyItem({
    arr: labelDefaultStaticRangesLangID,
    dataMaxLength: 8,
    isFrom: 'start',
  }),
  DEFAULT_LABEL_NAME_CUSTOM_ID,
];

const labelDefaultStaticRangesLangWithoutAllTimeID = [
  ...arrHelp.simplifyItem({
    arr: labelDefaultStaticRangesLangID,
    dataMaxLength: 8,
    isFrom: 'start',
  }),
];

const labelDefaultStaticRangesLangEN = [
  'Today',
  'Yesterday',
  'This week',
  'Last week',
  DEFAULT_LABEL_NAME_THIS_MONTH_EN,
  'Last month',
  'This year',
  'Last year',
  'All time',
];

const labelMobileDefaultStaticRangesEN = [
  ...arrHelp.simplifyItem({
    arr: labelDefaultStaticRangesLangEN,
    dataMaxLength: 8,
    isFrom: 'start',
  }),
  DEFAULT_LABEL_NAME_CUSTOM_EN,
];

const labelDefaultStaticRangesLangWithoutAllTimeEN = [
  ...arrHelp.simplifyItem({
    arr: labelDefaultStaticRangesLangEN,
    dataMaxLength: 8,
    isFrom: 'start',
  }),
];

// getting on range start and end date this month
export const getRangeDateThisMonth = () => {
  return {
    startDate: new Date(moment().startOf('month').toISOString(true)),
    endDate: new Date(moment().endOf('month').toISOString(true)),
  };
};

const thisYearDate = {
  startDate: new Date(moment().startOf('year').toISOString(true)),
  endDate: new Date(moment().endOf('year').toISOString(true)),
};

const lastYearDate = {
  startDate: new Date(moment().subtract(1, 'year').startOf('year').toISOString(true)),
  endDate: new Date(moment().subtract(1, 'year').endOf('year').toISOString(true)),
};

const allTimeDate = {
  startDate: null,
  endDate: null,
};

const customDates = [thisYearDate, lastYearDate, allTimeDate];

const customDefaultStaticRangeEn = labelDefaultStaticRangesLangEN.map((label, index) => {
  if (index < defaultStaticRanges.length) {
    const currSelectionDateRange = defaultStaticRanges[index].range();

    return {
      ...defaultStaticRanges[index],
      range: () => ({ ...currSelectionDateRange, label }),
      label,
    };
  }

  // adding new item index
  const newIndex = index - defaultStaticRanges.length;

  return {
    label,
    range: () => ({ ...customDates[newIndex], label }),
    isSelected(value) {
      const { startDate, endDate } = value;

      if (newIndex === 2 && (!startDate || !endDate)) {
        return true;
      }

      return (
        isEqual(startDate, customDates[newIndex].startDate) &&
        isEqual(endDate, customDates[newIndex].endDate)
      );
    },
  };
});

const customDefaultStaticRangeID = customDefaultStaticRangeEn.map((staticRange, index) => {
  const currSelectionDateRange = staticRange.range();

  return {
    ...staticRange,
    range: () => ({ ...currSelectionDateRange, label: labelDefaultStaticRangesLangID[index] }),
    label: labelDefaultStaticRangesLangID[index],
  };
});

const customMobileDefaultStaticRangeEN = customDefaultStaticRangeEn.map(
  (singleCustomDefault, index) => {
    const currSelectionDateRange = singleCustomDefault.range();

    if (index !== customDefaultStaticRangeEn.length - 1) {
      return {
        ...singleCustomDefault,
        range: () => ({ ...currSelectionDateRange, isShowDateRange: false }),
      };
    }

    return {
      label: labelMobileDefaultStaticRangesEN[index],
      range: () => ({
        ...currSelectionDateRange,
        label: labelMobileDefaultStaticRangesEN[index],
        isShowDateRange: true,
      }),
      isSelected: (value) => {
        const { startDate, endDate } = value;

        if (!startDate || !endDate) return true;

        return false;
      },
    };
  },
);

const customMobileDefaultStaticRangeID = customMobileDefaultStaticRangeEN.map(
  (singleCustomDefault, index) => {
    const currSelectionDateRange = singleCustomDefault.range();
    let isShowDateRange = true;

    if (index !== customDefaultStaticRangeEn.length - 1) {
      isShowDateRange = false;
    }

    return {
      ...singleCustomDefault,
      label: labelMobileDefaultStaticRangesID[index],
      range: () => ({
        ...currSelectionDateRange,
        label: labelMobileDefaultStaticRangesID[index],
        isShowDateRange,
      }),
    };
  },
);

const customDefaultStaticRangeWithoutAllTimeEN = customDefaultStaticRangeEn
  .filter((customDefault) =>
    labelDefaultStaticRangesLangWithoutAllTimeEN.includes(customDefault.label),
  )
  .map((singleCustomDefault, index) => {
    return {
      ...singleCustomDefault,
      label: labelDefaultStaticRangesLangWithoutAllTimeEN[index],
    };
  });

const customDefaultStaticRangeWithoutAllTimeID = customDefaultStaticRangeWithoutAllTimeEN.map(
  (singleCustomDefault, index) => {
    const currSelectionDateRange = singleCustomDefault.range();
    let isShowDateRange = true;

    if (index !== customDefaultStaticRangeWithoutAllTimeEN.length - 1) {
      isShowDateRange = false;
    }

    return {
      ...singleCustomDefault,
      label: labelDefaultStaticRangesLangWithoutAllTimeID[index],
      range: () => ({
        ...currSelectionDateRange,
        label: labelDefaultStaticRangesLangWithoutAllTimeID[index],
        isShowDateRange,
      }),
    };
  },
);

export const customDefaultStaticRange = {
  id: customDefaultStaticRangeID,
  en: customDefaultStaticRangeEn,
};

export const customMobileDefaultStaticRange = {
  id: customMobileDefaultStaticRangeID,
  en: customMobileDefaultStaticRangeEN,
};

export const customDefaultStaticRangeWithoutAllTime = {
  id: customDefaultStaticRangeWithoutAllTimeID,
  en: customDefaultStaticRangeWithoutAllTimeEN,
};
