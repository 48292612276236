/* eslint-disable react/react-in-jsx-scope */
import { cloneDeep, isEmpty, omit, pick } from 'lodash';
import moment from 'moment';

import gray from '../../assets/colors/gray';
import {
  FORMAT_CALCULATION_MANUAL_COLUMN,
  FORMAT_COMMON_COLUMN,
  FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
  FORMAT_CURRENCY_ON_BRACKET,
  FORMAT_DATE_COLUMN,
  FORMAT_MAPPING_TRANSLATION_COLUMN,
  FORMAT_NUMBER_COLUMN,
  FORMAT_TEMPLATE_COLUMN,
} from '../../components/table-row/TableRowPureMarkup/TableRowPureMarkup';
import { getAccountTransactionList } from '../../store/account/account.action';
import { getReportBasicList } from '../../store/report/report.action';
import {
  REACT_APP_API_URL_REPORT_BALANCE_SHEET,
  REACT_APP_API_URL_REPORT_BASIC_PRICE_LIST,
  REACT_APP_API_URL_REPORT_BASIC_PURCHASE,
  REACT_APP_API_URL_REPORT_BASIC_SALES,
  REACT_APP_API_URL_REPORT_BASIC_SALES_EXTRACTION,
  REACT_APP_API_URL_REPORT_BASIC_STOCK,
  REACT_APP_API_URL_REPORT_CAPITAL_CHANGE,
  REACT_APP_API_URL_REPORT_CASH_FLOW,
  REACT_APP_API_URL_REPORT_GENERAL_LEDGER,
  REACT_APP_API_URL_REPORT_INCOME_STATEMENT,
  REACT_APP_API_URL_REPORT_JOURNAL,
  REACT_APP_API_URL_REPORT_STOCK_ADJUSTMENT,
  REACT_APP_API_URL_REPORT_STOCK_CARD,
  REACT_APP_API_URL_REPORT_STOCK_MUTATION,
  REACT_APP_API_URL_REPORT_STOCK_PURCHASE,
  REACT_APP_API_URL_REPORT_STOCK_SALES,
  REACT_APP_API_URL_REPORT_TRIAL_BALANCE,
} from '../configs/api.config';
import { REACT_APP_DATA_STOCK_ADJUSTMENT_DETAILS_TEMPLATE_URL } from '../configs/url.config';
import {
  LOCAL_STORAGE_REPORT_BALANCE_SHEET,
  LOCAL_STORAGE_REPORT_BASIC_PRODUCT_PRICE_LIST,
  LOCAL_STORAGE_REPORT_BASIC_PURCHASE_LIST,
  LOCAL_STORAGE_REPORT_BASIC_SALES_LIST,
  LOCAL_STORAGE_REPORT_BASIC_STOCK_LIST,
  LOCAL_STORAGE_REPORT_CAPITAL_CHANGE,
  LOCAL_STORAGE_REPORT_CASH_FLOW,
  LOCAL_STORAGE_REPORT_GENERAL_LEDGER,
  LOCAL_STORAGE_REPORT_INCOME_STATEMENT,
  LOCAL_STORAGE_REPORT_JOURNAL,
  LOCAL_STORAGE_REPORT_STOCK_ADJUSTMENT_LIST,
  LOCAL_STORAGE_REPORT_STOCK_CARD_LIST,
  LOCAL_STORAGE_REPORT_STOCK_MUTATION_LIST,
  LOCAL_STORAGE_REPORT_STOCK_PURCHASE_LIST,
  LOCAL_STORAGE_REPORT_STOCK_SALES_LIST,
  LOCAL_STORAGE_REPORT_TRIAL_BALANCE,
} from '../constants/storage.constant';
import {
  NAME_STORE,
  ROOT_STORE_NAME_ACCOUNT,
  ROOT_STORE_NAME_REPORT,
  SLICE_NAME_ACCOUNT_TRANSACTION,
  SLICE_NAME_REPORT_BALANCE_SHEET,
  SLICE_NAME_REPORT_BASIC_PRODUCT_PRICE_LIST,
  SLICE_NAME_REPORT_BASIC_PURCHASE_LIST,
  SLICE_NAME_REPORT_BASIC_SALES_EXTRACTION_LIST,
  SLICE_NAME_REPORT_BASIC_SALES_LIST,
  SLICE_NAME_REPORT_BASIC_STOCK_LIST,
  SLICE_NAME_REPORT_CAPITAL_CHANGE,
  SLICE_NAME_REPORT_CASH_FLOW,
  SLICE_NAME_REPORT_GENERAL_LEDGER,
  SLICE_NAME_REPORT_INCOME_STATEMENT,
  SLICE_NAME_REPORT_JOURNAL_LIST,
  SLICE_NAME_REPORT_STOCK_ADJUSTMENT_LIST,
  SLICE_NAME_REPORT_STOCK_CARD_LIST,
  SLICE_NAME_REPORT_STOCK_MUTATION_LIST,
  SLICE_NAME_REPORT_STOCK_PURCHASE_LIST,
  SLICE_NAME_REPORT_STOCK_SALES_LIST,
  SLICE_NAME_REPORT_TRIAL_BALANCE,
  SLICE_NAME_SETTINGS_WAREHOUSE_DATA,
  STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_FAILED,
  STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_PENDING,
  STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_BALANCE_SHEET_FAILED,
  STATUS_REQUEST_LIST_REPORT_BALANCE_SHEET_PENDING,
  STATUS_REQUEST_LIST_REPORT_BALANCE_SHEET_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_BASIC_PRICE_LIST_FAILED,
  STATUS_REQUEST_LIST_REPORT_BASIC_PRICE_LIST_PENDING,
  STATUS_REQUEST_LIST_REPORT_BASIC_PRICE_LIST_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_BASIC_PURCHASE_FAILED,
  STATUS_REQUEST_LIST_REPORT_BASIC_PURCHASE_PENDING,
  STATUS_REQUEST_LIST_REPORT_BASIC_PURCHASE_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_BASIC_SALES_EXTRACTION_FAILED,
  STATUS_REQUEST_LIST_REPORT_BASIC_SALES_EXTRACTION_PENDING,
  STATUS_REQUEST_LIST_REPORT_BASIC_SALES_EXTRACTION_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_BASIC_SALES_FAILED,
  STATUS_REQUEST_LIST_REPORT_BASIC_SALES_PENDING,
  STATUS_REQUEST_LIST_REPORT_BASIC_SALES_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_BASIC_STOCK_FAILED,
  STATUS_REQUEST_LIST_REPORT_BASIC_STOCK_PENDING,
  STATUS_REQUEST_LIST_REPORT_BASIC_STOCK_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_CAPITAL_CHANGE_FAILED,
  STATUS_REQUEST_LIST_REPORT_CAPITAL_CHANGE_PENDING,
  STATUS_REQUEST_LIST_REPORT_CAPITAL_CHANGE_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_CASH_FLOW_FAILED,
  STATUS_REQUEST_LIST_REPORT_CASH_FLOW_PENDING,
  STATUS_REQUEST_LIST_REPORT_CASH_FLOW_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_GENERAL_LEDGER_FAILED,
  STATUS_REQUEST_LIST_REPORT_GENERAL_LEDGER_PENDING,
  STATUS_REQUEST_LIST_REPORT_GENERAL_LEDGER_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_INCOME_STATEMENT_FAILED,
  STATUS_REQUEST_LIST_REPORT_INCOME_STATEMENT_PENDING,
  STATUS_REQUEST_LIST_REPORT_INCOME_STATEMENT_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_JOURNAL_FAILED,
  STATUS_REQUEST_LIST_REPORT_JOURNAL_PENDING,
  STATUS_REQUEST_LIST_REPORT_JOURNAL_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_STOCK_ADJUSTMENT_FAILED,
  STATUS_REQUEST_LIST_REPORT_STOCK_ADJUSTMENT_PENDING,
  STATUS_REQUEST_LIST_REPORT_STOCK_ADJUSTMENT_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_STOCK_CARD_FAILED,
  STATUS_REQUEST_LIST_REPORT_STOCK_CARD_PENDING,
  STATUS_REQUEST_LIST_REPORT_STOCK_CARD_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_STOCK_MUTATION_FAILED,
  STATUS_REQUEST_LIST_REPORT_STOCK_MUTATION_PENDING,
  STATUS_REQUEST_LIST_REPORT_STOCK_MUTATION_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_STOCK_PURCHASE_FAILED,
  STATUS_REQUEST_LIST_REPORT_STOCK_PURCHASE_PENDING,
  STATUS_REQUEST_LIST_REPORT_STOCK_PURCHASE_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_STOCK_SALES_FAILED,
  STATUS_REQUEST_LIST_REPORT_STOCK_SALES_PENDING,
  STATUS_REQUEST_LIST_REPORT_STOCK_SALES_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_TRIAL_BALANCE_FAILED,
  STATUS_REQUEST_LIST_REPORT_TRIAL_BALANCE_PENDING,
  STATUS_REQUEST_LIST_REPORT_TRIAL_BALANCE_SUCCESS,
  STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_PENDING,
} from '../constants/store.constant';
import {
  primaryParagraph,
  primaryTableSubHead,
  primayAnchorTag,
  secondaryParagraph,
  tableDataOneColSpan,
  tableDataZeroColSpan,
  tersierParagraph,
} from '../data/display/additional-styles';
import {
  accountCategoryGroupingReportBalanceSheet,
  accountNamesReportBalanceSheet,
  allRowsTableReportBalanceSheet,
  // allRowsTableReportBalanceSheet,
  // rowsTableTotalBalance,
  correspondingAccountCategoryNaccountNameGroupingReportBalanceSheet,
  correspondingPartViewNaccountCategoryNameGroupingReportBalanceSheet,
  correspondTotalAssetAndLiabilityMinusWithAccountCategoryReportBalanceSheet,
  correspondTotalIncomeWithAccountCategoryReportBalanceSheet,
  correspondTotalLiabilityWithAccountCategoryReportBalanceSheet,
  DEFAULT_KEY_NAME_ADDITIONAL_KEY_TOTAL_LIABILITIES_AND_CAPITAL_MINUS,
  rowsTableReportBalanceSheetWithTranslation,
  rowsTableTotalAccountReportBalanceSheet,
  rowsTableTotalBalance,
  rowsTableWithChildren as rowsTableWithChildrenReportBalaceSheet,
  // accountCategoryGroupingReportBalanceSheet,
  // rowsTableTotalAccountReportBalanceSheet,
} from '../data/display/table-list-report-balance-sheet';
import { unusedKeyNameChildrenReportCapitalChangeData } from '../data/display/table-list-report-capital-change';
import {
  allRowsTableReportCashFlow,
  rowsTableReportCashFlowWithTranslation,
  rowsTableTotalPerCashAccount,
} from '../data/display/table-list-report-cash-flow';
import {
  columnsTableReportGeneralLedgerForTableRowPureMarkup,
  correspondKeynameForSubTotalOfGeneralLedgerReport,
  correspondKeynameForTitleOfGeneralLedgerReport,
  tableRowTotalAccountGeneralLedgerReport,
  unusedKeyNamesReportGeneralLedger,
} from '../data/display/table-list-report-general-ledger';
import {
  allRowsTableReportIncomeStatement,
  correspondAPIkeynameWithViewKeyName,
  rowsTableIncomeStatementsTotalCalculation,
  rowsTableProfitTotal,
  rowsTableReportIncomeStatementWithTranslation,
  rowsTableWithChildren,
} from '../data/display/table-list-report-income-statement';
import {
  columnsTableReportJournalForTableRowPureMarkup,
  correspondChangeKeynameJournalEntryToTransaction,
} from '../data/display/table-list-report-journal';
import {
  allRowsTableReportTrialBalance,
  correspondReportTrialBalanceAPIkeynameWithViewKeyName,
  keysDataCalculationReportTrialBalanceV2,
  rowsTableReportTrialBalanceTranslationRowV2,
  totalKeysReportTrialBalanceFromAPIv2,
  // rowsTableReportTrialBalanceTranslationRow,
} from '../data/display/table-list-report-trial-balance';
import { purchaseInvoiceChartBasicStatus } from '../data/label-value/purchase-status';
import { salesInvoiceChartBasicStatus } from '../data/label-value/sales-status';
import { transactionTypeWithTranslation } from '../data/mapping/transaction-type-translation.mapping';
import arrHelp from '../helpers/array.helpers';
import formatHelp from '../helpers/format.helpers';
import formulaHelpers from '../helpers/formula.helpers';
import objHelper from '../helpers/object.helper';
import strHelp from '../helpers/string.helpers';
import { defaultReportXLSXprintConfigurationProps } from '../properties/report-xlsx-print.properties';

import {
  DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
  DEFAULT_KEY_NAME_AMOUNT_REF_API,
  DEFAULT_KEY_NAME_STATUS_REQUEST,
  DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF,
} from './object-keyname.default';
import {
  DEFAULT_ALLOW_PARAMS_REPORT_STOCK_ADJUSTMENT,
  DEFAULT_ALLOW_PARAMS_REPORT_STOCK_CARD,
  DEFAULT_ALLOW_PARAMS_REPORT_STOCK_MUTATION,
  DEFAULT_ALLOW_PARAMS_REPORT_STOCK_PURCHASE,
  DEFAULT_ALLOW_PARAMS_REPORT_STOCK_SALES,
  DEFAULT_REPORT_BALANCE_SHEET_LIST_PARAMS,
  DEFAULT_REPORT_BALANCE_SHEET_SEARCH_FE_PARAMS,
  DEFAULT_REPORT_BASIC_PRODUCT_PRICE_LIST_PARAMS,
  DEFAULT_REPORT_BASIC_PRODUCT_PRICE_LIST_SEARCH_PARAMS,
  DEFAULT_REPORT_BASIC_PURCHASE_LIST_PARAMS,
  DEFAULT_REPORT_BASIC_PURCHASE_LIST_SEARCH_PARAMS,
  DEFAULT_REPORT_BASIC_SALES_EXTRACTION_LIST_PARAMS,
  DEFAULT_REPORT_BASIC_SALES_EXTRACTION_LIST_SEARCH_PARAMS,
  DEFAULT_REPORT_BASIC_SALES_LIST_PARAMS,
  DEFAULT_REPORT_BASIC_SALES_LIST_SEARCH_PARAMS,
  DEFAULT_REPORT_BASIC_STOCK_LIST_PARAMS,
  DEFAULT_REPORT_BASIC_STOCK_LIST_SEARCH_PARAMS,
  DEFAULT_REPORT_CAPITAL_CHANGE_LIST_PARAMS,
  DEFAULT_REPORT_CAPITAL_CHANGE_SEARCH_FE_PARAMS,
  DEFAULT_REPORT_CASH_FLOW_LIST_PARAMS,
  DEFAULT_REPORT_CASH_FLOW_SEARCH_FE_PARAMS,
  DEFAULT_REPORT_GENERAL_LEDGER_LIST_PARAMS,
  DEFAULT_REPORT_GENERAL_LEDGER_SEARCH_FE_PARAMS,
  DEFAULT_REPORT_INCOME_STATEMENT_LIST_PARAMS,
  DEFAULT_REPORT_INCOME_STATEMENT_SEARCH_FE_PARAMS,
  DEFAULT_REPORT_JOURNAL_LIST_PARAMS,
  DEFAULT_REPORT_JOURNAL_SEARCH_FE_PARAMS,
  DEFAULT_REPORT_TRIAL_BALANCE_LIST_PARAMS,
  DEFAULT_REPORT_TRIAL_BALANCE_SEARCH_FE_PARAMS,
  DEFAULT_SEARCH_PARAMS_REPORT_STOCK_ADJUSTMENT,
  DEFAULT_SEARCH_PARAMS_REPORT_STOCK_CARD,
  DEFAULT_SEARCH_PARAMS_REPORT_STOCK_MUTATION,
  DEFAULT_SEARCH_PARAMS_REPORT_STOCK_PURCHASE,
  DEFAULT_SEARCH_PARAMS_REPORT_STOCK_SALES,
} from './params.default';
import { ASC_SORT_TYPE_API_REF, DESC_SORT_TYPE_API_REF } from './sort-type.default';
import worksheetXLSXname from './worksheet-xlsx-name.default';

export const REPORT_BASIC_TYPE_PURCHASE = 'report-basic-purchase';
export const REPORT_BASIC_TYPE_SALES = 'report-basic-sales';
export const REPORT_BASIC_TYPE_STOCK = 'report-basic-stock';
export const REPORT_BASIC_TYPE_PRICE_LIST = 'report-basic-price-list';
export const REPORT_BASIC_TYPE_SALES_EXTRACTION = 'report-basic-sales-extraction';
export const REPORT_TYPE_CASH_FLOW = 'report-cash-flow';
export const REPORT_TYPE_JOURNAL = 'report-journal';
export const REPORT_TYPE_INCOME_STATEMENT = 'report-income-statement';
export const REPORT_TYPE_BALANCE_SHEET = 'report-balance-sheet';
export const REPORT_TYPE_CAPITAL_CHANGE = 'report-capital-change';
export const REPORT_TYPE_GENERAL_LEDGER = 'report-general-ledger';
export const REPORT_TYPE_TRIAL_BALANCE = 'report-trial-balance';
export const REPORT_STOCK_SALES_TYPE = 'report-stock-sales';
export const REPORT_STOCK_CARD_TYPE = 'report-stock-card';
export const REPORT_STOCK_MUTATION_TYPE = 'report-stock-mutation';
export const REPORT_STOCK_PURCHASE_TYPE = 'report-stock-purchase';
export const REPORT_STOCK_ADJUSTMENT_TYPE = 'report-stock-adjustment';

export const REPORT_BASIC_TYPE_KEY_NAME = 'reportBasicType';

export const reportBasicTypes = [
  REPORT_BASIC_TYPE_PURCHASE,
  REPORT_BASIC_TYPE_SALES,
  REPORT_BASIC_TYPE_STOCK,
  REPORT_BASIC_TYPE_PRICE_LIST,
  REPORT_BASIC_TYPE_SALES_EXTRACTION,
  REPORT_TYPE_CASH_FLOW,
  REPORT_TYPE_JOURNAL,
  REPORT_TYPE_INCOME_STATEMENT,
  REPORT_TYPE_BALANCE_SHEET,
  REPORT_TYPE_CAPITAL_CHANGE,
  REPORT_TYPE_GENERAL_LEDGER,
  REPORT_TYPE_TRIAL_BALANCE,
  REPORT_STOCK_SALES_TYPE,
  REPORT_STOCK_CARD_TYPE,
  REPORT_STOCK_MUTATION_TYPE,
  REPORT_STOCK_PURCHASE_TYPE,
  REPORT_STOCK_ADJUSTMENT_TYPE,
];

export const keyCurrencyType = [
  // in report income statement
  'total-income',
  'total-cost-of-goods',
  'total-other-income',
  'total-cost-of-sale',
  'total-other-expense',
  'gross-profit',
  'profit-loss',
  // in report capital change
  'begining_amount',
  'ending_amount',
  'movement_credit_amount',
  'movement_debit_amount',
  'total_beginning_balance',
  'total_movement_debit',
  'total_movement_credit',
  'total_ending_balance',
  // in report cash flow
  'increase-decrease-in-cash',
  'beginning-cash-balance',
  'ending-cash-balance',
];

export const reportBasicConfiguration = {
  [REPORT_BASIC_TYPE_PURCHASE]: {
    url: REACT_APP_API_URL_REPORT_BASIC_PURCHASE,
    parentSlice: NAME_STORE.REPORT,
    childrenSliceList: SLICE_NAME_REPORT_BASIC_PURCHASE_LIST,
    localStorageNameList: LOCAL_STORAGE_REPORT_BASIC_PURCHASE_LIST,
    uniqueIdentityIDList: 'purchase_invoice_id',
    requestStatusLoadingList: STATUS_REQUEST_LIST_REPORT_BASIC_PURCHASE_PENDING,
    requestStatusSuccessList: STATUS_REQUEST_LIST_REPORT_BASIC_PURCHASE_SUCCESS,
    requestStatusFailedList: STATUS_REQUEST_LIST_REPORT_BASIC_PURCHASE_FAILED,
    allowSearchParamsList: DEFAULT_REPORT_BASIC_PURCHASE_LIST_PARAMS,
    defaultSearchParamsList: DEFAULT_REPORT_BASIC_PURCHASE_LIST_SEARCH_PARAMS,
    defaultParamsList: {},
    initialSearchParamsList: {
      from_date: null,
      to_date: null,
      supplier_id: '',
      supplier_id_container: null,
      status: '',
      status_container: null,
      sort_by: {
        transaction_date: 'ASC',
      },
    },
    sortSearchParams: 'sort_by',
    isShowDateRangeInput: true,
    isShowAutocompleteContact: true,
    contactUniqueKey: 'supplier_id',
    contactContainerUniqueKey: 'supplier_id_container',
    contactPlaceholderForm: 'placeholder.contact-supplier',
    additionalFetchContactList: {
      type: ['is_supplier'],
    },
    isShowAutocompleteStatus: true,
    statusOptions: purchaseInvoiceChartBasicStatus,
    reportTitle: 'header.report-title-purchase',
    reportLabelBottom: [
      ['', 'from_date'],
      ['', 'to_date'],
    ], // represent first item on 2d array is translation and continue with cache on search list
    separatorEachItemLabel: ' - ',
    showPrintButton: true,
    columnsTableList: (t) => {
      return [
        {
          name: 'transaction_date',
          label: t('table.header-column.transaction-date'),
          style: {
            minWidth: 105,
            width: 105,
            maxWidth: 110,
          },
          formatData: FORMAT_DATE_COLUMN,
        },
        {
          name: 'purchase_invoice_no',
          label: t('table.header-column.transaction-number'),
          style: {
            minWidth: 120,
            width: 120,
            maxWidth: 125,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'description',
          label: t('table.header-column.description'),
          style: {
            minWidth: 150,
            maxWidth: 160,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'supplier_name',
          label: t('table.header-column.supplier-name'),
          style: {
            minWidth: 150,
            maxWidth: 160,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'amount',
          label: t('table.header-column.amount'),
          style: {
            minWidth: 160,
            width: 160,
            maxWidth: 170,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          align: 'right',
        },
      ];
    },
    columnTableFooter: (t) => [
      [
        {
          align: 'right',
          label: t('typography.total'),
          colSpan: 1,
          isUseSpecialValue: false,
        },
        {
          specialValue: '{total}',
          align: 'right',
          colSpan: 1,
          isUseSpecialValue: true,
        },
      ],
    ],
    additionalAmountHeaderRow: 7,
    additionalAmountFooterRow: 2,
    ...defaultReportXLSXprintConfigurationProps,
    worksheetName: worksheetXLSXname.DEFAULT_WORKSHEET_PURCHASE_REPORT_NAME,
    colIndexDateFormats: [0],
  },
  [REPORT_BASIC_TYPE_SALES]: {
    url: REACT_APP_API_URL_REPORT_BASIC_SALES,
    parentSlice: NAME_STORE.REPORT,
    childrenSliceList: SLICE_NAME_REPORT_BASIC_SALES_LIST,
    localStorageNameList: LOCAL_STORAGE_REPORT_BASIC_SALES_LIST,
    uniqueIdentityIDList: 'sales_invoice_id',
    requestStatusLoadingList: STATUS_REQUEST_LIST_REPORT_BASIC_SALES_PENDING,
    requestStatusSuccessList: STATUS_REQUEST_LIST_REPORT_BASIC_SALES_SUCCESS,
    requestStatusFailedList: STATUS_REQUEST_LIST_REPORT_BASIC_SALES_FAILED,
    allowSearchParamsList: DEFAULT_REPORT_BASIC_SALES_LIST_PARAMS,
    defaultSearchParamsList: DEFAULT_REPORT_BASIC_SALES_LIST_SEARCH_PARAMS,
    defaultParamsList: {},
    initialSearchParamsList: {
      from_date: null,
      to_date: null,
      customer_id: '',
      customer_id_container: null,
      status: '',
      status_container: null,
      sort_by: {
        transaction_date: 'ASC',
      },
    },
    sortSearchParams: 'sort_by',
    isShowDateRangeInput: true,
    isShowAutocompleteContact: true,
    contactUniqueKey: 'customer_id',
    contactContainerUniqueKey: 'customer_id_container',
    contactPlaceholderForm: 'placeholder.contact-customer',
    additionalFetchContactList: {
      type: ['is_customer'],
    },
    isShowAutocompleteStatus: true,
    statusOptions: salesInvoiceChartBasicStatus,
    reportTitle: 'header.report-title-sales',
    reportLabelBottom: [
      ['', 'from_date'],
      ['', 'to_date'],
    ], // represent first item on 2d array is translation and continue with cache on search list
    separatorEachItemLabel: ' - ',
    showPrintButton: true,
    columnsTableList: (t) => {
      return [
        {
          name: 'transaction_date',
          label: t('table.header-column.transaction-date'),
          style: {
            minWidth: 105,
            width: 105,
            maxWidth: 110,
          },
          formatData: FORMAT_DATE_COLUMN,
        },
        {
          name: 'sales_invoice_no',
          label: t('table.header-column.transaction-number'),
          style: {
            minWidth: 120,
            width: 120,
            maxWidth: 125,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'description',
          label: t('table.header-column.description'),
          style: {
            minWidth: 150,
            maxWidth: 160,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'customer_name',
          label: t('table.header-column.customer-name'),
          style: {
            minWidth: 150,
            maxWidth: 160,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'amount',
          label: t('table.header-column.amount'),
          style: {
            minWidth: 160,
            width: 160,
            maxWidth: 170,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          align: 'right',
        },
      ];
    },
    columnTableFooter: (t) => [
      [
        {
          align: 'right',
          label: t('typography.total'),
          colSpan: 1,
          isUseSpecialValue: false,
        },
        {
          specialValue: '{total}',
          align: 'right',
          colSpan: 1,
          isUseSpecialValue: true,
        },
      ],
    ],
    additionalAmountHeaderRow: 7,
    additionalAmountFooterRow: 2,
    ...defaultReportXLSXprintConfigurationProps,
    worksheetName: worksheetXLSXname.DEFAULT_WORKSHEET_SALES_REPORT_NAME,
    colIndexDateFormats: [0],
  },
  [REPORT_BASIC_TYPE_STOCK]: {
    url: REACT_APP_API_URL_REPORT_BASIC_STOCK,
    parentSlice: NAME_STORE.REPORT,
    childrenSliceList: SLICE_NAME_REPORT_BASIC_STOCK_LIST,
    localStorageNameList: LOCAL_STORAGE_REPORT_BASIC_STOCK_LIST,
    uniqueIdentityIDList: 'product_code',
    requestStatusLoadingList: STATUS_REQUEST_LIST_REPORT_BASIC_STOCK_PENDING,
    requestStatusSuccessList: STATUS_REQUEST_LIST_REPORT_BASIC_STOCK_SUCCESS,
    requestStatusFailedList: STATUS_REQUEST_LIST_REPORT_BASIC_STOCK_FAILED,
    allowSearchParamsList: DEFAULT_REPORT_BASIC_STOCK_LIST_PARAMS,
    defaultSearchParamsList: DEFAULT_REPORT_BASIC_STOCK_LIST_SEARCH_PARAMS,
    defaultParamsList: {},
    additionalSearchParamsWhenChangeAutocomplete: {
      warehouse_id: ['warehouse_name'],
      product_category_id: ['category_name'],
    },
    initialHitEndpoint: {
      warehouseList: {
        searchParams: {
          warehouse_type: ['main'],
        },
        typeGettingData: 'getDetailsOne',
        positionOnSearchParams: ['warehouse_id_container', 'warehouse_id', 'warehouse_name'],
        parentSliceName: NAME_STORE.WAREHOUSE,
        childrenSliceName: SLICE_NAME_SETTINGS_WAREHOUSE_DATA,
        requestStatusLoading: STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_PENDING,
      },
    },
    sortSearchParams: 'sort_by',
    initialSearchParamsList: {
      from_date: null,
      to_date: null,
      product_category_id: '',
      product_category_id_container: null,
      warehouse_id: '',
      warehouse_id_container: null,
      sort_by: {
        product_name: 'ASC',
      },
    },
    isShowDateInput: true,
    isShowAutocompleteProductCategory: true,
    isShowAutocompleteWarehouse: true,
    reportTitle: 'header.report-title-stock',
    reportLabelBottom: [
      ['typography.warehouse', 'warehouse_name'],
      ['typography.category', 'category_name'],
    ], // represent first item on 2d array is translation and continue with cache on search list
    separatorEachItemLabel: ' - ',
    showPrintButton: true,
    columnsTableList: (t) => {
      return [
        [
          {
            label: t('table.header-column.code'),
            style: {
              minWidth: 35,
              maxWidth: 40,
              verticalAlign: 'top',
            },
            rowSpan: 2,
          },
          {
            label: t('table.header-column.product-name'),
            style: {
              minWidth: 95,
              maxWidth: 100,
              verticalAlign: 'top',
            },
            rowSpan: 2,
          },
          {
            label: t('table.header-column.category'),
            style: {
              minWidth: 55,
              maxWidth: 60,
              verticalAlign: 'top',
            },
            rowSpan: 2,
          },
          {
            label: t('table.header-column.contents-per-carton'),
            style: {
              minWidth: 40,
              maxWidth: 45,
              verticalAlign: 'top',
            },
            rowSpan: 2,
            align: 'right',
          },
          {
            label: t('table.header-column.quantity'),
            style: {
              minWidth: 90,
              maxWidth: 95,
            },
            colSpan: 2,
            align: 'center',
          },
          {
            label: t('table.header-column.unit'),
            style: {
              minWidth: 25,
              maxWidth: 30,
              verticalAlign: 'top',
            },
            rowSpan: 2,
          },
          {
            label: t('table.header-column.sales-value'),
            style: {
              minWidth: 70,
              maxWidth: 80,
              verticalAlign: 'top',
            },
            rowSpan: 2,
            align: 'right',
          },
          {
            label: t('table.header-column.purchase-value'),
            style: {
              minWidth: 70,
              maxWidth: 80,
              verticalAlign: 'top',
            },
            rowSpan: 2,
            align: 'right',
          },
          {
            label: t('table.header-column.description'),
            style: {
              minWidth: 80,
              maxWidth: 85,
              verticalAlign: 'top',
            },
            rowSpan: 2,
          },
        ],
        [
          {
            label: t('table.header-column.carton'),
            style: {
              minWidth: 45,
              maxWidth: 50,
            },
            colSpan: 1,
            align: 'right',
          },
          {
            label: t('table.header-column.amount'),
            style: {
              minWidth: 45,
              maxWidth: 50,
            },
            colSpan: 1,
            align: 'right',
          },
        ],
      ];
    },
    isUseMultipleTableHead: true,
    isUseDifferentTableBody: true,
    columnsTableBody: [
      {
        name: 'product_code',
        formatData: FORMAT_COMMON_COLUMN,
      },
      {
        name: 'product_name',
        formatData: FORMAT_COMMON_COLUMN,
      },
      {
        name: 'category_name',
        formatData: FORMAT_COMMON_COLUMN,
      },
      {
        name: 'per_carton',
        formatData: FORMAT_NUMBER_COLUMN,
        isUseZeroNumberWhenNull: true,
        align: 'right',
      },
      {
        name: 'per_carton',
        paramsFormatterCalculationManual: ['per_carton', 'quantity_real'],
        formatData: FORMAT_CALCULATION_MANUAL_COLUMN,
        formatterData: (paramsCalculationManual) => {
          if (isEmpty(paramsCalculationManual)) return 0;

          const { per_carton, quantity_real } = paramsCalculationManual;
          if (!per_carton || !quantity_real || !Number(per_carton) || !Number(quantity_real))
            return 0;

          return Math.floor(Number(quantity_real) / Number(per_carton));
        },
        isUseZeroNumberWhenNull: true,
        align: 'right',
      },
      {
        name: 'quantity_real',
        paramsFormatterCalculationManual: ['per_carton', 'quantity_real'],
        formatData: FORMAT_CALCULATION_MANUAL_COLUMN,
        formatterData: (paramsCalculationManual) => {
          if (isEmpty(paramsCalculationManual)) return 0;

          const { per_carton, quantity_real } = paramsCalculationManual;
          if (!per_carton || !Number(per_carton)) {
            if (quantity_real && Number(quantity_real)) return Number(quantity_real);
          }

          if (!quantity_real || !Number(quantity_real)) return 0;

          return Number(quantity_real) % Number(per_carton);
        },
        isUseZeroNumberWhenNull: true,
        align: 'right',
      },
      {
        name: 'product_unit',
        formatData: FORMAT_NUMBER_COLUMN,
      },
      {
        name: 'sales_price',
        formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
        align: 'right',
      },
      {
        name: 'purchase_price',
        formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
        align: 'right',
      },
      {
        name: 'product_description',
        formatData: FORMAT_COMMON_COLUMN,
      },
    ],
    additionalAmountHeaderRow: 7,
    additionalAmountFooterRow: 1,
    isShowCurrentDateTitle: true,
    ...defaultReportXLSXprintConfigurationProps,
    worksheetName: worksheetXLSXname.DEFAULT_WORKSHEET_STOCK_REPORT_NAME,
  },
  [REPORT_BASIC_TYPE_PRICE_LIST]: {
    url: REACT_APP_API_URL_REPORT_BASIC_PRICE_LIST,
    parentSlice: NAME_STORE.REPORT,
    childrenSliceList: SLICE_NAME_REPORT_BASIC_PRODUCT_PRICE_LIST,
    localStorageNameList: LOCAL_STORAGE_REPORT_BASIC_PRODUCT_PRICE_LIST,
    uniqueIdentityIDList: 'product_code',
    requestStatusLoadingList: STATUS_REQUEST_LIST_REPORT_BASIC_PRICE_LIST_PENDING,
    requestStatusSuccessList: STATUS_REQUEST_LIST_REPORT_BASIC_PRICE_LIST_SUCCESS,
    requestStatusFailedList: STATUS_REQUEST_LIST_REPORT_BASIC_PRICE_LIST_FAILED,
    allowSearchParamsList: DEFAULT_REPORT_BASIC_PRODUCT_PRICE_LIST_PARAMS,
    defaultSearchParamsList: DEFAULT_REPORT_BASIC_PRODUCT_PRICE_LIST_SEARCH_PARAMS,
    defaultParamsList: {},
    additionalSearchParamsWhenChangeAutocomplete: {
      product_category_id: ['category_name'],
    },
    initialSearchParamsList: {
      from_date: null,
      to_date: null,
      product_category_id: '',
      product_category_id_container: null,
      sort_by: {
        product_name: 'ASC',
      },
    },
    sortSearchParams: 'sort_by',
    isShowAutocompleteProductCategory: true,
    reportTitle: 'header.report-title-price-list',
    reportLabelBottom: [['typography.category', 'category_name']], // represent first item on 2d array is translation and continue with cache on search list
    separatorEachItemLabel: ' - ',
    showPrintButton: true,
    columnsTableList: (t) => {
      return [
        [
          {
            label: t('table.header-column.code'),
            style: {
              minWidth: 45,
              maxWidth: 50,
              verticalAlign: 'top',
            },
            rowSpan: 2,
          },
          {
            label: t('table.header-column.product-name'),
            style: {
              minWidth: 185,
              maxWidth: 185,
              verticalAlign: 'top',
            },
            rowSpan: 2,
          },
          {
            label: t('table.header-column.category'),
            style: {
              minWidth: 145,
              maxWidth: 150,
              verticalAlign: 'top',
            },
            rowSpan: 2,
          },
          {
            label: t('table.header-column.contents-per-carton'),
            style: {
              minWidth: 65,
              maxWidth: 70,
              verticalAlign: 'top',
            },
            rowSpan: 2,
            align: 'right',
          },
          {
            label: t('table.header-column.shop-price'),
            style: {
              minWidth: 210,
              maxWidth: 225,
            },
            colSpan: 3,
            align: 'center',
          },
          {
            label: t('table.header-column.description'),
            style: {
              minWidth: 120,
              maxWidth: 235,
              width: 230,
              verticalAlign: 'top',
            },
            rowSpan: 2,
          },
        ],
        [
          {
            label: t('table.header-column.carton'),
            style: {
              minWidth: 70,
              maxWidth: 75,
            },
            colSpan: 1,
            align: 'right',
          },
          {
            label: t('table.header-column.dozen'),
            style: {
              minWidth: 70,
              maxWidth: 75,
            },
            colSpan: 1,
            align: 'right',
          },
          {
            label: t('table.header-column.pcs'),
            style: {
              minWidth: 70,
              maxWidth: 75,
              verticalAlign: 'top',
            },
            align: 'right',
            colSpan: 1,
          },
        ],
      ];
    },
    isUseMultipleTableHead: true,
    isUseDifferentTableBody: true,
    columnsTableBody: [
      {
        name: 'product_code',
        formatData: FORMAT_COMMON_COLUMN,
      },
      {
        name: 'product_name',
        formatData: FORMAT_COMMON_COLUMN,
      },
      {
        name: 'category_name',
        formatData: FORMAT_COMMON_COLUMN,
      },
      {
        name: 'per_carton',
        formatData: FORMAT_NUMBER_COLUMN,
        isUseZeroNumberWhenNull: true,
        align: 'right',
      },
      {
        name: 'per_carton_price',
        paramsFormatterCalculationManual: ['per_carton', 'sales_price'],
        formatData: FORMAT_CALCULATION_MANUAL_COLUMN,
        formatterData: (paramsCalculationManual) => {
          if (isEmpty(paramsCalculationManual)) return 0;

          const { per_carton, sales_price } = paramsCalculationManual;
          if (!per_carton || !sales_price || !Number(per_carton) || !Number(sales_price)) return 0;

          return formatHelp.currencyWithAutoComma(Number(per_carton) * Number(sales_price));
        },
        isUseZeroNumberWhenNull: true,
        align: 'right',
      },
      {
        name: 'per_dozen_price',
        paramsFormatterCalculationManual: ['sales_price'],
        formatData: FORMAT_CALCULATION_MANUAL_COLUMN,
        formatterData: (paramsCalculationManual) => {
          if (isEmpty(paramsCalculationManual)) return 0;

          const { sales_price } = paramsCalculationManual;
          if (!sales_price || !Number(sales_price)) return 0;

          return formatHelp.currencyWithAutoComma(Number(sales_price) * 12);
        },
        isUseZeroNumberWhenNull: true,
        align: 'right',
      },
      {
        name: 'per_pcs_price',
        paramsFormatterCalculationManual: ['sales_price'],
        formatData: FORMAT_CALCULATION_MANUAL_COLUMN,
        formatterData: (paramsCalculationManual) => {
          if (isEmpty(paramsCalculationManual)) return 0;

          const { sales_price } = paramsCalculationManual;
          if (!sales_price || !Number(sales_price)) return 0;

          return formatHelp.currencyWithAutoComma(Number(sales_price) * 1);
        },
        isUseZeroNumberWhenNull: true,
        align: 'right',
      },
      {
        name: 'product_description',
        formatData: FORMAT_COMMON_COLUMN,
      },
    ],
    additionalAmountHeaderRow: 7,
    additionalAmountFooterRow: 1,
    isShowCurrentDateTitle: true,
    ...defaultReportXLSXprintConfigurationProps,
    worksheetName: worksheetXLSXname.DEFAULT_WORKSHEET_PRICE_LIST_REPORT_NAME,
  },
  [REPORT_BASIC_TYPE_SALES_EXTRACTION]: {
    url: REACT_APP_API_URL_REPORT_BASIC_SALES_EXTRACTION,
    parentSlice: NAME_STORE.REPORT,
    childrenSliceList: SLICE_NAME_REPORT_BASIC_SALES_EXTRACTION_LIST,
    localStorageNameList: LOCAL_STORAGE_REPORT_BASIC_PURCHASE_LIST,
    uniqueIdentityIDList: 'sales_invoice_id',
    requestStatusLoadingList: STATUS_REQUEST_LIST_REPORT_BASIC_SALES_EXTRACTION_PENDING,
    requestStatusSuccessList: STATUS_REQUEST_LIST_REPORT_BASIC_SALES_EXTRACTION_SUCCESS,
    requestStatusFailedList: STATUS_REQUEST_LIST_REPORT_BASIC_SALES_EXTRACTION_FAILED,
    allowSearchParamsList: DEFAULT_REPORT_BASIC_SALES_EXTRACTION_LIST_PARAMS,
    defaultSearchParamsList: DEFAULT_REPORT_BASIC_SALES_EXTRACTION_LIST_SEARCH_PARAMS,
    defaultParamsList: {},
    initialSearchParamsList: {
      from_date: null,
      to_date: null,
      sort_by: {
        transaction_date: 'ASC',
      },
    },
    sortSearchParams: 'sort_by',
    isShowDateRangeInput: true,
    isShowCompanyAddressTitle: true,
    reportTitle: 'header.report-title-sales-extraction',
    reportLabelBottom: [
      ['', 'from_date'],
      ['', 'to_date'],
    ], // represent first item on 2d array is translation and continue with cache on search list
    separatorEachItemLabel: ' - ',
    showPrintButton: true,
    columnsTableList: (t) => {
      return [
        {
          name: 'sales_agent_code',
          label: t('table.header-column.sales-agent-code'),
          style: {
            minWidth: 50,
            maxWidth: 55,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'sales_agent_name',
          label: t('table.header-column.agent-name'),
          style: {
            minWidth: 75,
            maxWidth: 80,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'sales_invoice_no',
          label: t('table.header-column.invoice-number'),
          style: {
            minWidth: 45,
            maxWidth: 50,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'transaction_date',
          label: t('table.header-column.date'),
          style: {
            minWidth: 50,
            maxWidth: 55,
          },
          formatData: FORMAT_DATE_COLUMN,
        },
        {
          name: 'customer_code',
          label: t('table.header-column.customer-code'),
          style: {
            minWidth: 55,
            maxWidth: 60,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'customer_name',
          label: t('table.header-column.customer-name'),
          style: {
            minWidth: 75,
            maxWidth: 80,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'customer_shipping_address',
          label: t('table.header-column.customer-address'),
          style: {
            minWidth: 95,
            maxWidth: 100,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'product_category_name',
          label: t('table.header-column.category'),
          style: {
            minWidth: 50,
            maxWidth: 55,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'product_code',
          label: t('table.header-column.product-code'),
          style: {
            minWidth: 40,
            maxWidth: 45,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'product_name',
          label: t('table.header-column.product-name'),
          style: {
            minWidth: 95,
            maxWidth: 100,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'quantity_available',
          label: t('table.header-column.quantity'),
          style: {
            minWidth: 55,
            maxWidth: 60,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          isUseZeroNumberWhenNull: true,
          align: 'right',
        },
        {
          name: 'price',
          label: t('table.header-column.price'),
          style: {
            minWidth: 55,
            maxWidth: 60,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          isUseZeroNumberWhenNull: true,
          align: 'right',
        },
        {
          name: 'total',
          label: t('table.header-column.total'),
          style: {
            minWidth: 55,
            maxWidth: 60,
          },
          paramsFormatterCalculationManual: ['quantity', 'price'],
          formatData: FORMAT_CALCULATION_MANUAL_COLUMN,
          formatterData: (paramsCalculationManual) => {
            if (isEmpty(paramsCalculationManual)) return 0;

            const { price, quantity } = paramsCalculationManual;
            if (!price || !quantity) return 0;

            const total = Number(price) * Number(quantity);

            return formatHelp.currencyWithAutoComma(total);
          },
          isUseZeroNumberWhenNull: true,
          align: 'right',
        },
      ];
    },
    additionalAmountHeaderRow: 15,
    additionalAmountFooterRow: 0,
    totalRowPerPage: 41,
    maximumDistanceFromLastRow: 20,
    additionalTableModePrint: `
            th {
                font-size: 8px !important;
                padding: 4px 8px !important;
            }

            td > p {
                font-size: 8px !important;
            }
        `,
    ...defaultReportXLSXprintConfigurationProps,
    worksheetName: worksheetXLSXname.DEFAULT_WORKSHEET_SALES_EXTRACTION_REPORT_NAME,
    colIndexDateFormats: [3],
  },
  [REPORT_TYPE_JOURNAL]: {
    url: REACT_APP_API_URL_REPORT_JOURNAL,
    parentSlice: NAME_STORE.REPORT,
    childrenSliceList: SLICE_NAME_REPORT_JOURNAL_LIST,
    localStorageNameList: LOCAL_STORAGE_REPORT_JOURNAL,
    uniqueIdentityIDList: DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF,
    requestStatusLoadingList: STATUS_REQUEST_LIST_REPORT_JOURNAL_PENDING,
    requestStatusSuccessList: STATUS_REQUEST_LIST_REPORT_JOURNAL_SUCCESS,
    requestStatusFailedList: STATUS_REQUEST_LIST_REPORT_JOURNAL_FAILED,
    allowSearchParamsList: DEFAULT_REPORT_JOURNAL_LIST_PARAMS,
    defaultSearchParamsList: DEFAULT_REPORT_JOURNAL_SEARCH_FE_PARAMS,
    defaultParamsList: {},
    initialSearchParamsList: {
      from_date: null,
      to_date: null,
    },
    isShowDateRangeInput: true,
    reportTitle: 'header.journal-report',
    reportLabelBottom: [
      ['', 'from_date'],
      ['', 'to_date'],
    ], // represent first item on 2d array is translation and continue with cache on search list
    separatorEachItemLabel: ' - ',
    showPrintButton: true,
    columnsTableList: (t) => columnsTableReportJournalForTableRowPureMarkup(t),
    isUseGenerateDataList: true,
    generateDataListFromChildrenSlice: (t, childrenSliceData) => {
      if (!childrenSliceData) return childrenSliceData || [];

      const { data } = childrenSliceData;
      if (isEmpty(data)) return [];

      let grandTotalJournalReport = {
        debit_amount: 0,
        credit_amount: 0,
        transaction_description: t('table.row.grand-total'),
        styleParagraph: {
          ...secondaryParagraph,
          textAlign: 'right',
        },
      };

      const modifiedDataForTableRow =
        Array.isArray(data) &&
        data.reduce((allRowDatas, currentData) => {
          currentData = {
            ...currentData,
            additionalPropsTableDataBasedIndexColumn: Array(2)
              .fill(tableDataOneColSpan)
              .concat({ colSpan: 3 })
              .concat(Array(2).fill(tableDataZeroColSpan)),
          };

          allRowDatas = allRowDatas.concat(currentData);

          let { journal_entry: journalEntry } = currentData;
          if (!isEmpty(journalEntry) && Array.isArray(journalEntry)) {
            let subTotalJournalEntry = {
              debit_amount: 0,
              credit_amount: 0,
              transaction_description: t('typography.total'),
              styleParagraph: {
                ...tersierParagraph,
                textAlign: 'right',
              },
              additionalPropsTableDataBasedIndexColumn: Array(2)
                .fill(tableDataZeroColSpan)
                .concat({ colSpan: 3 })
                .concat(Array(2).fill(tableDataOneColSpan)),
              additionalStyleTableDataBasedIndexColumn: Array(2)
                .fill(null)
                .concat({ borderWidth: '1px' })
                .concat(Array(2).fill(null)),
            };

            journalEntry = journalEntry.map((singleJournalEntry) => {
              const { debit_amount, credit_amount } = singleJournalEntry;
              subTotalJournalEntry = {
                ...subTotalJournalEntry,
                debit_amount: Number(subTotalJournalEntry.debit_amount) + Number(debit_amount),
                credit_amount: Number(subTotalJournalEntry.credit_amount) + Number(credit_amount),
              };

              return {
                styleParagraph: primaryParagraph,
                ...objHelper.changeKeyNameWithCorrespondKeyTarget({
                  obj: singleJournalEntry,
                  correspondObj: correspondChangeKeynameJournalEntryToTransaction,
                }),
              };
            });

            grandTotalJournalReport = {
              ...grandTotalJournalReport,
              debit_amount:
                grandTotalJournalReport.debit_amount + subTotalJournalEntry.debit_amount,
              credit_amount:
                grandTotalJournalReport.credit_amount + subTotalJournalEntry.credit_amount,
            };

            journalEntry = journalEntry.concat(subTotalJournalEntry);

            allRowDatas = allRowDatas.concat(journalEntry);
          }

          return allRowDatas;
        }, []);

      return modifiedDataForTableRow.concat(grandTotalJournalReport);
    },
    additionalAmountHeaderRow: 7,
    additionalAmountFooterRow: 2,
    printMode: 'portrait',
    totalRowPerPage: 52,
    ...defaultReportXLSXprintConfigurationProps,
    worksheetName: worksheetXLSXname.DEFAULT_WORKSHEET_JOURNAL_REPORT_NAME,
    additionalMerges: ['A1:C1'],
  },
  [REPORT_TYPE_CASH_FLOW]: {
    url: REACT_APP_API_URL_REPORT_CASH_FLOW,
    parentSlice: NAME_STORE.REPORT,
    childrenSliceList: SLICE_NAME_REPORT_CASH_FLOW,
    localStorageNameList: LOCAL_STORAGE_REPORT_CASH_FLOW,
    uniqueIdentityIDList: 'cash_flow_id',
    requestStatusLoadingList: STATUS_REQUEST_LIST_REPORT_CASH_FLOW_PENDING,
    requestStatusSuccessList: STATUS_REQUEST_LIST_REPORT_CASH_FLOW_SUCCESS,
    requestStatusFailedList: STATUS_REQUEST_LIST_REPORT_CASH_FLOW_FAILED,
    allowSearchParamsList: DEFAULT_REPORT_CASH_FLOW_LIST_PARAMS,
    defaultSearchParamsList: DEFAULT_REPORT_CASH_FLOW_SEARCH_FE_PARAMS,
    defaultParamsList: {},
    initialSearchParamsList: {
      from_date: null,
      to_date: null,
    },
    isShowDateRangeInput: true,
    reportTitle: 'header.report-title-cash-flow',
    reportLabelBottom: [
      ['', 'from_date'],
      ['', 'to_date'],
    ], // represent first item on 2d array is translation and continue with cache on search list
    separatorEachItemLabel: ' - ',
    showPrintButton: true,
    columnsTableList: (t) => {
      return [
        {
          name: 'name',
          label: t('table.header-column.information'),
          style: {
            minWidth: 105,
            width: 105,
            maxWidth: 110,
          },
          formatData: FORMAT_COMMON_COLUMN,
          isUseFormatStyleOnRow: true,
        },
        {
          name: 'balance',
          label: t('table.header-column.balance'),
          style: {
            minWidth: 10,
            width: 10,
            maxWidth: 15,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          isUseZeroNumberWhenNull: false,
          align: 'right',
          isUseFormatStyleOnRow: true,
          substituteNotExistValue: '',
        },
      ];
    },
    isUseGenerateDataList: true,
    generateDataListFromChildrenSlice: (t, childrenSliceData) => {
      if (!childrenSliceData) return childrenSliceData;

      childrenSliceData = objHelper.changeFormatKeyObj(childrenSliceData, (key) =>
        key.replace(/_/g, '-'),
      );

      childrenSliceData = objHelper.changeFormatValueV2(
        childrenSliceData,
        keyCurrencyType,
        '',
        formatHelp.currencyWithRegexOnBrackets,
        [keyCurrencyType, true],
      );

      childrenSliceData = pick(childrenSliceData, allRowsTableReportCashFlow);

      const tableRowCashFlowTranslate = rowsTableReportCashFlowWithTranslation(t);

      childrenSliceData = allRowsTableReportCashFlow.reduce(
        (allReportCashFlow, singleReportCashFlow) => {
          const { translationNameRow } = arrHelp.filterObjDataWithID(
            tableRowCashFlowTranslate,
            'nameRow',
            singleReportCashFlow,
          );

          const currValue = childrenSliceData[singleReportCashFlow];

          if (typeof currValue === 'object') {
            const allKeysCurrValue = Object.keys(currValue);

            allReportCashFlow = allReportCashFlow.concat({
              balance: '',
              name: translationNameRow,
              style: primaryTableSubHead,
              styleParagraph: secondaryParagraph,
            });

            for (let keyCurrValue of allKeysCurrValue) {
              const isIncludeOnSubtotal = rowsTableTotalPerCashAccount.includes(keyCurrValue);

              const { translationNameRow } = arrHelp.filterObjDataWithID(
                tableRowCashFlowTranslate,
                'nameRow',
                keyCurrValue,
              );

              allReportCashFlow = allReportCashFlow.concat({
                balance: formatHelp.currencyWithRegexOnBrackets(currValue[keyCurrValue], true),
                name: translationNameRow,
                styleParagraph: isIncludeOnSubtotal ? tersierParagraph : primaryParagraph,
                useSpaceBar: !isIncludeOnSubtotal,
                spaceBarAmount: 4,
              });
            }

            return allReportCashFlow;
          }

          return allReportCashFlow.concat({
            balance: currValue || '',
            name: translationNameRow,
            styleParagraph: secondaryParagraph,
          });
        },
        [],
      );

      return childrenSliceData;
    },
    additionalAmountHeaderRow: 7,
    additionalAmountFooterRow: 2,
    printMode: 'portrait',
    totalRowPerPage: 52,
    ...defaultReportXLSXprintConfigurationProps,
    worksheetName: worksheetXLSXname.DEFAULT_WORKSHEET_CASH_FLOW_REPORT_NAME,
  },
  [REPORT_TYPE_INCOME_STATEMENT]: {
    url: REACT_APP_API_URL_REPORT_INCOME_STATEMENT,
    parentSlice: NAME_STORE.REPORT,
    childrenSliceList: SLICE_NAME_REPORT_INCOME_STATEMENT,
    localStorageNameList: LOCAL_STORAGE_REPORT_INCOME_STATEMENT,
    uniqueIdentityIDList: 'income_statement_id',
    requestStatusLoadingList: STATUS_REQUEST_LIST_REPORT_INCOME_STATEMENT_PENDING,
    requestStatusSuccessList: STATUS_REQUEST_LIST_REPORT_INCOME_STATEMENT_SUCCESS,
    requestStatusFailedList: STATUS_REQUEST_LIST_REPORT_INCOME_STATEMENT_FAILED,
    allowSearchParamsList: DEFAULT_REPORT_INCOME_STATEMENT_LIST_PARAMS,
    defaultSearchParamsList: DEFAULT_REPORT_INCOME_STATEMENT_SEARCH_FE_PARAMS,
    defaultParamsList: {},
    initialSearchParamsList: {
      from_date: null,
      to_date: null,
      balance_null: 0,
    },
    discardKeySearchParamsList: ['balance_null'],
    isShowDateRangeInput: true,
    reportTitle: 'header.report-title-income-statement',
    reportLabelBottom: [
      ['', 'from_date'],
      ['', 'to_date'],
    ], // represent first item on 2d array is translation and continue with cache on search list
    separatorEachItemLabel: ' - ',
    showPrintButton: true,
    columnsTableList: (t) => {
      return [
        {
          name: 'name',
          label: t('table.header-column.information'),
          style: {
            minWidth: 105,
            width: 105,
            maxWidth: 110,
          },
          formatData: FORMAT_COMMON_COLUMN,
          isUseFormatStyleOnRow: true,
        },
        {
          name: 'credit',
          label: t('table.header-column.credit'),
          style: {
            minWidth: 10,
            width: 10,
            maxWidth: 15,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          isUseZeroNumberWhenNull: false,
          align: 'right',
          isUseFormatStyleOnRow: true,
          substituteNotExistValue: '',
        },
      ];
    },
    isUseGenerateDataList: true,
    generateDataListFromChildrenSlice: (t, childrenSliceData) => {
      if (!childrenSliceData) return childrenSliceData;

      childrenSliceData = objHelper.changeFormatKeyObj(childrenSliceData, (key) =>
        key.replace(/_/g, '-'),
      );

      // changes key name
      childrenSliceData = objHelper.changeKeyNameWithCorrespondKeyTarget({
        obj: childrenSliceData,
        correspondObj: correspondAPIkeynameWithViewKeyName,
      });

      // changes on multiple object ot array
      childrenSliceData = objHelper.changeMultipleObjToArray({
        obj: childrenSliceData,
        multipleKeyNameTarget: rowsTableWithChildren,
        additionalParamsChanges: {
          keyName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
        },
      });

      // summing for total calculation
      childrenSliceData = objHelper.sumValueObjBasedKeyNames({
        obj: childrenSliceData,
        keyNamesTarget: rowsTableWithChildren,
        keyNameSumValue: DEFAULT_KEY_NAME_AMOUNT_REF_API,
        keyNamesResult: rowsTableIncomeStatementsTotalCalculation,
      });

      if (!isEmpty(childrenSliceData)) {
        // PRD
        // laba kotor (gross profit) adalah jumlah antara total pendapatan dikurangi dengan hpp
        // laba rugi (profit loss) adalah total laba kotor ditambah dengan total pendapatan lainya dan dikurangi dengan ( total  beban operasional dan total beban lainya).
        const grossProfit =
          childrenSliceData['total-income'] - childrenSliceData['total-cost-of-goods'];

        const profitLoss =
          Number(grossProfit) +
          childrenSliceData['total-other-income'] -
          (childrenSliceData['total-cost-of-sale'] + childrenSliceData['total-other-expense']);

        childrenSliceData = {
          ...childrenSliceData,
          ['gross-profit']: Number(grossProfit),
          ['profit-loss']: Number(profitLoss),
        };

        childrenSliceData = objHelper.changeFormatValueV2(
          childrenSliceData,
          keyCurrencyType,
          '',
          formatHelp.currencyWithRegexOnBrackets,
          [keyCurrencyType, true],
        );
      }

      childrenSliceData = pick(childrenSliceData, allRowsTableReportIncomeStatement);

      const tableRowIncomeStatementTranslate = rowsTableReportIncomeStatementWithTranslation(t);

      childrenSliceData = allRowsTableReportIncomeStatement.reduce(
        (allReportIncomeStatement, singleReportIncomeStatement) => {
          const { translationRowName } = arrHelp.filterObjDataWithID(
            tableRowIncomeStatementTranslate,
            'rowName',
            singleReportIncomeStatement,
          );

          let currValues = childrenSliceData[singleReportIncomeStatement];
          const isIncludeOnSubtotal = rowsTableProfitTotal.includes(singleReportIncomeStatement);

          if (typeof currValues === 'object') {
            const allKeysCurrValue = Object.values(currValues);

            allReportIncomeStatement = allReportIncomeStatement.concat({
              name: translationRowName,
              credit: '',
              style: primaryTableSubHead,
              styleParagraph: isIncludeOnSubtotal ? tersierParagraph : secondaryParagraph,
            });

            for (let singleValue of allKeysCurrValue) {
              singleValue = objHelper.changeFormatKeyObj(singleValue, (key) =>
                key.replace(/-/g, '_'),
              );

              const rowName = formatHelp.formatAccountNameWithAccountCode(singleValue);

              allReportIncomeStatement = allReportIncomeStatement.concat({
                name: rowName,
                credit: formatHelp.currencyWithRegexOnBrackets(singleValue.amount, true),
                styleParagraph: primaryParagraph,
                useSpaceBar: true,
                spaceBarAmount: 4,
              });
            }

            return allReportIncomeStatement;
          }

          return allReportIncomeStatement.concat({
            name: translationRowName,
            credit: currValues || 0,
            styleParagraph: isIncludeOnSubtotal ? tersierParagraph : secondaryParagraph,
          });
        },
        [],
      );

      return childrenSliceData;
    },
    additionalAmountHeaderRow: 7,
    additionalAmountFooterRow: 2,
    printMode: 'portrait',
    totalRowPerPage: 52,
    ...defaultReportXLSXprintConfigurationProps,
    worksheetName: worksheetXLSXname.DEFAULT_WORKSHEET_INCOME_STATEMENT_REPORT_NAME,
  },
  [REPORT_TYPE_BALANCE_SHEET]: {
    url: REACT_APP_API_URL_REPORT_BALANCE_SHEET,
    parentSlice: NAME_STORE.REPORT,
    childrenSliceList: SLICE_NAME_REPORT_BALANCE_SHEET,
    localStorageNameList: LOCAL_STORAGE_REPORT_BALANCE_SHEET,
    uniqueIdentityIDList: 'account_id',
    requestStatusLoadingList: STATUS_REQUEST_LIST_REPORT_BALANCE_SHEET_PENDING,
    requestStatusSuccessList: STATUS_REQUEST_LIST_REPORT_BALANCE_SHEET_SUCCESS,
    requestStatusFailedList: STATUS_REQUEST_LIST_REPORT_BALANCE_SHEET_FAILED,
    allowSearchParamsList: DEFAULT_REPORT_BALANCE_SHEET_LIST_PARAMS,
    defaultSearchParamsList: DEFAULT_REPORT_BALANCE_SHEET_SEARCH_FE_PARAMS,
    defaultParamsList: {},
    initialSearchParamsList: {
      from_date: null,
      to_date: null,
      balance_null: 0,
    },
    discardKeySearchParamsList: [],
    isShowDateRangeInput: true,
    reportTitle: 'header.report-title-balance-sheet',
    reportLabelBottom: [
      ['', 'from_date'],
      ['', 'to_date'],
    ], // represent first item on 2d array is translation and continue with cache on search list
    separatorEachItemLabel: ' - ',
    showPrintButton: true,
    columnsTableList: (t) => {
      return [
        {
          name: 'name',
          label: t('table.header-column.information'),
          style: {
            minWidth: 105,
            width: 105,
            maxWidth: 110,
          },
          formatData: FORMAT_COMMON_COLUMN,
          isUseFormatStyleOnRow: true,
        },
        {
          name: 'balance',
          label: t('table.header-column.balance'),
          style: {
            minWidth: 10,
            width: 10,
            maxWidth: 15,
          },
          formatData: FORMAT_CURRENCY_ON_BRACKET,
          isUseZeroNumberWhenNull: false,
          align: 'right',
          isUseFormatStyleOnRow: true,
          substituteNotExistValue: '',
        },
      ];
    },
    isUseGenerateDataList: true,
    generateDataListFromChildrenSlice: (t, childrenSliceData) => {
      if (!childrenSliceData) return childrenSliceData;

      // changes object report balance to array
      childrenSliceData = objHelper.changeMultipleObjToArray({
        obj: childrenSliceData,
        multipleKeyNameTarget: accountNamesReportBalanceSheet,
        additionalParamsChanges: {
          keyName: 'account_id',
        },
      });

      // grouping key names by account category
      childrenSliceData = objHelper.groupingObjByCorrespondingKeyNames({
        obj: childrenSliceData,
        correspondObjWithKeyNames:
          correspondingAccountCategoryNaccountNameGroupingReportBalanceSheet,
      });

      // amount total report balance sheet
      childrenSliceData = objHelper.sumValueObjBasedKeyNames({
        obj: childrenSliceData,
        keyNamesTarget: accountCategoryGroupingReportBalanceSheet,
        keyNamesResult: rowsTableTotalAccountReportBalanceSheet,
      });

      if (!isEmpty(childrenSliceData)) {
        // getting on total assets, total income and adding liability n capital
        childrenSliceData = objHelper.sumValueObjBasedCorrespondKeyNames({
          obj: childrenSliceData,
          correspondObjWithKeyNames:
            correspondTotalAssetAndLiabilityMinusWithAccountCategoryReportBalanceSheet,
        });

        if (
          DEFAULT_KEY_NAME_ADDITIONAL_KEY_TOTAL_LIABILITIES_AND_CAPITAL_MINUS in childrenSliceData
        ) {
          // change from total liabilities to minus
          childrenSliceData = {
            ...childrenSliceData,
            [DEFAULT_KEY_NAME_ADDITIONAL_KEY_TOTAL_LIABILITIES_AND_CAPITAL_MINUS]:
              -childrenSliceData[
                DEFAULT_KEY_NAME_ADDITIONAL_KEY_TOTAL_LIABILITIES_AND_CAPITAL_MINUS
              ],
          };

          // getting total income
          // total income = total assets - (total liabilities + total capital)
          childrenSliceData = objHelper.sumValueObjBasedCorrespondKeyNames({
            obj: childrenSliceData,
            correspondObjWithKeyNames: correspondTotalIncomeWithAccountCategoryReportBalanceSheet,
          });

          // getting total liabilitites and capital = total income + (total liabilities + total capital)
          childrenSliceData = objHelper.sumValueObjBasedCorrespondKeyNames({
            obj: childrenSliceData,
            correspondObjWithKeyNames:
              correspondTotalLiabilityWithAccountCategoryReportBalanceSheet,
          });
        }

        // grouping report balance on two big parts
        childrenSliceData = objHelper.groupingObjByCorrespondingKeyNames({
          obj: childrenSliceData,
          correspondObjWithKeyNames:
            correspondingPartViewNaccountCategoryNameGroupingReportBalanceSheet,
          useValuesOnly: false,
          useRemainderKeyNameNotUsed: true,
          remainderKeyNameNotUsed: [
            'total-assets',
            'total-liabilities-and-capital',
            'total-income-in-period',
          ],
        });
      }

      const tableRowBalanceSheetTranslate = rowsTableReportBalanceSheetWithTranslation(t);

      childrenSliceData = allRowsTableReportBalanceSheet.reduce(
        (allReportBalanceSheet, singleReportBalanceSheet) => {
          let { translationRowName } = arrHelp.filterObjDataWithID(
            tableRowBalanceSheetTranslate,
            'rowName',
            singleReportBalanceSheet,
          );

          const currValues = childrenSliceData[singleReportBalanceSheet];
          const isIncludeOnSubtotal = rowsTableTotalBalance.includes(singleReportBalanceSheet);

          if (typeof currValues === 'object') {
            // getting parent of report balance sheet
            allReportBalanceSheet = allReportBalanceSheet.concat({
              balance: '',
              name: translationRowName,
              style: primaryTableSubHead,
              styleParagraph: isIncludeOnSubtotal ? tersierParagraph : secondaryParagraph,
            });

            const correspondsPartOfReportBalance =
              correspondingPartViewNaccountCategoryNameGroupingReportBalanceSheet[
                singleReportBalanceSheet
              ];

            if (
              Array.isArray(correspondsPartOfReportBalance) &&
              correspondsPartOfReportBalance.length
            ) {
              for (let singleCorrespondPart of correspondsPartOfReportBalance) {
                const selectedCorrespondTranslationName = arrHelp.filterObjDataWithID(
                  tableRowBalanceSheetTranslate,
                  'rowName',
                  singleCorrespondPart,
                );

                if (!isEmpty(selectedCorrespondTranslationName))
                  translationRowName = selectedCorrespondTranslationName.translationRowName;

                const isChildrenOfCorrespondPart =
                  rowsTableWithChildrenReportBalaceSheet.includes(singleCorrespondPart);
                const selectedValueByCorrespondPart = currValues[singleCorrespondPart];

                // getting total or sub parent balance sheet
                allReportBalanceSheet = allReportBalanceSheet.concat({
                  balance: isChildrenOfCorrespondPart ? '' : selectedValueByCorrespondPart,
                  name: translationRowName,
                  useSpaceBar: true,
                  spaceBarAmount: 4,
                  styleParagraph: isIncludeOnSubtotal ? tersierParagraph : secondaryParagraph,
                });

                // getting each accounts of balance sheet
                if (
                  isChildrenOfCorrespondPart &&
                  Array.isArray(selectedValueByCorrespondPart) &&
                  selectedValueByCorrespondPart.length
                ) {
                  for (let singleSelectedValue of selectedValueByCorrespondPart) {
                    const rowName =
                      formatHelp.formatAccountNameWithAccountCode(singleSelectedValue);

                    allReportBalanceSheet = allReportBalanceSheet.concat({
                      balance: singleSelectedValue.amount || 0,
                      name: rowName,
                      styleParagraph: primaryParagraph,
                      useSpaceBar: true,
                      spaceBarAmount: 8,
                    });
                  }
                }
              }
            }

            return allReportBalanceSheet;
          }

          return allReportBalanceSheet.concat({
            balance: currValues || 0,
            name: translationRowName,
            styleParagraph: isIncludeOnSubtotal ? tersierParagraph : secondaryParagraph,
          });
        },
        [],
      );

      return childrenSliceData;
    },
    additionalAmountHeaderRow: 7,
    additionalAmountFooterRow: 2,
    printMode: 'portrait',
    totalRowPerPage: 52,
    ...defaultReportXLSXprintConfigurationProps,
    worksheetName: worksheetXLSXname.DEFAULT_WORKSHEET_BALANCE_SHEET_REPORT_NAME,
  },
  [REPORT_TYPE_CAPITAL_CHANGE]: {
    url: REACT_APP_API_URL_REPORT_CAPITAL_CHANGE,
    parentSlice: NAME_STORE.REPORT,
    childrenSliceList: SLICE_NAME_REPORT_CAPITAL_CHANGE,
    localStorageNameList: LOCAL_STORAGE_REPORT_CAPITAL_CHANGE,
    uniqueIdentityIDList: 'account_id',
    requestStatusLoadingList: STATUS_REQUEST_LIST_REPORT_CAPITAL_CHANGE_PENDING,
    requestStatusSuccessList: STATUS_REQUEST_LIST_REPORT_CAPITAL_CHANGE_SUCCESS,
    requestStatusFailedList: STATUS_REQUEST_LIST_REPORT_CAPITAL_CHANGE_FAILED,
    allowSearchParamsList: DEFAULT_REPORT_CAPITAL_CHANGE_LIST_PARAMS,
    defaultSearchParamsList: DEFAULT_REPORT_CAPITAL_CHANGE_SEARCH_FE_PARAMS,
    defaultParamsList: {},
    initialSearchParamsList: {
      from_date: null,
      to_date: null,
    },
    discardKeySearchParamsList: ['periode', 'balance_null'],
    isShowDateRangeInput: true,
    reportTitle: 'header.report-title-capital-change',
    reportLabelBottom: [
      ['', 'from_date'],
      ['', 'to_date'],
    ], // represent first item on 2d array is translation and continue with cache on search list
    separatorEachItemLabel: ' - ',
    showPrintButton: true,
    columnsTableList: (t) => {
      return [
        {
          name: 'account_name',
          label: t('table.header-column.account'),
          style: {
            minWidth: 300,
            width: 300,
            maxWidth: 350,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'begining_amount',
          label: t('table.header-column.beginning-balance'),
          style: {
            minWidth: 80,
            width: 80,
            maxWidth: 100,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          align: 'right',
        },
        {
          name: 'movement_debit_amount',
          label: t('table.header-column.debit'),
          style: {
            minWidth: 80,
            width: 80,
            maxWidth: 100,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          align: 'right',
        },
        {
          name: 'movement_credit_amount',
          label: t('table.header-column.credit'),
          style: {
            minWidth: 80,
            width: 80,
            maxWidth: 100,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          align: 'right',
        },
        {
          name: 'ending_amount',
          label: t('table.header-column.ending-balance'),
          style: {
            minWidth: 80,
            width: 80,
            maxWidth: 100,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          align: 'right',
        },
      ];
    },
    columnTableFooter: (t) => [
      [
        {
          align: 'left',
          label: t('typography.total'),
          colSpan: 1,
          styleParagraph: secondaryParagraph,
        },
        {
          specialValue: '{total_beginning_balance}',
          align: 'right',
          colSpan: 1,
          isUseSpecialValue: true,
          styleParagraph: secondaryParagraph,
        },
        {
          specialValue: '{total_movement_debit}',
          align: 'right',
          colSpan: 1,
          isUseSpecialValue: true,
          styleParagraph: secondaryParagraph,
        },
        {
          specialValue: '{total_movement_credit}',
          align: 'right',
          colSpan: 1,
          isUseSpecialValue: true,
          styleParagraph: secondaryParagraph,
        },
        {
          specialValue: '{total_ending_balance}',
          align: 'right',
          colSpan: 1,
          isUseSpecialValue: true,
          styleParagraph: secondaryParagraph,
        },
      ],
    ],
    isUseGenerateDataList: true,
    generateDataListFromChildrenSlice: (t, childrenSliceData) => {
      if (!childrenSliceData) return childrenSliceData;

      if (typeof childrenSliceData === 'object') {
        childrenSliceData = objHelper.filterKeyObj(
          childrenSliceData,
          unusedKeyNameChildrenReportCapitalChangeData,
        );

        childrenSliceData = Object.values(childrenSliceData);

        // combine account name with account code
        childrenSliceData = childrenSliceData.map((obj) => {
          const accountNameWithCode = formatHelp.formatAccountNameWithAccountCode(obj);

          obj = objHelper.changeFormatValueV2(
            obj,
            keyCurrencyType,
            '',
            formatHelp.currencyWithRegexOnBrackets,
            [keyCurrencyType, true],
          );

          return { ...obj, account_name: accountNameWithCode };
        });
      }

      return childrenSliceData;
    },
    isUseGenerateFooterData: true,
    generateFooterDataFromChildrenSlice: (childrenSliceData) => {
      if (!childrenSliceData) return childrenSliceData;

      let specialValue = {};

      if (typeof childrenSliceData === 'object') {
        childrenSliceData = objHelper.filterKeyObj(
          childrenSliceData,
          unusedKeyNameChildrenReportCapitalChangeData,
        );

        childrenSliceData = Object.values(childrenSliceData);

        let beginningBalanceColumn = arrHelp.mappingChildrenKeyOnObject(childrenSliceData, [
            'begining_amount',
          ]),
          movementDebitColumn = arrHelp.mappingChildrenKeyOnObject(childrenSliceData, [
            'movement_debit_amount',
          ]),
          movementCreditColumn = arrHelp.mappingChildrenKeyOnObject(childrenSliceData, [
            'movement_credit_amount',
          ]),
          endingBalanceColumn = arrHelp.mappingChildrenKeyOnObject(childrenSliceData, [
            'ending_amount',
          ]);

        beginningBalanceColumn = arrHelp.sumValueArr(beginningBalanceColumn);
        movementDebitColumn = arrHelp.sumValueArr(movementDebitColumn);
        movementCreditColumn = arrHelp.sumValueArr(movementCreditColumn);
        endingBalanceColumn = arrHelp.sumValueArr(endingBalanceColumn);

        specialValue = {
          ...specialValue,
          total_beginning_balance: beginningBalanceColumn,
          total_movement_debit: movementDebitColumn,
          total_movement_credit: movementCreditColumn,
          total_ending_balance: endingBalanceColumn,
        };

        specialValue = objHelper.changeFormatValueV2(
          specialValue,
          keyCurrencyType,
          '',
          formatHelp.currencyWithRegexOnBrackets,
          [keyCurrencyType, true],
        );
      }

      return specialValue;
    },
    additionalAmountHeaderRow: 7,
    additionalAmountFooterRow: 2,
    ...defaultReportXLSXprintConfigurationProps,
    worksheetName: worksheetXLSXname.DEFAULT_WORKSHEET_CAPITAL_CHANGE_REPORT_NAME,
  },
  [REPORT_TYPE_GENERAL_LEDGER]: {
    url: REACT_APP_API_URL_REPORT_GENERAL_LEDGER,
    parentSlice: NAME_STORE.REPORT,
    childrenSliceList: SLICE_NAME_REPORT_GENERAL_LEDGER,
    localStorageNameList: LOCAL_STORAGE_REPORT_GENERAL_LEDGER,
    uniqueIdentityIDList: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
    requestStatusLoadingList: STATUS_REQUEST_LIST_REPORT_GENERAL_LEDGER_PENDING,
    requestStatusSuccessList: STATUS_REQUEST_LIST_REPORT_GENERAL_LEDGER_SUCCESS,
    requestStatusFailedList: STATUS_REQUEST_LIST_REPORT_GENERAL_LEDGER_FAILED,
    allowSearchParamsList: DEFAULT_REPORT_GENERAL_LEDGER_LIST_PARAMS,
    defaultSearchParamsList: DEFAULT_REPORT_GENERAL_LEDGER_SEARCH_FE_PARAMS,
    defaultParamsList: {},
    initialSearchParamsList: {
      from_date: null,
      to_date: null,
      sort_by: {
        transaction_date: 'ASC',
      },
    },
    isShowDateRangeInput: true,
    reportTitle: 'header.general-ledger-report',
    reportLabelBottom: [
      ['', 'from_date'],
      ['', 'to_date'],
    ], // represent first item on 2d array is translation and continue with cache on search list
    separatorEachItemLabel: ' - ',
    showPrintButton: true,
    columnsTableList: (t) => columnsTableReportGeneralLedgerForTableRowPureMarkup(t),
    isUseGenerateDataList: true,
    generateDataListFromChildrenSlice: (t, childrenSliceData, siblingChildrenData) => {
      if (isEmpty(childrenSliceData) || !Array.isArray(childrenSliceData.data))
        return childrenSliceData;

      const totalAndGrandTotalProps = {
        styleParagraph: {
          ...tersierParagraph,
          textAlign: 'right',
        },
        additionalPropsTableDataBasedIndexColumn: Array(2)
          .fill(tableDataZeroColSpan)
          .concat({ colSpan: 3 })
          .concat(Array(3).fill(tableDataOneColSpan)),
      };

      childrenSliceData = omit(childrenSliceData.data, unusedKeyNamesReportGeneralLedger);

      let grandTotalGeneralLedgerReport = {
        credit_amount: 0,
        debit_amount: 0,
        balance: 0,
        transaction_description: t('table.row.grand-total'),
        ...totalAndGrandTotalProps,
        styleParagraph: {
          ...totalAndGrandTotalProps.styleParagraph,
          ...secondaryParagraph,
        },
      };

      if (!isEmpty(childrenSliceData)) {
        childrenSliceData = objHelper.changeObjTypeToArrBasedKeyName({
          obj: childrenSliceData,
          keyName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
          isOverrideOldKeyName: false,
        });

        const sumGrandTotalGeneralLedgerReport = objHelper.sumValueObjBasedKeyNamesV2({
          obj: { calculation: childrenSliceData },
          keyNamesTarget: ['calculation'],
          keyNamesSumValue: Object.keys(correspondKeynameForSubTotalOfGeneralLedgerReport),
          keyNamesResult: tableRowTotalAccountGeneralLedgerReport,
        });

        if (!isEmpty(sumGrandTotalGeneralLedgerReport)) {
          grandTotalGeneralLedgerReport = {
            ...grandTotalGeneralLedgerReport,
            credit_amount: sumGrandTotalGeneralLedgerReport.after_credit_amount,
            debit_amount: sumGrandTotalGeneralLedgerReport.after_debit_amount,
            balance: sumGrandTotalGeneralLedgerReport.after_total,
          };
        }

        const accountTransaction = !isEmpty(siblingChildrenData)
          ? siblingChildrenData['accountTransaction'] || {}
          : {};

        childrenSliceData = childrenSliceData.reduce(
          (allGeneralLegerReport, singleReportGeneralLedger) => {
            const accountID = singleReportGeneralLedger[DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF];
            const transactionNumber =
              formatHelp.formatAccountDataWithBracketsNUppercase(singleReportGeneralLedger);

            // title of general legder report
            allGeneralLegerReport = allGeneralLegerReport.concat({
              ...singleReportGeneralLedger,
              transaction_no: transactionNumber,
              styleParagraph: secondaryParagraph,
              additionalPropsTableDataBasedIndexColumn: Array(1)
                .fill(tableDataZeroColSpan)
                .concat([{ colSpan: 5 }])
                .concat(Array(3).fill(tableDataZeroColSpan))
                .concat(Array(1).fill(tableDataOneColSpan)),
              ...objHelper.changeKeyNameWithCorrespondKeyTarget({
                obj: singleReportGeneralLedger,
                correspondObj: correspondKeynameForTitleOfGeneralLedgerReport,
              }),
              // additionalStyleTableDataBasedIndexColumn: [
              //     {}, { borderWidth: '1px 0px 1px 1px',},
              //     {}, {}, {}, { borderWidth: '1px 1px 1px 0px' },
              // ]
            });

            let generalLedgerReportAccountTransaction = accountTransaction[accountID] || [];
            if (
              Array.isArray(generalLedgerReportAccountTransaction) &&
              !isEmpty(generalLedgerReportAccountTransaction)
            ) {
              allGeneralLegerReport = allGeneralLegerReport.concat(
                formulaHelpers.calculateMultipleAccountBalance({
                  parentAccount: singleReportGeneralLedger,
                  childrenAccount: generalLedgerReportAccountTransaction,
                }),
              );
            }

            // sub total general ledger report
            allGeneralLegerReport = allGeneralLegerReport.concat({
              transaction_description: t('table.row.total'),
              ...totalAndGrandTotalProps,
              ...objHelper.changeKeyNameWithCorrespondKeyTarget({
                obj: {
                  diffBalance: Number(singleReportGeneralLedger.after_total),
                  ...singleReportGeneralLedger,
                },
                correspondObj: correspondKeynameForSubTotalOfGeneralLedgerReport,
              }),
            });

            return allGeneralLegerReport;
          },
          [],
        );
      }

      grandTotalGeneralLedgerReport = {
        ...grandTotalGeneralLedgerReport,
        balance:
          Number(grandTotalGeneralLedgerReport.debit_amount).toFixed(2) -
          Number(grandTotalGeneralLedgerReport.credit_amount).toFixed(2),
      };

      if (!Array.isArray(childrenSliceData)) return [];

      return childrenSliceData.concat(grandTotalGeneralLedgerReport);
    },
    usageGetSiblingsData: true,
    actionsGetSiblingsData: [
      {
        parentSliceName: ROOT_STORE_NAME_ACCOUNT,
        keynameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
        childrenSliceName: SLICE_NAME_ACCOUNT_TRANSACTION,
        requestStatusLoading: STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_PENDING,
        requestStatusSuccess: STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_SUCCESS,
        requestStatusFailed: STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_FAILED,
        action: getAccountTransactionList,
        isRecursiveEachChildrenList: true,
        keynameStore: 'accountTransaction',
        keynameIdentity: 'account_id',
        keynameDependenciesFromChildrenData: 'account_id',
        usageSearchParamsFromChildrenData: true,
        searchParamsFromChildrenData: ['search_from_date', 'search_to_date'],
        additionalSearchParams: {
          sort_by: {
            transaction_date: ASC_SORT_TYPE_API_REF,
          },
          usagePagination: false,
        },
        generalizeChildrenDataBeforeStore: (childrenData) =>
          childrenData ? childrenData.data : [],
      },
    ],
    generalizeChildrenDataBeforeGetSiblingsData: (childrenSliceData) => {
      if (!childrenSliceData) return childrenSliceData;

      childrenSliceData = omit(childrenSliceData.data, unusedKeyNamesReportGeneralLedger);

      childrenSliceData = objHelper.changeObjTypeToArrBasedKeyName({
        obj: childrenSliceData,
        keyName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
        isOverrideOldKeyName: false,
      });

      return childrenSliceData;
    },
    additionalAmountHeaderRow: 7,
    additionalAmountFooterRow: 2,
    ...defaultReportXLSXprintConfigurationProps,
    worksheetName: worksheetXLSXname.DEFAULT_WORKSHEET_GENERAL_LEDGER_REPORT_NAME,
    colIndexDateFormats: [0],
  },
  [REPORT_TYPE_TRIAL_BALANCE]: {
    url: REACT_APP_API_URL_REPORT_TRIAL_BALANCE,
    parentSlice: NAME_STORE.REPORT,
    childrenSliceList: SLICE_NAME_REPORT_TRIAL_BALANCE,
    localStorageNameList: LOCAL_STORAGE_REPORT_TRIAL_BALANCE,
    uniqueIdentityIDList: 'account_id',
    requestStatusLoadingList: STATUS_REQUEST_LIST_REPORT_TRIAL_BALANCE_PENDING,
    requestStatusSuccessList: STATUS_REQUEST_LIST_REPORT_TRIAL_BALANCE_SUCCESS,
    requestStatusFailedList: STATUS_REQUEST_LIST_REPORT_TRIAL_BALANCE_FAILED,
    allowSearchParamsList: DEFAULT_REPORT_TRIAL_BALANCE_LIST_PARAMS,
    defaultSearchParamsList: DEFAULT_REPORT_TRIAL_BALANCE_SEARCH_FE_PARAMS,
    defaultParamsList: {},
    initialSearchParamsList: {
      from_date: null,
      to_date: null,
    },
    discardKeySearchParamsList: [],
    isShowDateRangeInput: true,
    reportTitle: 'header.report-title-trial-balance',
    reportLabelBottom: [
      ['', 'from_date'],
      ['', 'to_date'],
    ], // represent first item on 2d array is translation and continue with cache on search list
    separatorEachItemLabel: ' - ',
    showPrintButton: true,
    columnsTableList: (t) => {
      return [
        [
          {
            label: t('table.header-column.account'),
            style: {
              minWidth: 130,
              maxWidth: 140,
              verticalAlign: 'center',
            },
            rowSpan: 2,
          },
          {
            label: t('table.header-column.beginning-balance'),
            style: {
              minWidth: 110,
              maxWidth: 130,
              verticalAlign: 'top',
            },
            colSpan: 2,
            align: 'center',
          },
          {
            label: t('table.header-column.movement'),
            style: {
              minWidth: 110,
              maxWidth: 130,
              verticalAlign: 'top',
            },
            colSpan: 2,
            align: 'center',
          },
          {
            label: t('table.header-column.ending-balance'),
            style: {
              minWidth: 110,
              maxWidth: 130,
              verticalAlign: 'top',
            },
            colSpan: 2,
            align: 'center',
          },
        ],
        [
          {
            label: t('table.header-column.debit'),
            style: {
              minWidth: 80,
              maxWidth: 100,
            },
            colSpan: 1,
            align: 'right',
          },
          {
            label: t('table.header-column.credit'),
            style: {
              minWidth: 80,
              maxWidth: 100,
            },
            colSpan: 1,
            align: 'right',
          },
          {
            label: t('table.header-column.debit'),
            style: {
              minWidth: 80,
              maxWidth: 100,
            },
            colSpan: 1,
            align: 'right',
          },
          {
            label: t('table.header-column.credit'),
            style: {
              minWidth: 80,
              maxWidth: 100,
            },
            colSpan: 1,
            align: 'right',
          },
          {
            label: t('table.header-column.debit'),
            style: {
              minWidth: 80,
              maxWidth: 100,
            },
            colSpan: 1,
            align: 'right',
          },
          {
            label: t('table.header-column.credit'),
            style: {
              minWidth: 80,
              maxWidth: 100,
            },
            colSpan: 1,
            align: 'right',
          },
        ],
      ];
    },
    isUseMultipleTableHead: true,
    isUseDifferentTableBody: true,
    columnsTableBody: [
      {
        name: 'account_name',
        formatData: FORMAT_COMMON_COLUMN,
        isUseFormatStyleOnRow: true,
      },
      {
        name: 'before_debit_amount',
        formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
        substituteNotExistValue: '',
        align: 'right',
        isUseFormatStyleOnRow: true,
      },
      {
        name: 'before_credit_amount',
        formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
        substituteNotExistValue: '',
        align: 'right',
        isUseFormatStyleOnRow: true,
      },
      {
        name: 'movement_debit_amount',
        formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
        substituteNotExistValue: '',
        align: 'right',
        isUseFormatStyleOnRow: true,
      },
      {
        name: 'movement_credit_amount',
        formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
        substituteNotExistValue: '',
        align: 'right',
        isUseFormatStyleOnRow: true,
      },
      {
        name: 'after_debit_amount',
        formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
        substituteNotExistValue: '',
        align: 'right',
        isUseFormatStyleOnRow: true,
      },
      {
        name: 'after_credit_amount',
        formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
        substituteNotExistValue: '',
        align: 'right',
        isUseFormatStyleOnRow: true,
      },
    ],
    isUseGenerateDataList: true,
    generateDataListFromChildrenSlice: (t, childrenSliceData) => {
      if (!childrenSliceData) return childrenSliceData;

      childrenSliceData = objHelper.changeFormatKeyObj(childrenSliceData, (key) =>
        key.replace(/_/g, '-'),
      );

      // changes key name
      childrenSliceData = objHelper.changeKeyNameWithCorrespondKeyTarget({
        obj: childrenSliceData,
        correspondObj: correspondReportTrialBalanceAPIkeynameWithViewKeyName,
      });

      childrenSliceData = pick(childrenSliceData, allRowsTableReportTrialBalance);

      const tableRowTrialBalanceTranslate = rowsTableReportTrialBalanceTranslationRowV2(t);

      childrenSliceData = allRowsTableReportTrialBalance.reduce(
        (allReportTrialBalance, singleReportTrialBalance) => {
          const { translationRowName } = arrHelp.filterObjDataWithID(
            tableRowTrialBalanceTranslate,
            'rowName',
            singleReportTrialBalance,
          );

          let currValues = childrenSliceData[singleReportTrialBalance];

          if (typeof currValues === 'object') {
            const allKeysCurrValue = Object.values(currValues);

            allReportTrialBalance = allReportTrialBalance.concat({
              account_name: translationRowName,
              styleParagraph: secondaryParagraph,
              additionalPropsTableDataBasedIndexColumn: [{ colSpan: 7 }].concat(
                Array(6).fill(tableDataZeroColSpan),
              ),
            });

            for (let singleValue of allKeysCurrValue) {
              singleValue = objHelper.changeFormatKeyObj(singleValue, (key) =>
                key.replace(/-/g, '_'),
              );

              const rowName = formatHelp.formatAccountNameWithAccountCode(singleValue);
              const {
                before_debit_amount,
                before_credit_amount,
                movement_debit_amount,
                movement_credit_amount,
                after_debit_amount,
                after_credit_amount,
              } = singleValue;

              allReportTrialBalance = allReportTrialBalance.concat({
                account_name: rowName,
                before_debit_amount: before_debit_amount,
                before_credit_amount: before_credit_amount,
                movement_debit_amount: movement_debit_amount,
                movement_credit_amount: movement_credit_amount,
                after_debit_amount: after_debit_amount,
                after_credit_amount: after_credit_amount,
                useSpaceBar: true,
                spaceBarAmount: 4,
                styleParagraph: primaryParagraph,
              });
            }

            return allReportTrialBalance;
          }

          return allReportTrialBalance.concat({
            account_name: translationRowName,
            before_debit_amount: currValues || 0,
            before_credit_amount: currValues || 0,
            movement_debit_amount: currValues || 0,
            movement_credit_amount: currValues || 0,
            after_debit_amount: currValues || 0,
            after_credit_amount: currValues || 0,
            styleParagraph: secondaryParagraph,
          });
        },
        [],
      );

      return childrenSliceData;
    },
    columnTableFooter: (t) => [
      [
        {
          label: t('typography.total'),
          colSpan: 1,
          isUseSpecialValue: false,
          styleParagraph: secondaryParagraph,
        },
        {
          specialValue: '{total_before_debit_amount}',
          align: 'right',
          colSpan: 1,
          isUseSpecialValue: true,
          styleParagraph: secondaryParagraph,
        },
        {
          specialValue: '{total_before_credit_amount}',
          align: 'right',
          colSpan: 1,
          isUseSpecialValue: true,
          styleParagraph: secondaryParagraph,
        },
        {
          specialValue: '{total_movement_debit_amount}',
          align: 'right',
          colSpan: 1,
          isUseSpecialValue: true,
          styleParagraph: secondaryParagraph,
        },
        {
          specialValue: '{total_movement_credit_amount}',
          align: 'right',
          colSpan: 1,
          isUseSpecialValue: true,
          styleParagraph: secondaryParagraph,
        },
        {
          specialValue: '{total_after_debit_amount}',
          align: 'right',
          colSpan: 1,
          isUseSpecialValue: true,
          styleParagraph: secondaryParagraph,
        },
        {
          specialValue: '{total_after_credit_amount}',
          align: 'right',
          colSpan: 1,
          isUseSpecialValue: true,
          styleParagraph: secondaryParagraph,
        },
      ],
    ],
    isUseGenerateFooterData: true,
    generateFooterDataFromChildrenSlice: (childrenSliceData) => {
      if (!childrenSliceData) return childrenSliceData;

      // changes key name from API to usage on code
      childrenSliceData = objHelper.changeKeyNameWithCorrespondKeyTarget({
        obj: childrenSliceData,
        correspondObj: correspondReportTrialBalanceAPIkeynameWithViewKeyName,
      });

      childrenSliceData = objHelper.changeMultipleObjToArray({
        obj: childrenSliceData,
        multipleKeyNameTarget: allRowsTableReportTrialBalance,
        additionalParamsChanges: { keyName: 'account_id' },
      });

      childrenSliceData = pick(childrenSliceData, allRowsTableReportTrialBalance);

      // summing value of report trial balance per columns
      childrenSliceData = objHelper.sumValueObjBasedKeyNamesV2({
        obj: childrenSliceData,
        keyNamesTarget: allRowsTableReportTrialBalance,
        keyNamesSumValue: keysDataCalculationReportTrialBalanceV2,
        keyNamesResult: totalKeysReportTrialBalanceFromAPIv2,
      });

      return objHelper.changeFormatValue(childrenSliceData, totalKeysReportTrialBalanceFromAPIv2, [
        (value) => (value ? formatHelp.currencyWithAutoComma(Number(value)) : 0),
      ]);
    },
    additionalAmountHeaderRow: 7,
    additionalAmountFooterRow: 2,
    ...defaultReportXLSXprintConfigurationProps,
    worksheetName: worksheetXLSXname.DEFAULT_WORKSHEET_TRIAL_BALANCE_REPORT_NAME,
  },
  [REPORT_STOCK_SALES_TYPE]: {
    url: REACT_APP_API_URL_REPORT_STOCK_SALES,
    action: (params) =>
      getReportBasicList({
        reportBasicType: REPORT_STOCK_SALES_TYPE,
        isRewriteAll: true,
        ...params,
      }),
    parentSlice: NAME_STORE.REPORT,
    childrenSliceList: SLICE_NAME_REPORT_STOCK_SALES_LIST,
    localStorageNameList: LOCAL_STORAGE_REPORT_STOCK_SALES_LIST,
    uniqueIdentityIDList: 'sales_invoice_id',
    requestStatusLoadingList: STATUS_REQUEST_LIST_REPORT_STOCK_SALES_PENDING,
    requestStatusSuccessList: STATUS_REQUEST_LIST_REPORT_STOCK_SALES_SUCCESS,
    requestStatusFailedList: STATUS_REQUEST_LIST_REPORT_STOCK_SALES_FAILED,
    allowSearchParamsList: DEFAULT_ALLOW_PARAMS_REPORT_STOCK_SALES,
    defaultSearchParamsList: DEFAULT_SEARCH_PARAMS_REPORT_STOCK_SALES,
    defaultParamsList: {},
    usageGetListWhenFirstTimeAppear: true,
    initialSearchParamsList: {
      from_date: moment().startOf('M'),
      to_date: moment().endOf('M'),
      customer_id: '',
      customer_id_container: null,
      status: '',
      status_container: null,
      sales_invoice_no: '',
      sort_by: {
        transaction_date: 'ASC',
        sales_invoice_no: 'ASC',
      },
    },
    sortSearchParams: 'sort_by',
    isShowDateRangeInput: true,
    isShowAutocompleteContact: true,
    contactUniqueKey: 'customer_id',
    contactContainerUniqueKey: 'customer_id_container',
    contactPlaceholderForm: 'placeholder.contact-customer',
    additionalFetchContactList: {
      type: ['is_customer'],
    },
    isShowAutocompleteStatus: true,
    statusOptions: purchaseInvoiceChartBasicStatus,
    reportTitle: 'header.report-title-sales-product',
    reportLabelBottom: [
      ['', 'from_date'],
      ['', 'to_date'],
    ], // represent first item on 2d array is translation and continue with cache on search list
    separatorEachItemLabel: ' - ',
    showPrintButton: true,
    columnsTableList: (t) => {
      return [
        {
          name: 'transaction_date',
          label: t('table.header-column.date'),
          style: {
            minWidth: 50,
            width: 50,
            maxWidth: 75,
          },
          formatData: FORMAT_DATE_COLUMN,
        },
        {
          name: 'sales_invoice_no',
          label: t('table.header-column.transaction-number'),
          style: {
            minWidth: 80,
            width: 80,
            maxWidth: 100,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'product',
          label: t('table.header-column.product'),
          style: {
            minWidth: 125,
            width: 150,
            maxWidth: 160,
          },
          formatData: FORMAT_TEMPLATE_COLUMN,
          template: '{product_code} - {product_name}',
        },
        {
          name: 'description',
          label: t('table.header-column.description'),
          style: {
            minWidth: 125,
            width: 150,
            maxWidth: 160,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'customer_name',
          label: t('table.header-column.customer'),
          style: {
            minWidth: 125,
            width: 150,
            maxWidth: 160,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'quantity',
          label: t('table.header-column.qty'),
          style: {
            minWidth: 35,
            width: 50,
            maxWidth: 75,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          align: 'right',
        },
        {
          name: 'unit',
          label: t('table.header-column.unit'),
          style: {
            minWidth: 50,
            width: 75,
            maxWidth: 80,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'price',
          label: t('table.header-column.price'),
          style: {
            minWidth: 60,
            width: 80,
            maxWidth: 100,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          align: 'right',
        },
        {
          name: 'jumlah',
          label: t('table.header-column.amount'),
          style: {
            minWidth: 60,
            width: 80,
            maxWidth: 100,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          align: 'right',
        },
      ];
    },
    additionalAmountHeaderRow: 7,
    additionalAmountFooterRow: 2,
    ...defaultReportXLSXprintConfigurationProps,
    worksheetName: worksheetXLSXname.DEFAULT_WORKSHEET_STOCK_SALES_REPORT_NAME,
    colIndexDateFormats: [0],
  },
  [REPORT_STOCK_MUTATION_TYPE]: {
    url: REACT_APP_API_URL_REPORT_STOCK_MUTATION,
    useTemplateURLapi: true,
    action: (params) =>
      getReportBasicList({
        reportBasicType: REPORT_STOCK_MUTATION_TYPE,
        isRewriteAll: true,
        ...params,
      }),
    parentSlice: NAME_STORE.REPORT,
    childrenSliceList: SLICE_NAME_REPORT_STOCK_MUTATION_LIST,
    localStorageNameList: LOCAL_STORAGE_REPORT_STOCK_MUTATION_LIST,
    uniqueIdentityIDList: 'product_id',
    requestStatusLoadingList: STATUS_REQUEST_LIST_REPORT_STOCK_MUTATION_PENDING,
    requestStatusSuccessList: STATUS_REQUEST_LIST_REPORT_STOCK_MUTATION_SUCCESS,
    requestStatusFailedList: STATUS_REQUEST_LIST_REPORT_STOCK_MUTATION_FAILED,
    allowSearchParamsList: DEFAULT_ALLOW_PARAMS_REPORT_STOCK_MUTATION,
    defaultSearchParamsList: DEFAULT_SEARCH_PARAMS_REPORT_STOCK_MUTATION,
    defaultParamsList: {},
    additionalSearchParamsWhenChangeAutocomplete: {
      warehouse_id: ['warehouse_name'],
    },
    initialHitEndpoint: {
      warehouseList: {
        searchParams: {
          warehouse_type: ['main'],
        },
        typeGettingData: 'getDetailsOne',
        positionOnSearchParams: ['warehouse_id_container', 'warehouse_id', 'warehouse_name'],
        parentSliceName: NAME_STORE.WAREHOUSE,
        childrenSliceName: SLICE_NAME_SETTINGS_WAREHOUSE_DATA,
        requestStatusLoading: STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_PENDING,
      },
    },
    initialSearchParamsList: {
      warehouse_id: null,
      warehouse_id_container: null,
      from_date: moment().startOf('M'),
      to_date: moment().endOf('M'),
    },
    sortSearchParams: 'sort_by',
    isShowDateRangeInput: true,
    isShowAutocompleteContact: false,
    isShowAutocompleteWarehouse: true,
    contactUniqueKey: '',
    contactContainerUniqueKey: '',
    contactPlaceholderForm: '',
    additionalFetchContactList: {},
    isShowAutocompleteStatus: false,
    statusOptions: null,
    reportTitle: 'header.stock-mutation-report',
    reportLabelBottom: [
      ['', 'from_date'],
      ['', 'to_date'],
    ], // represent first item on 2d array is translation and continue with cache on search list
    separatorEachItemLabel: ' - ',
    isUseGenerateDataList: true,
    generateDataListFromChildrenSlice: (t, childrenSliceData) => {
      if (isEmpty(childrenSliceData)) return [];

      const { search_sort_by = null } = childrenSliceData;

      let isAscendingProduct = true,
        isDescendingExist = false;

      if (!isEmpty(search_sort_by)) {
        isAscendingProduct =
          search_sort_by.product_name === ASC_SORT_TYPE_API_REF ||
          search_sort_by.product_code === ASC_SORT_TYPE_API_REF;

        isDescendingExist =
          search_sort_by.product_name === DESC_SORT_TYPE_API_REF ||
          search_sort_by.product_code === DESC_SORT_TYPE_API_REF;
      }

      childrenSliceData = Object.keys(childrenSliceData).reduce((resChildrenSliceData, key) => {
        if (Number.isNaN(Number(key))) return resChildrenSliceData;

        return {
          ...resChildrenSliceData,
          [key]: childrenSliceData[key],
        };
      }, {});

      let generalizedChildrenSliceDataToArr = objHelper.changeObjTypeToArrBasedKeyName({
        obj: childrenSliceData,
        keyName: 'product_id',
        isOverrideOldKeyName: false,
      });

      // SORTING
      generalizedChildrenSliceDataToArr = Array.isArray(generalizedChildrenSliceDataToArr)
        ? generalizedChildrenSliceDataToArr.sort((a, b) => {
            if (a.product_name.toLowerCase() > b.product_name.toLowerCase())
              return isAscendingProduct && !isDescendingExist ? 1 : -1;
            else if (a.product_name.toLowerCase() < b.product_name.toLowerCase())
              return isAscendingProduct && !isDescendingExist ? -1 : 1;

            return 0;
          })
        : [];

      const stylingEachRowData = arrHelp
        .changesMultipleItemsWithInheritKeynameFromParentToChildren({
          arr: generalizedChildrenSliceDataToArr,
          passFunctionParams: {
            parentKeynames: ['mutation'],
            templateNewCombineKeyname: '{2}',
          },
        })
        .map((singleData, index) => {
          return {
            ...singleData,
            style: {
              background: index % 2 ? gray['B200'] : 'white',
              zIndex: 10,
            },
          };
        });

      return stylingEachRowData;
    },
    columnsTableList: (t, listChildrenSliceData) => {
      let additionalColumns = isEmpty(listChildrenSliceData)
        ? []
        : Object.keys(listChildrenSliceData[0]).filter(
            (key) => !Number.isNaN(new Date(key).getTime()),
          );

      if (!isEmpty(additionalColumns)) {
        additionalColumns = additionalColumns.map((column) => {
          return {
            name: column,
            label: column.split('-')[2],
            style: {
              padding: '4px',
              minWidth: '55px',
              width: 'auto',
            },
            styleParagraph: {
              wordBreak: 'unset',
            },
            align: 'center',
            formatData: FORMAT_COMMON_COLUMN,
            formatterData: (value) => {
              if (!value) return '0';

              return Number(value) > 0
                ? `+${formatHelp.currencyWithAutoComma(Number(value))}`
                : formatHelp.currencyWithAutoComma(Number(value));
            },
            isUseFormatStyleOnRow: true,
          };
        });
      } else {
        additionalColumns = Array(30)
          .fill()
          .map((_, index) => {
            return {
              label: index + 1,
              style: {
                padding: '4px',
                minWidth: '55px',
                width: 'auto',
              },
              align: 'center',
            };
          });
      }

      return [
        {
          name: 'product_code',
          label: t('table.header-column.code'),
          style: {
            position: 'sticky',
            left: '0px',
            padding: '4px',
            zIndex: 999,
            minWidth: 55,
            width: 60,
          },
          styleParagraph: {
            wordBreak: 'unset',
          },
          formatData: FORMAT_COMMON_COLUMN,
          isUseFormatStyleOnRow: true,
          usageSortAction: true,
          sortParentKeyname: 'sort_by',
          sortChildrenKeyname: 'product_code',
        },
        {
          name: 'product_name',
          label: t('table.header-column.product-name'),
          style: {
            position: 'sticky',
            left: '65px',
            zIndex: 999,
            padding: '4px',
            minWidth: 125,
            width: 130,
            maxWidth: 160,
          },
          formatData: FORMAT_COMMON_COLUMN,
          isUseFormatStyleOnRow: true,
          usageSortAction: true,
          sortParentKeyname: 'sort_by',
          sortChildrenKeyname: 'product_name',
        },
        {
          name: 'product_unit',
          label: t('table.header-column.unit'),
          style: {
            position: 'sticky',
            left: '200px',
            zIndex: 999,
            padding: '4px',
            minWidth: 40,
            width: 45,
          },
          styleParagraph: {
            wordBreak: 'unset',
          },
          formatData: FORMAT_COMMON_COLUMN,
          isUseFormatStyleOnRow: true,
        },
        {
          name: 'beginning',
          label: t('table.header-column.first-stock'),
          style: {
            position: 'sticky',
            left: '250px',
            zIndex: 999,
            padding: '4px',
            minWidth: 80,
            width: 85,
            maxWidth: 100,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          align: 'right',
          isUseFormatStyleOnRow: true,
        },
        ...additionalColumns,
        {
          name: 'ending',
          label: t('table.header-column.last-stock'),
          style: {
            padding: '4px',
            minWidth: 85,
            width: 80,
            maxWidth: 100,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          align: 'right',
          isUseFormatStyleOnRow: true,
        },
      ];
    },
    additionalAmountHeaderRow: 7,
    additionalAmountFooterRow: 2,
    showPrintButton: true,
    useGeneratePrintTable: true,
    generatePrintTable: (t, childrenSliceData) => {
      return {
        t,
        childrenSliceData,
      };
    },
    generalizePrintTableData: (t, childrenSliceData) => {
      if (isEmpty(childrenSliceData)) return [];

      const { search_sort_by = null } = childrenSliceData;

      let isAscendingProduct = true,
        isDescendingExist = false;

      if (!isEmpty(search_sort_by)) {
        isAscendingProduct =
          search_sort_by.product_name === ASC_SORT_TYPE_API_REF ||
          search_sort_by.product_code === ASC_SORT_TYPE_API_REF;

        isDescendingExist =
          search_sort_by.product_name === DESC_SORT_TYPE_API_REF ||
          search_sort_by.product_code === DESC_SORT_TYPE_API_REF;
      }

      childrenSliceData = Object.keys(childrenSliceData).reduce((resChildrenSliceData, key) => {
        if (Number.isNaN(Number(key))) return resChildrenSliceData;

        return {
          ...resChildrenSliceData,
          [key]: childrenSliceData[key],
        };
      }, {});

      let generalizedChildrenSliceDataToArr = objHelper.changeObjTypeToArrBasedKeyName({
        obj: childrenSliceData,
        keyName: 'product_id',
        isOverrideOldKeyName: false,
      });

      // SORTING
      generalizedChildrenSliceDataToArr = Array.isArray(generalizedChildrenSliceDataToArr)
        ? generalizedChildrenSliceDataToArr.sort((a, b) => {
            if (a.product_name.toLowerCase() > b.product_name.toLowerCase())
              return isAscendingProduct && !isDescendingExist ? 1 : -1;
            else if (a.product_name.toLowerCase() < b.product_name.toLowerCase())
              return isAscendingProduct && !isDescendingExist ? -1 : 1;

            return 0;
          })
        : [];

      const overridingMutation = arrHelp.changesMultipleItemsWithInheritKeynameFromParentToChildren(
        {
          arr: generalizedChildrenSliceDataToArr,
          passFunctionParams: {
            parentKeynames: ['mutation'],
            templateNewCombineKeyname: '{2}',
          },
        },
      );

      const splittingIntoToRow = overridingMutation.reduce((result, current) => {
        const secondRow = omit(cloneDeep(current), [
          'beginning',
          'ending',
          'product_code',
          'product_id',
          'product_name',
          'product_unit',
        ]);

        const keysSecondRow = Object.keys(secondRow).sort();

        const { beginning, ending, product_code, product_name, product_unit } = current;

        return result.concat([
          {
            additionalPropsTableDataBasedIndexColumn: [
              {
                align: 'left',
                colSpan: Math.floor(keysSecondRow.length / 2) + 1,
              },
              ...Array(Math.floor(keysSecondRow.length / 2) - 1).fill({
                colSpan: 0,
              }),
              {
                align: 'left',
                colSpan: Math.floor(keysSecondRow.length / 2) - 1,
              },
              ...Array(Math.floor(keysSecondRow.length / 2)).fill({
                colSpan: 0,
              }),
            ],
            additionalStyleTableDataBasedIndexColumn: [
              {
                textAlign: 'left!important',
              },
              ...Array(Math.floor(keysSecondRow.length / 2) - 1).fill({
                colSpan: 0,
              }),
              {
                textAlign: 'left!important',
              },
            ],
            [keysSecondRow[0]]: `
                            ${product_code} ${product_name} (${product_unit})
                            ${t('typography.beginning')}: ${formatHelp.currencyWithAutoComma(
              Number(beginning),
            )}
                            ${t('typography.end')}: ${formatHelp.currencyWithAutoComma(
              Number(ending),
            )}
                        `,
            [keysSecondRow[15]]: `
                            ${product_code} ${product_name} (${product_unit})
                            ${t('typography.beginning')}: ${formatHelp.currencyWithAutoComma(
              Number(beginning),
            )}
                            ${t('typography.end')}: ${formatHelp.currencyWithAutoComma(
              Number(ending),
            )}
                        `,
          },
          {
            ...omit(current, [
              'beginning',
              'ending',
              'product_code',
              'product_id',
              'product_name',
              'product_unit',
            ]),
          },
        ]);
      }, []);

      return splittingIntoToRow;
    },
    columnsPrintTableList: (t, listChildrenSliceData) => {
      let additionalColumns = isEmpty(listChildrenSliceData)
        ? []
        : Object.keys(
            omit(listChildrenSliceData[1], [
              'beginning',
              'ending',
              'product_code',
              'product_id',
              'product_name',
              'product_unit',
              'style',
            ]),
          );

      if (!isEmpty(additionalColumns)) {
        additionalColumns = additionalColumns.map((column) => {
          return {
            name: column,
            label: column.split('-')[2],
            style: {
              padding: '4px',
              minWidth: '55px',
              width: 'auto',
            },
            styleParagraph: {
              wordBreak: 'unset',
            },
            align: 'center',
            isUseFormatStyleOnRow: true,
            formatData: FORMAT_COMMON_COLUMN,
            formatterData: (value) => {
              if (Number.isNaN(Number(value))) return value;

              if (!value) return '0';

              return Number(value) > 0
                ? `+${formatHelp.currencyWithAutoComma(Number(value))}`
                : formatHelp.currencyWithAutoComma(Number(value));
            },
          };
        });

        return additionalColumns;
      } else {
        additionalColumns = Array(30)
          .fill()
          .map((_, index) => {
            return {
              label: index + 1,
              style: {
                padding: '4px',
                minWidth: '55px',
                width: 'auto',
              },
              align: 'center',
            };
          });
      }

      return [
        {
          name: 'product_code',
          label: t('table.header-column.code'),
          style: {
            padding: '4px',
            minWidth: 25,
          },
          styleParagraph: {
            wordBreak: 'unset',
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'product_name',
          label: t('table.header-column.product-name'),
          style: {
            padding: '4px',
            minWidth: 125,
            width: 150,
            maxWidth: 160,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'product_unit',
          label: t('table.header-column.unit'),
          style: {
            padding: '4px',
            minWidth: 10,
          },
          styleParagraph: {
            wordBreak: 'unset',
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'beginning',
          label: t('table.header-column.first-stock'),
          style: {
            padding: '4px',
            minWidth: 60,
            width: 80,
            maxWidth: 100,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          align: 'right',
        },
        ...additionalColumns,
        {
          name: 'ending',
          label: t('table.header-column.last-stock'),
          style: {
            padding: '4px',
            minWidth: 60,
            width: 80,
            maxWidth: 100,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          align: 'right',
        },
      ];
    },
    ...defaultReportXLSXprintConfigurationProps,
    worksheetName: worksheetXLSXname.DEFAULT_WORKSHEET_STOCK_MUTATION_REPORT_NAME,
  },
  [REPORT_STOCK_PURCHASE_TYPE]: {
    url: REACT_APP_API_URL_REPORT_STOCK_PURCHASE,
    action: (params) =>
      getReportBasicList({
        reportBasicType: REPORT_STOCK_PURCHASE_TYPE,
        isRewriteAll: true,
        ...params,
      }),
    parentSlice: NAME_STORE.REPORT,
    childrenSliceList: SLICE_NAME_REPORT_STOCK_PURCHASE_LIST,
    localStorageNameList: LOCAL_STORAGE_REPORT_STOCK_PURCHASE_LIST,
    uniqueIdentityIDList: 'purchase_invoice_id',
    requestStatusLoadingList: STATUS_REQUEST_LIST_REPORT_STOCK_PURCHASE_PENDING,
    requestStatusSuccessList: STATUS_REQUEST_LIST_REPORT_STOCK_PURCHASE_SUCCESS,
    requestStatusFailedList: STATUS_REQUEST_LIST_REPORT_STOCK_PURCHASE_FAILED,
    allowSearchParamsList: DEFAULT_ALLOW_PARAMS_REPORT_STOCK_PURCHASE,
    defaultSearchParamsList: DEFAULT_SEARCH_PARAMS_REPORT_STOCK_PURCHASE,
    defaultParamsList: {},
    usageGetListWhenFirstTimeAppear: true,
    initialSearchParamsList: {
      from_date: moment().startOf('M'),
      to_date: moment().endOf('M'),
      supplier_id: '',
      supplier_id_container: null,
      status: '',
      status_container: null,
      purchase_invoice_no: '',
      sort_by: {
        transaction_date: 'ASC',
        purchase_invoice_no: 'ASC',
      },
    },
    sortSearchParams: 'sort_by',
    isShowDateRangeInput: true,
    isShowAutocompleteContact: true,
    contactUniqueKey: 'supplier_id',
    contactContainerUniqueKey: 'supplier_id_container',
    contactPlaceholderForm: 'placeholder.contact-supplier',
    additionalFetchContactList: {
      type: ['is_customer'],
    },
    isShowAutocompleteStatus: true,
    statusOptions: purchaseInvoiceChartBasicStatus,
    reportTitle: 'header.report-title-purchase-product',
    reportLabelBottom: [
      ['', 'from_date'],
      ['', 'to_date'],
    ], // represent first item on 2d array is translation and continue with cache on search list
    separatorEachItemLabel: ' - ',
    showPrintButton: true,
    columnsTableList: (t) => {
      return [
        {
          name: 'transaction_date',
          label: t('table.header-column.date'),
          style: {
            minWidth: 50,
            width: 50,
            maxWidth: 75,
          },
          formatData: FORMAT_DATE_COLUMN,
        },
        {
          name: 'purchase_invoice_no',
          label: t('table.header-column.transaction-number'),
          style: {
            minWidth: 80,
            width: 80,
            maxWidth: 100,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'product',
          label: t('table.header-column.product'),
          style: {
            minWidth: 125,
            width: 150,
            maxWidth: 160,
          },
          formatData: FORMAT_TEMPLATE_COLUMN,
          template: '{product_code} - {product_name}',
        },
        {
          name: 'description',
          label: t('table.header-column.description'),
          style: {
            minWidth: 125,
            width: 150,
            maxWidth: 160,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'supplier_name',
          label: t('table.header-column.supplier'),
          style: {
            minWidth: 125,
            width: 150,
            maxWidth: 160,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'quantity',
          label: t('table.header-column.qty'),
          style: {
            minWidth: 35,
            width: 50,
            maxWidth: 75,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          align: 'right',
        },
        {
          name: 'unit',
          label: t('table.header-column.unit'),
          style: {
            minWidth: 50,
            width: 75,
            maxWidth: 80,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'price',
          label: t('table.header-column.price'),
          style: {
            minWidth: 60,
            width: 80,
            maxWidth: 100,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          align: 'right',
        },
        {
          name: 'jumlah',
          label: t('table.header-column.amount'),
          style: {
            minWidth: 60,
            width: 80,
            maxWidth: 100,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          align: 'right',
        },
      ];
    },
    additionalAmountHeaderRow: 7,
    additionalAmountFooterRow: 2,
    ...defaultReportXLSXprintConfigurationProps,
    worksheetName: worksheetXLSXname.DEFAULT_WORKSHEET_STOCK_PURCHASE_REPORT_NAME,
    colIndexDateFormats: [0],
  },
  [REPORT_STOCK_ADJUSTMENT_TYPE]: {
    url: REACT_APP_API_URL_REPORT_STOCK_ADJUSTMENT,
    action: (params) =>
      getReportBasicList({
        reportBasicType: REPORT_STOCK_ADJUSTMENT_TYPE,
        isRewriteAll: true,
        ...params,
      }),
    parentSlice: NAME_STORE.REPORT,
    childrenSliceList: SLICE_NAME_REPORT_STOCK_ADJUSTMENT_LIST,
    localStorageNameList: LOCAL_STORAGE_REPORT_STOCK_ADJUSTMENT_LIST,
    uniqueIdentityIDList: 'stock_adjustment_id',
    requestStatusLoadingList: STATUS_REQUEST_LIST_REPORT_STOCK_ADJUSTMENT_PENDING,
    requestStatusSuccessList: STATUS_REQUEST_LIST_REPORT_STOCK_ADJUSTMENT_SUCCESS,
    requestStatusFailedList: STATUS_REQUEST_LIST_REPORT_STOCK_ADJUSTMENT_FAILED,
    allowSearchParamsList: DEFAULT_ALLOW_PARAMS_REPORT_STOCK_ADJUSTMENT,
    defaultSearchParamsList: DEFAULT_SEARCH_PARAMS_REPORT_STOCK_ADJUSTMENT,
    defaultParamsList: {},
    usageGetListWhenFirstTimeAppear: true,
    initialSearchParamsList: {
      from_date: moment().startOf('M'),
      to_date: moment().endOf('M'),
    },
    sortSearchParams: '',
    isShowDateRangeInput: true,
    isShowAutocompleteContact: false,
    contactUniqueKey: '',
    contactContainerUniqueKey: '',
    contactPlaceholderForm: '',
    additionalFetchContactList: {},
    isShowAutocompleteStatus: false,
    statusOptions: [],
    reportTitle: 'header.stock-adjustment-report',
    reportLabelBottom: [
      ['', 'from_date'],
      ['', 'to_date'],
    ], // represent first item on 2d array is translation and continue with cache on search list
    separatorEachItemLabel: ' - ',
    showPrintButton: true,
    isUseGenerateDataList: true,
    generateDataListFromChildrenSlice: (t, childrenSliceData) => {
      if (isEmpty(childrenSliceData) || isEmpty(childrenSliceData.data)) return [];

      const { data } = childrenSliceData;
      if (!Array.isArray(data)) return [];

      return data.reduce((result, currData) => {
        const { transaction_date, description, stock_adjustment_no, warehouse_name, product } =
          currData;

        const descriptionText = strHelp.templateString('{0}: {1}', [
          t('typography.description'),
          description || '',
        ]);
        const transactionDate = transaction_date ? transaction_date.split(' ')[0] : '';

        const warehouseText = strHelp.templateString('({0}: {1})', [
          t('typography.warehouse'),
          warehouse_name || '',
        ]);

        return result.concat([
          {
            additionalPropsTableDataBasedIndexColumn: [
              {
                align: 'left',
                colSpan: 9,
              },
              ...Array(8).fill({
                colSpan: 0,
              }),
            ],
            ...currData,
            product_code: `${transactionDate} ${stock_adjustment_no} ${warehouseText}`,
            renderElement: (datum) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={primaryParagraph}>{transactionDate}&nbsp;&nbsp;</span>
                  <a
                    href={strHelp.templateString(
                      REACT_APP_DATA_STOCK_ADJUSTMENT_DETAILS_TEMPLATE_URL,
                      datum,
                    )}
                    style={primayAnchorTag}
                  >
                    {stock_adjustment_no}
                  </a>
                  <span style={primaryParagraph}>&nbsp;&nbsp;{warehouseText}</span>
                </div>
              );
            },
          },
          ...product,
          {
            additionalPropsTableDataBasedIndexColumn: [
              {
                align: 'left',
                colSpan: 9,
              },
              ...Array(8).fill({
                colSpan: 0,
              }),
            ],
            product_code: descriptionText,
            style: {
              background: gray['B200'],
            },
          },
        ]);
      }, []);

      // childrenSliceData = omit(
      //     childrenSliceData,
      //     [
      //         'data',
      //         'search_reportBasicType',
      //         ...DEFAULT_SEARCH_PARAMS_REPORT_STOCK_CARD,
      //     ]
      // )

      // const generalizedChildrenSliceDataToArr = objHelper.changeObjTypeToArrBasedKeyName({
      //     obj: childrenSliceData,
      //     keyName: 'product_id',
      //     isOverrideOldKeyName: false,
      // })

      // return arrHelp.changesMultipleItemsWithInheritKeynameFromParentToChildren({
      //     arr: generalizedChildrenSliceDataToArr,
      //     passFunctionParams: {
      //         parentKeynames: ['mutation'],
      //         templateNewCombineKeyname: '{2}',
      //     },
      // })
    },
    isUseMultipleTableHead: true,
    columnsTableList: (t) => {
      return [
        [
          {
            label: t('table.header-column.code'),
            style: {
              minWidth: 25,
              maxWidth: 50,
              verticalAlign: 'center',
            },
            rowSpan: 2,
          },
          {
            label: t('table.header-column.name'),
            style: {
              minWidth: 130,
              maxWidth: 140,
              verticalAlign: 'center',
            },
            rowSpan: 2,
          },
          {
            label: t('table.header-column.unit'),
            style: {
              minWidth: 25,
              maxWidth: 50,
              verticalAlign: 'center',
            },
            rowSpan: 2,
          },
          {
            label: t('table.header-column.initial-inventory'),
            style: {
              minWidth: 55,
              maxWidth: 65,
              verticalAlign: 'top',
            },
            colSpan: 2,
            align: 'center',
          },
          {
            label: t('table.header-column.difference'),
            style: {
              minWidth: 55,
              maxWidth: 65,
              verticalAlign: 'top',
            },
            colSpan: 2,
            align: 'center',
          },
          {
            label: t('table.header-column.final-inventory'),
            style: {
              minWidth: 55,
              maxWidth: 65,
              verticalAlign: 'top',
            },
            colSpan: 2,
            align: 'center',
          },
        ],
        [
          {
            label: t('table.header-column.qty'),
            style: {
              minWidth: 30,
              maxWidth: 35,
            },
            colSpan: 1,
            align: 'right',
          },
          {
            label: t('table.header-column.value'),
            style: {
              minWidth: 25,
              maxWidth: 30,
            },
            colSpan: 1,
            align: 'right',
          },
          {
            label: t('table.header-column.qty'),
            style: {
              minWidth: 30,
              maxWidth: 35,
            },
            colSpan: 1,
            align: 'right',
          },
          {
            label: t('table.header-column.value'),
            style: {
              minWidth: 25,
              maxWidth: 30,
            },
            colSpan: 1,
            align: 'right',
          },
          {
            label: t('table.header-column.qty'),
            style: {
              minWidth: 30,
              maxWidth: 35,
            },
            colSpan: 1,
            align: 'right',
          },
          {
            label: t('table.header-column.value'),
            style: {
              minWidth: 25,
              maxWidth: 30,
            },
            colSpan: 1,
            align: 'right',
          },
        ],
      ];
    },
    isUseDifferentTableBody: true,
    columnsTableBody: [
      {
        name: 'product_code',
        formatData: FORMAT_COMMON_COLUMN,
        isUseFormatStyleOnRow: true,
        useRenderingEachRow: true,
      },
      {
        name: 'product_name',
        formatData: FORMAT_COMMON_COLUMN,
        isUseFormatStyleOnRow: true,
      },
      {
        name: 'unit',
        formatData: FORMAT_COMMON_COLUMN,
        isUseFormatStyleOnRow: true,
      },
      {
        name: 'initial_quantity',
        formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
        substituteNotExistValue: '',
        align: 'right',
        isUseFormatStyleOnRow: true,
      },
      {
        name: 'initial_price',
        formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
        substituteNotExistValue: '',
        align: 'right',
        isUseFormatStyleOnRow: true,
      },
      {
        name: 'difference_quantity',
        formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
        substituteNotExistValue: '',
        align: 'right',
        isUseFormatStyleOnRow: true,
      },
      {
        name: 'difference_price',
        formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
        substituteNotExistValue: '',
        align: 'right',
        isUseFormatStyleOnRow: true,
      },
      {
        name: 'final_quantity',
        formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
        substituteNotExistValue: '',
        align: 'right',
        isUseFormatStyleOnRow: true,
      },
      {
        name: 'final_price',
        formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
        substituteNotExistValue: '',
        align: 'right',
        isUseFormatStyleOnRow: true,
      },
    ],
    additionalAmountHeaderRow: 7,
    additionalAmountFooterRow: 2,
    ...defaultReportXLSXprintConfigurationProps,
    worksheetName: worksheetXLSXname.DEFAULT_WORKSHEET_STOCK_ADJUSTMENT_REPORT_NAME,
  },
  [REPORT_STOCK_CARD_TYPE]: {
    url: REACT_APP_API_URL_REPORT_STOCK_CARD,
    useTemplateURLapi: true,
    action: (params) => {
      const productName = !isEmpty(params.product_id_container)
        ? params.product_id_container.product_name
        : '';

      return getReportBasicList({
        reportBasicType: REPORT_STOCK_CARD_TYPE,
        isRewriteAll: true,
        productName,
        ...params,
      });
    },
    parentSlice: ROOT_STORE_NAME_REPORT,
    childrenSliceList: SLICE_NAME_REPORT_STOCK_CARD_LIST,
    localStorageNameList: LOCAL_STORAGE_REPORT_STOCK_CARD_LIST,
    uniqueIdentityIDList: 'module_id',
    requestStatusLoadingList: STATUS_REQUEST_LIST_REPORT_STOCK_CARD_PENDING,
    requestStatusSuccessList: STATUS_REQUEST_LIST_REPORT_STOCK_CARD_SUCCESS,
    requestStatusFailedList: STATUS_REQUEST_LIST_REPORT_STOCK_CARD_FAILED,
    allowSearchParamsList: DEFAULT_ALLOW_PARAMS_REPORT_STOCK_CARD,
    defaultSearchParamsList: DEFAULT_SEARCH_PARAMS_REPORT_STOCK_CARD,
    defaultParamsList: {},
    usageGetListWhenFirstTimeAppear: false,
    initialSearchParamsList: {
      from_date: moment().startOf('M'),
      to_date: moment().endOf('M'),
      product_id: '',
      product_id_container: null,
    },
    sortSearchParams: '',
    isShowDateRangeInput: false,
    isShowAutocompleteContact: false,
    contactUniqueKey: '',
    contactContainerUniqueKey: '',
    contactPlaceholderForm: '',
    additionalFetchContactList: {},
    isShowAutocompleteStatus: false,
    statusOptions: null,
    reportTitle: 'header.stock-card-report',
    titleTemplate: '{reportTitle} {productName}',
    reportLabelBottom: [
      ['', 'from_date'],
      ['', 'to_date'],
    ], // represent first item on 2d array is translation and continue with cache on search list
    separatorEachItemLabel: ' - ',
    showPrintButton: true,
    columnsTableList: (t) => {
      return [
        {
          name: 'date',
          label: t('table.header-column.date'),
          style: {
            minWidth: 50,
            width: 50,
            maxWidth: 75,
          },
          formatData: FORMAT_DATE_COLUMN,
        },
        {
          name: 'module_description',
          label: t('table.header-column.transaction-number'),
          style: {
            minWidth: 80,
            width: 80,
            maxWidth: 100,
          },
          formatData: FORMAT_COMMON_COLUMN,
        },
        {
          name: 'module_name',
          label: t('table.header-column.transaction'),
          style: {
            minWidth: 125,
            width: 150,
            maxWidth: 160,
          },
          formatData: FORMAT_MAPPING_TRANSLATION_COLUMN,
          mappingTranslation: transactionTypeWithTranslation,
        },
        {
          name: 'quantity',
          label: t('table.header-column.in'),
          style: {
            minWidth: 35,
            width: 50,
            maxWidth: 75,
          },
          formatData: FORMAT_CALCULATION_MANUAL_COLUMN,
          align: 'right',
          paramsFormatterCalculationManual: ['module_type', 'quantity'],
          formatterData: (datum) => {
            if (datum.module_type !== 'in') return '0';

            return formatHelp.wrapperCurrencyWithAutoCommaV1(datum.quantity);
          },
          isUseZeroNumberWhenNull: true,
        },
        {
          name: 'quantity',
          label: t('table.header-column.out'),
          style: {
            minWidth: 50,
            width: 75,
            maxWidth: 80,
          },
          formatData: FORMAT_CALCULATION_MANUAL_COLUMN,
          align: 'right',
          paramsFormatterCalculationManual: ['module_type', 'quantity'],
          formatterData: (datum) => {
            if (datum.module_type !== 'out') return '0';

            return formatHelp.wrapperCurrencyWithAutoCommaV1(datum.quantity);
          },
          isUseZeroNumberWhenNull: true,
        },
        {
          name: 'current_total_quantity',
          label: t('table.header-column.stock'),
          style: {
            minWidth: 60,
            width: 80,
            maxWidth: 100,
          },
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          align: 'right',
        },
      ];
    },
    additionalAmountHeaderRow: 7,
    additionalAmountFooterRow: 2,
    printMode: 'portrait',
    ...defaultReportXLSXprintConfigurationProps,
    worksheetName: worksheetXLSXname.DEFAULT_WORKSHEET_STOCK_CARD_REPORT_NAME,
    colIndexDateFormats: [0],
  },
};

// get configuration on report basic with only report basic type
export function getReportBasicConfiguration(reportBasicType = REPORT_BASIC_TYPE_PURCHASE) {
  if (!reportBasicTypes.includes(reportBasicType))
    return reportBasicConfiguration[REPORT_BASIC_TYPE_PURCHASE];

  return reportBasicConfiguration[reportBasicType];
}
