import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import { DEFAULT_DISCOUNT_PERCENTAGE_TYPE } from '../../default/discount-type.default';
import {
  hasImplementedUnitProductUnit,
  keynamesImpelementProductUnit,
} from '../../default/unit-product.default';
import validation from '../../helpers/validation.helpers';
import { DEFAULT_CALCULATION_NUMBER } from '../default/number.default';

const initialID = uuidV4();

// minAccountingPeriod is accounting period on the current company user, that minimum input for user on transaction date
export const plainSalesOrderDataWithoutID = {
  customer_id: null,
  customer_id_container: '',
  customer_name: null,
  customer_address: '',
  transaction_date: moment(),
  expired_date: null,
  reference_number: '',
  reference_number_container: null,
  warehouse_id: null,
  warehouse_id_container: '',
  sales_quotation_id: null,
  sales_quotation_id_container: null,
  amount: DEFAULT_CALCULATION_NUMBER,
  amount_const: 0,
  description: '',
  minAccountingPeriod: null,
  accounting_period: null,
  sales_agent_id: '',
  sales_agent_id_container: '',
  sales_agent_name: '',
  discount_account_id: '',
  discount_account_id_container: '',
  discount_type: DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
  discount_percentage: '',
  discount_nominal: '',
  expense_account_id: '',
  expense_account_id_container: '',
  expense_value: '',
  down_payment_account_id: '',
  down_payment_account_id_container: '',
  down_payment_value: '',
  attachment: [],
  uuid_attachment: null,
  minDateInputRef: null,
  tag: [],
  tagContainer: [],
  tagNameInput: '',
};

export const plainSalesOrderValidationWithoutID = {
  customer_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  customer_address: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  transaction_date: {
    error: false,
    isValid: (value, minDateInputRef) => {
      if (!minDateInputRef) {
        return validation.notnull(value);
      }

      return moment(new Date(minDateInputRef)).isSameOrBefore(new Date(value), 'D');
    },
  },
  expired_date: {
    error: false,
    isValid: (value, transaction_date) => {
      if (!value || !transaction_date) {
        return validation.nullable(value);
      }

      return moment(new Date(transaction_date)).isBefore(new Date(value), 'D');
    },
  },
  reference_number: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  sales_quotation_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

/**
 * explain different on quantity
 * quantity is input user from form input
 *   quantity represent value for viewing user
 * quantity_origin is value that after changning on product unit
 * quantity_const is maximum input from user
 * quantity comparison, comparison from quantity with quantity origin
 * quantity_comparison_const_props, appear for update action,
 *  usage: preserve quantity comparison data
 *
 * unit_origin is value for represent unit before changing to new unit
 * unit_destination is value unit after change to new unit, this to view for user
 */
export const plainProductSalesOrderDataWithoutID = {
  product_id: null,
  product_id_container: null,
  product_description: '',
  quantity: '',
  quantity_origin: '',
  quantity_const: '',
  quantity_comparison: 1,
  quantity_comparison_const_props: null,
  quantity_available_with_unit: '',
  unit: null,
  unit_origin: '',
  unit_destination: '',
  amount: DEFAULT_CALCULATION_NUMBER,
  price: '',
};

export const plainProductSalesOrderValidationWithoutID = {
  product_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  product_description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  quantity: {
    error: false,
    isValid: (value) => validation.isNumberGreaterOrEqualThan(value, 1),
  },
  unit: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  unit_destination: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  amount: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  price: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const initialSalesOrderData = (newID = '', additionalID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainSalesOrderDataWithoutID,
    transaction_date: moment(),
  };
};

export const initialSalesOrderValidation = (newID = '', additionalID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainSalesOrderValidationWithoutID,
  };
};

export const initialProductSalesOrderData = (newID = '', additionalID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainProductSalesOrderDataWithoutID,
  };
};

export const initialProductSalesOrderValidation = (newID = '', additionalID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainProductSalesOrderValidationWithoutID,
  };
};

export const allowKeysSalesOrderData = [
  'customer_id',
  'customer_address',
  'customer_name',
  'transaction_date',
  'expired_date',
  'reference_number',
  'sales_quotation_id',
  'description',
  'warehouse_id',
  'sales_agent_id',
  'sales_agent_name',
  'discount_account_id',
  'discount_type',
  'discount_percentage',
  'discount_nominal',
  'expense_account_id',
  'expense_value',
  'down_payment_account_id',
  'down_payment_value',
  'uuid_attachment',
  'tag',
];

export const allowKeysProductSalesOrderData = [
  'product_id',
  'product_description',
  'quantity',
  'quantity',
  'quantity_origin',
  'unit_origin',
  'unit_destination',
  'price',
].filter((key) =>
  !hasImplementedUnitProductUnit ? !keynamesImpelementProductUnit.includes(key) : true,
);

export const allowKeysSalesOrderDataForUpdate = [
  // 'amount',
  // 'amount_available',
  'sales_order_id',
  'customer_id',
  'customer_address',
  'customer_name',
  'transaction_date',
  'expired_date',
  'reference_number',
  'sales_quotation_id',
  'description',
  'warehouse_id',
  'sales_agent_id',
  'sales_agent_name',
  'discount_account_id',
  'discount_type',
  'discount_percentage',
  'discount_nominal',
  'expense_account_id',
  'expense_value',
  'down_payment_account_id',
  'down_payment_value',
  'uuid_attachment',
];

export const allowKeysProductSalesOrderDataForUpdate = [
  'sales_order_product_id',
  'product_id',
  'product_description',
  'quantity',
  'quantity_origin',
  'unit_origin',
  'unit_destination',
  'price',
].filter((key) =>
  !hasImplementedUnitProductUnit ? !keynamesImpelementProductUnit.includes(key) : true,
);

export const relationalAdditionalCostValueNaccountForSalesOrder = {
  discount_percentage: ['discount_account_id', 'discount_type'],
  expense_value: 'expense_account_id',
  down_payment_value: 'down_payment_account_id',
};

export const salesOrderCalculationNumberKeyNames = [
  'discount_percentage',
  'discount_nominal',
  'discount_percentage',
  'expense_value',
  'down_payment_value',
  'amount',
];

export const productSalesOrderCalculationNumberKeyNames = ['quantity', 'price', 'amount'];
