import { isEmpty, omit, pick } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_DISCOUNT_NOMINAL_TYPE } from '../../default/discount-type.default';
import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE,
  DEFAULT_KEY_NAME_FILE_PROPERTIES,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_PRODUCT_API,
  DEFAULT_KEY_NAME_UUID_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import arrHelp from '../../helpers/array.helpers';
import formulaHelpers from '../../helpers/formula.helpers';
import objHelper from '../../helpers/object.helper';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import { productSalesInvoiceCalculationNumberKeyNames } from './initial-data-sales-invoice';
import {
  allowKeysDataProductSalesReturn,
  allowKeysDataSalesReturn,
  initialDataProductSalesReturn,
  initialDataSalesReturn,
  initialValidationProductSalesReturn,
  initialValidationSalesReturn,
  relationalAdditionalCostValueNaccountForSalesReturn,
} from './initial-data-sales-return';

/**
 *
 * @param   { object } param0 params function as object
 *
 * @param   { number }      amount                  total of generate data and validation for sales return
 * @param   { string }      dataKeyname             return of data for keyname on each items
 * @param   { string }      validationKeyname       return of validation for keyname on each items
 *
 * @returns
 *
 * generated data and validation for sales return
 *
 */
export const generateDataNvalidationAddSalesReturn = ({
  amount = 1,
  dataKeyname = 'salesReturnData',
  validationKeyname = 'salesReturnValidation',
}) => {
  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    initialDataSalesReturn,
    initialValidationSalesReturn,
    dataKeyname,
    validationKeyname,
  );
};

/**
 *
 * @param   { object } param0 params function as object
 *
 * @param   { number }      amount                  total of generate data and validation for sales return
 * @param   { string }      dataKeyname             return of data for keyname on each items
 * @param   { string }      validationKeyname       return of validation for keyname on each items
 *
 * @returns
 *
 * generated data and validation for product sales return
 *
 */
export const generateDataNvalidationAddProductSalesReturn = ({
  amount = 1,
  dataKeyname = 'productSalesReturnData',
  validationKeyname = 'productSalesReturnValidation',
}) => {
  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    initialDataProductSalesReturn,
    initialValidationProductSalesReturn,
    dataKeyname,
    validationKeyname,
  );
};

/**
 *
 * @param       { object } param0         object as function params
 *
 *
 * @param       { object }                salesInvoiceDetails           result from sales invoice details
 * @param       { object }                companyUserData               current of data company user
 * @param       { array }                 currentSalesReturnData        current state from sales return
 * @param       { string }                dataKeyname                   return data key name
 * @param       { string }                validationKeyname             return validation key name
 *
 * @returns
 */
export const generateDataNvalidationSalesReturnFromSalesInvoiceDetails = ({
  salesInvoiceDetails,
  companyUserData,
  currentSalesReturnData,
  dataKeyname = 'salesReturnData',
  validationKeyname = 'salesReturnValidation',
}) => {
  if (isEmpty(salesInvoiceDetails)) {
    const { id } = currentSalesReturnData;

    return {
      [dataKeyname]: [
        {
          ...initialDataSalesReturn(id),
          ...omit(currentSalesReturnData[0], [
            'id',
            'amount_const',
            'amount',
            'sales_invoice_no',
            'sales_invoice_id',
            'sales_invoice_id_container',
            'customer_id',
            'customer_id_container',
            'customer_address',
            'discount_type',
            'discount_percentage',
            'discount_nominal',
            'invoice_total',
            'invoiceNumber',
          ]),
        },
      ],
      [validationKeyname]: [initialValidationSalesReturn(id)],
    };
  }

  const {
    sales_invoice_id,
    customer_id,
    customer_name,
    customer_code,
    customer_address,
    sales_invoice_no,
    discount_nominal,
    discount_percentage,
    discount_type,
    product,
  } = salesInvoiceDetails;

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const productAmountConst =
    Array.isArray(product) && !isEmpty(product)
      ? product.reduce((resAmount, currProduct) => {
          const { price, quantity_available } = currProduct;

          return Number(price) * Number(quantity_available) + Number(resAmount);
        }, 0)
      : 0;

  const salesReturnData = usedID.map((id) => {
    return {
      ...initialDataSalesReturn(id),
      ...omit(currentSalesReturnData[0], ['id']),
      invoice_total: formulaHelpers.calculateAmountInvoiceReturn({
        parentData: salesInvoiceDetails,
      }),
      amount_const: Number(productAmountConst) - Number(discount_nominal) || 0,
      amount: Number(productAmountConst) - Number(discount_nominal) || 0,
      sales_invoice_id,
      sales_invoice_no,
      sales_invoice_id_container: {
        sales_invoice_id,
        sales_invoice_no,
      },
      invoiceNumber: sales_invoice_no || '',
      discount_nominal,
      discount_percentage,
      discount_type: discount_type || DEFAULT_DISCOUNT_NOMINAL_TYPE,
      customer_id,
      customer_id_container: {
        contact_id: customer_id,
        contact_code: customer_code || '',
        contact_name: customer_name || '',
      },
      customer_address: customer_address || '',
      accounting_period: isEmpty(companyUserData)
        ? null
        : companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE],
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
          documentData: salesInvoiceDetails,
        }),
    };
  });

  const salesReturnValidation = usedID.map((id) => initialValidationSalesReturn(id));

  return {
    [dataKeyname]: salesReturnData,
    [validationKeyname]: salesReturnValidation,
  };
};

/**
 *
 * @param {object} param0 object as function params
 *
 * @param   { object }      salesInvoiceDetails         data response sales invoice details
 * @param   { string }      dataKeyname                 return keyname data
 * @param   { string }      validationKeyname           return keyname validation
 *
 * @returns
 */
export const generateDataNvalidationProductSalesReturnFromSalesInvoiceDetails = ({
  salesInvoiceDetails,
  dataKeyname = 'productSalesReturnData',
  validationKeyname = 'productSalesReturnValidation',
}) => {
  if (isEmpty(salesInvoiceDetails)) {
    const usagedID = arrHelp.generateArrWithFunc(2, uuidv4);

    return {
      [dataKeyname]: usagedID.map((id) => initialDataProductSalesReturn(id)),
      [validationKeyname]: usagedID.map((id) => initialValidationProductSalesReturn(id)),
    };
  }

  const productSalesInvoiceDetails = salesInvoiceDetails[DEFAULT_KEY_NAME_PRODUCT_API];

  if (isEmpty(productSalesInvoiceDetails) || !Array.isArray(productSalesInvoiceDetails)) {
    return generateDataNvalidationAddProductSalesReturn({
      amount: 1,
      dataKeyname,
      validationKeyname,
    });
  }

  const usagedID = arrHelp.generateArrWithFunc(productSalesInvoiceDetails.length, uuidv4);

  const productSalesReturnData = usagedID.map((id, index) => {
    let selectedProductSalesInvoiceDetails = productSalesInvoiceDetails[index];
    if (!isEmpty(selectedProductSalesInvoiceDetails)) {
      selectedProductSalesInvoiceDetails = objHelper.changeFormatValue(
        selectedProductSalesInvoiceDetails,
        productSalesInvoiceCalculationNumberKeyNames,
        [(value) => Number(value) || ''],
      );
    }

    const { quantity_available } = selectedProductSalesInvoiceDetails;

    return {
      ...initialDataProductSalesReturn(id),
      ...selectedProductSalesInvoiceDetails,
      product_id_container: {
        ...selectedProductSalesInvoiceDetails,
        quantity_written: Number(quantity_available),
      },
      ...formulaHelpers.parseProductAPI2display({
        product: selectedProductSalesInvoiceDetails,
        compareQuantityAvailableToModulQuantityAvailable: true,
        swapQuantityOriginWithQuantity: true,
      }),
      // unit_destination: unit_origin, // swapping when for user view
    };
  });

  const productSalesReturnValidation = usagedID.map((id) =>
    initialValidationProductSalesReturn(id),
  );

  return {
    [dataKeyname]: productSalesReturnData,
    [validationKeyname]: productSalesReturnValidation,
  };
};

/**
 *
 * @param { array } salesReturnData            result input sales return data
 * @param { array } productSalesReturnData     result input product sales return data
 * @returns
 */
export const generalizeDataAddSalesReturn = (salesReturnData, productSalesReturnData) => {
  if (!Array.isArray(salesReturnData) || !Array.isArray(productSalesReturnData)) {
    return {
      product: productSalesReturnData,
      ...salesReturnData,
    };
  }

  salesReturnData = salesReturnData.map((singleSalesReturn) => {
    const { customer_id_container, transaction_date, attachment } = singleSalesReturn;

    const uuidAttachment =
      Array.isArray(attachment) && attachment.length
        ? attachment[0][DEFAULT_KEY_NAME_FILE_PROPERTIES][DEFAULT_KEY_NAME_UUID_API_REF] || ''
        : '';

    const keysAdditionalCostValue = Object.keys(
      relationalAdditionalCostValueNaccountForSalesReturn,
    );
    const omitedAdditionalAccount = keysAdditionalCostValue.reduce(
      (allAddCostValue, currAdditionalCost) => {
        if (!Number(singleSalesReturn[currAdditionalCost])) {
          allAddCostValue = allAddCostValue.concat(
            relationalAdditionalCostValueNaccountForSalesReturn[currAdditionalCost],
          );
        }

        return allAddCostValue;
      },
      [],
    );

    singleSalesReturn = omit(singleSalesReturn, omitedAdditionalAccount);

    let customer_name = '';
    if (customer_id_container) {
      const { contact_name, customer_name: customer_name_gen } = customer_id_container;

      customer_name = contact_name || customer_name_gen;
    }

    return objHelper.filteringExistedValueV2({
      obj: pick(
        {
          ...singleSalesReturn,
          customer_name,
          transaction_date: transaction_date ? moment(transaction_date).toISOString(true) : '',
          [DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF]: uuidAttachment,
        },
        allowKeysDataSalesReturn,
      ),
    });
  });

  productSalesReturnData = productSalesReturnData.reduce(
    (resSalesReturnProduct, singleProductSalesReturn) => {
      const { quantity, quantity_origin, unit_origin, unit_destination } = singleProductSalesReturn;

      if (!quantity) return resSalesReturnProduct;

      return resSalesReturnProduct.concat(
        pick(
          {
            ...singleProductSalesReturn,
            quantity_origin:
              unit_destination === unit_origin ? Number(quantity) : Number(quantity_origin),
          },
          allowKeysDataProductSalesReturn,
        ),
      );
    },
    [],
  );

  return {
    product: productSalesReturnData,
    ...salesReturnData[0],
  };
};
