import { cloneDeep, isEmpty, pick } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_DISCOUNT_PERCENTAGE_TYPE } from '../../default/discount-type.default';
import { DEFAULT_COMPARISON_VALUE_NUMBER } from '../../default/number.default';
import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF,
  DEFAULT_KEY_NAME_AMOUNT_REF_API,
  DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
  DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
  DEFAULT_KEY_NAME_EXPENSE_VALUE,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_MODUL_TYPE_API_REF,
  DEFAULT_KEY_NAME_PRODUCT_API,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';
import arrHelp from '../../helpers/array.helpers';
import objHelper from '../../helpers/object.helper';
import { DEFAULT_CALCULATION_NUMBER } from '../default/number.default';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import { generalizeAttachmentFromDetails } from './initial-add-attachment';
import {
  generalizeDataAddPurchaseInvoice,
  generateDataNValidationAddProductPurchaseInvoice,
} from './initial-add-purchase-invoice';
import {
  allowKeysPurchaseInvoiceData,
  initialProductPurchaseInvoiceData,
  initialProductPurchaseInvoiceValidation,
  initialPurchaseInvoiceValidation,
  modulTypePurchaseInvoice,
  plainPurchaseInvoiceDataWithoutID,
  purchaseInvoiceCalculationNumberKeyNames,
} from './initial-data-purchase-invoice';

/**
 *
 * @param { object } detailPurchaseInvoice data detail purchase invoice after hit detail on API
 * @param { string } customDataKey name key of return data
 * @param { string } customValidationKey name key of return validation
 * @returns
 *      generated data and validation purchase invoice from detail them
 */
export const generateDataNValidationUpdatePurchaseInvoiceFromDetailPurchaseInvoice = (
  detailPurchaseInvoice,
  customDataKey = 'purchaseInvoiceData',
  customValidationKey = 'purchaseInvoiceValidation',
) => {
  if (typeof detailPurchaseInvoice !== 'object') return;

  const {
    supplier_name: contact_name,
    supplier_code,
    contact_code,
    purchase_order_id,
    purchase_order_no,
    purchase_delivery_id,
    purchase_delivery_no,
    purchase_invoice_id,
    purchase_invoice_no,
    description,
    modul_type,
  } = cloneDeep(detailPurchaseInvoice);

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  // default modul id and container that from manual, that using origin purchase invoice
  let modul_id = purchase_invoice_id,
    modul_id_container = {
      transaction_id: purchase_invoice_id,
      transaction_no: purchase_invoice_no,
    };

  if (
    typeof modul_type === 'string' &&
    modul_type.toLowerCase() === modulTypePurchaseInvoice.delivery
  ) {
    (modul_id = purchase_delivery_id),
      (modul_id_container = {
        transaction_id: purchase_delivery_id,
        transaction_no: purchase_delivery_no,
      });
  } else if (
    typeof modul_type === 'string' &&
    modul_type.toLowerCase() === modulTypePurchaseInvoice.order
  ) {
    (modul_id = purchase_order_id),
      (modul_id_container = {
        transaction_id: purchase_order_id,
        transaction_no: purchase_order_no,
      });
  }

  const purchaseInvoiceData = usedID.map((id) => {
    return {
      id,
      ...plainPurchaseInvoiceDataWithoutID,
      ...detailPurchaseInvoice,
      purchase_invoice_id,
      description: description || '',
      modul_type,
      modul_id,
      modul_id_container,
      supplier_id_container: {
        contact_name,
        contact_code: supplier_code || contact_code,
      },
      reference_number: purchase_invoice_no,
      reference_number_container: {
        value: purchase_invoice_no,
      },
      discount_account_id_container: {
        account_id: detailPurchaseInvoice.discount_account_id || '',
        account_name: detailPurchaseInvoice.discount_account_name || '',
        account_code: detailPurchaseInvoice.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: detailPurchaseInvoice.expense_account_id || '',
        account_name: detailPurchaseInvoice.expense_account_name || '',
        account_code: detailPurchaseInvoice.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: detailPurchaseInvoice.down_payment_account_id || '',
        account_name: detailPurchaseInvoice.down_payment_account_name || '',
        account_code: detailPurchaseInvoice.down_payment_account_code || '',
      },
    };
  });

  const purchaseInvoiceValidation = usedID.map((id) => {
    return initialPurchaseInvoiceValidation(id, '', []);
  });

  return {
    [customDataKey]: purchaseInvoiceData,
    [customValidationKey]: purchaseInvoiceValidation,
  };
};

/**
 *
 * @param { object } detailPurchaseInvoice data detail purchase invoice after hit detail on API
 * @param { string } customDataKey name key of return data
 * @param { string } customValidationKey name key of return validation
 * @returns
 *      generated data and validation purchase invoice from detail them
 */
export const generateDataNValidationUpdatePurchaseInvoiceFromDetailPurchaseInvoiceV2 = ({
  detailPurchaseInvoice,
  companyUserData = {},
  customDataKey = 'purchaseInvoiceData',
  customValidationKey = 'purchaseInvoiceValidation',
}) => {
  if (typeof detailPurchaseInvoice !== 'object') return;

  const {
    supplier_name: contact_name,
    supplier_code,
    contact_code,
    purchase_order_id,
    purchase_order_no,
    purchase_delivery_id,
    purchase_delivery_no,
    purchase_invoice_id,
    purchase_invoice_no,
    description,
    modul_type,
    discount_type,
    status,
  } = cloneDeep(detailPurchaseInvoice);

  detailPurchaseInvoice = objHelper.changeFormatValue(
    detailPurchaseInvoice,
    purchaseInvoiceCalculationNumberKeyNames,
    [(value) => Number(value) || ''],
  );

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  // default modul id and container that from manual, that using origin purchase invoice
  let modul_id = purchase_invoice_id,
    modul_id_container = {
      transaction_id: purchase_invoice_id,
      transaction_no: purchase_invoice_no,
    };

  if (
    typeof modul_type === 'string' &&
    modul_type.toLowerCase() === modulTypePurchaseInvoice.delivery
  ) {
    (modul_id = purchase_delivery_id),
      (modul_id_container = {
        transaction_id: purchase_delivery_id,
        transaction_no: purchase_delivery_no,
      });
  } else if (
    typeof modul_type === 'string' &&
    modul_type.toLowerCase() === modulTypePurchaseInvoice.order
  ) {
    (modul_id = purchase_order_id),
      (modul_id_container = {
        transaction_id: purchase_order_id,
        transaction_no: purchase_order_no,
      });
  }

  detailPurchaseInvoice = objHelper.changeFormatValue(
    detailPurchaseInvoice,
    [
      DEFAULT_KEY_NAME_AMOUNT_REF_API,
      DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
      DEFAULT_KEY_NAME_EXPENSE_VALUE,
    ],
    [(value) => Number(value) || 0],
  );

  const purchaseInvoiceData = usedID.map((id) => {
    return {
      id,
      ...plainPurchaseInvoiceDataWithoutID,
      ...detailPurchaseInvoice,
      amount_const: Number(detailPurchaseInvoice[DEFAULT_KEY_NAME_AMOUNT_REF_API]),
      accounting_period: !isEmpty(companyUserData)
        ? companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF]
        : null,
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
      purchase_invoice_id,
      description: description || '',
      modul_type,
      modul_id,
      modul_id_container,
      supplier_id_container: {
        contact_name,
        contact_code: supplier_code || contact_code,
      },
      reference_number: purchase_invoice_no,
      reference_number_container: {
        value: purchase_invoice_no,
      },
      discount_type: discount_type ? discount_type : DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
      status,
      attachment: generalizeAttachmentFromDetails({
        dataDetails: detailPurchaseInvoice,
      }),
      discount_account_id_container: {
        account_id: detailPurchaseInvoice.discount_account_id || '',
        account_name: detailPurchaseInvoice.discount_account_name || '',
        account_code: detailPurchaseInvoice.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: detailPurchaseInvoice.expense_account_id || '',
        account_name: detailPurchaseInvoice.expense_account_name || '',
        account_code: detailPurchaseInvoice.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: detailPurchaseInvoice.down_payment_account_id || '',
        account_name: detailPurchaseInvoice.down_payment_account_name || '',
        account_code: detailPurchaseInvoice.down_payment_account_code || '',
      },
      warehouse_id_container: {
        ...pick(detailPurchaseInvoice, ['warehouse_id', 'warehouse_name']),
      },
    };
  });

  const purchaseInvoiceValidation = usedID.map((id) => {
    return initialPurchaseInvoiceValidation(id, '', []);
  });

  return {
    [customDataKey]: purchaseInvoiceData,
    [customValidationKey]: purchaseInvoiceValidation,
  };
};

/**
 *
 * @param { object } detailPurchaseInvoice data detail purchase invoice after hit detail on API
 * @param { string } customDataKey key name return of data
 * @param { string } customValidationKey key name return of validation
 * @returns
 *      generated product purchase invoice data and validation from detail purchase invoice
 */
export const generateDataNValidationUpdateProductPurchaseInvoiceFromDetailPurchaseInvoice = (
  detailPurchaseInvoice,
  customDataKey = 'productPurchaseInvoiceData',
  customValidationKey = 'productPurchaseInvoiceValidation',
) => {
  if (typeof detailPurchaseInvoice !== 'object') {
    return;
  }

  const { product: productPurchaseInvoiceDetail } = detailPurchaseInvoice;

  if (Array.isArray(productPurchaseInvoiceDetail)) {
    if (!productPurchaseInvoiceDetail.length) {
      const newID = uuidv4();

      return {
        [customDataKey]: [initialProductPurchaseInvoiceData(newID)],
        [customValidationKey]: [initialProductPurchaseInvoiceValidation(newID)],
      };
    }
  }

  const modulType = detailPurchaseInvoice[DEFAULT_KEY_NAME_MODUL_TYPE_API_REF];

  const usedID = arrHelp.generateArrWithFunc(productPurchaseInvoiceDetail.length, uuidv4);

  let productPurchaseInvoiceData = usedID.map((id, index) => {
    const {
      product_id,
      product_name,
      quantity,
      price,
      product_code,
      quantity_available,
      quantity_origin,
      product_unit,
      modul_quantity_available,
      unit_destination,
      unit_origin,
    } = productPurchaseInvoiceDetail[index];

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    const perQuantityComparison = 1 / quantityComparison;

    const modulQuantityAvailablePerQuantityComparison =
      (Number(modul_quantity_available) || 0) * perQuantityComparison;

    const quantityAvailableConst =
      modulType === modulTypePurchaseInvoice.manual
        ? Infinity
        : Number(quantity_available) + Number(modulQuantityAvailablePerQuantityComparison);

    let amount = Number(price) * Number(quantity);

    return {
      ...initialProductPurchaseInvoiceData(id),
      ...productPurchaseInvoiceDetail[index],
      product_id_container: {
        product_id,
        product_name,
        product_code,
        ...productPurchaseInvoiceDetail[index],
      },
      quantity: Number(quantity),
      price: Number(price),
      amount,
      quantity_available: DEFAULT_CALCULATION_NUMBER,
      quantity_available_const: quantityAvailableConst,
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: quantityAvailableConst,
        value_comparison: quantityComparison,
      },
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      quantity_available_with_unit: `${Number(quantityAvailableConst)} ${
        unit_origin || product_unit
      }`,
    };
  });

  let productPurchaseInvoiceValidation = usedID.map((id) => {
    return initialProductPurchaseInvoiceValidation(id);
  });

  // add new row empty only if modul type is manual
  if (detailPurchaseInvoice.modul_type === modulTypePurchaseInvoice.manual) {
    const {
      productPurchaseInvoiceData: productPurchaseInvoiceDataGen,
      productPurchaseInvoiceValidation: productPurchaseInvoiceValidationGen,
    } = generateDataNValidationAddProductPurchaseInvoice(1);

    productPurchaseInvoiceData = productPurchaseInvoiceData.concat(productPurchaseInvoiceDataGen);
    productPurchaseInvoiceValidation = productPurchaseInvoiceValidation.concat(
      productPurchaseInvoiceValidationGen,
    );
  }

  return {
    [customDataKey]: productPurchaseInvoiceData,
    [customValidationKey]: productPurchaseInvoiceValidation,
  };
};

/**
 *
 * @param { object } purchaseInvoiceDetails data detail purchase invoice after hit detail on API
 * @param { string } customDataKey key name return of data
 * @param { string } customValidationKey key name return of validation
 * @returns
 *      generated product purchase invoice data and validation from detail purchase invoice
 */
export const generateDataNValidationUpdateProductPurchaseInvoiceFromDetailPurchaseInvoiceV2 = ({
  purchaseInvoiceDetails,
  customDataKey = 'productPurchaseInvoiceData',
  customValidationKey = 'productPurchaseInvoiceValidation',
  keynameProductPurchaseInvoice = DEFAULT_KEY_NAME_PRODUCT_API,
}) => {
  if (typeof purchaseInvoiceDetails !== 'object') {
    return;
  }

  const productPurchaseInvoiceDetail = purchaseInvoiceDetails[keynameProductPurchaseInvoice];

  if (Array.isArray(productPurchaseInvoiceDetail)) {
    if (!productPurchaseInvoiceDetail.length) {
      const newID = uuidv4();

      return {
        [customDataKey]: [initialProductPurchaseInvoiceData(newID)],
        [customValidationKey]: [initialProductPurchaseInvoiceValidation(newID)],
      };
    }
  }

  const modulType = purchaseInvoiceDetails[DEFAULT_KEY_NAME_MODUL_TYPE_API_REF];

  const usedID = arrHelp.generateArrWithFunc(productPurchaseInvoiceDetail.length, uuidv4);

  const productPurchaseInvoiceData = usedID.map((id, index) => {
    const {
      product_id,
      product_name,
      quantity,
      price,
      product_code,
      modul_quantity_available,
      quantity_origin,
      product_unit,
      unit_destination,
      unit_origin,
    } = productPurchaseInvoiceDetail[index];

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    const perQuantityComparison = 1 / quantityComparison;

    const modulQuantityAvailablePerQuantityComparison =
      (Number(modul_quantity_available) || 1) * perQuantityComparison;

    const quantityAvailableConst =
      modulType === modulTypePurchaseInvoice.manual
        ? Infinity
        : Number(modulQuantityAvailablePerQuantityComparison);

    let amount = Number(price) * Number(quantity) || 0;

    return {
      ...initialProductPurchaseInvoiceData(id),
      ...productPurchaseInvoiceDetail[index],
      product_id_container: {
        product_id,
        product_name,
        product_code,
        ...productPurchaseInvoiceDetail[index],
      },
      price: Number(price),
      quantity: Number(quantity),
      amount,
      quantity_available: DEFAULT_CALCULATION_NUMBER,
      quantity_available_const: quantityAvailableConst,
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: quantityAvailableConst,
        value_comparison: quantityComparison,
      },
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      quantity_available_with_unit: `${Number(quantityAvailableConst)} ${
        unit_origin || product_unit
      }`,
    };
  });

  const productPurchaseInvoiceValidation = usedID.map((id) => {
    return initialProductPurchaseInvoiceValidation(id);
  });

  return {
    [customDataKey]: productPurchaseInvoiceData,
    [customValidationKey]: productPurchaseInvoiceValidation,
  };
};

/**
 *
 * @param { array } purchaseInvoiceData purchase invoice data for generalized
 * @param { arrat } productPurchaseInvoice product on purchase invoice data
 * @returns
 *      generalized data for updating on purchase invoice
 */
export const generalizeDataUpdatePurchaseInvoice = (
  purchaseInvoiceData,
  productPurchaseInvoice,
) => {
  let commonGeneralizeDataWithoutIndentity = generalizeDataAddPurchaseInvoice(
    cloneDeep(purchaseInvoiceData),
    cloneDeep(productPurchaseInvoice),
  );

  // adding identity for update data
  if (Array.isArray(purchaseInvoiceData)) {
    const { purchase_invoice_id } = Array.isArray(purchaseInvoiceData)
      ? purchaseInvoiceData[0]
      : purchaseInvoiceData;

    commonGeneralizeDataWithoutIndentity = {
      ...commonGeneralizeDataWithoutIndentity,
      purchase_invoice_id,
    };
  }

  // removing for actually used for update purchase invoice
  if (!isEmpty(commonGeneralizeDataWithoutIndentity)) {
    commonGeneralizeDataWithoutIndentity = pick(
      commonGeneralizeDataWithoutIndentity,
      allowKeysPurchaseInvoiceData,
    );
  }

  return commonGeneralizeDataWithoutIndentity;
};
