import { createAsyncThunk } from '@reduxjs/toolkit';

import transactionServices from '../../services/API/transaction/transaction.service';
import { TRANSACTION_ASYNC_THUNK_TYPE } from '../../utils/constants/action-type.constant';
import { LOCAL_STORAGE_TRANSACTION_LIST } from '../../utils/constants/storage.constant';
import {
  SLICE_NAME_TRANSACTION_DATA_LIST,
  SLICE_NAME_TRANSACTION_DETAILS,
  STATUS_REQUEST_LIST_TRANSACTION_FAILED,
  STATUS_REQUEST_LIST_TRANSACTION_PENDING,
  STATUS_REQUEST_LIST_TRANSACTION_SUCCESS,
} from '../../utils/constants/store.constant';
import { DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF } from '../../utils/default/object-keyname.default';
import {
  DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
  DEFAULT_TRANSACTION_LIST_SEARCH_FE_PARAMS,
} from '../../utils/default/params.default';
import {
  creatorDetailAction,
  creatorListActionDynamicSliceNameNproperties,
  creatorListRefreshAction,
} from '../creator-action/creator-action.helper';

/**
 * transaction actions
 *
 */

export const getTransactionList = createAsyncThunk(
  TRANSACTION_ASYNC_THUNK_TYPE.list,
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_TRANSACTION_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_TRANSACTION_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_TRANSACTION_FAILED,
      slicePayloadKeyname = SLICE_NAME_TRANSACTION_DATA_LIST,
      localStorageName = LOCAL_STORAGE_TRANSACTION_LIST,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...params
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params: params,
      apiService: transactionServices.getTransactionList,
      localStorageName,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      paginatePageParams,
      uniqueKeyname: DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF,
      slicePayloadKeyname,
      errorMessage: 'error.list-data.transaction',
    });
  },
);

// action transaction list refresh
export const getTransactionListRefresh = createAsyncThunk(
  TRANSACTION_ASYNC_THUNK_TYPE.listRefresh,
  async (discardKeySearch = [], thunkAPI) => {
    return await creatorListRefreshAction(
      thunkAPI,
      transactionServices.getTransactionList,
      LOCAL_STORAGE_TRANSACTION_LIST,
      SLICE_NAME_TRANSACTION_DATA_LIST,
      discardKeySearch,
      DEFAULT_TRANSACTION_LIST_SEARCH_FE_PARAMS,
      'error.list-data.transaction',
    );
  },
);

// action transaction details
export const getTransactionDetails = createAsyncThunk(
  TRANSACTION_ASYNC_THUNK_TYPE.detail,
  async (paramsGetTransactionDetails, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      paramsGetTransactionDetails,
      transactionServices.getTransactionDetails,
      '',
      SLICE_NAME_TRANSACTION_DETAILS,
      'error.detail.transaction',
    );
  },
);

const transactionActions = {
  getTransactionDetails,
  getTransactionList,
  getTransactionListRefresh,
};

export default transactionActions;
