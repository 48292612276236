const worksheetXLSXname = {
  DEFAULT_WORKSHEET_PURCHASE_REPORT_NAME: 'purchase_report',
  DEFAULT_WORKSHEET_SALES_REPORT_NAME: 'sales_report',
  DEFAULT_WORKSHEET_STOCK_REPORT_NAME: 'stock_report',
  DEFAULT_WORKSHEET_PRICE_LIST_REPORT_NAME: 'price_list_report',
  DEFAULT_WORKSHEET_SALES_EXTRACTION_REPORT_NAME: 'sales_extraction_report',
  DEFAULT_WORKSHEET_CASH_FLOW_REPORT_NAME: 'cash_flow_report',
  DEFAULT_WORKSHEET_JOURNAL_REPORT_NAME: 'journal_report',
  DEFAULT_WORKSHEET_INCOME_STATEMENT_REPORT_NAME: 'income_statement_report',
  DEFAULT_WORKSHEET_BALANCE_SHEET_REPORT_NAME: 'balance_sheet_report',
  DEFAULT_WORKSHEET_CAPITAL_CHANGE_REPORT_NAME: 'capital_change_report',
  DEFAULT_WORKSHEET_GENERAL_LEDGER_REPORT_NAME: 'general_ledger_report',
  DEFAULT_WORKSHEET_TRIAL_BALANCE_REPORT_NAME: 'trial_balance_report',
  DEFAULT_WORKSHEET_STOCK_SALES_REPORT_NAME: 'stock_sales_report',
  DEFAULT_WORKSHEET_STOCK_CARD_REPORT_NAME: 'stock_card_report',
  DEFAULT_WORKSHEET_STOCK_MUTATION_REPORT_NAME: 'stock_mutation_report',
  DEFAULT_WORKSHEET_STOCK_PURCHASE_REPORT_NAME: 'stock_purchase_report',
  DEFAULT_WORKSHEET_STOCK_ADJUSTMENT_REPORT_NAME: 'stock_adjustment_report',
};

export default worksheetXLSXname;
