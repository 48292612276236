import axios from 'axios';

import {
  REACT_APP_API_URL_PRODUCT_BY_SUPPLIER,
  REACT_APP_API_URL_PRODUCT_CATEGORY,
  REACT_APP_API_URL_PRODUCT_DATA,
  REACT_APP_API_URL_PRODUCT_MUTATION,
  REACT_APP_API_URL_PRODUCT_PURCHASE_SALE,
  REACT_APP_API_URL_PRODUCT_UNIT,
  REACT_APP_API_URL_PRODUCT_UPLOAD,
  REACT_APP_API_URL_PRODUCT_UPLOAD_PROGRESS,
} from '../../../utils/configs/api.config';
import { allowKeysProductData } from '../../../utils/data/display/initial-product-data';
import { DEFAULT_FILE_TYPE_CSV } from '../../../utils/default/file-type.default';
import {
  DEFAULT_ALLOW_PARAMS_PRODUCT_MUTATION_LIST,
  DEFAULT_PRODUCT_BY_SUPPLIER_LIST_PARAMS,
  DEFAULT_PRODUCT_CATEGORY_LIST_PARAMS,
  DEFAULT_PRODUCT_LIST_PARAMS,
  DEFAULT_PRODUCT_PURCHASE_SALE_LIST_PARAMS,
  DEFAULT_PRODUCT_UNIT_LIST_PARAMS,
  defaultSendParamsProductUnitList,
  TERSIER_DEFAULT_LIST_PARAMS,
} from '../../../utils/default/params.default';
import { authHeader } from '../../../utils/helpers/auth-header.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import strHelp from '../../../utils/helpers/string.helpers';
import {
  creatorDetailService,
  creatorDetailServiceV2,
  creatorListService,
  creatorMultipartFormDataService,
} from '../creator-services/creator-services.helper';

// list all product data in company
const listProductData = async (paramsGetListProduct) => {
  return await creatorListService(
    REACT_APP_API_URL_PRODUCT_DATA,
    paramsGetListProduct,
    DEFAULT_PRODUCT_LIST_PARAMS,
    TERSIER_DEFAULT_LIST_PARAMS,
    true,
  );
};

// detail product
const getProductDetails = async (productID) => {
  return await creatorDetailService(REACT_APP_API_URL_PRODUCT_DATA, productID);
};

const addProductData = async (dataAddProduct) => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  dataAddProduct = objHelper.filterKeyObj(dataAddProduct, [], allowKeysProductData);

  dataAddProduct = objHelper.filteringExistedValue(dataAddProduct, [], true);

  return await axios({
    method: 'POST',
    url: REACT_APP_API_URL_PRODUCT_DATA,
    headers: {
      Authorization,
    },
    data: {
      ...dataAddProduct,
    },
  });
};

const updateProductData = async (dataUpdateProduct) => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  const { product_id } = dataUpdateProduct;

  dataUpdateProduct = objHelper.filterKeyObj(dataUpdateProduct, ['product_id']);

  dataUpdateProduct = objHelper.filterKeyObj(dataUpdateProduct, [], allowKeysProductData);

  return await axios({
    method: 'PUT',
    url: `${REACT_APP_API_URL_PRODUCT_DATA}/${product_id}`,
    headers: {
      Authorization,
    },
    data: {
      ...dataUpdateProduct,
    },
  });
};

// deleting product data company
const deleteProductData = async ({ product_id }) => {
  const { Authorization } = authHeader();

  if (!Authorization || !product_id) {
    return null;
  }

  return axios({
    method: 'DELETE',
    url: `${REACT_APP_API_URL_PRODUCT_DATA}/${product_id}`,
    headers: {
      Authorization,
    },
  });
};

/**
 *
 * product category service
 *
 */

const listProductCategory = async (params) => {
  return await creatorListService(
    REACT_APP_API_URL_PRODUCT_CATEGORY,
    params,
    DEFAULT_PRODUCT_CATEGORY_LIST_PARAMS,
    TERSIER_DEFAULT_LIST_PARAMS,
    true,
  );
};

// add product category in company
const addProductCategory = async ({ category_name, product_description }) => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  let dataAddProductCategory = { category_name };

  if (product_description) {
    dataAddProductCategory.product_description = product_description;
  }

  return await axios({
    method: 'POST',
    url: REACT_APP_API_URL_PRODUCT_CATEGORY,
    headers: {
      Authorization,
    },
    data: {
      ...dataAddProductCategory,
    },
  });
};

// update product category in company
const updateProductCategory = async ({
  product_category_id,
  category_name,
  product_description,
}) => {
  const { Authorization } = authHeader();

  if (!Authorization || !product_category_id) {
    return null;
  }

  let dataUpdateProductCategory = { category_name };

  if (product_description) {
    dataUpdateProductCategory.product_description = product_description;
  }

  return await axios({
    method: 'PUT',
    url: `${REACT_APP_API_URL_PRODUCT_CATEGORY}/${product_category_id}`,
    headers: {
      Authorization,
    },
    data: {
      ...dataUpdateProductCategory,
    },
  });
};

// delete product category in company
const deleteProductCategory = async ({ product_category_id }) => {
  const { Authorization } = authHeader();

  if (!Authorization || !product_category_id) {
    return null;
  }

  return await axios({
    method: 'DELETE',
    url: `${REACT_APP_API_URL_PRODUCT_CATEGORY}/${product_category_id}`,
    headers: {
      Authorization,
    },
  });
};

// list all product in purchase/sale
const getlistProductPurchaseSale = async (paramsGetListProductPurchaseSale) => {
  return await creatorListService(
    REACT_APP_API_URL_PRODUCT_PURCHASE_SALE,
    paramsGetListProductPurchaseSale,
    DEFAULT_PRODUCT_PURCHASE_SALE_LIST_PARAMS,
    TERSIER_DEFAULT_LIST_PARAMS,
    true,
  );
};

// list all product that owned by supplier or contact which is supplier
const getListProductBySupplier = async (paramsGetListProductBySupplier) => {
  return await creatorListService(
    REACT_APP_API_URL_PRODUCT_BY_SUPPLIER,
    paramsGetListProductBySupplier,
    DEFAULT_PRODUCT_BY_SUPPLIER_LIST_PARAMS,
    TERSIER_DEFAULT_LIST_PARAMS,
    true,
  );
};

// import product
const importProduct = async (fileImportProduct) => {
  return await creatorMultipartFormDataService({
    endpoint: REACT_APP_API_URL_PRODUCT_UPLOAD,
    bodyData: fileImportProduct,
    grabToNewFormData: true,
    formDataKeyname: DEFAULT_FILE_TYPE_CSV,
  });
};

// progress importing product
const progressImportingProduct = async (paramsGetImportingPoduct) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_PRODUCT_UPLOAD_PROGRESS,
    paramsGetImportingPoduct,
  );
};

// service for get product unit list
const productUnitList = async (productUnitList) => {
  return await creatorListService(
    REACT_APP_API_URL_PRODUCT_UNIT,
    productUnitList,
    DEFAULT_PRODUCT_UNIT_LIST_PARAMS,
    defaultSendParamsProductUnitList,
  );
};

// service for product mutation list
// minimum required in params is product id
const getProductMutationList = async (params) => {
  return await creatorListService(
    strHelp.templateString(REACT_APP_API_URL_PRODUCT_MUTATION, params),
    params,
    DEFAULT_ALLOW_PARAMS_PRODUCT_MUTATION_LIST,
  );
};

const productServices = {
  listProductData,
  getProductDetails,
  addProductData,
  updateProductData,
  deleteProductData,
  listProductCategory,
  addProductCategory,
  updateProductCategory,
  deleteProductCategory,
  getlistProductPurchaseSale,
  getListProductBySupplier,
  importProduct,
  progressImportingProduct,
  productUnitList,
  getProductMutationList,
};

export default productServices;
