import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import validation from '../../helpers/validation.helpers';

const initialID = uuidv4();

/**
 * amount           total credit note billing
 */
export const plainSalesPayablePaymentDataWithoutID = {
  customer_id: '',
  customer_id_container: null,
  customer_name: '',
  customer_address: '',
  transaction_date: '',
  today: '',
  accountingPeriod: '',
  transaction_no: '',
  transaction_no_container: null,
  account_payable: '',
  account_payable_container: null,
  uuid_attachment: '',
  description: '',
  attachment: [],
  amount: 0,
  minDateInputRef: null,
  tag: [],
  tagContainer: [],
  tagNameInput: '',
};

/**
 * amount is total credit note billing
 * amount available is remaining credit note billing
 * amount available const is maximum input user input for billing credit note
 * amount input is user input for credit note billing
 */
export const plainCreditNoteSalesPayablePaymentWithoutID = {
  credit_note_id: '',
  credit_note_id_container: '',
  description: null,
  amount: '',
  amount_available: '',
  amount_available_const: 0,
  amount_input: '',
};

export const plainSalesPayablePaymentValidationWithoutID = {
  customer_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  customer_name: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  customer_address: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  transaction_date: {
    error: false,
    isValid: (value, minDateInputRef, today) => {
      if (minDateInputRef !== undefined && today !== undefined) {
        return (
          (moment(value).isSameOrAfter(moment(minDateInputRef), 'D') || !minDateInputRef) &&
          moment(value).isSameOrBefore(moment(today), 'D')
        );
      }

      return validation.notnull(value);
    },
  },
  transaction_no: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  account_payable: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
};

export const plainCreditNoteSalesPayablePaymentValidationWithoutID = {
  credit_note_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  amount_input: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
};

export const initialDataSalesPayablePayment = (newID = '') => {
  return {
    id: newID || initialID,
    ...plainSalesPayablePaymentDataWithoutID,
    transaction_date: new Date(),
    today: new Date(),
  };
};

export const initialValidationSalesPayablePayment = (newID = '') => {
  return {
    id: newID || initialID,
    ...plainSalesPayablePaymentValidationWithoutID,
  };
};

export const initialDataCreditNoteSalesPayablePayment = (newID = '') => {
  return {
    id: newID || initialID,
    ...plainCreditNoteSalesPayablePaymentWithoutID,
  };
};

export const initialValidationCreditNoteSalesPayablePayment = (newID = '') => {
  return {
    id: newID || initialID,
    ...plainCreditNoteSalesPayablePaymentValidationWithoutID,
  };
};

export const allowKeysDataSalesPayablePayment = [
  'customer_id',
  'customer_address',
  'customer_name',
  'account_payable',
  'transaction_date',
  'transaction_no',
  'description',
  'uuid_attachment',
  'credit_note',
  'tag',
];

export const allowKeysDataCreditNoteSalesPayablePayment = [
  'credit_note_id',
  'description',
  'amount',
];

export const salesPayablePaymentCalculationNumberKeyNames = ['amount'];

export const creditNoteSalesPayablePaymentCalculationNumberKeyNames = [
  'amount',
  'amount_available',
  'amount_paid',
];
