import React, { createRef, PureComponent } from 'react';
import { DateRange, DefinedRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import { withTranslation } from 'react-i18next';
import { ArrowDropDown, ArrowDropUp, Close, Event } from '@mui/icons-material';
import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Menu,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/system';
import clsx from 'clsx';
import { isSameDay } from 'date-fns';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import { PropTypes } from 'prop-types';

import 'moment/locale/id';
import 'moment/locale/en-gb';

import { DEFAULT_FORMAT_DATE_DAY_MONTH_YEAR_SLASH } from '../../../utils/default/format.default';
import arrHelp from '../../../utils/helpers/array.helpers';
import formatHelp from '../../../utils/helpers/format.helpers';
import strHelp from '../../../utils/helpers/string.helpers';

import {
  customDefaultStaticRange,
  customMobileDefaultStaticRange,
  DEFAULT_DISPLAY_STATIC_WRAPPER_AS_DESKTOP,
  DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MINI_DEVICE,
  DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MOBILE,
} from './customDefaultStaticRange';
import { styles } from './DateTimePicker.styles';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './override.css';

// usage when not match with selected date range picker
const customLabelDateRangePicker = {
  en: {
    label: 'Custom',
  },
  id: {
    label: 'Custom',
  },
};

/**
 * @prop    { date }               startDate                         start date of range date picker
 * @prop    { date }               endDate                           end date of range date picker
 * @prop    { string }             textCancel                        label text button cancel
 * @prop    { string }             textApply                         label text button apply
 * @prop    { string }             placeHolderInput                  placeholder input date
 * @prop    { array }              rangeColors                       range color from displaying range date
 * @prop    { string }             color                             color for date
 * @prop    { object }             anchorOrigin                      anchor origin of menu direction
 * @prop    { object }             transformOrigin                   transform origin of menu direction
 * @prop    { function }           onSelectedChange                  handle selected change data, only trigger when apply button clicked
 * @prop    { string }             locale                            locale translation
 * @prop    { string }             defaultKeyStartDate               name return for key start date
 * @prop    { string }             defaultKeyEndDate                 name return for key end date
 * @prop    { bool }               isDisabledInput                   determine input is disabled or not
 * @prop    { object }             additionalPropsDefinedRange       override props defined range
 * @prop    { object }             additionalPropsDateRange          override props date range
 * @prop    { string }             overrideFormatDate                you can override format of date with passing format date on here
 *      @default    'MMM d, yyyy'
 *
 * @prop    { string }             displayStaticWrapperAs            determine for static wrapper can displying as, available: 'mobile', 'mini-device' and 'desktop'
 *      @default    'desktop'
 *
 * @prop    { array }             selectedLabelNameDateRange        label name for date range, such as 'Today', etc you can see on custom default static range
 *      @default    []                                              make sure that items is contained on all locales that you want, and again you need for passing start date and end date that correspond on it
 *
 * @prop    { boolean }           isLabelCurrentDateShow            handling show or unshow on typography when wrapper is mobile
 *      @default false
 *
 * @prop    { object }            customStaticDateRange             customize on static date range you can bypassing change on range date
 *      usage
 *          {
 *              [primary-locale]: [{label, value}],
 *              [secondary-locale]: [{label, value}],
 *          }
 *
 * @prop    { boolean }         useTodayForDefaultValue             determine for add default value for start date and end date when not available
 *
 */

class DateTimePicker extends PureComponent {
  constructor(props) {
    super(props);

    this.dateInputRef = createRef();

    let {
      startDate,
      endDate,
      selectedLabelNameDateRange,
      displayStaticWrapperAs,
      locale,
      customStaticDateRange: customStaticDateRangeProps,
      useTodayForDefaultValue = false,
    } = props;

    if ((!startDate || !endDate) && useTodayForDefaultValue) {
      startDate = moment().startOf('d').toISOString(true);
      endDate = moment().endOf('d').toISOString(true);
    }

    // switch date when start date is before end date
    else if (!moment(startDate).isBefore(endDate)) {
      const tempDate = startDate;

      startDate = endDate;
      endDate = tempDate;
    }

    let selection = [
      {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: 'selection',
      },
    ];

    if ((!startDate || !endDate) && !useTodayForDefaultValue) {
      selection = [
        {
          ...selection[0],
          startDate: null,
          endDate: null,
        },
      ];
    }

    let isShowDateRange = true,
      label = '';

    this.isDateChangedFromProps = false;
    this.indexSelectedCustomStaticDateRange = 0;
    this.customUsedDefaultStaticDateRange = customDefaultStaticRange;

    if (!isEmpty(customStaticDateRangeProps)) {
      this.customUsedDefaultStaticDateRange = customStaticDateRangeProps;
    }

    if (displayStaticWrapperAs === DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MOBILE) {
      this.customUsedDefaultStaticDateRange = customMobileDefaultStaticRange;

      isShowDateRange = false;
    }

    if (!isEmpty(selectedLabelNameDateRange)) {
      const selectedCustomStaticRangeOnLocale = this.customUsedDefaultStaticDateRange[locale];

      this.selectedInitialCustomStaticDateRange = arrHelp.filterObjKeyWithValueExisted({
        arr: selectedCustomStaticRangeOnLocale,
        keysFilter: 'label',
        valuesFilter: selectedLabelNameDateRange,
      });

      const { label: dateRangeLabel, range } = this.selectedInitialCustomStaticDateRange[0];

      this.indexSelectedCustomStaticDateRange = arrHelp.getIndexSearchDataInKey(
        selectedCustomStaticRangeOnLocale,
        'label',
        dateRangeLabel,
      );

      label = dateRangeLabel;

      const currentRange = range();
      const { startDate, endDate } = currentRange;

      selection = [
        {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          key: 'selection',
          label,
        },
      ];

      this.isDateChangedFromProps = true;
    }

    this.lastDateSelection = selection;

    this.state = {
      anchorElCalendar: null,
      isAfterDeletion: false,
      selection,
      label,
      isShowDateRange,
    };

    this.getSelectedDate = this.getSelectedDate.bind(this);

    this.handleOnOpenCalender = this.handleOnOpenCalender.bind(this);
    this.handleOnCloseCalendar = this.handleOnCloseCalendar.bind(this);

    this.handleChangeDate = this.handleChangeDate.bind(this);

    this.handleDeleteDate = this.handleDeleteDate.bind(this);

    this.handleApplyDate = this.handleApplyDate.bind(this);
  }

  handleOnOpenCalender(event) {
    event.preventDefault();
    event.stopPropagation();

    const { isDisabledInput } = this.props;

    if (isDisabledInput) {
      return;
    }

    this.setState({
      anchorElCalendar: event.currentTarget,
    });
  }

  handleDeleteDate(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState((prevState) => ({
      ...prevState,
      anchorElCalendar: null,
      selection: [
        {
          startDate: null,
          endDate: null,
          key: 'selection',
        },
      ],
    }));

    const { defaultKeyStartDate, defaultKeyEndDate, onSelectedChange } = this.props;

    if (defaultKeyStartDate && defaultKeyEndDate && typeof onSelectedChange === 'function') {
      onSelectedChange({
        [defaultKeyStartDate]: null,
        [defaultKeyEndDate]: null,
      });
    }
  }

  handleOnCloseCalendar(isDateChanged = false, ignoreDate = true) {
    const isShowDateRange =
      this.props.displayStaticWrapperAs !== DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MOBILE;

    if (!isDateChanged) {
      let { label, startDate, endDate } = this.lastDateSelection[0];

      if (!startDate || !endDate) {
        startDate = moment().toISOString(true);
        endDate = moment().toISOString(true);
      }

      this.setState({
        anchorElCalendar: null,
        selection: [
          {
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            key: 'selection',
          },
        ],
        isShowDateRange,
        label,
      });

      const { defaultKeyStartDate, defaultKeyEndDate, onSelectedChange } = this.props;

      // change feature for always start of day in startDate
      startDate = moment(startDate).startOf('day');

      // change feature for always end of day in end date
      endDate = moment(endDate).endOf('day');

      onSelectedChange({
        [defaultKeyStartDate]: moment(startDate).toISOString(true),
        [defaultKeyEndDate]: moment(endDate).toISOString(true),
      });
    } else if (isDateChanged || ignoreDate) {
      this.setState({
        anchorElCalendar: null,
        isShowDateRange,
      });
    }
  }

  handleChangeDate(item) {
    const { displayStaticWrapperAs } = this.props;

    if (displayStaticWrapperAs !== DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MOBILE) {
      this.setState({
        selection: [item.selection],
      });

      return;
    }

    const { locale } = this.props;
    const selectedCustomStaticRangeOnLocale = this.customUsedDefaultStaticDateRange[locale];

    this.indexSelectedCustomStaticDateRange = arrHelp.getIndexSearchDataInKey(
      selectedCustomStaticRangeOnLocale,
      'label',
      item.selection.label,
    );

    this.handleApplyDate([item.selection]);
  }

  handleApplyDate(newSelection) {
    const { defaultKeyStartDate, defaultKeyEndDate, onSelectedChange } = this.props;

    if (defaultKeyStartDate && defaultKeyEndDate && typeof onSelectedChange === 'function') {
      let selection = newSelection;

      if (!selection) {
        const { selection: selectionState } = this.state;

        selection = selectionState;
      } else if (newSelection) {
        const { label, isShowDateRange, startDate, endDate } = newSelection[0];

        this.setState({
          selection: newSelection,
          label,
          isShowDateRange,
        });

        if (isShowDateRange) {
          if (!startDate && !endDate) {
            this.lastDateSelection = [
              {
                ...this.state.selection[0],
                label: this.state.selection[0].label || this.state.label,
              },
            ];
          }

          this.isDateChangedFromProps = false;
          return;
        }

        // setting history of date selection from current selection date
        if (startDate && endDate) {
          this.lastDateSelection = [
            {
              startDate,
              endDate,
              label,
            },
          ];
        }
      }

      let { startDate, endDate } = selection[0];

      // change feature for always start of day in startDate
      startDate = moment(startDate).startOf('day');

      // change feature for always end of day in end date
      endDate = moment(endDate).endOf('day');

      onSelectedChange({
        [defaultKeyStartDate]: moment(startDate).toISOString(true),
        [defaultKeyEndDate]: moment(endDate).toISOString(true),
      });
    }

    this.handleOnCloseCalendar(true);
  }

  getSelectedDate() {
    const { i18n } = this.props;
    const { selection } = this.state;

    // if usage locale from passing on props, can breaking selection
    const { language } = i18n;

    const staticDateRanges = this.customUsedDefaultStaticDateRange[language];

    let selectedDateRange = {};

    for (let singleStaticDateRange of staticDateRanges) {
      const { range } = singleStaticDateRange;

      const rangeDay = range();

      const isSameDateRange =
        isSameDay(rangeDay.startDate, selection[0].startDate) &&
        isSameDay(rangeDay.endDate, selection[0].endDate);

      if (!isSameDateRange) continue;

      selectedDateRange = singleStaticDateRange;
      break;
    }

    if (isEmpty(selectedDateRange)) {
      selectedDateRange = customLabelDateRangePicker[language];
    }

    return selectedDateRange;
  }

  componentDidUpdate(prevProps) {
    const { locale: prevPropsLocale } = prevProps;
    const { locale: nextPropsLocale } = this.props;

    if (!isEqual(prevPropsLocale, nextPropsLocale)) {
      const selectedCustomStaticRangeOnLocale =
        this.customUsedDefaultStaticDateRange[nextPropsLocale];

      this.selectedInitialCustomStaticDateRange =
        selectedCustomStaticRangeOnLocale[this.indexSelectedCustomStaticDateRange];

      const { label: dateRangeLabel, range } = this.selectedInitialCustomStaticDateRange;

      const { startDate, endDate } = range();

      if (!startDate || !endDate) {
        this.setState({
          label: dateRangeLabel,
        });

        return;
      }

      this.setState({
        label: dateRangeLabel,
        selection: [
          {
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            key: 'selection',
          },
        ],
      });
    }
  }

  formatDateRange(startDate, endDate, overrideFormatDate) {
    const formattedStartDate = moment(startDate).format(overrideFormatDate);
    const formattedEndDate = moment(endDate).format(overrideFormatDate);

    return `${formattedStartDate} - ${formattedEndDate}`;
  }

  render() {
    const { anchorElCalendar, label, selection, isShowDateRange } = this.state;

    const {
      classes,
      displayStaticWrapperAs,
      locale,
      textCancel,
      textApply,
      placeHolderInput,
      anchorOrigin,
      transformOrigin,
      color,
      rangeColors,
      additionalPropsDefinedRange,
      additionalPropsDateRange,
      isDisabledInput,
      startDate,
      endDate,
      overrideFormatDate,
      isLabelCurrentDateShow,
      removeButtonClearDate,
      usageTemplateForShowingDateRange,
      templateShowingDateRange,
      inputFullWidth,
    } = this.props;

    let formattedDateRanges = '',
      temporaryMobileDateRange = '';

    if (startDate && endDate) {
      formattedDateRanges = formatHelp.getReadableRangeDate(startDate, endDate, locale);
    }

    if (startDate && endDate && overrideFormatDate) {
      formattedDateRanges = this.formatDateRange(startDate, endDate, overrideFormatDate);
    }

    if (this.isDateChangedFromProps) {
      const { startDate, endDate } = selection[0];

      if (startDate && endDate) {
        formattedDateRanges = this.formatDateRange(startDate, endDate, overrideFormatDate);
      }
    }

    if (displayStaticWrapperAs === DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MOBILE) {
      const { startDate, endDate } = selection[0];

      if (startDate && endDate) {
        temporaryMobileDateRange = this.formatDateRange(startDate, endDate, overrideFormatDate);
      }
    }

    if (usageTemplateForShowingDateRange) {
      const { label } = this.getSelectedDate();

      formattedDateRanges = strHelp.templateString(templateShowingDateRange, [
        label,
        formattedDateRanges,
      ]);
    }

    const isCalendarOpen = Boolean(anchorElCalendar);

    return (
      <>
        {displayStaticWrapperAs === DEFAULT_DISPLAY_STATIC_WRAPPER_AS_DESKTOP && (
          <OutlinedInput
            readOnly
            fullWidth={inputFullWidth}
            className={classes.outlinedInputDateRangePicker}
            onClick={this.handleOnOpenCalender}
            value={formattedDateRanges}
            placeholder={placeHolderInput}
            disabled={isDisabledInput}
            endAdornment={
              <InputAdornment position={'end'}>
                {!removeButtonClearDate && startDate && endDate ? (
                  <IconButton
                    id={'clearButtonDateRangePicker'}
                    className={classes.clearButton}
                    onClick={(event) => this.handleDeleteDate(event)}
                  >
                    <Close fontSize={'small'} />
                  </IconButton>
                ) : null}
                <IconButton id={'eventButtonDateRangePicker'}>
                  <Event fontSize={'small'} />
                </IconButton>
              </InputAdornment>
            }
          />
        )}

        {displayStaticWrapperAs === DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MOBILE && (
          <Box display={'flex'} flexDirection={'column'} alignItems={'end'} className={'limitWord'}>
            {isLabelCurrentDateShow && (
              <Typography fontSize={'10px'} fontWeight={600} textAlign={'right'}>
                {!formattedDateRanges ? label : formattedDateRanges}
              </Typography>
            )}
            <Button
              id={'demo-customized-button'}
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup={'true'}
              aria-expanded={open ? 'true' : undefined}
              variant={'contained'}
              color={'whiteMain'}
              size={'small'}
              disabled={isDisabledInput}
              sx={{
                border: '2px solid',
                borderColor: 'grey.200',
                borderRadius: '8px',
                padding: '4px 6px',
                minWidth: '120px',
              }}
              disableElevation
              onClick={this.handleOnOpenCalender}
              endIcon={isCalendarOpen ? <ArrowDropUp /> : <ArrowDropDown />}
            >
              {label}
            </Button>
          </Box>
        )}

        {displayStaticWrapperAs === DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MINI_DEVICE && (
          <IconButton onClick={(event) => this.handleOnOpenCalender(event)}>
            <Event color={'primary'} fontSize={'10px'} />
          </IconButton>
        )}

        <Menu
          open={isCalendarOpen}
          onClose={() => this.handleOnCloseCalendar(true, true)}
          anchorEl={anchorElCalendar}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          <Stack direction={{ xs: 'column', sm: 'row' }}>
            <DefinedRange
              ranges={this.state.selection}
              onChange={(item) => this.handleChangeDate(item)}
              color={color}
              rangeColors={rangeColors}
              staticRanges={this.customUsedDefaultStaticDateRange[locale]}
              className={clsx(classes.definedRange, {
                [classes.definedRangeMobile]: isShowDateRange,
              })}
              {...additionalPropsDefinedRange}
            />

            {isShowDateRange && (
              <Stack direction={'column'}>
                <DateRange
                  editableDateInputs
                  onChange={(item) => this.handleChangeDate(item)}
                  ranges={this.state.selection}
                  rangeColors={rangeColors}
                  locale={locales[locale]}
                  className={classes.dateRange}
                  {...additionalPropsDateRange}
                />

                <Divider />

                {displayStaticWrapperAs === DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MOBILE && (
                  <Box
                    height={'42px'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Typography
                      fontWeight={600}
                      fontSize={'14px'}
                      color={'grey.650'}
                      textAlign={'right'}
                    >
                      {temporaryMobileDateRange}
                    </Typography>
                  </Box>
                )}

                <Stack
                  pb={2}
                  pt={displayStaticWrapperAs === DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MOBILE ? 0 : 2}
                  spacing={2}
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Button
                    variant={'outlined'}
                    onClick={() => this.handleOnCloseCalendar(true, true)}
                  >
                    {textCancel}
                  </Button>
                  <Button variant={'contained'} onClick={() => this.handleApplyDate()}>
                    {textApply}
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Menu>
      </>
    );
  }
}

DateTimePicker.propsType = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  textCancel: PropTypes.string,
  textApply: PropTypes.string,
  placeHolderInput: PropTypes.string,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  rangeColors: PropTypes.array,
  color: PropTypes.string,
  locale: PropTypes.string,
  defaultKeyStartDate: PropTypes.string,
  defaultKeyEndDate: PropTypes.string,
  isDisabledInput: PropTypes.bool,
  additionalPropsDefinedRange: PropTypes.object,
  additionalPropsDateRange: PropTypes.object,
  onSelectedChange: PropTypes.func.isRequired,
  overrideFormatDate: PropTypes.string,
  isLabelCurrentDateShow: PropTypes.bool,
};

DateTimePicker.defaultProps = {
  startDate: moment().startOf('d').toISOString(true),
  endDate: moment().endOf('d').toISOString(true),
  textCancel: 'Cancel',
  textApply: 'Apply',
  placeHolderInput: 'Input Date',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  color: '#deebff',
  rangeColors: ['#0052cc', '#deebff'],
  locale: 'en',
  defaultKeyStartDate: 'startDate',
  defaultKeyEndDate: 'endDate',
  isDisabledInput: false,
  additionalPropsDefinedRange: {},
  additionalPropsDateRange: {},
  overrideFormatDate: DEFAULT_FORMAT_DATE_DAY_MONTH_YEAR_SLASH,
  displayStaticWrapperAs: DEFAULT_DISPLAY_STATIC_WRAPPER_AS_DESKTOP,
  selectedLabelNameDateRange: [],
  isLabelCurrentDateShow: false,
  removeButtonClearDate: false,
  usageTemplateForShowingDateRange: false,
  templateShowingDateRange: '{0} {1}',
  inputFullWidth: true,
};

const stylingDateTimePicker = withStyles(styles)(DateTimePicker);

const DateTimePickerWithTranslation = withTranslation()(stylingDateTimePicker);

export default DateTimePickerWithTranslation;
