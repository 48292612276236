export const DEFAULT_ELEMENT_ID_TOOLBAR_APP = 'toolbar-app';
export const DEFAULT_ELEMENT_ID_MENU_TOOLBAR = 'menu-toolbar';
export const DEFAULT_ELEMENT_ID_MENU_USER_AND_COMPANY = 'menu-user-and-company';
export const DEFAULT_ELEMENT_ID_SEPARATOR_MENU_TOOLBAR_AND_MENU_USER_COMPANY =
  'separator-menu-toolbar-n-menu-user-company';
export const DEFAULT_ELEMENT_ID_PRINT_TRANSACTION_CONTAINER = 'print-transaction-container';
export const DEFAULT_ELEMENT_ID_SIGNATURE = 'signature';
export const DEFAULT_ELEMENT_ID_FOOTER_TABLE = 'footer-table';
export const DEFAULT_ELEMENT_ID_IFM_CONTENT_STOP_PRINT = 'ifmcontentstoprint';
export const DEFAULT_ELEMENT_ID_REFRESH_BUTTON_HEADER_LIST_PRIMARY =
  'refresh-button-header-list-primary';
export const DEFAULT_ELEMENT_ID_HIDDEN_REFRESH_BUTTON = 'hidden-refresh-button';
export const DEFAULT_ELEMENT_ID_PRINT_BILLING_DETAILS = 'print-billing-details';
export const DEFAULT_ELEMENT_ID_HIDDEN_BUTTON_CHECKBOX_SUMMARY_OVERVIEW_FORM =
  'button-checkbox-summary-overview-form';
export const DEFAULT_ELEMENT_ID_SCROLL_INTOVIEW_BUTTON = 'scroll-intoview-button';
export const DEFAULT_ELEMENT_ID_SUBSCRIPTION_BANNER = 'subscription-banner';
export const DEFAULT_ELEMENT_ID_HIDDEN_BUTTON = 'hidden-button';
export const DEFAULT_ELEMENT_ID_BUTTON_BEGINNING_BALANCE_LIST_FORM = 'beginning-balance-list-form';
export const DEFAULT_ELEMENT_ID_BUTTON_SUBMIT_BASIC_BEGINNING_BALANCE =
  'submit-basic-beginning-balance';
export const DEFAULT_ELEMENT_ID_SUBMIT_BUTTON_STOCK_ADJUSTMENT = 'submit-button-stock-adjustment';
export const DEFAULT_ELEMENT_ID_COMPOSITION_MENU_ICON_BUTTONS = 'composition-menu-icon-buttons';
export const DEFAULT_ELEMENT_ID_BUTTON_TRANSACTION_COLLECTIONS = 'button-transaction-collections';
export const DEFAULT_ELEMENT_ID_CLOSING_BOOK_TABLE_PRINT = 'closing-book-table-print';
export const DEFAULT_ELEMENT_ID_DIALOG_PDF_PRINT_COMMON = 'dialog-pdf-print';
