import {
  LOCAL_STORAGE_ACCOUNT_CATEGORY_PRINT,
  LOCAL_STORAGE_ACCOUNT_DATA_PRINT,
  LOCAL_STORAGE_ASSET_CATEGORY_PRINT,
  LOCAL_STORAGE_ASSET_MANAGEMENT_PRINT,
  LOCAL_STORAGE_CONTACT_PRINT_DATA,
  LOCAL_STORAGE_PRODUCT_CATEGORY_PRINT,
  LOCAL_STORAGE_PRODUCT_PRINT_DATA,
  LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_LIST_PDF_PRINT,
  LOCAL_STORAGE_PURCHASE_DELIVERY_PDF_PRINT,
  LOCAL_STORAGE_PURCHASE_INVOICE_PDF_PRINT,
  LOCAL_STORAGE_PURCHASE_ORDER_PDF_PRINT,
  LOCAL_STORAGE_PURCHASE_PAYMENT_PDF_PRINT,
  LOCAL_STORAGE_PURCHASE_RECEIVABLE_PAYMENT_LIST_PDF_PRINT,
  LOCAL_STORAGE_PURCHASE_RETURN_LIST_PDF_PRINT,
  LOCAL_STORAGE_QUOTATION_REQUEST_PRINT_PDF,
  LOCAL_STORAGE_SALES_CREDIT_NOTE_LIST_PRINT_PDF,
  LOCAL_STORAGE_SALES_DELIVERY_PRINT_PDF,
  LOCAL_STORAGE_SALES_INVOICE_PRINT_PDF,
  LOCAL_STORAGE_SALES_ORDER_PRINT_PDF,
  LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_LIST_PRINT_PDF,
  LOCAL_STORAGE_SALES_PAYMENT_PRINT_PDF,
  LOCAL_STORAGE_SALES_QUOTATION_PRINT_PDF,
  LOCAL_STORAGE_SALES_RETURN_LIST_PRINT_PDF,
  LOCAL_STORAGE_STOCK_ADJUSTMENT_PRINT_LIST,
  LOCAL_STORAGE_STOCK_TRANSFER_PRINT_LIST,
  LOCAL_STORAGE_TRANSACTION_PRINT_LIST,
} from '../constants/storage.constant';
import {
  REQUEST_STATUS_LIST_ACCOUNT_CATEGORY_PRINT_PDF_FAILED,
  REQUEST_STATUS_LIST_ACCOUNT_CATEGORY_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_ACCOUNT_CATEGORY_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_ACCOUNT_DATA_PRINT_PDF_FAILED,
  REQUEST_STATUS_LIST_ACCOUNT_DATA_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_ACCOUNT_DATA_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_ASSET_CATEGORY_PRINT_PDF_FAILED,
  REQUEST_STATUS_LIST_ASSET_CATEGORY_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_ASSET_CATEGORY_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_ASSET_MANAGEMENT_PRINT_PDF_FAILED,
  REQUEST_STATUS_LIST_ASSET_MANAGEMENT_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_ASSET_MANAGEMENT_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_CONTACT_DATA_PRINT_PDF_FAILED,
  REQUEST_STATUS_LIST_CONTACT_DATA_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_CONTACT_DATA_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_PRODUCT_CATEGORY_PRINT_PDF_FAILED,
  REQUEST_STATUS_LIST_PRODUCT_CATEGORY_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_PRODUCT_CATEGORY_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_PRODUCT_DATA_PRINT_PDF_FAILED,
  REQUEST_STATUS_LIST_PRODUCT_DATA_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_PRODUCT_DATA_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_PRINT_PDF_FAILED,
  REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_PRINT_PDF_LOADING,
  REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_PURCHASE_RETURN_PRINT_PDF_FAILED,
  REQUEST_STATUS_LIST_PURCHASE_RETURN_PRINT_PDF_LOADING,
  REQUEST_STATUS_LIST_PURCHASE_RETURN_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_PRINT_PDF_FAILED,
  REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_PRINT_PDF_LOADING,
  REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_SALES_RETURN_PRINT_PDF_FAILED,
  REQUEST_STATUS_LIST_SALES_RETURN_PRINT_PDF_LOADING,
  REQUEST_STATUS_LIST_SALES_RETURN_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_STOCK_ADJUSTMENT_PRINT_PDF_FAILED,
  REQUEST_STATUS_LIST_STOCK_ADJUSTMENT_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_STOCK_ADJUSTMENT_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_STOCK_TRANSFER_PRINT_PDF_FAILED,
  REQUEST_STATUS_LIST_STOCK_TRANSFER_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_STOCK_TRANSFER_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_TRANSACTION_PRINT_PDF_FAILED,
  REQUEST_STATUS_LIST_TRANSACTION_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_TRANSACTION_PRINT_PDF_SUCCESS,
  SLICE_NAME_ACCOUNT_CATEGORY_PRINT,
  SLICE_NAME_ACCOUNT_PRINT_DATA,
  SLICE_NAME_ASSET_CATEGORY_PRINT_PDF_DATA,
  SLICE_NAME_ASSET_MANAGEMENT_PRINT_PDF_DATA,
  SLICE_NAME_CONTACT_PRINT_DATA,
  SLICE_NAME_DATA_PURCHASE_INVOICE_PDF_PRINT,
  SLICE_NAME_PRODUCT_CATEGORY_PRINT_DATA,
  SLICE_NAME_PRODUCT_PRINT_DATA,
  SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST_PDF_PRINT,
  SLICE_NAME_PURCHASE_DELIVERY_DATA_PDF_PRINT,
  SLICE_NAME_PURCHASE_ORDER_DATA_PDF_PRINT,
  SLICE_NAME_PURCHASE_PAYMENT_DATA_PDF_PRINT,
  SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_LIST_PDF_PRINT,
  SLICE_NAME_PURCHASE_RETURN_LIST_PDF_PRINT,
  SLICE_NAME_QUOTATION_REQUEST_DATA_PDF_PRINT,
  SLICE_NAME_SALES_CREDIT_NOTE_PRINT_PDF_LIST,
  SLICE_NAME_SALES_DELIVERY_PRINT_PDF_DATA,
  SLICE_NAME_SALES_INVOICE_PRINT_PDF_DATA,
  SLICE_NAME_SALES_ORDER_PRINT_PDF_DATA,
  SLICE_NAME_SALES_PAYABLE_PAYMENT_PRINT_PDF_LIST,
  SLICE_NAME_SALES_PAYMENT_PRINT_PDF_DATA,
  SLICE_NAME_SALES_QUOTATION_PRINT_PDF_DATA,
  SLICE_NAME_SALES_RETURN_PRINT_PDF_LIST,
  SLICE_NAME_STOCK_ADJUSTMENT_PRINT_LIST,
  SLICE_NAME_STOCK_TRANSFER_PRINT_LIST,
  SLICE_NAME_TRANSACTION_PRINT_DATA_LIST,
  STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_PRINT_PDF_FAILED,
  STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_PRINT_PDF_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_PRINT_PDF_SUCCESS,
  STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PRINT_PDF_FAILED,
  STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PRINT_PDF_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PRINT_PDF_SUCCESS,
  STATUS_REQUEST_LIST_PURCHASE_INVOICE_PRINT_PDF_FAILED,
  STATUS_REQUEST_LIST_PURCHASE_INVOICE_PRINT_PDF_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_INVOICE_PRINT_PDF_SUCCESS,
  STATUS_REQUEST_LIST_PURCHASE_ORDER_PRINT_PDF_FAILED,
  STATUS_REQUEST_LIST_PURCHASE_ORDER_PRINT_PDF_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_ORDER_PRINT_PDF_SUCCESS,
  STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PRINT_PDF_FAILED,
  STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PRINT_PDF_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PRINT_PDF_SUCCESS,
  STATUS_REQUEST_LIST_QUOTATION_REQUEST_PRINT_PDF_FAILED,
  STATUS_REQUEST_LIST_QUOTATION_REQUEST_PRINT_PDF_PENDING,
  STATUS_REQUEST_LIST_QUOTATION_REQUEST_PRINT_PDF_SUCCESS,
  STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_PRINT_PDF_FAILED,
  STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_PRINT_PDF_PENDING,
  STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_PRINT_PDF_SUCCESS,
  STATUS_REQUEST_LIST_SALES_DELIVERY_PRINT_PDF_FAILED,
  STATUS_REQUEST_LIST_SALES_DELIVERY_PRINT_PDF_PENDING,
  STATUS_REQUEST_LIST_SALES_DELIVERY_PRINT_PDF_SUCCESS,
  STATUS_REQUEST_LIST_SALES_INVOICE_PRINT_PDF_FAILED,
  STATUS_REQUEST_LIST_SALES_INVOICE_PRINT_PDF_PENDING,
  STATUS_REQUEST_LIST_SALES_INVOICE_PRINT_PDF_SUCCESS,
  STATUS_REQUEST_LIST_SALES_ORDER_PRINT_PDF_FAILED,
  STATUS_REQUEST_LIST_SALES_ORDER_PRINT_PDF_PENDING,
  STATUS_REQUEST_LIST_SALES_ORDER_PRINT_PDF_SUCCESS,
  STATUS_REQUEST_LIST_SALES_PAYMENT_PRINT_PDF_FAILED,
  STATUS_REQUEST_LIST_SALES_PAYMENT_PRINT_PDF_PENDING,
  STATUS_REQUEST_LIST_SALES_PAYMENT_PRINT_PDF_SUCCESS,
  STATUS_REQUEST_LIST_SALES_QUOTATION_PRINT_PDF_FAILED,
  STATUS_REQUEST_LIST_SALES_QUOTATION_PRINT_PDF_PENDING,
  STATUS_REQUEST_LIST_SALES_QUOTATION_PRINT_PDF_SUCCESS,
} from '../constants/store.constant';
import { generatePayloadAction } from '../helpers/slice-reducer.helper';

// sales quotation
export const salesQuotationPDFprintPayloadAction = {
  requestStatusPending: STATUS_REQUEST_LIST_SALES_QUOTATION_PRINT_PDF_PENDING,
  requestStatusSuccess: STATUS_REQUEST_LIST_SALES_QUOTATION_PRINT_PDF_SUCCESS,
  requestStatusFailed: STATUS_REQUEST_LIST_SALES_QUOTATION_PRINT_PDF_FAILED,
  slicePayloadKeyname: SLICE_NAME_SALES_QUOTATION_PRINT_PDF_DATA,
  localStorageName: LOCAL_STORAGE_SALES_QUOTATION_PRINT_PDF,
  paginatePageParams: {},
};

// sales order
export const salesOrderPDFprintPayloadAction = {
  requestStatusPending: STATUS_REQUEST_LIST_SALES_ORDER_PRINT_PDF_PENDING,
  requestStatusSuccess: STATUS_REQUEST_LIST_SALES_ORDER_PRINT_PDF_SUCCESS,
  requestStatusFailed: STATUS_REQUEST_LIST_SALES_ORDER_PRINT_PDF_FAILED,
  slicePayloadKeyname: SLICE_NAME_SALES_ORDER_PRINT_PDF_DATA,
  localStorageName: LOCAL_STORAGE_SALES_ORDER_PRINT_PDF,
  paginatePageParams: {},
};

// sales delivery
export const salesDeliveryPDFprintPayloadAction = {
  requestStatusPending: STATUS_REQUEST_LIST_SALES_DELIVERY_PRINT_PDF_PENDING,
  requestStatusSuccess: STATUS_REQUEST_LIST_SALES_DELIVERY_PRINT_PDF_SUCCESS,
  requestStatusFailed: STATUS_REQUEST_LIST_SALES_DELIVERY_PRINT_PDF_FAILED,
  slicePayloadKeyname: SLICE_NAME_SALES_DELIVERY_PRINT_PDF_DATA,
  localStorageName: LOCAL_STORAGE_SALES_DELIVERY_PRINT_PDF,
  paginatePageParams: {},
};

// sales invoice
export const salesInvoicePDFprintPayloadAction = {
  requestStatusPending: STATUS_REQUEST_LIST_SALES_INVOICE_PRINT_PDF_PENDING,
  requestStatusSuccess: STATUS_REQUEST_LIST_SALES_INVOICE_PRINT_PDF_SUCCESS,
  requestStatusFailed: STATUS_REQUEST_LIST_SALES_INVOICE_PRINT_PDF_FAILED,
  slicePayloadKeyname: SLICE_NAME_SALES_INVOICE_PRINT_PDF_DATA,
  localStorageName: LOCAL_STORAGE_SALES_INVOICE_PRINT_PDF,
  paginatePageParams: {},
};

// sales payment
export const salesPaymentPDFprintPayloadAction = {
  requestStatusPending: STATUS_REQUEST_LIST_SALES_PAYMENT_PRINT_PDF_PENDING,
  requestStatusSuccess: STATUS_REQUEST_LIST_SALES_PAYMENT_PRINT_PDF_SUCCESS,
  requestStatusFailed: STATUS_REQUEST_LIST_SALES_PAYMENT_PRINT_PDF_FAILED,
  slicePayloadKeyname: SLICE_NAME_SALES_PAYMENT_PRINT_PDF_DATA,
  localStorageName: LOCAL_STORAGE_SALES_PAYMENT_PRINT_PDF,
  paginatePageParams: {},
};

// sales return
export const salesReturnPDFprintPayloadAction = {
  requestStatusPending: REQUEST_STATUS_LIST_SALES_RETURN_PRINT_PDF_LOADING,
  requestStatusSuccess: REQUEST_STATUS_LIST_SALES_RETURN_PRINT_PDF_SUCCESS,
  requestStatusFailed: REQUEST_STATUS_LIST_SALES_RETURN_PRINT_PDF_FAILED,
  slicePayloadKeyname: SLICE_NAME_SALES_RETURN_PRINT_PDF_LIST,
  localStorageName: LOCAL_STORAGE_SALES_RETURN_LIST_PRINT_PDF,
  paginatePageParams: {},
};

// sales credit note
export const salesCreditNotePDFprintPayloadAction = {
  requestStatusPending: STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_PRINT_PDF_PENDING,
  requestStatusSuccess: STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_PRINT_PDF_SUCCESS,
  requestStatusFailed: STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_PRINT_PDF_FAILED,
  slicePayloadKeyname: SLICE_NAME_SALES_CREDIT_NOTE_PRINT_PDF_LIST,
  localStorageName: LOCAL_STORAGE_SALES_CREDIT_NOTE_LIST_PRINT_PDF,
  paginatePageParams: {},
};

// sales payable payment
export const salesPayablePaymentPDFprintPayloadAction = {
  requestStatusPending: REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_PRINT_PDF_LOADING,
  requestStatusSuccess: REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_PRINT_PDF_SUCCESS,
  requestStatusFailed: REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_PRINT_PDF_FAILED,
  slicePayloadKeyname: SLICE_NAME_SALES_PAYABLE_PAYMENT_PRINT_PDF_LIST,
  localStorageName: LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_LIST_PRINT_PDF,
  paginatePageParams: {},
};

// purchase quotation request
export const purchaseQuotationRequestPDFprintPayloadAction = {
  requestStatusPending: STATUS_REQUEST_LIST_QUOTATION_REQUEST_PRINT_PDF_PENDING,
  requestStatusSuccess: STATUS_REQUEST_LIST_QUOTATION_REQUEST_PRINT_PDF_SUCCESS,
  requestStatusFailed: STATUS_REQUEST_LIST_QUOTATION_REQUEST_PRINT_PDF_FAILED,
  slicePayloadKeyname: SLICE_NAME_QUOTATION_REQUEST_DATA_PDF_PRINT,
  localStorageName: LOCAL_STORAGE_QUOTATION_REQUEST_PRINT_PDF,
  paginatePageParams: {},
};

// purchase order
export const purchaseOrderPDFprintPayloadAction = {
  requestStatusPending: STATUS_REQUEST_LIST_PURCHASE_ORDER_PRINT_PDF_PENDING,
  requestStatusSuccess: STATUS_REQUEST_LIST_PURCHASE_ORDER_PRINT_PDF_SUCCESS,
  requestStatusFailed: STATUS_REQUEST_LIST_PURCHASE_ORDER_PRINT_PDF_FAILED,
  slicePayloadKeyname: SLICE_NAME_PURCHASE_ORDER_DATA_PDF_PRINT,
  localStorageName: LOCAL_STORAGE_PURCHASE_ORDER_PDF_PRINT,
  paginatePageParams: {},
};

// purchase delivery
export const purchaseDeliveryPDFprintPayloadAction = {
  requestStatusPending: STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PRINT_PDF_PENDING,
  requestStatusSuccess: STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PRINT_PDF_SUCCESS,
  requestStatusFailed: STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PRINT_PDF_FAILED,
  slicePayloadKeyname: SLICE_NAME_PURCHASE_DELIVERY_DATA_PDF_PRINT,
  localStorageName: LOCAL_STORAGE_PURCHASE_DELIVERY_PDF_PRINT,
  paginatePageParams: {},
};

// purchase invoice
export const purchaseInvoicePDFprintPayloadAction = {
  requestStatusPending: STATUS_REQUEST_LIST_PURCHASE_INVOICE_PRINT_PDF_PENDING,
  requestStatusSuccess: STATUS_REQUEST_LIST_PURCHASE_INVOICE_PRINT_PDF_SUCCESS,
  requestStatusFailed: STATUS_REQUEST_LIST_PURCHASE_INVOICE_PRINT_PDF_FAILED,
  slicePayloadKeyname: SLICE_NAME_DATA_PURCHASE_INVOICE_PDF_PRINT,
  localStorageName: LOCAL_STORAGE_PURCHASE_INVOICE_PDF_PRINT,
  paginatePageParams: {},
};

// purchase payment
export const purchasePaymentPDFprintPayloadAction = {
  requestStatusPending: STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PRINT_PDF_PENDING,
  requestStatusSuccess: STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PRINT_PDF_SUCCESS,
  requestStatusFailed: STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PRINT_PDF_FAILED,
  slicePayloadKeyname: SLICE_NAME_PURCHASE_PAYMENT_DATA_PDF_PRINT,
  localStorageName: LOCAL_STORAGE_PURCHASE_PAYMENT_PDF_PRINT,
  paginatePageParams: {},
};

// purchase return
export const purchaseReturnPDFprintPayloadAction = {
  requestStatusPending: REQUEST_STATUS_LIST_PURCHASE_RETURN_PRINT_PDF_LOADING,
  requestStatusSuccess: REQUEST_STATUS_LIST_PURCHASE_RETURN_PRINT_PDF_SUCCESS,
  requestStatusFailed: REQUEST_STATUS_LIST_PURCHASE_RETURN_PRINT_PDF_FAILED,
  slicePayloadKeyname: SLICE_NAME_PURCHASE_RETURN_LIST_PDF_PRINT,
  localStorageName: LOCAL_STORAGE_PURCHASE_RETURN_LIST_PDF_PRINT,
  paginatePageParams: {},
};

// purchase debit note
export const purchaseDebitNotePDFprintPayloadAction = {
  requestStatusPending: STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_PRINT_PDF_PENDING,
  requestStatusSuccess: STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_PRINT_PDF_SUCCESS,
  requestStatusFailed: STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_PRINT_PDF_FAILED,
  slicePayloadKeyname: SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST_PDF_PRINT,
  localStorageName: LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_LIST_PDF_PRINT,
  paginatePageParams: {},
};

// purchase receivable payment
export const purchaseReceivablePaymentPDFprintPayloadAction = {
  requestStatusPending: REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_PRINT_PDF_LOADING,
  requestStatusSuccess: REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_PRINT_PDF_SUCCESS,
  requestStatusFailed: REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_PRINT_PDF_FAILED,
  slicePayloadKeyname: SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_LIST_PDF_PRINT,
  localStorageName: LOCAL_STORAGE_PURCHASE_RECEIVABLE_PAYMENT_LIST_PDF_PRINT,
  paginatePageParams: {},
};

// contact
export const contactDataPrintPayloadAction = generatePayloadAction(
  REQUEST_STATUS_LIST_CONTACT_DATA_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_CONTACT_DATA_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_CONTACT_DATA_PRINT_PDF_FAILED,
  SLICE_NAME_CONTACT_PRINT_DATA,
  LOCAL_STORAGE_CONTACT_PRINT_DATA,
);

// product
export const productDataListPrintPayloadAction = generatePayloadAction(
  REQUEST_STATUS_LIST_PRODUCT_DATA_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_PRODUCT_DATA_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_PRODUCT_DATA_PRINT_PDF_FAILED,
  SLICE_NAME_PRODUCT_PRINT_DATA,
  LOCAL_STORAGE_PRODUCT_PRINT_DATA,
);

// product category
export const productCategoryListPrintPayloadAction = generatePayloadAction(
  REQUEST_STATUS_LIST_PRODUCT_CATEGORY_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_PRODUCT_CATEGORY_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_PRODUCT_CATEGORY_PRINT_PDF_FAILED,
  SLICE_NAME_PRODUCT_CATEGORY_PRINT_DATA,
  LOCAL_STORAGE_PRODUCT_CATEGORY_PRINT,
);

// account
export const accountDataListPrintPayloadAction = generatePayloadAction(
  REQUEST_STATUS_LIST_ACCOUNT_DATA_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_ACCOUNT_DATA_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_ACCOUNT_DATA_PRINT_PDF_FAILED,
  SLICE_NAME_ACCOUNT_PRINT_DATA,
  LOCAL_STORAGE_ACCOUNT_DATA_PRINT,
);

// account category
export const accountCategoryListPrintPayloadAction = generatePayloadAction(
  REQUEST_STATUS_LIST_ACCOUNT_CATEGORY_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_ACCOUNT_CATEGORY_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_ACCOUNT_CATEGORY_PRINT_PDF_FAILED,
  SLICE_NAME_ACCOUNT_CATEGORY_PRINT,
  LOCAL_STORAGE_ACCOUNT_CATEGORY_PRINT,
);

// asset management
export const assetManagementListPrintPayloadAction = generatePayloadAction(
  REQUEST_STATUS_LIST_ASSET_MANAGEMENT_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_ASSET_MANAGEMENT_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_ASSET_MANAGEMENT_PRINT_PDF_FAILED,
  SLICE_NAME_ASSET_MANAGEMENT_PRINT_PDF_DATA,
  LOCAL_STORAGE_ASSET_MANAGEMENT_PRINT,
);

// asset category
export const assetCategoryListPrintPayloadAction = generatePayloadAction(
  REQUEST_STATUS_LIST_ASSET_CATEGORY_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_ASSET_CATEGORY_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_ASSET_CATEGORY_PRINT_PDF_FAILED,
  SLICE_NAME_ASSET_CATEGORY_PRINT_PDF_DATA,
  LOCAL_STORAGE_ASSET_CATEGORY_PRINT,
);

// stock adjustment
export const stockAdjustmentListPrintPayloadAction = generatePayloadAction(
  REQUEST_STATUS_LIST_STOCK_ADJUSTMENT_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_STOCK_ADJUSTMENT_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_STOCK_ADJUSTMENT_PRINT_PDF_FAILED,
  SLICE_NAME_STOCK_ADJUSTMENT_PRINT_LIST,
  LOCAL_STORAGE_STOCK_ADJUSTMENT_PRINT_LIST,
);

// stock transfer
export const stockTransferListPrintPayloadAction = generatePayloadAction(
  REQUEST_STATUS_LIST_STOCK_TRANSFER_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_STOCK_TRANSFER_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_STOCK_TRANSFER_PRINT_PDF_FAILED,
  SLICE_NAME_STOCK_TRANSFER_PRINT_LIST,
  LOCAL_STORAGE_STOCK_TRANSFER_PRINT_LIST,
);

// transaction
export const transactionListPrintPayloadAction = generatePayloadAction(
  REQUEST_STATUS_LIST_TRANSACTION_PRINT_PDF_PENDING,
  REQUEST_STATUS_LIST_TRANSACTION_PRINT_PDF_SUCCESS,
  REQUEST_STATUS_LIST_TRANSACTION_PRINT_PDF_FAILED,
  SLICE_NAME_TRANSACTION_PRINT_DATA_LIST,
  LOCAL_STORAGE_TRANSACTION_PRINT_LIST,
);
