import * as React from 'react';
function SvgSalesPayablePaymentMenu(props) {
  return (
    <svg width={100} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#sales-payable-payment-menu_svg__filter0_d_5586_192291)'>
        <path d='M44.102 1h37.345l9.845 10.655v54.9h-47.19V1z' fill='#F3F3F8' />
        <path d='M81.446 1v10.655h9.846L81.445 1z' fill='#CCC' />
        <path
          d='M73.96 18.808L54 18.843c-.551.001-.999-.42-1-.939 0-.52.446-.942.997-.943l19.96-.035c.55 0 .998.42 1 .94 0 .519-.446.941-.997.942zM83.934 15.027l-29.94.053c-.55 0-.998-.42-.999-.94 0-.519.446-.94.997-.942l29.94-.053c.55 0 .998.42.998.94.001.52-.445.941-.996.942z'
          fill='#C3E678'
        />
        <path
          d='M55.004 22.604l-.998.002c-.551.001-.999-.42-1-.939 0-.52.446-.942.997-.943l.998-.001c.551-.001.999.42 1 .939 0 .52-.446.941-.997.942z'
          fill='#C7CFE2'
        />
        <path
          d='M78.955 22.562l-19.96.035c-.55.001-.998-.42-.999-.939 0-.52.445-.942.997-.943l19.96-.035c.55 0 .998.42.999.94 0 .52-.446.941-.997.942z'
          fill='#D7DEED'
        />
        <path
          d='M55.01 26.367l-.998.002c-.551.001-1-.42-1-.939 0-.52.446-.942.997-.942l.998-.002c.55-.001.998.42 1 .939 0 .52-.446.941-.997.942z'
          fill='#C7CFE2'
        />
        <path
          d='M76.965 26.329l-17.964.031c-.55.001-.998-.419-1-.939 0-.52.446-.941.998-.942l17.963-.032c.551 0 .999.42 1 .94 0 .519-.446.94-.997.942z'
          fill='#D7DEED'
        />
        <path
          d='M55.015 30.13l-.998.002c-.551.001-.999-.42-1-.939 0-.52.446-.941.997-.942l.998-.002c.551-.001.999.42 1 .94 0 .519-.446.94-.997.942z'
          fill='#C7CFE2'
        />
        <path
          d='M78.966 30.088l-19.96.035c-.55.001-.998-.42-.999-.939 0-.52.446-.941.997-.942l19.96-.035c.55-.001.998.42.999.939 0 .52-.445.941-.997.942z'
          fill='#D7DEED'
        />
        <path
          d='M85.117 60.903H37.383c-1.835 0-3.323-1.61-3.323-3.597V35.072c0-1.987 1.488-3.597 3.323-3.597h47.735c1.835 0 3.323 1.61 3.323 3.597v22.234c0 1.986-1.488 3.597-3.324 3.597z'
          fill='#D35933'
        />
        <path
          d='M84.689 25.91L33.148 36.004l4.664 27.892L89.353 53.8l-4.664-27.892z'
          fill='#8BC180'
        />
        <path d='M84.69 25.912l-23.561 4.615v28.804l28.224-5.528-4.663-27.89z' fill='#6CAF5F' />
        <path
          d='M90.251 53.628l-4.664-27.891a.999.999 0 00-.377-.636.859.859 0 00-.683-.16l-23.398 4.583v2.007l18.652-3.653c.759 2.227 2.735 3.71 4.929 3.699l3.583 21.431-27.164 5.32v2.007l28.387-5.56c.495-.097.825-.61.735-1.147zM38.547 62.75l-4.34-25.947 26.922-5.272v-2.007l-28.144 5.512c-.495.097-.825.611-.735 1.148l4.663 27.89a.998.998 0 00.378.636.866.866 0 00.683.16l23.154-4.535v-2.007l-22.581 4.423z'
          fill='#477F3C'
        />
        <path
          d='M36.878 58.32l.933 5.578 5.154-1.01c-.515-3.08-3.24-5.125-6.087-4.568zM34.08 41.585c2.847-.557 4.737-3.506 4.222-6.587l-5.154 1.01.932 5.577zM88.42 48.225c-2.847.557-4.737 3.507-4.222 6.588l5.155-1.01-.933-5.578z'
          fill='#477F3C'
        />
        <path
          d='M87.68 69H34.82c-2.032 0-3.68-1.783-3.68-3.983V40.396c0-2.2 1.648-3.983 3.68-3.983h52.86c2.032 0 3.68 1.783 3.68 3.982v24.622c0 2.2-1.647 3.983-3.68 3.983z'
          fill='#AC451E'
        />
        <path
          d='M87.68 36.413H61.128V69H87.68c2.032 0 3.68-1.783 3.68-3.983V40.396c0-2.2-1.647-3.983-3.68-3.983z'
          fill='#8D3716'
        />
        <path
          d='M62.576 48.065h-2.65c-1.385 0-2.508-1.215-2.508-2.713V27.869c0-1.498 1.123-2.713 2.508-2.713h2.65c1.384 0 2.507 1.215 2.507 2.713v17.483c0 1.498-1.123 2.713-2.507 2.713z'
          fill='#FFC61B'
        />
        <path
          d='M62.575 25.155h-1.447v22.91h1.447c1.385 0 2.507-1.214 2.507-2.713V27.869c0-1.499-1.122-2.713-2.507-2.713z'
          fill='#EAA22F'
        />
        <path
          d='M61.25 45.893c1.109 0 2.008-.973 2.008-2.172 0-1.2-.899-2.173-2.008-2.173-1.108 0-2.007.973-2.007 2.173 0 1.2.899 2.172 2.007 2.172z'
          fill='#A30F44'
        />
        <path
          d='M61.25 41.548c-.04 0-.08.004-.121.006v4.332c.04.003.08.007.121.007 1.11 0 2.008-.973 2.008-2.173 0-1.2-.899-2.172-2.008-2.172z'
          fill='#930000'
        />
        <path
          d='M22.133 60.423h2.4v7.71c0 .479-.537.867-1.2.867-.663 0-1.2-.388-1.2-.867v-7.71z'
          fill='#FFC61B'
        />
        <path
          d='M23.253 60.423v8.571c.027.001.053.006.08.006.663 0 1.2-.388 1.2-.867v-7.71h-1.28zM24.456 56.188l2.286-.529 5.423 12.21c.202.456-.146.943-.777 1.09-.631.145-1.307-.105-1.51-.561l-5.422-12.21z'
          fill='#EAA22F'
        />
        <path
          d='M22.21 56.188l-2.286-.529-5.422 12.21c-.203.456.145.943.776 1.09.631.145 1.307-.105 1.51-.561l5.421-12.21z'
          fill='#FFC61B'
        />
        <path d='M43.136 38.028H3.53V59.86h39.606V38.028z' fill='#fff' />
        <path d='M43.136 38.028H23.254V59.86h19.882V38.028z' fill='#D0D1D3' />
        <path
          d='M25.314 36.859h-3.961v-1.43c0-.789.887-1.429 1.98-1.429 1.094 0 1.98.64 1.98 1.43v1.429z'
          fill='#FFC61B'
        />
        <path
          d='M25.314 35.43c0-.79-.887-1.43-1.98-1.43-.027 0-.054.002-.08.003v2.856h2.06v-1.43z'
          fill='#EAA22F'
        />
        <path
          d='M43.576 39.184H3.09c-1.016 0-1.84-.595-1.84-1.329 0-.733.824-1.328 1.84-1.328h40.486c1.017 0 1.84.594 1.84 1.328 0 .734-.824 1.329-1.84 1.329zM43.576 61.073H3.09c-1.016 0-1.84-.595-1.84-1.328 0-.734.824-1.329 1.84-1.329h40.486c1.017 0 1.84.595 1.84 1.329 0 .733-.824 1.328-1.84 1.328z'
          fill='#71E2EF'
        />
        <path
          d='M43.576 36.527H23.253v2.657h20.323c1.017 0 1.84-.595 1.84-1.329s-.823-1.328-1.84-1.328zM43.576 58.416H23.253v2.657h20.323c1.017 0 1.84-.595 1.84-1.328 0-.734-.823-1.329-1.84-1.329z'
          fill='#38C6D9'
        />
        <path
          d='M36.424 45.44l-10.17 5.673-9.947-2.394L8 53.304l.73.688 7.8-4.306 9.952 2.395 10.676-5.955-.734-.686z'
          fill='#59595B'
        />
        <path
          d='M16.53 49.686l-7.8 4.306-.73-.688 8.307-4.585 6.947 1.672v.913l-6.723-1.618z'
          fill='#59595B'
        />
        <path
          d='M26.481 52.08l-3.228-.776v-.913l3.001.722 10.17-5.673.733.686-10.676 5.955z'
          fill='#272525'
        />
        <path
          d='M26.367 50.4c.916 0 1.658.536 1.658 1.197s-.742 1.197-1.658 1.197c-.916 0-1.658-.536-1.658-1.197s.742-1.197 1.658-1.197z'
          fill='#C92F00'
        />
        <path
          d='M16.418 48.005c.916 0 1.658.536 1.658 1.197 0 .662-.742 1.197-1.658 1.197-.916 0-1.658-.535-1.658-1.197 0-.66.742-1.197 1.658-1.197z'
          fill='#FF7F4F'
        />
        <path
          d='M36.79 44.585c.915 0 1.658.536 1.658 1.197s-.743 1.197-1.659 1.197c-.915 0-1.658-.536-1.658-1.197 0-.66.743-1.197 1.658-1.197z'
          fill='#EAA22F'
        />
        <path
          d='M8.365 52.452c.916 0 1.658.535 1.658 1.196 0 .661-.742 1.197-1.658 1.197-.916 0-1.658-.535-1.658-1.197 0-.66.742-1.197 1.658-1.197z'
          fill='#FF314F'
        />
      </g>
      <defs>
        <filter
          id='sales-payable-payment-menu_svg__filter0_d_5586_192291'
          x={0.25}
          y={0}
          width={106}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_192291' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_192291' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgSalesPayablePaymentMenu;
