import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_MANAGEMENT_ROLE } from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  SLICE_NAME_MANAGEMENT_ROLE_DATA,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_LIST_MANAGEMENT_ROLES_FAILED,
  STATUS_REQUEST_LIST_MANAGEMENT_ROLES_PENDING,
  STATUS_REQUEST_LIST_MANAGEMENT_ROLES_SUCCESS,
} from '../../utils/constants/store.constant';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import { listRoles } from './management-role.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  [SLICE_NAME_MANAGEMENT_ROLE_DATA]: LocalStorage.get(LOCAL_STORAGE_MANAGEMENT_ROLE),
};

const managementRolesSlice = createSlice({
  name: NAME_STORE.MANAGEMENT_ROLE,
  initialState,
  reducers: {
    listRoles,
    clearManagementRoles: (state, action) => {
      LocalStorage.remove(LOCAL_STORAGE_MANAGEMENT_ROLE);

      if (action && action.payload && action.payload.removeLocalStorageOnly) return state;

      return {
        ...state,
        [SLICE_NAME_MANAGEMENT_ROLE_DATA]: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      [SLICE_NAME_MANAGEMENT_ROLE_DATA]: LOCAL_STORAGE_MANAGEMENT_ROLE,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(listRoles.fulfilled, (state, action) => {
      state.statusRequest = STATUS_REQUEST_LIST_MANAGEMENT_ROLES_SUCCESS;
      state[SLICE_NAME_MANAGEMENT_ROLE_DATA] = action.payload[SLICE_NAME_MANAGEMENT_ROLE_DATA];
    }),
      builder.addCase(listRoles.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_MANAGEMENT_ROLES_PENDING;
      }),
      builder.addCase(listRoles.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_MANAGEMENT_ROLES_FAILED;
        state[SLICE_NAME_MANAGEMENT_ROLE_DATA] = null;
      });
  },
});

export const { reducer, actions } = managementRolesSlice;

export const { clearManagementRoles } = actions;

export default reducer;
