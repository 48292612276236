export const abbrevationUnitNameWithOriginUnitName = {
  // pcs: 'pieces',
  // kg: 'kilogram',
  liter: 'l',
  pieces: 'pcs',
  kilogram: 'kg',
};

export const productUnits = {
  pcs: 'pcs',
};

export const hasImplementedUnitProductUnit = true;
export const keynamesImpelementProductUnit = ['unit_destination', 'unit_origin', 'quantity_origin'];
