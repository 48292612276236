import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { BASE_TYPES_JOURNAL_ENTRY_ACTION_REGISTERED } from '../../utils/constants/base-types-action-registered.constant';
import {
  LOCAL_STORAGE_JOURNAL_ENTRY,
  LOCAL_STORAGE_JOURNAL_ENTRY_DETAIL,
} from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  STATUS_REQUEST_ADD_JOURNAL_ENTRY_FAILED,
  STATUS_REQUEST_ADD_JOURNAL_ENTRY_PENDING,
  STATUS_REQUEST_ADD_JOURNAL_ENTRY_SUCCESS,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_DELETE_JOURNAL_ENTRY_FAILED,
  STATUS_REQUEST_DELETE_JOURNAL_ENTRY_PENDING,
  STATUS_REQUEST_DELETE_JOURNAL_ENTRY_SUCCESS,
  STATUS_REQUEST_DETAIL_JOURNAL_ENTRY_FAILED,
  STATUS_REQUEST_DETAIL_JOURNAL_ENTRY_PENDING,
  STATUS_REQUEST_DETAIL_JOURNAL_ENTRY_SUCCESS,
  STATUS_REQUEST_LIST_JOURNAL_ENTRY_FAILED,
  STATUS_REQUEST_LIST_JOURNAL_ENTRY_PENDING,
  STATUS_REQUEST_LIST_JOURNAL_ENTRY_SUCCESS,
  STATUS_REQUEST_UPDATE_JOURNAL_ENTRY_FAILED,
  STATUS_REQUEST_UPDATE_JOURNAL_ENTRY_PENDING,
  STATUS_REQUEST_UPDATE_JOURNAL_ENTRY_SUCCESS,
} from '../../utils/constants/store.constant';
import { journalEntryAsyncThunkTypeWithActionProps } from '../../utils/data/mapping/async-thunk-type-with-action-props.mapping';
import { DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS } from '../../utils/default/params.default';
import formatHelp from '../../utils/helpers/format.helpers';
import objHelper from '../../utils/helpers/object.helper';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import {
  addJournalEntry,
  deleteJournalEntry,
  detailJournalEntry,
  listJournalEntry,
  listJournalEntryRefresh,
  updateJournalEntry,
} from './journal-entry.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  journalEntryData: LocalStorage.get(LOCAL_STORAGE_JOURNAL_ENTRY),
  journalEntryDetail: LocalStorage.get(LOCAL_STORAGE_JOURNAL_ENTRY_DETAIL),
};

const journalEntrySlice = createSlice({
  name: NAME_STORE.JOURNAL_ENTRY,
  initialState,
  reducers: {
    listJournalEntry,
    listJournalEntryRefresh,
    addJournalEntry,
    updateJournalEntry,
    deleteJournalEntry,
    clearJournalEntry: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_JOURNAL_ENTRY);

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        journalEntryData: null,
      };
    },
    clearJournalEntryDetail: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_JOURNAL_ENTRY_DETAIL);

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        journalEntryDetail: null,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      journalEntryData: LOCAL_STORAGE_JOURNAL_ENTRY,
      journalEntryDetail: LOCAL_STORAGE_JOURNAL_ENTRY_DETAIL,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(listJournalEntry.pending, (state) => {
      state.statusRequest = STATUS_REQUEST_LIST_JOURNAL_ENTRY_PENDING;
    }),
      builder.addCase(listJournalEntry.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_JOURNAL_ENTRY_FAILED;
      }),
      builder.addCase(listJournalEntry.fulfilled, (state, action) => {
        state.journalEntryData = action.payload.journalEntryData;
        state.statusRequest = STATUS_REQUEST_LIST_JOURNAL_ENTRY_SUCCESS;
      }),
      builder.addCase(listJournalEntryRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_JOURNAL_ENTRY_PENDING;
      }),
      builder.addCase(listJournalEntryRefresh.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_JOURNAL_ENTRY_FAILED;
      }),
      builder.addCase(listJournalEntryRefresh.fulfilled, (state, action) => {
        state.journalEntryData = action.payload.journalEntryData;
        state.statusRequest = STATUS_REQUEST_LIST_JOURNAL_ENTRY_SUCCESS;
      }),
      builder.addCase(detailJournalEntry.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_JOURNAL_ENTRY_PENDING;
      }),
      builder.addCase(detailJournalEntry.rejected, (state) => {
        state.journalEntryDetail = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_JOURNAL_ENTRY_FAILED;
      }),
      builder.addCase(detailJournalEntry.fulfilled, (state, action) => {
        if (!isEmpty(action.payload)) {
          action.payload.journalEntryDetail = objHelper.changeFormatValueV2(
            action.payload.journalEntryDetail,
            DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
            null,
            formatHelp.changeFormatAccountName,
          );

          state.journalEntryDetail = action.payload.journalEntryDetail;
        }

        state.statusRequest = STATUS_REQUEST_DETAIL_JOURNAL_ENTRY_SUCCESS;
      }),
      builder.addCase(addJournalEntry.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_JOURNAL_ENTRY_PENDING;
      }),
      builder.addCase(addJournalEntry.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_JOURNAL_ENTRY_FAILED;
      }),
      builder.addCase(addJournalEntry.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_JOURNAL_ENTRY_SUCCESS;
      }),
      builder.addCase(updateJournalEntry.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_JOURNAL_ENTRY_PENDING;
      }),
      builder.addCase(updateJournalEntry.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_JOURNAL_ENTRY_FAILED;
      }),
      builder.addCase(updateJournalEntry.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_JOURNAL_ENTRY_SUCCESS;
      }),
      builder.addCase(deleteJournalEntry.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_JOURNAL_ENTRY_PENDING;
      }),
      builder.addCase(deleteJournalEntry.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_JOURNAL_ENTRY_FAILED;
      }),
      builder.addCase(deleteJournalEntry.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_JOURNAL_ENTRY_SUCCESS;
      }),
      /**
       * matcher for journal entry, includes import file and check progress import files
       */
      sliceReduceHelper.createMatcherEachRequestStatusAction({
        builder,
        baseTypesRegistered: BASE_TYPES_JOURNAL_ENTRY_ACTION_REGISTERED,
        mappingAsyncThunkTypeWithActionProps: journalEntryAsyncThunkTypeWithActionProps,
      });
  },
});

export const { actions, reducer } = journalEntrySlice;

export const { clearJournalEntry, clearJournalEntryDetail } = actions;

export default reducer;
