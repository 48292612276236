import { isEmpty, isEqual, pick } from 'lodash';

import { productUnitList } from '../../../store/product/product.action';
import { clearDataProductSlice } from '../../../store/product/product.slice';
import {
  NAME_STORE,
  ROOT_STORE_NAME_PRODUCT,
  SLICE_NAME_DATA_PRODUCT_UNIT_LIST,
  STATUS_REQUEST_LIST_PRODUCT_UNIT_PENDING,
} from '../../constants/store.constant';
import { DEFAULT_INPUT_TYPE_AUTOCOMPLETE } from '../../default/input-type.default';
import { DEFAULT_MINIMUM_POSITIVE_FRACTION_CALCULATION_NUMBER } from '../../default/number.default';
import {
  DEFAULT_KEY_NAME_GET_UNIT_GROUP_API_REF,
  DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER,
  DEFAULT_KEY_NAME_PRODUCT_UNIT_API_REF,
  DEFAULT_KEY_NAME_QUANTITY_ORIGIN_API_REF,
  DEFAULT_KEY_NAME_STATUS_REQUEST,
  DEFAULT_KEY_NAME_UNIT_API_REF,
  DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
  DEFAULT_KEY_NAME_UNIT_NAME_API_REF,
  DEFAULT_KEY_NAME_UNIT_ORIGIN_API_REF,
} from '../../default/object-keyname.default';
import { DEFAULT_PAYLOAD_STORAGE_PRODUCT_UNIT_LIST } from '../../default/payload-storage.default';
import { getCommonDifferentiationOfTransactionType } from '../../default/transaction-common-config.default';
import {
  DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_TYPE,
} from '../../default/transaction-type';
import { abbrevationUnitNameWithOriginUnitName } from '../../default/unit-product.default';
import arrHelp from '../../helpers/array.helpers';
import autocompleteHelper from '../../helpers/component/autocomplete.helpers';
import objHelper from '../../helpers/object.helper';
// import { DEFAULT_ACTION_MENU_ADD } from '../../default/action-menu.default'

export const getUnitProductAutocompleteTransactionPropertiesPrimary = ({
  productIDcontainerKeyname = DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER,
}) => {
  return {
    isInput: true,
    storeName: NAME_STORE.PRODUCT,
    childrenStoreName: SLICE_NAME_DATA_PRODUCT_UNIT_LIST,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestListPending: STATUS_REQUEST_LIST_PRODUCT_UNIT_PENDING,
    keyInputName: DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
    keyInputContainerName: DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
    keyUniqueName: DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
    inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
    getAdditionalParamsForAction: ({ rowSelectedContainerData }) => {
      if (isEmpty(rowSelectedContainerData)) return {};

      const productIDContainer = rowSelectedContainerData[productIDcontainerKeyname];
      if (isEmpty(productIDContainer)) return {};

      const { unit, unit_origin, product_unit } = objHelper.changesObjectValuesWithCorrespondValues(
        {
          obj: pick(productIDContainer, [
            DEFAULT_KEY_NAME_UNIT_API_REF,
            DEFAULT_KEY_NAME_UNIT_ORIGIN_API_REF,
            DEFAULT_KEY_NAME_PRODUCT_UNIT_API_REF,
          ]),
          correspondValues: abbrevationUnitNameWithOriginUnitName,
        },
      );

      return {
        [DEFAULT_KEY_NAME_GET_UNIT_GROUP_API_REF]: 1,
        [DEFAULT_KEY_NAME_UNIT_NAME_API_REF]: unit_origin || product_unit || unit,
      };
    },
    isDisabled: () => false,
    autocompleteProps: {
      fetchAction: productUnitList,
      additionalParamsForAction: {},
      removeDataAction: clearDataProductSlice,
      additionalParamsForRemoveDataAction: DEFAULT_PAYLOAD_STORAGE_PRODUCT_UNIT_LIST,
      additionalParamsForScrollAction: {
        isRewriteAll: false,
      },
      getOptionLabel: (options) =>
        autocompleteHelper.getOptionLabel(options, DEFAULT_KEY_NAME_UNIT_NAME_API_REF) || options,
      isOptionEqualToValue: (option, value) => isEqual(option, value),
      isUseFetchOnInput: false,
    },
    isReadOnly: ({ rowSelectedContainerData }) => {
      return isEmpty(rowSelectedContainerData[DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER]);
    },
  };
};

export const getChangesAutocompleteForUnitProductTransactionPropertiesPrimary = ({
  transactionType = DEFAULT_TRANSACTION_PURCHASE_TYPE,
  productIDcontainerKeyname = DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER,
  subTransactionType = DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_TYPE,
}) => {
  return {
    isHitEndpoint: false,
    isCanChangeAllState: false,
    getResultOfList: true,
    storeName: ROOT_STORE_NAME_PRODUCT,
    childrenStoreName: SLICE_NAME_DATA_PRODUCT_UNIT_LIST,
    generateChanges: ({
      // actionMenu = DEFAULT_ACTION_MENU_ADD,
      newValue,
      transactionData = [],
      childrenTransactionData = [],
      idChildrenTransaction = '',
      resultAutocompleteList = [],
    }) => {
      const initialParamsReturn = {
        transactionData,
        childrenTransactionData,
      };

      const targetChildrenTransactionData = arrHelp.filterObjDataWithID(
        childrenTransactionData,
        'id',
        idChildrenTransaction,
      );

      if (isEmpty(resultAutocompleteList) || isEmpty(targetChildrenTransactionData))
        return initialParamsReturn;

      const { quantity: quantityUserInput } = targetChildrenTransactionData;

      const productIDcontainer = targetChildrenTransactionData[productIDcontainerKeyname];

      const {
        quantity: quantityTarget,
        unit_origin,
        unit_destination,
        quantity_comparison_const_props,
      } = targetChildrenTransactionData;

      if (isEmpty(unit_origin) || isEmpty(unit_destination) || isEmpty(productIDcontainer))
        return initialParamsReturn;

      const commonTransactionConfig = getCommonDifferentiationOfTransactionType({
        transactionType,
      });

      const {
        priceKeyname: priceKeynameFromAPI,
        usageMaximumInputValue,
        keynameMaximumInputValueFromAPI,
        keynameMaximumInputCorrespondWithSubTransaction,
        priceUpdateKeyname,
        correspondKeynameAndValueForDiscardLimit,
      } = commonTransactionConfig;

      let priceKeyname = priceKeynameFromAPI,
        additionalComparisonValue = 1;
      if (
        quantity_comparison_const_props !== null &&
        quantity_comparison_const_props !== undefined
      ) {
        const { quantity, quantity_origin } = productIDcontainer;

        if (quantity !== undefined && quantity_origin !== undefined) {
          priceKeyname =
            priceUpdateKeyname !== undefined
              ? priceUpdateKeyname[subTransactionType] || priceKeynameFromAPI
              : priceKeynameFromAPI;
          additionalComparisonValue = Number(quantity / quantity_origin);
        }
      }

      const keynameMaximumInput = !isEmpty(keynameMaximumInputCorrespondWithSubTransaction)
        ? keynameMaximumInputCorrespondWithSubTransaction[subTransactionType]
        : null;

      // get implement maximum input value when came from details document
      const currentLimitValue =
        keynameMaximumInputValueFromAPI && keynameMaximumInputValueFromAPI !== undefined
          ? productIDcontainer[keynameMaximumInputValueFromAPI] ||
            targetChildrenTransactionData[keynameMaximumInput] ||
            Infinity
          : 0;

      // handling unreliable unit name, example pieces from pcs
      const unit = objHelper.changesObjectValuesWithCorrespondValues({
        obj: { unit_origin },
        correspondValues: abbrevationUnitNameWithOriginUnitName,
      });

      const selectedUnitOriginOnAutocompleteList = arrHelp.filterObjDataWithID(
        resultAutocompleteList.data,
        DEFAULT_KEY_NAME_UNIT_NAME_API_REF,
        unit[DEFAULT_KEY_NAME_UNIT_ORIGIN_API_REF],
      );

      if (isEmpty(selectedUnitOriginOnAutocompleteList)) return initialParamsReturn;

      const transactionPrice = productIDcontainer[priceKeyname] || productIDcontainer['price'];

      const { comparison_value: current_comparison_value } = selectedUnitOriginOnAutocompleteList;
      const { comparison_value: destination_comparison_value } = unit_destination;

      const comparisonValue =
        additionalComparisonValue * Number(destination_comparison_value / current_comparison_value);
      const perComparisonValue = Number(1 / comparisonValue);

      let maximumLimitValue = currentLimitValue * perComparisonValue;

      let quantity = quantityTarget,
        quantityOrigin = quantity * comparisonValue,
        isChangesQuantityToZero = false,
        isOverrideQuantity = false;

      if (
        // actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
        quantity_comparison_const_props !== undefined &&
        quantity_comparison_const_props !== null
      ) {
        const {
          quantity: quantity_const,
          quantity_available: quantity_available_const,
          quantity_origin: quantity_origin_const,
        } = quantity_comparison_const_props;

        const valueComparisonConst = Number(quantity_origin_const) / Number(quantity_const);

        quantityOrigin *= valueComparisonConst;

        maximumLimitValue =
          Number(maximumLimitValue / currentLimitValue) * Number(quantity_available_const);
      }

      const canDiscardLimit = isEmpty(correspondKeynameAndValueForDiscardLimit)
        ? false
        : Object.keys(correspondKeynameAndValueForDiscardLimit).reduce((result, currentKeyname) => {
            if (!(currentKeyname in productIDcontainer)) return result || false;

            const valueTarget = productIDcontainer[currentKeyname];
            const valueCorrespond = correspondKeynameAndValueForDiscardLimit[currentKeyname];

            return result || valueTarget === valueCorrespond;
          }, false);

      // maximum quantity input and swap them to maximum input
      if (
        keynameMaximumInput &&
        usageMaximumInputValue &&
        (Number(quantityOrigin) > currentLimitValue ||
          (!Math.floor(quantityUserInput) && maximumLimitValue < Math.floor(quantityUserInput))) &&
        !canDiscardLimit &&
        productIDcontainer[keynameMaximumInputValueFromAPI] !== undefined
      ) {
        quantityOrigin = productIDcontainer[keynameMaximumInputValueFromAPI];
        quantity = quantityOrigin * perComparisonValue;

        if (quantity < DEFAULT_MINIMUM_POSITIVE_FRACTION_CALCULATION_NUMBER) {
          quantity = 0;
          isChangesQuantityToZero = true;
        } else if (quantity < 1) {
          isOverrideQuantity = true;
        } else if (maximumLimitValue < Math.floor(quantityUserInput)) {
          quantity = maximumLimitValue;
          quantityOrigin = Number(quantity * comparisonValue);

          isOverrideQuantity = true;
        }
      }

      const price = Number(transactionPrice) * comparisonValue || 0;
      const amount = price * quantity;

      let changesChildrenTransactionData = {
        ...targetChildrenTransactionData,
        [DEFAULT_KEY_NAME_QUANTITY_ORIGIN_API_REF]: quantityOrigin || 0,
        unit_origin: unit_origin || '',
        unit_destination: newValue[DEFAULT_KEY_NAME_UNIT_NAME_API_REF] || '',
        price,
        amount,
        quantity_comparison: Number(quantityOrigin) / Number(quantity),
      };

      if (isOverrideQuantity || isChangesQuantityToZero || !Math.floor(quantityUserInput)) {
        changesChildrenTransactionData = {
          ...changesChildrenTransactionData,
          quantity,
        };
      }

      // getting limit value
      if (keynameMaximumInput) {
        changesChildrenTransactionData = {
          ...changesChildrenTransactionData,
          [keynameMaximumInput]: maximumLimitValue,
        };
      }

      return {
        resultChanges: changesChildrenTransactionData,
      };
    },
  };
};
