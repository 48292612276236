import * as React from 'react';
function SvgWarehouseTransferDataMenu(props) {
  return (
    <svg width={94} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#warehouse-transfer-data-menu_svg__filter0_d_5543_188772)'>
        <path d='M86.167 12.405V68.98H9.142V12.404L47.655 1l38.512 11.405z' fill='#F2F4F7' />
        <path d='M86.144 63.74H9.142v5.24h77.002v-5.24z' fill='#E6E9EE' />
        <path d='M77.397 30.756H17.913V68.98h59.484V30.756z' fill='#324A5E' />
        <path d='M71.444 37.908H24.593V69h46.851V37.908z' fill='#FFD05B' />
        <path
          d='M71.421 46.107v-.41h-9.134v-7.79h-.363v7.79h-9.27v-7.79h-.364v7.79h-9.27v-7.79h-.364v7.79h-9.27v-7.79h-.364v7.79h-8.43v.41h8.43v7.131h-8.43v.411h8.43v7.131h-8.43v.411h8.43v7.624h.364v-7.624h9.27v7.624h.364v-7.624h9.27v7.624h.364v-7.624h9.27v7.624h.363v-7.624h9.134v-.411h-9.134v-7.13h9.134v-.412h-9.134v-7.13h9.134zM42.656 60.78h-9.27v-7.13h9.27v7.13zm0-7.542h-9.27v-7.13h9.27v7.13zm9.634 7.542h-9.27v-7.13h9.27v7.13zm0-7.542h-9.27v-7.13h9.27v7.13zm9.634 7.542h-9.27v-7.13h9.27v7.13zm0-7.542h-9.27v-7.13h9.27v7.13z'
          fill='#F9B54C'
        />
        <path
          d='M33.931 16.002H17.663v5.26H33.93v-5.26zM55.789 16.002H39.52v5.26H55.79v-5.26zM77.624 16.002H61.356v5.26h16.268v-5.26zM67.945 37.908h-2.5v1.007h2.5v-1.007zM58.425 37.908h-2.5v1.007h2.5v-1.007zM48.904 37.908h-2.5v1.007h2.5v-1.007zM39.384 37.908h-2.5v1.007h2.5v-1.007zM29.864 37.908h-2.5v1.007h2.5v-1.007zM67.945 46.066h-2.5v1.007h2.5v-1.007zM58.425 46.066h-2.5v1.007h2.5v-1.007zM48.904 46.066h-2.5v1.007h2.5v-1.007zM39.384 46.066h-2.5v1.007h2.5v-1.007zM29.864 46.066h-2.5v1.007h2.5v-1.007zM67.945 53.629h-2.5v1.007h2.5v-1.007zM58.425 53.629h-2.5v1.007h2.5v-1.007zM48.904 53.629h-2.5v1.007h2.5v-1.007zM39.384 53.629h-2.5v1.007h2.5v-1.007zM29.864 53.629h-2.5v1.007h2.5v-1.007zM67.945 61.17h-2.5v1.007h2.5V61.17zM58.425 61.17h-2.5v1.007h2.5V61.17zM48.904 61.17h-2.5v1.007h2.5V61.17zM39.384 61.17h-2.5v1.007h2.5V61.17zM29.864 61.17h-2.5v1.007h2.5V61.17z'
          fill='#E6E9EE'
        />
        <path
          opacity={0.1}
          fillRule='evenodd'
          clipRule='evenodd'
          d='M86.167 68.98V12.404L47.655 1v67.98h38.512z'
          fill='#475467'
        />
      </g>
      <path
        d='M15.084 29C23.322 29 30 22.741 30 15.02 30 7.3 23.322 1.042 15.084 1.042 6.845 1.041.167 7.3.167 15.021.167 22.74 6.845 29 15.084 29z'
        fill='#27AE60'
      />
      <path
        d='M14.792 12.884a1.041 1.041 0 01-1.406 0l-1.431-1.341c-.313-.294-.85-.086-.85.329v9.673c0 .514-.444.932-.994.932s-.994-.418-.994-.932v-9.673c0-.415-.536-.624-.85-.33l-1.43 1.34a1.041 1.041 0 01-1.407 0 .89.89 0 010-1.317l3.978-3.728c.194-.182.449-.273.703-.273.255 0 .51.091.703.273l3.978 3.728a.891.891 0 010 1.319zM24.737 18.476l-3.978 3.728a1.026 1.026 0 01-.703.273c-.255 0-.51-.091-.703-.273l-3.978-3.728a.89.89 0 010-1.318 1.04 1.04 0 011.406 0l1.431 1.341c.313.294.85.086.85-.329V8.497c0-.514.444-.932.994-.932s.994.418.994.932v9.673c0 .415.536.624.85.33l1.43-1.341a1.041 1.041 0 011.407 0 .89.89 0 010 1.317z'
        fill='#fff'
      />
      <path
        opacity={0.1}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.084 29C23.322 29 30 22.741 30 15.02 30 7.3 23.322 1.042 15.084 1.042V29z'
        fill='#134528'
      />
      <defs>
        <filter
          id='warehouse-transfer-data-menu_svg__filter0_d_5543_188772'
          x={8.142}
          y={0}
          width={85.025}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5543_188772' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5543_188772' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgWarehouseTransferDataMenu;
