// column report balance sheet list that handling table head with translation
export const columnsTableReportBalanceSheet = (t) => {
  return [
    {
      name: 'information',
      label: t('dashboard.report.balance-sheet.table.header-column.information'),
      format: 'normal',
      width: 1600,
    },
    {
      name: 'journal_balance',
      align: 'right',
      label: t('dashboard.report.balance-sheet.table.header-column.balance'),
      format: 'normal-currency',
      width: 200,
      maxWidth: 250,
    },
  ];
};

export const rowsTableReportBalanceSheetWithTranslation = (t) => {
  return [
    {
      rowName: 'assets',
      translationRowName: t('table.row.assets'),
    },
    {
      rowName: 'current-assets',
      translationRowName: t('table.row.current-assets'),
    },
    {
      rowName: 'current-assets-total',
      translationRowName: t('table.row.current-assets-total'),
    },
    {
      rowName: 'fixed-asset',
      translationRowName: t('table.row.fixed-asset'),
    },
    {
      rowName: 'fixed-asset-total',
      translationRowName: t('table.row.fixed-asset-total'),
    },
    {
      rowName: 'total-assets',
      translationRowName: t('table.row.total-assets'),
    },
    {
      rowName: 'liabilities-and-capital',
      translationRowName: t('table.row.liabilities-and-capital'),
    },
    {
      rowName: 'liability',
      translationRowName: t('table.row.liability'),
    },
    {
      rowName: 'liability-total',
      translationRowName: t('table.row.liability-total'),
    },
    {
      rowName: 'equity',
      translationRowName: t('table.row.equity'),
    },
    {
      rowName: 'equity-total',
      translationRowName: t('table.row.equity-total'),
    },
    {
      rowName: 'total-liabilities-and-capital',
      translationRowName: t('table.row.total-liabilities-and-capital'),
    },
    {
      rowName: 'total-income-in-period',
      translationRowName: t('table.row.total-income-in-period'),
    },
  ];
};

export const rowsTableTotalBalance = [
  'total-assets',
  'total-income-in-period',
  'total-liabilities-and-capital',
];

export const rowsTableTotalAccount = [
  'current-assets-total',
  'fixed-asset-total',
  'liability-total',
  'total-income-in-period',
  'equity-total',
];

export const rowsTableWithChildren = [
  'assets',
  'current-assets',
  'fixed-asset',
  'liabilities-and-capital',
  'liability',
  'equity',
];

export const allRowsTableReportBalanceSheet = [
  'assets',
  'total-assets',
  'liabilities-and-capital',
  'total-income-in-period',
  'total-liabilities-and-capital',
];

export const accountNamesReportBalanceSheet = [
  'Kas & Bank',
  'Akun Piutang',
  'Aktiva Lancar Lainnya',
  'Persediaan',
  'Aktiva Tetap',
  'Aktiva Lainnya',
  'Depresiasi & Amortisasi',
  'Kartu Kredit',
  'Kewajiban Lancar Lainnya',
  'Akun Hutang',
  'Kewajiban Jangka Panjang',
  'Ekuitas',
];

// based on PRD
// terdapat 2 bagian utama yaitu
//
// Aset - merupakan kumpulan dari kategori aset lancar dan aset tetap
// aset lancar adalah akun yang berkategori
// - kas & bank
// - akun piutang
// - aktifa lancar lainya
// - persediaan
//
// aset tetap adalah kelompok akun yang berkategori
// - aktifa tetap
// - aktifa lainya
// - depresiasi dan amortisasi
//
// Liabilitas dan modal merupakan kumpulan dari kategori liabilitas dan ekuitas
// liabilitas adalah kelompok akun yang berkategori
// - kartu kredit
// - kewajiban lancar lainya
// - akun hutang
// - kewajiban jangka panjang
//
// ekuitas adalah aset dengan kategori ekuitas
//
// total pendapatan pada periode tersebut adalah total aset - (total liabilitas +total modal)
export const correspondingAccountCategoryNaccountNameGroupingReportBalanceSheet = {
  'current-assets': ['Kas & Bank', 'Akun Piutang', 'Aktiva Lancar Lainnya', 'Persediaan'],
  'fixed-asset': ['Aktiva Tetap', 'Aktiva Lainnya', 'Depresiasi & Amortisasi'],
  liability: [
    'Kartu Kredit',
    'Kewajiban Lancar Lainnya',
    'Akun Hutang',
    'Kewajiban Jangka Panjang',
  ],
  equity: ['Ekuitas'],
};

export const correspondingPartViewNaccountCategoryNameGroupingReportBalanceSheet = {
  assets: ['current-assets', 'current-assets-total', 'fixed-asset', 'fixed-asset-total'],
  'liabilities-and-capital': ['liability', 'liability-total', 'equity', 'equity-total'],
};

export const accountCategoryGroupingReportBalanceSheet = [
  ...Object.keys(correspondingAccountCategoryNaccountNameGroupingReportBalanceSheet),
];

export const rowsTableTotalAccountReportBalanceSheet = [
  'current-assets-total',
  'fixed-asset-total',
  'liability-total',
  'equity-total',
];

export const correspondTotalAssetAndLiabilityMinusWithAccountCategoryReportBalanceSheet = {
  'total-assets': ['current-assets-total', 'fixed-asset-total'],
  'total-liabilities-and-capital-minus': ['liability-total', 'equity-total'],
  'total-liabilities-and-capital-plus': ['liability-total', 'equity-total'],
};

export const DEFAULT_KEY_NAME_ADDITIONAL_KEY_TOTAL_LIABILITIES_AND_CAPITAL_MINUS =
  'total-liabilities-and-capital-minus';
export const DEFAULT_KEY_NAME_ADDITIONAL_KEY_TOTAL_LIABILITIES_AND_CAPITAL_PLUS =
  'total-liabilities-and-capital-plus';

export const correspondTotalIncomeWithAccountCategoryReportBalanceSheet = {
  'total-income-in-period': [
    'total-assets',
    DEFAULT_KEY_NAME_ADDITIONAL_KEY_TOTAL_LIABILITIES_AND_CAPITAL_MINUS,
  ],
};

export const correspondTotalLiabilityWithAccountCategoryReportBalanceSheet = {
  'total-liabilities-and-capital': [
    'total-income-in-period',
    DEFAULT_KEY_NAME_ADDITIONAL_KEY_TOTAL_LIABILITIES_AND_CAPITAL_PLUS,
  ],
};
