import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_PRIMARY_TOKEN, LOCAL_STORAGE_USER } from '../constants/storage.constant';

export function authHeader() {
  const user = LocalStorage.get(LOCAL_STORAGE_USER);
  const token = LocalStorage.get(LOCAL_STORAGE_PRIMARY_TOKEN);

  if (user && token) {
    return {
      Authorization: `Bearer ${token}`,
    };
  } else {
    return {};
  }
}

export function authHeaderV2({
  localStorageUserKeyname = LOCAL_STORAGE_USER,
  localStorageTokenKeyname = LOCAL_STORAGE_PRIMARY_TOKEN,
}) {
  const user = LocalStorage.get(localStorageUserKeyname);
  const token = LocalStorage.get(localStorageTokenKeyname);

  return user && token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {};
}
