// column cash flow list that handling table head with translation
export const columnsTableReportCashFlow = (t) => {
  return [
    {
      name: 'information',
      label: t('dashboard.report.cash-flow.table.header-column.information'),
      format: 'normal',
      width: 1600,
    },
    {
      name: 'balance',
      align: 'right',
      label: t('dashboard.report.cash-flow.table.header-column.balance'),
      format: 'normal-currency',
      width: 200,
      maxWidth: 250,
    },
  ];
};

export const rowsTableReportCashFlowWithTranslation = (t) => {
  return [
    {
      nameRow: 'cash-flow-from-operating-activities',
      translationNameRow: t('table.row.cash-flow-from-operating-activities'),
    },
    {
      nameRow: 'cash-received-from-customers',
      translationNameRow: t('table.row.cash-received-from-customers'),
    },
    {
      nameRow: 'other-current-assets',
      translationNameRow: t('table.row.other-current-assets'),
    },
    {
      nameRow: 'cash-paid-to-suppliers',
      translationNameRow: t('table.row.cash-paid-to-suppliers'),
    },
    {
      nameRow: 'credit-cards-and-current-liabilities',
      translationNameRow: t('table.row.credit-cards-and-current-liabilities'),
    },
    {
      nameRow: 'other-incomes',
      translationNameRow: t('table.row.other-incomes'),
    },
    {
      nameRow: 'operating-expenses-paid',
      translationNameRow: t('table.row.operating-expenses-paid'),
    },
    {
      nameRow: 'net-cash-provided-by-operating-activities',
      translationNameRow: t('table.row.net-cash-provided-by-operating-activities'),
    },
    {
      nameRow: 'cash-flow-from-investing-activities',
      translationNameRow: t('table.row.cash-flow-from-investing-activities'),
    },
    {
      nameRow: 'purchase-or-sales-of-assets',
      translationNameRow: t('table.row.purchase-or-sales-of-assets'),
    },
    {
      nameRow: 'other-investing-activities',
      translationNameRow: t('table.row.other-investing-activities'),
    },
    {
      nameRow: 'net-cash-provided',
      translationNameRow: t('table.row.net-cash-provided'),
    },
    {
      nameRow: 'cash-flow-from-financing-activities',
      translationNameRow: t('table.row.cash-flow-from-financing-activities'),
    },
    {
      nameRow: 'repayment-or-proceeds-of-loan',
      translationNameRow: t('table.row.repayment-or-proceeds-of-loan'),
    },
    {
      nameRow: 'equity-or-capital',
      translationNameRow: t('table.row.equity-or-capital'),
    },
    {
      nameRow: 'net-cash-provided-by-financing-activities',
      translationNameRow: t('table.row.net-cash-provided-by-financing-activities'),
    },
    {
      nameRow: 'increase-decrease-in-cash',
      translationNameRow: t('table.row.increase-(decrease)-in-cash'),
    },
    {
      nameRow: 'beginning-cash-balance',
      translationNameRow: t('table.row.beginning-cash-balance'),
    },
    {
      nameRow: 'ending-cash-balance',
      translationNameRow: t('table.row.ending-cash-balance'),
    },
  ];
};

export const rowsTableTotalCash = [
  'increase-decrease-in-cash',
  'beginning-cash-balance',
  'ending-cash-balance',
];

export const rowsTableWithChildren = [
  'cash-flow-from-operating-activities',
  'cash-flow-from-investing-activities',
  'cash-flow-from-financing-activities',
];

export const rowsTableTotalPerCashAccount = [
  'net-cash-provided-by-operating-activities',
  'net-cash-provided',
  'net-cash-provided-by-financing-activities',
];

export const allRowsTableReportCashFlow = [...rowsTableWithChildren, ...rowsTableTotalCash];
