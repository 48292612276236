import * as React from 'react';
function SvgXDeleteData(props) {
  return (
    <svg width={70} height={70} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M45 25L25 45m0-20l20 20m23.333-10c0 18.41-14.924 33.334-33.333 33.334C16.59 68.334 1.666 53.41 1.666 35 1.666 16.59 16.59 1.667 35 1.667c18.41 0 33.333 14.924 33.333 33.333z'
        stroke='#D92D20'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
export default SvgXDeleteData;
