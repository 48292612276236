import * as React from 'react';
function SvgIconExcelFileType(props) {
  return (
    <svg width={40} height={40} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20z'
        fill='#D1E9FF'
      />
      <path d='M28.214 23.571a.357.357 0 100-.714.357.357 0 000 .714z' fill='#1570EF' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.786 10a.357.357 0 00-.357.357v19.286a.357.357 0 00.357.357h16.428a.357.357 0 00.357-.357v-5a.357.357 0 10-.714 0v4.643H12.143V17.143h15.714v4.643a.358.358 0 00.714 0v-7.857a.357.357 0 00-.104-.253l-3.572-3.571a.357.357 0 00-.252-.105H11.786zm12.5.714H12.143v5.715h15.714v-2.143h-2.5a1.072 1.072 0 01-1.071-1.072v-2.5zm.714.505v1.995a.357.357 0 00.357.357h1.995L25 11.22z'
        fill='#1570EF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.272 17.857h10.742a1.06 1.06 0 011.057 1.058v4.656h-3.214v4.286h-8.585a1.06 1.06 0 01-1.058-1.058v-7.884a1.059 1.059 0 011.058-1.058zm0 9.286h1.442v-1.429H13.93V26.8a.344.344 0 00.343.344zM13.93 25h1.785v-1.429H13.93V25zm0-2.143h1.785V21.43H13.93v1.428zm0-2.143h1.785v-2.143h-1.442a.344.344 0 00-.343.344v1.8zm2.5 6.429h5.714v-1.429h-5.714v1.429zm0-2.143h5.714v-1.429h-5.714V25zm0-2.143h5.714V21.43h-5.714v1.428zm0-2.143h5.714v-2.143h-5.714v2.143zm6.428 2.143h2.5V21.43h-2.5v1.428zm0-2.143h2.5v-1.799a.344.344 0 00-.343-.344h-2.157v2.143z'
        fill='#1570EF'
      />
      <path
        d='M25.014 27.857A1.06 1.06 0 0026.07 26.8v-3.228h-.714V25h-2.5v.714h2.5V26.8a.344.344 0 01-.343.344h-2.157v.714h2.157zM21.25 12.143h.536a.357.357 0 01.357.357.357.357 0 10.714 0 1.071 1.071 0 00-1.071-1.071h-.536a1.25 1.25 0 000 2.5h.357a.536.536 0 010 1.071h-.536a.357.357 0 01-.357-.357.357.357 0 10-.714 0 1.072 1.072 0 001.071 1.071h.536a1.25 1.25 0 000-2.5h-.357a.536.536 0 110-1.071zM19.286 15H17.5v-3.214a.357.357 0 10-.714 0v3.571a.357.357 0 00.357.357h2.143a.357.357 0 100-.714zM15.902 11.48a.357.357 0 00-.49.122l-.766 1.275-.765-1.275a.357.357 0 00-.612.368l.96 1.601-.96 1.602a.355.355 0 00.118.496.356.356 0 00.494-.128l.765-1.275.765 1.275a.358.358 0 10.613-.368l-.961-1.602.96-1.601a.357.357 0 00-.121-.49z'
        fill='#1570EF'
      />
    </svg>
  );
}
export default SvgIconExcelFileType;
