import KEYNAME from '../constants/keyname.constant';

function tagContainerInputFromDetails(detailsData = {}) {
  const tags = detailsData[KEYNAME.TAG_API_REF] || [];

  return {
    [KEYNAME.TAG_CONTAINER]: tags.map((tag) => {
      return {
        [KEYNAME.TAG_NAME_API_REF]: tag,
      };
    }),
  };
}

const parseResponseHelpers = {
  tagContainerInputFromDetails,
};

export default parseResponseHelpers;
