import { omit } from 'lodash';
import queryString from 'qs';

export const queryStringToObject = (str, options = {}) =>
  queryString.parse(str, {
    arrayFormat: 'bracket',
    ignoreQueryPrefix: true,
    ...options,
  });

export const objectToQueryString = (obj, options = {}) =>
  queryString.stringify(obj, {
    arrayFormat: 'bracket',
    ...options,
  });

export const omitFromQueryString = (str, keys) =>
  objectToQueryString(omit(queryStringToObject(str), keys));

export const addToQueryString = (str, fields) =>
  objectToQueryString({
    ...queryStringToObject(str),
    ...fields,
  });

const urlHelpers = {
  queryStringToObject,
  objectToQueryString,
  omitFromQueryString,
  addToQueryString,
};

export default urlHelpers;
