import * as React from 'react';
function SvgPurchaseQuotationMenu(props) {
  return (
    <svg width={97} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#purchase-quotation-menu_svg__filter0_d_5586_192765)'>
        <path
          d='M42.782 15.553V9.199C42.782 4.67 62.328 1 67.544 1H20.537C15.32 1 11.09 4.67 11.09 9.199v6.354h31.691z'
          fill='#EAECEF'
        />
        <path
          d='M32.523 69V9.198C32.523 4.67 28.294 1 23.078 1h57.226c5.217 0 9.446 3.67 9.446 8.199V69H32.523z'
          fill='#FAFCFF'
        />
        <path d='M89.75 9.199C89.75 4.67 85.521 1 80.305 1H62.02v68h27.73V9.199z' fill='#EAECEF' />
        <path
          d='M81.884 22.931h-39.33c-.92 0-1.668-.648-1.668-1.448 0-.8.747-1.449 1.669-1.449h39.33c.92 0 1.668.649 1.668 1.449s-.747 1.448-1.669 1.448zM81.884 29.759h-39.33c-.92 0-1.668-.649-1.668-1.449s.747-1.448 1.669-1.448h39.33c.92 0 1.668.648 1.668 1.448 0 .8-.747 1.449-1.669 1.449zM81.884 36.586h-39.33c-.92 0-1.668-.648-1.668-1.448 0-.8.747-1.448 1.669-1.448h39.33c.92 0 1.668.648 1.668 1.448 0 .8-.747 1.448-1.669 1.448zM81.884 43.414h-39.33c-.92 0-1.668-.648-1.668-1.449 0-.8.747-1.448 1.669-1.448h39.33c.92 0 1.668.648 1.668 1.448 0 .8-.747 1.449-1.669 1.449zM81.884 50.242h-39.33c-.92 0-1.668-.649-1.668-1.449s.747-1.448 1.669-1.448h39.33c.92 0 1.668.648 1.668 1.448 0 .8-.747 1.449-1.669 1.449zM81.884 57.069h-39.33c-.92 0-1.668-.648-1.668-1.448 0-.8.747-1.449 1.669-1.449h39.33c.92 0 1.668.649 1.668 1.449s-.747 1.448-1.669 1.448z'
          fill='#808183'
        />
        <path d='M74.018 7.828H50.42v6.827h23.598V7.828z' fill='#C92F00' />
        <path
          d='M81.885 20.034H62.02v2.897h19.864c.921 0 1.668-.648 1.668-1.448 0-.8-.747-1.449-1.668-1.449zM81.885 26.862H62.02v2.897h19.864c.921 0 1.668-.649 1.668-1.449s-.747-1.448-1.668-1.448zM81.885 33.69H62.02v2.896h19.864c.921 0 1.668-.648 1.668-1.448 0-.8-.747-1.448-1.668-1.448zM81.885 40.517H62.02v2.897h19.864c.921 0 1.668-.648 1.668-1.449 0-.8-.747-1.448-1.668-1.448zM81.885 47.345H62.02v2.896h19.864c.921 0 1.668-.648 1.668-1.448 0-.8-.747-1.448-1.668-1.448zM81.885 54.172H62.02v2.897h19.864c.921 0 1.668-.648 1.668-1.448 0-.8-.747-1.449-1.668-1.449z'
          fill='#59595B'
        />
        <path d='M74.019 7.828h-12v6.827h12V7.828z' fill='#930000' />
        <path
          d='M6.398 35.088c0 1.03-1.04 1.868-2.323 1.868-1.284 0-2.325-.837-2.325-1.868 0-1.034 1.041-1.87 2.325-1.87 1.282 0 2.323.836 2.323 1.87zM38.863 37.532l-18.075 4.939 7.081 16.784 18.075-4.939-7.081-16.784z'
          fill='#FF8E31'
        />
        <path
          d='M49.384 64.633H19.097c-.96 0-1.865-.3-2.547-.85-.679-.548-1.054-1.275-1.054-2.05v-.077c0-.658.283-1.304.793-1.818.514-.513 1.237-.873 2.037-1.016l25.377-4.492c.602-.107 1.196.2 1.329.682.132.483-.248.962-.85 1.07l-25.377 4.493c-.31.053-.578.187-.777.387a.976.976 0 00-.304.694v.077c0 .296.143.574.402.783.26.208.606.325.97.325h30.287c.616 0 1.114.4 1.114.896 0 .494-.497.896-1.113.896z'
          fill='#F2F4F7'
        />
        <path
          d='M19.886 60.361c-.537 0-1.011-.313-1.098-.755l-4.582-23.094a1.055 1.055 0 00-.465-.669 1.579 1.579 0 00-.894-.265H4.075c-.616 0-1.116-.4-1.116-.896 0-.494.5-.898 1.116-.898h8.771c.857 0 1.688.248 2.34.695.651.446 1.087 1.069 1.22 1.75l4.581 23.093c.096.49-.318.95-.925 1.028-.06.008-.117.011-.176.011z'
          fill='#F2F4F7'
        />
        <path
          d='M25.014 66.103c0 1.58-1.593 2.86-3.553 2.86-1.962 0-3.555-1.28-3.555-2.86 0-1.58 1.593-2.858 3.555-2.858 1.96 0 3.553 1.278 3.553 2.858z'
          fill='#34495E'
        />
        <path
          d='M23.235 66.103c0 .79-.794 1.43-1.774 1.43-.983 0-1.775-.64-1.775-1.43 0-.789.792-1.429 1.775-1.429.98 0 1.774.64 1.774 1.429z'
          fill='#F2F4F7'
        />
        <path
          d='M50.498 66.103c0 1.58-1.592 2.86-3.554 2.86-1.961 0-3.552-1.28-3.552-2.86 0-1.58 1.591-2.858 3.552-2.858 1.962 0 3.554 1.278 3.554 2.858z'
          fill='#34495E'
        />
        <path
          d='M48.72 66.103c0 .79-.796 1.43-1.776 1.43s-1.776-.64-1.776-1.43c0-.788.796-1.428 1.776-1.428s1.777.64 1.777 1.428zM50.114 42.877a1.476 1.476 0 00-.928-.31H15.302l3.672 18.382 30.494-5.398c.282-.05.543-.174.74-.37a.935.935 0 00.29-.66V43.623c0-.27-.129-.54-.384-.745zm-28.56 15.837a.953.953 0 01-.133.008c-.4 0-.753-.234-.818-.562l-2.614-13.082c-.072-.365.235-.708.688-.766.45-.058.878.19.952.554l2.612 13.082c.073.365-.234.707-.687.766zm4.333-.769a.918.918 0 01-.118.008c-.406 0-.762-.24-.82-.574L22.77 45.066c-.065-.364.25-.703.703-.755.458-.053.875.202.94.567l2.178 12.313c.065.364-.25.703-.705.754zm4.33-.767a1.222 1.222 0 01-.1.005c-.413 0-.771-.25-.822-.588l-1.743-11.542c-.056-.366.27-.7.723-.744.456-.038.868.216.925.582l1.742 11.544c.055.365-.268.698-.724.743zm4.33-.769a1.294 1.294 0 01-.083.003c-.422 0-.783-.258-.824-.603l-1.308-10.772c-.045-.367.288-.694.746-.73.463-.032.863.233.907.6l1.308 10.773c.043.367-.29.694-.746.73zm4.325-.767l-.06.002c-.432 0-.795-.27-.826-.622l-.87-10.003c-.032-.368.311-.687.77-.714.462-.019.853.252.886.62l.87 10.004c.032.368-.314.687-.77.713zm4.322-.77h-.032c-.444 0-.812-.282-.83-.641l-.435-9.234c-.016-.368.34-.678.799-.693.463-.022.842.273.861.642l.435 9.233c.018.37-.34.68-.797.694zm5.147-1.437c0 .37-.372.668-.83.668-.459 0-.83-.299-.83-.668v-8.463c0-.37.371-.667.83-.667.458 0 .83.298.83.667v8.463z'
          fill='#F2F4F7'
        />
      </g>
      <defs>
        <filter
          id='purchase-quotation-menu_svg__filter0_d_5586_192765'
          x={0.75}
          y={0}
          width={96.001}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_192765' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_192765' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgPurchaseQuotationMenu;
