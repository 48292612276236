import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Clear } from '@mui/icons-material';
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { isEmpty, isEqual } from 'lodash';

import AutocompleteCustom from '../../../../components/custom/CustomAutocomplete/CustomAutocomplete';
import DateTimePicker from '../../../../components/date-time/DateTimePicker/DateTimePicker';
import InputAdornmentLoading from '../../../../components/input-adorment/InputAdornmentLoading/InputAdornmentLoading';
import PopperAutoWidth from '../../../../components/popper/PopperAutoWidth/PopperAutoWidth';
import { contactList } from '../../../../store/contact/contact.action';
import { clearContactData } from '../../../../store/contact/contact.slice';
import messageHelper from '../../../../store/message/message.helper';
import { getListPurchasePayment } from '../../../../store/purchase/purchase.action';
import {
  STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PENDING,
} from '../../../../utils/constants/store.constant';
import { initialSearchPurchasePayment } from '../../../../utils/data/display/initial-search-purchase-payment';
import { columnsSearchWithDate } from '../../../../utils/data/display/table-purchase-payment-list';
import { TERSIER_DEFAULT_LIST_PARAMS } from '../../../../utils/default/params.default';
import { DEFAULT_DELAY_TIMEOUT_IN_MS } from '../../../../utils/default/timeout.default';
import autocompleteHelper from '../../../../utils/helpers/component/autocomplete.helpers';
import dateHelpers from '../../../../utils/helpers/date.helpers';
import formatHelp from '../../../../utils/helpers/format.helpers';
import objHelper from '../../../../utils/helpers/object.helper';

import { styles } from './TableHeadSearchPurchasePayment.styles';

class TableHeadSearchPurchasePayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchParams: {
        ...initialSearchPurchasePayment,
      },
      focusedInputName: null,
    };

    this.searchPurchasePaymentTimeoutID = null;

    this.handleClearSingleFilter = this.handleClearSingleFilter.bind(this);

    // handling search by input
    this.handleOnChangeInput = this.handleOnChangeInput.bind(this);
    this.handleOnOpenAutocomplete = this.handleOnOpenAutocomplete.bind(this);
    this.handleOnCloseAutocomplete = this.handleOnCloseAutocomplete.bind(this);
    this.handleOnScrollAutocomplete = this.handleOnScrollAutocomplete.bind(this);
    this.handleChangeAutocomplete = this.handleChangeAutocomplete.bind(this);
  }

  async handleClearSingleFilter(event) {
    const { name } = event.currentTarget;

    const { searchParams: currSearchParams } = this.state;

    const newSearchParams = {
      ...currSearchParams,
      [name]: '',
    };

    this.setState((prevState) => ({
      ...prevState,
      searchParams: {
        ...newSearchParams,
      },
    }));

    const { purchase } = this.props;
    const { purchasePaymentData } = purchase;

    if (purchase.statusRequest === STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PENDING) return;

    const paginate = purchasePaymentData
      ? purchasePaymentData.per_page
      : TERSIER_DEFAULT_LIST_PARAMS.paginate;

    await this.props.getListPurchasePayment({
      paginate,
      ...newSearchParams,
    });
  }

  handleOnChangeDate(newValueDate) {
    const { searchParams: currSearchParams } = this.state;

    const isDateValueNotChanged = isEqual(
      newValueDate,
      objHelper.filterKeyObj(currSearchParams, [], columnsSearchWithDate),
    );

    if (isDateValueNotChanged) return;

    if (!isEmpty(newValueDate)) {
      const { from_date, to_date } = newValueDate;

      if (
        !dateHelpers.isValidDateV2(new Date(from_date)) ||
        !dateHelpers.isValidDateV2(new Date(to_date))
      ) {
        const { t } = this.props;

        this.props.setFailedMessage(
          t('dashboard.purchase.purchase-payment.message.invalid-date-format.primary'),
          t('dashboard.purchase.purchase-payment.message.invalid-date-format.secondary'),
        );

        return;
      }
    }

    const newSearchParams = {
      ...currSearchParams,
      ...newValueDate,
    };

    this.setState((prevState) => ({
      ...prevState,
      searchParams: {
        ...newSearchParams,
      },
    }));

    if (this.searchPurchasePaymentTimeoutID) {
      clearTimeout(this.searchPurchasePaymentTimeoutID);
    }

    this.searchPurchasePaymentTimeoutID = setTimeout(async () => {
      const { purchase } = this.props;
      const { purchasePaymentData } = purchase;

      const paginate = purchasePaymentData
        ? purchasePaymentData.per_page
        : TERSIER_DEFAULT_LIST_PARAMS.paginate;

      return await this.props.getListPurchasePayment({
        paginate,
        ...newSearchParams,
      });
    }, DEFAULT_DELAY_TIMEOUT_IN_MS);
  }

  handleOnChangeInput(event) {
    const { name, value } = event.target;

    const { searchParams: currSearchParams } = this.state;

    const newSearchParams = {
      ...currSearchParams,
      [name]: value,
    };

    this.setState((prevState) => ({
      ...prevState,
      searchParams: {
        ...newSearchParams,
      },
      focusedInputName: name,
    }));

    if (this.searchPurchasePaymentTimeoutID) {
      clearTimeout(this.searchPurchasePaymentTimeoutID);
    }

    this.searchPurchasePaymentTimeoutID = setTimeout(async () => {
      const { purchase } = this.props;
      const { purchasePaymentData } = purchase;

      const paginate = purchasePaymentData
        ? purchasePaymentData.per_page
        : TERSIER_DEFAULT_LIST_PARAMS.paginate;

      return await this.props.getListPurchasePayment({
        paginate,
        ...newSearchParams,
      });
    }, DEFAULT_DELAY_TIMEOUT_IN_MS);

    if (name === 'supplier_name') {
      const { value } = event.target;

      const { contact } = this.props;

      if (contact.statusRequest === STATUS_REQUEST_LIST_CONTACT_DATA_PENDING) {
        return;
      }

      if (this.searchPurchasePaymentTimeoutID) {
        clearTimeout(this.searchPurchasePaymentTimeoutID);
      }

      this.searchPurchasePaymentTimeoutID = setTimeout(async () => {
        return await this.props.getListContact({
          ...TERSIER_DEFAULT_LIST_PARAMS,
          type: ['is_supplier'],
          contact_name: value,
        });
      }, 750);
    }
  }

  async handleOnOpenAutocomplete(event, name) {
    event.preventDefault();
    const { contact } = this.props;

    if (
      name === 'supplier_name' &&
      contact.statusRequest !== STATUS_REQUEST_LIST_CONTACT_DATA_PENDING
    ) {
      await this.props.getListContact({
        ...TERSIER_DEFAULT_LIST_PARAMS,
        type: ['is_supplier'],
      });
    }
  }

  async handleOnCloseAutocomplete(event, name) {
    if (name === 'supplier_name') {
      await this.props.clearContactData();
    }
  }

  async handleOnScrollAutocomplete(name) {
    if (name === 'supplier_name') {
      const { contact } = this.props;
      const { contactData } = contact;

      if (!contactData) return;

      const {
        current_page: page,
        next_page_url,
        per_page: paginate,
        search_contact_name: contact_name,
      } = contactData;

      if (!next_page_url) return;

      await this.props.getListContact({
        page: page + 1,
        paginate,
        type: ['is_supplier'],
        contact_name,
        isRewriteAll: false,
      });
    }
  }

  /**
   *
   * @param { object } event built-in event data
   * @param { array } newValue selected new value autocomplete
   * @param { string } containerName name of container data, usually contain all data before ge data in secondary name
   * @param { string } secondaryName name container for handling store data for send to BE
   * @returns
   *      set new state and hit purchase payment again
   */
  async handleChangeAutocomplete(event, newValue, containerName, secondaryName) {
    event.preventDefault();

    const { searchParams: currSearchParams } = this.state;

    if (containerName === 'supplier_name_container') {
      let newSearchParams = {
        ...currSearchParams,
        [containerName]: newValue,
        [secondaryName]: !isEmpty(newValue) ? newValue.contact_name : '',
      };

      this.setState((prevState) => ({
        ...prevState,
        searchParams: {
          ...newSearchParams,
        },
      }));

      const { purchase } = this.props;
      const { purchasePaymentData } = purchase;

      const paginate = purchasePaymentData ? purchasePaymentData.per_page : 20;

      return await this.props.getListPurchasePayment({
        paginate,
        ...newSearchParams,
      });
    }
  }

  render() {
    const { searchParams, focusedInputName } = this.state;

    const { t, i18n, classes, dataHeaders, purchase, contact } = this.props;

    const { supplier_name_container } = searchParams;

    const { language } = i18n;

    const { contactData } = contact;

    let contactDataList = [];

    let paginateContactData = TERSIER_DEFAULT_LIST_PARAMS.paginate;

    if (contactData) {
      if (Array.isArray(contactData.data)) {
        contactDataList = contactData.data;
      }

      paginateContactData = contactData.per_page;
    }

    const isPurchasePaymentLoading =
      purchase.statusRequest === STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PENDING;

    return (
      <TableHead className={classes.TableHeadMainContainer}>
        <TableRow>
          {dataHeaders &&
            dataHeaders.map((dataHeader, index) => {
              if (!dataHeader.name_input) {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{
                      verticalAlign: 'top',
                      alignItems: 'flex-end',
                    }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                  </TableCell>
                );
              }

              // table header for date
              if (columnsSearchWithDate.includes(dataHeader.name_input)) {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{ width: dataHeader.width }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                    <DateTimePicker
                      startDate={searchParams['from_date']}
                      endDate={searchParams['to_date']}
                      textCancel={t('button.cancel')}
                      textApply={t('button.apply')}
                      placeHolderInput={dataHeader.placeholder}
                      onSelectedChange={(value) => this.handleOnChangeDate(value)}
                      locale={language}
                      defaultKeyStartDate={'from_date'}
                      defaultKeyEndDate={'to_date'}
                      isDisabledInput={isPurchasePaymentLoading}
                    />
                  </TableCell>
                );
              }

              // table header for supplier
              if (dataHeader.name_input === 'supplier_name') {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{ width: dataHeader.width }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                    <FormControl>
                      <AutocompleteCustom
                        id='supplier_name'
                        name='supplier_name'
                        size='small'
                        sx={{ width: 300 }}
                        options={contactDataList}
                        value={supplier_name_container}
                        autoHighlight={true}
                        isPaginated={true}
                        totalOptions={autocompleteHelper.getOptionPaginatedAutocomplete(
                          contactDataList,
                          paginateContactData,
                          3,
                        )}
                        PopperComponent={PopperAutoWidth}
                        onOpen={(event) => this.handleOnOpenAutocomplete(event, 'supplier_name')}
                        onClose={(event) => this.handleOnCloseAutocomplete(event, 'supplier_name')}
                        onChange={(event, newValue) =>
                          this.handleChangeAutocomplete(
                            event,
                            newValue,
                            'supplier_name_container',
                            'supplier_name',
                            'contact_name',
                          )
                        }
                        ListboxProps={{
                          onScroll: (event) => {
                            // i need scroll into bottom view when api was hitted
                            const { offsetHeight, scrollHeight, scrollTop } = event.target;

                            const isEndOfScroll = offsetHeight + scrollTop === scrollHeight;

                            if (isEndOfScroll) {
                              this.handleOnScrollAutocomplete('supplier_name');
                            }
                          },
                        }}
                        getOptionLabel={(option) =>
                          autocompleteHelper.getInputValue(
                            option,
                            ['contact_code', 'contact_name'],
                            ['contact_name'],
                            formatHelp.contactCodeWithName,
                          )
                        }
                        isOptionEqualToValue={(option, value) =>
                          autocompleteHelper.isOptionEqualToValue(option, value, 'contact_id')
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled={isPurchasePaymentLoading}
                            name='supplier_name'
                            placeholder={t(
                              'dialog.base-add-update-purchase-order.input.supplier.placeholder',
                            )}
                            onChange={this.handleOnChangeInput}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <InputAdornmentLoading
                                  isLoading={
                                    focusedInputName === 'supplier_name' &&
                                    contact.statusRequest ===
                                      STATUS_REQUEST_LIST_CONTACT_DATA_PENDING
                                  }
                                  defaultInputAdornment={params.InputProps.endAdornment}
                                />
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                );
              }

              return (
                <TableCell
                  key={index}
                  align={dataHeader.align}
                  style={{ width: dataHeader.width }}
                  className={classes.tableCellMain}
                >
                  <p>{dataHeader.label}</p>
                  <FormControl fullWidth>
                    <OutlinedInput
                      name={dataHeader.name_input}
                      readOnly={isPurchasePaymentLoading}
                      autoFocus={dataHeader.name_input === focusedInputName}
                      size='small'
                      placeholder={dataHeader.placeholder}
                      onChange={this.handleOnChangeInput}
                      value={searchParams[dataHeader.name_input]}
                      {...(searchParams[dataHeader.name_input] && {
                        endAdornment: (
                          <InputAdornment
                            position='end'
                            className={classes.inputAdornmentClearSingleFilter}
                          >
                            {searchParams[dataHeader.name_input] && (
                              <IconButton
                                edge='end'
                                name={dataHeader.name_input}
                                className={classes.iconButtonContainerClear}
                                disabled={isPurchasePaymentLoading}
                                onClick={this.handleClearSingleFilter}
                              >
                                <Clear fontSize='10px' />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      })}
                    />
                  </FormControl>
                </TableCell>
              );
            })}
        </TableRow>
      </TableHead>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    purchase: state.purchase,
    contact: state.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListPurchasePayment: (paramsgetListPurchasePayment) =>
      dispatch(getListPurchasePayment(paramsgetListPurchasePayment)),
    getListContact: (paramsGetListContact) => dispatch(contactList(paramsGetListContact)),
    clearContactData: () => dispatch(clearContactData()),
    setFailedMessage: (primaryText, secondaryText) =>
      messageHelper.failedMessage(dispatch, primaryText, secondaryText, false),
  };
};

// add style into component
const stylingTableHeadSearchPurchasePayment = withStyles(styles)(TableHeadSearchPurchasePayment);

// add translation into component
const TableHeadPurchasePaymentTranslate = withTranslation()(stylingTableHeadSearchPurchasePayment);

// connect with style and action into component
const ConnectedTableHeadSearchPurchasePayment = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TableHeadPurchasePaymentTranslate);

export default ConnectedTableHeadSearchPurchasePayment;
