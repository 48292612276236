import notificationActions from '../../store/notification/notification.action';
import {
  LOCAL_STORAGE_ALL_NOTIFICATION_LIST,
  LOCAL_STORAGE_INVITATION_NOTIFICATION_LIST,
} from '../constants/storage.constant';
import {
  REQUEST_STATUS_LIST_ALL_NOTIFICATIONS_FAILED,
  REQUEST_STATUS_LIST_ALL_NOTIFICATIONS_PENDING,
  REQUEST_STATUS_LIST_ALL_NOTIFICATIONS_SUCCESS,
  REQUEST_STATUS_LIST_INVITATION_NOTIFICATION_FAILED,
  REQUEST_STATUS_LIST_INVITATION_NOTIFICATION_PENDING,
  REQUEST_STATUS_LIST_INVITATION_NOTIFICATION_SUCCESS,
  ROOT_STORE_NAME_NOTIFICATION,
  SLICE_NAME_ALL_NOTIFICATIONS_ALL_LIST,
  SLICE_NAME_INVITATION_NOTIFICATION_LIST,
} from '../constants/store.constant';

import {
  additionalParamsAllNotificationsList,
  additionalParamsInvitationNotificationList,
} from './additional-params-action';
import { NOTIFICATION_ALL_TYPE, NOTIFICATION_INVITATION_TYPE } from './notification-type.default';
import {
  DEFAULT_KEY_NAME_NOTIFICATION_ID_API_REF,
  DEFAULT_KEY_NAME_PAGINATE,
  DEFAULT_KEY_NAME_REQUEST_STATUS,
} from './object-keyname.default';
import {
  DEFAULT_TAB_NAME_ALL_NOTIFICATIONS,
  DEFAULT_TAB_NAME_INVITATION_NOTIFICATION,
} from './tab-name.default';

export const relationalNotificationTypeWithNotificationListActionConfig = {
  [NOTIFICATION_ALL_TYPE]: {
    action: notificationActions.getNotificationList,
    parentStateName: ROOT_STORE_NAME_NOTIFICATION,
    childrenStateName: SLICE_NAME_ALL_NOTIFICATIONS_ALL_LIST,
    localStorageName: LOCAL_STORAGE_ALL_NOTIFICATION_LIST,
    keynameRequestStatus: DEFAULT_KEY_NAME_REQUEST_STATUS,
    uniqueListKeyName: DEFAULT_KEY_NAME_NOTIFICATION_ID_API_REF,
    requestStatusPending: REQUEST_STATUS_LIST_ALL_NOTIFICATIONS_PENDING,
    requestStatusSuccess: REQUEST_STATUS_LIST_ALL_NOTIFICATIONS_SUCCESS,
    requestStatusFailed: REQUEST_STATUS_LIST_ALL_NOTIFICATIONS_FAILED,
    additionalParams: additionalParamsAllNotificationsList,
  },
  [NOTIFICATION_INVITATION_TYPE]: {
    action: notificationActions.getNotificationList,
    parentStateName: ROOT_STORE_NAME_NOTIFICATION,
    childrenStateName: SLICE_NAME_INVITATION_NOTIFICATION_LIST,
    localStorageName: LOCAL_STORAGE_INVITATION_NOTIFICATION_LIST,
    uniqueListKeyName: DEFAULT_KEY_NAME_NOTIFICATION_ID_API_REF,
    keynameRequestStatus: DEFAULT_KEY_NAME_REQUEST_STATUS,
    additionalParams: additionalParamsInvitationNotificationList,
    requestStatusPending: REQUEST_STATUS_LIST_INVITATION_NOTIFICATION_PENDING,
    requestStatusSuccess: REQUEST_STATUS_LIST_INVITATION_NOTIFICATION_SUCCESS,
    requestStatusFailed: REQUEST_STATUS_LIST_INVITATION_NOTIFICATION_FAILED,
  },
};

export const getRelationalNotificationTypeWithNotificationActionListConfiguration = (
  notificationType = NOTIFICATION_ALL_TYPE,
) => {
  if (!notificationType) notificationType = NOTIFICATION_ALL_TYPE;
  if (
    !Object.keys(relationalNotificationTypeWithNotificationListActionConfig).includes(
      notificationType,
    )
  )
    notificationType = Object.keys(relationalNotificationTypeWithNotificationListActionConfig)[0];

  return relationalNotificationTypeWithNotificationListActionConfig[notificationType];
};

export const relationNotificationTypeWithNotificationListComponentConfig = {
  [NOTIFICATION_ALL_TYPE]: {
    labelTab: 'tab.all',
    valueTab: DEFAULT_TAB_NAME_ALL_NOTIFICATIONS,
  },
  [NOTIFICATION_INVITATION_TYPE]: {
    labelTab: 'tab.invitation',
    valueTab: DEFAULT_TAB_NAME_INVITATION_NOTIFICATION,
  },
};

export const defaultSizeNotificationMediumHeight = 'medium';
export const defaultSizeNotificationLargeHeight = 'large';

export const sizeNotificationHeightWithComponentUtils = {
  [defaultSizeNotificationMediumHeight]: {
    height: 'auto',
    maxHeightMenu: '700px',
    maxHeightTabPanel: 'calc(50vh - 216px)',
    additionalActionProps: {
      [DEFAULT_KEY_NAME_PAGINATE]: 10,
    },
  },
  [defaultSizeNotificationLargeHeight]: {
    height: 'calc(100vh - 140px)',
    maxHeightMenu: 'auto',
    maxHeightTabPanel: 'calc(95vh - 216px)',
    additionalActionProps: {
      [DEFAULT_KEY_NAME_PAGINATE]: 20,
    },
  },
};
