import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function withRouter(Child) {
  return function withRouter(props) {
    const location = useLocation();
    const navigate = useNavigate();

    return <Child {...props} location={location} navigate={navigate} />;
  };
}
