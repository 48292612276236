import * as React from 'react';
function SvgExpense(props) {
  return (
    <svg width={43} height={46} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#expense_svg__filter0_d_5693_194745)'>
        <path
          d='M2.088 1C1.49 1 1 1.393 1 1.874v40.25c0 .481.246.876.545.876.298 0 .981-.177 1.515-.392l2.405-.968c.536-.214 1.41-.214 1.945 0l2.406.968c.534.215 1.41.215 1.945 0l2.404-.968c.535-.214 1.411-.214 1.945 0l2.405.968c.535.215 1.41.215 1.945 0l2.406-.968c.535-.214 1.411-.214 1.946 0l2.402.968c.536.215 1.412.215 1.947 0l2.406-.968c.535-.214 1.408-.214 1.943 0l2.406.968c.535.215 1.217.392 1.516.392.3 0 .545-.395.545-.876V1.874c0-.48-.49-.874-1.09-.874H2.088z'
          fill='#FFCE54'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19.51 1v41.77c.353 0 .705-.055.973-.162l2.406-.968c.535-.214 1.411-.214 1.946 0l2.402.968c.536.215 1.412.215 1.947 0l2.406-.968c.535-.214 1.408-.214 1.943 0l2.405.968c.536.215 1.218.392 1.517.392.3 0 .545-.395.545-.876V1.874c0-.48-.49-.874-1.09-.874h-17.4z'
          fill='#F2B51D'
        />
        <path
          d='M28.19 25.499h4.348c.6 0 1.087-.391 1.087-.874 0-.485-.488-.876-1.087-.876H28.19c-.602 0-1.09.391-1.09.876 0 .483.488.874 1.09.874zM32.538 27.25H28.19c-.602 0-1.09.391-1.09.874s.488.876 1.09.876h4.35c.598 0 1.086-.393 1.086-.876s-.488-.874-1.087-.874z'
          fill='#30353C'
        />
        <path
          d='M19.488 23.749H6.438c-.602 0-1.087.391-1.087.876 0 .483.485.874 1.088.874h13.05c.598 0 1.086-.391 1.086-.874 0-.485-.488-.876-1.087-.876z'
          fill='#656D78'
        />
        <path
          d='M32.538 34.249H28.19c-.602 0-1.09.391-1.09.875 0 .482.488.875 1.09.875h4.35c.598 0 1.086-.393 1.086-.875 0-.484-.488-.875-1.087-.875z'
          fill='#30353C'
        />
        <path
          d='M10.787 34.249H6.44c-.603 0-1.088.391-1.088.875 0 .482.485.875 1.088.875h4.348c.601 0 1.088-.393 1.088-.875 0-.484-.487-.875-1.088-.875z'
          fill='#434A54'
        />
        <path
          d='M13.018 27.251v-.001h-6.58c-.601 0-1.087.391-1.087.874S5.837 29 6.439 29h6.579v-.003c.575-.023 1.032-.404 1.032-.873 0-.468-.457-.85-1.032-.873z'
          fill='#656D78'
        />
        <path
          d='M7.525 31.625c0 .483-.487.875-1.086.875-.603 0-1.088-.392-1.088-.875 0-.484.485-.876 1.088-.876.599 0 1.086.392 1.086.876zM11.876 31.625c0 .483-.488.875-1.088.875-.601 0-1.087-.392-1.087-.875 0-.484.486-.876 1.086-.876.601 0 1.088.392 1.088.876zM16.226 31.625c0 .483-.487.875-1.088.875-.6 0-1.088-.392-1.088-.875 0-.484.487-.876 1.088-.876.6 0 1.088.392 1.088.876zM20.575 31.625c0 .483-.488.875-1.087.875-.602 0-1.088-.392-1.088-.875 0-.484.486-.876 1.088-.876.6 0 1.087.392 1.087.876z'
          fill='#F6BB42'
        />
        <path
          d='M24.927 31.625c0 .483-.488.875-1.09.875-.599 0-1.086-.392-1.086-.875 0-.484.487-.876 1.086-.876.602 0 1.09.392 1.09.876zM29.276 31.625c0 .483-.488.875-1.087.875-.602 0-1.09-.392-1.09-.875 0-.484.488-.876 1.09-.876.6 0 1.087.392 1.087.876zM33.624 31.625c0 .483-.487.875-1.086.875-.602 0-1.086-.392-1.086-.875 0-.484.484-.876 1.086-.876.599 0 1.086.392 1.086.876z'
          fill='#E7AD37'
        />
        <path
          d='M21.999 6.61c-.198-.218-.52-.36-.88-.36-.602 0-1.087.391-1.087.874 0 .192.078.37.208.514l-.001.001 3.92 4.34 1.759-1.03-3.92-4.339z'
          fill='#30353C'
        />
        <path
          d='M17.857 6.25c-.363 0-.682.142-.88.36l-3.92 4.339 1.759 1.03 3.92-4.34h-.001a.762.762 0 00.208-.515c0-.483-.487-.874-1.086-.874z'
          fill='#434A54'
        />
        <path d='M26.013 19.375h-13.05l-2.176-8.751H28.19l-2.176 8.75z' fill='#ED5564' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19.488 19.375h6.525l2.176-8.751h-8.7v8.75z'
          fill='#E13041'
        />
        <path
          d='M28.19 10.624H10.786c-.6 0-1.086.392-1.086.875 0 .484.486.875 1.086.875H28.19c.6 0 1.087-.391 1.087-.875 0-.483-.488-.875-1.087-.875z'
          fill='#DA4453'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19.488 10.624h8.701c.6 0 1.087.392 1.087.875 0 .484-.488.875-1.087.875h-8.7v-1.75z'
          fill='#AE313E'
        />
        <path
          d='M17.312 13.249c-.6 0-1.086.392-1.086.875v2.626c0 .483.486.874 1.086.874.6 0 1.088-.391 1.088-.874v-2.626c0-.483-.487-.875-1.088-.875zM21.664 13.249c-.602 0-1.09.392-1.09.875v2.626c0 .483.488.874 1.09.874.6 0 1.087-.391 1.087-.874v-2.626c0-.483-.488-.875-1.087-.875z'
          fill='#DA4453'
        />
        <path
          d='M21.664 13.249c-.602 0-1.09.392-1.09.875v2.626c0 .483.488.874 1.09.874.6 0 1.087-.391 1.087-.874v-2.626c0-.483-.488-.875-1.087-.875z'
          fill='#AE313E'
        />
      </g>
      <defs>
        <filter
          id='expense_svg__filter0_d_5693_194745'
          x={0}
          y={0}
          width={45}
          height={50}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5693_194745' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5693_194745' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgExpense;
