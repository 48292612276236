import { isEmpty } from 'lodash';

import {
  DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
  DEFAULT_KEY_NAME_ATTACHMENT_NAME_API_REF,
  DEFAULT_KEY_NAME_FEATURE_API_REF,
  DEFAULT_KEY_NAME_FILE_CONTAINER,
  DEFAULT_KEY_NAME_FILE_NAME_API_REF,
  DEFAULT_KEY_NAME_FILE_PROPERTIES,
  DEFAULT_KEY_NAME_PATH,
  DEFAULT_KEY_NAME_UUID_API_REF,
} from '../../default/object-keyname.default';
import { DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_TYPE } from '../../default/transaction-type';
import arrHelp from '../../helpers/array.helpers';
import objHelper from '../../helpers/object.helper';

/**
 *
 * @param {*} param0
 *
 *
 * @param   { File }    attachmentFile                      file that contianed for attachment
 * @param   { string }  relationValueToOtherAttachment      value that connected for other attachment
 * @param   { string }  featureDocument                     that attachment come for document name
 * @param   { string }  keynameFeature                      key name for key feature
 * @param   { string }  keynameFileName                     key for file name
 * @param   { string }  keynameRelationedAttachment         key for relational with other attachment
 * @param   { string }  keynameAttachment                   key for attachment
 *
 * @returns
 *      formData that already for request attachment
 *
 */
export const generalizeAttachmentRequest = ({
  attachmentFile,
  relationValueToOtherAttachment = null,
  featureDocument = DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_TYPE,
  keynameFeature = DEFAULT_KEY_NAME_FEATURE_API_REF,
  keynameFileName = DEFAULT_KEY_NAME_FILE_NAME_API_REF,
  keynameRelationedAttachment = DEFAULT_KEY_NAME_UUID_API_REF,
  keynameAttachment = DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
}) => {
  if (isEmpty(attachmentFile)) return;

  let requestAttachment = {
    [keynameFileName]: attachmentFile ? attachmentFile.name : '',
    [keynameFeature]: featureDocument,
  };

  if (relationValueToOtherAttachment) {
    requestAttachment = {
      ...requestAttachment,
      [keynameRelationedAttachment]: relationValueToOtherAttachment,
    };
  }

  requestAttachment = {
    ...requestAttachment,
    [keynameAttachment]: new File([attachmentFile], requestAttachment.file_name),
  };

  return objHelper.objectToFormData(requestAttachment);
};

/**
 *
 * @param { object } param0
 *
 * @param { array }             attachment                          array that contained value from API
 * @param { string }            relationValueToOtherAttachment      attachment unique identity from document transaction
 * @param { string }            keynamePath                         key name for getting path file on each items
 * @param { string }            keynameFileName                     key for getting file name on each items
 * @param { string }            keynameFileContainer                key for storing file to file container, that consume on component
 * @param { string }            keynameFileProperties               key for storing file to cunsume on API
 * @param { string }            keynameRelationedAttachment         relational key name for other attachment
 *
 * @returns
 *          array that contained each items splitted for fileContainer (view on user) and fileProperties (handling for API)
 */
export const generalizeAttachmentFromDetails = ({
  dataDetails = {},
  keynamePath = DEFAULT_KEY_NAME_PATH,
  keynameFileName = DEFAULT_KEY_NAME_ATTACHMENT_NAME_API_REF,
  keynameFileContainer = DEFAULT_KEY_NAME_FILE_CONTAINER,
  keynameFileProperties = DEFAULT_KEY_NAME_FILE_PROPERTIES,
  keynameRelationedAttachment = DEFAULT_KEY_NAME_UUID_API_REF,
  keynameUUIDapiRef = DEFAULT_KEY_NAME_UUID_API_REF,
  keynameAttachmentAPIref = DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
}) => {
  if (
    isEmpty(dataDetails) ||
    isEmpty(dataDetails[keynameUUIDapiRef]) ||
    isEmpty(dataDetails[keynameAttachmentAPIref])
  )
    return [];

  let attachment = dataDetails[keynameAttachmentAPIref],
    relationValueToOtherAttachment = dataDetails[keynameUUIDapiRef];

  if (!Array.isArray(attachment) || isEmpty(attachment) || !relationValueToOtherAttachment)
    return [];

  attachment = attachment.map((singleAttachment) => {
    if (isEmpty(attachment)) return null;

    const fileName = singleAttachment[keynameFileName],
      path = singleAttachment[keynamePath];

    return {
      [keynameFileContainer]: new File([path], fileName),
      [keynameFileProperties]: {
        ...singleAttachment,
        [keynameRelationedAttachment]: relationValueToOtherAttachment,
      },
    };
  });

  return arrHelp.filteringExistedValue(attachment);
};

/**
 *
 * @param { object } param0   object as params
 *
 * @param  { array }  attachment                  request attachment
 * @param  { array }  keynameFileProperties       keyname for file properties
 * @param  { array }  keynameUUIDapiRef           keyname for uuid api ref
 * @returns
 */
export const getUUIDattachmentRequest = ({
  attachment = [],
  keynameFileProperties = DEFAULT_KEY_NAME_FILE_PROPERTIES,
  keynameUUIDapiRef = DEFAULT_KEY_NAME_UUID_API_REF,
}) => {
  return Array.isArray(attachment) && attachment.length
    ? attachment[0][keynameFileProperties][keynameUUIDapiRef] || ''
    : '';
};
