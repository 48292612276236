import { cloneDeep, isEmpty, pick } from 'lodash';

import arrHelp from './array.helpers';
import objHelper from './object.helper';

// @params: data, provide data that to compare into validation data
// @params: validationData, checking each data
// @params: keyIdentity, for differentiate each data and validation data and for ignore validalidation data
// all data and validation must in array type and have key identity
// example data
// [{ id: 'fnia', keyData: 'value', ... }, ...]
// example validation
// [{ id: 'fnia', keyData: { isValid: func(), error: bool }, ... }, ...]
// returning object of validation data that has update with data, and overall validation result
// example { resultValidationData: {}, isOverallValid: bool }
// @bypassInRow, passing data in row is valid all or partially valid, when youre want partially turn false, and vice versa
// @params: allowKeysValidation, where only keys for validation, and can hop validation in row
// @params: customDiscardValue, value when youre ignore, that keys is invalid again
function withDataNValidation(
  data,
  validationData,
  keyIdentity = 'id',
  bypassInRow = false,
  allowKeysValidation = [],
  customDiscardValue = [],
) {
  if (!Array.isArray(data) || !Array.isArray(validationData)) {
    return false;
  }

  let isOverallValid = true;

  let resultValidationData = Array.from(validationData);

  // checking each single validation
  for (let singleValidation of resultValidationData) {
    const keysValidation = Object.keys(singleValidation);

    const getValidationID = singleValidation[keyIdentity];

    let selectedDataForValidation = data.filter((d) => d.id === getValidationID)[0];

    let isDataInputted = objHelper.filterKeyObj(selectedDataForValidation, [], allowKeysValidation);

    if (isDataInputted) {
      isDataInputted = objHelper.filteringExistedValue(
        isDataInputted,
        [],
        true,
        customDiscardValue,
      );
    }

    if (!isDataInputted && bypassInRow) {
      continue;
    }

    if (!selectedDataForValidation) {
      continue;
    }

    for (const key of keysValidation) {
      if (
        key in selectedDataForValidation &&
        key !== keyIdentity &&
        singleValidation[key] &&
        'isValid' in singleValidation[key]
      ) {
        const isValidSingleData = singleValidation[key].isValid(selectedDataForValidation[key]);

        singleValidation = {
          ...singleValidation,
          [key]: {
            ...singleValidation[key],
            error: !isValidSingleData,
          },
        };

        isOverallValid = isOverallValid && isValidSingleData;
      }
    }

    resultValidationData = arrHelp.combineDataObjInArray(
      resultValidationData,
      'id',
      getValidationID,
      singleValidation,
    );
  }

  return {
    resultValidationData,
    isOverallValid,
  };
}

/**
 *
 * @param { array } data
 * @param { array } validationData
 * @param { string } keyIdentity
 * @param { array } keysValidation
 * @param { array } keysDepends
 * @returns
 */
function withDependingAnotherDataArgs(
  data,
  validationData,
  keyIdentity = 'id',
  keysValidationDepends = [],
  keysAnotherDataDepends = [],
) {
  if (!Array.isArray(data) || !Array.isArray(validationData)) {
    return false;
  }

  const filteringValidationDataWithoutKeysDepend = validationData.map((validation) => {
    return objHelper.filterKeyObj(validation, keysValidationDepends);
  });

  let { resultValidationData, isOverallValid } = checkValidation.withDataNValidation(
    data,
    filteringValidationDataWithoutKeysDepend,
    keyIdentity,
  );

  for (let singleValidation of validationData) {
    const getValidationID = singleValidation[keyIdentity];

    let selectedDataForValidation = data.filter((d) => d[keyIdentity] === getValidationID)[0];

    if (!selectedDataForValidation) continue;

    // container validation before combine with result without ignored key
    let tempSingleValidation = {};

    // checking validation only for two passing argument in is valid function
    for (let i = 0; i < keysValidationDepends.length; i++) {
      const keyValidation = keysValidationDepends[i];
      const keyAnotherDataDepend = keysAnotherDataDepends[i];

      // get multiple data with array, you can passing multiple keyname on single item
      let selectedOtherValueData = !Array.isArray(keyAnotherDataDepend)
        ? [keyAnotherDataDepend]
        : keyAnotherDataDepend;

      if (!isEmpty(selectedOtherValueData))
        selectedOtherValueData = Object.values(
          pick(selectedDataForValidation, selectedOtherValueData),
        );

      if (
        keyValidation in selectedDataForValidation &&
        keyValidation !== keyIdentity &&
        singleValidation[keyValidation] &&
        'isValid' in singleValidation[keyValidation]
      ) {
        const isValidSingleData = singleValidation[keyValidation].isValid(
          selectedDataForValidation[keyValidation],
          ...selectedOtherValueData,
        );

        // adding key on validation where after filtering before
        tempSingleValidation = {
          ...tempSingleValidation,
          [keyValidation]: {
            ...singleValidation[keyValidation],
            error: !isValidSingleData,
          },
        };

        isOverallValid = isOverallValid && isValidSingleData;
      }
    }

    resultValidationData = arrHelp.combineDataObjInArray(
      resultValidationData,
      'id',
      getValidationID,
      tempSingleValidation,
    );
  }

  return {
    resultValidationData,
    isOverallValid,
  };
}

/**
 *
 * @param { object }    currValidation validation data that minimum contained { error: [bool] }
 * @param { array }     keysChangeStatusError    name key that for change status error
 * @return
 *      status error validation is true on keysChangeStatusError
 *      object that contain
 *      {
 *          currValidation: [ validation-data ],
 *          newValidation: [ new-validation-data ],
 *      }
 */
function changeStatusToError(currValidation, keysChangeStatusError) {
  if (typeof currValidation !== 'object' || !keysChangeStatusError.length) {
    return {
      currValidation,
      newValidation: cloneDeep(currValidation),
    };
  }

  let newValidation = cloneDeep(currValidation);

  // change each keys on validation
  for (let keyValidation of keysChangeStatusError) {
    if (!(keyValidation in newValidation)) continue;

    if (!('error' in newValidation[keyValidation])) continue;

    newValidation = {
      ...newValidation,
      [keyValidation]: {
        ...newValidation[keyValidation],
        error: true,
      },
    };
  }

  return {
    currValidation,
    newValidation,
  };
}

/**
 *
 * @param { object }    currValidation validation data that minimum contained { error: [bool] }
 * @param { array }     keysChangeStatusValid    name key that for change status valid
 * @return
 *      status error validation is false on keysChangeStatusValid
 *      object that contain
 *      {
 *          currValidation: [ validation-data ],
 *          newValidation: [ new-validation-data ],
 *      }
 */
function changeStatusToValid(currValidation, keysChangeStatusValid) {
  if (typeof currValidation !== 'object' || !keysChangeStatusValid.length) {
    return {
      currValidation,
      newValidation: cloneDeep(currValidation),
    };
  }

  let newValidation = cloneDeep(currValidation);

  // change each keys on validation
  for (let keyValidation of keysChangeStatusValid) {
    if (!(keyValidation in newValidation)) continue;

    if (!('error' in newValidation[keyValidation])) continue;

    newValidation = {
      ...newValidation,
      [keyValidation]: {
        ...newValidation[keyValidation],
        error: false,
      },
    };
  }

  return {
    currValidation,
    newValidation,
  };
}

/**
 *
 * @param { object }    currValidation                  validation data that minimum contained { error: [bool] }
 * @param { array }     keysChangeStatusValidation      name key that for change status validation
 * @param { bool }      isForceChangeValidation         you can determine autoforce for change status validation
 * @param { bool }      forceStatusValidationInto       status force validation to this status validation, you can must passing true into params isForceChangeValidation
 * @return
 *      change status erorr to opposite status error on keysChangeStatusValidation
 *      object that contain
 *      {
 *          currValidation: [validation-data],
 *          newValidation: [new-validation-data],
 *      }
 */
function changeOppositeStatusValidation(
  currValidation,
  keysChangeStatusValidation,
  isForceChangeValidation = false,
  forceStatusValidationInto = true,
) {
  if (typeof currValidation !== 'object' || !keysChangeStatusValidation.length) {
    return {
      currValidation,
      newValidation: cloneDeep(currValidation),
    };
  }

  let newValidation = cloneDeep(currValidation);

  // change each keys on validation
  for (let keyValidation of keysChangeStatusValidation) {
    if (!(keyValidation in newValidation)) continue;

    if (!('error' in newValidation[keyValidation])) continue;

    const { error: currError } = newValidation[keyValidation];

    const newErrorStatus = isForceChangeValidation ? forceStatusValidationInto : !currError;

    // change status error into opposite status error
    newValidation = {
      ...newValidation,
      [keyValidation]: {
        ...newValidation[keyValidation],
        error: newErrorStatus,
      },
    };
  }

  return {
    currValidation,
    newValidation,
  };
}

/**
 *
 * @param { object } containerValidation object container for validator
 * @param { string } keyValidation name key validator that for restore function isValid
 * @param { function } initialValidation function for initializing validation
 * @param { string } keyIdentityValidator string that indicate identity for validator, this is can be skip for adding isValid function
 * @returns
 *      restored function checker validation on key isValid
 */
function restoreFuncIsValidData(
  containerValidation,
  keyValidation,
  initialValidation,
  keyIdentityValidator = 'id',
) {
  if (
    typeof initialValidation === 'function' &&
    Array.isArray(containerValidation[keyValidation])
  ) {
    containerValidation[keyValidation] = containerValidation[keyValidation].map(
      (singleValidation) => {
        const keysValidationData = Object.keys(singleValidation);

        let newValidator = keysValidationData.reduce((previousValidator, currValidator) => {
          if (currValidator === keyIdentityValidator) {
            return {
              ...previousValidator,
              [currValidator]: singleValidation[currValidator],
            };
          }

          const { error } = singleValidation[currValidator];

          const { isValid } = initialValidation()[currValidator];

          return {
            ...previousValidator,
            [currValidator]: {
              error,
              isValid,
            },
          };
        }, {});

        return newValidator;
      },
    );
  }

  return containerValidation;
}

const checkValidation = {
  withDataNValidation,
  withDependingAnotherDataArgs,
  changeStatusToError,
  changeOppositeStatusValidation,
  restoreFuncIsValidData,
  changeStatusToValid,
};

export default checkValidation;
