import * as React from 'react';
function SvgWarehouseSettingMenu(props) {
  return (
    <svg width={118} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#warehouse-setting-menu_svg__filter0_d_5586_193444)'>
        <path
          d='M70.783 68.9h-38.47c-.154 0-.265-.113-.265-.273V46.541c0-.16.11-.274.266-.274h38.469c.154 0 .265.114.265.274v22.086c0 .137-.133.274-.265.274z'
          fill='#FF7058'
        />
        <path
          d='M35.654 48.045h-1.77V67.1h1.77V48.045zM39.392 48.045h-1.77V67.1h1.77V48.045zM43.109 48.045h-1.77V67.1h1.77V48.045zM46.847 48.045h-1.77V67.1h1.77V48.045zM50.564 48.045h-1.77V67.1h1.77V48.045zM54.302 48.045h-1.77V67.1h1.77V48.045zM58.019 48.045h-1.77V67.1h1.77V48.045zM61.757 48.045h-1.77V67.1h1.77V48.045zM65.496 48.045h-1.77V67.1h1.77V48.045zM69.212 48.045h-1.77V67.1h1.77V48.045z'
          fill='#F1543F'
        />
        <path
          d='M109.783 68.9h-38.47c-.154 0-.265-.113-.265-.273V46.541c0-.16.11-.274.266-.274h38.469c.154 0 .265.114.265.274v22.086c0 .137-.111.274-.265.274z'
          fill='#E6E9EE'
        />
        <path
          d='M74.654 48.045h-1.77V67.1h1.77V48.045zM78.392 48.045h-1.77V67.1h1.77V48.045zM82.109 48.045h-1.77V67.1h1.77V48.045zM85.847 48.045h-1.77V67.1h1.77V48.045zM89.586 48.045h-1.77V67.1h1.77V48.045zM93.302 48.045h-1.77V67.1h1.77V48.045zM97.04 48.045h-1.769V67.1h1.77V48.045zM100.757 48.045h-1.77V67.1h1.77V48.045zM104.496 48.045h-1.77V67.1h1.77V48.045zM108.212 48.045h-1.77V67.1h1.77V48.045z'
          fill='#CED5E0'
        />
        <path
          d='M70.783 46.267h-38.47c-.154 0-.265-.114-.265-.274V23.907c0-.16.11-.273.266-.273h38.469c.154 0 .265.113.265.273v22.086c0 .137-.133.274-.265.274z'
          fill='#324A5E'
        />
        <path
          d='M35.654 25.412h-1.77v19.055h1.77V25.412zM39.392 25.412h-1.77v19.055h1.77V25.412zM43.109 25.412h-1.77v19.055h1.77V25.412zM46.847 25.412h-1.77v19.055h1.77V25.412zM50.564 25.412h-1.77v19.055h1.77V25.412zM54.302 25.412h-1.77v19.055h1.77V25.412zM58.019 25.412h-1.77v19.055h1.77V25.412zM61.757 25.412h-1.77v19.055h1.77V25.412zM65.496 25.412h-1.77v19.055h1.77V25.412zM69.212 25.412h-1.77v19.055h1.77V25.412z'
          fill='#2B3B4E'
        />
        <path
          d='M109.783 46.267h-38.47c-.154 0-.265-.114-.265-.274V23.907c0-.16.11-.273.266-.273h38.469c.154 0 .265.113.265.273v22.086c0 .137-.111.274-.265.274z'
          fill='#FFD05B'
        />
        <path
          d='M74.654 25.412h-1.77v19.055h1.77V25.412zM78.392 25.412h-1.77v19.055h1.77V25.412zM82.109 25.412h-1.77v19.055h1.77V25.412zM85.847 25.412h-1.77v19.055h1.77V25.412zM89.586 25.412h-1.77v19.055h1.77V25.412zM93.302 25.412h-1.77v19.055h1.77V25.412zM97.04 25.412h-1.769v19.055h1.77V25.412zM100.757 25.412h-1.77v19.055h1.77V25.412zM104.496 25.412h-1.77v19.055h1.77V25.412zM108.212 25.412h-1.77v19.055h1.77V25.412z'
          fill='#F9B54C'
        />
        <path
          d='M81.932 23.61H43.485c-.155 0-.266-.113-.266-.273V1.274c0-.16.11-.274.266-.274h38.469c.155 0 .265.114.265.274V23.36c0 .137-.132.25-.287.25z'
          fill='#84DBFF'
        />
        <path
          d='M46.825 2.778h-1.77v19.055h1.77V2.778zM50.541 2.778h-1.77v19.055h1.77V2.778zM54.28 2.778h-1.77v19.055h1.77V2.778zM58.019 2.778h-1.77v19.055h1.77V2.778zM61.735 2.778h-1.77v19.055h1.77V2.778zM65.474 2.778h-1.77v19.055h1.77V2.778zM69.19 2.778h-1.77v19.055h1.77V2.778zM72.928 2.778h-1.77v19.055h1.77V2.778zM76.645 2.778h-1.77v19.055h1.77V2.778zM80.383 2.778h-1.77v19.055h1.77V2.778z'
          fill='#54C0EB'
        />
        <path d='M66.215 21.56v47.423H1.452V21.56L33.834 12l32.381 9.56z' fill='#F2F4F7' />
        <path d='M66.196 64.59H1.452v4.393h64.744V64.59z' fill='#E6E9EE' />
        <path d='M58.84 36.943H8.827v32.04h50.015v-32.04z' fill='#324A5E' />
        <path d='M53.836 42.937H14.443V69h39.393V42.937z' fill='#FFD05B' />
        <path
          d='M53.816 49.81v-.344h-7.68v-6.529h-.305v6.529h-7.795v-6.529h-.305v6.529h-7.795v-6.529h-.305v6.529h-7.795v-6.529h-.305v6.529h-7.088v.344h7.088v5.978h-7.088v.344h7.088v5.978h-7.088v.344h7.088v6.391h.305v-6.39h7.795v6.39h.305v-6.39h7.795v6.39h.305v-6.39h7.795v6.39h.306v-6.39h7.68v-.345h-7.68v-5.978h7.68v-.344h-7.68V49.81h7.68zm-24.185 12.3h-7.795v-5.978h7.795v5.978zm0-6.322h-7.795V49.81h7.795v5.977zm8.1 6.322h-7.795v-5.978h7.795v5.978zm0-6.322h-7.795V49.81h7.795v5.977zm8.1 6.322h-7.795v-5.978h7.795v5.978zm0-6.322h-7.795V49.81h7.795v5.977z'
          fill='#F9B54C'
        />
        <path
          d='M22.295 24.575H8.616v4.41h13.679v-4.41zM40.673 24.575H26.994v4.41h13.679v-4.41zM59.032 24.575H45.353v4.41h13.679v-4.41zM50.893 42.937h-2.101v.845h2.101v-.845zM42.889 42.937h-2.101v.845h2.101v-.845zM34.884 42.937h-2.101v.845h2.101v-.845zM26.88 42.937h-2.102v.845h2.102v-.845zM18.875 42.937h-2.101v.845h2.101v-.845zM50.893 49.776h-2.101v.844h2.101v-.844zM42.889 49.776h-2.101v.844h2.101v-.844zM34.884 49.776h-2.101v.844h2.101v-.844zM26.88 49.776h-2.102v.844h2.102v-.844zM18.875 49.776h-2.101v.844h2.101v-.844zM50.893 56.115h-2.101v.844h2.101v-.844zM42.889 56.115h-2.101v.844h2.101v-.844zM34.884 56.115h-2.101v.844h2.101v-.844zM26.88 56.115h-2.102v.844h2.102v-.844zM18.875 56.115h-2.101v.844h2.101v-.844zM50.893 62.437h-2.101v.844h2.101v-.844zM42.889 62.437h-2.101v.844h2.101v-.844zM34.884 62.437h-2.101v.844h2.101v-.844zM26.88 62.437h-2.102v.844h2.102v-.844zM18.875 62.437h-2.101v.844h2.101v-.844z'
          fill='#E6E9EE'
        />
        <path
          opacity={0.1}
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.215 68.983V21.56L33.834 12v56.983h32.381z'
          fill='#475467'
        />
      </g>
      <defs>
        <filter
          id='warehouse-setting-menu_svg__filter0_d_5586_193444'
          x={0.452}
          y={0}
          width={116.596}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_193444' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_193444' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgWarehouseSettingMenu;
