import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import usePermission from '../../../hooks/usePermission';
import formatHelp from '../../../utils/helpers/format.helpers';

/**
 * @prop    { string }      typographyVariant           variant on typography
 * @prop    { object }      searchParamsInObj           search params when convert on object
 * @prop    { string }      searchParamsInStr           search params on sting
 * @prop    { string }      baseUrl                     base url for link component
 * @prop    { string }      typographyValue             value that displaying on typography
 * @prop    { bool }        isClickDisabled             determine is click disabled or not
 * @prop    { function }    onClick                     function when clicked typography
 * @prop    { object }      typographyProps             properties for typography component
 */
export default function TypographyLinkPrimary({
  typographyVariant = 'linkDetail',
  searchParamsInObj = {},
  searchParamsInStr = '',
  baseUrl = '',
  typographyValue = '',
  isClickDisabled = false,
  onClick,
  typographyProps,
  children = null,
  permissionProps = {},
}) {
  let destinationUrl = baseUrl;

  const { isPermissionsGranted } = usePermission();

  const isGrantedLink = isPermissionsGranted(permissionProps);

  if (!isEmpty(searchParamsInObj)) {
    destinationUrl = formatHelp.combineBaseUrlWithSearchParams(baseUrl, searchParamsInObj);
  } else if (!isEmpty(searchParamsInStr)) {
    destinationUrl = formatHelp.combineBaseUrlWithSearchParams(baseUrl, searchParamsInStr);
  }

  return (
    <Typography
      variant={typographyVariant}
      component={Link}
      to={isGrantedLink ? destinationUrl : ''}
      onClick={!isClickDisabled && isGrantedLink ? onClick : null}
      {...typographyProps}
    >
      {children || typographyValue}
    </Typography>
  );
}
