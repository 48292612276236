import * as React from 'react';
function SvgSalesDeliveryMenu(props) {
  return (
    <svg width={133} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#sales-delivery-menu_svg__filter0_d_5586_192016)'>
        <path
          d='M93.642 48.731v7.84H82.175v-2.652c0-.922-.625-1.614-1.46-1.614H62.578c-.834 0-1.46.692-1.46 1.614v2.652H46.42c-.938-3.805-4.066-6.572-7.819-6.572-3.752 0-6.88 2.767-7.818 6.572h-7.089v-7.84h69.95z'
          fill='#2B3B4E'
        />
        <path
          d='M93.642 48.731H23.694c-1.981 0-3.65-1.845-3.65-4.035V5.036c0-2.191 1.669-4.036 3.65-4.036h66.3c1.98 0 3.648 1.845 3.648 4.035v43.696z'
          fill='#324A5E'
        />
        <path d='M93.642 16.45H20.045v14.41h73.597V16.45z' fill='#FF7058' />
        <path d='M93.642 16.91v41.621h31.065V39.277l-11.05-22.367H93.642z' fill='#84DBFF' />
        <path
          d='M124.707 39.277v16.141h-5.629v3.113h-5.733c-.209-4.727-3.753-8.532-8.131-8.532-4.274 0-7.819 3.805-8.132 8.532h-3.544V17.026h20.015l11.154 22.251z'
          fill='#F9FAFB'
        />
        <path d='M120.955 37.202l-8.653-17.64H96.04v17.64h24.915z' fill='#84DBFF' />
        <path d='M106.673 37.202h14.281l-8.652-17.64h-1.147l-4.482 17.64z' fill='#54C0EB' />
        <path
          d='M38.6 67.985c4.491 0 8.131-4.026 8.131-8.993 0-4.966-3.64-8.993-8.13-8.993-4.491 0-8.132 4.027-8.132 8.993 0 4.967 3.64 8.993 8.131 8.993z'
          fill='#324A5E'
        />
        <path
          d='M38.6 64.757c2.88 0 5.213-2.581 5.213-5.765 0-3.184-2.334-5.764-5.213-5.764-2.878 0-5.212 2.58-5.212 5.764s2.334 5.765 5.212 5.765zM80.716 60.49H62.68c-.834 0-1.46-.69-1.46-1.613v-5.073c0-.922.626-1.614 1.46-1.614h18.035c.834 0 1.459.692 1.459 1.614v5.073c0 .922-.625 1.614-1.46 1.614z'
          fill='#E6E9EE'
        />
        <path d='M124.707 47.348h-3.023v6.687h3.023v-6.687z' fill='#FF7058' />
        <path
          d='M28.802 27.863c-.313 0-.626-.346-.626-.691v-6.803c0-.346.313-.692.626-.692h1.772s3.127 0 3.127 4.036c0 4.15-3.127 4.035-3.127 4.035h-1.772v.115zm.625-1.383h1.147s.521 0 .938-.346c.626-.461.938-1.268.938-2.421 0-1.153-.312-1.96-.938-2.421-.417-.346-.938-.346-.938-.346h-1.147v5.534zM41.311 27.172c0 .345-.312.691-.625.691H37.35c-.313 0-.625-.346-.625-.691v-6.803c0-.346.312-.692.625-.692h3.336c.313 0 .625.346.625.692s-.312.692-.625.692h-2.71v2.075h2.084c.313 0 .626.346.626.692s-.313.692-.626.692h-2.084v2.075h2.814c.313-.115.521.115.521.577zM44.96 27.863c-.313 0-.626-.346-.626-.691v-6.803c0-.346.313-.692.626-.692s.625.346.625.692v6.11h2.19c.312 0 .625.347.625.693 0 .345-.313.691-.625.691H44.96zM51.214 27.172v-6.803c0-.346.313-.692.626-.692s.625.346.625.692v6.803c0 .345-.312.691-.625.691s-.626-.346-.626-.691zM57.99 27.402L55.488 20.6c0-.116-.104-.923.522-.923.208 0 .52.116.52.462l1.877 5.188 1.877-5.188c.104-.23.313-.462.521-.462.73.116.625.808.521.923l-2.502 6.802c-.104.23-.312.461-.52.461 0 0-.21-.23-.314-.46zM69.145 27.172c0 .345-.313.691-.626.691h-3.335c-.313 0-.626-.346-.626-.691v-6.803c0-.346.313-.692.626-.692h3.335c.313 0 .626.346.626.692s-.313.692-.626.692h-2.814v2.075h2.189c.313 0 .625.346.625.692s-.312.692-.625.692h-2.19v2.075h2.815c.417-.115.626.115.626.577zM85.407 20.254c0 .115 0 .23-.104.346l-2.085 3.228v3.228c0 .346-.313.692-.626.692-.312 0-.625-.346-.625-.692v-3.228L79.882 20.6c-.104-.116-.104-.23-.104-.346 0-.346.208-.692.625-.692.209 0 .417.115.521.346l1.668 2.652 1.668-2.652a.568.568 0 01.522-.346c.104 0 .208 0 .312.115.209.231.313.462.313.577z'
          fill='#fff'
        />
        <path
          d='M101.252 40.43h-3.961c-.73 0-1.355-.692-1.355-1.499v-.46h6.671v.576c0 .807-.625 1.383-1.355 1.383z'
          fill='#E6E9EE'
        />
        <path
          d='M124.812 55.418h-5.734v4.612h5.734c.521 0 .938-.461.938-1.038v-2.421c0-.692-.417-1.153-.938-1.153z'
          fill='#324A5E'
        />
        <path
          d='M114.491 58.53h-1.146c-.209-4.726-3.753-8.53-8.132-8.53-4.378 0-7.922 3.804-8.13 8.53h-1.147c.208-5.418 4.274-9.799 9.277-9.799 5.004 0 9.07 4.266 9.278 9.8z'
          fill='#2B3B4E'
        />
        <path
          d='M105.214 67.985c4.49 0 8.131-4.026 8.131-8.993 0-4.966-3.641-8.993-8.131-8.993-4.491 0-8.132 4.027-8.132 8.993 0 4.967 3.641 8.993 8.132 8.993z'
          fill='#324A5E'
        />
        <path
          d='M105.214 64.757c2.878 0 5.212-2.581 5.212-5.765 0-3.183-2.334-5.764-5.212-5.764-2.879 0-5.213 2.58-5.213 5.764s2.334 5.765 5.213 5.765z'
          fill='#E6E9EE'
        />
        <path
          d='M75.608 24.75c.73-.46 1.25-1.383 1.25-2.42 0-1.5-1.146-2.768-2.501-2.768h-1.668c-.313 0-.625.346-.625.692v6.802c0 .346.312.692.625.692s.625-.346.625-.692v-2.075h.939l1.355 2.421c.208.346.521.461.834.23.313-.23.417-.576.208-.922l-1.042-1.96zm-2.19-1.037V21.06h1.043c.626 0 1.251.576 1.251 1.384 0 .691-.521 1.383-1.25 1.383h-1.043v-.115z'
          fill='#fff'
        />
        <path
          d='M22.633 60.423h2.4v7.71c0 .479-.537.867-1.2.867-.663 0-1.2-.388-1.2-.867v-7.71z'
          fill='#FFC61B'
        />
        <path
          d='M23.753 60.423v8.571c.027.001.053.006.08.006.663 0 1.2-.388 1.2-.867v-7.71h-1.28zM24.956 56.188l2.286-.529 5.423 12.21c.202.456-.146.943-.777 1.09-.631.145-1.307-.105-1.51-.561l-5.422-12.21z'
          fill='#EAA22F'
        />
        <path
          d='M22.71 56.188l-2.286-.529-5.422 12.21c-.203.456.145.943.776 1.09.631.145 1.307-.105 1.51-.561l5.421-12.21z'
          fill='#FFC61B'
        />
        <path d='M43.636 38.028H4.03V59.86h39.606V38.028z' fill='#fff' />
        <path d='M43.636 38.028H23.754V59.86h19.882V38.028z' fill='#D0D1D3' />
        <path
          d='M25.814 36.859h-3.961v-1.43c0-.789.887-1.429 1.98-1.429 1.094 0 1.98.64 1.98 1.43v1.429z'
          fill='#FFC61B'
        />
        <path
          d='M25.814 35.43c0-.79-.887-1.43-1.98-1.43-.027 0-.054.002-.08.003v2.856h2.06v-1.43z'
          fill='#EAA22F'
        />
        <path
          d='M44.076 39.184H3.59c-1.016 0-1.84-.595-1.84-1.329 0-.733.824-1.328 1.84-1.328h40.486c1.017 0 1.84.594 1.84 1.328 0 .734-.824 1.329-1.84 1.329zM44.076 61.073H3.59c-1.016 0-1.84-.595-1.84-1.328 0-.734.824-1.329 1.84-1.329h40.486c1.017 0 1.84.595 1.84 1.329 0 .733-.824 1.328-1.84 1.328z'
          fill='#71E2EF'
        />
        <path
          d='M44.076 36.527H23.753v2.657h20.323c1.017 0 1.84-.595 1.84-1.329s-.823-1.328-1.84-1.328zM44.076 58.416H23.753v2.657h20.323c1.017 0 1.84-.595 1.84-1.328 0-.734-.823-1.329-1.84-1.329z'
          fill='#38C6D9'
        />
        <path
          d='M36.924 45.44l-10.17 5.673-9.947-2.394L8.5 53.304l.73.688 7.8-4.306 9.952 2.395 10.676-5.955-.734-.686z'
          fill='#59595B'
        />
        <path
          d='M17.03 49.686l-7.8 4.306-.73-.688 8.307-4.585 6.947 1.672v.913l-6.723-1.618z'
          fill='#59595B'
        />
        <path
          d='M26.981 52.08l-3.228-.776v-.913l3.001.722 10.17-5.673.733.686-10.676 5.955z'
          fill='#272525'
        />
        <path
          d='M26.867 50.4c.916 0 1.658.536 1.658 1.197s-.742 1.197-1.658 1.197c-.916 0-1.658-.536-1.658-1.197s.742-1.197 1.658-1.197z'
          fill='#C92F00'
        />
        <path
          d='M16.918 48.005c.916 0 1.658.536 1.658 1.197 0 .662-.742 1.197-1.658 1.197-.916 0-1.658-.535-1.658-1.197 0-.66.742-1.197 1.658-1.197z'
          fill='#FF7F4F'
        />
        <path
          d='M37.29 44.585c.915 0 1.658.536 1.658 1.197s-.743 1.197-1.659 1.197c-.915 0-1.658-.536-1.658-1.197 0-.66.743-1.197 1.658-1.197z'
          fill='#EAA22F'
        />
        <path
          d='M8.865 52.452c.916 0 1.658.535 1.658 1.196 0 .661-.742 1.197-1.658 1.197-.916 0-1.658-.535-1.658-1.197 0-.66.742-1.197 1.658-1.197z'
          fill='#FF314F'
        />
      </g>
      <defs>
        <filter
          id='sales-delivery-menu_svg__filter0_d_5586_192016'
          x={0.75}
          y={0}
          width={132}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_192016' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_192016' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgSalesDeliveryMenu;
