import { cloneDeep, isEmpty, omit, pick } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF,
  DEFAULT_KEY_NAME_FILE_PROPERTIES,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_UUID_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import arrHelp from '../../helpers/array.helpers';
import objHelper from '../../helpers/object.helper';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import {
  allowKeysDataDebitNotePurchaseReceivablePayment,
  allowKeysDataPurchaseReceivablePayment,
  debitNotePurchaseReceivablePaymentCalculationNumberKeyNames,
  initialDataDebitNotePurchaseReceivablePayment,
  initialDataPurchaseReceivablePayment,
  initialValidationDebitNotePurchaseReceivablePayment,
  initialValidationPurchaseReceivablePayment,
  plainDebitNotePurchaseReceivablePaymentValidationWithoutID,
  plainDebitNotePurchaseReceivablePaymentWithoutID,
} from './initial-data-purchase-receivable-payment';

/**
 *
 * @param { object } param0         object as params function
 *
 * @param { object }    amount                  amount data generate of purchase receivable payment
 * @param { object }    companyUserData         current company user data
 * @param { object }    dataKeyname             return keyname of data
 * @param { object }    validationKeyname       return keyname of validation
 *
 * @returns
 *      data and validation for purchase receivable payment
 */
export const generateDataNvalidationAddPurchaseReceivablePayment = ({
  amount = 1,
  companyUserData,
  dataKeyname = 'purchaseReceivablePaymentData',
  validationKeyname = 'purchaseReceivablePaymentValidation',
}) => {
  const accountingPeriod = !isEmpty(companyUserData)
    ? companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF]
    : null;

  // overriding accounting period
  const wrapperInitialPurchaseReceivablePaymentData = (id) => {
    return {
      ...initialDataPurchaseReceivablePayment(id),
      accountingPeriod,
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
    };
  };

  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    wrapperInitialPurchaseReceivablePaymentData,
    initialValidationPurchaseReceivablePayment,
    dataKeyname,
    validationKeyname,
  );
};

/**
 *
 * @param { object } param0         object as params function
 *
 * @param { object }    amount                  amount data generate of purchase receivable payment
 * @param { object }    companyUserData         current company user data
 * @param { object }    dataKeyname             return keyname of data
 * @param { object }    validationKeyname       return keyname of validation
 *
 * @returns
 *      data and validation for purchase receivable payment
 */
export const generateDataNvalidationAddPurchaseReceivablePaymentWithWrapperAccountReceivable = ({
  amount = 1,
  companyUserData,
  dataKeyname = 'purchaseReceivablePaymentData',
  validationKeyname = 'purchaseReceivablePaymentValidation',
  isWrappingKeynameData = true,
  correspondWrappingKeyname = {
    account_receivable: 'account_id',
    account_receivable_container: 'account_id_container',
  },
}) => {
  const accountingPeriod = !isEmpty(companyUserData)
    ? companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF]
    : null;

  // overriding accounting period
  const wrapperInitialPurchaseReceivablePaymentData = (id) => {
    return {
      ...initialDataPurchaseReceivablePayment(id),
      accountingPeriod,
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]: accountingPeriod,
    };
  };

  let purchaseReceivablePaymentDataNvalidation = objHelper.creatorGenerateDataNValidationV2(
    amount,
    wrapperInitialPurchaseReceivablePaymentData,
    initialValidationPurchaseReceivablePayment,
    dataKeyname,
    validationKeyname,
  );

  if (isWrappingKeynameData) {
    const purchaseReceivablePaymentData = purchaseReceivablePaymentDataNvalidation[dataKeyname].map(
      (currentData) => {
        return objHelper.changeKeyNameWithCorrespondKeyTarget({
          obj: currentData,
          correspondObj: correspondWrappingKeyname,
        });
      },
    );

    const purchaseReceivablePaymentValidation = purchaseReceivablePaymentDataNvalidation[
      validationKeyname
    ].map((currentValidation) => {
      return objHelper.changeKeyNameWithCorrespondKeyTarget({
        obj: currentValidation,
        correspondObj: correspondWrappingKeyname,
      });
    });

    purchaseReceivablePaymentDataNvalidation = {
      [dataKeyname]: purchaseReceivablePaymentData,
      [validationKeyname]: purchaseReceivablePaymentValidation,
    };
  }

  return purchaseReceivablePaymentDataNvalidation;
};

/**
 *
 * @param { object } param0         object as params function
 *
 * @param { object }    amount                  amount data generate of debit note purchase receivable payment
 * @param { object }    dataKeyname             return keyname of data
 * @param { object }    validationKeyname       return keyname of validation
 *
 * @returns
 *      data and validation for debit note purchase receivable payment
 */
export const generateDataNvalidationAddDebitNotePurchaseReceivablePayment = ({
  amount = 0,
  dataKeyname = 'debitNotePurchaseReceivablePaymentData',
  validationKeyname = 'debitNotePurchaseReceivablePaymentValidation',
}) => {
  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    initialDataDebitNotePurchaseReceivablePayment,
    initialValidationDebitNotePurchaseReceivablePayment,
    dataKeyname,
    validationKeyname,
  );
};

/**
 *
 * @param { object }    param0         object as params function
 *
 * @param   { array }     debitNoteList           result for getting debit note list
 * @param   { string }    dataKeyname             return keyname of data
 * @param   { string }    validationKeyname       return keyname of validation
 *
 * @returns
 *      data and validation for debit note purchase receivable payment
 */
export const generateDataNvalidationAddDebitNotePurchaseReceivablePaymentFromDebitNoteList = ({
  debitNoteList,
  dataKeyname = 'debitNotePurchaseReceivablePaymentData',
  validationKeyname = 'debitNotePurchaseReceivablePaymentValidation',
  fillAmountInputFull = true,
  defaultEmptyAmountInput = 0,
}) => {
  if (isEmpty(debitNoteList) || !Array.isArray(debitNoteList)) {
    return {
      [dataKeyname]: [],
      [validationKeyname]: [],
    };
  }

  const usagedID = arrHelp.generateArrWithFunc(debitNoteList.length, uuidv4);

  const debitNotePurchaseReceivableData = debitNoteList.map((currentDebitNote, index) => {
    currentDebitNote = objHelper.changeFormatValue(
      currentDebitNote,
      debitNotePurchaseReceivablePaymentCalculationNumberKeyNames,
      [(value) => Number(value) || ''],
    );

    const { amount_available } = currentDebitNote;

    const amountInput = fillAmountInputFull ? amount_available : defaultEmptyAmountInput;

    return {
      id: usagedID[index],
      ...plainDebitNotePurchaseReceivablePaymentWithoutID,
      ...currentDebitNote,
      amount_available: Number(amount_available) - Number(amountInput),
      amount_available_const: amount_available,
      amount_input: amountInput || '',
    };
  });

  const debitNotePurchaseReceivablePaymentValidation = usagedID.map((id) => {
    return {
      id,
      ...plainDebitNotePurchaseReceivablePaymentValidationWithoutID,
    };
  });

  return {
    [dataKeyname]: debitNotePurchaseReceivableData,
    [validationKeyname]: debitNotePurchaseReceivablePaymentValidation,
  };
};

/**
 *
 * @param { object } param0  object as function params
 *
 * @param   { object }  debitNoteDetails                                current debit note details
 * @param   { object }  currentPurchaseReceivablePaymentData            current data of purchase receivable payment
 * @param   { object }  currentPurchaseReceivablePaymentValidation      current validation of purchase receivable payment
 * @param   { object }  dataKeyname                                     return keyname of data
 * @param   { object }  validationKeyname                               return keyname of validation
 *
 * @returns
 */
export const generateDataNvalidationAddPurchaseReceivablePaymentFromDebitNoteDetails = ({
  debitNoteDetails = {},
  companyUserData,
  currentPurchaseReceivablePaymentData,
  currentPurchaseReceivablePaymentValidation,
  dataKeyname = 'purchaseReceivablePaymentData',
  validationKeyname = 'purchaseReceivablePaymentValidation',
}) => {
  if (isEmpty(debitNoteDetails)) return debitNoteDetails;

  debitNoteDetails = objHelper.changeFormatValue(debitNoteDetails, Object.keys(debitNoteDetails), [
    (value) => value || '',
  ]);

  const { supplier_id, supplier_name } = debitNoteDetails;

  const minDateInput = getAccountingPeriodCompareWithDocumentTransactionDate({
    companyUserData,
    documentData: debitNoteDetails,
  });

  currentPurchaseReceivablePaymentData = currentPurchaseReceivablePaymentData.map(
    (purchaseReceivablePaymentData) => {
      return {
        ...purchaseReceivablePaymentData,
        ...omit(cloneDeep(debitNoteDetails), ['transaction_date', 'transaction_no', 'description']),
        supplier_id_container: {
          contact_id: supplier_id,
          contact_name: supplier_name,
        },
        [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]: new Date(minDateInput),
      };
    },
  );

  return {
    [dataKeyname]: currentPurchaseReceivablePaymentData,
    [validationKeyname]: currentPurchaseReceivablePaymentValidation,
  };
};

/**
 *
 * @param { array } purchaseReceivablePaymentData           result input purchase receivable payment data
 * @param { array } debitNotePurchaseReceivablePaymentData  result user input debit note purchase receivable payment data
 * @returns
 */
export const generalizeDataAddPurchaseReceivablePayment = (
  purchaseReceivablePaymentData,
  debitNotePurchaseReceivablePaymentData,
  isWrappingKeynameData = false,
  correspondWrappingKeyname = {
    account_id: 'account_receivable',
    account_id_container: 'account_receivable_container',
  },
) => {
  if (
    !Array.isArray(purchaseReceivablePaymentData) ||
    !Array.isArray(debitNotePurchaseReceivablePaymentData)
  ) {
    return {
      product: debitNotePurchaseReceivablePaymentData,
      ...purchaseReceivablePaymentData,
    };
  }

  purchaseReceivablePaymentData = purchaseReceivablePaymentData.map((singlePurchaseReturn) => {
    if (isWrappingKeynameData) {
      singlePurchaseReturn = objHelper.changeKeyNameWithCorrespondKeyTarget({
        obj: singlePurchaseReturn,
        correspondObj: correspondWrappingKeyname,
      });
    }

    const { supplier_id_container, transaction_date, attachment } = singlePurchaseReturn;

    const uuidAttachment =
      Array.isArray(attachment) && attachment.length
        ? attachment[0][DEFAULT_KEY_NAME_FILE_PROPERTIES][DEFAULT_KEY_NAME_UUID_API_REF] || ''
        : '';

    let supplier_name = '';
    if (supplier_id_container) {
      const { contact_name, supplier_name: supplier_name_gen } = supplier_id_container;

      supplier_name = contact_name || supplier_name_gen;
    }

    return objHelper.filteringExistedValue(
      pick(
        {
          ...singlePurchaseReturn,
          supplier_name,
          transaction_date: transaction_date ? moment(transaction_date).toISOString(true) : '',
          [DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF]: uuidAttachment,
        },
        allowKeysDataPurchaseReceivablePayment,
      ),
    );
  });

  debitNotePurchaseReceivablePaymentData = debitNotePurchaseReceivablePaymentData.reduce(
    (resultDebitNote, singleProductPurchaseReturn) => {
      const { amount_input } = singleProductPurchaseReturn;
      if (!Number(amount_input)) return resultDebitNote;

      return resultDebitNote.concat(
        pick(
          {
            ...singleProductPurchaseReturn,
            amount: Number(amount_input),
          },
          allowKeysDataDebitNotePurchaseReceivablePayment,
        ),
      );
    },
    [],
  );

  return {
    debit_note: debitNotePurchaseReceivablePaymentData,
    ...purchaseReceivablePaymentData[0],
  };
};
