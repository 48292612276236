import { createAsyncThunk } from '@reduxjs/toolkit';

import tagServices from '../../services/API/tag/tag.service';
import { SLICE_NAME_TAG_LIST } from '../../utils/constants/store.constant';
import { DEFAULT_KEY_NAME_TAG_ID_API_REF } from '../../utils/default/object-keyname.default';
import { creatorListAction } from '../creator-action/creator-action.helper';

// handling tag list
export const getTagList = createAsyncThunk('tag/tag-list', async (params, thunkAPI) => {
  return creatorListAction(
    thunkAPI,
    params,
    tagServices.getTagList,
    '',
    SLICE_NAME_TAG_LIST,
    DEFAULT_KEY_NAME_TAG_ID_API_REF,
    'error.list-data.tag',
  );
});

const tagActions = {
  getTagList,
};

export default tagActions;
