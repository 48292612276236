import * as React from 'react';
function SvgPdf(props) {
  return (
    <svg width={26} height={32} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.388 0H1v24h24V6l-6.612-6z'
        fill='#F4F4F3'
      />
      <path
        d='M18.586 0H2.023C.907 0 0 .806 0 1.798v28.404C0 31.194.907 32 2.023 32h21.954C25.093 32 26 31.194 26 30.202V6.598L18.586 0zm6.385 30.202c0 .486-.447.883-.994.883H2.023c-.547 0-.994-.397-.994-.883V1.798c0-.486.447-.883.994-.883h16.563v5.677h6.385v23.61z'
        fill='#D92D20'
      />
      <path
        d='M21.436 10.008H4.564a.413.413 0 01-.296-.125.433.433 0 010-.606.413.413 0 01.296-.125h16.872c.11 0 .217.045.295.125a.433.433 0 010 .606.413.413 0 01-.295.125zM21.436 14.095H4.564a.413.413 0 01-.296-.125.433.433 0 010-.605.413.413 0 01.296-.126h16.872c.11 0 .217.046.295.126a.433.433 0 010 .605.413.413 0 01-.295.125zM21.436 18.176H4.564a.413.413 0 01-.296-.125.433.433 0 010-.605.413.413 0 01.296-.126h16.872c.11 0 .217.045.295.126a.433.433 0 010 .605.413.413 0 01-.295.125zM.979 23.168h24.114v8H.98v-8z'
        fill='#D92D20'
      />
      <path
        d='M13.465 27.712h-.703v1.513c0 .216-.037.38-.11.492-.075.111-.165.164-.276.164-.116 0-.206-.053-.28-.164-.069-.112-.106-.276-.106-.485v-4.12c0-.24.037-.41.117-.507.079-.097.2-.157.37-.157h.983c.29 0 .513.03.672.097.153.06.29.164.401.305.111.142.201.313.26.515.058.2.09.432.09.685 0 .544-.117.954-.355 1.237-.238.283-.592.425-1.063.425zm-.185-2.444h-.518V26.9h.518c.18 0 .333-.03.455-.082a.557.557 0 00.275-.261c.063-.12.095-.283.095-.477 0-.238-.047-.425-.148-.574-.106-.164-.333-.238-.677-.238zm2.718-.813h1c.258 0 .48.038.665.105.186.067.355.194.508.38.397.477.592 1.2.592 2.168 0 .32-.02.611-.058.88-.037.26-.1.499-.185.715-.085.209-.19.402-.317.567-.1.126-.212.23-.333.313a1.247 1.247 0 01-.386.163c-.138.03-.296.045-.47.045h-1a.495.495 0 01-.317-.09.427.427 0 01-.138-.253 2.21 2.21 0 01-.032-.417v-3.92c0-.23.037-.402.111-.506.075-.097.196-.15.36-.15zm.296.85v3.622h.582c.127 0 .227-.008.301-.015a.654.654 0 00.222-.075.621.621 0 00.201-.164c.254-.305.386-.834.386-1.58 0-.529-.058-.924-.17-1.184-.11-.261-.253-.433-.417-.5a1.448 1.448 0 00-.597-.104h-.508zm5.404-.022h-1.512v1.363h1.263c.116 0 .206.038.264.112a.466.466 0 01.085.298.467.467 0 01-.09.298c-.058.075-.148.112-.259.112h-1.264v1.759c0 .223-.037.387-.105.5-.074.103-.164.163-.275.163a.312.312 0 01-.275-.164c-.074-.112-.106-.276-.106-.492v-4.12c0-.157.016-.284.048-.38a.398.398 0 01.153-.217.488.488 0 01.27-.067h1.808c.122 0 .211.037.275.112.063.074.09.179.09.298a.49.49 0 01-.09.305.347.347 0 01-.28.12zM9.168 27.714a.272.272 0 00-.272.272v1.088a.272.272 0 01-.272.272H4.816a.272.272 0 01-.272-.272v-1.088a.272.272 0 10-.544 0v1.088a.816.816 0 00.816.816h3.808a.816.816 0 00.816-.816v-1.088a.272.272 0 00-.272-.272zm-2.641.465a.274.274 0 00.09.057.255.255 0 00.206 0 .274.274 0 00.09-.057l1.088-1.088a.273.273 0 00-.386-.386l-.623.625v-2.608a.272.272 0 10-.544 0v2.608l-.623-.625a.275.275 0 00-.298-.06.275.275 0 00-.168.253.273.273 0 00.08.193l1.088 1.088z'
        fill='#F4F4F3'
      />
    </svg>
  );
}
export default SvgPdf;
