import { createAsyncThunk } from '@reduxjs/toolkit';

import { stockAdjustmentServices } from '../../services/API/stock-adjustment/stock-adjustment.service';
import {
  REACT_APP_DATA_STOCK_ADJUSTMENT_DETAILS_TEMPLATE_URL,
  REACT_APP_DATA_STOCK_ADJUSTMENT_URL,
  REACT_APP_PRODUCT_URL,
  REACT_APP_STOCK_URL,
} from '../../utils/configs/url.config';
import { STOCK_ADJUSTMENT_ASYNC_THUNK_TYPE } from '../../utils/constants/action-type.constant';
import { LOCAL_STORAGE_STOCK_ADJUSTMENT_LIST } from '../../utils/constants/storage.constant';
import {
  SLICE_NAME_STOCK_ADJUSTMENT_DETAILS,
  SLICE_NAME_STOCK_ADJUSTMENT_LIST,
  STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_FAILED,
  STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_PENDING,
  STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_SUCCESS,
} from '../../utils/constants/store.constant';
import { DEFAULT_KEY_NAME_STOCK_ADJUSTMENT_ID_API_REF } from '../../utils/default/object-keyname.default';
import {
  DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
  DEFAULT_STOCK_ADJUSTMENT_LIST_SEARCH_FE_PARAMS,
} from '../../utils/default/params.default';
import { DEFAULT_STATUS_PAGE_DETAIL_PRODUCT_STOCK_ADJUSTMENT } from '../../utils/default/status-page.default';
import objHelper from '../../utils/helpers/object.helper';
import strHelp from '../../utils/helpers/string.helpers';
import {
  creatorAddActionWithPreviewButton,
  creatorAddActionWithPreviewButtonGenerateLink,
  creatorDeleteAction,
  creatorDetailAction,
  creatorListActionDynamicSliceNameNproperties,
  creatorListActionWithDynamicSliceName,
  creatorListRefreshActionWithDynamicStore,
  creatorUpdateAction,
} from '../creator-action/creator-action.helper';

/**
 * stock adjustment actions
 *
 */

// action getting stock adjustment list with dynamic store data
export const getStockAdjustmentListWithDynamicStore = createAsyncThunk(
  'stock-adjustment/list-dynamic-store',
  async (
    {
      sliceName = SLICE_NAME_STOCK_ADJUSTMENT_LIST,
      localStorageName = LOCAL_STORAGE_STOCK_ADJUSTMENT_LIST,
      ...paramsGetStockAdjustmentList
    },
    thunkAPI,
  ) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      paramsGetStockAdjustmentList,
      stockAdjustmentServices.getStockAdjustmentList,
      localStorageName,
      sliceName,
      'stock_adjustment_id',
      'error.list-data.stock-adjustment',
    );
  },
);

// action getting refesh stock adjustment with dynamic storing data on slice name
export const getStockAdjustmentListRefreshWithDynamicStore = createAsyncThunk(
  'stock-adjustment/list-refresh-dynamic-store',
  async (
    {
      sliceName = SLICE_NAME_STOCK_ADJUSTMENT_LIST,
      localStorageName = LOCAL_STORAGE_STOCK_ADJUSTMENT_LIST,
      discardKeysSearch = [],
    },
    thunkAPI,
  ) => {
    return await creatorListRefreshActionWithDynamicStore(
      thunkAPI,
      stockAdjustmentServices.getStockAdjustmentList,
      localStorageName,
      sliceName,
      discardKeysSearch,
      DEFAULT_STOCK_ADJUSTMENT_LIST_SEARCH_FE_PARAMS,
      'error.list-data.stock-adjustment',
    );
  },
);

export const getStockAdjustmentList = createAsyncThunk(
  STOCK_ADJUSTMENT_ASYNC_THUNK_TYPE.list,
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_FAILED,
      slicePayloadKeyname = SLICE_NAME_STOCK_ADJUSTMENT_LIST,
      localStorageName = LOCAL_STORAGE_STOCK_ADJUSTMENT_LIST,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...params
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params: params,
      apiService: stockAdjustmentServices.getStockAdjustmentList,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: DEFAULT_KEY_NAME_STOCK_ADJUSTMENT_ID_API_REF,
      slicePayloadKeyname,
      errorMessage: 'error.list-data.stock-adjustment',
    });
  },
);

// action get details stock adjustment
export const getStockAdjustmentDetails = createAsyncThunk(
  'stock-adjustment/details',
  async (paramsGetStockAdjustmentDetails, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      paramsGetStockAdjustmentDetails,
      stockAdjustmentServices.getStockAdjustmentDetails,
      '',
      SLICE_NAME_STOCK_ADJUSTMENT_DETAILS,
      'error.details.stock-adjustment',
    );
  },
);

// action add stock adjustment with preview button
export const addStockAdjustment = createAsyncThunk(
  'stock-adjustment/add',
  async (bodyAddStockAdjustment, thunkAPI) => {
    return await creatorAddActionWithPreviewButton(
      thunkAPI,
      bodyAddStockAdjustment,
      stockAdjustmentServices.addStockAdjustment,
      'success.add.stock-adjustment',
      'error.add.stock-adjustment',
      {},
      {},
      'stock_adjustment_id',
      REACT_APP_PRODUCT_URL,
      { statusPage: DEFAULT_STATUS_PAGE_DETAIL_PRODUCT_STOCK_ADJUSTMENT },
    );
  },
);

// action add stock adjustment with preview button version 2
export const addStockAdjustmentV2 = createAsyncThunk(
  'stock-adjustment/add-version-2',
  async (bodyAddStockAdjustment, thunkAPI) => {
    return await creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: bodyAddStockAdjustment,
      serviceFunc: stockAdjustmentServices.addStockAdjustment,
      successMessage: 'success.add.stock-adjustment',
      errorMessage: 'error.add.stock-adjustment',
      baseContentUrl: REACT_APP_DATA_STOCK_ADJUSTMENT_URL,
      generateButtonLink: (responseResult) => {
        const templateLinkDetails = {
          [DEFAULT_KEY_NAME_STOCK_ADJUSTMENT_ID_API_REF]: objHelper.findAllValuesByKey(
            responseResult,
            DEFAULT_KEY_NAME_STOCK_ADJUSTMENT_ID_API_REF,
          )[0],
        };

        return strHelp.templateString(
          REACT_APP_DATA_STOCK_ADJUSTMENT_DETAILS_TEMPLATE_URL,
          templateLinkDetails,
        );
      },
      isShowPreviewButton: !!bodyAddStockAdjustment.isShowPreviewButton,
    });
  },
);

// action update stock adjustment
export const updateStockAdjustment = createAsyncThunk(
  'stock-adjustment/update',
  async (bodyUpdateStockAdjustment, thunkAPI) => {
    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdateStockAdjustment,
      stockAdjustmentServices.updateStockAdjustment,
      'success.update.stock-adjustment',
      'error.update.stock-adjustment',
    );
  },
);

// action delete stock adjustment
export const deleteStockAdjustment = createAsyncThunk(
  'stock-adjustment/delete',
  async (paramsDeleteStockAdjustment, thunkAPI) => {
    return await creatorDeleteAction(
      thunkAPI,
      paramsDeleteStockAdjustment,
      stockAdjustmentServices.deleteStockAdjustment,
      'success.delete.stock-adjustment',
      'error.delete.stock-adjustment',
    );
  },
);

// action add stock adjustment with preview button for basic app
export const addStockAdjustmentBasicApp = createAsyncThunk(
  'stock-adjustment/add',
  async (bodyAddStockAdjustment, thunkAPI) => {
    return await creatorAddActionWithPreviewButton(
      thunkAPI,
      bodyAddStockAdjustment,
      stockAdjustmentServices.addStockAdjustment,
      'success.add.stock-adjustment',
      'error.add.stock-adjustment',
      {},
      {},
      'stock_adjustment_id',
      REACT_APP_STOCK_URL,
      { statusPage: DEFAULT_STATUS_PAGE_DETAIL_PRODUCT_STOCK_ADJUSTMENT },
    );
  },
);

const stockAdjustmentActions = {
  getStockAdjustmentDetails,
  getStockAdjustmentListWithDynamicStore,
  getStockAdjustmentListRefreshWithDynamicStore,
  addStockAdjustment,
  addStockAdjustmentV2,
  updateStockAdjustment,
  deleteStockAdjustment,
};

export default stockAdjustmentActions;
