import {
  REACT_APP_API_URL_SETTINGS_WAREHOUSE,
  REACT_APP_API_URL_SETTINGS_WAREHOUSE_PRODUCT,
} from '../../../utils/configs/api.config';
import {
  DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION_SECONDARY,
  DEFAULT_SETTINGS_WAREHOUSE_LIST_PARAMS,
  DEFAULT_SETTINGS_WAREHOUSE_PRODUCT_LIST_PARAMS,
} from '../../../utils/default/params.default';
import strHelp from '../../../utils/helpers/string.helpers';
import {
  creatorAddService,
  creatorDetailService,
  creatorListService,
  creatorUpdateService,
} from '../creator-services/creator-services.helper';

// service list warehouse
const getWarehouseList = async (paramsGetWarehouseList) => {
  return await creatorListService(
    REACT_APP_API_URL_SETTINGS_WAREHOUSE,
    paramsGetWarehouseList,
    DEFAULT_SETTINGS_WAREHOUSE_LIST_PARAMS,
  );
};

// service get product on warehouse
const getWarehouseProductList = async (paramsGetWarehouseProductList) => {
  const urlWarehouseProduct = strHelp.templateString(
    REACT_APP_API_URL_SETTINGS_WAREHOUSE_PRODUCT,
    paramsGetWarehouseProductList,
  );

  return await creatorListService(
    urlWarehouseProduct,
    paramsGetWarehouseProductList,
    DEFAULT_SETTINGS_WAREHOUSE_PRODUCT_LIST_PARAMS,
    DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION_SECONDARY,
    true,
  );
};

// service warehouse details
const getDetailWarehouse = async (warehouseID) => {
  return await creatorDetailService(REACT_APP_API_URL_SETTINGS_WAREHOUSE, warehouseID);
};

// service add warehouse
const addWarehouse = async (bodyWarehouse) => {
  return await creatorAddService(REACT_APP_API_URL_SETTINGS_WAREHOUSE, bodyWarehouse);
};

// service update warehouse
const updateWarehouse = async (bodyUpdateWarehouse) => {
  const { warehouse_id } = bodyUpdateWarehouse;

  return await creatorUpdateService(
    warehouse_id,
    REACT_APP_API_URL_SETTINGS_WAREHOUSE,
    bodyUpdateWarehouse,
  );
};

const warehouseServices = {
  getWarehouseList,
  getWarehouseProductList,
  getDetailWarehouse,
  addWarehouse,
  updateWarehouse,
};

export default warehouseServices;
