import {
  FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN,
  FORMAT_DATE_COLUMN,
} from '../../../components/table-row/TableRowMultipleTableDataPureMarkup/TableRowMultipleTableDataPureMarkup';
import { FORMAT_CURRENCY_AUTO_COMMA_COLUMN } from '../../../components/table-row/TableRowPureMarkup/TableRowPureMarkup';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';

import { primaryTableDataCalculationNumber } from './additional-styles';

// column quotation request detail that handling table head with translation
// @param: p, function for translation
// @param: menuName, name menu that provide translation
export const columnsTableHeaderQuotationRequestDetail = (t) => {
  return [
    {
      name: 'product_code',
      label: t('dialog.detail-quotation-request.table.header-column.product-code'),
      width: 150,
    },
    {
      name: 'product_name',
      label: t('dialog.detail-quotation-request.table.header-column.product-name'),
      width: 150,
    },
    {
      name: 'description',
      label: t('dialog.detail-quotation-request.table.header-column.description'),
      width: 250,
      align: 'left',
    },
    {
      name: 'quantity',
      label: t('dialog.detail-quotation-request.table.header-column.quantity'),
      align: 'right',
      width: 150,
    },
    {
      name: 'product_unit',
      label: t('dialog.detail-quotation-request.table.header-column.unit'),
      align: 'right',
      width: 150,
    },
    {
      name: 'price',
      label: t('dialog.detail-quotation-request.table.header-column.at-price'),
      align: 'right',
      width: 150,
    },
    {
      name: 'price_amount',
      label: t('dialog.detail-quotation-request.table.header-column.amount'),
      align: 'right',
      width: 150,
    },
  ];
};

// enhancement on table header purchase quotation request details
export const columnsTableHeaderPurchaseQuotationRequestDetailsV2 = (t) => {
  return [
    {
      multipleNames: [
        {
          name: 'product_name',
        },
        {
          name: 'description',
        },
      ],
      label: t('table.header-column.product-name'),
      isUseMultipleNames: true,
      width: 250,
    },
    {
      name: 'quantity',
      label: t('table.header-column.quantity'),
      align: 'left',
      width: 50,
    },
    {
      name: !hasImplementedUnitProductUnit ? 'product_unit' : 'unit_destination',
      label: t('table.header-column.unit'),
      align: 'left',
      width: 25,
    },
    {
      name: 'price',
      label: t('table.header-column.at-price'),
      align: 'right',
      width: 75,
    },
    {
      name: 'amount',
      label: t('table.header-column.amount'),
      align: 'right',
      width: 100,
    },
  ];
};

// additional props on table head main
export const additionalPropsQuotationRequestDetail = {
  sx: {
    backgroundColor: '#EFF8FF!important',
  },
};

// colum that representation for calculation
export const calculationColumns = ['price_amount'];

// on representation total row
export const totalColumnUsed = ['price', ...calculationColumns];

// columns key value table
export const columnsTableKeyValuePrintPurchaseQuotationRequest = [
  {
    headerTranslated: 'No.',
    valueKeyName: 'transaction_no',
  },
  {
    header: 'table.header-column.date',
    valueKeyName: 'transaction_date',
    formatValue: FORMAT_DATE_COLUMN,
  },
  {
    header: 'table.header-column.supplier',
    valueKeyName: 'supplier_name',
  },
  {
    header: 'table.header-column.address',
    valueKeyName: 'supplier_address',
  },
];

export const columnsTableProductPurchaseQuotationRequestDetails = (t) => {
  return [
    {
      isAutoIncrementColumn: true,
      label: t('No.'),
      align: 'center',
      style: {
        maxWidth: 20,
      },
    },
    {
      name: 'product_name',
      label: t('table.header-column.product-name'),
      style: {
        minWidth: 'auto',
        width: 593,
        maxWidth: 600,
      },
    },
    {
      name: 'quantity',
      label: t('table.header-column.qty'),
      style: {
        minWidth: 'auto',
        width: 40,
        maxWidth: 45,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'unit_destination',
      label: t('table.header-column.unit'),
      style: {
        minWidth: 'auto',
        width: 40,
        maxWidth: 45,
      },
    },
    {
      name: 'price',
      label: t('table.header-column.at-price'),
      style: {
        minWidth: 'auto',
        width: 192,
        maxWidth: 300,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'amount',
      label: t('table.header-column.amount'),
      style: {
        minWidth: 'auto',
        width: 192,
        maxWidth: 300,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
  ];
};

export const columnsTableFooterProductPurchaseQuotationRequestDetails = (t) => {
  return [
    [
      {
        isEmptyTableData: true,
        colSpan: 4,
      },
      {
        isLabel: true,
        label: t('typography.discount'),
      },
      {
        isValue: true,
        valueKeyName: 'discount_percentage',
        tableDataStyle: primaryTableDataCalculationNumber,
      },
    ],
    [
      {
        isEmptyTableData: true,
        colSpan: 4,
      },
      {
        isLabel: true,
        label: t('typography.total'),
      },
      {
        isValue: true,
        valueKeyName: 'amount',
        formatValue: FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN,
        tableDataStyle: primaryTableDataCalculationNumber,
      },
    ],
  ];
};

export const columnsTableFooterPurchaseQuotationRequestDetails = (t) => {
  return [
    {
      name: 'description',
      label: t('table.header-column.memo-colon'),
      style: {
        minWidth: 100,
        width: 360,
        maxWidth: 365,
        height: 56,
        verticalAlign: 'top',
      },
      isUseTableHeaderStyle: false,
    },
  ];
};
