import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import moment from 'moment';

import salesServices from '../../services/API/sales/sales.service';
import {
  REACT_APP_SALES_INVOICE_URL,
  REACT_APP_SALES_PAYABLE_PAYMENT_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_PAYABLE_PAYMENT_URL,
  REACT_APP_SALES_RETURN_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_RETURN_URL,
  REACT_APP_SALES_SALES_DELIVERY_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_SALES_INVOICE_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_SALES_ORDER_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_SALES_PAYMENT_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_SALES_QUOTATION_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_URL,
} from '../../utils/configs/url.config';
import {
  DEFAULT_IMPORT_FILE_ACTION_TYPE,
  DEFAULT_IMPORT_FILE_PROGRESS_ACTION_TYPE,
  SALES_INVOICE_ASYNC_THUNK_TYPE,
} from '../../utils/constants/action-type.constant';
import {
  LOCAL_STORAGE_SALES_CREDIT_NOTE_DETAILS,
  LOCAL_STORAGE_SALES_CREDIT_NOTE_LIST,
  LOCAL_STORAGE_SALES_DELIVERY,
  LOCAL_STORAGE_SALES_DELIVERY_AND_ORDER,
  LOCAL_STORAGE_SALES_INVOICE,
  LOCAL_STORAGE_SALES_INVOICE_DETAIL,
  LOCAL_STORAGE_SALES_ORDER,
  LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_DETAILS,
  LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_LIST,
  LOCAL_STORAGE_SALES_PAYMENT,
  LOCAL_STORAGE_SALES_QUOTATION,
  LOCAL_STORAGE_SALES_QUOTATION_DETAIL,
  LOCAL_STORAGE_SALES_RETURN_DETAILS,
  LOCAL_STORAGE_SALES_RETURN_LIST,
} from '../../utils/constants/storage.constant';
import {
  REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_FAILED,
  REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_LOADING,
  REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_LIST_SALES_RETURN_FAILED,
  REQUEST_STATUS_LIST_SALES_RETURN_LOADING,
  REQUEST_STATUS_LIST_SALES_RETURN_SUCCESS,
  SLICE_NAME_IMPORT_SALES_INVOICE,
  SLICE_NAME_IMPORT_SALES_INVOICE_PROGRESS,
  SLICE_NAME_SALES_CREDIT_NOTE_DETAILS,
  SLICE_NAME_SALES_CREDIT_NOTE_HISTORY,
  SLICE_NAME_SALES_CREDIT_NOTE_LIST,
  SLICE_NAME_SALES_DELIVERY_DATA,
  SLICE_NAME_SALES_DELIVERY_DETAILS,
  SLICE_NAME_SALES_DELIVERY_HISTORY_DETAILS,
  SLICE_NAME_SALES_INVOICE_DATA,
  SLICE_NAME_SALES_ORDER_DATA,
  SLICE_NAME_SALES_ORDER_DETAILS,
  SLICE_NAME_SALES_ORDER_HISTORY_DETAILS,
  SLICE_NAME_SALES_PAYABLE_PAYMENT_DETAILS,
  SLICE_NAME_SALES_PAYABLE_PAYMENT_HISTORY,
  SLICE_NAME_SALES_PAYABLE_PAYMENT_LIST,
  SLICE_NAME_SALES_PAYMENT_DATA,
  SLICE_NAME_SALES_QUOTATION_DATA,
  SLICE_NAME_SALES_RETURN_DETAILS,
  SLICE_NAME_SALES_RETURN_HISTORY,
  SLICE_NAME_SALES_RETURN_LIST,
  SLICE_NAME_TEMP_ADD_SALES_INVOICE,
  SLICE_NAME_TEMP_UPDATE_SALES_INVOICE,
  STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_FAILED,
  STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_PENDING,
  STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_SUCCESS,
  STATUS_REQUEST_LIST_SALES_DELIVERY_FAILED,
  STATUS_REQUEST_LIST_SALES_DELIVERY_PENDING,
  STATUS_REQUEST_LIST_SALES_DELIVERY_SUCCESS,
  STATUS_REQUEST_LIST_SALES_INVOICE_FAILED,
  STATUS_REQUEST_LIST_SALES_INVOICE_PENDING,
  STATUS_REQUEST_LIST_SALES_INVOICE_SUCCESS,
  STATUS_REQUEST_LIST_SALES_ORDER_FAILED,
  STATUS_REQUEST_LIST_SALES_ORDER_PENDING,
  STATUS_REQUEST_LIST_SALES_ORDER_SUCCESS,
  STATUS_REQUEST_LIST_SALES_PAYMENT_FAILED,
  STATUS_REQUEST_LIST_SALES_PAYMENT_PENDING,
  STATUS_REQUEST_LIST_SALES_PAYMENT_SUCCESS,
  STATUS_REQUEST_LIST_SALES_QUOTATION_FAILED,
  STATUS_REQUEST_LIST_SALES_QUOTATION_PENDING,
  STATUS_REQUEST_LIST_SALES_QUOTATION_SUCCESS,
} from '../../utils/constants/store.constant';
import {
  DEFAULT_KEY_NAME_CREDIT_NOTE_ID_API_REF,
  DEFAULT_KEY_NAME_PAYABLE_PAYMENT_ID_API_REF,
  DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF,
  DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF,
  DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF,
  DEFAULT_KEY_NAME_SALES_PAYMENT_ID_API_REF,
  DEFAULT_KEY_NAME_SALES_QUOTATION_ID_API_REF,
  DEFAULT_KEY_NAME_SALES_RETURN_ID_API_REF,
} from '../../utils/default/object-keyname.default';
import {
  DEFAULT_ACTION_SECONDARY_PARAMS,
  DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
  DEFAULT_SALES_DELIVERY_SEARCH_FE_PARAMS,
  DEFAULT_SALES_INVOICE_SEARCH_FE_PARAMS,
  DEFAULT_SALES_ORDER_SEARCH_FE_PARAMS,
  DEFAULT_SALES_PAYMENT_SEARCH_FE_PARAMS,
  DEFAULT_SALES_QUOTATION_SEARCH_FE_PARAMS,
  DEFAULT_SEARCH_PARAMS_SALES_CREDIT_NOTE_LIST,
  DEFAULT_SEARCH_PARAMS_SALES_PAYABLE_PAYMENT_LIST,
  DEFAULT_SEARCH_PARAMS_SALES_RETURN_LIST,
} from '../../utils/default/params.default';
import { DEFAULT_STATUS_PAGE_DETAIL_SALES_INVOICE } from '../../utils/default/status-page.default';
import objHelper from '../../utils/helpers/object.helper';
import strHelp from '../../utils/helpers/string.helpers';
import {
  creatorAddActionWithPreviewButton,
  creatorAddActionWithPreviewButtonGenerateLink,
  creatorAddActionWithStoreDataOnSlice,
  creatorDeleteAction,
  creatorDetailAction,
  creatorListAction,
  creatorListActionDynamicSliceNameNproperties,
  creatorListActionWithDynamicSliceName,
  creatorListRefreshAction,
  creatorUpdateAction,
} from '../creator-action/creator-action.helper';

/**
 *
 * sales quotation actions
 *
 */

export const getListSalesQuotation = createAsyncThunk(
  'sales/sales-quotation-list',
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_SALES_QUOTATION_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_SALES_QUOTATION_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_SALES_QUOTATION_FAILED,
      slicePayloadKeyname = SLICE_NAME_SALES_QUOTATION_DATA,
      localStorageName = LOCAL_STORAGE_SALES_QUOTATION,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...paramsGetListSalesQuotation
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params: paramsGetListSalesQuotation,
      apiService: salesServices.getListSalesQuotation,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: 'sales_quotation_id',
      slicePayloadKeyname,
    });
  },
);

export const getListSalesQuotationRefresh = createAsyncThunk(
  'sales/sales-quotation-list-refresh',
  async (discardKeySearch = [], thunkAPI) => {
    return creatorListRefreshAction(
      thunkAPI,
      salesServices.getListSalesQuotation,
      LOCAL_STORAGE_SALES_QUOTATION,
      'salesQuotationData',
      discardKeySearch,
      DEFAULT_SALES_QUOTATION_SEARCH_FE_PARAMS,
      'error.list-data.sales-quotation',
    );
  },
);

export const addSalesQuotation = createAsyncThunk(
  'sales/sales-quotation-add',
  async (bodyAddSalesQuotation, thunkAPI) => {
    return creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: bodyAddSalesQuotation,
      serviceFunc: salesServices.addSalesQuotation,
      successMessage: 'success.add.sales-quotation',
      errorMessage: 'error.add.sales-quotation',
      baseContentUrl: REACT_APP_SALES_URL,
      generateButtonLink: (responseResult) => {
        const templateLinkDetails = {
          [DEFAULT_KEY_NAME_SALES_QUOTATION_ID_API_REF]: objHelper.findAllValuesByKey(
            responseResult,
            DEFAULT_KEY_NAME_SALES_QUOTATION_ID_API_REF,
          ),
        };

        return strHelp.templateString(
          REACT_APP_SALES_SALES_QUOTATION_DETAILS_TEMPLATE_URL,
          templateLinkDetails,
        );
      },
    });
  },
);

export const getDetailSalesQuotation = createAsyncThunk(
  'sales/sales-quotation-detail',
  async (salesQuotationID, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      salesQuotationID,
      salesServices.getDetailSalesQuotation,
      LOCAL_STORAGE_SALES_QUOTATION_DETAIL,
      'salesQuotationDetail',
      'error.detail.sales-quotation',
    );
  },
);

export const getHistorySalesQuotation = createAsyncThunk(
  'sales/sales-quotation-history',
  async (paramsGetSalesQuotationHistory, thunkAPI) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      paramsGetSalesQuotationHistory,
      salesServices.getHistorySalesQuotation,
      '',
      'salesQuotationHistory',
      'sales_quotation_id',
      'error.detail.sales-quotation-history',
    );
  },
);

export const updateSalesQuotation = createAsyncThunk(
  'sales/sales-quotation-update',
  async (bodyUpdateSalesQuotation, thunkAPI) => {
    const { transaction_no } = bodyUpdateSalesQuotation;

    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdateSalesQuotation,
      salesServices.updateSalesQuotation,
      'success.update.sales-quotation',
      'error.update.sales-quotation',
      {},
      { transaction_no },
    );
  },
);

export const deleteSalesQuotation = createAsyncThunk(
  'sales/sales-quotation-delete',
  async (paramsDeleteSalesQuotation, thunkAPI) => {
    const { transaction_no } = paramsDeleteSalesQuotation;

    return creatorDeleteAction(
      thunkAPI,
      paramsDeleteSalesQuotation,
      salesServices.deleteSalesQuotation,
      'success.delete.sales-quotation',
      'error.delete.sales-quotation',
      {},
      { transaction_no },
    );
  },
);

/**
 *
 * sales order actions
 *
 */

export const getListSalesOrder = createAsyncThunk(
  'sales/sales-order-list',
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_SALES_ORDER_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_SALES_ORDER_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_SALES_ORDER_FAILED,
      slicePayloadKeyname = SLICE_NAME_SALES_ORDER_DATA,
      localStorageName = LOCAL_STORAGE_SALES_ORDER,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...paramsGetListSalesOrder
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params: paramsGetListSalesOrder,
      apiService: salesServices.getListSalesOrder,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: 'sales_order_id',
      slicePayloadKeyname,
      errorMessage: 'error.list-data.sales-order',
    });
  },
);

export const getListSalesOrderRefresh = createAsyncThunk(
  'sales/sales-order-list-refresh',
  async (discardKeySearch = [], thunkAPI) => {
    return await creatorListRefreshAction(
      thunkAPI,
      salesServices.getListSalesOrder,
      LOCAL_STORAGE_SALES_ORDER,
      SLICE_NAME_SALES_ORDER_DATA,
      discardKeySearch,
      DEFAULT_SALES_ORDER_SEARCH_FE_PARAMS,
      'error.list-data.sales-quotation',
    );
  },
);

export const getDetailSalesOrder = createAsyncThunk(
  'sales/sales-order-details',
  async (paramsGetDetailSalesOrder, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      paramsGetDetailSalesOrder,
      salesServices.getDetailSalesOrder,
      '',
      SLICE_NAME_SALES_ORDER_DETAILS,
      'error.detail.sales-order',
    );
  },
);

// action sales order history details
export const getSalesOrderHistoryDetails = createAsyncThunk(
  'sales/sales-order-history-details',
  async (paramsGetSalesOrderHistoryDetails, thunkAPI) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      paramsGetSalesOrderHistoryDetails,
      salesServices.getSalesOrderHistoryDetails,
      '',
      SLICE_NAME_SALES_ORDER_HISTORY_DETAILS,
      'sales_order_id',
      'error.detail.sales-order-history',
    );
  },
);

// action adding sales order
export const addSalesOrder = createAsyncThunk(
  'sales/sales-order-add',
  async (bodyAddSalesOrder, thunkAPI) => {
    return await creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: bodyAddSalesOrder,
      serviceFunc: salesServices.addSalesOrder,
      successMessage: 'success.add.sales-order',
      errorMessage: 'error.add.sales-order',
      baseContentUrl: REACT_APP_SALES_URL,
      generateButtonLink: (responseResult) => {
        const templateLinkDetails = {
          [DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF]: objHelper.findAllValuesByKey(
            responseResult,
            DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF,
          ),
        };

        return strHelp.templateString(
          REACT_APP_SALES_SALES_ORDER_DETAILS_TEMPLATE_URL,
          templateLinkDetails,
        );
      },
    });
  },
);

// action update sales order
export const updateSalesOrder = createAsyncThunk(
  'sales/sales-order-update',
  async (bodyUpdateSalesOrder, thunkAPI) => {
    const { reference_number } = bodyUpdateSalesOrder;

    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdateSalesOrder,
      salesServices.updateSalesOrder,
      'success.update.sales-order',
      'error.update.sales-order',
      {},
      { reference_number },
    );
  },
);

// action delete sales order
export const deleteSalesOrder = createAsyncThunk(
  'sales/sales-order-delete',
  async (paramsDeleteSalesOrder, thunkAPI) => {
    const { sales_order_no } = paramsDeleteSalesOrder;

    return await creatorDeleteAction(
      thunkAPI,
      paramsDeleteSalesOrder,
      salesServices.deleteSalesOrder,
      'success.delete.sales-order',
      'error.delete.sales-order',
      {},
      { sales_order_no },
    );
  },
);

/**
 *
 * sales delivery actions
 *
 */

export const getListSalesDelivery = createAsyncThunk(
  'sales/sales-delivery-list',
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_SALES_DELIVERY_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_SALES_DELIVERY_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_SALES_DELIVERY_FAILED,
      slicePayloadKeyname = SLICE_NAME_SALES_DELIVERY_DATA,
      localStorageName = LOCAL_STORAGE_SALES_DELIVERY,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...paramsGetListSalesDelivery
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params: paramsGetListSalesDelivery,
      apiService: salesServices.getListSalesDelivery,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: 'sales_delivery_id',
      slicePayloadKeyname,
      errorMessage: 'error.list-data.sales-delivery',
    });
  },
);

export const getListSalesDeliveryRefresh = createAsyncThunk(
  'sales/sales-delivery-list-refresh',
  async (discardKeySearch = [], thunkAPI) => {
    return await creatorListRefreshAction(
      thunkAPI,
      salesServices.getListSalesDelivery,
      LOCAL_STORAGE_SALES_DELIVERY,
      SLICE_NAME_SALES_DELIVERY_DATA,
      discardKeySearch,
      DEFAULT_SALES_DELIVERY_SEARCH_FE_PARAMS,
      'error.list-data.sales-delivery',
    );
  },
);

export const getSalesDeliveryDetails = createAsyncThunk(
  'sales/sales-delivery-details',
  async (paramsGetSalesDeliveryDetail, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      paramsGetSalesDeliveryDetail,
      salesServices.getSalesDeliveryDetails,
      '',
      SLICE_NAME_SALES_DELIVERY_DETAILS,
      'error.detail.sales-delivery',
    );
  },
);

export const getSalesDeliveryHistoryDetails = createAsyncThunk(
  'sales/sales-delivery-history-details',
  async (paramsGetSalesDeliveryHistoryDetail, thunkAPI) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      paramsGetSalesDeliveryHistoryDetail,
      salesServices.getSalesDeliveryHistoryDetails,
      '',
      SLICE_NAME_SALES_DELIVERY_HISTORY_DETAILS,
      'sales_quotation_id',
      'error.detail.sales-delivery-history',
    );
  },
);

export const addSalesDelivery = createAsyncThunk(
  'sales/sales-delivery-add',
  async (bodyAddSalesDelivery, thunkAPI) => {
    return await creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: bodyAddSalesDelivery,
      serviceFunc: salesServices.addSalesDelivery,
      successMessage: 'success.add.sales-delivery',
      errorMessage: 'error.add.sales-delivery',
      baseContentUrl: REACT_APP_SALES_URL,
      generateButtonLink: (responseResult) => {
        return strHelp.templateString(REACT_APP_SALES_SALES_DELIVERY_DETAILS_TEMPLATE_URL, {
          [DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF]: objHelper.findAllValuesByKey(
            responseResult,
            DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF,
          )[0],
        });
      },
    });
  },
);

export const updateSalesDelivery = createAsyncThunk(
  'sales/sales-delivery-update',
  async (bodyUpdateSalesDelivery, thunkAPI) => {
    const { reference_number } = bodyUpdateSalesDelivery;

    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdateSalesDelivery,
      salesServices.updateSalesDelivery,
      'success.update.sales-delivery',
      'error.update.sales-delivery',
      {},
      { reference_number },
    );
  },
);

export const deleteSalesDelivery = createAsyncThunk(
  'sales/sales-delivery-delete',
  async (paramsDeleteSalesDelivery, thunkAPI) => {
    const { sales_delivery_no } = paramsDeleteSalesDelivery;

    return await creatorDeleteAction(
      thunkAPI,
      paramsDeleteSalesDelivery,
      salesServices.deleteSalesDelivery,
      'success.delete.sales-delivery',
      'error.delete.sales-delivery',
      {},
      { sales_delivery_no },
    );
  },
);

export const getListSalesDeliveryAndOrder = createAsyncThunk(
  'sales/sales-delivery-and-order-list',
  async (paramsGetListSalesDeliveryAndOrder, thunkAPI) => {
    return creatorListAction(
      thunkAPI,
      paramsGetListSalesDeliveryAndOrder,
      salesServices.getListSalesDeliveryAndOrder,
      LOCAL_STORAGE_SALES_DELIVERY_AND_ORDER,
      'salesDeliveryAndOrderData',
      '',
      'error.list-data.sales-delivery-and-order',
    );
  },
);

/**
 *
 * sales invoice actions
 *
 */

export const getListSalesInvoice = createAsyncThunk(
  'sales/sales-invoice-list',
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_SALES_INVOICE_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_SALES_INVOICE_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_SALES_INVOICE_FAILED,
      slicePayloadKeyname = SLICE_NAME_SALES_INVOICE_DATA,
      localStorageName = LOCAL_STORAGE_SALES_INVOICE,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      isUseSimplify = true,
      ...paramsGetListSalesInvoice
    } = {},
    thunkAPI,
  ) => {
    paramsGetListSalesInvoice = objHelper.changeFormatValue(
      paramsGetListSalesInvoice,
      ['from_date', 'to_date'],
      [
        (date) => {
          if (!date) return '';

          return moment(date).format('YYYY-MM-DD');
        },
      ],
    );

    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params: paramsGetListSalesInvoice,
      apiService: salesServices.getListSalesInvoice,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: 'sales_invoice_id',
      slicePayloadKeyname,
      errorMessage: 'error.list-data.sales-invoice',
      useSimplifyStorage: isUseSimplify,
    });
  },
);

export const getListSalesInvoiceRefresh = createAsyncThunk(
  'sales/sales-invoice-list-refresh',
  async (discardKeySearch = [], thunkAPI) => {
    return creatorListRefreshAction(
      thunkAPI,
      salesServices.getListSalesInvoice,
      LOCAL_STORAGE_SALES_INVOICE,
      'salesInvoiceData',
      discardKeySearch,
      DEFAULT_SALES_INVOICE_SEARCH_FE_PARAMS,
      'error.list-data.sales-invoice',
    );
  },
);

export const getDetailSalesInvoice = createAsyncThunk(
  'sales/sales-invoice-detail',
  async (params, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      params,
      salesServices.getDetailSalesInvoice,
      LOCAL_STORAGE_SALES_INVOICE_DETAIL,
      'salesInvoiceDetail',
      'error.detail.sales-invoice',
    );
  },
);

export const addSalesInvoice = createAsyncThunk(
  'sales/sales-invoice-add',
  async (bodyAddSalesInvoice, thunkAPI) => {
    return await creatorAddActionWithPreviewButton(
      thunkAPI,
      bodyAddSalesInvoice,
      salesServices.addSalesInvoice,
      'success.add.sales-invoice',
      'error.add.sales-invoice',
      {},
      {},
      'sales_invoice_id',
      REACT_APP_SALES_INVOICE_URL,
      { statusPage: DEFAULT_STATUS_PAGE_DETAIL_SALES_INVOICE },
    );
  },
);

// enhance on sales invoice create where for generate link button
export const addSalesInvoiceV2 = createAsyncThunk(
  'sales/sales-invoice-add/version-2',
  async (bodyAddSalesInvoice, thunkAPI) => {
    return await creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: bodyAddSalesInvoice,
      serviceFunc: salesServices.addSalesInvoice,
      successMessage: 'success.add.sales-invoice',
      errorMessage: 'error.add.sales-invoice',
      baseContentUrl: REACT_APP_SALES_URL,
      generateButtonLink: (responseResult) => {
        const templateLinkDetails = {
          [DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF]: objHelper.findAllValuesByKey(
            responseResult,
            DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF,
          ),
        };

        return strHelp.templateString(
          REACT_APP_SALES_SALES_INVOICE_DETAILS_TEMPLATE_URL,
          templateLinkDetails,
        );
      },
    });
  },
);

// action on add sales invoice where storing data on slice
export const addSalesInvoiceWithStoreData = createAsyncThunk(
  'sales/sales-invoice-add-with-store',
  async (bodyAddSalesInvoice, thunkAPI) => {
    return await creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      bodyAddSalesInvoice,
      salesServices.addSalesInvoice,
      SLICE_NAME_TEMP_ADD_SALES_INVOICE,
      'success.add.sales-invoice',
      'error.add.sales-invoice',
      {},
      {},
      true,
      ['paid'],
    );
  },
);

export const importSalesInvoice = createAsyncThunk(
  SALES_INVOICE_ASYNC_THUNK_TYPE[DEFAULT_IMPORT_FILE_ACTION_TYPE],
  async (body, thunkAPI) => {
    return await creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      body,
      salesServices.importSalesInvoice,
      SLICE_NAME_IMPORT_SALES_INVOICE,
    );
  },
);

export const getImportSalesInvoiceProgress = createAsyncThunk(
  SALES_INVOICE_ASYNC_THUNK_TYPE[DEFAULT_IMPORT_FILE_PROGRESS_ACTION_TYPE],
  async (params, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      params,
      salesServices.getImportSalesInvoiceProgress,
      null,
      SLICE_NAME_IMPORT_SALES_INVOICE_PROGRESS,
    );
  },
);

export const updateSalesInvoice = createAsyncThunk(
  'sales/sales-invoice-update',
  async (bodyUpdateSalesInvoice, thunkAPI) => {
    const { transaction_no } = bodyUpdateSalesInvoice;

    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdateSalesInvoice,
      salesServices.updateSalesInvoice,
      'success.update.sales-invoice',
      'error.update.sales-invoice',
      {},
      { transaction_no },
    );
  },
);

// action for update sales invoce that store on response data
export const updateSalesInvoiceWithStoreData = createAsyncThunk(
  'sales/sales-invoice-update-store-data',
  async (bodyUpdateSalesInvoice, thunkAPI) => {
    if (isEmpty(bodyUpdateSalesInvoice)) return thunkAPI.rejectWithValue(null);

    const { transaction_no } = bodyUpdateSalesInvoice;

    return await creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      bodyUpdateSalesInvoice,
      salesServices.updateSalesInvoice,
      SLICE_NAME_TEMP_UPDATE_SALES_INVOICE,
      'success.update.sales-invoice',
      'error.update.sales-invoice',
      {},
      { transaction_no },
      true,
      ['paid'],
    );
  },
);

export const getHistorySalesInvoice = createAsyncThunk(
  'sales/sales-invoice-history',
  async (paramsGetSalesInvoiceHistory, thunkAPI) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      paramsGetSalesInvoiceHistory,
      salesServices.getHistorySalesInvoice,
      '',
      'salesInvoiceHistory',
      'sales_invoice_id',
      'error.detail.sales-invoice-history',
    );
  },
);

export const deleteSalesInvoice = createAsyncThunk(
  'sales/sales-invoice-delete',
  async (paramsDeleteSalesInvoice, thunkAPI) => {
    const { sales_invoice_no } = paramsDeleteSalesInvoice;

    return creatorDeleteAction(
      thunkAPI,
      paramsDeleteSalesInvoice,
      salesServices.deleteSalesInvoice,
      'success.delete.sales-invoice',
      'error.delete.sales-invoice',
      {},
      { sales_invoice_no },
    );
  },
);

/**
 *
 * sales payment actions
 *
 */

export const getListSalesPayment = createAsyncThunk(
  'sales/sales-payment-list',
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_SALES_PAYMENT_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_SALES_PAYMENT_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_SALES_PAYMENT_FAILED,
      slicePayloadKeyname = SLICE_NAME_SALES_PAYMENT_DATA,
      localStorageName = LOCAL_STORAGE_SALES_PAYMENT,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...paramsGetListSalesPayment
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params: paramsGetListSalesPayment,
      apiService: salesServices.getListSalesPayment,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: 'sales_payment_id',
      slicePayloadKeyname,
      errorMessage: 'error.list-data.sales-payment',
    });
  },
);

export const getListSalesPaymentRefresh = createAsyncThunk(
  'sales/sales-payment-list-refresh',
  async (discardKeySearch = [], thunkAPI) => {
    return creatorListRefreshAction(
      thunkAPI,
      salesServices.getListSalesPayment,
      LOCAL_STORAGE_SALES_PAYMENT,
      'salesPaymentData',
      discardKeySearch,
      DEFAULT_SALES_PAYMENT_SEARCH_FE_PARAMS,
      'error.list-data.sales-payment',
    );
  },
);

export const getDetailSalesPayment = createAsyncThunk(
  'sales/sales-payment-detail',
  async (salesPaymentID, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      salesPaymentID,
      salesServices.getDetailSalesPayment,
      LOCAL_STORAGE_SALES_PAYMENT,
      'salesPaymentDetail',
      'error.detail.sales-payment',
    );
  },
);

export const getHistorySalesPayment = createAsyncThunk(
  'sales/sales-payment-history',
  async (paramsGetHistorySalesPayment, thunkAPI) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      paramsGetHistorySalesPayment,
      salesServices.getHistorySalesPayment,
      '',
      'salesPaymentHistory',
      'sales_payment_id',
      'error.history-data.sales-payment',
    );
  },
);

export const addSalesPayment = createAsyncThunk(
  'sales/sales-payment-add',
  async (bodyAddSalesPayment, thunkAPI) => {
    return await creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI: thunkAPI,
      bodyDataAction: bodyAddSalesPayment,
      serviceFunc: salesServices.addSalesPayment,
      successMessage: 'success.add.sales-payment',
      errorMessage: 'error.add.sales-payment',
      generateButtonLink: (subResponseDataAPI) => {
        if (isEmpty(subResponseDataAPI)) return '';

        subResponseDataAPI = {
          ...subResponseDataAPI,
          [DEFAULT_KEY_NAME_SALES_PAYMENT_ID_API_REF]: objHelper.findAllValuesByKey(
            subResponseDataAPI,
            DEFAULT_KEY_NAME_SALES_PAYMENT_ID_API_REF,
          ),
        };

        return strHelp.templateString(
          REACT_APP_SALES_SALES_PAYMENT_DETAILS_TEMPLATE_URL,
          subResponseDataAPI,
        );
      },
    });
  },
);

export const updateSalesPayment = createAsyncThunk(
  'sales/sales-payment-update',
  async (bodyUpdateSalesPayment, thunkAPI) => {
    const { sales_payment_no } = bodyUpdateSalesPayment;

    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdateSalesPayment,
      salesServices.updateSalesPayment,
      'success.update.sales-payment',
      'error.update.sales-payment',
      {},
      { sales_payment_no },
    );
  },
);

export const deleteSalesPayment = createAsyncThunk(
  'sales/sales-payment-delete',
  async (paramsDeleteSalesPayment, thunkAPI) => {
    const { sales_payment_no } = paramsDeleteSalesPayment;

    return await creatorDeleteAction(
      thunkAPI,
      paramsDeleteSalesPayment,
      salesServices.deleteSalesPayment,
      'success.delete.sales-payment',
      'error.delete.sales-payment',
      { sales_payment_no },
      { sales_payment_no },
    );
  },
);

/**
 *
 * sales return actions
 *
 */

export const getSalesReturnList = createAsyncThunk(
  'sales/sales-return-list',
  async (
    {
      requestStatusPending = REQUEST_STATUS_LIST_SALES_RETURN_LOADING,
      requestStatusSuccess = REQUEST_STATUS_LIST_SALES_RETURN_SUCCESS,
      requestStatusFailed = REQUEST_STATUS_LIST_SALES_RETURN_FAILED,
      slicePayloadKeyname = SLICE_NAME_SALES_RETURN_LIST,
      localStorageName = LOCAL_STORAGE_SALES_RETURN_LIST,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...paramsSalesReturnList
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params: paramsSalesReturnList,
      apiService: salesServices.getSalesReturnList,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: DEFAULT_KEY_NAME_SALES_RETURN_ID_API_REF,
      slicePayloadKeyname,
      errorMessage: 'error.list-data.sales-return',
    });
  },
);

// action sales return refresh list
export const getSalesReturnRefreshList = createAsyncThunk(
  'sales/sales-return-refresh-list',
  async (discardSearchKey = [], thunkAPI) => {
    return await creatorListRefreshAction(
      thunkAPI,
      salesServices.getSalesReturnList,
      LOCAL_STORAGE_SALES_RETURN_LIST,
      SLICE_NAME_SALES_RETURN_LIST,
      discardSearchKey,
      DEFAULT_SEARCH_PARAMS_SALES_RETURN_LIST,
      'error.list-data.sales-return',
    );
  },
);

// action sales return details
export const getSalesReturnDetails = createAsyncThunk(
  'sales/sales-return-details',
  async (paramsGetSalesReturnDetails, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      paramsGetSalesReturnDetails,
      salesServices.getSalesReturnDetails,
      LOCAL_STORAGE_SALES_RETURN_DETAILS,
      SLICE_NAME_SALES_RETURN_DETAILS,
      'error.detail.sales-return',
    );
  },
);

export const getSalesReturnHistory = createAsyncThunk(
  'sales/sales-return-history',
  async (params, thunkAPI) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      params,
      salesServices.getSalesReturnHistory,
      '',
      SLICE_NAME_SALES_RETURN_HISTORY,
      DEFAULT_KEY_NAME_SALES_RETURN_ID_API_REF,
      'error.detail.sales-return-history',
    );
  },
);

// action create sales return
export const createSalesReturn = createAsyncThunk(
  'sales/sales-return-create',
  async (bodyCreateSalesReturn, thunkAPI) => {
    return await creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: bodyCreateSalesReturn,
      serviceFunc: salesServices.createSalesReturn,
      successMessage: 'success.add.sales-return',
      errorMessage: 'error.add.sales-return',
      baseContentUrl: REACT_APP_SALES_RETURN_URL,
      generateButtonLink: (responseResult) => {
        const templateLinkDetails = {
          [DEFAULT_KEY_NAME_SALES_RETURN_ID_API_REF]: objHelper.findAllValuesByKey(
            responseResult,
            DEFAULT_KEY_NAME_SALES_RETURN_ID_API_REF,
          ),
        };

        return strHelp.templateString(
          REACT_APP_SALES_RETURN_DETAILS_TEMPLATE_URL,
          templateLinkDetails,
        );
      },
    });
  },
);

// action update sales return
export const updateSalesReturn = createAsyncThunk(
  'sales/sales-return-update',
  async (bodyUpdateSalesReturn, thunkAPI) => {
    const { transaction_no } = bodyUpdateSalesReturn;

    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdateSalesReturn,
      salesServices.updateSalesReturn,
      'success.update.sales-return',
      'error.update.sales-return',
      { transaction_no },
      { transaction_no },
    );
  },
);

// handling delete sales return
export const deleteSalesReturn = createAsyncThunk(
  'sales/sales-return-delete',
  async (paramsDeleteSalesReturn, thunkAPI) => {
    const { transaction_no } = paramsDeleteSalesReturn;

    return creatorDeleteAction(
      thunkAPI,
      paramsDeleteSalesReturn,
      salesServices.deleteSalesReturn,
      'success.delete.sales-return',
      'error.delete.sales-return',
      {},
      { transaction_no },
    );
  },
);

/**
 *
 * sales credit note actions
 *
 */

export const getSalesCreditNoteList = createAsyncThunk(
  'sales/credit-note-list',
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_FAILED,
      slicePayloadKeyname = SLICE_NAME_SALES_CREDIT_NOTE_LIST,
      localStorageName = LOCAL_STORAGE_SALES_CREDIT_NOTE_LIST,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...params
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params,
      apiService: salesServices.getSalesCreditNoteList,
      localStorageName,
      paginatePageParams,
      actionParams: DEFAULT_ACTION_SECONDARY_PARAMS,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: DEFAULT_KEY_NAME_CREDIT_NOTE_ID_API_REF,
      slicePayloadKeyname,
      errorMessage: 'error.list-data.sales-credit-note',
    });
  },
);

// action sales credit note refresh list
export const getSalesCreditNoteRefreshList = createAsyncThunk(
  'sales/credit-note-refresh-list',
  async (discardSearchKey = [], thunkAPI) => {
    return await creatorListRefreshAction(
      thunkAPI,
      salesServices.getSalesCreditNoteList,
      LOCAL_STORAGE_SALES_CREDIT_NOTE_LIST,
      SLICE_NAME_SALES_CREDIT_NOTE_LIST,
      discardSearchKey,
      DEFAULT_SEARCH_PARAMS_SALES_CREDIT_NOTE_LIST,
      'error.list-data.sales-credit-note',
    );
  },
);

// action sales credit note details
export const getSalesCreditNoteDetails = createAsyncThunk(
  'sales/credit-note-details',
  async (params, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      params,
      salesServices.getSalesCreditNoteDetails,
      LOCAL_STORAGE_SALES_CREDIT_NOTE_DETAILS,
      SLICE_NAME_SALES_CREDIT_NOTE_DETAILS,
      'error.detail.sales-credit-note',
    );
  },
);

export const getSalesCreditNoteHistory = createAsyncThunk(
  'sales/credit-note-history',
  async (params, thunkAPI) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      params,
      salesServices.getSalesCreditNoteHistory,
      '',
      SLICE_NAME_SALES_CREDIT_NOTE_HISTORY,
      DEFAULT_KEY_NAME_CREDIT_NOTE_ID_API_REF,
      'error.detail.sales-credit-note-history',
    );
  },
);

/**
 *
 * sales payable payment
 *
 */

export const getSalesPayablePaymentList = createAsyncThunk(
  'sales/payable-payment-list',
  async (
    {
      requestStatusPending = REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_LOADING,
      requestStatusSuccess = REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_SUCCESS,
      requestStatusFailed = REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_FAILED,
      slicePayloadKeyname = SLICE_NAME_SALES_PAYABLE_PAYMENT_LIST,
      localStorageName = LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_LIST,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...params
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params,
      apiService: salesServices.getSalesPayablePaymentList,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: DEFAULT_KEY_NAME_PAYABLE_PAYMENT_ID_API_REF,
      slicePayloadKeyname,
      errorMessage: 'error.list-data.sales-payable-payment',
    });
  },
);

// action sales payable payment refresh list
export const getSalesPayablePaymentRefreshList = createAsyncThunk(
  'sales/payable-payment-refresh-list',
  async (discardSearchKey = [], thunkAPI) => {
    return await creatorListRefreshAction(
      thunkAPI,
      salesServices.getSalesPayablePaymentList,
      LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_LIST,
      SLICE_NAME_SALES_PAYABLE_PAYMENT_LIST,
      discardSearchKey,
      DEFAULT_SEARCH_PARAMS_SALES_PAYABLE_PAYMENT_LIST,
      'error.list-data.sales-payable-payment',
    );
  },
);

// action sales payable payement details
export const getSalesPayablePaymentDetails = createAsyncThunk(
  'sales/payable-payment-details',
  async (params, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      params,
      salesServices.getSalesPayablePaymentDetails,
      LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_DETAILS,
      SLICE_NAME_SALES_PAYABLE_PAYMENT_DETAILS,
      'error.detail.sales-payable-payment',
    );
  },
);

export const getSalesPayablePaymentHistory = createAsyncThunk(
  'sales/payable-payment-history',
  async (params, thunkAPI) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      params,
      salesServices.getSalesPayablePaymentHistory,
      '',
      SLICE_NAME_SALES_PAYABLE_PAYMENT_HISTORY,
      DEFAULT_KEY_NAME_PAYABLE_PAYMENT_ID_API_REF,
      'error.detail.sales-payable-payment-history',
    );
  },
);

// action create sales payable payment
export const createSalesPayablePayment = createAsyncThunk(
  'sales/receivable-payment-create',
  async (body, thunkAPI) => {
    return await creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: body,
      serviceFunc: salesServices.createSalesPayablePayment,
      successMessage: 'success.add.sales-payable-payment',
      errorMessage: 'error.add.sales-payable-payment',
      baseContentUrl: REACT_APP_SALES_PAYABLE_PAYMENT_URL,
      generateButtonLink: (responseResult) => {
        const templateLinkDetails = {
          [DEFAULT_KEY_NAME_PAYABLE_PAYMENT_ID_API_REF]: objHelper.findAllValuesByKey(
            responseResult,
            DEFAULT_KEY_NAME_PAYABLE_PAYMENT_ID_API_REF,
          ),
        };

        return strHelp.templateString(
          REACT_APP_SALES_PAYABLE_PAYMENT_DETAILS_TEMPLATE_URL,
          templateLinkDetails,
        );
      },
    });
  },
);

// action update sales payable payment
export const updateSalesPayablePayment = createAsyncThunk(
  'sales/payable-payment-update',
  async (bodyUpdateSalesPayablePayment, thunkAPI) => {
    const { transaction_no } = bodyUpdateSalesPayablePayment;

    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdateSalesPayablePayment,
      salesServices.updateSalesPayablePayment,
      'success.update.sales-payable-payment',
      'error.update.sales-payable-payment',
      { transaction_no },
      { transaction_no },
    );
  },
);

// handling delete sales payable payment
export const deleteSalesPayablePayment = createAsyncThunk(
  'sales/payable-payment-delete',
  async (paramsDeleteSalesPayablePayment, thunkAPI) => {
    const { transaction_no } = paramsDeleteSalesPayablePayment;

    return creatorDeleteAction(
      thunkAPI,
      paramsDeleteSalesPayablePayment,
      salesServices.deleteSalesPayablePayment,
      'success.delete.sales-payable-payment',
      'error.delete.sales-payable-payment',
      {},
      { transaction_no },
    );
  },
);
