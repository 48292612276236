import { createAsyncThunk } from '@reduxjs/toolkit';

import userPermissionServices from '../../services/API/user-permission/user-permission.service';
import { LOCAL_STORAGE_USER_PERMISSION_LIST_DETAILS } from '../../utils/constants/storage.constant';
import { SLICE_NAME_USER_PERMISSION_LIST_DETAILS } from '../../utils/constants/store.constant';
import { setPermissionDataAuthSlice } from '../auth/auth.slice';
import {
  creatorListDetailAction,
  creatorUpdateAction,
} from '../creator-action/creator-action.helper';

// action user permission list details
export const getUserPermissionListDetails = createAsyncThunk(
  'user-permission/list-details',
  async (paramsGetUserPermissionListDetails, thunkAPI) => {
    return await creatorListDetailAction({
      thunkAPI,
      paramsDetail: paramsGetUserPermissionListDetails,
      serviceFunc: userPermissionServices.getUserPermissionListDetails,
      localStorageName: LOCAL_STORAGE_USER_PERMISSION_LIST_DETAILS,
      nameKeySlicePayload: SLICE_NAME_USER_PERMISSION_LIST_DETAILS,
      errorMessage: 'error.detail.user-permission',
      isMessageShow: true,
      usageStoreOnLocalStorage: true,
    });
  },
);

// action create user permission
export const createUserPermission = createAsyncThunk(
  'user-permission/create',
  async (bodyCreateUserPermission, thunkAPI) => {
    return await creatorUpdateAction(
      thunkAPI,
      bodyCreateUserPermission,
      userPermissionServices.createUserPermission,
      'success.update.user-permission',
      'error.update.user-permission',
      {},
      {},
      [setPermissionDataAuthSlice],
    );
  },
);

const userPermissionActions = {
  getUserPermissionListDetails,
  createUserPermission,
};

export default userPermissionActions;
