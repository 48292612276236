export const CHART_SALES_TRANSACTION_TYPE = 'chart-pro-sales-transaction';

export const CHART_INCOME_STATEMENT_TYPE = 'chart-pro-income-statement';

export const CHART_PRO_DASHBOARD_SALES_INVOICE_TYPE = 'chart-pro-dashboard-sales-invoice';
export const CHART_PRO_DASHBOARD_PURCHASE_INVOICE_TYPE = 'chart-pro-dashboard-purchase-invoice';

export const CHART_PRO_DASHBOARD_PIVOT_TABLE_TYPE = 'chart-pro-dashboard-pivot-table';

export const CHART_PRO_DASHBOARD_TOP_SALES_VERTICAL_CHART_MAIN_TYPE =
  'chart-pro-dashboard-top-sales-vertical-chart-main';
export const CHART_PRO_DASHBOARD_SALES_COMPARISON = 'chart-pro-dashboard-sales-comparison-main';
export const CHART_PRO_DASHBOARD_TOP_SALES_MAIN_TYPE = 'chart-pro-dashboard-top-sales-main';
export const CHART_PRO_DASHBOARD_TOP_SALES_SALES_AGENT_TYPE =
  'chart-pro-dashboard-top-sales-sales-agent';
export const CHART_PRO_DASHBOARD_TOP_SALES_CUSTOMER_TYPE = 'chart-pro-dashboard-top-sales-customer';
export const CHART_PRO_DASHBOARD_TOP_SALES_PRODUCT_TYPE = 'chart-pro-dashboard-top-sales-product';
export const CHART_PRO_DASHBOARD_TOP_SALES_PRODUCT_CATEGORY_TYPE =
  'chart-pro-dashboard-top-sales-product-category';

export const CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_MAIN_TYPE =
  'chart-pro-dashboard-top-sales-comparison-main';
export const CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_SALES_AGENT_TYPE =
  'chart-pro-dashboard-top-sales-comparison-sales-agent';
export const CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_CUSTOMER_TYPE =
  'chart-pro-dashboard-top-sales-comparison-customer';
export const CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_PRODUCT_TYPE =
  'chart-pro-dashboard-top-sales-comparison-product';
export const CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_PRODUCT_CATEGORY_TYPE =
  'chart-pro-dashboard-top-sales-comparison-product-category';
