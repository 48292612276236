import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';

class SpaceBarMain extends PureComponent {
  render() {
    const { amountSpace } = this.props;

    return (
      <>
        {[...Array(amountSpace)].map((i, index) => {
          return <span key={index}>&nbsp;</span>;
        })}
      </>
    );
  }
}

SpaceBarMain.propsType = {
  amountSpace: PropTypes.number,
};

SpaceBarMain.defaultProps = {
  amountSpace: 3,
};

export default SpaceBarMain;
