import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import { isEmpty } from 'lodash';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import BoxTableNotFound from '../../components/box/BoxTableNotFound/BoxTableNotFound';
import {
  axisXYreChartPrimaryProps,
  barRechartPrimaryProps,
  cartesianGridReChartPrimaryProps,
  responsiveContainerReChartPrimaryProps,
  verticalBarChartReChartPrimaryProps,
} from '../../utils/data/display/additional-props';
import { CHART_PRO_DASHBOARD_TOP_SALES_VERTICAL_CHART_MAIN_TYPE } from '../../utils/default/chart-component-type.default';
import { chartProfessionalDashboardListComponentUtils } from '../../utils/default/chart-professional-dashboard-config.default';
import arrHelp from '../../utils/helpers/array.helpers';
import formatHelp from '../../utils/helpers/format.helpers';

class VerticalBarChartTopSalesDashboard extends PureComponent {
  render() {
    const {
      t,
      data: dataProps,
      isRequestActionLoading,
      chartType,
      chartSubType,
      maximumItemsCount = 5,
    } = this.props;

    let selectedChartProfessionalConfig = chartProfessionalDashboardListComponentUtils[chartType];
    if (isEmpty(selectedChartProfessionalConfig)) {
      selectedChartProfessionalConfig =
        chartProfessionalDashboardListComponentUtils[
          CHART_PRO_DASHBOARD_TOP_SALES_VERTICAL_CHART_MAIN_TYPE
        ];
    }

    const {
      header,
      chartLegendLabel,
      chartYaxisKeyname: chartYaxisKeynameConfig,
      chartYaxisTooltipKeyname,
      otherChartYaxisKeyname,
      labelsNotFoundData,
      generalizeChartList,
      fillChartBars = [],
    } = selectedChartProfessionalConfig;

    let labelNotFoundData =
      labelsNotFoundData[chartSubType] || labelsNotFoundData[Object.keys(labelsNotFoundData)[0]];
    const chartLegendName = [t(chartLegendLabel), t(labelNotFoundData)].join(' ');

    if (header) labelNotFoundData = [t(header), t(labelNotFoundData)].join(' ');

    let changesDataProps = arrHelp.changeFormatValueInObj(
      dataProps,
      ['total'],
      [(value) => Number(value)],
    );

    const chartYaxisKeyname =
      chartSubType in otherChartYaxisKeyname
        ? otherChartYaxisKeyname[chartSubType]
        : chartYaxisKeynameConfig;

    if (isEmpty(dataProps) || isRequestActionLoading) {
      return (
        <Box minHeight={'inherit'}>
          {isRequestActionLoading ? (
            <Skeleton
              sx={{
                minHeight: 'inherit',
              }}
              width={'100%'}
              variant='rectangular'
            />
          ) : (
            <Box minHeight={'inherit'} display={'flex'}>
              <BoxTableNotFound
                showImage={false}
                textNotFound={t('typography.data-not-found', { menu: labelNotFoundData })}
                isExistButton={false}
              />
            </Box>
          )}
        </Box>
      );
    }

    if (Array.isArray(changesDataProps) && changesDataProps.length > maximumItemsCount)
      changesDataProps = changesDataProps.splice(0, maximumItemsCount);

    if (typeof generalizeChartList === 'function')
      changesDataProps = generalizeChartList(changesDataProps, chartYaxisKeyname);

    return (
      <ResponsiveContainer {...responsiveContainerReChartPrimaryProps}>
        <BarChart data={changesDataProps} {...verticalBarChartReChartPrimaryProps}>
          <CartesianGrid {...cartesianGridReChartPrimaryProps} />
          <XAxis
            {...axisXYreChartPrimaryProps}
            type='number'
            tickFormatter={(value) => {
              return formatHelp.currencyWithAutoComma(value);
            }}
          />
          <YAxis {...axisXYreChartPrimaryProps} dataKey={chartYaxisKeyname} type='category' />
          <Tooltip
            labelFormatter={(value, key) => {
              if (isEmpty(key)) return value;

              return key[0].payload[chartYaxisTooltipKeyname] || key[0].payload[chartYaxisKeyname];
            }}
            formatter={(value, key) => {
              return [formatHelp.currencyWithAutoComma(Number(value)), key];
            }}
          />
          <Bar {...barRechartPrimaryProps} name={chartLegendName} dataKey={'total'}>
            {!isEmpty(changesDataProps) && Array.isArray(changesDataProps)
              ? changesDataProps.map((val, index) => (
                  <Cell
                    key={index + JSON.stringify(val) + 'bar'}
                    fill={!isEmpty(fillChartBars) ? fillChartBars[index] : 'rgb(25, 118, 210)'}
                  />
                ))
              : null}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

const VerticalBarChartTopSalesDashboardWithTranslation = withTranslation()(
  VerticalBarChartTopSalesDashboard,
);

export default VerticalBarChartTopSalesDashboardWithTranslation;
