import { isEmpty } from 'lodash';

import {
  REACT_APP_API_URL_STOCK_ADJUSTMENT,
  REACT_APP_API_URL_STOCK_ADJUSTMENT_DETAILS,
} from '../../../utils/configs/api.config';
import { DEFAULT_STOCK_ADJUSTMENT_LIST_PARAMS } from '../../../utils/default/params.default';
import {
  creatorAddService,
  creatorDeleteService,
  creatorDetailServiceV2,
  creatorListService,
  creatorUpdateService,
} from '../creator-services/creator-services.helper';

/**
 *
 * stock adjustment services
 *
 */

// service getting stock adjustment list
const getStockAdjustmentList = async (paramsGetStockAdjustmentList) => {
  return await creatorListService(
    REACT_APP_API_URL_STOCK_ADJUSTMENT,
    paramsGetStockAdjustmentList,
    DEFAULT_STOCK_ADJUSTMENT_LIST_PARAMS,
  );
};

// service for get detail stock adjustment, minimum on params is stock_adjustment_id
const getStockAdjustmentDetails = async (paramsGetStockAdjustmentDetails) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_STOCK_ADJUSTMENT_DETAILS,
    paramsGetStockAdjustmentDetails,
  );
};

// service for adding stock adjustment
const addStockAdjustment = async (bodyAddStockAdjustment) => {
  return await creatorAddService(REACT_APP_API_URL_STOCK_ADJUSTMENT, bodyAddStockAdjustment, null);
};

// service update stock adjustment
const updateStockAdjustment = async ({ stock_adjustment_id, ...bodyUpdateStockAdjustment }) => {
  return await creatorUpdateService(
    stock_adjustment_id,
    REACT_APP_API_URL_STOCK_ADJUSTMENT,
    bodyUpdateStockAdjustment,
  );
};

// service delete on stock adjustment
const deleteStockAdjustment = async (paramsDeleteStockAdjustment) => {
  let stockAdjustmentID = null;

  if (!isEmpty(paramsDeleteStockAdjustment)) {
    stockAdjustmentID = paramsDeleteStockAdjustment.stock_adjustment_id;
  }

  return await creatorDeleteService(stockAdjustmentID, REACT_APP_API_URL_STOCK_ADJUSTMENT);
};

export const stockAdjustmentServices = {
  getStockAdjustmentDetails,
  getStockAdjustmentList,
  addStockAdjustment,
  updateStockAdjustment,
  deleteStockAdjustment,
};
