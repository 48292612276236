import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import billingServices from '../../services/API/billing/billing.service';
import {
  LOCAL_STORAGE_BILLING_INVOICE_DETAILS,
  LOCAL_STORAGE_BILLING_INVOICE_LIST,
  LOCAL_STORAGE_BILLING_PACKAGE_LIST,
} from '../../utils/constants/storage.constant';
import {
  SLICE_NAME_BILLING_INVOICE_DETAILS,
  SLICE_NAME_BILLING_INVOICE_LIST,
  SLICE_NAME_BILLING_PACKAGE_LIST,
  SLICE_NAME_CREATE_BILLING_TEMP,
  SLICE_NAME_PAY_BILLING_TEMP,
} from '../../utils/constants/store.constant';
import { dummyLastBillingTransactionsDataList } from '../../utils/data/dummy/last-billing-transactions';
import {
  DEFAULT_KEY_NAME_BILLING_ID_API_REF,
  DEFAULT_KEY_NAME_MASTER_PACKAGE_ID_API_REF,
  DEFAULT_KEY_NAME_USER_INVOICE_ID_API_REF,
} from '../../utils/default/object-keyname.default';
import arrHelp from '../../utils/helpers/array.helpers';
import {
  creatorAddAction,
  creatorAddActionWithStoreDataOnSlice,
  creatorDetailAction,
  creatorListAction,
} from '../creator-action/creator-action.helper';

export const getBillingDetailsFromDummy = createAsyncThunk(
  'billing/dummy-details',
  async (paramsGetDummyDetails, thunkAPI) => {
    if (
      isEmpty(paramsGetDummyDetails) ||
      (!isEmpty(paramsGetDummyDetails) &&
        !paramsGetDummyDetails[DEFAULT_KEY_NAME_BILLING_ID_API_REF])
    )
      return thunkAPI.rejectWithValue(null);

    const billingID = paramsGetDummyDetails[DEFAULT_KEY_NAME_BILLING_ID_API_REF];

    return arrHelp.filterObjDataWithID(
      dummyLastBillingTransactionsDataList,
      DEFAULT_KEY_NAME_BILLING_ID_API_REF,
      billingID,
    );
  },
);

// action for get user billing details
export const getUserBillingDetails = createAsyncThunk(
  'billing/user-details',
  async (paramsGetUserBillingDetails, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      paramsGetUserBillingDetails,
      billingServices.getUserBillingDetails,
      LOCAL_STORAGE_BILLING_INVOICE_DETAILS,
      SLICE_NAME_BILLING_INVOICE_DETAILS,
      '',
    );
  },
);

// action get user billing list
export const getUserBillingList = createAsyncThunk(
  'billing/user-list',
  async (paramsGetUserBillingList, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      paramsGetUserBillingList,
      billingServices.getUserBillingList,
      LOCAL_STORAGE_BILLING_INVOICE_LIST,
      SLICE_NAME_BILLING_INVOICE_LIST,
      DEFAULT_KEY_NAME_USER_INVOICE_ID_API_REF,
      'erorr.list-data.billing-user',
    );
  },
);

// action for get package list
export const getBillingPackageList = createAsyncThunk(
  'billing/package-list',
  async (paramsGetBillingPackageList, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      paramsGetBillingPackageList,
      billingServices.getBillingPackageList,
      LOCAL_STORAGE_BILLING_PACKAGE_LIST,
      SLICE_NAME_BILLING_PACKAGE_LIST,
      DEFAULT_KEY_NAME_MASTER_PACKAGE_ID_API_REF,
      'error.list-data.billing-package',
    );
  },
);

// actions for checkout billing
export const createBilling = createAsyncThunk('billing/create-billing', async (body, thunkAPI) => {
  return await creatorAddActionWithStoreDataOnSlice(
    thunkAPI,
    body,
    billingServices.createBilling,
    SLICE_NAME_CREATE_BILLING_TEMP,
  );
});

// update billing actions
export const updateBilling = createAsyncThunk('billing/update-billing', async (body, thunkAPI) => {
  return await creatorAddAction(
    thunkAPI,
    body,
    billingServices.updateBilling,
    '',
    '',
    {},
    {},
    false,
    false,
  );
});

// pay billing actions
export const payBilling = createAsyncThunk('billing/pay-billing', async (body, thunkAPI) => {
  return await creatorAddActionWithStoreDataOnSlice(
    thunkAPI,
    body,
    billingServices.payBilling,
    SLICE_NAME_PAY_BILLING_TEMP,
  );
});

const billingActions = {
  getBillingDetailsFromDummy,
  getUserBillingDetails,
  getUserBillingList,
  getBillingPackageList,
  createBilling,
  updateBilling,
  payBilling,
};

export default billingActions;
