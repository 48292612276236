// configuration for multiple application

import {
  alterRoutePathnameBillingDetails,
  alterRoutePathnameCashBankAccountTransactionList,
  alterRoutePathnameClosingBookCreate,
  alterRoutePathnameClosingBookDetails,
  alterRoutePathnameDataStockAdjustmentDetails,
  alterRoutePathnameJournalAssetManagementDetails,
  alterRoutePathnameJournalPurchaseReturnDetails,
  alterRoutePathnameJournalSalesDeliveryDetails,
  alterRoutePathnameJournalSalesInvoiceDetails,
  alterRoutePathnameJournalSalesPaymentDetails,
  alterRoutePathnameJournalSalesReturnDetails,
  alterRoutePathnamePurchaseDeliveryHandoverDetails,
  alterRoutePathnamePurchaseDeliveryHandoverJournalDetails,
} from './alter-route-pathname-properties.default';
import {
  connectGlobalStatePropertiesAccountDetails,
  connectGlobalStatePropertiesAccountTransactionCashBankList,
  connectGlobalStatePropertiesAssetManagementDetails,
  connectGlobalStatePropertiesBillingDetails,
  connectGlobalStatePropertiesCashPaymentDetails,
  connectGlobalStatePropertiesCashReceiptDetails,
  connectGlobalStatePropertiesCashTransferDetails,
  connectGlobalStatePropertiesContactDetails,
  connectGlobalStatePropertiesExpenseDetails,
  connectGlobalStatePropertiesMinutesHandoverPurchaseDeliveryDetails,
  connectGlobalStatePropertiesMinutesHandoverSalesDeliveryDetails,
  connectGlobalStatePropertiesProductDetails,
  connectGlobalStatePropertiesPurchaseDebitNoteDetails,
  connectGlobalStatePropertiesPurchaseDeliveryDetails,
  connectGlobalStatePropertiesPurchaseInvoiceDetails,
  connectGlobalStatePropertiesPurchaseOrderDetails,
  connectGlobalStatePropertiesPurchasePaymentDetails,
  connectGlobalStatePropertiesPurchaseQuotationRequestDetails,
  connectGlobalStatePropertiesPurchaseReceivablePaymentDetails,
  connectGlobalStatePropertiesPurchaseReturnDetails,
  connectGlobalStatePropertiesSalesCreditNoteDetails,
  connectGlobalStatePropertiesSalesDeliveryDetails,
  connectGlobalStatePropertiesSalesInvoiceDetails,
  connectGlobalStatePropertiesSalesOrderDetails,
  connectGlobalStatePropertiesSalesPayablePaymentDetails,
  connectGlobalStatePropertiesSalesPaymentDetails,
  connectGlobalStatePropertiesSalesQuotationDetails,
  connectGlobalStatePropertiesSalesReturnDetails,
  connectGlobalStatePropertiesSettingClosingBookDetails,
  // connectGlobalStatePropertiesSettingClosingBookDetails,
  connectGlobalStatePropertiesSettingUserDetails,
  connectGlobalStatePropertiesStockAdjustmentDetails,
  connectGlobalStatePropertiesWarehouseTransferDetails,
} from './connect-global-state-properties.default';
import {
  defaultParentUrlSearchParamsObjDataCashBankCashPayment,
  defaultParentUrlSearchParamsObjDataCashBankCashReceipt,
  defaultParentUrlSearchParamsObjDataCashBankCashTransfer,
  defaultParentUrlSearchParamsObjDataProductStockAdjustment,
} from './url-search-params.default';

export const DEFAULT_APPLICATION_TYPE_AUTH = 'auth';
export const DEFAULT_APPLICATION_TYPE_BASIC = 'basic';
export const DEFAULT_APPLICATION_TYPE_PROFESSIONAL = 'professional';
export const DEFAULT_APPLICATION_TYPE_COMPANY_SETUP = 'company-setup';
export const DEFAULT_APPLICATION_TYPE_CHOOSE_COMPANY = 'choose-company';

export const appConfiguration = {
  [DEFAULT_APPLICATION_TYPE_PROFESSIONAL]: {
    prefixBaseURL: '/',
    rootRouteURL: '/*',
    menuAccess: [
      '/',
      '/data',
      '/cash-bank',
      '/sales',
      '/purchase',
      '/setting',
      '/report',
      '/expense',
      '/billing',
    ],
    routerPathOnParams: [
      { path: '/data/account' },
      { path: '/data/account/create' },
      { path: '/data/account/add-multiple' },
      { path: '/data/account/:accountID/update' },
      { path: '/data/account/:accountID', ...connectGlobalStatePropertiesAccountDetails },
      { path: '/data/product/' },
      {
        path: '/data/product/stock-adjustment/create',
        ...defaultParentUrlSearchParamsObjDataProductStockAdjustment,
      },
      { path: '/data/stock-adjustment' },
      { path: '/data/stock-adjustment/create' },
      {
        path: '/data/stock-adjustment/:stockAdjustmentID',
        ...connectGlobalStatePropertiesStockAdjustmentDetails,
      },
      { path: '/data/stock-adjustment/:stockAdjustmentID/update' },
      {
        path: '/data/stock-adjustment/:stockAdjustmentID/journal/:transactionID',
        ...alterRoutePathnameDataStockAdjustmentDetails,
      },
      { path: '/data/warehouse-transfer' },
      { path: '/data/warehouse-transfer/create' },
      {
        path: '/data/warehouse-transfer/:transferStockID',
        ...connectGlobalStatePropertiesWarehouseTransferDetails,
      },
      { path: '/data/warehouse-transfer/:transferStockID/update' },
      { path: '/data/contact' },
      { path: '/data/contact/create' },
      { path: '/data/contact/:contactID', ...connectGlobalStatePropertiesContactDetails },
      { path: '/data/contact/:contactID/update' },
      {
        path: '/cash-bank/:accountID/account-transaction',
        ...connectGlobalStatePropertiesAccountTransactionCashBankList,
        ...alterRoutePathnameCashBankAccountTransactionList,
      },
      {
        path: '/cash-bank/cash-transfer/create',
        ...defaultParentUrlSearchParamsObjDataCashBankCashTransfer,
      },
      {
        path: '/cash-bank/cash-transfer/:transactionID',
        ...connectGlobalStatePropertiesCashTransferDetails,
        ...defaultParentUrlSearchParamsObjDataCashBankCashTransfer,
      },
      { path: '/cash-bank/cash-transfer/:transactionID/update' },
      { path: '/cash-bank/cash-transfer/:transactionID/journal-report' },
      {
        path: '/cash-bank/cash-receipt/create',
        ...defaultParentUrlSearchParamsObjDataCashBankCashReceipt,
      },
      {
        path: '/cash-bank/cash-receipt/:transactionID',
        ...connectGlobalStatePropertiesCashReceiptDetails,
        ...defaultParentUrlSearchParamsObjDataCashBankCashReceipt,
      },
      { path: '/cash-bank/cash-receipt/:transactionID/journal-report' },
      { path: '/cash-bank/cash-receipt/:transactionID/update' },
      {
        path: '/cash-bank/cash-payment/create',
        ...defaultParentUrlSearchParamsObjDataCashBankCashPayment,
      },
      {
        path: '/cash-bank/cash-payment/:transactionID',
        ...connectGlobalStatePropertiesCashPaymentDetails,
        ...defaultParentUrlSearchParamsObjDataCashBankCashPayment,
      },
      { path: '/cash-bank/cash-payment/:transactionID/journal-report' },
      { path: '/cash-bank/cash-payment/:transactionID/update' },
      { path: '/sales' },
      { path: '/sales/journal/:transactionID/details' },
      {
        path: '/sales/sales-delivery/:salesDeliveryID/journal/:transactionID',
        ...alterRoutePathnameJournalSalesDeliveryDetails,
      },
      {
        path: '/sales/sales-invoice/:salesInvoiceID/journal/:transactionID',
        ...alterRoutePathnameJournalSalesInvoiceDetails,
      },
      {
        path: '/sales/sales-payment/:salesPaymentID/journal/:transactionID',
        ...alterRoutePathnameJournalSalesPaymentDetails,
      },
      {
        path: '/sales/return/:salesReturnID/journal/:transactionID',
        ...alterRoutePathnameJournalSalesReturnDetails,
      },
      { path: '/sales/sales-quotation' },
      { path: '/sales/sales-quotation/create-new' },
      {
        path: '/sales/sales-quotation/:salesQuotationID',
        ...connectGlobalStatePropertiesSalesQuotationDetails,
      },
      { path: '/sales/sales-quotation/:salesQuotationID/update' },
      { path: '/sales/sales-quotation/:salesQuotationID/details/history' },
      { path: '/sales/sales-order' },
      { path: '/sales/sales-order/create-new' },
      {
        path: '/sales/sales-order/:salesOrderID',
        ...connectGlobalStatePropertiesSalesOrderDetails,
      },
      { path: '/sales/sales-order/:salesOrderID/update' },
      { path: '/sales/sales-order/:salesOrderID/details/history' },
      { path: '/sales/sales-delivery' },
      { path: '/sales/sales-delivery/create-new' },
      {
        path: '/sales/sales-delivery/:salesDeliveryID',
        ...connectGlobalStatePropertiesSalesDeliveryDetails,
      },
      { path: '/sales/sales-delivery/:salesDeliveryID/update' },
      {
        path: '/sales/sales-delivery/:salesDeliveryID/details/:salesDeliveryHandoverID/handover',
        ...connectGlobalStatePropertiesMinutesHandoverSalesDeliveryDetails,
      },
      {
        path: '/sales/sales-delivery/:salesDeliveryID/update/:salesDeliveryHandoverID/handover',
        ...connectGlobalStatePropertiesMinutesHandoverSalesDeliveryDetails,
      },
      { path: '/sales/sales-delivery/:salesDeliveryID/details/history' },
      { path: '/sales/sales-delivery/:salesDeliveryID/handover-create-new' },
      { path: '/sales/sales-invoice' },
      { path: '/sales/sales-invoice/create-new' },
      {
        path: '/sales/sales-invoice/:salesInvoiceID',
        ...connectGlobalStatePropertiesSalesInvoiceDetails,
      },
      { path: '/sales/sales-invoice/:salesInvoiceID/update' },
      { path: '/sales/sales-invoice/:salesInvoiceID/details/history' },
      { path: '/sales/sales-payment' },
      { path: '/sales/sales-payment/create-new' },
      {
        path: '/sales/sales-payment/:salesPaymentID',
        ...connectGlobalStatePropertiesSalesPaymentDetails,
      },
      { path: '/sales/sales-payment/:salesPaymentID/update' },
      { path: '/sales/sales-payment/:salesPaymentID/details/history' },
      { path: '/sales/return' },
      { path: '/sales/return/create-new' },
      { path: '/sales/return/:salesReturnID', ...connectGlobalStatePropertiesSalesReturnDetails },
      { path: '/sales/return/:salesReturnID/update' },
      { path: '/sales/credit-note' },
      {
        path: '/sales/credit-note/:creditNoteID',
        ...connectGlobalStatePropertiesSalesCreditNoteDetails,
      },
      { path: '/sales/payable-payment' },
      {
        path: '/sales/payable-payment/:payablePaymentID',
        ...connectGlobalStatePropertiesSalesPayablePaymentDetails,
      },
      { path: '/sales/payable-payment/create-new' },
      { path: '/sales/payable-payment/:payablePaymentID/update' },
      { path: '/purchase' },
      { path: '/purchase/journal/:transactionID/details' },
      {
        path: '/purchase/purchase-delivery/:purchaseDeliveryID/journal/:transactionID',
        ...alterRoutePathnameJournalSalesDeliveryDetails,
      },
      {
        path: '/purchase/purchase-invoice/:purchaseInvoiceID/journal/:transactionID',
        ...alterRoutePathnameJournalSalesDeliveryDetails,
      },
      {
        path: '/purchase/purchase-payment/:purchasePaymentID/journal/:transactionID',
        ...alterRoutePathnameJournalSalesDeliveryDetails,
      },
      {
        path: '/purchase/return/:purchaseReturnID/journal/:transactionID',
        ...alterRoutePathnameJournalPurchaseReturnDetails,
      },
      { path: '/purchase/quotation-request' },
      { path: '/purchase/quotation-request/create-new' },
      {
        path: '/purchase/quotation-request/:quotationRequestID',
        ...connectGlobalStatePropertiesPurchaseQuotationRequestDetails,
      },
      { path: '/purchase/quotation-request/:quotationRequestID/details/history' },
      { path: '/purchase/quotation-request/:quotationRequestID/update' },
      { path: '/purchase/purchase-order' },
      { path: '/purchase/purchase-order/create-new' },
      {
        path: '/purchase/purchase-order/:purchaseOrderID',
        ...connectGlobalStatePropertiesPurchaseOrderDetails,
      },
      { path: '/purchase/purchase-order/:purchaseOrderID/details/history' },
      { path: '/purchase/purchase-order/:purchaseOrderID/update' },
      { path: '/purchase/purchase-delivery' },
      { path: '/purchase/purchase-delivery/create-new' },
      {
        path: '/purchase/purchase-delivery/:purchaseDeliveryID',
        ...connectGlobalStatePropertiesPurchaseDeliveryDetails,
      },
      { path: '/purchase/purchase-delivery/:purchaseDeliveryID/details/history' },
      { path: '/purchase/purchase-delivery/:purchaseDeliveryID/update' },
      { path: '/purchase/purchase-delivery/:purchaseDeliveryID/handover-create-new' },
      {
        path: '/purchase/purchase-delivery/:purchaseDeliveryHandoverID/handover',
        ...connectGlobalStatePropertiesMinutesHandoverPurchaseDeliveryDetails,
        ...alterRoutePathnamePurchaseDeliveryHandoverDetails,
      },
      {
        path: '/purchase/purchase-delivery/:purchaseDeliveryID/details/:purchaseDeliveryHandoverID/handover',
        ...connectGlobalStatePropertiesMinutesHandoverPurchaseDeliveryDetails,
      },
      {
        path: '/purchase/purchase-delivery/:purchaseDeliveryID/details/:purchaseDeliveryHandoverID/handover/journal/:transactionID',
        ...alterRoutePathnamePurchaseDeliveryHandoverJournalDetails,
      },
      {
        path: '/purchase/purchase-delivery/:purchaseDeliveryID/update/:purchaseDeliveryHandoverID/handover',
        ...connectGlobalStatePropertiesMinutesHandoverPurchaseDeliveryDetails,
      },
      { path: '/purchase/purchase-invoice' },
      { path: '/purchase/purchase-invoice/create-new' },
      {
        path: '/purchase/purchase-invoice/:purchaseInvoiceID',
        ...connectGlobalStatePropertiesPurchaseInvoiceDetails,
      },
      { path: '/purchase/purchase-invoice/:purchaseInvoiceID/details/history' },
      { path: '/purchase/purchase-invoice/:purchaseInvoiceID/update' },
      { path: '/purchase/purchase-payment' },
      { path: '/purchase/purchase-payment/create-new' },
      {
        path: '/purchase/purchase-payment/:purchasePaymentID',
        ...connectGlobalStatePropertiesPurchasePaymentDetails,
      },
      { path: '/purchase/purchase-payment/:purchasePaymentID/details/history' },
      { path: '/purchase/purchase-payment/:purchasePaymentID/update' },
      { path: '/purchase/return' },
      { path: '/purchase/return/create-new' },
      {
        path: '/purchase/return/:purchaseReturnID',
        ...connectGlobalStatePropertiesPurchaseReturnDetails,
      },
      { path: '/purchase/return/:purchaseReturnID/update' },
      { path: '/purchase/debit-note' },
      {
        path: '/purchase/debit-note/:debitNoteID',
        ...connectGlobalStatePropertiesPurchaseDebitNoteDetails,
      },
      { path: '/purchase/receivable-payment' },
      {
        path: '/purchase/receivable-payment/:receivablePaymentID',
        ...connectGlobalStatePropertiesPurchaseReceivablePaymentDetails,
      },
      { path: '/purchase/receivable-payment/create-new' },
      { path: '/purchase/receivable-payment/:receivablePaymentID/update' },
      { path: '/expense' },
      { path: '/expense/:expenseID', ...connectGlobalStatePropertiesExpenseDetails },
      { path: '/expense/:expenseID/update' },
      { path: '/expense/create-new' },
      { path: '/data/product' },
      { path: '/data/product/create' },
      { path: '/data/product/:productID', ...connectGlobalStatePropertiesProductDetails },
      { path: '/data/product/:productID/update' },
      { path: '/setting' },
      { path: '/setting/user' },
      { path: '/setting/user/:userID', ...connectGlobalStatePropertiesSettingUserDetails },
      { path: '/setting/closing-book' },
      {
        path: '/setting/closing-book/create/:closingBookID',
        ...alterRoutePathnameClosingBookCreate,
      },
      {
        path: '/setting/closing-book/details/:closingBookID',
        ...connectGlobalStatePropertiesSettingClosingBookDetails,
        ...alterRoutePathnameClosingBookDetails,
      },
      { path: '/data/asset' },
      { path: '/data/asset/create-new' },
      { path: '/data/asset/:assetID', ...connectGlobalStatePropertiesAssetManagementDetails },
      { path: '/data/asset/:assetID/update' },
      {
        path: '/data/asset/:assetID/journal/:transactionID',
        ...alterRoutePathnameJournalAssetManagementDetails,
      },
      { path: '/billing' },
      { path: '/billing/:company_bill_no' },
    ],
    settings: {
      isNotificationShow: true,
      isIconMenuPopupShow: true,
      showUserNcompanyMenu: true,
      disabledSwitchApp: false,
      disabledFetchDashboardData: false,
      showSubscriptionBanner: true,
      showNavigationMenu: true,
    },
    historyPathnamePrevious: [],
  },
  [DEFAULT_APPLICATION_TYPE_BASIC]: {
    prefixBaseURL: '/basic',
    rootRouteURL: '/basic/*',
    menuAccess: ['/', '/purchase-basic', '/sales-basic', '/stock', '/report-basic'],
    routerPathOnParams: [],
    settings: {
      isNotificationShow: false,
      isIconMenuPopupShow: false,
      showUserNcompanyMenu: true,
      disabledSwitchApp: false,
      disabledFetchDashboardData: false,
      showSubscriptionBanner: true,
      showNavigationMenu: true,
    },
    historyPathnamePrevious: [],
  },
  [DEFAULT_APPLICATION_TYPE_CHOOSE_COMPANY]: {
    prefixBaseURL: '/',
    rootRouteURL: '/*',
    menuAccess: ['/choose-company', '/billing', '/company-register', '/company-branch-register'],
    routerPathOnParams: [
      {
        path: '/billing/:company_bill_no',
        ...connectGlobalStatePropertiesBillingDetails,
        ...alterRoutePathnameBillingDetails,
      },
    ],
    settings: {
      isNotificationShow: false,
      isIconMenuPopupShow: false,
      showUserNcompanyMenu: false,
      disabledSwitchApp: true,
      disabledFetchDashboardData: true,
      showSubscriptionBanner: false,
      showNavigationMenu: false,
    },
    historyPathnamePrevious: [],
  },
  [DEFAULT_APPLICATION_TYPE_COMPANY_SETUP]: {
    prefixBaseURL: '/',
    rootRouteURL: '/*',
    menuAccess: ['/company-setup'],
    routerPathOnParams: [],
    settings: {
      isNotificationShow: true,
      isIconMenuPopupShow: true,
      showUserNcompanyMenu: true,
      disabledSwitchApp: true,
      disabledFetchDashboardData: true,
      showSubscriptionBanner: false,
      showNavigationMenu: false,
    },
    historyPathnamePrevious: [],
  },
  [DEFAULT_APPLICATION_TYPE_AUTH]: {
    prefixBaseURL: '/',
    rootRouteURL: '/*',
    menuAccess: [
      '/login',
      '/forgot-password',
      '/new-password',
      '/register',
      '/resend-email',
      '/verify',
    ],
    routerPathOnParams: [],
    settings: {
      isNotificationShow: false,
      isIconMenuPopupShow: false,
      showUserNcompanyMenu: false,
      disabledSwitchApp: true,
      disabledFetchDashboardData: true,
      showSubscriptionBanner: false,
      showNavigationMenu: false,
    },
    historyPathnamePrevious: [],
  },
};
