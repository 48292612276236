import { createAsyncThunk } from '@reduxjs/toolkit';

import companyServices from '../../services/API/company/company.service';
import userServices from '../../services/API/user/user.service';
import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  LOCAL_STORAGE_USER_COMPANY,
  LOCAL_STORAGE_USER_DETAIL,
} from '../../utils/constants/storage.constant';
import { SLICE_NAME_COMPANY_LIST_AUTOCOMPLETE } from '../../utils/constants/store.constant';
import { DEFAULT_KEY_NAME_COMPANY_ID_API_REF } from '../../utils/default/object-keyname.default';
import { DEFAULT_SETTING_COMPANY_SEARCH_FE_PARAMS } from '../../utils/default/params.default';
import { setAuthUserData } from '../auth/auth.slice';
import {
  creatorListAction,
  creatorListRefreshAction,
} from '../creator-action/creator-action.helper';
import messageHelper from '../message/message.helper';

// get user profile detail
export const detailUser = createAsyncThunk('user/detail', async (data, thunkAPI) => {
  try {
    const response = await userServices.detailUser();

    if (!response) {
      return thunkAPI.rejectWithValue(response);
    }

    const { data } = response.data;

    LocalStorage.set(LOCAL_STORAGE_USER_DETAIL, data);

    return { profile: data };
  } catch (error) {
    const { response } = error;

    // handling server internal error
    if (!response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);
    }

    const { data } = error.response;

    return thunkAPI.rejectWithValue(data);
  }
});

// update user profile data
export const updateUser = createAsyncThunk('user/update', async (updateDataUser, thunkAPI) => {
  try {
    const response = await userServices.updateUser(updateDataUser);

    if (!response) {
      return thunkAPI.rejectWithValue(response);
    }

    // response data
    const { data: responseData } = response;

    // and data from server, that store for state
    const { data } = responseData;

    const messageResponse = messageHelper.getMessageFromResponseData(responseData);

    // create message from fe
    if (!messageResponse) {
      messageHelper.successMessageAuthorizated(
        thunkAPI.dispatch,
        'success.update.user-profile',
        'success.update.user-profile',
      );
    } else {
      messageHelper.successMessageAuthorizated(
        thunkAPI.dispatch,
        messageResponse,
        'success.update.user-profile',
      );
    }

    // setting data auth user
    thunkAPI.dispatch(setAuthUserData({ user: data }));

    LocalStorage.set(LOCAL_STORAGE_USER_DETAIL, data);

    return { profile: data };
  } catch (error) {
    const { response } = error;

    if (!response) {
      return thunkAPI.rejectWithValue(response);
    }

    const { data } = response;

    const responseMessage = messageHelper.getMessageFromResponseData(data);

    if (!responseMessage) {
      messageHelper.failedMessage(thunkAPI.dispatch, 'error.update.user-profile', '', false);
    } else {
      messageHelper.failedMessage(thunkAPI.dispatch, responseMessage, '', false);
    }

    return thunkAPI.rejectWithValue(data);
  }
});

// get companies that user join
export const getListCompanyUser = createAsyncThunk(
  'user/company-list',
  async (paramsGetListCompanyUser, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      paramsGetListCompanyUser,
      companyServices.getCompanyList,
      LOCAL_STORAGE_USER_COMPANY,
      'company',
      'company_id',
      'error.list-data.setting-company',
    );
  },
);

// get companies that user join
export const getListCompanyUserAutocomplete = createAsyncThunk(
  'user/company-list-autocomplete',
  async (paramsGetListCompanyUser, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      {
        isShowMessage: false,
        ...paramsGetListCompanyUser,
      },
      companyServices.getCompanyList,
      LOCAL_STORAGE_USER_COMPANY,
      SLICE_NAME_COMPANY_LIST_AUTOCOMPLETE,
      DEFAULT_KEY_NAME_COMPANY_ID_API_REF,
    );
  },
);

// action refresh list company user
export const getListCompanyUserRefresh = createAsyncThunk(
  'user/company-list-refresh',
  async (discardKeySearch = [], thunkAPI) => {
    return await creatorListRefreshAction(
      thunkAPI,
      companyServices.getCompanyList,
      LOCAL_STORAGE_USER_COMPANY,
      'company',
      discardKeySearch,
      DEFAULT_SETTING_COMPANY_SEARCH_FE_PARAMS,
      'error.list-data.setting-company',
    );
  },
);
