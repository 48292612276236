import { isEmpty } from 'lodash';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF,
  DEFAULT_KEY_NAME_FILE_PROPERTIES,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_UUID_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import arrHelp from '../../helpers/array.helpers';
import formatHelp from '../../helpers/format.helpers';
import objHelper from '../../helpers/object.helper';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import {
  allowKeysDataProductQuotationRequest,
  allowKeysDataQuotationRequest,
  initialDataProduct,
  initialDataQuotationRequest,
  initialValidationProduct,
  initialValidationQuotationRequest,
} from './initial-data-quotation-request';

/**
 *
 * @param { number } amount, amount generate data and validation product from quotation request
 * @param { string } customDataKey, name key return for data
 * @param { string } customValidationKey, name key return for validation
 * @returns
 *      generated data and validation for product from quotation request
 */
export const generateDataNValidationAddProductQR = (
  amount = 2,
  customDataKey = 'addProductData',
  customValidationKey = 'addProductValidation',
) => {
  const usedID = arrHelp.generateArrWithFunc(amount, uuidV4);

  const addProductData = usedID.map((id) => {
    return initialDataProduct('', id);
  });

  const addProductValidation = usedID.map((id) => {
    return initialValidationProduct('', id);
  });

  return {
    [customDataKey]: addProductData,
    [customValidationKey]: addProductValidation,
  };
};

/**
 *
 * @param { number } amount, amount data generate
 * @param { string } customDataKey, name key return for data
 * @param { string } customValidationKey, name key return for validation
 * @returns
 *     generated data and validation for quotation request
 */
export const generateDataNValidationAddQuotationRequest = (
  amount = 1,
  customDataKey = 'addQuotationRequestData',
  customValidationKey = 'addQuotationRequestValidation',
) => {
  const usedID = arrHelp.generateArrWithFunc(amount, uuidV4);

  const addQuotationRequestData = usedID.map((id) => {
    return initialDataQuotationRequest('', id);
  });

  const addQuotationRequestValidation = usedID.map((id) => {
    return initialValidationQuotationRequest('', id);
  });

  return {
    [customDataKey]: addQuotationRequestData,
    [customValidationKey]: addQuotationRequestValidation,
  };
};

/**
 *
 * @param { number } amount                 amount data generate
 * @param { string } customDataKey          name key return for data
 * @param { object } companyUserData        data user company
 * @param { string } customValidationKey    name key return for validation
 * @returns
 *     generated data and validation for quotation request (enhancement)
 */
export const generateDataNValidationAddQuotationRequestV2 = ({
  amount = 1,
  companyUserData = {},
  customDataKey = 'addQuotationRequestData',
  customValidationKey = 'addQuotationRequestValidation',
}) => {
  const usedID = arrHelp.generateArrWithFunc(amount, uuidV4);

  const addQuotationRequestData = usedID.map((id) => {
    return {
      ...initialDataQuotationRequest('', id),
      accounting_period: companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF],
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
    };
  });

  const addQuotationRequestValidation = usedID.map((id) => {
    return initialValidationQuotationRequest('', id);
  });

  return {
    [customDataKey]: addQuotationRequestData,
    [customValidationKey]: addQuotationRequestValidation,
  };
};

/**
 *
 * @param { object } addQuotationRequestData, data quotation request
 * @param { object } addProductData, data product,
 * @returns
 *      generalized data quotation request for add
 */
export const generalizeDataAddQuotationRequest = (addQuotationRequestData, addProductData) => {
  if (!Array.isArray(addQuotationRequestData) || !Array.isArray(addProductData)) {
    return {
      product: addProductData,
      ...addQuotationRequestData,
    };
  }

  const dataProductGeneralized = Array.from(addProductData).map((productSingleData) => {
    return {
      ...productSingleData,
      price: formatHelp.reverseCurrencyFormatWithRegex(productSingleData.price),
      product_description: productSingleData.product_description
        ? productSingleData.product_description
        : '',
    };
  });

  const dataAddQuotationRequestGeneralized = Array.from(addQuotationRequestData).map(
    (singleDataQR) => {
      const {
        transaction_date,
        expired_date,
        supplier_id_container,
        discount_percentage,
        discount_nominal,
        discount_type,
        discount_account_id,
        expense_value,
        expense_account_id,
        down_payment_value,
        down_payment_account_id,
        attachment,
      } = singleDataQR;

      const uuidAttachment =
        Array.isArray(attachment) && attachment.length
          ? attachment[0][DEFAULT_KEY_NAME_FILE_PROPERTIES][DEFAULT_KEY_NAME_UUID_API_REF] || ''
          : '';

      return {
        ...singleDataQR,
        [DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF]: uuidAttachment,
        supplier_name: !isEmpty(supplier_id_container) ? supplier_id_container.contact_name : '',
        transaction_date: transaction_date ? moment(transaction_date).toISOString(true) : '',
        expired_date: expired_date ? moment(expired_date).toISOString(true) : '',
        amount: formatHelp.reverseCurrencyFormatWithRegex(singleDataQR.amount),
        discount_percentage: discount_percentage == 0 ? '' : discount_percentage,
        discount_nominal: discount_nominal == 0 ? '' : discount_nominal,
        discount_type: discount_nominal == 0 || discount_percentage == 0 ? '' : discount_type,
        discount_account_id:
          discount_nominal == 0 || discount_percentage == 0 ? '' : discount_account_id,
        expense_account_id: expense_value ? expense_account_id : '',
        down_payment_account_id: down_payment_value ? down_payment_account_id : '',
      };
    },
  );

  return {
    product: dataProductGeneralized,
    ...dataAddQuotationRequestGeneralized[0],
  };
};

/**
 *
 * @param { object } bodyData, data body of update or add quotation request, this data for sending into backend
 * @returns
 * data generalize for action data or update quotation request in BE
 *
 */
export function generalizeDataAddUpdateQuotationRequest(bodyData) {
  bodyData = objHelper.filterKeyObj(bodyData, [], allowKeysDataQuotationRequest);

  if (Array.isArray(bodyData.product) && !isEmpty(bodyData.product)) {
    bodyData.product = bodyData.product.map((bodyProduct) => {
      const filteringKeyBodyProduct = objHelper.filterKeyObj(
        bodyProduct,
        [],
        allowKeysDataProductQuotationRequest,
      );

      const isExitedValueInROw = objHelper.filteringExistedValue(filteringKeyBodyProduct, [], true);

      if (!isEmpty(isExitedValueInROw)) {
        return filteringKeyBodyProduct;
      }
    });

    bodyData.product = arrHelp.filteringExistedValue(bodyData.product);
  }

  return objHelper.filteringExistedValue(bodyData);
}
