import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  NAME_STORE,
  SLICE_NAME_EXPORTED_DOCUMENT_REPORT,
  SLICE_NAME_REPORT_BALANCE_SHEET,
  SLICE_NAME_REPORT_CAPITAL_CHANGE,
  SLICE_NAME_REPORT_CASH_FLOW,
  SLICE_NAME_REPORT_GENERAL_LEDGER,
  SLICE_NAME_REPORT_INCOME_STATEMENT,
  SLICE_NAME_REPORT_INDEX_LIST,
  SLICE_NAME_REPORT_JOURNAL_LIST,
  SLICE_NAME_REPORT_TRIAL_BALANCE,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_EXPORT_DOCUMENT_REPORT_FAILED,
  STATUS_REQUEST_EXPORT_DOCUMENT_REPORT_PENDING,
  STATUS_REQUEST_EXPORT_DOCUMENT_REPORT_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_BALANCE_SHEET_FAILED,
  STATUS_REQUEST_LIST_REPORT_BALANCE_SHEET_PENDING,
  STATUS_REQUEST_LIST_REPORT_BALANCE_SHEET_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_CAPITAL_CHANGE_FAILED,
  STATUS_REQUEST_LIST_REPORT_CAPITAL_CHANGE_PENDING,
  STATUS_REQUEST_LIST_REPORT_CAPITAL_CHANGE_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_CASH_FLOW_FAILED,
  STATUS_REQUEST_LIST_REPORT_CASH_FLOW_PENDING,
  STATUS_REQUEST_LIST_REPORT_CASH_FLOW_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_GENERAL_LEDGER_FAILED,
  STATUS_REQUEST_LIST_REPORT_GENERAL_LEDGER_PENDING,
  STATUS_REQUEST_LIST_REPORT_GENERAL_LEDGER_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_INCOME_STATEMENT_FAILED,
  STATUS_REQUEST_LIST_REPORT_INCOME_STATEMENT_PENDING,
  STATUS_REQUEST_LIST_REPORT_INCOME_STATEMENT_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_INDEX_FAILED,
  STATUS_REQUEST_LIST_REPORT_INDEX_PENDING,
  STATUS_REQUEST_LIST_REPORT_INDEX_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_JOURNAL_FAILED,
  STATUS_REQUEST_LIST_REPORT_JOURNAL_PENDING,
  STATUS_REQUEST_LIST_REPORT_JOURNAL_SUCCESS,
  STATUS_REQUEST_LIST_REPORT_TRIAL_BALANCE_FAILED,
  STATUS_REQUEST_LIST_REPORT_TRIAL_BALANCE_PENDING,
  STATUS_REQUEST_LIST_REPORT_TRIAL_BALANCE_SUCCESS,
} from '../../utils/constants/store.constant';
import {
  getReportBasicConfiguration,
  REPORT_BASIC_TYPE_KEY_NAME,
  REPORT_BASIC_TYPE_PURCHASE,
} from '../../utils/default/report-basic-config.default';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import reportActions from './report.action';

const initialState = {
  [SLICE_NAME_REPORT_INDEX_LIST]: null,
  [SLICE_NAME_EXPORTED_DOCUMENT_REPORT]: null,
  [SLICE_NAME_REPORT_JOURNAL_LIST]: null,
  [SLICE_NAME_REPORT_INCOME_STATEMENT]: null,
  [SLICE_NAME_REPORT_BALANCE_SHEET]: null,
  [SLICE_NAME_REPORT_CAPITAL_CHANGE]: null,
  [SLICE_NAME_REPORT_CASH_FLOW]: null,
  [SLICE_NAME_REPORT_GENERAL_LEDGER]: null,
  [SLICE_NAME_REPORT_TRIAL_BALANCE]: null,
};

const reportSlice = createSlice({
  name: NAME_STORE.REPORT,
  initialState,
  reducers: {
    reportActions,
    clearReportDataSlice: (state, action) => {
      const { sliceName, localStorageName } = action.payload;

      if (localStorageName && LocalStorage.get(localStorageName)) {
        LocalStorage.remove(localStorageName);
      }

      return {
        ...state,
        [sliceName]: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState),
  },
  extraReducers: (builder) => {
    // list report index
    builder.addCase(reportActions.getReportIndexList.fulfilled, (state, action) => {
      state[SLICE_NAME_REPORT_INDEX_LIST] = action.payload[SLICE_NAME_REPORT_INDEX_LIST];
      state.statusRequest = STATUS_REQUEST_LIST_REPORT_INDEX_SUCCESS;
    }),
      builder.addCase(reportActions.getReportIndexList.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_INDEX_PENDING;
      }),
      builder.addCase(reportActions.getReportIndexList.rejected, (state) => {
        state[SLICE_NAME_REPORT_INDEX_LIST] = null;
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_INDEX_FAILED;
      }),
      // refresh list report index
      builder.addCase(reportActions.getReportIndexListRefresh.fulfilled, (state, action) => {
        state[SLICE_NAME_REPORT_INDEX_LIST] = action.payload[SLICE_NAME_REPORT_INDEX_LIST];
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_INDEX_SUCCESS;
      }),
      builder.addCase(reportActions.getReportIndexListRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_INDEX_PENDING;
      }),
      builder.addCase(reportActions.getReportIndexListRefresh.rejected, (state) => {
        state[SLICE_NAME_REPORT_INDEX_LIST] = null;
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_INDEX_FAILED;
      }),
      // export document report
      builder.addCase(reportActions.documentReportExport.fulfilled, (state, action) => {
        state[SLICE_NAME_EXPORTED_DOCUMENT_REPORT] =
          action.payload[SLICE_NAME_EXPORTED_DOCUMENT_REPORT];
        state.statusRequest = STATUS_REQUEST_EXPORT_DOCUMENT_REPORT_SUCCESS;
      }),
      builder.addCase(reportActions.documentReportExport.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_EXPORT_DOCUMENT_REPORT_PENDING;
      }),
      builder.addCase(reportActions.documentReportExport.rejected, (state) => {
        state[SLICE_NAME_EXPORTED_DOCUMENT_REPORT] = null;
        state.statusRequest = STATUS_REQUEST_EXPORT_DOCUMENT_REPORT_FAILED;
      }),
      // list report journal
      builder.addCase(reportActions.getReportJournalList.fulfilled, (state, action) => {
        state[SLICE_NAME_REPORT_JOURNAL_LIST] = action.payload[SLICE_NAME_REPORT_JOURNAL_LIST];
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_JOURNAL_SUCCESS;
      }),
      builder.addCase(reportActions.getReportJournalList.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_JOURNAL_PENDING;
      }),
      builder.addCase(reportActions.getReportJournalList.rejected, (state) => {
        state[SLICE_NAME_REPORT_JOURNAL_LIST] = null;
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_JOURNAL_FAILED;
      }),
      // list report income statement
      builder.addCase(reportActions.getReportIncomeStatementList.fulfilled, (state, action) => {
        state[SLICE_NAME_REPORT_INCOME_STATEMENT] =
          action.payload[SLICE_NAME_REPORT_INCOME_STATEMENT];
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_INCOME_STATEMENT_SUCCESS;
      }),
      builder.addCase(reportActions.getReportIncomeStatementList.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_INCOME_STATEMENT_PENDING;
      }),
      builder.addCase(reportActions.getReportIncomeStatementList.rejected, (state) => {
        state[SLICE_NAME_REPORT_INCOME_STATEMENT] = null;
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_INCOME_STATEMENT_FAILED;
      }),
      // list report balance sheet
      builder.addCase(reportActions.getReportBalanceSheetList.fulfilled, (state, action) => {
        state[SLICE_NAME_REPORT_BALANCE_SHEET] = action.payload[SLICE_NAME_REPORT_BALANCE_SHEET];
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_BALANCE_SHEET_SUCCESS;
      }),
      builder.addCase(reportActions.getReportBalanceSheetList.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_BALANCE_SHEET_PENDING;
      }),
      builder.addCase(reportActions.getReportBalanceSheetList.rejected, (state) => {
        state[SLICE_NAME_REPORT_BALANCE_SHEET] = null;
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_BALANCE_SHEET_FAILED;
      }),
      // list report capital change
      builder.addCase(reportActions.getReportCapitalChangeList.fulfilled, (state, action) => {
        state[SLICE_NAME_REPORT_CAPITAL_CHANGE] = action.payload[SLICE_NAME_REPORT_CAPITAL_CHANGE];
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_CAPITAL_CHANGE_SUCCESS;
      }),
      builder.addCase(reportActions.getReportCapitalChangeList.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_CAPITAL_CHANGE_PENDING;
      }),
      builder.addCase(reportActions.getReportCapitalChangeList.rejected, (state) => {
        state[SLICE_NAME_REPORT_CAPITAL_CHANGE] = null;
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_CAPITAL_CHANGE_FAILED;
      }),
      // list report cash flow
      builder.addCase(reportActions.getReportCashFlowList.fulfilled, (state, action) => {
        state[SLICE_NAME_REPORT_CASH_FLOW] = action.payload[SLICE_NAME_REPORT_CASH_FLOW];
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_CASH_FLOW_SUCCESS;
      }),
      builder.addCase(reportActions.getReportCashFlowList.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_CASH_FLOW_PENDING;
      }),
      builder.addCase(reportActions.getReportCashFlowList.rejected, (state) => {
        state[SLICE_NAME_REPORT_CASH_FLOW] = null;
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_CASH_FLOW_FAILED;
      }),
      // list report general ledger
      builder.addCase(reportActions.getReportGeneralLedgerList.fulfilled, (state, action) => {
        state[SLICE_NAME_REPORT_GENERAL_LEDGER] = action.payload[SLICE_NAME_REPORT_GENERAL_LEDGER];
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_GENERAL_LEDGER_SUCCESS;
      }),
      builder.addCase(reportActions.getReportGeneralLedgerList.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_GENERAL_LEDGER_PENDING;
      }),
      builder.addCase(reportActions.getReportGeneralLedgerList.rejected, (state) => {
        state[SLICE_NAME_REPORT_GENERAL_LEDGER] = null;
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_GENERAL_LEDGER_FAILED;
      }),
      // list report trial balance
      builder.addCase(reportActions.getReportTrialBalanceList.fulfilled, (state, action) => {
        state[SLICE_NAME_REPORT_TRIAL_BALANCE] = action.payload[SLICE_NAME_REPORT_TRIAL_BALANCE];
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_TRIAL_BALANCE_SUCCESS;
      }),
      builder.addCase(reportActions.getReportTrialBalanceList.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_TRIAL_BALANCE_PENDING;
      }),
      builder.addCase(reportActions.getReportTrialBalanceList.rejected, (state) => {
        state[SLICE_NAME_REPORT_TRIAL_BALANCE] = null;
        state.statusRequest = STATUS_REQUEST_LIST_REPORT_TRIAL_BALANCE_FAILED;
      }),
      // list report basic list
      builder.addCase(reportActions.getReportBasicList.fulfilled, (state, action) => {
        const reportBasicType =
          action.meta.arg[REPORT_BASIC_TYPE_KEY_NAME] || REPORT_BASIC_TYPE_PURCHASE;

        const { childrenSliceList, requestStatusSuccessList } =
          getReportBasicConfiguration(reportBasicType);

        state[childrenSliceList] = action.payload[childrenSliceList];
        state.statusRequest = requestStatusSuccessList;
      }),
      builder.addCase(reportActions.getReportBasicList.pending, (state, action) => {
        const reportBasicType =
          action.meta.arg[REPORT_BASIC_TYPE_KEY_NAME] || REPORT_BASIC_TYPE_PURCHASE;

        const { requestStatusLoadingList } = getReportBasicConfiguration(reportBasicType);

        state.statusRequest = requestStatusLoadingList;
      }),
      builder.addCase(reportActions.getReportBasicList.rejected, (state, action) => {
        const reportBasicType =
          action.meta.arg[REPORT_BASIC_TYPE_KEY_NAME] || REPORT_BASIC_TYPE_PURCHASE;

        const { childrenSliceList, requestStatusFailedList } =
          getReportBasicConfiguration(reportBasicType);

        state[childrenSliceList] = null;
        state.statusRequest = requestStatusFailedList;
      });
  },
});

export const { actions, reducer } = reportSlice;

export const { clearReportDataSlice } = actions;

export default reducer;
