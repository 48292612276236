import * as React from 'react';
function SvgIconMenuPurchaseQuotationRequest(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.857 68.571a.429.429 0 11-.857 0 .429.429 0 01.857 0zM6.857 68.143h58.286a.428.428 0 110 .857H6.857a.429.429 0 010-.857zM2.571 68.143h2.143a.428.428 0 110 .857H2.571a.429.429 0 010-.857zM69.429 68.143h-2.143a.428.428 0 100 .857h2.143a.429.429 0 000-.857zM72 68.571a.429.429 0 11-.857 0 .429.429 0 01.857 0zM19.714 22.98c0-.222.09-.435.252-.592a.868.868 0 01.605-.245h2.572v20.081h1.714V18.796c0-.222.09-.435.252-.591a.869.869 0 01.605-.246H57.43c.227 0 .445.089.605.245.161.157.252.37.252.592v29.633H60V18.796c0-.666-.272-1.304-.754-1.774a2.606 2.606 0 00-1.817-.736H25.714c-.681 0-1.335.265-1.817.736a2.483 2.483 0 00-.754 1.774v1.673H20.57c-.681.001-1.335.266-1.817.736A2.483 2.483 0 0018 22.98v17.326h1.714V22.98z'
        fill='#000'
      />
      <path
        d='M52.286 25.49H30.857a.868.868 0 00-.606.245.826.826 0 000 1.183c.16.157.379.245.606.245h21.429a.868.868 0 00.606-.245.827.827 0 000-1.183.868.868 0 00-.606-.245zM52.286 28.837H30.857a.868.868 0 00-.606.245.827.827 0 000 1.183c.16.157.379.245.606.245h21.429a.868.868 0 00.606-.245.826.826 0 000-1.183.868.868 0 00-.606-.245zM30.857 32.184h21.429c.227 0 .445.088.606.245a.827.827 0 010 1.183.868.868 0 01-.606.245H30.857a.868.868 0 01-.606-.245.827.827 0 010-1.183.868.868 0 01.606-.245zM52.286 38.878H30.857a.868.868 0 00-.606.245.827.827 0 000 1.183c.16.157.379.245.606.245h21.429a.868.868 0 00.606-.245.826.826 0 000-1.183.868.868 0 00-.606-.245zM30.857 35.53h21.429c.227 0 .445.089.606.246a.826.826 0 010 1.183.868.868 0 01-.606.245H30.857a.868.868 0 01-.606-.245.827.827 0 010-1.183.868.868 0 01.606-.245z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.865 38.709a.774.774 0 01.079-.249.646.646 0 01.151-.193.519.519 0 01.2-.108.467.467 0 01.22-.006l4.82 1.006c.095.02.184.068.259.14a.693.693 0 01.17.276l1.228 3.681h27.74a.97.97 0 01.491.139 1.2 1.2 0 01.392.383c.106.162.18.35.216.552.036.201.035.41-.005.61l-1.968 9.832a1.43 1.43 0 01-.382.736c-.19.188-.425.296-.67.307l-21.294.982.461 1.388c.046.124.121.229.215.302a.505.505 0 00.317.11H41.17c.149 0 .292.072.398.2a.761.761 0 01.164.482c0 .181-.059.354-.164.482a.518.518 0 01-.398.2H19.505a1.476 1.476 0 01-.947-.34 2.002 2.002 0 01-.625-.925l-6.095-18.259-4.514-.94a.507.507 0 01-.205-.096.626.626 0 01-.16-.183.758.758 0 01-.088-.243.823.823 0 01-.006-.266zm8.492 8.638l.455 1.364h25.102l.273-1.364h-25.83zm-.909-2.727l.453 1.364h26.558l.273-1.364H14.448zm3.614 10.834l-1.794-5.38H40.64l-.877 4.378-21.702 1.002z'
        fill='#000'
      />
      <path
        d='M21.224 68.143a4.502 4.502 0 01-.825-2.097 4.887 4.887 0 01.25-2.304c.265-.723.698-1.336 1.245-1.767a2.965 2.965 0 011.838-.66c.653 0 1.291.23 1.838.66.547.43.98 1.044 1.245 1.767.266.722.353 1.522.25 2.304a4.502 4.502 0 01-.824 2.097h-2.508c.596 0 1.169-.288 1.59-.8.422-.51.66-1.204.66-1.927 0-.54-.133-1.067-.38-1.515a2.406 2.406 0 00-1.01-1.005 1.887 1.887 0 00-1.3-.155 2.162 2.162 0 00-1.151.746 2.945 2.945 0 00-.616 1.396 3.268 3.268 0 00.127 1.576c.17.498.459.924.829 1.224.37.3.805.46 1.25.46h-2.508zM34.724 68.143a4.502 4.502 0 01-.825-2.097 4.887 4.887 0 01.25-2.304c.266-.723.698-1.336 1.245-1.767a2.965 2.965 0 011.838-.66c.653 0 1.291.23 1.838.66.548.43.98 1.044 1.245 1.767.266.722.353 1.522.25 2.304a4.502 4.502 0 01-.824 2.097h-2.508c.597 0 1.169-.288 1.59-.8.422-.51.66-1.204.66-1.927 0-.54-.133-1.067-.38-1.515a2.405 2.405 0 00-1.01-1.005 1.887 1.887 0 00-1.3-.155 2.162 2.162 0 00-1.151.746 2.945 2.945 0 00-.616 1.396 3.268 3.268 0 00.127 1.576c.17.498.459.924.829 1.224.37.3.805.46 1.25.46h-2.508z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M62.161 68.143a.742.742 0 00.5-.19.626.626 0 00.209-.46V51.239c-3.213 3.167-9.913 6.663-15.047 6.663-3.466 0-4.109-.26-4.109-.26v-8.356H62.161c.564 0 1.104.206 1.502.572.398.365.622.861.623 1.379v16.256c0 .221-.043.441-.124.65H62.16zm-14.346-11.38c-2.815.033-3.458 0-4.1-.406v-5.77H61.58c-1.878 1.765-7.15 6.097-13.766 6.175z'
        fill='#000'
      />
    </svg>
  );
}
export default SvgIconMenuPurchaseQuotationRequest;
