import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import moment from 'moment';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_CHART_DASHBOARD_PRO_APP_SEARCH_PARAMS } from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_FAILED,
  REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_PENDING,
  REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_SUCCESS,
  REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_FAILED,
  REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_PENDING,
  REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_SUCCESS,
  SLICE_NAME_CHART_BASIC_DASHBOARD_PAYABLE,
  SLICE_NAME_CHART_BASIC_DASHBOARD_PRODUCT_STOCK,
  SLICE_NAME_CHART_BASIC_DASHBOARD_PURCHASE,
  SLICE_NAME_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE,
  SLICE_NAME_CHART_BASIC_DASHBOARD_RECEIVABLE,
  SLICE_NAME_CHART_BASIC_DASHBOARD_SALES,
  SLICE_NAME_CHART_BASIC_DASHBOARD_SALES_INVOICE,
  SLICE_NAME_CHART_BASIC_DASHBOARD_SALES_PERSON,
  SLICE_NAME_CHART_COMPARISON_SALES,
  SLICE_NAME_CHART_DASHBOARD_PRO_APP_SEARCH_PARAMS,
  SLICE_NAME_CHART_EXPENSE,
  SLICE_NAME_CHART_INCOME_STATEMENT_DETAILS,
  SLICE_NAME_CHART_PIVOT_TABLE,
  SLICE_NAME_CHART_SALES_TRANSACTION_DETAILS,
  SLICE_NAME_CHART_TOP_SALES_COMPARISON_CUSTOMER,
  SLICE_NAME_CHART_TOP_SALES_COMPARISON_PRODUCT,
  SLICE_NAME_CHART_TOP_SALES_COMPARISON_PRODUCT_CATEGORY,
  SLICE_NAME_CHART_TOP_SALES_COMPARISON_SALES_AGENT,
  SLICE_NAME_CHART_TOP_SALES_CUSTOMER,
  SLICE_NAME_CHART_TOP_SALES_PRODUCT,
  SLICE_NAME_CHART_TOP_SALES_PRODUCT_CATEGORY,
  SLICE_NAME_CHART_TOP_SALES_SALES_AGENT,
} from '../../utils/constants/store.constant';
import {
  CHART_BASIC_DASHBOARD_TYPE_KEY_NAME,
  CHART_BASIC_DASHBOARD_TYPE_PURCHASE,
  CHART_BASIC_DASHBOARD_TYPE_SALES_INVOICE,
  chartBasicApplicationType,
  getChartDashboardBasicConfiguration,
  getPaperChartListBasicDashboard,
} from '../../utils/default/chart-basic-dashboard-config.default';
import {
  chartProfessionalApplicationType,
  getChartProfessionalDashboardListActionUtils,
} from '../../utils/default/chart-professional-dashboard-config.default';
import {
  DEFAULT_KEY_NAME_ACTION_UTILS,
  DEFAULT_KEY_NAME_CHART_UTILS_TYPE,
} from '../../utils/default/object-keyname.default';
import arrHelp from '../../utils/helpers/array.helpers';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import chartActions from './chart.action';

export const relateChartApplicationTypeWithGetConfiguraion = {
  [chartBasicApplicationType]: getPaperChartListBasicDashboard,
  [chartProfessionalApplicationType]: getChartProfessionalDashboardListActionUtils,
};

const chartDashboardProAppSearchParams = LocalStorage.get(
  LOCAL_STORAGE_CHART_DASHBOARD_PRO_APP_SEARCH_PARAMS,
);

const initialDashboardProAppSearchParams = {
  from_date: moment().startOf('month').toISOString(true),
  to_date: moment().endOf('month').toISOString(true),
};

const initialState = {
  [SLICE_NAME_CHART_BASIC_DASHBOARD_PURCHASE]: null,
  [SLICE_NAME_CHART_BASIC_DASHBOARD_SALES]: null,
  [SLICE_NAME_CHART_BASIC_DASHBOARD_PAYABLE]: null,
  [SLICE_NAME_CHART_BASIC_DASHBOARD_RECEIVABLE]: null,
  [SLICE_NAME_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE]: null,
  [SLICE_NAME_CHART_BASIC_DASHBOARD_SALES_INVOICE]: null,
  [SLICE_NAME_CHART_BASIC_DASHBOARD_SALES_PERSON]: null,
  [SLICE_NAME_CHART_BASIC_DASHBOARD_PRODUCT_STOCK]: null,
  [SLICE_NAME_CHART_SALES_TRANSACTION_DETAILS]: null,
  [SLICE_NAME_CHART_INCOME_STATEMENT_DETAILS]: null,
  [SLICE_NAME_CHART_TOP_SALES_SALES_AGENT]: null,
  [SLICE_NAME_CHART_TOP_SALES_CUSTOMER]: null,
  [SLICE_NAME_CHART_TOP_SALES_PRODUCT]: null,
  [SLICE_NAME_CHART_TOP_SALES_PRODUCT_CATEGORY]: null,
  [SLICE_NAME_CHART_TOP_SALES_COMPARISON_SALES_AGENT]: null,
  [SLICE_NAME_CHART_TOP_SALES_COMPARISON_CUSTOMER]: null,
  [SLICE_NAME_CHART_TOP_SALES_COMPARISON_PRODUCT]: null,
  [SLICE_NAME_CHART_TOP_SALES_COMPARISON_PRODUCT_CATEGORY]: null,
  [SLICE_NAME_CHART_DASHBOARD_PRO_APP_SEARCH_PARAMS]:
    chartDashboardProAppSearchParams || initialDashboardProAppSearchParams,
  [SLICE_NAME_CHART_COMPARISON_SALES]: null,
  [SLICE_NAME_CHART_EXPENSE]: null,
  [SLICE_NAME_CHART_PIVOT_TABLE]: null,
  requestStatusParallel: [],
};

const chartSlice = createSlice({
  name: NAME_STORE.CHART,
  initialState,
  reducers: {
    chartActions,
    // payload: {chartType, overrideValue}
    overrideDataChartSlice: (state, action) => {
      const { overrideValue } = action.payload;
      if (overrideValue === undefined) return state;

      const chartType =
        action.payload[CHART_BASIC_DASHBOARD_TYPE_KEY_NAME] || CHART_BASIC_DASHBOARD_TYPE_PURCHASE;
      const { localStorageName, sliceName } =
        getChartProfessionalDashboardListActionUtils(chartType);

      const overrideSliceValue = {
        ...state[sliceName],
        ...overrideValue,
      };

      if (localStorageName) {
        LocalStorage.set(localStorageName, overrideSliceValue);
      }

      state[sliceName] = overrideSliceValue;
    },
    clearDataChartSlice: (state, action) => {
      const chartType =
        action.payload[CHART_BASIC_DASHBOARD_TYPE_KEY_NAME] || CHART_BASIC_DASHBOARD_TYPE_PURCHASE;

      const {
        localStorageName,
        sliceName,
        requestStatusPending,
        requestStatusError,
        requestStatusSuccess,
      } = getChartDashboardBasicConfiguration(chartType);

      if (LocalStorage.get(localStorageName)) {
        LocalStorage.remove(localStorageName);
      }

      let currentRequestStatus = state.requestStatusParallel;

      if (Array.isArray(currentRequestStatus)) {
        currentRequestStatus = arrHelp.removeItemsAndSubtitue({
          arr: currentRequestStatus,
          itemsRemove: [requestStatusError, requestStatusPending, requestStatusSuccess],
          itemsSubtitue: [],
        });
      }

      return {
        ...state,
        [sliceName]: null,
        requestStatusParallel: currentRequestStatus,
      };
    },
    setupSingleChartSliceNlocalStorage: (state, action) => {
      const { sliceName, localStorageName, setupValue } = action.payload;

      if (isEmpty(setupValue)) return state;

      const newSelectedSlice = {
        ...state[sliceName],
        ...setupValue,
      };

      if (localStorageName) LocalStorage.set(localStorageName, newSelectedSlice);

      return {
        ...state,
        [sliceName]: newSelectedSlice,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState),
  },
  extraReducers: (builder) => {
    // get details of chart basic dashboard
    builder.addCase(chartActions.getChartBasicDashboardDetails.fulfilled, (state, action) => {
      const chartType =
        action.meta.arg[CHART_BASIC_DASHBOARD_TYPE_KEY_NAME] || CHART_BASIC_DASHBOARD_TYPE_PURCHASE;
      const chartActionUtils = action.meta.arg[DEFAULT_KEY_NAME_ACTION_UTILS];

      const { sliceName, requestStatusPending, requestStatusError, requestStatusSuccess } =
        typeof chartActionUtils === 'function'
          ? chartActionUtils(chartType)
          : getChartDashboardBasicConfiguration(chartType);

      state[sliceName] = action.payload[sliceName];
      state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
        arr: state.requestStatusParallel,
        itemsRemove: [requestStatusPending, requestStatusError],
        itemsSubtitue: requestStatusSuccess,
      });
    }),
      builder.addCase(chartActions.getChartBasicDashboardDetails.pending, (state, action) => {
        const chartType =
          action.meta.arg[CHART_BASIC_DASHBOARD_TYPE_KEY_NAME] ||
          CHART_BASIC_DASHBOARD_TYPE_PURCHASE;
        const chartActionUtils = action.meta.arg[DEFAULT_KEY_NAME_ACTION_UTILS];

        const { requestStatusPending, requestStatusError, requestStatusSuccess } =
          typeof chartActionUtils === 'function'
            ? chartActionUtils(chartType)
            : getChartDashboardBasicConfiguration(chartType);

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [requestStatusSuccess, requestStatusError],
          itemsSubtitue: requestStatusPending,
        });
      }),
      builder.addCase(chartActions.getChartBasicDashboardDetails.rejected, (state, action) => {
        const chartType =
          action.meta.arg[CHART_BASIC_DASHBOARD_TYPE_KEY_NAME] ||
          CHART_BASIC_DASHBOARD_TYPE_PURCHASE;
        const chartActionUtils = action.meta.arg[DEFAULT_KEY_NAME_ACTION_UTILS];

        const { sliceName, requestStatusPending, requestStatusError, requestStatusSuccess } =
          typeof chartActionUtils === 'function'
            ? chartActionUtils(chartType)
            : getChartDashboardBasicConfiguration(chartType);

        state[sliceName] = null;
        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [requestStatusPending, requestStatusSuccess],
          itemsSubtitue: requestStatusError,
        });
      }),
      // get list of chart basic dashboard
      builder.addCase(chartActions.getChartListBasicDashboard.fulfilled, (state, action) => {
        const chartType =
          action.meta.arg[CHART_BASIC_DASHBOARD_TYPE_KEY_NAME] ||
          CHART_BASIC_DASHBOARD_TYPE_SALES_INVOICE;
        const chartUtilsType =
          action.meta.arg[DEFAULT_KEY_NAME_CHART_UTILS_TYPE] || chartBasicApplicationType;

        const chartUtilsAction = relateChartApplicationTypeWithGetConfiguraion[chartUtilsType];

        const { sliceName, requestStatusPending, requestStatusError, requestStatusSuccess } =
          typeof chartUtilsAction === 'function'
            ? chartUtilsAction(chartType)
            : getPaperChartListBasicDashboard(chartType);

        state[sliceName] = action.payload[sliceName];
        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [requestStatusPending, requestStatusError],
          itemsSubtitue: requestStatusSuccess,
        });
      }),
      builder.addCase(chartActions.getChartListBasicDashboard.pending, (state, action) => {
        const chartType =
          action.meta.arg[CHART_BASIC_DASHBOARD_TYPE_KEY_NAME] ||
          CHART_BASIC_DASHBOARD_TYPE_SALES_INVOICE;
        const chartUtilsType =
          action.meta.arg[DEFAULT_KEY_NAME_CHART_UTILS_TYPE] || chartBasicApplicationType;

        const chartUtilsAction = relateChartApplicationTypeWithGetConfiguraion[chartUtilsType];

        const { requestStatusPending, requestStatusError, requestStatusSuccess } =
          typeof chartUtilsAction === 'function'
            ? chartUtilsAction(chartType)
            : getPaperChartListBasicDashboard(chartType);

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [requestStatusSuccess, requestStatusError],
          itemsSubtitue: requestStatusPending,
        });
      }),
      builder.addCase(chartActions.getChartListBasicDashboard.rejected, (state, action) => {
        const chartType =
          action.meta.arg[CHART_BASIC_DASHBOARD_TYPE_KEY_NAME] ||
          CHART_BASIC_DASHBOARD_TYPE_SALES_INVOICE;
        const chartUtilsType =
          action.meta.arg[DEFAULT_KEY_NAME_CHART_UTILS_TYPE] || chartBasicApplicationType;

        const chartUtilsAction = relateChartApplicationTypeWithGetConfiguraion[chartUtilsType];

        const { sliceName, requestStatusPending, requestStatusError, requestStatusSuccess } =
          typeof chartUtilsAction === 'function'
            ? chartUtilsAction(chartType)
            : getPaperChartListBasicDashboard(chartType);

        state[sliceName] = null;
        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [requestStatusPending, requestStatusSuccess],
          itemsSubtitue: requestStatusError,
        });
      }),
      // get sales transaction details
      builder.addCase(chartActions.getSalesTransactionChartDetails.fulfilled, (state, action) => {
        state[SLICE_NAME_CHART_SALES_TRANSACTION_DETAILS] =
          action.payload[SLICE_NAME_CHART_SALES_TRANSACTION_DETAILS];

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_PENDING,
            REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_FAILED,
          ],
          itemsSubtitue: REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_SUCCESS,
        });
      }),
      builder.addCase(chartActions.getSalesTransactionChartDetails.pending, (state) => {
        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_SUCCESS,
            REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_FAILED,
          ],
          itemsSubtitue: REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_PENDING,
        });
      }),
      builder.addCase(chartActions.getSalesTransactionChartDetails.rejected, (state) => {
        state[SLICE_NAME_CHART_SALES_TRANSACTION_DETAILS] = null;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_SUCCESS,
            REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_PENDING,
          ],
          itemsSubtitue: REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_FAILED,
        });
      }),
      // get income statement details
      builder.addCase(chartActions.getIncomeStatementChartDetails.fulfilled, (state, action) => {
        state[SLICE_NAME_CHART_INCOME_STATEMENT_DETAILS] =
          action.payload[SLICE_NAME_CHART_INCOME_STATEMENT_DETAILS];

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_FAILED,
            REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_PENDING,
          ],
          itemsSubtitue: REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_SUCCESS,
        });
      }),
      builder.addCase(chartActions.getIncomeStatementChartDetails.pending, (state) => {
        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_SUCCESS,
            REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_FAILED,
          ],
          itemsSubtitue: REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_PENDING,
        });
      }),
      builder.addCase(chartActions.getIncomeStatementChartDetails.rejected, (state) => {
        state[SLICE_NAME_CHART_INCOME_STATEMENT_DETAILS] = null;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_SUCCESS,
            REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_PENDING,
          ],
          itemsSubtitue: REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_FAILED,
        });
      });
  },
});

export const { actions, reducer } = chartSlice;

export const { setupSingleChartSliceNlocalStorage, clearDataChartSlice, overrideDataChartSlice } =
  actions;

export default reducer;
