import {
  FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN,
  FORMAT_DATE_COLUMN,
} from '../../../components/table-row/TableRowMultipleTableDataPureMarkup/TableRowMultipleTableDataPureMarkup';
import { FORMAT_CURRENCY_AUTO_COMMA_COLUMN } from '../../../components/table-row/TableRowPrimary/TableRowPrimary';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';

import { primaryTableDataCalculationNumber } from './additional-styles';

// enhancement on table header sales delivery product data list
export const columnsTableHeaderSalesDeliveryProductDataListV2 = (t) => {
  return [
    {
      multipleNames: [
        {
          name: 'product_name',
        },
        {
          name: 'description',
        },
      ],
      label: t('table.header-column.product-name'),
      isUseMultipleNames: true,
      width: 400,
    },
    {
      name: 'quantity',
      label: t('table.header-column.product-quantity'),
      align: 'left',
      width: 50,
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: !hasImplementedUnitProductUnit ? 'product_unit' : 'unit_destination',
      label: t('table.header-column.product-unit'),
      align: 'left',
      width: 25,
    },
  ];
};

// columns key value table
export const columnsTableKeyValuePrintSalesDeliveryDetails = [
  {
    headerTranslated: 'No.',
    valueKeyName: 'sales_delivery_no',
  },
  {
    header: 'table.header-column.date',
    valueKeyName: 'delivery_date',
    formatValue: FORMAT_DATE_COLUMN,
  },
  {
    header: 'table.header-column.customer',
    valueKeyName: 'customer_name',
  },
  {
    header: 'table.header-column.address',
    valueKeyName: 'customer_address',
  },
];

export const columnsTableProductSalesDeliveryDetails = (t) => {
  return [
    {
      isAutoIncrementColumn: true,
      label: t('No.'),
      align: 'center',
      style: {
        maxWidth: 20,
      },
    },
    {
      name: 'product_name',
      label: t('table.header-column.product-name'),
      style: {
        minWidth: 'auto',
        width: 593,
        maxWidth: 600,
      },
    },
    {
      name: 'quantity',
      label: t('table.header-column.qty'),
      style: {
        minWidth: 'auto',
        width: 20,
        maxWidth: 35,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'unit_destination',
      label: t('table.header-column.unit'),
      style: {
        minWidth: 'auto',
        width: 30,
        maxWidth: 35,
      },
    },
    {
      name: 'price',
      label: t('table.header-column.at-price'),
      style: {
        minWidth: 'auto',
        width: 192,
        maxWidth: 300,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'amount',
      label: t('table.header-column.amount'),
      style: {
        minWidth: 'auto',
        width: 192,
        maxWidth: 300,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
  ];
};

export const columnsTableFooterProductSalesDeliveryDetails = (t) => {
  return [
    [
      {
        isEmptyTableData: true,
        colSpan: 4,
      },
      {
        isLabel: true,
        label: t('typography.total'),
      },
      {
        isValue: true,
        valueKeyName: 'amount',
        formatValue: FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN,
        tableDataStyle: primaryTableDataCalculationNumber,
      },
    ],
  ];
};

export const columnsTableFooterSalesDeliveryDetails = (t) => {
  return [
    {
      name: 'description',
      label: t('table.header-column.memo-colon'),
      style: {
        minWidth: 100,
        width: 360,
        maxWidth: 365,
        height: 56,
        verticalAlign: 'top',
      },
      isUseTableHeaderStyle: false,
    },
  ];
};
