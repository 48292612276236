import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF,
  DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_PURCHASE_INVOICE_PAYMENT_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import arrHelp from '../../helpers/array.helpers';
import formatHelp from '../../helpers/format.helpers';
import parseResponseHelpers from '../../helpers/parse-response.helpers';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import { generalizeAttachmentFromDetails } from './initial-add-attachment';
import {
  generalizeDataAddPurchasePayment,
  generateDataNValidationAddInvoiceFromListPI,
} from './initial-add-purchase-payment';
import {
  initialInvoiceData,
  initialInvoiceValidation,
  initialPaymentData,
  initialPaymentValidation,
} from './initial-data-purchase-payment';

/**
 *
 * @param { object } purchasePaymentOld data detail purchase payment
 * @param { string } customDataKey return name key of data
 * @param { string } customValidationKey return name key of validation
 * @returns
 */
export const generateDataNValidationUpdatePurchasePaymentFromDetailPP = (
  purchasePaymentOld,
  customDataKey = 'paymentData',
  customValidationKey = 'paymentValidation',
) => {
  if (isEmpty(purchasePaymentOld) || typeof purchasePaymentOld !== 'object') {
    return {
      [customDataKey]: initialPaymentData(),
      [customValidationKey]: initialPaymentValidation(),
    };
  }

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const paymentData = usedID.map((id) => {
    const {
      purchase_payment_no,
      purchase_payment_id,
      account_id,
      account_code,
      account_name,
      contact_code,
      supplier_name,
      supplier_address,
      supplier_id,
      supplier_code,
    } = purchasePaymentOld;

    return {
      ...initialPaymentData('', id),
      ...purchasePaymentOld,
      supplier_id_container: {
        contact_id: supplier_id,
        contact_name: supplier_name,
        contact_code: supplier_code || contact_code,
        billing_address: supplier_address,
      },
      purchase_payment_no: purchase_payment_id,
      purchase_payment_no_container: {
        value: purchase_payment_no,
      },
      account_id_container: {
        account_id,
        account_code,
        account_name,
      },
      purchase_payment_id,
    };
  });

  const paymentValidation = usedID.map((id) => {
    return initialPaymentValidation('', id);
  });

  return {
    [customDataKey]: paymentData,
    [customValidationKey]: paymentValidation,
  };
};

/**
 *
 * @param { object } purchasePaymentOld data detail purchase payment
 * @param { string } customDataKey return name key of data
 * @param { string } customValidationKey return name key of validation
 * @returns
 */
export const generateDataNValidationUpdatePurchasePaymentFromPPDetailV2 = ({
  purchasePaymentOld,
  companyUserData = {},
  customDataKey = 'paymentData',
  customValidationKey = 'paymentValidation',
}) => {
  if (isEmpty(purchasePaymentOld) || typeof purchasePaymentOld !== 'object') {
    return {
      [customDataKey]: initialPaymentData(),
      [customValidationKey]: initialPaymentValidation(),
    };
  }

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const latestInvoiceTransactionDate = arrHelp.getLatestDate({
    arr: purchasePaymentOld[DEFAULT_KEY_NAME_PURCHASE_INVOICE_PAYMENT_API_REF],
    dateKeyname: 'transaction_date',
  });

  const paymentData = usedID.map((id) => {
    const {
      purchase_payment_no,
      purchase_payment_id,
      account_id,
      account_code,
      account_name,
      contact_code,
      supplier_name,
      supplier_address,
      supplier_id,
      supplier_code,
    } = purchasePaymentOld;

    return {
      ...initialPaymentData('', id),
      ...purchasePaymentOld,
      attachment: generalizeAttachmentFromDetails({
        dataDetails: purchasePaymentOld,
      }),
      accounting_period: !isEmpty(companyUserData)
        ? companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF]
        : null,
      supplier_id_container: {
        contact_id: supplier_id,
        contact_name: supplier_name,
        contact_code: supplier_code || contact_code,
        billing_address: supplier_address,
      },
      purchase_payment_no: purchase_payment_id,
      purchase_payment_no_container: {
        value: purchase_payment_no,
      },
      account_id_container: {
        account_id,
        account_code,
        account_name,
      },
      purchase_payment_id,
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        latestInvoiceTransactionDate ||
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
          documentData: purchasePaymentOld,
        }),
      // bypassing QA freak XD
      todays_date: undefined,
      ...parseResponseHelpers.tagContainerInputFromDetails(purchasePaymentOld),
    };
  });

  const paymentValidation = usedID.map((id) => {
    return initialPaymentValidation('', id);
  });

  return {
    [customDataKey]: paymentData,
    [customValidationKey]: paymentValidation,
  };
};

/**
 *
 * @param { object } purchasePaymentOld data detail purchase payment
 * @param { string } customDataKey return name key of data
 * @param { string } customValidationKey return name key of validation
 * @returns
 */
export const generateDataNValidationUpdateInvoicePPFromDetailPP = (
  purchasePaymentOld,
  customDataKey = 'invoiceData',
  customValidationKey = 'invoiceValidation',
) => {
  if (
    isEmpty(purchasePaymentOld) ||
    typeof purchasePaymentOld !== 'object' ||
    (typeof purchasePaymentOld === 'object' && !purchasePaymentOld.purchase_invoice_payment)
  ) {
    return {
      [customDataKey]: initialInvoiceData(),
      [customValidationKey]: initialInvoiceValidation(),
    };
  }

  const { purchase_invoice_payment } = purchasePaymentOld;

  const usedID = arrHelp.generateArrWithFunc(purchase_invoice_payment.length, uuidv4);

  /**
   * amount, last user input on add purchase payment
   * purchase_invoice_amount, total amount that must billed by user, require just for total amount
   * amount_available, total remaining amount that must bill by user
   * amount_available_const maximum input amount user
   *      on update purchase payment, amount_available_const result sum from amount_available by amount
   * amount_input from inputting amount by user
   *
   * isFromDetailPurchasePayment, is for differentiator about new invoice on autocomplete
   */
  const invoiceData = usedID.map((id, index) => {
    const { amount, amount_available, purchase_invoice_amount } = purchase_invoice_payment[index];

    const amount_available_const =
      formatHelp.reverseCurrencyFormatWithRegex(amount) +
      formatHelp.reverseCurrencyFormatWithRegex(amount_available);

    return {
      ...initialInvoiceData('', id),
      ...purchase_invoice_payment[index],
      amount_input: amount,
      amount: formatHelp.currencyFormatWithRegex(purchase_invoice_amount),
      amount_available: formatHelp.currencyFormatWithRegex(amount_available),
      amount_available_const,
      isFromDetailPurchasePayment: true,
    };
  });

  const invoiceValidation = usedID.map((id) => {
    return initialInvoiceValidation('', id);
  });

  return {
    [customDataKey]: invoiceData,
    [customValidationKey]: invoiceValidation,
  };
};

/**
 *
 * @param { object } purchasePaymentOld data detail purchase payment
 * @param { string } customDataKey return name key of data
 * @param { string } customValidationKey return name key of validation
 * @returns
 */
export const generateDataNValidationUpdateInvoicePPFromDetailPPV2 = ({
  purchasePaymentOld,
  customDataKey = 'invoiceData',
  customValidationKey = 'invoiceValidation',
  keynameInvoicePurchasePayment = DEFAULT_KEY_NAME_PURCHASE_INVOICE_PAYMENT_API_REF,
}) => {
  if (
    isEmpty(purchasePaymentOld) ||
    typeof purchasePaymentOld !== 'object' ||
    (typeof purchasePaymentOld === 'object' && !purchasePaymentOld[keynameInvoicePurchasePayment])
  ) {
    return {
      [customDataKey]: initialInvoiceData(),
      [customValidationKey]: initialInvoiceValidation(),
    };
  }

  const invoicePurchasePayment = purchasePaymentOld[keynameInvoicePurchasePayment];

  const usedID = arrHelp.generateArrWithFunc(invoicePurchasePayment.length, uuidv4);

  /**
   * amount, last user input on add purchase payment
   * purchase_invoice_amount, total amount that must billed by user, require just for total amount
   * amount_available, total remaining amount that must bill by user
   * amount_available_const maximum input amount user
   *      on update purchase payment, amount_available_const result sum from amount_available by amount
   * amount_input from inputting amount by user
   *
   * isFromDetailPurchasePayment, is for differentiator about new invoice on autocomplete
   */
  const invoiceData = usedID.map((id, index) => {
    const { amount, amount_available, purchase_invoice_amount } = invoicePurchasePayment[index];

    const amount_available_const = Number(amount) + Number(amount_available);

    return {
      ...initialInvoiceData('', id),
      ...invoicePurchasePayment[index],
      amount_input: Number(amount),
      amount: Number(purchase_invoice_amount),
      amount_available: Number(amount_available),
      amount_available_const,
      isFromDetailPurchasePayment: true,
    };
  });

  const invoiceValidation = usedID.map((id) => {
    return initialInvoiceValidation('', id);
  });

  return {
    [customDataKey]: invoiceData,
    [customValidationKey]: invoiceValidation,
  };
};

/**
 *
 * @param   { object }      param0  using keys object as params function
 *
 *
 * @param   { array }       currentInvoicePurchasePaymentData          current of purchase invoice data on purchase payment
 * @param   { array }       currentInvoicePurchasePaymentValidation    current purchase invoice validation on purchase payment
 * @param   { array }       invoiceList                             after hitting endpoint invoice list based on contact id you can passing on it
 * @param   { string }      customDataKey                           return keyname of data
 * @param   { string }      customValidationKey                     return keyname of validation
 *
 * @returns
 *      object that contained validation and data
 *      that combine from invoice list to current invoice list
 *      validation determined by invoice data purchase payment, so dont worry about validation id
 */
export const generateDataNvalidationInvoicePurchasePaymentFromPurchaseInvoiceList = ({
  invoiceList = [],
  currentInvoicePurchasePaymentData = [],
  currentInvoicePurchasePaymentValidation = [],
  customDataKey = 'invoiceData',
  customValidationKey = 'invoiceValidation',
}) => {
  if (!Array.isArray(invoiceList) || !Array.isArray(currentInvoicePurchasePaymentData)) {
    return {
      [customDataKey]: currentInvoicePurchasePaymentData,
      [customValidationKey]: currentInvoicePurchasePaymentValidation,
    };
  }

  const { invoiceData: invoiceDataGen, invoiceValidation: invoiceValidationGen } =
    generateDataNValidationAddInvoiceFromListPI(invoiceList);

  const purchaseInvoiceExistedID = new Set(
    currentInvoicePurchasePaymentData.map((purchaseInvoice) => purchaseInvoice.purchase_invoice_id),
  );
  const newInvoiceData = [
    ...currentInvoicePurchasePaymentData,
    ...invoiceDataGen.filter(
      (purchaseInvoice) => !purchaseInvoiceExistedID.has(purchaseInvoice.purchase_invoice_id),
    ),
  ];

  // remove unused validation items, following invoice data that unique purchase invoice id
  const newInvoiceValidation = arrHelp.combineMultipleDataObjectInArray(
    newInvoiceData,
    currentInvoicePurchasePaymentValidation.concat(invoiceValidationGen),
    'id',
    false,
  );

  return {
    [customDataKey]: newInvoiceData,
    [customValidationKey]: newInvoiceValidation,
  };
};

/**
 *
 * @param { array } dataAddPayment data input payment from form
 * @param { array } dataAddInvoice data input invoice form input user
 * @returns
 *      generalized data purchase payment before hitting BE
 */
export const generalizeDataUpdatePurchasePayment = (dataUpdatePayment, dataUpdateInvoice) => {
  const { purchase_payment_id, purchase_payment_no_container } = dataUpdatePayment[0];

  // get string of number purchase payment
  const { value: purchase_payment_no } = purchase_payment_no_container;

  return {
    purchase_payment_id,
    purchase_payment_no,
    ...generalizeDataAddPurchasePayment(dataUpdatePayment, dataUpdateInvoice),
  };
};
