import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  LOCAL_STORAGE_USER_COMPANY,
  LOCAL_STORAGE_USER_DETAIL,
} from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  SLICE_NAME_COMPANY_LIST_AUTOCOMPLETE,
  SLICE_NAME_USER_COMPANY,
  SLICE_NAME_USER_PROFILE_DATA,
  STATUS_REQUEST_BASE_FAILED,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_BASE_PENDING,
  STATUS_REQUEST_BASE_SUCCESS,
  STATUS_REQUEST_LIST_USER_COMPANY_AUTOCOMPLETE_FAILED,
  STATUS_REQUEST_LIST_USER_COMPANY_AUTOCOMPLETE_PENDING,
  STATUS_REQUEST_LIST_USER_COMPANY_AUTOCOMPLETE_SUCCESS,
  STATUS_REQUEST_LIST_USER_COMPANY_FAILED,
  STATUS_REQUEST_LIST_USER_COMPANY_PENDING,
  STATUS_REQUEST_LIST_USER_COMPANY_SUCCESS,
} from '../../utils/constants/store.constant';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import {
  detailUser,
  getListCompanyUser,
  getListCompanyUserAutocomplete,
  getListCompanyUserRefresh,
  updateUser,
} from './user.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  [SLICE_NAME_USER_PROFILE_DATA]: LocalStorage.get(LOCAL_STORAGE_USER_DETAIL),
  [SLICE_NAME_USER_COMPANY]: LocalStorage.get(LOCAL_STORAGE_USER_COMPANY),
  [SLICE_NAME_COMPANY_LIST_AUTOCOMPLETE]: null,
};

const userSlice = createSlice({
  name: NAME_STORE.USER,
  initialState,
  reducers: {
    detailUser,
    updateUser,
    getListCompanyUser,
    clearUserCompany: (state, action) => {
      LocalStorage.remove(LOCAL_STORAGE_USER_COMPANY);

      if (action && action.payload && action.payload.removeLocalStorageOnly) return state;

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        company: null,
      };
    },
    clearUserProfile: (state, action) => {
      LocalStorage.remove(LOCAL_STORAGE_USER_DETAIL);

      if (action && action.payload && action.payload.removeLocalStorageOnly) return state;

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        profile: null,
      };
    },
    clearUserSliceData: (state, action) => {
      const { sliceName } = action.payload;

      if (!sliceName) return;

      return {
        ...state,
        [sliceName]: null,
      };
    },
    filterDataUserSliceItem: (state, action) => {
      return sliceReduceHelper.filterDataSliceItemByKeyname(state, action);
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      [SLICE_NAME_USER_PROFILE_DATA]: LOCAL_STORAGE_USER_DETAIL,
      [SLICE_NAME_USER_COMPANY]: LOCAL_STORAGE_USER_COMPANY,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(detailUser.fulfilled, (state, action) => {
      state.statusRequest = STATUS_REQUEST_BASE_SUCCESS;
      state.profile = action.payload.profile;
    }),
      builder.addCase(detailUser.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_BASE_PENDING;
      }),
      builder.addCase(detailUser.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_BASE_FAILED;
      }),
      builder.addCase(updateUser.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_BASE_SUCCESS;
        state.profile = action.payload.profile;
      }),
      builder.addCase(updateUser.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_BASE_PENDING;
      }),
      builder.addCase(updateUser.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_BASE_FAILED;
      }),
      // action listing users on company
      builder.addCase(getListCompanyUser.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_USER_COMPANY_SUCCESS;
        state.company = action.payload.company;
      }),
      builder.addCase(getListCompanyUser.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_USER_COMPANY_PENDING;
      }),
      builder.addCase(getListCompanyUser.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_USER_COMPANY_FAILED;
        state.company = null;
      }),
      builder.addCase(getListCompanyUserRefresh.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_USER_COMPANY_SUCCESS;
        state.company = action.payload.company;
      }),
      builder.addCase(getListCompanyUserRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_USER_COMPANY_PENDING;
      }),
      builder.addCase(getListCompanyUserRefresh.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_USER_COMPANY_FAILED;
      }),
      // action listing company on autocomplete
      builder.addCase(getListCompanyUserAutocomplete.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_USER_COMPANY_AUTOCOMPLETE_SUCCESS;
        state[SLICE_NAME_COMPANY_LIST_AUTOCOMPLETE] =
          action.payload[SLICE_NAME_COMPANY_LIST_AUTOCOMPLETE];
      }),
      builder.addCase(getListCompanyUserAutocomplete.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_USER_COMPANY_AUTOCOMPLETE_FAILED;
      }),
      builder.addCase(getListCompanyUserAutocomplete.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_USER_COMPANY_AUTOCOMPLETE_PENDING;
        state[SLICE_NAME_COMPANY_LIST_AUTOCOMPLETE] = null;
      });
  },
});

export const { reducer, actions } = userSlice;

export const { clearUserCompany, clearUserProfile, clearUserSliceData, filterDataUserSliceItem } =
  actions;

export default reducer;
