import formatHelp from '../../helpers/format.helpers';

// column report capital change list that handling table head with translation
export const columnsTableReportCapitalChange = (t) => {
  return [
    {
      name: 'account_name',
      label: t('dashboard.report.capital-change.table.header-column.account'),
      format: 'normal-generalized',
      width: 1000,
      generalizedData: (accountContainer) =>
        formatHelp.formatAccountNameWithAccountCode(accountContainer),
    },
    {
      name: 'beginning_balance',
      align: 'right',
      label: t('dashboard.report.capital-change.table.header-column.beginning-balance'),
      format: 'normal-currency',
      width: 190,
      maxWidth: 200,
    },
    {
      name: 'debit_amount',
      align: 'right',
      label: t('dashboard.report.capital-change.table.header-column.debit-amount'),
      format: 'normal-currency',
      width: 190,
      maxWidth: 200,
    },
    {
      name: 'credit_amount',
      align: 'right',
      label: t('dashboard.report.capital-change.table.header-column.credit-amount'),
      format: 'normal-currency',
      width: 190,
      maxWidth: 200,
    },
    {
      name: 'final_balance',
      align: 'right',
      label: t('dashboard.report.capital-change.table.header-column.final-balance'),
      format: 'normal-currency',
      width: 190,
      maxWidth: 200,
    },
  ];
};

export const nameKeyOnReportCapitalChange = 'ekuitas';

export const unusedKeyNameChildrenReportCapitalChangeData = [
  'search_isShowMessage',
  'search_from_date',
  'search_to_date',
  'search_balance_null',
  'data',
];

export const correspondingNewKeyNamesAPIWithOldKeyNamesEachAccountReportCapitalChange = {
  begining_amount: 'beginning_balance',
  ending_amount: 'final_balance',
  movement_credit_amount: 'credit_amount',
  movement_debit_amount: 'debit_amount',
};
