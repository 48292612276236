import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import { DEFAULT_DISCOUNT_PERCENTAGE_TYPE } from '../../default/discount-type.default';
import {
  hasImplementedUnitProductUnit,
  keynamesImpelementProductUnit,
} from '../../default/unit-product.default';
import validation from '../../helpers/validation.helpers';
import { DEFAULT_CALCULATION_NUMBER } from '../default/number.default';

const initialID = uuidV4();

export const plainSalesQuotationDataWithoutID = {
  customer_id: null,
  customer_name: '',
  customer_id_container: null,
  customer_address: '',
  transaction_date: moment(),
  expired_date: null,
  reference_number: '',
  reference_number_container: null,
  description: '',
  amount: 0,
  amount_const: 0,
  accounting_period: null,
  sales_agent_id: '',
  sales_agent_id_container: '',
  sales_agent_name: '',
  discount_account_id: '',
  discount_account_id_container: '',
  discount_type: DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
  discount_percentage: '',
  discount_nominal: '',
  expense_account_id: '',
  expense_account_id_container: '',
  expense_value: '',
  down_payment_account_id: '',
  down_payment_account_id_container: '',
  down_payment_value: '',
  warehouse_id: '',
  warehouse_id_container: '',
  attachment: [],
  uuid_attachment: null,
  minDateInputRef: null,
  tag: [],
  tagContainer: [],
  tagNameInput: '',
};

export const plainSalesQuotationValidationWithoutID = {
  customer_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  customer_address: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  transaction_date: {
    error: false,
    isValid: (value, minDateInputRef) => {
      if (!minDateInputRef) {
        return validation.notnull(value);
      }

      return moment(new Date(minDateInputRef)).isSameOrBefore(new Date(value), 'D');
    },
  },
  expired_date: {
    error: false,
    isValid: (value, transactionDate) => {
      if (value && transactionDate) {
        return moment(new Date(value)).isSameOrAfter(new Date(transactionDate));
      }

      return validation.nullable(value);
    },
  },
  reference_number: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  sales_agent_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  discount_account_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  expense_account_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  down_payment_account_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

/**
 * quantity represent value for viewing user
 * quantity_origin is value that after changning on product unit
 * quantity_comparison is comparison for value quantity and quantity origin
 * quantity_comparison_const_props, appear for update action,
 *  usage: preserve quantity comparison data
 * unit_origin is value for represent unit before changing to new unit
 * unit_destination is value unit after change to new unit, this to view for user
 */
export const plainProductSalesQuotationDataWithoutID = {
  product_id: null,
  product_id_container: null,
  product_description: '',
  product_name: '',
  product_unit: '',
  quantity: '',
  quantity_origin: '',
  quantity_comparison: 1,
  quantity_comparison_const_props: null,
  quantity_const: 0,
  quantity_available_with_unit: '',
  unit: '',
  unit_origin: '',
  unit_destination: '',
  price: '',
  amount: DEFAULT_CALCULATION_NUMBER,
};

export const plainProductSalesQuotationValidationWithoutID = {
  product_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  product_description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  quantity: {
    error: false,
    isValid: (value) => validation.isNumberGreaterOrEqualThan(value, 1),
  },
  unit_destination: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  price: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const initialDataProduct = (additionalID = '', newID = '') => {
  const usedID = newID ? newID : initialID + additionalID;

  return {
    id: usedID,
    ...plainProductSalesQuotationDataWithoutID,
  };
};

export const initialValidationProduct = (additionalID = '', newID = '') => {
  const usedID = newID ? newID : initialID + additionalID;

  return {
    id: usedID,
    ...plainProductSalesQuotationValidationWithoutID,
  };
};

export const initialDataSalesQuotation = (additionalID = '', newID = '') => {
  const usedID = newID ? newID : initialID + additionalID;

  return {
    id: usedID,
    ...plainSalesQuotationDataWithoutID,
  };
};

export const initialValidationSalesQuotation = (additionalID = '', newID = '') => {
  const usedID = newID ? newID : initialID + additionalID;

  return {
    id: usedID,
    ...plainSalesQuotationValidationWithoutID,
  };
};

export const allowKeysDataProduct = ['product_id', 'product_description', 'quantity', 'price'];

export const allowKeysDataSalesQuotation = [
  'customer_id',
  'customer_name',
  'customer_address',
  'reference_number',
  'transaction_date',
  'expired_date',
  'description',
  'product',
  'amount',
  'sales_agent_id',
  'sales_agent_name',
  'discount_account_id',
  'discount_type',
  'discount_percentage',
  'discount_nominal',
  'expense_account_id',
  'expense_value',
  'down_payment_account_id',
  'down_payment_value',
  'warehouse_id',
  'uuid_attachment',
  'tag',
];

export const allowKeysDataProductSalesQuotation = [
  'product_id',
  'product_description',
  'unit_origin',
  'unit_destination',
  'quantity',
  'quantity_origin',
  'quantity_available',
  'price',
].filter((key) =>
  !hasImplementedUnitProductUnit ? !keynamesImpelementProductUnit.includes(key) : true,
);

export const relationalAdditionalCostValueNaccountForSalesQuotation = {
  discount_percentage: ['discount_account_id', 'discount_type'],
  expense_value: 'expense_account_id',
  down_payment_value: 'down_payment_account_id',
};

export const salesQuotationCalculationNumberKeyNames = [
  'discount_percentage',
  'discount_nominal',
  'discount_percentage',
  'expense_value',
  'down_payment_value',
  'amount',
];

export const productSalesQuotationCalculationNumberKeyNames = ['quantity', 'price', 'amount'];
