import { createAsyncThunk } from '@reduxjs/toolkit';

import assetService from '../../services/API/asset/asset.service';
import {
  REACT_APP_ASSET_CATEGORY_URL,
  REACT_APP_ASSET_MANAGEMENT_URL,
  REACT_APP_DATA_ASSET_DETAILS_TEMPLATE_URL,
} from '../../utils/configs/url.config';
import {
  ASSET_CATEGORY_ASYNC_THUNK_TYPE,
  ASSET_MANAGEMENT_ASYNC_THUNK_TYPE,
} from '../../utils/constants/action-type.constant';
import {
  LOCAL_STORAGE_ASSET_CATEGORY,
  LOCAL_STORAGE_ASSET_CATEGORY_DETAIL,
  LOCAL_STORAGE_ASSET_MANAGEMENT,
  LOCAL_STORAGE_ASSET_MANAGEMENT_DETAIL,
} from '../../utils/constants/storage.constant';
import {
  SLICE_NAME_ASSET_CATEGORY_DATA,
  SLICE_NAME_ASSET_MANAGEMENT_DATA,
  STATUS_REQUEST_LIST_ASSET_CATEGORY_FAILED,
  STATUS_REQUEST_LIST_ASSET_CATEGORY_PENDING,
  STATUS_REQUEST_LIST_ASSET_CATEGORY_SUCCESS,
  STATUS_REQUEST_LIST_ASSET_MANAGEMENT_FAILED,
  STATUS_REQUEST_LIST_ASSET_MANAGEMENT_PENDING,
  STATUS_REQUEST_LIST_ASSET_MANAGEMENT_SUCCESS,
} from '../../utils/constants/store.constant';
import {
  ASSET_CATEGORY_ID_KEY_NAME_API_REF,
  DEFAULT_KEY_NAME_ASSET_ID_API_REF,
} from '../../utils/default/object-keyname.default';
import {
  DEFAULT_ASSET_CATEGORY_SEARCH_FE_PARAMS,
  DEFAULT_ASSET_MANAGEMENT_SEARCH_FE_PARAMS,
  DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
} from '../../utils/default/params.default';
import { DEFAULT_STATUS_PAGE_DETAIL_ASSET_CATEGORY } from '../../utils/default/status-page.default';
import objHelper from '../../utils/helpers/object.helper';
import strHelp from '../../utils/helpers/string.helpers';
import {
  creatorAddAction,
  creatorAddActionWithPreviewButton,
  creatorAddActionWithPreviewButtonGenerateLink,
  creatorDeleteAction,
  creatorDetailAction,
  creatorListActionDynamicSliceNameNproperties,
  creatorListRefreshAction,
  creatorUpdateAction,
} from '../creator-action/creator-action.helper';

/**
 * asset category actions
 *
 */

export const getListAssetCategory = createAsyncThunk(
  ASSET_CATEGORY_ASYNC_THUNK_TYPE.list,
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_ASSET_CATEGORY_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_ASSET_CATEGORY_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_ASSET_CATEGORY_FAILED,
      slicePayloadKeyname = SLICE_NAME_ASSET_CATEGORY_DATA,
      localStorageName = LOCAL_STORAGE_ASSET_CATEGORY,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...params
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params: params,
      apiService: assetService.getListAssetCategory,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: ASSET_CATEGORY_ID_KEY_NAME_API_REF,
      slicePayloadKeyname,
      errorMessage: 'error.list-data.asset-category',
    });
  },
);

export const getListAssetCategoryRefresh = createAsyncThunk(
  ASSET_CATEGORY_ASYNC_THUNK_TYPE.listRefresh,
  async (discardKeySearch = [], thunkAPI) => {
    return creatorListRefreshAction(
      thunkAPI,
      assetService.getListAssetCategory,
      LOCAL_STORAGE_ASSET_CATEGORY,
      'assetCategoryData',
      discardKeySearch,
      DEFAULT_ASSET_CATEGORY_SEARCH_FE_PARAMS,
      'error.list-data.asset-category',
    );
  },
);

export const getDetailAssetCategory = createAsyncThunk(
  ASSET_CATEGORY_ASYNC_THUNK_TYPE.detail,
  async (assetCategoryID, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      assetCategoryID,
      assetService.getDetailAssetCategory,
      LOCAL_STORAGE_ASSET_CATEGORY_DETAIL,
      'assetCategoryDetail',
      'error.detail.asset-category',
    );
  },
);

export const addAssetCategory = createAsyncThunk(
  ASSET_CATEGORY_ASYNC_THUNK_TYPE.add,
  async (bodyAddAssetCategory, thunkAPI) => {
    return await creatorAddActionWithPreviewButton(
      thunkAPI,
      bodyAddAssetCategory,
      assetService.addAssetCategory,
      'success.add.asset-category',
      'error.add.asset-category',
      {},
      {},
      'asset_category_id',
      REACT_APP_ASSET_CATEGORY_URL,
      { statusPage: DEFAULT_STATUS_PAGE_DETAIL_ASSET_CATEGORY },
    );
  },
);

export const updateAssetCategory = createAsyncThunk(
  ASSET_CATEGORY_ASYNC_THUNK_TYPE.update,
  async (bodyUpdateAssetCategory, thunkAPI) => {
    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdateAssetCategory,
      assetService.updateAssetCategory,
      'success.update.asset-category',
      'error.update.asset-category',
      {},
      {},
    );
  },
);

export const deleteAssetCategory = createAsyncThunk(
  ASSET_CATEGORY_ASYNC_THUNK_TYPE.delete,
  async (paramsDeleteAssetCategory, thunkAPI) => {
    const { asset_category_name } = paramsDeleteAssetCategory;

    return creatorDeleteAction(
      thunkAPI,
      paramsDeleteAssetCategory,
      assetService.deleteAssetCategory,
      'success.delete.asset-category',
      'error.delete.asset-category',
      {},
      { asset_category_name },
    );
  },
);

/**
 *
 * asset management actions
 *
 */

export const getListAssetManagement = createAsyncThunk(
  ASSET_MANAGEMENT_ASYNC_THUNK_TYPE.list,
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_ASSET_MANAGEMENT_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_ASSET_MANAGEMENT_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_ASSET_MANAGEMENT_FAILED,
      slicePayloadKeyname = SLICE_NAME_ASSET_MANAGEMENT_DATA,
      localStorageName = LOCAL_STORAGE_ASSET_MANAGEMENT,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...params
    },
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params,
      apiService: assetService.getListAssetManagement,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: 'asset_management_id',
      slicePayloadKeyname,
      errorMessage: 'error.list-data.asset-management',
    });
  },
);

export const getListAssetManagementRefresh = createAsyncThunk(
  ASSET_MANAGEMENT_ASYNC_THUNK_TYPE.listRefresh,
  async (discardKeySearch = [], thunkAPI) => {
    return creatorListRefreshAction(
      thunkAPI,
      assetService.getListAssetManagement,
      LOCAL_STORAGE_ASSET_MANAGEMENT,
      'assetManagementData',
      discardKeySearch,
      DEFAULT_ASSET_MANAGEMENT_SEARCH_FE_PARAMS,
      'error.list-data.asset-management',
    );
  },
);

export const getDetailAssetManagement = createAsyncThunk(
  ASSET_MANAGEMENT_ASYNC_THUNK_TYPE.detail,
  async (assetManagementID, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      assetManagementID,
      assetService.getDetailAssetManagement,
      LOCAL_STORAGE_ASSET_MANAGEMENT_DETAIL,
      'assetManagementDetail',
      'error.detail.asset-management',
    );
  },
);

export const deleteAssetManagement = createAsyncThunk(
  ASSET_MANAGEMENT_ASYNC_THUNK_TYPE.delete,
  async (paramsDeleteAssetManagement, thunkAPI) => {
    const { asset_name } = paramsDeleteAssetManagement;

    return creatorDeleteAction(
      thunkAPI,
      paramsDeleteAssetManagement,
      assetService.deleteAssetManagement,
      'success.delete.asset-management',
      'error.delete.asset-management',
      {},
      { asset_name },
    );
  },
);

export const addAssetManagement = createAsyncThunk(
  ASSET_MANAGEMENT_ASYNC_THUNK_TYPE.add,
  async (bodyAddAssetManagement, thunkAPI) => {
    return await creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: bodyAddAssetManagement,
      serviceFunc: assetService.addAssetManagement,
      successMessage: 'success.add.asset-management',
      errorMessage: 'error.add.asset-management',
      baseContentUrl: REACT_APP_ASSET_MANAGEMENT_URL,
      generateButtonLink: (responseResult) => {
        const templateLinkDetails = {
          [DEFAULT_KEY_NAME_ASSET_ID_API_REF]: objHelper.findAllValuesByKey(
            responseResult,
            DEFAULT_KEY_NAME_ASSET_ID_API_REF,
          ),
        };

        return strHelp.templateString(
          REACT_APP_DATA_ASSET_DETAILS_TEMPLATE_URL,
          templateLinkDetails,
        );
      },
      isShowPreviewButton:
        bodyAddAssetManagement.isShowPreviewButton !== undefined
          ? bodyAddAssetManagement.isShowPreviewButton
          : true,
    });
  },
);

export const updateAssetManagement = createAsyncThunk(
  ASSET_MANAGEMENT_ASYNC_THUNK_TYPE.update,
  async (bodyUpdateAssetManagement, thunkAPI) => {
    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdateAssetManagement,
      assetService.updateAssetManagement,
      'success.update.asset-management',
      'error.update.asset-management',
      {},
      {},
    );
  },
);

export const saleAssetManagement = createAsyncThunk(
  'asset/asset-management-sale',
  async (bodySaleAssetManagement, thunkAPI) => {
    return await creatorAddAction(
      thunkAPI,
      bodySaleAssetManagement,
      assetService.saleAssetManagement,
      'success.add.sale-asset-management',
      'error.add.sale-asset-management',
      {},
      {},
    );
  },
);

export const revertAssetManagement = createAsyncThunk(
  'asset/asset-management-revert',
  async (paramsRevertAssetManagement, thunkAPI) => {
    return await creatorUpdateAction(
      thunkAPI,
      paramsRevertAssetManagement,
      assetService.revertAssetManagement,
      'success.update.revert-asset-management',
      'error.update.revert-asset-management',
    );
  },
);
