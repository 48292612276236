import * as React from 'react';
function SvgTrash(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M2.5 5h1.667m0 0H17.5M4.167 5v11.667a1.667 1.667 0 001.666 1.666h8.334a1.667 1.667 0 001.666-1.666V5H4.167zm2.5 0V3.333a1.667 1.667 0 011.666-1.666h3.334a1.667 1.667 0 011.666 1.666V5m-5 4.167v5m3.334-5v5'
        stroke='#667085'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
export default SvgTrash;
