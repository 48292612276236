import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import validation from '../../helpers/validation.helpers';

const initialID = uuidv4();

/**
 * amount           total debit note billing
 */
export const plainPurchaseReceivablePaymentDataWithoutID = {
  supplier_id: '',
  supplier_id_container: null,
  supplier_name: '',
  supplier_address: '',
  transaction_date: '',
  transaction_no: '',
  transaction_no_container: null,
  account_receivable: '',
  account_receivable_container: null,
  uuid_attachment: '',
  description: '',
  attachment: [],
  amount: 0,
  minDateInputRef: null,
  tag: [],
  tagContainer: [],
  tagNameInput: '',
};

/**
 * amount is total debit note billing
 * amount available is remaining debit note billing
 * amount available const is maximum input user input for billing debit note
 * amount input is user input for debit note billing
 */
export const plainDebitNotePurchaseReceivablePaymentWithoutID = {
  debit_note_id: '',
  debit_note_id_container: '',
  description: null,
  amount: '',
  amount_available: '',
  amount_available_const: 0,
  amount_input: '',
};

export const plainPurchaseReceivablePaymentValidationWithoutID = {
  supplier_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  supplier_name: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  supplier_address: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  transaction_date: {
    error: false,
    isValid: (value, minDateInputRef, today) => {
      if (minDateInputRef !== undefined && today !== undefined) {
        return (
          (moment(value).isSameOrAfter(moment(minDateInputRef), 'D') || !minDateInputRef) &&
          moment(value).isSameOrBefore(moment(today), 'D')
        );
      }

      return validation.notnull(value);
    },
  },
  transaction_no: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  account_receivable: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
};

export const plainDebitNotePurchaseReceivablePaymentValidationWithoutID = {
  debit_note_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  amount_input: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
};

export const initialDataPurchaseReceivablePayment = (newID = '') => {
  return {
    id: newID || initialID,
    ...plainPurchaseReceivablePaymentDataWithoutID,
    transaction_date: new Date(),
    today: new Date(),
  };
};

export const initialValidationPurchaseReceivablePayment = (newID = '') => {
  return {
    id: newID || initialID,
    ...plainPurchaseReceivablePaymentValidationWithoutID,
  };
};

export const initialDataDebitNotePurchaseReceivablePayment = (newID = '') => {
  return {
    id: newID || initialID,
    ...plainDebitNotePurchaseReceivablePaymentWithoutID,
  };
};

export const initialValidationDebitNotePurchaseReceivablePayment = (newID = '') => {
  return {
    id: newID || initialID,
    ...plainDebitNotePurchaseReceivablePaymentValidationWithoutID,
  };
};

export const allowKeysDataPurchaseReceivablePayment = [
  'supplier_id',
  'supplier_address',
  'supplier_name',
  'account_receivable',
  'transaction_date',
  'transaction_no',
  'description',
  'uuid_attachment',
  'debit_note',
  'tag',
];

export const allowKeysDataDebitNotePurchaseReceivablePayment = [
  'debit_note_id',
  'description',
  'amount',
];

export const purchaseReceivablePaymentCalculationNumberKeyNames = ['amount'];

export const debitNotePurchaseReceivablePaymentCalculationNumberKeyNames = [
  'amount',
  'amount_available',
  'amount_paid',
];
