import axios from 'axios';
import { isEmpty } from 'lodash';

import {
  REACT_APP_API_URL_JOURNAL_ENTRY,
  REACT_APP_API_URL_JOURNAL_ENTRY_UPLOAD,
  REACT_APP_API_URL_JOURNAL_ENTRY_UPLOAD_PROGRESS,
} from '../../../utils/configs/api.config';
import { DEFAULT_FILE_TYPE_CSV } from '../../../utils/default/file-type.default';
import arrHelp from '../../../utils/helpers/array.helpers';
import { authHeader } from '../../../utils/helpers/auth-header.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import {
  creatorDetailService,
  creatorDetailServiceV2,
  creatorListService,
  creatorMultipartFormDataService,
} from '../creator-services/creator-services.helper';

/**
 * Attention!!
 * name of journal entry in backend usually is General Journal
 */

// listing all journal entries in company
const listJournalEntry = async (paramsGetListJournalEntry) => {
  console.log(paramsGetListJournalEntry);
  return await creatorListService(REACT_APP_API_URL_JOURNAL_ENTRY, paramsGetListJournalEntry, [
    'page',
    'paginate',
    'transaction_no',
    'transaction_date',
    'transaction_value',
    'to_date',
    'from_date',
  ]);
};

const detailJournalEntry = async (transactionID) => {
  return await creatorDetailService(REACT_APP_API_URL_JOURNAL_ENTRY, transactionID);
};

// service for add journal entry data
const addJournalEntry = (bodyAddJournalEntry) => {
  const { Authorization } = authHeader();

  if (!Authorization) return null;

  // filtering data body add journal entry
  // for remove unused data
  bodyAddJournalEntry = objHelper.filterKeyObj(
    bodyAddJournalEntry,
    [],
    ['transaction_date', 'transaction_description', 'account', 'transaction_no'],
  );

  bodyAddJournalEntry.account = bodyAddJournalEntry.account.map((accountData) => {
    return objHelper.filterKeyObj(
      accountData,
      [],
      [
        'account_id',
        'account_category_id',
        'category_name',
        'account_name',
        'account_code',
        'account_balance',
        'account_normal_balance',
        'account_description',
      ],
    );
  });

  if (!isEmpty(bodyAddJournalEntry)) {
    bodyAddJournalEntry = objHelper.filteringExistedValue(bodyAddJournalEntry);
  }

  if (Array.isArray(bodyAddJournalEntry.account) && bodyAddJournalEntry.account.length) {
    bodyAddJournalEntry.account = arrHelp.filterExistedValueOnObjItem(bodyAddJournalEntry.account, [
      'account_id',
    ]);
  }

  return axios({
    method: 'POST',
    url: REACT_APP_API_URL_JOURNAL_ENTRY,
    data: {
      ...bodyAddJournalEntry,
    },
    headers: {
      Authorization,
    },
  });
};

// service for update journal entry data
const updateJournalEntry = (bodyUpdateJournalEntry) => {
  const { Authorization } = authHeader();
  const { transaction_id } = bodyUpdateJournalEntry;

  if (!Authorization || !transaction_id) return null;

  // filtering data body add journal entry
  // for remove unused data
  bodyUpdateJournalEntry = objHelper.filterKeyObj(
    bodyUpdateJournalEntry,
    [],
    ['transaction_date', 'transaction_description', 'account', 'transaction_no'],
  );

  bodyUpdateJournalEntry.account = bodyUpdateJournalEntry.account.map((accountData) => {
    return objHelper.filterKeyObj(
      accountData,
      [],
      [
        'account_id',
        'account_category_id',
        'category_name',
        'account_name',
        'account_code',
        'account_balance',
        'account_normal_balance',
        'account_description',
      ],
    );
  });

  return axios({
    method: 'PUT',
    url: `${REACT_APP_API_URL_JOURNAL_ENTRY}/${transaction_id}`,
    data: {
      ...bodyUpdateJournalEntry,
    },
    headers: {
      Authorization,
    },
  });
};

// service for delete journal entry data
const deleteJournalEntry = ({ transaction_id }) => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  return axios({
    method: 'DELETE',
    url: `${REACT_APP_API_URL_JOURNAL_ENTRY}/${transaction_id}`,
    headers: {
      Authorization,
    },
  });
};

const importJournalEntry = async (params) => {
  return await creatorMultipartFormDataService({
    endpoint: REACT_APP_API_URL_JOURNAL_ENTRY_UPLOAD,
    bodyData: params,
    grabToNewFormData: true,
    formDataKeyname: DEFAULT_FILE_TYPE_CSV,
  });
};

const getImportJournalEntryProgress = async (params) => {
  return await creatorDetailServiceV2(REACT_APP_API_URL_JOURNAL_ENTRY_UPLOAD_PROGRESS, params);
};

const journalEntryServices = {
  listJournalEntry,
  detailJournalEntry,
  addJournalEntry,
  updateJournalEntry,
  deleteJournalEntry,
  importJournalEntry,
  getImportJournalEntryProgress,
};

export default journalEntryServices;
