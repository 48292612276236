/**
 * @param { function } t function for handling translation
 * @returns
 *      initial search purchase invoice data with translation
 */
export const initialSearchPurchaseInvoice = () => {
  return {
    from_date: null,
    to_date: null,
    transaction_no: '',
    supplier_name: '',
    supplier_name_container: null,
    status: [],
    statusContainer: [],
  };
};
