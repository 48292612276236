import * as React from 'react';
function SvgIconPrint(props) {
  return (
    <svg width={40} height={40} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#icon-print_svg__filter0_d_134_49373)'>
        <rect x={2} y={1} width={36} height={36} rx={4} fill='#175CD3' />
        <g clipPath='url(#icon-print_svg__clip0_134_49373)'>
          <path
            d='M15 16.5v-5.833h10V16.5M15 24h-1.667a1.666 1.666 0 01-1.667-1.667v-4.166a1.666 1.666 0 011.667-1.667h13.333a1.666 1.666 0 011.667 1.667v4.166A1.666 1.666 0 0126.667 24H25m-10-3.333h10v6.666H15v-6.666z'
            stroke='#F5FAFF'
            strokeWidth={1.67}
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <rect x={2.5} y={1.5} width={35} height={35} rx={3.5} stroke='#175CD3' />
      </g>
      <defs>
        <clipPath id='icon-print_svg__clip0_134_49373'>
          <path fill='#fff' transform='translate(10 9)' d='M0 0h20v20H0z' />
        </clipPath>
        <filter
          id='icon-print_svg__filter0_d_134_49373'
          x={0}
          y={0}
          width={40}
          height={40}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy={1} />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values='0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_134_49373' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_134_49373' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgIconPrint;
