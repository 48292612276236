import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Chip,
  IconButton,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { isEmpty } from 'lodash';

import BoxActionPermission from '../../../components/box/BoxActionPermission/BoxActionPermission';
import BoxTableNotFound from '../../../components/box/BoxTableNotFound/BoxTableNotFound';
import TablePaginationMain from '../../../components/table-pagination/TablePaginationMain/TablePaginationMain';
import TableRowLoading from '../../../components/table-row/TableRowLoading/TableRowLoading';
import TypographyLinkPrimary from '../../../components/typography/TypographyLinkPrimary/TypographyLinkPrimary';
import { withRouter } from '../../../components/withRouter/withRouter';
import { decryptData, encryptData } from '../../../services/modules/Crypto/Crypto.service';
import messageHelper from '../../../store/message/message.helper';
import { getListSalesQuotation } from '../../../store/sales/sales.action';
import {
  REACT_APP_SALES_SALES_QUOTATION_CREATE_NEW_URL,
  REACT_APP_SALES_SALES_QUOTATION_DETAILS_TEMPLATE_URL,
} from '../../../utils/configs/url.config';
import { STATUS_REQUEST_LIST_SALES_QUOTATION_PENDING } from '../../../utils/constants/store.constant';
import {
  calculationColumns,
  columnsTableHeaderSalesQuotation,
} from '../../../utils/data/display/table-list-sales-quotation';
import {
  iconSalesQuotationList,
  tooltipSalesQuotation,
} from '../../../utils/data/display/tooltip-sales-quotation';
import { salesQoutationStatus } from '../../../utils/data/label-value/sales-status';
import {
  DEFAULT_SALES_QUOTATION_SEARCH_FE_PARAMS,
  TERSIER_DEFAULT_LIST_PARAMS,
} from '../../../utils/default/params.default';
import arrHelp from '../../../utils/helpers/array.helpers';
import formatHelp from '../../../utils/helpers/format.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import strHelp from '../../../utils/helpers/string.helpers';

import TableHeadSearchSalesQuotation from './TableHeadSearchSalesQuotation/TableHeadSearchSalesQuotation';
import { styles } from './SalesQuotationList.styles';

/**
 * @prop    { bool }            isUseChangedPageOnDetails           determine when you might use for changing page details on new page
 *      @default        false
 *
 * @prop    { function }        generateLinkDetails                 generating link for details, this function passing params single data each row on list
 *      @default        null
 *
 * @prop    { string }          dataListHeight                      determine height of list data, you can use 'middle' for resize list table
 *      @default         large
 *
 * @prop    { bool }            isNotFoundButtonShow                determine is showing button create on not found table
 *      @default        true
 *
 * @prop    { bool }            isUseLinkOnNotFoundButton           use link or not in button not found
 *      @default        false
 *
 * @prop    { string }          linkNotFoundButton                  link new create data on button not found
 *      @default        ''
 */

class SalesQuotationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage: TERSIER_DEFAULT_LIST_PARAMS.paginate,
    };

    const { handleSelectedStatusPage } = props;

    this.isHandleSelectedStatusPageFunction = typeof handleSelectedStatusPage === 'function';

    this.getSearchParamsSalesQuotation = this.getSearchParamsSalesQuotation.bind(this);

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

    this.handleClickActionSalesQuotation = this.handleClickActionSalesQuotation.bind(this);
  }

  handleClickActionSalesQuotation(event) {
    const { name: newStatusPage, value } = event.currentTarget;

    const decryptedValue = decryptData(value);

    if (this.isHandleSelectedStatusPageFunction) {
      this.props.handleSelectedStatusPage(newStatusPage, decryptedValue);
    }
  }

  getSearchParamsSalesQuotation() {
    const { sales } = this.props;
    const { salesQuotationData } = sales;

    let searchParamsSalesQuotation = objHelper.filterKeyObj(
      salesQuotationData,
      [],
      DEFAULT_SALES_QUOTATION_SEARCH_FE_PARAMS,
    );

    if (!isEmpty(searchParamsSalesQuotation)) {
      searchParamsSalesQuotation = objHelper.changeSuffixKey(
        searchParamsSalesQuotation,
        'search_',
        true,
      );
    }

    return searchParamsSalesQuotation;
  }

  async handleChangePage(event, newPage) {
    event.preventDefault();

    const { sales } = this.props;
    const { salesQuotationData } = sales;

    if (!salesQuotationData) {
      return;
    }

    const { next_page_url, current_page, per_page: paginate } = salesQuotationData;

    if (!next_page_url && current_page < newPage + 1) {
      return;
    }

    let searchParamsSalesQuotation = this.getSearchParamsSalesQuotation();

    await this.props.getListSalesQuotation({
      paginate,
      page: newPage + 1,
      ...searchParamsSalesQuotation,
    });
  }

  async handleChangeRowsPerPage(event) {
    const { value: newRowsPerPage } = event.target;

    const { t, sales } = this.props;

    const { salesQuotationData } = sales;

    if (!salesQuotationData) {
      return;
    }

    const { next_page_url, current_page, per_page: paginate } = salesQuotationData;

    if (Number(paginate) === Number(newRowsPerPage)) {
      return;
    }

    if (!next_page_url && paginate < newRowsPerPage && current_page === 1) {
      this.props.setMessageFailed(
        t('dashboard.sales.sales-quotation.message.max-data.primary'),
        t('dashboard.sales.sales-quotation.message.max-data.secondary'),
      );

      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      rowsPerPage: newRowsPerPage,
    }));

    let searchParamsSalesQuotation = this.getSearchParamsSalesQuotation();

    await this.props.getListSalesQuotation({
      paginate: newRowsPerPage,
      ...searchParamsSalesQuotation,
    });
  }

  async componentDidMount() {
    const { rowsPerPage: paginate } = this.state;

    await this.props.getListSalesQuotation({
      paginate,
      isShowMessage: false,
    });
  }

  render() {
    const { rowsPerPage } = this.state;

    const { t, auth, classes, sales } = this.props;

    const { permissions } = auth.data;

    const { salesQuotationData } = sales;

    let salesQuotationList = [];

    let currPagination = rowsPerPage;

    if (salesQuotationData) {
      if (salesQuotationData.data && Array.isArray(salesQuotationData.data)) {
        salesQuotationList = salesQuotationData.data;
      }

      if (salesQuotationData.per_page) {
        currPagination = Number(salesQuotationData.per_page);
      }
    }

    const tableHeaderSalesQuotation = columnsTableHeaderSalesQuotation(t);

    return (
      <Box className={classes.boxContainerTable}>
        <Paper elevation={0}>
          <TableContainer className={'tableContainerConstantHeightMDSizeConstantLayoutPrimary'}>
            <Table stickyHeader className='tableMain' aria-label='table-sales-quotation-list'>
              <TableHeadSearchSalesQuotation dataHeaders={tableHeaderSalesQuotation} />
              <TableBody className='primaryTableBody'>
                {sales.statusRequest === STATUS_REQUEST_LIST_SALES_QUOTATION_PENDING && (
                  <TableRowLoading
                    rowsAmount={rowsPerPage}
                    columnsAmount={tableHeaderSalesQuotation.length}
                  />
                )}
                {salesQuotationList &&
                  sales.statusRequest !== STATUS_REQUEST_LIST_SALES_QUOTATION_PENDING &&
                  salesQuotationList.map((singleSalesQuotation, index) => {
                    const baseUrl = strHelp.templateString(
                      REACT_APP_SALES_SALES_QUOTATION_DETAILS_TEMPLATE_URL,
                      singleSalesQuotation,
                    );

                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        className={'hoverEntireRow'}
                        onDoubleClick={() => this.props.navigate(baseUrl)}
                      >
                        {tableHeaderSalesQuotation.map((columnTableHeader, indexColumnHeader) => {
                          let valueData = singleSalesQuotation[columnTableHeader.name];

                          if (columnTableHeader.name === 'action') {
                            return (
                              <TableCell
                                key={indexColumnHeader}
                                align={columnTableHeader.align}
                                className='tableCellListData'
                              >
                                {tooltipSalesQuotation(t).map((tooltipData, index) => {
                                  return (
                                    <BoxActionPermission
                                      key={index}
                                      permissionsUser={permissions}
                                      permissionRequired={tooltipData.permission}
                                    >
                                      <Tooltip title={tooltipData.title}>
                                        <IconButton
                                          name={tooltipData.name}
                                          value={encryptData(JSON.stringify(singleSalesQuotation))}
                                          onClick={this.handleClickActionSalesQuotation}
                                          className={classes.iconButtonContainer}
                                        >
                                          <SvgIcon component={iconSalesQuotationList[index]} />
                                        </IconButton>
                                      </Tooltip>
                                    </BoxActionPermission>
                                  );
                                })}
                                {valueData}
                              </TableCell>
                            );
                          }

                          // formating date following localzone
                          if (columnTableHeader.name === 'transaction_date') {
                            valueData = formatHelp.getReadableDateV2(valueData);
                          }

                          if (columnTableHeader.name === 'transaction_no') {
                            return (
                              <TableCell key={indexColumnHeader}>
                                <TypographyLinkPrimary
                                  baseUrl={baseUrl}
                                  typographyValue={valueData}
                                />
                              </TableCell>
                            );
                          }

                          // formating value of nominal column
                          if (calculationColumns.includes(columnTableHeader.name)) {
                            valueData = formatHelp.currencyFormatWithRegex(valueData);
                          }

                          // container value status with Chip
                          if (columnTableHeader.name === 'status') {
                            const selectedSalesQuotationData = arrHelp.filterObjDataWithID(
                              salesQoutationStatus(t),
                              'value',
                              valueData,
                            );

                            let label = t('base.sales-quotation-status.open');
                            let colorChip = 'chipSecondaryStatus';

                            // get color and label status sales quotation
                            if (!isEmpty(selectedSalesQuotationData)) {
                              label = selectedSalesQuotationData.label;
                              colorChip = selectedSalesQuotationData.colorChip;
                            }

                            return (
                              <TableCell key={indexColumnHeader}>
                                <Chip size='small' color={colorChip} label={label} />
                              </TableCell>
                            );
                          }

                          return (
                            <TableCell key={indexColumnHeader} align={columnTableHeader.align}>
                              {valueData}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {!salesQuotationList.length &&
              sales.statusRequest !== STATUS_REQUEST_LIST_SALES_QUOTATION_PENDING && (
                <BoxTableNotFound
                  textNotFound={t('dashboard.sales.sales-quotation.table.body-not-found')}
                  textButton={t('button.add-sales-quotation')}
                  handleClickedButton={() => {
                    this.props.navigate(REACT_APP_SALES_SALES_QUOTATION_CREATE_NEW_URL);
                  }}
                  isExistButton
                />
              )}
          </TableContainer>
          <TablePaginationMain
            page={salesQuotationData ? salesQuotationData.current_page - 1 : 0}
            prev_page={salesQuotationData ? salesQuotationData.prev_page_url : null}
            next_page={salesQuotationData ? salesQuotationData.next_page_url : null}
            rowsPerPage={currPagination}
            isLoadingData={
              sales.statusRequest === STATUS_REQUEST_LIST_SALES_QUOTATION_PENDING ||
              !salesQuotationList.length
            }
            labelRowsPerPage={t('label.table.pagination.primary.rows-per-page')}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    sales: state.sales,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListSalesQuotation: (paramsGetListSalesQuotation) =>
      dispatch(getListSalesQuotation(paramsGetListSalesQuotation)),
    setMessageFailed: (primaryMessage, secondaryMessage) =>
      dispatch(messageHelper.failedMessage(dispatch, primaryMessage, secondaryMessage, false)),
  };
};

SalesQuotationList.defaultProps = {
  isUseChangedPageOnDetails: false,
};

// adding style into component
const stylingSalesQuotationList = withStyles(styles)(SalesQuotationList);

// adding translation into component
const SalesQuotationListTranslate = withTranslation()(stylingSalesQuotationList);

const SalesQuotationWithRouter = withRouter(SalesQuotationListTranslate);

// connect component with action and state
const ConnectedSalesQuotationList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SalesQuotationWithRouter);

export default ConnectedSalesQuotationList;
