import { createAsyncThunk } from '@reduxjs/toolkit';

import beginningBalanceServices from '../../services/API/beginning-balance/beginning-balance.service';
import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_BEGINNING_BALANCE } from '../../utils/constants/storage.constant';
import messageHelper from '../message/message.helper';

// handling list beginning balance data
export const getListBeginningBalance = createAsyncThunk(
  'beginning-balance/list',
  async (data, thunkAPI) => {
    try {
      const response = await beginningBalanceServices.getListBeginningBalance();

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      let { data: dataBeginningBalance, sucess } = response.data;

      if (!sucess || !Array.isArray(dataBeginningBalance)) {
        if (Array.isArray(dataBeginningBalance.data)) {
          dataBeginningBalance = [...dataBeginningBalance.data];
        } else if (!Array.isArray(dataBeginningBalance.data)) {
          dataBeginningBalance = [];
        }
      }

      LocalStorage.set(LOCAL_STORAGE_BEGINNING_BALANCE, dataBeginningBalance);

      return { dataBeginningBalance };
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.beginning-balance',
          'error.list-data.beginning-balance',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.list-data.beginning-balance',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// handling create beginning balance
export const createBeginningBalance = createAsyncThunk(
  'beginning-balance/create',
  async (dataCreateBeginningBalance, thunkAPI) => {
    try {
      const response = await beginningBalanceServices.createBeginningBalance(
        dataCreateBeginningBalance,
      );

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.create.beginning-balance',
          'success.create.beginning-balance',
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.create.beginning-balance',
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.create.beginning-balance',
          'error.create.beginning-balance',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.create.beginning-balance',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);
