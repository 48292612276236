import { isEmpty } from 'lodash';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import {
  hasImplementedUnitProductUnit,
  keynamesImpelementProductUnit,
} from '../../default/unit-product.default';
import validation from '../../helpers/validation.helpers';

const initialID = uuidV4();

export const plainSalesDeliveryDataWithoutID = {
  customer_id: null,
  customer_id_container: '',
  customer_name: null,
  customer_address: '',
  delivery_date: moment(),
  reference_number: '',
  reference_number_container: null,
  sales_order_id: null,
  sales_order_id_container: null,
  description: '',
  is_minutes_handover_paid_off: false,
  sales_agent_id: '',
  sales_agent_id_container: '',
  sales_agent_name: '',
  warehouse_id: null,
  warehouse_id_container: '',
  attachment: [],
  uuid_attachment: null,
  accounting_period: null,
  minDateInputRef: null,
  is_sent: false,
  tag: [],
  tagContainer: [],
  tagNameInput: '',
};

export const plainSalesDeliveryValidationWithoutID = {
  customer_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  customer_address: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  delivery_date: {
    error: false,
    isValid: (value, minDateInputRef) => {
      if (
        !minDateInputRef ||
        // cause when accounting period has been converted to 'moment'
        (!isEmpty(minDateInputRef) && !minDateInputRef._isValid)
      )
        return validation.notnull(value);

      return moment(new Date(minDateInputRef)).isSameOrBefore(new Date(value), 'days');
    },
  },
  reference_number: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  sales_order_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

/**
 * explain different on quantity
 * quantity is input user from form input
 *   quantity represent value for viewing user
 * quantity_origin is value that after changning on product unit
 * quantity_available is response from sales order data
 * quantity_available_const is maximum input quantity data, this is constant variable
 * quantity comparison, comparison from quantity with quantity origin
 * quantity_comparison_const_props, appear for update action,
 *  usage: preserve quantity comparison data
 *
 * unit_origin is value for represent unit before changing to new unit
 * unit_destination is value unit after change to new unit, this to view for user
 */
export const plainProductSalesDeliveryDataWithoutID = {
  product_id: null,
  product_id_container: null,
  product_description: '',
  quantity: '',
  quantity_origin: '',
  quantity_available: '',
  quantity_available_const: '',
  quantity_comparison: 1,
  quantity_comparison_const_props: null,
  quantity_available_with_unit: '',
  unit_destination: '',
  unit_origin: '',
};

export const plainProductSalesDeliveryValidationWithoutID = {
  product_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  product_description: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  quantity: {
    error: false,
    isValid: (value) => validation.isNumberGreaterOrEqualThan(value, 1),
  },
  unit_destination: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
};

export const initialSalesDeliveryData = (newID = '', additionalID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainSalesDeliveryDataWithoutID,
    delivery_date: moment(),
  };
};

export const initialSalesDeliveryValidation = (newID = '', additionalID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainSalesDeliveryValidationWithoutID,
  };
};

export const initialProductSalesDeliveryData = (newID = '', additionalID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainProductSalesDeliveryDataWithoutID,
  };
};

export const initialProductSalesDeliveryValidation = (newID = '', additionalID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainProductSalesDeliveryValidationWithoutID,
  };
};

export const allowKeysSalesDeliveryData = [
  'customer_id',
  'customer_address',
  'customer_name',
  'delivery_date',
  'reference_number',
  'sales_order_id',
  'description',
  'is_minutes_handover_paid_off',
  'sales_agent_id',
  'sales_agent_name',
  'warehouse_id',
  'uuid_attachment',
  'is_sent',
  'tag',
];

export const allowKeysProductSalesDeliveryData = [
  'product_id',
  'quantity',
  'quantity_origin',
  'product_description',
  'unit_destination',
  'unit_origin',
].filter((key) =>
  !hasImplementedUnitProductUnit ? !keynamesImpelementProductUnit.includes(key) : true,
);

export const salesDeliveryCalculationNumberKeyNames = [];

export const productSalesDeliveryCalculationNumberKeyNames = [
  'quantity',
  'quantity_origin',
  'quantity_available',
];
