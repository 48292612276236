import axios from 'axios';
import { isEmpty, pick } from 'lodash';
import qs from 'qs';

import {
  REACT_APP_API_URL_CHART_INCOME_STATEMENT,
  REACT_APP_API_URL_CHART_TRANSACTION_SALES,
} from '../../../utils/configs/api.config';
import {
  getChartDashboardBasicConfiguration,
  getPaperChartListBasicDashboard,
} from '../../../utils/default/chart-basic-dashboard-config.default';
import { DEFAULT_ACTION_FROM_TO_DATE_PARAMS } from '../../../utils/default/params.default';
import { authHeader } from '../../../utils/helpers/auth-header.helpers';
import {
  creatorDetailServiceV2,
  creatorDetailServiceWithParams,
  creatorListService,
} from '../creator-services/creator-services.helper';

// service for getting chart basic dashboard details, you can see on configuration chart basic dashboard
export const getChartBasicDashboardDetails = async ({ params, chartType }) => {
  const chartBasicConfiguration = getChartDashboardBasicConfiguration(chartType);

  const { url, allowParams, defaultParams } = chartBasicConfiguration;

  return await creatorDetailServiceWithParams({
    url,
    params,
    allowParams,
    defaultParams,
  });
};

// service for listing basic dasboard
export const getChartListBasicDashboard = async ({
  chartType,
  actionUtils = getPaperChartListBasicDashboard,
  ...params
}) => {
  const { Authorization } = authHeader();

  if (!Authorization) return null;

  const { url, allowParams, defaultParams, sortSearchParams } =
    typeof actionUtils === 'function'
      ? actionUtils(chartType)
      : getPaperChartListBasicDashboard(chartType);

  params = pick(
    {
      ...defaultParams,
      ...params,
    },
    allowParams,
  );

  if (!sortSearchParams || isEmpty(params[sortSearchParams])) {
    return await creatorListService(url, params, allowParams, defaultParams);
  }

  return await axios({
    method: 'GET',
    url: url,
    headers: {
      Authorization,
    },
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  });
};

// service get sales transction chart details
const getSalesTransactionChartDetails = async (paramsGetChartTransactionDetails) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_CHART_TRANSACTION_SALES,
    paramsGetChartTransactionDetails,
  );
};

// service getting income statement chart details
const getIncomeStatementChartDetails = async (paramsGetChartTransactionDetails) => {
  return await creatorDetailServiceWithParams({
    url: REACT_APP_API_URL_CHART_INCOME_STATEMENT,
    params: paramsGetChartTransactionDetails,
    allowParams: DEFAULT_ACTION_FROM_TO_DATE_PARAMS,
  });
};

const chartServices = {
  getChartBasicDashboardDetails,
  getChartListBasicDashboard,
  getSalesTransactionChartDetails,
  getIncomeStatementChartDetails,
};

export default chartServices;
