import * as React from 'react';
function SvgIncreaseGraph(props) {
  return (
    <svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fill='#fff' fillOpacity={0.01} d='M0 0h24v24H0z' />
      <path
        d='M21 17H4.995C4.448 17 4 16.548 4 15.991V6a1 1 0 00-2 0v9.991A3.004 3.004 0 004.995 19H21a1 1 0 000-2zm-3-8v3a1 1 0 002 0V8a1 1 0 00-1-1h-4a1 1 0 100 2h3z'
        fill='#42526E'
      />
      <path
        d='M13.293 13.707a1 1 0 001.414 0l4-4a1 1 0 10-1.414-1.414L14 11.586l-2.293-2.293a1 1 0 00-1.414 0l-4 4a1 1 0 001.414 1.414L11 11.414l2.293 2.293z'
        fill='#42526E'
      />
    </svg>
  );
}
export default SvgIncreaseGraph;
