import { isEmpty } from 'lodash';

import { encryptData } from '../../../services/modules/Crypto/Crypto.service';
import {
  REACT_APP_API_URL_HANDOVER_PURCHASE_HISTORY,
  REACT_APP_API_URL_HANDOVER_SALES_HISTORY,
} from '../../configs/api.config';
import {
  REACT_APP_PURCHASE_DELIVERY_JOURNAL_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_INVOICE_JOURNAL_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_PAYMENT_JOURNAL_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_PURCHASE_DELIVERY_CREATE_NEW_URL,
  REACT_APP_PURCHASE_PURCHASE_DELIVERY_DETAILS_HISTORY_TEMPLATE_URL,
  REACT_APP_PURCHASE_PURCHASE_DELIVERY_HANDOVER_JOURNAL_TEMPLATE_URL,
  REACT_APP_PURCHASE_PURCHASE_INVOICE_CREATE_NEW_URL,
  REACT_APP_PURCHASE_PURCHASE_INVOICE_DETAILS_HISTORY_TEMPLATE_URL,
  REACT_APP_PURCHASE_PURCHASE_ORDER_CREATE_NEW_URL,
  REACT_APP_PURCHASE_PURCHASE_ORDER_DETAILS_HISTORY_TEMPLATE_URL,
  REACT_APP_PURCHASE_PURCHASE_PAYMENT_CREATE_NEW_URL,
  REACT_APP_PURCHASE_PURCHASE_PAYMENT_DETAILS_HISTORY_TEMPLATE_URL,
  REACT_APP_PURCHASE_QUOTATION_REQUEST_DETAILS_HISTORY_TEMPLATE_URL,
  REACT_APP_PURCHASE_RECEIVABLE_PAYMENT_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_RETURN_JOURNAL_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_DELIVERY_JOURNAL_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_INVOICE_JOURNAL_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_JOURNAL_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_PAYABLE_PAYMENT_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_PAYMENT_JOURNAL_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_RETURN_JOURNAL_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_SALES_DELIVERY_CREATE_NEW_URL,
  REACT_APP_SALES_SALES_DELIVERY_DETAILS_HISTORY_TEMPLATE_URL,
  REACT_APP_SALES_SALES_INVOICE_CREATE_NEW_URL,
  REACT_APP_SALES_SALES_INVOICE_DETAILS_HISTORY_TEMPLATE_URL,
  REACT_APP_SALES_SALES_ORDER_CREATE_NEW_URL,
  REACT_APP_SALES_SALES_ORDER_DETAILS_HISTORY_TEMPLATE_URL,
  REACT_APP_SALES_SALES_PAYMENT_CREATE_NEW_URL,
  REACT_APP_SALES_SALES_PAYMENT_DETAILS_HISTORY_TEMPLATE_URL,
  REACT_APP_SALES_SALES_QUOTATION_DETAILS_HISTORY_TEMPLATE_URL,
} from '../../configs/url.config';
import {
  CASH_PAYMENT_CREATE_PERMISSION_V2,
  CASH_RECEIPT_CREATE_PERMISSION_V2,
  CASH_TRANSFER_CREATE_PERMISSION_V2,
  JOURNAL_READ_PERMISSION_V2,
  PURCHASE_DELIVERY_CREATE_PERMISSION_V2,
  PURCHASE_INVOICE_CREATE_PERMISSION_V2,
  PURCHASE_ORDER_CREATE_PERMISSION_V2,
  PURCHASE_PAYMENT_CREATE_PERMISSION_V2,
  SALES_DELIVERY_CREATE_PERMISSION_V2,
  SALES_INVOICE_CREATE_PERMISSION_V2,
  SALES_ORDER_CREATE_PERMISSION_V2,
  SALES_PAYMENT_CREATE_PERMISSION_V2,
} from '../../constants/permissions.constant';
import {
  DEFAULT_BUTTON_NAME_ACTION_PAY,
  DEFAULT_BUTTON_NAME_CREATE_PURCHASE_DELIVERY,
  DEFAULT_BUTTON_NAME_CREATE_PURCHASE_INVOICE,
  DEFAULT_BUTTON_NAME_CREATE_PURCHASE_ORDER,
  DEFAULT_BUTTON_NAME_CREATE_PURCHASE_PAYMENT,
  DEFAULT_BUTTON_NAME_CREATE_SALES_DELIVERY,
  DEFAULT_BUTTON_NAME_CREATE_SALES_INVOICE,
  DEFAULT_BUTTON_NAME_CREATE_SALES_ORDER,
  DEFAULT_BUTTON_NAME_CREATE_SALES_PAYMENT,
  DEFAULT_BUTTON_NAME_PRINT_EXCEL,
  DEFAULT_BUTTON_NAME_PRINT_PDF,
  DEFAULT_BUTTON_NAME_VIEW_HISTORY,
  DEFAULT_BUTTON_NAME_VIEW_JOURNAL,
} from '../../default/button-name.default';
import {
  DEFAULT_BUTTON_CHANGE_PAGE_STATUS,
  DEFAULT_BUTTON_GENERATE_LINK,
} from '../../default/button-type.default';
import {
  DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_PURCHASE_DELIVERY_DATA,
  DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_PURCHASE_INVOICE_DATA,
  DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_PURCHASE_ORDER_DATA,
  DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_QUOTATION_REQUEST_DATA,
  DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_DELIVERY_DATA,
  DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_INVOICE_DATA,
  DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_ORDER_DATA,
  DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_QUOTATION_DATA,
  DEFAULT_KEY_NAME_STATUS_REF_API,
} from '../../default/object-keyname.default';
import {
  DEFAULT_STATUS_PURCHASE_DELIVERY_CLOSED,
  DEFAULT_STATUS_PURCHASE_INVOICE_PAID,
  DEFAULT_STATUS_PURCHASE_ORDER_CLOSED,
  DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_CLOSE,
} from '../../default/purchase-status.default';
import {
  DEFAULT_STATUS_SALES_DELIVERY_CLOSE,
  DEFAULT_STATUS_SALES_INVOICE_PAID,
  DEFAULT_STATUS_SALES_ORDER_CLOSED,
  DEFAULT_STATUS_SALES_QUOTATION_CLOSE,
} from '../../default/sales-status.default';
import {
  DEFAULT_PAGE_STATUS_PAY_TRANSACTION,
  DEFAULT_PAGE_STATUS_PRINT_EXCEL,
  DEFAULT_PAGE_STATUS_PRINT_PDF,
  DEFAULT_PAGE_STATUS_TRANSACTION_HISTORY,
  DEFAULT_STATUS_PAGE_ADD_CASH_BANK_CASH_RECEIPT,
  DEFAULT_STATUS_PAGE_ADD_CASH_BANK_CASH_TRANSFER,
  DEFAULT_STATUS_PAGE_ADD_CASH_PAYMENT,
  DEFAULT_STATUS_PAGE_ADD_PRODUCT_STOCK_ADJUSTMENT,
  DEFAULT_STATUS_PAGE_ADD_TRANSFER_WAREHOUSE,
  DEFAULT_STATUS_PAGE_IMPORT_PRODUCT,
} from '../../default/status-page.default';
import formatHelp from '../../helpers/format.helpers';
import strHelp from '../../helpers/string.helpers';

import { additionalPropsButtonBlueSixMiddleBorderWithPayOnStartIcon } from './additional-props';

/**
 *
 * @param { function } t translation function for getting name correctly
 * @returns
 *      menu items that contained by sequence of button
 */
export const menuItemCashBankButton = (t) => {
  return [
    {
      itemName: DEFAULT_STATUS_PAGE_ADD_CASH_BANK_CASH_RECEIPT,
      buttonItemName: t('button-menu.actions.create-cash-receipt'),
    },
    {
      itemName: DEFAULT_STATUS_PAGE_ADD_CASH_PAYMENT,
      buttonItemName: t('button-menu.actions.create-cash-payment'),
    },
  ];
};

/**
 *
 * @param { function } t translation function for getting name correctly
 * @returns
 *      menu items that contained by sequence of button
 *      enhancement with additional permission required key
 */
export const menuItemCashBankButtonV2 = (t) => {
  return [
    {
      itemName: DEFAULT_STATUS_PAGE_ADD_CASH_BANK_CASH_TRANSFER,
      buttonItemName: t('button.create-cash-transfer'),
      permissionRequired: [CASH_TRANSFER_CREATE_PERMISSION_V2],
    },
    {
      itemName: DEFAULT_STATUS_PAGE_ADD_CASH_BANK_CASH_RECEIPT,
      buttonItemName: t('button-menu.actions.create-cash-receipt'),
      permissionRequired: [CASH_RECEIPT_CREATE_PERMISSION_V2],
    },
    {
      itemName: DEFAULT_STATUS_PAGE_ADD_CASH_PAYMENT,
      buttonItemName: t('button-menu.actions.create-cash-payment'),
      permissionRequired: [CASH_PAYMENT_CREATE_PERMISSION_V2],
    },
  ];
};

/**
 *
 * @param { function } t translation function for getting name correctly
 * @returns
 *      menu items that contained by sequence of button
 */
export const menuItemDataProductButton = (t) => {
  return [
    {
      itemName: DEFAULT_STATUS_PAGE_ADD_PRODUCT_STOCK_ADJUSTMENT,
      buttonItemName: t('button-menu.actions.stock-adjusment'),
    },
    {
      itemName: DEFAULT_STATUS_PAGE_ADD_TRANSFER_WAREHOUSE,
      buttonItemName: t('button-menu.actions.warehouse-transfer'),
    },
    {
      itemName: DEFAULT_STATUS_PAGE_IMPORT_PRODUCT,
      buttonItemName: t('button-menu.actions.import-product'),
    },
  ];
};

/**
 * asset button for create single item
 */

/**
 *
 * @param { function }      t                               translation function
 * @param { string }        keyNameDetailsDataCameFrom      key name on search url
 *      @default    'selectedQuotationRequestData'
 * @returns
 */
export const menuItemCreatePurchaseOrderWithButtonProps = (
  t,
  keyNameDetailsDataCameFrom = DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_QUOTATION_REQUEST_DATA,
) => {
  return {
    itemName: DEFAULT_BUTTON_NAME_CREATE_PURCHASE_ORDER,
    buttonItemName: t('button-menu.actions.create-order'),
    permissionRequired: [PURCHASE_ORDER_CREATE_PERMISSION_V2],
    buttonProps: {
      buttonType: DEFAULT_BUTTON_GENERATE_LINK,
      generateLink: (currentValueDetails) => {
        const ecryptedCurrentValue = encryptData(JSON.stringify(currentValueDetails));

        const paramsSearchUrl = {
          [keyNameDetailsDataCameFrom]: ecryptedCurrentValue,
        };

        return formatHelp.combineBaseUrlWithSearchParams(
          REACT_APP_PURCHASE_PURCHASE_ORDER_CREATE_NEW_URL,
          paramsSearchUrl,
        );
      },
    },
  };
};

/**
 *
 * @param { function }      t                               translation function
 * @param { string }        keyNameDetailsDataCameFrom      key name on search url
 *      @default    'selectedPurchaseOrderData'
 * @returns
 */
export const menuItemCreatePurchaseDeliveryWithButtonProps = (
  t,
  keyNameDetailsDataCameFrom = DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_PURCHASE_ORDER_DATA,
) => {
  return {
    itemName: DEFAULT_BUTTON_NAME_CREATE_PURCHASE_DELIVERY,
    buttonItemName: t('button-menu.actions.create-delivery'),
    permissionRequired: [PURCHASE_DELIVERY_CREATE_PERMISSION_V2],
    buttonProps: {
      buttonType: DEFAULT_BUTTON_GENERATE_LINK,
      generateLink: (currentValueDetails) => {
        const ecryptedCurrentValue = encryptData(JSON.stringify(currentValueDetails));

        const paramsSearchUrl = {
          [keyNameDetailsDataCameFrom]: ecryptedCurrentValue,
        };

        return formatHelp.combineBaseUrlWithSearchParams(
          REACT_APP_PURCHASE_PURCHASE_DELIVERY_CREATE_NEW_URL,
          paramsSearchUrl,
        );
      },
    },
  };
};

/**
 *
 * @param { function }      t                               translation function
 * @param { string }        keyNameDetailsDataCameFrom      key name on search url
 *      @default    'selectedPurchaseDeliveryData'
 * @returns
 */
export const menuItemCreatePurchaseInvoiceWithButtonProps = (
  t,
  keyNameDetailsDataCameFrom = DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_PURCHASE_DELIVERY_DATA,
) => {
  return {
    itemName: DEFAULT_BUTTON_NAME_CREATE_PURCHASE_INVOICE,
    buttonItemName: t('button-menu.actions.create-invoice'),
    permissionRequired: [PURCHASE_INVOICE_CREATE_PERMISSION_V2],
    buttonProps: {
      buttonType: DEFAULT_BUTTON_GENERATE_LINK,
      generateLink: (currentValueDetails) => {
        const ecryptedCurrentValue = encryptData(JSON.stringify(currentValueDetails));

        const paramsSearchUrl = {
          [keyNameDetailsDataCameFrom]: ecryptedCurrentValue,
        };

        return formatHelp.combineBaseUrlWithSearchParams(
          REACT_APP_PURCHASE_PURCHASE_INVOICE_CREATE_NEW_URL,
          paramsSearchUrl,
        );
      },
    },
  };
};

/**
 *
 * @param { function }      t                               translation function
 * @param { string }        keyNameDetailsDataCameFrom      key name on search url
 *      @default    'selectedPurchaseInvoiceData'
 * @returns
 */
export const menuItemCreatePurchasePaymentWithButtonProps = (
  t,
  keyNameDetailsDataCameFrom = DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_PURCHASE_INVOICE_DATA,
) => {
  return {
    itemName: DEFAULT_BUTTON_NAME_CREATE_PURCHASE_PAYMENT,
    buttonItemName: t('button-menu.actions.create-payment'),
    permissionRequired: [PURCHASE_PAYMENT_CREATE_PERMISSION_V2],
    buttonProps: {
      buttonType: DEFAULT_BUTTON_GENERATE_LINK,
      generateLink: (currentValueDetails) => {
        const ecryptedCurrentValue = encryptData(JSON.stringify(currentValueDetails));

        const paramsSearchUrl = {
          [keyNameDetailsDataCameFrom]: ecryptedCurrentValue,
        };

        return formatHelp.combineBaseUrlWithSearchParams(
          REACT_APP_PURCHASE_PURCHASE_PAYMENT_CREATE_NEW_URL,
          paramsSearchUrl,
        );
      },
    },
  };
};

/**
 *
 * @param { function }      t                               translation function
 * @param { string }        keyNameDetailsDataCameFrom      key name on search url
 *      @default    'selectedSalesQuotationData'
 * @returns
 */
export const menuItemCreateSalesOrderWithButtonProps = (
  t,
  keyNameDetailsDataCameFrom = DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_QUOTATION_DATA,
) => {
  return {
    itemName: DEFAULT_BUTTON_NAME_CREATE_SALES_ORDER,
    buttonItemName: t('button-menu.actions.create-order'),
    permissionRequired: [SALES_ORDER_CREATE_PERMISSION_V2],
    buttonProps: {
      buttonType: DEFAULT_BUTTON_GENERATE_LINK,
      generateLink: (currentValueDetails) => {
        const ecryptedCurrentValue = encryptData(JSON.stringify(currentValueDetails));

        const paramsSearchUrl = {
          [keyNameDetailsDataCameFrom]: ecryptedCurrentValue,
        };

        return formatHelp.combineBaseUrlWithSearchParams(
          REACT_APP_SALES_SALES_ORDER_CREATE_NEW_URL,
          paramsSearchUrl,
        );
      },
    },
  };
};

/**
 *
 * @param { function }      t                               translation function
 * @param { string }        keyNameDetailsDataCameFrom      key name on search url
 *      @default    'selectedSalesOrderData'
 * @returns
 */
export const menuItemCreateSalesDeliveryWithButtonProps = (
  t,
  keyNameDetailsDataCameFrom = DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_ORDER_DATA,
) => {
  return {
    itemName: DEFAULT_BUTTON_NAME_CREATE_SALES_DELIVERY,
    buttonItemName: t('button-menu.actions.create-delivery'),
    permissionRequired: [SALES_DELIVERY_CREATE_PERMISSION_V2],
    buttonProps: {
      buttonType: DEFAULT_BUTTON_GENERATE_LINK,
      generateLink: (currentValueDetails) => {
        const ecryptedCurrentValue = encryptData(JSON.stringify(currentValueDetails));

        const paramsSearchUrl = {
          [keyNameDetailsDataCameFrom]: ecryptedCurrentValue,
        };

        return formatHelp.combineBaseUrlWithSearchParams(
          REACT_APP_SALES_SALES_DELIVERY_CREATE_NEW_URL,
          paramsSearchUrl,
        );
      },
    },
  };
};

/**
 *
 * @param { function }      t                               translation function
 * @param { string }        keyNameDetailsDataCameFrom      key name on search url
 *      @default    'selectedSalesDeliveryData'
 * @returns
 */
export const menuItemCreateSalesInvoiceWithButtonProps = (
  t,
  keyNameDetailsDataCameFrom = DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_DELIVERY_DATA,
) => {
  return {
    itemName: DEFAULT_BUTTON_NAME_CREATE_SALES_INVOICE,
    buttonItemName: t('button-menu.actions.create-invoice'),
    permissionRequired: [SALES_INVOICE_CREATE_PERMISSION_V2],
    buttonProps: {
      buttonType: DEFAULT_BUTTON_GENERATE_LINK,
      generateLink: (currentValueDetails) => {
        const ecryptedCurrentValue = encryptData(JSON.stringify(currentValueDetails));

        const paramsSearchUrl = {
          [keyNameDetailsDataCameFrom]: ecryptedCurrentValue,
        };

        return formatHelp.combineBaseUrlWithSearchParams(
          REACT_APP_SALES_SALES_INVOICE_CREATE_NEW_URL,
          paramsSearchUrl,
        );
      },
    },
  };
};

/**
 *
 * @param { function }      t                               translation function
 * @param { string }        keyNameDetailsDataCameFrom      key name on search url
 *      @default    'selectedSalesInvoiceData'
 * @returns
 */
export const menuItemCreateSalesPaymentWithButtonProps = (
  t,
  keyNameDetailsDataCameFrom = DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_INVOICE_DATA,
) => {
  return {
    itemName: DEFAULT_BUTTON_NAME_CREATE_SALES_PAYMENT,
    buttonItemName: t('button-menu.actions.create-payment'),
    permissionRequired: [SALES_PAYMENT_CREATE_PERMISSION_V2],
    buttonProps: {
      buttonType: DEFAULT_BUTTON_GENERATE_LINK,
      generateLink: (currentValueDetails) => {
        const ecryptedCurrentValue = encryptData(JSON.stringify(currentValueDetails));

        const paramsSearchUrl = {
          [keyNameDetailsDataCameFrom]: ecryptedCurrentValue,
        };

        return formatHelp.combineBaseUrlWithSearchParams(
          REACT_APP_SALES_SALES_PAYMENT_CREATE_NEW_URL,
          paramsSearchUrl,
        );
      },
    },
  };
};

export const menuItemPrintPdfWithButtonProps = (t) => {
  return {
    itemName: DEFAULT_BUTTON_NAME_PRINT_PDF,
    buttonItemName: t('button-menu.print.pdf'),
    buttonProps: {
      buttonType: DEFAULT_BUTTON_CHANGE_PAGE_STATUS,
      newPageStatus: DEFAULT_PAGE_STATUS_PRINT_PDF,
    },
  };
};

export const menuItemPrintExcelWithButtonProps = (t) => {
  return {
    itemName: DEFAULT_BUTTON_NAME_PRINT_EXCEL,
    buttonItemName: t('button-menu.print.excel'),
    buttonProps: {
      buttonType: DEFAULT_BUTTON_CHANGE_PAGE_STATUS,
      newPageStatus: DEFAULT_PAGE_STATUS_PRINT_EXCEL,
    },
  };
};

export const menuItemPayTransactionWithButtonProps = (t) => {
  return {
    itemName: DEFAULT_BUTTON_NAME_ACTION_PAY,
    buttonItemName: t('button-menu.actions.pay'),
    buttonProps: {
      buttonType: DEFAULT_BUTTON_CHANGE_PAGE_STATUS,
      newPageStatus: DEFAULT_PAGE_STATUS_PAY_TRANSACTION,
    },
    additionalLeftButtonProps: additionalPropsButtonBlueSixMiddleBorderWithPayOnStartIcon,
  };
};

export const menuItemViewJournalEntryWithButtonProps = (
  t,
  baseUrl = REACT_APP_SALES_JOURNAL_DETAILS_TEMPLATE_URL,
) => {
  return {
    itemName: DEFAULT_BUTTON_NAME_VIEW_JOURNAL,
    buttonItemName: t('button-menu.actions.view-journal-entry'),
    permissionRequired: [JOURNAL_READ_PERMISSION_V2],
    buttonProps: {
      buttonType: DEFAULT_BUTTON_GENERATE_LINK,
      // minimum transaction id on key current value details
      generateLink: (currentValueDetails) => {
        return strHelp.templateString(baseUrl, currentValueDetails);
      },
    },
  };
};

export const menuItemViewHistoryWithButtonProps = (
  t,
  baseUrl = REACT_APP_SALES_SALES_QUOTATION_DETAILS_HISTORY_TEMPLATE_URL,
) => {
  return {
    itemName: DEFAULT_BUTTON_NAME_VIEW_HISTORY,
    buttonItemName: t('button-menu.actions.history'),
    buttonProps: {
      buttonType: DEFAULT_BUTTON_CHANGE_PAGE_STATUS,
      newPageStatus: DEFAULT_PAGE_STATUS_TRANSACTION_HISTORY,
      // buttonType: DEFAULT_BUTTON_GENERATE_LINK,
      // minimum sales order id on key current value details
      generateLink: (currentValueDetails) => {
        return strHelp.templateString(baseUrl, currentValueDetails);
      },
    },
  };
};

export const menuItemSalesQuotationButton = (t) => {
  return [
    {
      itemName: DEFAULT_BUTTON_NAME_CREATE_SALES_ORDER,
      buttonItemName: t('button-menu.actions.create-order'),
    },
    {
      itemName: DEFAULT_BUTTON_NAME_PRINT_PDF,
      buttonItemName: t('button-menu.print.pdf'),
    },
    {
      itemName: DEFAULT_BUTTON_NAME_PRINT_EXCEL,
      buttonItemName: t('button-menu.print.excel'),
    },
  ];
};

/**
 *
 * @param { object } param0         object params
 *
 * @param   { array }       initialMenuItems            current initial menu items
 * @param   { array }       countRemoves                remove count from start where removed
 * @param   { array }       conditionalKeyname          conditional where on body conditional
 * @param   { array }       conditionalValue            conditional result on body conditional
 * @param   { array }       bodyConditional             body where come for comparison value
 * @param   { array }       defaultConditionalKeyname   keyname for handling all values
 * @param   { array }       defaultCountRemove          count remove default where not handled on count removes
 *
 * @returns
 */
export const simplifyMenuItemsButton = ({
  initialMenuItems = [],
  countRemoves = [],
  conditionalKeynames = [],
  conditionalValues = [],
  bodyConditional = {},
  defaultConditionalKeyname = DEFAULT_KEY_NAME_STATUS_REF_API,
  defaultCountRemoves = 1,
}) => {
  if (
    isEmpty(bodyConditional) ||
    isEmpty(conditionalValues) ||
    isEmpty(initialMenuItems) ||
    !Array.isArray(initialMenuItems)
  )
    return initialMenuItems;

  return conditionalValues.reduce(
    (resultMenuItems, currentConditionalValue, indexConditionalValue) => {
      const conditionalKeyname =
        conditionalKeynames[indexConditionalValue] || defaultConditionalKeyname;
      const countRemove = countRemoves[indexConditionalValue] || defaultCountRemoves;

      if (bodyConditional[conditionalKeyname] !== currentConditionalValue) return resultMenuItems;

      return resultMenuItems.slice(countRemove);
    },
    initialMenuItems,
  );
};

export const menuItemPurchaseQuotationRequestButtonWithButtonProps = (t) => {
  return [
    menuItemCreatePurchaseOrderWithButtonProps(t),
    menuItemViewHistoryWithButtonProps(
      t,
      REACT_APP_PURCHASE_QUOTATION_REQUEST_DETAILS_HISTORY_TEMPLATE_URL,
    ),
    menuItemPrintPdfWithButtonProps(t),
    menuItemPrintExcelWithButtonProps(t),
  ];
};

export const menuItemPurchaseQuotationRequestButtonWithButtonPropsV2 = (
  t,
  purchaseQuotationRequestDetails,
) => {
  return simplifyMenuItemsButton({
    initialMenuItems: menuItemPurchaseQuotationRequestButtonWithButtonProps(t),
    conditionalValues: [DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_CLOSE],
    bodyConditional: purchaseQuotationRequestDetails,
  });
};

export const menuItemPurchaseOrderButtonWithButtonProps = (t) => {
  return [
    menuItemCreatePurchaseDeliveryWithButtonProps(
      t,
      DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_PURCHASE_ORDER_DATA,
    ),
    menuItemCreatePurchaseInvoiceWithButtonProps(
      t,
      DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_PURCHASE_ORDER_DATA,
    ),
    menuItemViewHistoryWithButtonProps(
      t,
      REACT_APP_PURCHASE_PURCHASE_ORDER_DETAILS_HISTORY_TEMPLATE_URL,
    ),
    menuItemPrintPdfWithButtonProps(t),
    menuItemPrintExcelWithButtonProps(t),
  ];
};

export const menuItemPurchaseOrderButtonWithButtonPropsV2 = (t, purchaseOrderDetails) => {
  return simplifyMenuItemsButton({
    initialMenuItems: menuItemPurchaseOrderButtonWithButtonProps(t),
    conditionalValues: [DEFAULT_STATUS_PURCHASE_ORDER_CLOSED],
    bodyConditional: purchaseOrderDetails,
    countRemoves: [2],
  });
};

export const menuItemPurchaseDeliveryButtonWithProps = (t) => {
  return [
    menuItemCreatePurchaseInvoiceWithButtonProps(
      t,
      DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_PURCHASE_DELIVERY_DATA,
    ),
    menuItemViewJournalEntryWithButtonProps(
      t,
      REACT_APP_PURCHASE_DELIVERY_JOURNAL_DETAILS_TEMPLATE_URL,
    ),
    menuItemViewHistoryWithButtonProps(
      t,
      REACT_APP_PURCHASE_PURCHASE_DELIVERY_DETAILS_HISTORY_TEMPLATE_URL,
    ),
    menuItemPrintPdfWithButtonProps(t),
    menuItemPrintExcelWithButtonProps(t),
  ];
};

export const menuItemPurchaseDeliveryButtonWithPropsV2 = (t, purchaseDeliveryDetails) => {
  return simplifyMenuItemsButton({
    initialMenuItems: menuItemPurchaseDeliveryButtonWithProps(t),
    conditionalValues: [DEFAULT_STATUS_PURCHASE_DELIVERY_CLOSED],
    bodyConditional: purchaseDeliveryDetails,
  });
};

export const menuItemPurchaseInvoiceButtonWithProps = (t) => {
  return [
    menuItemPayTransactionWithButtonProps(t),
    menuItemViewJournalEntryWithButtonProps(
      t,
      REACT_APP_PURCHASE_INVOICE_JOURNAL_DETAILS_TEMPLATE_URL,
    ),
    menuItemViewHistoryWithButtonProps(
      t,
      REACT_APP_PURCHASE_PURCHASE_INVOICE_DETAILS_HISTORY_TEMPLATE_URL,
    ),
    menuItemPrintPdfWithButtonProps(t),
    menuItemPrintExcelWithButtonProps(t),
  ];
};

export const menuItemPurchaseInvoiceButtonWithPropsV2 = (t, purchaseInvoiceDetails) => {
  return simplifyMenuItemsButton({
    initialMenuItems: menuItemPurchaseInvoiceButtonWithProps(t),
    conditionalValues: [DEFAULT_STATUS_PURCHASE_INVOICE_PAID],
    bodyConditional: purchaseInvoiceDetails,
  });
};

export const menuItemPurchasePaymentButtonWithProps = (t) => {
  return [
    menuItemViewJournalEntryWithButtonProps(
      t,
      REACT_APP_PURCHASE_PAYMENT_JOURNAL_DETAILS_TEMPLATE_URL,
    ),
    menuItemViewHistoryWithButtonProps(
      t,
      REACT_APP_PURCHASE_PURCHASE_PAYMENT_DETAILS_HISTORY_TEMPLATE_URL,
    ),
    menuItemPrintPdfWithButtonProps(t),
    menuItemPrintExcelWithButtonProps(t),
  ];
};

export const menuItemSalesQuotationButtonWithButtonProps = (t) => {
  return [
    menuItemCreateSalesOrderWithButtonProps(t),
    menuItemViewHistoryWithButtonProps(t),
    menuItemPrintPdfWithButtonProps(t),
    menuItemPrintExcelWithButtonProps(t),
  ];
};

export const menuItemSalesQuotationButtonWithButtonPropsV2 = (t, salesQuotationDetails) => {
  return simplifyMenuItemsButton({
    initialMenuItems: menuItemSalesQuotationButtonWithButtonProps(t),
    conditionalValues: [DEFAULT_STATUS_SALES_QUOTATION_CLOSE],
    bodyConditional: salesQuotationDetails,
  });
};

export const menuItemSalesOrderButtonWithButtonProps = (t) => {
  return [
    menuItemCreateSalesDeliveryWithButtonProps(
      t,
      DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_ORDER_DATA,
    ),
    menuItemCreateSalesInvoiceWithButtonProps(
      t,
      DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_ORDER_DATA,
    ),
    menuItemViewHistoryWithButtonProps(t, REACT_APP_SALES_SALES_ORDER_DETAILS_HISTORY_TEMPLATE_URL),
    menuItemPrintPdfWithButtonProps(t),
    menuItemPrintExcelWithButtonProps(t),
  ];
};

export const menuItemSalesOrderButtonWithButtonPropsV2 = (t, salesOrderDetails) => {
  return simplifyMenuItemsButton({
    initialMenuItems: menuItemSalesOrderButtonWithButtonProps(t),
    bodyConditional: salesOrderDetails,
    conditionalValues: [DEFAULT_STATUS_SALES_ORDER_CLOSED],
    countRemoves: [2],
  });
};

export const menuItemSalesDeliveryButtonWithProps = (t) => {
  return [
    menuItemCreateSalesInvoiceWithButtonProps(
      t,
      DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_DELIVERY_DATA,
    ),
    menuItemViewJournalEntryWithButtonProps(
      t,
      REACT_APP_SALES_DELIVERY_JOURNAL_DETAILS_TEMPLATE_URL,
    ),
    menuItemViewHistoryWithButtonProps(
      t,
      REACT_APP_SALES_SALES_DELIVERY_DETAILS_HISTORY_TEMPLATE_URL,
    ),
    menuItemPrintPdfWithButtonProps(t),
    menuItemPrintExcelWithButtonProps(t),
  ];
};

export const menuItemSalesDeliveryButtonWithPropsV2 = (t, salesDeliveryDetails) => {
  return simplifyMenuItemsButton({
    initialMenuItems: menuItemSalesDeliveryButtonWithProps(t),
    bodyConditional: salesDeliveryDetails,
    conditionalValues: [DEFAULT_STATUS_SALES_DELIVERY_CLOSE],
  });
};

export const menuItemSalesInvoiceButtonWithProps = (t) => {
  return [
    menuItemPayTransactionWithButtonProps(t),
    menuItemViewJournalEntryWithButtonProps(
      t,
      REACT_APP_SALES_INVOICE_JOURNAL_DETAILS_TEMPLATE_URL,
    ),
    menuItemViewHistoryWithButtonProps(
      t,
      REACT_APP_SALES_SALES_INVOICE_DETAILS_HISTORY_TEMPLATE_URL,
    ),
    menuItemPrintPdfWithButtonProps(t),
    menuItemPrintExcelWithButtonProps(t),
  ];
};

export const menuItemSalesInvoiceButtonWithPropsV2 = (t, salesInvoiceDetails) => {
  return simplifyMenuItemsButton({
    initialMenuItems: menuItemSalesInvoiceButtonWithProps(t),
    bodyConditional: salesInvoiceDetails,
    conditionalValues: [DEFAULT_STATUS_SALES_INVOICE_PAID],
  });
};

export const menuItemSalesPaymentButtonWithProps = (t) => {
  return [
    menuItemViewJournalEntryWithButtonProps(
      t,
      REACT_APP_SALES_PAYMENT_JOURNAL_DETAILS_TEMPLATE_URL,
    ),
    menuItemViewHistoryWithButtonProps(
      t,
      REACT_APP_SALES_SALES_PAYMENT_DETAILS_HISTORY_TEMPLATE_URL,
    ),
    menuItemPrintPdfWithButtonProps(t),
    menuItemPrintExcelWithButtonProps(t),
  ];
};

export const menuItemPurchaseReturnButtonWithProps = (t) => {
  return [
    menuItemViewJournalEntryWithButtonProps(
      t,
      REACT_APP_PURCHASE_RETURN_JOURNAL_DETAILS_TEMPLATE_URL,
    ),
    menuItemViewHistoryWithButtonProps(t, REACT_APP_PURCHASE_RETURN_JOURNAL_DETAILS_TEMPLATE_URL),
    menuItemPrintPdfWithButtonProps(t),
    menuItemPrintExcelWithButtonProps(t),
  ];
};

export const menuItemSalesOrderButton = (t) => {
  return [
    {
      itemName: DEFAULT_BUTTON_NAME_CREATE_SALES_DELIVERY,
      buttonItemName: t('button-menu.actions.create-delivery'),
    },
    {
      itemName: DEFAULT_BUTTON_NAME_CREATE_SALES_INVOICE,
      buttonItemName: t('button-menu.actions.create-invoice'),
    },
    {
      itemName: DEFAULT_BUTTON_NAME_PRINT_PDF,
      buttonItemName: t('button-menu.print.pdf'),
    },
    {
      itemName: DEFAULT_BUTTON_NAME_PRINT_EXCEL,
      buttonItemName: t('button-menu.print.excel'),
    },
  ];
};

export const menuItemSalesDeliveryButton = (t) => {
  return [
    {
      itemName: DEFAULT_BUTTON_NAME_CREATE_SALES_INVOICE,
      buttonItemName: t('button-menu.actions.create-invoice'),
    },
    {
      itemName: DEFAULT_BUTTON_NAME_VIEW_JOURNAL,
      buttonItemName: t('button-menu.actions.view-journal-entry'),
    },
    {
      itemName: DEFAULT_BUTTON_NAME_PRINT_PDF,
      buttonItemName: t('button-menu.print.pdf'),
    },
    {
      itemName: DEFAULT_BUTTON_NAME_PRINT_EXCEL,
      buttonItemName: t('button-menu.print.excel'),
    },
  ];
};

export const menuItemSalesInvoiceButton = (t) => {
  return [
    {
      itemName: DEFAULT_BUTTON_NAME_ACTION_PAY,
      buttonItemName: t('button-menu.actions.pay'),
    },
    {
      itemName: DEFAULT_BUTTON_NAME_VIEW_JOURNAL,
      buttonItemName: t('button-menu.actions.view-journal-entry'),
    },
    {
      itemName: DEFAULT_BUTTON_NAME_PRINT_PDF,
      buttonItemName: t('button-menu.print.pdf'),
    },
    {
      itemName: DEFAULT_BUTTON_NAME_PRINT_EXCEL,
      buttonItemName: t('button-menu.print.excel'),
    },
  ];
};

export const menuItemSalesPaymentButton = (t) => {
  return [
    {
      itemName: DEFAULT_BUTTON_NAME_VIEW_JOURNAL,
      buttonItemName: t('button-menu.actions.view-journal-entry'),
    },
    {
      itemName: DEFAULT_BUTTON_NAME_PRINT_PDF,
      buttonItemName: t('button-menu.print.pdf'),
    },
    {
      itemName: DEFAULT_BUTTON_NAME_PRINT_EXCEL,
      buttonItemName: t('button-menu.print.excel'),
    },
  ];
};

export const menuItemSalesReturnButtonWithProps = (t) => {
  return [
    menuItemViewJournalEntryWithButtonProps(t, REACT_APP_SALES_RETURN_JOURNAL_DETAILS_TEMPLATE_URL),
    menuItemViewHistoryWithButtonProps(t, REACT_APP_SALES_RETURN_JOURNAL_DETAILS_TEMPLATE_URL),
    menuItemPrintPdfWithButtonProps(t),
    menuItemPrintExcelWithButtonProps(t),
  ];
};

export const menuItemPurchaseHandoverJournalDetailsButtonWithProps = (t) => {
  return [
    menuItemViewJournalEntryWithButtonProps(
      t,
      REACT_APP_PURCHASE_PURCHASE_DELIVERY_HANDOVER_JOURNAL_TEMPLATE_URL,
    ),
    menuItemViewHistoryWithButtonProps(t, REACT_APP_API_URL_HANDOVER_PURCHASE_HISTORY),
  ];
};

export const menuItemSalesHandoverJournalDetailsButtonWithProps = (t) => {
  return [menuItemViewHistoryWithButtonProps(t, REACT_APP_API_URL_HANDOVER_SALES_HISTORY)];
};

export const menuItemPurchaseReceivablePaymentButtonWithProps = (t) => {
  return [
    menuItemViewHistoryWithButtonProps(
      t,
      REACT_APP_PURCHASE_RECEIVABLE_PAYMENT_DETAILS_TEMPLATE_URL,
    ),
  ];
};

export const menuItemSalesPayablePaymentButtonWithProps = (t) => {
  return [
    menuItemViewHistoryWithButtonProps(t, REACT_APP_SALES_PAYABLE_PAYMENT_DETAILS_TEMPLATE_URL),
  ];
};
