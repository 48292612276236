import {
  APPLICATION_BASIC_PERMISSION,
  APPLICATION_PRO_PERMISSION,
  ASSET_CATEGORY_CREATE_PERMISSION_V2,
  ASSET_CATEGORY_READ_PERMISSION_V2,
  ASSET_CREATE_PERMISSION_V2,
  ASSET_READ_PERMISSION_V2,
  ASSET_UPDATE_PERMISSION_V2,
  CASH_PAYMENT_CREATE_PERMISSION_V2,
  CASH_PAYMENT_DELETE_PERMISSION_V2,
  CASH_PAYMENT_UPDATE_PERMISSION_V2,
  CASH_RECEIPT_CREATE_PERMISSION_V2,
  CASH_RECEIPT_DELETE_PERMISSION_V2,
  CASH_RECEIPT_UPDATE_PERMISSION_V2,
  CASH_TRANSFER_CREATE_PERMISSION_V2,
  CASH_TRANSFER_DELETE_PERMISSION_V2,
  CASH_TRANSFER_UPDATE_PERMISSION_V2,
  CHART_CHART_COMPARISON_SALES_PERMISSION_V2,
  CHART_CHART_COMPARISON_TOP_SALES_PERMISSION_V2,
  CHART_CHART_INCOME_STATEMENT_PERMISSION_V2,
  CHART_CHART_PIVOT_TABLE_V2,
  CHART_CHART_PURCHASE_INVOICE_PERMISSION_V2,
  CHART_CHART_SALES_INVOICE_PERMISSION_V2,
  CHART_CHART_TRANSACTION_SALES_PERMISSION_V2,
  CHART_COMPARISON_SALES_PERMISSION,
} from '../constants/permissions.constant';
import {
  additionalDisabledButtonBoxHeaderListComponentProps,
  additionalDisabledComponentProps,
  additionalDisabledRefreshButtonBoxHeaderListComponentProps,
  additionalOmitComponentProps,
} from '../data/display/additional-props';

import {
  DEFAULT_ACTION_MENU_ADD,
  DEFAULT_ACTION_MENU_DELETE,
  DEFAULT_ACTION_MENU_UPDATE,
} from './action-menu.default';
import {
  CHART_INCOME_STATEMENT_TYPE,
  CHART_PRO_DASHBOARD_PIVOT_TABLE_TYPE,
  CHART_PRO_DASHBOARD_PURCHASE_INVOICE_TYPE,
  CHART_PRO_DASHBOARD_SALES_COMPARISON,
  CHART_PRO_DASHBOARD_SALES_INVOICE_TYPE,
  CHART_PRO_DASHBOARD_TOP_SALES_VERTICAL_CHART_MAIN_TYPE,
  CHART_SALES_TRANSACTION_TYPE,
} from './chart-component-type.default';
import {
  CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_MAIN_TYPE,
  CHART_PRO_DASHBOARD_TOP_SALES_MAIN_TYPE,
} from './chart-dashboard.default';
import {
  menuGroupAssetManagementName,
  menuGroupCashPaymentName,
  menuGroupCashReceiptName,
  menuGroupCashTransferName,
  menuGroupDashboardProfessional,
} from './menu-group-name.default';

/**
 *
 *  its about configuration from permission API
 *  to have permission on menu (route), component
 *  and access request action (GET, POST, PUT, DELETE)
 *
 *  */

export const restictAccessPermissionAction = {
  redirect: 'redirect',
  notFound: 'notFound',
  omit: 'omit',
};

export const correlationPermissionAccessingFeature = {
  [APPLICATION_BASIC_PERMISSION]: {
    accessMenu: [],
    accessComponent: [],
    accessRequestAction: [],
  },
  [APPLICATION_PRO_PERMISSION]: {
    accessMenu: [],
    accessComponent: [],
    accessRequestAction: [],
  },
  [CHART_COMPARISON_SALES_PERMISSION]: {
    accessMenu: [],
    accessComponent: [],
    accessRequestAction: [],
  },
  // [CHART_INCOME_STATEMENT_PERMISSION]: {
  //     accessMenu: [],
  //     accessComponent: [],
  //     accessRequestAction: [],
  // },
  // [CHART_PAYABLE_PERMISSION]: {
  //     accessMenu: [],
  //     accessComponent: [],
  //     accessRequestAction: [],
  // },
  // [CHART_PRODUCT_STOCK_PERMISSION]: {
  //     accessMenu: [],
  //     accessComponent: [],
  //     accessRequestAction: [],
  // },
  // [CHART_PURCHASE_PERMISSION]: {
  //     accessMenu: [],
  //     accessComponent: [],
  //     accessRequestAction: [],
  // },
  // [CHART_PURCHASE_INVOICE_PERMISSION]: {
  //     accessMenu: [],
  //     accessComponent: [],
  //     accessRequestAction: [],
  // },
  // [CHART_RECEIVABLE_PERMISSION]: {
  //     accessMenu: [],
  //     accessComponent: [],
  //     accessRequestAction: [],
  // },
  // [CHART_SALES_PERMISSION]: {
  //     accessMenu: [],
  //     accessComponent: [],
  //     accessRequestAction: [],
  // },
  // [CHART_SALES_INVOICE_PERMISSION]: {
  //     accessMenu: [],
  //     accessComponent: [],
  //     accessRequestAction: [],
  // },
  // [CHART_SALES_PERSON_PERMISSION]: {
  //     accessMenu: [],
  //     accessComponent: [],
  //     accessRequestAction: [],
  // },
  // [CHART_TOP_SALES_PERMISSION]: {
  //     accessMenu: [],
  //     accessComponent: [],
  //     accessRequestAction: [],
  // },
  // [CHART_TOP_SALES_COMPARISON_PERMISSION]: {
  //     accessMenu: [],
  //     accessComponent: [],
  //     accessRequestAction: [],
  // },
  // [CHART_TRANSACTION_SALES_PERMISSION]: {
  //     accessMenu: [],
  //     accessComponent: [],
  //     accessRequestAction: [],
  // },
};

export const permissionMenuType = 'menu';
export const permissionMenuGroupType = 'menuGroup';
export const permissionFeatureType = 'feature';

export const restrictAccessPermissionNotFoundAction = 'permission-not-found-action';
export const restrictAccessPermissionOmitAction = 'permission-omit-action';
export const restrictAccessPermissionDisabledAction = 'permission-disabled-action';
export const restrictAccessPermissionRedirectAction = 'permission-redirect-action';

export const permissionTypeMap = {
  [permissionMenuType]: {
    keyname: 'menu',
  },
  [permissionMenuGroupType]: {
    keyname: 'menu_group',
  },
  [permissionFeatureType]: {
    keyname: 'permission',
  },
};

export const restrictAccessPermissionActionTypeMap = {
  [restrictAccessPermissionOmitAction]: {
    [permissionMenuType]: additionalOmitComponentProps,
    [permissionMenuGroupType]: additionalOmitComponentProps,
    [permissionFeatureType]: additionalOmitComponentProps,
  },
  [restrictAccessPermissionNotFoundAction]: {
    [permissionMenuType]: {},
    [permissionMenuGroupType]: {},
    [permissionFeatureType]: {},
  },
  [restrictAccessPermissionDisabledAction]: {
    [permissionMenuType]: additionalDisabledComponentProps,
    [permissionMenuGroupType]: additionalDisabledComponentProps,
    [permissionFeatureType]: additionalDisabledComponentProps,
  },
  [restrictAccessPermissionRedirectAction]: {
    [permissionMenuType]: 'redirect',
    [permissionMenuGroupType]: 'redirect',
    [permissionFeatureType]: 'redirect',
  },
};

export const permissionAPIwithFeatureActionComponentMap = {
  [menuGroupDashboardProfessional]: {
    [CHART_PRO_DASHBOARD_TOP_SALES_VERTICAL_CHART_MAIN_TYPE]: {
      permissionsRequired: [CHART_CHART_COMPARISON_TOP_SALES_PERMISSION_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionOmitAction,
    },
    [CHART_PRO_DASHBOARD_SALES_COMPARISON]: {
      permissionsRequired: [CHART_CHART_COMPARISON_SALES_PERMISSION_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionOmitAction,
    },
    [CHART_PRO_DASHBOARD_PIVOT_TABLE_TYPE]: {
      permissionsRequired: [CHART_CHART_PIVOT_TABLE_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionOmitAction,
    },
    [CHART_PRO_DASHBOARD_TOP_SALES_MAIN_TYPE]: {
      permissionsRequired: [CHART_CHART_COMPARISON_TOP_SALES_PERMISSION_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionOmitAction,
    },
    [CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_MAIN_TYPE]: {
      permissionsRequired: [CHART_CHART_COMPARISON_TOP_SALES_PERMISSION_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionOmitAction,
    },
    [CHART_PRO_DASHBOARD_SALES_INVOICE_TYPE]: {
      permissionsRequired: [CHART_CHART_SALES_INVOICE_PERMISSION_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionOmitAction,
    },
    [CHART_PRO_DASHBOARD_PURCHASE_INVOICE_TYPE]: {
      permissionsRequired: [CHART_CHART_PURCHASE_INVOICE_PERMISSION_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionOmitAction,
    },
    [CHART_SALES_TRANSACTION_TYPE]: {
      permissionsRequired: [CHART_CHART_TRANSACTION_SALES_PERMISSION_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionOmitAction,
    },
    [CHART_INCOME_STATEMENT_TYPE]: {
      permissionsRequired: [CHART_CHART_INCOME_STATEMENT_PERMISSION_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionOmitAction,
    },
  },
  [menuGroupAssetManagementName]: {
    [DEFAULT_ACTION_MENU_ADD]: {
      permissionsRequired: [ASSET_CREATE_PERMISSION_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionDisabledAction,
    },
    [DEFAULT_ACTION_MENU_UPDATE]: {
      permissionsRequired: [ASSET_UPDATE_PERMISSION_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionDisabledAction,
    },
  },
  [menuGroupCashPaymentName]: {
    [DEFAULT_ACTION_MENU_ADD]: {
      permissionsRequired: [CASH_PAYMENT_CREATE_PERMISSION_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionDisabledAction,
    },
    [DEFAULT_ACTION_MENU_UPDATE]: {
      permissionsRequired: [CASH_PAYMENT_UPDATE_PERMISSION_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionDisabledAction,
    },
    [DEFAULT_ACTION_MENU_DELETE]: {
      permissionsRequired: [CASH_PAYMENT_DELETE_PERMISSION_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionDisabledAction,
    },
  },
  [menuGroupCashReceiptName]: {
    [DEFAULT_ACTION_MENU_ADD]: {
      permissionsRequired: [CASH_RECEIPT_CREATE_PERMISSION_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionDisabledAction,
    },
    [DEFAULT_ACTION_MENU_UPDATE]: {
      permissionsRequired: [CASH_RECEIPT_UPDATE_PERMISSION_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionDisabledAction,
    },
    [DEFAULT_ACTION_MENU_DELETE]: {
      permissionsRequired: [CASH_RECEIPT_DELETE_PERMISSION_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionDisabledAction,
    },
  },
  [menuGroupCashTransferName]: {
    [DEFAULT_ACTION_MENU_ADD]: {
      permissionsRequired: [CASH_TRANSFER_CREATE_PERMISSION_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionDisabledAction,
    },
    [DEFAULT_ACTION_MENU_UPDATE]: {
      permissionsRequired: [CASH_TRANSFER_UPDATE_PERMISSION_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionDisabledAction,
    },
    [DEFAULT_ACTION_MENU_DELETE]: {
      permissionsRequired: [CASH_TRANSFER_DELETE_PERMISSION_V2],
      permissionType: permissionFeatureType,
      restrictPermissionActionType: restrictAccessPermissionDisabledAction,
    },
  },
};

export const relationalPermissionRequiredWithComponentProps = {
  [ASSET_CREATE_PERMISSION_V2]: additionalDisabledButtonBoxHeaderListComponentProps,
  [ASSET_READ_PERMISSION_V2]: additionalDisabledRefreshButtonBoxHeaderListComponentProps,
  [ASSET_CATEGORY_CREATE_PERMISSION_V2]: additionalDisabledButtonBoxHeaderListComponentProps,
  [ASSET_CATEGORY_READ_PERMISSION_V2]: additionalDisabledRefreshButtonBoxHeaderListComponentProps,
};
