import * as React from 'react';
function SvgSalesCreditNoteMenu(props) {
  return (
    <svg width={109} height={77} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#sales-credit-note-menu_svg__filter0_d_5586_192247)'>
        <path
          d='M101.75 58.49L27.343 69.925 17.75 13.362 92.157 1.926l9.593 56.563z'
          fill='#F2EF8F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M64.546 64.208l37.204-5.718-9.593-56.564-37.204 5.718 9.593 56.564z'
          fill='#E2DF69'
        />
        <path d='M28.182 27.81l58.063-8.931-.587-3.536-58.063 8.93.587 3.536z' fill='#988534' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M85.566 15.343l-28.53 4.388.572 3.538 28.545-4.39-.587-3.536z'
          fill='#726324'
        />
        <path d='M29.86 36.642l58.061-8.925-.586-3.534-58.062 8.925.586 3.534z' fill='#988534' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M87.335 24.183l-28.776 4.423.572 3.536 28.79-4.425-.586-3.534z'
          fill='#726324'
        />
        <path d='M31.394 46.04l58.064-8.925-.587-3.536-58.064 8.925.587 3.536z' fill='#988534' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.871 33.58l-28.8 4.426.632 3.53 28.755-4.42-.587-3.537z'
          fill='#726324'
        />
        <path d='M64.928 50.213l-32.01 4.922-.59-3.535 32.01-4.922.59 3.535z' fill='#988534' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M62.276 50.62l2.652-.407-.59-3.535-2.706.416.644 3.527z'
          fill='#726324'
        />
        <path
          d='M22.633 61.423h2.4v7.71c0 .479-.537.867-1.2.867-.663 0-1.2-.388-1.2-.867v-7.71z'
          fill='#FFC61B'
        />
        <path
          d='M23.753 61.423v8.571c.027.001.053.006.08.006.663 0 1.2-.388 1.2-.867v-7.71h-1.28zM24.956 57.188l2.286-.529 5.423 12.21c.202.456-.146.943-.777 1.09-.631.145-1.307-.105-1.51-.561l-5.422-12.21z'
          fill='#EAA22F'
        />
        <path
          d='M22.71 57.188l-2.286-.529-5.422 12.21c-.203.456.145.943.776 1.09.631.145 1.307-.105 1.51-.561l5.421-12.21z'
          fill='#FFC61B'
        />
        <path d='M43.636 39.028H4.03V60.86h39.606V39.028z' fill='#fff' />
        <path d='M43.636 39.028H23.754V60.86h19.882V39.028z' fill='#D0D1D3' />
        <path
          d='M25.814 37.859h-3.961v-1.43c0-.789.887-1.429 1.98-1.429 1.094 0 1.98.64 1.98 1.43v1.429z'
          fill='#FFC61B'
        />
        <path
          d='M25.814 36.43c0-.79-.887-1.43-1.98-1.43-.027 0-.054.002-.08.003v2.856h2.06v-1.43z'
          fill='#EAA22F'
        />
        <path
          d='M44.076 40.184H3.59c-1.016 0-1.84-.595-1.84-1.329 0-.733.824-1.328 1.84-1.328h40.486c1.017 0 1.84.594 1.84 1.328 0 .734-.824 1.329-1.84 1.329zM44.076 62.073H3.59c-1.016 0-1.84-.595-1.84-1.328 0-.734.824-1.329 1.84-1.329h40.486c1.017 0 1.84.595 1.84 1.329 0 .733-.824 1.328-1.84 1.328z'
          fill='#71E2EF'
        />
        <path
          d='M44.076 37.527H23.753v2.657h20.323c1.017 0 1.84-.595 1.84-1.329s-.823-1.328-1.84-1.328zM44.076 59.416H23.753v2.657h20.323c1.017 0 1.84-.595 1.84-1.328 0-.734-.823-1.329-1.84-1.329z'
          fill='#38C6D9'
        />
        <path
          d='M36.924 46.44l-10.17 5.673-9.947-2.394L8.5 54.304l.73.688 7.8-4.306 9.952 2.395 10.676-5.955-.734-.686z'
          fill='#59595B'
        />
        <path
          d='M17.03 50.686l-7.8 4.306-.73-.688 8.307-4.585 6.947 1.672v.913l-6.723-1.618z'
          fill='#59595B'
        />
        <path
          d='M26.981 53.08l-3.228-.776v-.913l3.001.722 10.17-5.673.733.686-10.676 5.955z'
          fill='#272525'
        />
        <path
          d='M26.867 51.4c.916 0 1.658.536 1.658 1.197s-.742 1.197-1.658 1.197c-.916 0-1.658-.536-1.658-1.197s.742-1.197 1.658-1.197z'
          fill='#C92F00'
        />
        <path
          d='M16.918 49.005c.916 0 1.658.536 1.658 1.197 0 .662-.742 1.197-1.658 1.197-.916 0-1.658-.535-1.658-1.197 0-.66.742-1.197 1.658-1.197z'
          fill='#FF7F4F'
        />
        <path
          d='M37.29 45.585c.915 0 1.658.536 1.658 1.197s-.743 1.197-1.659 1.197c-.915 0-1.658-.536-1.658-1.197 0-.66.743-1.197 1.658-1.197z'
          fill='#EAA22F'
        />
        <path
          d='M8.865 53.452c.916 0 1.658.535 1.658 1.196 0 .661-.742 1.197-1.658 1.197-.916 0-1.658-.535-1.658-1.197 0-.66.742-1.197 1.658-1.197z'
          fill='#FF314F'
        />
      </g>
      <defs>
        <filter
          id='sales-credit-note-menu_svg__filter0_d_5586_192247'
          x={0.75}
          y={0.926}
          width={108}
          height={76.074}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_192247' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_192247' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgSalesCreditNoteMenu;
