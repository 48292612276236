import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';

import formatHelp from '../../../utils/helpers/format.helpers';
import strHelp from '../../../utils/helpers/string.helpers';

export const defaultListItemProductProps = {
  productIdentity: 'product_id',
  productCode: 'product_code',
  productName: 'product_name',
  productUnit: 'product_unit',
  productPrice: 'purchase_price',
  quantityAvailable: 'quantity_written',
  templateQuantityAvailableWithUnit: '{quantityAvailable} {productUnit}',
};

export const defaultListItemStockAdjustmentProps = {
  ...defaultListItemProductProps,
  quantityAvailable: 'quantity_real',
};

export const defaultListItemProductSalesProps = {
  ...defaultListItemProductProps,
  productUnit: 'unit',
  productPrice: 'sales_price',
  quantityAvailable: 'quantity_real',
};

export const defaultListItemProductPurchaseProps = {
  ...defaultListItemProductProps,
  productUnit: 'unit',
  productPrice: 'purchase_price',
};

/**
 *
 * @param { object } props
 *
 *
 * inside props usage keyname for
 * @param   { object }  renderOptionProps       passing this from native mui render option props
 * @param   { object }  option                  passing this from native mui option
 * @param   { object }  productProps            usage for getting key name from product
 *      available keyname for product props
 *         productCode                              keyname for product code
 *         productName                              keyname for product name
 *         productUnit                              keyname for product unit
 *         productPrice                             keyname for product price
 *         quantityAvailable                        keyname for quantity available
 *         templateQuantityAvailableWithUnit        template for showing quantity available with unit
 *
 * layout showing user
 *      productCode          quantityAvailableWithUnit
 *      productName          productPrice
 *
 * @returns
 */
function ListItemProduct(props) {
  const { renderOptionProps, option, productProps: customProductProps } = props;

  const {
    productIdentity: productIdentityKeyname,
    productCode: productCodeKeyname,
    productName: productNameKeyname,
    productUnit: productUnitKeyname,
    productPrice: productPriceKeyname,
    quantityAvailable: quantityAvailableKeyname,
    templateQuantityAvailableWithUnit,
  } = {
    ...defaultListItemProductProps,
    ...customProductProps,
  };

  const productIdentity = option[productIdentityKeyname];
  const productCode = option[productCodeKeyname];
  const productName = option[productNameKeyname];
  const productUnit = option[productUnitKeyname] || '';
  const productPrice = formatHelp.currencyWithAutoComma(Number(option[productPriceKeyname]));
  const quantityAvailable = Number(option[quantityAvailableKeyname]) || 0;

  const quantityAvailableWithUnit = strHelp.templateString(templateQuantityAvailableWithUnit, {
    quantityAvailable,
    productUnit,
  });

  return (
    <li {...renderOptionProps} key={productIdentity + productName}>
      <Box flexGrow={1}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Stack alignItems={'start'}>
            <Typography component={'p'} variant='subtitle8'>
              {productCode}
            </Typography>
            <Typography component={'p'} variant='body4'>
              {productName}
            </Typography>
          </Stack>
          <Stack alignItems={'end'}>
            <Typography component={'p'} variant='subtitle8'>
              {quantityAvailableWithUnit}
            </Typography>
            <Typography component={'p'} variant='body4'>
              {productPrice}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </li>
  );
}

export default ListItemProduct;
