import { additionalTransactionProUtils } from '../../default/transaction-professional-config.default';
import {
  DEFAULT_TRANSACTION_PURCHASE_DELIVERY_API_REF_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_HANDOVER_API_REF_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_INVOICE_API_REF_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_ORDER_API_REF_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_PAYMENT_API_REF_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_API_REF_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_RECEIVABLE_PAYMENT_API_REF_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_RETURN_API_REF_TYPE,
  DEFAULT_TRANSACTION_SALES_DELIVERY_API_REF_TYPE,
  DEFAULT_TRANSACTION_SALES_HANDOVER_API_REF_TYPE,
  DEFAULT_TRANSACTION_SALES_INVOICE_API_REF_TYPE,
  DEFAULT_TRANSACTION_SALES_ORDER_API_REF_TYPE,
  DEFAULT_TRANSACTION_SALES_PAYABLE_PAYMENT_API_REF_TYPE,
  DEFAULT_TRANSACTION_SALES_PAYMENT_API_REF_TYPE,
  DEFAULT_TRANSACTION_SALES_QUOTATION_API_REF_TYPE,
  DEFAULT_TRANSACTION_SALES_RETURN_API_REF_TYPE,
  DEFAULT_TRANSACTION_STOCK_ADJUSTMENT_API_REF_TYPE,
} from '../../default/transaction-type';
import objHelper from '../../helpers/object.helper';

export const transactionTypeWithTranslation = {
  [DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_API_REF_TYPE]:
    'base.transaction-type.purchase-quotation-request',
  [DEFAULT_TRANSACTION_PURCHASE_ORDER_API_REF_TYPE]: 'base.transaction-type.purchase-order',
  [DEFAULT_TRANSACTION_PURCHASE_DELIVERY_API_REF_TYPE]: 'base.transaction-type.purchase-delivery',
  [DEFAULT_TRANSACTION_PURCHASE_INVOICE_API_REF_TYPE]: 'base.transaction-type.purchase-invoice',
  [DEFAULT_TRANSACTION_PURCHASE_PAYMENT_API_REF_TYPE]: 'base.transaction-type.purchase-payment',
  [DEFAULT_TRANSACTION_PURCHASE_RECEIVABLE_PAYMENT_API_REF_TYPE]:
    'base.transaction-type.receivable-payment',
  [DEFAULT_TRANSACTION_PURCHASE_RETURN_API_REF_TYPE]: 'base.transaction-type.purchase-return',
  [DEFAULT_TRANSACTION_SALES_QUOTATION_API_REF_TYPE]: 'base.transaction-type.sales-quotation',
  [DEFAULT_TRANSACTION_SALES_ORDER_API_REF_TYPE]: 'base.transaction-type.sales-order',
  [DEFAULT_TRANSACTION_SALES_DELIVERY_API_REF_TYPE]: 'base.transaction-type.sales-delivery',
  [DEFAULT_TRANSACTION_SALES_INVOICE_API_REF_TYPE]: 'base.transaction-type.sales-invoice',
  [DEFAULT_TRANSACTION_SALES_PAYMENT_API_REF_TYPE]: 'base.transaction-type.sales-payment',
  [DEFAULT_TRANSACTION_SALES_PAYABLE_PAYMENT_API_REF_TYPE]:
    'base.transaction-type.sales-payable-payment',
  [DEFAULT_TRANSACTION_SALES_RETURN_API_REF_TYPE]: 'base.transaction-type.sales-return',
  [DEFAULT_TRANSACTION_PURCHASE_HANDOVER_API_REF_TYPE]: 'base.transaction-type.purchase-handover',
  [DEFAULT_TRANSACTION_SALES_HANDOVER_API_REF_TYPE]: 'base.transaction-type.sales-handover',
  [DEFAULT_TRANSACTION_STOCK_ADJUSTMENT_API_REF_TYPE]: 'base.transaction-type.stock-adjustment',
};

export const transactionTypeWithTranslationV2 = objHelper.reduceObjValuesByKeyname({
  obj: additionalTransactionProUtils,
  keynameTarget: 'typographyTranslation',
});
