import axios from 'axios';
import { pick } from 'lodash';

import {
  REACT_APP_API_URL_MANAGEMENT_INVITE,
  REACT_APP_API_URL_MANAGEMENT_INVITE_APPROVAL,
  REACT_APP_API_URL_MANAGEMENT_USER,
  REACT_APP_API_URL_MANAGEMENT_USER_DETAILS,
} from '../../../utils/configs/api.config';
import { DEFAULT_MANAGEMENT_USER_LIST_PARAMS } from '../../../utils/default/params.default';
import { authHeader } from '../../../utils/helpers/auth-header.helpers';
import strHelp from '../../../utils/helpers/string.helpers';
import {
  creatorAddService,
  creatorDetailServiceV2,
  creatorListService,
} from '../creator-services/creator-services.helper';

// list all users in company
const listUsers = async ({ page = 1, paginate = 20, user_fullname, email, company_id, role }) => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  const paramsListUser = {
    page,
    paginate,
  };

  if (user_fullname) {
    paramsListUser.user_fullname = encodeURIComponent(user_fullname);
  }

  if (company_id) {
    paramsListUser.company_id = company_id;
  }

  if (email) {
    paramsListUser.email = email;
  }

  if (role) {
    if (role.length) {
      paramsListUser.role = role;
    }
  }

  return await axios({
    method: 'GET',
    url: `${REACT_APP_API_URL_MANAGEMENT_USER}`,
    headers: {
      Authorization,
    },
    params: {
      ...paramsListUser,
    },
  });
};

// service getting user list
// same as above but for avoid crashing on already used function
const getUsersList = async (paramsGetUsersList) => {
  return await creatorListService(
    REACT_APP_API_URL_MANAGEMENT_USER,
    paramsGetUsersList,
    DEFAULT_MANAGEMENT_USER_LIST_PARAMS,
  );
};

const getManagementUserDetails = async (paramsGetManagementUserDetails) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_MANAGEMENT_USER_DETAILS,
    paramsGetManagementUserDetails,
  );
};

// updating user in company with id
const updateUserWithID = async (dataForUpdate) => {
  const { Authorization } = authHeader();

  const { user_id } = dataForUpdate;

  if (!user_id || !Authorization || !dataForUpdate) {
    return null;
  }

  let dataUpdate = {
    user_fullname: dataForUpdate.user_fullname,
    role: dataForUpdate.role,
  };

  if (dataForUpdate.profile_picture) {
    dataUpdate = dataForUpdate.profile_picture;
  }

  if (dataForUpdate.address) {
    dataUpdate.address = dataForUpdate.address;
  }

  if (dataForUpdate.password && dataForUpdate.password_confirmation) {
    dataUpdate.password = dataForUpdate;
    dataUpdate.password_confirmation = dataForUpdate.password_confirmation;
  }

  // changing only for profile picture
  return await axios({
    method: 'POST',
    url: `${REACT_APP_API_URL_MANAGEMENT_USER}/${user_id}`,
    headers: {
      Authorization,
    },
    data: {
      ...dataUpdate,
    },
  });
};

// add user in company
const addUserCompany = async (addUserCompanyData) => {
  const { Authorization } = authHeader();

  if (!Authorization || !addUserCompanyData) {
    return null;
  }

  return await axios({
    method: 'POST',
    url: REACT_APP_API_URL_MANAGEMENT_USER,
    headers: {
      Authorization,
    },
    data: {
      ...addUserCompanyData,
    },
  });
};

// delete user in company with id
const deleteUserWithID = async ({ user_id, company_id }) => {
  // get header auth
  const { Authorization } = authHeader();

  // only can hit api with token and user id and company id
  if (!Authorization || !user_id || !company_id) {
    return null;
  }

  return await axios({
    method: 'DELETE',
    url: `${REACT_APP_API_URL_MANAGEMENT_USER}/${user_id}`,
    headers: {
      Authorization,
    },
    data: {
      user_id,
      company_id,
    },
  });
};

// service for add role to user
const assignmentRoleToUser = async (bodyAssignmentRoleToUser) => {
  return await creatorAddService(REACT_APP_API_URL_MANAGEMENT_USER, bodyAssignmentRoleToUser);
};

// management invite user
const managementApprovalInviteUser = async (bodyManagementApprovalUser) => {
  const url = strHelp.templateString(
    REACT_APP_API_URL_MANAGEMENT_INVITE_APPROVAL,
    bodyManagementApprovalUser,
  );
  bodyManagementApprovalUser = pick(bodyManagementApprovalUser, ['accept']);

  return await creatorAddService(url, bodyManagementApprovalUser);
};

// servic management user invitation
const managementInviteUser = async (bodyManagementInviteUser) => {
  return await creatorAddService(REACT_APP_API_URL_MANAGEMENT_INVITE, bodyManagementInviteUser);
};

const managementUsersService = {
  listUsers,
  getUsersList,
  addUserCompany,
  getManagementUserDetails,
  updateUserWithID,
  deleteUserWithID,
  assignmentRoleToUser,
  managementInviteUser,
  managementApprovalInviteUser,
};

export default managementUsersService;
