import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_DISCOUNT_PERCENTAGE_TYPE } from '../../default/discount-type.default';
import {
  DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_PURCHASE_INVOICE_AMOUNT_AVAILABLE_API_REF,
  DEFAULT_KEY_NAME_PURCHASE_RETURN_PRODUCT_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import arrHelp from '../../helpers/array.helpers';
import formulaHelpers from '../../helpers/formula.helpers';
import objHelper from '../../helpers/object.helper';
import parseResponseHelpers from '../../helpers/parse-response.helpers';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import { generalizeAttachmentFromDetails } from './initial-add-attachment';
import {
  generalizeDataAddPurchaseReturn,
  generateDataNvalidationAddProductPurchaseReturn,
  generateDataNvalidationAddPurchaseReturn,
} from './initial-add-purchase-return';
import {
  initialDataProductPurchaseReturn,
  initialDataPurchaseReturn,
  initialValidationProductPurchaseReturn,
  initialValidationPurchaseReturn,
  productPurchaseReturnCalculationNumberKeyNames,
  purchaseReturnCalculationNumberKeyNames,
} from './initial-data-purchase-return';

/**
 * usaged for generate data and validation for purchase return
 * that came from purchase return details data
 *
 * @param   { object } param0         object as params function
 *
 * @param   { object }  purchaseReturnDetails              current data from purchase return details
 * @param   { object }  companyUserData                    current company user data
 * @param   { string }  dataKeyname                        return keyname for purchase return update
 * @param   { string }  validationKeyname                  return validation keyname for purchase return update
 *
 * @returns
 */
export const generateDataNvalidationUpdatePurchaseReturnFromPurchaseReturnDetails = ({
  purchaseReturnDetails = {},
  companyUserData = {},
  dataKeyname = 'purchaseReturnData',
  validationKeyname = 'purchaseReturnValidation',
}) => {
  if (isEmpty(purchaseReturnDetails)) {
    return generateDataNvalidationAddPurchaseReturn({
      amount: 1,
      dataKeyname,
      validationKeyname,
    });
  }

  purchaseReturnDetails = objHelper.changeFormatValue(
    purchaseReturnDetails,
    purchaseReturnCalculationNumberKeyNames,
    [(value) => Number(value) || ''],
  );

  const {
    supplier_id,
    supplier_name: contact_name,
    supplier_code: contact_code,
    supplier_address,
    transaction_date,
    transaction_no,
    purchase_invoice_id,
    purchase_invoice_no,
    purchase_invoice_transaction_date,
    amount,
    description,
  } = purchaseReturnDetails;

  const usagedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const purchaseReturnData = usagedID.map((id) => {
    let transaction_no_container = {
      value: transaction_no,
    };

    let accounting_period = null;
    if (!isEmpty(companyUserData) && !!companyUserData.accounting_periode) {
      accounting_period = companyUserData.accounting_periode;
    }

    const subtotal = purchaseReturnDetails[DEFAULT_KEY_NAME_PURCHASE_RETURN_PRODUCT_API_REF].reduce(
      (resSubtotal, currProduct) => {
        const { quantity_origin, price } = currProduct;

        return resSubtotal + Number(quantity_origin) * Number(price);
      },
      0,
    );

    return {
      ...initialDataPurchaseReturn(id),
      ...purchaseReturnDetails,
      accounting_period,
      transaction_no_container,
      invoiceNumber: purchase_invoice_no || '',
      transaction_date: transaction_date ? moment(transaction_date) : null,
      purchase_invoice_id_container: {
        purchase_invoice_id,
        purchase_invoice_no,
      },
      amount_const: Number(amount) || 0,
      supplier_id_container: {
        contact_id: supplier_id,
        contact_code,
        contact_name,
      },
      supplier_address: supplier_address || '',
      description: description || '',
      discount_account_id_container: {
        account_id: purchaseReturnDetails.discount_account_id || '',
        account_name: purchaseReturnDetails.discount_account_name || '',
        account_code: purchaseReturnDetails.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: purchaseReturnDetails.expense_account_id || '',
        account_name: purchaseReturnDetails.expense_account_name || '',
        account_code: purchaseReturnDetails.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: purchaseReturnDetails.down_payment_account_id || '',
        account_name: purchaseReturnDetails.down_payment_account_name || '',
        account_code: purchaseReturnDetails.down_payment_account_code || '',
      },
      attachment: generalizeAttachmentFromDetails({
        dataDetails: purchaseReturnDetails,
      }),
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        purchase_invoice_transaction_date ||
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
      invoice_total:
        Number(amount) +
        Number(purchaseReturnDetails[DEFAULT_KEY_NAME_PURCHASE_INVOICE_AMOUNT_AVAILABLE_API_REF]),
      discount_type: DEFAULT_DISCOUNT_PERCENTAGE_TYPE, // force discount type
      discount_percentage: formulaHelpers.calculateDiscountPercentage({
        parentData: {
          subtotal,
          ...purchaseReturnDetails,
        },
        amountKeyname: 'subtotal',
      }),
      ...parseResponseHelpers.tagContainerInputFromDetails(purchaseReturnDetails),
    };
  });

  const purchaseReturnValidation = usagedID.map((id) => {
    return initialValidationPurchaseReturn(id);
  });

  return {
    [dataKeyname]: purchaseReturnData,
    [validationKeyname]: purchaseReturnValidation,
  };
};

/**
 *
 * generate data and validation for update product purchase return
 * where came from purchase return details
 *
 * @param { object } param0         params object for function
 *
 * @param   { object }      purchaseReturnDetails               target of purchase return details
 * @param   { object }      dataKeyname                         return data keyname for product purchase return
 * @param   { object }      validationKeyname                   return validation keyname for product purchase return
 *
 * @returns
 */
export const generateDataNvalidationUpdateProductPurchaseReturnFromPurchaseReturnDetails = ({
  purchaseReturnDetails = {},
  dataKeyname = 'productPurchaseReturnData',
  validationKeyname = 'productPurchaseReturnValidation',
}) => {
  const purchaseReturnProduct = isEmpty(purchaseReturnDetails)
    ? null
    : purchaseReturnDetails[DEFAULT_KEY_NAME_PURCHASE_RETURN_PRODUCT_API_REF];

  if (isEmpty(purchaseReturnProduct)) {
    return generateDataNvalidationAddProductPurchaseReturn({
      amount: 2,
      dataKeyname,
      validationKeyname,
    });
  }

  const usagedID = arrHelp.generateArrWithFunc(purchaseReturnProduct.length, uuidv4);

  const productPurchaseReturnData = usagedID.map((id, index) => {
    let selectedProductPurchaseReturn = purchaseReturnProduct[index];

    if (!isEmpty(selectedProductPurchaseReturn)) {
      selectedProductPurchaseReturn = objHelper.changeFormatValue(
        selectedProductPurchaseReturn,
        productPurchaseReturnCalculationNumberKeyNames,
        [(value) => Number(value) || ''],
      );
    }

    const {
      product_id,
      product_name,
      product_code,
      quantity,
      quantity_origin = 0,
      price,
      description,
      product_unit,
      unit_origin,
      product_invoice_quantity,
      product_invoice_quantity_available = 0,
    } = selectedProductPurchaseReturn;

    const amount = Number(quantity) * Number(price);
    const quantity_available_const =
      product_invoice_quantity !== undefined ? Number(product_invoice_quantity) : Number(quantity);

    return {
      ...initialDataProductPurchaseReturn(id),
      ...formulaHelpers.parseProductAPI2display({
        product: selectedProductPurchaseReturn,
      }),
      ...selectedProductPurchaseReturn,
      product_id_container: {
        product_id,
        product_name,
        product_code,
        ...selectedProductPurchaseReturn,
      },
      description: description || '',
      quantity_available_const,
      amount,
      quantity_available_with_unit: `${
        Number(product_invoice_quantity_available) + Number(quantity_origin)
      } ${unit_origin || product_unit}`,
    };
  });

  const productPurchaseReturnValidation = usagedID.map((id) =>
    initialValidationProductPurchaseReturn(id),
  );

  return {
    [dataKeyname]: productPurchaseReturnData,
    [validationKeyname]: productPurchaseReturnValidation,
  };
};

/**
 *
 * @param { array }     purchaseReturnData                  target generalize data for update purchase return
 * @param { array }     productPurchaseReturnData           product purchase return data for generalized
 * @returns
 */
export const generalizeDataUpdatePurchaseReturn = (
  purchaseReturnData,
  productPurchaseReturnData,
) => {
  const { purchase_return_id } = cloneDeep(purchaseReturnData[0]);

  const generalizedDataPurchaseReturn = generalizeDataAddPurchaseReturn(
    purchaseReturnData,
    productPurchaseReturnData,
  );

  return {
    purchase_return_id,
    ...generalizedDataPurchaseReturn,
  };
};
