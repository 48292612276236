import React, { PureComponent } from 'react';
import { CircularProgress, InputAdornment } from '@mui/material';
import { withStyles } from '@mui/styles';
import { PropTypes } from 'prop-types';

import { styles } from './InputAdornmentLoading.style';

class InputAdornmentLoading extends PureComponent {
  render() {
    const { isLoading, defaultInputAdornment, classes } = this.props;

    return (
      <>
        {isLoading ? (
          <InputAdornment position='end' className={classes.inputAdornmentContainerIcon}>
            <CircularProgress color='inherit' size={16} />
          </InputAdornment>
        ) : (
          defaultInputAdornment
        )}
      </>
    );
  }
}

InputAdornmentLoading.propsType = {
  isLoading: PropTypes.bool,
  defaultInputAdornment: PropTypes.node.isRequired,
  additonalPropsInputAdornment: PropTypes.object,
};

InputAdornmentLoading.defaultProps = {
  isLoading: false,
  additonalPropsInputAdornment: {},
};

// adding styling into component
const stylingInputAdornmentLoading = withStyles(styles)(InputAdornmentLoading);

export default stylingInputAdornmentLoading;
