import { isEmpty } from 'lodash';

import {
  DEFAULT_DISCOUNT_NOMINAL_TYPE,
  DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
} from '../default/discount-type.default';
import { NORMAL_BALANCE_ACCOUNT_CREDIT_UPPERCASE } from '../default/normal-balance-account.default';
import { DEFAULT_COMPARISON_VALUE_NUMBER } from '../default/number.default';
import {
  DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
  DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
  DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE,
  DEFAULT_KEY_NAME_EXPENSE_VALUE,
} from '../default/object-keyname.default';
import { hasImplementedUnitProductUnit } from '../default/unit-product.default';

import formatHelp from './format.helpers';
import strHelp from './string.helpers';

/**
 *
 * objective:
 * calculate additional cost
 *
 * additional cost = amount (total) - discount + expense - downpayment
 *
 *
 * @param {*} param0
 * @returns
 */
function calculateAdditionalcostFromObject({
  obj = {},
  amountKeyname = 'amount',
  expenseKeyname = 'expense_value',
  discountNominalKeyname = 'discount_nominal',
  discountPercentageKeyname = 'discount_percentage',
  downpaymentKeyname = 'down_payment_value',
  usageDiscountNominal = true,
}) {
  if (isEmpty(obj) || !amountKeyname || !expenseKeyname || !downpaymentKeyname) return 0;

  const amount = Number(obj[amountKeyname] || 0);
  const discountNominal = Number(obj[discountNominalKeyname] || 0);
  const discountPercentage = Number(obj[discountPercentageKeyname] || 0);
  const expense = Number(obj[expenseKeyname] || 0);
  const downpayment = Number(obj[downpaymentKeyname] || 0);

  let additionalCost = amount;

  if (usageDiscountNominal) additionalCost -= discountNominal;
  else if (!usageDiscountNominal && discountPercentage) {
    const amountDiscount = amount * (discountPercentage / 100);

    additionalCost -= amountDiscount;
  }

  return additionalCost + expense - downpayment;
}

/**
 *
 * @param       { object }      parentData      as parent that handling recent discount type
 * @param       { array }       childrenData    where row for handling total children data
 *
 * @param {object} param0
 * @returns
 */
function calculateDiscountAndTotalOfChildrenData({
  parentData = {},
  childrenData = [],
  subTotalChildrenKeyname = 'amount',
  mappingDiscountKeynameWithDiscountType = {
    [DEFAULT_DISCOUNT_NOMINAL_TYPE]: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
    [DEFAULT_DISCOUNT_PERCENTAGE_TYPE]: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
  },
  discountTypeKeyname = 'discount_type',
  discountNominalKeyname = DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
  discountPercentageKeyname = DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
  totalParentDataKeyname = 'amount',
  totalConstParentKeyname = 'amount_const',
  expenseValueKeyname = DEFAULT_KEY_NAME_EXPENSE_VALUE,
  downpaymentValueKeyname = DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE,
}) {
  if (
    isEmpty(parentData) ||
    isEmpty(childrenData) ||
    (!isEmpty(parentData) &&
      !Object.keys(mappingDiscountKeynameWithDiscountType).includes(
        parentData[discountTypeKeyname],
      )) ||
    (!isEmpty(childrenData) && !Array.isArray(childrenData))
  ) {
    return {
      [discountNominalKeyname]: 0,
      [discountPercentageKeyname]: 0,
      [totalParentDataKeyname]: 0,
      [totalConstParentKeyname]: 0,
    };
  }

  const totalParentData = childrenData.reduce((currAmount, currChildren) => {
    return Number(currAmount) + Number(currChildren[subTotalChildrenKeyname]);
  }, 0);

  const activeDiscount = mappingDiscountKeynameWithDiscountType[parentData[discountTypeKeyname]];

  let discountNominal = 0,
    discountPercentage = 0;

  if (activeDiscount === DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF) {
    discountNominal = Number(parentData[activeDiscount]);
    discountPercentage = (discountNominal / totalParentData) * 100;
  } else if (activeDiscount === DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF) {
    discountPercentage = Number(parentData[activeDiscount]);
    discountNominal = (totalParentData * discountPercentage) / 100;
  }

  const expenseValue = !Number.isNaN(Number(parentData[expenseValueKeyname]))
    ? Number(parentData[expenseValueKeyname])
    : 0;

  const downpaymentValue = !Number.isNaN(Number(parentData[downpaymentValueKeyname]))
    ? Number(parentData[downpaymentValueKeyname])
    : 0;

  return {
    [totalParentDataKeyname]:
      totalParentData - discountNominal + Number(expenseValue) - Number(downpaymentValue),
    [discountNominalKeyname]: discountNominal,
    [discountPercentageKeyname]: discountPercentage,
    [totalConstParentKeyname]: totalParentData,
  };
}

/**
 *
 * objective: calculate after total general ledger account
 *
 * where: beginning balance + (debit-credit / credit-debit)
 *
 * @param { object } param0
 */
function calculateTotalBalanceGeneralLedger({
  obj = {},
  afterCreditAmountKeyname = 'after_credit_amount',
  afterDebitAmountKeyname = 'after_debit_amount',
  beforeTotalKeyname = 'before_total',
} = {}) {
  if (isEmpty(obj)) return;

  const {
    [afterCreditAmountKeyname]: afterCreditAmount = 0,
    [afterDebitAmountKeyname]: afterDebitAmount = 0,
    [beforeTotalKeyname]: beforeTotal = 0,
  } = obj;

  return Number(beforeTotal) - Number(afterCreditAmount) + Number(afterDebitAmount);
}

/**
 *
 * calculate discount percentage from amount and discount nominal
 *
 * @param {object} param0
 * @returns
 */
function calculateDiscountPercentage({
  parentData = {},
  amountKeyname = 'amount',
  discountNominalKeyname = 'discount_nominal',
}) {
  if (isEmpty(parentData)) return 0;

  const amount = Number(parentData[amountKeyname]);
  const discountNominal = Number(parentData[discountNominalKeyname]);

  return (discountNominal / amount) * 100;
}

/**
 *
 * calculate amount invoice available, that uses for amount invoice return
 * where value from api with keyname: '[transaction_type]_invoice_amount_available'
 *
 * formula = amount - discount + expense - paid - return
 *
 * @param { object } param0
 * @returns
 */
function calculateAmountInvoiceReturn({
  parentData = {},
  amountKeyname = 'amount',
  discountNominalKeyname = 'discount_nominal',
  expenseValueKeyname = 'expense_value',
  amountPaidKeyname = 'amount_paid',
  amountReturnKeyname = 'amount_return',
}) {
  if (isEmpty(parentData)) return 0;

  return (
    Number(parentData[amountKeyname]) -
    Number(parentData[discountNominalKeyname]) +
    Number(parentData[expenseValueKeyname]) -
    Number(parentData[amountPaidKeyname]) -
    Number(parentData[amountReturnKeyname])
  );
}

/**
 *
 * @param { object } param0
 *
 * @param { string }    product                         product data
 *
 * keyname that come from api response
 * @param { string }    quantityAPIkeyname
 * @param { string }    quantityAvailableAPIkeyname
 * @param { string }    quantityOriginAPIkeyname
 * @param { string }    productUnitAPIkeyname
 * @param { string }    unitDestinationAPIkeyname
 * @param { string }    unitOriginAPIkeyname
 * @param { string }    quantityResultKeyname
 *
 * keyname for returned data
 * @param { string }    quantityAvailableResultKeyname
 * @param { string }    quantityAvailableConstResultKeyname
 * @param { string }    quantityComparisonResultKeyname
 * @param { string }    quantityComparisonConstPropsResultKeyname
 * @param { string }    unitDestinationResultKeyname
 * @param { string }    unitOriginResultKeyname
 * @param { string }    quantityAvailableWithUnitResultKeyname
 *
 * @returns
 *
 * data for initialize from API response
 */
function parseProductAPI2display({
  product = {},
  // keyname that come from API
  quantityAPIkeyname = 'quantity',
  quantityAvailableAPIkeyname = 'quantity_available',
  quantityOriginAPIkeyname = 'quantity_origin',
  productUnitAPIkeyname = 'product_unit',
  unitDestinationAPIkeyname = 'unit_destination',
  unitOriginAPIkeyname = 'unit_origin',
  priceAPIkeyname = 'price',
  modulQuantityAvailableAPIkeyname = 'modul_quantity_available',
  // result function for object
  quantityResultKeyname = 'quantity',
  quantityAvailableResultKeyname = 'quantity_available',
  quantityAvailableConstResultKeyname = 'quantity_available_const',
  quantityComparisonResultKeyname = 'quantity_comparison',
  quantityComparisonConstPropsResultKeyname = 'quantity_comparison_const_props',
  unitDestinationResultKeyname = 'unit_destination',
  unitOriginResultKeyname = 'unit_origin',
  quantityAvailableWithUnitResultKeyname = 'quantity_available_with_unit',
  priceResultKeyname = 'price',
  amountResultKeyname = 'amount', // calculation price API to usages quantity available
  compareQuantityAvailableToModulQuantityAvailable = false,
  comparePriceToQuantityComparison = false, // determine to compare on quantity origin / quantity destination
  swapQuantityOriginWithQuantity = false, // determine for use quantity resuult on quantity origin on viewing with unit
} = {}) {
  if (isEmpty(product)) return product;

  const {
    [quantityAPIkeyname]: quantity,
    [quantityAvailableAPIkeyname]: quantityAvailable,
    [quantityOriginAPIkeyname]: quantityOrigin,
    [productUnitAPIkeyname]: productUnit,
    [unitDestinationAPIkeyname]: unitDestination,
    [unitOriginAPIkeyname]: unitOrigin,
    [modulQuantityAvailableAPIkeyname]: modulQuantityAvailable,
    [priceAPIkeyname]: price,
  } = product;

  const quantityComparison = hasImplementedUnitProductUnit
    ? Number(quantityOrigin) / Number(quantity)
    : DEFAULT_COMPARISON_VALUE_NUMBER;

  // compare modul quantity available with quantity available
  // when compare quantity available to modul quantity available true
  //  getting smaller when compare
  let usagedQuantityAvailable = Number(quantityAvailable);

  if (compareQuantityAvailableToModulQuantityAvailable) {
    usagedQuantityAvailable = Math.min(Number(quantityAvailable), Number(modulQuantityAvailable));
  }

  const quantityResult =
    quantityComparison !== DEFAULT_COMPARISON_VALUE_NUMBER
      ? usagedQuantityAvailable * Number(1 / quantityComparison)
      : usagedQuantityAvailable;

  const priceResult =
    quantityComparison !== DEFAULT_COMPARISON_VALUE_NUMBER && comparePriceToQuantityComparison
      ? Number(price) * Number(1 / quantityComparison)
      : Number(price);

  const unitOriginResult = hasImplementedUnitProductUnit ? unitOrigin : productUnit;

  const quantityAvailableView = swapQuantityOriginWithQuantity ? quantityResult : quantityOrigin;

  return {
    [quantityResultKeyname]: Number(quantityResult),
    [quantityAvailableResultKeyname]: Number(quantityResult) - Number(quantity),
    [quantityAvailableConstResultKeyname]: Number(quantityResult),
    [quantityComparisonResultKeyname]: quantityComparison,
    [quantityComparisonConstPropsResultKeyname]: {
      quantity,
      quantity_origin: Number(quantityOrigin),
      quantity_available: Number(quantityAvailable),
      value_comparison: Number(quantityComparison),
    },
    [unitDestinationResultKeyname]: hasImplementedUnitProductUnit ? unitDestination : productUnit,
    [unitOriginResultKeyname]: unitOrigin,
    [quantityAvailableWithUnitResultKeyname]: strHelp.templateString(
      '{0} {1}',
      [formatHelp.wrapperCurrencyWithAutoCommaV1(quantityAvailableView), unitOriginResult],
      true,
    ),
    [priceResultKeyname]: priceResult,
    [amountResultKeyname]: Number(priceResult) * Number(usagedQuantityAvailable),
  };
}

/**
 *
 * usage calculating account balance based normal balance and
 * determine by countra account
 *
 * normal balance credit
 *  credit - debit
 *
 * normal balance debit
 *  debit - credit
 *
 * countra account
 *  *-1
 *
 * @param   { object } param0         object as function params
 *
 * @param   { object }  account                     data account target
 * @param   { string }  creditAmountKeyname         keyname of credit amount
 * @param   { string }  debitAmountKeyname          keyname of debit amount
 * @param   { string }  normalBalanceKeyname        normal balance account keyname
 * @param   { string }  isCountraAccountKeyname     determine countra account keyname
 * @returns
 */
function calculateAccountBalance({
  account = {},
  creditAmountKeyname = 'credit_amount',
  debitAmountKeyname = 'debit_amount',
  normalBalanceKeyname = 'normal_balance',
  isCountraAccountKeyname = 'is_countra_account',
}) {
  if (isEmpty(account)) return 0;

  const {
    [creditAmountKeyname]: creditAmount,
    [debitAmountKeyname]: debitAmount,
    [normalBalanceKeyname]: normalBalance,
    [isCountraAccountKeyname]: isCountraAccount = false,
  } = account;

  const balance =
    normalBalance === NORMAL_BALANCE_ACCOUNT_CREDIT_UPPERCASE
      ? creditAmount - debitAmount
      : debitAmount - creditAmount;

  return isCountraAccount ? balance * -1 : balance;
}

/**
 *
 * calculate account balance based normal balance and
 * determine by countra account
 * on multiple account
 *
 * @param   { object } param0         object as function params
 *
 * @param   { object }  parentAccount                                   parent account for determine before total
 * @param   { array }   childrenAccount                                 target of account calculation
 * @param   { string }  beforeTotalKeyname                              keyname for before total of parent account
 * @param   { string }  creditAmountKeyname                             keyname of credit amount
 * @param   { string }  debitAmountKeyname                              keyname of debit amount
 * @param   { string }  normalBalanceKeyname                            normal balance account keyname
 * @param   { string }  isCountraAccountKeyname                         determine countra account keyname
 * @param   { string }  calculateAccountBalanceTargetKeyname            keyname for targeting result on object
 *
 * @returns
 */
function calculateMultipleAccountBalance({
  parentAccount = {},
  childrenAccount = [],
  beforeTotalKeyname = 'before_total',
  creditAmountKeyname = 'credit_amount',
  debitAmountKeyname = 'debit_amount',
  normalBalanceKeyname = 'normal_balance',
  isCountraAccountKeyname = 'is_countra_account',
  calculateAccountBalanceTargetKeyname = 'balance',
}) {
  if (isEmpty(parentAccount) || isEmpty(childrenAccount)) return childrenAccount;

  const { [beforeTotalKeyname]: beforeTotal } = parentAccount;

  return childrenAccount.reduce((res, currAccount, index) => {
    const beforeBalance = index
      ? Number(res[index - 1][calculateAccountBalanceTargetKeyname])
      : Number(beforeTotal);

    const balance = calculateAccountBalance({
      account: {
        ...parentAccount,
        ...currAccount,
      },
      creditAmountKeyname,
      debitAmountKeyname,
      normalBalanceKeyname,
      isCountraAccountKeyname,
    });

    return res.concat({
      ...currAccount,
      [calculateAccountBalanceTargetKeyname]: beforeBalance + balance,
    });
  }, []);
}

/**
 * use calculation for getting amount of discount by percentage
 *
 */
function calculateAmountOfDiscountPercentage({
  data,
  discountKeyname = 'discount',
  amountKeyname = 'amount',
}) {
  if (isEmpty(data)) return 0;

  const { [amountKeyname]: amount, [discountKeyname]: discount } = data;

  return (Number(discount) / 100) * Number(amount);
}

const formulaHelpers = {
  calculateAdditionalcostFromObject,
  calculateDiscountAndTotalOfChildrenData,
  calculateTotalBalanceGeneralLedger,
  calculateDiscountPercentage,
  calculateAmountInvoiceReturn,
  parseProductAPI2display,
  calculateAccountBalance,
  calculateMultipleAccountBalance,
  calculateAmountOfDiscountPercentage,
};

export default formulaHelpers;
