import * as React from 'react';
function SvgProductDataMenu(props) {
  return (
    <svg width={97} height={74} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#product-data-menu_svg__filter0_d_5539_188935)'>
        <path d='M87.522 57H3.033v3h84.489v-3z' fill='#324A5E' />
        <path d='M75.928 64H59.93l-1.97-4h19.937l-1.97 4z' fill='#2B3B4E' />
        <path
          d='M76.402 67c2.477 0 4.485-1.343 4.485-3s-2.008-3-4.485-3c-2.478 0-4.486 1.343-4.486 3s2.008 3 4.486 3z'
          fill='#324A5E'
        />
        <path
          d='M76.402 66c1.376 0 2.492-.895 2.492-2s-1.116-2-2.492-2c-1.377 0-2.492.895-2.492 2s1.115 2 2.492 2z'
          fill='#E6E9EE'
        />
        <path
          d='M59.455 67c2.478 0 4.486-1.343 4.486-3s-2.008-3-4.486-3c-2.477 0-4.485 1.343-4.485 3s2.008 3 4.485 3z'
          fill='#324A5E'
        />
        <path
          d='M59.455 66c1.377 0 2.492-.895 2.492-2s-1.115-2-2.492-2c-1.376 0-2.492.895-2.492 2s1.116 2 2.492 2z'
          fill='#E6E9EE'
        />
        <path d='M32.07 64H16.067L14.1 60h19.937l-1.967 4z' fill='#2B3B4E' />
        <path
          d='M32.541 67c2.478 0 4.486-1.343 4.486-3s-2.008-3-4.486-3c-2.477 0-4.485 1.343-4.485 3s2.008 3 4.485 3z'
          fill='#324A5E'
        />
        <path
          d='M32.541 66c1.377 0 2.492-.895 2.492-2s-1.115-2-2.492-2c-1.376 0-2.492.895-2.492 2s1.116 2 2.492 2z'
          fill='#E6E9EE'
        />
        <path
          d='M15.595 67c2.478 0 4.486-1.343 4.486-3s-2.008-3-4.486-3c-2.477 0-4.486 1.343-4.486 3s2.009 3 4.486 3z'
          fill='#324A5E'
        />
        <path
          d='M15.595 66c1.376 0 2.492-.895 2.492-2s-1.116-2-2.492-2-2.492.895-2.492 2 1.116 2 2.492 2z'
          fill='#E6E9EE'
        />
        <path d='M89.5 56h-1.994v4H89.5v-4zM3.494 56H1.5v4h1.994v-4z' fill='#2B3B4E' />
        <path d='M44.062 32.5H5.321V57h38.741V32.5z' fill='#FFD15D' />
        <path d='M46 29H3.383v7h42.615v-7z' fill='#F9B54C' />
        <path
          d='M30.503 42.364H18.88c-1.07 0-1.937-.784-1.937-1.75 0-.967.868-1.75 1.937-1.75h11.623c1.07 0 1.937.783 1.937 1.75 0 .966-.867 1.75-1.937 1.75z'
          fill='#B97850'
        />
        <path
          d='M16.846 45.194h-7.41c-.762 0-1.361.541-1.361 1.23v6.694c0 .689.599 1.23 1.362 1.23h7.409c.763 0 1.362-.541 1.362-1.23v-6.694c0-.64-.6-1.23-1.362-1.23zm.6 7.924c0 .295-.273.541-.6.541h-7.41c-.326 0-.599-.246-.599-.541v-6.694c0-.295.273-.541.6-.541h7.409c.327 0 .6.246.6.541v6.694z'
          fill='#fff'
        />
        <path
          d='M11.616 47.704c-.055-.05-.055-.05-.11-.05-.054 0-.108-.049-.163-.049-.054 0-.109 0-.163.05-.055 0-.11.049-.11.049l-1.252 1.132c-.164.148-.164.344 0 .443.163.147.381.147.49 0l.654-.59v2.903c0 .197.163.345.381.345s.382-.148.382-.345v-2.904l.654.59a.44.44 0 00.272.1c.109 0 .163-.05.272-.1.164-.147.164-.344 0-.442l-1.307-1.132zM15.212 47.704a.44.44 0 00-.272-.099.44.44 0 00-.273.099l-1.253 1.132c-.163.148-.163.344 0 .443.164.147.381.147.49 0l.654-.59v2.903c0 .197.164.345.382.345.217 0 .38-.148.38-.345v-2.904l.655.59a.44.44 0 00.272.1c.109 0 .163-.05.272-.1.164-.147.164-.344 0-.442l-1.307-1.132zM28.233 45.194h-7.41c-.762 0-1.362.541-1.362 1.23v6.694c0 .689.6 1.23 1.362 1.23h7.41c.762 0 1.362-.541 1.362-1.23v-6.694c0-.64-.6-1.23-1.362-1.23zm.654 7.924c0 .295-.273.541-.6.541h-7.464c-.326 0-.599-.246-.599-.541v-6.694c0-.295.273-.541.6-.541h7.409c.327 0 .599.246.599.541v6.694h.055z'
          fill='#fff'
        />
        <path
          d='M26.162 47.064h-3.214c-.218 0-.382.148-.382.345v1.575c0 .886.709 1.624 1.635 1.771v1.083h-.49c-.218 0-.382.148-.382.345 0 .197.164.344.381.344h1.744c.218 0 .381-.147.381-.344 0-.197-.163-.345-.381-.345h-.49v-1.083c.926-.147 1.634-.886 1.634-1.771v-1.575a.45.45 0 00-.436-.345zm-.381 1.92c0 .64-.545 1.132-1.253 1.132-.709 0-1.253-.493-1.253-1.132v-1.28h2.506v1.28zM39.618 45.194H32.21c-.763 0-1.362.541-1.362 1.23v6.694c0 .689.6 1.23 1.362 1.23h7.41c.762 0 1.361-.541 1.361-1.23v-6.694c0-.64-.653-1.23-1.361-1.23zm.6 7.924c0 .295-.273.541-.6.541H32.21c-.327 0-.6-.246-.6-.541v-6.694c0-.295.273-.541.6-.541h7.41c.326 0 .599.246.599.541v6.694z'
          fill='#fff'
        />
        <path
          d='M35.914 46.621c-1.798 0-3.269 1.329-3.269 2.953 0 .197.163.345.381.345h2.56v2.215c0 .098-.108.197-.217.197s-.218-.099-.218-.197c0-.197-.163-.345-.381-.345s-.382.148-.382.345c0 .492.436.886.981.886.545 0 .98-.394.98-.886v-2.215h2.561c.218 0 .382-.148.382-.345-.11-1.624-1.58-2.953-3.378-2.953zm-2.506 2.609c.163-1.132 1.253-1.97 2.506-1.97s2.343.838 2.506 1.97h-5.012z'
          fill='#fff'
        />
        <path d='M86.677 32.5H47.936V57h38.741V32.5z' fill='#FFD15D' />
        <path d='M87 32H48v25h39V32z' fill='#F9B54C' />
        <path d='M88.615 29H45.999v7h42.616v-7z' fill='#F9B54C' />
        <path d='M89 29H46v7h43v-7z' fill='#F4A200' />
        <path
          d='M73.118 42.364H61.495c-1.07 0-1.937-.784-1.937-1.75 0-.967.867-1.75 1.937-1.75h11.623c1.07 0 1.937.783 1.937 1.75 0 .966-.868 1.75-1.938 1.75z'
          fill='#935635'
        />
        <path
          d='M59.461 45.194h-7.41c-.762 0-1.361.541-1.361 1.23v6.694c0 .69.6 1.23 1.362 1.23h7.41c.762 0 1.361-.54 1.361-1.23v-6.694c0-.64-.599-1.23-1.362-1.23zm.6 7.924c0 .295-.273.541-.6.541h-7.41c-.326 0-.598-.246-.598-.541v-6.694c0-.295.272-.541.599-.541h7.41c.326 0 .598.246.598.541v6.694z'
          fill='#fff'
        />
        <path
          d='M54.231 47.704c-.054-.05-.054-.05-.108-.05-.055 0-.11-.049-.164-.049-.054 0-.109 0-.163.05-.055 0-.11.049-.11.049l-1.252 1.132c-.164.148-.164.345 0 .443.163.148.381.148.49 0l.654-.59v2.903c0 .197.163.345.381.345s.381-.148.381-.345v-2.904l.654.59a.44.44 0 00.273.1c.108 0 .163-.05.272-.1.163-.147.163-.344 0-.442l-1.308-1.132zM57.827 47.704a.44.44 0 00-.273-.099.44.44 0 00-.272.099l-1.253 1.132c-.164.148-.164.345 0 .443.163.148.381.148.49 0l.654-.59v2.903c0 .197.163.345.381.345s.382-.148.382-.345v-2.904l.653.59a.44.44 0 00.273.1c.109 0 .163-.05.272-.1.164-.147.164-.344 0-.442l-1.307-1.132zM70.847 45.194h-7.409c-.763 0-1.362.541-1.362 1.23v6.694c0 .69.6 1.23 1.362 1.23h7.41c.762 0 1.362-.54 1.362-1.23v-6.694c0-.64-.6-1.23-1.362-1.23zm.654 7.924c0 .295-.272.541-.599.541h-7.464c-.327 0-.6-.246-.6-.541v-6.694c0-.295.273-.541.6-.541h7.41c.326 0 .599.246.599.541v6.694h.054z'
          fill='#fff'
        />
        <path
          d='M68.777 47.064h-3.214c-.218 0-.381.148-.381.345v1.575c0 .886.708 1.624 1.634 1.771v1.083h-.49c-.218 0-.382.148-.382.345 0 .197.164.344.382.344h1.743c.218 0 .381-.147.381-.344 0-.197-.163-.345-.38-.345h-.491v-1.083c.926-.147 1.634-.885 1.634-1.771v-1.575a.45.45 0 00-.436-.345zm-.381 1.92c0 .64-.545 1.132-1.253 1.132-.708 0-1.253-.492-1.253-1.132v-1.28h2.506v1.28zM82.234 45.194h-7.41c-.762 0-1.362.541-1.362 1.23v6.694c0 .69.6 1.23 1.362 1.23h7.41c.762 0 1.362-.54 1.362-1.23v-6.694c0-.64-.654-1.23-1.362-1.23zm.599 7.924c0 .295-.272.541-.6.541h-7.409c-.326 0-.599-.246-.599-.541v-6.694c0-.295.273-.541.6-.541h7.409c.327 0 .599.246.599.541v6.694z'
          fill='#fff'
        />
        <path
          d='M78.53 46.621c-1.799 0-3.27 1.329-3.27 2.953 0 .197.164.345.382.345h2.56v2.215c0 .098-.109.197-.218.197s-.218-.099-.218-.197c0-.197-.163-.345-.381-.345s-.381.148-.381.345c0 .492.435.886.98.886.545 0 .98-.394.98-.886v-2.215h2.561c.218 0 .382-.148.382-.345-.11-1.624-1.58-2.953-3.378-2.953zm-2.507 2.609c.163-1.132 1.253-1.97 2.506-1.97s2.343.838 2.506 1.97h-5.012z'
          fill='#fff'
        />
        <path d='M64.874 4.5H26.133V29h38.741V4.5z' fill='#FFD15D' />
        <path d='M64.874 4.5H45.426V29h19.448V4.5z' fill='#F9B54C' />
        <path d='M66.811 1H24.196v7H66.81V1z' fill='#F9B54C' />
        <path d='M66.811 1H45.426v7h21.385V1z' fill='#F4A200' />
        <path
          d='M51.315 14.364H39.692c-1.07 0-1.937-.784-1.937-1.75 0-.967.868-1.75 1.937-1.75h11.623c1.07 0 1.937.783 1.937 1.75 0 .966-.867 1.75-1.937 1.75z'
          fill='#B97850'
        />
        <path
          d='M51.315 10.864h-5.89v3.5h5.89c1.07 0 1.937-.784 1.937-1.75 0-.967-.868-1.75-1.937-1.75z'
          fill='#935635'
        />
        <path
          d='M37.658 17.194H30.25c-.762 0-1.362.541-1.362 1.23v6.694c0 .69.6 1.23 1.362 1.23h7.41c.762 0 1.361-.54 1.361-1.23v-6.694c0-.64-.599-1.23-1.362-1.23zm.6 7.924c0 .295-.273.541-.6.541H30.25c-.327 0-.6-.246-.6-.541v-6.694c0-.295.273-.541.6-.541h7.41c.326 0 .599.246.599.541v6.694z'
          fill='#fff'
        />
        <path
          d='M32.429 19.704c-.055-.05-.055-.05-.11-.05-.054 0-.108-.049-.163-.049-.054 0-.109 0-.163.05-.055 0-.11.049-.11.049l-1.252 1.132c-.164.148-.164.345 0 .443.163.148.381.148.49 0l.654-.59v2.903c0 .197.163.345.381.345s.382-.148.382-.345v-2.904l.653.59a.44.44 0 00.273.1c.109 0 .163-.05.272-.1.164-.147.164-.344 0-.442l-1.307-1.132zM36.024 19.704a.44.44 0 00-.272-.099.44.44 0 00-.272.099l-1.253 1.132c-.164.148-.164.345 0 .443.163.148.38.148.49 0l.654-.59v2.903c0 .197.163.345.381.345s.381-.148.381-.345v-2.904l.654.59a.44.44 0 00.273.1c.108 0 .163-.05.272-.1.163-.147.163-.344 0-.442l-1.308-1.132zM49.045 17.194h-7.41c-.762 0-1.361.541-1.361 1.23v6.694c0 .69.6 1.23 1.362 1.23h7.41c.762 0 1.361-.54 1.361-1.23v-6.694c0-.64-.599-1.23-1.362-1.23zm.654 7.924c0 .295-.272.541-.6.541h-7.463c-.327 0-.6-.246-.6-.541v-6.694c0-.295.273-.541.6-.541h7.41c.326 0 .599.246.599.541v6.694h.054z'
          fill='#fff'
        />
        <path
          d='M46.975 19.064H43.76c-.218 0-.381.148-.381.345v1.575c0 .886.708 1.624 1.634 1.771v1.083h-.49c-.218 0-.381.148-.381.345 0 .197.163.344.381.344h1.743c.218 0 .382-.147.382-.344 0-.197-.164-.345-.382-.345h-.49v-1.082c.926-.148 1.634-.886 1.634-1.772v-1.575a.45.45 0 00-.435-.345zm-.382 1.92c0 .64-.545 1.132-1.253 1.132-.708 0-1.253-.492-1.253-1.132v-1.28h2.506v1.28zM60.431 17.194h-7.409c-.763 0-1.362.541-1.362 1.23v6.694c0 .69.6 1.23 1.362 1.23h7.41c.762 0 1.361-.54 1.361-1.23v-6.694c0-.64-.653-1.23-1.361-1.23zm.6 7.924c0 .295-.273.541-.6.541h-7.409c-.327 0-.6-.246-.6-.541v-6.694c0-.295.273-.541.6-.541h7.41c.326 0 .599.246.599.541v6.694z'
          fill='#fff'
        />
        <path
          d='M56.726 18.621c-1.797 0-3.268 1.329-3.268 2.953 0 .197.163.345.38.345H56.4v2.215c0 .098-.108.197-.218.197-.108 0-.217-.099-.217-.197 0-.197-.164-.345-.382-.345s-.381.148-.381.345c0 .492.436.886.98.886.545 0 .981-.394.981-.886v-2.215h2.56c.219 0 .382-.148.382-.345-.109-1.624-1.58-2.953-3.378-2.953zM54.22 21.23c.164-1.132 1.253-1.97 2.506-1.97s2.343.838 2.506 1.97H54.22z'
          fill='#fff'
        />
      </g>
      <defs>
        <filter
          id='product-data-menu_svg__filter0_d_5539_188935'
          x={0.5}
          y={0}
          width={96}
          height={74}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5539_188935' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5539_188935' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgProductDataMenu;
