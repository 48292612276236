import * as React from 'react';
function SvgPurchaseInvoiceMenu(props) {
  return (
    <svg width={98} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#purchase-invoice-menu_svg__filter0_d_5595_190774)'>
        <path
          d='M36.803 68.914h48.265c3.275 0 5.93-2.51 5.932-5.605H42.735c0 3.096-2.657 5.605-5.932 5.605z'
          fill='#566FF8'
        />
        <path
          d='M19 6.662h11.867c0-3.096-2.658-5.605-5.933-5.605C21.657 1.057 19 3.566 19 6.662z'
          fill='#FFC250'
        />
        <path
          d='M73.2 1c3.278 0 5.933 2.509 5.933 5.605v56.703H42.735c0 3.096-2.657 5.605-5.932 5.605-3.277 0-5.935-2.508-5.935-5.605V6.662h-.002c0-3.096-2.657-5.605-5.932-5.605V1H73.2zm-3.165 8.963a2.204 2.204 0 00-1.516-.593h-2.922c-1.184 0-2.143.908-2.143 2.025s.959 2.025 2.143 2.025h2.922c1.183 0 2.143-.908 2.143-2.025 0-.56-.24-1.064-.627-1.432zm0 8.534a2.204 2.204 0 00-1.516-.593h-2.922c-1.184 0-2.143.907-2.143 2.025 0 1.119.959 2.024 2.143 2.024h2.922c1.183 0 2.143-.905 2.143-2.024 0-.56-.24-1.064-.627-1.432zM68.52 30.489c1.183 0 2.143-.907 2.143-2.025 0-.56-.24-1.066-.627-1.432a2.204 2.204 0 00-1.516-.593h-2.922c-1.184 0-2.143.906-2.143 2.025 0 1.118.959 2.025 2.143 2.025h2.922zM55.001 55.614c5.287 0 9.573-4.05 9.573-9.046 0-4.994-4.286-9.043-9.573-9.043-5.288 0-9.574 4.05-9.574 9.043 0 4.996 4.286 9.046 9.574 9.046z'
          fill='#80B4FB'
        />
        <path
          d='M70.035 9.963a2.204 2.204 0 00-1.516-.593h-2.922c-1.184 0-2.143.908-2.143 2.025s.959 2.025 2.143 2.025h2.922c1.183 0 2.143-.908 2.143-2.025 0-.56-.24-1.064-.627-1.432zM70.035 18.497a2.204 2.204 0 00-1.516-.593h-2.922c-1.184 0-2.143.907-2.143 2.025 0 1.119.959 2.024 2.143 2.024h2.922c1.183 0 2.143-.905 2.143-2.024 0-.56-.24-1.064-.627-1.432zM68.52 30.49c1.182 0 2.142-.908 2.142-2.026 0-.56-.24-1.066-.627-1.432a2.204 2.204 0 00-1.516-.593h-2.922c-1.184 0-2.143.906-2.143 2.025 0 1.118.959 2.025 2.143 2.025h2.922zM55 55.614c5.288 0 9.574-4.05 9.574-9.046 0-4.994-4.286-9.043-9.573-9.043-5.288 0-9.574 4.05-9.574 9.043 0 4.996 4.286 9.046 9.574 9.046z'
          fill='#80B4FB'
        />
        <path
          d='M72.706 29.164H47.685v1.27h25.02v-1.27zM44.998 29.164h-5.542v1.27h5.541v-1.27zM72.706 34.4H47.685v1.27h25.02V34.4zM44.998 34.4h-5.542v1.27h5.541V34.4zM72.706 39.636H47.685v1.27h25.02v-1.27zM44.998 39.636h-5.542v1.27h5.541v-1.27zM72.706 44.872H47.685v1.27h25.02v-1.27zM44.998 44.872h-5.542v1.27h5.541v-1.27z'
          fill='#fff'
        />
        <path
          d='M72.705 29.164H55.128v1.27h17.577v-1.27zM72.706 34.4H55.128v1.27h17.578V34.4zM72.706 39.636H55.128v1.27h17.578v-1.27zM72.706 44.872H55.128v1.27h17.578v-1.27z'
          fill='#EDEAEA'
        />
        <g opacity={0.1} fill='#5F93D8'>
          <path d='M49.078 1.086v.057c3.275 0 5.933 2.51 5.933 5.606h.002v56.646c0 3.096 2.657 5.605 5.934 5.605 3.275 0 5.932-2.509 5.932-5.605h12.266V55.7c-5.287 0-9.573-4.05-9.573-9.045 0-4.994 4.286-9.044 9.573-9.044V6.605c0-3.048-2.614-5.519-5.84-5.519H49.077z' />
          <path d='M79.145 55.7c-5.287 0-9.573-4.05-9.573-9.045 0-4.994 4.286-9.044 9.573-9.044v18.09z' />
        </g>
        <path
          d='M57.505 20.584c.292 0 .53-.224.53-.5v-.341c0-1.036-1.037-1.88-2.326-1.914v-.13c0-.276-.237-.5-.53-.5-.292 0-.529.224-.529.5v.13c-1.289.033-2.325.878-2.325 1.914v.683c0 1.036 1.036 1.88 2.325 1.913v2.515c-.694-.028-1.266-.434-1.266-.913V23.6c0-.277-.237-.5-.53-.5-.292 0-.53.223-.53.5v.341c0 1.036 1.037 1.88 2.326 1.913v.131c0 .276.237.5.53.5.292 0 .529-.224.529-.5v-.13c1.289-.034 2.325-.878 2.325-1.914v-.683c0-1.036-1.036-1.88-2.325-1.913v-2.516c.694.029 1.266.435 1.266.913v.342c0 .276.237.5.53.5zm-4.122-.158v-.683c0-.479.573-.885 1.267-.913v2.51c-.694-.03-1.267-.435-1.267-.914zm3.593 2.832v.684c0 .478-.573.884-1.267.913v-2.51c.694.028 1.267.435 1.267.913z'
          fill='#FFE07D'
        />
        <path
          d='M5.898 34.87c0 1.03-1.04 1.869-2.323 1.869-1.284 0-2.325-.838-2.325-1.869 0-1.033 1.041-1.87 2.325-1.87 1.282 0 2.323.837 2.323 1.87zM38.363 37.314l-18.075 4.939 7.081 16.784 18.075-4.939-7.081-16.784z'
          fill='#FF8E31'
        />
        <path
          d='M48.884 64.415H18.597c-.96 0-1.865-.3-2.547-.849-.679-.549-1.054-1.276-1.054-2.05v-.078c0-.658.283-1.304.793-1.818.514-.512 1.237-.873 2.037-1.015l25.377-4.493c.602-.106 1.196.2 1.329.682.132.483-.248.963-.85 1.07l-25.377 4.493c-.31.053-.578.188-.777.387a.976.976 0 00-.304.694v.077c0 .296.143.574.402.783.26.208.606.325.97.325h30.287c.616 0 1.114.4 1.114.896 0 .495-.497.896-1.113.896z'
          fill='#F2F4F7'
        />
        <path
          d='M19.386 60.144c-.537 0-1.011-.314-1.098-.756l-4.582-23.094a1.055 1.055 0 00-.465-.668 1.579 1.579 0 00-.894-.265H3.575c-.616 0-1.116-.401-1.116-.896s.5-.899 1.116-.899h8.771c.857 0 1.688.249 2.34.695.651.446 1.087 1.07 1.22 1.75l4.581 23.093c.096.49-.318.95-.925 1.028-.06.008-.117.012-.176.012z'
          fill='#F2F4F7'
        />
        <path
          d='M24.514 65.885c0 1.581-1.593 2.86-3.553 2.86-1.962 0-3.555-1.279-3.555-2.86 0-1.58 1.593-2.858 3.555-2.858 1.96 0 3.553 1.278 3.553 2.858z'
          fill='#34495E'
        />
        <path
          d='M22.735 65.885c0 .79-.794 1.43-1.774 1.43-.983 0-1.775-.64-1.775-1.43 0-.788.792-1.428 1.775-1.428.98 0 1.774.64 1.774 1.428z'
          fill='#F2F4F7'
        />
        <path
          d='M49.998 65.885c0 1.581-1.592 2.86-3.554 2.86-1.961 0-3.552-1.279-3.552-2.86 0-1.58 1.591-2.858 3.552-2.858 1.962 0 3.554 1.278 3.554 2.858z'
          fill='#34495E'
        />
        <path
          d='M48.22 65.885c0 .79-.796 1.43-1.776 1.43s-1.776-.64-1.776-1.43c0-.788.796-1.428 1.776-1.428s1.777.64 1.777 1.428zM49.614 42.66a1.477 1.477 0 00-.928-.31H14.802l3.672 18.38v.002l30.494-5.4c.282-.049.543-.173.74-.368a.935.935 0 00.29-.661V43.405c0-.27-.129-.54-.384-.745zm-28.56 15.836a.953.953 0 01-.133.008c-.4 0-.753-.233-.818-.562L17.489 44.86c-.072-.364.235-.707.688-.765.45-.059.878.19.952.553l2.612 13.083c.073.364-.234.707-.687.765zm4.333-.768a.918.918 0 01-.118.007c-.406 0-.762-.24-.82-.574L22.27 44.848c-.065-.364.25-.703.703-.755.458-.053.875.202.94.567l2.178 12.313c.065.365-.25.703-.705.755zm4.33-.768a1.18 1.18 0 01-.1.005c-.413 0-.771-.249-.822-.588l-1.743-11.542c-.056-.365.27-.7.723-.744.456-.038.868.216.925.582l1.742 11.544c.055.366-.268.698-.724.743zm4.33-.768a1.226 1.226 0 01-.083.002c-.422 0-.783-.258-.824-.603l-1.308-10.772c-.045-.367.288-.694.746-.73.463-.031.863.233.907.6l1.308 10.773c.043.367-.29.694-.746.73zm4.325-.768l-.06.002c-.432 0-.795-.269-.826-.622l-.87-10.003c-.032-.368.311-.687.77-.714.462-.019.853.252.886.62l.87 10.004c.032.369-.314.687-.77.713zm4.322-.77h-.032c-.444 0-.812-.282-.83-.641l-.435-9.233c-.016-.37.34-.68.799-.694.463-.022.842.274.861.642l.435 9.233c.018.37-.34.68-.797.694zm5.147-1.437c0 .37-.372.668-.83.668-.459 0-.83-.298-.83-.668v-8.462c0-.37.371-.668.83-.668.458 0 .83.298.83.668v8.462z'
          fill='#F2F4F7'
        />
      </g>
      <defs>
        <filter
          id='purchase-invoice-menu_svg__filter0_d_5595_190774'
          x={0.25}
          y={0}
          width={98}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5595_190774' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5595_190774' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgPurchaseInvoiceMenu;
