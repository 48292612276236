import { productUnits } from '../../default/unit-product.default';
import validation from '../../helpers/validation.helpers';

// for initialization product data
export const initialStateProductData = {
  product_category_id: null,
  product_category: null,
  product_name: '',
  product_code: '',
  product_unit_data: {
    unit_name: productUnits.pcs,
  },
  product_unit: productUnits.pcs,
  product_description: '',
  is_purchased: false,
  purchase_price: '',
  purchase_account: null,
  purchase_account_id: null,
  purchase_tax: null,
  purchase_tax_id: null,
  is_for_sale: false,
  sales_price: '',
  sales_account: null,
  sales_account_id: null,
  sales_tax: null,
  sales_tax_id: null,
  is_stock_monitored: true,
  stock_min: '',
  inventory_account: null,
  inventory_account_id: null,
  is_new_category: false,
  new_category_name: null,
  per_carton: '',
  contact: [],
};

/**
 * initial product data when youre adding from purchase
 * the different only in is_purchased set to true
 */
export const initialProductDataForPurchaseOrder = {
  ...initialStateProductData,
  is_purchased: true,
};

/**
 * the different only in is_purchased set to true
 */
export const initialProductDataWithIsPurchasedTrue = {
  ...initialStateProductData,
  is_purchased: true,
};

export const initialProductDataForQuotationRequest = {
  ...initialStateProductData,
  is_purchased: true,
};

/**
 * initialize data product for checked customer
 * set is_purchased into false and
 *     is_for_sale into true
 */
export const initialProductDataForCustomerOnly = {
  ...initialStateProductData,
  is_for_sale: true,
  is_purchased: false,
};

// validation product data
export const initialValidationProductData = {
  product_category_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  product_name: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  product_unit: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  purchase_price: {
    error: false,
    isValid: (value, is_stock_monitored) => {
      if (is_stock_monitored) {
        return validation.isNumberGreaterOrEqualThan(value, 1);
      }

      return validation.nullable(value);
    },
  },
  purchase_account_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  purchase_tax_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  sales_price: {
    error: false,
    isValid: (value) => validation.isNumberGreaterOrEqualThan(value, 1),
  },
  sales_account_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  sales_tax_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  stock_min: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  inventory_account_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  per_carton: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const allowKeysProductData = [
  'product_category_id',
  'product_name',
  'product_qty',
  'product_code',
  'product_unit',
  'product_description',
  'is_purchased',
  'purchase_price',
  'purchase_account_id',
  'purchase_tax_id',
  'is_for_sale',
  'sales_price',
  'sales_account_id',
  'sales_tax_id',
  'is_stock_monitored',
  'stock_min',
  'inventory_account_id',
  'is_new_category',
  'new_category_name',
  'contact',
  'per_carton',
];

export const discardKeysProductData = [
  'inventory_account',
  'product_category',
  'product_unit_data',
  'purchase_account',
  'purchase_tax',
  'sales_account',
  'sales_tax',
];

export const purchaseKeyData = ['purchase_price', 'purchase_account_id', 'purchase_tax_id'];

export const salesKeyData = ['sales_price', 'sales_account_id', 'sales_tax_id'];

export const stockMonitorKeyData = ['stock_min', 'inventory_account_id'];

export const namesOfNumberType = ['purchase_price', 'stock_min', 'sales_price'];
