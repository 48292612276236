// handling all keys of localstorage
export const LOCAL_STORAGE_ROOT_APPLICATION = 'root-application';
export const LOCAL_STORAGE_USER = 'user';
export const LOCAL_STORAGE_AUTH_SESSION_STATUS = '_auth-session-status';
export const LOCAL_STORAGE_PRIMARY_TOKEN = 'primary-token';
export const LOCAL_STORAGE_SECONDARY_TOKEN = 'secondary-token';
export const LOCAL_STORAGE_TIMEOUT = '_timeout';
export const LOCAL_STORAGE_COMPANY = 'company';
export const LOCAL_STORAGE_USER_DETAIL = 'user-detail';
export const LOCAL_STORAGE_USER_COMPANY = 'user-company';
export const LOCAL_STORAGE_REMEMBER_LOGIN = 'remember-login';
export const LOCAL_STORAGE_ACCOUNT_DATA = 'account-data';
export const LOCAL_STORAGE_ACCOUNT_DATA_PRINT = 'account-data-print';
export const LOCAL_STORAGE_ACCOUNT_DETAILS = 'account-details';
export const LOCAL_STORAGE_ACCOUNT_DATA_ALTERNATIVE = 'account-data-alternative';
export const LOCAL_STORAGE_ACCOUNT_TRANSACTION = 'account-data-transaction';
export const LOCAL_STORAGE_ACCOUNT_CATEGORY = 'account-category';
export const LOCAL_STORAGE_ACCOUNT_CATEGORY_PRINT = 'account-category-print';
export const LOCAL_STORAGE_ACCOUNT_PARENT = 'account-parent';
export const LOCAL_STORAGE_SUB_ACCOUNT = 'sub-account';
export const LOCAL_STORAGE_ACCOUNT_SUB = 'account-sub';
export const LOCAL_STORAGE_MANAGEMENT_USERS = 'management-users';
export const LOCAL_STORAGE_MANAGEMENT_USERS_DETAILS = 'management-users-details';
export const LOCAL_STORAGE_MANAGEMENT_USERS_FOR_AUTOCOMPLETE = 'management-users-for-autocomplete';
export const LOCAL_STORAGE_MANAGEMENT_USERS_LIST_SETTING_COMPANY =
  'management-users-list-setting-company';
export const LOCAL_STORAGE_MANAGEMENT_ROLE = 'management-role';
export const LOCAL_STORAGE_MANAGEMENT_COMPANIES = 'management-companies';
export const LOCAL_STORAGE_CONTACT_DATA = 'contact-data';
export const LOCAL_STORAGE_CONTACT_PRINT_DATA = 'contact-print-data';
export const LOCAL_STORAGE_PURCHASE_INVOICE_FROM_CONTACT_DATA =
  'purchase-invoice-from-contact-data';
export const LOCAL_STORAGE_SALES_INVOICE_FROM_CONTACT_DATA = 'sales-invoice-from-contact-data';
export const LOCAL_STORAGE_PROVINCE = 'province-data';
export const LOCAL_STORAGE_REGIONAL_PROVINCE = 'regional-province';
export const LOCAL_STORAGE_REGIONAL_CITY = 'regional-city';
export const LOCAL_STORAGE_REGIONAL_SUB_DISTRICT = 'regional-sub-district';
export const LOCAL_STORAGE_REGIONAL_URBAN = 'regional-urban';
export const LOCAL_STORAGE_INDO_REGIONAL_DATA = 'indo-regional-data';
export const LOCAL_STORAGE_BANK_DATA = 'bank-data';
export const LOCAL_STORAGE_REFERENCE_NUMBER_DATA = 'reference-number';
export const LOCAL_STORAGE_PRODUCT_CATEGORY = 'product-category';
export const LOCAL_STORAGE_PRODUCT_CATEGORY_PRINT = 'product-category-print';
export const LOCAL_STORAGE_PRODUCT_DATA = 'product-data';
export const LOCAL_STORAGE_PRODUCT_PRINT_DATA = 'product-print-data';
export const LOCAL_STORAGE_PRODUCT_DATA_ALTERNATIVE = 'product-data-alternative';
export const LOCAL_STORAGE_PRODUCT_PURCHASE_SALE = 'product-purchase-sale';
export const LOCAL_STORAGE_PRODUCT_BY_SUPPLIER = 'product-supplier';
export const LOCAL_STORAGE_PRODUCT_IMPORTING_PROGRESS = 'product-importing-progress';
export const LOCAL_STORAGE_JOURNAL_ENTRY = 'journal-entry';
export const LOCAL_STORAGE_JOURNAL_ENTRY_DETAIL = 'journal-entry-detail';
export const LOCAL_STORAGE_BEGINNING_BALANCE = 'beginning-balance';
export const LOCAL_STORAGE_RECEIPT_CASH = 'receipt-cash';
export const LOCAL_STORAGE_PAYMENT_CASH = 'payment-cash';
export const LOCAL_STORAGE_CASH_TRANSFER = 'cash-transfer';
export const LOCAL_STORAGE_CASH_BANK = 'cash-bank';
export const LOCAL_STORAGE_CASH_BANK_CALCULATE = 'cash-bank-calculate';
export const LOCAL_STORAGE_CASH_BANK_ACCOUNT_TRANSACTION = 'cash-bank-account-transaction';
export const LOCAL_STORAGE_CASH_BANK_DETAIL_ACCOUNT_TRANSACTION =
  'cash-bank-detail-account-transaction';
export const LOCAL_STORAGE_QUOTATION_REQUEST = 'quotation-request';
export const LOCAL_STORAGE_QUOTATION_REQUEST_PRINT_PDF = 'quotation-request-print-pdf';
export const LOCAL_STORAGE_QUOTATION_REQUEST_DETAIL = 'quotation-request-detail';
export const LOCAL_STORAGE_QUOTATION_REQUEST_ADD = 'add-quotation-request';
export const LOCAL_STORAGE_PURCHASE_ORDER = 'purchase-order';
export const LOCAL_STORAGE_PURCHASE_ORDER_PDF_PRINT = 'purchase-order-pdf-print';
export const LOCAL_STORAGE_PURCHASE_ORDER_AUTOCOMPLETE = 'purchase-order-autocomplete';
export const LOCAL_STORAGE_PURCHASE_ORDER_ADD = 'add-purchase-order';
export const LOCAL_STORAGE_PURCHASE_PAYMENT = 'purchase-payment';
export const LOCAL_STORAGE_PURCHASE_PAYMENT_PDF_PRINT = 'purchase-payment-pdf-print';
export const LOCAL_STORAGE_PURCHASE_DELIVERY = 'purchase-delivery';
export const LOCAL_STORAGE_PURCHASE_DELIVERY_PDF_PRINT = 'purchase-delivery-pdf-print';
export const LOCAL_STORAGE_PURCHASE_DELIVERY_DETAIL = 'purchase-delivery-detail';
export const LOCAL_STORAGE_PURCHASE_INVOICE = 'purchase-invoice';
export const LOCAL_STORAGE_PURCHASE_INVOICE_PDF_PRINT = 'purchase-invoice-pdf-print';
export const LOCAL_STORAGE_SALES_QUOTATION = 'sales-quotation';
export const LOCAL_STORAGE_SALES_QUOTATION_PRINT_PDF = 'sales-quotation-print-pdf';
export const LOCAL_STORAGE_SALES_INVOICE = 'sales-invoice';
export const LOCAL_STORAGE_SALES_INVOICE_PRINT_PDF = 'sales-invoice-print-pdf';
export const LOCAL_STORAGE_SALES_INVOICE_DETAIL = 'sales-invoice-detail';
export const LOCAL_STORAGE_SALES_INVOICE_ADD = 'sales-invoice-add';
export const LOCAL_STORAGE_CACHE_ADD_UPDATE_PURCHASE_INVOICE_DIALOG =
  'cache-add-update-purchase-invoice-dialog';
export const LOCAL_STORAGE_SALES_ORDER = 'sales-order';
export const LOCAL_STORAGE_SALES_ORDER_PRINT_PDF = 'sales-order-print-pdf';
export const LOCAL_STORAGE_TEMP_ADD_UPDATE_SALES_ORDER = 'temp-add-update-sales-order';
export const LOCAL_STORAGE_SALES_DELIVERY_AND_ORDER = 'sales-delivery-and-order';
export const LOCAL_STORAGE_SALES_DELIVERY = 'sales-delivery';
export const LOCAL_STORAGE_SALES_DELIVERY_PRINT_PDF = 'sales-delivery-print-pdf';
export const LOCAL_STORAGE_TEMP_ADD_UPDATE_SALES_DELIVERY = 'temp-add-update-sales-delivery';
export const LOCAL_STORAGE_SALES_PAYMENT = 'sales-payment';
export const LOCAL_STORAGE_SALES_PAYMENT_PRINT_PDF = 'sales-payment-print-pdf';
export const LOCAL_STORAGE_SALES_QUOTATION_DETAIL = 'sales-quotation-detail';
export const LOCAL_STORAGE_SALES_QUOTATION_ADD = 'sales-quotation-add';
export const LOCAL_STORAGE_SALES_PAYMENT_HISTORY = 'sales-payment-history';
export const LOCAL_STORAGE_ASSET_CATEGORY = 'asset-category';
export const LOCAL_STORAGE_ASSET_CATEGORY_PRINT = 'asset-category-print';
export const LOCAL_STORAGE_ASSET_CATEGORY_DETAIL = 'asset-category-detail';
export const LOCAL_STORAGE_ASSET_MANAGEMENT = 'asset-management';
export const LOCAL_STORAGE_ASSET_MANAGEMENT_PRINT = 'asset-management-print';
export const LOCAL_STORAGE_REPORT_INDEX = 'report-index';
export const LOCAL_STORAGE_REPORT_JOURNAL = 'report-journal';
export const LOCAL_STORAGE_REPORT_INCOME_STATEMENT = 'report-income-statement';
export const LOCAL_STORAGE_REPORT_BALANCE_SHEET = 'report-balance-sheet';
export const LOCAL_STORAGE_REPORT_CAPITAL_CHANGE = 'report-capital-change';
export const LOCAL_STORAGE_REPORT_CASH_FLOW = 'report-cash-flow';
export const LOCAL_STORAGE_REPORT_GENERAL_LEDGER = 'report-general-ledger';
export const LOCAL_STORAGE_REPORT_TRIAL_BALANCE = 'report-trial-balance';
export const LOCAL_STORAGE_ASSET_MANAGEMENT_DETAIL = 'asset-management-detail';
export const LOCAL_STORAGE_SETTINGS_WAREHOUSE_DATA = 'warehouse-data';
export const LOCAL_STORAGE_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE = 'warehouse-data-autocomplete';
export const LOCAL_STORAGE_SETTINGS_WAREHOUSE_PRODUCT_DATA_AUTOCOMPLETE =
  'warehouse-product-data-autocomplete';
export const LOCAL_STORAGE_SETTINGS_WAREHOUSE_DETAIL = 'warehouse-detail';
export const LOCAL_STORAGE_SALES_PAYMENT_ADD = 'sales-payment-add';
export const LOCAL_STORAGE_CLOSING_BOOK_DATA = 'closing-book-data';
export const LOCAL_STORAGE_TEMP_CLOSING_BOOK_PROCESS = 'temp-closing-book-process';
export const LOCAL_STORAGE_CLOSING_BOOK_DETAIL_LIST = 'closing-book-detail-list';
export const LOCAL_STORAGE_TRANSACTION_LIST = 'transaction-list';
export const LOCAL_STORAGE_TRANSACTION_PRINT_LIST = 'transaction-print-list';
export const LOCAL_STORAGE_STOCK_ADJUSTMENT_LIST = 'stock-adjustment-list';
export const LOCAL_STORAGE_STOCK_ADJUSTMENT_PRINT_LIST = 'stock-adjustment-print-list';
export const LOCAL_STORAGE_STOCK_TRANSFER_LIST = 'stock-transfer-list';
export const LOCAL_STORAGE_STOCK_TRANSFER_PRINT_LIST = 'stock-transfer-print-list';
export const LOCAL_STORAGE_MINUTES_OF_HANDOVER_PURCHASE_DELIVERY =
  'minutes-of-handover-purchase-delivery-details';
export const LOCAL_STORAGE_MINUTES_OF_HANDOVER_PURCHASE_DELIVERY_HISTORY =
  'minutes-of-handover-purchase-delivery-history';
export const LOCAL_STORAGE_MINUTES_OF_HANDOVER_SALES_DELIVERY =
  'minutes-of-handover-sales-delivery-details';
export const LOCAL_STORAGE_MINUTES_OF_HANDOVER_SALES_DELIVERY_HISTORY =
  'minutes-of-handover-sales-delivery-history';
export const LOCAL_STORAGE_CHART_PURCHASE_INVOICE_BASIC_LIST = 'chart-basic-purchase-invoice-list';
export const LOCAL_STORAGE_CHART_SALES_INVOICE_BASIC_LIST = 'chart-basic-sales-invoice-list';
export const LOCAL_STORAGE_CHART_PRODUCT_STOCK_BASIC_LIST = 'chart-basic-product-stock-list';
export const LOCAL_STORAGE_CHART_SALES_PERSON_BASIC_LIST = 'chart-basic-sales-person-list';
export const LOCAL_STORAGE_CHART_COMPARISON_SALES_LIST = 'chart-comparison-sales-list';
export const LOCAL_STORAGE_CHART_TOP_SALES_SALES_AGENT_LIST = 'chart-top-sales-sales-agent-list';
export const LOCAL_STORAGE_CHART_TOP_SALES_CUSTOMER_LIST = 'chart-top-sales-customer-list';
export const LOCAL_STORAGE_CHART_TOP_SALES_PRODUCT_LIST = 'chart-top-sales-product-list';
export const LOCAL_STORAGE_CHART_TOP_SALES_PRODUCT_CATEGORY_LIST =
  'chart-top-sales-product-category-list';
export const LOCAL_STORAGE_CHART_TOP_SALES_COMPARISON_SALES_AGENT_LIST =
  'chart-top-sales-comparison-sales-agent-list';
export const LOCAL_STORAGE_CHART_TOP_SALES_COMPARISON_CUSTOMER_LIST =
  'chart-top-sales-comparison-customer-list';
export const LOCAL_STORAGE_CHART_TOP_SALES_COMPARISON_PRODUCT_LIST =
  'chart-top-sales-comparison-product-list';
export const LOCAL_STORAGE_CHART_TOP_SALES_COMPARISON_PRODUCT_CATEGORY_LIST =
  'chart-top-sales-comparison-product-category-list';
export const LOCAL_STORAGE_CHART_PIVOT_TABLE_LIST = 'chart-pivot-table-list';
export const LOCAL_STORAGE_CHART_DASHBOARD_PRO_APP_SEARCH_PARAMS =
  'chart-dashboard-pro-app-search-params';
export const LOCAL_STORAGE_REPORT_BASIC_PURCHASE_LIST = 'report-basic-purchase-list';
export const LOCAL_STORAGE_REPORT_BASIC_SALES_LIST = 'report-basic-sales-list';
export const LOCAL_STORAGE_REPORT_BASIC_STOCK_LIST = 'report-basic-stock-list';
export const LOCAL_STORAGE_REPORT_BASIC_PRODUCT_PRICE_LIST = 'report-basic-product-price-list';
export const LOCAL_STORAGE_REPORT_BASIC_SALES_EXTRACTION_LIST =
  'report-basic-sales-extraction-list';
export const LOCAL_STORAGE_REPORT_STOCK_SALES_LIST = 'report-stock-sales-list';
export const LOCAL_STORAGE_REPORT_STOCK_CARD_LIST = 'report-stock-card-list';
export const LOCAL_STORAGE_REPORT_STOCK_MUTATION_LIST = 'report-stock-mutation-list';
export const LOCAL_STORAGE_REPORT_STOCK_PURCHASE_LIST = 'report-stock-purchase-list';
export const LOCAL_STORAGE_REPORT_STOCK_ADJUSTMENT_LIST = 'report-stock-purchase-list';
export const LOCAL_STORAGE_EXPENSE_LIST = 'expense-list';
export const LOCAL_STORAGE_ALL_NOTIFICATION_LIST = 'all-notifications-list';
export const LOCAL_STORAGE_INVITATION_NOTIFICATION_LIST = 'invitation-notification-list';
export const LOCAL_STORAGE_PURCHASE_RETURN_LIST = 'purchase-return-list';
export const LOCAL_STORAGE_PURCHASE_RETURN_LIST_PDF_PRINT = 'purchase-return-list-pdf-print';
export const LOCAL_STORAGE_PURCHASE_RETURN_DETAILS = 'purchase-return-details';
export const LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_LIST = 'purchase-debit-note-list';
export const LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_LIST_PDF_PRINT =
  'purchase-debit-note-list-pdf-print';
export const LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_DETAILS = 'purchase-debit-note-details';
export const LOCAL_STORAGE_PURCHASE_RECEIVABLE_PAYMENT_LIST = 'purchase-receivable-payment-list';
export const LOCAL_STORAGE_PURCHASE_RECEIVABLE_PAYMENT_LIST_PDF_PRINT =
  'purchase-receivable-payment-list-pdf-print';
export const LOCAL_STORAGE_PURCHASE_RECEIVABLE_PAYMENT_DETAILS =
  'purchase-receivable-payment-details';
export const LOCAL_STORAGE_USER_PERMISSION_LIST_DETAILS = 'user-permission-list-details';
export const LOCAL_STORAGE_SALES_RETURN_LIST = 'sales-return-list';
export const LOCAL_STORAGE_SALES_RETURN_LIST_PRINT_PDF = 'sales-return-list-print-pdf';
export const LOCAL_STORAGE_SALES_RETURN_DETAILS = 'sales-return-details';
export const LOCAL_STORAGE_BILLING_INVOICE_DETAILS = 'billing-invoice-details';
export const LOCAL_STORAGE_BILLING_PACKAGE_LIST = 'billing-package-list';
export const LOCAL_STORAGE_BILLING_INVOICE_LIST = 'billing-invoice-list';
export const LOCAL_STORAGE_SALES_CREDIT_NOTE_LIST_PRINT_PDF = 'sales-credit-note-list-print-pdf';
export const LOCAL_STORAGE_SALES_CREDIT_NOTE_LIST = 'sales-credit-note-list';
export const LOCAL_STORAGE_SALES_CREDIT_NOTE_DETAILS = 'sales-credit-note-details';
export const LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_LIST = 'sales-payable-payment-list';
export const LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_LIST_PRINT_PDF =
  'sales-payable-payment-list-print-pdf';
export const LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_DETAILS = 'sales-payable-payment-details';

// key that only for provide data store in local storage
// if not exist in this constant, all data automatically remove
export const storageConstants = {
  LOCAL_STORAGE_KEY: [
    LOCAL_STORAGE_ROOT_APPLICATION,
    LOCAL_STORAGE_AUTH_SESSION_STATUS,
    LOCAL_STORAGE_ACCOUNT_DATA,
    LOCAL_STORAGE_ACCOUNT_DATA_PRINT,
    LOCAL_STORAGE_ACCOUNT_DETAILS,
    LOCAL_STORAGE_ACCOUNT_DATA_ALTERNATIVE,
    LOCAL_STORAGE_ACCOUNT_TRANSACTION,
    LOCAL_STORAGE_ACCOUNT_CATEGORY,
    LOCAL_STORAGE_ACCOUNT_CATEGORY_PRINT,
    LOCAL_STORAGE_ACCOUNT_PARENT,
    LOCAL_STORAGE_SUB_ACCOUNT,
    LOCAL_STORAGE_ACCOUNT_SUB,
    LOCAL_STORAGE_MANAGEMENT_USERS,
    LOCAL_STORAGE_MANAGEMENT_USERS_DETAILS,
    LOCAL_STORAGE_MANAGEMENT_USERS_FOR_AUTOCOMPLETE,
    LOCAL_STORAGE_MANAGEMENT_USERS_LIST_SETTING_COMPANY,
    LOCAL_STORAGE_MANAGEMENT_ROLE,
    LOCAL_STORAGE_MANAGEMENT_COMPANIES,
    LOCAL_STORAGE_REMEMBER_LOGIN,
    LOCAL_STORAGE_TIMEOUT,
    LOCAL_STORAGE_COMPANY,
    LOCAL_STORAGE_PRIMARY_TOKEN,
    LOCAL_STORAGE_SECONDARY_TOKEN,
    LOCAL_STORAGE_USER,
    LOCAL_STORAGE_USER_COMPANY,
    LOCAL_STORAGE_USER_DETAIL,
    LOCAL_STORAGE_CONTACT_DATA,
    LOCAL_STORAGE_CONTACT_PRINT_DATA,
    LOCAL_STORAGE_PURCHASE_INVOICE_FROM_CONTACT_DATA,
    LOCAL_STORAGE_SALES_INVOICE_FROM_CONTACT_DATA,
    LOCAL_STORAGE_PROVINCE,
    LOCAL_STORAGE_REGIONAL_PROVINCE,
    LOCAL_STORAGE_REGIONAL_CITY,
    LOCAL_STORAGE_REGIONAL_SUB_DISTRICT,
    LOCAL_STORAGE_REGIONAL_URBAN,
    LOCAL_STORAGE_INDO_REGIONAL_DATA,
    LOCAL_STORAGE_BANK_DATA,
    LOCAL_STORAGE_REFERENCE_NUMBER_DATA,
    LOCAL_STORAGE_PRODUCT_CATEGORY,
    LOCAL_STORAGE_PRODUCT_CATEGORY_PRINT,
    LOCAL_STORAGE_PRODUCT_DATA,
    LOCAL_STORAGE_PRODUCT_PRINT_DATA,
    LOCAL_STORAGE_PRODUCT_DATA_ALTERNATIVE,
    LOCAL_STORAGE_PRODUCT_PURCHASE_SALE,
    LOCAL_STORAGE_PRODUCT_BY_SUPPLIER,
    LOCAL_STORAGE_PRODUCT_IMPORTING_PROGRESS,
    LOCAL_STORAGE_JOURNAL_ENTRY,
    LOCAL_STORAGE_JOURNAL_ENTRY_DETAIL,
    LOCAL_STORAGE_BEGINNING_BALANCE,
    LOCAL_STORAGE_RECEIPT_CASH,
    LOCAL_STORAGE_PAYMENT_CASH,
    LOCAL_STORAGE_CASH_TRANSFER,
    LOCAL_STORAGE_CASH_BANK,
    LOCAL_STORAGE_CASH_BANK_CALCULATE,
    LOCAL_STORAGE_CASH_BANK_ACCOUNT_TRANSACTION,
    LOCAL_STORAGE_CASH_BANK_DETAIL_ACCOUNT_TRANSACTION,
    LOCAL_STORAGE_QUOTATION_REQUEST,
    LOCAL_STORAGE_QUOTATION_REQUEST_PRINT_PDF,
    LOCAL_STORAGE_QUOTATION_REQUEST_ADD,
    LOCAL_STORAGE_QUOTATION_REQUEST_DETAIL,
    LOCAL_STORAGE_PURCHASE_ORDER,
    LOCAL_STORAGE_PURCHASE_ORDER_PDF_PRINT,
    LOCAL_STORAGE_PURCHASE_ORDER_AUTOCOMPLETE,
    LOCAL_STORAGE_PURCHASE_ORDER_ADD,
    LOCAL_STORAGE_PURCHASE_PAYMENT,
    LOCAL_STORAGE_PURCHASE_PAYMENT_PDF_PRINT,
    LOCAL_STORAGE_PURCHASE_DELIVERY,
    LOCAL_STORAGE_PURCHASE_DELIVERY_PDF_PRINT,
    LOCAL_STORAGE_PURCHASE_DELIVERY_DETAIL,
    LOCAL_STORAGE_PURCHASE_INVOICE,
    LOCAL_STORAGE_PURCHASE_INVOICE_PDF_PRINT,
    LOCAL_STORAGE_SALES_QUOTATION,
    LOCAL_STORAGE_SALES_QUOTATION_PRINT_PDF,
    LOCAL_STORAGE_SALES_INVOICE,
    LOCAL_STORAGE_SALES_INVOICE_PRINT_PDF,
    LOCAL_STORAGE_CACHE_ADD_UPDATE_PURCHASE_INVOICE_DIALOG,
    LOCAL_STORAGE_SALES_ORDER,
    LOCAL_STORAGE_SALES_ORDER_PRINT_PDF,
    LOCAL_STORAGE_TEMP_ADD_UPDATE_SALES_ORDER,
    LOCAL_STORAGE_SALES_INVOICE_DETAIL,
    LOCAL_STORAGE_SALES_INVOICE_ADD,
    LOCAL_STORAGE_SALES_DELIVERY_AND_ORDER,
    LOCAL_STORAGE_SALES_DELIVERY,
    LOCAL_STORAGE_SALES_DELIVERY_PRINT_PDF,
    LOCAL_STORAGE_TEMP_ADD_UPDATE_SALES_DELIVERY,
    LOCAL_STORAGE_SALES_PAYMENT,
    LOCAL_STORAGE_SALES_PAYMENT_PRINT_PDF,
    LOCAL_STORAGE_SALES_QUOTATION_DETAIL,
    LOCAL_STORAGE_SALES_QUOTATION_ADD,
    LOCAL_STORAGE_SALES_PAYMENT_HISTORY,
    LOCAL_STORAGE_ASSET_CATEGORY,
    LOCAL_STORAGE_ASSET_CATEGORY_PRINT,
    LOCAL_STORAGE_ASSET_CATEGORY_DETAIL,
    LOCAL_STORAGE_ASSET_MANAGEMENT,
    LOCAL_STORAGE_ASSET_MANAGEMENT_PRINT,
    LOCAL_STORAGE_REPORT_INDEX,
    LOCAL_STORAGE_REPORT_JOURNAL,
    LOCAL_STORAGE_REPORT_INCOME_STATEMENT,
    LOCAL_STORAGE_REPORT_BALANCE_SHEET,
    LOCAL_STORAGE_REPORT_CAPITAL_CHANGE,
    LOCAL_STORAGE_REPORT_CASH_FLOW,
    LOCAL_STORAGE_REPORT_GENERAL_LEDGER,
    LOCAL_STORAGE_REPORT_TRIAL_BALANCE,
    LOCAL_STORAGE_ASSET_MANAGEMENT_DETAIL,
    LOCAL_STORAGE_SETTINGS_WAREHOUSE_DATA,
    LOCAL_STORAGE_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE,
    LOCAL_STORAGE_SETTINGS_WAREHOUSE_PRODUCT_DATA_AUTOCOMPLETE,
    LOCAL_STORAGE_SETTINGS_WAREHOUSE_DETAIL,
    LOCAL_STORAGE_SALES_PAYMENT_ADD,
    LOCAL_STORAGE_CLOSING_BOOK_DATA,
    LOCAL_STORAGE_TEMP_CLOSING_BOOK_PROCESS,
    LOCAL_STORAGE_CLOSING_BOOK_DETAIL_LIST,
    LOCAL_STORAGE_TRANSACTION_LIST,
    LOCAL_STORAGE_TRANSACTION_PRINT_LIST,
    LOCAL_STORAGE_STOCK_ADJUSTMENT_LIST,
    LOCAL_STORAGE_STOCK_ADJUSTMENT_PRINT_LIST,
    LOCAL_STORAGE_STOCK_TRANSFER_LIST,
    LOCAL_STORAGE_STOCK_TRANSFER_PRINT_LIST,
    LOCAL_STORAGE_MINUTES_OF_HANDOVER_PURCHASE_DELIVERY,
    LOCAL_STORAGE_MINUTES_OF_HANDOVER_PURCHASE_DELIVERY_HISTORY,
    LOCAL_STORAGE_MINUTES_OF_HANDOVER_SALES_DELIVERY,
    LOCAL_STORAGE_MINUTES_OF_HANDOVER_SALES_DELIVERY_HISTORY,
    LOCAL_STORAGE_CHART_PURCHASE_INVOICE_BASIC_LIST,
    LOCAL_STORAGE_CHART_SALES_INVOICE_BASIC_LIST,
    LOCAL_STORAGE_CHART_PRODUCT_STOCK_BASIC_LIST,
    LOCAL_STORAGE_CHART_SALES_PERSON_BASIC_LIST,
    LOCAL_STORAGE_CHART_COMPARISON_SALES_LIST,
    LOCAL_STORAGE_CHART_TOP_SALES_SALES_AGENT_LIST,
    LOCAL_STORAGE_CHART_TOP_SALES_CUSTOMER_LIST,
    LOCAL_STORAGE_CHART_TOP_SALES_PRODUCT_LIST,
    LOCAL_STORAGE_CHART_TOP_SALES_PRODUCT_CATEGORY_LIST,
    LOCAL_STORAGE_CHART_TOP_SALES_COMPARISON_SALES_AGENT_LIST,
    LOCAL_STORAGE_CHART_TOP_SALES_COMPARISON_CUSTOMER_LIST,
    LOCAL_STORAGE_CHART_TOP_SALES_COMPARISON_PRODUCT_LIST,
    LOCAL_STORAGE_CHART_TOP_SALES_COMPARISON_PRODUCT_CATEGORY_LIST,
    LOCAL_STORAGE_CHART_PIVOT_TABLE_LIST,
    LOCAL_STORAGE_CHART_DASHBOARD_PRO_APP_SEARCH_PARAMS,
    LOCAL_STORAGE_REPORT_BASIC_PURCHASE_LIST,
    LOCAL_STORAGE_REPORT_BASIC_SALES_LIST,
    LOCAL_STORAGE_REPORT_BASIC_STOCK_LIST,
    LOCAL_STORAGE_REPORT_BASIC_PRODUCT_PRICE_LIST,
    LOCAL_STORAGE_REPORT_BASIC_SALES_EXTRACTION_LIST,
    LOCAL_STORAGE_REPORT_STOCK_SALES_LIST,
    LOCAL_STORAGE_REPORT_STOCK_CARD_LIST,
    LOCAL_STORAGE_REPORT_STOCK_MUTATION_LIST,
    LOCAL_STORAGE_REPORT_STOCK_PURCHASE_LIST,
    LOCAL_STORAGE_REPORT_STOCK_ADJUSTMENT_LIST,
    LOCAL_STORAGE_EXPENSE_LIST,
    LOCAL_STORAGE_ALL_NOTIFICATION_LIST,
    LOCAL_STORAGE_INVITATION_NOTIFICATION_LIST,
    LOCAL_STORAGE_PURCHASE_RETURN_LIST,
    LOCAL_STORAGE_PURCHASE_RETURN_LIST_PDF_PRINT,
    LOCAL_STORAGE_PURCHASE_RETURN_DETAILS,
    LOCAL_STORAGE_USER_PERMISSION_LIST_DETAILS,
    LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_LIST,
    LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_LIST_PDF_PRINT,
    LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_DETAILS,
    LOCAL_STORAGE_PURCHASE_RECEIVABLE_PAYMENT_LIST,
    LOCAL_STORAGE_PURCHASE_RECEIVABLE_PAYMENT_LIST_PDF_PRINT,
    LOCAL_STORAGE_PURCHASE_RECEIVABLE_PAYMENT_DETAILS,
    LOCAL_STORAGE_SALES_RETURN_LIST,
    LOCAL_STORAGE_SALES_RETURN_LIST_PRINT_PDF,
    LOCAL_STORAGE_SALES_RETURN_DETAILS,
    LOCAL_STORAGE_BILLING_INVOICE_DETAILS,
    LOCAL_STORAGE_BILLING_PACKAGE_LIST,
    LOCAL_STORAGE_BILLING_INVOICE_LIST,
    LOCAL_STORAGE_SALES_CREDIT_NOTE_LIST_PRINT_PDF,
    LOCAL_STORAGE_SALES_CREDIT_NOTE_LIST,
    LOCAL_STORAGE_SALES_CREDIT_NOTE_DETAILS,
    LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_LIST,
    LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_LIST_PRINT_PDF,
    LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_DETAILS,
  ],
};
