import { createSlice } from '@reduxjs/toolkit';

import {
  NAME_STORE,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_EMAIL_RESEND_FAILED,
  STATUS_REQUEST_EMAIL_RESEND_PENDING,
  STATUS_REQUEST_EMAIL_RESEND_SUCCESS,
} from '../../utils/constants/store.constant';

import { resendEmailUser } from './email.action';

// initial state for email slice
const initialState = {
  isResendEmailSuccess: false,
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
};

const emailSlice = createSlice({
  name: NAME_STORE.EMAIL,
  initialState,
  reducers: {
    resendEmailUser,
  },
  extraReducers: (builder) => {
    builder.addCase(resendEmailUser.pending, (state) => {
      state.isResendEmailSuccess = true;
      state.statusRequest = STATUS_REQUEST_EMAIL_RESEND_PENDING;
    }),
      builder.addCase(resendEmailUser.rejected, (state) => {
        state.isResendEmailSuccess = false;
        state.statusRequest = STATUS_REQUEST_EMAIL_RESEND_FAILED;
      }),
      builder.addCase(resendEmailUser.fulfilled, (state) => {
        state.isResendEmailSuccess = true;
        state.statusRequest = STATUS_REQUEST_EMAIL_RESEND_SUCCESS;
      });
  },
});

const { reducer } = emailSlice;

export default reducer;
