import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { CONTACT_BASE_ACTION_TYPE } from '../../utils/constants/action-type.constant';
import {
  LOCAL_STORAGE_CONTACT_DATA,
  LOCAL_STORAGE_CONTACT_PRINT_DATA,
} from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  SLICE_NAME_CONTACT_DATA,
  SLICE_NAME_CONTACT_PRINT_DATA,
  SLICE_NAME_DETAIL_CONTACT,
  SLICE_NAME_TEMP_ADD_CONTACT_DATA,
  STATUS_REQUEST_ADD_CONTACT_DATA_FAILED,
  STATUS_REQUEST_ADD_CONTACT_DATA_PENDING,
  STATUS_REQUEST_ADD_CONTACT_DATA_SUCCESS,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_DELETE_CONTACT_DATA_FAILED,
  STATUS_REQUEST_DELETE_CONTACT_DATA_PENDING,
  STATUS_REQUEST_DELETE_CONTACT_DATA_SUCCESS,
  STATUS_REQUEST_DETAIL_CONTACT_DATA_FAILED,
  STATUS_REQUEST_DETAIL_CONTACT_DATA_PENDING,
  STATUS_REQUEST_DETAIL_CONTACT_DATA_SUCCESS,
  STATUS_REQUEST_LIST_CONTACT_DATA_FAILED,
  STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
  STATUS_REQUEST_LIST_CONTACT_DATA_SUCCESS,
  STATUS_REQUEST_LIST_PURCHASE_INVOCE_ON_CONTACT_DATA_FAILED,
  STATUS_REQUEST_LIST_PURCHASE_INVOCE_ON_CONTACT_DATA_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_INVOCE_ON_CONTACT_DATA_SUCCESS,
  STATUS_REQUEST_LIST_SALES_INVOICE_ON_CONTACT_DATA_FAILED,
  STATUS_REQUEST_LIST_SALES_INVOICE_ON_CONTACT_DATA_PENDING,
  STATUS_REQUEST_LIST_SALES_INVOICE_ON_CONTACT_DATA_SUCCESS,
  STATUS_REQUEST_UPDATE_CONTACT_DATA_FAILED,
  STATUS_REQUEST_UPDATE_CONTACT_DATA_PENDING,
  STATUS_REQUEST_UPDATE_CONTACT_DATA_SUCCESS,
} from '../../utils/constants/store.constant';
import { contactAsyncThunkTypeWithActionProps } from '../../utils/data/mapping/async-thunk-type-with-action-props.mapping';
import {
  bodyBuilderFulfilledAction,
  bodyBuilderPendingAction,
  bodyBuilderRejectedAction,
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
  sliceReduceHelper,
} from '../../utils/helpers/slice-reducer.helper';

import {
  addContact,
  contactList,
  deleteContact,
  getContactListRefresh,
  getDetailContact,
  getListPurchaseInvoiceWithContactID,
  getListSalesInvoiceWithContactID,
  updateContact,
} from './contact.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  [SLICE_NAME_CONTACT_DATA]: LocalStorage.get(LOCAL_STORAGE_CONTACT_DATA),
  purchaseInvoiceListWithContactID: null,
  [SLICE_NAME_DETAIL_CONTACT]: null,
  [SLICE_NAME_TEMP_ADD_CONTACT_DATA]: null,
  salesInvoiceListWithContactID: null,
  [SLICE_NAME_CONTACT_PRINT_DATA]: LocalStorage.get(LOCAL_STORAGE_CONTACT_PRINT_DATA),
};

const contactSlice = createSlice({
  name: NAME_STORE.CONTACT,
  initialState,
  reducers: {
    contactList,
    getContactListRefresh,
    getDetailContact,
    addContact,
    updateContact,
    deleteContact,
    getListPurchaseInvoiceWithContactID,
    getListSalesInvoiceWithContactID,
    clearContact: (state) => {
      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        category: null,
      };
    },
    clearContactData: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_CONTACT_DATA);

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        contactData: null,
      };
    },
    clearDetailContactData: (state) => {
      return {
        ...state,
        [SLICE_NAME_DETAIL_CONTACT]: null,
      };
    },
    clearPurchaseInvoceListWithContactID: (state) => {
      return {
        ...state,
        purchaseInvoiceListWithContactID: null,
      };
    },
    clearDataContactSlice: (state, action) => {
      const { sliceName } = action.payload;

      if (LocalStorage.get(sliceName)) {
        LocalStorage.remove(sliceName);
      }

      return {
        ...state,
        [sliceName]: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    clearSalesInvoiceListWithContactID: (state) => {
      return {
        ...state,
        salesInvoiceListWithContactID: null,
      };
    },
    clearContactSliceItem: (state, action) => {
      sliceReduceHelper.clearSliceItem({
        state,
        action,
      });
    },
    ...sliceReduceHelper.commonSliceReducers(initialState),
  },
  extraReducers: (builder) => {
    builder.addCase(getContactListRefresh.fulfilled, (state, action) => {
      state.statusRequest = STATUS_REQUEST_LIST_CONTACT_DATA_SUCCESS;
      state.contactData = action.payload.contactData;
    }),
      builder.addCase(getContactListRefresh.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_CONTACT_DATA_FAILED;
      }),
      builder.addCase(getContactListRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_CONTACT_DATA_PENDING;
      }),
      // contact details
      builder.addCase(getDetailContact.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_CONTACT_DATA_SUCCESS;
        state[SLICE_NAME_DETAIL_CONTACT] = action.payload[SLICE_NAME_DETAIL_CONTACT];
      }),
      builder.addCase(getDetailContact.rejected, (state) => {
        state[SLICE_NAME_DETAIL_CONTACT] = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_CONTACT_DATA_FAILED;
      }),
      builder.addCase(getDetailContact.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_CONTACT_DATA_PENDING;
      }),
      // add contact
      builder.addCase(addContact.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_ADD_CONTACT_DATA_SUCCESS;
        state[SLICE_NAME_TEMP_ADD_CONTACT_DATA] = action.payload[SLICE_NAME_TEMP_ADD_CONTACT_DATA];
      }),
      builder.addCase(addContact.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_CONTACT_DATA_FAILED;
        state[SLICE_NAME_TEMP_ADD_CONTACT_DATA] = null;
      }),
      builder.addCase(addContact.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_CONTACT_DATA_PENDING;
      }),
      builder.addCase(updateContact.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_CONTACT_DATA_SUCCESS;
      }),
      builder.addCase(updateContact.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_CONTACT_DATA_FAILED;
      }),
      builder.addCase(updateContact.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_CONTACT_DATA_PENDING;
      }),
      builder.addCase(deleteContact.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_CONTACT_DATA_SUCCESS;
      }),
      builder.addCase(deleteContact.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_CONTACT_DATA_FAILED;
      }),
      builder.addCase(deleteContact.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_CONTACT_DATA_PENDING;
      }),
      builder.addCase(getListPurchaseInvoiceWithContactID.fulfilled, (state, action) => {
        state.purchaseInvoiceListWithContactID = action.payload.purchaseInvoiceListWithContactID;
        state.statusRequest = STATUS_REQUEST_LIST_PURCHASE_INVOCE_ON_CONTACT_DATA_SUCCESS;
      }),
      builder.addCase(getListPurchaseInvoiceWithContactID.rejected, (state) => {
        state.purchaseInvoiceListWithContactID = null;
        state.statusRequest = STATUS_REQUEST_LIST_PURCHASE_INVOCE_ON_CONTACT_DATA_FAILED;
      }),
      builder.addCase(getListPurchaseInvoiceWithContactID.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PURCHASE_INVOCE_ON_CONTACT_DATA_PENDING;
      }),
      builder.addCase(getListSalesInvoiceWithContactID.fulfilled, (state, action) => {
        state.salesInvoiceListWithContactID = action.payload.salesInvoiceListWithContactID;
        state.statusRequest = STATUS_REQUEST_LIST_SALES_INVOICE_ON_CONTACT_DATA_SUCCESS;
      }),
      builder.addCase(getListSalesInvoiceWithContactID.rejected, (state) => {
        state.salesInvoiceListWithContactID = null;
        state.statusRequest = STATUS_REQUEST_LIST_SALES_INVOICE_ON_CONTACT_DATA_FAILED;
      }),
      builder.addCase(getListSalesInvoiceWithContactID.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_SALES_INVOICE_ON_CONTACT_DATA_PENDING;
      }),
      // enhancement for matching request status action on contact
      // current: only include list
      builder.addMatcher(
        (action) => isFulfilledAction({ action, multipleBaseTypes: [CONTACT_BASE_ACTION_TYPE] }),
        (state, action) => {
          bodyBuilderFulfilledAction({
            state,
            action,
            mappingAsyncThunkTypeWithActionProps: contactAsyncThunkTypeWithActionProps,
          });
        },
      ),
      builder.addMatcher(
        (action) => isPendingAction({ action, multipleBaseTypes: [CONTACT_BASE_ACTION_TYPE] }),
        (state, action) => {
          bodyBuilderPendingAction({
            state,
            action,
            mappingAsyncThunkTypeWithActionProps: contactAsyncThunkTypeWithActionProps,
          });
        },
      ),
      builder.addMatcher(
        (action) => isRejectedAction({ action, multipleBaseTypes: [CONTACT_BASE_ACTION_TYPE] }),
        (state, action) => {
          bodyBuilderRejectedAction({
            state,
            action,
            mappingAsyncThunkTypeWithActionProps: contactAsyncThunkTypeWithActionProps,
          });
        },
      );
  },
});

export const { actions, reducer } = contactSlice;

export const {
  clearContact,
  clearContactData,
  clearDetailContactData,
  clearPurchaseInvoceListWithContactID,
  clearDataContactSlice,
  clearSalesInvoiceListWithContactID,
  clearContactSliceItem,
} = actions;

export default reducer;
