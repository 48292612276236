import { isEmpty, omit, pick } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_DISCOUNT_NOMINAL_TYPE } from '../../default/discount-type.default';
import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE,
  DEFAULT_KEY_NAME_FILE_PROPERTIES,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_PRODUCT_API,
  DEFAULT_KEY_NAME_UUID_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import arrHelp from '../../helpers/array.helpers';
import formulaHelpers from '../../helpers/formula.helpers';
import objHelper from '../../helpers/object.helper';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import { formatCalculationPurchaseInvoiceData } from './initial-data-purchase-invoice';
import {
  allowKeysDataProductPurchaseReturn,
  allowKeysDataPurchaseReturn,
  initialDataProductPurchaseReturn,
  initialDataPurchaseReturn,
  initialValidationProductPurchaseReturn,
  initialValidationPurchaseReturn,
  relationalAdditionalCostValueNaccountForPurchaseReturn,
} from './initial-data-purchase-return';

/**
 *
 * @param   { object } param0 params function as object
 *
 * @param   { number }      amount                  total of generate data and validation for purchase return
 * @param   { string }      dataKeyname             return of data for keyname on each items
 * @param   { string }      validationKeyname       return of validation for keyname on each items
 *
 * @returns
 *
 * generated data and validation for purchase return
 *
 */
export const generateDataNvalidationAddPurchaseReturn = ({
  amount = 1,
  dataKeyname = 'purchaseReturnData',
  validationKeyname = 'purchaseReturnValidation',
}) => {
  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    initialDataPurchaseReturn,
    initialValidationPurchaseReturn,
    dataKeyname,
    validationKeyname,
  );
};

/**
 *
 * @param   { object } param0 params function as object
 *
 * @param   { number }      amount                  total of generate data and validation for purchase return
 * @param   { string }      dataKeyname             return of data for keyname on each items
 * @param   { string }      validationKeyname       return of validation for keyname on each items
 *
 * @returns
 *
 * generated data and validation for product purchase return
 *
 */
export const generateDataNvalidationAddProductPurchaseReturn = ({
  amount = 1,
  dataKeyname = 'productPurchaseReturnData',
  validationKeyname = 'productPurchaseReturnValidation',
}) => {
  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    initialDataProductPurchaseReturn,
    initialValidationProductPurchaseReturn,
    dataKeyname,
    validationKeyname,
  );
};

/**
 *
 * @param       { object } param0         object as function params
 *
 *
 * @param       { object }                purchaseInvoiceDetails        result from purchase invoice details
 * @param       { object }                companyUserData               current of data company user
 * @param       { array }                 currentPurchaseReturnData     current state from purchase return
 * @param       { string }                dataKeyname                   return data key name
 * @param       { string }                validationKeyname             return validation key name
 *
 * @returns
 */
export const generateDataNvalidationPurchaseReturnFromPurchaseInvoiceDetails = ({
  purchaseInvoiceDetails,
  companyUserData,
  currentPurchaseReturnData,
  dataKeyname = 'purchaseReturnData',
  validationKeyname = 'purchaseReturnValidation',
}) => {
  if (isEmpty(purchaseInvoiceDetails)) {
    const { id } = currentPurchaseReturnData;

    return {
      [dataKeyname]: [
        {
          ...initialDataPurchaseReturn(id),
          ...omit(currentPurchaseReturnData[0], [
            'id',
            'amount_const',
            'amount',
            'purchase_invoice_no',
            'purchase_invoice_id',
            'purchase_invoice_id_container',
            'supplier_id',
            'supplier_id_container',
            'supplier_address',
            'discount_type',
            'discount_percentage',
            'discount_nominal',
            'invoice_total',
            'invoiceNumber',
          ]),
        },
      ],
      [validationKeyname]: [initialValidationPurchaseReturn(id)],
    };
  }

  const {
    purchase_invoice_id,
    supplier_id,
    supplier_name,
    supplier_code,
    supplier_address,
    purchase_invoice_no,
    discount_type,
    discount_percentage,
    discount_nominal,
    product,
  } = purchaseInvoiceDetails;

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const productAmountConst =
    Array.isArray(product) && !isEmpty(product)
      ? product.reduce((resAmount, currProduct) => {
          const { price, quantity_available } = currProduct;

          return Number(price) * Number(quantity_available) + Number(resAmount);
        }, 0)
      : 0;

  const purchaseReturnData = usedID.map((id) => {
    return {
      ...initialDataPurchaseReturn(id),
      ...omit(currentPurchaseReturnData[0], ['id']),
      invoice_total: formulaHelpers.calculateAmountInvoiceReturn({
        parentData: purchaseInvoiceDetails,
      }),
      invoiceNumber: purchase_invoice_no,
      amount_const: Number(productAmountConst) - Number(discount_nominal) || 0,
      amount: Number(productAmountConst) - Number(discount_nominal) || 0,
      purchase_invoice_id,
      purchase_invoice_no,
      purchase_invoice_id_container: {
        purchase_invoice_id,
        purchase_invoice_no,
      },
      discount_type: discount_type || DEFAULT_DISCOUNT_NOMINAL_TYPE,
      discount_percentage,
      discount_nominal,
      supplier_id,
      supplier_id_container: {
        contact_id: supplier_id,
        contact_code: supplier_code || '',
        contact_name: supplier_name || '',
      },
      supplier_address: supplier_address || '',
      accounting_period: isEmpty(companyUserData)
        ? null
        : companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE],
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
          documentData: purchaseInvoiceDetails,
        }),
    };
  });

  const purchaseReturnValidation = usedID.map((id) => initialValidationPurchaseReturn(id));

  return {
    [dataKeyname]: purchaseReturnData,
    [validationKeyname]: purchaseReturnValidation,
  };
};

export const generateDataNvalidationProductPurchaseInvoiceFromPurchaseInvoiceDetails = ({
  purchaseInvoiceDetails,
  dataKeyname,
  validationKeyname,
}) => {
  if (isEmpty(purchaseInvoiceDetails)) {
    const usagedID = arrHelp.generateArrWithFunc(2, uuidv4);

    return {
      [dataKeyname]: usagedID.map((id) => initialDataProductPurchaseReturn(id)),
      [validationKeyname]: usagedID.map((id) => initialValidationProductPurchaseReturn(id)),
    };
  }

  const productPurchaseInvoiceDetails = purchaseInvoiceDetails[DEFAULT_KEY_NAME_PRODUCT_API];

  if (isEmpty(productPurchaseInvoiceDetails) || !Array.isArray(productPurchaseInvoiceDetails)) {
    return generateDataNvalidationAddProductPurchaseReturn({
      amount: 1,
      dataKeyname,
      validationKeyname,
    });
  }

  const usagedID = arrHelp.generateArrWithFunc(productPurchaseInvoiceDetails.length, uuidv4);

  const productPurchaseReturnData = usagedID.map((id, index) => {
    let selectedProductPurchaseInvoiceDetails = productPurchaseInvoiceDetails[index];
    if (!isEmpty(selectedProductPurchaseInvoiceDetails)) {
      selectedProductPurchaseInvoiceDetails = objHelper.changeFormatValue(
        selectedProductPurchaseInvoiceDetails,
        formatCalculationPurchaseInvoiceData,
        [(value) => Number(value) || ''],
      );
    }

    return {
      ...initialDataProductPurchaseReturn(id),
      ...selectedProductPurchaseInvoiceDetails,
      product_id_container: {
        ...selectedProductPurchaseInvoiceDetails,
      },
      ...formulaHelpers.parseProductAPI2display({
        product: selectedProductPurchaseInvoiceDetails,
        compareQuantityAvailableToModulQuantityAvailable: true,
        swapQuantityOriginWithQuantity: true,
      }),
      // unit_destination: unit_origin, // swapping when for user view
    };
  });

  const productPurchaseReturnValidation = usagedID.map((id) =>
    initialValidationProductPurchaseReturn(id),
  );

  return {
    [dataKeyname]: productPurchaseReturnData,
    [validationKeyname]: productPurchaseReturnValidation,
  };
};

export const generalizeDataAddPurchaseReturn = (purchaseReturnData, productPurchaseReturnData) => {
  if (!Array.isArray(purchaseReturnData) || !Array.isArray(productPurchaseReturnData)) {
    return {
      product: productPurchaseReturnData,
      ...purchaseReturnData,
    };
  }

  purchaseReturnData = purchaseReturnData.map((singlePurchaseReturn) => {
    const { supplier_id_container, transaction_date, attachment } = singlePurchaseReturn;

    const uuidAttachment =
      Array.isArray(attachment) && attachment.length
        ? attachment[0][DEFAULT_KEY_NAME_FILE_PROPERTIES][DEFAULT_KEY_NAME_UUID_API_REF] || ''
        : '';

    const keysAdditionalCostValue = Object.keys(
      relationalAdditionalCostValueNaccountForPurchaseReturn,
    );
    const omitedAdditionalAccount = keysAdditionalCostValue.reduce(
      (allAddCostValue, currAdditionalCost) => {
        if (!Number(singlePurchaseReturn[currAdditionalCost])) {
          allAddCostValue = allAddCostValue.concat(
            relationalAdditionalCostValueNaccountForPurchaseReturn[currAdditionalCost],
          );
        }

        return allAddCostValue;
      },
      [],
    );

    singlePurchaseReturn = omit(singlePurchaseReturn, omitedAdditionalAccount);

    let supplier_name = '';
    if (supplier_id_container) {
      const { contact_name, supplier_name: supplier_name_gen } = supplier_id_container;

      supplier_name = contact_name || supplier_name_gen;
    }

    return objHelper.filteringExistedValueV2({
      obj: pick(
        {
          ...singlePurchaseReturn,
          supplier_name,
          transaction_date: transaction_date ? moment(transaction_date).toISOString(true) : '',
          [DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF]: uuidAttachment,
        },
        allowKeysDataPurchaseReturn,
      ),
    });
  });

  productPurchaseReturnData = productPurchaseReturnData.reduce(
    (resPurchaseReturnProduct, singleProductPurchaseReturn) => {
      const { quantity, quantity_origin, unit_origin, unit_destination } =
        singleProductPurchaseReturn;

      if (!quantity) return resPurchaseReturnProduct;

      return resPurchaseReturnProduct.concat(
        pick(
          {
            ...singleProductPurchaseReturn,
            quantity_origin:
              unit_destination === unit_origin ? Number(quantity) : Number(quantity_origin),
          },
          allowKeysDataProductPurchaseReturn,
        ),
      );
    },
    [],
  );

  return {
    product: productPurchaseReturnData,
    ...purchaseReturnData[0],
  };
};
