import React, { PureComponent } from 'react';
import { ArrowUpward } from '@mui/icons-material';
import { IconButton, TablePagination } from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { PropTypes } from 'prop-types';

import { styles } from './TablePaginationMain.styles';

const displayNoneIconPrevNext = {
  disabled: true,
  sx: {
    '& svg': {
      fill: 'rgba(0, 0, 0, 0.26)!important',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
};

class TablePaginationActionsWithScrollTop extends PureComponent {
  constructor(props) {
    super(props);

    this.onClickScrollTop = this.onClickScrollTop.bind(this);
  }

  onClickScrollTop() {
    const { parentScrollTopButtonID } = this.props;

    if (parentScrollTopButtonID && document.getElementById(parentScrollTopButtonID)) {
      document.getElementById(parentScrollTopButtonID).scrollIntoView();
    }
  }

  render() {
    const { classes, additionalTablePaginationActions } = this.props;

    return (
      <Box className={classes.boxContainerScrollTop}>
        <IconButton className='normalButtonTablePagination' onClick={this.onClickScrollTop}>
          <ArrowUpward fontSize='small' />
        </IconButton>
        <TablePaginationActions {...additionalTablePaginationActions} />
      </Box>
    );
  }
}

const TablePaginationActionsWithScrollTopStyling = withStyles(styles)(
  TablePaginationActionsWithScrollTop,
);

/**
 *
 * @props { bool }      showScrollTopButton             determine is showing scroll to top table
 *      @default        false
 * @props { string }    parentScrollTopButtonID         id parent component for scrolling table, usually on table head, required when you showing scroll top button
 */
class TablePaginationMain extends PureComponent {
  render() {
    const {
      component,
      count,
      className,
      page,
      prev_page,
      next_page,
      rowsPerPage,
      rowsPerPageOptions,
      isLoadingData,
      isDisplayNextPrevWhenNotExist,
      labelRowsPerPage,
      labelDisplayedRows,
      additionalProps,
      showScrollTopButton,
      parentScrollTopButtonID,
    } = this.props;

    const isDisplayIcon = !isDisplayNextPrevWhenNotExist || isLoadingData;

    return (
      <TablePagination
        component={component}
        page={page || 0}
        rowsPerPage={rowsPerPage}
        count={count}
        className={className}
        rowsPerPageOptions={isLoadingData ? [] : rowsPerPageOptions}
        labelRowsPerPage={labelRowsPerPage}
        labelDisplayedRows={labelDisplayedRows}
        onPageChange={
          !isLoadingData
            ? this.props.onPageChange
            : () => {
                return;
              }
        }
        onRowsPerPageChange={
          !isLoadingData
            ? this.props.onRowsPerPageChange
            : () => {
                return;
              }
        }
        SelectProps={{
          disabled: isLoadingData,
        }}
        {...(isDisplayIcon && {
          backIconButtonProps: {
            ...(!prev_page || isLoadingData ? displayNoneIconPrevNext : {}),
          },
          nextIconButtonProps: {
            ...(!next_page || isLoadingData ? displayNoneIconPrevNext : {}),
          },
        })}
        {...additionalProps}
        ActionsComponent={
          showScrollTopButton
            ? (props) => (
                <TablePaginationActionsWithScrollTopStyling
                  additionalTablePaginationActions={props}
                  parentScrollTopButtonID={parentScrollTopButtonID}
                />
              )
            : TablePaginationActions
        }
      />
    );
  }
}

TablePaginationMain.propsType = {
  component: PropTypes.string,
  page: PropTypes.number,
  prev_page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  next_page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowsPerPage: PropTypes.number,
  count: PropTypes.number,
  className: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
  isLoadingData: PropTypes.bool,
  isDisplayNextPrevWhenNotExist: PropTypes.bool,
  labelRowsPerPage: PropTypes.string,
  labelDisplayedRows: PropTypes.func,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  additionalProps: PropTypes.object,
};

TablePaginationMain.defaultProps = {
  component: 'div',
  page: 0,
  prev_page: null,
  next_page: null,
  rowsPerPage: 100,
  count: 10000,
  className: 'primaryTablePagination',
  rowsPerPageOptions: [10, 20, 25, 50, 100],
  isLoadingData: false,
  isDisplayNextPrevWhenNotExist: false,
  labelRowsPerPage: 'Rows per page:',
  labelDisplayedRows: () => '',
  additionalProps: {},
  showScrollTopButton: false,
  parentScrollTopButtonID: '',
};

export default TablePaginationMain;
