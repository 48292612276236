import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty, pick } from 'lodash';

import minutesOfHandoverServices from '../../services/API/minutes-of-handover/minutes-of-handover.services';
import {
  DEFAULT_ACTION_MENU_ADD,
  DEFAULT_ACTION_MENU_UPDATE,
} from '../../utils/default/action-menu.default';
import {
  getHandoverConfiguration,
  MINUTES_OF_HANDOVER_TYPE_PURCHASE_DELIVERY,
} from '../../utils/default/minutes-of-handover-config.default';
import {
  // creatorAddActionWithPreviewButton,
  creatorAddActionWithPreviewButtonGenerateLink,
  creatorDetailAction,
  creatorUpdateAction,
} from '../creator-action/creator-action.helper';

// action for add minutes of handover, purchase delivery or sales delivery
export const addMinutesOfHandover = createAsyncThunk(
  'minutes-of-handover/add',
  async (
    {
      actionName = DEFAULT_ACTION_MENU_ADD,
      minutesHandoverType = MINUTES_OF_HANDOVER_TYPE_PURCHASE_DELIVERY,
      bodyAddMinutesOfHandover,
      additionalSearchParamsFromTransactionDetails,
    },
    thunkAPI,
  ) => {
    actionName = actionName === DEFAULT_ACTION_MENU_ADD ? 'create' : actionName;

    const handoverConfiguration = getHandoverConfiguration({
      minutesHandoverType,
      actionName,
    });

    const { errorMessage, successMessage, uniqueIDdetailsKey, buttonLink, generateButtonLink } =
      handoverConfiguration;

    const bodyDataMinutesHandoverObj =
      bodyAddMinutesOfHandover instanceof FormData
        ? Object.fromEntries(bodyAddMinutesOfHandover.entries())
        : bodyAddMinutesOfHandover;

    let getDetailsTransactionOnBody = pick(bodyDataMinutesHandoverObj, [uniqueIDdetailsKey]);
    if (
      isEmpty(getDetailsTransactionOnBody) &&
      !getDetailsTransactionOnBody[uniqueIDdetailsKey] &&
      !isEmpty(additionalSearchParamsFromTransactionDetails) &&
      additionalSearchParamsFromTransactionDetails[uniqueIDdetailsKey]
    ) {
      getDetailsTransactionOnBody = {
        ...getDetailsTransactionOnBody,
        [uniqueIDdetailsKey]: additionalSearchParamsFromTransactionDetails[uniqueIDdetailsKey],
      };
    }

    if (actionName === DEFAULT_ACTION_MENU_UPDATE) {
      return await creatorUpdateAction(
        thunkAPI,
        {
          actionName,
          minutesHandoverType,
          bodyAddMinutesOfHandover,
        },
        minutesOfHandoverServices.addMinutesOfHandover,
        successMessage,
        errorMessage,
      );
    }

    return await creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: {
        actionName,
        minutesHandoverType,
        bodyAddMinutesOfHandover,
        ...additionalSearchParamsFromTransactionDetails,
        [uniqueIDdetailsKey]: !isEmpty(getDetailsTransactionOnBody)
          ? getDetailsTransactionOnBody[uniqueIDdetailsKey]
          : null,
      },
      serviceFunc: minutesOfHandoverServices.addMinutesOfHandover,
      successMessage,
      errorMessage,
      baseContentUrl: buttonLink,
      generateButtonLink,
    });
  },
);

// details minutes of handover
export const minutesOfHandoverDetails = createAsyncThunk(
  'minutes-of-handover/details',
  async (
    {
      minutesHandoverType = MINUTES_OF_HANDOVER_TYPE_PURCHASE_DELIVERY,
      actionName = 'detail',
      paramsMinutesOfHandoverDetails,
    },
    thunkAPI,
  ) => {
    const { errorMessage, sliceName, localStorageName } = getHandoverConfiguration({
      minutesHandoverType,
      actionName,
    });

    return await creatorDetailAction(
      thunkAPI,
      {
        actionName,
        paramsMinutesOfHandoverDetails,
        minutesHandoverType,
      },
      minutesOfHandoverServices.minutesOfHandoverDetails,
      localStorageName,
      sliceName,
      errorMessage,
    );
  },
);

const minutesOfHandoverActions = {
  addMinutesOfHandover,
  minutesOfHandoverDetails,
};

export default minutesOfHandoverActions;
