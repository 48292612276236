import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_COMPARISON_VALUE_NUMBER } from '../../default/number.default';
import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIOD,
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF,
  DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_PRODUCT_API,
  DEFAULT_KEY_NAME_SALES_ORDER_PRODUCT_AVAILABLE_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';
import arrHelp from '../../helpers/array.helpers';
import formatHelp from '../../helpers/format.helpers';
import objHelper from '../../helpers/object.helper';
import parseResponseHelpers from '../../helpers/parse-response.helpers';
import { DEFAULT_CALCULATION_NUMBER } from '../default/number.default';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import { generalizeAttachmentFromDetails } from './initial-add-attachment';
import {
  generalizeDataAddSalesDelivery,
  generateDataNValidationAddProductSalesDelivery,
  generateDataNValidationAddSalesDelivery,
} from './initial-add-sales-delivery';
import {
  initialProductSalesDeliveryData,
  initialProductSalesDeliveryValidation,
  initialSalesDeliveryData,
  initialSalesDeliveryValidation,
  productSalesDeliveryCalculationNumberKeyNames,
  salesDeliveryCalculationNumberKeyNames,
} from './initial-data-sales-delivery';

/**
 *
 * @param { object } salesDeliveryDetails   data sales delivery details
 * @param { string } customDataKey          name key of return data
 * @param { string } customValidationKey    name key return validation
 * @returns
 *      generate data and validation for sales delivery on menu update
 */
export const generateDataNValidationUpdateSalesDeliveryFromSalesDeliveryDetails = (
  salesDeliveryDetails,
  customDataKey = 'salesDeliveryData',
  customValidationKey = 'salesDeliveryValidation',
) => {
  if (isEmpty(salesDeliveryDetails)) {
    return generateDataNValidationAddSalesDelivery(1, customDataKey, customValidationKey);
  }

  salesDeliveryDetails = objHelper.changeFormatValue(
    salesDeliveryDetails,
    salesDeliveryCalculationNumberKeyNames,
    [(value) => Number(value)],
  );

  const {
    customer_id,
    customer_name: contact_name,
    contact_code,
    delivery_date,
    sales_delivery_no: reference_number,
    sales_delivery_id,
    sales_order_id,
    sales_order_no,
    transaction_no,
  } = salesDeliveryDetails;

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const salesDeliveryData = usedID.map((id) => {
    let sales_order_id_container = {
      sales_order_id,
      transaction_no: sales_order_no || transaction_no,
      sales_order_no,
    };

    if (!isEmpty(sales_order_id_container)) {
      sales_order_id_container = objHelper.filteringExistedValue(sales_order_id_container) || null;
    }

    return {
      ...initialSalesDeliveryData(id),
      ...salesDeliveryDetails,
      customer_id_container: {
        contact_id: customer_id,
        contact_code,
        contact_name,
      },
      delivery_date: delivery_date ? moment(delivery_date) : null,
      reference_number,
      reference_number_container: {
        value: reference_number,
      },
      sales_order_id_container,
      sales_delivery_id,
      discount_account_id_container: {
        contact_id: salesDeliveryDetails.discount_account_id || '',
        contact_name: salesDeliveryDetails.discount_account_name || '',
        contact_code: salesDeliveryDetails.discount_account_code || '',
      },
      expense_account_id_container: {
        contact_id: salesDeliveryDetails.expense_account_id || '',
        contact_name: salesDeliveryDetails.expense_account_name || '',
        contact_code: salesDeliveryDetails.expense_account_code || '',
      },
      down_payment_account_id_container: {
        contact_id: salesDeliveryDetails.down_payment_account_id || '',
        contact_name: salesDeliveryDetails.down_payment_account_name || '',
        contact_code: salesDeliveryDetails.down_payment_account_code || '',
      },
    };
  });

  const salesDeliveryValidation = usedID.map((id) => {
    return initialSalesDeliveryValidation(id);
  });

  return {
    [customDataKey]: salesDeliveryData,
    [customValidationKey]: salesDeliveryValidation,
  };
};

/**
 *
 * @param { object } salesDeliveryDetails   data sales delivery details
 * @param { string } customDataKey          name key of return data
 * @param { string } customValidationKey    name key return validation
 * @returns
 *      generate data and validation for sales delivery on menu update
 */
export const generateDataNValidationUpdateSalesDeliveryFromSalesDeliveryDetailsV2 = ({
  salesDeliveryDetails,
  companyUserData,
  customDataKey = 'salesDeliveryData',
  customValidationKey = 'salesDeliveryValidation',
}) => {
  if (isEmpty(salesDeliveryDetails)) {
    return generateDataNValidationAddSalesDelivery(1, customDataKey, customValidationKey);
  }

  salesDeliveryDetails = objHelper.changeFormatValue(
    salesDeliveryDetails,
    salesDeliveryCalculationNumberKeyNames,
    [(value) => Number(value)],
  );

  const {
    customer_id,
    customer_name: contact_name,
    contact_code,
    delivery_date,
    sales_delivery_no: reference_number,
    sales_delivery_id,
    sales_order_id,
    sales_order_no,
    transaction_no,
    warehouse_id,
    warehouse_name,
  } = salesDeliveryDetails;

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const salesDeliveryData = usedID.map((id) => {
    let sales_order_id_container = {
      sales_order_id,
      transaction_no: sales_order_no || transaction_no,
      sales_order_no,
    };

    let accountingPeriodCompany = null;
    if (
      !isEmpty(companyUserData) &&
      !companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF]
    ) {
      accountingPeriodCompany = moment(
        companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF],
      );
    }

    if (!isEmpty(sales_order_id_container)) {
      sales_order_id_container = objHelper.filteringExistedValue(sales_order_id_container) || null;
    }

    return {
      ...initialSalesDeliveryData(id),
      ...salesDeliveryDetails,
      attachment: generalizeAttachmentFromDetails({
        dataDetails: salesDeliveryDetails,
      }),
      warehouse_id_container: {
        warehouse_id,
        warehouse_name,
      },
      discount_account_id_container: {
        contact_id: salesDeliveryDetails.discount_account_id || '',
        contact_name: salesDeliveryDetails.discount_account_name || '',
        contact_code: salesDeliveryDetails.discount_account_code || '',
      },
      expense_account_id_container: {
        contact_id: salesDeliveryDetails.expense_account_id || '',
        contact_name: salesDeliveryDetails.expense_account_name || '',
        contact_code: salesDeliveryDetails.expense_account_code || '',
      },
      down_payment_account_id_container: {
        contact_id: salesDeliveryDetails.down_payment_account_id || '',
        contact_name: salesDeliveryDetails.down_payment_account_name || '',
        contact_code: salesDeliveryDetails.down_payment_account_code || '',
      },
      sales_agent_id_container: {
        contact_id: salesDeliveryDetails.sales_agent_id || '',
        contact_name: salesDeliveryDetails.sales_agent_name || '',
        contact_code: salesDeliveryDetails.sales_agent_code || '',
      },
      customer_id_container: {
        contact_id: customer_id,
        contact_code,
        contact_name,
      },
      delivery_date: delivery_date ? moment(delivery_date) : null,
      reference_number,
      reference_number_container: {
        value: reference_number,
      },
      sales_order_id_container,
      sales_delivery_id,
      [DEFAULT_KEY_NAME_ACCOUNTING_PERIOD]: accountingPeriodCompany,
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
      ...parseResponseHelpers.tagContainerInputFromDetails(salesDeliveryDetails),
    };
  });

  const salesDeliveryValidation = usedID.map((id) => {
    return initialSalesDeliveryValidation(id);
  });

  return {
    [customDataKey]: salesDeliveryData,
    [customValidationKey]: salesDeliveryValidation,
  };
};

/**
 *
 * @param { object } salesDeliveryDetails   data detail sales delivery
 * @param { string } customDataKey          name key of return data
 * @param { string } customValidationKey    name key return validation
 * @returns
 *      generate data and validation for product of sales delivery on menu update
 */
export const generateDataNValidationUpdateProductSalesDeliveryFromSalesDeliveryDetails = (
  salesDeliveryDetails,
  customDataKey = 'productSalesDeliveryData',
  customValidationKey = 'productSalesDeliveryValidation',
) => {
  const { product } = salesDeliveryDetails;

  if (isEmpty(product)) {
    return generateDataNValidationAddProductSalesDelivery(2, customDataKey, customValidationKey);
  }

  const usedID = arrHelp.generateArrWithFunc(product.length, uuidv4);

  const productSalesDeliveryData = usedID.map((id, index) => {
    const {
      product_id,
      product_name,
      product_code,
      quantity,
      quantity_origin,
      product_unit,
      unit_destination,
      unit_origin,
    } = product[index];

    const salesOrderProductAvailable =
      product[index][DEFAULT_KEY_NAME_SALES_ORDER_PRODUCT_AVAILABLE_API_REF];

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    const perComparisonValue = Number(1 / quantityComparison);

    let quantityAvailableShowingUser = Number(DEFAULT_CALCULATION_NUMBER);

    if (salesOrderProductAvailable) {
      quantityAvailableShowingUser = Number(
        formatHelp.reverseCurrencyFormatWithRegex(salesOrderProductAvailable),
      );

      quantityAvailableShowingUser = Number(perComparisonValue * quantityAvailableShowingUser);
    }

    const quantityAvailableConst =
      quantityAvailableShowingUser + Number(formatHelp.reverseCurrencyFormatWithRegex(quantity));

    return {
      ...initialProductSalesDeliveryData(id),
      ...product[index],
      product_id_container: {
        product_id,
        product_code,
        product_name,
        ...product[index],
      },
      quantity,
      quantity_available: formatHelp.currencyFormatWithRegex(quantityAvailableShowingUser),
      quantity_available_const: Number(quantityAvailableConst),
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantityAvailableConst),
        value_comparison: quantityComparison,
      },
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      quantity_available_with_unit: `${Number(quantityAvailableConst)} ${
        unit_origin || product_unit
      }`,
    };
  });

  const productSalesDeliveryValidation = usedID.map((id) => {
    return initialProductSalesDeliveryValidation(id);
  });

  return {
    [customDataKey]: productSalesDeliveryData,
    [customValidationKey]: productSalesDeliveryValidation,
  };
};

/**
 *
 * @param { object } salesDeliveryDetails   data detail sales delivery
 * @param { string } customDataKey          name key of return data
 * @param { string } customValidationKey    name key return validation
 * @returns
 *      generate data and validation for product of sales delivery on menu update
 */
export const generateDataNValidationUpdateProductSalesDeliveryFromSalesDeliveryDetailsV2 = ({
  salesDeliveryDetails,
  customDataKey = 'productSalesDeliveryData',
  customValidationKey = 'productSalesDeliveryValidation',
  keynameProductSalesDelivery = DEFAULT_KEY_NAME_PRODUCT_API,
}) => {
  const productSalesDelivery = salesDeliveryDetails[keynameProductSalesDelivery];

  if (!Array.isArray(productSalesDelivery) || !productSalesDelivery.length) {
    return generateDataNValidationAddProductSalesDelivery(2, customDataKey, customValidationKey);
  }

  const usedID = arrHelp.generateArrWithFunc(productSalesDelivery.length, uuidv4);

  const productSalesDeliveryData = usedID.map((id, index) => {
    let selectedProductSalesDelivery = productSalesDelivery[index];
    if (!isEmpty(selectedProductSalesDelivery)) {
      selectedProductSalesDelivery = objHelper.changeFormatValue(
        selectedProductSalesDelivery,
        productSalesDeliveryCalculationNumberKeyNames,
        [(value) => Number(value)],
      );
    }

    const {
      product_id,
      product_name,
      product_code,
      quantity,
      quantity_origin,
      description,
      product_unit,
      unit_destination,
      unit_origin,
    } = selectedProductSalesDelivery;

    const salesOrderProductAvailable =
      selectedProductSalesDelivery[DEFAULT_KEY_NAME_SALES_ORDER_PRODUCT_AVAILABLE_API_REF];

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    const perComparisonValue = Number(1 / quantityComparison);

    let quantityAvailableShowingUser = Number(DEFAULT_CALCULATION_NUMBER);

    if (salesOrderProductAvailable) {
      quantityAvailableShowingUser = Number(
        formatHelp.reverseCurrencyFormatWithRegex(salesOrderProductAvailable),
      );

      quantityAvailableShowingUser = Number(perComparisonValue * quantityAvailableShowingUser);
    }

    const quantityAvailableConst =
      quantityAvailableShowingUser + Number(formatHelp.reverseCurrencyFormatWithRegex(quantity));

    return {
      ...initialProductSalesDeliveryData(id),
      ...selectedProductSalesDelivery,
      product_id_container: {
        product_id,
        product_code,
        product_name,
        ...selectedProductSalesDelivery,
      },
      product_description: description || '',
      quantity,
      quantity_available: formatHelp.currencyFormatWithRegex(quantityAvailableShowingUser),
      quantity_available_const: Number(quantityAvailableConst),
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantityAvailableConst),
        value_comparison: quantityComparison,
      },
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      quantity_available_with_unit: `${Number(quantityAvailableConst)} ${
        unit_origin || product_unit
      }`,
    };
  });

  const productSalesDeliveryValidation = usedID.map((id) => {
    return initialProductSalesDeliveryValidation(id);
  });

  return {
    [customDataKey]: productSalesDeliveryData,
    [customValidationKey]: productSalesDeliveryValidation,
  };
};

/**
 *
 * @param { array } salesDeliveryData           bunch data sales delivery that inputted by user on menu update
 * @param { array } productSalesDeliveryData    data product of sales delivery that inputted by user on menu update
 * @returns
 *      generalize data sales delivery that inputted by user, follow requirement from API
 */
export const generalizeDataUpdateSalesDelivery = (salesDeliveryData, productSalesDeliveryData) => {
  const { sales_delivery_id } =
    Array.isArray(salesDeliveryData) && salesDeliveryData.length
      ? cloneDeep(salesDeliveryData[0])
      : salesDeliveryData;

  const generalizedDataSalesDelivery = generalizeDataAddSalesDelivery(
    salesDeliveryData,
    productSalesDeliveryData,
  );

  return {
    sales_delivery_id,
    ...generalizedDataSalesDelivery,
  };
};
