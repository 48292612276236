import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';

class BoxActionPermission extends PureComponent {
  render() {
    const { permissionsUser, permissionRequired, children } = this.props;

    if (!permissionsUser.includes(permissionRequired)) {
      return null;
    }

    return <>{children}</>;
  }
}

BoxActionPermission.propsType = {
  permissionsUser: PropTypes.array,
  permissionRequired: PropTypes.string,
};

BoxActionPermission.defaultProps = {
  permissionsUser: [],
  permissionRequired: '',
};

export default BoxActionPermission;
