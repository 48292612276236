import axios from 'axios';

import {
  REACT_APP_API_URL_COMPANY_REGISTER,
  REACT_APP_API_URL_MANAGEMENT_COMPANY,
} from '../../../utils/configs/api.config';
import { DEFAULT_SETTING_COMPANY_LIST_PARAMS } from '../../../utils/default/params.default';
import { authHeader } from '../../../utils/helpers/auth-header.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import {
  creatorListService,
  creatorMultipartFormDataService,
} from '../creator-services/creator-services.helper';

// get list company
const getCompanyList = async (paramsGetCompanyList) => {
  return await creatorListService(
    REACT_APP_API_URL_MANAGEMENT_COMPANY,
    paramsGetCompanyList,
    DEFAULT_SETTING_COMPANY_LIST_PARAMS,
  );
};

// get company detail
const companyDetailWithID = async (company_id) => {
  const { Authorization } = authHeader();

  if (!Authorization || !company_id) {
    return null;
  }

  return await axios({
    method: 'GET',
    url: `${REACT_APP_API_URL_MANAGEMENT_COMPANY}/${company_id}`,
    headers: {
      Authorization,
    },
  });
};

// update user company
const updateCompanyUser = async (dataBodyUpdateCompany) => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  dataBodyUpdateCompany = objHelper.filteringExistedValue(dataBodyUpdateCompany, [], true);

  return await axios({
    method: 'PUT',
    url: `${REACT_APP_API_URL_MANAGEMENT_COMPANY}`,
    headers: {
      Authorization,
    },
    data: dataBodyUpdateCompany,
  });
};

// registering on new company
const registerCompany = async (bodyDataRegisterCompany) => {
  return await creatorMultipartFormDataService({
    endpoint: REACT_APP_API_URL_COMPANY_REGISTER,
    bodyData: bodyDataRegisterCompany,
  });
};

const companyServices = {
  getCompanyList,
  companyDetailWithID,
  updateCompanyUser,
  registerCompany,
};

export default companyServices;
