import * as React from 'react';
function SvgIconFolderOpenCustom(props) {
  return (
    <svg width={31} height={26} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.162 2.737H27.29c1.667 0 3.032 1.232 3.032 2.738v2.393c.15.323.232.677.232 1.047v13.687c0 1.505-1.365 2.737-3.033 2.737H3.264c-1.668 0-3.032-1.232-3.032-2.737l.002-2.39A2.486 2.486 0 010 19.163L.015 2.736C.015 1.232 1.365 0 3.032 0h9.097l3.033 2.737zm12.129 3.44H15.393l-.779-.702h12.677v.703z'
        fill='#2E90FA'
      />
    </svg>
  );
}
export default SvgIconFolderOpenCustom;
