import { checkboxVariant } from '../../../components/checkbox/CheckboxWithLabel/CheckboxWithLabel';
import {
  DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
  DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
  DEFAULT_CHIP_COMPONENT_NAME_WARNING_STATUS,
} from '../../default/chip-component-name.default';

export const comparisonSalesProfitType = 'comparison-sales-profit';
export const comparisonSalesSalesType = 'comparison-sales-sales';
export const comparisonSalesExpenseType = 'comparison-sales-expense';

/**
 *
 * @param   { function } t translation function
 * @returns
 *      pair label, value, and colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 *      - colorChip is for adding color on chip related to theme
 */
export function comparisonSalesChartTypeWithCheckboxVariant(t) {
  return [
    {
      label: t('label.income-statement'),
      value: comparisonSalesProfitType,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_WARNING_STATUS,
      checkboxVariant: checkboxVariant.primary,
    },
    {
      label: t('label.sales'),
      value: comparisonSalesSalesType,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
      checkboxVariant: checkboxVariant.successPastel,
    },
    {
      label: t('label.expense'),
      value: comparisonSalesExpenseType,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
      checkboxVariant: checkboxVariant.errorPastel,
    },
  ];
}
