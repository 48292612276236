import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { difference, isEmpty } from 'lodash';

import { ROOT_STORE_NAME_AUTH, SLICE_NAME_AUTH_DATA } from '../utils/constants/store.constant';
import objHelper from '../utils/helpers/object.helper';

export const permissionMenuType = 'menu';
export const permissionMenuGroupType = 'menuGroup';
export const permissionFeatureType = 'feature';

export const withPermission = ({
  storeProps = {
    rootKeyname: ROOT_STORE_NAME_AUTH,
    sliceKeyname: SLICE_NAME_AUTH_DATA,
  },
  permissionTypeMap = {
    [permissionMenuType]: {
      keyname: 'menu',
      defaultPermissionDeniedProps: {
        display: 'none',
      },
    },
    [permissionMenuGroupType]: {
      keyname: 'menu_group',
      defaultPermissionDeniedProps: {
        sx: {
          display: 'none!important',
        },
        display: 'none',
      },
    },
    [permissionFeatureType]: {
      keyname: 'permission',
      defaultPermissionDeniedProps: {
        display: 'none',
      },
    },
  },
}) =>
  function (WrappedComponent) {
    class ComponentWithPermission extends PureComponent {
      constructor(props) {
        super(props);

        this.isPermissionGranted = this.isPermissionGranted.bind(this);
        this.filteringBlockComponent = this.filteringBlockComponent.bind(this);
        this.getCurrentUserPermissions = this.getCurrentUserPermissions.bind(this);
      }

      getCurrentUserPermissions(permissionType = permissionMenuGroupType) {
        if (!permissionType) return [];

        const { rootKeyname, sliceKeyname } = storeProps;

        const userPermissionsStore = this.props[rootKeyname][sliceKeyname];
        if (isEmpty(userPermissionsStore) || !userPermissionsStore) return [];

        const selectedPermissionTypeItem = objHelper.getDefaultChildrenObjItem({
          obj: permissionTypeMap,
          keynameTarget: permissionType,
        });

        if (isEmpty(selectedPermissionTypeItem)) return [];

        const { keyname } = selectedPermissionTypeItem;

        return objHelper.getChildrenOfObjItemByKeynameStr({
          obj: userPermissionsStore,
          keyname: keyname,
        });
      }

      isPermissionGranted({
        permissionsRequired,
        allowEmptyPermission = true,
        permissionType = permissionMenuGroupType,
      }) {
        if (isEmpty(permissionsRequired) || permissionsRequired === undefined)
          return allowEmptyPermission;

        if (!Array.isArray(permissionsRequired) && typeof permissionsRequired !== 'string')
          return false;

        const currentUserPermissions = this.getCurrentUserPermissions(permissionType);

        return !difference(permissionsRequired, currentUserPermissions).length;
      }

      filteringBlockComponent({
        blockComponentItems,
        permissionRequiredKeyname = 'permissionRequired',
        permissionType = permissionMenuGroupType,
        allowedEmptyPermission = true,
      }) {
        if (isEmpty(blockComponentItems) || typeof blockComponentItems !== 'object')
          return blockComponentItems;

        if (!Array.isArray(blockComponentItems)) {
          const keyBlockComponentItems = Object.keys(blockComponentItems);

          return keyBlockComponentItems.reduce((componentPermissionGranted, currentKey) => {
            const valueOfSingleComponentItem = blockComponentItems[currentKey];

            const isComponentPermissionGranted = this.isPermissionGranted({
              permissionsRequired: valueOfSingleComponentItem[permissionRequiredKeyname],
              permissionType,
            });

            if (isComponentPermissionGranted) {
              return {
                ...componentPermissionGranted,
                [currentKey]: valueOfSingleComponentItem,
              };
            }

            return componentPermissionGranted;
          }, {});
        }

        const currentUserPermissions = this.getCurrentUserPermissions(permissionType);

        return blockComponentItems.filter((componentItem) => {
          const permissionRequired = componentItem[permissionRequiredKeyname];

          if (isEmpty(permissionRequired) || permissionRequired === undefined)
            return allowedEmptyPermission;

          const isPermissionGranted = !difference(permissionRequired, currentUserPermissions)
            .length;

          return isPermissionGranted;
        });
      }

      getAllowedBlockComponent({
        blockComponentItems,
        permissionRequiredKeyname = 'permissionRequired',
        permissionType = permissionMenuGroupType,
        allowedEmptyPermission = true,
      }) {
        const filteredBlockComponent = this.filteringBlockComponent({
          blockComponentItems,
          permissionRequiredKeyname,
          permissionType,
          allowedEmptyPermission,
        });

        return !isEmpty(filteredBlockComponent) ? filteredBlockComponent[0] : {};
      }

      render() {
        return (
          <WrappedComponent
            {...this.props}
            withPermissionMethod={{
              getCurrentUserPermissions: this.getCurrentUserPermissions,
              filteringBlockComponent: this.filteringBlockComponent,
              isGranted: this.isPermissionGranted,
              getAllowedBlockComponent: this.getAllowedBlockComponent,
            }}
          />
        );
      }
    }

    const { rootKeyname } = storeProps;

    const mapStateToProps = (state) => {
      return {
        [rootKeyname]: state[rootKeyname],
      };
    };

    const ConnectedComponentWithPermission = connect(mapStateToProps)(ComponentWithPermission);

    return ConnectedComponentWithPermission;
  };

export default withPermission;
