import React from 'react';
import { isEmpty, isEqual } from 'lodash';

import ListItemProduct, {
  defaultListItemProductPurchaseProps,
} from '../../../components/list-item/ListItemProduct/ListItemProduct';
import { getListProductBySupplier } from '../../../store/product/product.action';
import { clearDataProductSlice } from '../../../store/product/product.slice';
import { PRODUCT_PRICE_ACCESS_PERMISSION_V2 } from '../../constants/permissions.constant';
import {
  NAME_STORE,
  SLICE_NAME_DATA_PRODUCT_BY_SUPPLIER,
  STATUS_REQUEST_LIST_PRODUCT_BY_SUPPLIER_PENDING,
} from '../../constants/store.constant';
import {
  DEFAULT_ACTION_MENU_ADD,
  DEFAULT_ACTION_MENU_UPDATE,
} from '../../default/action-menu.default';
import {
  DEFAULT_GRID_ACTION_COLLAPSE_TYPE,
  DEFAULT_GRID_ACTION_REMOVE_TYPE,
} from '../../default/grid-type.default';
import {
  DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
  DEFAULT_INPUT_TYPE_CALCULATION_NUMBER_INPUT,
  DEFAULT_INPUT_TYPE_COMMON_INPUT,
} from '../../default/input-type.default';
import {
  DEFAULT_KEY_NAME_IS_PURCHASED_API_REF,
  DEFAULT_KEY_NAME_PRICE_API_REF,
  DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
  DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER,
  DEFAULT_KEY_NAME_PRODUCT_NAME_API_REF,
  DEFAULT_KEY_NAME_QUANTITY_AVAILABLE_WITH_UNIT,
  DEFAULT_KEY_NAME_STATUS_REQUEST,
} from '../../default/object-keyname.default';
import { DEFAULT_PAYLOAD_STORAGE_PRODUCT_DATA_BY_SUPPLIER } from '../../default/payload-storage.default';
import { DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE } from '../../default/representation-data-type.default';
import { DEFAULT_CLASSNAME_INPUT_CENTER } from '../../default/styles-classname.default';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';
import autocompleteHelper from '../../helpers/component/autocomplete.helpers';

import { modulTypePurchaseInvoice } from './initial-data-purchase-invoice';
import { getUnitProductAutocompleteTransactionPropertiesPrimary } from './unit-product-properties';

/**
 *
 * @param { function } t translation function
 * @returns
 *      header columns for add and update purchase invoice table
 */
export const columnsTableAddUpdatePurchaseInvoice = (t) => {
  return [
    {
      name: 'product_name',
      label: t('dialog.base-add-update-purchase-invoice.table.header-column.product-name'),
      minWidth: 100,
    },
    {
      name: 'product_description',
      label: t('dialog.base-add-update-purchase-invoice.table.header-column.product-description'),
      width: 750,
    },
    {
      name: 'product_quantity',
      label: t('dialog.base-add-update-purchase-invoice.table.header-column.product-quantity'),
      width: 200,
      align: 'left',
    },
    {
      name: 'product_unit',
      label: t('dialog.base-add-update-purchase-invoice.table.header-column.product-unit'),
      width: 200,
    },
    {
      name: 'product_price',
      label: t('dialog.base-add-update-purchase-invoice.table.header-column.product-price'),
      width: 300,
      align: 'right',
    },
    {
      name: 'product_amount',
      label: t('dialog.base-add-update-purchase-invoice.table.header-column.product-amount'),
      width: 100,
      align: 'right',
    },
    {
      name: 'action',
      label: '',
      align: 'right',
      width: 25,
    },
  ];
};

/**
 *
 * @param { function } t translation function
 * @returns
 *      header columns for add and update purchase invoice table for type basic app
 */
export const columnsTableAddUpdatePurchaseInvoiceBasic = (t) => {
  return [
    {
      name: 'product_name',
      label: t('dialog.base-add-update-purchase-invoice.table.header-column.product-name'),
      minWidth: 120,
      width: 300,
    },
    {
      name: 'product_quantity',
      label: t('dialog.base-add-update-purchase-invoice.table.header-column.product-quantity'),
      minWidth: 50,
      width: 200,
      align: 'right',
    },
    {
      name: 'product_unit',
      label: t('dialog.base-add-update-purchase-invoice.table.header-column.product-unit'),
      minWidth: 70,
      width: 150,
    },
    {
      name: 'product_price',
      label: t('dialog.base-add-update-purchase-invoice.table.header-column.product-price'),
      minWidth: 100,
      width: 300,
      align: 'right',
    },
    {
      name: 'product_amount',
      label: t('dialog.base-add-update-purchase-invoice.table.header-column.product-amount'),
      width: 300,
      align: 'right',
    },
    {
      name: 'action',
      label: '',
      align: 'right',
      width: 25,
    },
  ];
};

// grid header purchase invoice add update
export const gridHeaderProductPurchaseInvoice = [
  {
    name: DEFAULT_GRID_ACTION_COLLAPSE_TYPE,
    label: '',
    align: 'left',
    width: 20,
    gridProps: {
      xs: 0.75,
    },
    isButton: true,
  },
  {
    label: 'table.header-column.product-name',
    placeholder: 'placeholder.select-product',
    isUseMultipleNames: true,
    width: 250,
    minWidth: 150,
    gridProps: {
      xs: 3.7,
      display: 'flex',
      justifyContent: 'start',
    },
    isInput: true,
    storeName: NAME_STORE.PRODUCT,
    childrenStoreName: SLICE_NAME_DATA_PRODUCT_BY_SUPPLIER,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    keyUniqueName: DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
    keyInputName: DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
    keyInputContainerName: DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER,
    statusRequestListPending: STATUS_REQUEST_LIST_PRODUCT_BY_SUPPLIER_PENDING,
    inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
    autocompleteProps: {
      fetchAction: getListProductBySupplier,
      additionalParamsForAction: {
        [DEFAULT_KEY_NAME_IS_PURCHASED_API_REF]: 1,
      },
      removeDataAction: clearDataProductSlice,
      additionalParamsForRemoveDataAction: DEFAULT_PAYLOAD_STORAGE_PRODUCT_DATA_BY_SUPPLIER,
      additionalParamsForScrollAction: {
        isRewriteAll: false,
        [DEFAULT_KEY_NAME_IS_PURCHASED_API_REF]: 1,
      },
      getOptionLabel: (options) =>
        autocompleteHelper.getOptionLabel(options, DEFAULT_KEY_NAME_PRODUCT_NAME_API_REF),
      isOptionEqualToValue: (option, value) => isEqual(option, value),
      isUseFetchOnInput: true,
      keyNameFetchInput: DEFAULT_KEY_NAME_PRODUCT_NAME_API_REF,
      renderOption: (props, option) => (
        <ListItemProduct
          option={option}
          renderOptionProps={props}
          productProps={defaultListItemProductPurchaseProps}
        />
      ),
    },
    isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
      const { status, modul_type } = transactionData[0];

      return (
        (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
          !statusFreeUpdateInputTransaction.includes(status) &&
          modul_type !== modulTypePurchaseInvoice.manual) ||
        (actionMenu === DEFAULT_ACTION_MENU_ADD && modul_type !== modulTypePurchaseInvoice.manual)
      );
    },
  },
  {
    name: 'quantity',
    label: 'table.header-column.product-quantity',
    placeholder: 'placeholder.calculation-number-default',
    align: 'left',
    width: 50,
    minWidth: 50,
    gridProps: {
      xs: 1.2,
      display: 'flex',
      justifyContent: 'center',
    },
    isInput: true,
    inputType: DEFAULT_INPUT_TYPE_CALCULATION_NUMBER_INPUT,
    keyInputName: 'quantity',
    keyMaximumInput: 'quantity_available_const',
    isUseFooterInput: false,
    useFooterShowDecession: true,
    isFooterShow: ({ transactionData }) => {
      if (isEmpty(transactionData)) return false;

      const { modul_type } = transactionData[0];

      // showing footer only create PI from PO
      return modul_type !== modulTypePurchaseInvoice.manual;
    },
    footerInputLabel: 'typography.stock',
    footerInputKeyName: DEFAULT_KEY_NAME_QUANTITY_AVAILABLE_WITH_UNIT,
    additionalOutlinedInput: {
      className: DEFAULT_CLASSNAME_INPUT_CENTER,
    },
    isReadOnly: ({ rowSelectedContainerData }) => {
      return isEmpty(rowSelectedContainerData[DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER]);
    },
    isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
      const { status, modul_type } = transactionData[0];

      return (
        (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
          !statusFreeUpdateInputTransaction.includes(status) &&
          modul_type !== modulTypePurchaseInvoice.manual) ||
        (actionMenu === DEFAULT_ACTION_MENU_ADD && modul_type !== modulTypePurchaseInvoice.manual)
      );
    },
  },
  {
    name: 'product_unit',
    label: 'table.header-column.unit',
    placeholder: 'placeholder.product-unit',
    align: 'left',
    width: 50,
    minWidth: 50,
    gridProps: {
      xs: 1.8,
      display: 'flex',
      justifyContent: 'start',
    },
    ...getUnitProductAutocompleteTransactionPropertiesPrimary({}),
    isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
      const { status, modul_type } = transactionData[0];

      return (
        !hasImplementedUnitProductUnit ||
        (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
          !statusFreeUpdateInputTransaction.includes(status)) ||
        actionMenu === DEFAULT_ACTION_MENU_UPDATE ||
        (actionMenu === DEFAULT_ACTION_MENU_ADD && modul_type !== modulTypePurchaseInvoice.manual)
      );
    },
  },
  {
    name: 'price',
    label: 'table.header-column.at-price',
    placeholder: 'placeholder.calculation-number-default',
    align: 'right',
    width: 75,
    minWidth: 75,
    gridProps: {
      xs: 2,
      display: 'flex',
      justifyContent: 'end',
    },
    isInput: true,
    inputType: DEFAULT_INPUT_TYPE_CALCULATION_NUMBER_INPUT,
    keyInputName: DEFAULT_KEY_NAME_PRICE_API_REF,
    isDisabled: ({
      actionMenu,
      transactionData,
      statusFreeUpdateInputTransaction = [],
      permissions,
    }) => {
      const { status, modul_type } = transactionData[0];

      return (
        (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
          !statusFreeUpdateInputTransaction.includes(status)) ||
        modul_type === modulTypePurchaseInvoice.order ||
        modul_type === modulTypePurchaseInvoice.delivery ||
        (Array.isArray(permissions) && !permissions.includes(PRODUCT_PRICE_ACCESS_PERMISSION_V2))
      );
    },
    isReadOnly: ({ rowSelectedContainerData }) => {
      return isEmpty(rowSelectedContainerData[DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER]);
    },
  },
  {
    name: 'amount',
    label: 'table.header-column.amount',
    align: 'right',
    width: 100,
    minWidth: 80,
    gridProps: {
      xs: 1.75,
      display: 'flex',
      justifyContent: 'end',
    },
    isTypography: true,
    representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
  },
  {
    name: DEFAULT_GRID_ACTION_REMOVE_TYPE,
    label: '',
    align: 'right',
    width: 25,
    gridProps: {
      xs: 0.75,
    },
    isButton: true,
    isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
      const { status, modul_type } = transactionData[0];

      return (
        (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
          !statusFreeUpdateInputTransaction.includes(status)) ||
        (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
          modul_type !== modulTypePurchaseInvoice.manual) ||
        (actionMenu === DEFAULT_ACTION_MENU_ADD && modul_type !== modulTypePurchaseInvoice.manual)
      );
    },
  },
];

export const gridCollapseProductPurchaseInvoice = [
  {
    gridProps: {
      xs: 0.75,
    },
  },
  {
    placeholder: 'label.description',
    inputName: 'memo',
    keyInputName: 'description',
    inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
    gridProps: {
      xs: 10.5,
    },
  },
  {
    gridProps: {
      xs: 0.75,
    },
  },
];
