import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import moment from 'moment';

import purchaseServices from '../../services/API/purchase/purchase.service';
import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  REACT_APP_PURCHASE_DELIVERY_URL,
  REACT_APP_PURCHASE_PURCHASE_DELIVERY_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_PURCHASE_INVOICE_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_PURCHASE_INVOICE_URL,
  REACT_APP_PURCHASE_PURCHASE_ORDER_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_PURCHASE_ORDER_URL,
  REACT_APP_PURCHASE_PURCHASE_PAYMENT,
  REACT_APP_PURCHASE_PURCHASE_PAYMENT_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_QUOTATION_REQUEST_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_RECEIVABLE_PAYMENT_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_RECEIVABLE_PAYMENT_URL,
  REACT_APP_PURCHASE_RETURN_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_RETURN_URL,
  REACT_APP_PURCHASE_URL,
} from '../../utils/configs/url.config';
import { REACT_APP_PURCHASE_QUOTATION_REQUEST_URL } from '../../utils/configs/url.config';
import {
  DEFAULT_IMPORT_FILE_ACTION_TYPE,
  DEFAULT_IMPORT_FILE_PROGRESS_ACTION_TYPE,
  PURCHASE_INVOICE_ASYNC_THUNK_TYPE,
} from '../../utils/constants/action-type.constant';
import {
  LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_DETAILS,
  LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_LIST,
  LOCAL_STORAGE_PURCHASE_DELIVERY,
  LOCAL_STORAGE_PURCHASE_DELIVERY_DETAIL,
  LOCAL_STORAGE_PURCHASE_INVOICE,
  LOCAL_STORAGE_PURCHASE_ORDER,
  LOCAL_STORAGE_PURCHASE_PAYMENT,
  LOCAL_STORAGE_PURCHASE_RECEIVABLE_PAYMENT_DETAILS,
  LOCAL_STORAGE_PURCHASE_RECEIVABLE_PAYMENT_LIST,
  LOCAL_STORAGE_PURCHASE_RETURN_DETAILS,
  LOCAL_STORAGE_PURCHASE_RETURN_LIST,
  LOCAL_STORAGE_QUOTATION_REQUEST,
  LOCAL_STORAGE_QUOTATION_REQUEST_DETAIL,
} from '../../utils/constants/storage.constant';
import {
  NAME_STATE_DETAIL_PURCHASE_PAYMENT,
  REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_FAILED,
  REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
  REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_LIST_PURCHASE_RETURN_FAILED,
  REQUEST_STATUS_LIST_PURCHASE_RETURN_LOADING,
  REQUEST_STATUS_LIST_PURCHASE_RETURN_SUCCESS,
  SLICE_NAME_DATA_PURCHASE_INVOICE,
  SLICE_NAME_DETAIL_PURCHASE_INVOICE,
  SLICE_NAME_DETAIL_PURCHASE_ORDER,
  SLICE_NAME_IMPORT_PURCHASE_INVOICE,
  SLICE_NAME_IMPORT_PURCHASE_INVOICE_PROGRESS,
  SLICE_NAME_LIST_PURCHASE_ORDER_MAIN,
  SLICE_NAME_PURCHASE_DEBIT_NOTE_DETAILS,
  SLICE_NAME_PURCHASE_DEBIT_NOTE_HISTORY,
  SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST,
  SLICE_NAME_PURCHASE_DELIVERY_DATA,
  SLICE_NAME_PURCHASE_DELIVERY_HISTORY_DETAILS,
  SLICE_NAME_PURCHASE_INVOICE_HISTORY,
  SLICE_NAME_PURCHASE_ORDER_HISTORY,
  SLICE_NAME_PURCHASE_PAYMENT_DATA,
  SLICE_NAME_PURCHASE_PAYMENT_HISTORY,
  SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_DETAILS,
  SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_HISTORY,
  SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_LIST,
  SLICE_NAME_PURCHASE_RETURN_DETAILS,
  SLICE_NAME_PURCHASE_RETURN_HISTORY,
  SLICE_NAME_PURCHASE_RETURN_LIST,
  SLICE_NAME_QUOTATION_REQUEST_DATA,
  SLICE_NAME_TEMP_ADD_PURCHASE_INVOICE,
  SLICE_NAME_TEMP_ADD_PURCHASE_INVOICE_DATA,
  SLICE_NAME_TEMP_UPDATE_PURCHASE_INVOICE,
  STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_FAILED,
  STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_SUCCESS,
  STATUS_REQUEST_LIST_PURCHASE_DELIVERY_FAILED,
  STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_DELIVERY_SUCCESS,
  STATUS_REQUEST_LIST_PURCHASE_INVOICE_FAILED,
  STATUS_REQUEST_LIST_PURCHASE_INVOICE_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_INVOICE_SUCCESS,
  STATUS_REQUEST_LIST_PURCHASE_ORDER_FAILED,
  STATUS_REQUEST_LIST_PURCHASE_ORDER_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_ORDER_SUCCESS,
  STATUS_REQUEST_LIST_PURCHASE_PAYMENT_FAILED,
  STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_PAYMENT_SUCCESS,
  STATUS_REQUEST_LIST_QUOTATION_REQUEST_FAILED,
  STATUS_REQUEST_LIST_QUOTATION_REQUEST_PENDING,
  STATUS_REQUEST_LIST_QUOTATION_REQUEST_SUCCESS,
} from '../../utils/constants/store.constant';
import {
  DEFAULT_KEY_NAME_DEBIT_NOTE_ID_API_REF,
  DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF,
  DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF,
  DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_API_REF,
  DEFAULT_KEY_NAME_PURCHASE_PAYMENT_ID_API_REF,
  DEFAULT_KEY_NAME_PURCHASE_RETURN_ID_API_REF,
  DEFAULT_KEY_NAME_QUOTATION_REQUEST_ID_API_REF,
  DEFAULT_KEY_NAME_RECEIVABLE_PAYMENT_ID_API_REF,
} from '../../utils/default/object-keyname.default';
import {
  DEFAULT_ACTION_SECONDARY_PARAMS,
  DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
  DEFAULT_PURCHASE_DELIVERY_SEARCH_FE_PARAMS,
  DEFAULT_PURCHASE_INVOICE_SEARCH_FE_PARAMS,
  DEFAULT_PURCHASE_ORDER_SEARCH_FE_PARAMS,
  DEFAULT_PURCHASE_QR_SEARCH_FE_PARAMS,
  DEFAULT_SEARCH_PARAMS_PURCHASE_DEBIT_NOTE_LIST,
  DEFAULT_SEARCH_PARAMS_PURCHASE_RECEIVABLE_PAYMENT_LIST,
  DEFAULT_SEARCH_PARAMS_PURCHASE_RETURN_LIST,
} from '../../utils/default/params.default';
import {
  DEFAULT_STATUS_PAGE_DETAIL_PURCHASE_DELIVERY,
  DEFAULT_STATUS_PAGE_DETAIL_PURCHASE_INVOICE,
  DEFAULT_STATUS_PAGE_DETAIL_PURCHASE_ORDER,
  DEFAULT_STATUS_PAGE_DETAIL_PURCHASE_PAYMENT,
} from '../../utils/default/status-page.default';
import { DEFAULT_STATUS_PAGE_DETAIL_QUOTATION_REQUEST } from '../../utils/default/status-page.default';
import objHelper from '../../utils/helpers/object.helper';
import strHelp from '../../utils/helpers/string.helpers';
import {
  creatorAddActionWithPreviewButton,
  creatorAddActionWithPreviewButtonGenerateLink,
  creatorAddActionWithStoreDataOnSlice,
  creatorDeleteAction,
  creatorDetailAction,
  creatorListActionDynamicSliceNameNproperties,
  creatorListActionWithDynamicSliceName,
  creatorListRefreshAction,
  creatorUpdateAction,
} from '../creator-action/creator-action.helper';
import messageHelper from '../message/message.helper';

/**
 * quotation request actions
 */
export const getListQuotationRequest = createAsyncThunk(
  'purchase/quotation-request-list',
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_QUOTATION_REQUEST_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_QUOTATION_REQUEST_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_QUOTATION_REQUEST_FAILED,
      slicePayloadKeyname = SLICE_NAME_QUOTATION_REQUEST_DATA,
      localStorageName = LOCAL_STORAGE_QUOTATION_REQUEST,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...paramsGetListQuotationRequest
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params: paramsGetListQuotationRequest,
      apiService: purchaseServices.getListQuotationRequest,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: 'quotation_request_id',
      slicePayloadKeyname,
      errorMessage: 'error.list-data.quotation-request',
    });
  },
);

// list quotation request when refresh data
export const getListQuotationRequestRefresh = createAsyncThunk(
  'purchase/quotation-request-list-refresh',
  async (discardKeySearch = [], thunkAPI) => {
    return creatorListRefreshAction(
      thunkAPI,
      purchaseServices.getListQuotationRequest,
      LOCAL_STORAGE_QUOTATION_REQUEST,
      'quotationRequestData',
      discardKeySearch,
      DEFAULT_PURCHASE_QR_SEARCH_FE_PARAMS,
      'error.list-data.purchase-order',
    );
  },
);

// handling detail quotation request
export const getDetailQuotationRequest = createAsyncThunk(
  'purchase/quotation-request-detail',
  async (quotationRequestID, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      quotationRequestID,
      purchaseServices.getDetailQuotationRequest,
      LOCAL_STORAGE_QUOTATION_REQUEST_DETAIL,
      'quotationRequestDetail',
      'error.detail.quotation-request',
    );
  },
);

// handling history quotation request
export const getHistoryQuotationRequest = createAsyncThunk(
  'purchase/quotation-request-history',
  async (paramsGetQuotationRequestHistory, thunkAPI) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      paramsGetQuotationRequestHistory,
      purchaseServices.getHistoryQuotationRequest,
      '',
      'quotationRequestHistory',
      'quotation_request_id',
      'error.detail.quotation-request-history',
    );
  },
);

// handling add quotation request
export const addQuotationRequest = createAsyncThunk(
  'purchase/quotation-request-add',
  async (bodyAddQuotationRequest, thunkAPI) => {
    return await creatorAddActionWithPreviewButton(
      thunkAPI,
      bodyAddQuotationRequest,
      purchaseServices.addQuotationRequest,
      'success.add.quotation-request',
      'error.add.quotation-request',
      {},
      {},
      'quotation_request_id',
      REACT_APP_PURCHASE_QUOTATION_REQUEST_URL,
      { statusPage: DEFAULT_STATUS_PAGE_DETAIL_QUOTATION_REQUEST },
    );
  },
);

export const addQuotationRequestV2 = createAsyncThunk(
  'purchase/quotation-request-add',
  async (bodyAddQuotationRequest, thunkAPI) => {
    return creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: bodyAddQuotationRequest,
      serviceFunc: purchaseServices.addQuotationRequest,
      successMessage: 'success.add.quotation-request',
      errorMessage: 'error.add.quotation-request',
      baseContentUrl: REACT_APP_PURCHASE_URL,
      generateButtonLink: (responseResult) => {
        const templateLinkDetails = {
          [DEFAULT_KEY_NAME_QUOTATION_REQUEST_ID_API_REF]: objHelper.findAllValuesByKey(
            responseResult,
            DEFAULT_KEY_NAME_QUOTATION_REQUEST_ID_API_REF,
          ),
        };

        return strHelp.templateString(
          REACT_APP_PURCHASE_QUOTATION_REQUEST_DETAILS_TEMPLATE_URL,
          templateLinkDetails,
        );
      },
    });
  },
);

// handling update quotation request
export const updateQuotationRequest = createAsyncThunk(
  'purchase/quotation-request-update',
  async (bodyUpdateQuotationRequest, thunkAPI) => {
    const { transaction_no } = bodyUpdateQuotationRequest;

    try {
      const response = await purchaseServices.updateQuotationRequest(bodyUpdateQuotationRequest);

      if (!response) {
        throw { response };
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.update.quotation-request',
          'success.update.quotation-request',
          {},
          { transaction_no },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.update.quotation-request',
          {},
          { transaction_no },
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      const { response } = error;

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.update.quotation-request',
          'error.update.quotation-request',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.update.quotation-request',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// handling delete quotation request
export const deleteQuotationRequest = createAsyncThunk(
  'purhase/quotation-request-delete',
  async (paramsDeleteQuotationRequest, thunkAPI) => {
    const { transaction_no } = paramsDeleteQuotationRequest;

    return creatorDeleteAction(
      thunkAPI,
      paramsDeleteQuotationRequest,
      purchaseServices.deleteQuotationRequest,
      'success.delete.quotation-request',
      'error.delete.quotation-request',
      {},
      { transaction_no },
    );
  },
);

/**
 * purchase order actions
 */
export const getListPurchaseOrder = createAsyncThunk(
  'purchase/purchase-order-list',
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_PURCHASE_ORDER_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_PURCHASE_ORDER_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_PURCHASE_ORDER_FAILED,
      slicePayloadKeyname = null,
      localStorageName = LOCAL_STORAGE_PURCHASE_ORDER,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      sliceName = SLICE_NAME_LIST_PURCHASE_ORDER_MAIN,
      ...paramsGetListPurchaseOrder
    },
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params: paramsGetListPurchaseOrder,
      apiService: purchaseServices.getListPurchaseOrder,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: 'purchase_order_id',
      slicePayloadKeyname: slicePayloadKeyname || sliceName,
      errorMessage: 'error.list-data.purchase-order',
    });
  },
);

export const getListPurchaseOrderRefresh = createAsyncThunk(
  'purchase/purchase-order-list-refresh',
  async (discardKeySearch = [], thunkAPI) => {
    return creatorListRefreshAction(
      thunkAPI,
      purchaseServices.getListPurchaseOrder,
      LOCAL_STORAGE_PURCHASE_ORDER,
      SLICE_NAME_LIST_PURCHASE_ORDER_MAIN,
      discardKeySearch,
      DEFAULT_PURCHASE_ORDER_SEARCH_FE_PARAMS,
      'error.list-data.purchase-order',
    );
  },
);

export const getDetailPurchaseOrder = createAsyncThunk(
  'purchase/purchase-order-detail',
  async (paramsGetDetailPurchaseOrder, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      paramsGetDetailPurchaseOrder,
      purchaseServices.getDetailPurchaseOrder,
      '',
      SLICE_NAME_DETAIL_PURCHASE_ORDER,
      'error.detail.purchase-order',
    );
  },
);

export const getDetailPurchaseOrderV2 = createAsyncThunk(
  'purchase/purchase-order-detail',
  async (paramsGetDetailPurchaseOrder, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      paramsGetDetailPurchaseOrder,
      purchaseServices.getDetailPurchaseOrderV2,
      '',
      SLICE_NAME_DETAIL_PURCHASE_ORDER,
      'error.detail.purchase-order',
    );
  },
);

// handle purchase order history
export const getHistoryPurchaseOrder = createAsyncThunk(
  'purchase/purchase-order-history',
  async (paramsGetPurchaseOrderHistory, thunkAPI) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      paramsGetPurchaseOrderHistory,
      purchaseServices.getHistoryPurchaseOrder,
      '',
      SLICE_NAME_PURCHASE_ORDER_HISTORY,
      'purchase_order_id',
      'error.detail.purchase-order-history',
    );
  },
);

export const addPurchaseOrder = createAsyncThunk(
  'purchase/purchase-order-add',
  async (bodyAddPurchaseOder, thunkAPI) => {
    return await creatorAddActionWithPreviewButton(
      thunkAPI,
      bodyAddPurchaseOder,
      purchaseServices.addPurchaseOrder,
      'success.add.purchase-order',
      'error.add.purchase-order',
      {},
      {},
      'purchase_order_id',
      REACT_APP_PURCHASE_PURCHASE_ORDER_URL,
      { statusPage: DEFAULT_STATUS_PAGE_DETAIL_PURCHASE_ORDER },
    );
  },
);

export const addPurchaseOrderV2 = createAsyncThunk(
  'purchase/purchase-order-add',
  async (bodyAddPurchaseOder, thunkAPI) => {
    return creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: bodyAddPurchaseOder,
      serviceFunc: purchaseServices.addPurchaseOrder,
      successMessage: 'success.add.purchase-order',
      errorMessage: 'error.add.purchase-order',
      baseContentUrl: REACT_APP_PURCHASE_URL,
      generateButtonLink: (responseResult) => {
        const templateLinkDetails = {
          [DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_API_REF]: objHelper.findAllValuesByKey(
            responseResult,
            DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_API_REF,
          ),
        };

        return strHelp.templateString(
          REACT_APP_PURCHASE_PURCHASE_ORDER_DETAILS_TEMPLATE_URL,
          templateLinkDetails,
        );
      },
    });
  },
);

export const updatePurchaseOrder = createAsyncThunk(
  'purchase/purchase-order-update',
  async (bodyUpdatePurchaseOrder, thunkAPI) => {
    const { purchase_order_no } = bodyUpdatePurchaseOrder;

    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdatePurchaseOrder,
      purchaseServices.updatePurchaseOrder,
      'success.update.purchase-order',
      'error.update.purchase-order',
      {},
      { purchase_order_no },
    );
  },
);

export const deletePurchaseOrder = createAsyncThunk(
  'purhase/purchase-order-delete',
  async (paramsDeletePurchaseOrder, thunkAPI) => {
    const { purchase_order_no } = paramsDeletePurchaseOrder;

    return creatorDeleteAction(
      thunkAPI,
      paramsDeletePurchaseOrder,
      purchaseServices.deletePurchaseOrder,
      'success.delete.purchase-order',
      'error.delete.purchase-order',
      {},
      { purchase_order_no },
    );
  },
);

/**
 * purchase delivery actions
 */
export const getListPurchaseDelivery = createAsyncThunk(
  'purchase/purchase-delivery-list',
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_PURCHASE_DELIVERY_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_PURCHASE_DELIVERY_FAILED,
      slicePayloadKeyname = SLICE_NAME_PURCHASE_DELIVERY_DATA,
      localStorageName = LOCAL_STORAGE_PURCHASE_DELIVERY,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...paramsGetListPurchaseDelivery
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params: paramsGetListPurchaseDelivery,
      apiService: purchaseServices.getListPurchaseDelivery,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: 'purchase_delivery_id',
      slicePayloadKeyname,
      errorMessage: 'error.list-data.purchase-delivery',
    });
  },
);

export const getListPurchaseDeliveryRefresh = createAsyncThunk(
  'purchase/purchase-delivery-list-refresh',
  async (discardKeySearch = [], thunkAPI) => {
    return creatorListRefreshAction(
      thunkAPI,
      purchaseServices.getListPurchaseDelivery,
      LOCAL_STORAGE_PURCHASE_DELIVERY,
      'purchaseDeliveryData',
      discardKeySearch,
      DEFAULT_PURCHASE_DELIVERY_SEARCH_FE_PARAMS,
      'error.list-data.purchase-delivery',
    );
  },
);

// handling detail purchase delivery
export const getDetailPurchaseDelivery = createAsyncThunk(
  'purchase/purchase-delivery-detail',
  async (purchaseDeliveryID, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      purchaseDeliveryID,
      purchaseServices.getDetailPurchaseDelivery,
      LOCAL_STORAGE_PURCHASE_DELIVERY_DETAIL,
      'purchaseDeliveryDetail',
      'error.detail.purchase-delivery',
    );
  },
);

// handle purchase delivery history
export const getPurchaseDeliveryHistoryDetails = createAsyncThunk(
  'purchase/purchase-delivery-history',
  async (paramsGetPurchaseDeliveryHistoryDetails, thunkAPI) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      paramsGetPurchaseDeliveryHistoryDetails,
      purchaseServices.getPurchaseDeliveryHistoryDetails,
      '',
      SLICE_NAME_PURCHASE_DELIVERY_HISTORY_DETAILS,
      'purchase_delivery_id',
      'error.detail.purchase-delivery-history',
    );
  },
);

// handling add purchase delivery
export const addPurchaseDelivery = createAsyncThunk(
  'purchase/purchase-delivery-add',
  async (bodyAddPurchaseDelivery, thunkAPI) => {
    return await creatorAddActionWithPreviewButton(
      thunkAPI,
      bodyAddPurchaseDelivery,
      purchaseServices.addPurchaseDelivery,
      'success.add.purchase-delivery',
      'error.add.purchase-delivery',
      {},
      {},
      'purchase_delivery_id',
      REACT_APP_PURCHASE_DELIVERY_URL,
      { statusPage: DEFAULT_STATUS_PAGE_DETAIL_PURCHASE_DELIVERY },
    );
  },
);

export const addPurchaseDeliveryV2 = createAsyncThunk(
  'purchase/purchase-delivery-add',
  async (bodyAddPurchaseDelivery, thunkAPI) => {
    return creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: bodyAddPurchaseDelivery,
      serviceFunc: purchaseServices.addPurchaseDelivery,
      successMessage: 'success.add.purchase-delivery',
      errorMessage: 'error.add.purchase-delivery',
      baseContentUrl: REACT_APP_PURCHASE_URL,
      generateButtonLink: (responseResult) => {
        const templateLinkDetails = {
          [DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF]: objHelper.findAllValuesByKey(
            responseResult,
            DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF,
          ),
        };

        return strHelp.templateString(
          REACT_APP_PURCHASE_PURCHASE_DELIVERY_DETAILS_TEMPLATE_URL,
          templateLinkDetails,
        );
      },
    });
  },
);

// handling update purchase delivery
export const updatePurchaseDelivery = createAsyncThunk(
  'purchase/purchase-delivery-update',
  async (bodyUpdatePurchaseDelivery, thunkAPI) => {
    const { purchase_delivery_no } = bodyUpdatePurchaseDelivery;

    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdatePurchaseDelivery,
      purchaseServices.updatePurchaseDelivery,
      'success.update.purchase-delivery',
      'error.update.purchase-delivery',
      {},
      { purchase_delivery_no },
    );
  },
);

// handling delete purchase delivery
export const deletePurchaseDelivery = createAsyncThunk(
  'purhase/purchase-delivery-delete',
  async (paramsDeletePurchaseDelivery, thunkAPI) => {
    const { purchase_delivery_no } = paramsDeletePurchaseDelivery;

    return creatorDeleteAction(
      thunkAPI,
      paramsDeletePurchaseDelivery,
      purchaseServices.deletePurchaseDelivery,
      'success.delete.purchase-delivery',
      'error.delete.purchase-delivery',
      {},
      { purchase_delivery_no },
    );
  },
);

/**
 * purchase payment actions
 */
export const getListPurchasePayment = createAsyncThunk(
  'purchase/purchase-payment-list',
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_PURCHASE_PAYMENT_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_PURCHASE_PAYMENT_FAILED,
      slicePayloadKeyname = SLICE_NAME_PURCHASE_PAYMENT_DATA,
      localStorageName = LOCAL_STORAGE_PURCHASE_PAYMENT,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...paramsGetListPurchasePayment
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params: paramsGetListPurchasePayment,
      apiService: purchaseServices.getListPurchasePayment,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: 'purchase_payment_id',
      slicePayloadKeyname,
      errorMessage: 'error.list-data.purchase-payment',
    });
  },
);

// handling list purchase payment when refresh data
export const getListPurchasePaymentRefresh = createAsyncThunk(
  'purchase/purchase-payment-list-refresh',
  async (discardKeySearch = [], thunkAPI) => {
    const currentPurchasePaymentData = LocalStorage.get(LOCAL_STORAGE_PURCHASE_PAYMENT);

    let filterDataSearch = objHelper.filterKeyObj(currentPurchasePaymentData, discardKeySearch, [
      'search_transaction_no',
      'search_from_date',
      'search_to_date',
      'current_page',
      'per_page',
      'search_supplier_name',
    ]);

    const filterDataNextSearch = objHelper.filterKeyObj(filterDataSearch, [
      'per_page',
      'current_page',
    ]);

    filterDataSearch = objHelper.changeSuffixKey(filterDataSearch, 'search_');

    filterDataSearch = objHelper.changeSuffixKey(filterDataSearch, 'current_');

    filterDataSearch = {
      ...filterDataSearch,
      paginate: filterDataSearch ? filterDataSearch.per_page : 20,
    };

    filterDataSearch = objHelper.filteringExistedValue(filterDataSearch);

    filterDataSearch = objHelper.filterKeyObj(filterDataSearch, ['per_page']);

    try {
      const response = await purchaseServices.getListPurchasePayment(filterDataSearch);

      if (!response) {
        throw { response };
      }

      let { sucess, data: purchasePaymentData } = response.data;

      if (!sucess || !Array.isArray(purchasePaymentData.data)) {
        purchasePaymentData = {
          ...purchasePaymentData,
          data: [],
        };
      }

      // add parameter for next search data
      const dataResPurchasePaymentWithSearch = Object.assign(purchasePaymentData, {
        ...filterDataNextSearch,
      });

      LocalStorage.set(LOCAL_STORAGE_PURCHASE_PAYMENT, dataResPurchasePaymentWithSearch);

      return { purchasePaymentData: dataResPurchasePaymentWithSearch };
    } catch (error) {
      const { response } = error;

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.list-data.purchase-payment',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.purchase-payment',
          'error.list-data.purchase-payment',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

export const detailPurchasePayment = createAsyncThunk(
  'purcashe/purchase-payment-detail',
  async (purchasePaymentID, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      purchasePaymentID,
      purchaseServices.detailPurchasePayment,
      LOCAL_STORAGE_PURCHASE_PAYMENT,
      NAME_STATE_DETAIL_PURCHASE_PAYMENT,
      'error.detail.purchase-payment',
    );
  },
);

// handle purchase payment history
export const getHistoryPurchasePayment = createAsyncThunk(
  'purchase/purchase-payment-history',
  async (paramsGetPurchasePaymentHistory, thunkAPI) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      paramsGetPurchasePaymentHistory,
      purchaseServices.getHistoryPurchasePayment,
      '',
      SLICE_NAME_PURCHASE_PAYMENT_HISTORY,
      'purchase_payment_id',
      'error.detail.purchase-payment-history',
    );
  },
);

export const addPurchasePayment = createAsyncThunk(
  'purchase/purchase-payment-add',
  async (bodyAddPurchasePayment, thunkAPI) => {
    return await creatorAddActionWithPreviewButton(
      thunkAPI,
      bodyAddPurchasePayment,
      purchaseServices.addPurchasePayment,
      'success.add.purchase-payment',
      'error.add.purchase-payment',
      {},
      {},
      'purchase_payment_id',
      REACT_APP_PURCHASE_PURCHASE_PAYMENT,
      { statusPage: DEFAULT_STATUS_PAGE_DETAIL_PURCHASE_PAYMENT },
    );
  },
);

export const addPurchasePaymentV2 = createAsyncThunk(
  'purchase/purchase-payment-add',
  async (bodyAddPurchasePayment, thunkAPI) => {
    return creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: bodyAddPurchasePayment,
      serviceFunc: purchaseServices.addPurchasePayment,
      successMessage: 'success.add.purchase-payment',
      errorMessage: 'error.add.purchase-payment',
      baseContentUrl: REACT_APP_PURCHASE_URL,
      generateButtonLink: (responseResult) => {
        const templateLinkDetails = {
          [DEFAULT_KEY_NAME_PURCHASE_PAYMENT_ID_API_REF]: objHelper.findAllValuesByKey(
            responseResult,
            DEFAULT_KEY_NAME_PURCHASE_PAYMENT_ID_API_REF,
          ),
        };

        return strHelp.templateString(
          REACT_APP_PURCHASE_PURCHASE_PAYMENT_DETAILS_TEMPLATE_URL,
          templateLinkDetails,
        );
      },
    });
  },
);

// action for update purchase payment
export const updatePurchasePayment = createAsyncThunk(
  'purchase/purchase-payement-update',
  async (bodyUpdatePurchasePayment, thunkAPI) => {
    const { purchase_payment_no } = bodyUpdatePurchasePayment;

    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdatePurchasePayment,
      purchaseServices.updatePurchasePayment,
      'success.update.purchase-payment',
      'error.update.purchase-payment',
      {},
      { purchase_payment_no },
    );
  },
);

export const deletePurchasePayment = createAsyncThunk(
  'purchase/purchase-payment-delete',
  async (paramsDeletePurchasePayment, thunkAPI) => {
    const { purchase_payment_no } = paramsDeletePurchasePayment;

    return await creatorDeleteAction(
      thunkAPI,
      paramsDeletePurchasePayment,
      purchaseServices.deletePurchasePayment,
      'success.delete.purchase-payment',
      'error.delete.purchase-payment',
      { purchase_payment_no },
      { purchase_payment_no },
    );
  },
);

/**
 * purchase invoice actions
 */
export const getListPurchaseInvoice = createAsyncThunk(
  'purchase/purchase-invoice-list',
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_PURCHASE_INVOICE_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_PURCHASE_INVOICE_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_PURCHASE_INVOICE_FAILED,
      slicePayloadKeyname = SLICE_NAME_DATA_PURCHASE_INVOICE,
      localStorageName = LOCAL_STORAGE_PURCHASE_INVOICE,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      isUseSimplify = true,
      ...paramsGetListPurchaseInvoice
    } = {},
    thunkAPI,
  ) => {
    paramsGetListPurchaseInvoice = objHelper.changeFormatValue(
      paramsGetListPurchaseInvoice,
      ['from_date', 'to_date'],
      [
        (date) => {
          if (!date) return '';

          return moment(date).format('YYYY-MM-DD');
        },
      ],
    );

    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params: paramsGetListPurchaseInvoice,
      apiService: purchaseServices.getListPurchaseInvoice,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: 'purchase_invoice_id',
      slicePayloadKeyname,
      errorMessage: 'error.list-data.purchase-invoice',
      useSimplifyStorage: isUseSimplify,
    });
  },
);

// action list refresh purchase invoice
export const getListPurchaseInvoiceRefresh = createAsyncThunk(
  'purchase/purchase-invoice-list-refresh',
  async (discardKeySearch = [], thunkAPI) => {
    return await creatorListRefreshAction(
      thunkAPI,
      purchaseServices.getListPurchaseInvoice,
      LOCAL_STORAGE_PURCHASE_INVOICE,
      SLICE_NAME_DATA_PURCHASE_INVOICE,
      discardKeySearch,
      DEFAULT_PURCHASE_INVOICE_SEARCH_FE_PARAMS,
      'error.list-data.purchase-invoice',
    );
  },
);

// action detail purchase invoice
export const getDetailPurchaseInvoice = createAsyncThunk(
  'purchase/purchase-invoice-detail',
  async (paramsGetDetailPurchaseInvoice, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      paramsGetDetailPurchaseInvoice,
      purchaseServices.getDetailPurchaseInvoice,
      '',
      SLICE_NAME_DETAIL_PURCHASE_INVOICE,
      'error.detail.purchase-invoice',
    );
  },
);

// handle purchase invoice history
export const getHistoryPurchaseInvoice = createAsyncThunk(
  'purchase/purchase-invoice-history',
  async (paramsGetPurchaseInvoiceHistory, thunkAPI) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      paramsGetPurchaseInvoiceHistory,
      purchaseServices.getHistoryPurchaseInvoice,
      '',
      SLICE_NAME_PURCHASE_INVOICE_HISTORY,
      'purchase_invoice_id',
      'error.detail.purchase-invoice-history',
    );
  },
);

// action add purchase invoice
export const addPurchaseInvoice = createAsyncThunk(
  'purchase/purchase-invoice-add',
  async (bodyAddPurchaseInvoice, thunkAPI) => {
    return await creatorAddActionWithPreviewButton(
      thunkAPI,
      bodyAddPurchaseInvoice,
      purchaseServices.addPurchaseInvoice,
      'success.add.purchase-invoice',
      'error.add.purchase-invoice',
      {},
      {},
      'purchase_invoice_id',
      REACT_APP_PURCHASE_PURCHASE_INVOICE_URL,
      {
        statusPage: DEFAULT_STATUS_PAGE_DETAIL_PURCHASE_INVOICE,
      },
    );
  },
);

export const addPurchaseInvoiceV2 = createAsyncThunk(
  'purchase/purchase-invoice-add',
  async (bodyAddPurchaseInvoice, thunkAPI) => {
    return creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: bodyAddPurchaseInvoice,
      serviceFunc: purchaseServices.addPurchaseInvoice,
      successMessage: 'success.add.purchase-invoice',
      errorMessage: 'error.add.purchase-invoice',
      baseContentUrl: REACT_APP_PURCHASE_URL,
      generateButtonLink: (responseResult) => {
        const templateLinkDetails = {
          [DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF]: objHelper.findAllValuesByKey(
            responseResult,
            DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF,
          ),
        };

        return strHelp.templateString(
          REACT_APP_PURCHASE_PURCHASE_INVOICE_DETAILS_TEMPLATE_URL,
          templateLinkDetails,
        );
      },
    });
  },
);

// action add purchase invoice
export const addPurchaseInvoiceBasic = createAsyncThunk(
  'purchase/purchase-invoice-basic-add',
  async (bodyAddPurchaseInvoice, thunkAPI) => {
    return await creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      bodyAddPurchaseInvoice,
      purchaseServices.addPurchaseInvoice,
      SLICE_NAME_TEMP_ADD_PURCHASE_INVOICE_DATA,
      'success.add.purchase-invoice',
      'error.add.purchase-invoice',
      {},
      {},
    );
  },
);

// action for add purchase invoice and store back data
export const addPurchaseInvoiceWithStoreData = createAsyncThunk(
  'purchase/purchase-invoice-add-with-store-data',
  async (bodyAddPurchaseInvoice, thunkAPI) => {
    return await creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      bodyAddPurchaseInvoice,
      purchaseServices.addPurchaseInvoice,
      SLICE_NAME_TEMP_ADD_PURCHASE_INVOICE,
      'success.add.purchase-invoice',
      'error.add.purchase-invoice',
      {},
      {},
      true,
      ['paid'],
    );
  },
);

export const importPurchaseInvoice = createAsyncThunk(
  PURCHASE_INVOICE_ASYNC_THUNK_TYPE[DEFAULT_IMPORT_FILE_ACTION_TYPE],
  async (bodyData, thunkAPI) => {
    return await creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      bodyData,
      purchaseServices.importPurchaseInvoice,
      SLICE_NAME_IMPORT_PURCHASE_INVOICE,
    );
  },
);

export const getImportPurchaseInvoiceProgress = createAsyncThunk(
  PURCHASE_INVOICE_ASYNC_THUNK_TYPE[DEFAULT_IMPORT_FILE_PROGRESS_ACTION_TYPE],
  async (params, thunkAPI) => {
    return creatorDetailAction(
      thunkAPI,
      params,
      purchaseServices.getImportPurchaseInvoiceProgress,
      null,
      SLICE_NAME_IMPORT_PURCHASE_INVOICE_PROGRESS,
    );
  },
);

// action update purchase invoice
export const updatePurchaseInvoice = createAsyncThunk(
  'purchase/purchase-invoice-update',
  async (bodyUpdatePurchaseInvoice, thunkAPI) => {
    const { reference_number } = bodyUpdatePurchaseInvoice;

    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdatePurchaseInvoice,
      purchaseServices.updatePurchaseInvoice,
      'success.update.purchase-invoice',
      'error.update.purchase-invoice',
      { reference_number },
      { reference_number },
    );
  },
);

// action update purchase invoice that store on slice
export const updatePurchaseInviceWithStoreData = createAsyncThunk(
  'purchase/purchase-invoice-update-store-data',
  async (bodyUpdatePurchaseInvoice, thunkAPI) => {
    if (isEmpty(bodyUpdatePurchaseInvoice)) return thunkAPI.rejectWithValue(null);

    const { reference_number } = bodyUpdatePurchaseInvoice;

    return await creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      bodyUpdatePurchaseInvoice,
      purchaseServices.updatePurchaseInvoice,
      SLICE_NAME_TEMP_UPDATE_PURCHASE_INVOICE,
      'success.update.purchase-invoice',
      'error.update.purchase-invoice',
      { reference_number },
      { reference_number },
      true,
      ['paid'],
    );
  },
);

// handling action delete purchase invoice
export const deletePurchaseInvoice = createAsyncThunk(
  'purchase/purchase-invoice-delete',
  async (paramsDeletePurchaseInvoice, thunkAPI) => {
    const { purchase_invoice_no } = paramsDeletePurchaseInvoice;

    return await creatorDeleteAction(
      thunkAPI,
      paramsDeletePurchaseInvoice,
      purchaseServices.deletePurchaseInvoice,
      'success.delete.purchase-invoice',
      'error.delete.purchase-invoice',
      {},
      { purchase_invoice_no },
    );
  },
);

/**
 * purchase debit note actions
 */
export const getPurchaseDebitNoteList = createAsyncThunk(
  'purchase/debit-note-list',
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_FAILED,
      slicePayloadKeyname = SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST,
      localStorageName = LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_LIST,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...params
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params,
      apiService: purchaseServices.getPurchaseDebitNoteList,
      localStorageName,
      paginatePageParams,
      actionParams: DEFAULT_ACTION_SECONDARY_PARAMS,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: DEFAULT_KEY_NAME_DEBIT_NOTE_ID_API_REF,
      slicePayloadKeyname,
      errorMessage: 'error.list-data.purchase-debit-note',
    });
  },
);

// action purchase debit note refresh list
export const getPurchaseDebitNoteRefreshList = createAsyncThunk(
  'purchase/debit-note-refresh-list',
  async (discardSearchKey = [], thunkAPI) => {
    return await creatorListRefreshAction(
      thunkAPI,
      purchaseServices.getPurchaseDebitNoteList,
      LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_LIST,
      SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST,
      discardSearchKey,
      DEFAULT_SEARCH_PARAMS_PURCHASE_DEBIT_NOTE_LIST,
      'error.list-data.purchase-debit-note',
    );
  },
);

// action purchase debit note details
export const getPurchaseDebitNoteDetails = createAsyncThunk(
  'purchase/debit-note-details',
  async (paramsGetPurchaseDebitNoteDetails, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      paramsGetPurchaseDebitNoteDetails,
      purchaseServices.getPurchaseDebitNoteDetails,
      LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_DETAILS,
      SLICE_NAME_PURCHASE_DEBIT_NOTE_DETAILS,
      'error.detail.purchase-debit-note',
    );
  },
);

export const getPurchaseDebitNoteHistory = createAsyncThunk(
  'purchase/debit-note-history',
  async (params, thunkAPI) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      params,
      purchaseServices.getPurchaseDebitNoteHistory,
      '',
      SLICE_NAME_PURCHASE_DEBIT_NOTE_HISTORY,
      DEFAULT_KEY_NAME_DEBIT_NOTE_ID_API_REF,
      'error.detail.debit-note-history',
    );
  },
);

/**
 * purchase return actions
 */
export const getPurchaseReturnList = createAsyncThunk(
  'purchase/purchase-return-list',
  async (
    {
      requestStatusPending = REQUEST_STATUS_LIST_PURCHASE_RETURN_LOADING,
      requestStatusSuccess = REQUEST_STATUS_LIST_PURCHASE_RETURN_SUCCESS,
      requestStatusFailed = REQUEST_STATUS_LIST_PURCHASE_RETURN_FAILED,
      slicePayloadKeyname = SLICE_NAME_PURCHASE_RETURN_LIST,
      localStorageName = LOCAL_STORAGE_PURCHASE_RETURN_LIST,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...params
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params,
      apiService: purchaseServices.getPurchaseReturnList,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: DEFAULT_KEY_NAME_PURCHASE_RETURN_ID_API_REF,
      slicePayloadKeyname,
      errorMessage: 'error.list-data.purchase-return',
    });
  },
);

// action purchase return refresh list
export const getPurchaseReturnRefreshList = createAsyncThunk(
  'purchase/purchase-return-refresh-list',
  async (discardSearchKey = [], thunkAPI) => {
    return await creatorListRefreshAction(
      thunkAPI,
      purchaseServices.getPurchaseReturnList,
      LOCAL_STORAGE_PURCHASE_RETURN_LIST,
      SLICE_NAME_PURCHASE_RETURN_LIST,
      discardSearchKey,
      DEFAULT_SEARCH_PARAMS_PURCHASE_RETURN_LIST,
      'error.list-data.purchase-return',
    );
  },
);

// action purchase return details
export const getPurchaseReturnDetails = createAsyncThunk(
  'purchase/purchase-return-details',
  async (paramsGetPurchaseReturnDetails, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      paramsGetPurchaseReturnDetails,
      purchaseServices.getPurchaseReturnDetails,
      LOCAL_STORAGE_PURCHASE_RETURN_DETAILS,
      SLICE_NAME_PURCHASE_RETURN_DETAILS,
      'error.detail.purchase-return',
    );
  },
);

export const getPurchaseReturnHistory = createAsyncThunk(
  'purchase/purchase-return-history',
  async (params, thunkAPI) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      params,
      purchaseServices.getPurchaseReturnHistory,
      '',
      SLICE_NAME_PURCHASE_RETURN_HISTORY,
      'purchase_return_id',
      'error.detail.purchase-return-history',
    );
  },
);

// action create purchase return
export const createPurchaseReturn = createAsyncThunk(
  'purchase/purchase-return-create',
  async (bodyCreatePurchaseReturn, thunkAPI) => {
    return await creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: bodyCreatePurchaseReturn,
      serviceFunc: purchaseServices.createPurchaseReturn,
      successMessage: 'success.add.purchase-return',
      errorMessage: 'error.add.purchase-return',
      baseContentUrl: REACT_APP_PURCHASE_RETURN_URL,
      generateButtonLink: (responseResult) => {
        const templateLinkDetails = {
          [DEFAULT_KEY_NAME_PURCHASE_RETURN_ID_API_REF]: objHelper.findAllValuesByKey(
            responseResult,
            DEFAULT_KEY_NAME_PURCHASE_RETURN_ID_API_REF,
          ),
        };

        return strHelp.templateString(
          REACT_APP_PURCHASE_RETURN_DETAILS_TEMPLATE_URL,
          templateLinkDetails,
        );
      },
    });
  },
);

// action update purchase return
export const updatePurchaseReturn = createAsyncThunk(
  'purchase/purchase-return-update',
  async (bodyUpdatePurchaseReturn, thunkAPI) => {
    const { transaction_no } = bodyUpdatePurchaseReturn;

    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdatePurchaseReturn,
      purchaseServices.updatePurchaseReturn,
      'success.update.purchase-return',
      'error.update.purchase-return',
      { transaction_no },
      { transaction_no },
    );
  },
);

// handling delete purchase return
export const deletePurchaseReturn = createAsyncThunk(
  'purhase/purchase-return-delete',
  async (paramsDeletePurchaseReturn, thunkAPI) => {
    const { transaction_no } = paramsDeletePurchaseReturn;

    return creatorDeleteAction(
      thunkAPI,
      paramsDeletePurchaseReturn,
      purchaseServices.deletePurchaseReturn,
      'success.delete.purchase-return',
      'error.delete.purchase-return',
      {},
      { transaction_no },
    );
  },
);

/**
 * purchase receivable payment actions
 */
export const getPurchaseReceivablePaymentList = createAsyncThunk(
  'purchase/receivable-payment-list',
  async (
    {
      requestStatusPending = REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
      requestStatusSuccess = REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS,
      requestStatusFailed = REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_FAILED,
      slicePayloadKeyname = SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_LIST,
      localStorageName = LOCAL_STORAGE_PURCHASE_RECEIVABLE_PAYMENT_LIST,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...params
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params,
      apiService: purchaseServices.getPurchaseReceivablePaymentList,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: DEFAULT_KEY_NAME_RECEIVABLE_PAYMENT_ID_API_REF,
      slicePayloadKeyname,
      errorMessage: 'error.list-data.purchase-receivable-payment',
    });
  },
);

// action purchase receivable payment refresh list
export const getPurchaseReceivablePaymentRefreshList = createAsyncThunk(
  'purchase/receivable-payment-refresh-list',
  async (discardSearchKey = [], thunkAPI) => {
    return await creatorListRefreshAction(
      thunkAPI,
      purchaseServices.getPurchaseReceivablePaymentList,
      LOCAL_STORAGE_PURCHASE_RECEIVABLE_PAYMENT_LIST,
      SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_LIST,
      discardSearchKey,
      DEFAULT_SEARCH_PARAMS_PURCHASE_RECEIVABLE_PAYMENT_LIST,
      'error.list-data.purchase-receivable-payment',
    );
  },
);

export const getPurchaseReceivablePaymentHistory = createAsyncThunk(
  'purchase/receivable-payment-history',
  async (params, thunkAPI) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      params,
      purchaseServices.getPurchaseReceivablePaymentHistory,
      '',
      SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_HISTORY,
      DEFAULT_KEY_NAME_RECEIVABLE_PAYMENT_ID_API_REF,
      'error.detail.purchase-receivable-payment-history',
    );
  },
);

// action purchase receivable payement details
export const getPurchaseReceivablePaymentDetails = createAsyncThunk(
  'purchase/receivable-payment-details',
  async (paramsGetPurchaseReceivablePaymentDetails, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      paramsGetPurchaseReceivablePaymentDetails,
      purchaseServices.getPurchaseReceivablePaymentDetails,
      LOCAL_STORAGE_PURCHASE_RECEIVABLE_PAYMENT_DETAILS,
      SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_DETAILS,
      'error.detail.purchase-receivable-payment',
    );
  },
);

// action create purchase receivable payment
export const createPurchaseReceivablePayment = createAsyncThunk(
  'purchase/receivable-payment-create',
  async (body, thunkAPI) => {
    return await creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: body,
      serviceFunc: purchaseServices.createPurchaseReceivablePayment,
      successMessage: 'success.add.purchase-receivable-payment',
      errorMessage: 'error.add.purchase-receivable-payment',
      baseContentUrl: REACT_APP_PURCHASE_RECEIVABLE_PAYMENT_URL,
      generateButtonLink: (responseResult) => {
        const templateLinkDetails = {
          [DEFAULT_KEY_NAME_RECEIVABLE_PAYMENT_ID_API_REF]: objHelper.findAllValuesByKey(
            responseResult,
            DEFAULT_KEY_NAME_RECEIVABLE_PAYMENT_ID_API_REF,
          ),
        };

        return strHelp.templateString(
          REACT_APP_PURCHASE_RECEIVABLE_PAYMENT_DETAILS_TEMPLATE_URL,
          templateLinkDetails,
        );
      },
    });
  },
);

// action update purchase receivable payment
export const updatePurchaseReceivablePayment = createAsyncThunk(
  'purchase/receivable-payment-update',
  async (bodyUpdatePurchaseReceivablePayment, thunkAPI) => {
    const { transaction_no } = bodyUpdatePurchaseReceivablePayment;

    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdatePurchaseReceivablePayment,
      purchaseServices.updatePurchaseReceivablePayment,
      'success.update.purchase-receivable-payment',
      'error.update.purchase-receivable-payment',
      { transaction_no },
      { transaction_no },
    );
  },
);

// handling delete purchase receivable payment
export const deletePurchaseReceivablePayment = createAsyncThunk(
  'purhase/receivable-payment-delete',
  async (paramsDeletePurcahseReceivablePayment, thunkAPI) => {
    const { transaction_no } = paramsDeletePurcahseReceivablePayment;

    return creatorDeleteAction(
      thunkAPI,
      paramsDeletePurcahseReceivablePayment,
      purchaseServices.deletePurchaseReceivablePayment,
      'success.delete.purchase-receivable-payment',
      'error.delete.purchase-receivable-payment',
      {},
      { transaction_no },
    );
  },
);
