import { LibraryBooks } from '@mui/icons-material';

import { PERMISSION_SALES_DELIVERY_ACCESS } from '../../constants/permissions.constant';
import { DEFAULT_STATUS_PAGE_DETAIL_SALES_DELIVERY } from '../../default/status-page.default';

/**
 *
 * @param { function } t translation function
 * @returns
 *      tooltip of list sales delivery that handle permission also
 */
export const tooltipListSalesDelivery = (t) => {
  return [
    {
      name: DEFAULT_STATUS_PAGE_DETAIL_SALES_DELIVERY,
      title: t('base.tooltip.detail'),
      permission: PERMISSION_SALES_DELIVERY_ACCESS,
    },
  ];
};

export const iconListSalesDeliveryData = [LibraryBooks];
