// initial data account and validation account data

import validation from '../../helpers/validation.helpers';

export const initialAddAccountData = {
  account_code: '',
  account_name: '',
  account_description: '',
  category_account: null,
  is_sub_account: false,
  sub_account: '',
  is_cash_or_bank: true,
  is_active: true,
};

// form multilple accounts
export const initialStateAccountData = {
  account_code: '',
  account_name: '',
  account_description: '',
  category_account: '',
  is_cash_or_bank: false,
  is_active: false,
};

export const initialStateAccountDataValidation = {
  category_account: {
    error: false,
    isValid: (value) => {
      if (value) {
        return true;
      }

      return false;
    },
  },
  account_code: {
    error: false,
    isValid: (value) => validation.isNotEmpty(value),
  },
  account_name: {
    error: false,
    isValid: (value) => validation.isNotEmpty(value),
  },
  account_description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  sub_account: {
    error: false,
    isValid: (value) => {
      if (value) {
        return true;
      }

      return false;
    },
  },
};

export const allowKeynamesAccountDataUpdate = [
  'account_code',
  'account_category_id',
  'account_name',
  'account_normal_balance',
  'account_balance',
  'account_description',
  'category_code',
  'default_category_name',
  'is_parent',
  'parent_id',
];
