import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { BASE_TYPES_SALES_ACTION_REGISTERED } from '../../utils/constants/base-types-action-registered.constant';
import {
  LOCAL_STORAGE_SALES_CREDIT_NOTE_DETAILS,
  LOCAL_STORAGE_SALES_CREDIT_NOTE_LIST,
  LOCAL_STORAGE_SALES_CREDIT_NOTE_LIST_PRINT_PDF,
  LOCAL_STORAGE_SALES_DELIVERY,
  LOCAL_STORAGE_SALES_DELIVERY_AND_ORDER,
  LOCAL_STORAGE_SALES_DELIVERY_PRINT_PDF,
  LOCAL_STORAGE_SALES_INVOICE,
  LOCAL_STORAGE_SALES_INVOICE_PRINT_PDF,
  LOCAL_STORAGE_SALES_ORDER,
  LOCAL_STORAGE_SALES_ORDER_PRINT_PDF,
  LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_DETAILS,
  LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_LIST,
  LOCAL_STORAGE_SALES_PAYMENT,
  LOCAL_STORAGE_SALES_PAYMENT_PRINT_PDF,
  LOCAL_STORAGE_SALES_QUOTATION,
  LOCAL_STORAGE_SALES_QUOTATION_DETAIL,
  LOCAL_STORAGE_SALES_QUOTATION_PRINT_PDF,
} from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  REQUEST_STATUS_CREATE_SALES_PAYABLE_PAYMENT_FAILED,
  REQUEST_STATUS_CREATE_SALES_PAYABLE_PAYMENT_LOADING,
  REQUEST_STATUS_CREATE_SALES_PAYABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_CREATE_SALES_RETURN_FAILED,
  REQUEST_STATUS_CREATE_SALES_RETURN_PENDING,
  REQUEST_STATUS_CREATE_SALES_RETURN_SUCCESS,
  REQUEST_STATUS_DELETE_SALES_PAYABLE_PAYMENT_FAILED,
  REQUEST_STATUS_DELETE_SALES_PAYABLE_PAYMENT_PENDING,
  REQUEST_STATUS_DELETE_SALES_PAYABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_DELETE_SALES_RETURN_FAILED,
  REQUEST_STATUS_DELETE_SALES_RETURN_PENDING,
  REQUEST_STATUS_DELETE_SALES_RETURN_SUCCESS,
  REQUEST_STATUS_DETAILS_SALES_PAYABLE_PAYMENT_FAILED,
  REQUEST_STATUS_DETAILS_SALES_PAYABLE_PAYMENT_LOADING,
  REQUEST_STATUS_DETAILS_SALES_PAYABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_DETAILS_SALES_RETURN_FAILED,
  REQUEST_STATUS_DETAILS_SALES_RETURN_LOADING,
  REQUEST_STATUS_DETAILS_SALES_RETURN_SUCCESS,
  REQUEST_STATUS_HISTORY_SALES_PAYABLE_PAYMENT_FAILED,
  REQUEST_STATUS_HISTORY_SALES_PAYABLE_PAYMENT_LOADING,
  REQUEST_STATUS_HISTORY_SALES_PAYABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_HISTORY_SALES_RETURN_FAILED,
  REQUEST_STATUS_HISTORY_SALES_RETURN_LOADING,
  REQUEST_STATUS_HISTORY_SALES_RETURN_SUCCESS,
  REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_FAILED,
  REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_LOADING,
  REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_LIST_SALES_RETURN_FAILED,
  REQUEST_STATUS_LIST_SALES_RETURN_LOADING,
  REQUEST_STATUS_LIST_SALES_RETURN_SUCCESS,
  REQUEST_STATUS_UPDATE_SALES_PAYABLE_PAYMENT_FAILED,
  REQUEST_STATUS_UPDATE_SALES_PAYABLE_PAYMENT_PENDING,
  REQUEST_STATUS_UPDATE_SALES_PAYABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_UPDATE_SALES_RETURN_FAILED,
  REQUEST_STATUS_UPDATE_SALES_RETURN_PENDING,
  REQUEST_STATUS_UPDATE_SALES_RETURN_SUCCESS,
  SLICE_NAME_IMPORT_SALES_INVOICE,
  SLICE_NAME_IMPORT_SALES_INVOICE_PROGRESS,
  SLICE_NAME_SALES_CREDIT_NOTE_DETAILS,
  SLICE_NAME_SALES_CREDIT_NOTE_HISTORY,
  SLICE_NAME_SALES_CREDIT_NOTE_LIST,
  SLICE_NAME_SALES_CREDIT_NOTE_PRINT_PDF_LIST,
  SLICE_NAME_SALES_DELIVERY_DATA,
  SLICE_NAME_SALES_DELIVERY_DETAILS,
  SLICE_NAME_SALES_DELIVERY_HISTORY_DETAILS,
  SLICE_NAME_SALES_DELIVERY_PRINT_PDF_DATA,
  SLICE_NAME_SALES_INVOICE_DATA,
  SLICE_NAME_SALES_INVOICE_PRINT_PDF_DATA,
  SLICE_NAME_SALES_ORDER_DATA,
  SLICE_NAME_SALES_ORDER_DETAILS,
  SLICE_NAME_SALES_ORDER_HISTORY_DETAILS,
  SLICE_NAME_SALES_ORDER_PRINT_PDF_DATA,
  SLICE_NAME_SALES_PAYABLE_PAYMENT_DETAILS,
  SLICE_NAME_SALES_PAYABLE_PAYMENT_HISTORY,
  SLICE_NAME_SALES_PAYABLE_PAYMENT_LIST,
  SLICE_NAME_SALES_PAYMENT_DATA,
  SLICE_NAME_SALES_PAYMENT_DETAIL,
  SLICE_NAME_SALES_PAYMENT_HISTORY,
  SLICE_NAME_SALES_PAYMENT_PRINT_PDF_DATA,
  SLICE_NAME_SALES_QUOTATION_DATA,
  SLICE_NAME_SALES_QUOTATION_PRINT_PDF_DATA,
  SLICE_NAME_SALES_RETURN_DETAILS,
  SLICE_NAME_SALES_RETURN_LIST,
  SLICE_NAME_TEMP_ADD_SALES_INVOICE,
  SLICE_NAME_TEMP_UPDATE_SALES_INVOICE,
  STATUS_REQUEST_ADD_SALES_DELIVERY_FAILED,
  STATUS_REQUEST_ADD_SALES_DELIVERY_PENDING,
  STATUS_REQUEST_ADD_SALES_DELIVERY_SUCCESS,
  STATUS_REQUEST_ADD_SALES_INVOICE_FAILED,
  STATUS_REQUEST_ADD_SALES_INVOICE_PENDING,
  STATUS_REQUEST_ADD_SALES_INVOICE_SUCCESS,
  STATUS_REQUEST_ADD_SALES_ORDER_FAILED,
  STATUS_REQUEST_ADD_SALES_ORDER_PENDING,
  STATUS_REQUEST_ADD_SALES_ORDER_SUCCESS,
  STATUS_REQUEST_ADD_SALES_PAYMENT_FAILED,
  STATUS_REQUEST_ADD_SALES_PAYMENT_PENDING,
  STATUS_REQUEST_ADD_SALES_PAYMENT_SUCCESS,
  STATUS_REQUEST_ADD_SALES_QUOTATION_FAILED,
  STATUS_REQUEST_ADD_SALES_QUOTATION_PENDING,
  STATUS_REQUEST_ADD_SALES_QUOTATION_SUCCESS,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_DELETE_SALES_DELIVERY_FAILED,
  STATUS_REQUEST_DELETE_SALES_DELIVERY_PENDING,
  STATUS_REQUEST_DELETE_SALES_DELIVERY_SUCCESS,
  STATUS_REQUEST_DELETE_SALES_INVOICE_FAILED,
  STATUS_REQUEST_DELETE_SALES_INVOICE_PENDING,
  STATUS_REQUEST_DELETE_SALES_INVOICE_SUCCESS,
  STATUS_REQUEST_DELETE_SALES_ORDER_FAILED,
  STATUS_REQUEST_DELETE_SALES_ORDER_PENDING,
  STATUS_REQUEST_DELETE_SALES_ORDER_SUCCESS,
  STATUS_REQUEST_DELETE_SALES_PAYMENT_FAILED,
  STATUS_REQUEST_DELETE_SALES_PAYMENT_PENDING,
  STATUS_REQUEST_DELETE_SALES_PAYMENT_SUCCESS,
  STATUS_REQUEST_DELETE_SALES_QUOTATION_FAILED,
  STATUS_REQUEST_DELETE_SALES_QUOTATION_PENDING,
  STATUS_REQUEST_DELETE_SALES_QUOTATION_SUCCESS,
  STATUS_REQUEST_DETAIL_HISTORY_SALES_DELIVERY_FAILED,
  STATUS_REQUEST_DETAIL_HISTORY_SALES_DELIVERY_PENDING,
  STATUS_REQUEST_DETAIL_HISTORY_SALES_DELIVERY_SUCCESS,
  STATUS_REQUEST_DETAIL_HISTORY_SALES_ORDER_FAILED,
  STATUS_REQUEST_DETAIL_HISTORY_SALES_ORDER_PENDING,
  STATUS_REQUEST_DETAIL_HISTORY_SALES_ORDER_SUCCESS,
  STATUS_REQUEST_DETAIL_SALES_DELIVERY_FAILED,
  STATUS_REQUEST_DETAIL_SALES_DELIVERY_PENDING,
  STATUS_REQUEST_DETAIL_SALES_DELIVERY_SUCCESS,
  STATUS_REQUEST_DETAIL_SALES_INVOICE_FAILED,
  STATUS_REQUEST_DETAIL_SALES_INVOICE_PENDING,
  STATUS_REQUEST_DETAIL_SALES_INVOICE_SUCCESS,
  STATUS_REQUEST_DETAIL_SALES_ORDER_FAILED,
  STATUS_REQUEST_DETAIL_SALES_ORDER_PENDING,
  STATUS_REQUEST_DETAIL_SALES_ORDER_SUCCESS,
  STATUS_REQUEST_DETAIL_SALES_PAYMENT_FAILED,
  STATUS_REQUEST_DETAIL_SALES_PAYMENT_PENDING,
  STATUS_REQUEST_DETAIL_SALES_PAYMENT_SUCCESS,
  STATUS_REQUEST_DETAIL_SALES_QUOTATION_FAILED,
  STATUS_REQUEST_DETAIL_SALES_QUOTATION_PENDING,
  STATUS_REQUEST_DETAIL_SALES_QUOTATION_SUCCESS,
  STATUS_REQUEST_DETAILS_SALES_CREDIT_NOTE_FAILED,
  STATUS_REQUEST_DETAILS_SALES_CREDIT_NOTE_PENDING,
  STATUS_REQUEST_DETAILS_SALES_CREDIT_NOTE_SUCCESS,
  STATUS_REQUEST_HISTORY_SALES_CREDIT_NOTE_FAILED,
  STATUS_REQUEST_HISTORY_SALES_CREDIT_NOTE_PENDING,
  STATUS_REQUEST_HISTORY_SALES_CREDIT_NOTE_SUCCESS,
  STATUS_REQUEST_HISTORY_SALES_INVOICE_FAILED,
  STATUS_REQUEST_HISTORY_SALES_INVOICE_PENDING,
  STATUS_REQUEST_HISTORY_SALES_INVOICE_SUCCESS,
  STATUS_REQUEST_HISTORY_SALES_PAYMENT_FAILED,
  STATUS_REQUEST_HISTORY_SALES_PAYMENT_PENDING,
  STATUS_REQUEST_HISTORY_SALES_PAYMENT_SUCCESS,
  STATUS_REQUEST_HISTORY_SALES_QUOTATION_FAILED,
  STATUS_REQUEST_HISTORY_SALES_QUOTATION_PENDING,
  STATUS_REQUEST_HISTORY_SALES_QUOTATION_SUCCESS,
  STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_FAILED,
  STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_PENDING,
  STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_SUCCESS,
  STATUS_REQUEST_LIST_SALES_DELIVERY_AND_ORDER_FAILED,
  STATUS_REQUEST_LIST_SALES_DELIVERY_AND_ORDER_PENDING,
  STATUS_REQUEST_LIST_SALES_DELIVERY_AND_ORDER_SUCCESS,
  STATUS_REQUEST_LIST_SALES_DELIVERY_FAILED,
  STATUS_REQUEST_LIST_SALES_DELIVERY_PENDING,
  STATUS_REQUEST_LIST_SALES_DELIVERY_SUCCESS,
  STATUS_REQUEST_LIST_SALES_INVOICE_FAILED,
  STATUS_REQUEST_LIST_SALES_INVOICE_PENDING,
  STATUS_REQUEST_LIST_SALES_INVOICE_SUCCESS,
  STATUS_REQUEST_LIST_SALES_ORDER_FAILED,
  STATUS_REQUEST_LIST_SALES_ORDER_PENDING,
  STATUS_REQUEST_LIST_SALES_ORDER_SUCCESS,
  STATUS_REQUEST_LIST_SALES_PAYMENT_FAILED,
  STATUS_REQUEST_LIST_SALES_PAYMENT_PENDING,
  STATUS_REQUEST_LIST_SALES_PAYMENT_SUCCESS,
  STATUS_REQUEST_LIST_SALES_QUOTATION_FAILED,
  STATUS_REQUEST_LIST_SALES_QUOTATION_PENDING,
  STATUS_REQUEST_LIST_SALES_QUOTATION_SUCCESS,
  STATUS_REQUEST_UPDATE_SALES_DELIVERY_FAILED,
  STATUS_REQUEST_UPDATE_SALES_DELIVERY_PENDING,
  STATUS_REQUEST_UPDATE_SALES_DELIVERY_SUCCESS,
  STATUS_REQUEST_UPDATE_SALES_INVOICE_FAILED,
  STATUS_REQUEST_UPDATE_SALES_INVOICE_PENDING,
  STATUS_REQUEST_UPDATE_SALES_INVOICE_SUCCESS,
  STATUS_REQUEST_UPDATE_SALES_ORDER_FAILED,
  STATUS_REQUEST_UPDATE_SALES_ORDER_PENDING,
  STATUS_REQUEST_UPDATE_SALES_ORDER_SUCCESS,
  STATUS_REQUEST_UPDATE_SALES_PAYMENT_FAILED,
  STATUS_REQUEST_UPDATE_SALES_PAYMENT_PENDING,
  STATUS_REQUEST_UPDATE_SALES_PAYMENT_SUCCESS,
  STATUS_REQUEST_UPDATE_SALES_QUOTATION_FAILED,
  STATUS_REQUEST_UPDATE_SALES_QUOTATION_PENDING,
  STATUS_REQUEST_UPDATE_SALES_QUOTATION_SUCCESS,
} from '../../utils/constants/store.constant';
import { salesAsyncThunkTypeWithActionProps } from '../../utils/data/mapping/async-thunk-type-with-action-props.mapping';
import {
  DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING,
  DEFAULT_KEY_NAME_STATUS_REQUEST,
} from '../../utils/default/object-keyname.default';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import {
  addSalesDelivery,
  addSalesInvoice,
  addSalesInvoiceV2,
  addSalesInvoiceWithStoreData,
  addSalesOrder,
  addSalesPayment,
  addSalesQuotation,
  createSalesPayablePayment,
  createSalesReturn,
  deleteSalesDelivery,
  deleteSalesInvoice,
  deleteSalesOrder,
  deleteSalesPayablePayment,
  deleteSalesPayment,
  deleteSalesQuotation,
  deleteSalesReturn,
  getDetailSalesInvoice,
  getDetailSalesOrder,
  getDetailSalesPayment,
  getDetailSalesQuotation,
  getHistorySalesInvoice,
  getHistorySalesPayment,
  getHistorySalesQuotation,
  getListSalesDelivery,
  getListSalesDeliveryAndOrder,
  getListSalesDeliveryRefresh,
  getListSalesInvoice,
  getListSalesInvoiceRefresh,
  getListSalesOrder,
  getListSalesOrderRefresh,
  getListSalesPayment,
  getListSalesPaymentRefresh,
  getListSalesQuotation,
  getListSalesQuotationRefresh,
  getSalesCreditNoteDetails,
  getSalesCreditNoteHistory,
  getSalesCreditNoteList,
  getSalesCreditNoteRefreshList,
  getSalesDeliveryDetails,
  getSalesDeliveryHistoryDetails,
  getSalesOrderHistoryDetails,
  getSalesPayablePaymentDetails,
  getSalesPayablePaymentHistory,
  getSalesPayablePaymentList,
  getSalesPayablePaymentRefreshList,
  getSalesReturnDetails,
  getSalesReturnHistory,
  getSalesReturnList,
  getSalesReturnRefreshList,
  updateSalesDelivery,
  updateSalesInvoice,
  updateSalesInvoiceWithStoreData,
  updateSalesOrder,
  updateSalesPayablePayment,
  updateSalesPayment,
  updateSalesQuotation,
  updateSalesReturn,
} from './sales.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  // sales quotation
  salesQuotationData: LocalStorage.get(LOCAL_STORAGE_SALES_QUOTATION),
  salesQuotationDetail: LocalStorage.get(LOCAL_STORAGE_SALES_QUOTATION_DETAIL),
  salesQuotationHistory: null,
  [SLICE_NAME_SALES_QUOTATION_PRINT_PDF_DATA]: LocalStorage.get(
    LOCAL_STORAGE_SALES_QUOTATION_PRINT_PDF,
  ),
  // sales payment
  salesPaymentData: LocalStorage.get(LOCAL_STORAGE_SALES_PAYMENT),
  [SLICE_NAME_SALES_PAYMENT_DETAIL]: null,
  salesPaymentHistory: null,
  [SLICE_NAME_SALES_PAYMENT_PRINT_PDF_DATA]: LocalStorage.get(
    LOCAL_STORAGE_SALES_PAYMENT_PRINT_PDF,
  ),
  [SLICE_NAME_SALES_PAYMENT_HISTORY]: null,
  // sales invoice
  [SLICE_NAME_SALES_INVOICE_DATA]: LocalStorage.get(LOCAL_STORAGE_SALES_INVOICE),
  salesInvoiceDetail: null,
  salesInvoiceHistory: null,
  [SLICE_NAME_TEMP_ADD_SALES_INVOICE]: null,
  [SLICE_NAME_TEMP_UPDATE_SALES_INVOICE]: null,
  [SLICE_NAME_SALES_INVOICE_PRINT_PDF_DATA]: LocalStorage.get(
    LOCAL_STORAGE_SALES_INVOICE_PRINT_PDF,
  ),
  [SLICE_NAME_IMPORT_SALES_INVOICE]: null,
  [SLICE_NAME_IMPORT_SALES_INVOICE_PROGRESS]: null,
  // sales order
  salesOrderData: LocalStorage.get(LOCAL_STORAGE_SALES_ORDER),
  [SLICE_NAME_SALES_ORDER_DETAILS]: null,
  [SLICE_NAME_SALES_ORDER_HISTORY_DETAILS]: null,
  [SLICE_NAME_SALES_ORDER_PRINT_PDF_DATA]: LocalStorage.get(LOCAL_STORAGE_SALES_ORDER_PRINT_PDF),
  // sales delivery
  salesDeliveryAndOrderData: LocalStorage.get(LOCAL_STORAGE_SALES_DELIVERY_AND_ORDER),
  [SLICE_NAME_SALES_DELIVERY_DATA]: LocalStorage.get(LOCAL_STORAGE_SALES_DELIVERY),
  salesDeliveryData: LocalStorage.get(LOCAL_STORAGE_SALES_DELIVERY),
  [SLICE_NAME_SALES_DELIVERY_DETAILS]: null,
  [SLICE_NAME_SALES_DELIVERY_HISTORY_DETAILS]: null,
  [SLICE_NAME_SALES_DELIVERY_PRINT_PDF_DATA]: LocalStorage.get(
    LOCAL_STORAGE_SALES_DELIVERY_PRINT_PDF,
  ),
  // sales payable payment
  [SLICE_NAME_SALES_PAYABLE_PAYMENT_LIST]: LocalStorage.get(
    LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_LIST,
  ),
  [SLICE_NAME_SALES_PAYABLE_PAYMENT_DETAILS]: LocalStorage.get(
    LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_DETAILS,
  ),
  [SLICE_NAME_SALES_PAYMENT_PRINT_PDF_DATA]: LocalStorage.get(
    LOCAL_STORAGE_SALES_PAYMENT_PRINT_PDF,
  ),
  [SLICE_NAME_SALES_PAYABLE_PAYMENT_HISTORY]: null,
  // sales credit note
  [SLICE_NAME_SALES_CREDIT_NOTE_LIST]: LocalStorage.get(LOCAL_STORAGE_SALES_CREDIT_NOTE_LIST),
  [SLICE_NAME_SALES_CREDIT_NOTE_DETAILS]: LocalStorage.get(LOCAL_STORAGE_SALES_CREDIT_NOTE_DETAILS),
  [SLICE_NAME_SALES_CREDIT_NOTE_PRINT_PDF_LIST]: LocalStorage.get(
    LOCAL_STORAGE_SALES_CREDIT_NOTE_LIST_PRINT_PDF,
  ),
  [SLICE_NAME_SALES_CREDIT_NOTE_HISTORY]: null,
};

const salesSlice = createSlice({
  name: NAME_STORE.SALES,
  initialState,
  reducers: {
    getListSalesQuotation,
    getListSalesQuotationRefresh,
    getDetailSalesQuotation,
    getHistorySalesQuotation,
    addSalesQuotation,
    updateSalesQuotation,
    deleteSalesQuotation,
    getListSalesOrder,
    getListSalesOrderRefresh,
    getDetailSalesOrder,
    getSalesOrderHistoryDetails,
    addSalesOrder,
    updateSalesOrder,
    deleteSalesOrder,
    getListSalesInvoice,
    getListSalesInvoiceRefresh,
    getDetailSalesInvoice,
    getListSalesPayment,
    getListSalesPaymentRefresh,
    addSalesInvoice,
    updateSalesInvoice,
    getListSalesDeliveryAndOrder,
    getListSalesDelivery,
    getSalesDeliveryDetails,
    getSalesDeliveryHistoryDetails,
    addSalesDelivery,
    updateSalesDelivery,
    deleteSalesDelivery,
    getDetailSalesPayment,
    getHistorySalesPayment,
    addSalesPayment,
    updateSalesPayment,
    deleteSalesInvoice,
    deleteSalesPayment,
    getHistorySalesInvoice,
    clearSalesQuotationData: (state) => {
      // LocalStorage.remove( LOCAL_STORAGE_SALES_QUOTATION )

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        salesQuotationData: null,
      };
    },
    clearSalesInvoiceData: (state) => {
      // LocalStorage.remove( LOCAL_STORAGE_SALES_INVOICE )

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        salesInvoiceData: null,
      };
    },
    // clearing spesific on slice name of sales
    clearSalesDataSlice: (state, action) => {
      const { sliceName } = action.payload;

      if (LocalStorage.get(sliceName)) {
        LocalStorage.remove(sliceName);
      }

      return {
        ...state,
        [sliceName]: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    clearSalesPaymentData: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_SALES_PAYMENT);

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        salesPaymentData: null,
      };
    },
    clearSalesDeliveryData: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_SALES_DELIVERY);

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        salesDelivryData: null,
      };
    },
    clearSalesDeliveryAndOrderData: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_SALES_DELIVERY_AND_ORDER);

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        salesDeliveryAndOrderData: null,
      };
    },
    clearDataSalesSlice: (state, action) => {
      const { sliceName, localStorageName } = action.payload;

      if (LocalStorage.get(sliceName)) {
        LocalStorage.remove(sliceName);
      } else if (localStorageName) {
        if (LocalStorage.get(localStorageName)) LocalStorage.remove(localStorageName);
      }

      return {
        ...state,
        [sliceName]: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(
      initialState,
      {
        salesQuotationData: LOCAL_STORAGE_SALES_QUOTATION,
        salesQuotationDetail: LOCAL_STORAGE_SALES_QUOTATION_DETAIL,
        [SLICE_NAME_SALES_QUOTATION_PRINT_PDF_DATA]: LOCAL_STORAGE_SALES_QUOTATION_PRINT_PDF,
        salesPaymentData: LOCAL_STORAGE_SALES_PAYMENT,
        [SLICE_NAME_SALES_PAYMENT_PRINT_PDF_DATA]: LOCAL_STORAGE_SALES_PAYMENT_PRINT_PDF,
        [SLICE_NAME_SALES_INVOICE_DATA]: LOCAL_STORAGE_SALES_INVOICE,
        [SLICE_NAME_SALES_INVOICE_PRINT_PDF_DATA]: LOCAL_STORAGE_SALES_INVOICE_PRINT_PDF,
        salesOrderData: LOCAL_STORAGE_SALES_ORDER,
        [SLICE_NAME_SALES_ORDER_PRINT_PDF_DATA]: LOCAL_STORAGE_SALES_ORDER_PRINT_PDF,
        salesDeliveryAndOrderData: LOCAL_STORAGE_SALES_DELIVERY_AND_ORDER,
        [SLICE_NAME_SALES_DELIVERY_DATA]: LOCAL_STORAGE_SALES_DELIVERY,
        salesDeliveryData: LOCAL_STORAGE_SALES_DELIVERY,
        [SLICE_NAME_SALES_DELIVERY_PRINT_PDF_DATA]: LOCAL_STORAGE_SALES_DELIVERY_PRINT_PDF,
        [SLICE_NAME_SALES_PAYABLE_PAYMENT_LIST]: LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_LIST,
        [SLICE_NAME_SALES_PAYABLE_PAYMENT_DETAILS]: LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_DETAILS,
        [SLICE_NAME_SALES_PAYMENT_PRINT_PDF_DATA]: LOCAL_STORAGE_SALES_PAYMENT_PRINT_PDF,
        [SLICE_NAME_SALES_CREDIT_NOTE_LIST]: LOCAL_STORAGE_SALES_CREDIT_NOTE_LIST,
        [SLICE_NAME_SALES_CREDIT_NOTE_DETAILS]: LOCAL_STORAGE_SALES_CREDIT_NOTE_DETAILS,
        [SLICE_NAME_SALES_CREDIT_NOTE_PRINT_PDF_LIST]:
          LOCAL_STORAGE_SALES_CREDIT_NOTE_LIST_PRINT_PDF,
      },
      DEFAULT_KEY_NAME_STATUS_REQUEST,
    ),
  },
  extraReducers: (builder) => {
    // sales quotation list
    builder.addCase(getListSalesQuotation.fulfilled, (state, action) => {
      const { sliceName, requestStatusSuccessName } = action.payload;

      state[sliceName] = action.payload[sliceName];
      state.statusRequest = action.payload[requestStatusSuccessName];
    }),
      builder.addCase(getListSalesQuotation.pending, (state, action) => {
        state.statusRequest =
          action.meta && action.meta.arg && action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            ? action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            : STATUS_REQUEST_LIST_SALES_QUOTATION_PENDING;
      }),
      builder.addCase(getListSalesQuotation.rejected, (state, action) => {
        const {
          sliceName = SLICE_NAME_SALES_QUOTATION_DATA,
          requestStatusFailedName,
          searchParams,
        } = action.payload || {};

        state[sliceName] = searchParams;
        state.statusRequest = action.payload[requestStatusFailedName];
      }),
      // sales quotation refresh
      builder.addCase(getListSalesQuotationRefresh.fulfilled, (state, action) => {
        state.salesQuotationData = action.payload.salesQuotationData;
        state.statusRequest = STATUS_REQUEST_LIST_SALES_QUOTATION_SUCCESS;
      }),
      builder.addCase(getListSalesQuotationRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_SALES_QUOTATION_PENDING;
      }),
      builder.addCase(getListSalesQuotationRefresh.rejected, (state) => {
        state.salesQuotationData = null;
        state.statusRequest = STATUS_REQUEST_LIST_SALES_QUOTATION_FAILED;
      }),
      // sales quotation details
      builder.addCase(getDetailSalesQuotation.fulfilled, (state, action) => {
        if (!isEmpty(action.payload)) {
          state.salesQuotationDetail = action.payload.salesQuotationDetail;
        }

        state.statusRequest = STATUS_REQUEST_DETAIL_SALES_QUOTATION_SUCCESS;
      }),
      builder.addCase(getDetailSalesQuotation.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_SALES_QUOTATION_PENDING;
      }),
      builder.addCase(getDetailSalesQuotation.rejected, (state) => {
        state.salesQuotationDetail = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_SALES_QUOTATION_FAILED;
      }),
      // sales quotation history
      builder.addCase(getHistorySalesQuotation.fulfilled, (state, action) => {
        const { sliceName } = action.payload;

        if (!isEmpty(sliceName)) {
          state[sliceName] = action.payload[sliceName];
        }

        state.statusRequest = STATUS_REQUEST_HISTORY_SALES_QUOTATION_SUCCESS;
      }),
      builder.addCase(getHistorySalesQuotation.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_HISTORY_SALES_QUOTATION_PENDING;
      }),
      builder.addCase(getHistorySalesQuotation.rejected, (state) => {
        state.salesQuotationHistory = null;
        state.statusRequest = STATUS_REQUEST_HISTORY_SALES_QUOTATION_FAILED;
      }),
      builder.addCase(addSalesQuotation.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SALES_QUOTATION_SUCCESS;
      }),
      builder.addCase(addSalesQuotation.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SALES_QUOTATION_PENDING;
      }),
      builder.addCase(addSalesQuotation.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SALES_QUOTATION_FAILED;
      }),
      builder.addCase(updateSalesQuotation.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SALES_QUOTATION_SUCCESS;
      }),
      builder.addCase(updateSalesQuotation.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SALES_QUOTATION_PENDING;
      }),
      builder.addCase(updateSalesQuotation.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SALES_QUOTATION_FAILED;
      }),
      builder.addCase(deleteSalesQuotation.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_SALES_QUOTATION_SUCCESS;
      }),
      builder.addCase(deleteSalesQuotation.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_SALES_QUOTATION_PENDING;
      }),
      builder.addCase(deleteSalesQuotation.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_SALES_QUOTATION_FAILED;
      }),
      // get list of sales order
      builder.addCase(getListSalesOrder.fulfilled, (state, action) => {
        const { sliceName, requestStatusSuccessName } = action.payload;

        state[sliceName] = action.payload[sliceName];
        state.statusRequest = action.payload[requestStatusSuccessName];
      }),
      builder.addCase(getListSalesOrder.pending, (state, action) => {
        state.statusRequest =
          action.meta && action.meta.arg && action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            ? action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            : STATUS_REQUEST_LIST_SALES_ORDER_PENDING;
      }),
      builder.addCase(getListSalesOrder.rejected, (state, action) => {
        const {
          sliceName = SLICE_NAME_SALES_ORDER_DATA,
          requestStatusFailedName,
          searchParams,
        } = action.payload || {};

        state[sliceName] = searchParams;
        state.statusRequest = action.payload[requestStatusFailedName];
      }),
      // get list of sales order when refresh
      builder.addCase(getListSalesOrderRefresh.fulfilled, (state, action) => {
        state[SLICE_NAME_SALES_ORDER_DATA] = action.payload[SLICE_NAME_SALES_ORDER_DATA];
        state.statusRequest = STATUS_REQUEST_LIST_SALES_ORDER_SUCCESS;
      }),
      builder.addCase(getListSalesOrderRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_SALES_ORDER_PENDING;
      }),
      builder.addCase(getListSalesOrderRefresh.rejected, (state) => {
        state[SLICE_NAME_SALES_ORDER_DATA] = null;
        state.statusRequest = STATUS_REQUEST_LIST_SALES_ORDER_FAILED;
      }),
      // get detail sales order
      builder.addCase(getDetailSalesOrder.fulfilled, (state, action) => {
        state[SLICE_NAME_SALES_ORDER_DETAILS] = action.payload[SLICE_NAME_SALES_ORDER_DETAILS];
        state.statusRequest = STATUS_REQUEST_DETAIL_SALES_ORDER_SUCCESS;
      }),
      builder.addCase(getDetailSalesOrder.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_SALES_ORDER_PENDING;
      }),
      builder.addCase(getDetailSalesOrder.rejected, (state) => {
        state[SLICE_NAME_SALES_ORDER_DETAILS] = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_SALES_ORDER_FAILED;
      }),
      // sales delivery history details
      builder.addCase(getSalesOrderHistoryDetails.fulfilled, (state, action) => {
        const { sliceName } = action.payload;

        if (!isEmpty(sliceName)) {
          state[sliceName] = action.payload[sliceName];
        }

        state.statusRequest = STATUS_REQUEST_DETAIL_HISTORY_SALES_ORDER_SUCCESS;
      }),
      builder.addCase(getSalesOrderHistoryDetails.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_HISTORY_SALES_ORDER_PENDING;
      }),
      builder.addCase(getSalesOrderHistoryDetails.rejected, (state) => {
        state[SLICE_NAME_SALES_ORDER_HISTORY_DETAILS] = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_HISTORY_SALES_ORDER_FAILED;
      }),
      // add sales order
      builder.addCase(addSalesOrder.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SALES_ORDER_SUCCESS;
      }),
      builder.addCase(addSalesOrder.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SALES_ORDER_PENDING;
      }),
      builder.addCase(addSalesOrder.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SALES_ORDER_FAILED;
      }),
      // update sales order
      builder.addCase(updateSalesOrder.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SALES_ORDER_SUCCESS;
      }),
      builder.addCase(updateSalesOrder.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SALES_ORDER_PENDING;
      }),
      builder.addCase(updateSalesOrder.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SALES_ORDER_FAILED;
      }),
      // delete sales order
      builder.addCase(deleteSalesOrder.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_SALES_ORDER_SUCCESS;
      }),
      builder.addCase(deleteSalesOrder.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_SALES_ORDER_PENDING;
      }),
      builder.addCase(deleteSalesOrder.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_SALES_ORDER_FAILED;
      }),
      // list sales delivery
      builder.addCase(getListSalesDelivery.fulfilled, (state, action) => {
        const { sliceName, requestStatusSuccessName } = action.payload;

        state[sliceName] = action.payload[sliceName];
        state.statusRequest = action.payload[requestStatusSuccessName];
      }),
      builder.addCase(getListSalesDelivery.pending, (state, action) => {
        state.statusRequest =
          action.meta && action.meta.arg && action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            ? action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            : STATUS_REQUEST_LIST_SALES_DELIVERY_PENDING;
      }),
      builder.addCase(getListSalesDelivery.rejected, (state, action) => {
        const {
          sliceName = SLICE_NAME_SALES_DELIVERY_DATA,
          requestStatusFailedName,
          searchParams,
        } = action.payload || {};

        state[sliceName] = searchParams;
        state.statusRequest = action.payload[requestStatusFailedName];
      }),
      // list refresh sales delivery
      builder.addCase(getListSalesDeliveryRefresh.fulfilled, (state, action) => {
        state[SLICE_NAME_SALES_DELIVERY_DATA] = action.payload[SLICE_NAME_SALES_DELIVERY_DATA];
        state.statusRequest = STATUS_REQUEST_LIST_SALES_DELIVERY_SUCCESS;
      }),
      builder.addCase(getListSalesDeliveryRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_SALES_DELIVERY_PENDING;
      }),
      builder.addCase(getListSalesDeliveryRefresh.rejected, (state) => {
        state[SLICE_NAME_SALES_DELIVERY_DATA] = null;
        state.statusRequest = STATUS_REQUEST_LIST_SALES_DELIVERY_FAILED;
      }),
      // sales delivery details
      builder.addCase(getSalesDeliveryDetails.fulfilled, (state, action) => {
        state[SLICE_NAME_SALES_DELIVERY_DETAILS] =
          action.payload[SLICE_NAME_SALES_DELIVERY_DETAILS];
        state.statusRequest = STATUS_REQUEST_DETAIL_SALES_DELIVERY_SUCCESS;
      }),
      builder.addCase(getSalesDeliveryDetails.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_SALES_DELIVERY_PENDING;
      }),
      builder.addCase(getSalesDeliveryDetails.rejected, (state) => {
        state[SLICE_NAME_SALES_DELIVERY_DETAILS] = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_SALES_DELIVERY_FAILED;
      }),
      // sales delivery history details
      builder.addCase(getSalesDeliveryHistoryDetails.fulfilled, (state, action) => {
        const { sliceName } = action.payload;

        if (!isEmpty(sliceName)) {
          state[sliceName] = action.payload[sliceName];
        }

        state.statusRequest = STATUS_REQUEST_DETAIL_HISTORY_SALES_DELIVERY_SUCCESS;
      }),
      // add sales delivery
      builder.addCase(addSalesDelivery.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SALES_DELIVERY_SUCCESS;
      }),
      builder.addCase(addSalesDelivery.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SALES_DELIVERY_PENDING;
      }),
      builder.addCase(addSalesDelivery.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SALES_DELIVERY_FAILED;
      }),
      // update sales delivery
      builder.addCase(updateSalesDelivery.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SALES_DELIVERY_SUCCESS;
      }),
      builder.addCase(updateSalesDelivery.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SALES_DELIVERY_PENDING;
      }),
      builder.addCase(updateSalesDelivery.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SALES_DELIVERY_FAILED;
      }),
      // delete sales delivery
      builder.addCase(deleteSalesDelivery.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_SALES_DELIVERY_SUCCESS;
      }),
      builder.addCase(deleteSalesDelivery.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_SALES_DELIVERY_PENDING;
      }),
      builder.addCase(deleteSalesDelivery.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_SALES_DELIVERY_FAILED;
      }),
      builder.addCase(getSalesDeliveryHistoryDetails.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_HISTORY_SALES_DELIVERY_PENDING;
      }),
      builder.addCase(getSalesDeliveryHistoryDetails.rejected, (state) => {
        state[SLICE_NAME_SALES_DELIVERY_HISTORY_DETAILS] = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_HISTORY_SALES_DELIVERY_FAILED;
      }),
      // sales invoice list
      builder.addCase(getListSalesInvoice.fulfilled, (state, action) => {
        const { sliceName, requestStatusSuccessName } = action.payload;

        state[sliceName] = action.payload[sliceName];
        state.statusRequest = action.payload[requestStatusSuccessName];
      }),
      builder.addCase(getListSalesInvoice.pending, (state, action) => {
        state.statusRequest =
          action.meta && action.meta.arg && action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            ? action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            : STATUS_REQUEST_LIST_SALES_INVOICE_PENDING;
      }),
      builder.addCase(getListSalesInvoice.rejected, (state, action) => {
        const {
          sliceName = SLICE_NAME_SALES_INVOICE_DATA,
          requestStatusFailedName,
          searchParams,
        } = action.payload || {};

        state[sliceName] = searchParams;
        state.statusRequest = action.payload[requestStatusFailedName];
      }),
      builder.addCase(getListSalesInvoiceRefresh.fulfilled, (state, action) => {
        state.salesInvoiceData = action.payload.salesInvoiceData;
        state.statusRequest = STATUS_REQUEST_LIST_SALES_INVOICE_SUCCESS;
      }),
      builder.addCase(getListSalesInvoiceRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_SALES_INVOICE_PENDING;
      }),
      builder.addCase(getListSalesInvoiceRefresh.rejected, (state) => {
        state.salesInvoiceData = null;
        state.statusRequest = STATUS_REQUEST_LIST_SALES_INVOICE_FAILED;
      }),
      builder.addCase(getDetailSalesInvoice.fulfilled, (state, action) => {
        if (!isEmpty(action.payload)) {
          state.salesInvoiceDetail = action.payload.salesInvoiceDetail;
        }

        state.statusRequest = STATUS_REQUEST_DETAIL_SALES_INVOICE_SUCCESS;
      }),
      builder.addCase(getDetailSalesInvoice.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_SALES_INVOICE_PENDING;
      }),
      builder.addCase(getDetailSalesInvoice.rejected, (state) => {
        state.salesInvoiceDetail = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_SALES_INVOICE_FAILED;
      }),
      // sales payment list
      builder.addCase(getListSalesPayment.fulfilled, (state, action) => {
        const { sliceName, requestStatusSuccessName } = action.payload;

        state[sliceName] = action.payload[sliceName];
        state.statusRequest = action.payload[requestStatusSuccessName];
      }),
      builder.addCase(getListSalesPayment.pending, (state, action) => {
        state.statusRequest =
          action.meta && action.meta.arg && action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            ? action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            : STATUS_REQUEST_LIST_SALES_PAYMENT_PENDING;
      }),
      builder.addCase(getListSalesPayment.rejected, (state, action) => {
        const {
          sliceName = SLICE_NAME_SALES_PAYMENT_DATA,
          requestStatusFailedName,
          searchParams,
        } = action.payload || {};

        state[sliceName] = searchParams;
        state.statusRequest = action.payload[requestStatusFailedName];
      }),
      builder.addCase(getListSalesPaymentRefresh.fulfilled, (state, action) => {
        state.salesPaymentData = action.payload.salesPaymentData;
        state.statusRequest = STATUS_REQUEST_LIST_SALES_PAYMENT_SUCCESS;
      }),
      builder.addCase(getListSalesPaymentRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_SALES_PAYMENT_PENDING;
      }),
      builder.addCase(getListSalesPaymentRefresh.rejected, (state) => {
        state.salesPaymentData = null;
        state.statusRequest = STATUS_REQUEST_LIST_SALES_PAYMENT_FAILED;
      }),
      builder.addCase(getDetailSalesPayment.fulfilled, (state, action) => {
        state['salesPaymentDetail'] = action.payload['salesPaymentDetail'];
        state.statusRequest = STATUS_REQUEST_DETAIL_SALES_PAYMENT_SUCCESS;
      }),
      builder.addCase(getDetailSalesPayment.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_SALES_PAYMENT_PENDING;
      }),
      builder.addCase(getDetailSalesPayment.rejected, (state) => {
        state['salesPaymentDetail'] = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_SALES_PAYMENT_FAILED;
      }),
      builder.addCase(getHistorySalesPayment.fulfilled, (state, action) => {
        const { sliceName } = action.payload;

        if (!isEmpty(sliceName)) {
          state[sliceName] = action.payload[sliceName];
        }

        state.statusRequest = STATUS_REQUEST_HISTORY_SALES_PAYMENT_SUCCESS;
      }),
      builder.addCase(getHistorySalesPayment.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_HISTORY_SALES_PAYMENT_PENDING;
      }),
      builder.addCase(getHistorySalesPayment.rejected, (state) => {
        state.salesPaymentHistory = null;
        state.statusRequest = STATUS_REQUEST_HISTORY_SALES_PAYMENT_FAILED;
      }),
      builder.addCase(addSalesPayment.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SALES_PAYMENT_SUCCESS;
      }),
      builder.addCase(addSalesPayment.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SALES_PAYMENT_PENDING;
      }),
      builder.addCase(addSalesPayment.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SALES_PAYMENT_FAILED;
      }),
      builder.addCase(addSalesInvoice.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SALES_INVOICE_SUCCESS;
      }),
      builder.addCase(addSalesInvoice.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SALES_INVOICE_PENDING;
      }),
      builder.addCase(addSalesInvoice.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SALES_INVOICE_FAILED;
      }),
      builder.addCase(addSalesInvoiceV2.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SALES_INVOICE_SUCCESS;
      }),
      builder.addCase(addSalesInvoiceV2.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SALES_INVOICE_PENDING;
      }),
      builder.addCase(addSalesInvoiceV2.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SALES_INVOICE_FAILED;
      }),
      // on add sales invoice and storing data on slice
      builder.addCase(addSalesInvoiceWithStoreData.fulfilled, (state, action) => {
        state[SLICE_NAME_TEMP_ADD_SALES_INVOICE] =
          action.payload[SLICE_NAME_TEMP_ADD_SALES_INVOICE];
        state.statusRequest = STATUS_REQUEST_ADD_SALES_INVOICE_SUCCESS;
      }),
      builder.addCase(addSalesInvoiceWithStoreData.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SALES_INVOICE_PENDING;
      }),
      builder.addCase(addSalesInvoiceWithStoreData.rejected, (state) => {
        state[SLICE_NAME_TEMP_ADD_SALES_INVOICE] = null;
        state.statusRequest = STATUS_REQUEST_ADD_SALES_INVOICE_FAILED;
      }),
      builder.addCase(updateSalesInvoice.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SALES_INVOICE_SUCCESS;
      }),
      builder.addCase(updateSalesInvoice.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SALES_INVOICE_PENDING;
      }),
      builder.addCase(updateSalesInvoice.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SALES_INVOICE_FAILED;
      }),
      // update sales invoice where store data response
      builder.addCase(updateSalesInvoiceWithStoreData.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SALES_INVOICE_SUCCESS;
        state[SLICE_NAME_TEMP_UPDATE_SALES_INVOICE] =
          action.payload[SLICE_NAME_TEMP_UPDATE_SALES_INVOICE];
      }),
      builder.addCase(updateSalesInvoiceWithStoreData.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SALES_INVOICE_PENDING;
      }),
      builder.addCase(updateSalesInvoiceWithStoreData.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SALES_INVOICE_FAILED;
        state[SLICE_NAME_TEMP_UPDATE_SALES_INVOICE] = null;
      }),
      builder.addCase(deleteSalesInvoice.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_SALES_INVOICE_SUCCESS;
      }),
      builder.addCase(deleteSalesInvoice.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_SALES_INVOICE_PENDING;
      }),
      builder.addCase(deleteSalesInvoice.rejected, (state) => {
        state.salesInvoiceData = null;
        state.statusRequest = STATUS_REQUEST_DELETE_SALES_INVOICE_FAILED;
      }),
      builder.addCase(getListSalesDeliveryAndOrder.fulfilled, (state, action) => {
        state.salesDeliveryAndOrderData = action.payload.salesDeliveryAndOrderData;
        state.statusRequest = STATUS_REQUEST_LIST_SALES_DELIVERY_AND_ORDER_SUCCESS;
      }),
      builder.addCase(getListSalesDeliveryAndOrder.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_SALES_DELIVERY_AND_ORDER_PENDING;
      }),
      builder.addCase(getListSalesDeliveryAndOrder.rejected, (state) => {
        state.salesDeliveryAndOrderData = null;
        state.statusRequest = STATUS_REQUEST_LIST_SALES_DELIVERY_AND_ORDER_FAILED;
      }),
      builder.addCase(updateSalesPayment.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SALES_PAYMENT_SUCCESS;
      }),
      builder.addCase(updateSalesPayment.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SALES_PAYMENT_PENDING;
      }),
      builder.addCase(updateSalesPayment.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SALES_PAYMENT_FAILED;
      }),
      builder.addCase(deleteSalesPayment.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_SALES_PAYMENT_SUCCESS;
      }),
      builder.addCase(deleteSalesPayment.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_SALES_PAYMENT_PENDING;
      }),
      builder.addCase(deleteSalesPayment.rejected, (state) => {
        state.salesPaymentData = null;
        state.statusRequest = STATUS_REQUEST_DELETE_SALES_PAYMENT_FAILED;
      }),
      // sales invoice history
      builder.addCase(getHistorySalesInvoice.fulfilled, (state, action) => {
        const { sliceName } = action.payload;

        if (!isEmpty(sliceName)) {
          state[sliceName] = action.payload[sliceName];
        }

        state.statusRequest = STATUS_REQUEST_HISTORY_SALES_INVOICE_SUCCESS;
      }),
      builder.addCase(getHistorySalesInvoice.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_HISTORY_SALES_INVOICE_PENDING;
      }),
      builder.addCase(getHistorySalesInvoice.rejected, (state) => {
        state.salesInvoiceHistory = null;
        state.statusRequest = STATUS_REQUEST_HISTORY_SALES_INVOICE_FAILED;
      }),
      // sales return list
      builder.addCase(getSalesReturnList.fulfilled, (state, action) => {
        const { sliceName, requestStatusSuccessName } = action.payload;

        state[sliceName] = action.payload[sliceName];
        state.statusRequest = action.payload[requestStatusSuccessName];
      }),
      builder.addCase(getSalesReturnList.pending, (state, action) => {
        state.statusRequest =
          action.meta && action.meta.arg && action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            ? action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            : REQUEST_STATUS_LIST_SALES_RETURN_LOADING;
      }),
      builder.addCase(getSalesReturnList.rejected, (state, action) => {
        const {
          sliceName = SLICE_NAME_SALES_RETURN_LIST,
          requestStatusFailedName = REQUEST_STATUS_LIST_SALES_RETURN_FAILED,
        } = action.payload || {};

        state[sliceName] = null;
        state.statusRequest = action.payload
          ? action.payload[requestStatusFailedName]
          : REQUEST_STATUS_LIST_SALES_RETURN_FAILED;
      }),
      // sales return list refresh
      builder.addCase(getSalesReturnRefreshList.fulfilled, (state, action) => {
        state[SLICE_NAME_SALES_RETURN_LIST] = action.payload[SLICE_NAME_SALES_RETURN_LIST];
        state.statusRequest = REQUEST_STATUS_LIST_SALES_RETURN_SUCCESS;
      }),
      builder.addCase(getSalesReturnRefreshList.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_LIST_SALES_RETURN_LOADING;
      }),
      builder.addCase(getSalesReturnRefreshList.rejected, (state) => {
        state[SLICE_NAME_SALES_RETURN_LIST] = null;
        state.statusRequest = REQUEST_STATUS_LIST_SALES_RETURN_FAILED;
      }),
      // sales return details
      builder.addCase(getSalesReturnDetails.fulfilled, (state, action) => {
        state[SLICE_NAME_SALES_RETURN_DETAILS] = action.payload[SLICE_NAME_SALES_RETURN_DETAILS];
        state.statusRequest = REQUEST_STATUS_DETAILS_SALES_RETURN_SUCCESS;
      }),
      builder.addCase(getSalesReturnDetails.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_DETAILS_SALES_RETURN_LOADING;
      }),
      builder.addCase(getSalesReturnDetails.rejected, (state) => {
        state[SLICE_NAME_SALES_RETURN_DETAILS] = null;
        state.statusRequest = REQUEST_STATUS_DETAILS_SALES_RETURN_FAILED;
      }),
      // sales return history
      builder.addCase(getSalesReturnHistory.fulfilled, (state, action) => {
        const { sliceName } = action.payload;
        if (sliceName) state[sliceName] = action.payload[sliceName];

        state.statusRequest = REQUEST_STATUS_HISTORY_SALES_RETURN_SUCCESS;
      }),
      builder.addCase(getSalesReturnHistory.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_HISTORY_SALES_RETURN_LOADING;
      }),
      builder.addCase(getSalesReturnHistory.rejected, (state, action) => {
        const { sliceName } = action.payload;
        if (sliceName) state[sliceName] = null;

        state.statusRequest = REQUEST_STATUS_HISTORY_SALES_RETURN_FAILED;
      }),
      // create sales return
      builder.addCase(createSalesReturn.fulfilled, (state) => {
        state.statusRequest = REQUEST_STATUS_CREATE_SALES_RETURN_SUCCESS;
      }),
      builder.addCase(createSalesReturn.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_CREATE_SALES_RETURN_PENDING;
      }),
      builder.addCase(createSalesReturn.rejected, (state) => {
        state.statusRequest = REQUEST_STATUS_CREATE_SALES_RETURN_FAILED;
      }),
      // update sales return
      builder.addCase(updateSalesReturn.fulfilled, (state) => {
        state.statusRequest = REQUEST_STATUS_UPDATE_SALES_RETURN_SUCCESS;
      }),
      builder.addCase(updateSalesReturn.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_UPDATE_SALES_RETURN_PENDING;
      }),
      builder.addCase(updateSalesReturn.rejected, (state) => {
        state.statusRequest = REQUEST_STATUS_UPDATE_SALES_RETURN_FAILED;
      }),
      // delete sales return
      builder.addCase(deleteSalesReturn.fulfilled, (state) => {
        state.statusRequest = REQUEST_STATUS_DELETE_SALES_RETURN_SUCCESS;
      }),
      builder.addCase(deleteSalesReturn.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_DELETE_SALES_RETURN_PENDING;
      }),
      builder.addCase(deleteSalesReturn.rejected, (state) => {
        state.statusRequest = REQUEST_STATUS_DELETE_SALES_RETURN_FAILED;
      }),
      // sales credit note list
      builder.addCase(getSalesCreditNoteList.fulfilled, (state, action) => {
        const { sliceName, requestStatusSuccessName } = action.payload;

        state[sliceName] = action.payload[sliceName];
        state.statusRequest = action.payload[requestStatusSuccessName];
      }),
      builder.addCase(getSalesCreditNoteList.pending, (state, action) => {
        state.statusRequest =
          action.meta && action.meta.arg && action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            ? action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            : STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_PENDING;
      }),
      builder.addCase(getSalesCreditNoteList.rejected, (state, action) => {
        const {
          sliceName = SLICE_NAME_SALES_CREDIT_NOTE_LIST,
          requestStatusFailedName,
          searchParams,
        } = action.payload || {};

        state[sliceName] = searchParams;
        state.statusRequest = action.payload[requestStatusFailedName];
      }),
      // sales credit note refresh list
      builder.addCase(getSalesCreditNoteRefreshList.fulfilled, (state, action) => {
        state[SLICE_NAME_SALES_CREDIT_NOTE_LIST] =
          action.payload[SLICE_NAME_SALES_CREDIT_NOTE_LIST];
        state.statusRequest = STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_SUCCESS;
      }),
      builder.addCase(getSalesCreditNoteRefreshList.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_PENDING;
      }),
      builder.addCase(getSalesCreditNoteRefreshList.rejected, (state) => {
        state[SLICE_NAME_SALES_CREDIT_NOTE_LIST] = null;
        state.statusRequest = STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_FAILED;
      }),
      // sales credit note details
      builder.addCase(getSalesCreditNoteDetails.fulfilled, (state, action) => {
        state[SLICE_NAME_SALES_CREDIT_NOTE_DETAILS] =
          action.payload[SLICE_NAME_SALES_CREDIT_NOTE_DETAILS];
        state.statusRequest = STATUS_REQUEST_DETAILS_SALES_CREDIT_NOTE_SUCCESS;
      }),
      builder.addCase(getSalesCreditNoteDetails.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAILS_SALES_CREDIT_NOTE_PENDING;
      }),
      builder.addCase(getSalesCreditNoteDetails.rejected, (state) => {
        state[SLICE_NAME_SALES_CREDIT_NOTE_DETAILS] = null;
        state.statusRequest = STATUS_REQUEST_DETAILS_SALES_CREDIT_NOTE_FAILED;
      }),
      // sales credit note history
      builder.addCase(getSalesCreditNoteHistory.fulfilled, (state, action) => {
        const { sliceName } = action.payload;
        if (sliceName) state[sliceName] = action.payload[sliceName];

        state.statusRequest = STATUS_REQUEST_HISTORY_SALES_CREDIT_NOTE_SUCCESS;
      }),
      builder.addCase(getSalesCreditNoteHistory.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_HISTORY_SALES_CREDIT_NOTE_PENDING;
      }),
      builder.addCase(getSalesCreditNoteHistory.rejected, (state, action) => {
        const { sliceName } = action.payload;
        if (sliceName) state[sliceName] = null;

        state.statusRequest = STATUS_REQUEST_HISTORY_SALES_CREDIT_NOTE_FAILED;
      }),
      // sales payable payment list
      builder.addCase(getSalesPayablePaymentList.fulfilled, (state, action) => {
        const { sliceName, requestStatusSuccessName } = action.payload;

        state[sliceName] = action.payload[sliceName];
        state.statusRequest = action.payload[requestStatusSuccessName];
      }),
      builder.addCase(getSalesPayablePaymentList.pending, (state, action) => {
        state.statusRequest =
          action.meta && action.meta.arg && action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            ? action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            : REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_LOADING;
      }),
      builder.addCase(getSalesPayablePaymentList.rejected, (state, action) => {
        const {
          sliceName = SLICE_NAME_SALES_PAYABLE_PAYMENT_LIST,
          requestStatusFailedName,
          searchParams,
        } = action.payload || {};

        state[sliceName] = searchParams;
        state.statusRequest = action.payload[requestStatusFailedName];
      }),
      // sales payable payment refresh list
      builder.addCase(getSalesPayablePaymentRefreshList.fulfilled, (state, action) => {
        state[SLICE_NAME_SALES_PAYABLE_PAYMENT_LIST] =
          action.payload[SLICE_NAME_SALES_PAYABLE_PAYMENT_LIST];
        state.statusRequest = REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_SUCCESS;
      }),
      builder.addCase(getSalesPayablePaymentRefreshList.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_LOADING;
      }),
      builder.addCase(getSalesPayablePaymentRefreshList.rejected, (state) => {
        state[SLICE_NAME_SALES_PAYABLE_PAYMENT_LIST] = null;
        state.statusRequest = REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_FAILED;
      }),
      // sales payable payment details
      builder.addCase(getSalesPayablePaymentDetails.fulfilled, (state, action) => {
        state[SLICE_NAME_SALES_PAYABLE_PAYMENT_DETAILS] =
          action.payload[SLICE_NAME_SALES_PAYABLE_PAYMENT_DETAILS];
        state.statusRequest = REQUEST_STATUS_DETAILS_SALES_PAYABLE_PAYMENT_SUCCESS;
      }),
      builder.addCase(getSalesPayablePaymentDetails.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_DETAILS_SALES_PAYABLE_PAYMENT_LOADING;
      }),
      builder.addCase(getSalesPayablePaymentDetails.rejected, (state) => {
        state[SLICE_NAME_SALES_PAYABLE_PAYMENT_DETAILS] = null;
        state.statusRequest = REQUEST_STATUS_DETAILS_SALES_PAYABLE_PAYMENT_FAILED;
      }),
      // sales payable payment history
      builder.addCase(getSalesPayablePaymentHistory.fulfilled, (state, action) => {
        const { sliceName } = action.payload;
        if (sliceName) state[sliceName] = action.payload[sliceName];

        state.statusRequest = REQUEST_STATUS_HISTORY_SALES_PAYABLE_PAYMENT_SUCCESS;
      }),
      builder.addCase(getSalesPayablePaymentHistory.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_HISTORY_SALES_PAYABLE_PAYMENT_LOADING;
      }),
      builder.addCase(getSalesPayablePaymentHistory.rejected, (state, action) => {
        const { sliceName } = action.payload;
        if (sliceName) state[sliceName] = null;

        state.statusRequest = REQUEST_STATUS_HISTORY_SALES_PAYABLE_PAYMENT_FAILED;
      }),
      // create sales payable payment
      builder.addCase(createSalesPayablePayment.fulfilled, (state) => {
        state.statusRequest = REQUEST_STATUS_CREATE_SALES_PAYABLE_PAYMENT_SUCCESS;
      }),
      builder.addCase(createSalesPayablePayment.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_CREATE_SALES_PAYABLE_PAYMENT_LOADING;
      }),
      builder.addCase(createSalesPayablePayment.rejected, (state) => {
        state.statusRequest = REQUEST_STATUS_CREATE_SALES_PAYABLE_PAYMENT_FAILED;
      }),
      // update sales payable payment
      builder.addCase(updateSalesPayablePayment.fulfilled, (state) => {
        state.statusRequest = REQUEST_STATUS_UPDATE_SALES_PAYABLE_PAYMENT_SUCCESS;
      }),
      builder.addCase(updateSalesPayablePayment.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_UPDATE_SALES_PAYABLE_PAYMENT_PENDING;
      }),
      builder.addCase(updateSalesPayablePayment.rejected, (state) => {
        state.statusRequest = REQUEST_STATUS_UPDATE_SALES_PAYABLE_PAYMENT_FAILED;
      }),
      // delete sales payable payment
      builder.addCase(deleteSalesPayablePayment.fulfilled, (state) => {
        state.statusRequest = REQUEST_STATUS_DELETE_SALES_PAYABLE_PAYMENT_SUCCESS;
      }),
      builder.addCase(deleteSalesPayablePayment.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_DELETE_SALES_PAYABLE_PAYMENT_PENDING;
      }),
      builder.addCase(deleteSalesPayablePayment.rejected, (state) => {
        state.statusRequest = REQUEST_STATUS_DELETE_SALES_PAYABLE_PAYMENT_FAILED;
      }),
      /**
       * sales slice matcher where currently
       * importSalesInvoice, getImportSalesInvoiceProgress
       */
      sliceReduceHelper.createMatcherEachRequestStatusAction({
        builder,
        baseTypesRegistered: BASE_TYPES_SALES_ACTION_REGISTERED,
        mappingAsyncThunkTypeWithActionProps: salesAsyncThunkTypeWithActionProps,
      });
  },
});

export const { actions, reducer } = salesSlice;

export const {
  clearSalesQuotationData,
  clearSalesInvoiceData,
  clearSalesDataSlice,
  clearSalesDeliveryData,
  clearSalesDeliveryAndOrderData,
  clearDataSalesSlice,
} = actions;

export default reducer;
