import { createSlice } from '@reduxjs/toolkit';

import {
  REQUEST_STATUS_CREATE_USER_PERMISSION_FAILED,
  REQUEST_STATUS_CREATE_USER_PERMISSION_PENDING,
  REQUEST_STATUS_CREATE_USER_PERMISSION_SUCCESS,
  REQUEST_STATUS_LIST_DETAILS_USER_PERMISSION_FAILED,
  REQUEST_STATUS_LIST_DETAILS_USER_PERMISSION_PENDING,
  REQUEST_STATUS_LIST_DETAILS_USER_PERMISSION_SUCCESS,
  ROOT_STORE_NAME_USER_PERMISSION,
  SLICE_NAME_USER_PERMISSION_LIST_DETAILS,
  STATUS_REQUEST_BASE_IDDLE,
} from '../../utils/constants/store.constant';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import userPermissionActions from './user-permission.action';

const initialState = {
  requestStatus: STATUS_REQUEST_BASE_IDDLE,
  [SLICE_NAME_USER_PERMISSION_LIST_DETAILS]: null,
};

const userPermissionSlice = createSlice({
  name: ROOT_STORE_NAME_USER_PERMISSION,
  initialState,
  reducers: {
    ...sliceReduceHelper.commonSliceReducers(initialState),
  },
  extraReducers: (builder) => {
    // list details user permission
    builder.addCase(
      userPermissionActions.getUserPermissionListDetails.fulfilled,
      (state, action) => {
        state.requestStatus = REQUEST_STATUS_LIST_DETAILS_USER_PERMISSION_SUCCESS;
        state[SLICE_NAME_USER_PERMISSION_LIST_DETAILS] =
          action.payload[SLICE_NAME_USER_PERMISSION_LIST_DETAILS];
      },
    ),
      builder.addCase(userPermissionActions.getUserPermissionListDetails.pending, (state) => {
        state.requestStatus = REQUEST_STATUS_LIST_DETAILS_USER_PERMISSION_PENDING;
      }),
      builder.addCase(userPermissionActions.getUserPermissionListDetails.rejected, (state) => {
        state.requestStatus = REQUEST_STATUS_LIST_DETAILS_USER_PERMISSION_FAILED;
        state[SLICE_NAME_USER_PERMISSION_LIST_DETAILS] = null;
      }),
      // create user permission
      builder.addCase(userPermissionActions.createUserPermission.fulfilled, (state) => {
        state.requestStatus = REQUEST_STATUS_CREATE_USER_PERMISSION_SUCCESS;
      }),
      builder.addCase(userPermissionActions.createUserPermission.pending, (state) => {
        state.requestStatus = REQUEST_STATUS_CREATE_USER_PERMISSION_PENDING;
      }),
      builder.addCase(userPermissionActions.createUserPermission.rejected, (state) => {
        state.requestStatus = REQUEST_STATUS_CREATE_USER_PERMISSION_FAILED;
      });
  },
});

export const { reducer, actions } = userPermissionSlice;

export default reducer;
