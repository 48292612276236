import { FORMAT_CURRENCY_AUTO_COMMA_COLUMN } from '../../../components/table-row/TableRowPrimary/TableRowPrimary';
import { DEFAULT_GRID_ACTION_COLLAPSE_TYPE } from '../../default/grid-type.default';
import { DEFAULT_INPUT_TYPE_CALCULATION_NUMBER_INPUT } from '../../default/input-type.default';
import {
  DEFAULT_KEY_NAME_AMOUNT_AVAILABLE_CONST,
  DEFAULT_KEY_NAME_AMOUNT_INPUT,
} from '../../default/object-keyname.default';
import {
  DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
  DEFAULT_REPRESENTATION_DATA_READABLE_DATE_TYPE,
} from '../../default/representation-data-type.default';

// column invoice for in add update sales payment
export const columnsTableHeaderInvoiceSalesPayment = (t) => {
  return [
    {
      name: 'sales_invoice_no',
      label: t('dialog.base-add-update-sales-payment.table.header-column.sales-invoice'),
      placeholder: t('dialog.base-add-update-sales-payment.input.invoice-no.placeholder'),
      width: 300,
    },
    {
      name: 'description',
      label: t('dialog.base-add-update-sales-payment.table.header-column.sales-description'),
      width: 300,
    },
    {
      name: 'expired_date',
      label: t('dialog.base-add-update-sales-payment.table.header-column.due-date'),
      width: 300,
      align: 'left',
    },
    {
      name: 'sales_invoice_amount',
      label: t('dialog.base-add-update-sales-payment.table.header-column.total-bill'),
      align: 'right',
      width: 200,
    },
    {
      name: 'amount_available',
      label: t('dialog.base-add-update-sales-payment.table.header-column.remaining-bill'),
      align: 'right',
      width: 200,
    },
    {
      name: 'amount',
      name_input: 'amount_input',
      label: t('dialog.base-add-update-sales-payment.table.header-column.amount'),
      placeholder: t('dialog.base-add-update-sales-payment.input.amount.placeholder'),
      width: 200,
      align: 'right',
    },
  ];
};

// column invoice for in detail sales payment on enhancement v2
export const columnsTableHeaderInvoiceSalesPaymentAddUpdateV2 = (t) => {
  return [
    {
      name: 'collapse_action',
      label: '',
      align: 'left',
      width: 20,
    },
    {
      multipleNames: [
        {
          name: 'sales_invoice_no',
        },
        {
          name: 'description',
        },
      ],
      isUseMultipleNames: true,
      label: t('table.header-column.sales-invoice'),
      width: 300,
    },
    {
      name: 'expired_date',
      label: t('table.header-column.due-date-v2'),
      width: 300,
      align: 'left',
    },
    {
      name: 'sales_invoice_amount',
      label: t('table.header-column.total-bill'),
      align: 'right',
      width: 200,
    },
    {
      name: 'amount_available',
      label: t('table.header-column.remaining-bill'),
      align: 'right',
      width: 200,
    },
    {
      name: 'amount',
      label: t('table.header-column.amount-paid'),
      width: 200,
      align: 'right',
    },
    {
      name: 'action',
      label: '',
      align: 'right',
      width: 25,
    },
  ];
};

// column invoice for in add update sales payment on enhancement v2
export const columnsTableHeaderInvoiceSalesPaymentV2 = (t) => {
  return [
    {
      multipleNames: [
        {
          name: 'sales_invoice_no',
        },
        {
          name: 'description',
        },
      ],
      isUseMultipleNames: true,
      label: t('table.header-column.sales-invoice'),
      width: 300,
    },
    {
      name: 'expired_date',
      label: t('table.header-column.due-date-v2'),
      width: 300,
      align: 'left',
    },
    {
      name: 'sales_invoice_amount',
      label: t('table.header-column.total-bill'),
      align: 'right',
      width: 200,
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'amount_available',
      label: t('table.header-column.remaining-bill'),
      align: 'right',
      width: 200,
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      substituteNotExistValue: 0,
    },
    {
      name: 'amount',
      label: t('table.header-column.amount-paid'),
      width: 200,
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
  ];
};

// only for case update sales payment
export const additionalColumnsHeaderInvoiceSalesPayment = [
  {
    name: 'action',
    label: '',
    align: 'right',
    width: '10px',
    maxWidth: '10px',
  },
];

// grid header sales payment add update
export const gridHeaderInvoiceSalesPayment = [
  {
    name: DEFAULT_GRID_ACTION_COLLAPSE_TYPE,
    label: '',
    align: 'left',
    width: 20,
    gridProps: {
      xs: 0.75,
    },
    isButton: true,
  },
  {
    name: 'sales_invoice_no',
    label: 'table.header-column.sales-invoice',
    isUseMultipleNames: true,
    width: 250,
    minWidth: 150,
    gridProps: {
      xs: 4.25,
      display: 'flex',
      justifyContent: 'start',
    },
    isTypography: true,
  },
  {
    name: 'expired_date',
    label: 'table.header-column.due-date',
    align: 'left',
    width: 50,
    minWidth: 50,
    gridProps: {
      xs: 1.75,
      display: 'flex',
      justifyContent: 'start',
    },
    isTypography: true,
    representationDataType: DEFAULT_REPRESENTATION_DATA_READABLE_DATE_TYPE,
  },
  {
    name: 'amount',
    label: 'table.header-column.total-bill',
    align: 'left',
    width: 50,
    minWidth: 50,
    gridProps: {
      xs: 1.75,
      display: 'flex',
      justifyContent: 'start',
    },
    isTypography: true,
    representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
  },
  {
    name: 'amount_available_const',
    label: 'table.header-column.remaining-bill',
    align: 'right',
    width: 75,
    minWidth: 75,
    gridProps: {
      xs: 1.75,
      display: 'flex',
      justifyContent: 'end',
    },
    isTypography: true,
    representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
  },
  {
    name: 'amount_input',
    placeholder: 'placeholder.calculation-number-default',
    label: 'table.header-column.amount',
    align: 'right',
    width: 100,
    minWidth: 80,
    gridProps: {
      xs: 1.75,
      display: 'flex',
      justifyContent: 'end',
    },
    isInput: true,
    inputType: DEFAULT_INPUT_TYPE_CALCULATION_NUMBER_INPUT,
    keyInputName: DEFAULT_KEY_NAME_AMOUNT_INPUT,
    isDisabled: () => {
      return false;
    },
    keyMaximumInput: DEFAULT_KEY_NAME_AMOUNT_AVAILABLE_CONST,
  },
];

export const gridCollapseInvoiceSalesPayment = [
  {
    gridProps: {
      xs: 0.75,
    },
  },
  {
    placeholder: 'label.memo',
    name: 'description',
    gridProps: {
      xs: 10.5,
    },
    isTypography: true,
  },
  {
    gridProps: {
      xs: 0.75,
    },
  },
];

// column that representation for calculation
export const calculationColumnsInvoiceSalesPayment = [
  'amount',
  'amount_available',
  'sales_invoice_amount',
];

export const dateColumnsInvoiceSalesPayment = ['expired_date'];

// additional props on table head main
export const additionalPropsSalesPaymentDetail = {
  sx: {
    backgroundColor: '#EFF8FF!important',
  },
};

export const columnDataTotalDetailSalesPayment = ['amount_available', 'amount'];
