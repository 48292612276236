import * as React from 'react';
function SvgUserSettingMenu(props) {
  return (
    <svg width={85} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#user-setting-menu_svg__filter0_d_5586_193368)'>
        <path
          d='M42.717 1c12.852 0 23.27 8.596 23.27 19.2s-10.418 19.2-23.27 19.2l-8.164-19.2L42.717 1z'
          fill='#366796'
        />
        <path
          d='M42.718 1v38.4c-12.853 0-23.27-8.596-23.27-19.2S29.865 1 42.718 1z'
          fill='#5488A8'
        />
        <path
          d='M63.21 43.003c5.58 2.274 10.519 5.46 14.54 9.32C70.767 62.29 57.709 69 42.75 69l-4.367-16.602 20.916-9.395h3.912z'
          fill='#121149'
        />
        <path
          d='M22.29 43.003c-5.58 2.274-10.519 5.46-14.54 9.32C14.733 62.29 27.791 69 42.75 69V49.76l-16.549-6.757H22.29z'
          fill='#366796'
        />
        <path d='M53.122 43.003L40.566 49.76l2.184 6.898 16.548-13.655h-6.176z' fill='#366796' />
        <path d='M32.378 43.003L42.75 49.76v6.898L26.201 43.002h6.177z' fill='#5488A8' />
        <path d='M42.75 43.003l-2.184 3.656 2.184 4.903 10.372-8.559H42.75z' fill='#C2FBFF' />
        <path d='M32.378 43.003l10.372 8.559v-8.559H32.378z' fill='#fff' />
        <path
          d='M25.75 59.4v-4.8h-2.702a9.542 9.542 0 00-1.029-2.475l1.913-1.913-3.394-3.394-1.913 1.913a9.543 9.543 0 00-2.475-1.029V45h-4.8v2.702a9.54 9.54 0 00-2.475 1.029l-1.913-1.913-3.394 3.394 1.913 1.913A9.541 9.541 0 004.453 54.6H1.75v4.8h2.702a9.54 9.54 0 001.029 2.475l-1.913 1.913 3.394 3.394 1.913-1.913a9.54 9.54 0 002.475 1.028V69h4.8v-2.702a9.54 9.54 0 002.475-1.029l1.913 1.913 3.394-3.394-1.913-1.913a9.543 9.543 0 001.028-2.475h2.703zm-12 1.2a3.6 3.6 0 110-7.2 3.6 3.6 0 010 7.2z'
          fill='#273B7A'
        />
        <path
          d='M13.75 62.818A5.825 5.825 0 017.932 57a5.825 5.825 0 015.818-5.818A5.825 5.825 0 0119.568 57a5.825 5.825 0 01-5.818 5.818zm0-10.424A4.611 4.611 0 009.144 57a4.611 4.611 0 004.606 4.606A4.611 4.611 0 0018.356 57a4.611 4.611 0 00-4.606-4.606z'
          fill='#FFC61B'
        />
      </g>
      <defs>
        <filter
          id='user-setting-menu_svg__filter0_d_5586_193368'
          x={0.75}
          y={0}
          width={84}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_193368' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_193368' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgUserSettingMenu;
