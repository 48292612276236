import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  LOCAL_STORAGE_MANAGEMENT_USERS,
  LOCAL_STORAGE_MANAGEMENT_USERS_DETAILS,
  LOCAL_STORAGE_MANAGEMENT_USERS_FOR_AUTOCOMPLETE,
  LOCAL_STORAGE_MANAGEMENT_USERS_LIST_SETTING_COMPANY,
} from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  SLICE_NAME_MANAGEMENT_USER_DETAILS,
  SLICE_NAME_MANAGEMENT_USERS_FOR_AUTOCOMPLETE,
  SLICE_NAME_MANAGEMENT_USERS_LIST_REGULAR,
  SLICE_NAME_MANAGEMENT_USERS_LIST_SETTING_COMPANY,
  STATUS_REQUEST_ADD_MANAGEMENT_USERS_ASSIGNMENT_ROLE_FAILED,
  STATUS_REQUEST_ADD_MANAGEMENT_USERS_ASSIGNMENT_ROLE_PENDING,
  STATUS_REQUEST_ADD_MANAGEMENT_USERS_ASSIGNMENT_ROLE_SUCCESS,
  STATUS_REQUEST_ADD_MANAGEMENT_USERS_FAILED,
  STATUS_REQUEST_ADD_MANAGEMENT_USERS_PENDING,
  STATUS_REQUEST_ADD_MANAGEMENT_USERS_SUCCESS,
  STATUS_REQUEST_APPROVAL_INVITATION_MANAGEMENT_USERS_ROLE_FAILED,
  STATUS_REQUEST_APPROVAL_INVITATION_MANAGEMENT_USERS_ROLE_PENDING,
  STATUS_REQUEST_APPROVAL_INVITATION_MANAGEMENT_USERS_ROLE_SUCCESS,
  STATUS_REQUEST_BASE_FAILED,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_BASE_PENDING,
  STATUS_REQUEST_BASE_SUCCESS,
  STATUS_REQUEST_DELETE_MANAGEMENT_USERS_FAILED,
  STATUS_REQUEST_DELETE_MANAGEMENT_USERS_PENDING,
  STATUS_REQUEST_DELETE_MANAGEMENT_USERS_SUCCESS,
  STATUS_REQUEST_DETAIL_USER_MANAGEMENT_USERS_FAILED,
  STATUS_REQUEST_DETAIL_USER_MANAGEMENT_USERS_PENDING,
  STATUS_REQUEST_DETAIL_USER_MANAGEMENT_USERS_SUCCESS,
  STATUS_REQUEST_INVITE_USER_MANAGEMENT_USERS_FAILED,
  STATUS_REQUEST_INVITE_USER_MANAGEMENT_USERS_PENDING,
  STATUS_REQUEST_INVITE_USER_MANAGEMENT_USERS_SUCCESS,
  STATUS_REQUEST_LIST_MANAGEMENT_USERS_COMPANY_FAILED,
  STATUS_REQUEST_LIST_MANAGEMENT_USERS_COMPANY_PENDING,
  STATUS_REQUEST_LIST_MANAGEMENT_USERS_COMPANY_SUCCESS,
  STATUS_REQUEST_LIST_MANAGEMENT_USERS_FAILED,
  STATUS_REQUEST_LIST_MANAGEMENT_USERS_FOR_AUTOCOMPLETE_FAILED,
  STATUS_REQUEST_LIST_MANAGEMENT_USERS_FOR_AUTOCOMPLETE_PENDING,
  STATUS_REQUEST_LIST_MANAGEMENT_USERS_FOR_AUTOCOMPLETE_SUCCESS,
  STATUS_REQUEST_LIST_MANAGEMENT_USERS_PENDING,
  STATUS_REQUEST_LIST_MANAGEMENT_USERS_SUCCESS,
  STATUS_REQUEST_UPDATE_MANAGEMENT_USERS_FAILED,
  STATUS_REQUEST_UPDATE_MANAGEMENT_USERS_PENDING,
  STATUS_REQUEST_UPDATE_MANAGEMENT_USERS_SUCCESS,
} from '../../utils/constants/store.constant';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import {
  addUserCompany,
  assignmentRoleToUser,
  deleteUserWithID,
  getManagementUserDetails,
  getUsersListRefreshWithDynamicStore,
  getUsersListWithDynamicStore,
  listUsers,
  managementApprovalInviteUser,
  managementInviteUser,
  refreshListUsers,
  updateUserWithID,
} from './management-user.action';

function createStatusRequest(pending, success, failed) {
  return {
    [STATUS_REQUEST_BASE_PENDING]: pending,
    [STATUS_REQUEST_BASE_SUCCESS]: success,
    [STATUS_REQUEST_BASE_FAILED]: failed,
  };
}

const sliceNameWithRequestStatusManagementUsers = {
  [SLICE_NAME_MANAGEMENT_USERS_FOR_AUTOCOMPLETE]: createStatusRequest(
    STATUS_REQUEST_LIST_MANAGEMENT_USERS_FOR_AUTOCOMPLETE_PENDING,
    STATUS_REQUEST_LIST_MANAGEMENT_USERS_FOR_AUTOCOMPLETE_SUCCESS,
    STATUS_REQUEST_LIST_MANAGEMENT_USERS_FOR_AUTOCOMPLETE_FAILED,
  ),
  [SLICE_NAME_MANAGEMENT_USERS_LIST_REGULAR]: createStatusRequest(
    STATUS_REQUEST_LIST_MANAGEMENT_USERS_PENDING,
    STATUS_REQUEST_LIST_MANAGEMENT_USERS_SUCCESS,
    STATUS_REQUEST_LIST_MANAGEMENT_USERS_FAILED,
  ),
  [SLICE_NAME_MANAGEMENT_USERS_LIST_SETTING_COMPANY]: createStatusRequest(
    STATUS_REQUEST_LIST_MANAGEMENT_USERS_COMPANY_PENDING,
    STATUS_REQUEST_LIST_MANAGEMENT_USERS_COMPANY_SUCCESS,
    STATUS_REQUEST_LIST_MANAGEMENT_USERS_COMPANY_FAILED,
  ),
};

function getRequestStatusUserListDynamicStore(sliceName, status) {
  return sliceNameWithRequestStatusManagementUsers[sliceName][status];
}

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  users: LocalStorage.get(LOCAL_STORAGE_MANAGEMENT_USERS),
  [SLICE_NAME_MANAGEMENT_USERS_FOR_AUTOCOMPLETE]: LocalStorage.get(
    LOCAL_STORAGE_MANAGEMENT_USERS_FOR_AUTOCOMPLETE,
  ),
  [SLICE_NAME_MANAGEMENT_USERS_LIST_SETTING_COMPANY]: LocalStorage.get(
    LOCAL_STORAGE_MANAGEMENT_USERS_LIST_SETTING_COMPANY,
  ),
  [SLICE_NAME_MANAGEMENT_USER_DETAILS]: LocalStorage.get(LOCAL_STORAGE_MANAGEMENT_USERS_DETAILS),
};

const managementUsersSlice = createSlice({
  name: NAME_STORE.MANAGEMENT_USER,
  initialState,
  reducers: {
    listUsers,
    refreshListUsers,
    addUserCompany,
    updateUserWithID,
    deleteUserWithID,
    assignmentRoleToUser,
    clearManagementUsersData: (state, action) => {
      LocalStorage.remove(LOCAL_STORAGE_MANAGEMENT_USERS);

      if (action && action.payload && action.payload.removeLocalStorageOnly) return state;

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        users: null,
      };
    },
    // clearing data on slice and localstorage, you can pass it on object with keys 'sliceName' and 'localStorageName'
    clearDataManagementUsersSlice: (state, action) => {
      let sliceName = SLICE_NAME_MANAGEMENT_USERS_FOR_AUTOCOMPLETE,
        localStorageName = LOCAL_STORAGE_MANAGEMENT_USERS_FOR_AUTOCOMPLETE;

      if (action.payload) {
        sliceName = action.payload.sliceName;
        localStorageName = action.payload.localStorageName;
      }

      if (localStorageName && LocalStorage.get(localStorageName)) {
        LocalStorage.remove(localStorageName);
      }

      return {
        ...state,
        [sliceName]: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      users: LOCAL_STORAGE_MANAGEMENT_USERS,
      [SLICE_NAME_MANAGEMENT_USERS_FOR_AUTOCOMPLETE]:
        LOCAL_STORAGE_MANAGEMENT_USERS_FOR_AUTOCOMPLETE,
      [SLICE_NAME_MANAGEMENT_USERS_LIST_SETTING_COMPANY]:
        LOCAL_STORAGE_MANAGEMENT_USERS_LIST_SETTING_COMPANY,
      [SLICE_NAME_MANAGEMENT_USER_DETAILS]: LOCAL_STORAGE_MANAGEMENT_USERS_DETAILS,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(listUsers.fulfilled, (state, action) => {
      state.statusRequest = STATUS_REQUEST_LIST_MANAGEMENT_USERS_SUCCESS;
      state.users = action.payload.users;
    }),
      builder.addCase(listUsers.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_MANAGEMENT_USERS_PENDING;
      }),
      builder.addCase(listUsers.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_MANAGEMENT_USERS_FAILED;
      }),
      builder.addCase(refreshListUsers.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_MANAGEMENT_USERS_SUCCESS;
        state.users = action.payload.users;
      }),
      builder.addCase(refreshListUsers.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_MANAGEMENT_USERS_PENDING;
      }),
      builder.addCase(refreshListUsers.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_MANAGEMENT_USERS_FAILED;
      }),
      // getting user list with dynamic store
      builder.addCase(getUsersListWithDynamicStore.fulfilled, (state, action) => {
        const { sliceName } = action.payload;

        state.statusRequest = getRequestStatusUserListDynamicStore(
          sliceName,
          STATUS_REQUEST_BASE_SUCCESS,
        );
        state[sliceName] = action.payload[sliceName];
      }),
      builder.addCase(getUsersListWithDynamicStore.pending, (state, action) => {
        const { sliceName } = action.meta.arg;

        state.statusRequest = getRequestStatusUserListDynamicStore(
          sliceName,
          STATUS_REQUEST_BASE_PENDING,
        );
      }),
      builder.addCase(getUsersListWithDynamicStore.rejected, (state, action) => {
        const { sliceName } = action.payload;

        state.statusRequest = getRequestStatusUserListDynamicStore(
          sliceName,
          STATUS_REQUEST_BASE_FAILED,
        );
        state[sliceName] = null;
      }),
      // getting user list refresh
      builder.addCase(getUsersListRefreshWithDynamicStore.fulfilled, (state, action) => {
        const keysOnActionPayload = action.payload ? Object.keys(action.payload) : null;

        if (!keysOnActionPayload) return;

        const sliceName = keysOnActionPayload[0];

        state.statusRequest = getRequestStatusUserListDynamicStore(
          sliceName,
          STATUS_REQUEST_BASE_SUCCESS,
        );
        state[sliceName] = action.payload[sliceName];
      }),
      builder.addCase(getUsersListRefreshWithDynamicStore.pending, (state, action) => {
        const { sliceName } = action.meta.arg;

        state.statusRequest = getRequestStatusUserListDynamicStore(
          sliceName,
          STATUS_REQUEST_BASE_PENDING,
        );
      }),
      builder.addCase(getUsersListRefreshWithDynamicStore.rejected, (state, action) => {
        const keysOnActionPayload = action.payload ? Object.keys(action.payload) : null;

        if (!keysOnActionPayload) return;

        const sliceName = keysOnActionPayload[0];

        state[sliceName] = null;
        state.statusRequest = getRequestStatusUserListDynamicStore(
          sliceName,
          STATUS_REQUEST_BASE_FAILED,
        );
      }),
      builder.addCase(addUserCompany.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_MANAGEMENT_USERS_SUCCESS;
      }),
      builder.addCase(addUserCompany.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_MANAGEMENT_USERS_PENDING;
      }),
      builder.addCase(addUserCompany.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_MANAGEMENT_USERS_FAILED;
      }),
      builder.addCase(updateUserWithID.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_MANAGEMENT_USERS_PENDING;
      }),
      builder.addCase(updateUserWithID.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_MANAGEMENT_USERS_SUCCESS;
      }),
      builder.addCase(updateUserWithID.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_MANAGEMENT_USERS_FAILED;
      }),
      builder.addCase(deleteUserWithID.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_MANAGEMENT_USERS_SUCCESS;
      }),
      builder.addCase(deleteUserWithID.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_MANAGEMENT_USERS_PENDING;
      }),
      builder.addCase(deleteUserWithID.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_MANAGEMENT_USERS_FAILED;
      }),
      builder.addCase(assignmentRoleToUser.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_MANAGEMENT_USERS_ASSIGNMENT_ROLE_SUCCESS;
      }),
      builder.addCase(assignmentRoleToUser.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_MANAGEMENT_USERS_ASSIGNMENT_ROLE_PENDING;
      }),
      builder.addCase(assignmentRoleToUser.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_MANAGEMENT_USERS_ASSIGNMENT_ROLE_FAILED;
      }),
      // approval user invitation
      builder.addCase(managementApprovalInviteUser.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_APPROVAL_INVITATION_MANAGEMENT_USERS_ROLE_SUCCESS;
      }),
      builder.addCase(managementApprovalInviteUser.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_APPROVAL_INVITATION_MANAGEMENT_USERS_ROLE_PENDING;
      }),
      builder.addCase(managementApprovalInviteUser.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_APPROVAL_INVITATION_MANAGEMENT_USERS_ROLE_FAILED;
      }),
      // invitation user
      builder.addCase(managementInviteUser.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_INVITE_USER_MANAGEMENT_USERS_SUCCESS;
      }),
      builder.addCase(managementInviteUser.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_INVITE_USER_MANAGEMENT_USERS_PENDING;
      }),
      builder.addCase(managementInviteUser.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_INVITE_USER_MANAGEMENT_USERS_FAILED;
      }),
      // magement user details
      builder.addCase(getManagementUserDetails.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_USER_MANAGEMENT_USERS_SUCCESS;
        state[SLICE_NAME_MANAGEMENT_USER_DETAILS] =
          action.payload[SLICE_NAME_MANAGEMENT_USER_DETAILS];
      }),
      builder.addCase(getManagementUserDetails.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_USER_MANAGEMENT_USERS_PENDING;
      }),
      builder.addCase(getManagementUserDetails.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_USER_MANAGEMENT_USERS_FAILED;
      });
  },
});

export const { actions, reducer } = managementUsersSlice;

export const { clearManagementUsersData, clearDataManagementUsersSlice } = actions;

export default reducer;
