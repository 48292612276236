import { createAsyncThunk } from '@reduxjs/toolkit';

import expenseServices from '../../services/API/expense/expense.service';
import {
  REACT_APP_EXPENSE_DETAILS_TEMPLATE_URL,
  REACT_APP_EXPENSE_URL,
} from '../../utils/configs/url.config';
import {
  DEFAULT_IMPORT_FILE_ACTION_TYPE,
  DEFAULT_IMPORT_FILE_PROGRESS_ACTION_TYPE,
  EXPENSE_ASYNC_THUNK_TYPE,
} from '../../utils/constants/action-type.constant';
import { LOCAL_STORAGE_EXPENSE_LIST } from '../../utils/constants/storage.constant';
import {
  SLICE_NAME_EXPENSE_DETAIL,
  SLICE_NAME_EXPENSE_LIST,
  SLICE_NAME_IMPORT_EXPENSE,
  SLICE_NAME_IMPORT_EXPENSE_PROGRESS,
} from '../../utils/constants/store.constant';
import { DEFAULT_KEY_NAME_EXPENSE_ID_API_REF } from '../../utils/default/object-keyname.default';
import { DEFAULT_EXPENSE_SEARCH_FE_PARAMS } from '../../utils/default/params.default';
import objHelper from '../../utils/helpers/object.helper';
import strHelp from '../../utils/helpers/string.helpers';
import {
  creatorAddActionWithPreviewButtonGenerateLink,
  creatorAddActionWithStoreDataOnSlice,
  creatorDeleteAction,
  creatorDetailAction,
  creatorListAction,
  creatorListRefreshAction,
  creatorUpdateAction,
} from '../creator-action/creator-action.helper';

// handling expense list
export const getExpenseList = createAsyncThunk(
  'expense/list',
  async (paramsGetExpenseList, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      paramsGetExpenseList,
      expenseServices.getExpenseList,
      LOCAL_STORAGE_EXPENSE_LIST,
      SLICE_NAME_EXPENSE_LIST,
      'expense_id',
      'error.list-data.expense',
    );
  },
);

// handling refresh expense list
export const getExpenseListRefresh = createAsyncThunk(
  'expense/list-refresh',
  async (discardKeysSearch = [], thunkAPI) => {
    return await creatorListRefreshAction(
      thunkAPI,
      expenseServices.getExpenseList,
      LOCAL_STORAGE_EXPENSE_LIST,
      SLICE_NAME_EXPENSE_LIST,
      discardKeysSearch,
      DEFAULT_EXPENSE_SEARCH_FE_PARAMS,
      'error.list-data.expense',
    );
  },
);

// handling expense detail
export const getExpenseDetail = createAsyncThunk('expense/detail', async (expenseID, thunkAPI) => {
  return await creatorDetailAction(
    thunkAPI,
    expenseID,
    expenseServices.getExpenseDetail,
    '',
    [SLICE_NAME_EXPENSE_DETAIL],
    'error.detail.expense',
  );
});

// handling delete expense
export const deleteExpense = createAsyncThunk(
  'expense/delete',
  async (paramsDeleteExpense, thunkAPI) => {
    const { transaction_no } = paramsDeleteExpense;

    return await creatorDeleteAction(
      thunkAPI,
      paramsDeleteExpense,
      expenseServices.deleteExpense,
      'success.delete.expense',
      'error.delete.expense',
      {},
      { transaction_no },
    );
  },
);

// handling create/add expense
export const addExpense = createAsyncThunk('expense/add', async (bodyAddExpense, thunkAPI) => {
  return creatorAddActionWithPreviewButtonGenerateLink({
    thunkAPI,
    bodyDataAction: bodyAddExpense,
    serviceFunc: expenseServices.addExpense,
    successMessage: 'success.add.expense',
    errorMessage: 'error.add.expense',
    baseContentUrl: REACT_APP_EXPENSE_URL,
    generateButtonLink: (responseResult) => {
      const templateLinkDetails = {
        [DEFAULT_KEY_NAME_EXPENSE_ID_API_REF]: objHelper.findAllValuesByKey(
          responseResult,
          DEFAULT_KEY_NAME_EXPENSE_ID_API_REF,
        ),
      };

      return strHelp.templateString(REACT_APP_EXPENSE_DETAILS_TEMPLATE_URL, templateLinkDetails);
    },
  });
});

export const importExpense = createAsyncThunk(
  EXPENSE_ASYNC_THUNK_TYPE[DEFAULT_IMPORT_FILE_ACTION_TYPE],
  async (body, thunkAPI) => {
    return await creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      body,
      expenseServices.importExpense,
      SLICE_NAME_IMPORT_EXPENSE,
    );
  },
);

export const getImportExpenseProgress = createAsyncThunk(
  EXPENSE_ASYNC_THUNK_TYPE[DEFAULT_IMPORT_FILE_PROGRESS_ACTION_TYPE],
  async (params, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      params,
      expenseServices.getImportExpenseProgress,
      null,
      SLICE_NAME_IMPORT_EXPENSE_PROGRESS,
    );
  },
);

// handling update expense
export const updateExpense = createAsyncThunk(
  'expense/update',
  async (bodyUpdateExpense, thunkAPI) => {
    // const { transaction_no } = bodyUpdateExpense

    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdateExpense,
      expenseServices.updateExpense,
      'success.update.expense',
      'error.update.expense',
      {},
      {},
    );
  },
);

const expenseActions = {
  getExpenseList,
  getExpenseListRefresh,
  getExpenseDetail,
  deleteExpense,
  addExpense,
  updateExpense,
};

export default expenseActions;
