function parseJwt(token) {
  if (!token) return {};

  // https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
  const base64Url = token.split('.')[1] || token;

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

function getExpiredTimeToken(token) {
  const bodyParsingToken = parseJwt(token);

  return bodyParsingToken.exp;
}

const tokenServices = {
  parseJwt,
  getExpiredTimeToken,
};

export default tokenServices;
