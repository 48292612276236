import {
  ACCOUNT_ASYNC_THUNK_TYPE,
  ACCOUNT_CATEGORY_ASYNC_THUNK_TYPE,
  ASSET_CATEGORY_ASYNC_THUNK_TYPE,
  ASSET_MANAGEMENT_ASYNC_THUNK_TYPE,
  BANK_STATEMENTS_ASYNC_THUNK_TYPE,
  CONTACT_ASYNC_THUNK_TYPE,
  DEFAULT_IMPORT_FILE_ACTION_TYPE,
  DEFAULT_IMPORT_FILE_PROGRESS_ACTION_TYPE,
  DEFAULT_LIST_ACTION_TYPE,
  EXPENSE_ASYNC_THUNK_TYPE,
  JOURNAL_ENTRY_ASYNC_THUNK_TYPE,
  PRODUCT_ASYNC_THUNK_TYPE,
  PRODUCT_CATEGORY_ASYNC_THUNK_TYPE,
  PURCHASE_INVOICE_ASYNC_THUNK_TYPE,
  REDUX_TOOLKIT_FULFILLED_STATUS_ACTION_TYPE,
  REDUX_TOOLKIT_PENDING_STATUS_ACTION_TYPE,
  REDUX_TOOLKIT_REJECTED_STATUS_ACTION_TYPE,
  SALES_INVOICE_ASYNC_THUNK_TYPE,
  STOCK_ADJUSTMENT_ASYNC_THUNK_TYPE,
  STOCK_TRANSFER_ASYNC_THUNK_TYPE,
  TRANSACTION_ASYNC_THUNK_TYPE,
} from '../../constants/action-type.constant';
import {
  REQUEST_STATUS_IMPORT_BANK_STATEMENTS,
  REQUEST_STATUS_IMPORT_BANK_STATEMENTS_PROGRESS,
  REQUEST_STATUS_IMPORT_EXPENSE,
  REQUEST_STATUS_IMPORT_EXPENSE_PROGRESS,
  REQUEST_STATUS_IMPORT_JOURNAL_ENTRY,
  REQUEST_STATUS_IMPORT_JOURNAL_ENTRY_PROGRESS,
  REQUEST_STATUS_IMPORT_PURCHASE_INVOICE,
  REQUEST_STATUS_IMPORT_PURCHASE_INVOICE_PROGRESS,
  REQUEST_STATUS_IMPORT_SALES_INVOICE,
  REQUEST_STATUS_IMPORT_SALES_INVOICE_PROGRESS,
  REQUEST_STATUS_LIST_BANK_STATEMENTS,
  SLICE_NAME_ACCOUNT_CATEGORY,
  SLICE_NAME_ACCOUNT_DATA,
  SLICE_NAME_ASSET_CATEGORY_DATA,
  SLICE_NAME_ASSET_MANAGEMENT_DATA,
  SLICE_NAME_BANK_STATEMENTS_LIST,
  SLICE_NAME_CONTACT_DATA,
  SLICE_NAME_IMPORT_BANK_STATEMENTS,
  SLICE_NAME_IMPORT_BANK_STATEMENTS_PROGRESS,
  SLICE_NAME_IMPORT_EXPENSE,
  SLICE_NAME_IMPORT_EXPENSE_PROGRESS,
  SLICE_NAME_IMPORT_JOURNAL_ENTRY,
  SLICE_NAME_IMPORT_JOURNAL_ENTRY_PROGRESS,
  SLICE_NAME_IMPORT_PURCHASE_INVOICE,
  SLICE_NAME_IMPORT_PURCHASE_INVOICE_PROGRESS,
  SLICE_NAME_IMPORT_SALES_INVOICE,
  SLICE_NAME_IMPORT_SALES_INVOICE_PROGRESS,
  SLICE_NAME_PRODUCT_CATEGORY_DATA,
  SLICE_NAME_PRODUCT_DATA,
  SLICE_NAME_STOCK_ADJUSTMENT_LIST,
  SLICE_NAME_STOCK_TRANSFER_LIST,
  SLICE_NAME_TRANSACTION_DATA_LIST,
  STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_FAILED,
  STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_PENDING,
  STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_SUCCESS,
  STATUS_REQUEST_LIST_ACCOUNT_DATA_FAILED,
  STATUS_REQUEST_LIST_ACCOUNT_DATA_PENDING,
  STATUS_REQUEST_LIST_ACCOUNT_DATA_SUCCESS,
  STATUS_REQUEST_LIST_ASSET_CATEGORY_FAILED,
  STATUS_REQUEST_LIST_ASSET_CATEGORY_PENDING,
  STATUS_REQUEST_LIST_ASSET_CATEGORY_SUCCESS,
  STATUS_REQUEST_LIST_ASSET_MANAGEMENT_FAILED,
  STATUS_REQUEST_LIST_ASSET_MANAGEMENT_PENDING,
  STATUS_REQUEST_LIST_ASSET_MANAGEMENT_SUCCESS,
  STATUS_REQUEST_LIST_CONTACT_DATA_FAILED,
  STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
  STATUS_REQUEST_LIST_CONTACT_DATA_SUCCESS,
  STATUS_REQUEST_LIST_PRODUCT_CATEGORY_FAILED,
  STATUS_REQUEST_LIST_PRODUCT_CATEGORY_PENDING,
  STATUS_REQUEST_LIST_PRODUCT_CATEGORY_SUCCESS,
  STATUS_REQUEST_LIST_PRODUCT_DATA_FAILED,
  STATUS_REQUEST_LIST_PRODUCT_DATA_PENDING,
  STATUS_REQUEST_LIST_PRODUCT_DATA_SUCCESS,
  STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_FAILED,
  STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_PENDING,
  STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_SUCCESS,
  STATUS_REQUEST_LIST_STOCK_TRANSFER_FAILED,
  STATUS_REQUEST_LIST_STOCK_TRANSFER_PENDING,
  STATUS_REQUEST_LIST_STOCK_TRANSFER_SUCCESS,
  STATUS_REQUEST_LIST_TRANSACTION_FAILED,
  STATUS_REQUEST_LIST_TRANSACTION_PENDING,
  STATUS_REQUEST_LIST_TRANSACTION_SUCCESS,
} from '../../constants/store.constant';

export const mappingContactAsyncThunkTypeWithRejectedActionProps = {
  [CONTACT_ASYNC_THUNK_TYPE.list]: {
    sliceName: SLICE_NAME_CONTACT_DATA,
    requestStatus: STATUS_REQUEST_LIST_CONTACT_DATA_FAILED,
  },
};

export const mappingProductAsyncThunkTypeWithRejectedActionProps = {
  [PRODUCT_ASYNC_THUNK_TYPE.list]: {
    sliceName: SLICE_NAME_PRODUCT_DATA,
    requestStatus: STATUS_REQUEST_LIST_PRODUCT_DATA_FAILED,
  },
  [PRODUCT_CATEGORY_ASYNC_THUNK_TYPE.list]: {
    sliceName: SLICE_NAME_PRODUCT_CATEGORY_DATA,
    requestStatus: STATUS_REQUEST_LIST_PRODUCT_CATEGORY_FAILED,
  },
};

export const contactAsyncThunkTypeWithActionProps = {
  [CONTACT_ASYNC_THUNK_TYPE.list]: {
    sliceName: SLICE_NAME_CONTACT_DATA,
    [REDUX_TOOLKIT_REJECTED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_CONTACT_DATA_FAILED,
    [REDUX_TOOLKIT_PENDING_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
    [REDUX_TOOLKIT_FULFILLED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_CONTACT_DATA_SUCCESS,
  },
};

export const productAsyncThunkTypeWithActionProps = {
  [PRODUCT_ASYNC_THUNK_TYPE.list]: {
    sliceName: SLICE_NAME_PRODUCT_DATA,
    [REDUX_TOOLKIT_REJECTED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_PRODUCT_DATA_FAILED,
    [REDUX_TOOLKIT_PENDING_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_PRODUCT_DATA_PENDING,
    [REDUX_TOOLKIT_FULFILLED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_PRODUCT_DATA_SUCCESS,
  },
  [PRODUCT_CATEGORY_ASYNC_THUNK_TYPE.list]: {
    sliceName: SLICE_NAME_PRODUCT_CATEGORY_DATA,
    [REDUX_TOOLKIT_REJECTED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_PRODUCT_CATEGORY_FAILED,
    [REDUX_TOOLKIT_PENDING_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_PRODUCT_CATEGORY_PENDING,
    [REDUX_TOOLKIT_FULFILLED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_PRODUCT_CATEGORY_SUCCESS,
  },
};

export const accountAsyncThunkTypeWithActionProps = {
  [ACCOUNT_ASYNC_THUNK_TYPE.list]: {
    sliceName: SLICE_NAME_ACCOUNT_DATA,
    [REDUX_TOOLKIT_REJECTED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_ACCOUNT_DATA_FAILED,
    [REDUX_TOOLKIT_PENDING_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_ACCOUNT_DATA_PENDING,
    [REDUX_TOOLKIT_FULFILLED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_ACCOUNT_DATA_SUCCESS,
  },
  [ACCOUNT_CATEGORY_ASYNC_THUNK_TYPE.list]: {
    sliceName: SLICE_NAME_ACCOUNT_CATEGORY,
    [REDUX_TOOLKIT_REJECTED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_FAILED,
    [REDUX_TOOLKIT_PENDING_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_PENDING,
    [REDUX_TOOLKIT_FULFILLED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_SUCCESS,
  },
};

export const assetAsyncThunkTypeWithActionProps = {
  [ASSET_MANAGEMENT_ASYNC_THUNK_TYPE.list]: {
    sliceName: SLICE_NAME_ASSET_MANAGEMENT_DATA,
    [REDUX_TOOLKIT_REJECTED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_ASSET_MANAGEMENT_FAILED,
    [REDUX_TOOLKIT_PENDING_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_ASSET_MANAGEMENT_PENDING,
    [REDUX_TOOLKIT_FULFILLED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_ASSET_MANAGEMENT_SUCCESS,
  },
  [ASSET_CATEGORY_ASYNC_THUNK_TYPE.list]: {
    sliceName: SLICE_NAME_ASSET_CATEGORY_DATA,
    [REDUX_TOOLKIT_REJECTED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_ASSET_CATEGORY_FAILED,
    [REDUX_TOOLKIT_PENDING_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_ASSET_CATEGORY_PENDING,
    [REDUX_TOOLKIT_FULFILLED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_ASSET_CATEGORY_SUCCESS,
  },
};

export const stockAdjustmentAsyncThunkTypeWithActionProps = {
  [STOCK_ADJUSTMENT_ASYNC_THUNK_TYPE.list]: {
    sliceName: SLICE_NAME_STOCK_ADJUSTMENT_LIST,
    [REDUX_TOOLKIT_REJECTED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_FAILED,
    [REDUX_TOOLKIT_PENDING_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_PENDING,
    [REDUX_TOOLKIT_FULFILLED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_SUCCESS,
  },
};

export const stockTransferAsyncThunkTypeWithActionProps = {
  [STOCK_TRANSFER_ASYNC_THUNK_TYPE.list]: {
    sliceName: SLICE_NAME_STOCK_TRANSFER_LIST,
    [REDUX_TOOLKIT_REJECTED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_STOCK_TRANSFER_FAILED,
    [REDUX_TOOLKIT_PENDING_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_STOCK_TRANSFER_PENDING,
    [REDUX_TOOLKIT_FULFILLED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_STOCK_TRANSFER_SUCCESS,
  },
};

export const transactionAsyncThunkTypeWithActionProps = {
  [TRANSACTION_ASYNC_THUNK_TYPE.list]: {
    sliceName: SLICE_NAME_TRANSACTION_DATA_LIST,
    [REDUX_TOOLKIT_REJECTED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_TRANSACTION_FAILED,
    [REDUX_TOOLKIT_PENDING_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_TRANSACTION_PENDING,
    [REDUX_TOOLKIT_FULFILLED_STATUS_ACTION_TYPE]: STATUS_REQUEST_LIST_TRANSACTION_SUCCESS,
  },
};

// purchase
export const purchaseAsyncThunkTypeWithActionProps = {
  [PURCHASE_INVOICE_ASYNC_THUNK_TYPE[DEFAULT_IMPORT_FILE_ACTION_TYPE]]: {
    sliceName: SLICE_NAME_IMPORT_PURCHASE_INVOICE,
    ...REQUEST_STATUS_IMPORT_PURCHASE_INVOICE,
  },
  [PURCHASE_INVOICE_ASYNC_THUNK_TYPE[DEFAULT_IMPORT_FILE_PROGRESS_ACTION_TYPE]]: {
    sliceName: SLICE_NAME_IMPORT_PURCHASE_INVOICE_PROGRESS,
    ...REQUEST_STATUS_IMPORT_PURCHASE_INVOICE_PROGRESS,
  },
};

// sales
export const salesAsyncThunkTypeWithActionProps = {
  [SALES_INVOICE_ASYNC_THUNK_TYPE[DEFAULT_IMPORT_FILE_ACTION_TYPE]]: {
    sliceName: SLICE_NAME_IMPORT_SALES_INVOICE,
    ...REQUEST_STATUS_IMPORT_SALES_INVOICE,
  },
  [SALES_INVOICE_ASYNC_THUNK_TYPE[DEFAULT_IMPORT_FILE_PROGRESS_ACTION_TYPE]]: {
    sliceName: SLICE_NAME_IMPORT_SALES_INVOICE_PROGRESS,
    ...REQUEST_STATUS_IMPORT_SALES_INVOICE_PROGRESS,
  },
};

// expense
export const expenseAsyncThunkTypeWithActionProps = {
  [EXPENSE_ASYNC_THUNK_TYPE[DEFAULT_IMPORT_FILE_ACTION_TYPE]]: {
    sliceName: SLICE_NAME_IMPORT_EXPENSE,
    ...REQUEST_STATUS_IMPORT_EXPENSE,
  },
  [EXPENSE_ASYNC_THUNK_TYPE[DEFAULT_IMPORT_FILE_PROGRESS_ACTION_TYPE]]: {
    sliceName: SLICE_NAME_IMPORT_EXPENSE_PROGRESS,
    ...REQUEST_STATUS_IMPORT_EXPENSE_PROGRESS,
  },
};

// journal entry
export const journalEntryAsyncThunkTypeWithActionProps = {
  [JOURNAL_ENTRY_ASYNC_THUNK_TYPE[DEFAULT_IMPORT_FILE_ACTION_TYPE]]: {
    sliceName: SLICE_NAME_IMPORT_JOURNAL_ENTRY,
    ...REQUEST_STATUS_IMPORT_JOURNAL_ENTRY,
  },
  [JOURNAL_ENTRY_ASYNC_THUNK_TYPE[DEFAULT_IMPORT_FILE_PROGRESS_ACTION_TYPE]]: {
    sliceName: SLICE_NAME_IMPORT_JOURNAL_ENTRY_PROGRESS,
    ...REQUEST_STATUS_IMPORT_JOURNAL_ENTRY_PROGRESS,
  },
};

// cash and bank
export const cashNbankAsyncThunkTypeWithActionProps = {
  [BANK_STATEMENTS_ASYNC_THUNK_TYPE[DEFAULT_LIST_ACTION_TYPE]]: {
    sliceName: SLICE_NAME_BANK_STATEMENTS_LIST,
    ...REQUEST_STATUS_LIST_BANK_STATEMENTS,
  },
  [BANK_STATEMENTS_ASYNC_THUNK_TYPE[DEFAULT_IMPORT_FILE_ACTION_TYPE]]: {
    sliceName: SLICE_NAME_IMPORT_BANK_STATEMENTS,
    ...REQUEST_STATUS_IMPORT_BANK_STATEMENTS,
  },
  [BANK_STATEMENTS_ASYNC_THUNK_TYPE[DEFAULT_IMPORT_FILE_PROGRESS_ACTION_TYPE]]: {
    sliceName: SLICE_NAME_IMPORT_BANK_STATEMENTS_PROGRESS,
    ...REQUEST_STATUS_IMPORT_BANK_STATEMENTS_PROGRESS,
  },
};
