export const styles = (theme) => {
  return {
    boxContainerTable: {
      borderRadius: '8px',
    },
    paperContainerTable: {
      width: '100%',
      borderRadius: '8px',
      boxShadow: theme.shadows[3],
    },
    removeBoxShadownContainerTable: {
      boxShadow: 'none',
    },
    tableContainerList: {
      height: 'calc(100% - 56px)',
      minHeight: '275px',
      backgroundColor: 'white',
      borderRadius: '8px 8px 0px 0px',
    },
    iconButtonContainer: {
      '&:hover': {
        backgroundColor: 'rgba(222, 235, 255, 0.6)',
        '& svg': {
          fill: '#0052CC',
        },
      },
    },
  };
};
