import axios from 'axios';
import { isEmpty, pick } from 'lodash';
import moment from 'moment';
import qs from 'qs';

import {
  REACT_APP_API_URL_REPORT,
  REACT_APP_API_URL_REPORT_BALANCE_SHEET,
  REACT_APP_API_URL_REPORT_CAPITAL_CHANGE,
  REACT_APP_API_URL_REPORT_CASH_FLOW,
  REACT_APP_API_URL_REPORT_DOWNLOAD,
  REACT_APP_API_URL_REPORT_GENERAL_LEDGER,
  REACT_APP_API_URL_REPORT_INCOME_STATEMENT,
  REACT_APP_API_URL_REPORT_JOURNAL,
  REACT_APP_API_URL_REPORT_TRIAL_BALANCE,
} from '../../../utils/configs/api.config';
import {
  DEFAULT_DOCUMENT_REPORT_EXPORT_PARAMS,
  DEFAULT_REPORT_BALANCE_SHEET_LIST_PARAMS,
  DEFAULT_REPORT_CAPITAL_CHANGE_LIST_PARAMS,
  DEFAULT_REPORT_CASH_FLOW_LIST_PARAMS,
  DEFAULT_REPORT_GENERAL_LEDGER_LIST_PARAMS,
  DEFAULT_REPORT_INCOME_STATEMENT_LIST_PARAMS,
  DEFAULT_REPORT_INDEX_LIST_PARAMS,
  DEFAULT_REPORT_JOURNAL_LIST_PARAMS,
  DEFAULT_REPORT_TRIAL_BALANCE_LIST_PARAMS,
} from '../../../utils/default/params.default';
import {
  getReportBasicConfiguration,
  REPORT_BASIC_TYPE_PURCHASE,
} from '../../../utils/default/report-basic-config.default';
import { documentReportExportUtils } from '../../../utils/default/report-type.default';
import arrHelp from '../../../utils/helpers/array.helpers';
import { authHeader } from '../../../utils/helpers/auth-header.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import strHelp from '../../../utils/helpers/string.helpers';
import {
  creatorAddService,
  creatorListService,
  creatorListServiceV2,
} from '../creator-services/creator-services.helper';

// service document report export
// params minimum is type, for getting url
// download on here tho
const documentReportExport = async (paramsDocumentReportExport) => {
  const { reportType, isDownloadUsage } = paramsDocumentReportExport;

  if (isDownloadUsage) {
    paramsDocumentReportExport = pick(paramsDocumentReportExport, ['link']);

    if (paramsDocumentReportExport) {
      return await creatorListService(
        REACT_APP_API_URL_REPORT_DOWNLOAD,
        paramsDocumentReportExport,
        DEFAULT_DOCUMENT_REPORT_EXPORT_PARAMS,
        {},
      );
    }
  }

  let urlAPI = documentReportExportUtils[0].url;

  if (reportType) {
    const selectedExportDocumentReportUtil = arrHelp.filterObjDataWithID(
      documentReportExportUtils,
      'type',
      reportType,
    );

    if (!isEmpty(selectedExportDocumentReportUtil)) {
      urlAPI = selectedExportDocumentReportUtil.url;
    }
  }

  paramsDocumentReportExport = pick(
    paramsDocumentReportExport,
    DEFAULT_DOCUMENT_REPORT_EXPORT_PARAMS,
  );

  return await creatorAddService(urlAPI, paramsDocumentReportExport);
};

// service listing report index
const getListReportIndex = async (paramsGetListReportIndex) => {
  return await creatorListService(
    REACT_APP_API_URL_REPORT,
    paramsGetListReportIndex,
    DEFAULT_REPORT_INDEX_LIST_PARAMS,
  );
};

// service listing report journal
const getReportJournalList = async (paramsGetReportJournalList) => {
  return await creatorListService(
    REACT_APP_API_URL_REPORT_JOURNAL,
    paramsGetReportJournalList,
    DEFAULT_REPORT_JOURNAL_LIST_PARAMS,
  );
};

// service listing report income statement
const getReportIncomeStatementList = async (paramsGetReportIncomeStatementList) => {
  return await creatorListService(
    REACT_APP_API_URL_REPORT_INCOME_STATEMENT,
    paramsGetReportIncomeStatementList,
    DEFAULT_REPORT_INCOME_STATEMENT_LIST_PARAMS,
    {},
  );
};

// service list report balance
const getReportBalanceSheetList = async (paramsGetReportBalanceSheetList) => {
  return await creatorListService(
    REACT_APP_API_URL_REPORT_BALANCE_SHEET,
    paramsGetReportBalanceSheetList,
    DEFAULT_REPORT_BALANCE_SHEET_LIST_PARAMS,
    {},
  );
};

// service list report capital change
const getReportCapitalChangeList = async (paramsGetReportCapitalChangeList) => {
  return await creatorListService(
    REACT_APP_API_URL_REPORT_CAPITAL_CHANGE,
    paramsGetReportCapitalChangeList,
    DEFAULT_REPORT_CAPITAL_CHANGE_LIST_PARAMS,
    {},
  );
};

// service list report cash flow
const getReportCashFlowList = async (paramsGetReportCashFlowList) => {
  return await creatorListService(
    REACT_APP_API_URL_REPORT_CASH_FLOW,
    paramsGetReportCashFlowList,
    DEFAULT_REPORT_CASH_FLOW_LIST_PARAMS,
    {},
  );
};

// service list report general ledger
const getReportGeneralLedgerList = async (paramsGetReportGeneralLedgerList) => {
  paramsGetReportGeneralLedgerList = objHelper.changeFormatValueV2(
    paramsGetReportGeneralLedgerList,
    ['from_date', 'to_date'],
    null,
    (value) => moment(value).toISOString(false),
  );

  return await creatorListServiceV2({
    endpoint: REACT_APP_API_URL_REPORT_GENERAL_LEDGER,
    paramsGetList: paramsGetReportGeneralLedgerList,
    allowParams: DEFAULT_REPORT_GENERAL_LEDGER_LIST_PARAMS,
    defaultParams: {},
    useEncodeParamsSerializer: true,
  });
};

// service list report trial balance
const getReportTrialBalanceList = async (paramsGetReportTrialBalanceList) => {
  return await creatorListService(
    REACT_APP_API_URL_REPORT_TRIAL_BALANCE,
    paramsGetReportTrialBalanceList,
    DEFAULT_REPORT_TRIAL_BALANCE_LIST_PARAMS,
    {},
  );
};

// getting on report basic list, look on configuration default report basic
const getReportBasicList = async ({
  reportBasicType = REPORT_BASIC_TYPE_PURCHASE,
  ...paramsSearchReportBasic
}) => {
  const {
    url: urlProps,
    useTemplateURLapi = false,
    allowSearchParamsList,
    defaultSearchParamsList,
    defaultParamsList,
    sortSearchParams,
  } = getReportBasicConfiguration(reportBasicType);

  const url = useTemplateURLapi
    ? strHelp.templateString(urlProps, paramsSearchReportBasic, true)
    : urlProps;

  paramsSearchReportBasic = pick(
    {
      ...defaultParamsList,
      ...paramsSearchReportBasic,
    },
    allowSearchParamsList,
  );

  if (!sortSearchParams || !paramsSearchReportBasic[sortSearchParams]) {
    return await creatorListService(
      url,
      paramsSearchReportBasic,
      allowSearchParamsList,
      defaultSearchParamsList,
    );
  }

  const { Authorization } = authHeader();

  if (!Authorization) return null;

  return await axios({
    method: 'GET',
    url: url,
    headers: {
      Authorization,
    },
    params: paramsSearchReportBasic,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  });
};

const reportServices = {
  documentReportExport,
  getListReportIndex,
  getReportJournalList,
  getReportIncomeStatementList,
  getReportBalanceSheetList,
  getReportCapitalChangeList,
  getReportCashFlowList,
  getReportGeneralLedgerList,
  getReportTrialBalanceList,
  getReportBasicList,
};

export default reportServices;
