// initial data and validaiton for base contact data

import validation from '../../helpers/validation.helpers';

export const initialProfileData = {
  contact_code: '',
  contact_name: '',
  phone_number: '',
  email: '',
  is_customer: false,
  is_supplier: true,
  is_employee: false,
  is_other: false,
  npwp_number: '',
  company_name: '',
  company_type: '',
  company_type_container: null,
  tag: [],
  tagContainer: [],
  tagNameInput: '',
};

// initial data profile on contact for menu sales
export const initialProfileDataForSalesOnly = {
  ...initialProfileData,
  is_customer: true,
  is_supplier: false,
};

export const allowKeysProfileData = [
  'contact_code',
  'contact_name',
  'phone_number',
  'email',
  'is_customer',
  'is_supplier',
  'is_employee',
  'is_other',
  'npwp_number',
  'company_name',
  'company_type',
  'tag',
];

export const initialProfileValidation = {
  contact_name: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  phone_number: {
    error: false,
    isValid: (value) => validation.nullableButSometimes(value, validation.isNumberPhone),
  },
  email: {
    error: false,
    isValid: (value) => validation.nullableButSometimes(value, validation.isEmail),
  },
  is_customer: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  is_employee: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  is_other: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  npwp_number: {
    error: false,
    isValid: (value) => validation.nullableButSometimes(value, validation.isNPWPNumber),
  },
  company_name: {
    error: false,
    isValid: (value) => validation.nullableButSometimes(value, validation.notnull),
  },
  company_type: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  tag: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const initialOtherData = {
  billing_address: '',
  billing_province_id: '',
  billing_province_id_container: null,
  billing_city: '',
  billing_city_container: null,
  billing_sub_district: '',
  billing_sub_district_container: null,
  billing_urban: '',
  billing_urban_container: null,
  billing_postal_code_id: '',
  billing_postal_code_id_container: null,
  billing_postal_code: '',
  billing_postal_code_container: null,
  is_shipping_same: false,
  shipping_address: '',
  shipping_province_id: '',
  shipping_province_id_container: null,
  shipping_city: '',
  shipping_city_container: null,
  shipping_sub_district: '',
  shipping_sub_district_container: null,
  shipping_urban: '',
  shipping_urban_container: null,
  shipping_postal_code_id: '',
  shipping_postal_code_id_container: null,
  shipping_postal_code: '',
  shipping_postal_code_container: null,
  account_receivable_id: '',
  account_receivable_id_container: null,
  account_payable_id: '',
  account_payable_id_container: null,
  payment_term: '',
  payment_term_container: null,
};

export const allowKeysOtherData = [
  'billing_address',
  'billing_province_id',
  'billing_province_name',
  'billing_province_code',
  'billing_city',
  'billing_sub_district',
  'billing_urban',
  'billing_postal_code_id',
  'billing_postal_code',
  'is_shipping_same',
  'shipping_address',
  'shipping_province_id',
  'shipping_province_name',
  'shipping_province_code',
  'shipping_city',
  'shipping_sub_district',
  'shipping_urban',
  'shipping_postal_code_id',
  'shipping_postal_code',
  'account_receivable_id',
  'account_payable_id',
  'payment_term',
  'billing_postal_code_address',
  'shipping_postal_code_address',
  'account_receivable',
  'account_payable',
];

export const initialOtherValidaton = {
  billing_address: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  billing_province_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  billing_city: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  billing_sub_district: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  billing_urban: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  billing_postal_code_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  billing_postal_code: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  is_shipping_same: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  shipping_address: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  shipping_province_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  shipping_city: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  shipping_sub_district: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  shipping_urban: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  shipping_postal_code_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  shipping_postal_code: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  account_receivable_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  account_payable_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  payment_term: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const initialBankData = {
  bank_name: '',
  bank_id: '',
  bank_id_container: null,
  branch_office: '',
  account_number: '',
  account_number_name: '',
};

export const allowKeysBankData = [
  'bank_name',
  'bank_id',
  'branch_office',
  'account_number',
  'account_number_name',
];

export const initialBankValidation = {
  bank_name: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  bank_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  branch_office: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  account_number: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  account_number_name: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const initialProductListData = {
  product_name: '',
};

export const initialProductListValidation = {
  product_name: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const allowKeysProductData = ['product_name'];

export const allowKeysDataContact = [
  'contact_name',
  'contact_code',
  'phone_number',
  'email',
  'is_customer',
  'is_supplier',
  'is_employee',
  'is_other',
  'npwp_number',
  'company_name',
  'billing_address',
  'billing_province_id',
  'billing_postal_code_id',
  'billing_postal_code',
  'is_shipping_same',
  'shipping_address',
  'shipping_province_id',
  'shipping_postal_code_id',
  'shipping_postal_code',
  'account_receivable_id',
  'account_payable_id',
  'payment_term',
  'bank',
  'company_type',
  'products',
  'tag',
];
