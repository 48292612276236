import * as React from 'react';
function SvgOncall(props) {
  return (
    <svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fill='#fff' fillOpacity={0.01} d='M0 0h24v24H0z' />
      <path
        d='M9.092 2c1.317 0 2.353 1.111 2.244 2.408L11.1 7.227a2.186 2.186 0 01-2.188 1.99h-.026c-.114.862-.171 1.73-.171 2.59v.172c0 .921.066 1.861.197 2.805 1.142 0 2.093.866 2.188 1.99l.237 2.818C11.446 20.89 10.41 22 9.092 22h-1.63c-1.375 0-2.624-.908-3.109-2.259A22.81 22.81 0 013 11.98v-.171c0-2.61.453-5.161 1.349-7.581C4.849 2.874 6.064 2 7.446 2h1.646zm.001 2.222H7.446c-.425 0-.811.301-.983.767a19.568 19.568 0 00-1.213 6.819v.17c0 2.398.405 4.744 1.206 6.973.188.521.576.827 1.007.827h1.63l-.233-2.771H7.007l-.165-.915a23.082 23.082 0 01-.378-4.113v-.171c0-1.297.117-2.608.35-3.897l.164-.917H8.86l.233-2.772zm9.453 6.111c.931 0 1.687.747 1.687 1.667 0 .921-.756 1.667-1.687 1.667A1.678 1.678 0 0116.858 12c0-.92.756-1.667 1.688-1.667zm-4.5 0c.931 0 1.687.747 1.687 1.667 0 .921-.756 1.667-1.687 1.667A1.678 1.678 0 0112.358 12c0-.92.756-1.667 1.688-1.667z'
        fill='#42526E'
      />
    </svg>
  );
}
export default SvgOncall;
