import {
  REACT_APP_API_URL_EXPENSE,
  REACT_APP_API_URL_EXPENSE_UPLOAD,
  REACT_APP_API_URL_EXPENSE_UPLOAD_PROGRESS,
} from '../../../utils/configs/api.config';
import { DEFAULT_FILE_TYPE_CSV } from '../../../utils/default/file-type.default';
import { DEFAULT_EXPENSE_LIST_PARAMS } from '../../../utils/default/params.default';
import {
  creatorAddService,
  creatorDeleteService,
  creatorDetailService,
  creatorDetailServiceV2,
  creatorListService,
  creatorMultipartFormDataService,
  creatorUpdateService,
} from '../creator-services/creator-services.helper';

// expense list
const getExpenseList = async (paramsGetExpenseList) => {
  return await creatorListService(
    REACT_APP_API_URL_EXPENSE,
    paramsGetExpenseList,
    DEFAULT_EXPENSE_LIST_PARAMS,
  );
};

// expense detail
const getExpenseDetail = async (expenseID) => {
  return await creatorDetailService(REACT_APP_API_URL_EXPENSE, expenseID);
};

// delete expense
const deleteExpense = async (paramsDeleteExpense) => {
  const { expense_id } = paramsDeleteExpense;

  return await creatorDeleteService(expense_id, REACT_APP_API_URL_EXPENSE);
};

// add expense
const addExpense = async (bodyAddExpense) => {
  return await creatorAddService(REACT_APP_API_URL_EXPENSE, bodyAddExpense, null);
};

const importExpense = async (bodyData) => {
  return await creatorMultipartFormDataService({
    endpoint: REACT_APP_API_URL_EXPENSE_UPLOAD,
    bodyData,
    grabToNewFormData: true,
    formDataKeyname: DEFAULT_FILE_TYPE_CSV,
  });
};

const getImportExpenseProgress = async (params) => {
  return await creatorDetailServiceV2(REACT_APP_API_URL_EXPENSE_UPLOAD_PROGRESS, params);
};

// update expense
const updateExpense = async (bodyUpdateExpense) => {
  const { expense_id } = bodyUpdateExpense;

  return await creatorUpdateService(expense_id, REACT_APP_API_URL_EXPENSE, bodyUpdateExpense, null);
};

const expenseServices = {
  getExpenseList,
  getExpenseDetail,
  deleteExpense,
  addExpense,
  importExpense,
  getImportExpenseProgress,
  updateExpense,
};

export default expenseServices;
