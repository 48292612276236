import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Chip,
  IconButton,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { isEmpty } from 'lodash';

import BoxActionPermission from '../../../components/box/BoxActionPermission/BoxActionPermission';
import BoxTableNotFound from '../../../components/box/BoxTableNotFound/BoxTableNotFound';
import TablePaginationMain from '../../../components/table-pagination/TablePaginationMain/TablePaginationMain';
import TableRowLoading from '../../../components/table-row/TableRowLoading/TableRowLoading';
import TypographyLinkPrimary from '../../../components/typography/TypographyLinkPrimary/TypographyLinkPrimary';
import { withRouter } from '../../../components/withRouter/withRouter';
import { decryptData, encryptData } from '../../../services/modules/Crypto/Crypto.service';
import messageHelper from '../../../store/message/message.helper';
import { getListSalesPayment } from '../../../store/sales/sales.action';
import {
  REACT_APP_SALES_SALES_PAYMENT_CREATE_NEW_URL,
  REACT_APP_SALES_SALES_PAYMENT_DETAILS_TEMPLATE_URL,
} from '../../../utils/configs/url.config';
import { STATUS_REQUEST_LIST_SALES_PAYMENT_PENDING } from '../../../utils/constants/store.constant';
import {
  calculationColumns,
  columnsTableHeaderSalesPayment,
} from '../../../utils/data/display/table-list-sales-payment';
import {
  iconSalesPaymentList,
  tooltipSalesPayment,
} from '../../../utils/data/display/tooltip-sales-payment';
import {
  DEFAULT_SALES_PAYMENT_SEARCH_FE_PARAMS,
  TERSIER_DEFAULT_LIST_PARAMS,
} from '../../../utils/default/params.default';
import formatHelp from '../../../utils/helpers/format.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import strHelp from '../../../utils/helpers/string.helpers';

import TableHeadSearchSalesPayment from './TableHeadSearchSalesPayment/TableHeadSearchSalesPayment';
import { styles } from './SalesPaymentList.styles';

class SalesPaymentList extends Component {
  constructor(props) {
    super(props);

    const { handleSelectedStatusPage } = props;

    this.isHandleSelectedStatusPageFunction = typeof handleSelectedStatusPage === 'function';

    this.state = {
      rowsPerPage: TERSIER_DEFAULT_LIST_PARAMS.paginate,
    };
    this.getSearchParamsSalesPayment = this.getSearchParamsSalesPayment.bind(this);

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

    this.handleClickActionSalesPayment = this.handleClickActionSalesPayment.bind(this);
  }

  handleClickActionSalesPayment(event) {
    const { name: newStatusPage, value } = event.currentTarget;

    const decryptedValue = decryptData(value);

    if (this.isHandleSelectedStatusPageFunction) {
      this.props.handleSelectedStatusPage(newStatusPage, decryptedValue);
    }
  }

  getSearchParamsSalesPayment() {
    const { sales } = this.props;
    const { salesPaymentData } = sales;

    let searchParamsSalesPayment = objHelper.filterKeyObj(
      salesPaymentData,
      [],
      DEFAULT_SALES_PAYMENT_SEARCH_FE_PARAMS,
    );

    if (!isEmpty(searchParamsSalesPayment)) {
      searchParamsSalesPayment = objHelper.changeSuffixKey(
        searchParamsSalesPayment,
        'search_',
        true,
      );
    }

    return searchParamsSalesPayment;
  }

  async handleChangePage(event, newPage) {
    event.preventDefault();

    const { sales } = this.props;
    const { salesPaymentData } = sales;

    if (!salesPaymentData) {
      return;
    }

    const { next_page_url, current_page, per_page: paginate } = salesPaymentData;

    if (!next_page_url && current_page < newPage + 1) {
      return;
    }

    let searchParamsSalesPayment = this.getSearchParamsSalesPayment();

    await this.props.getListSalesPayment({
      paginate,
      page: newPage + 1,
      ...searchParamsSalesPayment,
    });
  }

  async handleChangeRowsPerPage(event) {
    const { value: newRowsPerPage } = event.target;

    const { t, sales } = this.props;

    const { salesPaymentData } = sales;

    if (!salesPaymentData) {
      return;
    }

    const { next_page_url, current_page, per_page: paginate } = salesPaymentData;

    if (Number(paginate) === Number(newRowsPerPage)) {
      return;
    }

    if (!next_page_url && paginate < newRowsPerPage && current_page === 1) {
      this.props.setMessageFailed(
        t('dashboard.sales.sales-payment.message.max-data.primary'),
        t('dashboard.sales.sales-payment.message.max-data.secondary'),
      );

      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      rowsPerPage: newRowsPerPage,
    }));

    let searchParamsSalesPayment = this.getSearchParamsSalesPayment();

    await this.props.getListSalesPayment({
      paginate: newRowsPerPage,
      ...searchParamsSalesPayment,
    });
  }

  async componentDidMount() {
    const { rowsPerPage: paginate } = this.state;

    await this.props.getListSalesPayment({
      paginate,
      isShowMessage: false,
    });
  }

  render() {
    const { rowsPerPage } = this.state;

    const { t, auth, classes, sales } = this.props;

    const { permissions } = auth.data;
    const { salesPaymentData } = sales;

    let salesPaymentList = [];

    let currPagination = rowsPerPage;

    if (salesPaymentData) {
      if (salesPaymentData.data) {
        if (Array.isArray(salesPaymentData.data)) {
          salesPaymentList = salesPaymentData.data;
        }
      }

      if (salesPaymentData.per_page) {
        currPagination = Number(salesPaymentData.per_page);
      }
    }

    const tableHeaderSalesPayment = columnsTableHeaderSalesPayment(t);

    return (
      <Box className={classes.boxContainerTable}>
        <Paper elevation={0}>
          <TableContainer className={'tableContainerConstantHeightMDSizeConstantLayoutPrimary'}>
            <Table stickyHeader className='tableMain' aria-label='table-cash-bank-list'>
              <TableHeadSearchSalesPayment dataHeaders={tableHeaderSalesPayment} />
              <TableBody className='primaryTableBody'>
                {sales.statusRequest === STATUS_REQUEST_LIST_SALES_PAYMENT_PENDING && (
                  <TableRowLoading
                    rowsAmount={rowsPerPage}
                    columnsAmount={tableHeaderSalesPayment.length}
                  />
                )}
                {salesPaymentList &&
                  sales.statusRequest !== STATUS_REQUEST_LIST_SALES_PAYMENT_PENDING &&
                  salesPaymentList.map((singleSalesPayment, index) => {
                    const baseUrl = strHelp.templateString(
                      REACT_APP_SALES_SALES_PAYMENT_DETAILS_TEMPLATE_URL,
                      singleSalesPayment,
                    );

                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        className={'hoverEntireRow'}
                        onDoubleClick={() => this.props.navigate(baseUrl)}
                      >
                        {tableHeaderSalesPayment.map((columnTableHeader, indexColumnHeader) => {
                          let valueData = singleSalesPayment[columnTableHeader.name];

                          if (columnTableHeader.name === 'action') {
                            return (
                              <TableCell
                                key={indexColumnHeader}
                                align={columnTableHeader.align}
                                className='tableCellListData'
                              >
                                {tooltipSalesPayment(t).map((tooltipData, index) => {
                                  return (
                                    <BoxActionPermission
                                      key={index}
                                      permissionsUser={permissions}
                                      permissionRequired={tooltipData.permission}
                                    >
                                      <Tooltip title={tooltipData.title}>
                                        <IconButton
                                          name={tooltipData.name}
                                          value={encryptData(JSON.stringify(singleSalesPayment))}
                                          onClick={this.handleClickActionSalesPayment}
                                          className={classes.iconButtonContainer}
                                        >
                                          <SvgIcon component={iconSalesPaymentList[index]} />
                                        </IconButton>
                                      </Tooltip>
                                    </BoxActionPermission>
                                  );
                                })}

                                {valueData}
                              </TableCell>
                            );
                          }

                          // formating date following localzone
                          if (columnTableHeader.name === 'transaction_date') {
                            valueData = formatHelp.getReadableDateV2(valueData);
                          }

                          if (columnTableHeader.name === 'sales_payment_no') {
                            return (
                              <TableCell key={indexColumnHeader}>
                                <TypographyLinkPrimary
                                  baseUrl={baseUrl}
                                  typographyValue={valueData}
                                />
                              </TableCell>
                            );
                          }

                          // formating value of nominal column
                          if (calculationColumns.includes(columnTableHeader.name)) {
                            valueData = formatHelp.currencyFormatWithRegex(valueData);
                          }

                          // container value status with Chip
                          if (columnTableHeader.name === 'status') {
                            return (
                              <TableCell key={indexColumnHeader}>
                                <Chip
                                  size='small'
                                  label={valueData}
                                  className={classes[valueData]}
                                />
                              </TableCell>
                            );
                          }

                          return (
                            <TableCell key={indexColumnHeader} align={columnTableHeader.align}>
                              {valueData}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {!salesPaymentList.length &&
              sales.statusRequest !== STATUS_REQUEST_LIST_SALES_PAYMENT_PENDING && (
                <BoxTableNotFound
                  textNotFound={t('dashboard.sales.sales-payment.table.body-not-found')}
                  textButton={t('dashboard.sales.sales-payment.button.add-sales-payment')}
                  handleClickedButton={() => {
                    this.props.navigate(REACT_APP_SALES_SALES_PAYMENT_CREATE_NEW_URL);
                  }}
                  isExistButton={true}
                />
              )}
          </TableContainer>
          <TablePaginationMain
            page={salesPaymentData ? salesPaymentData.current_page - 1 : 0}
            prev_page={salesPaymentData ? salesPaymentData.prev_page_url : null}
            next_page={salesPaymentData ? salesPaymentData.next_page_url : null}
            rowsPerPage={currPagination}
            isLoadingData={
              sales.statusRequest === STATUS_REQUEST_LIST_SALES_PAYMENT_PENDING ||
              !salesPaymentList.length
            }
            labelRowsPerPage={t('label.table.pagination.primary.rows-per-page')}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    );
  }
}

SalesPaymentList.defaultProps = {
  isUseChangedPageOnDetails: false,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    sales: state.sales,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListSalesPayment: (paramsgetListSalesPayment) =>
      dispatch(getListSalesPayment(paramsgetListSalesPayment)),
    setMessageFailed: (primaryMessage, secondaryMessage) =>
      dispatch(messageHelper.failedMessage(dispatch, primaryMessage, secondaryMessage, false)),
  };
};

// adding style into component
const stylingSalesPaymentList = withStyles(styles)(SalesPaymentList);

// adding translation into component
const SalesPaymentListTranslate = withTranslation()(stylingSalesPaymentList);

const SalesPaymentListWithRouter = withRouter(SalesPaymentListTranslate);

// connect component with action and state
const ConnectedSalesPaymentList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SalesPaymentListWithRouter);

export default ConnectedSalesPaymentList;
