import {
  REACT_APP_API_URL_ATTACHMENT,
  REACT_APP_API_URL_ATTACHMENT_DELETE,
  REACT_APP_API_URL_ATTACHMENT_DELETE_BATCH,
  REACT_APP_API_URL_ATTACHMENT_DETAILS,
} from '../../../utils/configs/api.config';
import {
  creatorDeleteServiceWithTemplateUrl,
  creatorDetailServiceV2,
  creatorMultipartFormDataService,
} from '../creator-services/creator-services.helper';

// handle service for creating attachment
const createAttachment = async (bodyCreateAttachment) => {
  return await creatorMultipartFormDataService({
    endpoint: REACT_APP_API_URL_ATTACHMENT,
    bodyData: bodyCreateAttachment,
  });
};

// handling list detail for attachment
const listDetailsAttachment = async (paramsDetailAttachment) => {
  return await creatorDetailServiceV2(REACT_APP_API_URL_ATTACHMENT_DETAILS, paramsDetailAttachment);
};

// handle service for delete attachment
const deleteAttachment = async (paramsDeleteAttachment) => {
  return await creatorDeleteServiceWithTemplateUrl(
    REACT_APP_API_URL_ATTACHMENT_DELETE,
    paramsDeleteAttachment,
  );
};

// handle service delete batch (multiple) attachment
const deleteBatchAttachment = async (paramsDeleteBatchAttachment) => {
  return await creatorDeleteServiceWithTemplateUrl(
    REACT_APP_API_URL_ATTACHMENT_DELETE_BATCH,
    paramsDeleteBatchAttachment,
  );
};

const attachmentServices = {
  createAttachment,
  listDetailsAttachment,
  deleteAttachment,
  deleteBatchAttachment,
};

export default attachmentServices;
