import * as React from 'react';
function SvgStockAdjustmentDataMenu(props) {
  return (
    <svg width={92} height={75} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#stock-adjustment-data-menu_svg__filter0_d_5543_188865)'>
        <path d='M81.1 22.19H13.091V68h68.007V22.19z' fill='#FFD15D' />
        <path d='M81.1 22.19H46.96V68H81.1V22.19z' fill='#F9B54C' />
        <path d='M84.5 15.645H9.69v13.089H84.5V15.645z' fill='#F9B54C' />
        <path d='M84.5 15.645H46.96v13.089H84.5V15.645z' fill='#F4A200' />
        <path
          d='M57.297 40.633H36.895c-1.878 0-3.4-1.465-3.4-3.272 0-1.807 1.522-3.273 3.4-3.273h20.402c1.878 0 3.4 1.466 3.4 3.273 0 1.807-1.523 3.272-3.4 3.272z'
          fill='#B97850'
        />
        <path
          d='M57.297 34.088H46.959v6.545h10.338c1.878 0 3.4-1.465 3.4-3.272 0-1.807-1.522-3.273-3.4-3.273z'
          fill='#935635'
        />
        <path
          d='M33.324 45.925H20.318c-1.34 0-2.391 1.012-2.391 2.3v12.516c0 1.289 1.052 2.301 2.39 2.301h13.007c1.339 0 2.391-1.012 2.391-2.3V48.224c0-1.196-1.052-2.3-2.39-2.3zm1.052 14.816c0 .553-.478 1.013-1.052 1.013H20.318c-.574 0-1.052-.46-1.052-1.013V48.225c0-.552.478-1.012 1.052-1.012h13.006c.574 0 1.052.46 1.052 1.012v12.516z'
          fill='#fff'
        />
        <path
          d='M24.143 50.618c-.096-.092-.096-.092-.191-.092-.096 0-.191-.092-.287-.092-.096 0-.191 0-.287.092-.096 0-.191.092-.191.092l-2.2 2.117c-.287.276-.287.644 0 .828.287.276.67.276.86 0l1.148-1.104v5.43c0 .367.287.644.67.644.383 0 .67-.276.67-.645v-5.43l1.147 1.105a.746.746 0 00.478.184c.191 0 .287-.092.478-.184.287-.276.287-.644 0-.828l-2.295-2.117zM30.455 50.618a.746.746 0 00-.478-.184.746.746 0 00-.478.184l-2.2 2.117c-.287.276-.287.644 0 .828.287.276.67.276.86 0l1.149-1.104v5.43c0 .367.286.644.669.644.383 0 .67-.276.67-.645v-5.43l1.147 1.105a.746.746 0 00.478.184c.192 0 .287-.092.478-.184.287-.276.287-.644 0-.828l-2.295-2.117zM53.312 45.925H40.305c-1.338 0-2.39 1.012-2.39 2.3v12.516c0 1.289 1.052 2.301 2.39 2.301h13.007c1.339 0 2.39-1.012 2.39-2.3V48.224c0-1.196-1.051-2.3-2.39-2.3zM54.46 60.74c0 .553-.479 1.013-1.053 1.013H40.305c-.573 0-1.052-.46-1.052-1.013V48.225c0-.552.479-1.012 1.052-1.012h13.007c.574 0 1.052.46 1.052 1.012v12.516h.096z'
          fill='#fff'
        />
        <path
          d='M49.678 49.422h-5.643c-.382 0-.67.276-.67.644v2.945c0 1.656 1.244 3.037 2.87 3.313v2.025h-.86c-.383 0-.67.276-.67.644s.287.644.67.644h3.06c.382 0 .669-.276.669-.644s-.287-.644-.67-.644h-.86v-2.025c1.626-.276 2.869-1.657 2.869-3.313v-2.945c-.096-.368-.383-.644-.765-.644zm-.67 3.589c0 1.196-.956 2.116-2.2 2.116-1.243 0-2.199-.92-2.199-2.116v-2.393h4.4v2.393zM73.3 45.925H60.293c-1.339 0-2.39 1.012-2.39 2.3v12.516c0 1.289 1.051 2.301 2.39 2.301H73.3c1.339 0 2.39-1.012 2.39-2.3V48.224c0-1.196-1.147-2.3-2.39-2.3zm1.052 14.816c0 .553-.478 1.013-1.052 1.013H60.293c-.574 0-1.052-.46-1.052-1.013V48.225c0-.552.478-1.012 1.052-1.012H73.3c.574 0 1.052.46 1.052 1.012v12.516z'
          fill='#fff'
        />
        <path
          d='M66.797 48.593c-3.156 0-5.738 2.485-5.738 5.522 0 .368.287.644.669.644h4.495v4.142c0 .184-.191.368-.383.368-.19 0-.382-.184-.382-.368 0-.368-.287-.645-.67-.645-.382 0-.67.277-.67.645 0 .92.766 1.656 1.722 1.656.957 0 1.722-.736 1.722-1.656v-4.142h4.495c.382 0 .67-.276.67-.644-.192-3.037-2.774-5.522-5.93-5.522zm-4.4 4.878c.287-2.117 2.2-3.681 4.4-3.681s4.112 1.564 4.399 3.681h-8.798z'
          fill='#fff'
        />
      </g>
      <path
        d='M29.901 14.377c0-.03-.004-.058-.004-.089C29.85 6.39 23.29 0 15.2 0 7.112 0 .551 6.394.501 14.295l-.001.082C.5 22.32 7.083 28.76 15.2 28.76c8.12 0 14.701-6.439 14.701-14.382zm-27.303 0c0-6.793 5.654-12.323 12.601-12.323v24.651c-6.947 0-12.6-5.53-12.6-12.328z'
        fill='#273B7A'
      />
      <path
        d='M11.67 13.402h-1.433v-1.38c0-.54-.456-.978-1.017-.978-.56 0-1.016.438-1.016.978v1.38H6.77c-.561 0-1.017.438-1.017.978s.456.978 1.017.978h1.434v1.38c0 .54.456.978 1.016.978.561 0 1.016-.439 1.016-.978v-1.38h1.435c.56 0 1.016-.439 1.016-.978 0-.54-.455-.978-1.016-.978z'
        fill='#273B7A'
      />
      <path
        d='M24.82 13.575h-4.9c-.561 0-1.017.464-1.017 1.035s.456 1.035 1.017 1.035h4.9c.562 0 1.017-.464 1.017-1.035s-.455-1.035-1.017-1.035z'
        fill='#fff'
      />
      <defs>
        <filter
          id='stock-adjustment-data-menu_svg__filter0_d_5543_188865'
          x={8.691}
          y={14.645}
          width={82.809}
          height={60.355}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5543_188865' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5543_188865' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgStockAdjustmentDataMenu;
