import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import {
  primaryParagraph,
  primaryTableKeyValueTable,
  primaryTableKeyValueTableHead,
  primaryTableKeyValueTableHeadLastOrder,
  secondaryTableData,
  secondaryTableDataSingleDividerTableHeadTableBody,
  typographyBreakAllWordBreak,
} from '../../../utils/data/display/additional-styles';
import formatHelp from '../../../utils/helpers/format.helpers';
import strHelp from '../../../utils/helpers/string.helpers';

export const FORMAT_PLAIN_COLUMN = 'plain';
export const FORMAT_DATE_COLUMN = 'date';
export const FORMAT_PERIOD_DATE_COLUMN = 'period-date';
export const FORMAT_TEMPLATE_COLUMN = 'template';
export const FORMAT_MAPPING_TRANSLATION_COLUMN = 'mapping-translation';
export const FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN = 'calculation-number-auto-comma';

/**
 * @prop        { object }      data                data that provide for columns table key
 * @prop        { array }       columnsTableBody    column table body for representation data, and getting header and value correnpond on single object each items array
 *      each item of columnsTableBody contain:
 *          - header                header not already translate string
 *          - headerTranslated      translated string for header
 *          - valueKeyName          key name that getting from data, where representation for value, on right side
 *
 *      additional:
 *          - formatValue               { string }      auto format data when use this, manually only for representation plain
 *                                                      existed format data: 'plain', 'date', 'calculation-number-auto-comma'
 *
 *          - substituteNotExistValue   { string }      change on subtitute when data not exist or null
 *                  default '-'
 *
 *
 */
class TableKeyValuePureMarkup extends Component {
  render() {
    const {
      t,
      i18n,
      data,
      columnsTableBody,
      tableStyle = {},
      nonLastTableHeadStyle = {},
      lastTableHeadStyle = {},
      tableDividerStyle = {},
      secondaryTableDataStyle = {},
    } = this.props;

    if (isEmpty(data) || Array.isArray(data) || typeof data !== 'object') return null;

    return (
      <table
        style={{
          ...primaryTableKeyValueTable,
          ...tableStyle,
        }}
      >
        <tbody>
          {Array.isArray(columnsTableBody) &&
            columnsTableBody.map((singleColumnTableBody, indexColumn) => {
              const isFirstIndex = indexColumn === 0;
              const isLastIndex = indexColumn === columnsTableBody.length - 1;

              const {
                header,
                headerTranslated,
                valueKeyName,
                valueKeyNameSecondary,
                formatValue = null,
                substituteNotExistValue = '-',
                template,
                showOnlyWhenExistedValue = false,
                mappingTranslation = {},
              } = singleColumnTableBody;

              const headerView = header ? t(header) : headerTranslated;

              if (
                showOnlyWhenExistedValue &&
                ((Array.isArray(data[valueKeyName]) && isEmpty(data[valueKeyName])) ||
                  !data[valueKeyName])
              )
                return null;

              let valueView = data[valueKeyName] || substituteNotExistValue;
              if (formatValue === FORMAT_DATE_COLUMN) {
                const isDateValid = !Number.isNaN(new Date(valueView).getTime());

                valueView = isDateValid ? formatHelp.getReadableDateV2(valueView) : valueView;
              }

              if (formatValue === FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN) {
                const isNumberValid = !Number.isNaN(Number(valueView));

                valueView = isNumberValid ? formatHelp.currencyWithAutoComma(Number(valueView)) : 0;
              }

              if (formatValue === FORMAT_TEMPLATE_COLUMN) {
                valueView = strHelp.templateString(template, data, true);
              }

              if (formatValue === FORMAT_PERIOD_DATE_COLUMN) {
                valueView = formatHelp.getReadableRangeDate(
                  data[valueKeyName],
                  data[valueKeyNameSecondary],
                  i18n.language,
                );
              }

              if (
                formatValue === FORMAT_MAPPING_TRANSLATION_COLUMN &&
                !isEmpty(mappingTranslation) &&
                Array.isArray(data[valueKeyName])
              ) {
                valueView = data[valueKeyName].map((keyname) => {
                  if (!Object.keys(mappingTranslation).includes(keyname)) return '';

                  return t(mappingTranslation[keyname]);
                });

                valueView = !isEmpty(valueView) ? valueView.join(', ') : '';
              }

              return (
                <tr key={indexColumn}>
                  <th
                    style={
                      !isLastIndex
                        ? !isEmpty(nonLastTableHeadStyle)
                          ? nonLastTableHeadStyle
                          : primaryTableKeyValueTableHead
                        : !isEmpty(lastTableHeadStyle)
                        ? lastTableHeadStyle
                        : primaryTableKeyValueTableHeadLastOrder
                    }
                  >
                    {headerView}
                  </th>
                  {isFirstIndex ? (
                    <td
                      rowSpan={columnsTableBody.length}
                      style={{
                        ...secondaryTableDataSingleDividerTableHeadTableBody,
                        ...tableDividerStyle,
                      }}
                    />
                  ) : null}
                  <td
                    style={{
                      ...secondaryTableData,
                      ...secondaryTableDataStyle,
                    }}
                  >
                    <p
                      style={{
                        ...primaryParagraph,
                        ...typographyBreakAllWordBreak,
                      }}
                    >
                      {valueView}
                    </p>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  }
}

const TableKeyValuePureMarkupWithTranslation = withTranslation()(TableKeyValuePureMarkup);

export default TableKeyValuePureMarkupWithTranslation;
