import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import { DEFAULT_DISCOUNT_PERCENTAGE_TYPE } from '../../default/discount-type.default';
import {
  hasImplementedUnitProductUnit,
  keynamesImpelementProductUnit,
} from '../../default/unit-product.default';
import validation from '../../helpers/validation.helpers';
import { DEFAULT_CALCULATION_NUMBER } from '../default/number.default';

const initialID = uuidV4();

/**
 * quantity is value from user input
 * quantity origin, value that changes from unit
 * quantity comparison, comparison from quantity with quantity origin
 * quantity_comparison_const_props, appear for update action,
 *  usage: preserve quantity comparison data
 */
export const plainProductQuotationRequestDataWithoutID = {
  product_id: null,
  product_id_container: null,
  product_description: '',
  product_name: '',
  product_unit: '',
  quantity: '',
  unit: '',
  quantity_origin: '',
  quantity_comparison: 1,
  unit_origin: '',
  unit_destination: '',
  price: '',
  price_amount: 0,
  amount: DEFAULT_CALCULATION_NUMBER,
  is_new_product: false,
  new_product: null,
  quantity_available: 0,
  quantity_comparison_const_props: null,
};

export const initialDataProduct = (additionalID = '', newID = '') => {
  const usedID = newID ? newID : initialID + additionalID;

  return {
    id: usedID,
    ...plainProductQuotationRequestDataWithoutID,
  };
};

export const plainProductQuotationRequestValidationWithoutID = {
  product_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  product_description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  quantity: {
    error: false,
    isValid: (value) => validation.isNumberGreaterOrEqualThan(value, 1),
  },
  unit_destination: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  price: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const initialValidationProduct = (additionalID = '', newID = '') => {
  const usedID = newID ? newID : initialID + additionalID;

  return {
    id: usedID,
    ...plainProductQuotationRequestValidationWithoutID,
  };
};

export const plainQuotationRequestDataWithoutID = {
  supplier_id: null,
  supplier_name: '',
  supplier_id_container: null,
  supplier_address: '',
  transaction_date: moment(),
  expired_date: null,
  reference_number: '',
  reference_number_container: null,
  description: '',
  uuid_attachment: null,
  attachment: [],
  is_new_ref_number: false,
  new_ref_number: null,
  is_new_supplier: false,
  new_supplier: null,
  minAccountingPeriod: null,
  amount: 0,
  amount_const: 0,
  accounting_period: null,
  discount_account_id: '',
  discount_account_id_container: '',
  discount_type: DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
  discount_percentage: '',
  discount_nominal: '',
  expense_account_id: '',
  expense_account_id_container: '',
  expense_value: '',
  down_payment_account_id: '',
  down_payment_account_id_container: '',
  down_payment_value: '',
  on_behalf: '',
  minDateInputRef: null,
  tag: [],
  tagContainer: [],
  tagNameInput: '',
};

export const initialDataQuotationRequest = (additionalID = '', newID = '') => {
  const usedID = newID ? newID : initialID + additionalID;

  return {
    id: usedID,
    ...plainQuotationRequestDataWithoutID,
  };
};

export const plainQuotationRequestValidationWithoutID = {
  supplier_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  supplier_address: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  transaction_date: {
    error: false,
    isValid: (value, minDateInputRef) => {
      if (!minDateInputRef) {
        return validation.notnull(value);
      }

      return moment(new Date(minDateInputRef)).isSameOrBefore(new Date(value), 'D');
    },
  },
  expired_date: {
    error: false,
    isValid: (value, transactionDate) => {
      if (!value) {
        return validation.nullable(value);
      }

      return moment(new Date(value)).isSameOrAfter(new Date(transactionDate), 'D');
    },
  },
  reference_number: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },

  discount_type: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  discount_percentage: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  discount_nominal: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  discount_account_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  expense_value: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  expense_account_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  down_payment_account_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  down_payment_value: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  on_behalf: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const initialValidationQuotationRequest = (additionalID = '', newID = '') => {
  const usedID = newID ? newID : initialID + additionalID;

  return {
    id: usedID,
    ...plainQuotationRequestValidationWithoutID,
  };
};

export const allowKeysDataProduct = ['product_id', 'product_description', 'quantity', 'price'];

export const allowKeysDataProductQuotationRequest = [
  'product_id',
  'product_description',
  'quantity',
  'price',
  'unit_destination',
  'unit_origin',
  'quantity_origin',
].filter((key) =>
  !hasImplementedUnitProductUnit ? !keynamesImpelementProductUnit.includes(key) : true,
);

export const allowKeysDataQuotationRequest = [
  'supplier_id',
  'supplier_name',
  'supplier_address',
  'reference_number',
  'transaction_date',
  'expired_date',
  'description',
  'product',
  'uuid_attachment',
  'discount_account_id',
  'discount_type',
  'discount_percentage',
  'discount_nominal',
  'expense_account_id',
  'expense_value',
  'down_payment_account_id',
  'down_payment_value',
  'on_behalf',
  'warehouse_id',
  'tag',
];

export const purchaseQuotationRequestCalculationNumberKeyNames = [
  'discount_nominal',
  'discount_percentage',
  'expense_value',
  'down_payment_value',
  'amount',
];
