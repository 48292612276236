import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_MANAGEMENT_COMPANIES } from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  STATUS_REQUEST_ADD_MANAGEMENT_COMPANY_FAILED,
  STATUS_REQUEST_ADD_MANAGEMENT_COMPANY_PENDING,
  STATUS_REQUEST_ADD_MANAGEMENT_COMPANY_SUCCESS,
  STATUS_REQUEST_BASE_FAILED,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_BASE_PENDING,
  STATUS_REQUEST_BASE_SUCCESS,
  STATUS_REQUEST_DELETE_MANAGEMENT_COMPANY_FAILED,
  STATUS_REQUEST_DELETE_MANAGEMENT_COMPANY_PENDING,
  STATUS_REQUEST_DELETE_MANAGEMENT_COMPANY_SUCCESS,
  STATUS_REQUEST_UPDATE_MANAGEMENT_COMPANY_FAILED,
  STATUS_REQUEST_UPDATE_MANAGEMENT_COMPANY_PENDING,
  STATUS_REQUEST_UPDATE_MANAGEMENT_COMPANY_SUCCESS,
} from '../../utils/constants/store.constant';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import {
  addCompany,
  deleteCompanyManagement,
  listCompany,
  updateCompany,
} from './management-company.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  companies: LocalStorage.get(LOCAL_STORAGE_MANAGEMENT_COMPANIES),
};

const managementCompanySlice = createSlice({
  name: NAME_STORE.MANAGEMENT_COMPANY,
  initialState,
  reducers: {
    listCompany,
    addCompany,
    deleteCompanyManagement,
    clearManagementCompany: (state, action) => {
      LocalStorage.remove(LOCAL_STORAGE_MANAGEMENT_COMPANIES);

      if (action && action.payload && action.payload.removeLocalStorageOnly) return state;

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        companies: null,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      companies: LOCAL_STORAGE_MANAGEMENT_COMPANIES,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(listCompany.fulfilled, (state, action) => {
      state.statusRequest = STATUS_REQUEST_BASE_SUCCESS;
      state.companies = action.payload.companies;
    }),
      builder.addCase(listCompany.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_BASE_PENDING;
      }),
      builder.addCase(listCompany.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_BASE_FAILED;
      }),
      builder.addCase(addCompany.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_MANAGEMENT_COMPANY_SUCCESS;
      }),
      builder.addCase(addCompany.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_MANAGEMENT_COMPANY_PENDING;
      }),
      builder.addCase(addCompany.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_MANAGEMENT_COMPANY_FAILED;
      }),
      builder.addCase(updateCompany.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_MANAGEMENT_COMPANY_SUCCESS;
      }),
      builder.addCase(updateCompany.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_MANAGEMENT_COMPANY_PENDING;
      }),
      builder.addCase(updateCompany.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_MANAGEMENT_COMPANY_FAILED;
      }),
      builder.addCase(deleteCompanyManagement.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_MANAGEMENT_COMPANY_SUCCESS;
      }),
      builder.addCase(deleteCompanyManagement.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_MANAGEMENT_COMPANY_PENDING;
      }),
      builder.addCase(deleteCompanyManagement.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_MANAGEMENT_COMPANY_FAILED;
      });
  },
});

export const { actions, reducer } = managementCompanySlice;

export const { clearManagementCompany } = actions;

export default reducer;
