import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import { DEFAULT_DISCOUNT_PERCENTAGE_TYPE } from '../../default/discount-type.default';
import {
  hasImplementedUnitProductUnit,
  keynamesImpelementProductUnit,
} from '../../default/unit-product.default';
import validation from '../../helpers/validation.helpers';

const initialID = uuidV4();

export const plainPurchaseDataWithoutID = {
  supplier_id: null,
  supplier_id_container: null,
  isNewSupplier: false,
  supplier_name: '',
  supplier_address: '',
  transaction_date: moment(),
  expired_date: null,
  reference_number: '',
  reference_number_container: null,
  quotation_request_id: null,
  quotation_request_id_container: null,
  description: '',
  minAccountingPeriod: null,
  amount: 0,
  amount_const: 0,
  accounting_period: null,
  discount_account_id: '',
  discount_account_id_container: '',
  discount_type: DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
  discount_percentage: '',
  discount_nominal: '',
  expense_account_id: '',
  expense_account_id_container: '',
  expense_value: '',
  down_payment_account_id: '',
  down_payment_account_id_container: '',
  down_payment_value: '',
  on_behalf: '',
  warehouse_id: '',
  warehouse_id_container: null,
  attachment: [],
  uuid_attachment: null,
  minDateInputRef: null,
  tag: [],
  tagContainer: [],
  tagNameInput: '',
};

export const initialPurchaseData = (additionalID = '', newID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainPurchaseDataWithoutID,
  };
};

export const plainPurchaseValidationWithoutID = {
  supplier_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  supplier_address: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  transaction_date: {
    error: false,
    isValid: (value, minDateInputRef) => {
      if (!minDateInputRef) {
        return validation.notnull(value);
      }

      return moment(new Date(minDateInputRef)).isSameOrBefore(new Date(value), 'D');
    },
  },
  expired_date: {
    error: false,
    isValid: (value, transaction_date) => {
      if (!value || !transaction_date) {
        return validation.nullable(value);
      }

      return moment(new Date(transaction_date)).isBefore(new Date(value), 'D');
    },
  },
  reference_number: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  quotation_request_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  amount: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  product_description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },

  discount_type: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  discount_percentage: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  discount_nominal: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  discount_account_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  expense_value: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  expense_account_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  down_payment_account_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  down_payment_value: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  on_behalf: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const initialPurchaseValidation = (additionalID = '', newID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainPurchaseValidationWithoutID,
  };
};

/**
 * quantity comparison, comparison from quantity with quantity origin
 * quantity_comparison_const_props, appear for update action,
 *  usage: preserve quantity comparison data
 */
export const plainProductDataWithoutID = {
  product_id: null,
  product_id_container: {},
  isNewProduct: false,
  quantity: '',
  quantity_origin: '',
  quantity_available: 0,
  quantity_comparison: 1,
  quantity_comparison_const_props: null,
  product_unit: '',
  unit: '',
  unit_origin: '',
  unit_destination: '',
  amount: '0.00',
  price: '',
  product_description: '',
};

export const initialProductData = (additionalID = '', newID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainProductDataWithoutID,
  };
};

export const plainProductValidationWithoutID = {
  product_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  quantity: {
    error: false,
    isValid: (value) => validation.isNumberGreaterOrEqualThan(value, 1),
  },
  unit: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  unit_destination: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  amount: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  price: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  product_description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const initialProductValidation = (additionalID = '', newID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainProductValidationWithoutID,
  };
};

export const allowKeysPurchaseData = [
  'supplier_id',
  'supplier_address',
  'transaction_date',
  'expired_date',
  'reference_number',
  'quotation_request_id',
  'description',
  'uuid_attachment',
  'discount_account_id',
  'discount_type',
  'discount_percentage',
  'discount_nominal',
  'expense_account_id',
  'expense_value',
  'down_payment_account_id',
  'down_payment_value',
  'on_behalf',
];

export const allowKeysPurchaseOrderData = [
  'supplier_id',
  'supplier_name',
  'supplier_address',
  'transaction_date',
  'expired_date',
  'reference_number',
  'quotation_request_id',
  'description',
  'uuid_attachment',
  'discount_account_id',
  'discount_type',
  'discount_percentage',
  'discount_nominal',
  'expense_account_id',
  'expense_value',
  'down_payment_account_id',
  'down_payment_value',
  'on_behalf',
  'warehouse_id',
  'product',
  'tag',
];

export const allowKeysProductData = [
  'product_id',
  'quantity',
  'product_unit',
  'unit',
  'price',
  'product_description',
];

export const allowKeysProductPurchaseOrderData = [
  'product_id',
  'quantity',
  'price',
  'product_description',
  'quantity_origin',
  'unit_origin',
  'unit_destination',
].filter((key) =>
  !hasImplementedUnitProductUnit ? !keynamesImpelementProductUnit.includes(key) : true,
);

export const purchaseOrderCalculationNumberKeyNames = [
  'discount_nominal',
  'discount_percentage',
  'expense_value',
  'down_payment_value',
  'amount',
];
