// column expense list that handling table head with translation
export const columnsTableHeaderExpense = (t) => {
  return [
    {
      name: 'transaction_no',
      name_input: 'transaction_no',
      label: t('dashboard.sales.sales-quotation.table.header-column.transaction-no'),
      placeholder: t('dashboard.sales.sales-quotation.input.search-transaction-no.placeholder'),
      minWidth: 100,
      width: 170,
      // maxWidth: 100,
    },
    {
      name: 'transaction_date',
      name_input: 'from_date',
      label: t('dashboard.sales.sales-quotation.table.header-column.transaction-date'),
      placeholder: t('dashboard.sales.sales-quotation.input.search-transaction-date.placeholder'),
      minWidth: 130,
      width: 200,
      // maxWidth: 200,
    },
    {
      name: 'amount',
      label: t('table.header-column.amount'),
      align: 'right',
      minWidth: 100,
      width: 100,
      // maxWidth: 200,
    },
    {
      name: 'contact_name',
      name_input: 'contact_name',
      label: t('dashboard.sales.sales-quotation.table.header-column.customer'),
      placeholder: t('dashboard.sales.sales-quotation.input.search-customer.placeholder'),
      minWidth: 250,
      width: 260,
      align: 'left',
    },
    {
      name: 'description',
      name_input: '',
      label: t('table.header-column.description'),
      align: 'left',
      minWidth: 300,
      width: 500,
      // maxWidth: 500,
      limitWord: 55,
    },
  ];
};

export const columnsSearchWithDate = ['from_date', 'to_date'];

// column that representation for calculation
export const calculationColumns = ['amount'];
