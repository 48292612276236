import { createSlice } from '@reduxjs/toolkit';
import { isEmpty, pick } from 'lodash';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  LOCAL_STORAGE_AUTH_SESSION_STATUS,
  LOCAL_STORAGE_PRIMARY_TOKEN,
  LOCAL_STORAGE_SECONDARY_TOKEN,
  LOCAL_STORAGE_USER,
} from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  REQUEST_STATUS_SETUP_MANAGEMENT_COMPANY_FAILED,
  REQUEST_STATUS_SETUP_MANAGEMENT_COMPANY_PENDING,
  REQUEST_STATUS_SETUP_MANAGEMENT_COMPANY_SUCCESS,
  SLICE_NAME_AUTH_DATA,
  SLICE_NAME_AUTH_PRIMARY_TOKEN,
  SLICE_NAME_AUTH_SECONDARY_TOKEN,
  STATUS_REQUEST_BASE_FAILED,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_BASE_PENDING,
  STATUS_REQUEST_BASE_SUCCESS,
  STATUS_REQUEST_CHANGE_COMPANY_AUTH_BY_AUTH_PORTAL_FAILED,
  STATUS_REQUEST_CHANGE_COMPANY_AUTH_BY_AUTH_PORTAL_PENDING,
  STATUS_REQUEST_CHANGE_COMPANY_AUTH_BY_AUTH_PORTAL_SUCCESS,
  STATUS_REQUEST_CHANGE_COMPANY_AUTH_FAILED,
  STATUS_REQUEST_CHANGE_COMPANY_AUTH_PENDING,
  STATUS_REQUEST_CHANGE_COMPANY_AUTH_SUCCESS,
  STATUS_REQUEST_LOGIN_AUTH_FAILED,
  STATUS_REQUEST_LOGIN_AUTH_PENDING,
  STATUS_REQUEST_LOGIN_AUTH_SUCCESS,
  STATUS_REQUEST_LOGOUT_AUTH_FAILED,
  STATUS_REQUEST_LOGOUT_AUTH_PENDING,
  STATUS_REQUEST_LOGOUT_AUTH_SUCCESS,
  STATUS_REQUEST_NEW_PASSWORD_FAILED,
  STATUS_REQUEST_NEW_PASSWORD_PENDING,
  STATUS_REQUEST_NEW_PASSWORD_SUCCESS,
  STATUS_REQUEST_REGISTER_AUTH_FAILED,
  STATUS_REQUEST_REGISTER_AUTH_PENDING,
  STATUS_REQUEST_REGISTER_AUTH_SUCCESS,
  STATUS_REQUEST_VERIFY_EMAIL_AUTH_FAILED,
  STATUS_REQUEST_VERIFY_EMAIL_AUTH_PENDING,
  STATUS_REQUEST_VERIFY_EMAIL_AUTH_SUCCESS,
} from '../../utils/constants/store.constant';
import { companySetupStartSetup } from '../../utils/default/company-setup-steps';
import {
  DEFAULT_KEY_NAME_CONTAINER_COMBINE_RESPONSE,
  DEFAULT_KEY_NAME_LOGIN_SETUP_API_REF,
  DEFAULT_KEY_NAME_SETUP_COMPANY_PROGRESS,
} from '../../utils/default/object-keyname.default';
import objHelper from '../../utils/helpers/object.helper';

import {
  changeCompany,
  changeCompanyByAuthPortal,
  forgotPassword,
  login,
  logout,
  moveCompany,
  newPasswordRequest,
  register,
  removeAllDataState,
  setupCompany,
  verifyEmail,
} from './auth.action';

/**
 *
 * @param { object } currentState
 * set on auth session status on local storage, you can pass cureent state on slice
 *
 * alreadyRegistered        state for handlig successfully registered new user
 * stillOnPortalLogin       determine for user only for portal login, before select company
 *
 *
 */
function settingAuthSessionStatus(currentState) {
  const keyAuthSessionObj = ['alreadyRegistered', 'stillOnPortalLogin'];

  const selectedAuthUserData = pick(currentState, keyAuthSessionObj);

  if (!isEmpty(selectedAuthUserData)) {
    LocalStorage.set(LOCAL_STORAGE_AUTH_SESSION_STATUS, selectedAuthUserData);
  }
}

const isValidLocalStorage = LocalStorage.checkingValidationStorage(),
  authSessionStatus = LocalStorage.get(LOCAL_STORAGE_AUTH_SESSION_STATUS);

// force remove token local storage and whole application
if (isValidLocalStorage && LocalStorage.get('token')) {
  LocalStorage.clear();
  window.location.reload();
}

/**
 *
 * primary token: for authentication where whole application
 *      when token has been setting?
 *          when login, change company
 *
 * secondary token: authentication user only, not provide company
 *      when token has been setting?
 *          only after user logged in
 *
 */
const authSlice = createSlice({
  name: NAME_STORE.AUTH,
  // data and token existed when localStorage is free from suspicious key
  initialState: {
    alreadyRegistered: (authSessionStatus?.alreadyRegistered && isValidLocalStorage) || false,
    stillOnPortalLogin: (authSessionStatus?.stillOnPortalLogin && isValidLocalStorage) || false,
    statusRequest: STATUS_REQUEST_BASE_IDDLE,
    [SLICE_NAME_AUTH_DATA]: LocalStorage.get(LOCAL_STORAGE_USER),
    [SLICE_NAME_AUTH_PRIMARY_TOKEN]: LocalStorage.get(LOCAL_STORAGE_PRIMARY_TOKEN),
    [SLICE_NAME_AUTH_SECONDARY_TOKEN]: LocalStorage.get(LOCAL_STORAGE_SECONDARY_TOKEN),
  },
  reducers: {
    setAuthSessionStatus: (state, action) => {
      const { payload } = action;
      if (isEmpty(payload)) return;

      const nextState = {
        ...state,
        ...payload,
      };

      settingAuthSessionStatus(nextState);

      return nextState;
    },
    setAuthUserData: (state, action) => {
      const newDataUser = {
        ...state,
        data: {
          ...state.data,
          user: {
            ...state.data.user,
            ...action.payload.user,
          },
        },
      };

      LocalStorage.set(LOCAL_STORAGE_USER, newDataUser.data);

      return {
        ...state,
        ...newDataUser,
      };
    },
    setPermissionDataAuthSlice: (state, action) => {
      const { data, bodyDataAction } = action.payload;
      if (isEmpty(data) || isEmpty(bodyDataAction)) return state;

      const { isAssignSelf } = bodyDataAction;
      if (!isAssignSelf) return state;

      let permissionData = pick(data, ['application', 'menu', 'menu_group', 'permission', 'role']);
      if (!isEmpty(permissionData)) {
        permissionData = objHelper.changeKeyNameWithCorrespondKeyTarget({
          obj: permissionData,
          correspondObj: {
            role: 'user_role',
          },
        });
      }

      const newUserData = {
        ...state.data,
        ...permissionData,
      };

      LocalStorage.set(LOCAL_STORAGE_USER, newUserData);

      return {
        ...state,
        data: newUserData,
      };
    },
    setStatusRequestAuth: (state, action) => {
      const statusRequest = action.payload
        ? action.payload.statusRequest
        : STATUS_REQUEST_BASE_IDDLE;

      return {
        ...state,
        statusRequest,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.stillOnPortalLogin = true;

      state.data = action.payload.data;
      state[SLICE_NAME_AUTH_PRIMARY_TOKEN] = action.payload.token;
      state[SLICE_NAME_AUTH_SECONDARY_TOKEN] = action.payload.token;

      settingAuthSessionStatus(state);

      state.statusRequest = STATUS_REQUEST_LOGIN_AUTH_SUCCESS;
    }),
      builder.addCase(login.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LOGIN_AUTH_PENDING;
      }),
      builder.addCase(login.rejected, (state) => {
        state.data = {};
        state.stillOnPortalLogin = false;

        settingAuthSessionStatus(state);

        state.statusRequest = STATUS_REQUEST_LOGIN_AUTH_FAILED;
      }),
      // change company authentication
      builder.addCase(changeCompany.fulfilled, (state, action) => {
        state.stillOnPortalLogin = false;

        if (!isEmpty(action.payload)) {
          const { data, token } = action.payload;

          state.data = data;
          state.data.permissions = data.role_menu || [];
          state.data.user_role = data.role || '';

          const loginSetup = data.company[DEFAULT_KEY_NAME_LOGIN_SETUP_API_REF];

          state.data[DEFAULT_KEY_NAME_SETUP_COMPANY_PROGRESS] =
            loginSetup || companySetupStartSetup;

          state[SLICE_NAME_AUTH_PRIMARY_TOKEN] = token;

          LocalStorage.set(LOCAL_STORAGE_PRIMARY_TOKEN, token);
          LocalStorage.set(LOCAL_STORAGE_USER, data);
        }

        settingAuthSessionStatus(state);

        state.statusRequest = STATUS_REQUEST_CHANGE_COMPANY_AUTH_SUCCESS;
      }),
      builder.addCase(changeCompany.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_CHANGE_COMPANY_AUTH_PENDING;
      }),
      builder.addCase(changeCompany.rejected, (state) => {
        state.stillOnPortalLogin = true;

        settingAuthSessionStatus(state);

        state.statusRequest = STATUS_REQUEST_CHANGE_COMPANY_AUTH_FAILED;
      }),
      // change company on auth portal
      builder.addCase(changeCompanyByAuthPortal.fulfilled, (state, action) => {
        state.stillOnPortalLogin = true;

        if (!isEmpty(action.payload)) {
          const { data, token } = action.payload;

          state.data = data;

          if (data.role) state.data.user_role = data.role;
          if (data.role_menu) state.data.permissions = data.role_menu;

          state[SLICE_NAME_AUTH_PRIMARY_TOKEN] = token;

          LocalStorage.set(LOCAL_STORAGE_PRIMARY_TOKEN, token);
          LocalStorage.set(LOCAL_STORAGE_USER, data);
        }

        settingAuthSessionStatus(state);

        state.statusRequest = STATUS_REQUEST_CHANGE_COMPANY_AUTH_BY_AUTH_PORTAL_SUCCESS;
      }),
      builder.addCase(changeCompanyByAuthPortal.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_CHANGE_COMPANY_AUTH_BY_AUTH_PORTAL_PENDING;
      }),
      builder.addCase(changeCompanyByAuthPortal.rejected, (state) => {
        state.data = {};
        state.stillOnPortalLogin = true;

        settingAuthSessionStatus(state);

        state.statusRequest = STATUS_REQUEST_CHANGE_COMPANY_AUTH_BY_AUTH_PORTAL_FAILED;
      }),
      // move company
      builder.addCase(moveCompany.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state[SLICE_NAME_AUTH_PRIMARY_TOKEN] = action.payload.token;

        settingAuthSessionStatus(state);

        state.statusRequest = STATUS_REQUEST_BASE_SUCCESS;
      }),
      builder.addCase(moveCompany.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_BASE_PENDING;
      }),
      builder.addCase(moveCompany.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_BASE_FAILED;
      }),
      // register auth
      builder.addCase(register.fulfilled, (state) => {
        state.alreadyRegistered = true;

        settingAuthSessionStatus(state);

        state.statusRequest = STATUS_REQUEST_REGISTER_AUTH_SUCCESS;
      }),
      builder.addCase(register.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_REGISTER_AUTH_PENDING;
      }),
      builder.addCase(register.rejected, (state) => {
        state.alreadyRegistered = false;

        settingAuthSessionStatus(state);

        state.statusRequest = STATUS_REQUEST_REGISTER_AUTH_FAILED;
      }),
      builder.addCase(logout.fulfilled, (state) => {
        state.data = {};
        state[SLICE_NAME_AUTH_PRIMARY_TOKEN] = null;
        state[SLICE_NAME_AUTH_SECONDARY_TOKEN] = null;

        state.stillOnPortalLogin = false;

        state.statusRequest = STATUS_REQUEST_LOGOUT_AUTH_SUCCESS;
      }),
      builder.addCase(logout.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LOGOUT_AUTH_PENDING;
      }),
      builder.addCase(logout.rejected, (state) => {
        state.stillOnPortalLogin = false;

        settingAuthSessionStatus(state);

        state.statusRequest = STATUS_REQUEST_LOGOUT_AUTH_FAILED;
      }),
      builder.addCase(forgotPassword.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_BASE_PENDING;
      }),
      builder.addCase(forgotPassword.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_BASE_SUCCESS;
      }),
      builder.addCase(forgotPassword.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_BASE_FAILED;
      }),
      builder.addCase(verifyEmail.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_VERIFY_EMAIL_AUTH_SUCCESS;
      }),
      builder.addCase(verifyEmail.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_VERIFY_EMAIL_AUTH_PENDING;
      }),
      builder.addCase(verifyEmail.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_VERIFY_EMAIL_AUTH_FAILED;
      }),
      builder.addCase(newPasswordRequest.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_NEW_PASSWORD_FAILED;
      }),
      builder.addCase(newPasswordRequest.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_NEW_PASSWORD_SUCCESS;
      }),
      builder.addCase(newPasswordRequest.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_NEW_PASSWORD_PENDING;
      }),
      builder.addCase(setupCompany.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_SETUP_MANAGEMENT_COMPANY_PENDING;
      }),
      builder.addCase(setupCompany.fulfilled, (state, action) => {
        const { data } = action.payload;
        const { progress } = data[DEFAULT_KEY_NAME_CONTAINER_COMBINE_RESPONSE];

        state.data[DEFAULT_KEY_NAME_SETUP_COMPANY_PROGRESS] = progress;

        LocalStorage.set(LOCAL_STORAGE_USER, state.data);

        state.statusRequest = REQUEST_STATUS_SETUP_MANAGEMENT_COMPANY_SUCCESS;
      }),
      builder.addCase(setupCompany.rejected, (state) => {
        state.statusRequest = REQUEST_STATUS_SETUP_MANAGEMENT_COMPANY_FAILED;
      });
  },
});

const { reducer, actions } = authSlice;

export const {
  setAuthUserData,
  setPermissionDataAuthSlice,
  setStatusRequestAuth,
  setAuthSessionStatus,
} = actions;

export default reducer;
