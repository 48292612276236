import * as React from 'react';
function SvgBeginningBalanceSettingMenu(props) {
  return (
    <svg width={93} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#beginning-balance-setting-menu_svg__filter0_d_5586_193656)'>
        <path d='M81.79 1H18.798v26.173h62.994V1z' fill='#C2D64A' />
        <path
          d='M55.826 16.44c0 .89-.418 1.605-1.257 2.147-.836.541-2.01.864-3.516.97v1.723h-1.496v-1.69c-1.776-.027-3.335-.268-4.675-.72v-2.22c.634.242 1.396.454 2.288.64.892.184 1.688.294 2.387.328v-2.605l-.732-.22c-1.442-.436-2.463-.912-3.063-1.423-.6-.512-.9-1.147-.9-1.903 0-.812.411-1.48 1.238-2.004.828-.524 1.978-.842 3.457-.954V7.224h1.496v1.252c1.667.056 3.175.314 4.522.774l-1.028 1.966a14.737 14.737 0 00-3.494-.655v2.48c1.419.418 2.43.783 3.031 1.09.6.309 1.042.648 1.322 1.018.28.367.42.798.42 1.29zm-7.678-4.975c0 .246.111.45.334.609.223.16.58.324 1.076.491v-1.973c-.94.105-1.41.397-1.41.873zm4.39 5.1c0-.234-.123-.433-.37-.596-.248-.163-.62-.33-1.114-.505v2.093c.99-.13 1.485-.458 1.485-.991z'
          fill='#A4B830'
        />
        <path
          d='M21.741 3.26v21.652h57.115V3.26H21.741zm3.25 19.01v-5.706c1.779 0 3.22-1.11 3.22-2.478s-1.442-2.478-3.22-2.478V5.73h16.36c-3.128 1.986-5.122 4.991-5.122 8.355 0 3.273 1.896 6.2 4.879 8.185H24.99zm25.309.594c-6.184 0-11.196-3.856-11.196-8.614 0-4.757 5.012-8.614 11.196-8.614 6.183 0 11.195 3.857 11.195 8.614 0 4.758-5.013 8.614-11.195 8.614zm25.412-11.198c-1.737 0-3.145 1.083-3.145 2.42s1.408 2.42 3.145 2.42v5.765h-16.22c2.982-1.985 4.878-4.912 4.878-8.185 0-3.364-1.994-6.37-5.122-8.355h16.465v5.935h-.001zM81.79 27.173H18.798v26.173h62.994V27.173z'
          fill='#A4B830'
        />
        <path
          d='M81.79 29.871H18.798v1.13h62.994v-1.13zM81.79 33.777H18.798v1.13h62.994v-1.13zM81.79 37.68H18.798v1.13h62.994v-1.13zM81.79 41.59H18.798v1.13h62.994v-1.13zM81.79 45.492H18.798v1.13h62.994v-1.13zM81.79 49.395H18.798v1.13h62.994v-1.13z'
          fill='#C2D64A'
        />
        <path
          d='M84.94 52.376c-1.168-3.849-5.647-6.72-11.006-6.72-5.358 0-9.837 2.871-11.007 6.72h-.307v1.986c0 4.809 5.066 8.706 11.314 8.706 6.25 0 11.315-3.898 11.315-8.706v-1.986h-.308z'
          fill='#EB9900'
        />
        <path
          d='M73.936 61.084c6.248 0 11.314-3.898 11.314-8.706s-5.066-8.706-11.314-8.706c-6.25 0-11.315 3.898-11.315 8.706s5.066 8.706 11.315 8.706z'
          fill='#FFF466'
        />
        <path
          d='M73.933 43.67v17.412c6.25 0 11.314-3.898 11.314-8.706 0-4.807-5.065-8.706-11.314-8.706z'
          fill='#FDC00F'
        />
        <path
          d='M73.933 59.652c-5.214 0-9.456-3.264-9.456-7.276 0-4.011 4.242-7.275 9.456-7.275 5.214 0 9.456 3.264 9.456 7.275 0 4.013-4.242 7.276-9.456 7.276zm0-13.558c-4.501 0-8.164 2.819-8.164 6.282 0 3.465 3.663 6.283 8.164 6.283 4.502 0 8.165-2.819 8.165-6.283 0-3.464-3.663-6.282-8.165-6.282z'
          fill='#EB9900'
        />
        <path
          d='M75.498 54.037c0-.163-.085-.3-.257-.413-.172-.114-.428-.23-.772-.35v1.449c.686-.088 1.03-.317 1.03-.686zm2.277-.087c0 .617-.29 1.113-.87 1.487-.58.374-1.39.598-2.436.672v1.193h-1.036v-1.17c-1.23-.018-2.31-.186-3.238-.5v-1.535c.44.166.968.314 1.585.442a10.87 10.87 0 001.653.227v-1.803l-.507-.152c-1-.302-1.706-.63-2.121-.986-.416-.355-.624-.794-.624-1.317 0-.563.287-1.026.86-1.388.572-.362 1.37-.583 2.393-.66v-.89h1.036v.866c1.154.038 2.199.217 3.131.535l-.71 1.361a10.24 10.24 0 00-2.42-.454v1.716c.983.291 1.682.544 2.098.757.416.213.72.448.916.705.193.254.29.553.29.894zm-5.318-3.446a.5.5 0 00.232.423c.154.11.402.224.745.34v-1.368c-.65.075-.977.276-.977.605z'
          fill='#EB9900'
        />
        <path d='M50.91 36.752H2.077V67.88H50.91V36.752z' fill='#C2D64A' />
        <path
          d='M5.215 35.368c-2.19 0-3.965 1.367-3.965 3.051v27.157l.01.009c.014.46.04 1.135.077.997.38 1.38 1.97 2.418 3.878 2.418l47.583-.002V35.369H5.215zm45.7 31.595l-45.7.002c-1.207 0-1.998-.26-1.998-1.278v-3.322c0-.842.687-1.13 1.998-1.13l45.7.002v5.726z'
          fill='#0F303F'
        />
        <path
          d='M5.215 33.335c-2.19 0-3.965 1.366-3.965 3.05v24.6c0 .218.032.43.089.634.38-1.38 1.97-2.416 3.877-2.416l47.583.002v-25.87H5.215z'
          fill='#2C5871'
        />
        <path
          d='M44.38 45.313c-2.824 0-5.112 1.76-5.112 3.933s2.288 3.934 5.112 3.934H55.28v-7.867H44.38z'
          fill='#0F303F'
        />
        <path
          d='M44.38 43.279c-2.824 0-5.112 1.76-5.112 3.933s2.288 3.934 5.112 3.934H55.28v-7.867H44.38z'
          fill='#2C5871'
        />
        <path
          d='M44.22 49.828c1.687 0 3.055-1.053 3.055-2.351s-1.368-2.35-3.055-2.35-3.055 1.052-3.055 2.35 1.368 2.35 3.055 2.35z'
          fill='#FC611F'
        />
      </g>
      <defs>
        <filter
          id='beginning-balance-setting-menu_svg__filter0_d_5586_193656'
          x={0.25}
          y={0}
          width={92}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_193656' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_193656' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgBeginningBalanceSettingMenu;
