import {
  DEFAULT_KEY_NAME_PRICE_API_REF,
  DEFAULT_KEY_NAME_PURCHASE_PRICE_API_REF,
  DEFAULT_KEY_NAME_QUANTITY_AVAILABLE_CONST,
  DEFAULT_KEY_NAME_QUANTITY_CONST,
  DEFAULT_KEY_NAME_QUANTITY_WRITTEN_API_REF,
  DEFAULT_KEY_NAME_SALES_PRICE_API_REF,
} from './object-keyname.default';
import {
  DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_PURCHASE_INVOICE_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_PURCHASE_ORDER_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_PURCHASE_PAYMENT_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_PURCHASE_RETURN_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_TYPE,
  DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE,
  DEFAULT_TRANSACTION_SALES_SALES_INVOICE_TYPE,
  DEFAULT_TRANSACTION_SALES_SALES_ORDER_TYPE,
  DEFAULT_TRANSACTION_SALES_SALES_PAYMENT_TYPE,
  DEFAULT_TRANSACTION_SALES_SALES_QUOTATION_TYPE,
  DEFAULT_TRANSACTION_SALES_SALES_RETURN_TYPE,
  DEFAULT_TRANSACTION_SALES_TYPE,
} from './transaction-type';

export const commonDifferentiationOfTransactionType = {
  [DEFAULT_TRANSACTION_PURCHASE_TYPE]: {
    priceKeyname: DEFAULT_KEY_NAME_PURCHASE_PRICE_API_REF,
    priceUpdateKeyname: {
      [DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_TYPE]: DEFAULT_KEY_NAME_PRICE_API_REF,
      [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_ORDER_TYPE]: DEFAULT_KEY_NAME_PRICE_API_REF,
      [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE]: DEFAULT_KEY_NAME_PRICE_API_REF,
      [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_INVOICE_TYPE]: DEFAULT_KEY_NAME_PRICE_API_REF,
      [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_PAYMENT_TYPE]: DEFAULT_KEY_NAME_PRICE_API_REF,
      [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_RETURN_TYPE]: DEFAULT_KEY_NAME_PRICE_API_REF,
    },
    usageMaximumInputValue: true,
    keynameMaximumInputValueFromAPI: DEFAULT_KEY_NAME_QUANTITY_WRITTEN_API_REF,
    keynameMaximumInputCorrespondWithSubTransaction: {
      [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE]:
        DEFAULT_KEY_NAME_QUANTITY_AVAILABLE_CONST,
      [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_RETURN_TYPE]:
        DEFAULT_KEY_NAME_QUANTITY_AVAILABLE_CONST,
    },
    correspondKeynameAndValueForDiscardLimit: null,
  },
  [DEFAULT_TRANSACTION_SALES_TYPE]: {
    priceKeyname: DEFAULT_KEY_NAME_SALES_PRICE_API_REF,
    priceUpdateKeyname: {
      [DEFAULT_TRANSACTION_SALES_SALES_QUOTATION_TYPE]: DEFAULT_KEY_NAME_PRICE_API_REF,
      [DEFAULT_TRANSACTION_SALES_SALES_ORDER_TYPE]: DEFAULT_KEY_NAME_PRICE_API_REF,
      [DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE]: DEFAULT_KEY_NAME_PRICE_API_REF,
      [DEFAULT_TRANSACTION_SALES_SALES_INVOICE_TYPE]: DEFAULT_KEY_NAME_PRICE_API_REF,
      [DEFAULT_TRANSACTION_SALES_SALES_PAYMENT_TYPE]: DEFAULT_KEY_NAME_PRICE_API_REF,
      [DEFAULT_TRANSACTION_SALES_SALES_RETURN_TYPE]: DEFAULT_KEY_NAME_PRICE_API_REF,
    },
    usageMaximumInputValue: true,
    keynameMaximumInputValueFromAPI: DEFAULT_KEY_NAME_QUANTITY_WRITTEN_API_REF,
    keynameMaximumInputCorrespondWithSubTransaction: {
      [DEFAULT_TRANSACTION_SALES_SALES_QUOTATION_TYPE]: DEFAULT_KEY_NAME_QUANTITY_CONST,
      [DEFAULT_TRANSACTION_SALES_SALES_ORDER_TYPE]: DEFAULT_KEY_NAME_QUANTITY_CONST,
      [DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE]: DEFAULT_KEY_NAME_QUANTITY_AVAILABLE_CONST,
      [DEFAULT_TRANSACTION_SALES_SALES_INVOICE_TYPE]: DEFAULT_KEY_NAME_QUANTITY_AVAILABLE_CONST,
      [DEFAULT_TRANSACTION_SALES_SALES_PAYMENT_TYPE]: DEFAULT_KEY_NAME_QUANTITY_AVAILABLE_CONST,
      [DEFAULT_TRANSACTION_SALES_SALES_RETURN_TYPE]: DEFAULT_KEY_NAME_QUANTITY_AVAILABLE_CONST,
    },
    correspondKeynameAndValueForDiscardLimit: {
      is_stock_monitored: false,
    },
  },
};

export const getCommonDifferentiationOfTransactionType = ({
  transactionType = DEFAULT_TRANSACTION_PURCHASE_TYPE,
}) => {
  const transactionCommonKeys = Object.keys(commonDifferentiationOfTransactionType);
  if (!transactionCommonKeys.includes(transactionType)) transactionType = transactionCommonKeys[0];

  return commonDifferentiationOfTransactionType[transactionType];
};
