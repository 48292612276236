/**
 * default status sales invoice
 */
export const DEFAULT_STATUS_SALES_INVOICE_OPEN = 'open';
export const DEFAULT_STATUS_SALES_INVOICE_PARTIALLY_PAID = 'partially_paid';
export const DEFAULT_STATUS_SALES_INVOICE_PAID = 'paid';
export const DEFAULT_STATUS_SALES_INVOICE_OVERDUE = 'overdue';

/**
 * default status sales invoice chart basic
 */
export const DEFAULT_STATUS_SALES_INVOICE_CHART_BASIC_OPEN = 'open';
export const DEFAULT_STATUS_SALES_INVOICE_CHART_BASIC_PARTIALLY_PAID = 'partially_paid';
export const DEFAULT_STATUS_SALES_INVOICE_CHART_BASIC_PAID = 'paid';

/*
 * default sales order status
 */
// open','partially_sent','closed', 'reject'
export const DEFAULT_ADDITIONAL_STATUS_SALES_ORDER_ALL = 'all';
export const DEFAULT_STATUS_SALES_ORDER_OPEN = 'open';
export const DEFAULT_STATUS_SALES_ORDER_PARTIALLY_SENT = 'partially_sent';
export const DEFAULT_STATUS_SALES_ORDER_REJECT = 'reject';
export const DEFAULT_STATUS_SALES_ORDER_CLOSED = 'closed';

/**
 * default status sales delivery
 */
export const DEFAULT_ADDITIONAL_STATUS_SALES_DELIVERY_ALL = 'all';
export const DEFAULT_STATUS_SALES_DELIVERY_OPEN = 'open';
export const DEFAULT_STATUS_SALES_DELIVERY_PARTIALLY_SENT = 'partially_sent';
export const DEFAULT_STATUS_SALES_DELIVERY_REJECT = 'reject';
export const DEFAULT_STATUS_SALES_DELIVERY_CLOSE = 'close';

/**
 * default status sales quotation
 */
export const DEFAULT_ADDITIONAL_STATUS_SALES_QUOTATION_ALL = 'all';
export const DEFAULT_STATUS_SALES_QUOTATION_OPEN = 'open';
export const DEFAULT_STATUS_SALES_QUOTATION_EXPIRED = 'expired';
export const DEFAULT_STATUS_SALES_QUOTATION_REJECT = 'reject';
export const DEFAULT_STATUS_SALES_QUOTATION_CLOSE = 'close';

/*
 * default sales return status
 */
export const ADDITIONAL_SALES_RETURN_STATUS_ALL = 'all';
export const DEFAULT_STATUS_SALES_RETURN_OPEN = 'open';
export const DEFAULT_STATUS_SALES_RETURN_CLOSED = 'closed';

/**
 * status sales credit note
 */
export const DEFAULT_ADDITIONAL_STATUS_SALES_CREDIT_NOTE_ALL = 'all';
export const DEFAULT_STATUS_SALES_CREDIT_NOTE_OPEN = 'open';
export const DEFAULT_STATUS_SALES_CREDIT_NOTE_PAID = 'paid';
export const DEFAULT_STATUS_SALES_CREDIT_NOTE_CLOSED = 'closed';
export const DEFAULT_STATUS_SALES_CREDIT_NOTE_PARTIALLY_PAID = 'partially_paid';
