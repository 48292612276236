import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import chartServices from '../../services/API/chart/chart.services';
import {
  SLICE_NAME_CHART_INCOME_STATEMENT_DETAILS,
  SLICE_NAME_CHART_SALES_TRANSACTION_DETAILS,
} from '../../utils/constants/store.constant';
import {
  CHART_BASIC_DASHBOARD_TYPE_KEY_NAME,
  getChartDashboardBasicConfiguration,
  getPaperChartListBasicDashboard,
} from '../../utils/default/chart-basic-dashboard-config.default';
import {
  DEFAULT_ACTION_PARAMS,
  DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
} from '../../utils/default/params.default';
import arrHelp from '../../utils/helpers/array.helpers';
import objHelper from '../../utils/helpers/object.helper';
import { creatorDetailAction, creatorListAction } from '../creator-action/creator-action.helper';

// action getting chart basic dashboard details
export const getChartBasicDashboardDetails = createAsyncThunk(
  'chart-basic-dashboard/details',
  async ({ params, chartType }, thunkAPI) => {
    const chartBasicConfiguration = getChartDashboardBasicConfiguration(chartType);

    const { sliceName, localStorageName, errorMessage, defaultKeyStartDate, defaultKeyEndDate } =
      chartBasicConfiguration;

    params = objHelper.changeFormatValue(
      params,
      [defaultKeyStartDate, defaultKeyEndDate],
      [(date) => moment(date).format('YYYY-MM-DD')],
    );

    return await creatorDetailAction(
      thunkAPI,
      {
        params,
        [CHART_BASIC_DASHBOARD_TYPE_KEY_NAME]: chartType,
      },
      chartServices.getChartBasicDashboardDetails,
      localStorageName,
      sliceName,
      errorMessage,
    );
  },
);

// getting action on list basic dashboard
export const getChartListBasicDashboard = createAsyncThunk(
  'chart-basic-dashboard/list',
  async ({ chartType, actionUtils = getPaperChartListBasicDashboard, ...params }, thunkAPI) => {
    const {
      sliceName,
      localStorageName,
      uniqueIdentityID,
      defaultKeyStartDate,
      defaultKeyEndDate,
    } =
      typeof actionUtils === 'function'
        ? actionUtils(chartType)
        : getPaperChartListBasicDashboard(chartType);

    const searchDateTypeParams = arrHelp.filteringExistedValue([
      defaultKeyStartDate,
      defaultKeyEndDate,
    ]);

    params = objHelper.changeFormatValue(params, searchDateTypeParams, [
      (date) => moment(date).format('YYYY-MM-DD'),
    ]);

    return await creatorListAction(
      thunkAPI,
      {
        [CHART_BASIC_DASHBOARD_TYPE_KEY_NAME]: chartType,
        actionUtils,
        ...params,
      },
      chartServices.getChartListBasicDashboard,
      localStorageName,
      sliceName,
      uniqueIdentityID,
      '',
      DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      DEFAULT_ACTION_PARAMS,
      'data',
      'data',
      'search_',
      false,
      ['actionUtils'],
    );
  },
);

// action sales transaction details
export const getSalesTransactionChartDetails = createAsyncThunk(
  'chart/details-sales-transaction',
  async (paramsGetSalesTransactionChartDetails, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      {
        isShowMessage: false,
        ...paramsGetSalesTransactionChartDetails,
      },
      chartServices.getSalesTransactionChartDetails,
      null,
      SLICE_NAME_CHART_SALES_TRANSACTION_DETAILS,
      null,
    );
  },
);

// action get income statement details
export const getIncomeStatementChartDetails = createAsyncThunk(
  'chart/details-income-statement',
  async (paramsGetIncomeStatementDetails, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      {
        isShowMessage: false,
        ...paramsGetIncomeStatementDetails,
      },
      chartServices.getIncomeStatementChartDetails,
      null,
      SLICE_NAME_CHART_INCOME_STATEMENT_DETAILS,
      null,
    );
  },
);

const chartActions = {
  getChartBasicDashboardDetails,
  getChartListBasicDashboard,
  getSalesTransactionChartDetails,
  getIncomeStatementChartDetails,
};

export default chartActions;
