import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import { DEFAULT_DISCOUNT_PERCENTAGE_TYPE } from '../../default/discount-type.default';
import {
  DEFAULT_KEY_NAME_MODUL_ID_API_REF,
  DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF,
  DEFAULT_KEY_NAME_SALES_DELIVERY_NO_API_REF,
  DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF,
  DEFAULT_KEY_NAME_SALES_INVOICE_NO_API_REF,
  DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF,
  DEFAULT_KEY_NAME_SALES_ORDER_NO_API_REF,
  DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF,
} from '../../default/object-keyname.default';
import {
  hasImplementedUnitProductUnit,
  keynamesImpelementProductUnit,
} from '../../default/unit-product.default';
import validation from '../../helpers/validation.helpers';

const initialID = uuidV4();

export const modulTypeSalesInvoice = {
  manual: 'manual',
  order: 'order',
  delivery: 'delivery',
};

/**
 * modul_id                 wrapper for sales_delivery_id or sales_order_id
 * modul_id_container       contained data for transaction_id and transaction_no
 * modul_type               modul where user come from create sales invoice
 * amount                   amount input for user that must be paid
 * amount_const             amount constant for checking user that has been paidded on input
 */
export const plainSalesInvoiceDataWithoutID = {
  modul_type: modulTypeSalesInvoice.manual,
  modul_id: '',
  modul_id_container: '',
  customer_id: '',
  customer_id_container: '',
  customer_name: '',
  customer_address: '',
  transaction_date: new Date(),
  expired_date: null,
  reference_number: '',
  reference_number_container: null,
  is_create_delivery: false,
  is_paid_off: false,
  warehouse_id: null,
  warehouse_id_container: '',
  accounting_period: null,
  amount: '',
  amount_const: '',
  description: '',
  sales_agent_id: '',
  sales_agent_id_container: '',
  sales_agent_name: '',
  discount_account_id: '',
  discount_account_id_container: '',
  discount_type: DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
  discount_percentage: '',
  discount_nominal: '',
  expense_account_id: '',
  expense_account_id_container: '',
  expense_value: '',
  down_payment_account_id: '',
  down_payment_account_id_container: '',
  down_payment_value: '',
  on_behalf: '',
  attachment: [],
  uuid_attachment: null,
  minDateInputRef: null,
  tag: [],
  tagContainer: [],
  tagNameInput: '',
};

/**
 * quantity_available is quantity for showing on user
 * quantity represent value for viewing user
 * quantity_origin is value that after changning on product unit
 * quantity_available_const is quantity for max on input user
 * quantity comparison, comparison from quantity with quantity origin
 * quantity_comparison_const_props, appear for update action,
 *  usage: preserve quantity comparison data
 *
 * unit_origin is value for represent unit before changing to new unit
 * unit_destination is value unit after change to new unit, this to view for user
 *
 * amount is handling input by user
 */
export const plainProductSalesInvoiceDataWithoutID = {
  product_id: null,
  product_id_container: '',
  product_description: '',
  quantity: '',
  quantity_origin: '',
  unit: '',
  unit_origin: '',
  unit_destination: '',
  quantity_available: '',
  quantity_available_const: '',
  quantity_available_with_unit: '',
  quantity_comparison: 1,
  quantity_comparison_const_props: null,
  price: '',
  amount: 0,
};

export const plainSalesInvoiceValidationWithoutID = {
  modul_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  customer_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  customer_address: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  transaction_date: {
    error: false,
    isValid: (value, minDateInputRef) => {
      if (!minDateInputRef) {
        return validation.notnull(value);
      }

      return moment(new Date(minDateInputRef)).isSameOrBefore(new Date(value), 'D');
    },
  },
  expired_date: {
    error: false,
    isValid: (value, transactionDate) => {
      if (transactionDate && value) {
        return moment(new Date(value)).isAfter(new Date(transactionDate));
      }

      return validation.nullable(value);
    },
  },
  reference_number: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  amount: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  amount_available: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const plainProductSalesInvoiceValidationWithoutID = {
  product_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  product_description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  quantity: {
    error: false,
    isValid: (value) => validation.isNumberGreaterOrEqualThan(value, 1),
  },
  unit_destination: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  price: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  amount: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const initialDataProduct = (additionalID = '', newID = '') => {
  const usedID = newID ? newID : initialID + additionalID;

  return {
    id: usedID,
    product_id: null,
    product_id_container: null,
    product_description: '',
    product_name: '',
    product_unit: '',
    quantity: '',
    quantity_available: '',
    quantity_available_const: '',
    unit: '',
    price: '',
    price_amount: 0,

    is_new_product: false,
    new_product: null,
    ...plainProductSalesInvoiceDataWithoutID,
  };
};

export const initialValidationProduct = (additionalID = '', newID = '') => {
  const usedID = newID ? newID : initialID + additionalID;

  return {
    id: usedID,
    product_id: {
      error: false,
      isValid: (value) => validation.notnull(value),
    },
    product_description: {
      error: false,
      isValid: (value) => validation.nullable(value),
    },
    quantity: {
      error: false,
      isValid: (value) => validation.isNumberGreaterOrEqualThan(value, 1),
    },
    unit: {
      error: false,
      isValid: (value) => validation.notnull(value),
    },
    price: {
      error: false,
      isValid: (value) => validation.notnull(value),
    },
    ...plainProductSalesInvoiceValidationWithoutID,
  };
};

/**
 *
 * @param {*} additionalID
 * @param {*} newID
 *
 * additional info,
 *      modul_id is data id that can came from sales order id or sales delivery id
 *      modul_type is type where modul_id came from can hold two type, includes 'order' or 'delivery'
 *
 * @returns
 */
export const initialDataSalesInvoice = (additionalID = '', newID = '') => {
  const usedID = newID ? newID : initialID + additionalID;

  return {
    id: usedID,
    ...plainSalesInvoiceDataWithoutID,
    modul_type: 'manual',
    modul_id: null,
    modul_id_container: null,
    customer_id: null,
    customer_name: '',
    customer_id_container: null,
    customer_address: '',
    transaction_date: moment(),
    expired_date: null,
    reference_number: 'SI0001',
    reference_number_container: {
      code: 'SI0001',
    },
    description: '',
    amount: 0,
    is_create_delivery: false,
    is_paid_off: false,
    uuid_attachment: null,
    attachment: [],
    is_new_ref_number: false,
    new_ref_number: null,
    is_new_customer: false,
    new_customer: null,
    minAccountingPeriod: null,
  };
};

export const initialValidationSalesInvoiceOnModulIDmodulManualType = {
  modul_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const initialValidationSalesInvoice = (additionalID = '', newID = '') => {
  const usedID = newID ? newID : initialID + additionalID;

  return {
    id: usedID,
    modul_id: {
      error: false,
      isValid: (value) => validation.nullable(value),
    },
    customer_id: {
      error: false,
      isValid: (value) => validation.nullable(value),
    },
    customer_address: {
      error: false,
      isValid: (value) => validation.nullable(value),
    },
    transaction_date: {
      error: false,
      isValid: (value, minAccountingPeriod) => {
        if (!value || !minAccountingPeriod) {
          return validation.notnull(value);
        }

        return new Date(moment(minAccountingPeriod).isBefore(new Date(value), 'D'));
      },
    },
    expired_date: {
      error: false,
      isValid: (value, transaction_date) => {
        if (!value || !transaction_date) {
          return validation.nullable(value);
        }

        return moment(new Date(transaction_date)).isBefore(new Date(value), 'D');
      },
    },
    reference_number: {
      error: false,
      isValid: (value) => validation.notnull(value),
    },
    description: {
      error: false,
      isValid: (value) => validation.nullable(value),
    },
    ...plainSalesInvoiceValidationWithoutID,
  };
};

export const allowKeysDataProduct = [
  'product_id',
  'product_description',
  'quantity',
  'unit',
  'price',
  'unit',
  'sales_invoice_id',
];

export const allowKeysDataProductSalesInvoice = [
  'product_id',
  'product_description',
  'quantity',
  'quantity_origin',
  'price',
  'unit',
  'unit_destination',
  'unit_origin',
  'sales_invoice_id',
].filter((key) =>
  !hasImplementedUnitProductUnit ? !keynamesImpelementProductUnit.includes(key) : true,
);

export const allowKeysDataSalesInvoice = [
  'modul_id',
  'modul_type',
  'customer_id',
  'customer_name',
  'customer_address',
  'reference_number',
  'transaction_date',
  'expired_date',
  'description',
  'product',
  'is_create_delivery',
  'is_paid_off',
  'sales_agent_id',
  'sales_agent_name',
  'discount_account_id',
  'discount_type',
  'discount_percentage',
  'discount_nominal',
  'expense_account_id',
  'expense_value',
  'down_payment_account_id',
  'down_payment_value',
  'on_behalf',
  'warehouse_id',
  'uuid_attachment',
  'paid',
  'tag',
];

// where sales invoice created from
export const modulsSalesInvoiceTypeComeFrom = ['order', 'delivery'];

export const modulOrderType = 'order';
export const modulDeliveryType = 'delivery';

export const relationalAdditionalCostValueNaccountForSalesInvoice = {
  discount_percentage: ['discount_account_id', 'discount_type'],
  expense_value: 'expense_account_id',
  down_payment_value: 'down_payment_account_id',
};

export const salesInvoiceCalculationNumberKeyNames = [
  'amount',
  'amount_const',
  'discount_percentage',
  'discount_nominal',
  'expense_value',
  'down_payment_value',
];

export const productSalesInvoiceCalculationNumberKeyNames = [
  'quantity',
  'quantity_available',
  'quantity_available_const',
  'price',
  'amount',
];

// correspoding on modul type with input modul container autocomplete
export const relationalModulTypeWithModulIDcorrespondInputValue = {
  [modulTypeSalesInvoice.manual]: {
    [DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF]: DEFAULT_KEY_NAME_MODUL_ID_API_REF,
    [DEFAULT_KEY_NAME_SALES_INVOICE_NO_API_REF]: DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF,
  },
  [modulTypeSalesInvoice.order]: {
    [DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF]: DEFAULT_KEY_NAME_MODUL_ID_API_REF,
    [DEFAULT_KEY_NAME_SALES_ORDER_NO_API_REF]: DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF,
  },
  [modulTypeSalesInvoice.delivery]: {
    [DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF]: DEFAULT_KEY_NAME_MODUL_ID_API_REF,
    [DEFAULT_KEY_NAME_SALES_DELIVERY_NO_API_REF]: DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF,
  },
};
