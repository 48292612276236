import { createAsyncThunk } from '@reduxjs/toolkit';

import { stockTransferServices } from '../../services/API/stock-transfer/stock-transfer.service';
import {
  REACT_APP_DATA_WAREHOUSE_TRANSFER_DETAILS_TEMPLATE_URL,
  REACT_APP_DATA_WAREHOUSE_TRANSFER_URL,
  REACT_APP_PRODUCT_URL,
} from '../../utils/configs/url.config';
import { STOCK_TRANSFER_ASYNC_THUNK_TYPE } from '../../utils/constants/action-type.constant';
import { LOCAL_STORAGE_STOCK_TRANSFER_LIST } from '../../utils/constants/storage.constant';
import {
  SLICE_NAME_STOCK_TRANSFER_DETAILS,
  SLICE_NAME_STOCK_TRANSFER_LIST,
  STATUS_REQUEST_LIST_STOCK_TRANSFER_FAILED,
  STATUS_REQUEST_LIST_STOCK_TRANSFER_PENDING,
  STATUS_REQUEST_LIST_STOCK_TRANSFER_SUCCESS,
} from '../../utils/constants/store.constant';
import { DEFAULT_KEY_NAME_TRANSFER_STOCK_ID_API_REF } from '../../utils/default/object-keyname.default';
import {
  DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
  DEFAULT_STOCK_TRANSFER_LIST_SEARCH_FE_PARAMS,
} from '../../utils/default/params.default';
import { DEFAULT_STATUS_PAGE_DETAIL_TRANSFER_WAREHOUSE } from '../../utils/default/status-page.default';
import objHelper from '../../utils/helpers/object.helper';
import strHelp from '../../utils/helpers/string.helpers';
import {
  creatorAddActionWithPreviewButton,
  creatorAddActionWithPreviewButtonGenerateLink,
  creatorDeleteAction,
  creatorDetailAction,
  creatorListActionDynamicSliceNameNproperties,
  creatorListActionWithDynamicSliceName,
  creatorListRefreshActionWithDynamicStore,
  creatorUpdateAction,
} from '../creator-action/creator-action.helper';

/**
 * stock transfer actions
 *
 */

// action getting stock transfer list with dynamic store data
export const getStockTransferListWithDynamicStore = createAsyncThunk(
  'stock-transfer/list-dynamic-store',
  async (
    {
      sliceName = SLICE_NAME_STOCK_TRANSFER_LIST,
      localStorageName = LOCAL_STORAGE_STOCK_TRANSFER_LIST,
      ...paramsGetStockTransferList
    },
    thunkAPI,
  ) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      paramsGetStockTransferList,
      stockTransferServices.getStockTransferList,
      localStorageName,
      sliceName,
      'transfer_stock_id',
      'error.list-data.stock-transfer',
    );
  },
);

// action getting refesh stock transfer with dynamic storing data on slice name
export const getStockTransferListRefreshWithDynamicStore = createAsyncThunk(
  'stock-transfer/list-refresh-dynamic-store',
  async (
    {
      sliceName = SLICE_NAME_STOCK_TRANSFER_LIST,
      localStorageName = LOCAL_STORAGE_STOCK_TRANSFER_LIST,
      discardKeysSearch = [],
    },
    thunkAPI,
  ) => {
    return await creatorListRefreshActionWithDynamicStore(
      thunkAPI,
      stockTransferServices.getStockTransferList,
      localStorageName,
      sliceName,
      discardKeysSearch,
      DEFAULT_STOCK_TRANSFER_LIST_SEARCH_FE_PARAMS,
      'error.list-data.stock-transfer',
    );
  },
);

export const getStockTransferList = createAsyncThunk(
  STOCK_TRANSFER_ASYNC_THUNK_TYPE.list,
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_STOCK_TRANSFER_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_STOCK_TRANSFER_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_STOCK_TRANSFER_FAILED,
      slicePayloadKeyname = SLICE_NAME_STOCK_TRANSFER_LIST,
      localStorageName = LOCAL_STORAGE_STOCK_TRANSFER_LIST,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...params
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params: params,
      apiService: stockTransferServices.getStockTransferList,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: DEFAULT_KEY_NAME_TRANSFER_STOCK_ID_API_REF,
      slicePayloadKeyname,
      errorMessage: 'error.list-data.stock-transfer',
    });
  },
);

// action get stock transfer details
export const getStockTransferDetails = createAsyncThunk(
  'stock-transfer/details',
  async (stockTransferID, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      stockTransferID,
      stockTransferServices.getStockTransferDetails,
      '',
      SLICE_NAME_STOCK_TRANSFER_DETAILS,
      'error.detail.stock-transfer',
    );
  },
);

// action delete stock transfer
export const deleteStockTransfer = createAsyncThunk(
  'stock-transfer/delete',
  async (paramsDeleteStockTransfer, thunkAPI) => {
    return await creatorDeleteAction(
      thunkAPI,
      paramsDeleteStockTransfer,
      stockTransferServices.deleteStockTransfer,
      'success.delete.stock-transfer',
      'error.delete.stock-transfer',
    );
  },
);

// action add transfer stock
export const addTransferStock = createAsyncThunk(
  'stock-transfer/add',
  async (bodyAddTransferStock, thunkAPI) => {
    return await creatorAddActionWithPreviewButton(
      thunkAPI,
      bodyAddTransferStock,
      stockTransferServices.addTransferStock,
      'success.add.transfer-warehouse',
      'error.add.transfer-warehouse',
      {},
      {},
      'transfer_stock_id',
      REACT_APP_PRODUCT_URL,
      { statusPage: DEFAULT_STATUS_PAGE_DETAIL_TRANSFER_WAREHOUSE },
    );
  },
);

// action add transfer stock version 2
export const addTransferStockV2 = createAsyncThunk(
  'stock-transfer/add-version-2',
  async (bodyAddTransferStock, thunkAPI) => {
    return await creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: bodyAddTransferStock,
      serviceFunc: stockTransferServices.addTransferStock,
      successMessage: 'success.add.sales-invoice',
      errorMessage: 'error.add.sales-invoice',
      baseContentUrl: REACT_APP_DATA_WAREHOUSE_TRANSFER_URL,
      generateButtonLink: (responseResult) => {
        const templateLinkDetails = {
          [DEFAULT_KEY_NAME_TRANSFER_STOCK_ID_API_REF]: objHelper.findAllValuesByKey(
            responseResult,
            DEFAULT_KEY_NAME_TRANSFER_STOCK_ID_API_REF,
          )[0],
        };

        return strHelp.templateString(
          REACT_APP_DATA_WAREHOUSE_TRANSFER_DETAILS_TEMPLATE_URL,
          templateLinkDetails,
        );
      },
    });
  },
);

// action update transfer stock
export const updateTransferStock = createAsyncThunk(
  'stock-transfer/update',
  async (bodyUpdateTransferStock, thunkAPI) => {
    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdateTransferStock,
      stockTransferServices.updateTransferStock,
      'success.update.transfer-warehouse',
      'error.update.transfer-warehouse',
      {},
      {},
    );
  },
);

const stockTransferActions = {
  getStockTransferListWithDynamicStore,
  getStockTransferListRefreshWithDynamicStore,
  getStockTransferDetails,
  deleteStockTransfer,
  addTransferStock,
  addTransferStockV2,
  updateTransferStock,
};

export default stockTransferActions;
