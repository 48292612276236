import {
  DEFAULT_KEY_NAME_PARENT_URL_SEARCH_PARAMS_OBJ,
  DEFAULT_KEY_NAME_SELECTED_TAB,
  DEFAULT_KEY_NAME_STATUS_PAGE,
} from './object-keyname.default';
import {
  DEFAULT_STATUS_PAGE_LIST_CASH_BANK_CASH_RECEIPT,
  DEFAULT_STATUS_PAGE_LIST_CASH_BANK_CASH_TRANSFER,
  DEFAULT_STATUS_PAGE_LIST_CASH_PAYMENT,
  DEFAULT_STATUS_PAGE_LIST_PRODUCT_STOCK_ADJUSTMENT,
  DEFAULT_STATUS_PAGE_LIST_PURCHASE_DELIVERY,
  DEFAULT_STATUS_PAGE_LIST_PURCHASE_INVOICE,
  DEFAULT_STATUS_PAGE_LIST_PURCHASE_ORDER,
  DEFAULT_STATUS_PAGE_LIST_PURCHASE_PAYMENT,
  DEFAULT_STATUS_PAGE_LIST_QUOTATION_REQUEST,
  DEFAULT_STATUS_PAGE_LIST_SALES_DELIVERY,
  DEFAULT_STATUS_PAGE_LIST_SALES_INVOICE,
  DEFAULT_STATUS_PAGE_LIST_SALES_ORDER,
  DEFAULT_STATUS_PAGE_LIST_SALES_PAYMENT,
  DEFAULT_STATUS_PAGE_LIST_SALES_QUOTATION,
  DEFAULT_STATUS_PAGE_LIST_TRANSFER_WAREHOUSE,
} from './status-page.default';
import {
  DEFAULT_TAB_NAME_CASH_BANK_CASH_PAYMENT,
  DEFAULT_TAB_NAME_CASH_BANK_CASH_RECEIPT,
  DEFAULT_TAB_NAME_CASH_BANK_CASH_TRANSFER,
  DEFAULT_TAB_NAME_DATA_PRODUCT_STOCK_ADJUSMENT,
  DEFAULT_TAB_NAME_DATA_PRODUCT_WAREHOUSE_TRANSFER,
  DEFAULT_TAB_NAME_PURCHASE_PURCHASE_DELIVERY,
  DEFAULT_TAB_NAME_PURCHASE_PURCHASE_INVOICE,
  DEFAULT_TAB_NAME_PURCHASE_PURCHASE_ORDER,
  DEFAULT_TAB_NAME_PURCHASE_PURCHASE_PAYMENT,
  DEFAULT_TAB_NAME_PURCHASE_QUOTATION_REQUEST,
  DEFAULT_TAB_NAME_SALES_SALES_DELIVERY,
  DEFAULT_TAB_NAME_SALES_SALES_INVOICE,
  DEFAULT_TAB_NAME_SALES_SALES_ORDER,
  DEFAULT_TAB_NAME_SALES_SALES_PAYMENT,
  DEFAULT_TAB_NAME_SALES_SALES_QUOTATION,
} from './tab-name.default';

export const DEFAULT_URL_DATA_PRODUCT_STOCK_ADJUSTMENT_SEARCH_PARAMS = {
  [DEFAULT_KEY_NAME_SELECTED_TAB]: DEFAULT_TAB_NAME_DATA_PRODUCT_STOCK_ADJUSMENT,
  [DEFAULT_KEY_NAME_STATUS_PAGE]: DEFAULT_STATUS_PAGE_LIST_PRODUCT_STOCK_ADJUSTMENT,
};

export const DEFAULT_URL_DATA_PRODUCT_WAREHOUSE_TRANSFER_SEARCH_PARAMS = {
  [DEFAULT_KEY_NAME_SELECTED_TAB]: DEFAULT_TAB_NAME_DATA_PRODUCT_WAREHOUSE_TRANSFER,
  [DEFAULT_KEY_NAME_STATUS_PAGE]: DEFAULT_STATUS_PAGE_LIST_TRANSFER_WAREHOUSE,
};

export const DEFAULT_URL_CASH_BANK_CASH_TRANSFER_SEARCH_PARAMS = {
  [DEFAULT_KEY_NAME_SELECTED_TAB]: DEFAULT_TAB_NAME_CASH_BANK_CASH_TRANSFER,
  [DEFAULT_KEY_NAME_STATUS_PAGE]: DEFAULT_STATUS_PAGE_LIST_CASH_BANK_CASH_TRANSFER,
};

export const DEFAULT_URL_CASH_BANK_CASH_RECEIPT_SEARCH_PARAMS = {
  [DEFAULT_KEY_NAME_SELECTED_TAB]: DEFAULT_TAB_NAME_CASH_BANK_CASH_RECEIPT,
  [DEFAULT_KEY_NAME_STATUS_PAGE]: DEFAULT_STATUS_PAGE_LIST_CASH_BANK_CASH_RECEIPT,
};

export const DEFAULT_URL_CASH_BANK_CASH_PAYMENT_SEARCH_PARAMS = {
  [DEFAULT_KEY_NAME_SELECTED_TAB]: DEFAULT_TAB_NAME_CASH_BANK_CASH_PAYMENT,
  [DEFAULT_KEY_NAME_STATUS_PAGE]: DEFAULT_STATUS_PAGE_LIST_CASH_PAYMENT,
};

// purchase
export const DEFAULT_URL_PURCHASE_QUOTATION_REQUEST_SEARCH_PARAMS = {
  [DEFAULT_KEY_NAME_SELECTED_TAB]: DEFAULT_TAB_NAME_PURCHASE_QUOTATION_REQUEST,
  [DEFAULT_KEY_NAME_STATUS_PAGE]: DEFAULT_STATUS_PAGE_LIST_QUOTATION_REQUEST,
};

export const DEFAULT_URL_PURCHASE_PURCHASE_ORDER_SEARCH_PARAMS = {
  [DEFAULT_KEY_NAME_SELECTED_TAB]: DEFAULT_TAB_NAME_PURCHASE_PURCHASE_ORDER,
  [DEFAULT_KEY_NAME_STATUS_PAGE]: DEFAULT_STATUS_PAGE_LIST_PURCHASE_ORDER,
};

export const DEFAULT_URL_PURCHASE_PURCHASE_DELIVERY_SEARCH_PARAMS = {
  [DEFAULT_KEY_NAME_SELECTED_TAB]: DEFAULT_TAB_NAME_PURCHASE_PURCHASE_DELIVERY,
  [DEFAULT_KEY_NAME_STATUS_PAGE]: DEFAULT_STATUS_PAGE_LIST_PURCHASE_DELIVERY,
};

export const DEFAULT_URL_PURCHASE_PURCHASE_INVOICE_SEARCH_PARAMS = {
  [DEFAULT_KEY_NAME_SELECTED_TAB]: DEFAULT_TAB_NAME_PURCHASE_PURCHASE_INVOICE,
  [DEFAULT_KEY_NAME_STATUS_PAGE]: DEFAULT_STATUS_PAGE_LIST_PURCHASE_INVOICE,
};

export const DEFAULT_URL_PURCHASE_PURCHASE_PAYMENT_SEARCH_PARAMS = {
  [DEFAULT_KEY_NAME_SELECTED_TAB]: DEFAULT_TAB_NAME_PURCHASE_PURCHASE_PAYMENT,
  [DEFAULT_KEY_NAME_STATUS_PAGE]: DEFAULT_STATUS_PAGE_LIST_PURCHASE_PAYMENT,
};

// sales
export const DEFAULT_URL_SALES_SALES_QUOTATION_SEARCH_PARAMS = {
  [DEFAULT_KEY_NAME_SELECTED_TAB]: DEFAULT_TAB_NAME_SALES_SALES_QUOTATION,
  [DEFAULT_KEY_NAME_STATUS_PAGE]: DEFAULT_STATUS_PAGE_LIST_SALES_QUOTATION,
};

export const DEFAULT_URL_SALES_SALES_ORDER_SEARCH_PARAMS = {
  [DEFAULT_KEY_NAME_SELECTED_TAB]: DEFAULT_TAB_NAME_SALES_SALES_ORDER,
  [DEFAULT_KEY_NAME_STATUS_PAGE]: DEFAULT_STATUS_PAGE_LIST_SALES_ORDER,
};

export const DEFAULT_URL_SALES_SALES_DELIVERY_SEARCH_PARAMS = {
  [DEFAULT_KEY_NAME_SELECTED_TAB]: DEFAULT_TAB_NAME_SALES_SALES_DELIVERY,
  [DEFAULT_KEY_NAME_STATUS_PAGE]: DEFAULT_STATUS_PAGE_LIST_SALES_DELIVERY,
};

export const DEFAULT_URL_SALES_SALES_INVOICE_SEARCH_PARAMS = {
  [DEFAULT_KEY_NAME_SELECTED_TAB]: DEFAULT_TAB_NAME_SALES_SALES_INVOICE,
  [DEFAULT_KEY_NAME_STATUS_PAGE]: DEFAULT_STATUS_PAGE_LIST_SALES_INVOICE,
};

export const DEFAULT_URL_SALES_SALES_PAYMENT_SEARCH_PARAMS = {
  [DEFAULT_KEY_NAME_SELECTED_TAB]: DEFAULT_TAB_NAME_SALES_SALES_PAYMENT,
  [DEFAULT_KEY_NAME_STATUS_PAGE]: DEFAULT_STATUS_PAGE_LIST_SALES_PAYMENT,
};

// search params object for app configuration
// purchase
export const defaultParentUrlSearchParamsObjPurchaseQuotationRequest = {
  [DEFAULT_KEY_NAME_PARENT_URL_SEARCH_PARAMS_OBJ]: {
    ...DEFAULT_URL_PURCHASE_QUOTATION_REQUEST_SEARCH_PARAMS,
  },
};

export const defaultParentUrlSearchParamsObjPurchasePurchaseOrder = {
  [DEFAULT_KEY_NAME_PARENT_URL_SEARCH_PARAMS_OBJ]: {
    ...DEFAULT_URL_PURCHASE_PURCHASE_ORDER_SEARCH_PARAMS,
  },
};

export const defaultParentUrlSearchParamsObjPurchasePurchaseDelivery = {
  [DEFAULT_KEY_NAME_PARENT_URL_SEARCH_PARAMS_OBJ]: {
    ...DEFAULT_URL_PURCHASE_PURCHASE_DELIVERY_SEARCH_PARAMS,
  },
};

export const defaultParentUrlSearchParamsObjPurchasePurchaseInvoice = {
  [DEFAULT_KEY_NAME_PARENT_URL_SEARCH_PARAMS_OBJ]: {
    ...DEFAULT_URL_PURCHASE_PURCHASE_INVOICE_SEARCH_PARAMS,
  },
};

export const defaultParentUrlSearchParamsObjPurchasePurchasePayment = {
  [DEFAULT_KEY_NAME_PARENT_URL_SEARCH_PARAMS_OBJ]: {
    ...DEFAULT_URL_PURCHASE_PURCHASE_PAYMENT_SEARCH_PARAMS,
  },
};

// sales
export const defaultParentUrlSearchParamsObjSalesSalesQuotation = {
  [DEFAULT_KEY_NAME_PARENT_URL_SEARCH_PARAMS_OBJ]: {
    ...DEFAULT_URL_SALES_SALES_QUOTATION_SEARCH_PARAMS,
  },
};

export const defaultParentUrlSearchParamsObjSalesSalesOrder = {
  [DEFAULT_KEY_NAME_PARENT_URL_SEARCH_PARAMS_OBJ]: {
    ...DEFAULT_URL_SALES_SALES_ORDER_SEARCH_PARAMS,
  },
};

export const defaultParentUrlSearchParamsObjSalesSalesDelivery = {
  [DEFAULT_KEY_NAME_PARENT_URL_SEARCH_PARAMS_OBJ]: {
    ...DEFAULT_URL_SALES_SALES_DELIVERY_SEARCH_PARAMS,
  },
};

export const defaultParentUrlSearchParamsObjSalesSalesInvoice = {
  [DEFAULT_KEY_NAME_PARENT_URL_SEARCH_PARAMS_OBJ]: {
    ...DEFAULT_URL_SALES_SALES_INVOICE_SEARCH_PARAMS,
  },
};

export const defaultParentUrlSearchParamsObjSalesSalesPayment = {
  [DEFAULT_KEY_NAME_PARENT_URL_SEARCH_PARAMS_OBJ]: {
    ...DEFAULT_URL_SALES_SALES_PAYMENT_SEARCH_PARAMS,
  },
};

// data product stock adjustment
export const defaultParentUrlSearchParamsObjDataProductStockAdjustment = {
  [DEFAULT_KEY_NAME_PARENT_URL_SEARCH_PARAMS_OBJ]: {
    ...DEFAULT_URL_DATA_PRODUCT_STOCK_ADJUSTMENT_SEARCH_PARAMS,
  },
};

// cash bank - cash transfer
export const defaultParentUrlSearchParamsObjDataCashBankCashTransfer = {
  [DEFAULT_KEY_NAME_PARENT_URL_SEARCH_PARAMS_OBJ]: {
    ...DEFAULT_URL_CASH_BANK_CASH_TRANSFER_SEARCH_PARAMS,
  },
};

// cash bank - cash receipt
export const defaultParentUrlSearchParamsObjDataCashBankCashReceipt = {
  [DEFAULT_KEY_NAME_PARENT_URL_SEARCH_PARAMS_OBJ]: {
    ...DEFAULT_URL_CASH_BANK_CASH_RECEIPT_SEARCH_PARAMS,
  },
};

// cash bank - cash payment
export const defaultParentUrlSearchParamsObjDataCashBankCashPayment = {
  [DEFAULT_KEY_NAME_PARENT_URL_SEARCH_PARAMS_OBJ]: {
    ...DEFAULT_URL_CASH_BANK_CASH_PAYMENT_SEARCH_PARAMS,
  },
};
