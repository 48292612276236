// initial search transaction data
export const initialSearchPurchaseDelivery = {
  from_date: null,
  to_date: null,
  purchase_delivery_no: '',
  supplier_name: '',
};

export const initialPurchaseDeliverySearch = () => {
  return {
    from_date: null,
    to_date: null,
    purchase_delivery_no: '',
    supplier_name: '',
    supplier_name_container: null,
    status: [],
    statusContainer: [],
    purchase_handover_status: [],
    purchase_handover_status_container: [],
  };
};
