import axios from 'axios';

import {
  REACT_APP_API_URL_USER,
  REACT_APP_API_URL_USER_COMPANY,
} from '../../../utils/configs/api.config';
import { DEFAULT_SETTING_COMPANY_LIST_PARAMS } from '../../../utils/default/params.default';
import { authHeader } from '../../../utils/helpers/auth-header.helpers';
import { creatorListService } from '../creator-services/creator-services.helper';

// get detail profile user
const detailUser = async () => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  return await axios({
    method: 'GET',
    url: `${REACT_APP_API_URL_USER}/profile`,
    headers: {
      Authorization,
    },
  });
};

// changing data user profile
const updateUser = async ({
  user_fullname,
  address,
  profile_picture,
  current_password,
  password,
  password_confirmation,
}) => {
  const { Authorization } = authHeader();

  if (!Authorization || !current_password) {
    return null;
  }

  const dataUpdateProfile = {
    user_fullname,
    current_password,
  };

  if (password || password_confirmation) {
    dataUpdateProfile.password = password;
    dataUpdateProfile.password_confirmation = password_confirmation;
  }

  if (address) {
    dataUpdateProfile.address = address;
  }

  if (profile_picture) {
    dataUpdateProfile.profile_picture = profile_picture;
  }

  return await axios({
    method: 'POST',
    url: `${REACT_APP_API_URL_USER}/profile`,
    data: {
      ...dataUpdateProfile,
    },
    headers: {
      Authorization,
    },
  });
};

// service list user company
const getListCompanyUser = async (paramsGetListCompany) => {
  return await creatorListService(
    REACT_APP_API_URL_USER_COMPANY,
    paramsGetListCompany,
    DEFAULT_SETTING_COMPANY_LIST_PARAMS,
  );
};

const userServices = {
  detailUser,
  updateUser,
  getListCompanyUser,
};

export default userServices;
