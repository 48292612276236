import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_SUB_ACCOUNT } from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  STATUS_REQUEST_ADD_SUB_ACCOUNT_FAILED,
  STATUS_REQUEST_ADD_SUB_ACCOUNT_PENDING,
  STATUS_REQUEST_ADD_SUB_ACCOUNT_SUCCESS,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_LIST_SUB_ACCOUNT_FAILED,
  STATUS_REQUEST_LIST_SUB_ACCOUNT_PENDING,
  STATUS_REQUEST_LIST_SUB_ACCOUNT_SUCCESS,
} from '../../utils/constants/store.constant';
import { DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS } from '../../utils/default/params.default';
import formatHelp from '../../utils/helpers/format.helpers';
import objHelper from '../../utils/helpers/object.helper';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import { addSubAccount, getSubAccountList } from './sub-account.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  subAccountData: LocalStorage.get(LOCAL_STORAGE_SUB_ACCOUNT),
};

const subAccountSlice = createSlice({
  name: NAME_STORE.SUB_ACCOUNT,
  initialState,
  reducers: {
    getSubAccountList,
    addSubAccount,
    clearSubAccountData: (state) => {
      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        accountData: null,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      subAccountData: LOCAL_STORAGE_SUB_ACCOUNT,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getSubAccountList.fulfilled, (state, action) => {
      state.statusRequest = STATUS_REQUEST_LIST_SUB_ACCOUNT_SUCCESS;

      if (!isEmpty(action.payload.subAccountData)) {
        action.payload.subAccountData = objHelper.changeFormatValueV2(
          action.payload.subAccountData,
          DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
          null,
          formatHelp.changeFormatAccountName,
        );
      }

      state.subAccountData = action.payload.subAccountData;
    }),
      builder.addCase(getSubAccountList.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_SUB_ACCOUNT_FAILED;
      }),
      builder.addCase(getSubAccountList.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_SUB_ACCOUNT_PENDING;
      }),
      builder.addCase(addSubAccount.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SUB_ACCOUNT_SUCCESS;
      }),
      builder.addCase(addSubAccount.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SUB_ACCOUNT_FAILED;
      }),
      builder.addCase(addSubAccount.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SUB_ACCOUNT_PENDING;
      });
  },
});

export const { actions, reducer } = subAccountSlice;

export const { clearSubAccountData } = actions;

export default reducer;
