import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { MULTIPLE_BASE_TYPES_ASSET_SLICE } from '../../utils/constants/action-type.constant';
import {
  LOCAL_STORAGE_ASSET_CATEGORY,
  LOCAL_STORAGE_ASSET_CATEGORY_PRINT,
  LOCAL_STORAGE_ASSET_MANAGEMENT,
  LOCAL_STORAGE_ASSET_MANAGEMENT_PRINT,
} from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  SLICE_NAME_ASSET_CATEGORY_PRINT_PDF_DATA,
  SLICE_NAME_ASSET_MANAGEMENT_PRINT_PDF_DATA,
  STATUS_REQUEST_ADD_ASSET_CATEGORY_FAILED,
  STATUS_REQUEST_ADD_ASSET_CATEGORY_PENDING,
  STATUS_REQUEST_ADD_ASSET_CATEGORY_SUCCESS,
  STATUS_REQUEST_ADD_ASSET_MANAGEMENT_FAILED,
  STATUS_REQUEST_ADD_ASSET_MANAGEMENT_PENDING,
  STATUS_REQUEST_ADD_ASSET_MANAGEMENT_SUCCESS,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_DELETE_ASSET_CATEGORY_FAILED,
  STATUS_REQUEST_DELETE_ASSET_CATEGORY_PENDING,
  STATUS_REQUEST_DELETE_ASSET_CATEGORY_SUCCESS,
  STATUS_REQUEST_DELETE_ASSET_MANAGEMENT_FAILED,
  STATUS_REQUEST_DELETE_ASSET_MANAGEMENT_PENDING,
  STATUS_REQUEST_DELETE_ASSET_MANAGEMENT_SUCCESS,
  STATUS_REQUEST_DETAIL_ASSET_CATEGORY_FAILED,
  STATUS_REQUEST_DETAIL_ASSET_CATEGORY_PENDING,
  STATUS_REQUEST_DETAIL_ASSET_CATEGORY_SUCCESS,
  STATUS_REQUEST_DETAIL_ASSET_MANAGEMENT_FAILED,
  STATUS_REQUEST_DETAIL_ASSET_MANAGEMENT_PENDING,
  STATUS_REQUEST_DETAIL_ASSET_MANAGEMENT_SUCCESS,
  STATUS_REQUEST_LIST_ASSET_CATEGORY_FAILED,
  STATUS_REQUEST_LIST_ASSET_CATEGORY_PENDING,
  STATUS_REQUEST_LIST_ASSET_CATEGORY_SUCCESS,
  STATUS_REQUEST_LIST_ASSET_MANAGEMENT_FAILED,
  STATUS_REQUEST_LIST_ASSET_MANAGEMENT_PENDING,
  STATUS_REQUEST_LIST_ASSET_MANAGEMENT_SUCCESS,
  STATUS_REQUEST_REVERT_ASSET_MANAGEMENT_FAILED,
  STATUS_REQUEST_REVERT_ASSET_MANAGEMENT_PENDING,
  STATUS_REQUEST_REVERT_ASSET_MANAGEMENT_SUCCESS,
  STATUS_REQUEST_SALE_ASSET_MANAGEMENT_FAILED,
  STATUS_REQUEST_SALE_ASSET_MANAGEMENT_PENDING,
  STATUS_REQUEST_SALE_ASSET_MANAGEMENT_SUCCESS,
  STATUS_REQUEST_UPDATE_ASSET_CATEGORY_FAILED,
  STATUS_REQUEST_UPDATE_ASSET_CATEGORY_PENDING,
  STATUS_REQUEST_UPDATE_ASSET_CATEGORY_SUCCESS,
  STATUS_REQUEST_UPDATE_ASSET_MANAGEMENT_FAILED,
  STATUS_REQUEST_UPDATE_ASSET_MANAGEMENT_PENDING,
  STATUS_REQUEST_UPDATE_ASSET_MANAGEMENT_SUCCESS,
} from '../../utils/constants/store.constant';
import { assetAsyncThunkTypeWithActionProps } from '../../utils/data/mapping/async-thunk-type-with-action-props.mapping';
import {
  bodyBuilderFulfilledAction,
  bodyBuilderPendingAction,
  bodyBuilderRejectedAction,
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
  sliceReduceHelper,
} from '../../utils/helpers/slice-reducer.helper';

import {
  addAssetCategory,
  addAssetManagement,
  deleteAssetCategory,
  deleteAssetManagement,
  getDetailAssetCategory,
  getDetailAssetManagement,
  getListAssetCategory,
  getListAssetCategoryRefresh,
  getListAssetManagement,
  getListAssetManagementRefresh,
  revertAssetManagement,
  saleAssetManagement,
  updateAssetCategory,
  updateAssetManagement,
} from './asset.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  // asset management
  assetManagementData: LocalStorage.get(LOCAL_STORAGE_ASSET_MANAGEMENT),
  [SLICE_NAME_ASSET_MANAGEMENT_PRINT_PDF_DATA]: LocalStorage.get(
    LOCAL_STORAGE_ASSET_MANAGEMENT_PRINT,
  ),
  assetManagementDetail: null,
  // asset category
  assetCategoryData: LocalStorage.get(LOCAL_STORAGE_ASSET_CATEGORY),
  [SLICE_NAME_ASSET_CATEGORY_PRINT_PDF_DATA]: LocalStorage.get(LOCAL_STORAGE_ASSET_CATEGORY_PRINT),
  assetCategoryDetail: null,
};

const assetSlice = createSlice({
  name: NAME_STORE.ASSET,
  initialState,
  reducers: {
    getListAssetCategory,
    getListAssetCategoryRefresh,
    getDetailAssetCategory,
    addAssetCategory,
    updateAssetCategory,
    deleteAssetCategory,
    getListAssetManagement,
    getListAssetManagementRefresh,
    getDetailAssetManagement,
    deleteAssetManagement,
    addAssetManagement,
    updateAssetManagement,
    saleAssetManagement,
    revertAssetManagement,
    clearDataAssetSlice: (state, action) => {
      const { sliceName, localStorageName, backToIddleRequestStatus = false } = action.payload;

      if (LocalStorage.get(sliceName)) {
        LocalStorage.remove(sliceName);
      } else if (localStorageName) {
        if (LocalStorage.get(localStorageName)) LocalStorage.remove(localStorageName);
      }

      return {
        ...state,
        [sliceName]: null,
        statusRequest: backToIddleRequestStatus ? STATUS_REQUEST_BASE_IDDLE : state.statusRequest,
      };
    },
    clearAssetSliceItem: (state, action) => {
      sliceReduceHelper.clearSliceItem({
        state,
        action,
      });
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      assetManagementData: LOCAL_STORAGE_ASSET_MANAGEMENT,
      [SLICE_NAME_ASSET_MANAGEMENT_PRINT_PDF_DATA]: LOCAL_STORAGE_ASSET_MANAGEMENT_PRINT,
      assetCategoryData: LOCAL_STORAGE_ASSET_CATEGORY,
      [SLICE_NAME_ASSET_CATEGORY_PRINT_PDF_DATA]: LOCAL_STORAGE_ASSET_CATEGORY_PRINT,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getListAssetCategory.fulfilled, (state, action) => {
      state.assetCategoryData = action.payload.assetCategoryData;
      state.statusRequest = STATUS_REQUEST_LIST_ASSET_CATEGORY_SUCCESS;
    }),
      builder.addCase(getListAssetCategory.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_ASSET_CATEGORY_PENDING;
      }),
      builder.addCase(getListAssetCategory.rejected, (state) => {
        state.assetCategoryData = null;
        state.statusRequest = STATUS_REQUEST_LIST_ASSET_CATEGORY_FAILED;
      }),
      builder.addCase(getListAssetCategoryRefresh.fulfilled, (state, action) => {
        state.assetCategoryData = action.payload.assetCategoryData;
        state.statusRequest = STATUS_REQUEST_LIST_ASSET_CATEGORY_SUCCESS;
      }),
      builder.addCase(getListAssetCategoryRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_ASSET_CATEGORY_PENDING;
      }),
      builder.addCase(getListAssetCategoryRefresh.rejected, (state) => {
        state.assetCategoryData = null;
        state.statusRequest = STATUS_REQUEST_LIST_ASSET_CATEGORY_FAILED;
      }),
      builder.addCase(getDetailAssetCategory.fulfilled, (state, action) => {
        state.assetCategoryDetail = action.payload.assetCategoryDetail;
        state.statusRequest = STATUS_REQUEST_DETAIL_ASSET_CATEGORY_SUCCESS;
      }),
      builder.addCase(getDetailAssetCategory.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_ASSET_CATEGORY_PENDING;
      }),
      builder.addCase(getDetailAssetCategory.rejected, (state) => {
        state.assetCategoryDetail = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_ASSET_CATEGORY_FAILED;
      }),
      builder.addCase(addAssetCategory.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_ASSET_CATEGORY_SUCCESS;
      }),
      builder.addCase(addAssetCategory.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_ASSET_CATEGORY_PENDING;
      }),
      builder.addCase(addAssetCategory.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_ASSET_CATEGORY_FAILED;
      }),
      builder.addCase(updateAssetCategory.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_ASSET_CATEGORY_SUCCESS;
      }),
      builder.addCase(updateAssetCategory.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_ASSET_CATEGORY_PENDING;
      }),
      builder.addCase(updateAssetCategory.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_ASSET_CATEGORY_FAILED;
      }),
      builder.addCase(deleteAssetCategory.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_ASSET_CATEGORY_SUCCESS;
      }),
      builder.addCase(deleteAssetCategory.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_ASSET_CATEGORY_PENDING;
      }),
      builder.addCase(deleteAssetCategory.rejected, (state) => {
        state.assetCategoryData = null;
        state.statusRequest = STATUS_REQUEST_DELETE_ASSET_CATEGORY_FAILED;
      }),
      builder.addCase(getListAssetManagement.fulfilled, (state, action) => {
        state.assetManagementData = action.payload.assetManagementData;
        state.statusRequest = STATUS_REQUEST_LIST_ASSET_MANAGEMENT_SUCCESS;
      }),
      builder.addCase(getListAssetManagement.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_ASSET_MANAGEMENT_PENDING;
      }),
      builder.addCase(getListAssetManagement.rejected, (state) => {
        state.assetManagementData = null;
        state.statusRequest = STATUS_REQUEST_LIST_ASSET_MANAGEMENT_FAILED;
      }),
      builder.addCase(getListAssetManagementRefresh.fulfilled, (state, action) => {
        state.assetManagementData = action.payload.assetManagementData;
        state.statusRequest = STATUS_REQUEST_LIST_ASSET_MANAGEMENT_SUCCESS;
      }),
      builder.addCase(getListAssetManagementRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_ASSET_MANAGEMENT_PENDING;
      }),
      builder.addCase(getListAssetManagementRefresh.rejected, (state) => {
        state.assetManagementData = null;
        state.statusRequest = STATUS_REQUEST_LIST_ASSET_MANAGEMENT_FAILED;
      }),
      builder.addCase(getDetailAssetManagement.fulfilled, (state, action) => {
        state.assetManagementDetail = action.payload.assetManagementDetail;
        state.statusRequest = STATUS_REQUEST_DETAIL_ASSET_MANAGEMENT_SUCCESS;
      }),
      builder.addCase(getDetailAssetManagement.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_ASSET_MANAGEMENT_PENDING;
      }),
      builder.addCase(getDetailAssetManagement.rejected, (state) => {
        state.assetManagementDetail = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_ASSET_MANAGEMENT_FAILED;
      }),
      builder.addCase(deleteAssetManagement.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_ASSET_MANAGEMENT_SUCCESS;
      }),
      builder.addCase(deleteAssetManagement.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_ASSET_MANAGEMENT_PENDING;
      }),
      builder.addCase(deleteAssetManagement.rejected, (state) => {
        state.assetManagementData = null;
        state.statusRequest = STATUS_REQUEST_DELETE_ASSET_MANAGEMENT_FAILED;
      }),
      builder.addCase(addAssetManagement.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_ASSET_MANAGEMENT_SUCCESS;
      }),
      builder.addCase(addAssetManagement.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_ASSET_MANAGEMENT_PENDING;
      }),
      builder.addCase(addAssetManagement.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_ASSET_MANAGEMENT_FAILED;
      }),
      builder.addCase(updateAssetManagement.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_ASSET_MANAGEMENT_SUCCESS;
      }),
      builder.addCase(updateAssetManagement.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_ASSET_MANAGEMENT_PENDING;
      }),
      builder.addCase(updateAssetManagement.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_ASSET_MANAGEMENT_FAILED;
      }),
      builder.addCase(saleAssetManagement.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_SALE_ASSET_MANAGEMENT_SUCCESS;
      }),
      builder.addCase(saleAssetManagement.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_SALE_ASSET_MANAGEMENT_PENDING;
      }),
      builder.addCase(saleAssetManagement.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_SALE_ASSET_MANAGEMENT_FAILED;
      }),
      builder.addCase(revertAssetManagement.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_REVERT_ASSET_MANAGEMENT_SUCCESS;
      }),
      builder.addCase(revertAssetManagement.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_REVERT_ASSET_MANAGEMENT_PENDING;
      }),
      builder.addCase(revertAssetManagement.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_REVERT_ASSET_MANAGEMENT_FAILED;
      }),
      // enhancement for matching request status action on asset management and asset category
      // current: only include list
      builder.addMatcher(
        (action) =>
          isFulfilledAction({ action, multipleBaseTypes: MULTIPLE_BASE_TYPES_ASSET_SLICE }),
        (state, action) => {
          bodyBuilderFulfilledAction({
            state,
            action,
            mappingAsyncThunkTypeWithActionProps: assetAsyncThunkTypeWithActionProps,
          });
        },
      ),
      builder.addMatcher(
        (action) => isPendingAction({ action, multipleBaseTypes: MULTIPLE_BASE_TYPES_ASSET_SLICE }),
        (state, action) => {
          bodyBuilderPendingAction({
            state,
            action,
            mappingAsyncThunkTypeWithActionProps: assetAsyncThunkTypeWithActionProps,
          });
        },
      ),
      builder.addMatcher(
        (action) =>
          isRejectedAction({ action, multipleBaseTypes: MULTIPLE_BASE_TYPES_ASSET_SLICE }),
        (state, action) => {
          bodyBuilderRejectedAction({
            state,
            action,
            mappingAsyncThunkTypeWithActionProps: assetAsyncThunkTypeWithActionProps,
          });
        },
      );
  },
});

export const { actions, reducer } = assetSlice;

export const { clearDataAssetSlice, clearAssetSliceItem } = actions;

export default reducer;
