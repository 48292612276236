import { cloneDeep, isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF } from '../../default/object-keyname.default';
import arrHelp from '../../helpers/array.helpers';
import objHelper from '../../helpers/object.helper';

import { generalizeAttachmentFromDetails } from './initial-add-attachment';
import {
  generalizeDataAddExpense,
  generateDataNValidationAddDestinationAccount,
  generateDataNValidationAddExpense,
} from './initial-add-expense';
import {
  allowKeysExpenseData,
  expenseType,
  initialDestinationAccountData,
  initialDestinationAccountValidation,
  initialExpenseValidation,
  plainExpenseDataWithoutID,
} from './initial-data-expense';

/**
 *
 * @param { object } detailPurchaseInvoice data detail expense after hit detail on API
 * @param { string } customDataKey name key of return data
 * @param { string } customValidationKey name key of return validation
 * @returns
 *      generated data and validation expense from detail expense
 */
export const generateDataNValidationUpdateExpenseFromDetailExpense = ({
  detailExpense,
  companyUserData = {},
  customDataKey = 'expenseData',
  customValidationKey = 'expenseValidation',
}) => {
  if (typeof detailExpense !== 'object') {
    return generateDataNValidationAddExpense({
      amount: 1,
      customDataKey,
      customValidationKey,
    });
  }

  const {
    expense_id,
    transaction_id,
    transaction_no,
    contact_name,
    contact_code,
    account_origin_name,
    account_origin_id,
    account_origin_code,
    description,
    type,
    type_module_id,
    type_module_number,
    discount_type,
    type_module,
  } = cloneDeep(detailExpense);

  detailExpense = objHelper.filterKeyObj(detailExpense, [], allowKeysExpenseData);

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  // default type modul id and container that from manual, that using origin expense
  let type_module_id_container = {
    purchase_invoice_id: '',
    purchase_invoice_no: '',
  };

  if (typeof type === 'string' && type.toLowerCase() === expenseType.linked) {
    type_module_id_container = {
      purchase_invoice_id: type_module_id,
      purchase_invoice_no: type_module_number,
    };
  }

  const expenseData = usedID.map((id) => {
    return {
      id,
      ...plainExpenseDataWithoutID,
      ...detailExpense,
      accounting_period: !isEmpty(companyUserData)
        ? companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF]
        : null,
      attachment: generalizeAttachmentFromDetails({
        dataDetails: detailExpense,
      }),
      expense_id,
      description: description || '',
      type,
      type_module: !isEmpty(type_module) ? type_module : 'purchase',
      type_module_id,
      type_module_id_container,
      contact_id_container: {
        contact_name,
        contact_code: contact_code,
      },
      account_origin_id,
      account_origin_id_container: {
        account_id: account_origin_id,
        account_name: account_origin_name,
        account_code: account_origin_code,
      },
      reference_number: transaction_id,
      reference_number_container: {
        value: transaction_no,
      },
      discount_type: discount_type ? discount_type : '',
    };
  });

  const expenseValidation = usedID.map((id) => {
    return initialExpenseValidation(id, '', []);
  });

  return {
    [customDataKey]: expenseData,
    [customValidationKey]: expenseValidation,
  };
};

/**
 *
 * @param { object } detailExpense data detail expense after hit detail on API
 * @param { string } customDataKey key name return of data
 * @param { string } customValidationKey key name return of validation
 * @returns
 *      generated destination account data and validation from detail expense
 */
export const generateDataNValidationUpdateDestinationAccountFromDetailExpense = (
  detailExpense,
  customDataKey = 'destinationAccountData',
  customValidationKey = 'destinationAccountValidation',
) => {
  if (typeof detailExpense !== 'object') {
    return;
  }

  const { expense_data: destinationAccount } = detailExpense;

  if (Array.isArray(destinationAccount)) {
    if (!destinationAccount.length) {
      const newID = uuidv4();

      return {
        [customDataKey]: [initialDestinationAccountData(newID)],
        [customValidationKey]: [initialDestinationAccountValidation(newID)],
      };
    }
  }

  const usedID = arrHelp.generateArrWithFunc(destinationAccount.length, uuidv4);

  let destinationAccountData = usedID.map((id, index) => {
    const { account_id, account_name, account_code, amount } = destinationAccount[index];

    return {
      ...initialDestinationAccountData(id),
      ...destinationAccount[index],
      account_destination_id: account_id,
      account_destination_id_container: {
        account_id,
        account_name,
        account_code,
      },
      amount: Number(amount),
    };
  });

  const {
    destinationAccountData: destinationAccountDataGen,
    destinationAccountValidation: destinationAccountValidationGen,
  } = generateDataNValidationAddDestinationAccount(1);

  let destinationAccountValidation = usedID.map((id) => {
    return initialDestinationAccountValidation(id);
  });

  destinationAccountData = destinationAccountData.concat(destinationAccountDataGen);
  destinationAccountValidation = destinationAccountValidation.concat(
    destinationAccountValidationGen,
  );

  return {
    [customDataKey]: destinationAccountData,
    [customValidationKey]: destinationAccountValidation,
  };
};

/**
 *
 * @param { array } expenseData expense data for generalized
 * @param { arrat } destinationAccountData destination account on expense data
 * @returns
 *      generalized data for updating on expense
 */
export const generalizeDataUpdateExpense = (expenseData, destinationAccountData) => {
  let commonGeneralizeDataWithoutIndentity = generalizeDataAddExpense(
    cloneDeep(expenseData),
    cloneDeep(destinationAccountData),
  );

  // adding identity for update data
  if (Array.isArray(expenseData)) {
    const { expense_id } = expenseData[0];

    commonGeneralizeDataWithoutIndentity = {
      ...commonGeneralizeDataWithoutIndentity,
      expense_id,
    };
  }

  return commonGeneralizeDataWithoutIndentity;
};
