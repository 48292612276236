import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_PROVINCE } from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_LIST_PROVINCE_FAILED,
  STATUS_REQUEST_LIST_PROVINCE_PENDING,
  STATUS_REQUEST_LIST_PROVINCE_SUCCESS,
} from '../../utils/constants/store.constant';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import { provinceList } from './province.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  provinceData: LocalStorage.get(LOCAL_STORAGE_PROVINCE),
};

const provinceSlice = createSlice({
  name: NAME_STORE.PROVINCE,
  initialState,
  reducer: {
    provinceList,
    clearProvince: (state) => {
      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      provinceData: LOCAL_STORAGE_PROVINCE,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(provinceList.fulfilled, (state, action) => {
      state.statusRequest = STATUS_REQUEST_LIST_PROVINCE_SUCCESS;
      state.provinceData = action.payload.provinceData;
    }),
      builder.addCase(provinceList.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PROVINCE_FAILED;
      }),
      builder.addCase(provinceList.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PROVINCE_PENDING;
      });
  },
});

export const { actions, reducer } = provinceSlice;

export const { clearProvince } = actions;

export default reducer;
