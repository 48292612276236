/* eslint-disable no-undef */

// removing suffix in url react application
let getBaseUrlApp = process.env.REACT_APP_API_URL.split('/').filter((url) => url);

getBaseUrlApp = getBaseUrlApp.slice(0, 2);
getBaseUrlApp = getBaseUrlApp.join('//');

export const REACT_APP_BASE_URL = getBaseUrlApp;
export const REACT_APP_STORAGE_URL = `${REACT_APP_BASE_URL}/storage`;

export const REACT_APP_DASHBOARD_URL = '/';

export const REACT_APP_LOGIN_URL = '/login';

export const REACT_APP_COMPANY_SETUP_URL = '/company-setup';

export const REACT_APP_CASH_BANK_BASE_URL = '/cash-bank';
export const REACT_APP_CASH_BANK_CASH_PAYMENT_URL = '/cash-bank/payment-cash';
export const REACT_APP_CASH_BANK_CASH_RECEIPT_URL = '/cash-bank/receipt-cash';
export const REACT_APP_CASH_BANK_CASH_TRANSFER_URL = '/cash-bank/cash-transfer';

// data menu
export const REACT_APP_DATA_ACCOUNT_URL = '/data/account';
export const REACT_APP_DATA_ACCOUNT_CREATE_URL = '/data/account/create';
export const REACT_APP_DATA_ACCOUNT_ADD_MULTIPLE_URL = '/data/account/add-multiple';
export const REACT_APP_DATA_ACCOUNT_DETAILS_TEMPLATE_URL = '/data/account/{account_id}';
export const REACT_APP_DATA_ACCOUNT_UPDATE_TEMPLATE_URL = '/data/account/{account_id}/update';

// purchase root

export const REACT_APP_PURCHASE_URL = '/purchase';

export const REACT_APP_PURCHASE_JOURNAL_DETAILS_TEMPLATE_URL =
  '/purchase/journal/{transaction_id}/details';
export const REACT_APP_PURCHASE_DELIVERY_JOURNAL_DETAILS_TEMPLATE_URL =
  '/purchase/purchase-delivery/{purchase_delivery_id}/journal/{transaction_id}';
export const REACT_APP_PURCHASE_INVOICE_JOURNAL_DETAILS_TEMPLATE_URL =
  '/purchase/purchase-invoice/{purchase_invoice_id}/journal/{transaction_id}';
export const REACT_APP_PURCHASE_PAYMENT_JOURNAL_DETAILS_TEMPLATE_URL =
  '/purchase/purchase-payment/{purchase_payment_id}/journal/{transaction_id}';
export const REACT_APP_PURCHASE_RETURN_JOURNAL_DETAILS_TEMPLATE_URL =
  '/purchase/return/{purchase_return_id}/journal/{transaction_id}';

// quotation request purchase
export const REACT_APP_PURCHASE_QUOTATION_REQUEST_URL = '/purchase/quotation-request';
export const REACT_APP_PURCHASE_QUOTATION_REQUEST_CREATE_NEW_URL =
  '/purchase/quotation-request/create-new';
export const REACT_APP_PURCHASE_QUOTATION_REQUEST_DETAILS_TEMPLATE_URL =
  '/purchase/quotation-request/{quotation_request_id}';
export const REACT_APP_PURCHASE_QUOTATION_REQUEST_DETAILS_HISTORY_TEMPLATE_URL =
  '/purchase/quotation-request/{quotation_request_id}/details/history';
export const REACT_APP_PURCHASE_QUOTATION_REQUEST_UPDATE_TEMPLATE_URL =
  '/purchase/quotation-request/{quotation_request_id}/update';

// purchase order
export const REACT_APP_PURCHASE_PURCHASE_ORDER_URL = '/purchase/purchase-order';
export const REACT_APP_PURCHASE_PURCHASE_ORDER_CREATE_NEW_URL =
  '/purchase/purchase-order/create-new';
export const REACT_APP_PURCHASE_PURCHASE_ORDER_DETAILS_TEMPLATE_URL =
  '/purchase/purchase-order/{purchase_order_id}';
export const REACT_APP_PURCHASE_PURCHASE_ORDER_DETAILS_HISTORY_TEMPLATE_URL =
  '/purchase/purchase-order/{purchase_order_id}/details/history';
export const REACT_APP_PURCHASE_PURCHASE_ORDER_UPDATE_TEMPLATE_URL =
  '/purchase/purchase-order/{purchase_order_id}/update';

// purchase delivery
export const REACT_APP_PURCHASE_DELIVERY_URL = '/purchase/purchase-delivery';
export const REACT_APP_PURCHASE_PURCHASE_DELIVERY_URL = '/purchase/purchase-delivery';
export const REACT_APP_PURCHASE_PURCHASE_DELIVERY_CREATE_NEW_URL =
  '/purchase/purchase-delivery/create-new';
export const REACT_APP_PURCHASE_PURCHASE_DELIVERY_DETAILS_TEMPLATE_URL =
  '/purchase/purchase-delivery/{purchase_delivery_id}';
export const REACT_APP_PURCHASE_PURCHASE_DELIVERY_DETAILS_HISTORY_TEMPLATE_URL =
  '/purchase/purchase-delivery/{purchase_delivery_id}/details/history';
export const REACT_APP_PURCHASE_PURCHASE_DELIVERY_HANDOVER_DETAILS_TEMPLATE_URL =
  '/purchase/purchase-delivery/{purchase_delivery_id}/details/{purchase_handover_id}/handover';
export const REACT_APP_PURCHASE_PURCHASE_DELIVERY_HANDOVER_DETAILS_TEMPLATE_V2_URL =
  '/purchase/purchase-delivery/{purchase_handover_id}/handover';
export const REACT_APP_PURCHASE_PURCHASE_DELIVERY_HANDOVER_JOURNAL_TEMPLATE_URL =
  '/purchase/purchase-delivery/{purchase_delivery_id}/details/{purchase_handover_id}/handover/journal/{transaction_id}';
export const REACT_APP_PURCHASE_PURCHASE_DELIVERY_UPDATE_TEMPLATE_URL =
  '/purchase/purchase-delivery/{purchase_delivery_id}/update';
export const REACT_APP_PURCHASE_PURCHASE_DELIVERY_CREATE_NEW_HANDOVER_TEMPLATE_URL =
  '/purchase/purchase-delivery/{purchase_delivery_id}/handover-create-new';
export const REACT_APP_PURCHASE_PURCHASE_DELIVERY_HANDOVER_UPDATE_TEMPLATE_URL =
  '/purchase/purchase-delivery/{purchase_delivery_id}/update/{purchase_handover_id}/handover';

// purchase invoice
export const REACT_APP_PURCHASE_PURCHASE_INVOICE_URL = '/purchase/purchase-invoice';
export const REACT_APP_PURCHASE_PURCHASE_INVOICE_CREATE_NEW_URL =
  '/purchase/purchase-invoice/create-new';
export const REACT_APP_PURCHASE_PURCHASE_INVOICE_DETAILS_TEMPLATE_URL =
  '/purchase/purchase-invoice/{purchase_invoice_id}';
export const REACT_APP_PURCHASE_PURCHASE_INVOICE_DETAILS_HISTORY_TEMPLATE_URL =
  '/purchase/purchase-invoice/{purchase_invoice_id}/details/history';
export const REACT_APP_PURCHASE_PURCHASE_INVOICE_UPDATE_TEMPLATE_URL =
  '/purchase/purchase-invoice/{purchase_invoice_id}/update';

// purchase payment
export const REACT_APP_PURCHASE_PURCHASE_PAYMENT = '/purchase/purchase-payment';
export const REACT_APP_PURCHASE_PURCHASE_PAYMENT_URL = '/purchase/purchase-payment';
export const REACT_APP_PURCHASE_PURCHASE_PAYMENT_CREATE_NEW_URL =
  '/purchase/purchase-payment/create-new';
export const REACT_APP_PURCHASE_PURCHASE_PAYMENT_DETAILS_TEMPLATE_URL =
  '/purchase/purchase-payment/{purchase_payment_id}';
export const REACT_APP_PURCHASE_PURCHASE_PAYMENT_DETAILS_HISTORY_TEMPLATE_URL =
  '/purchase/purchase-payment/{purchase_payment_id}/details/history';
export const REACT_APP_PURCHASE_PURCHASE_PAYMENT_UPDATE_TEMPLATE_URL =
  '/purchase/purchase-payment/{purchase_payment_id}/update';

export const REACT_APP_SALES_URL = '/sales';

export const REACT_APP_SALES_JOURNAL_DETAILS_TEMPLATE_URL =
  '/sales/journal/{transaction_id}/details';
export const REACT_APP_SALES_DELIVERY_JOURNAL_DETAILS_TEMPLATE_URL =
  '/sales/sales-delivery/{sales_delivery_id}/journal/{transaction_id}';
export const REACT_APP_SALES_INVOICE_JOURNAL_DETAILS_TEMPLATE_URL =
  '/sales/sales-invoice/{sales_invoice_id}/journal/{transaction_id}';
export const REACT_APP_SALES_PAYMENT_JOURNAL_DETAILS_TEMPLATE_URL =
  '/sales/sales-payment/{sales_payment_id}/journal/{transaction_id}';
export const REACT_APP_SALES_RETURN_JOURNAL_DETAILS_TEMPLATE_URL =
  '/sales/return/{sales_return_id}/journal/{transaction_id}';

export const REACT_APP_SALES_SALES_ORDER_URL = '/sales/sales-order';
export const REACT_APP_SALES_SALES_ORDER_CREATE_NEW_URL = '/sales/sales-order/create-new';
export const REACT_APP_SALES_SALES_ORDER_DETAILS_TEMPLATE_URL =
  '/sales/sales-order/{sales_order_id}';
export const REACT_APP_SALES_SALES_ORDER_DETAILS_HISTORY_TEMPLATE_URL =
  '/sales/sales-order/{sales_order_id}/details/history';
export const REACT_APP_SALES_SALES_ORDER_UPDATE_TEMPLATE_URL =
  '/sales/sales-order/{sales_order_id}/update';

export const REACT_APP_SALES_PAYMENT_URL = '/sales/sales-payment';

export const REACT_APP_SALES_QUOTATION_URL = '/sales/sales-quotation';
export const REACT_APP_SALES_SALES_QUOTATION_URL = '/sales/sales-quotation';
export const REACT_APP_SALES_SALES_QUOTATION_CREATE_NEW_URL = '/sales/sales-quotation/create-new';
export const REACT_APP_SALES_SALES_QUOTATION_DETAILS_TEMPLATE_URL =
  '/sales/sales-quotation/{sales_quotation_id}';
export const REACT_APP_SALES_SALES_QUOTATION_DETAILS_HISTORY_TEMPLATE_URL =
  '/sales/sales-quotation/{sales_quotation_id}/details/history';
export const REACT_APP_SALES_SALES_QUOTATION_UPDATE_TEMPLATE_URL =
  '/sales/sales-quotation/{sales_quotation_id}/update';

// sales delivery
export const REACT_APP_SALES_SALES_DELIVERY_URL = '/sales/sales-delivery';
export const REACT_APP_SALES_SALES_DELIVERY_CREATE_NEW_URL = '/sales/sales-delivery/create-new';
export const REACT_APP_SALES_SALES_DELIVERY_DETAILS_TEMPLATE_URL =
  '/sales/sales-delivery/{sales_delivery_id}';
export const REACT_APP_SALES_SALES_DELIVERY_DETAILS_HISTORY_TEMPLATE_URL =
  '/sales/sales-delivery/{sales_delivery_id}/details/history';
export const REACT_APP_SALES_SALES_DELIVERY_HANDOVER_DETAILS_TEMPLATE_URL =
  '/sales/sales-delivery/{sales_delivery_id}/details/{sales_handover_id}/handover';
export const REACT_APP_SALES_SALES_DELIVERY_HANDOVER_DETAILS_TEMPLATE_V2_URL =
  '/sales/sales-delivery/{sales_handover_id}/handover';
export const REACT_APP_SALES_SALES_DELIVERY_UPDATE_TEMPLATE_URL =
  '/sales/sales-delivery/{sales_delivery_id}/update';
export const REACT_APP_SALES_SALES_DELIVERY_CREATE_NEW_HANDOVER_TEMPLATE_URL =
  '/sales/sales-delivery/{sales_delivery_id}/handover-create-new';
export const REACT_APP_SALES_SALES_DELIVERY_HANDOVER_UPDATE_TEMPLATE_URL =
  '/sales/sales-delivery/{sales_delivery_id}/update/{sales_handover_id}/handover';

export const REACT_APP_SALES_SALES_INVOICE_URL = '/sales/sales-invoice';
export const REACT_APP_SALES_SALES_INVOICE_CREATE_NEW_URL = '/sales/sales-invoice/create-new';
export const REACT_APP_SALES_SALES_INVOICE_DETAILS_TEMPLATE_URL =
  '/sales/sales-invoice/{sales_invoice_id}';
export const REACT_APP_SALES_SALES_INVOICE_DETAILS_HISTORY_TEMPLATE_URL =
  '/sales/sales-invoice/{sales_invoice_id}/details/history';
export const REACT_APP_SALES_SALES_INVOICE_UPDATE_TEMPLATE_URL =
  '/sales/sales-invoice/{sales_invoice_id}/update';

export const REACT_APP_SALES_SALES_PAYMENT_URL = '/sales/sales-payment';
export const REACT_APP_SALES_SALES_PAYMENT_CREATE_NEW_URL = '/sales/sales-payment/create-new';
export const REACT_APP_SALES_SALES_PAYMENT_DETAILS_TEMPLATE_URL =
  '/sales/sales-payment/{sales_payment_id}';
export const REACT_APP_SALES_SALES_PAYMENT_DETAILS_HISTORY_TEMPLATE_URL =
  '/sales/sales-payment/{sales_payment_id}/details/history';
export const REACT_APP_SALES_SALES_PAYMENT_UPDATE_TEMPLATE_URL =
  '/sales/sales-payment/{sales_payment_id}/update';

export const REACT_APP_ASSET_CATEGORY_URL = '/data/asset-category';
export const REACT_APP_ASSET_MANAGEMENT_URL = '/data/asset';

export const REACT_APP_SALES_INVOICE_URL = '/sales/sales-invoice';

export const REACT_APP_WAREHOUSE_URL = '/setting/warehouse';

export const REACT_APP_TRANSFER_WAREHOUSE_URL = '/product/transfer-warehouse';

// data product
export const REACT_APP_PRODUCT_URL = '/data/product';
export const REACT_APP_DATA_PRODUCT_URL = '/data/product';
export const REACT_APP_DATA_PRODUCT_CREATE_URL = '/data/product/create';
export const REACT_APP_PRODUCT_DETAILS_TEMPALTE_URL = '/data/product/{product_id}';
export const REACT_APP_DATA_PRODUCT_UPDATE_TEMPALTE_URL = '/data/product/{product_id}/update';

// data stock adjustment
export const REACT_APP_DATA_STOCK_ADJUSTMENT_URL = '/data/stock-adjustment';
export const REACT_APP_DATA_STOCK_ADJUSTMENT_CREATE_URL = '/data/stock-adjustment/create';
export const REACT_APP_DATA_STOCK_ADJUSTMENT_DETAILS_TEMPLATE_URL =
  '/data/stock-adjustment/{stock_adjustment_id}';
export const REACT_APP_DATA_STOCK_ADJUSTMENT_JOURNAL_ENTRY_DETAILS_TEMPLATE_URL =
  '/data/stock-adjustment/{stock_adjustment_id}/journal/{transaction_id}';
export const REACT_APP_DATA_STOCK_ADJUSTMENT_UPDATE_TEMPLATE_URL =
  '/data/stock-adjustment/{stock_adjustment_id}/update';

// data warehouse transfer
export const REACT_APP_DATA_WAREHOUSE_TRANSFER_URL = '/data/warehouse-transfer';
export const REACT_APP_DATA_WAREHOUSE_TRANSFER_CREATE_URL = '/data/warehouse-transfer/create';
export const REACT_APP_DATA_WAREHOUSE_TRANSFER_DETAILS_TEMPLATE_URL =
  '/data/warehouse-transfer/{transfer_stock_id}';
export const REACT_APP_DATA_WAREHOUSE_TRANSFER_UPDATE_TEMPLATE_URL =
  '/data/warehouse-transfer/{transfer_stock_id}/update';

export const REACT_APP_STOCK_URL = '/basic/stock';

export const REACT_APP_DATA_ACCOUNT_GENERAL_JOURNAL_URL = '/data/account/general-journal';
export const REACT_APP_DATA_GENERAL_JOURNAL_URL = '/data/general-journal';

export const REACT_APP_EXPENSE_URL = '/expense';
export const REACT_APP_EXPENSE_CREATE_NEW_URL = '/expense/create-new';
export const REACT_APP_EXPENSE_DETAILS_TEMPLATE_URL = '/expense/{expense_id}';
export const REACT_APP_EXPENSE_DETAILS_HISTORY_TEMPLATE_URL =
  '/expense/{expense_id}/details/history';
export const REACT_APP_EXPENSE_UPDATE_TEMPLATE_URL = '/expense/{expense_id}/update';

export const REACT_APP_BASIC_SALES_BASIC_URL = '/basic/sales-basic';
export const REACT_APP_BASIC_PURCHASE_BASIC_URL = '/basic/purchase-basic';

export const REACT_APP_DATA_ACCOUNT_CLOSING_BOOK_URL = '/data/account/closing-book';
export const REACT_APP_LEDGER_JOURNAL_ENTRY_URL = '/ledger/journal-entry';

export const REACT_APP_DATA_ACCOUNT_BEGINING_BALANCE_URL = '/data/account/begining-balance';
export const REACT_APP_DATA_ACCOUNT_BEGINNING_BALANCE_URL = '/data/account/beginning-balance';

export const REACT_APP_COMPANY_REGISTER_URL = '/company-register';
export const REACT_APP_COMPANY_BRANCH_REGISTER_URL = '/company-branch-register';
export const REACT_APP_CHOOSE_COMPANY_URL = '/choose-company';

export const REACT_APP_DATA_ACCOUNT_CATEGORY_URL = '/data/account-category';
export const REACT_APP_DATA_ASSET_CATEGORY_URL = '/data/asset-category';
export const REACT_APP_DATA_CONTACT_URL = '/data/contact';
export const REACT_APP_DATA_PRODUCT_CATEGORY_URL = '/data/product-category';

// setting menu
export const REACT_APP_SETTING_COMPANY_URL = '/setting/company';
export const REACT_APP_SETTING_WAREHOUSE_URL = '/setting/warehouse';
export const REACT_APP_SETTING_REFERENCE_NUMBER_URL = '/setting/reference-number';
export const REACT_APP_SETTING_BEGINING_BALANCE_URL = '/setting/begining-balance';

// user setting menu
export const REACT_APP_SETTING_USER_URL = '/setting/user';
export const REACT_APP_SETTING_USER_DETAILS_TEMPLATE_URL = `${REACT_APP_SETTING_USER_URL}/{user_id}`;

// closing book setting menu
export const REACT_APP_SETTING_CLOSING_BOOK_URL = '/setting/closing-book';
export const REACT_APP_SETTING_CLOSING_BOOK_DETAILS_URL = `${REACT_APP_SETTING_CLOSING_BOOK_URL}/details/{closing_book_id}`;
export const REACT_APP_SETTING_CLOSING_BOOK_CREATE_URL = `${REACT_APP_SETTING_CLOSING_BOOK_URL}/create/{closing_book_id}`;

export const REACT_APP_DATA_PRODUCT_STOCK_ADJUSTMENT = '/data/product/stock-adjustment';
export const REACT_APP_DATA_PRODUCT_STOCK_ADJUSTMENT_CREATE =
  '/data/product/stock-adjustment/create';

export const REACT_APP_BILLING_URL = '/billing';
export const REACT_APP_BILLING_DETAILS_TEMPLATE_URL = '/billing/{company_bill_no}';

// purchase return
export const REACT_APP_PURCHASE_RETURN_URL = '/purchase/return';
export const REACT_APP_PURCHASE_RETURN_CREATE_NEW_URL = '/purchase/return/create-new';
export const REACT_APP_PURCHASE_RETURN_DETAILS_TEMPLATE_URL =
  '/purchase/return/{purchase_return_id}';
export const REACT_APP_PURCHASE_RETURN_UPDATE_TEMPLATE_URL =
  '/purchase/return/{purchase_return_id}/update';

// purchase debit note
export const REACT_APP_PURCHASE_DEBIT_NOTE_URL = '/purchase/debit-note';
export const REACT_APP_PURCHASE_DEBIT_NOTE_DETAILS_TEMPLATE_URL =
  '/purchase/debit-note/{debit_note_id}';

// purchase receivable payment
export const REACT_APP_PURCHASE_RECEIVABLE_PAYMENT_URL = '/purchase/receivable-payment';
export const REACT_APP_PURCHASE_RECEIVABLE_PAYMENT_CREATE_NEW_URL =
  '/purchase/receivable-payment/create-new';
export const REACT_APP_PURCHASE_RECEIVABLE_PAYMENT_DETAILS_TEMPLATE_URL =
  '/purchase/receivable-payment/{receivable_payment_id}';
export const REACT_APP_PURCHASE_RECEIVABLE_PAYMENT_UPDATE_TEMPLATE_URL =
  '/purchase/receivable-payment/{receivable_payment_id}/update';

// sales return
export const REACT_APP_SALES_RETURN_URL = '/sales/return';
export const REACT_APP_SALES_RETURN_CREATE_NEW_URL = '/sales/return/create-new';
export const REACT_APP_SALES_RETURN_DETAILS_TEMPLATE_URL = '/sales/return/{sales_return_id}';
export const REACT_APP_SALES_RETURN_UPDATE_TEMPLATE_URL = '/sales/return/{sales_return_id}/update';

// sales credit note
export const REACT_APP_SALES_CREDIT_NOTE_URL = '/sales/credit-note';
export const REACT_APP_SALES_CREDIT_NOTE_DETAILS_TEMPLATE_URL =
  '/sales/credit-note/{credit_note_id}';

// sales payable payment
export const REACT_APP_SALES_PAYABLE_PAYMENT_URL = '/sales/payable-payment';
export const REACT_APP_SALES_PAYABLE_PAYMENT_CREATE_NEW_URL = '/sales/payable-payment/create-new';
export const REACT_APP_SALES_PAYABLE_PAYMENT_DETAILS_TEMPLATE_URL =
  '/sales/payable-payment/{payable_payment_id}';
export const REACT_APP_SALES_PAYABLE_PAYMENT_UPDATE_TEMPLATE_URL =
  '/sales/payable-payment/{payable_payment_id}/update';

// data asset
export const REACT_APP_DATA_ASSET_URL = '/data/asset';
export const REACT_APP_DATA_ASSET_CREATE_NEW_URL = '/data/asset/create-new';
export const REACT_APP_DATA_ASSET_DETAILS_TEMPLATE_URL = '/data/asset/{asset_id}';
export const REACT_APP_DATA_ASSET_UPDATE_TEMPLATE_URL = '/data/asset/{asset_id}/update';
export const REACT_APP_DATA_ASSET_JOURNAL_DETAILS_TEMPLATE_URL =
  '/data/asset/{asset_id}/journal/{transaction_id}';

// data contact
export const REACT_APP_DATA_CONTACT_CREATE_URL = '/data/contact/create';
export const REACT_APP_DATA_CONTACT_DETAILS_TEMPLATE_URL = '/data/contact/{contact_id}';
export const REACT_APP_DATA_CONTACT_UPDATE_TEMPLATE_URL = '/data/contact/{contact_id}/update';

// cash transfer
export const REACT_APP_CASH_BANK_CASH_TRANSFER_CREATE_URL = '/cash-bank/cash-transfer/create';
export const REACT_APP_CASH_BANK_CASH_TRANSFER_DETAILS_TEMPLATE_URL =
  '/cash-bank/cash-transfer/{transaction_id}';
export const REACT_APP_CASH_BANK_CASH_TRANSFER_JOURNAL_REPORT_DETAILS_TEMPLATE_URL =
  '/cash-bank/cash-transfer/{transaction_id}/journal-report';
export const REACT_APP_CASH_BANK_CASH_TRANSFER_UPDATE_TEMPLATE_URL =
  '/cash-bank/cash-transfer/{transaction_id}/update';

// cash receipt
export const REACT_APP_CASH_BANK_CASH_RECEIPT_CREATE_URL = '/cash-bank/cash-receipt/create';
export const REACT_APP_CASH_BANK_CASH_RECEIPT_DETAILS_TEMPLATE_URL =
  '/cash-bank/cash-receipt/{transaction_id}';
export const REACT_APP_CASH_BANK_CASH_RECEIPT_JOURNAL_REPORT_DETAILS_TEMPLATE_URL =
  '/cash-bank/cash-receipt/{transaction_id}/journal-report';
export const REACT_APP_CASH_BANK_CASH_RECEIPT_UPDATE_TEMPLATE_URL =
  '/cash-bank/cash-receipt/{transaction_id}/update';

// cash payment
export const REACT_APP_CASH_BANK_CASH_PAYMENT_CREATE_URL = '/cash-bank/cash-payment/create';
export const REACT_APP_CASH_BANK_CASH_PAYMENT_DETAILS_TEMPLATE_URL =
  '/cash-bank/cash-payment/{transaction_id}';
export const REACT_APP_CASH_BANK_CASH_PAYMENT_JOURNAL_REPORT_DETAILS_TEMPLATE_URL =
  '/cash-bank/cash-payment/{transaction_id}/journal-report';
export const REACT_APP_CASH_BANK_CASH_PAYMENT_UPDATE_TEMPLATE_URL =
  '/cash-bank/cash-payment/{transaction_id}/update';

// cash bank base
export const REACT_APP_CASH_BANK_TRANSACTION_ACCOUNT =
  '/cash-bank/{account_id}/account-transaction';

// report
export const REACT_APP_REPORT_SALES_STOCK = '/report/sales-stock';
export const REACT_APP_REPORT_STOCK_CARD = '/report/stock-card';
export const REACT_APP_REPORT_STOCK_PURCHASE = '/report/stock-purchase';
export const REACT_APP_REPORT_STOCK_ADJUSTMENT = '/report/stock-adjustment';
export const REACT_APP_REPORT_STOCK_MUTATION = '/report/stock-mutation';
