import {
  FORMAT_MAPPING_TRANSLATION_COLUMN as FORMAT_MAPPING_TRANSLATION_COLUMN_TABLE_KEY_VALUE,
  FORMAT_PERIOD_DATE_COLUMN,
} from '../../../components/table/TableKeyValuePureMarkup/TableKeyValuePureMarkup';
import {
  FORMAT_COMMON_COLUMN,
  FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
  FORMAT_DATE_COLUMN,
  FORMAT_MAPPING_TRANSLATION_COLUMN,
} from '../../../components/table-row/TableRowPureMarkup/TableRowPureMarkup';
import { DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF } from '../../default/object-keyname.default';
import { mappingPurchaseInvoiceStatus } from '../label-value/purchase-status';

import {
  primaryTableDataGreyBackgroundNgreyOnlyTopBorder,
  primaryTableDataGreyBackgroundNgreyOnlyTopBottom,
  secondarySubtitleCalculationNumberParagraph,
  secondarySubtitleCalculationNumberParagraphReduceFontWight,
} from './additional-styles';

// column cash transfer list that handling table head with translation
export const columnsTablePurchaseInvoice = (t) => {
  return [
    {
      name: 'transaction_date',
      name_input: 'from_date',
      label: t('dashboard.purchase.purchase-invoice.table.header-column.transaction-date'),
      placeholder: t(
        'dashboard.purchase.purchase-invoice.input.search-transaction-date.placeholder',
      ),
      width: 150,
    },
    {
      name: 'purchase_invoice_no',
      name_input: 'transaction_no',
      label: t('dashboard.purchase.purchase-invoice.table.header-column.transaction-no'),
      placeholder: t('dashboard.purchase.purchase-invoice.input.search-transaction-no.placeholder'),
      width: 200,
    },
    {
      name: 'supplier_name',
      name_input: 'supplier_name',
      label: t('dashboard.purchase.purchase-invoice.table.header-column.supplier'),
      placeholder: t('dashboard.purchase.purchase-invoice.input.search-supplier-name.placeholder'),
      width: 150,
    },
    {
      name: 'amount_available',
      label: t('dashboard.purchase.purchase-invoice.table.header-column.nominal'),
      placeholder: t('dashboard.purchase.purchase-invoice.input.search-transaction-no.placeholder'),
      align: 'right',
      width: 150,
    },
    {
      name: 'status',
      name_input: 'status',
      label: t('dashboard.purchase.purchase-invoice.table.header-column.status'),
      placeholder: t('dashboard.purchase.purchase-invoice.input.search-status.placeholder'),
      width: 50,
      maxWidth: 55,
    },
  ];
};

export const columnsSearchPurchaseInvoiceWithDate = ['from_date', 'to_date'];

export const columnsCalculationNumberPurchaseInvoice = ['amount_available'];

export const columnsTableFooterProductPurchaseInvoiceList = (t) => {
  return [
    [
      {
        isEmptyTableData: true,
        colSpan: 1,
      },
      {
        isLabel: true,
        label: t('typography.discount'),
        colSpan: 2,
        typographyStyle: secondarySubtitleCalculationNumberParagraphReduceFontWight,
        tableDataStyle: primaryTableDataGreyBackgroundNgreyOnlyTopBorder,
      },
      {
        isValue: true,
        valueKeyName: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
        colSpan: 2,
        substituteNotExistValue: 0,
        typographyStyle: secondarySubtitleCalculationNumberParagraphReduceFontWight,
        tableDataStyle: primaryTableDataGreyBackgroundNgreyOnlyTopBorder,
      },
    ],
    [
      {
        isEmptyTableData: true,
        colSpan: 1,
      },
      {
        isLabel: true,
        label: t('typography.total'),
        colSpan: 2,
        typographyStyle: secondarySubtitleCalculationNumberParagraph,
        tableDataStyle: primaryTableDataGreyBackgroundNgreyOnlyTopBottom,
      },
      {
        isValue: true,
        valueKeyName: 'amount',
        colSpan: 2,
        substituteNotExistValue: 0,
        typographyStyle: secondarySubtitleCalculationNumberParagraph,
        tableDataStyle: primaryTableDataGreyBackgroundNgreyOnlyTopBottom,
      },
    ],
  ];
};

export const columnsTableHeaderPurchaseInvoicePDFprint = (t) => {
  return [
    {
      name: 'transaction_date',
      label: t('dashboard.purchase.purchase-invoice.table.header-column.transaction-date'),
      style: {
        width: 200,
        position: 'unset',
      },
      formatData: FORMAT_DATE_COLUMN,
    },
    {
      name: 'purchase_invoice_no',
      label: t('dashboard.purchase.purchase-invoice.table.header-column.transaction-no'),
      style: {
        width: 200,
        position: 'unset',
      },
      formatData: FORMAT_COMMON_COLUMN,
    },
    {
      name: 'supplier_name',
      label: t('dashboard.purchase.purchase-invoice.table.header-column.supplier'),
      style: {
        width: 400,
        position: 'unset',
      },
      formatData: FORMAT_COMMON_COLUMN,
    },
    {
      name: 'amount_available',
      label: t('dashboard.purchase.purchase-invoice.table.header-column.nominal'),
      style: {
        width: 150,
        maxWidth: 155,
        position: 'unset',
      },
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      isUseZeroNumberWhenNull: true,
      align: 'right',
    },
    {
      name: 'status',
      label: t('dashboard.purchase.purchase-invoice.table.header-column.status'),
      style: {
        width: 150,
        maxWidth: 155,
        position: 'unset',
      },
      formatData: FORMAT_MAPPING_TRANSLATION_COLUMN,
      mappingTranslation: mappingPurchaseInvoiceStatus,
    },
  ];
};

// columns key value table
export const columnsTableKeyValuePrintIndexPurchaseInvoice = [
  {
    header: 'dashboard.purchase.purchase-invoice.table.header-column.transaction-date',
    valueKeyName: 'search_from_date',
    valueKeyNameSecondary: 'search_to_date',
    formatValue: FORMAT_PERIOD_DATE_COLUMN,
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'dashboard.purchase.purchase-invoice.table.header-column.transaction-no',
    valueKeyName: 'search_transaction_no',
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'dashboard.purchase.purchase-invoice.table.header-column.supplier',
    valueKeyName: 'search_supplier_name',
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'dashboard.purchase.purchase-invoice.table.header-column.status',
    valueKeyName: 'search_status',
    formatValue: FORMAT_MAPPING_TRANSLATION_COLUMN_TABLE_KEY_VALUE,
    mappingTranslation: mappingPurchaseInvoiceStatus,
    showOnlyWhenExistedValue: true,
  },
];
