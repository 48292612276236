import * as React from 'react';
function SvgIconMenuPurchasePurchaseOrder(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.857 68.571a.429.429 0 11-.857 0 .429.429 0 01.857 0zM6.857 68.143h58.286a.428.428 0 110 .857H6.857a.429.429 0 010-.857zM2.571 68.143h2.143a.428.428 0 110 .857H2.571a.429.429 0 010-.857zM69.429 68.143h-2.143a.428.428 0 100 .857h2.143a.429.429 0 000-.857zM72 68.571a.429.429 0 11-.857 0 .429.429 0 01.857 0z'
        fill='#000'
      />
      <path
        d='M19.167 68.16a4.502 4.502 0 01-.826-2.097 4.886 4.886 0 01.251-2.304c.265-.723.698-1.336 1.245-1.767a2.965 2.965 0 011.838-.66c.652 0 1.291.23 1.838.66.547.43.98 1.044 1.245 1.767.266.722.353 1.522.25 2.304a4.502 4.502 0 01-.825 2.097h-2.507c.596 0 1.169-.288 1.59-.8.422-.51.66-1.204.66-1.927 0-.54-.133-1.067-.38-1.515a2.407 2.407 0 00-1.01-1.005 1.887 1.887 0 00-1.3-.155 2.162 2.162 0 00-1.152.746 2.945 2.945 0 00-.616 1.396 3.267 3.267 0 00.128 1.576c.17.498.459.924.828 1.224.37.3.805.46 1.25.46h-2.507zM32.667 68.16a4.503 4.503 0 01-.825-2.097 4.886 4.886 0 01.25-2.304c.266-.723.698-1.336 1.245-1.767a2.965 2.965 0 011.838-.66c.653 0 1.291.23 1.838.66.547.43.98 1.044 1.245 1.767.266.722.353 1.522.25 2.304a4.502 4.502 0 01-.824 2.097h-2.508c.596 0 1.169-.288 1.59-.8.422-.51.66-1.204.66-1.927 0-.54-.133-1.067-.38-1.515a2.407 2.407 0 00-1.01-1.005 1.887 1.887 0 00-1.3-.155 2.162 2.162 0 00-1.151.746c-.315.381-.53.867-.617 1.396a3.268 3.268 0 00.128 1.576c.17.498.459.924.829 1.224.37.3.805.46 1.25.46h-2.508z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.887 38.477a.823.823 0 00-.073.514c.016.088.046.17.089.244a.625.625 0 00.159.183c.062.048.132.08.205.095l4.514.941 6.095 18.26c.127.377.345.7.625.925.28.225.61.344.947.34h21.665c.149 0 .292-.073.398-.2a.761.761 0 00.164-.483c0-.18-.059-.354-.164-.482a.518.518 0 00-.398-.2H17.448a.504.504 0 01-.317-.11.677.677 0 01-.215-.302l-.461-1.387 21.294-.982c.245-.012.48-.12.67-.308a1.43 1.43 0 00.382-.736l1.968-9.831c.04-.2.041-.41.005-.611a1.51 1.51 0 00-.216-.552 1.2 1.2 0 00-.392-.383.97.97 0 00-.49-.139h-27.74l-1.23-3.681a.694.694 0 00-.17-.275.528.528 0 00-.257-.141L5.458 38.17a.464.464 0 00-.22.007.519.519 0 00-.2.108.644.644 0 00-.151.192zm8.412 8.888l.456 1.363h25.102l.273-1.364H13.3zm-.908-2.728l.453 1.364h26.558l.273-1.364H12.391zm3.614 10.834l-1.794-5.38h24.373l-.878 4.378-21.701 1.002zM50.665 41.307c0 .553-.425 1.003-.948 1.003h-5.834c-.523 0-.948-.45-.948-1.003v-2.65c0-.553.425-1.003.948-1.003h5.834c.523 0 .948.45.948 1.003v2.65zm-1.194-2.39h-5.342v2.13h5.342v-2.13zm2.122 18.068c0-.553.425-1.003.948-1.003h5.834c.523 0 .948.45.948 1.003v2.65c0 .553-.425 1.003-.948 1.003h-5.834c-.522 0-.948-.45-.948-1.003v-2.65zm1.194 2.389h5.342v-2.128h-5.341v2.128z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M66.309 62.456h-1.596v-2.45c0-.35-.267-.632-.597-.632-.33 0-.597.283-.597.632v2.45H47.397V45.391h5.397v4.235c0 .357.172.682.46.868.288.186.64.2.94.037l1.264-.686 1.265.686a.92.92 0 00.439.113.92.92 0 00.5-.15.987.987 0 00.339-.37c.08-.152.122-.324.122-.498v-4.235h5.38c.01 0 .016.007.016.016v11.727c0 .349.267.632.597.632.33 0 .597-.283.597-.633V45.409c0-.706-.542-1.28-1.21-1.28h-7.448V27.08c0-.706-.542-1.28-1.21-1.28h-16.09c-.668 0-1.21.575-1.21 1.28v3.17c0 .167.063.328.175.446a.581.581 0 00.422.185c.158 0 .31-.066.422-.185a.651.651 0 00.175-.447v-3.168c0-.01.007-.017.016-.017h5.38v4.235c0 .357.172.682.46.868a.92.92 0 00.501.15c.15 0 .3-.037.44-.113l1.264-.686 1.265.686c.3.163.651.15.94-.037a.987.987 0 00.337-.37c.081-.152.123-.324.122-.498v-4.235h5.382l.01.005.005.011v17.048H43.505v1.264h2.698v17.063h-4.105v1.265h23.908v1.193H42.098v1.264h18.945v1.699c0 .62.476 1.124 1.062 1.124h1.737c.586 0 1.063-.504 1.063-1.124v-1.7h1.404c.491 0 .891-.422.891-.942v-1.835c0-.52-.4-.943-.891-.943zm-38.715 2.457h3.29v1.264h-.994v1.699h.994V69h-1.126c-.586 0-1.063-.504-1.063-1.124v-1.7h-1.404c-.491 0-.891-.422-.891-.942v-1.835c0-.52.4-.943.891-.943h1.596v-2.433h1.194v2.432h.803v1.265h-3.29v1.193zM38.74 33.12v5.504h-1.194V33.12c0-.167.063-.328.175-.447a.581.581 0 01.422-.185c.158 0 .31.067.422.185.112.119.175.28.175.447zm8.5-2.784l1.031.56v-3.832h-2.94v3.831l1.032-.56a.92.92 0 01.877 0zm9.69 15.056h-2.941v3.831l1.032-.56a.92.92 0 01.877 0l1.031.56v-3.831zm5.308 22.484h1.473v-1.7h-1.473v1.7z'
        fill='#000'
      />
      <path
        d='M24.637 24h2.25v10.79l1.463-1.453 1.586 1.59-3.375 3.375c-.21.21-.496.323-.793.323-.297 0-.582-.114-.793-.323L21.6 34.927l1.586-1.59 1.451 1.454V24z'
        fill='#000'
      />
    </svg>
  );
}
export default SvgIconMenuPurchasePurchaseOrder;
