import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import { DEFAULT_DISCOUNT_PERCENTAGE_TYPE } from '../../default/discount-type.default';
import { DEFAULT_TRANSACTION_NUMBER_EXPENSE } from '../../default/transaction-number.default';
import validation from '../../helpers/validation.helpers';

const initialID = uuidV4();

export const plainExpenseDataWithoutID = {
  contact_id: null,
  contact_id_container: null,
  isNewContact: false,
  transaction_date: moment(),
  reference_number: DEFAULT_TRANSACTION_NUMBER_EXPENSE,
  reference_number_container: {
    value: DEFAULT_TRANSACTION_NUMBER_EXPENSE,
  },
  type_module_id: null,
  type_module_id_container: null,
  account_origin_id: null,
  account_origin_id_container: null,
  description: '',
  type: 'detached',
  type_module: 'purchase',
  amount: 0,
  amount_const: 0,
  accounting_period: null,
  discount_account_id: '',
  discount_account_id_container: '',
  discount_type: DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
  discount_percentage: '',
  discount_nominal: '',
  uuid_attachment: null,
  attachment: [],
  tag: [],
  tagContainer: [],
  tagNameInput: '',
};

export const initialExpenseData = (additionalID = '', newID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainExpenseDataWithoutID,
  };
};

export const plainExpenseValidationWithoutID = {
  contact_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  transaction_date: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  reference_number: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  type_module_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  account_origin_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  discount_type: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  discount_percentage: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  discount_nominal: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  discount_account_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const initialExpenseValidation = (additionalID = '', newID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainExpenseValidationWithoutID,
  };
};

export const plainDestinationAccountDataWithoutID = {
  account_destination_id: null,
  account_destination_id_container: null,
  amount: '',
  description: '',
};

export const initialDestinationAccountData = (additionalID = '', newID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainDestinationAccountDataWithoutID,
  };
};

export const plainDestinationAccountValidationWithoutID = {
  account_destination_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  amount: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const initialDestinationAccountValidation = (additionalID = '', newID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainDestinationAccountValidationWithoutID,
  };
};

export const expenseType = {
  detached: 'detached',
  linked: 'linked',
};

export const allowKeysExpenseData = [
  'reference_number',
  'contact_id',
  'type',
  'type_module',
  // 'type_module_id',
  'transaction_date',
  'account_origin_id',
  'description',
  'discount_account_id',
  'discount_type',
  'discount_percentage',
  'discount_nominal',
  'uuid_attachment',
  'tag',
];

export const allowKeysDestinationAccountData = ['account_destination_id', 'amount', 'description'];

export const formatCalculationDestinationAccountData = ['amount'];
