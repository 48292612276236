// initial search transaction data
export const initialSearchSalesQuotation = () => {
  return {
    from_date: null,
    to_date: null,
    transaction_no: '',
    customer_name: '',
    customer_name_container: null,
    status: [],
    status_container: [],
  };
};
