import * as React from 'react';
function SvgIconMenuSettingWarehouse(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.857 68.143a.429.429 0 11-.857 0 .429.429 0 01.857 0zM6.857 67.714h58.286a.428.428 0 110 .857H6.857a.428.428 0 110-.857zM2.571 67.714h2.143a.428.428 0 110 .857H2.571a.428.428 0 110-.857zM69.429 67.714h-2.143a.428.428 0 100 .857h2.143a.428.428 0 100-.857zM72 68.143a.429.429 0 11-.857 0 .429.429 0 01.857 0zM24.857 64.286h1.286v.857h-1.286v-.857z'
        fill='#000'
      />
      <path
        d='M40.466 56.118H28.962V46.097a.664.664 0 00-.198-.473.681.681 0 00-.478-.195h-12.18c-.18 0-.352.07-.48.195a.664.664 0 00-.197.473v21.378c0 .177.07.347.198.472a.681.681 0 00.478.196h24.361c.18 0 .352-.07.479-.196a.663.663 0 00.198-.472v-10.69a.663.663 0 00-.198-.472.68.68 0 00-.479-.195zm-7.444 1.336h2.707v2.672h-2.706v-2.672zm-12.18-10.69h2.707v2.673h-2.707v-2.672zm-4.06 0h2.707v3.341c0 .177.071.347.198.473a.681.681 0 00.478.195h4.06c.18 0 .352-.07.479-.196a.664.664 0 00.198-.472v-3.34h2.707v9.353H16.782v-9.353zm4.06 10.69h2.707v2.672h-2.707v-2.672zm-4.06 0h2.707v3.34c0 .177.071.347.198.473a.681.681 0 00.478.195h4.06c.18 0 .352-.07.479-.196a.664.664 0 00.198-.472v-3.34h2.707v9.353H16.782v-9.353zm23.007 9.353H28.962v-9.353h2.707v3.34c0 .177.072.347.198.473a.681.681 0 00.479.195h4.06c.18 0 .352-.07.479-.196a.664.664 0 00.198-.472v-3.34h2.706v9.353z'
        fill='#000'
      />
      <path
        d='M36.814 63.81h1.329v1.333h-1.329V63.81zM24.857 53.143h1.329v1.333h-1.329v-1.333z'
        fill='#000'
      />
      <path
        d='M54.885 36.087a.67.67 0 00.493-.07.67.67 0 00.336-.594v-2.655a.665.665 0 00-.514-.649L35.73 27.447a.674.674 0 00-.315 0L15.943 32.12a.673.673 0 00-.514.65v2.65a.671.671 0 00.829.668l1.185-.285v8.313h1.343V35.48l16.785-4.028 16.786 4.028v31.328h-4.029v-26.03a.673.673 0 00-.671-.667H23.486a.673.673 0 00-.672.667v2.67a.666.666 0 00.672.667h23.5v1.335H30.2v1.335h16.786v1.335H30.2v1.334h16.786v1.335H30.2v1.335h16.786v14.684h-4.7v1.335h13.428v-1.335H53.7V35.802l1.185.285zM24.157 42.78v-1.335h22.829v1.335H24.157zm11.414-12.682a.668.668 0 00-.157.02L16.77 34.59v-1.296l18.8-4.511 18.8 4.51v1.297L35.73 30.117a.67.67 0 00-.158-.019z'
        fill='#000'
      />
      <path
        d='M36.943 38.143c.545 0 1.069-.226 1.455-.628.385-.402.602-.947.602-1.515a2.19 2.19 0 00-.602-1.515 2.017 2.017 0 00-1.455-.628H34.2c-.546 0-1.069.226-1.455.628A2.19 2.19 0 0032.143 36c0 .568.217 1.113.602 1.515.386.402.91.628 1.455.628h2.743zM33.514 36a.73.73 0 01.201-.505.673.673 0 01.485-.21h2.743c.182 0 .356.076.485.21a.73.73 0 01.2.505.73.73 0 01-.2.505.672.672 0 01-.485.21H34.2a.674.674 0 01-.485-.21.73.73 0 01-.2-.505z'
        fill='#000'
      />
    </svg>
  );
}
export default SvgIconMenuSettingWarehouse;
