const success = {
  B25: '#f6fef9',
  B50: '#ecfdf3',
  B100: '#d1fadf',
  B200: '#a6f4c5',
  B300: '#6ce9a6',
  B400: '#32d583',
  B478: '#3fb8ac',
  B500: '#12b76a',
  B600: '#039855',
  B700: '#027a48',
  B800: '#05603a',
  B900: '#054f31',
};

export default success;
