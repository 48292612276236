import React from 'react';
import { isEmpty, isEqual, pick } from 'lodash';

import ListItemProduct, {
  defaultListItemProductSalesProps,
} from '../../../components/list-item/ListItemProduct/ListItemProduct';
import { getListProductBySupplier } from '../../../store/product/product.action';
import { clearDataProductSlice } from '../../../store/product/product.slice';
import { PRODUCT_PRICE_ACCESS_PERMISSION_V2 } from '../../constants/permissions.constant';
import {
  NAME_STORE,
  SLICE_NAME_DATA_PRODUCT_BY_SUPPLIER,
  STATUS_REQUEST_LIST_PRODUCT_BY_SUPPLIER_PENDING,
} from '../../constants/store.constant';
import { DEFAULT_ACTION_MENU_UPDATE } from '../../default/action-menu.default';
import {
  DEFAULT_GRID_ACTION_COLLAPSE_TYPE,
  DEFAULT_GRID_ACTION_REMOVE_TYPE,
} from '../../default/grid-type.default';
import {
  DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
  DEFAULT_INPUT_TYPE_CALCULATION_NUMBER_INPUT,
  DEFAULT_INPUT_TYPE_COMMON_INPUT,
} from '../../default/input-type.default';
import {
  DEFAULT_KEY_NAME_IS_FOR_SALE_API_REF,
  DEFAULT_KEY_NAME_IS_REWRITE_ALL,
  DEFAULT_KEY_NAME_IS_STOCK_MONITORED_API_REF,
  DEFAULT_KEY_NAME_PRICE_API_REF,
  DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
  DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER,
  DEFAULT_KEY_NAME_PRODUCT_NAME_API_REF,
  DEFAULT_KEY_NAME_QUANTITY_AVAILABLE_WITH_UNIT,
  DEFAULT_KEY_NAME_QUANTITY_CONST,
  DEFAULT_KEY_NAME_STATUS_REQUEST,
  DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF,
} from '../../default/object-keyname.default';
import { DEFAULT_PAYLOAD_STORAGE_PRODUCT_DATA_BY_SUPPLIER } from '../../default/payload-storage.default';
import { DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE } from '../../default/representation-data-type.default';
import { DEFAULT_STATUS_SALES_ORDER_CLOSED } from '../../default/sales-status.default';
import { DEFAULT_CLASSNAME_INPUT_CENTER } from '../../default/styles-classname.default';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';
import autocompleteHelper from '../../helpers/component/autocomplete.helpers';
import objHelper from '../../helpers/object.helper';

import { getUnitProductAutocompleteTransactionPropertiesPrimary } from './unit-product-properties';

/**
 *
 * @param { function } t translation function
 * @returns
 *      header columns for add and update product on sales order
 */
export const columnsTableAddUpdateProductSalesOrder = (t) => {
  return [
    {
      name: 'product_name',
      label: t('dialog.base-add-update-sales-order.table.header-column.product-name'),
      minWidth: 200,
    },
    {
      name: 'product_description',
      label: t('dialog.base-add-update-sales-order.table.header-column.product-description'),
      width: 750,
    },
    {
      name: 'product_quantity',
      label: t('dialog.base-add-update-sales-order.table.header-column.product-quantity'),
      width: 200,
      align: 'right',
    },
    {
      name: 'product_unit',
      label: t('dialog.base-add-update-sales-order.table.header-column.product-unit'),
      width: 250,
    },
    {
      name: 'product_price',
      label: t('dialog.base-add-update-sales-order.table.header-column.product-price'),
      width: 150,
      align: 'right',
    },
    {
      name: 'product_amount',
      label: t('dialog.base-add-update-sales-order.table.header-column.product-amount'),
      width: 100,
      align: 'right',
    },
    {
      name: 'action',
      label: '',
      align: 'right',
      width: 25,
    },
  ];
};

// grid header sales order add update
export const gridHeaderProductSalesOrder = [
  {
    name: DEFAULT_GRID_ACTION_COLLAPSE_TYPE,
    label: '',
    align: 'left',
    width: 20,
    gridProps: {
      xs: 0.75,
    },
    isButton: true,
  },
  {
    label: 'table.header-column.product-name',
    placeholder: 'placeholder.select-product',
    isUseMultipleNames: true,
    width: 250,
    minWidth: 150,
    gridProps: {
      xs: 4.2,
      display: 'flex',
      justifyContent: 'start',
    },
    isInput: true,
    storeName: NAME_STORE.PRODUCT,
    childrenStoreName: SLICE_NAME_DATA_PRODUCT_BY_SUPPLIER,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    keyUniqueName: DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
    keyInputName: DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
    keyInputContainerName: DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER,
    statusRequestListPending: STATUS_REQUEST_LIST_PRODUCT_BY_SUPPLIER_PENDING,
    inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
    getAdditionalParamsForAction: ({ transactionData }) => {
      if (isEmpty(transactionData)) return {};

      return pick(transactionData[0], [DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF]);
    },
    autocompleteProps: {
      fetchAction: (params) =>
        getListProductBySupplier(
          objHelper.filteringExistedValueWithAllowKeys(
            params,
            [],
            [DEFAULT_KEY_NAME_IS_REWRITE_ALL],
            true,
          ),
        ),
      additionalParamsForAction: {
        [DEFAULT_KEY_NAME_IS_FOR_SALE_API_REF]: 1,
      },
      removeDataAction: clearDataProductSlice,
      additionalParamsForRemoveDataAction: DEFAULT_PAYLOAD_STORAGE_PRODUCT_DATA_BY_SUPPLIER,
      additionalParamsForScrollAction: {
        isRewriteAll: false,
        [DEFAULT_KEY_NAME_IS_FOR_SALE_API_REF]: 1,
      },
      getOptionLabel: (options) =>
        autocompleteHelper.getOptionLabel(options, DEFAULT_KEY_NAME_PRODUCT_NAME_API_REF),
      isOptionEqualToValue: (option, value) => isEqual(option, value),
      isUseFetchOnInput: true,
      keyNameFetchInput: DEFAULT_KEY_NAME_PRODUCT_NAME_API_REF,
      renderOption: (props, option) => (
        <ListItemProduct
          option={option}
          renderOptionProps={props}
          productProps={defaultListItemProductSalesProps}
        />
      ),
    },
    isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
      const { status } = transactionData[0];

      return (
        actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
        !statusFreeUpdateInputTransaction.includes(status)
      );
    },
  },
  {
    name: 'quantity',
    label: 'table.header-column.product-quantity',
    placeholder: 'placeholder.calculation-number-default',
    align: 'left',
    width: 50,
    minWidth: 50,
    gridProps: {
      xs: 1,
      display: 'flex',
      justifyContent: 'center',
    },
    isInput: true,
    inputType: DEFAULT_INPUT_TYPE_CALCULATION_NUMBER_INPUT,
    keyInputName: 'quantity',
    isUseFooterInput: true,
    keyMaximumInput: DEFAULT_KEY_NAME_QUANTITY_CONST,
    footerInputLabel: 'typography.stock',
    footerInputKeyName: DEFAULT_KEY_NAME_QUANTITY_AVAILABLE_WITH_UNIT,
    additionalOutlinedInput: {
      className: DEFAULT_CLASSNAME_INPUT_CENTER,
    },
    useInputInfinityDecession: true,
    isInputInfinity: ({ rowSelectedContainerData }) => {
      if (
        isEmpty(rowSelectedContainerData) ||
        !rowSelectedContainerData[DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER]
      )
        return false;

      const productContainer = rowSelectedContainerData[DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER];

      // making decession when product is not a service and product is limited
      return !productContainer[DEFAULT_KEY_NAME_IS_STOCK_MONITORED_API_REF];
    },
    useFooterShowDecession: true,
    isFooterShow: ({ rowSelectedContainerData }) => {
      if (
        isEmpty(rowSelectedContainerData) ||
        !rowSelectedContainerData[DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER]
      )
        return false;

      const productContainer = rowSelectedContainerData[DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER];

      // showing footer only product is monitored
      return productContainer[DEFAULT_KEY_NAME_IS_STOCK_MONITORED_API_REF];
    },
    isReadOnly: ({ rowSelectedContainerData }) => {
      return isEmpty(rowSelectedContainerData[DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER]);
    },
    isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
      const { status } = transactionData[0];

      return (
        actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
        !statusFreeUpdateInputTransaction.includes(status)
      );
    },
  },
  {
    name: 'unit',
    label: 'table.header-column.unit',
    placeholder: 'placeholder.product-unit',
    align: 'left',
    width: 50,
    minWidth: 50,
    gridProps: {
      xs: 1.8,
      display: 'flex',
      justifyContent: 'start',
    },
    ...getUnitProductAutocompleteTransactionPropertiesPrimary({}),
    isDisabled: ({ actionMenu, transactionData }) => {
      const { status } = transactionData[0];

      return (
        !hasImplementedUnitProductUnit ||
        (actionMenu === DEFAULT_ACTION_MENU_UPDATE && status === DEFAULT_STATUS_SALES_ORDER_CLOSED)
      );
    },
  },
  {
    name: 'price',
    label: 'table.header-column.at-price',
    placeholder: 'placeholder.calculation-number-default',
    align: 'right',
    width: 75,
    minWidth: 75,
    gridProps: {
      xs: 1.75,
      display: 'flex',
      justifyContent: 'end',
    },
    isInput: true,
    inputType: DEFAULT_INPUT_TYPE_CALCULATION_NUMBER_INPUT,
    keyInputName: DEFAULT_KEY_NAME_PRICE_API_REF,
    isDisabled: ({
      actionMenu,
      transactionData,
      statusFreeUpdateInputTransaction = [],
      permissions,
    }) => {
      const { status } = transactionData[0];

      return (
        (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
          !statusFreeUpdateInputTransaction.includes(status)) ||
        (Array.isArray(permissions) && !permissions.includes(PRODUCT_PRICE_ACCESS_PERMISSION_V2))
      );
    },
    isReadOnly: ({ rowSelectedContainerData }) => {
      return isEmpty(rowSelectedContainerData[DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER]);
    },
  },
  {
    name: 'amount',
    label: 'table.header-column.amount',
    align: 'right',
    width: 100,
    minWidth: 80,
    gridProps: {
      xs: 1.75,
      display: 'block',
      overflow: 'auto',
      textAlign: 'right',
    },
    representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
    isTypography: true,
  },
  {
    name: DEFAULT_GRID_ACTION_REMOVE_TYPE,
    label: '',
    align: 'right',
    width: 25,
    gridProps: {
      xs: 0.75,
    },
    isButton: true,
    isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
      const { status } = transactionData[0];

      return (
        actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
        !statusFreeUpdateInputTransaction.includes(status)
      );
    },
  },
];

export const gridCollapseProductSalesOrder = [
  {
    gridProps: {
      xs: 0.75,
    },
  },
  {
    placeholder: 'placeholder.description',
    inputName: 'memo',
    keyInputName: 'product_description',
    inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
    gridProps: {
      xs: 10.5,
    },
  },
  {
    gridProps: {
      xs: 0.75,
    },
  },
];
