import * as React from 'react';
function SvgIconMenuSalesSalesInvoice(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.857 68.143a.429.429 0 11-.857 0 .429.429 0 01.857 0zM6.857 67.714h58.286a.428.428 0 110 .857H6.857a.428.428 0 110-.857zM2.571 67.714h2.143a.428.428 0 110 .857H2.571a.428.428 0 110-.857zM69.429 67.714h-2.143a.428.428 0 100 .857h2.143a.428.428 0 100-.857zM72 68.143a.429.429 0 11-.857 0 .429.429 0 01.857 0zM39.6 40.428h-8.4V42h8.4v-1.572zM44.18 31.647H31.22v1.68h12.96v-1.68zM44.18 34.428H31.22V36h12.96v-1.572zM44.18 37.258H31.22v1.572h12.96v-1.572z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M52.277 23.471a3.86 3.86 0 011.195 2.78v3.5H51.84v-3.5c0-.626-.258-1.225-.717-1.668a2.496 2.496 0 00-1.731-.69H26.544c.516.687.8 1.51.816 2.358v15.383h-1.632v-6.735h-5.712a.832.832 0 01-.577-.23.772.772 0 01-.239-.556v-7.862a3.86 3.86 0 011.195-2.78 4.16 4.16 0 012.885-1.151h26.112a4.16 4.16 0 012.885 1.151zM55.92 67.92H38.4v-1.572h17.52c.65 0 1.272-.249 1.731-.691.46-.443.717-1.042.717-1.668v-3.145l-3.052 2.201a.837.837 0 01-.979 0l-3.86-2.79-3.868 2.79a.836.836 0 01-.979 0l-3.86-2.79-3.37 2.437v-1.966l2.881-2.083a.836.836 0 01.98 0l3.867 2.79 3.86-2.798a.837.837 0 01.97 0l.882.637V52.8h1.632v7.651l1.355.983 3.867-2.791a.834.834 0 01.855-.075c.136.066.25.166.33.29.079.125.121.268.121.414v4.717a3.86 3.86 0 01-1.195 2.78 4.16 4.16 0 01-2.885 1.151zM20.832 33.327V26.25c0-.626.258-1.225.717-1.668a2.496 2.496 0 011.731-.69c.65 0 1.272.248 1.731.69.46.443.717 1.043.717 1.668v7.076h-4.896z'
        fill='#000'
      />
      <path
        d='M36.932 63.045a.836.836 0 00.979 0l.489-.353v-1.966l-.979.708-1.359-.983v1.966l.87.628zM38.4 67.92v-1.572h-2.338v1.572H38.4zM53.144 39.455a.502.502 0 00.502-.502V37.63a.502.502 0 00-.502-.502h-3.088a.502.502 0 00-.502.502v1.324c0 .277.225.502.502.502h3.088zm-2.958-1.696h2.828v1.064h-2.828V37.76zm4.453 8.532a.502.502 0 00-.501.502v1.324c0 .277.225.502.502.502h3.088a.502.502 0 00.502-.502v-1.324a.502.502 0 00-.502-.502h-3.089zm2.958 1.696H54.77v-1.064h2.827v1.064zm-9.037-1.696h-3.088a.502.502 0 00-.502.502v1.324c0 .277.225.502.502.502h3.088a.502.502 0 00.502-.502v-1.324a.502.502 0 00-.502-.502zm-.13 1.696h-2.828v-1.064h2.828v1.064z'
        fill='#000'
      />
      <path
        d='M61.928 49.528h-.844v-1.225a.316.316 0 00-.633 0v1.225h-8.535v-8.532h2.857v2.117c0 .18.091.341.244.434.152.094.338.1.497.02l.67-.344.67.343a.51.51 0 00.74-.453v-2.117h2.849c.005 0 .008.004.008.008v5.863a.316.316 0 00.633 0v-5.863a.641.641 0 00-.641-.64H56.5V31.84a.64.64 0 00-.64-.64h-8.52a.641.641 0 00-.64.64v1.585a.316.316 0 00.632 0V31.84c0-.004.004-.008.009-.008h2.848v2.117a.505.505 0 00.51.51.51.51 0 00.232-.057l.669-.343.67.343a.506.506 0 00.676-.203.505.505 0 00.065-.25v-2.117h2.849c.002 0 .004 0 .005.002a.008.008 0 01.003.006v8.524h-8.536V34.86a.316.316 0 10-.632 0v5.504h-3.943a.641.641 0 00-.64.64v8.524h-.845a.472.472 0 00-.472.471v.918c0 .26.212.471.472.471h.743v.85c0 .31.252.562.563.562h.92c.31 0 .562-.252.562-.562v-.85h15.08v.85c0 .31.253.562.563.562h.92c.31 0 .562-.252.562-.562v-.85h.743c.26 0 .472-.211.472-.471v-.918a.472.472 0 00-.472-.471zm-9.55-15.78l-.546-.28a.509.509 0 00-.464 0l-.546.28v-1.916h1.556v1.916zm3.027 7.248h1.557v1.916l-.546-.28a.51.51 0 00-.464 0l-.547.28v-1.916zm-9.167 0h1.557v1.916l-.547-.28a.51.51 0 00-.464 0l-.546.28v-1.916zm-3.49.008c0-.004.004-.008.009-.008h2.849v2.117a.505.505 0 00.49.51.506.506 0 00.25-.057l.67-.343.67.343a.509.509 0 00.74-.453v-2.117h2.858v8.532h-8.535v-8.524zm.68 11.164h-.78v-.78h.78v.78zm17.125 0h-.78v-.78h.78v.78zm1.215-1.412H41.432v-.596h20.336v.596zM13.342 46.373h1.182a.238.238 0 00.167-.069.231.231 0 00.069-.164c0-.258-.278-.247-.552-.237-.053.002-.106.004-.157.004a1.19 1.19 0 01-.793-.3 1.152 1.152 0 01-.16-1.555c.175-.233.43-.393.717-.45v-.49c0-.123.05-.242.138-.33a.476.476 0 01.668 0 .462.462 0 01.139.33v.466h.472c.126 0 .246.049.335.136a.462.462 0 010 .659.476.476 0 01-.335.137h-1.18a.24.24 0 00-.168.068.231.231 0 000 .329.238.238 0 00.167.068h.473c.293 0 .575.107.793.3a1.152 1.152 0 01.16 1.555 1.183 1.183 0 01-.717.451v.49c0 .123-.05.241-.139.328a.476.476 0 01-.668 0 .462.462 0 01-.138-.329v-.466h-.473a.476.476 0 01-.334-.136.463.463 0 010-.659.476.476 0 01.335-.136z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.035 47.44c2.126 4.453 8.977 2.948 8.977-1.999 0-5.282-7.46-6.4-9.175-1.556a4.597 4.597 0 00.198 3.554zm.515-2.409c.08-.716.37-1.393.833-1.95a3.782 3.782 0 011.776-1.19 3.833 3.833 0 012.146-.044c.705.19 1.339.578 1.825 1.115.486.538.803 1.204.914 1.916.11.712.009 1.44-.292 2.097a3.743 3.743 0 01-1.403 1.602 3.82 3.82 0 01-2.062.59c-.53 0-1.055-.111-1.54-.325a3.779 3.779 0 01-1.272-.917c-.354-.39-.62-.85-.782-1.348a3.677 3.677 0 01-.157-1.546h.014z'
        fill='#000'
      />
      <path
        d='M33.717 50.974a1.428 1.428 0 00-1.002-.409h-.945c-.376 0-.737.147-1.003.41a1.388 1.388 0 00-.415.987v13.975c0 .37.15.726.415.988.266.262.627.41 1.003.41h.945c.376 0 .736-.148 1.002-.41s.415-.618.415-.988v-6.522a.463.463 0 00-.334-.445.478.478 0 00-.472.116.463.463 0 00-.139.33v6.52c0 .124-.05.243-.138.33a.476.476 0 01-.334.137h-.945a.476.476 0 01-.334-.137.462.462 0 01-.139-.33V51.963c0-.123.05-.242.139-.329a.476.476 0 01.334-.136h.945c.125 0 .245.049.334.136a.462.462 0 01.138.33v3.26c0 .124.05.242.139.33a.476.476 0 00.668 0 .463.463 0 00.138-.33v-3.26c0-.371-.15-.727-.415-.989z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.045 53.826H26.1c-.376 0-.737.147-1.003.409a1.387 1.387 0 00-.415.988v11.18c0 .37.15.726.416.988.265.262.626.409 1.002.409h.945c.376 0 .736-.147 1.002-.41.266-.261.415-.617.415-.987v-11.18c0-.37-.15-.726-.415-.988a1.428 1.428 0 00-1.002-.41zm.472 12.577c0 .123-.05.242-.138.329a.476.476 0 01-.334.136H26.1a.476.476 0 01-.44-.294.466.466 0 01-.033-.171v-11.18c0-.123.05-.242.139-.33a.476.476 0 01.334-.136h.945c.125 0 .245.05.334.137a.463.463 0 01.138.33v11.179zM20.43 56.62h.945c.376 0 .736.148 1.002.41s.415.617.415.988v8.385c0 .37-.149.726-.415.988s-.626.409-1.002.409h-.945c-.376 0-.736-.147-1.002-.41a1.388 1.388 0 01-.416-.987v-8.385c0-.37.15-.726.416-.988.265-.262.626-.41 1.002-.41zm1.279 10.112a.463.463 0 00.138-.33v-8.384a.463.463 0 00-.138-.33.476.476 0 00-.334-.136h-.945a.476.476 0 00-.334.137.462.462 0 00-.139.329v8.385c0 .123.05.242.139.329a.476.476 0 00.334.136h.945c.125 0 .245-.049.334-.136zM15.705 59.415h-.945c-.376 0-.736.148-1.002.41a1.388 1.388 0 00-.415.988v5.59c0 .37.149.726.415.988s.626.409 1.002.409h.945c.376 0 .736-.147 1.002-.41.266-.261.415-.617.415-.987v-5.59c0-.37-.149-.726-.415-.988a1.428 1.428 0 00-1.002-.41zm.472 6.988c0 .123-.05.242-.138.329a.476.476 0 01-.334.136h-.945a.476.476 0 01-.334-.136.462.462 0 01-.139-.33v-5.59c0-.123.05-.241.139-.328a.476.476 0 01.334-.137h.945c.125 0 .245.05.334.136a.462.462 0 01.138.33v5.59zM10.035 62.21H9.09c-.376 0-.736.148-1.002.41a1.388 1.388 0 00-.415.988v2.795c0 .37.149.726.415.988s.626.409 1.002.409h.945c.376 0 .736-.147 1.002-.41.266-.261.415-.617.415-.987v-2.795c0-.37-.149-.726-.415-.988a1.428 1.428 0 00-1.002-.41zm.473 4.193a.489.489 0 01-.011.098.466.466 0 01-.128.23.476.476 0 01-.334.137H9.09a.476.476 0 01-.334-.136.462.462 0 01-.139-.33v-2.794c0-.124.05-.242.139-.33a.476.476 0 01.334-.136h.945a.476.476 0 01.437.29l.008.02.005.015a.53.53 0 01.015.058.462.462 0 01.008.083v2.795z'
        fill='#000'
      />
      <path
        d='M33.187 57.552v-.466c0-.123.05-.242.139-.329a.476.476 0 01.668 0 .462.462 0 01.138.33v.465c0 .124-.05.242-.138.33a.476.476 0 01-.668 0 .462.462 0 01-.139-.33zM31.908 42.783a.476.476 0 01.334-.137c1.396 0 1.987-.101 2.228.14.242.24.135.822.135 2.19a.46.46 0 01-.139.329.476.476 0 01-.668 0 .463.463 0 01-.138-.33v-.754l-2.523 2.44C25.117 52.49 17.43 55.69 7.673 55.69a.476.476 0 01-.335-.136.463.463 0 010-.66.476.476 0 01.335-.136c9.45 0 16.92-3.07 22.802-8.757l2.5-2.422h-.733a.476.476 0 01-.334-.137.463.463 0 010-.658z'
        fill='#000'
      />
    </svg>
  );
}
export default SvgIconMenuSalesSalesInvoice;
