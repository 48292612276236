import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_DISCOUNT_PERCENTAGE_TYPE } from '../../default/discount-type.default';
import {
  DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_SALES_INVOICE_AMOUNT_AVAILABLE_API_REF,
  DEFAULT_KEY_NAME_SALES_RETURN_PRODUCT_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import arrHelp from '../../helpers/array.helpers';
import formulaHelpers from '../../helpers/formula.helpers';
import objHelper from '../../helpers/object.helper';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import { generalizeAttachmentFromDetails } from './initial-add-attachment';
import {
  generalizeDataAddSalesReturn,
  generateDataNvalidationAddProductSalesReturn,
  generateDataNvalidationAddSalesReturn,
} from './initial-add-sales-return';
import {
  initialDataProductSalesReturn,
  initialDataSalesReturn,
  initialValidationProductSalesReturn,
  initialValidationSalesReturn,
  productSalesReturnCalculationNumberKeyNames,
  salesReturnCalculationNumberKeyNames,
} from './initial-data-sales-return';

/**
 * usaged for generate data and validation for sales return
 * that came from sales return details data
 *
 * @param   { object } param0         object as params function
 *
 * @param   { object }  salesReturnDetails              current data from sales return details
 * @param   { object }  companyUserData                 current company user data
 * @param   { string }  dataKeyname                     return keyname for sales return update
 * @param   { string }  validationKeyname               return validation keyname for sales return update
 *
 * @returns
 */
export const generateDataNvalidationUpdateSalesReturnFromSalesReturnDetails = ({
  salesReturnDetails = {},
  companyUserData = {},
  dataKeyname = 'salesReturnData',
  validationKeyname = 'salesReturnValidation',
}) => {
  if (isEmpty(salesReturnDetails)) {
    return generateDataNvalidationAddSalesReturn({
      amount: 1,
      dataKeyname,
      validationKeyname,
    });
  }

  salesReturnDetails = objHelper.changeFormatValue(
    salesReturnDetails,
    salesReturnCalculationNumberKeyNames,
    [(value) => Number(value) || ''],
  );

  const {
    customer_id,
    customer_name: contact_name,
    customer_code: contact_code,
    customer_address,
    transaction_date,
    transaction_no,
    sales_invoice_id,
    sales_invoice_no,
    sales_invoice_transaction_date,
    amount,
    description,
  } = salesReturnDetails;

  const usagedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const salesReturnData = usagedID.map((id) => {
    let transaction_no_container = {
      value: transaction_no,
    };

    let accounting_period = null;
    if (!isEmpty(companyUserData) && !!companyUserData.accounting_periode) {
      accounting_period = companyUserData.accounting_periode;
    }

    const subtotal = salesReturnDetails[DEFAULT_KEY_NAME_SALES_RETURN_PRODUCT_API_REF].reduce(
      (resSubtotal, currProduct) => {
        const { quantity_origin, price } = currProduct;

        return resSubtotal + Number(quantity_origin) * Number(price);
      },
      0,
    );

    return {
      ...initialDataSalesReturn(id),
      ...salesReturnDetails,
      accounting_period,
      transaction_no_container,
      invoiceNumber: sales_invoice_no || '',
      transaction_date: transaction_date ? moment(transaction_date) : null,
      sales_invoice_id_container: {
        sales_invoice_id,
        sales_invoice_no,
      },
      amount_const: Number(amount) || 0,
      customer_id_container: {
        contact_id: customer_id,
        contact_code,
        contact_name,
      },
      customer_address: customer_address || '',
      description: description || '',
      discount_account_id_container: {
        account_id: salesReturnDetails.discount_account_id || '',
        account_name: salesReturnDetails.discount_account_name || '',
        account_code: salesReturnDetails.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: salesReturnDetails.expense_account_id || '',
        account_name: salesReturnDetails.expense_account_name || '',
        account_code: salesReturnDetails.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: salesReturnDetails.down_payment_account_id || '',
        account_name: salesReturnDetails.down_payment_account_name || '',
        account_code: salesReturnDetails.down_payment_account_code || '',
      },
      attachment: generalizeAttachmentFromDetails({
        dataDetails: salesReturnDetails,
      }),
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        sales_invoice_transaction_date ||
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
      invoice_total:
        Number(amount) +
        Number(salesReturnDetails[DEFAULT_KEY_NAME_SALES_INVOICE_AMOUNT_AVAILABLE_API_REF]),
      discount_type: DEFAULT_DISCOUNT_PERCENTAGE_TYPE, // force discount type
      discount_percentage: formulaHelpers.calculateDiscountPercentage({
        parentData: {
          subtotal,
          ...salesReturnDetails,
        },
        amountKeyname: 'subtotal',
      }),
    };
  });

  const salesReturnValidation = usagedID.map((id) => {
    return initialValidationSalesReturn(id);
  });

  return {
    [dataKeyname]: salesReturnData,
    [validationKeyname]: salesReturnValidation,
  };
};

/**
 *
 * generate data and validation for update product sales return
 * where came from sales return details
 *
 * @param { object } param0         params object for function
 *
 * @param   { object }      salesReturnDetails               target of sales return details
 * @param   { object }      dataKeyname                         return data keyname for product sales return
 * @param   { object }      validationKeyname                   return validation keyname for product sales return
 *
 * @returns
 */
export const generateDataNvalidationUpdateProductSalesReturnFromSalesReturnDetails = ({
  salesReturnDetails = {},
  dataKeyname = 'productSalesReturnData',
  validationKeyname = 'productSalesReturnValidation',
}) => {
  const salesReturnProduct = isEmpty(salesReturnDetails)
    ? null
    : salesReturnDetails[DEFAULT_KEY_NAME_SALES_RETURN_PRODUCT_API_REF];

  if (isEmpty(salesReturnProduct)) {
    return generateDataNvalidationAddProductSalesReturn({
      amount: 2,
      dataKeyname,
      validationKeyname,
    });
  }

  const usagedID = arrHelp.generateArrWithFunc(salesReturnProduct.length, uuidv4);

  const productSalesReturnData = usagedID.map((id, index) => {
    let selectedProductSalesReturn = salesReturnProduct[index];

    if (!isEmpty(selectedProductSalesReturn)) {
      selectedProductSalesReturn = objHelper.changeFormatValue(
        selectedProductSalesReturn,
        productSalesReturnCalculationNumberKeyNames,
        [(value) => Number(value) || ''],
      );
    }

    const {
      product_id,
      product_name,
      product_code,
      quantity,
      quantity_origin = 0,
      product_invoice_quantity,
      product_invoice_quantity_available = 0,
      price,
      description,
      product_unit,
      unit_origin,
    } = selectedProductSalesReturn;

    const amount = Number(quantity) * Number(price);
    const quantity_available_const =
      product_invoice_quantity !== undefined ? Number(product_invoice_quantity) : Number(quantity);

    return {
      ...initialDataProductSalesReturn(id),
      ...formulaHelpers.parseProductAPI2display({
        product: selectedProductSalesReturn,
      }),
      ...selectedProductSalesReturn,
      product_id_container: {
        product_id,
        product_name,
        product_code,
        ...selectedProductSalesReturn,
      },
      description: description || '',
      quantity_available_const,
      amount,
      quantity_available_with_unit: `${
        Number(product_invoice_quantity_available) + Number(quantity_origin)
      } ${unit_origin || product_unit}`,
    };
  });

  const productSalesReturnValidation = usagedID.map((id) =>
    initialValidationProductSalesReturn(id),
  );

  return {
    [dataKeyname]: productSalesReturnData,
    [validationKeyname]: productSalesReturnValidation,
  };
};

/**
 *
 * @param { array }     salesReturnData                  target generalize data for update sales return
 * @param { array }     productSalesReturnData           product sales return data for generalized
 * @returns
 */
export const generalizeDataUpdateSalesReturn = (salesReturnData, productSalesReturnData) => {
  const { sales_return_id } = cloneDeep(salesReturnData[0]);

  const generalizedDataSalesReturn = generalizeDataAddSalesReturn(
    salesReturnData,
    productSalesReturnData,
  );

  return {
    sales_return_id,
    ...generalizedDataSalesReturn,
  };
};
