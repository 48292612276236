import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  FormControl,
  OutlinedInput,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { cloneDeep, isEmpty, isEqual, omit } from 'lodash';

import { TERSIER_DEFAULT_LIST_PARAMS } from '../../../utils/default/params.default';
import { DEFAULT_DELAY_TIMEOUT_IN_MS } from '../../../utils/default/timeout.default';
import autocompleteHelper from '../../../utils/helpers/component/autocomplete.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import AutocompleteCustom from '../../custom/CustomAutocomplete/CustomAutocomplete';
import DateTimePicker from '../../date-time/DateTimePicker/DateTimePicker';
import InputAdornmentClear from '../../input-adorment/InputAdornmentClear/InputAdornmentClear';
import PopperAutoWidth from '../../popper/PopperAutoWidth/PopperAutoWidth';

import { styles } from './TableHeadSearchMain.styles';

export const DEFAULT_SEARCH_INPUT = {
  commonInput: 'common-input',
  dateRangeInput: 'date-range-input',
  autocompleteFetchAPIInput: 'autocomplete-fetch-api-input',
  autocompleteConstantOptions: 'autocomplete-constant-options',
};

/**
 * @prop    { object }      containerSliceData
 * @prop    { function }    onGetDataList
 * @prop    { bool }        isGetDataListLoading
 * @prop    { object }      initialSearchParams
 * @prop    { array }       columnSearchList
 * @prop    { string }      keyNamePaginate                 key name for data paginate from API response
 *      @default    'per_page'
 * @prop    { string }      textCancelButtonDateRange       text button for canceling date range
 *      @default    'button.cancel'
 * @prop    { string }      textApplyButtonDateRange        text button for apply date range
 *      @default    'button.apply'
 *
 *
 * additional info (on each column search) you must add at least:
 *      placeholder     for placeholder on input
 *      label           for labelling on top input
 *      styles          adding styling on table cell
 *      inputType       determine what input are them,
 *              available -> ['common-input', 'date-range-input', 'autocomplete-fetch-api-input', 'autocomplete-constant-input']
 *      inputName       make sure that input name correspond with initial search params, if not using inputName, the column is not using for input
 *      fullWidth       additional on full width or not on each table cell, default true
 *
 *  make sure if inputType is equal 'date-range-input', you must add key start date and key end date
 *      keyStartDate    indicate where start date from
 *      keyEndDate      indicate where date must stop
 *
 *  if use input type is equal 'autocomplete-fetch-api-input', adding this on key
 *    - make sure for watch documentation for custom/CustomAutocomplete
 *      autoHighlight                                  { boolean }
 *          @default    true
 *      isPaginated                                    { boolean }
 *          @default    true
 *      totalOptions                                   { number }
 *          @default    'all option from list data multiple by 3'
 *      isUseFetchAction                               { boolean }
 *          @default    true
 *      fetchAction                                    { function }
 *      additionalParamsForAction                      { object }
 *      isUseRemoveDataAction                          { boolean }
 *          @default    true
 *      removeDataAction                               { function }
 *      additionalParamsForRemoveDataAction            { object }
 *      isUseScrollAction                              { boolean }
 *          @default    true
 *      isUseFetchOnInput                              { boolean }
 *          @default    true
 *      keyNameFetchInput                              { string }
 *      searchKeyFromAPIData                           { array }
 *      requestStatusLoading                           { string }
 *      requestStatusKeyName                           { string }
 *          @default    'statusRequest'
 *      parentStoreDataKeyName                         { string }
 *      childrenStoreDataKeyName                       { string }
 *      additionalParamsForScrollAction                { object }
 *
 *      size                                           { string }
 *           @default   'small'
 *      PopperComponent                                { node }
 *           @default   PopperAutoWidth
 *
 *      multiple                                        { boolean }
 *          if using multiple make sure for initial container is array
 *           @default   false
 *
 *  if use input type is equal 'autocomplete-constant-options', adding this on key
 *      options                                        { array }
 *      generateOptions                                { function }         handling generate options where on callback with translation function
 *
 *      overrideOptionValues                           { array }            values can override all of selected options,
 *                                                                          for example list available is 'all', 'open',
 *                                                                          if select 'all', that means 'open' was override by 'all' valus
 *
 *      size                                           { string }
 *           @default   'small'
 *      PopperComponent                                { node }
 *           @default   PopperAutoWidth
 *
 *      multiple                                        { boolean }
 *          if using multiple make sure for initial container is array
 *           @default   true
 *
 *      getOptionLabel
 *      getOptionLabel={
 *          (option) => autocompleteHelper.getOptionLabel(option, 'label')
 *      }
 *
 *      isOptionEqualToValue
 *      (option, value) => autocompleteHelper.isOptionEqualToValue(option, value, 'value')
 *
 *
 *
 *    - additional on autocomplete
 *      identity                { string }      where data that different with another
 *      containerName           { string }      where data that store on state, that showing on autocomplete
 *      containerSelectedName   { string }      where data that store for hitting API, and after selected key name
 *      selectedKeyName         { string }      key where for selected from containerName, container data
 *          here on example: containerName, containerSelectedName, selectedKeyName
 *          on params search contains
 *              {
 *                  [containerName]: {
 *                      [selectedKeyName]: [valueSelectedKeyName],
 *                      ...[another data],
 *                  },
 *                  [containerSelectedName]: [valueSelectedKeyName],
 *              }
 *          where containerName is usually used by autocomplete and containerSelectedName is using for hitting API
 *
 *      containerKeyDataList    { string }      where data list is store on childrenStoreDataKeyName
 *            @default      'data'
 *      paginateKeyName         { string }      key name where pagination from API stored
 *            @default      'per_page
 *
 */
class TableHeadSearchMain extends Component {
  constructor(props) {
    super(props);

    this.isCanHitListAPI = typeof props.onGetDataList === 'function';

    this.state = {
      searchParams: props.initialSearchParams,
      focusedInputName: null,
    };

    this.searchInputTimeoutID = null;

    this.handleClearSingleFilter = this.handleClearSingleFilter.bind(this);
    this.handleOnChangeInput = this.handleOnChangeInput.bind(this);
    this.handleOnChangeDateRange = this.handleOnChangeDateRange.bind(this);
    this.handleChangeAutocompleteAPISingleData =
      this.handleChangeAutocompleteAPISingleData.bind(this);
  }

  async handleClearSingleFilter(event) {
    event.stopPropagation();

    const { name } = event.currentTarget;

    const { searchParams: currSearchParams } = this.state;

    let newSearchParams = {
      ...currSearchParams,
      [name]: '',
    };

    this.setState((prevState) => ({
      ...prevState,
      searchParams: newSearchParams,
    }));

    if (this.props.isGetDataListLoading || !this.isCanHitListAPI) return;

    const {
      containerSliceData,
      keyNamePaginate,
      forceAppendPayloadWhenFilterChanges,
      appendPayloadWhenFilterChanges,
    } = this.props;

    const paginate = !isEmpty(containerSliceData)
      ? containerSliceData[keyNamePaginate] || TERSIER_DEFAULT_LIST_PARAMS.paginate
      : TERSIER_DEFAULT_LIST_PARAMS.paginate;

    if (forceAppendPayloadWhenFilterChanges) {
      newSearchParams = {
        ...newSearchParams,
        ...appendPayloadWhenFilterChanges,
      };
    }

    await this.props.onGetDataList(
      objHelper.filteringExistedValue({
        paginate,
        ...newSearchParams,
      }),
    );
  }

  handleOnChangeInput(event) {
    const { name, value } = event.target;

    const { searchParams: currSearchParams } = this.state;

    let newSearchParams = {
      ...currSearchParams,
      [name]: value,
    };

    this.setState((prevState) => ({
      ...prevState,
      searchParams: newSearchParams,
      focusedInputName: name,
    }));

    if (!this.isCanHitListAPI) return;

    if (this.searchInputTimeoutID) clearTimeout(this.searchInputTimeoutID);

    this.searchInputTimeoutID = setTimeout(async () => {
      const {
        containerSliceData,
        keyNamePaginate,
        forceAppendPayloadWhenFilterChanges,
        appendPayloadWhenFilterChanges,
      } = this.props;

      if (forceAppendPayloadWhenFilterChanges) {
        newSearchParams = {
          ...newSearchParams,
          ...appendPayloadWhenFilterChanges,
        };
      }

      const paginate = !isEmpty(containerSliceData)
        ? containerSliceData[keyNamePaginate] || TERSIER_DEFAULT_LIST_PARAMS.paginate
        : TERSIER_DEFAULT_LIST_PARAMS.paginate;

      await this.props.onGetDataList(
        objHelper.filteringExistedValue({
          paginate,
          ...newSearchParams,
        }),
      );
    }, DEFAULT_DELAY_TIMEOUT_IN_MS);
  }

  // handle change date range, that from key start date and key end date
  handleOnChangeDateRange(newValueDate, keyStartDate, keyEndDate) {
    if (this.props.isGetDataListLoading) return;

    const { searchParams: currSearchParams } = this.state;

    const isDateRangeValueChanged = isEqual(
      newValueDate,
      objHelper.filterKeyObj(currSearchParams, [], [keyStartDate, keyEndDate]),
    );

    if (isDateRangeValueChanged && newValueDate[keyStartDate] && newValueDate[keyEndDate]) return;

    const newSearchParamsForState = {
      ...currSearchParams,
      ...newValueDate,
    };

    this.setState((prevState) => ({
      ...prevState,
      searchParams: newSearchParamsForState,
    }));

    if (this.searchInputTimeoutID) clearTimeout(this.searchInputTimeoutID);

    this.searchInputTimeoutID = setTimeout(async () => {
      const {
        keyNamePaginate,
        containerSliceData,
        forceAppendPayloadWhenFilterChanges,
        appendPayloadWhenFilterChanges,
      } = this.props;

      const paginate = !isEmpty(containerSliceData)
        ? containerSliceData[keyNamePaginate]
        : TERSIER_DEFAULT_LIST_PARAMS.paginate;

      let searchParamsForHitEndpoint = cloneDeep(newSearchParamsForState);

      if (!searchParamsForHitEndpoint[keyStartDate] || !searchParamsForHitEndpoint[keyEndDate]) {
        searchParamsForHitEndpoint = omit(searchParamsForHitEndpoint, [keyStartDate, keyEndDate]);
      }

      if (paginate) {
        searchParamsForHitEndpoint = {
          ...searchParamsForHitEndpoint,
          paginate,
        };
      }

      if (forceAppendPayloadWhenFilterChanges) {
        searchParamsForHitEndpoint = {
          ...searchParamsForHitEndpoint,
          ...appendPayloadWhenFilterChanges,
        };
      }

      if (isEmpty(searchParamsForHitEndpoint)) {
        await this.props.onGetDataList({});

        return;
      }

      await this.props.onGetDataList(objHelper.filteringExistedValue(searchParamsForHitEndpoint));
    }, DEFAULT_DELAY_TIMEOUT_IN_MS);
  }

  /**
   *
   * @param       { object }    event                  object event built-in
   * @param       { object }    newValue               new value selected from autocomplete
   * @param       { string }    primaryName            name key that store data for container data such as customer_id_contaier
   * @param       { string }    secondaryName          name key that store data for hitting on API such as customer_id
   * @param       { string }    keyValueSecondary      key where value for secondary data store, such as customer_id on object customer data fetching api
   * @param       { string }    nameContainerData      container name for store data from state, such as searchParams
   * @returns
   *      handled change autocomplete for separate data for hit api and store on search params that using for autocomplete
   *      only for single data autocomplete
   */
  async handleChangeAutocompleteAPISingleData(
    event,
    newValue,
    primaryName,
    secondaryName,
    keyValueSecondary,
    nameContainerData = 'searchParams',
  ) {
    if (this.props.isGetDataListLoading) return;

    event.preventDefault();
    event.stopPropagation();

    const selectedContainerData = this.state[nameContainerData];

    const newSelectedSecondaryValue =
      newValue && newValue[keyValueSecondary] ? newValue[keyValueSecondary] : newValue;

    const newSelectedContainerData = {
      ...selectedContainerData,
      [primaryName]: newValue,
      [secondaryName]: newSelectedSecondaryValue,
    };

    this.setState((prevState) => ({
      ...prevState,
      [nameContainerData]: newSelectedContainerData,
    }));

    const {
      keyNamePaginate,
      containerSliceData,
      forceAppendPayloadWhenFilterChanges,
      appendPayloadWhenFilterChanges,
    } = this.props;

    const paginate = !isEmpty(containerSliceData)
      ? containerSliceData[keyNamePaginate] || TERSIER_DEFAULT_LIST_PARAMS.paginate
      : TERSIER_DEFAULT_LIST_PARAMS.paginate;

    let searchParamsForHitEndpoint = cloneDeep(newSelectedContainerData);

    if (paginate) {
      searchParamsForHitEndpoint = {
        ...searchParamsForHitEndpoint,
        paginate,
      };
    }

    if (forceAppendPayloadWhenFilterChanges) {
      searchParamsForHitEndpoint = {
        ...searchParamsForHitEndpoint,
        ...appendPayloadWhenFilterChanges,
      };
    }

    if (isEmpty(searchParamsForHitEndpoint)) {
      await this.props.onGetDataList({});

      return;
    }

    await this.props.onGetDataList(objHelper.filteringExistedValue(searchParamsForHitEndpoint));
  }

  /**
   *
   * @param       { object }    event                  object event built-in
   * @param       { object }    newValue               new value selected from autocomplete
   * @param       { string }    primaryName            name key that store data for container data such as customer_id_contaier
   * @param       { string }    secondaryName          name key that store data for hitting on API such as customer_id
   * @param       { string }    keyValueSecondary      key where value for secondary data store, such as customer_id on object customer data fetching api
   * @param       { object }    additionalOptionProps  contained for getting property for options
   * @param       { string }    nameContainerData      container name for store data from state, such as searchParams
   * @returns
   *      handled change autocomplete for separate data for hit api and store on search params that using for autocomplete
   *      only for single data autocomplete
   */
  async handleChangeAutocompleteConstantOptions(
    event,
    newValue,
    primaryName,
    secondaryName,
    keyValueSecondary,
    additionalOptionProps = {},
    nameContainerData = 'searchParams',
  ) {
    if (this.props.isGetDataListLoading) return;

    event.preventDefault();
    event.stopPropagation();

    const selectedContainerData = this.state[nameContainerData];

    const { overrideOptionValues = null, multiple = true } = additionalOptionProps || {};

    let newSelectedSecondaryValue =
      newValue && newValue[keyValueSecondary] ? newValue[keyValueSecondary] : newValue;

    if (multiple && newValue) {
      newSelectedSecondaryValue = newValue.reduce(
        (allNv, nv) => (allNv = allNv.concat(nv[keyValueSecondary])),
        [],
      );
    }

    // check is current params is contained 'override' value
    // when current params contained and incoming data contained another 'override' value, then change into incoming data
    // check is new value is contained 'override' value
    // then contained change new value into 'override' value
    if (!isEmpty(selectedContainerData[primaryName]) && overrideOptionValues) {
      const overrideOptionValuesList = Array.isArray(overrideOptionValues)
        ? overrideOptionValues
        : [overrideOptionValues];

      const isCurrentStatusContainOverrideValues =
        selectedContainerData[primaryName].some((nv) =>
          overrideOptionValuesList.includes(nv[keyValueSecondary]),
        ) && newValue.length > 1;

      const isNewValueContainedOverrideValue = newValue.some((nv) =>
        overrideOptionValuesList.includes(nv[keyValueSecondary]),
      );

      if (isCurrentStatusContainOverrideValues) {
        newValue = newValue.filter(
          (nv) => !overrideOptionValuesList.includes(nv[keyValueSecondary]),
        );
      } else if (isNewValueContainedOverrideValue) {
        newValue = newValue.filter((nv) =>
          overrideOptionValuesList.includes(nv[keyValueSecondary]),
        );
      }

      newSelectedSecondaryValue = keyValueSecondary
        ? newValue.map((value) => value[keyValueSecondary])
        : newValue;
    }

    if (!isEmpty(newSelectedSecondaryValue) && overrideOptionValues) {
      newSelectedSecondaryValue = newSelectedSecondaryValue.filter((currentSecondaryValue) => {
        return !overrideOptionValues.includes(currentSecondaryValue);
      });
    }

    const newSelectedContainerData = {
      ...selectedContainerData,
      [primaryName]: newValue,
      [secondaryName]: newSelectedSecondaryValue,
    };

    this.setState((prevState) => ({
      ...prevState,
      [nameContainerData]: newSelectedContainerData,
    }));

    const {
      keyNamePaginate,
      containerSliceData,
      forceAppendPayloadWhenFilterChanges,
      appendPayloadWhenFilterChanges,
    } = this.props;

    const paginate = !isEmpty(containerSliceData)
      ? containerSliceData[keyNamePaginate]
      : TERSIER_DEFAULT_LIST_PARAMS.paginate;

    let searchParamsForHitEndpoint = cloneDeep(newSelectedContainerData);

    searchParamsForHitEndpoint = objHelper.filteringExistedValue(
      cloneDeep(searchParamsForHitEndpoint),
    );

    if (paginate) {
      searchParamsForHitEndpoint = {
        ...searchParamsForHitEndpoint,
        paginate,
      };
    }

    if (forceAppendPayloadWhenFilterChanges) {
      searchParamsForHitEndpoint = {
        ...searchParamsForHitEndpoint,
        ...appendPayloadWhenFilterChanges,
      };
    }

    if (isEmpty(searchParamsForHitEndpoint)) {
      await this.props.onGetDataList({});

      return;
    }

    await this.props.onGetDataList(objHelper.filteringExistedValue(searchParamsForHitEndpoint));
  }

  render() {
    const { searchParams, focusedInputName } = this.state;

    const {
      t,
      i18n,
      classes,
      columnSearchList,
      isGetDataListLoading,
      textCancelButtonDateRange,
      textApplyButtonDateRange,
      additionalCellTableProps,
    } = this.props;

    const { language: currLanguage } = i18n;

    return (
      <TableHead className={classes.TableHeadMainContainer}>
        <TableRow>
          {!isEmpty(columnSearchList) &&
            Array.isArray(columnSearchList) &&
            columnSearchList.map((coloumnHeader, index) => {
              const { name, label, placeholder, inputName, fullWidth = true } = coloumnHeader;

              if (!inputName) {
                return (
                  <TableCell
                    key={index}
                    align={coloumnHeader.align}
                    style={{
                      verticalAlign: 'top',
                      alignItems: 'flex-end',
                      width: coloumnHeader.width,
                      minWidth: coloumnHeader.minWidth,
                      maxWidth: coloumnHeader.maxWidth,
                    }}
                    className={classes.tableCellMain}
                    {...additionalCellTableProps}
                  >
                    <p>{coloumnHeader.label}</p>
                  </TableCell>
                );
              }

              if (coloumnHeader.inputType === DEFAULT_SEARCH_INPUT.dateRangeInput) {
                const { keyStartDate, keyEndDate, overrideFormatDate } = coloumnHeader;

                return (
                  <TableCell
                    key={index}
                    align={coloumnHeader.align}
                    style={{
                      width: coloumnHeader.width,
                      minWidth: coloumnHeader.minWidth,
                      maxWidth: coloumnHeader.maxWidth,
                      ...coloumnHeader.styles,
                    }}
                    className={classes.tableCellMain}
                    {...additionalCellTableProps}
                  >
                    <p>{label}</p>
                    <FormControl fullWidth={fullWidth}>
                      <DateTimePicker
                        locale={currLanguage}
                        startDate={searchParams[keyStartDate]}
                        endDate={searchParams[keyEndDate]}
                        textCancel={t(textCancelButtonDateRange)}
                        textApply={t(textApplyButtonDateRange)}
                        placeHolderInput={placeholder}
                        onSelectedChange={(value) =>
                          this.handleOnChangeDateRange(value, keyStartDate, keyEndDate)
                        }
                        defaultKeyStartDate={keyStartDate}
                        defaultKeyEndDate={keyEndDate}
                        isDisabledInput={isGetDataListLoading}
                        overrideFormatDate={overrideFormatDate}
                      />
                    </FormControl>
                  </TableCell>
                );
              }

              if (coloumnHeader.inputType === DEFAULT_SEARCH_INPUT.autocompleteFetchAPIInput) {
                const {
                  autoHighlight = true,
                  isPaginated = true,
                  isUseFetchAction = true,
                  fetchAction = () => {
                    return null;
                  },
                  additionalParamsForAction,
                  isUseRemoveDataAction = true,
                  removeDataAction = () => {
                    return null;
                  },
                  additionalParamsForRemoveDataAction,
                  isUseScrollAction = true,
                  additionalParamsForScrollAction,
                  isUseFetchOnInput = true,
                  keyNameFetchInput,
                  searchKeyFromAPIData,
                  requestStatusLoading,
                  requestStatusKeyName = 'statusRequest',
                  parentStoreDataKeyName,
                  childrenStoreDataKeyName,
                  size = 'small',
                  PopperComponent = PopperAutoWidth,
                  multiple = false,
                  identity,
                  containerName,
                  containerSelectedName,
                  selectedKeyName,
                  containerKeyDataList = 'data',
                  paginateKeyName = 'per_page',
                  getOptionLabel,
                } = coloumnHeader;

                const { rootState } = this.props;

                const parentState = rootState[parentStoreDataKeyName];
                const childrenState = parentState[childrenStoreDataKeyName];

                let isFetchLoading = false,
                  options = [],
                  paginateOptions = TERSIER_DEFAULT_LIST_PARAMS.paginate;

                if (requestStatusLoading && requestStatusKeyName) {
                  isFetchLoading = parentState[requestStatusKeyName] === requestStatusLoading;
                }

                if (!isEmpty(childrenState) && Array.isArray(childrenState[containerKeyDataList])) {
                  options = Array.from(childrenState[containerKeyDataList]);

                  if (childrenState[paginateKeyName]) {
                    paginateOptions = Number(childrenState[paginateKeyName]);
                  }
                }

                return (
                  <TableCell
                    key={index}
                    align={coloumnHeader.align}
                    style={{
                      width: coloumnHeader.width,
                      minWidth: coloumnHeader.minWidth,
                      maxWidth: coloumnHeader.maxWidth,
                      ...coloumnHeader.styles,
                    }}
                    className={classes.tableCellMain}
                    {...additionalCellTableProps}
                  >
                    <p>{label}</p>
                    <FormControl fullWidth={fullWidth}>
                      <AutocompleteCustom
                        id={identity}
                        name={inputName}
                        size={size}
                        multiple={multiple}
                        options={options}
                        value={searchParams[containerName]}
                        autoHighlight={autoHighlight}
                        isPaginated={isPaginated}
                        totalOptions={autocompleteHelper.getOptionPaginatedAutocomplete(
                          options,
                          paginateOptions,
                          3,
                        )}
                        requestStatusKeyName={requestStatusKeyName}
                        isUseFetchAction={isUseFetchAction}
                        fetchAction={fetchAction}
                        additionalParamsForAction={additionalParamsForAction}
                        isUseRemoveDataAction={isUseRemoveDataAction}
                        removeDataAction={removeDataAction}
                        additionalParamsForRemoveDataAction={additionalParamsForRemoveDataAction}
                        isUseScrollAction={isUseScrollAction}
                        additionalParamsForScrollAction={additionalParamsForScrollAction}
                        requestStatusLoading={requestStatusLoading}
                        parentStoreDataKeyName={parentStoreDataKeyName}
                        childrenStoreDataKeyName={childrenStoreDataKeyName}
                        searchKeyFromAPIData={searchKeyFromAPIData}
                        onChange={(event, newValue) =>
                          this.handleChangeAutocompleteAPISingleData(
                            event,
                            newValue,
                            containerName,
                            containerSelectedName,
                            selectedKeyName,
                          )
                        }
                        getOptionLabel={
                          getOptionLabel !== undefined && typeof getOptionLabel === 'function'
                            ? getOptionLabel
                            : (options) => options[name]
                        }
                        isOptionEqualToValue={(option, value) =>
                          autocompleteHelper.isOptionEqualToValue(option, value, identity)
                        }
                        PopperComponent={PopperComponent}
                        isUseFetchOnInput={isUseFetchOnInput}
                        keyNameFetchInput={keyNameFetchInput}
                        isFetchLoading={isFetchLoading}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id={identity}
                            name={inputName}
                            placeholder={placeholder}
                            disabled={isGetDataListLoading}
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                );
              }

              if (coloumnHeader.inputType === DEFAULT_SEARCH_INPUT.autocompleteConstantOptions) {
                const {
                  inputName,
                  placeholder,
                  align,
                  width,
                  minWidth,
                  maxWidth,
                  styles,
                  multiple = true,
                  identity,
                  containerName,
                  containerSelectedName,
                  selectedKeyName,
                  size = 'small',
                  PopperComponent = PopperAutoWidth,
                  options: optionsProps,
                  generateOptions,
                  getOptionLabel,
                  isOptionEqualToValue,
                  label,
                } = coloumnHeader;

                const options =
                  typeof generateOptions === 'function' ? generateOptions(t) : optionsProps;

                return (
                  <TableCell
                    key={index}
                    align={align}
                    style={{ width, maxWidth, minWidth, ...styles }}
                    className={classes.tableCellMain}
                    {...additionalCellTableProps}
                  >
                    <p>{label}</p>
                    <FormControl fullWidth>
                      <AutocompleteCustom
                        id={identity}
                        size={size}
                        className='minimumInput'
                        multiple={multiple}
                        limitTags={1}
                        name={inputName}
                        value={searchParams[containerName]}
                        options={options}
                        disabled={isGetDataListLoading}
                        getOptionLabel={
                          typeof getOptionLabel === 'function'
                            ? getOptionLabel
                            : (option) => autocompleteHelper.getOptionLabel(option, 'label')
                        }
                        isOptionEqualToValue={
                          typeof isOptionEqualToValue === 'function'
                            ? isOptionEqualToValue
                            : (option, value) =>
                                autocompleteHelper.isOptionEqualToValue(option, value, 'value')
                        }
                        onChange={(event, newValue) => {
                          this.handleChangeAutocompleteConstantOptions(
                            event,
                            newValue,
                            containerName,
                            containerSelectedName,
                            selectedKeyName,
                            coloumnHeader,
                          );
                        }}
                        PopperComponent={PopperComponent}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled={isGetDataListLoading}
                            placeholder={isEmpty(searchParams[containerName]) ? placeholder : ''}
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                );
              }

              return (
                <TableCell
                  key={index}
                  align={coloumnHeader.align}
                  style={{
                    width: coloumnHeader.width,
                    minWidth: coloumnHeader.minWidth,
                    maxWidth: coloumnHeader.maxWidth,
                    ...coloumnHeader.styles,
                  }}
                  className={classes.tableCellMain}
                  {...additionalCellTableProps}
                >
                  <p>{label}</p>
                  <FormControl fullWidth={fullWidth}>
                    <OutlinedInput
                      name={inputName}
                      autoFocus={inputName === focusedInputName}
                      readOnly={isGetDataListLoading}
                      size='small'
                      placeholder={coloumnHeader.placeholder}
                      onChange={this.handleOnChangeInput}
                      value={searchParams[inputName]}
                      {...(searchParams[inputName] && {
                        endAdornment: (
                          <InputAdornmentClear
                            isShowIcon={searchParams[inputName]}
                            iconName={inputName}
                            isLoadingSearch={isGetDataListLoading}
                            onClickClearButton={this.handleClearSingleFilter}
                          />
                        ),
                      })}
                    />
                  </FormControl>
                </TableCell>
              );
            })}
        </TableRow>
      </TableHead>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rootState: state,
  };
};

TableHeadSearchMain.defaultProps = {
  keyNamePaginate: 'per_page',
  textCancelButtonDateRange: 'button.cancel',
  textApplyButtonDateRange: 'button.apply',
  additionalCellTableProps: {},
  forceAppendPayloadWhenFilterChanges: true,
  appendPayloadWhenFilterChanges: {
    page: 1,
  },
};

const stylingTableHeadSearchMain = withStyles(styles)(TableHeadSearchMain);

const TableHeadSearchMainWithTranslation = withTranslation()(stylingTableHeadSearchMain);

const ConnectedTableHeadSearchMain = connect(mapStateToProps)(TableHeadSearchMainWithTranslation);

export default ConnectedTableHeadSearchMain;
