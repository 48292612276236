import {
  DEFAULT_CHIP_COMPONENT_NAME_INFO_STATUS,
  DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
  DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
} from '../../default/chip-component-name.default';
import {
  DEFAULT_STATUS_MINUTES_HANDOVER_OPEN,
  DEFAULT_STATUS_MINUTES_HANDOVER_PARTIALLY_SENT,
  DEFAULT_STATUS_MINUTES_HANDOVER_SENT,
} from '../../default/minutes-of-handover-status';

/**
 *
 * @param { function } t translation function
 * @returns
 *      pair label, value, colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 *      - colorChip used for coloring chip on status minutes handover
 */
export function minutesHandoverStatus(t) {
  return [
    {
      label: t('label.minutes-handover-status.sent'),
      value: DEFAULT_STATUS_MINUTES_HANDOVER_SENT,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
    },
    {
      label: t('label.minutes-handover-status.partially-sent'),
      value: DEFAULT_STATUS_MINUTES_HANDOVER_PARTIALLY_SENT,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_INFO_STATUS,
    },
    {
      label: t('label.minutes-handover-status.open'),
      value: DEFAULT_STATUS_MINUTES_HANDOVER_OPEN,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
  ];
}

export const mappingMinutesHandoverStatus = {
  [DEFAULT_STATUS_MINUTES_HANDOVER_SENT]: 'label.minutes-handover-status.sent',
  [DEFAULT_STATUS_MINUTES_HANDOVER_PARTIALLY_SENT]: 'label.minutes-handover-status.partially-sent',
  [DEFAULT_STATUS_MINUTES_HANDOVER_OPEN]: 'label.minutes-handover-status.open',
};
