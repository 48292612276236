import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  NAME_STORE,
  REQUEST_STATUS_CREATE_ATTACHMENT_FAILED,
  REQUEST_STATUS_CREATE_ATTACHMENT_PENDING,
  REQUEST_STATUS_CREATE_ATTACHMENT_SUCCESS,
  REQUEST_STATUS_DELETE_ATTACHMENT_FAILED,
  REQUEST_STATUS_DELETE_ATTACHMENT_PENDING,
  REQUEST_STATUS_DELETE_ATTACHMENT_SUCCESS,
  REQUEST_STATUS_DELETE_BATCH_ATTACHMENT_FAILED,
  REQUEST_STATUS_DELETE_BATCH_ATTACHMENT_PENDING,
  REQUEST_STATUS_DELETE_BATCH_ATTACHMENT_SUCCESS,
  REQUEST_STATUS_LIST_DETAILS_ATTACHMENT_FAILED,
  REQUEST_STATUS_LIST_DETAILS_ATTACHMENT_PENDING,
  REQUEST_STATUS_LIST_DETAILS_ATTACHMENT_SUCCESS,
  SLICE_NAME_LIST_DETAILS_ATTACHMENT,
  SLICE_NAME_TEMP_CREATE_ATTACHMENT,
  SLICE_NAME_TEMP_DELETE_ATTACHMENT,
  STATUS_REQUEST_BASE_IDDLE,
} from '../../utils/constants/store.constant';
import { DEFAULT_KEY_NAME_REQUEST_STATUS } from '../../utils/default/object-keyname.default';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import attachmentActions from './attachment.action';

const initialState = {
  [DEFAULT_KEY_NAME_REQUEST_STATUS]: STATUS_REQUEST_BASE_IDDLE,
  [SLICE_NAME_TEMP_CREATE_ATTACHMENT]: null,
  [SLICE_NAME_TEMP_DELETE_ATTACHMENT]: null,
  [SLICE_NAME_LIST_DETAILS_ATTACHMENT]: null,
};

const attachmentSlice = createSlice({
  name: NAME_STORE.ATTACHMENT,
  initialState,
  reducers: {
    attachmentActions,
    clearDataAttachmentSlice: (state, action) => {
      const { sliceName = SLICE_NAME_TEMP_CREATE_ATTACHMENT, localStorageName = '' } =
        action.payload;

      if (localStorageName) LocalStorage.remove(localStorageName);

      return {
        ...state,
        [sliceName]: null,
        [DEFAULT_KEY_NAME_REQUEST_STATUS]: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState),
  },
  extraReducers: (builder) => {
    // create attachment
    builder.addCase(attachmentActions.createAttachment.pending, (state) => {
      state[DEFAULT_KEY_NAME_REQUEST_STATUS] = REQUEST_STATUS_CREATE_ATTACHMENT_PENDING;
    }),
      builder.addCase(attachmentActions.createAttachment.fulfilled, (state, action) => {
        state[DEFAULT_KEY_NAME_REQUEST_STATUS] = REQUEST_STATUS_CREATE_ATTACHMENT_SUCCESS;
        state[SLICE_NAME_TEMP_CREATE_ATTACHMENT] =
          action.payload[SLICE_NAME_TEMP_CREATE_ATTACHMENT];
      }),
      builder.addCase(attachmentActions.createAttachment.rejected, (state) => {
        state[DEFAULT_KEY_NAME_REQUEST_STATUS] = REQUEST_STATUS_CREATE_ATTACHMENT_FAILED;
      }),
      // list details attachment
      builder.addCase(attachmentActions.listDetailsAttachment.pending, (state) => {
        state[DEFAULT_KEY_NAME_REQUEST_STATUS] = REQUEST_STATUS_LIST_DETAILS_ATTACHMENT_PENDING;
      }),
      builder.addCase(attachmentActions.listDetailsAttachment.fulfilled, (state, action) => {
        state[DEFAULT_KEY_NAME_REQUEST_STATUS] = REQUEST_STATUS_LIST_DETAILS_ATTACHMENT_SUCCESS;
        state[SLICE_NAME_LIST_DETAILS_ATTACHMENT] =
          action.payload[SLICE_NAME_LIST_DETAILS_ATTACHMENT];
      }),
      builder.addCase(attachmentActions.listDetailsAttachment.rejected, (state) => {
        state[DEFAULT_KEY_NAME_REQUEST_STATUS] = REQUEST_STATUS_LIST_DETAILS_ATTACHMENT_FAILED;
        state[SLICE_NAME_LIST_DETAILS_ATTACHMENT] = null;
      });
    // delete attachment
    builder.addCase(attachmentActions.deleteAttachment.pending, (state, action) => {
      state[DEFAULT_KEY_NAME_REQUEST_STATUS] = REQUEST_STATUS_DELETE_ATTACHMENT_PENDING;
      state[SLICE_NAME_TEMP_DELETE_ATTACHMENT] = action.meta.arg;
    }),
      builder.addCase(attachmentActions.deleteAttachment.fulfilled, (state) => {
        state[DEFAULT_KEY_NAME_REQUEST_STATUS] = REQUEST_STATUS_DELETE_ATTACHMENT_SUCCESS;
        state[SLICE_NAME_TEMP_DELETE_ATTACHMENT] = null;
      }),
      builder.addCase(attachmentActions.deleteAttachment.rejected, (state) => {
        state[DEFAULT_KEY_NAME_REQUEST_STATUS] = REQUEST_STATUS_DELETE_ATTACHMENT_FAILED;
        state[SLICE_NAME_TEMP_DELETE_ATTACHMENT] = null;
      }),
      // delete batch attachment
      builder.addCase(attachmentActions.deleteBatchAttachment.pending, (state) => {
        state[DEFAULT_KEY_NAME_REQUEST_STATUS] = REQUEST_STATUS_DELETE_BATCH_ATTACHMENT_PENDING;
      }),
      builder.addCase(attachmentActions.deleteBatchAttachment.fulfilled, (state) => {
        state[DEFAULT_KEY_NAME_REQUEST_STATUS] = REQUEST_STATUS_DELETE_BATCH_ATTACHMENT_SUCCESS;
        state[SLICE_NAME_TEMP_DELETE_ATTACHMENT] = null;
      }),
      builder.addCase(attachmentActions.deleteBatchAttachment.rejected, (state) => {
        state[DEFAULT_KEY_NAME_REQUEST_STATUS] = REQUEST_STATUS_DELETE_BATCH_ATTACHMENT_FAILED;
      });
  },
});

export const { actions, reducer } = attachmentSlice;

export const { clearDataAttachmentSlice } = actions;

export default reducer;
