export const styles = (theme) => {
  return {
    boxMain: {
      display: 'flex',
      minHeight: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.primary,
    },
    boxTextOut: {
      position: 'absolute',
      left: '1vw',
      top: '1vh',
    },
  };
};
