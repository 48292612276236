import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Clear } from '@mui/icons-material';
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { cloneDeep, isEmpty, isEqual } from 'lodash';

import AutocompleteCustom from '../../../../components/custom/CustomAutocomplete/CustomAutocomplete';
import DateTimePicker from '../../../../components/date-time/DateTimePicker/DateTimePicker';
import InputAdornmentLoading from '../../../../components/input-adorment/InputAdornmentLoading/InputAdornmentLoading';
import PopperAutoWidth from '../../../../components/popper/PopperAutoWidth/PopperAutoWidth';
import { contactList } from '../../../../store/contact/contact.action';
import { clearContactData } from '../../../../store/contact/contact.slice';
import messageHelper from '../../../../store/message/message.helper';
import { getListSalesInvoice } from '../../../../store/sales/sales.action';
import {
  STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
  STATUS_REQUEST_LIST_SALES_INVOICE_PENDING,
} from '../../../../utils/constants/store.constant';
import { initialSearchSalesInvoice } from '../../../../utils/data/display/initial-search-sales-invoice';
import { columnsSearchWithDate } from '../../../../utils/data/display/table-list-sales-invoice';
import {
  salesDeliveryStatus,
  salesInvoiceStatus,
} from '../../../../utils/data/label-value/sales-status';
import { TERSIER_DEFAULT_LIST_PARAMS } from '../../../../utils/default/params.default';
import arrHelp from '../../../../utils/helpers/array.helpers';
import autocompleteHelper from '../../../../utils/helpers/component/autocomplete.helpers';
import dateHelpers from '../../../../utils/helpers/date.helpers';
import formatHelp from '../../../../utils/helpers/format.helpers';
import objHelper from '../../../../utils/helpers/object.helper';

import { styles } from './TableHeadSearchSalesInvoice.styles';

class TableHeadSearchSalesInvoice extends Component {
  constructor(props) {
    super(props);

    const { t } = props;

    this.state = {
      searchParams: {
        ...initialSearchSalesInvoice(t),
      },
      focusedInputName: null,
      selectedAutocompleteName: null,
    };

    this.searchSalesInvoiceTimeoutID = null;

    this.handleClearSingleFilter = this.handleClearSingleFilter.bind(this);

    // handling search by input
    this.handleOnChangeInput = this.handleOnChangeInput.bind(this);
    this.handleChangeAutocomplete = this.handleChangeAutocomplete.bind(this);
  }

  async handleClearSingleFilter(event) {
    const { name } = event.currentTarget;

    const { searchParams: currSearchParams } = this.state;

    const newSearchParams = {
      ...currSearchParams,
      [name]: '',
    };

    this.setState((prevState) => ({
      ...prevState,
      searchParams: {
        ...newSearchParams,
      },
    }));

    const { sales } = this.props;
    const { salesInvoiceData } = sales;

    if (sales.statusRequest === STATUS_REQUEST_LIST_SALES_INVOICE_PENDING) {
      return;
    }

    const paginate = salesInvoiceData ? salesInvoiceData.per_page : 20;

    await this.props.getListSalesInvoice({
      paginate,
      ...newSearchParams,
    });
  }

  handleOnChangeDate(newValueDate) {
    const { searchParams: currSearchParams } = this.state;

    const isDateValueNotChanged = isEqual(
      newValueDate,
      objHelper.filterKeyObj(currSearchParams, [], columnsSearchWithDate),
    );

    if (isDateValueNotChanged) return;

    if (!isEmpty(newValueDate)) {
      const { from_date, to_date } = newValueDate;

      if (
        !dateHelpers.isValidDateV2(new Date(from_date)) ||
        !dateHelpers.isValidDateV2(new Date(to_date))
      ) {
        const { t } = this.props;

        this.props.setFailedMessage(
          t('dashboard.sales.sales-invoice.message.invalid-date-format.primary'),
          t('dashboard.sales.sales-invoice.message.invalid-date-format.secondary'),
        );

        return;
      }
    }

    const newSearchParams = {
      ...currSearchParams,
      ...newValueDate,
    };

    this.setState((prevState) => ({
      ...prevState,
      searchParams: {
        ...newSearchParams,
      },
    }));

    if (this.searchSalesInvoiceTimeoutID) {
      clearTimeout(this.searchSalesInvoiceTimeoutID);
    }

    this.searchSalesInvoiceTimeoutID = setTimeout(async () => {
      const { sales } = this.props;
      const { salesInvoiceData } = sales;

      const paginate = salesInvoiceData ? salesInvoiceData.per_page : 20;

      return await this.props.getListSalesInvoice({
        paginate,
        ...newSearchParams,
      });
    }, 750);
  }

  handleOnChangeInput(event) {
    const { name, value } = event.target;

    const { searchParams: currSearchParams } = this.state;

    const newSearchParams = {
      ...currSearchParams,
      [name]: value,
    };

    this.setState((prevState) => ({
      ...prevState,
      searchParams: {
        ...newSearchParams,
      },
      focusedInputName: name,
    }));

    if (this.searchSalesInvoiceTimeoutID) {
      clearTimeout(this.searchSalesInvoiceTimeoutID);
    }

    this.searchSalesInvoiceTimeoutID = setTimeout(async () => {
      const { sales } = this.props;
      const { salesInvoiceData } = sales;

      const paginate = salesInvoiceData ? salesInvoiceData.per_page : 20;

      return await this.props.getListSalesInvoice({
        paginate,
        ...newSearchParams,
      });
    }, 750);

    if (name === 'customer_id') {
      const { value } = event.target;

      const { contact } = this.props;

      if (contact.statusRequest === STATUS_REQUEST_LIST_CONTACT_DATA_PENDING) {
        return;
      }

      // value = {
      //     contact_name: value,
      // }

      if (this.searchSalesInvoiceTimeoutID) {
        clearTimeout(this.searchSalesInvoiceTimeoutID);
      }

      this.searchSalesInvoiceTimeoutID = setTimeout(async () => {
        return await this.props.getListContact({
          ...TERSIER_DEFAULT_LIST_PARAMS,
          type: ['is_customer'],
          contact_name: value,
        });
      }, 750);
    }
  }

  async handleOnOpenAutocomplete(event, name) {
    event.preventDefault();

    if (name) {
      this.setState((prevState) => ({
        ...prevState,
        selectedAutocompleteName: name,
      }));
    }

    const { contact } = this.props;

    if (
      name === 'customer_id' &&
      contact.statusRequest !== STATUS_REQUEST_LIST_CONTACT_DATA_PENDING
    ) {
      await this.props.getListContact({
        ...TERSIER_DEFAULT_LIST_PARAMS,
        type: ['is_customer'],
      });
    }
  }

  async handleOnCloseAutocomplete(event, name) {
    if (name === 'customer_id') {
      await this.props.clearContactData();
    }

    this.setState((prevState) => ({
      ...prevState,
      selectedAutocompleteName: '',
    }));
  }

  async handleOnScrollAutocomplete(name) {
    if (name === 'customer_id') {
      const { contact } = this.props;
      const { contactData } = contact;

      if (!contactData) return;

      const {
        current_page: page,
        next_page_url,
        per_page: paginate,
        search_contact_name: contact_name,
      } = contactData;

      if (!next_page_url) return;

      await this.props.getListContact({
        page: page + 1,
        paginate,
        type: ['is_customer'],
        contact_name,
        isRewriteAll: false,
      });
    }
  }

  /**
   *
   * @param { object } event built-in event data
   * @param { array } newValue selected new value autocomplete
   * @param { string } containerName name of container data, usually contain all data before ge data in secondary name
   * @param { string } secondaryName name container for handling store data for send to BE
   * @param { string } valueSecondaryKey name key of container data value
   * @param { string } name name input field
   * @returns
   *      set new state and hit sales invoice again
   */
  async handleChangeAutocomplete(
    event,
    newValue,
    containerName,
    secondaryName,
    valueSecondaryKey,
    name,
  ) {
    event.preventDefault();

    const { searchParams: currSearchParams } = this.state;

    if (name === 'customer_id') {
      const newSearchParams = {
        ...currSearchParams,
        [name]: newValue,
        customer_name: !isEmpty(newValue) ? newValue.contact_name : '',
        customer_name_container: newValue,
      };

      this.setState((prevState) => ({
        ...prevState,
        searchParams: {
          ...newSearchParams,
        },
      }));

      const { sales } = this.props;
      const { salesInvoiceData } = sales;

      const paginate = salesInvoiceData ? salesInvoiceData.per_page : 20;

      return await this.props.getListSalesInvoice({
        paginate,
        ...newSearchParams,
      });
    }

    if (name === 'status' || name === 'status_delivery') {
      // check is current params is contained 'all' status
      // when current params contained and incoming data contained another status, then change into incoming data
      // check is new value is contained 'all' status
      // then contained change new value into 'all' status
      if (!isEmpty(currSearchParams[containerName])) {
        const isCurrentStatusContainValueAll =
          currSearchParams[containerName].some((nv) => nv.value === 'all') && newValue.length > 1;

        const isNewValueContainedValueAll = newValue.some((nv) => nv.value === 'all');

        if (isCurrentStatusContainValueAll) {
          newValue = newValue.filter((nv) => nv.value !== 'all');
        } else if (isNewValueContainedValueAll) {
          newValue = newValue.filter((nv) => nv.value === 'all');
        }
      }

      const newValueSecondaryContainer = valueSecondaryKey
        ? newValue.map((value) => value[valueSecondaryKey])
        : newValue;

      const newSearchParamsForState = {
        ...currSearchParams,
        [containerName]: newValue,
        [secondaryName]: newValueSecondaryContainer,
      };

      this.setState((prevState) => ({
        ...prevState,
        searchParams: newSearchParamsForState,
      }));

      const { sales } = this.props;
      const salesInvoiceData = sales.salesInvoiceData;

      const paginate = salesInvoiceData
        ? salesInvoiceData.per_page
        : TERSIER_DEFAULT_LIST_PARAMS.paginate;

      // search params is clean version from params search state
      let searchParamsForHitEndpoint = {
        paginate,
        ...cloneDeep(newSearchParamsForState),
      };

      // only when data status is 'all' status
      if (Array.isArray(newValueSecondaryContainer) && newValueSecondaryContainer.includes('all')) {
        searchParamsForHitEndpoint = objHelper.filterKeyObj(searchParamsForHitEndpoint, [name]);
      }

      const keysIncludeItemAll = Object.keys(searchParamsForHitEndpoint).reduce(
        (statusAllKeys, currentKey) => {
          if (
            Array.isArray(searchParamsForHitEndpoint[currentKey]) &&
            searchParamsForHitEndpoint[currentKey].includes('all')
          ) {
            statusAllKeys = statusAllKeys.concat(currentKey);
          }

          return statusAllKeys;
        },
        [],
      );

      if (keysIncludeItemAll.length) {
        searchParamsForHitEndpoint = objHelper.filterKeyObj(
          searchParamsForHitEndpoint,
          keysIncludeItemAll,
        );
      }

      return await this.props.getListSalesInvoice(searchParamsForHitEndpoint);
    }
  }

  render() {
    const { searchParams, focusedInputName, selectedAutocompleteName } = this.state;

    const { t, i18n, classes, dataHeaders, sales, contact } = this.props;

    const { language } = i18n;

    const {
      status_container: currStatusContainer,
      status_delivery_container: currDeliveryStatusContainer,
    } = searchParams;

    // option list for status autocomplete
    let statusList = salesInvoiceStatus(t);
    let deliveryStatusList = salesDeliveryStatus(t);

    // value for status autocomplete
    const valueOnCurrentStatus = arrHelp.mapWithExistedValue(currStatusContainer, 'value');
    const valueOnCurrentDeliveryStatus = arrHelp.mapWithExistedValue(
      currDeliveryStatusContainer,
      'value',
    );

    const depreciationMethodContainer = statusList.filter((status) => {
      if (valueOnCurrentStatus.includes(status.value)) return status;
    });

    const depreciationDeliveryStatusMethodContainer = deliveryStatusList.filter((status) => {
      if (valueOnCurrentDeliveryStatus.includes(status.value)) return status;
    });

    // value for customer autocomplete
    const { customer_name_container } = searchParams;

    const { contactData } = contact;

    let contactDataList = [];

    let paginateContactData = TERSIER_DEFAULT_LIST_PARAMS.paginate;

    if (contactData) {
      if (Array.isArray(contactData.data)) {
        contactDataList = contactData.data;
      }

      paginateContactData = contactData.per_page;
    }

    const isSalesInvoiceLoading = sales.statusRequest === STATUS_REQUEST_LIST_SALES_INVOICE_PENDING;

    return (
      <TableHead className={classes.TableHeadMainContainer}>
        <TableRow>
          {dataHeaders &&
            dataHeaders.map((dataHeader, index) => {
              if (!dataHeader.name_input) {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{
                      verticalAlign: 'top',
                      alignItems: 'flex-end',
                    }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                  </TableCell>
                );
              }

              // table header for date
              if (columnsSearchWithDate.includes(dataHeader.name_input)) {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{ width: dataHeader.width }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                    <DateTimePicker
                      startDate={searchParams['from_date']}
                      endDate={searchParams['to_date']}
                      textCancel={t('button.cancel')}
                      textApply={t('button.apply')}
                      placeHolderInput={dataHeader.placeholder}
                      onSelectedChange={(value) => this.handleOnChangeDate(value)}
                      locale={language}
                      defaultKeyStartDate={'from_date'}
                      defaultKeyEndDate={'to_date'}
                      isDisabledInput={isSalesInvoiceLoading}
                    />
                  </TableCell>
                );
              }

              // table header for customer
              if (dataHeader.name_input === 'customer_name') {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{ width: dataHeader.width }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                    <FormControl>
                      <AutocompleteCustom
                        id='customer_id'
                        name='customer_id'
                        size='small'
                        sx={{ width: 300 }}
                        options={contactDataList}
                        value={customer_name_container}
                        autoHighlight={true}
                        isPaginated={true}
                        totalOptions={autocompleteHelper.getOptionPaginatedAutocomplete(
                          contactDataList,
                          paginateContactData,
                          3,
                        )}
                        PopperComponent={PopperAutoWidth}
                        onOpen={(event) => this.handleOnOpenAutocomplete(event, 'customer_id')}
                        onClose={(event) => this.handleOnCloseAutocomplete(event, 'customer_id')}
                        onChange={(event, newValue) =>
                          this.handleChangeAutocomplete(event, newValue, '', '', '', 'customer_id')
                        }
                        ListboxProps={{
                          onScroll: (event) => {
                            // i need scroll into bottom view when api was hitted
                            const { offsetHeight, scrollHeight, scrollTop } = event.target;

                            const isEndOfScroll = offsetHeight + scrollTop === scrollHeight;

                            if (isEndOfScroll) {
                              this.handleOnScrollAutocomplete('customer_id');
                            }
                          },
                        }}
                        getOptionLabel={(option) =>
                          autocompleteHelper.getInputValue(
                            option,
                            ['contact_code', 'contact_name'],
                            ['contact_name'],
                            formatHelp.contactCodeWithName,
                          )
                        }
                        isOptionEqualToValue={(option, value) =>
                          autocompleteHelper.isOptionEqualToValue(option, value, 'contact_id')
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name='customer_id'
                            placeholder={t(
                              'dashboard.sales.sales-invoice.input.search-customer.placeholder',
                            )}
                            disabled={this.isActionMenuUpdate}
                            onChange={this.handleOnChangeInput}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <InputAdornmentLoading
                                  isLoading={
                                    selectedAutocompleteName === 'customer_id' &&
                                    contact.statusRequest ===
                                      STATUS_REQUEST_LIST_CONTACT_DATA_PENDING
                                  }
                                  defaultInputAdornment={params.InputProps.endAdornment}
                                />
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                );
              }

              // table header for status
              if (dataHeader.name_input === 'status') {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{ width: dataHeader.width, minWidth: dataHeader.minWidth }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        size='small'
                        id='multiple-limit-tages-status-purchase'
                        name={dataHeader.name_input}
                        limitTags={1}
                        className={classes.containerAutocomplete}
                        value={depreciationMethodContainer}
                        options={statusList}
                        getOptionLabel={(option) =>
                          autocompleteHelper.getOptionLabel(option, 'label')
                        }
                        isOptionEqualToValue={(option, value) =>
                          autocompleteHelper.isOptionEqualToValue(option, value, 'value')
                        }
                        onChange={(event, newValue) => {
                          this.handleChangeAutocomplete(
                            event,
                            newValue,
                            'status_container',
                            'status',
                            'value',
                            'status',
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={
                              isEmpty(depreciationMethodContainer) ? dataHeader.placeholder : ''
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                );
              }

              if (dataHeader.name_input === 'status_delivery') {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{ width: dataHeader.width, minWidth: dataHeader.minWidth }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        size='small'
                        id='multiple-limit-tages-status-purchase'
                        name={dataHeader.name_input}
                        limitTags={1}
                        className={classes.containerAutocomplete}
                        value={depreciationDeliveryStatusMethodContainer}
                        options={deliveryStatusList}
                        getOptionLabel={(option) =>
                          autocompleteHelper.getOptionLabel(option, 'label')
                        }
                        isOptionEqualToValue={(option, value) =>
                          autocompleteHelper.isOptionEqualToValue(option, value, 'value')
                        }
                        onChange={(event, newValue) => {
                          this.handleChangeAutocomplete(
                            event,
                            newValue,
                            'status_delivery_container',
                            'status_delivery',
                            'value',
                            'status_delivery',
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={
                              isEmpty(depreciationDeliveryStatusMethodContainer)
                                ? dataHeader.placeholder
                                : ''
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                );
              }

              return (
                <TableCell
                  key={index}
                  align={dataHeader.align}
                  style={{ width: dataHeader.width }}
                  className={classes.tableCellMain}
                >
                  <p>{dataHeader.label}</p>
                  <FormControl>
                    <OutlinedInput
                      name={dataHeader.name_input}
                      autoFocus={dataHeader.name_input === focusedInputName}
                      size='small'
                      placeholder={dataHeader.placeholder}
                      onChange={this.handleOnChangeInput}
                      value={searchParams[dataHeader.name_input]}
                      {...(searchParams[dataHeader.name_input] && {
                        endAdornment: (
                          <InputAdornment
                            position='end'
                            className={classes.inputAdornmentClearSingleFilter}
                          >
                            {searchParams[dataHeader.name_input] && (
                              <IconButton
                                edge='end'
                                name={dataHeader.name_input}
                                className={classes.iconButtonContainerClear}
                                onClick={this.handleClearSingleFilter}
                              >
                                <Clear fontSize='10px' />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      })}
                    />
                  </FormControl>
                </TableCell>
              );
            })}
        </TableRow>
      </TableHead>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sales: state.sales,
    contact: state.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListSalesInvoice: (paramsGetListSalesInvoice) =>
      dispatch(getListSalesInvoice(paramsGetListSalesInvoice)),
    getListContact: (paramsGetListContact) => dispatch(contactList(paramsGetListContact)),
    clearContactData: () => dispatch(clearContactData()),
    setFailedMessage: (primaryText, secondaryText) =>
      messageHelper.failedMessage(dispatch, primaryText, secondaryText, false),
  };
};

// add style into component
const stylingTableHeadSearchSalesInvoice = withStyles(styles)(TableHeadSearchSalesInvoice);

// add translation into component
const TableHeadSalesInvoiceTranslate = withTranslation()(stylingTableHeadSearchSalesInvoice);

// connect with style and action into component
const ConnectedTableHeadSearchSalesInvoice = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TableHeadSalesInvoiceTranslate);

export default ConnectedTableHeadSearchSalesInvoice;
