import * as React from 'react';
function SvgPurchaseDebitNoteMenu(props) {
  return (
    <svg width={109} height={77} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#purchase-debit-note-menu_svg__filter0_d_5586_193068)'>
        <path
          d='M101.75 58.49L27.343 69.925 17.75 13.362 92.157 1.926l9.593 56.563z'
          fill='#F2EF8F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M64.546 64.208l37.204-5.718-9.593-56.564-37.204 5.718 9.593 56.564z'
          fill='#E2DF69'
        />
        <path d='M28.182 27.81l58.063-8.931-.587-3.536-58.063 8.93.587 3.536z' fill='#988534' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M85.566 15.343l-28.53 4.388.572 3.538 28.545-4.39-.587-3.536z'
          fill='#726324'
        />
        <path d='M29.86 36.642l58.061-8.925-.586-3.534-58.062 8.925.586 3.534z' fill='#988534' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M87.335 24.183l-28.776 4.423.572 3.536 28.79-4.425-.586-3.534z'
          fill='#726324'
        />
        <path d='M31.394 46.04l58.064-8.925-.587-3.536-58.064 8.925.587 3.536z' fill='#988534' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.871 33.58l-28.8 4.426.632 3.53 28.755-4.42-.587-3.537z'
          fill='#726324'
        />
        <path d='M64.928 50.213l-32.01 4.922-.59-3.535 32.01-4.922.59 3.535z' fill='#988534' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M62.276 50.62l2.652-.407-.59-3.535-2.706.416.644 3.527z'
          fill='#726324'
        />
        <path
          d='M6.398 36.124c0 1.03-1.04 1.869-2.323 1.869-1.284 0-2.325-.838-2.325-1.869 0-1.033 1.041-1.87 2.325-1.87 1.282 0 2.323.837 2.323 1.87zM38.863 38.568l-18.075 4.94L27.87 60.29l18.075-4.939-7.081-16.784z'
          fill='#FF8E31'
        />
        <path
          d='M49.384 65.67H19.097c-.96 0-1.865-.301-2.547-.85-.679-.548-1.054-1.276-1.054-2.05v-.078c0-.657.283-1.304.793-1.818.514-.512 1.237-.873 2.037-1.015l25.377-4.493c.602-.106 1.196.2 1.329.682.132.483-.248.963-.85 1.07l-25.377 4.493c-.31.053-.578.188-.777.387a.976.976 0 00-.304.694v.078c0 .295.143.573.402.782.26.208.606.325.97.325h30.287c.616 0 1.114.4 1.114.896 0 .495-.497.897-1.113.897z'
          fill='#F2F4F7'
        />
        <path
          d='M19.886 61.398c-.537 0-1.011-.314-1.098-.756l-4.582-23.094a1.055 1.055 0 00-.465-.668 1.58 1.58 0 00-.894-.265H4.075c-.616 0-1.116-.401-1.116-.896s.5-.898 1.116-.898h8.771c.857 0 1.688.248 2.34.695.651.446 1.087 1.068 1.22 1.75l4.581 23.093c.096.489-.318.949-.925 1.027-.06.008-.117.012-.176.012z'
          fill='#F2F4F7'
        />
        <path
          d='M25.014 67.14c0 1.58-1.593 2.86-3.553 2.86-1.962 0-3.555-1.28-3.555-2.86 0-1.58 1.593-2.859 3.555-2.859 1.96 0 3.553 1.278 3.553 2.858z'
          fill='#34495E'
        />
        <path
          d='M23.235 67.14c0 .79-.794 1.428-1.774 1.428-.983 0-1.775-.638-1.775-1.429 0-.788.792-1.428 1.775-1.428.98 0 1.774.64 1.774 1.428z'
          fill='#F2F4F7'
        />
        <path
          d='M50.498 67.14c0 1.58-1.592 2.86-3.554 2.86-1.961 0-3.552-1.28-3.552-2.86 0-1.58 1.591-2.859 3.552-2.859 1.962 0 3.554 1.278 3.554 2.858z'
          fill='#34495E'
        />
        <path
          d='M48.72 67.14c0 .79-.796 1.429-1.776 1.429s-1.776-.64-1.776-1.43c0-.788.796-1.428 1.776-1.428s1.777.64 1.777 1.428zM50.114 43.914a1.476 1.476 0 00-.928-.31H15.302l3.672 18.381v.001l30.494-5.4c.282-.049.543-.173.74-.368a.935.935 0 00.29-.661V44.659c0-.27-.129-.54-.384-.745zM21.554 59.75a.953.953 0 01-.133.008c-.4 0-.753-.233-.818-.562l-2.614-13.082c-.072-.364.235-.707.688-.765.45-.058.878.19.952.553l2.612 13.083c.073.364-.234.707-.687.765zm4.333-.768a.9.9 0 01-.118.007c-.406 0-.762-.24-.82-.573L22.77 46.103c-.065-.365.25-.703.703-.755.458-.054.875.201.94.567l2.178 12.312c.065.365-.25.703-.705.755zm4.33-.768a1.222 1.222 0 01-.1.005c-.413 0-.771-.249-.822-.588l-1.743-11.542c-.056-.365.27-.7.723-.744.456-.038.868.217.925.583l1.742 11.543c.055.366-.268.698-.724.743zm4.33-.768a1.294 1.294 0 01-.083.003c-.422 0-.783-.258-.824-.604l-1.308-10.772c-.045-.367.288-.694.746-.73.463-.031.863.233.907.6l1.308 10.773c.043.367-.29.694-.746.73zm4.325-.767l-.06.001c-.432 0-.795-.269-.826-.621l-.87-10.004c-.032-.368.311-.687.77-.713.462-.02.853.252.886.62l.87 10.003c.032.369-.314.687-.77.714zm4.322-.77h-.032c-.444 0-.812-.282-.83-.642l-.435-9.233c-.016-.369.34-.679.799-.693.463-.023.843.273.861.642l.435 9.233c.018.37-.34.679-.797.693zm5.147-1.438c0 .37-.372.668-.83.668-.459 0-.83-.298-.83-.668V46.01c0-.37.371-.668.83-.668.458 0 .83.298.83.668v8.462z'
          fill='#F2F4F7'
        />
      </g>
      <defs>
        <filter
          id='purchase-debit-note-menu_svg__filter0_d_5586_193068'
          x={0.75}
          y={0.926}
          width={108}
          height={76.074}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_193068' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_193068' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgPurchaseDebitNoteMenu;
