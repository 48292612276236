import { createAsyncThunk } from '@reduxjs/toolkit';

import companyServices from '../../services/API/company/company.service';
import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_COMPANY } from '../../utils/constants/storage.constant';
import { SLICE_NAME_SETTING_COMPANY_DETAILS } from '../../utils/constants/store.constant';
import { logout } from '../auth/auth.action';
import { creatorAddAction, creatorDetailAction } from '../creator-action/creator-action.helper';
import messageHelper from '../message/message.helper';

// handling get detail company with id
// always triggering when user already logged in
export const detailCompanyWithID = createAsyncThunk(
  'company/detail-with-id',
  async (company_id, thunkAPI) => {
    try {
      const response = await companyServices.companyDetailWithID(company_id);

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      const { data: dataCompanyUser } = response.data;

      LocalStorage.set(LOCAL_STORAGE_COMPANY, dataCompanyUser);

      return { dataCompanyUser };
    } catch (error) {
      const { response } = error;

      // fixing: remove logout action when detail company self failed
      // await thunkAPI.dispatch(logout());

      if (!response) {
        return thunkAPI.rejectWithValue(null);
      }

      const { data } = response;

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// action get company details
// same as above but can pass object that minimum contained company_id
export const getCompanyDetails = createAsyncThunk(
  'company/get-company-details',
  async (paramsGetCompanyDetails, thunkAPI) => {
    const { company_id } = paramsGetCompanyDetails;

    return await creatorDetailAction(
      thunkAPI,
      company_id,
      companyServices.companyDetailWithID,
      '',
      SLICE_NAME_SETTING_COMPANY_DETAILS,
      'error.detail.company',
    );
  },
);

export const updateCompanyUser = createAsyncThunk(
  'company/user-update',
  async (dataBodyUpdateCompany, thunkAPI) => {
    try {
      const response = await companyServices.updateCompanyUser(dataBodyUpdateCompany);

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      const { data: dataCompanyUser, sucess } = response.data;

      const messageResponse = messageHelper.getMessageFromResponseData(response.data);

      if (!sucess) {
        throw { response };
      }

      if (!messageResponse) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.update.user-company',
          'success.update.user-company',
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          messageResponse,
          'success.update.user-company',
        );
      }

      return { dataCompanyUser };
    } catch (error) {
      const { response } = error;

      if (!response) {
        return thunkAPI.rejectWithValue(error);
      }

      const { data } = response;

      const messageResponse = messageHelper.getMessageFromResponseData(data);

      if (!messageResponse) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.update.user-company',
          'error.update.user-company',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          messageResponse,
          'error.update.user-company',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// action registration company
export const registerCompany = createAsyncThunk(
  'company/register',
  async (companyRegisterData, thunkAPI) => {
    return await creatorAddAction(
      thunkAPI,
      companyRegisterData,
      companyServices.registerCompany,
      '',
      'error.register.new-company',
      {},
      {},
      false,
    );
  },
);
