import { blue } from '@mui/material/colors';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { getRangeDateThisMonth } from '../../components/date-time/DateTimePicker/customDefaultStaticRange';
import { Expense, ProfitLoss, Sale } from '../../components/icons';
import {
  getChartBasicDashboardDetails,
  getIncomeStatementChartDetails,
  getSalesTransactionChartDetails,
} from '../../store/chart/chart.action';
import LineChartSalesComparisonDashboard from '../../views/ContentMain/LineChartSalesComparisonDashboard';
import VerticalBarChartTopSalesDashboard from '../../views/ContentMain/VerticalBarChartTopSalesDashboard';
import {
  REACT_APP_API_URL_CHART_COMPARISON_SALES,
  REACT_APP_API_URL_CHART_PIVOT_TABLE,
  REACT_APP_API_URL_CHART_PURCHASE_INVOICE,
  REACT_APP_API_URL_CHART_SALES_INVOICE,
  REACT_APP_API_URL_CHART_TOP_SALES_COMPARISON_CUSTOMER,
  REACT_APP_API_URL_CHART_TOP_SALES_COMPARISON_PRODUCT,
  REACT_APP_API_URL_CHART_TOP_SALES_COMPARISON_PRODUCT_CATEGORY,
  REACT_APP_API_URL_CHART_TOP_SALES_COMPARISON_SALES_AGENT,
  REACT_APP_API_URL_CHART_TOP_SALES_CUSTOMER,
  REACT_APP_API_URL_CHART_TOP_SALES_PRODUCT,
  REACT_APP_API_URL_CHART_TOP_SALES_PRODUCT_CATEGORY,
  REACT_APP_API_URL_CHART_TOP_SALES_SALES_AGENT,
} from '../configs/api.config';
import {
  REACT_APP_PURCHASE_PURCHASE_INVOICE_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_SALES_INVOICE_DETAILS_TEMPLATE_URL,
} from '../configs/url.config';
import {
  LOCAL_STORAGE_CHART_COMPARISON_SALES_LIST,
  LOCAL_STORAGE_CHART_PIVOT_TABLE_LIST,
  LOCAL_STORAGE_CHART_PURCHASE_INVOICE_BASIC_LIST,
  LOCAL_STORAGE_CHART_SALES_INVOICE_BASIC_LIST,
  LOCAL_STORAGE_CHART_TOP_SALES_COMPARISON_CUSTOMER_LIST,
  LOCAL_STORAGE_CHART_TOP_SALES_COMPARISON_PRODUCT_CATEGORY_LIST,
  LOCAL_STORAGE_CHART_TOP_SALES_COMPARISON_PRODUCT_LIST,
  LOCAL_STORAGE_CHART_TOP_SALES_COMPARISON_SALES_AGENT_LIST,
  LOCAL_STORAGE_CHART_TOP_SALES_CUSTOMER_LIST,
  LOCAL_STORAGE_CHART_TOP_SALES_PRODUCT_CATEGORY_LIST,
  LOCAL_STORAGE_CHART_TOP_SALES_PRODUCT_LIST,
  LOCAL_STORAGE_CHART_TOP_SALES_SALES_AGENT_LIST,
} from '../constants/storage.constant';
import {
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE_FAILED,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE_PENDING,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE_SUCCESS,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_FAILED,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_INVOICE_FAILED,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_INVOICE_PENDING,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_INVOICE_SUCCESS,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_PENDING,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_SUCCESS,
  REQUEST_STATUS_CHART_DASHBOARD_EXPENSE_FAILED,
  REQUEST_STATUS_CHART_DASHBOARD_EXPENSE_PENDING,
  REQUEST_STATUS_CHART_DASHBOARD_EXPENSE_SUCCESS,
  REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_FAILED,
  REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_PENDING,
  REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_SUCCESS,
  REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_FAILED,
  REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_PENDING,
  REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_SUCCESS,
  REQUEST_STATUS_CHART_LIST_COMPARISON_SALES_FAILED,
  REQUEST_STATUS_CHART_LIST_COMPARISON_SALES_PENDING,
  REQUEST_STATUS_CHART_LIST_COMPARISON_SALES_SUCCESS,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_CUSTOMER_FAILED,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_CUSTOMER_PENDING,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_CUSTOMER_SUCCESS,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_PRODUCT_CATEGORY_FAILED,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_PRODUCT_CATEGORY_PENDING,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_PRODUCT_CATEGORY_SUCCESS,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_PRODUCT_FAILED,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_PRODUCT_PENDING,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_PRODUCT_SUCCESS,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_SALES_AGENT_FAILED,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_SALES_AGENT_PENDING,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_SALES_AGENT_SUCCESS,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_CUSTOMER_FAILED,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_CUSTOMER_PENDING,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_CUSTOMER_SUCCESS,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_PRODUCT_CATEGORY_FAILED,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_PRODUCT_CATEGORY_PENDING,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_PRODUCT_CATEGORY_SUCCESS,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_PRODUCT_FAILED,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_PRODUCT_PENDING,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_PRODUCT_SUCCESS,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_SALES_AGENT_FAILED,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_SALES_AGENT_PENDING,
  REQUEST_STATUS_CHART_LIST_TOP_SALES_SALES_AGENT_SUCCESS,
  REQUEST_STATUS_CHART_PIVOT_TABLE_FAILED,
  REQUEST_STATUS_CHART_PIVOT_TABLE_PENDING,
  REQUEST_STATUS_CHART_PIVOT_TABLE_SUCCESS,
  ROOT_STORE_NAME_CHART,
  SLICE_NAME_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE,
  SLICE_NAME_CHART_BASIC_DASHBOARD_SALES,
  SLICE_NAME_CHART_BASIC_DASHBOARD_SALES_INVOICE,
  SLICE_NAME_CHART_COMPARISON_SALES,
  SLICE_NAME_CHART_EXPENSE,
  SLICE_NAME_CHART_INCOME_STATEMENT_DETAILS,
  SLICE_NAME_CHART_PIVOT_TABLE,
  SLICE_NAME_CHART_SALES_TRANSACTION_DETAILS,
  SLICE_NAME_CHART_TOP_SALES_COMPARISON_CUSTOMER,
  SLICE_NAME_CHART_TOP_SALES_COMPARISON_PRODUCT,
  SLICE_NAME_CHART_TOP_SALES_COMPARISON_PRODUCT_CATEGORY,
  SLICE_NAME_CHART_TOP_SALES_COMPARISON_SALES_AGENT,
  SLICE_NAME_CHART_TOP_SALES_CUSTOMER,
  SLICE_NAME_CHART_TOP_SALES_PRODUCT,
  SLICE_NAME_CHART_TOP_SALES_PRODUCT_CATEGORY,
  SLICE_NAME_CHART_TOP_SALES_SALES_AGENT,
} from '../constants/store.constant';
import {
  columnsTableChartPurchaseInvoiceDashboard,
  columnsTableChartSalesInvoiceDashboard,
  columnsTableChartTopSalesComparisonDashboard,
  columnsTableChartTopSalesDashboard,
  columnsTableChartTopSalesProductDashboard,
} from '../data/display/table-chart-dashboard-list';
import {
  chartTopSalesComparisonSubType,
  chartTopSalesSubType,
} from '../data/label-value/chart-dashboard';
import {
  comparisonSalesChartTypeWithCheckboxVariant,
  comparisonSalesExpenseType,
  comparisonSalesProfitType,
  comparisonSalesSalesType,
} from '../data/label-value/comparison-sales';
import { purchaseInvoiceChartBasicStatusWithCheckboxVariant } from '../data/label-value/purchase-status';
import { salesInvoiceChartBasicStatusWithCheckboxVariant } from '../data/label-value/sales-status';
import strHelp from '../helpers/string.helpers';

import {
  CHART_BASIC_DASHBOARD_TYPE_SALES,
  CHART_DASHBOARD_TYPE_EXPENSE,
} from './chart-basic-dashboard-config.default';
import {
  CHART_INCOME_STATEMENT_TYPE,
  CHART_PRO_DASHBOARD_PIVOT_TABLE_TYPE,
  CHART_PRO_DASHBOARD_PURCHASE_INVOICE_TYPE,
  CHART_PRO_DASHBOARD_SALES_COMPARISON,
  CHART_PRO_DASHBOARD_SALES_INVOICE_TYPE,
  CHART_PRO_DASHBOARD_TOP_SALES_VERTICAL_CHART_MAIN_TYPE,
  CHART_SALES_TRANSACTION_TYPE,
} from './chart-component-type.default';
import {
  CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_CUSTOMER_TYPE,
  CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_MAIN_TYPE,
  CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_PRODUCT_CATEGORY_TYPE,
  CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_PRODUCT_TYPE,
  CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_SALES_AGENT_TYPE,
  CHART_PRO_DASHBOARD_TOP_SALES_CUSTOMER_TYPE,
  CHART_PRO_DASHBOARD_TOP_SALES_MAIN_TYPE,
  CHART_PRO_DASHBOARD_TOP_SALES_PRODUCT_CATEGORY_TYPE,
  CHART_PRO_DASHBOARD_TOP_SALES_PRODUCT_TYPE,
  CHART_PRO_DASHBOARD_TOP_SALES_SALES_AGENT_TYPE,
} from './chart-dashboard.default';
import {
  DEFAULT_FORMAT_READABLE_DATE_MONTH_YEAR,
  DEFAULT_FORMAT_READABLE_MONTH_YEAR,
} from './format.default';
import {
  DEFAULT_DATA_MAX_LENGTH_CHART_Y_AXIS_VALUE,
  DEFAULT_DATA_MAX_LENGTH_SPLITTED_CHART_Y_AXIS_VALUE,
} from './max-length-data.default';
import { menuGroupDashboardProfessional } from './menu-group-name.default';
import {
  DEFAULT_KEY_NAME_AMOUNT_REF_API,
  DEFAULT_KEY_NAME_BEST_DATE_API_REF,
  DEFAULT_KEY_NAME_BEST_MONTH_API_REF,
  DEFAULT_KEY_NAME_CURRENT_DATE_AMOUNT_API_REF,
  DEFAULT_KEY_NAME_CURRENT_DATE_API_REF,
  DEFAULT_KEY_NAME_CURRENT_MONTH_AMOUNT_API_REF,
  DEFAULT_KEY_NAME_DAILY_TRANSACTION_API_REF,
  DEFAULT_KEY_NAME_DATE_API_REF,
  DEFAULT_KEY_NAME_EXPENSE_API_REF,
  DEFAULT_KEY_NAME_FROM_DATE_API_REF,
  DEFAULT_KEY_NAME_INCOME_API_REF,
  DEFAULT_KEY_NAME_INCOME_STATEMENT_API_REF,
  DEFAULT_KEY_NAME_MONTHLY_TRANSACTION_API_REF,
  DEFAULT_KEY_NAME_NAME_API_REF,
  DEFAULT_KEY_NAME_PREVIOUS_DATE_AMOUNT_API_REF,
  DEFAULT_KEY_NAME_PREVIOUS_MONTH_AMOUNT_API_REF,
  DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF,
  DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
  DEFAULT_KEY_NAME_SALE_API_REF,
  DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF,
  DEFAULT_KEY_NAME_SORT_BY_API_REF,
  DEFAULT_KEY_NAME_TO_DATE_API_REF,
} from './object-keyname.default';
import {
  DEFAULT_ACTION_FROM_TO_DATE_PARAMS,
  DEFAULT_CHART_PURCHASE_INVOICE_LIST_PARAMS,
  DEFAULT_CHART_PURCHASE_INVOICE_LIST_SEARCH_PARAMS,
  DEFAULT_CHART_SALES_INVOICE_LIST_PARAMS,
  DEFAULT_CHART_SALES_INVOICE_LIST_SEARCH_PARAMS,
  DEFAULT_CHART_TOP_SALES_LIST_PARAMS,
  DEFAULT_CHART_TOP_SALES_LIST_SEARCH_PARAMS,
  DEFAULT_DATE_ONLY_PARAMS,
  DEFAULT_DATE_ONLY_SEARCH_PARAMS,
  DEFAULT_FROM_TO_DATE_SEARCH_PARAMS,
  SECONDARY_DEFAULT_LIST_PARAMS,
} from './params.default';
import { permissionAPIwithFeatureActionComponentMap } from './permission.default';

export const chartProfessionalApplicationType = 'chart-professional-application';

export const defaultChartSalesTransactionDetails = {
  action: getSalesTransactionChartDetails,
  parentStateName: ROOT_STORE_NAME_CHART,
  childrenStateName: SLICE_NAME_CHART_SALES_TRANSACTION_DETAILS,
  usageRequestStatusParallel: true,
  requestStatusParallelKeyname: DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
  requestStatusPending: REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_PENDING,
  requestStatusFailed: REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_FAILED,
  requestStatusSuccess: REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_SUCCESS,
  searchAgainWhenChangesDate: false,
  ...permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
    CHART_SALES_TRANSACTION_TYPE
  ],
};

export const chartProfessionalSalesTransactionDetailsConfig = {
  [DEFAULT_KEY_NAME_MONTHLY_TRANSACTION_API_REF]: {
    header: 'header.this-month',
    subtitleKeyname: DEFAULT_KEY_NAME_CURRENT_DATE_API_REF,
    formatDateSubtitle: DEFAULT_FORMAT_READABLE_MONTH_YEAR,
    contentKeyname: DEFAULT_KEY_NAME_CURRENT_MONTH_AMOUNT_API_REF,
    usageReadableDateOnSubtitle: true,
    usageFooter: true,
    usageDifferentPercentage: true,
    differentPercentage: {
      baseKeyname: DEFAULT_KEY_NAME_PREVIOUS_MONTH_AMOUNT_API_REF,
      comparisonKeyname: DEFAULT_KEY_NAME_CURRENT_MONTH_AMOUNT_API_REF,
    },
    footer: 'typography.vs-last-month',
    additionalPaperStyleProps: {
      borderColor: blue[700],
    },
    ...defaultChartSalesTransactionDetails,
  },
  [DEFAULT_KEY_NAME_DAILY_TRANSACTION_API_REF]: {
    header: 'header.today',
    subtitleKeyname: DEFAULT_KEY_NAME_CURRENT_DATE_API_REF,
    formatDateSubtitle: DEFAULT_FORMAT_READABLE_DATE_MONTH_YEAR,
    contentKeyname: DEFAULT_KEY_NAME_CURRENT_DATE_AMOUNT_API_REF,
    usageReadableDateOnSubtitle: true,
    usageFooter: true,
    usageDifferentPercentage: true,
    differentPercentage: {
      baseKeyname: DEFAULT_KEY_NAME_PREVIOUS_DATE_AMOUNT_API_REF,
      comparisonKeyname: DEFAULT_KEY_NAME_CURRENT_DATE_AMOUNT_API_REF,
    },
    footer: 'typography.vs-yesterday',
    additionalPaperStyleProps: {
      borderColor: '#3FB8AC',
    },
    ...defaultChartSalesTransactionDetails,
  },
  [DEFAULT_KEY_NAME_BEST_MONTH_API_REF]: {
    header: 'header.best-month',
    subtitleKeyname: DEFAULT_KEY_NAME_CURRENT_DATE_API_REF,
    formatDateSubtitle: DEFAULT_FORMAT_READABLE_MONTH_YEAR,
    contentKeyname: DEFAULT_KEY_NAME_AMOUNT_REF_API,
    usageReadableDateOnSubtitle: true,
    usageFooter: false,
    usageDifferentPercentage: false,
    additionalPaperStyleProps: {
      borderColor: '#E28652',
    },
    ...defaultChartSalesTransactionDetails,
  },
  [DEFAULT_KEY_NAME_BEST_DATE_API_REF]: {
    header: 'header.best-date',
    subtitleKeyname: DEFAULT_KEY_NAME_CURRENT_DATE_API_REF,
    formatDateSubtitle: DEFAULT_FORMAT_READABLE_DATE_MONTH_YEAR,
    contentKeyname: DEFAULT_KEY_NAME_AMOUNT_REF_API,
    usageReadableDateOnSubtitle: true,
    usageFooter: false,
    usageDifferentPercentage: false,
    additionalPaperStyleProps: {
      borderColor: '#AE6E80',
    },
    ...defaultChartSalesTransactionDetails,
  },
};

export const chartProfessionalIncomeStatementDetailsActionUtils = {
  action: getIncomeStatementChartDetails,
  parentStateName: ROOT_STORE_NAME_CHART,
  childrenStateName: SLICE_NAME_CHART_INCOME_STATEMENT_DETAILS,
  usageRequestStatusParallel: true,
  requestStatusParallelKeyname: DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
  requestStatusPending: REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_PENDING,
  requestStatusFailed: REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_FAILED,
  requestStatusSuccess: REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_SUCCESS,
  initialSearchParams: {
    isShowMessage: false,
    from_date: moment(getRangeDateThisMonth().startDate).toISOString(),
    to_date: moment(getRangeDateThisMonth().endDate).toISOString(),
  },
  searchAgainWhenChangesDate: true,
  ...permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
    CHART_INCOME_STATEMENT_TYPE
  ],
};

export const chartProfessionalIncomeStatementDetailsComponentUtils = {
  [DEFAULT_KEY_NAME_INCOME_API_REF]: {
    header: 'header.loss-profit',
    contentKeyname: DEFAULT_KEY_NAME_INCOME_STATEMENT_API_REF,
    endIcon: ProfitLoss,
    additionalPaperStyleProps: {
      borderColor: blue[700],
    },
    ...chartProfessionalIncomeStatementDetailsActionUtils,
  },
  [DEFAULT_KEY_NAME_SALE_API_REF]: {
    header: 'header.sale',
    contentKeyname: 'value',
    endIcon: Sale,
    additionalPaperStyleProps: {
      borderColor: '#3FB8AC',
    },
    ...chartProfessionalIncomeStatementDetailsActionUtils,
    action: getChartBasicDashboardDetails,
    parentStateName: ROOT_STORE_NAME_CHART,
    childrenStateName: SLICE_NAME_CHART_BASIC_DASHBOARD_SALES,
    usageRequestStatusParallel: true,
    requestStatusParallelKeyname: DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
    requestStatusPending: REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_PENDING,
    requestStatusFailed: REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_SUCCESS,
    generalizeSearchParams: (currentSearchParams) => {
      return {
        params: currentSearchParams,
        chartType: CHART_BASIC_DASHBOARD_TYPE_SALES,
      };
    },
  },
  [DEFAULT_KEY_NAME_EXPENSE_API_REF]: {
    header: 'header.expense',
    contentKeyname: 'total',
    endIcon: Expense,
    additionalPaperStyleProps: {
      borderColor: '#E28652',
    },
    ...chartProfessionalIncomeStatementDetailsActionUtils,
    action: getChartBasicDashboardDetails,
    parentStateName: ROOT_STORE_NAME_CHART,
    childrenStateName: SLICE_NAME_CHART_EXPENSE,
    usageRequestStatusParallel: true,
    requestStatusParallelKeyname: DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
    requestStatusPending: REQUEST_STATUS_CHART_DASHBOARD_EXPENSE_PENDING,
    requestStatusFailed: REQUEST_STATUS_CHART_DASHBOARD_EXPENSE_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_DASHBOARD_EXPENSE_SUCCESS,
    generalizeSearchParams: (currentSearchParams) => {
      return {
        params: currentSearchParams,
        chartType: CHART_DASHBOARD_TYPE_EXPENSE,
      };
    },
  },
};

// request action for details where contained action, that triggering on dispatch
export const chartProfessionalRequestAction = [
  defaultChartSalesTransactionDetails,
  chartProfessionalIncomeStatementDetailsActionUtils,
  chartProfessionalIncomeStatementDetailsComponentUtils[DEFAULT_KEY_NAME_SALE_API_REF],
  chartProfessionalIncomeStatementDetailsComponentUtils[DEFAULT_KEY_NAME_EXPENSE_API_REF],
];

export const chartProfessionalDashboardListActionUtils = {
  [CHART_PRO_DASHBOARD_SALES_INVOICE_TYPE]: {
    url: REACT_APP_API_URL_CHART_SALES_INVOICE,
    parentStateName: ROOT_STORE_NAME_CHART,
    sliceName: SLICE_NAME_CHART_BASIC_DASHBOARD_SALES_INVOICE,
    childrenStateName: SLICE_NAME_CHART_BASIC_DASHBOARD_SALES_INVOICE,
    localStorageName: LOCAL_STORAGE_CHART_SALES_INVOICE_BASIC_LIST,
    usageRequestStatusParallel: true,
    requestStatusParallelKeyname: DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
    requestStatusPending: REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_INVOICE_PENDING,
    requestStatusError: REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_INVOICE_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_INVOICE_SUCCESS,
    allowParams: DEFAULT_CHART_SALES_INVOICE_LIST_PARAMS,
    defaultParams: SECONDARY_DEFAULT_LIST_PARAMS,
    uniqueIdentityID: DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF,
    searchParams: DEFAULT_CHART_SALES_INVOICE_LIST_SEARCH_PARAMS,
    initialSearchParams: {
      ...SECONDARY_DEFAULT_LIST_PARAMS,
      chartType: CHART_PRO_DASHBOARD_SALES_INVOICE_TYPE,
      chartUtilsType: chartProfessionalApplicationType,
      isShowMessage: false,
      from_date: moment(getRangeDateThisMonth().startDate).toISOString(),
      to_date: moment(getRangeDateThisMonth().endDate).toISOString(),
      status: [],
    },
    sortSearchParams: DEFAULT_KEY_NAME_SORT_BY_API_REF,
    defaultKeyStartDate: DEFAULT_KEY_NAME_FROM_DATE_API_REF,
    defaultKeyEndDate: DEFAULT_KEY_NAME_TO_DATE_API_REF,
    ...permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
      CHART_PRO_DASHBOARD_SALES_INVOICE_TYPE
    ],
  },
  [CHART_PRO_DASHBOARD_PURCHASE_INVOICE_TYPE]: {
    url: REACT_APP_API_URL_CHART_PURCHASE_INVOICE,
    parentStateName: ROOT_STORE_NAME_CHART,
    sliceName: SLICE_NAME_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE,
    childrenStateName: SLICE_NAME_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE,
    localStorageName: LOCAL_STORAGE_CHART_PURCHASE_INVOICE_BASIC_LIST,
    usageRequestStatusParallel: true,
    requestStatusParallelKeyname: DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
    requestStatusPending: REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE_PENDING,
    requestStatusError: REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE_SUCCESS,
    allowParams: DEFAULT_CHART_PURCHASE_INVOICE_LIST_PARAMS,
    defaultParams: SECONDARY_DEFAULT_LIST_PARAMS,
    uniqueIdentityID: DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF,
    searchParams: DEFAULT_CHART_PURCHASE_INVOICE_LIST_SEARCH_PARAMS,
    initialSearchParams: {
      ...SECONDARY_DEFAULT_LIST_PARAMS,
      chartType: CHART_PRO_DASHBOARD_PURCHASE_INVOICE_TYPE,
      chartUtilsType: chartProfessionalApplicationType,
      isShowMessage: false,
      from_date: moment(getRangeDateThisMonth().startDate).toISOString(),
      to_date: moment(getRangeDateThisMonth().endDate).toISOString(),
      status: [],
    },
    sortSearchParams: DEFAULT_KEY_NAME_SORT_BY_API_REF,
    defaultKeyStartDate: DEFAULT_KEY_NAME_FROM_DATE_API_REF,
    defaultKeyEndDate: DEFAULT_KEY_NAME_TO_DATE_API_REF,
    ...permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
      CHART_PRO_DASHBOARD_PURCHASE_INVOICE_TYPE
    ],
  },
  [CHART_PRO_DASHBOARD_TOP_SALES_SALES_AGENT_TYPE]: {
    url: REACT_APP_API_URL_CHART_TOP_SALES_SALES_AGENT,
    parentStateName: ROOT_STORE_NAME_CHART,
    sliceName: SLICE_NAME_CHART_TOP_SALES_SALES_AGENT,
    childrenStateName: SLICE_NAME_CHART_TOP_SALES_SALES_AGENT,
    localStorageName: LOCAL_STORAGE_CHART_TOP_SALES_SALES_AGENT_LIST,
    usageRequestStatusParallel: true,
    requestStatusParallelKeyname: DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
    requestStatusPending: REQUEST_STATUS_CHART_LIST_TOP_SALES_SALES_AGENT_PENDING,
    requestStatusError: REQUEST_STATUS_CHART_LIST_TOP_SALES_SALES_AGENT_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_LIST_TOP_SALES_SALES_AGENT_SUCCESS,
    allowParams: DEFAULT_CHART_TOP_SALES_LIST_PARAMS,
    defaultParams: SECONDARY_DEFAULT_LIST_PARAMS,
    uniqueIdentityID: DEFAULT_KEY_NAME_NAME_API_REF,
    searchParams: DEFAULT_CHART_TOP_SALES_LIST_SEARCH_PARAMS,
    initialSearchParams: {
      ...SECONDARY_DEFAULT_LIST_PARAMS,
      isShowMessage: false,
      chartUtilsType: chartProfessionalApplicationType,
      from_date: moment(getRangeDateThisMonth().startDate).toISOString(),
      to_date: moment(getRangeDateThisMonth().endDate).toISOString(),
    },
    sortSearchParams: DEFAULT_KEY_NAME_SORT_BY_API_REF,
    defaultKeyStartDate: DEFAULT_KEY_NAME_FROM_DATE_API_REF,
    defaultKeyEndDate: DEFAULT_KEY_NAME_TO_DATE_API_REF,
  },
  [CHART_PRO_DASHBOARD_SALES_COMPARISON]: {
    url: REACT_APP_API_URL_CHART_COMPARISON_SALES,
    parentStateName: ROOT_STORE_NAME_CHART,
    sliceName: SLICE_NAME_CHART_COMPARISON_SALES,
    childrenStateName: SLICE_NAME_CHART_COMPARISON_SALES,
    localStorageName: LOCAL_STORAGE_CHART_COMPARISON_SALES_LIST,
    usageRequestStatusParallel: true,
    requestStatusParallelKeyname: DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
    requestStatusPending: REQUEST_STATUS_CHART_LIST_COMPARISON_SALES_PENDING,
    requestStatusError: REQUEST_STATUS_CHART_LIST_COMPARISON_SALES_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_LIST_COMPARISON_SALES_SUCCESS,
    allowParams: DEFAULT_ACTION_FROM_TO_DATE_PARAMS,
    defaultParams: [],
    uniqueIdentityID: DEFAULT_KEY_NAME_NAME_API_REF,
    searchParams: DEFAULT_FROM_TO_DATE_SEARCH_PARAMS,
    searchAgainWhenChangesDate: true,
    initialSearchParams: {
      isShowMessage: false,
      chartType: CHART_PRO_DASHBOARD_SALES_COMPARISON,
      chartUtilsType: chartProfessionalApplicationType,
      from_date: moment(getRangeDateThisMonth().startDate).toISOString(true),
      to_date: moment(getRangeDateThisMonth().endDate).toISOString(true),
      showLineData: [
        comparisonSalesProfitType,
        comparisonSalesSalesType,
        comparisonSalesExpenseType,
      ],
    },
    sortSearchParams: DEFAULT_KEY_NAME_SORT_BY_API_REF,
    defaultKeyStartDate: DEFAULT_KEY_NAME_FROM_DATE_API_REF,
    defaultKeyEndDate: DEFAULT_KEY_NAME_TO_DATE_API_REF,
    ...permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
      CHART_PRO_DASHBOARD_SALES_COMPARISON
    ],
  },
  [CHART_PRO_DASHBOARD_TOP_SALES_CUSTOMER_TYPE]: {
    url: REACT_APP_API_URL_CHART_TOP_SALES_CUSTOMER,
    parentStateName: ROOT_STORE_NAME_CHART,
    sliceName: SLICE_NAME_CHART_TOP_SALES_CUSTOMER,
    childrenStateName: SLICE_NAME_CHART_TOP_SALES_CUSTOMER,
    localStorageName: LOCAL_STORAGE_CHART_TOP_SALES_CUSTOMER_LIST,
    usageRequestStatusParallel: true,
    requestStatusParallelKeyname: DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
    requestStatusPending: REQUEST_STATUS_CHART_LIST_TOP_SALES_CUSTOMER_PENDING,
    requestStatusError: REQUEST_STATUS_CHART_LIST_TOP_SALES_CUSTOMER_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_LIST_TOP_SALES_CUSTOMER_SUCCESS,
    allowParams: DEFAULT_CHART_TOP_SALES_LIST_PARAMS,
    defaultParams: SECONDARY_DEFAULT_LIST_PARAMS,
    uniqueIdentityID: DEFAULT_KEY_NAME_NAME_API_REF,
    searchParams: DEFAULT_CHART_TOP_SALES_LIST_SEARCH_PARAMS,
    initialSearchParams: {
      ...SECONDARY_DEFAULT_LIST_PARAMS,
      isShowMessage: false,
      chartUtilsType: chartProfessionalApplicationType,
      from_date: moment(getRangeDateThisMonth().startDate).toISOString(),
      to_date: moment(getRangeDateThisMonth().endDate).toISOString(),
    },
    sortSearchParams: DEFAULT_KEY_NAME_SORT_BY_API_REF,
    defaultKeyStartDate: DEFAULT_KEY_NAME_FROM_DATE_API_REF,
    defaultKeyEndDate: DEFAULT_KEY_NAME_TO_DATE_API_REF,
  },
  [CHART_PRO_DASHBOARD_TOP_SALES_PRODUCT_TYPE]: {
    url: REACT_APP_API_URL_CHART_TOP_SALES_PRODUCT,
    parentStateName: ROOT_STORE_NAME_CHART,
    sliceName: SLICE_NAME_CHART_TOP_SALES_PRODUCT,
    childrenStateName: SLICE_NAME_CHART_TOP_SALES_PRODUCT,
    localStorageName: LOCAL_STORAGE_CHART_TOP_SALES_PRODUCT_LIST,
    usageRequestStatusParallel: true,
    requestStatusParallelKeyname: DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
    requestStatusPending: REQUEST_STATUS_CHART_LIST_TOP_SALES_PRODUCT_PENDING,
    requestStatusError: REQUEST_STATUS_CHART_LIST_TOP_SALES_PRODUCT_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_LIST_TOP_SALES_PRODUCT_SUCCESS,
    allowParams: DEFAULT_CHART_TOP_SALES_LIST_PARAMS,
    defaultParams: SECONDARY_DEFAULT_LIST_PARAMS,
    uniqueIdentityID: DEFAULT_KEY_NAME_NAME_API_REF,
    searchParams: DEFAULT_CHART_TOP_SALES_LIST_SEARCH_PARAMS,
    initialSearchParams: {
      ...SECONDARY_DEFAULT_LIST_PARAMS,
      isShowMessage: false,
      chartUtilsType: chartProfessionalApplicationType,
      from_date: moment(getRangeDateThisMonth().startDate).toISOString(),
      to_date: moment(getRangeDateThisMonth().endDate).toISOString(),
    },
    sortSearchParams: DEFAULT_KEY_NAME_SORT_BY_API_REF,
    defaultKeyStartDate: DEFAULT_KEY_NAME_FROM_DATE_API_REF,
    defaultKeyEndDate: DEFAULT_KEY_NAME_TO_DATE_API_REF,
  },
  [CHART_PRO_DASHBOARD_TOP_SALES_PRODUCT_CATEGORY_TYPE]: {
    url: REACT_APP_API_URL_CHART_TOP_SALES_PRODUCT_CATEGORY,
    parentStateName: ROOT_STORE_NAME_CHART,
    sliceName: SLICE_NAME_CHART_TOP_SALES_PRODUCT_CATEGORY,
    childrenStateName: SLICE_NAME_CHART_TOP_SALES_PRODUCT_CATEGORY,
    localStorageName: LOCAL_STORAGE_CHART_TOP_SALES_PRODUCT_CATEGORY_LIST,
    usageRequestStatusParallel: true,
    requestStatusParallelKeyname: DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
    requestStatusPending: REQUEST_STATUS_CHART_LIST_TOP_SALES_PRODUCT_CATEGORY_SUCCESS,
    requestStatusError: REQUEST_STATUS_CHART_LIST_TOP_SALES_PRODUCT_CATEGORY_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_LIST_TOP_SALES_PRODUCT_CATEGORY_PENDING,
    allowParams: DEFAULT_CHART_TOP_SALES_LIST_PARAMS,
    defaultParams: SECONDARY_DEFAULT_LIST_PARAMS,
    uniqueIdentityID: DEFAULT_KEY_NAME_NAME_API_REF,
    searchParams: DEFAULT_CHART_TOP_SALES_LIST_SEARCH_PARAMS,
    initialSearchParams: {
      ...SECONDARY_DEFAULT_LIST_PARAMS,
      isShowMessage: false,
      chartUtilsType: chartProfessionalApplicationType,
      from_date: moment(getRangeDateThisMonth().startDate).toISOString(),
      to_date: moment(getRangeDateThisMonth().endDate).toISOString(),
    },
    sortSearchParams: DEFAULT_KEY_NAME_SORT_BY_API_REF,
    defaultKeyStartDate: DEFAULT_KEY_NAME_FROM_DATE_API_REF,
    defaultKeyEndDate: DEFAULT_KEY_NAME_TO_DATE_API_REF,
  },
  [CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_SALES_AGENT_TYPE]: {
    url: REACT_APP_API_URL_CHART_TOP_SALES_COMPARISON_SALES_AGENT,
    parentStateName: ROOT_STORE_NAME_CHART,
    sliceName: SLICE_NAME_CHART_TOP_SALES_COMPARISON_SALES_AGENT,
    childrenStateName: SLICE_NAME_CHART_TOP_SALES_COMPARISON_SALES_AGENT,
    localStorageName: LOCAL_STORAGE_CHART_TOP_SALES_COMPARISON_SALES_AGENT_LIST,
    usageRequestStatusParallel: true,
    requestStatusParallelKeyname: DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
    requestStatusPending: REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_SALES_AGENT_PENDING,
    requestStatusError: REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_SALES_AGENT_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_SALES_AGENT_SUCCESS,
    allowParams: DEFAULT_DATE_ONLY_PARAMS,
    usageChangesSearchParamsFromGlobalDashboard: true,
    correspondChangesKeynameWithGlobalDashboard: {
      from_date: 'date',
    },
    defaultParams: [],
    uniqueIdentityID: DEFAULT_KEY_NAME_NAME_API_REF,
    searchParams: DEFAULT_DATE_ONLY_SEARCH_PARAMS,
    initialSearchParams: {
      isShowMessage: false,
      chartUtilsType: chartProfessionalApplicationType,
      [DEFAULT_KEY_NAME_DATE_API_REF]: moment(getRangeDateThisMonth().startDate).toISOString(),
    },
    sortSearchParams: DEFAULT_KEY_NAME_SORT_BY_API_REF,
    defaultKeyStartDate: DEFAULT_KEY_NAME_DATE_API_REF,
    defaultKeyEndDate: '',
  },
  [CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_CUSTOMER_TYPE]: {
    url: REACT_APP_API_URL_CHART_TOP_SALES_COMPARISON_CUSTOMER,
    parentStateName: ROOT_STORE_NAME_CHART,
    sliceName: SLICE_NAME_CHART_TOP_SALES_COMPARISON_CUSTOMER,
    childrenStateName: SLICE_NAME_CHART_TOP_SALES_COMPARISON_CUSTOMER,
    localStorageName: LOCAL_STORAGE_CHART_TOP_SALES_COMPARISON_CUSTOMER_LIST,
    usageRequestStatusParallel: true,
    requestStatusParallelKeyname: DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
    requestStatusPending: REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_CUSTOMER_SUCCESS,
    requestStatusError: REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_CUSTOMER_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_CUSTOMER_PENDING,
    allowParams: DEFAULT_DATE_ONLY_PARAMS,
    usageChangesSearchParamsFromGlobalDashboard: true,
    correspondChangesKeynameWithGlobalDashboard: {
      from_date: 'date',
    },
    defaultParams: [],
    uniqueIdentityID: DEFAULT_KEY_NAME_NAME_API_REF,
    searchParams: DEFAULT_DATE_ONLY_SEARCH_PARAMS,
    initialSearchParams: {
      isShowMessage: false,
      chartUtilsType: chartProfessionalApplicationType,
      [DEFAULT_KEY_NAME_DATE_API_REF]: moment(getRangeDateThisMonth().startDate).toISOString(),
    },
    sortSearchParams: DEFAULT_KEY_NAME_SORT_BY_API_REF,
    defaultKeyStartDate: DEFAULT_KEY_NAME_DATE_API_REF,
    defaultKeyEndDate: '',
  },
  [CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_PRODUCT_TYPE]: {
    url: REACT_APP_API_URL_CHART_TOP_SALES_COMPARISON_PRODUCT,
    parentStateName: ROOT_STORE_NAME_CHART,
    sliceName: SLICE_NAME_CHART_TOP_SALES_COMPARISON_PRODUCT,
    childrenStateName: SLICE_NAME_CHART_TOP_SALES_COMPARISON_PRODUCT,
    localStorageName: LOCAL_STORAGE_CHART_TOP_SALES_COMPARISON_PRODUCT_LIST,
    usageRequestStatusParallel: true,
    requestStatusParallelKeyname: DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
    requestStatusPending: REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_PRODUCT_PENDING,
    requestStatusError: REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_PRODUCT_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_PRODUCT_SUCCESS,
    allowParams: DEFAULT_DATE_ONLY_PARAMS,
    usageChangesSearchParamsFromGlobalDashboard: true,
    correspondChangesKeynameWithGlobalDashboard: {
      from_date: 'date',
    },
    defaultParams: [],
    uniqueIdentityID: DEFAULT_KEY_NAME_NAME_API_REF,
    searchParams: DEFAULT_DATE_ONLY_SEARCH_PARAMS,
    initialSearchParams: {
      isShowMessage: false,
      chartUtilsType: chartProfessionalApplicationType,
      [DEFAULT_KEY_NAME_DATE_API_REF]: moment(getRangeDateThisMonth().startDate).toISOString(),
    },
    sortSearchParams: DEFAULT_KEY_NAME_SORT_BY_API_REF,
    defaultKeyStartDate: DEFAULT_KEY_NAME_DATE_API_REF,
    defaultKeyEndDate: '',
  },
  [CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_PRODUCT_CATEGORY_TYPE]: {
    url: REACT_APP_API_URL_CHART_TOP_SALES_COMPARISON_PRODUCT_CATEGORY,
    parentStateName: ROOT_STORE_NAME_CHART,
    sliceName: SLICE_NAME_CHART_TOP_SALES_COMPARISON_PRODUCT_CATEGORY,
    childrenStateName: SLICE_NAME_CHART_TOP_SALES_COMPARISON_PRODUCT_CATEGORY,
    localStorageName: LOCAL_STORAGE_CHART_TOP_SALES_COMPARISON_PRODUCT_CATEGORY_LIST,
    usageRequestStatusParallel: true,
    requestStatusParallelKeyname: DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
    requestStatusPending: REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_PRODUCT_CATEGORY_PENDING,
    requestStatusError: REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_PRODUCT_CATEGORY_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_PRODUCT_CATEGORY_SUCCESS,
    allowParams: DEFAULT_DATE_ONLY_PARAMS,
    usageChangesSearchParamsFromGlobalDashboard: true,
    correspondChangesKeynameWithGlobalDashboard: {
      from_date: 'date',
    },
    defaultParams: [],
    uniqueIdentityID: DEFAULT_KEY_NAME_NAME_API_REF,
    searchParams: DEFAULT_DATE_ONLY_SEARCH_PARAMS,
    initialSearchParams: {
      isShowMessage: false,
      chartUtilsType: chartProfessionalApplicationType,
      [DEFAULT_KEY_NAME_DATE_API_REF]: moment(getRangeDateThisMonth().startDate).toISOString(),
    },
    sortSearchParams: DEFAULT_KEY_NAME_SORT_BY_API_REF,
    defaultKeyStartDate: DEFAULT_KEY_NAME_DATE_API_REF,
    defaultKeyEndDate: '',
  },
  [CHART_PRO_DASHBOARD_PIVOT_TABLE_TYPE]: {
    url: REACT_APP_API_URL_CHART_PIVOT_TABLE,
    parentStateName: ROOT_STORE_NAME_CHART,
    sliceName: SLICE_NAME_CHART_PIVOT_TABLE,
    childrenStateName: SLICE_NAME_CHART_PIVOT_TABLE,
    localStorageName: LOCAL_STORAGE_CHART_PIVOT_TABLE_LIST,
    usageRequestStatusParallel: true,
    requestStatusParallelKeyname: DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
    requestStatusPending: REQUEST_STATUS_CHART_PIVOT_TABLE_PENDING,
    requestStatusError: REQUEST_STATUS_CHART_PIVOT_TABLE_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_PIVOT_TABLE_SUCCESS,
    searchAgainWhenChangesDate: true,
    allowParams: DEFAULT_ACTION_FROM_TO_DATE_PARAMS,
    defaultParams: SECONDARY_DEFAULT_LIST_PARAMS,
    uniqueIdentityID: DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF,
    searchParams: DEFAULT_FROM_TO_DATE_SEARCH_PARAMS,
    initialSearchParams: {
      ...SECONDARY_DEFAULT_LIST_PARAMS,
      chartType: CHART_PRO_DASHBOARD_PIVOT_TABLE_TYPE,
      chartUtilsType: chartProfessionalApplicationType,
      isShowMessage: false,
      from_date: moment(getRangeDateThisMonth().startDate).toISOString(),
      to_date: moment(getRangeDateThisMonth().endDate).toISOString(),
    },
    sortSearchParams: DEFAULT_KEY_NAME_SORT_BY_API_REF,
    defaultKeyStartDate: DEFAULT_KEY_NAME_FROM_DATE_API_REF,
    defaultKeyEndDate: DEFAULT_KEY_NAME_TO_DATE_API_REF,
    ...permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
      CHART_PRO_DASHBOARD_PIVOT_TABLE_TYPE
    ],
  },
};

export function getChartProfessionalDashboardListActionUtils(
  chartType = CHART_PRO_DASHBOARD_SALES_INVOICE_TYPE,
) {
  if (!Object.keys(chartProfessionalDashboardListActionUtils).includes(chartType)) {
    chartType = CHART_PRO_DASHBOARD_SALES_INVOICE_TYPE;
  }

  return chartProfessionalDashboardListActionUtils[chartType];
}

// usageSubTypeUtils
// subTypeUtilsKeynameOnSearchParams
export const chartProfessionalDashboardListComponentUtils = {
  [CHART_PRO_DASHBOARD_TOP_SALES_VERTICAL_CHART_MAIN_TYPE]: {
    header: 'header.sales-by',
    usageAdditionalInitialSearchParams: false,
    usageOnActionWhenInitial: true,
    usageSubTypeUtils: true,
    subTypeUtilsKeynameOnSearchParams: 'chartType',
    additionalInitialSearchParams: {
      chartType: CHART_PRO_DASHBOARD_TOP_SALES_PRODUCT_TYPE,
      chartTypeContainer: chartTopSalesSubType[0],
    },
    columnsTable: columnsTableChartTopSalesDashboard,
    usageComparisonGetColumnsTable: true,
    // cause on top sales main not only contain one response API,
    // and comparisonColumnsTable is only when connected key with
    comparisonColumnsTable: {
      [CHART_PRO_DASHBOARD_TOP_SALES_PRODUCT_TYPE]: columnsTableChartTopSalesProductDashboard,
    },
    usageFilterDropdown: true,
    filterDropdownList: chartTopSalesSubType,
    keynameFilterDropdown: 'chartType',
    keynameFilterDropdownContainer: 'chartTypeContainer',
    usageFilterCheckboxWithLabel: false,
    representationDataType: 'chart',
    chartComponent: VerticalBarChartTopSalesDashboard,
    labelsNotFoundData: {
      [CHART_PRO_DASHBOARD_TOP_SALES_SALES_AGENT_TYPE]: 'label.sales-agent',
      [CHART_PRO_DASHBOARD_TOP_SALES_CUSTOMER_TYPE]: 'label.customer',
      [CHART_PRO_DASHBOARD_TOP_SALES_PRODUCT_TYPE]: 'label.product',
      [CHART_PRO_DASHBOARD_TOP_SALES_PRODUCT_CATEGORY_TYPE]: 'label.product-category',
    },
    chartLegendLabel: 'header.top',
    chartYaxisKeyname: 'case',
    chartYaxisTooltipKeyname: 'caseTooltip',
    otherChartYaxisKeyname: {
      [CHART_PRO_DASHBOARD_TOP_SALES_PRODUCT_TYPE]: 'product_name',
    },
    generalizeChartList: (chartList, chartYaxisKeyname) => {
      if (!Array.isArray(chartList) || isEmpty(chartList)) return chartList;

      return chartList.map((singleChart) => {
        let caseChartYaxisValue =
          chartYaxisKeyname !== undefined ? singleChart[chartYaxisKeyname] : singleChart.case;
        if (
          caseChartYaxisValue &&
          caseChartYaxisValue.length > DEFAULT_DATA_MAX_LENGTH_SPLITTED_CHART_Y_AXIS_VALUE
        ) {
          if (
            !caseChartYaxisValue.slice(0, DEFAULT_DATA_MAX_LENGTH_CHART_Y_AXIS_VALUE).includes(' ')
          ) {
            const slicedValue = [
              caseChartYaxisValue.slice(0, DEFAULT_DATA_MAX_LENGTH_SPLITTED_CHART_Y_AXIS_VALUE),
              caseChartYaxisValue.slice(DEFAULT_DATA_MAX_LENGTH_SPLITTED_CHART_Y_AXIS_VALUE),
            ];

            caseChartYaxisValue = slicedValue.join(' ');
          }

          caseChartYaxisValue = strHelp.limitExcededStr(
            caseChartYaxisValue,
            DEFAULT_DATA_MAX_LENGTH_CHART_Y_AXIS_VALUE,
          );
        }

        return {
          ...singleChart,
          [chartYaxisKeyname]: caseChartYaxisValue,
          case: caseChartYaxisValue,
          caseTooltip: singleChart.case || singleChart.product_name,
        };
      });
    },
    permissionActionMap:
      permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
        CHART_PRO_DASHBOARD_TOP_SALES_VERTICAL_CHART_MAIN_TYPE
      ],
    ...permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
      CHART_PRO_DASHBOARD_TOP_SALES_VERTICAL_CHART_MAIN_TYPE
    ],
    fillChartBars: ['#20639B', '#3CAEA3', '#F6D55C', '#ED553B', '#833294'],
  },
  [CHART_PRO_DASHBOARD_SALES_COMPARISON]: {
    header: 'header.sales-chart',
    usageAdditionalInitialSearchParams: false,
    usageOnActionWhenInitial: false,
    usageSubTypeUtils: false,
    subTypeUtilsKeynameOnSearchParams: 'chartType',
    usageFilterDropdown: false,
    usageFilterCheckboxWithLabel: true,
    representationDataType: 'chart',
    chartComponent: LineChartSalesComparisonDashboard,
    keyNameCheckboxWithLabel: 'showLineData',
    checkboxWithLabelList: (t) => comparisonSalesChartTypeWithCheckboxVariant(t),
    limitCheckboxValue: 1,
    overrideSliceValueWhenChangeCheckbox: true,
    permissionActionMap:
      permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
        CHART_PRO_DASHBOARD_SALES_COMPARISON
      ],
    ...permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
      CHART_PRO_DASHBOARD_SALES_COMPARISON
    ],
  },
  [CHART_PRO_DASHBOARD_PIVOT_TABLE_TYPE]: {
    header: '',
    gridProps: {
      xs: 12,
      overflow: 'auto',
    },
    usageAdditionalInitialSearchParams: false,
    usageOnActionWhenInitial: false,
    usageSubTypeUtils: false,
    subTypeUtilsKeynameOnSearchParams: 'chartType',
    usageFilterDropdown: false,
    usageFilterCheckboxWithLabel: false,
    representationDataType: 'pivotTable',
    limitCheckboxValue: 1,
    permissionActionMap:
      permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
        CHART_PRO_DASHBOARD_PIVOT_TABLE_TYPE
      ],
    ...permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
      CHART_PRO_DASHBOARD_PIVOT_TABLE_TYPE
    ],
  },
  [CHART_PRO_DASHBOARD_TOP_SALES_MAIN_TYPE]: {
    header: 'header.top',
    usageAdditionalInitialSearchParams: false,
    usageOnActionWhenInitial: true,
    usageSubTypeUtils: true,
    subTypeUtilsKeynameOnSearchParams: 'chartType',
    additionalInitialSearchParams: {
      chartType: CHART_PRO_DASHBOARD_TOP_SALES_SALES_AGENT_TYPE,
      chartTypeContainer: chartTopSalesSubType[1],
    },
    columnsTable: columnsTableChartTopSalesDashboard,
    usageComparisonGetColumnsTable: true,
    // cause on top sales main not only contain one response API,
    // and comparisonColumnsTable is only when connected key with
    comparisonColumnsTable: {
      [CHART_PRO_DASHBOARD_TOP_SALES_PRODUCT_TYPE]: columnsTableChartTopSalesProductDashboard,
    },
    usageFilterDropdown: true,
    filterDropdownList: chartTopSalesSubType,
    keynameFilterDropdown: 'chartType',
    keynameFilterDropdownContainer: 'chartTypeContainer',
    usageFilterCheckboxWithLabel: false,
    representationDataType: 'list',
    permissionActionMap:
      permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
        CHART_PRO_DASHBOARD_TOP_SALES_MAIN_TYPE
      ],
    ...permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
      CHART_PRO_DASHBOARD_TOP_SALES_MAIN_TYPE
    ],
  },
  [CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_MAIN_TYPE]: {
    header: 'header.progress',
    usageAdditionalInitialSearchParams: false,
    usageOnActionWhenInitial: true,
    usageSubTypeUtils: true,
    subTypeUtilsKeynameOnSearchParams: 'chartType',
    additionalInitialSearchParams: {
      chartType: CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_PRODUCT_TYPE,
      chartTypeContainer: chartTopSalesComparisonSubType[0],
    },
    columnsTable: columnsTableChartTopSalesComparisonDashboard,
    usageFilterDropdown: true,
    filterDropdownList: chartTopSalesComparisonSubType,
    keynameFilterDropdown: 'chartType',
    keynameFilterDropdownContainer: 'chartTypeContainer',
    usageFilterCheckboxWithLabel: false,
    keyNameCheckboxWithLabel: 'status',
    representationDataType: 'list',
    permissionActionMap:
      permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
        CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_MAIN_TYPE
      ],
    ...permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
      CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_MAIN_TYPE
    ],
  },
  [CHART_PRO_DASHBOARD_SALES_INVOICE_TYPE]: {
    header: 'header.sales-invoice',
    usageAdditionalInitialSearchParams: false,
    usageOnActionWhenInitial: false,
    usageSubTypeUtils: false,
    subTypeUtilsKeynameOnSearchParams: '',
    additionalInitialSearchParams: {},
    childrenStateName: CHART_PRO_DASHBOARD_SALES_INVOICE_TYPE,
    columnsTable: columnsTableChartSalesInvoiceDashboard,
    usageFilterDropdown: false,
    filterDropdownList: [],
    keynameFilterDropdown: '',
    keynameFilterDropdownContainer: '',
    usageFilterCheckboxWithLabel: true,
    keyNameCheckboxWithLabel: 'status',
    representationDataType: 'list',
    checkboxWithLabelList: (t) => salesInvoiceChartBasicStatusWithCheckboxVariant(t),
    usageClickEntireTableRow: true,
    additionalPropsForTableRowPrimary: {
      usageDoubleClickEntireRow: true,
    },
    actionTypeWhenClickedEntireTableRow: 'navigate',
    generateNavigateLink: (datum) =>
      strHelp.templateString(REACT_APP_SALES_SALES_INVOICE_DETAILS_TEMPLATE_URL, datum),
    permissionActionMap:
      permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
        CHART_PRO_DASHBOARD_SALES_INVOICE_TYPE
      ],
    ...permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
      CHART_PRO_DASHBOARD_SALES_INVOICE_TYPE
    ],
  },
  [CHART_PRO_DASHBOARD_PURCHASE_INVOICE_TYPE]: {
    header: 'header.purchase-invoice',
    usageAdditionalInitialSearchParams: false,
    usageOnActionWhenInitial: false,
    usageSubTypeUtils: false,
    subTypeUtilsKeynameOnSearchParams: '',
    additionalInitialSearchParams: {},
    childrenStateName: CHART_PRO_DASHBOARD_PURCHASE_INVOICE_TYPE,
    columnsTable: columnsTableChartPurchaseInvoiceDashboard,
    usageFilterDropdown: false,
    filterDropdownList: [],
    keynameFilterDropdown: '',
    keynameFilterDropdownContainer: '',
    usageFilterCheckboxWithLabel: true,
    keyNameCheckboxWithLabel: 'status',
    representationDataType: 'list',
    checkboxWithLabelList: (t) => purchaseInvoiceChartBasicStatusWithCheckboxVariant(t),
    usageClickEntireTableRow: true,
    additionalPropsForTableRowPrimary: {
      usageDoubleClickEntireRow: true,
    },
    actionTypeWhenClickedEntireTableRow: 'navigate',
    generateNavigateLink: (datum) =>
      strHelp.templateString(REACT_APP_PURCHASE_PURCHASE_INVOICE_DETAILS_TEMPLATE_URL, datum),
    permissionActionMap:
      permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
        CHART_PRO_DASHBOARD_PURCHASE_INVOICE_TYPE
      ],
    ...permissionAPIwithFeatureActionComponentMap[menuGroupDashboardProfessional][
      CHART_PRO_DASHBOARD_PURCHASE_INVOICE_TYPE
    ],
  },
};

// identify for action list
export const chartProfessionalListRequestAction = [
  CHART_PRO_DASHBOARD_SALES_INVOICE_TYPE,
  CHART_PRO_DASHBOARD_PURCHASE_INVOICE_TYPE,
  CHART_PRO_DASHBOARD_PIVOT_TABLE_TYPE,
  CHART_PRO_DASHBOARD_SALES_COMPARISON,
];
