import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import { DEFAULT_CALCULATION_NUMBER } from '../../default/number.default';
import dateHelpers from '../../helpers/date.helpers';
import validation from '../../helpers/validation.helpers';

const initialID = uuidV4();

export const plainPaymentDataWithoutID = {
  customer_id: null,
  customer_id_container: null,
  customer_name: '',
  customer_address: '',
  sales_payment_no: '',
  sales_payment_no_container: null,
  transaction_date: new Date(),
  account_id: null,
  account_id_container: null,
  expired_date: null,
  description: '',
  amount: DEFAULT_CALCULATION_NUMBER,
  uuid_attachment: null,
  attachment: [],
  is_new_customer: false,
  accounting_period: null,
  sales_agent_id: '',
  sales_agent_id_container: '',
  current_date: null,
  minDateInputRef: null,
  tag: [],
  tagContainer: [],
  tagNameInput: '',
};

export const initialPaymentData = (additionalID = '', newID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainPaymentDataWithoutID,
    transaction_date: moment(),
    current_date: new Date(),
  };
};

export const plainPaymentValidationWithoutID = {
  customer_id: {
    error: false,
    isValid: (value) => {
      return validation.notnull(value);
    },
  },
  sales_payment_no: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
  transaction_date: {
    error: false,
    isValid: (value, minDateInputRef) => {
      if (minDateInputRef !== undefined) {
        return moment(new Date(value)).isSameOrAfter(new Date(minDateInputRef), 'D');
      }

      return validation.notnull(value) || dateHelpers.isValidDateV2(value);
    },
  },
  expired_date: {
    error: false,
    isValid: (value, transactionDate) => {
      if (transactionDate !== undefined && value) {
        return moment(new Date(value)).isAfter(new Date(transactionDate), 'D');
      }

      return validation.nullable(value) || dateHelpers.isValidDateV2(value);
    },
  },
  account_id: {
    error: false,
    isValid: (value) => {
      return validation.notnull(value);
    },
  },
  amount: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
};

export const initialPaymentValidation = (additionalID = '', newID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainPaymentValidationWithoutID,
  };
};

/**
 * amount is total bill
 * amount_available is just remaining bill for show on user
 * amount_available_const is just comparison for maximum amount_input, name was suffix by const is for determine cannot change variable
 * amount_input is just amount that inputted by user
 */
export const plainInvoiceDataWithoutID = {
  sales_invoice_id: null,
  sales_invoice_id_container: null,
  sales_invoice_no: null,
  expired_date: null,
  description: '',
  amount: '',
  amount_available: DEFAULT_CALCULATION_NUMBER,
  amount_available_const: DEFAULT_CALCULATION_NUMBER,
  amount_input: '',
  isFromDetailSalesPayment: false,
};

export const initialInvoiceData = (additionalID = '', newID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainInvoiceDataWithoutID,
  };
};

export const plainInvoiceValidationWithoutID = {
  sales_invoice_id: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
  sales_invoice_no: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
  transaction_date: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
  expired_date: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
  description: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
  amount: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
  amount_available: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
  amount_input: {
    error: false,
    isValid: (value) => validation.isNumberGreaterOrEqualThan(value, 1),
    // isValid: (value, amount_available_const) => {
    //     if(DEFAULT_EMPTY_CALCULATIONS_NUMBER.includes(value)) {
    //         return true
    //     }

    //     return Number(value) <= Number(amount_available_const)
    // }
  },
};

export const initialInvoiceValidation = (additionalID = '', newID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainInvoiceValidationWithoutID,
  };
};

export const allowKeysPaymentData = [
  'customer_id',
  'sales_payment_no',
  'transaction_date',
  'account_id',
  'expired_date',
  'description',
];

export const allowKeysInvoiceData = ['amount_input'];

export const allowKeysBodySalesPayment = [
  'customer_id',
  'customer_name',
  'customer_address',
  'sales_payment_no',
  'transaction_date',
  'account_id',
  'expired_date',
  'description',
  // 'amount',
  'invoice',
  'uuid_attachment',
  'tag',
];

export const allowKeysBodySalesPaymentOnInvoice = [
  'sales_invoice_id',
  // 'sales_invoice_no',
  // 'expired_date',
  'description',
  'amount',
  // 'amount_available',
];

export const salesPaymentCalculationNumberKeyNames = [];

export const invoiceSalesPaymentCalculationNumberKeyNames = ['amount', 'amount_available'];
