import { isEmpty } from 'lodash';

/**
 * determine checking empty value use bool
 *
 * @param {*} value target value
 * @returns
 */
function isEmptyValues(value) {
  if (typeof value === 'object') return isEmpty(value);

  if (!Number.isNaN(Number(value))) {
    return Boolean(Number(value)) === false;
  }

  return value === '' || value === undefined || value === null;
}

const boolHelp = {
  isEmptyValues,
};

export default boolHelp;
