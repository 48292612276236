import {
  REACT_APP_API_URL_ASSET_CATEGORY,
  REACT_APP_API_URL_ASSET_MANAGEMENT,
  REACT_APP_API_URL_ASSET_MANAGEMENT_REVERT,
  REACT_APP_API_URL_SALE_ASSET_MANAGEMENT,
} from '../../../utils/configs/api.config';
import {
  DEFAULT_ASSET_CATEGORY_LIST_PARAMS,
  DEFAULT_ASSET_MANAGEMENT_LIST_PARAMS,
} from '../../../utils/default/params.default';
import {
  creatorAddService,
  creatorDeleteService,
  creatorDetailService,
  creatorListService,
  creatorUpdateService,
  creatorUpdateServiceWithTemplateURL,
} from '../creator-services/creator-services.helper';

/**
 * asset category services
 *
 */

const getListAssetCategory = async (paramsGetListAssetCategory) => {
  return await creatorListService(
    REACT_APP_API_URL_ASSET_CATEGORY,
    paramsGetListAssetCategory,
    DEFAULT_ASSET_CATEGORY_LIST_PARAMS,
  );
};

const getDetailAssetCategory = async (assetCategoryID) => {
  return await creatorDetailService(REACT_APP_API_URL_ASSET_CATEGORY, assetCategoryID);
};

const addAssetCategory = async (bodyAssetCategory) => {
  return await creatorAddService(REACT_APP_API_URL_ASSET_CATEGORY, bodyAssetCategory);
};

const deleteAssetCategory = async (paramsDeleteAssetCategory) => {
  const { asset_category_id } = paramsDeleteAssetCategory;

  return await creatorDeleteService(asset_category_id, REACT_APP_API_URL_ASSET_CATEGORY);
};

const updateAssetCategory = async (bodyUpdateAssetCategory) => {
  const { asset_category_id } = bodyUpdateAssetCategory;

  return await creatorUpdateService(
    asset_category_id,
    REACT_APP_API_URL_ASSET_CATEGORY,
    bodyUpdateAssetCategory,
  );
};

/**
 *
 * asset management services
 *
 */

const getListAssetManagement = async (paramsGetListAssetManagement) => {
  return await creatorListService(
    REACT_APP_API_URL_ASSET_MANAGEMENT,
    paramsGetListAssetManagement,
    DEFAULT_ASSET_MANAGEMENT_LIST_PARAMS,
  );
};

const getDetailAssetManagement = async (assetManagementID) => {
  return await creatorDetailService(REACT_APP_API_URL_ASSET_MANAGEMENT, assetManagementID);
};

const deleteAssetManagement = async (paramsDeleteAssetManagement) => {
  const { asset_id } = paramsDeleteAssetManagement;

  return await creatorDeleteService(asset_id, REACT_APP_API_URL_ASSET_MANAGEMENT);
};

const addAssetManagement = async (bodyAssetManagement) => {
  return await creatorAddService(REACT_APP_API_URL_ASSET_MANAGEMENT, bodyAssetManagement);
};

const updateAssetManagement = async (bodyUpdateAssetManagement) => {
  const { asset_id } = bodyUpdateAssetManagement;

  return await creatorUpdateService(
    asset_id,
    REACT_APP_API_URL_ASSET_MANAGEMENT,
    bodyUpdateAssetManagement,
  );
};

const saleAssetManagement = async (bodySaleAssetManagement) => {
  return await creatorUpdateServiceWithTemplateURL(
    REACT_APP_API_URL_SALE_ASSET_MANAGEMENT,
    bodySaleAssetManagement,
  );
};

const revertAssetManagement = async (paramsRevertAssetManagement) => {
  return await creatorUpdateServiceWithTemplateURL(
    REACT_APP_API_URL_ASSET_MANAGEMENT_REVERT,
    paramsRevertAssetManagement,
    null,
  );
};

const assetServices = {
  getListAssetCategory,
  getDetailAssetCategory,
  addAssetCategory,
  updateAssetCategory,
  deleteAssetCategory,
  getListAssetManagement,
  getDetailAssetManagement,
  deleteAssetManagement,
  addAssetManagement,
  updateAssetManagement,
  saleAssetManagement,
  revertAssetManagement,
};

export default assetServices;
