import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_BANK_DATA } from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_LIST_BANK_DATA_FAILED,
  STATUS_REQUEST_LIST_BANK_DATA_PENDING,
  STATUS_REQUEST_LIST_BANK_DATA_SUCCESS,
} from '../../utils/constants/store.constant';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import { getListBank } from './bank.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  bankData: LocalStorage.get(LOCAL_STORAGE_BANK_DATA),
};

const bankSlice = createSlice({
  name: NAME_STORE.BANK_DATA,
  initialState,
  reducers: {
    getListBank,
    clearBankData: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_BANK_DATA);

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      bankData: LOCAL_STORAGE_BANK_DATA,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getListBank.fulfilled, (state, action) => {
      state.statusRequest = STATUS_REQUEST_LIST_BANK_DATA_SUCCESS;
      state.bankData = action.payload.bankData;
    }),
      builder.addCase(getListBank.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_BANK_DATA_FAILED;
      }),
      builder.addCase(getListBank.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_BANK_DATA_PENDING;
      });
  },
});

export const { reducer, actions } = bankSlice;

export const { clearBankData } = actions;

export default reducer;
