export const ACCOUNT_CATEGORY_NAME_COST_OF_GOODS_SOLD = 'Harga Pokok Penjualan';
export const ACCOUNT_CATEGORY_NAME_EXPENSE = 'Beban';
export const ACCOUNT_CATEGORY_NAME_FIXED_ASSET = 'Aktiva Tetap';
export const ACCOUNT_CATEGORY_NAME_DEPRECIATION_N_AMORTASI = 'Depresiasi & Amortisasi';

// there account category name with indonesian value and variable name
export const ACCOUNT_CATEGORY_NAME_AKTIVA_LAINNYA = 'Aktiva Lainnya';
export const ACCOUNT_CATEGORY_NAME_AKTIVA_LANCAR_LAINNYA = 'Aktiva Lancar Lainnya';
export const ACCOUNT_CATEGORY_NAME_AKTIVA_TETAP = 'Aktiva Tetap';
export const ACCOUNT_CATEGORY_NAME_AKUN_HUTANG = 'Akun Hutang';
export const ACCOUNT_CATEGORY_NAME_AKUN_HUTANG_PIUTANG = 'Akun Piutang';
export const ACCOUNT_CATEGORY_NAME_BEBAN = 'Beban';
export const ACCOUNT_CATEGORY_NAME_BEBAN_LAINNYA = 'Beban Lainnya';
export const ACCOUNT_CATEGORY_NAME_DEPRESIASI_N_AMORTISASI = 'Depresiasi & Amortisasi';
export const ACCOUNT_CATEGORY_NAME_EKUITAS = 'Ekuitas';
export const ACCOUNT_CATEGORY_NAME_HARGA_POKOK_PENJUALAN = 'Harga Pokok Penjualan';
export const ACCOUNT_CATEGORY_NAME_KARTU_KREDIT = 'Kartu Kredit';
export const ACCOUNT_CATEGORY_NAME_KAS_N_BANK = 'Kas & Bank';
export const ACCOUNT_CATEGORY_NAME_KEWAJIBAN_JANGKA_PANJANG = 'Kewajiban Jangka Panjang';
export const ACCOUNT_CATEGORY_NAME_KEWAJIBAN_LANCAR_LAINNYA = 'Kewajiban Lancar Lainnya';
export const ACCOUNT_CATEGORY_NAME_PENDAPATAN = 'Pendapatan';
export const ACCOUNT_CATEGORY_NAME_PENDAPATAN_LAINNYA = 'Pendapatan Lainnya';
export const ACCOUNT_CATEGORY_NAME_PERSEDIAAN = 'Persediaan';

// there account category name with each related with english variable and value is still indonesian
export const ACCOUNT_CATEGORY_NAME_OTHER_ASSETS = 'Aktiva Lainnya';
export const ACCOUNT_CATEGORY_NAME_OTHER_CURRENT_ASSETS = 'Aktiva Lancar Lainnya';
export const ACCOUNT_CATEGORY_NAME_FIXED_ASSETS = 'Aktiva Tetap';
export const ACCOUNT_CATEGORY_NAME_ACCOUNTS_PAYABLE_A_OR_P = 'Akun Hutang';
export const ACCOUNT_CATEGORY_NAME_ACCOUNTS_RECEIVABLE_A_OR_R = 'Akun Piutang';
export const ACCOUNT_CATEGORY_NAME_EXPENSES = 'Beban';
export const ACCOUNT_CATEGORY_NAME_OTHER_EXPENSE = 'Beban Lainnya';
export const ACCOUNT_CATEGORY_NAME_DEPRECIATION_N_AMORTIZATION = 'Depresiasi & Amortisasi';
export const ACCOUNT_CATEGORY_NAME_EQUITY = 'Ekuitas';
export const ACCOUNT_CATEGORY_NAME_COST_OF_SALES = 'Harga Pokok Penjualan';
export const ACCOUNT_CATEGORY_NAME_CREDIT_CARD = 'Kartu Kredit';
export const ACCOUNT_CATEGORY_NAME_CASH_N_BANK = 'Kas & Bank';
export const ACCOUNT_CATEGORY_NAME_LONG_TERM_LIABILITIES = 'Kewajiban Jangka Panjang';
export const ACCOUNT_CATEGORY_NAME_OTHER_CURRENT_LIABILITIES = 'Kewajiban Lancar Lainnya';
export const ACCOUNT_CATEGORY_NAME_INCOME = 'Pendapatan';
export const ACCOUNT_CATEGORY_NAME_OTHER_INCOME = 'Pendapatan Lainnya';
export const ACCOUNT_CATEGORY_NAME_INVENTORY = 'Persediaan';
