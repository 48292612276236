import {
  REACT_APP_API_URL_CLOSING_BOOK,
  REACT_APP_API_URL_CLOSING_BOOK_DETAILS,
  REACT_APP_API_URL_CLOSING_BOOK_PROCESS,
  REACT_APP_API_URL_CLOSING_BOOK_REVERT,
} from '../../../utils/configs/api.config';
import { DEFAULT_CLOSING_BOOK_LIST_PARAMS } from '../../../utils/default/params.default';
import strHelp from '../../../utils/helpers/string.helpers';
import {
  creatorAddService,
  creatorDeleteServiceWithTemplateUrl,
  creatorDetailServiceV2,
  creatorListService,
} from '../creator-services/creator-services.helper';

// closing book list service
const getClosingBookList = async (paramsGetClosingBookList) => {
  return await creatorListService(
    REACT_APP_API_URL_CLOSING_BOOK,
    paramsGetClosingBookList,
    DEFAULT_CLOSING_BOOK_LIST_PARAMS,
  );
};

// service revert closing book
const closingBookRevert = async (bodyClosingBookRevert) => {
  return await creatorDeleteServiceWithTemplateUrl(
    REACT_APP_API_URL_CLOSING_BOOK_REVERT,
    bodyClosingBookRevert,
  );
};

// service process closing book
const closingBookProcess = async (bodyClosingBookProcess) => {
  return await creatorAddService(REACT_APP_API_URL_CLOSING_BOOK, bodyClosingBookProcess, null);
};

// service closing book details
const getClosingBookDetails = async (paramsGetClosingBookDetails) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_CLOSING_BOOK_DETAILS,
    paramsGetClosingBookDetails,
  );
};

// service create closing book
const closingBookCreate = async (bodyClosingBookCreate) => {
  const urlCreateClosingBook = strHelp.templateString(
    REACT_APP_API_URL_CLOSING_BOOK_PROCESS,
    bodyClosingBookCreate,
  );

  return await creatorAddService(urlCreateClosingBook, bodyClosingBookCreate, null);
};

const closingBookServices = {
  getClosingBookList,
  closingBookRevert,
  closingBookProcess,
  getClosingBookDetails,
  closingBookCreate,
};

export default closingBookServices;
