import { sliceReduceHelper } from '../helpers/slice-reducer.helper';

// include all store data for global state
export const NAME_STORE = {
  APPLICATIION: 'application',
  ACCOUNT: 'account',
  AUTH: 'auth',
  COMPANY: 'company',
  EMAIL: 'email',
  MANAGEMENT_COMPANY: 'management-company',
  MANAGEMENT_USER: 'management_user',
  MANAGEMENT_ROLE: 'management-role',
  MESSAGE: 'message',
  USER: 'user',
  CONTACT: 'contact',
  PROVINCE: 'province',
  INDO_REGIONAL: 'indo_regional',
  REGIONAL: 'regional',
  BANK: 'bank',
  BANK_DATA: 'bank-data',
  REFERENCE_NUMBER: 'reference-number',
  PRODUCT: 'product',
  JOURNAL_ENTRY: 'journal-entry',
  BEGINNING_BALANCE: 'beginning-balance',
  RECEIPT_CASH: 'receipt-cash',
  SUB_ACCOUNT: 'sub-account',
  PURCHASE: 'purchase',
  SALES: 'sales',
  ASSET: 'asset',
  REPORT: 'report',
  WAREHOUSE: 'warehouse',
  CLOSING_BOOK: 'closing-book',
  TRANSACTION: 'transaction',
  STOCK_ADJUSTMENT: 'stock-adjustment',
  STOCK_TRANSFER: 'stock-transfer',
  MINUTES_OF_HANDOVER: 'minutes-of-handover',
  CHART: 'chart',
  EXPENSE: 'expense',
  ATTACHMENT: 'attachment',
};

export const ROOT_STORE_NAME_APPLICATION = 'application';
export const ROOT_STORE_NAME_ASSET = 'asset';
export const ROOT_STORE_NAME_ACCOUNT = 'account';
export const ROOT_STORE_NAME_CONTACT = 'contact';
export const ROOT_STORE_NAME_PURCHASE = 'purchase';
export const ROOT_STORE_NAME_SALES = 'sales';
export const ROOT_STORE_NAME_EXPENSE = 'expense';
export const ROOT_STORE_NAME_PRODUCT = 'product';
export const ROOT_STORE_NAME_REFERENCE_NUMBER = 'referenceNumber';
export const ROOT_STORE_NAME_STOCK_ADJUSTMENT = 'stockAdjustment';
export const ROOT_STORE_NAME_MINUTES_OF_HANDOVER = 'minutesOfHandover';
export const ROOT_STORE_NAME_CHART = 'chart';
export const ROOT_STORE_NAME_JOURNAL_ENTRY = 'journalEntry';
export const ROOT_STORE_NAME_ATTACHMENT = 'attachment';
export const ROOT_STORE_NAME_NOTIFICATION = 'notification';
export const ROOT_STORE_NAME_USER = 'user';
export const ROOT_STORE_NAME_AUTH = 'auth';
export const ROOT_STORE_NAME_COMPANY = 'company';
export const ROOT_STORE_NAME_MANAGEMENT_COMPANY = 'managementCompany';
export const ROOT_STORE_NAME_MANAGEMENT_USERS = 'managementUsers';
export const ROOT_STORE_NAME_MANAGEMENT_ROLES = 'managementRoles';
export const ROOT_STORE_NAME_USER_PERMISSION = 'userPermission';
export const ROOT_STORE_NAME_BILLING = 'billing';
export const ROOT_STORE_NAME_BANK = 'bank';
export const ROOT_STORE_NAME_TAG = 'tag';
export const ROOT_STORE_NAME_STOCK_TRANSFER = 'stockTransfer';
export const ROOT_STORE_NAME_CASH_BANK = 'cashBank';
export const ROOT_STORE_NAME_BEGINNING_BALANCE = 'beginningBalance';
export const ROOT_STORE_NAME_REPORT = 'report';
export const ROOT_STORE_NAME_CLOSING_BOOK = 'closingBook';

// auth slice name
export const SLICE_NAME_AUTH_DATA = 'data';
export const SLICE_NAME_AUTH_PRIMARY_TOKEN = 'primaryToken';
export const SLICE_NAME_AUTH_SECONDARY_TOKEN = 'secondaryToken';

// reference number name state
export const NAME_STATE_DETAIL_REFERENCE_NUMBER = 'detailReferenceNumber';
export const NAME_STATE_DETAIL_PURCHASE_PAYMENT = 'detailPurchasePayment';

export const SLICE_NAME_DATA_COMPANY_USER = 'dataCompanyUser';

// contact slice name
export const SLICE_NAME_DETAIL_CONTACT = 'detailContact';
export const SLICE_NAME_CONTACT_DATA = 'contactData';
export const SLICE_NAME_CONTACT_PRINT_DATA = 'contactPrintData';
export const SLICE_NAME_TEMP_ADD_CONTACT_DATA = 'tempAddContactData';

export const SLICE_NAME_CONTACT_SALES_INVOICE_LIST_WITH_CONTACT_ID =
  'salesInvoiceListWithContactID';
export const SLICE_NAME_PURCHASE_INVOICE_WITH_CONTACT_ID = 'purchaseInvoiceListWithContactID';

// cash bank slice
export const SLICE_NAME_CASH_BANK_CASH_BANK_DATA = 'cashBankData';
export const SLICE_NAME_DETAIL_CASH_BANK_CASH_TRANSFER = 'detailCashTransfer';
export const SLICE_NAME_TEMP_UPDATE_CASH_TRANSFER = 'tempUpdateCashTransfer';
export const SLICE_NAME_DETAIL_CASH_BANK_CASH_RECEIPT = 'detailCashReceipt';
export const SLICE_NAME_TEMP_UPDATE_CASH_RECEIPT = 'tempUpdateCashReceipt';
export const SLICE_NAME_DETAIL_CASH_BANK_CASH_PAYMENT = 'detailCashPayment';
export const SLICE_NAME_TEMP_UPDATE_CASH_PAYMENT = 'tempUpdateCashPayment';
export const SLICE_NAME_BANK_STATEMENTS_LIST = 'bankStatementsList';
export const SLICE_NAME_IMPORT_BANK_STATEMENTS = 'importBankStatements';
export const SLICE_NAME_IMPORT_BANK_STATEMENTS_PROGRESS = 'importBankStatementsProgress';
export const SLICE_NAME_RECONCILIATION_BANK_LIST = 'reconciliationBankList';
export const SLICE_NAME_RECONCILIATION_BANK_DETAILS = 'reconciliationBankDetails';

export const SLICE_NAME_DETAIL_PURCHASE_ORDER = 'detailPurchaseOrder';

export const SLICE_NAME_TEMP_ADD_PRODUCT_DATA = 'tempAddProductData';
export const SLICE_NAME_TEMP_ADD_PURCHASE_INVOICE_DATA = 'tempAddPurchaseInvoiceData';
export const SLICE_NAME_TEMP_IMPORT_PRODUCT_DATA = 'tempImportProductData';
export const SLICE_NAME_PRODUCT_DETAILS = 'productDetails';

// slice name in state global
export const SLICE_NAME_DATA_PRODUCT_BY_SUPPLIER = 'productBySupplier';
export const SLICE_NAME_DATA_PRODUCT_IMPORTING_PROGRESS = 'productImportingProgress';
export const SLICE_NAME_DATA_PRODUCT_UNIT_LIST = 'productUnitList';
export const SLICE_NAME_PRODUCT_MUTATION_LIST = 'productMutationList';

// quotation request
export const SLICE_NAME_QUOTATION_REQUEST_DATA = 'quotationRequestData';
export const SLICE_NAME_QUOTATION_REQUEST_DATA_PDF_PRINT = 'quotationRequestDataPDFprint';
export const SLICE_NAME_QUOTATION_REQUEST_HISTORY = 'quotationRequestHistory';
export const SLICE_NAME_QUOTATION_REQUEST_DETAIL = 'quotationRequestDetail';

// purchase order
export const SLICE_NAME_PURCHASE_ORDER_DATA = 'purchaseOrderData';
export const SLICE_NAME_PURCHASE_ORDER_DATA_PDF_PRINT = 'purchaseOrderDataPDFprint';
export const SLICE_NAME_PURCHASE_ORDER_HISTORY = 'purchaseOrderHistory';
export const SLICE_NAME_LIST_PURCHASE_ORDER_MAIN = 'purchaseOrderData';
export const SLICE_NAME_LIST_PURCHASE_ORDER_FOR_AUTOCOMPLETE = 'listPurchaseOrderForAutocomplete';

// purchase delivery
export const SLICE_NAME_PURCHASE_DELIVERY_DATA = 'purchaseDeliveryData';
export const SLICE_NAME_PURCHASE_DELIVERY_DATA_PDF_PRINT = 'purchaseDeliveryDataPDFprint';
export const SLICE_NAME_PURCHASE_DELIVERY_DETAIL = 'purchaseDeliveryDetail';
export const SLICE_NAME_PURCHASE_DELIVERY_HISTORY_DETAILS = 'purchaseDeliveryHistoryDetails';

// purchase invoice
export const SLICE_NAME_DATA_PURCHASE_INVOICE = 'purchaseInvoiceData';
export const SLICE_NAME_DATA_PURCHASE_INVOICE_PDF_PRINT = 'purchaseInvoiceDataPDFprint';
export const SLICE_NAME_DETAIL_PURCHASE_INVOICE = 'detailPurchaseInvoice';
export const SLICE_NAME_PURCHASE_INVOICE_HISTORY = 'purchaseInvoiceHistory';
export const SLICE_NAME_TEMP_ADD_PURCHASE_INVOICE = 'tempAddPurchaseInvoice';
export const SLICE_NAME_TEMP_UPDATE_PURCHASE_INVOICE = 'tempUpdatePurchaseInvoce';
export const SLICE_NAME_IMPORT_PURCHASE_INVOICE = 'importPurchaseInvoice';
export const SLICE_NAME_IMPORT_PURCHASE_INVOICE_PROGRESS = 'importPurchaseInvoiceProgress';

// purchase payment
export const SLICE_NAME_PURCHASE_PAYMENT_DATA = 'purchasePaymentData';
export const SLICE_NAME_PURCHASE_PAYMENT_DATA_PDF_PRINT = 'purchasePaymentDataPDFprint';
export const SLICE_NAME_PURCHASE_PAYMENT_HISTORY = 'purchasePaymentHistory';
export const SLICE_NAME_PURCHASE_PAYMENT_DETAIL = 'purchasePaymentDetail';

// purchase debit note
export const SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST = 'purchaseDebitNoteList';
export const SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST_PDF_PRINT = 'purchaseDebitNoteListPDFprint';
export const SLICE_NAME_PURCHASE_DEBIT_NOTE_DETAILS = 'purchaseDebitNoteDetails';
export const SLICE_NAME_PURCHASE_DEBIT_NOTE_HISTORY = 'purchaseDebitNoteHistory';

export const SLICE_NAME_TRANSACTION_DATA_LIST = 'transactionDataList';
export const SLICE_NAME_TRANSACTION_PRINT_DATA_LIST = 'transactionPrintDataList';
export const SLICE_NAME_TRANSACTION_DETAILS = 'transactionDetails';

// sales quotation
export const SLICE_NAME_SALES_QUOTATION_DATA = 'salesQuotationData';
export const SLICE_NAME_SALES_QUOTATION_PRINT_PDF_DATA = 'salesQuotationPrintPDFdata';
export const SLICE_NAME_SALES_QUOTATION_DETAIL = 'salesQuotationDetail';
export const SLICE_NAME_SALES_QUOTATION_DETAILS = 'salesQuotationDetail';
export const SLICE_NAME_SALES_QUOTATION_HISTORY = 'salesQuotationHistory';

// sales order
export const SLICE_NAME_SALES_ORDER_DATA = 'salesOrderData';
export const SLICE_NAME_SALES_ORDER_DETAILS = 'detailSalesOrder';
export const SLICE_NAME_SALES_ORDER_HISTORY_DETAILS = 'salesOrderHistoryDetails';
export const SLICE_NAME_SALES_ORDER_PRINT_PDF_DATA = 'salesOrderPrintPDFdata';

// sales invoice
export const SLICE_NAME_SALES_INVOICE_DATA = 'salesInvoiceData';
export const SLICE_NAME_SALES_INVOICE_PRINT_PDF_DATA = 'salesInvoicePrintPDFdata';
export const SLICE_NAME_SALES_INVOICE_DETAILS = 'salesInvoiceDetail';
export const SLICE_NAME_SALES_INVOICE_HISTORY = 'salesInvoiceHistory';
export const SLICE_NAME_TEMP_ADD_SALES_INVOICE = 'tempAddSalesInvoice';
export const SLICE_NAME_TEMP_UPDATE_SALES_INVOICE = 'tempUpdateSalesInvoice';
export const SLICE_NAME_IMPORT_SALES_INVOICE = 'importSalesInvoice';
export const SLICE_NAME_IMPORT_SALES_INVOICE_PROGRESS = 'importSalesInvoiceProgress';

// sales delivery
export const SLICE_NAME_SALES_DELIVERY_DATA = 'salesDeliveryData';
export const SLICE_NAME_SALES_DELIVERY_PRINT_PDF_DATA = 'salesDeliveryPrintPDFdata';
export const SLICE_NAME_SALES_DELIVERY_DETAILS = 'salesDeliveryDetails';
export const SLICE_NAME_SALES_DELIVERY_HISTORY_DETAILS = 'salesDeliveryHistoryDetails';

// sales payment
export const SLICE_NAME_SALES_PAYMENT_DATA = 'salesPaymentData';
export const SLICE_NAME_SALES_PAYMENT_PRINT_PDF_DATA = 'salesPaymentPrintPDFdata';
export const SLICE_NAME_SALES_PAYMENT_DETAIL = 'salesPaymentDetail';
export const SLICE_NAME_SALES_PAYMENT_HISTORY = 'salesPaymentHistory';

export const SLICE_NAME_REPORT_INDEX_LIST = 'reportIndexList';
export const SLICE_NAME_EXPORTED_DOCUMENT_REPORT = 'exportedDocumentReport';

export const SLICE_NAME_REPORT_JOURNAL_LIST = 'reportJournalList';
export const SLICE_NAME_REPORT_INCOME_STATEMENT = 'reportIncomeStatement';
export const SLICE_NAME_REPORT_BALANCE_SHEET = 'reportBalanceSheet';
export const SLICE_NAME_REPORT_CAPITAL_CHANGE = 'reportCapitalChange';
export const SLICE_NAME_REPORT_CASH_FLOW = 'reportCashFlow';
export const SLICE_NAME_REPORT_GENERAL_LEDGER = 'reportGeneralLedger';
export const SLICE_NAME_REPORT_TRIAL_BALANCE = 'reportTrialBalance';

export const SLICE_NAME_REPORT_BASIC_PURCHASE_LIST = 'reportBasicPurchaseList';
export const SLICE_NAME_REPORT_BASIC_SALES_LIST = 'reportBasicSalesList';
export const SLICE_NAME_REPORT_BASIC_STOCK_LIST = 'reportBasicStockList';
export const SLICE_NAME_REPORT_BASIC_PRODUCT_PRICE_LIST = 'reportBasicPriceList';
export const SLICE_NAME_REPORT_BASIC_SALES_EXTRACTION_LIST = 'reportBasicSalesExtractionList';
export const SLICE_NAME_REPORT_STOCK_SALES_LIST = 'reportStockSalesList';
export const SLICE_NAME_REPORT_STOCK_CARD_LIST = 'reportStockCardList';
export const SLICE_NAME_REPORT_STOCK_MUTATION_LIST = 'reportStockMutationList';
export const SLICE_NAME_REPORT_STOCK_PURCHASE_LIST = 'reportStockPurchaseList';
export const SLICE_NAME_REPORT_STOCK_ADJUSTMENT_LIST = 'reportStockAdjustmentList';

export const SLICE_NAME_SETTINGS_WAREHOUSE_DATA = 'settingsWarehouseData';
export const SLICE_NAME_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE = 'settingsWarehouseDataAutocomplete';
export const SLICE_NAME_SETTINGS_WAREHOUSE_PRODUCT_DATA_AUTOCOMPLETE =
  'settingsWarehouseProductDataAutocomplete';
export const SLICE_NAME_SETTINGS_WAREHOUSE_DETAIL = 'settingsWarehouseDetail';

export const SLICE_NAME_CLOSING_BOOK_DATA = 'closingBookData';
export const SLICE_NAME_TEMP_PROCESS_CLOSING_BOOK = 'tempProcessClosingBook';
export const SLICE_NAME_CLOSING_BOOK_DETAILS = 'closingBookDetails';

export const SLICE_NAME_MANAGEMENT_USERS_LIST_REGULAR = 'users';
export const SLICE_NAME_MANAGEMENT_USERS_FOR_AUTOCOMPLETE = 'userListForAutocomplete';
export const SLICE_NAME_MANAGEMENT_USERS_LIST_SETTING_COMPANY = 'userListSettingCompany';

export const SLICE_NAME_COMPANY_LIST_AUTOCOMPLETE = 'companyListAutocomplete';

export const SLICE_NAME_SETTING_COMPANY_DETAILS = 'settingCompanyDetails';

export const SLICE_NAME_ALL_NOTIFICATIONS_ALL_LIST = 'allNotificationsList';
export const SLICE_NAME_INVITATION_NOTIFICATION_LIST = 'invitationNotificationList';
export const SLICE_NAME_UNREAD_NOTIFICATION_COUNT = 'unreadNotificationCount';

// slice name for stock transfer
export const SLICE_NAME_STOCK_TRANSFER_LIST = 'stockTransferList';
export const SLICE_NAME_STOCK_TRANSFER_PRINT_LIST = 'stockTransferPrintList';
export const SLICE_NAME_STOCK_TRANSFER_DETAILS = 'stockTransferDetails';

// account slice name
export const SLICE_NAME_ACCOUNT_DATA = 'accountData';
export const SLICE_NAME_ACCOUNT_PRINT_DATA = 'accountPrintData';
export const SLICE_NAME_ACCOUNT_DETAILS = 'accountDetails';
export const SLICE_NAME_ACCOUNT_DATA_ALTERNATIVE = 'accountDataAlternative';
export const SLICE_NAME_ACCOUNT_TRANSACTION = 'accountTransction';

// account category slice name
export const SLICE_NAME_ACCOUNT_CATEGORY = 'category';
export const SLICE_NAME_ACCOUNT_CATEGORY_PRINT = 'categoryPrint';

// slice name for stock adjustment
export const SLICE_NAME_STOCK_ADJUSTMENT_LIST = 'stockAdjustmentList';
export const SLICE_NAME_STOCK_ADJUSTMENT_PRINT_LIST = 'stockAdjustmentPrintList';
export const SLICE_NAME_STOCK_ADJUSTMENT_DETAILS = 'stockAdjustmentDetails';

// slice name for handover
export const SLICE_NAME_HANDOVER_PURCHASE_DELIVERY_DETAILS = 'handoverPurchaseDeliveryDetails';
export const SLICE_NAME_HANDOVER_PURCHASE_DELIVERY_HISTORY = 'handoverPurchaseDeliveryHistory';
export const SLICE_NAME_HANDOVER_SALES_DELIVERY_DETAILS = 'handoverSalesDeliveryDetails';
export const SLICE_NAME_HANDOVER_SALES_DELIVERY_HISTORY = 'handoverSalesDeliveryHistory';

// slice name for user
export const SLICE_NAME_USER_PROFILE_DATA = 'profile';
export const SLICE_NAME_USER_COMPANY = 'company';

// slice name for reference number
export const SLICE_NAME_REFERENCE_NUMBER_DATA = 'referenceNumberData';
export const SLICE_NAME_REFERENCE_NUMBER_DETAILS = 'detailReferenceNumber';

// slice name for product
export const SLICE_NAME_PRODUCT_DATA = 'productData';
export const SLICE_NAME_PRODUCT_PRINT_DATA = 'productPrintData';
export const SLICE_NAME_PRODUCT_DATA_ALTERNATIVE = 'productDataAlternative';

// product category slice name
export const SLICE_NAME_PRODUCT_CATEGORY_DATA = 'categoryData';
export const SLICE_NAME_PRODUCT_CATEGORY_PRINT_DATA = 'categoryPrintData';

// slice name for application
export const SLICE_NAME_APPLICATION_TYPE = 'applicationType';
export const SLICE_NAME_APPLICATION_CONFIGURATION = 'applicationConfiguration';

// slice name for journal entry
export const SLICE_NAME_JOURNAL_ENTRY_DETAIL = 'journalEntryDetail';
export const SLICE_NAME_IMPORT_JOURNAL_ENTRY = 'importJournalEntry';
export const SLICE_NAME_IMPORT_JOURNAL_ENTRY_PROGRESS = 'importJournalEntryProgress';

// slice name for purchase return
export const SLICE_NAME_PURCHASE_RETURN_LIST = 'purchaseReturnList';
export const SLICE_NAME_PURCHASE_RETURN_LIST_PDF_PRINT = 'purchaseReturnListPDFprint';
export const SLICE_NAME_PURCHASE_RETURN_DETAILS = 'purchaseReturnDetails';
export const SLICE_NAME_PURCHASE_RETURN_HISTORY = 'purchaseReturnHistory';

// slice name for purchase receivable payment
export const SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_LIST = 'purchaseReceivablePaymentList';
export const SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_LIST_PDF_PRINT =
  'purchaseReceivablePaymentListPDFprint';
export const SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_DETAILS = 'purchaseReceivablePaymentDetails';
export const SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_HISTORY = 'purchaseReceivablePaymentHistory';

// slice name for chart
export const SLICE_NAME_CHART_BASIC_DASHBOARD_PURCHASE = 'chartBasicDashboardPurchase';
export const SLICE_NAME_CHART_BASIC_DASHBOARD_SALES = 'chartBasicDashboardSales';
export const SLICE_NAME_CHART_BASIC_DASHBOARD_RECEIVABLE = 'chartBasicDashboardReceivable';
export const SLICE_NAME_CHART_BASIC_DASHBOARD_PAYABLE = 'chartBasicDashboardPayable';
export const SLICE_NAME_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE =
  'chartBasicDashboardPurchaseInvoice';
export const SLICE_NAME_CHART_BASIC_DASHBOARD_SALES_INVOICE = 'chartBasicDashboardSalesInvoice';
export const SLICE_NAME_CHART_BASIC_DASHBOARD_SALES_PERSON = 'chartBasicDashboardSalesPerson';
export const SLICE_NAME_CHART_BASIC_DASHBOARD_PRODUCT_STOCK = 'chartBasicDashboardProductStock';
export const SLICE_NAME_CHART_SALES_TRANSACTION_DETAILS = 'chartSalesTransactionDetails';
export const SLICE_NAME_CHART_INCOME_STATEMENT_DETAILS = 'chartIncomeStamentDetails';
export const SLICE_NAME_CHART_EXPENSE = 'chartExpense';
export const SLICE_NAME_CHART_COMPARISON_SALES = 'chartComparisonSales';
export const SLICE_NAME_CHART_TOP_SALES_SALES_AGENT = 'chartTopSalesSalesAgent';
export const SLICE_NAME_CHART_TOP_SALES_CUSTOMER = 'chartTopSalesCustomer';
export const SLICE_NAME_CHART_TOP_SALES_PRODUCT = 'chartTopSalesProduct';
export const SLICE_NAME_CHART_TOP_SALES_PRODUCT_CATEGORY = 'chartTopSalesProductCategory';
export const SLICE_NAME_CHART_TOP_SALES_COMPARISON_SALES_AGENT =
  'chartTopSalesComparisonSalesAgent';
export const SLICE_NAME_CHART_TOP_SALES_COMPARISON_CUSTOMER = 'chartTopSalesComparisonCustomer';
export const SLICE_NAME_CHART_TOP_SALES_COMPARISON_PRODUCT = 'chartTopSalesComparisonProduct';
export const SLICE_NAME_CHART_TOP_SALES_COMPARISON_PRODUCT_CATEGORY =
  'chartTopSalesComparisonProductCategory';
export const SLICE_NAME_CHART_PIVOT_TABLE = 'chartDashboardPivotTable';
export const SLICE_NAME_CHART_DASHBOARD_PRO_APP_SEARCH_PARAMS = 'chardDashboardProAppSearchParams';

// slice name for expense
export const SLICE_NAME_EXPENSE_LIST = 'expenseList';
export const SLICE_NAME_EXPENSE_DETAIL = 'expenseDetail';
export const SLICE_NAME_IMPORT_EXPENSE = 'importExpense';
export const SLICE_NAME_IMPORT_EXPENSE_PROGRESS = 'importExpenseProgress';

// slice name for attachment
export const SLICE_NAME_TEMP_CREATE_ATTACHMENT = 'tempCreateAttachment';
export const SLICE_NAME_TEMP_DELETE_ATTACHMENT = 'tempDeleteAttachment';
export const SLICE_NAME_LIST_DETAILS_ATTACHMENT = 'listDetailsAttachment';

// slice name management user
export const SLICE_NAME_MANAGEMENT_USER_DETAILS = 'managementUserDetails';

// slice name user permission
export const SLICE_NAME_USER_PERMISSION_LIST_DETAILS = 'userPermissionListDetails';

// slice name billing
export const SLICE_NAME_BILLING_DETAILS = 'billingDetails';
export const SLICE_NAME_BILLING_INVOICE_LIST = 'billingInvoiceList';
export const SLICE_NAME_BILLING_INVOICE_DETAILS = 'billingInvoiceDetails';
export const SLICE_NAME_BILLING_PACKAGE_LIST = 'billingPackageList';
export const SLICE_NAME_SELECTED_BILLING_PACKAGE = 'selectedBillingPackage';
export const SLICE_NAME_CREATE_BILLING_TEMP = 'createBillingTemp';
export const SLICE_NAME_PAY_BILLING_TEMP = 'payBillingTemp';

// sales return
export const SLICE_NAME_SALES_RETURN_LIST = 'salesReturnList';
export const SLICE_NAME_SALES_RETURN_PRINT_PDF_LIST = 'salesReturnPrintPDFlist';
export const SLICE_NAME_SALES_RETURN_DETAILS = 'salesReturnDetails';
export const SLICE_NAME_SALES_RETURN_HISTORY = 'salesReturnHistory';

// sales payable payment
export const SLICE_NAME_SALES_PAYABLE_PAYMENT_LIST = 'salesPayablePaymentList';
export const SLICE_NAME_SALES_PAYABLE_PAYMENT_PRINT_PDF_LIST = 'salesPayablePaymentPrintPDFlist';
export const SLICE_NAME_SALES_PAYABLE_PAYMENT_DETAILS = 'salesPayablePaymentDetails';
export const SLICE_NAME_SALES_PAYABLE_PAYMENT_HISTORY = 'salesPayablePaymentHistory';

// sales credit note
export const SLICE_NAME_SALES_CREDIT_NOTE_LIST = 'salesCreditNoteList';
export const SLICE_NAME_SALES_CREDIT_NOTE_PRINT_PDF_LIST = 'salesCreditNotePrintPDFlist';
export const SLICE_NAME_SALES_CREDIT_NOTE_DETAILS = 'salesCreditNoteDetails';
export const SLICE_NAME_SALES_CREDIT_NOTE_HISTORY = 'salesCreditNoteHistory';

// slice name for asset category
export const SLICE_NAME_ASSET_CATEGORY_DATA = 'assetCategoryData';
export const SLICE_NAME_ASSET_CATEGORY_PRINT_PDF_DATA = 'assetCategoryPrintPDFdata';

// slice name for asset management
export const SLICE_NAME_ASSET_MANAGEMENT_DATA = 'assetManagementData';
export const SLICE_NAME_ASSET_MANAGEMENT_PRINT_PDF_DATA = 'assetManagementPrintPDFdata';
export const SLICE_NAME_ASSET_MANAGEMENT_DETAIL = 'assetManagementDetail';

// slice name for bank reducer
export const SLICE_NAME_BANK_DATA = 'bankData';

// slice name fot tag
export const SLICE_NAME_TAG_LIST = 'tagList';

// slice beginning balance
export const SLICE_NAME_DATA_BEGINNING_BALANCE = 'dataBeginningBalance';

// slice managmenet role
export const SLICE_NAME_MANAGEMENT_ROLE_DATA = 'managementRoleData';

// base of state request
export const STATUS_REQUEST_BASE_IDDLE = 'iddle';
export const STATUS_REQUEST_BASE_PENDING = 'pending';
export const STATUS_REQUEST_BASE_SUCCESS = 'success';
export const STATUS_REQUEST_BASE_FAILED = 'failed';

// base of action status
export const ACTION_STATUS_BASE_IDDLE = 'action-status-iddle';
export const ACTION_STATUS_BASE_PENDING = 'action-status-pending';
export const ACTION_STATUS_BASE_SUCCESS = 'action-status-success';
export const ACTION_STATUS_BASE_FAILED = 'action-status-failed';

// auth of status request
export const STATUS_REQUEST_REGISTER_AUTH_SUCCESS = 'register-auth-success';
export const STATUS_REQUEST_REGISTER_AUTH_FAILED = 'register-auth-failed';
export const STATUS_REQUEST_REGISTER_AUTH_PENDING = 'register-auth-loading';

export const STATUS_REQUEST_LOGIN_AUTH_SUCCESS = 'login-auth-success';
export const STATUS_REQUEST_LOGIN_AUTH_FAILED = 'login-auth-failed';
export const STATUS_REQUEST_LOGIN_AUTH_PENDING = 'login-auth-loading';

export const STATUS_REQUEST_CHANGE_COMPANY_AUTH_SUCCESS = 'change-company-auth-success';
export const STATUS_REQUEST_CHANGE_COMPANY_AUTH_FAILED = 'change-company-auth-failed';
export const STATUS_REQUEST_CHANGE_COMPANY_AUTH_PENDING = 'change-company-auth-loading';

export const STATUS_REQUEST_CHANGE_COMPANY_AUTH_BY_AUTH_PORTAL_SUCCESS =
  'change-company-auth-by-auth-portal-success';
export const STATUS_REQUEST_CHANGE_COMPANY_AUTH_BY_AUTH_PORTAL_FAILED =
  'change-company-auth-by-auth-portal-failed';
export const STATUS_REQUEST_CHANGE_COMPANY_AUTH_BY_AUTH_PORTAL_PENDING =
  'change-company-auth-by-auth-portal-loading';

export const STATUS_REQUEST_NEW_PASSWORD_SUCCESS = 'new-password-auth-success';
export const STATUS_REQUEST_NEW_PASSWORD_FAILED = 'new-password-auth-failed';
export const STATUS_REQUEST_NEW_PASSWORD_PENDING = 'new-password-auth-loading';

export const STATUS_REQUEST_LOGOUT_AUTH_SUCCESS = 'logout-auth-success';
export const STATUS_REQUEST_LOGOUT_AUTH_FAILED = 'logout-auth-failed';
export const STATUS_REQUEST_LOGOUT_AUTH_PENDING = 'logout-auth-loading';

export const STATUS_REQUEST_VERIFY_EMAIL_AUTH_SUCCESS = 'verify-email-auth-success';
export const STATUS_REQUEST_VERIFY_EMAIL_AUTH_FAILED = 'verify-email-auth-failed';
export const STATUS_REQUEST_VERIFY_EMAIL_AUTH_PENDING = 'verify-email-auth-loading';

// email of status request
export const STATUS_REQUEST_EMAIL_RESEND_SUCCESS = 'email-resend-success';
export const STATUS_REQUEST_EMAIL_RESEND_FAILED = 'email-resend-failed';
export const STATUS_REQUEST_EMAIL_RESEND_PENDING = 'email-resend-loading';

// company user of status request
export const STATUS_REQUEST_UPDATE_COMPANY_USER_PENDING = 'update-company-user-pending';
export const STATUS_REQUEST_UPDATE_COMPANY_USER_SUCCESS = 'update-company-user-success';
export const STATUS_REQUEST_UPDATE_COMPANY_USER_FAILED = 'update-company-user-failed';

// account of status request
export const STATUS_REQUEST_LIST_ACCOUNT_DATA_SUCCESS = 'list-account-data-success';
export const STATUS_REQUEST_LIST_ACCOUNT_DATA_FAILED = 'list-account-data-failed';
export const STATUS_REQUEST_LIST_ACCOUNT_DATA_PENDING = 'list-account-data-loading';

export const REQUEST_STATUS_LIST_ACCOUNT_DATA_PRINT_PDF_SUCCESS =
  'list-account-data-print-pdf-success';
export const REQUEST_STATUS_LIST_ACCOUNT_DATA_PRINT_PDF_FAILED =
  'list-account-data-print-pdf-failed';
export const REQUEST_STATUS_LIST_ACCOUNT_DATA_PRINT_PDF_PENDING =
  'list-account-data-print-pdf-loading';

export const STATUS_REQUEST_DETAILS_ACCOUNT_DATA_SUCCESS = 'details-account-data-success';
export const STATUS_REQUEST_DETAILS_ACCOUNT_DATA_FAILED = 'details-account-data-failed';
export const STATUS_REQUEST_DETAILS_ACCOUNT_DATA_PENDING = 'details-account-data-loading';

export const STATUS_REQUEST_ADD_ACCOUNT_DATA_SUCCESS = 'add-account-data-success';
export const STATUS_REQUEST_ADD_ACCOUNT_DATA_FAILED = 'add-account-data-failed';
export const STATUS_REQUEST_ADD_ACCOUNT_DATA_PENDING = 'add-account-data-loading';

export const STATUS_REQUEST_UPDATE_ACCOUNT_DATA_SUCCESS = 'update-account-data-success';
export const STATUS_REQUEST_UPDATE_ACCOUNT_DATA_FAILED = 'update-account-data-failed';
export const STATUS_REQUEST_UPDATE_ACCOUNT_DATA_PENDING = 'update-account-data-loading';

export const STATUS_REQUEST_DELETE_ACCOUNT_DATA_SUCCESS = 'delete-account-data-success';
export const STATUS_REQUEST_DELETE_ACCOUNT_DATA_FAILED = 'delete-account-data-failed';
export const STATUS_REQUEST_DELETE_ACCOUNT_DATA_PENDING = 'delete-account-data-loading';

export const STATUS_REQUEST_LIST_ACCOUNT_DATA_ALTERNATIVE_SUCCESS =
  'list-account-data-alternative-success';
export const STATUS_REQUEST_LIST_ACCOUNT_DATA_ALTERNATIVE_FAILED =
  'list-account2-data-alternative-failed';
export const STATUS_REQUEST_LIST_ACCOUNT_DATA_ALTERNATIVE_PENDING =
  'list-account2-data-alternative-loading';

// account transction status request
export const STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_SUCCESS = 'list-account-transaction-success';
export const STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_PENDING = 'list-account-transaction-pending';
export const STATUS_REQUEST_LIST_ACCOUNT_TRANSACTION_FAILED = 'list-account-transaction-failed';

// account category of status request
export const STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_SUCCESS = 'list-account-category-success';
export const STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_FAILED = 'list-account-category-failed';
export const STATUS_REQUEST_LIST_ACCOUNT_CATEGORY_PENDING = 'list-account-category-loading';

export const REQUEST_STATUS_LIST_ACCOUNT_CATEGORY_PRINT_PDF_SUCCESS =
  'list-account-category-print-pdf-success';
export const REQUEST_STATUS_LIST_ACCOUNT_CATEGORY_PRINT_PDF_FAILED =
  'list-account-category-print-pdf-failed';
export const REQUEST_STATUS_LIST_ACCOUNT_CATEGORY_PRINT_PDF_PENDING =
  'list-account-category-print-pdf-loading';

export const STATUS_REQUEST_ADD_ACCOUNT_CATEGORY_SUCCESS = 'add-account-category-success';
export const STATUS_REQUEST_ADD_ACCOUNT_CATEGORY_FAILED = 'add-account-category-failed';
export const STATUS_REQUEST_ADD_ACCOUNT_CATEGORY_PENDING = 'add-account-category-loading';

export const STATUS_REQUEST_UPDATE_ACCOUNT_CATEGORY_SUCCESS = 'update-account-category-success';
export const STATUS_REQUEST_UPDATE_ACCOUNT_CATEGORY_FAILED = 'update-account-category-failed';
export const STATUS_REQUEST_UPDATE_ACCOUNT_CATEGORY_PENDING = 'update-account-category-loading';

export const STATUS_REQUEST_DELETE_ACCOUNT_CATEGORY_SUCCESS = 'delete-account-category-success';
export const STATUS_REQUEST_DELETE_ACCOUNT_CATEGORY_FAILED = 'delete-account-category-failed';
export const STATUS_REQUEST_DELETE_ACCOUNT_CATEGORY_PENDING = 'delete-account-category-loading';

// status request of account parent
export const STATUS_REQUEST_LIST_ACCOUNT_PARENT_SUCCESS = 'list-account-parent-data-success';
export const STATUS_REQUEST_LIST_ACCOUNT_PARENT_FAILED = 'list-account-parent-data-failed';
export const STATUS_REQUEST_LIST_ACCOUNT_PARENT_PENDING = 'list-account-parent-data-loading';

// status request of sub account
export const STATUS_REQUEST_LIST_SUB_ACCOUNT_SUCCESS = 'list-sub-account-data-success';
export const STATUS_REQUEST_LIST_SUB_ACCOUNT_FAILED = 'list-sub-account-data-failed';
export const STATUS_REQUEST_LIST_SUB_ACCOUNT_PENDING = 'list-sub-account-data-loading';

export const STATUS_REQUEST_ADD_SUB_ACCOUNT_SUCCESS = 'add-sub-account-success';
export const STATUS_REQUEST_ADD_SUB_ACCOUNT_FAILED = 'add-sub-account-failed';
export const STATUS_REQUEST_ADD_SUB_ACCOUNT_PENDING = 'add-sub-account-loading';

export const STATUS_REQUEST_UPDATE_SUB_ACCOUNT_SUCCESS = 'update-account-success';
export const STATUS_REQUEST_UPDATE_SUB_ACCOUNT_FAILED = 'update-sub-account-failed';
export const STATUS_REQUEST_UPDATE_SUB_ACCOUNT_PENDING = 'update-sub-account-loading';

export const STATUS_REQUEST_DELETE_SUB_ACCOUNT_SUCCESS = 'delete-sub-account-success';
export const STATUS_REQUEST_DELETE_SUB_ACCOUNT_FAILED = 'delete-sub-account-failed';
export const STATUS_REQUEST_DELETE_SUB_ACCOUNT_PENDING = 'delete-sub-account-loading';

// product data of status request
export const STATUS_REQUEST_LIST_PRODUCT_DATA_SUCCESS = 'list-product-data-success';
export const STATUS_REQUEST_LIST_PRODUCT_DATA_FAILED = 'list-product-data-failed';
export const STATUS_REQUEST_LIST_PRODUCT_DATA_PENDING = 'list-product-data-loading';

export const REQUEST_STATUS_LIST_PRODUCT_DATA_PRINT_PDF_SUCCESS =
  'list-product-data-print-pdf-success';
export const REQUEST_STATUS_LIST_PRODUCT_DATA_PRINT_PDF_FAILED =
  'list-product-data-print-pdf-failed';
export const REQUEST_STATUS_LIST_PRODUCT_DATA_PRINT_PDF_PENDING =
  'list-product-data-print-pdf-loading';

export const STATUS_REQUEST_DETAIL_PRODUCT_DATA_SUCCESS = 'detail-product-data-success';
export const STATUS_REQUEST_DETAIL_PRODUCT_DATA_FAILED = 'detail-product-data-failed';
export const STATUS_REQUEST_DETAIL_PRODUCT_DATA_PENDING = 'detail-product-data-loading';

export const STATUS_REQUEST_ADD_PRODUCT_DATA_SUCCESS = 'add-product-data-success';
export const STATUS_REQUEST_ADD_PRODUCT_DATA_FAILED = 'add-product-data-failed';
export const STATUS_REQUEST_ADD_PRODUCT_DATA_PENDING = 'add-product-data-loading';

export const STATUS_REQUEST_UPDATE_PRODUCT_DATA_SUCCESS = 'update-product-data-success';
export const STATUS_REQUEST_UPDATE_PRODUCT_DATA_FAILED = 'update-product-data-failed';
export const STATUS_REQUEST_UPDATE_PRODUCT_DATA_PENDING = 'update-product-data-loading';

export const STATUS_REQUEST_DELETE_PRODUCT_DATA_SUCCESS = 'delete-product-data-success';
export const STATUS_REQUEST_DELETE_PRODUCT_DATA_FAILED = 'delete-product-data-failed';
export const STATUS_REQUEST_DELETE_PRODUCT_DATA_PENDING = 'delete-product-data-loading';

export const STATUS_REQUEST_LIST_PRODUCT_PURCHASE_SALE_SUCCESS =
  'list-product-purchase-sale-success';
export const STATUS_REQUEST_LIST_PRODUCT_PURCHASE_SALE_FAILED = 'list-product-purchase-sale-failed';
export const STATUS_REQUEST_LIST_PRODUCT_PURCHASE_SALE_PENDING =
  'list-product-purchase-sale-loading';

export const STATUS_REQUEST_LIST_PRODUCT_DATA_ALTERNATIVE_SUCCESS =
  'list-product-data-alternative-success';
export const STATUS_REQUEST_LIST_PRODUCT_DATA_ALTERNATIVE_FAILED =
  'list-product-data-alternative-failed';
export const STATUS_REQUEST_LIST_PRODUCT_DATA_ALTERNATIVE_PENDING =
  'list-product-data-alternative-loading';

export const STATUS_REQUEST_IMPORT_PRODUCT_DATA_SUCCESS = 'import-product-data-success';
export const STATUS_REQUEST_IMPORT_PRODUCT_DATA_FAILED = 'import-product-data-failed';
export const STATUS_REQUEST_IMPORT_PRODUCT_DATA_PENDING = 'import-product-data-loading';

export const STATUS_REQUEST_IMPORT_PRODUCT_PROGRESS_DATA_SUCCESS =
  'import-product-progress-data-success';
export const STATUS_REQUEST_IMPORT_PRODUCT_PROGRESS_DATA_FAILED =
  'import-product-progress-data-failed';
export const STATUS_REQUEST_IMPORT_PRODUCT_PROGRESS_DATA_PENDING =
  'import-product-progress-data-loading';

export const STATUS_REQUEST_LIST_PRODUCT_UNIT_SUCCESS = 'list-product-unit-success';
export const STATUS_REQUEST_LIST_PRODUCT_UNIT_FAILED = 'list-product-unit-failed';
export const STATUS_REQUEST_LIST_PRODUCT_UNIT_PENDING = 'list-product-unit-loading';

export const REQUEST_STATUS_LIST_PRODUCT_MUTATION_SUCCESS = 'list-product-mutation-success';
export const REQUEST_STATUS_LIST_PRODUCT_MUTATION_FAILED = 'list-product-mutation-failed';
export const REQUEST_STATUS_LIST_PRODUCT_MUTATION_PENDING = 'list-product-mutation-loading';

// product category of status request
export const STATUS_REQUEST_LIST_PRODUCT_CATEGORY_SUCCESS = 'list-product-category-success';
export const STATUS_REQUEST_LIST_PRODUCT_CATEGORY_FAILED = 'list-product-category-failed';
export const STATUS_REQUEST_LIST_PRODUCT_CATEGORY_PENDING = 'list-product-category-loading';

export const REQUEST_STATUS_LIST_PRODUCT_CATEGORY_PRINT_PDF_SUCCESS =
  'list-product-category-print-pdf-success';
export const REQUEST_STATUS_LIST_PRODUCT_CATEGORY_PRINT_PDF_FAILED =
  'list-product-category-print-pdf-failed';
export const REQUEST_STATUS_LIST_PRODUCT_CATEGORY_PRINT_PDF_PENDING =
  'list-product-category-print-pdf-loading';

export const STATUS_REQUEST_ADD_PRODUCT_CATEGORY_SUCCESS = 'add-product-category-success';
export const STATUS_REQUEST_ADD_PRODUCT_CATEGORY_FAILED = 'add-product-category-failed';
export const STATUS_REQUEST_ADD_PRODUCT_CATEGORY_PENDING = 'add-product-category-loading';

export const STATUS_REQUEST_UPDATE_PRODUCT_CATEGORY_SUCCESS = 'update-product-category-success';
export const STATUS_REQUEST_UPDATE_PRODUCT_CATEGORY_FAILED = 'update-product-category-failed';
export const STATUS_REQUEST_UPDATE_PRODUCT_CATEGORY_PENDING = 'update-product-category-loading';

export const STATUS_REQUEST_DELETE_PRODUCT_CATEGORY_SUCCESS = 'delete-product-category-success';
export const STATUS_REQUEST_DELETE_PRODUCT_CATEGORY_FAILED = 'delete-product-category-failed';
export const STATUS_REQUEST_DELETE_PRODUCT_CATEGORY_PENDING = 'delete-product-category-loading';

// product by supplier of status request
export const STATUS_REQUEST_LIST_PRODUCT_BY_SUPPLIER_SUCCESS = 'list-product-by-supplier-success';
export const STATUS_REQUEST_LIST_PRODUCT_BY_SUPPLIER_FAILED = 'list-product-by-supplier-failed';
export const STATUS_REQUEST_LIST_PRODUCT_BY_SUPPLIER_PENDING = 'list-product-by-supplier-loading';

// status request of setting company
export const STATUS_REQUEST_DETAIL_SETTING_COMPANY_SUCCESS = 'detail-setting-company-success';
export const STATUS_REQUEST_DETAIL_SETTING_COMPANY_FAILED = 'detail-setting-company-failed';
export const STATUS_REQUEST_DETAIL_SETTING_COMPANY_PENDING = 'detail-setting-company-pending';

export const STATUS_REQUEST_DETAIL_USER_COMPANY_SUCCESS = 'detail-user-company-success';
export const STATUS_REQUEST_DETAIL_USER_COMPANY_FAILED = 'detail-user-company-failed';
export const STATUS_REQUEST_DETAIL_USER_COMPANY_PENDING = 'detail-user-company-pending';

export const STATUS_REQUEST_REGISTER_COMPANY_SUCCESS = 'register-company-success';
export const STATUS_REQUEST_REGISTER_COMPANY_FAILED = 'register-company-failed';
export const STATUS_REQUEST_REGISTER_COMPANY_PENDING = 'register-company-pending';

// user company of status request
export const STATUS_REQUEST_LIST_USER_COMPANY_SUCCESS = 'list-user-company-success';
export const STATUS_REQUEST_LIST_USER_COMPANY_FAILED = 'list-user-company-failed';
export const STATUS_REQUEST_LIST_USER_COMPANY_PENDING = 'list-user-company-loading';

export const STATUS_REQUEST_LIST_USER_COMPANY_AUTOCOMPLETE_SUCCESS =
  'list-user-company-autocomplete-success';
export const STATUS_REQUEST_LIST_USER_COMPANY_AUTOCOMPLETE_FAILED =
  'list-user-company-autocomplete-failed';
export const STATUS_REQUEST_LIST_USER_COMPANY_AUTOCOMPLETE_PENDING =
  'list-user-company-autocomplete-loading';

// management company of request status
export const STATUS_REQUEST_ADD_MANAGEMENT_COMPANY_SUCCESS = 'add-management-company-success';
export const STATUS_REQUEST_ADD_MANAGEMENT_COMPANY_FAILED = 'add-management-company-failed';
export const STATUS_REQUEST_ADD_MANAGEMENT_COMPANY_PENDING = 'add-management-company-loading';

export const STATUS_REQUEST_UPDATE_MANAGEMENT_COMPANY_SUCCESS = 'update-management-company-success';
export const STATUS_REQUEST_UPDATE_MANAGEMENT_COMPANY_FAILED = 'update-management-company-failed';
export const STATUS_REQUEST_UPDATE_MANAGEMENT_COMPANY_PENDING = 'update-management-company-loading';

export const STATUS_REQUEST_DELETE_MANAGEMENT_COMPANY_SUCCESS = 'delete-management-company-success';
export const STATUS_REQUEST_DELETE_MANAGEMENT_COMPANY_FAILED = 'delete-management-company-failed';
export const STATUS_REQUEST_DELETE_MANAGEMENT_COMPANY_PENDING = 'delete-management-company-loading';

// management users of status request
export const STATUS_REQUEST_LIST_MANAGEMENT_USERS_SUCCESS = 'list-management-users-success';
export const STATUS_REQUEST_LIST_MANAGEMENT_USERS_FAILED = 'list-management-users-failed';
export const STATUS_REQUEST_LIST_MANAGEMENT_USERS_PENDING = 'list-management-users-loading';

export const STATUS_REQUEST_LIST_MANAGEMENT_USERS_FOR_AUTOCOMPLETE_SUCCESS =
  'list-management-users-for-autocomplete-success';
export const STATUS_REQUEST_LIST_MANAGEMENT_USERS_FOR_AUTOCOMPLETE_FAILED =
  'list-management-users-for-autocomplete-failed';
export const STATUS_REQUEST_LIST_MANAGEMENT_USERS_FOR_AUTOCOMPLETE_PENDING =
  'list-management-users-for-autocomplete-loading';

export const STATUS_REQUEST_LIST_MANAGEMENT_USERS_COMPANY_SUCCESS =
  'list-management-users-company-success';
export const STATUS_REQUEST_LIST_MANAGEMENT_USERS_COMPANY_FAILED =
  'list-management-users-company-failed';
export const STATUS_REQUEST_LIST_MANAGEMENT_USERS_COMPANY_PENDING =
  'list-management-users-company-loading';

export const STATUS_REQUEST_ADD_MANAGEMENT_USERS_SUCCESS = 'add-management-users-success';
export const STATUS_REQUEST_ADD_MANAGEMENT_USERS_FAILED = 'add-management-users-failed';
export const STATUS_REQUEST_ADD_MANAGEMENT_USERS_PENDING = 'add-management-users-loading';

export const STATUS_REQUEST_UPDATE_MANAGEMENT_USERS_SUCCESS = 'update-management-users-success';
export const STATUS_REQUEST_UPDATE_MANAGEMENT_USERS_FAILED = 'update-management-users-failed';
export const STATUS_REQUEST_UPDATE_MANAGEMENT_USERS_PENDING = 'update-management-users-loading';

export const STATUS_REQUEST_DELETE_MANAGEMENT_USERS_SUCCESS = 'delete-management-users-success';
export const STATUS_REQUEST_DELETE_MANAGEMENT_USERS_FAILED = 'delete-management-users-failed';
export const STATUS_REQUEST_DELETE_MANAGEMENT_USERS_PENDING = 'delete-management-users-loading';

export const STATUS_REQUEST_ADD_MANAGEMENT_USERS_ASSIGNMENT_ROLE_SUCCESS =
  'add-management-users-assignment-role-success';
export const STATUS_REQUEST_ADD_MANAGEMENT_USERS_ASSIGNMENT_ROLE_FAILED =
  'add-management-users-assignment-role-failed';
export const STATUS_REQUEST_ADD_MANAGEMENT_USERS_ASSIGNMENT_ROLE_PENDING =
  'add-management-users-assignment-role-loading';

export const STATUS_REQUEST_APPROVAL_INVITATION_MANAGEMENT_USERS_ROLE_SUCCESS =
  'approval-invitation-management-users-success';
export const STATUS_REQUEST_APPROVAL_INVITATION_MANAGEMENT_USERS_ROLE_FAILED =
  'approval-invitation-management-users-failed';
export const STATUS_REQUEST_APPROVAL_INVITATION_MANAGEMENT_USERS_ROLE_PENDING =
  'approval-invitation-management-users-loading';

export const STATUS_REQUEST_INVITE_USER_MANAGEMENT_USERS_SUCCESS =
  'invite-user-management-users-success';
export const STATUS_REQUEST_INVITE_USER_MANAGEMENT_USERS_FAILED =
  'invite-user-management-users-failed';
export const STATUS_REQUEST_INVITE_USER_MANAGEMENT_USERS_PENDING =
  'invite-user-management-users-loading';

export const STATUS_REQUEST_DETAIL_USER_MANAGEMENT_USERS_SUCCESS =
  'details-user-management-users-success';
export const STATUS_REQUEST_DETAIL_USER_MANAGEMENT_USERS_FAILED =
  'details-user-management-users-failed';
export const STATUS_REQUEST_DETAIL_USER_MANAGEMENT_USERS_PENDING =
  'details-user-management-users-loading';

// management roles of status request
export const STATUS_REQUEST_LIST_MANAGEMENT_ROLES_SUCCESS = 'list-management-roles-success';
export const STATUS_REQUEST_LIST_MANAGEMENT_ROLES_FAILED = 'list-management-roles-failed';
export const STATUS_REQUEST_LIST_MANAGEMENT_ROLES_PENDING = 'list-management-roles-loading';

// journal entry of status request
export const STATUS_REQUEST_LIST_JOURNAL_ENTRY_SUCCESS = 'list-journal-entry-success';
export const STATUS_REQUEST_LIST_JOURNAL_ENTRY_FAILED = 'list-journal-entry-failed';
export const STATUS_REQUEST_LIST_JOURNAL_ENTRY_PENDING = 'list-journal-entry-loading';

export const STATUS_REQUEST_DETAIL_JOURNAL_ENTRY_SUCCESS = 'detail-journal-entry-success';
export const STATUS_REQUEST_DETAIL_JOURNAL_ENTRY_FAILED = 'detail-journal-entry-failed';
export const STATUS_REQUEST_DETAIL_JOURNAL_ENTRY_PENDING = 'detail-journal-entry-loading';

export const STATUS_REQUEST_ADD_JOURNAL_ENTRY_SUCCESS = 'add-journal-entry-success';
export const STATUS_REQUEST_ADD_JOURNAL_ENTRY_FAILED = 'add-journal-entry-failed';
export const STATUS_REQUEST_ADD_JOURNAL_ENTRY_PENDING = 'add-journal-entry-loading';

export const STATUS_REQUEST_UPDATE_JOURNAL_ENTRY_SUCCESS = 'update-journal-entry-success';
export const STATUS_REQUEST_UPDATE_JOURNAL_ENTRY_FAILED = 'update-journal-entry-failed';
export const STATUS_REQUEST_UPDATE_JOURNAL_ENTRY_PENDING = 'update-journal-entry-loading';

export const STATUS_REQUEST_DELETE_JOURNAL_ENTRY_SUCCESS = 'delete-journal-entry-success';
export const STATUS_REQUEST_DELETE_JOURNAL_ENTRY_FAILED = 'delete-journal-entry-failed';
export const STATUS_REQUEST_DELETE_JOURNAL_ENTRY_PENDING = 'delete-journal-entry-loading';

const REQUEST_STATUS_IMPORT_JOURNAL_ENTRY_MAIN = 'import-journal-entry';
export const REQUEST_STATUS_IMPORT_JOURNAL_ENTRY = sliceReduceHelper.generateRequestStatus(
  REQUEST_STATUS_IMPORT_JOURNAL_ENTRY_MAIN,
);

const REQUEST_STATUS_IMPORT_JOURNAL_ENTRY_PROGRESS_MAIN = 'import-journal-entry-progress';
export const REQUEST_STATUS_IMPORT_JOURNAL_ENTRY_PROGRESS = sliceReduceHelper.generateRequestStatus(
  REQUEST_STATUS_IMPORT_JOURNAL_ENTRY_PROGRESS_MAIN,
);

// contact of status request
export const STATUS_REQUEST_LIST_CONTACT_DATA_SUCCESS = 'list-contact-data-success';
export const STATUS_REQUEST_LIST_CONTACT_DATA_FAILED = 'list-contact-data-failed';
export const STATUS_REQUEST_LIST_CONTACT_DATA_PENDING = 'list-contact-data-loading';

export const REQUEST_STATUS_LIST_CONTACT_DATA_PRINT_PDF_SUCCESS =
  'list-contact-data-print-pdf-success';
export const REQUEST_STATUS_LIST_CONTACT_DATA_PRINT_PDF_FAILED =
  'list-contact-data-print-pdf-failed';
export const REQUEST_STATUS_LIST_CONTACT_DATA_PRINT_PDF_PENDING =
  'list-contact-data-print-pdf-loading';

export const STATUS_REQUEST_DETAIL_CONTACT_DATA_SUCCESS = 'detail-contact-data-success';
export const STATUS_REQUEST_DETAIL_CONTACT_DATA_FAILED = 'detail-contact-data-failed';
export const STATUS_REQUEST_DETAIL_CONTACT_DATA_PENDING = 'detail-contact-data-loading';

export const STATUS_REQUEST_ADD_CONTACT_DATA_SUCCESS = 'add-contact-data-success';
export const STATUS_REQUEST_ADD_CONTACT_DATA_FAILED = 'add-contact-data-failed';
export const STATUS_REQUEST_ADD_CONTACT_DATA_PENDING = 'add-contact-data-loading';

export const STATUS_REQUEST_UPDATE_CONTACT_DATA_SUCCESS = 'update-contact-data-success';
export const STATUS_REQUEST_UPDATE_CONTACT_DATA_FAILED = 'update-contact-data-failed';
export const STATUS_REQUEST_UPDATE_CONTACT_DATA_PENDING = 'update-contact-data-loading';

export const STATUS_REQUEST_DELETE_CONTACT_DATA_SUCCESS = 'delete-contact-data-success';
export const STATUS_REQUEST_DELETE_CONTACT_DATA_FAILED = 'delete-contact-data-failed';
export const STATUS_REQUEST_DELETE_CONTACT_DATA_PENDING = 'delete-contact-data-loading';

export const STATUS_REQUEST_LIST_PURCHASE_INVOCE_ON_CONTACT_DATA_SUCCESS =
  'list-purchase-invoice-contact-data-success';
export const STATUS_REQUEST_LIST_PURCHASE_INVOCE_ON_CONTACT_DATA_FAILED =
  'list-purchase-invoice-contact-data-failed';
export const STATUS_REQUEST_LIST_PURCHASE_INVOCE_ON_CONTACT_DATA_PENDING =
  'list-purchase-invoice-contact-data-loading';

export const STATUS_REQUEST_LIST_SALES_INVOICE_ON_CONTACT_DATA_SUCCESS =
  'list-sales-invoice-contact-data-success';
export const STATUS_REQUEST_LIST_SALES_INVOICE_ON_CONTACT_DATA_FAILED =
  'list-sales-invoice-contact-data-failed';
export const STATUS_REQUEST_LIST_SALES_INVOICE_ON_CONTACT_DATA_PENDING =
  'list-sales-invoice-contact-data-loading';

// reference number of status request
export const STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_SUCCESS =
  'list-reference-number-data-success';
export const STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_FAILED = 'list-reference-number-data-failed';
export const STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_PENDING =
  'list-reference-number-data-loading';

export const STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_SUCCESS =
  'detail-reference-number-data-success';
export const STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_FAILED =
  'detail-reference-number-data-failed';
export const STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_PENDING =
  'detail-reference-number-data-loading';

export const STATUS_REQUEST_ADD_REFERENCE_NUMBER_DATA_SUCCESS = 'add-reference-number-data-success';
export const STATUS_REQUEST_ADD_REFERENCE_NUMBER_DATA_FAILED = 'add-reference-number-data-failed';
export const STATUS_REQUEST_ADD_REFERENCE_NUMBER_DATA_PENDING = 'add-reference-number-data-loading';

export const STATUS_REQUEST_UPDATE_REFERENCE_NUMBER_DATA_SUCCESS =
  'update-reference-number-data-success';
export const STATUS_REQUEST_UPDATE_REFERENCE_NUMBER_DATA_FAILED =
  'update-reference-number-data-failed';
export const STATUS_REQUEST_UPDATE_REFERENCE_NUMBER_DATA_PENDING =
  'update-reference-number-data-loading';

export const STATUS_REQUEST_DELETE_REFERENCE_NUMBER_DATA_SUCCESS =
  'delete-reference-number-data-success';
export const STATUS_REQUEST_DELETE_REFERENCE_NUMBER_DATA_FAILED =
  'delete-reference-number-data-failed';
export const STATUS_REQUEST_DELETE_REFERENCE_NUMBER_DATA_PENDING =
  'delete-reference-number-data-loading';

// province of status request
export const STATUS_REQUEST_LIST_PROVINCE_SUCCESS = 'list-province-success';
export const STATUS_REQUEST_LIST_PROVINCE_FAILED = 'list-province-failed';
export const STATUS_REQUEST_LIST_PROVINCE_PENDING = 'list-province-loading';

// regional province, city, subdistrict, and urban status request
export const STATUS_REQUEST_LIST_REGIONAL_PROVINCE_SUCCESS = 'list-regional-province-success';
export const STATUS_REQUEST_LIST_REGIONAL_PROVINCE_FAILED = 'list-regional-province-failed';
export const STATUS_REQUEST_LIST_REGIONAL_PROVINCE_PENDING = 'list-regional-province-loading';

export const STATUS_REQUEST_LIST_REGIONAL_CITY_SUCCESS = 'list-regional-city-success';
export const STATUS_REQUEST_LIST_REGIONAL_CITY_FAILED = 'list-regional-city-failed';
export const STATUS_REQUEST_LIST_REGIONAL_CITY_PENDING = 'list-regional-city-loading';

export const STATUS_REQUEST_LIST_REGIONAL_SUB_DISTRICT_SUCCESS =
  'list-regional-sub-district-success';
export const STATUS_REQUEST_LIST_REGIONAL_SUB_DISTRICT_FAILED = 'list-regional-sub-district-failed';
export const STATUS_REQUEST_LIST_REGIONAL_SUB_DISTRICT_PENDING =
  'list-regional-sub-district-loading';

export const STATUS_REQUEST_LIST_REGIONAL_URBAN_SUCCESS = 'list-regional-urban-success';
export const STATUS_REQUEST_LIST_REGIONAL_URBAN_FAILED = 'list-regional-urban-failed';
export const STATUS_REQUEST_LIST_REGIONAL_URBAN_PENDING = 'list-regional-urban-loading';

// indo regional of status request
export const STATUS_REQUEST_LIST_INDO_REGIONAL_SUCCESS = 'list-indo-regional-success';
export const STATUS_REQUEST_LIST_INDO_REGIONAL_FAILED = 'list-indo-regional-failed';
export const STATUS_REQUEST_LIST_INDO_REGIONAL_PENDING = 'list-indo-regional-loading';

// beginning balance of status request
export const STATUS_REQUEST_LIST_BEGINNING_BALANCE_SUCCESS = 'list-beginning-balance-success';
export const STATUS_REQUEST_LIST_BEGINNING_BALANCE_FAILED = 'list-beginning-balance-failed';
export const STATUS_REQUEST_LIST_BEGINNING_BALANCE_PENDING = 'list-beginning-balance-loading';

export const STATUS_REQUEST_CREATE_BEGINNING_BALANCE_SUCCESS = 'create-beginning-balance-success';
export const STATUS_REQUEST_CREATE_BEGINNING_BALANCE_FAILED = 'create-beginning-balance-failed';
export const STATUS_REQUEST_CREATE_BEGINNING_BALANCE_PENDING = 'create-beginning-balance-loading';

// bank data status request
export const STATUS_REQUEST_LIST_BANK_DATA_SUCCESS = 'list-bank-data-success';
export const STATUS_REQUEST_LIST_BANK_DATA_FAILED = 'list-bank-data-failed';
export const STATUS_REQUEST_LIST_BANK_DATA_PENDING = 'list-bank-data-pending';

// receipt cash of status request
export const STATUS_REQUEST_LIST_RECEIPT_CASH_SUCCESS = 'list-receipt-cash-success';
export const STATUS_REQUEST_LIST_RECEIPT_CASH_FAILED = 'list-receipt-cash-failed';
export const STATUS_REQUEST_LIST_RECEIPT_CASH_PENDING = 'list-receipt-cash-loading';

export const STATUS_REQUEST_DETAIL_RECEIPT_CASH_SUCCESS = 'detail-receipt-cash-success';
export const STATUS_REQUEST_DETAIL_RECEIPT_CASH_FAILED = 'detail-receipt-cash-failed';
export const STATUS_REQUEST_DETAIL_RECEIPT_CASH_PENDING = 'detail-receipt-cash-loading';

export const STATUS_REQUEST_ADD_RECEIPT_CASH_SUCCESS = 'add-receipt-cash-success';
export const STATUS_REQUEST_ADD_RECEIPT_CASH_FAILED = 'add-receipt-cash-failed';
export const STATUS_REQUEST_ADD_RECEIPT_CASH_PENDING = 'add-receipt-cash-loading';

export const STATUS_REQUEST_UPDATE_RECEIPT_CASH_SUCCESS = 'update-receipt-cash-success';
export const STATUS_REQUEST_UPDATE_RECEIPT_CASH_FAILED = 'update-receipt-cash-failed';
export const STATUS_REQUEST_UPDATE_RECEIPT_CASH_PENDING = 'update-receipt-cash-loading';

export const STATUS_REQUEST_DELETE_RECEIPT_CASH_SUCCESS = 'delete-receipt-cash-success';
export const STATUS_REQUEST_DELETE_RECEIPT_CASH_FAILED = 'delete-receipt-cash-failed';
export const STATUS_REQUEST_DELETE_RECEIPT_CASH_PENDING = 'delete-receipt-cash-loading';

// payment cash status request
export const STATUS_REQUEST_LIST_PAYMENT_CASH_SUCCESS = 'list-payment-cash-success';
export const STATUS_REQUEST_LIST_PAYMENT_CASH_FAILED = 'list-payment-cash-failed';
export const STATUS_REQUEST_LIST_PAYMENT_CASH_PENDING = 'list-payment-cash-loading';

export const STATUS_REQUEST_DETAIL_PAYMENT_CASH_SUCCESS = 'detail-payment-cash-success';
export const STATUS_REQUEST_DETAIL_PAYMENT_CASH_FAILED = 'detail-payment-cash-failed';
export const STATUS_REQUEST_DETAIL_PAYMENT_CASH_PENDING = 'detail-payment-cash-loading';

export const STATUS_REQUEST_ADD_PAYMENT_CASH_SUCCESS = 'add-payment-cash-success';
export const STATUS_REQUEST_ADD_PAYMENT_CASH_FAILED = 'add-payment-cash-failed';
export const STATUS_REQUEST_ADD_PAYMENT_CASH_PENDING = 'add-payment-cash-loading';

export const STATUS_REQUEST_UPDATE_PAYMENT_CASH_SUCCESS = 'update-payment-cash-success';
export const STATUS_REQUEST_UPDATE_PAYMENT_CASH_FAILED = 'update-payment-cash-failed';
export const STATUS_REQUEST_UPDATE_PAYMENT_CASH_PENDING = 'update-payment-cash-loading';

export const STATUS_REQUEST_DELETE_PAYMENT_CASH_SUCCESS = 'delete-payment-cash-success';
export const STATUS_REQUEST_DELETE_PAYMENT_CASH_FAILED = 'delete-payment-cash-failed';
export const STATUS_REQUEST_DELETE_PAYMENT_CASH_PENDING = 'delete-payment-cash-loading';

// cash transfer status request
export const STATUS_REQUEST_LIST_CASH_TRANSFER_SUCCESS = 'list-cash-transfer-success';
export const STATUS_REQUEST_LIST_CASH_TRANSFER_FAILED = 'list-cash-transfer-failed';
export const STATUS_REQUEST_LIST_CASH_TRANSFER_PENDING = 'list-cash-transfer-loading';

export const STATUS_REQUEST_DETAIL_CASH_TRANSFER_SUCCESS = 'detail-cash-transfer-success';
export const STATUS_REQUEST_DETAIL_CASH_TRANSFER_FAILED = 'detail-cash-transfer-failed';
export const STATUS_REQUEST_DETAIL_CASH_TRANSFER_PENDING = 'detail-cash-transfer-loading';

export const STATUS_REQUEST_ADD_CASH_TRANSFER_SUCCESS = 'add-cash-transfer-success';
export const STATUS_REQUEST_ADD_CASH_TRANSFER_FAILED = 'add-cash-transfer-failed';
export const STATUS_REQUEST_ADD_CASH_TRANSFER_PENDING = 'add-cash-transfer-loading';

export const STATUS_REQUEST_UPDATE_CASH_TRANSFER_SUCCESS = 'update-cash-transfer-success';
export const STATUS_REQUEST_UPDATE_CASH_TRANSFER_FAILED = 'update-cash-transfer-failed';
export const STATUS_REQUEST_UPDATE_CASH_TRANSFER_PENDING = 'update-cash-transfer-loading';

export const STATUS_REQUEST_DELETE_CASH_TRANSFER_SUCCESS = 'delete-cash-transfer-success';
export const STATUS_REQUEST_DELETE_CASH_TRANSFER_FAILED = 'delete-cash-transfer-failed';
export const STATUS_REQUEST_DELETE_CASH_TRANSFER_PENDING = 'delete-cash-transfer-loading';

// cash bank of status request
export const STATUS_REQUEST_LIST_CASH_BANK_SUCCESS = 'list-cash-bank-success';
export const STATUS_REQUEST_LIST_CASH_BANK_FAILED = 'list-cash-bank-failed';
export const STATUS_REQUEST_LIST_CASH_BANK_PENDING = 'list-cash-bank-loading';

export const STATUS_REQUEST_CALCULATE_CASH_BANK_SUCCESS = 'calculate-cash-bank-success';
export const STATUS_REQUEST_CALCULATE_CASH_BANK_FAILED = 'calculate-cash-bank-failed';
export const STATUS_REQUEST_CALCULATE_CASH_BANK_PENDING = 'calculate-cash-bank-loading';

// bank statements request status
export const REQUEST_STATUS_LIST_BANK_STATEMENTS =
  sliceReduceHelper.generateRequestStatus('list-bank-statements');
export const REQUEST_STATUS_IMPORT_BANK_STATEMENTS =
  sliceReduceHelper.generateRequestStatus('import-bank-statements');
export const REQUEST_STATUS_IMPORT_BANK_STATEMENTS_PROGRESS =
  sliceReduceHelper.generateRequestStatus('import-bank-statements-progress');

// account transaction of status request
export const STATUS_REQUEST_LIST_CASH_BANK_ACCOUNT_TRANSACTION_SUCCESS =
  'list-cash-bank-account-transaction-success';
export const STATUS_REQUEST_LIST_CASH_BANK_ACCOUNT_TRANSACTION_FAILED =
  'list-cash-bank-account-transaction-failed';
export const STATUS_REQUEST_LIST_CASH_BANK_ACCOUNT_TRANSACTION_PENDING =
  'list-cash-bank-account-transaction-loading';

// detail account transaction of status request
export const STATUS_REQUEST_DETAIL_CASH_BANK_ACCOUNT_TRANSACTION_SUCCESS =
  'detail-cash-bank-account-transaction-success';
export const STATUS_REQUEST_DETAIL_CASH_BANK_ACCOUNT_TRANSACTION_FAILED =
  'detail-cash-bank-account-transaction-failed';
export const STATUS_REQUEST_DETAIL_CASH_BANK_ACCOUNT_TRANSACTION_PENDING =
  'detail-cash-bank-account-transaction-loading';

// purchase quotation request of status request
export const STATUS_REQUEST_LIST_QUOTATION_REQUEST_SUCCESS = 'list-quotation-request-success';
export const STATUS_REQUEST_LIST_QUOTATION_REQUEST_FAILED = 'list-quotation-request-failed';
export const STATUS_REQUEST_LIST_QUOTATION_REQUEST_PENDING = 'list-quotation-request-loading';

export const STATUS_REQUEST_LIST_QUOTATION_REQUEST_PRINT_PDF_SUCCESS =
  'list-quotation-request-print-pdf-success';
export const STATUS_REQUEST_LIST_QUOTATION_REQUEST_PRINT_PDF_FAILED =
  'list-quotation-request-print-pdf-failed';
export const STATUS_REQUEST_LIST_QUOTATION_REQUEST_PRINT_PDF_PENDING =
  'list-quotation-request-print-pdf-loading';

export const STATUS_REQUEST_DETAIL_QUOTATION_REQUEST_SUCCESS = 'detail-quotation-request-success';
export const STATUS_REQUEST_DETAIL_QUOTATION_REQUEST_FAILED = 'detail-quotation-request-failed';
export const STATUS_REQUEST_DETAIL_QUOTATION_REQUEST_PENDING = 'detail-quotation-request-loading';

export const STATUS_REQUEST_ADD_QUOTATION_REQUEST_SUCCESS = 'add-quotation-request-success';
export const STATUS_REQUEST_ADD_QUOTATION_REQUEST_FAILED = 'add-quotation-request-failed';
export const STATUS_REQUEST_ADD_QUOTATION_REQUEST_PENDING = 'add-quotation-request-loading';

export const STATUS_REQUEST_UPDATE_QUOTATION_REQUEST_SUCCESS = 'update-quotation-request-success';
export const STATUS_REQUEST_UPDATE_QUOTATION_REQUEST_FAILED = 'update-quotation-request-failed';
export const STATUS_REQUEST_UPDATE_QUOTATION_REQUEST_PENDING = 'update-quotation-request-loading';

export const STATUS_REQUEST_DELETE_QUOTATION_REQUEST_SUCCESS = 'delete-quotation-request-success';
export const STATUS_REQUEST_DELETE_QUOTATION_REQUEST_FAILED = 'delete-quotation-request-failed';
export const STATUS_REQUEST_DELETE_QUOTATION_REQUEST_PENDING = 'delete-quotation-request-loading';

export const STATUS_REQUEST_HISTORY_QUOTATION_REQUEST_SUCCESS = 'history-quotation-request-success';
export const STATUS_REQUEST_HISTORY_QUOTATION_REQUEST_FAILED = 'history-quotation-request-failed';
export const STATUS_REQUEST_HISTORY_QUOTATION_REQUEST_PENDING = 'history-quotation-request-loading';

// purchase order request of status request
export const STATUS_REQUEST_LIST_PURCHASE_ORDER_SUCCESS = 'list-purchase-order-success';
export const STATUS_REQUEST_LIST_PURCHASE_ORDER_FAILED = 'list-purchase-order-failed';
export const STATUS_REQUEST_LIST_PURCHASE_ORDER_PENDING = 'list-purchase-order-loading';

export const STATUS_REQUEST_LIST_PURCHASE_ORDER_PRINT_PDF_SUCCESS =
  'list-purchase-order-print-pdf-success';
export const STATUS_REQUEST_LIST_PURCHASE_ORDER_PRINT_PDF_FAILED =
  'list-purchase-order-print-pdf-failed';
export const STATUS_REQUEST_LIST_PURCHASE_ORDER_PRINT_PDF_PENDING =
  'list-purchase-order-print-pdf-loading';

export const STATUS_REQUEST_DETAIL_PURCHASE_ORDER_SUCCESS = 'detail-purchase-order-success';
export const STATUS_REQUEST_DETAIL_PURCHASE_ORDER_FAILED = 'detail-purchase-order-failed';
export const STATUS_REQUEST_DETAIL_PURCHASE_ORDER_PENDING = 'detail-purchase-order-loading';

export const STATUS_REQUEST_ADD_PURCHASE_ORDER_SUCCESS = 'add-purchase-order-success';
export const STATUS_REQUEST_ADD_PURCHASE_ORDER_FAILED = 'add-purchase-order-failed';
export const STATUS_REQUEST_ADD_PURCHASE_ORDER_PENDING = 'add-purchase-order-loading';

export const STATUS_REQUEST_UPDATE_PURCHASE_ORDER_SUCCESS = 'update-purchase-order-success';
export const STATUS_REQUEST_UPDATE_PURCHASE_ORDER_FAILED = 'update-purchase-order-failed';
export const STATUS_REQUEST_UPDATE_PURCHASE_ORDER_PENDING = 'update-purchase-order-loading';

export const STATUS_REQUEST_DELETE_PURCHASE_ORDER_SUCCESS = 'delete-purchase-order-success';
export const STATUS_REQUEST_DELETE_PURCHASE_ORDER_FAILED = 'delete-purchase-order-failed';
export const STATUS_REQUEST_DELETE_PURCHASE_ORDER_PENDING = 'delete-purchase-order-loading';

export const STATUS_REQUEST_HISTORY_PURCHASE_ORDER_SUCCESS = 'history-purchase-order-success';
export const STATUS_REQUEST_HISTORY_PURCHASE_ORDER_FAILED = 'history-purchase-order-failed';
export const STATUS_REQUEST_HISTORY_PURCHASE_ORDER_PENDING = 'history-purchase-order-loading';

// purchase payment of status request
export const STATUS_REQUEST_LIST_PURCHASE_PAYMENT_SUCCESS = 'list-purchase-payment-success';
export const STATUS_REQUEST_LIST_PURCHASE_PAYMENT_FAILED = 'list-purchase-payment-failed';
export const STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PENDING = 'list-purchase-payment-loading';

export const STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PRINT_PDF_SUCCESS =
  'list-purchase-payment-print-pdf-success';
export const STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PRINT_PDF_FAILED =
  'list-purchase-payment-print-pdf-failed';
export const STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PRINT_PDF_PENDING =
  'list-purchase-payment-print-pdf-loading';

export const STATUS_REQUEST_DETAIL_PURCHASE_PAYMENT_SUCCESS = 'detail-purchase-payment-success';
export const STATUS_REQUEST_DETAIL_PURCHASE_PAYMENT_FAILED = 'detail-purchase-payment-failed';
export const STATUS_REQUEST_DETAIL_PURCHASE_PAYMENT_PENDING = 'detail-purchase-payment-loading';

export const STATUS_REQUEST_ADD_PURCHASE_PAYMENT_SUCCESS = 'add-purchase-payment-success';
export const STATUS_REQUEST_ADD_PURCHASE_PAYMENT_FAILED = 'add-purchase-payment-failed';
export const STATUS_REQUEST_ADD_PURCHASE_PAYMENT_PENDING = 'add-purchase-payment-loading';

export const STATUS_REQUEST_UPDATE_PURCHASE_PAYMENT_SUCCESS = 'update-purchase-payment-success';
export const STATUS_REQUEST_UPDATE_PURCHASE_PAYMENT_FAILED = 'update-purchase-payment-failed';
export const STATUS_REQUEST_UPDATE_PURCHASE_PAYMENT_PENDING = 'update-purchase-payment-loading';

export const STATUS_REQUEST_DELETE_PURCHASE_PAYMENT_SUCCESS = 'delete-purchase-payment-success';
export const STATUS_REQUEST_DELETE_PURCHASE_PAYMENT_FAILED = 'delete-purchase-payment-failed';
export const STATUS_REQUEST_DELETE_PURCHASE_PAYMENT_PENDING = 'delete-purchase-payment-loading';

export const STATUS_REQUEST_HISTORY_PURCHASE_PAYMENT_SUCCESS = 'history-purchase-payment-success';
export const STATUS_REQUEST_HISTORY_PURCHASE_PAYMENT_FAILED = 'history-purchase-payment-failed';
export const STATUS_REQUEST_HISTORY_PURCHASE_PAYMENT_PENDING = 'history-purchase-payment-loading';

// purchase invoice status request
export const STATUS_REQUEST_LIST_PURCHASE_INVOICE_SUCCESS = 'list-purchase-invoice-success';
export const STATUS_REQUEST_LIST_PURCHASE_INVOICE_FAILED = 'list-purchase-invoice-failed';
export const STATUS_REQUEST_LIST_PURCHASE_INVOICE_PENDING = 'list-purchase-invoice-loading';

export const STATUS_REQUEST_LIST_PURCHASE_INVOICE_PRINT_PDF_SUCCESS =
  'list-purchase-invoice-print-pdf-success';
export const STATUS_REQUEST_LIST_PURCHASE_INVOICE_PRINT_PDF_FAILED =
  'list-purchase-invoice-print-pdf-failed';
export const STATUS_REQUEST_LIST_PURCHASE_INVOICE_PRINT_PDF_PENDING =
  'list-purchase-invoice-print-pdf-loading';

export const STATUS_REQUEST_DETAIL_PURCHASE_INVOICE_SUCCESS = 'detail-purchase-invoice-success';
export const STATUS_REQUEST_DETAIL_PURCHASE_INVOICE_FAILED = 'detail-purchase-invoice-failed';
export const STATUS_REQUEST_DETAIL_PURCHASE_INVOICE_PENDING = 'detail-purchase-invoice-loading';

export const STATUS_REQUEST_ADD_PURCHASE_INVOICE_SUCCESS = 'add-purchase-invoice-success';
export const STATUS_REQUEST_ADD_PURCHASE_INVOICE_FAILED = 'add-purchase-invoice-failed';
export const STATUS_REQUEST_ADD_PURCHASE_INVOICE_PENDING = 'add-purchase-invoice-loading';

export const STATUS_REQUEST_UPDATE_PURCHASE_INVOICE_SUCCESS = 'update-purchase-invoice-success';
export const STATUS_REQUEST_UPDATE_PURCHASE_INVOICE_FAILED = 'update-purchase-invoice-failed';
export const STATUS_REQUEST_UPDATE_PURCHASE_INVOICE_PENDING = 'update-purchase-invoice-loading';

export const STATUS_REQUEST_DELETE_PURCHASE_INVOICE_SUCCESS = 'delete-purchase-invoice-success';
export const STATUS_REQUEST_DELETE_PURCHASE_INVOICE_FAILED = 'delete-purchase-invoice-failed';
export const STATUS_REQUEST_DELETE_PURCHASE_INVOICE_PENDING = 'delete-purchase-invoice-loading';

export const STATUS_REQUEST_HISTORY_PURCHASE_INVOICE_SUCCESS = 'history-purchase-invoice-success';
export const STATUS_REQUEST_HISTORY_PURCHASE_INVOICE_FAILED = 'history-purchase-invoice-failed';
export const STATUS_REQUEST_HISTORY_PURCHASE_INVOICE_PENDING = 'history-purchase-invoice-loading';

const REQUEST_STATUS_IMPORT_PURCHASE_INVOICE_MAIN = 'import-purchase-invoice';
export const REQUEST_STATUS_IMPORT_PURCHASE_INVOICE = sliceReduceHelper.generateRequestStatus(
  REQUEST_STATUS_IMPORT_PURCHASE_INVOICE_MAIN,
);

const REQUEST_STATUS_IMPORT_PURCHASE_INVOICE_PROGRESS_MAIN = 'import-purchase-invoice-progress';
export const REQUEST_STATUS_IMPORT_PURCHASE_INVOICE_PROGRESS =
  sliceReduceHelper.generateRequestStatus(REQUEST_STATUS_IMPORT_PURCHASE_INVOICE_PROGRESS_MAIN);

// purchase delivery of status request
export const STATUS_REQUEST_LIST_PURCHASE_DELIVERY_SUCCESS = 'list-purchase-delivery-success';
export const STATUS_REQUEST_LIST_PURCHASE_DELIVERY_FAILED = 'list-purchase-delivery-failed';
export const STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PENDING = 'list-purchase-delivery-loading';

export const STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PRINT_PDF_SUCCESS =
  'list-purchase-delivery-print-pdf-success';
export const STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PRINT_PDF_FAILED =
  'list-purchase-delivery-print-pdf-failed';
export const STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PRINT_PDF_PENDING =
  'list-purchase-delivery-print-pdf-loading';

export const STATUS_REQUEST_DETAIL_PURCHASE_DELIVERY_SUCCESS = 'detail-purchase-delivery-success';
export const STATUS_REQUEST_DETAIL_PURCHASE_DELIVERY_FAILED = 'detail-purchase-delivery-failed';
export const STATUS_REQUEST_DETAIL_PURCHASE_DELIVERY_PENDING = 'detail-purchase-delivery-loading';

export const STATUS_REQUEST_HISTORY_PURCHASE_DELIVERY_SUCCESS = 'history-purchase-delivery-success';
export const STATUS_REQUEST_HISTORY_PURCHASE_DELIVERY_FAILED = 'history-purchase-delivery-failed';
export const STATUS_REQUEST_HISTORY_PURCHASE_DELIVERY_PENDING = 'history-purchase-delivery-loading';

export const STATUS_REQUEST_ADD_PURCHASE_DELIVERY_SUCCESS = 'add-purchase-delivery-success';
export const STATUS_REQUEST_ADD_PURCHASE_DELIVERY_FAILED = 'add-purchase-delivery-failed';
export const STATUS_REQUEST_ADD_PURCHASE_DELIVERY_PENDING = 'add-purchase-delivery-loading';

export const STATUS_REQUEST_UPDATE_PURCHASE_DELIVERY_SUCCESS = 'update-purchase-delivery-success';
export const STATUS_REQUEST_UPDATE_PURCHASE_DELIVERY_FAILED = 'update-purchase-delivery-failed';
export const STATUS_REQUEST_UPDATE_PURCHASE_DELIVERY_PENDING = 'update-purchase-delivery-loading';

export const STATUS_REQUEST_DELETE_PURCHASE_DELIVERY_SUCCESS = 'delete-purchase-delivery-success';
export const STATUS_REQUEST_DELETE_PURCHASE_DELIVERY_FAILED = 'delete-purchase-delivery-failed';
export const STATUS_REQUEST_DELETE_PURCHASE_DELIVERY_PENDING = 'delete-purchase-delivery-loading';

// purchase debit note request status
export const STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_SUCCESS = 'list-purchase-debit-note-success';
export const STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_FAILED = 'list-purchase-debit-note-failed';
export const STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_PENDING = 'list-purchase-debit-note-loading';

export const STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_PRINT_PDF_SUCCESS =
  'list-purchase-debit-note-print-pdf-success';
export const STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_PRINT_PDF_FAILED =
  'list-purchase-debit-note-print-pdf-failed';
export const STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_PRINT_PDF_PENDING =
  'list-purchase-debit-note-print-pdf-loading';

export const STATUS_REQUEST_DETAILS_PURCHASE_DEBIT_NOTE_SUCCESS =
  'details-purchase-debit-note-success';
export const STATUS_REQUEST_DETAILS_PURCHASE_DEBIT_NOTE_FAILED =
  'details-purchase-debit-note-failed';
export const STATUS_REQUEST_DETAILS_PURCHASE_DEBIT_NOTE_PENDING =
  'details-purchase-debit-note-loading';

export const STATUS_REQUEST_HISTORY_PURCHASE_DEBIT_NOTE_SUCCESS =
  'history-purchase-debit-note-success';
export const STATUS_REQUEST_HISTORY_PURCHASE_DEBIT_NOTE_FAILED =
  'history-purchase-debit-note-failed';
export const STATUS_REQUEST_HISTORY_PURCHASE_DEBIT_NOTE_PENDING =
  'history-purchase-debit-note-loading';

// sales quotation of status request
export const STATUS_REQUEST_LIST_SALES_QUOTATION_SUCCESS = 'list-sales-quotation-success';
export const STATUS_REQUEST_LIST_SALES_QUOTATION_FAILED = 'list-sales-quotation-failed';
export const STATUS_REQUEST_LIST_SALES_QUOTATION_PENDING = 'list-sales-quotation-loading';

export const STATUS_REQUEST_LIST_SALES_QUOTATION_PRINT_PDF_SUCCESS =
  'list-sales-quotation-print-pdf-success';
export const STATUS_REQUEST_LIST_SALES_QUOTATION_PRINT_PDF_FAILED =
  'list-sales-quotation-print-pdf-failed';
export const STATUS_REQUEST_LIST_SALES_QUOTATION_PRINT_PDF_PENDING =
  'list-sales-quotation-print-pdf-loading';

export const STATUS_REQUEST_DETAIL_SALES_QUOTATION_SUCCESS = 'detail-sales-quotation-success';
export const STATUS_REQUEST_DETAIL_SALES_QUOTATION_FAILED = 'detail-sales-quotation-failed';
export const STATUS_REQUEST_DETAIL_SALES_QUOTATION_PENDING = 'detail-sales-quotation-loading';

export const STATUS_REQUEST_HISTORY_SALES_QUOTATION_SUCCESS = 'history-sales-quotation-success';
export const STATUS_REQUEST_HISTORY_SALES_QUOTATION_FAILED = 'history-sales-quotation-failed';
export const STATUS_REQUEST_HISTORY_SALES_QUOTATION_PENDING = 'history-sales-quotation-loading';

export const STATUS_REQUEST_ADD_SALES_QUOTATION_SUCCESS = 'add-sales-quotation-success';
export const STATUS_REQUEST_ADD_SALES_QUOTATION_FAILED = 'add-sales-quotation-failed';
export const STATUS_REQUEST_ADD_SALES_QUOTATION_PENDING = 'add-sales-quotation-loading';

export const STATUS_REQUEST_UPDATE_SALES_QUOTATION_SUCCESS = 'update-sales-quotation-success';
export const STATUS_REQUEST_UPDATE_SALES_QUOTATION_FAILED = 'update-sales-quotation-failed';
export const STATUS_REQUEST_UPDATE_SALES_QUOTATION_PENDING = 'update-sales-quotation-loading';

export const STATUS_REQUEST_DELETE_SALES_QUOTATION_SUCCESS = 'delete-sales-quotation-success';
export const STATUS_REQUEST_DELETE_SALES_QUOTATION_FAILED = 'delete-sales-quotation-failed';
export const STATUS_REQUEST_DELETE_SALES_QUOTATION_PENDING = 'delete-sales-quotation-loading';

// sales invoice of status request
export const STATUS_REQUEST_LIST_SALES_INVOICE_SUCCESS = 'list-sales-invoice-success';
export const STATUS_REQUEST_LIST_SALES_INVOICE_FAILED = 'list-sales-invoice-failed';
export const STATUS_REQUEST_LIST_SALES_INVOICE_PENDING = 'list-sales-invoice-loading';

export const STATUS_REQUEST_LIST_SALES_INVOICE_PRINT_PDF_SUCCESS =
  'list-sales-invoice-print-pdf-success';
export const STATUS_REQUEST_LIST_SALES_INVOICE_PRINT_PDF_FAILED =
  'list-sales-invoice-print-pdf-failed';
export const STATUS_REQUEST_LIST_SALES_INVOICE_PRINT_PDF_PENDING =
  'list-sales-invoice-print-pdf-loading';

export const STATUS_REQUEST_DETAIL_SALES_INVOICE_SUCCESS = 'detail-sales-invoice-success';
export const STATUS_REQUEST_DETAIL_SALES_INVOICE_FAILED = 'detail-sales-invoice-failed';
export const STATUS_REQUEST_DETAIL_SALES_INVOICE_PENDING = 'detail-sales-invoice-loading';

export const STATUS_REQUEST_ADD_SALES_INVOICE_SUCCESS = 'add-sales-invoice-success';
export const STATUS_REQUEST_ADD_SALES_INVOICE_FAILED = 'add-sales-invoice-failed';
export const STATUS_REQUEST_ADD_SALES_INVOICE_PENDING = 'add-sales-invoice-loading';

export const STATUS_REQUEST_UPDATE_SALES_INVOICE_SUCCESS = 'update-sales-invoice-success';
export const STATUS_REQUEST_UPDATE_SALES_INVOICE_FAILED = 'update-sales-invoice-failed';
export const STATUS_REQUEST_UPDATE_SALES_INVOICE_PENDING = 'update-sales-invoice-loading';

export const STATUS_REQUEST_DELETE_SALES_INVOICE_SUCCESS = 'delete-sales-invoice-success';
export const STATUS_REQUEST_DELETE_SALES_INVOICE_FAILED = 'delete-sales-invoice-failed';
export const STATUS_REQUEST_DELETE_SALES_INVOICE_PENDING = 'delete-sales-invoice-loading';

const REQUEST_STATUS_IMPORT_SALES_INVOICE_MAIN = 'import-sales-invoice';
export const REQUEST_STATUS_IMPORT_SALES_INVOICE = sliceReduceHelper.generateRequestStatus(
  REQUEST_STATUS_IMPORT_SALES_INVOICE_MAIN,
);

const REQUEST_STATUS_IMPORT_SALES_INVOICE_PROGRESS_MAIN = 'import-sales-invoice-progress';
export const REQUEST_STATUS_IMPORT_SALES_INVOICE_PROGRESS = sliceReduceHelper.generateRequestStatus(
  REQUEST_STATUS_IMPORT_SALES_INVOICE_PROGRESS_MAIN,
);

// sales order of status request
export const STATUS_REQUEST_LIST_SALES_ORDER_SUCCESS = 'list-sales-order-success';
export const STATUS_REQUEST_LIST_SALES_ORDER_FAILED = 'list-sales-order-failed';
export const STATUS_REQUEST_LIST_SALES_ORDER_PENDING = 'list-sales-order-loading';

export const STATUS_REQUEST_LIST_SALES_ORDER_PRINT_PDF_SUCCESS =
  'list-sales-order-print-pdf-success';
export const STATUS_REQUEST_LIST_SALES_ORDER_PRINT_PDF_FAILED = 'list-sales-order-print-pdf-failed';
export const STATUS_REQUEST_LIST_SALES_ORDER_PRINT_PDF_PENDING =
  'list-sales-order-print-pdf-loading';

export const STATUS_REQUEST_DETAIL_SALES_ORDER_SUCCESS = 'detail-sales-order-success';
export const STATUS_REQUEST_DETAIL_SALES_ORDER_FAILED = 'detail-sales-order-failed';
export const STATUS_REQUEST_DETAIL_SALES_ORDER_PENDING = 'detail-sales-order-loading';

export const STATUS_REQUEST_DETAIL_HISTORY_SALES_ORDER_SUCCESS =
  'history-detail-sales-order-success';
export const STATUS_REQUEST_DETAIL_HISTORY_SALES_ORDER_FAILED = 'history-detail-sales-order-failed';
export const STATUS_REQUEST_DETAIL_HISTORY_SALES_ORDER_PENDING =
  'history-detail-sales-order-loading';

export const STATUS_REQUEST_ADD_SALES_ORDER_SUCCESS = 'add-sales-order-success';
export const STATUS_REQUEST_ADD_SALES_ORDER_FAILED = 'add-sales-order-failed';
export const STATUS_REQUEST_ADD_SALES_ORDER_PENDING = 'add-sales-order-loading';

export const STATUS_REQUEST_UPDATE_SALES_ORDER_SUCCESS = 'update-sales-order-success';
export const STATUS_REQUEST_UPDATE_SALES_ORDER_FAILED = 'update-sales-order-failed';
export const STATUS_REQUEST_UPDATE_SALES_ORDER_PENDING = 'update-sales-order-loading';

export const STATUS_REQUEST_DELETE_SALES_ORDER_SUCCESS = 'delete-sales-order-success';
export const STATUS_REQUEST_DELETE_SALES_ORDER_FAILED = 'delete-sales-order-failed';
export const STATUS_REQUEST_DELETE_SALES_ORDER_PENDING = 'delete-sales-order-loading';

export const STATUS_REQUEST_HISTORY_SALES_INVOICE_SUCCESS = 'history-sales-invoice-success';
export const STATUS_REQUEST_HISTORY_SALES_INVOICE_FAILED = 'history-sales-invoice-failed';
export const STATUS_REQUEST_HISTORY_SALES_INVOICE_PENDING = 'history-sales-invoice-loading';

// sales delivery and order of status request
export const STATUS_REQUEST_LIST_SALES_DELIVERY_AND_ORDER_SUCCESS =
  'list-sales-delivery-and-order-success';
export const STATUS_REQUEST_LIST_SALES_DELIVERY_AND_ORDER_FAILED =
  'list-sales-delivery-and-order-failed';
export const STATUS_REQUEST_LIST_SALES_DELIVERY_AND_ORDER_PENDING =
  'list-sales-delivery-and-order-loading';

// sales delivery of status request
export const STATUS_REQUEST_LIST_SALES_DELIVERY_SUCCESS = 'list-sales-delivery-success';
export const STATUS_REQUEST_LIST_SALES_DELIVERY_FAILED = 'list-sales-delivery-failed';
export const STATUS_REQUEST_LIST_SALES_DELIVERY_PENDING = 'list-sales-delivery-loading';

export const STATUS_REQUEST_LIST_SALES_DELIVERY_PRINT_PDF_SUCCESS =
  'list-sales-delivery-print-pdf-success';
export const STATUS_REQUEST_LIST_SALES_DELIVERY_PRINT_PDF_FAILED =
  'list-sales-delivery-print-pdf-failed';
export const STATUS_REQUEST_LIST_SALES_DELIVERY_PRINT_PDF_PENDING =
  'list-sales-delivery-print-pdf-loading';

export const STATUS_REQUEST_DETAIL_SALES_DELIVERY_SUCCESS = 'detail-sales-delivery-success';
export const STATUS_REQUEST_DETAIL_SALES_DELIVERY_FAILED = 'detail-sales-delivery-failed';
export const STATUS_REQUEST_DETAIL_SALES_DELIVERY_PENDING = 'detail-sales-delivery-loading';

export const STATUS_REQUEST_DETAIL_HISTORY_SALES_DELIVERY_SUCCESS =
  'history-detail-sales-delivery-success';
export const STATUS_REQUEST_DETAIL_HISTORY_SALES_DELIVERY_FAILED =
  'history-detail-sales-delivery-failed';
export const STATUS_REQUEST_DETAIL_HISTORY_SALES_DELIVERY_PENDING =
  'history-detail-sales-delivery-loading';

export const STATUS_REQUEST_ADD_SALES_DELIVERY_SUCCESS = 'add-sales-delivery-success';
export const STATUS_REQUEST_ADD_SALES_DELIVERY_FAILED = 'add-sales-delivery-failed';
export const STATUS_REQUEST_ADD_SALES_DELIVERY_PENDING = 'add-sales-delivery-loading';

export const STATUS_REQUEST_UPDATE_SALES_DELIVERY_SUCCESS = 'update-sales-delivery-success';
export const STATUS_REQUEST_UPDATE_SALES_DELIVERY_FAILED = 'update-sales-delivery-failed';
export const STATUS_REQUEST_UPDATE_SALES_DELIVERY_PENDING = 'update-sales-delivery-loading';

export const STATUS_REQUEST_DELETE_SALES_DELIVERY_SUCCESS = 'delete-sales-delivery-success';
export const STATUS_REQUEST_DELETE_SALES_DELIVERY_FAILED = 'delete-sales-delivery-failed';
export const STATUS_REQUEST_DELETE_SALES_DELIVERY_PENDING = 'delete-sales-delivery-loading';

// sales payment of status request
export const STATUS_REQUEST_LIST_SALES_PAYMENT_SUCCESS = 'list-sales-payment-success';
export const STATUS_REQUEST_LIST_SALES_PAYMENT_FAILED = 'list-sales-payment-failed';
export const STATUS_REQUEST_LIST_SALES_PAYMENT_PENDING = 'list-sales-payment-loading';

export const STATUS_REQUEST_LIST_SALES_PAYMENT_PRINT_PDF_SUCCESS =
  'list-sales-payment-print-pdf-success';
export const STATUS_REQUEST_LIST_SALES_PAYMENT_PRINT_PDF_FAILED =
  'list-sales-payment-print-pdf-failed';
export const STATUS_REQUEST_LIST_SALES_PAYMENT_PRINT_PDF_PENDING =
  'list-sales-payment-print-pdf-loading';

export const STATUS_REQUEST_DETAIL_SALES_PAYMENT_SUCCESS = 'detail-sales-payment-success';
export const STATUS_REQUEST_DETAIL_SALES_PAYMENT_FAILED = 'detail-sales-payment-failed';
export const STATUS_REQUEST_DETAIL_SALES_PAYMENT_PENDING = 'detail-sales-payment-loading';

export const STATUS_REQUEST_HISTORY_SALES_PAYMENT_SUCCESS = 'history-sales-payment-success';
export const STATUS_REQUEST_HISTORY_SALES_PAYMENT_FAILED = 'history-sales-payment-failed';
export const STATUS_REQUEST_HISTORY_SALES_PAYMENT_PENDING = 'history-sales-payment-loading';

export const STATUS_REQUEST_ADD_SALES_PAYMENT_SUCCESS = 'add-sales-payment-success';
export const STATUS_REQUEST_ADD_SALES_PAYMENT_FAILED = 'add-sales-payment-failed';
export const STATUS_REQUEST_ADD_SALES_PAYMENT_PENDING = 'add-sales-payment-loading';

export const STATUS_REQUEST_UPDATE_SALES_PAYMENT_SUCCESS = 'update-sales-payment-success';
export const STATUS_REQUEST_UPDATE_SALES_PAYMENT_FAILED = 'update-sales-payment-failed';
export const STATUS_REQUEST_UPDATE_SALES_PAYMENT_PENDING = 'update-sales-payment-loading';

export const STATUS_REQUEST_DELETE_SALES_PAYMENT_SUCCESS = 'delete-sales-payment-success';
export const STATUS_REQUEST_DELETE_SALES_PAYMENT_FAILED = 'delete-sales-payment-failed';
export const STATUS_REQUEST_DELETE_SALES_PAYMENT_PENDING = 'delete-sales-payment-loading';

// asset category of status request
export const STATUS_REQUEST_LIST_ASSET_CATEGORY_SUCCESS = 'list-asset-category-success';
export const STATUS_REQUEST_LIST_ASSET_CATEGORY_FAILED = 'list-asset-category-failed';
export const STATUS_REQUEST_LIST_ASSET_CATEGORY_PENDING = 'list-asset-category-loading';

export const REQUEST_STATUS_LIST_ASSET_CATEGORY_PRINT_PDF_SUCCESS =
  'list-asset-category-print-pdf-success';
export const REQUEST_STATUS_LIST_ASSET_CATEGORY_PRINT_PDF_FAILED =
  'list-asset-category-print-pdf-failed';
export const REQUEST_STATUS_LIST_ASSET_CATEGORY_PRINT_PDF_PENDING =
  'list-asset-category-print-pdf-loading';

export const STATUS_REQUEST_DETAIL_ASSET_CATEGORY_SUCCESS = 'detail-asset-category-success';
export const STATUS_REQUEST_DETAIL_ASSET_CATEGORY_FAILED = 'detail-asset-category-failed';
export const STATUS_REQUEST_DETAIL_ASSET_CATEGORY_PENDING = 'detail-asset-category-loading';

export const STATUS_REQUEST_ADD_ASSET_CATEGORY_SUCCESS = 'add-asset-category-success';
export const STATUS_REQUEST_ADD_ASSET_CATEGORY_FAILED = 'add-asset-category-failed';
export const STATUS_REQUEST_ADD_ASSET_CATEGORY_PENDING = 'add-asset-category-loading';

export const STATUS_REQUEST_UPDATE_ASSET_CATEGORY_SUCCESS = 'update-asset-category-success';
export const STATUS_REQUEST_UPDATE_ASSET_CATEGORY_FAILED = 'update-asset-category-failed';
export const STATUS_REQUEST_UPDATE_ASSET_CATEGORY_PENDING = 'update-asset-category-loading';

export const STATUS_REQUEST_DELETE_ASSET_CATEGORY_SUCCESS = 'delete-asset-category-success';
export const STATUS_REQUEST_DELETE_ASSET_CATEGORY_FAILED = 'delete-asset-category-failed';
export const STATUS_REQUEST_DELETE_ASSET_CATEGORY_PENDING = 'delete-asset-category-loading';

// asset management of status request
export const STATUS_REQUEST_LIST_ASSET_MANAGEMENT_SUCCESS = 'list-asset-management-success';
export const STATUS_REQUEST_LIST_ASSET_MANAGEMENT_FAILED = 'list-asset-management-failed';
export const STATUS_REQUEST_LIST_ASSET_MANAGEMENT_PENDING = 'list-asset-management-loading';

export const REQUEST_STATUS_LIST_ASSET_MANAGEMENT_PRINT_PDF_SUCCESS =
  'list-asset-management-print-pdf-success';
export const REQUEST_STATUS_LIST_ASSET_MANAGEMENT_PRINT_PDF_FAILED =
  'list-asset-management-print-pdf-failed';
export const REQUEST_STATUS_LIST_ASSET_MANAGEMENT_PRINT_PDF_PENDING =
  'list-asset-management-print-pdf-loading';

export const STATUS_REQUEST_DETAIL_ASSET_MANAGEMENT_SUCCESS = 'detail-asset-management-success';
export const STATUS_REQUEST_DETAIL_ASSET_MANAGEMENT_FAILED = 'detail-asset-management-failed';
export const STATUS_REQUEST_DETAIL_ASSET_MANAGEMENT_PENDING = 'detail-asset-management-loading';

export const STATUS_REQUEST_DELETE_ASSET_MANAGEMENT_SUCCESS = 'delete-asset-management-success';
export const STATUS_REQUEST_DELETE_ASSET_MANAGEMENT_FAILED = 'delete-asset-management-failed';
export const STATUS_REQUEST_DELETE_ASSET_MANAGEMENT_PENDING = 'delete-asset-management-loading';

export const STATUS_REQUEST_ADD_ASSET_MANAGEMENT_SUCCESS = 'add-asset-management-success';
export const STATUS_REQUEST_ADD_ASSET_MANAGEMENT_FAILED = 'add-asset-management-failed';
export const STATUS_REQUEST_ADD_ASSET_MANAGEMENT_PENDING = 'add-asset-management-loading';

export const STATUS_REQUEST_UPDATE_ASSET_MANAGEMENT_SUCCESS = 'update-asset-management-success';
export const STATUS_REQUEST_UPDATE_ASSET_MANAGEMENT_FAILED = 'update-asset-management-failed';
export const STATUS_REQUEST_UPDATE_ASSET_MANAGEMENT_PENDING = 'update-asset-management-loading';

export const STATUS_REQUEST_SALE_ASSET_MANAGEMENT_SUCCESS = 'sale-asset-management-success';
export const STATUS_REQUEST_SALE_ASSET_MANAGEMENT_FAILED = 'sale-asset-management-failed';
export const STATUS_REQUEST_SALE_ASSET_MANAGEMENT_PENDING = 'sale-asset-management-loading';

export const STATUS_REQUEST_REVERT_ASSET_MANAGEMENT_SUCCESS = 'revert-asset-management-success';
export const STATUS_REQUEST_REVERT_ASSET_MANAGEMENT_FAILED = 'revert-asset-management-failed';
export const STATUS_REQUEST_REVERT_ASSET_MANAGEMENT_PENDING = 'revert-asset-management-loading';

// report index of status request
export const STATUS_REQUEST_LIST_REPORT_INDEX_SUCCESS = 'list-report-index-success';
export const STATUS_REQUEST_LIST_REPORT_INDEX_FAILED = 'list-report-index-failed';
export const STATUS_REQUEST_LIST_REPORT_INDEX_PENDING = 'list-report-index-loading';

export const STATUS_REQUEST_EXPORT_DOCUMENT_REPORT_SUCCESS = 'export-document-report-success';
export const STATUS_REQUEST_EXPORT_DOCUMENT_REPORT_FAILED = 'export-document-report-failes';
export const STATUS_REQUEST_EXPORT_DOCUMENT_REPORT_PENDING = 'export-document-report-loading';

// report journal of status request
export const STATUS_REQUEST_LIST_REPORT_JOURNAL_SUCCESS = 'list-report-journal-success';
export const STATUS_REQUEST_LIST_REPORT_JOURNAL_FAILED = 'list-report-journal-failed';
export const STATUS_REQUEST_LIST_REPORT_JOURNAL_PENDING = 'list-report-journal-loading';

// report income statement of status request
export const STATUS_REQUEST_LIST_REPORT_INCOME_STATEMENT_SUCCESS =
  'list-report-income-statement-success';
export const STATUS_REQUEST_LIST_REPORT_INCOME_STATEMENT_FAILED =
  'list-report-income-statement-failed';
export const STATUS_REQUEST_LIST_REPORT_INCOME_STATEMENT_PENDING =
  'list-report-income-statement-loading';

// report balance sheet of status request
export const STATUS_REQUEST_LIST_REPORT_BALANCE_SHEET_SUCCESS = 'list-report-balance-sheet-success';
export const STATUS_REQUEST_LIST_REPORT_BALANCE_SHEET_FAILED = 'list-report-balance-sheet-failed';
export const STATUS_REQUEST_LIST_REPORT_BALANCE_SHEET_PENDING = 'list-report-balance-sheet-loading';

// report capital change of status request
export const STATUS_REQUEST_LIST_REPORT_CAPITAL_CHANGE_SUCCESS =
  'list-report-capital-change-success';
export const STATUS_REQUEST_LIST_REPORT_CAPITAL_CHANGE_FAILED = 'list-report-capital-change-failed';
export const STATUS_REQUEST_LIST_REPORT_CAPITAL_CHANGE_PENDING =
  'list-report-capital-change-loading';

// report cash flow of status request
export const STATUS_REQUEST_LIST_REPORT_CASH_FLOW_SUCCESS = 'list-report-cash-flow-success';
export const STATUS_REQUEST_LIST_REPORT_CASH_FLOW_FAILED = 'list-report-cash-flow-failed';
export const STATUS_REQUEST_LIST_REPORT_CASH_FLOW_PENDING = 'list-report-cash-flow-loading';

// report general ledger of status request
export const STATUS_REQUEST_LIST_REPORT_GENERAL_LEDGER_SUCCESS =
  'list-report-general-ledger-success';
export const STATUS_REQUEST_LIST_REPORT_GENERAL_LEDGER_FAILED = 'list-report-general-ledger-failed';
export const STATUS_REQUEST_LIST_REPORT_GENERAL_LEDGER_PENDING =
  'list-report-general-ledger-loading';

// report trial balance of status request
export const STATUS_REQUEST_LIST_REPORT_TRIAL_BALANCE_SUCCESS = 'list-report-trial-balance-success';
export const STATUS_REQUEST_LIST_REPORT_TRIAL_BALANCE_FAILED = 'list-report-trial-balance-failed';
export const STATUS_REQUEST_LIST_REPORT_TRIAL_BALANCE_PENDING = 'list-report-trial-balance-loading';

// settings warehouse data of status request
export const STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_SUCCESS =
  'list-settings-warehouse-data-success';
export const STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_FAILED =
  'list-settings-warehouse-data-failed';
export const STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_PENDING =
  'list-settings-warehouse-data-loading';

export const STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_PRODUCT_DATA_SUCCESS =
  'list-settings-warehouse-product-data-success';
export const STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_PRODUCT_DATA_FAILED =
  'list-settings-warehouse-product-data-failed';
export const STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_PRODUCT_DATA_PENDING =
  'list-settings-warehouse-product-data-loading';

export const STATUS_REQUEST_DETAIL_SETTINGS_WAREHOUSE_DATA_SUCCESS =
  'detail-settings-warehouse-data-success';
export const STATUS_REQUEST_DETAIL_SETTINGS_WAREHOUSE_DATA_FAILED =
  'detail-settings-warehouse-data-failed';
export const STATUS_REQUEST_DETAIL_SETTINGS_WAREHOUSE_DATA_PENDING =
  'detail-settings-warehouse-data-loading';

export const STATUS_REQUEST_ADD_SETTINGS_WAREHOUSE_DATA_SUCCESS =
  'add-settings-warehouse-data-success';
export const STATUS_REQUEST_ADD_SETTINGS_WAREHOUSE_DATA_FAILED =
  'add-settings-warehouse-data-failed';
export const STATUS_REQUEST_ADD_SETTINGS_WAREHOUSE_DATA_PENDING =
  'add-settings-warehouse-data-loading';

export const STATUS_REQUEST_UPDATE_SETTINGS_WAREHOUSE_DATA_SUCCESS =
  'update-settings-warehouse-data-success';
export const STATUS_REQUEST_UPDATE_SETTINGS_WAREHOUSE_DATA_FAILED =
  'update-settings-warehouse-data-failed';
export const STATUS_REQUEST_UPDATE_SETTINGS_WAREHOUSE_DATA_PENDING =
  'update-settings-warehouse-data-loading';

// closing book data of status request
export const STATUS_REQUEST_LIST_CLOSING_BOOK_DATA_SUCCESS = 'list-closing-book-data-success';
export const STATUS_REQUEST_LIST_CLOSING_BOOK_DATA_FAILED = 'list-closing-book-data-failed';
export const STATUS_REQUEST_LIST_CLOSING_BOOK_DATA_PENDING = 'list-closing-book-data-loading';

export const STATUS_REQUEST_REVERT_CLOSING_BOOK_DATA_SUCCESS = 'revert-closing-book-data-success';
export const STATUS_REQUEST_REVERT_CLOSING_BOOK_DATA_FAILED = 'revert-closing-book-data-failed';
export const STATUS_REQUEST_REVERT_CLOSING_BOOK_DATA_PENDING = 'revert-closing-book-data-loading';

export const STATUS_REQUEST_PROCESS_CLOSING_BOOK_DATA_SUCCESS = 'process-closing-book-data-success';
export const STATUS_REQUEST_PROCESS_CLOSING_BOOK_DATA_FAILED = 'process-closing-book-data-failed';
export const STATUS_REQUEST_PROCESS_CLOSING_BOOK_DATA_PENDING = 'process-closing-book-data-loading';

export const STATUS_REQUEST_DETAILS_CLOSING_BOOK_DATA_SUCCESS = 'detail-closing-book-data-success';
export const STATUS_REQUEST_DETAILS_CLOSING_BOOK_DATA_FAILED = 'detail-closing-book-data-failed';
export const STATUS_REQUEST_DETAILS_CLOSING_BOOK_DATA_PENDING = 'detail-closing-book-data-loading';

export const STATUS_REQUEST_CREATE_CLOSING_BOOK_DATA_SUCCESS = 'create-closing-book-data-success';
export const STATUS_REQUEST_CREATE_CLOSING_BOOK_DATA_FAILED = 'create-closing-book-data-failed';
export const STATUS_REQUEST_CREATE_CLOSING_BOOK_DATA_PENDING = 'create-closing-book-data-loading';

// transaction of status request
export const STATUS_REQUEST_LIST_TRANSACTION_SUCCESS = 'list-transaction-data-success';
export const STATUS_REQUEST_LIST_TRANSACTION_FAILED = 'list-transaction-data-failed';
export const STATUS_REQUEST_LIST_TRANSACTION_PENDING = 'list-transaction-data-loading';

export const REQUEST_STATUS_LIST_TRANSACTION_PRINT_PDF_SUCCESS =
  'list-transaction-data-print-pdf-success';
export const REQUEST_STATUS_LIST_TRANSACTION_PRINT_PDF_FAILED =
  'list-transaction-data-print-pdf-failed';
export const REQUEST_STATUS_LIST_TRANSACTION_PRINT_PDF_PENDING =
  'list-transaction-data-print-pdf-loading';

export const STATUS_REQUEST_DETAILS_TRANSACTION_SUCCESS = 'detail-transaction-data-success';
export const STATUS_REQUEST_DETAILS_TRANSACTION_FAILED = 'detail-transaction-data-failed';
export const STATUS_REQUEST_DETAILS_TRANSACTION_PENDING = 'detail-transaction-data-loading';

// transfer warehouse/stock data of status request
export const STATUS_REQUEST_ADD_TRANSFER_WAREHOUSE_SUCCESS = 'add-transfer-warehouse-success';
export const STATUS_REQUEST_ADD_TRANSFER_WAREHOUSE_FAILED = 'add-transfer-warehouse-failed';
export const STATUS_REQUEST_ADD_TRANSFER_WAREHOUSE_PENDING = 'add-transfer-warehouse-loading';

export const STATUS_REQUEST_LIST_STOCK_TRANSFER_SUCCESS = 'list-stock-transfer-success';
export const STATUS_REQUEST_LIST_STOCK_TRANSFER_FAILED = 'list-stock-transfer-failed';
export const STATUS_REQUEST_LIST_STOCK_TRANSFER_PENDING = 'list-stock-transfer-loading';

export const REQUEST_STATUS_LIST_STOCK_TRANSFER_PRINT_PDF_SUCCESS =
  'list-stock-transfer-print-pdf-success';
export const REQUEST_STATUS_LIST_STOCK_TRANSFER_PRINT_PDF_FAILED =
  'list-stock-transfer-print-pdf-failed';
export const REQUEST_STATUS_LIST_STOCK_TRANSFER_PRINT_PDF_PENDING =
  'list-stock-transfer-print-pdf-loading';

export const STATUS_REQUEST_DETAIL_STOCK_TRANSFER_SUCCESS = 'detail-stock-transfer-success';
export const STATUS_REQUEST_DETAIL_STOCK_TRANSFER_FAILED = 'detail-stock-transfer-failed';
export const STATUS_REQUEST_DETAIL_STOCK_TRANSFER_PENDING = 'detail-stock-transfer-loading';

export const STATUS_REQUEST_DELETE_STOCK_TRANSFER_SUCCESS = 'delete-stock-transfer-success';
export const STATUS_REQUEST_DELETE_STOCK_TRANSFER_FAILED = 'delete-stock-transfer-failed';
export const STATUS_REQUEST_DELETE_STOCK_TRANSFER_PENDING = 'delete-stock-transfer-loading';

export const STATUS_REQUEST_UPDATE_TRANSFER_WAREHOUSE_SUCCESS = 'update-transfer-warehouse-success';
export const STATUS_REQUEST_UPDATE_TRANSFER_WAREHOUSE_FAILED = 'update-transfer-warehouse-failed';
export const STATUS_REQUEST_UPDATE_TRANSFER_WAREHOUSE_PENDING = 'update-transfer-warehouse-loading';

// stock adjustment of status request
export const STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_SUCCESS = 'list-stock-adjustment-data-success';
export const STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_FAILED = 'list-stock-adjustment-data-failed';
export const STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_PENDING = 'list-stock-adjustment-data-loading';

export const REQUEST_STATUS_LIST_STOCK_ADJUSTMENT_PRINT_PDF_SUCCESS =
  'list-stock-adjustment-data-print-pdf-success';
export const REQUEST_STATUS_LIST_STOCK_ADJUSTMENT_PRINT_PDF_FAILED =
  'list-stock-adjustment-data-print-pdf-failed';
export const REQUEST_STATUS_LIST_STOCK_ADJUSTMENT_PRINT_PDF_PENDING =
  'list-stock-adjustment-data-print-pdf-loading';

export const STATUS_REQUEST_DETAILS_STOCK_ADJUSTMENT_SUCCESS =
  'detail-stock-adjustment-data-success';
export const STATUS_REQUEST_DETAILS_STOCK_ADJUSTMENT_FAILED = 'detail-stock-adjustment-data-failed';
export const STATUS_REQUEST_DETAILS_STOCK_ADJUSTMENT_PENDING =
  'detail-stock-adjustment-data-loading';

export const STATUS_REQUEST_ADD_STOCK_ADJUSTMENT_SUCCESS = 'add-stock-adjustment-data-success';
export const STATUS_REQUEST_ADD_STOCK_ADJUSTMENT_FAILED = 'add-stock-adjustment-data-failed';
export const STATUS_REQUEST_ADD_STOCK_ADJUSTMENT_PENDING = 'add-stock-adjustment-data-loading';

export const STATUS_REQUEST_UPDATE_STOCK_ADJUSTMENT_SUCCESS =
  'update-stock-adjustment-data-success';
export const STATUS_REQUEST_UPDATE_STOCK_ADJUSTMENT_FAILED = 'update-stock-adjustment-data-failed';
export const STATUS_REQUEST_UPDATE_STOCK_ADJUSTMENT_PENDING =
  'update-stock-adjustment-data-loading';

export const STATUS_REQUEST_DELETE_STOCK_ADJUSTMENT_SUCCESS =
  'delete-stock-adjustment-data-success';
export const STATUS_REQUEST_DELETE_STOCK_ADJUSTMENT_FAILED = 'delete-stock-adjustment-data-failed';
export const STATUS_REQUEST_DELETE_STOCK_ADJUSTMENT_PENDING =
  'delete-stock-adjustment-data-loading';

// handover purchase delivery status request
export const STATUS_REQUEST_DETAILS_HANDOVER_PURCHASE_DELIVERY_SUCCESS =
  'detail-handover-purchase-delivery-data-success';
export const STATUS_REQUEST_DETAILS_HANDOVER_PURCHASE_DELIVERY_FAILED =
  'detail-handover-purchase-delivery-data-failed';
export const STATUS_REQUEST_DETAILS_HANDOVER_PURCHASE_DELIVERY_PENDING =
  'detail-handover-purchase-delivery-data-loading';

export const REQUEST_STATUS_HISTORY_HANDOVER_PURCHASE_DELIVERY_SUCCESS =
  'history-handover-purchase-delivery-data-success';
export const REQUEST_STATUS_HISTORY_HANDOVER_PURCHASE_DELIVERY_FAILED =
  'history-handover-purchase-delivery-data-failed';
export const REQUEST_STATUS_HISTORY_HANDOVER_PURCHASE_DELIVERY_PENDING =
  'history-handover-purchase-delivery-data-loading';

export const STATUS_REQUEST_ADD_HANDOVER_PURCHASE_DELIVERY_SUCCESS =
  'add-handover-purchase-delivery-data-success';
export const STATUS_REQUEST_ADD_HANDOVER_PURCHASE_DELIVERY_FAILED =
  'add-handover-purchase-delivery-data-failed';
export const STATUS_REQUEST_ADD_HANDOVER_PURCHASE_DELIVERY_PENDING =
  'add-handover-purchase-delivery-data-loading';

export const STATUS_REQUEST_UPDATE_HANDOVER_PURCHASE_DELIVERY_SUCCESS =
  'update-handover-purchase-delivery-data-success';
export const STATUS_REQUEST_UPDATE_HANDOVER_PURCHASE_DELIVERY_FAILED =
  'update-handover-purchase-delivery-data-failed';
export const STATUS_REQUEST_UPDATE_HANDOVER_PURCHASE_DELIVERY_PENDING =
  'update-handover-purchase-delivery-data-loading';

// handover sales delivery status request
export const STATUS_REQUEST_DETAILS_HANDOVER_SALES_DELIVERY_SUCCESS =
  'detail-handover-sales-delivery-data-success';
export const STATUS_REQUEST_DETAILS_HANDOVER_SALES_DELIVERY_FAILED =
  'detail-handover-sales-delivery-data-failed';
export const STATUS_REQUEST_DETAILS_HANDOVER_SALES_DELIVERY_PENDING =
  'detail-handover-sales-delivery-data-loading';

export const REQUEST_STATUS_HISTORY_HANDOVER_SALES_DELIVERY_SUCCESS =
  'history-handover-sales-delivery-data-success';
export const REQUEST_STATUS_HISTORY_HANDOVER_SALES_DELIVERY_FAILED =
  'history-handover-sales-delivery-data-failed';
export const REQUEST_STATUS_HISTORY_HANDOVER_SALES_DELIVERY_PENDING =
  'history-handover-sales-delivery-data-loading';

export const STATUS_REQUEST_ADD_HANDOVER_SALES_DELIVERY_SUCCESS =
  'add-handover-sales-delivery-data-success';
export const STATUS_REQUEST_ADD_HANDOVER_SALES_DELIVERY_FAILED =
  'add-handover-sales-delivery-data-failed';
export const STATUS_REQUEST_ADD_HANDOVER_SALES_DELIVERY_PENDING =
  'add-handover-sales-delivery-data-loading';

export const STATUS_REQUEST_UPDATE_HANDOVER_SALES_DELIVERY_SUCCESS =
  'update-handover-sales-delivery-data-success';
export const STATUS_REQUEST_UPDATE_HANDOVER_SALES_DELIVERY_FAILED =
  'update-handover-sales-delivery-data-failed';
export const STATUS_REQUEST_UPDATE_HANDOVER_SALES_DELIVERY_PENDING =
  'update-handover-sales-delivery-data-loading';

// application action status
export const ACTION_STATUS_SWITCH_APPLICATION_SUCCESS = 'switch-application-action-success';
export const ACTION_STATUS_SWITCH_APPLICATION_FAILED = 'switch-application-action-failed';
export const ACTION_STATUS_SWITCH_APPLICATION_PENDING = 'switch-application-action-loading';

export const ACTION_STATUS_HISTORY_CHANGES_HISTORY_SUCCESS =
  'history-changes-application-action-success';
export const ACTION_STATUS_HISTORY_CHANGES_HISTORY_FAILED =
  'history-changes-application-action-failed';
export const ACTION_STATUS_HISTORY_CHANGES_HISTORY_PENDING =
  'history-changes-application-action-loading';

// chart basic dashboard request status
export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_SUCCESS =
  'chart-basic-dashboard-purchase-success';
export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_FAILED =
  'chart-basic-dashboard-purchase-failed';
export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_PENDING =
  'chart-basic-dashboard-purchase-pending';

export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_SUCCESS =
  'chart-basic-dashboard-sales-success';
export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_FAILED =
  'chart-basic-dashboard-sales-failed';
export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_PENDING =
  'chart-basic-dashboard-sales-pending';

export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_PAYABLE_SUCCESS =
  'chart-basic-dashboard-payable-success';
export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_PAYABLE_FAILED =
  'chart-basic-dashboard-payable-failed';
export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_PAYABLE_PENDING =
  'chart-basic-dashboard-payable-pending';

export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_RECEIVABLE_SUCCESS =
  'chart-basic-dashboard-receivable-success';
export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_RECEIVABLE_FAILED =
  'chart-basic-dashboard-receivable-failed';
export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_RECEIVABLE_PENDING =
  'chart-basic-dashboard-receivable-pending';

export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE_SUCCESS =
  'chart-basic-dashboard-purchase-invoice-success';
export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE_FAILED =
  'chart-basic-dashboard-purchase-invoice-failed';
export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE_PENDING =
  'chart-basic-dashboard-purchase-invoice-pending';

export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_INVOICE_SUCCESS =
  'chart-basic-dashboard-sales-invoice-success';
export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_INVOICE_FAILED =
  'chart-basic-dashboard-sales-invoice-failed';
export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_INVOICE_PENDING =
  'chart-basic-dashboard-sales-invoice-pending';

export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_PRODUCT_STOCK_SUCCESS =
  'chart-basic-dashboard-product-stock-success';
export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_PRODUCT_STOCK_FAILED =
  'chart-basic-dashboard-product-stock-failed';
export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_PRODUCT_STOCK_PENDING =
  'chart-basic-dashboard-product-stock-pending';

export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_PERSON_SUCCESS =
  'chart-basic-dashboard-sales-person-success';
export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_PERSON_FAILED =
  'chart-basic-dashboard-sales-person-failed';
export const REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_PERSON_PENDING =
  'chart-basic-dashboard-sales-person-pending';

export const REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_SUCCESS =
  'chart-detail-sales-transaction-success';
export const REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_FAILED =
  'chart-detail-sales-transaction-failed';
export const REQUEST_STATUS_CHART_DETAILS_SALES_TRANSACTION_PENDING =
  'chart-detail-sales-transaction-pending';

export const REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_SUCCESS =
  'chart-detail-income-statement-success';
export const REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_FAILED =
  'chart-detail-income-statement-failed';
export const REQUEST_STATUS_CHART_DETAILS_INCOME_STATEMENT_PENDING =
  'chart-detail-income-statement-pending';

export const REQUEST_STATUS_CHART_LIST_COMPARISON_SALES_SUCCESS =
  'chart-list-comparison-sales-success';
export const REQUEST_STATUS_CHART_LIST_COMPARISON_SALES_FAILED =
  'chart-list-comparison-sales-failed';
export const REQUEST_STATUS_CHART_LIST_COMPARISON_SALES_PENDING =
  'chart-list-comparison-sales-pending';

export const REQUEST_STATUS_CHART_LIST_TOP_SALES_SALES_AGENT_SUCCESS =
  'chart-top-sales-sales-agent-success';
export const REQUEST_STATUS_CHART_LIST_TOP_SALES_SALES_AGENT_FAILED =
  'chart-top-sales-sales-agent-failed';
export const REQUEST_STATUS_CHART_LIST_TOP_SALES_SALES_AGENT_PENDING =
  'chart-top-sales-sales-agent-pending';

export const REQUEST_STATUS_CHART_LIST_TOP_SALES_CUSTOMER_SUCCESS =
  'chart-top-sales-customer-success';
export const REQUEST_STATUS_CHART_LIST_TOP_SALES_CUSTOMER_FAILED =
  'chart-top-sales-customer-failed';
export const REQUEST_STATUS_CHART_LIST_TOP_SALES_CUSTOMER_PENDING =
  'chart-top-sales-customer-pending';

export const REQUEST_STATUS_CHART_LIST_TOP_SALES_PRODUCT_SUCCESS =
  'chart-top-sales-product-success';
export const REQUEST_STATUS_CHART_LIST_TOP_SALES_PRODUCT_FAILED = 'chart-top-sales-product-failed';
export const REQUEST_STATUS_CHART_LIST_TOP_SALES_PRODUCT_PENDING =
  'chart-top-sales-product-pending';

export const REQUEST_STATUS_CHART_LIST_TOP_SALES_PRODUCT_CATEGORY_SUCCESS =
  'chart-top-sales-product-category-success';
export const REQUEST_STATUS_CHART_LIST_TOP_SALES_PRODUCT_CATEGORY_FAILED =
  'chart-top-sales-product-category-failed';
export const REQUEST_STATUS_CHART_LIST_TOP_SALES_PRODUCT_CATEGORY_PENDING =
  'chart-top-sales-product-category-pending';

export const REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_SALES_AGENT_SUCCESS =
  'chart-top-sales-comparison-sales-agent-success';
export const REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_SALES_AGENT_FAILED =
  'chart-top-sales-comparison-sales-agent-failed';
export const REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_SALES_AGENT_PENDING =
  'chart-top-sales-comparison-sales-agent-pending';

export const REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_CUSTOMER_SUCCESS =
  'chart-top-sales-comparison-customer-success';
export const REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_CUSTOMER_FAILED =
  'chart-top-sales-comparison-customer-failed';
export const REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_CUSTOMER_PENDING =
  'chart-top-sales-comparison-customer-pending';

export const REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_PRODUCT_SUCCESS =
  'chart-top-sales-comparison-product-success';
export const REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_PRODUCT_FAILED =
  'chart-top-sales-comparison-product-failed';
export const REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_PRODUCT_PENDING =
  'chart-top-sales-comparison-product-pending';

export const REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_PRODUCT_CATEGORY_SUCCESS =
  'chart-top-sales-comparison-product-category-success';
export const REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_PRODUCT_CATEGORY_FAILED =
  'chart-top-sales-comparison-product-category-failed';
export const REQUEST_STATUS_CHART_LIST_TOP_SALES_COMPARISON_PRODUCT_CATEGORY_PENDING =
  'chart-top-sales-comparison-product-category-pending';

export const REQUEST_STATUS_CHART_DASHBOARD_EXPENSE_SUCCESS = 'chart-dashboard-expense-success';
export const REQUEST_STATUS_CHART_DASHBOARD_EXPENSE_FAILED = 'chart-dashboard-expense-failed';
export const REQUEST_STATUS_CHART_DASHBOARD_EXPENSE_PENDING = 'chart-dashboard-expense-pending';

export const REQUEST_STATUS_CHART_PIVOT_TABLE_SUCCESS = 'chart-pivot-table-success';
export const REQUEST_STATUS_CHART_PIVOT_TABLE_FAILED = 'chart-pivot-table-failed';
export const REQUEST_STATUS_CHART_PIVOT_TABLE_PENDING = 'chart-pivot-table-pending';

// report basic purchase status request
export const STATUS_REQUEST_LIST_REPORT_BASIC_PURCHASE_SUCCESS =
  'list-report-basic-purchase-success';
export const STATUS_REQUEST_LIST_REPORT_BASIC_PURCHASE_FAILED = 'list-report-basic-purchase-failed';
export const STATUS_REQUEST_LIST_REPORT_BASIC_PURCHASE_PENDING =
  'list-report-basic-purchase-loading';

// report basic sales status request
export const STATUS_REQUEST_LIST_REPORT_BASIC_SALES_SUCCESS = 'list-report-basic-sales-success';
export const STATUS_REQUEST_LIST_REPORT_BASIC_SALES_FAILED = 'list-report-basic-sales-failed';
export const STATUS_REQUEST_LIST_REPORT_BASIC_SALES_PENDING = 'list-report-basic-sales-loading';

// report basic stock status request
export const STATUS_REQUEST_LIST_REPORT_BASIC_STOCK_SUCCESS = 'list-report-basic-stock-success';
export const STATUS_REQUEST_LIST_REPORT_BASIC_STOCK_FAILED = 'list-report-basic-stock-failed';
export const STATUS_REQUEST_LIST_REPORT_BASIC_STOCK_PENDING = 'list-report-basic-stock-loading';

// report basic price list status request
export const STATUS_REQUEST_LIST_REPORT_BASIC_PRICE_LIST_SUCCESS =
  'list-report-basic-price-list-success';
export const STATUS_REQUEST_LIST_REPORT_BASIC_PRICE_LIST_FAILED =
  'list-report-basic-price-list-failed';
export const STATUS_REQUEST_LIST_REPORT_BASIC_PRICE_LIST_PENDING =
  'list-report-basic-price-list-loading';

// report basic sales extraction status request
export const STATUS_REQUEST_LIST_REPORT_BASIC_SALES_EXTRACTION_SUCCESS =
  'list-report-basic-sales-extraction-success';
export const STATUS_REQUEST_LIST_REPORT_BASIC_SALES_EXTRACTION_FAILED =
  'list-report-basic-sales-extraction-failed';
export const STATUS_REQUEST_LIST_REPORT_BASIC_SALES_EXTRACTION_PENDING =
  'list-report-basic-sales-extraction-loading';

// report stock sales request
export const STATUS_REQUEST_LIST_REPORT_STOCK_SALES_SUCCESS = 'list-report-stock-sales-success';
export const STATUS_REQUEST_LIST_REPORT_STOCK_SALES_FAILED = 'list-report-stock-sales-failed';
export const STATUS_REQUEST_LIST_REPORT_STOCK_SALES_PENDING = 'list-report-stock-sales-loading';

// report stock card request
export const STATUS_REQUEST_LIST_REPORT_STOCK_CARD_SUCCESS = 'list-report-stock-card-success';
export const STATUS_REQUEST_LIST_REPORT_STOCK_CARD_FAILED = 'list-report-stock-card-failed';
export const STATUS_REQUEST_LIST_REPORT_STOCK_CARD_PENDING = 'list-report-stock-card-loading';

// report stock mutation request
export const STATUS_REQUEST_LIST_REPORT_STOCK_MUTATION_SUCCESS =
  'list-report-stock-mutation-success';
export const STATUS_REQUEST_LIST_REPORT_STOCK_MUTATION_FAILED = 'list-report-stock-mutation-failed';
export const STATUS_REQUEST_LIST_REPORT_STOCK_MUTATION_PENDING =
  'list-report-stock-mutation-loading';

// report stock purchase request
export const STATUS_REQUEST_LIST_REPORT_STOCK_PURCHASE_SUCCESS =
  'list-report-stock-purchase-success';
export const STATUS_REQUEST_LIST_REPORT_STOCK_PURCHASE_FAILED = 'list-report-stock-purchase-failed';
export const STATUS_REQUEST_LIST_REPORT_STOCK_PURCHASE_PENDING =
  'list-report-stock-purchase-loading';

// report stock adjustment request status
export const STATUS_REQUEST_LIST_REPORT_STOCK_ADJUSTMENT_SUCCESS =
  'list-report-stock-adjustment-success';
export const STATUS_REQUEST_LIST_REPORT_STOCK_ADJUSTMENT_FAILED =
  'list-report-stock-adjustment-failed';
export const STATUS_REQUEST_LIST_REPORT_STOCK_ADJUSTMENT_PENDING =
  'list-report-stock-adjustment-loading';

// expense of status request
export const STATUS_REQUEST_LIST_EXPENSE_SUCCESS = 'list-expense-success';
export const STATUS_REQUEST_LIST_EXPENSE_FAILED = 'list-expense-failed';
export const STATUS_REQUEST_LIST_EXPENSE_PENDING = 'list-expense-loading';

export const STATUS_REQUEST_DETAIL_EXPENSE_SUCCESS = 'detail-expense-success';
export const STATUS_REQUEST_DETAIL_EXPENSE_FAILED = 'detail-expense-failed';
export const STATUS_REQUEST_DETAIL_EXPENSE_PENDING = 'detail-expense-loading';

export const STATUS_REQUEST_DELETE_EXPENSE_SUCCESS = 'delete-expense-success';
export const STATUS_REQUEST_DELETE_EXPENSE_FAILED = 'delete-expense-failed';
export const STATUS_REQUEST_DELETE_EXPENSE_PENDING = 'delete-expense-loading';

export const STATUS_REQUEST_ADD_EXPENSE_SUCCESS = 'add-expense-success';
export const STATUS_REQUEST_ADD_EXPENSE_FAILED = 'add-expense-failed';
export const STATUS_REQUEST_ADD_EXPENSE_PENDING = 'add-expense-loading';

export const STATUS_REQUEST_UPDATE_EXPENSE_SUCCESS = 'update-expense-success';
export const STATUS_REQUEST_UPDATE_EXPENSE_FAILED = 'update-expense-failed';
export const STATUS_REQUEST_UPDATE_EXPENSE_PENDING = 'update-expense-loading';

const REQUEST_STATUS_IMPORT_EXPENSE_MAIN = 'import-expense';
export const REQUEST_STATUS_IMPORT_EXPENSE = sliceReduceHelper.generateRequestStatus(
  REQUEST_STATUS_IMPORT_EXPENSE_MAIN,
);

const REQUEST_STATUS_IMPORT_EXPENSE_PROGRESS_MAIN = 'import-expense-progress';
export const REQUEST_STATUS_IMPORT_EXPENSE_PROGRESS = sliceReduceHelper.generateRequestStatus(
  REQUEST_STATUS_IMPORT_EXPENSE_PROGRESS_MAIN,
);

// request status for attachment
export const REQUEST_STATUS_CREATE_ATTACHMENT_PENDING = 'create-attachment-pending';
export const REQUEST_STATUS_CREATE_ATTACHMENT_SUCCESS = 'create-attachment-success';
export const REQUEST_STATUS_CREATE_ATTACHMENT_FAILED = 'create-attachment-failed';

export const REQUEST_STATUS_LIST_DETAILS_ATTACHMENT_PENDING = 'list-details-attachment-pending';
export const REQUEST_STATUS_LIST_DETAILS_ATTACHMENT_SUCCESS = 'list-details-attachment-success';
export const REQUEST_STATUS_LIST_DETAILS_ATTACHMENT_FAILED = 'list-details-attachment-failed';

export const REQUEST_STATUS_DELETE_ATTACHMENT_PENDING = 'delete-attachment-pending';
export const REQUEST_STATUS_DELETE_ATTACHMENT_SUCCESS = 'delete-attachment-success';
export const REQUEST_STATUS_DELETE_ATTACHMENT_FAILED = 'delete-attachment-failed';

export const REQUEST_STATUS_DELETE_BATCH_ATTACHMENT_PENDING = 'delete-batch-attachment-pending';
export const REQUEST_STATUS_DELETE_BATCH_ATTACHMENT_SUCCESS = 'delete-batch-attachment-success';
export const REQUEST_STATUS_DELETE_BATCH_ATTACHMENT_FAILED = 'delete-batch-attachment-failed';

// request status notification
export const REQUEST_STATUS_COUNT_UNREAD_NOTIFICATIONS_PENDING =
  'count-unread-notification-pending';
export const REQUEST_STATUS_COUNT_UNREAD_NOTIFICATIONS_SUCCESS =
  'count-unread-notification-success';
export const REQUEST_STATUS_COUNT_UNREAD_NOTIFICATIONS_FAILED = 'count-unread-notification-failed';

export const REQUEST_STATUS_LIST_ALL_NOTIFICATIONS_PENDING = 'list-all-notifications-pending';
export const REQUEST_STATUS_LIST_ALL_NOTIFICATIONS_SUCCESS = 'list-all-notifications-success';
export const REQUEST_STATUS_LIST_ALL_NOTIFICATIONS_FAILED = 'list-all-notifications-failed';

export const REQUEST_STATUS_LIST_INVITATION_NOTIFICATION_PENDING =
  'list-invitation-notification-pending';
export const REQUEST_STATUS_LIST_INVITATION_NOTIFICATION_SUCCESS =
  'list-invitation-notification-success';
export const REQUEST_STATUS_LIST_INVITATION_NOTIFICATION_FAILED =
  'list-invitation-notification-failed';

// request status user permission
export const REQUEST_STATUS_LIST_DETAILS_USER_PERMISSION_PENDING =
  'list-details-user-permission-pending';
export const REQUEST_STATUS_LIST_DETAILS_USER_PERMISSION_SUCCESS =
  'list-details-user-permission-success';
export const REQUEST_STATUS_LIST_DETAILS_USER_PERMISSION_FAILED =
  'list-details-user-permission-failed';

export const REQUEST_STATUS_CREATE_USER_PERMISSION_PENDING = 'create-user-permission-pending';
export const REQUEST_STATUS_CREATE_USER_PERMISSION_SUCCESS = 'create-user-permission-success';
export const REQUEST_STATUS_CREATE_USER_PERMISSION_FAILED = 'create-user-permission-failed';

// request status billing
export const REQUEST_STATUS_BILLING_DETAILS_SUCCESS = 'details-billing-success';
export const REQUEST_STATUS_BILLING_DETAILS_FAILED = 'details-billing-failed';
export const REQUEST_STATUS_BILLING_DETAILS_PENDING = 'details-billing-pending';

export const REQUEST_STATUS_LIST_BILLING_INVOICE_SUCCESS = 'list-billing-invoice-success';
export const REQUEST_STATUS_LIST_BILLING_INVOICE_FAILED = 'list-billing-invoice-failed';
export const REQUEST_STATUS_LIST_BILLING_INVOICE_PENDING = 'list-billing-invoice-pending';

export const REQUEST_STATUS_LIST_BILLING_PACKAGE_SUCCESS = 'list-billing-package-success';
export const REQUEST_STATUS_LIST_BILLING_PACKAGE_FAILED = 'list-billing-package-failed';
export const REQUEST_STATUS_LIST_BILLING_PACKAGE_PENDING = 'list-billing-package-pending';

export const REQUEST_STATUS_DETAILS_BILLING_INVOICE_SUCCESS = 'details-billing-invoice-success';
export const REQUEST_STATUS_DETAILS_BILLING_INVOICE_FAILED = 'details-billing-invoice-failed';
export const REQUEST_STATUS_DETAILS_BILLING_INVOICE_PENDING = 'details-billing-invoice-pending';

export const REQUEST_STATUS_CREATE_BILLING_SUCCESS = 'create-billing-success';
export const REQUEST_STATUS_CREATE_BILLING_FAILED = 'create-billing-failed';
export const REQUEST_STATUS_CREATE_BILLING_PENDING = 'create-billing-pending';

export const REQUEST_STATUS_UPDATE_BILLING_SUCCESS = 'update-billing-success';
export const REQUEST_STATUS_UPDATE_BILLING_FAILED = 'update-billing-failed';
export const REQUEST_STATUS_UPDATE_BILLING_PENDING = 'update-billing-pending';

export const REQUEST_STATUS_PAY_BILLING_SUCCESS = 'pay-billing-success';
export const REQUEST_STATUS_PAY_BILLING_FAILED = 'pay-billing-failed';
export const REQUEST_STATUS_PAY_BILLING_PENDING = 'pay-billing-pending';

// request status for purchase return
export const REQUEST_STATUS_LIST_PURCHASE_RETURN_SUCCESS = 'list-purchase-return-success';
export const REQUEST_STATUS_LIST_PURCHASE_RETURN_LOADING = 'list-purchase-return-loading';
export const REQUEST_STATUS_LIST_PURCHASE_RETURN_FAILED = 'list-purchase-return-failed';

export const REQUEST_STATUS_LIST_PURCHASE_RETURN_PRINT_PDF_SUCCESS =
  'list-purchase-return-print-pdf-success';
export const REQUEST_STATUS_LIST_PURCHASE_RETURN_PRINT_PDF_LOADING =
  'list-purchase-return-print-pdf-loading';
export const REQUEST_STATUS_LIST_PURCHASE_RETURN_PRINT_PDF_FAILED =
  'list-purchase-return-print-pdf-failed';

export const REQUEST_STATUS_DETAILS_PURCHASE_RETURN_SUCCESS = 'details-purchase-return-success';
export const REQUEST_STATUS_DETAILS_PURCHASE_RETURN_LOADING = 'details-purchase-return-loading';
export const REQUEST_STATUS_DETAILS_PURCHASE_RETURN_FAILED = 'details-purchase-return-failed';

export const REQUEST_STATUS_HISTORY_PURCHASE_RETURN_SUCCESS = 'history-purchase-return-success';
export const REQUEST_STATUS_HISTORY_PURCHASE_RETURN_LOADING = 'history-purchase-return-loading';
export const REQUEST_STATUS_HISTORY_PURCHASE_RETURN_FAILED = 'history-purchase-return-failed';

export const REQUEST_STATUS_CREATE_PURCHASE_RETURN_SUCCESS = 'create-purchase-return-success';
export const REQUEST_STATUS_CREATE_PURCHASE_RETURN_PENDING = 'create-purchase-return-pending';
export const REQUEST_STATUS_CREATE_PURCHASE_RETURN_FAILED = 'create-purchase-return-failed';

export const REQUEST_STATUS_UPDATE_PURCHASE_RETURN_SUCCESS = 'update-purchase-return-success';
export const REQUEST_STATUS_UPDATE_PURCHASE_RETURN_PENDING = 'update-purchase-return-pending';
export const REQUEST_STATUS_UPDATE_PURCHASE_RETURN_FAILED = 'update-purchase-return-failed';

export const REQUEST_STATUS_DELETE_PURCHASE_RETURN_SUCCESS = 'delete-purchase-return-success';
export const REQUEST_STATUS_DELETE_PURCHASE_RETURN_PENDING = 'delete-purchase-return-pending';
export const REQUEST_STATUS_DELETE_PURCHASE_RETURN_FAILED = 'delete-purchase-return-failed';

// request status for purchase receivable payment
export const REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS =
  'list-purchase-receivable-payment-success';
export const REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_LOADING =
  'list-purchase-receivable-payment-loading';
export const REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_FAILED =
  'list-purchase-receivable-payment-failed';

export const REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_PRINT_PDF_SUCCESS =
  'list-purchase-receivable-payment-print-pdf-success';
export const REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_PRINT_PDF_LOADING =
  'list-purchase-receivable-payment-print-pdf-loading';
export const REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_PRINT_PDF_FAILED =
  'list-purchase-receivable-payment-print-pdf-failed';

export const REQUEST_STATUS_DETAILS_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS =
  'details-purchase-receivable-payment-success';
export const REQUEST_STATUS_DETAILS_PURCHASE_RECEIVABLE_PAYMENT_LOADING =
  'details-purchase-receivable-payment-loading';
export const REQUEST_STATUS_DETAILS_PURCHASE_RECEIVABLE_PAYMENT_FAILED =
  'details-purchase-receivable-payment-failed';

export const REQUEST_STATUS_HISTORY_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS =
  'history-purchase-receivable-payment-success';
export const REQUEST_STATUS_HISTORY_PURCHASE_RECEIVABLE_PAYMENT_LOADING =
  'history-purchase-receivable-payment-loading';
export const REQUEST_STATUS_HISTORY_PURCHASE_RECEIVABLE_PAYMENT_FAILED =
  'history-purchase-receivable-payment-failed';

export const REQUEST_STATUS_CREATE_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS =
  'create-purchase-receivable-payment-success';
export const REQUEST_STATUS_CREATE_PURCHASE_RECEIVABLE_PAYMENT_LOADING =
  'create-purchase-receivable-payment-loading';
export const REQUEST_STATUS_CREATE_PURCHASE_RECEIVABLE_PAYMENT_FAILED =
  'create-purchase-receivable-payment-failed';

export const REQUEST_STATUS_UPDATE_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS =
  'update-purchase-receivable-payment-success';
export const REQUEST_STATUS_UPDATE_PURCHASE_RECEIVABLE_PAYMENT_LOADING =
  'update-purchase-receivable-payment-loading';
export const REQUEST_STATUS_UPDATE_PURCHASE_RECEIVABLE_PAYMENT_FAILED =
  'update-purchase-receivable-payment-failed';

export const REQUEST_STATUS_DELETE_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS =
  'deleteupdate-purchase-receivable-payment-success';
export const REQUEST_STATUS_DELETE_PURCHASE_RECEIVABLE_PAYMENT_LOADING =
  'delete-purchase-receivable-payment-loading';
export const REQUEST_STATUS_DELETE_PURCHASE_RECEIVABLE_PAYMENT_FAILED =
  'delete-purchase-receivable-payment-failed';

// request status for sales return
export const REQUEST_STATUS_LIST_SALES_RETURN_SUCCESS = 'list-sales-return-success';
export const REQUEST_STATUS_LIST_SALES_RETURN_LOADING = 'list-sales-return-loading';
export const REQUEST_STATUS_LIST_SALES_RETURN_FAILED = 'list-sales-return-failed';

export const REQUEST_STATUS_LIST_SALES_RETURN_PRINT_PDF_SUCCESS =
  'list-sales-return-print-pdf-success';
export const REQUEST_STATUS_LIST_SALES_RETURN_PRINT_PDF_LOADING =
  'list-sales-return-print-pdf-loading';
export const REQUEST_STATUS_LIST_SALES_RETURN_PRINT_PDF_FAILED =
  'list-sales-return-print-pdf-failed';

export const REQUEST_STATUS_DETAILS_SALES_RETURN_SUCCESS = 'details-sales-return-success';
export const REQUEST_STATUS_DETAILS_SALES_RETURN_LOADING = 'details-sales-return-loading';
export const REQUEST_STATUS_DETAILS_SALES_RETURN_FAILED = 'details-sales-return-failed';

export const REQUEST_STATUS_HISTORY_SALES_RETURN_SUCCESS = 'history-sales-return-success';
export const REQUEST_STATUS_HISTORY_SALES_RETURN_LOADING = 'history-sales-return-loading';
export const REQUEST_STATUS_HISTORY_SALES_RETURN_FAILED = 'history-sales-return-failed';

export const REQUEST_STATUS_CREATE_SALES_RETURN_SUCCESS = 'create-sales-return-success';
export const REQUEST_STATUS_CREATE_SALES_RETURN_PENDING = 'create-sales-return-pending';
export const REQUEST_STATUS_CREATE_SALES_RETURN_FAILED = 'create-sales-return-failed';

export const REQUEST_STATUS_UPDATE_SALES_RETURN_SUCCESS = 'update-sales-return-success';
export const REQUEST_STATUS_UPDATE_SALES_RETURN_PENDING = 'update-sales-return-pending';
export const REQUEST_STATUS_UPDATE_SALES_RETURN_FAILED = 'update-sales-return-failed';

export const REQUEST_STATUS_DELETE_SALES_RETURN_SUCCESS = 'delete-sales-return-success';
export const REQUEST_STATUS_DELETE_SALES_RETURN_PENDING = 'delete-sales-return-pending';
export const REQUEST_STATUS_DELETE_SALES_RETURN_FAILED = 'delete-sales-return-failed';

// sales credit note request status
export const STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_SUCCESS = 'list-sales-credit-note-success';
export const STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_FAILED = 'list-sales-credit-note-failed';
export const STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_PENDING = 'list-sales-credit-note-loading';

export const STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_PRINT_PDF_SUCCESS =
  'list-sales-credit-note-print-pdf-success';
export const STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_PRINT_PDF_FAILED =
  'list-sales-credit-note-print-pdf-failed';
export const STATUS_REQUEST_LIST_SALES_CREDIT_NOTE_PRINT_PDF_PENDING =
  'list-sales-credit-note-print-pdf-loading';

export const STATUS_REQUEST_DETAILS_SALES_CREDIT_NOTE_SUCCESS = 'details-sales-credit-note-success';
export const STATUS_REQUEST_DETAILS_SALES_CREDIT_NOTE_FAILED = 'details-sales-credit-note-failed';
export const STATUS_REQUEST_DETAILS_SALES_CREDIT_NOTE_PENDING = 'details-sales-credit-note-loading';

export const STATUS_REQUEST_HISTORY_SALES_CREDIT_NOTE_SUCCESS = 'history-sales-credit-note-success';
export const STATUS_REQUEST_HISTORY_SALES_CREDIT_NOTE_FAILED = 'history-sales-credit-note-failed';
export const STATUS_REQUEST_HISTORY_SALES_CREDIT_NOTE_PENDING = 'history-sales-credit-note-loading';

// request status for sales payable payment
export const REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_SUCCESS =
  'list-sales-payable-payment-success';
export const REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_LOADING =
  'list-sales-payable-payment-loading';
export const REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_FAILED = 'list-sales-payable-payment-failed';

export const REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_PRINT_PDF_SUCCESS =
  'list-sales-payable-payment-print-pdf-success';
export const REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_PRINT_PDF_LOADING =
  'list-sales-payable-payment-print-pdf-loading';
export const REQUEST_STATUS_LIST_SALES_PAYABLE_PAYMENT_PRINT_PDF_FAILED =
  'list-sales-payable-payment-print-pdf-failed';

export const REQUEST_STATUS_DETAILS_SALES_PAYABLE_PAYMENT_SUCCESS =
  'details-sales-payable-payment-success';
export const REQUEST_STATUS_DETAILS_SALES_PAYABLE_PAYMENT_LOADING =
  'details-sales-payable-payment-loading';
export const REQUEST_STATUS_DETAILS_SALES_PAYABLE_PAYMENT_FAILED =
  'details-sales-payable-payment-failed';

export const REQUEST_STATUS_HISTORY_SALES_PAYABLE_PAYMENT_SUCCESS =
  'history-sales-payable-payment-success';
export const REQUEST_STATUS_HISTORY_SALES_PAYABLE_PAYMENT_LOADING =
  'history-sales-payable-payment-loading';
export const REQUEST_STATUS_HISTORY_SALES_PAYABLE_PAYMENT_FAILED =
  'history-sales-payable-payment-failed';

export const REQUEST_STATUS_CREATE_SALES_PAYABLE_PAYMENT_SUCCESS =
  'create-sales-payable-payment-success';
export const REQUEST_STATUS_CREATE_SALES_PAYABLE_PAYMENT_LOADING =
  'create-sales-payable-payment-loading';
export const REQUEST_STATUS_CREATE_SALES_PAYABLE_PAYMENT_FAILED =
  'create-sales-payable-payment-failed';

export const REQUEST_STATUS_UPDATE_SALES_PAYABLE_PAYMENT_SUCCESS =
  'update-sales-payable-payment-success';
export const REQUEST_STATUS_UPDATE_SALES_PAYABLE_PAYMENT_PENDING =
  'update-sales-payable-payment-pending';
export const REQUEST_STATUS_UPDATE_SALES_PAYABLE_PAYMENT_FAILED =
  'update-sales-payable-payment-failed';

export const REQUEST_STATUS_DELETE_SALES_PAYABLE_PAYMENT_SUCCESS =
  'delete-sales-payable-payment-success';
export const REQUEST_STATUS_DELETE_SALES_PAYABLE_PAYMENT_PENDING =
  'delete-sales-payable-payment-pending';
export const REQUEST_STATUS_DELETE_SALES_PAYABLE_PAYMENT_FAILED =
  'delete-sales-payable-payment-failed';

// request status for tag
export const REQUEST_STATUS_LIST_TAG_SUCCESS = 'list-tag-success';
export const REQUEST_STATUS_LIST_TAG_LOADING = 'list-tag-loading';
export const REQUEST_STATUS_LIST_TAG_FAILED = 'list-tag-failed';

// request status management company setup
export const REQUEST_STATUS_SETUP_MANAGEMENT_COMPANY_PENDING = 'setup-management-company-pending';
export const REQUEST_STATUS_SETUP_MANAGEMENT_COMPANY_SUCCESS = 'setup-management-company-success';
export const REQUEST_STATUS_SETUP_MANAGEMENT_COMPANY_FAILED = 'setup-management-company-failed';
