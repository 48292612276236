import React from 'react';
import { useStore } from 'react-redux';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { SLICE_NAME_APPLICATION_CONFIGURATION } from '../utils/constants/store.constant';
import {
  DEFAULT_KEY_NAME_ROUTER_PARAMS,
  DEFAULT_KEYNAME_ROUTER_PATH_ON_PARAMS,
} from '../utils/default/object-keyname.default';
import objHelper from '../utils/helpers/object.helper';

export function withRouter(Child) {
  return function withRouter(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const globalState = useStore().getState();

    const { application } = globalState;
    const routerPathOnParamsApp =
      !isEmpty(application) && application[SLICE_NAME_APPLICATION_CONFIGURATION]
        ? application[SLICE_NAME_APPLICATION_CONFIGURATION][DEFAULT_KEYNAME_ROUTER_PATH_ON_PARAMS]
        : null;

    function getURLparams(
      routerPathOnParams,
      keynameRouterParams = DEFAULT_KEY_NAME_ROUTER_PARAMS,
    ) {
      if (isEmpty(routerPathOnParams) || !routerPathOnParams) return {};

      let urlParamsMatch = matchRoutes(routerPathOnParams, location);
      if (!isEmpty(urlParamsMatch) && Array.isArray(urlParamsMatch) && !!keynameRouterParams) {
        urlParamsMatch = urlParamsMatch[0][keynameRouterParams];
      }

      return urlParamsMatch;
    }

    function getURLparamsWithApplicationConfig(
      keynameRouterParams = DEFAULT_KEY_NAME_ROUTER_PARAMS,
    ) {
      return getURLparams(routerPathOnParamsApp, keynameRouterParams);
    }

    function changesURLparamsBasedCorrespondObj(urlParams, correspondObj) {
      if (typeof correspondObj !== 'object' || isEmpty(correspondObj)) return urlParams;

      return objHelper.changeKeyNameWithCorrespondKeyTarget({
        obj: urlParams,
        correspondObj,
      });
    }

    function getURLparamsNchangesBasedCorrespondObj({
      urlParams: ownUrlParams = {},
      autoGetURLparams = true,
      keynameRouterParams = DEFAULT_KEY_NAME_ROUTER_PARAMS,
      correspondObj = {},
    }) {
      const urlParams =
        isEmpty(ownUrlParams) || autoGetURLparams
          ? getURLparamsWithApplicationConfig(keynameRouterParams)
          : ownUrlParams;

      return changesURLparamsBasedCorrespondObj(urlParams, correspondObj);
    }

    return (
      <Child
        {...props}
        location={location}
        navigate={navigate}
        withRouterMethod={{
          getURLparams,
          getURLparamsWithApplicationConfig,
          changesURLparamsBasedCorrespondObj,
          getURLparamsNchangesBasedCorrespondObj,
        }}
      />
    );
  };
}
