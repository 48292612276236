import {
  REACT_APP_API_URL_BILLING_INVOICE,
  REACT_APP_API_URL_BILLING_INVOICE_DETAILS,
  REACT_APP_API_URL_BILLING_INVOICE_PAY,
  REACT_APP_API_URL_BILLING_PACKAGE,
} from '../../../utils/configs/api.config';
import { LOCAL_STORAGE_SECONDARY_TOKEN } from '../../../utils/constants/storage.constant';
import { generalizeAddUpdateBillingCheckoutData } from '../../../utils/data/display/initial-add-billing-checkout-data';
import { DEFAULT_BASE_LIST_ACTION_PARAMS } from '../../../utils/default/params.default';
import {
  creatorAddService,
  creatorAddServiceWithTemplateURL,
  creatorDetailServiceV2,
  creatorListService,
  creatorListServiceV2,
  creatorUpdateServiceWithTemplateURL,
} from '../creator-services/creator-services.helper';

// user billing package list service
const getBillingPackageList = async (paramsGetBillingPackageList) => {
  return await creatorListService(
    REACT_APP_API_URL_BILLING_PACKAGE,
    paramsGetBillingPackageList,
    DEFAULT_BASE_LIST_ACTION_PARAMS,
  );
};

// user billing list service
const getUserBillingList = async (paramsGetBillingList) => {
  return await creatorListServiceV2({
    endpoint: REACT_APP_API_URL_BILLING_INVOICE,
    paramsGetList: paramsGetBillingList,
    allowParams: DEFAULT_BASE_LIST_ACTION_PARAMS,
    localStorageTokenKeyname: LOCAL_STORAGE_SECONDARY_TOKEN,
  });
};

// user billing details service
const getUserBillingDetails = async (paramsGetBillingDetails) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_BILLING_INVOICE_DETAILS,
    paramsGetBillingDetails,
    LOCAL_STORAGE_SECONDARY_TOKEN,
  );
};

// checkout billing
const createBilling = async (body) => {
  return await creatorAddService(REACT_APP_API_URL_BILLING_INVOICE, body);
};

// update billing service
const updateBilling = async (body) => {
  return await creatorUpdateServiceWithTemplateURL(
    REACT_APP_API_URL_BILLING_INVOICE,
    body,
    generalizeAddUpdateBillingCheckoutData,
  );
};

// service pay billing
const payBilling = async (body) => {
  return await creatorAddServiceWithTemplateURL(REACT_APP_API_URL_BILLING_INVOICE_PAY, body);
};

const billingServices = {
  getBillingPackageList,
  getUserBillingList,
  getUserBillingDetails,
  createBilling,
  updateBilling,
  payBilling,
};

export default billingServices;
