import { createAsyncThunk } from '@reduxjs/toolkit';

import postalCodeServices from '../../services/API/postal-code/postal-code.service';
import provinceServices from '../../services/API/province/province.service';
import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  LOCAL_STORAGE_REGIONAL_CITY,
  LOCAL_STORAGE_REGIONAL_PROVINCE,
  LOCAL_STORAGE_REGIONAL_SUB_DISTRICT,
  LOCAL_STORAGE_REGIONAL_URBAN,
} from '../../utils/constants/storage.constant';
import objHelper from '../../utils/helpers/object.helper';
import messageHelper from '../message/message.helper';

const creatorListAction = async (
  thunkAPI,
  paramsList,
  serviceFunc,
  localStorageName,
  sliceKeyPayload,
  uniqueKey,
  errorMessage,
) => {
  const { isRewriteAll } = paramsList;

  try {
    const response = await serviceFunc(paramsList);

    if (!response) {
      throw { response };
    }

    let { data: responseData, sucess } = response.data;

    if (!sucess || !Array.isArray(responseData.data)) {
      responseData = {
        ...responseData,
        data: [],
      };
    }

    let searchParams = objHelper.filterKeyObj(paramsList, ['isRewriteAll', 'page', 'paginate']);

    if (searchParams) {
      searchParams = objHelper.changeSuffixKey(searchParams, 'search_', false);

      responseData = {
        ...responseData,
        ...searchParams,
      };
    }

    if (isRewriteAll) {
      LocalStorage.set(localStorageName, responseData);

      return { [sliceKeyPayload]: responseData };
    }

    const currentData = LocalStorage.get(localStorageName);

    let newCurrentDataList = responseData.data || [];

    // combine old data list with response data
    if (Array.isArray(currentData.data) && Array.isArray(responseData.data)) {
      const currUniqueKey = currentData.data.map((currData) => {
        return currData[uniqueKey];
      });

      const resUniqueKey = responseData.data.map((resData) => {
        return resData[uniqueKey];
      });

      const isDuplicateUniqueKey = resUniqueKey.some((uniqKeyRes) => {
        return currUniqueKey.includes(uniqKeyRes);
      });

      if (!isDuplicateUniqueKey) {
        newCurrentDataList = currentData.data.concat(responseData.data);
      }
    }

    const newResponseData = Object.assign(responseData, { data: newCurrentDataList });

    LocalStorage.set(localStorageName, newResponseData);

    return { [sliceKeyPayload]: newResponseData };
  } catch (error) {
    const { response } = error;

    if (!response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue(response);
    }

    const { data } = response;

    const responseMessage = messageHelper.getMessageFromResponseData(data);

    if (responseMessage) {
      messageHelper.failedMessage(thunkAPI.dispatch, responseMessage, errorMessage, false);
    } else {
      messageHelper.failedMessage(thunkAPI.dispatch, errorMessage, errorMessage, false);
    }

    return thunkAPI.rejectWithValue(data);
  }
};

export const getListProvince = createAsyncThunk(
  'regional/province-list',
  async (paramsGetListProvince, thunkAPI) => {
    paramsGetListProvince = objHelper.withDefaultValue(paramsGetListProvince, {
      isRewriteAll: true,
    });

    return creatorListAction(
      thunkAPI,
      paramsGetListProvince,
      provinceServices.provinceList,
      LOCAL_STORAGE_REGIONAL_PROVINCE,
      'provinceData',
      'province_id',
      'error.list-data.regional-province',
    );
  },
);

export const getListCity = createAsyncThunk(
  'regional/city-list',
  async (paramsGetListCity, thunkAPI) => {
    paramsGetListCity = objHelper.withDefaultValue(paramsGetListCity, {
      isRewriteAll: true,
    });

    return creatorListAction(
      thunkAPI,
      paramsGetListCity,
      postalCodeServices.getListCity,
      LOCAL_STORAGE_REGIONAL_CITY,
      'cityData',
      'postal_code_id',
      'error.list-data.regional-city',
    );
  },
);

export const getListSubDistrict = createAsyncThunk(
  'regional/sub-district-list',
  async (paramsGetListSubDistrict, thunkAPI) => {
    paramsGetListSubDistrict = objHelper.withDefaultValue(paramsGetListSubDistrict, {
      isRewriteAll: true,
    });

    return creatorListAction(
      thunkAPI,
      paramsGetListSubDistrict,
      postalCodeServices.getListSubDistrict,
      LOCAL_STORAGE_REGIONAL_SUB_DISTRICT,
      'subDistrictData',
      'postal_code_id',
      'error.list-data.regional-sub-district',
    );
  },
);

export const getListUrban = createAsyncThunk(
  'regional/urban-list',
  async (paramsGetListUrban, thunkAPI) => {
    paramsGetListUrban = objHelper.withDefaultValue(paramsGetListUrban, {
      isRewriteAll: true,
    });

    return creatorListAction(
      thunkAPI,
      paramsGetListUrban,
      postalCodeServices.getListUrban,
      LOCAL_STORAGE_REGIONAL_URBAN,
      'urbanData',
      'postal_code_id',
      'error.list-data.regional-urban',
    );
  },
);
