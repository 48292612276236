import * as React from 'react';
function SvgSalesInvoiceMenu(props) {
  return (
    <svg width={107} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#sales-invoice-menu_svg__filter0_d_5586_192075)'>
        <path
          d='M45.803 68.914h48.265c3.275 0 5.93-2.51 5.932-5.605H51.735c0 3.096-2.657 5.605-5.932 5.605z'
          fill='#566FF8'
        />
        <path
          d='M28 6.662h11.867c0-3.096-2.658-5.605-5.933-5.605C30.658 1.057 28 3.566 28 6.662z'
          fill='#FFC250'
        />
        <path
          d='M82.2 1c3.278 0 5.933 2.509 5.933 5.605v56.703H51.735c0 3.096-2.657 5.605-5.932 5.605-3.277 0-5.935-2.508-5.935-5.605V6.662h-.002c0-3.096-2.657-5.605-5.932-5.605V1H82.2zm-3.165 8.963a2.204 2.204 0 00-1.516-.593h-2.922c-1.184 0-2.143.908-2.143 2.025s.959 2.025 2.143 2.025h2.922c1.183 0 2.143-.908 2.143-2.025 0-.56-.24-1.064-.627-1.432zm0 8.534a2.204 2.204 0 00-1.516-.593h-2.922c-1.184 0-2.143.907-2.143 2.025 0 1.119.959 2.024 2.143 2.024h2.922c1.183 0 2.143-.905 2.143-2.024 0-.56-.24-1.064-.627-1.432zM77.52 30.489c1.183 0 2.143-.907 2.143-2.025 0-.56-.24-1.066-.627-1.432a2.204 2.204 0 00-1.516-.593h-2.922c-1.184 0-2.143.906-2.143 2.025 0 1.118.959 2.025 2.143 2.025h2.922zM64.001 55.614c5.287 0 9.573-4.05 9.573-9.046 0-4.994-4.286-9.043-9.573-9.043-5.288 0-9.574 4.05-9.574 9.043 0 4.996 4.286 9.046 9.574 9.046z'
          fill='#80B4FB'
        />
        <path
          d='M79.035 9.963a2.204 2.204 0 00-1.516-.593h-2.922c-1.184 0-2.143.908-2.143 2.025s.959 2.025 2.143 2.025h2.922c1.183 0 2.143-.908 2.143-2.025 0-.56-.24-1.064-.627-1.432zM79.035 18.497a2.204 2.204 0 00-1.516-.593h-2.922c-1.184 0-2.143.907-2.143 2.025 0 1.119.959 2.024 2.143 2.024h2.922c1.183 0 2.143-.905 2.143-2.024 0-.56-.24-1.064-.627-1.432zM77.52 30.49c1.182 0 2.142-.908 2.142-2.026 0-.56-.24-1.066-.627-1.432a2.204 2.204 0 00-1.516-.593h-2.922c-1.184 0-2.143.906-2.143 2.025 0 1.118.959 2.025 2.143 2.025h2.922zM64 55.614c5.288 0 9.574-4.05 9.574-9.046 0-4.994-4.286-9.043-9.573-9.043-5.288 0-9.574 4.05-9.574 9.043 0 4.996 4.286 9.046 9.574 9.046z'
          fill='#80B4FB'
        />
        <path
          d='M81.706 29.164H56.685v1.27h25.02v-1.27zM53.998 29.164h-5.542v1.27h5.541v-1.27zM81.706 34.4H56.685v1.27h25.02V34.4zM53.998 34.4h-5.542v1.27h5.541V34.4zM81.706 39.636H56.685v1.27h25.02v-1.27zM53.998 39.636h-5.542v1.27h5.541v-1.27zM81.706 44.872H56.685v1.27h25.02v-1.27zM53.998 44.872h-5.542v1.27h5.541v-1.27z'
          fill='#fff'
        />
        <path
          d='M81.705 29.164H64.128v1.27h17.577v-1.27zM81.706 34.4H64.128v1.27h17.578V34.4zM81.706 39.636H64.128v1.27h17.578v-1.27zM81.706 44.872H64.128v1.27h17.578v-1.27z'
          fill='#EDEAEA'
        />
        <g opacity={0.1} fill='#5F93D8'>
          <path d='M58.078 1.086v.057c3.275 0 5.933 2.51 5.933 5.606h.002v56.646c0 3.096 2.657 5.605 5.934 5.605 3.275 0 5.932-2.509 5.932-5.605h12.266V55.7c-5.287 0-9.573-4.05-9.573-9.045 0-4.994 4.286-9.044 9.573-9.044V6.605c0-3.048-2.614-5.519-5.84-5.519H58.077z' />
          <path d='M88.145 55.7c-5.287 0-9.573-4.05-9.573-9.045 0-4.994 4.286-9.044 9.573-9.044v18.09z' />
        </g>
        <path
          d='M66.505 20.584c.292 0 .53-.224.53-.5v-.341c0-1.036-1.037-1.88-2.326-1.914v-.13c0-.276-.237-.5-.53-.5-.292 0-.529.224-.529.5v.13c-1.289.033-2.325.878-2.325 1.914v.683c0 1.036 1.036 1.88 2.325 1.913v2.515c-.694-.028-1.266-.434-1.266-.913V23.6c0-.277-.237-.5-.53-.5-.292 0-.53.223-.53.5v.341c0 1.036 1.037 1.88 2.326 1.913v.131c0 .276.237.5.53.5.292 0 .529-.224.529-.5v-.13c1.289-.034 2.325-.878 2.325-1.914v-.683c0-1.036-1.036-1.88-2.325-1.913v-2.516c.694.029 1.266.435 1.266.913v.342c0 .276.237.5.53.5zm-4.122-.158v-.683c0-.479.573-.885 1.267-.913v2.51c-.694-.03-1.267-.435-1.267-.914zm3.593 2.832v.684c0 .478-.573.884-1.267.913v-2.51c.694.028 1.267.435 1.267.913z'
          fill='#FFE07D'
        />
        <path
          d='M22.633 60.423h2.4v7.71c0 .479-.537.867-1.2.867-.663 0-1.2-.388-1.2-.867v-7.71z'
          fill='#FFC61B'
        />
        <path
          d='M23.753 60.423v8.571c.027.001.053.006.08.006.663 0 1.2-.388 1.2-.867v-7.71h-1.28zM24.956 56.188l2.286-.529 5.423 12.21c.202.456-.146.943-.777 1.09-.631.145-1.307-.105-1.51-.561l-5.422-12.21z'
          fill='#EAA22F'
        />
        <path
          d='M22.71 56.188l-2.286-.529-5.422 12.21c-.203.456.145.943.776 1.09.631.145 1.307-.105 1.51-.561l5.421-12.21z'
          fill='#FFC61B'
        />
        <path d='M43.636 38.028H4.03V59.86h39.606V38.028z' fill='#fff' />
        <path d='M43.636 38.028H23.754V59.86h19.882V38.028z' fill='#D0D1D3' />
        <path
          d='M25.814 36.859h-3.961v-1.43c0-.789.887-1.429 1.98-1.429 1.094 0 1.98.64 1.98 1.43v1.429z'
          fill='#FFC61B'
        />
        <path
          d='M25.814 35.43c0-.79-.887-1.43-1.98-1.43-.027 0-.054.002-.08.003v2.856h2.06v-1.43z'
          fill='#EAA22F'
        />
        <path
          d='M44.076 39.184H3.59c-1.016 0-1.84-.595-1.84-1.329 0-.733.824-1.328 1.84-1.328h40.486c1.017 0 1.84.594 1.84 1.328 0 .734-.824 1.329-1.84 1.329zM44.076 61.073H3.59c-1.016 0-1.84-.595-1.84-1.328 0-.734.824-1.329 1.84-1.329h40.486c1.017 0 1.84.595 1.84 1.329 0 .733-.824 1.328-1.84 1.328z'
          fill='#71E2EF'
        />
        <path
          d='M44.076 36.527H23.753v2.657h20.323c1.017 0 1.84-.595 1.84-1.329s-.823-1.328-1.84-1.328zM44.076 58.416H23.753v2.657h20.323c1.017 0 1.84-.595 1.84-1.328 0-.734-.823-1.329-1.84-1.329z'
          fill='#38C6D9'
        />
        <path
          d='M36.924 45.44l-10.17 5.673-9.947-2.394L8.5 53.304l.73.688 7.8-4.306 9.952 2.395 10.676-5.955-.734-.686z'
          fill='#59595B'
        />
        <path
          d='M17.03 49.686l-7.8 4.306-.73-.688 8.307-4.585 6.947 1.672v.913l-6.723-1.618z'
          fill='#59595B'
        />
        <path
          d='M26.981 52.08l-3.228-.776v-.913l3.001.722 10.17-5.673.733.686-10.676 5.955z'
          fill='#272525'
        />
        <path
          d='M26.867 50.4c.916 0 1.658.536 1.658 1.197s-.742 1.197-1.658 1.197c-.916 0-1.658-.536-1.658-1.197s.742-1.197 1.658-1.197z'
          fill='#C92F00'
        />
        <path
          d='M16.918 48.005c.916 0 1.658.536 1.658 1.197 0 .662-.742 1.197-1.658 1.197-.916 0-1.658-.535-1.658-1.197 0-.66.742-1.197 1.658-1.197z'
          fill='#FF7F4F'
        />
        <path
          d='M37.29 44.585c.915 0 1.658.536 1.658 1.197s-.743 1.197-1.659 1.197c-.915 0-1.658-.536-1.658-1.197 0-.66.743-1.197 1.658-1.197z'
          fill='#EAA22F'
        />
        <path
          d='M8.865 52.452c.916 0 1.658.535 1.658 1.196 0 .661-.742 1.197-1.658 1.197-.916 0-1.658-.535-1.658-1.197 0-.66.742-1.197 1.658-1.197z'
          fill='#FF314F'
        />
      </g>
      <defs>
        <filter
          id='sales-invoice-menu_svg__filter0_d_5586_192075'
          x={0.75}
          y={0}
          width={106.25}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_192075' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_192075' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgSalesInvoiceMenu;
