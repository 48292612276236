import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Add } from '@mui/icons-material';
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { isEmpty } from 'lodash';

import BoxTableNotFound from '../../../components/box/BoxTableNotFound/BoxTableNotFound';
import TablePaginationMain from '../../../components/table-pagination/TablePaginationMain/TablePaginationMain';
import TableRowLoading from '../../../components/table-row/TableRowLoading/TableRowLoading';
import TypographyLinkPrimary from '../../../components/typography/TypographyLinkPrimary/TypographyLinkPrimary';
import { withRouter } from '../../../components/withRouter/withRouter';
import { decryptData } from '../../../services/modules/Crypto/Crypto.service';
import messageHelper from '../../../store/message/message.helper';
import { getListQuotationRequest } from '../../../store/purchase/purchase.action';
import {
  REACT_APP_PURCHASE_QUOTATION_REQUEST_CREATE_NEW_URL,
  REACT_APP_PURCHASE_QUOTATION_REQUEST_DETAILS_TEMPLATE_URL,
} from '../../../utils/configs/url.config';
import { STATUS_REQUEST_LIST_QUOTATION_REQUEST_PENDING } from '../../../utils/constants/store.constant';
import {
  calculationColumns,
  columnsTableHeaderQuotationRequest,
} from '../../../utils/data/display/table-quotation-request';
import { purchaseQuotationRequestStatus } from '../../../utils/data/label-value/purchase-status';
import {
  DEFAULT_PURCHASE_QR_SEARCH_FE_PARAMS,
  TERSIER_DEFAULT_LIST_PARAMS,
} from '../../../utils/default/params.default';
import arrHelp from '../../../utils/helpers/array.helpers';
import formatHelp from '../../../utils/helpers/format.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import strHelp from '../../../utils/helpers/string.helpers';

import TableHeadSearchQuotationRequest from './TableHeadSearchQuotationRequest/TableHeadSearchQuotationRequest';
import { styles } from './PurchaseQuotationRequestList.styles';

class PurchaseQuotationRequestList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage: TERSIER_DEFAULT_LIST_PARAMS.paginate,
    };

    this.getSearchParamsQuotationRequest = this.getSearchParamsQuotationRequest.bind(this);

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

    this.handleClickActionQuotationRequest = this.handleClickActionQuotationRequest.bind(this);
  }

  handleClickActionQuotationRequest(event) {
    const { name: newStatusPage, value } = event.currentTarget;

    const decryptedValue = decryptData(value, false);

    this.props.handleSelectedStatusPage(newStatusPage, decryptedValue);
  }

  getSearchParamsQuotationRequest() {
    const { purchase } = this.props;
    const { quotationRequestData } = purchase;

    let searchParamsQuotationRequest = objHelper.filterKeyObj(
      quotationRequestData,
      [],
      DEFAULT_PURCHASE_QR_SEARCH_FE_PARAMS,
    );

    if (!isEmpty(searchParamsQuotationRequest)) {
      searchParamsQuotationRequest = objHelper.changeSuffixKey(
        searchParamsQuotationRequest,
        'search_',
        true,
      );
    }

    return searchParamsQuotationRequest;
  }

  async handleChangePage(event, newPage) {
    event.preventDefault();

    const { purchase } = this.props;
    const { quotationRequestData } = purchase;

    if (!quotationRequestData) {
      return;
    }

    const { next_page_url, current_page, per_page: paginate } = quotationRequestData;

    if (!next_page_url && current_page < newPage + 1) {
      return;
    }

    let searchParamsQuotationRequest = this.getSearchParamsQuotationRequest();

    await this.props.getListQuotationRequest({
      paginate,
      page: newPage + 1,
      ...searchParamsQuotationRequest,
    });
  }

  async handleChangeRowsPerPage(event) {
    const { value: newRowsPerPage } = event.target;

    const { t, purchase } = this.props;

    const { quotationRequestData } = purchase;

    if (!quotationRequestData) {
      return;
    }

    const { next_page_url, current_page, per_page: paginate } = quotationRequestData;

    if (Number(paginate) === Number(newRowsPerPage)) {
      return;
    }

    if (!next_page_url && paginate < newRowsPerPage && current_page === 1) {
      this.props.setMessageFailed(
        t('dashboard.purchase.quotation-request.message.max-data.primary'),
        t('dashboard.purchase.quotation-request.message.max-data.secondary'),
      );

      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      rowsPerPage: newRowsPerPage,
    }));

    let searchParamsQuotationRequest = this.getSearchParamsQuotationRequest();

    await this.props.getListQuotationRequest({
      paginate: newRowsPerPage,
      ...searchParamsQuotationRequest,
    });
  }

  async componentDidMount() {
    const { rowsPerPage: paginate } = this.state;

    await this.props.getListQuotationRequest({
      paginate,
      isShowMessage: false,
    });
  }

  render() {
    const { rowsPerPage } = this.state;

    const { t, i18n, classes, purchase } = this.props;

    const { language: languageI18n } = i18n;

    const { quotationRequestData } = purchase;

    let quotationRequestList = [];

    let currPagination = rowsPerPage;

    if (quotationRequestData) {
      if (quotationRequestData.data) {
        if (Array.isArray(quotationRequestData.data)) {
          quotationRequestList = quotationRequestData.data;
        }
      }

      if (quotationRequestData.per_page) {
        currPagination = Number(quotationRequestData.per_page);
      }
    }

    const tableHeaderQuotationRequest = columnsTableHeaderQuotationRequest(t);

    return (
      <Box className={classes.boxContainerTable}>
        <Paper elevation={0}>
          <TableContainer className={'tableContainerConstantHeightMDSizeConstantLayoutPrimary'}>
            <Table stickyHeader className='tableMain' aria-label='table-cash-bank-list'>
              <TableHeadSearchQuotationRequest dataHeaders={tableHeaderQuotationRequest} />
              <TableBody className='primaryTableBody'>
                {purchase.statusRequest === STATUS_REQUEST_LIST_QUOTATION_REQUEST_PENDING && (
                  <TableRowLoading
                    rowsAmount={rowsPerPage}
                    columnsAmount={tableHeaderQuotationRequest.length}
                  />
                )}
                {quotationRequestList &&
                  purchase.statusRequest !== STATUS_REQUEST_LIST_QUOTATION_REQUEST_PENDING &&
                  quotationRequestList.map((singleQuotationRequest, index) => {
                    const baseUrl = strHelp.templateString(
                      REACT_APP_PURCHASE_QUOTATION_REQUEST_DETAILS_TEMPLATE_URL,
                      singleQuotationRequest,
                    );

                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        className={'hoverEntireRow'}
                        onDoubleClick={() => this.props.navigate(baseUrl)}
                      >
                        {tableHeaderQuotationRequest.map((columnTableHeader, indexColumnHeader) => {
                          let valueData = singleQuotationRequest[columnTableHeader.name];

                          // formating date following localzone
                          if (columnTableHeader.name === 'transaction_date') {
                            valueData = formatHelp.getReadableDateV2(valueData, languageI18n);
                          }

                          if (columnTableHeader.name === 'transaction_no') {
                            return (
                              <TableCell key={indexColumnHeader}>
                                <TypographyLinkPrimary
                                  typographyValue={valueData}
                                  baseUrl={baseUrl}
                                  searchParamsInObj={{}}
                                />
                              </TableCell>
                            );
                          }

                          // formating value of nominal column
                          if (calculationColumns.includes(columnTableHeader.name)) {
                            valueData = formatHelp.currencyFormatWithRegex(Number(valueData), 0);
                          }

                          // container value status with Chip
                          if (columnTableHeader.name === 'status') {
                            const selectedStatusData = arrHelp.filterObjDataWithID(
                              purchaseQuotationRequestStatus(t),
                              'value',
                              valueData,
                            );

                            let label = t('label.purchase-quotation-request-status.open');
                            let colorChip = 'chipSecondaryStatus';

                            // get color and label status quotation request
                            if (!isEmpty(selectedStatusData)) {
                              label = selectedStatusData.label;
                              colorChip = selectedStatusData.colorChip;
                            }

                            return (
                              <TableCell key={indexColumnHeader}>
                                <Chip size='small' color={colorChip} label={label} />
                              </TableCell>
                            );
                          }

                          return (
                            <TableCell key={indexColumnHeader} align={columnTableHeader.align}>
                              {valueData}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {!quotationRequestList.length &&
            purchase.statusRequest !== STATUS_REQUEST_LIST_QUOTATION_REQUEST_PENDING ? (
              <Box height={'calc(100% - 96px)'}>
                <BoxTableNotFound
                  textNotFound={t('dashboard.purchase.quotation-request.table.body-not-found')}
                  textButton={t('button.quotation-request')}
                  handleClickedButton={() => {
                    this.props.navigate(REACT_APP_PURCHASE_QUOTATION_REQUEST_CREATE_NEW_URL);
                  }}
                  isExistButton={true}
                  buttonProps={{
                    startIcon: <Add fontSize='small' />,
                  }}
                />
              </Box>
            ) : null}
          </TableContainer>
          <TablePaginationMain
            page={quotationRequestData ? quotationRequestData.current_page - 1 : 0}
            prev_page={quotationRequestData ? quotationRequestData.prev_page_url : null}
            next_page={quotationRequestData ? quotationRequestData.next_page_url : null}
            rowsPerPage={currPagination}
            isLoadingData={
              purchase.statusRequest === STATUS_REQUEST_LIST_QUOTATION_REQUEST_PENDING ||
              !quotationRequestList.length
            }
            labelRowsPerPage={t('label.table.pagination.primary.rows-per-page')}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    );
  }
}

PurchaseQuotationRequestList.defaultProps = {
  isUseChangedPageOnDetails: false,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    purchase: state.purchase,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListQuotationRequest: (paramsGetListQuotationRequest) =>
      dispatch(getListQuotationRequest(paramsGetListQuotationRequest)),
    setMessageFailed: (primaryMessage, secondaryMessage) =>
      dispatch(messageHelper.failedMessage(dispatch, primaryMessage, secondaryMessage, false)),
  };
};

// adding style into component
const stylingPurchaseQuotationRequestList = withStyles(styles)(PurchaseQuotationRequestList);

// adding translation into component
const PurchaseQuotationRequestListTranslate = withTranslation()(
  stylingPurchaseQuotationRequestList,
);

const PurchaseQuotationRequestListWithRouter = withRouter(PurchaseQuotationRequestListTranslate);

// connect component with action and state
const ConnectedQuotationRequestList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PurchaseQuotationRequestListWithRouter);

export default ConnectedQuotationRequestList;
