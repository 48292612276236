import * as React from 'react';
function SvgIconMenuDataContact(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.857 68.143a.429.429 0 11-.857 0 .429.429 0 01.857 0zM6.857 67.714h58.286a.428.428 0 110 .857H6.857a.428.428 0 110-.857zM2.571 67.714h2.143a.428.428 0 110 .857H2.571a.428.428 0 110-.857zM69.429 67.714h-2.143a.428.428 0 100 .857h2.143a.428.428 0 100-.857zM72 68.143a.429.429 0 11-.857 0 .429.429 0 01.857 0z'
        fill='#000'
      />
      <path
        d='M53.05 31.823h-.932c.03-2.831-2.248-5.252-5.1-5.252H24.748c-2.795 0-5.07 2.27-5.099 5.069h-1.518a2.281 2.281 0 00-2.274 2.283c0 1.26 1.02 2.284 2.274 2.284h1.517v4.389h-1.517a2.282 2.282 0 00-2.274 2.283c0 1.26 1.02 2.284 2.274 2.284h1.517v4.389h-1.517a2.282 2.282 0 00-2.274 2.283c0 1.26 1.02 2.284 2.274 2.284h1.517v4.388h-1.517a2.281 2.281 0 00-2.274 2.284c0 1.26 1.02 2.284 2.274 2.284h1.518c.029 2.8 2.304 5.068 5.099 5.068h22.27c2.86 0 5.13-2.427 5.1-5.263h.931a3.104 3.104 0 003.094-3.106V34.929a3.104 3.104 0 00-3.094-3.106zm1.88 21.313a1.887 1.887 0 01-1.88 1.89h-.932v-5.42h.931c.707 0 1.36-.24 1.881-.643v4.173zm0-6.637a1.887 1.887 0 01-1.88 1.889h-.932v-5.53h.931c.707 0 1.36-.24 1.881-.642v4.283zM53.05 33.04c1.036 0 1.88.848 1.88 1.89v4.822a1.887 1.887 0 01-1.88 1.888h-.932v-8.6h.931zm-35.98.883c0-.587.476-1.065 1.061-1.065h4.273a1.065 1.065 0 010 2.132h-4.273a1.065 1.065 0 01-1.061-1.067zm0 8.956c0-.587.476-1.066 1.061-1.066h4.273c.586 0 1.062.479 1.062 1.066 0 .588-.476 1.066-1.062 1.066h-4.273a1.065 1.065 0 01-1.061-1.066zm0 8.956c0-.587.476-1.066 1.061-1.066h4.273c.586 0 1.062.479 1.062 1.066 0 .588-.476 1.066-1.062 1.066h-4.273a1.065 1.065 0 01-1.061-1.066zm0 8.956c0-.587.476-1.066 1.061-1.066h4.273a1.065 1.065 0 010 2.132h-4.273a1.065 1.065 0 01-1.061-1.066zm35.98.872h-.932v-.872c0-.787-1.213-.787-1.213 0v2.231c0 2.152-1.744 3.903-3.887 3.903H28.17V35.598c0-.787-1.213-.787-1.213 0v31.327h-2.21c-2.125 0-3.857-1.722-3.885-3.85h1.542a2.281 2.281 0 002.274-2.284c0-1.26-1.02-2.284-2.274-2.284h-1.543V54.12h1.543a2.281 2.281 0 002.274-2.284c0-1.26-1.02-2.284-2.274-2.284h-1.543v-4.388h1.543a2.281 2.281 0 002.274-2.284c0-1.259-1.02-2.283-2.274-2.283h-1.543v-4.39h1.543a2.281 2.281 0 002.274-2.283c0-1.259-1.02-2.283-2.274-2.283h-1.542c.028-2.128 1.76-3.85 3.886-3.85h2.209v4.642c0 .787 1.213.787 1.213 0v-4.643h18.848c2.143 0 3.887 1.751 3.887 3.903v25.94c0 .787 1.213.787 1.213 0v-1.389h.931c.707 0 1.36-.24 1.881-.642v4.173a1.887 1.887 0 01-1.88 1.889z'
        fill='#000'
      />
      <path
        d='M42.426 43.369a5.024 5.024 0 001.773-3.82c0-2.752-2.23-4.991-4.972-4.991-2.741 0-4.971 2.24-4.971 4.992 0 1.486.668 2.882 1.772 3.819a7.627 7.627 0 00-4.371 6.89c0 .786 1.213.786 1.213 0 0-2.864 1.918-5.394 4.664-6.154.532-.147.596-.902.097-1.138a3.793 3.793 0 01-2.163-3.417c0-2.081 1.686-3.774 3.76-3.774 2.072 0 3.758 1.693 3.758 3.774a3.793 3.793 0 01-2.163 3.417c-.499.236-.435.991.097 1.138 2.747.76 4.665 3.29 4.665 6.153 0 .787 1.213.787 1.213 0a7.627 7.627 0 00-4.372-6.89zm3.765 11.54H32.263c-.784 0-.784 1.218 0 1.218h13.928c.784 0 .784-1.218 0-1.218zm-2.436 4.018H34.7c-.784 0-.784 1.218 0 1.218h9.055c.784 0 .784-1.218 0-1.218z'
        fill='#000'
      />
    </svg>
  );
}
export default SvgIconMenuDataContact;
