import { omit } from 'lodash';
import moment from 'moment';

import { DEFAULT_DISCOUNT_PERCENTAGE_TYPE } from '../../default/discount-type.default';
import {
  hasImplementedUnitProductUnit,
  keynamesImpelementProductUnit,
} from '../../default/unit-product.default';
import objHelper from '../../helpers/object.helper';
import validation from '../../helpers/validation.helpers';

/**
 * modul_id             data for contain purchase_delivery_id or purchase_order_id
 * modul_id_container   container for transaction_id and transactin_no
 * modul_type           where type of data modul from, can from 'manual', 'order', or 'delivery'
 */
export const plainPurchaseInvoiceDataWithoutID = {
  modul_id: null,
  modul_id_container: '',
  modul_type: 'manual',
  supplier_id: null,
  supplier_id_container: '',
  supplier_address: '',
  transaction_date: moment(),
  expired_date: null,
  reference_number: '',
  reference_number_container: null,
  uuid_attachment: null,
  attachment: [],
  amount_available: null,
  description: '',
  is_paid_off: false,
  minAccountingPeriod: null,
  amount: 0,
  amount_const: 0,
  accounting_period: null,
  discount_account_id: '',
  discount_account_id_container: '',
  discount_type: DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
  discount_percentage: '',
  discount_nominal: '',
  expense_account_id: '',
  expense_account_id_container: '',
  expense_value: '',
  down_payment_account_id: '',
  down_payment_account_id_container: '',
  down_payment_value: '',
  on_behalf: '',
  warehouse_id: '',
  warehouse_id_container: null,
  minDateInputRef: null,
  tag: [],
  tagContainer: [],
  tagNameInput: '',
};

/**
 * quantity_origin is amount of quantity for has changes unit
 * quantity_available is quantity for showing on user
 * quantity_available_const is quantity for max on input user
 * quantity comparison, comparison from quantity with quantity origin
 * quantity_comparison_const_props, appear for update action,
 *  usage: preserve quantity comparison data
 */
export const plainProductPurchaseInvoiceDataWithoutID = {
  product_id: null,
  product_id_container: '',
  description: '',
  quantity: '',
  quantity_origin: '',
  product_unit: '',
  unit: '',
  unit_origin: '',
  unit_destination: '',
  quantity_available: '',
  quantity_available_const: 0,
  quantity_comparison: 1,
  quantity_comparison_const_props: null,
  price: '',
  amount: 0,
};

export const plainPurchaseInvoiceValidationWithoutID = {
  modul_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  supplier_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  supplier_address: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  transaction_date: {
    error: false,
    isValid: (value, minDateInputRef) => {
      if (!minDateInputRef) {
        return validation.notnull(value);
      }

      return moment(new Date(minDateInputRef)).isSameOrBefore(new Date(value), 'D');
    },
  },
  expired_date: {
    error: false,
    isValid: (value, minimumDateInput) => {
      if (minimumDateInput && value) {
        return moment(new Date(value)).isAfter(new Date(minimumDateInput));
      }

      return validation.nullable(value);
    },
  },
  reference_number: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  amount: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  amount_available: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  discount_type: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  discount_percentage: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  discount_nominal: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  discount_account_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  expense_value: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  expense_account_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  down_payment_account_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  down_payment_value: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  on_behalf: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const plainProductPurchaseInvoiceValidationWithoutID = {
  product_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  quantity: {
    error: false,
    isValid: (value) => validation.isNumberGreaterOrEqualThan(value, 1),
  },
  unit: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  unit_destination: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  price: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  amount: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const initialPurchaseInvoiceData = (
  newID = '',
  additionalID = '',
  ignoreKeyData = ['modul_id'],
) => {
  const filteredPlainPurchaseInvoiceData = omit(plainPurchaseInvoiceDataWithoutID, ignoreKeyData);

  return objHelper.creatorInitializingDataOrValidation(
    newID,
    additionalID,
    filteredPlainPurchaseInvoiceData,
  );
};

export const initialPurchaseInvoiceValidation = (
  newID = '',
  additionalID = '',
  ignoreKeyValidation = ['modul_id'],
) => {
  const filteredPlainPurchaseInvoiceValidation = omit(
    plainPurchaseInvoiceValidationWithoutID,
    ignoreKeyValidation,
  );

  return objHelper.creatorInitializingDataOrValidation(
    newID,
    additionalID,
    filteredPlainPurchaseInvoiceValidation,
  );
};

export const initialProductPurchaseInvoiceData = (newID = '', additionalID = '') => {
  return objHelper.creatorInitializingDataOrValidation(
    newID,
    additionalID,
    plainProductPurchaseInvoiceDataWithoutID,
  );
};

export const initialProductPurchaseInvoiceValidation = (newID = '', additionalID = '') => {
  return objHelper.creatorInitializingDataOrValidation(
    newID,
    additionalID,
    plainProductPurchaseInvoiceValidationWithoutID,
  );
};

export const modulTypePurchaseInvoice = {
  manual: 'manual',
  order: 'order',
  delivery: 'delivery',
};

export const allowKeysPurchaseInvoiceData = [
  'modul_id',
  'modul_type',
  'supplier_id',
  'supplier_address',
  'transaction_date',
  'expired_date',
  'reference_number',
  'is_paid_off',
  'supplier_name',
  'description',
  'uuid_attachment',
  'discount_account_id',
  'discount_type',
  'discount_percentage',
  'discount_nominal',
  'expense_account_id',
  'expense_value',
  'down_payment_account_id',
  'down_payment_value',
  'on_behalf',
  'warehouse_id',
  'purchase_invoice_id',
  'product',
  'tag',
];

export const allowKeysProductPurchaseInvoiceData = [
  'product_id',
  'product_description',
  'quantity',
  'quantity_origin',
  'unit',
  'unit_origin',
  'unit_destination',
  'price',
].filter((key) =>
  !hasImplementedUnitProductUnit ? !keynamesImpelementProductUnit.includes(key) : true,
);

export const formatCalculationPurchaseInvoiceData = [
  'price',
  'amount',
  'quantity',
  'quantity_available',
];

export const formatDatePurchaseInvoiceData = ['transaction_date', 'expired_date'];

export const purchaseInvoiceCalculationNumberKeyNames = [
  'discount_nominal',
  'discount_percentage',
  'expense_value',
  'down_payment_value',
  'amount',
];
