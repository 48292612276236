import * as React from 'react';
function SvgReferenceNumberSettingMenu(props) {
  return (
    <svg width={123} height={60} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#reference-number-setting-menu_svg__filter0_d_5586_193542)'>
        <path
          d='M105.028 48.243H23.472c-3.805 0-6.89-4.4-6.89-9.829v-22.83c0-5.428 3.085-9.828 6.89-9.828h81.556c3.805 0 6.889 4.4 6.889 9.829v22.829c0 5.429-3.084 9.83-6.889 9.83z'
          fill='#EA971E'
        />
        <path
          d='M105.028 5.756H64.652v42.488h40.376c3.805 0 6.89-4.401 6.89-9.83V15.585c-.001-5.428-3.085-9.829-6.89-9.829z'
          fill='#C27526'
        />
        <path
          d='M105.028 53H23.472c-5.637 0-10.223-6.543-10.223-14.586V15.585C13.25 7.543 17.835 1 23.472 1h81.556c5.637 0 10.223 6.543 10.223 14.585v22.83c0 8.042-4.586 14.584-10.223 14.584zM23.472 10.511c-1.96 0-3.556 2.276-3.556 5.074v22.829c0 2.797 1.595 5.073 3.556 5.073h81.556c1.961 0 3.556-2.276 3.556-5.073v-22.83c0-2.797-1.595-5.073-3.556-5.073H23.472z'
          fill='#F7DC4D'
        />
        <path
          d='M105.028 1H64.652v9.512h40.376c1.961 0 3.556 2.276 3.556 5.074v22.829c0 2.797-1.595 5.073-3.556 5.073H64.652V53h40.376c5.637 0 10.223-6.543 10.223-14.585v-22.83C115.251 7.544 110.665 1 105.028 1z'
          fill='#EDA637'
        />
        <path
          d='M30.2 29.988l-3.171 7.688c-.152.34-.456.496-.804.496-.934 0-2.26-1.054-2.26-2.387 0-.28.066-.558.196-.868l3.759-8.277-3.607-8.245a2.305 2.305 0 01-.217-.961c0-1.302 1.238-2.294 2.194-2.294.478 0 .804.248 1.021.807l2.89 7.191 2.89-7.191c.216-.558.542-.807 1.02-.807.956 0 2.195.992 2.195 2.294 0 .31-.066.62-.218.961l-3.606 8.245 3.758 8.277c.13.31.196.589.196.868 0 1.333-1.326 2.387-2.26 2.387-.347 0-.673-.156-.804-.496l-3.171-7.688zM41.324 28.717c0-.186-.043-.34-.087-.434L36.74 17.651c-.044-.124-.044-.248-.044-.34 0-1.148 1.37-1.985 2.303-1.985.544 0 .74.31.978.9l3.042 7.966 3.02-7.967c.217-.589.434-.899.977-.899.934 0 2.303.837 2.303 1.984 0 .093 0 .217-.043.34L44.8 28.284a1.062 1.062 0 00-.087.434v7.78c0 .992-.847 1.488-1.695 1.488-.847 0-1.694-.496-1.694-1.488v-7.78zM53.946 33.77h5.91c.651 0 1.02 1.116 1.02 2.108s-.369 2.108-1.02 2.108H50.6c-.695 0-.977-.527-.977-1.271 0-.496.13-1.116.37-1.706l6.126-15.467h-5.04c-.653 0-1.022-.992-1.022-2.108 0-1.023.304-2.108 1.021-2.108h8.386c.652 0 .977.527.977 1.271 0 .496-.13 1.085-.369 1.705L53.946 33.77zM69.973 30.856v-8.214c0-5.332 2.325-7.316 5.323-7.316s5.344 1.984 5.344 7.316v8.214c0 5.332-2.346 7.315-5.344 7.315s-5.323-1.983-5.323-7.315zm7.278-8.214c0-2.14-.739-3.1-1.955-3.1-1.217 0-1.934.96-1.934 3.1v8.214c0 2.139.717 3.1 1.934 3.1 1.216 0 1.955-.961 1.955-3.1v-8.214zM82.334 30.856v-8.214c0-5.332 2.325-7.316 5.323-7.316s5.344 1.984 5.344 7.316v8.214c0 5.332-2.346 7.315-5.344 7.315s-5.323-1.983-5.323-7.315zm7.278-8.214c0-2.14-.738-3.1-1.955-3.1s-1.934.96-1.934 3.1v8.214c0 2.139.717 3.1 1.934 3.1 1.216 0 1.955-.961 1.955-3.1v-8.214zM94.695 30.856v-8.214c0-5.332 2.325-7.316 5.323-7.316s5.344 1.984 5.344 7.316v8.214c0 5.332-2.346 7.315-5.344 7.315s-5.323-1.983-5.323-7.315zm7.278-8.214c0-2.14-.738-3.1-1.955-3.1s-1.934.96-1.934 3.1v8.214c0 2.139.717 3.1 1.934 3.1 1.216 0 1.955-.961 1.955-3.1v-8.214z'
          fill='#2D2E30'
        />
        <path
          d='M37.36 8.927c1.228 0 2.223-1.42 2.223-3.17 0-1.752-.995-3.172-2.222-3.172-1.228 0-2.223 1.42-2.223 3.171s.995 3.171 2.223 3.171zM37.36 51.414c1.228 0 2.223-1.42 2.223-3.17 0-1.752-.995-3.172-2.222-3.172-1.228 0-2.223 1.42-2.223 3.171 0 1.752.995 3.171 2.223 3.171z'
          fill='#57565C'
        />
        <path
          d='M91.14 8.927c1.227 0 2.222-1.42 2.222-3.17 0-1.752-.995-3.172-2.223-3.172-1.227 0-2.222 1.42-2.222 3.171s.995 3.171 2.222 3.171zM91.14 51.414c1.227 0 2.222-1.42 2.222-3.17 0-1.752-.995-3.172-2.223-3.172-1.227 0-2.222 1.42-2.222 3.171 0 1.752.995 3.171 2.222 3.171z'
          fill='#1D1D1F'
        />
        <path
          d='M25.25 43.4v-4.8h-2.703a9.54 9.54 0 00-1.028-2.475l1.913-1.913-3.394-3.394-1.914 1.913a9.542 9.542 0 00-2.475-1.029V29h-4.8v2.702a9.54 9.54 0 00-2.475 1.029l-1.913-1.913-3.394 3.394 1.913 1.913A9.541 9.541 0 003.952 38.6H1.249v4.8h2.703a9.54 9.54 0 001.028 2.475l-1.913 1.913 3.394 3.394 1.913-1.913a9.54 9.54 0 002.475 1.029V53h4.8v-2.702a9.54 9.54 0 002.475-1.029l1.913 1.913 3.395-3.394-1.914-1.913a9.54 9.54 0 001.029-2.475h2.702zm-12 1.2a3.6 3.6 0 110-7.2 3.6 3.6 0 010 7.2z'
          fill='#273B7A'
        />
        <path
          d='M13.25 46.818A5.825 5.825 0 017.43 41a5.825 5.825 0 015.818-5.818A5.825 5.825 0 0119.067 41a5.825 5.825 0 01-5.818 5.818zm0-10.424A4.611 4.611 0 008.642 41a4.611 4.611 0 004.606 4.606A4.611 4.611 0 0017.855 41a4.611 4.611 0 00-4.606-4.606z'
          fill='#FFC61B'
        />
      </g>
      <defs>
        <filter
          id='reference-number-setting-menu_svg__filter0_d_5586_193542'
          x={0.249}
          y={0}
          width={122.002}
          height={60}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_193542' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_193542' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgReferenceNumberSettingMenu;
