import { REACT_APP_API_URL_ROLE } from '../../../utils/configs/api.config';
import { DEFAULT_ALLOW_PARAMS_MANAGEMENT_ROLE } from '../../../utils/default/params.default';
import { creatorListService } from '../creator-services/creator-services.helper';

// list all role in company
const listRoles = async (params) => {
  return await creatorListService(
    REACT_APP_API_URL_ROLE,
    params,
    DEFAULT_ALLOW_PARAMS_MANAGEMENT_ROLE,
  );
};

const managementRolesService = {
  listRoles,
};

export default managementRolesService;
