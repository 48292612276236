import { red as redMuiColor } from '@mui/material/colors';

const red = {
  ...redMuiColor,
  C139: '#f97066',
  B25: '#fffbfa',
  B50: '#fef3f2',
  B100: '#fee4e2',
  B200: '#fecdca',
  B300: '#fda29b',
  B400: '#f97066',
  B500: '#f04438',
  B600: '#d92d20',
  B643: '#c92f00',
  B700: '#b42318',
  B800: '#912018',
  B900: '#7a271a',
};

export default red;
