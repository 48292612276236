import {
  NAME_STATE_DETAIL_PURCHASE_PAYMENT,
  REQUEST_STATUS_DETAILS_BILLING_INVOICE_FAILED,
  REQUEST_STATUS_DETAILS_BILLING_INVOICE_PENDING,
  REQUEST_STATUS_DETAILS_BILLING_INVOICE_SUCCESS,
  REQUEST_STATUS_DETAILS_PURCHASE_RECEIVABLE_PAYMENT_FAILED,
  REQUEST_STATUS_DETAILS_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
  REQUEST_STATUS_DETAILS_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_DETAILS_PURCHASE_RETURN_FAILED,
  REQUEST_STATUS_DETAILS_PURCHASE_RETURN_LOADING,
  REQUEST_STATUS_DETAILS_PURCHASE_RETURN_SUCCESS,
  REQUEST_STATUS_DETAILS_SALES_PAYABLE_PAYMENT_FAILED,
  REQUEST_STATUS_DETAILS_SALES_PAYABLE_PAYMENT_LOADING,
  REQUEST_STATUS_DETAILS_SALES_PAYABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_DETAILS_SALES_RETURN_FAILED,
  REQUEST_STATUS_DETAILS_SALES_RETURN_LOADING,
  REQUEST_STATUS_DETAILS_SALES_RETURN_SUCCESS,
  ROOT_STORE_NAME_ACCOUNT,
  ROOT_STORE_NAME_ASSET,
  ROOT_STORE_NAME_BILLING,
  ROOT_STORE_NAME_CASH_BANK,
  ROOT_STORE_NAME_CLOSING_BOOK,
  ROOT_STORE_NAME_CONTACT,
  ROOT_STORE_NAME_EXPENSE,
  ROOT_STORE_NAME_MANAGEMENT_USERS,
  ROOT_STORE_NAME_MINUTES_OF_HANDOVER,
  ROOT_STORE_NAME_PRODUCT,
  ROOT_STORE_NAME_PURCHASE,
  ROOT_STORE_NAME_SALES,
  ROOT_STORE_NAME_STOCK_ADJUSTMENT,
  ROOT_STORE_NAME_STOCK_TRANSFER,
  SLICE_NAME_ACCOUNT_DETAILS,
  SLICE_NAME_ASSET_MANAGEMENT_DETAIL,
  SLICE_NAME_BILLING_INVOICE_DETAILS,
  SLICE_NAME_CLOSING_BOOK_DETAILS,
  SLICE_NAME_DETAIL_CASH_BANK_CASH_PAYMENT,
  SLICE_NAME_DETAIL_CASH_BANK_CASH_RECEIPT,
  SLICE_NAME_DETAIL_CASH_BANK_CASH_TRANSFER,
  SLICE_NAME_DETAIL_CONTACT,
  SLICE_NAME_DETAIL_PURCHASE_INVOICE,
  SLICE_NAME_DETAIL_PURCHASE_ORDER,
  SLICE_NAME_EXPENSE_DETAIL,
  SLICE_NAME_HANDOVER_PURCHASE_DELIVERY_DETAILS,
  SLICE_NAME_HANDOVER_SALES_DELIVERY_DETAILS,
  SLICE_NAME_MANAGEMENT_USER_DETAILS,
  SLICE_NAME_PRODUCT_DETAILS,
  SLICE_NAME_PURCHASE_DEBIT_NOTE_DETAILS,
  SLICE_NAME_PURCHASE_DELIVERY_DETAIL,
  SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_DETAILS,
  SLICE_NAME_PURCHASE_RETURN_DETAILS,
  SLICE_NAME_QUOTATION_REQUEST_DETAIL,
  SLICE_NAME_SALES_CREDIT_NOTE_DETAILS,
  SLICE_NAME_SALES_DELIVERY_DETAILS,
  SLICE_NAME_SALES_INVOICE_DETAILS,
  SLICE_NAME_SALES_ORDER_DETAILS,
  SLICE_NAME_SALES_PAYABLE_PAYMENT_DETAILS,
  SLICE_NAME_SALES_PAYMENT_DETAIL,
  SLICE_NAME_SALES_QUOTATION_DETAIL,
  SLICE_NAME_SALES_RETURN_DETAILS,
  SLICE_NAME_STOCK_ADJUSTMENT_DETAILS,
  SLICE_NAME_STOCK_TRANSFER_DETAILS,
  STATUS_REQUEST_DETAIL_ASSET_MANAGEMENT_FAILED,
  STATUS_REQUEST_DETAIL_ASSET_MANAGEMENT_PENDING,
  STATUS_REQUEST_DETAIL_ASSET_MANAGEMENT_SUCCESS,
  STATUS_REQUEST_DETAIL_CASH_TRANSFER_FAILED,
  STATUS_REQUEST_DETAIL_CASH_TRANSFER_PENDING,
  STATUS_REQUEST_DETAIL_CASH_TRANSFER_SUCCESS,
  STATUS_REQUEST_DETAIL_CONTACT_DATA_FAILED,
  STATUS_REQUEST_DETAIL_CONTACT_DATA_PENDING,
  STATUS_REQUEST_DETAIL_CONTACT_DATA_SUCCESS,
  STATUS_REQUEST_DETAIL_EXPENSE_FAILED,
  STATUS_REQUEST_DETAIL_EXPENSE_PENDING,
  STATUS_REQUEST_DETAIL_EXPENSE_SUCCESS,
  STATUS_REQUEST_DETAIL_PAYMENT_CASH_FAILED,
  STATUS_REQUEST_DETAIL_PAYMENT_CASH_PENDING,
  STATUS_REQUEST_DETAIL_PAYMENT_CASH_SUCCESS,
  STATUS_REQUEST_DETAIL_PRODUCT_DATA_FAILED,
  STATUS_REQUEST_DETAIL_PRODUCT_DATA_PENDING,
  STATUS_REQUEST_DETAIL_PRODUCT_DATA_SUCCESS,
  STATUS_REQUEST_DETAIL_PURCHASE_DELIVERY_FAILED,
  STATUS_REQUEST_DETAIL_PURCHASE_DELIVERY_PENDING,
  STATUS_REQUEST_DETAIL_PURCHASE_DELIVERY_SUCCESS,
  STATUS_REQUEST_DETAIL_PURCHASE_INVOICE_FAILED,
  STATUS_REQUEST_DETAIL_PURCHASE_INVOICE_PENDING,
  STATUS_REQUEST_DETAIL_PURCHASE_INVOICE_SUCCESS,
  STATUS_REQUEST_DETAIL_PURCHASE_ORDER_FAILED,
  STATUS_REQUEST_DETAIL_PURCHASE_ORDER_PENDING,
  STATUS_REQUEST_DETAIL_PURCHASE_ORDER_SUCCESS,
  STATUS_REQUEST_DETAIL_PURCHASE_PAYMENT_FAILED,
  STATUS_REQUEST_DETAIL_PURCHASE_PAYMENT_PENDING,
  STATUS_REQUEST_DETAIL_PURCHASE_PAYMENT_SUCCESS,
  STATUS_REQUEST_DETAIL_QUOTATION_REQUEST_FAILED,
  STATUS_REQUEST_DETAIL_QUOTATION_REQUEST_PENDING,
  STATUS_REQUEST_DETAIL_QUOTATION_REQUEST_SUCCESS,
  STATUS_REQUEST_DETAIL_RECEIPT_CASH_FAILED,
  STATUS_REQUEST_DETAIL_RECEIPT_CASH_PENDING,
  STATUS_REQUEST_DETAIL_RECEIPT_CASH_SUCCESS,
  STATUS_REQUEST_DETAIL_SALES_DELIVERY_FAILED,
  STATUS_REQUEST_DETAIL_SALES_DELIVERY_PENDING,
  STATUS_REQUEST_DETAIL_SALES_DELIVERY_SUCCESS,
  STATUS_REQUEST_DETAIL_SALES_INVOICE_FAILED,
  STATUS_REQUEST_DETAIL_SALES_INVOICE_PENDING,
  STATUS_REQUEST_DETAIL_SALES_INVOICE_SUCCESS,
  STATUS_REQUEST_DETAIL_SALES_ORDER_FAILED,
  STATUS_REQUEST_DETAIL_SALES_ORDER_PENDING,
  STATUS_REQUEST_DETAIL_SALES_ORDER_SUCCESS,
  STATUS_REQUEST_DETAIL_SALES_PAYMENT_FAILED,
  STATUS_REQUEST_DETAIL_SALES_PAYMENT_PENDING,
  STATUS_REQUEST_DETAIL_SALES_PAYMENT_SUCCESS,
  STATUS_REQUEST_DETAIL_SALES_QUOTATION_FAILED,
  STATUS_REQUEST_DETAIL_SALES_QUOTATION_PENDING,
  STATUS_REQUEST_DETAIL_SALES_QUOTATION_SUCCESS,
  STATUS_REQUEST_DETAIL_STOCK_TRANSFER_FAILED,
  STATUS_REQUEST_DETAIL_STOCK_TRANSFER_PENDING,
  STATUS_REQUEST_DETAIL_STOCK_TRANSFER_SUCCESS,
  STATUS_REQUEST_DETAIL_USER_MANAGEMENT_USERS_FAILED,
  STATUS_REQUEST_DETAIL_USER_MANAGEMENT_USERS_PENDING,
  STATUS_REQUEST_DETAIL_USER_MANAGEMENT_USERS_SUCCESS,
  STATUS_REQUEST_DETAILS_ACCOUNT_DATA_FAILED,
  STATUS_REQUEST_DETAILS_ACCOUNT_DATA_PENDING,
  STATUS_REQUEST_DETAILS_ACCOUNT_DATA_SUCCESS,
  STATUS_REQUEST_DETAILS_CLOSING_BOOK_DATA_FAILED,
  STATUS_REQUEST_DETAILS_CLOSING_BOOK_DATA_PENDING,
  STATUS_REQUEST_DETAILS_CLOSING_BOOK_DATA_SUCCESS,
  STATUS_REQUEST_DETAILS_HANDOVER_PURCHASE_DELIVERY_FAILED,
  STATUS_REQUEST_DETAILS_HANDOVER_PURCHASE_DELIVERY_PENDING,
  STATUS_REQUEST_DETAILS_HANDOVER_PURCHASE_DELIVERY_SUCCESS,
  STATUS_REQUEST_DETAILS_HANDOVER_SALES_DELIVERY_FAILED,
  STATUS_REQUEST_DETAILS_HANDOVER_SALES_DELIVERY_PENDING,
  STATUS_REQUEST_DETAILS_HANDOVER_SALES_DELIVERY_SUCCESS,
  STATUS_REQUEST_DETAILS_PURCHASE_DEBIT_NOTE_FAILED,
  STATUS_REQUEST_DETAILS_PURCHASE_DEBIT_NOTE_PENDING,
  STATUS_REQUEST_DETAILS_PURCHASE_DEBIT_NOTE_SUCCESS,
  STATUS_REQUEST_DETAILS_SALES_CREDIT_NOTE_FAILED,
  STATUS_REQUEST_DETAILS_SALES_CREDIT_NOTE_PENDING,
  STATUS_REQUEST_DETAILS_SALES_CREDIT_NOTE_SUCCESS,
  STATUS_REQUEST_DETAILS_STOCK_ADJUSTMENT_FAILED,
  STATUS_REQUEST_DETAILS_STOCK_ADJUSTMENT_PENDING,
  STATUS_REQUEST_DETAILS_STOCK_ADJUSTMENT_SUCCESS,
} from '../constants/store.constant';
import { purchaseInvoicePlainStatus } from '../data/label-value/purchase-status';
import { salesInvoicePlainStatus } from '../data/label-value/sales-status';

import {
  DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES,
  DEFAULT_KEY_NAME_REQUEST_STATUS,
  DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
  DEFAULT_KEY_NAME_STATUS_REQUEST,
} from './object-keyname.default';
import { DEFAULT_TRANSACTION_NUMBER_CODE_CLOSING_BOOK } from './transaction-number.default';

const defaultConnectGlobalStateProperties = {
  parentState: '',
  childrenState: '',
  keynameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
  requestStatusPending: '',
  requestStatusSuccess: '',
  requestStatusFailed: '',
  breadcrumbTemplate: '',
  // handling not found breadcrumb templating
  breadcrumbDefault: {},
  keynameRequestStatusParallel: DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
  usageRequestStatusParallel: false,
  previousIndexFromCurrentPath: undefined,
  // determine for generalize / formatting property details
  usageGeneralizePropertyDetails: false,
  // keyname on format helper
  formatHelpKeyname: '',
  useChipRepresentationData: false,
  chipRepresentationData: [],
  compareChildrenValueKeyname: 'status',
  compareChipValueKeyname: 'value',
  chipLabelKeyname: 'label',
  chipColorKeyname: 'colorChip',
  // determine usage translation
  useTypographyTranslation: false,
  typographyTranslation: '',
  // can multiple keyname for picking object
  useFormatHelpKeynameProperties: false,
  keynameProperties: [],
  formatHelpKeynameProperties: [],
  alterInnerHTML: false,
  containerAlterInnerHTMLstyles: {},
};

// product
export const connectGlobalStatePropertiesProductDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_PRODUCT,
    childrenState: SLICE_NAME_PRODUCT_DETAILS,
    requestStatusPending: STATUS_REQUEST_DETAIL_PRODUCT_DATA_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAIL_PRODUCT_DATA_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAIL_PRODUCT_DATA_FAILED,
    breadcrumbTemplate: '{product_code}',
  },
};

// account
export const connectGlobalStatePropertiesAccountDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_ACCOUNT,
    childrenState: SLICE_NAME_ACCOUNT_DETAILS,
    requestStatusPending: STATUS_REQUEST_DETAILS_ACCOUNT_DATA_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAILS_ACCOUNT_DATA_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAILS_ACCOUNT_DATA_FAILED,
    breadcrumbTemplate: '{account_code}',
    usageRequestStatusParallel: true,
  },
};

// account
export const connectGlobalStatePropertiesContactDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_CONTACT,
    childrenState: SLICE_NAME_DETAIL_CONTACT,
    requestStatusPending: STATUS_REQUEST_DETAIL_CONTACT_DATA_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAIL_CONTACT_DATA_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAIL_CONTACT_DATA_FAILED,
    breadcrumbTemplate: '{contact_code}',
    usageRequestStatusParallel: false,
  },
};

// stock adjustment
export const connectGlobalStatePropertiesStockAdjustmentDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_STOCK_ADJUSTMENT,
    childrenState: SLICE_NAME_STOCK_ADJUSTMENT_DETAILS,
    requestStatusPending: STATUS_REQUEST_DETAILS_STOCK_ADJUSTMENT_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAILS_STOCK_ADJUSTMENT_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAILS_STOCK_ADJUSTMENT_FAILED,
    breadcrumbTemplate: '{stock_adjustment_no}',
  },
};

// warehouse transfer
export const connectGlobalStatePropertiesWarehouseTransferDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_STOCK_TRANSFER,
    childrenState: SLICE_NAME_STOCK_TRANSFER_DETAILS,
    requestStatusPending: STATUS_REQUEST_DETAIL_STOCK_TRANSFER_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAIL_STOCK_TRANSFER_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAIL_STOCK_TRANSFER_FAILED,
    breadcrumbTemplate: '{transaction_no}',
  },
};

// cash bank account transaction
export const connectGlobalStatePropertiesAccountTransactionCashBankList = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_ACCOUNT,
    childrenState: SLICE_NAME_ACCOUNT_DETAILS,
    requestStatusPending: STATUS_REQUEST_DETAILS_ACCOUNT_DATA_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAILS_ACCOUNT_DATA_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAILS_ACCOUNT_DATA_FAILED,
    breadcrumbTemplate: '{account_code} {account_name}',
    usageRequestStatusParallel: true,
    previousIndexFromCurrentPath: 0,
  },
};

// cash transfer
export const connectGlobalStatePropertiesCashTransferDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_CASH_BANK,
    childrenState: SLICE_NAME_DETAIL_CASH_BANK_CASH_TRANSFER,
    requestStatusPending: STATUS_REQUEST_DETAIL_CASH_TRANSFER_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAIL_CASH_TRANSFER_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAIL_CASH_TRANSFER_FAILED,
    breadcrumbTemplate: '{transaction_no}',
    usageRequestStatusParallel: false,
  },
};

// cash receipt
export const connectGlobalStatePropertiesCashReceiptDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_CASH_BANK,
    childrenState: SLICE_NAME_DETAIL_CASH_BANK_CASH_RECEIPT,
    requestStatusPending: STATUS_REQUEST_DETAIL_RECEIPT_CASH_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAIL_RECEIPT_CASH_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAIL_RECEIPT_CASH_FAILED,
    breadcrumbTemplate: '{transaction_no}',
    usageRequestStatusParallel: false,
  },
};

// cash payment
export const connectGlobalStatePropertiesCashPaymentDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_CASH_BANK,
    childrenState: SLICE_NAME_DETAIL_CASH_BANK_CASH_PAYMENT,
    requestStatusPending: STATUS_REQUEST_DETAIL_PAYMENT_CASH_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAIL_PAYMENT_CASH_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAIL_PAYMENT_CASH_FAILED,
    breadcrumbTemplate: '{transaction_no}',
    usageRequestStatusParallel: false,
  },
};

// sales
export const connectGlobalStatePropertiesSalesQuotationDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_SALES,
    childrenState: SLICE_NAME_SALES_QUOTATION_DETAIL,
    requestStatusPending: STATUS_REQUEST_DETAIL_SALES_QUOTATION_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAIL_SALES_QUOTATION_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAIL_SALES_QUOTATION_FAILED,
    breadcrumbTemplate: '{transaction_no}',
  },
};

export const connectGlobalStatePropertiesSalesOrderDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_SALES,
    childrenState: SLICE_NAME_SALES_ORDER_DETAILS,
    requestStatusPending: STATUS_REQUEST_DETAIL_SALES_ORDER_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAIL_SALES_ORDER_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAIL_SALES_ORDER_FAILED,
    breadcrumbTemplate: '{sales_order_no}',
  },
};

export const connectGlobalStatePropertiesSalesDeliveryDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_SALES,
    childrenState: SLICE_NAME_SALES_DELIVERY_DETAILS,
    requestStatusPending: STATUS_REQUEST_DETAIL_SALES_DELIVERY_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAIL_SALES_DELIVERY_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAIL_SALES_DELIVERY_FAILED,
    breadcrumbTemplate: '{sales_delivery_no}',
  },
};

export const connectGlobalStatePropertiesSalesInvoiceDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_SALES,
    childrenState: SLICE_NAME_SALES_INVOICE_DETAILS,
    requestStatusPending: STATUS_REQUEST_DETAIL_SALES_INVOICE_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAIL_SALES_INVOICE_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAIL_SALES_INVOICE_FAILED,
    breadcrumbTemplate: '{sales_invoice_no}',
    useChipRepresentationData: true,
    chipRepresentationData: salesInvoicePlainStatus,
  },
};

export const connectGlobalStatePropertiesSalesPaymentDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_SALES,
    childrenState: SLICE_NAME_SALES_PAYMENT_DETAIL,
    requestStatusPending: STATUS_REQUEST_DETAIL_SALES_PAYMENT_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAIL_SALES_PAYMENT_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAIL_SALES_PAYMENT_FAILED,
    breadcrumbTemplate: '{sales_payment_no}',
  },
};

export const connectGlobalStatePropertiesSalesReturnDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_SALES,
    childrenState: SLICE_NAME_SALES_RETURN_DETAILS,
    requestStatusPending: REQUEST_STATUS_DETAILS_SALES_RETURN_LOADING,
    requestStatusSuccess: REQUEST_STATUS_DETAILS_SALES_RETURN_SUCCESS,
    requestStatusFailed: REQUEST_STATUS_DETAILS_SALES_RETURN_FAILED,
    breadcrumbTemplate: '{transaction_no}',
  },
};

export const connectGlobalStatePropertiesSalesCreditNoteDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_SALES,
    childrenState: SLICE_NAME_SALES_CREDIT_NOTE_DETAILS,
    requestStatusPending: STATUS_REQUEST_DETAILS_SALES_CREDIT_NOTE_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAILS_SALES_CREDIT_NOTE_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAILS_SALES_CREDIT_NOTE_FAILED,
    breadcrumbTemplate: '{transaction_no}',
  },
};

export const connectGlobalStatePropertiesSalesPayablePaymentDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_SALES,
    childrenState: SLICE_NAME_SALES_PAYABLE_PAYMENT_DETAILS,
    requestStatusPending: REQUEST_STATUS_DETAILS_SALES_PAYABLE_PAYMENT_LOADING,
    requestStatusSuccess: REQUEST_STATUS_DETAILS_SALES_PAYABLE_PAYMENT_SUCCESS,
    requestStatusFailed: REQUEST_STATUS_DETAILS_SALES_PAYABLE_PAYMENT_FAILED,
    breadcrumbTemplate: '{transaction_no}',
  },
};

// purchase
export const connectGlobalStatePropertiesPurchaseQuotationRequestDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_PURCHASE,
    childrenState: SLICE_NAME_QUOTATION_REQUEST_DETAIL,
    requestStatusPending: STATUS_REQUEST_DETAIL_QUOTATION_REQUEST_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAIL_QUOTATION_REQUEST_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAIL_QUOTATION_REQUEST_FAILED,
    breadcrumbTemplate: '{transaction_no}',
  },
};

export const connectGlobalStatePropertiesPurchaseOrderDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_PURCHASE,
    childrenState: SLICE_NAME_DETAIL_PURCHASE_ORDER,
    requestStatusPending: STATUS_REQUEST_DETAIL_PURCHASE_ORDER_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAIL_PURCHASE_ORDER_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAIL_PURCHASE_ORDER_FAILED,
    breadcrumbTemplate: '{purchase_order_no}',
  },
};

export const connectGlobalStatePropertiesPurchaseDeliveryDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_PURCHASE,
    childrenState: SLICE_NAME_PURCHASE_DELIVERY_DETAIL,
    requestStatusPending: STATUS_REQUEST_DETAIL_PURCHASE_DELIVERY_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAIL_PURCHASE_DELIVERY_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAIL_PURCHASE_DELIVERY_FAILED,
    breadcrumbTemplate: '{purchase_delivery_no}',
  },
};

export const connectGlobalStatePropertiesPurchaseInvoiceDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_PURCHASE,
    childrenState: SLICE_NAME_DETAIL_PURCHASE_INVOICE,
    requestStatusPending: STATUS_REQUEST_DETAIL_PURCHASE_INVOICE_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAIL_PURCHASE_INVOICE_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAIL_PURCHASE_INVOICE_FAILED,
    breadcrumbTemplate: '{purchase_invoice_no}',
    useChipRepresentationData: true,
    chipRepresentationData: purchaseInvoicePlainStatus,
  },
};

export const connectGlobalStatePropertiesPurchasePaymentDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_PURCHASE,
    childrenState: NAME_STATE_DETAIL_PURCHASE_PAYMENT,
    requestStatusPending: STATUS_REQUEST_DETAIL_PURCHASE_PAYMENT_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAIL_PURCHASE_PAYMENT_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAIL_PURCHASE_PAYMENT_FAILED,
    breadcrumbTemplate: '{purchase_payment_no}',
  },
};

export const connectGlobalStatePropertiesPurchaseReturnDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_PURCHASE,
    childrenState: SLICE_NAME_PURCHASE_RETURN_DETAILS,
    requestStatusPending: REQUEST_STATUS_DETAILS_PURCHASE_RETURN_LOADING,
    requestStatusSuccess: REQUEST_STATUS_DETAILS_PURCHASE_RETURN_SUCCESS,
    requestStatusFailed: REQUEST_STATUS_DETAILS_PURCHASE_RETURN_FAILED,
    breadcrumbTemplate: '{transaction_no}',
  },
};

export const connectGlobalStatePropertiesPurchaseReceivablePaymentDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_PURCHASE,
    childrenState: SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_DETAILS,
    requestStatusPending: REQUEST_STATUS_DETAILS_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
    requestStatusSuccess: REQUEST_STATUS_DETAILS_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS,
    requestStatusFailed: REQUEST_STATUS_DETAILS_PURCHASE_RECEIVABLE_PAYMENT_FAILED,
    breadcrumbTemplate: '{transaction_no}',
  },
};

export const connectGlobalStatePropertiesPurchaseDebitNoteDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_PURCHASE,
    childrenState: SLICE_NAME_PURCHASE_DEBIT_NOTE_DETAILS,
    requestStatusPending: STATUS_REQUEST_DETAILS_PURCHASE_DEBIT_NOTE_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAILS_PURCHASE_DEBIT_NOTE_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAILS_PURCHASE_DEBIT_NOTE_FAILED,
    breadcrumbTemplate: '{transaction_no}',
  },
};

// purchase - sales handover
export const connectGlobalStatePropertiesMinutesHandoverPurchaseDeliveryDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_MINUTES_OF_HANDOVER,
    keynameRequestStatus: DEFAULT_KEY_NAME_REQUEST_STATUS,
    childrenState: SLICE_NAME_HANDOVER_PURCHASE_DELIVERY_DETAILS,
    requestStatusPending: STATUS_REQUEST_DETAILS_HANDOVER_PURCHASE_DELIVERY_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAILS_HANDOVER_PURCHASE_DELIVERY_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAILS_HANDOVER_PURCHASE_DELIVERY_FAILED,
    breadcrumbTemplate: '{purchase_handover_no}',
    previousIndexFromCurrentPath: 0,
  },
};

export const connectGlobalStatePropertiesMinutesHandoverSalesDeliveryDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_MINUTES_OF_HANDOVER,
    keynameRequestStatus: DEFAULT_KEY_NAME_REQUEST_STATUS,
    childrenState: SLICE_NAME_HANDOVER_SALES_DELIVERY_DETAILS,
    requestStatusPending: STATUS_REQUEST_DETAILS_HANDOVER_SALES_DELIVERY_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAILS_HANDOVER_SALES_DELIVERY_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAILS_HANDOVER_SALES_DELIVERY_FAILED,
    breadcrumbTemplate: '{sales_handover_no}',
    previousIndexFromCurrentPath: 0,
  },
};

// expense
export const connectGlobalStatePropertiesExpenseDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_EXPENSE,
    childrenState: SLICE_NAME_EXPENSE_DETAIL,
    requestStatusPending: STATUS_REQUEST_DETAIL_EXPENSE_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAIL_EXPENSE_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAIL_EXPENSE_FAILED,
    breadcrumbTemplate: '{transaction_no}',
  },
};

// user details
export const connectGlobalStatePropertiesSettingUserDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_MANAGEMENT_USERS,
    childrenState: SLICE_NAME_MANAGEMENT_USER_DETAILS,
    requestStatusPending: STATUS_REQUEST_DETAIL_USER_MANAGEMENT_USERS_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAIL_USER_MANAGEMENT_USERS_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAIL_USER_MANAGEMENT_USERS_FAILED,
    breadcrumbTemplate: '{user_fullname}',
  },
};

// closing book details
export const connectGlobalStatePropertiesSettingClosingBookDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_CLOSING_BOOK,
    childrenState: SLICE_NAME_CLOSING_BOOK_DETAILS,
    keynameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    requestStatusPending: STATUS_REQUEST_DETAILS_CLOSING_BOOK_DATA_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAILS_CLOSING_BOOK_DATA_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAILS_CLOSING_BOOK_DATA_FAILED,
    breadcrumbDefault: {
      transaction_no: DEFAULT_TRANSACTION_NUMBER_CODE_CLOSING_BOOK,
    },
    breadcrumbTemplate:
      "{transaction_no} <span style='font-size: 13px!important'>({periode_start}-{periode_end})</span>",
    alterInnerHTML: true,
    containerAlterInnerHTMLstyles: {
      display: 'flex',
      gap: '4px',
      alignItems: 'center',
      height: 'max-content',
    },
    useTypographyTranslation: true,
    typographyTranslation: 'typography.period',
    keynameProperties: ['periode_start', 'periode_end'],
    useFormatHelpKeynameProperties: true,
    formatHelpKeynameProperties: ['getReadableDateV2', 'getReadableDateV2'],
  },
};

// billing
export const connectGlobalStatePropertiesBillingDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_BILLING,
    childrenState: SLICE_NAME_BILLING_INVOICE_DETAILS,
    keynameRequestStatus: DEFAULT_KEY_NAME_REQUEST_STATUS,
    requestStatusPending: REQUEST_STATUS_DETAILS_BILLING_INVOICE_PENDING,
    requestStatusSuccess: REQUEST_STATUS_DETAILS_BILLING_INVOICE_SUCCESS,
    requestStatusFailed: REQUEST_STATUS_DETAILS_BILLING_INVOICE_FAILED,
    breadcrumbTemplate: '{translation}',
    useTypographyTranslation: true,
    typographyTranslation: 'typography.bill',
  },
};

// asset
export const connectGlobalStatePropertiesAssetManagementDetails = {
  [DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES]: {
    ...defaultConnectGlobalStateProperties,
    parentState: ROOT_STORE_NAME_ASSET,
    childrenState: SLICE_NAME_ASSET_MANAGEMENT_DETAIL,
    keynameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    requestStatusPending: STATUS_REQUEST_DETAIL_ASSET_MANAGEMENT_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DETAIL_ASSET_MANAGEMENT_SUCCESS,
    requestStatusFailed: STATUS_REQUEST_DETAIL_ASSET_MANAGEMENT_FAILED,
    breadcrumbTemplate: '{asset_number}',
  },
};
