import {
  FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN,
  FORMAT_DATE_COLUMN,
} from '../../../components/table-row/TableRowMultipleTableDataPureMarkup/TableRowMultipleTableDataPureMarkup';
import { FORMAT_CURRENCY_AUTO_COMMA_COLUMN } from '../../../components/table-row/TableRowPureMarkup/TableRowPureMarkup';

import { primaryTableDataCalculationNumber } from './additional-styles';

// column purchase order detail that handling table head with translation
// @param: p, function for translation
export const columnsTableHeaderPurchaseOrderDetail = (t) => {
  return [
    {
      name: 'product_code',
      label: t('dialog.purchase-order-detail.table.header-column.product-code'),
      width: 150,
    },
    {
      name: 'product_name',
      label: t('dialog.purchase-order-detail.table.header-column.product-name'),
      width: 150,
    },
    {
      name: 'description',
      label: t('dialog.purchase-order-detail.table.header-column.description'),
      width: 250,
      align: 'left',
    },
    {
      name: 'quantity',
      label: t('dialog.purchase-order-detail.table.header-column.quantity'),
      align: 'right',
      width: 75,
    },
    {
      name: 'product_unit',
      label: t('dialog.purchase-order-detail.table.header-column.unit'),
      align: 'left',
      width: 75,
    },
    {
      name: 'price',
      label: t('dialog.purchase-order-detail.table.header-column.price'),
      align: 'right',
      width: 150,
    },
    {
      name: 'amount',
      label: t('dialog.purchase-order-detail.table.header-column.amount'),
      align: 'right',
      width: 150,
    },
  ];
};

// additional props on table head main
export const additionalPropsPurchaseOrderDetail = {
  sx: {
    backgroundColor: '#EFF8FF!important',
  },
};

// colum that representation for calculation
export const calculationColumns = ['price', 'amount'];

// on representation total row
export const totalColumnName = ['unit', ...calculationColumns];

// columns key value table
export const columnsTableKeyValuePrintPurchaseOrderDetails = [
  {
    headerTranslated: 'No.',
    valueKeyName: 'purchase_order_no',
  },
  {
    header: 'table.header-column.date',
    valueKeyName: 'transaction_date',
    formatValue: FORMAT_DATE_COLUMN,
  },
  {
    header: 'table.header-column.supplier',
    valueKeyName: 'supplier_name',
  },
  {
    header: 'table.header-column.address',
    valueKeyName: 'supplier_address',
  },
];

export const columnsTableProductPurchaseOrderDetails = (t) => {
  return [
    {
      isAutoIncrementColumn: true,
      label: t('No.'),
      align: 'center',
      style: {
        maxWidth: 20,
      },
    },
    {
      name: 'product_name',
      label: t('table.header-column.product-name'),
      style: {
        minWidth: 'auto',
        width: 593,
        maxWidth: 600,
      },
    },
    {
      name: 'quantity',
      label: t('table.header-column.qty'),
      style: {
        minWidth: 'auto',
        width: 20,
        maxWidth: 35,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'unit_destination',
      label: t('table.header-column.unit'),
      style: {
        minWidth: 'auto',
        width: 30,
        maxWidth: 35,
      },
    },
    {
      name: 'price',
      label: t('table.header-column.at-price'),
      style: {
        minWidth: 'auto',
        width: 192,
        maxWidth: 300,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'amount',
      label: t('table.header-column.amount'),
      style: {
        minWidth: 'auto',
        width: 192,
        maxWidth: 300,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
  ];
};

export const columnsTableFooterProductPurchaseOrderDetails = (t) => {
  return [
    [
      {
        isEmptyTableData: true,
        colSpan: 4,
      },
      {
        isLabel: true,
        label: t('typography.discount'),
      },
      {
        isValue: true,
        valueKeyName: 'discount_percentage',
        tableDataStyle: primaryTableDataCalculationNumber,
      },
    ],
    [
      {
        isEmptyTableData: true,
        colSpan: 4,
      },
      {
        isLabel: true,
        label: t('typography.total'),
      },
      {
        isValue: true,
        valueKeyName: 'amount',
        formatValue: FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN,
        tableDataStyle: primaryTableDataCalculationNumber,
      },
    ],
  ];
};

export const columnsTableFooterPurchaseOrderDetails = (t) => {
  return [
    {
      name: 'description',
      label: t('table.header-column.memo-colon'),
      style: {
        minWidth: 100,
        width: 360,
        maxWidth: 365,
        height: 56,
        verticalAlign: 'top',
      },
      isUseTableHeaderStyle: false,
    },
  ];
};
