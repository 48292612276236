import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import reportServices from '../../services/API/report/report.service';
import {
  LOCAL_STORAGE_REPORT_BALANCE_SHEET,
  LOCAL_STORAGE_REPORT_CAPITAL_CHANGE,
  LOCAL_STORAGE_REPORT_CASH_FLOW,
  LOCAL_STORAGE_REPORT_GENERAL_LEDGER,
  LOCAL_STORAGE_REPORT_INCOME_STATEMENT,
  LOCAL_STORAGE_REPORT_INDEX,
  LOCAL_STORAGE_REPORT_JOURNAL,
  LOCAL_STORAGE_REPORT_TRIAL_BALANCE,
} from '../../utils/constants/storage.constant';
import {
  SLICE_NAME_EXPORTED_DOCUMENT_REPORT,
  SLICE_NAME_REPORT_BALANCE_SHEET,
  SLICE_NAME_REPORT_CAPITAL_CHANGE,
  SLICE_NAME_REPORT_CASH_FLOW,
  SLICE_NAME_REPORT_GENERAL_LEDGER,
  SLICE_NAME_REPORT_INCOME_STATEMENT,
  SLICE_NAME_REPORT_INDEX_LIST,
  SLICE_NAME_REPORT_JOURNAL_LIST,
  SLICE_NAME_REPORT_TRIAL_BALANCE,
} from '../../utils/constants/store.constant';
import {
  DEFAULT_ACTION_PARAMS,
  DEFAULT_REPORT_INDEX_SEARCH_FE_PARAMS,
} from '../../utils/default/params.default';
import {
  getReportBasicConfiguration,
  REPORT_BASIC_TYPE_KEY_NAME,
  REPORT_BASIC_TYPE_PURCHASE,
} from '../../utils/default/report-basic-config.default';
import objHelper from '../../utils/helpers/object.helper';
import {
  creatorAddActionWithStoreDataOnSlice,
  creatorListAction,
  creatorListRefreshAction,
} from '../creator-action/creator-action.helper';

// action get report index list
export const getReportIndexList = createAsyncThunk(
  'report/report-index-list',
  async (paramsGetReportIndexList, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      paramsGetReportIndexList,
      reportServices.getListReportIndex,
      LOCAL_STORAGE_REPORT_INDEX,
      SLICE_NAME_REPORT_INDEX_LIST,
      'report_id',
      'error.list-data.report-index',
    );
  },
);

// action refresh get report index list
export const getReportIndexListRefresh = createAsyncThunk(
  'report/report-index-list-refresh',
  async (discardKeySearch = [], thunkAPI) => {
    return await creatorListRefreshAction(
      thunkAPI,
      reportServices.getListReportIndex,
      LOCAL_STORAGE_REPORT_INDEX,
      SLICE_NAME_REPORT_INDEX_LIST,
      discardKeySearch,
      DEFAULT_REPORT_INDEX_SEARCH_FE_PARAMS,
      'error.list-data.report-index',
    );
  },
);

// action for document report export
export const documentReportExport = createAsyncThunk(
  'report/report-export-document',
  async (paramsDocumentReportExport, thunkAPI) => {
    return await creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      paramsDocumentReportExport,
      reportServices.documentReportExport,
      SLICE_NAME_EXPORTED_DOCUMENT_REPORT,
      '',
      'error.export.document-report',
    );
  },
);

// action for report journal list
export const getReportJournalList = createAsyncThunk(
  'report/report-journal-list',
  async (paramsReportJournalList, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      paramsReportJournalList,
      reportServices.getReportJournalList,
      LOCAL_STORAGE_REPORT_JOURNAL,
      SLICE_NAME_REPORT_JOURNAL_LIST,
      'transaction_id',
      'error.list-data.report-journal',
    );
  },
);

// action for report income statement list
export const getReportIncomeStatementList = createAsyncThunk(
  'report/report-income-statement-list',
  async (paramsReportIncomeStatementList, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      paramsReportIncomeStatementList,
      reportServices.getReportIncomeStatementList,
      LOCAL_STORAGE_REPORT_INCOME_STATEMENT,
      SLICE_NAME_REPORT_INCOME_STATEMENT,
      'transaction_id',
      'error.list-data.report-income-statement',
    );
  },
);

// action for report balance sheet list
export const getReportBalanceSheetList = createAsyncThunk(
  'report/report-balance-sheet-list',
  async (paramsReportBalanceSheetList, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      paramsReportBalanceSheetList,
      reportServices.getReportBalanceSheetList,
      LOCAL_STORAGE_REPORT_BALANCE_SHEET,
      SLICE_NAME_REPORT_BALANCE_SHEET,
      'transaction_id',
      'error.list-data.report-balance-sheet',
    );
  },
);

// action for report capital change list
export const getReportCapitalChangeList = createAsyncThunk(
  'report/report-capital-change-list',
  async (paramsReportCapitalChangeList, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      paramsReportCapitalChangeList,
      reportServices.getReportCapitalChangeList,
      LOCAL_STORAGE_REPORT_CAPITAL_CHANGE,
      SLICE_NAME_REPORT_CAPITAL_CHANGE,
      'transaction_id',
      'error.list-data.report-capital-change',
    );
  },
);

// action for report cash flow list
export const getReportCashFlowList = createAsyncThunk(
  'report/report-cash-flow-list',
  async (paramsGetReportCashFlowList, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      paramsGetReportCashFlowList,
      reportServices.getReportCashFlowList,
      LOCAL_STORAGE_REPORT_CASH_FLOW,
      SLICE_NAME_REPORT_CASH_FLOW,
      'transaction_id',
      'error.list-data.report-cash-flow',
    );
  },
);

// action for report general ledger list
export const getReportGeneralLedgerList = createAsyncThunk(
  'report/report-general-ledger-list',
  async (paramsGetReportGeneralLedgerList, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      paramsGetReportGeneralLedgerList,
      reportServices.getReportGeneralLedgerList,
      LOCAL_STORAGE_REPORT_GENERAL_LEDGER,
      SLICE_NAME_REPORT_GENERAL_LEDGER,
      'transaction_id',
      'error.list-data.report-general-ledger',
    );
  },
);

// action for report trial balance list
export const getReportTrialBalanceList = createAsyncThunk(
  'report/report-trial-balance-list',
  async (paramsGetReportTrialBalanceList, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      paramsGetReportTrialBalanceList,
      reportServices.getReportTrialBalanceList,
      LOCAL_STORAGE_REPORT_TRIAL_BALANCE,
      SLICE_NAME_REPORT_TRIAL_BALANCE,
      'transaction_id',
      'error.list-data.report-trial-balance',
    );
  },
);

export const getReportBasicList = createAsyncThunk(
  'report/report-basic-list',
  async (
    { reportBasicType = REPORT_BASIC_TYPE_PURCHASE, ...paramsSearchReportBasic },
    thunkAPI,
  ) => {
    const { childrenSliceList, localStorageNameList, uniqueIdentityIDList } =
      getReportBasicConfiguration(reportBasicType);

    if (paramsSearchReportBasic.from_date && paramsSearchReportBasic.to_date) {
      paramsSearchReportBasic = objHelper.changeFormatValue(
        paramsSearchReportBasic,
        ['from_date', 'to_date'],
        [(date) => moment(date).format('YYYY-MM-DD')],
      );
    }

    return await creatorListAction(
      thunkAPI,
      {
        [REPORT_BASIC_TYPE_KEY_NAME]: reportBasicType,
        ...paramsSearchReportBasic,
      },
      reportServices.getReportBasicList,
      localStorageNameList,
      childrenSliceList,
      uniqueIdentityIDList,
      '',
      {},
      DEFAULT_ACTION_PARAMS,
      'data',
      'data',
      'search_',
      false,
    );
  },
);

const reportActions = {
  getReportIndexList,
  getReportIndexListRefresh,
  documentReportExport,
  getReportJournalList,
  getReportIncomeStatementList,
  getReportBalanceSheetList,
  getReportCapitalChangeList,
  getReportCashFlowList,
  getReportGeneralLedgerList,
  getReportTrialBalanceList,
  getReportBasicList,
};

export default reportActions;
