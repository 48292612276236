import * as React from 'react';
function SvgIconMenuReportTrialBalance(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.857 68.464c0 .415-.192.75-.428.75-.237 0-.429-.335-.429-.75 0-.414.192-.75.429-.75.236 0 .428.336.428.75zM6.857 67.714h58.286c.114 0 .222.08.303.22.08.14.125.331.125.53 0 .2-.045.39-.125.53-.08.141-.19.22-.303.22H6.857c-.114 0-.223-.079-.303-.22a1.094 1.094 0 01-.125-.53c0-.199.045-.39.125-.53s.19-.22.303-.22zM2.571 67.714h2.143c.114 0 .223.08.303.22.08.14.126.331.126.53 0 .2-.045.39-.126.53-.08.141-.19.22-.303.22H2.571c-.113 0-.222-.079-.303-.22a1.094 1.094 0 01-.125-.53c0-.199.045-.39.125-.53s.19-.22.303-.22zM69.429 67.714h-2.143c-.114 0-.223.08-.303.22-.08.14-.126.331-.126.53 0 .2.045.39.126.53.08.141.19.22.303.22h2.143c.113 0 .222-.079.303-.22.08-.14.125-.33.125-.53 0-.199-.045-.39-.125-.53s-.19-.22-.303-.22zM72 68.464c0 .415-.192.75-.429.75-.236 0-.428-.335-.428-.75 0-.414.192-.75.428-.75.237 0 .429.336.429.75zM45.297 24.75H18.339a.902.902 0 00-.657.289 1.022 1.022 0 00-.272.7c0 .262.097.513.272.699.174.185.41.29.657.29h26.958a.902.902 0 00.657-.29c.175-.186.273-.437.273-.7 0-.262-.098-.514-.273-.7a.902.902 0 00-.657-.289zM18.34 31.673h20.45a.902.902 0 00.657-.29c.175-.185.272-.437.272-.699 0-.262-.097-.514-.272-.7a.902.902 0 00-.657-.29H18.34a.902.902 0 00-.658.29 1.022 1.022 0 00-.272.7c0 .262.097.514.272.7.174.185.41.29.657.29z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.34 34.64H41v1.979H18.34a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.7c0-.261.097-.513.272-.699a.902.902 0 01.657-.29z'
        fill='#000'
      />
      <path
        d='M45.297 36.619a.902.902 0 00.657-.29c.175-.185.273-.437.273-.7 0-.261-.098-.513-.273-.699a.902.902 0 00-.657-.29H41v1.979h4.297zM18.34 41.565h20.45a.902.902 0 00.657-.29c.175-.185.272-.437.272-.7 0-.262-.097-.513-.272-.699a.902.902 0 00-.657-.29H18.34a.902.902 0 00-.658.29 1.022 1.022 0 00-.272.7c0 .262.097.514.272.699.174.186.41.29.657.29z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.34 44.532H36v1.979H18.34a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.7c0-.262.097-.514.272-.7a.902.902 0 01.657-.289zM34.5 51.456H18.34a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.699c0-.262.097-.514.272-.7a.902.902 0 01.657-.29H34.5v1.98zM18.34 54.424H33v1.978H18.34a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.7c0-.261.097-.513.272-.699a.902.902 0 01.657-.29zM18.34 59.37H33v1.978H18.34a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.7c0-.262.097-.513.272-.699a.902.902 0 01.657-.29z'
        fill='#000'
      />
      <path
        d='M45.297 14.858H18.339a.902.902 0 00-.657.29 1.022 1.022 0 00-.272.699c0 .262.097.514.272.7.174.185.41.289.657.289h26.958a.902.902 0 00.657-.29c.175-.185.273-.437.273-.7 0-.261-.098-.513-.273-.699a.902.902 0 00-.657-.29zM18.34 21.782h20.45a.902.902 0 00.657-.29c.175-.186.272-.437.272-.7 0-.262-.097-.513-.272-.699a.902.902 0 00-.657-.29H18.34a.902.902 0 00-.658.29 1.022 1.022 0 00-.272.7c0 .262.097.514.272.699.174.185.41.29.657.29z'
        fill='#000'
      />
      <path
        d='M35 69v-2.34H12.263a2.807 2.807 0 01-1.86-.685c-.494-.439-.771-1.034-.771-1.654V11.679c0-.62.277-1.215.77-1.654a2.807 2.807 0 011.861-.685h39.474c.698 0 1.367.246 1.86.685.494.439.771 1.034.771 1.654V35H57V11.68c-.002-1.241-.557-2.43-1.543-3.308-.987-.877-2.325-1.37-3.72-1.372H12.263c-1.395.001-2.733.495-3.72 1.372C7.557 9.25 7.002 10.44 7 11.68v52.642c.002 1.24.557 2.43 1.543 3.307.987.877 2.325 1.37 3.72 1.372H35z'
        fill='#000'
      />
      <path
        d='M41.91 47.781h1.415l-2.683-2.515 1.078-1 1.71 3.515h2l-1.038-8.266 2.358-.26v8.526h1v-7.5h10.5v7.5h1v-8.526l2.358.26-1.043 8.266h2l1.71-3.515 1.078 1.01-2.678 2.505h1.415l2.328-2.182a.466.466 0 00.146-.331.443.443 0 00-.146-.332l-1.696-1.59.46-.955a.443.443 0 00-.09-.515.502.502 0 00-.16-.106l-2.472-1.055.197-1.563a.449.449 0 00-.106-.339.51.51 0 00-.329-.172l-2.972-.33v-.842a.455.455 0 00-.146-.332.518.518 0 00-.354-.137h-11.5c-.133 0-.26.05-.354.137a.455.455 0 00-.146.332v.841l-2.972.33a.509.509 0 00-.334.176.448.448 0 00-.104.345l.197 1.563-2.472 1.055a.501.501 0 00-.16.106.466.466 0 00-.134.337.444.444 0 00.044.178l.46.95-1.695 1.588a.466.466 0 00-.147.332.443.443 0 00.147.332l2.33 2.18zM62.338 41.7l1.724.736-2.454 5.065.73-5.8zM47.75 37.938h10.5v1.406h-10.5v-1.407zm-4.09 3.761l.733 5.801-2.458-5.065 1.725-.736zM67 48.72H39c-.53 0-1.04.197-1.414.549A1.818 1.818 0 0037 50.594v14.531c0 .497.21.974.586 1.326.375.352.884.549 1.414.549h28c.53 0 1.04-.198 1.414-.55.375-.35.586-.828.586-1.325V50.594c0-.498-.21-.974-.586-1.326a2.07 2.07 0 00-1.414-.55zm1 16.406a.909.909 0 01-.293.663 1.035 1.035 0 01-.707.275H39c-.265 0-.52-.1-.707-.275a.909.909 0 01-.293-.663v-7.734h30v7.734zm0-8.672H38v-3.281h30v3.281zm0-4.219H38v-1.64a.91.91 0 01.293-.663c.187-.176.442-.275.707-.275h28c.265 0 .52.099.707.275a.91.91 0 01.293.663v1.64z'
        fill='#000'
      />
      <path
        d='M62.781 65c.637 0 1.259-.176 1.788-.506.53-.33.942-.798 1.186-1.346a2.81 2.81 0 00.183-1.733 2.942 2.942 0 00-.88-1.536 3.298 3.298 0 00-1.649-.821 3.438 3.438 0 00-1.86.17 3.18 3.18 0 00-1.445 1.105A2.857 2.857 0 0059.562 62a2.9 2.9 0 00.943 2.121A3.342 3.342 0 0062.78 65zm0-5.077c.44 0 .872.122 1.238.35.367.228.652.553.82.932.17.38.214.797.128 1.2a2.037 2.037 0 01-.61 1.064c-.312.29-.709.488-1.141.568a2.38 2.38 0 01-1.288-.118 2.202 2.202 0 01-1-.765A1.978 1.978 0 0160.552 62c0-.55.235-1.08.653-1.469.418-.39.985-.608 1.576-.608zm-22.286 1.385H45.2a.514.514 0 00.35-.136.446.446 0 00.145-.326.446.446 0 00-.145-.326.514.514 0 00-.35-.135h-4.705a.514.514 0 00-.35.135.446.446 0 00-.145.326c0 .123.052.24.145.327a.514.514 0 00.35.135zm0 2.307h7.181a.514.514 0 00.35-.135.446.446 0 00.145-.326.446.446 0 00-.145-.327.514.514 0 00-.35-.135h-7.18a.514.514 0 00-.35.136.446.446 0 00-.146.326c0 .122.052.24.145.326a.514.514 0 00.35.135zm9.162 0h1.981a.514.514 0 00.35-.135.446.446 0 00.145-.326.446.446 0 00-.145-.327.514.514 0 00-.35-.135h-1.98a.514.514 0 00-.351.136.446.446 0 00-.145.326c0 .122.052.24.145.326a.514.514 0 00.35.135z'
        fill='#000'
      />
    </svg>
  );
}
export default SvgIconMenuReportTrialBalance;
