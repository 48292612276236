import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { BASE_TYPES_PRODUCT_ACTION_REGISTERED } from '../../utils/constants/base-types-action-registered.constant';
import {
  LOCAL_STORAGE_PRODUCT_BY_SUPPLIER,
  LOCAL_STORAGE_PRODUCT_CATEGORY,
  LOCAL_STORAGE_PRODUCT_CATEGORY_PRINT,
  LOCAL_STORAGE_PRODUCT_DATA,
  LOCAL_STORAGE_PRODUCT_DATA_ALTERNATIVE,
  LOCAL_STORAGE_PRODUCT_PRINT_DATA,
  LOCAL_STORAGE_PRODUCT_PURCHASE_SALE,
} from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  REQUEST_STATUS_LIST_PRODUCT_MUTATION_FAILED,
  REQUEST_STATUS_LIST_PRODUCT_MUTATION_PENDING,
  REQUEST_STATUS_LIST_PRODUCT_MUTATION_SUCCESS,
  SLICE_NAME_DATA_PRODUCT_BY_SUPPLIER,
  SLICE_NAME_DATA_PRODUCT_IMPORTING_PROGRESS,
  SLICE_NAME_DATA_PRODUCT_UNIT_LIST,
  SLICE_NAME_PRODUCT_CATEGORY_PRINT_DATA,
  SLICE_NAME_PRODUCT_DETAILS,
  SLICE_NAME_PRODUCT_MUTATION_LIST,
  SLICE_NAME_PRODUCT_PRINT_DATA,
  SLICE_NAME_TEMP_ADD_PRODUCT_DATA,
  SLICE_NAME_TEMP_IMPORT_PRODUCT_DATA,
  STATUS_REQUEST_ADD_PRODUCT_CATEGORY_FAILED,
  STATUS_REQUEST_ADD_PRODUCT_CATEGORY_PENDING,
  STATUS_REQUEST_ADD_PRODUCT_CATEGORY_SUCCESS,
  STATUS_REQUEST_ADD_PRODUCT_DATA_FAILED,
  STATUS_REQUEST_ADD_PRODUCT_DATA_PENDING,
  STATUS_REQUEST_ADD_PRODUCT_DATA_SUCCESS,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_DELETE_PRODUCT_CATEGORY_FAILED,
  STATUS_REQUEST_DELETE_PRODUCT_CATEGORY_PENDING,
  STATUS_REQUEST_DELETE_PRODUCT_CATEGORY_SUCCESS,
  STATUS_REQUEST_DELETE_PRODUCT_DATA_FAILED,
  STATUS_REQUEST_DELETE_PRODUCT_DATA_PENDING,
  STATUS_REQUEST_DELETE_PRODUCT_DATA_SUCCESS,
  STATUS_REQUEST_DETAIL_PRODUCT_DATA_FAILED,
  STATUS_REQUEST_DETAIL_PRODUCT_DATA_PENDING,
  STATUS_REQUEST_DETAIL_PRODUCT_DATA_SUCCESS,
  STATUS_REQUEST_IMPORT_PRODUCT_DATA_FAILED,
  STATUS_REQUEST_IMPORT_PRODUCT_DATA_PENDING,
  STATUS_REQUEST_IMPORT_PRODUCT_DATA_SUCCESS,
  STATUS_REQUEST_IMPORT_PRODUCT_PROGRESS_DATA_FAILED,
  STATUS_REQUEST_IMPORT_PRODUCT_PROGRESS_DATA_PENDING,
  STATUS_REQUEST_IMPORT_PRODUCT_PROGRESS_DATA_SUCCESS,
  STATUS_REQUEST_LIST_PRODUCT_BY_SUPPLIER_FAILED,
  STATUS_REQUEST_LIST_PRODUCT_BY_SUPPLIER_PENDING,
  STATUS_REQUEST_LIST_PRODUCT_BY_SUPPLIER_SUCCESS,
  STATUS_REQUEST_LIST_PRODUCT_CATEGORY_FAILED,
  STATUS_REQUEST_LIST_PRODUCT_CATEGORY_PENDING,
  STATUS_REQUEST_LIST_PRODUCT_CATEGORY_SUCCESS,
  STATUS_REQUEST_LIST_PRODUCT_DATA_ALTERNATIVE_FAILED,
  STATUS_REQUEST_LIST_PRODUCT_DATA_ALTERNATIVE_PENDING,
  STATUS_REQUEST_LIST_PRODUCT_DATA_ALTERNATIVE_SUCCESS,
  STATUS_REQUEST_LIST_PRODUCT_DATA_FAILED,
  STATUS_REQUEST_LIST_PRODUCT_DATA_PENDING,
  STATUS_REQUEST_LIST_PRODUCT_DATA_SUCCESS,
  STATUS_REQUEST_LIST_PRODUCT_PURCHASE_SALE_FAILED,
  STATUS_REQUEST_LIST_PRODUCT_PURCHASE_SALE_PENDING,
  STATUS_REQUEST_LIST_PRODUCT_PURCHASE_SALE_SUCCESS,
  STATUS_REQUEST_LIST_PRODUCT_UNIT_FAILED,
  STATUS_REQUEST_LIST_PRODUCT_UNIT_PENDING,
  STATUS_REQUEST_LIST_PRODUCT_UNIT_SUCCESS,
  STATUS_REQUEST_UPDATE_PRODUCT_CATEGORY_FAILED,
  STATUS_REQUEST_UPDATE_PRODUCT_CATEGORY_PENDING,
  STATUS_REQUEST_UPDATE_PRODUCT_CATEGORY_SUCCESS,
  STATUS_REQUEST_UPDATE_PRODUCT_DATA_FAILED,
  STATUS_REQUEST_UPDATE_PRODUCT_DATA_PENDING,
  STATUS_REQUEST_UPDATE_PRODUCT_DATA_SUCCESS,
} from '../../utils/constants/store.constant';
import { productAsyncThunkTypeWithActionProps } from '../../utils/data/mapping/async-thunk-type-with-action-props.mapping';
import { DEFAULT_KEY_NAME_STATUS_REQUEST } from '../../utils/default/object-keyname.default';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import {
  addProductCategory,
  addProductData,
  deleteProductCategory,
  deleteProductData,
  getlistProductAlternative,
  getListProductBySupplier,
  getListProductPurchaseSale,
  getProductDetails,
  getProductMutationList,
  importProduct,
  listProductCategory,
  listProductData,
  productUnitList,
  progressImportingProduct,
  refreshListProductData,
  refreshProductCategoryList,
  updateProductCategory,
  updateProductData,
} from './product.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  // product category
  categoryData: LocalStorage.get(LOCAL_STORAGE_PRODUCT_CATEGORY),
  [SLICE_NAME_PRODUCT_CATEGORY_PRINT_DATA]: LocalStorage.get(LOCAL_STORAGE_PRODUCT_CATEGORY_PRINT),
  // product data
  productData: LocalStorage.get(LOCAL_STORAGE_PRODUCT_DATA),
  [SLICE_NAME_PRODUCT_PRINT_DATA]: LocalStorage.get(LOCAL_STORAGE_PRODUCT_PRINT_DATA),
  [SLICE_NAME_PRODUCT_DETAILS]: null,
  // additional slice
  [SLICE_NAME_TEMP_ADD_PRODUCT_DATA]: null,
  productPurchaseSaleData: LocalStorage.get(LOCAL_STORAGE_PRODUCT_PURCHASE_SALE),
  [SLICE_NAME_DATA_PRODUCT_BY_SUPPLIER]: LocalStorage.get(LOCAL_STORAGE_PRODUCT_BY_SUPPLIER),
  productDataAlternative: LocalStorage.get(LOCAL_STORAGE_PRODUCT_DATA_ALTERNATIVE),
  [SLICE_NAME_TEMP_IMPORT_PRODUCT_DATA]: null,
  [SLICE_NAME_DATA_PRODUCT_IMPORTING_PROGRESS]: null,
  [SLICE_NAME_DATA_PRODUCT_UNIT_LIST]: null,
  [SLICE_NAME_PRODUCT_MUTATION_LIST]: null,
};

const productSlice = createSlice({
  name: NAME_STORE.PRODUCT,
  initialState,
  reducers: {
    listProductData,
    refreshListProductData,
    addProductData,
    updateProductData,
    deleteProductData,
    listProductCategory,
    refreshProductCategoryList,
    addProductCategory,
    updateProductCategory,
    deleteProductCategory,
    getListProductBySupplier,
    getListProductPurchaseSale,
    getlistProductAlternative,
    importProduct,
    progressImportingProduct,
    clearProductData: (state, action) => {
      LocalStorage.remove(LOCAL_STORAGE_PRODUCT_DATA);

      if (action && action.payload && action.payload.removeLocalStorageOnly) return state;

      return {
        ...state,
        productData: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    clearProductCategory: (state, action) => {
      LocalStorage.remove(LOCAL_STORAGE_PRODUCT_CATEGORY);

      if (action && action.payload && action.payload.removeLocalStorageOnly) return state;

      return {
        ...state,
        categoryData: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    clearProductPurchaseSaleData: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_PRODUCT_PURCHASE_SALE);

      return {
        ...state,
        productPurchaseSaleData: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    clearDataProductSlice: (state, action) => {
      const { sliceName } = action.payload;

      if (LocalStorage.get(sliceName)) {
        LocalStorage.remove(sliceName);
      }

      return {
        ...state,
        [sliceName]: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    clearProductSliceItem: (state, action) => {
      sliceReduceHelper.clearSliceItem({
        state,
        action,
      });
    },
    ...sliceReduceHelper.commonSliceReducers(
      initialState,
      {
        categoryData: LOCAL_STORAGE_PRODUCT_CATEGORY,
        [SLICE_NAME_PRODUCT_CATEGORY_PRINT_DATA]: LOCAL_STORAGE_PRODUCT_CATEGORY_PRINT,
        productData: LOCAL_STORAGE_PRODUCT_DATA,
        [SLICE_NAME_PRODUCT_PRINT_DATA]: LOCAL_STORAGE_PRODUCT_PRINT_DATA,
        productPurchaseSaleData: LOCAL_STORAGE_PRODUCT_PURCHASE_SALE,
        [SLICE_NAME_DATA_PRODUCT_BY_SUPPLIER]: LOCAL_STORAGE_PRODUCT_BY_SUPPLIER,
        productDataAlternative: LOCAL_STORAGE_PRODUCT_DATA_ALTERNATIVE,
      },
      DEFAULT_KEY_NAME_STATUS_REQUEST,
    ),
  },
  extraReducers: (builder) => {
    builder.addCase(refreshListProductData.fulfilled, (state, action) => {
      state.statusRequest = STATUS_REQUEST_LIST_PRODUCT_DATA_SUCCESS;
      state.productData = action.payload.productData;
    }),
      builder.addCase(refreshListProductData.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PRODUCT_DATA_PENDING;
      }),
      builder.addCase(refreshListProductData.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PRODUCT_DATA_FAILED;
      }),
      // detail product
      builder.addCase(getProductDetails.fulfilled, (state, action) => {
        state[SLICE_NAME_PRODUCT_DETAILS] = action.payload[SLICE_NAME_PRODUCT_DETAILS];
        state.statusRequest = STATUS_REQUEST_DETAIL_PRODUCT_DATA_SUCCESS;
      }),
      builder.addCase(getProductDetails.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_PRODUCT_DATA_PENDING;
      }),
      builder.addCase(getProductDetails.rejected, (state) => {
        state[SLICE_NAME_PRODUCT_DETAILS] = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_PRODUCT_DATA_FAILED;
      }),
      builder.addCase(addProductData.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_ADD_PRODUCT_DATA_SUCCESS;
        state[SLICE_NAME_TEMP_ADD_PRODUCT_DATA] = action.payload[SLICE_NAME_TEMP_ADD_PRODUCT_DATA];
      }),
      builder.addCase(addProductData.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PRODUCT_DATA_PENDING;
      }),
      builder.addCase(addProductData.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PRODUCT_DATA_FAILED;
        state[SLICE_NAME_TEMP_ADD_PRODUCT_DATA] = null;
      }),
      builder.addCase(updateProductData.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PRODUCT_DATA_SUCCESS;
      }),
      builder.addCase(updateProductData.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PRODUCT_DATA_PENDING;
      }),
      builder.addCase(updateProductData.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PRODUCT_DATA_FAILED;
      }),
      builder.addCase(deleteProductData.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_PRODUCT_DATA_SUCCESS;
      }),
      builder.addCase(deleteProductData.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_PRODUCT_DATA_PENDING;
      }),
      builder.addCase(deleteProductData.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_PRODUCT_DATA_FAILED;
      }),
      // product category refresh list
      builder.addCase(refreshProductCategoryList.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_PRODUCT_CATEGORY_SUCCESS;
        state.categoryData = action.payload.categoryData;
      }),
      builder.addCase(refreshProductCategoryList.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PRODUCT_CATEGORY_PENDING;
      }),
      builder.addCase(refreshProductCategoryList.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PRODUCT_CATEGORY_FAILED;
      }),
      builder.addCase(addProductCategory.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PRODUCT_CATEGORY_SUCCESS;
      }),
      builder.addCase(addProductCategory.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PRODUCT_CATEGORY_PENDING;
      }),
      builder.addCase(addProductCategory.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PRODUCT_CATEGORY_FAILED;
      }),
      builder.addCase(updateProductCategory.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PRODUCT_CATEGORY_SUCCESS;
      }),
      builder.addCase(updateProductCategory.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PRODUCT_CATEGORY_PENDING;
      }),
      builder.addCase(updateProductCategory.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PRODUCT_CATEGORY_FAILED;
      }),
      builder.addCase(deleteProductCategory.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_PRODUCT_CATEGORY_SUCCESS;
      }),
      builder.addCase(deleteProductCategory.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_PRODUCT_CATEGORY_PENDING;
      }),
      builder.addCase(deleteProductCategory.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_PRODUCT_CATEGORY_FAILED;
      }),
      builder.addCase(getListProductPurchaseSale.fulfilled, (state, action) => {
        state.productPurchaseSaleData = action.payload.productPurchaseSaleData;
        state.statusRequest = STATUS_REQUEST_LIST_PRODUCT_PURCHASE_SALE_SUCCESS;
      }),
      builder.addCase(getListProductPurchaseSale.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PRODUCT_PURCHASE_SALE_PENDING;
      }),
      builder.addCase(getListProductPurchaseSale.rejected, (state) => {
        state.productPurchaseSaleData = null;
        state.statusRequest = STATUS_REQUEST_LIST_PRODUCT_PURCHASE_SALE_FAILED;
      }),
      builder.addCase(getListProductBySupplier.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_PRODUCT_BY_SUPPLIER_SUCCESS;
        state[SLICE_NAME_DATA_PRODUCT_BY_SUPPLIER] =
          action.payload[SLICE_NAME_DATA_PRODUCT_BY_SUPPLIER];
      }),
      builder.addCase(getListProductBySupplier.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PRODUCT_BY_SUPPLIER_PENDING;
      }),
      builder.addCase(getListProductBySupplier.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PRODUCT_BY_SUPPLIER_FAILED;
        state[SLICE_NAME_DATA_PRODUCT_BY_SUPPLIER] = null;
      }),
      builder.addCase(getlistProductAlternative.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_PRODUCT_DATA_ALTERNATIVE_SUCCESS;
        state.productDataAlternative = action.payload.productDataAlternative;
      }),
      builder.addCase(getlistProductAlternative.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PRODUCT_DATA_ALTERNATIVE_PENDING;
      }),
      builder.addCase(getlistProductAlternative.rejected, (state) => {
        state.productDataAlternative = null;
        state.statusRequest = STATUS_REQUEST_LIST_PRODUCT_DATA_ALTERNATIVE_FAILED;
      }),
      builder.addCase(importProduct.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_IMPORT_PRODUCT_DATA_SUCCESS;
        state[SLICE_NAME_TEMP_IMPORT_PRODUCT_DATA] =
          action.payload[SLICE_NAME_TEMP_IMPORT_PRODUCT_DATA];
      }),
      builder.addCase(importProduct.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_IMPORT_PRODUCT_DATA_PENDING;
      }),
      builder.addCase(importProduct.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_IMPORT_PRODUCT_DATA_FAILED;
      }),
      // progress importring product
      builder.addCase(progressImportingProduct.fulfilled, (state, action) => {
        state[SLICE_NAME_DATA_PRODUCT_IMPORTING_PROGRESS] =
          action.payload[SLICE_NAME_DATA_PRODUCT_IMPORTING_PROGRESS];
        state.statusRequest = STATUS_REQUEST_IMPORT_PRODUCT_PROGRESS_DATA_SUCCESS;
      }),
      builder.addCase(progressImportingProduct.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_IMPORT_PRODUCT_PROGRESS_DATA_PENDING;
      }),
      builder.addCase(progressImportingProduct.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_IMPORT_PRODUCT_PROGRESS_DATA_FAILED;
      }),
      builder.addCase(productUnitList.fulfilled, (state, action) => {
        state[SLICE_NAME_DATA_PRODUCT_UNIT_LIST] =
          action.payload[SLICE_NAME_DATA_PRODUCT_UNIT_LIST];
        state.statusRequest = STATUS_REQUEST_LIST_PRODUCT_UNIT_SUCCESS;
      }),
      builder.addCase(productUnitList.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PRODUCT_UNIT_PENDING;
      }),
      builder.addCase(productUnitList.rejected, (state) => {
        state[SLICE_NAME_DATA_PRODUCT_UNIT_LIST] = null;
        state.statusRequest = STATUS_REQUEST_LIST_PRODUCT_UNIT_FAILED;
      }),
      // product mutation list
      builder.addCase(getProductMutationList.fulfilled, (state, action) => {
        state[SLICE_NAME_PRODUCT_MUTATION_LIST] = action.payload[SLICE_NAME_PRODUCT_MUTATION_LIST];
        state.statusRequest = REQUEST_STATUS_LIST_PRODUCT_MUTATION_SUCCESS;
      }),
      builder.addCase(getProductMutationList.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_LIST_PRODUCT_MUTATION_PENDING;
      }),
      builder.addCase(getProductMutationList.rejected, (state) => {
        state[SLICE_NAME_PRODUCT_MUTATION_LIST] = null;
        state.statusRequest = REQUEST_STATUS_LIST_PRODUCT_MUTATION_FAILED;
      }),
      // enhancement for matching request status action on product, and product category
      // current: only include list
      sliceReduceHelper.createMatcherEachRequestStatusAction({
        builder,
        baseTypesRegistered: BASE_TYPES_PRODUCT_ACTION_REGISTERED,
        mappingAsyncThunkTypeWithActionProps: productAsyncThunkTypeWithActionProps,
      });
  },
});

export const { actions, reducer } = productSlice;

export const {
  clearProductData,
  clearProductCategory,
  clearProductPurchaseSaleData,
  clearDataProductSlice,
  clearProductSliceItem,
} = actions;

export default reducer;
