import * as React from 'react';
function SvgUnitConversionSettingMenu(props) {
  return (
    <svg width={81} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#unit-conversion-setting-menu_svg__filter0_d_5586_193617)'>
        <path d='M61.743 17.034H57.97v45.233h3.772V17.034z' fill='#E6E9EE' />
        <path
          d='M73.335 65.603H2.187c-.17 0-.292-.101-.292-.242v-4.752c0-.142.121-.243.292-.243H73.31c.17 0 .292.101.292.243v4.752c.024.141-.122.242-.268.242z'
          fill='#324A5E'
        />
        <path
          d='M68.007 65.603h-9.588v3.296c0 .06.05.101.122.101h9.344c.073 0 .121-.04.121-.101v-3.296zM17.102 65.603H7.515v3.296c0 .06.05.101.122.101h9.344c.073 0 .121-.04.121-.101v-3.296z'
          fill='#2B3B4E'
        />
        <path
          d='M72.765 17.924h-25.84c-.171 0-.293-.1-.293-.242V1.241c0-.14.122-.242.292-.242h25.841c.17 0 .292.101.292.243V17.68c0 .122-.121.243-.292.243z'
          fill='#324A5E'
        />
        <path d='M71.281 2.84H48.433v6.733H71.28V2.84z' fill='#84DBFF' />
        <path
          d='M50.793 10.746h-2.36v1.961h2.36v-1.96zM54.492 10.746h-2.36v1.961h2.36v-1.96zM58.19 10.746h-2.36v1.961h2.36v-1.96zM61.889 10.746h-2.36v1.961h2.36v-1.96zM50.793 13.82h-2.36v1.96h2.36v-1.96zM54.492 13.82h-2.36v1.96h2.36v-1.96zM58.19 13.82h-2.36v1.96h2.36v-1.96zM61.889 13.82h-2.36v1.96h2.36v-1.96z'
          fill='#CED5E0'
        />
        <path d='M49.573 31.194H10.832v29.36h38.741v-29.36z' fill='#FFD15D' />
        <path d='M49.573 31.194H30.125v29.36h19.448v-29.36z' fill='#F9B54C' />
        <path d='M51.51 27H8.895v8.388H51.51V27z' fill='#F9B54C' />
        <path d='M51.51 27H30.125v8.388H51.51V27z' fill='#F4A200' />
        <path
          d='M36.014 43.014H24.39c-1.07 0-1.937-.939-1.937-2.097s.867-2.097 1.937-2.097h11.623c1.07 0 1.937.939 1.937 2.097s-.868 2.097-1.938 2.097z'
          fill='#B97850'
        />
        <path
          d='M36.014 38.82h-5.89v4.194h5.89c1.07 0 1.936-.939 1.936-2.097s-.867-2.097-1.937-2.097z'
          fill='#935635'
        />
        <path
          d='M22.357 46.405h-7.41c-.762 0-1.361.65-1.361 1.475V55.9c0 .826.599 1.475 1.362 1.475h7.409c.763 0 1.362-.65 1.362-1.475V47.88c0-.767-.6-1.475-1.362-1.475zm.6 9.496c0 .354-.273.649-.6.649h-7.41c-.326 0-.599-.295-.599-.649V47.88c0-.354.273-.649.6-.649h7.409c.327 0 .6.295.6.649V55.9z'
          fill='#fff'
        />
        <path
          d='M17.128 49.413c-.055-.059-.055-.059-.11-.059-.054 0-.108-.059-.163-.059-.054 0-.109 0-.163.06-.055 0-.11.058-.11.058L15.33 50.77c-.164.177-.164.413 0 .53.163.178.381.178.49 0l.654-.707v3.48c0 .236.163.413.381.413s.381-.177.381-.413v-3.48l.654.708a.4.4 0 00.273.118c.108 0 .163-.06.272-.118.163-.177.163-.413 0-.531l-1.307-1.357zM20.723 49.413a.4.4 0 00-.272-.118.4.4 0 00-.273.118l-1.253 1.357c-.163.177-.163.413 0 .53.163.178.381.178.49 0l.654-.707v3.48c0 .236.164.413.382.413.217 0 .38-.177.38-.413v-3.48l.655.708a.4.4 0 00.272.118c.109 0 .164-.06.272-.118.164-.177.164-.413 0-.531l-1.307-1.357zM33.744 46.405h-7.41c-.762 0-1.362.65-1.362 1.475V55.9c0 .826.6 1.475 1.362 1.475h7.41c.762 0 1.362-.65 1.362-1.475V47.88c0-.767-.6-1.475-1.362-1.475zm.654 9.496c0 .354-.273.649-.6.649h-7.464c-.326 0-.599-.295-.599-.649V47.88c0-.354.273-.649.6-.649h7.409c.327 0 .599.295.599.649V55.9h.054z'
          fill='#fff'
        />
        <path
          d='M31.673 48.647H28.46c-.218 0-.381.177-.381.413v1.887c0 1.061.708 1.946 1.634 2.123v1.298h-.49c-.218 0-.382.177-.382.413s.164.413.382.413h1.743c.218 0 .381-.178.381-.413 0-.236-.163-.413-.38-.413h-.491V53.07c.926-.177 1.634-1.062 1.634-2.123v-1.888c-.054-.235-.218-.412-.436-.412zm-.381 2.3c0 .767-.545 1.356-1.253 1.356-.708 0-1.253-.59-1.253-1.356v-1.534h2.506v1.534zM45.13 46.405h-7.41c-.762 0-1.362.65-1.362 1.475V55.9c0 .826.6 1.475 1.362 1.475h7.41c.762 0 1.362-.65 1.362-1.475V47.88c0-.767-.654-1.475-1.362-1.475zm.599 9.496c0 .354-.272.649-.6.649H37.72c-.326 0-.599-.295-.599-.649V47.88c0-.354.273-.649.6-.649h7.409c.327 0 .599.295.599.649V55.9z'
          fill='#fff'
        />
        <path
          d='M41.425 48.116c-1.798 0-3.269 1.592-3.269 3.539 0 .236.164.413.382.413h2.56v2.654c0 .117-.109.236-.218.236s-.218-.118-.218-.236c0-.236-.163-.413-.381-.413s-.381.177-.381.413c0 .59.436 1.061.98 1.061.545 0 .98-.472.98-1.061v-2.654h2.562c.217 0 .38-.177.38-.413-.108-1.947-1.58-3.54-3.377-3.54zm-2.506 3.126c.163-1.357 1.253-2.36 2.506-2.36s2.343 1.003 2.506 2.36H38.92z'
          fill='#fff'
        />
      </g>
      <defs>
        <filter
          id='unit-conversion-setting-menu_svg__filter0_d_5586_193617'
          x={0.895}
          y={0}
          width={79.711}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_193617' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_193617' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgUnitConversionSettingMenu;
