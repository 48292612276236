import { isEmpty } from 'lodash';
import { v4 as uuidV4 } from 'uuid';

import arrHelp from '../../helpers/array.helpers';
import formatHelp from '../../helpers/format.helpers';
import objHelper from '../../helpers/object.helper';

import {
  initialBankData,
  initialBankValidation,
  initialOtherData,
  initialOtherValidaton,
  initialProductListData,
  initialProductListValidation,
  initialProfileData,
  initialProfileDataForSalesOnly,
  initialProfileValidation,
} from './initial-contact-data';

const initialID = uuidV4();

const generateDataNValidationContact = (
  generateDataFunc,
  generateValidationFunc,
  nameKeyData,
  nameKeyValidation,
  amount = 1,
) => {
  const idUsed = new Array(amount).fill(uuidV4());

  const data = idUsed.map((id) => {
    return generateDataFunc('', id);
  });

  const validation = idUsed.map((id) => {
    return generateValidationFunc('', id);
  });

  return {
    [nameKeyData]: data,
    [nameKeyValidation]: validation,
  };
};

export const generateInitialAddProfileData = (additionalID = '', newID = '') => {
  const idData = newID || initialID + additionalID;

  return {
    id: idData,
    ...initialProfileData,
  };
};

export const generateInitialAddProfileDataWithStartIsCustomer = (additionalID = '', newID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...initialProfileDataForSalesOnly,
  };
};

export const generateInitialAddProfileValidation = (additionalID = '', newID = '') => {
  const idValidation = newID || initialID + additionalID;

  return {
    id: idValidation,
    ...initialProfileValidation,
  };
};

export const generateDataNValidationAddProfile = (amountData = 1) => {
  return generateDataNValidationContact(
    generateInitialAddProfileData,
    generateInitialAddProfileValidation,
    'contactProfileData',
    'contactProfileValidation',
    amountData,
  );
};

export const generateDataNValidationAddProfileForIsCustomer = (amountData = 1) => {
  return generateDataNValidationContact(
    generateInitialAddProfileDataWithStartIsCustomer,
    generateInitialAddProfileValidation,
    'contactProfileData',
    'contactProfileValidation',
    amountData,
  );
};

export const generateInitialAddOtherData = (additionalID = '', newID = '') => {
  const idData = newID || initialID + additionalID;

  return {
    id: idData,
    ...initialOtherData,
  };
};

export const generateInitialAddOtherValidation = (additionalID = '', newID = '') => {
  const idValidation = newID || initialID + additionalID;

  return {
    id: idValidation,
    ...initialOtherValidaton,
  };
};

export const generateDataNValidationAddOther = (amountData = 1) => {
  return generateDataNValidationContact(
    generateInitialAddOtherData,
    generateInitialAddOtherValidation,
    'contactOtherData',
    'contactOtherValidation',
    amountData,
  );
};

export const generateInitialAddBankData = (additionalID = '', newID = '') => {
  const idData = newID || initialID + additionalID;

  return {
    id: idData,
    ...initialBankData,
  };
};

export const generateInitialAddBankValidation = (additionalID = '', newID = '') => {
  const idValidation = newID || initialID + additionalID;

  return {
    id: idValidation,
    ...initialBankValidation,
  };
};

export const generateDataNValidationAddBank = (amountData = 1) => {
  return generateDataNValidationContact(
    generateInitialAddBankData,
    generateInitialAddBankValidation,
    'contactBankData',
    'contactBankValidation',
    amountData,
  );
};

export const generateInitialAddProductListData = (additionalID = '', newID = '') => {
  const idData = newID || initialID + additionalID;

  return {
    id: idData,
    ...initialProductListData,
  };
};

export const generateInitialAddProductListValidation = (additionalID = '', newID = '') => {
  const idValidation = newID || initialID + additionalID;

  return {
    id: idValidation,
    ...initialProductListValidation,
  };
};

export const generateDataNValidationAddProduct = (amountData = 1) => {
  return generateDataNValidationContact(
    generateInitialAddProductListData,
    generateInitialAddProductListValidation,
    'contactProductData',
    'contactProductValidation',
    amountData,
  );
};

export const generalizeDataAddContact = (dataProfile, dataOther, dataBank, dataProduct) => {
  if (
    !Array.isArray(dataProfile) ||
    !Array.isArray(dataOther) ||
    !Array.isArray(dataBank) ||
    !Array.isArray(dataProduct)
  )
    return null;

  const dataProfileFormatted = dataProfile.map((profile) => {
    if (!isEmpty(profile.tag)) {
      profile = {
        ...profile,
        tag: arrHelp.mapObjKeyOnArr(profile.tagContainer, 'tag_name'),
      };
    }

    if (profile.phone_number) {
      return objHelper.changeFormatValue(profile, 'phone_number', formatHelp.numberPhoneFormat);
    }

    return profile;
  });

  const products = dataProduct.map((product) => product.product_name);

  return {
    ...dataProfileFormatted[0],
    ...dataOther[0],
    bank: dataBank,
    products,
  };
};
