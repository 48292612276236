import { isEmpty, omit, pick } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_COMPARISON_VALUE_NUMBER } from '../../default/number.default';
import {
  DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
  DEFAULT_KEY_NAME_FILE_PROPERTIES,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_MODUL_QUANTITY_AVAILABLE_API_REF,
  DEFAULT_KEY_NAME_PRODUCT_SALES_ORDER_DETAILS,
  DEFAULT_KEY_NAME_UUID_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';
import arrHelp from '../../helpers/array.helpers';
import formatHelp from '../../helpers/format.helpers';
import objHelper from '../../helpers/object.helper';
import parseResponseHelpers from '../../helpers/parse-response.helpers';
import { DEFAULT_CALCULATION_NUMBER } from '../default/number.default';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import { generalizeAttachmentFromDetails } from './initial-add-attachment';
import {
  generateDataNValidationAddProductSalesOrder,
  generateDataNValidationAddSalesOrder,
} from './initial-add-sales-order';
import {
  allowKeysProductSalesOrderDataForUpdate,
  allowKeysSalesOrderDataForUpdate,
  initialProductSalesOrderData,
  initialProductSalesOrderValidation,
  initialSalesOrderData,
  initialSalesOrderValidation,
  productSalesOrderCalculationNumberKeyNames,
  relationalAdditionalCostValueNaccountForSalesOrder,
  salesOrderCalculationNumberKeyNames,
} from './initial-data-sales-order';

/**
 *
 * @param { object } salesOrderDetails data from detail sales order for generate update sales order data and validation
 * @param { string } customDataKey name key of return data
 * @param { string } customValidationKey name key return validation
 * @returns
 *      generate data and validation for sales order on menu update
 *      this data from sales order detail
 */
export const generateDataNValidationUpdateSalesOrderFromSalesOrderDetails = (
  salesOrderDetails,
  customDataKey = 'salesOrderData',
  customValidationKey = 'salesOrderValidation',
) => {
  if (isEmpty(salesOrderDetails)) {
    return generateDataNValidationAddSalesOrder(1, customDataKey, customValidationKey);
  }

  const {
    customer_id,
    customer_name: contact_name,
    contact_code,
    transaction_date,
    expired_date,
    sales_order_no: reference_number,
    sales_quotation_no,
    transaction_no,
    warehouse_id,
    warehouse_name,
  } = salesOrderDetails;

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const salesOrderData = usedID.map((id) => {
    let sales_quotation_id_container = {
      transaction_no: transaction_no || sales_quotation_no,
    };

    if (!isEmpty(sales_quotation_id_container)) {
      sales_quotation_id_container =
        objHelper.filteringExistedValue(sales_quotation_id_container) || null;
    }

    return {
      ...initialSalesOrderData(id),
      ...salesOrderDetails,
      warehouse_id_container: {
        warehouse_id,
        warehouse_name,
      },
      amount_const: Number(salesOrderDetails.amount) || DEFAULT_CALCULATION_NUMBER,
      sales_agent_id_container: {
        contact_id: salesOrderDetails.sales_agent_id || '',
        contact_code: salesOrderDetails.sales_agent_code || '',
        contact_name: salesOrderDetails.sales_agent_name || '',
      },
      discount_account_id_container: {
        contact_id: salesOrderDetails.discount_account_id || '',
        contact_name: salesOrderDetails.discount_account_name || '',
        contact_code: salesOrderDetails.discount_account_code || '',
      },
      expense_account_id_container: {
        contact_id: salesOrderDetails.expense_account_id || '',
        contact_name: salesOrderDetails.expense_account_name || '',
        contact_code: salesOrderDetails.expense_account_code || '',
      },
      down_payment_account_id_container: {
        contact_id: salesOrderDetails.down_payment_account_id || '',
        contact_name: salesOrderDetails.down_payment_account_name || '',
        contact_code: salesOrderDetails.down_payment_account_code || '',
      },
      customer_id_container: {
        contact_id: customer_id,
        contact_code,
        contact_name,
      },
      transaction_date: transaction_date ? moment(transaction_date) : null,
      expired_date: expired_date ? moment(expired_date) : null,
      reference_number,
      reference_number_container: {
        value: reference_number,
      },
      sales_quotation_id_container,
    };
  });

  const salesOrderValidation = usedID.map((id) => {
    return initialSalesOrderValidation(id);
  });

  return {
    [customDataKey]: salesOrderData,
    [customValidationKey]: salesOrderValidation,
  };
};

/**
 *
 * @param { object } salesOrderDetails data from detail sales order for generate update sales order data and validation
 * @param { string } customDataKey name key of return data
 * @param { string } customValidationKey name key return validation
 * @returns
 *      generate data and validation for sales order on menu update
 *      this data from sales order detail
 */
export const generateDataNValidationUpdateSalesOrderFromSalesOrderDetailsV2 = ({
  salesOrderDetails,
  companyUserData,
  customDataKey = 'salesOrderData',
  customValidationKey = 'salesOrderValidation',
}) => {
  if (isEmpty(salesOrderDetails)) {
    return generateDataNValidationAddSalesOrder(1, customDataKey, customValidationKey);
  }

  // changes format calculation number
  salesOrderDetails = objHelper.changeFormatValue(
    salesOrderDetails,
    salesOrderCalculationNumberKeyNames,
    [(value) => Number(value) || ''],
  );

  const {
    customer_id,
    customer_name: contact_name,
    contact_code,
    transaction_date,
    expired_date,
    sales_order_no: reference_number,
    sales_quotation_no,
    transaction_no,
    warehouse_id,
    warehouse_name,
  } = salesOrderDetails;

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const salesOrderData = usedID.map((id) => {
    let sales_quotation_id_container = {
      transaction_no: transaction_no || sales_quotation_no,
    };

    let accounting_period = null;
    if (!isEmpty(companyUserData)) {
      const { accounting_periode: accountingPeriodeCompanyUser } = companyUserData;

      if (!isEmpty(accountingPeriodeCompanyUser)) {
        accounting_period = moment(accountingPeriodeCompanyUser);
      }
    }

    if (!isEmpty(sales_quotation_id_container)) {
      sales_quotation_id_container =
        objHelper.filteringExistedValue(sales_quotation_id_container) || null;
    }

    return {
      ...initialSalesOrderData(id),
      ...salesOrderDetails,
      attachment: generalizeAttachmentFromDetails({
        dataDetails: salesOrderDetails,
      }),
      warehouse_id_container: {
        warehouse_id,
        warehouse_name,
      },
      accounting_period,
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
      amount_const: Number(salesOrderDetails.amount) || DEFAULT_CALCULATION_NUMBER,
      sales_agent_id_container: {
        contact_id: salesOrderDetails.sales_agent_id || '',
        contact_code: salesOrderDetails.sales_agent_code || '',
        contact_name: salesOrderDetails.sales_agent_name || '',
      },
      discount_account_id_container: {
        account_id: salesOrderDetails.discount_account_id || '',
        account_name: salesOrderDetails.discount_account_name || '',
        account_code: salesOrderDetails.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: salesOrderDetails.expense_account_id || '',
        account_name: salesOrderDetails.expense_account_name || '',
        account_code: salesOrderDetails.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: salesOrderDetails.down_payment_account_id || '',
        account_name: salesOrderDetails.down_payment_account_name || '',
        account_code: salesOrderDetails.down_payment_account_code || '',
      },
      customer_id_container: {
        contact_id: customer_id,
        contact_code,
        contact_name,
      },
      transaction_date: transaction_date ? moment(transaction_date) : null,
      expired_date: expired_date ? moment(expired_date) : null,
      reference_number,
      reference_number_container: {
        value: reference_number,
      },
      sales_quotation_id_container,
      ...parseResponseHelpers.tagContainerInputFromDetails(salesOrderDetails),
    };
  });

  const salesOrderValidation = usedID.map((id) => {
    return initialSalesOrderValidation(id);
  });

  return {
    [customDataKey]: salesOrderData,
    [customValidationKey]: salesOrderValidation,
  };
};

/**
 *
 * @param { object } salesOrderDetails      sales order details data that convert into data sales order and validation sales order update,
 *      minimum contain key sales_order_product and length sales_order_product is one
 * @param { string } customDataKey          name key of return data
 * @param { string } customValidationKey    name key return validation
 * @returns
 *      generate data and validation for product of sales order on menu update
 */
export const generateDataNValidationUpdateProductSalesOrderFromSalesOrderDetails = (
  salesOrderDetails,
  customDataKey = 'productSalesOrderData',
  customValidationKey = 'productSalesOrderValidation',
) => {
  const { sales_order_product } = salesOrderDetails;

  if (isEmpty(sales_order_product)) {
    return generateDataNValidationAddProductSalesOrder(2, customDataKey, customValidationKey);
  }

  const usedID = arrHelp.generateArrWithFunc(sales_order_product.length, uuidv4);

  const productSalesOrderData = usedID.map((id, index) => {
    const {
      product_id,
      product_name,
      product_code,
      description: product_description,
      quantity,
      quantity_origin,
      price,
      product_unit,
      unit_destination,
      unit_origin,
    } = sales_order_product[index];

    const amount = formatHelp.currencyFormatWithRegex(Number(quantity) * Number(price));

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    return {
      ...initialProductSalesOrderData(id),
      ...sales_order_product[index],
      product_id_container: {
        product_id,
        product_name,
        product_code,
        ...sales_order_product[index],
      },
      product_description,
      amount,
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantity),
        value_comparison: quantityComparison,
      },
      quantity_available_with_unit: `${quantity} ${unit_origin || product_unit}`,
    };
  });

  const productSalesOrderValidation = usedID.map((id) => {
    return initialProductSalesOrderValidation(id);
  });

  return {
    [customDataKey]: productSalesOrderData,
    [customValidationKey]: productSalesOrderValidation,
  };
};

/**
 *
 * @param { object } salesOrderDetails      sales order details data that convert into data sales order and validation sales order update,
 *      minimum contain key sales_order_product and length sales_order_product is one
 * @param { string } customDataKey          name key of return data
 * @param { string } customValidationKey    name key return validation
 * @returns
 *      generate data and validation for product of sales order on menu update
 */
export const generateDataNValidationUpdateProductSalesOrderFromSalesOrderDetailsV2 = ({
  salesOrderDetails,
  customDataKey = 'productSalesOrderData',
  customValidationKey = 'productSalesOrderValidation',
  useAddNewRow = true,
  amountNewRow = 1,
  keyNameProductSalesOrder = DEFAULT_KEY_NAME_PRODUCT_SALES_ORDER_DETAILS,
}) => {
  const salesOrderProduct = !isEmpty(salesOrderDetails)
    ? salesOrderDetails[keyNameProductSalesOrder]
    : null;

  if (isEmpty(salesOrderProduct)) {
    return generateDataNValidationAddProductSalesOrder(2, customDataKey, customValidationKey);
  }

  const usedID = arrHelp.generateArrWithFunc(salesOrderProduct.length, uuidv4);

  let productSalesOrderData = usedID.map((id, index) => {
    let selectedSalesOrderProduct = salesOrderProduct[index];

    if (!isEmpty(selectedSalesOrderProduct)) {
      selectedSalesOrderProduct = objHelper.changeFormatValue(
        selectedSalesOrderProduct,
        productSalesOrderCalculationNumberKeyNames,
        [(value) => Number(value) || ''],
      );
    }

    const {
      product_id,
      product_name,
      product_code,
      description: product_description,
      quantity,
      quantity_origin,
      price,
      product_unit,
      unit_destination,
      unit_origin,
    } = selectedSalesOrderProduct;

    const amount = Number(Number(quantity) * Number(price));

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    const perQuantityComparison = Number(1 / quantityComparison);

    const modulQuantityAvailable =
      selectedSalesOrderProduct[DEFAULT_KEY_NAME_MODUL_QUANTITY_AVAILABLE_API_REF];

    const modulQuantityAvailablePerQuantityComparison =
      Number(modulQuantityAvailable) * perQuantityComparison;

    const quantityAvailable = modulQuantityAvailablePerQuantityComparison + Number(quantity_origin);

    return {
      ...initialProductSalesOrderData(id),
      ...selectedSalesOrderProduct,
      product_id_container: {
        product_id,
        product_name,
        product_code,
        ...selectedSalesOrderProduct,
      },
      product_description,
      amount,
      quantity_const: Number(quantityAvailable),
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantityAvailable),
        value_comparison: quantityComparison,
      },
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      quantity_available_with_unit: `${quantityAvailable} ${unit_origin || product_unit}`,
    };
  });

  let productSalesOrderValidation = usedID.map((id) => {
    return initialProductSalesOrderValidation(id);
  });

  if (useAddNewRow && amountNewRow) {
    const {
      productSalesOrderData: productSalesOrderDataGen,
      productSalesOrderValidation: productSalesOrderValidationGen,
    } = generateDataNValidationAddProductSalesOrder(amountNewRow);

    productSalesOrderData = productSalesOrderData.concat(productSalesOrderDataGen);
    productSalesOrderValidation = productSalesOrderValidation.concat(
      productSalesOrderValidationGen,
    );
  }

  return {
    [customDataKey]: productSalesOrderData,
    [customValidationKey]: productSalesOrderValidation,
  };
};

/**
 *
 * @param { array } salesOrderData bunch data sales order that inputted by user on menu add
 * @param { array } productSalesOrderData data product of sales order that inputted by user on menu add
 * @returns
 *      generalize data sales order that inputted by user, follow requirement from API
 *      generalize data for hit endpoint update sales order
 */
export const generalizeDataUpdateSalesOrder = (salesOrderData, productSalesOrderData) => {
  if (Array.isArray(salesOrderData) && Array.isArray(productSalesOrderData)) {
    salesOrderData = salesOrderData.map((singleSalesOrderData) => {
      const { customer_id_container, transaction_date, expired_date, attachment } =
        singleSalesOrderData;

      const keysAdditionalCostValue = Object.keys(
        relationalAdditionalCostValueNaccountForSalesOrder,
      );
      const omittedAdditionalAccount = keysAdditionalCostValue.reduce(
        (allAddCostVal, currAddCostVal) => {
          if (!Number(singleSalesOrderData[currAddCostVal])) {
            allAddCostVal = allAddCostVal.concat(
              relationalAdditionalCostValueNaccountForSalesOrder[currAddCostVal],
            );
          }

          return allAddCostVal;
        },
        [],
      );

      singleSalesOrderData = omit(singleSalesOrderData, omittedAdditionalAccount);

      let customer_name = '';

      if (customer_id_container) {
        const { contact_name, customer_name: customer_name_gen } = customer_id_container;

        customer_name = contact_name || customer_name_gen;
      }

      const uuidAttachment =
        Array.isArray(attachment) &&
        attachment.length &&
        attachment[0][DEFAULT_KEY_NAME_FILE_PROPERTIES]
          ? attachment[0][DEFAULT_KEY_NAME_FILE_PROPERTIES][DEFAULT_KEY_NAME_UUID_API_REF] || ''
          : '';

      return objHelper.filteringExistedValue(
        pick(
          {
            ...singleSalesOrderData,
            customer_name,
            transaction_date: transaction_date ? moment(transaction_date).toISOString(true) : '',
            expired_date: expired_date ? moment(expired_date).toISOString(true) : '',
            [DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF]: uuidAttachment,
          },
          allowKeysSalesOrderDataForUpdate,
        ),
      );
    });

    productSalesOrderData = productSalesOrderData.map((singleProductSalesOrder) => {
      const { price } = singleProductSalesOrder;

      return objHelper.filteringExistedValue(
        pick(
          {
            ...singleProductSalesOrder,
            price: price ? formatHelp.reverseCurrencyFormatWithRegex(price) : 0,
          },
          allowKeysProductSalesOrderDataForUpdate,
        ),
      );
    });

    productSalesOrderData = arrHelp.filterExistedValueOnObjItem(
      productSalesOrderData,
      allowKeysProductSalesOrderDataForUpdate,
      [],
      DEFAULT_CALCULATION_NUMBER,
    );

    return {
      product: productSalesOrderData,
      ...salesOrderData[0],
    };
  }
};
