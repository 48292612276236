// column header purchase invoice list that handling table head with translation
export const columnsTableHeaderPurchaseInvoice = (t) => {
  return [
    {
      name: 'transaction_date',
      name_input: 'from_date',
      label: t('dashboard.purchase.purchase-invoice.table.header-column.transaction-date'),
      placeholder: t(
        'dashboard.purchase.purchase-invoice.input.search-transaction-date.placeholder',
      ),
      width: 100,
    },
    {
      name: 'purchase_invoice_no',
      name_input: 'transaction_no',
      label: t('dashboard.purchase.purchase-invoice.table.header-column.transaction-no'),
      placeholder: t('dashboard.purchase.purchase-invoice.input.search-transaction-no.placeholder'),
      width: 150,
    },
    {
      name: 'supplier_name',
      name_input: 'supplier_name',
      label: t('dashboard.purchase.purchase-invoice.table.header-column.supplier'),
      placeholder: t('dashboard.purchase.purchase-invoice.input.search-supplier-name.placeholder'),
      width: 150,
    },
    {
      name: 'amount',
      label: t('dashboard.purchase.purchase-invoice.table.header-column.nominal'),
      placeholder: t('dashboard.purchase.purchase-invoice.input.search-transaction-no.placeholder'),
      align: 'right',
      width: 120,
    },
    {
      name: 'status',
      name_input: 'status',
      label: t('table.header-column.invoice-status'),
      placeholder: t('dashboard.purchase.purchase-invoice.input.search-status.placeholder'),
      width: 150,
      maxWidth: 155,
    },
  ];
};

export const columnsSearchPurchaseInvoiceWithDate = ['from_date', 'to_date'];

export const columnsCalculationNumberPurchaseInvoice = ['amount'];

// column header purchase invoice list that handling table head with translation (type basic app)
export const columnsTableHeaderPurchaseInvoiceBasic = (t) => {
  return [
    {
      name: 'transaction_date',
      name_input: 'from_date',
      label: t('dashboard.purchase.purchase-invoice.table.header-column.transaction-date'),
      placeholder: t(
        'dashboard.purchase.purchase-invoice.input.search-transaction-date.placeholder',
      ),
      minWidth: 120,
      width: 120,
    },
    {
      name: 'purchase_invoice_no',
      name_input: 'transaction_no',
      label: t('dashboard.purchase.purchase-invoice.table.header-column.transaction-no'),
      placeholder: t('dashboard.purchase.purchase-invoice.input.search-transaction-no.placeholder'),
      minWidth: 120,
      width: 120,
    },
    {
      name: 'amount',
      label: t('table.header-column.amount'),
      align: 'right',
      width: 150,
    },
    {
      name: 'status',
      name_input: '',
      label: t('dashboard.purchase.purchase-invoice.table.header-column.status'),
      placeholder: t('dashboard.purchase.purchase-invoice.input.search-status.placeholder'),
      width: 100,
      maxWidth: 100,
    },
    {
      name: 'action',
      label: '',
      align: 'right',
      width: '50px',
      maxWidth: '50px',
    },
  ];
};
