import { REACT_APP_API_URL_USER_PERMISSION_DETAILS } from '../../../utils/configs/api.config';
import { DEFAULT_KEY_NAME_USER_ID_API_REF } from '../../../utils/default/object-keyname.default';
import {
  creatorAddServiceWithTemplateURL,
  creatorDetailServiceWithParams,
} from '../creator-services/creator-services.helper';

// get permission details for single user_id
const getUserPermissionListDetails = async (paramsGetManagementUserPermissionList) => {
  return await creatorDetailServiceWithParams({
    url: REACT_APP_API_URL_USER_PERMISSION_DETAILS,
    identityToCombineOnUrl: paramsGetManagementUserPermissionList,
  });
};

// create permission for single user, this service is usage for update user permissions
const createUserPermission = async (bodyCreateManagementUserPermission) => {
  return await creatorAddServiceWithTemplateURL(
    REACT_APP_API_URL_USER_PERMISSION_DETAILS,
    bodyCreateManagementUserPermission,
    [DEFAULT_KEY_NAME_USER_ID_API_REF, 'isAssignSelf'],
  );
};

const userPermissionServices = {
  getUserPermissionListDetails,
  createUserPermission,
};

export default userPermissionServices;
