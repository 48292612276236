import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Chip, Typography } from '@mui/material';
import clsx from 'clsx';
import { isEmpty } from 'lodash';

import ButtonCollections from '../components/button/ButtonCollections';
import TypographyLinkPrimary from '../components/typography/TypographyLinkPrimary/TypographyLinkPrimary';
import arrHelp from '../utils/helpers/array.helpers';
import strHelp from '../utils/helpers/string.helpers';
import { objectToQueryString, queryStringToObject } from '../utils/helpers/url.helpers';

export const typographyElementType = 'typography-element';
export const linkElementType = 'link-element';
export const buttonCollectionsType = 'button-collections';
export const labelType = 'label-element';
export const renderButtonType = 'render-button-element';
export const chipSelectItemType = 'chip-select-item';
export const emptyElementType = 'empty-element';

const mappingPropsDefaultElement = {
  [typographyElementType]: {
    variant: 'body6',
    className: ['limitWord'],
  },
  [linkElementType]: {
    typographyVariant: 'linkDetailV3',
    onClick: () => null,
    isClickDisabled: true,
    typographyProps: {
      textAlign: 'left',
    },
  },
  [buttonCollectionsType]: {
    buttonConfigs: [],
  },
  [labelType]: {},
  [renderButtonType]: {},
  [chipSelectItemType]: {},
};

const mappingConfigurationDefaultElement = {
  [chipSelectItemType]: {
    identity: 'value',
    labelKeyName: 'label',
    colorKeyname: 'colorChip',
    useGenerateListTranslation: true,
  },
};

// destination -> from
// additionalProps          -> representationDataProps
// valueContainer           -> valueSingleRowData
// generateListSelectItems  -> generateListWithTranslationForSelectItems
const mappingRepresentationElementFormatter = {
  [linkElementType]: ({
    location,
    value,
    valueContainer,
    baseUrl,
    additionalProps = {},
    defaultProps = {},
    urlTemplate = '',
    generateLink = () => null,
    generateLinkFromCurrentLocation = () => null,
  }) => {
    let linkTarget = baseUrl;

    if (typeof generateLink === 'function') {
      linkTarget = generateLink({
        currentPathname: location.pathname,
        valueContainer,
      });
    }

    if (typeof generateLinkFromCurrentLocation === 'function') {
      linkTarget = generateLinkFromCurrentLocation({
        currentLocation: location,
        singleRowValue: valueContainer,
      });
    }

    if (urlTemplate) {
      linkTarget = strHelp.templateString(urlTemplate, valueContainer);
    }

    // replace old search location with current search location
    if (typeof linkTarget === 'string' && linkTarget.startsWith('?')) {
      const combineSearchParams = {
        ...queryStringToObject(location.search),
        ...queryStringToObject(linkTarget),
      };

      linkTarget = '?' + objectToQueryString(combineSearchParams);
    }

    return (
      <TypographyLinkPrimary
        {...defaultProps}
        {...additionalProps}
        baseUrl={linkTarget}
        typographyValue={value}
      />
    );
  },
  [buttonCollectionsType]: ({ additionalProps }) => {
    return <ButtonCollections {...additionalProps} />;
  },
  [typographyElementType]: ({ value, defaultProps = {}, additionalProps = {} }) => {
    const { className: additionalClassName } = additionalProps;
    const { className: defaultClassName } = defaultProps;

    return (
      <Typography
        {...defaultProps}
        {...additionalProps}
        className={clsx([...defaultClassName, additionalClassName])}
      >
        {value}
      </Typography>
    );
  },
  [labelType]: ({ additionalProps, value }) => {
    return <label {...additionalProps}>{value}</label>;
  },
  [renderButtonType]: ({ location, valueContainer = {}, renderButton }) => {
    if (typeof renderButton !== 'function') return null;

    return renderButton({ valueContainer, location });
  },
  [chipSelectItemType]: ({
    t,
    value,
    elementConfiguration = mappingConfigurationDefaultElement[chipSelectItemType],
    generateSelectListItems = () => null,
  }) => {
    const {
      identity,
      labelKeyName,
      colorKeyname,
      useGenerateListTranslation = true,
    } = elementConfiguration;

    let listSelectItems = generateSelectListItems();
    if (useGenerateListTranslation) {
      listSelectItems = generateSelectListItems(t);
    }

    if (isEmpty(listSelectItems)) return value;

    let selectedObjectItemsOnArr = arrHelp.filterObjDataWithID(listSelectItems, identity, value);
    if (isEmpty(selectedObjectItemsOnArr)) selectedObjectItemsOnArr = listSelectItems[0];

    return (
      <Chip
        size='small'
        label={selectedObjectItemsOnArr[labelKeyName]}
        color={selectedObjectItemsOnArr[colorKeyname]}
      />
    );
  },
  [emptyElementType]: () => null,
};

export default function useRepresentationElement() {
  const location = useLocation();
  const { t } = useTranslation();

  function canFormat(elementType) {
    return Object.keys(mappingRepresentationElementFormatter).includes(elementType);
  }

  function generateRepresentationElement({
    value = '',
    valueContainer = null,
    elementType = '',
    additionalProps = {},
    elementConfiguration = {},
    generateLink = null,
    generateLinkFromCurrentLocation = null,
    generateSelectListItems = null,
    getRepresentationElementType = null,
    baseUrl = '',
    urlTemplate = '',
    renderButton = null,
  } = {}) {
    if (typeof getRepresentationElementType == 'function') {
      elementType = getRepresentationElementType({ valueContainer });
    }

    if (!canFormat(elementType)) return null;

    return mappingRepresentationElementFormatter[elementType]({
      t,
      location,
      value,
      valueContainer,
      defaultProps: mappingPropsDefaultElement[elementType],
      additionalProps,
      elementConfiguration,
      baseUrl,
      urlTemplate,
      generateLink,
      generateLinkFromCurrentLocation,
      generateSelectListItems,
      renderButton,
    });
  }

  return {
    generateRepresentationElement,
  };
}
