import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_INDO_REGIONAL_DATA } from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  STATUS_REQUEST_BASE_FAILED,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_BASE_PENDING,
  STATUS_REQUEST_BASE_SUCCESS,
} from '../../utils/constants/store.constant';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import { indoRegionalList } from './indo-regional.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  indoRegionalData: LocalStorage.get(LOCAL_STORAGE_INDO_REGIONAL_DATA),
};

const indoRegionalSlice = createSlice({
  name: NAME_STORE.INDO_REGIONAL,
  initialState,
  reducer: {
    indoRegionalList,
    clearIndoRegional: (state) => {
      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      indoRegionalData: LOCAL_STORAGE_INDO_REGIONAL_DATA,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(indoRegionalList.fulfilled, (state, action) => {
      state.statusRequest = STATUS_REQUEST_BASE_SUCCESS;
      state.indoRegionalData = action.payload.regionalData;
    }),
      builder.addCase(indoRegionalList.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_BASE_FAILED;
      }),
      builder.addCase(indoRegionalList.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_BASE_PENDING;
      });
  },
});

export const { actions, reducer } = indoRegionalSlice;

export const { clearIndoRegional } = actions;

export default reducer;
