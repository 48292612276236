import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_COMPANY } from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  SLICE_NAME_DATA_COMPANY_USER,
  SLICE_NAME_SETTING_COMPANY_DETAILS,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_DETAIL_SETTING_COMPANY_FAILED,
  STATUS_REQUEST_DETAIL_SETTING_COMPANY_PENDING,
  STATUS_REQUEST_DETAIL_SETTING_COMPANY_SUCCESS,
  STATUS_REQUEST_DETAIL_USER_COMPANY_FAILED,
  STATUS_REQUEST_DETAIL_USER_COMPANY_PENDING,
  STATUS_REQUEST_DETAIL_USER_COMPANY_SUCCESS,
  STATUS_REQUEST_REGISTER_COMPANY_FAILED,
  STATUS_REQUEST_REGISTER_COMPANY_PENDING,
  STATUS_REQUEST_REGISTER_COMPANY_SUCCESS,
  STATUS_REQUEST_UPDATE_COMPANY_USER_FAILED,
  STATUS_REQUEST_UPDATE_COMPANY_USER_PENDING,
  STATUS_REQUEST_UPDATE_COMPANY_USER_SUCCESS,
} from '../../utils/constants/store.constant';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import {
  detailCompanyWithID,
  getCompanyDetails,
  registerCompany,
  updateCompanyUser,
} from './company.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  [SLICE_NAME_DATA_COMPANY_USER]: LocalStorage.get(LOCAL_STORAGE_COMPANY),
  [SLICE_NAME_SETTING_COMPANY_DETAILS]: null,
};

const companySlice = createSlice({
  name: NAME_STORE.COMPANY,
  initialState,
  reducers: {
    getCompanyDetails,
    detailCompanyWithID,
    updateCompanyUser,
    setDataCompanyUserState: (state, action) => {
      const currentDataCompanyUser = state[SLICE_NAME_DATA_COMPANY_USER];

      const { payload: payloadData } = action,
        { data: companyData } = payloadData;

      if (isEmpty(companyData)) return state;

      const { company } = companyData;

      const newCompanyUserData = {
        ...currentDataCompanyUser,
        ...company,
      };

      if (!isEmpty(newCompanyUserData)) {
        LocalStorage.set(LOCAL_STORAGE_COMPANY, newCompanyUserData);
      }

      return {
        ...state,
        dataCompanyUser: newCompanyUserData,
      };
    },
    clearCompany: (state, action) => {
      LocalStorage.remove(LOCAL_STORAGE_COMPANY);

      if (action && action.payload && action.payload.removeLocalStorageOnly) return state;

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        dataCompanyUser: null,
      };
    },
    // remove data on sliceName state and remove data localstorage
    // pass sliceName and localStorageName on action
    clearDataCompanySlice: (state, action) => {
      const { sliceName, localStorageName } = action.payload;

      if (localStorageName && LocalStorage.get(localStorageName)) {
        LocalStorage.remove(localStorageName);
      }

      return {
        ...state,
        [sliceName]: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      [SLICE_NAME_DATA_COMPANY_USER]: LocalStorage.get(LOCAL_STORAGE_COMPANY),
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(detailCompanyWithID.fulfilled, (state, action) => {
      state.dataCompanyUser = action.payload.dataCompanyUser;

      if (!isEmpty(state.dataCompanyUser)) {
        LocalStorage.set(LOCAL_STORAGE_COMPANY, state.dataCompanyUser);
      }

      state.statusRequest = STATUS_REQUEST_DETAIL_USER_COMPANY_SUCCESS;
    }),
      builder.addCase(detailCompanyWithID.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_USER_COMPANY_PENDING;
      }),
      builder.addCase(detailCompanyWithID.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_USER_COMPANY_FAILED;
        state.dataCompanyUser = null;
      }),
      builder.addCase(getCompanyDetails.fulfilled, (state, action) => {
        state[SLICE_NAME_SETTING_COMPANY_DETAILS] =
          action.payload[SLICE_NAME_SETTING_COMPANY_DETAILS];
        state.statusRequest = STATUS_REQUEST_DETAIL_SETTING_COMPANY_SUCCESS;
      }),
      builder.addCase(getCompanyDetails.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_SETTING_COMPANY_PENDING;
      }),
      builder.addCase(getCompanyDetails.rejected, (state) => {
        state[SLICE_NAME_SETTING_COMPANY_DETAILS] = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_SETTING_COMPANY_FAILED;
      }),
      builder.addCase(updateCompanyUser.fulfilled, (state, action) => {
        state.dataCompanyUser = action.payload.dataCompanyUser;
        state.statusRequest = STATUS_REQUEST_UPDATE_COMPANY_USER_SUCCESS;
      }),
      builder.addCase(updateCompanyUser.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_COMPANY_USER_PENDING;
      }),
      builder.addCase(updateCompanyUser.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_COMPANY_USER_FAILED;
      }),
      builder.addCase(registerCompany.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_REGISTER_COMPANY_SUCCESS;
      }),
      builder.addCase(registerCompany.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_REGISTER_COMPANY_PENDING;
      }),
      builder.addCase(registerCompany.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_REGISTER_COMPANY_FAILED;
      });
  },
});

export const { actions, reducer } = companySlice;

export const { clearCompany, clearDataCompanySlice } = actions;

export default reducer;
