import { isValid, parse } from 'date-fns';
import { enGB } from 'date-fns/locale';

// checking valid date
function isValidDate(date) {
  if (!date) {
    return date instanceof Date && !isNaN(date);
  }

  const changeDateIntoSring = new Date(date).toLocaleDateString();

  const parsedDate = parse(changeDateIntoSring, 'P', new Date(), { locale: enGB });

  return isValid(parsedDate);
}

// checking validation date
// https://stackoverflow.com/questions/10589732/checking-if-a-date-is-valid-in-javascript
function isValidDateV2(date) {
  return date instanceof Date && !isNaN(date.valueOf());
}

const dateHelpers = {
  isValidDate,
  isValidDateV2,
};

export default dateHelpers;
