import { cloneDeep, isEmpty, pick } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_DISCOUNT_PERCENTAGE_TYPE } from '../../default/discount-type.default';
import { DEFAULT_COMPARISON_VALUE_NUMBER } from '../../default/number.default';
import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE,
  DEFAULT_KEY_NAME_DELIVERY_DATE_API_REF,
  DEFAULT_KEY_NAME_FILE_PROPERTIES,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_REF_API,
  DEFAULT_KEY_NAME_UUID_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';
import arrHelp from '../../helpers/array.helpers';
import formatHelp from '../../helpers/format.helpers';
import formulaHelpers from '../../helpers/formula.helpers';
import objHelper from '../../helpers/object.helper';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import {
  allowKeysProductPurchaseInvoiceData,
  allowKeysPurchaseInvoiceData,
  formatCalculationPurchaseInvoiceData,
  initialProductPurchaseInvoiceData,
  initialProductPurchaseInvoiceValidation,
  initialPurchaseInvoiceData,
  initialPurchaseInvoiceValidation,
  modulTypePurchaseInvoice,
  purchaseInvoiceCalculationNumberKeyNames,
} from './initial-data-purchase-invoice';

/**
 *
 * @param { number } amount amount data generated
 * @param { string } customDataKey return key name of data
 * @param { string } customValidationKey return key name of validation
 * @returns
 *      generated amount data and validation purchase invoice for add purchase invoice
 */
export const generateDataNValidationAddPurchaseInvoice = (
  amount = 1,
  customDataKey = 'purchaseInvoiceData',
  customValidationKey = 'purchaseInvoiceValidation',
) => {
  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    initialPurchaseInvoiceData,
    initialPurchaseInvoiceValidation,
    customDataKey,
    customValidationKey,
  );
};

/**
 *
 * @param { number } amount amount data generated
 * @param { string } customDataKey return key name of data
 * @param { string } customValidationKey return key name of validation
 * @returns
 *      generated amount data and validation purchase invoice for add purchase invoice
 */
export const generateDataNValidationAddPurchaseInvoiceV2 = ({
  amount = 1,
  companyUserData = {},
  customDataKey = 'purchaseInvoiceData',
  customValidationKey = 'purchaseInvoiceValidation',
}) => {
  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    (id) => {
      return {
        ...initialPurchaseInvoiceData(id),
        accounting_period: !isEmpty(companyUserData) ? companyUserData.accounting_periode : null,
        [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
          getAccountingPeriodCompareWithDocumentTransactionDate({
            companyUserData,
          }),
      };
    },
    initialPurchaseInvoiceValidation,
    customDataKey,
    customValidationKey,
  );
};

/**
 *
 * @param { number } amount amount data generated
 * @param { string } customDataKey key name return of data
 * @param { string } customValidationKey key name return of validation
 * @returns
 *      generated amount data and validation product invoice for add purchase invoice
 */
export const generateDataNValidationAddProductPurchaseInvoice = (
  amount = 2,
  customDataKey = 'productPurchaseInvoiceData',
  customValidationKey = 'productPurchaseInvoiceValidation',
) => {
  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    initialProductPurchaseInvoiceData,
    initialProductPurchaseInvoiceValidation,
    customDataKey,
    customValidationKey,
  );
};

/**
 *
 * @param { number } amount amount data generated
 * @param { string } customDataKey key name return of data
 * @param { string } customValidationKey key name return of validation
 * @returns
 *      generated amount data and validation product invoice for add purchase invoice
 */
export const generateDataNValidationAddProductPurchaseInvoiceV2 = ({
  amount = 2,
  customDataKey = 'productPurchaseInvoiceData',
  customValidationKey = 'productPurchaseInvoiceValidation',
}) => {
  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    initialProductPurchaseInvoiceData,
    initialProductPurchaseInvoiceValidation,
    customDataKey,
    customValidationKey,
  );
};

/**
 *
 * @param { object } purchaseDeliveryData selected purchase delivery data for convert into purchase invoice data
 * @param { string } customDataKey key name return of data
 * @param { string } customValidationKey key name return of validation
 * @returns
 *      generated purchase invoice data and validation from purchase delivery data
 */
export const generateDataNValidationAddPurchaseInvoiceFromPurchaseDelivery = (
  purchaseDeliveryData,
  customDataKey = 'purchaseInvoiceData',
  customValidationKey = 'purchaseInvoiceValidation',
) => {
  if (typeof purchaseDeliveryData !== 'object') {
    return;
  }

  if (Array.isArray(purchaseDeliveryData)) {
    if (!purchaseDeliveryData.length) return;

    purchaseDeliveryData = purchaseDeliveryData[0];
  }

  const {
    purchase_delivery_id,
    purchase_delivery_no,
    supplier_name,
    supplier_id,
    supplier_code,
    contact_code,
    supplier_address,
  } = purchaseDeliveryData;

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const purchaseInvoiceData = usedID.map((id) => {
    return {
      ...initialPurchaseInvoiceData(id, '', []),
      transaction_date: moment(),
      supplier_id,
      supplier_address,
      supplier_id_container: {
        contact_id: supplier_id,
        contact_name: supplier_name,
        contact_code: supplier_code || contact_code,
      },
      modul_id: purchase_delivery_id,
      modul_id_container: {
        transaction_id: purchase_delivery_id,
        transaction_no: purchase_delivery_no,
      },
      modul_type: modulTypePurchaseInvoice.delivery,
      is_delivery_goods: false,
      is_paid_off: false,
      discount_account_id_container: {
        account_id: purchaseDeliveryData.discount_account_id || '',
        account_name: purchaseDeliveryData.discount_account_name || '',
        account_code: purchaseDeliveryData.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: purchaseDeliveryData.expense_account_id || '',
        account_name: purchaseDeliveryData.expense_account_name || '',
        account_code: purchaseDeliveryData.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: purchaseDeliveryData.down_payment_account_id || '',
        account_name: purchaseDeliveryData.down_payment_account_name || '',
        account_code: purchaseDeliveryData.down_payment_account_code || '',
      },
    };
  });

  const purchaseInvoiceValidation = usedID.map((id) => {
    return initialPurchaseInvoiceValidation(id, '', []);
  });

  return {
    [customDataKey]: purchaseInvoiceData,
    [customValidationKey]: purchaseInvoiceValidation,
  };
};

/**
 *
 * @param { object } purchaseDeliveryData selected purchase delivery data for convert into purchase invoice data
 * @param { string } customDataKey key name return of data
 * @param { string } customValidationKey key name return of validation
 * @returns
 *      generated purchase invoice data and validation from purchase delivery data
 */
export const generateDataNValidationAddPurchaseInvoiceFromPurchaseDeliveryV2 = ({
  purchaseDeliveryData,
  companyUserData = {},
  customDataKey = 'purchaseInvoiceData',
  customValidationKey = 'purchaseInvoiceValidation',
}) => {
  if (typeof purchaseDeliveryData !== 'object') return;

  if (Array.isArray(purchaseDeliveryData)) {
    if (!purchaseDeliveryData.length) return;

    purchaseDeliveryData = purchaseDeliveryData[0];
  }

  purchaseDeliveryData = objHelper.changeFormatValueV2(
    purchaseDeliveryData,
    purchaseInvoiceCalculationNumberKeyNames,
    '',
    (value) => Number(value) || 0,
  );

  const {
    purchase_delivery_id,
    purchase_delivery_no,
    supplier_name,
    supplier_id,
    supplier_code,
    contact_code,
    supplier_address,
    warehouse_id,
    warehouse_name,
    discount_type = DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
    discount_nominal,
    product,
  } = purchaseDeliveryData;

  const amountConst = product.reduce((amount, curr) => {
    const { quantity_available, price } = curr;

    return amount + Number(quantity_available) * Number(price);
  }, 0);

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const purchaseInvoiceData = usedID.map((id) => {
    return {
      ...initialPurchaseInvoiceData(id, '', []),
      ...purchaseDeliveryData,
      amount_const: Number(amountConst),
      discount_type,
      accounting_period: isEmpty(companyUserData)
        ? null
        : companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE],
      transaction_date: moment(),
      supplier_id,
      supplier_address,
      supplier_id_container: {
        contact_id: supplier_id,
        contact_name: supplier_name,
        contact_code: supplier_code || contact_code,
      },
      modul_id: purchase_delivery_id,
      modul_id_container: {
        transaction_id: purchase_delivery_id,
        transaction_no: purchase_delivery_no,
      },
      modul_type: modulTypePurchaseInvoice.delivery,
      is_delivery_goods: false,
      is_paid_off: false,
      warehouse_id_container: {
        warehouse_id,
        warehouse_name,
      },
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
          documentData: purchaseDeliveryData,
          keynameTransactionDocumentData: DEFAULT_KEY_NAME_DELIVERY_DATE_API_REF,
        }),
      discount_account_id_container: {
        account_id: purchaseDeliveryData.discount_account_id || '',
        account_name: purchaseDeliveryData.discount_account_name || '',
        account_code: purchaseDeliveryData.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: purchaseDeliveryData.expense_account_id || '',
        account_name: purchaseDeliveryData.expense_account_name || '',
        account_code: purchaseDeliveryData.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: purchaseDeliveryData.down_payment_account_id || '',
        account_name: purchaseDeliveryData.down_payment_account_name || '',
        account_code: purchaseDeliveryData.down_payment_account_code || '',
      },
      discount_nominal: Number(discount_nominal) || '',
    };
  });

  const purchaseInvoiceValidation = usedID.map((id) => {
    return initialPurchaseInvoiceValidation(id, '', []);
  });

  return {
    [customDataKey]: purchaseInvoiceData,
    [customValidationKey]: purchaseInvoiceValidation,
  };
};

/**
 *
 * @param { array } productPurchaseDelivery selected product purchase delivery data for convert into purchase invoice data
 * @param { string } customDataKey key name return of data
 * @param { string } customValidationKey key name return of validation
 * @returns
 *      generated product purchase invoice data and validation from purchase delivery data
 */
export const generateDataNValidationAddProductPurchaseInvoiceFromPurchaseDelivery = (
  productPurchaseDelivery,
  customDataKey = 'productPurchaseInvoiceData',
  customValidationKey = 'productPurchaseInvoiceValidation',
) => {
  if (typeof productPurchaseDelivery !== 'object') return;

  if (Array.isArray(productPurchaseDelivery)) {
    if (!productPurchaseDelivery.length) {
      const newID = uuidv4();

      return {
        [customDataKey]: [initialProductPurchaseInvoiceData(newID)],
        [customValidationKey]: [initialProductPurchaseInvoiceValidation(newID)],
      };
    }
  }

  const usedID = arrHelp.generateArrWithFunc(productPurchaseDelivery.length, uuidv4);

  const productPurchaseInvoiceData = usedID.map((id, index) => {
    // const quantity = '1'

    const {
      purchase_delivery_product_id,
      product_id,
      product_name,
      price,
      description: product_description,
      quantity,
      quantity_available,
      quantity_origin,
      product_unit,
      unit_destination,
      unit_origin,
    } = productPurchaseDelivery[index];

    let amount = Number(price) * Number(quantity);

    amount = formatHelp.currencyFormatWithRegex(amount);

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    return {
      ...initialProductPurchaseInvoiceData(id),
      ...productPurchaseDelivery[index],
      purchase_delivery_product_id,
      product_id_container: {
        product_id,
        product_name,
        ...productPurchaseDelivery[index],
      },
      amount,
      product_description: product_description || '',
      quantity_available_const: Number(quantity_available),
      quantity_available: quantity_available - quantity,
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantity_available),
        value_comparison: quantityComparison,
      },
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
    };
  });

  const productPurchaseInvoiceValidation = usedID.map((id) => {
    return initialProductPurchaseInvoiceValidation(id);
  });

  return {
    [customDataKey]: productPurchaseInvoiceData,
    [customValidationKey]: productPurchaseInvoiceValidation,
  };
};

/**
 *
 * @param { array } productPurchaseDelivery selected product purchase delivery data for convert into purchase invoice data
 * @param { string } customDataKey key name return of data
 * @param { string } customValidationKey key name return of validation
 * @returns
 *      generated product purchase invoice data and validation from purchase delivery data
 */
export const generateDataNValidationAddProductPurchaseInvoiceFromPurchaseDeliveryV2 = ({
  productPurchaseDelivery,
  customDataKey = 'productPurchaseInvoiceData',
  customValidationKey = 'productPurchaseInvoiceValidation',
}) => {
  if (typeof productPurchaseDelivery !== 'object') return;

  if (Array.isArray(productPurchaseDelivery)) {
    if (!productPurchaseDelivery.length) {
      const newID = uuidv4();

      return {
        [customDataKey]: [initialProductPurchaseInvoiceData(newID)],
        [customValidationKey]: [initialProductPurchaseInvoiceValidation(newID)],
      };
    }
  }

  const { product } = productPurchaseDelivery;

  const purchaseDeliveryProductLength = product ? product.length : 0;

  const usedID = arrHelp.generateArrWithFunc(purchaseDeliveryProductLength, uuidv4);

  const productPurchaseInvoiceData = usedID.map((id, index) => {
    const {
      purchase_delivery_product_id,
      product_id,
      product_name,
      price,
      description: product_description,
      quantity,
      quantity_origin,
      product_unit,
      unit_destination,
      unit_origin,
    } = product[index];

    let amount = Number(price) * Number(quantity);

    return {
      ...initialProductPurchaseInvoiceData(id),
      ...product[index],
      purchase_delivery_product_id,
      product_id_container: {
        product_id,
        product_name,
        ...product[index],
      },
      price: Number(price),
      amount,
      product_description: product_description || '',
      quantity: Number(quantity),
      quantity_available_const: Number(quantity),
      quantity_available: quantity - quantity,
      quantity_comparison: !hasImplementedUnitProductUnit
        ? 1
        : Number(quantity_origin) / Number(quantity),
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantity),
        value_comparison: Number(quantity_origin) / Number(quantity),
      },
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      quantity_available_with_unit: `${Number(quantity)} ${unit_origin || product_unit}`,
    };
  });

  const productPurchaseInvoiceValidation = usedID.map((id) => {
    return initialProductPurchaseInvoiceValidation(id);
  });

  return {
    [customDataKey]: productPurchaseInvoiceData,
    [customValidationKey]: productPurchaseInvoiceValidation,
  };
};

/**
 *
 * @param { object } purchaseOrderData selected purchase order data for convert into purchase invoice data
 * @param { string } customDataKey key name return of data
 * @param { string } customValidationKey key name return of validation
 * @returns
 *      generated purchase invoice data and validation from purchase delivery data
 */
export const generateDataNValidationAddPurchaseInvoiceFromPurchaseOrder = (
  purchaseOrderData,
  customDataKey = 'purchaseInvoiceData',
  customValidationKey = 'purchaseInvoiceValidation',
) => {
  if (typeof purchaseOrderData !== 'object') {
    return;
  }

  if (Array.isArray(purchaseOrderData)) {
    if (!purchaseOrderData.length) return;

    purchaseOrderData = purchaseOrderData[0];
  }

  const {
    purchase_order_id,
    purchase_order_no,
    supplier_name,
    supplier_id,
    supplier_code,
    supplier_address,
    // expired_date,
    // description,
    contact_code,
  } = purchaseOrderData;

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const purchaseInvoiceData = usedID.map((id) => {
    return {
      ...initialProductPurchaseInvoiceData(id),
      supplier_id,
      supplier_address,
      supplier_id_container: {
        contact_id: supplier_id,
        contact_name: supplier_name,
        contact_code: supplier_code || contact_code,
      },
      modul_id: purchase_order_id,
      modul_id_container: {
        transaction_id: purchase_order_id,
        transaction_no: purchase_order_no,
      },
      modul_type: modulTypePurchaseInvoice.order,
      is_delivery_goods: false,
      is_paid_off: false,
      transaction_date: moment(),
      expired_date: null,
      discount_account_id_container: {
        account_id: purchaseOrderData.discount_account_id || '',
        account_name: purchaseOrderData.discount_account_name || '',
        account_code: purchaseOrderData.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: purchaseOrderData.expense_account_id || '',
        account_name: purchaseOrderData.expense_account_name || '',
        account_code: purchaseOrderData.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: purchaseOrderData.down_payment_account_id || '',
        account_name: purchaseOrderData.down_payment_account_name || '',
        account_code: purchaseOrderData.down_payment_account_code || '',
      },
    };
  });

  const purchaseInvoiceValidation = usedID.map((id) => {
    return initialPurchaseInvoiceValidation(id, '', []);
  });

  return {
    [customDataKey]: purchaseInvoiceData,
    [customValidationKey]: purchaseInvoiceValidation,
  };
};

/**
 *
 * @param { object } purchaseOrderData selected purchase order data for convert into purchase invoice data
 * @param { string } customDataKey key name return of data
 * @param { string } customValidationKey key name return of validation
 * @returns
 *      generated purchase invoice data and validation from purchase delivery data
 */
export const generateDataNValidationAddPurchaseInvoiceFromPurchaseOrderV2 = ({
  purchaseOrderData,
  companyUserData = {},
  customDataKey = 'purchaseInvoiceData',
  customValidationKey = 'purchaseInvoiceValidation',
}) => {
  if (typeof purchaseOrderData !== 'object') {
    return;
  }

  if (Array.isArray(purchaseOrderData)) {
    if (!purchaseOrderData.length) return;

    purchaseOrderData = purchaseOrderData[0];
  }

  const {
    purchase_order_id,
    purchase_order_no,
    supplier_name,
    supplier_id,
    supplier_code,
    supplier_address,
    contact_code,
    warehouse_id,
    warehouse_name,
    discount_type = DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
  } = purchaseOrderData;

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const purchaseInvoiceData = usedID.map((id) => {
    return {
      ...initialPurchaseInvoiceData(id),
      ...purchaseOrderData,
      discount_type,
      accounting_period: isEmpty(companyUserData)
        ? null
        : companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE],
      supplier_id,
      supplier_address,
      supplier_id_container: {
        contact_id: supplier_id,
        contact_name: supplier_name,
        contact_code: supplier_code || contact_code,
      },
      modul_id: purchase_order_id,
      modul_id_container: {
        transaction_id: purchase_order_id,
        transaction_no: purchase_order_no,
      },
      modul_type: modulTypePurchaseInvoice.order,
      is_delivery_goods: false,
      is_paid_off: false,
      transaction_date: moment(),
      expired_date: null,
      warehouse_id_container: {
        warehouse_id,
        warehouse_name,
      },
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
          documentData: purchaseOrderData,
        }),
      discount_account_id_container: {
        account_id: purchaseOrderData.discount_account_id || '',
        account_name: purchaseOrderData.discount_account_name || '',
        account_code: purchaseOrderData.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: purchaseOrderData.expense_account_id || '',
        account_name: purchaseOrderData.expense_account_name || '',
        account_code: purchaseOrderData.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: purchaseOrderData.down_payment_account_id || '',
        account_name: purchaseOrderData.down_payment_account_name || '',
        account_code: purchaseOrderData.down_payment_account_code || '',
      },
    };
  });

  const purchaseInvoiceValidation = usedID.map((id) => {
    return initialPurchaseInvoiceValidation(id, '', []);
  });

  return {
    [customDataKey]: purchaseInvoiceData,
    [customValidationKey]: purchaseInvoiceValidation,
  };
};

/**
 *
 * @param { array } productPurchaseOrder selected product purchase order data for convert into purchase invoice data
 * @param { string } customDataKey key name return of data
 * @param { string } customValidationKey key name return of validation
 * @returns
 *      generated product purchase invoice data and validation from purchase order data
 */
export const generateDataNValidationAddProductPurchaseInvoiceFromPurchaseOrder = (
  productPurchaseOrder,
  customDataKey = 'productPurchaseInvoiceData',
  customValidationKey = 'productPurchaseInvoiceValidation',
) => {
  if (typeof productPurchaseOrder !== 'object') {
    return;
  }

  if (Array.isArray(productPurchaseOrder)) {
    if (!productPurchaseOrder.length) {
      const newID = uuidv4();

      return {
        [customDataKey]: [initialProductPurchaseInvoiceData(newID)],
        [customValidationKey]: [initialProductPurchaseInvoiceValidation(newID)],
      };
    }
  }

  const usedID = arrHelp.generateArrWithFunc(productPurchaseOrder.length, uuidv4);

  const productPurchaseInvoiceData = usedID.map((id, index) => {
    // const quantity = '1'

    const {
      purchase_order_product_id,
      product_id,
      product_name,
      price,
      description: product_description,
      quantity,
      quantity_available,
      quantity_origin,
      product_unit,
      unit_destination,
      unit_origin,
    } = productPurchaseOrder[index];

    let amount = Number(price) * Number(quantity);

    amount = formatHelp.currencyFormatWithRegex(amount);

    return {
      ...initialProductPurchaseInvoiceData(id),
      ...productPurchaseOrder[index],
      purchase_order_product_id,
      product_id_container: {
        product_id,
        product_name,
        ...productPurchaseOrder[index],
      },
      amount,
      product_description: product_description || '',
      quantity_available_const: Number(quantity_available),
      quantity_available: quantity_available - quantity,
      quantity_comparison: !hasImplementedUnitProductUnit
        ? 1
        : Number(quantity_origin) / Number(quantity),
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantity_available),
        value_comparison: Number(quantity_origin) / Number(quantity),
      },
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
    };
  });

  const productPurchaseInvoiceValidation = usedID.map((id) => {
    return initialProductPurchaseInvoiceValidation(id);
  });

  return {
    [customDataKey]: productPurchaseInvoiceData,
    [customValidationKey]: productPurchaseInvoiceValidation,
  };
};

/**
 *
 * @param { array } productPurchaseOrder selected product purchase order data for convert into purchase invoice data
 * @param { string } customDataKey key name return of data
 * @param { string } customValidationKey key name return of validation
 * @returns
 *      generated product purchase invoice data and validation from purchase order data
 */
export const generateDataNValidationAddProductPurchaseInvoiceFromPurchaseOrderV2 = ({
  productPurchaseOrder,
  customDataKey = 'productPurchaseInvoiceData',
  customValidationKey = 'productPurchaseInvoiceValidation',
}) => {
  if (typeof productPurchaseOrder !== 'object') {
    return;
  }

  if (Array.isArray(productPurchaseOrder)) {
    if (!productPurchaseOrder.length) {
      const newID = uuidv4();

      return {
        [customDataKey]: [initialProductPurchaseInvoiceData(newID)],
        [customValidationKey]: [initialProductPurchaseInvoiceValidation(newID)],
      };
    }
  }

  const purchaseOrderProducts =
    productPurchaseOrder[DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_REF_API];
  const purchaseOrderProductLength = purchaseOrderProducts ? purchaseOrderProducts.length : 0;

  const usedID = arrHelp.generateArrWithFunc(purchaseOrderProductLength, uuidv4);

  const productPurchaseInvoiceData = usedID.map((id, index) => {
    const {
      purchase_order_product_id,
      product_id,
      product_name,
      description: product_description,
    } = purchaseOrderProducts[index];

    return {
      ...initialProductPurchaseInvoiceData(id),
      ...purchaseOrderProducts[index],
      purchase_order_product_id,
      product_id_container: {
        product_id,
        product_name,
        ...purchaseOrderProducts[index],
      },
      product_description,
      ...formulaHelpers.parseProductAPI2display({
        product: purchaseOrderProducts[index],
      }),
    };
  });

  const productPurchaseInvoiceValidation = usedID.map((id) => {
    return initialProductPurchaseInvoiceValidation(id);
  });

  return {
    [customDataKey]: productPurchaseInvoiceData,
    [customValidationKey]: productPurchaseInvoiceValidation,
  };
};

/**
 *
 * @param { array } purchaseInvoiceData purchase invoice data for generalized
 * @param { arrat } productPurchaseInvoice product on purchase invoice data
 * @returns
 *      generalized data for adding on purchase invoice
 */
export const generalizeDataAddPurchaseInvoice = (purchaseInvoiceData, productPurchaseInvoice) => {
  if (Array.isArray(purchaseInvoiceData)) {
    purchaseInvoiceData = purchaseInvoiceData.map((singlePurchaseInvoiceData) => {
      const {
        supplier_id_container,
        transaction_date,
        expired_date,
        discount_percentage,
        discount_nominal,
        discount_type,
        discount_account_id,
        expense_value,
        expense_account_id,
        down_payment_value,
        down_payment_account_id,
        attachment,
        is_paid_off,
      } = singlePurchaseInvoiceData;

      let supplier_name = '';
      if (!isEmpty(supplier_id_container)) {
        const { contact_name } = supplier_id_container;

        supplier_name = contact_name;
      }

      let generalizedDataPurchaseInvoice = objHelper.filterKeyObj(
        cloneDeep(singlePurchaseInvoiceData),
        [],
        allowKeysPurchaseInvoiceData,
      );

      const uuidAttachment =
        Array.isArray(attachment) && attachment.length
          ? attachment[0][DEFAULT_KEY_NAME_FILE_PROPERTIES][DEFAULT_KEY_NAME_UUID_API_REF] || ''
          : '';

      generalizedDataPurchaseInvoice = {
        ...generalizedDataPurchaseInvoice,
        supplier_name,
        transaction_date: transaction_date ? moment(transaction_date).toISOString(true) : null,
        expired_date: expired_date ? moment(expired_date).toISOString(true) : null,
        [DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF]: uuidAttachment,
        discount_percentage: discount_percentage == 0 ? '' : discount_percentage,
        discount_nominal: discount_nominal == 0 ? '' : discount_nominal,
        discount_type: discount_nominal == 0 || discount_percentage == 0 ? '' : discount_type,
        discount_account_id:
          discount_nominal == 0 || discount_percentage == 0 ? '' : discount_account_id,
        expense_account_id: expense_value ? expense_account_id : '',
        down_payment_account_id: down_payment_value ? down_payment_account_id : '',
        is_paid_off: is_paid_off || false,
      };

      return (generalizedDataPurchaseInvoice = objHelper.filteringExistedValue(
        generalizedDataPurchaseInvoice,
        [],
        true,
      ));
    });
  }

  if (Array.isArray(productPurchaseInvoice)) {
    productPurchaseInvoice = productPurchaseInvoice.map((singleProductPurchaseInvoice) => {
      const { description, product_unit } = singleProductPurchaseInvoice;

      let generalizedDataProductPurchaseInvoice = objHelper.filterKeyObj(
        singleProductPurchaseInvoice,
        [],
        allowKeysProductPurchaseInvoiceData,
      );

      generalizedDataProductPurchaseInvoice = objHelper.changeFormatValueV2(
        generalizedDataProductPurchaseInvoice,
        formatCalculationPurchaseInvoiceData,
        '',
        formatHelp.reverseCurrencyFormatWithRegex,
      );

      return pick(
        {
          ...generalizedDataProductPurchaseInvoice,
          product_description: description || '',
          unit: product_unit || '',
        },
        allowKeysProductPurchaseInvoiceData,
      );
    });

    productPurchaseInvoice = arrHelp.filteringExistedObjValueWithUniqueKey(
      productPurchaseInvoice,
      'product_id',
    );
  }

  return {
    ...purchaseInvoiceData[0],
    product: productPurchaseInvoice,
  };
};
