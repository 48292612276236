import { createAsyncThunk } from '@reduxjs/toolkit';

import notificationServices from '../../services/API/notification/notification.services';
import { SLICE_NAME_UNREAD_NOTIFICATION_COUNT } from '../../utils/constants/store.constant';
import { additionalParamsUnShowMessage } from '../../utils/default/additional-params-action';
import { getRelationalNotificationTypeWithNotificationActionListConfiguration } from '../../utils/default/notification-config.default';
import { DEFAULT_KEY_NAME_NOTIFICATION_TYPE } from '../../utils/default/object-keyname.default';
import {
  creatorDetailAction,
  creatorListActionWithDynamicSliceName,
} from '../creator-action/creator-action.helper';

// action for get unread notifications
export const getUnreadNotifications = createAsyncThunk(
  'notification/count-unread-notifications',
  async (paramsGetUnreadNotifications, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      {
        ...paramsGetUnreadNotifications,
        ...additionalParamsUnShowMessage,
      },
      notificationServices.getUnreadNotifications,
      '',
      SLICE_NAME_UNREAD_NOTIFICATION_COUNT,
      '',
    );
  },
);

// action for getting notifications list
export const getNotificationList = createAsyncThunk(
  'notification/list-notification',
  async (paramsGetNotificationList, thunkAPI) => {
    const notificationType = paramsGetNotificationList[DEFAULT_KEY_NAME_NOTIFICATION_TYPE];

    const { childrenStateName, localStorageName, additionalParams, uniqueListKeyName } =
      getRelationalNotificationTypeWithNotificationActionListConfiguration(notificationType);

    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      {
        ...additionalParamsUnShowMessage,
        ...additionalParams,
        ...paramsGetNotificationList,
      },
      notificationServices.getNotificationList,
      localStorageName,
      childrenStateName,
      uniqueListKeyName,
    );
  },
);

const notificationActions = {
  getUnreadNotifications,
  getNotificationList,
};

export default notificationActions;
