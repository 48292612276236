/**
 * @param { function } t function for handling translation
 * @returns
 *      initial search purchase order data with translation
 */
export const initialSearchPurchaseOrder = () => {
  return {
    from_date: null,
    to_date: null,
    purchase_order_no: '',
    supplier_name: '',
    supplier_name_container: null,
    status: [],
    statusContainer: [],
    invoice_status: [],
    invoice_status_container: [],
  };
};
