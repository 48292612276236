import {
  FORMAT_MAPPING_TRANSLATION_COLUMN as FORMAT_MAPPING_TRANSLATION_COLUMN_TABLE_KEY_VALUE,
  FORMAT_PERIOD_DATE_COLUMN,
} from '../../../components/table/TableKeyValuePureMarkup/TableKeyValuePureMarkup';
import {
  FORMAT_COMMON_COLUMN,
  FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
  FORMAT_DATE_COLUMN,
  FORMAT_MAPPING_TRANSLATION_COLUMN,
} from '../../../components/table-row/TableRowPureMarkup/TableRowPureMarkup';
import {
  mappingPurchaseInvoiceStatus,
  mappingPurchaseOrderStatus,
} from '../label-value/purchase-status';

// column purchase order list that handling table head with translation
export const columnsTableHeaderPurchaseOrder = (t) => {
  return [
    {
      name: 'transaction_date',
      name_input: 'from_date',
      label: t('dashboard.purchase.purchase-order.table.header-column.transaction-date'),
      placeholder: t('dashboard.purchase.purchase-order.input.search-transaction-date.placeholder'),
      width: 200,
    },
    {
      name: 'purchase_order_no',
      name_input: 'purchase_order_no',
      label: t('dashboard.purchase.purchase-order.table.header-column.transaction-no'),
      placeholder: t('dashboard.purchase.purchase-order.input.search-transaction-no.placeholder'),
      width: 200,
    },
    {
      name: 'supplier_name',
      name_input: 'supplier_name',
      label: t('dashboard.purchase.purchase-order.table.header-column.supplier'),
      placeholder: t('dashboard.purchase.purchase-order.input.search-supplier.placeholder'),
      width: 300,
      align: 'left',
    },
    {
      name: 'amount',
      label: t('dashboard.purchase.purchase-order.table.header-column.nominal'),
      align: 'right',
      width: 150,
    },
    {
      name: 'status',
      name_input: 'status',
      label: t('dashboard.purchase.purchase-order.table.header-column.status'),
      placeholder: t('dashboard.purchase.purchase-order.input.search-status.placeholder'),
      align: 'left',
      width: 200,
      maxWidth: 205,
    },
    {
      name: 'invoice_status',
      name_input: 'invoice_status',
      label: t('table.header-column.invoice-status'),
      placeholder: t('dashboard.purchase.purchase-delivery.select.search-status.placeholder'),
      align: 'left',
      width: 250,
      maxWidth: 255,
    },
  ];
};

export const columnsTableHeaderPurchaseOrderPDFprint = (t) => {
  return [
    {
      name: 'transaction_date',
      label: t('dashboard.purchase.purchase-order.table.header-column.transaction-date'),
      style: {
        width: 200,
        position: 'unset',
      },
      formatData: FORMAT_DATE_COLUMN,
    },
    {
      name: 'transaction_no',
      label: t('dashboard.purchase.purchase-order.table.header-column.transaction-no'),
      style: {
        width: 200,
        position: 'unset',
      },
      formatData: FORMAT_COMMON_COLUMN,
    },
    {
      name: 'supplier_name',
      label: t('dashboard.purchase.purchase-order.table.header-column.supplier'),
      style: {
        width: 400,
        position: 'unset',
      },
      formatData: FORMAT_COMMON_COLUMN,
    },
    {
      name: 'amount',
      label: t('dashboard.purchase.purchase-order.table.header-column.nominal'),
      style: {
        width: 150,
        position: 'unset',
      },
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      isUseZeroNumberWhenNull: true,
      align: 'right',
    },
    {
      name: 'status',
      label: t('dashboard.purchase.purchase-order.table.header-column.status'),
      style: {
        width: 150,
        maxWidth: 155,
        position: 'unset',
      },
      formatData: FORMAT_MAPPING_TRANSLATION_COLUMN,
      mappingTranslation: mappingPurchaseOrderStatus,
    },
    {
      name: 'invoice_status',
      label: t('table.header-column.invoice-status'),
      style: {
        width: 150,
        maxWidth: 155,
        position: 'unset',
      },
      formatData: FORMAT_MAPPING_TRANSLATION_COLUMN,
      mappingTranslation: mappingPurchaseInvoiceStatus,
      substituteNotExistValue: t('label.purchase-invoice-status.open'),
    },
  ];
};

// columns key value table
export const columnsTableKeyValuePrintIndexPurchaseOrder = [
  {
    header: 'dashboard.purchase.purchase-order.table.header-column.transaction-date',
    valueKeyName: 'search_from_date',
    valueKeyNameSecondary: 'search_to_date',
    formatValue: FORMAT_PERIOD_DATE_COLUMN,
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'dashboard.purchase.purchase-order.table.header-column.transaction-no',
    valueKeyName: 'search_purchase_order_no',
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'dashboard.purchase.purchase-order.table.header-column.supplier',
    valueKeyName: 'search_supplier_name',
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'dashboard.purchase.purchase-order.table.header-column.status',
    valueKeyName: 'search_status',
    formatValue: FORMAT_MAPPING_TRANSLATION_COLUMN_TABLE_KEY_VALUE,
    mappingTranslation: mappingPurchaseOrderStatus,
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'table.header-column.invoice-status',
    valueKeyName: 'search_invoice_status',
    formatValue: FORMAT_MAPPING_TRANSLATION_COLUMN_TABLE_KEY_VALUE,
    mappingTranslation: mappingPurchaseInvoiceStatus,
    showOnlyWhenExistedValue: true,
  },
];

export const columnsSearchWithDate = ['from_date', 'to_date'];

// column that representation for calculation
export const calculationColumns = ['amount'];
