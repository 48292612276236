import {
  FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN,
  FORMAT_DATE_COLUMN,
} from '../../../components/table-row/TableRowMultipleTableDataPureMarkup/TableRowMultipleTableDataPureMarkup';
import { FORMAT_CURRENCY_AUTO_COMMA_COLUMN } from '../../../components/table-row/TableRowPureMarkup/TableRowPureMarkup';

import { primaryTableDataCalculationNumber } from './additional-styles';

/**
 *
 * @param { function } t translation funtion
 * @returns
 *      array row of all columns detail purchase invoice
 */
export const columnsTableHeaderDetailPurchaseInvoice = (t) => {
  return [
    {
      name: 'product_code',
      label: t('dialog.detail-purchase-invoice.table.header-column.product-code'),
      width: 150,
    },
    {
      name: 'product_name',
      label: t('dialog.detail-purchase-invoice.table.header-column.product-name'),
      width: 150,
    },
    {
      name: 'product_description',
      label: t('dialog.detail-purchase-invoice.table.header-column.description'),
      width: 250,
      align: 'left',
    },
    {
      name: 'quantity',
      label: t('dialog.detail-purchase-invoice.table.header-column.quantity'),
      align: 'right',
      width: 150,
    },
    {
      name: 'product_unit',
      label: t('dialog.detail-purchase-invoice.table.header-column.unit'),
      align: 'right',
      width: 60,
    },
    {
      name: 'price',
      label: t('dialog.detail-purchase-invoice.table.header-column.price'),
      align: 'right',
      width: 150,
    },
    {
      name: 'amount',
      label: t('dialog.detail-purchase-invoice.table.header-column.amount'),
      align: 'right',
      width: 150,
    },
  ];
};

// columns key value table
export const columnsTableKeyValuePrintPurchaseInvoice = [
  {
    headerTranslated: 'No.',
    valueKeyName: 'purchase_invoice_no',
  },
  {
    header: 'table.header-column.date',
    valueKeyName: 'transaction_date',
    formatValue: FORMAT_DATE_COLUMN,
  },
  {
    header: 'table.header-column.name',
    valueKeyName: 'supplier_name',
  },
  {
    header: 'table.header-column.address',
    valueKeyName: 'supplier_address',
  },
];

export const columnsTableProductPurchaseInvoiceDetails = (t) => {
  return [
    {
      isAutoIncrementColumn: true,
      label: t('No.'),
      align: 'center',
      style: {
        maxWidth: 20,
      },
    },
    {
      name: 'product_name',
      label: t('table.header-column.product-name'),
      style: {
        minWidth: 'auto',
        width: 593,
        maxWidth: 600,
      },
    },
    {
      name: 'quantity',
      label: t('table.header-column.qty'),
      style: {
        minWidth: 'auto',
        width: 20,
        maxWidth: 35,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'unit_destination',
      label: t('table.header-column.unit'),
      style: {
        minWidth: 'auto',
        width: 30,
        maxWidth: 35,
      },
    },
    {
      name: 'price',
      label: t('table.header-column.at-price'),
      style: {
        minWidth: 'auto',
        width: 192,
        maxWidth: 300,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'amount',
      label: t('table.header-column.amount'),
      style: {
        minWidth: 'auto',
        width: 192,
        maxWidth: 300,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
  ];
};

export const columnsTableFooterProductPurchaseInvoiceDetails = (t) => {
  return [
    [
      {
        isEmptyTableData: true,
        colSpan: 4,
      },
      {
        isLabel: true,
        label: t('typography.discount'),
      },
      {
        isValue: true,
        valueKeyName: 'discount',
        tableDataStyle: primaryTableDataCalculationNumber,
      },
    ],
    [
      {
        isEmptyTableData: true,
        colSpan: 4,
      },
      {
        isLabel: true,
        label: t('label.other-cost'),
      },
      {
        isValue: true,
        valueKeyName: 'expense',
        tableDataStyle: primaryTableDataCalculationNumber,
      },
    ],
    [
      {
        isEmptyTableData: true,
        colSpan: 4,
      },
      {
        isLabel: true,
        label: t('label.down-payment'),
      },
      {
        isValue: true,
        valueKeyName: 'down_payment',
        tableDataStyle: primaryTableDataCalculationNumber,
      },
    ],
    [
      {
        isEmptyTableData: true,
        colSpan: 4,
      },
      {
        isLabel: true,
        label: t('typography.total'),
      },
      {
        isValue: true,
        valueKeyName: 'amount',
        formatValue: FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN,
        tableDataStyle: primaryTableDataCalculationNumber,
      },
    ],
    [
      {
        isEmptyTableData: true,
        colSpan: 4,
      },
      {
        isLabel: true,
        label: t('typography.paid'),
      },
      {
        isValue: true,
        valueKeyName: 'paid',
        formatValue: FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN,
        tableDataStyle: primaryTableDataCalculationNumber,
      },
    ],
    // [
    //     {
    //         isEmptyTableData: true,
    //         colSpan: 4,
    //     },
    //     {
    //         isLabel: true,
    //         label: t('typography.return'),
    //     },
    //     {
    //         isValue: true,
    //         valueKeyName: 'amount_return',
    //         formatValue: FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN,
    //         tableDataStyle: primaryTableDataCalculationNumber,
    //     },
    // ]
  ];
};

export const columnsTableFooterPurchaseInvoiceDetails = (t) => {
  return [
    {
      name: 'description',
      label: t('table.header-column.memo-colon'),
      style: {
        minWidth: 100,
        width: 360,
        maxWidth: 365,
        height: 56,
        verticalAlign: 'top',
      },
      isUseTableHeaderStyle: false,
    },
  ];
};

// colums that representation for calculation
export const columnsCalculationPurchaseInvoice = ['price', 'amount'];
