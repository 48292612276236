import { isEmpty } from 'lodash';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import { DEFAULT_DISCOUNT_PERCENTAGE_TYPE } from '../../default/discount-type.default';
import { DEFAULT_COMPARISON_VALUE_NUMBER } from '../../default/number.default';
import {
  DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
  DEFAULT_KEY_NAME_DISCOUNT_TYPE_API_REF,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_MODUL_ID_API_REF,
  DEFAULT_KEY_NAME_MODUL_QUANTITY_AVAILABLE_API_REF,
  DEFAULT_KEY_NAME_MODUL_TYPE_API_REF,
  DEFAULT_KEY_NAME_SALES_DELIVERY_QUANTITY_AVAILABLE_API_REF,
  DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';
import objHelper from '../../helpers/object.helper';
import { DEFAULT_CALCULATION_NUMBER } from '../default/number.default';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import { generalizeAttachmentFromDetails } from './initial-add-attachment';
import {
  generalizeDataAddSalesInvoice,
  generateDataNValidationAddProductSI,
} from './initial-add-sales-invoice';
import {
  initialDataProduct,
  initialDataSalesInvoice,
  initialValidationProduct,
  initialValidationSalesInvoice,
  modulTypeSalesInvoice,
  productSalesInvoiceCalculationNumberKeyNames,
  relationalModulTypeWithModulIDcorrespondInputValue,
  salesInvoiceCalculationNumberKeyNames,
} from './initial-data-sales-invoice';

// data and validation sales invoice generate from data selected
export const generateDataNValidationUpdateSalesInvoice = (
  salesInvoiceDataSelected,
  customID = null,
) => {
  let idHasUsed = customID || [];

  let dataUpdateSalesInvoice = objHelper.filterKeyObj(
    salesInvoiceDataSelected,
    [],
    [
      'customer_id',
      'customer_name',
      'customer_address',
      'contact_code',
      'transaction_date',
      'expired_date',
      'sales_invoice_no',
      'description',
      'sales_invoice_id',
      'status',
      'sales_delivery_id',
      'sales_delivery_no',
      'sales_order_id',
      'sales_order_no',
      'modul_type',
      'discount_account_id',
      'discount_account_name',
      'discount_account_code',
      'expense_account_id',
      'expense_account_name',
      'expense_account_code',
      'down_payment_account_id',
      'down_payment_account_name',
      'down_payment_account_code',
    ],
  );

  idHasUsed = idHasUsed.concat(uuidV4());

  dataUpdateSalesInvoice = idHasUsed.map((id) => {
    return {
      id,
      ...dataUpdateSalesInvoice,
      customer_id_container: {
        contact_id: dataUpdateSalesInvoice.customer_id || null,
        contact_code: dataUpdateSalesInvoice.contact_code || '',
        contact_name: dataUpdateSalesInvoice.customer_name || '',
      },
      customer_address: dataUpdateSalesInvoice.customer_address,
      reference_number_container: {
        code: dataUpdateSalesInvoice.sales_invoice_no,
      },
      description: dataUpdateSalesInvoice.description,
      transaction_date: dataUpdateSalesInvoice.transaction_date
        ? moment(dataUpdateSalesInvoice.transaction_date).toISOString(true)
        : null,
      expired_date: dataUpdateSalesInvoice.expired_date
        ? moment(dataUpdateSalesInvoice.expired_date).toISOString(true)
        : null,
      modul_id: dataUpdateSalesInvoice.sales_delivery_id || dataUpdateSalesInvoice.sales_order_id,
      modul_id_container: {
        modul_id: dataUpdateSalesInvoice.sales_delivery_id || dataUpdateSalesInvoice.sales_order_id,
        transaction_no:
          dataUpdateSalesInvoice.sales_delivery_no || dataUpdateSalesInvoice.sales_order_no,
      },
      discount_account_id_container: {
        contact_id: dataUpdateSalesInvoice.discount_account_id || '',
        contact_name: dataUpdateSalesInvoice.discount_account_name || '',
        contact_code: dataUpdateSalesInvoice.discount_account_code || '',
      },
      expense_account_id_container: {
        contact_id: dataUpdateSalesInvoice.expense_account_id || '',
        contact_name: dataUpdateSalesInvoice.expense_account_name || '',
        contact_code: dataUpdateSalesInvoice.expense_account_code || '',
      },
      down_payment_account_id_container: {
        contact_id: dataUpdateSalesInvoice.down_payment_account_id || '',
        contact_name: dataUpdateSalesInvoice.down_payment_account_name || '',
        contact_code: dataUpdateSalesInvoice.down_payment_account_code || '',
      },
    };
  });

  const validationUpdateSalesInvoice = idHasUsed.map((id) => {
    return initialValidationSalesInvoice('', id);
  });

  return {
    updateSalesInvoiceData: dataUpdateSalesInvoice,
    updateSalesInvoiceValidation: validationUpdateSalesInvoice,
  };
};

// data and validation sales invoice generate from data selected
export const generateDataNValidationUpdateSalesInvoiceV2 = ({
  salesInvoiceDataSelected,
  companyUserData = {},
  customID = null,
  customDataKey = 'updateSalesInvoiceData',
  customValidationKey = 'updateSalesInvoiceValidation',
}) => {
  let idHasUsed = customID || [];

  salesInvoiceDataSelected = objHelper.changeFormatValue(
    salesInvoiceDataSelected,
    salesInvoiceCalculationNumberKeyNames,
    [(value) => Number(value) || ''],
  );

  let dataUpdateSalesInvoice = objHelper.filterKeyObj(
    salesInvoiceDataSelected,
    [],
    [
      'customer_id',
      'contact_code',
      'customer_name',
      'customer_address',
      'transaction_date',
      'expired_date',
      'sales_invoice_no',
      'description',
      'sales_invoice_id',
      'status',
      'sales_delivery_id',
      'sales_delivery_no',
      'sales_order_id',
      'sales_order_no',
      'modul_type',
      'warehouse_id',
      'warehouse_name',
      'discount_account_id',
      'discount_account_name',
      'discount_account_code',
      'expense_account_id',
      'expense_account_name',
      'expense_account_code',
      'down_payment_account_id',
      'down_payment_account_name',
      'down_payment_account_code',
    ],
  );

  const modulTypeSalesInvoiceDetails =
    dataUpdateSalesInvoice[DEFAULT_KEY_NAME_MODUL_TYPE_API_REF] || modulTypeSalesInvoice.manual;

  const modulContainer = objHelper.changeKeyNameWithCorrespondKeyTarget({
    obj: salesInvoiceDataSelected,
    correspondObj: relationalModulTypeWithModulIDcorrespondInputValue[modulTypeSalesInvoiceDetails],
  });

  let modul_id = !isEmpty(modulContainer) ? modulContainer[DEFAULT_KEY_NAME_MODUL_ID_API_REF] : '',
    transaction_no = !isEmpty(modulContainer)
      ? modulContainer[DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF]
      : '',
    discount_type = !isEmpty(salesInvoiceDataSelected)
      ? salesInvoiceDataSelected[DEFAULT_KEY_NAME_DISCOUNT_TYPE_API_REF] ||
        DEFAULT_DISCOUNT_PERCENTAGE_TYPE
      : DEFAULT_DISCOUNT_PERCENTAGE_TYPE;

  idHasUsed = idHasUsed.concat(uuidV4());

  dataUpdateSalesInvoice = idHasUsed.map((id) => {
    return {
      id,
      ...initialDataSalesInvoice('', id),
      ...dataUpdateSalesInvoice,
      ...salesInvoiceDataSelected,
      attachment: generalizeAttachmentFromDetails({
        dataDetails: salesInvoiceDataSelected,
      }),
      warehouse_id_container: {
        warehouse_id: dataUpdateSalesInvoice.warehouse_id || '',
        warehouse_name: dataUpdateSalesInvoice.warehouse_name || '',
      },
      amount_const: Number(salesInvoiceDataSelected.amount) || DEFAULT_CALCULATION_NUMBER,
      sales_agent_id_container: {
        contact_id: salesInvoiceDataSelected.sales_agent_id || '',
        contact_name: salesInvoiceDataSelected.sales_agent_name || '',
        contact_code: salesInvoiceDataSelected.sales_agent_code || '',
      },
      discount_account_id_container: {
        account_id: salesInvoiceDataSelected.discount_account_id || '',
        account_name: salesInvoiceDataSelected.discount_account_name || '',
        account_code: salesInvoiceDataSelected.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: salesInvoiceDataSelected.expense_account_id || '',
        account_name: salesInvoiceDataSelected.expense_account_name || '',
        account_code: salesInvoiceDataSelected.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: salesInvoiceDataSelected.down_payment_account_id || '',
        account_name: salesInvoiceDataSelected.down_payment_account_name || '',
        account_code: salesInvoiceDataSelected.down_payment_account_code || '',
      },
      customer_id_container: {
        contact_id: dataUpdateSalesInvoice.customer_id || null,
        contact_code: dataUpdateSalesInvoice.contact_code || '',
        contact_name: dataUpdateSalesInvoice.customer_name || '',
      },
      customer_address: dataUpdateSalesInvoice.customer_address,
      reference_number: dataUpdateSalesInvoice.sales_invoice_id,
      reference_number_container: {
        value: dataUpdateSalesInvoice.sales_invoice_no,
      },
      description: dataUpdateSalesInvoice.description,
      transaction_date: dataUpdateSalesInvoice.transaction_date
        ? moment(dataUpdateSalesInvoice.transaction_date).toISOString(true)
        : null,
      expired_date: dataUpdateSalesInvoice.expired_date
        ? moment(dataUpdateSalesInvoice.expired_date).toISOString(true)
        : null,
      modul_id,
      modul_id_container: {
        modul_id,
        transaction_no,
      },
      amount: Number(salesInvoiceDataSelected.amount) || 0,
      discount_type,
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
    };
  });

  const validationUpdateSalesInvoice = idHasUsed.map((id) => {
    return initialValidationSalesInvoice('', id);
  });

  return {
    [customDataKey]: dataUpdateSalesInvoice,
    [customValidationKey]: validationUpdateSalesInvoice,
  };
};

// generate data and validation for product in sales invoice
export const generateDataNValidationUpdateProductSalesInvoice = (
  salesInvoiceDataSelected,
  customID = null,
) => {
  let idHasUsed = customID || [];

  const productUpdateData = objHelper.filterKeyObj(salesInvoiceDataSelected, [], ['product']);

  let allProductUpdateData = productUpdateData.product;

  allProductUpdateData = allProductUpdateData.map((dataProduct, index) => {
    const { product_unit, unit_destination, unit_origin, quantity, quantity_origin } = dataProduct;

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    const perQuantityComparison = Number(1 / quantityComparison);

    const salesDeliveryQuantityAvailable = Number(
      (dataProduct[DEFAULT_KEY_NAME_SALES_DELIVERY_QUANTITY_AVAILABLE_API_REF] || 0) *
        perQuantityComparison,
    );

    idHasUsed = idHasUsed.concat(uuidV4());

    const quantityAvailable = Number(salesDeliveryQuantityAvailable) + Number(dataProduct.quantity);

    return {
      ...dataProduct,
      id: idHasUsed[index],
      product_id_container: {
        product_id: dataProduct.product_id,
        product_name: dataProduct.product_name,
        ...dataProduct,
      },
      product_description: dataProduct.description,
      quantity: dataProduct.quantity,
      quantity_available: salesDeliveryQuantityAvailable,
      quantity_available_const: quantityAvailable,
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: quantityAvailable,
        value_comparison: quantityComparison,
      },
      price: dataProduct.price,
      price_amount: dataProduct.price * dataProduct.quantity,
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      quantity_available_with_unit: `${Number(quantityAvailable)} ${unit_origin || product_unit}`,
    };
  });

  const validationUpdateProduct = allProductUpdateData.map((dataProduct, index) => {
    return initialValidationProduct('', idHasUsed[index]);
  });

  return {
    updateProductData: allProductUpdateData,
    updateProductValidation: validationUpdateProduct,
  };
};

// generate data and validation for product in sales invoice
export const generateDataNValidationUpdateProductSalesInvoiceV2 = ({
  salesInvoiceDataSelected,
  customID = null,
  customDataKey = 'updateProductData',
  customValidationKey = 'updateProductValidation',
  useAddNewRow = true,
  amountNewRow = 1,
}) => {
  let idHasUsed = customID || [];

  const productUpdateData = objHelper.filterKeyObj(salesInvoiceDataSelected, [], ['product']);

  let allProductUpdateData = productUpdateData.product;

  allProductUpdateData = allProductUpdateData.map((dataProduct, index) => {
    if (!isEmpty(dataProduct)) {
      dataProduct = objHelper.changeFormatValue(
        dataProduct,
        productSalesInvoiceCalculationNumberKeyNames,
        [(value) => Number(value) || ''],
      );
    }

    idHasUsed = idHasUsed.concat(uuidV4());

    let amount = Number(dataProduct.quantity) * Number(dataProduct.price);
    if (Number.isNaN(amount)) amount = 0;

    const { product_unit, unit_destination, unit_origin, quantity, quantity_origin } = dataProduct;

    const modulQuantityAvailable =
      Number(dataProduct[DEFAULT_KEY_NAME_MODUL_QUANTITY_AVAILABLE_API_REF]) || 0;

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(Number(quantity_origin) / Number(quantity))
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    const perQuantityComparison = Number(1 / quantityComparison);

    const modulQuantityAvailablePerQuantityComparison =
      Number(modulQuantityAvailable) * perQuantityComparison;

    const quantityAvailable = modulQuantityAvailablePerQuantityComparison + Number(quantity_origin);

    return {
      ...initialDataProduct('', idHasUsed[index]),
      ...dataProduct,
      id: idHasUsed[index],
      product_id_container: {
        product_id: dataProduct.product_id,
        product_name: dataProduct.product_name,
        ...dataProduct,
        quantity_available_const: Number(quantityAvailable),
      },
      product_description: dataProduct.description,
      quantity: dataProduct.quantity,
      quantity_available: dataProduct.sales_delivery_quantity_available,
      quantity_available_const: quantityAvailable,
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: quantityAvailable,
        value_comparison: Number(quantity_origin) / Number(quantity),
      },
      price: Number(dataProduct.price),
      price_amount: dataProduct.price * dataProduct.quantity,
      amount,
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      quantity_available_with_unit: `${Number(quantityAvailable)} ${unit_origin || product_unit}`,
    };
  });

  let validationUpdateProduct = allProductUpdateData.map((dataProduct, index) => {
    return initialValidationProduct('', idHasUsed[index]);
  });

  if (useAddNewRow && amountNewRow) {
    const { addProductData, addProductValidation } =
      generateDataNValidationAddProductSI(amountNewRow);

    allProductUpdateData = allProductUpdateData.concat(addProductData);
    validationUpdateProduct = validationUpdateProduct.concat(addProductValidation);
  }

  return {
    [customDataKey]: allProductUpdateData,
    [customValidationKey]: validationUpdateProduct,
  };
};

// generalize data for update sales invoice
export const generalizeDataUpdateSalesInvoice = (salesInvoiceData, productData) => {
  const generalizedDataSalesInvoice = generalizeDataAddSalesInvoice(salesInvoiceData, productData);

  const { sales_invoice_id } =
    Array.isArray(salesInvoiceData) && salesInvoiceData.length
      ? salesInvoiceData[0]
      : salesInvoiceData;

  return {
    ...generalizedDataSalesInvoice,
    sales_invoice_id,
  };
};
