import { createAsyncThunk } from '@reduxjs/toolkit';

import subAccountServices from '../../services/API/account/sub-account.service';
import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_SUB_ACCOUNT } from '../../utils/constants/storage.constant';
import messageHelper from '../message/message.helper';

// listing sub account
export const getSubAccountList = createAsyncThunk(
  'sub-account/list',
  async ({ page, paginate }, thunkAPI) => {
    try {
      const response = await subAccountServices.getSubAccountList({
        page,
        paginate,
      });

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      // new data response
      let { data: subAccountData, sucess } = response.data;

      if (!sucess || !Array.isArray(subAccountData.data)) {
        subAccountData = {
          ...subAccountData,
          data: [],
        };
      }

      // set into local storage
      LocalStorage.set(LOCAL_STORAGE_SUB_ACCOUNT, subAccountData);

      return { subAccountData };
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.sub-account',
          'error.list-data.sub-account',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// adding sub account
export const addSubAccount = createAsyncThunk(
  'sub-account/add',
  async (dataAddSubAccount, thunkAPI) => {
    const getAccountNameFromDataAdd = dataAddSubAccount.map((data) => data.account_name).join(', ');

    try {
      const response = await subAccountServices.addSubAccount(dataAddSubAccount);

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.add.account-data',
          'success.add.account-data',
          {},
          { account_name: getAccountNameFromDataAdd },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.add.account-data',
          {},
          { account_name: getAccountNameFromDataAdd },
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.add.account-data',
          'error.add.account-data',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.add.account-data',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);
