import * as React from 'react';
function SvgSalesReturnMenu(props) {
  return (
    <svg width={110} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#sales-return-menu_svg__filter0_d_5586_192513)'>
        <path
          d='M61.529 1.012c19.21 0 34.811 14.966 35.078 33.511l3.949-3.003a1.293 1.293 0 011.762.2 1.2 1.2 0 01-.207 1.708l-5.93 4.51c-.242.188-.534.27-.825.26a1.253 1.253 0 01-.826-.26l-5.93-4.51a1.182 1.182 0 01-.206-1.707 1.27 1.27 0 011.761-.2l3.548 2.696c-.425-16.85-14.666-30.39-32.162-30.39-16.354 0-29.854 11.833-31.895 27.14a3.46 3.46 0 00-1.81-.47 3.83 3.83 0 00-1.094.152C28.942 13.93 43.68 1 61.541 1l-.012.012zM21.068 36.525l5.93-4.51c.242-.189.534-.271.825-.26.292-.011.584.071.826.26l5.93 4.51c.547.412.644 1.19.206 1.707a1.27 1.27 0 01-1.761.2l-3.658-2.779c.353 16.92 14.617 30.533 32.163 30.533 16.379 0 29.89-11.858 31.907-27.2a3.471 3.471 0 001.908.53c.328 0 .656-.036.972-.13C94.092 56.095 79.366 69 61.529 69c-19.198 0-34.8-14.942-35.079-33.488l-3.84 2.92a1.293 1.293 0 01-1.76-.2 1.2 1.2 0 01.206-1.707h.012z'
          fill='#039855'
        />
        <path d='M81.022 23.644H41.874V49.15h39.148V23.644z' fill='#FFD15D' />
        <path d='M81.022 23.644H61.37V49.15h19.652V23.644z' fill='#F9B54C' />
        <path d='M82.98 20H39.915v7.287H82.98V20z' fill='#F9B54C' />
        <path d='M82.98 20H61.37v7.287h21.61V20z' fill='#F4A200' />
        <path
          d='M67.32 33.912H55.576c-1.08 0-1.957-.815-1.957-1.822 0-1.006.876-1.821 1.957-1.821H67.32c1.081 0 1.958.815 1.958 1.822 0 1.006-.877 1.821-1.958 1.821z'
          fill='#B97850'
        />
        <path
          d='M67.32 30.269h-5.95v3.643h5.95c1.081 0 1.958-.815 1.958-1.822 0-1.006-.877-1.821-1.958-1.821z'
          fill='#935635'
        />
        <path
          d='M53.52 36.859h-7.487c-.77 0-1.376.563-1.376 1.28v6.97c0 .716.606 1.28 1.377 1.28h7.487c.77 0 1.376-.564 1.376-1.28v-6.97c0-.666-.606-1.28-1.376-1.28zm.606 8.25c0 .307-.275.563-.605.563h-7.488c-.33 0-.605-.256-.605-.564V38.14c0-.308.275-.564.605-.564h7.488c.33 0 .605.256.605.564v6.968z'
          fill='#fff'
        />
        <path
          d='M48.236 39.472c-.055-.051-.055-.051-.11-.051-.056 0-.11-.052-.166-.052-.055 0-.11 0-.165.052-.055 0-.11.05-.11.05l-1.266 1.18c-.165.153-.165.358 0 .46.165.154.385.154.495 0l.66-.614v3.023c0 .205.166.358.386.358s.386-.153.386-.358v-3.023l.66.614a.438.438 0 00.276.103c.11 0 .165-.051.275-.102.165-.154.165-.36 0-.462l-1.322-1.178zM51.869 39.472a.437.437 0 00-.276-.103c-.11 0-.22.052-.275.103l-1.266 1.178c-.165.154-.165.359 0 .462.165.153.386.153.496 0l.66-.615v3.023c0 .205.165.358.386.358.22 0 .385-.153.385-.358v-3.023l.66.614a.438.438 0 00.276.103c.11 0 .165-.051.275-.102.165-.154.165-.36 0-.462l-1.321-1.178zM65.026 36.859H57.54c-.77 0-1.376.563-1.376 1.28v6.97c0 .716.606 1.28 1.376 1.28h7.487c.771 0 1.377-.564 1.377-1.28v-6.97c0-.666-.606-1.28-1.377-1.28zm.661 8.25c0 .307-.275.563-.605.563h-7.543c-.33 0-.605-.256-.605-.564V38.14c0-.308.275-.564.605-.564h7.487c.33 0 .606.256.606.564v6.968h.055z'
          fill='#fff'
        />
        <path
          d='M62.935 38.806h-3.248c-.22 0-.386.153-.386.358v1.64c0 .922.716 1.69 1.652 1.845v1.127h-.496c-.22 0-.385.154-.385.359s.165.358.385.358h1.762c.22 0 .385-.154.385-.358 0-.205-.165-.36-.385-.36h-.495V42.65c.935-.154 1.651-.923 1.651-1.845v-1.64c-.055-.205-.22-.358-.44-.358zm-.386 1.998c0 .666-.55 1.178-1.266 1.178-.715 0-1.266-.512-1.266-1.178v-1.332h2.532v1.332zM76.532 36.859h-7.487c-.77 0-1.376.563-1.376 1.28v6.97c0 .716.606 1.28 1.376 1.28h7.487c.771 0 1.377-.564 1.377-1.28v-6.97c0-.666-.661-1.28-1.377-1.28zm.606 8.25c0 .307-.275.563-.606.563h-7.487c-.33 0-.605-.256-.605-.564V38.14c0-.308.275-.564.605-.564h7.487c.33 0 .606.256.606.564v6.968z'
          fill='#fff'
        />
        <path
          d='M72.789 38.344c-1.817 0-3.303 1.384-3.303 3.075 0 .205.165.359.385.359h2.588v2.305c0 .103-.11.205-.22.205s-.22-.102-.22-.205c0-.205-.166-.358-.386-.358s-.385.153-.385.358c0 .513.44.923.99.923.551 0 .991-.41.991-.923v-2.305h2.588c.22 0 .385-.154.385-.36-.11-1.69-1.596-3.074-3.413-3.074zm-2.532 2.716c.165-1.178 1.266-2.05 2.532-2.05s2.367.872 2.532 2.05h-5.064z'
          fill='#fff'
        />
        <path
          d='M22.8 60.423h2.4v7.71c0 .479-.538.867-1.2.867-.663 0-1.2-.388-1.2-.867v-7.71z'
          fill='#FFC61B'
        />
        <path
          d='M23.92 60.423v8.571c.026.001.052.006.08.006.662 0 1.2-.388 1.2-.867v-7.71h-1.28zM25.123 56.188l2.286-.529 5.422 12.21c.202.456-.145.943-.777 1.09-.63.145-1.307-.105-1.509-.561l-5.422-12.21z'
          fill='#EAA22F'
        />
        <path
          d='M22.876 56.188l-2.286-.529-5.422 12.21c-.202.456.145.943.777 1.09.63.145 1.306-.105 1.509-.561l5.422-12.21z'
          fill='#FFC61B'
        />
        <path d='M43.803 38.028H4.197V59.86h39.606V38.028z' fill='#fff' />
        <path d='M43.803 38.028H23.92V59.86h19.883V38.028z' fill='#D0D1D3' />
        <path
          d='M25.98 36.859h-3.96v-1.43c0-.789.886-1.429 1.98-1.429 1.093 0 1.98.64 1.98 1.43v1.429z'
          fill='#FFC61B'
        />
        <path
          d='M25.98 35.43c0-.79-.887-1.43-1.98-1.43-.027 0-.053.002-.08.003v2.856h2.06v-1.43z'
          fill='#EAA22F'
        />
        <path
          d='M44.243 39.184H3.757c-1.016 0-1.84-.595-1.84-1.329 0-.733.823-1.328 1.84-1.328h40.486c1.016 0 1.84.594 1.84 1.328 0 .734-.824 1.329-1.84 1.329zM44.243 61.073H3.757c-1.016 0-1.84-.595-1.84-1.328 0-.734.823-1.329 1.84-1.329h40.486c1.016 0 1.84.595 1.84 1.329 0 .733-.824 1.328-1.84 1.328z'
          fill='#71E2EF'
        />
        <path
          d='M44.243 36.527H23.92v2.657h20.323c1.016 0 1.84-.595 1.84-1.329s-.824-1.328-1.84-1.328zM44.243 58.416H23.92v2.657h20.323c1.016 0 1.84-.595 1.84-1.328 0-.734-.824-1.329-1.84-1.329z'
          fill='#38C6D9'
        />
        <path
          d='M37.09 45.44l-10.17 5.673-9.947-2.394-8.306 4.585.73.688 7.8-4.306 9.951 2.395 10.676-5.955-.734-.686z'
          fill='#59595B'
        />
        <path
          d='M17.197 49.686l-7.8 4.306-.73-.688 8.307-4.585 6.946 1.672v.913l-6.723-1.618z'
          fill='#59595B'
        />
        <path
          d='M27.148 52.08l-3.228-.776v-.913l3 .722 10.17-5.673.734.686-10.676 5.955z'
          fill='#272525'
        />
        <path
          d='M27.034 50.4c.916 0 1.658.536 1.658 1.197s-.742 1.197-1.658 1.197c-.916 0-1.659-.536-1.659-1.197s.743-1.197 1.659-1.197z'
          fill='#C92F00'
        />
        <path
          d='M17.084 48.005c.916 0 1.659.536 1.659 1.197 0 .662-.743 1.197-1.659 1.197-.915 0-1.658-.535-1.658-1.197 0-.66.743-1.197 1.658-1.197z'
          fill='#FF7F4F'
        />
        <path
          d='M37.456 44.585c.916 0 1.658.536 1.658 1.197s-.742 1.197-1.658 1.197c-.916 0-1.658-.536-1.658-1.197 0-.66.742-1.197 1.658-1.197z'
          fill='#EAA22F'
        />
        <path
          d='M9.031 52.452c.916 0 1.659.535 1.659 1.196 0 .661-.743 1.197-1.659 1.197s-1.658-.535-1.658-1.197c0-.66.742-1.197 1.658-1.197z'
          fill='#FF314F'
        />
      </g>
      <defs>
        <filter
          id='sales-return-menu_svg__filter0_d_5586_192513'
          x={0.917}
          y={0}
          width={108.667}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_192513' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_192513' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgSalesReturnMenu;
