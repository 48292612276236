export const reportXLSXprintStyles = [
  {
    font: {
      bold: true,
      sz: 12,
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
    },
  },
  {
    font: {
      bold: true,
      sz: 14,
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
    },
  },
  {
    font: {
      sz: 12,
      color: {
        rgb: '757575',
      },
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
    },
  },
  {
    font: {
      sz: 12,
      color: {
        rgb: '757575',
      },
    },
    alignment: {
      horizontal: 'right',
      vertical: 'center',
    },
  },
];

export const reportXLSXprintRowsInfo = [
  {
    hpt: 15,
  },
  {
    hpt: 18,
  },
  {
    hpt: 15,
  },
  {
    hpt: 15,
  },
];

export const defaultReportXLSXprintConfigurationProps = {
  worksheetName: '',
  fileNameTemplate: '{worksheetName}-{company_name}-{currentDate}.xlsx',
  additionalMerges: [],
  colIndexDateFormats: [],
};
