import { createSlice } from '@reduxjs/toolkit';

import { TYPE_MESSAGES } from '../../utils/constants/message.constant';
import { NAME_STORE } from '../../utils/constants/store.constant';

const initialState = {
  primaryText: '',
  secondaryText: '',
  paramsTextPrimary: {},
  paramsTextSecondary: {},
  code: '',
  variant: '',
  isShowingImage: false,
  isShow: false,
  typeMessage: TYPE_MESSAGES.DIALOG,
  isShowPreviewButton: false,
  linkNavigatePreviewButton: '',
  textButtonPreview: 'button.view-detail',
  // contains dispatch event -> on action event, params actions event and status request loading event
  // {
  //      actionName: '', name of action that for calling back
  //      rootState: '', => parent state that handling on action
  //      statusRequestLoading: '',   status request when action is still loading
  //      paramsAction: {}, you can passing empty object or additional params for action
  // }
  dispatchAttribute: {},
};

const messageSlice = createSlice({
  name: NAME_STORE.MESSAGE,
  initialState,
  reducers: {
    setMessage: (state, action) => {
      return {
        ...state,
        ...action.payload,
        isShow: true,
      };
    },
    clearMessage: () => {
      return {
        ...initialState,
      };
    },
  },
});

const { reducer, actions } = messageSlice;

export const { setMessage, clearMessage } = actions;

export default reducer;
