import { isEmpty } from 'lodash';

import {
  REACT_APP_API_URL_HANDOVER_PURCHASE,
  REACT_APP_API_URL_HANDOVER_PURCHASE_DETAILS,
  REACT_APP_API_URL_HANDOVER_PURCHASE_HISTORY,
  REACT_APP_API_URL_HANDOVER_PURCHASE_UPDATE,
  REACT_APP_API_URL_HANDOVER_SALES,
  REACT_APP_API_URL_HANDOVER_SALES_DETAILS,
  REACT_APP_API_URL_HANDOVER_SALES_HISTORY,
  REACT_APP_API_URL_HANDOVER_SALES_UPDATE,
} from '../configs/api.config';
import {
  REACT_APP_PURCHASE_PURCHASE_DELIVERY_HANDOVER_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_URL,
  REACT_APP_SALES_SALES_DELIVERY_HANDOVER_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_URL,
} from '../configs/url.config';
import {
  LOCAL_STORAGE_MINUTES_OF_HANDOVER_PURCHASE_DELIVERY,
  LOCAL_STORAGE_MINUTES_OF_HANDOVER_PURCHASE_DELIVERY_HISTORY,
  LOCAL_STORAGE_MINUTES_OF_HANDOVER_SALES_DELIVERY,
  LOCAL_STORAGE_MINUTES_OF_HANDOVER_SALES_DELIVERY_HISTORY,
} from '../constants/storage.constant';
import {
  REQUEST_STATUS_HISTORY_HANDOVER_PURCHASE_DELIVERY_FAILED,
  REQUEST_STATUS_HISTORY_HANDOVER_PURCHASE_DELIVERY_PENDING,
  REQUEST_STATUS_HISTORY_HANDOVER_PURCHASE_DELIVERY_SUCCESS,
  REQUEST_STATUS_HISTORY_HANDOVER_SALES_DELIVERY_FAILED,
  REQUEST_STATUS_HISTORY_HANDOVER_SALES_DELIVERY_PENDING,
  REQUEST_STATUS_HISTORY_HANDOVER_SALES_DELIVERY_SUCCESS,
  SLICE_NAME_HANDOVER_PURCHASE_DELIVERY_DETAILS,
  SLICE_NAME_HANDOVER_PURCHASE_DELIVERY_HISTORY,
  SLICE_NAME_HANDOVER_SALES_DELIVERY_DETAILS,
  SLICE_NAME_HANDOVER_SALES_DELIVERY_HISTORY,
  STATUS_REQUEST_ADD_HANDOVER_PURCHASE_DELIVERY_FAILED,
  STATUS_REQUEST_ADD_HANDOVER_PURCHASE_DELIVERY_PENDING,
  STATUS_REQUEST_ADD_HANDOVER_PURCHASE_DELIVERY_SUCCESS,
  STATUS_REQUEST_ADD_HANDOVER_SALES_DELIVERY_FAILED,
  STATUS_REQUEST_ADD_HANDOVER_SALES_DELIVERY_PENDING,
  STATUS_REQUEST_ADD_HANDOVER_SALES_DELIVERY_SUCCESS,
  STATUS_REQUEST_DETAILS_HANDOVER_PURCHASE_DELIVERY_FAILED,
  STATUS_REQUEST_DETAILS_HANDOVER_PURCHASE_DELIVERY_PENDING,
  STATUS_REQUEST_DETAILS_HANDOVER_PURCHASE_DELIVERY_SUCCESS,
  STATUS_REQUEST_DETAILS_HANDOVER_SALES_DELIVERY_FAILED,
  STATUS_REQUEST_DETAILS_HANDOVER_SALES_DELIVERY_PENDING,
  STATUS_REQUEST_DETAILS_HANDOVER_SALES_DELIVERY_SUCCESS,
  STATUS_REQUEST_UPDATE_HANDOVER_PURCHASE_DELIVERY_FAILED,
  STATUS_REQUEST_UPDATE_HANDOVER_PURCHASE_DELIVERY_PENDING,
  STATUS_REQUEST_UPDATE_HANDOVER_PURCHASE_DELIVERY_SUCCESS,
  STATUS_REQUEST_UPDATE_HANDOVER_SALES_DELIVERY_FAILED,
  STATUS_REQUEST_UPDATE_HANDOVER_SALES_DELIVERY_PENDING,
  STATUS_REQUEST_UPDATE_HANDOVER_SALES_DELIVERY_SUCCESS,
} from '../constants/store.constant';
import objHelper from '../helpers/object.helper';
import strHelp from '../helpers/string.helpers';

import {
  DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
  DEFAULT_KEY_NAME_FILE_NAME_API_REF,
  DEFAULT_KEY_NAME_UUID_API_REF,
} from './object-keyname.default';
import {
  DEFAULT_STATUS_PAGE_DETAIL_HANDOVER,
  DEFAULT_STATUS_PAGE_REGULAR_HANDOVER,
} from './status-page.default';
import {
  DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_MINUTES_OF_HANDOVER,
  DEFAULT_TRANSACTION_NUMBER_CODE_SALES_MINUTES_OF_HANDOVER,
  DEFAULT_TRANSACTION_NUMBER_PURCHASE_MINUTES_OF_HANDOVER,
  DEFAULT_TRANSACTION_NUMBER_SALES_MINUTES_OF_HANDOVER,
} from './transaction-number.default';
import {
  DEFAULT_TRANSACTION_PURCHASE_HANDOVER_API_REF_TYPE,
  DEFAULT_TRANSACTION_SALES_HANDOVER_API_REF_TYPE,
} from './transaction-type';

export const MINUTES_OF_HANDOVER_TYPE_KEY_NAME = 'minutesHandoverType';

export const MINUTES_OF_HANDOVER_TYPE_PURCHASE_DELIVERY = 'minutes-of-handover-purchase-delivery';
export const MINUTES_OF_HANDOVER_TYPE_SALES_DELIVERY = 'minutest-of-handover-sales-delivery';

export const minutesHandoverTypes = [
  MINUTES_OF_HANDOVER_TYPE_PURCHASE_DELIVERY,
  MINUTES_OF_HANDOVER_TYPE_SALES_DELIVERY,
];

export const minutesOfHandoverUtils = {
  [MINUTES_OF_HANDOVER_TYPE_PURCHASE_DELIVERY]: {
    regular: {
      uniqueIDKey: 'purchase_handover_id',
      transactionIDKey: 'purchase_delivery_id',
      transactionNumberKey: 'purchase_delivery_no',
      senderIDKeyTransaction: 'supplier_id',
      senderNameKeyNameTransaction: 'supplier_name',
      senderIDKeyMinutesHandover: 'sender_id',
      senderNameKeyNameMinutesHandover: 'sender_name',
      receiverIDKeyTransaction: 'user_id',
      receiverNameKeyNameTransaction: 'user_fullname',
      receiverIDKeyMinutesHandover: 'receiver_id',
      receiverNameKeyNameMinutesHandover: 'receiver_name',
      productKeyNameTransaction: 'product',
      accountingPeriodKeyNameUserCompany: 'accounting_periode',
      warehouseIDKeyNameTransaction: 'warehouse_id',
      productIDKeyNameTransaction: 'product_id',
      productNameKeyNameTransaction: 'product_name',
      productQtyKeyNameTransaction: 'quantity',
      productQtyAvailableKeyNameTransaction: 'quantity_available',
      productDescriptionKeyNameTransaction: 'description',
      productUnitKeyNameTransaction: 'product_unit',
      productQtyKeyNameMinutesHandover: 'quantity',
      productDescriptionKeyNameMinutesHandover: 'description',
      transactionNumber: DEFAULT_TRANSACTION_NUMBER_PURCHASE_MINUTES_OF_HANDOVER,
      transactionNumberCode: DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_MINUTES_OF_HANDOVER,
      generalizeAttachmentFile: (attachmentFile, relationedKeynameToAnotherAttachment) => {
        if (isEmpty(attachmentFile)) return '';

        let requestAttachment = {
          [DEFAULT_KEY_NAME_FILE_NAME_API_REF]: attachmentFile ? attachmentFile.name : '',
          feature: DEFAULT_TRANSACTION_PURCHASE_HANDOVER_API_REF_TYPE,
        };

        if (relationedKeynameToAnotherAttachment) {
          requestAttachment = {
            ...requestAttachment,
            [DEFAULT_KEY_NAME_UUID_API_REF]: relationedKeynameToAnotherAttachment,
          };
        }

        requestAttachment = {
          ...requestAttachment,
          [DEFAULT_KEY_NAME_ATTACHMENT_API_REF]: new File(
            [attachmentFile],
            requestAttachment.file_name,
          ),
        };

        return objHelper.objectToFormData(requestAttachment);
      },
      usageAttachmentFile: true,
      keynameAttachmentFile: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
    },
    detail: {
      url: REACT_APP_API_URL_HANDOVER_PURCHASE_DETAILS,
      errorMessage: 'error.detail.minutes-of-handover',
      uniqueIDKey: 'purchase_handover_id',
      minutesHandoverNumberKey: 'purchase_handover_no',
      minutesHandoverProductKey: 'purchase_handover_product',
      minutesHandoverProductIDKey: 'product_id',
      minutesHandoverAttachmentKey: 'attachment',
      minutesHandoverDescriptionKey: 'description',
      minutesHandoverTransctionDateKey: 'transaction_date',
      sliceName: SLICE_NAME_HANDOVER_PURCHASE_DELIVERY_DETAILS,
      localStorageName: LOCAL_STORAGE_MINUTES_OF_HANDOVER_PURCHASE_DELIVERY,
      requestStatusPending: STATUS_REQUEST_DETAILS_HANDOVER_PURCHASE_DELIVERY_PENDING,
      requestStatusSuccess: STATUS_REQUEST_DETAILS_HANDOVER_PURCHASE_DELIVERY_SUCCESS,
      requestStatusFailed: STATUS_REQUEST_DETAILS_HANDOVER_PURCHASE_DELIVERY_FAILED,
    },
    update: {
      url: REACT_APP_API_URL_HANDOVER_PURCHASE_UPDATE,
      errorMessage: 'error.update.minutes-of-handover',
      successMessage: 'success.update.minutes-of-handover',
      paramsSecondaryTextOnBodyKeys: ['purchase_handover_no'],
      requestStatusPending: STATUS_REQUEST_UPDATE_HANDOVER_PURCHASE_DELIVERY_PENDING,
      requestStatusSuccess: STATUS_REQUEST_UPDATE_HANDOVER_PURCHASE_DELIVERY_SUCCESS,
      requestStatusFailed: STATUS_REQUEST_UPDATE_HANDOVER_PURCHASE_DELIVERY_FAILED,
      isAttachmentFileIncluded: false,
      useFormDataForRequest: false,
      useMultipartFormDataRequest: false,
    },
    create: {
      url: REACT_APP_API_URL_HANDOVER_PURCHASE,
      errorMessage: 'error.add.minutes-of-handover',
      successMessage: 'success.add.minutes-of-handover',
      uniqueIDKey: 'purchase_handover_id',
      uniqueIDdetailsKey: 'purchase_delivery_id',
      buttonLink: REACT_APP_PURCHASE_URL,
      additionalSearchParamsOnBodyKeys: ['purchase_delivery_id'],
      additionalSearchParams: {
        statusPage: DEFAULT_STATUS_PAGE_REGULAR_HANDOVER,
        handoverStatusPage: DEFAULT_STATUS_PAGE_DETAIL_HANDOVER,
      },
      generateButtonLink: (subResponseDataAPI) => {
        if (isEmpty(subResponseDataAPI)) return '';

        return strHelp.templateString(
          REACT_APP_PURCHASE_PURCHASE_DELIVERY_HANDOVER_DETAILS_TEMPLATE_URL,
          subResponseDataAPI,
        );
      },
      requestStatusPending: STATUS_REQUEST_ADD_HANDOVER_PURCHASE_DELIVERY_PENDING,
      requestStatusSuccess: STATUS_REQUEST_ADD_HANDOVER_PURCHASE_DELIVERY_SUCCESS,
      requestStatusFailed: STATUS_REQUEST_ADD_HANDOVER_PURCHASE_DELIVERY_FAILED,
      isAttachmentFileIncluded: false,
      useFormDataForRequest: false,
      useMultipartFormDataRequest: false,
    },
    history: {
      url: REACT_APP_API_URL_HANDOVER_PURCHASE_HISTORY,
      errorMessage: 'error.detail.minutes-of-handover-history',
      uniqueIDKey: 'purchase_handover_id',
      sliceName: SLICE_NAME_HANDOVER_PURCHASE_DELIVERY_HISTORY,
      localStorageName: LOCAL_STORAGE_MINUTES_OF_HANDOVER_PURCHASE_DELIVERY_HISTORY,
      requestStatusPending: REQUEST_STATUS_HISTORY_HANDOVER_PURCHASE_DELIVERY_PENDING,
      requestStatusSuccess: REQUEST_STATUS_HISTORY_HANDOVER_PURCHASE_DELIVERY_SUCCESS,
      requestStatusFailed: REQUEST_STATUS_HISTORY_HANDOVER_PURCHASE_DELIVERY_FAILED,
    },
  },
  [MINUTES_OF_HANDOVER_TYPE_SALES_DELIVERY]: {
    regular: {
      uniqueIDKey: 'sales_handover_id',
      transactionIDKey: 'sales_delivery_id',
      transactionNumberKey: 'sales_delivery_no',
      senderIDKeyTransaction: 'user_id',
      senderNameKeyNameTransaction: 'user_fullname',
      senderIDKeyMinutesHandover: 'sender_id',
      senderNameKeyNameMinutesHandover: 'sender_name',
      receiverIDKeyTransaction: 'customer_id',
      receiverNameKeyNameTransaction: 'customer_name',
      receiverIDKeyMinutesHandover: 'receiver_id',
      receiverNameKeyNameMinutesHandover: 'receiver_name',
      productKeyNameTransaction: 'product',
      accountingPeriodKeyNameUserCompany: 'accounting_periode',
      warehouseIDKeyNameTransaction: 'warehouse_id',
      productIDKeyNameTransaction: 'product_id',
      productNameKeyNameTransaction: 'product_name',
      productQtyKeyNameTransaction: 'quantity',
      productQtyAvailableKeyNameTransaction: 'quantity_available',
      productDescriptionKeyNameTransaction: 'description',
      productUnitKeyNameTransaction: 'product_unit',
      productQtyKeyNameMinutesHandover: 'quantity',
      productDescriptionKeyNameMinutesHandover: 'description',
      transactionNumber: DEFAULT_TRANSACTION_NUMBER_SALES_MINUTES_OF_HANDOVER,
      transactionNumberCode: DEFAULT_TRANSACTION_NUMBER_CODE_SALES_MINUTES_OF_HANDOVER,
      generalizeAttachmentFile: (attachmentFile, relationedKeynameToAnotherAttachment) => {
        if (isEmpty(attachmentFile)) return '';

        let requestAttachment = {
          [DEFAULT_KEY_NAME_FILE_NAME_API_REF]: attachmentFile ? attachmentFile.name : '',
          feature: DEFAULT_TRANSACTION_SALES_HANDOVER_API_REF_TYPE,
        };

        if (relationedKeynameToAnotherAttachment) {
          requestAttachment = {
            ...requestAttachment,
            [DEFAULT_KEY_NAME_UUID_API_REF]: relationedKeynameToAnotherAttachment,
          };
        }

        requestAttachment = {
          ...requestAttachment,
          [DEFAULT_KEY_NAME_ATTACHMENT_API_REF]: new File(
            [attachmentFile],
            requestAttachment.file_name,
          ),
        };

        return objHelper.objectToFormData(requestAttachment);
      },
      usageAttachmentFile: true,
      keynameAttachmentFile: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
    },
    detail: {
      url: REACT_APP_API_URL_HANDOVER_SALES_DETAILS,
      errorMessage: 'error.detail.minutes-of-handover',
      uniqueIDKey: 'sales_handover_id',
      minutesHandoverNumberKey: 'sales_handover_no',
      minutesHandoverProductKey: 'sales_handover_product',
      minutesHandoverProductIDKey: 'product_id',
      minutesHandoverAttachmentKey: 'attachment',
      minutesHandoverDescriptionKey: 'description',
      minutesHandoverTransctionDateKey: 'transaction_date',
      sliceName: SLICE_NAME_HANDOVER_SALES_DELIVERY_DETAILS,
      localStorageName: LOCAL_STORAGE_MINUTES_OF_HANDOVER_SALES_DELIVERY,
      requestStatusPending: STATUS_REQUEST_DETAILS_HANDOVER_SALES_DELIVERY_PENDING,
      requestStatusSuccess: STATUS_REQUEST_DETAILS_HANDOVER_SALES_DELIVERY_SUCCESS,
      requestStatusFailed: STATUS_REQUEST_DETAILS_HANDOVER_SALES_DELIVERY_FAILED,
    },
    update: {
      url: REACT_APP_API_URL_HANDOVER_SALES_UPDATE,
      errorMessage: 'error.update.minutes-of-handover',
      successMessage: 'success.update.minutes-of-handover',
      paramsSecondaryTextOnBodyKeys: ['sales_handover_no'],
      requestStatusPending: STATUS_REQUEST_UPDATE_HANDOVER_SALES_DELIVERY_PENDING,
      requestStatusSuccess: STATUS_REQUEST_UPDATE_HANDOVER_SALES_DELIVERY_SUCCESS,
      requestStatusFailed: STATUS_REQUEST_UPDATE_HANDOVER_SALES_DELIVERY_FAILED,
      isAttachmentFileIncluded: false,
      useFormDataForRequest: false,
      useMultipartFormDataRequest: false,
    },
    create: {
      url: REACT_APP_API_URL_HANDOVER_SALES,
      errorMessage: 'error.add.minutes-of-handover',
      successMessage: 'success.add.minutes-of-handover',
      uniqueIDKey: 'sales_handover_id',
      uniqueIDdetailsKey: 'sales_delivery_id',
      buttonLink: REACT_APP_SALES_URL,
      additionalSearchParamsOnBodyKeys: ['sales_delivery_id'],
      additionalSearchParams: {
        statusPage: DEFAULT_STATUS_PAGE_REGULAR_HANDOVER,
        handoverStatusPage: DEFAULT_STATUS_PAGE_DETAIL_HANDOVER,
      },
      generateButtonLink: (subResponseDataAPI) => {
        if (isEmpty(subResponseDataAPI)) return '';

        return strHelp.templateString(
          REACT_APP_SALES_SALES_DELIVERY_HANDOVER_DETAILS_TEMPLATE_URL,
          subResponseDataAPI,
        );
      },
      requestStatusPending: STATUS_REQUEST_ADD_HANDOVER_SALES_DELIVERY_PENDING,
      requestStatusSuccess: STATUS_REQUEST_ADD_HANDOVER_SALES_DELIVERY_SUCCESS,
      requestStatusFailed: STATUS_REQUEST_ADD_HANDOVER_SALES_DELIVERY_FAILED,
      isAttachmentFileIncluded: false,
      useFormDataForRequest: false,
      useMultipartFormDataRequest: false,
    },
    history: {
      url: REACT_APP_API_URL_HANDOVER_SALES_HISTORY,
      errorMessage: 'error.detail.minutes-of-handover-history',
      uniqueIDKey: 'sales_handover_id',
      sliceName: SLICE_NAME_HANDOVER_SALES_DELIVERY_HISTORY,
      localStorageName: LOCAL_STORAGE_MINUTES_OF_HANDOVER_SALES_DELIVERY_HISTORY,
      requestStatusPending: REQUEST_STATUS_HISTORY_HANDOVER_SALES_DELIVERY_PENDING,
      requestStatusSuccess: REQUEST_STATUS_HISTORY_HANDOVER_SALES_DELIVERY_SUCCESS,
      requestStatusFailed: REQUEST_STATUS_HISTORY_HANDOVER_SALES_DELIVERY_FAILED,
    },
  },
};

// getting handover configuration only from function
export function getHandoverConfiguration({
  minutesHandoverType = MINUTES_OF_HANDOVER_TYPE_PURCHASE_DELIVERY,
  actionName = 'create',
}) {
  minutesHandoverType = minutesHandoverTypes.includes(minutesHandoverType)
    ? minutesHandoverType
    : MINUTES_OF_HANDOVER_TYPE_PURCHASE_DELIVERY;

  actionName = Object.keys(minutesOfHandoverUtils[minutesHandoverType]).includes(actionName)
    ? actionName
    : Object.keys(minutesOfHandoverUtils[minutesHandoverType])[0];

  return minutesOfHandoverUtils[minutesHandoverType][actionName];
}
