/**
 * @param { function } t function for handling translation
 * @returns
 *      initial search sales order data with translation
 */
export const initialSearchSalesOrder = () => {
  return {
    from_date: null,
    to_date: null,
    sales_order_no: '',
    customer_name: '',
    customer_name_container: null,
    status: [],
    statusContainer: [],
  };
};
