/**
 * additional tab name for menu cash bank
 */
export const DEFAULT_TAB_NAME_CASH_BANK_CASH_INDEX = 'cash-bank-cash-index';
export const DEFAULT_TAB_NAME_CASH_BANK_CASH_TRANSFER = 'cash-bank-cash-transfer';
export const DEFAULT_TAB_NAME_CASH_BANK_CASH_RECEIPT = 'cash-bank-cash-receipt';
export const DEFAULT_TAB_NAME_CASH_BANK_CASH_PAYMENT = 'cash-bank-cash-payment';
export const availableTabNameCashBankMenu = [
  DEFAULT_TAB_NAME_CASH_BANK_CASH_INDEX,
  DEFAULT_TAB_NAME_CASH_BANK_CASH_TRANSFER,
  DEFAULT_TAB_NAME_CASH_BANK_CASH_RECEIPT,
  DEFAULT_TAB_NAME_CASH_BANK_CASH_PAYMENT,
];

/**
 * additional tab name for menu product
 */
export const DEFAULT_TAB_NAME_DATA_PRODUCT_INDEX = 'data-product-index';
export const DEFAULT_TAB_NAME_DATA_PRODUCT_STOCK_ADJUSMENT = 'data-product-stock-adjusment';
export const DEFAULT_TAB_NAME_DATA_PRODUCT_WAREHOUSE_TRANSFER = 'data-product-warehouse-transfer';
export const DEFAULT_TAB_NAME_DATA_PRODUCT_BEGINNING_BALANCE = 'data-product-beginning-balance';

/**
 * tab name for menu sales
 */
export const DEFAULT_TAB_NAME_SALES_SALES_QUOTATION = 'sales-sales-quotation';
export const DEFAULT_TAB_NAME_SALES_SALES_ORDER = 'sales-sales-order';
export const DEFAULT_TAB_NAME_SALES_SALES_INVOICE = 'sales-sales-invoice';
export const DEFAULT_TAB_NAME_SALES_SALES_DELIVERY = 'sales-sales-delivery';
export const DEFAULT_TAB_NAME_SALES_SALES_PAYMENT = 'sales-sales-payment';

/**
 * tab name for menu purchase
 */
export const DEFAULT_TAB_NAME_PURCHASE_QUOTATION_REQUEST = 'purchase-quotation-request';
export const DEFAULT_TAB_NAME_PURCHASE_PURCHASE_ORDER = 'purchase-purchase-order';
export const DEFAULT_TAB_NAME_PURCHASE_PURCHASE_INVOICE = 'purchase-purchase-invoice';
export const DEFAULT_TAB_NAME_PURCHASE_PURCHASE_DELIVERY = 'purchase-purchase-delivery';
export const DEFAULT_TAB_NAME_PURCHASE_PURCHASE_PAYMENT = 'purchase-purchase-payment';

/**
 * tab name for menu product details
 */
export const DEFAULT_TAB_NAME_SUPPLIER_LIST = 'tab-supplier-list';
export const DEFAULT_TAB_NAME_CUSTOMER_LIST = 'tab-customer-list';
export const DEFAULT_TAB_NAME_STOCK_LIST = 'tab-stock-list';
export const DEFAULT_TAB_NAME_MUTATION_LIST = 'tab-mutation-list';

/**
 * tab name for notification menu
 */
export const DEFAULT_TAB_NAME_ALL_NOTIFICATIONS = 'tab-all-notifications';
export const DEFAULT_TAB_NAME_INVITATION_NOTIFICATION = 'tab-invitation-notification';

/**
 * asset management tab panel name
 */
export const DEFAULT_TAB_PANEL_NAME_ASSET_MANAGEMENT_DETAILS = 'tab-panel-asset-management-details';
export const DEFAULT_TAB_PANEL_NAME_ASSET_MANAGEMENT_TRANSACTIONS =
  'tab-panel-asset-management-transactions';

/**
 * report tab panel name
 */
export const DEFAULT_TAB_PANEL_NAME_BALANCE_SHEET = 'tab-balance-sheet';
export const DEFAULT_TAB_PANEL_NAME_TRIAL_BALANCE = 'tab-trial-balance';
export const DEFAULT_TAB_PANEL_NAME_INCOME_STATEMENT = 'tab-income-statement';
export const DEFAULT_TAB_PANEL_NAME_CASH_FLOW = 'tab-cash-flow';
