import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import i18nextHttpBackend from 'i18next-http-backend';
import Cookie from 'js-cookie';

import translationEN from './assets/locales/en/translation.json';
import translationID from './assets/locales/id/translation.json';

// setting cookie for default language
if (!Cookie.get('locale')) {
  Cookie.set('locale', 'id', { expires: 7, secure: true, sameSite: 'strict' });
}

i18n
  .use(i18nextHttpBackend)
  .use(initReactI18next)
  .init({
    lng: Cookie.get('locale'),
    fallbackLng: 'id',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: translationEN,
      },
      id: {
        translation: translationID,
      },
    },
  });

export default i18n;
