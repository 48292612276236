import * as React from 'react';
function SvgIconMenuReportCashFlow(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.857 68.464c0 .415-.192.75-.428.75-.237 0-.429-.335-.429-.75 0-.414.192-.75.429-.75.236 0 .428.336.428.75zM6.857 67.714h58.286c.114 0 .222.08.303.22.08.14.125.331.125.53 0 .2-.045.39-.125.53-.08.141-.19.22-.303.22H6.857c-.114 0-.223-.079-.303-.22a1.094 1.094 0 01-.125-.53c0-.199.045-.39.125-.53s.19-.22.303-.22zM2.571 67.714h2.143c.114 0 .223.08.303.22.08.14.126.331.126.53 0 .2-.045.39-.126.53-.08.141-.19.22-.303.22H2.571c-.113 0-.222-.079-.303-.22a1.094 1.094 0 01-.125-.53c0-.199.045-.39.125-.53s.19-.22.303-.22zM69.429 67.714h-2.143c-.114 0-.223.08-.303.22-.08.14-.126.331-.126.53 0 .2.045.39.126.53.08.141.19.22.303.22h2.143c.113 0 .222-.079.303-.22.08-.14.125-.33.125-.53 0-.199-.045-.39-.125-.53s-.19-.22-.303-.22zM72 68.464c0 .415-.192.75-.429.75-.236 0-.428-.335-.428-.75 0-.414.192-.75.428-.75.237 0 .429.336.429.75zM45.297 24.75H18.339a.902.902 0 00-.657.289 1.022 1.022 0 00-.272.7c0 .262.097.513.272.699.174.185.41.29.657.29h26.958a.902.902 0 00.657-.29c.175-.186.273-.437.273-.7 0-.262-.098-.514-.273-.7a.902.902 0 00-.657-.289zM18.34 31.673h20.45a.902.902 0 00.657-.29c.175-.185.272-.437.272-.699 0-.262-.097-.514-.272-.7a.902.902 0 00-.657-.29H18.34a.902.902 0 00-.658.29 1.022 1.022 0 00-.272.7c0 .262.097.514.272.7.174.185.41.29.657.29z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27 34.64h-8.66a.902.902 0 00-.658.29 1.022 1.022 0 00-.272.7c0 .262.097.514.272.7.174.185.41.289.657.289H27v-1.978zM27 41.565h-8.66a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.7c0-.262.097-.513.272-.699a.902.902 0 01.657-.29H27v1.979zM18.34 44.532H27V46.5l-8.66.01a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.699c0-.262.097-.514.272-.7a.902.902 0 01.657-.289zM27 51.456h-8.66a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.699c0-.262.097-.514.272-.7a.902.902 0 01.657-.29L27 49.5v1.956zM18.34 54.424H27v1.978h-8.66a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.7c0-.261.097-.513.272-.699a.902.902 0 01.657-.29zM18.34 59.37H27v1.978h-8.66a.902.902 0 01-.658-.29 1.022 1.022 0 01-.272-.7c0-.262.097-.513.272-.699a.902.902 0 01.657-.29z'
        fill='#000'
      />
      <path
        d='M45.297 14.858H18.339a.902.902 0 00-.657.29 1.022 1.022 0 00-.272.699c0 .262.097.514.272.7.174.185.41.289.657.289h26.958a.902.902 0 00.657-.29c.175-.185.273-.437.273-.7 0-.261-.098-.513-.273-.699a.902.902 0 00-.657-.29zM18.34 21.782h20.45a.902.902 0 00.657-.29c.175-.186.272-.437.272-.7 0-.262-.097-.513-.272-.699a.902.902 0 00-.657-.29H18.34a.902.902 0 00-.658.29 1.022 1.022 0 00-.272.7c0 .262.097.514.272.699.174.185.41.29.657.29z'
        fill='#000'
      />
      <path
        d='M25 69v-2.34H12.263a2.807 2.807 0 01-1.86-.685c-.494-.439-.771-1.034-.771-1.654V11.679c0-.62.277-1.215.77-1.654a2.807 2.807 0 011.861-.685h39.474c.698 0 1.367.246 1.86.685.494.439.771 1.034.771 1.654V37H57V11.68c-.002-1.241-.557-2.43-1.543-3.308-.987-.877-2.325-1.37-3.72-1.372H12.263c-1.395.001-2.733.495-3.72 1.372C7.557 9.25 7.002 10.44 7 11.68v52.642c.002 1.24.557 2.43 1.543 3.307.987.877 2.325 1.37 3.72 1.372H25z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.933 41.292a1.234 1.234 0 00-.561.758l-.693 3.293a3.883 3.883 0 00-1.44.652l.882-4.19a2.54 2.54 0 01.318-.786 2.551 2.551 0 011.168-.953 2.712 2.712 0 011.532-.152l27.896 5.33h-6.56L32.891 41.12a1.355 1.355 0 00-.958.172z'
        fill='#000'
      />
      <path
        d='M47.317 58.863c.163.262.386.486.654.653.268.167.573.273.89.31v.811c0 .282.358.51.641.51.283 0 .641-.228.641-.51v-.803a2.422 2.422 0 001.06-.42c.225-.137.417-.318.562-.531.146-.214.242-.454.281-.705a1.82 1.82 0 00-.292-1.306 1.994 1.994 0 00-1.127-.8c-.203-.059-.279-.119-.484-.176v-1.901c.139.05.265.128.369.228.24.187.42.434.52.713.034.11.094.212.176.297.083.084.185.15.299.19a.47.47 0 00.353-.058.411.411 0 00.195-.286c.025-.4-.107-.794-.37-1.106a2.166 2.166 0 00-.663-.623 2.236 2.236 0 00-.879-.349V51.93c0-.282-.358-.51-.64-.51-.284 0-.641.228-.641.51v1.054c-.09.013-.051.03-.137.054-.726.198-1.405.65-1.573 1.41a1.705 1.705 0 00-.026.814c.058.27.18.523.357.74.368.372.85.623 1.379.72v2.117a1.17 1.17 0 01-.644-.49c-.175-.231-.24-.523-.426-.748a.46.46 0 00-.174-.148.489.489 0 00-.452.011.457.457 0 00-.165.157.998.998 0 00.059.797c.072.155.158.304.257.445zm2.826-1.903c.099.028.068.057.164.092.15.047.285.127.395.234.11.106.192.237.238.38a.888.888 0 01-.162.844.922.922 0 01-.273.265.974.974 0 01-.362.138V56.96zm-1.911-1.797c-.302-.498.167-1.014.63-1.172v1.658a.964.964 0 01-.373-.176.906.906 0 01-.257-.31zm-17.95-16.1c.443-.27.94-.452 1.458-.537l.33-1.366c.058-.254.205-.48.418-.644.212-.163.477-.253.75-.254.096 0 34.53 8.052 34.53 8.052.271.066.512.217.683.429.17.211.262.47.26.737.44.088.861.246 1.246.467a2.356 2.356 0 00-.345-1.787 2.588 2.588 0 00-1.543-1.075l-34.24-8.018a2.564 2.564 0 00-1.612.148 2.39 2.39 0 00-1.16 1.073c-.165.34-.751 2.67-.776 2.774z'
        fill='#000'
      />
      <path
        d='M69.364 64.947c.48-.456.641-.88.64-1.368V48.987a2.37 2.37 0 00-.75-1.72 2.635 2.635 0 00-1.812-.712h-35.88c-.679 0-1.33.257-1.811.713a2.37 2.37 0 00-.751 1.72v14.591c0 .76.234 1.286.8 1.824.481.456 1.122.608 1.763.608l35.879-.304c.961 0 1.47-.331 1.922-.76zm-39.083-15.96c0-.322.136-.631.376-.86.24-.227.566-.355.906-.356h35.879c.34 0 .666.129.906.357.24.228.375.537.375.86v14.591c0 .323-.135.632-.375.86-.24.228-.566.356-.906.356h-35.88c-.339 0-.665-.128-.905-.356a1.186 1.186 0 01-.376-.86V48.987z'
        fill='#000'
      />
      <path
        d='M40.533 62.363a.593.593 0 00-.188-.43.659.659 0 00-.453-.178h-2.115a3.298 3.298 0 00-1.392-1.812 4.254 4.254 0 00-2.26-.58v-6.16a4.268 4.268 0 002.26-.58 3.298 3.298 0 001.392-1.812h2.115c.17 0 .333-.064.453-.178a.593.593 0 00.188-.43.593.593 0 00-.188-.43.659.659 0 00-.453-.178h-2.61a.662.662 0 00-.406.138.603.603 0 00-.222.35c-.487 2.276-2.782 1.911-3.039 1.864a.674.674 0 00-.535.123.613.613 0 00-.174.21.58.58 0 00-.062.262v7.483c0 .09.022.178.063.26.04.081.1.153.173.21a.657.657 0 00.532.126c.261-.05 2.555-.414 3.042 1.863.029.137.108.261.222.35a.662.662 0 00.406.137h2.61c.17 0 .333-.064.453-.178a.593.593 0 00.188-.43zM65.39 51.947a3.273 3.273 0 01-2.065-.339 2.326 2.326 0 01-.651-.668 2.199 2.199 0 01-.323-.858.603.603 0 00-.222-.35.661.661 0 00-.406-.137h-2.61a.658.658 0 00-.453.178.592.592 0 00-.188.43c0 .161.068.316.188.43.12.114.283.178.453.178h2.114c.226.74.719 1.382 1.393 1.812.677.398 1.463.6 2.26.58v6.16a4.261 4.261 0 00-2.26.58 3.298 3.298 0 00-1.393 1.812h-2.114a.658.658 0 00-.453.178.592.592 0 00-.188.43c0 .161.068.316.188.43.12.114.283.178.453.178h2.61a.662.662 0 00.406-.137.603.603 0 00.222-.35c.486-2.276 2.781-1.912 3.039-1.864a.674.674 0 00.534-.124.614.614 0 00.175-.21.582.582 0 00.062-.261v-7.483c0-.09-.022-.18-.062-.261a.615.615 0 00-.175-.211.653.653 0 00-.534-.123z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M55.202 62.777a8.763 8.763 0 001.288-1.187.256.256 0 00-.04-.379.306.306 0 00-.408.038 8.198 8.198 0 01-1.206 1.11c-3.393 2.554-8.22 2.289-11.252-.532l.645.11c.157.027.309-.07.338-.215.029-.147-.075-.287-.233-.314l-1.487-.254a.307.307 0 00-.251.068.258.258 0 00-.09.228l.18 1.414c.018.137.143.237.288.237.012 0 .023 0 .035-.002.159-.017.273-.15.254-.298l-.074-.58c1.486 1.372 3.43 2.214 5.542 2.389.271.022.542.033.811.033a9.39 9.39 0 005.66-1.866zM47.43 48.766a8.706 8.706 0 00-3.208 1.5c-2.818 2.121-3.85 5.658-2.566 8.802.057.139-.019.294-.169.347a.311.311 0 01-.102.017.29.29 0 01-.272-.174 7.719 7.719 0 01-.285-5.058c.486-1.721 1.533-3.226 3.028-4.351a9.292 9.292 0 013.218-1.551l-.475-.157c-.15-.05-.23-.203-.176-.343.053-.14.22-.213.37-.163l1.426.468a.28.28 0 01.18.176c.027.081.01.17-.045.238l-.92 1.141a.298.298 0 01-.232.108.305.305 0 01-.174-.053.256.256 0 01-.059-.376l.46-.571zm11.203 9.283a.307.307 0 01.41.03.255.255 0 01-.033.38l-1.15.908a.304.304 0 01-.255.057.286.286 0 01-.2-.155l-.608-1.308c-.063-.137.005-.295.152-.353a.3.3 0 01.382.14l.303.655c.735-2.438.162-5.023-1.577-7.005-1.3-1.481-3.136-2.467-5.169-2.774-.158-.024-.266-.163-.24-.309.026-.146.175-.246.334-.222 2.173.329 4.136 1.382 5.526 2.966a8.054 8.054 0 011.893 3.884 7.786 7.786 0 01-.153 3.41l.385-.304z'
        fill='#000'
      />
    </svg>
  );
}
export default SvgIconMenuReportCashFlow;
