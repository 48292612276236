import axios from 'axios';
import { pick } from 'lodash';

import {
  REACT_APP_API_URL,
  REACT_APP_API_URL_EMAIL,
  REACT_APP_API_URL_USER,
  REACT_APP_API_URL_USER_AUTH_CHANGE_COMPANY,
} from '../../../utils/configs/api.config';
import { DEFAULT_DATA_IDENTITY_COMPANY } from '../../../utils/default/data-indentity.default';
import { authHeader } from '../../../utils/helpers/auth-header.helpers';
import { creatorAddService } from '../creator-services/creator-services.helper';

// request for handling login
const login = async (email, password) => {
  return await axios({
    url: `${REACT_APP_API_URL}/login`,
    method: 'POST',
    data: {
      email,
      password,
    },
  });
};

// request move compoany
const moveCompany = async (company_id) => {
  const { Authorization } = authHeader();

  if (!company_id || !Authorization) {
    return null;
  }

  return await axios({
    method: 'POST',
    url: `${REACT_APP_API_URL_USER}/company/move/${company_id}`,
    headers: {
      Authorization,
    },
  });
};

// hit api for registering user
const register = async (dataRegister) => {
  if (!dataRegister) {
    return null;
  }

  return await axios({
    method: 'POST',
    url: `${REACT_APP_API_URL}/register`,
    data: {
      ...dataRegister,
    },
  });
};

// request api for logout user
const logout = async () => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  return await axios({
    method: 'POST',
    url: `${REACT_APP_API_URL_USER}/logout`,
    headers: {
      Authorization,
    },
  });
};

// handling forgot password request for hit api
const forgotPassword = async (email) => {
  if (!email) {
    return null;
  }

  return await axios({
    method: 'POST',
    url: `${REACT_APP_API_URL_EMAIL}/forget-password`,
    data: {
      email,
    },
  });
};

// request api for verify email
const verifyEmail = async (token) => {
  // when token is not provide returning null
  if (!token) {
    return null;
  }

  return await axios({
    method: 'GET',
    url: `${REACT_APP_API_URL_EMAIL}/verify`,
    params: {
      token,
    },
  });
};

// request api change password
const newPassword = async (dataRequest) => {
  if (!dataRequest) {
    return null;
  }

  const { token, password, password_confirmation } = dataRequest;

  return await axios({
    method: 'POST',
    url: `${REACT_APP_API_URL}/password/change-password`,
    data: {
      password,
      password_confirmation,
    },
    params: {
      token,
    },
  });
};

// service change company user
const changeCompany = async (bodyChangeCompany) => {
  return await creatorAddService(
    REACT_APP_API_URL_USER_AUTH_CHANGE_COMPANY,
    bodyChangeCompany,
    () => pick(bodyChangeCompany, DEFAULT_DATA_IDENTITY_COMPANY),
  );
};

const authService = {
  login,
  moveCompany,
  register,
  logout,
  forgotPassword,
  verifyEmail,
  newPassword,
  changeCompany,
};

export default authService;
