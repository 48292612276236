import * as React from 'react';
function SvgPurchaseDeliveryMenu(props) {
  return (
    <svg width={133} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#purchase-delivery-menu_svg__filter0_d_5586_192885)'>
        <path
          d='M93.642 48.731v7.84H82.175v-2.652c0-.922-.625-1.614-1.46-1.614H62.578c-.834 0-1.46.692-1.46 1.614v2.652H46.42c-.938-3.805-4.066-6.572-7.819-6.572-3.752 0-6.88 2.767-7.818 6.572h-7.089v-7.84h69.95z'
          fill='#2B3B4E'
        />
        <path
          d='M93.642 48.731H23.694c-1.981 0-3.65-1.845-3.65-4.035V5.036c0-2.191 1.669-4.036 3.65-4.036h66.3c1.98 0 3.648 1.845 3.648 4.035v43.696z'
          fill='#324A5E'
        />
        <path d='M93.642 16.45H20.045v14.41h73.597V16.45z' fill='#FF7058' />
        <path d='M93.642 16.91v41.621h31.065V39.277l-11.05-22.367H93.642z' fill='#84DBFF' />
        <path
          d='M124.707 39.277v16.14h-5.629v3.114h-5.733c-.209-4.727-3.753-8.532-8.131-8.532-4.274 0-7.819 3.805-8.132 8.532h-3.544V17.026h20.015l11.154 22.251z'
          fill='#F9FAFB'
        />
        <path d='M120.955 37.202l-8.653-17.64H96.04v17.64h24.915z' fill='#84DBFF' />
        <path d='M106.673 37.202h14.281l-8.652-17.64h-1.147l-4.482 17.64z' fill='#54C0EB' />
        <path
          d='M38.6 67.985c4.491 0 8.131-4.026 8.131-8.993 0-4.966-3.64-8.993-8.13-8.993-4.491 0-8.132 4.027-8.132 8.993 0 4.967 3.64 8.993 8.131 8.993z'
          fill='#324A5E'
        />
        <path
          d='M38.6 64.757c2.88 0 5.213-2.581 5.213-5.765 0-3.184-2.334-5.765-5.213-5.765-2.878 0-5.212 2.581-5.212 5.765 0 3.184 2.334 5.765 5.212 5.765zM80.716 60.49H62.68c-.834 0-1.46-.69-1.46-1.613v-5.073c0-.922.626-1.614 1.46-1.614h18.035c.834 0 1.459.692 1.459 1.614v5.073c0 .922-.625 1.614-1.46 1.614z'
          fill='#E6E9EE'
        />
        <path d='M124.707 47.347h-3.023v6.688h3.023v-6.688z' fill='#FF7058' />
        <path
          d='M28.802 27.863c-.313 0-.626-.346-.626-.691v-6.803c0-.346.313-.692.626-.692h1.772s3.127 0 3.127 4.036c0 4.15-3.127 4.035-3.127 4.035h-1.772v.115zm.625-1.383h1.147s.521 0 .938-.346c.626-.461.938-1.268.938-2.421 0-1.153-.312-1.96-.938-2.421-.417-.346-.938-.346-.938-.346h-1.147v5.534zM41.311 27.172c0 .345-.312.691-.625.691H37.35c-.313 0-.625-.346-.625-.691v-6.803c0-.346.312-.692.625-.692h3.336c.313 0 .625.346.625.692s-.312.692-.625.692h-2.71v2.075h2.084c.313 0 .626.346.626.692s-.313.692-.626.692h-2.084v2.075h2.814c.313-.115.521.115.521.577zM44.96 27.863c-.313 0-.626-.346-.626-.691v-6.803c0-.346.313-.692.626-.692s.625.346.625.692v6.11h2.19c.312 0 .625.347.625.693 0 .345-.313.691-.625.691H44.96zM51.214 27.172v-6.803c0-.346.313-.692.626-.692s.625.346.625.692v6.803c0 .345-.312.691-.625.691s-.626-.346-.626-.691zM57.99 27.402L55.488 20.6c0-.116-.104-.923.522-.923.208 0 .52.116.52.462l1.877 5.188 1.877-5.188c.104-.23.313-.462.521-.462.73.116.625.808.521.923l-2.502 6.802c-.104.23-.312.461-.52.461 0 0-.21-.23-.314-.46zM69.145 27.172c0 .345-.313.691-.626.691h-3.335c-.313 0-.626-.346-.626-.691v-6.803c0-.346.313-.692.626-.692h3.335c.313 0 .626.346.626.692s-.313.692-.626.692h-2.814v2.075h2.189c.313 0 .625.346.625.692s-.312.692-.625.692h-2.19v2.075h2.815c.417-.115.626.115.626.577zM85.407 20.254c0 .115 0 .23-.104.346l-2.085 3.228v3.228c0 .346-.313.692-.626.692-.312 0-.625-.346-.625-.692v-3.228L79.882 20.6c-.104-.115-.104-.23-.104-.346 0-.346.208-.692.625-.692.209 0 .417.116.521.346l1.668 2.652 1.668-2.652a.568.568 0 01.522-.346c.104 0 .208 0 .312.116.209.23.313.46.313.576z'
          fill='#fff'
        />
        <path
          d='M101.252 40.43h-3.961c-.73 0-1.355-.692-1.355-1.499v-.46h6.671v.576c0 .807-.625 1.383-1.355 1.383z'
          fill='#E6E9EE'
        />
        <path
          d='M124.812 55.418h-5.734v4.612h5.734c.521 0 .938-.461.938-1.038v-2.421c0-.692-.417-1.153-.938-1.153z'
          fill='#324A5E'
        />
        <path
          d='M114.491 58.531h-1.146c-.209-4.727-3.753-8.532-8.132-8.532-4.378 0-7.922 3.805-8.13 8.532h-1.147c.208-5.419 4.274-9.8 9.277-9.8 5.004 0 9.07 4.266 9.278 9.8z'
          fill='#2B3B4E'
        />
        <path
          d='M105.214 67.985c4.49 0 8.131-4.026 8.131-8.993 0-4.966-3.641-8.993-8.131-8.993-4.491 0-8.132 4.027-8.132 8.993 0 4.967 3.641 8.993 8.132 8.993z'
          fill='#324A5E'
        />
        <path
          d='M105.214 64.757c2.878 0 5.212-2.581 5.212-5.765 0-3.183-2.334-5.764-5.212-5.764-2.879 0-5.213 2.58-5.213 5.764s2.334 5.765 5.213 5.765z'
          fill='#E6E9EE'
        />
        <path
          d='M75.608 24.75c.73-.46 1.25-1.383 1.25-2.42 0-1.5-1.146-2.768-2.501-2.768h-1.668c-.313 0-.625.346-.625.692v6.802c0 .346.312.692.625.692s.625-.346.625-.692v-2.075h.939l1.355 2.421c.208.346.521.461.834.23.313-.23.417-.576.208-.922l-1.042-1.96zm-2.19-1.037V21.06h1.043c.626 0 1.251.576 1.251 1.384 0 .691-.521 1.383-1.25 1.383h-1.043v-.115z'
          fill='#fff'
        />
        <path
          d='M6.064 35.088c0 1.03-1.04 1.868-2.323 1.868-1.284 0-2.325-.837-2.325-1.868 0-1.034 1.041-1.87 2.325-1.87 1.282 0 2.323.836 2.323 1.87zM38.529 37.532l-18.075 4.939 7.081 16.784 18.075-4.939-7.081-16.784z'
          fill='#FF8E31'
        />
        <path
          d='M49.05 64.633H18.763c-.96 0-1.865-.3-2.547-.85-.679-.548-1.054-1.275-1.054-2.05v-.077c0-.658.283-1.304.793-1.818.514-.513 1.237-.873 2.037-1.016L43.37 54.33c.602-.107 1.196.2 1.329.682.132.483-.248.962-.85 1.07L18.47 60.575c-.31.053-.578.187-.777.387a.976.976 0 00-.304.694v.077c0 .296.143.574.402.783.26.208.606.325.97.325H49.05c.616 0 1.114.4 1.114.896 0 .494-.497.896-1.113.896z'
          fill='#F2F4F7'
        />
        <path
          d='M19.552 60.361c-.537 0-1.011-.313-1.098-.755l-4.582-23.094a1.055 1.055 0 00-.465-.669 1.579 1.579 0 00-.894-.265H3.741c-.616 0-1.116-.4-1.116-.896 0-.494.5-.898 1.116-.898h8.771c.857 0 1.688.248 2.34.695.651.446 1.087 1.069 1.22 1.75l4.581 23.093c.096.49-.319.95-.925 1.028-.06.008-.117.011-.176.011z'
          fill='#F2F4F7'
        />
        <path
          d='M24.68 66.103c0 1.58-1.593 2.86-3.553 2.86-1.962 0-3.555-1.28-3.555-2.86 0-1.58 1.593-2.858 3.555-2.858 1.96 0 3.553 1.278 3.553 2.858z'
          fill='#34495E'
        />
        <path
          d='M22.901 66.103c0 .79-.794 1.43-1.774 1.43-.983 0-1.775-.64-1.775-1.43 0-.789.792-1.429 1.775-1.429.98 0 1.774.64 1.774 1.429z'
          fill='#F2F4F7'
        />
        <path
          d='M50.164 66.103c0 1.58-1.592 2.86-3.554 2.86-1.961 0-3.552-1.28-3.552-2.86 0-1.58 1.591-2.858 3.552-2.858 1.962 0 3.554 1.278 3.554 2.858z'
          fill='#34495E'
        />
        <path
          d='M48.387 66.103c0 .79-.797 1.43-1.777 1.43-.98 0-1.776-.64-1.776-1.43 0-.788.796-1.428 1.776-1.428s1.777.64 1.777 1.428zM49.78 42.877a1.476 1.476 0 00-.928-.31H14.968L18.64 60.95l30.494-5.398c.282-.05.543-.174.74-.37a.935.935 0 00.29-.66V43.623c0-.27-.129-.54-.384-.745zM21.22 58.715a.952.952 0 01-.133.008c-.4 0-.753-.234-.818-.562l-2.614-13.082c-.072-.365.235-.708.688-.766.45-.058.878.19.952.554l2.612 13.082c.073.365-.234.707-.687.766zm4.333-.769a.918.918 0 01-.118.008c-.406 0-.762-.24-.82-.574l-2.178-12.313c-.065-.364.25-.703.703-.755.458-.053.875.202.94.567l2.178 12.313c.065.364-.25.703-.705.754zm4.33-.767a1.222 1.222 0 01-.1.005c-.413 0-.771-.25-.822-.588l-1.743-11.542c-.056-.366.27-.7.723-.744.456-.038.868.216.925.582l1.742 11.544c.055.365-.268.698-.724.743zm4.33-.769a1.294 1.294 0 01-.083.003c-.422 0-.783-.258-.824-.603l-1.308-10.772c-.045-.367.288-.694.746-.73.463-.032.863.233.907.6l1.308 10.773c.042.367-.29.694-.746.73zm4.325-.767l-.06.002c-.432 0-.795-.27-.826-.622l-.87-10.003c-.032-.368.311-.687.77-.714.462-.019.853.252.886.62l.87 10.004c.032.368-.314.687-.77.713zm4.323-.77h-.033c-.444 0-.812-.282-.83-.641l-.435-9.234c-.016-.368.34-.678.799-.693.463-.022.843.273.861.642l.435 9.233c.018.37-.34.68-.797.694zm5.146-1.437c0 .37-.372.668-.83.668-.459 0-.83-.299-.83-.668v-8.463c0-.37.371-.667.83-.667.458 0 .83.298.83.667v8.463z'
          fill='#F2F4F7'
        />
      </g>
      <defs>
        <filter
          id='purchase-delivery-menu_svg__filter0_d_5586_192885'
          x={0.416}
          y={0}
          width={132.334}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_192885' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_192885' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgPurchaseDeliveryMenu;
