import moment from 'moment';

import {
  DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MOBILE,
  DEFAULT_LABEL_NAME_THIS_MONTH,
  getRangeDateThisMonth,
} from '../../components/date-time/DateTimePicker/customDefaultStaticRange';
import {
  REACT_APP_API_URL_CHART_EXPENSE,
  REACT_APP_API_URL_CHART_PAYABLE,
  REACT_APP_API_URL_CHART_PRODUCT_STOCK,
  REACT_APP_API_URL_CHART_PURCHASE,
  REACT_APP_API_URL_CHART_PURCHASE_INVOICE,
  REACT_APP_API_URL_CHART_RECEIVABLE,
  REACT_APP_API_URL_CHART_SALES,
  REACT_APP_API_URL_CHART_SALES_INVOICE,
  REACT_APP_API_URL_CHART_SALES_PERSON,
} from '../configs/api.config';
import {
  REACT_APP_BASIC_PURCHASE_BASIC_URL,
  REACT_APP_BASIC_SALES_BASIC_URL,
  REACT_APP_PURCHASE_PURCHASE_INVOICE_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_SALES_INVOICE_DETAILS_TEMPLATE_URL,
} from '../configs/url.config';
import {
  LOCAL_STORAGE_CHART_PRODUCT_STOCK_BASIC_LIST,
  LOCAL_STORAGE_CHART_PURCHASE_INVOICE_BASIC_LIST,
  LOCAL_STORAGE_CHART_SALES_INVOICE_BASIC_LIST,
  LOCAL_STORAGE_CHART_SALES_PERSON_BASIC_LIST,
} from '../constants/storage.constant';
import {
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_PAYABLE_FAILED,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_PAYABLE_PENDING,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_PAYABLE_SUCCESS,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_PRODUCT_STOCK_FAILED,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_PRODUCT_STOCK_PENDING,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_PRODUCT_STOCK_SUCCESS,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_FAILED,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE_FAILED,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE_PENDING,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE_SUCCESS,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_PENDING,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_SUCCESS,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_RECEIVABLE_FAILED,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_RECEIVABLE_PENDING,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_RECEIVABLE_SUCCESS,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_FAILED,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_INVOICE_FAILED,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_INVOICE_PENDING,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_INVOICE_SUCCESS,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_PENDING,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_PERSON_FAILED,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_PERSON_PENDING,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_PERSON_SUCCESS,
  REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_SUCCESS,
  REQUEST_STATUS_CHART_DASHBOARD_EXPENSE_FAILED,
  REQUEST_STATUS_CHART_DASHBOARD_EXPENSE_PENDING,
  REQUEST_STATUS_CHART_DASHBOARD_EXPENSE_SUCCESS,
  SLICE_NAME_CHART_BASIC_DASHBOARD_PAYABLE,
  SLICE_NAME_CHART_BASIC_DASHBOARD_PRODUCT_STOCK,
  SLICE_NAME_CHART_BASIC_DASHBOARD_PURCHASE,
  SLICE_NAME_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE,
  SLICE_NAME_CHART_BASIC_DASHBOARD_RECEIVABLE,
  SLICE_NAME_CHART_BASIC_DASHBOARD_SALES,
  SLICE_NAME_CHART_BASIC_DASHBOARD_SALES_INVOICE,
  SLICE_NAME_CHART_BASIC_DASHBOARD_SALES_PERSON,
  SLICE_NAME_CHART_EXPENSE,
} from '../constants/store.constant';
import { productStockFilter } from '../data/label-value/product-stock-filter';
import { purchaseInvoiceChartBasicStatus } from '../data/label-value/purchase-status';
import { salesInvoiceChartBasicStatus } from '../data/label-value/sales-status';

import {
  DEFAULT_ALLOW_CHART_EXPENSE_PARAMS,
  DEFAULT_CHART_PAYABLE_PARAMS,
  DEFAULT_CHART_PRODUCT_STOCK_LIST_PARAMS,
  DEFAULT_CHART_PRODUCT_STOCK_LIST_SEARCH_PARAMS,
  DEFAULT_CHART_PURCHASE_INVOICE_LIST_PARAMS,
  DEFAULT_CHART_PURCHASE_INVOICE_LIST_SEARCH_PARAMS,
  DEFAULT_CHART_PURCHASE_PARAMS,
  DEFAULT_CHART_RECEIVABLE_PARAMS,
  DEFAULT_CHART_SALES_INVOICE_LIST_PARAMS,
  DEFAULT_CHART_SALES_INVOICE_LIST_SEARCH_PARAMS,
  DEFAULT_CHART_SALES_PARAMS,
  DEFAULT_CHART_SALES_PERSON_LIST_PARAMS,
  DEFAULT_CHART_SALES_PERSON_LIST_SEARCH_PARAMS,
  SECONDARY_DEFAULT_LIST_PARAMS,
} from './params.default';
import { DEFAULT_STATUS_PAGE_DETAILS_TRANSACTION_INVOICE_BASIC } from './status-page.default';

export const CHART_BASIC_DASHBOARD_TYPE_PURCHASE = 'chart-basic-dashboard-purchase';
export const CHART_BASIC_DASHBOARD_TYPE_SALES = 'chart-basic-dashboard-sales';
export const CHART_BASIC_DASHBOARD_TYPE_PAYABLE = 'chart-basic-dashboard-payable';
export const CHART_BASIC_DASHBOARD_TYPE_RECEIVABLE = 'chart-basic-dashboard-receivable';
export const CHART_DASHBOARD_TYPE_EXPENSE = 'chart-dashboard-expense';

export const CHART_BASIC_DASHBOARD_TYPE_SALES_INVOICE = 'chart-basic-dashboard-sales-invoice';
export const CHART_BASIC_DASHBOARD_TYPE_PURCHASE_INVOICE = 'chart-basic-dashboard-purchase-invoice';
export const CHART_BASIC_DASHBOARD_TYPE_PRODUCT_STOCK = 'chart-basic-dashboard-product-stock';
export const CHART_BASIC_DASHBOARD_TYPE_SALES_PERSON = 'chart-basic-dashboard-sales-person';

export const CHART_BASIC_DASHBOARD_TYPE_KEY_NAME = 'chartType';

export const chartBasicApplicationType = 'chart-basic-application';

export const chartBasicDashboardTypes = [
  CHART_BASIC_DASHBOARD_TYPE_SALES,
  CHART_BASIC_DASHBOARD_TYPE_RECEIVABLE,
  CHART_BASIC_DASHBOARD_TYPE_PURCHASE,
  CHART_BASIC_DASHBOARD_TYPE_PAYABLE,
];

export const chartListBasicDashboardTypes = [
  CHART_BASIC_DASHBOARD_TYPE_SALES_INVOICE,
  CHART_BASIC_DASHBOARD_TYPE_PURCHASE_INVOICE,
  CHART_BASIC_DASHBOARD_TYPE_SALES_PERSON,
  CHART_BASIC_DASHBOARD_TYPE_PRODUCT_STOCK,
];

export const chartBasicDashboardDetailsUtils = {
  [CHART_BASIC_DASHBOARD_TYPE_PURCHASE]: {
    url: REACT_APP_API_URL_CHART_PURCHASE,
    sliceName: SLICE_NAME_CHART_BASIC_DASHBOARD_PURCHASE,
    localStorageName: '',
    errorMessage: '',
    requestStatusPending: REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_PENDING,
    requestStatusError: REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_SUCCESS,
    allowParams: DEFAULT_CHART_PURCHASE_PARAMS,
    defaultParams: {},
    initialSearchParams: {
      from_date: moment(getRangeDateThisMonth().startDate).toISOString(true),
      to_date: moment(getRangeDateThisMonth().endDate).toISOString(true),
    },
    displayDateRangeStaticWrapperAs: DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MOBILE,
    selectedLabelNameDateRange: DEFAULT_LABEL_NAME_THIS_MONTH,
    defaultKeyStartDate: 'from_date',
    defaultKeyEndDate: 'to_date',
    keyNameDetailsValue: 'value',
    header: 'typography.expense',
  },
  [CHART_BASIC_DASHBOARD_TYPE_SALES]: {
    url: REACT_APP_API_URL_CHART_SALES,
    sliceName: SLICE_NAME_CHART_BASIC_DASHBOARD_SALES,
    localStorageName: '',
    errorMessage: '',
    requestStatusPending: REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_PENDING,
    requestStatusError: REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_SUCCESS,
    allowParams: DEFAULT_CHART_SALES_PARAMS,
    defaultParams: {},
    initialSearchParams: {
      from_date: moment(getRangeDateThisMonth().startDate).toISOString(true),
      to_date: moment(getRangeDateThisMonth().endDate).toISOString(true),
    },
    displayDateRangeStaticWrapperAs: DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MOBILE,
    selectedLabelNameDateRange: DEFAULT_LABEL_NAME_THIS_MONTH,
    defaultKeyStartDate: 'from_date',
    defaultKeyEndDate: 'to_date',
    keyNameDetailsValue: 'value',
    header: 'typography.income',
  },
  [CHART_BASIC_DASHBOARD_TYPE_PAYABLE]: {
    url: REACT_APP_API_URL_CHART_PAYABLE,
    sliceName: SLICE_NAME_CHART_BASIC_DASHBOARD_PAYABLE,
    localStorageName: '',
    errorMessage: '',
    requestStatusPending: REQUEST_STATUS_CHART_BASIC_DASHBOARD_PAYABLE_PENDING,
    requestStatusError: REQUEST_STATUS_CHART_BASIC_DASHBOARD_PAYABLE_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_BASIC_DASHBOARD_PAYABLE_SUCCESS,
    allowParams: DEFAULT_CHART_PAYABLE_PARAMS,
    defaultParams: {},
    initialSearchParams: {
      from_date: moment(getRangeDateThisMonth().startDate).toISOString(true),
      to_date: moment(getRangeDateThisMonth().endDate).toISOString(true),
    },
    displayDateRangeStaticWrapperAs: DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MOBILE,
    selectedLabelNameDateRange: DEFAULT_LABEL_NAME_THIS_MONTH,
    defaultKeyStartDate: 'from_date',
    defaultKeyEndDate: 'to_date',
    keyNameDetailsValue: 'value',
    header: 'typography.charge',
  },
  [CHART_BASIC_DASHBOARD_TYPE_RECEIVABLE]: {
    url: REACT_APP_API_URL_CHART_RECEIVABLE,
    sliceName: SLICE_NAME_CHART_BASIC_DASHBOARD_RECEIVABLE,
    localStorageName: '',
    errorMessage: '',
    requestStatusPending: REQUEST_STATUS_CHART_BASIC_DASHBOARD_RECEIVABLE_PENDING,
    requestStatusError: REQUEST_STATUS_CHART_BASIC_DASHBOARD_RECEIVABLE_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_BASIC_DASHBOARD_RECEIVABLE_SUCCESS,
    allowParams: DEFAULT_CHART_RECEIVABLE_PARAMS,
    defaultParams: {},
    initialSearchParams: {
      from_date: moment(getRangeDateThisMonth().startDate).toISOString(true),
      to_date: moment(getRangeDateThisMonth().endDate).toISOString(true),
    },
    displayDateRangeStaticWrapperAs: DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MOBILE,
    selectedLabelNameDateRange: DEFAULT_LABEL_NAME_THIS_MONTH,
    defaultKeyStartDate: 'from_date',
    defaultKeyEndDate: 'to_date',
    keyNameDetailsValue: 'value',
    header: 'typography.receivable',
  },
  [CHART_DASHBOARD_TYPE_EXPENSE]: {
    url: REACT_APP_API_URL_CHART_EXPENSE,
    sliceName: SLICE_NAME_CHART_EXPENSE,
    localStorageName: '',
    errorMessage: '',
    requestStatusPending: REQUEST_STATUS_CHART_DASHBOARD_EXPENSE_PENDING,
    requestStatusError: REQUEST_STATUS_CHART_DASHBOARD_EXPENSE_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_DASHBOARD_EXPENSE_SUCCESS,
    allowParams: DEFAULT_ALLOW_CHART_EXPENSE_PARAMS,
    defaultParams: {},
    initialSearchParams: {
      from_date: moment(getRangeDateThisMonth().startDate).toISOString(true),
      to_date: moment(getRangeDateThisMonth().endDate).toISOString(true),
    },
    displayDateRangeStaticWrapperAs: DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MOBILE,
    selectedLabelNameDateRange: DEFAULT_LABEL_NAME_THIS_MONTH,
    defaultKeyStartDate: 'from_date',
    defaultKeyEndDate: 'to_date',
    keyNameDetailsValue: 'value',
    header: 'typography.expense',
  },
};

export const paperChartListBasicDashboardUtils = {
  [CHART_BASIC_DASHBOARD_TYPE_PURCHASE_INVOICE]: {
    url: REACT_APP_API_URL_CHART_PURCHASE_INVOICE,
    sliceName: SLICE_NAME_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE,
    localStorageName: LOCAL_STORAGE_CHART_PURCHASE_INVOICE_BASIC_LIST,
    requestStatusPending: REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE_PENDING,
    requestStatusError: REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_BASIC_DASHBOARD_PURCHASE_INVOICE_SUCCESS,
    allowParams: DEFAULT_CHART_PURCHASE_INVOICE_LIST_PARAMS,
    defaultParams: SECONDARY_DEFAULT_LIST_PARAMS,
    uniqueIdentityID: 'purchase_invoice_id',
    searchParams: DEFAULT_CHART_PURCHASE_INVOICE_LIST_SEARCH_PARAMS,
    initialSearchParams: {
      ...SECONDARY_DEFAULT_LIST_PARAMS,
      isShowMessage: false,
      from_date: moment(getRangeDateThisMonth().startDate).toISOString(),
      to_date: moment(getRangeDateThisMonth().endDate).toISOString(),
      status: [],
    },
    sortSearchParams: 'sort_by',
    header: 'typography.purchase-invoice',
    isFilterDateShow: true,
    displayDateRangeStaticWrapperAs: DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MOBILE,
    selectedLabelNameDateRange: DEFAULT_LABEL_NAME_THIS_MONTH,
    defaultKeyStartDate: 'from_date',
    defaultKeyEndDate: 'to_date',
    isFilterCustomShow: true,
    buttonCustomFilterName: 'button.status',
    customKeyFilter: 'status',
    customFilterConfig: (t) => purchaseInvoiceChartBasicStatus(t),
    columnsTable: (t) => {
      return [
        {
          name: 'purchase_invoice_no',
          label: t('table.header-column.transaction-number'),
          width: 360,
          format: 'link',
          isSortColumn: true,
          sortKeyName: 'transaction_id',
        },
        {
          name: 'transaction_date',
          label: t('table.header-column.transaction-date'),
          width: 300,
          format: 'date',
          isSortColumn: true,
          sortKeyName: 'transaction_date',
        },
        {
          name: 'amount',
          label: t('table.header-column.nominal'),
          width: 180,
          format: 'normal-currency',
          isSortColumn: true,
          sortKeyName: 'amount',
          align: 'right',
        },
        {
          name: 'status',
          label: t('table.header-column.status'),
          width: 100,
          format: 'normal-chip',
          isSortColumn: false,
          generateChip: (t) => purchaseInvoiceChartBasicStatus(t),
        },
      ];
    },
    keyNameLinkEncrypted: 'selectedPurchaseInvoice',
    linkBaseUrl: REACT_APP_BASIC_PURCHASE_BASIC_URL,
    linkProfessionalDetailsUrl: REACT_APP_PURCHASE_PURCHASE_INVOICE_DETAILS_TEMPLATE_URL,
    pageStatus: DEFAULT_STATUS_PAGE_DETAILS_TRANSACTION_INVOICE_BASIC,
  },
  [CHART_BASIC_DASHBOARD_TYPE_SALES_INVOICE]: {
    url: REACT_APP_API_URL_CHART_SALES_INVOICE,
    sliceName: SLICE_NAME_CHART_BASIC_DASHBOARD_SALES_INVOICE,
    localStorageName: LOCAL_STORAGE_CHART_SALES_INVOICE_BASIC_LIST,
    requestStatusPending: REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_INVOICE_PENDING,
    requestStatusError: REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_INVOICE_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_INVOICE_SUCCESS,
    allowParams: DEFAULT_CHART_SALES_INVOICE_LIST_PARAMS,
    defaultParams: SECONDARY_DEFAULT_LIST_PARAMS,
    uniqueIdentityID: 'sales_invoice_id',
    searchParams: DEFAULT_CHART_SALES_INVOICE_LIST_SEARCH_PARAMS,
    initialSearchParams: {
      ...SECONDARY_DEFAULT_LIST_PARAMS,
      isShowMessage: false,
      from_date: moment(getRangeDateThisMonth().startDate).toISOString(),
      to_date: moment(getRangeDateThisMonth().endDate).toISOString(),
      status: [],
    },
    sortSearchParams: 'sort_by',
    header: 'typography.sales-invoice',
    isFilterDateShow: true,
    displayDateRangeStaticWrapperAs: DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MOBILE,
    selectedLabelNameDateRange: DEFAULT_LABEL_NAME_THIS_MONTH,
    defaultKeyStartDate: 'from_date',
    defaultKeyEndDate: 'to_date',
    isFilterCustomShow: true,
    buttonCustomFilterName: 'button.status',
    customKeyFilter: 'status',
    customFilterConfig: (t) => salesInvoiceChartBasicStatus(t),
    columnsTable: (t) => {
      return [
        {
          name: 'sales_invoice_no',
          label: t('table.header-column.transaction-number'),
          width: 360,
          format: 'link',
          isSortColumn: true,
          sortKeyName: 'transaction_id',
        },
        {
          name: 'transaction_date',
          label: t('table.header-column.transaction-date'),
          width: 300,
          format: 'date',
          isSortColumn: true,
          sortKeyName: 'transaction_date',
        },
        {
          name: 'amount',
          label: t('table.header-column.nominal'),
          width: 180,
          format: 'normal-currency',
          isSortColumn: true,
          sortKeyName: 'amount',
          align: 'right',
        },
        {
          name: 'status',
          label: t('table.header-column.status'),
          width: 100,
          format: 'normal-chip',
          isSortColumn: false,
          generateChip: (t) => salesInvoiceChartBasicStatus(t),
        },
      ];
    },
    keyNameLinkEncrypted: 'selectedSalesInvoice',
    linkBaseUrl: REACT_APP_BASIC_SALES_BASIC_URL,
    linkProfessionalDetailsUrl: REACT_APP_SALES_SALES_INVOICE_DETAILS_TEMPLATE_URL,
    pageStatus: DEFAULT_STATUS_PAGE_DETAILS_TRANSACTION_INVOICE_BASIC,
  },
  [CHART_BASIC_DASHBOARD_TYPE_PRODUCT_STOCK]: {
    url: REACT_APP_API_URL_CHART_PRODUCT_STOCK,
    sliceName: SLICE_NAME_CHART_BASIC_DASHBOARD_PRODUCT_STOCK,
    localStorageName: LOCAL_STORAGE_CHART_PRODUCT_STOCK_BASIC_LIST,
    requestStatusPending: REQUEST_STATUS_CHART_BASIC_DASHBOARD_PRODUCT_STOCK_PENDING,
    requestStatusError: REQUEST_STATUS_CHART_BASIC_DASHBOARD_PRODUCT_STOCK_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_BASIC_DASHBOARD_PRODUCT_STOCK_SUCCESS,
    allowParams: DEFAULT_CHART_PRODUCT_STOCK_LIST_PARAMS,
    defaultParams: SECONDARY_DEFAULT_LIST_PARAMS,
    uniqueIdentityID: 'product_id',
    searchParams: DEFAULT_CHART_PRODUCT_STOCK_LIST_SEARCH_PARAMS,
    sortSearchParams: 'sort_by',
    initialSearchParams: {
      ...SECONDARY_DEFAULT_LIST_PARAMS,
      isShowMessage: false,
      from_date: moment(getRangeDateThisMonth().startDate).toISOString(),
      to_date: moment(getRangeDateThisMonth().endDate).toISOString(),
      status: [],
    },
    header: 'typography.product-stock',
    isFilterDateShow: false,
    isFilterCustomShow: true,
    buttonCustomFilterName: 'button.filter',
    customKeyFilter: 'filter',
    customFilterConfig: (t) => productStockFilter(t),
    columnsTable: (t) => {
      return [
        {
          name: 'product_name',
          label: t('table.header-column.product-name'),
          width: 300,
          format: 'normal',
          isSortColumn: true,
          sortKeyName: 'product_name',
        },
        {
          name: 'quantity_real',
          label: t('table.header-column.stock-quantity'),
          width: 300,
          format: 'normal-currency',
          isSortColumn: true,
          sortKeyName: 'quantity_real',
          align: 'right',
        },
      ];
    },
  },
  [CHART_BASIC_DASHBOARD_TYPE_SALES_PERSON]: {
    url: REACT_APP_API_URL_CHART_SALES_PERSON,
    sliceName: SLICE_NAME_CHART_BASIC_DASHBOARD_SALES_PERSON,
    localStorageName: LOCAL_STORAGE_CHART_SALES_PERSON_BASIC_LIST,
    requestStatusPending: REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_PERSON_PENDING,
    requestStatusError: REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_PERSON_FAILED,
    requestStatusSuccess: REQUEST_STATUS_CHART_BASIC_DASHBOARD_SALES_PERSON_SUCCESS,
    allowParams: DEFAULT_CHART_SALES_PERSON_LIST_PARAMS,
    defaultParams: SECONDARY_DEFAULT_LIST_PARAMS,
    uniqueIdentityID: 'sales_person_id',
    searchParams: DEFAULT_CHART_SALES_PERSON_LIST_SEARCH_PARAMS,
    sortSearchParams: 'sort_by',
    initialSearchParams: {
      ...SECONDARY_DEFAULT_LIST_PARAMS,
      isShowMessage: false,
      from_date: moment(getRangeDateThisMonth().startDate).toISOString(),
      to_date: moment(getRangeDateThisMonth().endDate).toISOString(),
      status: [],
    },
    header: 'typography.sales-person',
    displayDateRangeStaticWrapperAs: DEFAULT_DISPLAY_STATIC_WRAPPER_AS_MOBILE,
    selectedLabelNameDateRange: DEFAULT_LABEL_NAME_THIS_MONTH,
    isFilterDateShow: true,
    defaultKeyStartDate: 'from_date',
    defaultKeyEndDate: 'to_date',
    isFilterCustomShow: false,
    columnsTable: (t) => {
      return [
        {
          name: 'sales_agent_name',
          label: t('table.header-column.contact-name'),
          width: 300,
          format: 'normal',
          isSortColumn: true,
          sortKeyName: 'sales_agent_name',
        },
        {
          name: 'total_transaction',
          label: t('table.header-column.total-transaction'),
          width: 300,
          format: 'normal-currency',
          isSortColumn: true,
          sortKeyName: 'total_transaction',
          align: 'right',
        },
      ];
    },
  },
};

// getting configuration on top, where form chart dashboard basic configuration
export function getChartDashboardBasicConfiguration(
  chartType = CHART_BASIC_DASHBOARD_TYPE_PURCHASE,
) {
  if (!Object.keys(chartBasicDashboardDetailsUtils).includes(chartType)) {
    return chartBasicDashboardDetailsUtils[CHART_BASIC_DASHBOARD_TYPE_PURCHASE];
  }

  return chartBasicDashboardDetailsUtils[chartType];
}

export function getPaperChartListBasicDashboard(
  chartType = CHART_BASIC_DASHBOARD_TYPE_SALES_INVOICE,
) {
  if (!chartListBasicDashboardTypes.includes(chartType)) {
    return paperChartListBasicDashboardUtils[CHART_BASIC_DASHBOARD_TYPE_SALES_INVOICE];
  }

  return paperChartListBasicDashboardUtils[chartType];
}
