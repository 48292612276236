import * as React from 'react';
function SvgIconXlsFileTypeV2(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M18.214 13.571a.357.357 0 100-.714.357.357 0 000 .714z' fill='#000' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.786 0a.357.357 0 00-.357.357v19.286a.357.357 0 00.357.357h16.428a.357.357 0 00.357-.357v-5a.357.357 0 10-.714 0v4.643H2.143V7.143h15.714v4.643a.358.358 0 00.714 0V3.929a.357.357 0 00-.104-.253L14.895.105A.357.357 0 0014.643 0H1.786zm12.5.714H2.143V6.43h15.714V4.286h-2.5a1.071 1.071 0 01-1.071-1.072v-2.5zM15 1.22v1.995a.357.357 0 00.357.357h1.995L15 1.22z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.272 7.857h10.741a1.06 1.06 0 011.058 1.058v4.656h-3.214v4.286H4.272A1.06 1.06 0 013.214 16.8V8.915a1.06 1.06 0 011.058-1.058zm0 9.286h1.442v-1.429H3.93V16.8a.344.344 0 00.343.344zM3.93 15h1.785v-1.429H3.93V15zm0-2.143h1.785V11.43H3.93v1.428zm0-2.143h1.785V8.571H4.272a.344.344 0 00-.343.344v1.8zm2.5 6.429h5.714v-1.429H6.429v1.429zm0-2.143h5.714v-1.429H6.429V15zm0-2.143h5.714V11.43H6.429v1.428zm0-2.143h5.714V8.571H6.429v2.143zm6.428 2.143h2.5V11.43h-2.5v1.428zm0-2.143h2.5V8.915a.344.344 0 00-.343-.344h-2.157v2.143z'
        fill='#000'
      />
      <path
        d='M15.014 17.857A1.06 1.06 0 0016.07 16.8v-3.228h-.714V15h-2.5v.714h2.5V16.8a.344.344 0 01-.343.344h-2.157v.714h2.157zM11.25 2.143h.536a.357.357 0 01.357.357.357.357 0 00.714 0 1.072 1.072 0 00-1.071-1.071h-.536a1.25 1.25 0 100 2.5h.357a.536.536 0 110 1.071h-.536a.357.357 0 01-.357-.357.357.357 0 00-.714 0 1.072 1.072 0 001.071 1.071h.536a1.25 1.25 0 100-2.5h-.357a.536.536 0 110-1.071zM9.286 5H7.5V1.786a.357.357 0 00-.714 0v3.571a.357.357 0 00.357.357h2.143a.357.357 0 100-.714zM5.902 1.48a.357.357 0 00-.49.122l-.766 1.275-.765-1.275a.357.357 0 00-.612.368l.96 1.601-.96 1.602a.357.357 0 10.612.368l.765-1.275.766 1.275a.357.357 0 10.612-.368l-.961-1.602.96-1.601a.357.357 0 00-.121-.49z'
        fill='#000'
      />
    </svg>
  );
}
export default SvgIconXlsFileTypeV2;
