export const dummyLastBillingTransactionsDataList = [
  {
    invoice_id: 1,
    billing_id: 1,
    invoice_number: 'INV0001',
    date: '2022-12-08',
    payment_date: '2022-12-01',
    next_payment_date: '2022-12-31',
    amount: '10000',
    next_amount: '10000',
    status: 'overdue',
    package_type: 'simply',
    amount_per_month: '10000',
    payment_method: 'paypal',
    unit: 'month',
    quantity: 1,
    buyer: 'Genta',
    buyer_address: 'BLUAM',
  },
  {
    invoice_id: 2,
    billing_id: 2,
    invoice_number: 'INV0002',
    date: '2023-01-08',
    payment_date: '2023-01-01',
    next_payment_date: '2023-01-31',
    amount: '20000',
    next_amount: '20000',
    status: 'paid',
    package_type: 'essential',
    amount_per_month: '20000',
    payment_method: 'paypal',
    unit: 'month',
    quantity: 1,
    buyer: 'Besar',
    buyer_address: 'Disine',
  },
  {
    invoice_id: 3,
    billing_id: 3,
    invoice_number: 'INV0003',
    date: '2023-02-08',
    payment_date: '2023-02-01',
    next_payment_date: '2023-02-31',
    amount: '30000',
    next_amount: '30000',
    status: 'pending',
    package_type: 'enterprise',
    amount_per_month: '30000',
    payment_method: 'paypal',
    unit: 'month',
    quantity: 1,
    buyer: 'donat',
    buyer_address: 'DIY',
  },
];
