import React from 'react';
import { Add } from '@mui/icons-material';
import { blue, green, grey, indigo, teal, yellow } from '@mui/material/colors';

import SvgIconPayInvoice from '../../../components/icons/IconPayInvoice';

// props for not found page in verify route
export const additionalPropsBoxContainerNotFound = {
  sx: {
    minHeight: '85vh!important',
    backgroundColor: 'white!important',
  },
};

export const additionalPropsStyleForAddingNewData = {
  color: '#3F51B5',
  backgroundColor: '#E6E6E6',
};

export const additionalPropsScrollIntoView = {
  behavior: 'smooth',
  block: 'end',
  inline: 'nearest',
};

export const additionalPropsBorderBottomTableHeadMain = {
  sx: {
    borderBottom: '1px #E4E7EC solid!important',
  },
};

// blue header table backgound
export const additionalPropsBlueBackgroundHeaderTable = {
  sx: {
    backgroundColor: '#EFF8FF!important',
  },
};

// blue header without border bottom table backgound
export const additionalPropsBlueBackgroundWithoutBorderBottomHeaderTable = {
  sx: {
    backgroundColor: '#EFF8FF!important',
    borderBottom: '0px!important',
  },
};

// blue header cell and reduce padding top and bottom to 4px
export const reducePaddingTableHeaderNBlueBackground = {
  sx: {
    paddingY: '4px',
    borderBottom: '0px!important',
    backgroundColor: '#EFF8FF!important',
  },
};

export const additionalPropsTableHeaderNbluePrimaryBackgroundWithPadding = {
  sx: {
    paddingY: '8px',
    color: 'white!important',
    backgroundColor: blue[700] + '!important',
    '& svg': {
      fill: 'white',
    },
  },
};

export const additionalPropsTableHeaderNlightBluePrimaryBackgroundWithPadding = {
  sx: {
    paddingY: '8px',
    color: blue[800] + '!important',
    backgroundColor: blue[50] + '!important',
    '& svg': {
      fill: blue[800],
    },
  },
};

// addional style for reduce height on table header
export const additionalPropsReduceHeightTableHeader = {
  sx: {
    paddingY: '2px',
  },
};

export const additionalPropsMediumGreyReducePadding = {
  sx: {
    backgroundColor: grey[50] + '!important',
    borderBottom: '2px solid!important',
    borderColor: grey[200] + '!important',
    padding: '6px 8px',
  },
};

export const additionalPropsMediumGreyReducePaddingWithEigthBorderRadius = {
  sx: {
    backgroundColor: grey[50] + '!important',
    borderBottom: '2px solid!important',
    borderColor: grey[200] + '!important',
    padding: '6px 8px',
    borderRadius: '8px 8px 0px 0px',
  },
};

export const additionalPropsMediumGreyReducePaddingToEightWithoutPadding = {
  sx: {
    backgroundColor: grey[50] + '!important',
    borderBottom: '2px solid!important',
    borderColor: grey[200] + '!important',
    padding: '8px',
  },
};

export const additionalPropsMediumGreyEnhancePaddingWithEigthBorderRadius = {
  sx: {
    backgroundColor: grey[50] + '!important',
    borderBottom: '2px solid!important',
    borderColor: grey[200] + '!important',
    borderRadius: '8px 8px 0px 0px',
    padding: '12px 24px',
  },
};

export const additionalPropsMediumGreyEnhancePaddingWithEigthBorderRadiusV2 = {
  sx: {
    ...additionalPropsMediumGreyEnhancePaddingWithEigthBorderRadius.sx,
    padding: '16px',
  },
};

export const additionalPropsMediumGreyReducePaddingWithEigthBorderRadiusReduceFontWeight = {
  sx: {
    ...additionalPropsMediumGreyReducePaddingWithEigthBorderRadius.sx,
    fontWeight: '500!important',
  },
};

export const additionalPropsDarkGreyBackground = {
  sx: {
    backgroundColor: '#667085' + '!important',
    color: '#EFF8FF' + '!important',
  },
};

export const additionalPropsMediumDarkGreyBackground = {
  sx: {
    backgroundColor: grey[50] + '!important',
    borderBottom: '2px solid!important',
    borderColor: grey[200] + '!important',
  },
};

export const additionalPropsMediumDarkGreyBackgroundNreducePadding = {
  sx: {
    backgroundColor: grey[50] + '!important',
    borderBottom: '2px solid!important',
    borderColor: grey[200] + '!important',
    paddingY: '4px',
  },
};

export const additionalPropsMDgreyEightPaddingWithHeight = {
  sx: {
    backgroundColor: grey[50] + '!important',
    padding: '8px',
  },
};

export const additionalPropsGreyBorderTopBottom = {
  sx: {
    borderBottom: '1px solid!important',
    borderTop: '1px solid!important',
    borderColor: grey[200] + '!important',
    fontWeight: 700,
    fontSize: '14px !important',
    height: '18px !important',
  },
};

export const additionalPropsButtonGroupBlueSixMiddleBorder = {
  className: 'blueSixMiddleBorder',
};

export const additionalPropsButtonBlueSixMiddleBorder = {
  className: 'blueSixBorderNwhiteBackground',
};

export const additionalPropsButtonBlueSixMiddleBorderWithPayOnStartIcon = {
  className: 'blueSixBorderNwhiteBackground',
  startIcon: <SvgIconPayInvoice />,
};

export const additionalPropsButtonAddOnStartIcon = {
  startIcon: <Add />,
};

export const additionalGridHeaderPropsMDgreyEightPaddingWithHeight = {
  sx: {
    minHeight: '34px',
    borderRadius: '8px 8px 0px 0px',
    borderBottom: '2px solid!important',
    borderColor: grey[200] + '!important',
    backgroundColor: grey[50] + '!important',
    padding: '8px',
  },
};

export const additionalBoxBodyPrimaryProps = {
  height: 'calc(100vh - 364px)',
  sx: {
    overflowY: 'auto',
    '& div.boxGridRow:nth-of-type(odd)': {
      backgroundColor: 'rgba(204, 233, 255, 0.15)',
    },
    '& div.boxGridRow:hover': {
      backgroundColor: 'rgba(204, 233, 255, 0.50)',
    },
  },
};

export const additionalPropsPrimaryButtonWithUnsetBoxShadowNminWidth = {
  sx: {
    minWidth: '200px',
    boxShadow: 'unset',
    '&:hover': {
      boxShadow: 'unset',
    },
  },
};

export const additionalPropsSecondaryButtonWithUnsetBoxShadowNminWidth = {
  sx: {
    minWidth: '100px',
    boxShadow: 'unset',
    '&:hover': {
      boxShadow: 'unset',
    },
  },
};

export const additionalPropsGradientBackgroundIndigoNteal = {
  sx: {
    background: `linear-gradient(45deg, ${indigo[700]}, ${teal[200]})`,
  },
};

export const responsiveContainerReChartPrimaryProps = {
  width: '100%',
  minHeight: 'inherit',
};

export const verticalBarChartReChartPrimaryProps = {
  layout: 'vertical',
  margin: {
    top: 10,
    right: 10,
    left: 10,
    bottom: 10,
  },
};

export const lineChartReChartPrimaryProps = {
  margin: {
    top: 10,
    right: 20,
    left: 20,
    bottom: 10,
  },
};

export const cartesianGridReChartPrimaryProps = {
  strokeDasharray: '3 3',
  stroke: grey[300],
};

export const axisXYreChartPrimaryProps = {
  style: {
    fontSize: '10px',
    fontFamily: 'Inter',
  },
};

export const barRechartPrimaryProps = {
  startOffset: 'silhouette',
  legendType: 'dot',
  barSize: 20,
  fill: blue[700],
};

export const lineReChartPrimaryProps = {
  strokeWidth: 2.5,
  dot: false,
  legendType: 'circle',
  type: 'monotone',
};

export const lineReChartSecondaryProps = {
  ...lineReChartPrimaryProps,
  stroke: yellow[700],
};

export const lineReChartTersierProps = {
  ...lineReChartPrimaryProps,
  stroke: blue[700],
};

export const lineReChartSuccessProps = {
  ...lineReChartPrimaryProps,
  stroke: green[800],
};

export const lineReChartSuccessPastelProps = {
  ...lineReChartPrimaryProps,
  stroke: '#3CAEA3',
};

export const lineReChartErrorPastelProps = {
  ...lineReChartPrimaryProps,
  stroke: '#ED553B',
};

export const getTextAlignmentForInputBasedExistedValue = (value) => {
  return {
    '& input': {
      textAlign: !value ? 'left!important' : 'right!important',
    },
  };
};

export const absolutePrintButtonPositionPrimary = {
  right: '14px',
  top: '14px',
  display: 'flex',
  gap: '4px',
};

export const additionalOmitComponentProps = {
  sx: {
    display: 'none!important',
  },
  display: 'none',
};

export const additionalDisabledComponentProps = {
  additionalButtonProps: {
    disabled: true,
  },
  disabled: true,
};

export const additionalDisabledButtonBoxHeaderListComponentProps = {
  additionalButtonPrimaryProps: {
    disabled: true,
  },
};

export const additionalDisabledRefreshButtonBoxHeaderListComponentProps = {
  disabledRefreshButton: true,
};

export const additionalOmitExistedButtonBoxTableNotFound = {
  isExistButton: false,
};

export const additionalDisabledRightButtonConfirmationPrimary = {
  isRightButtonDisabled: true,
};

export const additionalDisabledLeftButtonConfirmationPrimary = {
  isLeftButtonDisabled: true,
};

export const maximumGridBreakpoints = {
  xs: 12,
};

export const spaceBetweenStackRowProps = {
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const boxWhiteBackgroundInheritBorderRadius = {
  backgroundColor: 'white',
  borderRadius: 'inherit',
};

export const typographyTitle3LeftAlign = {
  component: 'p',
  variant: 'title3',
  textAlign: 'left',
};

export const circleWhiteBox = {
  position: 'absolute',
  width: 35,
  height: 35,
  backgroundColor: 'white',
  borderRadius: '50%',
};

export const displayFlexAndFlexGrow = {
  display: 'flex',
  flexGrow: 1,
};
