import * as React from 'react';
function SvgIconCsvFileType(props) {
  return (
    <svg width={40} height={40} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20z'
        fill='#D1E9FF'
      />
      <path
        d='M25.37 18.214H14.63a1.06 1.06 0 00-1.059 1.058v7.884a1.06 1.06 0 001.058 1.058h10.742a1.059 1.059 0 001.058-1.058v-7.884a1.06 1.06 0 00-1.058-1.058zM16.072 27.5H14.63a.344.344 0 01-.343-.344v-1.085h1.785V27.5zm0-2.143h-1.785V23.93h1.785v1.428zm0-2.143h-1.785v-1.428h1.785v1.428zm0-2.143h-1.785v-1.799a.344.344 0 01.343-.343h1.442v2.142zM22.5 27.5h-5.714v-1.429H22.5V27.5zm0-2.143h-5.714V23.93H22.5v1.428zm0-2.143h-5.714v-1.428H22.5v1.428zm0-2.143h-5.714V18.93H22.5v2.142zm3.214 6.085a.345.345 0 01-.343.344h-2.157v-1.429h2.5v1.085zm0-1.799h-2.5V23.93h2.5v1.428zm0-2.143h-2.5v-1.428h2.5v1.428zm0-2.143h-2.5V18.93h2.157a.344.344 0 01.343.343v1.8zM15 12.143h.714a.357.357 0 000-.714H15a1.788 1.788 0 00-1.786 1.785v.715A1.788 1.788 0 0015 15.714h.714a.357.357 0 100-.714H15a1.071 1.071 0 01-1.071-1.071v-.715A1.071 1.071 0 0115 12.143zm3.036 0h.535a.357.357 0 01.358.357.357.357 0 10.714 0 1.072 1.072 0 00-1.072-1.071h-.535a1.25 1.25 0 000 2.5h.357a.536.536 0 010 1.071h-.536a.357.357 0 01-.357-.357.357.357 0 10-.714 0 1.072 1.072 0 001.071 1.071h.536a1.25 1.25 0 000-2.5h-.357a.536.536 0 110-1.071zm4.821-.714a.357.357 0 00-.357.357v1.687l-.714 1.19-.715-1.19v-1.687a.357.357 0 10-.714 0v1.785c0 .065.018.129.051.184l1.072 1.786a.357.357 0 00.612 0l1.071-1.786a.357.357 0 00.051-.184v-1.785a.357.357 0 00-.357-.357zM28.214 23.571a.357.357 0 100-.714.357.357 0 000 .714z'
        fill='#1570EF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.786 10a.357.357 0 00-.357.357v19.286a.357.357 0 00.357.357h16.428a.357.357 0 00.357-.357v-5a.357.357 0 10-.714 0v4.643H12.143V17.143h15.714v4.643a.358.358 0 00.714 0v-7.857a.357.357 0 00-.104-.253l-3.572-3.571a.357.357 0 00-.252-.105H11.786zm12.5.714H12.143v5.715h15.714v-2.143h-2.5a1.072 1.072 0 01-1.071-1.072v-2.5zm.714.505v1.995a.357.357 0 00.357.357h1.995L25 11.22z'
        fill='#1570EF'
      />
    </svg>
  );
}
export default SvgIconCsvFileType;
