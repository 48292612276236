import React from 'react';

import useValueFormatter from '../hooks/useValueFormatter';

export default function withValueFormatter(Component) {
  return function valueFormatter(props) {
    const valueFormatterMethod = useValueFormatter();

    return (
      <Component
        {...props}
        withValueFormatterMethod={{
          ...valueFormatterMethod,
        }}
      />
    );
  };
}
