import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { isEmpty } from 'lodash';

import BoxTableNotFound from '../../../components/box/BoxTableNotFound/BoxTableNotFound';
import HiddenButton from '../../../components/button/HiddenButton';
import TablePaginationMain from '../../../components/table-pagination/TablePaginationMain/TablePaginationMain';
import TableRowLoading from '../../../components/table-row/TableRowLoading/TableRowLoading';
import TypographyLinkPrimary from '../../../components/typography/TypographyLinkPrimary/TypographyLinkPrimary';
import { withRouter } from '../../../components/withRouter/withRouter';
import { decryptData, encryptData } from '../../../services/modules/Crypto/Crypto.service';
import { getExpenseList, getExpenseListRefresh } from '../../../store/expense/expense.action';
import messageHelper from '../../../store/message/message.helper';
import { REACT_APP_EXPENSE_DETAILS_TEMPLATE_URL } from '../../../utils/configs/url.config';
import { STATUS_REQUEST_LIST_EXPENSE_PENDING } from '../../../utils/constants/store.constant';
import {
  calculationColumns,
  columnsTableHeaderExpense,
} from '../../../utils/data/display/table-list-expense';
import {
  DEFAULT_DATA_LIST_HEIGHT_LARGE,
  DEFAULT_DATA_LIST_HEIGHT_MIDDLE,
} from '../../../utils/default/height.default';
import {
  DEFAULT_EXPENSE_SEARCH_FE_PARAMS,
  TERSIER_DEFAULT_LIST_PARAMS,
} from '../../../utils/default/params.default';
import { DEFAULT_CLASSNAME_TABLE_CONTAINER_MD_SIZE } from '../../../utils/default/styles-classname.default';
import formatHelp from '../../../utils/helpers/format.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import strHelp from '../../../utils/helpers/string.helpers';

import TableHeadSearchExpense from './TableHeadSearchExpense/TableHeadSearchExpense';
import { styles } from './ExpenseList.styles';

/**
 * @prop    { bool }            isUseChangedPageOnDetails           determine when you might use for changing page details on new page
 *      @default        false
 *
 * @prop    { function }        generateLinkDetails                 generating link for details, this function passing params single data each row on list
 *      @default        null
 *
 * @prop    { string }          dataListHeight                      determine height of list data, you can use 'middle' for resize list table
 *      @default         large
 *
 * @prop    { bool }            isNotFoundButtonShow                determine is showing button create on not found table
 *      @default        true
 *
 * @prop    { bool }            isUseLinkOnNotFoundButton           use link or not in button not found
 *      @default        false
 *
 * @prop    { string }          linkNotFoundButton                  link new create data on button not found
 *      @default        ''
 */

class ExpenseList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage: TERSIER_DEFAULT_LIST_PARAMS.paginate,
    };

    const { generateLinkDetails, handleSelectedStatusPage } = props;

    this.isHandleSelectedStatusPageFunction = typeof handleSelectedStatusPage === 'function';
    this.isGenerateLinkDetailsFunction = typeof generateLinkDetails === 'function';

    this.getSearchParamsExpense = this.getSearchParamsExpense.bind(this);

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

    this.handleClickActionExpense = this.handleClickActionExpense.bind(this);
  }

  handleClickActionExpense(event) {
    const { name: newStatusPage, value } = event.currentTarget;

    const decryptedValue = decryptData(value);

    if (this.isHandleSelectedStatusPageFunction) {
      this.props.handleSelectedStatusPage(newStatusPage, decryptedValue);
    }

    if (this.isGenerateLinkDetailsFunction) {
      if (!this.props.isUseChangedPageOnDetails) return;

      const linkDetails = this.props.generateLinkDetails(decryptedValue);

      if (!linkDetails) return;

      this.props.navigate(linkDetails, { replace: true });
    }
  }

  getSearchParamsExpense() {
    const { expense } = this.props;
    const { expenseList } = expense;

    let searchParamsExpense = objHelper.filterKeyObj(
      expenseList,
      [],
      DEFAULT_EXPENSE_SEARCH_FE_PARAMS,
    );

    if (!isEmpty(searchParamsExpense)) {
      searchParamsExpense = objHelper.changeSuffixKey(searchParamsExpense, 'search_', true);
    }

    return searchParamsExpense;
  }

  async handleChangePage(event, newPage) {
    event.preventDefault();

    const { expense } = this.props;
    const { expenseList } = expense;

    if (!expenseList) {
      return;
    }

    const { next_page_url, current_page, per_page: paginate } = expenseList;

    if (!next_page_url && current_page < newPage + 1) {
      return;
    }

    let searchParamsExpense = this.getSearchParamsExpense();

    await this.props.getExpenseList({
      paginate,
      page: newPage + 1,
      ...searchParamsExpense,
    });
  }

  async handleChangeRowsPerPage(event) {
    const { value: newRowsPerPage } = event.target;

    const { t, expense } = this.props;

    const { expenseList } = expense;

    if (!expenseList) {
      return;
    }

    const { next_page_url, current_page, per_page: paginate } = expenseList;

    if (Number(paginate) === Number(newRowsPerPage)) {
      return;
    }

    if (!next_page_url && paginate < newRowsPerPage && current_page === 1) {
      this.props.setMessageFailed(
        t('dashboard.expense.message.max-data.primary'),
        t('dashboard.expense.message.max-data.secondary'),
      );

      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      rowsPerPage: newRowsPerPage,
    }));

    let searchParamsExpense = this.getSearchParamsExpense();

    await this.props.getExpenseList({
      paginate: newRowsPerPage,
      ...searchParamsExpense,
    });
  }

  async componentDidMount() {
    const { rowsPerPage: paginate } = this.state;

    await this.props.getExpenseList({
      paginate,
      isShowMessage: false,
    });
  }

  render() {
    const { rowsPerPage } = this.state;

    const {
      t,
      classes,
      expense,
      isNotFoundButtonShow,
      isUseLinkOnNotFoundButton,
      linkNotFoundButton,
      dataListHeight,
      isUseChangedPageOnDetails,
      generateLinkDetails,
    } = this.props;

    const { expenseList } = expense;

    let expenseListData = [];

    let currPagination = rowsPerPage;

    if (expenseList) {
      if (expenseList.data) {
        if (Array.isArray(expenseList.data)) {
          expenseListData = expenseList.data;
        }
      }

      if (expenseList.per_page) {
        currPagination = Number(expenseList.per_page);
      }
    }

    const tableHeaderExpense = columnsTableHeaderExpense(t);

    const classTableContainer =
      dataListHeight === DEFAULT_DATA_LIST_HEIGHT_MIDDLE
        ? DEFAULT_CLASSNAME_TABLE_CONTAINER_MD_SIZE
        : classes.tableContainerList;

    return (
      <Box height='100%' className={classes.boxContainerTable}>
        <HiddenButton onClick={this.props.getExpenseListRefresh} />
        <TableContainer className={classTableContainer}>
          <Table stickyHeader className='tableMain'>
            <TableHeadSearchExpense dataHeaders={tableHeaderExpense} />
            <TableBody className='primaryTableBody'>
              {expense.statusRequest === STATUS_REQUEST_LIST_EXPENSE_PENDING && (
                <TableRowLoading
                  rowsAmount={rowsPerPage}
                  columnsAmount={tableHeaderExpense.length}
                />
              )}
              {expenseListData &&
                expense.statusRequest !== STATUS_REQUEST_LIST_EXPENSE_PENDING &&
                expenseListData.map((singleExpense, index) => {
                  const isChangeOnNewPageDetails =
                    this.isGenerateLinkDetailsFunction && isUseChangedPageOnDetails;

                  const baseUrl =
                    !isChangeOnNewPageDetails && !generateLinkDetails(singleExpense)
                      ? strHelp.templateString(
                          REACT_APP_EXPENSE_DETAILS_TEMPLATE_URL,
                          singleExpense,
                        )
                      : generateLinkDetails(singleExpense);

                  return (
                    <TableRow
                      hover
                      key={index}
                      tabIndex={-1}
                      className={'hoverEntireRow'}
                      onDoubleClick={() => this.props.navigate(baseUrl)}
                    >
                      {tableHeaderExpense.map((columnTableHeader, indexColumnHeader) => {
                        let valueData = singleExpense[columnTableHeader.name];

                        // formating date following localzone
                        if (columnTableHeader.name === 'transaction_date') {
                          valueData = formatHelp.getReadableDateV2(valueData);
                        }

                        if (columnTableHeader.name === 'transaction_no') {
                          const valueEncrypted = encryptData(JSON.stringify(singleExpense));

                          const eventTargetData = {
                            currentTarget: {
                              name: 'detail-expense',
                              value: valueEncrypted,
                            },
                          };

                          return (
                            <TableCell key={indexColumnHeader}>
                              <TypographyLinkPrimary
                                baseUrl={baseUrl}
                                searchParamsInObj={
                                  isChangeOnNewPageDetails
                                    ? {
                                        // statusPage: DEFAULT_STATUS_PAGE_DETAIL_SALES_QUOTATION,
                                        expenseDataSelected: valueEncrypted,
                                      }
                                    : null
                                }
                                typographyValue={valueData}
                                onClick={
                                  isUseChangedPageOnDetails
                                    ? null
                                    : () => this.handleClickActionExpense(eventTargetData)
                                }
                              />
                            </TableCell>
                          );
                        }

                        // formating value of nominal column
                        if (calculationColumns.includes(columnTableHeader.name)) {
                          valueData = formatHelp.currencyFormatWithRegex(Number(valueData), 0);
                        }

                        return (
                          <TableCell key={indexColumnHeader} align={columnTableHeader.align}>
                            {valueData}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {!expenseListData.length &&
            expense.statusRequest !== STATUS_REQUEST_LIST_EXPENSE_PENDING && (
              <BoxTableNotFound
                textNotFound={t('dashboard.expense.table.body-not-found')}
                textButton={t('button-menu.actions.create-expense')}
                handleClickedButton={() => {
                  if (isUseLinkOnNotFoundButton && linkNotFoundButton) {
                    this.props.navigate(linkNotFoundButton, { replace: true });
                  }

                  if (!this.isHandleSelectedStatusPageFunction) return;

                  this.props.handleSelectedStatusPage('add-expense');
                }}
                isExistButton={isNotFoundButtonShow}
              />
            )}
        </TableContainer>
        <TablePaginationMain
          page={expenseList ? expenseList.current_page - 1 : 0}
          prev_page={expenseList ? expenseList.prev_page_url : null}
          next_page={expenseList ? expenseList.next_page_url : null}
          rowsPerPage={currPagination}
          isLoadingData={
            expense.statusRequest === STATUS_REQUEST_LIST_EXPENSE_PENDING || !expenseListData.length
          }
          labelRowsPerPage={t('label.table.pagination.primary.rows-per-page')}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    expense: state.expense,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExpenseList: (paramsGetListExpense) => dispatch(getExpenseList(paramsGetListExpense)),
    getExpenseListRefresh: () => dispatch(getExpenseListRefresh()),
    setMessageFailed: (primaryMessage, secondaryMessage) =>
      dispatch(messageHelper.failedMessage(dispatch, primaryMessage, secondaryMessage, false)),
  };
};

ExpenseList.defaultProps = {
  isUseChangedPageOnDetails: false,
  generateLinkDetails: () => null,
  dataListHeight: DEFAULT_DATA_LIST_HEIGHT_LARGE,
  isNotFoundButtonShow: true,
  isUseLinkOnNotFoundButton: false,
  linkNotFoundButton: '',
};

// adding style into component
const stylingExpenseList = withStyles(styles)(ExpenseList);

// adding translation into component
const ExpenseListTranslate = withTranslation()(stylingExpenseList);

const ExpenseListWithRouter = withRouter(ExpenseListTranslate);

// connect component with action and state
const ConnectedExpenseList = connect(mapStateToProps, mapDispatchToProps)(ExpenseListWithRouter);

export default ConnectedExpenseList;
