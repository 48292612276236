import * as React from 'react';
function SvgSalesQuotationMenu(props) {
  return (
    <svg width={97} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#sales-quotation-menu_svg__filter0_d_5586_191872)'>
        <path
          d='M42.782 15.553V9.199C42.782 4.67 62.328 1 67.544 1H20.537C15.32 1 11.09 4.67 11.09 9.199v6.354h31.691z'
          fill='#EAECEF'
        />
        <path
          d='M32.523 69V9.198C32.523 4.67 28.294 1 23.078 1h57.226c5.217 0 9.446 3.67 9.446 8.199V69H32.523z'
          fill='#FAFCFF'
        />
        <path d='M89.75 9.199C89.75 4.67 85.521 1 80.305 1H62.02v68h27.73V9.199z' fill='#EAECEF' />
        <path
          d='M81.884 22.931h-39.33c-.92 0-1.668-.648-1.668-1.448 0-.8.747-1.449 1.669-1.449h39.33c.92 0 1.668.649 1.668 1.449s-.747 1.448-1.669 1.448zM81.884 29.759h-39.33c-.92 0-1.668-.649-1.668-1.449s.747-1.448 1.669-1.448h39.33c.92 0 1.668.648 1.668 1.448 0 .8-.747 1.449-1.669 1.449zM81.884 36.586h-39.33c-.92 0-1.668-.648-1.668-1.448 0-.8.747-1.448 1.669-1.448h39.33c.92 0 1.668.648 1.668 1.448 0 .8-.747 1.448-1.669 1.448zM81.884 43.414h-39.33c-.92 0-1.668-.648-1.668-1.449 0-.8.747-1.448 1.669-1.448h39.33c.92 0 1.668.648 1.668 1.448 0 .8-.747 1.449-1.669 1.449zM81.884 50.242h-39.33c-.92 0-1.668-.649-1.668-1.449s.747-1.448 1.669-1.448h39.33c.92 0 1.668.648 1.668 1.448 0 .8-.747 1.449-1.669 1.449zM81.884 57.069h-39.33c-.92 0-1.668-.648-1.668-1.448 0-.8.747-1.449 1.669-1.449h39.33c.92 0 1.668.649 1.668 1.449s-.747 1.448-1.669 1.448z'
          fill='#808183'
        />
        <path d='M74.018 7.828H50.42v6.827h23.598V7.828z' fill='#C92F00' />
        <path
          d='M81.885 20.034H62.02v2.897h19.864c.921 0 1.668-.648 1.668-1.448 0-.8-.747-1.449-1.668-1.449zM81.885 26.862H62.02v2.897h19.864c.921 0 1.668-.649 1.668-1.449s-.747-1.448-1.668-1.448zM81.885 33.69H62.02v2.896h19.864c.921 0 1.668-.648 1.668-1.448 0-.8-.747-1.448-1.668-1.448zM81.885 40.517H62.02v2.897h19.864c.921 0 1.668-.648 1.668-1.449 0-.8-.747-1.448-1.668-1.448zM81.885 47.345H62.02v2.896h19.864c.921 0 1.668-.648 1.668-1.448 0-.8-.747-1.448-1.668-1.448zM81.885 54.172H62.02v2.897h19.864c.921 0 1.668-.648 1.668-1.449 0-.8-.747-1.448-1.668-1.448z'
          fill='#59595B'
        />
        <path d='M74.019 7.828h-12v6.827h12V7.828z' fill='#930000' />
        <path
          d='M22.633 60.423h2.4v7.71c0 .479-.537.867-1.2.867-.663 0-1.2-.388-1.2-.867v-7.71z'
          fill='#FFC61B'
        />
        <path
          d='M23.753 60.423v8.571c.027.001.053.006.08.006.663 0 1.2-.388 1.2-.867v-7.71h-1.28zM24.956 56.188l2.286-.529 5.423 12.21c.202.456-.146.943-.777 1.09-.631.145-1.307-.105-1.51-.561l-5.422-12.21z'
          fill='#EAA22F'
        />
        <path
          d='M22.71 56.188l-2.286-.529-5.422 12.21c-.203.456.145.943.776 1.09.631.145 1.307-.105 1.51-.561l5.421-12.21z'
          fill='#FFC61B'
        />
        <path d='M43.636 38.028H4.03V59.86h39.606V38.028z' fill='#fff' />
        <path d='M43.636 38.028H23.754V59.86h19.882V38.028z' fill='#D0D1D3' />
        <path
          d='M25.814 36.859h-3.961v-1.43c0-.789.887-1.429 1.98-1.429 1.094 0 1.98.64 1.98 1.43v1.429z'
          fill='#FFC61B'
        />
        <path
          d='M25.814 35.43c0-.79-.887-1.43-1.98-1.43-.027 0-.054.002-.08.003v2.856h2.06v-1.43z'
          fill='#EAA22F'
        />
        <path
          d='M44.076 39.184H3.59c-1.016 0-1.84-.595-1.84-1.329 0-.733.824-1.328 1.84-1.328h40.486c1.017 0 1.84.594 1.84 1.328 0 .734-.824 1.329-1.84 1.329zM44.076 61.073H3.59c-1.016 0-1.84-.595-1.84-1.328 0-.734.824-1.329 1.84-1.329h40.486c1.017 0 1.84.595 1.84 1.329 0 .733-.824 1.328-1.84 1.328z'
          fill='#71E2EF'
        />
        <path
          d='M44.076 36.527H23.753v2.657h20.323c1.017 0 1.84-.595 1.84-1.329s-.823-1.328-1.84-1.328zM44.076 58.416H23.753v2.657h20.323c1.017 0 1.84-.595 1.84-1.328 0-.734-.823-1.329-1.84-1.329z'
          fill='#38C6D9'
        />
        <path
          d='M36.924 45.44l-10.17 5.673-9.947-2.394L8.5 53.304l.73.688 7.8-4.306 9.952 2.395 10.676-5.955-.734-.686z'
          fill='#59595B'
        />
        <path
          d='M17.03 49.686l-7.8 4.306-.73-.688 8.307-4.585 6.947 1.672v.913l-6.723-1.618z'
          fill='#59595B'
        />
        <path
          d='M26.981 52.08l-3.228-.776v-.913l3.001.722 10.17-5.673.733.686-10.676 5.955z'
          fill='#272525'
        />
        <path
          d='M26.867 50.4c.916 0 1.658.536 1.658 1.197s-.742 1.197-1.658 1.197c-.916 0-1.658-.536-1.658-1.197s.742-1.197 1.658-1.197z'
          fill='#C92F00'
        />
        <path
          d='M16.918 48.005c.916 0 1.658.536 1.658 1.197 0 .662-.742 1.197-1.658 1.197-.916 0-1.658-.535-1.658-1.197 0-.66.742-1.197 1.658-1.197z'
          fill='#FF7F4F'
        />
        <path
          d='M37.29 44.585c.915 0 1.658.536 1.658 1.197s-.743 1.197-1.659 1.197c-.915 0-1.658-.536-1.658-1.197 0-.66.743-1.197 1.658-1.197z'
          fill='#EAA22F'
        />
        <path
          d='M8.865 52.452c.916 0 1.658.535 1.658 1.196 0 .661-.742 1.197-1.658 1.197-.916 0-1.658-.535-1.658-1.197 0-.66.742-1.197 1.658-1.197z'
          fill='#FF314F'
        />
      </g>
      <defs>
        <filter
          id='sales-quotation-menu_svg__filter0_d_5586_191872'
          x={0.75}
          y={0}
          width={96.001}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_191872' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_191872' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgSalesQuotationMenu;
