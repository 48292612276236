// cash bank
export const DEFAULT_TRANSACTION_NUMBER_CASH_TRANSFER = 'CT0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_CASH_TRANSFER = 'CT';

export const DEFAULT_TRANSACTION_NUMBER_CASH_RECEIPT = 'CR0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_CASH_RECEIPT = 'CR';

export const DEFAULT_TRANSACTION_NUMBER_CASH_PAYMENT = 'CP0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_CASH_PAYMENT = 'CP';

// purchase
export const DEFAULT_TRANSACTION_NUMBER_PURCHASE_QUOTATION_REQUEST = 'QR0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_QUOTATION_REQUEST = 'QR';

export const DEFAULT_TRANSACTION_NUMBER_PURCHASE_DELIVERY = 'PD0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_DELIVERY = 'PD';

export const DEFAULT_TRANSACTION_NUMBER_PURCHASE_PAYMENT = 'PP0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_PAYMENT = 'PP';

export const DEFAULT_TRANSACTION_NUMBER_PURCHASE_ORDER = 'PO0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_ORDER = 'PO';

export const DEFAULT_TRANSACTION_NUMBER_PURCHASE_INVOICE = 'PI0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_INVOICE = 'PI';

export const DEFAULT_TRANSACTION_NUMBER_PURCHASE_RETURN = 'PR0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_RETURN = 'PR';

export const DEFAULT_TRANSACTION_NUMBER_PURCHASE_DEBIT_NOTE = 'PDN0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_DEBIT_NOTE = 'PDN';

// sales
export const DEFAULT_TRANSACTION_NUMBER_SALES_QUOTATION = 'SQ0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_SALES_QUOTATION = 'SQ';

export const DEFAULT_TRANSACTION_NUMBER_SALES_ORDER = 'SO0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_SALES_ORDER = 'SO';

export const DEFAULT_TRANSACTION_NUMBER_SALES_DELIVERY = 'SD0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_SALES_DELIVERY = 'SD';

export const DEFAULT_TRANSACTION_NUMBER_SALES_INVOICE = 'SI0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_SALES_INVOICE = 'SI';

export const DEFAULT_TRANSACTION_NUMBER_SALES_PAYMENT = 'SP0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_SALES_PAYMENT = 'SP';

export const DEFAULT_TRANSACTION_NUMBER_SALES_RETURN = 'SR0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_SALES_RETURN = 'SR';

export const DEFAULT_TRANSACTION_NUMBER_SALES_CREDIT_NOTE = 'SCN0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_SALES_CREDIT_NOTE = 'SCN';

// minutes handover
export const DEFAULT_TRANSACTION_NUMBER_MINUTES_OF_HANDOVER = 'MH0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_MINUTES_OF_HANDOVER = 'MH';

export const DEFAULT_TRANSACTION_NUMBER_SALES_MINUTES_OF_HANDOVER = 'SH0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_SALES_MINUTES_OF_HANDOVER = 'SH';

export const DEFAULT_TRANSACTION_NUMBER_PURCHASE_MINUTES_OF_HANDOVER = 'PH0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_MINUTES_OF_HANDOVER = 'PH';

// expense
export const DEFAULT_TRANSACTION_NUMBER_EXPENSE = 'EX0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_EXPENSE = 'EX';

// payable - receivable payment
export const DEFAULT_TRANSACTION_NUMBER_RECEIVABLE_PAYMENT = 'RF0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_RECEIVABLE_PAYMENT = 'RF';

export const DEFAULT_TRANSACTION_NUMBER_PAYABLE_PAYMENT = 'SF0001';
export const DEFAULT_TRANSACTION_NUMBER_CODE_PAYABLE_PAYMENT = 'SF';

// general journal
export const DEFAULT_TRANSACTION_NUMBER_CODE_GENERAL_JOURNAL = 'GJ';

// closing book
export const DEFAULT_TRANSACTION_NUMBER_CODE_CLOSING_BOOK = 'CB';
