import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import moment from 'moment-timezone';

import './i18n';

import { theme } from './assets/theme/theme';
import { DEFAULT_TIMEZONE } from './utils/default/timezone.default';
import SuspensePage from './views/Suspense/SuspensePage/SuspensePage';
import store from './store';

import './App.css';
import './index.css';

moment.tz.setDefault(DEFAULT_TIMEZONE);

const App = lazy(() => import('./App'));

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <Suspense fallback={<SuspensePage />}>
            <App />
          </Suspense>
        </Router>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root'),
);
