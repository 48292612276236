import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import {
  noBorderTableData,
  primaryParagraph,
  primaryTableData,
} from '../../../utils/data/display/additional-styles';
import formatHelp from '../../../utils/helpers/format.helpers';

export const FORMAT_PLAIN_COLUMN = 'plain';
export const FORMAT_DATE_COLUMN = 'date';
export const FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN = 'calculation-number-auto-comma';

/**
 * @prop        { object }          data        provider for view table data user
 * @prop        { array }           rowsTableBody       list of rows table body that each items contain array
 *          data structure
 *          [[{}, {}], [{}, ...]]
 *          in each items of rowsTableBody is representation for table row or tr, suppose trItem
 *          in each items of trItem is representaton for table data for table row
 *              using array on each items rowsTableBody
 *              use object for each items trItem, representation for single table data
 *
 *          each tabl data minimum contain:
 *          - isLabel               { bool }        determine using label
 *              default false
 *              -- must add on items object when use this:
 *                  - label             { string }      provide label that not translated
 *                  - labelTranslated   { string }      label that already translated
 *
 *          - isEmptyTableData      { bool }        determine is only for empty table data
 *              default false
 *              -- must add on items object when isEmptyTableData is true:
 *                  - colSpan       { number }      amount colSpan
 *
 *          - isValue               { bool }        determine is data view is from data props
 *              default false
 *              -- must add on items object when isValue is true:
 *                  - valueKeyName    { string }        name key of value from data props
 *                  - formatValue     { string }        autoformatting data, default is plain
 *                                                      existed format data: 'plain', 'date', 'calculation-number-auto-comma'
 *
 *              -- additional
 *                  - substituteNotExistValue   { string }      change on subtitute when data not exist or null
 *                      default '-'
 *
 *          additional:
 *              tableDataStyle      { object }      styling for table data
 *              typographyStyle     { object }      style of typography
 *
 */
class TableRowMultipleTableDataPureMarkup extends Component {
  render() {
    const { t, data = {}, rowsTableBody = [] } = this.props;

    if (!Array.isArray(rowsTableBody) || isEmpty(rowsTableBody)) return null;

    return (
      <>
        {Array.isArray(rowsTableBody) &&
          rowsTableBody.map((tableRowItems, index) => {
            return (
              <tr key={index}>
                {Array.isArray(tableRowItems) &&
                  tableRowItems.length &&
                  tableRowItems.map((singleTableRowData, indexTableRow) => {
                    const {
                      isLabel = false,
                      isEmptyTableData = false,
                      isValue = false,
                      colSpan = 1,
                      label,
                      labelTranslated,
                      valueKeyName,
                      formatValue,
                      substituteNotExistValue = '',
                      tableDataStyle,
                      typographyStyle,
                      align = 'left',
                    } = singleTableRowData;

                    let defaultTableDataStyle = isEmpty(tableDataStyle)
                        ? primaryTableData
                        : tableDataStyle,
                      defaultTypographyStyle = isEmpty(typographyStyle)
                        ? primaryParagraph
                        : typographyStyle;

                    if (isEmptyTableData) {
                      defaultTableDataStyle = noBorderTableData;

                      return (
                        <td key={indexTableRow} colSpan={colSpan} style={defaultTableDataStyle} />
                      );
                    }

                    let valueView = isLabel
                      ? labelTranslated || t(label)
                      : data[valueKeyName] || substituteNotExistValue;

                    if (isValue) {
                      if (formatValue === FORMAT_DATE_COLUMN) {
                        const isDateValid = !Number.isNaN(new Date(valueView).getTime());

                        valueView = isDateValid
                          ? formatHelp.getReadableDateV2(valueView)
                          : valueView;
                      }

                      if (formatValue === FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN) {
                        const isNumberValid = !Number.isNaN(Number(valueView));

                        valueView = isNumberValid
                          ? formatHelp.currencyWithAutoComma(Number(valueView))
                          : 0;
                      }
                    }

                    return (
                      <td
                        colSpan={colSpan}
                        key={indexTableRow}
                        style={defaultTableDataStyle}
                        align={align}
                      >
                        <p style={defaultTypographyStyle}>{valueView}</p>
                      </td>
                    );
                  })}
              </tr>
            );
          })}
      </>
    );
  }
}

const TableRowMultipleTableDataPureMarkupWithTranslation = withTranslation()(
  TableRowMultipleTableDataPureMarkup,
);

export default TableRowMultipleTableDataPureMarkupWithTranslation;
