import React from 'react';
import { isEmpty, isEqual } from 'lodash';

import ListItemProduct, {
  defaultListItemProductPurchaseProps,
} from '../../../components/list-item/ListItemProduct/ListItemProduct';
import { getListProductBySupplier } from '../../../store/product/product.action';
import { clearDataProductSlice } from '../../../store/product/product.slice';
import {
  NAME_STORE,
  SLICE_NAME_DATA_PRODUCT_BY_SUPPLIER,
  STATUS_REQUEST_LIST_PRODUCT_BY_SUPPLIER_PENDING,
} from '../../constants/store.constant';
import {
  DEFAULT_ACTION_MENU_ADD,
  DEFAULT_ACTION_MENU_UPDATE,
} from '../../default/action-menu.default';
import { DEFAULT_GRID_ACTION_COLLAPSE_TYPE } from '../../default/grid-type.default';
import {
  DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
  DEFAULT_INPUT_TYPE_CALCULATION_NUMBER_INPUT,
  DEFAULT_INPUT_TYPE_COMMON_INPUT,
} from '../../default/input-type.default';
import {
  DEFAULT_KEY_NAME_IS_PURCHASED_API_REF,
  DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
  DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER,
  DEFAULT_KEY_NAME_PRODUCT_NAME_API_REF,
  DEFAULT_KEY_NAME_QUANTITY_AVAILABLE_WITH_UNIT,
  DEFAULT_KEY_NAME_STATUS_REQUEST,
} from '../../default/object-keyname.default';
import { DEFAULT_PAYLOAD_STORAGE_PRODUCT_DATA_BY_SUPPLIER } from '../../default/payload-storage.default';
import { DEFAULT_CLASSNAME_INPUT_CENTER } from '../../default/styles-classname.default';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';
import autocompleteHelper from '../../helpers/component/autocomplete.helpers';

import { getUnitProductAutocompleteTransactionPropertiesPrimary } from './unit-product-properties';

// column header add/update purchase delivery list that handling table head with translation
export const columnsTableHeaderAddUpdatePurchaseDelivery = (t, menuName) => {
  return [
    {
      name: 'product_id',
      label: t(`dialog.${menuName}.table.header-column.product`),
      minWidth: 175,
    },
    {
      name: 'product_description',
      label: t(`dialog.${menuName}.table.header-column.description`),
      width: 550,
    },
    {
      name: 'quantity',
      label: t(`dialog.${menuName}.table.header-column.qty`),
      minWidth: 120,
      align: 'right',
    },
    {
      name: 'unit',
      label: t(`dialog.${menuName}.table.header-column.unit`),
      width: 100,
      align: 'right',
    },
    {
      name: 'action',
      label: '',
      align: 'right',
      width: 10,
    },
  ];
};

// grid header quotation request add update
export const gridHeaderProductPurchaseDelivery = [
  {
    name: DEFAULT_GRID_ACTION_COLLAPSE_TYPE,
    label: '',
    align: 'left',
    width: 20,
    gridProps: {
      xs: 0.75,
    },
    isButton: true,
  },
  {
    label: 'table.header-column.product-name',
    placeholder: 'placeholder.select-product',
    isUseMultipleNames: true,
    width: 250,
    minWidth: 150,
    gridProps: {
      xs: 7.5,
      display: 'flex',
      justifyContent: 'start',
    },
    isInput: true,
    storeName: NAME_STORE.PRODUCT,
    childrenStoreName: SLICE_NAME_DATA_PRODUCT_BY_SUPPLIER,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    keyUniqueName: DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
    keyInputName: DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
    keyInputContainerName: DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER,
    statusRequestListPending: STATUS_REQUEST_LIST_PRODUCT_BY_SUPPLIER_PENDING,
    inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
    autocompleteProps: {
      fetchAction: getListProductBySupplier,
      additionalParamsForAction: {
        [DEFAULT_KEY_NAME_IS_PURCHASED_API_REF]: 1,
      },
      removeDataAction: clearDataProductSlice,
      additionalParamsForRemoveDataAction: DEFAULT_PAYLOAD_STORAGE_PRODUCT_DATA_BY_SUPPLIER,
      additionalParamsForScrollAction: {
        isRewriteAll: false,
        [DEFAULT_KEY_NAME_IS_PURCHASED_API_REF]: 1,
      },
      getOptionLabel: (options) =>
        autocompleteHelper.getOptionLabel(options, DEFAULT_KEY_NAME_PRODUCT_NAME_API_REF),
      isOptionEqualToValue: (option, value) => isEqual(option, value),
      isUseFetchOnInput: true,
      keyNameFetchInput: DEFAULT_KEY_NAME_PRODUCT_NAME_API_REF,
      renderOption: (props, option) => (
        <ListItemProduct
          option={option}
          renderOptionProps={props}
          productProps={defaultListItemProductPurchaseProps}
        />
      ),
    },
    isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
      const { status, purchase_order_id } = transactionData[0];

      return (
        (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
          !statusFreeUpdateInputTransaction.includes(status)) ||
        (actionMenu === DEFAULT_ACTION_MENU_ADD && purchase_order_id) ||
        actionMenu === DEFAULT_ACTION_MENU_UPDATE
      );
    },
  },
  {
    name: 'quantity',
    label: 'table.header-column.product-quantity',
    placeholder: 'placeholder.calculation-number-default',
    align: 'left',
    width: 50,
    minWidth: 50,
    gridProps: {
      xs: 1.5,
      display: 'flex',
      justifyContent: 'center',
    },
    isInput: true,
    inputType: DEFAULT_INPUT_TYPE_CALCULATION_NUMBER_INPUT,
    keyInputName: 'quantity',
    keyMaximumInput: 'quantity_available_const',
    isUseFooterInput: true,
    footerInputLabel: 'typography.stock',
    footerInputKeyName: DEFAULT_KEY_NAME_QUANTITY_AVAILABLE_WITH_UNIT,
    additionalOutlinedInput: {
      className: DEFAULT_CLASSNAME_INPUT_CENTER,
    },
    isReadOnly: ({ rowSelectedContainerData }) => {
      return isEmpty(rowSelectedContainerData[DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER]);
    },
    isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
      const { status } = transactionData[0];

      return (
        actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
        !statusFreeUpdateInputTransaction.includes(status)
      );
    },
  },
  {
    name: 'unit',
    label: 'table.header-column.unit',
    align: 'left',
    width: 50,
    minWidth: 50,
    gridProps: {
      xs: 2,
      display: 'flex',
      justifyContent: 'start',
    },
    ...getUnitProductAutocompleteTransactionPropertiesPrimary({}),
    isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
      const { status, purchase_order_id } = transactionData[0];

      return (
        !hasImplementedUnitProductUnit ||
        (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
          !statusFreeUpdateInputTransaction.includes(status)) ||
        (actionMenu === DEFAULT_ACTION_MENU_ADD && purchase_order_id) ||
        actionMenu === DEFAULT_ACTION_MENU_UPDATE
      );
    },
  },
];

export const gridCollapseProductPurchaseDelivery = [
  {
    gridProps: {
      xs: 0.75,
    },
  },
  {
    // label: 'test',
    placeholder: 'label.description',
    inputName: 'memo',
    keyInputName: 'product_description',
    inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
    gridProps: {
      xs: 10.5,
    },
  },
  {
    gridProps: {
      xs: 0.75,
    },
  },
];
