import { createAsyncThunk } from '@reduxjs/toolkit';

import managementCompanyServices from '../../services/API/management-company/management-company.service';
import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_MANAGEMENT_COMPANIES } from '../../utils/constants/storage.constant';
import {
  creatorAddAction,
  creatorDeleteAction,
  creatorUpdateAction,
} from '../creator-action/creator-action.helper';

export const listCompany = createAsyncThunk(
  'management-company/list',
  async ({ page, paginate }, thunkAPI) => {
    try {
      const response = await managementCompanyServices.listCompany({ page, paginate });

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response.data;

      LocalStorage.set(LOCAL_STORAGE_MANAGEMENT_COMPANIES, data);

      return { companies: data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// action add new company
export const addCompany = createAsyncThunk(
  'management-company/add',
  async (bodyAddCompany, thunkAPI) => {
    return await creatorAddAction(
      thunkAPI,
      bodyAddCompany,
      managementCompanyServices.addCompany,
      'success.add.management-company',
      'error.add.management-company',
    );
  },
);

// action update company
export const updateCompany = createAsyncThunk(
  'management-company/update',
  async (bodyUpdateCompany, thunkAPI) => {
    const { company_name } = bodyUpdateCompany;

    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdateCompany,
      managementCompanyServices.updateCompany,
      'success.update.management-company',
      'error.update.management-company',
      {},
      { company_name },
    );
  },
);

// action for deleting single company, that you can passing company_id on params
export const deleteCompanyManagement = createAsyncThunk(
  'management-company/delete',
  async (paramsDeleteCompanyManagement, thunkAPI) => {
    return await creatorDeleteAction(
      thunkAPI,
      paramsDeleteCompanyManagement,
      managementCompanyServices.deleteCompany,
      'success.delete.management-company',
      'error.delete.management-company',
      paramsDeleteCompanyManagement,
      paramsDeleteCompanyManagement,
    );
  },
);
