import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  NAME_STORE,
  SLICE_NAME_HANDOVER_PURCHASE_DELIVERY_DETAILS,
  SLICE_NAME_HANDOVER_SALES_DELIVERY_DETAILS,
  STATUS_REQUEST_BASE_IDDLE,
} from '../../utils/constants/store.constant';
import {
  getHandoverConfiguration,
  MINUTES_OF_HANDOVER_TYPE_KEY_NAME,
} from '../../utils/default/minutes-of-handover-config.default';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import minutesOfHandoverActions from './minutes-of-handover.action';

const initialState = {
  [SLICE_NAME_HANDOVER_PURCHASE_DELIVERY_DETAILS]: null,
  [SLICE_NAME_HANDOVER_SALES_DELIVERY_DETAILS]: null,
  requestStatus: STATUS_REQUEST_BASE_IDDLE,
};

const minutesOfHandoverSlice = createSlice({
  name: NAME_STORE.MINUTES_OF_HANDOVER,
  initialState,
  reducers: {
    minutesOfHandoverActions,
    clearDataMinutesOfHandoverSlice: (state, action) => {
      const { sliceName, localStorageName } = action.payload;

      if (LocalStorage.get(sliceName)) {
        LocalStorage.remove(sliceName);
      } else if (localStorageName) {
        if (LocalStorage.get(localStorageName)) LocalStorage.remove(localStorageName);
      }

      return {
        ...state,
        [sliceName]: null,
        requestStatus: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState),
  },
  extraReducers: (builder) => {
    // reducer for add minutes of handover, purchase delivery or sales delivery
    builder.addCase(minutesOfHandoverActions.addMinutesOfHandover.pending, (state, action) => {
      const actionName = action.meta.arg['actionName'] || 'create';

      const { requestStatusPending } = getHandoverConfiguration({
        actionName: actionName,
        minutesHandoverType: action.meta.arg[MINUTES_OF_HANDOVER_TYPE_KEY_NAME],
      });

      state.requestStatus = requestStatusPending;
    }),
      builder.addCase(minutesOfHandoverActions.addMinutesOfHandover.fulfilled, (state, action) => {
        const actionName = action.meta.arg['actionName'] || 'create';

        const { requestStatusSuccess } = getHandoverConfiguration({
          actionName: actionName,
          minutesHandoverType: action.meta.arg[MINUTES_OF_HANDOVER_TYPE_KEY_NAME],
        });

        state.requestStatus = requestStatusSuccess;
      }),
      builder.addCase(minutesOfHandoverActions.addMinutesOfHandover.rejected, (state, action) => {
        const actionName = action.meta.arg['actionName'] || 'create';

        const { requestStatusFailed } = getHandoverConfiguration({
          actionName: actionName,
          minutesHandoverType: action.meta.arg[MINUTES_OF_HANDOVER_TYPE_KEY_NAME],
        });

        state.requestStatus = requestStatusFailed;
      }),
      // reducer for minutes of handover details or history, purchase delivery or sales delivery
      builder.addCase(
        minutesOfHandoverActions.minutesOfHandoverDetails.pending,
        (state, action) => {
          const actionName = action.meta.arg['actionName'] || 'detail';

          const { requestStatusPending } = getHandoverConfiguration({
            actionName,
            minutesHandoverType: action.meta.arg[MINUTES_OF_HANDOVER_TYPE_KEY_NAME],
          });

          state.requestStatus = requestStatusPending;
        },
      ),
      builder.addCase(
        minutesOfHandoverActions.minutesOfHandoverDetails.fulfilled,
        (state, action) => {
          const actionName = action.meta.arg['actionName'] || 'detail';

          const { requestStatusSuccess, sliceName } = getHandoverConfiguration({
            actionName,
            minutesHandoverType: action.meta.arg[MINUTES_OF_HANDOVER_TYPE_KEY_NAME],
          });

          state[sliceName] = action.payload[sliceName];
          state.requestStatus = requestStatusSuccess;
        },
      ),
      builder.addCase(
        minutesOfHandoverActions.minutesOfHandoverDetails.rejected,
        (state, action) => {
          const actionName = action.meta.arg['actionName'] || 'detail';

          const { requestStatusFailed, sliceName } = getHandoverConfiguration({
            actionName,
            minutesHandoverType: action.meta.arg[MINUTES_OF_HANDOVER_TYPE_KEY_NAME],
          });

          state[sliceName] = null;
          state.requestStatus = requestStatusFailed;
        },
      );
  },
});

export const { reducer, actions } = minutesOfHandoverSlice;

export const { clearDataMinutesOfHandoverSlice } = actions;

export default reducer;
