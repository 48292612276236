export const DEFAULT_PARAMS_PAGINATE_PAGE = {
  page: 1,
  paginate: 20,
};

export const SECONDARY_DEFAULT_LIST_PARAMS = {
  page: 1,
  paginate: 10,
};

export const TERSIER_DEFAULT_LIST_PARAMS = {
  ...SECONDARY_DEFAULT_LIST_PARAMS,
  paginate: 100,
};

export const DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION = {
  page: 1,
  paginate: 20,
  isRewriteAll: true,
  isShowMessage: true,
  usagePagination: true,
};

export const DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION_SECONDARY = {
  paginate: 0,
  isRewriteAll: true,
  isShowMessage: true,
};

export const DEFAULT_IGNORE_PARAMS = ['page', 'paginate'];

export const DEFAULT_BASE_LIST_ACTION_PARAMS = ['page', 'paginate'];

export const DEFAULT_SEARCH_PARAM_ACTIONS = ['search_page', 'search_paginate'];

export const DEFAULT_ACTION_PARAMS = ['page', 'paginate', 'isRewriteAll'];

export const DEFAULT_ACTION_SECONDARY_PARAMS = ['paginate', 'isRewriteAll'];

export const DEFAULT_ACTION_FROM_TO_DATE_PARAMS = ['from_date', 'to_date'];

export const DEFAULT_DATE_ONLY_PARAMS = ['date'];

export const DEFAULT_DATE_ONLY_SEARCH_PARAMS = ['search_date'];
export const DEFAULT_FROM_TO_DATE_SEARCH_PARAMS = ['search_from_date', 'search_to_date'];

export const DEFAULT_PRODUCT_LIST_PARAMS = [
  'page',
  'paginate',
  'type',
  'product_id',
  'product_name',
  'product_code',
  'contact_id',
  'category_name',
  'warehouse_id',
];

export const DEFAULT_PRODUCT_PURCHASE_SALE_LIST_PARAMS = [
  'page',
  'paginate',
  'type',
  'product_id',
  'product_name',
  'product_code',
  'contact_id',
];

export const DEFAULT_PRODUCT_BY_SUPPLIER_LIST_PARAMS = [
  'page',
  'paginate',
  'type',
  'product_id',
  'product_name',
  'product_code',
  'contact_id',
  'is_purchased',
  'is_for_sale',
  'warehouse_id',
];

export const DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS = [
  'account_name',
  'category_name',
  'category_name_multiple',
];

export const DEFAULT_ACCOUNT_DATA_LIST_PARAMS = [
  'page',
  'paginate',
  'account_name',
  'account_code',
  'category_name',
  'category_name_multiple',
];

export const DEFAULT_ACCOUNT_DATA_LIST_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_account_name',
  'search_category_name',
  'search_account_code',
];

export const DEFAULT_ACCOUNT_CATEGORY_LIST_PARAMS = [
  'page',
  'paginate',
  'category_name',
  'category_type',
  'default_code',
];

export const DEFAULT_ACCOUNT_PARENT_LIST_PARAMS = [
  'page',
  'paginate',
  'is_paginate',
  'account_name',
  'transaction_no',
  'transaction_date',
  'transaction_value',
  'from_date',
  'to_date',
];

export const DEFAULT_ACCOUNT_SUB_LIST_PARAMS = ['page', 'paginate'];

export const DEFAULT_CONTACT_ID_ON_SALES_INVOICE_LIST_PARAMS = [
  'page',
  'paginate',
  'status',
  'status_delivery',
];

export const DEFAULT_CASH_BANK_INDEX_LIST_PARAMS = [
  'page',
  'paginate',
  'account_code',
  'account_name',
];

export const DEFAULT_CONTACT_ID_ON_PURCHASE_INVOICE_LIST_PARAMS = ['page', 'paginate', 'status'];

export const DEFAULT_CASH_PAYMENT_PARAMS = [
  'page',
  'paginate',
  'from_date',
  'to_date',
  'transaction_no',
];

export const DEFAULT_SEARCH_BE_CASH_PAYMENT = ['to_date', 'from_date', 'transaction_no'];

export const DEFAULT_SEARCH_FE_CASH_PAYMENT = [
  'per_page',
  'current_page',
  'search_to_date',
  'search_from_date',
  'search_transaction_no',
];

export const DEFAULT_CASH_BANK_RECEIPT_CASH_LIST_PARAMS = [
  'page',
  'paginate',
  'transaction_no',
  'from_date',
  'to_date',
];

export const DEFAULT_CASH_RECEIPT_PARAMS = [
  'page',
  'paginate',
  'from_date',
  'to_date',
  'transaction_no',
];

export const DEFAULT_SEARCH_BE_CASH_RECEIPT_PARAMS = ['to_date', 'from_date', 'transaction_no'];

export const DEFAULT_SEARCH_FE_CASH_RECEIPT_PARAMS = [
  'per_page',
  'current_page',
  'search_to_date',
  'search_from_date',
  'search_transaction_no',
];

export const DEFAULT_CASH_TRANSFER_PARAMS = [
  'page',
  'paginate',
  'from_date',
  'to_date',
  'transaction_no',
];

export const DEFAULT_SEARCH_BE_CASH_TRANSFER_PARAMS = ['to_date', 'from_date', 'transaction_no'];

export const DEFAULT_SEARCH_FE_CASH_TRANSFER_PARAMS = [
  'per_page',
  'current_page',
  'search_to_date',
  'search_from_date',
  'search_transaction_no',
];

export const DEFAULT_PURCHASE_ORDER_LIST_PARAMS = [
  'page',
  'paginate',
  'purchase_order_no',
  'supplier_name',
  'supplier_id',
  'status',
  'invoice_status',
  'from_date',
  'to_date',
];

export const DEFAULT_PURCHASE_ORDER_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_purchase_order_no',
  'search_supplier_name',
  'search_supplier_id',
  'search_status',
  'search_invoice_status',
  'search_from_date',
  'search_to_date',
];

export const DEFAULT_PURCHASE_QR_LIST_PARAMS = [
  'page',
  'paginate',
  'transaction_no',
  'supplier_name',
  'supplier_id',
  'status',
  'from_date',
  'to_date',
];

export const DEFAULT_PURCHASE_QR_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_transaction_no',
  'search_supplier_name',
  'search_supplier_id',
  'search_status',
  'search_from_date',
  'search_to_date',
];

export const DEFAULT_PURCHASE_PAYMENT_LIST_PARAMS = [
  'page',
  'paginate',
  'transaction_no',
  'supplier_name',
  'from_date',
  'to_date',
];

export const DEFAULT_PURCHASE_PAYMENT_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_transaction_no',
  'search_supplier_name',
  'search_from_date',
  'search_to_date',
];

export const DEFAULT_PURCHASE_DELIVERY_LIST_PARAMS = [
  'page',
  'paginate',
  'purchase_delivery_no',
  'supplier_name',
  'supplier_id',
  'status',
  'purchase_handover_status',
  'from_date',
  'to_date',
];

export const DEFAULT_PURCHASE_DELIVERY_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_purchase_delivery_no',
  'search_supplier_name',
  'search_supplier_id',
  'search_status',
  'search_purchase_handover_status',
  'search_from_date',
  'search_to_date',
];

export const DEFAULT_PURCHASE_INVOICE_LIST_PARAMS = [
  'page',
  'paginate',
  'supplier_id',
  'supplier_name',
  'transaction_no',
  'from_date',
  'to_date',
  'sort_by',
  'status',
  'status_delivery',
  'is_return_allowed',
];

export const DEFAULT_PURCHASE_INVOICE_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_supplier_id',
  'search_supplier_name',
  'search_transaction_no',
  'search_sort_by',
  'search_status',
  'search_status_delivery',
  'search_from_date',
  'search_to_date',
  'search_is_return_allowed',
];

export const DEFAULT_SALES_QUOTATION_LIST_PARAMS = [
  'page',
  'paginate',
  'transaction_no',
  'customer_name',
  'status',
  'from_date',
  'to_date',
];

export const DEFAULT_SALES_QUOTATION_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_transaction_no',
  'search_customer_name',
  'search_status',
  'search_from_date',
  'search_to_date',
];

export const DEFAULT_SALES_INVOICE_LIST_PARAMS = [
  'page',
  'paginate',
  'sales_invoice_no',
  'customer_name',
  'customer_id',
  'status',
  'status_delivery',
  'from_date',
  'to_date',
  'is_return_allowed',
];

export const DEFAULT_SALES_PAYMENT_LIST_PARAMS = [
  'page',
  'paginate',
  'sales_payment_no',
  'customer_name',
  'from_date',
  'to_date',
];

export const DEFAULT_SALES_INVOICE_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_sales_invoice_no',
  'search_customer_name',
  'search_customer_id',
  'search_status',
  'search_status_delivery',
  'search_from_date',
  'search_to_date',
  'search_is_return_allowed',
];

export const DEFAULT_SALES_ORDER_LIST_PARAMS = [
  'page',
  'paginate',
  'status',
  'to_date',
  'from_date',
  'customer_id',
  'customer_name',
  'sales_order_no',
];

export const DEFAULT_SALES_ORDER_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_status',
  'search_to_date',
  'search_from_date',
  'search_customer_id',
  'search_customer_name',
  'search_sales_order_no',
];

export const DEFAULT_SALES_PAYMENT_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_sales_payment_no',
  'search_customer_name',
  'search_from_date',
  'search_to_date',
];

export const DEFAULT_SALES_DELIVERY_LIST_PARAMS = [
  'page',
  'paginate',
  'sales_delivery_no',
  'transaction_no',
  'customer_name',
  'status',
  'sales_handover_status',
  'status_invoice',
  'from_date',
  'to_date',
];

export const DEFAULT_ACCOUNT_TRANSACTION_LIST_PARAMS = [
  'page',
  'paginate',
  'transaction_no',
  'from_date',
  'to_date',
  'account_id',
  'sort_by',
];

export const DEFAULT_ACCOUNT_TRANSACTION_SEARCH_FE_PARAMS = [
  'per_page',
  'paginate',
  'search_transaction_no',
  'search_from_date',
  'search_to_date',
  'search_account_id',
  'search_sort_by',
];

export const DEFAULT_SALES_DELIVERY_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_sales_delivery_no',
  'search_transaction_no',
  'search_customer_name',
  'search_status',
  'search_sales_handover_status',
  'search_status_invoice',
  'search_from_date',
  'search_to_date',
];

export const DEFAULT_SALES_DELIVERY_AND_ORDER_LIST_PARAMS = [
  'page',
  'paginate',
  'status',
  'transaction_no',
];

export const DEFAULT_SALES_DELIVERY_AND_ORDER_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_status',
  'search_transaction_no',
];

export const DEFAULT_DETAIL_CONTACT_DATA_PARAMS = ['page', 'paginate', 'contact_id'];

export const DEFAULT_ASSET_CATEGORY_LIST_PARAMS = [
  'page',
  'paginate',
  'asset_category_name',
  'useful_life',
  'depreciation_method',
  'description',
];

export const DEFAULT_ASSET_CATEGORY_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_asset_category_name',
  'search_depreciation_method',
];

export const DEFAULT_ASSET_MANAGEMENT_LIST_PARAMS = [
  'page',
  'paginate',
  'acquisition_date',
  'asset_number',
  'asset_name',
  'depreciation_method',
  'acquisition_cost',
  'current_depreciation_value_in_month',
  'accumulated_depreciation_value',
  'book_value',
  'asset_status',
  'from_date',
  'to_date',
  'asset_category',
];

export const DEFAULT_ASSET_MANAGEMENT_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_asset_number',
  'search_asset_name',
  'search_depreciation_method',
  'search_asset_status',
  'search_from_date',
  'search_to_date',
  'search_asset_category',
];

export const DEFAULT_REPORT_INDEX_LIST_PARAMS = [
  'page',
  'paginate',
  'report_code',
  'status',
  'type',
  'periode',
  'from_date',
  'to_date',
];

export const DEFAULT_REPORT_INDEX_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_report_code',
  'search_status',
  'search_type',
  'search_periode',
  'search_from_date',
  'search_to_date',
];

export const DEFAULT_DOCUMENT_REPORT_EXPORT_PARAMS = [
  'from_date',
  'to_date',
  'file_name',
  'doc_type',
  'link',
  'transaction_no',
  'balance_null', // true or false
];

export const DEFAULT_REPORT_JOURNAL_LIST_PARAMS = [
  'page',
  // 'paginate',
  'transaction_no',
  'from_date',
  'to_date',
  'account_id',
];

export const DEFAULT_REPORT_JOURNAL_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_transaction_no',
  'search_from_date',
  'search_to_date',
  'search_account_id',
];

export const DEFAULT_REPORT_INCOME_STATEMENT_LIST_PARAMS = [
  'page',
  'paginate',
  'periode',
  'from_date',
  'to_date',
];

export const DEFAULT_REPORT_INCOME_STATEMENT_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_periode',
  'search_from_date',
  'search_to_date',
];

export const DEFAULT_REPORT_BALANCE_SHEET_LIST_PARAMS = [
  'periode',
  // 'from_date',
  'to_date',
];

export const DEFAULT_REPORT_BALANCE_SHEET_SEARCH_FE_PARAMS = [
  'search_periode',
  // 'search_from_date',
  'search_to_date',
];

export const DEFAULT_REPORT_CAPITAL_CHANGE_LIST_PARAMS = ['periode', 'from_date', 'to_date'];

export const DEFAULT_REPORT_CAPITAL_CHANGE_SEARCH_FE_PARAMS = [
  'search_periode',
  'search_from_date',
  'search_to_date',
];

export const DEFAULT_REPORT_CASH_FLOW_LIST_PARAMS = ['periode', 'from_date', 'to_date'];

export const DEFAULT_REPORT_CASH_FLOW_SEARCH_FE_PARAMS = [
  'search_periode',
  'search_from_date',
  'search_to_date',
];

export const DEFAULT_REPORT_GENERAL_LEDGER_LIST_PARAMS = [
  'page',
  'paginate',
  'periode',
  'from_date',
  'to_date',
  'sort_by',
];

export const DEFAULT_REPORT_GENERAL_LEDGER_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_periode',
  'search_from_date',
  'search_to_date',
  'search_sort_by',
];

export const DEFAULT_REPORT_TRIAL_BALANCE_LIST_PARAMS = ['periode', 'from_date', 'to_date'];

export const DEFAULT_REPORT_TRIAL_BALANCE_SEARCH_FE_PARAMS = [
  'data',
  'search_periode',
  'search_from_date',
  'search_to_date',
  'search_isShowMessage',
  'search_balance_null',
];

export const DEFAULT_SETTING_COMPANY_LIST_PARAMS = [
  'page',
  'paginate',
  'company_name',
  'role_name',
];

export const DEFAULT_SETTING_COMPANY_SEARCH_FE_PARAMS = [
  'per_page',
  'paginate',
  'search_company_name',
  'search_role_name',
];

export const DEFAULT_SETTINGS_WAREHOUSE_LIST_PARAMS = [
  'page',
  'paginate',
  'warehouse_name',
  'warehouse_code',
  'warehouse_type',
];

export const DEFAULT_SETTINGS_WAREHOUSE_SEARCH_FE_PARAMS = [
  'search_warehouse_name',
  'search_warehouse_code',
  'search_warehouse_type',
];

// default params for warehouse product
export const DEFAULT_SETTINGS_WAREHOUSE_PRODUCT_LIST_PARAMS = [
  'page',
  'paginate',
  'product_code',
  'product_name',
  'is_stock_monitored',
];

export const DEFAULT_SETTINGS_WAREHOUSE_PRODUCT_SEARCH_FE_PARAMS = [
  'search_product_code',
  'search_product_name',
  'search_is_stock_monitored',
];

export const DEFAULT_CLOSING_BOOK_LIST_PARAMS = [
  'page',
  'paginate',
  'from_date',
  'to_date',
  'status',
  'sort_by',
  'transaction_no',
];

export const DEFAULT_CLOSING_BOOK_SEARCH_FE_PARAMS = [
  'search_from_date',
  'search_to_date',
  'search_status',
  'search_sort_by',
  'search_transaction_no',
];

// management users list
export const DEFAULT_MANAGEMENT_USER_LIST_PARAMS = [
  'page',
  'paginate',
  'user_fullname',
  'email',
  'role',
  'company_id',
  'is_paginate',
];

export const DEFAULT_MANAGEMENT_USER_SEARCH_FE_PARAMS = [
  'per_page',
  'paginate',
  'search_user_fullname',
  'search_email',
  'search_role',
  'search_company_id',
  'search_is_paginate',
];

export const DEFAULT_TRANSACTION_LIST_PARAMS = [
  'page',
  'paginate',
  'to_date',
  'from_date',
  'transaction_no',
];

export const DEFAULT_TRANSACTION_LIST_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_to_date',
  'search_from_date',
  'search_transaction_no',
];

// stock adjustment list
export const DEFAULT_STOCK_ADJUSTMENT_LIST_PARAMS = [
  'page',
  'paginate',
  'from_date',
  'to_date',
  'warehouse_id',
  'transaction_number',
];

export const DEFAULT_STOCK_ADJUSTMENT_LIST_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_from_date',
  'search_to_date',
  'search_warehouse_id',
  'search_transaction_number',
];

// stock transfer
export const DEFAULT_STOCK_TRANSFER_LIST_PARAMS = [
  'page',
  'paginate',
  'from_date',
  'to_date',
  'transfer_stock_id',
  'origin_warehouse',
  'destination_warehouse',
];

export const DEFAULT_STOCK_TRANSFER_LIST_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_from_date',
  'search_to_date',
  'search_transfer_stock_id',
  'search_origin_warehouse',
  'search_destination_warehouse',
];

// params for details chart basic dashboard
export const DEFAULT_CHART_PURCHASE_PARAMS = ['from_date', 'to_date'];

export const DEFAULT_CHART_SALES_PARAMS = ['from_date', 'to_date'];

export const DEFAULT_CHART_PAYABLE_PARAMS = ['from_date', 'to_date'];

export const DEFAULT_CHART_RECEIVABLE_PARAMS = ['from_date', 'to_date'];

export const DEFAULT_ALLOW_CHART_EXPENSE_PARAMS = ['from_date', 'to_date'];

export const DEFAULT_CHART_PURCHASE_INVOICE_LIST_PARAMS = [
  'page',
  'paginate',
  'from_date',
  'to_date',
  'status',
  'sort_by',
];

export const DEFAULT_CHART_PURCHASE_INVOICE_LIST_SEARCH_PARAMS = [
  'per_page',
  'current_page',
  'search_from_date',
  'search_to_date',
  'search_status',
  'search_sort_by',
];

export const DEFAULT_CHART_SALES_INVOICE_LIST_PARAMS = [
  'page',
  'paginate',
  'from_date',
  'to_date',
  'status',
  'sort_by',
];

export const DEFAULT_CHART_SALES_INVOICE_LIST_SEARCH_PARAMS = [
  'per_page',
  'current_page',
  'search_from_date',
  'search_to_date',
  'search_status',
  'search_sort_by',
];

export const DEFAULT_CHART_SALES_PERSON_LIST_PARAMS = [
  'page',
  'paginate',
  'from_date',
  'to_date',
  'sort_by',
];

export const DEFAULT_CHART_SALES_PERSON_LIST_SEARCH_PARAMS = [
  'per_page',
  'current_page',
  'search_from_date',
  'search_to_date',
  'search_sort_by',
];

export const DEFAULT_CHART_PRODUCT_STOCK_LIST_PARAMS = ['page', 'paginate', 'sort_by', 'filter'];

export const DEFAULT_CHART_PRODUCT_STOCK_LIST_SEARCH_PARAMS = [
  'per_page',
  'current_page',
  'search_sort_by',
  'search_filter',
];

export const DEFAULT_CONTACT_LIST_PARAMS = [
  'page',
  'paginate',
  'contact_code',
  'contact_name',
  'phone_number',
  'type',
];

export const DEFAULT_CONTACT_LIST_SEARCH_PARAMS = [
  'per_page',
  'current_page',
  'search_contact_code',
  'search_contact_name',
  'search_phone_number',
  'search_type',
];

export const DEFAULT_PRODUCT_CATEGORY_LIST_PARAMS = ['page', 'paginate', 'category_name'];

export const DEFAULT_PRODUCT_CATEGORY_LIST_SEARCH_PARAMS = [
  'per_page',
  'current_page',
  'search_category_name',
];

export const DEFAULT_REPORT_BASIC_PURCHASE_LIST_PARAMS = [
  'page',
  'paginate',
  'to_date',
  'from_date',
  'purchase_invoice_no',
  'status',
  'sort_by',
  'supplier_id',
];

export const DEFAULT_REPORT_BASIC_PURCHASE_LIST_SEARCH_PARAMS = [
  'per_page',
  'current_page',
  'search_to_date',
  'search_from_date',
  'search_purchase_invoice_no',
  'search_status',
  'search_sort_by',
  'search_supplier_id',
];

export const DEFAULT_REPORT_BASIC_SALES_LIST_PARAMS = [
  'page',
  'paginate',
  'to_date',
  'from_date',
  'sales_invoice_no',
  'status',
  'sort_by',
  'customer_id',
];

export const DEFAULT_REPORT_BASIC_SALES_LIST_SEARCH_PARAMS = [
  'per_page',
  'current_page',
  'search_to_date',
  'search_from_date',
  'search_sales_invoice_no',
  'search_status',
  'search_sort_by',
  'customer_id',
];

export const DEFAULT_REPORT_BASIC_STOCK_LIST_PARAMS = [
  'page',
  'paginate',
  'product_name',
  'product_code',
  'product_category_id',
  'warehouse_id',
  'sort_by',
  'date',
];

export const DEFAULT_REPORT_BASIC_STOCK_LIST_SEARCH_PARAMS = [
  'per_page',
  'current_page',
  'search_product_name',
  'search_product_code',
  'search_product_category_id',
  'search_warehouse_id',
  'search_sort_by',
  'search_category_name',
  'search_warehouse_name',
  'search_date',
];

export const DEFAULT_REPORT_BASIC_PRODUCT_PRICE_LIST_PARAMS = [
  'page',
  'paginate',
  'sort_by',
  'product_name',
  'product_code',
  'product_category_id',
];

export const DEFAULT_REPORT_BASIC_PRODUCT_PRICE_LIST_SEARCH_PARAMS = [
  'per_page',
  'current_page',
  'search_sort_by',
  'search_product_name',
  'search_product_code',
  'search_product_category_id',
  'search_category_name',
];

export const DEFAULT_REPORT_BASIC_SALES_EXTRACTION_LIST_PARAMS = [
  'page',
  'paginate',
  'to_date',
  'from_date',
];

export const DEFAULT_REPORT_BASIC_SALES_EXTRACTION_LIST_SEARCH_PARAMS = [
  'per_page',
  'current_page',
  'search_to_date',
  'search_from_date',
];

export const DEFAULT_EXPENSE_LIST_PARAMS = [
  'page',
  'paginate',
  'transaction_no',
  'contact_name',
  'from_date',
  'to_date',
];

export const DEFAULT_EXPENSE_SEARCH_FE_PARAMS = [
  'per_page',
  'current_page',
  'search_transaction_no',
  'search_contact_name',
  'search_from_date',
  'search_to_date',
];

export const DEFAULT_PRODUCT_UNIT_LIST_PARAMS = [
  'unit_name',
  'unit_type',
  'comparison_value',
  'quantity',
  'sort_by',
  'order',
  'is_basic',
  'is_custom',
  'get_unit_group',
];

export const DEFAULT_CHART_TOP_SALES_LIST_PARAMS = [
  'page',
  'paginate',
  'from_date',
  'to_date',
  'status',
  'sort_by',
];

export const defaultSendParamsProductUnitList = {
  ...TERSIER_DEFAULT_LIST_PARAMS,
  sort_by: 'comparison_value',
  is_basic: 1,
};

export const additionalSendParamsProductUnitList = {};

export const DEFAULT_CHART_TOP_SALES_LIST_SEARCH_PARAMS = [
  'per_page',
  'current_page',
  'search_from_date',
  'search_to_date',
  'search_status',
  'search_sort_by',
];

export const DEFAULT_NOTIFICATION_LIST_PARAMS = [
  ...DEFAULT_BASE_LIST_ACTION_PARAMS,
  'notification_type',
];

export const DEFAULT_ALLOW_PARAMS_PURCHASE_DEBIT_NOTE_LIST = [
  ...DEFAULT_BASE_LIST_ACTION_PARAMS,
  'from_date',
  'to_date',
  'supplier_id',
  'supplier_name',
  'transaction_no',
  'status',
];

export const DEFAULT_SEARCH_PARAMS_PURCHASE_DEBIT_NOTE_LIST = [
  'search_from_date',
  'search_to_date',
  'search_supplier_id',
  'search_supplier_name',
  'search_transaction_no',
  'search_status',
];

export const DEFAULT_ALLOW_PARAMS_PURCHASE_RETURN_LIST = [
  ...DEFAULT_BASE_LIST_ACTION_PARAMS,
  'from_date',
  'to_date',
  'supplier_name',
  'transaction_no',
  'purchase_invoice_no',
  'status',
];

export const DEFAULT_SEARCH_PARAMS_PURCHASE_RETURN_LIST = [
  'search_from_date',
  'search_to_date',
  'search_supplier_name',
  'transaction_no',
  'search_purchase_invoice_no',
  'status',
];

export const DEFAULT_ALLOW_PARAMS_PURCHASE_RECEIVABLE_PAYMENT_LIST = [
  ...DEFAULT_BASE_LIST_ACTION_PARAMS,
  'from_date',
  'to_date',
  'transaction_no',
  'supplier_name',
];

export const DEFAULT_SEARCH_PARAMS_PURCHASE_RECEIVABLE_PAYMENT_LIST = [
  'search_from_date',
  'search_to_date',
  'search_transaction_no',
  'search_supplier_name',
];

export const DEFAULT_ALLOW_PARAMS_SALES_RETURN_LIST = [
  ...DEFAULT_BASE_LIST_ACTION_PARAMS,
  'from_date',
  'to_date',
  'customer_name',
  'sales_invoice_no',
  'transaction_no',
  'status',
];

export const DEFAULT_SEARCH_PARAMS_SALES_RETURN_LIST = [
  'search_from_date',
  'search_to_date',
  'search_customer_name',
  'search_sales_invoice_no',
  'search_transaction_no',
  'search_status',
];

export const DEFAULT_ALLOW_PARAMS_SALES_CREDIT_NOTE_LIST = [
  ...DEFAULT_BASE_LIST_ACTION_PARAMS,
  'from_date',
  'to_date',
  'customer_id',
  'customer_name',
  'transaction_no',
  'status',
];

export const DEFAULT_SEARCH_PARAMS_SALES_CREDIT_NOTE_LIST = [
  'search_from_date',
  'search_to_date',
  'search_customer_id',
  'search_customer_name',
  'search_transaction_no',
  'search_status',
];

export const DEFAULT_ALLOW_PARAMS_SALES_PAYABLE_PAYMENT_LIST = [
  ...DEFAULT_BASE_LIST_ACTION_PARAMS,
  'from_date',
  'to_date',
  'transaction_no',
  'customer_name',
];

export const DEFAULT_SEARCH_PARAMS_SALES_PAYABLE_PAYMENT_LIST = [
  'search_from_date',
  'search_to_date',
  'search_transaction_no',
  'search_customer_name',
];

export const DEFAULT_ALLOW_PARAMS_TAG_LIST = [...DEFAULT_BASE_LIST_ACTION_PARAMS, 'tag_name'];

export const DEFAULT_SEARCH_PARAMS_TAG_LIST = ['search_tag_name'];

export const DEFAULT_ALLOW_PARAMS_PRODUCT_MUTATION_LIST = [
  ...DEFAULT_BASE_LIST_ACTION_PARAMS,
  'from_date',
  'to_date',
];

export const DEFAULT_SEARCH_PARAMS_PRODUCT_MUTATION_LIST = ['search_from_date', 'search_to_date'];

export const DEFAULT_ALLOW_PARAMS_PURCHASE_RETURN_DETAILS = ['show_all_product'];

export const DEFAULT_ALLOW_PARAMS_SALES_RETURN_DETAILS = ['show_all_product'];

export const DEFAULT_ALLOW_PARAMS_REPORT_STOCK_SALES = [
  'to_date',
  'from_date',
  'sales_invoice_no',
  'status',
  'sort_by',
  'customer_id',
];

export const DEFAULT_SEARCH_PARAMS_REPORT_STOCK_SALES = [
  'search_to_date',
  'search_from_date',
  'search_sales_invoice_no',
  'search_status',
  'search_sort_by',
  'search_customer_id',
];

export const DEFAULT_ALLOW_PARAMS_REPORT_STOCK_MUTATION = [
  'warehouse_id',
  'to_date',
  'from_date',
  'product_id',
  'sort_by',
];

export const DEFAULT_SEARCH_PARAMS_REPORT_STOCK_MUTATION = [
  'search_warehouse_id',
  'search_to_date',
  'search_from_date',
  'search_product_id',
  'search_sort_by',
];

export const DEFAULT_ALLOW_PARAMS_REPORT_STOCK_PURCHASE = [
  'to_date',
  'from_date',
  'purchase_invoice_no',
  'status',
  'sort_by',
  'supplier_id',
];

export const DEFAULT_SEARCH_PARAMS_REPORT_STOCK_PURCHASE = [
  'search_to_date',
  'search_from_date',
  'search_purchase_invoice_no',
  'search_status',
  'search_sort_by',
  'search_supplier_id',
];

export const DEFAULT_ALLOW_PARAMS_REPORT_STOCK_ADJUSTMENT = ['to_date', 'from_date'];

export const DEFAULT_SEARCH_PARAMS_REPORT_STOCK_ADJUSTMENT = ['search_to_date', 'search_from_date'];

export const DEFAULT_ALLOW_PARAMS_REPORT_STOCK_CARD = ['to_date', 'from_date', 'product_id'];

export const DEFAULT_SEARCH_PARAMS_REPORT_STOCK_CARD = [
  'search_to_date',
  'search_from_date',
  'search_product_id',
  'search_productName',
];

export const DEFAULT_ALLOW_PARAMS_MANAGEMENT_ROLE = ['page', 'paginate', 'role_id', 'role_name'];

export const DEFAULT_SEARCH_PARAMS_MANAGEMENT_ROLE = [
  'search_page',
  'search_paginate',
  'search_role_id',
  'search_role_name',
];

export const DEFAULT_ALLOW_PARAMS_CONTACT_DATA_LISTING = [
  'page',
  'paginate',
  'contact_name',
  'email',
  'phone_number',
  'type',
  'not_type',
  'contact_code',
];

export const DEFAULT_SEARCH_PARAMS_CONTACT_DATA_LISTING = [
  'search_page',
  'search_paginate',
  'search_contact_name',
  'search_email',
  'search_phone_number',
  'search_type',
  'search_not_type',
  'search_contact_code',
];

export const ALLOW_PARAMS_CASH_BANK_ACCOUNT_TRANSACTION = [
  'page',
  'paginate',
  'journal_entry',
  'account_id',
  'transaction_no',
  'from_date',
  'to_date',
];
