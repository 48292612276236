import { LibraryBooks } from '@mui/icons-material';

import { PERMISSION_SALES_INVOICE_ACCESS } from '../../constants/permissions.constant';

export const tooltipSalesInvoice = (t) => {
  return [
    {
      name: 'detail-sales-invoice',
      title: t('base.tooltip.detail'),
      permission: PERMISSION_SALES_INVOICE_ACCESS,
    },
  ];
};

export const iconSalesInvoiceList = [LibraryBooks];
