import { FORMAT_CURRENCY_AUTO_COMMA_COLUMN } from '../../../components/table-row/TableRowPrimary/TableRowPrimary';

// columns for table purchase receivable payment
export const columnsTableHeaderDebitNoteListOnPurchaseReceivablePayment = (t) => {
  return [
    {
      multipleNames: [
        {
          name: 'debit_note_no',
        },
        {
          name: 'description',
        },
      ],
      label: t('table.header-column.debit-note-no'),
      isUseMultipleNames: true,
      width: 250,
    },
    {
      name: 'amount',
      label: t('table.header-column.total-bill'),
      align: 'right',
      width: 50,
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'amount_available',
      label: t('table.header-column.remaining-bill'),
      align: 'right',
      width: 25,
      minWidth: 40,
    },
    {
      name: 'amount_paid',
      label: t('table.header-column.amount-paid'),
      align: 'right',
      width: 75,
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
  ];
};
