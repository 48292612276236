export const ACCOUNT_CATEGORY = {
  INVENTORY: 'Persediaan',
  EQUITY: 'Ekuitas',
  COST_OF_GOODS_SOLD: 'Harga Pokok Penjualan',
  EXPENSE: 'Beban',
  OTHER_EXPENSE: 'Beban Lainnya',
  FIXED_ASSET: 'Aktiva Tetap',
  DEPRECIATION_N_AMORTASI: 'Depresiasi & Amortisasi',
  CASH_N_BANK: 'Kas & Bank',
};

export const DEFAULT_ACCOUNT_CATEGORY_PENDAPATAN = 'Pendapatan';
export const DEFAULT_ACCOUNT_CATEGORY_INVENTARIS = 'Persediaan';
export const DEFAULT_ACCOUNT_CATEGORY_HARGA_POKOK_PENJUALAN = 'Harga Pokok Penjualan';
export const DEFAULT_ACCOUNT_CATEGORY_CASH_N_BANK = 'Kas & Bank';
export const DEFAULT_ACCOUNT_CATEGORY_FIXED_ASSET = 'Aktiva Tetap';
export const DEFAULT_ACCOUNT_CATEGORY_DEPRECIATION_N_AMORTIZATION = 'Depresiasi & Amortisasi';
export const DEFAULT_ACCOUNT_CATEGORY_EXPRENSE = 'beban';
export const DEFAULT_ACCOUNT_CATEGORY_OTHER_EXPENSE = 'Beban Lainnya';
export const DEFAULT_ACCOUNT_CATEGORY_COST_OF_GOODS_SOLD = 'Harga Pokok Penjualan';
