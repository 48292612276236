import * as React from 'react';
function SvgProductCategoryDataMenu(props) {
  return (
    <svg width={97} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#product-category-data-menu_svg__filter0_d_5543_189105)'>
        <path d='M42.939 25.267H3.791v25.506h39.148V25.267z' fill='#FFD15D' />
        <path d='M42.939 25.267H23.287v25.506h19.652V25.267z' fill='#F9B54C' />
        <path d='M44.896 21.624H1.833v7.287h43.063v-7.287z' fill='#F9B54C' />
        <path d='M44.896 21.624h-21.61v7.287h21.61v-7.287z' fill='#F4A200' />
        <path
          d='M29.237 35.536H17.493c-1.08 0-1.957-.816-1.957-1.822 0-1.006.876-1.821 1.957-1.821h11.744c1.081 0 1.958.815 1.958 1.821 0 1.007-.877 1.822-1.958 1.822z'
          fill='#B97850'
        />
        <path
          d='M29.237 31.893h-5.95v3.643h5.95c1.081 0 1.958-.816 1.958-1.822 0-1.006-.877-1.821-1.958-1.821z'
          fill='#935635'
        />
        <path
          d='M15.438 38.483H7.95c-.771 0-1.377.563-1.377 1.28v6.97c0 .716.606 1.28 1.377 1.28h7.487c.77 0 1.376-.564 1.376-1.28v-6.97c0-.666-.606-1.28-1.376-1.28zm.605 8.25c0 .306-.275.563-.605.563H7.95c-.33 0-.606-.257-.606-.564v-6.968c0-.308.275-.564.606-.564h7.487c.33 0 .605.256.605.563v6.97z'
          fill='#fff'
        />
        <path
          d='M10.152 41.096c-.055-.051-.055-.051-.11-.051s-.11-.052-.165-.052c-.055 0-.11 0-.165.051-.055 0-.11.052-.11.052l-1.266 1.178c-.165.154-.165.359 0 .461.165.154.385.154.495 0l.66-.614v3.023c0 .205.166.358.386.358s.386-.153.386-.358V42.12l.66.614a.438.438 0 00.275.103c.11 0 .166-.051.276-.103.165-.153.165-.358 0-.46l-1.322-1.18zM13.786 41.096a.438.438 0 00-.275-.103c-.11 0-.22.051-.276.103l-1.266 1.178c-.165.154-.165.359 0 .461.165.154.385.154.496 0l.66-.614v3.023c0 .205.165.358.386.358.22 0 .385-.153.385-.358V42.12l.66.614a.438.438 0 00.276.103c.11 0 .165-.051.275-.103.165-.153.165-.358 0-.46l-1.321-1.18zM26.943 38.483h-7.487c-.77 0-1.376.563-1.376 1.28v6.97c0 .716.606 1.28 1.376 1.28h7.487c.771 0 1.377-.564 1.377-1.28v-6.97c0-.666-.606-1.28-1.377-1.28zm.661 8.25c0 .306-.275.563-.605.563h-7.543c-.33 0-.605-.257-.605-.564v-6.968c0-.308.275-.564.605-.564h7.487c.33 0 .606.256.606.563v6.97h.055z'
          fill='#fff'
        />
        <path
          d='M24.852 40.43h-3.248c-.22 0-.386.153-.386.358v1.64c0 .922.716 1.69 1.652 1.845V45.4h-.496c-.22 0-.385.154-.385.358 0 .205.165.36.385.36h1.762c.22 0 .385-.155.385-.36s-.165-.358-.385-.358h-.495v-1.127c.935-.154 1.651-.923 1.651-1.845v-1.64c-.055-.205-.22-.358-.44-.358zm-.386 1.998c0 .666-.55 1.178-1.266 1.178-.715 0-1.266-.512-1.266-1.178v-1.332h2.532v1.332zM38.45 38.483h-7.488c-.77 0-1.376.563-1.376 1.28v6.97c0 .716.606 1.28 1.376 1.28h7.487c.771 0 1.377-.564 1.377-1.28v-6.97c0-.666-.661-1.28-1.377-1.28zm.605 8.25c0 .306-.275.563-.606.563h-7.487c-.33 0-.605-.257-.605-.564v-6.968c0-.308.275-.564.605-.564h7.487c.33 0 .606.256.606.563v6.97z'
          fill='#fff'
        />
        <path
          d='M34.706 39.968c-1.817 0-3.303 1.384-3.303 3.075 0 .205.165.358.385.358h2.588v2.306c0 .103-.11.205-.22.205s-.22-.102-.22-.205c0-.205-.166-.358-.386-.358s-.386.153-.386.358c0 .513.44.923.991.923.551 0 .991-.41.991-.923v-2.306h2.588c.22 0 .385-.153.385-.358-.11-1.691-1.596-3.075-3.413-3.075zm-2.532 2.716c.165-1.178 1.266-2.05 2.532-2.05s2.367.872 2.532 2.05h-5.064z'
          fill='#fff'
        />
      </g>
      <g filter='url(#product-category-data-menu_svg__filter1_d_5543_189105)'>
        <path
          d='M52.728 15.704v18.87H42.94v2.07h9.787v18.869c0 3.008 1.975 5.456 4.404 5.456h12.905V58.9H57.132c-1.547 0-2.807-1.519-2.807-3.386v-18.87h14.98v-2.07h-14.98V15.705c0-1.867 1.26-3.386 2.807-3.386h12.905v-2.07H57.132c-2.428 0-4.404 2.448-4.404 5.456z'
          fill='#E9EFF4'
        />
      </g>
      <g filter='url(#product-category-data-menu_svg__filter2_d_5543_189105)'>
        <path
          d='M79.453 69c5.733 0 10.38-4.326 10.38-9.661 0-5.336-4.647-9.661-10.38-9.661-5.732 0-10.38 4.325-10.38 9.66 0 5.336 4.648 9.662 10.38 9.662z'
          fill='#FD8469'
        />
        <path
          d='M79.453 49.678c5.733 0 10.38 4.325 10.38 9.66 0 5.336-4.647 9.662-10.38 9.662V49.678z'
          fill='#FC6F58'
        />
        <path
          d='M79.453 44.661c5.733 0 10.38-4.325 10.38-9.661 0-5.336-4.647-9.661-10.38-9.661-5.732 0-10.38 4.325-10.38 9.661 0 5.336 4.648 9.661 10.38 9.661z'
          fill='#FD8469'
        />
        <path
          d='M79.454 25.339c-.042 0-.083.002-.124.003v19.316c.041 0 .082.003.124.003 5.732 0 10.38-4.325 10.38-9.661 0-5.336-4.648-9.661-10.38-9.661z'
          fill='#FC6F58'
        />
        <path
          d='M79.453 20.322c5.733 0 10.38-4.325 10.38-9.66C89.833 5.324 85.186 1 79.453 1c-5.732 0-10.38 4.325-10.38 9.661 0 5.336 4.648 9.661 10.38 9.661z'
          fill='#FD8469'
        />
        <path
          d='M79.453 1c5.733 0 10.38 4.326 10.38 9.661 0 5.336-4.647 9.661-10.38 9.661V1z'
          fill='#FC6F58'
        />
      </g>
      <defs>
        <filter
          id='product-category-data-menu_svg__filter0_d_5543_189105'
          x={0.833}
          y={20.624}
          width={51.063}
          height={37.15}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5543_189105' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5543_189105' result='shape' />
        </filter>
        <filter
          id='product-category-data-menu_svg__filter1_d_5543_189105'
          x={41.941}
          y={9.248}
          width={35.095}
          height={58.721}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5543_189105' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5543_189105' result='shape' />
        </filter>
        <filter
          id='product-category-data-menu_svg__filter2_d_5543_189105'
          x={68.073}
          y={0}
          width={28.76}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5543_189105' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5543_189105' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgProductCategoryDataMenu;
