import { createAsyncThunk } from '@reduxjs/toolkit';

import bankServices from '../../services/API/bank/bank.service';
import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_BANK_DATA } from '../../utils/constants/storage.constant';
import { DEFAULT_IGNORE_PARAMS } from '../../utils/default/params.default';
import objHelper from '../../utils/helpers/object.helper';

export const getListBank = createAsyncThunk(
  'bank-data/list',
  async ({ isRewriteAll = true, ...paramsGetListBank }, thunkAPI) => {
    try {
      const response = await bankServices.getListBank(paramsGetListBank);

      if (!response) {
        return thunkAPI.rejectWithValue(response.data);
      }

      let { data: bankData, sucess } = response.data;

      if (!sucess || !Array.isArray(bankData.data)) {
        bankData = {
          ...bankData,
          data: [],
        };
      }

      let searchParams = objHelper.filterKeyObj(paramsGetListBank, DEFAULT_IGNORE_PARAMS);

      if (searchParams) {
        searchParams = objHelper.changeSuffixKey(searchParams, 'search_', false);

        bankData = {
          ...bankData,
          ...searchParams,
        };
      }

      if (isRewriteAll) {
        LocalStorage.set(LOCAL_STORAGE_BANK_DATA, bankData);

        return { bankData };
      }

      const currentBank = LocalStorage.get(LOCAL_STORAGE_BANK_DATA);

      // combine data from previous
      const newListDataBank = currentBank.data.concat(bankData.data);

      // adding data from previous
      const newDataBank = Object.assign(currentBank, bankData, { data: newListDataBank });

      LocalStorage.set(LOCAL_STORAGE_BANK_DATA, newDataBank);

      return { bankData: newDataBank };
    } catch (error) {
      if (!error.response) {
        return thunkAPI.rejectWithValue(error.respnse);
      }

      const { data } = error.response;

      return thunkAPI.rejectWithValue(data);
    }
  },
);
