// name pallete of chip component, you can check on theme.js
export const DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS = 'chipSuccessStatus';
export const DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_V2_STATUS = 'chipSuccessV2status';
export const DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS = 'chipSecondaryStatus';
export const DEFAULT_CHIP_COMPONENT_NAME_WARNING_STATUS = 'chipWarningStatus';
export const DEFAULT_CHIP_COMPONENT_NAME_INFO_STATUS = 'chipInfoStatus';
export const DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS = 'chipErrorStatus';

/**
 * blue border and text
 * white background
 */
export const DEFAULT_CHIP_INFO_V2_COMPONENT_NAME = 'chipInfoV2';
