import { checkboxVariant } from '../../../components/checkbox/CheckboxWithLabel/CheckboxWithLabel';
import {
  DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
  DEFAULT_CHIP_COMPONENT_NAME_INFO_STATUS,
  DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
  DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
  DEFAULT_CHIP_COMPONENT_NAME_WARNING_STATUS,
} from '../../default/chip-component-name.default';
import {
  ADDITIONAL_SALES_RETURN_STATUS_ALL,
  DEFAULT_ADDITIONAL_STATUS_SALES_CREDIT_NOTE_ALL,
  DEFAULT_ADDITIONAL_STATUS_SALES_DELIVERY_ALL,
  DEFAULT_ADDITIONAL_STATUS_SALES_ORDER_ALL,
  DEFAULT_ADDITIONAL_STATUS_SALES_QUOTATION_ALL,
  DEFAULT_STATUS_SALES_CREDIT_NOTE_CLOSED,
  DEFAULT_STATUS_SALES_CREDIT_NOTE_OPEN,
  DEFAULT_STATUS_SALES_CREDIT_NOTE_PAID,
  DEFAULT_STATUS_SALES_CREDIT_NOTE_PARTIALLY_PAID,
  DEFAULT_STATUS_SALES_DELIVERY_CLOSE,
  DEFAULT_STATUS_SALES_DELIVERY_OPEN,
  DEFAULT_STATUS_SALES_DELIVERY_PARTIALLY_SENT,
  DEFAULT_STATUS_SALES_DELIVERY_REJECT,
  DEFAULT_STATUS_SALES_INVOICE_CHART_BASIC_OPEN,
  DEFAULT_STATUS_SALES_INVOICE_CHART_BASIC_PAID,
  DEFAULT_STATUS_SALES_INVOICE_CHART_BASIC_PARTIALLY_PAID,
  DEFAULT_STATUS_SALES_ORDER_CLOSED,
  DEFAULT_STATUS_SALES_ORDER_OPEN,
  DEFAULT_STATUS_SALES_ORDER_PARTIALLY_SENT,
  DEFAULT_STATUS_SALES_ORDER_REJECT,
  DEFAULT_STATUS_SALES_QUOTATION_CLOSE,
  DEFAULT_STATUS_SALES_QUOTATION_EXPIRED,
  DEFAULT_STATUS_SALES_QUOTATION_OPEN,
  DEFAULT_STATUS_SALES_QUOTATION_REJECT,
  DEFAULT_STATUS_SALES_RETURN_CLOSED,
  DEFAULT_STATUS_SALES_RETURN_OPEN,
} from '../../default/sales-status.default';

/**
 *
 * @param { function } t translation function
 * @returns
 *      pair label, value, colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 *      - colorChip used for coloring chip on status sales order
 */
export function salesOrderStatus(t) {
  return [
    {
      label: t('label.sales-order-status.all'),
      value: DEFAULT_ADDITIONAL_STATUS_SALES_ORDER_ALL,
      colorChip: 'chipSecondaryStatus',
    },
    {
      label: t('label.sales-order-status.open'),
      value: DEFAULT_STATUS_SALES_ORDER_OPEN,
      colorChip: 'chipSecondaryStatus',
    },
    {
      label: t('label.sales-order-status.partially-sent'),
      value: DEFAULT_STATUS_SALES_ORDER_PARTIALLY_SENT,
      colorChip: 'chipInfoStatus',
    },
    {
      label: t('label.sales-order-status.reject'),
      value: DEFAULT_STATUS_SALES_ORDER_REJECT,
      colorChip: 'chipErrorStatus',
    },
    {
      label: t('label.sales-order-status.closed'),
      value: DEFAULT_STATUS_SALES_ORDER_CLOSED,
      colorChip: 'chipSuccessStatus',
    },
  ];
}

export const salesOrderPlainStatus = [
  {
    label: 'label.sales-order-status.open',
    value: DEFAULT_STATUS_SALES_ORDER_OPEN,
    colorChip: 'chipSecondaryStatus',
  },
  {
    label: 'label.sales-order-status.partially-sent',
    value: DEFAULT_STATUS_SALES_ORDER_PARTIALLY_SENT,
    colorChip: 'chipInfoStatus',
  },
  {
    label: 'label.sales-order-status.reject',
    value: DEFAULT_STATUS_SALES_ORDER_REJECT,
    colorChip: 'chipErrorStatus',
  },
  {
    label: 'label.sales-order-status.closed',
    value: DEFAULT_STATUS_SALES_ORDER_CLOSED,
    colorChip: 'chipSuccessStatus',
  },
];

export const mappingSalesOrderStatus = {
  [DEFAULT_STATUS_SALES_ORDER_OPEN]: 'label.sales-order-status.open',
  [DEFAULT_STATUS_SALES_ORDER_PARTIALLY_SENT]: 'label.sales-order-status.partially-sent',
  [DEFAULT_STATUS_SALES_ORDER_REJECT]: 'label.sales-order-status.reject',
  [DEFAULT_STATUS_SALES_ORDER_CLOSED]: 'label.sales-order-status.closed',
};

/**
 *
 * @param { function } t translation function
 * @returns
 *      pair label, value, and colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 *      - colorChip is for adding color on chip related to theme
 */
export function salesDeliveryStatus(t) {
  return [
    {
      label: t('label.sales-delivery-status.all'),
      value: DEFAULT_ADDITIONAL_STATUS_SALES_DELIVERY_ALL,
      colorChip: 'chipSecondaryStatus',
    },
    {
      label: t('label.sales-delivery-status.open'),
      value: DEFAULT_STATUS_SALES_DELIVERY_OPEN,
      colorChip: 'chipSecondaryStatus',
    },
    {
      label: t('label.sales-delivery-status.partially-sent'),
      value: DEFAULT_STATUS_SALES_DELIVERY_PARTIALLY_SENT,
      colorChip: 'chipInfoStatus',
    },
    {
      label: t('label.sales-delivery-status.reject'),
      value: DEFAULT_STATUS_SALES_DELIVERY_REJECT,
      colorChip: 'chipErrorStatus',
    },
    {
      label: t('label.sales-delivery-status.close'),
      value: DEFAULT_STATUS_SALES_DELIVERY_CLOSE,
      colorChip: 'chipSuccessStatus',
    },
  ];
}

export const salesDeliveryPlainStatus = [
  {
    label: 'label.sales-delivery-status.open',
    value: DEFAULT_STATUS_SALES_DELIVERY_OPEN,
    colorChip: 'chipSecondaryStatus',
  },
  {
    label: 'label.sales-delivery-status.partially-sent',
    value: DEFAULT_STATUS_SALES_DELIVERY_PARTIALLY_SENT,
    colorChip: 'chipInfoStatus',
  },
  {
    label: 'label.sales-delivery-status.reject',
    value: DEFAULT_STATUS_SALES_DELIVERY_REJECT,
    colorChip: 'chipErrorStatus',
  },
  {
    label: 'label.sales-delivery-status.close',
    value: DEFAULT_STATUS_SALES_DELIVERY_CLOSE,
    colorChip: 'chipSuccessStatus',
  },
];

export const mappingSalesDeliveryStatus = {
  [DEFAULT_STATUS_SALES_DELIVERY_OPEN]: 'label.sales-delivery-status.open',
  [DEFAULT_STATUS_SALES_DELIVERY_PARTIALLY_SENT]: 'label.sales-delivery-status.partially-sent',
  [DEFAULT_STATUS_SALES_DELIVERY_REJECT]: 'label.sales-delivery-status.reject',
  [DEFAULT_STATUS_SALES_DELIVERY_CLOSE]: 'label.sales-delivery-status.close',
};

/**
 *
 * @param   { function } t translation function
 * @returns
 *      pair label, value, and colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 *      - colorChip is for adding color on chip related to theme
 */
export function salesQoutationStatus(t) {
  return [
    {
      label: t('label.sales-quotation-status.all'),
      value: DEFAULT_ADDITIONAL_STATUS_SALES_QUOTATION_ALL,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
    {
      label: t('label.sales-quotation-status.open'),
      value: DEFAULT_STATUS_SALES_QUOTATION_OPEN,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
    {
      label: t('label.sales-quotation-status.partially-sent'),
      value: DEFAULT_STATUS_SALES_QUOTATION_EXPIRED,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_INFO_STATUS,
    },
    {
      label: t('label.sales-quotation-status.reject'),
      value: DEFAULT_STATUS_SALES_QUOTATION_REJECT,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
    },
    {
      label: t('label.sales-quotation-status.close'),
      value: DEFAULT_STATUS_SALES_QUOTATION_CLOSE,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
    },
  ];
}

export function salesQoutationStatusV2(t) {
  return [
    {
      label: t('label.sales-quotation-status.all'),
      value: DEFAULT_ADDITIONAL_STATUS_SALES_QUOTATION_ALL,
    },
    {
      label: t('label.sales-quotation-status.open'),
      value: DEFAULT_STATUS_SALES_QUOTATION_OPEN,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
    {
      label: t('label.sales-quotation-status.close'),
      value: DEFAULT_STATUS_SALES_QUOTATION_CLOSE,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
    },
    {
      label: t('label.sales-quotation-status.reject'),
      value: DEFAULT_STATUS_SALES_QUOTATION_REJECT,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
    },
    {
      label: t('label.sales-quotation-status.expired'),
      value: DEFAULT_STATUS_SALES_QUOTATION_EXPIRED,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
    },
  ];
}

export const salesQuotationPlainStatus = [
  {
    label: 'label.sales-quotation-status.open',
    value: DEFAULT_STATUS_SALES_QUOTATION_OPEN,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
  },
  {
    label: 'label.sales-quotation-status.close',
    value: DEFAULT_STATUS_SALES_QUOTATION_CLOSE,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
  },
  {
    label: 'label.sales-quotation-status.reject',
    value: DEFAULT_STATUS_SALES_QUOTATION_REJECT,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
  },
  {
    label: 'label.sales-quotation-status.expired',
    value: DEFAULT_STATUS_SALES_QUOTATION_EXPIRED,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
  },
];

// mapping sales quotation status
export const mappingSalesQuotationStatus = {
  [DEFAULT_STATUS_SALES_QUOTATION_OPEN]: 'label.sales-quotation-status.open',
  [DEFAULT_STATUS_SALES_QUOTATION_CLOSE]: 'label.sales-quotation-status.close',
  [DEFAULT_STATUS_SALES_QUOTATION_REJECT]: 'label.sales-quotation-status.reject',
  [DEFAULT_STATUS_SALES_QUOTATION_EXPIRED]: 'label.sales-quotation-status.expired',
};

/**
 *
 * @param { function } t translation function
 * @returns
 *      sales invoice status that already translate for label
 *
 *      pair label, value, and colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 *      - colorChip is for adding color on chip related to theme
 */
export function salesInvoiceStatus(t) {
  return [
    {
      label: t('label.sales-invoice-status.all'),
      value: 'all',
    },
    {
      label: t('label.sales-invoice-status.open'),
      value: 'open',
      colorChip: 'chipSecondaryStatus',
    },
    {
      label: t('label.sales-invoice-status.partially-paid'),
      value: 'partially_paid',
      colorChip: 'chipInfoStatus',
    },
    {
      label: t('label.sales-invoice-status.paid'),
      value: 'paid',
      colorChip: 'chipSuccessStatus',
    },
  ];
}

export const salesInvoicePlainStatus = [
  {
    label: 'label.sales-invoice-status.open',
    value: 'open',
    colorChip: 'chipSecondaryStatus',
  },
  {
    label: 'label.sales-invoice-status.partially-paid',
    value: 'partially_paid',
    colorChip: 'chipInfoStatus',
  },
  {
    label: 'label.sales-invoice-status.paid',
    value: 'paid',
    colorChip: 'chipSuccessStatus',
  },
];

export const mappingSalesInvoiceStatus = {
  ['open']: 'label.sales-invoice-status.open',
  ['partially_paid']: 'label.sales-invoice-status.partially-paid',
  ['paid']: 'label.sales-invoice-status.paid',
};

/**
 *
 * @param { function } t translation function
 * @returns
 *      sales invoice status that already translate for label
 *
 *      pair label, value, and colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 *      - colorChip is for adding color on chip related to theme
 */
export function salesInvoiceChartBasicStatus(t) {
  return [
    {
      label: t('label.sales-invoice-chart-basic-status.open'),
      value: DEFAULT_STATUS_SALES_INVOICE_CHART_BASIC_OPEN,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
    },
    {
      label: t('label.sales-invoice-chart-basic-status.partially-paid'),
      value: DEFAULT_STATUS_SALES_INVOICE_CHART_BASIC_PARTIALLY_PAID,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_WARNING_STATUS,
    },
    {
      label: t('label.sales-invoice-chart-basic-status.close'),
      value: DEFAULT_STATUS_SALES_INVOICE_CHART_BASIC_PAID,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
    },
  ];
}

/**
 *
 * @param { function } t translation function
 * @returns
 *      sales invoice status that already translate for label
 *
 *      pair label, value, and colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 *      - colorChip is for adding color on chip related to theme
 */
export function salesInvoiceChartBasicStatusWithCheckboxVariant(t) {
  return [
    {
      label: t('label.sales-invoice-chart-basic-status.close'),
      value: DEFAULT_STATUS_SALES_INVOICE_CHART_BASIC_PAID,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
      checkboxVariant: checkboxVariant.successPastel,
    },
    {
      label: t('label.sales-invoice-chart-basic-status.partially-paid'),
      value: DEFAULT_STATUS_SALES_INVOICE_CHART_BASIC_PARTIALLY_PAID,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_WARNING_STATUS,
      checkboxVariant: checkboxVariant.warningPastel,
    },
    {
      label: t('label.sales-invoice-chart-basic-status.open'),
      value: DEFAULT_STATUS_SALES_INVOICE_CHART_BASIC_OPEN,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
      checkboxVariant: checkboxVariant.errorPastel,
    },
  ];
}

/**
 *
 * @param { function } t    translation function
 *
 *      pair label, value, and colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 *      - colorChip is for adding color on chip related to theme
 *
 * @returns
 */
export function salesCreditNoteStatus(t) {
  return [
    {
      label: t('label.all'),
      value: DEFAULT_ADDITIONAL_STATUS_SALES_CREDIT_NOTE_ALL,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
    {
      label: t('label.sales-credit-note-status.open'),
      value: DEFAULT_STATUS_SALES_CREDIT_NOTE_OPEN,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
    {
      label: t('label.sales-credit-note-status.paid'),
      value: DEFAULT_STATUS_SALES_CREDIT_NOTE_PAID,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
    },
    {
      label: t('label.sales-credit-note-status.partially-paid'),
      value: DEFAULT_STATUS_SALES_CREDIT_NOTE_PARTIALLY_PAID,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_INFO_STATUS,
    },
  ];
}

export function salesCreditNoteStatusV2(t) {
  return [
    ...salesCreditNoteStatus(t),
    {
      label: t('label.sales-credit-note-status.closed'),
      value: DEFAULT_STATUS_SALES_CREDIT_NOTE_CLOSED,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
    },
  ];
}

export const mappingSalesCreditNoteStatus = {
  [DEFAULT_STATUS_SALES_CREDIT_NOTE_OPEN]: 'label.sales-credit-note-status.open',
  [DEFAULT_STATUS_SALES_CREDIT_NOTE_PAID]: 'label.sales-credit-note-status.paid',
  [DEFAULT_STATUS_SALES_CREDIT_NOTE_PARTIALLY_PAID]:
    'label.sales-credit-note-status.partially-paid',
};

/**
 *
 * @param { function } t    translation function
 *
 *      pair label, value, and colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 *      - colorChip is for adding color on chip related to theme
 *
 * @returns
 */
export function salesReturnStatus(t) {
  return [
    {
      label: t('label.all'),
      value: ADDITIONAL_SALES_RETURN_STATUS_ALL,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
    {
      label: t('label.sales-return-status.open'),
      value: DEFAULT_STATUS_SALES_RETURN_OPEN,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
    {
      label: t('label.sales-return-status.closed'),
      value: DEFAULT_STATUS_SALES_RETURN_CLOSED,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
    },
  ];
}
