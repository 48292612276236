// contain all message constant
export const messageConstants = {
  CONTAINER_MESSAGE: 'userMessage',
  TRANSLATION_MESSAGE: ['ID', 'EN'],
};

export const VARIANT_MESSAGES = {
  ERROR: 'error',
  FORBIDDEN: 'forbidden',
  SUCCESS: 'success',
};

export const TYPE_MESSAGES = {
  DIALOG: 'dialog',
  SNACKBAR: 'snackbar',
};
