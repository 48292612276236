import * as React from 'react';
function SvgEarthGlobe(props) {
  return (
    <svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fill='#fff' fillOpacity={0.01} d='M0 0h24v24H0z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 21a9 9 0 100-18 9 9 0 000 18zm-.9-1.863A7.19 7.19 0 014.8 12c0-.558.072-1.089.189-1.611L9.3 14.7v.9c0 .99.81 1.8 1.8 1.8v1.737zm6.21-2.286A1.786 1.786 0 0015.6 15.6h-.9v-2.7c0-.495-.405-.9-.9-.9H8.4v-1.8h1.8c.495 0 .9-.405.9-.9V7.5h1.8c.99 0 1.8-.81 1.8-1.8v-.369c2.637 1.071 4.5 3.654 4.5 6.669 0 1.872-.72 3.573-1.89 4.851z'
        fill='#42526E'
      />
    </svg>
  );
}
export default SvgEarthGlobe;
