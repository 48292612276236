import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { withRouter } from '../../../components/withRouter/withRouter';
import { verifyEmail } from '../../../store/auth/auth.action';
import {
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_VERIFY_EMAIL_AUTH_SUCCESS,
} from '../../../utils/constants/store.constant';

class VerifyEmailPage extends Component {
  async verifyEmailUser() {
    // get token on path query
    const token = this.props.router.location.query['x-key'];

    await this.props.verifyEmail(token);
  }

  async componentDidMount() {
    const { auth } = this.props;

    // only request to api when status request store is iddle
    if (auth.statusRequest === STATUS_REQUEST_BASE_IDDLE) {
      await this.verifyEmailUser();
    }

    if (auth.statusRequest !== STATUS_REQUEST_VERIFY_EMAIL_AUTH_SUCCESS) {
      return this.props.navigate('/not-found', { replace: true });
    }

    if (auth.statusRequest === STATUS_REQUEST_VERIFY_EMAIL_AUTH_SUCCESS) {
      return this.props.navigate('/login', { replace: true });
    }
  }

  render() {
    return (
      <Box>
        <Typography sx={{ textAlign: 'left' }}>{'redirect...'}</Typography>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    router: state.router,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyEmail: (token) => dispatch(verifyEmail({ token })),
  };
};

// add translation into component
const VerifyEmailPageTranslate = withTranslation()(VerifyEmailPage);

// connect component into store and dispatch
const ConnectedVerifyEmailPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerifyEmailPageTranslate);

export default withRouter(ConnectedVerifyEmailPage);
