import axios from 'axios';

import { REACT_APP_API_URL_EMAIL } from '../../../utils/configs/api.config';

const resendEmailUser = async (email) => {
  if (!email) {
    return null;
  }

  return await axios({
    method: 'POST',
    url: `${REACT_APP_API_URL_EMAIL}/resend`,
    data: {
      email,
    },
  });
};

const emailServices = {
  resendEmailUser,
};

export default emailServices;
