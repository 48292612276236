import { createSlice } from '@reduxjs/toolkit';

import {
  REQUEST_STATUS_LIST_TAG_FAILED,
  REQUEST_STATUS_LIST_TAG_LOADING,
  REQUEST_STATUS_LIST_TAG_SUCCESS,
  ROOT_STORE_NAME_TAG,
  SLICE_NAME_TAG_LIST,
  STATUS_REQUEST_BASE_IDDLE,
} from '../../utils/constants/store.constant';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import tagActions from './tag.action';

const initialState = {
  requestStatus: STATUS_REQUEST_BASE_IDDLE,
  [SLICE_NAME_TAG_LIST]: null,
};

const tagSlice = createSlice({
  name: ROOT_STORE_NAME_TAG,
  initialState,
  reducers: {
    ...tagActions,
    ...sliceReduceHelper.commonSliceReducers(initialState),
  },
  extraReducers: (builder) => {
    // tag list
    builder.addCase(tagActions.getTagList.fulfilled, (state, action) => {
      state[SLICE_NAME_TAG_LIST] = action.payload[SLICE_NAME_TAG_LIST];
      state.requestStatus = REQUEST_STATUS_LIST_TAG_SUCCESS;
    }),
      builder.addCase(tagActions.getTagList.pending, (state) => {
        state.requestStatus = REQUEST_STATUS_LIST_TAG_LOADING;
      }),
      builder.addCase(tagActions.getTagList.rejected, (state) => {
        state.requestStatus = REQUEST_STATUS_LIST_TAG_FAILED;
        state[SLICE_NAME_TAG_LIST] = null;
      });
  },
});

export const { actions, reducer } = tagSlice;

export default reducer;
