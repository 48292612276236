import { isEmpty, omit, pick } from 'lodash';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF,
  DEFAULT_KEY_NAME_FILE_PROPERTIES,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_UUID_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import arrHelp from '../../helpers/array.helpers';
import formatHelp from '../../helpers/format.helpers';
import objHelper from '../../helpers/object.helper';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import {
  allowKeysDataProductSalesQuotation,
  allowKeysDataSalesQuotation,
  initialDataProduct,
  initialDataSalesQuotation,
  initialValidationProduct,
  initialValidationSalesQuotation,
  relationalAdditionalCostValueNaccountForSalesQuotation,
} from './initial-data-sales-quotation';

/**
 *
 * @param { number } amount, amount generate data and validation product from sales quotation
 * @param { string } customDataKey, name key return for data
 * @param { string } customValidationKey, name key return for validation
 * @returns
 *      generated data and validation for product from sales quotation
 */
export const generateDataNValidationAddProductSQ = (
  amount = 2,
  customDataKey = 'addProductData',
  customValidationKey = 'addProductValidation',
) => {
  const usedID = arrHelp.generateArrWithFunc(amount, uuidV4);

  const addProductData = usedID.map((id) => {
    return initialDataProduct('', id);
  });

  const addProductValidation = usedID.map((id) => {
    return initialValidationProduct('', id);
  });

  return {
    [customDataKey]: addProductData,
    [customValidationKey]: addProductValidation,
  };
};

/**
 *
 * @param { number } amount, amount data generate
 * @param { string } customDataKey, name key return for data
 * @param { string } customValidationKey, name key return for validation
 * @returns
 *     generated data and validation for sales quotation
 */
export const generateDataNValidationAddSalesQuotation = (
  amount = 1,
  customDataKey = 'addSalesQuotationData',
  customValidationKey = 'addSalesQuotationValidation',
) => {
  const usedID = arrHelp.generateArrWithFunc(amount, uuidV4);

  const addSalesQuotationData = usedID.map((id) => {
    return initialDataSalesQuotation('', id);
  });

  const addSalesQuotationValidation = usedID.map((id) => {
    return initialValidationSalesQuotation('', id);
  });

  return {
    [customDataKey]: addSalesQuotationData,
    [customValidationKey]: addSalesQuotationValidation,
  };
};

/**
 *
 * @param { number } amount                 amount data generate
 * @param { string } customDataKey          name key return for data
 * @param { object } companyUserData        data user company
 * @param { string } customValidationKey    name key return for validation
 * @returns
 *     generated data and validation for sales quotation
 */
export const generateDataNValidationAddSalesQuotationV2 = ({
  amount = 1,
  companyUserData = {},
  customDataKey = 'addSalesQuotationData',
  customValidationKey = 'addSalesQuotationValidation',
}) => {
  const usedID = arrHelp.generateArrWithFunc(amount, uuidV4);

  const addSalesQuotationData = usedID.map((id) => {
    return {
      ...initialDataSalesQuotation('', id),
      accounting_period: companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF],
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
    };
  });

  const addSalesQuotationValidation = usedID.map((id) => {
    return initialValidationSalesQuotation('', id);
  });

  return {
    [customDataKey]: addSalesQuotationData,
    [customValidationKey]: addSalesQuotationValidation,
  };
};

/**
 *
 * @param { object } addSalesQuotationData, data sales quotation
 * @param { object } addProductData, data product,
 * @returns
 *      generalized data sales quotation for add
 */
export const generalizeDataAddSalesQuotation = (addSalesQuotationData, addProductData) => {
  if (!Array.isArray(addSalesQuotationData) || !Array.isArray(addProductData)) {
    return {
      product: addProductData,
      ...addSalesQuotationData,
    };
  }

  const dataProductGeneralized = Array.from(addProductData).map((productSingleData) => {
    return pick(
      {
        ...productSingleData,
        // ...productSingleData.product_id_container,
        price: formatHelp.reverseCurrencyFormatWithRegex(productSingleData.price),
      },
      allowKeysDataProductSalesQuotation,
    );
  });

  const dataAddSalesQuotationGeneralized = Array.from(addSalesQuotationData).map((singleDataSQ) => {
    const { transaction_date, expired_date, customer_id_container, attachment } = singleDataSQ;

    const keysAdditionalCostValue = Object.keys(
      relationalAdditionalCostValueNaccountForSalesQuotation,
    );
    const omittedAdditionalAccount = keysAdditionalCostValue.reduce(
      (allAddCostVal, currAddCostVal) => {
        if (!Number(singleDataSQ[currAddCostVal])) {
          allAddCostVal = allAddCostVal.concat(
            relationalAdditionalCostValueNaccountForSalesQuotation[currAddCostVal],
          );
        }

        return allAddCostVal;
      },
      [],
    );

    singleDataSQ = omit(singleDataSQ, omittedAdditionalAccount);

    const uuidAttachment =
      Array.isArray(attachment) &&
      attachment.length &&
      attachment[0][DEFAULT_KEY_NAME_FILE_PROPERTIES]
        ? attachment[0][DEFAULT_KEY_NAME_FILE_PROPERTIES][DEFAULT_KEY_NAME_UUID_API_REF] || ''
        : '';

    return {
      ...singleDataSQ,
      [DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF]: uuidAttachment,
      customer_name: !isEmpty(customer_id_container) ? customer_id_container.contact_name : '',
      transaction_date: transaction_date ? moment(transaction_date).toISOString(true) : '',
      expired_date: expired_date ? moment(expired_date).toISOString(true) : '',
      amount: formatHelp.reverseCurrencyFormatWithRegex(singleDataSQ.amount),
    };
  });

  return {
    product: dataProductGeneralized,
    ...dataAddSalesQuotationGeneralized[0],
  };
};

/**
 *
 * @param { object } bodyData, data body of update or add sales quotation, this data for sending into backend
 * @returns
 * data generalize for action data or update sales quotation in BE
 *
 */
export function generalizeDataAddUpdateSalesQuotation(bodyData) {
  bodyData = objHelper.filterKeyObj(bodyData, [], allowKeysDataSalesQuotation);

  if (Array.isArray(bodyData.product) && !isEmpty(bodyData.product)) {
    bodyData.product = bodyData.product.map((bodyProduct) => {
      const filteringKeyBodyProduct = objHelper.filterKeyObj(
        bodyProduct,
        [],
        allowKeysDataProductSalesQuotation,
      );

      const isExitedValueInROw = objHelper.filteringExistedValue(filteringKeyBodyProduct, [], true);

      if (!isEmpty(isExitedValueInROw)) {
        return filteringKeyBodyProduct;
      }
    });

    bodyData.product = arrHelp.filteringExistedValue(bodyData.product);
  }

  return (bodyData = objHelper.filteringExistedValue(bodyData));
}
