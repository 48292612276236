import * as React from 'react';
function SvgJournalDataMenu(props) {
  return (
    <svg width={78} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#journal-data-menu_svg__filter0_d_5543_188938)'>
        <path
          d='M12.074 3.475c-1.14 0-2.074.712-2.074 1.583v62.358c0 .871.933 1.584 2.074 1.584h56.532c1.14 0 2.076-.713 2.076-1.584V5.058c0-.872-.936-1.583-2.076-1.583H12.074z'
          fill='#0575A5'
        />
        <path d='M57.274 64.522H14.147V7.95h52.387v49.502l-9.26 7.069z' fill='#fff' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.487 8.001h-26.02v56.546h16.847l9.173-7.005V8.002z'
          fill='#E6F3FF'
        />
        <path d='M57.274 64.522v-7.07h9.26l-9.26 7.07z' fill='#CEE2F2' />
        <path
          d='M44.828 4.433C44.828 2.539 42.82 1 40.34 1s-4.489 1.538-4.489 3.433H30.17v7.036h20.34V4.433h-5.682zm-4.486-1.166c.839 0 1.528.522 1.528 1.166 0 .641-.688 1.16-1.528 1.16-.846 0-1.528-.519-1.528-1.16 0-.644.681-1.166 1.528-1.166z'
          fill='#5D6D7E'
        />
        <path
          d='M44.828 4.433C44.828 2.539 42.82 1 40.34 1s-4.489 1.538-4.489 3.433H30.17v3.518h20.34V4.433h-5.682zm-4.486-1.166c.839 0 1.528.522 1.528 1.166 0 .641-.688 1.16-1.528 1.16-.846 0-1.528-.519-1.528-1.16 0-.644.681-1.166 1.528-1.166z'
          fill='#34495E'
        />
        <path d='M50.512 7.216h-20.34v.735h20.34v-.735z' fill='#5D6D7E' />
        <path d='M50.512 7.953h-20.34v.735h20.34v-.735z' fill='#34495E' />
        <path
          d='M62.484 18.353H18.116v.835h44.368v-.835zM62.484 27.53H18.116v.834h44.368v-.834zM62.484 36.706H18.116v.835h44.368v-.835zM62.484 45.883H18.116v.834h44.368v-.834zM62.484 16.407H18.116v.834h44.368v-.834zM62.484 25.583H18.116v.835h44.368v-.835zM62.484 34.76H18.116v.834h44.368v-.834zM62.484 43.937H18.116v.834h44.368v-.834z'
          fill='#CFDBE6'
        />
      </g>
      <path d='M54.754 53.812l.12 5.344 6.008-2.744-6.128-2.6z' fill='#fff' />
      <path d='M76.038 35.58l-6.127-2.599-15.157 20.832 6.128 2.599L76.038 35.58z' fill='#FF9D4D' />
      <path
        d='M71.444 33.538l-1.302-.556-15.387 20.925 1.302.556 15.387-20.925zM76.035 35.486l-1.302-.556-15.387 20.924 1.303.557 15.386-20.925z'
        fill='#F28124'
      />
      <path
        d='M55.072 53.812l1.373.59-1.89 4.754.517-5.344zM60.881 56.292l-1.373-.59-4.634 3.574 6.007-2.984z'
        fill='#E1EFFA'
      />
      <path d='M54.828 57.063l.046 2.093 2.353-1.074-2.4-1.02z' fill='#5D6D7E' />
      <path
        d='M54.953 57.063l-.203 2.093.74-1.862-.537-.231zM56.69 57.803l-1.816 1.4 2.353-1.168-.536-.232z'
        fill='#34495E'
      />
      <path d='M76.53 34.905l-6.127-2.6-.49.675 6.127 2.6.49-.675z' fill='#fff' />
      <path
        d='M71.935 32.916l-1.446-.61-.576.714 1.446.61.576-.715zM76.528 34.864l-1.447-.61-.576.714 1.447.61.576-.714z'
        fill='#E1EFFA'
      />
      <path
        d='M74.937 31.583c-1.691-.717-3.723-.393-4.535.723l6.127 2.6c.813-1.117.099-2.605-1.592-3.323z'
        fill='#34495E'
      />
      <path
        d='M71.822 32.495a.587.587 0 01-.193-.128c-.178-.18-.132-.41.101-.513.863-.384 2.002-.367 2.976.046.264.112.376.345.249.52-.127.175-.445.226-.71.113-.612-.26-1.33-.27-1.872-.028a.723.723 0 01-.55-.01z'
        fill='#5E7E99'
      />
      <defs>
        <filter
          id='journal-data-menu_svg__filter0_d_5543_188938'
          x={9}
          y={0}
          width={68.682}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5543_188938' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5543_188938' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgJournalDataMenu;
