import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF,
  DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
  DEFAULT_KEY_NAME_CREDIT_NOTE_ID_API_REF,
  DEFAULT_KEY_NAME_ITEM_API_REF,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import arrHelp from '../../helpers/array.helpers';
import objHelper from '../../helpers/object.helper';
import parseResponseHelpers from '../../helpers/parse-response.helpers';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import { generalizeAttachmentFromDetails } from './initial-add-attachment';
import {
  generalizeDataAddSalesPayablePayment,
  generateDataNvalidationAddCreditNoteSalesPayablePaymentFromCreditNoteList,
  generateDataNvalidationAddSalesPayablePayment,
} from './initial-add-sales-payable-payment';
import {
  creditNoteSalesPayablePaymentCalculationNumberKeyNames,
  initialDataCreditNoteSalesPayablePayment,
  initialDataSalesPayablePayment,
  initialValidationCreditNoteSalesPayablePayment,
  initialValidationSalesPayablePayment,
} from './initial-data-sales-payable-payment';

/**
 *
 * @param   { object }  param0                      object as params function
 * @param   { object }  salesPayablePaymentDetails          data from sales payable payment details
 * @param   { object }  companyUserData                     current company user data for getting accounting period
 * @param   { object }  dataKeyname                         return of data keyname
 * @param   { object }  validationKeyname                   return of validation keyname
 *
 * @returns
 */
export const generateDataNvalidationUpdateSalesPayablePayment = ({
  salesPayablePaymentDetails,
  companyUserData,
  dataKeyname = 'salesPayablePaymentData',
  validationKeyname = 'salesPayablePaymentValidation',
}) => {
  if (isEmpty(salesPayablePaymentDetails)) {
    return generateDataNvalidationAddSalesPayablePayment({
      companyUserData,
      dataKeyname,
      validationKeyname,
    });
  }

  const accountingPeriod = !isEmpty(companyUserData)
    ? companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF]
    : null;

  salesPayablePaymentDetails = objHelper.changeFormatValue(
    salesPayablePaymentDetails,
    Object.keys(salesPayablePaymentDetails),
    [(value) => value || ''],
  );

  const {
    customer_id,
    customer_code,
    customer_name,
    transaction_no,
    account_payable_id,
    account_payable_name,
    account_payable_code,
  } = salesPayablePaymentDetails;

  const usagedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const salesPayablePaymentData = usagedID.map((id) => {
    return {
      ...initialDataSalesPayablePayment(id),
      ...salesPayablePaymentDetails,
      attachment: generalizeAttachmentFromDetails({
        dataDetails: salesPayablePaymentDetails,
      }),
      customer_id_container: {
        contact_id: customer_id,
        contact_code: customer_code,
        contact_name: customer_name,
      },
      transaction_no_container: {
        value: transaction_no,
      },
      account_payable: account_payable_id,
      account_payable_container: {
        account_id: account_payable_id,
        account_name: account_payable_name,
        account_code: account_payable_code,
      },
      accountingPeriod,
      today: new Date(),
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
      ...parseResponseHelpers.tagContainerInputFromDetails(salesPayablePaymentDetails),
    };
  });

  const salesPayablePaymentValidation = usagedID.map((id) => {
    return initialValidationSalesPayablePayment(id);
  });

  return {
    [dataKeyname]: salesPayablePaymentData,
    [validationKeyname]: salesPayablePaymentValidation,
  };
};

/**
 *
 * @param       { object }  param0                       object as params function
 * @param       { object }  salesPayablePaymentDetails          data from sales payable payment details
 * @param       { string }  dataKeyname                         return of data keyname
 * @param       { string }  validationKeyname                   return of validation keyname
 *
 * @returns
 */
export const generateDataNvalidationUpdateCreditNoteSalesPayablePayment = ({
  salesPayablePaymentDetails,
  dataKeyname = 'creditNoteSalesPayablePaymentData',
  validationKeyname = 'creditNoteSalesPayablePaymentValidation',
}) => {
  const creditNoteSalesPayablePayment = isEmpty(salesPayablePaymentDetails)
    ? []
    : salesPayablePaymentDetails[DEFAULT_KEY_NAME_ITEM_API_REF];

  if (isEmpty(creditNoteSalesPayablePayment)) {
    return {
      [dataKeyname]: [],
      [validationKeyname]: [],
    };
  }

  const usagedID = arrHelp.generateArrWithFunc(creditNoteSalesPayablePayment.length, uuidv4);

  const creditNoteSalesPayablePaymentData = usagedID.map((id, index) => {
    const currentCreditNote = objHelper.changeFormatValue(
      creditNoteSalesPayablePayment[index],
      creditNoteSalesPayablePaymentCalculationNumberKeyNames,
      [(value) => Number(value) || ''],
    );

    const { amount_paid, credit_note_no, amount_available } = currentCreditNote;

    return {
      ...initialDataCreditNoteSalesPayablePayment(id),
      ...currentCreditNote,
      transaction_no: credit_note_no,
      amount_input: amount_paid,
      amount_available_const: Number(amount_available) + Number(amount_paid),
    };
  });

  const creditNoteSalesPayablePaymentValidation = usagedID.map((id) => {
    return initialValidationCreditNoteSalesPayablePayment(id);
  });

  return {
    [dataKeyname]: creditNoteSalesPayablePaymentData,
    [validationKeyname]: creditNoteSalesPayablePaymentValidation,
  };
};

/**
 *
 * @param       { object }  param0                  object as params function
 * @param       { object }  creditNoteList                                  result from getting credit note list, that in future to combine with current
 * @param       { object }  currentCreditNoteSalesPaymentData               current data of credit note
 * @param       { object }  currentCreditNoteSalesPaymentValidation         current validation of credit note
 * @param       { string }  dataKeyname                                     return of data keyname
 * @param       { string }  validationKeyname                               return of validation keyname
 *
 * @returns
 */
export const generateDataNvalidationUpdateCreditNoteSalesPayablePaymentFromCreditNoteList = ({
  creditNoteList = [],
  currentCreditNoteSalesPaymentData = [],
  currentCreditNoteSalesPaymentValidation = [],
  dataKeyname = 'creditNoteSalesPayablePaymentData',
  validationKeyname = 'creditNoteSalesPayablePaymentValidation',
}) => {
  if (!Array.isArray(creditNoteList) || !Array.isArray(currentCreditNoteSalesPaymentData)) {
    return {
      [dataKeyname]: currentCreditNoteSalesPaymentData,
      [validationKeyname]: currentCreditNoteSalesPaymentValidation,
    };
  }

  const {
    creditNoteSalesPayablePaymentData: creditNoteSalesPaymentDataGen,
    creditNoteSalesPayablePaymentValidation: creditNoteSalesPaymentValidationGen,
  } = generateDataNvalidationAddCreditNoteSalesPayablePaymentFromCreditNoteList({
    creditNoteList,
    fillAmountInputFull: false,
  });

  const alreadyExistedCreditNoteID = new Set(
    currentCreditNoteSalesPaymentData.map(
      (creditNote) => creditNote[DEFAULT_KEY_NAME_CREDIT_NOTE_ID_API_REF],
    ),
  );

  const combineCreditNoteSalesPaymentDataWithCurrentData = [
    ...currentCreditNoteSalesPaymentData,
    ...creditNoteSalesPaymentDataGen.filter(
      (creditNote) =>
        !alreadyExistedCreditNoteID.has(creditNote[DEFAULT_KEY_NAME_CREDIT_NOTE_ID_API_REF]),
    ),
  ];

  const combineCreditNoteSalesPaymentValidationWithCurrentValidation =
    arrHelp.combineMultipleDataObjectInArray(
      combineCreditNoteSalesPaymentDataWithCurrentData,
      currentCreditNoteSalesPaymentValidation.concat(creditNoteSalesPaymentValidationGen),
      'id',
      false,
    );

  return {
    [dataKeyname]: combineCreditNoteSalesPaymentDataWithCurrentData,
    [validationKeyname]: combineCreditNoteSalesPaymentValidationWithCurrentValidation,
  };
};

/**
 *
 * @param { array } salesPayablePaymentData             sales payable payment data from user input
 * @param { array } creditNoteSalesPayablePayment       credit note sales payable payment data from user input
 * @returns
 *      generalize for data update sales payable payment
 */
export const generalizeDataUpdateSalesPayablePayment = (
  salesPayablePaymentData,
  creditNoteSalesPayablePayment,
) => {
  if (!Array.isArray(salesPayablePaymentData) || !Array.isArray(creditNoteSalesPayablePayment)) {
    return {
      salesPayablePaymentData,
      creditNoteSalesPayablePayment,
    };
  }

  const { payable_payment_id } = salesPayablePaymentData[0];

  return {
    ...generalizeDataAddSalesPayablePayment(salesPayablePaymentData, creditNoteSalesPayablePayment),
    payable_payment_id,
  };
};
