import { REACT_APP_API_URL_STOCK_TRANSFER } from '../../../utils/configs/api.config';
import { DEFAULT_STOCK_TRANSFER_LIST_PARAMS } from '../../../utils/default/params.default';
import {
  creatorAddService,
  creatorDeleteService,
  creatorDetailService,
  creatorListService,
  creatorUpdateService,
} from '../creator-services/creator-services.helper';

/**
 *
 * stock transfer services
 *
 */

// service stock transfer list
const getStockTransferList = async (paramsGetStockTransferList) => {
  return await creatorListService(
    REACT_APP_API_URL_STOCK_TRANSFER,
    paramsGetStockTransferList,
    DEFAULT_STOCK_TRANSFER_LIST_PARAMS,
  );
};

// service stock transfer details
const getStockTransferDetails = async (stockTransferID) => {
  return await creatorDetailService(REACT_APP_API_URL_STOCK_TRANSFER, stockTransferID);
};

// service delete stock transfer
const deleteStockTransfer = async (paramsDeleteStockTransfer) => {
  const { transfer_stock_id } = paramsDeleteStockTransfer;

  return await creatorDeleteService(transfer_stock_id, REACT_APP_API_URL_STOCK_TRANSFER);
};

// service add transfer stock
const addTransferStock = async (bodyTransferStock) => {
  return await creatorAddService(REACT_APP_API_URL_STOCK_TRANSFER, bodyTransferStock);
};

// service update transfer stock
const updateTransferStock = async (bodyUpdateTransferStock) => {
  const { transfer_stock_id } = bodyUpdateTransferStock;

  return await creatorUpdateService(
    transfer_stock_id,
    REACT_APP_API_URL_STOCK_TRANSFER,
    bodyUpdateTransferStock,
  );
};

export const stockTransferServices = {
  getStockTransferList,
  getStockTransferDetails,
  deleteStockTransfer,
  addTransferStock,
  updateTransferStock,
};
