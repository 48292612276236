import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import {
  DEFAULT_CALCULATION_NUMBER,
  // DEFAULT_EMPTY_CALCULATIONS_NUMBER,
} from '../../default/number.default';
import dateHelpers from '../../helpers/date.helpers';
import validation from '../../helpers/validation.helpers';

const initialID = uuidV4();

export const plainPaymentDataWithoutID = {
  supplier_id: null,
  supplier_id_container: null,
  supplier_name: '',
  supplier_address: '',
  purchase_payment_no: '',
  purchase_payment_no_container: null,
  transaction_date: new Date(),
  account_id: null,
  account_id_container: null,
  expired_date: null,
  description: '',
  amount: DEFAULT_CALCULATION_NUMBER,
  on_behalf: '',
  accounting_period: '',
  todays_date: '',
  uuid_attachment: null,
  attachment: [],
  minDateInputRef: null,
  tag: [],
  tagContainer: [],
  tagNameInput: '',
};

export const initialPaymentData = (additionalID = '', newID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainPaymentDataWithoutID,
    transaction_date: moment(),
    todays_date: new Date(),
  };
};

export const plainPaymentValidationWithoutID = {
  supplier_id: {
    error: false,
    isValid: (value) => {
      return validation.notnull(value);
    },
  },
  purchase_payment_no: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
  transaction_date: {
    error: false,
    isValid: (value, currentDate) => {
      if (currentDate !== undefined) {
        return moment(new Date(value)).isSameOrAfter(new Date(currentDate), 'D');
      }

      return validation.notnull(value) || dateHelpers.isValidDateV2(value);
    },
  },
  expired_date: {
    error: false,
    isValid: (value, transactionDate) => {
      if (transactionDate && value) {
        return moment(new Date(value)).isAfter(new Date(transactionDate), 'D');
      }

      return validation.nullable(value) || dateHelpers.isValidDateV2(value);
    },
  },
  account_id: {
    error: false,
    isValid: (value) => {
      return validation.notnull(value);
    },
  },
  on_behalf: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
  amount: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
};

export const initialPaymentValidation = (additionalID = '', newID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainPaymentValidationWithoutID,
  };
};

/**
 * amount is total bill
 * amount_available is just remaining bill for show on user
 * amount_available_const is just comparison for maximum amount_input, name was suffix by const is for determine cannot change variable
 * amount_input is just amount that inputted by user
 */
export const plainInvoiceDataWithoutID = {
  purchase_invoice_id: null,
  purchase_invoice_id_container: null,
  purchase_payment_no: null,
  expired_date: null,
  description: '',
  amount: '',
  // amount_available: DEFAULT_CALCULATION_NUMBER,
  amount_available: '',
  amount_available_const: DEFAULT_CALCULATION_NUMBER,
  amount_input: '',
  isFromDetailPurchasePayment: false,
};

export const initialInvoiceData = (additionalID = '', newID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainInvoiceDataWithoutID,
  };
};

export const plainInvoiceValidationWithoutID = {
  purchase_invoice_id: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
  purchase_payment_no: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
  transaction_date: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
  expired_date: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
  supplier_address: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
  description: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
  amount: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
  amount_available: {
    error: false,
    isValid: (value) => {
      return validation.nullable(value);
    },
  },
  amount_input: {
    error: false,
    isValid: (value) => validation.isNumberGreaterOrEqualThan(value, 1),
    // isValid: ( value, amount_available_const ) => {
    //     if( DEFAULT_EMPTY_CALCULATIONS_NUMBER.includes( value )) {
    //         return true
    //     }

    //     return Number( value ) <= Number( amount_available_const )
    // }
  },
};

export const initialInvoiceValidation = (additionalID = '', newID = '') => {
  const usedID = newID || initialID + additionalID;

  return {
    id: usedID,
    ...plainInvoiceValidationWithoutID,
  };
};

export const allowKeysPaymentData = [
  'supplier_id',
  'purchase_payment_no',
  'transaction_date',
  'account_id',
  'expired_date',
  'description',
];

export const allowKeysInvoiceData = ['amount_input'];

export const allowKeysBodyPurchasePayment = [
  'supplier_id',
  'supplier_name',
  'supplier_address',
  'transaction_no',
  'purchase_payment_no',
  'transaction_date',
  'account_id',
  'expired_date',
  'description',
  // 'amount',
  'invoice',
  'on_behalf',
  'uuid_attachment',
  'tag',
];

export const allowKeysBodyPurchasePaymentOnInvoice = [
  'purchase_invoice_id',
  // 'purchase_payment_no',
  'expired_date',
  'description',
  'amount',
  // 'amount_available',
];
