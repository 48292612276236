import axios from 'axios';
import { isEmpty, omit, pick } from 'lodash';
import qs from 'qs';

import { LOCAL_STORAGE_PRIMARY_TOKEN } from '../../../utils/constants/storage.constant';
import {
  DEFAULT_KEY_NAME_PAGE,
  DEFAULT_KEY_NAME_PAGINATE,
} from '../../../utils/default/object-keyname.default';
import { TERSIER_DEFAULT_LIST_PARAMS } from '../../../utils/default/params.default';
import { authHeader, authHeaderV2 } from '../../../utils/helpers/auth-header.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import strHelp from '../../../utils/helpers/string.helpers';

/**
 *
 * @param   { string }    endpoint                 url name for hitting list
 * @param   { object }    paramsGetList            data params for getting list data
 * @param   { array }     allowParams              params that allowing for send into be
 * @param   { object }    defaultParams            default params, such as paginate: 20 or page: 1
 * @param   { boolean }   usageEncodeParams        determine for using encode params
 * @returns
 */
export const creatorListService = async (
  endpoint,
  paramsGetList,
  allowParams = [],
  defaultParams = TERSIER_DEFAULT_LIST_PARAMS,
  usageEncodeParams = false,
) => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  paramsGetList = objHelper.withDefaultValue(paramsGetList, defaultParams);

  if (!isEmpty(allowParams)) {
    paramsGetList = objHelper.filterKeyObj(paramsGetList, [], allowParams);
  }

  if (usageEncodeParams) {
    paramsGetList = objHelper.changeFormatValue(paramsGetList, Object.keys(paramsGetList), [
      (value) => encodeURIComponent(value),
    ]);
  }

  return await axios({
    method: 'GET',
    url: endpoint,
    headers: {
      Authorization,
    },
    params: paramsGetList,
  });
};

/**
 *
 * @param   { string }    endpoint                      url name for hitting list
 * @param   { bool }      combineParams2endpoint        determine endpoint combine with params on template
 *    @default false
 * @param   { object }    paramsGetList                 data params for getting list data
 * @param   { array }     allowParams                   params that allowing for send into be
 * @param   { object }    defaultParams                 default params, such as paginate: 20 or page: 1
 * @param   { boolean }   useEncodeParamsSerializer     determine for usage params serializer
 * @param   { function }  paramsFormatter               formatting params, where params list as callback
 *      (params) => paramsFormatter(params)
 * @param   { array }     paginationKeynames            keynames for remove pagination
 *      where on params included 'usePagination = false'
 *
 * @returns
 */
export const creatorListServiceV2 = async ({
  endpoint,
  combineParams2endpoint = false,
  paramsGetList,
  allowParams = [],
  defaultParams = TERSIER_DEFAULT_LIST_PARAMS,
  useEncodeParamsSerializer = false,
  encodeValueParams = false,
  additionalQueryStringOptions = { arrayFormat: 'brackets' },
  paramsFormatter = null,
  paginationKeynames = [DEFAULT_KEY_NAME_PAGINATE, DEFAULT_KEY_NAME_PAGE],
  localStorageTokenKeyname = LOCAL_STORAGE_PRIMARY_TOKEN,
} = {}) => {
  const { Authorization } = authHeaderV2({
    localStorageTokenKeyname,
  });

  if (!Authorization) return null;

  paramsGetList = objHelper.withDefaultValue(paramsGetList, defaultParams);

  const { usagePagination = true } = paramsGetList || {};

  // prevent removed params before use
  if (combineParams2endpoint) {
    endpoint = strHelp.templateString(endpoint, paramsGetList);
  }

  if (!usagePagination) {
    paramsGetList = omit(paramsGetList, paginationKeynames);
  }

  if (!isEmpty(allowParams)) {
    paramsGetList = objHelper.filterKeyObj(paramsGetList, [], allowParams);
  }

  if (paramsFormatter && typeof paramsFormatter === 'function') {
    paramsGetList = paramsFormatter(paramsGetList);
  }

  return await axios({
    method: 'GET',
    url: endpoint,
    headers: {
      Authorization,
    },
    params: paramsGetList,
    paramsSerializer: function (params) {
      if (!isEmpty(params) && encodeValueParams) {
        params = objHelper.changeFormatValueV2(params, Object.keys(params), null, (value) =>
          typeof value === 'string' ? encodeURIComponent(value) : value,
        );
      }

      return qs.stringify(params, {
        encode: useEncodeParamsSerializer,
        ...additionalQueryStringOptions,
      });
    },
  });
};

/**
 *
 * @param { string } endpoint endpoint url
 * @param { string } identityData id or identity that makes different with other data
 * @returns
 *      service creator for detail
 */
export const creatorDetailService = async (endpoint, identityData) => {
  const { Authorization } = authHeader();

  if (!Authorization || !identityData) {
    return null;
  }

  const endpointWithIdentity = `${endpoint}/${identityData}`;

  return await axios({
    method: 'GET',
    headers: {
      Authorization,
    },
    url: endpointWithIdentity,
  });
};

/**
 *
 * @param { string } baseURL you can pass base url for getting detail service, when you need to combine with identity, you can passing such as '/sales-order/{identity}/history'
 * @param { array | object } identityToCombineOnUrl data that can passing on base url, please read on arrHelp.templateString
 * @returns
 *      creator service, same as above but enhance on url
 */
export const creatorDetailServiceV2 = async (
  baseURL,
  identityToCombineOnUrl = '',
  localStorageTokenKeyname = LOCAL_STORAGE_PRIMARY_TOKEN,
) => {
  if (!baseURL) return null;

  const { Authorization } = authHeaderV2({
    localStorageTokenKeyname,
  });

  if (!Authorization) return null;

  let url = baseURL;

  if (!isEmpty(identityToCombineOnUrl)) {
    url = strHelp.templateString(baseURL, identityToCombineOnUrl);
  }

  return await axios({
    method: 'GET',
    headers: {
      Authorization,
    },
    url,
  });
};

/**
 * @param   { object }              obj0
 *
 * @param { string }                url                     you can pass base url for getting detail service, when you need to combine with identity, you can passing such as '/sales-order/{identity}/history'
 * @param { array | object }        identityToCombineOnUrl  data that can passing on base url, please read on arrHelp.templateString
 * @param { any }                   params                  parameter for passing on url
 *
 * @param { array }                 allowParams             filtering params for only on allow params
 *      @default []
 *
 * @param { object }                defaultParams           default params when not empty
 *      @default {}
 *
 * @returns
 *      creator detail service, same as above but enhance on url
 *      adding enhance on params and adding allow params and default params
 */
export const creatorDetailServiceWithParams = async ({
  url,
  identityToCombineOnUrl,
  params,
  allowParams = [],
  defaultParams = {},
}) => {
  if (!url) return null;

  const { Authorization } = authHeader();

  if (!Authorization) return;

  let endpoint = url;

  if (!isEmpty(identityToCombineOnUrl)) {
    endpoint = strHelp.templateString(url, identityToCombineOnUrl);
  }

  let paramsGetDetails = {
    ...defaultParams,
    ...params,
  };

  if (!isEmpty(allowParams)) {
    paramsGetDetails = pick(paramsGetDetails, allowParams);
  }

  return await axios({
    method: 'GET',
    headers: {
      Authorization,
    },
    url: endpoint,
    params: paramsGetDetails,
  });
};

/**
 *
 * @param { string }    endpoint, url endpoint name
 * @param { object }    bodyAddData, data of body for send into BE
 * @param { function }  generalizeData, function for generalize data before send
 * @returns
 *      async function for handling add data after generalized data
 */
export const creatorAddService = async (endpoint, bodyAddData, generalizeData) => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  const url = endpoint;

  if (typeof generalizeData === 'function') {
    bodyAddData = generalizeData(bodyAddData);
  }

  return await axios({
    method: 'POST',
    url: url,
    headers: {
      Authorization,
    },
    data: {
      ...bodyAddData,
    },
  });
};

/**
 *
 * @param { string }            endpoint            url endpoint name
 * @param { object }            bodyAddData         data of body for send for API
 * @param { array || string }   discardKeyData      key on body data that not sending to API
 *      @default    []
 * @param { function }          generalizeData      function for generalize data before send, you can passing null when not using generalizeData
 *      @default    null
 *
 * @returns
 *      creator service
 *          with template url for service, using string template url combine with data, make sure your decodeURL, can passing on helper string template
 *          remove key on data from discard key
 *          generalize data before send them
 */
export const creatorAddServiceWithTemplateURL = async (
  url,
  data,
  discardKeyData = [],
  generalizeData = null,
) => {
  const { Authorization } = authHeader();

  if (!Authorization) return null;

  if (!isEmpty(data)) {
    url = strHelp.templateString(url, data);
  }

  if (!isEmpty(discardKeyData)) {
    data = omit(data, discardKeyData);
  }

  if (generalizeData && typeof generalizeData === 'function') {
    data = generalizeData(data);
  }

  return await axios({
    method: 'POST',
    url,
    headers: {
      Authorization,
    },
    data,
  });
};

/**
 *
 * @param { number }    identity, number id of selected data for changes
 * @param { string }    endpoint, url endpoint name
 * @param { object }    bodyUpdateData, data of body for send into BE
 * @param { function }  generalizeData, function for generalize data before send
 * @returns
 *      async function for handling update data after generalized data
 */
export const creatorUpdateService = async (identity, endpoint, bodyUpdateData, generalizeData) => {
  const { Authorization } = authHeader();

  if (!identity || !Authorization) {
    return null;
  }

  const url = `${endpoint}/${identity}`;

  if (typeof generalizeData === 'function') {
    bodyUpdateData = generalizeData(bodyUpdateData);
  }

  return await axios({
    method: 'PUT',
    url: url,
    headers: {
      Authorization,
    },
    data: {
      ...bodyUpdateData,
    },
  });
};

/**
 *
 * @param {*} param0
 * @param { string }            endpoint        passing base url on here
 * @param { object || Form }    bodyData        data for sending to endpoint
 * @param { string }            ContentType     content header that sending to endpoint
 * @returns
 */
export const creatorMultipartFormDataService = async ({
  endpoint,
  bodyData,
  params,
  combineParams2endpoint = false,
  method = 'POST',
  ContentType = 'multipart/form-data',
  grabToNewFormData = false,
  formDataKeyname = '',
}) => {
  const { Authorization } = authHeader();

  if (!Authorization || !endpoint) return null;

  const headerOptions = {
    Authorization,
    'Content-Type': ContentType,
  };

  if (combineParams2endpoint) {
    endpoint = strHelp.templateString(endpoint, params);
  }

  if (formDataKeyname && grabToNewFormData) {
    let newBodyFormData = new FormData();
    newBodyFormData.append(formDataKeyname, bodyData);

    bodyData = newBodyFormData;
  }

  if (typeof method === 'string' && method.toLowerCase() === 'post') {
    return axios.post(endpoint, bodyData, {
      headers: headerOptions,
    });
  }

  return await axios.put(endpoint, bodyData, {
    headers: headerOptions,
  });
};

/**
 *
 * @param { string }    endpoint       url endpoint name
 * @param { object }    bodyUpdateData data of body for send into BE
 * @param { function }  generalizeData function for generalize data before send
 * @returns
 *      async function for handling update data after generalized data
 *      and using template string for url
 */
export const creatorUpdateServiceWithTemplateURL = async (
  endpoint,
  bodyUpdateData,
  generalizeData,
) => {
  const { Authorization } = authHeader();

  if (!Authorization) return null;

  let modifiedBodyUpdateData = { ...bodyUpdateData };
  if (generalizeData && typeof generalizeData === 'function') {
    modifiedBodyUpdateData = generalizeData(modifiedBodyUpdateData);
  }

  return await axios({
    method: 'PUT',
    url: strHelp.templateString(endpoint, bodyUpdateData),
    headers: {
      Authorization,
    },
    data: modifiedBodyUpdateData,
  });
};

/**
 *
 * @param { number } identity, id for different with other data
 * @param { string } endpoint, url endpoint name
 * @returns
 *      async function for handling delete data
 */
export const creatorDeleteService = async (identity, endpoint) => {
  const { Authorization } = authHeader();

  if (!identity || !Authorization) {
    return null;
  }

  const url = `${endpoint}/${identity}`;

  return await axios({
    method: 'DELETE',
    url: url,
    headers: {
      Authorization,
    },
  });
};

/**
 *
 * @param   { string }          endpoint            endpoint for hitting API
 * @param   { object | Array }  subtituesTemplate   subtitue for endpoint template
 * @returns
 */
export const creatorDeleteServiceWithTemplateUrl = async (endpoint, subtituesTemplate) => {
  const { Authorization } = authHeader();

  if (!Authorization) return null;

  if (!isEmpty(subtituesTemplate)) {
    endpoint = strHelp.templateString(endpoint, subtituesTemplate);
  }

  return await axios({
    method: 'DELETE',
    url: endpoint,
    headers: {
      Authorization,
    },
  });
};
