import { isEmpty } from 'lodash';
import moment from 'moment';

import 'moment/locale/id';
import 'moment/locale/en-gb';

import { DEFAULT_FORMAT_READABLE_MONTH_YEAR } from '../default/format.default';
import { DEFAULT_MAX_LENGTH_NUMBER_REFERENCE } from '../default/max-length-data.default';
import {
  DEFAULT_CALCULATION_NUMBER,
  DEFAULT_NUMBER_SUBSTITUTE_REFERENCE_NUMBER,
} from '../default/number.default';

import objHelper from './object.helper';
import strHelp from './string.helpers';
import { objectToQueryString } from './url.helpers';

function numberPhoneFormat(number) {
  if (typeof number !== 'string') {
    return number;
  }

  let convertNumber = number.replace(/[-\s]/g, '');

  if (convertNumber.startsWith('0')) {
    convertNumber = convertNumber.slice(1);
  }

  return `+62${convertNumber}`;
}

/**
 *
 * @param { string } numberPhoneFormatted, number phone that has been formatted
 * @param { string } numberPhonePrefix, number phone prefix
 * @returns
 *  non prefix number phone
 */
function reverseNumberPhoneFormat(numberPhoneFormatted, numberPhonePrefix = '+62') {
  if (typeof numberPhoneFormatted !== 'string') {
    return numberPhoneFormatted;
  }

  if (numberPhoneFormatted.startsWith(numberPhoneFormatted)) {
    return numberPhoneFormatted.replace(numberPhonePrefix, '');
  }

  return numberPhoneFormatted;
}

function currencyFormat(value, locales = 'id-ID', currency = 'IDR') {
  if (value === undefined || value === null) return null;

  return new Intl.NumberFormat(locales, {
    style: 'currency',
    currency,
  }).format(value);
}

// formatting currency just with regex
function currencyFormatWithRegex(value, fixedNumber = 2, maxFixedNumber = 2) {
  if ((typeof value !== 'string' && typeof value !== 'number') || !value) {
    return value;
  }

  // subtition type always number type
  let changeTypeValue = value;

  if (typeof value === 'string') {
    // adding fixed number for follow count behind point
    if (changeTypeValue.indexOf('.') !== -1) {
      const countNumberBehindPoint = changeTypeValue.split('.')[1].length;

      fixedNumber =
        countNumberBehindPoint > maxFixedNumber ? maxFixedNumber : countNumberBehindPoint;
    }

    if (changeTypeValue.indexOf(',') !== -1) {
      changeTypeValue = changeTypeValue.replace(/,/g, '');

      changeTypeValue = Number(changeTypeValue);
    } else {
      changeTypeValue = Number(value);
    }
  }

  if (!fixedNumber) {
    return changeTypeValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '$&,');
  }

  return Number(changeTypeValue)
    .toFixed(fixedNumber)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

/**
 *
 * @param { string | number }   value               number of value that for fixing on comma behind
 * @param { number }            maxFixedNumber      maximum number that reached for digit on behind
 * @param { bool }              isUseOver           determine can react maximum fixed number
 * @returns
 *      returning on currentcy with auto comma on behind input
 */
function currencyWithAutoComma(value, maxFixedNumber = 2, isUseOver = false) {
  if (typeof value !== 'number' && typeof value !== 'string') return value;

  const valueOrigin = value;

  if (typeof value === 'string') value = Number(value);

  if (Number.isNaN(value) && typeof valueOrigin === 'string') {
    const amountOfCommaOnValue = valueOrigin.match(/,/g);

    if (amountOfCommaOnValue.length > 1) {
      value = reverseCurrencyFormatWithRegex(valueOrigin);

      if (Number.isNaN(Number(value))) {
        value = Number(valueOrigin);
      }
    }

    const lengthValue = valueOrigin.length;
    const lastOrderComma = valueOrigin.lastIndexOf(',');

    const positionLastOrderCommaWithLengthValue = lengthValue - (lastOrderComma + 1);

    if (positionLastOrderCommaWithLengthValue && amountOfCommaOnValue.length <= 1) {
      value = reverseCurrencyFormatWithRegex(valueOrigin);

      if (Number.isNaN(Number(value))) {
        value = Number(valueOrigin);
      }
    }
  }

  const valueOnString = String(value);

  let amountFixedNumber = 0;

  if (valueOnString.indexOf('.') !== -1) {
    const amountDigitOnBehindComma = valueOnString.split('.')[1].length;

    amountFixedNumber = amountDigitOnBehindComma;

    if (!isUseOver && amountDigitOnBehindComma > maxFixedNumber) {
      amountFixedNumber = maxFixedNumber;
    }
  }

  if (!amountFixedNumber) return valueOnString.replace(/\B(?=(\d{3})+(?!\d))/g, '$&,');

  return Number(valueOnString)
    .toFixed(amountFixedNumber)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

/**
 *
 * @param { string | number }   value                           value for converted into currency
 * @param { bool }              useAutoCommaFormatter           usage for autocomma formatter
 *      @default    false
 *
 * @param { bool }              isUsageBracketsWhenNegative     determine is using bracket when value is negative
 * @param { number }            fixedNumber                     amount fixed number following output
 * @param { number }            maxFixedNumber                  maximum fixed number on behind output
 * @param { string || number }  defaultEmptyNumber              subtitue when number is empty
 * @returns
 *      value that has been converted into currency
 *          change comma on dot, and change dot on comma
 *          input: 1.000,99     output: 1,000.00
 *      can adding brackets when value return is negative
 */
function currencyWithRegexOnBrackets(
  value,
  useAutoCommaFormatter = false,
  isUsageBracketsWhenNegative = true,
  fixedNumber = 2,
  maxFixedNumber = 2,
  defaultEmptyNumber = DEFAULT_CALCULATION_NUMBER,
) {
  if (!value)
    return defaultEmptyNumber !== undefined ? defaultEmptyNumber : DEFAULT_CALCULATION_NUMBER;

  let afterCurrencyValue = currencyFormatWithRegex(value, fixedNumber, maxFixedNumber);

  if (useAutoCommaFormatter)
    afterCurrencyValue = currencyWithAutoComma(value, maxFixedNumber, false);

  if (
    (typeof afterCurrencyValue !== 'string' && typeof afterCurrencyValue !== 'number') ||
    !afterCurrencyValue
  )
    return afterCurrencyValue;

  if (isUsageBracketsWhenNegative) {
    let valueOnNumber = Number(afterCurrencyValue);

    if (Number.isNaN(valueOnNumber)) {
      valueOnNumber = reverseCurrencyFormatWithRegex(afterCurrencyValue);

      valueOnNumber = Number(valueOnNumber);
    }

    if (valueOnNumber < 0) {
      const replacedAfterCurrencyValue = afterCurrencyValue.replace(/-/g, '');

      return `(${replacedAfterCurrencyValue})`;
    }
  }

  return afterCurrencyValue;
}

// only returning number format that has been changed into human readable format
// example input 1,111,111.00 will return 1111111.00 in number
function reverseCurrencyFormatWithRegex(value) {
  if (typeof value !== 'string' && typeof value !== 'number') {
    return value;
  }

  if (typeof value === 'number') {
    return Number(value.toString().replace(/,/g, ''));
  }

  return Number(value.replace(/,/g, ''));
}

// when get account code like 123-12312312 and account category id is 123
// returning left data of separator, output 12312312
// or when input like 12312311231 and account category id is 12
// output is 312311231
// returning account code when account category id is not prefix on account category
function getFormatDataAccountCode(accountCode, accountCategoryID, separator = '-') {
  const isSeparatorInAccountCode = accountCode.indexOf(separator) > -1;

  // checking account code always prefix by account category id
  const isAccountCategoryIDPrefixAccountCode = accountCode.indexOf(accountCategoryID) === 0;

  if (!isAccountCategoryIDPrefixAccountCode) {
    return accountCode;
  }

  // when separator not found in account code
  if (!isSeparatorInAccountCode) {
    return accountCode.replace(accountCategoryID, '');
  }

  // get data separated by separator and returning last data
  const getDataSeparator = accountCode.split(separator);

  return getDataSeparator[getDataSeparator.length - 1];
}

// returning account balance credit or debit
function getFormatAccountBalance(is_cash_or_bank) {
  return is_cash_or_bank ? 'C' : 'D';
}

// returning reverve of account balance credt or debit
function getReverseFormatAccountBalance(accountNormalBalance) {
  return accountNormalBalance === 'C';
}

// readable date with locales
function getReadableDate(utcDate, locales = 'id') {
  if (!utcDate) return 'Invalid Date';

  if (typeof utcDate === 'string' && utcDate.includes('T')) utcDate = utcDate.split('T')[0];

  return new Date(utcDate).toLocaleDateString(locales, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

/**
 *
 * @param { date } startDate, begin date
 * @param { date } endDate, end date for convert
 * @param { string } locale, locale time zone
 * @returns
 *      readable date with locales
 */
function getReadableRangeDate(startDate, endDate, locale = 'id') {
  if (!startDate && !endDate) {
    return '';
  }

  return `${getReadableDate(startDate, locale)} - ${getReadableDate(endDate, locale)}`;
}

/**
 *
 * @param { date }      startDate           begin date
 * @param { date }      endDate             end date
 * @param { function }  fnFormatter         custom date formatter
 * @param { string }    locale              localization date
 * @returns
 */
function getReadableDateRangeWithCustomFormatter(
  startDate,
  endDate,
  fnFormatter = getReadableDateV2,
  locale = 'id',
) {
  if (!startDate && !endDate) return '';

  if (typeof fnFormatter !== 'function') return '';

  return `${fnFormatter(startDate, locale)} - ${fnFormatter(endDate, locale)}`;
}

/**
 *
 * @param { string } date target date
 * @param { string } locale localization date
 * @returns
 *      formatter date with new version,
 *      if using id, then result is DD/MM/YYYY
 *      en, result is MM/DD/YYYY
 */
function getReadableDateV2(date, locale = 'id') {
  if (!date || Number.isNaN(new Date(date).getTime())) return date;

  if (/\s/g.test(date)) {
    date = date.split(' ')[0];
  }

  if (locale === 'en') {
    return moment(date).format('MM/DD/YYYY');
  }

  return moment(date).format('DD/MM/YYYY');
}

/**
 *
 * @param { string } date               target date
 * @param { string } locale             locale of translation date
 * @param { string } dateFormat         format that result on date
 *      @default        'MMMM
 * @returns
 */
function getReadableDateV3(date, locale, dateFormat = DEFAULT_FORMAT_READABLE_MONTH_YEAR) {
  if (!date) return date;

  return moment(date).locale(locale).format(dateFormat);
}

/**
 * @param { object } singleAccountData, single account data, minimum include account name and account code
 * @param { string } separator, separator with account code and account name
 * @returns
 *  account code following account name
 *  adding for replace underscore on account name
 *  example '1123-123 cash bank'
 */
function formatAccountNameWithAccountCode(singleAccountData, separator = ' ') {
  if (typeof singleAccountData !== 'object' || isEmpty(singleAccountData)) {
    return '';
  }

  let accountName = singleAccountData.account_name || '';

  if (accountName) {
    if (accountName.includes('_')) {
      accountName = accountName.replace(/_/g, ' ');
    }
  }

  const accountCode = singleAccountData.account_code || '';

  return `${accountCode}${separator}${accountName}`;
}

/**
 *
 * @param { object }    accountData     account data that for getting formatting
 * @param { bool }      isUseBrackets   determine for adding brackets on account code
 * @param { bool }      isUseUppercase  determine is using uppercase on account name or not
 * @returns
 *      formatting account data with bracket and uppercase
 */
function formatAccountDataWithBracketsNUppercase(
  accountData,
  isUseBrackets = true,
  isUseUppercase = true,
) {
  if (typeof accountData !== 'object' || isEmpty(accountData)) return accountData;

  const { account_code, account_name } = accountData;

  let accountCode = account_code || '';
  let accountName = account_name || '';

  if (accountName && accountName.includes('_')) {
    accountName = accountName.replace(/_/g, ' ');
  }

  if (accountCode && isUseBrackets) {
    accountCode = `(${accountCode})`;
  }

  if (accountName && isUseUppercase) {
    accountName = strHelp.capitalize(accountName);
  }

  return `${accountCode} ${accountName}`;
}

// get format account code
function formatAccountCode(accountCode = '', accountCategoryName = '', separator = '-') {
  if (typeof accountCode !== 'string' || accountCategoryName !== 'string') {
    return `${accountCode.toString()}${separator}${accountCategoryName.toString()}`;
  }

  return `${accountCode}${separator}${accountCategoryName}`;
}

// transaction number with format in transaction number container
// adding input code for custom number
function formatTransactionNumber(
  transactionNumberContainer,
  inputCodeUser = '',
  separator = ['/', '.'],
) {
  if (!transactionNumberContainer) {
    return '';
  }

  const { format, code, current_number } = transactionNumberContainer;

  if (!format) return `${code}${current_number + 1}`;

  let selectedSeparator = separator.filter((sp) => format.indexOf(sp) !== -1)[0];

  let splittedFormat = format.split(selectedSeparator);
  let splittedInputCodeUser = inputCodeUser.split(selectedSeparator);

  splittedFormat = splittedFormat.map((spFormat, index) => {
    if (/Y{2,}/i.test(spFormat)) {
      const lengthYear = spFormat.match(/y+/i)[0].length;

      if (inputCodeUser && splittedInputCodeUser.length) {
        if (splittedInputCodeUser[index]) {
          if (splittedInputCodeUser[index].length <= lengthYear) {
            return splittedInputCodeUser[index];
          }

          return splittedInputCodeUser[index].slice(0, lengthYear);
        }
      }

      let dateYear = new Date().getFullYear();

      if (lengthYear !== 4) {
        return dateYear.toString().slice(2);
      }

      return dateYear;
    }

    if (/D{2,}/i.test(spFormat)) {
      const lengthDayString = spFormat.match(/d+/i)[0].length;

      if (inputCodeUser && splittedInputCodeUser.length) {
        if (splittedInputCodeUser[index]) {
          if (splittedInputCodeUser[index].length <= lengthDayString) {
            return splittedInputCodeUser[index];
          }

          return splittedInputCodeUser[index].slice(0, lengthDayString);
        }
      }

      const currentDay = new Date().getDate().toString();

      if (currentDay.length < lengthDayString) {
        return `0${currentDay}`;
      }

      return currentDay;
    }

    if (/M{2,}/i.test(spFormat)) {
      const lengthMonthString = spFormat.match(/m+/i)[0].length;

      if (inputCodeUser && splittedInputCodeUser.length) {
        if (splittedInputCodeUser[index]) {
          if (splittedInputCodeUser[index].length <= lengthMonthString) {
            return splittedInputCodeUser[index];
          }

          return splittedInputCodeUser[index].slice(0, lengthMonthString);
        }
      }

      const currentMonth = new Date().getMonth().toString();

      if (currentMonth.length < lengthMonthString) {
        return `0${currentMonth}`;
      }

      return currentMonth;
    }

    if (/code/i.test(spFormat)) {
      const codeFormatted = code;

      if (index === 0) return codeFormatted;

      return selectedSeparator + codeFormatted;
    }

    if (/number/i.test(spFormat)) {
      if (index === 0)
        return String(current_number + 1).padStart(
          DEFAULT_MAX_LENGTH_NUMBER_REFERENCE,
          DEFAULT_NUMBER_SUBSTITUTE_REFERENCE_NUMBER,
        );

      return (
        selectedSeparator +
        String(current_number + 1).padStart(
          DEFAULT_MAX_LENGTH_NUMBER_REFERENCE,
          DEFAULT_NUMBER_SUBSTITUTE_REFERENCE_NUMBER,
        )
      );
    }

    return spFormat;
  });

  splittedFormat = splittedFormat.join(selectedSeparator);

  return splittedFormat.replace(selectedSeparator + selectedSeparator, '');
}

// formatting languages
// that acceptable for FE
function getLanguage(language) {
  if (typeof language !== 'string') {
    return language;
  }

  const aliasLanguages = {
    'Indonesia Language': ['id', 'idn', 'ina', 'indonesia'],
    English: ['en', 'eng', 'english'],
  };

  const resultLanguage = Object.keys(aliasLanguages).filter((key) => {
    return aliasLanguages[key].includes(language.toLowerCase());
  });

  return resultLanguage.length ? resultLanguage[0] : '';
}

// formating label on contact type following contact name
function contactNameWithType(t, contactDataContainer, maxType = 2) {
  if (typeof contactDataContainer !== 'object') {
    return contactDataContainer;
  }

  const { contact_name } = contactDataContainer;

  let contactTypes = objHelper.filterKeyObj(
    contactDataContainer,
    [],
    ['is_customer', 'is_supplier', 'is_employee', 'is_other'],
  );

  // removing suffix
  contactTypes = objHelper.changeSuffixKey(contactTypes, 'is_', true);

  contactTypes = objHelper.filteringExistedValue(contactTypes, [], true);

  const contactTypeKeysInArr = Object.keys(contactTypes);

  // change contact type into string
  let contactTypesStr = contactTypeKeysInArr
    .slice(0, maxType)
    .reduce(function (allContactType, currContactType) {
      return allContactType.concat(currContactType);
    }, []);

  if (t) {
    contactTypesStr = contactTypeKeysInArr
      .slice(0, maxType)
      .reduce(function (allContactType, currContactType) {
        return allContactType.concat(t(`base.contact-type.${currContactType}`));
      }, []);
  }

  contactTypesStr = contactTypesStr.join(' | ');

  return `${contactTypesStr} - ${contact_name}`;
}

/**
 *
 * @param { object } contactDataContainer, contact data for get contact_name and contact_code
 * @param { string } separator, separator between _code and contact_name
 * @returns
 *      contact_code and contact_name, separate with symbol
 */
function contactCodeWithName(contactDataContainer, separator = '-') {
  if (typeof contactDataContainer !== 'object') {
    return contactDataContainer;
  }

  let { contact_code, contact_name } = contactDataContainer;

  if (isEmpty(contact_code) && isEmpty(contact_name)) {
    return '';
  } else if (isEmpty(contact_code) && contact_name) {
    return contact_name;
  }

  return `${contact_code || ' '} ${separator} ${contact_name || ' '}`;
}

/**
 *
 * @param { string } accountName account name target
 * @param { boolean } isReverse determine is remove underscore or adding underscore, isReverse true, then adding underscore
 * @returns
 *      formatted account name, with or without underscore
 */
function changeFormatAccountName(accountName, isReverse = false) {
  if (typeof accountName !== 'string') {
    return accountName;
  }

  if (isReverse) {
    return accountName.replace(/\s/g, '_');
  }

  return accountName.replace(/_/g, ' ');
}

/**
 *
 * @param { string } dateFromBe, string type data date from backend
 * @returns
 *      formated date type into js
 */
function dateFormatToJS(dateFromBe) {
  if (typeof dateFromBe !== 'string') {
    return dateFromBe;
  }

  return dateFromBe.split(' ')[0].replace(/-/g, '/');
}

/**
 *
 * @param { string } statusPurchase target status purchase
 * @returns
 *      capitalized and no conjuction of status request
 */
function getStatusPurchase(statusPurchase) {
  if (typeof statusPurchase !== 'string') {
    return '';
  }

  if (statusPurchase.match(/\W/g) || statusPurchase.includes('_')) {
    statusPurchase = statusPurchase.replace(/\W/g, ' ');

    statusPurchase = statusPurchase.replace('_', ' ');
  }

  statusPurchase = strHelp.capitalize(statusPurchase);

  if (statusPurchase.match(/\s/g)) {
    statusPurchase = statusPurchase.replace(/\s/g, '');
  }

  return statusPurchase;
}

/**
 *
 * @param { string } statusTransaction target status transaction
 * @returns
 *      same as above but more common name
 *      capitalized and no conjuction of status transaction
 */
function getStatusTransaction(statusTransaction) {
  if (typeof statusTransaction !== 'string') {
    return '';
  }

  if (statusTransaction.match(/\W/g) || statusTransaction.includes('_')) {
    statusTransaction = statusTransaction.replace(/\W/g, ' ');

    statusTransaction = statusTransaction.replace('_', ' ');
  }

  statusTransaction = strHelp.capitalize(statusTransaction);

  if (statusTransaction.match(/\s/g)) {
    statusTransaction = statusTransaction.replace(/\s/g, '');
  }

  return statusTransaction;
}

/**
 *
 * @param { string } encryptDataFromURL data that encrypted in url search params
 * @returns
 *     formatted encrypt data, which is replace space to plus(+)
 */
function formattingSelectedDataFromURL(encryptDataFromURL = '') {
  if (typeof encryptDataFromURL !== 'string') {
    return '';
  }

  return encryptDataFromURL.replace(/\s/g, '+');
}

/**
 *
 * @param { string | object } baseUrl base url of page, you can adding from location instance and passing object where you minimal you passing pathname on them
 * @param { string | object } searchParams string search params or object where can automatically convert into string
 * @returns
 *      formatting base url with search params
 */
function combineBaseUrlWithSearchParams(baseUrl, searchParams = '') {
  if (typeof baseUrl !== 'string' && typeof baseUrl !== 'object') return baseUrl;

  if (typeof baseUrl === 'object') {
    const { hash, pathname, search } = baseUrl;

    if (pathname) {
      return pathname + search + hash;
    }
  }

  if (typeof searchParams === 'object') {
    searchParams = objectToQueryString(searchParams);
  }

  return baseUrl + '?' + searchParams;
}

/**
 * same functionality as above but search params not encoded
 *
 *
 */
function combineBaseUrlWithSearchParamsV2(baseUrl, searchParams = '') {
  if (typeof baseUrl !== 'string') return baseUrl;

  if (typeof searchParams === 'object') {
    searchParams = Object.keys(searchParams).reduce((res, curr) => {
      const nextSearchParams = `${curr}=${searchParams[curr]}`;

      if (!res) return nextSearchParams;

      return `${res}&${nextSearchParams}`;
    }, '');
  }

  return `${baseUrl}?${searchParams}`;
}

/**
 *
 * @param { number }    value                   target value
 * @param { any }       emptyValueSubstitute    substitue when value is empty
 * @returns
 */
function wrapperCurrencyWithAutoCommaV1(value, emptyValueSubstitute = '0') {
  if (!value) return emptyValueSubstitute;

  if (!Number.isNaN(Number(value))) value = Number(value);

  return currencyWithAutoComma(value, 2, true) || emptyValueSubstitute;
}

/**
 *
 * templating user code
 *
 * @param { string } userCode   target user code
 * @param { string } prefix     prefix for user code
 * @returns
 */
function userCodeFormat(userCode, prefix = '#') {
  if (!userCode || typeof userCode !== 'string' || userCode.indexOf(prefix) === 0) return userCode;

  return strHelp.templateString('{0}{1}', [prefix, userCode]);
}

const formatHelp = {
  numberPhoneFormat,
  reverseNumberPhoneFormat,
  currencyFormat,
  currencyFormatWithRegex,
  reverseCurrencyFormatWithRegex,
  currencyWithRegexOnBrackets,
  currencyWithAutoComma,
  getFormatDataAccountCode,
  getFormatAccountBalance,
  getReverseFormatAccountBalance,
  getReadableDate,
  getReadableDateV2,
  getReadableDateV3,
  getReadableRangeDate,
  getReadableDateRangeWithCustomFormatter,
  formatAccountNameWithAccountCode,
  formatAccountDataWithBracketsNUppercase,
  formatAccountCode,
  formatTransactionNumber,
  getLanguage,
  contactNameWithType,
  contactCodeWithName,
  changeFormatAccountName,
  dateFormatToJS,
  getStatusPurchase,
  getStatusTransaction,
  formattingSelectedDataFromURL,
  combineBaseUrlWithSearchParams,
  combineBaseUrlWithSearchParamsV2,
  wrapperCurrencyWithAutoCommaV1,
  userCodeFormat,
};

export default formatHelp;
