import axios from 'axios';

import { REACT_APP_API_URL_BANK } from '../../../utils/configs/api.config';
import { TERSIER_DEFAULT_LIST_PARAMS } from '../../../utils/default/params.default';
import { authHeader } from '../../../utils/helpers/auth-header.helpers';
import objHelper from '../../../utils/helpers/object.helper';

const getListBank = async (paramsGetListBank) => {
  const { Authorization } = authHeader();

  if (!Authorization || !paramsGetListBank) {
    return null;
  }

  paramsGetListBank = objHelper.withDefaultValue(paramsGetListBank, TERSIER_DEFAULT_LIST_PARAMS);

  paramsGetListBank = objHelper.filterKeyObj(
    paramsGetListBank,
    [],
    ['page', 'paginate', 'bank_name', 'bank_code'],
  );

  return await axios({
    method: 'GET',
    url: REACT_APP_API_URL_BANK,
    params: {
      ...paramsGetListBank,
    },
    headers: {
      Authorization,
    },
  });
};

const bankServices = {
  getListBank,
};

export default bankServices;
