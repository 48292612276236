import { pick } from 'lodash';

import { ADD_ON_KEY_NAME } from '../../default/object-keyname.default';
import objHelper from '../../helpers/object.helper';

import {
  allowedAddOnsBodyKeyname,
  allowedBodyBillingCheckoutKeyname,
  correlationMasterPackageId2packageId,
} from './initial-billing-checkout-data';

export function generalizeAddUpdateBillingCheckoutData(billingCheckoutData) {
  billingCheckoutData = objHelper.changeKeyNameWithCorrespondKeyTarget({
    obj: billingCheckoutData,
    correspondObj: correlationMasterPackageId2packageId,
  });

  const addOns = billingCheckoutData[ADD_ON_KEY_NAME]
    ? billingCheckoutData[ADD_ON_KEY_NAME].map((addOn) => {
        addOn = objHelper.changeKeyNameWithCorrespondKeyTarget({
          obj: addOn,
          correspondObj: correlationMasterPackageId2packageId,
        });

        return pick(addOn, allowedAddOnsBodyKeyname);
      })
    : [];

  return pick(
    {
      ...billingCheckoutData,
      [ADD_ON_KEY_NAME]: addOns,
    },
    allowedBodyBillingCheckoutKeyname,
  );
}
