import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  LOCAL_STORAGE_SETTINGS_WAREHOUSE_DATA,
  LOCAL_STORAGE_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE,
  LOCAL_STORAGE_SETTINGS_WAREHOUSE_PRODUCT_DATA_AUTOCOMPLETE,
} from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  SLICE_NAME_SETTINGS_WAREHOUSE_DATA,
  SLICE_NAME_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE,
  SLICE_NAME_SETTINGS_WAREHOUSE_DETAIL,
  SLICE_NAME_SETTINGS_WAREHOUSE_PRODUCT_DATA_AUTOCOMPLETE,
  STATUS_REQUEST_ADD_SETTINGS_WAREHOUSE_DATA_FAILED,
  STATUS_REQUEST_ADD_SETTINGS_WAREHOUSE_DATA_PENDING,
  STATUS_REQUEST_ADD_SETTINGS_WAREHOUSE_DATA_SUCCESS,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_DETAIL_SETTINGS_WAREHOUSE_DATA_FAILED,
  STATUS_REQUEST_DETAIL_SETTINGS_WAREHOUSE_DATA_PENDING,
  STATUS_REQUEST_DETAIL_SETTINGS_WAREHOUSE_DATA_SUCCESS,
  STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_FAILED,
  STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_PENDING,
  STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_SUCCESS,
  STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_PRODUCT_DATA_FAILED,
  STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_PRODUCT_DATA_PENDING,
  STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_PRODUCT_DATA_SUCCESS,
  STATUS_REQUEST_UPDATE_SETTINGS_WAREHOUSE_DATA_FAILED,
  STATUS_REQUEST_UPDATE_SETTINGS_WAREHOUSE_DATA_PENDING,
  STATUS_REQUEST_UPDATE_SETTINGS_WAREHOUSE_DATA_SUCCESS,
} from '../../utils/constants/store.constant';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import warehouseActions from './warehouse.action';

const initialState = {
  [SLICE_NAME_SETTINGS_WAREHOUSE_DATA]: LocalStorage.get(LOCAL_STORAGE_SETTINGS_WAREHOUSE_DATA),
  [SLICE_NAME_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE]: LocalStorage.get(
    LOCAL_STORAGE_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE,
  ),
  [SLICE_NAME_SETTINGS_WAREHOUSE_PRODUCT_DATA_AUTOCOMPLETE]: LocalStorage.get(
    LOCAL_STORAGE_SETTINGS_WAREHOUSE_PRODUCT_DATA_AUTOCOMPLETE,
  ),
  [SLICE_NAME_SETTINGS_WAREHOUSE_DETAIL]: null,
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
};

const warehouseSlice = createSlice({
  name: NAME_STORE.WAREHOUSE,
  initialState,
  reducers: {
    warehouseActions,
    clearWarehouseDataSlice: (state, action) => {
      const { sliceName, localStorageName } = action.payload;

      if (localStorageName && LocalStorage.get(localStorageName)) {
        LocalStorage.remove(localStorageName);
      }

      return {
        ...state,
        [sliceName]: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      [SLICE_NAME_SETTINGS_WAREHOUSE_DATA]: LOCAL_STORAGE_SETTINGS_WAREHOUSE_DATA,
      [SLICE_NAME_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE]:
        LOCAL_STORAGE_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE,
      [SLICE_NAME_SETTINGS_WAREHOUSE_PRODUCT_DATA_AUTOCOMPLETE]:
        LOCAL_STORAGE_SETTINGS_WAREHOUSE_PRODUCT_DATA_AUTOCOMPLETE,
    }),
  },
  extraReducers: (builder) => {
    // list warehouse data
    builder.addCase(warehouseActions.getWarehouseList.fulfilled, (state, action) => {
      state[SLICE_NAME_SETTINGS_WAREHOUSE_DATA] =
        action.payload[SLICE_NAME_SETTINGS_WAREHOUSE_DATA];
      state.statusRequest = STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_SUCCESS;
    }),
      builder.addCase(warehouseActions.getWarehouseList.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_PENDING;
      }),
      builder.addCase(warehouseActions.getWarehouseList.rejected, (state) => {
        state[SLICE_NAME_SETTINGS_WAREHOUSE_DATA] = null;
        state.statusRequest = STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_FAILED;
      }),
      // list warehouse data with dynamically slice name
      builder.addCase(
        warehouseActions.getWarehouseListWithDynamicStore.fulfilled,
        (state, action) => {
          const { sliceName } = action.payload;

          if (sliceName) {
            state[sliceName] = action.payload[sliceName];
          }

          state.statusRequest = STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_SUCCESS;
        },
      ),
      builder.addCase(warehouseActions.getWarehouseListWithDynamicStore.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_PENDING;
      }),
      builder.addCase(
        warehouseActions.getWarehouseListWithDynamicStore.rejected,
        (state, action) => {
          const { sliceName } = action.payload;

          if (sliceName) {
            state[sliceName] = action.payload[sliceName];
          }

          state.statusRequest = STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_FAILED;
        },
      ),
      // refresh list warehouse refresh
      builder.addCase(warehouseActions.getWarehouseListRefresh.fulfilled, (state, action) => {
        state[SLICE_NAME_SETTINGS_WAREHOUSE_DATA] =
          action.payload[SLICE_NAME_SETTINGS_WAREHOUSE_DATA];
        state.statusRequest = STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_SUCCESS;
      }),
      builder.addCase(warehouseActions.getWarehouseListRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_PENDING;
      }),
      builder.addCase(warehouseActions.getWarehouseListRefresh.rejected, (state) => {
        state[SLICE_NAME_SETTINGS_WAREHOUSE_DATA] = null;
        state.statusRequest = STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_FAILED;
      }),
      // list warehouse product data with dynamically slice name
      builder.addCase(
        warehouseActions.getWarehouseProductListWithDynamicStore.fulfilled,
        (state, action) => {
          const { sliceName } = action.payload;

          if (sliceName) {
            state[sliceName] = action.payload[sliceName];
          }

          state.statusRequest = STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_PRODUCT_DATA_SUCCESS;
        },
      ),
      builder.addCase(warehouseActions.getWarehouseProductListWithDynamicStore.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_PRODUCT_DATA_PENDING;
      }),
      builder.addCase(
        warehouseActions.getWarehouseProductListWithDynamicStore.rejected,
        (state, action) => {
          const { sliceName } = action.payload;

          if (sliceName) {
            state[sliceName] = action.payload[sliceName];
          }

          state.statusRequest = STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_PRODUCT_DATA_FAILED;
        },
      ),
      // detail warehouse
      builder.addCase(warehouseActions.getDetailWarehouse.fulfilled, (state, action) => {
        state[SLICE_NAME_SETTINGS_WAREHOUSE_DETAIL] =
          action.payload[SLICE_NAME_SETTINGS_WAREHOUSE_DETAIL];
        state.statusRequest = STATUS_REQUEST_DETAIL_SETTINGS_WAREHOUSE_DATA_SUCCESS;
      }),
      builder.addCase(warehouseActions.getDetailWarehouse.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_SETTINGS_WAREHOUSE_DATA_PENDING;
      }),
      builder.addCase(warehouseActions.getDetailWarehouse.rejected, (state) => {
        state[SLICE_NAME_SETTINGS_WAREHOUSE_DETAIL] = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_SETTINGS_WAREHOUSE_DATA_FAILED;
      }),
      builder.addCase(warehouseActions.addWarehouse.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SETTINGS_WAREHOUSE_DATA_SUCCESS;
      }),
      builder.addCase(warehouseActions.addWarehouse.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SETTINGS_WAREHOUSE_DATA_PENDING;
      }),
      builder.addCase(warehouseActions.addWarehouse.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_SETTINGS_WAREHOUSE_DATA_FAILED;
      }),
      builder.addCase(warehouseActions.updateWarehouse.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SETTINGS_WAREHOUSE_DATA_SUCCESS;
      }),
      builder.addCase(warehouseActions.updateWarehouse.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SETTINGS_WAREHOUSE_DATA_PENDING;
      }),
      builder.addCase(warehouseActions.updateWarehouse.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_SETTINGS_WAREHOUSE_DATA_FAILED;
      });
  },
});

export const { reducer, actions } = warehouseSlice;

export const { clearWarehouseDataSlice } = actions;

export default reducer;
