import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import { isEmpty, omit } from 'lodash';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import BoxTableNotFound from '../../components/box/BoxTableNotFound/BoxTableNotFound';
import {
  axisXYreChartPrimaryProps,
  cartesianGridReChartPrimaryProps,
  lineChartReChartPrimaryProps,
  lineReChartErrorPastelProps,
  lineReChartSuccessPastelProps,
  lineReChartTersierProps,
  responsiveContainerReChartPrimaryProps,
} from '../../utils/data/display/additional-props';
import {
  comparisonSalesExpenseType,
  comparisonSalesProfitType,
  comparisonSalesSalesType,
} from '../../utils/data/label-value/comparison-sales';
import { chartProfessionalDashboardListComponentUtils } from '../../utils/default/chart-professional-dashboard-config.default';
import { DEFAULT_FORMAT_READABLE_YEAR_MONTH } from '../../utils/default/format.default';
import arrHelp from '../../utils/helpers/array.helpers';
import formatHelp from '../../utils/helpers/format.helpers';
import objHelper from '../../utils/helpers/object.helper';

class LineChartSalesComparisonDashboard extends PureComponent {
  render() {
    const { t, i18n, data, isRequestActionLoading, chartType } = this.props;

    const {
      showLineData = [
        comparisonSalesProfitType,
        comparisonSalesSalesType,
        comparisonSalesExpenseType,
      ],
    } = data || {};

    let dataProps = omit(data, [
      'data',
      'search',
      'search_chartType',
      'search_chartUtilsType',
      'search_from_date',
      'search_isShowMessage',
      'search_to_date',
      'showLineData',
      'search_showLineData',
      'page',
      'paginate',
      'chartType',
      'chartUtilsType',
      'from_date',
      'isShowMessage',
      'to_date',
    ]);

    if (!isEmpty(dataProps)) {
      dataProps = objHelper.changeObjTypeToArrBasedKeyName({
        obj: dataProps,
        keyName: 'date',
      });

      const dateOfDataProps = arrHelp.mappingChildrenKeyOnObject(dataProps, 'date').sort();

      if (!isEmpty(dateOfDataProps)) {
        const firstMonthDateProps = dateOfDataProps[0].split('-')[1];
        const lastMonthDateProps = dateOfDataProps[dateOfDataProps.length - 1].split('-')[1];

        dataProps = dataProps.map((singleDataProps) => {
          const { date, sales, expense, profit } = singleDataProps;

          let showDate = date;
          if (firstMonthDateProps === lastMonthDateProps) {
            showDate = date.split('-')[2];
          } else {
            showDate = formatHelp.getReadableDateV3(
              date,
              i18n.language,
              DEFAULT_FORMAT_READABLE_YEAR_MONTH,
            );
          }

          return {
            ...singleDataProps,
            date: showDate,
            sales: Number(sales),
            expense: Number(expense),
            profit: Number(profit),
          };
        });
      }
    }

    const { header } = chartProfessionalDashboardListComponentUtils[chartType];

    if (isEmpty(dataProps) || isRequestActionLoading) {
      return (
        <Box minHeight={'inherit'}>
          {isRequestActionLoading ? (
            <Skeleton
              sx={{
                minHeight: 'inherit',
              }}
              width={'100%'}
              variant='rectangular'
            />
          ) : (
            <Box minHeight={'inherit'} display={'flex'}>
              <BoxTableNotFound
                showImage={false}
                textNotFound={t('typography.data-not-found', { menu: t(header) })}
                isExistButton={false}
              />
            </Box>
          )}
        </Box>
      );
    }

    return (
      <ResponsiveContainer {...responsiveContainerReChartPrimaryProps}>
        <LineChart data={dataProps} {...lineChartReChartPrimaryProps}>
          <CartesianGrid {...cartesianGridReChartPrimaryProps} />
          <XAxis dataKey='date' {...axisXYreChartPrimaryProps} />
          <YAxis
            {...axisXYreChartPrimaryProps}
            tickFormatter={(value) => {
              return formatHelp.currencyWithAutoComma(value);
            }}
          />
          <Tooltip
            formatter={(value, key) => {
              return [formatHelp.currencyWithAutoComma(Number(value)), key];
            }}
          />
          {showLineData.includes(comparisonSalesSalesType) && (
            <Line {...lineReChartSuccessPastelProps} name={t('typography.sales')} dataKey='sales' />
          )}
          {showLineData.includes(comparisonSalesProfitType) && (
            <Line
              {...lineReChartTersierProps}
              dataKey='profit'
              name={t('typography.income-statement')}
            />
          )}
          {showLineData.includes(comparisonSalesExpenseType) && (
            <Line
              {...lineReChartErrorPastelProps}
              dataKey='expense'
              name={t('typography.expense')}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    );
  }
}

const LineChartSalesComparisonDashboardWithTranslation = withTranslation()(
  LineChartSalesComparisonDashboard,
);

export default LineChartSalesComparisonDashboardWithTranslation;
