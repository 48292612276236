import * as React from 'react';
function SvgDetailView(props) {
  return (
    <svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fill='#fff' fillOpacity={0.01} d='M0 0h24v24H0z' />
      <path
        d='M14 17h6c.003 0 0-9.994 0-9.994C20 7 14 7 14 7v10zM12 5h8c1.105 0 2 .897 2 2.006v9.988A1.998 1.998 0 0120 19h-8V5zM2 7h8V5H2.997A.996.996 0 002 6v1zm0 4h8V9H2v2zm0 7c0 .552.453 1 .997 1H10v-2H2v1zm0-3h8v-2H2v2z'
        fill='#42526E'
      />
    </svg>
  );
}
export default SvgDetailView;
