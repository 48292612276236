import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Autocomplete,
  FormControl,
  OutlinedInput,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { isEmpty, isEqual } from 'lodash';

import AutocompleteCustom from '../../../../components/custom/CustomAutocomplete/CustomAutocomplete';
import DateTimePicker from '../../../../components/date-time/DateTimePicker/DateTimePicker';
import InputAdornmentClear from '../../../../components/input-adorment/InputAdornmentClear/InputAdornmentClear';
import InputAdornmentLoading from '../../../../components/input-adorment/InputAdornmentLoading/InputAdornmentLoading';
import PopperAutoWidth from '../../../../components/popper/PopperAutoWidth/PopperAutoWidth';
import { contactList } from '../../../../store/contact/contact.action';
import { clearContactData } from '../../../../store/contact/contact.slice';
import messageHelper from '../../../../store/message/message.helper';
import { getListPurchaseInvoice } from '../../../../store/purchase/purchase.action';
import {
  STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_INVOICE_PENDING,
} from '../../../../utils/constants/store.constant';
import { initialSearchPurchaseInvoice } from '../../../../utils/data/display/initial-search-purchase-invoice';
import { columnsSearchPurchaseInvoiceWithDate } from '../../../../utils/data/display/table-purchase-invoice';
import { purchaseInvoiceStatus } from '../../../../utils/data/label-value/purchase-status';
import { TERSIER_DEFAULT_LIST_PARAMS } from '../../../../utils/default/params.default';
import { DEFAULT_DELAY_TIMEOUT_IN_MS } from '../../../../utils/default/timeout.default';
import autocompleteHelper from '../../../../utils/helpers/component/autocomplete.helpers';
import dateHelpers from '../../../../utils/helpers/date.helpers';
import formatHelp from '../../../../utils/helpers/format.helpers';
import objHelper from '../../../../utils/helpers/object.helper';

import { styles } from './TableHeadSearchPurchaseInvoice.styles';

class TableHeadSearchPurchaseInvoice extends Component {
  constructor(props) {
    super(props);

    const { t } = props;

    this.state = {
      searchParams: {
        ...initialSearchPurchaseInvoice(t),
      },
      focusedInputName: null,
    };

    this.searchPurchaseInvoiceTimeoutID = null;

    this.handleClearSingleFilter = this.handleClearSingleFilter.bind(this);

    this.handleOnChangeInput = this.handleOnChangeInput.bind(this);
    this.handleOnOpenAutocomplete = this.handleOnOpenAutocomplete.bind(this);
    this.handleOnCloseAutocomplete = this.handleOnCloseAutocomplete.bind(this);
    this.handleOnScrollAutocomplete = this.handleOnScrollAutocomplete.bind(this);
    this.handleChangeAutocomplete = this.handleChangeAutocomplete.bind(this);
  }

  async handleClearSingleFilter(event) {
    event.stopPropagation();

    const { name } = event.currentTarget;

    const { searchParams: currSearchParams } = this.state;

    const newSearchParams = {
      ...currSearchParams,
      [name]: '',
    };

    this.setState((prevState) => ({
      ...prevState,
      searchParams: {
        ...newSearchParams,
      },
    }));

    const { purchase } = this.props;
    const { purchaseInvoice } = purchase;

    if (purchase.statusRequest === STATUS_REQUEST_LIST_PURCHASE_INVOICE_PENDING) return;

    const paginate = purchaseInvoice ? purchaseInvoice.per_page : 20;

    await this.props.getListPurchaseInvoice({
      paginate,
      ...newSearchParams,
    });
  }

  handleOnChangeDate(newValueDate) {
    const { searchParams: currSearchParams } = this.state;

    const isDateValueNotChanged = isEqual(
      newValueDate,
      objHelper.filterKeyObj(currSearchParams, [], columnsSearchPurchaseInvoiceWithDate),
    );

    if (isDateValueNotChanged) return;

    if (!isEmpty(newValueDate)) {
      const { from_date, to_date } = newValueDate;

      if (
        !dateHelpers.isValidDateV2(new Date(from_date)) ||
        !dateHelpers.isValidDateV2(new Date(to_date))
      ) {
        const { t } = this.props;

        this.props.setFailedMessage(
          t('dashboard.purchase.purchase-invoice.message.invalid-date-format.primary'),
          t('dashboard.purchase.purchase-invoice.message.invalid-date-format.secondary'),
        );

        return;
      }
    }

    const newSearchParams = {
      ...currSearchParams,
      ...newValueDate,
    };

    this.setState((prevState) => ({
      ...prevState,
      searchParams: {
        ...newSearchParams,
      },
    }));

    if (this.searchPurchaseInvoiceTimeoutID) {
      clearTimeout(this.searchPurchaseInvoiceTimeoutID);
    }

    this.searchPurchaseInvoiceTimeoutID = setTimeout(async () => {
      const { purchase } = this.props;
      const { purchaseInvoiceData } = purchase;

      const paginate = purchaseInvoiceData ? purchaseInvoiceData.per_page : 20;

      return await this.props.getListPurchaseInvoice({
        paginate,
        ...newSearchParams,
      });
    }, DEFAULT_DELAY_TIMEOUT_IN_MS);
  }

  handleOnChangeInput(event) {
    const { name, value } = event.target;

    const { searchParams: currSearchParams } = this.state;

    const newSearchParams = {
      ...currSearchParams,
      [name]: value,
    };

    this.setState((prevState) => ({
      ...prevState,
      searchParams: {
        ...newSearchParams,
      },
      focusedInputName: name,
    }));

    if (this.searchPurchaseInvoiceTimeoutID) {
      clearTimeout(this.searchPurchaseInvoiceTimeoutID);
    }

    this.searchPurchaseInvoiceTimeoutID = setTimeout(async () => {
      const { purchase } = this.props;
      const { purchaseInvoiceData } = purchase;

      const paginate = purchaseInvoiceData ? purchaseInvoiceData.per_page : 20;

      return await this.props.getListPurchaseInvoice({
        paginate,
        ...newSearchParams,
      });
    }, DEFAULT_DELAY_TIMEOUT_IN_MS);

    if (name === 'supplier_name') {
      const { value } = event.target;

      const { contact } = this.props;

      if (contact.statusRequest === STATUS_REQUEST_LIST_CONTACT_DATA_PENDING) {
        return;
      }

      if (this.searchPurchaseInvoiceTimeoutID) {
        clearTimeout(this.searchPurchaseInvoiceTimeoutID);
      }

      this.searchPurchaseInvoiceTimeoutID = setTimeout(async () => {
        return await this.props.getListContact({
          ...TERSIER_DEFAULT_LIST_PARAMS,
          type: ['is_supplier'],
          contact_name: value,
        });
      }, 750);
    }
  }

  async handleOnOpenAutocomplete(event, name) {
    event.preventDefault();
    const { contact } = this.props;

    if (
      name === 'supplier_name' &&
      contact.statusRequest !== STATUS_REQUEST_LIST_CONTACT_DATA_PENDING
    ) {
      await this.props.getListContact({
        ...TERSIER_DEFAULT_LIST_PARAMS,
        type: ['is_supplier'],
      });
    }
  }

  async handleOnCloseAutocomplete(event, name) {
    if (name === 'supplier_name') {
      await this.props.clearContactData();
    }
  }

  async handleOnScrollAutocomplete(name) {
    if (name === 'supplier_name') {
      const { contact } = this.props;
      const { contactData } = contact;

      if (!contactData) return;

      const {
        current_page: page,
        next_page_url,
        per_page: paginate,
        search_contact_name: contact_name,
      } = contactData;

      if (!next_page_url) return;

      await this.props.getListContact({
        page: page + 1,
        paginate,
        type: ['is_supplier'],
        contact_name,
        isRewriteAll: false,
      });
    }
  }

  /**
   *
   * @param { object } event built-in event data
   * @param { array } newValue selected new value autocomplete
   * @param { string } containerName name of container data, usually contain all data before ge data in secondary name
   * @param { string } secondaryName name container for handling store data for send to BE
   * @param { string } valueSecondaryKey name key of container data value
   * @returns
   *      set new state and hit purchase order again
   */
  async handleChangeAutocomplete(event, newValue, containerName, secondaryName, valueSecondaryKey) {
    event.preventDefault();

    const { searchParams: currSearchParams } = this.state;

    if (containerName === 'supplier_name_container') {
      let newSearchParams = {
        ...currSearchParams,
        [containerName]: newValue,
        [secondaryName]: !isEmpty(newValue) ? newValue.contact_name : '',
      };

      this.setState((prevState) => ({
        ...prevState,
        searchParams: {
          ...newSearchParams,
        },
      }));

      const { purchase } = this.props;
      const { purchaseOrderData } = purchase;

      const paginate = purchaseOrderData ? purchaseOrderData.per_page : 20;

      return await this.props.getListPurchaseInvoice({
        paginate,
        ...newSearchParams,
      });
    }

    // check is current params is contained 'all' status
    // when current params contained and incoming data contained another status, then change into incoming data
    // check is new value is contained 'all' status
    // when contained change new value into 'all' status
    if (containerName === 'statusContainer') {
      if (!isEmpty(currSearchParams[containerName])) {
        const isCurrentStatusContainValueAll =
          currSearchParams[containerName].some((nv) => nv.value === 'all') && newValue.length > 1;

        const isNewValueContainedValueAll = newValue.some((nv) => nv.value === 'all');

        if (isCurrentStatusContainValueAll) {
          newValue = newValue.filter((nv) => nv.value !== 'all');
        } else if (isNewValueContainedValueAll) {
          newValue = newValue.filter((nv) => nv.value === 'all');
        }
      }

      const newValueSecondaryContainer = valueSecondaryKey
        ? newValue.map((value) => value[valueSecondaryKey])
        : newValue;

      let newSearchParams = {
        ...currSearchParams,
        [containerName]: newValue,
        [secondaryName]: newValueSecondaryContainer,
      };

      // remove value 'all' if exist on status
      if (newSearchParams.status.includes('all')) {
        newSearchParams = {
          ...newSearchParams,
          status: [],
        };
      }

      this.setState((prevState) => ({
        ...prevState,
        searchParams: {
          ...newSearchParams,
        },
      }));

      const { purchase } = this.props;
      const { purchaseInvoice } = purchase;

      const paginate = purchaseInvoice ? purchaseInvoice.per_page : 20;

      // only when data status is 'all' status
      if (newValueSecondaryContainer.includes('all')) {
        let removingPurchaseStatusParams = objHelper.filterKeyObj(newSearchParams, ['status']);

        return await this.props.getListPurchaseInvoice({
          paginate,
          ...removingPurchaseStatusParams,
        });
      }

      return await this.props.getListPurchaseInvoice({
        paginate,
        ...newSearchParams,
      });
    }
  }

  render() {
    const { searchParams, focusedInputName } = this.state;

    const { statusContainer: currStatusContainer, supplier_name_container } = searchParams;

    const { t, i18n, classes, dataHeaders, purchase, contact } = this.props;

    const { language: languageI18n } = i18n;

    const isPurchaseOrderLoadingData =
      purchase.statusRequest === STATUS_REQUEST_LIST_PURCHASE_INVOICE_PENDING;

    // setting on purchase invoice status list with translation
    const purchaseInvoiceStatusList = purchaseInvoiceStatus(t);

    // set temporary status purchase invoice for handle change translation from user
    const valueOnCurrentStatusContainer = currStatusContainer.map((status) => {
      return status.value;
    });

    const tempStatusPurchaseInvoiceContainer = purchaseInvoiceStatus(t).filter((status) => {
      if (valueOnCurrentStatusContainer.includes(status.value)) {
        return status;
      }
    });

    const { contactData } = contact;

    let contactDataList = [];

    let paginateContactData = TERSIER_DEFAULT_LIST_PARAMS.paginate;

    if (contactData) {
      if (Array.isArray(contactData.data)) {
        contactDataList = contactData.data;
      }

      paginateContactData = contactData.per_page;
    }

    return (
      <TableHead className={classes.TableHeadMainContainer}>
        <TableRow>
          {!isEmpty(dataHeaders) &&
            dataHeaders.map((dataHeader, index) => {
              if (!dataHeader.name_input) {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{
                      verticalAlign: 'top',
                      alignItems: 'flex-end',
                      width: dataHeader.width,
                      minWidth: dataHeader.minWidth,
                      maxWidth: dataHeader.maxWidth,
                    }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                  </TableCell>
                );
              }

              if (columnsSearchPurchaseInvoiceWithDate.includes(dataHeader.name_input)) {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{ width: dataHeader.width }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                    <DateTimePicker
                      startDate={searchParams['from_date']}
                      endDate={searchParams['to_date']}
                      textCancel={t('button.cancel')}
                      textApply={t('button.apply')}
                      placeHolderInput={dataHeader.placeholder}
                      onSelectedChange={(value) => this.handleOnChangeDate(value)}
                      locale={languageI18n}
                      defaultKeyStartDate={'from_date'}
                      defaultKeyEndDate={'to_date'}
                      isDisabledInput={isPurchaseOrderLoadingData}
                    />
                  </TableCell>
                );
              }

              if (dataHeader.name_input === 'status') {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{ width: dataHeader.width, maxWidth: dataHeader.maxWidth }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                    <FormControl fullWidth>
                      <Autocomplete
                        id='multiple-limit-tages-status-purchase'
                        size='small'
                        multiple
                        limitTags={1}
                        name={dataHeader.name_input}
                        className={classes.autocompletePurchaseStatus}
                        value={tempStatusPurchaseInvoiceContainer}
                        options={purchaseInvoiceStatusList}
                        disabled={isPurchaseOrderLoadingData}
                        getOptionLabel={(option) =>
                          autocompleteHelper.getOptionLabel(option, 'label')
                        }
                        isOptionEqualToValue={(option, value) =>
                          autocompleteHelper.isOptionEqualToValue(option, value, 'value')
                        }
                        onChange={(event, newValue) => {
                          this.handleChangeAutocomplete(
                            event,
                            newValue,
                            'statusContainer',
                            'status',
                            'value',
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={
                              isEmpty(searchParams.statusContainer) ? dataHeader.placeholder : ''
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                );
              }

              // table header for supplier
              if (dataHeader.name_input === 'supplier_name') {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{ width: dataHeader.width }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                    <FormControl>
                      <AutocompleteCustom
                        id='supplier_name'
                        name='supplier_name'
                        size='small'
                        sx={{ width: 300 }}
                        options={contactDataList}
                        value={supplier_name_container}
                        autoHighlight={true}
                        isPaginated={true}
                        totalOptions={autocompleteHelper.getOptionPaginatedAutocomplete(
                          contactDataList,
                          paginateContactData,
                          3,
                        )}
                        PopperComponent={PopperAutoWidth}
                        onOpen={(event) => this.handleOnOpenAutocomplete(event, 'supplier_name')}
                        onClose={(event) => this.handleOnCloseAutocomplete(event, 'supplier_name')}
                        onChange={(event, newValue) =>
                          this.handleChangeAutocomplete(
                            event,
                            newValue,
                            'supplier_name_container',
                            'supplier_name',
                            'contact_name',
                          )
                        }
                        ListboxProps={{
                          onScroll: (event) => {
                            // i need scroll into bottom view when api was hitted
                            const { offsetHeight, scrollHeight, scrollTop } = event.target;

                            const isEndOfScroll = offsetHeight + scrollTop === scrollHeight;

                            if (isEndOfScroll) {
                              this.handleOnScrollAutocomplete('supplier_name');
                            }
                          },
                        }}
                        getOptionLabel={(option) =>
                          autocompleteHelper.getInputValue(
                            option,
                            ['contact_code', 'contact_name'],
                            ['contact_name'],
                            formatHelp.contactCodeWithName,
                          )
                        }
                        isOptionEqualToValue={(option, value) =>
                          autocompleteHelper.isOptionEqualToValue(option, value, 'contact_id')
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled={isPurchaseOrderLoadingData}
                            name='supplier_name'
                            placeholder={t(
                              'dialog.base-add-update-purchase-order.input.supplier.placeholder',
                            )}
                            onChange={this.handleOnChangeInput}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <InputAdornmentLoading
                                  isLoading={
                                    focusedInputName === 'supplier_name' &&
                                    contact.statusRequest ===
                                      STATUS_REQUEST_LIST_CONTACT_DATA_PENDING
                                  }
                                  defaultInputAdornment={params.InputProps.endAdornment}
                                />
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                );
              }

              return (
                <TableCell
                  key={index}
                  align={dataHeader.align}
                  style={{ width: dataHeader.width }}
                  className={classes.tableCellMain}
                >
                  <p>{dataHeader.label}</p>
                  <FormControl fullWidth>
                    <OutlinedInput
                      name={dataHeader.name_input}
                      autoFocus={dataHeader.name_input === focusedInputName}
                      readOnly={isPurchaseOrderLoadingData}
                      size='small'
                      placeholder={dataHeader.placeholder}
                      onChange={this.handleOnChangeInput}
                      value={searchParams[dataHeader.name_input]}
                      {...(searchParams[dataHeader.name_input] && {
                        endAdornment: (
                          <InputAdornmentClear
                            isShowIcon={searchParams[dataHeader.name_input]}
                            iconName={dataHeader.name_input}
                            isLoadingSearch={isPurchaseOrderLoadingData}
                            onClickClearButton={this.handleClearSingleFilter}
                          />
                        ),
                      })}
                    />
                  </FormControl>
                </TableCell>
              );
            })}
        </TableRow>
      </TableHead>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    purchase: state.purchase,
    contact: state.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListPurchaseInvoice: (paramsGetListPurchaseInvoice) =>
      dispatch(getListPurchaseInvoice(paramsGetListPurchaseInvoice)),
    getListContact: (paramsGetListContact) => dispatch(contactList(paramsGetListContact)),
    clearContactData: () => dispatch(clearContactData()),
    setFailedMessage: (primaryText, secondaryText) =>
      messageHelper.failedMessage(dispatch, primaryText, secondaryText, false),
  };
};

const stylingTableHeadSearchPurchaseInvoice = withStyles(styles)(TableHeadSearchPurchaseInvoice);

const TableHeadPurchaseOrderTranslate = withTranslation()(stylingTableHeadSearchPurchaseInvoice);

const ConnectedTableHeadSearchPurchaseInvoice = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TableHeadPurchaseOrderTranslate);

export default ConnectedTableHeadSearchPurchaseInvoice;
