import * as React from 'react';
function SvgPurchaseReceivablePaymentMenu(props) {
  return (
    <svg width={99} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#purchase-receivable-payment-menu_svg__filter0_d_5586_193100)'>
        <path d='M44.102 1h37.345l9.845 10.655v54.9h-47.19V1z' fill='#F3F3F8' />
        <path d='M81.446 1v10.655h9.846L81.445 1z' fill='#CCC' />
        <path
          d='M73.96 18.808L54 18.843c-.551.001-.999-.42-1-.939 0-.52.446-.942.997-.943l19.96-.035c.55 0 .998.42 1 .94 0 .519-.446.94-.997.942zM83.934 15.027l-29.94.053c-.55 0-.998-.42-.999-.94 0-.519.446-.94.997-.942l29.94-.053c.55 0 .998.42.998.94.001.52-.445.941-.996.942z'
          fill='#C3E678'
        />
        <path
          d='M55.004 22.604l-.998.002c-.551.001-.999-.42-1-.939 0-.52.446-.942.997-.943l.998-.001c.551-.001.999.42 1 .939 0 .52-.446.941-.997.942z'
          fill='#C7CFE2'
        />
        <path
          d='M78.955 22.562l-19.96.035c-.55.001-.998-.42-.999-.939 0-.52.445-.942.997-.943l19.96-.035c.55 0 .998.42.999.94 0 .52-.446.941-.997.942z'
          fill='#D7DEED'
        />
        <path
          d='M55.01 26.367l-.998.002c-.551.001-1-.42-1-.939 0-.52.446-.942.997-.942l.998-.002c.55-.001.998.42 1 .939 0 .52-.446.941-.997.942z'
          fill='#C7CFE2'
        />
        <path
          d='M76.965 26.329l-17.964.031c-.55.001-.998-.419-1-.939 0-.52.446-.941.998-.942l17.963-.032c.551 0 .999.42 1 .94 0 .519-.446.94-.997.942z'
          fill='#D7DEED'
        />
        <path
          d='M55.015 30.13l-.998.002c-.551.001-.999-.42-1-.939 0-.52.446-.941.997-.942l.998-.002c.551-.001.999.42 1 .939 0 .52-.446.942-.997.943z'
          fill='#C7CFE2'
        />
        <path
          d='M78.966 30.088l-19.96.035c-.55.001-.998-.42-.999-.939 0-.52.446-.941.997-.942l19.96-.035c.55-.001.998.42.999.939 0 .52-.445.941-.997.942z'
          fill='#D7DEED'
        />
        <path
          d='M85.117 60.903H37.383c-1.835 0-3.323-1.61-3.323-3.597V35.072c0-1.987 1.488-3.597 3.323-3.597h47.735c1.835 0 3.323 1.61 3.323 3.597v22.234c0 1.986-1.488 3.597-3.324 3.597z'
          fill='#D35933'
        />
        <path
          d='M84.689 25.91L33.148 36.004l4.664 27.892L89.353 53.8l-4.664-27.892z'
          fill='#8BC180'
        />
        <path d='M84.69 25.912l-23.561 4.615v28.804l28.224-5.528-4.663-27.89z' fill='#6CAF5F' />
        <path
          d='M90.251 53.628l-4.664-27.891a.998.998 0 00-.377-.636.86.86 0 00-.683-.16l-23.398 4.583v2.007l18.652-3.653c.759 2.227 2.735 3.71 4.929 3.699l3.583 21.431-27.164 5.32v2.007l28.387-5.56c.495-.097.825-.61.735-1.147zM38.547 62.751l-4.34-25.948 26.922-5.272v-2.007l-28.144 5.512c-.495.097-.825.611-.735 1.148l4.663 27.89a.999.999 0 00.378.636.866.866 0 00.683.16l23.154-4.535v-2.007l-22.581 4.423z'
          fill='#477F3C'
        />
        <path
          d='M36.878 58.32l.933 5.578 5.154-1.01c-.515-3.08-3.24-5.125-6.087-4.568zM34.08 41.586c2.847-.558 4.737-3.507 4.222-6.588l-5.154 1.01.932 5.578zM88.42 48.225c-2.847.558-4.737 3.507-4.222 6.588l5.155-1.01-.933-5.578z'
          fill='#477F3C'
        />
        <path
          d='M87.68 69H34.82c-2.032 0-3.68-1.783-3.68-3.983V40.396c0-2.2 1.648-3.983 3.68-3.983h52.86c2.032 0 3.68 1.783 3.68 3.982v24.622c0 2.2-1.647 3.983-3.68 3.983z'
          fill='#AC451E'
        />
        <path
          d='M87.68 36.413H61.128V69H87.68c2.032 0 3.68-1.783 3.68-3.983V40.396c0-2.2-1.647-3.983-3.68-3.983z'
          fill='#8D3716'
        />
        <path
          d='M62.576 48.065h-2.65c-1.385 0-2.508-1.215-2.508-2.713V27.869c0-1.498 1.123-2.713 2.508-2.713h2.65c1.384 0 2.507 1.215 2.507 2.713v17.483c0 1.498-1.123 2.713-2.507 2.713z'
          fill='#FFC61B'
        />
        <path
          d='M62.575 25.155h-1.447v22.91h1.447c1.385 0 2.507-1.214 2.507-2.713V27.869c0-1.499-1.122-2.713-2.507-2.713z'
          fill='#EAA22F'
        />
        <path
          d='M61.25 45.893c1.109 0 2.008-.972 2.008-2.172 0-1.2-.899-2.172-2.008-2.172-1.108 0-2.007.972-2.007 2.172 0 1.2.899 2.172 2.007 2.172z'
          fill='#A30F44'
        />
        <path
          d='M61.25 41.548c-.04 0-.08.004-.121.006v4.332c.04.003.08.007.121.007 1.11 0 2.008-.973 2.008-2.173 0-1.2-.899-2.172-2.008-2.172z'
          fill='#930000'
        />
        <path
          d='M5.788 34.87c0 1.03-1.04 1.869-2.323 1.869-1.284 0-2.325-.838-2.325-1.869 0-1.033 1.041-1.87 2.325-1.87 1.282 0 2.323.837 2.323 1.87zM38.253 37.314l-18.075 4.939 7.081 16.784 18.075-4.939-7.081-16.784z'
          fill='#FF8E31'
        />
        <path
          d='M48.774 64.415H18.487c-.96 0-1.864-.3-2.547-.849-.679-.549-1.054-1.276-1.054-2.05v-.078c0-.658.283-1.304.794-1.818.513-.512 1.236-.873 2.037-1.015l25.376-4.493c.602-.106 1.196.2 1.329.682.132.483-.248.963-.85 1.07l-25.377 4.493c-.31.053-.578.188-.777.387a.975.975 0 00-.304.694v.077c0 .296.143.574.402.783.26.208.606.325.97.325h30.287c.616 0 1.114.4 1.114.896 0 .495-.497.896-1.113.896z'
          fill='#F2F4F7'
        />
        <path
          d='M19.276 60.144c-.537 0-1.011-.314-1.098-.756l-4.582-23.094a1.055 1.055 0 00-.465-.668 1.578 1.578 0 00-.894-.265H3.465c-.616 0-1.115-.401-1.115-.896s.499-.899 1.115-.899h8.772c.856 0 1.687.249 2.339.695.651.446 1.087 1.07 1.22 1.75l4.581 23.093c.096.49-.318.95-.925 1.028-.06.008-.117.012-.176.012z'
          fill='#F2F4F7'
        />
        <path
          d='M24.404 65.885c0 1.581-1.593 2.86-3.553 2.86-1.962 0-3.555-1.279-3.555-2.86 0-1.58 1.593-2.858 3.555-2.858 1.96 0 3.553 1.278 3.553 2.858z'
          fill='#34495E'
        />
        <path
          d='M22.625 65.885c0 .79-.793 1.43-1.774 1.43-.983 0-1.775-.64-1.775-1.43 0-.788.792-1.428 1.775-1.428.98 0 1.774.64 1.774 1.428z'
          fill='#F2F4F7'
        />
        <path
          d='M49.888 65.885c0 1.581-1.592 2.86-3.554 2.86-1.961 0-3.553-1.279-3.553-2.86 0-1.58 1.592-2.858 3.553-2.858 1.962 0 3.554 1.278 3.554 2.858z'
          fill='#34495E'
        />
        <path
          d='M48.11 65.885c0 .79-.796 1.43-1.776 1.43s-1.776-.64-1.776-1.43c0-.788.796-1.428 1.776-1.428s1.777.64 1.777 1.428zM49.504 42.66a1.477 1.477 0 00-.927-.31H14.692l3.672 18.38v.002l30.494-5.4c.282-.049.543-.173.74-.368a.936.936 0 00.29-.661V43.405c0-.27-.129-.54-.384-.745zm-28.56 15.836a.952.952 0 01-.133.008c-.4 0-.753-.233-.818-.562L17.38 44.86c-.073-.364.234-.707.688-.765.449-.059.877.19.951.553l2.613 13.083c.072.364-.235.707-.688.765zm4.333-.768a.918.918 0 01-.117.007c-.407 0-.763-.24-.822-.574l-2.177-12.313c-.065-.364.25-.703.703-.755.458-.053.875.202.94.567l2.178 12.313c.065.365-.25.703-.705.755zm4.33-.768a1.176 1.176 0 01-.1.005c-.413 0-.771-.249-.822-.588l-1.743-11.542c-.056-.365.27-.7.723-.744.457-.038.868.216.926.582l1.74 11.544c.056.366-.267.698-.723.743zm4.33-.768a1.226 1.226 0 01-.083.002c-.422 0-.783-.258-.824-.603l-1.308-10.772c-.045-.367.289-.694.746-.73.463-.031.863.233.907.6l1.308 10.773c.043.367-.29.694-.746.73zm4.325-.768l-.06.002c-.431 0-.795-.269-.826-.622l-.87-10.003c-.032-.368.311-.687.77-.714.462-.019.853.252.886.62l.87 10.004c.032.369-.313.687-.77.713zm4.323-.77h-.033c-.444 0-.811-.282-.83-.641l-.435-9.233c-.016-.37.34-.68.799-.694.463-.022.843.274.861.642l.435 9.233c.018.37-.34.68-.797.694zm5.146-1.437c0 .37-.372.668-.83.668-.459 0-.83-.298-.83-.668v-8.462c0-.37.371-.668.83-.668.458 0 .83.298.83.668v8.462z'
          fill='#F2F4F7'
        />
      </g>
      <defs>
        <filter
          id='purchase-receivable-payment-menu_svg__filter0_d_5586_193100'
          x={0.14}
          y={0}
          width={98.22}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_193100' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_193100' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgPurchaseReceivablePaymentMenu;
