import { isEmpty, omit } from 'lodash';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import { DEFAULT_COMPARISON_VALUE_NUMBER } from '../../default/number.default';
import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE,
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF,
  DEFAULT_KEY_NAME_FILE_PROPERTIES,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_REF_API,
  DEFAULT_KEY_NAME_UUID_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import { DEFAULT_TRANSACTION_NUMBER_PURCHASE_DELIVERY } from '../../default/transaction-number.default';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';
import arrHelp from '../../helpers/array.helpers';
import formulaHelpers from '../../helpers/formula.helpers';
import objHelper from '../../helpers/object.helper';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import {
  allowKeysDataProductPurchaseDelivery,
  allowKeysDataPurchaseDelivery,
  initialDataProduct,
  initialDataPurchaseDelivery,
  initialValidationProduct,
  initialValidationPurchaseDelivery,
} from './initial-data-purchase-delivery';

/**
 *
 * @param { number } amount, amount generate data and validation product from purchase delivery
 * @param { string } customDataKey, name key return for data
 * @param { string } customValidationKey, name key return for validation
 * @returns
 *      generated data and validation for product from purchase delivery
 */
export const generateDataNValidationAddProductPD = (
  amount = 1,
  customDataKey = 'addProductData',
  customValidationKey = 'addProductValidation',
) => {
  const usedID = arrHelp.generateArrWithFunc(amount, uuidV4);

  const addProductData = usedID.map((id) => {
    return initialDataProduct('', id);
  });

  const addProductValidation = usedID.map((id) => {
    return initialValidationProduct('', id);
  });

  return {
    [customDataKey]: addProductData,
    [customValidationKey]: addProductValidation,
  };
};

/**
 *
 * @param { number } amount, amount data generate
 * @param { string } customDataKey, name key return for data
 * @param { string } customValidationKey, name key return for validation
 * @returns
 *     generated data and validation for purchase delivery
 */
export const generateDataNValidationAddPurchaseDelivery = (
  amount = 1,
  customDataKey = 'addPurchaseDeliveryData',
  customValidationKey = 'addPurchaseDeliveryValidation',
) => {
  const usedID = arrHelp.generateArrWithFunc(amount, uuidV4);

  const addPurchaseDeliveryData = usedID.map((id) => {
    return initialDataPurchaseDelivery('', id);
  });

  const addPurchaseDeliveryValidation = usedID.map((id) => {
    return initialValidationPurchaseDelivery('', id);
  });

  return {
    [customDataKey]: addPurchaseDeliveryData,
    [customValidationKey]: addPurchaseDeliveryValidation,
  };
};

/**
 *
 * @param { number } amount, amount data generate
 * @param { string } customDataKey, name key return for data
 * @param { string } customValidationKey, name key return for validation
 * @returns
 *     generated data and validation for purchase delivery
 */
export const generateDataNValidationAddPurchaseDeliveryV2 = ({
  amount = 1,
  companyUserData = {},
  customDataKey = 'addPurchaseDeliveryData',
  customValidationKey = 'addPurchaseDeliveryValidation',
}) => {
  const usedID = arrHelp.generateArrWithFunc(amount, uuidV4);

  const addPurchaseDeliveryData = usedID.map((id) => {
    return {
      ...initialDataPurchaseDelivery('', id),
      accounting_period: companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF],
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
    };
  });

  const addPurchaseDeliveryValidation = usedID.map((id) => {
    return initialValidationPurchaseDelivery('', id);
  });

  return {
    [customDataKey]: addPurchaseDeliveryData,
    [customValidationKey]: addPurchaseDeliveryValidation,
  };
};

/**
 *
 * @param { object } addPurchaseDeliveryData, data purchase delivery
 * @param { object } addProductData, data product,
 * @returns
 *      generalized data purchase delivery for add
 */
export const generalizeDataAddPurchaseDelivery = (addPurchaseDeliveryData, addProductData) => {
  if (!Array.isArray(addPurchaseDeliveryData) || !Array.isArray(addProductData)) {
    return {
      product: addProductData,
      ...addPurchaseDeliveryData,
    };
  }

  const dataProductGeneralized = Array.from(addProductData).map((productSingleData) => {
    const { description, quantity } = productSingleData;

    return {
      ...productSingleData,
      description: description || '',
      quantity: quantity || 0,
    };
  });

  const dataAddPurchaseDeliveryGeneralized = Array.from(addPurchaseDeliveryData).map(
    (singlePurchaseDelivery) => {
      const { delivery_date, attachment } = singlePurchaseDelivery;

      const uuidAttachment =
        Array.isArray(attachment) && attachment.length
          ? attachment[0][DEFAULT_KEY_NAME_FILE_PROPERTIES][DEFAULT_KEY_NAME_UUID_API_REF] || ''
          : '';

      return omit(
        {
          ...singlePurchaseDelivery,
          [DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF]: uuidAttachment,
          delivery_date: delivery_date
            ? moment(delivery_date).toISOString(true)
            : moment().toISOString(),
        },
        ['product'],
      );
    },
  );

  return {
    product: dataProductGeneralized,
    ...dataAddPurchaseDeliveryGeneralized[0],
  };
};

/**
 *
 * @param { object } selectedPurchaseOrderData data that selected for purchase order
 * @param { string } customDataKey returning name key of data purchase on purchase delivery
 * @param { string } customValidationKey returning name key of validation purchase on purchase delivery
 * @returns
 *      generated data and validation purchase delivery for updating data
 *      this data from purchase order, convert to purchase delivery data selected
 */
export const generateDataNValidationInitialPurchaseDeliveryFromPurchaseOrder = (
  selectedPurchaseOrderData,
  customDataKey = 'purchaseDeliveryData',
  customValidationKey = 'purchaseDeliveryValidation',
) => {
  const usedID = arrHelp.generateArrWithFunc(1, uuidV4);

  const {
    supplier_id,
    supplier_name,
    contact_code,
    purchase_order_no,
    description,
    purchase_order_id,
  } = selectedPurchaseOrderData;

  const purchaseDeliveryData = usedID.map((id) => {
    return {
      ...initialDataPurchaseDelivery('', id),
      ...selectedPurchaseOrderData,
      supplier_id: supplier_id || null,
      supplier_name: supplier_name || '',
      supplier_id_container: {
        supplier_id: supplier_id || null,
        supplier_name: supplier_name || '',
        contact_name: supplier_name || '',
        contact_code,
      },
      reference_number: DEFAULT_TRANSACTION_NUMBER_PURCHASE_DELIVERY,
      reference_number_container: {
        value: DEFAULT_TRANSACTION_NUMBER_PURCHASE_DELIVERY,
      },
      purchase_order_id: purchase_order_id || null,
      purchase_order_id_container: {
        purchase_order_no,
        purchase_order_id: purchase_order_id || null,
        // purchase_order_code: purchase_order_code || '',
      },
      description,
      discount_account_id_container: {
        account_id: selectedPurchaseOrderData.discount_account_id || '',
        account_name: selectedPurchaseOrderData.discount_account_name || '',
        account_code: selectedPurchaseOrderData.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: selectedPurchaseOrderData.expense_account_id || '',
        account_name: selectedPurchaseOrderData.expense_account_name || '',
        account_code: selectedPurchaseOrderData.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: selectedPurchaseOrderData.down_payment_account_id || '',
        account_name: selectedPurchaseOrderData.down_payment_account_name || '',
        account_code: selectedPurchaseOrderData.down_payment_account_code || '',
      },
    };
  });

  const purchaseDeliveryValidation = usedID.map((id) => {
    return initialValidationPurchaseDelivery('', id);
  });

  return {
    [customDataKey]: purchaseDeliveryData,
    [customValidationKey]: purchaseDeliveryValidation,
  };
};

/**
 *
 * @param { object } selectedPurchaseOrderData data that selected for purchase order
 * @param { string } customDataKey returning name key of data purchase on purchase delivery
 * @param { string } customValidationKey returning name key of validation purchase on purchase delivery
 * @returns
 *      generated data and validation purchase delivery for updating data
 *      this data from purchase order, convert to purchase delivery data selected
 */
export const generateDataNValidationInitialPurchaseDeliveryFromPurchaseOrderV2 = ({
  selectedPurchaseOrderData,
  currentPurchaseDeliveryData = [],
  companyUserData = {},
  customDataKey = 'purchaseDeliveryData',
  customValidationKey = 'purchaseDeliveryValidation',
}) => {
  if (isEmpty(selectedPurchaseOrderData) && isEmpty(currentPurchaseDeliveryData)) {
    return generateDataNValidationAddPurchaseDelivery(1, customDataKey, customValidationKey);
  } else if (!isEmpty(currentPurchaseDeliveryData) && isEmpty(selectedPurchaseOrderData)) {
    const generatedDataNvalidationPurchaseDelivery = generateDataNValidationAddPurchaseDelivery(
      1,
      customDataKey,
      customValidationKey,
    );

    const { reference_number, reference_number_container } = currentPurchaseDeliveryData[0];

    const newPurchaseDeliveryData = [
      {
        ...generatedDataNvalidationPurchaseDelivery[customDataKey][0],
        reference_number,
        reference_number_container,
        supplier_address: '',
      },
    ];

    return {
      [customDataKey]: newPurchaseDeliveryData,
      [customValidationKey]: generatedDataNvalidationPurchaseDelivery[customValidationKey],
    };
  }

  let revertCurrentPurchaseDeliveryData = {};

  if (!isEmpty(currentPurchaseDeliveryData) && Array.isArray(currentPurchaseDeliveryData)) {
    const {
      reference_number,
      reference_number_container,
      accounting_period,
      uuid_attachment,
      attachment,
    } = currentPurchaseDeliveryData[0];

    revertCurrentPurchaseDeliveryData = {
      accounting_period,
      reference_number,
      reference_number_container,
      uuid_attachment,
      attachment,
    };
  }

  const usedID = arrHelp.generateArrWithFunc(1, uuidV4);

  const {
    supplier_id,
    supplier_name,
    contact_code,
    purchase_order_no,
    description,
    purchase_order_id,
    warehouse_id,
    warehouse_name,
  } = selectedPurchaseOrderData;

  const purchaseDeliveryData = usedID.map((id) => {
    return {
      ...initialDataPurchaseDelivery('', id),
      ...selectedPurchaseOrderData,
      ...revertCurrentPurchaseDeliveryData,
      accounting_period: isEmpty(companyUserData)
        ? null
        : companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE],
      supplier_id: supplier_id || null,
      supplier_name: supplier_name || '',
      supplier_id_container: {
        supplier_id: supplier_id || null,
        supplier_name: supplier_name || '',
        contact_name: supplier_name || '',
        contact_code,
      },
      purchase_order_id: purchase_order_id || null,
      purchase_order_id_container: {
        purchase_order_no,
        purchase_order_id: purchase_order_id || null,
      },
      description,
      warehouse_id_container: {
        warehouse_id,
        warehouse_name,
      },
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
          documentData: selectedPurchaseOrderData,
        }),
      discount_account_id_container: {
        account_id: selectedPurchaseOrderData.discount_account_id || '',
        account_name: selectedPurchaseOrderData.discount_account_name || '',
        account_code: selectedPurchaseOrderData.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: selectedPurchaseOrderData.expense_account_id || '',
        account_name: selectedPurchaseOrderData.expense_account_name || '',
        account_code: selectedPurchaseOrderData.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: selectedPurchaseOrderData.down_payment_account_id || '',
        account_name: selectedPurchaseOrderData.down_payment_account_name || '',
        account_code: selectedPurchaseOrderData.down_payment_account_code || '',
      },
    };
  });

  const purchaseDeliveryValidation = usedID.map((id) => {
    return initialValidationPurchaseDelivery('', id);
  });

  return {
    [customDataKey]: purchaseDeliveryData,
    [customValidationKey]: purchaseDeliveryValidation,
  };
};

/**
 *
 * @param { object } selectedPurchaseOrderData data that represent purchase order data selected
 * @param { string } customDataKey name key of data that for returning
 * @param { string } customValidationKey name key of validation data for returing value
 * @returns
 *      object that represent data and validation for product purchase delivery
 *      this data from purchase order, convert to purchase delivery data selected
 */
export const generateDataNValidationInitialProductPurchaseDeliveryFromPurchaseOrder = (
  selectedPurchaseOrderData,
  customDataKey = 'productPurchaseDeliveryData',
  customValidationKey = 'productPurchaseDeliveryValidation',
) => {
  if (
    isEmpty(selectedPurchaseOrderData) ||
    isEmpty(selectedPurchaseOrderData[DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_REF_API])
  ) {
    return generateDataNValidationAddProductPD(2, customDataKey, customValidationKey);
  }

  const purchaseOrderProduct =
    selectedPurchaseOrderData[DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_REF_API];

  const purchaseOrderProductLength = purchaseOrderProduct ? purchaseOrderProduct.length : 0;

  const usedID = arrHelp.generateArrWithFunc(purchaseOrderProductLength, uuidV4);

  const productPurchaseDeliveryData = usedID.map((id, index) => {
    const {
      purchase_order_product_id,
      product_id,
      product_name,
      quantity,
      quantity_origin,
      quantity_available,
      description: product_description,
    } = purchaseOrderProduct[index];

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    return {
      ...initialDataProduct('', id),
      ...purchaseOrderProduct[index],
      purchase_order_product_id,
      product_id,
      product_id_container: {
        product_id: product_id || null,
        product_name: product_name || '',
        ...purchaseOrderProduct[index],
      },
      quantity,
      quantity_available: quantity_available - quantity,
      quantity_available_const: quantity_available,
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantity_available),
        value_comparison: quantityComparison,
      },
      product_description: product_description || '',
    };
  });

  const productPurchaseDeliveryValidation = usedID.map((id) => {
    return initialValidationProduct('', id);
  });

  return {
    [customDataKey]: productPurchaseDeliveryData,
    [customValidationKey]: productPurchaseDeliveryValidation,
  };
};

/**
 *
 * @param { object } selectedPurchaseOrderData data that represent purchase order data selected
 * @param { string } customDataKey name key of data that for returning
 * @param { string } customValidationKey name key of validation data for returing value
 * @returns
 *      object that represent data and validation for product purchase delivery
 *      this data from purchase order, convert to purchase delivery data selected
 */
export const generateDataNValidationInitialProductPurchaseDeliveryFromPurchaseOrderV2 = ({
  selectedPurchaseOrderData,
  customDataKey = 'productPurchaseDeliveryData',
  customValidationKey = 'productPurchaseDeliveryValidation',
}) => {
  if (
    isEmpty(selectedPurchaseOrderData) ||
    isEmpty(selectedPurchaseOrderData[DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_REF_API])
  ) {
    return generateDataNValidationAddProductPD(2, customDataKey, customValidationKey);
  }

  const purchaseOrderProduct =
    selectedPurchaseOrderData[DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_REF_API];

  const purchaseOrderProductLength = purchaseOrderProduct ? purchaseOrderProduct.length : 0;

  const usedID = arrHelp.generateArrWithFunc(purchaseOrderProductLength, uuidV4);

  const productPurchaseDeliveryData = usedID.map((id, index) => {
    const {
      purchase_order_product_id,
      product_id,
      product_name,
      description: product_description,
    } = purchaseOrderProduct[index];

    return {
      ...initialDataProduct('', id),
      ...purchaseOrderProduct[index],
      purchase_order_product_id,
      product_id,
      product_id_container: {
        product_id: product_id || null,
        product_name: product_name || '',
        ...purchaseOrderProduct[index],
      },
      product_description: product_description || '',
      ...formulaHelpers.parseProductAPI2display({
        product: purchaseOrderProduct[index],
      }),
    };
  });

  const productPurchaseDeliveryValidation = usedID.map((id) => {
    return initialValidationProduct('', id);
  });

  return {
    [customDataKey]: productPurchaseDeliveryData,
    [customValidationKey]: productPurchaseDeliveryValidation,
  };
};

/**
 *
 * @param { object } bodyData, data body of update or add purchase delivery, this data for sending into backend
 * @returns
 * data generalize for action data or update purchase delivery in BE
 *
 */
export function generalizeDataAddUpdatePurchaseDelivery(bodyData) {
  bodyData = objHelper.filterKeyObj(bodyData, [], allowKeysDataPurchaseDelivery);

  if (Array.isArray(bodyData.product) && !isEmpty(bodyData.product)) {
    bodyData.product = bodyData.product.map((bodyProduct) => {
      const filteringKeyBodyProduct = objHelper.filterKeyObj(
        bodyProduct,
        [],
        allowKeysDataProductPurchaseDelivery,
      );

      const isExitedValueInROw = objHelper.filteringExistedValue(filteringKeyBodyProduct, [], true);

      if (!isEmpty(isExitedValueInROw)) {
        return filteringKeyBodyProduct;
      }
    });

    bodyData.product = arrHelp.filteringExistedValue(bodyData.product);
  }

  bodyData = objHelper.filteringExistedValue(bodyData, [], true);

  return bodyData;
}
