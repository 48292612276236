/**
 * @param { function } t function for handling translation
 * @returns
 *      initial search sales delivery data with translation
 */
export const initialSearchSalesDelivery = () => {
  return {
    from_date: null,
    to_date: null,
    transaction_no: '',
    customer_name: '',
    customer_name_container: null,
    status: [],
    statusContainer: [],
    status_invoice: [],
    status_invoice_container: [],
    sales_handover_status: [],
    sales_handover_status_container: [],
  };
};
