import axios from 'axios';

import { REACT_APP_API_URL_ACCOUNT_SUB } from '../../../utils/configs/api.config';
import {
  DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
  DEFAULT_ACCOUNT_SUB_LIST_PARAMS,
} from '../../../utils/default/params.default';
import { authHeader } from '../../../utils/helpers/auth-header.helpers';
import formatHelp from '../../../utils/helpers/format.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import { creatorListService } from '../creator-services/creator-services.helper';

// listing sub account
const getSubAccountList = async (paramsGetListAccountSub) => {
  paramsGetListAccountSub = objHelper.changeFormatValueV2(
    paramsGetListAccountSub,
    DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
    null,
    formatHelp.changeFormatAccountName,
    true,
  );

  return await creatorListService(
    REACT_APP_API_URL_ACCOUNT_SUB,
    paramsGetListAccountSub,
    DEFAULT_ACCOUNT_SUB_LIST_PARAMS,
  );
};

// adding sub account
const addSubAccount = async (dataAddSubAccount) => {
  const { Authorization } = authHeader();

  if (!Authorization || !dataAddSubAccount) {
    return null;
  }

  return await axios({
    method: 'POST',
    url: REACT_APP_API_URL_ACCOUNT_SUB,
    headers: {
      Authorization,
    },
    data: {
      account: [...dataAddSubAccount],
    },
  });
};

// update sub account

// delete sub account

const subAccountServices = {
  getSubAccountList,
  addSubAccount,
};

export default subAccountServices;
