import { isEmpty, omit, pick } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_COMPARISON_VALUE_NUMBER } from '../../default/number.default';
import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE,
  DEFAULT_KEY_NAME_FILE_PROPERTIES,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_UUID_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';
import arrHelp from '../../helpers/array.helpers';
import formatHelp from '../../helpers/format.helpers';
import objHelper from '../../helpers/object.helper';
import {
  DEFAULT_CALCULATION_NUMBER,
  DEFAULT_EMPTY_CALCULATIONS_NUMBER,
} from '../default/number.default';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import {
  allowKeysProductSalesOrderData,
  allowKeysSalesOrderData,
  initialProductSalesOrderData,
  initialProductSalesOrderValidation,
  initialSalesOrderData,
  initialSalesOrderValidation,
  productSalesOrderCalculationNumberKeyNames,
  relationalAdditionalCostValueNaccountForSalesOrder,
  salesOrderCalculationNumberKeyNames,
} from './initial-data-sales-order';

/**
 *
 * @param { number } amount amount data and validation that want to generated
 * @param { string } customDataKey name key of return data
 * @param { string } customValidationKey name key return validation
 * @returns
 *      generate data and validation for sales order on menu add
 */
export const generateDataNValidationAddSalesOrder = (
  amount = 1,
  customDataKey = 'salesOrderData',
  customValidationKey = 'salesOrderValidation',
) => {
  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    initialSalesOrderData,
    initialSalesOrderValidation,
    customDataKey,
    customValidationKey,
  );
};

/**
 *
 * @param { number } amount amount data and validation that want to generated
 * @param   { object } companyUserData data where company user that used
 * @param { string } customDataKey name key of return data
 * @param { string } customValidationKey name key return validation
 * @returns
 *      generate data and validation for sales order on menu add
 */
export const generateDataNValidationAddSalesOrderV2 = ({
  amount = 1,
  companyUserData = {},
  customDataKey = 'salesOrderData',
  customValidationKey = 'salesOrderValidation',
}) => {
  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    (id) => {
      return {
        ...initialSalesOrderData(id),
        accounting_period: !isEmpty(companyUserData) ? companyUserData.accounting_periode : null,
        [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
          getAccountingPeriodCompareWithDocumentTransactionDate({
            companyUserData,
          }),
      };
    },
    initialSalesOrderValidation,
    customDataKey,
    customValidationKey,
  );
};

/**
 *
 * @param { number } amount amount data and validation that want to generated
 * @param { string } customDataKey name key of return data
 * @param { string } customValidationKey name key return validation
 * @returns
 *      generate data and validation for product of sales order on menu add
 */
export const generateDataNValidationAddProductSalesOrder = (
  amount = 2,
  customDataKey = 'productSalesOrderData',
  customValidationKey = 'productSalesOrderValidation',
) => {
  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    initialProductSalesOrderData,
    initialProductSalesOrderValidation,
    customDataKey,
    customValidationKey,
  );
};

/**
 *
 * @param { number } amount amount data and validation that want to generated
 * @param { string } customDataKey name key of return data
 * @param { string } customValidationKey name key return validation
 * @returns
 *      generate data and validation for product of sales order on menu add
 */
export const generateDataNValidationAddProductSalesOrderV2 = ({
  amount = 2,
  customDataKey = 'productSalesOrderData',
  customValidationKey = 'productSalesOrderValidation',
}) => {
  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    initialProductSalesOrderData,
    initialProductSalesOrderValidation,
    customDataKey,
    customValidationKey,
  );
};

/**
 *
 * @param { number } salesQuotationDetails      data sales quotation details
 * @param { string } customDataKey              name key of return data
 * @param { string } customValidationKey        name key return validation
 * @returns
 *      generate data and validation for create sales order from sales quotation details
 */
export const generateDataNValidationSalesOrderFromSalesQuotationDetails = (
  salesQuotationDetails,
  customDataKey = 'salesOrderData',
  customValidationKey = 'salesOrderValidation',
) => {
  if (isEmpty(salesQuotationDetails)) {
    return generateDataNValidationAddSalesOrder(1, customDataKey, customValidationKey);
  }

  salesQuotationDetails = objHelper.changeFormatValue(
    salesQuotationDetails,
    salesOrderCalculationNumberKeyNames,
    [(value) => Number(value)],
  );

  const {
    sales_quotation_id,
    transaction_no,
    customer_id,
    customer_name: contact_name,
    contact_code,
  } = salesQuotationDetails;

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const salesOrderData = usedID.map((id) => {
    return {
      ...initialSalesOrderData(id),
      ...salesQuotationDetails,
      amount_const: Number(salesQuotationDetails.amount) || DEFAULT_CALCULATION_NUMBER,
      sales_agent_id_container: {
        contact_id: salesQuotationDetails.sales_agent_id || '',
        contact_code: salesQuotationDetails.sales_agent_code || '',
        contact_name: salesQuotationDetails.sales_agent_name || '',
      },
      discount_account_id_container: {
        contact_id: salesQuotationDetails.discount_account_id || '',
        contact_name: salesQuotationDetails.discount_account_name || '',
        contact_code: salesQuotationDetails.discount_account_code || '',
      },
      expense_account_id_container: {
        contact_id: salesQuotationDetails.expense_account_id || '',
        contact_name: salesQuotationDetails.expense_account_name || '',
        contact_code: salesQuotationDetails.expense_account_code || '',
      },
      down_payment_account_id_container: {
        contact_id: salesQuotationDetails.down_payment_account_id || '',
        contact_name: salesQuotationDetails.down_payment_account_name || '',
        contact_code: salesQuotationDetails.down_payment_account_code || '',
      },
      sales_quotation_id,
      sales_quotation_id_container: {
        sales_quotation_id,
        transaction_no,
      },
      customer_id,
      customer_id_container: {
        contact_id: customer_id,
        contact_name,
        contact_code,
      },
      description: '',
      transaction_date: moment(),
      expired_date: null,
    };
  });

  const salesOrderValidation = usedID.map((id) => {
    return initialSalesOrderValidation(id);
  });

  return {
    [customDataKey]: salesOrderData,
    [customValidationKey]: salesOrderValidation,
  };
};

/**
 *
 * @param { number } salesQuotationDetails      data sales quotation details
 * @param { array }  currentSalesOrderData      current data of sales order data
 * @param { object } companyUserData            data company user
 * @param { string } customDataKey              name key of return data
 * @param { string } customValidationKey        name key return validation
 * @returns
 *      generate data and validation for create sales order from sales quotation details
 */
export const generateDataNValidationSalesOrderFromSalesQuotationDetailsV2 = ({
  salesQuotationDetails,
  currentSalesOrderData = [],
  companyUserData = {},
  customDataKey = 'salesOrderData',
  customValidationKey = 'salesOrderValidation',
}) => {
  if (isEmpty(salesQuotationDetails) && isEmpty(currentSalesOrderData)) {
    return generateDataNValidationAddSalesOrder(1, customDataKey, customValidationKey);
  } else if (!isEmpty(currentSalesOrderData) && isEmpty(salesQuotationDetails)) {
    const generatedDataNvalidationSalesOrder = generateDataNValidationAddSalesOrder(
      1,
      customDataKey,
      customValidationKey,
    );

    const { reference_number, reference_number_container, discount_type } =
      currentSalesOrderData[0];

    const newSalesOrderData = [
      {
        ...generatedDataNvalidationSalesOrder[customDataKey][0],
        reference_number,
        reference_number_container,
        customer_address: '',
        discount_type,
      },
    ];

    return {
      [customDataKey]: newSalesOrderData,
      [customValidationKey]: generatedDataNvalidationSalesOrder[customValidationKey],
    };
  }

  salesQuotationDetails = objHelper.changeFormatValue(
    salesQuotationDetails,
    salesOrderCalculationNumberKeyNames,
    [(value) => Number(value)],
  );

  let revertCurrentSalesOrderData = {};

  if (!isEmpty(currentSalesOrderData) && Array.isArray(currentSalesOrderData)) {
    const {
      discount_type,
      reference_number,
      reference_number_container,
      accounting_period,
      attachment,
      uuid_attachment,
    } = currentSalesOrderData[0];

    revertCurrentSalesOrderData = {
      discount_type,
      accounting_period,
      reference_number,
      reference_number_container,
      attachment,
      uuid_attachment,
    };
  }

  const {
    sales_quotation_id,
    transaction_no,
    customer_id,
    customer_name: contact_name,
    contact_code,
  } = salesQuotationDetails;

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const salesOrderData = usedID.map((id) => {
    return {
      ...initialSalesOrderData(id),
      ...salesQuotationDetails,
      ...revertCurrentSalesOrderData,
      sales_agent_id_container: {
        contact_id: salesQuotationDetails.sales_agent_id || '',
        contact_code: salesQuotationDetails.sales_agent_code || '',
        contact_name: salesQuotationDetails.sales_agent_name || '',
      },
      accounting_period: isEmpty(companyUserData)
        ? null
        : companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE],
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
          documentData: salesQuotationDetails,
        }),
      sales_quotation_id,
      sales_quotation_id_container: {
        sales_quotation_id,
        transaction_no,
      },
      customer_id,
      customer_id_container: {
        contact_id: customer_id,
        contact_name,
        contact_code,
      },
      description: '',
      transaction_date: moment(),
      expired_date: null,
    };
  });

  const salesOrderValidation = usedID.map((id) => {
    return initialSalesOrderValidation(id);
  });

  return {
    [customDataKey]: salesOrderData,
    [customValidationKey]: salesOrderValidation,
  };
};

/**
 *
 * @param { number } salesQuotationDetails      data sales quotation details
 * @param { string } customDataKey              name key of return data
 * @param { string } customValidationKey        name key return validation
 * @returns
 *      generate data and validation for create product in sales order from sales quotation details
 */
export const generateDataNValidationProductSalesOrderFromSalesQuotationDetails = (
  salesQuotationDetails,
  customDataKey = 'productSalesOrderData',
  customValidationKey = 'productSalesOrderValidation',
) => {
  if (isEmpty(salesQuotationDetails) || isEmpty(salesQuotationDetails.sales_quotation_product)) {
    return generateDataNValidationAddProductSalesOrder(2, customDataKey, customValidationKey);
  }

  const productsSalesQuotation = salesQuotationDetails.sales_quotation_product;

  const usedID = arrHelp.generateArrWithFunc(productsSalesQuotation.length, uuidv4);

  const productSalesOrderData = usedID.map((id, index) => {
    const {
      product_id,
      product_name,
      product_code,
      quantity,
      quantity_origin,
      price,
      description: product_description,
      product_unit,
      unit_destination,
      unit_origin,
    } = productsSalesQuotation[index];

    const amount = Number(quantity) * Number(price);

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    return {
      ...initialProductSalesOrderData(id),
      ...productsSalesQuotation[index],
      product_id_container: {
        product_id,
        product_name,
        product_code,
        ...productsSalesQuotation[index],
      },
      product_description,
      amount: formatHelp.currencyFormatWithRegex(amount),
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantity),
        value_comparison: quantityComparison,
      },
      quantity_available_with_unit: `${quantity} ${unit_origin || product_unit}`,
    };
  });

  const productSalesOrderValidation = usedID.map((id) => {
    return initialProductSalesOrderValidation(id);
  });

  return {
    [customDataKey]: productSalesOrderData,
    [customValidationKey]: productSalesOrderValidation,
  };
};

/**
 *
 * @param { number } salesQuotationDetails      data sales quotation details
 * @param { string } customDataKey              name key of return data
 * @param { string } customValidationKey        name key return validation
 * @returns
 *      generate data and validation for create product in sales order from sales quotation details
 */
export const generateDataNValidationProductSalesOrderFromSalesQuotationDetailsV2 = ({
  salesQuotationDetails,
  customDataKey = 'productSalesOrderData',
  customValidationKey = 'productSalesOrderValidation',
}) => {
  if (isEmpty(salesQuotationDetails) || isEmpty(salesQuotationDetails.sales_quotation_product)) {
    return generateDataNValidationAddProductSalesOrder(2, customDataKey, customValidationKey);
  }

  const productsSalesQuotation = salesQuotationDetails.sales_quotation_product;

  const usedID = arrHelp.generateArrWithFunc(productsSalesQuotation.length, uuidv4);

  let productSalesOrderData = usedID.map((id, index) => {
    let selectedProductSalesQuotation = productsSalesQuotation[index];

    if (!isEmpty(selectedProductSalesQuotation)) {
      selectedProductSalesQuotation = objHelper.changeFormatValue(
        selectedProductSalesQuotation,
        productSalesOrderCalculationNumberKeyNames,
        [(value) => Number(value)],
      );
    }

    const {
      product_id,
      product_name,
      product_code,
      quantity,
      quantity_origin,
      price,
      description: product_description,
      product_unit,
      unit_destination,
      unit_origin,
    } = selectedProductSalesQuotation;

    const quantity_const = Number(quantity);
    const amount = Number(quantity) * Number(price);

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    return {
      ...initialProductSalesOrderData(id),
      ...selectedProductSalesQuotation,
      product_id_container: {
        product_id,
        product_name,
        product_code,
        ...selectedProductSalesQuotation,
      },
      product_description,
      amount,
      quantity,
      quantity_const,
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantity),
        value_comparison: quantityComparison,
      },
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      quantity_available_with_unit: `${quantity} ${unit_origin || product_unit}`,
    };
  });

  let productSalesOrderValidation = usedID.map((id) => {
    return initialProductSalesOrderValidation(id);
  });

  const {
    productSalesOrderData: productSalesOrderDataGen,
    productSalesOrderValidation: productSalesOrderValidationGen,
  } = generateDataNValidationAddProductSalesOrder(1);

  productSalesOrderData = productSalesOrderData.concat(productSalesOrderDataGen);
  productSalesOrderValidation = productSalesOrderValidation.concat(productSalesOrderValidationGen);

  return {
    [customDataKey]: productSalesOrderData,
    [customValidationKey]: productSalesOrderValidation,
  };
};

/**
 *
 * @param { array } salesOrderData bunch data sales order that inputted by user on menu add
 * @param { array } productSalesOrderData data product of sales order that inputted by user on menu add
 * @returns
 *      generalize data sales order that inputted by user, follow requirement from API
 */
export const generalizeDataAddSalesOrder = (salesOrderData, productSalesOrderData) => {
  if (Array.isArray(salesOrderData) && Array.isArray(productSalesOrderData)) {
    salesOrderData = salesOrderData.map((singleSalesOrderData) => {
      const { customer_id_container, warehouse_id, transaction_date, expired_date, attachment } =
        singleSalesOrderData;

      const uuidAttachment =
        Array.isArray(attachment) && attachment.length
          ? attachment[0][DEFAULT_KEY_NAME_FILE_PROPERTIES][DEFAULT_KEY_NAME_UUID_API_REF] || ''
          : '';

      const keysAdditionalCostValue = Object.keys(
        relationalAdditionalCostValueNaccountForSalesOrder,
      );
      const omittedAdditionalAccount = keysAdditionalCostValue.reduce(
        (allAddCostVal, currAddCostVal) => {
          if (!Number(singleSalesOrderData[currAddCostVal])) {
            allAddCostVal = allAddCostVal.concat(
              relationalAdditionalCostValueNaccountForSalesOrder[currAddCostVal],
            );
          }

          return allAddCostVal;
        },
        [],
      );

      singleSalesOrderData = omit(singleSalesOrderData, omittedAdditionalAccount);

      let customer_name = '';

      if (customer_id_container) {
        const { contact_name, customer_name: customer_name_gen } = customer_id_container;

        customer_name = contact_name || customer_name_gen;
      }

      return objHelper.filteringExistedValue(
        pick(
          {
            ...singleSalesOrderData,
            customer_name,
            transaction_date: transaction_date ? moment(transaction_date).toISOString(true) : '',
            expired_date: expired_date ? moment(expired_date).toISOString(true) : '',
            warehouse_id: warehouse_id || '',
            [DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF]: uuidAttachment,
          },
          allowKeysSalesOrderData,
        ),
      );
    });

    productSalesOrderData = productSalesOrderData.map((singleProductSalesOrder) => {
      const { price, quantity } = singleProductSalesOrder;

      if (
        !price ||
        !quantity ||
        DEFAULT_EMPTY_CALCULATIONS_NUMBER.includes(price) ||
        DEFAULT_EMPTY_CALCULATIONS_NUMBER.includes(quantity)
      )
        return;

      return pick(
        {
          ...singleProductSalesOrder,
          price: price ? formatHelp.reverseCurrencyFormatWithRegex(price) : 0,
        },
        allowKeysProductSalesOrderData,
      );
    });

    productSalesOrderData = arrHelp.filterExistedValueOnObjItem(
      productSalesOrderData,
      allowKeysProductSalesOrderData,
      [],
      DEFAULT_CALCULATION_NUMBER,
    );

    return {
      product: productSalesOrderData,
      ...salesOrderData[0],
    };
  }
};
