export const styles = {
  root: {
    width: '72px',
    height: '72px',
    display: 'flex',
    justifyContent: 'center',
    background: 'none',
  },
  '@keyframes loadingSpinnerAnimation': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  loadingSpinner: {
    '& div': {
      position: 'fixed',
      animation: '$loadingSpinnerAnimation linear 1s infinite',
      background: '#85a2b6',
      width: '9px',
      height: '18px',
      borderRadius: '0px / 0px',
      transformOrigin: '4px 34.5px',
      boxSizing: 'content-box',
    },
    '& div:nth-child(1)': {
      transform: 'rotate(0deg)',
      animationDelay: '-0.875s',
      background: '#85a2b6',
    },
    '& div:nth-child(2)': {
      transform: 'rotate(45deg)',
      animationDelay: '-0.75s',
      background: '#85a2b6',
    },
    '& div:nth-child(3)': {
      transform: 'rotate(90deg)',
      animationDelay: '-0.625s',
      background: '#85a2b6',
    },
    '& div:nth-child(4)': {
      transform: 'rotate(135deg)',
      animationDelay: '-0.5s',
      background: '#85a2b6',
    },
    '& div:nth-child(5)': {
      transform: 'rotate(180deg)',
      animationDelay: '-0.375s',
      background: '#85a2b6',
    },
    '& div:nth-child(6)': {
      transform: 'rotate(225deg)',
      animationDelay: '-0.25s',
      background: '#85a2b6',
    },
    '& div:nth-child(7)': {
      transform: 'rotate(270deg)',
      animationDelay: '-0.125s',
      background: '#85a2b6',
    },
    '& div:nth-child(8)': {
      transform: 'rotate(315deg)',
      animationDelay: '0s',
      background: '#85a2b6',
    },
  },
};
