import moment from 'moment';

import { DEFAULT_TRANSACTION_NUMBER_CASH_TRANSFER } from '../../default/transaction-number.default';
import validation from '../../helpers/validation.helpers';

export const initialCashTransferData = {
  reference_number: DEFAULT_TRANSACTION_NUMBER_CASH_TRANSFER,
  reference_number_container: {
    value: DEFAULT_TRANSACTION_NUMBER_CASH_TRANSFER,
  },
  transaction_date: new Date(),
  origin_account: '',
  origin_account_container: null,
  destination_account: '',
  destination_account_container: null,
  normal_balance: '',
  transaction_description: '',
  accounting_period: null,
};

export const initialCashTransferValidation = {
  reference_number: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  transaction_date: {
    error: false,
    isValid: (value, accountingPeriod) => {
      if (!accountingPeriod) {
        return validation.isBeforeThanNow(value);
      }

      return moment(new Date(value)).isSameOrAfter(new Date(value), 'D');
    },
  },
  origin_account: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  destination_account: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  normal_balance: {
    error: false,
    isValid: (value) => validation.isNotEmptyBalance(value),
  },
  transaction_description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const allowKeysCashTransferData = [
  'reference_number',
  'transaction_date',
  'origin_account',
  'destination_account',
  'normal_balance',
  'transaction_description',
];
