import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Popper } from '@mui/core';
import { ClickAwayListener, Grow, ListItemIcon, MenuItem, MenuList, Paper } from '@mui/material';
import { isEmpty } from 'lodash';

import { theme } from '../../../assets/theme/theme';
import usePermission, { permissionFeatureType } from '../../../hooks/usePermission';

const defaultPermissionProps = {
  permissionType: permissionFeatureType,
  permissionsRequired: [],
};

export default function PopperMenuListItems({
  menuListItems = [],
  anchorRef = null,
  open = false,
  popperProps = {},
  onClose = () => null,
  onMenuItemClick = () => null,
  // where handler for menu item clicked on keyname 'indexClick'  and passing on menuListItems
  // when not passed, the action will following index on menuListItems
  onMenuItemsClick = [],
}) {
  if (!anchorRef || isEmpty(menuListItems)) return null;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { isPermissionsGranted } = usePermission();

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      placement='bottom-start'
      transition
      disablePortal
      style={{
        zIndex: theme.zIndex.tooltip,
      }}
      {...popperProps}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
          }}
        >
          <Paper className='paperMenuListPrimary'>
            <ClickAwayListener
              onClickAway={typeof onClose === 'function' ? (event) => onClose(event) : null}
            >
              <MenuList
                autoFocusItem={open}
                id='composition-menu'
                className='primary'
                aria-labelledby='composition-button'
              >
                {Array.isArray(menuListItems)
                  ? menuListItems.map((menuListItem, index) => {
                      const {
                        identity,
                        indexClick = '',
                        name = '',
                        translationName = '',
                        Icon = null,
                        permissionProps,
                        isLink = false,
                        linkDestination = null,
                      } = menuListItem;

                      const grantPermissionProps = {
                        ...defaultPermissionProps,
                        ...permissionProps,
                      };

                      if (!isPermissionsGranted(grantPermissionProps)) return null;

                      let menuItemClickHandler =
                        typeof onMenuItemClick === 'function'
                          ? (event) => {
                              onClose(event);
                              onMenuItemClick(event, identity, index);
                            }
                          : null;

                      if (!isEmpty(onMenuItemsClick) && Array.isArray(onMenuItemsClick)) {
                        const selectedIndex = indexClick !== undefined ? indexClick : index;

                        menuItemClickHandler =
                          typeof onMenuItemsClick[selectedIndex] === 'function'
                            ? (event) => {
                                onClose(event);
                                onMenuItemsClick[selectedIndex](event, identity, index);
                              }
                            : menuItemClickHandler;
                      }

                      if (isLink && linkDestination) {
                        menuItemClickHandler = () => navigate(linkDestination);
                      }

                      return (
                        <MenuItem
                          key={`${identity}-${name || t(translationName)}-${index}`}
                          className='primary'
                          onClick={menuItemClickHandler}
                        >
                          {Icon ? <ListItemIcon>{Icon}</ListItemIcon> : null}
                          {t(translationName) || name}
                        </MenuItem>
                      );
                    })
                  : null}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
