/* eslint-disable react/react-in-jsx-scope */
import { Add, PrintOutlined, VisibilityOutlined } from '@mui/icons-material';

import {
  childrenType,
  commonButtonType,
  excelBoxIconType,
  menuIconButtonsType,
  pdfBoxIconType,
} from '../../components/button/ButtonCollections';
import SvgIconPayInvoiceWhite from '../../components/icons/IconPayInvoiceWhite';
import {
  REACT_APP_CASH_BANK_CASH_PAYMENT_CREATE_URL,
  REACT_APP_CASH_BANK_CASH_RECEIPT_CREATE_URL,
  REACT_APP_DATA_STOCK_ADJUSTMENT_JOURNAL_ENTRY_DETAILS_TEMPLATE_URL,
  REACT_APP_EXPENSE_CREATE_NEW_URL,
} from '../configs/url.config';
import {
  CASH_PAYMENT_CREATE_PERMISSION_V2,
  CASH_RECEIPT_CREATE_PERMISSION_V2,
  CASH_TRANSFER_CREATE_PERMISSION_V2,
  PRODUCT_CREATE_PERMISSION_V2,
  PRODUCT_IMPORT_PRODUCT_PERMISSION_V2,
} from '../constants/permissions.constant';
import { simplifyMenuItemsButton } from '../data/display/menu-items-button';
import {
  DEFAULT_BUTTON_NAME_ACTION_PAY,
  DEFAULT_BUTTON_NAME_CREATE_PURCHASE_DELIVERY,
  DEFAULT_BUTTON_NAME_CREATE_PURCHASE_INVOICE,
  DEFAULT_BUTTON_NAME_CREATE_PURCHASE_ORDER,
  DEFAULT_BUTTON_NAME_CREATE_SALES_DELIVERY,
  DEFAULT_BUTTON_NAME_CREATE_SALES_INVOICE,
  DEFAULT_BUTTON_NAME_CREATE_SALES_ORDER,
  DEFAULT_BUTTON_NAME_PRINT_EXCEL,
  DEFAULT_BUTTON_NAME_PRINT_PDF,
  DEFAULT_BUTTON_NAME_VIEW_HISTORY,
  DEFAULT_BUTTON_NAME_VIEW_JOURNAL,
} from '../default/button-name.default';
import { DEFAULT_ELEMENT_ID_BUTTON_TRANSACTION_COLLECTIONS } from '../default/html-element-identity.default';
import {
  DEFAULT_STATUS_PURCHASE_DELIVERY_CLOSED,
  DEFAULT_STATUS_PURCHASE_INVOICE_PAID,
  DEFAULT_STATUS_PURCHASE_ORDER_CLOSED,
  DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_CLOSE,
} from '../default/purchase-status.default';
import {
  DEFAULT_STATUS_SALES_DELIVERY_CLOSE,
  DEFAULT_STATUS_SALES_INVOICE_PAID,
  DEFAULT_STATUS_SALES_ORDER_CLOSED,
  DEFAULT_STATUS_SALES_QUOTATION_CLOSE,
} from '../default/sales-status.default';
import {
  DEFAULT_PRINT_PAGE_STATUS,
  IMPORT_FILES_PAGE_STATUS,
} from '../default/status-page.default';
import formatHelp from '../helpers/format.helpers';
import strHelp from '../helpers/string.helpers';
import { addToQueryString } from '../helpers/url.helpers';

export const buttonCollectionsChildrenOnly = [
  {
    identity: 1,
    type: childrenType,
  },
];

export const buttonCollectionsPDFboxIconOnly = [
  {
    identity: 1,
    type: pdfBoxIconType,
    indexClick: 0,
  },
];

export const buttonCollectionsPDFnExcelBoxIconOnly = [
  {
    identity: 1,
    type: pdfBoxIconType,
    indexClick: 0,
  },
  {
    identity: 2,
    type: excelBoxIconType,
    indexClick: 1,
  },
];

export const buttonCollectionsCashBankPageList = [
  {
    identity: 1,
    type: commonButtonType,
    indexClick: 0,
    translationName: 'button.cash-transfer',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
    permissionProps: {
      permissionsRequired: [CASH_TRANSFER_CREATE_PERMISSION_V2],
    },
  },
  {
    identity: 2,
    type: menuIconButtonsType,
    childrenItems: [
      {
        translationName: 'button.cash-receipt',
        indexClick: 0,
        Icon: <Add fontSize='small' />,
        permissionProps: {
          permissionsRequired: [CASH_RECEIPT_CREATE_PERMISSION_V2],
        },
      },
      {
        translationName: 'button.cash-payment',
        indexClick: 1,
        Icon: <Add fontSize='small' />,
        permissionProps: {
          permissionsRequired: [CASH_PAYMENT_CREATE_PERMISSION_V2],
        },
      },
    ],
  },
];

export const buttonCollectionsCashBankDetails = [
  {
    identity: 1,
    type: commonButtonType,
    indexClick: 0,
    translationName: 'button.journal-report',
    additionalProps: {
      startIcon: <VisibilityOutlined fontSize='small' />,
    },
  },
  {
    identity: 2,
    type: pdfBoxIconType,
  },
  {
    identity: 3,
    type: excelBoxIconType,
  },
];

export const buttonCollectionsDataContactList = [
  ...buttonCollectionsPDFnExcelBoxIconOnly,
  {
    identity: 3,
    type: commonButtonType,
    indexClick: 2,
    translationName: 'button.contact',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

export const buttonCollectionsDataProductList = [
  {
    identity: 1,
    type: commonButtonType,
    indexClick: 0,
    translationName: 'button.product',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
    permissionProps: {
      permissionsRequired: [PRODUCT_CREATE_PERMISSION_V2],
    },
  },
  {
    identity: 2,
    type: commonButtonType,
    indexClick: 1,
    translationName: 'button-menu.actions.import-product',
    additionalProps: {
      className: 'primaryButtonV2',
      variant: 'outlined',
    },
    permissionProps: {
      permissionsRequired: [PRODUCT_IMPORT_PRODUCT_PERMISSION_V2],
    },
  },
];

export const buttonCollectionsDataAccountList = [
  {
    identity: 1,
    type: commonButtonType,
    indexClick: 0,
    translationName: 'button.account',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
  {
    identity: 2,
    type: menuIconButtonsType,
    childrenItems: [
      {
        translationName: 'button.batch-insert',
        indexClick: 0,
        Icon: <Add fontSize='small' />,
      },
      {
        translationName: 'button.general-journal',
        indexClick: 1,
      },
    ],
  },
];

export const buttonCollectionsDataAccountTransactionList = [
  {
    identity: 1,
    type: commonButtonType,
    indexClick: 0,
    translationName: 'button.change-account',
  },
  {
    identity: 2,
    type: pdfBoxIconType,
  },
  {
    identity: 3,
    type: childrenType,
  },
  {
    identity: 4,
    type: excelBoxIconType,
  },
];

export const buttonCollectionsDataAccountClosingBookList = [
  {
    identity: 1,
    type: commonButtonType,
    indexClick: 0,
    translationName: 'button.closing-book',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

export const buttonCollectionsDataAssetList = [
  {
    identity: 1,
    type: commonButtonType,
    indexClick: 0,
    translationName: 'button.fixed-asset',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

export const buttonCollectionsDataStockAdjustmentList = [
  {
    identity: 1,
    type: commonButtonType,
    indexClick: 0,
    translationName: 'button.stock-adjustment',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

export const buttonCollectionsDataWarehouseTransferList = [
  {
    identity: 1,
    type: commonButtonType,
    indexClick: 0,
    translationName: 'button.warehouse-transfer',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

export const buttonCollectionsDataProductCategoryList = [
  {
    identity: 1,
    type: childrenType,
  },
  {
    identity: 2,
    type: commonButtonType,
    indexClick: 0,
    translationName: 'button.product-category',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

export const buttonCollectionsDataAssetCategoryList = [
  {
    identity: 1,
    type: commonButtonType,
    indexClick: 0,
    translationName: 'button.asset-category',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

export const buttonCollectionsDataGeneralJournalList = [
  {
    identity: 1,
    type: commonButtonType,
    indexClick: 0,
    translationName: 'button.general-journal',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
  {
    identity: 3,
    type: commonButtonType,
    indexClick: 2,
    translationName: 'typography.import',
    translationNameOptions: {
      type: 'typography.journal',
    },
    isLinkComponent: true,
    linkSearchParams: {
      statusPage: IMPORT_FILES_PAGE_STATUS,
    },
    additionalProps: {
      className: 'primaryButtonV2',
      variant: 'outlined',
    },
  },
];

export const buttonCollectionsSettingUserList = [
  {
    identity: 1,
    type: commonButtonType,
    indexClick: 0,
    translationName: 'button.invite-users',
  },
];

export const buttonCollectionsSettingWarehouseList = [
  {
    identity: 1,
    type: commonButtonType,
    indexClick: 0,
    translationName: 'button.warehouse',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

export const buttonCollectionsReportList = [
  {
    identity: 1,
    type: childrenType,
  },
  {
    identity: 2,
    indexClick: 0,
    type: pdfBoxIconType,
  },
  {
    identity: 3,
    indexClick: 0,
    type: excelBoxIconType,
  },
];

export const buttonCollectionsExpenseList = [
  {
    identity: 1,
    type: commonButtonType,
    indexClick: 0,
    translationName: 'button.expense',
    isLinkComponent: true,
    linkDestination: REACT_APP_EXPENSE_CREATE_NEW_URL,
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
  {
    identity: 2,
    type: commonButtonType,
    indexClick: 1,
    translationName: 'typography.import',
    translationNameOptions: {
      type: 'typography.expense',
    },
    isLinkComponent: true,
    linkSearchParams: {
      pageStatus: IMPORT_FILES_PAGE_STATUS,
    },
    isSetPageStatusAction: true,
    newPageStatus: IMPORT_FILES_PAGE_STATUS,
    additionalProps: {
      className: 'primaryButtonV2',
      variant: 'outlined',
    },
  },
];

export const buttonCollectionsPurchaseQuotationRequestList = [
  buttonCollectionsPDFboxIconOnly[0],
  {
    identity: 2,
    type: commonButtonType,
    indexClick: 1,
    translationName: 'button.quotation-request',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

export const buttonCollectionsPurchaseOrderList = [
  buttonCollectionsPDFboxIconOnly[0],
  {
    identity: 2,
    type: commonButtonType,
    indexClick: 1,
    translationName: 'button.purchase-order',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

export const buttonCollectionsPurchaseDeliveryList = [
  buttonCollectionsPDFboxIconOnly[0],
  {
    identity: 2,
    type: commonButtonType,
    indexClick: 1,
    translationName: 'button.purchase-delivery',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

export const buttonCollectionsPurchaseInvoiceList = [
  // buttonCollectionsPDFboxIconOnly[0],
  {
    identity: 2,
    type: commonButtonType,
    indexClick: 1,
    translationName: 'button.purchase-invoice',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
  {
    identity: 3,
    type: commonButtonType,
    indexClick: 2,
    translationName: 'typography.import',
    translationNameOptions: {
      type: 'typography.purchase-invoice',
    },
    isLinkComponent: true,
    linkSearchParams: {
      statusPage: IMPORT_FILES_PAGE_STATUS,
    },
    additionalProps: {
      className: 'primaryButtonV2',
      variant: 'outlined',
    },
  },
];

export const buttonCollectionsPurchasePaymentList = [
  buttonCollectionsPDFboxIconOnly[0],
  {
    identity: 2,
    type: commonButtonType,
    indexClick: 1,
    translationName: 'button.purchase-payment',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

export const buttonCollectionsPurchaseReturnList = [
  buttonCollectionsPDFboxIconOnly[0],
  {
    identity: 2,
    type: commonButtonType,
    indexClick: 1,
    translationName: 'button.purchase-return',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

export const buttonCollectionsPurchaseReceivablePaymentList = [
  buttonCollectionsPDFboxIconOnly[0],
  {
    identity: 2,
    type: commonButtonType,
    indexClick: 1,
    translationName: 'button.receivable-payment',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

export const buttonCollectionsSalesQuotationList = [
  buttonCollectionsPDFboxIconOnly[0],
  {
    identity: 2,
    type: commonButtonType,
    indexClick: 1,
    translationName: 'button.sales-quotation',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

export const buttonCollectionsSalesOrderList = [
  buttonCollectionsPDFboxIconOnly[0],
  {
    identity: 2,
    type: commonButtonType,
    indexClick: 1,
    translationName: 'button.sales-order',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

export const buttonCollectionsSalesDeliveryList = [
  buttonCollectionsPDFboxIconOnly[0],
  {
    identity: 2,
    type: commonButtonType,
    indexClick: 1,
    translationName: 'button.sales-delivery',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

export const buttonCollectionsSalesInvoiceList = [
  buttonCollectionsPDFboxIconOnly[0],
  {
    identity: 2,
    type: commonButtonType,
    indexClick: 1,
    translationName: 'button.sales-invoice',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
  {
    identity: 3,
    type: commonButtonType,
    indexClick: 2,
    translationName: 'typography.import',
    translationNameOptions: {
      type: 'typography.sales-invoice',
    },
    additionalProps: {
      className: 'primaryButtonV2',
      variant: 'outlined',
    },
  },
];

export const buttonCollectionsSalesPaymentList = [
  buttonCollectionsPDFboxIconOnly[0],
  {
    identity: 2,
    type: commonButtonType,
    indexClick: 1,
    translationName: 'button.sales-payment',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

export const buttonCollectionsSalesReturnList = [
  buttonCollectionsPDFboxIconOnly[0],
  {
    identity: 2,
    type: commonButtonType,
    indexClick: 1,
    translationName: 'button.sales-return',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

export const buttonCollectionsSalesPayablePaymentList = [
  buttonCollectionsPDFboxIconOnly[0],
  {
    identity: 2,
    type: commonButtonType,
    indexClick: 1,
    translationName: 'button.payable-payment',
    additionalProps: {
      startIcon: <Add fontSize='small' />,
    },
  },
];

const buttonCollectionsExportFileTransaction = [
  {
    identity: DEFAULT_BUTTON_NAME_PRINT_PDF,
    type: pdfBoxIconType,
  },
  {
    identity: DEFAULT_BUTTON_NAME_PRINT_EXCEL,
    type: excelBoxIconType,
  },
];

export const buttonCollectionsViewJournalEntry = [
  {
    identity: DEFAULT_BUTTON_NAME_VIEW_JOURNAL,
    type: commonButtonType,
    translationName: 'button.journal-entry',
    additionalProps: {
      startIcon: <VisibilityOutlined fontSize='small' />,
    },
  },
];

const additionalButtonTransactionsIdentityProps = {
  additionalProps: {
    id: DEFAULT_ELEMENT_ID_BUTTON_TRANSACTION_COLLECTIONS,
  },
};

export const buttonCollectionsHistoryTransactionOnly = [
  {
    identity: DEFAULT_BUTTON_NAME_VIEW_HISTORY,
    type: commonButtonType,
    translationName: 'button.history',
    additionalProps: {
      className: 'primaryButtonV2',
      variant: 'outlined',
      ...additionalButtonTransactionsIdentityProps.additionalProps,
    },
  },
];

export const buttonCollectionsPurchaseQuotationRequestDetails = (
  purchaseQuotationRequestDetails,
) => {
  return [
    ...simplifyMenuItemsButton({
      initialMenuItems: [
        {
          identity: DEFAULT_BUTTON_NAME_CREATE_PURCHASE_ORDER,
          type: commonButtonType,
          groupingWrapID: '1',
          translationName: 'button.order',
          additionalProps: {
            startIcon: <Add fontSize='small' />,
          },
        },
      ],
      conditionalValues: [DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_CLOSE],
      bodyConditional: purchaseQuotationRequestDetails,
    }),
    {
      identity: DEFAULT_BUTTON_NAME_VIEW_HISTORY,
      type: menuIconButtonsType,
      groupingWrapID: '1',
      minimumWrapCount: 2,
      childrenItems: [
        {
          ...buttonCollectionsHistoryTransactionOnly[0],
          identity: DEFAULT_BUTTON_NAME_VIEW_HISTORY,
          translationName: 'button.history',
        },
      ],
      ...additionalButtonTransactionsIdentityProps,
    },
    ...buttonCollectionsExportFileTransaction,
  ];
};

export const buttonCollectionsPurchaseOrderDetails = (purchaseOrderDetails) => {
  return [
    ...simplifyMenuItemsButton({
      initialMenuItems: [
        {
          identity: DEFAULT_BUTTON_NAME_CREATE_PURCHASE_DELIVERY,
          type: commonButtonType,
          translationName: 'button.delivery',
          groupingWrapID: '1',
          additionalProps: {
            startIcon: <Add fontSize='small' />,
          },
        },
        {
          identity: DEFAULT_BUTTON_NAME_CREATE_PURCHASE_INVOICE,
          type: menuIconButtonsType,
          groupingWrapID: '1',
          minimumWrapCount: 2,
          childrenItems: [
            ...simplifyMenuItemsButton({
              initialMenuItems: [
                {
                  identity: DEFAULT_BUTTON_NAME_CREATE_PURCHASE_INVOICE,
                  type: commonButtonType,
                  translationName: 'button.create-invoice',
                  indexClick: 0,
                },
                {
                  ...buttonCollectionsHistoryTransactionOnly[0],
                  indexClick: 1,
                  identity: DEFAULT_BUTTON_NAME_VIEW_HISTORY,
                  translationName: 'button.history',
                },
              ],
              conditionalValues: [DEFAULT_STATUS_PURCHASE_ORDER_CLOSED],
              bodyConditional: purchaseOrderDetails,
            }),
          ],
          ...additionalButtonTransactionsIdentityProps,
        },
      ],
      conditionalValues: [DEFAULT_STATUS_PURCHASE_ORDER_CLOSED],
      bodyConditional: purchaseOrderDetails,
    }),
    ...buttonCollectionsExportFileTransaction,
  ];
};

export const buttonCollectionsPurchaseDeliveryDetails = (purchaseDeliveryDetails) => {
  return [
    ...simplifyMenuItemsButton({
      initialMenuItems: [
        {
          identity: DEFAULT_BUTTON_NAME_CREATE_PURCHASE_INVOICE,
          type: commonButtonType,
          translationName: 'button.invoice',
          additionalProps: {
            startIcon: <Add fontSize='small' />,
          },
          groupingWrapID: '1',
        },
        {
          identity: DEFAULT_BUTTON_NAME_VIEW_HISTORY,
          type: menuIconButtonsType,
          groupingWrapID: '1',
          minimumWrapCount: 2,
          childrenItems: simplifyMenuItemsButton({
            initialMenuItems: [
              {
                identity: DEFAULT_BUTTON_NAME_VIEW_JOURNAL,
                translationName: 'button.journal-entry',
              },
              {
                ...buttonCollectionsHistoryTransactionOnly[0],
                identity: DEFAULT_BUTTON_NAME_VIEW_HISTORY,
                translationName: 'button.history',
              },
            ],
            conditionalKeynames: ['purchase_handover_id'],
            conditionalValues: [null],
            bodyConditional: purchaseDeliveryDetails,
          }),
          ...additionalButtonTransactionsIdentityProps,
        },
      ],
      conditionalValues: [DEFAULT_STATUS_PURCHASE_DELIVERY_CLOSED],
      bodyConditional: purchaseDeliveryDetails,
    }),
    ...buttonCollectionsExportFileTransaction,
  ];
};

export const buttonCollectionsPurchaseInvoiceDetails = (purchaseInvoiceDetails) => {
  return [
    ...simplifyMenuItemsButton({
      initialMenuItems: [
        {
          identity: DEFAULT_BUTTON_NAME_ACTION_PAY,
          type: commonButtonType,
          translationName: 'button.pay',
          additionalProps: {
            startIcon: <SvgIconPayInvoiceWhite fontSize='small' />,
          },
          groupingWrapID: '1',
        },
        {
          identity: DEFAULT_BUTTON_NAME_VIEW_JOURNAL,
          type: menuIconButtonsType,
          groupingWrapID: '1',
          minimumWrapCount: 2,
          childrenItems: [
            {
              ...buttonCollectionsViewJournalEntry[0],
              identity: DEFAULT_BUTTON_NAME_VIEW_JOURNAL,
              translationName: 'button.journal-entry',
            },
            {
              ...buttonCollectionsHistoryTransactionOnly[0],
              identity: DEFAULT_BUTTON_NAME_VIEW_HISTORY,
              translationName: 'button.history',
            },
          ],
          ...additionalButtonTransactionsIdentityProps,
        },
      ],
      conditionalValues: [DEFAULT_STATUS_PURCHASE_INVOICE_PAID],
      bodyConditional: purchaseInvoiceDetails,
    }),
    ...buttonCollectionsExportFileTransaction,
  ];
};

export const buttonCollectionsPurchasePaymentDetails = [
  ...buttonCollectionsViewJournalEntry,
  {
    identity: DEFAULT_BUTTON_NAME_VIEW_HISTORY,
    type: commonButtonType,
    translationName: 'button.history',
    additionalProps: {
      className: 'primaryButtonV2',
      variant: 'outlined',
      ...additionalButtonTransactionsIdentityProps.additionalProps,
    },
  },
  ...buttonCollectionsExportFileTransaction,
];

export const buttonCollectionsPurchaseReturnDetails = [
  ...buttonCollectionsViewJournalEntry,
  buttonCollectionsHistoryTransactionOnly[0],
  ...buttonCollectionsExportFileTransaction,
];

export const buttonCollectionsMinutesHandoverPurchaseDeliveryDetails = [
  buttonCollectionsHistoryTransactionOnly[0],
];

export const buttonCollectionsSalesQuotationDetails = (salesQuotationDetails) => {
  return [
    ...simplifyMenuItemsButton({
      initialMenuItems: [
        {
          identity: DEFAULT_BUTTON_NAME_CREATE_SALES_ORDER,
          type: commonButtonType,
          translationName: 'button.order',
          additionalProps: {
            startIcon: <Add fontSize='small' />,
          },
          groupingWrapID: '1',
        },
      ],
      conditionalValues: [DEFAULT_STATUS_SALES_QUOTATION_CLOSE],
      bodyConditional: salesQuotationDetails,
    }),
    {
      identity: DEFAULT_BUTTON_NAME_VIEW_HISTORY,
      type: menuIconButtonsType,
      groupingWrapID: '1',
      minimumWrapCount: 2,
      childrenItems: [
        {
          ...buttonCollectionsHistoryTransactionOnly[0],
          identity: DEFAULT_BUTTON_NAME_VIEW_HISTORY,
          translationName: 'button.history',
        },
      ],
      ...additionalButtonTransactionsIdentityProps,
    },
    ...buttonCollectionsExportFileTransaction,
  ];
};

export const buttonCollectionsSalesOrderDetails = (salesOrderDetails) => {
  return [
    ...simplifyMenuItemsButton({
      initialMenuItems: [
        {
          identity: DEFAULT_BUTTON_NAME_CREATE_SALES_DELIVERY,
          type: commonButtonType,
          translationName: 'button.delivery',
          additionalProps: {
            startIcon: <Add fontSize='small' />,
          },
          groupingWrapID: '1',
        },
        {
          identity: DEFAULT_BUTTON_NAME_CREATE_SALES_INVOICE,
          type: menuIconButtonsType,
          groupingWrapID: '1',
          minimumWrapCount: 2,
          childrenItems: [
            ...simplifyMenuItemsButton({
              initialMenuItems: [
                {
                  identity: DEFAULT_BUTTON_NAME_CREATE_SALES_INVOICE,
                  translationName: 'button.create-invoice',
                },
                {
                  ...buttonCollectionsHistoryTransactionOnly[0],
                  identity: DEFAULT_BUTTON_NAME_VIEW_HISTORY,
                  translationName: 'button.history',
                },
              ],
              conditionalValues: [DEFAULT_STATUS_SALES_ORDER_CLOSED],
              bodyConditional: salesOrderDetails,
            }),
          ],
          ...additionalButtonTransactionsIdentityProps,
        },
      ],
      conditionalValues: [DEFAULT_STATUS_SALES_ORDER_CLOSED],
      bodyConditional: salesOrderDetails,
    }),
    ...buttonCollectionsExportFileTransaction,
  ];
};

export const buttonCollectionsSalesDeliveryDetails = (salesDeliveryDetails) => {
  return [
    ...simplifyMenuItemsButton({
      initialMenuItems: [
        {
          identity: DEFAULT_BUTTON_NAME_CREATE_SALES_INVOICE,
          type: commonButtonType,
          translationName: 'button.invoice',
          additionalProps: {
            startIcon: <Add fontSize='small' />,
          },
          groupingWrapID: '1',
        },
        {
          identity: DEFAULT_BUTTON_NAME_VIEW_JOURNAL,
          type: menuIconButtonsType,
          groupingWrapID: '1',
          minimumWrapCount: 2,
          childrenItems: [
            {
              ...buttonCollectionsViewJournalEntry[0],
              identity: DEFAULT_BUTTON_NAME_VIEW_JOURNAL,
              translationName: 'button.journal-entry',
            },
            {
              ...buttonCollectionsHistoryTransactionOnly[0],
              identity: DEFAULT_BUTTON_NAME_VIEW_HISTORY,
              translationName: 'button.history',
            },
          ],
          ...additionalButtonTransactionsIdentityProps,
        },
      ],
      conditionalValues: [DEFAULT_STATUS_SALES_DELIVERY_CLOSE],
      bodyConditional: salesDeliveryDetails,
    }),
    ...buttonCollectionsExportFileTransaction,
  ];
};

export const buttonCollectionsSalesInvoiceDetails = (salesInvoiceDetails) => {
  return [
    ...simplifyMenuItemsButton({
      initialMenuItems: [
        {
          identity: DEFAULT_BUTTON_NAME_ACTION_PAY,
          type: commonButtonType,
          translationName: 'button.pay',
          additionalProps: {
            startIcon: <SvgIconPayInvoiceWhite fontSize='small' />,
          },
          groupingWrapID: '1',
        },
        {
          identity: DEFAULT_BUTTON_NAME_VIEW_JOURNAL,
          type: menuIconButtonsType,
          groupingWrapID: '1',
          minimumWrapCount: 2,
          childrenItems: [
            {
              ...buttonCollectionsViewJournalEntry[0],
              identity: DEFAULT_BUTTON_NAME_VIEW_JOURNAL,
              translationName: 'button.journal-entry',
            },
            {
              ...buttonCollectionsHistoryTransactionOnly[0],
              identity: DEFAULT_BUTTON_NAME_VIEW_HISTORY,
              translationName: 'button.history',
            },
          ],
          ...additionalButtonTransactionsIdentityProps,
        },
      ],
      conditionalValues: [DEFAULT_STATUS_SALES_INVOICE_PAID],
      bodyConditional: salesInvoiceDetails,
    }),
    ...buttonCollectionsExportFileTransaction,
  ];
};

export const buttonCollectionsSalesPaymentDetails = [
  ...buttonCollectionsViewJournalEntry,
  {
    identity: DEFAULT_BUTTON_NAME_VIEW_HISTORY,
    type: commonButtonType,
    translationName: 'button.history',
    additionalProps: {
      className: 'primaryButtonV2',
      variant: 'outlined',
      ...additionalButtonTransactionsIdentityProps.additionalProps,
    },
  },
  ...buttonCollectionsExportFileTransaction,
];

export const buttonCollectionsSalesReturnDetails = [
  ...buttonCollectionsViewJournalEntry,
  buttonCollectionsHistoryTransactionOnly[0],
  ...buttonCollectionsExportFileTransaction,
];

export const buttonCollectionsMinutesHandoverSalesDeliveryDetails = [
  buttonCollectionsHistoryTransactionOnly[0],
];

export const buttonCollectionsBillingDetails = [
  {
    identity: DEFAULT_BUTTON_NAME_PRINT_PDF,
    indexClick: 0,
    type: commonButtonType,
    translationName: 'button-menu.print.pdf',
    additionalProps: {
      className: 'primaryButtonV2',
      variant: 'outlined',
      startIcon: <PrintOutlined sx={{ strokeWidth: 0.1, fontSize: '24px!important' }} />,
    },
  },
];

export const buttonCollectionsDataStockAdjustmentDetails = [
  {
    identity: 1,
    type: commonButtonType,
    translationName: 'button.journal-entry',
    isLinkComponent: true,
    additionalProps: {
      startIcon: <VisibilityOutlined fontSize='small' />,
    },
    generateLink: ({ location, inheritedData }) => {
      if (inheritedData) {
        return strHelp.templateString(
          REACT_APP_DATA_STOCK_ADJUSTMENT_JOURNAL_ENTRY_DETAILS_TEMPLATE_URL,
          inheritedData,
        );
      }

      return formatHelp.combineBaseUrlWithSearchParamsV2(
        location.pathname,
        addToQueryString(location.search, {
          pageStatus: 'print',
        }),
      );
    },
  },
  {
    identity: 2,
    type: pdfBoxIconType,
    isLinkComponent: true,
    generateLink: ({ location }) => {
      return formatHelp.combineBaseUrlWithSearchParamsV2(
        location.pathname,
        addToQueryString(location.search, {
          pageStatus: DEFAULT_PRINT_PAGE_STATUS,
        }),
      );
    },
  },
];

export const buttonCollectionsBankStatementsList = [
  {
    identity: 1,
    type: commonButtonType,
    indexClick: 0,
    translationName: 'typography.import',
    translationNameOptions: {
      type: 'header.bank-statements',
    },
    additionalProps: {
      className: 'primaryButtonV2',
      variant: 'outlined',
    },
  },
];

export const buttonCollectionsSearchAndMatchReconciliation = [
  {
    identity: 2,
    type: menuIconButtonsType,
    additionalProps: {
      buttonText: 'new transaction',
      buttonProps: {
        className: 'primaryButtonV3',
        variant: 'contained',
        startIcon: <Add fontSize='small' />,
      },
    },
    childrenItems: [
      {
        translationName: 'button.cash-receipt',
        indexClick: 0,
        Icon: <Add fontSize='small' />,
        permissionProps: {
          permissionsRequired: [CASH_RECEIPT_CREATE_PERMISSION_V2],
        },
        isLink: true,
        linkDestination: REACT_APP_CASH_BANK_CASH_RECEIPT_CREATE_URL,
      },
      {
        translationName: 'button.cash-payment',
        indexClick: 1,
        Icon: <Add fontSize='small' />,
        permissionProps: {
          permissionsRequired: [CASH_PAYMENT_CREATE_PERMISSION_V2],
        },
        isLink: true,
        linkDestination: REACT_APP_CASH_BANK_CASH_PAYMENT_CREATE_URL,
      },
    ],
  },
];
