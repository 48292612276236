import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_BEGINNING_BALANCE } from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_CREATE_BEGINNING_BALANCE_FAILED,
  STATUS_REQUEST_CREATE_BEGINNING_BALANCE_PENDING,
  STATUS_REQUEST_CREATE_BEGINNING_BALANCE_SUCCESS,
  STATUS_REQUEST_LIST_BEGINNING_BALANCE_FAILED,
  STATUS_REQUEST_LIST_BEGINNING_BALANCE_PENDING,
  STATUS_REQUEST_LIST_BEGINNING_BALANCE_SUCCESS,
} from '../../utils/constants/store.constant';
import { DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS } from '../../utils/default/params.default';
import formatHelp from '../../utils/helpers/format.helpers';
import objHelper from '../../utils/helpers/object.helper';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import { createBeginningBalance, getListBeginningBalance } from './beginning-balance.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  dataBeginningBalance: LocalStorage.get(LOCAL_STORAGE_BEGINNING_BALANCE),
};

const beginningbalanceSlice = createSlice({
  name: NAME_STORE.BEGINNING_BALANCE,
  initialState,
  reducers: {
    getListBeginningBalance,
    createBeginningBalance,
    clearBeginningBalanceData: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_BEGINNING_BALANCE);

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        dataBeginningBalance: null,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      dataBeginningBalance: LOCAL_STORAGE_BEGINNING_BALANCE,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getListBeginningBalance.fulfilled, (state, action) => {
      state.statusRequest = STATUS_REQUEST_LIST_BEGINNING_BALANCE_SUCCESS;

      if (!isEmpty(action.payload.dataBeginningBalance)) {
        action.payload.dataBeginningBalance = objHelper.changeFormatValueV2(
          action.payload.dataBeginningBalance,
          DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
          null,
          formatHelp.changeFormatAccountName,
        );
      }

      state.dataBeginningBalance = action.payload.dataBeginningBalance;
    }),
      builder.addCase(getListBeginningBalance.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_BEGINNING_BALANCE_PENDING;
      }),
      builder.addCase(getListBeginningBalance.rejected, (state) => {
        state.dataBeginningBalance = null;
        state.statusRequest = STATUS_REQUEST_LIST_BEGINNING_BALANCE_FAILED;
      }),
      builder.addCase(createBeginningBalance.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_CREATE_BEGINNING_BALANCE_SUCCESS;
      }),
      builder.addCase(createBeginningBalance.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_CREATE_BEGINNING_BALANCE_PENDING;
      }),
      builder.addCase(createBeginningBalance.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_CREATE_BEGINNING_BALANCE_FAILED;
      });
  },
});

export const { actions, reducer } = beginningbalanceSlice;

export const { clearBeginningBalanceData } = actions;

export default reducer;
