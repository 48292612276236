import formatHelp from './format.helpers';

// split string with separator and return only existed data
function splitWithReturnExistedData(str, separator) {
  if (typeof str !== 'string') {
    return str;
  }

  return str.split(separator).filter((data) => data);
}

// checing string contain space or not
function isContainSpace(str) {
  if (typeof str !== 'string') {
    return str;
  }

  return /\s/.test(str);
}

// returning name that for simplicity
function getSimpleName(name, maxLength = 20) {
  if (name.length < maxLength) {
    return name;
  }

  return `${name.slice(0, maxLength)}...`;
}

function getFirstName(name) {
  if (!name || typeof name !== 'string') {
    return '';
  }

  // getting upper case name
  const upperCaseName = name.toUpperCase();

  let splitName = upperCaseName.split(' ').filter((sn) => sn);

  if (upperCaseName.length < 2) {
    return upperCaseName;
  }

  if (splitName.length === 1) {
    return upperCaseName.slice(0, 2);
  }

  return `${splitName[0][0]}${splitName[1][0]}`;
}

function stringToColor(string) {
  if (!string) {
    return '#efefef';
  }

  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name, additionalStyle = {}) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      ...additionalStyle,
    },
    children: getFirstName(name),
  };
}

// get sequence of string after split with separator
function getStrIndexAfterSplit(str, separator = '', index = 0) {
  if (typeof str !== 'string') {
    return;
  }

  return str.split(separator)[index];
}

// removing that execedd data string with subtition str
function limitExcededStr(str, limit = 100, subtition = '...') {
  if (typeof str !== 'string' || !str.length) {
    return '-';
  }

  if (str.length <= limit) {
    return str;
  }

  return `${str.slice(0, limit)}${subtition}`;
}

function isEmpty(value, customEmptyData = []) {
  if (typeof value !== 'string' && typeof value !== 'number') {
    return Boolean(value);
  }

  if (typeof value === 'number') {
    value = String(value);
  }

  let isEmptyStr = false;

  if (customEmptyData.length) {
    isEmptyStr = isEmptyStr || customEmptyData.includes(value);
  }

  return isEmptyStr || value === '' || !value;
}

// @param: str, string target for change into method string
// @param: methodString, method on String built-in function
function wrapperStringMethod(str, methodString) {
  if (typeof str !== 'string' || !(methodString in new String())) {
    return str;
  }

  return str[methodString]();
}

// @param: str, string changed into lowerCase
function toLowerCase(str) {
  if (typeof str !== 'string') {
    return str;
  }

  return wrapperStringMethod(str, 'toLowerCase');
}

// example:
// str = 'ainf-emha-hayu', startIndex = 1, endIndex = 3, separator = '-'
// return = 'emha-hayu'
// @param: str, string for getting joined split with separator
// @param: startIndex, start where slice string
// @param: endIndex, end of index that slice string
// @param: separator, divide and join string with separator
function getStrWithSeparatorNIndex(str, startIndex, endIndex, separator = '-') {
  if (typeof str !== 'string') {
    return str;
  }

  return str.split(separator).slice(startIndex, endIndex).join(separator);
}

/**
 * https://stackoverflow.com/questions/2332811/capitalize-words-in-string
 * Capitalizes first letters of words in string.
 * @param { string } str String to be modified
 * @param { boolean = true } lower Whether all other letters should be lowercased
 * @return { string }
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
function capitalize(str, lower = true) {
  return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase(),
  );
}

/**
 *
 * @param { string } decodeString string that contain special differentiator {}
 * @param { array || object } substitution change substitution for decode string
 * @param { boolean }           isRemove   determine for throwing empty string
 *      @default    false
 *
 * @returns
 *      parsed string that came from substitution changed into decodeString
 * @example
 *      templateString('hame {hame}, {1}, {0}', { hame: 'hayu' })
 *          return 'hame hayu {1}, {0}'
 *      templateString('hame {hame}, {1}, {0}', [ 'ainf', 'test', { hame: 'hayu' }])
 *          return 'hame hayu test, ainf'
 */
function templateString(decodeString, substitution, isRemove = false) {
  if (!substitution) return decodeString;

  if (typeof decodeString !== 'string') {
    return decodeString;
  }

  let arrArgs = [];

  if (Array.isArray(substitution)) {
    arrArgs = substitution;
  } else if (typeof substitution === 'object') {
    arrArgs = Object.keys(substitution);
  }

  return decodeString.replace(/\{(\w*)\}/g, function (match, key) {
    for (let i = 0; i < arrArgs.length; i++) {
      if (typeof substitution === 'object' && typeof substitution[key] !== 'undefined') {
        return substitution[key];
      } else if (
        Array.isArray(substitution) &&
        typeof arrArgs[i] === 'object' &&
        typeof arrArgs[i][key] !== 'undefined'
      ) {
        return arrArgs[i][key];
      } else if (Array.isArray(substitution) && typeof arrArgs[i] === 'object') {
        return arrArgs[key];
      }

      if (isRemove) return '';
    }

    return match;
  });
}

/**
 *
 * @param { string }        char        string that for being add on previous or next char
 * @param { string }        prefixChar  first char on result char
 * @param { string }        suffixChar  last char on result char
 * @returns
 *      returing char that actually adding first char with prefixChar and adding last char with suffixChar
 */
function addPrefixSuffix(char, prefixChar = '#', suffixChar = '') {
  if (!char) return '-';

  return `${prefixChar}${char}${suffixChar}`;
}

/**
 *
 * @param { object } param0
 *
 * each key object is params formatting data
 * @param { object }      productData                   contain product data
 * @param { string }      keyNameProductPrice           name key of product price
 *      @default    'product_price'
 * @param { string }      keyNameProductUnit            name key of product unit on product data
 *      @default    'product_unit'
 * @param { string }      separatorPriceWithUnit        separator on price with unit
 *      @default    '/''
 * @param { function }    formatterDataProductPrice     formatter data for product price
 *      @default    currencyWithAutoComma -> formatHelp
 * @param { function }    formatterDataProductUnit      formatter data for product unit
 *      @default    capitalize -> string
 *
 * @returns
 *      getting format price per unit on product data
 */
function formatPricePerUnit({
  productData = {},
  keyNameProductPrice = 'product_price',
  keyNameProductUnit = 'product_unit',
  separatorPriceWithUnit = '/',
  formatterDataProductPrice = formatHelp.currencyWithAutoComma,
  formatterDataProductUnit = capitalize,
}) {
  if (typeof productData !== 'object') return productData;

  const { [keyNameProductPrice]: productPriceData, [keyNameProductUnit]: productUnitData } =
    productData;

  let productPrice = productPriceData || '',
    productUnit = productUnitData || '';

  if (typeof formatterDataProductPrice === 'function' && !Number.isNaN(Number(productPrice))) {
    productPrice = formatterDataProductPrice(Number(productPrice));
  }

  if (typeof formatterDataProductUnit === 'function') {
    productUnit = formatterDataProductUnit(productUnit);
  }

  return `${productPrice}${separatorPriceWithUnit}${productUnit}`;
}

/**
 *
 * @param  { string } separator separator each params
 * @param  { string } params    as many as you can
 * @returns
 */
function joinBy(separator = '', ...params) {
  if (!params || !separator) return '';

  return [...params].join(separator);
}

const strHelp = {
  splitWithReturnExistedData,
  isContainSpace,
  getSimpleName,
  stringAvatar,
  getStrIndexAfterSplit,
  limitExcededStr,
  isEmpty,
  wrapperStringMethod,
  toLowerCase,
  getStrWithSeparatorNIndex,
  capitalize,
  templateString,
  addPrefixSuffix,
  formatPricePerUnit,
  joinBy,
};

export default strHelp;
