import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Chip,
  IconButton,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';

import BoxActionPermission from '../../../components/box/BoxActionPermission/BoxActionPermission';
import BoxTableNotFound from '../../../components/box/BoxTableNotFound/BoxTableNotFound';
import TablePaginationMain from '../../../components/table-pagination/TablePaginationMain/TablePaginationMain';
import TableRowLoading from '../../../components/table-row/TableRowLoading/TableRowLoading';
import TypographyLinkPrimary from '../../../components/typography/TypographyLinkPrimary/TypographyLinkPrimary';
import { withRouter } from '../../../components/withRouter/withRouter';
import { decryptData, encryptData } from '../../../services/modules/Crypto/Crypto.service';
import messageHelper from '../../../store/message/message.helper';
import { getListSalesDelivery } from '../../../store/sales/sales.action';
import {
  REACT_APP_SALES_SALES_DELIVERY_CREATE_NEW_URL,
  REACT_APP_SALES_SALES_DELIVERY_DETAILS_TEMPLATE_URL,
} from '../../../utils/configs/url.config';
import {
  SLICE_NAME_SALES_DELIVERY_DATA,
  STATUS_REQUEST_LIST_SALES_DELIVERY_PENDING,
} from '../../../utils/constants/store.constant';
import { initialSearchSalesDelivery } from '../../../utils/data/display/initial-search-sales-delivery';
import {
  columnsDataSalesDeliveryFormatCalculationNumber,
  columnsSearchSalesDeliveryFormatAction,
  columnsSearchSalesDeliveryFormatDate,
  columnsSearchSalesDeliveryFormatLink,
  columnsSearchSalesDeliveryFormatStatus,
  columnsTableSalesDelivery,
} from '../../../utils/data/display/table-list-sales-delivery';
import {
  iconListSalesDeliveryData,
  tooltipListSalesDelivery,
} from '../../../utils/data/display/tooltip-list-sales-delivery';
import { minutesHandoverStatus } from '../../../utils/data/label-value/minutes-of-handover-status';
import {
  salesDeliveryStatus,
  salesInvoiceStatus,
} from '../../../utils/data/label-value/sales-status';
import {
  DEFAULT_SALES_DELIVERY_LIST_PARAMS,
  DEFAULT_SALES_DELIVERY_SEARCH_FE_PARAMS,
  TERSIER_DEFAULT_LIST_PARAMS,
} from '../../../utils/default/params.default';
import arrHelp from '../../../utils/helpers/array.helpers';
import dateHelpers from '../../../utils/helpers/date.helpers';
import formatHelp from '../../../utils/helpers/format.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import strHelp from '../../../utils/helpers/string.helpers';

import TableHeadSearchSalesDelivery from './TableHeadSearchSalesDelivery/TableHeadSearchSalesDelivery';
import { styles } from './SalesDeliveryList.styles';

class SalesDeliveryList extends Component {
  constructor(props) {
    super(props);

    const { handleChangeStatusPage, onClickNewSalesDelivery } = props;

    this.isHandleChangeStatusPageFunction = typeof handleChangeStatusPage === 'function';
    this.isOnClickNewSalesDeliveryFunction = typeof onClickNewSalesDelivery === 'function';

    this.state = {
      rowsPerPage: TERSIER_DEFAULT_LIST_PARAMS.paginate,
    };

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

    this.handleClickActionSalesDelivery = this.handleClickActionSalesDelivery.bind(this);
  }

  handleClickActionSalesDelivery(event) {
    const { name: newStatusPage, value } = event.currentTarget;

    const decryptedValue = decryptData(value);

    const newSelectedSalesDelivery = JSON.stringify(decryptedValue);

    if (this.isHandleChangeStatusPageFunction) {
      this.props.handleChangeStatusPage(newStatusPage, newSelectedSalesDelivery);
    }
  }

  async handleChangePage(event, newPage) {
    event.preventDefault();

    const salesDeliveryData = this.props.sales[SLICE_NAME_SALES_DELIVERY_DATA];

    if (isEmpty(salesDeliveryData)) return;

    const { per_page: paginate, next_page_url, current_page } = salesDeliveryData;

    const isCannotNextPage = !next_page_url && current_page < newPage + 1;

    if (isCannotNextPage) return;

    let searchParams = {
      page: Number(newPage) + 1,
    };

    let currentSearchParams = objHelper.filterKeyObj(
      salesDeliveryData,
      [],
      DEFAULT_SALES_DELIVERY_SEARCH_FE_PARAMS,
    );

    // removing previx on key search and filter by existed params
    if (currentSearchParams) {
      currentSearchParams = objHelper.changeSuffixKey(currentSearchParams, 'search_', true);

      currentSearchParams = objHelper.filterKeyObj(
        currentSearchParams,
        [],
        DEFAULT_SALES_DELIVERY_LIST_PARAMS,
      );

      searchParams = {
        ...searchParams,
        ...currentSearchParams,
        paginate,
      };
    }

    await this.props.getListSalesDelivery(searchParams);
  }

  async handleChangeRowsPerPage(event) {
    const { value: newRowsPerPage } = event.target;

    const { t } = this.props;

    const salesDeliveryData = this.props.sales[SLICE_NAME_SALES_DELIVERY_DATA];

    if (isEmpty(salesDeliveryData)) return;

    const { next_page_url, current_page, per_page: paginate } = salesDeliveryData;

    if (isEqual(paginate, newRowsPerPage)) return;

    const isNotExistedNextData = !next_page_url && paginate < newRowsPerPage && current_page === 1;

    if (isNotExistedNextData) {
      this.props.setFailedMessage(
        t('dashboard.sales.sales-order.message.max-data.primary'),
        t('dashboard.sales.sales-order.message.max-data.secondary'),
      );

      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      rowsPerPage: newRowsPerPage,
    }));

    let searchParams = {
      current_page,
      paginate: newRowsPerPage,
    };

    let currentSearchParams = objHelper.filterKeyObj(
      salesDeliveryData,
      [],
      DEFAULT_SALES_DELIVERY_SEARCH_FE_PARAMS,
    );

    // removing previx on key search and filtering not existed on params
    if (currentSearchParams) {
      currentSearchParams = objHelper.changeSuffixKey(currentSearchParams, 'search_', true);

      currentSearchParams = objHelper.filterKeyObj(
        currentSearchParams,
        [],
        DEFAULT_SALES_DELIVERY_LIST_PARAMS,
      );

      searchParams = {
        ...searchParams,
        ...currentSearchParams,
      };
    }

    await this.props.getListSalesDelivery(searchParams);
  }

  async componentDidMount() {
    const { t } = this.props;

    const initSearchSalesOrder = initialSearchSalesDelivery(t);

    await this.props.getListSalesDelivery({
      paginate: this.state.rowsPerPage,
      ...initSearchSalesOrder,
      isShowMessage: false,
    });
  }

  render() {
    const { t, classes, auth, sales } = this.props;

    const { rowsPerPage } = this.state;

    const { permissions } = auth.data;

    const salesDeliveryData = sales[SLICE_NAME_SALES_DELIVERY_DATA];

    let salesDeliveryDataList = [];
    let currPagination = rowsPerPage;

    const columnsTableHeaderSalesDelivery = columnsTableSalesDelivery(t);

    if (!isEmpty(salesDeliveryData)) {
      if (Array.isArray(salesDeliveryData.data)) {
        salesDeliveryDataList = salesDeliveryData.data;
      }

      currPagination = Number(salesDeliveryData.per_page);
    }

    if (Number.isNaN(currPagination)) currPagination = 20;

    return (
      <Box className={classes.boxContainerTable}>
        <Paper elevation={0}>
          <TableContainer className={'tableContainerConstantHeightMDSizeConstantLayoutPrimary'}>
            <Table stickyHeader className='tableMain' aria-label='table-sales-delivery-list'>
              <TableHeadSearchSalesDelivery
                columnsTableHeaderSalesDelivery={columnsTableHeaderSalesDelivery}
              />

              <TableBody className='primaryTableBody'>
                {sales.statusRequest === STATUS_REQUEST_LIST_SALES_DELIVERY_PENDING && (
                  <TableRowLoading
                    rowsAmount={rowsPerPage}
                    columnsAmount={columnsTableHeaderSalesDelivery.length}
                  />
                )}
                {!isEmpty(salesDeliveryDataList) &&
                  sales.statusRequest !== STATUS_REQUEST_LIST_SALES_DELIVERY_PENDING &&
                  salesDeliveryDataList.map((singleSalesDeliveryData, index) => {
                    const baseUrl = strHelp.templateString(
                      REACT_APP_SALES_SALES_DELIVERY_DETAILS_TEMPLATE_URL,
                      singleSalesDeliveryData,
                    );

                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        className={'hoverEntireRow'}
                        onDoubleClick={() => this.props.navigate(baseUrl)}
                      >
                        {columnsTableHeaderSalesDelivery.map(
                          (columnTableHeader, indexColumnHeader) => {
                            const { name: columnName } = columnTableHeader;

                            let valueData = singleSalesDeliveryData[columnName];

                            if (columnsSearchSalesDeliveryFormatAction.includes(columnName)) {
                              return (
                                <TableCell
                                  key={indexColumnHeader}
                                  align={columnTableHeader.align}
                                  className='tableCellListData'
                                >
                                  {tooltipListSalesDelivery(t).map((tooltipData, index) => {
                                    return (
                                      <BoxActionPermission
                                        key={index}
                                        permissionsUser={permissions}
                                        permissionRequired={tooltipData.permission}
                                      >
                                        <Tooltip title={tooltipData.title}>
                                          <IconButton
                                            name={tooltipData.name}
                                            value={encryptData(
                                              JSON.stringify(singleSalesDeliveryData),
                                            )}
                                            onClick={this.handleClickActionSalesDelivery}
                                            className={classes.iconButtonContainer}
                                          >
                                            <SvgIcon component={iconListSalesDeliveryData[0]} />
                                          </IconButton>
                                        </Tooltip>
                                      </BoxActionPermission>
                                    );
                                  })}
                                  {valueData}
                                </TableCell>
                              );
                            }

                            if (columnsSearchSalesDeliveryFormatLink.includes(columnName)) {
                              return (
                                <TableCell key={indexColumnHeader}>
                                  <TypographyLinkPrimary
                                    baseUrl={baseUrl}
                                    typographyValue={valueData}
                                  />
                                </TableCell>
                              );
                            }

                            if (columnsSearchSalesDeliveryFormatDate.includes(columnName)) {
                              if (dateHelpers.isValidDateV2(new Date(valueData))) {
                                valueData = formatHelp.getReadableDateV2(valueData);
                              }
                            }

                            if (
                              columnsDataSalesDeliveryFormatCalculationNumber.includes(columnName)
                            ) {
                              valueData = formatHelp.currencyFormatWithRegex(valueData);
                            }

                            if (columnsSearchSalesDeliveryFormatStatus.includes(columnName)) {
                              let usedStatus = salesDeliveryStatus(t);

                              if (columnName === 'sales_handover_status')
                                usedStatus = minutesHandoverStatus(t);
                              if (columnName === 'status_invoice')
                                usedStatus = salesInvoiceStatus(t);

                              const selectedSalesDeliveryData = arrHelp.filterObjDataWithID(
                                usedStatus,
                                'value',
                                valueData,
                              );

                              let colorChip = 'chipSecondaryStatus';
                              let label = t('base.sales-delivery-status.open');

                              // getting color chip from selected sales delivery invoice data
                              if (!isEmpty(selectedSalesDeliveryData)) {
                                label = selectedSalesDeliveryData.label;
                                colorChip = selectedSalesDeliveryData.colorChip;
                              }

                              return (
                                <TableCell key={indexColumnHeader}>
                                  <Chip size='small' color={colorChip} label={label} />
                                </TableCell>
                              );
                            }

                            return (
                              <TableCell key={indexColumnHeader} align={columnTableHeader.align}>
                                {valueData}
                              </TableCell>
                            );
                          },
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {isEmpty(salesDeliveryDataList) &&
              sales.statusRequest !== STATUS_REQUEST_LIST_SALES_DELIVERY_PENDING && (
                <BoxTableNotFound
                  textNotFound={t('dashboard.sales.sales-delivery.table.body-not-found')}
                  textButton={t('dashboard.sales.sales-delivery.button.add-sales-delivery')}
                  handleClickedButton={() => {
                    this.props.navigate(REACT_APP_SALES_SALES_DELIVERY_CREATE_NEW_URL);
                  }}
                  isExistButton={true}
                />
              )}
          </TableContainer>
          <TablePaginationMain
            page={salesDeliveryData ? salesDeliveryData.current_page - 1 : 0}
            prev_page={salesDeliveryData ? salesDeliveryData.prev_page_url : null}
            next_page={salesDeliveryData ? salesDeliveryData.next_page_url : null}
            rowsPerPage={currPagination}
            isLoadingData={
              sales.statusRequest === STATUS_REQUEST_LIST_SALES_DELIVERY_PENDING ||
              !salesDeliveryDataList.length
            }
            labelRowsPerPage={t('label.table.pagination.primary.rows-per-page')}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    );
  }
}

SalesDeliveryList.defaultProps = {
  isUseChangedPageOnDetails: false,
};

SalesDeliveryList.propsTypes = {
  handleChangeStatusPage: PropTypes.func.isRequired,
  onClickNewSalesDelivery: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    sales: state.sales,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListSalesDelivery: (paramsGetListSalesDelivery) =>
      dispatch(getListSalesDelivery(paramsGetListSalesDelivery)),
    setFailedMessage: (primaryText, secondaryText) =>
      messageHelper.failedMessage(dispatch, primaryText, secondaryText, false),
  };
};

const stylingSalesDeliveryList = withStyles(styles)(SalesDeliveryList);

const SalesDeliveryListTranslate = withTranslation()(stylingSalesDeliveryList);

const SalesDeliveryListWithRouter = withRouter(SalesDeliveryListTranslate);

const ConnectedSalesDeliveryList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SalesDeliveryListWithRouter);

export default ConnectedSalesDeliveryList;
