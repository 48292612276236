import { createAsyncThunk } from '@reduxjs/toolkit';

import journalEntryServices from '../../services/API/journal-entry/journal-entry.service';
import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { REACT_APP_DATA_GENERAL_JOURNAL_URL } from '../../utils/configs/url.config';
import {
  DEFAULT_IMPORT_FILE_ACTION_TYPE,
  DEFAULT_IMPORT_FILE_PROGRESS_ACTION_TYPE,
  JOURNAL_ENTRY_ASYNC_THUNK_TYPE,
} from '../../utils/constants/action-type.constant';
import {
  LOCAL_STORAGE_JOURNAL_ENTRY,
  LOCAL_STORAGE_JOURNAL_ENTRY_DETAIL,
} from '../../utils/constants/storage.constant';
import {
  SLICE_NAME_IMPORT_JOURNAL_ENTRY,
  SLICE_NAME_IMPORT_JOURNAL_ENTRY_PROGRESS,
} from '../../utils/constants/store.constant';
import { DEFAULT_STATUS_PAGE_DETAIL_LEDGER_JOURNAL_ENTRY } from '../../utils/default/status-page.default';
import objHelper from '../../utils/helpers/object.helper';
import {
  creatorAddActionWithPreviewButton,
  creatorAddActionWithStoreDataOnSlice,
  creatorDetailAction,
} from '../creator-action/creator-action.helper';
import messageHelper from '../message/message.helper';

// list journal entry in company
export const listJournalEntry = createAsyncThunk(
  'journal-entry/list',
  async (paramsGetListJournalEntry, thunkAPI) => {
    const { transaction_no, transaction_date, transaction_value, to_date, from_date } =
      paramsGetListJournalEntry;

    try {
      const response = await journalEntryServices.listJournalEntry(paramsGetListJournalEntry);

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      let { data: journalEntryData, sucess } = response.data;

      if (!sucess || !Array.isArray(journalEntryData.data)) {
        journalEntryData = {
          ...journalEntryData,
          data: [],
        };
      }

      // adding parameter for next search
      if (transaction_no) {
        journalEntryData.search_transaction_no = transaction_no;
      }

      if (transaction_date) {
        journalEntryData.search_transaction_date = transaction_date;
      }

      if (transaction_value) {
        journalEntryData.search_transaction_value = transaction_value;
      }

      if (to_date) {
        journalEntryData.search_to_date = to_date.toString();
      }

      if (from_date) {
        journalEntryData.search_from_date = from_date.toString();
      }

      LocalStorage.set(LOCAL_STORAGE_JOURNAL_ENTRY, journalEntryData);

      return { journalEntryData };
    } catch (error) {
      const { response } = error;

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.journal-entry',
          'error.list-data.journal-entry',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.list-data.journal-entry',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// refresh list journal entry from cache previous data search
export const listJournalEntryRefresh = createAsyncThunk(
  'journal-entry/list-refresh',
  async (discardKeySearch = [], thunkAPI) => {
    const currentDataJournalEntry = LocalStorage.get(LOCAL_STORAGE_JOURNAL_ENTRY);

    // get filtering data from previous search
    let filterDataSearch = objHelper.filterKeyObj(currentDataJournalEntry, discardKeySearch, [
      'search_transaction_date',
      'search_transaction_value',
      'search_transaction_no',
      'current_page',
      'per_page',
      'search_from_date',
      'search_to_date',
    ]);

    // data for cache filter search
    const filterDataNextSearch = objHelper.filterKeyObj(filterDataSearch, [
      'per_page',
      'current_page',
    ]);

    filterDataSearch = {
      ...filterDataSearch,
      page: filterDataSearch ? filterDataSearch.current_page : '',
      paginate: filterDataSearch ? filterDataSearch.per_page : '',
      transaction_no: filterDataSearch ? filterDataSearch.search_transaction_no : '',
      transaction_date: filterDataSearch ? filterDataSearch.search_transaction_date : '',
      transaction_value: filterDataSearch ? filterDataSearch.search_transaction_value : '',
      from_date: filterDataSearch ? filterDataSearch.search_from_date : '',
      to_date: filterDataSearch ? filterDataSearch.search_to_date : '',
    };

    // get filter that only existed value
    filterDataSearch = objHelper.filteringExistedValue(filterDataSearch);

    // removing unused key for search data
    filterDataSearch = objHelper.filterKeyObj(filterDataSearch, [
      'search_transaction_date',
      'search_transaction_value',
      'search_transaction_no',
      'current_page',
      'per_page',
      'search_from_date',
      'search_to_date',
    ]);

    try {
      const response = await journalEntryServices.listJournalEntry(filterDataSearch);

      if (!response) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.journal-entry',
          'error.list-data.journal-entry',
          false,
        );

        return thunkAPI.rejectWithValue(response);
      }

      const { data: dataResponseJournalEntry, sucess } = response.data;

      if (!sucess) {
        return { journalEntryData: null };
      }

      // adding search cache for next search
      const dataResponseJournalEntryWithSearch = Object.assign(dataResponseJournalEntry, {
        ...filterDataNextSearch,
      });

      LocalStorage.set(LOCAL_STORAGE_JOURNAL_ENTRY, dataResponseJournalEntryWithSearch);

      return { journalEntryData: dataResponseJournalEntryWithSearch };
    } catch (error) {
      const { response } = error;

      if (!response) {
        return thunkAPI.rejectWithValue(error);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.journal-entry',
          'error.list-data.journal-entry',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.list-data.journal-entry',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

export const detailJournalEntry = createAsyncThunk(
  'journal-entry/detail',
  async (transactionID, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      transactionID,
      journalEntryServices.detailJournalEntry,
      LOCAL_STORAGE_JOURNAL_ENTRY_DETAIL,
      'journalEntryDetail',
      'error.detail.journal-entry',
    );
  },
);

// add journal entry data
export const addJournalEntry = createAsyncThunk(
  'journal-entry/journal-entry-add',
  async (bodyAddJournalEntry, thunkAPI) => {
    return await creatorAddActionWithPreviewButton(
      thunkAPI,
      bodyAddJournalEntry,
      journalEntryServices.addJournalEntry,
      'success.add.journal-entry',
      'error.add.journal-entry',
      {},
      {},
      'transaction_id',
      REACT_APP_DATA_GENERAL_JOURNAL_URL,
      { statusPage: DEFAULT_STATUS_PAGE_DETAIL_LEDGER_JOURNAL_ENTRY },
    );
  },
);

// update journal entry data
export const updateJournalEntry = createAsyncThunk(
  'journal-entry/update',
  async (bodyUpdateJournalEntry, thunkAPI) => {
    const { journal_entry_id } = bodyUpdateJournalEntry;

    try {
      const response = await journalEntryServices.updateJournalEntry(bodyUpdateJournalEntry);

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      // get response message
      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.update.journal-entry',
          'success.update.journal-entry',
          {},
          { journal_entry_id },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.update.journal-entry',
          {},
          { journal_entry_id },
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      // get response message
      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.update.journal-entry',
          'error.update.journal-entry',
          false,
          {},
          { journal_entry_id },
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.update.journal-entry',
          false,
          {},
          { journal_entry_id },
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// delete journal entry data
export const deleteJournalEntry = createAsyncThunk(
  'journal-entry/delete',
  async ({ transaction_id, transaction_no }, thunkAPI) => {
    try {
      const response = await journalEntryServices.deleteJournalEntry({ transaction_id });

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      // get response message
      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.delete.journal-entry',
          'success.delete.journal-entry',
          {},
          { transaction_no },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.delete.journal-entry',
          {},
          { transaction_no },
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      // get response message
      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.delete.journal-entry',
          'error.delete.journal-entry',
          false,
          {},
          { transaction_no },
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.delete.journal-entry',
          false,
          {},
          { transaction_no },
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

export const importJournalEntry = createAsyncThunk(
  JOURNAL_ENTRY_ASYNC_THUNK_TYPE[DEFAULT_IMPORT_FILE_ACTION_TYPE],
  async (bodyData, thunkAPI) => {
    return await creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      bodyData,
      journalEntryServices.importJournalEntry,
      SLICE_NAME_IMPORT_JOURNAL_ENTRY,
    );
  },
);

export const getImportJournalEntryProgress = createAsyncThunk(
  JOURNAL_ENTRY_ASYNC_THUNK_TYPE[DEFAULT_IMPORT_FILE_PROGRESS_ACTION_TYPE],
  async (params, thunkAPI) => {
    return creatorDetailAction(
      thunkAPI,
      params,
      journalEntryServices.getImportJournalEntryProgress,
      null,
      SLICE_NAME_IMPORT_JOURNAL_ENTRY_PROGRESS,
    );
  },
);
