// column report income statement list that handling table head with translation
export const columnsTableReportIncomeStatement = (t) => {
  return [
    {
      name: 'information',
      label: t('dashboard.report.income-statement.table.header-column.information'),
      format: 'normal',
      width: 1600,
    },
    {
      name: 'journal_balance',
      align: 'right',
      label: t('dashboard.report.income-statement.table.header-column.credit'),
      format: 'normal-currency',
      width: 200,
      maxWidth: 250,
    },
  ];
};

export const rowsTableReportIncomeStatementWithTranslation = (t) => {
  return [
    {
      rowName: 'income',
      translationRowName: t('table.row.income'),
    },
    {
      rowName: 'total-income',
      translationRowName: t('table.row.total-income'),
    },
    {
      rowName: 'cost-of-goods',
      translationRowName: t('table.row.cost-of-goods'),
    },
    {
      rowName: 'total-cost-of-goods',
      translationRowName: t('table.row.total-cost-of-goods'),
    },
    {
      rowName: 'gross-profit',
      translationRowName: t('table.row.gross-profit'),
    },
    {
      rowName: 'cost-of-sale',
      translationRowName: t('table.row.cost-of-sale'),
    },
    {
      rowName: 'total-cost-of-sale',
      translationRowName: t('table.row.total-cost-of-sale'),
    },
    {
      rowName: 'other-income',
      translationRowName: t('table.row.other-income'),
    },
    {
      rowName: 'total-other-income',
      translationRowName: t('table.row.total-other-income'),
    },
    {
      rowName: 'other-expense',
      translationRowName: t('table.row.other-expense'),
    },
    {
      rowName: 'total-other-expense',
      translationRowName: t('table.row.total-other-expense'),
    },
    {
      rowName: 'profit-loss',
      translationRowName: t('table.row.profit-loss'),
    },
  ];
};

export const rowsTableProfitTotal = ['gross-profit', 'profit-loss'];

export const rowsTableWithChildren = [
  'income',
  'cost-of-goods',
  'cost-of-sale',
  'other-income',
  'other-expense',
];

export const allRowsTableReportIncomeStatement = [
  'income',
  'total-income',
  'cost-of-goods',
  'total-cost-of-goods',
  'gross-profit',
  'cost-of-sale',
  'total-cost-of-sale',
  'other-income',
  'total-other-income',
  'other-expense',
  'total-other-expense',
  'profit-loss',
];

export const correspondAPIkeynameWithViewKeyName = {
  Pendapatan: 'income',
  'Harga Pokok Penjualan': 'cost-of-goods',
  Beban: 'cost-of-sale',
  'Pendapatan Lainnya': 'other-income',
  'Beban Lainnya': 'other-expense',
};

export const allRowsTableReportIncomeStatementV2 = [
  'Pendapatan',
  'Harga Pokok Penjualan',
  'Beban',
  'Pendapatan Lainnya',
  'Beban Lainnya',
];

export const rowsTableIncomeStatementsTotalCalculation = [
  'total-income',
  'total-cost-of-goods',
  'total-cost-of-sale',
  'total-other-income',
  'total-other-expense',
];
