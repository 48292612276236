export const styles = (theme) => {
  return {
    boxContainerTable: {
      borderRadius: '8px',
    },
    paperContainerTable: {
      width: '100%',
      borderRadius: '8px',
      boxShadow: theme.shadows[0],
    },
    removeBoxShadownTableContainer: {
      boxShadow: 'none',
    },
    tableContainerSalesOrder: {
      height: 'calc(100vh - 260px)',
      minHeight: '275px',
      borderRadius: '8px',
    },
    iconButtonContainer: {
      '&:hover': {
        backgroundColor: 'rgba(222, 235, 255, 0.6)',
        '& svg': {
          fill: '#0052CC',
        },
      },
    },
  };
};
