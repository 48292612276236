import { DEFAULT_KEY_NAME_ALTER_ROUTE_PATHNAME_PROPERTIES } from './object-keyname.default';

const defaultAlterRoutePathname = {
  usagePreviousPathname: true,
  showingCurrentPathname: false,
  removeIndexPaths: [],
};

export const alterRoutePathnameJournalSalesDeliveryDetails = {
  [DEFAULT_KEY_NAME_ALTER_ROUTE_PATHNAME_PROPERTIES]: {
    ...defaultAlterRoutePathname,
  },
};

export const alterRoutePathnameJournalSalesInvoiceDetails = {
  [DEFAULT_KEY_NAME_ALTER_ROUTE_PATHNAME_PROPERTIES]: {
    ...defaultAlterRoutePathname,
  },
};

export const alterRoutePathnameJournalSalesPaymentDetails = {
  [DEFAULT_KEY_NAME_ALTER_ROUTE_PATHNAME_PROPERTIES]: {
    ...defaultAlterRoutePathname,
  },
};

export const alterRoutePathnameJournalSalesReturnDetails = {
  [DEFAULT_KEY_NAME_ALTER_ROUTE_PATHNAME_PROPERTIES]: {
    ...defaultAlterRoutePathname,
  },
};

export const alterRoutePathnameJournalPurchaseDeliveryDetails = {
  [DEFAULT_KEY_NAME_ALTER_ROUTE_PATHNAME_PROPERTIES]: {
    ...defaultAlterRoutePathname,
  },
};

export const alterRoutePathnameJournalPurchaseInvoiceDetails = {
  [DEFAULT_KEY_NAME_ALTER_ROUTE_PATHNAME_PROPERTIES]: {
    ...defaultAlterRoutePathname,
  },
};

export const alterRoutePathnameJournalPurchasePaymentDetails = {
  [DEFAULT_KEY_NAME_ALTER_ROUTE_PATHNAME_PROPERTIES]: {
    ...defaultAlterRoutePathname,
  },
};

export const alterRoutePathnameJournalPurchaseReturnDetails = {
  [DEFAULT_KEY_NAME_ALTER_ROUTE_PATHNAME_PROPERTIES]: {
    ...defaultAlterRoutePathname,
  },
};

export const alterRoutePathnameJournalAssetManagementDetails = {
  [DEFAULT_KEY_NAME_ALTER_ROUTE_PATHNAME_PROPERTIES]: {
    ...defaultAlterRoutePathname,
  },
};

export const alterRoutePathnameCashBankAccountTransactionList = {
  [DEFAULT_KEY_NAME_ALTER_ROUTE_PATHNAME_PROPERTIES]: {
    ...defaultAlterRoutePathname,
    removeIndexPaths: [2],
  },
};

export const alterRoutePathnamePurchaseDeliveryHandoverDetails = {
  [DEFAULT_KEY_NAME_ALTER_ROUTE_PATHNAME_PROPERTIES]: {
    ...defaultAlterRoutePathname,
    removeIndexPaths: [2],
  },
};

export const alterRoutePathnameClosingBookDetails = {
  [DEFAULT_KEY_NAME_ALTER_ROUTE_PATHNAME_PROPERTIES]: {
    ...defaultAlterRoutePathname,
    usagePreviousPathname: false,
    showingCurrentPathname: true,
    removeIndexPaths: [2],
  },
};

export const alterRoutePathnameClosingBookCreate = {
  [DEFAULT_KEY_NAME_ALTER_ROUTE_PATHNAME_PROPERTIES]: {
    ...defaultAlterRoutePathname,
  },
};

export const alterRoutePathnamePurchaseDeliveryHandoverJournalDetails = {
  [DEFAULT_KEY_NAME_ALTER_ROUTE_PATHNAME_PROPERTIES]: {
    ...defaultAlterRoutePathname,
  },
};

export const alterRoutePathnameBillingDetails = {
  [DEFAULT_KEY_NAME_ALTER_ROUTE_PATHNAME_PROPERTIES]: {
    ...defaultAlterRoutePathname,
  },
};

export const alterRoutePathnameDataStockAdjustmentDetails = {
  [DEFAULT_KEY_NAME_ALTER_ROUTE_PATHNAME_PROPERTIES]: {
    ...defaultAlterRoutePathname,
  },
};
