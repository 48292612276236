import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import {
  hasImplementedUnitProductUnit,
  keynamesImpelementProductUnit,
} from '../../default/unit-product.default';
import validation from '../../helpers/validation.helpers';

const initialID = uuidV4();

/**
 * quantity comparison, comparison from quantity with quantity origin
 * quantity_comparison_const_props, appear for update action,
 *  usage: preserve quantity comparison data
 * quantity_available_with_unit getting data with quantity available const with unit
 */
export const plainProductPurchaseOrderDataWithoutID = {
  product_id: null,
  product_id_container: null,
  description: '',
  product_name: '',
  product_unit: '',
  quantity: '',
  quantity_origin: '',
  quantity_available: '',
  quantity_available_const: '',
  quantity_comparison: 1,
  quantity_comparison_const_props: null,
  quantity_available_with_unit: '',
  unit: '',
  unit_origin: '',
  unit_destination: '',
  is_new_product: false,
  new_product: null,
};

export const initialDataProduct = (additionalID = '', newID = '') => {
  const usedID = newID ? newID : initialID + additionalID;

  return {
    id: usedID,
    ...plainProductPurchaseOrderDataWithoutID,
  };
};

export const plainProductPurchaseDeliveryWithoutID = {
  product_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  quantity: {
    error: false,
    isValid: (value) => validation.isNumberGreaterOrEqualThan(value, 1),
  },
  unit: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  unit_destination: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
};

export const initialValidationProduct = (additionalID = '', newID = '') => {
  const usedID = newID ? newID : initialID + additionalID;

  return {
    id: usedID,
    ...plainProductPurchaseDeliveryWithoutID,
  };
};

export const plainPurchaseDeliveryDataWithoutID = {
  purchase_order_id: '',
  purchase_order_id_container: null,
  supplier_id: null,
  supplier_name: '',
  supplier_id_container: null,
  supplier_address: '',
  delivery_date: new Date(),
  reference_number: '',
  reference_number_container: null,
  description: '',
  attachment: [],
  uuid_attachment: null,
  is_new_ref_number: false,
  new_ref_number: null,
  is_new_supplier: false,
  new_supplier: null,
  on_behalf: '',
  warehouse_id: '',
  warehouse_id_container: null,
  minDateInputRef: null,
  is_sent: false,
  tag: [],
  tagContainer: [],
  tagNameInput: '',
};

export const initialDataPurchaseDelivery = (additionalID = '', newID = '') => {
  const usedID = newID ? newID : initialID + additionalID;

  return {
    id: usedID,
    ...plainPurchaseDeliveryDataWithoutID,
  };
};

export const plainPurchaseDeliveryValidationWithoutID = {
  purchase_order_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  supplier_id: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  supplier_address: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  delivery_date: {
    error: false,
    isValid: (value, minDateInputRef) => {
      if (!minDateInputRef) {
        return validation.notnull(value);
      }

      return moment(new Date(minDateInputRef)).isSameOrBefore(new Date(value), 'D');
    },
  },
  reference_number: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  on_behalf: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const initialValidationPurchaseDelivery = (additionalID = '', newID = '') => {
  const usedID = newID ? newID : initialID + additionalID;

  return {
    id: usedID,
    ...plainPurchaseDeliveryValidationWithoutID,
  };
};

export const allowKeysDataProduct = [
  'product_id',
  'description',
  'quantity',
  'purchase_order_product_id',
];

export const allowKeysDataProductPurchaseDelivery = [
  'product_id',
  'description',
  'quantity',
  'unit_origin',
  'unit_destination',
  'quantity',
  'quantity_origin',
  'purchase_order_product_id',
].filter((key) =>
  !hasImplementedUnitProductUnit ? !keynamesImpelementProductUnit.includes(key) : true,
);

export const allowKeysDataPurchaseDelivery = [
  'purchase_order_id',
  'supplier_id',
  'supplier_name',
  'supplier_address',
  'reference_number',
  'delivery_date',
  'description',
  'product',
  'on_behalf',
  'warehouse_id',
  'uuid_attachment',
  'is_sent',
  'tag',
];
