import { createAsyncThunk } from '@reduxjs/toolkit';

import warehouseServices from '../../services/API/warehouse/warehouse.service';
import { REACT_APP_WAREHOUSE_URL } from '../../utils/configs/url.config';
import {
  LOCAL_STORAGE_SETTINGS_WAREHOUSE_DATA,
  LOCAL_STORAGE_SETTINGS_WAREHOUSE_DETAIL,
  LOCAL_STORAGE_SETTINGS_WAREHOUSE_PRODUCT_DATA_AUTOCOMPLETE,
} from '../../utils/constants/storage.constant';
import {
  SLICE_NAME_SETTINGS_WAREHOUSE_DATA,
  SLICE_NAME_SETTINGS_WAREHOUSE_DETAIL,
  SLICE_NAME_SETTINGS_WAREHOUSE_PRODUCT_DATA_AUTOCOMPLETE,
} from '../../utils/constants/store.constant';
import { DEFAULT_SETTINGS_WAREHOUSE_SEARCH_FE_PARAMS } from '../../utils/default/params.default';
import { DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION_SECONDARY } from '../../utils/default/params.default';
import { DEFAULT_STATUS_PAGE_DETAIL_SETTINGS_WAREHOUSE } from '../../utils/default/status-page.default';
import {
  creatorAddActionWithPreviewButton,
  creatorDetailAction,
  creatorListAction,
  creatorListActionWithDynamicSliceName,
  creatorListRefreshAction,
  creatorUpdateAction,
} from '../creator-action/creator-action.helper';

// action get warehouse list
export const getWarehouseList = createAsyncThunk(
  'warehouse/warehouse-list',
  async (paramsGetWarehouseList, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      paramsGetWarehouseList,
      warehouseServices.getWarehouseList,
      LOCAL_STORAGE_SETTINGS_WAREHOUSE_DATA,
      SLICE_NAME_SETTINGS_WAREHOUSE_DATA,
      'warehouse_id',
      'error.list-data.settings-warehouse',
    );
  },
);

// action getting warehouse list with dynamic store
// where you can passing slice name and localstorage name on them
// and rest is params for paramsGetWarehouseList
// { sliceName, localStorageName, ...paramsGetWarehouseList }
export const getWarehouseListWithDynamicStore = createAsyncThunk(
  'warehouse/warehouse-list/dynamic-store',
  async (
    {
      sliceName = SLICE_NAME_SETTINGS_WAREHOUSE_DATA,
      localStorageName = LOCAL_STORAGE_SETTINGS_WAREHOUSE_DATA,
      ...paramsGetWarehouseList
    },
    thunkAPI,
  ) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      paramsGetWarehouseList,
      warehouseServices.getWarehouseList,
      localStorageName,
      sliceName,
      'warehouse_id',
      'error.list-data.settings-warehouse',
    );
  },
);

// action get refresh warehouse list
export const getWarehouseListRefresh = createAsyncThunk(
  'warehouse/warehouse-list-refresh',
  async (discardKeySearch = [], thunkAPI) => {
    return await creatorListRefreshAction(
      thunkAPI,
      warehouseServices.getWarehouseList,
      LOCAL_STORAGE_SETTINGS_WAREHOUSE_DATA,
      SLICE_NAME_SETTINGS_WAREHOUSE_DATA,
      discardKeySearch,
      DEFAULT_SETTINGS_WAREHOUSE_SEARCH_FE_PARAMS,
      'error.list-data.settings-warehouse',
    );
  },
);

// action getting warehouse product list with dynamic store
// where you can passing slice name and localstorage name on them
// and rest is params for paramsGetWarehouseProductList
// { sliceName, localStorageName, ...paramsGetWarehouseProductList }
export const getWarehouseProductListWithDynamicStore = createAsyncThunk(
  'warehouse/warehouse-product-list/dynamic-store',
  async (
    {
      sliceName = SLICE_NAME_SETTINGS_WAREHOUSE_PRODUCT_DATA_AUTOCOMPLETE,
      localStorageName = LOCAL_STORAGE_SETTINGS_WAREHOUSE_PRODUCT_DATA_AUTOCOMPLETE,
      ...paramsGetWarehouseProductList
    },
    thunkAPI,
  ) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      paramsGetWarehouseProductList,
      warehouseServices.getWarehouseProductList,
      localStorageName,
      sliceName,
      'product_id',
      'error.list-data.settings-warehouse-product',
      DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION_SECONDARY,
    );
  },
);

// action get warehouse details
export const getDetailWarehouse = createAsyncThunk(
  'warehouse/warehouse-detail',
  async (warehouseID, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      warehouseID,
      warehouseServices.getDetailWarehouse,
      LOCAL_STORAGE_SETTINGS_WAREHOUSE_DETAIL,
      SLICE_NAME_SETTINGS_WAREHOUSE_DETAIL,
      'error.detail.warehouse',
    );
  },
);

// action add warehouse
export const addWarehouse = createAsyncThunk(
  'warehouse/warehouse-add',
  async (bodyAddWarehouse, thunkAPI) => {
    return await creatorAddActionWithPreviewButton(
      thunkAPI,
      bodyAddWarehouse,
      warehouseServices.addWarehouse,
      'success.add.warehouse',
      'error.add.warehouse',
      {},
      {},
      'warehouse_id',
      REACT_APP_WAREHOUSE_URL,
      { statusPage: DEFAULT_STATUS_PAGE_DETAIL_SETTINGS_WAREHOUSE },
    );
  },
);

// action update warehouse
export const updateWarehouse = createAsyncThunk(
  'warehouse/warehouse-update',
  async (bodyUpdateWarehouse, thunkAPI) => {
    return await creatorUpdateAction(
      thunkAPI,
      bodyUpdateWarehouse,
      warehouseServices.updateWarehouse,
      'success.update.warehouse',
      'error.update.warehouse',
      {},
      {},
    );
  },
);

const warehouseActions = {
  getWarehouseList,
  getWarehouseListWithDynamicStore,
  getWarehouseListRefresh,
  getWarehouseProductListWithDynamicStore,
  getDetailWarehouse,
  addWarehouse,
  updateWarehouse,
};

export default warehouseActions;
