export const DEFAULT_CALCULATION_NUMBER = '0.00';
export const DEFAULT_MIN_DEBIT_CREDIT_NUMBER = 0;
export const DEFAULT_MAX_DEBIT_CREDIT_NUMBER = 999999999999.99;
export const DEFAULT_EMPTY_CALCULATIONS_NUMBER = ['0.00', '0', '0.0', 0, ''];
export const DEFAULT_NUMBER_SUBSTITUTE_REFERENCE_NUMBER = '0';
export const DEFAULT_MIN_LENGTH_HISTORY_PATHNAME_FOR_SHOWING_BACK_BUTTON = 2;
export const DEFAULT_MAXIMUM_UNREAD_NOTIFICATION = 99;
export const DEFAULT_MINIMUM_POSITIVE_FRACTION_CALCULATION_NUMBER = 0.01;
export const DEFAULT_COMPARISON_VALUE_NUMBER = 1;
export const DEFAULT_QUANTITY_AVAILABLE_STOCK_MONITORED_PRODUCT = 0;
export const DEFAULT_QUANTITY_AVAILABLE_STOCK_NOT_MONITORED_PRODUCT = 1;
export const DEFAULT_AMOUNT_MONTH_IN_YEAR = 12;
export const DEFAULT_AMOUNT_DAYS_IN_MONTH = 30;
export const DEFAULT_MINIMUM_COMPANY_NAME_LENGTH = 3;
export const DEFAULT_MINIMUM_BEGINNING_BALANCE_ACCOUNT_LENGTH = 2;
