// check number on beetwen limit and min number
function isNumberBetween(number, minNumber = 0, maxNumber = 999999999999.99) {
  if (typeof number === 'object' || Array.isArray(number)) {
    return number;
  }

  let numberInString = number.toString();

  // checking validation if contain cocmma
  if (numberInString.indexOf(',') !== -1) {
    numberInString = numberInString.replace(/,/g, '');
  }

  return Number(numberInString) <= Number(maxNumber) && Number(numberInString) >= Number(minNumber);
}

function isNumber(number) {
  if (typeof number !== 'number' && typeof number !== 'string') {
    return number;
  }

  const numberPattern = /\d+/g;

  return numberPattern.test(number);
}

/**
 *
 * @param   { Number } num        number target
 * @returns
 *
 * determine negative number
 */
function isNegative(num) {
  if (Number.isNaN(Number(num))) return false;

  return Number(num) < 0;
}

const numberHelper = {
  isNumberBetween,
  isNumber,
  isNegative,
};

export default numberHelper;
