const gray = {
  B25: '#fcfcfd',
  B50: '#f9fafb',
  B100: '#f2f4f7',
  B200: '#e4e7ec',
  B300: '#d0d5dd',
  B400: '#98a2b3',
  B499: '#6b778c',
  B500: '#667085',
  B600: '#475467',
  B700: '#344054',
  B800: '#1d2939',
  B900: '#101828',
};

export default gray;
