import { FORMAT_CURRENCY_AUTO_COMMA_COLUMN } from '../../../components/table-row/TableRowPrimary/TableRowPrimary';

// table column purchase debit note details
export const columnsTableHeaderPurchaseDebitNoteDetails = (t) => {
  return [
    {
      name: 'expired_date',
      label: t('table.header-column.deposit-to'),
      width: 300,
    },
    {
      name: 'description',
      label: t('table.header-column.description'),
      width: 300,
      align: 'left',
    },
    {
      name: 'amount',
      label: t('table.header-column.amount'),
      width: 200,
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
  ];
};
