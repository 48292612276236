import * as React from 'react';
function SvgSalesPaymentMenu(props) {
  return (
    <svg width={136} height={80} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#sales-payment-menu_svg__filter0_d_5586_192465)'>
        <path
          d='M119.057 5.213c1.246 0 2.501.485 3.455 1.457a5.044 5.044 0 011.425 3.524l-.001 57.825a5.07 5.07 0 01-1.424 3.528A4.843 4.843 0 01119.057 73H29.133a4.846 4.846 0 01-3.455-1.453 5.064 5.064 0 01-1.428-3.528l.002-57.823a5.042 5.042 0 011.427-3.529 4.842 4.842 0 013.452-1.454h89.926z'
          fill='#CEE2F2'
        />
        <path
          d='M118.498 5H72.276v67.841h46.222c3.177 0 5.752-2.41 5.752-5.384V10.384C124.249 7.411 121.674 5 118.498 5z'
          fill='#BCDBF4'
        />
        <path
          d='M52.248 57.963H36.665v2.182h15.583v-2.182zM72.01 57.963H56.423v2.182H72.01v-2.182zM91.763 57.963H76.178v2.182h15.585v-2.182zM111.523 57.963H95.941v2.182h15.582v-2.182z'
          fill='#03628A'
        />
        <path d='M111.518 39.087H36.665v8.728h74.853v-8.728z' fill='#fff' />
        <path d='M111.529 39.106H72.276v8.727h39.253v-8.727z' fill='#F4EFEF' />
        <path
          d='M77.795 42.627h-4.278v1.641h4.278v-1.64zM93.032 42.627H80.197v1.641h12.835v-1.64zM108.272 42.627H95.437v1.641h12.835v-1.64zM123.937 17.544H24.25v15.51h99.687v-15.51z'
          fill='#03628A'
        />
        <path d='M123.887 17.545h-51.61v15.511h51.61v-15.51z' fill='#045A7E' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M118.521 5.43c1.231.005 2.176.246 2.785.493l.013-.006-.538 1.709a6.06 6.06 0 00-2.254-.463v2.85l.074.03c.043.017.089.035.134.05 2.131.777 3.083 1.777 3.083 3.202 0 1.548-1.172 2.751-3.291 3.14v1.283h-1.535V16.57h-.019c-1.315 0-2.649-.296-3.271-.604l.499-1.746c.673.327 1.735.592 2.785.605V11.66c-1.924-.703-3.116-1.709-3.116-3.085 0-1.45 1.14-2.628 3.116-3.01V4.282h1.535v1.147zM128.25 11c0-5.521-4.703-10-10.5-10s-10.5 4.479-10.5 10 4.703 10 10.5 10 10.5-4.479 10.5-10zm-1.878 0c0-4.534-3.861-8.21-8.622-8.21-4.761 0-8.621 3.676-8.621 8.21 0 4.534 3.86 8.211 8.621 8.211 4.761 0 8.622-3.677 8.622-8.21zm-7.851 1.345c.486.32.712.666.712 1.129 0 .456-.239.832-.712 1.073v-2.202zm-2.073-3.98c0 .396.169.68.538.939V7.496c-.363.228-.538.549-.538.87z'
          fill='#039855'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M117.75 2.79c4.761 0 8.622 3.676 8.622 8.21 0 4.534-3.861 8.211-8.622 8.211-4.761 0-8.621-3.677-8.621-8.211 0-4.534 3.86-8.21 8.621-8.21zm.771 2.64c1.231.005 2.176.246 2.785.493l.013-.006-.538 1.709a6.06 6.06 0 00-2.254-.463v2.85l.074.03c.043.017.089.035.134.05 2.131.777 3.083 1.777 3.083 3.202 0 1.548-1.172 2.751-3.291 3.14v1.283h-1.535V16.57h-.019c-1.315 0-2.649-.296-3.271-.604l.499-1.746c.673.327 1.735.592 2.785.605V11.66c-1.924-.703-3.116-1.709-3.116-3.085 0-1.45 1.14-2.628 3.116-3.01V4.282h1.535v1.147z'
          fill='#FEC84B'
        />
        <path
          d='M116.986 9.304c-.369-.26-.538-.543-.538-.938 0-.321.175-.642.538-.87v1.808zM118.521 12.345c.486.32.712.666.712 1.129 0 .456-.239.832-.712 1.073v-2.202z'
          fill='#FEC84B'
        />
        <path
          d='M22.133 64.423h2.4v7.71c0 .479-.537.867-1.2.867-.663 0-1.2-.388-1.2-.867v-7.71z'
          fill='#FFC61B'
        />
        <path
          d='M23.253 64.423v8.571c.027.001.053.006.08.006.663 0 1.2-.388 1.2-.867v-7.71h-1.28zM24.456 60.188l2.286-.529 5.423 12.21c.202.456-.146.943-.777 1.09-.631.145-1.307-.105-1.51-.561l-5.422-12.21z'
          fill='#EAA22F'
        />
        <path
          d='M22.21 60.188l-2.286-.529-5.422 12.21c-.203.456.145.943.776 1.09.631.145 1.307-.105 1.51-.561l5.421-12.21z'
          fill='#FFC61B'
        />
        <path d='M43.136 42.028H3.53V63.86h39.606V42.028z' fill='#fff' />
        <path d='M43.136 42.028H23.254V63.86h19.882V42.028z' fill='#D0D1D3' />
        <path
          d='M25.314 40.859h-3.961v-1.43c0-.789.887-1.429 1.98-1.429 1.094 0 1.98.64 1.98 1.43v1.429z'
          fill='#FFC61B'
        />
        <path
          d='M25.314 39.43c0-.79-.887-1.43-1.98-1.43-.027 0-.054.002-.08.003v2.856h2.06v-1.43z'
          fill='#EAA22F'
        />
        <path
          d='M43.576 43.184H3.09c-1.016 0-1.84-.595-1.84-1.329 0-.733.824-1.328 1.84-1.328h40.486c1.017 0 1.84.594 1.84 1.328 0 .734-.824 1.329-1.84 1.329zM43.576 65.073H3.09c-1.016 0-1.84-.595-1.84-1.328 0-.734.824-1.329 1.84-1.329h40.486c1.017 0 1.84.595 1.84 1.329 0 .733-.824 1.328-1.84 1.328z'
          fill='#71E2EF'
        />
        <path
          d='M43.576 40.527H23.253v2.657h20.323c1.017 0 1.84-.595 1.84-1.329s-.823-1.328-1.84-1.328zM43.576 62.416H23.253v2.657h20.323c1.017 0 1.84-.595 1.84-1.328 0-.734-.823-1.329-1.84-1.329z'
          fill='#38C6D9'
        />
        <path
          d='M36.424 49.44l-10.17 5.673-9.947-2.394L8 57.304l.73.688 7.8-4.306 9.952 2.395 10.676-5.955-.734-.686z'
          fill='#59595B'
        />
        <path
          d='M16.53 53.686l-7.8 4.306-.73-.688 8.307-4.585 6.947 1.672v.913l-6.723-1.618z'
          fill='#59595B'
        />
        <path
          d='M26.481 56.08l-3.228-.776v-.913l3.001.722 10.17-5.673.733.686-10.676 5.955z'
          fill='#272525'
        />
        <path
          d='M26.367 54.4c.916 0 1.658.536 1.658 1.197s-.742 1.197-1.658 1.197c-.916 0-1.658-.536-1.658-1.197s.742-1.197 1.658-1.197z'
          fill='#C92F00'
        />
        <path
          d='M16.418 52.005c.916 0 1.658.536 1.658 1.197 0 .662-.742 1.197-1.658 1.197-.916 0-1.658-.535-1.658-1.197 0-.66.742-1.197 1.658-1.197z'
          fill='#FF7F4F'
        />
        <path
          d='M36.79 48.585c.915 0 1.658.536 1.658 1.197s-.743 1.197-1.659 1.197c-.915 0-1.658-.536-1.658-1.197 0-.66.743-1.197 1.658-1.197z'
          fill='#EAA22F'
        />
        <path
          d='M8.365 56.452c.916 0 1.658.535 1.658 1.196 0 .661-.742 1.197-1.658 1.197-.916 0-1.658-.535-1.658-1.197 0-.66.742-1.197 1.658-1.197z'
          fill='#FF314F'
        />
      </g>
      <defs>
        <filter
          id='sales-payment-menu_svg__filter0_d_5586_192465'
          x={0.25}
          y={0}
          width={135}
          height={80}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_192465' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_192465' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgSalesPaymentMenu;
