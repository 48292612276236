// initial search transaction data
export const initialSearchExpense = () => {
  return {
    from_date: null,
    to_date: null,
    transaction_no: '',
    contact_name: '',
    contact_name_container: null,
  };
};
