import {
  DEFAULT_FILTER_PRODUCT_STOCK_ALMOST_OUT_STOCK,
  DEFAULT_FILTER_PRODUCT_STOCK_OUT_STOCK,
  DEFAULT_FILTER_PRODUCT_STOCK_STOCK_AVAILABLE,
} from '../../default/product-stock-filter.default';

/**
 *
 * @param { function } t translation function
 * @returns
 *      pair label, value, colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 */
export function productStockFilter(t) {
  return [
    {
      label: t('label.product-stock-filter.stock-available'),
      value: DEFAULT_FILTER_PRODUCT_STOCK_STOCK_AVAILABLE,
    },
    {
      label: t('label.product-stock-filter.almost-out-stock'),
      value: DEFAULT_FILTER_PRODUCT_STOCK_ALMOST_OUT_STOCK,
    },
    {
      label: t('label.product-stock-filter.out-stock'),
      value: DEFAULT_FILTER_PRODUCT_STOCK_OUT_STOCK,
    },
  ];
}
