import axios from 'axios';

import {
  REACT_APP_API_URL_ACCOUNT,
  REACT_APP_API_URL_ACCOUNT_CATEGORY,
  REACT_APP_API_URL_ACCOUNT_DETAILS,
  REACT_APP_API_URL_ACCOUNT_TRANSACTION,
} from '../../../utils/configs/api.config';
import { allowKeynamesAccountDataUpdate } from '../../../utils/data/display/initial-account-data';
import {
  DEFAULT_ACCOUNT_CATEGORY_LIST_PARAMS,
  DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
  DEFAULT_ACCOUNT_DATA_LIST_PARAMS,
  DEFAULT_ACCOUNT_PARENT_LIST_PARAMS,
  DEFAULT_ACCOUNT_TRANSACTION_LIST_PARAMS,
} from '../../../utils/default/params.default';
import { authHeader } from '../../../utils/helpers/auth-header.helpers';
import formatHelp from '../../../utils/helpers/format.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import strHelp from '../../../utils/helpers/string.helpers';
import {
  creatorDetailServiceV2,
  creatorListService,
  creatorListServiceV2,
} from '../creator-services/creator-services.helper';

/**
 *
 * account services
 *
 */

const getAccountList = async (params) => {
  return await creatorListServiceV2({
    endpoint: REACT_APP_API_URL_ACCOUNT,
    paramsGetList: params,
    allowParams: DEFAULT_ACCOUNT_DATA_LIST_PARAMS,
    encodeValueParams: true,
    paramsFormatter: (params) =>
      objHelper.changeFormatValueV2(
        params,
        DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
        null,
        formatHelp.changeFormatAccountName,
        true,
      ),
  });
};

const getAccountDetails = async (paramsGetAccountDetails) => {
  const url = strHelp.templateString(REACT_APP_API_URL_ACCOUNT_DETAILS, paramsGetAccountDetails);

  return await creatorDetailServiceV2(url);
};

const addAccount = async (dataAddAccount) => {
  const { Authorization } = authHeader();

  if (!Authorization || !dataAddAccount) {
    return null;
  }

  return await axios({
    method: 'POST',
    url: REACT_APP_API_URL_ACCOUNT,
    headers: {
      Authorization,
    },
    data: {
      account: [...dataAddAccount],
    },
  });
};

const updateAccount = async (dataUpdateAccount) => {
  const { Authorization } = authHeader();
  const { account_id } = dataUpdateAccount;

  if (!Authorization || !account_id) return null;

  dataUpdateAccount = objHelper.filterKeyObj(dataUpdateAccount, [], allowKeynamesAccountDataUpdate);

  dataUpdateAccount = objHelper.filteringExistedValueWithAllowKeys(
    dataUpdateAccount,
    [],
    ['default_category_name'],
    true,
  );

  return await axios({
    method: 'PUT',
    url: `${REACT_APP_API_URL_ACCOUNT}/${account_id}`,
    headers: {
      Authorization,
    },
    data: {
      ...dataUpdateAccount,
    },
  });
};

const deleteAccountData = async ({ account_id }) => {
  const { Authorization } = authHeader();

  if (!Authorization || !account_id) {
    return null;
  }

  return await axios({
    method: 'DELETE',
    url: `${REACT_APP_API_URL_ACCOUNT}/${account_id}`,
    headers: {
      Authorization,
    },
  });
};

/**
 *
 * account category services
 *
 */

const accountCategoryList = async (params) => {
  return await creatorListServiceV2({
    endpoint: REACT_APP_API_URL_ACCOUNT_CATEGORY,
    paramsGetList: params,
    allowParams: DEFAULT_ACCOUNT_CATEGORY_LIST_PARAMS,
    paramsFormatter: (params) =>
      objHelper.changeFormatValueV2(
        params,
        DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
        null,
        formatHelp.changeFormatAccountName,
        true,
      ),
  });
};

const addAccountCategory = async (dataAddAccountCategory) => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  return await axios({
    method: 'POST',
    url: REACT_APP_API_URL_ACCOUNT_CATEGORY,
    data: {
      category: [...dataAddAccountCategory],
    },
    headers: {
      Authorization,
    },
  });
};

const updateAccountCategory = async (dataUpdateAccountCategory) => {
  const { Authorization } = authHeader();

  if (!Authorization || !dataUpdateAccountCategory) {
    return null;
  }

  const { account_category_id } = dataUpdateAccountCategory;

  // removing account category id in key of data update
  const filteringDataUpdate = objHelper.filterKeyObj(dataUpdateAccountCategory, [
    'account_category_id',
  ]);

  return await axios({
    method: 'PUT',
    url: `${REACT_APP_API_URL_ACCOUNT_CATEGORY}/${account_category_id}`,
    headers: {
      Authorization,
    },
    data: {
      ...filteringDataUpdate,
    },
  });
};

const deleteAccountCategory = async ({ account_category_id }) => {
  const { Authorization } = authHeader();

  if (!Authorization || !account_category_id) {
    return null;
  }

  return await axios({
    method: 'DELETE',
    url: `${REACT_APP_API_URL_ACCOUNT}/category/${account_category_id}`,
    headers: {
      Authorization,
    },
  });
};

const getListAccountParent = async (paramsGetListAccountParent) => {
  paramsGetListAccountParent = objHelper.changeFormatValueV2(
    paramsGetListAccountParent,
    DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
    null,
    formatHelp.changeFormatAccountName,
    true,
  );

  return await creatorListService(
    REACT_APP_API_URL_ACCOUNT,
    paramsGetListAccountParent,
    DEFAULT_ACCOUNT_PARENT_LIST_PARAMS,
  );
};

const getListAccountTransaction = async (paramsGetListAccountTransaction) => {
  return await creatorListServiceV2({
    endpoint: REACT_APP_API_URL_ACCOUNT_TRANSACTION,
    combineParams2endpoint: true,
    paramsGetList: paramsGetListAccountTransaction,
    allowParams: DEFAULT_ACCOUNT_TRANSACTION_LIST_PARAMS,
    useEncodeParamsSerializer: true,
  });
};

const accountServices = {
  getAccountList,
  getAccountDetails,
  addAccount,
  updateAccount,
  deleteAccountData,
  accountCategoryList,
  addAccountCategory,
  updateAccountCategory,
  deleteAccountCategory,
  getListAccountParent,
  getListAccountTransaction,
};

export default accountServices;
