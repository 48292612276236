import { createAsyncThunk } from '@reduxjs/toolkit';

import productServices from '../../services/API/product/product.service';
import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  PRODUCT_ASYNC_THUNK_TYPE,
  PRODUCT_BASE_ACTION_TYPE,
  PRODUCT_CATEGORY_ASYNC_THUNK_TYPE,
} from '../../utils/constants/action-type.constant';
import {
  LOCAL_STORAGE_PRODUCT_BY_SUPPLIER,
  LOCAL_STORAGE_PRODUCT_CATEGORY,
  LOCAL_STORAGE_PRODUCT_DATA,
  LOCAL_STORAGE_PRODUCT_DATA_ALTERNATIVE,
  LOCAL_STORAGE_PRODUCT_IMPORTING_PROGRESS,
  LOCAL_STORAGE_PRODUCT_PURCHASE_SALE,
} from '../../utils/constants/storage.constant';
import {
  SLICE_NAME_DATA_PRODUCT_BY_SUPPLIER,
  SLICE_NAME_DATA_PRODUCT_IMPORTING_PROGRESS,
  SLICE_NAME_DATA_PRODUCT_UNIT_LIST,
  SLICE_NAME_PRODUCT_CATEGORY_DATA,
  SLICE_NAME_PRODUCT_DATA,
  SLICE_NAME_PRODUCT_DETAILS,
  SLICE_NAME_PRODUCT_MUTATION_LIST,
  SLICE_NAME_TEMP_ADD_PRODUCT_DATA,
  SLICE_NAME_TEMP_IMPORT_PRODUCT_DATA,
  STATUS_REQUEST_LIST_PRODUCT_CATEGORY_FAILED,
  STATUS_REQUEST_LIST_PRODUCT_CATEGORY_PENDING,
  STATUS_REQUEST_LIST_PRODUCT_CATEGORY_SUCCESS,
  STATUS_REQUEST_LIST_PRODUCT_DATA_FAILED,
  STATUS_REQUEST_LIST_PRODUCT_DATA_PENDING,
  STATUS_REQUEST_LIST_PRODUCT_DATA_SUCCESS,
} from '../../utils/constants/store.constant';
import {
  DEFAULT_KEY_NAME_MODUL_ID_API_REF,
  DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
  DEFAULT_KEY_NAME_UNIT_ID_API_REF,
  PRODUCT_CATEGORY_ID_KEY_NAME_API_REF,
} from '../../utils/default/object-keyname.default';
import {
  DEFAULT_ACTION_PARAMS,
  DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
} from '../../utils/default/params.default';
import objHelper from '../../utils/helpers/object.helper';
import {
  creatorAddActionWithStoreDataOnSlice,
  creatorDetailAction,
  creatorListAction,
  creatorListActionDynamicSliceNameNproperties,
} from '../creator-action/creator-action.helper';
import messageHelper from '../message/message.helper';

/**
 * product actions
 *
 */

export const listProductData = createAsyncThunk(
  PRODUCT_ASYNC_THUNK_TYPE.list,
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_PRODUCT_DATA_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_PRODUCT_DATA_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_PRODUCT_DATA_FAILED,
      slicePayloadKeyname = SLICE_NAME_PRODUCT_DATA,
      localStorageName = LOCAL_STORAGE_PRODUCT_DATA,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...params
    },
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params,
      apiService: productServices.listProductData,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
      slicePayloadKeyname,
      errorMessage: 'error.list-data.product-data',
    });
  },
);

// get refresh data product with current search list product
export const refreshListProductData = createAsyncThunk(
  PRODUCT_ASYNC_THUNK_TYPE.listRefresh,
  async (dataIgnoreRefresh = [], thunkAPI) => {
    const currentProductData = LocalStorage.get(LOCAL_STORAGE_PRODUCT_DATA);

    // get filtering data from prev search
    let filterDataSearch = objHelper.filterKeyObj(currentProductData, dataIgnoreRefresh, [
      'per_page',
      'current_page',
      'search_product_name',
      'search_category_name',
      'search_warehouse_id',
    ]);

    // get data for next search
    const filterDataNextSearch = objHelper.filterKeyObj(filterDataSearch, [
      'per_page',
      'current_page',
    ]);

    // formalize data for search
    filterDataSearch = {
      ...filterDataSearch,
      page: filterDataSearch.current_page,
      paginate: filterDataSearch.per_page,
      product_name: filterDataSearch.search_product_name,
      category_name: filterDataSearch.search_category_name,
      warehouse_id: filterDataSearch.search_warehouse_id,
    };

    // only get value for existed data
    filterDataSearch = objHelper.filteringExistedValue(filterDataSearch);

    // removing unused key for search account data
    filterDataSearch = objHelper.filterKeyObj(filterDataSearch, [
      'current_page',
      'per_page',
      'search_product_name',
    ]);

    try {
      const response = await productServices.listProductData(filterDataSearch);

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      let { data: dataResponseAccount, sucess } = response.data;

      // adding search params when not sucess response
      if (!sucess || !Array.isArray(dataResponseAccount.data)) {
        dataResponseAccount = {
          ...dataResponseAccount,
          data: [],
        };
      }

      // adding for search cache
      const dataResAccountWithSearch = Object.assign(dataResponseAccount, {
        ...filterDataNextSearch,
      });

      LocalStorage.set(LOCAL_STORAGE_PRODUCT_DATA, dataResAccountWithSearch);

      return { productData: dataResAccountWithSearch };
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.product-data',
          'error.list-data.product-data',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.list-data.product-data',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// detail product
export const getProductDetails = createAsyncThunk(
  PRODUCT_ASYNC_THUNK_TYPE.detail,
  async (paramsGetDetailProduct, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      paramsGetDetailProduct,
      productServices.getProductDetails,
      '',
      SLICE_NAME_PRODUCT_DETAILS,
      'error.detail.product',
    );
  },
);

// add product data company
export const addProductData = createAsyncThunk(
  PRODUCT_ASYNC_THUNK_TYPE.add,
  async (bodyDataAddProduct, thunkAPI) => {
    const { product_name } = bodyDataAddProduct;

    return await creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      bodyDataAddProduct,
      productServices.addProductData,
      SLICE_NAME_TEMP_ADD_PRODUCT_DATA,
      'success.add.product-data',
      'error.add.product-data',
      {},
      { product_name },
    );
  },
);

// udpate product data company
export const updateProductData = createAsyncThunk(
  PRODUCT_ASYNC_THUNK_TYPE.update,
  async (dataUpdateProduct, thunkAPI) => {
    const { product_name } = dataUpdateProduct;

    try {
      const response = await productServices.updateProductData(dataUpdateProduct);

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.update.product-data',
          'success.update.product-data',
          {},
          { product_name },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.update.product-data',
          {},
          { product_name },
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.update.product-data',
          'error.update.product-data',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.update.product-data',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

export const deleteProductData = createAsyncThunk(
  PRODUCT_ASYNC_THUNK_TYPE.delete,
  async ({ product_id, product_name }, thunkAPI) => {
    try {
      const response = await productServices.deleteProductData({ product_id });

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response.data;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.delete.product-data',
          'success.delete.product-data',
          {},
          { product_name },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.delete.product-data',
          {},
          { product_name },
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.update.product-data',
          'error.update.product-data',
          false,
          {},
          { product_name },
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.update.product-data',
          false,
          {},
          { product_name },
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

/**
 * product category actions
 *
 */

export const listProductCategory = createAsyncThunk(
  PRODUCT_CATEGORY_ASYNC_THUNK_TYPE.list,
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_PRODUCT_CATEGORY_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_PRODUCT_CATEGORY_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_PRODUCT_CATEGORY_FAILED,
      slicePayloadKeyname = SLICE_NAME_PRODUCT_CATEGORY_DATA,
      localStorageName = LOCAL_STORAGE_PRODUCT_CATEGORY,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...params
    },
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params,
      apiService: productServices.listProductCategory,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: PRODUCT_CATEGORY_ID_KEY_NAME_API_REF,
      slicePayloadKeyname,
      errorMessage: 'error.list-data.product-category',
    });
  },
);

// refresh list product category
export const refreshProductCategoryList = createAsyncThunk(
  PRODUCT_CATEGORY_ASYNC_THUNK_TYPE.listRefresh,
  async (dataIgnoreRefresh = [], thunkAPI) => {
    const currentProductCategory = LocalStorage.get(LOCAL_STORAGE_PRODUCT_CATEGORY);

    // get filter data from search filter
    let filterDataSearch = objHelper.filterKeyObj(currentProductCategory, dataIgnoreRefresh, [
      'search_category_name',
      'per_page',
      'current_page',
    ]);

    // save data for next search
    const filterDataNextSearch = objHelper.filterKeyObj(filterDataSearch, [
      'per_page',
      'current_page',
    ]);

    // normalize data for search
    filterDataSearch = {
      ...filterDataSearch,
      page: filterDataSearch.current_page,
      paginate: filterDataSearch.per_page,
      category_name: filterDataSearch.search_category_name,
    };

    // removing unused key that not provide value
    filterDataSearch = objHelper.filteringExistedValue(filterDataSearch);

    filterDataSearch = objHelper.filterKeyObj(filterDataSearch, [
      'current_page',
      'search_category_name',
      'per_page',
    ]);

    try {
      const response = await productServices.listProductCategory(filterDataSearch);

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      let { data: dataResponseProductCategory, sucess } = response.data;

      if (!sucess || !Array.isArray(dataResponseProductCategory.data)) {
        dataResponseProductCategory = {
          ...dataResponseProductCategory,
          data: [],
        };
      }

      // save data filtering for next search
      const dataResProductCategoryWithSearch = Object.assign(dataResponseProductCategory, {
        ...filterDataNextSearch,
      });

      // cache data account category
      LocalStorage.set(LOCAL_STORAGE_PRODUCT_CATEGORY, dataResProductCategoryWithSearch);

      return { categoryData: dataResProductCategoryWithSearch };
    } catch (error) {
      console.log(error);
    }
  },
);

// add product category company
export const addProductCategory = createAsyncThunk(
  PRODUCT_CATEGORY_ASYNC_THUNK_TYPE.add,
  async (dataAddProductCategory, thunkAPI) => {
    const { category_name } = dataAddProductCategory;

    try {
      const response = await productServices.addProductCategory(dataAddProductCategory);

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.add.product-category',
          'success.add.product-category',
          {},
          { category_name },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.add.product-category',
          {},
          { category_name },
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data, status } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      let secondaryMessage = 'error.add.product-category';

      // geting message when data already exist
      if (status === 403) {
        secondaryMessage = 'error.add.product-category-existed-data';
      }

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.add.product-category',
          secondaryMessage,
          false,
          {},
          { category_name },
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          secondaryMessage,
          false,
          {},
          { category_name },
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// update product category company
export const updateProductCategory = createAsyncThunk(
  PRODUCT_CATEGORY_ASYNC_THUNK_TYPE.update,
  async (dataUpdateProductCategory, thunkAPI) => {
    const { category_name } = dataUpdateProductCategory;

    try {
      const response = await productServices.updateProductCategory(dataUpdateProductCategory);

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.update.product-category',
          'success.update.product-category',
          {},
          { category_name },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.update.product-category',
          {},
          { category_name },
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.update.product-category',
          'error.update.product-category',
          false,
          {},
          { category_name },
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.update.product-category',
          false,
          {},
          { category_name },
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// delete product category company
export const deleteProductCategory = createAsyncThunk(
  PRODUCT_CATEGORY_ASYNC_THUNK_TYPE.delete,
  async ({ product_category_id, category_name }, thunkAPI) => {
    try {
      const response = await productServices.deleteProductCategory({ product_category_id });

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      // get status response data
      const { sucess } = data;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!sucess) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.delete.product-category-not-found-data',
          false,
          {},
          { category_name },
        );

        return thunkAPI.rejectWithValue(data);
      }

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.delete.product-category',
          'success.delete.product-category',
          {},
          { category_name },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.delete.product-category',
          {},
          { category_name },
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.delete.product-category',
          'error.delete.product-category',
          false,
          { category_name },
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.delete.product-category',
          false,
          { category_name },
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// handling get list product in purchase/sale
export const getListProductPurchaseSale = createAsyncThunk(
  `${PRODUCT_BASE_ACTION_TYPE}/product-purchase-sale-list`,
  async (paramsGetListProductPurchaseSale, thunkAPI) => {
    return creatorListAction(
      thunkAPI,
      paramsGetListProductPurchaseSale,
      productServices.getlistProductPurchaseSale,
      LOCAL_STORAGE_PRODUCT_PURCHASE_SALE,
      'productPurchaseSaleData',
      'product_id',
      'error.list-data.product-purchase-sale',
    );
  },
);

// list all product that owned on supplier
export const getListProductBySupplier = createAsyncThunk(
  `${PRODUCT_BASE_ACTION_TYPE}/supplier-list`,
  async (paramsGetListProductBySupplier, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      paramsGetListProductBySupplier,
      productServices.getListProductBySupplier,
      LOCAL_STORAGE_PRODUCT_BY_SUPPLIER,
      SLICE_NAME_DATA_PRODUCT_BY_SUPPLIER,
      'product_id',
      'error.list-data.product-by-supplier',
      DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      DEFAULT_ACTION_PARAMS,
      'data',
      'data',
      'search_',
      false,
    );
  },
);

// get list product altefnative
export const getlistProductAlternative = createAsyncThunk(
  PRODUCT_ASYNC_THUNK_TYPE.listAlternative,
  async (paramsGetListProductData, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      paramsGetListProductData,
      productServices.listProductData,
      LOCAL_STORAGE_PRODUCT_DATA_ALTERNATIVE,
      'productDataAlternative',
      'product_id',
      'error.list-data.product-data',
    );
  },
);

// import product
export const importProduct = createAsyncThunk(
  `${PRODUCT_BASE_ACTION_TYPE}/data-import`,
  async (bodyImportProduct, thunkAPI) => {
    return await creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      bodyImportProduct,
      productServices.importProduct,
      SLICE_NAME_TEMP_IMPORT_PRODUCT_DATA,
      '',
      'error.add.import-product',
      {},
      {},
    );
  },
);

// progress importing product
export const progressImportingProduct = createAsyncThunk(
  `${PRODUCT_BASE_ACTION_TYPE}/data-import-progress`,
  async (paramsProgressImportProduct, thunkAPI) => {
    return creatorDetailAction(
      thunkAPI,
      paramsProgressImportProduct,
      productServices.progressImportingProduct,
      LOCAL_STORAGE_PRODUCT_IMPORTING_PROGRESS,
      SLICE_NAME_DATA_PRODUCT_IMPORTING_PROGRESS,
      'error.list-data.import-product-progress',
    );
  },
);

// product unit list
export const productUnitList = createAsyncThunk(
  `${PRODUCT_BASE_ACTION_TYPE}/product-unit-list`,
  async (paramsProductUnitList, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      {
        isShowMessage: false,
        ...paramsProductUnitList,
      },
      productServices.productUnitList,
      '',
      SLICE_NAME_DATA_PRODUCT_UNIT_LIST,
      DEFAULT_KEY_NAME_UNIT_ID_API_REF,
      '',
    );
  },
);

// action for getting product mutation list
// always not show message
export const getProductMutationList = createAsyncThunk(
  `${PRODUCT_BASE_ACTION_TYPE}/product-mutation-list`,
  async (params, thunkAPI) => {
    params = {
      ...params,
      isShowMessage: false,
    };

    return await creatorListAction(
      thunkAPI,
      params,
      productServices.getProductMutationList,
      '',
      SLICE_NAME_PRODUCT_MUTATION_LIST,
      DEFAULT_KEY_NAME_MODUL_ID_API_REF,
      '',
    );
  },
);
