import {
  FORMAT_MAPPING_TRANSLATION_COLUMN as FORMAT_MAPPING_TRANSLATION_COLUMN_TABLE_KEY_VALUE,
  FORMAT_PERIOD_DATE_COLUMN,
} from '../../../components/table/TableKeyValuePureMarkup/TableKeyValuePureMarkup';
import {
  FORMAT_COMMON_COLUMN,
  FORMAT_DATE_COLUMN,
  FORMAT_MAPPING_TRANSLATION_COLUMN,
} from '../../../components/table-row/TableRowPureMarkup/TableRowPureMarkup';
import { mappingMinutesHandoverStatus } from '../label-value/minutes-of-handover-status';
import { mappingPurchaseDeliveryStatus } from '../label-value/purchase-status';

// column purchase delivery list that handling table head with translation
export const columnsTableHeaderPurchaseDelivery = (t) => {
  return [
    {
      name: 'delivery_date',
      name_input: 'from_date',
      label: t('dashboard.purchase.purchase-delivery.table.header-column.transaction-date'),
      placeholder: t(
        'dashboard.purchase.purchase-delivery.input.search-transaction-date.placeholder',
      ),
      width: 200,
    },
    {
      name: 'purchase_delivery_no',
      name_input: 'purchase_delivery_no',
      label: t('dashboard.purchase.purchase-delivery.table.header-column.transaction-no'),
      placeholder: t(
        'dashboard.purchase.purchase-delivery.input.search-transaction-no.placeholder',
      ),
      width: 200,
    },
    {
      name: 'supplier_name',
      name_input: 'supplier_name',
      label: t('dashboard.purchase.purchase-delivery.table.header-column.supplier'),
      placeholder: t('dashboard.purchase.purchase-delivery.input.search-supplier.placeholder'),
      width: 400,
      align: 'left',
    },
    {
      name: 'status',
      name_input: 'status',
      label: t('table.header-column.invoice-status'),
      placeholder: t('dashboard.purchase.purchase-delivery.select.search-status.placeholder'),
      align: 'left',
      width: 250,
      maxWidth: 255,
    },
    {
      name: 'purchase_handover_status',
      name_input: 'purchase_handover_status',
      label: t('table.header-column.bast-uppercase'),
      placeholder: t('dashboard.purchase.purchase-delivery.select.search-status.placeholder'),
      align: 'left',
      width: 250,
      maxWidth: 255,
    },
  ];
};

export const columnsTableHeaderPurchaseDeliveryPDFprint = (t) => {
  return [
    {
      name: 'delivery_date',
      label: t('dashboard.purchase.purchase-delivery.table.header-column.transaction-date'),
      style: {
        width: 200,
        position: 'unset',
      },
      formatData: FORMAT_DATE_COLUMN,
    },
    {
      name: 'purchase_delivery_no',
      label: t('dashboard.purchase.purchase-delivery.table.header-column.transaction-no'),
      style: {
        width: 200,
        position: 'unset',
      },
      formatData: FORMAT_COMMON_COLUMN,
    },
    {
      name: 'supplier_name',
      label: t('dashboard.purchase.purchase-delivery.table.header-column.supplier'),
      style: {
        width: 400,
        position: 'unset',
      },
      formatData: FORMAT_COMMON_COLUMN,
    },
    {
      name: 'status',
      label: t('table.header-column.invoice-status'),
      style: {
        width: 150,
        maxWidth: 155,
        position: 'unset',
      },
      formatData: FORMAT_MAPPING_TRANSLATION_COLUMN,
      mappingTranslation: mappingPurchaseDeliveryStatus,
    },
    {
      name: 'purchase_handover_status',
      label: t('table.header-column.bast-uppercase'),
      style: {
        width: 150,
        maxWidth: 155,
        position: 'unset',
      },
      formatData: FORMAT_MAPPING_TRANSLATION_COLUMN,
      mappingTranslation: mappingMinutesHandoverStatus,
      substituteNotExistValue: t('label.minutes-handover-status.open'),
    },
  ];
};

// columns key value table
export const columnsTableKeyValuePrintIndexPurchaseDelivery = [
  {
    header: 'dashboard.purchase.purchase-delivery.table.header-column.transaction-date',
    valueKeyName: 'search_from_date',
    valueKeyNameSecondary: 'search_to_date',
    formatValue: FORMAT_PERIOD_DATE_COLUMN,
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'dashboard.purchase.purchase-delivery.table.header-column.transaction-no',
    valueKeyName: 'search_purchase_delivery_no',
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'dashboard.purchase.purchase-delivery.table.header-column.supplier',
    valueKeyName: 'search_supplier_name',
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'dashboard.purchase.purchase-delivery.table.header-column.status',
    valueKeyName: 'search_status',
    formatValue: FORMAT_MAPPING_TRANSLATION_COLUMN_TABLE_KEY_VALUE,
    mappingTranslation: mappingPurchaseDeliveryStatus,
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'table.header-column.bast-uppercase',
    valueKeyName: 'search_purchase_handover_status',
    formatValue: FORMAT_MAPPING_TRANSLATION_COLUMN_TABLE_KEY_VALUE,
    mappingTranslation: mappingMinutesHandoverStatus,
    showOnlyWhenExistedValue: true,
  },
];

export const columnsSearchWithDate = ['from_date', 'to_date'];

// column that representation for calculation
export const calculationColumns = ['amount'];
