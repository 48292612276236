import { cloneDeep, isEmpty, omit, pick } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF,
  DEFAULT_KEY_NAME_FILE_PROPERTIES,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_UUID_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import arrHelp from '../../helpers/array.helpers';
import objHelper from '../../helpers/object.helper';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import {
  allowKeysDataCreditNoteSalesPayablePayment,
  allowKeysDataSalesPayablePayment,
  creditNoteSalesPayablePaymentCalculationNumberKeyNames,
  initialDataCreditNoteSalesPayablePayment,
  initialDataSalesPayablePayment,
  initialValidationCreditNoteSalesPayablePayment,
  initialValidationSalesPayablePayment,
  plainCreditNoteSalesPayablePaymentValidationWithoutID,
  plainCreditNoteSalesPayablePaymentWithoutID,
} from './initial-data-sales-payable-payment';

/**
 *
 * @param { object } param0         object as params function
 *
 * @param { object }    amount                  amount data generate of sales payable payment
 * @param { object }    companyUserData         current company user data
 * @param { object }    dataKeyname             return keyname of data
 * @param { object }    validationKeyname       return keyname of validation
 *
 * @returns
 *      data and validation for sales payable payment
 */
export const generateDataNvalidationAddSalesPayablePayment = ({
  amount = 1,
  companyUserData,
  dataKeyname = 'salesPayablePaymentData',
  validationKeyname = 'salesPayablePaymentValidation',
}) => {
  const accountingPeriod = !isEmpty(companyUserData)
    ? companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF]
    : null;

  // overriding accounting period
  const wrapperInitialSalesPayablePaymentData = (id) => {
    return {
      ...initialDataSalesPayablePayment(id),
      accountingPeriod,
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
    };
  };

  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    wrapperInitialSalesPayablePaymentData,
    initialValidationSalesPayablePayment,
    dataKeyname,
    validationKeyname,
  );
};

/**
 *
 * @param { object } param0         object as params function
 *
 * @param { object }    amount                  amount data generate of sales payable payment
 * @param { object }    companyUserData         current company user data
 * @param { object }    dataKeyname             return keyname of data
 * @param { object }    validationKeyname       return keyname of validation
 *
 * @returns
 *      data and validation for sales payable payment
 */
export const generateDataNvalidationAddSalesPayablePaymentWithWrappingKeyname = ({
  amount = 1,
  companyUserData,
  dataKeyname = 'salesPayablePaymentData',
  validationKeyname = 'salesPayablePaymentValidation',
  isWrappingKeynameData = true,
  correspondWrappingKeyname = {
    account_payable: 'account_id',
    account_payable_container: 'account_id_container',
  },
}) => {
  const accountingPeriod = !isEmpty(companyUserData)
    ? companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF]
    : null;

  // overriding accounting period
  const wrapperInitialSalesPayablePaymentData = (id) => {
    return {
      ...initialDataSalesPayablePayment(id),
      accountingPeriod,
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
    };
  };

  let salesPayablePaymentDataNvalidation = objHelper.creatorGenerateDataNValidationV2(
    amount,
    wrapperInitialSalesPayablePaymentData,
    initialValidationSalesPayablePayment,
    dataKeyname,
    validationKeyname,
  );

  if (isWrappingKeynameData) {
    const salesPayablePaymentData = salesPayablePaymentDataNvalidation[dataKeyname].map(
      (currentData) => {
        return objHelper.changeKeyNameWithCorrespondKeyTarget({
          obj: currentData,
          correspondObj: correspondWrappingKeyname,
        });
      },
    );

    const salesPayablePaymentValidation = salesPayablePaymentDataNvalidation[validationKeyname].map(
      (currentValidation) => {
        return objHelper.changeKeyNameWithCorrespondKeyTarget({
          obj: currentValidation,
          correspondObj: correspondWrappingKeyname,
        });
      },
    );

    salesPayablePaymentDataNvalidation = {
      [dataKeyname]: salesPayablePaymentData,
      [validationKeyname]: salesPayablePaymentValidation,
    };
  }

  return salesPayablePaymentDataNvalidation;
};

/**
 *
 * @param { object } param0         object as params function
 *
 * @param { object }    amount                  amount data generate of credit note sales payable payment
 * @param { object }    dataKeyname             return keyname of data
 * @param { object }    validationKeyname       return keyname of validation
 *
 * @returns
 *      data and validation for credit note sales payable payment
 */
export const generateDataNvalidationAddCreditNoteSalesPayablePayment = ({
  amount = 0,
  dataKeyname = 'creditNoteSalesPayablePaymentData',
  validationKeyname = 'creditNoteSalesPayablePaymentValidation',
}) => {
  return objHelper.creatorGenerateDataNValidationV2(
    amount,
    initialDataCreditNoteSalesPayablePayment,
    initialValidationCreditNoteSalesPayablePayment,
    dataKeyname,
    validationKeyname,
  );
};

/**
 *
 * @param { object }    param0         object as params function
 *
 * @param   { array }     creditNoteList           result for getting credit note list
 * @param   { string }    dataKeyname             return keyname of data
 * @param   { string }    validationKeyname       return keyname of validation
 *
 * @returns
 *      data and validation for credit note sales payable payment
 */
export const generateDataNvalidationAddCreditNoteSalesPayablePaymentFromCreditNoteList = ({
  creditNoteList,
  dataKeyname = 'creditNoteSalesPayablePaymentData',
  validationKeyname = 'creditNoteSalesPayablePaymentValidation',
  fillAmountInputFull = true,
  defaultEmptyAmountInput = 0,
}) => {
  if (isEmpty(creditNoteList) || !Array.isArray(creditNoteList)) {
    return {
      [dataKeyname]: [],
      [validationKeyname]: [],
    };
  }

  const usagedID = arrHelp.generateArrWithFunc(creditNoteList.length, uuidv4);

  const creditNoteSalesPayablePaymentData = creditNoteList.map((currentCreditNote, index) => {
    currentCreditNote = objHelper.changeFormatValue(
      currentCreditNote,
      creditNoteSalesPayablePaymentCalculationNumberKeyNames,
      [(value) => Number(value) || ''],
    );

    const { amount_available } = currentCreditNote;

    const amountInput = fillAmountInputFull ? amount_available : defaultEmptyAmountInput;

    return {
      id: usagedID[index],
      ...plainCreditNoteSalesPayablePaymentWithoutID,
      ...currentCreditNote,
      amount_available: Number(amount_available) - Number(amountInput),
      amount_available_const: amount_available,
      amount_input: amountInput || '',
    };
  });

  const creditNoteSalesPayablePaymentValidation = usagedID.map((id) => {
    return {
      id,
      ...plainCreditNoteSalesPayablePaymentValidationWithoutID,
    };
  });

  return {
    [dataKeyname]: creditNoteSalesPayablePaymentData,
    [validationKeyname]: creditNoteSalesPayablePaymentValidation,
  };
};

/**
 *
 * @param { object } param0  object as function params
 *
 * @param   { object }  creditNoteDetails                               credit note details
 * @param   { object }  currentSalesPayablePaymentData                  current data of sales payable payment
 * @param   { object }  currentSalesPayablePaymentValidation            current validation of sales payable payment
 * @param   { object }  dataKeyname                                     return keyname of data
 * @param   { object }  validationKeyname                               return keyname of validation
 *
 * @returns
 */
export const generateDataNvalidationAddSalesPayablePaymentFromCreditNoteDetails = ({
  creditNoteDetails = {},
  companyUserData,
  currentSalesPayablePaymentData,
  currentSalesPayablePaymentValidation,
  dataKeyname = 'salesPayablePaymentData',
  validationKeyname = 'salesPayablePaymentValidation',
}) => {
  if (isEmpty(creditNoteDetails)) return creditNoteDetails;

  creditNoteDetails = objHelper.changeFormatValue(
    creditNoteDetails,
    Object.keys(creditNoteDetails),
    [(value) => value || ''],
  );

  const { customer_id, customer_name } = creditNoteDetails;

  const minDateInput = getAccountingPeriodCompareWithDocumentTransactionDate({
    companyUserData,
    documentData: creditNoteDetails,
  });

  currentSalesPayablePaymentData = currentSalesPayablePaymentData.map((salesPayablePaymentData) => {
    return {
      ...salesPayablePaymentData,
      ...omit(cloneDeep(creditNoteDetails), ['transaction_date', 'transaction_no', 'description']),
      customer_id_container: {
        contact_id: customer_id,
        contact_name: customer_name,
      },
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]: new Date(minDateInput),
    };
  });

  return {
    [dataKeyname]: currentSalesPayablePaymentData,
    [validationKeyname]: currentSalesPayablePaymentValidation,
  };
};

/**
 *
 * @param { array } salesPayablePaymentData                 result input sales payable payment data
 * @param { array } creditNoteSalesPayablePaymentData       result user input credit note sales payable payment data
 * @returns
 */
export const generalizeDataAddSalesPayablePayment = (
  salesPayablePaymentData,
  creditNoteSalesPayablePaymentData,
  isWrappingKeynameData = false,
  correspondWrappingKeyname = {
    account_id: 'account_payable',
    account_id_container: 'account_payable_container',
  },
) => {
  if (
    !Array.isArray(salesPayablePaymentData) ||
    !Array.isArray(creditNoteSalesPayablePaymentData)
  ) {
    return {
      credit_note: creditNoteSalesPayablePaymentData,
      ...salesPayablePaymentData,
    };
  }

  salesPayablePaymentData = salesPayablePaymentData.map((singleSalesPayablePayment) => {
    if (isWrappingKeynameData) {
      singleSalesPayablePayment = objHelper.changeKeyNameWithCorrespondKeyTarget({
        obj: singleSalesPayablePayment,
        correspondObj: correspondWrappingKeyname,
      });
    }

    const { customer_id_container, transaction_date, attachment } = singleSalesPayablePayment;

    const uuidAttachment =
      Array.isArray(attachment) && attachment.length
        ? attachment[0][DEFAULT_KEY_NAME_FILE_PROPERTIES][DEFAULT_KEY_NAME_UUID_API_REF] || ''
        : '';

    let customer_name = '';
    if (customer_id_container) {
      const { contact_name, customer_name: customer_name_gen } = customer_id_container;

      customer_name = contact_name || customer_name_gen;
    }

    return objHelper.filteringExistedValue(
      pick(
        {
          ...singleSalesPayablePayment,
          customer_name,
          transaction_date: transaction_date ? moment(transaction_date).toISOString(true) : '',
          [DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF]: uuidAttachment,
        },
        allowKeysDataSalesPayablePayment,
      ),
    );
  });

  creditNoteSalesPayablePaymentData = creditNoteSalesPayablePaymentData.reduce(
    (resultCreditNote, singleCreditNote) => {
      const { amount_input } = singleCreditNote;
      if (!Number(amount_input)) return resultCreditNote;

      return resultCreditNote.concat(
        pick(
          {
            ...singleCreditNote,
            amount: Number(amount_input),
          },
          allowKeysDataCreditNoteSalesPayablePayment,
        ),
      );
    },
    [],
  );

  return {
    credit_note: creditNoteSalesPayablePaymentData,
    ...salesPayablePaymentData[0],
  };
};
