import { cloneDeep, isEmpty, isEqual, pick } from 'lodash';

import formatHelp from './format.helpers';
import objHelper from './object.helper';

function checkLength(arr) {
  return arr.length;
}

function isEmptyArr(arr) {
  return checkLength(arr) ? false : true;
}

function getFirstArr(arr) {
  if (!Array.isArray(arr)) {
    return arr;
  }

  return arr[0];
}

// this is for find active value in autocomplete component
// suppose arrValue is [{ value: , label: }]
function findLabelAutoComplete(arrValue, findValue, defaultValue = null, paramObj = 'label') {
  if (isEmptyArr(arrValue) || !findValue) {
    return defaultValue;
  }

  const findValueInArr = arrValue.find((objValue) => objValue.value === findValue);

  if (!findValueInArr) {
    return findValue;
  }

  return findValueInArr[paramObj] || defaultValue;
}

// search value in key of mapping object
// such [{id: 1}, {id: 2}] => (arr, 'id', 2) => return 1
function getIndexSearchDataInKey(arr, key, searchValue) {
  if (!Array.isArray(arr)) {
    return arr;
  }

  return arr.map((o) => o[key]).indexOf(searchValue);
}

// return only array with value
// suppose [{ id: 1 }, { id: '' }] return [1]
function mapWithExistedValue(arr, key) {
  if (!Array.isArray(arr) || isEmpty(arr)) return arr;

  return arr.map((a) => a[key]).filter((a) => a);
}

/**
 *
 * @param { array }     arr             data array that contain minimum single object that contain name key keyObj
 * @param { string }    keyObj          name key object on array
 * @param { bool }      isUseFilter     determine is using filtering that means get only existed value or not
 *      @default false
 *
 * @returns
 *      get value on keyObj that contained by array
 *
 * @example
 *      arr: [{name: 'hame'}, {name: 'hayu', id: 1}]
 *      keyObj: 'name'
 *
 *      return
 *          ['hame', 'hayu']
 */
function mapObjKeyOnArr(arr, keyObj, isUseFilter = false) {
  if (!Array.isArray(arr)) return arr;

  if (isUseFilter) return mapWithExistedValue(arr, keyObj);

  return arr.map((a) => a[keyObj]);
}

/**
 *
 * @param { object } param0         object as function params
 * @param   { array }   arr         where array target
 * @param   { keynames }    arr     keynames for mapping target
 *
 * @returns
 *
 *      {
 *          [keyname]: [array result for keyname],
 *      }
 *
 */
function mapMultipleObjKeynamesOnArr({ arr, keynames = [] }) {
  if (!Array.isArray(arr) || !Array.isArray(arr)) return arr;

  return keynames.reduce((result, currentKeyname) => {
    return {
      ...result,
      [currentKeyname]: arr.map((item) => item[currentKeyname]),
    };
  }, {});
}

// return only same value that handling in object key with match value
function filterObjKeyWithMatch(arr, objKey, matchValue) {
  if (typeof arr !== 'object') {
    return arr;
  }

  return arr.filter((a) => a[objKey] === matchValue);
}

/**
 *
 * @param { array }     arr        array that contain object, in object minimum contain name objKey
 * @param { string }    objKey     name key of object that for getting data
 * @param { bool }      isUseFilter determine what you want is data that existed
 *      @default    true
 *
 * @example
 *      input
 *          arr: [{ keyObj: [{}] }, { keyObj: [{}] }]
 *          objKey: 'objKey'
 *
 *      output
 *          arr: [{ valueThatFromObjKey }, { valueThatFromObjKey }]
 *
 * @returns
 *      all children object of array on key selected object
 *
 */
function mappingChildrenKeyOnObject(arr, objKey, isUseFilter = true) {
  if (!arr || !Array.isArray(arr)) return [];

  const mapExistedValue = mapObjKeyOnArr(arr, objKey, isUseFilter);

  return mapExistedValue.reduce((allValues, currValue) => {
    return allValues.concat(currValue);
  }, []);
}

// checking value is last index on arr
function isLastValue(arr, value) {
  if (typeof arr !== 'object') {
    return arr;
  }

  return arr.indexOf(value) === arr.length - 1;
}

// get value from last index array
// for bug fix
// example arr = [1, 2 , 3], index = 1, will return 3
function getValueFromLast(arr, index = 1) {
  if (typeof arr !== 'object') {
    return arr;
  }

  if (!index) index = 1;

  const length = checkLength(arr);

  return arr[length - index];
}

/**
 *
 * @param { array }             arr                 target array
 * @param { number | string }   indexFromBehind     index for getting array from behind
 */
function getValueFromLastV2(arr, indexFromBehind) {
  if (!Array.isArray(arr) || Number.isNaN(Number(indexFromBehind))) return null;

  return arr.reverse()[Number(indexFromBehind)];
}

// input array of number or string and output result sum
function sumValueArr(arr, fixedAmount = 2) {
  if (!Array.isArray(arr)) return 0;

  if (!arr.length) return 0;

  return arr.reduce((currentResultNumber, currentNumber) => {
    const resultSumNumber =
      formatHelp.reverseCurrencyFormatWithRegex(currentResultNumber) +
      formatHelp.reverseCurrencyFormatWithRegex(currentNumber);

    return formatHelp.currencyFormatWithRegex(resultSumNumber.toFixed(fixedAmount));
  });
}

/**
 *
 * @param { array } arr         contain number or string each items
 * @returns
 *      summed items on each items
 */
function sumValueArrV2(arr) {
  if (!Array.isArray(arr) || !arr.length) return 0;

  return arr.reduce((currSumResult, currNumber) => {
    if (Number.isNaN(Number(currNumber))) return currSumResult;

    return Number(currSumResult) + Number(currNumber);
  });
}

/**
 * data structure on arr, [{}, {}] each array items contained by object
 *
 * @param { array }      arr                    selected array that for filtering by identity, minimum require per item on array is contain that identity
 * @param { string }     keyObj                 name key identity for make different with another object in item array
 * @param { * }          identifiedValue        value of identity that for get filtering
 * @param { number }     indexSelected          get index of result filtered selected object
 * @param { bool }       isAllFilter            is return data was filtered or only on indexSelected
 * @param { function }   compareFunc            function handling comparison about target value and object value
 *      callback function   (identifiedValue, object value each array items)
 *
 * @returns
 *      if result filtered contain one or more items array, return object that on indexSelected
 *      else if result filtered contain one or more items array and using isAllFilter, return all result filered array
 *      else when result filtered not contain one array, return null
 */
function filterObjDataWithID(
  arr,
  keyObj,
  identifiedValue,
  indexSelected = 0,
  isAllFilter = false,
  compareFunc = (targetValue, objectValue) => targetValue == objectValue,
) {
  if (!Array.isArray(arr)) return arr;

  const resultFilterArr = arr.filter((objData) => {
    let value = objData[keyObj];
    if (typeof value === 'string' && typeof identifiedValue === 'string') {
      return compareFunc(identifiedValue.toLowerCase(), value.toLowerCase());
    }

    return compareFunc(identifiedValue, value);
  });

  if (isEmptyArr(resultFilterArr)) return null;
  if (isAllFilter) return resultFilterArr;

  return resultFilterArr[indexSelected];
}

/**
 *
 * enhancement filtering object data with id(identity) on version 2
 * where usage object as params
 *
 */
function filterObjDataWithIDv2({
  arr,
  objKeyname,
  targetValue,
  indexSelected = 0,
  getAllResultFilter = false,
  compareFunc = (targetValue, objectValue) => {
    if (typeof objectValue !== 'string') return false;

    return objectValue.includes(targetValue);
  },
} = {}) {
  return filterObjDataWithID(
    arr,
    objKeyname,
    targetValue,
    indexSelected,
    getAllResultFilter,
    compareFunc,
  );
}

// combine data object in array
// for example [ { unixID: '1' }, { unixID: '2' }, { unixID: '3' } ]
// and change object that unixID equal 2, and not change the order of array
// identifiedValue is value like id or whatever for difference with other data object
// value changed is value that for replace selected data
/**
 *
 * @param {*} arr
 * @param {*} keyObj
 * @param {*} identifiedValue
 * @param {*} valueChangedObj
 * @param { number } indexArr       index array that changes
 * @returns
 */
function combineDataObjInArray(
  arr,
  keyObj,
  identifiedValue,
  valueChangedObj = {},
  indexArr = null,
) {
  if (!Array.isArray(arr) || !arr.length) return arr;

  if (indexArr !== null) {
    const changesObjInIndexArr = {
      ...arr[indexArr],
      ...valueChangedObj,
    };

    return [
      ...arr.slice(0, indexArr),
      changesObjInIndexArr,
      ...arr.slice(indexArr + 1, arr.length),
    ];
  }

  // get position index with identified value and key object that store identified value
  const indexChangeData = arr.findIndex((objData) => objData[keyObj] === identifiedValue);

  // get first selected data change
  const selectedDataToChange = filterObjDataWithID(arr, keyObj, identifiedValue);

  // renewing data that selected into change data
  const newSelectedDataToChange = {
    ...selectedDataToChange,
    ...valueChangedObj,
  };

  // array that not changed order data
  return [
    ...arr.slice(0, indexChangeData),
    newSelectedDataToChange,
    ...arr.slice(indexChangeData + 1, arr.length),
  ];
}

/**
 * @param { array } originArray where default all data object in item of array, that can overrided by combinatorArray
 * @param { array } combinatorArray where array combinator that can overide data originArray by keyIdentityObj
 * @param { string } keyIdentityObj get different each array item on object data
 * @param { bool } isAddingNotExistedCombinatorOnOriginArr determine adding not existed combinatorArray on behind originArray when not exist
 * @param { bool } isEachKeyFollowResult determine each key that returning on resultCombineArray following key of originArray or combinatorArray
 * @returns
 *      returning array, where from originArray that each item identity, overrided by combinatorArray when same of keyIdentityObj
 *             or originArray can adding items when item of combinatorArray not existed on them
 */
function combineMultipleDataObjectInArray(
  originArray,
  combinatorArray,
  keyIdentityObj,
  isAddingNotExistedCombinatorOnOriginArr = true,
  isEachKeyFollowResult = true,
) {
  if (!Array.isArray(originArray) || !Array.isArray(combinatorArray)) return originArray;

  const keysOriginObjInArr = Object.keys(originArray[0]);
  const keysCombinatorObjInArr = Object.keys(combinatorArray[0]);

  let resultCombineArr = originArray.map((originObj, index) => {
    const identityObj = originObj[keyIdentityObj];

    // checking, whether item of combinator array existed on origin array
    const filteringCombinatorArrayItemInOriginArr = filterObjDataWithID(
      combinatorArray,
      keyIdentityObj,
      identityObj,
    );

    if (!isEmpty(filteringCombinatorArrayItemInOriginArr)) {
      const resultCombineDataObjInArray = combineDataObjInArray(
        originArray,
        keyIdentityObj,
        identityObj,
        filteringCombinatorArrayItemInOriginArr,
      );

      if (isEachKeyFollowResult)
        return pick(resultCombineDataObjInArray[index], keysCombinatorObjInArr);

      return resultCombineDataObjInArray[index];
    }

    if (isEachKeyFollowResult) return pick(originObj, keysOriginObjInArr);

    return originObj;
  });

  if (!isAddingNotExistedCombinatorOnOriginArr) return resultCombineArr;

  // get values of items on each identity on origin and combinator array
  const keysIdentityObjInOriginArr = mapWithExistedValue(originArray, keyIdentityObj);
  const keysIdentityObjInCombinatorArr = mapWithExistedValue(combinatorArray, keyIdentityObj);

  // checking where item of combinator array not existed on origin array and combine them on behin origin array
  let combinatorArrayNotExistedOnOriginArray = keysIdentityObjInCombinatorArr.filter(
    (singleKeyIdentityObjCombinatorArr) =>
      !keysIdentityObjInOriginArr.includes(singleKeyIdentityObjCombinatorArr),
  );

  combinatorArrayNotExistedOnOriginArray = combinatorArrayNotExistedOnOriginArray.map(
    (singleKeyIdentityObjCombinatorArr) => {
      return filterObjDataWithID(
        combinatorArray,
        keyIdentityObj,
        singleKeyIdentityObjCombinatorArr,
      );
    },
  );

  return resultCombineArr.concat(combinatorArrayNotExistedOnOriginArray);
}

// compare two data returning is two data is same or not
// @param: initialData: that data only for one comparison data, type object { initialData }
// @param: comparisonData: that all data for comparing with initial data, type array [ { initialData }, ... ]
// @param: allowKeys, provide all keys that allowing in data
// @param: discardKeys, provide all keys that not provide for comparison
// @param: returnAllTrue, when u using returnAllTrue that each data must true all, and otherwise
function compareInDataObjWithFilterKey(
  initialData,
  comparisonData,
  allowKeys = [],
  discardKeys = [],
  returnAllTrue = true,
) {
  if (typeof initialData !== 'object' || !Array.isArray(comparisonData)) {
    return false;
  }

  let isDataEqual = comparisonData.map((singleComparisonData) => {
    const initialDataFiltered = objHelper.filterKeyObj(initialData, discardKeys, allowKeys);

    singleComparisonData = objHelper.filterKeyObj(singleComparisonData, discardKeys, allowKeys);

    return isEqual(initialDataFiltered, singleComparisonData);
  });

  if (returnAllTrue) {
    isDataEqual = isDataEqual.every((data) => data);
  } else {
    isDataEqual = isDataEqual.some((data) => data);
  }

  return isDataEqual;
}

/**
 *
 * same as above but initial data is on array, can more than one
 *
 * @param { array } initialData multiple initial data
 * @param { array } comparisonData comparison data to compare with initial data
 * @param { array } allowKeys allow keys that for filtering used keys on compare data
 * @param { array } discardKeys discard keys that for not used keys when compare data
 * @param { bool } returnAllTrue determine is return equal data with fully same or partially same
 * @returns
 *      is intialData and comparisonData identically with filtering on keys each initialData and comparisonData
 */
function compareInDataObjWithFilterKeyOnMultipleInitialData(
  initialData,
  comparisonData,
  allowKeys = [],
  discardKeys = [],
  returnAllTrue = true,
) {
  if (!Array.isArray(initialData)) {
    if (typeof initialData !== 'object') return;

    return compareInDataObjWithFilterKey(
      initialData,
      comparisonData,
      allowKeys,
      discardKeys,
      returnAllTrue,
    );
  }

  if (initialData.length !== comparisonData.length) return false;

  // compare each single initial data object and comparison data on array
  let isDataEqual = initialData.map((singleInitialData, index) => {
    const genComparisonData = [comparisonData[index]];

    return compareInDataObjWithFilterKey(
      singleInitialData,
      genComparisonData,
      allowKeys,
      discardKeys,
      returnAllTrue,
    );
  });

  if (returnAllTrue) {
    isDataEqual = isDataEqual.every((data) => data);
  } else {
    isDataEqual = isDataEqual.some((data) => data);
  }

  return isDataEqual;
}

// @param: arr, array with content is object
// @param: keyChanges, key on object for change value
// @param: funcChanges, function for change format value
// format value each data in array, each data is object
function changeFormatValueInObj(arr, keyChanges, funcChanges) {
  if (!Array.isArray(arr) || (typeof funcChanges !== 'function' && !Array.isArray(funcChanges))) {
    return arr;
  }

  arr = arr.map((aObj) => {
    return objHelper.changeFormatValue(aObj, keyChanges, funcChanges);
  });

  return arr;
}

/**
 *
 * @param { object } obj
 * @param { string } singleKeyFilter
 * @param { string || array } valuesFilter
 * @returns
 *      checking value on object is existed or not
 */
function currentObjValueExisted(obj, singleKeyFilter, valuesFilter) {
  if (typeof obj !== 'object' || typeof singleKeyFilter !== 'string') return obj;

  const currentData = obj[singleKeyFilter];

  let isValueExisted = true;

  if (typeof currentData === 'object') {
    isValueExisted = isEqual(currentData, valuesFilter);
  } else if (Array.isArray(valuesFilter) && typeof currentData === 'string') {
    isValueExisted = valuesFilter.includes(currentData);
  } else if (Array.isArray(currentData) && typeof valuesFilter === 'string') {
    isValueExisted = currentData.includes(valuesFilter);
  } else if (Array.isArray(valuesFilter) && typeof Array.isArray(currentData)) {
    isValueExisted = isEqual(currentData, valuesFilter);
  } else if (typeof valuesFilter === 'string' && typeof currentData === 'string') {
    isValueExisted = currentData === valuesFilter;
  } else {
    isValueExisted = false;
  }

  return isValueExisted;
}

/**
 *
 * @param {*} param0
 *  {
 *      arr                 { array }               include array that finding key on object, data structure is [{}, {}, ...]
 *      keysFilter          { array || string }     search on keys filtered for remove or keep
 *      valuesFilter        { array || string }     values on object children values keys, that filtered can keep or remove
 *      isValueOnlyInclude  { bool }                determine is index on array keep or remove, when keep use true and vice versa,
 *                                                  you can remove or keep on action when exist or not values on object that selected key
 *          @default true
 *  }
 * @returns
 *      filetered array that based on value existed or not
 *
 * @example
 *      input:
 *          { arr: [{hame: 'fnia'}, {hame: 'hayu'}], keysFilter: ['hame'], valuesFilter: ['hayu'], isValueOnlyInclude: true }
 *      output:
 *          [{hame: 'hayu'}]
 *
 *      input:
 *          { arr: [{hame: 'fnia'}, {hame: 'hayu'}], keysFilter: ['hame'], valuesFilter: ['hayu'], isValueOnlyInclude: false }
 *      output:
 *          [{hame: 'fnia'}]
 */
function filterObjKeyWithValueExisted({
  arr,
  keysFilter,
  valuesFilter,
  isValueOnlyInclude = true,
}) {
  if (
    !Array.isArray(arr) ||
    (!Array.isArray(keysFilter) && typeof keysFilter !== 'string') ||
    (!Array.isArray(valuesFilter) && typeof valuesFilter !== 'string')
  )
    return arr;

  return arr.reduce(function (allData, data) {
    let isDataExisted = false;

    if (Array.isArray(keysFilter)) {
      for (let singleKeyFilter of keysFilter) {
        isDataExisted = currentObjValueExisted(data, singleKeyFilter, valuesFilter);
      }
    } else if (typeof keysFilter === 'string') {
      isDataExisted = currentObjValueExisted(data, keysFilter, valuesFilter);
    }

    if (isDataExisted === isValueOnlyInclude) {
      return allData.concat(data);
    }

    return allData;
  }, []);
}

// @param: arr, array data with object data each index
// @param: keyParam: key param with unique for different with other value
function reduceUniqueInObj(arr, keyParam) {
  if (!Array.isArray(arr)) {
    return arr;
  }

  return arr.reduce(function (allData, data) {
    const allDataWithParams = allData.map((d) => d[keyParam]);
    const currentData = data[keyParam];

    if (!allDataWithParams.includes(currentData)) {
      return allData.concat(data);
    }

    return allData;
  }, []);
}

/**
 *
 * @param { array }     arr                 array target
 * @param { string }    keyname             keyname for object items
 * @param { array }     nonExistedValues    amount of non existed value for filtering
 * @returns
 */
function filteringExistedValue(arr, keyname = '', nonExistedValues = []) {
  if (!Array.isArray(arr)) return arr;

  return isEmpty(nonExistedValues) || !keyname
    ? arr.filter((a) => a)
    : arr.filter((a) => !nonExistedValues.includes(a[keyname]));
}

/**
 *
 * @param { array } arr array target for filtering data, each item array must be object
 * @param { array } allowKeys key that only picker for checking existed value
 * @param { array } discardKeys key that discarded when checking existed value
 * @param { array } customDiscardValue value that discard when checking value
 * @param { bool } isAllowBooleanValue determine is discard value bool or not
 * @returns
 *      array contains object that already filtered
 */
function filterExistedValueOnObjItem(
  arr,
  allowKeys = [],
  discardKeys,
  customDiscardValue = [],
  isAllowBooleanValue = false,
) {
  if (!Array.isArray(arr)) return arr;

  return arr.reduce((allArraysData, currData) => {
    const filteredCurrDataWithAllowKeys = pick(currData, allowKeys);
    const isExistedValueOnSingleItemArray = objHelper.filteringExistedValue(
      filteredCurrDataWithAllowKeys,
      discardKeys,
      isAllowBooleanValue,
      customDiscardValue,
    );

    if (!isExistedValueOnSingleItemArray) return allArraysData;

    return allArraysData.concat(currData);
  }, []);
}

/**
 *
 * @param { array } arr array target for filtering data, each item array must be object
 * @param { array } allowKeys key that only picker for checking existed value
 * @param { array } discardKeys key that discarded when checking existed value
 * @param { array } customDiscardValue value that discard when checking value
 * @param { bool } isAllowBooleanValue determine is discard value bool or not
 * @returns
 *      array contains object that already filtered
 *      enhanced above function
 *      additional feature, that filtering for only keys and key on filtered is must same on existed
 */
function filterExistedValueOnObjItemV2(
  arr,
  allowKeys = [],
  discardKeys,
  customDiscardValue = [],
  isAllowBooleanValue = false,
) {
  if (!Array.isArray(arr)) return arr;

  return arr.reduce((allArraysData, currData) => {
    const filteredCurrDataWithAllowKeys = pick(currData, allowKeys);
    const isExistedValueOnSingleItemArray = objHelper.filteringExistedValue(
      filteredCurrDataWithAllowKeys,
      discardKeys,
      isAllowBooleanValue,
      customDiscardValue,
    );

    if (!isEqual(isExistedValueOnSingleItemArray, filteredCurrDataWithAllowKeys))
      return allArraysData;

    return allArraysData.concat(currData);
  }, []);
}

/**
 *
 * @param { array } arr array with contain object data
 * @param { string } uniqueKey name key for different data with other object in array
 * @returns
 */
function filteringExistedObjValueWithUniqueKey(arr, uniqueKey) {
  if (!Array.isArray(arr)) {
    return arr;
  }

  return arr.filter((a) => a[uniqueKey]);
}

/**
 *
 * @param { number } amount, amount generate data
 * @param { function } funcGen, function for generate data
 *
 * generating data with amount number, and generate with function
 */
function generateArrWithFunc(amount, funcGen) {
  if (typeof amount !== 'number' && typeof amount !== 'string') {
    return Array(amount);
  }

  if (typeof funcGen !== 'function') {
    return;
  }

  return Array.from(Array(amount)).map(() => funcGen());
}

/**
 *
 * @param { object } param0 that contain
 *      {
 *          arr                 { array }   array data that for reduce length
 *          dataMaxLength       { int }     max data that can hold
 *          isFrom              { string }  item on array can hold from, option 'last' or 'begin'
 *       }
 * @returns
 *      reducing item that contained
 */
function simplifyItem({ arr, dataMaxLength = 10, isFrom = 'last' }) {
  if (!Array.isArray(arr) || isEmpty(arr)) return arr;

  if (isFrom === 'last') {
    const lengthArr = arr.length;

    if (lengthArr < dataMaxLength) return arr;

    return arr.splice(lengthArr - dataMaxLength, dataMaxLength);
  }

  return arr.slice(0, dataMaxLength);
}

/**
 *
 * @param { object } param0
 *       {
 *          arr                 { array }   array data that for filtering items
 *          itemsRemove         { any }     items can remove from array
 *          itemsSubtitue       { any }     items that subtitue from already removed items from array
 *       }
 * @returns
 */
function removeItemsAndSubtitue({ arr, itemsRemove, itemsSubtitue }) {
  if (!Array.isArray(arr)) return arr;

  arr = arr.filter((item) => {
    if (Array.isArray(itemsRemove) && (typeof item === 'number' || typeof item === 'string')) {
      return !itemsRemove.includes(item);
    }

    return !isEqual(item, itemsRemove);
  });

  if (!Array.isArray(arr)) return arr;

  return arr.concat(itemsSubtitue);
}

/**
 *
 * @param { object } param0        usage all item on below
 *
 * @param   { array }                  arr             array that usage for join other item
 * @param   { number }                 joinIndex       number of index, representation where 'itemJoin' can append on 'arr'
 * @param   { object | array }         itemJoin        new item for array on number of index
 *
 * @returns
 */
function joinAtIndex({ arr, joinIndex = 0, itemJoin }) {
  if (!Array.isArray(arr) || joinIndex === undefined || joinIndex < 0 || isEmpty(itemJoin))
    return arr;

  if (Array.isArray(itemJoin)) {
    return [
      ...cloneDeep(arr).splice(0, joinIndex + 1),
      ...itemJoin,
      ...cloneDeep(arr).splice(joinIndex + 1, arr.length),
    ];
  }

  return [
    ...cloneDeep(arr).splice(0, joinIndex + 1),
    itemJoin,
    ...cloneDeep(arr).splice(joinIndex + 1, arr.length),
  ];
}

/*
 * @param       { object }          param0
 * @param       { array | object }  arr                         target array that each item for changes from parent key name to children keyname
 * @param       { object }          passFunctionParams          params that for passing on function objHelper.inheritKeynameFromParentToChildren
 *                                                              look on objHelper
 * @returns
 *
 *      each items that inherit parent keyname to children keyname
 */
function changesMultipleItemsWithInheritKeynameFromParentToChildren({
  arr = [],
  passFunctionParams = {},
}) {
  if (!Array.isArray(arr) || typeof arr !== 'object') return arr;

  if (Array.isArray(arr)) {
    return arr.map((arrItem) => {
      return objHelper.inheritKeynameFromParentToChildren({
        obj: arrItem,
        ...passFunctionParams,
      });
    });
  }

  return objHelper.inheritKeynameFromParentToChildren({
    obj: arr,
    ...passFunctionParams,
  });
}

/**
 * usage for get array in depth unlimited
 *
 * @param { array }         items       array target
 * @param { string }        key         sometimes getting deep of keyname of object that contained array
 * @returns
 */
const flattenItems = (items, key) => {
  if (!Array.isArray(items)) return items;

  return items.reduce((flattenedItems, item) => {
    flattenedItems.push(item);
    if (Array.isArray(item[key])) {
      flattenedItems = flattenedItems.concat(flattenItems(item[key], key));
    }
    return flattenedItems;
  }, []);
};

/**
 * usage for check is not empty array
 *
 * @param {*} arr
 * @returns
 */
function isNotEmptyArray(arr) {
  return !isEmpty(arr) && Array.isArray(arr);
}

/**
 *
 * @param { object } param0     object as function params
 *
 *
 * @param   { array }    arr        grouping array where data structure is each items is object on array
 * @param   { string }   keyname    name key where can holding each group
 *
 * @returns
 *
 * object that contained array where same on value keyname
 *
 */
function groupingItemsByObjectKeyname({ arr = [], keyname = '' }) {
  if (isEmpty(arr) || !Array.isArray(arr) || !keyname) return arr;

  return arr.reduce((res, currentArr) => {
    if (!currentArr) return res;

    const keyTarget = currentArr[keyname];
    const oldResTarget = res[keyTarget] || [];

    return {
      ...res,
      [keyTarget]: [...oldResTarget, currentArr],
    };
  }, {});
}

/**
 *
 * @param { object } param0         params by object
 *
 *
 * @param { array } arr             arr target
 * @param { array } indexes         remove index target
 *
 *
 * @returns
 */
function removeItemsByIndex({ arr = [], indexes = [] }) {
  if (!Array.isArray(arr)) return arr;

  return arr.reduce((res, currItem, index) => {
    if (indexes.includes(index)) return res;

    return res.concat(currItem);
  }, []);
}

/**
 *
 * comparing date on array and getting latest one
 *
 *
 * @param { object } param0         object as function params
 *
 * @param   { array }       arr             array target
 * @param   { string }      dateKeyname     keyname object of date
 * @param   { boolean }     isObjectItems   determine items object or not
 * @param   { number }      indexTarget     target getting index
 *
 * @returns
 */
function getLatestDate({ arr = [], dateKeyname = '', isObjectItems = true, indexTarget = 0 }) {
  if (isEmpty(arr) || !dateKeyname) return null;

  const res = [...arr].sort((a, b) => {
    const currentDate = isObjectItems ? a[dateKeyname] : a;
    const nextDate = isObjectItems ? b[dateKeyname] : b;

    return new Date(nextDate) - new Date(currentDate);
  })[indexTarget];

  return isObjectItems && !isEmpty(res) ? res[dateKeyname] : res;
}

/**
 *
 * @param       { object } param0         object as params function
 *
 * @param       { array }   arr           target array
 *      contained by boolean value type, when not bool, automatically converted
 *
 * @param       { array }   substituteFalseItems        substitute value when false item value
 * @param       { array }   substituteTrueItems         substitute value when true item value
 *
 * @returns
 */
function substituteBooleanItem({ arr = [], substituteFalseItems = [], substituteTrueItems = [] }) {
  if (isEmpty(arr) || !Array.isArray(arr)) return arr;

  return arr.reduce((res, val, index) => {
    if (typeof val !== 'boolean') val = Boolean(val);

    const selectedSubstituteItems = val ? substituteTrueItems : substituteFalseItems;

    res = selectedSubstituteItems[index] ? res.concat(selectedSubstituteItems[index]) : res;

    return res;
  }, []);
}

/**
 *
 * @param { object } param0
 *
 * @param   { array }   arr         target filtering array
 * @param   { array }   objProps    property of object that usage for keyname filtering and value filtering
 *
 * @returns
 *      filtering each key and value of objProps
 *      where filtering based on index number of keys objProps
 *
 */
function filterMultipleObjWithObjProps({ arr = [], objProps = {} } = {}) {
  if (!Array.isArray(arr) || isEmpty(objProps)) return arr;

  return Object.keys(objProps).reduce((res, currentKeyname) => {
    return filterObjDataWithIDv2({
      arr: res,
      objKeyname: currentKeyname,
      targetValue: objProps[currentKeyname],
      getAllResultFilter: true,
    });
  }, arr);
}

const arrHelp = {
  checkLength,
  isEmptyArr,
  getFirstArr,
  findLabelAutoComplete,
  getIndexSearchDataInKey,
  isLastValue,
  mapWithExistedValue,
  mapObjKeyOnArr,
  mapMultipleObjKeynamesOnArr,
  mappingChildrenKeyOnObject,
  filterObjKeyWithMatch,
  getValueFromLast,
  getValueFromLastV2,
  sumValueArr,
  sumValueArrV2,
  filterObjDataWithID,
  filterObjDataWithIDv2,
  combineDataObjInArray,
  combineMultipleDataObjectInArray,
  compareInDataObjWithFilterKey,
  compareInDataObjWithFilterKeyOnMultipleInitialData,
  changeFormatValueInObj,
  currentObjValueExisted,
  filterObjKeyWithValueExisted,
  reduceUniqueInObj,
  filteringExistedValue,
  filterExistedValueOnObjItem,
  filterExistedValueOnObjItemV2,
  filteringExistedObjValueWithUniqueKey,
  generateArrWithFunc,
  simplifyItem,
  removeItemsAndSubtitue,
  joinAtIndex,
  changesMultipleItemsWithInheritKeynameFromParentToChildren,
  flattenItems,
  isNotEmptyArray,
  groupingItemsByObjectKeyname,
  removeItemsByIndex,
  getLatestDate,
  substituteBooleanItem,
  filterMultipleObjWithObjProps,
};

export default arrHelp;
