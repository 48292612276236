export const initialBillingCheckoutData = {
  package_id: null,
  package_name: null,
  company_id: '',
  quantity: '',
  add_on: [],
  additional_user: 0,
  amount_package: 1,
  period_month: 1,
  package_quantity: 1,
  minimumAdditionalUser: 0,
  minimumPeriodMonth: 1,
  minimumAmountPackage: 1,
};

export const initialPriceBillingCheckoutData = {
  additionalUserPackagePrice: 0,
  additionalMonthPackagePrice: 0,
  variantTotalPrice: 0,
  total: 0,
};

export const allowedBodyBillingCheckoutKeyname = ['package_id', 'company_id', 'quantity', 'add_on'];

export const allowedAddOnsBodyKeyname = ['package_id', 'quantity'];

export const correlationMasterPackageId2packageId = {
  master_package_id: 'package_id',
};
