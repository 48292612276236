import * as React from 'react';
function SvgIconMenuDataProduct(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.857 68.143a.429.429 0 11-.857 0 .429.429 0 01.857 0zM6.857 67.714h58.286a.428.428 0 110 .857H6.857a.428.428 0 110-.857zM2.571 67.714h2.143a.428.428 0 110 .857H2.571a.428.428 0 110-.857zM69.429 67.714h-2.143a.428.428 0 100 .857h2.143a.428.428 0 100-.857zM72 68.143a.429.429 0 11-.857 0 .429.429 0 01.857 0zM38.941 42.724a.957.957 0 00.956-.956v-2.523a.957.957 0 00-.956-.956H33.06a.957.957 0 00-.956.956v2.523c0 .527.428.956.956.956h5.882zm-5.634-3.23h5.386v2.026h-5.386v-2.027zm8.482 16.25a.957.957 0 00-.956.956v2.523c0 .527.43.956.956.956h5.883a.957.957 0 00.956-.956V56.7a.957.957 0 00-.956-.956H41.79zm5.635 3.231h-5.386v-2.027h5.386v2.027zm-17.214-3.23h-5.882a.957.957 0 00-.956.955v2.523c0 .527.429.956.956.956h5.882a.957.957 0 00.956-.956V56.7a.957.957 0 00-.956-.956zm-.248 3.23h-5.386v-2.027h5.386v2.027z'
        fill='#000'
      />
      <path
        d='M55.673 61.91h-1.61v-2.333a.602.602 0 00-1.204 0v2.333H36.602V45.659h5.442v4.033c0 .34.174.65.464.827.29.177.645.19.948.035l1.275-.653 1.275.653a.97.97 0 001.412-.862V45.66h5.426c.008 0 .015.007.015.016v11.167a.602.602 0 001.205 0V45.675c0-.673-.547-1.22-1.22-1.22h-7.511V28.22A1.22 1.22 0 0044.113 27H27.888c-.673 0-1.22.547-1.22 1.22v3.017a.602.602 0 001.204 0V28.22c0-.009.007-.016.016-.016h5.426v4.033c0 .34.174.65.464.827a.967.967 0 00.948.035L36 32.446l1.275.653a.963.963 0 00.948-.035.963.963 0 00.464-.827v-4.033h5.427a.015.015 0 01.015.015v16.236H27.87V33.972a.602.602 0 00-1.204 0v10.483h-7.51c-.674 0-1.221.547-1.221 1.22V61.91h-1.609a.9.9 0 00-.898.898v1.748a.9.9 0 00.898.898h1.416v1.618c0 .59.48 1.07 1.072 1.07h1.751c.59 0 1.072-.48 1.072-1.07v-1.618h28.725v1.618c0 .59.48 1.07 1.07 1.07h1.752c.591 0 1.072-.48 1.072-1.07v-1.618h1.416a.9.9 0 00.898-.898v-1.748a.9.9 0 00-.898-.898zm-18.19-30.057l-1.04-.533a.972.972 0 00-.885 0l-1.04.533v-3.65h2.965v3.65zm5.765 13.806h2.965v3.649l-1.04-.533a.971.971 0 00-.884 0l-1.04.533v-3.649zm-17.461 0h2.965v3.649l-1.04-.533a.971.971 0 00-.885 0l-1.04.533v-3.649zm-6.647.016c0-.009.007-.016.016-.016h5.426v4.033c0 .34.174.65.464.827a.963.963 0 00.948.035l1.275-.653 1.275.653a.97.97 0 001.412-.862v-4.033h5.442v16.25H19.14V45.676zm1.293 21.264h-1.485v-1.485h1.485v1.485zm32.62 0h-1.486v-1.485h1.485v1.485zm2.314-2.689H16.633v-1.136h38.734v1.136z'
        fill='#000'
      />
    </svg>
  );
}
export default SvgIconMenuDataProduct;
