import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { BASE_TYPES_PURCHASE_ACTION_REGISTERED } from '../../utils/constants/base-types-action-registered.constant';
import {
  LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_LIST_PDF_PRINT,
  LOCAL_STORAGE_PURCHASE_DELIVERY,
  LOCAL_STORAGE_PURCHASE_DELIVERY_DETAIL,
  LOCAL_STORAGE_PURCHASE_DELIVERY_PDF_PRINT,
  LOCAL_STORAGE_PURCHASE_INVOICE,
  LOCAL_STORAGE_PURCHASE_INVOICE_PDF_PRINT,
  LOCAL_STORAGE_PURCHASE_ORDER,
  LOCAL_STORAGE_PURCHASE_ORDER_PDF_PRINT,
  LOCAL_STORAGE_PURCHASE_PAYMENT,
  LOCAL_STORAGE_PURCHASE_PAYMENT_PDF_PRINT,
  LOCAL_STORAGE_PURCHASE_RECEIVABLE_PAYMENT_LIST_PDF_PRINT,
  LOCAL_STORAGE_PURCHASE_RETURN_DETAILS,
  LOCAL_STORAGE_PURCHASE_RETURN_LIST,
  LOCAL_STORAGE_PURCHASE_RETURN_LIST_PDF_PRINT,
  LOCAL_STORAGE_QUOTATION_REQUEST,
  LOCAL_STORAGE_QUOTATION_REQUEST_DETAIL,
  LOCAL_STORAGE_QUOTATION_REQUEST_PRINT_PDF,
} from '../../utils/constants/storage.constant';
import {
  NAME_STATE_DETAIL_PURCHASE_PAYMENT,
  NAME_STORE,
  REQUEST_STATUS_CREATE_PURCHASE_RECEIVABLE_PAYMENT_FAILED,
  REQUEST_STATUS_CREATE_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
  REQUEST_STATUS_CREATE_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_CREATE_PURCHASE_RETURN_FAILED,
  REQUEST_STATUS_CREATE_PURCHASE_RETURN_PENDING,
  REQUEST_STATUS_CREATE_PURCHASE_RETURN_SUCCESS,
  REQUEST_STATUS_DELETE_PURCHASE_RECEIVABLE_PAYMENT_FAILED,
  REQUEST_STATUS_DELETE_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
  REQUEST_STATUS_DELETE_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_DELETE_PURCHASE_RETURN_FAILED,
  REQUEST_STATUS_DELETE_PURCHASE_RETURN_PENDING,
  REQUEST_STATUS_DELETE_PURCHASE_RETURN_SUCCESS,
  REQUEST_STATUS_DETAILS_PURCHASE_RECEIVABLE_PAYMENT_FAILED,
  REQUEST_STATUS_DETAILS_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
  REQUEST_STATUS_DETAILS_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_DETAILS_PURCHASE_RETURN_FAILED,
  REQUEST_STATUS_DETAILS_PURCHASE_RETURN_LOADING,
  REQUEST_STATUS_DETAILS_PURCHASE_RETURN_SUCCESS,
  REQUEST_STATUS_HISTORY_PURCHASE_RECEIVABLE_PAYMENT_FAILED,
  REQUEST_STATUS_HISTORY_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
  REQUEST_STATUS_HISTORY_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_HISTORY_PURCHASE_RETURN_FAILED,
  REQUEST_STATUS_HISTORY_PURCHASE_RETURN_LOADING,
  REQUEST_STATUS_HISTORY_PURCHASE_RETURN_SUCCESS,
  REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_FAILED,
  REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
  REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_LIST_PURCHASE_RETURN_FAILED,
  REQUEST_STATUS_LIST_PURCHASE_RETURN_LOADING,
  REQUEST_STATUS_LIST_PURCHASE_RETURN_SUCCESS,
  REQUEST_STATUS_UPDATE_PURCHASE_RECEIVABLE_PAYMENT_FAILED,
  REQUEST_STATUS_UPDATE_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
  REQUEST_STATUS_UPDATE_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_UPDATE_PURCHASE_RETURN_FAILED,
  REQUEST_STATUS_UPDATE_PURCHASE_RETURN_PENDING,
  REQUEST_STATUS_UPDATE_PURCHASE_RETURN_SUCCESS,
  SLICE_NAME_DATA_PURCHASE_INVOICE,
  SLICE_NAME_DATA_PURCHASE_INVOICE_PDF_PRINT,
  SLICE_NAME_DETAIL_PURCHASE_INVOICE,
  SLICE_NAME_DETAIL_PURCHASE_ORDER,
  SLICE_NAME_IMPORT_PURCHASE_INVOICE,
  SLICE_NAME_IMPORT_PURCHASE_INVOICE_PROGRESS,
  SLICE_NAME_LIST_PURCHASE_ORDER_FOR_AUTOCOMPLETE,
  SLICE_NAME_LIST_PURCHASE_ORDER_MAIN,
  SLICE_NAME_PURCHASE_DEBIT_NOTE_DETAILS,
  SLICE_NAME_PURCHASE_DEBIT_NOTE_HISTORY,
  SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST,
  SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST_PDF_PRINT,
  SLICE_NAME_PURCHASE_DELIVERY_DATA,
  SLICE_NAME_PURCHASE_DELIVERY_DATA_PDF_PRINT,
  SLICE_NAME_PURCHASE_DELIVERY_HISTORY_DETAILS,
  SLICE_NAME_PURCHASE_INVOICE_HISTORY,
  SLICE_NAME_PURCHASE_ORDER_DATA,
  SLICE_NAME_PURCHASE_ORDER_DATA_PDF_PRINT,
  SLICE_NAME_PURCHASE_ORDER_HISTORY,
  SLICE_NAME_PURCHASE_PAYMENT_DATA,
  SLICE_NAME_PURCHASE_PAYMENT_DATA_PDF_PRINT,
  SLICE_NAME_PURCHASE_PAYMENT_HISTORY,
  SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_DETAILS,
  SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_HISTORY,
  SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_LIST,
  SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_LIST_PDF_PRINT,
  SLICE_NAME_PURCHASE_RETURN_DETAILS,
  SLICE_NAME_PURCHASE_RETURN_HISTORY,
  SLICE_NAME_PURCHASE_RETURN_LIST,
  SLICE_NAME_PURCHASE_RETURN_LIST_PDF_PRINT,
  SLICE_NAME_QUOTATION_REQUEST_DATA,
  SLICE_NAME_QUOTATION_REQUEST_DATA_PDF_PRINT,
  SLICE_NAME_QUOTATION_REQUEST_HISTORY,
  SLICE_NAME_TEMP_ADD_PURCHASE_INVOICE,
  SLICE_NAME_TEMP_UPDATE_PURCHASE_INVOICE,
  STATUS_REQUEST_ADD_PURCHASE_DELIVERY_FAILED,
  STATUS_REQUEST_ADD_PURCHASE_DELIVERY_PENDING,
  STATUS_REQUEST_ADD_PURCHASE_DELIVERY_SUCCESS,
  STATUS_REQUEST_ADD_PURCHASE_INVOICE_FAILED,
  STATUS_REQUEST_ADD_PURCHASE_INVOICE_PENDING,
  STATUS_REQUEST_ADD_PURCHASE_INVOICE_SUCCESS,
  STATUS_REQUEST_ADD_PURCHASE_ORDER_FAILED,
  STATUS_REQUEST_ADD_PURCHASE_ORDER_PENDING,
  STATUS_REQUEST_ADD_PURCHASE_ORDER_SUCCESS,
  STATUS_REQUEST_ADD_PURCHASE_PAYMENT_FAILED,
  STATUS_REQUEST_ADD_PURCHASE_PAYMENT_PENDING,
  STATUS_REQUEST_ADD_PURCHASE_PAYMENT_SUCCESS,
  STATUS_REQUEST_ADD_QUOTATION_REQUEST_FAILED,
  STATUS_REQUEST_ADD_QUOTATION_REQUEST_PENDING,
  STATUS_REQUEST_ADD_QUOTATION_REQUEST_SUCCESS,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_DELETE_PURCHASE_DELIVERY_FAILED,
  STATUS_REQUEST_DELETE_PURCHASE_DELIVERY_PENDING,
  STATUS_REQUEST_DELETE_PURCHASE_DELIVERY_SUCCESS,
  STATUS_REQUEST_DELETE_PURCHASE_INVOICE_FAILED,
  STATUS_REQUEST_DELETE_PURCHASE_INVOICE_PENDING,
  STATUS_REQUEST_DELETE_PURCHASE_INVOICE_SUCCESS,
  STATUS_REQUEST_DELETE_PURCHASE_ORDER_FAILED,
  STATUS_REQUEST_DELETE_PURCHASE_ORDER_PENDING,
  STATUS_REQUEST_DELETE_PURCHASE_ORDER_SUCCESS,
  STATUS_REQUEST_DELETE_PURCHASE_PAYMENT_FAILED,
  STATUS_REQUEST_DELETE_PURCHASE_PAYMENT_PENDING,
  STATUS_REQUEST_DELETE_PURCHASE_PAYMENT_SUCCESS,
  STATUS_REQUEST_DELETE_QUOTATION_REQUEST_FAILED,
  STATUS_REQUEST_DELETE_QUOTATION_REQUEST_PENDING,
  STATUS_REQUEST_DELETE_QUOTATION_REQUEST_SUCCESS,
  STATUS_REQUEST_DETAIL_PURCHASE_DELIVERY_FAILED,
  STATUS_REQUEST_DETAIL_PURCHASE_DELIVERY_PENDING,
  STATUS_REQUEST_DETAIL_PURCHASE_DELIVERY_SUCCESS,
  STATUS_REQUEST_DETAIL_PURCHASE_INVOICE_FAILED,
  STATUS_REQUEST_DETAIL_PURCHASE_INVOICE_PENDING,
  STATUS_REQUEST_DETAIL_PURCHASE_INVOICE_SUCCESS,
  STATUS_REQUEST_DETAIL_PURCHASE_ORDER_FAILED,
  STATUS_REQUEST_DETAIL_PURCHASE_ORDER_PENDING,
  STATUS_REQUEST_DETAIL_PURCHASE_ORDER_SUCCESS,
  STATUS_REQUEST_DETAIL_PURCHASE_PAYMENT_FAILED,
  STATUS_REQUEST_DETAIL_PURCHASE_PAYMENT_PENDING,
  STATUS_REQUEST_DETAIL_PURCHASE_PAYMENT_SUCCESS,
  STATUS_REQUEST_DETAIL_QUOTATION_REQUEST_FAILED,
  STATUS_REQUEST_DETAIL_QUOTATION_REQUEST_PENDING,
  STATUS_REQUEST_DETAIL_QUOTATION_REQUEST_SUCCESS,
  STATUS_REQUEST_DETAILS_PURCHASE_DEBIT_NOTE_FAILED,
  STATUS_REQUEST_DETAILS_PURCHASE_DEBIT_NOTE_PENDING,
  STATUS_REQUEST_DETAILS_PURCHASE_DEBIT_NOTE_SUCCESS,
  STATUS_REQUEST_HISTORY_PURCHASE_DEBIT_NOTE_FAILED,
  STATUS_REQUEST_HISTORY_PURCHASE_DEBIT_NOTE_PENDING,
  STATUS_REQUEST_HISTORY_PURCHASE_DEBIT_NOTE_SUCCESS,
  STATUS_REQUEST_HISTORY_PURCHASE_DELIVERY_FAILED,
  STATUS_REQUEST_HISTORY_PURCHASE_DELIVERY_PENDING,
  STATUS_REQUEST_HISTORY_PURCHASE_DELIVERY_SUCCESS,
  STATUS_REQUEST_HISTORY_PURCHASE_INVOICE_FAILED,
  STATUS_REQUEST_HISTORY_PURCHASE_INVOICE_PENDING,
  STATUS_REQUEST_HISTORY_PURCHASE_INVOICE_SUCCESS,
  STATUS_REQUEST_HISTORY_PURCHASE_ORDER_FAILED,
  STATUS_REQUEST_HISTORY_PURCHASE_ORDER_PENDING,
  STATUS_REQUEST_HISTORY_PURCHASE_ORDER_SUCCESS,
  STATUS_REQUEST_HISTORY_PURCHASE_PAYMENT_FAILED,
  STATUS_REQUEST_HISTORY_PURCHASE_PAYMENT_PENDING,
  STATUS_REQUEST_HISTORY_PURCHASE_PAYMENT_SUCCESS,
  STATUS_REQUEST_HISTORY_QUOTATION_REQUEST_FAILED,
  STATUS_REQUEST_HISTORY_QUOTATION_REQUEST_PENDING,
  STATUS_REQUEST_HISTORY_QUOTATION_REQUEST_SUCCESS,
  STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_FAILED,
  STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_SUCCESS,
  STATUS_REQUEST_LIST_PURCHASE_DELIVERY_FAILED,
  STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_DELIVERY_SUCCESS,
  STATUS_REQUEST_LIST_PURCHASE_INVOICE_FAILED,
  STATUS_REQUEST_LIST_PURCHASE_INVOICE_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_INVOICE_SUCCESS,
  STATUS_REQUEST_LIST_PURCHASE_ORDER_FAILED,
  STATUS_REQUEST_LIST_PURCHASE_ORDER_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_ORDER_SUCCESS,
  STATUS_REQUEST_LIST_PURCHASE_PAYMENT_FAILED,
  STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_PAYMENT_SUCCESS,
  STATUS_REQUEST_LIST_QUOTATION_REQUEST_FAILED,
  STATUS_REQUEST_LIST_QUOTATION_REQUEST_PENDING,
  STATUS_REQUEST_LIST_QUOTATION_REQUEST_SUCCESS,
  STATUS_REQUEST_UPDATE_PURCHASE_DELIVERY_FAILED,
  STATUS_REQUEST_UPDATE_PURCHASE_DELIVERY_PENDING,
  STATUS_REQUEST_UPDATE_PURCHASE_DELIVERY_SUCCESS,
  STATUS_REQUEST_UPDATE_PURCHASE_INVOICE_FAILED,
  STATUS_REQUEST_UPDATE_PURCHASE_INVOICE_PENDING,
  STATUS_REQUEST_UPDATE_PURCHASE_INVOICE_SUCCESS,
  STATUS_REQUEST_UPDATE_PURCHASE_ORDER_FAILED,
  STATUS_REQUEST_UPDATE_PURCHASE_ORDER_PENDING,
  STATUS_REQUEST_UPDATE_PURCHASE_ORDER_SUCCESS,
  STATUS_REQUEST_UPDATE_PURCHASE_PAYMENT_FAILED,
  STATUS_REQUEST_UPDATE_PURCHASE_PAYMENT_PENDING,
  STATUS_REQUEST_UPDATE_PURCHASE_PAYMENT_SUCCESS,
  STATUS_REQUEST_UPDATE_QUOTATION_REQUEST_FAILED,
  STATUS_REQUEST_UPDATE_QUOTATION_REQUEST_PENDING,
  STATUS_REQUEST_UPDATE_QUOTATION_REQUEST_SUCCESS,
} from '../../utils/constants/store.constant';
import { purchaseAsyncThunkTypeWithActionProps } from '../../utils/data/mapping/async-thunk-type-with-action-props.mapping';
import {
  DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING,
  DEFAULT_KEY_NAME_STATUS_REQUEST,
} from '../../utils/default/object-keyname.default';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import {
  addPurchaseDelivery,
  addPurchaseInvoice,
  addPurchaseInvoiceBasic,
  addPurchaseInvoiceWithStoreData,
  addPurchaseOrder,
  addPurchasePayment,
  addQuotationRequest,
  createPurchaseReceivablePayment,
  createPurchaseReturn,
  deletePurchaseDelivery,
  deletePurchaseInvoice,
  deletePurchaseOrder,
  deletePurchasePayment,
  deletePurchaseReceivablePayment,
  deletePurchaseReturn,
  deleteQuotationRequest,
  detailPurchasePayment,
  getDetailPurchaseDelivery,
  getDetailPurchaseInvoice,
  getDetailPurchaseOrder,
  getDetailQuotationRequest,
  getHistoryPurchaseInvoice,
  getHistoryPurchaseOrder,
  getHistoryPurchasePayment,
  getHistoryQuotationRequest,
  getListPurchaseDelivery,
  getListPurchaseDeliveryRefresh,
  getListPurchaseInvoice,
  getListPurchaseInvoiceRefresh,
  getListPurchaseOrder,
  getListPurchaseOrderRefresh,
  getListPurchasePayment,
  getListPurchasePaymentRefresh,
  getListQuotationRequest,
  getListQuotationRequestRefresh,
  getPurchaseDebitNoteDetails,
  getPurchaseDebitNoteHistory,
  getPurchaseDebitNoteList,
  getPurchaseDebitNoteRefreshList,
  getPurchaseDeliveryHistoryDetails,
  getPurchaseReceivablePaymentDetails,
  getPurchaseReceivablePaymentHistory,
  getPurchaseReceivablePaymentList,
  getPurchaseReceivablePaymentRefreshList,
  getPurchaseReturnDetails,
  getPurchaseReturnHistory,
  getPurchaseReturnList,
  getPurchaseReturnRefreshList,
  updatePurchaseDelivery,
  updatePurchaseInviceWithStoreData,
  updatePurchaseInvoice,
  updatePurchaseOrder,
  updatePurchasePayment,
  updatePurchaseReceivablePayment,
  updatePurchaseReturn,
  updateQuotationRequest,
} from './purchase.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  // quotation request
  quotationRequestData: LocalStorage.get(LOCAL_STORAGE_QUOTATION_REQUEST),
  quotationRequestDetail: LocalStorage.get(LOCAL_STORAGE_QUOTATION_REQUEST_DETAIL),
  [SLICE_NAME_QUOTATION_REQUEST_DATA_PDF_PRINT]: LocalStorage.get(
    LOCAL_STORAGE_QUOTATION_REQUEST_PRINT_PDF,
  ),
  [SLICE_NAME_QUOTATION_REQUEST_HISTORY]: null,
  // purchase order
  purchaseOrderData: LocalStorage.get(LOCAL_STORAGE_PURCHASE_ORDER),
  [SLICE_NAME_PURCHASE_ORDER_DATA_PDF_PRINT]: LocalStorage.get(
    LOCAL_STORAGE_PURCHASE_ORDER_PDF_PRINT,
  ),
  [SLICE_NAME_LIST_PURCHASE_ORDER_MAIN]: LocalStorage.get(LOCAL_STORAGE_PURCHASE_ORDER),
  [SLICE_NAME_LIST_PURCHASE_ORDER_FOR_AUTOCOMPLETE]: null,
  [SLICE_NAME_DETAIL_PURCHASE_ORDER]: null,
  [SLICE_NAME_PURCHASE_ORDER_HISTORY]: null,
  // purchase delivery
  purchaseDeliveryData: LocalStorage.get(LOCAL_STORAGE_PURCHASE_DELIVERY),
  [SLICE_NAME_PURCHASE_DELIVERY_DATA_PDF_PRINT]: LocalStorage.get(
    LOCAL_STORAGE_PURCHASE_DELIVERY_PDF_PRINT,
  ),
  purchaseDeliveryDetail: LocalStorage.get(LOCAL_STORAGE_PURCHASE_DELIVERY_DETAIL),
  [SLICE_NAME_PURCHASE_DELIVERY_HISTORY_DETAILS]: null,
  // purchase payment
  purchasePaymentData: LocalStorage.get(LOCAL_STORAGE_PURCHASE_PAYMENT),
  [SLICE_NAME_PURCHASE_PAYMENT_DATA_PDF_PRINT]: LocalStorage.get(
    LOCAL_STORAGE_PURCHASE_PAYMENT_PDF_PRINT,
  ),
  [NAME_STATE_DETAIL_PURCHASE_PAYMENT]: null,
  [SLICE_NAME_PURCHASE_PAYMENT_HISTORY]: null,
  // purchase invoice
  [SLICE_NAME_DATA_PURCHASE_INVOICE]: LocalStorage.get(LOCAL_STORAGE_PURCHASE_INVOICE),
  [SLICE_NAME_DATA_PURCHASE_INVOICE_PDF_PRINT]: LocalStorage.get(
    LOCAL_STORAGE_PURCHASE_INVOICE_PDF_PRINT,
  ),
  [SLICE_NAME_DETAIL_PURCHASE_INVOICE]: null,
  [SLICE_NAME_TEMP_UPDATE_PURCHASE_INVOICE]: null,
  [SLICE_NAME_PURCHASE_INVOICE_HISTORY]: null,
  [SLICE_NAME_TEMP_ADD_PURCHASE_INVOICE]: null,
  [SLICE_NAME_IMPORT_PURCHASE_INVOICE]: null,
  [SLICE_NAME_IMPORT_PURCHASE_INVOICE_PROGRESS]: null,
  // purchase return
  [SLICE_NAME_PURCHASE_RETURN_LIST]: LocalStorage.get(LOCAL_STORAGE_PURCHASE_RETURN_LIST),
  [SLICE_NAME_PURCHASE_RETURN_LIST_PDF_PRINT]: LocalStorage.get(
    LOCAL_STORAGE_PURCHASE_RETURN_LIST_PDF_PRINT,
  ),
  [SLICE_NAME_PURCHASE_RETURN_DETAILS]: LocalStorage.get(LOCAL_STORAGE_PURCHASE_RETURN_DETAILS),
  [SLICE_NAME_PURCHASE_RETURN_HISTORY]: null,
  // purchase debit note
  [SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST_PDF_PRINT]: LocalStorage.get(
    LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_LIST_PDF_PRINT,
  ),
  [SLICE_NAME_PURCHASE_DEBIT_NOTE_HISTORY]: null,
  // purchase receivable payment
  [SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_LIST_PDF_PRINT]: LocalStorage.get(
    LOCAL_STORAGE_PURCHASE_RECEIVABLE_PAYMENT_LIST_PDF_PRINT,
  ),
  [SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_HISTORY]: null,
};

const purchaseSlice = createSlice({
  name: NAME_STORE.PURCHASE,
  initialState,
  reducers: {
    getListQuotationRequest,
    getListQuotationRequestRefresh,
    getDetailQuotationRequest,
    getHistoryQuotationRequest,
    addQuotationRequest,
    updateQuotationRequest,
    deleteQuotationRequest,
    getListPurchaseOrder,
    getListPurchaseOrderRefresh,
    getDetailPurchaseOrder,
    getHistoryPurchaseOrder,
    addPurchaseOrder,
    updatePurchaseOrder,
    deletePurchaseOrder,
    getListPurchaseDelivery,
    getListPurchaseDeliveryRefresh,
    getDetailPurchaseDelivery,
    getPurchaseDeliveryHistoryDetails,
    deletePurchaseDelivery,
    addPurchaseDelivery,
    updatePurchaseDelivery,
    getListPurchasePayment,
    getListPurchasePaymentRefresh,
    addPurchasePayment,
    updatePurchasePayment,
    detailPurchasePayment,
    getHistoryPurchasePayment,
    deletePurchasePayment,
    getListPurchaseInvoice,
    getListPurchaseInvoiceRefresh,
    addPurchaseInvoice,
    addPurchaseInvoiceBasic,
    updatePurchaseInvoice,
    getDetailPurchaseInvoice,
    getHistoryPurchaseInvoice,
    deletePurchaseInvoice,
    clearQuotationRequestData: (state) => {
      // LocalStorage.remove( LOCAL_STORAGE_QUOTATION_REQUEST )

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        quotationRequestData: null,
      };
    },
    clearPurchaseOrderData: (state) => {
      // temporaly disable for handling cache on refresh purchase order
      // LocalStorage.remove( LOCAL_STORAGE_PURCHASE_ORDER )

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        purchaseOrderData: null,
      };
    },
    clearPurchaseDeliveryData: (state) => {
      // LocalStorage.remove( LOCAL_STORAGE_PURCHASE_DELIVERY )

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        purchaseDeliveryData: null,
      };
    },
    clearPurchaseDeliveryDetail: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_PURCHASE_DELIVERY_DETAIL);

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        purchaseDeliveryDetail: null,
      };
    },
    clearPurchasePaymentData: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_PURCHASE_PAYMENT);

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        purchasePaymentData: null,
      };
    },
    clearDetailPurchasePayment: (state) => {
      return {
        ...state,
        [NAME_STATE_DETAIL_PURCHASE_PAYMENT]: null,
      };
    },
    clearPurchaseInvoiceData: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_PURCHASE_INVOICE);

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        [SLICE_NAME_DATA_PURCHASE_INVOICE]: null,
      };
    },
    clearDetailPurchaseInvoice: (state) => {
      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        [SLICE_NAME_DETAIL_PURCHASE_INVOICE]: null,
      };
    },
    clearDataSlicePurchase: (state, action) => {
      const { sliceName } = action.payload;

      if (LocalStorage.get(sliceName)) {
        LocalStorage.remove(sliceName);
      }

      return {
        ...state,
        [sliceName]: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    clearDataPurchaseSlice: (state, action) => {
      const { sliceName, localStorageName } = action.payload;

      if (LocalStorage.get(sliceName)) {
        LocalStorage.remove(sliceName);
      } else if (localStorageName) {
        if (LocalStorage.get(localStorageName)) LocalStorage.remove(localStorageName);
      }

      return {
        ...state,
        [sliceName]: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(
      initialState,
      {
        quotationRequestData: LOCAL_STORAGE_QUOTATION_REQUEST,
        quotationRequestDetail: LOCAL_STORAGE_QUOTATION_REQUEST_DETAIL,
        [SLICE_NAME_QUOTATION_REQUEST_DATA_PDF_PRINT]: LOCAL_STORAGE_QUOTATION_REQUEST_PRINT_PDF,
        purchaseOrderData: LOCAL_STORAGE_PURCHASE_ORDER,
        [SLICE_NAME_PURCHASE_ORDER_DATA_PDF_PRINT]: LOCAL_STORAGE_PURCHASE_ORDER_PDF_PRINT,
        [SLICE_NAME_LIST_PURCHASE_ORDER_MAIN]: LOCAL_STORAGE_PURCHASE_ORDER,
        purchaseDeliveryData: LOCAL_STORAGE_PURCHASE_DELIVERY,
        [SLICE_NAME_PURCHASE_DELIVERY_DATA_PDF_PRINT]: LOCAL_STORAGE_PURCHASE_DELIVERY_PDF_PRINT,
        purchaseDeliveryDetail: LOCAL_STORAGE_PURCHASE_DELIVERY_DETAIL,
        purchasePaymentData: LOCAL_STORAGE_PURCHASE_PAYMENT,
        [SLICE_NAME_PURCHASE_PAYMENT_DATA_PDF_PRINT]: LOCAL_STORAGE_PURCHASE_PAYMENT_PDF_PRINT,
        [SLICE_NAME_DATA_PURCHASE_INVOICE]: LOCAL_STORAGE_PURCHASE_INVOICE,
        [SLICE_NAME_DATA_PURCHASE_INVOICE_PDF_PRINT]: LOCAL_STORAGE_PURCHASE_INVOICE_PDF_PRINT,
        [SLICE_NAME_PURCHASE_RETURN_LIST]: LOCAL_STORAGE_PURCHASE_RETURN_LIST,
        [SLICE_NAME_PURCHASE_RETURN_LIST_PDF_PRINT]: LOCAL_STORAGE_PURCHASE_RETURN_LIST_PDF_PRINT,
        [SLICE_NAME_PURCHASE_RETURN_DETAILS]: LOCAL_STORAGE_PURCHASE_RETURN_DETAILS,
        [SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST_PDF_PRINT]:
          LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_LIST_PDF_PRINT,
        [SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_LIST_PDF_PRINT]:
          LOCAL_STORAGE_PURCHASE_RECEIVABLE_PAYMENT_LIST_PDF_PRINT,
      },
      DEFAULT_KEY_NAME_STATUS_REQUEST,
    ),
  },
  extraReducers: (builder) => {
    // quotation request list
    builder.addCase(getListQuotationRequest.fulfilled, (state, action) => {
      const { sliceName, requestStatusSuccessName } = action.payload;

      state[sliceName] = action.payload[sliceName];
      state.statusRequest = action.payload[requestStatusSuccessName];
    }),
      builder.addCase(getListQuotationRequest.pending, (state, action) => {
        state.statusRequest =
          action.meta && action.meta.arg && action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            ? action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            : STATUS_REQUEST_LIST_QUOTATION_REQUEST_PENDING;
      }),
      builder.addCase(getListQuotationRequest.rejected, (state, action) => {
        const {
          sliceName = SLICE_NAME_QUOTATION_REQUEST_DATA,
          requestStatusFailedName,
          searchParams,
        } = action.payload || {};

        state[sliceName] = searchParams;
        state.statusRequest = !isEmpty(action.payload)
          ? action.payload[requestStatusFailedName]
          : STATUS_REQUEST_LIST_QUOTATION_REQUEST_FAILED;
      }),
      // quotation request refresh list
      builder.addCase(getListQuotationRequestRefresh.fulfilled, (state, action) => {
        state.quotationRequestData = action.payload.quotationRequestData;
        state.statusRequest = STATUS_REQUEST_LIST_QUOTATION_REQUEST_SUCCESS;
      }),
      builder.addCase(getListQuotationRequestRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_QUOTATION_REQUEST_PENDING;
      }),
      builder.addCase(getListQuotationRequestRefresh.rejected, (state) => {
        state.quotationRequestData = null;
        state.statusRequest = STATUS_REQUEST_LIST_QUOTATION_REQUEST_FAILED;
      }),
      // request status details
      builder.addCase(getDetailQuotationRequest.fulfilled, (state, action) => {
        state.quotationRequestDetail = action.payload.quotationRequestDetail;
        state.statusRequest = STATUS_REQUEST_DETAIL_QUOTATION_REQUEST_SUCCESS;
      }),
      builder.addCase(getDetailQuotationRequest.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_QUOTATION_REQUEST_PENDING;
      }),
      builder.addCase(getDetailQuotationRequest.rejected, (state) => {
        state.quotationRequestDetail = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_QUOTATION_REQUEST_FAILED;
      }),
      // quotation request history
      builder.addCase(getHistoryQuotationRequest.fulfilled, (state, action) => {
        const { sliceName } = action.payload;

        if (!isEmpty(sliceName)) {
          state[sliceName] = action.payload[sliceName];
        }

        state.statusRequest = STATUS_REQUEST_HISTORY_QUOTATION_REQUEST_SUCCESS;
      }),
      builder.addCase(getHistoryQuotationRequest.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_HISTORY_QUOTATION_REQUEST_PENDING;
      }),
      builder.addCase(getHistoryQuotationRequest.rejected, (state) => {
        state[SLICE_NAME_QUOTATION_REQUEST_HISTORY] = null;
        state.statusRequest = STATUS_REQUEST_HISTORY_QUOTATION_REQUEST_FAILED;
      }),
      builder.addCase(addQuotationRequest.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_QUOTATION_REQUEST_SUCCESS;
      }),
      builder.addCase(addQuotationRequest.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_QUOTATION_REQUEST_PENDING;
      }),
      builder.addCase(addQuotationRequest.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_QUOTATION_REQUEST_FAILED;
      }),
      builder.addCase(updateQuotationRequest.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_QUOTATION_REQUEST_SUCCESS;
      }),
      builder.addCase(updateQuotationRequest.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_QUOTATION_REQUEST_PENDING;
      }),
      builder.addCase(updateQuotationRequest.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_QUOTATION_REQUEST_FAILED;
      }),
      builder.addCase(deleteQuotationRequest.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_QUOTATION_REQUEST_SUCCESS;
      }),
      builder.addCase(deleteQuotationRequest.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_QUOTATION_REQUEST_PENDING;
      }),
      builder.addCase(deleteQuotationRequest.rejected, (state) => {
        state.quotationRequestData = null;
        state.statusRequest = STATUS_REQUEST_DELETE_QUOTATION_REQUEST_FAILED;
      }),
      // list purchase order
      builder.addCase(getListPurchaseOrder.fulfilled, (state, action) => {
        const { sliceName, requestStatusSuccessName } = action.payload;

        state[sliceName] = action.payload[sliceName];
        state.statusRequest = action.payload[requestStatusSuccessName];
      }),
      builder.addCase(getListPurchaseOrder.pending, (state, action) => {
        state.statusRequest =
          action.meta && action.meta.arg && action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            ? action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            : STATUS_REQUEST_LIST_PURCHASE_ORDER_PENDING;
      }),
      builder.addCase(getListPurchaseOrder.rejected, (state, action) => {
        const {
          sliceName = SLICE_NAME_PURCHASE_ORDER_DATA,
          requestStatusFailedName,
          searchParams,
        } = action.payload || {};

        state[sliceName] = searchParams;
        state.statusRequest = !isEmpty(action.payload)
          ? action.payload[requestStatusFailedName]
          : STATUS_REQUEST_LIST_PURCHASE_ORDER_FAILED;
      }),
      // list purchase order when refresh
      builder.addCase(getListPurchaseOrderRefresh.fulfilled, (state, action) => {
        state.purchaseOrderData = action.payload.purchaseOrderData;
        state.statusRequest = STATUS_REQUEST_LIST_PURCHASE_ORDER_SUCCESS;
      }),
      builder.addCase(getListPurchaseOrderRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PURCHASE_ORDER_PENDING;
      }),
      builder.addCase(getListPurchaseOrderRefresh.rejected, (state) => {
        state.purchaseOrderData = null;
        state.statusRequest = STATUS_REQUEST_LIST_PURCHASE_ORDER_FAILED;
      }),
      // detail purchase order
      builder.addCase(getDetailPurchaseOrder.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_PURCHASE_ORDER_SUCCESS;
        state[SLICE_NAME_DETAIL_PURCHASE_ORDER] = action.payload[SLICE_NAME_DETAIL_PURCHASE_ORDER];
      }),
      builder.addCase(getDetailPurchaseOrder.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_PURCHASE_ORDER_PENDING;
      }),
      builder.addCase(getDetailPurchaseOrder.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_PURCHASE_ORDER_FAILED;
        state[SLICE_NAME_DETAIL_PURCHASE_ORDER] = null;
      }),
      // purchase order history
      builder.addCase(getHistoryPurchaseOrder.fulfilled, (state, action) => {
        const { sliceName } = action.payload;

        if (!isEmpty(sliceName)) {
          state[sliceName] = action.payload[sliceName];
        }

        state.statusRequest = STATUS_REQUEST_HISTORY_PURCHASE_ORDER_SUCCESS;
      }),
      builder.addCase(getHistoryPurchaseOrder.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_HISTORY_PURCHASE_ORDER_PENDING;
      }),
      builder.addCase(getHistoryPurchaseOrder.rejected, (state, action) => {
        const { sliceName } = action.payload;

        if (sliceName) {
          state[sliceName] = null;
        }

        state.statusRequest = STATUS_REQUEST_HISTORY_PURCHASE_ORDER_FAILED;
      }),
      // add purchae order
      builder.addCase(addPurchaseOrder.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PURCHASE_ORDER_SUCCESS;
      }),
      builder.addCase(addPurchaseOrder.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PURCHASE_ORDER_PENDING;
      }),
      builder.addCase(addPurchaseOrder.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PURCHASE_ORDER_FAILED;
      }),
      // update purchase order
      builder.addCase(updatePurchaseOrder.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PURCHASE_ORDER_SUCCESS;
      }),
      builder.addCase(updatePurchaseOrder.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PURCHASE_ORDER_PENDING;
      }),
      builder.addCase(updatePurchaseOrder.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PURCHASE_ORDER_FAILED;
      }),
      // delete purchase order
      builder.addCase(deletePurchaseOrder.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_PURCHASE_ORDER_SUCCESS;
      }),
      builder.addCase(deletePurchaseOrder.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_PURCHASE_ORDER_PENDING;
      }),
      builder.addCase(deletePurchaseOrder.rejected, (state) => {
        state.purchaseOrderData = null;
        state.statusRequest = STATUS_REQUEST_DELETE_PURCHASE_ORDER_FAILED;
      }),
      // purchase delivery list
      builder.addCase(getListPurchaseDelivery.fulfilled, (state, action) => {
        const { sliceName, requestStatusSuccessName } = action.payload;

        state[sliceName] = action.payload[sliceName];
        state.statusRequest = action.payload[requestStatusSuccessName];
      }),
      builder.addCase(getListPurchaseDelivery.pending, (state, action) => {
        state.statusRequest =
          action.meta && action.meta.arg && action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            ? action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            : STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PENDING;
      }),
      builder.addCase(getListPurchaseDelivery.rejected, (state, action) => {
        const {
          sliceName = SLICE_NAME_PURCHASE_DELIVERY_DATA,
          requestStatusFailedName,
          searchParams,
        } = action.payload || {};

        state[sliceName] = searchParams;
        state.statusRequest = !isEmpty(action.payload)
          ? action.payload[requestStatusFailedName]
          : STATUS_REQUEST_LIST_PURCHASE_DELIVERY_FAILED;
      }),
      // purchase delivery list refresh
      builder.addCase(getListPurchaseDeliveryRefresh.fulfilled, (state, action) => {
        state.purchaseDeliveryData = action.payload.purchaseDeliveryData;
        state.statusRequest = STATUS_REQUEST_LIST_PURCHASE_DELIVERY_SUCCESS;
      }),
      builder.addCase(getListPurchaseDeliveryRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PENDING;
      }),
      builder.addCase(getListPurchaseDeliveryRefresh.rejected, (state) => {
        state.purchaseDeliveryData = null;
        state.statusRequest = STATUS_REQUEST_LIST_PURCHASE_DELIVERY_FAILED;
      }),
      // purchase delivery details
      builder.addCase(getDetailPurchaseDelivery.fulfilled, (state, action) => {
        if (!isEmpty(action.payload)) {
          state.purchaseDeliveryDetail = action.payload.purchaseDeliveryDetail;
        }

        state.statusRequest = STATUS_REQUEST_DETAIL_PURCHASE_DELIVERY_SUCCESS;
      }),
      builder.addCase(getDetailPurchaseDelivery.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_PURCHASE_DELIVERY_PENDING;
      }),
      builder.addCase(getDetailPurchaseDelivery.rejected, (state) => {
        state.purchaseDeliveryDetail = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_PURCHASE_DELIVERY_FAILED;
      }),
      // purchase delivery history details
      builder.addCase(getPurchaseDeliveryHistoryDetails.fulfilled, (state, action) => {
        const { sliceName } = action.payload;

        if (!isEmpty(sliceName)) {
          state[sliceName] = action.payload[sliceName];
        }

        state.statusRequest = STATUS_REQUEST_HISTORY_PURCHASE_DELIVERY_SUCCESS;
      }),
      builder.addCase(getPurchaseDeliveryHistoryDetails.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_HISTORY_PURCHASE_DELIVERY_PENDING;
      }),
      builder.addCase(getPurchaseDeliveryHistoryDetails.rejected, (state, action) => {
        const { sliceName } = action.payload;

        if (sliceName) {
          state[sliceName] = null;
        }

        state.statusRequest = STATUS_REQUEST_HISTORY_PURCHASE_DELIVERY_FAILED;
      }),
      builder.addCase(deletePurchaseDelivery.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_PURCHASE_DELIVERY_SUCCESS;
      }),
      builder.addCase(deletePurchaseDelivery.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_PURCHASE_DELIVERY_PENDING;
      }),
      builder.addCase(deletePurchaseDelivery.rejected, (state) => {
        state.purchaseDeliveryData = null;
        state.statusRequest = STATUS_REQUEST_DELETE_PURCHASE_DELIVERY_FAILED;
      }),
      builder.addCase(addPurchaseDelivery.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PURCHASE_DELIVERY_SUCCESS;
      }),
      builder.addCase(addPurchaseDelivery.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PURCHASE_DELIVERY_PENDING;
      }),
      builder.addCase(addPurchaseDelivery.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PURCHASE_DELIVERY_FAILED;
      }),
      builder.addCase(updatePurchaseDelivery.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PURCHASE_DELIVERY_SUCCESS;
      }),
      builder.addCase(updatePurchaseDelivery.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PURCHASE_DELIVERY_PENDING;
      }),
      builder.addCase(updatePurchaseDelivery.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PURCHASE_DELIVERY_FAILED;
      }),
      // purchase payment list
      builder.addCase(getListPurchasePayment.fulfilled, (state, action) => {
        const { sliceName, requestStatusSuccessName } = action.payload;

        state[sliceName] = action.payload[sliceName];
        state.statusRequest = action.payload[requestStatusSuccessName];
      }),
      builder.addCase(getListPurchasePayment.pending, (state, action) => {
        state.statusRequest =
          action.meta && action.meta.arg && action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            ? action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            : STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PENDING;
      }),
      builder.addCase(getListPurchasePayment.rejected, (state, action) => {
        const {
          sliceName = SLICE_NAME_PURCHASE_PAYMENT_DATA,
          requestStatusFailedName,
          searchParams,
        } = action.payload || {};

        state[sliceName] = searchParams;
        state.statusRequest = !isEmpty(action.payload)
          ? action.payload[requestStatusFailedName]
          : STATUS_REQUEST_LIST_PURCHASE_PAYMENT_FAILED;
      }),
      // purchase payment list refresh
      builder.addCase(getListPurchasePaymentRefresh.fulfilled, (state, action) => {
        state.purchasePaymentData = action.payload.purchasePaymentData;
        state.statusRequest = STATUS_REQUEST_LIST_PURCHASE_PAYMENT_SUCCESS;
      }),
      builder.addCase(getListPurchasePaymentRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PENDING;
      }),
      builder.addCase(getListPurchasePaymentRefresh.rejected, (state) => {
        state.purchasePaymentData = null;
        state.statusRequest = STATUS_REQUEST_LIST_PURCHASE_PAYMENT_FAILED;
      }),
      builder.addCase(detailPurchasePayment.fulfilled, (state, action) => {
        state[NAME_STATE_DETAIL_PURCHASE_PAYMENT] =
          action.payload[NAME_STATE_DETAIL_PURCHASE_PAYMENT];
        state.statusRequest = STATUS_REQUEST_DETAIL_PURCHASE_PAYMENT_SUCCESS;
      }),
      builder.addCase(detailPurchasePayment.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_PURCHASE_PAYMENT_PENDING;
      }),
      builder.addCase(detailPurchasePayment.rejected, (state) => {
        state[NAME_STATE_DETAIL_PURCHASE_PAYMENT] = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_PURCHASE_PAYMENT_FAILED;
      }),
      // purchase payment history
      builder.addCase(getHistoryPurchasePayment.fulfilled, (state, action) => {
        const { sliceName } = action.payload;

        if (!isEmpty(sliceName)) {
          state[sliceName] = action.payload[sliceName];
        }

        state.statusRequest = STATUS_REQUEST_HISTORY_PURCHASE_PAYMENT_SUCCESS;
      }),
      builder.addCase(getHistoryPurchasePayment.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_HISTORY_PURCHASE_PAYMENT_PENDING;
      }),
      builder.addCase(getHistoryPurchasePayment.rejected, (state, action) => {
        const { sliceName } = action.payload;

        if (sliceName) {
          state[sliceName] = null;
        }

        state.statusRequest = STATUS_REQUEST_HISTORY_PURCHASE_PAYMENT_FAILED;
      }),
      builder.addCase(addPurchasePayment.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PURCHASE_PAYMENT_SUCCESS;
      }),
      builder.addCase(addPurchasePayment.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PURCHASE_PAYMENT_PENDING;
      }),
      builder.addCase(addPurchasePayment.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PURCHASE_PAYMENT_FAILED;
      }),
      // update purchase payment
      builder.addCase(updatePurchasePayment.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PURCHASE_PAYMENT_SUCCESS;
      }),
      builder.addCase(updatePurchasePayment.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PURCHASE_PAYMENT_PENDING;
      }),
      builder.addCase(updatePurchasePayment.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PURCHASE_PAYMENT_FAILED;
      }),
      // delete purchase payment
      builder.addCase(deletePurchasePayment.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_PURCHASE_PAYMENT_SUCCESS;
      }),
      builder.addCase(deletePurchasePayment.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_PURCHASE_PAYMENT_PENDING;
      }),
      builder.addCase(deletePurchasePayment.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_PURCHASE_PAYMENT_FAILED;
      }),
      // purchase invoice list
      builder.addCase(getListPurchaseInvoice.fulfilled, (state, action) => {
        const { sliceName, requestStatusSuccessName } = action.payload;

        state[sliceName] = action.payload[sliceName];
        state.statusRequest = action.payload[requestStatusSuccessName];
      }),
      builder.addCase(getListPurchaseInvoice.pending, (state, action) => {
        state.statusRequest =
          action.meta && action.meta.arg && action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            ? action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            : STATUS_REQUEST_LIST_PURCHASE_INVOICE_PENDING;
      }),
      builder.addCase(getListPurchaseInvoice.rejected, (state, action) => {
        const {
          sliceName = SLICE_NAME_DATA_PURCHASE_INVOICE,
          requestStatusFailedName,
          searchParams,
        } = action.payload || {};

        state[sliceName] = searchParams;
        state.statusRequest = !isEmpty(action.payload)
          ? action.payload[requestStatusFailedName]
          : STATUS_REQUEST_LIST_PURCHASE_INVOICE_FAILED;
      }),
      // purchase invoice list refresh
      builder.addCase(getListPurchaseInvoiceRefresh.fulfilled, (state, action) => {
        state[SLICE_NAME_DATA_PURCHASE_INVOICE] = action.payload[SLICE_NAME_DATA_PURCHASE_INVOICE];
        state.statusRequest = STATUS_REQUEST_LIST_PURCHASE_INVOICE_SUCCESS;
      }),
      builder.addCase(getListPurchaseInvoiceRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PURCHASE_INVOICE_PENDING;
      }),
      builder.addCase(getListPurchaseInvoiceRefresh.rejected, (state) => {
        state[SLICE_NAME_DATA_PURCHASE_INVOICE] = null;
        state.statusRequest = STATUS_REQUEST_LIST_PURCHASE_INVOICE_FAILED;
      }),
      builder.addCase(getDetailPurchaseInvoice.fulfilled, (state, action) => {
        state[SLICE_NAME_DETAIL_PURCHASE_INVOICE] =
          action.payload[SLICE_NAME_DETAIL_PURCHASE_INVOICE];
        state.statusRequest = STATUS_REQUEST_DETAIL_PURCHASE_INVOICE_SUCCESS;
      }),
      builder.addCase(getDetailPurchaseInvoice.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_PURCHASE_INVOICE_PENDING;
      }),
      builder.addCase(getDetailPurchaseInvoice.rejected, (state) => {
        state[SLICE_NAME_DETAIL_PURCHASE_INVOICE] = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_PURCHASE_INVOICE_FAILED;
      }),
      // purchase invoice history
      builder.addCase(getHistoryPurchaseInvoice.fulfilled, (state, action) => {
        const { sliceName } = action.payload;

        if (!isEmpty(sliceName)) {
          state[sliceName] = action.payload[sliceName];
        }

        state.statusRequest = STATUS_REQUEST_HISTORY_PURCHASE_INVOICE_SUCCESS;
      }),
      builder.addCase(getHistoryPurchaseInvoice.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_HISTORY_PURCHASE_INVOICE_PENDING;
      }),
      builder.addCase(getHistoryPurchaseInvoice.rejected, (state, action) => {
        const { sliceName } = action.payload;

        if (sliceName) {
          state[sliceName] = null;
        }

        state.statusRequest = STATUS_REQUEST_HISTORY_PURCHASE_INVOICE_FAILED;
      }),
      // add purchase invoice
      builder.addCase(addPurchaseInvoice.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PURCHASE_INVOICE_SUCCESS;
      }),
      builder.addCase(addPurchaseInvoice.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PURCHASE_INVOICE_PENDING;
      }),
      builder.addCase(addPurchaseInvoice.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PURCHASE_INVOICE_FAILED;
      }),
      // add purchase invoice basic (for basic app)
      builder.addCase(addPurchaseInvoiceBasic.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PURCHASE_INVOICE_SUCCESS;
      }),
      builder.addCase(addPurchaseInvoiceBasic.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PURCHASE_INVOICE_PENDING;
      }),
      builder.addCase(addPurchaseInvoiceBasic.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PURCHASE_INVOICE_FAILED;
      }),
      // add purchase invoice
      // add purchase invoice and store data
      builder.addCase(addPurchaseInvoiceWithStoreData.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_ADD_PURCHASE_INVOICE_SUCCESS;
        state[SLICE_NAME_TEMP_ADD_PURCHASE_INVOICE] =
          action.payload[SLICE_NAME_TEMP_ADD_PURCHASE_INVOICE];
      }),
      builder.addCase(addPurchaseInvoiceWithStoreData.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PURCHASE_INVOICE_PENDING;
      }),
      builder.addCase(addPurchaseInvoiceWithStoreData.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PURCHASE_INVOICE_FAILED;
        state[SLICE_NAME_TEMP_ADD_PURCHASE_INVOICE] = null;
      }),
      // update purchase invoice
      builder.addCase(updatePurchaseInvoice.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PURCHASE_INVOICE_SUCCESS;
      }),
      builder.addCase(updatePurchaseInvoice.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PURCHASE_INVOICE_PENDING;
      }),
      builder.addCase(updatePurchaseInvoice.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PURCHASE_INVOICE_FAILED;
      }),
      // update purchase invoice with store data
      builder.addCase(updatePurchaseInviceWithStoreData.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PURCHASE_INVOICE_SUCCESS;
        state[SLICE_NAME_TEMP_UPDATE_PURCHASE_INVOICE] =
          action.payload[SLICE_NAME_TEMP_UPDATE_PURCHASE_INVOICE];
      }),
      builder.addCase(updatePurchaseInviceWithStoreData.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PURCHASE_INVOICE_PENDING;
      }),
      builder.addCase(updatePurchaseInviceWithStoreData.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PURCHASE_INVOICE_FAILED;
        state[SLICE_NAME_TEMP_UPDATE_PURCHASE_INVOICE] = null;
      }),
      // delete purchase invoice
      builder.addCase(deletePurchaseInvoice.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_PURCHASE_INVOICE_SUCCESS;
      }),
      builder.addCase(deletePurchaseInvoice.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_PURCHASE_INVOICE_PENDING;
      }),
      builder.addCase(deletePurchaseInvoice.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_PURCHASE_INVOICE_FAILED;
      }),
      // purchase debit note list
      builder.addCase(getPurchaseDebitNoteList.fulfilled, (state, action) => {
        const { sliceName, requestStatusSuccessName } = action.payload;

        state[sliceName] = action.payload[sliceName];
        state.statusRequest = action.payload[requestStatusSuccessName];
      }),
      builder.addCase(getPurchaseDebitNoteList.pending, (state, action) => {
        state.statusRequest =
          action.meta && action.meta.arg && action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            ? action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            : STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_PENDING;
      }),
      builder.addCase(getPurchaseDebitNoteList.rejected, (state, action) => {
        const {
          sliceName = SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST,
          requestStatusFailedName,
          searchParams,
        } = action.payload || {};

        state[sliceName] = searchParams;
        state.statusRequest = !isEmpty(action.payload)
          ? action.payload[requestStatusFailedName]
          : STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_FAILED;
      }),
      // purchase debit note list refresh
      builder.addCase(getPurchaseDebitNoteRefreshList.fulfilled, (state, action) => {
        state[SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST] =
          action.payload[SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST];
        state.statusRequest = STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_SUCCESS;
      }),
      builder.addCase(getPurchaseDebitNoteRefreshList.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_PENDING;
      }),
      builder.addCase(getPurchaseDebitNoteRefreshList.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PURCHASE_DEBIT_NOTE_FAILED;
        state[SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST] = null;
      }),
      // purchase debit note details
      builder.addCase(getPurchaseDebitNoteDetails.fulfilled, (state, action) => {
        state[SLICE_NAME_PURCHASE_DEBIT_NOTE_DETAILS] =
          action.payload[SLICE_NAME_PURCHASE_DEBIT_NOTE_DETAILS];
        state.statusRequest = STATUS_REQUEST_DETAILS_PURCHASE_DEBIT_NOTE_SUCCESS;
      }),
      builder.addCase(getPurchaseDebitNoteDetails.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAILS_PURCHASE_DEBIT_NOTE_PENDING;
      }),
      builder.addCase(getPurchaseDebitNoteDetails.rejected, (state) => {
        state[SLICE_NAME_PURCHASE_DEBIT_NOTE_DETAILS] = null;
        state.statusRequest = STATUS_REQUEST_DETAILS_PURCHASE_DEBIT_NOTE_FAILED;
      }),
      // purchase debit note history
      builder.addCase(getPurchaseDebitNoteHistory.fulfilled, (state, action) => {
        const { sliceName } = action.payload;

        if (sliceName) state[sliceName] = action.payload[sliceName];
        state.statusRequest = STATUS_REQUEST_HISTORY_PURCHASE_DEBIT_NOTE_SUCCESS;
      }),
      builder.addCase(getPurchaseDebitNoteHistory.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_HISTORY_PURCHASE_DEBIT_NOTE_PENDING;
      }),
      builder.addCase(getPurchaseDebitNoteHistory.rejected, (state, action) => {
        const { sliceName } = action.payload;
        if (sliceName) state[sliceName] = null;

        state.statusRequest = STATUS_REQUEST_HISTORY_PURCHASE_DEBIT_NOTE_FAILED;
      }),
      // purchase return list
      builder.addCase(getPurchaseReturnList.fulfilled, (state, action) => {
        const { sliceName, requestStatusSuccessName } = action.payload;

        state[sliceName] = action.payload[sliceName];
        state.statusRequest = action.payload[requestStatusSuccessName];
      }),
      builder.addCase(getPurchaseReturnList.pending, (state, action) => {
        state.statusRequest =
          action.meta && action.meta.arg && action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            ? action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            : REQUEST_STATUS_LIST_PURCHASE_RETURN_LOADING;
      }),
      builder.addCase(getPurchaseReturnList.rejected, (state, action) => {
        const {
          sliceName = SLICE_NAME_PURCHASE_RETURN_LIST,
          requestStatusFailedName,
          searchParams,
        } = action.payload || {};

        state[sliceName] = searchParams;
        state.statusRequest = !isEmpty(action.payload)
          ? action.payload[requestStatusFailedName]
          : REQUEST_STATUS_LIST_PURCHASE_RETURN_FAILED;
      }),
      // purchase return list refresh
      builder.addCase(getPurchaseReturnRefreshList.fulfilled, (state, action) => {
        state[SLICE_NAME_PURCHASE_RETURN_LIST] = action.payload[SLICE_NAME_PURCHASE_RETURN_LIST];
        state.statusRequest = REQUEST_STATUS_LIST_PURCHASE_RETURN_SUCCESS;
      }),
      builder.addCase(getPurchaseReturnRefreshList.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_LIST_PURCHASE_RETURN_LOADING;
      }),
      builder.addCase(getPurchaseReturnRefreshList.rejected, (state) => {
        state.statusRequest = REQUEST_STATUS_LIST_PURCHASE_RETURN_FAILED;
        state[SLICE_NAME_PURCHASE_RETURN_LIST] = null;
      }),
      // purchase return details
      builder.addCase(getPurchaseReturnDetails.fulfilled, (state, action) => {
        state[SLICE_NAME_PURCHASE_RETURN_DETAILS] =
          action.payload[SLICE_NAME_PURCHASE_RETURN_DETAILS];
        state.statusRequest = REQUEST_STATUS_DETAILS_PURCHASE_RETURN_SUCCESS;
      }),
      builder.addCase(getPurchaseReturnDetails.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_DETAILS_PURCHASE_RETURN_LOADING;
      }),
      builder.addCase(getPurchaseReturnDetails.rejected, (state) => {
        state[SLICE_NAME_PURCHASE_RETURN_DETAILS] = null;
        state.statusRequest = REQUEST_STATUS_DETAILS_PURCHASE_RETURN_FAILED;
      }),
      // create purchase return
      builder.addCase(createPurchaseReturn.fulfilled, (state) => {
        state.statusRequest = REQUEST_STATUS_CREATE_PURCHASE_RETURN_SUCCESS;
      }),
      builder.addCase(createPurchaseReturn.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_CREATE_PURCHASE_RETURN_PENDING;
      }),
      builder.addCase(createPurchaseReturn.rejected, (state) => {
        state.statusRequest = REQUEST_STATUS_CREATE_PURCHASE_RETURN_FAILED;
      }),
      // purchase return history
      builder.addCase(getPurchaseReturnHistory.fulfilled, (state, action) => {
        const { sliceName } = action.payload;

        if (sliceName) state[sliceName] = action.payload[sliceName];
        state.statusRequest = REQUEST_STATUS_HISTORY_PURCHASE_RETURN_SUCCESS;
      }),
      builder.addCase(getPurchaseReturnHistory.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_HISTORY_PURCHASE_RETURN_LOADING;
      }),
      builder.addCase(getPurchaseReturnHistory.rejected, (state, action) => {
        const { sliceName } = action.payload;
        if (sliceName) state[sliceName] = null;

        state.statusRequest = REQUEST_STATUS_HISTORY_PURCHASE_RETURN_FAILED;
      }),
      // update purchase return
      builder.addCase(updatePurchaseReturn.fulfilled, (state) => {
        state.statusRequest = REQUEST_STATUS_UPDATE_PURCHASE_RETURN_SUCCESS;
      }),
      builder.addCase(updatePurchaseReturn.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_UPDATE_PURCHASE_RETURN_PENDING;
      }),
      builder.addCase(updatePurchaseReturn.rejected, (state) => {
        state.statusRequest = REQUEST_STATUS_UPDATE_PURCHASE_RETURN_FAILED;
      }),
      // delete purchase return
      builder.addCase(deletePurchaseReturn.fulfilled, (state) => {
        state.statusRequest = REQUEST_STATUS_DELETE_PURCHASE_RETURN_SUCCESS;
      }),
      builder.addCase(deletePurchaseReturn.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_DELETE_PURCHASE_RETURN_PENDING;
      }),
      builder.addCase(deletePurchaseReturn.rejected, (state) => {
        state.statusRequest = REQUEST_STATUS_DELETE_PURCHASE_RETURN_FAILED;
      }),
      // purchase receivable payment list
      builder.addCase(getPurchaseReceivablePaymentList.fulfilled, (state, action) => {
        const { sliceName, requestStatusSuccessName } = action.payload;

        state[sliceName] = action.payload[sliceName];
        state.statusRequest = action.payload[requestStatusSuccessName];
      }),
      builder.addCase(getPurchaseReceivablePaymentList.pending, (state, action) => {
        state.statusRequest =
          action.meta && action.meta.arg && action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            ? action.meta.arg[DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING]
            : REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_LOADING;
      }),
      builder.addCase(getPurchaseReceivablePaymentList.rejected, (state, action) => {
        const {
          sliceName = SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_LIST,
          requestStatusFailedName,
          searchParams,
        } = action.payload || {};

        state[sliceName] = searchParams;
        state.statusRequest = !isEmpty(action.payload)
          ? action.payload[requestStatusFailedName]
          : REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_FAILED;
      }),
      // purchase receivable payment list refresh
      builder.addCase(getPurchaseReceivablePaymentRefreshList.fulfilled, (state, action) => {
        state[SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_LIST] =
          action.payload[SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_LIST];
        state.statusRequest = REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS;
      }),
      builder.addCase(getPurchaseReceivablePaymentRefreshList.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_LOADING;
      }),
      builder.addCase(getPurchaseReceivablePaymentRefreshList.rejected, (state) => {
        state[SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_LIST] = null;
        state.statusRequest = REQUEST_STATUS_LIST_PURCHASE_RECEIVABLE_PAYMENT_FAILED;
      }),
      // purchase receivable payment details
      builder.addCase(getPurchaseReceivablePaymentDetails.fulfilled, (state, action) => {
        state[SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_DETAILS] =
          action.payload[SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_DETAILS];
        state.statusRequest = REQUEST_STATUS_DETAILS_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS;
      }),
      builder.addCase(getPurchaseReceivablePaymentDetails.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_DETAILS_PURCHASE_RECEIVABLE_PAYMENT_LOADING;
      }),
      builder.addCase(getPurchaseReceivablePaymentDetails.rejected, (state) => {
        state[SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_DETAILS] = null;
        state.statusRequest = REQUEST_STATUS_DETAILS_PURCHASE_RECEIVABLE_PAYMENT_FAILED;
      }),
      // purchase receivable payment history
      builder.addCase(getPurchaseReceivablePaymentHistory.fulfilled, (state, action) => {
        const { sliceName } = action.payload;

        if (sliceName) state[sliceName] = action.payload[sliceName];
        state.statusRequest = REQUEST_STATUS_HISTORY_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS;
      }),
      builder.addCase(getPurchaseReceivablePaymentHistory.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_HISTORY_PURCHASE_RECEIVABLE_PAYMENT_LOADING;
      }),
      builder.addCase(getPurchaseReceivablePaymentHistory.rejected, (state, action) => {
        const { sliceName } = action.payload;
        if (sliceName) state[sliceName] = null;

        state.statusRequest = REQUEST_STATUS_HISTORY_PURCHASE_RECEIVABLE_PAYMENT_FAILED;
      }),
      // create purchase return
      builder.addCase(createPurchaseReceivablePayment.fulfilled, (state) => {
        state.statusRequest = REQUEST_STATUS_CREATE_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS;
      }),
      builder.addCase(createPurchaseReceivablePayment.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_CREATE_PURCHASE_RECEIVABLE_PAYMENT_LOADING;
      }),
      builder.addCase(createPurchaseReceivablePayment.rejected, (state) => {
        state.statusRequest = REQUEST_STATUS_CREATE_PURCHASE_RECEIVABLE_PAYMENT_FAILED;
      }),
      // update purchase return
      builder.addCase(updatePurchaseReceivablePayment.fulfilled, (state) => {
        state.statusRequest = REQUEST_STATUS_UPDATE_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS;
      }),
      builder.addCase(updatePurchaseReceivablePayment.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_UPDATE_PURCHASE_RECEIVABLE_PAYMENT_LOADING;
      }),
      builder.addCase(updatePurchaseReceivablePayment.rejected, (state) => {
        state.statusRequest = REQUEST_STATUS_UPDATE_PURCHASE_RECEIVABLE_PAYMENT_FAILED;
      }),
      // delete purchase receivable payment
      builder.addCase(deletePurchaseReceivablePayment.fulfilled, (state) => {
        state.statusRequest = REQUEST_STATUS_DELETE_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS;
      }),
      builder.addCase(deletePurchaseReceivablePayment.pending, (state) => {
        state.statusRequest = REQUEST_STATUS_DELETE_PURCHASE_RECEIVABLE_PAYMENT_LOADING;
      }),
      builder.addCase(deletePurchaseReceivablePayment.rejected, (state) => {
        state.statusRequest = REQUEST_STATUS_DELETE_PURCHASE_RECEIVABLE_PAYMENT_FAILED;
      }),
      /**
       * matcher for purchase invoice, includes import file and check progress import files
       */
      sliceReduceHelper.createMatcherEachRequestStatusAction({
        builder,
        baseTypesRegistered: BASE_TYPES_PURCHASE_ACTION_REGISTERED,
        mappingAsyncThunkTypeWithActionProps: purchaseAsyncThunkTypeWithActionProps,
      });
  },
});

export const { actions, reducer } = purchaseSlice;

export const {
  clearQuotationRequestData,
  clearPurchaseOrderData,
  clearPurchaseDeliveryData,
  clearPurchaseDeliveryDetail,
  clearPurchasePaymentData,
  clearPurchaseInvoiceData,
  clearDetailPurchaseInvoice,
  clearDataSlicePurchase,
  clearDataPurchaseSlice,
} = actions;

export default reducer;
