import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_SALES_INVOICE_PAYMENT_DETAILS,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import arrHelp from '../../helpers/array.helpers';
import formatHelp from '../../helpers/format.helpers';
import objHelper from '../../helpers/object.helper';
import parseResponseHelpers from '../../helpers/parse-response.helpers';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import { generalizeAttachmentFromDetails } from './initial-add-attachment';
import {
  generalizeDataAddSalesPayment,
  generateDataNValidationAddInvoiceFromListSI,
} from './initial-add-sales-payment';
import {
  initialInvoiceData,
  initialInvoiceValidation,
  initialPaymentData,
  initialPaymentValidation,
} from './initial-data-sales-payment';

/**
 *
 * @param { object } salesPaymentOld data detail sales payment
 * @param { string } customDataKey return name key of data
 * @param { string } customValidationKey return name key of validation
 * @returns
 */
export const generateDataNValidationUpdateSalesPaymentFromDetailSP = (
  salesPaymentOld,
  customDataKey = 'paymentData',
  customValidationKey = 'paymentValidation',
) => {
  if (isEmpty(salesPaymentOld) || typeof salesPaymentOld !== 'object') {
    return {
      [customDataKey]: initialPaymentData(),
      [customValidationKey]: initialPaymentValidation(),
    };
  }

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const paymentData = usedID.map((id) => {
    const {
      sales_payment_no,
      sales_payment_id,
      account_id,
      account_code,
      account_name,
      contact_code,
      customer_name,
      customer_address,
      customer_id,
      customer_code,
    } = salesPaymentOld;

    return {
      ...initialPaymentData('', id),
      ...salesPaymentOld,
      sales_agent_id_container: {
        contact_id: salesPaymentOld.sales_agent_id || '',
        contact_name: salesPaymentOld.sales_agent_name || '',
        contact_code: salesPaymentOld.sales_agent_code || '',
      },
      customer_id_container: {
        contact_id: customer_id,
        contact_name: customer_name,
        contact_code: customer_code || contact_code,
        billing_address: customer_address,
      },
      sales_payment_no: sales_payment_no,
      sales_payment_no_container: {
        value: sales_payment_no,
      },
      account_id_container: {
        account_id,
        account_code,
        account_name,
      },
      sales_payment_id,
      // sales_invoice_no: sales_payment_no,
      // sales_invoice_no_container: {
      //     value: sales_payment_no,
      // }
    };
  });

  const paymentValidation = usedID.map((id) => {
    return initialPaymentValidation('', id);
  });

  return {
    [customDataKey]: paymentData,
    [customValidationKey]: paymentValidation,
  };
};

/**
 *
 * @param { object } salesPaymentDetails    data detail sales payment
 * @param { string } customDataKey          return name key of data
 * @param { string } customValidationKey    return name key of validation
 * @returns
 */
export const generateDataNValidationUpdateSalesPaymentFromDetailSPv2 = ({
  salesPaymentDetails,
  companyUserData,
  customDataKey = 'paymentData',
  customValidationKey = 'paymentValidation',
}) => {
  if (isEmpty(salesPaymentDetails) || typeof salesPaymentDetails !== 'object') {
    return {
      [customDataKey]: initialPaymentData(),
      [customValidationKey]: initialPaymentValidation(),
    };
  }

  const usedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const latestInvoiceTransactionDate = arrHelp.getLatestDate({
    arr: salesPaymentDetails[DEFAULT_KEY_NAME_SALES_INVOICE_PAYMENT_DETAILS],
    dateKeyname: 'transaction_date',
  });

  const paymentData = usedID.map((id) => {
    const {
      sales_payment_no,
      sales_payment_id,
      account_id,
      account_code,
      account_name,
      contact_code,
      customer_name,
      customer_address,
      customer_id,
      customer_code,
      description,
    } = salesPaymentDetails;

    return {
      ...initialPaymentData('', id),
      ...salesPaymentDetails,
      attachment: generalizeAttachmentFromDetails({
        dataDetails: salesPaymentDetails,
      }),
      customer_id_container: {
        contact_id: customer_id,
        contact_name: customer_name,
        contact_code: customer_code || contact_code,
        billing_address: customer_address,
      },
      sales_payment_no: sales_payment_no,
      sales_payment_no_container: {
        value: sales_payment_no,
      },
      account_id_container: {
        account_id,
        account_code,
        account_name,
      },
      sales_payment_id,
      description: description || '',
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        latestInvoiceTransactionDate ||
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
      ...parseResponseHelpers.tagContainerInputFromDetails(salesPaymentDetails),
      // bypass validation, freaking QA
      current_date: undefined,
    };
  });

  const paymentValidation = usedID.map((id) => {
    return initialPaymentValidation('', id);
  });

  return {
    [customDataKey]: paymentData,
    [customValidationKey]: paymentValidation,
  };
};

/**
 *
 * @param { object } salesPaymentOld data detail sales payment
 * @param { string } customDataKey return name key of data
 * @param { string } customValidationKey return name key of validation
 * @returns
 */
export const generateDataNValidationUpdateInvoiceSalesPaymentFromDetailSP = (
  salesPaymentOld,
  customDataKey = 'invoiceData',
  customValidationKey = 'invoiceValidation',
) => {
  if (
    isEmpty(salesPaymentOld) ||
    typeof salesPaymentOld !== 'object' ||
    (typeof salesPaymentOld === 'object' && !salesPaymentOld.sales_invoice_payments)
  ) {
    return {
      [customDataKey]: initialInvoiceData(),
      [customValidationKey]: initialInvoiceValidation(),
    };
  }

  const { sales_invoice_payments } = salesPaymentOld;

  const usedID = arrHelp.generateArrWithFunc(sales_invoice_payments.length, uuidv4);

  /**
   * amount, last user input on add sales payment
   * sales_invoice_amount, total amount that must billed by user, require just for total amount
   * amount_available, total remaining amount that must bill by user
   * amount_available_const maximum input amount user
   *      on update sales payment, amount_available_const result sum from amount_available by amount
   * amount_input from inputting amount by user
   *
   * isFromDetailSalesPayment, is for differentiator about new invoice on autocomplete
   */
  const invoiceData = usedID.map((id, index) => {
    const { amount, amount_available, sales_invoice_amount } = sales_invoice_payments[index];

    const amount_available_const =
      formatHelp.reverseCurrencyFormatWithRegex(amount) +
      formatHelp.reverseCurrencyFormatWithRegex(amount_available);

    return {
      ...initialInvoiceData('', id),
      ...sales_invoice_payments[index],
      amount_input: Number(amount),
      amount: sales_invoice_amount,
      amount_available: formatHelp.currencyFormatWithRegex(amount_available),
      amount_available_const,
      isFromDetailSalesPayment: true,
    };
  });

  const invoiceValidation = usedID.map((id) => {
    return initialInvoiceValidation('', id);
  });

  return {
    [customDataKey]: invoiceData,
    [customValidationKey]: invoiceValidation,
  };
};

/**
 *
 * @param { object } salesPaymentDetails        data detail sales payment
 * @param { string } customDataKey              return name key of data
 * @param { string } customValidationKey        return name key of validation
 * @returns
 */
export const generateDataNValidationUpdateInvoiceSalesPaymentFromDetailSPv2 = ({
  salesPaymentDetails,
  customDataKey = 'invoiceData',
  customValidationKey = 'invoiceValidation',
  keyNameInvoiceSalesPayment = DEFAULT_KEY_NAME_SALES_INVOICE_PAYMENT_DETAILS,
}) => {
  if (
    isEmpty(salesPaymentDetails) ||
    typeof salesPaymentDetails !== 'object' ||
    (typeof salesPaymentDetails === 'object' && !salesPaymentDetails[keyNameInvoiceSalesPayment])
  ) {
    return {
      [customDataKey]: initialInvoiceData(),
      [customValidationKey]: initialInvoiceValidation(),
    };
  }

  const invoiceSalesPayments = salesPaymentDetails[keyNameInvoiceSalesPayment];

  const usedID = arrHelp.generateArrWithFunc(invoiceSalesPayments.length, uuidv4);

  /**
   * amount, last user input on add sales payment
   * sales_invoice_amount, total amount that must billed by user, require just for total amount
   * amount_available, total remaining amount that must bill by user
   * amount_available_const maximum input amount user
   *      on update sales payment, amount_available_const result sum from amount_available by amount
   * amount_input from inputting amount by user
   *
   * isFromDetailSalesPayment, is for differentiator about new invoice on autocomplete
   */
  const invoiceData = usedID.map((id, index) => {
    const { amount, amount_available, sales_invoice_amount } = invoiceSalesPayments[index];

    const amount_available_const = Number(amount) + Number(amount_available);

    return {
      ...initialInvoiceData('', id),
      ...invoiceSalesPayments[index],
      amount_input: Number(amount),
      amount: Number(sales_invoice_amount),
      amount_available: Number(amount_available),
      amount_available_const,
      sales_invoice_amount: Number(sales_invoice_amount),
      isFromDetailSalesPayment: true,
    };
  });

  const invoiceValidation = usedID.map((id) => {
    return initialInvoiceValidation('', id);
  });

  return {
    [customDataKey]: invoiceData,
    [customValidationKey]: invoiceValidation,
  };
};

/**
 *
 * @param   { object }      param0  using keys object as params function
 *
 *
 * @param   { array }       currentInvoiceSalesPaymentData          current of sales invoice data on sales payment
 * @param   { array }       currentInvoiceSalesPaymentValidation    current sales invoice validation on sales payment
 * @param   { array }       invoiceList                             after hitting endpoint invoice list based on contact id you can passing on it
 * @param   { string }      customDataKey                           return keyname of data
 * @param   { string }      customValidationKey                     return keyname of validation
 *
 * @returns
 *      object that contained validation and data
 *      that combine from invoice list to current invoice list
 *      validation determined by invoice data sales payment, so dont worry about validation id
 */
export const generateDataNvalidationInvoiceSalesPaymentFromSalesInvoiceList = ({
  invoiceList = [],
  currentInvoiceSalesPaymentData = [],
  currentInvoiceSalesPaymentValidation = [],
  customDataKey = 'invoiceData',
  customValidationKey = 'invoiceValidation',
}) => {
  if (!Array.isArray(invoiceList) || !Array.isArray(currentInvoiceSalesPaymentData)) {
    return {
      [customDataKey]: currentInvoiceSalesPaymentData,
      [customValidationKey]: currentInvoiceSalesPaymentValidation,
    };
  }

  const { invoiceData: invoiceDataGen, invoiceValidation: invoiceValidationGen } =
    generateDataNValidationAddInvoiceFromListSI(
      invoiceList,
      'invoiceData',
      'invoiceValidation',
      true,
    );

  const salesInvoiceExistedID = new Set(
    currentInvoiceSalesPaymentData.map((salesInvoice) => salesInvoice.sales_invoice_id),
  );
  const newInvoiceData = [
    ...currentInvoiceSalesPaymentData,
    ...invoiceDataGen.filter(
      (salesInvoice) => !salesInvoiceExistedID.has(salesInvoice.sales_invoice_id),
    ),
  ];

  // remove unused validation items, following invoice data that unique sales invoice id
  const newInvoiceValidation = arrHelp.combineMultipleDataObjectInArray(
    newInvoiceData,
    currentInvoiceSalesPaymentValidation.concat(invoiceValidationGen),
    'id',
    false,
  );

  return {
    [customDataKey]: newInvoiceData,
    [customValidationKey]: newInvoiceValidation,
  };
};

/**
 *
 * @param { array } dataAddPayment data input payment from form
 * @param { array } dataAddInvoice data input invoice form input user
 * @returns
 *      generalized data sales payment before hitting BE
 */
export const generalizeDataUpdateSalesPayment = (dataUpdatePayment, dataUpdateInvoice) => {
  const { sales_payment_id, sales_payment_no_container } = dataUpdatePayment[0];

  // get string of number sales payment
  const { value: sales_payment_no } = sales_payment_no_container;

  return objHelper.filteringExistedValue(
    {
      sales_payment_id,
      sales_payment_no,
      ...generalizeDataAddSalesPayment(dataUpdatePayment, dataUpdateInvoice),
    },
    [],
    true,
  );
};
