import React from 'react';
import { Stack, TableCell } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import useRepresentationElement from '../../../hooks/useRepresentationElement';
import useValueFormatter from '../../../hooks/useValueFormatter';
import arrHelp from '../../../utils/helpers/array.helpers';

export default function TableCellPrimary({
  index = 0,
  datum = {},
  columns = [],
  afterMultipleNames = false,
  additionalTableCellProps = {},
}) {
  if (!Array.isArray(columns)) return null;

  const { generateRepresentationElement } = useRepresentationElement();
  const { valueFormatterV1 } = useValueFormatter();

  const uniqueIdTableCell = arrHelp.generateArrWithFunc(columns.length, uuidv4);

  return columns.map((column, colIndex) => {
    const {
      name: columnName,
      isUseMultipleNames = false,
      usageMultiple2Dnames = false,
      multipleNames = [],
      multiple2Dnames = [],
      align = 'left',
      substituteNotExistValue = '-',
      formatData = 'common',
      valueFormatter,
      containerValueFormatter,
      emptyValues = [],
      representationDataType = 'typography-element',
      generateLink,
      generateLinkFromCurrentLocation,
      defaultLink,
      representationDataProps,
      selectObjectValueOnListItemProps,
      generateListWithTranslationForSelectItems,
      colSpan = 1,
      translationValue = null,
      translationKeynameProps = null,
      translationValuePropsFormatter = null,
      renderButton = null,
      correspondKeynameAndSign = {},
      getRepresentationElementType,
      ...props
    } = column;

    const valueFormatted = valueFormatterV1({
      value: datum[columnName],
      valueFormatter,
      containerValue: datum,
      containerValueFormatter,
      format: formatData,
      emptyValueSubtitute: substituteNotExistValue,
      ...props,
    });

    let children = [],
      viewValue = generateRepresentationElement({
        value: valueFormatted,
        valueContainer: datum,
        elementType: representationDataType,
        additionalProps: representationDataProps,
        generateLink,
        generateLinkFromCurrentLocation,
        generateSelectListItems: generateListWithTranslationForSelectItems,
        getRepresentationElementType,
        baseUrl: defaultLink,
        renderButton,
      });

    if (afterMultipleNames && !isUseMultipleNames && !usageMultiple2Dnames) {
      return viewValue;
    }

    // multiple table cell
    // for 2d: use rows and columns separate
    // for common multiples: rows only
    if (isUseMultipleNames || usageMultiple2Dnames) {
      const multipleChildrens = (
        <Stack key={'stack-container-col-' + index} direction='column'>
          {isUseMultipleNames ? (
            <TableCellPrimary
              index={'table-cell-primary-col-' + colIndex}
              columns={arrHelp.mappingChildrenKeyOnObject(multipleNames, 'name')}
              afterMultipleNames
              datum={datum}
            />
          ) : (
            multiple2Dnames.map((multiple2D, indexMultiple2D) => {
              return (
                <Stack
                  spacing={1}
                  key={'stack-container-row-' + indexMultiple2D}
                  direction='row'
                  alignItems='end'
                >
                  <TableCellPrimary
                    index={'table-cell-primary-row' + indexMultiple2D}
                    columns={multiple2D}
                    afterMultipleNames
                    datum={datum}
                  />
                </Stack>
              );
            })
          )}
        </Stack>
      );

      children = children.concat(multipleChildrens);
    } else {
      children = viewValue;
    }

    return (
      <TableCell
        key={'table-cell-primary-' + index + (columnName || uniqueIdTableCell[colIndex])}
        align={align}
        colSpan={colSpan}
        {...additionalTableCellProps}
      >
        {children}
      </TableCell>
    );
  });
}
