import * as React from 'react';
function SvgSalesOrderMenu(props) {
  return (
    <svg width={123} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#sales-order-menu_svg__filter0_d_5586_191924)'>
        <path d='M70.312 38.747H31.571v29.36h38.741v-29.36z' fill='#FFD15D' />
        <path d='M72.25 34.553H29.633v8.388h42.615v-8.388z' fill='#F9B54C' />
        <path
          d='M56.753 50.567H45.13c-1.07 0-1.937-.939-1.937-2.097s.868-2.097 1.937-2.097h11.623c1.07 0 1.937.939 1.937 2.097s-.867 2.097-1.937 2.097z'
          fill='#B97850'
        />
        <path
          d='M43.096 53.958h-7.41c-.762 0-1.361.65-1.361 1.475v8.021c0 .826.599 1.475 1.362 1.475h7.409c.763 0 1.362-.649 1.362-1.475v-8.021c0-.767-.6-1.475-1.362-1.475zm.6 9.496c0 .354-.273.649-.6.649h-7.41c-.326 0-.599-.295-.599-.649v-8.021c0-.354.273-.649.6-.649h7.409c.327 0 .6.295.6.649v8.021z'
          fill='#fff'
        />
        <path
          d='M37.866 56.966c-.055-.059-.055-.059-.11-.059-.054 0-.108-.059-.163-.059-.054 0-.109 0-.163.06-.055 0-.11.058-.11.058l-1.252 1.357c-.163.177-.163.413 0 .53.163.178.381.178.49 0l.654-.707v3.48c0 .236.163.413.381.413s.382-.177.382-.413v-3.48l.654.708a.4.4 0 00.272.118c.109 0 .163-.06.272-.118.164-.177.164-.413 0-.531l-1.307-1.357zM41.462 56.966a.4.4 0 00-.273-.118.4.4 0 00-.272.118l-1.253 1.357c-.163.177-.163.413 0 .53.163.178.381.178.49 0l.654-.707v3.48c0 .236.164.413.382.413.217 0 .38-.177.38-.413v-3.48l.655.708a.4.4 0 00.272.118c.109 0 .163-.06.272-.118.164-.177.164-.413 0-.531l-1.307-1.357zM54.483 53.958h-7.41c-.762 0-1.362.65-1.362 1.475v8.021c0 .826.6 1.475 1.362 1.475h7.41c.762 0 1.362-.649 1.362-1.475v-8.021c0-.767-.6-1.475-1.362-1.475zm.654 9.496c0 .354-.273.649-.6.649h-7.464c-.327 0-.599-.295-.599-.649v-8.021c0-.354.273-.649.6-.649h7.409c.327 0 .599.295.599.649v8.021h.054z'
          fill='#fff'
        />
        <path
          d='M52.412 56.2h-3.214c-.218 0-.382.177-.382.413V58.5c0 1.062.709 1.946 1.635 2.123v1.298h-.49c-.218 0-.382.177-.382.413s.164.412.382.412h1.743c.218 0 .381-.176.381-.412s-.163-.413-.381-.413h-.49v-1.298c.926-.177 1.634-1.061 1.634-2.123v-1.887c-.055-.236-.218-.413-.436-.413zm-.381 2.3c0 .767-.545 1.356-1.253 1.356-.709 0-1.253-.59-1.253-1.356v-1.533h2.506V58.5zM65.868 53.958H58.46c-.763 0-1.362.65-1.362 1.475v8.021c0 .826.6 1.475 1.362 1.475h7.41c.762 0 1.362-.649 1.362-1.475v-8.021c0-.767-.654-1.475-1.362-1.475zm.6 9.496c0 .354-.273.649-.6.649H58.46c-.327 0-.6-.295-.6-.649v-8.021c0-.354.273-.649.6-.649h7.41c.326 0 .599.295.599.649v8.021z'
          fill='#fff'
        />
        <path
          d='M62.164 55.669c-1.798 0-3.269 1.592-3.269 3.539 0 .236.163.413.381.413h2.56v2.654c0 .118-.108.236-.217.236s-.218-.118-.218-.236c0-.236-.163-.413-.381-.413s-.382.177-.382.413c0 .59.436 1.061.981 1.061.545 0 .98-.472.98-1.061V59.62h2.561c.218 0 .382-.177.382-.413-.11-1.947-1.58-3.54-3.378-3.54zm-2.506 3.126c.163-1.357 1.253-2.36 2.506-2.36s2.343 1.003 2.506 2.36h-5.012z'
          fill='#fff'
        />
        <path d='M112.927 38.747H74.186v29.36h38.741v-29.36z' fill='#FFD15D' />
        <path d='M113.25 38.148h-39v29.958h39V38.148z' fill='#F9B54C' />
        <path d='M114.865 34.553H72.249v8.388h42.616v-8.388z' fill='#F9B54C' />
        <path d='M115.25 34.553h-43v8.388h43v-8.388z' fill='#F4A200' />
        <path
          d='M99.368 50.567H87.745c-1.07 0-1.937-.939-1.937-2.097s.867-2.097 1.937-2.097h11.623c1.069 0 1.937.939 1.937 2.097s-.868 2.097-1.938 2.097z'
          fill='#935635'
        />
        <path
          d='M85.711 53.959h-7.41c-.762 0-1.361.648-1.361 1.474v8.021c0 .826.6 1.475 1.362 1.475h7.41c.762 0 1.361-.649 1.361-1.475v-8.021c0-.767-.599-1.474-1.362-1.474zm.6 9.495c0 .354-.273.65-.6.65h-7.41c-.326 0-.598-.296-.598-.65v-8.021c0-.354.272-.649.599-.649h7.41c.326 0 .599.295.599.649v8.021z'
          fill='#fff'
        />
        <path
          d='M80.481 56.967c-.054-.06-.054-.06-.108-.06-.055 0-.11-.058-.164-.058-.054 0-.109 0-.163.059-.055 0-.11.059-.11.059l-1.252 1.356c-.164.177-.164.413 0 .53.163.178.381.178.49 0l.654-.707v3.48c0 .236.163.413.381.413s.381-.177.381-.413v-3.48l.654.708a.4.4 0 00.273.118c.109 0 .163-.06.272-.118.163-.177.163-.413 0-.53l-1.308-1.357zM84.077 56.967a.4.4 0 00-.273-.118.4.4 0 00-.272.118l-1.253 1.356c-.164.177-.164.413 0 .53.163.178.381.178.49 0l.654-.707v3.48c0 .236.163.413.381.413s.382-.177.382-.413v-3.48l.653.708a.4.4 0 00.273.118c.109 0 .163-.06.272-.118.164-.177.164-.413 0-.53l-1.307-1.357zM97.097 53.959h-7.409c-.763 0-1.362.648-1.362 1.474v8.021c0 .826.6 1.475 1.362 1.475h7.41c.762 0 1.362-.649 1.362-1.475v-8.021c0-.767-.6-1.474-1.362-1.474zm.654 9.495c0 .354-.272.65-.599.65h-7.464c-.327 0-.6-.296-.6-.65v-8.021c0-.354.273-.649.6-.649h7.41c.326 0 .599.295.599.649v8.021h.054z'
          fill='#fff'
        />
        <path
          d='M95.027 56.2h-3.214c-.218 0-.381.177-.381.413V58.5c0 1.062.708 1.946 1.634 2.123v1.298h-.49c-.218 0-.382.177-.382.413s.164.413.382.413h1.743c.218 0 .381-.177.381-.413s-.163-.413-.38-.413h-.491v-1.298c.926-.177 1.634-1.061 1.634-2.123v-1.887c-.054-.236-.218-.413-.436-.413zm-.381 2.3c0 .767-.545 1.356-1.253 1.356-.708 0-1.253-.59-1.253-1.356v-1.533h2.506V58.5zM108.484 53.959h-7.41c-.762 0-1.362.648-1.362 1.474v8.021c0 .826.6 1.475 1.362 1.475h7.41c.762 0 1.362-.649 1.362-1.475v-8.021c0-.767-.654-1.474-1.362-1.474zm.599 9.495c0 .354-.272.65-.599.65h-7.41c-.326 0-.599-.296-.599-.65v-8.021c0-.354.273-.649.599-.649h7.41c.327 0 .599.295.599.649v8.021z'
          fill='#fff'
        />
        <path
          d='M104.779 55.669c-1.798 0-3.269 1.592-3.269 3.539 0 .236.164.413.382.413h2.56v2.654c0 .118-.109.236-.218.236s-.218-.118-.218-.236c0-.236-.163-.413-.381-.413s-.381.177-.381.413c0 .59.435 1.061.98 1.061.545 0 .981-.471.981-1.061V59.62h2.56c.218 0 .382-.177.382-.413-.109-1.947-1.58-3.54-3.378-3.54zm-2.506 3.126c.163-1.357 1.253-2.36 2.506-2.36s2.343 1.003 2.506 2.36h-5.012z'
          fill='#fff'
        />
        <path d='M91.124 5.194H52.383v29.36h38.741V5.193z' fill='#FFD15D' />
        <path d='M91.124 5.194H71.677v29.36h19.448V5.193z' fill='#F9B54C' />
        <path d='M93.061 1H50.446v8.388H93.06V1z' fill='#F9B54C' />
        <path d='M93.061 1H71.676v8.388h21.385V1z' fill='#F4A200' />
        <path
          d='M77.565 17.014H65.942c-1.07 0-1.937-.939-1.937-2.097s.868-2.097 1.937-2.097h11.623c1.07 0 1.937.939 1.937 2.097s-.867 2.097-1.937 2.097z'
          fill='#B97850'
        />
        <path
          d='M77.565 12.82h-5.89v4.194h5.89c1.07 0 1.937-.939 1.937-2.097s-.868-2.097-1.937-2.097z'
          fill='#935635'
        />
        <path
          d='M63.908 20.405H56.5c-.763 0-1.362.65-1.362 1.475V29.9c0 .826.6 1.475 1.362 1.475h7.41c.762 0 1.361-.65 1.361-1.475V21.88c0-.767-.599-1.475-1.361-1.475zm.6 9.496c0 .354-.273.649-.6.649H56.5c-.327 0-.6-.295-.6-.649V21.88c0-.354.273-.649.6-.649h7.41c.326 0 .599.295.599.649V29.9z'
          fill='#fff'
        />
        <path
          d='M58.679 23.413c-.055-.059-.055-.059-.11-.059-.054 0-.108-.059-.163-.059-.054 0-.109 0-.163.06-.055 0-.11.058-.11.058l-1.252 1.357c-.164.177-.164.413 0 .53.163.178.381.178.49 0l.654-.707v3.48c0 .236.163.413.381.413s.382-.177.382-.413v-3.48l.653.708a.4.4 0 00.273.118c.109 0 .163-.06.272-.118.164-.177.164-.413 0-.531l-1.307-1.357zM62.274 23.413a.4.4 0 00-.272-.118.4.4 0 00-.272.118l-1.253 1.357c-.164.177-.164.413 0 .53.163.178.38.178.49 0l.654-.707v3.48c0 .236.163.413.381.413s.381-.177.381-.413v-3.48l.654.708a.4.4 0 00.273.118c.108 0 .163-.06.272-.118.163-.177.163-.413 0-.531l-1.308-1.357zM75.295 20.405h-7.41c-.762 0-1.361.65-1.361 1.475V29.9c0 .826.6 1.475 1.362 1.475h7.41c.762 0 1.361-.65 1.361-1.475V21.88c0-.767-.599-1.475-1.362-1.475zm.654 9.496c0 .354-.272.649-.6.649h-7.463c-.327 0-.6-.295-.6-.649V21.88c0-.354.273-.649.6-.649h7.41c.326 0 .598.295.598.649V29.9h.055z'
          fill='#fff'
        />
        <path
          d='M73.225 22.647H70.01c-.218 0-.381.177-.381.413v1.887c0 1.062.708 1.946 1.634 2.123v1.298h-.49c-.218 0-.381.177-.381.413s.163.413.381.413h1.743c.218 0 .382-.177.382-.413s-.164-.413-.382-.413h-.49V27.07c.926-.177 1.634-1.061 1.634-2.123V23.06c-.054-.236-.218-.413-.435-.413zm-.382 2.3c0 .767-.545 1.356-1.253 1.356-.708 0-1.253-.59-1.253-1.356v-1.534h2.506v1.534zM86.681 20.405h-7.409c-.763 0-1.362.65-1.362 1.475V29.9c0 .826.6 1.475 1.362 1.475h7.41c.762 0 1.361-.65 1.361-1.475V21.88c0-.767-.653-1.475-1.362-1.475zm.6 9.496c0 .354-.273.649-.6.649h-7.409c-.327 0-.6-.295-.6-.649V21.88c0-.354.273-.649.6-.649h7.41c.326 0 .599.295.599.649V29.9z'
          fill='#fff'
        />
        <path
          d='M82.976 22.116c-1.797 0-3.269 1.592-3.269 3.539 0 .235.164.412.382.412h2.56v2.655c0 .117-.109.235-.218.235-.108 0-.217-.117-.217-.235 0-.236-.164-.413-.382-.413s-.381.177-.381.413c0 .59.436 1.061.98 1.061.545 0 .981-.472.981-1.061v-2.655h2.56c.219 0 .382-.177.382-.412-.109-1.947-1.58-3.54-3.378-3.54zm-2.506 3.126c.164-1.357 1.253-2.36 2.506-2.36s2.343 1.003 2.506 2.36H80.47z'
          fill='#fff'
        />
        <path
          d='M22.133 60.423h2.4v7.71c0 .479-.537.867-1.2.867-.663 0-1.2-.388-1.2-.867v-7.71z'
          fill='#FFC61B'
        />
        <path
          d='M23.253 60.423v8.571c.027.001.053.006.08.006.663 0 1.2-.388 1.2-.867v-7.71h-1.28zM24.456 56.188l2.286-.529 5.423 12.21c.202.456-.146.943-.777 1.09-.631.145-1.307-.105-1.51-.561l-5.422-12.21z'
          fill='#EAA22F'
        />
        <path
          d='M22.21 56.188l-2.286-.529-5.422 12.21c-.203.456.145.943.776 1.09.631.145 1.307-.105 1.51-.561l5.421-12.21z'
          fill='#FFC61B'
        />
        <path d='M43.136 38.028H3.53V59.86h39.606V38.028z' fill='#fff' />
        <path d='M43.136 38.028H23.254V59.86h19.882V38.028z' fill='#D0D1D3' />
        <path
          d='M25.314 36.859h-3.961v-1.43c0-.789.887-1.429 1.98-1.429 1.094 0 1.98.64 1.98 1.43v1.429z'
          fill='#FFC61B'
        />
        <path
          d='M25.314 35.43c0-.79-.887-1.43-1.98-1.43-.027 0-.054.002-.08.003v2.856h2.06v-1.43z'
          fill='#EAA22F'
        />
        <path
          d='M43.576 39.184H3.09c-1.016 0-1.84-.595-1.84-1.329 0-.733.824-1.328 1.84-1.328h40.486c1.017 0 1.84.594 1.84 1.328 0 .734-.824 1.329-1.84 1.329zM43.576 61.073H3.09c-1.016 0-1.84-.595-1.84-1.328 0-.734.824-1.329 1.84-1.329h40.486c1.017 0 1.84.595 1.84 1.329 0 .733-.824 1.328-1.84 1.328z'
          fill='#71E2EF'
        />
        <path
          d='M43.576 36.527H23.253v2.657h20.323c1.017 0 1.84-.595 1.84-1.329s-.823-1.328-1.84-1.328zM43.576 58.416H23.253v2.657h20.323c1.017 0 1.84-.595 1.84-1.328 0-.734-.823-1.329-1.84-1.329z'
          fill='#38C6D9'
        />
        <path
          d='M36.424 45.44l-10.17 5.673-9.947-2.394L8 53.304l.73.688 7.8-4.306 9.952 2.395 10.676-5.955-.734-.686z'
          fill='#59595B'
        />
        <path
          d='M16.53 49.686l-7.8 4.306-.73-.688 8.307-4.585 6.947 1.672v.913l-6.723-1.618z'
          fill='#59595B'
        />
        <path
          d='M26.481 52.08l-3.228-.776v-.913l3.001.722 10.17-5.673.733.686-10.676 5.955z'
          fill='#272525'
        />
        <path
          d='M26.367 50.4c.916 0 1.658.536 1.658 1.197s-.742 1.197-1.658 1.197c-.916 0-1.658-.536-1.658-1.197s.742-1.197 1.658-1.197z'
          fill='#C92F00'
        />
        <path
          d='M16.418 48.005c.916 0 1.658.536 1.658 1.197 0 .662-.742 1.197-1.658 1.197-.916 0-1.658-.535-1.658-1.197 0-.66.742-1.197 1.658-1.197z'
          fill='#FF7F4F'
        />
        <path
          d='M36.79 44.585c.915 0 1.658.536 1.658 1.197s-.743 1.197-1.659 1.197c-.915 0-1.658-.536-1.658-1.197 0-.66.743-1.197 1.658-1.197z'
          fill='#EAA22F'
        />
        <path
          d='M8.365 52.452c.916 0 1.658.535 1.658 1.196 0 .661-.742 1.197-1.658 1.197-.916 0-1.658-.535-1.658-1.197 0-.66.742-1.197 1.658-1.197z'
          fill='#FF314F'
        />
        <path d='M50.25 28.743a8 8 0 110 16 8 8 0 010-16z' fill='#3689C9' />
        <path
          d='M49.02 37.051l-.924-1.108a1.232 1.232 0 00-1.477-.307c.062-.185.185-.308.308-.431.492-.43 1.292-.37 1.723.185l.308.369v1.292h.061zM54.865 36.928l-3.077 3.692c-.061.123-.184.185-.307.247l2.769-3.324c.43-.492.369-1.292-.185-1.723-.43-.369-.984-.369-1.415-.123l.307-.369c.431-.492 1.231-.615 1.724-.185.553.493.615 1.231.184 1.785zM51.48 37.051v-4c0-.677-.553-1.23-1.23-1.23-.184 0-.37.06-.492.122.184-.43.615-.738 1.107-.738.677 0 1.231.554 1.231 1.23v3.878l-.615.738z'
          fill='#1B6FAB'
        />
        <path
          d='M46.373 35.82c.492-.43 1.292-.369 1.723.185l.923 1.108V33.05c0-.677.554-1.23 1.231-1.23s1.23.553 1.23 1.23v4l.924-1.108c.43-.492 1.23-.615 1.723-.184.492.43.615 1.23.184 1.723l-3.076 3.692a1.285 1.285 0 01-.985.492c-.37 0-.739-.184-.923-.43l-3.077-3.693c-.43-.553-.37-1.292.123-1.723z'
          fill='#fff'
        />
        <path
          d='M48.342 36.312c-.43.123-.861.062-1.292-.123a1.359 1.359 0 00-.985 0c.123-.246.308-.492.554-.615.554-.246 1.17-.062 1.539.37l.246.245-.062.123z'
          fill='#DDDFE1'
        />
        <path
          d='M57.02 37.974c-.677 0-1.231-.554-1.231-1.23a5.515 5.515 0 00-5.539-5.54c-.677 0-1.23-.553-1.23-1.23 0-.677.553-1.23 1.23-1.23 4.431 0 8 3.568 8 8 0 .676-.553 1.23-1.23 1.23z'
          fill='#1B6FAB'
        />
        <path
          d='M43.48 35.512c.678 0 1.232.554 1.232 1.231a5.515 5.515 0 005.538 5.539c.677 0 1.23.554 1.23 1.23 0 .677-.553 1.231-1.23 1.231-4.43 0-8-3.57-8-8 0-.677.554-1.23 1.23-1.23z'
          fill='#4C9FD7'
        />
        <path
          d='M51.48 37.051c-.369 0-.615-.246-.615-.615v-4c0-.185-.061-.37-.123-.493.431.185.739.616.739 1.108v4z'
          fill='#DDDFE1'
        />
      </g>
      <defs>
        <filter
          id='sales-order-menu_svg__filter0_d_5586_191924'
          x={0.25}
          y={0}
          width={122}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_191924' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_191924' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgSalesOrderMenu;
