import { FORMAT_CURRENCY_AUTO_COMMA_COLUMN } from '../../../components/table-row/TableRowPrimary/TableRowPrimary';
import {
  DEFAULT_ACTION_MENU_ADD,
  DEFAULT_ACTION_MENU_DETAIL,
} from '../../default/action-menu.default';

/**
 *
 * @param { function } t                    translation function
 * @param { string }   actionMenuType       indicate your menu was on where, you can ignore required when on detail action menu type
 * @returns
 *      header columns for detail product minutes of handover
 */
export const columnsTableDetailProductMinutesOfHandover = (
  t,
  actionMenuType = DEFAULT_ACTION_MENU_ADD,
) => {
  return [
    {
      name: 'product_name',
      label: t('table.header-column.product-name'),
      minWidth: 200,
      isRequired: actionMenuType !== DEFAULT_ACTION_MENU_DETAIL,
    },
    {
      name: 'description',
      label: t('table.header-column.product-description'),
      width: 750,
    },
    {
      name: 'quantity',
      label: t('table.header-column.product-received'),
      width: 200,
      align: 'right',
    },
    {
      name: 'product_unit',
      label: t('table.header-column.product-unit'),
      width: 250,
      isRequired: actionMenuType !== DEFAULT_ACTION_MENU_DETAIL,
    },
  ];
};

/**
 *
 * @param { function } t                    translation function
 * @returns
 *      header columns for detail product minutes of handover
 */
export const columnsTableDetailProductMinutesOfHandoverV2 = (t) => {
  return [
    {
      multipleNames: [
        {
          name: 'product_name',
        },
        {
          name: 'description',
        },
      ],
      isUseMultipleNames: true,
      label: t('table.header-column.product-name'),
      minWidth: 200,
    },
    {
      name: 'quantity',
      label: t('table.header-column.product-received'),
      width: 75,
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'unit_destination',
      label: t('table.header-column.product-unit'),
      width: 50,
    },
  ];
};
