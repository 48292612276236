import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { BASE_TYPES_EXPENSE_ACTION_REGISTERED } from '../../utils/constants/base-types-action-registered.constant';
import { LOCAL_STORAGE_EXPENSE_LIST } from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  SLICE_NAME_EXPENSE_DETAIL,
  SLICE_NAME_EXPENSE_LIST,
  SLICE_NAME_IMPORT_EXPENSE,
  SLICE_NAME_IMPORT_EXPENSE_PROGRESS,
  STATUS_REQUEST_ADD_EXPENSE_FAILED,
  STATUS_REQUEST_ADD_EXPENSE_PENDING,
  STATUS_REQUEST_ADD_EXPENSE_SUCCESS,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_DELETE_EXPENSE_FAILED,
  STATUS_REQUEST_DELETE_EXPENSE_PENDING,
  STATUS_REQUEST_DELETE_EXPENSE_SUCCESS,
  STATUS_REQUEST_DETAIL_EXPENSE_FAILED,
  STATUS_REQUEST_DETAIL_EXPENSE_PENDING,
  STATUS_REQUEST_DETAIL_EXPENSE_SUCCESS,
  STATUS_REQUEST_LIST_EXPENSE_FAILED,
  STATUS_REQUEST_LIST_EXPENSE_PENDING,
  STATUS_REQUEST_LIST_EXPENSE_SUCCESS,
  STATUS_REQUEST_UPDATE_EXPENSE_FAILED,
  STATUS_REQUEST_UPDATE_EXPENSE_PENDING,
  STATUS_REQUEST_UPDATE_EXPENSE_SUCCESS,
} from '../../utils/constants/store.constant';
import { expenseAsyncThunkTypeWithActionProps } from '../../utils/data/mapping/async-thunk-type-with-action-props.mapping';
import { DEFAULT_KEY_NAME_STATUS_REQUEST } from '../../utils/default/object-keyname.default';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import expenseActions from './expense.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  [SLICE_NAME_EXPENSE_LIST]: LocalStorage.get(LOCAL_STORAGE_EXPENSE_LIST),
  [SLICE_NAME_EXPENSE_DETAIL]: null,
  [SLICE_NAME_IMPORT_EXPENSE]: null,
  [SLICE_NAME_IMPORT_EXPENSE_PROGRESS]: null,
};

const expenseSlice = createSlice({
  name: NAME_STORE.EXPENSE,
  initialState,
  reducers: {
    expenseActions,
    clearDataExpenseSlice: (state, action) => {
      const { sliceName, localStorageName } = action.payload;

      if (localStorageName && LocalStorage.get(localStorageName)) {
        LocalStorage.remove(localStorageName);
      }

      return {
        ...state,
        [sliceName]: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(
      initialState,
      {
        [SLICE_NAME_EXPENSE_LIST]: LOCAL_STORAGE_EXPENSE_LIST,
      },
      DEFAULT_KEY_NAME_STATUS_REQUEST,
    ),
  },
  extraReducers: (builder) => {
    // expense list
    builder.addCase(expenseActions.getExpenseList.fulfilled, (state, action) => {
      state[SLICE_NAME_EXPENSE_LIST] = action.payload[SLICE_NAME_EXPENSE_LIST];
      state.statusRequest = STATUS_REQUEST_LIST_EXPENSE_SUCCESS;
    }),
      builder.addCase(expenseActions.getExpenseList.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_EXPENSE_PENDING;
      }),
      builder.addCase(expenseActions.getExpenseList.rejected, (state) => {
        state[SLICE_NAME_EXPENSE_LIST] = null;
        state.statusRequest = STATUS_REQUEST_LIST_EXPENSE_FAILED;
      }),
      // expense list refresh
      builder.addCase(expenseActions.getExpenseListRefresh.fulfilled, (state, action) => {
        state[SLICE_NAME_EXPENSE_LIST] = action.payload[SLICE_NAME_EXPENSE_LIST];
        state.statusRequest = STATUS_REQUEST_LIST_EXPENSE_SUCCESS;
      }),
      builder.addCase(expenseActions.getExpenseListRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_EXPENSE_PENDING;
      }),
      builder.addCase(expenseActions.getExpenseListRefresh.rejected, (state) => {
        state[SLICE_NAME_EXPENSE_LIST] = null;
        state.statusRequest = STATUS_REQUEST_LIST_EXPENSE_FAILED;
      }),
      // expense details
      builder.addCase(expenseActions.getExpenseDetail.fulfilled, (state, action) => {
        state[SLICE_NAME_EXPENSE_DETAIL] = action.payload[SLICE_NAME_EXPENSE_DETAIL];
        state.statusRequest = STATUS_REQUEST_DETAIL_EXPENSE_SUCCESS;
      }),
      builder.addCase(expenseActions.getExpenseDetail.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_EXPENSE_PENDING;
      }),
      builder.addCase(expenseActions.getExpenseDetail.rejected, (state) => {
        state[SLICE_NAME_EXPENSE_DETAIL] = null;
        state.statusRequest = STATUS_REQUEST_DETAIL_EXPENSE_FAILED;
      }),
      // delete expense
      builder.addCase(expenseActions.deleteExpense.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_EXPENSE_SUCCESS;
      }),
      builder.addCase(expenseActions.deleteExpense.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_EXPENSE_PENDING;
      }),
      builder.addCase(expenseActions.deleteExpense.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_EXPENSE_FAILED;
      }),
      // add expense
      builder.addCase(expenseActions.addExpense.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_EXPENSE_SUCCESS;
      }),
      builder.addCase(expenseActions.addExpense.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_EXPENSE_PENDING;
      }),
      builder.addCase(expenseActions.addExpense.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_EXPENSE_FAILED;
      }),
      // update expense
      builder.addCase(expenseActions.updateExpense.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_EXPENSE_SUCCESS;
      }),
      builder.addCase(expenseActions.updateExpense.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_EXPENSE_PENDING;
      }),
      builder.addCase(expenseActions.updateExpense.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_EXPENSE_FAILED;
      }),
      /**
       * expense slice matcher available currently
       * importExpense, getImportExpenseProgress
       */
      sliceReduceHelper.createMatcherEachRequestStatusAction({
        builder,
        baseTypesRegistered: BASE_TYPES_EXPENSE_ACTION_REGISTERED,
        mappingAsyncThunkTypeWithActionProps: expenseAsyncThunkTypeWithActionProps,
      });
  },
});

export const { reducer, actions } = expenseSlice;

export const { clearDataExpenseSlice } = actions;

export default reducer;
