import * as React from 'react';
function SvgAccountCategoryDataMenu(props) {
  return (
    <svg width={97} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#account-category-data-menu_svg__filter0_d_5543_189225)'>
        <path
          d='M47.719 15.704v18.87h-11.02v2.07h11.02v18.869c0 3.008 2.224 5.456 4.96 5.456h14.53V58.9h-14.53c-1.743 0-3.161-1.519-3.161-3.386v-18.87h16.866v-2.07H49.518V15.705c0-1.867 1.418-3.386 3.16-3.386h14.53v-2.07h-14.53c-2.734 0-4.96 2.448-4.96 5.456z'
          fill='#E9EFF4'
        />
      </g>
      <g filter='url(#account-category-data-menu_svg__filter1_d_5543_189225)'>
        <path
          d='M77.812 69c6.455 0 11.688-4.326 11.688-9.661 0-5.336-5.233-9.661-11.688-9.661-6.455 0-11.688 4.325-11.688 9.66 0 5.336 5.233 9.662 11.688 9.662z'
          fill='#FD8469'
        />
        <path
          d='M77.812 49.678c6.455 0 11.688 4.325 11.688 9.66C89.5 64.675 84.267 69 77.812 69V49.678z'
          fill='#FC6F58'
        />
        <path
          d='M77.812 44.661c6.455 0 11.688-4.325 11.688-9.661 0-5.336-5.233-9.661-11.688-9.661-6.455 0-11.688 4.325-11.688 9.661 0 5.336 5.233 9.661 11.688 9.661z'
          fill='#FD8469'
        />
        <path
          d='M77.812 25.339l-.139.003v19.316c.047 0 .093.003.14.003 6.455 0 11.687-4.325 11.687-9.661 0-5.336-5.233-9.661-11.688-9.661z'
          fill='#FC6F58'
        />
        <path
          d='M77.812 20.322c6.455 0 11.688-4.325 11.688-9.66C89.5 5.324 84.267 1 77.812 1c-6.455 0-11.688 4.325-11.688 9.661 0 5.336 5.233 9.661 11.688 9.661z'
          fill='#FD8469'
        />
        <path
          d='M77.812 1C84.267 1 89.5 5.326 89.5 10.661c0 5.336-5.233 9.661-11.688 9.661V1z'
          fill='#FC6F58'
        />
      </g>
      <g filter='url(#account-category-data-menu_svg__filter2_d_5543_189225)'>
        <path d='M36.765 10.607H1.5v43.725h35.265V10.607z' fill='#B97850' />
        <path d='M36.765 10.607H19.132v43.725h17.633V10.607z' fill='#935635' />
        <path d='M34.628 12.264H3.638v40.412h30.99V12.264z' fill='#fff' />
        <path d='M34.628 12.264H19.132v40.412h15.496V12.264z' fill='#E6F3FF' />
        <path d='M27.949 10.607H10.316v3.644H27.95v-3.644z' fill='#734B46' />
        <path d='M27.949 10.607h-8.817v3.644h8.817v-3.644z' fill='#57442F' />
        <path
          d='M10.316 21.539H5.908v3.643h4.408V21.54zM10.316 28.826H5.908v3.644h4.408v-3.644zM10.316 36.113H5.908v3.644h4.408v-3.644zM10.316 43.4H5.908v3.644h4.408v-3.643z'
          fill='#84DBFF'
        />
        <path
          d='M32.357 24.52H14.725v.662h17.632v-.662zM32.357 31.807H14.725v.663h17.632v-.663zM32.357 39.094H14.725v.663h17.632v-.663zM32.357 46.382H14.725v.663h17.632v-.663zM32.357 22.974H14.725v.663h17.632v-.663zM32.357 30.261H14.725v.663h17.632v-.663zM32.357 37.549H14.725v.662h17.632v-.662zM32.357 44.836H14.725v.663h17.632v-.663z'
          fill='#CFDBE6'
        />
      </g>
      <defs>
        <filter
          id='account-category-data-menu_svg__filter0_d_5543_189225'
          x={35.699}
          y={9.248}
          width={38.51}
          height={58.721}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5543_189225' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5543_189225' result='shape' />
        </filter>
        <filter
          id='account-category-data-menu_svg__filter1_d_5543_189225'
          x={65.124}
          y={0}
          width={31.377}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5543_189225' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5543_189225' result='shape' />
        </filter>
        <filter
          id='account-category-data-menu_svg__filter2_d_5543_189225'
          x={0.5}
          y={9.607}
          width={43.265}
          height={51.724}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5543_189225' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5543_189225' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgAccountCategoryDataMenu;
