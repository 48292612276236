export const styles = () => {
  return {
    tableHeadMainContainer: {
      '& th': {
        padding: '16px',
        borderBottom: '0px',
      },
      '& .MuiTableCell-root:last-of-type': {
        verticalAlign: 'bottom',
      },
    },
    tableCellMain: {
      fontSize: '14px',
      fontWeight: '700',
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.6)',
      borderBottom: '0px',
      '& p': {
        margin: '0px',
      },
    },
    autocompletePurchaseStatus: {
      paddingRight: '0px',
      '& .MuiOutlinedInput-input': {
        minWidth: '0px!important',
      },
    },
  };
};
