import * as React from 'react';
function SvgCompanySettingMenu(props) {
  return (
    <svg width={94} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#company-setting-menu_svg__filter0_d_5586_193388)'>
        <path
          d='M81.18 65.206H50.165V10.66c0-2.111 1.515-3.823 3.385-3.823h24.243c1.87 0 3.386 1.711 3.386 3.823v54.546z'
          fill='#273B7A'
        />
        <path
          d='M81.18 10.66c0-2.112-1.517-3.823-3.386-3.823H65.385v58.37H81.18V10.66z'
          fill='#121149'
        />
        <path
          d='M77.044 13.841H54.301v6.42h22.743v-6.42zM77.044 22.402H54.301v6.42h22.743v-6.42zM77.044 39.524H54.301v6.42h22.743v-6.42zM77.044 30.963H54.301v6.42h22.743v-6.42z'
          fill='#C8EBED'
        />
        <path d='M77.045 13.841H65.386v6.42h11.659v-6.42z' fill='#71E2EF' />
        <path d='M65.386 22.402H54.301v6.42h11.085v-6.42z' fill='#C8EBED' />
        <path
          d='M77.045 22.402H65.386v6.42h11.659v-6.42zM77.045 39.524H65.386v6.42h11.659v-6.42z'
          fill='#71E2EF'
        />
        <path
          d='M65.386 39.524H54.301v6.42h11.085v-6.42zM65.386 30.963H54.301v6.42h11.085v-6.42z'
          fill='#C8EBED'
        />
        <path d='M77.045 30.963H65.386v6.42h11.659v-6.42z' fill='#71E2EF' />
        <path
          d='M44.997 65.206H24.32V4.274C24.321 2.466 25.619 1 27.22 1h14.878c1.6 0 2.899 1.466 2.899 3.274v60.932z'
          fill='#273B7A'
        />
        <path
          d='M44.996 4.273C44.996 2.466 43.7 1 42.098 1h-7.554v64.206h10.453V4.273z'
          fill='#121149'
        />
        <path
          d='M65.84 65.206H43.717V22.263c0-1.58 1.134-2.86 2.533-2.86h17.059c1.398 0 2.533 1.28 2.533 2.86v42.943z'
          fill='#FFC61B'
        />
        <path
          d='M65.84 22.263c0-1.58-1.133-2.86-2.532-2.86h-8.95v45.803h11.483V22.263z'
          fill='#EAA22F'
        />
        <path d='M52.581 23.278h-5.686v6.42h5.686v-6.42z' fill='#FF5419' />
        <path d='M62.66 23.278h-5.685v6.42h5.685v-6.42z' fill='#C92F00' />
        <path d='M52.581 32.811h-5.686v6.42h5.686v-6.42z' fill='#FF5419' />
        <path d='M62.66 32.811h-5.685v6.42h5.685v-6.42z' fill='#C92F00' />
        <path d='M52.581 42.345h-5.686v6.42h5.686v-6.42z' fill='#FF5419' />
        <path d='M62.66 42.345h-5.685v6.42h5.685v-6.42z' fill='#C92F00' />
        <path d='M33.034 5.327H26.75v4.78h6.284v-4.78z' fill='#FEE187' />
        <path d='M42.565 5.327h-6.283v4.78h6.283v-4.78z' fill='#FFC61B' />
        <path d='M33.034 12.437H26.75v4.78h6.284v-4.78z' fill='#FEE187' />
        <path d='M42.565 12.437h-6.283v4.78h6.283v-4.78z' fill='#FFC61B' />
        <path d='M33.034 19.546H26.75v4.78h6.284v-4.78z' fill='#FEE187' />
        <path d='M42.565 19.546h-6.283v4.78h6.283v-4.78z' fill='#FFC61B' />
        <path d='M33.034 26.655H26.75v4.78h6.284v-4.78z' fill='#FEE187' />
        <path d='M42.565 26.655h-6.283v4.78h6.283v-4.78z' fill='#FFC61B' />
        <path d='M33.034 33.769H26.75v4.78h6.284v-4.78z' fill='#FEE187' />
        <path d='M42.565 33.769h-6.283v4.78h6.283v-4.78z' fill='#FFC61B' />
        <path d='M33.034 40.878H26.75v4.78h6.284v-4.78z' fill='#FEE187' />
        <path d='M42.565 40.878h-6.283v4.78h6.283v-4.78z' fill='#FFC61B' />
        <path d='M60.465 52.365H49.094v12.841h11.371V52.365z' fill='#C92F00' />
        <path d='M60.465 52.365H54.36v12.841h6.106V52.365z' fill='#930000' />
        <path d='M39.139 52.365h-8.96v12.841h8.96V52.365z' fill='#FFC61B' />
        <path d='M39.14 52.365h-4.595v12.841h4.594V52.365z' fill='#EAA22F' />
        <path
          d='M84.022 69H21.478c-1.57 0-2.843-1.437-2.843-3.21s1.273-3.21 2.843-3.21h62.544c1.57 0 2.843 1.437 2.843 3.21S85.592 69 84.022 69z'
          fill='#FF5419'
        />
        <path
          d='M84.022 62.58H52.808V69h31.214c1.57 0 2.843-1.437 2.843-3.21s-1.273-3.21-2.843-3.21z'
          fill='#C92F00'
        />
        <path
          d='M25.635 59.4v-4.8h-2.702a9.542 9.542 0 00-1.029-2.475l1.913-1.913-3.394-3.394-1.913 1.913a9.544 9.544 0 00-2.475-1.029V45h-4.8v2.702a9.54 9.54 0 00-2.475 1.029l-1.913-1.913-3.394 3.394 1.913 1.913A9.541 9.541 0 004.338 54.6H1.635v4.8h2.702a9.54 9.54 0 001.029 2.475l-1.913 1.913 3.394 3.394L8.76 65.27a9.54 9.54 0 002.475 1.028V69h4.8v-2.702a9.54 9.54 0 002.475-1.029l1.913 1.913 3.394-3.394-1.913-1.913a9.543 9.543 0 001.028-2.475h2.703zm-12 1.2a3.6 3.6 0 110-7.2 3.6 3.6 0 010 7.2z'
          fill='#273B7A'
        />
        <path
          d='M13.635 62.818A5.825 5.825 0 017.817 57a5.825 5.825 0 015.818-5.818A5.825 5.825 0 0119.453 57a5.825 5.825 0 01-5.818 5.818zm0-10.424A4.611 4.611 0 009.029 57a4.611 4.611 0 004.606 4.606A4.611 4.611 0 0018.241 57a4.611 4.611 0 00-4.606-4.606z'
          fill='#FFC61B'
        />
      </g>
      <defs>
        <filter
          id='company-setting-menu_svg__filter0_d_5586_193388'
          x={0.635}
          y={0}
          width={93.23}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_193388' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_193388' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgCompanySettingMenu;
