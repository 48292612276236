import * as React from 'react';
function SvgIconMenuSalesQuotationRequest(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.857 68.571a.429.429 0 11-.857 0 .429.429 0 01.857 0zM6.857 68.143h58.286a.428.428 0 110 .857H6.857a.429.429 0 010-.857zM2.571 68.143h2.143a.428.428 0 110 .857H2.571a.429.429 0 010-.857zM69.429 68.143h-2.143a.428.428 0 100 .857h2.143a.429.429 0 000-.857zM72 68.571a.429.429 0 11-.857 0 .429.429 0 01.857 0zM19.714 22.98c0-.222.09-.435.252-.592a.869.869 0 01.605-.245h2.572v20.081h1.714V18.796c0-.222.09-.435.252-.591a.869.869 0 01.605-.246H57.43c.227 0 .445.089.605.245.161.157.252.37.252.592v29.633H60V18.796c0-.666-.272-1.304-.754-1.774a2.606 2.606 0 00-1.817-.736H25.714c-.681 0-1.335.265-1.817.736a2.483 2.483 0 00-.754 1.774v1.673H20.57c-.681.001-1.335.266-1.817.736A2.483 2.483 0 0018 22.98v17.326h1.714V22.98z'
        fill='#000'
      />
      <path
        d='M52.286 25.49H30.857a.867.867 0 00-.606.245.826.826 0 000 1.183c.16.157.379.245.606.245h21.429a.868.868 0 00.606-.245.827.827 0 000-1.183.868.868 0 00-.606-.245zM52.286 28.837H30.857a.868.868 0 00-.606.245.827.827 0 000 1.183c.16.157.379.245.606.245h21.429a.868.868 0 00.606-.245.826.826 0 000-1.183.868.868 0 00-.606-.245zM30.857 32.184h21.429c.227 0 .445.088.606.245a.827.827 0 010 1.183.868.868 0 01-.606.245H30.857a.868.868 0 01-.606-.245.827.827 0 01-.251-.592c0-.222.09-.434.251-.591a.868.868 0 01.606-.245zM52.286 38.877H30.857a.868.868 0 00-.606.246.827.827 0 000 1.183c.16.157.379.245.606.245h21.429a.868.868 0 00.606-.245.826.826 0 000-1.183.868.868 0 00-.606-.245zM30.857 35.53h21.429c.227 0 .445.089.606.246a.826.826 0 010 1.183.868.868 0 01-.606.245H30.857a.868.868 0 01-.606-.245.827.827 0 010-1.183.868.868 0 01.606-.245z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M62.907 68.143h-.69a.711.711 0 00.488-.19.633.633 0 00.202-.46V51.239c0-.239 0-.388-.121-.453-.039-.047-.215-.215-.569-.215H43.602v-1.285H62.218c.549 0 1.075.206 1.462.572.388.365.606.861.607 1.378v16.257c0 .221-.042.441-.12.65h-1.259z'
        fill='#000'
      />
      <path
        d='M15.142 46.716h1.182a.238.238 0 00.167-.069.231.231 0 00.069-.164c0-.258-.278-.247-.552-.237-.053.002-.106.004-.157.004a1.19 1.19 0 01-.793-.3 1.153 1.153 0 01-.16-1.555c.175-.233.43-.393.717-.451v-.49c0-.123.05-.241.138-.328a.477.477 0 01.669 0 .463.463 0 01.138.329v.466h.472c.126 0 .246.049.334.136a.463.463 0 010 .659.476.476 0 01-.334.136h-1.18a.24.24 0 00-.168.069.231.231 0 00.167.397h.473c.292 0 .575.107.793.3a1.152 1.152 0 01.16 1.555 1.183 1.183 0 01-.717.45v.49c0 .123-.05.242-.138.33a.476.476 0 01-.669 0 .463.463 0 01-.138-.33v-.466h-.473a.476.476 0 01-.334-.136.462.462 0 010-.659.476.476 0 01.334-.136z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.835 47.782c2.126 4.453 8.977 2.949 8.977-1.998 0-5.282-7.46-6.4-9.175-1.556a4.598 4.598 0 00.198 3.554zm.515-2.408c.08-.716.37-1.394.833-1.951a3.783 3.783 0 011.776-1.19 3.832 3.832 0 012.146-.043 3.79 3.79 0 011.825 1.115c.486.538.803 1.203.913 1.916.111.712.01 1.44-.29 2.097a3.744 3.744 0 01-1.404 1.602 3.82 3.82 0 01-2.062.59c-.53 0-1.055-.111-1.54-.325a3.777 3.777 0 01-1.272-.917c-.354-.39-.62-.85-.782-1.348a3.676 3.676 0 01-.157-1.546h.014z'
        fill='#000'
      />
      <path
        d='M35.517 51.317a1.428 1.428 0 00-1.002-.41h-.945c-.376 0-.737.148-1.003.41a1.388 1.388 0 00-.415.988V66.28c0 .37.15.726.415.988.266.262.627.409 1.003.409h.945c.376 0 .736-.147 1.002-.41.266-.261.415-.617.415-.987v-6.522a.462.462 0 00-.335-.445.477.477 0 00-.471.116.462.462 0 00-.139.33v6.52c0 .124-.05.243-.138.33a.476.476 0 01-.334.136h-.945a.476.476 0 01-.334-.136.463.463 0 01-.139-.33V52.306c0-.123.05-.242.139-.33a.476.476 0 01.334-.136h.945c.125 0 .245.05.334.137a.463.463 0 01.138.33v3.26c0 .123.05.242.139.33a.476.476 0 00.668 0 .462.462 0 00.138-.33v-3.26c0-.371-.15-.727-.415-.989z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.845 54.169H27.9c-.376 0-.737.147-1.002.409a1.388 1.388 0 00-.416.988v11.18c0 .37.15.726.416.988.265.262.626.409 1.002.409h.945c.376 0 .736-.147 1.002-.41.266-.261.415-.617.415-.988V55.566c0-.37-.15-.726-.415-.988a1.428 1.428 0 00-1.002-.41zm.472 12.576c0 .124-.05.242-.138.33a.476.476 0 01-.334.136H27.9a.476.476 0 01-.44-.294.463.463 0 01-.033-.172V55.566c0-.124.05-.242.139-.33a.476.476 0 01.334-.136h.945c.125 0 .245.05.334.137a.462.462 0 01.138.329v11.18zM22.23 56.963h.945c.376 0 .736.148 1.002.41s.415.617.415.988v8.384c0 .371-.149.727-.415.989s-.626.409-1.002.409h-.945c-.376 0-.737-.147-1.003-.41a1.388 1.388 0 01-.415-.988v-8.384c0-.37.15-.726.415-.988.266-.262.627-.41 1.003-.41zm1.279 10.112a.463.463 0 00.138-.33v-8.384a.462.462 0 00-.138-.33.476.476 0 00-.334-.136h-.945a.476.476 0 00-.334.136.462.462 0 00-.139.33v8.384c0 .124.05.242.139.33a.476.476 0 00.334.136h.945c.125 0 .245-.049.334-.136zM17.505 59.758h-.945c-.376 0-.736.148-1.002.41a1.387 1.387 0 00-.416.988v5.59c0 .37.15.726.416.988.265.262.626.409 1.002.409h.945c.376 0 .736-.147 1.002-.41.266-.261.415-.617.415-.988v-5.59c0-.37-.149-.725-.415-.987a1.428 1.428 0 00-1.002-.41zm.472 6.987c0 .124-.05.242-.138.33a.476.476 0 01-.334.136h-.945a.476.476 0 01-.334-.136.463.463 0 01-.139-.33v-5.59c0-.123.05-.241.139-.329a.476.476 0 01.334-.136h.945c.125 0 .245.049.334.136a.463.463 0 01.138.33v5.59zM11.835 62.553h-.945c-.376 0-.736.147-1.002.41a1.388 1.388 0 00-.415.988v2.794c0 .371.149.727.415.989s.626.409 1.002.409h.945c.376 0 .736-.147 1.002-.41.266-.261.415-.617.415-.988v-2.794c0-.371-.149-.727-.415-.989a1.428 1.428 0 00-1.002-.409zm.472 4.192a.487.487 0 01-.01.099.463.463 0 01-.128.23.476.476 0 01-.334.137h-.945a.476.476 0 01-.334-.136.463.463 0 01-.138-.33v-2.794c0-.124.05-.242.138-.33a.476.476 0 01.334-.136h.945a.476.476 0 01.437.29l.008.02.005.015a.508.508 0 01.015.058.464.464 0 01.007.083v2.794z'
        fill='#000'
      />
      <path
        d='M34.987 57.895v-.466c0-.123.05-.242.139-.33a.476.476 0 01.668 0 .463.463 0 01.138.33v.466c0 .124-.05.242-.138.33a.476.476 0 01-.668 0 .463.463 0 01-.139-.33zM33.708 43.126a.476.476 0 01.334-.137c1.396 0 1.987-.101 2.228.139.242.24.135.822.135 2.19a.46.46 0 01-.139.33.476.476 0 01-.668 0 .463.463 0 01-.138-.33v-.755l-2.523 2.441c-6.02 5.828-13.707 9.028-23.464 9.028a.476.476 0 01-.335-.137.463.463 0 010-.658.476.476 0 01.335-.137c9.45 0 16.92-3.07 22.802-8.757l2.5-2.422h-.733a.476.476 0 01-.334-.137.463.463 0 010-.658z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M62.161 68.143a.741.741 0 00.5-.19.626.626 0 00.209-.46V51.239c-3.213 3.167-9.913 6.663-15.047 6.663-5.559 0-7.537-1.76-9.252-2.83v-5.786h23.59c.564 0 1.104.206 1.502.572.398.365.622.861.623 1.378v16.257c0 .221-.043.441-.124.65H62.16zm-14.346-11.38c-4.692.068-7.315-1.906-9.244-3.192v-2.985H61.581c-1.878 1.766-7.15 6.098-13.766 6.176z'
        fill='#000'
      />
    </svg>
  );
}
export default SvgIconMenuSalesQuotationRequest;
