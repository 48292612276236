import { configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { REACT_APP_IS_PRODUCTION_ENVIRONMENT } from '../utils/configs/env.config';
import {
  ROOT_STORE_NAME_ATTACHMENT,
  ROOT_STORE_NAME_BILLING,
  ROOT_STORE_NAME_CHART,
  ROOT_STORE_NAME_MINUTES_OF_HANDOVER,
  ROOT_STORE_NAME_NOTIFICATION,
  ROOT_STORE_NAME_STOCK_ADJUSTMENT,
  ROOT_STORE_NAME_TAG,
  ROOT_STORE_NAME_USER_PERMISSION,
} from '../utils/constants/store.constant';

import accountReducer from './account/account.slice';
import applicationReducer from './application/application.slice';
import assetReducer from './asset/asset.slice';
import attachmentReducer from './attachment/attachment.slice';
import authReducer from './auth/auth.slice';
import bankReducer from './bank/bank.slice';
import beginningBalanceReducer from './beginning-balance/beginning-balance.slice';
import billingReducer from './billing/billing.slice';
import cashBankReducer from './cash-bank/cash-bank.slice';
import chartReducer from './chart/chart.slice';
import closingBookReducer from './closing-book/closing-book.slice';
import companyReducer from './company/company.slice';
import contactReducer from './contact/contact.slice';
import emailReducer from './email/email.slice';
import expenseReducer from './expense/expense.slice';
import indoRegionalReducer from './indo-regional/indo-regional.slice';
import journalEntryReducer from './journal-entry/journal-entry.slice';
import managementCompanyReducer from './management-company/management-company.slice';
import managementRolesReducer from './management-role/management-role.slice';
import managementUsersReducer from './management-user/management-user.slice';
import messageReducer from './message/message.slice';
import minutesOfHandoverReducer from './minutes-of-handover/minutes-of-handover.slice';
import notificationReducer from './notification/notification.slice';
import productReducer from './product/product.slice';
import provinceReducer from './province/province.slice';
import purchaseReducer from './purchase/purchase.slice';
import referenceNumberReducer from './reference-number/reference-number.slice';
import regionalReducer from './regional/regional.slice';
import reportReducer from './report/report.slice';
import salesReducer from './sales/sales.slice';
import stockAdjustmentReducer from './stock-adjustment/stock-adjustment.slice';
import stockTransferReducer from './stock-transfer/stock-transfer.slice';
import tagReducer from './tag/tag.slice';
import transactionReducer from './transaction/transaction.slice';
import userReducer from './user/user.slice';
import userPermissionReducer from './user-permission/user-permission.slice';
import warehouseReducer from './warehouse/warehouse.slice';

export const history = createBrowserHistory();

const store = configureStore({
  reducer: {
    router: connectRouter(history),
    application: applicationReducer,
    account: accountReducer,
    auth: authReducer,
    email: emailReducer,
    company: companyReducer,
    user: userReducer,
    product: productReducer,
    managementCompany: managementCompanyReducer,
    managementUsers: managementUsersReducer,
    managementRoles: managementRolesReducer,
    message: messageReducer,
    contact: contactReducer,
    province: provinceReducer,
    indoRegional: indoRegionalReducer,
    regional: regionalReducer,
    journalEntry: journalEntryReducer,
    bank: bankReducer,
    referenceNumber: referenceNumberReducer,
    beginningBalance: beginningBalanceReducer,
    cashBank: cashBankReducer,
    purchase: purchaseReducer,
    sales: salesReducer,
    asset: assetReducer,
    report: reportReducer,
    warehouse: warehouseReducer,
    closingBook: closingBookReducer,
    transaction: transactionReducer,
    [ROOT_STORE_NAME_STOCK_ADJUSTMENT]: stockAdjustmentReducer,
    stockTransfer: stockTransferReducer,
    [ROOT_STORE_NAME_MINUTES_OF_HANDOVER]: minutesOfHandoverReducer,
    [ROOT_STORE_NAME_CHART]: chartReducer,
    expense: expenseReducer,
    [ROOT_STORE_NAME_ATTACHMENT]: attachmentReducer,
    [ROOT_STORE_NAME_NOTIFICATION]: notificationReducer,
    [ROOT_STORE_NAME_USER_PERMISSION]: userPermissionReducer,
    [ROOT_STORE_NAME_BILLING]: billingReducer,
    [ROOT_STORE_NAME_TAG]: tagReducer,
  },
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), routerMiddleware(history)],
  devTools: !REACT_APP_IS_PRODUCTION_ENVIRONMENT,
});

export default store;
