import * as React from 'react';
function SvgIconMenuSalesSalesOrder(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.857 68.571a.429.429 0 11-.857 0 .429.429 0 01.857 0zM6.857 68.143h58.286a.428.428 0 110 .857H6.857a.429.429 0 010-.857zM2.571 68.143h2.143a.428.428 0 110 .857H2.571a.429.429 0 010-.857zM69.429 68.143h-2.143a.428.428 0 100 .857h2.143a.429.429 0 000-.857zM72 68.571a.429.429 0 11-.857 0 .429.429 0 01.857 0z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M50.665 41.307c0 .553-.425 1.003-.948 1.003h-5.834c-.523 0-.948-.45-.948-1.003v-2.65c0-.553.425-1.003.948-1.003h5.834c.523 0 .948.45.948 1.003v2.65zm-1.194-2.39h-5.342v2.13h5.342v-2.13zm2.122 18.068c0-.553.425-1.003.948-1.003h5.834c.523 0 .948.45.948 1.003v2.65c0 .553-.425 1.003-.948 1.003h-5.834c-.522 0-.948-.45-.948-1.003v-2.65zm1.194 2.389h5.342v-2.128h-5.341v2.128z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M64.713 62.456h1.596c.491 0 .89.423.891.943v1.835c0 .52-.4.943-.89.943h-1.405v1.699c0 .62-.477 1.124-1.063 1.124h-1.737c-.586 0-1.062-.504-1.062-1.124v-1.7H40.806l.006-1.263h25.194V63.72h-25.2l.006-1.265h5.39V45.392h-5.396l.006-1.264h14.049V27.08l-.004-.011-.011-.005h-5.382v4.235c.001.174-.041.346-.122.498a.987.987 0 01-.338.37.909.909 0 01-.94.037l-1.264-.686-1.265.686a.919.919 0 01-.439.113.92.92 0 01-.5-.15 1.024 1.024 0 01-.46-.868v-4.235h-5.381c-.009 0-.016.008-.016.017v3.168a.651.651 0 01-.175.447.581.581 0 01-.422.185.581.581 0 01-.422-.185.651.651 0 01-.175-.447v-3.168c0-.707.542-1.281 1.21-1.281h16.09c.668 0 1.21.574 1.21 1.28v17.048h7.449c.667 0 1.21.574 1.21 1.28v11.727c0 .349-.268.632-.598.632-.33 0-.597-.283-.597-.633V45.409c0-.009-.007-.016-.015-.016h-5.381v4.235c0 .174-.042.346-.122.498a.987.987 0 01-.338.37.918.918 0 01-.94.037l-1.265-.686-1.264.686a.91.91 0 01-.94-.037 1.024 1.024 0 01-.46-.868v-4.235h-5.397v17.063H63.52v-2.45c0-.348.267-.631.597-.631.33 0 .597.283.597.632v2.45zM38.74 38.624V33.12a.651.651 0 00-.175-.447.581.581 0 00-.422-.185.581.581 0 00-.422.185.651.651 0 00-.175.447v5.504h1.194zm9.531-7.729l-1.032-.56a.921.921 0 00-.877 0l-1.031.56v-3.831h2.94v3.831zm5.718 14.497h2.94v3.831l-1.031-.56a.92.92 0 00-.877 0l-1.032.56v-3.831zm9.722 22.484h-1.473v-1.7h1.473v1.7z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40.812 55.982h.247c.522 0 .948.45.948 1.003v2.65c0 .553-.426 1.003-.948 1.003h-.247V55.982z'
        fill='#000'
      />
      <path
        d='M39.612 45.392v3.831l-1.032-.56a.928.928 0 00-.18-.074v1.397l1.006.546a.92.92 0 00.44.113.92.92 0 00.5-.15.988.988 0 00.338-.37c.08-.152.123-.324.122-.498v-4.235l.006-1.264H38.74v-5.504h-1.194V40.8h1.194v4.592h.873zM40.812 57.246v-1.264H38.4v1.264h2.412zM40.812 60.638v-1.264H38.4v1.264h2.412zM40.806 63.72l.006-1.265H38.4v1.265h2.406zM40.806 66.177l.006-1.264H38.4v1.264h2.406zM24.637 24h2.25v10.79l1.463-1.453 1.586 1.59-3.375 3.375c-.21.21-.496.323-.793.323-.297 0-.582-.114-.793-.323L21.6 34.927l1.586-1.59 1.451 1.454V24zM15.742 46.805h1.182a.238.238 0 00.167-.069.231.231 0 00.069-.164c0-.258-.278-.247-.552-.237-.053.002-.106.004-.157.004a1.19 1.19 0 01-.793-.3 1.152 1.152 0 01-.16-1.555c.175-.233.43-.393.717-.45v-.49c0-.123.05-.242.138-.33a.476.476 0 01.668 0 .462.462 0 01.139.33v.466h.472c.126 0 .246.049.334.136a.462.462 0 010 .659.476.476 0 01-.334.136h-1.18a.236.236 0 00-.237.233c0 .062.025.121.07.165a.238.238 0 00.166.068h.473c.293 0 .575.107.793.3a1.152 1.152 0 01.16 1.555 1.183 1.183 0 01-.717.451v.49c0 .123-.05.241-.139.328a.476.476 0 01-.668 0 .463.463 0 01-.138-.329v-.466h-.473a.476.476 0 01-.334-.136.463.463 0 010-.659.476.476 0 01.334-.136z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.435 47.871c2.126 4.454 8.977 2.949 8.977-1.998 0-5.282-7.46-6.4-9.175-1.556a4.597 4.597 0 00.198 3.554zm.515-2.408c.08-.716.37-1.394.833-1.95a3.782 3.782 0 011.776-1.19 3.833 3.833 0 012.146-.044 3.79 3.79 0 011.825 1.115c.486.538.803 1.204.913 1.916.111.712.01 1.44-.29 2.097a3.743 3.743 0 01-1.404 1.602 3.82 3.82 0 01-2.062.59c-.53 0-1.055-.111-1.54-.325a3.779 3.779 0 01-1.272-.917c-.354-.39-.62-.85-.782-1.348a3.677 3.677 0 01-.157-1.546h.014z'
        fill='#000'
      />
      <path
        d='M36.117 51.406a1.428 1.428 0 00-1.002-.409h-.945c-.376 0-.737.147-1.003.41a1.388 1.388 0 00-.415.987V66.37c0 .37.15.726.415.988.266.262.627.41 1.003.41h.945c.376 0 .736-.148 1.002-.41s.415-.618.415-.988v-6.522a.463.463 0 00-.334-.445.478.478 0 00-.472.116.463.463 0 00-.139.33v6.52c0 .124-.05.243-.138.33a.476.476 0 01-.334.137h-.945a.476.476 0 01-.334-.137.462.462 0 01-.139-.33V52.395c0-.123.05-.242.139-.329a.476.476 0 01.334-.136h.945c.125 0 .245.049.334.136a.462.462 0 01.138.33v3.26c0 .124.05.242.139.33a.476.476 0 00.668 0 .463.463 0 00.138-.33v-3.26c0-.371-.15-.727-.415-.989z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.445 54.258H28.5c-.376 0-.737.147-1.003.409a1.387 1.387 0 00-.415.988v11.18c0 .37.15.726.415.988.266.262.627.409 1.003.409h.945c.376 0 .736-.147 1.002-.41.266-.261.415-.617.415-.987v-11.18c0-.37-.15-.726-.415-.988a1.428 1.428 0 00-1.002-.41zm.472 12.577c0 .123-.05.242-.138.329a.476.476 0 01-.334.136H28.5a.476.476 0 01-.44-.294.466.466 0 01-.033-.171v-11.18c0-.123.05-.242.139-.33a.476.476 0 01.334-.136h.945c.125 0 .245.05.334.137a.463.463 0 01.138.33v11.179zM22.83 57.053h.945c.376 0 .736.147 1.002.409s.415.617.415.988v8.385c0 .37-.149.726-.415.988s-.626.409-1.002.409h-.945c-.376 0-.736-.147-1.002-.41a1.388 1.388 0 01-.416-.987V58.45c0-.37.15-.726.416-.988.265-.262.626-.41 1.002-.41zm1.279 10.11a.462.462 0 00.138-.328V58.45a.463.463 0 00-.138-.33.475.475 0 00-.334-.136h-.945a.476.476 0 00-.334.137.463.463 0 00-.139.329v8.385c0 .123.05.242.139.329a.476.476 0 00.334.136h.945c.125 0 .245-.049.334-.136zM18.105 59.847h-.945c-.376 0-.736.148-1.002.41a1.388 1.388 0 00-.416.988v5.59c0 .37.15.726.416.988.265.262.626.409 1.002.409h.945c.376 0 .736-.147 1.002-.41.266-.261.415-.617.415-.987v-5.59c0-.37-.149-.726-.415-.988a1.428 1.428 0 00-1.002-.41zm.472 6.988c0 .123-.05.242-.138.329a.476.476 0 01-.334.136h-.945a.476.476 0 01-.334-.136.462.462 0 01-.139-.33v-5.59c0-.123.05-.241.139-.328a.476.476 0 01.334-.137h.945c.125 0 .245.05.334.136a.462.462 0 01.138.33v5.59zM12.435 62.642h-.945c-.376 0-.736.147-1.002.41a1.387 1.387 0 00-.415.988v2.795c0 .37.149.726.415.988s.626.409 1.002.409h.945c.376 0 .736-.147 1.002-.41.266-.261.415-.617.415-.987V64.04c0-.37-.149-.726-.415-.988a1.428 1.428 0 00-1.002-.41zm.473 4.193a.487.487 0 01-.011.098.466.466 0 01-.128.23.476.476 0 01-.334.137h-.945a.476.476 0 01-.334-.136.462.462 0 01-.139-.33V64.04c0-.124.05-.242.139-.33a.476.476 0 01.334-.136h.945a.476.476 0 01.437.29l.008.02.005.015a.595.595 0 01.015.058.462.462 0 01.008.083v2.795z'
        fill='#000'
      />
      <path
        d='M35.587 57.984v-.466c0-.123.05-.242.139-.329a.476.476 0 01.668 0 .462.462 0 01.138.33v.465c0 .124-.05.242-.138.33a.476.476 0 01-.668 0 .462.462 0 01-.139-.33zM34.308 43.215a.476.476 0 01.334-.137c1.396 0 1.987-.101 2.228.14.242.24.135.822.135 2.19a.461.461 0 01-.139.329.476.476 0 01-.668 0 .463.463 0 01-.138-.33v-.754l-2.523 2.44c-6.02 5.828-13.707 9.028-23.465 9.028a.476.476 0 01-.334-.137.463.463 0 010-.658.476.476 0 01.335-.137c9.45 0 16.92-3.07 22.802-8.757l2.5-2.422h-.733a.476.476 0 01-.334-.137.463.463 0 010-.658z'
        fill='#000'
      />
    </svg>
  );
}
export default SvgIconMenuSalesSalesOrder;
