import axios from 'axios';

import { REACT_APP_API_URL_BEGINNING_BALANCE } from '../../../utils/configs/api.config';
import { authHeader } from '../../../utils/helpers/auth-header.helpers';
import objHelper from '../../../utils/helpers/object.helper';

// get beginning balance data for list
// from account list endpoint
const getListBeginningBalance = async () => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  return await axios({
    method: 'GET',
    url: REACT_APP_API_URL_BEGINNING_BALANCE,
    headers: {
      Authorization,
    },
    params: {
      is_paginate: 0,
    },
  });
};

// create beginning balance
// idk this is update or create
const createBeginningBalance = async (dataCreateBeginningBalance) => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  dataCreateBeginningBalance = objHelper.filterKeyObj(
    dataCreateBeginningBalance,
    [],
    ['account', 'transaction_description', 'beginning_balance'],
  );

  dataCreateBeginningBalance.beginning_balance = dataCreateBeginningBalance.beginning_balance.map(
    (dataAccount) => {
      return objHelper.filterKeyObj(
        dataAccount,
        [],
        [
          'account_id',
          'account_category_id',
          'category_name',
          'account_name',
          'account_code',
          'account_balance',
          'balance',
          'account_normal_balance',
          'account_description',
        ],
      );
    },
  );

  return await axios({
    method: 'POST',
    url: REACT_APP_API_URL_BEGINNING_BALANCE,
    headers: {
      Authorization,
    },
    data: {
      ...dataCreateBeginningBalance,
    },
  });
};

const beginningBalanceServices = {
  getListBeginningBalance,
  createBeginningBalance,
};

export default beginningBalanceServices;
