import { isEmpty, omit, pick } from 'lodash';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import { DEFAULT_DISCOUNT_PERCENTAGE_TYPE } from '../../default/discount-type.default';
import { DEFAULT_COMPARISON_VALUE_NUMBER } from '../../default/number.default';
import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIOD,
  DEFAULT_KEY_NAME_DELIVERY_DATE_API_REF,
  DEFAULT_KEY_NAME_FILE_PROPERTIES,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_PRODUCT_API,
  DEFAULT_KEY_NAME_PRODUCT_SALES_DELIVEY_DETAILS,
  DEFAULT_KEY_NAME_SALES_ORDER_PRODUCT_API_REF,
  DEFAULT_KEY_NAME_UUID_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';
import arrHelp from '../../helpers/array.helpers';
import formatHelp from '../../helpers/format.helpers';
import formulaHelpers from '../../helpers/formula.helpers';
import objHelper from '../../helpers/object.helper';
import {
  DEFAULT_CALCULATION_NUMBER,
  DEFAULT_EMPTY_CALCULATIONS_NUMBER,
} from '../default/number.default';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import {
  allowKeysDataProductSalesInvoice,
  allowKeysDataSalesInvoice,
  initialDataProduct,
  initialDataSalesInvoice,
  initialValidationProduct,
  initialValidationSalesInvoice,
  initialValidationSalesInvoiceOnModulIDmodulManualType,
  modulDeliveryType,
  modulOrderType,
  productSalesInvoiceCalculationNumberKeyNames,
  relationalAdditionalCostValueNaccountForSalesInvoice,
  salesInvoiceCalculationNumberKeyNames,
} from './initial-data-sales-invoice';

/**
 *
 * @param { number } amount, amount generate data and validation product from sales invoice
 * @param { string } customDataKey, name key return for data
 * @param { string } customValidationKey, name key return for validation
 * @returns
 *      generated data and validation for product from sales invoice
 */
export const generateDataNValidationAddProductSI = (
  amount = 2,
  customDataKey = 'addProductData',
  customValidationKey = 'addProductValidation',
) => {
  const usedID = arrHelp.generateArrWithFunc(amount, uuidV4);

  const addProductData = usedID.map((id) => {
    return initialDataProduct('', id);
  });

  const addProductValidation = usedID.map((id) => {
    return initialValidationProduct('', id);
  });

  return {
    [customDataKey]: addProductData,
    [customValidationKey]: addProductValidation,
  };
};

/**
 *
 * @param { number } amount, amount generate data and validation product from sales invoice
 * @param { string } customDataKey, name key return for data
 * @param { string } customValidationKey, name key return for validation
 * @returns
 *      generated data and validation for product from sales invoice
 */
export const generateDataNValidationAddProductSIv2 = ({
  amount = 2,
  customDataKey = 'addProductData',
  customValidationKey = 'addProductValidation',
}) => {
  const usedID = arrHelp.generateArrWithFunc(amount, uuidV4);

  const addProductData = usedID.map((id) => {
    return initialDataProduct('', id);
  });

  const addProductValidation = usedID.map((id) => {
    return initialValidationProduct('', id);
  });

  return {
    [customDataKey]: addProductData,
    [customValidationKey]: addProductValidation,
  };
};

/**
 *
 * @param { number } amount, amount data generate
 * @param { string } customDataKey, name key return for data
 * @param { string } customValidationKey, name key return for validation
 * @returns
 *     generated data and validation for sales invoice
 */
export const generateDataNValidationAddSalesInvoice = (
  amount = 1,
  customDataKey = 'addSalesInvoiceData',
  customValidationKey = 'addSalesInvoiceValidation',
) => {
  const usedID = arrHelp.generateArrWithFunc(amount, uuidV4);

  const addSalesInvoiceData = usedID.map((id) => {
    return initialDataSalesInvoice('', id);
  });

  const addSalesInvoiceValidation = usedID.map((id) => {
    return initialValidationSalesInvoice('', id);
  });

  return {
    [customDataKey]: addSalesInvoiceData,
    [customValidationKey]: addSalesInvoiceValidation,
  };
};

/**
 *
 * @param { number } amount, amount data generate
 * @param { object } companyUserData        data user company
 * @param { string } customDataKey, name key return for data
 * @param { string } customValidationKey, name key return for validation
 * @returns
 *     generated data and validation for sales invoice
 */
// export const generateDataNValidationAddSalesInvoiceV2 = ({
//     amount = 1,
//     companyUserData= {},
//     customDataKey = 'addSalesInvoiceData',
//     customValidationKey = 'addSalesInvoiceValidation',
// }) => {
//     const usedID = arrHelp.generateArrWithFunc( amount, uuidV4 )

//     const addSalesInvoiceData = usedID.map( id => {
//         return {
//             ...initialDataSalesInvoice( '', id ),
//             accounting_period: companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF],

//         }
//     })

//     const addSalesInvoiceValidation = usedID.map( id => {
//         return initialValidationSalesInvoice( '', id )
//     })

//     return {
//         [ customDataKey ]: addSalesInvoiceData,
//         [ customValidationKey ]: addSalesInvoiceValidation,
//     }
// }

/**
 *
 * @param { number } amount, amount data generate
 * @param { string } customDataKey, name key return for data
 * @param { string } customValidationKey, name key return for validation
 * @returns
 *     generated data and validation for sales invoice
 */
export const generateDataNValidationAddSalesInvoiceV2 = ({
  amount = 1,
  companyUserData = {},
  customDataKey = 'addSalesInvoiceData',
  customValidationKey = 'addSalesInvoiceValidation',
}) => {
  const usedID = arrHelp.generateArrWithFunc(amount, uuidV4);

  const addSalesInvoiceData = usedID.map((id) => {
    return {
      ...initialDataSalesInvoice('', id),
      accounting_period: !isEmpty(companyUserData) ? companyUserData.accounting_periode : null,
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
    };
  });

  const addSalesInvoiceValidation = usedID.map((id) => {
    return {
      ...initialValidationSalesInvoice('', id),
      ...initialValidationSalesInvoiceOnModulIDmodulManualType,
    };
  });

  return {
    [customDataKey]: addSalesInvoiceData,
    [customValidationKey]: addSalesInvoiceValidation,
  };
};

/**
 *
 * @param { object } salesDeliveryDetails   data from sales delivery details
 * @param { string } customDataKey          return key of data sales invoice
 * @param { string } customValidationKey    return key of validation sales invoice
 * @returns
 *      generated data and validation for sales invoice from sales delivery details
 */
export const generateDataNValidationSalesInvoiceFromSalesDeliveryDetails = (
  salesDeliveryDetails,
  customDataKey = 'salesInvoiceData',
  customValidationKey = 'salesInvoiceValidation',
) => {
  if (isEmpty(salesDeliveryDetails)) {
    return generateDataNValidationAddSalesInvoice(1, customDataKey, customValidationKey);
  }

  salesDeliveryDetails = objHelper.changeFormatValue(
    salesDeliveryDetails,
    salesInvoiceCalculationNumberKeyNames,
    [(value) => Number(value) || ''],
  );

  const usedID = arrHelp.generateArrWithFunc(1, uuidV4);

  const {
    sales_delivery_id,
    sales_delivery_no,
    contact_code,
    customer_id: contact_id,
    customer_name: contact_name,
    customer_address,
    attachment,
  } = salesDeliveryDetails;

  const uuidAttachment =
    Array.isArray(attachment) && attachment.length
      ? attachment[0][DEFAULT_KEY_NAME_FILE_PROPERTIES][DEFAULT_KEY_NAME_UUID_API_REF] || ''
      : '';

  // some variable is inherited from initial data, so this assign variable follow inherit from
  // for example on modul_id and modul_id_container, where assigned from sales_order_id and sales_order_no
  const salesInvoiceData = usedID.map((id) => {
    return {
      ...initialDataSalesInvoice('', id),
      ...salesDeliveryDetails,
      [DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF]: uuidAttachment,
      sales_agent_id_container: {
        contact_id: salesDeliveryDetails.sales_agent_id || '',
        contact_name: salesDeliveryDetails.sales_agent_name || '',
        contact_code: salesDeliveryDetails.sales_agent_code || '',
      },
      warehouse_id_container: {
        warehouse_id: salesDeliveryDetails.warehouse_id || '',
        warehouse_name: salesDeliveryDetails.warehouse_name || '',
      },
      amount_const: Number(salesDeliveryDetails.amount) || 0,
      customer_id_container: {
        contact_id,
        contact_code,
        contact_name,
        customer_address,
      },
      modul_id: sales_delivery_id,
      modul_id_container: {
        sales_delivery_id,
        transaction_no: sales_delivery_no,
      },
      modul_type: modulDeliveryType,
      description: '',
    };
  });

  const salesInvoiceValidation = usedID.map((id) => {
    return initialValidationSalesInvoice('', id);
  });

  return {
    [customDataKey]: salesInvoiceData,
    [customValidationKey]: salesInvoiceValidation,
  };
};

/**
 *
 * @param { object } salesDeliveryDetails   data from sales delivery details
 * @param { string } customDataKey          return key of data sales invoice
 * @param { string } customValidationKey    return key of validation sales invoice
 * @returns
 *      generated data and validation for sales invoice from sales delivery details
 */
export const generateDataNValidationSalesInvoiceFromSalesDeliveryDetailsV2 = ({
  salesDeliveryDetails,
  currentSalesInvoiceData = [],
  companyUserData = {},
  customDataKey = 'salesInvoiceData',
  customValidationKey = 'salesInvoiceValidation',
}) => {
  if (isEmpty(salesDeliveryDetails) && isEmpty(currentSalesInvoiceData)) {
    return generateDataNValidationAddSalesInvoice(1, customDataKey, customValidationKey);
  } else if (!isEmpty(currentSalesInvoiceData) && isEmpty(salesDeliveryDetails)) {
    const generatedDataNvalidationSalesInvoice = generateDataNValidationAddSalesInvoice(
      1,
      customDataKey,
      customValidationKey,
    );

    const { reference_number, reference_number_container } = currentSalesInvoiceData[0];

    const newSalesInvoiceData = [
      {
        ...generatedDataNvalidationSalesInvoice[customDataKey][0],
        reference_number,
        reference_number_container,
        customer_address: '',
      },
    ];

    return {
      [customDataKey]: newSalesInvoiceData,
      [customValidationKey]: generatedDataNvalidationSalesInvoice[customValidationKey],
    };
  }

  salesDeliveryDetails = objHelper.changeFormatValue(
    salesDeliveryDetails,
    salesInvoiceCalculationNumberKeyNames,
    [(value) => Number(value) || ''],
  );

  let revertCurrentSalesInvoiceData = {};

  if (!isEmpty(currentSalesInvoiceData) && Array.isArray(currentSalesInvoiceData)) {
    const {
      reference_number,
      reference_number_container,
      accounting_period,
      uuid_attachment,
      attachment,
    } = currentSalesInvoiceData[0];

    revertCurrentSalesInvoiceData = {
      reference_number,
      reference_number_container,
      accounting_period,
      uuid_attachment,
      attachment,
    };
  }

  const usedID = arrHelp.generateArrWithFunc(1, uuidV4);

  const {
    sales_delivery_id,
    sales_delivery_no,
    contact_code,
    customer_id: contact_id,
    customer_name: contact_name,
    customer_address,
    transaction_date,
    expired_date,
    discount_type = DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
  } = salesDeliveryDetails;

  const amountConstMapping = salesDeliveryDetails[
    DEFAULT_KEY_NAME_PRODUCT_SALES_DELIVEY_DETAILS
  ].map((curr) => {
    const { quantity_available, price } = curr;

    return {
      ...curr,
      amount: Number(quantity_available) * Number(price),
    };
  });

  const { amount_const, discount_nominal, discount_percentage } =
    formulaHelpers.calculateDiscountAndTotalOfChildrenData({
      parentData: {
        ...salesDeliveryDetails,
        discount_type: discount_type || DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
      },
      childrenData: amountConstMapping,
    });

  // some variable is inherited from initial data, so this assign variable follow inherit from
  // for example on modul_id and modul_id_container, where assigned from sales_order_id and sales_order_no
  const salesInvoiceData = usedID.map((id) => {
    return {
      ...initialDataSalesInvoice('', id),
      ...salesDeliveryDetails,
      ...revertCurrentSalesInvoiceData,
      discount_type: discount_type || DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
      sales_agent_id_container: {
        contact_id: salesDeliveryDetails.sales_agent_id || '',
        contact_name: salesDeliveryDetails.sales_agent_name || '',
        contact_code: salesDeliveryDetails.sales_agent_code || '',
      },
      warehouse_id_container: {
        warehouse_id: salesDeliveryDetails.warehouse_id || '',
        warehouse_name: salesDeliveryDetails.warehouse_name || '',
      },
      accounting_period: isEmpty(companyUserData)
        ? null
        : companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIOD],
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
          documentData: salesDeliveryDetails,
          keynameTransactionDocumentData: DEFAULT_KEY_NAME_DELIVERY_DATE_API_REF,
        }),
      customer_id_container: {
        contact_id,
        contact_code,
        contact_name,
        customer_address,
      },
      modul_id: sales_delivery_id,
      modul_id_container: {
        sales_delivery_id,
        transaction_no: sales_delivery_no,
      },
      modul_type: modulDeliveryType,
      transaction_date: transaction_date ? new Date(transaction_date) : moment(),
      expired_date: expired_date ? new Date(expired_date) : null,
      description: '',
      amount_const,
      discount_nominal,
      discount_percentage,
    };
  });

  const salesInvoiceValidation = usedID.map((id) => {
    return initialValidationSalesInvoice('', id);
  });

  return {
    [customDataKey]: salesInvoiceData,
    [customValidationKey]: salesInvoiceValidation,
  };
};

/**
 *
 * @param { object } salesDeliveryDetails   data from sales delivery details
 * @param { string } customDataKey          return key of data sales invoice
 * @param { string } customValidationKey    return key of validation sales invoice
 * @returns
 *      generated data and validation for product in sales invoice from sales delivery details
 */
export const generateDataNValidationProductSalesInvoiceFromSalesDeliveryDetails = (
  salesDeliveryDetails,
  customDataKey = 'productSalesInvoiceData',
  customValidationKey = 'productSalesInvoiceValidation',
) => {
  if (isEmpty(salesDeliveryDetails)) {
    return generateDataNValidationAddProductSI(2, customDataKey, customValidationKey);
  }

  const usedID = arrHelp.generateArrWithFunc(salesDeliveryDetails.product.length, uuidV4);

  const productSalesInvoiceData = usedID.map((id, index) => {
    const {
      product_id,
      product_name,
      product_code,
      quantity,
      quantity_available,
      quantity_origin,
      price,
      product_unit,
      unit_destination,
      unit_origin,
    } = salesDeliveryDetails.product[index];

    let quantity_available_const = formatHelp.reverseCurrencyFormatWithRegex(quantity_available);
    let price_amount = Number(price) * Number(quantity);

    if (Number.isNaN(quantity_available_const)) {
      quantity_available_const = DEFAULT_CALCULATION_NUMBER;
    }

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    return {
      ...initialDataProduct('', id),
      ...salesDeliveryDetails.product[index],
      product_id_container: {
        product_id,
        product_code,
        product_name,
        ...salesDeliveryDetails.product[index],
      },
      price_amount: formatHelp.currencyFormatWithRegex(price_amount),
      quantity: quantity_available,
      quantity_available: DEFAULT_CALCULATION_NUMBER,
      quantity_available_const,
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantity_available_const),
        value_comparison: quantityComparison,
      },
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      quantity_available_with_unit: `${Number(quantity_available_const)} ${
        unit_origin || product_unit
      }`,
    };
  });

  const productSalesInvoiceValidation = usedID.map((id) => {
    return initialValidationProduct('', id);
  });

  return {
    [customDataKey]: productSalesInvoiceData,
    [customValidationKey]: productSalesInvoiceValidation,
  };
};

/**
 *
 * @param { object } salesDeliveryDetails   data from sales delivery details
 * @param { string } customDataKey          return key of data sales invoice
 * @param { string } customValidationKey    return key of validation sales invoice
 * @returns
 *      generated data and validation for product in sales invoice from sales delivery details
 */
export const generateDataNValidationProductSalesInvoiceFromSalesDeliveryDetailsV2 = ({
  salesDeliveryDetails,
  customDataKey = 'productSalesInvoiceData',
  customValidationKey = 'productSalesInvoiceValidation',
}) => {
  if (
    isEmpty(salesDeliveryDetails) ||
    isEmpty(salesDeliveryDetails[DEFAULT_KEY_NAME_PRODUCT_API])
  ) {
    return generateDataNValidationAddProductSI(2, customDataKey, customValidationKey);
  }

  const usedID = arrHelp.generateArrWithFunc(salesDeliveryDetails.product.length, uuidV4);

  const productSalesInvoiceData = usedID.map((id, index) => {
    let selectedProductSalesDelivery = salesDeliveryDetails.product[index];
    if (!isEmpty(selectedProductSalesDelivery)) {
      selectedProductSalesDelivery = objHelper.changeFormatValue(
        selectedProductSalesDelivery,
        productSalesInvoiceCalculationNumberKeyNames,
        [(value) => Number(value) || ''],
      );
    }

    const {
      product_id,
      product_name,
      product_code,
      quantity,
      quantity_available,
      quantity_origin,
      price,
      product_unit,
      unit_destination,
      unit_origin,
    } = selectedProductSalesDelivery;

    let quantity_available_const = Number(quantity_available),
      price_amount = Number(price) * Number(quantity_available),
      amount = Number(price) * Number(quantity_available);

    if (Number.isNaN(quantity_available_const)) {
      quantity_available_const = DEFAULT_CALCULATION_NUMBER;
    }

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    return {
      ...initialDataProduct('', id),
      ...selectedProductSalesDelivery,
      product_id_container: {
        product_id,
        product_code,
        product_name,
        ...selectedProductSalesDelivery,
      },
      price_amount: Number(price_amount),
      quantity: Number(quantity),
      quantity_available: DEFAULT_CALCULATION_NUMBER,
      quantity_available_const,
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantity_available_const),
        value_comparison: quantityComparison,
      },
      amount,
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      quantity_available_with_unit: `${Number(quantity_origin)} ${unit_origin || product_unit}`,
    };
  });

  const productSalesInvoiceValidation = usedID.map((id) => {
    return initialValidationProduct('', id);
  });

  return {
    [customDataKey]: productSalesInvoiceData,
    [customValidationKey]: productSalesInvoiceValidation,
  };
};

/**
 *
 * @param { object } salesOrderDetails      data from sales order details
 * @param { string } customDataKey          return key of data sales invoice
 * @param { string } customValidationKey    return key of validation sales invoice
 * @returns
 *      generated data and validation for sales invoice from sales order details
 */
export const generateDataNValidationSalesInvoiceFromSalesOrderDetails = (
  salesOrderDetails,
  customDataKey = 'salesInvoiceData',
  customValidationKey = 'salesInvoiceValidation',
) => {
  if (isEmpty(salesOrderDetails)) {
    return generateDataNValidationAddSalesInvoice(1, customDataKey, customValidationKey);
  }

  const usedID = arrHelp.generateArrWithFunc(1, uuidV4);

  const {
    sales_order_id,
    sales_order_no,
    contact_code,
    customer_id: contact_id,
    customer_name: contact_name,
    customer_address,
  } = salesOrderDetails;

  // some variable is inherit from initial data, so this assign variable follow inherit from
  // for example on modul_id and modul_id_container, where assigned from sales_order_id and sales_order_no
  const salesInvoiceData = usedID.map((id) => {
    return {
      ...initialDataSalesInvoice('', id),
      ...salesOrderDetails,
      customer_id_container: {
        contact_id,
        contact_code,
        contact_name,
        customer_address,
      },
      modul_id: sales_order_id,
      modul_id_container: {
        sales_order_id,
        transaction_no: sales_order_no,
      },
      modul_type: modulOrderType,
      transaction_date: moment(),
      expired_date: null,
      description: '',
    };
  });

  const salesInvoiceValidation = usedID.map((id) => {
    return initialValidationSalesInvoice('', id);
  });

  return {
    [customDataKey]: salesInvoiceData,
    [customValidationKey]: salesInvoiceValidation,
  };
};

/**
 *
 * @param { object } salesOrderDetails      data from sales order details
 * @param { string } customDataKey          return key of data sales invoice
 * @param { string } customValidationKey    return key of validation sales invoice
 * @returns
 *      function enhancement
 *      generated data and validation for sales invoice from sales order details
 */
export const generateDataNValidationSalesInvoiceFromSalesOrderDetailsV2 = ({
  salesOrderDetails,
  currentSalesOrderData = [],
  companyUserData = {},
  customDataKey = 'salesInvoiceData',
  customValidationKey = 'salesInvoiceValidation',
}) => {
  if (isEmpty(salesOrderDetails) && isEmpty(currentSalesOrderData)) {
    return generateDataNValidationAddSalesInvoice(1, customDataKey, customValidationKey);
  } else if (!isEmpty(currentSalesOrderData) && isEmpty(salesOrderDetails)) {
    const generatedDataNvalidationSalesOrder = generateDataNValidationAddSalesInvoice(
      1,
      customDataKey,
      customValidationKey,
    );

    const { reference_number, reference_number_container } = currentSalesOrderData[0];

    const newSalesOrderData = [
      {
        ...generatedDataNvalidationSalesOrder[customDataKey][0],
        reference_number,
        reference_number_container,
        customer_address: '',
      },
    ];

    return {
      [customDataKey]: newSalesOrderData,
      [customValidationKey]: generatedDataNvalidationSalesOrder[customValidationKey],
    };
  }

  salesOrderDetails = objHelper.changeFormatValue(
    salesOrderDetails,
    salesInvoiceCalculationNumberKeyNames,
    [(value) => Number(value) || ''],
  );

  let revertCurrentSalesOrderData = {};

  if (!isEmpty(currentSalesOrderData) && Array.isArray(currentSalesOrderData)) {
    const { reference_number, reference_number_container, accounting_period } =
      currentSalesOrderData[0];

    revertCurrentSalesOrderData = {
      reference_number,
      reference_number_container,
      accounting_period,
    };
  }

  const usedID = arrHelp.generateArrWithFunc(1, uuidV4);

  const {
    sales_order_id,
    sales_order_no,
    contact_code,
    customer_id: contact_id,
    customer_name: contact_name,
    customer_address,
    transaction_date,
    expired_date,
    discount_type = DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
  } = salesOrderDetails;

  const salesOrderProducts = salesOrderDetails[DEFAULT_KEY_NAME_SALES_ORDER_PRODUCT_API_REF];

  const amountConst = salesOrderProducts.reduce((amount, curr) => {
    const { quantity_available, price } = curr;

    return amount + Number(quantity_available) * Number(price);
  }, 0);

  // some variable is inherit from initial data, so this assign variable follow inherit from
  // for example on modul_id and modul_id_container, where assigned from sales_order_id and sales_order_no
  const salesInvoiceData = usedID.map((id) => {
    return {
      ...initialDataSalesInvoice('', id),
      ...salesOrderDetails,
      ...revertCurrentSalesOrderData,
      discount_type,
      warehouse_id_container: {
        warehouse_id: salesOrderDetails.warehouse_id || '',
        warehouse_name: salesOrderDetails.warehouse_name || '',
      },
      amount_const: Number(amountConst) || DEFAULT_CALCULATION_NUMBER,
      sales_agent_id_container: {
        contact_id: salesOrderDetails.sales_agent_id || '',
        contact_code: salesOrderDetails.sales_agent_code || '',
        contact_name: salesOrderDetails.sales_agent_name || '',
      },
      discount_account_id_container: {
        contact_id: salesOrderDetails.discount_account_id || '',
        contact_name: salesOrderDetails.discount_account_name || '',
        contact_code: salesOrderDetails.discount_account_code || '',
      },
      expense_account_id_container: {
        contact_id: salesOrderDetails.expense_account_id || '',
        contact_name: salesOrderDetails.expense_account_name || '',
        contact_code: salesOrderDetails.expense_account_code || '',
      },
      down_payment_account_id_container: {
        contact_id: salesOrderDetails.down_payment_account_id || '',
        contact_name: salesOrderDetails.down_payment_account_name || '',
        contact_code: salesOrderDetails.down_payment_account_code || '',
      },
      accounting_period: isEmpty(companyUserData)
        ? null
        : companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIOD],
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
          documentData: salesOrderDetails,
        }),
      customer_id_container: {
        contact_id,
        contact_code,
        contact_name,
        customer_address,
      },
      modul_id: sales_order_id,
      modul_id_container: {
        sales_order_id,
        transaction_no: sales_order_no,
      },
      modul_type: modulOrderType,
      transaction_date: transaction_date ? new Date(transaction_date) : moment(),
      expired_date: expired_date ? new Date(expired_date) : null,
      description: '',
    };
  });

  const salesInvoiceValidation = usedID.map((id) => {
    return initialValidationSalesInvoice('', id);
  });

  return {
    [customDataKey]: salesInvoiceData,
    [customValidationKey]: salesInvoiceValidation,
  };
};

/**
 *
 * @param { object } salesOrderDetails      data from sales delivery details
 * @param { string } customDataKey          return key of data sales invoice
 * @param { string } customValidationKey    return key of validation sales invoice
 * @returns
 *      generated data and validation for product in sales invoice from sales order details
 */
export const generateDataNValidationProductSalesInvoiceFromSalesOrderDetails = (
  salesOrderDetails,
  customDataKey = 'productSalesInvoiceData',
  customValidationKey = 'productSalesInvoiceValidation',
) => {
  if (isEmpty(salesOrderDetails)) {
    return generateDataNValidationAddProductSI(2, customDataKey, customValidationKey);
  }

  const usedID = arrHelp.generateArrWithFunc(salesOrderDetails.sales_order_product.length, uuidV4);

  const productSalesInvoiceData = usedID.map((id, index) => {
    const {
      product_id,
      product_name,
      product_code,
      quantity,
      quantity_available,
      quantity_origin,
      price,
      product_unit,
      unit_destination,
      unit_origin,
    } = salesOrderDetails.sales_order_product[index];

    let quantity_available_const = formatHelp.reverseCurrencyFormatWithRegex(quantity_available);
    let price_amount = Number(price) * Number(quantity_available);

    if (Number.isNaN(quantity_available_const)) {
      quantity_available_const = DEFAULT_CALCULATION_NUMBER;
    }

    if (Number.isNaN(price_amount)) {
      price_amount = DEFAULT_CALCULATION_NUMBER;
    }

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    return {
      ...initialDataProduct('', id),
      ...salesOrderDetails.sales_order_product[index],
      product_id_container: {
        product_id,
        product_code,
        product_name,
        ...salesOrderDetails.sales_order_product[index],
      },
      price_amount,
      quantity: quantity_available,
      quantity_available: DEFAULT_CALCULATION_NUMBER,
      quantity_available_const,
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantity_available_const),
        value_comparison: quantityComparison,
      },
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      quantity_available_with_unit: `${Number(quantity_available_const)} ${
        unit_origin || product_unit
      }`,
    };
  });

  const productSalesInvoiceValidation = usedID.map((id) => {
    return initialValidationProduct('', id);
  });

  return {
    [customDataKey]: productSalesInvoiceData,
    [customValidationKey]: productSalesInvoiceValidation,
  };
};

/**
 *
 * @param { object } salesOrderDetails      data from sales delivery details
 * @param { string } customDataKey          return key of data sales invoice
 * @param { string } customValidationKey    return key of validation sales invoice
 * @returns
 *      function on params enhancement
 *      generated data and validation for product in sales invoice from sales order details
 */
export const generateDataNValidationProductSalesInvoiceFromSalesOrderDetailsV2 = ({
  salesOrderDetails,
  customDataKey = 'productSalesInvoiceData',
  customValidationKey = 'productSalesInvoiceValidation',
}) => {
  if (
    isEmpty(salesOrderDetails) ||
    isEmpty(salesOrderDetails[DEFAULT_KEY_NAME_SALES_ORDER_PRODUCT_API_REF])
  ) {
    return generateDataNValidationAddProductSI(2, customDataKey, customValidationKey);
  }

  const usedID = arrHelp.generateArrWithFunc(salesOrderDetails.sales_order_product.length, uuidV4);

  const productSalesInvoiceData = usedID.map((id, index) => {
    let selectedProductSalesOrder =
      salesOrderDetails[DEFAULT_KEY_NAME_SALES_ORDER_PRODUCT_API_REF][index];
    if (!isEmpty(selectedProductSalesOrder)) {
      selectedProductSalesOrder = objHelper.changeFormatValue(
        selectedProductSalesOrder,
        productSalesInvoiceCalculationNumberKeyNames,
        [(value) => Number(value) || ''],
      );
    }

    const { product_id, product_name, product_code } = selectedProductSalesOrder;

    return {
      ...initialDataProduct('', id),
      ...selectedProductSalesOrder,
      product_id_container: {
        product_id,
        product_code,
        product_name,
        ...selectedProductSalesOrder,
      },
      ...formulaHelpers.parseProductAPI2display({
        product: selectedProductSalesOrder,
      }),
    };
  });

  const productSalesInvoiceValidation = usedID.map((id) => {
    return initialValidationProduct('', id);
  });

  return {
    [customDataKey]: productSalesInvoiceData,
    [customValidationKey]: productSalesInvoiceValidation,
  };
};

/**
 *
 * @param { object } addSalesInvoiceData, data sales invoice
 * @param { object } addProductData, data product,
 * @returns
 *      generalized data sales invoice for add
 */
export const generalizeDataAddSalesInvoice = (addSalesInvoiceData, addProductData) => {
  if (!Array.isArray(addSalesInvoiceData) || !Array.isArray(addProductData)) {
    return {
      product: addProductData,
      ...addSalesInvoiceData,
    };
  }

  const dataProductGeneralized = Array.from(addProductData)
    .filter((product) => product.product_id)
    .map((productSingleData) => {
      const {
        product_unit,
        unit_destination,
        unit_origin,
        quantity_origin,
        quantity,
        quantity_destination,
      } = productSingleData;

      return pick(
        {
          ...productSingleData,
          quantity: Number(quantity),
          quantity_origin: Number(quantity_origin) || Number(quantity),
          quantity_destination: Number(quantity_destination) || Number(quantity),
          price: formatHelp.reverseCurrencyFormatWithRegex(productSingleData.price),
          unit:
            hasImplementedUnitProductUnit || unit_destination
              ? unit_destination || product_unit
              : product_unit,
          unit_origin: unit_origin || product_unit,
          unit_destination: unit_destination || product_unit,
        },
        allowKeysDataProductSalesInvoice,
      );
    });

  const dataAddSalesInvoiceGeneralized = Array.from(addSalesInvoiceData).map((singleDataSi) => {
    const {
      customer_id_container,
      transaction_date,
      expired_date,
      discount_nominal,
      discount_percentage,
      attachment,
      sales_agent_id_container,
    } = singleDataSi;

    const uuidAttachment =
      Array.isArray(attachment) && attachment.length
        ? attachment[0][DEFAULT_KEY_NAME_FILE_PROPERTIES][DEFAULT_KEY_NAME_UUID_API_REF] || ''
        : '';

    const keysAdditionalCostValue = Object.keys(
      relationalAdditionalCostValueNaccountForSalesInvoice,
    );
    const omittedAdditionalAccount = keysAdditionalCostValue.reduce(
      (allAddCostVal, currAddCostVal) => {
        if (!Number(singleDataSi[currAddCostVal])) {
          allAddCostVal = allAddCostVal.concat(
            relationalAdditionalCostValueNaccountForSalesInvoice[currAddCostVal],
          );
        }

        return allAddCostVal;
      },
      [],
    );

    singleDataSi = omit(singleDataSi, omittedAdditionalAccount);

    return objHelper.filteringExistedValue(
      pick(
        {
          ...singleDataSi,
          [DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF]: uuidAttachment,
          customer_name: !isEmpty(customer_id_container) ? customer_id_container.contact_name : '',
          transaction_date: transaction_date ? moment(transaction_date).toISOString(true) : '',
          expired_date: expired_date ? moment(expired_date).toISOString(true) : '',
          amount: formatHelp.reverseCurrencyFormatWithRegex(singleDataSi.amount),
          discount_nominal: Number(discount_nominal) || 0,
          discount_percentage: Number(discount_percentage) || 0,
          sales_agent_name: sales_agent_id_container ? sales_agent_id_container.contact_name : '',
        },
        allowKeysDataSalesInvoice,
      ),
      [],
      true,
      DEFAULT_EMPTY_CALCULATIONS_NUMBER,
    );
  });

  return {
    ...dataAddSalesInvoiceGeneralized[0],
    product: dataProductGeneralized,
  };
};

/**
 *
 * @param { object } bodyData, data body of update or add sales invoice, this data for sending into backend
 * @returns
 * data generalize for action data or update sales invoice in BE
 *
 */
export function generalizeDataAddUpdateSalesInvoice(bodyData) {
  bodyData = objHelper.filterKeyObj(bodyData, [], allowKeysDataSalesInvoice);

  if (Array.isArray(bodyData.product) && !isEmpty(bodyData.product)) {
    bodyData.product = bodyData.product.map((bodyProduct) => {
      const filteringKeyBodyProduct = objHelper.filterKeyObj(
        bodyProduct,
        [],
        allowKeysDataProductSalesInvoice,
      );

      const isExitedValueInROw = objHelper.filteringExistedValue(filteringKeyBodyProduct, [], true);

      if (!isEmpty(isExitedValueInROw)) {
        return filteringKeyBodyProduct;
      }
    });

    bodyData.product = arrHelp.filteringExistedValue(bodyData.product);
  }

  return objHelper.filteringExistedValue(bodyData, [], true);
}
