import axios from 'axios';

import { REACT_APP_API_URL_POSTAL_CODE } from '../../../utils/configs/api.config';
import { TERSIER_DEFAULT_LIST_PARAMS } from '../../../utils/default/params.default';
import { authHeader } from '../../../utils/helpers/auth-header.helpers';
import objHelper from '../../../utils/helpers/object.helper';

// param endpoint : menerima endpoint city, sub-district, urban
// param params : menerima params from masing2 endpoint city, sub-district, urban
// param allowKeyParams : menerima param that only valid
const creatorGetList = async (endpoint, params, allowKeysParms) => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  params = objHelper.withDefaultValue(params, TERSIER_DEFAULT_LIST_PARAMS);

  params = objHelper.filterKeyObj(params, [], allowKeysParms);

  return await axios({
    method: 'GET',
    url: endpoint,
    headers: {
      Authorization,
    },
    params: {
      ...params,
    },
  });
};

const getListCity = async (paramsGetListCity) => {
  return creatorGetList(`${REACT_APP_API_URL_POSTAL_CODE}/city`, paramsGetListCity, [
    'paginate',
    'page',
    'province_id',
    'province_code',
    'city',
  ]);
};

const getListSubDistrict = async (paramsGetListSubDistrict) => {
  return creatorGetList(`${REACT_APP_API_URL_POSTAL_CODE}/sub-district`, paramsGetListSubDistrict, [
    'paginate',
    'page',
    'province_id',
    'city',
    'sub_district',
  ]);
};

const getListUrban = async (paramsGetListUrban) => {
  return creatorGetList(`${REACT_APP_API_URL_POSTAL_CODE}/urban`, paramsGetListUrban, [
    'paginate',
    'page',
    'province_id',
    'sub_district',
    'urban',
    'postal_code',
  ]);
};

const postalCodeServices = {
  getListCity,
  getListSubDistrict,
  getListUrban,
};

export default postalCodeServices;
