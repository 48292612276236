import axios from 'axios';

import { REACT_APP_API_URL_PROVINCE } from '../../../utils/configs/api.config';
import { authHeader } from '../../../utils/helpers/auth-header.helpers';

// listing province
const provinceList = async ({
  page = 1,
  paginate = 15,
  province_id,
  province_code,
  province_name,
}) => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  let provinceList = {
    page,
    paginate,
  };

  if (province_id) {
    provinceList.province_id = province_id;
  }

  if (province_name) {
    provinceList.province_name = province_name;
  }

  if (province_code) {
    provinceList.province_code = province_code;
  }

  return await axios({
    method: 'GET',
    url: REACT_APP_API_URL_PROVINCE,
    params: {
      ...provinceList,
    },
    headers: {
      Authorization,
    },
  });
};

const provinceServices = {
  provinceList,
};

export default provinceServices;
