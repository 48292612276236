import * as React from 'react';
function SvgProfileAccount(props) {
  return (
    <svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fill='#fff' fillOpacity={0.01} d='M0 0h24v24H0z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-7-3a3 3 0 11-6 0 3 3 0 016 0zm-3 11a7.966 7.966 0 01-5-1.755V15c0-1.105.887-2 2-2h6c1.105 0 2 .885 2 2v3.245A7.967 7.967 0 0112 20z'
        fill='#42526E'
      />
    </svg>
  );
}
export default SvgProfileAccount;
