import * as React from 'react';
function SvgIconMenuPurchasePurchaseDelivery(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.857 68.786a.429.429 0 11-.857 0 .429.429 0 01.857 0zM6.857 68.357h58.286a.429.429 0 010 .857H6.857a.429.429 0 010-.857zM2.571 68.357h2.143a.429.429 0 010 .857H2.571a.429.429 0 010-.857zM69.429 68.357h-2.143a.428.428 0 000 .857h2.143a.429.429 0 000-.857zM72 68.786a.429.429 0 11-.857 0 .429.429 0 01.857 0z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40.8 63.107h13.136a7.325 7.325 0 00-.023.505c0 .9.166 1.792.489 2.618a6.586 6.586 0 001.386 2.18h8.007a6.586 6.586 0 001.386-2.18 7.203 7.203 0 00.489-2.618c0-.17-.012-.338-.023-.505h2.243a1.775 1.775 0 001.314-.623c.347-.395.54-.926.535-1.479V49.972a11.256 11.256 0 00-.858-3.962l-3.368-7.31a3.584 3.584 0 00-1.143-1.313 3.104 3.104 0 00-1.564-.549H51.2v-5.615c-.01-1.068-.4-2.088-1.084-2.835-.684-.747-1.605-1.16-2.56-1.148h-32.05c-.956-.011-1.877.402-2.56 1.148-.684.747-1.075 1.767-1.085 2.835V40.8h1.808v-9.577a2.126 2.126 0 01.556-1.405 1.7 1.7 0 011.28-.557h13.99V40.8h1.81V29.26h8.139v18.692H38.4v2.02h10.991v11.114H40.8v2.02zm8.036-33.289c.344.368.544.873.555 1.405v16.729h-8.139V29.26h6.303a1.7 1.7 0 011.281.557zm5.985 9.04h3.84v9.086l-2.854.008h-.003a.888.888 0 01-.666-.29 1.108 1.108 0 01-.29-.73l-.027-8.073zm9.084.767l3.368 7.31c.138.316.25.645.336.983l-7.14.02v-9.08h2.337c.22.037.432.123.621.255.19.132.352.306.478.511zm3.985 21.46h-2.672c-.447-1.196-1.2-2.218-2.166-2.937a5.45 5.45 0 00-3.26-1.103 5.45 5.45 0 00-3.261 1.103c-.966.719-1.72 1.741-2.166 2.938H51.2V38.859h1.812l.027 8.081c.01.811.305 1.585.823 2.153.518.568 1.216.884 1.942.88h.007l12.118-.035v.016l.002.018v3.537h-10.4c-.24 0-.47.106-.64.295-.17.19-.265.447-.265.715 0 .268.096.525.265.714.17.19.4.296.64.296h10.4v5.476c0 .06-.034.08-.04.08zm-10.36-1.254c.67-.5 1.457-.766 2.262-.766 1.079.002 2.113.481 2.876 1.334.763.852 1.192 2.008 1.193 3.213 0 .899-.239 1.778-.686 2.526a4.204 4.204 0 01-1.826 1.674 3.675 3.675 0 01-2.352.259 3.958 3.958 0 01-2.083-1.245 4.733 4.733 0 01-1.114-2.328 5.043 5.043 0 01.232-2.626c.308-.831.83-1.541 1.499-2.04z'
        fill='#000'
      />
      <path
        d='M14.367 68.4a4.502 4.502 0 01-.825-2.097 4.885 4.885 0 01.25-2.304c.265-.723.698-1.336 1.245-1.767a2.965 2.965 0 011.838-.66c.652 0 1.291.23 1.838.66.547.43.98 1.044 1.245 1.767.266.722.353 1.523.25 2.304a4.502 4.502 0 01-.824 2.097h-2.508c.596 0 1.168-.288 1.59-.8.422-.51.66-1.204.66-1.927 0-.54-.133-1.067-.38-1.515a2.407 2.407 0 00-1.01-1.005 1.887 1.887 0 00-1.3-.155 2.162 2.162 0 00-1.152.746 2.946 2.946 0 00-.616 1.396 3.267 3.267 0 00.128 1.576c.17.498.459.924.829 1.224.37.3.804.46 1.25.46h-2.508zM27.867 68.4a4.503 4.503 0 01-.825-2.097 4.886 4.886 0 01.25-2.304c.265-.723.698-1.336 1.245-1.767a2.965 2.965 0 011.838-.66c.653 0 1.291.23 1.838.66.547.43.98 1.044 1.245 1.767.266.722.353 1.523.25 2.304a4.502 4.502 0 01-.824 2.097h-2.508c.596 0 1.169-.288 1.59-.8.422-.51.66-1.204.66-1.927 0-.54-.133-1.067-.38-1.515a2.407 2.407 0 00-1.01-1.005 1.887 1.887 0 00-1.3-.155 2.162 2.162 0 00-1.151.746c-.315.381-.53.867-.616 1.396a3.268 3.268 0 00.127 1.576c.17.498.459.924.829 1.224.37.3.805.46 1.25.46h-2.508z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M.087 38.717a.823.823 0 00-.073.514c.016.088.046.17.089.244a.625.625 0 00.159.183c.062.048.132.08.205.095l4.514.941 6.095 18.26c.127.377.345.7.625.925.28.225.61.344.947.34h21.665c.149 0 .292-.073.398-.2a.761.761 0 00.164-.483c0-.18-.059-.354-.164-.482a.518.518 0 00-.398-.2H12.648a.504.504 0 01-.317-.11.677.677 0 01-.215-.302l-.461-1.388 21.294-.981c.245-.012.48-.12.67-.308.19-.188.324-.447.382-.736l1.968-9.831c.04-.2.041-.41.005-.611a1.507 1.507 0 00-.216-.552 1.2 1.2 0 00-.392-.383.97.97 0 00-.49-.139H7.135l-1.23-3.681a.693.693 0 00-.17-.275.528.528 0 00-.257-.141L.658 38.41a.464.464 0 00-.22.007.519.519 0 00-.2.108.644.644 0 00-.151.192zm8.412 8.888l.456 1.363h25.102l.273-1.364H8.5zm-.908-2.728l.453 1.364h26.558l.273-1.364H7.591zm3.614 10.834l-1.794-5.38h24.373l-.877 4.378-21.702 1.002z'
        fill='#000'
      />
    </svg>
  );
}
export default SvgIconMenuPurchasePurchaseDelivery;
