import { FORMAT_DATE_COLUMN } from '../../../components/table/TableKeyValuePureMarkup/TableKeyValuePureMarkup';
import { FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN } from '../../../components/table-row/TableRowMultipleTableDataPureMarkup/TableRowMultipleTableDataPureMarkup';
import { FORMAT_CURRENCY_AUTO_COMMA_COLUMN } from '../../../components/table-row/TableRowPureMarkup/TableRowPureMarkup';

import { primaryTableDataCalculationNumber } from './additional-styles';

// column sales invoice detail that handling table head with translation
// @param: p, function for translation
// @param: menuName, name menu that provide translation
export const columnsTableHeaderSalesInvoiceDetail = (t) => {
  return [
    {
      name: 'product_code',
      label: t('dialog.detail-sales-invoice.table.header-column.product-code'),
      width: 150,
    },
    {
      name: 'product_name',
      label: t('dialog.detail-sales-invoice.table.header-column.product-name'),
      width: 150,
    },
    {
      name: 'description',
      label: t('dialog.detail-sales-invoice.table.header-column.description'),
      width: 250,
      align: 'left',
    },
    {
      name: 'quantity',
      label: t('dialog.detail-sales-invoice.table.header-column.quantity'),
      align: 'right',
      width: 100,
    },
    {
      name: 'product_unit',
      label: t('dialog.detail-sales-invoice.table.header-column.unit'),
      align: 'right',
      width: 50,
    },
    {
      name: 'price',
      label: t('dialog.detail-sales-invoice.table.header-column.price'),
      align: 'right',
      width: 140,
    },
    {
      name: 'price_amount',
      label: t('dialog.detail-sales-invoice.table.header-column.amount'),
      align: 'right',
      width: 150,
    },
  ];
};

// columns key value table
export const columnsTableKeyValuePrintSalesInvoice = [
  {
    headerTranslated: 'No.',
    valueKeyName: 'sales_invoice_no',
  },
  {
    header: 'table.header-column.date',
    valueKeyName: 'transaction_date',
    formatValue: FORMAT_DATE_COLUMN,
  },
  {
    header: 'table.header-column.name',
    valueKeyName: 'customer_name',
  },
  {
    header: 'table.header-column.address',
    valueKeyName: 'customer_address',
  },
];

export const columnsTableProductSalesInvoiceDetails = (t) => {
  return [
    {
      isAutoIncrementColumn: true,
      label: t('No.'),
      align: 'center',
      style: {
        maxWidth: 20,
      },
    },
    {
      name: 'product_name',
      label: t('table.header-column.product-name'),
      style: {
        minWidth: 'auto',
        width: 593,
        maxWidth: 600,
      },
    },
    {
      name: 'quantity',
      label: t('table.header-column.qty'),
      style: {
        minWidth: 'auto',
        width: 20,
        maxWidth: 35,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'unit_destination',
      label: t('table.header-column.unit'),
      style: {
        minWidth: 'auto',
        width: 30,
        maxWidth: 35,
      },
    },
    {
      name: 'price',
      label: t('table.header-column.at-price'),
      style: {
        minWidth: 'auto',
        width: 192,
        maxWidth: 300,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'amount',
      label: t('table.header-column.amount'),
      style: {
        minWidth: 'auto',
        width: 192,
        maxWidth: 300,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
  ];
};

export const columnsTableFooterProductSalesInvoiceDetails = (t) => {
  return [
    [
      {
        isEmptyTableData: true,
        colSpan: 4,
      },
      {
        isLabel: true,
        label: t('typography.discount'),
      },
      {
        isValue: true,
        valueKeyName: 'discount',
        tableDataStyle: primaryTableDataCalculationNumber,
      },
    ],
    [
      {
        isEmptyTableData: true,
        colSpan: 4,
      },
      {
        isLabel: true,
        label: t('label.other-cost'),
      },
      {
        isValue: true,
        valueKeyName: 'expense',
        tableDataStyle: primaryTableDataCalculationNumber,
      },
    ],
    [
      {
        isEmptyTableData: true,
        colSpan: 4,
      },
      {
        isLabel: true,
        label: t('label.down-payment'),
      },
      {
        isValue: true,
        valueKeyName: 'down_payment',
        tableDataStyle: primaryTableDataCalculationNumber,
      },
    ],
    [
      {
        isEmptyTableData: true,
        colSpan: 4,
      },
      {
        isLabel: true,
        label: t('typography.total'),
      },
      {
        isValue: true,
        valueKeyName: 'amount',
        formatValue: FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN,
        tableDataStyle: primaryTableDataCalculationNumber,
      },
    ],
    [
      {
        isEmptyTableData: true,
        colSpan: 4,
      },
      {
        isLabel: true,
        label: t('typography.paid'),
      },
      {
        isValue: true,
        valueKeyName: 'paid',
        formatValue: FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN,
        tableDataStyle: primaryTableDataCalculationNumber,
      },
    ],
    // [
    //     {
    //         isEmptyTableData: true,
    //         colSpan: 4,
    //     },
    //     {
    //         isLabel: true,
    //         label: t('typography.return'),
    //     },
    //     {
    //         isValue: true,
    //         valueKeyName: 'amount_return',
    //         formatValue: FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN,
    //         tableDataStyle: primaryTableDataCalculationNumber,
    //     },
    // ]
  ];
};

export const columnsTableFooterSalesInvoiceDetails = (t) => {
  return [
    {
      name: 'description',
      label: t('table.header-column.memo-colon'),
      style: {
        minWidth: 100,
        width: 360,
        maxWidth: 365,
        height: 56,
        verticalAlign: 'top',
      },
      isUseTableHeaderStyle: false,
    },
  ];
};

// additional props on table head main
export const additionalPropsSalesInvoiceDetail = {
  sx: {
    backgroundColor: '#EFF8FF!important',
  },
};

// colum that representation for calculation
export const calculationColumns = ['price_amount'];

// on representation total row
export const totalColumnUsed = ['price', ...calculationColumns];
