/* eslint-disable no-undef */
require('dotenv').config();

const REACT_APP_SITE_KEY_RECAPTHA = process.env.REACT_APP_SITE_KEY_RECAPTHA;
const REACT_APP_IDDLE_TIME_IN_SECOND = Number(process.env.REACT_APP_IDDLE_TIME_IN_SECOND);
const REACT_APP_SECRET_KEY_ENCRYPT = process.env.REACT_APP_SECRET_KEY_ENCRYPT;
const REACT_APP_MIDTRANS_CLIENT_KEY = process.env.REACT_APP_MIDTRANS_CLIENT_KEY;
const REACT_APP_IS_PRODUCTION_ENVIRONMENT = process.env.NODE_ENV === 'production';
const REACT_APP_USE_MIDTRANS_PRODUCTION_SERVER =
  Boolean(Number(process.env.REACT_APP_USE_MIDTRANS_PRODUCTION_SERVER)) || false;

module.exports = {
  REACT_APP_SITE_KEY_RECAPTHA,
  REACT_APP_IDDLE_TIME_IN_SECOND,
  REACT_APP_SECRET_KEY_ENCRYPT,
  REACT_APP_MIDTRANS_CLIENT_KEY,
  REACT_APP_IS_PRODUCTION_ENVIRONMENT,
  REACT_APP_USE_MIDTRANS_PRODUCTION_SERVER,
};
