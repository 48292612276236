import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Chip,
  IconButton,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';

import BoxActionPermission from '../../../components/box/BoxActionPermission/BoxActionPermission';
import BoxTableNotFound from '../../../components/box/BoxTableNotFound/BoxTableNotFound';
import TablePaginationMain from '../../../components/table-pagination/TablePaginationMain/TablePaginationMain';
import TableRowLoading from '../../../components/table-row/TableRowLoading/TableRowLoading';
import TypographyLinkPrimary from '../../../components/typography/TypographyLinkPrimary/TypographyLinkPrimary';
import { withRouter } from '../../../components/withRouter/withRouter';
import { decryptData, encryptData } from '../../../services/modules/Crypto/Crypto.service';
import messageHelper from '../../../store/message/message.helper';
import { getListSalesOrder } from '../../../store/sales/sales.action';
import {
  REACT_APP_SALES_SALES_ORDER_CREATE_NEW_URL,
  REACT_APP_SALES_SALES_ORDER_DETAILS_TEMPLATE_URL,
} from '../../../utils/configs/url.config';
import {
  SLICE_NAME_SALES_ORDER_DATA,
  STATUS_REQUEST_LIST_SALES_ORDER_PENDING,
} from '../../../utils/constants/store.constant';
import { initialSearchSalesOrder } from '../../../utils/data/display/initial-search-sales-order';
import {
  columnsDataSalesOrderFormatCalculationNumber,
  columnsSearchFormatAction,
  columnsSearchFormatLink,
  columnsSearchFormatStatus,
  columnsSearchSalesOrderFormatDate,
  columnsTableSalesOrder,
} from '../../../utils/data/display/table-list-sales-order';
import {
  iconListSalesOrderData,
  tooltipListSalesOrder,
} from '../../../utils/data/display/tooltip-list-sales-order';
import { salesOrderStatus } from '../../../utils/data/label-value/sales-status';
import {
  DEFAULT_SALES_ORDER_LIST_PARAMS,
  DEFAULT_SALES_ORDER_SEARCH_FE_PARAMS,
  TERSIER_DEFAULT_LIST_PARAMS,
} from '../../../utils/default/params.default';
import arrHelp from '../../../utils/helpers/array.helpers';
import dateHelpers from '../../../utils/helpers/date.helpers';
import formatHelp from '../../../utils/helpers/format.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import strHelp from '../../../utils/helpers/string.helpers';

import TableHeadSearchSalesOrder from './TableHeadSearchSalesOrder/TableHeadSearchSalesOrder';
import { styles } from './SalesOrderList.styles';

class SalesOrderList extends Component {
  constructor(props) {
    super(props);

    const { handleChangeStatusPage, onClickNewSalesOrder } = props;

    this.isHandleChangeStatusPageFunction = typeof handleChangeStatusPage === 'function';
    this.isOnClickNewSalesOrderFunction = typeof onClickNewSalesOrder === 'function';

    this.state = {
      rowsPerPage: TERSIER_DEFAULT_LIST_PARAMS.paginate,
    };

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

    this.handleClickActionSalesOrder = this.handleClickActionSalesOrder.bind(this);
  }

  handleClickActionSalesOrder(event) {
    const { name: newStatusPage, value } = event.currentTarget;

    const decryptedValue = decryptData(value, false);

    const newSelectedSalesOrder = JSON.stringify(decryptedValue);

    if (this.isHandleChangeStatusPageFunction) {
      this.props.handleChangeStatusPage(newStatusPage, newSelectedSalesOrder);
    }
  }

  async handleChangePage(event, newPage) {
    event.preventDefault();

    const { sales } = this.props;
    const salesOrderData = sales[SLICE_NAME_SALES_ORDER_DATA];

    if (!salesOrderData) return;

    const { next_page_url, current_page } = salesOrderData;

    const isCannotNextPage = !next_page_url && current_page < newPage + 1;

    if (isCannotNextPage) return;

    let searchParams = {
      page: Number(newPage) + 1,
    };

    let currentSearchParams = objHelper.filterKeyObj(
      salesOrderData,
      [],
      DEFAULT_SALES_ORDER_SEARCH_FE_PARAMS,
    );

    if (currentSearchParams) {
      // removing previx on key search and filter by existed params
      currentSearchParams = objHelper.changeSuffixKey(currentSearchParams, 'search_', true);

      currentSearchParams = objHelper.filterKeyObj(
        currentSearchParams,
        [],
        DEFAULT_SALES_ORDER_LIST_PARAMS,
      );

      searchParams = {
        ...searchParams,
        ...currentSearchParams,
      };
    }

    await this.props.getListSalesOrder(searchParams);
  }

  async handleChangeRowsPerPage(event) {
    const { value: newRowsPerPage } = event.target;

    const { t, sales } = this.props;

    const salesOrderData = sales[SLICE_NAME_SALES_ORDER_DATA];

    if (isEmpty(salesOrderData)) return;

    const { next_page_url, current_page, per_page: paginate } = salesOrderData;

    if (isEqual(paginate, newRowsPerPage)) return;

    const isNotExistedNextData = !next_page_url && paginate < newRowsPerPage && current_page === 1;

    if (isNotExistedNextData) {
      this.props.setFailedMessage(
        t('dashboard.sales.sales-order.message.max-data.primary'),
        t('dashboard.sales.sales-order.message.max-data.secondary'),
      );

      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      rowsPerPage: newRowsPerPage,
    }));

    let searchParams = {
      current_page,
      paginate: newRowsPerPage,
    };

    let currentSearchParams = objHelper.filterKeyObj(
      salesOrderData,
      [],
      DEFAULT_SALES_ORDER_SEARCH_FE_PARAMS,
    );

    if (currentSearchParams) {
      // removing previx on key search and filtering not existed on params
      currentSearchParams = objHelper.changeSuffixKey(currentSearchParams, 'search_', true);

      currentSearchParams = objHelper.filterKeyObj(
        currentSearchParams,
        [],
        DEFAULT_SALES_ORDER_LIST_PARAMS,
      );

      searchParams = {
        ...searchParams,
        ...currentSearchParams,
      };
    }

    await this.props.getListSalesOrder(searchParams);
  }

  async componentDidMount() {
    const { rowsPerPage: paginate } = this.state;
    const { t } = this.props;

    const initSearchSalesOrder = initialSearchSalesOrder(t);

    await this.props.getListSalesOrder({
      paginate,
      ...initSearchSalesOrder,
      isShowMessage: false,
    });
  }

  render() {
    const { t, classes, auth, sales } = this.props;

    const { rowsPerPage } = this.state;

    const { permissions } = auth.data;

    const salesOrderData = sales[SLICE_NAME_SALES_ORDER_DATA];

    let salesOrderDataList = [];
    let currPagination = rowsPerPage;

    const columnsTableHeaderSalesOrder = columnsTableSalesOrder(t);

    if (!isEmpty(salesOrderData)) {
      if (Array.isArray(salesOrderData.data)) {
        salesOrderDataList = salesOrderData.data;
      }

      currPagination = Number(salesOrderData.per_page);
    }

    return (
      <Box className={classes.boxContainerTable}>
        <Paper elevation={0}>
          <TableContainer className={'tableContainerConstantHeightMDSizeConstantLayoutPrimary'}>
            <Table stickyHeader className='tableMain' aria-label='table-sales-order-list'>
              <TableHeadSearchSalesOrder
                columnsTableHeaderSearchSalesOrder={columnsTableHeaderSalesOrder}
              />

              <TableBody className='primaryTableBody'>
                {sales.statusRequest === STATUS_REQUEST_LIST_SALES_ORDER_PENDING && (
                  <TableRowLoading
                    rowsAmount={rowsPerPage}
                    columnsAmount={columnsTableHeaderSalesOrder.length}
                  />
                )}
                {!isEmpty(salesOrderDataList) &&
                  sales.statusRequest !== STATUS_REQUEST_LIST_SALES_ORDER_PENDING &&
                  salesOrderDataList.map((singleSalesOrderData, index) => {
                    const baseUrl = strHelp.templateString(
                      REACT_APP_SALES_SALES_ORDER_DETAILS_TEMPLATE_URL,
                      singleSalesOrderData,
                    );

                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        className={'hoverEntireRow'}
                        onDoubleClick={() => this.props.navigate(baseUrl)}
                      >
                        {columnsTableHeaderSalesOrder.map(
                          (columnTableHeader, indexColumnHeader) => {
                            const { name: columnName } = columnTableHeader;

                            let valueData = singleSalesOrderData[columnName];

                            if (columnsSearchFormatAction.includes(columnName)) {
                              return (
                                <TableCell
                                  key={indexColumnHeader}
                                  align={columnTableHeader.align}
                                  className='tableCellListData'
                                >
                                  {tooltipListSalesOrder(t).map((tooltipData, index) => {
                                    return (
                                      <BoxActionPermission
                                        key={index}
                                        permissionsUser={permissions}
                                        permissionRequired={tooltipData.permission}
                                      >
                                        <Tooltip title={tooltipData.title}>
                                          <IconButton
                                            name={tooltipData.name}
                                            value={encryptData(
                                              JSON.stringify(singleSalesOrderData),
                                            )}
                                            onClick={this.handleClickActionSalesOrder}
                                            className={classes.iconButtonContainer}
                                          >
                                            <SvgIcon component={iconListSalesOrderData[0]} />
                                          </IconButton>
                                        </Tooltip>
                                      </BoxActionPermission>
                                    );
                                  })}
                                  {valueData}
                                </TableCell>
                              );
                            }

                            if (columnsSearchFormatLink.includes(columnName)) {
                              return (
                                <TableCell key={indexColumnHeader}>
                                  <TypographyLinkPrimary
                                    baseUrl={baseUrl}
                                    typographyValue={valueData}
                                  />
                                </TableCell>
                              );
                            }

                            // formatting date following localzone
                            if (columnsSearchSalesOrderFormatDate.includes(columnName)) {
                              if (dateHelpers.isValidDateV2(new Date(valueData))) {
                                valueData = formatHelp.getReadableDateV2(valueData);
                              }
                            }

                            if (columnsDataSalesOrderFormatCalculationNumber.includes(columnName)) {
                              valueData = formatHelp.currencyFormatWithRegex(valueData);
                            }

                            if (columnsSearchFormatStatus.includes(columnName)) {
                              if (valueData === 'referenceNumberMax') {
                                return <TableCell key={indexColumnHeader}>-</TableCell>;
                              }

                              const selectedSalesOrderData = arrHelp.filterObjDataWithID(
                                salesOrderStatus(t),
                                'value',
                                valueData,
                              );

                              let label = t('base.sales-order-status.open');
                              let colorChip = 'chipSecondaryStatus';

                              // get color and label status sales order
                              if (!isEmpty(selectedSalesOrderData)) {
                                label = selectedSalesOrderData.label;
                                colorChip = selectedSalesOrderData.colorChip;
                              }

                              return (
                                <TableCell key={indexColumnHeader}>
                                  <Chip size='small' color={colorChip} label={label} />
                                </TableCell>
                              );
                            }

                            return (
                              <TableCell key={indexColumnHeader} align={columnTableHeader.align}>
                                {valueData}
                              </TableCell>
                            );
                          },
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {isEmpty(salesOrderDataList) &&
              sales.statusRequest !== STATUS_REQUEST_LIST_SALES_ORDER_PENDING && (
                <BoxTableNotFound
                  textNotFound={t('dashboard.sales.sales-order.table.body-not-found')}
                  textButton={t('dashboard.sales.sales-order.button.add-sales-order')}
                  handleClickedButton={() => {
                    this.props.navigate(REACT_APP_SALES_SALES_ORDER_CREATE_NEW_URL);
                  }}
                  isExistButton={true}
                />
              )}
          </TableContainer>
          <TablePaginationMain
            page={salesOrderData ? salesOrderData.current_page - 1 : 0}
            prev_page={salesOrderData ? salesOrderData.prev_page_url : null}
            next_page={salesOrderData ? salesOrderData.next_page_url : null}
            rowsPerPage={currPagination}
            isLoadingData={
              sales.statusRequest === STATUS_REQUEST_LIST_SALES_ORDER_PENDING ||
              !salesOrderDataList.length
            }
            labelRowsPerPage={t('label.table.pagination.primary.rows-per-page')}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    );
  }
}

SalesOrderList.defaultProps = {
  isUseChangedPageOnDetails: false,
};

SalesOrderList.propsTypes = {
  handleChangeStatusPage: PropTypes.func.isRequired,
  onClickNewSalesOrder: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    sales: state.sales,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListSalesOrder: (paramsGetListSalesOrder) =>
      dispatch(getListSalesOrder(paramsGetListSalesOrder)),
    setFailedMessage: (primaryText, secondaryText) =>
      messageHelper.failedMessage(dispatch, primaryText, secondaryText, false),
  };
};

const stylingSalesOrderList = withStyles(styles)(SalesOrderList);

const SalesOrderListTranslate = withTranslation()(stylingSalesOrderList);

const SalesOrderWithRouter = withRouter(SalesOrderListTranslate);

const ConnectedSalesOrderList = connect(mapStateToProps, mapDispatchToProps)(SalesOrderWithRouter);

export default ConnectedSalesOrderList;
