const warning = {
  B25: '#fffcf5',
  B50: '#fffaeb',
  B100: '#fef0c7',
  B200: '#fedf89',
  B300: '#fec84b',
  B400: '#fdb022',
  B500: '#f79009',
  B577: '#e28652',
  B600: '#dc6803',
  B700: '#b54708',
  B800: '#93370d',
  B900: '#7a2e0e',
};

export default warning;
