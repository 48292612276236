import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { STOCK_ADJUSTMENT_BASE_ACTION_TYPE } from '../../utils/constants/action-type.constant';
import {
  LOCAL_STORAGE_STOCK_ADJUSTMENT_LIST,
  LOCAL_STORAGE_STOCK_ADJUSTMENT_PRINT_LIST,
} from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  SLICE_NAME_STOCK_ADJUSTMENT_DETAILS,
  SLICE_NAME_STOCK_ADJUSTMENT_LIST,
  SLICE_NAME_STOCK_ADJUSTMENT_PRINT_LIST,
  STATUS_REQUEST_ADD_STOCK_ADJUSTMENT_FAILED,
  STATUS_REQUEST_ADD_STOCK_ADJUSTMENT_PENDING,
  STATUS_REQUEST_ADD_STOCK_ADJUSTMENT_SUCCESS,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_DELETE_STOCK_ADJUSTMENT_FAILED,
  STATUS_REQUEST_DELETE_STOCK_ADJUSTMENT_PENDING,
  STATUS_REQUEST_DELETE_STOCK_ADJUSTMENT_SUCCESS,
  STATUS_REQUEST_DETAILS_STOCK_ADJUSTMENT_FAILED,
  STATUS_REQUEST_DETAILS_STOCK_ADJUSTMENT_PENDING,
  STATUS_REQUEST_DETAILS_STOCK_ADJUSTMENT_SUCCESS,
  STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_FAILED,
  STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_PENDING,
  STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_SUCCESS,
  STATUS_REQUEST_UPDATE_STOCK_ADJUSTMENT_FAILED,
  STATUS_REQUEST_UPDATE_STOCK_ADJUSTMENT_PENDING,
  STATUS_REQUEST_UPDATE_STOCK_ADJUSTMENT_SUCCESS,
} from '../../utils/constants/store.constant';
import { stockAdjustmentAsyncThunkTypeWithActionProps } from '../../utils/data/mapping/async-thunk-type-with-action-props.mapping';
import {
  bodyBuilderFulfilledAction,
  bodyBuilderPendingAction,
  bodyBuilderRejectedAction,
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
  sliceReduceHelper,
} from '../../utils/helpers/slice-reducer.helper';
import { getSliceNameFromDynamicStore } from '../creator-action/creator-action.helper';

import stockAdjustmentActions from './stock-adjustment.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  [SLICE_NAME_STOCK_ADJUSTMENT_DETAILS]: null,
  [SLICE_NAME_STOCK_ADJUSTMENT_LIST]: LocalStorage.get(LOCAL_STORAGE_STOCK_ADJUSTMENT_LIST),
  [SLICE_NAME_STOCK_ADJUSTMENT_PRINT_LIST]: LocalStorage.get(
    LOCAL_STORAGE_STOCK_ADJUSTMENT_PRINT_LIST,
  ),
};

const stockAdjustmentSlice = createSlice({
  name: NAME_STORE.STOCK_ADJUSTMENT,
  initialState,
  reducers: {
    stockAdjustmentActions,
    clearDataStockAdjustmentSlice: (state, action) => {
      const { sliceName, localStorageName } = action.payload;

      if (localStorageName && LocalStorage.get(localStorageName)) {
        LocalStorage.remove(localStorageName);
      }

      return {
        ...state,
        [sliceName]: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    clearStockAdjustmentSliceItem: (state, action) => {
      sliceReduceHelper.clearSliceItem({
        state,
        action,
      });
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      [SLICE_NAME_STOCK_ADJUSTMENT_LIST]: LOCAL_STORAGE_STOCK_ADJUSTMENT_LIST,
      [SLICE_NAME_STOCK_ADJUSTMENT_PRINT_LIST]: LOCAL_STORAGE_STOCK_ADJUSTMENT_PRINT_LIST,
    }),
  },
  extraReducers: (builder) => {
    // list stock adjustment with dynamic store data
    builder.addCase(
      stockAdjustmentActions.getStockAdjustmentListWithDynamicStore.fulfilled,
      (state, action) => {
        const sliceName = getSliceNameFromDynamicStore(action.payload);

        if (!sliceName) return;

        state[sliceName] = action.payload[sliceName];
        state.statusRequest = STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_SUCCESS;
      },
    ),
      builder.addCase(
        stockAdjustmentActions.getStockAdjustmentListWithDynamicStore.pending,
        (state) => {
          state.statusRequest = STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_PENDING;
        },
      ),
      builder.addCase(
        stockAdjustmentActions.getStockAdjustmentListWithDynamicStore.rejected,
        (state, action) => {
          const sliceName = getSliceNameFromDynamicStore(action.payload);

          if (!sliceName) return;

          state[sliceName] = null;
          state.statusRequest = STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_FAILED;
        },
      ),
      // list refresh stock adjustment with dynamic store data
      builder.addCase(
        stockAdjustmentActions.getStockAdjustmentListRefreshWithDynamicStore.fulfilled,
        (state, action) => {
          const sliceName = getSliceNameFromDynamicStore(action.payload);

          if (!sliceName) return;

          state[sliceName] = action.payload[sliceName];
          state.statusRequest = STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_SUCCESS;
        },
      ),
      builder.addCase(
        stockAdjustmentActions.getStockAdjustmentListRefreshWithDynamicStore.pending,
        (state) => {
          state.statusRequest = STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_PENDING;
        },
      ),
      builder.addCase(
        stockAdjustmentActions.getStockAdjustmentListRefreshWithDynamicStore.rejected,
        (state, action) => {
          const sliceName = getSliceNameFromDynamicStore(action.payload);

          if (!sliceName) return;

          state[sliceName] = null;
          state.statusRequest = STATUS_REQUEST_LIST_STOCK_ADJUSTMENT_FAILED;
        },
      ),
      // stock adjustment details
      builder.addCase(
        stockAdjustmentActions.getStockAdjustmentDetails.fulfilled,
        (state, action) => {
          state[SLICE_NAME_STOCK_ADJUSTMENT_DETAILS] =
            action.payload[SLICE_NAME_STOCK_ADJUSTMENT_DETAILS];
          state.statusRequest = STATUS_REQUEST_DETAILS_STOCK_ADJUSTMENT_SUCCESS;
        },
      ),
      builder.addCase(stockAdjustmentActions.getStockAdjustmentDetails.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAILS_STOCK_ADJUSTMENT_PENDING;
      }),
      builder.addCase(stockAdjustmentActions.getStockAdjustmentDetails.rejected, (state) => {
        state[SLICE_NAME_STOCK_ADJUSTMENT_DETAILS] = null;
        state.statusRequest = STATUS_REQUEST_DETAILS_STOCK_ADJUSTMENT_FAILED;
      }),
      // add stock adjustment
      builder.addCase(stockAdjustmentActions.addStockAdjustment.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_STOCK_ADJUSTMENT_SUCCESS;
      }),
      builder.addCase(stockAdjustmentActions.addStockAdjustment.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_STOCK_ADJUSTMENT_PENDING;
      }),
      builder.addCase(stockAdjustmentActions.addStockAdjustment.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_STOCK_ADJUSTMENT_FAILED;
      }),
      // add stock adjustment version 2
      builder.addCase(stockAdjustmentActions.addStockAdjustmentV2.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_STOCK_ADJUSTMENT_SUCCESS;
      }),
      builder.addCase(stockAdjustmentActions.addStockAdjustmentV2.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_STOCK_ADJUSTMENT_PENDING;
      }),
      builder.addCase(stockAdjustmentActions.addStockAdjustmentV2.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_STOCK_ADJUSTMENT_FAILED;
      }),
      // update stock adjustment
      builder.addCase(stockAdjustmentActions.updateStockAdjustment.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_STOCK_ADJUSTMENT_SUCCESS;
      }),
      builder.addCase(stockAdjustmentActions.updateStockAdjustment.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_STOCK_ADJUSTMENT_PENDING;
      }),
      builder.addCase(stockAdjustmentActions.updateStockAdjustment.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_STOCK_ADJUSTMENT_FAILED;
      }),
      // delete stock adjustment
      builder.addCase(stockAdjustmentActions.deleteStockAdjustment.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_STOCK_ADJUSTMENT_SUCCESS;
      }),
      builder.addCase(stockAdjustmentActions.deleteStockAdjustment.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_STOCK_ADJUSTMENT_PENDING;
      }),
      builder.addCase(stockAdjustmentActions.deleteStockAdjustment.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_STOCK_ADJUSTMENT_FAILED;
      }),
      // enhancement for matching request status action on stock adjustment
      // current: only include list
      builder.addMatcher(
        (action) => isFulfilledAction({ action, baseType: STOCK_ADJUSTMENT_BASE_ACTION_TYPE }),
        (state, action) => {
          bodyBuilderFulfilledAction({
            state,
            action,
            mappingAsyncThunkTypeWithActionProps: stockAdjustmentAsyncThunkTypeWithActionProps,
          });
        },
      ),
      builder.addMatcher(
        (action) => isPendingAction({ action, baseType: STOCK_ADJUSTMENT_BASE_ACTION_TYPE }),
        (state, action) => {
          bodyBuilderPendingAction({
            state,
            action,
            mappingAsyncThunkTypeWithActionProps: stockAdjustmentAsyncThunkTypeWithActionProps,
          });
        },
      ),
      builder.addMatcher(
        (action) => isRejectedAction({ action, baseType: STOCK_ADJUSTMENT_BASE_ACTION_TYPE }),
        (state, action) => {
          bodyBuilderRejectedAction({
            state,
            action,
            mappingAsyncThunkTypeWithActionProps: stockAdjustmentAsyncThunkTypeWithActionProps,
          });
        },
      );
  },
});

export const { actions, reducer } = stockAdjustmentSlice;

export const { clearDataStockAdjustmentSlice, clearStockAdjustmentSliceItem } = actions;

export default reducer;
