import { checkboxVariant } from '../../../components/checkbox/CheckboxWithLabel/CheckboxWithLabel';
import {
  DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
  DEFAULT_CHIP_COMPONENT_NAME_INFO_STATUS,
  DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
  DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
  DEFAULT_CHIP_COMPONENT_NAME_WARNING_STATUS,
} from '../../default/chip-component-name.default';
import {
  ADDITIONAL_PURCHASE_RETURN_STATUS_ALL,
  DEFAULT_ADDITIONAL_STATUS_PURCHASE_DEBIT_NOTE_ALL,
  DEFAULT_ADDITIONAL_STATUS_PURCHASE_DELIVERY_ALL,
  DEFAULT_ADDITIONAL_STATUS_PURCHASE_ORDER_ALL,
  DEFAULT_ADDITIONAL_STATUS_PURCHASE_QUOTATION_REQUEST_ALL,
  DEFAULT_PURCHASE_INVOICE_CHART_BASIC_STATUS_OPEN,
  DEFAULT_PURCHASE_INVOICE_CHART_BASIC_STATUS_PAID,
  DEFAULT_PURCHASE_INVOICE_CHART_BASIC_STATUS_PARTIALLY_PAID,
  DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_CLOSED,
  DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_OPEN,
  DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_PAID,
  DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_PARTIALLY_PAID,
  DEFAULT_STATUS_PURCHASE_DELIVERY_CLOSED,
  DEFAULT_STATUS_PURCHASE_DELIVERY_OPEN,
  DEFAULT_STATUS_PURCHASE_DELIVERY_PARTIALLY_SENT,
  DEFAULT_STATUS_PURCHASE_DELIVERY_REJECT,
  DEFAULT_STATUS_PURCHASE_INVOICE_ALL,
  DEFAULT_STATUS_PURCHASE_INVOICE_OPEN,
  DEFAULT_STATUS_PURCHASE_INVOICE_OVERDUE,
  DEFAULT_STATUS_PURCHASE_INVOICE_PAID,
  DEFAULT_STATUS_PURCHASE_INVOICE_PARTIALLY_PAID,
  DEFAULT_STATUS_PURCHASE_ORDER_CLOSED,
  DEFAULT_STATUS_PURCHASE_ORDER_OPEN,
  DEFAULT_STATUS_PURCHASE_ORDER_PARTIALLY_SENT,
  DEFAULT_STATUS_PURCHASE_ORDER_REJECT,
  DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_CLOSE,
  DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_OPEN,
  DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_REJECT,
  PURCHASE_RETURN_STATUS_CLOSED,
  PURCHASE_RETURN_STATUS_OPEN,
} from '../../default/purchase-status.default';

/**
 *
 * @param { function } t translation function
 * @returns
 *      purchase status with pair of label and value
 *      usually value used for hitting backed
 */
export function purchaseStatus(t) {
  return [
    {
      label: t('label.purchase-status.all'),
      value: 'all',
    },
    {
      label: t('label.purchase-status.open'),
      value: 'open',
    },
    {
      label: t('label.purchase-status.reject'),
      value: 'reject',
    },
    {
      label: t('label.purchase-status.closed'),
      value: 'closed',
    },
    {
      label: t('label.purchase-status.partially-sent'),
      value: 'partially_sent',
    },
  ];
}

/**
 *
 * @param   { function } t translation function
 * @returns
 *      pair label, value, and colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 *      - colorChip is for adding color on chip related to theme
 */
export function purchaseInvoiceStatus(t) {
  return [
    {
      label: t('label.purchase-invoice-status.all'),
      value: DEFAULT_STATUS_PURCHASE_INVOICE_ALL,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
    {
      label: t('label.purchase-invoice-status.open'),
      value: DEFAULT_STATUS_PURCHASE_INVOICE_OPEN,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
    {
      label: t('label.purchase-invoice-status.partially-paid'),
      value: DEFAULT_STATUS_PURCHASE_INVOICE_PARTIALLY_PAID,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_INFO_STATUS,
    },
    {
      label: t('label.purchase-invoice-status.paid'),
      value: DEFAULT_STATUS_PURCHASE_INVOICE_PAID,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
    },
    {
      label: t('label.purchase-invoice-status.overdue'),
      value: DEFAULT_STATUS_PURCHASE_INVOICE_OVERDUE,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
    },
  ];
}

export const purchaseInvoicePlainStatus = [
  {
    label: 'label.purchase-invoice-status.all',
    value: DEFAULT_STATUS_PURCHASE_INVOICE_ALL,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
  },
  {
    label: 'label.purchase-invoice-status.open',
    value: DEFAULT_STATUS_PURCHASE_INVOICE_OPEN,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
  },
  {
    label: 'label.purchase-invoice-status.partially-paid',
    value: DEFAULT_STATUS_PURCHASE_INVOICE_PARTIALLY_PAID,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_INFO_STATUS,
  },
  {
    label: 'label.purchase-invoice-status.paid',
    value: DEFAULT_STATUS_PURCHASE_INVOICE_PAID,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
  },
  {
    label: 'label.purchase-invoice-status.overdue',
    value: DEFAULT_STATUS_PURCHASE_INVOICE_OVERDUE,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
  },
];

export const mappingPurchaseInvoiceStatus = {
  [DEFAULT_STATUS_PURCHASE_INVOICE_OPEN]: 'label.purchase-invoice-status.open',
  [DEFAULT_STATUS_PURCHASE_INVOICE_PARTIALLY_PAID]: 'label.purchase-invoice-status.partially-paid',
  [DEFAULT_STATUS_PURCHASE_INVOICE_PAID]: 'label.purchase-invoice-status.paid',
  [DEFAULT_STATUS_PURCHASE_INVOICE_OVERDUE]: 'label.purchase-invoice-status.overdue',
};

/**
 *
 * @param   { function } t translation function
 * @returns
 *      pair label, value, and colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 *      - colorChip is for adding color on chip related to theme
 */
export function purchaseInvoiceChartBasicStatus(t) {
  return [
    {
      label: t('label.purchase-invoice-chart-basic-status.open'),
      value: DEFAULT_PURCHASE_INVOICE_CHART_BASIC_STATUS_OPEN,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
    },
    {
      label: t('label.purchase-invoice-chart-basic-status.partially-paid'),
      value: DEFAULT_PURCHASE_INVOICE_CHART_BASIC_STATUS_PARTIALLY_PAID,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_WARNING_STATUS,
    },
    {
      label: t('label.purchase-invoice-chart-basic-status.close'),
      value: DEFAULT_PURCHASE_INVOICE_CHART_BASIC_STATUS_PAID,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
    },
  ];
}

/**
 *
 * @param   { function } t translation function
 * @returns
 *      pair label, value, and colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 *      - colorChip is for adding color on chip related to theme
 */
export function purchaseInvoiceChartBasicStatusWithCheckboxVariant(t) {
  return [
    {
      label: t('label.purchase-invoice-chart-basic-status.close'),
      value: DEFAULT_PURCHASE_INVOICE_CHART_BASIC_STATUS_PAID,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
      checkboxVariant: checkboxVariant.successPastel,
    },
    {
      label: t('label.purchase-invoice-chart-basic-status.partially-paid'),
      value: DEFAULT_PURCHASE_INVOICE_CHART_BASIC_STATUS_PARTIALLY_PAID,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_WARNING_STATUS,
      checkboxVariant: checkboxVariant.warningPastel,
    },
    {
      label: t('label.purchase-invoice-chart-basic-status.open'),
      value: DEFAULT_PURCHASE_INVOICE_CHART_BASIC_STATUS_OPEN,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
      checkboxVariant: checkboxVariant.errorPastel,
    },
  ];
}

/**
 *
 * @param   { function } t translation function
 * @returns
 *      pair label, value, and colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 *      - colorChip is for adding color on chip related to theme
 */
export function purchaseQuotationRequestStatus(t) {
  return [
    {
      label: t('label.purchase-quotation-request-status.all'),
      value: DEFAULT_ADDITIONAL_STATUS_PURCHASE_QUOTATION_REQUEST_ALL,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
    {
      label: t('label.purchase-quotation-request-status.open'),
      value: DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_OPEN,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
    {
      label: t('label.purchase-quotation-request-status.close'),
      value: DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_CLOSE,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
    },
    {
      label: t('label.purchase-quotation-request-status.reject'),
      value: DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_REJECT,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
    },
  ];
}

export const purchaseQuotationRequestPlainStatus = [
  {
    label: 'label.purchase-quotation-request-status.all',
    value: DEFAULT_ADDITIONAL_STATUS_PURCHASE_QUOTATION_REQUEST_ALL,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
  },
  {
    label: 'label.purchase-quotation-request-status.open',
    value: DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_OPEN,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
  },
  {
    label: 'label.purchase-quotation-request-status.close',
    value: DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_CLOSE,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
  },
  {
    label: 'label.purchase-quotation-request-status.reject',
    value: DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_REJECT,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
  },
];

export const mappingPurchaseQuotationStatus = {
  [DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_OPEN]: 'label.purchase-quotation-request-status.open',
  [DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_CLOSE]:
    'label.purchase-quotation-request-status.close',
  [DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_REJECT]:
    'label.purchase-quotation-request-status.reject',
};

/**
 *
 * @param   { function } t translation function
 * @returns
 *      pair label, value, and colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 *      - colorChip is for adding color on chip related to theme
 */
export function purchaseOrderStatus(t) {
  return [
    {
      label: t('label.purchase-order-status.all'),
      value: DEFAULT_ADDITIONAL_STATUS_PURCHASE_ORDER_ALL,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
    {
      label: t('label.purchase-order-status.open'),
      value: DEFAULT_STATUS_PURCHASE_ORDER_OPEN,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
    {
      label: t('label.purchase-order-status.partially-sent'),
      value: DEFAULT_STATUS_PURCHASE_ORDER_PARTIALLY_SENT,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_INFO_STATUS,
    },
    {
      label: t('label.purchase-order-status.reject'),
      value: DEFAULT_STATUS_PURCHASE_ORDER_REJECT,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
    },
    {
      label: t('label.purchase-order-status.closed'),
      value: DEFAULT_STATUS_PURCHASE_ORDER_CLOSED,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
    },
  ];
}

export const purchaseOrderPlainStatus = [
  {
    label: 'label.purchase-order-status.all',
    value: DEFAULT_ADDITIONAL_STATUS_PURCHASE_ORDER_ALL,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
  },
  {
    label: 'label.purchase-order-status.open',
    value: DEFAULT_STATUS_PURCHASE_ORDER_OPEN,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
  },
  {
    label: 'label.purchase-order-status.partially-sent',
    value: DEFAULT_STATUS_PURCHASE_ORDER_PARTIALLY_SENT,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_INFO_STATUS,
  },
  {
    label: 'label.purchase-order-status.reject',
    value: DEFAULT_STATUS_PURCHASE_ORDER_REJECT,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
  },
  {
    label: 'label.purchase-order-status.closed',
    value: DEFAULT_STATUS_PURCHASE_ORDER_CLOSED,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
  },
];

export const mappingPurchaseOrderStatus = {
  [DEFAULT_STATUS_PURCHASE_ORDER_OPEN]: 'label.purchase-order-status.open',
  [DEFAULT_STATUS_PURCHASE_ORDER_PARTIALLY_SENT]: 'label.purchase-order-status.partially-sent',
  [DEFAULT_STATUS_PURCHASE_ORDER_REJECT]: 'label.purchase-order-status.reject',
  [DEFAULT_STATUS_PURCHASE_ORDER_CLOSED]: 'label.purchase-order-status.closed',
};

/**
 *
 * @param   { function } t translation function
 * @returns
 *      pair label, value, and colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 *      - colorChip is for adding color on chip related to theme
 */
export function purchaseDeliveryStatus(t) {
  return [
    {
      label: t('label.purchase-delivery-status.all'),
      value: DEFAULT_ADDITIONAL_STATUS_PURCHASE_DELIVERY_ALL,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
    {
      label: t('label.purchase-delivery-status.open'),
      value: DEFAULT_STATUS_PURCHASE_DELIVERY_OPEN,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
    {
      label: t('label.purchase-delivery-status.partially-sent'),
      value: DEFAULT_STATUS_PURCHASE_DELIVERY_PARTIALLY_SENT,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_INFO_STATUS,
    },
    {
      label: t('label.purchase-delivery-status.reject'),
      value: DEFAULT_STATUS_PURCHASE_DELIVERY_REJECT,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
    },
    {
      label: t('label.purchase-delivery-status.sent'),
      value: DEFAULT_STATUS_PURCHASE_DELIVERY_CLOSED,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
    },
  ];
}

export const purchaseDeliveryPlainStatus = [
  {
    label: 'label.purchase-delivery-status.all',
    value: DEFAULT_ADDITIONAL_STATUS_PURCHASE_DELIVERY_ALL,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
  },
  {
    label: 'label.purchase-delivery-status.open',
    value: DEFAULT_STATUS_PURCHASE_DELIVERY_OPEN,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
  },
  {
    label: 'label.purchase-delivery-status.partially-sent',
    value: DEFAULT_STATUS_PURCHASE_DELIVERY_PARTIALLY_SENT,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_INFO_STATUS,
  },
  {
    label: 'label.purchase-delivery-status.reject',
    value: DEFAULT_STATUS_PURCHASE_DELIVERY_REJECT,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
  },
  {
    label: 'label.purchase-delivery-status.sent',
    value: DEFAULT_STATUS_PURCHASE_DELIVERY_CLOSED,
    colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
  },
];

export const mappingPurchaseDeliveryStatus = {
  [DEFAULT_STATUS_PURCHASE_DELIVERY_OPEN]: 'label.purchase-delivery-status.open',
  [DEFAULT_STATUS_PURCHASE_DELIVERY_PARTIALLY_SENT]:
    'label.purchase-delivery-status.partially-sent',
  [DEFAULT_STATUS_PURCHASE_DELIVERY_REJECT]: 'label.purchase-delivery-status.reject',
  [DEFAULT_STATUS_PURCHASE_DELIVERY_CLOSED]: 'label.purchase-delivery-status.sent',
};

/**
 *
 * @param   { function } t translation function
 * @returns
 *      pair label, value, and colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 *      - colorChip is for adding color on chip related to theme
 */
export function purchaseInvoiceBasicStatus(t) {
  return [
    {
      label: t('label.purchase-invoice-status.not-paid-yet'),
      value: DEFAULT_STATUS_PURCHASE_INVOICE_OPEN,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_ERROR_STATUS,
    },
  ];
}

/**
 *
 * @param { function } t    translation function
 *
 *      pair label, value, and colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 *      - colorChip is for adding color on chip related to theme
 *
 * @returns
 */
export function purchaseDebitNoteStatus(t) {
  return [
    {
      label: t('label.all'),
      value: DEFAULT_ADDITIONAL_STATUS_PURCHASE_DEBIT_NOTE_ALL,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
    {
      label: t('label.purchase-debit-note-status.open'),
      value: DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_OPEN,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
    {
      label: t('label.purchase-debit-note-status.paid'),
      value: DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_PAID,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
    },
    {
      label: t('label.purchase-debit-note-status.partially-paid'),
      value: DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_PARTIALLY_PAID,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_INFO_STATUS,
    },
  ];
}

export function purchaseDebitNoteStatusV2(t) {
  return [
    ...purchaseDebitNoteStatus(t),
    {
      label: t('label.purchase-debit-note-status.closed'),
      value: DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_CLOSED,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
    },
  ];
}

export const mappingPurchaseDebitNoteStatus = {
  [DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_OPEN]: 'label.purchase-debit-note-status.open',
  [DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_PAID]: 'label.purchase-debit-note-status.paid',
  [DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_PARTIALLY_PAID]:
    'label.purchase-debit-note-status.partially-paid',
};

/**
 *
 * @param { function } t    translation function
 *
 *      pair label, value, and colorChip
 *      where is label for displaying on user
 *      - value for hitting endpoint
 *      - colorChip is for adding color on chip related to theme
 *
 * @returns
 */
export function purchaseReturnStatus(t) {
  return [
    {
      label: t('label.all'),
      value: ADDITIONAL_PURCHASE_RETURN_STATUS_ALL,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
    {
      label: t('label.purchase-return-status.open'),
      value: PURCHASE_RETURN_STATUS_OPEN,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SECONDARY_STATUS,
    },
    {
      label: t('label.purchase-return-status.closed'),
      value: PURCHASE_RETURN_STATUS_CLOSED,
      colorChip: DEFAULT_CHIP_COMPONENT_NAME_SUCCESS_STATUS,
    },
  ];
}
