export const ACCOUNT = {
  PURCHASE: {
    ID: 20866,
    CODE: '5-50000',
    NAME: 'Biaya Penjualan',
  },
  SALES: {
    ID: 20863,
    CODE: '4-40000',
    NAME: 'Pendapatan',
  },
  INVENTORY: {
    ID: 20815,
    CODE: '1-10200',
    NAME: 'Inventaris',
  },
  INVENTORY_ADJUSTMENT: {
    ID: 20940,
    CODE: '8-80100',
    NAME: 'Penyesuaian Persediaan',
  },
  CASH: {
    ID: 20841,
    CODE: '1-10001',
    NAME: 'Kas',
  },
};

export const DEFAULT_ACCOUNT_NAME_INVENTORY = 'Persediaan Barang';
export const DEFAULT_ACCOUNT_NAME_COST_OF_SALES = 'Biaya Penjualan';
export const DEFAULT_ACCOUNT_NAME_UNBILLED_ACCOUNT_PAYABLE = 'Hutang Belum Ditagih';

export const DEFAULT_ACCOUNT_CASH_BANK_NAME_CASH = 'Kas';
export const DEFAULT_ACCOUNT_NAME_SALES_DISCOUNT = 'Diskon Penjualan';
export const DEFAULT_ACCOUNT_NAME_PURCHASE_DISCOUNT = 'Diskon Pembelian';
export const DEFAULT_ACCOUNT_NAME_CASH = 'Kas';

export const DEFAULT_ACCOUNT_NAME_PENDAPATAN = 'Pendapatan';
export const DEFAULT_ACCOUNT_NAME_BIAYA_PENJUALAN = 'Biaya Penjualan';
export const DEFAULT_ACCOUNT_NAME_BEBAN_POKOK_PENDAPATAN = 'Beban Pokok Pendapatan';
export const DEFAULT_ACCOUNT_NAME_DISKON_PENJUALAN = 'Diskon Penjualan';

export const DEFAULT_ACCOUNT_OTHER_EXPENSES_NAME = 'Beban Lain - Lain';
export const DEFAULT_ACCOUNT_OTHER_INCOMES_NAME = 'Pendapatan Lain - Lain';

export const DEFAULT_ACCOUNT_NAME_INVENTORY_ADJUSTMENT = 'penyesuaian_persediaan';

export const DEFAULT_ACCOUNT_NAME_CASH_IND = 'Kas';

export const DEFAULT_ACCOUNT_NAME_OPENING_BALANCE_EQUITY_IND = 'Ekuitas Saldo Awal';

export const DEFAULT_ACCOUNT_RETAINED_EARNINGS_NAME_IND = 'Laba Ditahan';

export const FIXED_ASSET_ACCOUNT_OFFICE_SUPPLIES = 'Aset Tetap - Perlengkapan Kantor';
