import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  LOCAL_STORAGE_BILLING_INVOICE_DETAILS,
  LOCAL_STORAGE_BILLING_INVOICE_LIST,
  LOCAL_STORAGE_BILLING_PACKAGE_LIST,
} from '../../utils/constants/storage.constant';
import {
  REQUEST_STATUS_BILLING_DETAILS_FAILED,
  REQUEST_STATUS_BILLING_DETAILS_PENDING,
  REQUEST_STATUS_BILLING_DETAILS_SUCCESS,
  REQUEST_STATUS_CREATE_BILLING_FAILED,
  REQUEST_STATUS_CREATE_BILLING_PENDING,
  REQUEST_STATUS_CREATE_BILLING_SUCCESS,
  REQUEST_STATUS_DETAILS_BILLING_INVOICE_FAILED,
  REQUEST_STATUS_DETAILS_BILLING_INVOICE_PENDING,
  REQUEST_STATUS_DETAILS_BILLING_INVOICE_SUCCESS,
  REQUEST_STATUS_LIST_BILLING_INVOICE_FAILED,
  REQUEST_STATUS_LIST_BILLING_INVOICE_PENDING,
  REQUEST_STATUS_LIST_BILLING_INVOICE_SUCCESS,
  REQUEST_STATUS_LIST_BILLING_PACKAGE_FAILED,
  REQUEST_STATUS_LIST_BILLING_PACKAGE_PENDING,
  REQUEST_STATUS_LIST_BILLING_PACKAGE_SUCCESS,
  REQUEST_STATUS_PAY_BILLING_FAILED,
  REQUEST_STATUS_PAY_BILLING_PENDING,
  REQUEST_STATUS_PAY_BILLING_SUCCESS,
  REQUEST_STATUS_UPDATE_BILLING_FAILED,
  REQUEST_STATUS_UPDATE_BILLING_PENDING,
  REQUEST_STATUS_UPDATE_BILLING_SUCCESS,
  ROOT_STORE_NAME_BILLING,
  SLICE_NAME_BILLING_DETAILS,
  SLICE_NAME_BILLING_INVOICE_DETAILS,
  SLICE_NAME_BILLING_INVOICE_LIST,
  SLICE_NAME_BILLING_PACKAGE_LIST,
  SLICE_NAME_CREATE_BILLING_TEMP,
  SLICE_NAME_PAY_BILLING_TEMP,
  SLICE_NAME_SELECTED_BILLING_PACKAGE,
  STATUS_REQUEST_BASE_IDDLE,
} from '../../utils/constants/store.constant';
import arrHelp from '../../utils/helpers/array.helpers';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import billingActions from './billing.action';

const initialState = {
  requestStatus: STATUS_REQUEST_BASE_IDDLE,
  requestStatusParallel: [],
  [SLICE_NAME_BILLING_DETAILS]: null,
  [SLICE_NAME_BILLING_INVOICE_LIST]: LocalStorage.get(LOCAL_STORAGE_BILLING_INVOICE_LIST),
  [SLICE_NAME_BILLING_INVOICE_DETAILS]: LocalStorage.get(LOCAL_STORAGE_BILLING_INVOICE_DETAILS),
  [SLICE_NAME_BILLING_PACKAGE_LIST]: LocalStorage.get(LOCAL_STORAGE_BILLING_PACKAGE_LIST),
  [SLICE_NAME_SELECTED_BILLING_PACKAGE]: null,
  [SLICE_NAME_CREATE_BILLING_TEMP]: null,
  [SLICE_NAME_PAY_BILLING_TEMP]: null,
};

const billingSlice = createSlice({
  name: ROOT_STORE_NAME_BILLING,
  initialState,
  reducers: {
    particularChangeBillingSlice: (state, action) => {
      const { sliceName = null, sliceValue = null } = action.payload;

      if (!sliceName || !sliceValue) return state;

      return {
        ...state,
        [sliceName]: sliceValue,
      };
    },
    clearDataBillingSlice: (state, action) => {
      const { sliceName = SLICE_NAME_BILLING_DETAILS, localStorageName = null } = action.payload;

      if (localStorageName) LocalStorage.remove(localStorageName);

      return {
        ...state,
        [sliceName]: null,
        requestStatus: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      [SLICE_NAME_BILLING_INVOICE_LIST]: LOCAL_STORAGE_BILLING_INVOICE_LIST,
      [SLICE_NAME_BILLING_INVOICE_DETAILS]: LOCAL_STORAGE_BILLING_INVOICE_DETAILS,
      [SLICE_NAME_BILLING_PACKAGE_LIST]: LOCAL_STORAGE_BILLING_PACKAGE_LIST,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(billingActions.getBillingDetailsFromDummy.fulfilled, (state, action) => {
      state.requestStatus = REQUEST_STATUS_BILLING_DETAILS_SUCCESS;
      state[SLICE_NAME_BILLING_DETAILS] = action.payload;
    }),
      builder.addCase(billingActions.getBillingDetailsFromDummy.pending, (state) => {
        state.requestStatus = REQUEST_STATUS_BILLING_DETAILS_PENDING;
      }),
      builder.addCase(billingActions.getBillingDetailsFromDummy.rejected, (state) => {
        state.requestStatus = REQUEST_STATUS_BILLING_DETAILS_FAILED;
        state[SLICE_NAME_BILLING_DETAILS] = null;
      }),
      // billing package list
      builder.addCase(billingActions.getBillingPackageList.fulfilled, (state, action) => {
        state.requestStatus = REQUEST_STATUS_LIST_BILLING_PACKAGE_SUCCESS;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_LIST_BILLING_PACKAGE_FAILED,
            REQUEST_STATUS_LIST_BILLING_PACKAGE_PENDING,
          ],
          itemsSubtitue: [REQUEST_STATUS_LIST_BILLING_PACKAGE_SUCCESS],
        });

        const responsePayload = action.payload[SLICE_NAME_BILLING_PACKAGE_LIST];
        state[SLICE_NAME_BILLING_PACKAGE_LIST] = responsePayload;

        if (responsePayload && responsePayload.data) {
          state[SLICE_NAME_SELECTED_BILLING_PACKAGE] = responsePayload.data[0];
        }
      }),
      builder.addCase(billingActions.getBillingPackageList.pending, (state) => {
        state.requestStatus = REQUEST_STATUS_LIST_BILLING_PACKAGE_PENDING;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_LIST_BILLING_PACKAGE_FAILED,
            REQUEST_STATUS_LIST_BILLING_PACKAGE_SUCCESS,
          ],
          itemsSubtitue: [REQUEST_STATUS_LIST_BILLING_PACKAGE_PENDING],
        });
      }),
      builder.addCase(billingActions.getBillingPackageList.rejected, (state) => {
        state.requestStatus = REQUEST_STATUS_LIST_BILLING_PACKAGE_FAILED;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_LIST_BILLING_PACKAGE_SUCCESS,
            REQUEST_STATUS_LIST_BILLING_PACKAGE_PENDING,
          ],
          itemsSubtitue: [REQUEST_STATUS_LIST_BILLING_PACKAGE_FAILED],
        });

        state[SLICE_NAME_BILLING_PACKAGE_LIST] = null;
        state[SLICE_NAME_SELECTED_BILLING_PACKAGE] = null;
      }),
      // billing invoice list
      builder.addCase(billingActions.getUserBillingList.fulfilled, (state, action) => {
        state.requestStatus = REQUEST_STATUS_LIST_BILLING_INVOICE_SUCCESS;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_LIST_BILLING_INVOICE_FAILED,
            REQUEST_STATUS_LIST_BILLING_INVOICE_PENDING,
          ],
          itemsSubtitue: [REQUEST_STATUS_LIST_BILLING_INVOICE_SUCCESS],
        });

        state[SLICE_NAME_BILLING_INVOICE_LIST] = action.payload[SLICE_NAME_BILLING_INVOICE_LIST];
      }),
      builder.addCase(billingActions.getUserBillingList.pending, (state) => {
        state.requestStatus = REQUEST_STATUS_LIST_BILLING_INVOICE_PENDING;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_LIST_BILLING_INVOICE_SUCCESS,
            REQUEST_STATUS_LIST_BILLING_INVOICE_FAILED,
          ],
          itemsSubtitue: [REQUEST_STATUS_LIST_BILLING_INVOICE_PENDING],
        });
      }),
      builder.addCase(billingActions.getUserBillingList.rejected, (state) => {
        state.requestStatus = REQUEST_STATUS_LIST_BILLING_INVOICE_FAILED;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_LIST_BILLING_INVOICE_PENDING,
            REQUEST_STATUS_LIST_BILLING_INVOICE_SUCCESS,
          ],
          itemsSubtitue: [REQUEST_STATUS_LIST_BILLING_INVOICE_FAILED],
        });

        state[SLICE_NAME_BILLING_INVOICE_LIST] = null;
      }),
      // billing invoice details
      builder.addCase(billingActions.getUserBillingDetails.fulfilled, (state, action) => {
        state.requestStatus = REQUEST_STATUS_DETAILS_BILLING_INVOICE_SUCCESS;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_DETAILS_BILLING_INVOICE_FAILED,
            REQUEST_STATUS_DETAILS_BILLING_INVOICE_PENDING,
          ],
          itemsSubtitue: [REQUEST_STATUS_DETAILS_BILLING_INVOICE_SUCCESS],
        });

        state[SLICE_NAME_BILLING_INVOICE_DETAILS] =
          action.payload[SLICE_NAME_BILLING_INVOICE_DETAILS];
      }),
      builder.addCase(billingActions.getUserBillingDetails.pending, (state) => {
        state.requestStatus = REQUEST_STATUS_DETAILS_BILLING_INVOICE_PENDING;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_DETAILS_BILLING_INVOICE_SUCCESS,
            REQUEST_STATUS_DETAILS_BILLING_INVOICE_FAILED,
          ],
          itemsSubtitue: [REQUEST_STATUS_DETAILS_BILLING_INVOICE_PENDING],
        });
      }),
      builder.addCase(billingActions.getUserBillingDetails.rejected, (state) => {
        state.requestStatus = REQUEST_STATUS_DETAILS_BILLING_INVOICE_FAILED;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_DETAILS_BILLING_INVOICE_PENDING,
            REQUEST_STATUS_DETAILS_BILLING_INVOICE_SUCCESS,
          ],
          itemsSubtitue: [REQUEST_STATUS_DETAILS_BILLING_INVOICE_FAILED],
        });

        state[SLICE_NAME_BILLING_INVOICE_DETAILS] = null;
      }),
      // create billing
      builder.addCase(billingActions.createBilling.fulfilled, (state, action) => {
        state.requestStatus = REQUEST_STATUS_CREATE_BILLING_SUCCESS;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_CREATE_BILLING_FAILED,
            REQUEST_STATUS_CREATE_BILLING_PENDING,
          ],
          itemsSubtitue: [REQUEST_STATUS_CREATE_BILLING_SUCCESS],
        });

        state[SLICE_NAME_CREATE_BILLING_TEMP] = action.payload[SLICE_NAME_CREATE_BILLING_TEMP];
      }),
      builder.addCase(billingActions.createBilling.pending, (state) => {
        state.requestStatus = REQUEST_STATUS_CREATE_BILLING_PENDING;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_CREATE_BILLING_SUCCESS,
            REQUEST_STATUS_CREATE_BILLING_FAILED,
          ],
          itemsSubtitue: [REQUEST_STATUS_CREATE_BILLING_PENDING],
        });
      }),
      builder.addCase(billingActions.createBilling.rejected, (state) => {
        state.requestStatus = REQUEST_STATUS_CREATE_BILLING_FAILED;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_CREATE_BILLING_PENDING,
            REQUEST_STATUS_CREATE_BILLING_SUCCESS,
          ],
          itemsSubtitue: [REQUEST_STATUS_CREATE_BILLING_FAILED],
        });

        state[SLICE_NAME_CREATE_BILLING_TEMP] = null;
      }),
      // update billing
      builder.addCase(billingActions.updateBilling.fulfilled, (state) => {
        state.requestStatus = REQUEST_STATUS_UPDATE_BILLING_SUCCESS;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_UPDATE_BILLING_FAILED,
            REQUEST_STATUS_UPDATE_BILLING_PENDING,
          ],
          itemsSubtitue: [REQUEST_STATUS_UPDATE_BILLING_SUCCESS],
        });
      }),
      builder.addCase(billingActions.updateBilling.pending, (state) => {
        state.requestStatus = REQUEST_STATUS_UPDATE_BILLING_PENDING;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_UPDATE_BILLING_SUCCESS,
            REQUEST_STATUS_UPDATE_BILLING_FAILED,
          ],
          itemsSubtitue: [REQUEST_STATUS_UPDATE_BILLING_PENDING],
        });
      }),
      builder.addCase(billingActions.updateBilling.rejected, (state) => {
        state.requestStatus = REQUEST_STATUS_UPDATE_BILLING_FAILED;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [
            REQUEST_STATUS_UPDATE_BILLING_PENDING,
            REQUEST_STATUS_UPDATE_BILLING_SUCCESS,
          ],
          itemsSubtitue: [REQUEST_STATUS_UPDATE_BILLING_FAILED],
        });
      }),
      // pay billing
      builder.addCase(billingActions.payBilling.fulfilled, (state, action) => {
        state.requestStatus = REQUEST_STATUS_PAY_BILLING_SUCCESS;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [REQUEST_STATUS_PAY_BILLING_FAILED, REQUEST_STATUS_PAY_BILLING_PENDING],
          itemsSubtitue: [REQUEST_STATUS_PAY_BILLING_SUCCESS],
        });

        state[SLICE_NAME_PAY_BILLING_TEMP] = action.payload[SLICE_NAME_PAY_BILLING_TEMP];
      }),
      builder.addCase(billingActions.payBilling.pending, (state) => {
        state.requestStatus = REQUEST_STATUS_PAY_BILLING_PENDING;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [REQUEST_STATUS_PAY_BILLING_SUCCESS, REQUEST_STATUS_PAY_BILLING_FAILED],
          itemsSubtitue: [REQUEST_STATUS_PAY_BILLING_PENDING],
        });
      }),
      builder.addCase(billingActions.payBilling.rejected, (state) => {
        state.requestStatus = REQUEST_STATUS_PAY_BILLING_FAILED;

        state.requestStatusParallel = arrHelp.removeItemsAndSubtitue({
          arr: state.requestStatusParallel,
          itemsRemove: [REQUEST_STATUS_PAY_BILLING_PENDING, REQUEST_STATUS_PAY_BILLING_SUCCESS],
          itemsSubtitue: [REQUEST_STATUS_PAY_BILLING_FAILED],
        });

        state[SLICE_NAME_PAY_BILLING_TEMP] = null;
      });
  },
});

export const { actions, reducer } = billingSlice;

export const { particularChangeBillingSlice, clearDataBillingSlice } = actions;

export default reducer;
