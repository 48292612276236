import * as React from 'react';
function SvgUploadCloud(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#upload-cloud_svg__clip0_2813_42596)'>
        <path
          d='M13.334 13.333L10 10m0 0l-3.333 3.333M10 10v7.5m6.992-2.175A4.167 4.167 0 0015 7.5h-1.05A6.667 6.667 0 102.5 13.583'
          stroke='#1570EF'
          strokeWidth={1.667}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='upload-cloud_svg__clip0_2813_42596'>
          <path fill='#fff' d='M0 0h20v20H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgUploadCloud;
