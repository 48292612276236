export const DEFAULT_REPRESENTATION_DATA_COMMON_TYPE = 'common';
export const DEFAULT_REPRESENTATION_DATA_PLAIN_TYPE = 'plain';
export const DEFAULT_REPRESENTATION_DATA_CALCULATION_NUMBER_TYPE = 'calculation-number';
export const DEFAULT_REPRESENTATION_DATA_NUMBER_TYPE = 'number';
export const DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE = 'currency-auto-comma';
export const DEFAULT_REPRESENTATION_DATA_LINK_TYPE = 'link';
export const DEFAULT_REPRESENTATION_DATA_LINK_COMMON_CONDITIONAL_TYPE =
  'link-common-with-conditional';
export const DEFAULT_REPRESENTATION_DATA_CHIP_TYPE = 'chip';
export const DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE = 'bold-typography';
export const DEFAULT_REPRESENTATION_DATA_BOX_TYPE = 'box';
export const DEFAULT_REPRESENTATION_DATA_READABLE_DATE_TYPE = 'readable-date';
