import * as React from 'react';
function SvgPurchaseOrderMenu(props) {
  return (
    <svg width={123} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#purchase-order-menu_svg__filter0_d_5586_192805)'>
        <path d='M70.312 38.747H31.571v29.36h38.741v-29.36z' fill='#FFD15D' />
        <path d='M72.25 34.553H29.633v8.388h42.615v-8.388z' fill='#F9B54C' />
        <path
          d='M56.753 50.567H45.13c-1.07 0-1.937-.939-1.937-2.097s.868-2.097 1.937-2.097h11.623c1.07 0 1.937.939 1.937 2.097s-.867 2.097-1.937 2.097z'
          fill='#B97850'
        />
        <path
          d='M43.096 53.959h-7.41c-.762 0-1.361.648-1.361 1.474v8.021c0 .826.599 1.475 1.362 1.475h7.409c.763 0 1.362-.649 1.362-1.475v-8.021c0-.767-.6-1.474-1.362-1.474zm.6 9.495c0 .354-.273.65-.6.65h-7.41c-.326 0-.599-.296-.599-.65v-8.021c0-.354.273-.649.6-.649h7.409c.327 0 .6.295.6.649v8.021z'
          fill='#fff'
        />
        <path
          d='M37.866 56.967c-.055-.06-.055-.06-.11-.06-.054 0-.108-.058-.163-.058-.054 0-.109 0-.163.059-.055 0-.11.059-.11.059l-1.252 1.356c-.163.177-.163.413 0 .53.163.178.381.178.49 0l.654-.707v3.48c0 .236.163.413.381.413s.382-.177.382-.413v-3.48l.654.708a.4.4 0 00.272.118c.109 0 .163-.06.272-.118.164-.177.164-.413 0-.531l-1.307-1.356zM41.462 56.967a.4.4 0 00-.273-.118.4.4 0 00-.272.118l-1.253 1.356c-.163.177-.163.413 0 .53.163.178.381.178.49 0l.654-.707v3.48c0 .236.164.413.382.413.217 0 .38-.177.38-.413v-3.48l.655.708a.4.4 0 00.272.118c.109 0 .163-.06.272-.118.164-.177.164-.413 0-.531l-1.307-1.356zM54.483 53.959h-7.41c-.762 0-1.362.648-1.362 1.474v8.021c0 .826.6 1.475 1.362 1.475h7.41c.762 0 1.362-.649 1.362-1.475v-8.021c0-.767-.6-1.474-1.362-1.474zm.654 9.495c0 .354-.273.65-.6.65h-7.464c-.327 0-.599-.296-.599-.65v-8.021c0-.354.273-.649.6-.649h7.409c.327 0 .599.295.599.649v8.021h.054z'
          fill='#fff'
        />
        <path
          d='M52.412 56.2h-3.214c-.218 0-.382.177-.382.413V58.5c0 1.062.709 1.946 1.635 2.123v1.298h-.49c-.218 0-.382.177-.382.413s.164.412.382.412h1.743c.218 0 .381-.176.381-.412s-.163-.413-.381-.413h-.49v-1.298c.926-.177 1.634-1.061 1.634-2.123v-1.887c-.055-.236-.218-.413-.436-.413zm-.381 2.3c0 .767-.545 1.356-1.253 1.356-.709 0-1.253-.59-1.253-1.356v-1.533h2.506V58.5zM65.868 53.959H58.46c-.763 0-1.362.648-1.362 1.474v8.021c0 .826.6 1.475 1.362 1.475h7.41c.762 0 1.362-.649 1.362-1.475v-8.021c0-.767-.654-1.474-1.362-1.474zm.6 9.495c0 .354-.273.65-.6.65H58.46c-.327 0-.6-.296-.6-.65v-8.021c0-.354.273-.649.6-.649h7.41c.326 0 .599.295.599.649v8.021z'
          fill='#fff'
        />
        <path
          d='M62.164 55.669c-1.798 0-3.269 1.592-3.269 3.539 0 .236.163.413.381.413h2.56v2.654c0 .118-.108.236-.217.236s-.218-.118-.218-.236c0-.236-.163-.413-.381-.413s-.382.177-.382.413c0 .59.436 1.061.981 1.061.545 0 .98-.471.98-1.061V59.62h2.561c.218 0 .382-.177.382-.413-.11-1.947-1.58-3.54-3.378-3.54zm-2.506 3.126c.163-1.357 1.253-2.36 2.506-2.36s2.343 1.003 2.506 2.36h-5.012z'
          fill='#fff'
        />
        <path d='M112.927 38.747H74.186v29.36h38.741v-29.36z' fill='#FFD15D' />
        <path d='M113.25 38.148h-39v29.958h39V38.148z' fill='#F9B54C' />
        <path d='M114.865 34.553H72.249v8.388h42.616v-8.388z' fill='#F9B54C' />
        <path d='M115.25 34.553h-43v8.388h43v-8.388z' fill='#F4A200' />
        <path
          d='M99.368 50.567H87.745c-1.07 0-1.937-.939-1.937-2.097s.867-2.097 1.937-2.097h11.623c1.069 0 1.937.939 1.937 2.097s-.868 2.097-1.938 2.097z'
          fill='#935635'
        />
        <path
          d='M85.711 53.959h-7.41c-.762 0-1.361.648-1.361 1.474v8.021c0 .826.6 1.475 1.362 1.475h7.41c.762 0 1.361-.649 1.361-1.475v-8.021c0-.767-.599-1.474-1.362-1.474zm.6 9.495c0 .354-.273.65-.6.65h-7.41c-.326 0-.598-.296-.598-.65v-8.021c0-.354.272-.649.599-.649h7.41c.326 0 .599.295.599.649v8.021z'
          fill='#fff'
        />
        <path
          d='M80.481 56.967c-.054-.06-.054-.06-.108-.06-.055 0-.11-.058-.164-.058-.054 0-.109 0-.163.059-.055 0-.11.059-.11.059l-1.252 1.356c-.164.177-.164.413 0 .53.163.178.381.178.49 0l.654-.707v3.48c0 .236.163.413.381.413s.381-.177.381-.413v-3.48l.654.708a.4.4 0 00.273.118c.109 0 .163-.06.272-.118.163-.177.163-.413 0-.531l-1.308-1.356zM84.077 56.967a.4.4 0 00-.273-.118.4.4 0 00-.272.118l-1.253 1.356c-.164.177-.164.413 0 .53.163.178.381.178.49 0l.654-.707v3.48c0 .236.163.413.381.413s.382-.177.382-.413v-3.48l.653.708a.4.4 0 00.273.118c.109 0 .163-.06.272-.118.164-.177.164-.413 0-.531l-1.307-1.356zM97.097 53.959h-7.409c-.763 0-1.362.648-1.362 1.474v8.021c0 .826.6 1.475 1.362 1.475h7.41c.762 0 1.362-.649 1.362-1.475v-8.021c0-.767-.6-1.474-1.362-1.474zm.654 9.495c0 .354-.272.65-.599.65h-7.464c-.327 0-.6-.296-.6-.65v-8.021c0-.354.273-.649.6-.649h7.41c.326 0 .599.295.599.649v8.021h.054z'
          fill='#fff'
        />
        <path
          d='M95.027 56.2h-3.214c-.218 0-.381.177-.381.413V58.5c0 1.062.708 1.946 1.634 2.123v1.298h-.49c-.218 0-.382.177-.382.413s.164.412.382.412h1.743c.218 0 .381-.176.381-.412s-.163-.413-.38-.413h-.491v-1.298c.926-.177 1.634-1.061 1.634-2.123v-1.887c-.054-.236-.218-.413-.436-.413zm-.381 2.3c0 .767-.545 1.356-1.253 1.356-.708 0-1.253-.59-1.253-1.356v-1.533h2.506V58.5zM108.484 53.959h-7.41c-.762 0-1.362.648-1.362 1.474v8.021c0 .826.6 1.475 1.362 1.475h7.41c.762 0 1.362-.649 1.362-1.475v-8.021c0-.767-.654-1.474-1.362-1.474zm.599 9.495c0 .354-.272.65-.599.65h-7.41c-.326 0-.599-.296-.599-.65v-8.021c0-.354.273-.649.599-.649h7.41c.327 0 .599.295.599.649v8.021z'
          fill='#fff'
        />
        <path
          d='M104.779 55.669c-1.798 0-3.269 1.592-3.269 3.539 0 .236.164.413.382.413h2.56v2.654c0 .118-.109.236-.218.236s-.218-.118-.218-.236c0-.236-.163-.413-.381-.413s-.381.177-.381.413c0 .59.435 1.061.98 1.061.545 0 .981-.471.981-1.061V59.62h2.56c.218 0 .382-.177.382-.413-.109-1.947-1.58-3.54-3.378-3.54zm-2.506 3.126c.163-1.357 1.253-2.36 2.506-2.36s2.343 1.003 2.506 2.36h-5.012z'
          fill='#fff'
        />
        <path d='M91.124 5.194H52.383v29.36h38.741V5.193z' fill='#FFD15D' />
        <path d='M91.124 5.194H71.677v29.36h19.448V5.193z' fill='#F9B54C' />
        <path d='M93.061 1H50.446v8.388H93.06V1z' fill='#F9B54C' />
        <path d='M93.061 1H71.676v8.388h21.385V1z' fill='#F4A200' />
        <path
          d='M77.565 17.014H65.942c-1.07 0-1.937-.939-1.937-2.097s.868-2.097 1.937-2.097h11.623c1.07 0 1.937.939 1.937 2.097s-.867 2.097-1.937 2.097z'
          fill='#B97850'
        />
        <path
          d='M77.565 12.82h-5.89v4.194h5.89c1.07 0 1.937-.939 1.937-2.097s-.868-2.097-1.937-2.097z'
          fill='#935635'
        />
        <path
          d='M63.908 20.405H56.5c-.763 0-1.362.65-1.362 1.475V29.9c0 .826.6 1.475 1.362 1.475h7.41c.762 0 1.361-.65 1.361-1.475V21.88c0-.767-.599-1.475-1.361-1.475zm.6 9.496c0 .354-.273.649-.6.649H56.5c-.327 0-.6-.295-.6-.649V21.88c0-.354.273-.649.6-.649h7.41c.326 0 .599.295.599.649V29.9z'
          fill='#fff'
        />
        <path
          d='M58.679 23.413c-.055-.059-.055-.059-.11-.059-.054 0-.108-.059-.163-.059-.054 0-.109 0-.163.06-.055 0-.11.058-.11.058l-1.252 1.357c-.164.177-.164.413 0 .53.163.178.381.178.49 0l.654-.707v3.48c0 .236.163.413.381.413s.382-.177.382-.413v-3.48l.653.708a.4.4 0 00.273.118c.109 0 .163-.06.272-.118.164-.177.164-.413 0-.531l-1.307-1.357zM62.274 23.413a.4.4 0 00-.272-.118.4.4 0 00-.272.118l-1.253 1.357c-.164.177-.164.413 0 .53.163.178.38.178.49 0l.654-.707v3.48c0 .236.163.413.381.413s.381-.177.381-.413v-3.48l.654.708a.4.4 0 00.273.118c.108 0 .163-.06.272-.118.163-.177.163-.413 0-.531l-1.308-1.357zM75.295 20.405h-7.41c-.762 0-1.361.65-1.361 1.475V29.9c0 .826.6 1.475 1.362 1.475h7.41c.762 0 1.361-.65 1.361-1.475V21.88c0-.767-.599-1.475-1.362-1.475zm.654 9.496c0 .354-.272.649-.6.649h-7.463c-.327 0-.6-.295-.6-.649V21.88c0-.354.273-.649.6-.649h7.41c.326 0 .598.295.598.649V29.9h.055z'
          fill='#fff'
        />
        <path
          d='M73.225 22.647H70.01c-.218 0-.381.177-.381.413v1.887c0 1.062.708 1.946 1.634 2.123v1.298h-.49c-.218 0-.381.177-.381.413s.163.412.381.412h1.743c.218 0 .382-.177.382-.412 0-.236-.164-.413-.382-.413h-.49V27.07c.926-.177 1.634-1.061 1.634-2.123V23.06c-.054-.236-.218-.413-.435-.413zm-.382 2.3c0 .767-.545 1.356-1.253 1.356-.708 0-1.253-.59-1.253-1.356v-1.534h2.506v1.534zM86.681 20.405h-7.409c-.763 0-1.362.65-1.362 1.475V29.9c0 .826.6 1.475 1.362 1.475h7.41c.762 0 1.361-.65 1.361-1.475V21.88c0-.767-.653-1.475-1.362-1.475zm.6 9.496c0 .354-.273.649-.6.649h-7.409c-.327 0-.6-.295-.6-.649V21.88c0-.354.273-.649.6-.649h7.41c.326 0 .599.295.599.649V29.9z'
          fill='#fff'
        />
        <path
          d='M82.976 22.116c-1.797 0-3.269 1.592-3.269 3.539 0 .236.164.412.382.412h2.56v2.655c0 .118-.109.236-.218.236-.108 0-.217-.118-.217-.236 0-.236-.164-.413-.382-.413s-.381.177-.381.413c0 .59.436 1.061.98 1.061.545 0 .981-.472.981-1.061v-2.655h2.56c.219 0 .382-.176.382-.412-.109-1.947-1.58-3.54-3.378-3.54zm-2.506 3.126c.164-1.357 1.253-2.36 2.506-2.36s2.343 1.003 2.506 2.36H80.47z'
          fill='#fff'
        />
        <path
          d='M5.731 35.088c0 1.03-1.04 1.868-2.323 1.868-1.284 0-2.325-.837-2.325-1.868 0-1.034 1.041-1.87 2.325-1.87 1.282 0 2.323.836 2.323 1.87zM38.196 37.532L20.12 42.471l7.081 16.784 18.075-4.939-7.081-16.784z'
          fill='#FF8E31'
        />
        <path
          d='M48.717 64.633H18.43c-.96 0-1.865-.3-2.547-.85-.679-.548-1.054-1.275-1.054-2.05v-.077c0-.658.283-1.304.793-1.818.514-.513 1.237-.873 2.038-1.016l25.376-4.492c.602-.107 1.196.2 1.329.682.132.483-.248.962-.85 1.07l-25.377 4.493c-.31.053-.578.187-.777.387a.976.976 0 00-.304.694v.077c0 .296.143.574.402.783.26.208.606.325.97.325h30.287c.616 0 1.114.4 1.114.896 0 .494-.497.896-1.113.896z'
          fill='#F2F4F7'
        />
        <path
          d='M19.219 60.361c-.537 0-1.011-.313-1.098-.755l-4.582-23.094a1.055 1.055 0 00-.465-.669 1.579 1.579 0 00-.894-.265H3.408c-.616 0-1.116-.4-1.116-.896 0-.494.5-.898 1.116-.898h8.771c.857 0 1.688.248 2.34.695.651.446 1.087 1.069 1.22 1.75l4.581 23.093c.096.49-.319.95-.925 1.028-.06.008-.117.011-.176.011z'
          fill='#F2F4F7'
        />
        <path
          d='M24.347 66.103c0 1.58-1.593 2.86-3.553 2.86-1.962 0-3.555-1.28-3.555-2.86 0-1.58 1.593-2.858 3.555-2.858 1.96 0 3.553 1.278 3.553 2.858z'
          fill='#34495E'
        />
        <path
          d='M22.568 66.103c0 .79-.794 1.43-1.774 1.43-.983 0-1.775-.64-1.775-1.43 0-.789.792-1.429 1.775-1.429.98 0 1.774.64 1.774 1.429z'
          fill='#F2F4F7'
        />
        <path
          d='M49.831 66.103c0 1.58-1.592 2.86-3.554 2.86-1.961 0-3.552-1.28-3.552-2.86 0-1.58 1.591-2.858 3.552-2.858 1.962 0 3.554 1.278 3.554 2.858z'
          fill='#34495E'
        />
        <path
          d='M48.054 66.103c0 .79-.797 1.43-1.777 1.43-.98 0-1.776-.64-1.776-1.43 0-.788.796-1.428 1.776-1.428s1.777.64 1.777 1.428zM49.447 42.877a1.476 1.476 0 00-.928-.31H14.635l3.672 18.382 30.494-5.398c.282-.05.543-.174.74-.37a.935.935 0 00.29-.66V43.623c0-.27-.129-.54-.384-.745zm-28.56 15.837a.953.953 0 01-.133.008c-.4 0-.753-.234-.818-.562l-2.614-13.082c-.072-.365.235-.708.688-.766.45-.058.878.19.952.554l2.612 13.082c.073.365-.234.707-.687.766zm4.333-.769a.918.918 0 01-.118.008c-.407 0-.762-.24-.82-.574l-2.178-12.313c-.065-.364.25-.703.703-.755.458-.053.875.202.94.567l2.178 12.313c.065.364-.25.703-.705.754zm4.33-.767a1.222 1.222 0 01-.1.005c-.413 0-.771-.25-.822-.588l-1.743-11.542c-.056-.366.27-.7.723-.744.456-.038.868.216.925.582l1.742 11.544c.055.365-.268.698-.724.743zm4.33-.769a1.294 1.294 0 01-.083.003c-.422 0-.783-.258-.824-.603l-1.308-10.772c-.045-.367.288-.694.746-.73.463-.032.863.233.907.6l1.308 10.773c.043.367-.29.694-.746.73zm4.325-.767l-.06.002c-.432 0-.795-.27-.826-.622l-.87-10.003c-.032-.368.311-.687.77-.714.462-.019.853.252.886.62l.87 10.004c.032.368-.314.687-.77.713zm4.323-.77h-.033c-.444 0-.811-.282-.83-.641l-.435-9.234c-.016-.368.34-.678.799-.693.463-.022.842.273.861.642l.435 9.233c.018.37-.34.68-.797.694zm5.146-1.437c0 .37-.372.668-.83.668-.459 0-.83-.299-.83-.668v-8.463c0-.37.371-.667.83-.667.458 0 .83.298.83.667v8.463z'
          fill='#F2F4F7'
        />
        <path d='M50.25 28.743a8 8 0 110 16 8 8 0 010-16z' fill='#3689C9' />
        <path
          d='M49.02 37.051l-.924-1.108a1.232 1.232 0 00-1.477-.307c.062-.185.185-.308.308-.431.492-.43 1.292-.37 1.723.185l.308.369v1.292h.061zM54.865 36.928l-3.077 3.692c-.061.123-.184.185-.307.247l2.769-3.324c.43-.492.369-1.292-.185-1.723-.43-.369-.984-.369-1.415-.123l.307-.369c.431-.492 1.231-.615 1.724-.185.553.493.615 1.231.184 1.785zM51.48 37.051v-4c0-.677-.553-1.23-1.23-1.23-.184 0-.37.06-.492.122.184-.43.615-.738 1.107-.738.677 0 1.231.554 1.231 1.23v3.878l-.615.738z'
          fill='#1B6FAB'
        />
        <path
          d='M46.373 35.82c.492-.43 1.292-.369 1.723.185l.923 1.108V33.05c0-.677.554-1.23 1.231-1.23s1.23.553 1.23 1.23v4l.924-1.108c.43-.492 1.23-.615 1.723-.184.492.43.615 1.23.184 1.723l-3.076 3.692a1.285 1.285 0 01-.985.492c-.37 0-.739-.184-.923-.43l-3.077-3.693c-.43-.553-.37-1.292.123-1.723z'
          fill='#fff'
        />
        <path
          d='M48.342 36.312c-.43.123-.861.062-1.292-.123a1.359 1.359 0 00-.985 0c.123-.246.308-.492.554-.615.554-.246 1.17-.062 1.539.37l.246.245-.062.123z'
          fill='#DDDFE1'
        />
        <path
          d='M57.02 37.974c-.677 0-1.231-.554-1.231-1.23a5.515 5.515 0 00-5.539-5.54c-.677 0-1.23-.553-1.23-1.23 0-.677.553-1.23 1.23-1.23 4.431 0 8 3.568 8 8 0 .676-.553 1.23-1.23 1.23z'
          fill='#1B6FAB'
        />
        <path
          d='M43.48 35.512c.678 0 1.232.554 1.232 1.231a5.515 5.515 0 005.538 5.539c.677 0 1.23.554 1.23 1.23 0 .677-.553 1.231-1.23 1.231-4.43 0-8-3.57-8-8 0-.677.554-1.23 1.23-1.23z'
          fill='#4C9FD7'
        />
        <path
          d='M51.48 37.051c-.369 0-.615-.246-.615-.615v-4c0-.185-.061-.37-.123-.493.431.185.739.616.739 1.108v4z'
          fill='#DDDFE1'
        />
      </g>
      <defs>
        <filter
          id='purchase-order-menu_svg__filter0_d_5586_192805'
          x={0.083}
          y={0}
          width={122.167}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_192805' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_192805' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgPurchaseOrderMenu;
