/**
 * default of status page in data contact
 */
export const DEFAULT_STATUS_PAGE_LIST_DATA_CONTACT = 'list-data-contact';
export const DEFAULT_STATUS_PAGE_DETAIL_DATA_CONTACT = 'detail-data-contact';
export const DEFAULT_STATUS_PAGE_ADD_DATA_CONTACT = 'add-data-contact';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_DATA_CONTACT = 'discard-add-data-contact';
export const DEFAULT_STATUS_PAGE_UPDATE_DATA_CONTACT = 'update-data-contact';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_DATA_CONTACT = 'discard-update-data-contact';
export const DEFAULT_STATUS_PAGE_DELETE_DATA_CONTACT = 'delete-data-contact';

/**
 * default of status page in quotation request
 */
export const DEFAULT_STATUS_PAGE_LIST_QUOTATION_REQUEST = 'list-quotation-request';
export const DEFAULT_STATUS_PAGE_PRINT_PDF_QUOTATION_REQUEST = 'print-pdf-quotation-request';
export const DEFAULT_STATUS_PAGE_DETAIL_QUOTATION_REQUEST = 'detail-quotation-request';
export const DEFAULT_STATUS_PAGE_ADD_QUOTATION_REQUEST = 'add-quotation-request';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_QUOTATION_REQUEST = 'discard-add-quotation-request';
export const DEFAULT_STATUS_PAGE_UPDATE_QUOTATION_REQUEST = 'update-quotation-request';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_QUOTATION_REQUEST =
  'discard-update-quotation-request';
export const DEFAULT_STATUS_PAGE_DELETE_QUOTATION_REQUEST = 'delete-quotation-request';

/**
 * default of status page in purchase order
 */
export const DEFAULT_STATUS_PAGE_LIST_PURCHASE_ORDER = 'list-purchase-order';
export const DEFAULT_STATUS_PAGE_PRINT_PDF_PURCHASE_ORDER = 'print-pdf-purchase-order';
export const DEFAULT_STATUS_PAGE_DETAIL_PURCHASE_ORDER = 'detail-purchase-order';
export const DEFAULT_STATUS_PAGE_ADD_PURCHASE_ORDER = 'add-purchase-order';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_PURCHASE_ORDER = 'discard-add-purchase-order';
export const DEFAULT_STATUS_PAGE_UPDATE_PURCHASE_ORDER = 'update-purchase-order';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_PURCHASE_ORDER = 'discard-update-purchase-order';
export const DEFAULT_STATUS_PAGE_DELETE_PURCHASE_ORDER = 'delete-purchase-order';

export const statusPagePurchaseOrderUseDataPurchaseOrderDetail = [
  DEFAULT_STATUS_PAGE_DETAIL_DATA_CONTACT,
  DEFAULT_STATUS_PAGE_UPDATE_PURCHASE_ORDER,
  DEFAULT_STATUS_PAGE_DETAIL_PURCHASE_ORDER,
];

/**
 * default of status page in purchase payment
 */
export const DEFAULT_STATUS_PAGE_LIST_PURCHASE_PAYMENT = 'list-purchase-payment';
export const DEFAULT_STATUS_PAGE_PRINT_PDF_PURCHASE_PAYMENT = 'print-pdf-purchase-payment';
export const DEFAULT_STATUS_PAGE_DETAIL_PURCHASE_PAYMENT = 'detail-purchase-payment';
export const DEFAULT_STATUS_PAGE_ADD_PURCHASE_PAYMENT = 'add-purchase-payment';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_PURCHASE_PAYMENT = 'discard-add-purchase-payment';
export const DEFAULT_STATUS_PAGE_UPDATE_PURCHASE_PAYMENT = 'update-purchase-payment';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_PURCHASE_PAYMENT =
  'discard-update-purchase-payment';
export const DEFAULT_STATUS_PAGE_DELETE_PURCHASE_PAYMENT = 'delete-purchase-payment';

export const statusPagePurchaseOrderUseDataPurchaseInvoiceDetail = [
  DEFAULT_STATUS_PAGE_DETAIL_DATA_CONTACT,
  DEFAULT_STATUS_PAGE_DETAIL_PURCHASE_PAYMENT,
  DEFAULT_STATUS_PAGE_UPDATE_PURCHASE_PAYMENT,
];

/**
 * detault status page in content cash payment
 */
export const DEFAULT_STATUS_PAGE_LIST_CASH_PAYMENT = 'list-cash-payment';
export const DEFAULT_STATUS_PAGE_DETAIL_CASH_PAYMENT = 'detail-cash-payment';
export const DEFAULT_STATUS_PAGE_ADD_CASH_PAYMENT = 'add-cash-payment';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_CASH_PAYMENT = 'discard-add-cash-payment';
export const DEFAULT_STATUS_PAGE_UPDATE_CASH_PAYMENT = 'update-cash-payment';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_CASH_PAYMENT = 'discard-update-cash-payment';
export const DEFAULT_STATUS_PAGE_DELETE_CASH_PAYMENT = 'delete-cash-payment';

/**
 * default of status page in cash receipt
 */
export const DEFAULT_STATUS_PAGE_LIST_CASH_BANK_CASH_RECEIPT = 'list-cash-receipt';
export const DEFAULT_STATUS_PAGE_DETAIL_CASH_BANK_CASH_RECEIPT = 'detail-cash-receipt';
export const DEFAULT_STATUS_PAGE_ADD_CASH_BANK_CASH_RECEIPT = 'add-cash-receipt';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_CASH_BANK_CASH_RECEIPT = 'discard-add-cash-receipt';
export const DEFAULT_STATUS_PAGE_UPDATE_CASH_BANK_CASH_RECEIPT = 'update-cash-receipt';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_CASH_BANK_CASH_RECEIPT =
  'discard-update-cash-receipt';
export const DEFAULT_STATUS_PAGE_DELETE_CASH_BANK_CASH_RECEIPT = 'delete-cash-receipt';

/**
 * default of status page in purchase delivery
 */
export const DEFAULT_STATUS_PAGE_LIST_PURCHASE_DELIVERY = 'list-purchase-delivery';
export const DEFAULT_STATUS_PAGE_PRINT_PDF_PURCHASE_DELIVERY = 'print-pdf-purchase-delivery';
export const DEFAULT_STATUS_PAGE_DETAIL_PURCHASE_DELIVERY = 'detail-purchase-delivery';
export const DEFAULT_STATUS_PAGE_ADD_PURCHASE_DELIVERY = 'add-purchase-delivery';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_PURCHASE_DELIVERY = 'discard-add-purchase-delivery';
export const DEFAULT_STATUS_PAGE_UPDATE_PURCHASE_DELIVERY = 'update-purchase-delivery';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_PURCHASE_DELIVERY =
  'discard-update-purchase-delivery';
export const DEFAULT_STATUS_PAGE_DELETE_PURCHASE_DELIVERY = 'delete-purchase-delivery';
export const statusPagePurchaseDeliveryUseDataPurchaseDeliveryDetail = [
  'regular-handover',
  DEFAULT_STATUS_PAGE_DETAIL_DATA_CONTACT,
  DEFAULT_STATUS_PAGE_UPDATE_PURCHASE_DELIVERY,
  DEFAULT_STATUS_PAGE_DETAIL_PURCHASE_DELIVERY,
];

/**
 * default of status page in purchase invoice
 */
export const DEFAULT_STATUS_PAGE_LIST_PURCHASE_INVOICE = 'list-purchase-invoice';
export const DEFAULT_STATUS_PAGE_PRINT_PDF_PURCHASE_INVOICE = 'print-pdf-purchase-invoice';
export const DEFAULT_STATUS_PAGE_DETAIL_PURCHASE_INVOICE = 'detail-purchase-invoice';
export const DEFAULT_STATUS_PAGE_ADD_PURCHASE_INVOICE = 'add-purchase-invoice';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_PURCHASE_INVOICE = 'discard-add-purchase-invoice';
export const DEFAULT_STATUS_PAGE_UPDATE_PURCHASE_INVOICE = 'update-purchase-invoice';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_PURCHASE_INVOICE =
  'discard-update-purchase-invoice';
export const DEFAULT_STATUS_PAGE_DELETE_PURCHASE_INVOICE = 'delete-purchase-invoice';
export const statusPagePurchaseInvoiceUseDataPurchaseDeliveryDetail = [
  DEFAULT_STATUS_PAGE_DETAIL_DATA_CONTACT,
  DEFAULT_STATUS_PAGE_DETAIL_PURCHASE_INVOICE,
  DEFAULT_STATUS_PAGE_UPDATE_PURCHASE_INVOICE,
];
export const statusPagePurchaseInvoiceUseDataPurchaseOrderDetail = [
  DEFAULT_STATUS_PAGE_DETAIL_DATA_CONTACT,
  DEFAULT_STATUS_PAGE_DETAIL_PURCHASE_INVOICE,
  DEFAULT_STATUS_PAGE_UPDATE_PURCHASE_INVOICE,
];

/*
 * default of status page in cash transfer
 */
export const DEFAULT_STATUS_PAGE_LIST_CASH_BANK_CASH_TRANSFER = 'list-cash-transfer';
export const DEFAULT_STATUS_PAGE_DETAIL_CASH_BANK_CASH_TRANSFER = 'detail-cash-transfer';
export const DEFAULT_STATUS_PAGE_ADD_CASH_BANK_CASH_TRANSFER = 'add-cash-transfer';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_CASH_BANK_CASH_TRANSFER = 'discard-add-cash-transfer';
export const DEFAULT_STATUS_PAGE_UPDATE_CASH_BANK_CASH_TRANSFER = 'update-cash-transfer';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_CASH_BANK_CASH_TRANSFER =
  'discard-update-cash-transfer';
export const DEFAULT_STATUS_PAGE_DELETE_CASH_BANK_CASH_TRANSFER = 'delete-cash-transfer';

/**
 * default of status page in data product
 */
export const DEFAULT_STATUS_PAGE_LIST_DATA_PRODUCT = 'list-data-product';
export const DEFAULT_STATUS_PAGE_DETAIL_DATA_PRODUCT = 'detail-data-product';
export const DEFAULT_STATUS_PAGE_ADD_DATA_PRODUCT = 'add-data-product';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_DATA_PRODUCT = 'discard-add-data-product';
export const DEFAULT_STATUS_PAGE_UPDATE_DATA_PRODUCT = 'update-data-product';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_DATA_PRODUCT = 'discard-update-data-product';
export const DEFAULT_STATUS_PAGE_DELETE_DATA_PRODUCT = 'delete-data-product';

/*
 * default of status page in sales order
 */
export const DEFAULT_STATUS_PAGE_LIST_SALES_ORDER = 'list-sales-order';
export const DEFAULT_STATUS_PAGE_DETAIL_SALES_ORDER = 'detail-sales-order';
export const DEFAULT_STATUS_PAGE_ADD_SALES_ORDER = 'add-sales-order';
export const DEFAULT_STATUS_PAGE_PRINT_PDF_SALES_ORDER = 'print-pdf-sales-order';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_SALES_ORDER = 'discard-add-sales-order';
export const DEFAULT_STATUS_PAGE_UPDATE_SALES_ORDER = 'update-sales-order';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_SALES_ORDER = 'discard-update-sales-order';
export const DEFAULT_STATUS_PAGE_DELETE_SALES_ORDER = 'delete-sales-order';
export const statusPageSalesOrderUseDataSalesOrderDetail = [
  DEFAULT_STATUS_PAGE_DETAIL_DATA_CONTACT,
  DEFAULT_STATUS_PAGE_DETAIL_SALES_ORDER,
  DEFAULT_STATUS_PAGE_UPDATE_SALES_ORDER,
];

/**
 *
 * default of status page in sales delivery
 */
export const DEFAULT_STATUS_PAGE_LIST_SALES_DELIVERY = 'list-sales-delivery';
export const DEFAULT_STATUS_PAGE_PRINT_PDF_SALES_DELIVERY = 'print-pdf-sales-delivery';
export const DEFAULT_STATUS_PAGE_DETAIL_SALES_DELIVERY = 'detail-sales-delivery';
export const DEFAULT_STATUS_PAGE_ADD_SALES_DELIVERY = 'add-sales-delivery';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_SALES_DELIVERY = 'discard-add-sales-delivery';
export const DEFAULT_STATUS_PAGE_UPDATE_SALES_DELIVERY = 'update-sales-delivery';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_SALES_DELIVERY = 'discard-update-sales-delivery';
export const DEFAULT_STATUS_PAGE_DELETE_SALES_DELIVERY = 'delete-sales-delivery';

export const statusPageSalesDeliveryUseDataSalesDeliveryDetail = [
  DEFAULT_STATUS_PAGE_DETAIL_DATA_CONTACT,
  DEFAULT_STATUS_PAGE_DETAIL_SALES_DELIVERY,
  DEFAULT_STATUS_PAGE_UPDATE_SALES_DELIVERY,
];

/**
 * default of status page in sales payment
 */
export const DEFAULT_STATUS_PAGE_LIST_SALES_PAYMENT = 'list-sales-payment';
export const DEFAULT_STATUS_PAGE_PRINT_PDF_SALES_PAYMENT = 'print-pdf-sales-payment';
export const DEFAULT_STATUS_PAGE_DETAIL_SALES_PAYMENT = 'detail-sales-payment';
export const DEFAULT_STATUS_PAGE_ADD_SALES_PAYMENT = 'add-sales-payment';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_SALES_PAYMENT = 'discard-add-sales-payment';
export const DEFAULT_STATUS_PAGE_UPDATE_SALES_PAYMENT = 'update-sales-payment';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_SALES_PAYMENT = 'discard-update-sales-payment';
export const DEFAULT_STATUS_PAGE_DELETE_SALES_PAYMENT = 'delete-sales-payment';

export const statusPageSalesOrderUseDataSalesInvoiceDetail = [
  DEFAULT_STATUS_PAGE_DETAIL_DATA_CONTACT,
  DEFAULT_STATUS_PAGE_DETAIL_SALES_PAYMENT,
  DEFAULT_STATUS_PAGE_UPDATE_SALES_PAYMENT,
];

/**
 * default of status page in sales quotation
 */
export const DEFAULT_STATUS_PAGE_LIST_SALES_QUOTATION = 'list-sales-quotation';
export const DEFAULT_STATUS_PAGE_DETAIL_SALES_QUOTATION = 'detail-sales-quotation';
export const DEFAULT_STATUS_PAGE_ADD_SALES_QUOTATION = 'add-sales-quotation';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_SALES_QUOTATION = 'discard-add-sales-quotation';
export const DEFAULT_STATUS_PAGE_UPDATE_SALES_QUOTATION = 'update-sales-quotation';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_SALES_QUOTATION = 'discard-update-sales-quotation';
export const DEFAULT_STATUS_PAGE_DELETE_SALES_QUOTATION = 'delete-sales-quotation';
export const DEFAULT_STATUS_PAGE_PRINT_PDF_SALES_QUOTATION = 'print-pdf-sales-quotation';

/**
 * default of status page in sales invoice
 */
export const DEFAULT_STATUS_PAGE_LIST_SALES_INVOICE = 'list-sales-invoice';
export const DEFAULT_STATUS_PAGE_PRINT_PDF_SALES_INVOICE = 'print-pdf-sales-invoice';
export const DEFAULT_STATUS_PAGE_DETAIL_SALES_INVOICE = 'detail-sales-invoice';
export const DEFAULT_STATUS_PAGE_ADD_SALES_INVOICE = 'add-sales-invoice';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_SALES_INVOICE = 'discard-add-sales-invoice';
export const DEFAULT_STATUS_PAGE_UPDATE_SALES_INVOICE = 'update-sales-invoice';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_SALES_INVOICE = 'discard-update-sales-invoice';
export const DEFAULT_STATUS_PAGE_DELETE_SALES_INVOICE = 'delete-sales-invoice';

/**
 * default of status page in asset category
 */
export const DEFAULT_STATUS_PAGE_LIST_ASSET_CATEGORY = 'list-asset-category';
export const DEFAULT_STATUS_PAGE_DETAIL_ASSET_CATEGORY = 'detail-asset-category';
export const DEFAULT_STATUS_PAGE_ADD_ASSET_CATEGORY = 'add-asset-category';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_ASSET_CATEGORY = 'discard-asset-category';
export const DEFAULT_STATUS_PAGE_UPDATE_ASSET_CATEGORY = 'update-asset-category';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_ASSET_CATEGORY = 'discard-update-asset-category';
export const DEFAULT_STATUS_PAGE_DELETE_ASSET_CATEGORY = 'delete-asset-category';

/**
 * default of status page in asset management
 */
export const DEFAULT_STATUS_PAGE_LIST_ASSET_MANAGEMENT = 'list-asset-management';
export const DEFAULT_STATUS_PAGE_DETAIL_ASSET_MANAGEMENT = 'detail-asset-management';
export const DEFAULT_STATUS_PAGE_ADD_ASSET_MANAGEMENT = 'add-asset-management';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_ASSET_MANAGEMENT = 'discard-asset-management';
export const DEFAULT_STATUS_PAGE_UPDATE_ASSET_MANAGEMENT = 'update-asset-management';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_ASSET_MANAGEMENT =
  'discard-update-asset-management';
export const DEFAULT_STATUS_PAGE_REVERT_ASSET_MANAGEMENT = 'revert-asset-management';
export const DEFAULT_STATUS_PAGE_DELETE_ASSET_MANAGEMENT = 'delete-asset-management';
export const DEFAULT_STATUS_PAGE_SALE_ASSET_MANAGEMENT = 'sale-asset-management';
export const DEFAULT_STATUS_PAGE_DISCARD_SALE_ASSET_MANAGEMENT = 'discard-sale-asset-management';

/**
 * default of status page on cash bank index
 */
export const DEFAULT_STATUS_PAGE_LIST_CASH_BANK_INDEX = 'list-cash-bank-index';

/**
 * default status page in setting company
 */
export const DEFAULT_STATUS_PAGE_LIST_SETTING_COMPANY = 'list-setting-company';
export const DEFAULT_STATUS_PAGE_DETAIL_SETTING_COMPANY = 'detail-setting-company';
export const DEFAULT_STATUS_PAGE_ADD_SETTING_COMPANY = 'add-setting-company';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_SETTING_COMPANY = 'discard-add-setting-company';
export const DEFAULT_STATUS_PAGE_UPDATE_SETTING_COMPANY = 'update-setting-company';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_SETTING_COMPANY = 'discard-update-setting-company';
export const DEFAULT_STATUS_PAGE_DELETE_SETTING_COMPANY = 'delete-setting-company';

/**
 * default of status page in setting warehouse
 */
export const DEFAULT_STATUS_PAGE_LIST_SETTINGS_WAREHOUSE = 'list-settings-warehouse';
export const DEFAULT_STATUS_PAGE_DETAIL_SETTINGS_WAREHOUSE = 'details-settings-warehouse';
export const DEFAULT_STATUS_PAGE_ADD_SETTINGS_WAREHOUSE = 'add-settings-warehouse';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_SETTINGS_WAREHOUSE = 'discard-add-settings-warehouse';
export const DEFAULT_STATUS_PAGE_UPDATE_SETTINGS_WAREHOUSE = 'update-settings-warehouse';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_SETTINGS_WAREHOUSE =
  'discard-update-settings-warehouse';
export const DEFAULT_STATUS_PAGE_DELETE_SETTINGS_WAREHOUSE = 'delete-settings-warehouse';

/**
 * default of status page in closing book
 */
export const DEFAULT_STATUS_PAGE_LIST_CLOSING_BOOK = 'list-closing-book';
export const DEFAULT_STATUS_PAGE_DETAIL_CLOSING_BOOK = 'detail-closing-book';
export const DEFAULT_STATUS_PAGE_REVERT_CLOSING_BOOK = 'revert-closing-book';
export const DEFAULT_STATUS_PAGE_CONFIRM_REVERT_CLOSING_BOOK = 'confirm-revert-closing-book';
export const DEFAULT_STATUS_PAGE_ADD_CLOSING_BOOK = 'add-closing-book';
export const DEFAULT_STATUS_PAGE_PROCESS_CLOSING_BOOK = 'process-closing-book';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_CLOSING_BOOK = 'discard-closing-book';
export const DEFAULT_STATUS_PAGE_UPDATE_CLOSING_BOOK = 'update-closing-book';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_CLOSING_BOOK = 'discard-update-closing-book';
export const DEFAULT_STATUS_PAGE_DELETE_CLOSING_BOOK = 'delete-closing-book';

/**
 * default status page in account transaction
 */
export const DEFAULT_STATUS_PAGE_LIST_TRANSACTION_ACCOUNT = 'list-transaction-account';

/**
 * default status page in journal entry
 */
export const DEFAULT_STATUS_PAGE_DETAIL_JOURNAL_ENTRY_REPORT = 'detail-journal-entry-report';

/**
 * default status page journal report
 */
export const DEFAULT_STATUS_PAGE_LIST_JOURNAL_REPORT = 'list-journal-report';
export const DEFAULT_STATUS_PAGE_PRINT_JOURNAL_REPORT = 'print-journal-report';

/**
 * default status page report balance sheet
 */
export const DEFAULT_STATUS_PAGE_LIST_REPORT_BALANCE_SHEET = 'list-report-balance-sheet';
export const DEFAULT_STATUS_PAGE_PRINT_REPORT_BALANCE_SHEET = 'print-report-balance-sheet';

/**
 * default status page report income statement
 */
export const DEFAULT_STATUS_PAGE_LIST_REPORT_INCOME_STATEMENT = 'list-report-income-statement';
export const DEFAULT_STATUS_PAGE_PRINT_REPORT_INCOME_STATEMENT = 'print-report-income-statement';

/**
 * default status page report general ledger
 */
export const DEFAULT_STATUS_PAGE_LIST_REPORT_GENERAL_LEDGER = 'list-report-general-ledger';
export const DEFAULT_STATUS_PAGE_PRINT_REPORT_GENERAL_LEDGER = 'print-report-general-ledger';

/**
 * default status page in capital change report
 */
export const DEFAULT_STATUS_PAGE_LIST_REPORT_CAPITAL_CHANGE = 'list-report-capital-change';
export const DEFAULT_STATUS_PAGE_PRINT_REPORT_CAPITAL_CHANGE = 'print-report-capital-change';

/**
 * default status page in cash flow report
 */
export const DEFAULT_STATUS_PAGE_LIST_REPORT_CASH_FLOW = 'list-report-cash-flow';
export const DEFAULT_STATUS_PAGE_PRINT_REPORT_CASH_FLOW = 'print-report-cash-flow';

/**
 * default status page in trial balance report
 */
export const DEFAULT_STATUS_PAGE_LIST_REPORT_TRIAL_BALANCE = 'list-report-trial-balance';
export const DEFAULT_STATUS_PAGE_PRINT_REPORT_TRIAL_BALANCE = 'print-report-trial-balance';

/**
/*
 * default of status page in transfer warehouse
 */
export const DEFAULT_STATUS_PAGE_LIST_TRANSFER_WAREHOUSE = 'list-transfer-warehouse';
export const DEFAULT_STATUS_PAGE_DETAIL_TRANSFER_WAREHOUSE = 'detail-transfer-warehouse';
export const DEFAULT_STATUS_PAGE_ADD_TRANSFER_WAREHOUSE = 'add-transfer-warehouse';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_TRANSFER_WAREHOUSE = 'discard-add-transfer-warehouse';
export const DEFAULT_STATUS_PAGE_UPDATE_TRANSFER_WAREHOUSE = 'update-transfer-warehouse';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_TRANSFER_WAREHOUSE =
  'discard-update-transfer-warehouse';
export const DEFAULT_STATUS_PAGE_DELETE_TRANSFER_WAREHOUSE = 'delete-transfer-warehouse';

/**
 * default status page in product stock adjustment
 */
export const DEFAULT_STATUS_PAGE_LIST_PRODUCT_STOCK_ADJUSTMENT = 'list-product-stock-adjustment';
export const DEFAULT_STATUS_PAGE_DETAIL_PRODUCT_STOCK_ADJUSTMENT =
  'detail-product-stock-adjustment';
export const DEFAULT_STATUS_PAGE_ADD_PRODUCT_STOCK_ADJUSTMENT = 'add-product-stock-adjustment';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_PRODUCT_STOCK_ADJUSTMENT =
  'discard-add-product-stock-adjustment';
export const DEFAULT_STATUS_PAGE_UPDATE_PRODUCT_STOCK_ADJUSTMENT =
  'update-product-stock-adjustment';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_PRODUCT_STOCK_ADJUSTMENT =
  'discard-update-product-stock-adjustment';
export const DEFAULT_STATUS_PAGE_DELETE_PRODUCT_STOCK_ADJUSTMENT =
  'delete-product-stock-adjustment';

/**
 * default status page in ledger journal entry
 */
export const DEFAULT_STATUS_PAGE_LIST_LEDGER_JOURNAL_ENTRY = 'list-ledger-journal-entry';
export const DEFAULT_STATUS_PAGE_DETAIL_LEDGER_JOURNAL_ENTRY = 'detail-ledger-journal-entry';
export const DEFAULT_STATUS_PAGE_ADD_LEDGER_JOURNAL_ENTRY = 'add-ledger-journal-entry';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_LEDGER_JOURNAL_ENTRY =
  'discard-add-ledger-journal-entry';
export const DEFAULT_STATUS_PAGE_UPDATE_LEDGER_JOURNAL_ENTRY = 'update-ledger-journal-entry';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_LEDGER_JOURNAL_ENTRY =
  'discard-update-ledger-journal-entry';
export const DEFAULT_STATUS_PAGE_DELETE_LEDGER_JOURNAL_ENTRY = 'delete-ledger-journal-entry';
export const availableStatusPageJournalEntryMenu = [
  DEFAULT_STATUS_PAGE_LIST_LEDGER_JOURNAL_ENTRY,
  DEFAULT_STATUS_PAGE_DETAIL_LEDGER_JOURNAL_ENTRY,
];

/**
 * default status page of minutes handover
 */
export const DEFAULT_STATUS_PAGE_REGULAR_HANDOVER = 'regular-handover';
export const DEFAULT_STATUS_PAGE_DETAIL_HANDOVER = 'detail-handover';
export const DEFAULT_STATUS_PAGE_ADD_HANDOVER = 'add-handover';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_HANDOVER = 'discard-add-handover';
export const DEFAULT_STATUS_PAGE_UPDATE_HANDOVER = 'update-handover';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_HANDOVER = 'discard-update-handover';

/**
 * default status page for transaction invoice basic app
 */
export const DEFAULT_STATUS_PAGE_LIST_TRANSACTION_INVOICE_BASIC = 'list-transaction-invoice-basic';
export const DEFAULT_STATUS_PAGE_DETAILS_TRANSACTION_INVOICE_BASIC =
  'details-transaction-invoice-basic';
export const DEFAULT_STATUS_PAGE_PRINT_THERMAL_TRANSACTION_INVOICE_BASIC =
  'print-thermal-transaction-invoice-basic';
export const DEFAULT_STATUS_PAGE_PRINT_REGULAR_TRANSACTION_INVOICE_BASIC =
  'print-reguler-transaction-invoice-basic';

/**
 * default status page for print
 */
export const DEFAULT_PAGE_STATUS_PRINT_PDF = 'print-pdf';
export const DEFAULT_PAGE_STATUS_PRINT_EXCEL = 'print-excel';
export const DEFAULT_PAGE_STATUS_PRINT_THERMAL = 'print-thermal';

/**
 * default status page for transaction
 */
export const DEFAULT_PAGE_STATUS_PAY_TRANSACTION = 'pay-transaction';
export const DEFAULT_PAGE_STATUS_DISCARD_PAY_TRANSACTION = 'discard-pay-transaction';
export const DEFAULT_PAGE_STATUS_DELETE_TRANSACTION = 'delete-transaction';
export const DEFAULT_PAGE_STATUS_UPDATE_TRANSACTION_CONFRIMATION =
  'update-transaction-confirmation';
export const DEFAULT_PAGE_STATUS_ADD_UPDATE_TRANSACTION = 'add-update-transaction';
export const DEFAULT_PAGE_STATUS_DETAILS_TRANSACTION = 'details-transaction';
export const DEFAULT_PAGE_STATUS_TRANSACTION_HISTORY = 'transaction-history';

/**
 * default status page for contact
 */
export const DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA = 'add-contact-data';

/**
 * default of status page in import product
 */
export const DEFAULT_STATUS_PAGE_IMPORT_PRODUCT = 'import-product';

/**
 * default of status page in expense
 */
export const DEFAULT_STATUS_PAGE_LIST_EXPENSE = 'list-expense';
export const DEFAULT_STATUS_PAGE_DETAIL_EXPENSE = 'detail-expense';
export const DEFAULT_STATUS_PAGE_ADD_EXPENSE = 'add-expense';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_EXPENSE = 'discard-add-expense';
export const DEFAULT_STATUS_PAGE_UPDATE_EXPENSE = 'update-expense';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_EXPENSE = 'discard-update-expense';
export const DEFAULT_STATUS_PAGE_DELETE_EXPENSE = 'delete-expense';

/**
 * default page status for account
 */
export const DEFAULT_PAGE_STATUS_LIST_ACCOUNT = 'list-account';
export const DEFAULT_PAGE_STATUS_INPUT_ACCOUNT = 'input-account';
export const DEFAULT_PAGE_STATUS_DISCARD_INPUT_ACCOUNT = 'discard-input-account';
export const DEFAULT_PAGE_STATUS_DELETE_ACCOUNT = 'delete-account';
export const DEFAULT_PAGE_STATUS_ADD_ACCOUNT = 'add-account';
export const DEFAULT_PAGE_STATUS_UPDATE_ACCOUNT = 'update-account';

/**
 * default page status of transaction account
 */
export const DEFAULT_PAGE_STATUS_DETAIL_TRANSACTION_ACCOUNT = 'detail-transaction-account';

/**
 * default page status for cash bank
 */
export const DEFAULT_PAGE_STATUS_LIST_CASH_BANK = 'list-cash-bank';

/**
 * default of status page in product data
 */
export const DEFAULT_STATUS_PAGE_LIST_PRODUCT_DATA = 'list-product-data';
export const DEFAULT_STATUS_PAGE_DETAIL_PRODUCT_DATA = 'detail-product-data';
export const DEFAULT_STATUS_PAGE_ADD_PRODUCT_DATA = 'add-product-data';
export const DEFAULT_STATUS_PAGE_DISCARD_ADD_PRODUCT_DATA = 'discard-add-product-data';
export const DEFAULT_STATUS_PAGE_UPDATE_PRODUCT_DATA = 'update-product-data';
export const DEFAULT_STATUS_PAGE_DISCARD_UPDATE_PRODUCT_DATA = 'discard-update-product-data';
export const DEFAULT_STATUS_PAGE_DELETE_PRODUCT_DATA = 'delete-product-data';

/**
 * default page status of setting user
 */
export const DEFAULT_STATUS_PAGE_LIST_SETTING_USER = 'list-setting-user';
export const DEFAULT_PAGE_STATUS_INVITATION_USER = 'invitation-user';
export const DEFAULT_PAGE_STATUS_DISCARD_INVITATION_USER = 'discard-invitation-user';
export const DEFAULT_PAGE_STATUS_DELETE_USER = 'delete-user';
export const DEFAULT_PAGE_STATUS_DETAILS_USER = 'details-user';

/**
 * default page status of login by company page
 */
export const DEFAULT_PAGE_STATUS_MAIN_LOGIN_BY_COMPANY = 'main-login-by-company';
export const DEFAULT_PAGE_STATUS_CHOOSE_REGISTER_COMPANY_LOGIN_BY_COMPANY =
  'choose-register-company-login-by-company';

/**
 * default page status for product category
 */
export const DEFAULT_PAGE_STATUS_DETAIL_PRODUCT_CATEGORY = 'detail-product-category';
export const DEFAULT_PAGE_STATUS_UPDATE_PRODUCT_CATEGORY = 'update-product-category';
export const DEFAULT_PAGE_STATUS_DELETE_PRODUCT_CATEGORY = 'delete-product-category';

/**
 * default page status for account category
 */
export const DEFAULT_PAGE_STATUS_DETAIL_ACCOUNT_CATEGORY = 'detail-account-category';
export const DEFAULT_PAGE_STATUS_UPDATE_ACCOUNT_CATEGORY = 'update-account-category';
export const DEFAULT_PAGE_STATUS_DELETE_ACCOUNT_CATEGORY = 'delete-account-category';

/**
 * default page status for asset category
 */
export const DEFAULT_PAGE_STATUS_LIST_ASSET_CATEGORY = 'list-asset-category';
export const DEFAULT_PAGE_STATUS_DETAIL_ASSET_CATEGORY = 'detail-asset-category';
export const DEFAULT_PAGE_STATUS_DELETE_ASSET_CATGEORY = 'delete-asset-category';
export const DEFAULT_PAGE_STATUS_ADD_ASSET_CATEGORY = 'add-asset-category';
export const DEFAULT_PAGE_STATUS_DISCARD_ADD_ASSET_CATEGORY = 'discard-add-asset-category';
export const DEFAULT_PAGE_STATUS_UPDATE_ASSET_CATEGORY = 'update-asset-category';
export const DEFAULT_PAGE_STATUS_DISCARD_UPDATE_ASSET_CATEGORY = 'discard-update-asset-category';

/**
 * default page status for contact
 */
export const DEFAULT_PAGE_STATUS_CONTACT_DETAILS = 'details-contact';
export const DEFAULT_PAGE_STATUS_CONTACT_DELETE = 'delete-contact';

/**
 * default page status for billing menu
 */
export const DEFAULT_PAGE_STATUS_BILLING_INDEX = 'billing-index';
export const DEFAULT_PAGE_STATUS_SUBSCRIPTION_CHECKOUT = 'subscription-checkout';
export const DEFAULT_PAGE_STATUS_SUBSCRIPTION_CHANGES = 'subscription-change';

/**
 * page status default for setup beginning balance
 */
export const DEFAULT_PAGE_STATUS_BASIC_BEGINNING_BALANCE_SETUP = 'basic-beginning-balance-setup';
export const DEFAULT_PAGE_STATUS_ADVANCE_BEGINNING_BALANCE_SETUP =
  'advance-beginning-balance-setup';

/**
 * page status for report professional base
 */
export const DEFAULT_PAGE_STATUS_REPORT_PROFESSIONAL_PRINT = 'professional-report-print';
export const DEFAULT_PAGE_STATUS_REPORT_PROFESSIONAL_LIST = 'professional-report-list';

/**
 * action page status default
 */
export const DEFAULT_PRINT_PAGE_STATUS = 'print';
export const IMPORT_FILES_PAGE_STATUS = 'import-files';
