import { createAsyncThunk } from '@reduxjs/toolkit';

import referenceNumberServices from '../../services/API/reference-number/reference-number.service';
import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_REFERENCE_NUMBER_DATA } from '../../utils/constants/storage.constant';
import { NAME_STATE_DETAIL_REFERENCE_NUMBER } from '../../utils/constants/store.constant';
import { creatorDetailAction } from '../creator-action/creator-action.helper';
import messageHelper from '../message/message.helper';

// listing reference number
export const referenceNumberList = createAsyncThunk(
  'reference-number/list',
  async ({ isRewriteAll = true, ...dataReferenceNumberList }, thunkAPI) => {
    try {
      const response = await referenceNumberServices.referenceNumberList(dataReferenceNumberList);

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      let { data: referenceNumberData, sucess } = response.data;

      if (!sucess && !Array.isArray(referenceNumberData.data)) {
        if (!referenceNumberData.length) {
          referenceNumberData = {
            data: [],
          };
        }
      }

      const currentRefNumber = LocalStorage.get(LOCAL_STORAGE_REFERENCE_NUMBER_DATA);

      // if(!currentRefNumber || isRewriteAll) {
      //     LocalStorage.set(LOCAL_STORAGE_REFERENCE_NUMBER_DATA, referenceNumberData)

      //     return {referenceNumberData}
      // }

      if (!currentRefNumber || isRewriteAll) {
        LocalStorage.set(LOCAL_STORAGE_REFERENCE_NUMBER_DATA, referenceNumberData);

        return { referenceNumberData };
      }

      // combine data from previous
      const newListDataRefNumber = currentRefNumber.data.concat(referenceNumberData.data);

      // adding data from previous
      const newDataRefNumber = Object.assign(currentRefNumber, referenceNumberData, {
        data: newListDataRefNumber,
      });

      LocalStorage.set(LOCAL_STORAGE_REFERENCE_NUMBER_DATA, newDataRefNumber);

      return { referenceNumberData: newDataRefNumber };
    } catch (error) {
      if (!error.response) {
        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      return thunkAPI.rejectWithValue(data);
    }
  },
);

export const getDetailReferenceNumber = createAsyncThunk(
  'reference-number/detail',
  async (paramsDetailRefNumber, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      paramsDetailRefNumber,
      referenceNumberServices.referenceNumberList,
      '',
      NAME_STATE_DETAIL_REFERENCE_NUMBER,
      'error.detail.reference-number',
    );
  },
);

// adding reference number
export const addReferenceNumber = createAsyncThunk(
  'reference-number/add',
  async (addReferenceNumberData, thunkAPI) => {
    try {
      const response = await referenceNumberServices.addReferenceNumber(addReferenceNumberData);

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.add.reference-number',
          'success.add.reference-number',
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.add.reference-number',
        );
      }

      // get current reference number to listing reference number data
      const currentReferenceNumberData = LocalStorage.get(LOCAL_STORAGE_REFERENCE_NUMBER_DATA);

      let page = 1;
      let paginate = 10;
      let module = '';

      if (currentReferenceNumberData) {
        const { current_page, per_page, search_module } = currentReferenceNumberData;

        page = current_page;
        paginate = per_page;
        module = search_module;
      }

      // listing reference number data again when add reference number
      await thunkAPI.dispatch(
        referenceNumberList({
          page,
          paginate,
          module,
        }),
      );

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.failedMessage(thunkAPI.dispatch, 'error.internal-server-error', '', false);

        return thunkAPI.rejectWithValue(error.response);
      }

      // get response message
      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.add.reference-number',
          'error.add.reference-number',
          false,
          {},
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.add.reference-number',
          false,
          {},
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// update reference number
export const updateReferenceNumber = createAsyncThunk(
  'reference-number/update',
  async (updateReferenceNumberData, thunkAPI) => {
    try {
      const response = await referenceNumberServices.updateReferenceNumber(
        updateReferenceNumberData,
      );

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.update.reference-number',
          'success.update.reference-number',
          {},
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.update.reference-number',
          {},
        );
      }

      // get current reference number to listing reference number data
      const currentReferenceNumberData = LocalStorage.get(LOCAL_STORAGE_REFERENCE_NUMBER_DATA);

      let page = 1;
      let paginate = 10;

      if (currentReferenceNumberData) {
        const { current_page, per_page } = currentReferenceNumberData;

        page = current_page;
        paginate = per_page;
      }

      // listing data again when update reference number
      await thunkAPI.dispatch(
        referenceNumberList({
          page,
          paginate,
        }),
      );

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.update.reference-number',
          'error.update.reference-number',
          false,
          {},
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.update.reference-number',
          false,
          {},
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// delete reference number
export const deleteReferenceNumber = createAsyncThunk(
  'reference-number/delete',
  async (deleteReferenceNumberData, thunkAPI) => {
    try {
      const response = await referenceNumberServices.deleteReferenceNumber(
        deleteReferenceNumberData,
      );

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.delete.reference-number',
          'success.delete.reference-number',
          {},
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.delete.reference-number',
          {},
        );
      }

      // get current reference number list
      const referenceNumberData = LocalStorage.get(LOCAL_STORAGE_REFERENCE_NUMBER_DATA);

      let page = 1;
      let paginate = 10;

      if (referenceNumberData) {
        const { current_page, per_page, data } = referenceNumberData;

        page = current_page;
        paginate = per_page;

        // get previous data when current data is null
        if (data.length === 1) {
          page = page - 1;
        }
      }

      await thunkAPI.dispatch(
        referenceNumberList({
          page,
          paginate,
        }),
      );

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.delete.reference-number',
          'error.delete.reference-number',
          false,
          {},
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.delete.reference-number',
          false,
          {},
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);
