import axios from 'axios';

import { REACT_APP_API_URL_INDO_REGIONAL } from '../../../utils/configs/api.config';
import { authHeader } from '../../../utils/helpers/auth-header.helpers';

// listing indo regional
const regionalList = async ({
  page = 1,
  paginate = 15,
  province_id,
  province_code,
  province_name,
  city,
  sub_district,
  urban,
  postal_code_id,
  postal_code,
}) => {
  console.log(province_name);
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  let dataIndoRegionalList = {
    page,
    paginate,
  };

  if (province_id) {
    dataIndoRegionalList.province_id = province_id;
  }

  if (province_code) {
    dataIndoRegionalList.province_code = province_code;
  }

  if (province_name) {
    dataIndoRegionalList.province_name = province_name;
  }

  if (city) {
    dataIndoRegionalList.city = city;
  }

  if (sub_district) {
    dataIndoRegionalList.sub_district = sub_district;
  }

  if (urban) {
    dataIndoRegionalList.urban = urban;
  }

  if (postal_code_id) {
    dataIndoRegionalList.postal_code_id = postal_code_id;
  }

  if (postal_code) {
    dataIndoRegionalList.postal_code = postal_code;
  }

  return await axios({
    method: 'GET',
    url: REACT_APP_API_URL_INDO_REGIONAL,
    params: {
      ...dataIndoRegionalList,
    },
    headers: {
      Authorization,
    },
  });
};

const indoRegionalServices = {
  regionalList,
};

export default indoRegionalServices;
