import { blue, green, grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

import { blue as customBlue } from '../colors';
import gray from '../colors/gray';

const defaultTheme = createTheme();

export const theme = createTheme({
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      xxl: 2000,
    },
  },
  palette: {
    secondaryApp: {
      ...defaultTheme.palette.secondary,
      main: '#3F51B5',
    },
    buttonAuth: {
      ...defaultTheme.palette.primary,
      main: '#0052CC',
    },
    appBar: {
      main: '#fafafa',
    },
    chipRoleUser: {
      ...defaultTheme.palette.success,
      main: '#E3FCEF',
      contrastText: '#006644',
    },
    chipStatusCompany: {
      ...defaultTheme.palette.primary,
      main: '#0052CC',
      contrastText: '#DEEBFF',
    },
    chipRoleUserList: {
      ...defaultTheme.palette.success,
      main: '#E0E0E0',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    chipStatusPurchaseOpen: {
      ...defaultTheme.palette.primary,
      main: '#F2F4F7',
      contrastText: '#344054',
    },
    chipStatusPurchaseClosed: {
      ...defaultTheme.palette.success,
      main: '#ECFDF3',
      contrastText: '#027A48',
    },
    chipStatusPurchaseClose: {
      ...defaultTheme.palette.success,
      main: '#ECFDF3',
      contrastText: '#027A48',
    },
    chipStatusPurchaseReject: {
      ...defaultTheme.palette.primary,
      main: '#FEF3F2',
      contrastText: '#B42318',
    },
    chipStatusPurchasePartiallySent: {
      ...defaultTheme.palette.primary,
      main: '#EFF8FF',
      contrastText: '#175CD3',
    },
    chipStatusPurchasePaid: {
      ...defaultTheme.palette.primary,
      main: '#ECFDF3',
      contrastText: '#027A48',
    },
    chipStatusPurchasePartiallyPaid: {
      ...defaultTheme.palette.primary,
      main: '#EFF8FF',
      contrastText: '#175CD3',
    },
    chipStatusPurchaseOverdue: {
      ...defaultTheme.palette.primary,
      main: '#FEF3F2',
      contrastText: '#B42318',
    },
    chipStatusSalesOrderOpen: {
      ...defaultTheme.palette.primary,
      main: '#F2F4F7',
      contrastText: '#344054',
    },
    chipStatusSalesOrderPartiallySent: {
      ...defaultTheme.palette.primary,
      main: '#EFF8FF',
      contrastText: '#175CD3',
    },
    chipStatusSalesOrderReject: {
      ...defaultTheme.palette.primary,
      main: '#FEF3F2',
      contrastText: '#B42318',
    },
    chipStatusSalesOrderClosed: {
      ...defaultTheme.palette.success,
      main: '#ECFDF3',
      contrastText: '#027A48',
    },
    chipSuccessStatus: {
      ...defaultTheme.palette.success,
      main: '#ECFDF3', // light green background
      contrastText: '#027A48', // green text
    },
    chipSuccessV2status: {
      ...defaultTheme.palette.success,
      main: '#00875A', // dark green background
      contrastText: '#FFFFFF', // white text
    },
    chipSecondaryStatus: {
      ...defaultTheme.palette.grey[100],
      main: '#F2F4F7', // light grey background
      contrastText: '#344054', // grey text
    },
    chipWarningStatus: {
      ...defaultTheme.palette.warning,
      main: '#FFFAEB', // light yellow brown background
      contrastText: '#B54708', // yellow brown text
    },
    chipWarningStatusV2: {
      ...defaultTheme.palette.warning,
      main: '#FFFAEB', // light yellow brown background
      contrastText: '#F79009', // yellow brown text
    },
    chipInfoStatus: {
      ...defaultTheme.palette.primary,
      main: '#EFF8FF', // light blue background
      contrastText: '#175CD3', // blue background
    },
    chipInfoV2: {
      ...defaultTheme.palette.primary,
      main: '#FFF', // light blue background
      contrastText: '#175CD3', // blue background
    },
    chipErrorStatus: {
      ...defaultTheme.palette.error,
      main: '#FEF3F2', // light red background
      contrastText: '#B42318', // red text
    },
    chipPurpleStatus: {
      ...defaultTheme.palette.error,
      main: '#F9F5FF', // light purple background
      contrastText: '#6941C6', // purple text
    },
    background: {
      primary: '#fafafa',
      mainAuth: '#fafafa',
      mainDashboardPage: '#ebecf0',
      mainDashboardContent: '#FAFBFC',
      mainDashboardTabsContent: '#F4F5F7',
      splitAuthLeft: '#fafafa',
      splitAuthRight: '#ffffff',
      footerDashboard: '#F4F5F7',
    },
    hoverItem: {
      ...defaultTheme.palette.primary,
      main: '#0052CC',
    },
    whiteMain: {
      main: '#fff',
      light: '#fff',
      dark: '#fff',
      contrastText: '#000',
    },
    blue: {
      49: '#EFF8FF',
    },
    grey: {
      ...defaultTheme.palette.grey,
      51: '#F9FAFB',
      650: '#6B778C',
    },
  },
  typography: {
    fontFamily: 'Inter',
    h3: {
      fontFamily: 'Inter',
    },
    h5: {
      fontFamily: 'SFProDisplay',
    },
    title1: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      fontWeight: 500,
    },
    title2: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
    },
    title3: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 18,
      lineHeight: 1.6,
    },
    title4: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      fontWeight: 600,
      fontSize: 24,
    },
    title5: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 20,
    },
    title6: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      fontWeight: 600,
      fontSize: 20,
    },
    title7: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      fontWeight: 600,
      fontSize: 16,
    },
    title8: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      fontWeight: 700,
      fontSize: 24,
    },
    title9: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      fontSize: 24,
      fontWeight: 500,
    },
    title10: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      fontSize: 36,
      fontWeight: 600,
    },
    title11: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      fontSize: 33,
      fontWeight: 600,
    },
    subtitle3: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      fontSize: '14px',
      color: '#667085',
    },
    subtitle4: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      fontSize: '14px',
      color: '#667085',
      fontWeight: 500,
    },
    subtitle5: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      fontSize: '14px',
      color: '#667085',
      fontWeight: 400,
    },
    subtitle6: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      fontSize: '12px',
      color: '#667085',
      fontWeight: 400,
    },
    subtitle7: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      fontSize: '16px',
      color: '#667085',
      fontWeight: 500,
    },
    subtitle8: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      fontSize: '10px',
      color: '#667085',
      fontWeight: 400,
    },
    subtitle9: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      fontSize: '20px',
      color: '#667085',
      fontWeight: 400,
    },
    body3: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    body4: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      color: 'black',
      fontSize: '14px',
      fontWeight: 400,
    },
    body5: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      color: 'black',
      fontSize: '12px',
      fontWeight: 500,
    },
    body6: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      color: 'black',
      fontSize: '12px',
      fontWeight: 400,
    },
    body7: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      color: 'black',
      fontSize: '14px',
      fontWeight: 700,
    },
    body8: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      color: 'black',
      fontSize: '12px',
      fontWeight: 700,
    },
    body9: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      color: 'black',
      fontSize: '14px',
      fontWeight: 500,
    },
    appBrand: {
      fontFamily: 'Inter',
      fontSize: '1.75rem',
      fontWeight: '800',
      color: '#0052CC',
      textAlign: 'left',
    },
    disabled: {
      fontFamily: 'Inter',
      fontSize: '0.875rem',
      fontWeight: '400',
      color: '#6B778C',
    },
    footer: {
      color: '#505F79',
      fontWeight: '400',
      fontSize: '0.875rem',
    },
    footer2: {
      color: '#505F79',
      fontWeight: '400',
      fontSize: '0.6875rem',
    },
    label1: {
      ...defaultTheme.typography.body1,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      color: '#101828',
    },
    label2: {
      ...defaultTheme.typography.body1,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      color: '#344054',
    },
    link: {
      fontWeight: '400',
      cursor: 'pointer',
      fontSize: '0.6875rem',
      color: '#0065ff',
      '&:hover': {
        fontWeight: '500',
        textDecoration: 'underline',
      },
    },
    linkOnTable: {
      fontWeight: '400',
      cursor: 'pointer',
      color: '#0065ff',
      '&:hover': {
        fontWeight: '500',
        textDecoration: 'underline',
      },
    },
    linkDetail: {
      fontWeight: '400',
      cursor: 'pointer',
      color: '#0065ff',
      textDecoration: 'none',
      '&:hover': {
        fontWeight: '500',
        textDecoration: 'underline',
      },
    },
    linkDetailV2: {
      fontSize: '14px',
      fontWeight: '500',
      cursor: 'pointer',
      color: '#0065ff',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    linkDetailV3: {
      fontSize: '12px',
      fontWeight: '500',
      cursor: 'pointer',
      color: '#0065ff',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    linkWithoutUnderline: {
      fontWeight: '500',
      cursor: 'pointer',
      color: '#667085',
      textDecoration: 'none',
      '&:hover': {
        fontWeight: '500',
        textDecoration: 'none',
      },
    },
    smallIndicator1: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      textAlign: 'left',
      color: gray['B500'],
    },
    smallIndicator2: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '0px',
      textAlign: 'left',
      color: gray['B500'],
    },
    smallIndicator3: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '0px',
      textAlign: 'left',
      color: gray['B500'],
    },
    boldIndicator1: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '16px',
      letterSpacing: '0px',
      textAlign: 'left',
      color: gray['B500'],
    },
    boldIndicator2: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '16px',
      letterSpacing: '0px',
      textAlign: 'left',
      color: gray['B500'],
    },
    linkPrimary: {
      ...defaultTheme.typography.body1,
      fontFamily: 'Inter',
      fontSize: '19px',
      color: '#0052cc',
      fontWeight: 600,
    },
    italicV1: {
      ...defaultTheme.typography.body2,
      fontSize: '12px',
      fontStyle: 'italic',
      color: gray['B499'],
    },
    // red italic
    italicV2: {
      ...defaultTheme.typography.body2,
      fontSize: '12px',
      fontStyle: 'italic',
      color: 'red',
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          '&.avatarPrimary': {
            width: '130px',
            height: '130px',
            fontSize: '4rem',
          },
          '&.avatarRoundedPrimary': {
            width: '-webkit-fill-available',
            height: '130px',
            fontSize: '4rem',
          },
        },
      },
    },
    MuiOutlinedInput: {
      ...defaultTheme.components.MuiOutlinedInput,
      defaultProps: {
        inputProps: {
          maxLength: 50,
        },
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            backgroundColor: '#EEF0F1',
          },
          '& .Mui-disabled': {
            cursor: 'not-allowed',
          },
          '& input': {
            height: '12px',
          },
          'label + &': {
            fontFamily: 'Inter',
            marginTop: '10px',
          },
          '&.none-padding': {
            padding: '0px',
          },
          '&.input-calculation-number': {
            input: {
              textAlign: 'right',
            },
            '& input': {
              textAlign: 'right',
            },
          },
          '&.input-center': {
            input: {
              textAlign: 'center',
            },
            '& input': {
              textAlign: 'center',
            },
          },
          '&.calculation-input': {
            input: {
              textAlign: 'right',
            },
          },
        },
        input: {
          padding: '10px 8px',
          '&:focus': {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          marginLeft: '-12px',
          '&.primaryInputLabel': {
            fontSize: '12px',
            transform: 'translate(14px, -9px) scale(1)',
            fontWeight: '600',
            color: gray['B499'],
          },
        },
        asterisk: {
          color: 'red',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          'label + &': {
            fontFamily: 'Inter',
            marginTop: '10px',
          },
          '&.minimumInput': {
            paddingRight: '0px',
            '& .MuiOutlinedInput-input': {
              minWidth: '0px!important',
            },
          },
        },
        input: {
          padding: '10px 8px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.mainButton': {
            height: '32px',
          },
          '&.blueSixBorderNwhiteBackground': {
            height: '32px',
            borderRadius: '8px',
            background: 'white',
            border: '1px solid #1570EF',
            color: '#1570EF',
            '& path': {
              stroke: '#1570EF',
            },
            '& .MuiSvgIcon-root': {
              color: '#1570EF',
            },
            '&:disabled': {
              opacity: '0.75',
            },
          },
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: {
            variant: 'contained',
            className: 'primaryButton',
          },
          style: {
            height: '36px',
            color: 'white',
            borderColor: '#0052CC',
            backgroundColor: '#0052CC',
            minWidth: 'max-content',
            borderRadius: '8px',
            boxShadow: defaultTheme.shadows[1],
            ':hover': {
              backgroundColor: '#0045ad',
              boxShadow: defaultTheme.shadows[5],
            },
            '&.MuiButton-disableElevation': {
              boxShadow: defaultTheme.shadows[0],
            },
          },
        },
        {
          props: {
            variant: 'outlined',
            className: 'primaryButtonV2',
          },
          style: {
            color: '#0052CC',
            borderColor: '#0052CC',
            backgroundColor: 'white',
            padding: '3px 5px',
            minWidth: 'max-content',
            borderRadius: '8px',
            boxShadow: defaultTheme.shadows[1],
            '& svg>path': {
              fill: '#0052CC',
            },
            ':hover': {
              backgroundColor: 'white',
              boxShadow: defaultTheme.shadows[5],
            },
            '&.MuiButton-disableElevation': {
              boxShadow: defaultTheme.shadows[0],
            },
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'primaryButtonOnDisabledOpacity',
          },
          style: {
            height: '36px',
            color: 'white',
            borderColor: '#0052CC',
            backgroundColor: '#0052CC',
            boxShadow: defaultTheme.shadows[1],
            ':hover': {
              backgroundColor: '#0045ad',
              boxShadow: defaultTheme.shadows[5],
            },
            '&.Mui-disabled': {
              opacity: '0.9',
              color: 'white',
              backgroundColor: '#0052CC',
            },
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'primaryButtonUnsetBoxShadowBorderRadiusTwentyFourNminWidth',
          },
          style: {
            height: '36px',
            color: 'white',
            minWidth: '126px',
            borderColor: '#0052CC',
            backgroundColor: '#0052CC',
            boxShadow: defaultTheme.shadows[0],
            borderRadius: '24px',
            ':hover': {
              backgroundColor: '#0045ad',
              boxShadow: defaultTheme.shadows[0],
            },
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'primaryButtonV2',
          },
          style: {
            height: '36px',
            color: 'white',
            borderColor: blue[700],
            backgroundColor: blue[700],
            minWidth: 'max-content',
            borderRadius: '8px',
            boxShadow: defaultTheme.shadows[0],
            ':hover': {
              backgroundColor: blue[800],
              boxShadow: defaultTheme.shadows[0],
            },
            '&.MuiButton-disableElevation': {
              boxShadow: defaultTheme.shadows[0],
            },
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'primaryButtonV3',
          },
          style: {
            color: 'white',
            height: '32px',
            width: 'max-content',
            fontSize: '14px',
            padding: '6px 14px',
            borderRadius: '8px',
            backgroundColor: customBlue['B400'],
            '&:hover': {
              backgroundColor: blue[700],
            },
            '& .MuiSvgIcon-root': {
              color: 'white',
            },
            '&.MuiButton-disableElevation': {
              boxShadow: defaultTheme.shadows[0],
            },
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'secondaryButton',
          },
          style: {
            height: '36px',
            color: '#6B778C',
            backgroundColor: '#EBECF0',
            boxShadow: defaultTheme.shadows[1],
            borderRadius: '8px',
            ':hover': {
              backgroundColor: '#cfd2db',
              boxShadow: defaultTheme.shadows[5],
            },
            '&.MuiButton-disableElevation': {
              boxShadow: defaultTheme.shadows[0],
            },
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'secondaryButtonV2',
          },
          style: {
            height: '36px',
            color: '#6B778C',
            backgroundColor: '#EBECF0',
            boxShadow: defaultTheme.shadows[0],
            borderRadius: '8px',
            ':hover': {
              backgroundColor: '#cfd2db',
              boxShadow: defaultTheme.shadows[0],
            },
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'secondaryButtonOnDisabledOpacity',
          },
          style: {
            height: '36px',
            color: '#6B778C',
            backgroundColor: '#EBECF0',
            boxShadow: defaultTheme.shadows[1],
            ':hover': {
              backgroundColor: '#cfd2db',
              boxShadow: defaultTheme.shadows[5],
            },
            '&.Mui-disabled': {
              opacity: '0.9',
              color: '#6B778C',
              backgroundColor: '#EBECF0',
            },
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'secondaryButtonUnsetBoxShadowBorderRadiusTwentyFourNminWidth',
          },
          style: {
            height: '36px',
            minWidth: '126px',
            color: '#6B778C',
            backgroundColor: '#EBECF0',
            boxShadow: defaultTheme.shadows[0],
            borderRadius: '24px',
            ':hover': {
              backgroundColor: '#cfd2db',
              boxShadow: defaultTheme.shadows[0],
            },
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'primaryDeleteButton',
          },
          style: {
            color: 'white',
            height: '36px',
            backgroundColor: '#D92D20',
            borderRadius: '8px',
            boxShadow: defaultTheme.shadows[0],
            ':hover': {
              opacity: '0.7',
              backgroundColor: '#D92D20',
            },
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'primaryDeleteButtonUnsetBoxShadowBorderRadiusTwentyFourNminWidth',
          },
          style: {
            color: 'white',
            minWidth: '126px',
            borderRadius: '24px',
            height: '36px',
            backgroundColor: '#D92D20',
            boxShadow: defaultTheme.shadows[0],
            ':hover': {
              opacity: '0.7',
              backgroundColor: '#D92D20',
              boxShadow: defaultTheme.shadows[0],
            },
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'secondaryDeleteButton',
          },
          style: {
            height: '36px',
            color: '#667085',
            backgroundColor: '#D0D5DD',
            boxShadow: defaultTheme.shadows[0],
            ':hover': {
              opacity: '0.7',
              backgroundColor: '#D0D5DD',
            },
          },
        },
        {
          props: {
            variant: 'outlined',
            className: 'primaryOutlinedDeleteButton',
          },
          style: {
            color: '#D92D20',
            height: '36px',
            backgroundColor: 'white',
            borderRadius: '8px',
            borderColor: '#D92D20',
            ':hover': {
              color: 'white',
              borderColor: '#D92D20',
              backgroundColor: '#D92D20',
            },
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'primaryWarningButton',
          },
          style: {
            color: 'white',
            minWidth: '126px',
            borderRadius: '8px',
            height: '36px',
            backgroundColor: '#DC6803',
            boxShadow: defaultTheme.shadows[0],
            ':hover': {
              opacity: '0.7',
              backgroundColor: '#DC6803',
              boxShadow: defaultTheme.shadows[0],
            },
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'primaryWarningButtonUnsetBoxShadowBorderRadiusTwentyFourNminWidth',
          },
          style: {
            color: 'white',
            minWidth: '126px',
            borderRadius: '24px',
            height: '36px',
            backgroundColor: '#DC6803',
            boxShadow: defaultTheme.shadows[0],
            ':hover': {
              opacity: '0.7',
              backgroundColor: '#DC6803',
              boxShadow: defaultTheme.shadows[0],
            },
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'primarySuccessButton',
          },
          style: {
            height: '36px',
            color: 'white',
            minWidth: '126px',
            borderColor: green[700],
            backgroundColor: green[700],
            boxShadow: defaultTheme.shadows[0],
            borderRadius: '8px',
            ':hover': {
              backgroundColor: green[600],
              boxShadow: defaultTheme.shadows[0],
            },
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'primarySuccessUnsetBoxShadowBorderRadiusTwentyFourNminWidth',
          },
          style: {
            height: '36px',
            color: 'white',
            minWidth: '126px',
            borderColor: green[700],
            backgroundColor: green[700],
            boxShadow: defaultTheme.shadows[0],
            borderRadius: '24px',
            ':hover': {
              backgroundColor: green[600],
              boxShadow: defaultTheme.shadows[0],
            },
          },
        },
        {
          props: {
            variant: 'outlined',
            className: 'secondaryButton',
          },
          style: {
            color: '#3F51B5',
            borderColor: '#3F51B5',
            ':hover': {
              color: 'white',
              borderColor: '#3F51B5',
              backgroundColor: '#3F51B5',
              opacity: '0.7',
            },
          },
        },
        {
          props: {
            type: 'submit',
            variant: 'contained',
            className: 'authButton',
          },
          style: {
            backgroundColor: '#0052CC',
            boxShadow: defaultTheme.shadows[1],
            ':hover': {
              backgroundColor: '#0045ad',
              boxShadow: defaultTheme.shadows[5],
            },
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'authVerification',
          },
          style: {
            backgroundColor: '#0052CC',
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'authError',
          },
          style: {
            backgroundColor: '#0052CC',
            ':hover': {
              backgroundColor: '#0045ad',
            },
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'authForbidden',
          },
          style: {
            backgroundColor: '#A5ADBA',
            ':hover': {
              backgroundColor: '#8791a3',
            },
          },
        },
        {
          props: {
            variant: 'text',
            className: 'appBarButton',
          },
          style: {
            textTransform: 'none',
            backgroundColor: '#fff',
            color: 'black',
            paddingLeft: '4px',
            paddingRight: '4px',
            marginRight: '8px',
            ':hover': {
              backgroundColor: '#F4F5F7',
              opacity: '0.7',
            },
          },
        },
        {
          props: {
            variant: 'text',
            className: 'link-navigation',
          },
          style: {
            padding: '0px',
            color: '#1570EF',
            display: 'inline',
            textTransform: 'none',
            ':hover': {
              textDecoration: 'underline',
            },
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'primaryInfoButton',
          },
          style: {
            color: 'white',
            minWidth: '126px',
            borderRadius: '8px',
            height: '36px',
            backgroundColor: customBlue['B600'],
            boxShadow: defaultTheme.shadows[0],
            ':hover': {
              opacity: '0.7',
              backgroundColor: customBlue['B600'],
              boxShadow: defaultTheme.shadows[0],
            },
          },
        },
        {
          props: {
            variant: 'contained',
            className: 'primaryLightButton',
          },
          style: {
            color: blue['700'],
            height: '36px',
            borderRadius: '4px',
            backgroundColor: blue['50'],
            ':hover': {
              backgroundColor: blue['100'],
            },
          },
        },
      ],
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          '&.whiteMiddleBorder': {
            '& .MuiButtonGroup-grouped:not(:last-of-type)': {
              borderRight: '1px solid #7986CB',
            },
          },
          '&.blueSixMiddleBorder': {
            boxShadow: 'none',
            '& .MuiButtonGroup-grouped:not(:last-of-type)': {
              borderRight: '1px solid #1570EF',
            },
          },
        },
        grouped: {
          minWidth: '24px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.nonPadding': {
            padding: 0,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.chipRoleUser': {
            textTransform: 'capitalize',
          },
          '&.chipRoleViewUser': {
            marginTop: '10px',
          },
          '&.chipVerifiedEmail': {
            color: '#FFFFFF',
            backgroundColor: '#00875A',
            margin: '0px 0px 0px 10px',
          },
          '&.chipNotVerifiedEmail': {
            color: '#505F79',
            backgroundColor: '#EBECF0',
            margin: '0px 0px 0px 10px',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.formHelperTextMain': {
            marginLeft: '4px',
            marginRight: '4px',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&.removingMarginXAxisParagraph': {
            ' p': {
              marginLeft: 0,
              marginRight: 0,
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '&.formControlLabelSwitchMain': {
            width: 'fit-content',
          },
          '&.formControlLabelSwitchSecondary': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '0px',
          },
          '&.formControlLabelSubscription': {
            alignItems: 'start',
            border: '1px solid',
            borderColor: gray['B300'],
            borderRadius: '8px',
            marginLeft: '0px',
            marginRight: '0px',
            width: 'calc(100% - 24px)',
            height: 'calc(100% - 24px)',
            padding: '12px',
            [defaultTheme.breakpoints.down('sm')]: {
              height: 'fit-content',
            },
          },
          '&.formControlLabelSubscription-selected': {
            border: '2px solid',
            borderColor: blue['600'],
            boxShadow: defaultTheme.shadows[2],
          },
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          '&.whiteStroke': {
            '& path': {
              stroke: 'white',
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.iconButtonAppBar': {
            marginLeft: '4px',
            marginRight: '4px',
            '&:hover': {
              backgroundColor: '#DEEBFF',
              color: '#0052CC',
              '& svg': {
                color: '#0052CC',
                fill: '#0052CC',
              },
              '& .icon>path': {
                fill: '#0052CC',
              },
            },
            '&.active': {
              backgroundColor: '#DEEBFF',
              '& svg': {
                color: '#0052CC',
                fill: '#0052CC',
              },
              '& .icon>path': {
                fill: '#0052CC',
              },
            },
          },
          '&.iconButtonActionPrimary': {
            '& .MuiSvgIcon-root': {
              fontSize: '20px',
              color: 'rgba(0, 0, 0, 0.54)',
            },
            '&:hover': {
              backgroundColor: 'rgba(222, 235, 255, 0.6)',
              '& svg': {
                fill: '#0052CC',
              },
            },
          },
          '&.iconButtonActionSecondary': {
            borderRadius: '8px',
            backgroundColor: defaultTheme.palette.grey['300'],
            '& .MuiSvgIcon-root': {
              fontSize: '20px',
              color: 'rgba(0, 0, 0, 0.54)',
            },
            '&:hover': {
              backgroundColor: defaultTheme.palette.grey['200'],
            },
          },
          '&.iconButtonActionTersier': {
            borderRadius: '8px',
            fontSize: 'small',
            '& svg': {
              fill: 'black',
            },
          },
          '&.nonPadding': {
            padding: '0px',
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          color: '#0065FF',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          '&.primaryDivider': {
            borderWidth: '1px',
          },
          '&.secondaryDivider': {
            borderWidth: '0px 1px 0px 0px',
          },
          '&.topOnlyDivider': {
            borderWidth: '1px 0px 0px 0px',
          },
          '&.leftOnlyDivider': {
            borderWidth: '0px 1px 0px 0px',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          '&.dialogTransparent': {
            background: '#ffffff00',
            boxShadow: 'unset',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '&.dialogActionsPrimary': {
            padding: '20px 24px',
          },
          '&.dialogActionsSpaceBetweenPrimary': {
            display: 'flex',
            alignItems: 'end',
            flexDirection: 'row',
            padding: '0px 32px 24px 32px',
            justifyContent: 'space-between',
          },
          '&.dialogActionsFifth': {
            padding: '0px 24px 16px 24px',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '&.dialogContentPrimary': {
            display: 'flex',
            margin: 'auto 0px',
            flexDirection: 'column',
            padding: '10px 32px!important',
            [defaultTheme.breakpoints.down('sm')]: {
              padding: '8px!important',
            },
          },
          '&.dialogContentSecondary': {
            position: 'relative',
            borderRadius: '0px 0px 4px 4px',
            background: defaultTheme.palette.grey[200],
          },
          '&.dialogContentTersier': {
            display: 'flex',
            margin: 'auto 0px',
            flexDirection: 'column',
            padding: '32px!important',
            paddingTop: '12px!important',
            [defaultTheme.breakpoints.down('sm')]: {
              padding: '8px!important',
            },
          },
          '&.dialogContentFourth': {
            position: 'relative',
            padding: '32px !important',
            borderRadius: '0px 0px 4px 4px',
            background: defaultTheme.palette.grey[200],
          },
          '&.dialogContentTransparent': {
            background: '#ffffff00',
          },
          '&.dialogContentFifth': {
            display: 'flex',
            margin: 'auto 0px',
            flexDirection: 'column',
            padding: '16px 24px!important',
            [defaultTheme.breakpoints.down('sm')]: {
              padding: '8px!important',
            },
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '& .boxContainerCloseIconPrimary.MuiBox-root': {
            top: 8,
            right: 8,
            borderRadius: '50%',
            position: 'absolute',
            color: defaultTheme.palette.grey[500],
            display: 'flex',
            height: '32px',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            '& svg': {
              fontSize: '1rem!important',
            },
          },
          '& .boxContainerCloseIconSecondary.MuiBox-root': {
            top: 8,
            right: 8,
            borderRadius: '50%',
            position: 'absolute',
            color: defaultTheme.palette.grey[500],
            backgroundColor: 'white',
            '& svg': {
              fontSize: '1rem!important',
            },
          },
          '&.dialogTitleFifth': {
            padding: '16px 24px 0px 24px',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          '&.MuiListItemButton-root:hover': {
            '& .icon>path': {
              fill: '#0052CC',
            },
          },
          '&.MuiListItemButton-root.Mui-selected': {
            backgroundColor: '#DEEBFF',
            color: '#0052CC',
            '& .icon>path': {
              fill: '#0052CC',
            },
          },
          '&.sidebarDashboard': {
            paddingLeft: '10px',
            paddingRight: '10px',
            '@media (max-width: 600px)': {
              justifyContent: 'center',
              '& .MuiListItemText-root': {
                display: 'none',
              },
            },
            '&.sidebarClose': {
              justifyContent: 'center',
              '& .MuiListItemText-root': {
                display: 'none',
              },
            },
            ':hover': {
              backgroundColor: '#DEEBFF',
              color: '#0052CC',
            },
          },
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '24px',
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 3,
      },
      styleOverrides: {
        root: {
          minHeight: '56px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: '24px',
          paddingRight: '24px',
          minHeight: '56px',
          '@media (min-width: 600px)': {
            minHeight: '56px',
            paddingLeft: '18px!important',
            paddingRight: '18px!important',
          },
          '@media (max-width: 600px)': {
            paddingLeft: '12px!important',
            paddingRight: '12px!important',
          },
          '& .MuiTablePagination-actions': {
            flexGrow: 1,
            display: 'inline-flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          },
          '& p.MuiTablePagination-selectLabel': {
            margin: '0px',
            padding: '6px 8px',
            borderRadius: '8px 0px 0px 8px',
            border: '2px solid #E4E7EC',
          },
          '& .MuiInputBase-root': {
            margin: '0px',
          },
          '& .MuiTablePagination-select': {
            border: '2px solid #E4E7EC',
            borderRadius: '0px 8px 8px 0px',
            padding: '6px 8px',
            borderLeft: '0px',
            '&:focus': {
              borderRadius: '0px 8px 8px 0px',
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          '&.Mui-selected': {
            borderRadius: '8px 8px 0px 0px',
            backgroundColor: '#EFF8FF',
          },
          '&.menuTab': {
            '&.Mui-selected': {
              borderRadius: '8px',
              backgroundColor: gray['B200'],
              color: 'black',
              border: '1px solid',
              borderColor: blue['600'],
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '&.primaryTabs': {
            minHeight: '12px',
            '& .MuiButtonBase-root': {
              minHeight: '24px',
              padding: '8px 12px!important',
            },
          },
          '&.secondaryTabs': {
            alignItems: 'center',
            '& .MuiTabs-scroller': {
              overflow: 'unset!important',
            },
            '& span.MuiTabs-indicator': {
              width: '100%',
              backgroundColor: 'unset',
            },
            '& .MuiButtonBase-root': {
              minHeight: '24px',
              padding: '4px 16px!important',
              overflow: 'unset!important',
            },
          },
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          '&.smallPaddingTopBottomZeroPaddingHorizontal': {
            padding: '12px 0px',
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '&.tableMain': {
            '& thead:last-of-type>tr>th': {
              borderBottom: '1px solid #E4E7EC',
            },
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '&.primaryTableBody': {
            '& .MuiTableRow-root:nth-of-type(odd)': {
              backgroundColor: 'rgba(204, 233, 255, 0.15)',
            },
            '& .MuiTableRow-root:hover': {
              backgroundColor: 'rgba(204, 233, 255, 0.50)',
            },
            '& .MuiTableCell-root': {
              height: '15px',
              padding: '4px 16px',
              borderBottom: '0px',
              fontSize: '12px',
            },
          },
          '&.secondaryTableBody': {
            '& .MuiTableRow-root:nth-of-type(odd)': {
              backgroundColor: 'rgba(204, 233, 255, 0.15)',
            },
            '& .MuiTableRow-root:hover': {
              backgroundColor: 'rgba(204, 233, 255, 0.50)',
            },
            '& .MuiTableCell-root': {
              height: '15px',
              padding: '1px',
              borderBottom: '0px',
            },
          },
          '&.tersierTableBody': {
            '& .MuiTableRow-root:nth-of-type(odd)': {
              backgroundColor: 'rgba(204, 233, 255, 0.15)',
            },
            '& .MuiTableRow-root:hover': {
              backgroundColor: 'rgba(204, 233, 255, 0.50)',
            },
            '& .MuiTableCell-root': {
              height: '15px',
              padding: '0px 16px',
              paddingRight: '0px',
              borderBottom: '0px',
            },
            '& .MuiTableCell-root:last-of-type': {
              height: '15px',
              padding: '0px 16px',
              paddingRight: '16px',
              borderBottom: '0px',
            },
          },
          '&.fourthTableBody': {
            '& .MuiTableCell-root': {
              height: '15px',
              padding: '8px 16px',
              borderBottom: '1px solid #EFF8FF',
            },
          },
          '&.fifthTableBody': {
            '& .MuiTableRow-root:nth-of-type(odd)': {
              backgroundColor: 'rgba(204, 233, 255, 0.15)',
            },
            '& .MuiTableRow-root:hover': {
              backgroundColor: 'rgba(204, 233, 255, 0.50)',
            },
            '& .MuiTableCell-root': {
              height: '15px',
              padding: '4px 16px',
              borderBottom: '1px solid #EFF8FF',
            },
          },
          '&.primaryMoreHeightTableBody': {
            '& .MuiTableRow-root:nth-of-type(odd)': {
              backgroundColor: 'rgba(204, 233, 255, 0.15)',
            },
            '& .MuiTableRow-root:hover': {
              backgroundColor: 'rgba(204, 233, 255, 0.50)',
            },
            '& .MuiTableCell-root': {
              fontSize: '13px',
              borderBottom: '0px',
              padding: '4px 16px',
              height: '45px!important',
            },
          },
          '&.nonBorderTableBody': {
            '& .MuiTableCell-root': {
              padding: '8px 16px',
              borderBottom: '0px',
            },
          },
          '&.greyBorderTableBody': {
            '& .MuiTableCell-root': {
              padding: '8px 8px',
              border: '1px solid #667085',
            },
          },
          '&.sixthTableBody': {
            '& .MuiTableRow-root:nth-of-type(odd)': {
              backgroundColor: 'rgba(204, 233, 255, 0.15)',
            },
            '& .MuiTableRow-root:hover': {
              backgroundColor: 'rgba(204, 233, 255, 0.50)',
            },
            '& .MuiTableCell-root': {
              height: '15px',
              padding: '4px 8px',
              borderBottom: '0px',
              fontSize: '13px',
            },
          },
          '&.seventhTableBody': {
            '& .MuiTableRow-root:nth-of-type(odd)': {
              backgroundColor: 'rgba(204, 233, 255, 0.15)',
            },
            '& .MuiTableRow-root:hover': {
              backgroundColor: 'rgba(204, 233, 255, 0.50)',
            },
            '& .MuiTableCell-root': {
              padding: '8px',
              borderBottom: '0px',
              height: '20px',
            },
          },
          '&.nonBorderTableBodyWithEvenBlueBackground': {
            '& .MuiTableRow-root.even': {
              backgroundColor: 'rgba(239, 248, 255, 1)',
            },
            '& .MuiTableRow-root:hover': {
              backgroundColor: 'rgba(239, 248, 255, 0.75)',
            },
            '& .MuiTableCell-root': {
              padding: '8px 16px',
              borderBottom: '0px',
            },
          },
          '&.primaryTableBodyWithWhiteBackground': {
            backgroundColor: 'white',
            '& .MuiTableRow-root:nth-of-type(odd)': {
              backgroundColor: 'rgba(204, 233, 255, 0.15)',
            },
            '& .MuiTableRow-root:hover': {
              backgroundColor: 'rgba(204, 233, 255, 0.50)',
            },
            '& .MuiTableCell-root': {
              height: '15px',
              padding: '4px 16px',
              borderBottom: '0px',
              fontSize: '13px',
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.verticalAlignTopOfTableData': {
            ' td': {
              verticalAlign: 'top',
            },
          },
          '&.hoverEntireRow': {
            '&:hover': {
              cursor: 'pointer',
            },
          },
          '&.bottomTableRowPrimary': {
            backgroundColor: `${grey[50]}!important`,
            '& td': {
              borderTop: '1px solid!important',
              borderBottom: '1px solid!important',
              borderColor: `${grey[200]}!important`,
              padding: '4px 16px',
            },
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          '&.tableContainerXXXLSizeFullHeight': {
            height: 'calc(80vh - 8px)',
            marginBottom: '8px',
            '@media only screen and (min-height: 700px)': {
              height: 'calc(78vh - 8px)',
            },
            '@media only screen and (min-height: 700px) and (min-width: 360px)': {
              height: 'calc(90vh - 8px)',
            },
            '@media only screen and (min-height: 900px)': {
              height: 'calc(85vh - 8px)',
            },
            '@media only screen and (min-height: 1300px)': {
              height: 'calc(69vh - 8px)',
            },
            '@media only screen and (min-height: 1400px)': {
              height: 'calc(70vh - 8px)',
            },
            '@media only screen and (min-height: 1700px)': {
              height: 'calc(75vh - 8px)',
            },
            '@media only screen and (min-height: 2000px)': {
              height: 'calc(80vh - 8px)',
            },
          },
          '&.tableContainerXXXLSize': {
            height: 'calc(25vh - 8px)',
            marginBottom: '8px',
            '@media only screen and (min-height: 500px)': {
              height: 'calc(35vh - 8px)',
            },
            '@media only screen and (min-height: 550px)': {
              height: 'calc(37vh - 8px)',
            },
            '@media only screen and (min-height: 600px)': {
              height: 'calc(40vh - 8px)',
            },
            '@media only screen and (min-height: 650px)': {
              height: 'calc(43vh - 8px)',
            },
            '@media only screen and (min-height: 700px)': {
              height: 'calc(45vh - 8px)',
            },
            '@media only screen and (min-height: 750px)': {
              height: 'calc(48vh - 8px)',
            },
            '@media only screen and (min-height: 800px)': {
              height: 'calc(51vh - 8px)',
            },
            '@media only screen and (min-height: 850px)': {
              height: 'calc(55vh - 8px)',
            },
            '@media only screen and (min-height: 900px)': {
              height: 'calc(58vh - 8px)',
            },
            '@media only screen and (min-height: 1000px)': {
              height: 'calc(61vh - 8px)',
            },
            '@media only screen and (min-height: 1100px)': {
              height: 'calc(65vh - 8px)',
            },
            '@media only screen and (min-height: 1200px)': {
              height: 'calc(68vh - 8px)',
            },
            '@media only screen and (min-height: 1300px)': {
              height: 'calc(69vh - 8px)',
            },
            '@media only screen and (min-height: 1400px)': {
              height: 'calc(70vh - 8px)',
            },
            '@media only screen and (min-height: 1700px)': {
              height: 'calc(75vh - 8px)',
            },
            '@media only screen and (min-height: 2000px)': {
              height: 'calc(80vh - 8px)',
            },
          },
          '&.tableContainerXXXLSizeCollapsibleOn': {
            height: 'calc(25vh - 8px + 25px)',
            marginBottom: '8px',
            '@media only screen and (min-height: 500px)': {
              height: 'calc(35vh - 8px + 20px)',
            },
            '@media only screen and (min-height: 550px)': {
              height: 'calc(37vh - 8px + 20px)',
            },
            '@media only screen and (min-height: 600px)': {
              height: 'calc(40vh - 8px + 70px)',
            },
            '@media only screen and (min-height: 650px)': {
              height: 'calc(43vh - 8px + 70px)',
            },
            '@media only screen and (min-height: 700px)': {
              height: 'calc(45vh - 8px + 90px)',
            },
            '@media only screen and (min-height: 750px)': {
              height: 'calc(48vh - 8px + 90px)',
            },
            '@media only screen and (min-height: 800px)': {
              height: 'calc(51vh - 8px + 145px)',
            },
            '@media only screen and (min-height: 850px)': {
              height: 'calc(55vh - 8px + 145px)',
            },
            '@media only screen and (min-height: 900px)': {
              height: 'calc(58vh - 8px + 145px)',
            },
            '@media only screen and (min-height: 1000px)': {
              height: 'calc(61vh - 8px + 145px)',
            },
            '@media only screen and (min-height: 1100px)': {
              height: 'calc(65vh - 8px + 145px)',
            },
            '@media only screen and (min-height: 1200px)': {
              height: 'calc(68vh - 8px + 145px)',
            },
            '@media only screen and (min-height: 1300px)': {
              height: 'calc(69vh - 8px + 145px)',
            },
            '@media only screen and (min-height: 1400px)': {
              height: 'calc(70vh - 8px + 145px)',
            },
            '@media only screen and (min-height: 1700px)': {
              height: 'calc(75vh - 8px + 145px)',
            },
            '@media only screen and (min-height: 2000px)': {
              height: 'calc(80vh - 8px + 145px)',
            },
          },
          // middle size
          '&.tableContainerMDSize': {
            height: 'calc(26vh - 8px)',
            marginBottom: '8px',
            '@media only screen and (min-height: 500px)': {
              height: 'calc(36vh - 8px)',
            },
            '@media only screen and (min-height: 550px)': {
              height: 'calc(38vh - 8px)',
            },
            '@media only screen and (min-height: 600px)': {
              height: 'calc(41vh - 8px)',
            },
            '@media only screen and (min-height: 650px)': {
              height: 'calc(44vh - 8px)',
            },
            '@media only screen and (min-height: 700px)': {
              height: 'calc(46vh - 8px)',
            },
            '@media only screen and (min-height: 750px)': {
              height: 'calc(49vh - 8px)',
            },
            '@media only screen and (min-height: 800px)': {
              height: 'calc(52vh - 8px)',
            },
            '@media only screen and (min-height: 850px)': {
              height: 'calc(56vh - 8px)',
            },
            '@media only screen and (min-height: 900px)': {
              height: 'calc(59vh - 8px)',
            },
            '@media only screen and (min-height: 1000px)': {
              height: 'calc(62vh - 8px)',
            },
            '@media only screen and (min-height: 1100px)': {
              height: 'calc(66vh - 8px)',
            },
            '@media only screen and (min-height: 1200px)': {
              height: 'calc(69vh - 8px)',
            },
            '@media only screen and (min-height: 1300px)': {
              height: 'calc(70vh - 8px)',
            },
            '@media only screen and (min-height: 1400px)': {
              height: 'calc(71vh - 8px)',
            },
            '@media only screen and (min-height: 1700px)': {
              height: 'calc(76vh - 8px)',
            },
            '@media only screen and (min-height: 2000px)': {
              height: 'calc(79vh - 8px)',
            },
          },
          '&.tableContainerConstantHeightMDSize': {
            height: 'calc(100% - 32px)',
            minHeight: '100px',
            marginBottom: '8px',
          },
          '&.tableContainerConstantHeightSSMsizeConstantLayoutPrimary': {
            minHeight: '181px',
            height: 'calc(100vh - 292px)',
          },
          '&.tableContainerConstantHeightSMsizeConstantLayoutPrimary': {
            minHeight: '181px',
            height: 'calc(100vh - 324px)',
          },
          '&.tableContainerConstantHeightXSsizeConstantLayoutPrimary': {
            minHeight: '181px',
            height: 'calc(100vh - 400px)',
          },
          '&.tableContainerConstantHeightXXXSsizeConstantLayoutPrimary': {
            minHeight: '181px',
            height: 'calc(100vh - 668px)',
          },
          '&.tableContainerConstantHeightXXXXSsizeConstantLayoutPrimary': {
            minHeight: '181px',
            height: 'calc(100vh - 720px)',
          },
          '&.tableContainerConstantHeightMDSizeConstantLayoutPrimary': {
            minHeight: '181px',
            height: 'calc(100vh - 181px)',
          },
          '&.tableContainerConstantHeightMDSizeConstantLayoutSecondary': {
            minHeight: '181px',
            height: 'calc(100% - 54px)',
          },
          '&.tableContainerConstantHeightMMDsizeConstantLayoutPrimary': {
            minHeight: '480px',
            height: 'calc(100vh - 150px)',
          },
          // large + 1 than middle size
          '&.tableContainerMMDSize': {
            height: 'calc(33vh - 8px)',
            marginBottom: '8px',
            '@media only screen and (min-height: 500px)': {
              height: 'calc(43vh - 8px)',
            },
            '@media only screen and (min-height: 550px)': {
              height: 'calc(45vh - 8px)',
            },
            '@media only screen and (min-height: 600px)': {
              height: 'calc(48vh - 8px)',
            },
            '@media only screen and (min-height: 650px)': {
              height: 'calc(51vh - 8px)',
            },
            '@media only screen and (min-height: 700px)': {
              height: 'calc(53vh - 8px)',
            },
            '@media only screen and (min-height: 750px)': {
              height: 'calc(56vh - 8px)',
            },
            '@media only screen and (min-height: 800px)': {
              height: 'calc(59vh - 8px)',
            },
            '@media only screen and (min-height: 850px)': {
              height: 'calc(63vh - 8px)',
            },
            '@media only screen and (min-height: 900px)': {
              height: 'calc(66vh - 8px)',
            },
            '@media only screen and (min-height: 1000px)': {
              height: 'calc(68vh - 8px)',
            },
            '@media only screen and (min-height: 1100px)': {
              height: 'calc(73vh - 8px)',
            },
            '@media only screen and (min-height: 1200px)': {
              height: 'calc(76vh - 8px)',
            },
            '@media only screen and (min-height: 1300px)': {
              height: 'calc(77vh - 8px)',
            },
            '@media only screen and (min-height: 1400px)': {
              height: 'calc(78vh - 8px)',
            },
            '@media only screen and (min-height: 1700px)': {
              height: 'calc(83vh - 8px)',
            },
            '@media only screen and (min-height: 2000px)': {
              height: 'calc(86vh - 8px)',
            },
          },
          // extra extra small
          '&.tableContainerXXSSize': {
            height: 'calc(11vh - 8px)',
            '@media only screen and (min-height: 500px)': {
              height: 'calc(23vh - 8px)',
            },
            '@media only screen and (min-height: 550px)': {
              height: 'calc(25vh - 8px)',
            },
            '@media only screen and (min-height: 600px)': {
              height: 'calc(28vh - 8px)',
            },
            '@media only screen and (min-height: 650px)': {
              height: 'calc(31vh - 8px)',
            },
            '@media only screen and (min-height: 700px)': {
              height: 'calc(33vh - 8px)',
            },
            '@media only screen and (min-height: 750px)': {
              height: 'calc(36vh - 8px)',
            },
            '@media only screen and (min-height: 800px)': {
              height: 'calc(39vh - 8px)',
            },
            '@media only screen and (min-height: 850px)': {
              height: 'calc(43vh - 8px)',
            },
            '@media only screen and (min-height: 900px)': {
              height: 'calc(46vh - 8px)',
            },
            '@media only screen and (min-height: 1000px)': {
              height: 'calc(49vh - 8px)',
            },
            '@media only screen and (min-height: 1100px)': {
              height: 'calc(53vh - 8px)',
            },
            '@media only screen and (min-height: 1200px)': {
              height: 'calc(56vh - 8px)',
            },
            '@media only screen and (min-height: 1300px)': {
              height: 'calc(57vh - 8px)',
            },
            '@media only screen and (min-height: 1400px)': {
              height: 'calc(58vh - 8px)',
            },
            '@media only screen and (min-height: 1700px)': {
              height: 'calc(63vh - 8px)',
            },
            '@media only screen and (min-height: 2000px)': {
              height: 'calc(68vh - 8px)',
            },
          },
          // extra small size for secondary
          '&.tableContainerXXXSSizeSecondary': {
            minHeight: '250px',
            height: 'calc(25vh - 8px)',
            marginBottom: '8px',
          },
          '&.tableContainerSMSizeSecondaryLayout': {
            minHeight: '240px',
            height: 'calc(100vh - 464px)',
            marginBottom: '8px',
          },
          '&.tableContainerSMSizeSecondaryLayoutV2': {
            minHeight: '240px',
            height: 'calc(100vh - 494px)',
            marginBottom: '8px',
          },
        },
      },
    },
    MuiTablePagination: {
      defaultProps: {
        rowsPerPage: 100,
        rowsPerPageOptions: [10, 20, 25, 50, 100],
      },
      styleOverrides: {
        root: {
          '&.primaryTablePagination': {
            backgroundColor: 'white',
            borderTop: '1px solid #E4E7EC',
            borderRadius: '0px 0px 8px 8px',
            '& button': {
              padding: '4px 6px',
              margin: '7px 0px',
              border: '2px solid #E4E7EC',
              borderRadius: '0px 8px 8px 0px',
            },
            '& button:nth-of-type(1)': {
              border: '2px solid #E4E7EC',
              borderRight: '0px',
              borderRadius: '8px 0px 0px 8px',
            },
            '& button.normalButtonTablePagination': {
              border: '0px',
              borderRadius: '8px',
              minWidth: '36px',
              minHeight: '36px',
              backgroundColor: 'rgba(222, 235, 255, 0.6)',
            },
            '& button.normalButtonTablePagination:hover': {
              boxShadow: defaultTheme.shadows[0],
              backgroundColor: 'rgba(222, 235, 255, 1)',
            },
            '& button:hover': {
              opacity: 1,
              boxShadow: defaultTheme.shadows[3],
              backgroundColor: 'rgba(239, 248, 255, 0.6)',
            },
            '& button>svg': {
              fill: '#175CD3',
            },
          },
          '&.secondaryTablePagination': {
            backgroundColor: '#F9FAFB',
            borderTop: '1px solid #E4E7EC',
            borderRadius: '0px 0px 8px 8px',
            '& button': {
              padding: '4px 6px',
              margin: '7px 0px',
              border: '2px solid #E4E7EC',
              borderRadius: '0px 8px 8px 0px',
            },
            '& button:nth-of-type(1)': {
              border: '2px solid #E4E7EC',
              borderRight: '0px',
              borderRadius: '8px 0px 0px 8px',
            },
            '& button.normalButtonTablePagination': {
              border: '0px',
              borderRadius: '8px',
              minWidth: '36px',
              minHeight: '36px',
              backgroundColor: 'rgba(222, 235, 255, 0.6)',
            },
            '& button.normalButtonTablePagination:hover': {
              boxShadow: defaultTheme.shadows[0],
              backgroundColor: 'rgba(222, 235, 255, 1)',
            },
            '& button:hover': {
              opacity: 1,
              boxShadow: defaultTheme.shadows[3],
              backgroundColor: 'rgba(239, 248, 255, 0.6)',
            },
            '& button>svg': {
              fill: '#175CD3',
            },
          },
          '&.MuiTablePagination-actions': {
            flexGrow: 1,
            alignItems: 'flex-end',
            display: 'inline-flex',
            justifyContent: 'flex-end',
          },
        },
        spacer: {
          flex: '0 0 0%',
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          '&.stickyTableFooterPrimary': {
            bottom: 0,
            position: 'sticky',
            height: '36px',
            borderBottom: '0px',
            backgroundColor: '#EFF8FF!important',
            '& td': {
              color: 'black',
              fontSize: '16px',
              fontWeight: '600',
            },
          },
          '&.stickyTableFooterSecondary': {
            position: 'sticky',
            insetBlockEnd: 0,
            '& p': {
              color: 'black',
            },
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          title1: 'p',
          subtitle3: 'p',
        },
      },
      styleOverrides: {
        root: {
          '&.limitWord': {
            wordBreak: 'break-word',
          },
          '&.errorParagraph': {
            color: 'red',
          },
          '&.leftAlign': {
            textAlign: 'left',
          },
          '&.diagonalStrikethrough': {
            position: 'relative',
            '&:before': {
              position: 'absolute',
              content: '""',
              left: '0',
              top: '50%',
              right: '0',
              borderTop: '1px solid',
              borderColor: 'inherit',
              webkitTransform: 'rotate(-5deg)',
              mozTransform: 'rotate(-5deg)',
              msTransform: 'rotate(-5deg)',
              oTransform: 'rotate(-5deg)',
              transform: 'rotate(-5deg)',
            },
          },
          '&.removeUnderline': {
            textDecoration: 'unset',
          },
          '&.minimumLinkStyle': {
            '&:hover': {
              cursor: 'pointer',
              textDecoration: 'underline',
            },
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '&.DrawerDashboard': {
            '& .MuiDrawer-paper': {
              borderRight: 'none',
              boxShadow:
                '1px 0px 6px rgba(9, 30, 66, 0.02), 1px 0px 6px linear-gradient(270deg, #091E42 -340.65%, rgba(9, 30, 66, 0.551037) -207.28%, rgba(9, 30, 66, 0.173565) -96.18%, rgba(9, 30, 66, 0.0001) 97.56%), 1px 0px 6px linear-gradient(270deg, #000000 -709.23%, rgba(0, 0, 0, 0.551037) -610.39%, rgba(0, 0, 0, 0.173565) -436.25%, rgba(0, 0, 0, 0.0001) 97.56%) ',
            },
          },
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          '&.infoStack': {
            padding: defaultTheme.spacing(1.5),
            direction: 'column',
            borderRadius: defaultTheme.spacing(1.2),
            backgroundColor: gray['B50'],
            border: '1.5px solid',
            borderColor: gray['B200'],
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          '&.stepLabelPrimary': {
            '& .MuiStepLabel-label': {
              marginTop: '5px',
            },
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          // color: '#344563',
          '&.svgIconPrimary': {
            color: '#175CD3',
          },
          '&.svgIconSecondary': {
            color: 'rgba(0, 0, 0, 0.6)',
          },
          '&.svgIconWhiteColor': {
            color: 'white',
          },
          '&.svgIconTersier': {
            fill: 'none',
            width: '48px',
            height: '48px',
            transform: 'scale(1.5,1.5)',
          },
          '&.svgIconTransparanceBackground': {
            color: 'rgba(0, 0, 0, 0)',
          },
          '&.svgIconAutoSizeAdjustment': {
            width: 'auto',
            height: 'auto',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            color: '#3F51B5',
            '& + .MuiSwitch-track': {
              opacity: 0.5,
              backgroundColor: '#3F51B5',
            },
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        disableInteractive: true,
      },
      styleOverrides: {
        tooltip: {
          borderRadius: '8px',
        },
        tooltipArrow: {
          color: 'white!important',
          backgroundColor: 'black!important',
          padding: '4px 12px',
          '& span': {
            color: 'black!important',
          },
        },
        tooltipPlacementBottom: {
          color: '#0052CC',
          // marginTop: '0px!important',
          backgroundColor: 'rgba(222, 235, 255, 0.6)',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '&.tableCellListData': {
            '& .MuiSvgIcon-root': {
              fontSize: '20px',
              color: 'rgba(0, 0, 0, 0.54)',
            },
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
          },
          '&.tableCellListDataFlexStart': {
            '& .MuiSvgIcon-root': {
              fontSize: '20px',
              color: 'rgba(0, 0, 0, 0.54)',
            },
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
          },
          '&.table-cell-calculation-number': {
            textAlign: 'right',
          },
          '&.additionalPaddingLeft3Spacing': {
            paddingLeft: '24px!important',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '&.primary': {
            padding: 0,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '&.appbarMenu': {
            '& .MuiPaper-root': {
              top: '58px!important',
            },
          },
          '&.menuLanguage': {
            '& .MuiPaper-root': {
              padding: '16px 8px',
              width: '199px',
              minHeight: '80px',
            },
          },
          '&.menuSwitch': {
            '& .MuiPaper-root': {
              padding: '16px 8px',
              width: '256px',
            },
          },
          '& .MuiList-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.menuItemLanguage': {
            padding: '8px 12px',
            borderRadius: '3px',
            '&:hover': {
              backgroundColor: '#DEEBFF',
              color: '#0052CC',
            },
          },
          '&.menuItemSwitch': {
            padding: '4px 16px',
            borderRadius: '3px',
            '&:hover': {
              backgroundColor: '#DEEBFF',
              color: '#0052CC',
            },
          },
          '&.menuItemUser': {
            marginTop: '8px',
            padding: '4px 16px',
            borderRadius: '3px',
            '&:hover': {
              backgroundColor: '#DEEBFF',
              color: '#0052CC',
            },
          },
          '&.menuItemUserNCompany': {
            padding: '4px 16px',
            borderRadius: '3px',
            '& .topMiniText': {
              fontSize: '11px',
            },
            '&:hover': {
              backgroundColor: '#DEEBFF',
              color: '#0052CC',
              '& .topMiniText': {
                color: '#4c9aff',
              },
            },
            width: '100%',
            '&:hover path': {
              fill: '#0052cc',
            },
          },
          '&.primary': {
            '&:hover': {
              background: blue[50],
              color: blue[900],
              borderRadius: '10px',
              '& svg': {
                fill: blue[900],
              },
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.dialogXXLHeightXXLWidthFullscreenOnSmallDevice': {
            width: '100vw',
            minHeight: '100vh',
            maxWidth: '1188px',
            [defaultTheme.breakpoints.up('sm')]: {
              minHeight: '92vh',
              width: '85vw',
            },
            [defaultTheme.breakpoints.up('xl')]: {
              minHeight: '85vh',
              width: '70vw',
            },
          },
          '&.dialogMDHeightXXLWidthFullscreenOnSmallDevice': {
            width: '100vw',
            minHeight: '100vh',
            maxWidth: '1188px',
            [defaultTheme.breakpoints.up('sm')]: {
              minHeight: '72vh',
              width: '85vw',
            },
            [defaultTheme.breakpoints.up('xl')]: {
              minHeight: '65vh',
              width: '70vw',
            },
          },
          '&.dialogXXLHeightXXLWidth': {
            width: '95vw',
            minHeight: '95vh',
            maxWidth: '1188px',
            [defaultTheme.breakpoints.up('sm')]: {
              minHeight: '92vh',
              width: '85vw',
            },
            [defaultTheme.breakpoints.up('xl')]: {
              minHeight: '85vh',
              width: '70vw',
            },
          },
          '&.dialogMDHeightLWidth': {
            width: '85vw',
            minHeight: '72.5vh',
            maxWidth: '1025px',
            justifyContent: 'center',
            [defaultTheme.breakpoints.up('sm')]: {
              minHeight: '65vh',
              width: '75vw',
            },
            [defaultTheme.breakpoints.up('xl')]: {
              minHeight: '60vh',
              width: '65vw',
            },
          },
          '&.dialogLHeightMDWidth': {
            width: '50vw',
            minHeight: '72.5vh',
            maxWidth: '1025px',
            justifyContent: 'center',
            [defaultTheme.breakpoints.up('sm')]: {
              minHeight: '70vh',
              width: '75vw',
            },
            [defaultTheme.breakpoints.up('xl')]: {
              minHeight: '65vh',
              width: '55vw',
            },
          },
          '&.dialogMDHeightMDWidth': {
            width: '50vw',
            minHeight: '72.5vh',
            maxWidth: '1025px',
            justifyContent: 'center',
            [defaultTheme.breakpoints.up('sm')]: {
              minHeight: '65vh',
              width: '65vw',
            },
            [defaultTheme.breakpoints.up('sm')]: {
              minHeight: '60vh',
              width: '60vw',
            },
            [defaultTheme.breakpoints.up('xl')]: {
              minHeight: '55vh',
              width: '55vw',
            },
          },
          '&.dialogXSHeightMDWidthUnsetOverflowY': {
            margin: 0,
            borderRadius: '12px',
            width: '100vw',
            minHeight: '156px',
            maxWidth: '600px',
            justifyContent: 'center',
            overflowY: 'unset',
            [defaultTheme.breakpoints.up('sm')]: {
              width: '42vw',
              minWidth: '586px',
              minHeight: '156px',
            },
            [defaultTheme.breakpoints.up('xl')]: {
              width: '35vw',
            },
          },
          '&.dialogXSHeightSSMWidthUnsetOverflowY': {
            margin: 0,
            borderRadius: '12px',
            width: '100vw',
            minHeight: '156px',
            maxWidth: '496px',
            justifyContent: 'center',
            overflowY: 'unset',
            [defaultTheme.breakpoints.up('sm')]: {
              width: '37vw',
              minWidth: '472px',
              minHeight: '156px',
            },
            [defaultTheme.breakpoints.up('xl')]: {
              width: '27vw',
            },
          },
          '&.paperPrimarySMheightLayoutPrimary': {
            width: '100%',
            minHeight: '60px',
            maxWidth: '-webkit-fill-available',
            padding: 16,
            borderRadius: 16,
          },
          '&.paperPrimaryMDheightLayoutPrimary': {
            width: '100%',
            minHeight: '120px',
            maxWidth: '-webkit-fill-available',
            padding: 16,
            borderRadius: 16,
          },
          '&.paperSecondarySMheightLayoutPrimary': {
            width: '100%',
            minHeight: '256px',
            height: 'calc(100vh - 356px)',
            maxHeight: '1080px',
            maxWidth: '-webkit-fill-available',
            padding: 16,
            borderRadius: 16,
          },
          '&.paperSecondaryMDheightLayoutPrimary': {
            width: '100%',
            minHeight: '256px',
            height: 'calc(100vh - 296px)',
            maxHeight: '1080px',
            maxWidth: '-webkit-fill-available',
            padding: 16,
            borderRadius: 16,
          },
          '&.paperPrimaryPrintTransactionProfessional': {
            width: '100%',
            boxShadow: 'unset',
            minHeight: 'calc(100vh - 208px)',
            margin: '32px 0px 0px 0px',
          },
          '&.paperMDheightFullWidthConstantLayoutPrimary': {
            width: '100%',
            minHeight: '200px',
            height: 'calc(100vh - 200px)',
            maxWidth: '-webkit-fill-available',
            borderRadius: 8,
            boxShadow: 'unset',
          },
          '&.paperPrimaryXXXLheightXXXLWidthLayoutSecondary': {
            width: '100%',
            maxWidth: '2000px',
            minHeight: '120px',
            borderRadius: 8,
          },
          '&.paperLGheightFullWidthConstantLayoutPrimary': {
            width: '100%',
            minHeight: '250px',
            height: 'calc(100vh - 185px)',
            maxWidth: '-webkit-fill-available',
            boxShadow: 'unset',
            padding: 16,
            borderRadius: 16,
          },
          '&.nonePadding': {
            padding: 0,
          },
          '&.paperMenuListPrimary': {
            borderRadius: '10px',
            overflow: 'hidden',
            height: '100%',
            boxShadow: 'unset',
          },
          '&.fullPaperLayoutPrimary': {
            padding: '8px',
            height: 'calc(100% - 16px)',
            width: 'calc(100% - 16px)',
            boxShadow: 'unset',
            borderRadius: '8px',
          },
          '&.fullPaperLayoutSecondary': {
            padding: '0px',
            height: '100%',
            width: '100%',
            boxShadow: 'unset',
            borderRadius: '8px',
          },
        },
      },
    },
  },
});
