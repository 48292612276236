import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  LOCAL_STORAGE_REGIONAL_CITY,
  LOCAL_STORAGE_REGIONAL_PROVINCE,
  LOCAL_STORAGE_REGIONAL_SUB_DISTRICT,
  LOCAL_STORAGE_REGIONAL_URBAN,
} from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_LIST_REGIONAL_CITY_FAILED,
  STATUS_REQUEST_LIST_REGIONAL_CITY_PENDING,
  STATUS_REQUEST_LIST_REGIONAL_CITY_SUCCESS,
  STATUS_REQUEST_LIST_REGIONAL_PROVINCE_FAILED,
  STATUS_REQUEST_LIST_REGIONAL_PROVINCE_PENDING,
  STATUS_REQUEST_LIST_REGIONAL_PROVINCE_SUCCESS,
  STATUS_REQUEST_LIST_REGIONAL_SUB_DISTRICT_FAILED,
  STATUS_REQUEST_LIST_REGIONAL_SUB_DISTRICT_PENDING,
  STATUS_REQUEST_LIST_REGIONAL_SUB_DISTRICT_SUCCESS,
  STATUS_REQUEST_LIST_REGIONAL_URBAN_FAILED,
  STATUS_REQUEST_LIST_REGIONAL_URBAN_PENDING,
  STATUS_REQUEST_LIST_REGIONAL_URBAN_SUCCESS,
} from '../../utils/constants/store.constant';
import arrHelp from '../../utils/helpers/array.helpers';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';
import strHelp from '../../utils/helpers/string.helpers';

import { getListCity, getListProvince, getListSubDistrict, getListUrban } from './regional.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  provinceData: LocalStorage.get(LOCAL_STORAGE_REGIONAL_PROVINCE),
  cityData: LocalStorage.get(LOCAL_STORAGE_REGIONAL_CITY),
  subDistrictData: LocalStorage.get(LOCAL_STORAGE_REGIONAL_SUB_DISTRICT),
  urbanData: LocalStorage.get(LOCAL_STORAGE_REGIONAL_URBAN),
};

const regionalSlice = createSlice({
  name: NAME_STORE.REGIONAL,
  initialState,
  reducers: {
    getListProvince,
    clearProvinceData: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_REGIONAL_PROVINCE);

      return {
        ...state,
        provinceData: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    clearCityData: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_REGIONAL_CITY);

      return {
        ...state,
        cityData: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    clearSubDistrictData: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_REGIONAL_SUB_DISTRICT);

      return {
        ...state,
        subDistrictData: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    clearUrbanData: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_REGIONAL_URBAN);

      return {
        ...state,
        urbanData: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      provinceData: LOCAL_STORAGE_REGIONAL_PROVINCE,
      cityData: LOCAL_STORAGE_REGIONAL_CITY,
      subDistrictData: LOCAL_STORAGE_REGIONAL_SUB_DISTRICT,
      urbanData: LOCAL_STORAGE_REGIONAL_URBAN,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getListProvince.fulfilled, (state, action) => {
      state.statusRequest = STATUS_REQUEST_LIST_REGIONAL_PROVINCE_SUCCESS;

      const { provinceData } = action.payload;

      if (provinceData) {
        const { data } = provinceData;

        if (data && Array.isArray(data)) {
          action.payload.provinceData.data = arrHelp.changeFormatValueInObj(
            action.payload.provinceData.data,
            'province_name',
            strHelp.capitalize,
          );
        }
      }

      state.provinceData = action.payload.provinceData;
    }),
      builder.addCase(getListProvince.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_REGIONAL_PROVINCE_FAILED;
      }),
      builder.addCase(getListProvince.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_REGIONAL_PROVINCE_PENDING;
      }),
      builder.addCase(getListCity.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_REGIONAL_CITY_SUCCESS;

        const { cityData } = action.payload;

        if (cityData) {
          const { data } = cityData;

          if (data && Array.isArray(data)) {
            action.payload.cityData.data = arrHelp.changeFormatValueInObj(
              action.payload.cityData.data,
              'city',
              strHelp.capitalize,
            );

            action.payload.cityData.data = arrHelp.reduceUniqueInObj(
              action.payload.cityData.data,
              'city',
            );
          }
        }

        state.cityData = action.payload.cityData;
      }),
      builder.addCase(getListCity.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_REGIONAL_CITY_FAILED;
      }),
      builder.addCase(getListCity.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_REGIONAL_CITY_PENDING;
      }),
      builder.addCase(getListSubDistrict.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_REGIONAL_SUB_DISTRICT_SUCCESS;

        const { subDistrictData } = action.payload;

        if (subDistrictData) {
          const { data } = subDistrictData;

          if (data && Array.isArray(data)) {
            action.payload.subDistrictData.data = arrHelp.changeFormatValueInObj(
              action.payload.subDistrictData.data,
              'sub_district',
              strHelp.capitalize,
            );

            action.payload.subDistrictData.data = arrHelp.reduceUniqueInObj(
              action.payload.subDistrictData.data,
              'sub_district',
            );
          }
        }

        state.subDistrictData = action.payload.subDistrictData;
      }),
      builder.addCase(getListSubDistrict.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_REGIONAL_SUB_DISTRICT_PENDING;
      }),
      builder.addCase(getListSubDistrict.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_REGIONAL_SUB_DISTRICT_FAILED;
      }),
      builder.addCase(getListUrban.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_REGIONAL_URBAN_SUCCESS;

        const { urbanData } = action.payload;

        if (urbanData) {
          const { data } = urbanData;

          if (data && Array.isArray(data)) {
            action.payload.urbanData.data = arrHelp.changeFormatValueInObj(
              action.payload.urbanData.data,
              'urban',
              strHelp.capitalize,
            );

            action.payload.urbanData.data = arrHelp.reduceUniqueInObj(
              action.payload.urbanData.data,
              'urban',
            );
          }
        }

        state.urbanData = action.payload.urbanData;
      }),
      builder.addCase(getListUrban.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_REGIONAL_URBAN_PENDING;
      }),
      builder.addCase(getListUrban.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_REGIONAL_URBAN_FAILED;
      });
  },
});

export const { actions, reducer } = regionalSlice;

export const { clearProvinceData, clearCityData, clearSubDistrictData, clearUrbanData } = actions;

export default reducer;
