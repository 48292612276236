import * as React from 'react';
function SvgCircleCheck(props) {
  return (
    <svg width={80} height={80} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M56.667 30L36.042 50l-9.375-9.09'
        stroke='#039855'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M40 73.333c18.41 0 33.333-14.923 33.333-33.333C73.333 21.59 58.41 6.667 40 6.667 21.59 6.667 6.667 21.59 6.667 40 6.667 58.41 21.59 73.333 40 73.333z'
        stroke='#039855'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
export default SvgCircleCheck;
