import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF,
  DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
  DEFAULT_KEY_NAME_DEBIT_NOTE_ID_API_REF,
  DEFAULT_KEY_NAME_ITEM_API_REF,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import arrHelp from '../../helpers/array.helpers';
import objHelper from '../../helpers/object.helper';
import parseResponseHelpers from '../../helpers/parse-response.helpers';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import { generalizeAttachmentFromDetails } from './initial-add-attachment';
import {
  generalizeDataAddPurchaseReceivablePayment,
  generateDataNvalidationAddDebitNotePurchaseReceivablePaymentFromDebitNoteList,
  generateDataNvalidationAddPurchaseReceivablePayment,
} from './initial-add-purchase-receivable-payment';
import {
  debitNotePurchaseReceivablePaymentCalculationNumberKeyNames,
  initialDataDebitNotePurchaseReceivablePayment,
  initialDataPurchaseReceivablePayment,
  initialValidationDebitNotePurchaseReceivablePayment,
  initialValidationPurchaseReceivablePayment,
} from './initial-data-purchase-receivable-payment';

/**
 *
 * @param   { object }  param0     object as params function
 * @param   { object }  purchaseReceivablePaymentDetails    data from purchase receivable payment details
 * @param   { object }  companyUserData                     current company user data for getting accounting period
 * @param   { object }  dataKeyname                         return of data keyname
 * @param   { object }  validationKeyname                   return of validation keyname
 *
 * @returns
 */
export const generateDataNvalidationUpdatePurchaseReceivablePayment = ({
  purchaseReceivablePaymentDetails,
  companyUserData,
  dataKeyname = 'purchaseReceivablePaymentData',
  validationKeyname = 'purchaseReceivablePaymentValidation',
}) => {
  if (isEmpty(purchaseReceivablePaymentDetails)) {
    return generateDataNvalidationAddPurchaseReceivablePayment({
      companyUserData,
      dataKeyname,
      validationKeyname,
    });
  }

  const accountingPeriod = !isEmpty(companyUserData)
    ? companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF]
    : null;

  purchaseReceivablePaymentDetails = objHelper.changeFormatValue(
    purchaseReceivablePaymentDetails,
    Object.keys(purchaseReceivablePaymentDetails),
    [(value) => value || ''],
  );

  const {
    supplier_id,
    supplier_code,
    supplier_name,
    transaction_no,
    account_receivable_id,
    account_receivable_name,
    account_receivable_code,
  } = purchaseReceivablePaymentDetails;

  const usagedID = arrHelp.generateArrWithFunc(1, uuidv4);

  const purchaseReceivablePaymentData = usagedID.map((id) => {
    return {
      ...initialDataPurchaseReceivablePayment(id),
      ...purchaseReceivablePaymentDetails,
      attachment: generalizeAttachmentFromDetails({
        dataDetails: purchaseReceivablePaymentData,
      }),
      supplier_id_container: {
        contact_id: supplier_id,
        contact_code: supplier_code,
        contact_name: supplier_name,
      },
      transaction_no_container: {
        value: transaction_no,
      },
      account_receivable: account_receivable_id,
      account_receivable_container: {
        account_id: account_receivable_id,
        account_name: account_receivable_name,
        account_code: account_receivable_code,
      },
      accountingPeriod,
      today: new Date(),
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
      ...parseResponseHelpers.tagContainerInputFromDetails(purchaseReceivablePaymentDetails),
    };
  });

  const purchaseReceivablePaymentValidation = usagedID.map((id) => {
    return initialValidationPurchaseReceivablePayment(id);
  });

  return {
    [dataKeyname]: purchaseReceivablePaymentData,
    [validationKeyname]: purchaseReceivablePaymentValidation,
  };
};

/**
 *
 * @param       { object }  param0      object as params function
 * @param       { object }  purchaseReceivablePaymentDetails    data from purchase receivable payment details
 * @param       { string }  dataKeyname                         return of data keyname
 * @param       { string }  validationKeyname                   return of validation keyname
 *
 * @returns
 */
export const generateDataNvalidationUpdateDebitNotePurchaseReceivablePayment = ({
  purchaseReceivablePaymentDetails,
  dataKeyname = 'purchaseReceivablePaymentData',
  validationKeyname = 'purchaseReceivablePaymentValidation',
}) => {
  const debitNotePurchaseReceivablePayment = isEmpty(purchaseReceivablePaymentDetails)
    ? []
    : purchaseReceivablePaymentDetails[DEFAULT_KEY_NAME_ITEM_API_REF];

  if (isEmpty(debitNotePurchaseReceivablePayment)) {
    return {
      [dataKeyname]: [],
      [validationKeyname]: [],
    };
  }

  const usagedID = arrHelp.generateArrWithFunc(debitNotePurchaseReceivablePayment.length, uuidv4);

  const debitNotePurchaseReceivablePaymentData = usagedID.map((id, index) => {
    const currentDebitNote = objHelper.changeFormatValue(
      debitNotePurchaseReceivablePayment[index],
      debitNotePurchaseReceivablePaymentCalculationNumberKeyNames,
      [(value) => Number(value) || ''],
    );

    const { amount_paid, debit_note_no, amount_available } = currentDebitNote;

    return {
      ...initialDataDebitNotePurchaseReceivablePayment(id),
      ...currentDebitNote,
      transaction_no: debit_note_no,
      amount_input: amount_paid,
      amount_available_const: Number(amount_available) + Number(amount_paid),
    };
  });

  const debitNotePurchaseReceivablePaymentValidation = usagedID.map((id) => {
    return initialValidationDebitNotePurchaseReceivablePayment(id);
  });

  return {
    [dataKeyname]: debitNotePurchaseReceivablePaymentData,
    [validationKeyname]: debitNotePurchaseReceivablePaymentValidation,
  };
};

/**
 *
 * @param       { object }  param0      object as params function
 * @param       { object }  debitNoteList                                   result from getting debit note list, that in future to combine with current
 * @param       { object }  currentDebitNotePurchasePaymentData             current data of debit note
 * @param       { object }  currentDebitNotePurchasePaymentValidation       current validation of debit note
 * @param       { string }  dataKeyname                                     return of data keyname
 * @param       { string }  validationKeyname                               return of validation keyname
 *
 * @returns
 */
export const generateDataNvalidationUpdateDebitNotePurchaseReceivablePaymentFromDebitNoteList = ({
  debitNoteList = [],
  currentDebitNotePurchasePaymentData = [],
  currentDebitNotePurchasePaymentValidation = [],
  dataKeyname = 'purchaseReceivablePaymentData',
  validationKeyname = 'purchaseReceivablePaymentValidation',
}) => {
  if (!Array.isArray(debitNoteList) || !Array.isArray(currentDebitNotePurchasePaymentData)) {
    return {
      [dataKeyname]: currentDebitNotePurchasePaymentData,
      [validationKeyname]: currentDebitNotePurchasePaymentValidation,
    };
  }

  const {
    debitNotePurchaseReceivablePaymentData: debitNotePurchaseReceivablePaymentDataGen,
    debitNotePurchaseReceivablePaymentValidation: debitNotePurchaseReceivablePaymentValidationGen,
  } = generateDataNvalidationAddDebitNotePurchaseReceivablePaymentFromDebitNoteList({
    debitNoteList,
    fillAmountInputFull: false,
  });

  const alreadyExistedDebitNoteID = new Set(
    currentDebitNotePurchasePaymentData.map(
      (debitNote) => debitNote[DEFAULT_KEY_NAME_DEBIT_NOTE_ID_API_REF],
    ),
  );

  const combineDebitNotePurchasePaymentDataWithCurrent = [
    ...currentDebitNotePurchasePaymentData,
    ...debitNotePurchaseReceivablePaymentDataGen.filter(
      (debitNote) =>
        !alreadyExistedDebitNoteID.has(debitNote[DEFAULT_KEY_NAME_DEBIT_NOTE_ID_API_REF]),
    ),
  ];

  const combineDebitNotePurchasePaymentValidationWithCurrent =
    arrHelp.combineMultipleDataObjectInArray(
      combineDebitNotePurchasePaymentDataWithCurrent,
      currentDebitNotePurchasePaymentValidation.concat(
        debitNotePurchaseReceivablePaymentValidationGen,
      ),
      'id',
      false,
    );

  return {
    [dataKeyname]: combineDebitNotePurchasePaymentDataWithCurrent,
    [validationKeyname]: combineDebitNotePurchasePaymentValidationWithCurrent,
  };
};

/**
 *
 * @param { array } purchaseReceivablePaymentData           purchase receivable payment data from user input
 * @param { array } debitNotePurchaseReceivablePayment      debit note purchase receivable payment data from user input
 * @returns
 *      generalize for data update purchase receivable payment
 */
export const generalizeDataUpdatePurchaseReceivablePayment = (
  purchaseReceivablePaymentData,
  debitNotePurchaseReceivablePayment,
) => {
  if (
    !Array.isArray(purchaseReceivablePaymentData) ||
    !Array.isArray(debitNotePurchaseReceivablePayment)
  ) {
    return {
      purchaseReceivablePaymentData,
      debitNotePurchaseReceivablePayment,
    };
  }

  const { receivable_payment_id } = purchaseReceivablePaymentData[0];

  return {
    ...generalizeDataAddPurchaseReceivablePayment(
      purchaseReceivablePaymentData,
      debitNotePurchaseReceivablePayment,
    ),
    receivable_payment_id,
  };
};
