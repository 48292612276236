import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { isEmpty, pick } from 'lodash';

import { gray } from '../../../assets/colors';
import withValueFormatter from '../../../hoc/withValueFormatter';
import formatHelp from '../../../utils/helpers/format.helpers';
import strHelp from '../../../utils/helpers/string.helpers';
import SpaceBarMain from '../../space-bar/SpaceBarMain/SpaceBarMain';

export const FORMAT_COMMON_COLUMN = 'common';
export const FORMAT_DATE_COLUMN = 'date';
export const FORMAT_CURRENCY_COLUMN = 'currency';
export const FORMAT_NUMBER_COLUMN = 'number';
export const FORMAT_CALCULATION_MANUAL_COLUMN = 'calculation-manual';
export const FORMAT_CURRENCY_AUTO_COMMA_COLUMN = 'currency-auto-comma';
export const FORMAT_CURRENCY_ON_BRACKET = 'currency-on-bracket';
export const FORMAT_TEMPLATE_COLUMN = 'template';
export const FORMAT_MAPPING_TRANSLATION_COLUMN = 'mapping-translation';

const primaryTableData = {
  textAlign: 'left',
  padding: '4px 8px',
  border: '1px solid white',
};

const primaryParagraph = {
  margin: 0,
  fontSize: '12px',
  fontFamily: 'MariupolReguler',
};

const primaryLabelParagraph = {
  ...primaryParagraph,
  fontWeight: 600,
  marginBottom: 8,
  color: '#6B778C',
};

const secondaryLabelParagraph = {
  ...primaryLabelParagraph,
  fontWeight: 500,
  marginBottom: 0,
};

/**
 * @prop    { array }       data            data that result from hit api
 * @prop    { array }       columnTableBody     column table body that represent from data, for getting data precisely
 *      each items on array minimum contain
 *          name, where use as looking for key data of value on data
 *
 *      additional, such as
 *          align                               { string }      where using for alignment text on table data
 *          style                               { object }      overriding style that existed
 *          isPrimaryParagraph                  { boolean }     decession for change style on paragraph to secondary paragraph
 *          styleParagraph                      { object }      change and override paragraph
 *          formatData                          { string }      auto formattion data on table data, exist, common, currency, and date
 *          formatterData                       { function }    function handling change format date manually
 *          paramsFormatterCalculationManual    { array }       array that for picking data on each datum per row, to active you can use format data 'calculation-manual' and make use passing formatter data
 *          isUseZeroNumberWhenNull             { bool }        subtitue to zero number when table data is null
 *          isUseFormatStyleOnRow               { bool }        adding on formatting each table row
 *          substituteNotExistValue             { string }      change on subtitute when data not exist or null
 *
 *      each row when using formattion style, you need passing this each row when using formatting style on row, or the style can override by default or from column header
 *          style                               { object }      styling on table data each row
 *          styleParagraph                      { object }      styling paragraph on table data each row
 *          tableDataBodyStyle                  { object }      styling for table data on body
 *
 *      additional
 *          useMultipleNames                    { boolean }     determine for usaging multiple names
 *          multipleConfigs                     { array }       use for multiple names when true, each items contained object that same as additional on above
 */
class TableRowPureMarkup extends Component {
  generateRepresentationData(datumTarget, singleColumnTarget, identity = '') {
    const { t, withValueFormatterMethod } = this.props;

    const {
      name: columnName,
      template,
      isPrimaryParagraph = true,
      styleParagraph: styleParagraphTableData,
      formatData,
      formatterData,
      isUseZeroNumberWhenNull = false,
      isUseFormatStyleOnRow = false,
      substituteNotExistValue = '',
      useRenderingEachRow = false,
      paramsFormatterCalculationManual = [],
      mappingTranslation = {},
      keynamesExistedValue = [],
    } = singleColumnTarget;

    const { useSpaceBar = false, spaceBarAmount = 0 } = datumTarget;

    let currValue = datumTarget[columnName],
      styleParagraph = isPrimaryParagraph ? primaryParagraph : secondaryLabelParagraph,
      additionalPropsTableData = {};

    if (formatData === FORMAT_TEMPLATE_COLUMN && template) {
      currValue = strHelp.templateString(template, datumTarget);
    }

    if (!isEmpty(styleParagraphTableData)) {
      styleParagraph = {
        ...styleParagraph,
        ...styleParagraphTableData,
      };
    }

    if (isUseFormatStyleOnRow) {
      const { styleParagraph: styleParagraphRowTableData } = datumTarget;

      styleParagraph = !isEmpty(styleParagraphRowTableData)
        ? styleParagraphRowTableData
        : styleParagraph;

      if (!isEmpty(additionalPropsTableData)) {
        const { colSpan } = additionalPropsTableData;

        if (!colSpan) return null;
      }
    }

    if (
      useRenderingEachRow &&
      datumTarget.renderElement !== undefined &&
      typeof datumTarget.renderElement === 'function'
    ) {
      return datumTarget.renderElement(datumTarget);
    }

    if (formatData === FORMAT_CURRENCY_COLUMN) {
      currValue = formatHelp.currencyFormatWithRegex(currValue);
    }

    if (formatData === FORMAT_CURRENCY_AUTO_COMMA_COLUMN) {
      if (!Number.isNaN(Number(currValue)) && currValue !== '' && currValue !== null) {
        currValue = formatHelp.currencyWithAutoComma(Number(currValue));
      }
    }

    if (formatData === FORMAT_DATE_COLUMN) {
      currValue = formatHelp.getReadableDateV2(currValue);
    }

    if (formatData === FORMAT_NUMBER_COLUMN) {
      if (!Number.isNaN(Number(currValue))) currValue = Number(currValue);

      if (Number.isNaN(currValue)) currValue = datumTarget[columnName];
    }

    if (
      formatData === FORMAT_CALCULATION_MANUAL_COLUMN &&
      !isEmpty(paramsFormatterCalculationManual) &&
      Array.isArray(paramsFormatterCalculationManual)
    ) {
      const selectedDataFromDatum = pick(datumTarget, paramsFormatterCalculationManual);

      currValue = formatterData(selectedDataFromDatum);
    }

    if (typeof formatterData === 'function' && formatData !== FORMAT_CALCULATION_MANUAL_COLUMN) {
      currValue = formatterData(currValue);
    }

    if (formatData === FORMAT_MAPPING_TRANSLATION_COLUMN && !isEmpty(mappingTranslation)) {
      currValue = mappingTranslation[currValue] ? t(mappingTranslation[currValue]) : currValue;
    }

    if (formatData === FORMAT_CURRENCY_ON_BRACKET) {
      currValue = formatHelp.currencyWithRegexOnBrackets(Number(currValue), true);
    }

    if (withValueFormatterMethod.canFormatted(formatData)) {
      currValue = withValueFormatterMethod.valueFormatterV1({
        format: formatData,
        value: currValue,
        containerValue: datumTarget,
        emptyValueSubtitute: substituteNotExistValue,
        mappingTranslation,
        keynamesExistedValue,
      });
    }

    return (
      <p
        key={identity}
        style={{
          wordBreak: 'break-word',
          ...styleParagraph,
        }}
      >
        {useSpaceBar ? <SpaceBarMain amountSpace={spaceBarAmount} /> : null}

        {isUseZeroNumberWhenNull ? currValue || '0' : currValue || substituteNotExistValue}
      </p>
    );
  }

  renderTableRow(data, columnTableBody) {
    return data.map((datum, index) => {
      return (
        <tr key={`table-row-gen-${index}`}>
          {Array.isArray(columnTableBody) &&
            columnTableBody.length &&
            columnTableBody.map((singleColumnTableBody, indexColumnTable) => {
              const {
                name: columnName,
                align = 'left',
                style: styleTableData,
                tableDataBodyStyle,
                isPrimaryParagraph = true,
                isUseFormatStyleOnRow = false,
                isAutoIncrementColumn = false,
                useMultipleNames = false,
                multipleConfigs = [],
                useContainerMultipleConfigs = false,
                containerMultipleConfigs = {},
              } = singleColumnTableBody;

              let currValue = datum[columnName],
                style = styleTableData,
                styleParagraph = isPrimaryParagraph ? primaryParagraph : secondaryLabelParagraph,
                additionalPropsTableData = {},
                additionalStyleTableData = {}; // this styling come from each row

              if (isAutoIncrementColumn) {
                const currentOrderNumber = index + 1;
                currValue = strHelp.templateString('{0}.', [currentOrderNumber]);

                return (
                  <td
                    key={`table-data-${indexColumnTable}`}
                    style={{
                      ...primaryTableData,
                      textAlign: align,
                      ...style,
                    }}
                  >
                    <p
                      style={{
                        ...styleParagraph,
                        wordBreak: 'break-word',
                      }}
                    >
                      {currValue}
                    </p>
                  </td>
                );
              }

              // changes style from table row data
              if (isUseFormatStyleOnRow) {
                const {
                  style: styleRowTableData,
                  additionalPropsTableDataBasedIndexColumn = [],
                  additionalStyleTableDataBasedIndexColumn = [],
                } = datum;

                style = !isEmpty(styleRowTableData)
                  ? {
                      ...style,
                      ...styleRowTableData,
                    }
                  : style;

                additionalPropsTableData =
                  !isEmpty(additionalPropsTableDataBasedIndexColumn) &&
                  Array.isArray(additionalPropsTableDataBasedIndexColumn)
                    ? additionalPropsTableDataBasedIndexColumn[indexColumnTable]
                    : {};
                additionalStyleTableData = !isEmpty(additionalStyleTableDataBasedIndexColumn)
                  ? additionalStyleTableDataBasedIndexColumn[indexColumnTable]
                  : {};

                if (!isEmpty(additionalPropsTableData)) {
                  const { colSpan } = additionalPropsTableData;

                  if (!colSpan) return null;
                }
              }

              let representationData = this.generateRepresentationData(
                datum,
                singleColumnTableBody,
              );

              if (useMultipleNames && Array.isArray(multipleConfigs) && !isEmpty(multipleConfigs)) {
                representationData = multipleConfigs.map((singleConfig, index) => {
                  return this.generateRepresentationData(
                    datum,
                    singleConfig,
                    `paragraph-${singleConfig.name}-${index}`,
                  );
                });

                if (useContainerMultipleConfigs) {
                  representationData = (
                    <div {...containerMultipleConfigs}>{representationData}</div>
                  );
                }
              }

              return (
                <td
                  key={`table-data-${indexColumnTable}`}
                  style={{
                    ...primaryTableData,
                    textAlign: align,
                    ...style,
                    ...tableDataBodyStyle,
                    ...additionalStyleTableData,
                  }}
                  {...additionalPropsTableData}
                >
                  {representationData}
                </td>
              );
            })}
        </tr>
      );
    });
  }

  render() {
    const { data, columnTableBody, isTableFooter = false, tableFootStyle = {} } = this.props;

    if (isEmpty(data) || !Array.isArray(data)) return null;

    if (isTableFooter) {
      return (
        <tfoot
          style={{
            position: 'sticky',
            insetBlockEnd: 0,
            background: gray['B200'],
            ...tableFootStyle,
          }}
        >
          {this.renderTableRow(data, columnTableBody)}
        </tfoot>
      );
    }

    return this.renderTableRow(data, columnTableBody);
  }
}

const TableRowPureMarkupWithTranslation = withTranslation()(TableRowPureMarkup);

const TableRowPureMarkupWithValueFormatter = withValueFormatter(TableRowPureMarkupWithTranslation);

export default TableRowPureMarkupWithValueFormatter;
