import { createSlice } from '@reduxjs/toolkit';
import { isEqual, omit } from 'lodash';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_ROOT_APPLICATION } from '../../utils/constants/storage.constant';
import {
  ACTION_STATUS_BASE_IDDLE,
  ACTION_STATUS_HISTORY_CHANGES_HISTORY_SUCCESS,
  ACTION_STATUS_SWITCH_APPLICATION_SUCCESS,
  NAME_STORE,
  SLICE_NAME_APPLICATION_CONFIGURATION,
  SLICE_NAME_APPLICATION_TYPE,
} from '../../utils/constants/store.constant';
import {
  appConfiguration,
  DEFAULT_APPLICATION_TYPE_AUTH,
  DEFAULT_APPLICATION_TYPE_PROFESSIONAL,
} from '../../utils/default/application-configs.default';
import { DEFAULT_KEY_NAME_HISTORY_PATHNAME_PREVIOUS } from '../../utils/default/object-keyname.default';

function initializeState() {
  const currentApplicationLocalStorage = LocalStorage.get(LOCAL_STORAGE_ROOT_APPLICATION);

  let currentApplicationType = null,
    currentApplicationConfiguration = null;

  if (currentApplicationLocalStorage) {
    currentApplicationType = currentApplicationLocalStorage[SLICE_NAME_APPLICATION_TYPE];
    currentApplicationConfiguration =
      currentApplicationLocalStorage[SLICE_NAME_APPLICATION_CONFIGURATION];
  }

  if (
    currentApplicationConfiguration &&
    currentApplicationType &&
    !isEqual(currentApplicationConfiguration, appConfiguration[currentApplicationType])
  ) {
    currentApplicationConfiguration = appConfiguration[currentApplicationType];
  }

  return {
    actionStatus: ACTION_STATUS_BASE_IDDLE,
    [SLICE_NAME_APPLICATION_TYPE]: currentApplicationType || DEFAULT_APPLICATION_TYPE_AUTH,
    [SLICE_NAME_APPLICATION_CONFIGURATION]:
      currentApplicationConfiguration || appConfiguration[DEFAULT_APPLICATION_TYPE_AUTH],
  };
}

const applicationSlice = createSlice({
  name: NAME_STORE.APPLICATIION,
  initialState: initializeState(),
  reducers: {
    switchApplication: (state, action) => {
      const applicationType =
        action.payload[SLICE_NAME_APPLICATION_TYPE] || DEFAULT_APPLICATION_TYPE_PROFESSIONAL;

      if (applicationType === state[SLICE_NAME_APPLICATION_TYPE]) return;

      const changesApplicationState = {
        [SLICE_NAME_APPLICATION_TYPE]: applicationType,
        [SLICE_NAME_APPLICATION_CONFIGURATION]: appConfiguration[applicationType],
      };

      LocalStorage.set(LOCAL_STORAGE_ROOT_APPLICATION, changesApplicationState);

      return {
        ...state,
        ...changesApplicationState,
        actionStatus: ACTION_STATUS_SWITCH_APPLICATION_SUCCESS,
      };
    },
    // payload params { applicationType: '', historyPathnamePrevious: [], }
    historyPathnameChanges: (state, action) => {
      const applicationType =
        action.payload[SLICE_NAME_APPLICATION_TYPE] || DEFAULT_APPLICATION_TYPE_PROFESSIONAL;

      let changesHistoryPathname = {
        [SLICE_NAME_APPLICATION_TYPE]: applicationType,
        [SLICE_NAME_APPLICATION_CONFIGURATION]: {
          ...state[SLICE_NAME_APPLICATION_CONFIGURATION],
          [DEFAULT_KEY_NAME_HISTORY_PATHNAME_PREVIOUS]:
            action.payload[DEFAULT_KEY_NAME_HISTORY_PATHNAME_PREVIOUS] || [],
        },
      };

      LocalStorage.set(LOCAL_STORAGE_ROOT_APPLICATION, changesHistoryPathname);

      changesHistoryPathname = omit(changesHistoryPathname, [SLICE_NAME_APPLICATION_TYPE]);

      return {
        ...state,
        ...changesHistoryPathname,
        actionStatus: ACTION_STATUS_HISTORY_CHANGES_HISTORY_SUCCESS,
      };
    },
  },
});

export const { reducer, actions } = applicationSlice;

export const { switchApplication, historyPathnameChanges } = actions;

export default reducer;
