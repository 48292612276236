import * as React from 'react';
function SvgIconPdfFileType(props) {
  return (
    <svg width={40} height={40} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20z'
        fill='#D1E9FF'
      />
      <path
        d='M23.762 25.286h-1.684l-1.67-3.165.87-1.505a1.475 1.475 0 10-2.555 0l.87 1.505-1.671 3.165h-1.684a1.465 1.465 0 101.273 2.186L18.345 26h3.31l.834 1.472a1.464 1.464 0 101.273-2.186zM16.89 27.12a.75.75 0 11-.652-1.12h1.285l-.633 1.12zm2.451-6.861a.76.76 0 111.318 0L20 21.399l-.659-1.14zm-.612 5.027L20 22.879l1.27 2.407h-2.54zm5.686 1.834a.75.75 0 01-1.305 0L22.476 26h1.285a.75.75 0 01.653 1.12h.001zm-9.594-15.691h-1.25a.357.357 0 00-.357.357v3.571a.357.357 0 10.715 0V13.93h.892a1.25 1.25 0 100-2.5zm0 1.785h-.892v-1.071h.892a.535.535 0 110 1.071zm3.036-1.785h-.714a.357.357 0 00-.357.357v3.571a.357.357 0 00.357.357h.714a1.788 1.788 0 001.786-1.785v-.715a1.788 1.788 0 00-1.786-1.785zm1.072 2.5A1.072 1.072 0 0117.857 15H17.5v-2.857h.357a1.072 1.072 0 011.072 1.071v.715zm3.928-2.5h-2.143a.357.357 0 00-.357.357v3.571a.357.357 0 00.714 0V13.93H22.5a.357.357 0 100-.715h-1.429v-1.071h1.786a.358.358 0 000-.714zM28.214 23.571a.357.357 0 100-.714.357.357 0 000 .714z'
        fill='#1570EF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.786 10a.357.357 0 00-.357.357v19.286a.357.357 0 00.357.357h16.428a.357.357 0 00.357-.357v-5a.357.357 0 10-.714 0v4.643H12.143V17.143h15.714v4.643a.358.358 0 00.714 0v-7.857a.357.357 0 00-.104-.253l-3.572-3.571a.357.357 0 00-.252-.105H11.786zm12.5.714H12.143v5.715h15.714v-2.143h-2.5a1.072 1.072 0 01-1.071-1.072v-2.5zm.714.505v1.995a.357.357 0 00.357.357h1.995L25 11.22z'
        fill='#1570EF'
      />
    </svg>
  );
}
export default SvgIconPdfFileType;
