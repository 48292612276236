import { createAsyncThunk } from '@reduxjs/toolkit';

import indoRegionalServices from '../../services/API/indo-regional/indo-regional.service';
import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { LOCAL_STORAGE_INDO_REGIONAL_DATA } from '../../utils/constants/storage.constant';

// listing indo regional list
export const indoRegionalList = createAsyncThunk(
  'indoRegional/list',
  async (dataIndoRegionalList, thunkAPI) => {
    const { isRewriteAll } = dataIndoRegionalList;
    // console.log( dataIndoRegionalList )
    try {
      const response = await indoRegionalServices.regionalList(dataIndoRegionalList);

      // console.log( response )

      if (!response) {
        return thunkAPI.rejectWithValue(response.data);
      }

      let { data: regionalData, sucess } = response.data;

      if (!sucess || !Array.isArray(regionalData.data)) {
        regionalData = {
          ...regionalData,
          data: [],
        };
      }

      const currentIndoRegional = LocalStorage.get(LOCAL_STORAGE_INDO_REGIONAL_DATA);

      if (!currentIndoRegional || isRewriteAll) {
        // set into local storge
        LocalStorage.set(LOCAL_STORAGE_INDO_REGIONAL_DATA, regionalData);

        return { regionalData };
      }

      // combine data from previous
      const newListDataRegional = currentIndoRegional.data.concat(regionalData.data);

      // adding data from previous
      const newDataRegional = Object.assign(currentIndoRegional, regionalData, {
        data: newListDataRegional,
      });

      LocalStorage.set(LOCAL_STORAGE_INDO_REGIONAL_DATA, newDataRegional);

      return { regionalData: newDataRegional };
    } catch (error) {
      if (!error.response) {
        return thunkAPI.rejectWithValue(error.respnse);
      }

      const { data } = error.response;

      return thunkAPI.rejectWithValue(data);
    }
  },
);
