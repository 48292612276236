import * as React from 'react';
function SvgWarningTriangle(props) {
  return (
    <svg width={72} height={63} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M36 21.667V35m0 13.333h.032M30.3 4.533L2.066 51.667a6.667 6.667 0 005.7 10h56.466a6.667 6.667 0 005.7-10L41.7 4.533a6.666 6.666 0 00-11.4 0z'
        stroke='#DC6803'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
export default SvgWarningTriangle;
