import { createAsyncThunk } from '@reduxjs/toolkit';

import managementRolesService from '../../services/API/management-role/management-role';
import { LOCAL_STORAGE_MANAGEMENT_ROLE } from '../../utils/constants/storage.constant';
import { SLICE_NAME_MANAGEMENT_ROLE_DATA } from '../../utils/constants/store.constant';
import { creatorListAction } from '../creator-action/creator-action.helper';

// list all role in company
export const listRoles = createAsyncThunk('management-role/list', async (params, thunkAPI) => {
  return await creatorListAction(
    thunkAPI,
    {
      ...params,
      isShowMessage: false,
    },
    managementRolesService.listRoles,
    LOCAL_STORAGE_MANAGEMENT_ROLE,
    SLICE_NAME_MANAGEMENT_ROLE_DATA,
    'role_id',
  );
});
