import { storageConstants } from '../../../utils/constants/storage.constant';
import { decryptData, encryptData } from '../Crypto/Crypto.service';

// checking all storage that required for store
// when user is illegally input data for storage,
// localStorage immediatelly remove all data
function checkingValidationStorage() {
  const encryptedObjectKeys = Object.keys(localStorage);

  let isValidStorage = true;

  if (encryptedObjectKeys.length) {
    for (let encObjectKey of encryptedObjectKeys) {
      let decryptObjKey = decryptData(encObjectKey);

      // changing valid storage, when key is not include in constant
      isValidStorage = isValidStorage && storageConstants.LOCAL_STORAGE_KEY.includes(decryptObjKey);

      if (isValidStorage) {
        continue;
      }

      // clearing all data when there is suspicious key in localStorage
      clear();
      break;
    }
  }

  const encryptedObjectValue = Object.values(localStorage);

  if (encryptedObjectValue.length) {
    for (let encObjectValue of encryptedObjectValue) {
      let decryptObjValue = !!decryptData(encObjectValue);

      // changing valid storage, when key is not include in constant
      isValidStorage = isValidStorage && decryptObjValue;

      if (isValidStorage) {
        continue;
      }

      // clearing all data when there is suspicious key in localStorage
      clear();
      break;
    }
  }

  return isValidStorage;
}

// checking all encryption key
// return match encrypted key on localStorage with encytpted original key
function checkEncryptedKey(originalKey) {
  let encryptedKey = null;

  const encryptedObjectKeys = Object.keys(localStorage);

  // checking through all keys in localStorage
  for (let encObjectKey of encryptedObjectKeys) {
    let decryptObjKey = decryptData(encObjectKey);

    if (decryptObjKey !== originalKey) {
      continue;
    }

    // set decrypt key of encrypted key in localStorage
    encryptedKey = encObjectKey;
    break;
  }

  return encryptedKey;
}

// get item thats pair with key item
function get(key) {
  // decrypt key for pairing in localstorage
  // get encrypted key in localstorage
  let encryptedKey = checkEncryptedKey(key);

  // get encrypted data using encrypted key
  let encryptedValue = localStorage.getItem(encryptedKey);

  // sometimes we cannot get localStorage, because its null
  if (!encryptedValue) {
    return encryptedValue;
  }

  // differentiate about object in localstorage, but this is useless
  if (encryptedValue.startsWith('{') && encryptedValue.endsWith('}')) {
    encryptedValue = JSON.parse(localStorage.getItem(encryptedKey));
  }

  const decryptedData = decryptData(encryptedValue);

  // removing all data when is not provide data
  // to prevent set data from console browser
  if (!decryptedData) {
    setTimeout(() => {
      if (window.location) {
        window.location.reload();
      }
    }, 10000);

    clear();
  }

  // returning decrypt value
  return decryptedData;
}

// set item localStorage with key item
function set(key, value) {
  let encryptingValue = null;

  // checking encrypted key has not existed or not
  let encryptingKey = checkEncryptedKey(key);

  // when existed create new key in localStorage
  if (!encryptingKey) {
    encryptingKey = encryptData(key);
  }

  // encrypt value before storing
  // changing value into string
  if (typeof value === 'string') {
    encryptingValue = encryptData(value);
  } else {
    encryptingValue = encryptData(JSON.stringify(value));
  }

  localStorage.setItem(encryptingKey, encryptingValue);
}

// remove single value in pairing with key item
function remove(key) {
  // encrypt key before accessing original key in localStorage
  let encryptedKey = checkEncryptedKey(key);

  localStorage.removeItem(encryptedKey);
}

// clearing all data in localStorage
function clear() {
  localStorage.clear();
}

const LocalStorage = {
  get,
  set,
  remove,
  clear,
  checkingValidationStorage,
};

export default LocalStorage;
