import * as React from 'react';
function SvgAssetDataMenu(props) {
  return (
    <svg width={95} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#asset-data-menu_svg__filter0_d_5537_188948)'>
        <path
          d='M55.498 55.094c0-9.518 7.238-21.543 16.168-21.543 8.929 0 16.168 12.025 16.168 21.543 0 9.518-7.24 12.925-16.168 12.925-8.93 0-16.168-3.407-16.168-12.925z'
          fill='#324A5E'
        />
        <path
          d='M71.665 33.552l-.067.001V68.02h.067c8.93 0 16.168-3.407 16.168-12.925s-7.238-21.542-16.168-21.542z'
          fill='#2B3B4E'
        />
        <path
          d='M77.022 26.793c0 3.754-2.398 6.796-5.356 6.796-2.958 0-5.356-3.042-5.356-6.796h10.712z'
          fill='#324A5E'
        />
        <path
          d='M71.598 26.793v6.794l.067.002c2.958 0 5.356-3.042 5.356-6.796h-5.423z'
          fill='#2B3B4E'
        />
        <path
          d='M71.665 58.008c2.842 0 5.145-2.455 5.145-5.484 0-3.028-2.303-5.483-5.145-5.483-2.84 0-5.144 2.455-5.144 5.483 0 3.029 2.303 5.484 5.144 5.484z'
          fill='#FFD15D'
        />
        <path
          d='M71.665 47.04l-.067.003v10.963l.067.002c2.842 0 5.145-2.455 5.145-5.484s-2.303-5.483-5.145-5.483z'
          fill='#F9B54C'
        />
        <path
          d='M71.665 59.3c-3.505 0-6.356-3.039-6.356-6.776 0-3.736 2.851-6.776 6.356-6.776 3.506 0 6.357 3.04 6.357 6.776 0 3.737-2.851 6.777-6.357 6.777zm0-10.967c-2.167 0-3.931 1.88-3.931 4.191s1.764 4.192 3.931 4.192c2.168 0 3.932-1.88 3.932-4.192 0-2.31-1.764-4.19-3.931-4.19z'
          fill='#F9B54C'
        />
        <path
          d='M70.701 45.552l-.067.001v2.587c.022 0 .044-.003.067-.003 2.168 0 3.932 1.88 3.932 4.19 0 2.312-1.764 4.192-3.932 4.192-.022 0-.045-.003-.067-.004v2.587l.067.002c3.505 0 6.357-3.04 6.357-6.776 0-3.737-2.852-6.776-6.357-6.776z'
          fill='#F4A200'
        />
        <path d='M75.707 31.7h-8.083v2.584h8.084V31.7z' fill='#F9B54C' />
        <path d='M75.708 31.7H71.6v2.584h4.11V31.7z' fill='#F4A200' />
        <path
          d='M64.377 65.206H33.364V10.66c0-2.111 1.515-3.823 3.385-3.823h24.243c1.87 0 3.386 1.711 3.386 3.823v54.546z'
          fill='#273B7A'
        />
        <path
          d='M64.377 10.66c0-2.112-1.515-3.823-3.385-3.823H48.584v58.37h15.793V10.66z'
          fill='#121149'
        />
        <path
          d='M60.242 13.841H37.5v6.42h22.743v-6.42zM60.242 22.402H37.5v6.42h22.743v-6.42zM60.242 39.524H37.5v6.42h22.743v-6.42zM60.242 30.963H37.5v6.42h22.743v-6.42z'
          fill='#C8EBED'
        />
        <path d='M60.243 13.841H48.584v6.42h11.66v-6.42z' fill='#71E2EF' />
        <path d='M48.585 22.402H37.499v6.42h11.086v-6.42z' fill='#C8EBED' />
        <path
          d='M60.243 22.402H48.584v6.42h11.66v-6.42zM60.243 39.524H48.584v6.42h11.66v-6.42z'
          fill='#71E2EF'
        />
        <path
          d='M48.585 39.524H37.499v6.42h11.086v-6.42zM48.585 30.963H37.499v6.42h11.086v-6.42z'
          fill='#C8EBED'
        />
        <path d='M60.243 30.963H48.584v6.42h11.66v-6.42z' fill='#71E2EF' />
        <path
          d='M28.195 65.206H7.519V4.274C7.519 2.466 8.817 1 10.418 1h14.878c1.6 0 2.899 1.466 2.899 3.274v60.932z'
          fill='#273B7A'
        />
        <path
          d='M28.195 4.273C28.195 2.466 26.897 1 25.296 1h-7.554v64.206h10.453V4.273z'
          fill='#121149'
        />
        <path
          d='M49.039 65.206H26.915V22.263c0-1.58 1.134-2.86 2.532-2.86h17.06c1.398 0 2.532 1.28 2.532 2.86v42.943z'
          fill='#FFC61B'
        />
        <path
          d='M49.038 22.263c0-1.58-1.133-2.86-2.532-2.86h-8.95v45.803H49.04V22.263z'
          fill='#EAA22F'
        />
        <path d='M35.78 23.278h-5.686v6.42h5.685v-6.42z' fill='#FF5419' />
        <path d='M45.859 23.278h-5.686v6.42h5.686v-6.42z' fill='#C92F00' />
        <path d='M35.78 32.811h-5.686v6.42h5.685v-6.42z' fill='#FF5419' />
        <path d='M45.859 32.811h-5.686v6.42h5.686v-6.42z' fill='#C92F00' />
        <path d='M35.78 42.345h-5.686v6.42h5.685v-6.42z' fill='#FF5419' />
        <path d='M45.859 42.345h-5.686v6.42h5.686v-6.42z' fill='#C92F00' />
        <path d='M16.232 5.327H9.95v4.78h6.283v-4.78z' fill='#FEE187' />
        <path d='M25.763 5.327H19.48v4.78h6.283v-4.78z' fill='#FFC61B' />
        <path d='M16.232 12.437H9.95v4.78h6.283v-4.78z' fill='#FEE187' />
        <path d='M25.763 12.437H19.48v4.78h6.283v-4.78z' fill='#FFC61B' />
        <path d='M16.232 19.546H9.95v4.78h6.283v-4.78z' fill='#FEE187' />
        <path d='M25.763 19.546H19.48v4.78h6.283v-4.78z' fill='#FFC61B' />
        <path d='M16.232 26.655H9.95v4.78h6.283v-4.78z' fill='#FEE187' />
        <path d='M25.763 26.655H19.48v4.78h6.283v-4.78z' fill='#FFC61B' />
        <path d='M16.232 33.769H9.95v4.78h6.283v-4.78z' fill='#FEE187' />
        <path d='M25.763 33.769H19.48v4.78h6.283v-4.78z' fill='#FFC61B' />
        <path d='M16.232 40.878H9.95v4.78h6.283v-4.78z' fill='#FEE187' />
        <path d='M25.763 40.878H19.48v4.78h6.283v-4.78z' fill='#FFC61B' />
        <path d='M43.664 52.365H32.292v12.841h11.372V52.365z' fill='#C92F00' />
        <path d='M43.663 52.365h-6.105v12.841h6.105V52.365z' fill='#930000' />
        <path d='M22.337 52.365h-8.96v12.841h8.96V52.365z' fill='#FFC61B' />
        <path d='M22.338 52.365h-4.595v12.841h4.595V52.365z' fill='#EAA22F' />
        <path
          d='M67.22 69H4.676c-1.57 0-2.843-1.437-2.843-3.21s1.273-3.21 2.843-3.21H67.22c1.57 0 2.843 1.437 2.843 3.21S68.79 69 67.22 69z'
          fill='#FF5419'
        />
        <path
          d='M67.22 62.58H36.006V69H67.22c1.57 0 2.843-1.437 2.843-3.21s-1.273-3.21-2.843-3.21z'
          fill='#C92F00'
        />
      </g>
      <defs>
        <filter
          id='asset-data-menu_svg__filter0_d_5537_188948'
          x={0.833}
          y={0}
          width={94}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5537_188948' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5537_188948' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgAssetDataMenu;
