import {
  FORMAT_MAPPING_TRANSLATION_COLUMN as FORMAT_MAPPING_TRANSLATION_COLUMN_TABLE_KEY_VALUE,
  FORMAT_PERIOD_DATE_COLUMN,
} from '../../../components/table/TableKeyValuePureMarkup/TableKeyValuePureMarkup';
import {
  FORMAT_COMMON_COLUMN,
  FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
  FORMAT_DATE_COLUMN,
  FORMAT_MAPPING_TRANSLATION_COLUMN,
} from '../../../components/table-row/TableRowPureMarkup/TableRowPureMarkup';
import { DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF } from '../../default/object-keyname.default';
import { mappingSalesDeliveryStatus, mappingSalesInvoiceStatus } from '../label-value/sales-status';

import {
  primaryTableDataGreyBackgroundNgreyOnlyTopBorder,
  primaryTableDataGreyBackgroundNgreyOnlyTopBottom,
  secondarySubtitleCalculationNumberParagraph,
  secondarySubtitleCalculationNumberParagraphReduceFontWight,
} from './additional-styles';

// column sales invoice list that handling table head with translation
export const columnsTableHeaderSalesInvoice = (t) => {
  return [
    {
      name: 'transaction_date',
      name_input: 'from_date',
      label: t('dashboard.sales.sales-invoice.table.header-column.transaction-date'),
      placeholder: t('dashboard.sales.sales-invoice.input.search-transaction-date.placeholder'),
      width: 150,
    },
    {
      name: 'sales_invoice_no',
      name_input: 'sales_invoice_no',
      label: t('dashboard.sales.sales-invoice.table.header-column.transaction-no'),
      placeholder: t('dashboard.sales.sales-invoice.input.search-transaction-no.placeholder'),
      width: 150,
    },
    {
      name: 'customer_name',
      name_input: 'customer_name',
      label: t('dashboard.sales.sales-invoice.table.header-column.customer'),
      placeholder: t('dashboard.sales.sales-invoice.input.search-customer.placeholder'),
      width: 300,
      align: 'left',
    },
    {
      name: 'amount',
      label: t('dashboard.sales.sales-invoice.table.header-column.nominal'),
      align: 'right',
      width: 150,
    },
    {
      name: 'status',
      name_input: 'status',
      label: t('table.header-column.invoice-status'),
      placeholder: t('placeholder.choose-status'),
      align: 'left',
      width: 252,
      minWidth: 240,
      maxWidth: 255,
    },
    {
      name: 'status_delivery',
      name_input: 'status_delivery',
      label: t('table.header-column.delivery-status'),
      placeholder: t('placeholder.choose-status'),
      align: 'left',
      width: 252,
      minWidth: 240,
      maxWidth: 255,
    },
  ];
};

export const columnsSearchWithDate = ['from_date', 'to_date'];

// column that representation for calculation
export const calculationColumns = ['amount'];

export const columnsTableFooterProductSalesInvoiceList = (t) => {
  return [
    [
      {
        isEmptyTableData: true,
        colSpan: 1,
      },
      {
        isLabel: true,
        label: t('typography.discount'),
        colSpan: 2,
        typographyStyle: secondarySubtitleCalculationNumberParagraphReduceFontWight,
        tableDataStyle: primaryTableDataGreyBackgroundNgreyOnlyTopBorder,
      },
      {
        isValue: true,
        valueKeyName: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
        colSpan: 2,
        substituteNotExistValue: 0,
        typographyStyle: secondarySubtitleCalculationNumberParagraphReduceFontWight,
        tableDataStyle: primaryTableDataGreyBackgroundNgreyOnlyTopBorder,
      },
    ],
    [
      {
        isEmptyTableData: true,
        colSpan: 1,
      },
      {
        isLabel: true,
        label: t('typography.total'),
        colSpan: 2,
        typographyStyle: secondarySubtitleCalculationNumberParagraph,
        tableDataStyle: primaryTableDataGreyBackgroundNgreyOnlyTopBottom,
      },
      {
        isValue: true,
        valueKeyName: 'amount',
        colSpan: 2,
        substituteNotExistValue: 0,
        typographyStyle: secondarySubtitleCalculationNumberParagraph,
        tableDataStyle: primaryTableDataGreyBackgroundNgreyOnlyTopBottom,
      },
    ],
  ];
};

// columns sales invoice pdf print
export const columnsTableHeaderSalesInvoicePDFprint = (t) => {
  return [
    {
      name: 'transaction_date',
      label: t('dashboard.sales.sales-invoice.table.header-column.transaction-date'),
      style: {
        width: 150,
        position: 'unset',
      },
      formatData: FORMAT_DATE_COLUMN,
    },
    {
      name: 'sales_invoice_no',
      label: t('dashboard.sales.sales-invoice.table.header-column.transaction-no'),
      style: {
        position: 'unset',
        width: 150,
      },
      formatData: FORMAT_COMMON_COLUMN,
    },
    {
      name: 'customer_name',
      label: t('dashboard.sales.sales-invoice.table.header-column.customer'),
      style: {
        position: 'unset',
        width: 300,
      },
      align: 'left',
      formatData: FORMAT_COMMON_COLUMN,
    },
    {
      name: 'amount',
      label: t('dashboard.sales.sales-invoice.table.header-column.nominal'),
      style: {
        position: 'unset',
        width: 150,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      isUseZeroNumberWhenNull: true,
    },
    {
      name: 'status',
      label: t('table.header-column.invoice-status'),
      align: 'left',
      style: {
        width: 252,
        position: 'unset',
      },
      formatData: FORMAT_MAPPING_TRANSLATION_COLUMN,
      mappingTranslation: mappingSalesInvoiceStatus,
    },
    {
      name: 'status_delivery',
      label: t('table.header-column.delivery-status'),
      style: {
        width: 252,
        position: 'unset',
      },
      align: 'left',
      formatData: FORMAT_MAPPING_TRANSLATION_COLUMN,
      mappingTranslation: mappingSalesDeliveryStatus,
      substituteNotExistValue: t('label.sales-delivery-status.open'),
    },
  ];
};

// columns table sales invoice print
export const columnsTableKeyValuePrintIndexSalesInvoice = [
  {
    header: 'dashboard.sales.sales-invoice.table.header-column.transaction-date',
    valueKeyName: 'search_from_date',
    valueKeyNameSecondary: 'search_to_date',
    formatValue: FORMAT_PERIOD_DATE_COLUMN,
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'dashboard.sales.sales-invoice.table.header-column.transaction-no',
    valueKeyName: 'search_sales_order_no',
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'dashboard.sales.sales-invoice.table.header-column.customer',
    valueKeyName: 'search_customer_name',
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'table.header-column.invoice-status',
    valueKeyName: 'search_status',
    formatValue: FORMAT_MAPPING_TRANSLATION_COLUMN_TABLE_KEY_VALUE,
    mappingTranslation: mappingSalesInvoiceStatus,
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'table.header-column.delivery-status',
    valueKeyName: 'search_status_delivery',
    formatValue: FORMAT_MAPPING_TRANSLATION_COLUMN_TABLE_KEY_VALUE,
    mappingTranslation: mappingSalesDeliveryStatus,
    showOnlyWhenExistedValue: true,
  },
];
