import { isEmpty } from 'lodash';
import moment from 'moment';

import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF,
  DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
} from '../../default/object-keyname.default';

/**
 *
 * @param { object } param0  params function as object
 *
 * @param   { object }      companyUserData
 * @param   { object }      accountingPeriodAPIkeyname
 * @param   { object }      documentData
 * @param   { object }      keynameTransactionDocumentData
 * @param   { object }      amountAddDateToAccountingPeriod
 *
 * @returns
 */
export const getAccountingPeriodCompareWithDocumentTransactionDate = ({
  companyUserData = {},
  accountingPeriodAPIkeyname = DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF,
  documentData = { [DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF]: null },
  keynameTransactionDocumentData = DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
  amountAddDateToAccountingPeriod = 1,
} = {}) => {
  if (!accountingPeriodAPIkeyname || isEmpty(companyUserData)) return null;

  let accountingPeriodCompany = companyUserData[accountingPeriodAPIkeyname] || null,
    transactionDateDocument = documentData[keynameTransactionDocumentData] || null;

  if (accountingPeriodCompany && amountAddDateToAccountingPeriod) {
    accountingPeriodCompany = moment(new Date(accountingPeriodCompany)).add(
      Number(amountAddDateToAccountingPeriod),
      'd',
    );

    accountingPeriodCompany = moment(accountingPeriodCompany).toISOString();
  }

  if (!accountingPeriodCompany) return transactionDateDocument;
  if (!transactionDateDocument) return accountingPeriodCompany;

  const isAccountingPeriodGreaterThanTransactionDateDocument = moment(
    new Date(transactionDateDocument),
  ).isBefore(new Date(moment(accountingPeriodCompany)));

  if (isAccountingPeriodGreaterThanTransactionDateDocument) return accountingPeriodCompany;

  return transactionDateDocument;
};
