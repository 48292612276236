import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import closingBookServices from '../../services/API/closing-book/closing-book.service';
import {
  REACT_APP_SETTING_CLOSING_BOOK_DETAILS_URL,
  REACT_APP_SETTING_CLOSING_BOOK_URL,
} from '../../utils/configs/url.config';
import {
  LOCAL_STORAGE_CLOSING_BOOK_DATA,
  LOCAL_STORAGE_CLOSING_BOOK_DETAIL_LIST,
} from '../../utils/constants/storage.constant';
import {
  SLICE_NAME_CLOSING_BOOK_DATA,
  SLICE_NAME_CLOSING_BOOK_DETAILS,
  SLICE_NAME_TEMP_PROCESS_CLOSING_BOOK,
} from '../../utils/constants/store.constant';
import { DEFAULT_CLOSING_BOOK_SEARCH_FE_PARAMS } from '../../utils/default/params.default';
import formatHelp from '../../utils/helpers/format.helpers';
import strHelp from '../../utils/helpers/string.helpers';
import {
  creatorAddAction,
  creatorAddActionWithPreviewButtonGenerateLink,
  creatorAddActionWithStoreDataOnSlice,
  creatorListAction,
  creatorListRefreshAction,
} from '../creator-action/creator-action.helper';

// handle action closing book list
export const getClosingBookList = createAsyncThunk(
  'closing-book/list',
  async (paramsGetClosingBookList, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      paramsGetClosingBookList,
      closingBookServices.getClosingBookList,
      LOCAL_STORAGE_CLOSING_BOOK_DATA,
      SLICE_NAME_CLOSING_BOOK_DATA,
      'error.list-data.closing-book',
    );
  },
);

// handle action closing book list refresh
export const getClosingBookListRefresh = createAsyncThunk(
  'closing-book/list-refresh',
  async (discardKeySearch = [], thunkAPI) => {
    return await creatorListRefreshAction(
      thunkAPI,
      closingBookServices.getClosingBookList,
      LOCAL_STORAGE_CLOSING_BOOK_DATA,
      SLICE_NAME_CLOSING_BOOK_DATA,
      discardKeySearch,
      DEFAULT_CLOSING_BOOK_SEARCH_FE_PARAMS,
      'error.list-data.closing-book',
    );
  },
);

// action revert closing book
export const closingBookRevert = createAsyncThunk(
  'closing-book/revert',
  async (bodyClosingBookRevertData, thunkAPI) => {
    const { periode_start, periode_end, closing_book_id } = bodyClosingBookRevertData;

    let closingBookPeriod = '';

    if (!isEmpty(periode_start) && !isEmpty(periode_end)) {
      closingBookPeriod = formatHelp.getReadableDateRangeWithCustomFormatter(
        periode_start,
        periode_end,
      );
    }

    return await creatorAddAction(
      thunkAPI,
      { closing_book_id },
      closingBookServices.closingBookRevert,
      'success.revert.closing-book',
      'error.revert.closing-book',
      { closingBookPeriod },
    );
  },
);

// action process closing book
export const closingBookProcess = createAsyncThunk(
  'closing-book/process',
  async (bodyClosingBookProcess, thunkAPI) => {
    const { from_date, to_date } = bodyClosingBookProcess;

    let closingBookPeriod = '';

    if (!isEmpty(from_date) && !isEmpty(to_date)) {
      closingBookPeriod = formatHelp.getReadableDateRangeWithCustomFormatter(from_date, to_date);
    }

    return await creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      bodyClosingBookProcess,
      closingBookServices.closingBookProcess,
      SLICE_NAME_TEMP_PROCESS_CLOSING_BOOK,
      'success.process.closing-book',
      'error.process.closing-book',
      { closingBookPeriod },
    );
  },
);

// action detail closing book
export const getClosingBookDetails = createAsyncThunk(
  'closing-book/details',
  async (paramsGetClosingBookDetails, thunkAPI) => {
    return await creatorListAction(
      thunkAPI,
      paramsGetClosingBookDetails,
      closingBookServices.getClosingBookDetails,
      LOCAL_STORAGE_CLOSING_BOOK_DETAIL_LIST,
      SLICE_NAME_CLOSING_BOOK_DETAILS,
      'closing_book_id',
      'error.detail.closing-book',
    );
  },
);

// action create closing book
export const closingBookCreate = createAsyncThunk(
  'closing-book/create',
  async (bodyClosingBookCreate, thunkAPI) => {
    return await creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: bodyClosingBookCreate,
      serviceFunc: closingBookServices.closingBookCreate,
      successMessage: 'success.add.closing-book',
      errorMessage: 'error.add.closing-book',
      baseContentUrl: REACT_APP_SETTING_CLOSING_BOOK_URL,
      generateButtonLink: (responseResult) => {
        return strHelp.templateString(
          REACT_APP_SETTING_CLOSING_BOOK_DETAILS_URL,
          responseResult,
          true,
        );
      },
      isShowPreviewButton:
        bodyClosingBookCreate.isShowPreviewButton !== undefined
          ? bodyClosingBookCreate.isShowPreviewButton
          : true,
    });
  },
);

const closingBookActions = {
  getClosingBookList,
  getClosingBookListRefresh,
  closingBookRevert,
  closingBookProcess,
  getClosingBookDetails,
  closingBookCreate,
};

export default closingBookActions;
