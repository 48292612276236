import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/system';
import Cookies from 'js-cookie';

import LoadingSpinner from '../../../components/loading/LoadingSpinner/LoadingSpinner';
import VerifyEmailPage from '../../VerifyEmail/VerifyEmailPage/VerifyEmailPage';

import { styles } from './SuspensePage.styles.js';

class SuspensePage extends Component {
  render() {
    const { router, classes } = this.props;

    const { pathname } = router.location;

    // get current language
    const language = Cookies.get('locale');

    if (pathname.startsWith('/verify/email')) {
      return <VerifyEmailPage />;
    }

    // text for displaying output
    const textOut = language === 'id' ? 'Memuat...' : 'Loading...';

    return (
      <Box className={classes.boxMain}>
        <LoadingSpinner />
        <Box className={classes.boxTextOut}>
          <Typography variant='body2'>{textOut}</Typography>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    router: state.router,
  };
};

// styling component
const stylingSuspensePage = withStyles(styles)(SuspensePage);

// connect component into store
const ConnectedSuspensePage = connect(mapStateToProps)(stylingSuspensePage);

export default ConnectedSuspensePage;
