import { FORMAT_DATE_COLUMN } from '../../../components/table/TableKeyValuePureMarkup/TableKeyValuePureMarkup';
import { FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN } from '../../../components/table-row/TableRowMultipleTableDataPureMarkup/TableRowMultipleTableDataPureMarkup';
import { FORMAT_CURRENCY_AUTO_COMMA_COLUMN } from '../../../components/table-row/TableRowPureMarkup/TableRowPureMarkup';

import { primaryTableDataCalculationNumber } from './additional-styles';

// columns key value table
export const columnsTableKeyValuePrintSalesOrderDetails = [
  {
    headerTranslated: 'No.',
    valueKeyName: 'sales_order_no',
  },
  {
    header: 'table.header-column.date',
    valueKeyName: 'transaction_date',
    formatValue: FORMAT_DATE_COLUMN,
  },
  {
    header: 'table.header-column.name',
    valueKeyName: 'customer_name',
  },
  {
    header: 'table.header-column.address',
    valueKeyName: 'customer_address',
  },
];

export const columnsTableProductSalesOrderDetails = (t) => {
  return [
    {
      isAutoIncrementColumn: true,
      label: t('No.'),
      align: 'center',
      style: {
        maxWidth: 20,
      },
    },
    {
      name: 'product_name',
      label: t('table.header-column.product-name'),
      style: {
        minWidth: 'auto',
        width: 593,
        maxWidth: 600,
      },
    },
    {
      name: 'quantity',
      label: t('table.header-column.qty'),
      style: {
        minWidth: 'auto',
        width: 20,
        maxWidth: 35,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'unit_destination',
      label: t('table.header-column.unit'),
      style: {
        minWidth: 'auto',
        width: 30,
        maxWidth: 35,
      },
    },
    {
      name: 'price',
      label: t('table.header-column.at-price'),
      style: {
        minWidth: 'auto',
        width: 192,
        maxWidth: 300,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'amount',
      label: t('table.header-column.amount'),
      style: {
        minWidth: 'auto',
        width: 192,
        maxWidth: 300,
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
  ];
};

export const columnsTableFooterProductSalesOrderDetails = (t) => {
  return [
    [
      {
        isEmptyTableData: true,
        colSpan: 4,
      },
      {
        isLabel: true,
        label: t('typography.discount'),
      },
      {
        isValue: true,
        valueKeyName: 'discount_percentage',
        tableDataStyle: primaryTableDataCalculationNumber,
      },
    ],
    [
      {
        isEmptyTableData: true,
        colSpan: 4,
      },
      {
        isLabel: true,
        label: t('typography.total'),
      },
      {
        isValue: true,
        valueKeyName: 'amount',
        formatValue: FORMAT_CALCULATION_NUMBER_AUTO_COMMA_COLUMN,
        tableDataStyle: primaryTableDataCalculationNumber,
      },
    ],
  ];
};

export const columnsTableFooterSalesOrderDetails = (t) => {
  return [
    {
      name: 'description',
      label: t('table.header-column.memo-colon'),
      style: {
        minWidth: 100,
        width: 360,
        maxWidth: 365,
        height: 56,
        verticalAlign: 'top',
      },
      isUseTableHeaderStyle: false,
    },
  ];
};
