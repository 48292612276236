import React, { Component } from 'react';
import { Clear } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { withStyles } from '@mui/styles';

import { styles } from './InputAdornmentClear.styles';

/**
 * @props { bool }      isShowIcon, indicate is icon showing or not
 * @props { string }    iconName, icon name
 * @props { bool }      isLoadingSearch, status loading search data
 * @props { function }  onClickClearButton, handling click clear search input
 */

class InputAdornmentClear extends Component {
  render() {
    const { classes, isShowIcon, iconName, isLoadingSearch } = this.props;

    return (
      <InputAdornment position='end' className={classes.inputAdornmentClearSingleFilter}>
        {isShowIcon && (
          <IconButton
            edge='end'
            name={iconName}
            className={classes.iconButtonContainerClear}
            disabled={isLoadingSearch}
            onClick={this.props.onClickClearButton}
          >
            <Clear fontSize='10px' />
          </IconButton>
        )}
      </InputAdornment>
    );
  }
}

const stylingInputAdornmentClear = withStyles(styles)(InputAdornmentClear);

export default stylingInputAdornmentClear;
