export const styles = () => {
  return {
    tableHeadMainContainer: {
      '& th': {
        padding: '16px',
        borderBottom: '0px',
      },
      '& .MuiTableCell-root:last-of-type': {
        verticalAlign: 'bottom',
      },
    },
    tableCellMain: {
      fontSize: '14px',
      fontWeight: '700',
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.6)',
      borderBottom: '0px',
      '& p': {
        margin: '0px',
      },
    },
    inputAdornmentClearSingleFilter: {
      width: '30px',
    },
    iconButtonContainerClear: {
      right: '20px',
      top: '9px',
      padding: '1px',
      borderRadius: '50%',
      position: 'absolute',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      '& svg': {
        fill: 'white',
        width: '12px',
        height: '12px',
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    containerAutocomplete: {
      paddingRight: '0px',
      '& .MuiOutlinedInput-root': {
        paddingRight: '6px!important',
        marginRight: '40px !important',
      },
      '& .MuiAutocomplete-tag': {
        margin: '2px 14px 2px 2px',
      },
    },
  };
};
