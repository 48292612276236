// column report trial balance list that handling table head with translation
export const columnsTableReportTrialBalance = (t) => {
  return [
    [
      {
        name: 'account',
        label: t('dashboard.report.trial-balance.table.header-column.account'),
        format: 'normal',
        minWidth: 400,
        rowSpan: 2,
      },
      {
        name: 'beginning-balance',
        label: t('dashboard.report.trial-balance.table.header-column.beginning-balance'),
        format: 'normal-generalized',
        width: 300,
        colSpan: 2,
        align: 'center',
      },
      {
        name: 'movement',
        label: t('dashboard.report.trial-balance.table.header-column.movement-balance'),
        format: 'normal',
        width: 300,
        colSpan: 2,
        align: 'center',
      },
      {
        name: 'ending-balance',
        label: t('dashboard.report.trial-balance.table.header-column.ending-balance'),
        format: 'normal-currency',
        width: 300,
        colSpan: 2,
        align: 'center',
      },
    ],
    [
      {
        name: 'beginning-balance-credit',
        label: t('dashboard.report.trial-balance.table.header-column.beginning-balance-credit'),
        format: 'normal-currency',
        width: 200,
        align: 'center',
      },
      {
        name: 'beginning-balance-debit',
        label: t('dashboard.report.trial-balance.table.header-column.beginning-balance-debit'),
        format: 'normal-currency',
        width: 200,
        align: 'center',
      },
      {
        name: 'movement-balance-credit',
        label: t('dashboard.report.trial-balance.table.header-column.movement-balance-credit'),
        format: 'normal-currency',
        width: 200,
        align: 'center',
      },
      {
        name: 'movement-balance-debit',
        label: t('dashboard.report.trial-balance.table.header-column.movement-balance-debit'),
        format: 'normal-currency',
        width: 200,
        align: 'center',
      },
      {
        name: 'ending-balance-credit',
        label: t('dashboard.report.trial-balance.table.header-column.ending-balance-credit'),
        format: 'normal-currency',
        width: 200,
        align: 'center',
      },
      {
        name: 'ending-balance-debit',
        label: t('dashboard.report.trial-balance.table.header-column.ending-balance-debit'),
        format: 'normal-currency',
        width: 200,
        align: 'center',
      },
    ],
  ];
};

export const keysDataCalculationReportTrialBalance = [
  'beginning_balance_debit',
  'beginning_balance_credit',
  'movement_balance_debit',
  'movement_balance_credit',
  'ending_balance_debit',
  'ending_balance_credit',
];

export const keysDataCalculationReportTrialBalanceV2 = [
  'before_debit_amount',
  'before_credit_amount',
  'movement_debit_amount',
  'movement_credit_amount',
  'after_debit_amount',
  'after_credit_amount',
];

export const totalKeysReportTrialBalanceFromAPI = [
  'total_beginning_balance_debit',
  'total_beginning_balance_credit',
  'total_ending_balance_debit',
  'total_ending_balance_credit',
  'total_movement_balance_debit',
  'total_movement_balance_credit',
];

export const totalKeysReportTrialBalanceFromAPIv2 = [
  'total_before_debit_amount',
  'total_before_credit_amount',
  'total_movement_debit_amount',
  'total_movement_credit_amount',
  'total_after_debit_amount',
  'total_after_credit_amount',
];

export const correspondReportTrialBalanceAPIkeynameWithViewKeyName = {
  aset: 'asset',
  ekuitas: 'equity',
  kewajiban: 'liability',
  pendapatan: 'income',
  pengeluaran: 'expense',
};

export const allRowsTableReportTrialBalance = ['asset', 'equity', 'liability', 'income', 'expense'];

export const rowsTableReportTrialBalanceTranslationRow = [
  {
    rowName: 'asset',
    translationRowName: 'table.row.asset',
  },
  {
    rowName: 'equity',
    translationRowName: 'table.row.equity',
  },
  {
    rowName: 'liability',
    translationRowName: 'table.row.liability',
  },
  {
    rowName: 'income',
    translationRowName: 'table.row.income',
  },
  {
    rowName: 'expense',
    translationRowName: 'table.row.expense',
  },
];

export const rowsTableReportTrialBalanceTranslationRowV2 = (t) => {
  return [
    {
      rowName: 'asset',
      translationRowName: t('table.row.asset'),
    },
    {
      rowName: 'equity',
      translationRowName: t('table.row.equity'),
    },
    {
      rowName: 'liability',
      translationRowName: t('table.row.liability'),
    },
    {
      rowName: 'income',
      translationRowName: t('table.row.income'),
    },
    {
      rowName: 'expense',
      translationRowName: t('table.row.expense'),
    },
  ];
};
