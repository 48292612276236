import * as React from 'react';
function SvgPurchaseReturnMenu(props) {
  return (
    <svg width={110} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#purchase-return-menu_svg__filter0_d_5586_193259)'>
        <path
          d='M61.529 1.012c19.21 0 34.811 14.966 35.078 33.511l3.949-3.003a1.293 1.293 0 011.762.2 1.2 1.2 0 01-.207 1.708l-5.93 4.51c-.242.188-.534.27-.825.26a1.253 1.253 0 01-.826-.26l-5.93-4.51a1.182 1.182 0 01-.206-1.707 1.27 1.27 0 011.761-.2l3.548 2.696c-.425-16.85-14.666-30.39-32.162-30.39-16.354 0-29.854 11.833-31.895 27.14a3.46 3.46 0 00-1.81-.47 3.83 3.83 0 00-1.094.152C28.942 13.93 43.68 1 61.541 1l-.012.012zM21.068 36.525l5.93-4.51c.242-.189.534-.271.825-.26.292-.011.584.071.826.26l5.93 4.51c.547.412.644 1.19.206 1.707a1.27 1.27 0 01-1.761.2l-3.658-2.779c.353 16.92 14.617 30.533 32.163 30.533 16.379 0 29.89-11.858 31.907-27.2a3.471 3.471 0 001.908.53c.328 0 .656-.036.972-.13C94.092 56.095 79.366 69 61.529 69c-19.198 0-34.8-14.942-35.079-33.488l-3.84 2.92a1.293 1.293 0 01-1.76-.2 1.2 1.2 0 01.206-1.707h.012z'
          fill='#039855'
        />
        <path d='M81.022 23.644H41.874V49.15h39.148V23.644z' fill='#FFD15D' />
        <path d='M81.022 23.644H61.37V49.15h19.652V23.644z' fill='#F9B54C' />
        <path d='M82.98 20H39.915v7.287H82.98V20z' fill='#F9B54C' />
        <path d='M82.98 20H61.37v7.287h21.61V20z' fill='#F4A200' />
        <path
          d='M67.32 33.912H55.576c-1.08 0-1.957-.815-1.957-1.822 0-1.006.876-1.821 1.957-1.821H67.32c1.081 0 1.958.815 1.958 1.822 0 1.006-.877 1.821-1.958 1.821z'
          fill='#B97850'
        />
        <path
          d='M67.32 30.269h-5.95v3.643h5.95c1.081 0 1.958-.815 1.958-1.822 0-1.006-.877-1.821-1.958-1.821z'
          fill='#935635'
        />
        <path
          d='M53.52 36.858h-7.487c-.77 0-1.376.564-1.376 1.282v6.968c0 .717.606 1.281 1.377 1.281h7.487c.77 0 1.376-.564 1.376-1.28v-6.97c0-.666-.606-1.28-1.376-1.28zm.606 8.25c0 .308-.275.564-.605.564h-7.488c-.33 0-.605-.256-.605-.564V38.14c0-.308.275-.564.605-.564h7.488c.33 0 .605.256.605.563v6.97z'
          fill='#fff'
        />
        <path
          d='M48.236 39.472c-.055-.052-.055-.052-.11-.052-.056 0-.11-.05-.166-.05-.055 0-.11 0-.165.05-.055 0-.11.052-.11.052l-1.266 1.178c-.165.154-.165.359 0 .461.165.154.385.154.495 0l.66-.614v3.023c0 .205.166.358.386.358s.386-.153.386-.358v-3.024l.66.615a.438.438 0 00.276.103c.11 0 .165-.051.275-.103.165-.153.165-.358 0-.46l-1.322-1.18zM51.869 39.472a.437.437 0 00-.276-.103c-.11 0-.22.051-.275.103l-1.266 1.178c-.165.154-.165.359 0 .461.165.154.386.154.496 0l.66-.614v3.023c0 .205.165.358.386.358.22 0 .385-.153.385-.358v-3.024l.66.615a.438.438 0 00.276.103c.11 0 .165-.051.275-.103.165-.153.165-.358 0-.46l-1.321-1.18zM65.026 36.858H57.54c-.77 0-1.376.564-1.376 1.282v6.968c0 .717.606 1.281 1.376 1.281h7.487c.771 0 1.377-.564 1.377-1.28v-6.97c0-.666-.606-1.28-1.377-1.28zm.661 8.25c0 .308-.275.564-.605.564h-7.543c-.33 0-.605-.256-.605-.564V38.14c0-.308.275-.564.605-.564h7.487c.33 0 .606.256.606.563v6.97h.055z'
          fill='#fff'
        />
        <path
          d='M62.935 38.806h-3.248c-.22 0-.386.153-.386.358v1.64c0 .922.716 1.69 1.652 1.845v1.127h-.496c-.22 0-.385.154-.385.359s.165.358.385.358h1.762c.22 0 .385-.154.385-.358 0-.205-.165-.36-.385-.36h-.495V42.65c.935-.154 1.651-.923 1.651-1.845v-1.64c-.055-.205-.22-.358-.44-.358zm-.386 1.998c0 .666-.55 1.178-1.266 1.178-.715 0-1.266-.512-1.266-1.178v-1.332h2.532v1.332zM76.532 36.858h-7.487c-.77 0-1.376.564-1.376 1.282v6.968c0 .717.606 1.281 1.376 1.281h7.487c.771 0 1.377-.564 1.377-1.28v-6.97c0-.666-.661-1.28-1.377-1.28zm.606 8.25c0 .308-.275.564-.606.564h-7.487c-.33 0-.605-.256-.605-.564V38.14c0-.308.275-.564.605-.564h7.487c.33 0 .606.256.606.563v6.97z'
          fill='#fff'
        />
        <path
          d='M72.789 38.344c-1.817 0-3.303 1.384-3.303 3.075 0 .205.165.359.385.359h2.588v2.305c0 .103-.11.205-.22.205s-.22-.102-.22-.205c0-.205-.166-.358-.386-.358s-.385.153-.385.358c0 .513.44.923.99.923.551 0 .991-.41.991-.923v-2.305h2.588c.22 0 .385-.154.385-.36-.11-1.69-1.596-3.074-3.413-3.074zm-2.532 2.716c.165-1.178 1.266-2.05 2.532-2.05s2.367.872 2.532 2.05h-5.064z'
          fill='#fff'
        />
        <path
          d='M6.565 34.87c0 1.03-1.041 1.869-2.324 1.869-1.284 0-2.324-.838-2.324-1.869 0-1.033 1.04-1.87 2.324-1.87 1.283 0 2.324.837 2.324 1.87zM39.03 37.314l-18.075 4.939 7.08 16.784 18.076-4.939-7.082-16.784z'
          fill='#FF8E31'
        />
        <path
          d='M49.55 64.415H19.265c-.96 0-1.865-.3-2.547-.849-.68-.549-1.055-1.276-1.055-2.05v-.078c0-.658.283-1.304.794-1.818.514-.512 1.236-.873 2.037-1.015l25.376-4.493c.602-.106 1.197.2 1.33.682.131.483-.248.963-.851 1.07l-25.376 4.493c-.31.053-.579.188-.778.387a.975.975 0 00-.303.694v.077c0 .296.143.574.401.783.26.208.607.325.971.325H49.55c.616 0 1.113.4 1.113.896 0 .495-.497.896-1.112.896z'
          fill='#F2F4F7'
        />
        <path
          d='M20.052 60.144c-.537 0-1.01-.314-1.098-.756l-4.581-23.094a1.055 1.055 0 00-.466-.668 1.579 1.579 0 00-.893-.265H4.24c-.616 0-1.115-.401-1.115-.896s.499-.899 1.115-.899h8.772c.856 0 1.688.249 2.339.695.652.446 1.087 1.07 1.22 1.75l4.582 23.093c.096.49-.319.95-.925 1.028-.06.008-.117.012-.177.012z'
          fill='#F2F4F7'
        />
        <path
          d='M25.18 65.885c0 1.581-1.592 2.86-3.553 2.86-1.961 0-3.554-1.279-3.554-2.86 0-1.58 1.593-2.858 3.554-2.858 1.96 0 3.553 1.278 3.553 2.858z'
          fill='#34495E'
        />
        <path
          d='M23.402 65.885c0 .79-.794 1.43-1.775 1.43-.982 0-1.775-.64-1.775-1.43 0-.788.793-1.428 1.775-1.428.98 0 1.775.64 1.775 1.428z'
          fill='#F2F4F7'
        />
        <path
          d='M50.664 65.885c0 1.581-1.592 2.86-3.554 2.86-1.961 0-3.552-1.279-3.552-2.86 0-1.58 1.591-2.858 3.552-2.858 1.962 0 3.554 1.278 3.554 2.858z'
          fill='#34495E'
        />
        <path
          d='M48.887 65.885c0 .79-.797 1.43-1.777 1.43-.98 0-1.776-.64-1.776-1.43 0-.788.796-1.428 1.776-1.428s1.777.64 1.777 1.428zM50.28 42.66a1.477 1.477 0 00-.927-.31H15.468l3.672 18.38v.002l30.495-5.4c.282-.049.543-.173.74-.368a.935.935 0 00.29-.661V43.405c-.001-.27-.13-.54-.385-.745zM21.72 58.496a.953.953 0 01-.132.008c-.4 0-.753-.233-.819-.562L18.156 44.86c-.072-.364.235-.707.688-.765.45-.059.877.19.952.553l2.612 13.083c.073.364-.235.707-.687.765zm4.334-.768a.918.918 0 01-.118.007c-.407 0-.762-.24-.821-.574l-2.177-12.313c-.065-.364.25-.703.703-.755.458-.053.874.202.94.567l2.178 12.313c.064.365-.25.703-.705.755zm4.33-.768a1.18 1.18 0 01-.1.005c-.414 0-.772-.249-.822-.588l-1.744-11.542c-.055-.365.27-.7.724-.744.456-.038.867.216.925.582l1.741 11.544c.055.366-.268.698-.724.743zm4.33-.768a1.226 1.226 0 01-.083.002c-.422 0-.784-.258-.825-.603L32.5 44.819c-.045-.367.288-.694.746-.73.463-.031.862.233.906.6l1.308 10.773c.043.367-.29.694-.746.73zm4.325-.768l-.06.002c-.432 0-.795-.269-.827-.622l-.87-10.003c-.032-.368.312-.687.77-.714.462-.019.854.252.886.62l.87 10.004c.033.369-.313.687-.77.713zm4.322-.77h-.032c-.445 0-.812-.282-.83-.641l-.436-9.233c-.016-.37.34-.68.8-.694.462-.022.842.274.86.642l.435 9.233c.018.37-.34.68-.797.694zm5.146-1.437c0 .37-.371.668-.83.668-.459 0-.83-.298-.83-.668v-8.462c0-.37.371-.668.83-.668.459 0 .83.298.83.668v8.462z'
          fill='#F2F4F7'
        />
      </g>
      <defs>
        <filter
          id='purchase-return-menu_svg__filter0_d_5586_193259'
          x={0.917}
          y={0}
          width={108.667}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_193259' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_193259' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgPurchaseReturnMenu;
