import { REACT_APP_API_URL_TAG } from '../../../utils/configs/api.config';
import { DEFAULT_ALLOW_PARAMS_TAG_LIST } from '../../../utils/default/params.default';
import { creatorListService } from '../creator-services/creator-services.helper';

// get tag list
const getTagList = async (params) => {
  return await creatorListService(REACT_APP_API_URL_TAG, params, DEFAULT_ALLOW_PARAMS_TAG_LIST);
};

const tagServices = {
  getTagList,
};

export default tagServices;
