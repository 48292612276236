import React, { Component } from 'react';
import { CheckCircleOutlineRounded, RadioButtonUncheckedRounded } from '@mui/icons-material';
import { Checkbox, Typography } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { Box } from '@mui/system';
import { isEmpty } from 'lodash';

import objHelper from '../../../utils/helpers/object.helper';

export const checkboxVariant = {
  primary: 'primary',
  warningPastel: 'warning-pastel',
  successPastel: 'success-pastel',
  errorPastel: 'error-pastel',
};

const checkedCheckboxKeyName = 'checked';
const unCheckedCheckboxKeyName = 'unChecked';

const checkboxColorCombination = {
  [checkboxVariant.primary]: {
    [checkedCheckboxKeyName]: {
      boxBackground: blue[700],
      disabledBoxBackground: grey[300],
      boxBorderColor: blue[500],
      disabledBoxBorderColor: grey[400],
      boxBorder: '1px solid',
      iconColor: 'white',
      disabledIconColor: grey[400],
      labelColor: 'white',
      disabledLabelColor: grey[400],
    },
    [unCheckedCheckboxKeyName]: {
      boxBackground: 'white',
      disabledBoxBackground: grey[300],
      boxBorderColor: blue[500],
      disabledBoxBorderColor: grey[400],
      boxBorder: '1px solid',
      iconColor: blue[500],
      disabledIconColor: grey[400],
      labelColor: blue[500],
      disabledLabelColor: grey[400],
    },
  },
  [checkboxVariant.warningPastel]: {
    [checkedCheckboxKeyName]: {
      boxBackground: '#FD965B',
      boxBorderColor: '#FD965B',
      boxBorder: '1px solid',
      iconColor: 'white',
      labelColor: 'white',
    },
    [unCheckedCheckboxKeyName]: {
      boxBackground: 'white',
      boxBorderColor: '#FD965B',
      boxBorder: '1px solid',
      iconColor: '#FD965B',
      labelColor: '#FD965B',
    },
  },
  [checkboxVariant.successPastel]: {
    [checkedCheckboxKeyName]: {
      boxBackground: '#3CAEA3',
      boxBorderColor: '#3CAEA3',
      boxBorder: '1px solid',
      iconColor: 'white',
      labelColor: 'white',
    },
    [unCheckedCheckboxKeyName]: {
      boxBackground: 'white',
      boxBorderColor: '#3CAEA3',
      boxBorder: '1px solid',
      iconColor: '#3CAEA3',
      labelColor: '#3CAEA3',
    },
  },
  [checkboxVariant.errorPastel]: {
    [checkedCheckboxKeyName]: {
      boxBackground: '#FF4D41',
      boxBorderColor: '#FF4D41',
      boxBorder: '1px solid',
      iconColor: 'white',
      labelColor: 'white',
    },
    [unCheckedCheckboxKeyName]: {
      boxBackground: 'white',
      boxBorderColor: '#FF4D41',
      boxBorder: '1px solid',
      iconColor: '#FF4D41',
      labelColor: '#FF4D41',
    },
  },
};

/**
 *
 * @prop    { string }      checkboxVariant                 name variant of checkbox
 * @prop    { object }      additionalPropsOuterBox         additional props for outer box
 * @prop    { object }      additionalPropsCheckbox         additional props for checkbox
 * @prop    { object }      additionalPropsTypography       additional props for typography / label
 * @prop    { bool }        checkboxChecked                 determine is checkbox checked
 * @prop    { string }      checkboxName                    name checkbox
 * @prop    { function }    onChange                        function getting change checkbox
 * @prop    { string }      labelChecked                    label when checkbox has checked
 * @prop    { string }      labelUnChecked                  label when checkbox was unchecked
 * @prop    { bool }        disabled                        determine checkbox can action or not
 */
class CheckboxWithLabel extends Component {
  render() {
    const {
      fullWidth,
      checkboxVariant,
      additionalPropsOuterBox,
      additionalPropsCheckbox,
      additionalPropsTypography,
      checkboxChecked,
      checkboxName,
      onChange,
      labelChecked,
      labelUnChecked,
      disabled = false,
      CheckedIcon = CheckCircleOutlineRounded,
      UnCheckedIcon = RadioButtonUncheckedRounded,
    } = this.props;

    const { value, defaultChecked } = additionalPropsCheckbox;

    const isCheckedCheckbox = value || defaultChecked || checkboxChecked;
    const isOnChangeFunction = typeof onChange === 'function' && isEmpty(onChange);

    const checkboxVariantKeyName = isCheckedCheckbox
      ? checkedCheckboxKeyName
      : unCheckedCheckboxKeyName;

    const selectedCheckboxColorCombination =
      checkboxColorCombination[checkboxVariant][checkboxVariantKeyName];

    const {
      boxBackground,
      disabledBoxBackground,
      boxBorderColor,
      disabledBoxBorderColor,
      boxBorder,
      iconColor,
      disabledIconColor,
      labelColor,
      disabledLabelColor,
    } = selectedCheckboxColorCombination;

    const eventCheckbox = {
      target: {
        ...objHelper.createEventData(checkboxName, !checkboxChecked, 'target').target,
        checked: !checkboxChecked,
      },
    };

    return (
      <Box
        width={fullWidth ? '100%' : 'auto'}
        height={'28px'}
        borderRadius={2}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        sx={{
          background: disabled ? disabledBoxBackground : boxBackground,
          '&:hover': {
            cursor: disabled ? 'not-allowed' : 'pointer',
          },
        }}
        border={boxBorder}
        borderColor={disabled ? disabledBoxBorderColor : boxBorderColor}
        onClick={
          isOnChangeFunction && !disabled
            ? (e) => this.props.onChange({ ...e, ...eventCheckbox })
            : null
        }
        {...additionalPropsOuterBox}
      >
        <Checkbox
          sx={{
            color: disabled ? disabledIconColor : iconColor,
          }}
          size={'small'}
          checkedIcon={
            <CheckedIcon
              sx={{
                color: disabled ? disabledIconColor : iconColor,
              }}
            />
          }
          icon={
            <UnCheckedIcon
              sx={{
                color: disabled ? disabledIconColor : iconColor,
              }}
            />
          }
          name={checkboxName}
          checked={checkboxChecked}
          onChange={isOnChangeFunction && !disabled ? this.props.onChange : null}
          disabled={disabled}
          {...additionalPropsCheckbox}
        />

        <Typography
          variant={'body2'}
          fontSize={'14px'}
          paddingRight={'8px'}
          {...additionalPropsTypography}
          color={disabled ? disabledLabelColor : labelColor}
        >
          {isCheckedCheckbox ? labelChecked : labelUnChecked}
        </Typography>
      </Box>
    );
  }
}

CheckboxWithLabel.defaultProps = {
  fullWidth: true,
  checkboxVariant: 'primary',
  additionalPropsOuterBox: {},
  additionalPropsCheckbox: {},
  additionalPropsTypography: {},
  checkboxChecked: false,
  checkboxName: '',
  onChange: () => {
    return null;
  },
  labelChecked: '',
  labelUnChecked: '',
};

export default CheckboxWithLabel;
