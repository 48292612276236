import * as React from 'react';
function SvgAssetCategoryDataMenu(props) {
  return (
    <svg width={97} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#asset-category-data-menu_svg__filter0_d_5543_189163)'>
        <path
          d='M50.705 15.704v18.87H40.56v2.07h10.145v18.869c0 3.008 2.048 5.456 4.565 5.456h13.377V58.9H55.27c-1.604 0-2.91-1.519-2.91-3.386v-18.87h15.527v-2.07H52.361V15.705c0-1.867 1.305-3.386 2.91-3.386h13.376v-2.07H55.27c-2.517 0-4.565 2.448-4.565 5.456z'
          fill='#E9EFF4'
        />
      </g>
      <g filter='url(#asset-category-data-menu_svg__filter1_d_5543_189163)'>
        <path
          d='M78.408 69c5.942 0 10.76-4.326 10.76-9.661 0-5.336-4.818-9.661-10.76-9.661-5.943 0-10.76 4.325-10.76 9.66 0 5.336 4.817 9.662 10.76 9.662z'
          fill='#FD8469'
        />
        <path
          d='M78.408 49.678c5.942 0 10.76 4.325 10.76 9.66 0 5.336-4.818 9.662-10.76 9.662V49.678z'
          fill='#FC6F58'
        />
        <path
          d='M78.408 44.661c5.942 0 10.76-4.325 10.76-9.661 0-5.336-4.818-9.661-10.76-9.661-5.943 0-10.76 4.325-10.76 9.661 0 5.336 4.817 9.661 10.76 9.661z'
          fill='#FD8469'
        />
        <path
          d='M78.408 25.339c-.043 0-.086.002-.128.003v19.316c.042 0 .085.003.128.003 5.942 0 10.76-4.325 10.76-9.661 0-5.336-4.818-9.661-10.76-9.661z'
          fill='#FC6F58'
        />
        <path
          d='M78.408 20.322c5.942 0 10.76-4.325 10.76-9.66C89.167 5.324 84.35 1 78.407 1c-5.943 0-10.76 4.325-10.76 9.661 0 5.336 4.817 9.661 10.76 9.661z'
          fill='#FD8469'
        />
        <path
          d='M78.408 1c5.942 0 10.76 4.326 10.76 9.661 0 5.336-4.818 9.661-10.76 9.661V1z'
          fill='#FC6F58'
        />
      </g>
      <g filter='url(#asset-category-data-menu_svg__filter2_d_5543_189163)'>
        <path
          d='M1.167 43.461c0-9.757 8.809-22.083 19.675-22.083 10.866 0 19.675 12.326 19.675 22.083 0 9.757-8.809 13.25-19.675 13.25-10.866 0-19.675-3.493-19.675-13.25z'
          fill='#324A5E'
        />
        <path
          d='M20.842 21.378l-.082.002v35.33l.082.002c10.866 0 19.675-3.493 19.675-13.25 0-9.758-8.81-22.084-19.675-22.084z'
          fill='#2B3B4E'
        />
        <path
          d='M27.36 14.45c0 3.848-2.918 6.967-6.518 6.967-3.6 0-6.517-3.119-6.517-6.967H27.36z'
          fill='#324A5E'
        />
        <path d='M20.76 14.45v6.965l.082.002c3.6 0 6.517-3.119 6.517-6.967h-6.6z' fill='#2B3B4E' />
        <path
          d='M20.842 46.449c3.457 0 6.26-2.517 6.26-5.622 0-3.104-2.803-5.621-6.26-5.621-3.458 0-6.26 2.517-6.26 5.621 0 3.105 2.802 5.622 6.26 5.622z'
          fill='#FFD15D'
        />
        <path
          d='M20.842 35.206l-.082.002v11.239l.082.001c3.457 0 6.26-2.516 6.26-5.62 0-3.106-2.803-5.622-6.26-5.622z'
          fill='#F9B54C'
        />
        <path
          d='M20.842 47.773c-4.266 0-7.736-3.115-7.736-6.946 0-3.83 3.47-6.946 7.736-6.946 4.265 0 7.735 3.116 7.735 6.946 0 3.83-3.47 6.947-7.735 6.947zm0-11.242c-2.638 0-4.785 1.927-4.785 4.296 0 2.37 2.147 4.297 4.785 4.297 2.638 0 4.784-1.928 4.784-4.297 0-2.369-2.146-4.296-4.784-4.296z'
          fill='#F9B54C'
        />
        <path
          d='M20.842 33.881l-.082.002v2.652c.027 0 .054-.004.082-.004 2.638 0 4.784 1.927 4.784 4.296 0 2.37-2.146 4.296-4.784 4.296-.028 0-.055-.003-.082-.003v2.651l.082.002c4.265 0 7.735-3.116 7.735-6.946 0-3.83-3.47-6.946-7.735-6.946z'
          fill='#F4A200'
        />
        <path d='M25.76 19.48h-9.837v2.65h9.838v-2.65z' fill='#F9B54C' />
        <path d='M25.761 19.48h-5v2.65h5v-2.65z' fill='#F4A200' />
      </g>
      <defs>
        <filter
          id='asset-category-data-menu_svg__filter0_d_5543_189163'
          x={39.56}
          y={9.248}
          width={36.086}
          height={58.721}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5543_189163' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5543_189163' result='shape' />
        </filter>
        <filter
          id='asset-category-data-menu_svg__filter1_d_5543_189163'
          x={66.648}
          y={0}
          width={29.52}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5543_189163' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5543_189163' result='shape' />
        </filter>
        <filter
          id='asset-category-data-menu_svg__filter2_d_5543_189163'
          x={0.167}
          y={13.45}
          width={47.35}
          height={50.261}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5543_189163' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5543_189163' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgAssetCategoryDataMenu;
