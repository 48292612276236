import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Clear } from '@mui/icons-material';
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { isEmpty, isEqual } from 'lodash';

import AutocompleteCustom from '../../../../components/custom/CustomAutocomplete/CustomAutocomplete';
import DateTimePicker from '../../../../components/date-time/DateTimePicker/DateTimePicker';
import InputAdornmentLoading from '../../../../components/input-adorment/InputAdornmentLoading/InputAdornmentLoading';
import PopperAutoWidth from '../../../../components/popper/PopperAutoWidth/PopperAutoWidth';
import { contactList } from '../../../../store/contact/contact.action';
import { clearContactData } from '../../../../store/contact/contact.slice';
import messageHelper from '../../../../store/message/message.helper';
import { getListPurchaseDelivery } from '../../../../store/purchase/purchase.action';
import {
  STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PENDING,
} from '../../../../utils/constants/store.constant';
import { initialPurchaseDeliverySearch } from '../../../../utils/data/display/initial-search-purchase-delivery';
import { columnsSearchWithDate } from '../../../../utils/data/display/table-list-purchase-delivery';
import { minutesHandoverStatus } from '../../../../utils/data/label-value/minutes-of-handover-status';
import { purchaseDeliveryStatus } from '../../../../utils/data/label-value/purchase-status';
import { TERSIER_DEFAULT_LIST_PARAMS } from '../../../../utils/default/params.default';
import arrHelp from '../../../../utils/helpers/array.helpers';
import autocompleteHelper from '../../../../utils/helpers/component/autocomplete.helpers';
import dateHelpers from '../../../../utils/helpers/date.helpers';
import formatHelp from '../../../../utils/helpers/format.helpers';
import objHelper from '../../../../utils/helpers/object.helper';

import { styles } from './TableHeadSearchPurchaseDelivery.styles';

class TableHeadSearchPurchaseDelivery extends Component {
  constructor(props) {
    super(props);

    const { t } = props;

    this.state = {
      searchParams: initialPurchaseDeliverySearch(t),
      focusedInputName: null,
    };

    this.searchPurchaseDeliveryTimeoutID = null;

    this.handleClearSingleFilter = this.handleClearSingleFilter.bind(this);

    // handling search by input
    this.handleOnChangeInput = this.handleOnChangeInput.bind(this);
    this.handleOnOpenAutocomplete = this.handleOnOpenAutocomplete.bind(this);
    this.handleOnCloseAutocomplete = this.handleOnCloseAutocomplete.bind(this);
    this.handleOnScrollAutocomplete = this.handleOnScrollAutocomplete.bind(this);
    this.handleChangeAutocomplete = this.handleChangeAutocomplete.bind(this);
  }

  async handleClearSingleFilter(event) {
    const { name } = event.currentTarget;

    const { searchParams: currSearchParams } = this.state;

    const newSearchParams = {
      ...currSearchParams,
      [name]: '',
    };

    this.setState((prevState) => ({
      ...prevState,
      searchParams: {
        ...newSearchParams,
      },
    }));

    const { purchase } = this.props;
    const { purchaseDeliveryData } = purchase;

    if (purchase.statusRequest === STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PENDING) {
      return;
    }

    const paginate = purchaseDeliveryData ? purchaseDeliveryData.per_page : 20;

    await this.props.getListPurchaseDelivery({
      paginate,
      ...newSearchParams,
    });
  }

  handleOnChangeDate(newValueDate) {
    const { searchParams: currSearchParams } = this.state;

    const isDateValueNotChanged = isEqual(
      newValueDate,
      objHelper.filterKeyObj(currSearchParams, [], columnsSearchWithDate),
    );

    if (isDateValueNotChanged) return;

    if (!isEmpty(newValueDate)) {
      const { from_date, to_date } = newValueDate;

      if (
        !dateHelpers.isValidDateV2(new Date(from_date)) ||
        !dateHelpers.isValidDateV2(new Date(to_date))
      ) {
        const { t } = this.props;

        this.props.setFailedMessage(
          t('dashboard.purchase.purchase-delivery.message.invalid-date-format.primary'),
          t('dashboard.purchase.purchase-delivery.message.invalid-date-format.secondary'),
        );

        return;
      }
    }

    const newSearchParams = {
      ...currSearchParams,
      ...newValueDate,
    };

    this.setState((prevState) => ({
      ...prevState,
      searchParams: {
        ...newSearchParams,
      },
    }));

    if (this.searchPurchaseDeliveryTimeoutID) {
      clearTimeout(this.searchPurchaseDeliveryTimeoutID);
    }

    this.searchPurchaseDeliveryTimeoutID = setTimeout(async () => {
      const { purchase } = this.props;
      const { purchaseDeliveryData } = purchase;

      const paginate = purchaseDeliveryData ? purchaseDeliveryData.per_page : 20;

      return await this.props.getListPurchaseDelivery({
        paginate,
        ...newSearchParams,
      });
    }, 750);
  }

  handleOnChangeInput(event) {
    const { name, value } = event.target;

    const { searchParams: currSearchParams } = this.state;

    const newSearchParams = {
      ...currSearchParams,
      [name]: value,
    };

    this.setState((prevState) => ({
      ...prevState,
      searchParams: {
        ...newSearchParams,
      },
      focusedInputName: name,
    }));

    if (this.searchPurchaseDeliveryTimeoutID) {
      clearTimeout(this.searchPurchaseDeliveryTimeoutID);
    }

    this.searchPurchaseDeliveryTimeoutID = setTimeout(async () => {
      const { purchase } = this.props;
      const { purchaseDeliveryData } = purchase;

      const paginate = purchaseDeliveryData ? purchaseDeliveryData.per_page : 20;

      return await this.props.getListPurchaseDelivery({
        paginate,
        ...newSearchParams,
      });
    }, 750);

    if (name === 'supplier_name') {
      const { value } = event.target;

      const { contact } = this.props;

      if (contact.statusRequest === STATUS_REQUEST_LIST_CONTACT_DATA_PENDING) {
        return;
      }

      if (this.searchPurchaseDeliveryTimeoutID) {
        clearTimeout(this.searchPurchaseDeliveryTimeoutID);
      }

      this.searchPurchaseDeliveryTimeoutID = setTimeout(async () => {
        return await this.props.getListContact({
          ...TERSIER_DEFAULT_LIST_PARAMS,
          type: ['is_supplier'],
          contact_name: value,
        });
      }, 750);
    }
  }

  async handleOnOpenAutocomplete(event, name) {
    event.preventDefault();
    const { contact } = this.props;

    if (
      name === 'supplier_name' &&
      contact.statusRequest !== STATUS_REQUEST_LIST_CONTACT_DATA_PENDING
    ) {
      await this.props.getListContact({
        ...TERSIER_DEFAULT_LIST_PARAMS,
        type: ['is_supplier'],
      });
    }
  }

  async handleOnCloseAutocomplete(event, name) {
    if (name === 'supplier_name') {
      await this.props.clearContactData();
    }
  }

  async handleOnScrollAutocomplete(name) {
    if (name === 'supplier_name') {
      const { contact } = this.props;
      const { contactData } = contact;

      if (!contactData) return;

      const {
        current_page: page,
        next_page_url,
        per_page: paginate,
        search_contact_name: contact_name,
      } = contactData;

      if (!next_page_url) return;

      await this.props.getListContact({
        page: page + 1,
        paginate,
        type: ['is_supplier'],
        contact_name,
        isRewriteAll: false,
      });
    }
  }

  async handleChangeAutocomplete(event, newValue, primaryName, secondaryName, keySecondary) {
    event.preventDefault();

    const { searchParams: currSearchParams } = this.state;

    if (primaryName === 'supplier_name_container') {
      let newSearchParams = {
        ...currSearchParams,
        [primaryName]: newValue,
        [secondaryName]: !isEmpty(newValue) ? newValue.contact_name : '',
      };

      this.setState((prevState) => ({
        ...prevState,
        searchParams: {
          ...newSearchParams,
        },
      }));

      const { purchase } = this.props;
      const { purchaseDeliveryData } = purchase;

      const paginate = purchaseDeliveryData ? purchaseDeliveryData.per_page : 20;

      return await this.props.getListPurchaseDelivery({
        paginate,
        ...newSearchParams,
      });
    }

    // check is current params is contained 'all' status
    // when current params contained and incoming data contained another status, then change into incoming data
    // check is new value is contained 'all' status
    // when contained change new value into 'all' status

    if (!isEmpty(currSearchParams[primaryName])) {
      const isCurrentStatusContainValueAll =
        currSearchParams[primaryName].some((item) => item.value === 'all') && newValue.length > 1;

      const isNewValueContainedValueAll = newValue.some((item) => item.value === 'all');

      if (isCurrentStatusContainValueAll) {
        newValue = newValue.filter((item) => item.value !== 'all');
      } else if (isNewValueContainedValueAll) {
        newValue = newValue.filter((item) => item.value === 'all');
      }
    }

    const newSelectedValue = keySecondary ? newValue.map((value) => value[keySecondary]) : newValue;

    let newSearchParams = {
      ...currSearchParams,
      [primaryName]: newValue,
      [secondaryName]: newSelectedValue,
    };

    // remove value 'all' if exist on status
    if (newSearchParams.status.includes('all')) {
      newSearchParams = {
        ...newSearchParams,
        status: [],
      };
    }

    // remove value 'all' if exist on purchase_handover_status
    if (newSearchParams.purchase_handover_status.includes('all')) {
      newSearchParams = {
        ...newSearchParams,
        purchase_handover_status: [],
      };
    }

    this.setState((prevState) => ({
      ...prevState,
      searchParams: {
        ...newSearchParams,
      },
    }));

    const { purchase } = this.props;
    const { purchaseDeliveryData } = purchase;

    const paginate = purchaseDeliveryData ? purchaseDeliveryData.per_page : 20;

    // only when status selected is 'all
    if (newSelectedValue.includes('all')) {
      let removingPurchaseStatusParams = objHelper.filterKeyObj(newSearchParams, ['status']);

      if (secondaryName === 'purchase_handover_status') {
        removingPurchaseStatusParams = objHelper.filterKeyObj(newSearchParams, [
          'purchase_handover_status',
        ]);
      }

      return await this.props.getListPurchaseDelivery({
        paginate,
        ...removingPurchaseStatusParams,
      });
    }

    return await this.props.getListPurchaseDelivery({
      paginate,
      ...newSearchParams,
    });
  }

  render() {
    const { searchParams, focusedInputName } = this.state;

    const { t, i18n, classes, dataHeaders, purchase, contact } = this.props;

    const { language } = i18n;

    const isPurchaseDeliveryLoading =
      purchase.statusRequest === STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PENDING;

    const { contactData } = contact;

    // value for status autocomplete
    let statusContainerValue = searchParams['statusContainer'],
      statusBastContainerValue = searchParams['purchase_handover_status_container'],
      supplier_name_container = searchParams['supplier_name_container'];

    // option list for status autocomplete
    let purchaseDeliveryStatusList = purchaseDeliveryStatus(t),
      bastStatusList = minutesHandoverStatus(t),
      contactDataList = [];

    // set temporary status purchase order for handle change translation from user
    const valueOnCurrentStatusContainer = arrHelp.mapWithExistedValue(
        statusContainerValue,
        'value',
      ),
      valueOnCurrentStatusBastContainer = arrHelp.mapWithExistedValue(
        statusBastContainerValue,
        'value',
      );

    const tempStatusPurchaseDeliveryContainer = purchaseDeliveryStatusList.filter((status) => {
      if (valueOnCurrentStatusContainer.includes(status.value)) return status;
    });

    const tempStatusBastContainer = bastStatusList.filter((status) => {
      if (valueOnCurrentStatusBastContainer.includes(status.value)) return status;
    });

    let paginateContactData = TERSIER_DEFAULT_LIST_PARAMS.paginate;

    if (contactData) {
      if (Array.isArray(contactData.data)) {
        contactDataList = contactData.data;
      }

      paginateContactData = contactData.per_page;
    }

    return (
      <TableHead className={classes.TableHeadMainContainer}>
        <TableRow>
          {dataHeaders &&
            dataHeaders.map((dataHeader, index) => {
              if (!dataHeader.name_input) {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{
                      verticalAlign: 'top',
                      alignItems: 'flex-end',
                    }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                  </TableCell>
                );
              }

              // table header for date
              if (columnsSearchWithDate.includes(dataHeader.name_input)) {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{ width: dataHeader.width }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                    <DateTimePicker
                      startDate={searchParams['from_date']}
                      endDate={searchParams['to_date']}
                      textCancel={t('button.cancel')}
                      textApply={t('button.apply')}
                      placeHolderInput={dataHeader.placeholder}
                      onSelectedChange={(value) => this.handleOnChangeDate(value)}
                      locale={language}
                      defaultKeyStartDate={'from_date'}
                      defaultKeyEndDate={'to_date'}
                      isDisabledInput={isPurchaseDeliveryLoading}
                    />
                  </TableCell>
                );
              }

              // table header for status
              if (dataHeader.name_input === 'status') {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{ width: dataHeader.width }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        size='small'
                        id='multiple-limit-tages-status-purchase'
                        name={dataHeader.name_input}
                        limitTags={1}
                        className={classes.containerAutocomplete}
                        value={tempStatusPurchaseDeliveryContainer}
                        options={purchaseDeliveryStatusList}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option === value}
                        onChange={(event, newValue) => {
                          this.handleChangeAutocomplete(
                            event,
                            newValue,
                            'statusContainer',
                            'status',
                            'value',
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={
                              isEmpty(statusContainerValue) ? dataHeader.placeholder : ''
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                );
              }

              if (dataHeader.name_input === 'purchase_handover_status') {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{ width: dataHeader.width }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        size='small'
                        id='multiple-limit-tages-status-purchase'
                        name={dataHeader.name_input}
                        limitTags={1}
                        className={classes.containerAutocomplete}
                        value={tempStatusBastContainer}
                        options={bastStatusList}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option === value}
                        onChange={(event, newValue) => {
                          this.handleChangeAutocomplete(
                            event,
                            newValue,
                            'purchase_handover_status_container',
                            'purchase_handover_status',
                            'value',
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={
                              isEmpty(statusBastContainerValue) ? dataHeader.placeholder : ''
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                );
              }

              // table header for supplier
              if (dataHeader.name_input === 'supplier_name') {
                return (
                  <TableCell
                    key={index}
                    align={dataHeader.align}
                    style={{ width: dataHeader.width }}
                    className={classes.tableCellMain}
                  >
                    <p>{dataHeader.label}</p>
                    <FormControl>
                      <AutocompleteCustom
                        id='supplier_name'
                        name='supplier_name'
                        size='small'
                        sx={{ width: 300 }}
                        options={contactDataList}
                        value={supplier_name_container}
                        autoHighlight={true}
                        isPaginated={true}
                        totalOptions={autocompleteHelper.getOptionPaginatedAutocomplete(
                          contactDataList,
                          paginateContactData,
                          3,
                        )}
                        PopperComponent={PopperAutoWidth}
                        onOpen={(event) => this.handleOnOpenAutocomplete(event, 'supplier_name')}
                        onClose={(event) => this.handleOnCloseAutocomplete(event, 'supplier_name')}
                        onChange={(event, newValue) =>
                          this.handleChangeAutocomplete(
                            event,
                            newValue,
                            'supplier_name_container',
                            'supplier_name',
                            'contact_name',
                          )
                        }
                        ListboxProps={{
                          onScroll: (event) => {
                            // i need scroll into bottom view when api was hitted
                            const { offsetHeight, scrollHeight, scrollTop } = event.target;

                            const isEndOfScroll = offsetHeight + scrollTop === scrollHeight;

                            if (isEndOfScroll) {
                              this.handleOnScrollAutocomplete('supplier_name');
                            }
                          },
                        }}
                        getOptionLabel={(option) =>
                          autocompleteHelper.getInputValue(
                            option,
                            ['contact_code', 'contact_name'],
                            ['contact_name'],
                            formatHelp.contactCodeWithName,
                          )
                        }
                        isOptionEqualToValue={(option, value) =>
                          autocompleteHelper.isOptionEqualToValue(option, value, 'contact_id')
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled={isPurchaseDeliveryLoading}
                            name='supplier_name'
                            placeholder={t(
                              'dialog.base-add-update-purchase-order.input.supplier.placeholder',
                            )}
                            onChange={this.handleOnChangeInput}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <InputAdornmentLoading
                                  isLoading={
                                    focusedInputName === 'supplier_name' &&
                                    contact.statusRequest ===
                                      STATUS_REQUEST_LIST_CONTACT_DATA_PENDING
                                  }
                                  defaultInputAdornment={params.InputProps.endAdornment}
                                />
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                );
              }

              return (
                <TableCell
                  key={index}
                  align={dataHeader.align}
                  style={{ width: dataHeader.width }}
                  className={classes.tableCellMain}
                >
                  <p>{dataHeader.label}</p>
                  <FormControl fullWidth>
                    <OutlinedInput
                      name={dataHeader.name_input}
                      autoFocus={dataHeader.name_input === focusedInputName}
                      size='small'
                      placeholder={dataHeader.placeholder}
                      onChange={this.handleOnChangeInput}
                      value={searchParams[dataHeader.name_input]}
                      {...(searchParams[dataHeader.name_input] && {
                        endAdornment: (
                          <InputAdornment
                            position='end'
                            className={classes.inputAdornmentClearSingleFilter}
                          >
                            {searchParams[dataHeader.name_input] && (
                              <IconButton
                                edge='end'
                                name={dataHeader.name_input}
                                className={classes.iconButtonContainerClear}
                                onClick={this.handleClearSingleFilter}
                              >
                                <Clear fontSize='10px' />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      })}
                    />
                  </FormControl>
                </TableCell>
              );
            })}
        </TableRow>
      </TableHead>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    purchase: state.purchase,
    contact: state.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListPurchaseDelivery: (paramsgetListPurchaseDelivery) =>
      dispatch(getListPurchaseDelivery(paramsgetListPurchaseDelivery)),
    getListContact: (paramsGetListContact) => dispatch(contactList(paramsGetListContact)),
    clearContactData: () => dispatch(clearContactData()),
    setFailedMessage: (primaryText, secondaryText) =>
      messageHelper.failedMessage(dispatch, primaryText, secondaryText, false),
  };
};

// add style into component
const stylingTableHeadSearchPurchaseDelivery = withStyles(styles)(TableHeadSearchPurchaseDelivery);

// add translation into component
const TableHeadPurchaseDeliveryTranslate = withTranslation()(
  stylingTableHeadSearchPurchaseDelivery,
);

// connect with style and action into component
const ConnectedTableHeadSearchPurchaseDelivery = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TableHeadPurchaseDeliveryTranslate);

export default ConnectedTableHeadSearchPurchaseDelivery;
