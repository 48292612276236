import {
  REACT_APP_API_URL_CREDIT_NOTE,
  REACT_APP_API_URL_CREDIT_NOTE_DETAILS,
  REACT_APP_API_URL_CREDIT_NOTE_HISTORY,
  REACT_APP_API_URL_PAYABLE_PAYMENT,
  REACT_APP_API_URL_PAYABLE_PAYMENT_DETAILS,
  REACT_APP_API_URL_PAYABLE_PAYMENT_HISTORY,
  REACT_APP_API_URL_SALES_DELIVERY,
  REACT_APP_API_URL_SALES_DELIVERY_AND_ORDER,
  REACT_APP_API_URL_SALES_DELIVERY_HISTORY,
  REACT_APP_API_URL_SALES_INVOICE,
  REACT_APP_API_URL_SALES_INVOICE_DETAILS_TEMPLATE,
  REACT_APP_API_URL_SALES_INVOICE_HISTORY,
  REACT_APP_API_URL_SALES_INVOICE_UPLOAD,
  REACT_APP_API_URL_SALES_INVOICE_UPLOAD_PROGRESS,
  REACT_APP_API_URL_SALES_ORDER,
  REACT_APP_API_URL_SALES_ORDER_HISTORY,
  REACT_APP_API_URL_SALES_PAYMENT,
  REACT_APP_API_URL_SALES_PAYMENT_HISTORY,
  REACT_APP_API_URL_SALES_QUOTATION,
  REACT_APP_API_URL_SALES_QUOTATION_HISTORY,
  REACT_APP_API_URL_SALES_RETURN,
  REACT_APP_API_URL_SALES_RETURN_DETAILS,
  REACT_APP_API_URL_SALES_RETURN_HISTORY,
} from '../../../utils/configs/api.config';
import { generalizeDataAddUpdateSalesQuotation } from '../../../utils/data/display/initial-add-sales-quotation';
import { DEFAULT_FILE_TYPE_CSV } from '../../../utils/default/file-type.default';
import {
  DEFAULT_ALLOW_PARAMS_SALES_CREDIT_NOTE_LIST,
  DEFAULT_ALLOW_PARAMS_SALES_PAYABLE_PAYMENT_LIST,
  DEFAULT_ALLOW_PARAMS_SALES_RETURN_DETAILS,
  DEFAULT_ALLOW_PARAMS_SALES_RETURN_LIST,
  DEFAULT_SALES_DELIVERY_AND_ORDER_LIST_PARAMS,
  DEFAULT_SALES_DELIVERY_LIST_PARAMS,
  DEFAULT_SALES_INVOICE_LIST_PARAMS,
  DEFAULT_SALES_ORDER_LIST_PARAMS,
  DEFAULT_SALES_PAYMENT_LIST_PARAMS,
  DEFAULT_SALES_QUOTATION_LIST_PARAMS,
} from '../../../utils/default/params.default';
import {
  creatorAddService,
  creatorDeleteService,
  creatorDeleteServiceWithTemplateUrl,
  creatorDetailService,
  creatorDetailServiceV2,
  creatorDetailServiceWithParams,
  creatorListService,
  creatorMultipartFormDataService,
  creatorUpdateService,
  creatorUpdateServiceWithTemplateURL,
} from '../creator-services/creator-services.helper';

/**
 *
 * sales quotation services
 *
 */

const getListSalesQuotation = async (paramsGetListSalesQuotation) => {
  return await creatorListService(
    REACT_APP_API_URL_SALES_QUOTATION,
    paramsGetListSalesQuotation,
    DEFAULT_SALES_QUOTATION_LIST_PARAMS,
    {},
  );
};

const getDetailSalesQuotation = async (salesQuotationID) => {
  return await creatorDetailService(REACT_APP_API_URL_SALES_QUOTATION, salesQuotationID);
};

const getHistorySalesQuotation = async (paramsGetSalesQuotationHistory) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_SALES_QUOTATION_HISTORY,
    paramsGetSalesQuotationHistory,
  );
};

const addSalesQuotation = async (bodyaddSalesQuotation) => {
  return creatorAddService(
    REACT_APP_API_URL_SALES_QUOTATION,
    bodyaddSalesQuotation,
    generalizeDataAddUpdateSalesQuotation,
  );
};

const updateSalesQuotation = async (bodyUpdateSalesQuotation) => {
  const { sales_quotation_id } = bodyUpdateSalesQuotation;

  return await creatorUpdateService(
    sales_quotation_id,
    REACT_APP_API_URL_SALES_QUOTATION,
    bodyUpdateSalesQuotation,
    generalizeDataAddUpdateSalesQuotation,
  );
};

const deleteSalesQuotation = async (paramsDeleteSalesQuotation) => {
  const { sales_quotation_id } = paramsDeleteSalesQuotation;

  return await creatorDeleteService(sales_quotation_id, REACT_APP_API_URL_SALES_QUOTATION);
};

/**
 *
 * sales order services
 *
 */

const getListSalesOrder = async (paramsGetListSalesOrder) => {
  return await creatorListService(
    REACT_APP_API_URL_SALES_ORDER,
    paramsGetListSalesOrder,
    DEFAULT_SALES_ORDER_LIST_PARAMS,
  );
};

const getDetailSalesOrder = async (paramsGetDetailSalesOrder) => {
  const { sales_order_id } = paramsGetDetailSalesOrder;

  return await creatorDetailService(REACT_APP_API_URL_SALES_ORDER, sales_order_id);
};

// params minimum include sales_order_id on key
const getSalesOrderHistoryDetails = async (paramsGetSalesDeliveryHitoryDetails) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_SALES_ORDER_HISTORY,
    paramsGetSalesDeliveryHitoryDetails,
  );
};

const addSalesOrder = async (bodyAddSalesOrder) => {
  return await creatorAddService(REACT_APP_API_URL_SALES_ORDER, bodyAddSalesOrder);
};

const updateSalesOrder = async (bodyUpdateSalesOrder) => {
  const { sales_order_id } = bodyUpdateSalesOrder;

  return await creatorUpdateService(
    sales_order_id,
    REACT_APP_API_URL_SALES_ORDER,
    bodyUpdateSalesOrder,
  );
};

const deleteSalesOrder = async (paramsDeleteSalesOrder) => {
  const { sales_order_id } = paramsDeleteSalesOrder;

  return await creatorDeleteService(sales_order_id, REACT_APP_API_URL_SALES_ORDER);
};

/**
 *
 * sales delivery services
 *
 */

const getListSalesDelivery = async (paramsGetListSalesDelivery) => {
  return await creatorListService(
    REACT_APP_API_URL_SALES_DELIVERY,
    paramsGetListSalesDelivery,
    DEFAULT_SALES_DELIVERY_LIST_PARAMS,
  );
};

const getSalesDeliveryDetails = async (paramsGetSalesDeliveryDetails) => {
  const { sales_delivery_id } = paramsGetSalesDeliveryDetails;

  return await creatorDetailService(REACT_APP_API_URL_SALES_DELIVERY, sales_delivery_id);
};

// params minimum include sales_delivery_id on key
const getSalesDeliveryHistoryDetails = async (paramsGetSalesDeliveryHitoryDetails) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_SALES_DELIVERY_HISTORY,
    paramsGetSalesDeliveryHitoryDetails,
  );
};

const addSalesDelivery = async (bodyAddSalesDelivery) => {
  return await creatorAddService(REACT_APP_API_URL_SALES_DELIVERY, bodyAddSalesDelivery, null);
};

const updateSalesDelivery = async (bodyUpdateSalesDelivery) => {
  const { sales_delivery_id } = bodyUpdateSalesDelivery;

  return await creatorUpdateService(
    sales_delivery_id,
    REACT_APP_API_URL_SALES_DELIVERY,
    bodyUpdateSalesDelivery,
  );
};

const deleteSalesDelivery = async (paramsDeleteSalesDelivery) => {
  const { sales_delivery_id } = paramsDeleteSalesDelivery;

  return await creatorDeleteService(sales_delivery_id, REACT_APP_API_URL_SALES_DELIVERY);
};

const getListSalesDeliveryAndOrder = async (paramsGetListSalesDeliveryAndOrder) => {
  return await creatorListService(
    REACT_APP_API_URL_SALES_DELIVERY_AND_ORDER,
    paramsGetListSalesDeliveryAndOrder,
    DEFAULT_SALES_DELIVERY_AND_ORDER_LIST_PARAMS,
  );
};

/**
 *
 * sales invoice services
 *
 */

const getListSalesInvoice = async (paramsGetListSalesInvoice) => {
  return await creatorListService(
    REACT_APP_API_URL_SALES_INVOICE,
    paramsGetListSalesInvoice,
    DEFAULT_SALES_INVOICE_LIST_PARAMS,
  );
};

const getDetailSalesInvoice = async (params) => {
  return await creatorDetailServiceV2(REACT_APP_API_URL_SALES_INVOICE_DETAILS_TEMPLATE, params);
};

const getHistorySalesInvoice = async (paramsGetSalesInvoiceHistory) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_SALES_INVOICE_HISTORY,
    paramsGetSalesInvoiceHistory,
  );
};

const addSalesInvoice = async (bodyAddSalesInvoice) => {
  return creatorAddService(REACT_APP_API_URL_SALES_INVOICE, bodyAddSalesInvoice, null);
};

const importSalesInvoice = async (body) => {
  return await creatorMultipartFormDataService({
    endpoint: REACT_APP_API_URL_SALES_INVOICE_UPLOAD,
    bodyData: body,
    grabToNewFormData: true,
    formDataKeyname: DEFAULT_FILE_TYPE_CSV,
  });
};

const getImportSalesInvoiceProgress = async (params) => {
  return await creatorDetailServiceV2(REACT_APP_API_URL_SALES_INVOICE_UPLOAD_PROGRESS, params);
};

const updateSalesInvoice = async (bodyUpdateSalesInvoice) => {
  const { sales_invoice_id } = bodyUpdateSalesInvoice;

  return await creatorUpdateService(
    sales_invoice_id,
    REACT_APP_API_URL_SALES_INVOICE,
    bodyUpdateSalesInvoice,
    null,
  );
};

/**
 *
 * sales payment services
 *
 */

const getListSalesPayment = async (paramsGetListSalesPayment) => {
  return await creatorListService(
    REACT_APP_API_URL_SALES_PAYMENT,
    paramsGetListSalesPayment,
    DEFAULT_SALES_PAYMENT_LIST_PARAMS,
  );
};

const getDetailSalesPayment = async (salesPaymentID) => {
  return await creatorDetailService(REACT_APP_API_URL_SALES_PAYMENT, salesPaymentID);
};

const getHistorySalesPayment = async (paramsGetHistorySalesPayment) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_SALES_PAYMENT_HISTORY,
    paramsGetHistorySalesPayment,
  );
};

const addSalesPayment = async (bodyAddSalesPayment) => {
  return await creatorAddService(REACT_APP_API_URL_SALES_PAYMENT, bodyAddSalesPayment);
};

const updateSalesPayment = async (bodyUpdateSalesPayment) => {
  const { sales_payment_id } = bodyUpdateSalesPayment;

  return await creatorUpdateService(
    sales_payment_id,
    REACT_APP_API_URL_SALES_PAYMENT,
    bodyUpdateSalesPayment,
  );
};

const deleteSalesInvoice = async (paramsDeleteSalesInvoice) => {
  const { sales_invoice_id } = paramsDeleteSalesInvoice;

  return await creatorDeleteService(sales_invoice_id, REACT_APP_API_URL_SALES_INVOICE);
};

const deleteSalesPayment = async (paramsDeleteSalesPayment) => {
  const { sales_payment_id } = paramsDeleteSalesPayment;

  return await creatorDeleteService(sales_payment_id, REACT_APP_API_URL_SALES_PAYMENT);
};

/**
 *
 * sales return services
 *
 */

const getSalesReturnList = async (params) => {
  return await creatorListService(
    REACT_APP_API_URL_SALES_RETURN,
    params,
    DEFAULT_ALLOW_PARAMS_SALES_RETURN_LIST,
  );
};

const getSalesReturnDetails = async (params) => {
  return await creatorDetailServiceWithParams({
    url: REACT_APP_API_URL_SALES_RETURN_DETAILS,
    params,
    identityToCombineOnUrl: params,
    allowParams: DEFAULT_ALLOW_PARAMS_SALES_RETURN_DETAILS,
  });
};

const getSalesReturnHistory = async (params) => {
  return await creatorDetailServiceV2(REACT_APP_API_URL_SALES_RETURN_HISTORY, params);
};

const createSalesReturn = async (bodyCreateSalesReturn) => {
  return await creatorAddService(REACT_APP_API_URL_SALES_RETURN, bodyCreateSalesReturn);
};

const updateSalesReturn = async (bodyUpdateSalesReturn) => {
  return await creatorUpdateServiceWithTemplateURL(
    REACT_APP_API_URL_SALES_RETURN_DETAILS,
    bodyUpdateSalesReturn,
  );
};

const deleteSalesReturn = async (paramsDeleteSalesReturn) => {
  return await creatorDeleteServiceWithTemplateUrl(
    REACT_APP_API_URL_SALES_RETURN_DETAILS,
    paramsDeleteSalesReturn,
  );
};

/**
 *
 * sales credit note services
 *
 */

const getSalesCreditNoteList = async (params) => {
  return await creatorListService(
    REACT_APP_API_URL_CREDIT_NOTE,
    params,
    DEFAULT_ALLOW_PARAMS_SALES_CREDIT_NOTE_LIST,
    {},
  );
};

const getSalesCreditNoteDetails = async (params) => {
  return await creatorDetailServiceV2(REACT_APP_API_URL_CREDIT_NOTE_DETAILS, params);
};

const getSalesCreditNoteHistory = async (params) => {
  return await creatorDetailServiceV2(REACT_APP_API_URL_CREDIT_NOTE_HISTORY, params);
};

/**
 *
 * sales payable payment services
 *
 */

const getSalesPayablePaymentList = async (params) => {
  return await creatorListService(
    REACT_APP_API_URL_PAYABLE_PAYMENT,
    params,
    DEFAULT_ALLOW_PARAMS_SALES_PAYABLE_PAYMENT_LIST,
  );
};

const getSalesPayablePaymentDetails = async (params) => {
  return await creatorDetailServiceV2(REACT_APP_API_URL_PAYABLE_PAYMENT_DETAILS, params);
};

const getSalesPayablePaymentHistory = async (params) => {
  return await creatorDetailServiceV2(REACT_APP_API_URL_PAYABLE_PAYMENT_HISTORY, params);
};

const createSalesPayablePayment = async (body) => {
  return await creatorAddService(REACT_APP_API_URL_PAYABLE_PAYMENT, body);
};

const updateSalesPayablePayment = async (body) => {
  return await creatorUpdateServiceWithTemplateURL(REACT_APP_API_URL_PAYABLE_PAYMENT_DETAILS, body);
};

const deleteSalesPayablePayment = async (params) => {
  return await creatorDeleteServiceWithTemplateUrl(
    REACT_APP_API_URL_PAYABLE_PAYMENT_DETAILS,
    params,
  );
};

const salesServices = {
  getListSalesQuotation,
  getDetailSalesQuotation,
  getHistorySalesQuotation,
  addSalesQuotation,
  updateSalesQuotation,
  deleteSalesQuotation,
  getListSalesOrder,
  getDetailSalesOrder,
  getSalesOrderHistoryDetails,
  addSalesOrder,
  updateSalesOrder,
  deleteSalesOrder,
  getListSalesInvoice,
  getDetailSalesInvoice,
  addSalesInvoice,
  importSalesInvoice,
  getImportSalesInvoiceProgress,
  updateSalesInvoice,
  deleteSalesInvoice,
  getListSalesDeliveryAndOrder,
  getListSalesDelivery,
  getSalesDeliveryHistoryDetails,
  getSalesDeliveryDetails,
  addSalesDelivery,
  updateSalesDelivery,
  deleteSalesDelivery,
  getListSalesPayment,
  getDetailSalesPayment,
  getHistorySalesPayment,
  addSalesPayment,
  updateSalesPayment,
  deleteSalesPayment,
  getHistorySalesInvoice,
  getSalesReturnList,
  getSalesReturnDetails,
  getSalesReturnHistory,
  createSalesReturn,
  updateSalesReturn,
  deleteSalesReturn,
  getSalesCreditNoteList,
  getSalesCreditNoteDetails,
  getSalesCreditNoteHistory,
  getSalesPayablePaymentList,
  getSalesPayablePaymentDetails,
  getSalesPayablePaymentHistory,
  createSalesPayablePayment,
  updateSalesPayablePayment,
  deleteSalesPayablePayment,
};

export default salesServices;
