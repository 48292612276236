import * as React from 'react';
function SvgIconMenuSalesSalesDelivery(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.857 68.786a.429.429 0 11-.857 0 .429.429 0 01.857 0zM6.857 68.357h58.286a.429.429 0 010 .857H6.857a.429.429 0 010-.857zM2.571 68.357h2.143a.429.429 0 010 .857H2.571a.429.429 0 010-.857zM69.429 68.357h-2.143a.428.428 0 000 .857h2.143a.429.429 0 000-.857zM72 68.786a.429.429 0 11-.857 0 .429.429 0 01.857 0z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M38.4 63.107h15.536a7.325 7.325 0 00-.023.505c0 .9.166 1.792.489 2.618a6.584 6.584 0 001.386 2.18h8.007a6.586 6.586 0 001.386-2.18 7.203 7.203 0 00.489-2.618c0-.17-.012-.338-.023-.505h2.243a1.776 1.776 0 001.314-.623c.347-.395.54-.926.535-1.479V49.972a11.255 11.255 0 00-.858-3.962l-3.368-7.31a3.585 3.585 0 00-1.143-1.313 3.105 3.105 0 00-1.564-.549H51.2v-5.615c-.01-1.068-.4-2.088-1.084-2.835-.684-.747-1.605-1.16-2.56-1.148h-32.05c-.956-.011-1.877.402-2.56 1.148-.684.747-1.075 1.767-1.085 2.835v8.234h1.808v-8.234a2.126 2.126 0 01.556-1.405 1.7 1.7 0 011.28-.557h13.99V40.8h1.81V29.26h8.139v18.692H36v2.02h13.391v11.114H38.4v2.02zm10.436-33.289c.344.368.544.873.555 1.405v16.729h-8.139V29.26h6.303a1.7 1.7 0 011.281.557zm5.985 9.04h3.84v9.086l-2.854.008h-.003a.888.888 0 01-.666-.29 1.108 1.108 0 01-.29-.73l-.027-8.073zm9.084.767l3.368 7.31c.138.316.25.645.336.983l-7.14.02v-9.08h2.337c.22.037.432.123.621.255.19.132.352.306.478.511zm3.985 21.46h-2.672c-.447-1.196-1.2-2.218-2.166-2.937a5.45 5.45 0 00-3.26-1.103 5.45 5.45 0 00-3.261 1.103c-.966.719-1.72 1.741-2.166 2.938H51.2V38.859h1.812l.027 8.081c.01.811.305 1.585.823 2.153.518.568 1.216.884 1.942.88h.007l12.118-.035v.016l.002.018v3.536h-10.4a.86.86 0 00-.64.296c-.17.19-.265.447-.265.715 0 .268.096.525.265.714.17.19.4.296.64.296h10.4v5.476c0 .06-.034.08-.04.08zm-10.36-1.254c.67-.5 1.457-.766 2.262-.766 1.079.002 2.113.481 2.876 1.334.763.852 1.192 2.008 1.193 3.213 0 .899-.239 1.778-.686 2.526a4.204 4.204 0 01-1.826 1.674 3.675 3.675 0 01-2.352.259 3.958 3.958 0 01-2.083-1.245 4.734 4.734 0 01-1.114-2.328 5.044 5.044 0 01.232-2.627c.308-.83.83-1.54 1.499-2.04z'
        fill='#000'
      />
      <path
        d='M13.342 47.093h1.182a.238.238 0 00.167-.069.231.231 0 00.069-.164c0-.258-.278-.247-.552-.237-.053.002-.106.004-.157.004a1.19 1.19 0 01-.793-.3 1.152 1.152 0 01-.16-1.555c.175-.233.43-.393.717-.45v-.49c0-.123.05-.242.138-.33a.476.476 0 01.668 0 .462.462 0 01.139.33v.466h.472c.126 0 .246.049.335.136a.463.463 0 010 .659.476.476 0 01-.335.136h-1.18a.24.24 0 00-.168.069.231.231 0 000 .33.238.238 0 00.167.067h.473c.293 0 .575.107.793.3a1.152 1.152 0 01.16 1.555 1.183 1.183 0 01-.717.451v.49c0 .123-.05.241-.139.328a.476.476 0 01-.668 0 .462.462 0 01-.138-.329v-.466h-.473a.476.476 0 01-.334-.136.462.462 0 010-.659.476.476 0 01.335-.136z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.035 48.16c2.126 4.453 8.977 2.948 8.977-1.999 0-5.282-7.46-6.4-9.175-1.556a4.597 4.597 0 00.198 3.554zm.515-2.409c.08-.716.37-1.393.833-1.95a3.781 3.781 0 011.776-1.19 3.832 3.832 0 012.146-.044c.705.19 1.339.578 1.825 1.115.486.538.803 1.204.914 1.916.11.712.009 1.44-.292 2.097a3.743 3.743 0 01-1.403 1.602 3.82 3.82 0 01-2.062.59c-.53 0-1.055-.111-1.54-.325a3.777 3.777 0 01-1.272-.917c-.354-.39-.62-.85-.782-1.348a3.677 3.677 0 01-.157-1.546h.014z'
        fill='#000'
      />
      <path
        d='M33.717 51.694a1.428 1.428 0 00-1.002-.409h-.945c-.376 0-.737.147-1.003.41a1.388 1.388 0 00-.415.987v13.975c0 .37.15.726.415.988.266.262.627.41 1.003.41h.945c.376 0 .736-.148 1.002-.41s.415-.618.415-.988v-6.522a.462.462 0 00-.334-.445.478.478 0 00-.472.116.462.462 0 00-.139.33v6.52c0 .124-.05.243-.138.33a.476.476 0 01-.334.137h-.945a.476.476 0 01-.334-.137.462.462 0 01-.139-.33V52.683c0-.123.05-.242.139-.329a.476.476 0 01.334-.136h.945c.125 0 .245.049.334.136a.462.462 0 01.138.33v3.26c0 .124.05.242.139.33a.476.476 0 00.668 0 .462.462 0 00.138-.33v-3.26c0-.371-.15-.727-.415-.989z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.045 54.546H26.1c-.376 0-.737.147-1.003.409a1.388 1.388 0 00-.415.988v11.18c0 .37.15.726.416.988.265.262.626.409 1.002.409h.945c.376 0 .736-.147 1.002-.41.266-.261.415-.617.415-.987v-11.18c0-.37-.15-.726-.415-.988a1.428 1.428 0 00-1.002-.41zm.472 12.577c0 .123-.05.242-.138.329a.476.476 0 01-.334.136H26.1a.476.476 0 01-.44-.294.463.463 0 01-.033-.171v-11.18c0-.123.05-.242.139-.33a.476.476 0 01.334-.136h.945c.125 0 .245.05.334.137a.462.462 0 01.138.33v11.179zM20.43 57.34h.945c.376 0 .736.148 1.002.41s.415.617.415.988v8.385c0 .37-.149.726-.415.988s-.626.409-1.002.409h-.945c-.376 0-.736-.147-1.002-.41a1.387 1.387 0 01-.416-.987v-8.385c0-.37.15-.726.416-.988.265-.262.626-.41 1.002-.41zm1.279 10.112a.463.463 0 00.138-.33v-8.384a.463.463 0 00-.138-.33.476.476 0 00-.334-.136h-.945a.476.476 0 00-.334.137.463.463 0 00-.139.329v8.385c0 .123.05.242.139.329a.476.476 0 00.334.136h.945c.125 0 .245-.049.334-.136zM15.705 60.135h-.945c-.376 0-.736.148-1.002.41a1.388 1.388 0 00-.415.988v5.59c0 .37.149.726.415.988s.626.409 1.002.409h.945c.376 0 .736-.147 1.002-.41.266-.261.415-.617.415-.987v-5.59c0-.37-.149-.726-.415-.988a1.429 1.429 0 00-1.002-.41zm.472 6.988c0 .123-.05.242-.138.329a.476.476 0 01-.334.136h-.945a.476.476 0 01-.334-.136.463.463 0 01-.139-.33v-5.59c0-.123.05-.241.139-.328a.476.476 0 01.334-.137h.945c.125 0 .245.05.334.136a.462.462 0 01.138.33v5.59zM10.035 62.93H9.09c-.376 0-.736.148-1.002.41a1.388 1.388 0 00-.415.988v2.795c0 .37.149.726.415.988s.626.409 1.002.409h.945c.376 0 .736-.147 1.002-.41.266-.261.415-.617.415-.987v-2.795c0-.37-.149-.726-.415-.988a1.428 1.428 0 00-1.002-.41zm.473 4.193a.487.487 0 01-.011.098.465.465 0 01-.128.231.476.476 0 01-.334.136H9.09a.476.476 0 01-.334-.136.463.463 0 01-.139-.33v-2.794c0-.124.05-.242.139-.33a.476.476 0 01.334-.136h.945a.476.476 0 01.437.29l.008.02.005.015a.486.486 0 01.023.14v2.796z'
        fill='#000'
      />
      <path
        d='M33.187 58.272v-.466c0-.123.05-.242.139-.329a.476.476 0 01.668 0 .463.463 0 01.138.33v.465c0 .124-.05.242-.138.33a.476.476 0 01-.668 0 .463.463 0 01-.139-.33zM31.908 43.503a.476.476 0 01.334-.137c1.396 0 1.987-.101 2.228.14.242.24.135.822.135 2.19a.46.46 0 01-.139.329.476.476 0 01-.668 0 .463.463 0 01-.138-.33v-.754l-2.523 2.44C25.117 53.21 17.43 56.41 7.673 56.41a.476.476 0 01-.335-.136.462.462 0 010-.66.476.476 0 01.335-.136c9.45 0 16.92-3.07 22.802-8.757l2.5-2.422h-.733a.476.476 0 01-.334-.137.462.462 0 010-.658z'
        fill='#000'
      />
    </svg>
  );
}
export default SvgIconMenuSalesSalesDelivery;
