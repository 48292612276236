import {
  CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_CUSTOMER_TYPE,
  CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_PRODUCT_CATEGORY_TYPE,
  CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_PRODUCT_TYPE,
  CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_SALES_AGENT_TYPE,
  CHART_PRO_DASHBOARD_TOP_SALES_CUSTOMER_TYPE,
  CHART_PRO_DASHBOARD_TOP_SALES_PRODUCT_CATEGORY_TYPE,
  CHART_PRO_DASHBOARD_TOP_SALES_PRODUCT_TYPE,
  CHART_PRO_DASHBOARD_TOP_SALES_SALES_AGENT_TYPE,
} from '../../default/chart-dashboard.default';

/**
 *
 * @returns
 *      sales invoice status that already translate for label
 *
 *      pair label, value, and colorChip
 *      where is label for displaying on user
 *      - value for passing on configuration
 *      - colorChip is for adding color on chip related to theme
 */
export const chartTopSalesSubType = [
  {
    label: 'label.product',
    value: CHART_PRO_DASHBOARD_TOP_SALES_PRODUCT_TYPE,
  },
  {
    label: 'label.sales-agent',
    value: CHART_PRO_DASHBOARD_TOP_SALES_SALES_AGENT_TYPE,
  },
  {
    label: 'label.customer',
    value: CHART_PRO_DASHBOARD_TOP_SALES_CUSTOMER_TYPE,
  },
  {
    label: 'label.product-category',
    value: CHART_PRO_DASHBOARD_TOP_SALES_PRODUCT_CATEGORY_TYPE,
  },
];

/**
 *
 * @returns
 *      sales invoice status that already translate for label
 *
 *      pair label, value, and colorChip
 *      where is label for displaying on user
 *      - value for passing on configuration
 *      - colorChip is for adding color on chip related to theme
 */
export const chartTopSalesComparisonSubType = [
  {
    label: 'label.product',
    value: CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_PRODUCT_TYPE,
  },
  {
    label: 'label.sales-agent',
    value: CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_SALES_AGENT_TYPE,
  },
  {
    label: 'label.customer',
    value: CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_CUSTOMER_TYPE,
  },
  {
    label: 'label.product-category',
    value: CHART_PRO_DASHBOARD_TOP_SALES_COMPARISON_PRODUCT_CATEGORY_TYPE,
  },
];
