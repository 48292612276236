import { FORMAT_PERIOD_DATE_COLUMN } from '../../../components/table/TableKeyValuePureMarkup/TableKeyValuePureMarkup';
import {
  FORMAT_COMMON_COLUMN,
  FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
  FORMAT_DATE_COLUMN,
} from '../../../components/table-row/TableRowPureMarkup/TableRowPureMarkup';

import {
  primaryTableDataGreyBackgroundNgreyTopBottomBorder,
  secondarySubtitleCalculationNumberParagraph,
} from './additional-styles';

// column purchase payment list that handling table head with translation
export const columnsTableHeaderPurchasePayment = (t) => {
  return [
    {
      name: 'transaction_date',
      name_input: 'from_date',
      label: t('dashboard.purchase.purchase-payment.table.header-column.transaction-date'),
      placeholder: t(
        'dashboard.purchase.purchase-payment.input.search-transaction-date.placeholder',
      ),
      width: 200,
    },
    {
      name: 'purchase_payment_no',
      name_input: 'transaction_no',
      label: t('dashboard.purchase.purchase-payment.table.header-column.transaction-no'),
      placeholder: t('dashboard.purchase.purchase-payment.input.search-transaction-no.placeholder'),
      width: 200,
    },
    {
      name: 'supplier_name',
      name_input: 'supplier_name',
      label: t('dashboard.purchase.purchase-payment.table.header-column.supplier'),
      placeholder: t('dashboard.purchase.purchase-payment.input.search-supplier.placeholder'),
      width: 400,
      align: 'left',
    },
    {
      name: 'amount',
      label: t('dashboard.purchase.purchase-payment.table.header-column.nominal'),
      align: 'right',
      width: 150,
    },
  ];
};

export const columnsSearchWithDate = ['from_date', 'to_date'];

// column that representation for calculation
export const calculationColumns = ['amount'];

export const columnsTableFooterInvoicePurchasePaymentList = (t) => {
  return [
    [
      {
        isEmptyTableData: true,
        colSpan: 3,
      },
      {
        isLabel: true,
        label: t('typography.total'),
        typographyStyle: secondarySubtitleCalculationNumberParagraph,
        tableDataStyle: primaryTableDataGreyBackgroundNgreyTopBottomBorder,
      },
      {
        isValue: true,
        valueKeyName: 'amount',
        tableDataStyle: primaryTableDataGreyBackgroundNgreyTopBottomBorder,
        substituteNotExistValue: 0,
        typographyStyle: secondarySubtitleCalculationNumberParagraph,
      },
    ],
  ];
};

export const columnsTableHeaderPurchasePaymentPDFprint = (t) => {
  return [
    {
      name: 'transaction_date',
      label: t('dashboard.purchase.purchase-payment.table.header-column.transaction-date'),
      style: {
        width: 200,
        position: 'unset',
      },
      formatData: FORMAT_DATE_COLUMN,
    },
    {
      name: 'purchase_payment_no',
      label: t('dashboard.purchase.purchase-payment.table.header-column.transaction-no'),
      style: {
        width: 200,
        position: 'unset',
      },
      formatData: FORMAT_COMMON_COLUMN,
    },
    {
      name: 'supplier_name',
      label: t('dashboard.purchase.purchase-payment.table.header-column.supplier'),
      style: {
        width: 400,
        position: 'unset',
      },
      formatData: FORMAT_COMMON_COLUMN,
    },
    {
      name: 'amount',
      label: t('dashboard.purchase.purchase-payment.table.header-column.nominal'),
      style: {
        width: 150,
        position: 'unset',
      },
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      isUseZeroNumberWhenNull: true,
      align: 'right',
    },
  ];
};

// columns key value table
export const columnsTableKeyValuePrintIndexPurchasePayment = [
  {
    header: 'dashboard.purchase.purchase-payment.table.header-column.transaction-date',
    valueKeyName: 'search_from_date',
    valueKeyNameSecondary: 'search_to_date',
    formatValue: FORMAT_PERIOD_DATE_COLUMN,
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'dashboard.purchase.purchase-payment.table.header-column.transaction-no',
    valueKeyName: 'search_transaction_no',
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'dashboard.purchase.purchase-payment.table.header-column.supplier',
    valueKeyName: 'search_supplier_name',
    showOnlyWhenExistedValue: true,
  },
];
