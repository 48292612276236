import React, { Component } from 'react';
import { Skeleton, TableCell, TableRow } from '@mui/material';
import { PropTypes } from 'prop-types';

class TableRowLoading extends Component {
  render() {
    const { rowsAmount, columnsAmount, optionsSkeleton, optionsTableRow, tableCellProps } =
      this.props;

    if (!rowsAmount || !columnsAmount) return null;

    return [...Array(rowsAmount).keys()].map((data, rowIndex) => {
      return (
        <TableRow
          hover
          tabIndex={-1}
          role='checkbox'
          {...optionsTableRow}
          key={`table-row-loading-${data}-${rowIndex}`}
        >
          {[...Array(columnsAmount).keys()].map((col, index) => {
            return (
              <TableCell {...tableCellProps} key={`table-row-loading-table-data-${index}`}>
                <Skeleton variant='rectangular' width='100%' height={36} {...optionsSkeleton} />
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  }
}

TableRowLoading.propsTypes = {
  rowsAmount: PropTypes.number,
  columnsAmount: PropTypes.number,
  optionsSkeleton: PropTypes.object,
  optionsTableRow: PropTypes.object,
  tableCellProps: PropTypes.object,
};

TableRowLoading.defaultProps = {
  rowsAmount: 5,
  columnsAmount: 4,
  optionsSkeleton: {},
  optionsTableRow: {},
  tableCellProps: {},
};

export default TableRowLoading;
