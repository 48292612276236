import * as React from 'react';
function SvgFileText(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M11.666 1.667H5a1.667 1.667 0 00-1.667 1.666v13.334A1.667 1.667 0 005 18.333h10a1.667 1.667 0 001.666-1.666v-10m-5-5l5 5m-5-5v5h5m-3.333 4.166H6.666m6.667 3.334H6.666M8.333 7.5H6.666'
        stroke='#175CD3'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
export default SvgFileText;
