import * as React from 'react';
function SvgProfitLoss(props) {
  return (
    <svg
      width={49}
      height={48}
      viewBox='0 0 50 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g filter='url(#profit-loss_svg__filter0_d_5705_194570)'>
        <path
          d='M8.878 8.269a1.994 1.994 0 01-3.09 0L.333 26.998l1.92.56 5.08-17.443 5.08 17.445 1.92-.56L8.878 8.27zM42.878 8.269a1.998 1.998 0 01-3.09 0l-5.454 18.73 1.919.56 5.08-17.443 5.08 17.445 1.92-.56L42.878 8.27z'
          fill='#E6E9ED'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M41.333 9c.622 0 1.178-.285 1.545-.731L48.333 27l-1.92.56-5.08-17.445V9z'
          fill='#CFD3D9'
        />
        <path d='M7.334 33.999A6.999 6.999 0 0014.333 27h-14a7 7 0 007 6.999z' fill='#FFCE54' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.333 27a6.999 6.999 0 01-7 6.999V27h7z'
          fill='#FFBF1F'
        />
        <path d='M41.333 33.999a7 7 0 007-6.999h-14a6.999 6.999 0 007 6.999z' fill='#FFCE54' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M48.333 27a7 7 0 01-7 6.999V27h7z'
          fill='#FFBF1F'
        />
        <path
          d='M32.995 9c-.98.774-16.343.774-17.323 0h-2.956a15.95 15.95 0 0011.617 5c4.575 0 8.702-1.921 11.618-5h-2.956z'
          fill='#F6BB42'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M24.333 9.58c4.086 0 8.172-.193 8.662-.58h2.956a15.955 15.955 0 01-11.618 5V9.58z'
          fill='#F5A603'
        />
        <path d='M41.333 8H7.438v2h33.895V8z' fill='#FFCE54' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M41.333 10V8H24.385v2h16.948z'
          fill='#FFBF1F'
        />
        <path d='M25.334 1h-2.001v44.999h2V1z' fill='#434A54' />
        <path
          d='M24.333 0a3 3 0 00-3 3v13a2 2 0 002 2h2a2 2 0 002-2V3a3 3 0 00-3-3z'
          fill='#ED5564'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M24.333 0v18h1a2 2 0 002-2V3a3 3 0 00-3-3z'
          fill='#EF2D40'
        />
        <path
          d='M7.334 4a3 3 0 100 6 3 3 0 000-6zm0 4a1.001 1.001 0 010-2 1 1 0 010 2z'
          fill='#FFCE54'
        />
        <path
          d='M41.333 4a3 3 0 100 6 3 3 0 000-6zm0 4a1.001 1.001 0 010-2 1.001 1.001 0 010 2z'
          fill='#FFBF1F'
        />
        <path d='M28.334 36a4 4 0 00-4-3.999A4 4 0 0020.332 36v8h8v-8z' fill='#FFCE54' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M24.333 32.001a4 4 0 014 3.999v8h-4V32.002z'
          fill='#FFBF1F'
        />
        <path
          d='M38.333 44.001a2 2 0 00-2-2.001h-24a2 2 0 00-2 2.001v1.998a2 2 0 002 2.001h24a2 2 0 002-2.001v-1.998z'
          fill='#656D78'
        />
        <path d='M40.334 45.999H8.333a1 1 0 000 2.001h32a1 1 0 000-2.001z' fill='#434A54' />
      </g>
      <defs>
        <filter
          id='profit-loss_svg__filter0_d_5705_194570'
          x={-0.667}
          y={-1}
          width={56}
          height={56}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5705_194570' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5705_194570' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgProfitLoss;
