import * as React from 'react';
function SvgClose(props) {
  return (
    <svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fill='#fff' fillOpacity={0.01} d='M0 0h24v24H0z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.185 7.4L12 10.585 8.815 7.399a.507.507 0 00-.712.003l-.7.701a.496.496 0 00-.004.712l3.185 3.184L7.4 15.185a.507.507 0 00.004.712l.7.7c.206.207.516.2.712.004l3.186-3.185 3.184 3.185a.507.507 0 00.712-.004l.7-.7a.496.496 0 00.004-.712l-3.186-3.186 3.186-3.184a.507.507 0 00-.003-.712l-.7-.7a.507.507 0 00-.36-.153.5.5 0 00-.353.15h-.001z'
        fill='#42526E'
      />
    </svg>
  );
}
export default SvgClose;
