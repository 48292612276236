import { cloneDeep, isEmpty, isEqual, omit, pick } from 'lodash';

import { encryptData } from '../../services/modules/Crypto/Crypto.service';
import { getAccountList } from '../../store/account/account.action';
import { clearAccountData } from '../../store/account/account.slice';
import { listDetailsAttachment } from '../../store/attachment/attachment.action';
import { getListPurchaseInvoiceWithContactID } from '../../store/contact/contact.action';
import { contactList, getListSalesInvoiceWithContactID } from '../../store/contact/contact.action';
import {
  clearContactData,
  clearSalesInvoiceListWithContactID,
} from '../../store/contact/contact.slice';
import { clearPurchaseInvoceListWithContactID } from '../../store/contact/contact.slice';
import {
  addExpense,
  deleteExpense,
  getExpenseDetail,
  getExpenseListRefresh,
  updateExpense,
} from '../../store/expense/expense.action';
import { detailJournalEntry } from '../../store/journal-entry/journal-entry.action';
import { minutesOfHandoverDetails } from '../../store/minutes-of-handover/minutes-of-handover.action';
import { clearDataProductSlice } from '../../store/product/product.slice';
import {
  addPurchaseDeliveryV2,
  addPurchaseInvoiceV2,
  addPurchaseOrderV2,
  addPurchasePaymentV2,
  addQuotationRequestV2,
  createPurchaseReceivablePayment,
  createPurchaseReturn,
  deletePurchaseDelivery,
  deletePurchaseInvoice,
  deletePurchaseOrder,
  deletePurchasePayment,
  deletePurchaseReceivablePayment,
  deletePurchaseReturn,
  deleteQuotationRequest,
  detailPurchasePayment,
  getDetailPurchaseDelivery,
  getDetailPurchaseInvoice,
  getDetailPurchaseOrder,
  getDetailPurchaseOrderV2,
  getDetailQuotationRequest,
  getHistoryPurchaseInvoice,
  getHistoryPurchaseOrder,
  getHistoryPurchasePayment,
  getHistoryQuotationRequest,
  getListPurchaseDeliveryRefresh,
  getListPurchaseInvoice,
  getListPurchaseInvoiceRefresh,
  getListPurchaseOrder,
  getListPurchaseOrderRefresh,
  getListPurchasePaymentRefresh,
  getListQuotationRequest,
  getListQuotationRequestRefresh,
  getPurchaseDebitNoteDetails,
  getPurchaseDebitNoteHistory,
  getPurchaseDebitNoteList,
  getPurchaseDeliveryHistoryDetails,
  getPurchaseReceivablePaymentDetails,
  getPurchaseReceivablePaymentHistory,
  getPurchaseReturnDetails,
  getPurchaseReturnHistory,
  updatePurchaseDelivery,
  updatePurchaseInvoice,
  updatePurchaseOrder,
  updatePurchasePayment,
  updatePurchaseReceivablePayment,
  updatePurchaseReturn,
  updateQuotationRequest,
} from '../../store/purchase/purchase.action';
import {
  clearDataPurchaseSlice,
  clearPurchaseInvoiceData,
  clearPurchaseOrderData,
  clearQuotationRequestData,
} from '../../store/purchase/purchase.slice';
import { referenceNumberList } from '../../store/reference-number/reference-number.action';
import { clearReferenceNumber } from '../../store/reference-number/reference-number.slice';
import {
  addSalesDelivery,
  addSalesInvoiceV2,
  addSalesOrder,
  addSalesPayment,
  addSalesQuotation,
  createSalesPayablePayment,
  createSalesReturn,
  deleteSalesDelivery,
  deleteSalesInvoice,
  deleteSalesOrder,
  deleteSalesPayablePayment,
  deleteSalesPayment,
  deleteSalesQuotation,
  deleteSalesReturn,
  getDetailSalesInvoice,
  getDetailSalesOrder,
  getDetailSalesPayment,
  getDetailSalesQuotation,
  getHistorySalesInvoice,
  getHistorySalesPayment,
  getHistorySalesQuotation,
  getListSalesDeliveryRefresh,
  getListSalesInvoice,
  getListSalesInvoiceRefresh,
  getListSalesOrder,
  getListSalesOrderRefresh,
  getListSalesPaymentRefresh,
  getListSalesQuotation,
  getListSalesQuotationRefresh,
  getSalesCreditNoteDetails,
  getSalesCreditNoteHistory,
  getSalesCreditNoteList,
  getSalesDeliveryDetails,
  getSalesDeliveryHistoryDetails,
  getSalesOrderHistoryDetails,
  getSalesPayablePaymentDetails,
  getSalesPayablePaymentHistory,
  getSalesReturnDetails,
  getSalesReturnHistory,
  updateSalesDelivery,
  updateSalesInvoice,
  updateSalesOrder,
  updateSalesPayablePayment,
  updateSalesPayment,
  updateSalesQuotation,
  updateSalesReturn,
} from '../../store/sales/sales.action';
import {
  clearDataSalesSlice,
  clearSalesInvoiceData,
  clearSalesQuotationData,
} from '../../store/sales/sales.slice';
import { getTagList } from '../../store/tag/tag.action';
import { getWarehouseListWithDynamicStore } from '../../store/warehouse/warehouse.action';
import { clearWarehouseDataSlice } from '../../store/warehouse/warehouse.slice';
import ExpenseList from '../../views/Expense/ExpenseList/ExpenseList';
import PurchaseDeliveryList from '../../views/PurchaseDelivery/PurchaseDeliveryList/PurchaseDeliveryList';
import PurchaseInvoiceList from '../../views/PurchaseInvoice/PurchaseInvoiceList/PurchaseInvoiceList';
import PurchaseOrderList from '../../views/PurchaseOrder/PurchaseOrderList/PurchaseOrderList';
import PurchasePaymentList from '../../views/PurchasePayment/PurchasePaymentList/PurchasePaymentList';
import QuotationRequestList from '../../views/PurchaseQuotationRequest/PurchaseQuotationRequestList/PurchaseQuotationRequestList';
import SalesDeliveryList from '../../views/SalesDelivery/SalesDeliveryList/SalesDeliveryList';
import SalesInvoiceList from '../../views/SalesInvoice/SalesInvoiceList/SalesInvoiceList';
import SalesOrderList from '../../views/SalesOrder/SalesOrderList/SalesOrderList';
import SalesPaymentList from '../../views/SalesPayment/SalesPaymentList/SalesPaymentList';
import SalesQuotationList from '../../views/SalesQuotation/SalesQuotationList/SalesQuotationList';
import {
  REACT_APP_CASH_BANK_BASE_URL,
  REACT_APP_CASH_BANK_CASH_PAYMENT_DETAILS_TEMPLATE_URL,
  REACT_APP_CASH_BANK_CASH_RECEIPT_DETAILS_TEMPLATE_URL,
  REACT_APP_CASH_BANK_CASH_TRANSFER_DETAILS_TEMPLATE_URL,
  REACT_APP_DATA_ACCOUNT_BEGINNING_BALANCE_URL,
  REACT_APP_DATA_ASSET_URL,
  REACT_APP_DATA_GENERAL_JOURNAL_URL,
  REACT_APP_DATA_STOCK_ADJUSTMENT_DETAILS_TEMPLATE_URL,
  REACT_APP_EXPENSE_CREATE_NEW_URL,
  REACT_APP_EXPENSE_DETAILS_TEMPLATE_URL,
  REACT_APP_EXPENSE_UPDATE_TEMPLATE_URL,
  REACT_APP_EXPENSE_URL,
  REACT_APP_PURCHASE_DEBIT_NOTE_URL,
  REACT_APP_PURCHASE_DELIVERY_URL,
  REACT_APP_PURCHASE_PURCHASE_DELIVERY_CREATE_NEW_HANDOVER_TEMPLATE_URL,
  REACT_APP_PURCHASE_PURCHASE_DELIVERY_CREATE_NEW_URL,
  REACT_APP_PURCHASE_PURCHASE_DELIVERY_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_PURCHASE_DELIVERY_HANDOVER_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_PURCHASE_DELIVERY_HANDOVER_DETAILS_TEMPLATE_V2_URL,
  REACT_APP_PURCHASE_PURCHASE_DELIVERY_HANDOVER_UPDATE_TEMPLATE_URL,
  REACT_APP_PURCHASE_PURCHASE_DELIVERY_UPDATE_TEMPLATE_URL,
  REACT_APP_PURCHASE_PURCHASE_DELIVERY_URL,
  REACT_APP_PURCHASE_PURCHASE_INVOICE_CREATE_NEW_URL,
  REACT_APP_PURCHASE_PURCHASE_INVOICE_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_PURCHASE_INVOICE_UPDATE_TEMPLATE_URL,
  REACT_APP_PURCHASE_PURCHASE_INVOICE_URL,
  REACT_APP_PURCHASE_PURCHASE_ORDER_CREATE_NEW_URL,
  REACT_APP_PURCHASE_PURCHASE_ORDER_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_PURCHASE_ORDER_UPDATE_TEMPLATE_URL,
  REACT_APP_PURCHASE_PURCHASE_ORDER_URL,
  REACT_APP_PURCHASE_PURCHASE_PAYMENT_CREATE_NEW_URL,
  REACT_APP_PURCHASE_PURCHASE_PAYMENT_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_PURCHASE_PAYMENT_UPDATE_TEMPLATE_URL,
  REACT_APP_PURCHASE_PURCHASE_PAYMENT_URL,
  REACT_APP_PURCHASE_QUOTATION_REQUEST_CREATE_NEW_URL,
  REACT_APP_PURCHASE_QUOTATION_REQUEST_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_QUOTATION_REQUEST_UPDATE_TEMPLATE_URL,
  REACT_APP_PURCHASE_QUOTATION_REQUEST_URL,
  REACT_APP_PURCHASE_RECEIVABLE_PAYMENT_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_RECEIVABLE_PAYMENT_UPDATE_TEMPLATE_URL,
  REACT_APP_PURCHASE_RECEIVABLE_PAYMENT_URL,
  REACT_APP_PURCHASE_RETURN_DETAILS_TEMPLATE_URL,
  REACT_APP_PURCHASE_RETURN_UPDATE_TEMPLATE_URL,
  REACT_APP_PURCHASE_RETURN_URL,
  REACT_APP_PURCHASE_URL,
  REACT_APP_SALES_CREDIT_NOTE_URL,
  REACT_APP_SALES_INVOICE_URL,
  REACT_APP_SALES_PAYABLE_PAYMENT_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_PAYABLE_PAYMENT_UPDATE_TEMPLATE_URL,
  REACT_APP_SALES_PAYABLE_PAYMENT_URL,
  REACT_APP_SALES_PAYMENT_URL,
  REACT_APP_SALES_QUOTATION_URL,
  REACT_APP_SALES_RETURN_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_RETURN_UPDATE_TEMPLATE_URL,
  REACT_APP_SALES_RETURN_URL,
  REACT_APP_SALES_SALES_DELIVERY_CREATE_NEW_HANDOVER_TEMPLATE_URL,
  REACT_APP_SALES_SALES_DELIVERY_CREATE_NEW_URL,
  REACT_APP_SALES_SALES_DELIVERY_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_SALES_DELIVERY_HANDOVER_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_SALES_DELIVERY_HANDOVER_UPDATE_TEMPLATE_URL,
  REACT_APP_SALES_SALES_DELIVERY_UPDATE_TEMPLATE_URL,
  REACT_APP_SALES_SALES_DELIVERY_URL,
  REACT_APP_SALES_SALES_INVOICE_CREATE_NEW_URL,
  REACT_APP_SALES_SALES_INVOICE_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_SALES_INVOICE_UPDATE_TEMPLATE_URL,
  REACT_APP_SALES_SALES_INVOICE_URL,
  REACT_APP_SALES_SALES_ORDER_CREATE_NEW_URL,
  REACT_APP_SALES_SALES_ORDER_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_SALES_ORDER_UPDATE_TEMPLATE_URL,
  REACT_APP_SALES_SALES_ORDER_URL,
  REACT_APP_SALES_SALES_PAYMENT_CREATE_NEW_URL,
  REACT_APP_SALES_SALES_PAYMENT_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_SALES_PAYMENT_UPDATE_TEMPLATE_URL,
  REACT_APP_SALES_SALES_PAYMENT_URL,
  REACT_APP_SALES_SALES_QUOTATION_CREATE_NEW_URL,
  REACT_APP_SALES_SALES_QUOTATION_DETAILS_TEMPLATE_URL,
  REACT_APP_SALES_SALES_QUOTATION_UPDATE_TEMPLATE_URL,
  REACT_APP_SALES_SALES_QUOTATION_URL,
  REACT_APP_SALES_URL,
  REACT_APP_SETTING_CLOSING_BOOK_URL,
} from '../configs/url.config';
import {
  NAME_STATE_DETAIL_PURCHASE_PAYMENT,
  NAME_STORE,
  REQUEST_STATUS_CREATE_PURCHASE_RECEIVABLE_PAYMENT_FAILED,
  REQUEST_STATUS_CREATE_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
  REQUEST_STATUS_CREATE_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_CREATE_PURCHASE_RETURN_FAILED,
  REQUEST_STATUS_CREATE_PURCHASE_RETURN_PENDING,
  REQUEST_STATUS_CREATE_SALES_PAYABLE_PAYMENT_FAILED,
  REQUEST_STATUS_CREATE_SALES_PAYABLE_PAYMENT_LOADING,
  REQUEST_STATUS_CREATE_SALES_PAYABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_CREATE_SALES_RETURN_FAILED,
  REQUEST_STATUS_CREATE_SALES_RETURN_PENDING,
  REQUEST_STATUS_DELETE_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
  REQUEST_STATUS_DELETE_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_DELETE_PURCHASE_RETURN_PENDING,
  REQUEST_STATUS_DELETE_PURCHASE_RETURN_SUCCESS,
  REQUEST_STATUS_DELETE_SALES_PAYABLE_PAYMENT_PENDING,
  REQUEST_STATUS_DELETE_SALES_PAYABLE_PAYMENT_SUCCESS,
  REQUEST_STATUS_DELETE_SALES_RETURN_PENDING,
  REQUEST_STATUS_DELETE_SALES_RETURN_SUCCESS,
  REQUEST_STATUS_DETAILS_PURCHASE_RECEIVABLE_PAYMENT_FAILED,
  REQUEST_STATUS_DETAILS_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
  REQUEST_STATUS_DETAILS_PURCHASE_RETURN_FAILED,
  REQUEST_STATUS_DETAILS_PURCHASE_RETURN_LOADING,
  REQUEST_STATUS_DETAILS_SALES_PAYABLE_PAYMENT_FAILED,
  REQUEST_STATUS_DETAILS_SALES_PAYABLE_PAYMENT_LOADING,
  REQUEST_STATUS_DETAILS_SALES_RETURN_FAILED,
  REQUEST_STATUS_DETAILS_SALES_RETURN_LOADING,
  REQUEST_STATUS_HISTORY_HANDOVER_PURCHASE_DELIVERY_FAILED,
  REQUEST_STATUS_HISTORY_HANDOVER_PURCHASE_DELIVERY_PENDING,
  REQUEST_STATUS_HISTORY_HANDOVER_SALES_DELIVERY_FAILED,
  REQUEST_STATUS_HISTORY_HANDOVER_SALES_DELIVERY_PENDING,
  REQUEST_STATUS_HISTORY_PURCHASE_RECEIVABLE_PAYMENT_FAILED,
  REQUEST_STATUS_HISTORY_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
  REQUEST_STATUS_HISTORY_PURCHASE_RETURN_FAILED,
  REQUEST_STATUS_HISTORY_PURCHASE_RETURN_LOADING,
  REQUEST_STATUS_HISTORY_SALES_PAYABLE_PAYMENT_FAILED,
  REQUEST_STATUS_HISTORY_SALES_PAYABLE_PAYMENT_LOADING,
  REQUEST_STATUS_HISTORY_SALES_RETURN_FAILED,
  REQUEST_STATUS_HISTORY_SALES_RETURN_LOADING,
  REQUEST_STATUS_LIST_DETAILS_ATTACHMENT_FAILED,
  REQUEST_STATUS_LIST_DETAILS_ATTACHMENT_PENDING,
  REQUEST_STATUS_LIST_DETAILS_ATTACHMENT_SUCCESS,
  REQUEST_STATUS_LIST_TAG_LOADING,
  REQUEST_STATUS_UPDATE_PURCHASE_RECEIVABLE_PAYMENT_FAILED,
  REQUEST_STATUS_UPDATE_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
  REQUEST_STATUS_UPDATE_PURCHASE_RETURN_FAILED,
  REQUEST_STATUS_UPDATE_PURCHASE_RETURN_PENDING,
  REQUEST_STATUS_UPDATE_SALES_PAYABLE_PAYMENT_FAILED,
  REQUEST_STATUS_UPDATE_SALES_PAYABLE_PAYMENT_PENDING,
  REQUEST_STATUS_UPDATE_SALES_RETURN_FAILED,
  REQUEST_STATUS_UPDATE_SALES_RETURN_PENDING,
  ROOT_STORE_NAME_ACCOUNT,
  ROOT_STORE_NAME_ATTACHMENT,
  ROOT_STORE_NAME_JOURNAL_ENTRY,
  ROOT_STORE_NAME_MINUTES_OF_HANDOVER,
  ROOT_STORE_NAME_PURCHASE,
  ROOT_STORE_NAME_REFERENCE_NUMBER,
  ROOT_STORE_NAME_SALES,
  ROOT_STORE_NAME_TAG,
  SLICE_NAME_ACCOUNT_DATA,
  SLICE_NAME_CONTACT_DATA,
  SLICE_NAME_CONTACT_SALES_INVOICE_LIST_WITH_CONTACT_ID,
  SLICE_NAME_DATA_PURCHASE_INVOICE,
  SLICE_NAME_DETAIL_PURCHASE_INVOICE,
  SLICE_NAME_DETAIL_PURCHASE_ORDER,
  SLICE_NAME_EXPENSE_DETAIL,
  SLICE_NAME_HANDOVER_PURCHASE_DELIVERY_DETAILS,
  SLICE_NAME_HANDOVER_PURCHASE_DELIVERY_HISTORY,
  SLICE_NAME_HANDOVER_SALES_DELIVERY_DETAILS,
  SLICE_NAME_HANDOVER_SALES_DELIVERY_HISTORY,
  SLICE_NAME_JOURNAL_ENTRY_DETAIL,
  SLICE_NAME_LIST_DETAILS_ATTACHMENT,
  SLICE_NAME_PURCHASE_DEBIT_NOTE_DETAILS,
  SLICE_NAME_PURCHASE_DEBIT_NOTE_HISTORY,
  SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST,
  SLICE_NAME_PURCHASE_DELIVERY_DETAIL,
  SLICE_NAME_PURCHASE_DELIVERY_HISTORY_DETAILS,
  SLICE_NAME_PURCHASE_INVOICE_HISTORY,
  SLICE_NAME_PURCHASE_INVOICE_WITH_CONTACT_ID,
  SLICE_NAME_PURCHASE_ORDER_DATA,
  SLICE_NAME_PURCHASE_ORDER_HISTORY,
  SLICE_NAME_PURCHASE_PAYMENT_DETAIL,
  SLICE_NAME_PURCHASE_PAYMENT_HISTORY,
  SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_DETAILS,
  SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_HISTORY,
  SLICE_NAME_PURCHASE_RETURN_DETAILS,
  SLICE_NAME_PURCHASE_RETURN_HISTORY,
  SLICE_NAME_QUOTATION_REQUEST_DATA,
  SLICE_NAME_QUOTATION_REQUEST_DETAIL,
  SLICE_NAME_QUOTATION_REQUEST_HISTORY,
  SLICE_NAME_REFERENCE_NUMBER_DATA,
  SLICE_NAME_SALES_CREDIT_NOTE_DETAILS,
  SLICE_NAME_SALES_CREDIT_NOTE_HISTORY,
  SLICE_NAME_SALES_CREDIT_NOTE_LIST,
  SLICE_NAME_SALES_DELIVERY_DETAILS,
  SLICE_NAME_SALES_DELIVERY_HISTORY_DETAILS,
  SLICE_NAME_SALES_INVOICE_DATA,
  SLICE_NAME_SALES_INVOICE_DETAILS,
  SLICE_NAME_SALES_INVOICE_HISTORY,
  SLICE_NAME_SALES_ORDER_DATA,
  SLICE_NAME_SALES_ORDER_DETAILS,
  SLICE_NAME_SALES_ORDER_HISTORY_DETAILS,
  SLICE_NAME_SALES_PAYABLE_PAYMENT_DETAILS,
  SLICE_NAME_SALES_PAYABLE_PAYMENT_HISTORY,
  SLICE_NAME_SALES_PAYMENT_DETAIL,
  SLICE_NAME_SALES_PAYMENT_HISTORY,
  SLICE_NAME_SALES_QUOTATION_DATA,
  SLICE_NAME_SALES_QUOTATION_DETAIL,
  SLICE_NAME_SALES_QUOTATION_DETAILS,
  SLICE_NAME_SALES_QUOTATION_HISTORY,
  SLICE_NAME_SALES_RETURN_DETAILS,
  SLICE_NAME_SALES_RETURN_HISTORY,
  SLICE_NAME_SETTINGS_WAREHOUSE_DATA,
  SLICE_NAME_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE,
  SLICE_NAME_TAG_LIST,
  SLICE_NAME_TEMP_ADD_CONTACT_DATA,
  STATUS_REQUEST_ADD_EXPENSE_FAILED,
  STATUS_REQUEST_ADD_EXPENSE_PENDING,
  STATUS_REQUEST_ADD_PURCHASE_DELIVERY_FAILED,
  STATUS_REQUEST_ADD_PURCHASE_DELIVERY_PENDING,
  STATUS_REQUEST_ADD_PURCHASE_DELIVERY_SUCCESS,
  STATUS_REQUEST_ADD_PURCHASE_INVOICE_FAILED,
  STATUS_REQUEST_ADD_PURCHASE_INVOICE_PENDING,
  STATUS_REQUEST_ADD_PURCHASE_INVOICE_SUCCESS,
  STATUS_REQUEST_ADD_PURCHASE_ORDER_FAILED,
  STATUS_REQUEST_ADD_PURCHASE_ORDER_PENDING,
  STATUS_REQUEST_ADD_PURCHASE_ORDER_SUCCESS,
  STATUS_REQUEST_ADD_PURCHASE_PAYMENT_FAILED,
  STATUS_REQUEST_ADD_PURCHASE_PAYMENT_PENDING,
  STATUS_REQUEST_ADD_PURCHASE_PAYMENT_SUCCESS,
  STATUS_REQUEST_ADD_QUOTATION_REQUEST_FAILED,
  STATUS_REQUEST_ADD_QUOTATION_REQUEST_PENDING,
  STATUS_REQUEST_ADD_QUOTATION_REQUEST_SUCCESS,
  STATUS_REQUEST_ADD_SALES_DELIVERY_FAILED,
  STATUS_REQUEST_ADD_SALES_DELIVERY_PENDING,
  STATUS_REQUEST_ADD_SALES_INVOICE_FAILED,
  STATUS_REQUEST_ADD_SALES_INVOICE_PENDING,
  STATUS_REQUEST_ADD_SALES_ORDER_FAILED,
  STATUS_REQUEST_ADD_SALES_ORDER_PENDING,
  STATUS_REQUEST_ADD_SALES_PAYMENT_FAILED,
  STATUS_REQUEST_ADD_SALES_PAYMENT_PENDING,
  STATUS_REQUEST_ADD_SALES_PAYMENT_SUCCESS,
  STATUS_REQUEST_ADD_SALES_QUOTATION_FAILED,
  STATUS_REQUEST_ADD_SALES_QUOTATION_PENDING,
  STATUS_REQUEST_DELETE_EXPENSE_PENDING,
  STATUS_REQUEST_DELETE_EXPENSE_SUCCESS,
  STATUS_REQUEST_DELETE_PURCHASE_DELIVERY_PENDING,
  STATUS_REQUEST_DELETE_PURCHASE_DELIVERY_SUCCESS,
  STATUS_REQUEST_DELETE_PURCHASE_INVOICE_PENDING,
  STATUS_REQUEST_DELETE_PURCHASE_INVOICE_SUCCESS,
  STATUS_REQUEST_DELETE_PURCHASE_ORDER_PENDING,
  STATUS_REQUEST_DELETE_PURCHASE_ORDER_SUCCESS,
  STATUS_REQUEST_DELETE_PURCHASE_PAYMENT_PENDING,
  STATUS_REQUEST_DELETE_PURCHASE_PAYMENT_SUCCESS,
  STATUS_REQUEST_DELETE_QUOTATION_REQUEST_PENDING,
  STATUS_REQUEST_DELETE_QUOTATION_REQUEST_SUCCESS,
  STATUS_REQUEST_DELETE_SALES_DELIVERY_PENDING,
  STATUS_REQUEST_DELETE_SALES_DELIVERY_SUCCESS,
  STATUS_REQUEST_DELETE_SALES_INVOICE_PENDING,
  STATUS_REQUEST_DELETE_SALES_INVOICE_SUCCESS,
  STATUS_REQUEST_DELETE_SALES_ORDER_PENDING,
  STATUS_REQUEST_DELETE_SALES_ORDER_SUCCESS,
  STATUS_REQUEST_DELETE_SALES_PAYMENT_PENDING,
  STATUS_REQUEST_DELETE_SALES_PAYMENT_SUCCESS,
  STATUS_REQUEST_DELETE_SALES_QUOTATION_PENDING,
  STATUS_REQUEST_DELETE_SALES_QUOTATION_SUCCESS,
  STATUS_REQUEST_DETAIL_EXPENSE_FAILED,
  STATUS_REQUEST_DETAIL_EXPENSE_PENDING,
  STATUS_REQUEST_DETAIL_HISTORY_SALES_DELIVERY_FAILED,
  STATUS_REQUEST_DETAIL_HISTORY_SALES_DELIVERY_PENDING,
  STATUS_REQUEST_DETAIL_HISTORY_SALES_ORDER_FAILED,
  STATUS_REQUEST_DETAIL_HISTORY_SALES_ORDER_PENDING,
  STATUS_REQUEST_DETAIL_JOURNAL_ENTRY_PENDING,
  STATUS_REQUEST_DETAIL_PURCHASE_DELIVERY_FAILED,
  STATUS_REQUEST_DETAIL_PURCHASE_DELIVERY_PENDING,
  STATUS_REQUEST_DETAIL_PURCHASE_INVOICE_FAILED,
  STATUS_REQUEST_DETAIL_PURCHASE_INVOICE_PENDING,
  STATUS_REQUEST_DETAIL_PURCHASE_ORDER_FAILED,
  STATUS_REQUEST_DETAIL_PURCHASE_ORDER_PENDING,
  STATUS_REQUEST_DETAIL_PURCHASE_PAYMENT_FAILED,
  STATUS_REQUEST_DETAIL_PURCHASE_PAYMENT_PENDING,
  STATUS_REQUEST_DETAIL_QUOTATION_REQUEST_FAILED,
  STATUS_REQUEST_DETAIL_QUOTATION_REQUEST_PENDING,
  STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_PENDING,
  STATUS_REQUEST_DETAIL_SALES_DELIVERY_FAILED,
  STATUS_REQUEST_DETAIL_SALES_DELIVERY_PENDING,
  STATUS_REQUEST_DETAIL_SALES_INVOICE_FAILED,
  STATUS_REQUEST_DETAIL_SALES_INVOICE_PENDING,
  STATUS_REQUEST_DETAIL_SALES_ORDER_FAILED,
  STATUS_REQUEST_DETAIL_SALES_ORDER_PENDING,
  STATUS_REQUEST_DETAIL_SALES_PAYMENT_FAILED,
  STATUS_REQUEST_DETAIL_SALES_PAYMENT_PENDING,
  STATUS_REQUEST_DETAIL_SALES_QUOTATION_FAILED,
  STATUS_REQUEST_DETAIL_SALES_QUOTATION_PENDING,
  STATUS_REQUEST_DETAILS_HANDOVER_PURCHASE_DELIVERY_PENDING,
  STATUS_REQUEST_DETAILS_HANDOVER_SALES_DELIVERY_PENDING,
  STATUS_REQUEST_DETAILS_PURCHASE_DEBIT_NOTE_FAILED,
  STATUS_REQUEST_DETAILS_PURCHASE_DEBIT_NOTE_PENDING,
  STATUS_REQUEST_DETAILS_SALES_CREDIT_NOTE_FAILED,
  STATUS_REQUEST_DETAILS_SALES_CREDIT_NOTE_PENDING,
  STATUS_REQUEST_HISTORY_PURCHASE_DEBIT_NOTE_FAILED,
  STATUS_REQUEST_HISTORY_PURCHASE_DEBIT_NOTE_PENDING,
  STATUS_REQUEST_HISTORY_PURCHASE_DELIVERY_PENDING,
  STATUS_REQUEST_HISTORY_PURCHASE_INVOICE_PENDING,
  STATUS_REQUEST_HISTORY_PURCHASE_ORDER_PENDING,
  STATUS_REQUEST_HISTORY_PURCHASE_PAYMENT_PENDING,
  STATUS_REQUEST_HISTORY_QUOTATION_REQUEST_FAILED,
  STATUS_REQUEST_HISTORY_QUOTATION_REQUEST_PENDING,
  STATUS_REQUEST_HISTORY_SALES_CREDIT_NOTE_FAILED,
  STATUS_REQUEST_HISTORY_SALES_CREDIT_NOTE_PENDING,
  STATUS_REQUEST_HISTORY_SALES_INVOICE_FAILED,
  STATUS_REQUEST_HISTORY_SALES_INVOICE_PENDING,
  STATUS_REQUEST_HISTORY_SALES_PAYMENT_FAILED,
  STATUS_REQUEST_HISTORY_SALES_PAYMENT_PENDING,
  STATUS_REQUEST_HISTORY_SALES_QUOTATION_FAILED,
  STATUS_REQUEST_HISTORY_SALES_QUOTATION_PENDING,
  STATUS_REQUEST_LIST_ACCOUNT_DATA_PENDING,
  STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
  STATUS_REQUEST_LIST_EXPENSE_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_INVOICE_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_ORDER_PENDING,
  STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PENDING,
  STATUS_REQUEST_LIST_QUOTATION_REQUEST_PENDING,
  STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_PENDING,
  STATUS_REQUEST_LIST_SALES_DELIVERY_PENDING,
  STATUS_REQUEST_LIST_SALES_INVOICE_PENDING,
  STATUS_REQUEST_LIST_SALES_ORDER_PENDING,
  STATUS_REQUEST_LIST_SALES_PAYMENT_PENDING,
  STATUS_REQUEST_LIST_SALES_QUOTATION_PENDING,
  STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_PENDING,
  STATUS_REQUEST_UPDATE_EXPENSE_FAILED,
  STATUS_REQUEST_UPDATE_EXPENSE_PENDING,
  STATUS_REQUEST_UPDATE_PURCHASE_DELIVERY_FAILED,
  STATUS_REQUEST_UPDATE_PURCHASE_DELIVERY_PENDING,
  STATUS_REQUEST_UPDATE_PURCHASE_DELIVERY_SUCCESS,
  STATUS_REQUEST_UPDATE_PURCHASE_INVOICE_FAILED,
  STATUS_REQUEST_UPDATE_PURCHASE_INVOICE_PENDING,
  STATUS_REQUEST_UPDATE_PURCHASE_INVOICE_SUCCESS,
  STATUS_REQUEST_UPDATE_PURCHASE_ORDER_FAILED,
  STATUS_REQUEST_UPDATE_PURCHASE_ORDER_PENDING,
  STATUS_REQUEST_UPDATE_PURCHASE_ORDER_SUCCESS,
  STATUS_REQUEST_UPDATE_PURCHASE_PAYMENT_FAILED,
  STATUS_REQUEST_UPDATE_PURCHASE_PAYMENT_PENDING,
  STATUS_REQUEST_UPDATE_QUOTATION_REQUEST_FAILED,
  STATUS_REQUEST_UPDATE_QUOTATION_REQUEST_PENDING,
  STATUS_REQUEST_UPDATE_QUOTATION_REQUEST_SUCCESS,
  STATUS_REQUEST_UPDATE_SALES_DELIVERY_FAILED,
  STATUS_REQUEST_UPDATE_SALES_DELIVERY_PENDING,
  STATUS_REQUEST_UPDATE_SALES_INVOICE_FAILED,
  STATUS_REQUEST_UPDATE_SALES_INVOICE_PENDING,
  STATUS_REQUEST_UPDATE_SALES_ORDER_FAILED,
  STATUS_REQUEST_UPDATE_SALES_ORDER_PENDING,
  STATUS_REQUEST_UPDATE_SALES_PAYMENT_FAILED,
  STATUS_REQUEST_UPDATE_SALES_PAYMENT_PENDING,
  STATUS_REQUEST_UPDATE_SALES_QUOTATION_FAILED,
  STATUS_REQUEST_UPDATE_SALES_QUOTATION_PENDING,
} from '../constants/store.constant';
import { getAccountingPeriodCompareWithDocumentTransactionDate } from '../data/display/initial-accounting-period';
import { generalizeAttachmentRequest } from '../data/display/initial-add-attachment';
import {
  generateDataNValidationAddProfile,
  generateDataNValidationAddProfileForIsCustomer,
} from '../data/display/initial-add-contact-data';
import {
  generalizeDataAddExpense,
  generateDataNValidationAddDestinationAccount,
  generateDataNValidationAddExpense,
} from '../data/display/initial-add-expense';
import {
  generalizeDataAddPurchaseDelivery,
  generateDataNValidationAddProductPD,
  generateDataNValidationAddPurchaseDeliveryV2,
} from '../data/display/initial-add-purchase-delivery';
import {
  generateDataNValidationInitialProductPurchaseDeliveryFromPurchaseOrderV2,
  generateDataNValidationInitialPurchaseDeliveryFromPurchaseOrderV2,
} from '../data/display/initial-add-purchase-delivery';
import {
  generalizeDataAddPurchaseInvoice,
  generateDataNValidationAddProductPurchaseInvoice,
  generateDataNValidationAddProductPurchaseInvoiceFromPurchaseDeliveryV2,
  generateDataNValidationAddProductPurchaseInvoiceFromPurchaseOrderV2,
  generateDataNValidationAddProductPurchaseInvoiceV2,
  generateDataNValidationAddPurchaseInvoiceFromPurchaseDeliveryV2,
  generateDataNValidationAddPurchaseInvoiceFromPurchaseOrderV2,
  generateDataNValidationAddPurchaseInvoiceV2,
} from '../data/display/initial-add-purchase-invoice';
import {
  generalizeDataAddPurchaseOrder,
  generateDataNValidationAddProductPO,
  generateDataNValidationAddProductPOV2,
  generateDataNValidationAddPurchaseOrderV2,
} from '../data/display/initial-add-purchase-order';
import {
  generateDataNValidationInitialProductPOFromQuotationRequestV2,
  generateDataNValidationInitialPurchaseOrderFromQuotationRequestV2,
} from '../data/display/initial-add-purchase-order';
import {
  generalizeDataAddPurchasePayment,
  generateDataNValidationAddInvoiceFromListPIV2,
  generateDataNValidationAddInvoicePP,
  generateDataNValidationAddPaymentPP,
  generateDataNvalidationAddPurchasePaymentFromPurchaseInvoiceDetails,
  generateDataNValidationAddPurchasePaymentV2,
} from '../data/display/initial-add-purchase-payment';
import {
  generalizeDataAddPurchaseReceivablePayment,
  generateDataNvalidationAddDebitNotePurchaseReceivablePayment,
  generateDataNvalidationAddDebitNotePurchaseReceivablePaymentFromDebitNoteList,
  generateDataNvalidationAddPurchaseReceivablePayment,
  generateDataNvalidationAddPurchaseReceivablePaymentFromDebitNoteDetails,
  generateDataNvalidationAddPurchaseReceivablePaymentWithWrapperAccountReceivable,
} from '../data/display/initial-add-purchase-receivable-payment';
import {
  generalizeDataAddPurchaseReturn,
  generateDataNvalidationAddProductPurchaseReturn,
  generateDataNvalidationAddPurchaseReturn,
  generateDataNvalidationProductPurchaseInvoiceFromPurchaseInvoiceDetails,
  generateDataNvalidationPurchaseReturnFromPurchaseInvoiceDetails,
} from '../data/display/initial-add-purchase-return';
import {
  generalizeDataAddQuotationRequest,
  generateDataNValidationAddProductQR,
  generateDataNValidationAddQuotationRequestV2,
} from '../data/display/initial-add-quotation-request';
import {
  generalizeDataAddSalesDelivery,
  generateDataNValidationAddProductSalesDeliveryV2,
  generateDataNValidationAddSalesDeliveryV2,
  generateDataNValidationProductSalesDeliveryFromSalesOrderDetailsV2,
  generateDataNValidationSalesDeliveryFromSalesOrderDetailsV2,
} from '../data/display/initial-add-sales-delivery';
import {
  generalizeDataAddSalesInvoice,
  generateDataNValidationAddProductSIv2,
  generateDataNValidationAddSalesInvoiceV2,
  generateDataNValidationProductSalesInvoiceFromSalesDeliveryDetailsV2,
  generateDataNValidationProductSalesInvoiceFromSalesOrderDetailsV2,
  generateDataNValidationSalesInvoiceFromSalesDeliveryDetailsV2,
  generateDataNValidationSalesInvoiceFromSalesOrderDetailsV2,
} from '../data/display/initial-add-sales-invoice';
import {
  generalizeDataAddSalesOrder,
  generateDataNValidationAddProductSalesOrderV2,
  generateDataNValidationAddSalesOrderV2,
  generateDataNValidationProductSalesOrderFromSalesQuotationDetailsV2,
  generateDataNValidationSalesOrderFromSalesQuotationDetailsV2,
} from '../data/display/initial-add-sales-order';
import {
  generalizeDataAddSalesPayablePayment,
  generateDataNvalidationAddCreditNoteSalesPayablePayment,
  generateDataNvalidationAddCreditNoteSalesPayablePaymentFromCreditNoteList,
  generateDataNvalidationAddSalesPayablePayment,
  generateDataNvalidationAddSalesPayablePaymentFromCreditNoteDetails,
  generateDataNvalidationAddSalesPayablePaymentWithWrappingKeyname,
} from '../data/display/initial-add-sales-payable-payment';
import {
  generalizeDataAddSalesPayment,
  generateDataNValidationAddInvoiceFromListSIv2,
  generateDataNValidationAddInvoiceSalesPayment,
  generateDataNValidationAddInvoiceSalesPaymentV2,
  generateDataNValidationAddPaymentSalesPayment,
  generateDataNValidationAddPaymentSalesPaymentV2,
} from '../data/display/initial-add-sales-payment';
import {
  generalizeDataAddSalesQuotation,
  generateDataNValidationAddProductSQ,
  generateDataNValidationAddSalesQuotationV2,
} from '../data/display/initial-add-sales-quotation';
import {
  generalizeDataAddSalesReturn,
  generateDataNvalidationAddProductSalesReturn,
  generateDataNvalidationAddSalesReturn,
  generateDataNvalidationProductSalesReturnFromSalesInvoiceDetails,
  generateDataNvalidationSalesReturnFromSalesInvoiceDetails,
} from '../data/display/initial-add-sales-return';
import {
  allowKeysDestinationAccountData,
  allowKeysExpenseData,
} from '../data/display/initial-data-expense';
import {
  allowKeysDataProduct as allowKeysDataProductPurchaseDelivery,
  allowKeysDataPurchaseDelivery,
} from '../data/display/initial-data-purchase-delivery';
import {
  allowKeysProductPurchaseInvoiceData as allowKeysDataProductPurchaseInvoice,
  allowKeysPurchaseInvoiceData as allowKeysDataPurchaseInvoice,
} from '../data/display/initial-data-purchase-invoice';
import {
  allowKeysProductPurchaseOrderData,
  allowKeysPurchaseOrderData,
} from '../data/display/initial-data-purchase-order';
import {
  allowKeysBodyPurchasePayment as allowKeysDataPurchasePayment,
  allowKeysBodyPurchasePaymentOnInvoice as allowKeysDataInvoiceOnPurchasePayment,
  allowKeysInvoiceData as allowKeysPurchaseInvoiceData,
  allowKeysPaymentData as allowKeysPurchasePaymentData,
  initialInvoiceData as initialPurchaseInvoiceData,
  initialPaymentData as initialPurchasePaymentData,
} from '../data/display/initial-data-purchase-payment';
import {
  allowKeysDataDebitNotePurchaseReceivablePayment,
  allowKeysDataPurchaseReceivablePayment,
  initialDataDebitNotePurchaseReceivablePayment,
  initialDataPurchaseReceivablePayment,
} from '../data/display/initial-data-purchase-receivable-payment';
import {
  allowKeysDataProductPurchaseReturn,
  allowKeysDataPurchaseReturn,
} from '../data/display/initial-data-purchase-return';
import {
  allowKeysDataProductQuotationRequest,
  allowKeysDataQuotationRequest,
} from '../data/display/initial-data-quotation-request';
import {
  allowKeysProductSalesDeliveryData,
  allowKeysSalesDeliveryData,
} from '../data/display/initial-data-sales-delivery';
import {
  allowKeysDataProduct,
  allowKeysDataSalesInvoice,
  modulTypeSalesInvoice,
} from '../data/display/initial-data-sales-invoice';
import {
  allowKeysProductSalesOrderData,
  allowKeysSalesOrderData,
} from '../data/display/initial-data-sales-order';
import {
  allowKeysDataCreditNoteSalesPayablePayment,
  allowKeysDataSalesPayablePayment,
  initialDataCreditNoteSalesPayablePayment,
  initialDataSalesPayablePayment,
} from '../data/display/initial-data-sales-payable-payment';
import {
  allowKeysInvoiceData as allowKeysSalesInvoiceData,
  allowKeysPaymentData as allowKeysSalesPaymentData,
  initialInvoiceData as initialSalesInvoiceData,
  initialPaymentData as initialSalesPaymentData,
} from '../data/display/initial-data-sales-payment';
import {
  allowKeysInvoiceData,
  allowKeysPaymentData,
} from '../data/display/initial-data-sales-payment';
import {
  allowKeysDataProductSalesQuotation,
  allowKeysDataSalesQuotation,
} from '../data/display/initial-data-sales-quotation';
import {
  allowKeysDataProductSalesReturn,
  allowKeysDataSalesReturn,
} from '../data/display/initial-data-sales-return';
import {
  generalizeDataUpdateExpense,
  generateDataNValidationUpdateDestinationAccountFromDetailExpense,
  generateDataNValidationUpdateExpenseFromDetailExpense,
} from '../data/display/initial-update-expense';
import {
  generalizeDataUpdatePurchaseDelivery,
  generateDataNValidationUpdateProductPurchaseDeliveryV2,
  generateDataNValidationUpdateProductPurchaseDeliveryV3,
  generateDataNValidationUpdatePurchaseDeliveryV2,
} from '../data/display/initial-update-purchase-delivery';
import {
  generalizeDataUpdatePurchaseInvoice,
  generateDataNValidationUpdateProductPurchaseInvoiceFromDetailPurchaseInvoice,
  generateDataNValidationUpdateProductPurchaseInvoiceFromDetailPurchaseInvoiceV2,
  generateDataNValidationUpdatePurchaseInvoiceFromDetailPurchaseInvoiceV2,
} from '../data/display/initial-update-purchase-invoice';
import {
  generalizeDataUpdatePurchaseOrder,
  generateDataNValidationUpdateProductPO,
  generateDataNValidationUpdatePurchaseOrderProductV2,
  generateDataNValidationUpdatePurchasePurchaseOrderV2,
} from '../data/display/initial-update-purchase-order';
import {
  generalizeDataUpdatePurchasePayment,
  generateDataNvalidationInvoicePurchasePaymentFromPurchaseInvoiceList,
  generateDataNValidationUpdateInvoicePPFromDetailPPV2,
  generateDataNValidationUpdatePurchasePaymentFromPPDetailV2,
} from '../data/display/initial-update-purchase-payment';
import {
  generalizeDataUpdatePurchaseReceivablePayment,
  generateDataNvalidationUpdateDebitNotePurchaseReceivablePayment,
  generateDataNvalidationUpdateDebitNotePurchaseReceivablePaymentFromDebitNoteList,
  generateDataNvalidationUpdatePurchaseReceivablePayment,
} from '../data/display/initial-update-purchase-receivable-payment';
import {
  generalizeDataUpdatePurchaseReturn,
  generateDataNvalidationUpdateProductPurchaseReturnFromPurchaseReturnDetails,
  generateDataNvalidationUpdatePurchaseReturnFromPurchaseReturnDetails,
} from '../data/display/initial-update-purchase-return';
import {
  generalizeDataUpdateQuotationRequestV2,
  generateDataNValidationUpdateProductQuotationRequestV2,
  generateDataNValidationUpdateQuotationRequestV2,
} from '../data/display/initial-update-quotation-request';
import {
  generalizeDataUpdateSalesDelivery,
  generateDataNValidationUpdateProductSalesDeliveryFromSalesDeliveryDetailsV2,
  generateDataNValidationUpdateSalesDeliveryFromSalesDeliveryDetailsV2,
} from '../data/display/initial-update-sales-delivery';
import {
  generalizeDataUpdateSalesInvoice,
  generateDataNValidationUpdateProductSalesInvoiceV2,
  generateDataNValidationUpdateSalesInvoiceV2,
} from '../data/display/initial-update-sales-invoice';
import {
  generalizeDataUpdateSalesOrder,
  generateDataNValidationUpdateProductSalesOrderFromSalesOrderDetailsV2,
  generateDataNValidationUpdateSalesOrderFromSalesOrderDetailsV2,
} from '../data/display/initial-update-sales-order';
import {
  generalizeDataUpdateSalesPayablePayment,
  generateDataNvalidationUpdateCreditNoteSalesPayablePayment,
  generateDataNvalidationUpdateCreditNoteSalesPayablePaymentFromCreditNoteList,
  generateDataNvalidationUpdateSalesPayablePayment,
} from '../data/display/initial-update-sales-payable-payment';
import {
  generalizeDataUpdateSalesPayment,
  generateDataNvalidationInvoiceSalesPaymentFromSalesInvoiceList,
  generateDataNValidationUpdateInvoiceSalesPaymentFromDetailSPv2,
  generateDataNValidationUpdateSalesPaymentFromDetailSPv2,
} from '../data/display/initial-update-sales-payment';
import {
  generalizeDataUpdateSalesQuotation,
  generateDataNValidationUpdateProductSQ,
  generateDataNValidationUpdateSalesQuotationV2,
} from '../data/display/initial-update-sales-quotation';
import {
  generalizeDataUpdateSalesReturn,
  generateDataNvalidationUpdateProductSalesReturnFromSalesReturnDetails,
  generateDataNvalidationUpdateSalesReturnFromSalesReturnDetails,
} from '../data/display/initial-update-sales-return';
import {
  menuItemPurchaseDeliveryButtonWithPropsV2,
  menuItemPurchaseHandoverJournalDetailsButtonWithProps,
  menuItemPurchaseInvoiceButtonWithPropsV2,
  menuItemPurchaseOrderButtonWithButtonPropsV2,
  menuItemPurchasePaymentButtonWithProps,
  menuItemPurchaseQuotationRequestButtonWithButtonPropsV2,
  menuItemPurchaseReceivablePaymentButtonWithProps,
  menuItemPurchaseReturnButtonWithProps,
  menuItemSalesDeliveryButtonWithPropsV2,
  menuItemSalesHandoverJournalDetailsButtonWithProps,
  menuItemSalesInvoiceButtonWithPropsV2,
  menuItemSalesOrderButtonWithButtonPropsV2,
  menuItemSalesPayablePaymentButtonWithProps,
  menuItemSalesPaymentButtonWithProps,
  menuItemSalesQuotationButtonWithButtonPropsV2,
  menuItemSalesReturnButtonWithProps,
} from '../data/display/menu-items-button';
import { gridHeaderDestinationAccountOnExpense } from '../data/display/table-add-update-expense';
import {
  gridCollapseProductSalesOrder,
  gridHeaderProductSalesOrder,
} from '../data/display/table-add-update-product-sales-order';
import {
  gridCollapseProductPurchaseDelivery,
  gridHeaderProductPurchaseDelivery,
} from '../data/display/table-add-update-purchase-delivery';
import {
  gridCollapseProductPurchaseInvoice,
  gridHeaderProductPurchaseInvoice,
} from '../data/display/table-add-update-purchase-invoice';
import {
  gridCollapseProductPurchaseOrder,
  gridHeaderProductPurchaseOrder,
} from '../data/display/table-add-update-purchase-order';
import {
  gridCollapseProductPurchaseReturn,
  gridHeaderProductPurchaseReturn,
} from '../data/display/table-add-update-purchase-return';
import {
  gridCollapseProductQuotationRequest,
  gridHeaderProductQuotationRequest,
} from '../data/display/table-add-update-quotation-request';
import {
  gridCollapseProductSalesDelivery,
  gridHeaderProductSalesDelivery,
} from '../data/display/table-add-update-sales-delivery';
import {
  gridCollapseProductSalesInvoice,
  gridHeaderProductSalesInvoice,
} from '../data/display/table-add-update-sales-invoice';
import {
  columnsTableHeaderAddUpdateSalesQuotationV2,
  gridCollapseProductSalesQuotation,
  gridHeaderProductSalesQuotation,
} from '../data/display/table-add-update-sales-quotation';
import {
  gridCollapseCreditNoteSalesPayablePayment,
  gridHeaderCreditNoteSalesPayablePayment,
} from '../data/display/table-credit-note-sales-payable-payment';
import {
  gridCollapseDebitNotePurchaseReceivablePayment,
  gridHeaderDebitNotePurchaseReceivablePayment,
} from '../data/display/table-debit-note-purchase-receivable-payment';
import { columnsTableHeaderAccountInExpense } from '../data/display/table-detail-expense';
import { columnsTableDetailProductMinutesOfHandoverV2 } from '../data/display/table-detail-product-minutes-of-handover';
import {
  columnsTableFooterProductPurchaseDeliveryDetails,
  columnsTableFooterPurchaseDeliveryDetails,
  columnsTableHeaderPurchaseDeliveryProductDataListV2,
  columnsTableKeyValuePrintPurchaseDeliveryDetails,
  columnsTableProductPurchaseDeliveryDetails,
} from '../data/display/table-detail-purchase-delivery';
import {
  columnsTableFooterProductPurchaseInvoiceDetails,
  columnsTableFooterPurchaseInvoiceDetails,
  columnsTableKeyValuePrintPurchaseInvoice,
  columnsTableProductPurchaseInvoiceDetails,
} from '../data/display/table-detail-purchase-invoice';
import {
  columnsTableFooterProductPurchaseOrderDetails,
  columnsTableFooterPurchaseOrderDetails,
  columnsTableKeyValuePrintPurchaseOrderDetails,
  columnsTableProductPurchaseOrderDetails,
} from '../data/display/table-detail-purchase-order';
import {
  columnsTableFooterInvoicePurchasePaymentDetails,
  columnsTableFooterPurchasePaymentDetails,
  columnsTableInvoicePurchasePaymentDetails,
  columnsTableKeyValuePrintPurchasePaymentDetails,
} from '../data/display/table-detail-purchase-payment';
import {
  columnsTableFooterProductPurchaseQuotationRequestDetails,
  columnsTableFooterPurchaseQuotationRequestDetails,
  columnsTableHeaderPurchaseQuotationRequestDetailsV2,
  columnsTableKeyValuePrintPurchaseQuotationRequest,
  columnsTableProductPurchaseQuotationRequestDetails,
} from '../data/display/table-detail-quotation-request';
import {
  columnsTableFooterProductSalesDeliveryDetails,
  columnsTableFooterSalesDeliveryDetails,
  columnsTableHeaderSalesDeliveryProductDataListV2,
  columnsTableKeyValuePrintSalesDeliveryDetails,
  columnsTableProductSalesDeliveryDetails,
} from '../data/display/table-detail-sales-delivery';
import {
  columnsTableFooterProductSalesInvoiceDetails,
  columnsTableFooterSalesInvoiceDetails,
  columnsTableKeyValuePrintSalesInvoice,
  columnsTableProductSalesInvoiceDetails,
} from '../data/display/table-detail-sales-invoice';
import {
  columnsTableFooterProductSalesOrderDetails,
  columnsTableFooterSalesOrderDetails,
  columnsTableKeyValuePrintSalesOrderDetails,
  columnsTableProductSalesOrderDetails,
} from '../data/display/table-detail-sales-order';
import {
  columnsTableFooterInvoiceSalesPaymentDetails,
  columnsTableFooterSalesPaymentDetails,
  columnsTableInvoiceSalesPaymentDetails,
  columnsTableKeyValuePrintSalesPaymentDetails,
} from '../data/display/table-detail-sales-payment';
import {
  columnsTableFooterProductSalesQuotationDetails,
  columnsTableFooterSalesQuotationDetails,
  columnsTableHeaderSalesQuotationDetailsV2,
  columnsTableKeyValuePrintSalesQuotation,
  columnsTableProductSalesQuotationDetails,
} from '../data/display/table-detail-sales-quotation';
import { columnsTableHeaderDebitNoteListOnPurchaseReceivablePayment } from '../data/display/table-details-purchase-receivable-payment';
import { columnsTableHeaderCreditNoteListOnSalesPayablePayment } from '../data/display/table-details-sales-payable-payment';
import { columnsTableHeaderInvoicePurchasePaymentV2 } from '../data/display/table-invoice-purchase-payment';
import {
  gridCollapseInvoiceOnPurchasePayment,
  gridHeaderInvoiceOnPurchasePayment,
} from '../data/display/table-invoice-purchase-payment';
import {
  columnsTableHeaderInvoiceSalesPaymentV2,
  gridCollapseInvoiceSalesPayment,
  gridHeaderInvoiceSalesPayment,
} from '../data/display/table-invoice-sales-payment';
import { columnsTableFooterProductSalesInvoiceList } from '../data/display/table-list-sales-invoice';
import { columnsTableFooterInvoiceSalesPaymentList } from '../data/display/table-list-sales-payment';
import {
  columnsTableFooterProductDataList,
  columnsTableHeaderProductDataListV2,
  columnsTableHeaderProductPurchaseDeliveryDataListV2,
} from '../data/display/table-product-list';
import { columnsTableHeaderPurchaseDebitNoteDetails } from '../data/display/table-purchase-debit-note-details';
import { columnsTableFooterProductPurchaseInvoiceList } from '../data/display/table-purchase-invoice';
import { columnsTableFooterInvoicePurchasePaymentList } from '../data/display/table-purchase-payment-list';
import { getChangesAutocompleteForUnitProductTransactionPropertiesPrimary } from '../data/display/unit-product-properties';
import { minutesHandoverStatus } from '../data/label-value/minutes-of-handover-status';
import {
  purchaseDebitNoteStatus,
  purchaseDeliveryPlainStatus,
  purchaseDeliveryStatus,
  purchaseInvoicePlainStatus,
  purchaseInvoiceStatus,
  purchaseOrderPlainStatus,
  purchaseOrderStatus,
  purchaseQuotationRequestPlainStatus,
  purchaseQuotationRequestStatus,
} from '../data/label-value/purchase-status';
import {
  salesCreditNoteStatus,
  salesDeliveryPlainStatus,
  salesDeliveryStatus,
  salesInvoicePlainStatus,
  salesInvoiceStatus,
  salesOrderPlainStatus,
  salesOrderStatus,
  salesQoutationStatus,
  salesQoutationStatusV2,
  salesQuotationPlainStatus,
} from '../data/label-value/sales-status';
import {
  DEFAULT_PAYLOAD_STORAGE_LIST_SALES_ORDER,
  DEFAULT_PAYLOAD_STORAGE_PRODUCT_DATA_BY_SUPPLIER,
  DEFAULT_PAYLOAD_STORAGE_PURCHASE_DEBIT_NOTE_LIST,
  DEFAULT_PAYLOAD_STORAGE_PURCHASE_INVOICE_DETAILS,
  DEFAULT_PAYLOAD_STORAGE_PURCHASE_ORDER_DETAIL,
  DEFAULT_PAYLOAD_STORAGE_QUOTATION_REQUEST_DETAIL,
  DEFAULT_PAYLOAD_STORAGE_SALES_INVOICE_DETAILS,
  DEFAULT_PAYLOAD_STORAGE_SALES_ORDER_DETAILS,
  DEFAULT_PAYLOAD_STORAGE_SALES_QUOTATION_DETAIL,
  DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE,
} from '../default/payload-storage.default';
import arrHelp from '../helpers/array.helpers';
import checkValidation from '../helpers/check-validation.helpers';
import autocompleteHelper from '../helpers/component/autocomplete.helpers';
import formatHelp from '../helpers/format.helpers';
import formulaHelpers from '../helpers/formula.helpers';
import objHelper from '../helpers/object.helper';
import strHelp from '../helpers/string.helpers';
import { objectToQueryString } from '../helpers/url.helpers';
import {
  buttonCollectionsHistoryTransactionOnly,
  buttonCollectionsMinutesHandoverPurchaseDeliveryDetails,
  buttonCollectionsMinutesHandoverSalesDeliveryDetails,
  buttonCollectionsPurchaseDeliveryDetails,
  buttonCollectionsPurchaseInvoiceDetails,
  buttonCollectionsPurchaseOrderDetails,
  buttonCollectionsPurchasePaymentDetails,
  buttonCollectionsPurchaseQuotationRequestDetails,
  buttonCollectionsPurchaseReturnDetails,
  buttonCollectionsSalesDeliveryDetails,
  buttonCollectionsSalesInvoiceDetails,
  buttonCollectionsSalesOrderDetails,
  buttonCollectionsSalesPaymentDetails,
  buttonCollectionsSalesQuotationDetails,
  buttonCollectionsSalesReturnDetails,
} from '../properties/button-collections-v1.properties';

import {
  DEFAULT_ACCOUNT_NAME_CASH,
  DEFAULT_ACCOUNT_NAME_DISKON_PENJUALAN,
  DEFAULT_ACCOUNT_NAME_PURCHASE_DISCOUNT,
  DEFAULT_ACCOUNT_OTHER_EXPENSES_NAME,
  DEFAULT_ACCOUNT_OTHER_INCOMES_NAME,
} from './account.default';
import {
  ACCOUNT_CATEGORY_NAME_CASH_N_BANK,
  ACCOUNT_CATEGORY_NAME_KAS_N_BANK,
  ACCOUNT_CATEGORY_NAME_OTHER_EXPENSE,
} from './account-category-name.default';
import { DEFAULT_ACTION_MENU_ADD, DEFAULT_ACTION_MENU_UPDATE } from './action-menu.default';
import { additionalParamsGetContactCustomer } from './additional-params-action';
import {
  DEFAULT_CONTACT_IS_CUSTOMER_TYPE,
  DEFAULT_CONTACT_IS_EMPLOYEE_TYPE,
} from './contact-type.default';
import { DEFAULT_CONTACT_IS_SUPPLIER_TYPE } from './contact-type.default';
import {
  DEFAULT_DISCOUNT_NOMINAL_TYPE,
  DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
} from './discount-type.default';
import {
  DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
  DEFAULT_INPUT_TYPE_CHECKBOX_WITH_LABEL,
  DEFAULT_INPUT_TYPE_COMMON_INPUT,
  DEFAULT_INPUT_TYPE_DATE_PICKER,
  DEFAULT_INPUT_TYPE_DROPZONE,
} from './input-type.default';
import { DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE } from './max-length-data.default';
import {
  MINUTES_OF_HANDOVER_TYPE_PURCHASE_DELIVERY,
  MINUTES_OF_HANDOVER_TYPE_SALES_DELIVERY,
} from './minutes-of-handover-config.default';
import {
  DEFAULT_CALCULATION_NUMBER,
  DEFAULT_EMPTY_CALCULATIONS_NUMBER,
  DEFAULT_QUANTITY_AVAILABLE_STOCK_MONITORED_PRODUCT,
  DEFAULT_QUANTITY_AVAILABLE_STOCK_NOT_MONITORED_PRODUCT,
} from './number.default';
import {
  DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
  DEFAULT_KEY_NAME_ACCOUNT_ID_CONTAINER,
  DEFAULT_KEY_NAME_ACCOUNT_NAME_API_REF,
  DEFAULT_KEY_NAME_ACCOUNT_PAYABLE_API_REF,
  DEFAULT_KEY_NAME_ACCOUNT_PAYABLE_CONTAINER,
  DEFAULT_KEY_NAME_ACCOUNT_RECEIVABLE_API_REF,
  DEFAULT_KEY_NAME_ACCOUNT_RECEIVABLE_CONTAINER,
  DEFAULT_KEY_NAME_ACCOUNTING_PERIOD,
  DEFAULT_KEY_NAME_AMOUNT_CONST,
  DEFAULT_KEY_NAME_AMOUNT_INPUT,
  DEFAULT_KEY_NAME_AMOUNT_REF_API,
  DEFAULT_KEY_NAME_ASSET_ID_API_REF,
  DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
  DEFAULT_KEY_NAME_BILLING_ADDRESS_API_REF,
  DEFAULT_KEY_NAME_CATEGORY_NAME_API_REF,
  DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
  DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
  DEFAULT_KEY_NAME_CLOSING_BOOK_ID_API_REF,
  DEFAULT_KEY_NAME_CONTACT_CODE_API_REF,
  DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
  DEFAULT_KEY_NAME_CONTACT_ID_CONTAINER_API_REF,
  DEFAULT_KEY_NAME_CONTACT_NAME_API_REF,
  DEFAULT_KEY_NAME_CREDIT_NOTE_ID_API_REF,
  DEFAULT_KEY_NAME_CUSTOMER_ADDRESS_API_REF,
  DEFAULT_KEY_NAME_CUSTOMER_CONTAINER_ID_API_REF,
  DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF,
  DEFAULT_KEY_NAME_CUSTOMER_NAME_API_REF,
  DEFAULT_KEY_NAME_DEBIT_NOTE_ID_API_REF,
  DEFAULT_KEY_NAME_DELIVERY_DATE_API_REF,
  DEFAULT_KEY_NAME_DESCRIPTION_API_REF,
  DEFAULT_KEY_NAME_DESTINATION_ACCOUNT_ID_API_REF,
  DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_API_REF,
  DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_CONTAINER,
  DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
  DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
  DEFAULT_KEY_NAME_DISCOUNT_TYPE_API_REF,
  DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_API_REF,
  DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_CONTAINER,
  DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE,
  DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_API_REF,
  DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_CONTAINER,
  DEFAULT_KEY_NAME_EXPENSE_DATA_API,
  DEFAULT_KEY_NAME_EXPENSE_ID_API_REF,
  DEFAULT_KEY_NAME_EXPENSE_VALUE,
  DEFAULT_KEY_NAME_EXPIRED_DATE_API_REF,
  DEFAULT_KEY_NAME_INVOICE_PAYMENT_API_REF,
  DEFAULT_KEY_NAME_IS_NEW_CONTACT_DATA,
  DEFAULT_KEY_NAME_IS_PAID_OFF_API_REF,
  DEFAULT_KEY_NAME_IS_SENT_API_REF,
  DEFAULT_KEY_NAME_ITEM_API_REF,
  DEFAULT_KEY_NAME_JOURNAL_ENTRY_LIST_API_REF,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_MODUL_ID,
  DEFAULT_KEY_NAME_MODUL_ID_API_REF,
  DEFAULT_KEY_NAME_MODUL_ID_CONTAINER,
  DEFAULT_KEY_NAME_MODUL_TYPE_API_REF,
  DEFAULT_KEY_NAME_ON_BEHALF_API_REF,
  DEFAULT_KEY_NAME_ORIGIN_ACCOUNT_ID_API_REF,
  DEFAULT_KEY_NAME_ORIGIN_ACCOUNT_ID_CONTAINER,
  DEFAULT_KEY_NAME_PARAMS_URL_CREDIT_NOTE_ID,
  DEFAULT_KEY_NAME_PARAMS_URL_DEBITE_NOTE_ID,
  DEFAULT_KEY_NAME_PARAMS_URL_EXPENSE_ID,
  DEFAULT_KEY_NAME_PARAMS_URL_PAYABLE_PAYMENT_ID,
  DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_DELIVERY_HANDOVER_ID,
  DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_DELIVERY_ID,
  DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_INVOICE_ID,
  DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_ORDER_ID,
  DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_PAYMENT_ID,
  DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_QUOTATION_ID,
  DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_RETURN_ID,
  DEFAULT_KEY_NAME_PARAMS_URL_RECEIVABLE_PAYMENT_ID,
  DEFAULT_KEY_NAME_PARAMS_URL_SALES_DELIVERY_HANDOVER_ID,
  DEFAULT_KEY_NAME_PARAMS_URL_SALES_DELIVERY_ID,
  DEFAULT_KEY_NAME_PARAMS_URL_SALES_INVOICE_ID,
  DEFAULT_KEY_NAME_PARAMS_URL_SALES_ORDER_ID,
  DEFAULT_KEY_NAME_PARAMS_URL_SALES_PAYMENT_ID,
  DEFAULT_KEY_NAME_PARAMS_URL_SALES_QUOTATION_ID,
  DEFAULT_KEY_NAME_PARAMS_URL_SALES_RETURN_ID,
  DEFAULT_KEY_NAME_PARAMS_URL_TRANSACTION_ID,
  DEFAULT_KEY_NAME_PAYABLE_PAYMENT_ID_API_REF,
  DEFAULT_KEY_NAME_PREV_PAGE_STATUS,
  DEFAULT_KEY_NAME_PRICE_API_REF,
  DEFAULT_KEY_NAME_PRODUCT_API,
  DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
  DEFAULT_KEY_NAME_PRODUCT_PURCHASE_HANDOVER_DETAILS_API_REF,
  DEFAULT_KEY_NAME_PRODUCT_SALES_HANDOVER_DETAILS,
  DEFAULT_KEY_NAME_PRODUCT_SALES_ORDER_DETAILS,
  DEFAULT_KEY_NAME_PRODUCT_SALES_QUOTATION_DETAILS,
  DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF,
  DEFAULT_KEY_NAME_PURCHASE_DELIVERY_NO_API_REF,
  DEFAULT_KEY_NAME_PURCHASE_HANDOVER_ID_API_REF,
  DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF,
  DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_CONTAINER,
  DEFAULT_KEY_NAME_PURCHASE_INVOICE_NO_API_REF,
  DEFAULT_KEY_NAME_PURCHASE_INVOICE_PAYMENT_API_REF,
  DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_API_REF,
  DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_CONTAINER,
  DEFAULT_KEY_NAME_PURCHASE_ORDER_NO_API_REF,
  DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_REF_API,
  DEFAULT_KEY_NAME_PURCHASE_PAYMENT_ID_API_REF,
  DEFAULT_KEY_NAME_PURCHASE_PAYMENT_NO_API_REF,
  DEFAULT_KEY_NAME_PURCHASE_PAYMENT_NO_CONTAINER,
  DEFAULT_KEY_NAME_PURCHASE_RETURN_ID_API_REF,
  DEFAULT_KEY_NAME_PURCHASE_RETURN_PRODUCT_API_REF,
  DEFAULT_KEY_NAME_QUANTITY_API_REF,
  DEFAULT_KEY_NAME_QUANTITY_REAL_API_REF,
  DEFAULT_KEY_NAME_QUOTATION_REQUEST_ID_API_REF,
  DEFAULT_KEY_NAME_QUOTATION_REQUEST_ID_CONTAINER,
  DEFAULT_KEY_NAME_QUOTATION_REQUEST_PRODUCT_REF_API,
  DEFAULT_KEY_NAME_RECEIPT_CASH_ID_API_REF,
  DEFAULT_KEY_NAME_RECEIVABLE_PAYMENT_ID_API_REF,
  DEFAULT_KEY_NAME_REFERENCE_NUMBER,
  DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER,
  DEFAULT_KEY_NAME_REFERENCE_NUMBER_ID_API_REF,
  DEFAULT_KEY_NAME_REQUEST_STATUS,
  DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
  DEFAULT_KEY_NAME_RESPONSE_DATA_API_REF,
  DEFAULT_KEY_NAME_SALES_AGENT_ID_API_REF,
  DEFAULT_KEY_NAME_SALES_AGENT_ID_CONTAINER,
  DEFAULT_KEY_NAME_SALES_AGENT_NAME_API_REF,
  DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF,
  DEFAULT_KEY_NAME_SALES_DELIVERY_NO_API_REF,
  DEFAULT_KEY_NAME_SALES_HANDOVER_ID_API_REF,
  DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF,
  DEFAULT_KEY_NAME_SALES_INVOICE_ID_CONTAINER,
  DEFAULT_KEY_NAME_SALES_INVOICE_NO_API_REF,
  DEFAULT_KEY_NAME_SALES_INVOICE_PAYMENT_DETAILS,
  DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF,
  DEFAULT_KEY_NAME_SALES_ORDER_ID_CONTAINER,
  DEFAULT_KEY_NAME_SALES_ORDER_NO_API_REF,
  DEFAULT_KEY_NAME_SALES_PAYMENT_ID_API_REF,
  DEFAULT_KEY_NAME_SALES_PAYMENT_NO_API_REF,
  DEFAULT_KEY_NAME_SALES_PAYMENT_NO_CONTAINER,
  DEFAULT_KEY_NAME_SALES_QUOTATION_ID_API_REF,
  DEFAULT_KEY_NAME_SALES_QUOTATION_ID_CONTAINER,
  DEFAULT_KEY_NAME_SALES_RETURN_ID_API_REF,
  DEFAULT_KEY_NAME_SALES_RETURN_PRODUCT_API_REF,
  DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_PURCHASE_DELIVERY_DATA,
  DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_PURCHASE_ORDER_DATA,
  DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_QUOTATION_REQUEST_DATA,
  DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_DELIVERY_DATA,
  DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_ORDER_DATA,
  DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_QUOTATION_DATA,
  DEFAULT_KEY_NAME_SELECTED_CONTACT_DATA,
  DEFAULT_KEY_NAME_SELECTED_TAB,
  DEFAULT_KEY_NAME_SHIPPING_ADDRESS_API_REF,
  DEFAULT_KEY_NAME_SHOW_ALL_PRODUCT_API_REF,
  DEFAULT_KEY_NAME_STATUS_PAGE,
  DEFAULT_KEY_NAME_STATUS_REF_API,
  DEFAULT_KEY_NAME_STATUS_REQUEST,
  DEFAULT_KEY_NAME_STOCK_ADJUSTMENT_ID_API_REF,
  DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF,
  DEFAULT_KEY_NAME_SUPPLIER_ADDRESS_API_REF,
  DEFAULT_KEY_NAME_SUPPLIER_CONTAINER_ID_API_REF,
  DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF,
  DEFAULT_KEY_NAME_SUPPLIER_NAME_API_REF,
  DEFAULT_KEY_NAME_TAG_API_REF,
  DEFAULT_KEY_NAME_TAG_NAME_API_REF,
  DEFAULT_KEY_NAME_TODAY,
  DEFAULT_KEY_NAME_TODAYS_DATE,
  DEFAULT_KEY_NAME_TRANSACTION_DATA,
  DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
  DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF,
  DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF,
  DEFAULT_KEY_NAME_TRANSACTION_NO_CONTAINER,
  DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
  DEFAULT_KEY_NAME_TRANSFER_CASH_ID_API_REF,
  DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
  DEFAULT_KEY_NAME_UPDATED_AT_API,
  DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
  DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF,
  DEFAULT_KEY_NAME_WAREHOUSE_ID_CONTAINER,
  DEFAULT_KEY_NAME_WAREHOUSE_NAME_API_REF,
  DEFAULT_KEY_NAME_WAREHOUSE_TYPE_API_REF,
  TAG_CONTAINER_KEY_NAME,
  TAG_NAME_INPUT_KEY_NAME,
} from './object-keyname.default';
import { DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION_SECONDARY } from './params.default';
import {
  DEFAULT_PURCHASE_INVOICE_STATUS_PAID,
  DEFAULT_PURCHASE_INVOICE_STATUS_PARTIALLY_PAID,
  DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_OPEN,
  DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_PARTIALLY_PAID,
  DEFAULT_STATUS_PURCHASE_DELIVERY_OPEN,
  DEFAULT_STATUS_PURCHASE_INVOICE_OPEN,
  DEFAULT_STATUS_PURCHASE_ORDER_OPEN,
  DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_OPEN,
  DEFAULT_STATUS_PURCHASE_RETURN_OPEN,
} from './purchase-status.default';
import { DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_PAID } from './purchase-status.default';
import {
  DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
  DEFAULT_REPRESENTATION_DATA_NUMBER_TYPE,
} from './representation-data-type.default';
import {
  DEFAULT_STATUS_SALES_CREDIT_NOTE_OPEN,
  DEFAULT_STATUS_SALES_CREDIT_NOTE_PAID,
  DEFAULT_STATUS_SALES_CREDIT_NOTE_PARTIALLY_PAID,
  DEFAULT_STATUS_SALES_DELIVERY_OPEN,
  DEFAULT_STATUS_SALES_DELIVERY_PARTIALLY_SENT,
  DEFAULT_STATUS_SALES_INVOICE_OPEN,
  DEFAULT_STATUS_SALES_INVOICE_PAID,
  DEFAULT_STATUS_SALES_INVOICE_PARTIALLY_PAID,
  DEFAULT_STATUS_SALES_ORDER_OPEN,
  DEFAULT_STATUS_SALES_ORDER_PARTIALLY_SENT,
  DEFAULT_STATUS_SALES_QUOTATION_OPEN,
  DEFAULT_STATUS_SALES_RETURN_OPEN,
} from './sales-status.default';
import {
  DEFAULT_PAGE_STATUS_DETAILS_TRANSACTION,
  DEFAULT_PAGE_STATUS_PAY_TRANSACTION,
  DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA,
  DEFAULT_STATUS_PAGE_ADD_EXPENSE,
  DEFAULT_STATUS_PAGE_ADD_PURCHASE_DELIVERY,
  DEFAULT_STATUS_PAGE_ADD_PURCHASE_INVOICE,
  DEFAULT_STATUS_PAGE_ADD_PURCHASE_ORDER,
  DEFAULT_STATUS_PAGE_ADD_PURCHASE_PAYMENT,
  DEFAULT_STATUS_PAGE_ADD_QUOTATION_REQUEST,
  DEFAULT_STATUS_PAGE_ADD_SALES_DELIVERY,
  DEFAULT_STATUS_PAGE_ADD_SALES_INVOICE,
  DEFAULT_STATUS_PAGE_ADD_SALES_ORDER,
  DEFAULT_STATUS_PAGE_ADD_SALES_PAYMENT,
  DEFAULT_STATUS_PAGE_ADD_SALES_QUOTATION,
  DEFAULT_STATUS_PAGE_DETAIL_ASSET_MANAGEMENT,
  DEFAULT_STATUS_PAGE_DETAIL_CASH_BANK_CASH_TRANSFER,
  DEFAULT_STATUS_PAGE_DETAIL_CASH_PAYMENT,
  DEFAULT_STATUS_PAGE_DETAIL_CLOSING_BOOK,
  DEFAULT_STATUS_PAGE_DETAIL_DATA_CONTACT,
  DEFAULT_STATUS_PAGE_DETAIL_LEDGER_JOURNAL_ENTRY,
  DEFAULT_STATUS_PAGE_LIST_EXPENSE,
  DEFAULT_STATUS_PAGE_LIST_PURCHASE_DELIVERY,
  DEFAULT_STATUS_PAGE_LIST_PURCHASE_INVOICE,
  DEFAULT_STATUS_PAGE_LIST_PURCHASE_ORDER,
  DEFAULT_STATUS_PAGE_LIST_PURCHASE_PAYMENT,
  DEFAULT_STATUS_PAGE_LIST_QUOTATION_REQUEST,
  DEFAULT_STATUS_PAGE_LIST_SALES_DELIVERY,
  DEFAULT_STATUS_PAGE_LIST_SALES_INVOICE,
  DEFAULT_STATUS_PAGE_LIST_SALES_ORDER,
  DEFAULT_STATUS_PAGE_LIST_SALES_PAYMENT,
  DEFAULT_STATUS_PAGE_LIST_SALES_QUOTATION,
} from './status-page.default';
import {
  DEFAULT_CLASSNAME_PAPER_PRIMARY_MD_HEIGHT_LAYOUT_PRIMARY,
  DEFAULT_CLASSNAME_PAPER_PRIMARY_SM_HEIGHT_LAYOUT_PRIMARY,
  DEFAULT_CLASSNAME_PAPER_SECONDARY_MD_HEIGHT_LAYOUT_PRIMARY,
  DEFAULT_CLASSNAME_PAPER_SECONDARY_SM_HEIGHT_LAYOUT_PRIMARY,
  DEFAULT_CLASSNAME_TABLE_CONTAINER_CONSTTANT_HEIGHT_MD_SIZE,
  DEFAULT_CLASSNAME_TABLE_CONTAINER_MD_SIZE,
} from './styles-classname.default';
import {
  DEFAULT_TAB_NAME_CASH_BANK_CASH_PAYMENT,
  DEFAULT_TAB_NAME_CASH_BANK_CASH_TRANSFER,
  DEFAULT_TAB_NAME_PURCHASE_PURCHASE_DELIVERY,
  DEFAULT_TAB_NAME_PURCHASE_PURCHASE_INVOICE,
  DEFAULT_TAB_NAME_PURCHASE_PURCHASE_ORDER,
  DEFAULT_TAB_NAME_PURCHASE_PURCHASE_PAYMENT,
  DEFAULT_TAB_NAME_PURCHASE_QUOTATION_REQUEST,
  DEFAULT_TAB_NAME_SALES_SALES_DELIVERY,
  DEFAULT_TAB_NAME_SALES_SALES_INVOICE,
  DEFAULT_TAB_NAME_SALES_SALES_ORDER,
  DEFAULT_TAB_NAME_SALES_SALES_PAYMENT,
  DEFAULT_TAB_NAME_SALES_SALES_QUOTATION,
} from './tab-name.default';
import {
  DEFAULT_TRANSACTION_NUMBER_CODE_PAYABLE_PAYMENT,
  DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_PAYMENT,
  DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_RETURN,
  DEFAULT_TRANSACTION_NUMBER_CODE_RECEIVABLE_PAYMENT,
  DEFAULT_TRANSACTION_NUMBER_CODE_SALES_PAYMENT,
  DEFAULT_TRANSACTION_NUMBER_CODE_SALES_RETURN,
} from './transaction-number.default';
import {
  DEFAULT_TRANSACTION_NUMBER_CODE_EXPENSE,
  DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_DELIVERY,
  DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_INVOICE,
  DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_ORDER,
  DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_QUOTATION_REQUEST,
  DEFAULT_TRANSACTION_NUMBER_CODE_SALES_DELIVERY,
  DEFAULT_TRANSACTION_NUMBER_CODE_SALES_INVOICE,
  DEFAULT_TRANSACTION_NUMBER_CODE_SALES_ORDER,
  DEFAULT_TRANSACTION_NUMBER_CODE_SALES_QUOTATION,
} from './transaction-number.default';
import {
  DEFAULT_TRANSACTION_ASSET_DEPRECIATION_API_REF_TYPE,
  DEFAULT_TRANSACTION_ASSET_ENTRY_API_REF_TYPE,
  DEFAULT_TRANSACTION_ASSET_RESIDUAL_API_REF_TYPE,
  DEFAULT_TRANSACTION_BEGINNING_BALANCE_API_REF,
  DEFAULT_TRANSACTION_CLOSING_BOOK_API_REF_TYPE,
  DEFAULT_TRANSACTION_EXPENSE_API_REF_TYPE,
  DEFAULT_TRANSACTION_JOURNAL_ENTRY_API_REF_TYPE,
  DEFAULT_TRANSACTION_PAYMENT_CASH_API_REF_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_DEBIT_NOTE_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_DELIVERY_API_REF_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_HANDOVER_API_REF_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_INVOICE_API_REF_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_ORDER_API_REF_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_PAYMENT_API_REF_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_PURCHASE_INVOICE_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_PURCHASE_ORDER_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_PURCHASE_PAYMENT_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_PURCHASE_RETURN_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_API_REF_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_RECEIVABLE_PAYMENT_API_REF_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_RECEIVABLE_PAYMENT_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_RETURN_API_REF_TYPE,
  DEFAULT_TRANSACTION_RECEIPT_CASH_API_REF_TYPE,
  DEFAULT_TRANSACTION_SALES_CREDIT_NOTE_TYPE,
  DEFAULT_TRANSACTION_SALES_DELIVERY_API_REF_TYPE,
  DEFAULT_TRANSACTION_SALES_INVOICE_API_REF_TYPE,
  DEFAULT_TRANSACTION_SALES_ORDER_API_REF_TYPE,
  DEFAULT_TRANSACTION_SALES_PAYABLE_PAYMENT_API_REF_TYPE,
  DEFAULT_TRANSACTION_SALES_PAYABLE_PAYMENT_TYPE,
  DEFAULT_TRANSACTION_SALES_PAYMENT_API_REF_TYPE,
  DEFAULT_TRANSACTION_SALES_QUOTATION_API_REF_TYPE,
  DEFAULT_TRANSACTION_SALES_RETURN_API_REF_TYPE,
  DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE,
  DEFAULT_TRANSACTION_SALES_SALES_INVOICE_TYPE,
  DEFAULT_TRANSACTION_SALES_SALES_ORDER_TYPE,
  DEFAULT_TRANSACTION_SALES_SALES_PAYMENT_TYPE,
  DEFAULT_TRANSACTION_SALES_SALES_QUOTATION_TYPE,
  DEFAULT_TRANSACTION_SALES_SALES_RETURN_TYPE,
  DEFAULT_TRANSACTION_STOCK_ADJUSTMENT_API_REF_TYPE,
  DEFAULT_TRANSACTION_TRANSFER_CASH_API_REF_TYPE,
  DEFAULT_TRANSACTION_TYPE_PURCHASE_HANDOVER,
  DEFAULT_TRANSACTION_TYPE_SALES_HANDOVER,
  transactionInvoiceTypePurchaseInvoice,
  transactionInvoiceTypeSalesInvoice,
} from './transaction-type';
import { hasImplementedUnitProductUnit } from './unit-product.default';
import { WAREHOUSE_MAIN_TYPE } from './warehouse-type.default';

export const DEFAULT_TRANSACTION_SALES_TYPE = 'sales';
export const DEFAULT_TRANSACTION_PURCHASE_TYPE = 'purchase';
export const DEFAULT_TRANSACTION_EXPENSE_TYPE = 'expense';

export const DEFAULT_REPRESENTATION_DATA_COMMON_TYPE = 'common';
export const DEFAULT_REPRESENTATION_DATA_LINK_TYPE = 'link';
export const DEFAULT_REPRESENTATION_DATA_LINK_COMMON_CONDITIONAL_TYPE =
  'link-common-with-conditional';
export const DEFAULT_REPRESENTATION_DATA_CHIP_TYPE = 'chip';
export const DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE = 'bold-typography';
export const DEFAULT_REPRESENTATION_DATA_BOX_TYPE = 'box';
export const DEFAULT_REPRESENTATION_DATA_DROPZONE_DETAILS_TYPE = 'dropzone-details';
export const DEFAULT_REPRESENTATION_DATA_HORIZONTAL_LINE_TYPE = 'horizontal-line';
export const DEFAULT_REPRESENTATION_DATA_CHIP_WITH_PARENT_LABEL_TYPE = 'chip-with-parent-label';

export const DEFAULT_COMMON_DETAIL_TYPE = 'common-details';
export const DEFAULT_HANDOVER_DETAIL_TYPE = 'handover-details';

export const keyNameTransactionData = 'transactionData';
export const keyNameTransactionValidation = 'transactionValidation';
export const keyNameProductTransactionData = 'productTransactionData';
export const keyNameProductTransactionValidation = 'productTransactionValidation';

export const defaultFirstGridProps = {
  xs: 1,
};

export const defaultSecondGridProps = {
  xs: 2,
};

export const defaultSecondPointQuarterGridProps = {
  xs: 2.25,
};

export const defaultFourthGridProps = {
  xs: 4,
};

export const defaultFourthPointHalfGridProps = {
  xs: 4.5,
};

export const defaultFifthGridProps = {
  xs: 5,
};

export const defaultSixGridProps = {
  xs: 6,
};

export const defaultSeventhGridProps = {
  xs: 7,
};

export const defaultSeventPointHalfGridProps = {
  xs: 7.5,
};

export const defaultNineGridProps = {
  xs: 9,
};

const defaultGridProps = {
  xs: 3,
};

const defaultGridPropsSecondary = {
  xs: 2.4,
};

const allTransactionTypes = [
  DEFAULT_TRANSACTION_SALES_TYPE,
  DEFAULT_TRANSACTION_PURCHASE_TYPE,
  DEFAULT_TRANSACTION_EXPENSE_TYPE,
];

const allSubDetailsType = [DEFAULT_COMMON_DETAIL_TYPE, DEFAULT_HANDOVER_DETAIL_TYPE];

export const defaultAccountAutocompleteProps = {
  fetchAction: getAccountList,
  additionalParamsForAction: {},
  removeDataAction: clearAccountData,
  additionalParamsForScrollAction: {
    isRewriteAll: false,
  },
  getOptionLabel: (options) =>
    autocompleteHelper.getInputValue(
      options,
      ['account_name', 'account_code'],
      ['account_name'],
      formatHelp.formatAccountNameWithAccountCode,
    ),
  isOptionEqualToValue: (option, value) => {
    return (
      option[DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF] === value[DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF]
    );
  },
  isUseFetchOnInput: true,
  keyNameFetchInput: 'account_name',
};

const defaultKeyNameMaximumDownPaymentValue = [
  {
    name: DEFAULT_KEY_NAME_AMOUNT_CONST,
    sign: '+',
  },
  {
    name: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
    sign: '-',
  },
  {
    name: DEFAULT_KEY_NAME_EXPENSE_VALUE,
    sign: '+',
  },
];

const defaultKeynamesCalculationTotalAfterDiscount = [
  {
    name: DEFAULT_KEY_NAME_AMOUNT_CONST,
    sign: '+',
  },
  {
    name: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
    sign: '-',
  },
];

const defaultKeynamesCalculationTotalAfterDiscountReturnVersion = [
  {
    name: 'amount_const',
    sign: '+',
  },
  {
    name: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
    sign: '-',
  },
];

const tagAutocompleteInputProps = {
  label: 'label.tag',
  placeholder: 'placeholder.select-tag',
  inputName: 'tag_name',
  storeName: ROOT_STORE_NAME_TAG,
  childrenStoreName: SLICE_NAME_TAG_LIST,
  keyNameRequestStatus: DEFAULT_KEY_NAME_REQUEST_STATUS,
  keyUniqueName: DEFAULT_KEY_NAME_TAG_NAME_API_REF,
  keyInputName: DEFAULT_KEY_NAME_TAG_API_REF,
  // use only when keyInputName is different with keyname for store input autocomplete
  keyInputNameWrapper: TAG_NAME_INPUT_KEY_NAME,
  keyInputContainerName: TAG_CONTAINER_KEY_NAME,
  statusRequestListPending: REQUEST_STATUS_LIST_TAG_LOADING,
  inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
  gridProps: defaultGridProps,
  representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
  autocompleteProps: {
    fetchAction: getTagList,
    additionalParamsForScrollAction: {
      isRewriteAll: false,
    },
    getOptionLabel: (options) => {
      return options[DEFAULT_KEY_NAME_TAG_NAME_API_REF];
    },
    isOptionEqualToValue: (option, value) => isEqual(option, value),
    isUseFetchOnInput: true,
    keyNameFetchInput: DEFAULT_KEY_NAME_TAG_NAME_API_REF,
    limitTags: 2,
    multiple: true,
  },
  isUseTextInput: true,
  isUseAdditionalButtonNotFound: true,
  additionalButtonNotFoundProps: {
    textNotFound: 'label.plus-new-tag-name',
    keyNameIsNew: null,
    useTranslationParams: true,
    translationParamsKeyname: 'newTagName',
    autoSelectOnClick: true,
  },
};

const defaultAdditionalGridPropsSecondarySchemaInput = {
  gridContainerProps: {
    direction: 'row',
  },
  gridLabelProps: {
    xs: 5,
  },
  gridValueProps: {
    xs: 7,
  },
};

const totalAfterDiscountSchema = {
  isTypography: true,
  label: 'label.total-after-discount',
  keyNameValue: 'totalAfterDiscount',
  typographyValueProps: {
    variant: 'body6',
  },
  isCalculateBasedKeyname: true,
  calculateKeynames: defaultKeynamesCalculationTotalAfterDiscount,
  representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
  emptyValueSubstitute: 0,
};

function isTransactionAlreadyPaid({ details, statusKeyname = 'status', paidValues = '' }) {
  return !isEmpty(details) && paidValues && paidValues.includes(details[statusKeyname]);
}

function isTransactionAlreadyReturned({
  details,
  amountKeyname = 'amount',
  amountReturnKeyname = 'amount_return',
}) {
  return (
    !isEmpty(details) && Number(details[amountKeyname]) === Number(details[amountReturnKeyname])
  );
}

function calculateTotalFromDetails({
  details,
  totalKeyname = 'total',
  discountNominalKeyname = 'discount_nominal',
  expenseValueKeyname = 'expense_value',
  downPaymentValueKeyname = 'down_payment_value',
}) {
  return (
    Number(details[totalKeyname] || 0) -
    Number(details[discountNominalKeyname] || 0) +
    Number(details[expenseValueKeyname] || 0) -
    Number(details[downPaymentValueKeyname] || 0)
  );
}

function parseAdditionalCostData({
  details,
  discountNominalKeyname = 'discount_nominal',
  discountPercentageKeyname = 'discount_percentage',
  expenseValueKeyname = 'expense_value',
  downPaymentValueKeyname = 'down_payment_value',
  resultDiscountKeyname = 'discount',
  resultExpenseKeyname = 'expense',
  resultDownPaymentKeyname = 'down_payment',
}) {
  if (isEmpty(details)) {
    return {
      [resultDiscountKeyname]: 0,
      [resultExpenseKeyname]: 0,
      [resultDownPaymentKeyname]: 0,
    };
  }

  details = objHelper.changeFormatValue(
    details,
    [
      discountNominalKeyname,
      discountPercentageKeyname,
      expenseValueKeyname,
      downPaymentValueKeyname,
    ],
    [],
    [(value) => Number(value) || 0],
  );

  const discountNominalNpercentage = strHelp.templateString('{0} ({1}%)', [
    formatHelp.currencyWithAutoComma(Number(details[discountNominalKeyname])),
    Number(details[discountPercentageKeyname]),
  ]);

  return {
    [resultDiscountKeyname]: discountNominalNpercentage,
    [resultExpenseKeyname]: formatHelp.currencyWithAutoComma(Number(details[expenseValueKeyname])),
    [resultDownPaymentKeyname]: formatHelp.currencyWithAutoComma(
      Number(details[downPaymentValueKeyname]),
    ),
  };
}

function isPurchaseInvoiceAlreadyPaidOrAlreadyReturned({ details }) {
  return (
    isTransactionAlreadyPaid({
      details,
      paidValues: [
        DEFAULT_PURCHASE_INVOICE_STATUS_PAID,
        DEFAULT_PURCHASE_INVOICE_STATUS_PARTIALLY_PAID,
      ],
    }) ||
    isTransactionAlreadyReturned({
      details,
    })
  );
}

function isSalesInvoiceAlreadyPaidOrAlreadyReturned({ details }) {
  return (
    isTransactionAlreadyPaid({
      details,
      paidValues: [DEFAULT_STATUS_SALES_INVOICE_PAID, DEFAULT_STATUS_SALES_INVOICE_PARTIALLY_PAID],
    }) ||
    isTransactionAlreadyReturned({
      details,
    })
  );
}

export function filteringChildrenTransactionData({
  childrenTransactionData,
  childrenTransactionValidation,
  childrenTransactionDataID = '',
  identityKeyname = 'id',
  childrenTransactionDataKeyname = 'childrenTransactionData',
  childrenTransactionValidationKeyname = 'childrenTransactionValidation',
}) {
  if (
    isEmpty(childrenTransactionData) ||
    isEmpty(childrenTransactionValidation) ||
    !childrenTransactionDataID
  ) {
    return {
      childrenTransactionData,
      childrenTransactionValidation,
    };
  }

  return {
    [childrenTransactionDataKeyname]: childrenTransactionData.filter(
      (childrenTransaction) => childrenTransaction[identityKeyname] !== childrenTransactionDataID,
    ),
    [childrenTransactionValidationKeyname]: childrenTransactionValidation.filter(
      (children) => children[identityKeyname] !== childrenTransactionDataID,
    ),
  };
}

// additional cost for sales
export const defaultFormAdditionalCost = [
  // discount account autocomplete
  {
    storeName: NAME_STORE.ACCOUNT,
    childrenStoreName: SLICE_NAME_ACCOUNT_DATA,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
    keyInputName: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_API_REF,
    keyInputContainerName: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_CONTAINER,
    inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
    isAdditionalCostInputForm: true,
    statusRequestListPending: STATUS_REQUEST_LIST_ACCOUNT_DATA_PENDING,
    label: 'label.discount',
    placeholderPrimary: 'placeholder.calculation-number-default',
    placeholder: 'placeholder.choose-discount-account',
    keyNameStateCollapse: 'isCollapseOpenDiscount',
    collapseName: 'discount',
    isUseCurrencyConversion: true,
    accountAutocompleteProps: {
      ...defaultAccountAutocompleteProps,
    },
    isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
      const { status } = transactionData[0];

      return (
        actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
        !statusFreeUpdateInputTransaction.includes(status)
      );
    },
    isDisabledPrimary: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
      const { status } = transactionData[0];

      return (
        actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
        !statusFreeUpdateInputTransaction.includes(status)
      );
    },
  },
  // additional cost account autocomplete
  {
    storeName: NAME_STORE.ACCOUNT,
    childrenStoreName: SLICE_NAME_ACCOUNT_DATA,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
    keyInputName: DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_API_REF,
    keyInputContainerName: DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_CONTAINER,
    inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
    isAdditionalCostInputForm: true,
    statusRequestListPending: STATUS_REQUEST_LIST_ACCOUNT_DATA_PENDING,
    label: 'label.other-cost',
    placeholderPrimary: 'placeholder.calculation-number-default',
    placeholder: 'placeholder.choose-another-cost-account',
    keyNameStateCollapse: 'isCollapseOpenOtherCost',
    keyNameConfigurationValue: 'keyNameExpenseValue',
    collapseName: 'otherCost',
    isUseCurrencyConversion: false,
    accountAutocompleteProps: {
      ...defaultAccountAutocompleteProps,
      additionalParamsForAction: {},
      additionalParamsForScrollAction: {
        isRewriteAll: false,
      },
    },
    isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
      const { status } = transactionData[0];

      return (
        actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
        !statusFreeUpdateInputTransaction.includes(status)
      );
    },
    isDisabledPrimary: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
      const { status } = transactionData[0];

      return (
        actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
        !statusFreeUpdateInputTransaction.includes(status)
      );
    },
  },
  // down payment account autocomplete
  {
    storeName: NAME_STORE.ACCOUNT,
    childrenStoreName: SLICE_NAME_ACCOUNT_DATA,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
    keyInputName: DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_API_REF,
    keyInputContainerName: DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_CONTAINER,
    inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
    isAdditionalCostInputForm: true,
    statusRequestListPending: STATUS_REQUEST_LIST_ACCOUNT_DATA_PENDING,
    label: 'label.down-payment',
    placeholderPrimary: 'placeholder.calculation-number-default',
    placeholder: 'placeholder.choose-down-payment-account',
    keyNameStateCollapse: 'isCollapseOpenDownPayment',
    keyNameConfigurationValue: 'keyNameDownPaymentValue',
    keyNameMaximumValue: 'keyNameMaximumDownPaymentValue',
    collapseName: 'downPayment',
    isUseCurrencyConversion: false,
    accountAutocompleteProps: {
      ...defaultAccountAutocompleteProps,
      additionalParamsForAction: {
        category_name: ACCOUNT_CATEGORY_NAME_KAS_N_BANK,
      },
      additionalParamsForScrollAction: {
        category_name: ACCOUNT_CATEGORY_NAME_KAS_N_BANK,
        isRewriteAll: false,
      },
    },
    isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
      const { status } = transactionData[0];

      return (
        actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
        !statusFreeUpdateInputTransaction.includes(status)
      );
    },
    isDisabledPrimary: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
      const { status } = transactionData[0];

      return (
        actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
        !statusFreeUpdateInputTransaction.includes(status)
      );
    },
  },
];

export const additionalCostPurchaseForm = arrHelp.combineDataObjInArray(
  defaultFormAdditionalCost,
  'collapseName',
  'otherCost',
  {
    accountAutocompleteProps: {
      ...defaultAccountAutocompleteProps,
      additionalParamsForAction: {
        [DEFAULT_KEY_NAME_CATEGORY_NAME_API_REF]: ACCOUNT_CATEGORY_NAME_OTHER_EXPENSE,
      },
      additionalParamsForScrollAction: {
        [DEFAULT_KEY_NAME_CATEGORY_NAME_API_REF]: ACCOUNT_CATEGORY_NAME_OTHER_EXPENSE,
        isRewriteAll: false,
      },
    },
  },
);

export const defaultTransactionProIndexConfig = {
  [DEFAULT_TRANSACTION_PURCHASE_TYPE]: {
    parentStateName: NAME_STORE.PURCHASE,
    childrenStateTransactionHeader: '',
    isUseRequestStatusParallel: true,
    isDetailsPaperIndexShow: false,
    requestStatusParallelKeyName: DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
    generateTransactionList: () => {
      return [];
    },
    generateDefaultTransactionList: (t) => {
      return [
        {
          header: t('header.unpaid-purchase'),
          totalTransaction: 0,
          totalCalculation: DEFAULT_CALCULATION_NUMBER,
        },
        {
          header: t('header.purchase-due'),
          totalTransaction: 0,
          totalCalculation: DEFAULT_CALCULATION_NUMBER,
        },
        {
          header: t('header.last-thirty-days-purchase'),
          totalTransaction: 0,
          totalCalculation: DEFAULT_CALCULATION_NUMBER,
        },
      ];
    },
    initialStatusPage: DEFAULT_STATUS_PAGE_LIST_PURCHASE_INVOICE,
    initialTabName: DEFAULT_TAB_NAME_PURCHASE_PURCHASE_INVOICE,
    defaultAdditionalPropsTabPanelComponent: {
      isUseChangedPageOnDetails: true,
      isRemoveBoxShadowPaper: true,
      isUseLinkOnNotFoundButton: true,
    },
    transactionRequestStatusPending: [
      STATUS_REQUEST_LIST_QUOTATION_REQUEST_PENDING,
      STATUS_REQUEST_LIST_PURCHASE_ORDER_PENDING,
      STATUS_REQUEST_LIST_PURCHASE_DELIVERY_PENDING,
      STATUS_REQUEST_LIST_PURCHASE_INVOICE_PENDING,
      STATUS_REQUEST_LIST_PURCHASE_PAYMENT_PENDING,
    ],
    isUseMultipleButton: true,
    tabNameSettings: (t) => {
      return [
        {
          tabName: DEFAULT_TAB_NAME_PURCHASE_PURCHASE_INVOICE,
          statusPage: DEFAULT_STATUS_PAGE_LIST_PURCHASE_INVOICE,
          labelName: typeof t === 'function' ? t('tab.purchase-invoice') : '',
          itemName: DEFAULT_STATUS_PAGE_ADD_PURCHASE_INVOICE,
          buttonItemName:
            typeof t === 'function' ? t('button-menu.actions.create-purchase-invoice') : '',
          isPrimaryButton: true,
          refreshAction: getListPurchaseInvoiceRefresh,
          tabChildren: PurchaseInvoiceList,
          additionalPropsTabPanelComponent: {
            generateLinkDetails: (valueSelected) => {
              if (isEmpty(valueSelected)) return '';

              return strHelp.templateString(
                REACT_APP_PURCHASE_PURCHASE_INVOICE_DETAILS_TEMPLATE_URL,
                valueSelected,
              );
            },
            linkNotFoundButton: REACT_APP_PURCHASE_PURCHASE_INVOICE_CREATE_NEW_URL,
          },
          buttonLink: REACT_APP_PURCHASE_PURCHASE_INVOICE_CREATE_NEW_URL,
        },
        {
          tabName: DEFAULT_TAB_NAME_PURCHASE_QUOTATION_REQUEST,
          statusPage: DEFAULT_STATUS_PAGE_LIST_QUOTATION_REQUEST,
          labelName: typeof t === 'function' ? t('tab.quotation-request') : '',
          itemName: DEFAULT_STATUS_PAGE_ADD_QUOTATION_REQUEST,
          buttonItemName:
            typeof t === 'function' ? t('button-menu.actions.create-quotation-request') : '',
          isPrimaryButton: false,
          refreshAction: getListQuotationRequestRefresh,
          tabChildren: QuotationRequestList,
          additionalPropsTabPanelComponent: {
            generateLinkDetails: (valueSelected) => {
              if (isEmpty(valueSelected)) return '';

              return strHelp.templateString(
                REACT_APP_PURCHASE_QUOTATION_REQUEST_DETAILS_TEMPLATE_URL,
                valueSelected,
              );
            },
            linkNotFoundButton: REACT_APP_PURCHASE_QUOTATION_REQUEST_CREATE_NEW_URL,
          },
          buttonLink: REACT_APP_PURCHASE_QUOTATION_REQUEST_CREATE_NEW_URL,
        },
        {
          tabName: DEFAULT_TAB_NAME_PURCHASE_PURCHASE_ORDER,
          statusPage: DEFAULT_STATUS_PAGE_LIST_PURCHASE_ORDER,
          labelName: typeof t === 'function' ? t('tab.purchase-order') : '',
          itemName: DEFAULT_STATUS_PAGE_ADD_PURCHASE_ORDER,
          buttonItemName:
            typeof t === 'function' ? t('button-menu.actions.create-purchase-order') : '',
          isPrimaryButton: false,
          refreshAction: getListPurchaseOrderRefresh,
          tabChildren: PurchaseOrderList,
          additionalPropsTabPanelComponent: {
            generateLinkDetails: (valueSelected) => {
              if (isEmpty(valueSelected)) return '';

              return strHelp.templateString(
                REACT_APP_PURCHASE_PURCHASE_ORDER_DETAILS_TEMPLATE_URL,
                valueSelected,
              );
            },
            linkNotFoundButton: REACT_APP_PURCHASE_PURCHASE_ORDER_CREATE_NEW_URL,
          },
          buttonLink: REACT_APP_PURCHASE_PURCHASE_ORDER_CREATE_NEW_URL,
        },
        {
          tabName: DEFAULT_TAB_NAME_PURCHASE_PURCHASE_DELIVERY,
          statusPage: DEFAULT_STATUS_PAGE_LIST_PURCHASE_DELIVERY,
          labelName: typeof t === 'function' ? t('tab.purchase-delivery') : '',
          itemName: DEFAULT_STATUS_PAGE_ADD_PURCHASE_DELIVERY,
          buttonItemName:
            typeof t === 'function' ? t('button-menu.actions.create-purchase-delivery') : '',
          isPrimaryButton: false,
          refreshAction: getListPurchaseDeliveryRefresh,
          tabChildren: PurchaseDeliveryList,
          additionalPropsTabPanelComponent: {
            generateLinkDetails: (valueSelected) => {
              if (isEmpty(valueSelected)) return '';

              return strHelp.templateString(
                REACT_APP_PURCHASE_PURCHASE_DELIVERY_DETAILS_TEMPLATE_URL,
                valueSelected,
              );
            },
            linkNotFoundButton: REACT_APP_PURCHASE_PURCHASE_DELIVERY_CREATE_NEW_URL,
          },
          buttonLink: REACT_APP_PURCHASE_PURCHASE_DELIVERY_CREATE_NEW_URL,
        },
        {
          tabName: DEFAULT_TAB_NAME_PURCHASE_PURCHASE_PAYMENT,
          statusPage: DEFAULT_STATUS_PAGE_LIST_PURCHASE_PAYMENT,
          itemName: DEFAULT_STATUS_PAGE_ADD_PURCHASE_PAYMENT,
          buttonItemName:
            typeof t === 'function' ? t('button-menu.actions.create-purchase-payment') : '',
          labelName: typeof t === 'function' ? t('tab.purchase-payment') : '',
          isPrimaryButton: false,
          refreshAction: getListPurchasePaymentRefresh,
          tabChildren: PurchasePaymentList,
          additionalPropsTabPanelComponent: {
            generateLinkDetails: (valueSelected) => {
              if (isEmpty(valueSelected)) return '';

              return strHelp.templateString(
                REACT_APP_PURCHASE_PURCHASE_PAYMENT_DETAILS_TEMPLATE_URL,
                valueSelected,
              );
            },
            linkNotFoundButton: REACT_APP_PURCHASE_PURCHASE_PAYMENT_CREATE_NEW_URL,
          },
          buttonLink: REACT_APP_PURCHASE_PURCHASE_PAYMENT_CREATE_NEW_URL,
        },
      ];
    },
    availableSearchParams: [DEFAULT_KEY_NAME_SELECTED_TAB, DEFAULT_KEY_NAME_STATUS_PAGE],
    availableValuesSearchParams: [
      [
        DEFAULT_TAB_NAME_PURCHASE_QUOTATION_REQUEST,
        DEFAULT_TAB_NAME_PURCHASE_PURCHASE_ORDER,
        DEFAULT_TAB_NAME_PURCHASE_PURCHASE_DELIVERY,
        DEFAULT_TAB_NAME_PURCHASE_PURCHASE_INVOICE,
        DEFAULT_TAB_NAME_PURCHASE_PURCHASE_PAYMENT,
      ],
      [
        DEFAULT_STATUS_PAGE_LIST_QUOTATION_REQUEST,
        DEFAULT_STATUS_PAGE_LIST_PURCHASE_ORDER,
        DEFAULT_STATUS_PAGE_LIST_PURCHASE_DELIVERY,
        DEFAULT_STATUS_PAGE_LIST_PURCHASE_INVOICE,
        DEFAULT_STATUS_PAGE_LIST_PURCHASE_PAYMENT,
      ],
    ],
  },
  [DEFAULT_TRANSACTION_SALES_TYPE]: {
    parentStateName: NAME_STORE.SALES,
    childrenStateTransactionHeader: '',
    isUseRequestStatusParallel: true,
    isDetailsPaperIndexShow: false,
    requestStatusParallelKeyName: DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
    generateTransactionList: () => {
      return [];
    },
    generateDefaultTransactionList: (t) => {
      return [
        {
          header: t('header.unpaid-sales'),
          totalTransaction: 0,
          totalCalculation: DEFAULT_CALCULATION_NUMBER,
        },
        {
          header: t('header.sales-due'),
          totalTransaction: 0,
          totalCalculation: DEFAULT_CALCULATION_NUMBER,
        },
        {
          header: t('header.last-thirty-days-payment'),
          totalTransaction: 0,
          totalCalculation: DEFAULT_CALCULATION_NUMBER,
        },
      ];
    },
    initialStatusPage: DEFAULT_STATUS_PAGE_LIST_SALES_INVOICE,
    initialTabName: DEFAULT_TAB_NAME_SALES_SALES_INVOICE,
    defaultAdditionalPropsTabPanelComponent: {
      isUseChangedPageOnDetails: true,
      isRemoveBoxShadowPaper: true,
      isUseLinkOnNotFoundButton: true,
    },
    transactionRequestStatusPending: [
      STATUS_REQUEST_LIST_SALES_QUOTATION_PENDING,
      STATUS_REQUEST_LIST_SALES_ORDER_PENDING,
      STATUS_REQUEST_LIST_SALES_DELIVERY_PENDING,
      STATUS_REQUEST_LIST_SALES_INVOICE_PENDING,
      STATUS_REQUEST_LIST_SALES_PAYMENT_PENDING,
    ],
    isUseMultipleButton: true,
    tabNameSettings: (t) => {
      return [
        {
          tabName: DEFAULT_TAB_NAME_SALES_SALES_INVOICE,
          statusPage: DEFAULT_STATUS_PAGE_LIST_SALES_INVOICE,
          labelName: typeof t === 'function' ? t('tab.sales-invoice') : '',
          itemName: DEFAULT_STATUS_PAGE_ADD_SALES_INVOICE,
          buttonItemName:
            typeof t === 'function' ? t('button-menu.actions.create-sales-invoice') : '',
          isPrimaryButton: true,
          refreshAction: getListSalesInvoiceRefresh,
          tabChildren: SalesInvoiceList,
          additionalPropsTabPanelComponent: {
            generateLinkDetails: (valueSelected) => {
              if (isEmpty(valueSelected)) return '';

              return strHelp.templateString(
                REACT_APP_SALES_SALES_INVOICE_DETAILS_TEMPLATE_URL,
                valueSelected,
              );
            },
            linkNotFoundButton: REACT_APP_SALES_SALES_INVOICE_CREATE_NEW_URL,
          },
          buttonLink: REACT_APP_SALES_SALES_INVOICE_CREATE_NEW_URL,
        },
        {
          tabName: DEFAULT_TAB_NAME_SALES_SALES_QUOTATION,
          statusPage: DEFAULT_STATUS_PAGE_LIST_SALES_QUOTATION,
          labelName: typeof t === 'function' ? t('tab.sales-quotation') : '',
          itemName: DEFAULT_STATUS_PAGE_ADD_SALES_QUOTATION,
          buttonItemName:
            typeof t === 'function' ? t('button-menu.actions.create-sales-quotation') : '',
          isPrimaryButton: false,
          refreshAction: getListSalesQuotationRefresh,
          tabChildren: SalesQuotationList,
          additionalPropsTabPanelComponent: {
            generateLinkDetails: (valueSelected) => {
              if (isEmpty(valueSelected)) return '';

              return strHelp.templateString(
                REACT_APP_SALES_SALES_QUOTATION_DETAILS_TEMPLATE_URL,
                valueSelected,
              );
            },
            linkNotFoundButton: REACT_APP_SALES_SALES_QUOTATION_CREATE_NEW_URL,
          },
          buttonLink: REACT_APP_SALES_SALES_QUOTATION_CREATE_NEW_URL,
        },
        {
          tabName: DEFAULT_TAB_NAME_SALES_SALES_ORDER,
          statusPage: DEFAULT_STATUS_PAGE_LIST_SALES_ORDER,
          labelName: typeof t === 'function' ? t('tab.sales-order') : '',
          itemName: DEFAULT_STATUS_PAGE_ADD_SALES_ORDER,
          buttonItemName:
            typeof t === 'function' ? t('button-menu.actions.create-sales-order') : '',
          isPrimaryButton: false,
          refreshAction: getListSalesOrderRefresh,
          tabChildren: SalesOrderList,
          additionalPropsTabPanelComponent: {
            generateLinkDetails: (valueSelected) => {
              if (isEmpty(valueSelected)) return '';

              return strHelp.templateString(
                REACT_APP_SALES_SALES_ORDER_DETAILS_TEMPLATE_URL,
                valueSelected,
              );
            },
            linkNotFoundButton: REACT_APP_SALES_SALES_ORDER_CREATE_NEW_URL,
          },
          buttonLink: REACT_APP_SALES_SALES_ORDER_CREATE_NEW_URL,
        },
        {
          tabName: DEFAULT_TAB_NAME_SALES_SALES_DELIVERY,
          statusPage: DEFAULT_STATUS_PAGE_LIST_SALES_DELIVERY,
          labelName: typeof t === 'function' ? t('tab.sales-delivery') : '',
          itemName: DEFAULT_STATUS_PAGE_ADD_SALES_DELIVERY,
          buttonItemName:
            typeof t === 'function' ? t('button-menu.actions.create-sales-delivery') : '',
          isPrimaryButton: false,
          refreshAction: getListSalesDeliveryRefresh,
          tabChildren: SalesDeliveryList,
          additionalPropsTabPanelComponent: {
            generateLinkDetails: (valueSelected) => {
              if (isEmpty(valueSelected)) return '';

              return strHelp.templateString(
                REACT_APP_SALES_SALES_DELIVERY_DETAILS_TEMPLATE_URL,
                valueSelected,
              );
            },
            linkNotFoundButton: REACT_APP_SALES_SALES_DELIVERY_CREATE_NEW_URL,
          },
          buttonLink: REACT_APP_SALES_SALES_DELIVERY_CREATE_NEW_URL,
        },
        {
          tabName: DEFAULT_TAB_NAME_SALES_SALES_PAYMENT,
          statusPage: DEFAULT_STATUS_PAGE_LIST_SALES_PAYMENT,
          itemName: DEFAULT_STATUS_PAGE_ADD_SALES_PAYMENT,
          buttonItemName:
            typeof t === 'function' ? t('button-menu.actions.create-sales-payment') : '',
          labelName: typeof t === 'function' ? t('tab.sales-payment') : '',
          isPrimaryButton: false,
          refreshAction: getListSalesPaymentRefresh,
          tabChildren: SalesPaymentList,
          additionalPropsTabPanelComponent: {
            generateLinkDetails: (valueSelected) => {
              if (isEmpty(valueSelected)) return '';

              return strHelp.templateString(
                REACT_APP_SALES_SALES_PAYMENT_DETAILS_TEMPLATE_URL,
                valueSelected,
              );
            },
            linkNotFoundButton: REACT_APP_SALES_SALES_PAYMENT_CREATE_NEW_URL,
          },
          buttonLink: REACT_APP_SALES_SALES_PAYMENT_CREATE_NEW_URL,
        },
      ];
    },
    availableSearchParams: [DEFAULT_KEY_NAME_SELECTED_TAB, DEFAULT_KEY_NAME_STATUS_PAGE],
    availableValuesSearchParams: [
      [
        DEFAULT_TAB_NAME_SALES_SALES_QUOTATION,
        DEFAULT_TAB_NAME_SALES_SALES_ORDER,
        DEFAULT_TAB_NAME_SALES_SALES_DELIVERY,
        DEFAULT_TAB_NAME_SALES_SALES_INVOICE,
        DEFAULT_TAB_NAME_SALES_SALES_PAYMENT,
      ],
      [
        DEFAULT_STATUS_PAGE_LIST_SALES_QUOTATION,
        DEFAULT_STATUS_PAGE_LIST_SALES_ORDER,
        DEFAULT_STATUS_PAGE_LIST_SALES_DELIVERY,
        DEFAULT_STATUS_PAGE_LIST_SALES_INVOICE,
        DEFAULT_STATUS_PAGE_LIST_SALES_PAYMENT,
      ],
    ],
  },
  [DEFAULT_TRANSACTION_EXPENSE_TYPE]: {
    parentStateName: NAME_STORE.EXPENSE,
    childrenStateTransactionHeader: '',
    isUseRequestStatusParallel: false,
    isDetailsPaperIndexShow: false,
    requestStatusParallelKeyName: '',
    generateTransactionList: () => {
      return [];
    },
    initialStatusPage: DEFAULT_STATUS_PAGE_LIST_EXPENSE,
    initialTabName: '',
    defaultAdditionalPropsTabPanelComponent: {
      isUseChangedPageOnDetails: true,
      isRemoveBoxShadowPaper: true,
      isUseLinkOnNotFoundButton: true,
    },
    transactionRequestStatusPending: [STATUS_REQUEST_LIST_EXPENSE_PENDING],
    isUseMultipleButton: false,
    tabNameSettings: (t) => {
      return [
        {
          tabName: '',
          statusPage: DEFAULT_STATUS_PAGE_LIST_EXPENSE,
          labelName: typeof t === 'function' ? t('') : '',
          itemName: DEFAULT_STATUS_PAGE_ADD_EXPENSE,
          buttonItemName: typeof t === 'function' ? t('button-menu.actions.create-expense') : '',
          isPrimaryButton: true,
          refreshAction: getExpenseListRefresh,
          tabChildren: ExpenseList,
          additionalPropsTabPanelComponent: {
            generateLinkDetails: (valueSelected) => {
              if (isEmpty(valueSelected)) return '';

              return strHelp.templateString(REACT_APP_EXPENSE_DETAILS_TEMPLATE_URL, valueSelected);
            },
            linkNotFoundButton: REACT_APP_EXPENSE_CREATE_NEW_URL,
          },
          buttonLink: REACT_APP_EXPENSE_CREATE_NEW_URL,
        },
      ];
    },
    availableSearchParams: null,
    availableValuesSearchParams: null,
  },
};

export const getTransactionProIndexConfig = (
  transactionType = DEFAULT_TRANSACTION_PURCHASE_TYPE,
) => {
  if (!allTransactionTypes.includes(transactionType))
    transactionType = DEFAULT_TRANSACTION_PURCHASE_TYPE;

  return defaultTransactionProIndexConfig[transactionType];
};

const defaultDisplayLayoutStatus = {
  primaryLayout: 'flex',
  secondaryLayout: 'none',
};

export const defaultTransactionProDetailsConfig = {
  [DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_TYPE]: {
    transactionType: DEFAULT_TRANSACTION_PURCHASE_TYPE,
    parentStateName: NAME_STORE.PURCHASE,
    childrenDetailsStateName: SLICE_NAME_QUOTATION_REQUEST_DETAIL,
    requestStatusPendingGetDetails: STATUS_REQUEST_DETAIL_QUOTATION_REQUEST_PENDING,
    requestStatusFailedGetDetails: STATUS_REQUEST_DETAIL_QUOTATION_REQUEST_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_QUOTATION_ID,
    uniqueParamsGetDetails: '',
    isNotUseObjectToGetDetails: true,
    actionGetDetails: getDetailQuotationRequest,
    generateUpdateDataLink: (singleData) => {
      if (isEmpty(singleData)) return '';

      return strHelp.templateString(
        REACT_APP_PURCHASE_QUOTATION_REQUEST_UPDATE_TEMPLATE_URL,
        singleData,
      );
    },
    generalizeDataDetails: (t, dataDetails) => {
      if (isEmpty(dataDetails)) return {};

      const purchaseQuotationRequestStatusTranslated = purchaseQuotationRequestStatus(t);

      const { status, updated_at, description, amount, sales_agent_name } = dataDetails;

      let purchaseQuotationProduct =
          dataDetails[DEFAULT_KEY_NAME_QUOTATION_REQUEST_PRODUCT_REF_API],
        total = Number(amount) ? formatHelp.currencyWithAutoComma(Number(amount)) : 0,
        statusContainer = {};

      if (
        status &&
        purchaseQuotationRequestStatusTranslated &&
        Array.isArray(purchaseQuotationRequestStatusTranslated)
      ) {
        const selectedPurchaseQuotationRequestStatus = arrHelp.filterObjDataWithID(
          purchaseQuotationRequestStatusTranslated,
          'value',
          status,
        );

        statusContainer = selectedPurchaseQuotationRequestStatus;

        if (isEmpty(selectedPurchaseQuotationRequestStatus)) {
          statusContainer = purchaseQuotationRequestStatusTranslated[0];
        }
      }

      if (!isEmpty(purchaseQuotationProduct) && Array.isArray(purchaseQuotationProduct)) {
        purchaseQuotationProduct = purchaseQuotationProduct.map((singleSalesQuotation) => {
          const { description, product_unit } = singleSalesQuotation;

          const amount =
            (Number(singleSalesQuotation.price) || 0) * Number(singleSalesQuotation.quantity) || 0;

          return {
            ...singleSalesQuotation,
            price: singleSalesQuotation.price
              ? formatHelp.currencyWithAutoComma(Number(singleSalesQuotation.price))
              : 0,
            quantity: Number(singleSalesQuotation.quantity)
              ? formatHelp.currencyWithAutoComma(Number(singleSalesQuotation.quantity))
              : 0,
            amount: amount ? formatHelp.currencyWithAutoComma(amount) : 0,
            description: description || '',
            product_unit: product_unit ? strHelp.capitalize(product_unit) : '',
          };
        });
      }

      return {
        ...dataDetails,
        expired_date: dataDetails.expired_date
          ? formatHelp.getReadableDateV2(dataDetails.expired_date)
          : '-',
        transaction_date: dataDetails.transaction_date
          ? formatHelp.getReadableDateV2(dataDetails.transaction_date)
          : '-',
        [DEFAULT_KEY_NAME_QUOTATION_REQUEST_PRODUCT_REF_API]: purchaseQuotationProduct,
        total,
        statusContainer,
        updated_at: updated_at ? formatHelp.getReadableDateV2(updated_at) : '-',
        description: description || '-',
        sales_agent_name: sales_agent_name || '',
      };
    },
    primaryDetailsDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          keyNameValue: 'transaction_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.transaction-date',
          keyNameValue: 'transaction_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.expired-date',
          keyNameValue: 'expired_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
      [
        {
          label: 'label.contact-supplier',
          keyNameValue: 'supplier_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.supplier-address',
          keyNameValue: 'supplier_address',
          gridProps: {
            xs: 6,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryDetailsDataSchema: [
      [
        {
          label: 'label.status',
          keyNameValue: 'statusContainer',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CHIP_TYPE,
        },
      ],
      [
        {
          label: 'label.total',
          keyNameValue: 'total',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
        },
        {
          label: '',
          keyNameValue: 'attachment',
          additionalStackProps: {
            display: 'block',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_DROPZONE_DETAILS_TYPE,
        },
        {
          label: 'label.memo',
          keyNameValue: 'description',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    columnsTableHead: (t) => columnsTableHeaderPurchaseQuotationRequestDetailsV2(t),
    buttonGroupHeaderDetails: (t, childrenState) =>
      menuItemPurchaseQuotationRequestButtonWithButtonPropsV2(t, childrenState),
    buttonHeaderConfigs: (childrenState) =>
      buttonCollectionsPurchaseQuotationRequestDetails(childrenState),
    dataListKeyName: DEFAULT_KEY_NAME_QUOTATION_REQUEST_PRODUCT_REF_API,
    keyNameLastUpdateByName: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
    keyNameLastUpdateByTime: DEFAULT_KEY_NAME_UPDATED_AT_API,
    baseUrlDetailsNotFound: REACT_APP_PURCHASE_QUOTATION_REQUEST_URL,
    relationalSearchParamsWithStateComponent: null,
    displayLayoutStatus: defaultDisplayLayoutStatus,
    parentMenuURL: REACT_APP_PURCHASE_QUOTATION_REQUEST_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_ORDER_TYPE]: {
    transactionType: DEFAULT_TRANSACTION_PURCHASE_TYPE,
    parentStateName: NAME_STORE.PURCHASE,
    childrenDetailsStateName: SLICE_NAME_DETAIL_PURCHASE_ORDER,
    requestStatusPendingGetDetails: STATUS_REQUEST_DETAIL_PURCHASE_ORDER_PENDING,
    requestStatusFailedGetDetails: STATUS_REQUEST_DETAIL_PURCHASE_ORDER_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_ORDER_ID,
    uniqueParamsGetDetails: DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_API_REF,
    isNotUseObjectToGetDetails: false,
    actionGetDetails: getDetailPurchaseOrder,
    generateUpdateDataLink: (singleData) => {
      if (isEmpty(singleData)) return '';

      return strHelp.templateString(
        REACT_APP_PURCHASE_PURCHASE_ORDER_UPDATE_TEMPLATE_URL,
        singleData,
      );
    },
    generalizeDataDetails: (t, dataDetails) => {
      if (isEmpty(dataDetails)) return {};

      const purchaseOrderStatusTranslated = purchaseOrderStatus(t);

      const {
        status,
        updated_at,
        description,
        amount,
        sales_agent_name,
        expired_date,
        transaction_date,
      } = dataDetails;

      let salesOrderProduct = dataDetails[DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_REF_API],
        total = Number(amount) ? formatHelp.currencyWithAutoComma(Number(amount)) : 0,
        statusContainer = {};

      if (status && purchaseOrderStatusTranslated && Array.isArray(purchaseOrderStatusTranslated)) {
        const selectedPurchaseOrderStatus = arrHelp.filterObjDataWithID(
          purchaseOrderStatusTranslated,
          'value',
          status,
        );

        statusContainer = selectedPurchaseOrderStatus;

        if (isEmpty(selectedPurchaseOrderStatus)) {
          statusContainer = purchaseOrderStatusTranslated[0];
        }
      }

      if (!isEmpty(salesOrderProduct) && Array.isArray(salesOrderProduct)) {
        salesOrderProduct = salesOrderProduct.map((singleSalesOrder) => {
          const { description, product_unit } = singleSalesOrder;

          const amount =
            (Number(singleSalesOrder.price) || 0) * Number(singleSalesOrder.quantity) || 0;

          return {
            ...singleSalesOrder,
            price: singleSalesOrder.price
              ? formatHelp.currencyWithAutoComma(Number(singleSalesOrder.price))
              : 0,
            quantity: Number(singleSalesOrder.quantity)
              ? formatHelp.currencyWithAutoComma(Number(singleSalesOrder.quantity))
              : 0,
            amount: amount ? formatHelp.currencyWithAutoComma(amount) : 0,
            description: description || '',
            product_unit: product_unit ? strHelp.capitalize(product_unit) : '',
          };
        });
      }

      return {
        ...dataDetails,
        expired_date: expired_date ? formatHelp.getReadableDateV2(expired_date) : '-',
        transaction_date: transaction_date ? formatHelp.getReadableDateV2(transaction_date) : '-',
        [DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_REF_API]: salesOrderProduct,
        total,
        statusContainer,
        updated_at: updated_at ? formatHelp.getReadableDateV2(updated_at) : '-',
        description: description || '-',
        sales_agent_name: sales_agent_name || '',
      };
    },
    primaryDetailsDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          keyNameValue: 'purchase_order_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.quotation-number',
          keyNameValue: 'transaction_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_LINK_TYPE,
          generateLink: (dataDetails) => {
            if (!dataDetails || !dataDetails.transaction_no) return;

            return strHelp.templateString(
              REACT_APP_PURCHASE_QUOTATION_REQUEST_DETAILS_TEMPLATE_URL,
              dataDetails,
            );
          },
        },
        {
          label: 'label.transaction-date',
          keyNameValue: 'transaction_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.expired-date',
          keyNameValue: 'expired_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
      [
        {
          label: 'label.warehouse',
          keyNameValue: 'warehouse_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.contact-supplier',
          keyNameValue: 'supplier_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.supplier-address',
          keyNameValue: 'supplier_address',
          gridProps: {
            xs: 6,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryDetailsDataSchema: [
      [
        {
          label: 'label.status',
          keyNameValue: 'statusContainer',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CHIP_TYPE,
        },
      ],
      [
        {
          label: 'label.total',
          keyNameValue: 'total',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
        },
        {
          label: '',
          keyNameValue: 'attachment',
          additionalStackProps: {
            display: 'block',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_DROPZONE_DETAILS_TYPE,
        },
        {
          label: 'label.memo',
          keyNameValue: 'description',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    columnsTableHead: (t) => columnsTableHeaderProductDataListV2(t),
    buttonGroupHeaderDetails: (t, childrenState) =>
      menuItemPurchaseOrderButtonWithButtonPropsV2(t, childrenState),
    buttonHeaderConfigs: (childrenState) => buttonCollectionsPurchaseOrderDetails(childrenState),
    dataListKeyName: DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_REF_API,
    keyNameLastUpdateByName: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
    keyNameLastUpdateByTime: DEFAULT_KEY_NAME_UPDATED_AT_API,
    baseUrlDetailsNotFound: REACT_APP_PURCHASE_PURCHASE_ORDER_URL,
    relationalSearchParamsWithStateComponent: null,
    displayLayoutStatus: defaultDisplayLayoutStatus,
    parentMenuURL: REACT_APP_PURCHASE_PURCHASE_ORDER_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE]: {
    transactionType: DEFAULT_TRANSACTION_PURCHASE_TYPE,
    parentStateName: NAME_STORE.PURCHASE,
    childrenDetailsStateName: SLICE_NAME_PURCHASE_DELIVERY_DETAIL,
    requestStatusPendingGetDetails: STATUS_REQUEST_DETAIL_PURCHASE_DELIVERY_PENDING,
    requestStatusFailedGetDetails: STATUS_REQUEST_DETAIL_PURCHASE_DELIVERY_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_DELIVERY_ID,
    uniqueParamsGetDetails: DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF,
    isNotUseObjectToGetDetails: true,
    actionGetDetails: getDetailPurchaseDelivery,
    isWarningConfirmationMinutesHandover: ({ transactionDetails }) => {
      return (
        !isEmpty(transactionDetails) &&
        transactionDetails[DEFAULT_KEY_NAME_PURCHASE_HANDOVER_ID_API_REF]
      );
    },
    generateUpdateDataLink: (singleData) => {
      if (isEmpty(singleData)) return '';

      return strHelp.templateString(
        REACT_APP_PURCHASE_PURCHASE_DELIVERY_UPDATE_TEMPLATE_URL,
        singleData,
      );
    },
    generalizeDataDetails: (t, dataDetails) => {
      if (isEmpty(dataDetails)) return {};

      const {
        status,
        purchase_handover_status,
        updated_at,
        description,
        amount,
        sales_agent_name,
        expired_date,
        delivery_date,
      } = dataDetails;

      let purchaseDeliveryProduct = dataDetails[DEFAULT_KEY_NAME_PRODUCT_API],
        total = Number(amount) ? formatHelp.currencyWithAutoComma(Number(amount)) : 0,
        statusContainer = {},
        statusHandoverContainer = {};

      if (status && purchase_handover_status) {
        const purchaseDeliveryStatusTranslated = purchaseDeliveryStatus(t);
        const minutesHandoverStatusTranslated = minutesHandoverStatus(t);

        const selectedSalesDeliveryStatus = arrHelp.filterObjDataWithID(
          purchaseDeliveryStatusTranslated,
          'value',
          status,
        );

        const selectedMinutesHandoverStatus = arrHelp.filterObjDataWithID(
          minutesHandoverStatusTranslated,
          'value',
          purchase_handover_status,
        );

        statusContainer = selectedSalesDeliveryStatus;
        statusHandoverContainer = cloneDeep(selectedMinutesHandoverStatus);

        if (isEmpty(selectedSalesDeliveryStatus)) {
          statusContainer = purchaseDeliveryStatusTranslated[0];
        }

        if (isEmpty(selectedMinutesHandoverStatus)) {
          statusHandoverContainer = minutesHandoverStatusTranslated[0];
        }
      }

      if (!isEmpty(purchaseDeliveryProduct) && Array.isArray(purchaseDeliveryProduct)) {
        purchaseDeliveryProduct = purchaseDeliveryProduct.map((singlePurchaseDeliveryProduct) => {
          const { description, product_unit } = singlePurchaseDeliveryProduct;

          const amount =
            (Number(singlePurchaseDeliveryProduct.price) || 0) *
              Number(singlePurchaseDeliveryProduct.quantity) || 0;

          return {
            ...singlePurchaseDeliveryProduct,
            price: singlePurchaseDeliveryProduct.price
              ? formatHelp.currencyWithAutoComma(Number(singlePurchaseDeliveryProduct.price))
              : 0,
            quantity: Number(singlePurchaseDeliveryProduct.quantity)
              ? formatHelp.currencyWithAutoComma(Number(singlePurchaseDeliveryProduct.quantity))
              : 0,
            amount: amount ? formatHelp.currencyWithAutoComma(amount) : 0,
            description: description || '',
            product_unit: product_unit ? strHelp.capitalize(product_unit) : '',
          };
        });
      }

      return {
        ...dataDetails,
        expired_date: expired_date ? formatHelp.getReadableDateV2(expired_date) : '-',
        delivery_date: delivery_date ? formatHelp.getReadableDateV2(delivery_date) : '-',
        [DEFAULT_KEY_NAME_PRODUCT_API]: purchaseDeliveryProduct,
        total,
        statusContainer,
        statusHandoverContainer,
        updated_at: updated_at ? formatHelp.getReadableDateV2(updated_at) : '-',
        description: description || '-',
        sales_agent_name: sales_agent_name || '',
      };
    },
    primaryDetailsDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          keyNameValue: 'purchase_delivery_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.order-number',
          keyNameValue: 'purchase_order_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_LINK_TYPE,
          generateLink: (dataDetails) => {
            if (!dataDetails || !dataDetails.purchase_order_no) return;

            return strHelp.templateString(
              REACT_APP_PURCHASE_PURCHASE_ORDER_DETAILS_TEMPLATE_URL,
              dataDetails,
            );
          },
        },
        {
          label: 'label.transaction-date',
          keyNameValue: 'delivery_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
      [
        {
          label: 'label.warehouse',
          keyNameValue: 'warehouse_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.contact-supplier',
          keyNameValue: 'supplier_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.supplier-address',
          keyNameValue: 'supplier_address',
          gridProps: {
            xs: 6,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryDetailsDataSchema: [
      [
        {
          label: 'label.invoice-status',
          keyNameValue: 'statusContainer',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CHIP_TYPE,
        },
        {
          label: 'label.goods-receipt-status',
          keyNameValue: 'statusHandoverContainer',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CHIP_TYPE,
        },
        {
          label: 'label.bast-uppercase',
          keyNameValue: 'purchase_handover_no',
          representationDataType: DEFAULT_REPRESENTATION_DATA_LINK_COMMON_CONDITIONAL_TYPE,
          generateLink: (dataDetails) => {
            if (!dataDetails || !dataDetails.purchase_handover_no)
              return strHelp.templateString(
                REACT_APP_PURCHASE_PURCHASE_DELIVERY_CREATE_NEW_HANDOVER_TEMPLATE_URL,
                dataDetails,
              );

            return strHelp.templateString(
              REACT_APP_PURCHASE_PURCHASE_DELIVERY_HANDOVER_DETAILS_TEMPLATE_URL,
              dataDetails,
            );
          },
          generateLinkLabel: (t, dataDetails) => {
            if (!dataDetails || !dataDetails.purchase_handover_no) return t('label.create-bast');

            return dataDetails.purchase_handover_no;
          },
        },
      ],
      [
        {
          label: '',
          keyNameValue: 'attachment',
          additionalStackProps: {
            display: 'block',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_DROPZONE_DETAILS_TYPE,
        },
        {
          label: 'label.memo',
          keyNameValue: 'description',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    columnsTableHead: (t) => columnsTableHeaderProductPurchaseDeliveryDataListV2(t),
    buttonGroupHeaderDetails: (t, childrenState) =>
      menuItemPurchaseDeliveryButtonWithPropsV2(t, childrenState),
    buttonHeaderConfigs: (childrenState) => buttonCollectionsPurchaseDeliveryDetails(childrenState),
    dataListKeyName: DEFAULT_KEY_NAME_PRODUCT_API,
    keyNameLastUpdateByName: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
    keyNameLastUpdateByTime: DEFAULT_KEY_NAME_UPDATED_AT_API,
    baseUrlDetailsNotFound: REACT_APP_PURCHASE_PURCHASE_DELIVERY_URL,
    relationalSearchParamsWithStateComponent: null,
    displayLayoutStatus: defaultDisplayLayoutStatus,
    parentMenuURL: REACT_APP_PURCHASE_PURCHASE_DELIVERY_URL,
    additionalStores: [
      {
        action: (childrenState) => {
          return minutesOfHandoverDetails({
            paramsMinutesOfHandoverDetails: childrenState,
          });
        },
        isCommitEndpoint: (childrenState) =>
          !!childrenState[DEFAULT_KEY_NAME_PURCHASE_HANDOVER_ID_API_REF],
        parentStateName: ROOT_STORE_NAME_MINUTES_OF_HANDOVER,
        childrenStateName: SLICE_NAME_HANDOVER_PURCHASE_DELIVERY_DETAILS,
      },
    ],
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_INVOICE_TYPE]: {
    transactionType: DEFAULT_TRANSACTION_PURCHASE_TYPE,
    parentStateName: NAME_STORE.PURCHASE,
    childrenDetailsStateName: SLICE_NAME_DETAIL_PURCHASE_INVOICE,
    requestStatusPendingGetDetails: STATUS_REQUEST_DETAIL_PURCHASE_INVOICE_PENDING,
    requestStatusFailedGetDetails: STATUS_REQUEST_DETAIL_PURCHASE_INVOICE_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_INVOICE_ID,
    uniqueParamsGetDetails: DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF,
    isNotUseObjectToGetDetails: false,
    actionGetDetails: getDetailPurchaseInvoice,
    generateWarningConfirmationDialogText: ({ transactionDetails }) => {
      const isPurchaseInvoiceAlreadyPaid = isTransactionAlreadyPaid({
        details: transactionDetails,
        paidValues: [
          DEFAULT_PURCHASE_INVOICE_STATUS_PAID,
          DEFAULT_PURCHASE_INVOICE_STATUS_PARTIALLY_PAID,
        ],
      });

      const isPurchaseInvoiceAlreadyReturned = isTransactionAlreadyReturned({
        details: transactionDetails,
      });

      let dialogText = {
        title: 'confirmation-dialog.invoice-already-paid-and-return.primary',
        subtitle: 'confirmation-dialog.invoice-already-paid-and-return.secondary',
      };

      if (isPurchaseInvoiceAlreadyPaid && !isPurchaseInvoiceAlreadyReturned) {
        dialogText = {
          title: 'confirmation-dialog.invoice-already-paid.primary',
          subtitle: 'confirmation-dialog.invoice-already-paid.secondary',
        };
      } else if (isPurchaseInvoiceAlreadyReturned && !isPurchaseInvoiceAlreadyPaid) {
        dialogText = {
          title: 'confirmation-dialog.invoice-already-returned.primary',
          subtitle: 'confirmation-dialog.invoice-already-returned.secondary',
        };
      }

      return dialogText;
    },
    isWarningUpdateConfirmation: ({ transactionDetails }) => {
      return isPurchaseInvoiceAlreadyPaidOrAlreadyReturned({
        details: transactionDetails,
      });
    },
    isWarningDeleteConfirmation: ({ transactionDetails }) => {
      return isPurchaseInvoiceAlreadyPaidOrAlreadyReturned({
        details: transactionDetails,
      });
    },
    generateUpdateDataLink: (singleData) => {
      if (isEmpty(singleData)) return '';

      return strHelp.templateString(
        REACT_APP_PURCHASE_PURCHASE_INVOICE_UPDATE_TEMPLATE_URL,
        singleData,
      );
    },
    generalizeDataDetails: (t, dataDetails) => {
      if (isEmpty(dataDetails)) return {};

      const {
        status,
        updated_at,
        description,
        amount,
        discount_nominal = 0,
        sales_agent_name,
        expired_date,
        transaction_date,
      } = dataDetails;

      const purchaseInvoiceStatusTranslated = purchaseInvoiceStatus(t);

      let purchaseInvoiceProduct = dataDetails[DEFAULT_KEY_NAME_PRODUCT_API],
        subtotal = Number(amount) || 0,
        statusContainer = {};

      if (!isEmpty(purchaseInvoiceProduct) && Array.isArray(purchaseInvoiceProduct)) {
        purchaseInvoiceProduct = purchaseInvoiceProduct.map((singlePurchaseInvoice) => {
          const { description, product_unit } = singlePurchaseInvoice;

          const amount =
            (Number(singlePurchaseInvoice.price) || 0) * Number(singlePurchaseInvoice.quantity) ||
            0;

          return {
            ...singlePurchaseInvoice,
            price: singlePurchaseInvoice.price
              ? formatHelp.currencyWithAutoComma(Number(singlePurchaseInvoice.price))
              : 0,
            quantity: Number(singlePurchaseInvoice.quantity)
              ? formatHelp.currencyWithAutoComma(Number(singlePurchaseInvoice.quantity))
              : 0,
            amount: amount ? formatHelp.currencyWithAutoComma(amount) : 0,
            product_description: description || '',
            product_unit: product_unit ? strHelp.capitalize(product_unit) : '',
          };
        });
      }

      if (
        status &&
        purchaseInvoiceStatusTranslated &&
        Array.isArray(purchaseInvoiceStatusTranslated)
      ) {
        const selectedSalesInvoiceStatus = arrHelp.filterObjDataWithID(
          purchaseInvoiceStatusTranslated,
          'value',
          status,
        );

        statusContainer = selectedSalesInvoiceStatus;

        if (isEmpty(selectedSalesInvoiceStatus)) {
          statusContainer = purchaseInvoiceStatusTranslated[0];
        }
      }

      // calculate total with additional cost
      let total = calculateTotalFromDetails({
        details: dataDetails,
        totalKeyname: 'amount',
      });

      return {
        ...dataDetails,
        expired_date: expired_date ? formatHelp.getReadableDateV2(expired_date) : '-',
        transaction_date: transaction_date ? formatHelp.getReadableDateV2(transaction_date) : '-',
        [DEFAULT_KEY_NAME_PRODUCT_API]: purchaseInvoiceProduct,
        total,
        statusContainer,
        updated_at: updated_at ? formatHelp.getReadableDateV2(updated_at) : '-',
        description: description || '-',
        sales_agent_name: sales_agent_name || '',
        subtotal,
        totalAfterDiscount: Number(amount) - Number(discount_nominal),
        discountPerItem: '0',
        ...parseAdditionalCostData({
          details: dataDetails,
        }),
      };
    },
    primaryDetailsDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          keyNameValue: 'purchase_invoice_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.order-number',
          keyNameValue: 'purchase_order_no' || 'purchase_delivery_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_LINK_TYPE,
          generateLink: (dataDetails) => {
            if (dataDetails.modul_type === 'order') {
              if (!dataDetails || !dataDetails.purchase_order_no) return;

              return strHelp.templateString(
                REACT_APP_PURCHASE_PURCHASE_ORDER_DETAILS_TEMPLATE_URL,
                dataDetails,
              );
            }

            if (dataDetails.modul_type === 'delivery') {
              if (!dataDetails || !dataDetails.purchase_delivery_no) return;

              return strHelp.templateString(
                REACT_APP_PURCHASE_PURCHASE_DELIVERY_DETAILS_TEMPLATE_URL,
                dataDetails,
              );
            }
          },
        },
        {
          label: 'label.transaction-date',
          keyNameValue: 'transaction_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.expired-date',
          keyNameValue: 'expired_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
      [
        {
          label: 'label.warehouse',
          keyNameValue: 'warehouse_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.contact-supplier',
          keyNameValue: 'supplier_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.supplier-address',
          keyNameValue: 'supplier_address',
          gridProps: {
            xs: 6,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryDetailsDataSchema: [
      [
        {
          label: 'label.subtotal',
          keyNameValue: 'subtotal',
          typographyLabelProps: {
            variant: 'boldIndicator1',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        {
          label: 'label.discount-per-item',
          keyNameValue: 'discountPerItem',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        {
          label: 'label.additional-discount',
          keyNameValue: 'discount',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.total-after-discount',
          keyNameValue: 'totalAfterDiscount',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        {
          label: 'label.other-cost',
          keyNameValue: 'expense',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.down-payment',
          keyNameValue: 'down_payment',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        {
          representationDataType: DEFAULT_REPRESENTATION_DATA_HORIZONTAL_LINE_TYPE,
        },
        {
          label: 'label.total',
          keyNameValue: 'total',
          typographyLabelProps: {
            variant: 'boldIndicator1',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        {
          label: 'label.pays-off',
          keyNameValue: 'amount_paid',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        {
          label: 'label.retur',
          keyNameValue: 'amount_return',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        {
          representationDataType: DEFAULT_REPRESENTATION_DATA_HORIZONTAL_LINE_TYPE,
        },
        {
          label: 'label.remaining-bill',
          keyNameValue: 'amount_available',
          typographyLabelProps: {
            variant: 'boldIndicator2',
          },
          typographyValueProps: {
            fontSize: 12,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
      ],
      [
        {
          label: 'label.tags',
          keyNameValue: 'tag',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CHIP_WITH_PARENT_LABEL_TYPE,
        },
        {
          label: 'label.memo',
          keyNameValue: 'description',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
        {
          label: '',
          keyNameValue: 'attachment',
          additionalStackProps: {
            display: 'block',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_DROPZONE_DETAILS_TYPE,
        },
      ],
    ],
    columnsTableHead: (t) => columnsTableHeaderProductDataListV2(t),
    buttonGroupHeaderDetails: (t, childrenState) =>
      menuItemPurchaseInvoiceButtonWithPropsV2(t, childrenState),
    buttonHeaderConfigs: (childrenState) => buttonCollectionsPurchaseInvoiceDetails(childrenState),
    dataListKeyName: DEFAULT_KEY_NAME_PRODUCT_API,
    keyNameLastUpdateByName: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
    keyNameLastUpdateByTime: DEFAULT_KEY_NAME_UPDATED_AT_API,
    baseUrlDetailsNotFound: REACT_APP_PURCHASE_PURCHASE_INVOICE_URL,
    relationalSearchParamsWithStateComponent: null,
    displayLayoutStatus: defaultDisplayLayoutStatus,
    parentMenuURL: REACT_APP_PURCHASE_PURCHASE_INVOICE_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_PAYMENT_TYPE]: {
    transactionType: DEFAULT_TRANSACTION_PURCHASE_TYPE,
    parentStateName: NAME_STORE.PURCHASE,
    childrenDetailsStateName: NAME_STATE_DETAIL_PURCHASE_PAYMENT,
    requestStatusPendingGetDetails: STATUS_REQUEST_DETAIL_PURCHASE_PAYMENT_PENDING,
    requestStatusFailedGetDetails: STATUS_REQUEST_DETAIL_PURCHASE_PAYMENT_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_PAYMENT_ID,
    uniqueParamsGetDetails: '',
    isNotUseObjectToGetDetails: true,
    actionGetDetails: detailPurchasePayment,
    generateUpdateDataLink: (singleData) => {
      if (isEmpty(singleData)) return '';

      return strHelp.templateString(
        REACT_APP_PURCHASE_PURCHASE_PAYMENT_UPDATE_TEMPLATE_URL,
        singleData,
      );
    },
    generalizeDataDetails: (t, dataDetails) => {
      if (isEmpty(dataDetails)) return {};

      const { updated_at, description, amount, sales_agent_name, expired_date, transaction_date } =
        dataDetails;

      let purchasePaymentInvoiceList =
          dataDetails[DEFAULT_KEY_NAME_PURCHASE_INVOICE_PAYMENT_API_REF],
        total = Number(amount) ? formatHelp.currencyWithAutoComma(Number(amount)) : 0,
        statusContainer = {};

      if (!isEmpty(purchasePaymentInvoiceList) && Array.isArray(purchasePaymentInvoiceList)) {
        purchasePaymentInvoiceList = purchasePaymentInvoiceList.map((singleInvoice) => {
          const { amount, amount_available, description, sales_invoice_amount } = singleInvoice;

          return {
            ...singleInvoice,
            price: singleInvoice.price
              ? formatHelp.currencyWithAutoComma(Number(singleInvoice.price))
              : 0,
            quantity: Number(singleInvoice.quantity)
              ? formatHelp.currencyWithAutoComma(Number(singleInvoice.quantity))
              : 0,
            amount: amount ? formatHelp.currencyWithAutoComma(Number(amount)) : 0,
            amount_available: amount_available
              ? formatHelp.currencyWithAutoComma(Number(amount_available))
              : 0,
            sales_invoice_amount: sales_invoice_amount
              ? formatHelp.currencyWithAutoComma(Number(sales_invoice_amount))
              : 0,
            description: description || '',
          };
        });
      }

      return {
        ...dataDetails,
        expired_date: expired_date ? formatHelp.getReadableDateV2(expired_date) : '-',
        transaction_date: transaction_date ? formatHelp.getReadableDateV2(transaction_date) : '-',
        [DEFAULT_KEY_NAME_PURCHASE_INVOICE_PAYMENT_API_REF]: purchasePaymentInvoiceList,
        total,
        statusContainer,
        updated_at: updated_at ? formatHelp.getReadableDateV2(updated_at) : '-',
        description: description || '-',
        sales_agent_name: sales_agent_name || '',
      };
    },
    primaryDetailsDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          keyNameValue: 'purchase_payment_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.transaction-date',
          keyNameValue: 'transaction_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.expired-date',
          keyNameValue: 'expired_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.pay-to',
          keyNameValue: 'account_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
      [
        {
          label: 'label.contact-supplier',
          keyNameValue: 'supplier_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.supplier-address',
          keyNameValue: 'supplier_address',
          gridProps: {
            xs: 6,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryDetailsDataSchema: [
      [],
      [
        {
          label: 'label.total',
          keyNameValue: 'total',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
        },
        {
          label: '',
          keyNameValue: 'attachment',
          additionalStackProps: {
            display: 'block',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_DROPZONE_DETAILS_TYPE,
        },
        {
          label: 'label.memo',
          keyNameValue: 'description',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    columnsTableHead: (t) => columnsTableHeaderInvoicePurchasePaymentV2(t),
    buttonGroupHeaderDetails: (t) => menuItemPurchasePaymentButtonWithProps(t),
    buttonHeaderConfigs: buttonCollectionsPurchasePaymentDetails,
    dataListKeyName: DEFAULT_KEY_NAME_PURCHASE_INVOICE_PAYMENT_API_REF,
    keyNameLastUpdateByName: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
    keyNameLastUpdateByTime: DEFAULT_KEY_NAME_UPDATED_AT_API,
    baseUrlDetailsNotFound: REACT_APP_PURCHASE_PURCHASE_PAYMENT_URL,
    relationalSearchParamsWithStateComponent: null,
    displayLayoutStatus: defaultDisplayLayoutStatus,
    parentMenuURL: REACT_APP_PURCHASE_PURCHASE_PAYMENT_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_DEBIT_NOTE_TYPE]: {
    transactionType: DEFAULT_TRANSACTION_PURCHASE_TYPE,
    parentStateName: NAME_STORE.PURCHASE,
    childrenDetailsStateName: SLICE_NAME_PURCHASE_DEBIT_NOTE_DETAILS,
    requestStatusPendingGetDetails: STATUS_REQUEST_DETAILS_PURCHASE_DEBIT_NOTE_PENDING,
    requestStatusFailedGetDetails: STATUS_REQUEST_DETAILS_PURCHASE_DEBIT_NOTE_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_DEBITE_NOTE_ID,
    uniqueParamsGetDetails: DEFAULT_KEY_NAME_DEBIT_NOTE_ID_API_REF,
    isNotUseObjectToGetDetails: false,
    actionGetDetails: getPurchaseDebitNoteDetails,
    generateUpdateDataLink: (singleData) => {
      if (isEmpty(singleData)) return '';

      // return strHelp.templateString(REACT_APP_PURCHASE_PURCHASE_PAYMENT_UPDATE_TEMPLATE_URL, singleData)
    },
    generalizeDataDetails: (t, dataDetails) => {
      if (isEmpty(dataDetails)) return {};

      const {
        updated_at,
        description,
        amount,
        amount_available,
        expired_date,
        transaction_date,
        status,
      } = dataDetails;

      const purchaseDebitNoteStatusTranslated = purchaseDebitNoteStatus(t);

      const total = Number(amount) ? formatHelp.currencyWithAutoComma(Number(amount)) : 0,
        receive = formatHelp.currencyWithAutoComma(Number(amount) - Number(amount_available));

      let statusContainer = {};

      if (
        status &&
        purchaseDebitNoteStatusTranslated &&
        Array.isArray(purchaseDebitNoteStatusTranslated)
      ) {
        const selectedSalesInvoiceStatus = arrHelp.filterObjDataWithID(
          purchaseDebitNoteStatusTranslated,
          'value',
          status,
        );

        statusContainer = selectedSalesInvoiceStatus;

        if (isEmpty(selectedSalesInvoiceStatus)) {
          statusContainer = purchaseDebitNoteStatusTranslated[0];
        }
      }

      return {
        ...dataDetails,
        expired_date: expired_date ? formatHelp.getReadableDateV2(expired_date) : '-',
        transaction_date: transaction_date ? formatHelp.getReadableDateV2(transaction_date) : '-',
        total,
        statusContainer,
        updated_at: updated_at ? formatHelp.getReadableDateV2(updated_at) : '-',
        description: description || '-',
        receive,
      };
    },
    primaryDetailsDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          keyNameValue: 'transaction_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isInline: true,
        },
        {
          label: 'label.transaction-date',
          keyNameValue: 'transaction_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isInline: true,
        },
        {
          label: 'label.status',
          keyNameValue: 'statusContainer',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CHIP_TYPE,
          isInline: true,
        },
        {
          label: 'label.contact-supplier',
          keyNameValue: 'supplier_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isInline: false,
          additionalStackProps: {
            mt: '16px!important',
          },
        },
        {
          // label: 'label.supplier-address',
          keyNameValue: 'supplier_address',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isInline: false,
        },
        {
          label: 'label.description',
          keyNameValue: 'description',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isInline: false,
          additionalStackProps: {
            mt: '16px!important',
          },
        },
      ],
      [
        {
          label: 'label.total',
          keyNameValue: 'total',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isInline: false,
          additionalStackProps: {
            height: '95px',
            justifyContent: 'unset',
            alignItems: 'end',
          },
          additionalTypographyProps: {
            variant: 'h5',
          },
        },
        {
          label: 'label.receive',
          keyNameValue: 'receive',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isInline: false,
          additionalStackProps: {
            alignItems: 'end',
          },
          additionalTypographyProps: {
            variant: 'h5',
          },
        },
      ],
    ],
    secondaryDetailsDataSchema: [[], []],
    columnsTableHead: (t) => columnsTableHeaderPurchaseDebitNoteDetails(t),
    // buttonGroupHeaderDetails: (t) => menuItemPurchasePaymentButtonWithProps(t),
    dataListKeyName: DEFAULT_KEY_NAME_PURCHASE_INVOICE_PAYMENT_API_REF,
    keyNameLastUpdateByName: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
    keyNameLastUpdateByTime: DEFAULT_KEY_NAME_UPDATED_AT_API,
    baseUrlDetailsNotFound: REACT_APP_PURCHASE_DEBIT_NOTE_URL,
    relationalSearchParamsWithStateComponent: null,
    overrideComponentProperties: {
      minHeightPaperPrimaryDetails: '60px',
      paperPrimaryClassName: DEFAULT_CLASSNAME_PAPER_PRIMARY_SM_HEIGHT_LAYOUT_PRIMARY,
      paperSecondaryClassName: DEFAULT_CLASSNAME_PAPER_SECONDARY_MD_HEIGHT_LAYOUT_PRIMARY,
      tableContainerClassName: DEFAULT_CLASSNAME_TABLE_CONTAINER_CONSTTANT_HEIGHT_MD_SIZE,
    },
    amountSliceActionButtons: [0, 3],
    displayLayoutStatus: {
      ...defaultDisplayLayoutStatus,
      primaryLayout: 'none',
      secondaryLayout: 'block',
    },
    overrideActionButtonProps: {
      1: {
        buttonLabelTranslation: 'button.journal-entry',
        isDisabled: () => true,
        buttonProps: {
          variant: 'outlined',
          className: 'primaryButtonV2',
        },
      },
      2: {
        action: 'change-page-status',
        newPageStatus: DEFAULT_PAGE_STATUS_PAY_TRANSACTION,
        buttonLabelTranslation: 'button.receive-payment',
        isLinkComponent: false,
        isDisabled: (childrenState) => {
          if (isEmpty(childrenState)) return false;

          return (
            childrenState[DEFAULT_KEY_NAME_STATUS_REF_API] ===
            DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_PAID
          );
        },
      },
    },
    parentMenuURL: REACT_APP_PURCHASE_DEBIT_NOTE_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_RETURN_TYPE]: {
    transactionType: DEFAULT_TRANSACTION_PURCHASE_TYPE,
    parentStateName: ROOT_STORE_NAME_PURCHASE,
    childrenDetailsStateName: SLICE_NAME_PURCHASE_RETURN_DETAILS,
    requestStatusPendingGetDetails: REQUEST_STATUS_DETAILS_PURCHASE_RETURN_LOADING,
    requestStatusFailedGetDetails: REQUEST_STATUS_DETAILS_PURCHASE_RETURN_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_RETURN_ID,
    uniqueParamsGetDetails: DEFAULT_KEY_NAME_PURCHASE_RETURN_ID_API_REF,
    isNotUseObjectToGetDetails: false,
    actionGetDetails: getPurchaseReturnDetails,
    generateUpdateDataLink: (singleData) => {
      if (isEmpty(singleData)) return '';

      return strHelp.templateString(REACT_APP_PURCHASE_RETURN_UPDATE_TEMPLATE_URL, singleData);
    },
    generalizeDataDetails: (t, dataDetails) => {
      if (isEmpty(dataDetails)) return {};

      const { updated_at, description, transaction_date, discount_nominal = 0 } = dataDetails;

      let purchaseReturnProductList = dataDetails[DEFAULT_KEY_NAME_PURCHASE_RETURN_PRODUCT_API_REF];
      if (!isEmpty(purchaseReturnProductList) && Array.isArray(purchaseReturnProductList)) {
        purchaseReturnProductList = purchaseReturnProductList.map((singleInvoice) => {
          const { quantity, price, description } = singleInvoice;

          return {
            ...singleInvoice,
            amount: Number(quantity) * Number(price),
            description: description || '',
          };
        });
      }

      const subtotal = arrHelp.sumValueArrV2(
        arrHelp.mapObjKeyOnArr(purchaseReturnProductList, 'amount'),
      );

      return {
        ...dataDetails,
        transaction_date: transaction_date ? formatHelp.getReadableDateV2(transaction_date) : '-',
        updated_at: updated_at ? formatHelp.getReadableDateV2(updated_at) : '-',
        subtotal: Number(subtotal),
        total: Number(subtotal) - Number(discount_nominal),
        description: description || '',
        [DEFAULT_KEY_NAME_PURCHASE_RETURN_PRODUCT_API_REF]: purchaseReturnProductList,
      };
    },
    primaryDetailsDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          keyNameValue: 'transaction_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.transaction-number',
          keyNameValue: 'purchase_invoice_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_LINK_TYPE,
          generateLink: (dataDetails) => {
            if (isEmpty(dataDetails)) return '';

            return strHelp.templateString(
              REACT_APP_PURCHASE_PURCHASE_INVOICE_DETAILS_TEMPLATE_URL,
              dataDetails,
            );
          },
        },
        {
          label: 'label.transaction-date',
          keyNameValue: 'transaction_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
      [
        {
          label: 'label.contact-supplier',
          keyNameValue: 'supplier_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.supplier-address',
          keyNameValue: 'supplier_address',
          gridProps: {
            xs: 6,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryDetailsDataSchema: [
      [
        // {
        //     label: 'label.invoice-value',
        //     useTemplateLabel: true,
        //     templateLabel: '{label} {purchase_invoice_no}',
        //     keyNameValue: 'amount',
        //     typographyLabelProps: {
        //         variant: 'boldIndicator1',
        //     },
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
        //     formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        // },
        // {
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_HORIZONTAL_LINE_TYPE,
        // },
        {
          label: 'label.subtotal',
          keyNameValue: 'subtotal',
          typographyLabelProps: {
            variant: 'boldIndicator1',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        // {
        //     label: 'label.discount-per-item',
        //     keyNameValue: 'discountPerItem',
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        //     formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        // },
        {
          label: 'label.additional-discount',
          keyNameValue: 'discount_nominal',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        // {
        //     label: 'label.total-after-discount',
        //     keyNameValue: 'totalAfterDiscount',
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        //     formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        // },
        {
          representationDataType: DEFAULT_REPRESENTATION_DATA_HORIZONTAL_LINE_TYPE,
        },
        {
          label: 'label.total',
          keyNameValue: 'total',
          typographyLabelProps: {
            variant: 'boldIndicator1',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        // {
        //     label: 'label.pays-off',
        //     keyNameValue: 'amount_paid',
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        //     formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        // },
        // {
        //     label: 'label.retur',
        //     keyNameValue: 'amount_return',
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        //     formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        // },
        // {
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_HORIZONTAL_LINE_TYPE,
        // },
        // {
        //     label: 'label.remaining-bill',
        //     keyNameValue: 'amount_available',
        //     typographyLabelProps: {
        //         variant: 'boldIndicator2',
        //     },
        //     typographyValueProps: {
        //         fontSize: 12,
        //     },
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
        //     formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        // },
      ],
      [
        {
          label: 'label.tags',
          keyNameValue: 'tag',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CHIP_WITH_PARENT_LABEL_TYPE,
        },
        {
          label: 'label.memo',
          keyNameValue: 'description',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
        {
          label: '',
          keyNameValue: 'attachment',
          additionalStackProps: {
            display: 'block',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_DROPZONE_DETAILS_TYPE,
        },
      ],
    ],
    columnsTableHead: (t) => columnsTableHeaderProductDataListV2(t),
    buttonGroupHeaderDetails: (t) => menuItemPurchaseReturnButtonWithProps(t),
    buttonHeaderConfigs: buttonCollectionsPurchaseReturnDetails,
    dataListKeyName: DEFAULT_KEY_NAME_PURCHASE_RETURN_PRODUCT_API_REF,
    keyNameLastUpdateByName: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
    keyNameLastUpdateByTime: DEFAULT_KEY_NAME_UPDATED_AT_API,
    baseUrlDetailsNotFound: REACT_APP_PURCHASE_RETURN_URL,
    relationalSearchParamsWithStateComponent: null,
    parentMenuURL: REACT_APP_PURCHASE_RETURN_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_RECEIVABLE_PAYMENT_TYPE]: {
    transactionType: DEFAULT_TRANSACTION_PURCHASE_TYPE,
    parentStateName: ROOT_STORE_NAME_PURCHASE,
    childrenDetailsStateName: SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_DETAILS,
    requestStatusPendingGetDetails: REQUEST_STATUS_DETAILS_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
    requestStatusFailedGetDetails: REQUEST_STATUS_DETAILS_PURCHASE_RECEIVABLE_PAYMENT_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_RECEIVABLE_PAYMENT_ID,
    uniqueParamsGetDetails: DEFAULT_KEY_NAME_RECEIVABLE_PAYMENT_ID_API_REF,
    isNotUseObjectToGetDetails: false,
    actionGetDetails: getPurchaseReceivablePaymentDetails,
    generateUpdateDataLink: (singleData) => {
      if (isEmpty(singleData)) return '';

      return strHelp.templateString(
        REACT_APP_PURCHASE_RECEIVABLE_PAYMENT_UPDATE_TEMPLATE_URL,
        singleData,
      );
    },
    generalizeDataDetails: (t, dataDetails) => {
      if (isEmpty(dataDetails)) return {};

      const { updated_at, description, amount, transaction_date } = dataDetails;

      const total = Number(amount) ? formatHelp.currencyWithAutoComma(Number(amount)) : 0;

      let purchaseReceivablePaymentItems = dataDetails.item;
      if (
        !isEmpty(purchaseReceivablePaymentItems) &&
        Array.isArray(purchaseReceivablePaymentItems)
      ) {
        purchaseReceivablePaymentItems = purchaseReceivablePaymentItems.map((singleItem) => {
          const { amount, amount_paid, description, debit_note_no, amount_available } = singleItem;

          return {
            ...singleItem,
            amount: Number(amount),
            amount_paid: Number(amount_paid),
            amount_available: Number(amount_available),
            debit_note_no: debit_note_no || '-',
            description: description || '',
          };
        });
      }

      return {
        ...dataDetails,
        transaction_date: transaction_date ? formatHelp.getReadableDateV2(transaction_date) : '-',
        total,
        updated_at: updated_at ? formatHelp.getReadableDateV2(updated_at) : '-',
        description: description || '',
        item: purchaseReceivablePaymentItems,
      };
    },
    primaryDetailsDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          keyNameValue: 'transaction_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.transaction-date',
          keyNameValue: 'transaction_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.recipient-account',
          keyNameValue: 'recipient_account',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
      [
        {
          label: 'label.contact-supplier',
          keyNameValue: 'supplier_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.supplier-address',
          keyNameValue: 'supplier_address',
          gridProps: {
            xs: 6,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryDetailsDataSchema: [
      [
        {
          label: 'label.total',
          keyNameValue: 'total',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
        },
      ],
      [
        {
          label: '',
          keyNameValue: 'attachment',
          additionalStackProps: {
            display: 'block',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_DROPZONE_DETAILS_TYPE,
        },
        {
          label: 'label.memo',
          keyNameValue: 'description',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    columnsTableHead: (t) => columnsTableHeaderDebitNoteListOnPurchaseReceivablePayment(t),
    buttonGroupHeaderDetails: (t) => menuItemPurchaseReceivablePaymentButtonWithProps(t),
    buttonHeaderConfigs: buttonCollectionsHistoryTransactionOnly,
    dataListKeyName: 'item',
    keyNameLastUpdateByName: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
    keyNameLastUpdateByTime: DEFAULT_KEY_NAME_UPDATED_AT_API,
    baseUrlDetailsNotFound: REACT_APP_PURCHASE_RECEIVABLE_PAYMENT_URL,
    relationalSearchParamsWithStateComponent: null,
    parentMenuURL: REACT_APP_PURCHASE_RECEIVABLE_PAYMENT_URL,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_QUOTATION_TYPE]: {
    transactionType: DEFAULT_TRANSACTION_SALES_TYPE,
    parentStateName: NAME_STORE.SALES,
    childrenDetailsStateName: SLICE_NAME_SALES_QUOTATION_DETAILS,
    requestStatusPendingGetDetails: STATUS_REQUEST_DETAIL_SALES_QUOTATION_PENDING,
    requestStatusFailedGetDetails: STATUS_REQUEST_DETAIL_SALES_QUOTATION_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_SALES_QUOTATION_ID,
    uniqueParamsGetDetails: '',
    isNotUseObjectToGetDetails: true,
    actionGetDetails: getDetailSalesQuotation,
    generateUpdateDataLink: (singleData) => {
      if (isEmpty(singleData)) return '';

      return strHelp.templateString(
        REACT_APP_SALES_SALES_QUOTATION_UPDATE_TEMPLATE_URL,
        singleData,
      );
    },
    generalizeDataDetails: (t, dataDetails) => {
      if (isEmpty(dataDetails)) return {};

      const salesQuotationStatus = salesQoutationStatusV2(t);

      const { status, updated_at, description, amount, sales_agent_name } = dataDetails;

      let salesQuotationProduct = dataDetails[DEFAULT_KEY_NAME_PRODUCT_SALES_QUOTATION_DETAILS],
        total = Number(amount) ? formatHelp.currencyWithAutoComma(Number(amount)) : 0,
        statusContainer = {};

      if (status && salesQuotationStatus && Array.isArray(salesQuotationStatus)) {
        const selectedSalesQuotationStatus = arrHelp.filterObjDataWithID(
          salesQuotationStatus,
          'value',
          status,
        );

        statusContainer = selectedSalesQuotationStatus;

        if (isEmpty(selectedSalesQuotationStatus)) {
          statusContainer = salesQuotationStatus[0];
        }
      }

      if (!isEmpty(salesQuotationProduct) && Array.isArray(salesQuotationProduct)) {
        salesQuotationProduct = salesQuotationProduct.map((singleSalesQuotation) => {
          const { description, product_unit } = singleSalesQuotation;

          const amount =
            (Number(singleSalesQuotation.price) || 0) * Number(singleSalesQuotation.quantity) || 0;

          return {
            ...singleSalesQuotation,
            price: singleSalesQuotation.price
              ? formatHelp.currencyWithAutoComma(Number(singleSalesQuotation.price))
              : 0,
            quantity: Number(singleSalesQuotation.quantity)
              ? formatHelp.currencyWithAutoComma(Number(singleSalesQuotation.quantity))
              : 0,
            amount: amount ? formatHelp.currencyWithAutoComma(amount) : 0,
            description: description || '',
            product_unit: product_unit ? strHelp.capitalize(product_unit) : '',
          };
        });
      }

      return {
        ...dataDetails,
        expired_date: dataDetails.expired_date
          ? formatHelp.getReadableDateV2(dataDetails.expired_date)
          : '-',
        transaction_date: dataDetails.transaction_date
          ? formatHelp.getReadableDateV2(dataDetails.transaction_date)
          : '-',
        [DEFAULT_KEY_NAME_PRODUCT_SALES_QUOTATION_DETAILS]: salesQuotationProduct,
        total,
        statusContainer,
        updated_at: updated_at ? formatHelp.getReadableDateV2(updated_at) : '-',
        description: description || '-',
        sales_agent_name: sales_agent_name || '',
      };
    },
    primaryDetailsDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          keyNameValue: 'transaction_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.transaction-date',
          keyNameValue: 'transaction_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.expired-date',
          keyNameValue: 'expired_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
      [
        {
          label: 'label.contact-customer',
          keyNameValue: 'customer_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.customer-address',
          keyNameValue: 'customer_address',
          gridProps: {
            xs: 6,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryDetailsDataSchema: [
      [
        {
          label: 'label.status',
          keyNameValue: 'statusContainer',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CHIP_TYPE,
        },
        {
          label: 'label.salesman',
          keyNameValue: 'sales_agent_name',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
      [
        {
          label: 'label.total',
          keyNameValue: 'total',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
        },
        {
          label: '',
          keyNameValue: 'attachment',
          additionalStackProps: {
            display: 'block',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_DROPZONE_DETAILS_TYPE,
        },
        {
          label: 'label.memo',
          keyNameValue: 'description',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    columnsTableHead: (t) => columnsTableHeaderSalesQuotationDetailsV2(t),
    buttonGroupHeaderDetails: (t, childrenState) =>
      menuItemSalesQuotationButtonWithButtonPropsV2(t, childrenState),
    buttonHeaderConfigs: (childrenState) => buttonCollectionsSalesQuotationDetails(childrenState),
    dataListKeyName: DEFAULT_KEY_NAME_PRODUCT_SALES_QUOTATION_DETAILS,
    keyNameLastUpdateByName: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
    keyNameLastUpdateByTime: DEFAULT_KEY_NAME_UPDATED_AT_API,
    baseUrlDetailsNotFound: REACT_APP_SALES_SALES_QUOTATION_URL,
    relationalSearchParamsWithStateComponent: null,
    parentMenuURL: REACT_APP_SALES_SALES_QUOTATION_URL,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_ORDER_TYPE]: {
    transactionType: DEFAULT_TRANSACTION_SALES_TYPE,
    parentStateName: NAME_STORE.SALES,
    childrenDetailsStateName: SLICE_NAME_SALES_ORDER_DETAILS,
    requestStatusPendingGetDetails: STATUS_REQUEST_DETAIL_SALES_ORDER_PENDING,
    requestStatusFailedGetDetails: STATUS_REQUEST_DETAIL_SALES_ORDER_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_SALES_ORDER_ID,
    uniqueParamsGetDetails: 'sales_order_id',
    isNotUseObjectToGetDetails: false,
    actionGetDetails: getDetailSalesOrder,
    generateUpdateDataLink: (singleData) => {
      if (isEmpty(singleData)) return '';

      return strHelp.templateString(REACT_APP_SALES_SALES_ORDER_UPDATE_TEMPLATE_URL, singleData);
    },
    generalizeDataDetails: (t, dataDetails) => {
      if (isEmpty(dataDetails)) return {};

      const salesOrderStatusTransalated = salesOrderStatus(t);

      const {
        status,
        updated_at,
        description,
        amount,
        sales_agent_name,
        expired_date,
        transaction_date,
      } = dataDetails;

      let salesOrderProduct = dataDetails[DEFAULT_KEY_NAME_PRODUCT_SALES_ORDER_DETAILS],
        total = Number(amount) ? formatHelp.currencyWithAutoComma(Number(amount)) : 0,
        statusContainer = {};

      if (status && salesOrderStatusTransalated && Array.isArray(salesOrderStatusTransalated)) {
        const selectedSalesOrderStatus = arrHelp.filterObjDataWithID(
          salesOrderStatusTransalated,
          'value',
          status,
        );

        statusContainer = selectedSalesOrderStatus;

        if (isEmpty(selectedSalesOrderStatus)) {
          statusContainer = salesOrderStatusTransalated[0];
        }
      }

      if (!isEmpty(salesOrderProduct) && Array.isArray(salesOrderProduct)) {
        salesOrderProduct = salesOrderProduct.map((singleSalesOrder) => {
          const { description, product_unit } = singleSalesOrder;

          const amount =
            (Number(singleSalesOrder.price) || 0) * Number(singleSalesOrder.quantity) || 0;

          return {
            ...singleSalesOrder,
            price: singleSalesOrder.price ? Number(singleSalesOrder.price) : 0,
            quantity: Number(singleSalesOrder.quantity) ? Number(singleSalesOrder.quantity) : 0,
            amount: amount ? Number(amount) : 0,
            description: description || '',
            product_unit: product_unit ? strHelp.capitalize(product_unit) : '',
          };
        });
      }

      return {
        ...dataDetails,
        expired_date: expired_date ? formatHelp.getReadableDateV2(expired_date) : '-',
        transaction_date: transaction_date ? formatHelp.getReadableDateV2(transaction_date) : '-',
        [DEFAULT_KEY_NAME_PRODUCT_SALES_ORDER_DETAILS]: salesOrderProduct,
        total,
        statusContainer,
        updated_at: updated_at ? formatHelp.getReadableDateV2(updated_at) : '-',
        description: description || '-',
        sales_agent_name: sales_agent_name || '',
      };
    },
    primaryDetailsDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          keyNameValue: 'sales_order_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.quotation-number',
          keyNameValue: 'sales_quotation_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_LINK_TYPE,
          generateLink: (dataDetails) => {
            if (!dataDetails || !dataDetails[DEFAULT_KEY_NAME_SALES_QUOTATION_ID_API_REF])
              return '';

            return strHelp.templateString(
              REACT_APP_SALES_SALES_QUOTATION_DETAILS_TEMPLATE_URL,
              dataDetails,
            );
          },
        },
        {
          label: 'label.transaction-date',
          keyNameValue: 'transaction_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.expired-date',
          keyNameValue: 'expired_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
      [
        {
          label: 'label.warehouse',
          keyNameValue: 'warehouse_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.contact-customer',
          keyNameValue: 'customer_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.customer-address',
          keyNameValue: 'customer_address',
          gridProps: {
            xs: 6,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryDetailsDataSchema: [
      [
        {
          label: 'label.status',
          keyNameValue: 'statusContainer',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CHIP_TYPE,
        },
        {
          label: 'label.salesman-constant',
          keyNameValue: 'sales_agent_name',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
      [
        {
          label: 'label.total',
          keyNameValue: 'total',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
        },
        {
          label: '',
          keyNameValue: 'attachment',
          additionalStackProps: {
            display: 'block',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_DROPZONE_DETAILS_TYPE,
        },
        {
          label: 'label.memo',
          keyNameValue: 'description',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    columnsTableHead: (t) => columnsTableHeaderProductDataListV2(t),
    buttonGroupHeaderDetails: (t, childrenState) =>
      menuItemSalesOrderButtonWithButtonPropsV2(t, childrenState),
    buttonHeaderConfigs: (childrenState) => buttonCollectionsSalesOrderDetails(childrenState),
    dataListKeyName: DEFAULT_KEY_NAME_PRODUCT_SALES_ORDER_DETAILS,
    keyNameLastUpdateByName: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
    keyNameLastUpdateByTime: DEFAULT_KEY_NAME_UPDATED_AT_API,
    baseUrlDetailsNotFound: REACT_APP_SALES_SALES_ORDER_URL,
    relationalSearchParamsWithStateComponent: null,
    displayLayoutStatus: defaultDisplayLayoutStatus,
    parentMenuURL: REACT_APP_SALES_SALES_ORDER_URL,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE]: {
    transactionType: DEFAULT_TRANSACTION_SALES_TYPE,
    parentStateName: NAME_STORE.SALES,
    childrenDetailsStateName: SLICE_NAME_SALES_DELIVERY_DETAILS,
    requestStatusPendingGetDetails: STATUS_REQUEST_DETAIL_SALES_DELIVERY_PENDING,
    requestStatusFailedGetDetails: STATUS_REQUEST_DETAIL_SALES_DELIVERY_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_SALES_DELIVERY_ID,
    uniqueParamsGetDetails: 'sales_delivery_id',
    isNotUseObjectToGetDetails: false,
    actionGetDetails: getSalesDeliveryDetails,
    isWarningConfirmationMinutesHandover: ({ transactionDetails }) => {
      return (
        !isEmpty(transactionDetails) &&
        transactionDetails[DEFAULT_KEY_NAME_SALES_HANDOVER_ID_API_REF]
      );
    },
    generateUpdateDataLink: (singleData) => {
      if (isEmpty(singleData)) return '';

      return strHelp.templateString(REACT_APP_SALES_SALES_DELIVERY_UPDATE_TEMPLATE_URL, singleData);
    },
    generalizeDataDetails: (t, dataDetails) => {
      if (isEmpty(dataDetails)) return {};

      const salesDeliveryStatusTranslated = salesDeliveryStatus(t);

      const {
        status,
        updated_at,
        description,
        amount,
        sales_agent_name,
        expired_date,
        delivery_date,
      } = dataDetails;

      let salesDeliveryProduct = dataDetails[DEFAULT_KEY_NAME_PRODUCT_API],
        total = Number(amount) ? formatHelp.currencyWithAutoComma(Number(amount)) : 0,
        statusContainer = {};

      if (status && salesDeliveryStatusTranslated && Array.isArray(salesDeliveryStatusTranslated)) {
        const selectedSalesDeliveryStatus = arrHelp.filterObjDataWithID(
          salesDeliveryStatusTranslated,
          'value',
          status,
        );

        statusContainer = selectedSalesDeliveryStatus;

        if (isEmpty(selectedSalesDeliveryStatus)) {
          statusContainer = salesDeliveryStatusTranslated[0];
        }
      }

      if (!isEmpty(salesDeliveryProduct) && Array.isArray(salesDeliveryProduct)) {
        salesDeliveryProduct = salesDeliveryProduct.map((singleSalesOrder) => {
          const { description, product_unit } = singleSalesOrder;

          const amount =
            (Number(singleSalesOrder.price) || 0) * Number(singleSalesOrder.quantity) || 0;

          return {
            ...singleSalesOrder,
            price: singleSalesOrder.price
              ? formatHelp.currencyWithAutoComma(Number(singleSalesOrder.price))
              : 0,
            quantity: Number(singleSalesOrder.quantity)
              ? formatHelp.currencyWithAutoComma(Number(singleSalesOrder.quantity))
              : 0,
            amount: amount ? formatHelp.currencyWithAutoComma(amount) : 0,
            description: description || '',
            product_unit: product_unit ? strHelp.capitalize(product_unit) : '',
          };
        });
      }

      return {
        ...dataDetails,
        expired_date: expired_date ? formatHelp.getReadableDateV2(expired_date) : '-',
        delivery_date: delivery_date ? formatHelp.getReadableDateV2(delivery_date) : '-',
        [DEFAULT_KEY_NAME_PRODUCT_API]: salesDeliveryProduct,
        total,
        statusContainer,
        updated_at: updated_at ? formatHelp.getReadableDateV2(updated_at) : '-',
        description: description || '-',
        sales_agent_name: sales_agent_name || '',
      };
    },
    primaryDetailsDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          keyNameValue: 'sales_delivery_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.order-number',
          keyNameValue: 'sales_order_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.transaction-date',
          keyNameValue: 'delivery_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
      [
        {
          label: 'label.warehouse',
          keyNameValue: 'warehouse_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.contact-customer',
          keyNameValue: 'customer_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.customer-address',
          keyNameValue: 'customer_address',
          gridProps: {
            xs: 6,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryDetailsDataSchema: [
      [
        {
          label: 'label.status',
          keyNameValue: 'statusContainer',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CHIP_TYPE,
        },
        {
          label: 'label.salesman',
          keyNameValue: 'sales_agent_name',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.bast-uppercase',
          keyNameValue: 'sales_handover_no',
          representationDataType: DEFAULT_REPRESENTATION_DATA_LINK_COMMON_CONDITIONAL_TYPE,
          generateLink: (dataDetails) => {
            if (!dataDetails || !dataDetails.sales_handover_no)
              return strHelp.templateString(
                REACT_APP_SALES_SALES_DELIVERY_CREATE_NEW_HANDOVER_TEMPLATE_URL,
                dataDetails,
              );

            return strHelp.templateString(
              REACT_APP_SALES_SALES_DELIVERY_HANDOVER_DETAILS_TEMPLATE_URL,
              dataDetails,
            );
          },
          generateLinkLabel: (t, dataDetails) => {
            if (!dataDetails || !dataDetails.sales_handover_no) return t('label.create-bast');

            return dataDetails.sales_handover_no;
          },
        },
      ],
      [
        {
          label: '',
          keyNameValue: 'attachment',
          additionalStackProps: {
            display: 'block',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_DROPZONE_DETAILS_TYPE,
        },
        {
          label: 'label.memo',
          keyNameValue: 'description',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    columnsTableHead: (t) => columnsTableHeaderSalesDeliveryProductDataListV2(t),
    buttonGroupHeaderDetails: (t, childrenState) =>
      menuItemSalesDeliveryButtonWithPropsV2(t, childrenState),
    buttonHeaderConfigs: (childrenState) => buttonCollectionsSalesDeliveryDetails(childrenState),
    dataListKeyName: DEFAULT_KEY_NAME_PRODUCT_API,
    keyNameLastUpdateByName: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
    keyNameLastUpdateByTime: DEFAULT_KEY_NAME_UPDATED_AT_API,
    baseUrlDetailsNotFound: REACT_APP_SALES_SALES_DELIVERY_URL,
    relationalSearchParamsWithStateComponent: null,
    displayLayoutStatus: defaultDisplayLayoutStatus,
    parentMenuURL: REACT_APP_SALES_SALES_DELIVERY_URL,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_INVOICE_TYPE]: {
    transactionType: DEFAULT_TRANSACTION_SALES_TYPE,
    parentStateName: NAME_STORE.SALES,
    childrenDetailsStateName: SLICE_NAME_SALES_INVOICE_DETAILS,
    requestStatusPendingGetDetails: STATUS_REQUEST_DETAIL_SALES_INVOICE_PENDING,
    requestStatusFailedGetDetails: STATUS_REQUEST_DETAIL_SALES_INVOICE_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_SALES_INVOICE_ID,
    uniqueParamsGetDetails: DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF,
    isNotUseObjectToGetDetails: false,
    actionGetDetails: getDetailSalesInvoice,
    generateWarningConfirmationDialogText: ({ transactionDetails }) => {
      const isSalesInvoiceAlreadyPaid = isTransactionAlreadyPaid({
        details: transactionDetails,
        paidValues: [
          DEFAULT_STATUS_SALES_INVOICE_PAID,
          DEFAULT_STATUS_SALES_INVOICE_PARTIALLY_PAID,
        ],
      });

      const isSalesInvoiceAlreadyReturned = isTransactionAlreadyReturned({
        details: transactionDetails,
      });

      let dialogText = {
        title: 'confirmation-dialog.invoice-already-paid-and-return.primary',
        subtitle: 'confirmation-dialog.invoice-already-paid-and-return.secondary',
      };

      if (isSalesInvoiceAlreadyPaid && !isSalesInvoiceAlreadyReturned) {
        dialogText = {
          title: 'confirmation-dialog.invoice-already-paid.primary',
          subtitle: 'confirmation-dialog.invoice-already-paid.secondary',
        };
      } else if (isSalesInvoiceAlreadyReturned && !isSalesInvoiceAlreadyPaid) {
        dialogText = {
          title: 'confirmation-dialog.invoice-already-returned.primary',
          subtitle: 'confirmation-dialog.invoice-already-returned.secondary',
        };
      }

      return dialogText;
    },
    isWarningUpdateConfirmation: ({ transactionDetails }) => {
      return isSalesInvoiceAlreadyPaidOrAlreadyReturned({
        details: transactionDetails,
      });
    },
    isWarningDeleteConfirmation: ({ transactionDetails }) => {
      return isSalesInvoiceAlreadyPaidOrAlreadyReturned({
        details: transactionDetails,
      });
    },
    generateUpdateDataLink: (singleData) => {
      if (isEmpty(singleData)) return '';

      return strHelp.templateString(REACT_APP_SALES_SALES_INVOICE_UPDATE_TEMPLATE_URL, singleData);
    },
    generalizeDataDetails: (t, dataDetails) => {
      if (isEmpty(dataDetails)) return {};

      const {
        status,
        updated_at,
        description,
        amount = 0,
        discount_nominal = 0,
        sales_agent_name,
        expired_date,
        transaction_date,
      } = dataDetails;

      const salesInvoiceStatusTranslated = salesInvoiceStatus(t);

      let salesInvoiceProduct = dataDetails[DEFAULT_KEY_NAME_PRODUCT_API],
        subtotal = Number(amount) || 0,
        statusContainer = {};

      if (!isEmpty(salesInvoiceProduct) && Array.isArray(salesInvoiceProduct)) {
        salesInvoiceProduct = salesInvoiceProduct.map((singleSalesInvoice) => {
          const { description, product_unit } = singleSalesInvoice;

          const amount =
            (Number(singleSalesInvoice.price) || 0) * Number(singleSalesInvoice.quantity) || 0;

          return {
            ...singleSalesInvoice,
            price: singleSalesInvoice.price ? Number(singleSalesInvoice.price) : 0,
            quantity: Number(singleSalesInvoice.quantity) ? Number(singleSalesInvoice.quantity) : 0,
            amount: amount ? Number(amount) : 0,
            description: description || '',
            product_unit: product_unit ? strHelp.capitalize(product_unit) : '',
          };
        });
      }

      if (status && salesInvoiceStatusTranslated && Array.isArray(salesInvoiceStatusTranslated)) {
        const selectedSalesInvoiceStatus = arrHelp.filterObjDataWithID(
          salesInvoiceStatusTranslated,
          'value',
          status,
        );

        statusContainer = selectedSalesInvoiceStatus;

        if (isEmpty(selectedSalesInvoiceStatus)) {
          statusContainer = salesInvoiceStatusTranslated[0];
        }
      }

      // calculate total after calculate with additional cost
      let total = calculateTotalFromDetails({
        details: dataDetails,
        totalKeyname: 'amount',
      });

      return {
        ...dataDetails,
        expired_date: expired_date ? formatHelp.getReadableDateV2(expired_date) : '-',
        transaction_date: transaction_date ? formatHelp.getReadableDateV2(transaction_date) : '-',
        [DEFAULT_KEY_NAME_PRODUCT_API]: salesInvoiceProduct,
        total,
        statusContainer,
        updated_at: updated_at ? formatHelp.getReadableDateV2(updated_at) : '-',
        description: description || '-',
        sales_agent_name: sales_agent_name || '',
        subtotal,
        totalAfterDiscount: Number(amount) - Number(discount_nominal),
        discountPerItem: '0',
        ...parseAdditionalCostData({
          details: dataDetails,
        }),
      };
    },
    primaryDetailsDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          keyNameValue: 'sales_invoice_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.order-number',
          keyNameValue: 'sales_delivery_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.transaction-date',
          keyNameValue: 'transaction_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.expired-date',
          keyNameValue: 'expired_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
      [
        {
          label: 'label.warehouse',
          keyNameValue: 'warehouse_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.contact-customer',
          keyNameValue: 'customer_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.customer-address',
          keyNameValue: 'customer_address',
          gridProps: {
            xs: 6,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryDetailsDataSchema: [
      [
        // {
        //     label: 'label.status',
        //     keyNameValue: 'statusContainer',
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_CHIP_TYPE,
        // },
        {
          label: 'label.subtotal',
          keyNameValue: 'subtotal',
          typographyLabelProps: {
            variant: 'boldIndicator1',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        {
          label: 'label.discount-per-item',
          keyNameValue: 'discountPerItem',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        {
          label: 'label.additional-discount',
          keyNameValue: 'discount',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.total-after-discount',
          keyNameValue: 'totalAfterDiscount',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        // {
        //     label: 'label.on-behalf',
        //     keyNameValue: DEFAULT_KEY_NAME_ON_BEHALF_API_REF,
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        // },
        // {
        //     label: 'label.bast-uppercase',
        //     keyNameValue: 'sales_handover_no',
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        // },
        {
          label: 'label.other-cost',
          keyNameValue: 'expense',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        {
          label: 'label.down-payment',
          keyNameValue: 'down_payment',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        {
          representationDataType: DEFAULT_REPRESENTATION_DATA_HORIZONTAL_LINE_TYPE,
        },
        {
          label: 'label.total',
          keyNameValue: 'total',
          typographyLabelProps: {
            variant: 'boldIndicator1',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        {
          label: 'label.pays-off',
          keyNameValue: 'amount_paid',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        {
          label: 'label.retur',
          keyNameValue: 'amount_return',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        {
          representationDataType: DEFAULT_REPRESENTATION_DATA_HORIZONTAL_LINE_TYPE,
        },
        {
          label: 'label.remaining-bill',
          keyNameValue: 'amount_available',
          typographyLabelProps: {
            variant: 'boldIndicator2',
          },
          typographyValueProps: {
            fontSize: 12,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
      ],
      [
        {
          label: 'label.salesman',
          keyNameValue: 'sales_agent_name',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.tags',
          keyNameValue: 'tag',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CHIP_WITH_PARENT_LABEL_TYPE,
        },
        {
          label: 'label.memo',
          keyNameValue: 'description',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
        {
          label: '',
          keyNameValue: 'attachment',
          additionalStackProps: {
            display: 'block',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_DROPZONE_DETAILS_TYPE,
        },
      ],
    ],
    columnsTableHead: (t) => columnsTableHeaderProductDataListV2(t),
    buttonGroupHeaderDetails: (t, childrenState) =>
      menuItemSalesInvoiceButtonWithPropsV2(t, childrenState),
    buttonHeaderConfigs: (childrenState) => buttonCollectionsSalesInvoiceDetails(childrenState),
    dataListKeyName: DEFAULT_KEY_NAME_PRODUCT_API,
    keyNameLastUpdateByName: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
    keyNameLastUpdateByTime: DEFAULT_KEY_NAME_UPDATED_AT_API,
    baseUrlDetailsNotFound: REACT_APP_SALES_SALES_INVOICE_URL,
    relationalSearchParamsWithStateComponent: null,
    displayLayoutStatus: defaultDisplayLayoutStatus,
    parentMenuURL: REACT_APP_SALES_SALES_INVOICE_URL,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_PAYMENT_TYPE]: {
    transactionType: DEFAULT_TRANSACTION_SALES_TYPE,
    parentStateName: NAME_STORE.SALES,
    childrenDetailsStateName: SLICE_NAME_SALES_PAYMENT_DETAIL,
    requestStatusPendingGetDetails: STATUS_REQUEST_DETAIL_SALES_PAYMENT_PENDING,
    requestStatusFailedGetDetails: STATUS_REQUEST_DETAIL_SALES_PAYMENT_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_SALES_PAYMENT_ID,
    uniqueParamsGetDetails: '',
    isNotUseObjectToGetDetails: true,
    actionGetDetails: getDetailSalesPayment,
    generateUpdateDataLink: (singleData) => {
      if (isEmpty(singleData)) return '';

      return strHelp.templateString(REACT_APP_SALES_SALES_PAYMENT_UPDATE_TEMPLATE_URL, singleData);
    },
    generalizeDataDetails: (t, dataDetails) => {
      if (isEmpty(dataDetails)) return {};

      const { updated_at, description, amount, sales_agent_name, expired_date, transaction_date } =
        dataDetails;

      let salesPaymentInvoiceList = dataDetails[DEFAULT_KEY_NAME_SALES_INVOICE_PAYMENT_DETAILS],
        total = Number(amount) ? formatHelp.currencyWithAutoComma(Number(amount)) : 0,
        statusContainer = {};

      if (!isEmpty(salesPaymentInvoiceList) && Array.isArray(salesPaymentInvoiceList)) {
        salesPaymentInvoiceList = salesPaymentInvoiceList.map((singleSalesOrder) => {
          const { amount, amount_available, description, sales_invoice_amount } = singleSalesOrder;

          return {
            ...singleSalesOrder,
            price: singleSalesOrder.price
              ? formatHelp.currencyWithAutoComma(Number(singleSalesOrder.price))
              : 0,
            quantity: Number(singleSalesOrder.quantity)
              ? formatHelp.currencyWithAutoComma(Number(singleSalesOrder.quantity))
              : 0,
            amount: amount ? formatHelp.currencyWithAutoComma(Number(amount)) : 0,
            amount_available: amount_available
              ? formatHelp.currencyWithAutoComma(Number(amount_available))
              : 0,
            sales_invoice_amount: sales_invoice_amount
              ? formatHelp.currencyWithAutoComma(Number(sales_invoice_amount))
              : 0,
            description: description || '',
          };
        });
      }

      return {
        ...dataDetails,
        expired_date: expired_date ? formatHelp.getReadableDateV2(expired_date) : '-',
        transaction_date: transaction_date ? formatHelp.getReadableDateV2(transaction_date) : '-',
        [DEFAULT_KEY_NAME_SALES_INVOICE_PAYMENT_DETAILS]: salesPaymentInvoiceList,
        total,
        statusContainer,
        updated_at: updated_at ? formatHelp.getReadableDateV2(updated_at) : '-',
        description: description || '-',
        sales_agent_name: sales_agent_name || '',
      };
    },
    primaryDetailsDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          keyNameValue: 'sales_payment_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.transaction-date',
          keyNameValue: 'transaction_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.expired-date',
          keyNameValue: 'expired_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.pay-to',
          keyNameValue: 'account_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
      [
        {
          label: 'label.contact-customer',
          keyNameValue: 'customer_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.customer-address',
          keyNameValue: 'customer_address',
          gridProps: {
            xs: 6,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryDetailsDataSchema: [
      [
        {
          label: 'label.salesman',
          keyNameValue: 'sales_agent_name',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
      [
        {
          label: 'label.total',
          keyNameValue: 'total',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
        },
        {
          label: '',
          keyNameValue: 'attachment',
          additionalStackProps: {
            display: 'block',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_DROPZONE_DETAILS_TYPE,
        },
        {
          label: 'label.memo',
          keyNameValue: 'description',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    columnsTableHead: (t) => columnsTableHeaderInvoiceSalesPaymentV2(t),
    buttonGroupHeaderDetails: (t) => menuItemSalesPaymentButtonWithProps(t),
    buttonHeaderConfigs: buttonCollectionsSalesPaymentDetails,
    dataListKeyName: DEFAULT_KEY_NAME_SALES_INVOICE_PAYMENT_DETAILS,
    keyNameLastUpdateByName: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
    keyNameLastUpdateByTime: DEFAULT_KEY_NAME_UPDATED_AT_API,
    baseUrlDetailsNotFound: REACT_APP_SALES_SALES_PAYMENT_URL,
    relationalSearchParamsWithStateComponent: null,
    displayLayoutStatus: defaultDisplayLayoutStatus,
    parentMenuURL: REACT_APP_SALES_SALES_PAYMENT_URL,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_RETURN_TYPE]: {
    transactionType: DEFAULT_TRANSACTION_SALES_TYPE,
    parentStateName: ROOT_STORE_NAME_SALES,
    childrenDetailsStateName: SLICE_NAME_SALES_RETURN_DETAILS,
    requestStatusPendingGetDetails: REQUEST_STATUS_DETAILS_SALES_RETURN_LOADING,
    requestStatusFailedGetDetails: REQUEST_STATUS_DETAILS_SALES_RETURN_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_SALES_RETURN_ID,
    uniqueParamsGetDetails: DEFAULT_KEY_NAME_SALES_RETURN_ID_API_REF,
    isNotUseObjectToGetDetails: false,
    actionGetDetails: getSalesReturnDetails,
    generateUpdateDataLink: (singleData) => {
      if (isEmpty(singleData)) return '';

      return strHelp.templateString(REACT_APP_SALES_RETURN_UPDATE_TEMPLATE_URL, singleData);
    },
    generalizeDataDetails: (t, dataDetails) => {
      if (isEmpty(dataDetails)) return {};

      const { updated_at, description, discount_nominal = 0, transaction_date } = dataDetails;

      let salesReturnProductList = dataDetails[DEFAULT_KEY_NAME_SALES_RETURN_PRODUCT_API_REF];
      if (!isEmpty(salesReturnProductList) && Array.isArray(salesReturnProductList)) {
        salesReturnProductList = salesReturnProductList.map((singleInvoice) => {
          const { quantity, price, description } = singleInvoice;

          return {
            ...singleInvoice,
            amount: Number(quantity) * Number(price),
            description: description || '',
          };
        });
      }

      const subtotal = arrHelp.sumValueArrV2(
        arrHelp.mapObjKeyOnArr(salesReturnProductList, 'amount'),
      );

      return {
        ...dataDetails,
        transaction_date: transaction_date ? formatHelp.getReadableDateV2(transaction_date) : '-',
        subtotal: Number(subtotal),
        total: Number(subtotal) - Number(discount_nominal),
        updated_at: updated_at ? formatHelp.getReadableDateV2(updated_at) : '-',
        description: description || '',
        [DEFAULT_KEY_NAME_SALES_RETURN_PRODUCT_API_REF]: salesReturnProductList,
      };
    },
    primaryDetailsDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          keyNameValue: 'transaction_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.transaction-number',
          keyNameValue: 'sales_invoice_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_LINK_TYPE,
          generateLink: (dataDetails) => {
            if (isEmpty(dataDetails)) return '';

            return strHelp.templateString(
              REACT_APP_SALES_SALES_INVOICE_DETAILS_TEMPLATE_URL,
              dataDetails,
            );
          },
        },
        {
          label: 'label.transaction-date',
          keyNameValue: 'transaction_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
      [
        {
          label: 'label.customer',
          keyNameValue: 'customer_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.customer-address',
          keyNameValue: 'customer_address',
          gridProps: {
            xs: 6,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryDetailsDataSchema: [
      [
        // {
        //     label: 'label.invoice-value',
        //     useTemplateLabel: true,
        //     templateLabel: '{label} {sales_invoice_no}',
        //     keyNameValue: 'amount',
        //     typographyLabelProps: {
        //         variant: 'boldIndicator1',
        //     },
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
        //     formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        // },
        // {
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_HORIZONTAL_LINE_TYPE,
        // },
        {
          label: 'label.subtotal',
          keyNameValue: 'subtotal',
          typographyLabelProps: {
            variant: 'boldIndicator1',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        // {
        //     label: 'label.discount-per-item',
        //     keyNameValue: 'discountPerItem',
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        //     formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        // },
        {
          label: 'label.additional-discount',
          keyNameValue: 'discount_nominal',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        // {
        //     label: 'label.total-after-discount',
        //     keyNameValue: 'totalAfterDiscount',
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        //     formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        // },
        {
          representationDataType: DEFAULT_REPRESENTATION_DATA_HORIZONTAL_LINE_TYPE,
        },
        {
          label: 'label.total',
          keyNameValue: 'total',
          typographyLabelProps: {
            variant: 'boldIndicator1',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
          formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        },
        // {
        //     label: 'label.pays-off',
        //     keyNameValue: 'amount_paid',
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        //     formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        // },
        // {
        //     label: 'label.retur',
        //     keyNameValue: 'amount_return',
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        //     formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        // },
        // {
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_HORIZONTAL_LINE_TYPE,
        // },
        // {
        //     label: 'label.remaining-bill',
        //     keyNameValue: 'amount_available',
        //     typographyLabelProps: {
        //         variant: 'boldIndicator2',
        //     },
        //     typographyValueProps: {
        //         fontSize: 12,
        //     },
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
        //     formatter: formatHelp.wrapperCurrencyWithAutoCommaV1,
        // },
      ],
      [
        {
          label: 'label.tags',
          keyNameValue: 'tag',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CHIP_WITH_PARENT_LABEL_TYPE,
        },
        {
          label: 'label.memo',
          keyNameValue: 'description',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
        {
          label: '',
          keyNameValue: 'attachment',
          additionalStackProps: {
            display: 'block',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_DROPZONE_DETAILS_TYPE,
        },
      ],
    ],
    columnsTableHead: (t) => columnsTableHeaderProductDataListV2(t),
    buttonGroupHeaderDetails: (t) => menuItemSalesReturnButtonWithProps(t),
    buttonHeaderConfigs: buttonCollectionsSalesReturnDetails,
    dataListKeyName: DEFAULT_KEY_NAME_SALES_RETURN_PRODUCT_API_REF,
    keyNameLastUpdateByName: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
    keyNameLastUpdateByTime: DEFAULT_KEY_NAME_UPDATED_AT_API,
    baseUrlDetailsNotFound: REACT_APP_SALES_RETURN_URL,
    relationalSearchParamsWithStateComponent: null,
    parentMenuURL: REACT_APP_SALES_RETURN_URL,
  },
  [DEFAULT_TRANSACTION_SALES_CREDIT_NOTE_TYPE]: {
    transactionType: DEFAULT_TRANSACTION_SALES_TYPE,
    parentStateName: ROOT_STORE_NAME_SALES,
    childrenDetailsStateName: SLICE_NAME_SALES_CREDIT_NOTE_DETAILS,
    requestStatusPendingGetDetails: STATUS_REQUEST_DETAILS_SALES_CREDIT_NOTE_PENDING,
    requestStatusFailedGetDetails: STATUS_REQUEST_DETAILS_SALES_CREDIT_NOTE_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_CREDIT_NOTE_ID,
    uniqueParamsGetDetails: DEFAULT_KEY_NAME_CREDIT_NOTE_ID_API_REF,
    isNotUseObjectToGetDetails: false,
    actionGetDetails: getSalesCreditNoteDetails,
    generateUpdateDataLink: () => {
      return '';
    },
    generalizeDataDetails: (t, dataDetails) => {
      if (isEmpty(dataDetails)) return {};

      const {
        updated_at,
        description,
        amount,
        amount_available,
        expired_date,
        transaction_date,
        status,
      } = dataDetails;

      const salesCreditNoteStatusTranslated = salesCreditNoteStatus(t);

      const total = Number(amount) ? formatHelp.currencyWithAutoComma(Number(amount)) : 0,
        paidOff = formatHelp.currencyWithAutoComma(Number(amount) - Number(amount_available));

      let statusContainer = {};

      if (
        status &&
        salesCreditNoteStatusTranslated &&
        Array.isArray(salesCreditNoteStatusTranslated)
      ) {
        const selectedSalesCreditNoteStatus = arrHelp.filterObjDataWithID(
          salesCreditNoteStatusTranslated,
          'value',
          status,
        );

        statusContainer = selectedSalesCreditNoteStatus;

        if (isEmpty(selectedSalesCreditNoteStatus)) {
          statusContainer = salesCreditNoteStatusTranslated[0];
        }
      }

      return {
        ...dataDetails,
        expired_date: expired_date ? formatHelp.getReadableDateV2(expired_date) : '-',
        transaction_date: transaction_date ? formatHelp.getReadableDateV2(transaction_date) : '-',
        total,
        statusContainer,
        updated_at: updated_at ? formatHelp.getReadableDateV2(updated_at) : '-',
        description: description || '-',
        paidOff,
      };
    },
    primaryDetailsDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          keyNameValue: 'transaction_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isInline: true,
        },
        {
          label: 'label.transaction-date',
          keyNameValue: 'transaction_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isInline: true,
        },
        {
          label: 'label.status',
          keyNameValue: 'statusContainer',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CHIP_TYPE,
          isInline: true,
        },
        {
          label: 'label.contact-customer',
          keyNameValue: 'customer_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isInline: false,
          additionalStackProps: {
            mt: '16px!important',
          },
        },
        {
          // label: 'label.customer-address',
          keyNameValue: 'customer_address',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isInline: false,
        },
        {
          label: 'label.description',
          keyNameValue: 'description',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isInline: false,
          additionalStackProps: {
            mt: '16px!important',
          },
        },
      ],
      [
        {
          label: 'label.total',
          keyNameValue: 'total',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isInline: false,
          additionalStackProps: {
            height: '95px',
            justifyContent: 'unset',
            alignItems: 'end',
          },
          additionalTypographyProps: {
            variant: 'h5',
          },
        },
        {
          label: 'label.pays-off',
          keyNameValue: 'paidOff',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isInline: false,
          additionalStackProps: {
            alignItems: 'end',
          },
          additionalTypographyProps: {
            variant: 'h5',
          },
        },
      ],
    ],
    secondaryDetailsDataSchema: [[], []],
    // columnsTableHead: (t) => columnsTableHeaderPurchaseDebitNoteDetails(t),
    // buttonGroupHeaderDetails: (t) => menuItemPurchasePaymentButtonWithProps(t),
    dataListKeyName: DEFAULT_KEY_NAME_PURCHASE_INVOICE_PAYMENT_API_REF,
    keyNameLastUpdateByName: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
    keyNameLastUpdateByTime: DEFAULT_KEY_NAME_UPDATED_AT_API,
    baseUrlDetailsNotFound: REACT_APP_SALES_CREDIT_NOTE_URL,
    relationalSearchParamsWithStateComponent: null,
    overrideComponentProperties: {
      minHeightPaperPrimaryDetails: '60px',
      paperPrimaryClassName: DEFAULT_CLASSNAME_PAPER_PRIMARY_SM_HEIGHT_LAYOUT_PRIMARY,
      paperSecondaryClassName: DEFAULT_CLASSNAME_PAPER_SECONDARY_MD_HEIGHT_LAYOUT_PRIMARY,
      tableContainerClassName: DEFAULT_CLASSNAME_TABLE_CONTAINER_CONSTTANT_HEIGHT_MD_SIZE,
    },
    amountSliceActionButtons: [0, 3],
    displayLayoutStatus: {
      ...defaultDisplayLayoutStatus,
      primaryLayout: 'none',
      secondaryLayout: 'block',
    },
    overrideActionButtonProps: {
      1: {
        buttonLabelTranslation: 'button.journal-entry',
        isDisabled: () => true,
        buttonProps: {
          variant: 'outlined',
          className: 'primaryButtonV2',
        },
      },
      2: {
        action: 'change-page-status',
        newPageStatus: DEFAULT_PAGE_STATUS_PAY_TRANSACTION,
        buttonLabelTranslation: 'button.pay',
        isLinkComponent: false,
        isDisabled: (childrenState) => {
          if (isEmpty(childrenState)) return false;

          return (
            childrenState[DEFAULT_KEY_NAME_STATUS_REF_API] === DEFAULT_STATUS_SALES_CREDIT_NOTE_PAID
          );
        },
      },
    },
    parentMenuURL: REACT_APP_SALES_CREDIT_NOTE_URL,
  },
  [DEFAULT_TRANSACTION_SALES_PAYABLE_PAYMENT_TYPE]: {
    transactionType: DEFAULT_TRANSACTION_SALES_TYPE,
    parentStateName: ROOT_STORE_NAME_SALES,
    childrenDetailsStateName: SLICE_NAME_SALES_PAYABLE_PAYMENT_DETAILS,
    requestStatusPendingGetDetails: REQUEST_STATUS_DETAILS_SALES_PAYABLE_PAYMENT_LOADING,
    requestStatusFailedGetDetails: REQUEST_STATUS_DETAILS_SALES_PAYABLE_PAYMENT_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_PAYABLE_PAYMENT_ID,
    uniqueParamsGetDetails: DEFAULT_KEY_NAME_PAYABLE_PAYMENT_ID_API_REF,
    isNotUseObjectToGetDetails: false,
    actionGetDetails: getSalesPayablePaymentDetails,
    generateUpdateDataLink: (singleData) => {
      if (isEmpty(singleData)) return '';

      return strHelp.templateString(
        REACT_APP_SALES_PAYABLE_PAYMENT_UPDATE_TEMPLATE_URL,
        singleData,
      );
    },
    generalizeDataDetails: (t, dataDetails) => {
      if (isEmpty(dataDetails)) return {};

      const { updated_at, description, amount, transaction_date } = dataDetails;

      const total = Number(amount) ? formatHelp.currencyWithAutoComma(Number(amount)) : 0;

      let salesPayablePaymentItems = dataDetails[DEFAULT_KEY_NAME_ITEM_API_REF];
      if (!isEmpty(salesPayablePaymentItems) && Array.isArray(salesPayablePaymentItems)) {
        salesPayablePaymentItems = salesPayablePaymentItems.map((singleItem) => {
          const { amount, amount_paid, description, credit_note_no, amount_available } = singleItem;

          return {
            ...singleItem,
            amount: Number(amount),
            amount_paid: Number(amount_paid),
            amount_available: Number(amount_available),
            credit_note_no: credit_note_no || '-',
            description: description || '',
          };
        });
      }

      return {
        ...dataDetails,
        transaction_date: transaction_date ? formatHelp.getReadableDateV2(transaction_date) : '-',
        total,
        updated_at: updated_at ? formatHelp.getReadableDateV2(updated_at) : '-',
        description: description || '',
        item: salesPayablePaymentItems,
      };
    },
    primaryDetailsDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          keyNameValue: 'transaction_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.transaction-date',
          keyNameValue: 'transaction_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.payment-account',
          keyNameValue: 'account_payable',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
      [
        {
          label: 'label.contact-customer',
          keyNameValue: 'customer_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.customer-address',
          keyNameValue: 'customer_address',
          gridProps: {
            xs: 6,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryDetailsDataSchema: [
      [
        {
          label: 'label.total',
          keyNameValue: 'total',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
        },
      ],
      [
        {
          label: '',
          keyNameValue: 'attachment',
          additionalStackProps: {
            display: 'block',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_DROPZONE_DETAILS_TYPE,
        },
        {
          label: 'label.memo',
          keyNameValue: 'description',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    columnsTableHead: (t) => columnsTableHeaderCreditNoteListOnSalesPayablePayment(t),
    buttonGroupHeaderDetails: (t) => menuItemSalesPayablePaymentButtonWithProps(t),
    buttonHeaderConfigs: buttonCollectionsHistoryTransactionOnly,
    dataListKeyName: DEFAULT_KEY_NAME_ITEM_API_REF,
    keyNameLastUpdateByName: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
    keyNameLastUpdateByTime: DEFAULT_KEY_NAME_UPDATED_AT_API,
    baseUrlDetailsNotFound: REACT_APP_SALES_PAYABLE_PAYMENT_URL,
    relationalSearchParamsWithStateComponent: null,
    parentMenuURL: REACT_APP_SALES_PAYABLE_PAYMENT_URL,
  },
  [DEFAULT_TRANSACTION_EXPENSE_TYPE]: {
    parentStateName: NAME_STORE.EXPENSE,
    childrenDetailsStateName: SLICE_NAME_EXPENSE_DETAIL,
    requestStatusPendingGetDetails: STATUS_REQUEST_DETAIL_EXPENSE_PENDING,
    requestStatusFailedGetDetails: STATUS_REQUEST_DETAIL_EXPENSE_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_EXPENSE_ID,
    isNotUseObjectToGetDetails: true,
    actionGetDetails: getExpenseDetail,
    generateUpdateDataLink: (singleData) => {
      if (isEmpty(singleData)) return '';

      return strHelp.templateString(REACT_APP_EXPENSE_UPDATE_TEMPLATE_URL, singleData);
    },
    generalizeDataDetails: (t, dataDetails) => {
      if (isEmpty(dataDetails)) return {};

      const { updated_at, description, amount, transaction_date, discount_percentage } =
        dataDetails;

      let expenseAccounts = dataDetails[DEFAULT_KEY_NAME_EXPENSE_DATA_API],
        total = Number(amount) ? formatHelp.currencyWithAutoComma(Number(amount)) : 0,
        statusContainer = {};

      if (!isEmpty(expenseAccounts) && Array.isArray(expenseAccounts)) {
        expenseAccounts = expenseAccounts.map((singleExpenseAccount) => {
          const { description, product_unit, amount: account_amount } = singleExpenseAccount;

          return {
            ...singleExpenseAccount,
            amount: account_amount ? formatHelp.currencyWithAutoComma(Number(account_amount)) : 0,
            description: description || '',
            product_unit: product_unit ? strHelp.capitalize(product_unit) : '',
          };
        });
      }

      return {
        ...dataDetails,
        transaction_date: transaction_date ? formatHelp.getReadableDateV2(transaction_date) : '-',
        [DEFAULT_KEY_NAME_EXPENSE_DATA_API]: expenseAccounts,
        total,
        statusContainer,
        updated_at: updated_at ? formatHelp.getReadableDateV2(updated_at) : '-',
        description: description || '-',
        discount_percentage: `${Number(discount_percentage)} %` || '-',
      };
    },
    primaryDetailsDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          keyNameValue: 'transaction_no',
          gridProps: defaultGridPropsSecondary,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.transaction-date',
          keyNameValue: 'transaction_date',
          gridProps: defaultGridPropsSecondary,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.contact',
          keyNameValue: 'contact_name',
          gridProps: defaultGridPropsSecondary,
          representationDataType: DEFAULT_REPRESENTATION_DATA_LINK_TYPE,
          generateLink: (dataDetails, currentLocation) => {
            if (isEmpty(dataDetails)) return '';

            const searchParamsObj = {
              pageStatus: DEFAULT_STATUS_PAGE_DETAIL_DATA_CONTACT,
              [DEFAULT_KEY_NAME_PREV_PAGE_STATUS]: DEFAULT_PAGE_STATUS_DETAILS_TRANSACTION,
              [DEFAULT_KEY_NAME_SELECTED_CONTACT_DATA]: encryptData(JSON.stringify(dataDetails)),
            };

            const searchParamsStr = objectToQueryString(searchParamsObj);

            return formatHelp.combineBaseUrlWithSearchParams(currentLocation, searchParamsStr);
          },
        },
        {
          label: 'label.origin-account',
          keyNameValue: 'account_origin_name',
          gridProps: defaultGridPropsSecondary,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryDetailsDataSchema: [
      [
        {
          label: 'label.discount',
          keyNameValue: 'discount_percentage',
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.total',
          keyNameValue: 'total',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOLD_TYPOGRAPHY_TYPE,
        },
      ],
      [
        {
          label: '',
          keyNameValue: 'attachment',
          additionalStackProps: {
            display: 'block',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_DROPZONE_DETAILS_TYPE,
        },
        {
          label: 'label.memo',
          keyNameValue: 'description',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    columnsTableHead: (t) => columnsTableHeaderAccountInExpense(t),
    dataListKeyName: DEFAULT_KEY_NAME_EXPENSE_DATA_API,
    keyNameLastUpdateByName: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
    keyNameLastUpdateByTime: DEFAULT_KEY_NAME_UPDATED_AT_API,
    baseUrlDetailsNotFound: REACT_APP_EXPENSE_URL,
    relationalSearchParamsWithStateComponent: null,
    parentMenuURL: REACT_APP_EXPENSE_URL,
  },
};

export const getTransactionProDetailsConfig = (subTransactionType) => {
  const keysTransactionProDetails = Object.keys(defaultTransactionProDetailsConfig);

  if (!keysTransactionProDetails.includes(subTransactionType))
    subTransactionType = keysTransactionProDetails[0];

  return defaultTransactionProDetailsConfig[subTransactionType];
};

export const defaultTransactionProHandoverDetailsConfig = {
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE]: {
    transactionType: DEFAULT_TRANSACTION_PURCHASE_TYPE,
    parentStateName: ROOT_STORE_NAME_MINUTES_OF_HANDOVER,
    childrenDetailsStateName: SLICE_NAME_HANDOVER_PURCHASE_DELIVERY_DETAILS,
    requestStatusPendingGetDetails: STATUS_REQUEST_DETAILS_HANDOVER_PURCHASE_DELIVERY_PENDING,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_DELIVERY_HANDOVER_ID,
    keyNameRequestStatus: DEFAULT_KEY_NAME_REQUEST_STATUS,
    uniqueParamsGetDetails: 'purchase_handover_id',
    isNotUseObjectToGetDetails: false,
    additionalParamsGetDetails: {
      minutesHandoverType: MINUTES_OF_HANDOVER_TYPE_PURCHASE_DELIVERY,
    },
    generateParamsGetDetails: (currParams) => {
      if (isEmpty(currParams)) return {};

      return {
        paramsMinutesOfHandoverDetails: {
          purchase_handover_id: currParams.purchase_handover_id,
        },
        minutesHandoverType: currParams.minutesHandoverType,
      };
    },
    actionGetDetails: minutesOfHandoverDetails,
    generateUpdateDataLink: (singleData, paramsUrl = {}) => {
      if (isEmpty(singleData)) return '';

      const templateObj = {
        purchase_delivery_id: paramsUrl[DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_DELIVERY_ID],
        ...singleData,
      };

      return strHelp.templateString(
        REACT_APP_PURCHASE_PURCHASE_DELIVERY_HANDOVER_UPDATE_TEMPLATE_URL,
        templateObj,
      );
    },
    generalizeDataDetails: (t, dataDetails) => {
      if (isEmpty(dataDetails)) return {};

      const minutesHandoverStatusTranslated = minutesHandoverStatus(t);

      const { status, description, transaction_date, history } = dataDetails;

      let minutesHandoverHistory = JSON.parse(history);

      if (!isEmpty(minutesHandoverHistory) && Array.isArray(minutesHandoverHistory)) {
        minutesHandoverHistory = minutesHandoverHistory[0];
      }

      let minutesHandoverProduct = dataDetails[DEFAULT_KEY_NAME_PRODUCT_SALES_HANDOVER_DETAILS],
        statusContainer = {},
        updatedBy = '',
        updatedAt = '';

      if (
        status &&
        minutesHandoverStatusTranslated &&
        Array.isArray(minutesHandoverStatusTranslated)
      ) {
        const selectedSalesDeliveryHandoverStatus = arrHelp.filterObjDataWithID(
          minutesHandoverStatusTranslated,
          'value',
          status,
        );

        statusContainer = selectedSalesDeliveryHandoverStatus;

        if (isEmpty(selectedSalesDeliveryHandoverStatus)) {
          statusContainer = minutesHandoverStatusTranslated[0];
        }
      }

      if (!isEmpty(minutesHandoverProduct) && Array.isArray(minutesHandoverProduct)) {
        minutesHandoverProduct = minutesHandoverProduct.map((singleSalesDeliveryHandover) => {
          const { description, product_unit, product_name } = singleSalesDeliveryHandover;

          return {
            ...singleSalesDeliveryHandover,
            quantity: Number(singleSalesDeliveryHandover.quantity)
              ? formatHelp.currencyWithAutoComma(Number(singleSalesDeliveryHandover.quantity))
              : 0,
            description: description || '',
            product_unit: product_unit ? strHelp.capitalize(product_unit) : '',
            product_name: product_name || '-',
          };
        });
      }

      if (!isEmpty(minutesHandoverHistory) && typeof minutesHandoverHistory === 'object') {
        updatedBy = minutesHandoverHistory.user_name || '';
        updatedAt = minutesHandoverHistory.date
          ? formatHelp.getReadableDateV2(minutesHandoverHistory.date)
          : '';
      }

      return {
        ...dataDetails,
        transaction_date: transaction_date ? formatHelp.getReadableDateV2(transaction_date) : '-',
        [DEFAULT_KEY_NAME_PRODUCT_SALES_HANDOVER_DETAILS]: minutesHandoverProduct,
        statusContainer,
        description: description || '-',
        [DEFAULT_KEY_NAME_UPDATED_BY_NAME_API]: updatedBy,
        [DEFAULT_KEY_NAME_UPDATED_AT_API]: updatedAt,
      };
    },
    primaryDetailsDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          keyNameValue: 'purchase_handover_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.transaction-date',
          keyNameValue: 'transaction_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.receiver',
          keyNameValue: 'receiver_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.sender',
          keyNameValue: 'sender_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryDetailsDataSchema: [
      [
        {
          label: 'label.status',
          keyNameValue: 'statusContainer',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CHIP_TYPE,
        },
      ],
      [
        {
          label: '',
          keyNameValue: 'attachment',
          additionalStackProps: {
            display: 'block',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_DROPZONE_DETAILS_TYPE,
        },
        {
          label: 'label.memo',
          keyNameValue: 'description',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    amountSliceActionButtons: [2, 3],
    overrideActionButtonProps: {
      3: {
        gridProps: {
          xs: 12,
        },
        isLinkComponent: true,
      },
    },
    columnsTableHead: (t) => columnsTableDetailProductMinutesOfHandoverV2(t),
    dataListKeyName: DEFAULT_KEY_NAME_PRODUCT_PURCHASE_HANDOVER_DETAILS_API_REF,
    keyNameLastUpdateByName: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
    keyNameLastUpdateByTime: DEFAULT_KEY_NAME_UPDATED_AT_API,
    parentMenuURL: REACT_APP_PURCHASE_DELIVERY_URL,
    buttonHeaderConfigs: buttonCollectionsMinutesHandoverSalesDeliveryDetails,
    buttonGroupHeaderDetails: (t, childrenState) =>
      menuItemPurchaseHandoverJournalDetailsButtonWithProps(t, childrenState),
  },
  [DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE]: {
    transactionType: DEFAULT_TRANSACTION_SALES_TYPE,
    parentStateName: ROOT_STORE_NAME_MINUTES_OF_HANDOVER,
    childrenDetailsStateName: SLICE_NAME_HANDOVER_SALES_DELIVERY_DETAILS,
    requestStatusPendingGetDetails: STATUS_REQUEST_DETAILS_HANDOVER_SALES_DELIVERY_PENDING,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_SALES_DELIVERY_HANDOVER_ID,
    keyNameRequestStatus: DEFAULT_KEY_NAME_REQUEST_STATUS,
    uniqueParamsGetDetails: 'sales_handover_id',
    isNotUseObjectToGetDetails: false,
    additionalParamsGetDetails: {
      minutesHandoverType: MINUTES_OF_HANDOVER_TYPE_SALES_DELIVERY,
    },
    generateParamsGetDetails: (currParams) => {
      if (isEmpty(currParams)) return {};

      return {
        paramsMinutesOfHandoverDetails: {
          sales_handover_id: currParams.sales_handover_id,
        },
        minutesHandoverType: currParams.minutesHandoverType,
      };
    },
    actionGetDetails: minutesOfHandoverDetails,
    generateUpdateDataLink: (singleData, paramsUrl = {}) => {
      if (isEmpty(singleData)) return '';

      const templateObj = {
        sales_delivery_id: paramsUrl[DEFAULT_KEY_NAME_PARAMS_URL_SALES_DELIVERY_ID],
        ...singleData,
      };

      return strHelp.templateString(
        REACT_APP_SALES_SALES_DELIVERY_HANDOVER_UPDATE_TEMPLATE_URL,
        templateObj,
      );
    },
    generalizeDataDetails: (t, dataDetails) => {
      if (isEmpty(dataDetails)) return {};

      const minutesHandoverStatusTranslated = minutesHandoverStatus(t);

      const { status, description, transaction_date, history } = dataDetails;

      let minutesHandoverHistory = JSON.parse(history);

      if (!isEmpty(minutesHandoverHistory) && Array.isArray(minutesHandoverHistory)) {
        minutesHandoverHistory = minutesHandoverHistory[0];
      }

      let minutesHandoverProduct = dataDetails[DEFAULT_KEY_NAME_PRODUCT_SALES_HANDOVER_DETAILS],
        statusContainer = {},
        updatedBy = '',
        updatedAt = '';

      if (
        status &&
        minutesHandoverStatusTranslated &&
        Array.isArray(minutesHandoverStatusTranslated)
      ) {
        const selectedSalesDeliveryHandoverStatus = arrHelp.filterObjDataWithID(
          minutesHandoverStatusTranslated,
          'value',
          status,
        );

        statusContainer = selectedSalesDeliveryHandoverStatus;

        if (isEmpty(selectedSalesDeliveryHandoverStatus)) {
          statusContainer = minutesHandoverStatusTranslated[0];
        }
      }

      if (!isEmpty(minutesHandoverProduct) && Array.isArray(minutesHandoverProduct)) {
        minutesHandoverProduct = minutesHandoverProduct.map((singleSalesDeliveryHandover) => {
          const { description, product_unit, product_name } = singleSalesDeliveryHandover;

          return {
            ...singleSalesDeliveryHandover,
            quantity: Number(singleSalesDeliveryHandover.quantity)
              ? formatHelp.currencyWithAutoComma(Number(singleSalesDeliveryHandover.quantity))
              : 0,
            description: description || '',
            product_unit: product_unit ? strHelp.capitalize(product_unit) : '',
            product_name: product_name || '-',
          };
        });
      }

      if (!isEmpty(minutesHandoverHistory) && typeof minutesHandoverHistory === 'object') {
        updatedBy = minutesHandoverHistory.user_name || '';
        updatedAt = minutesHandoverHistory.date
          ? formatHelp.getReadableDateV2(minutesHandoverHistory.date)
          : '';
      }

      return {
        ...dataDetails,
        transaction_date: transaction_date ? formatHelp.getReadableDateV2(transaction_date) : '-',
        [DEFAULT_KEY_NAME_PRODUCT_SALES_HANDOVER_DETAILS]: minutesHandoverProduct,
        statusContainer,
        description: description || '-',
        [DEFAULT_KEY_NAME_UPDATED_BY_NAME_API]: updatedBy,
        [DEFAULT_KEY_NAME_UPDATED_AT_API]: updatedAt,
      };
    },
    primaryDetailsDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          keyNameValue: 'sales_handover_no',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.transaction-date',
          keyNameValue: 'transaction_date',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.receiver',
          keyNameValue: 'receiver_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.sender',
          keyNameValue: 'sender_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryDetailsDataSchema: [
      [
        {
          label: 'label.status',
          keyNameValue: 'statusContainer',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CHIP_TYPE,
        },
      ],
      [
        {
          label: '',
          keyNameValue: 'attachment',
          additionalStackProps: {
            display: 'block',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_DROPZONE_DETAILS_TYPE,
        },
        {
          label: 'label.memo',
          keyNameValue: 'description',
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    amountSliceActionButtons: [2, 3],
    overrideActionButtonProps: {
      3: {
        gridProps: {
          xs: 12,
        },
        isLinkComponent: true,
      },
    },
    columnsTableHead: (t) => columnsTableDetailProductMinutesOfHandoverV2(t),
    dataListKeyName: DEFAULT_KEY_NAME_PRODUCT_SALES_HANDOVER_DETAILS,
    keyNameLastUpdateByName: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
    keyNameLastUpdateByTime: DEFAULT_KEY_NAME_UPDATED_AT_API,
    parentMenuURL: REACT_APP_SALES_SALES_DELIVERY_URL,
    buttonHeaderConfigs: buttonCollectionsMinutesHandoverPurchaseDeliveryDetails,
    buttonGroupHeaderDetails: (t, childrenState) =>
      menuItemSalesHandoverJournalDetailsButtonWithProps(t, childrenState),
  },
};

export const getTransactionProDetailsHandoverConfig = (subTransactionType) => {
  const subTransactionTypeDetailsHandover = Object.keys(defaultTransactionProHandoverDetailsConfig);

  if (!subTransactionTypeDetailsHandover.includes(subTransactionType))
    subTransactionType = subTransactionTypeDetailsHandover[0];

  return defaultTransactionProHandoverDetailsConfig[subTransactionType];
};

const relationalConfigurationSubDetailsTypeWithComponentConfiguration = {
  [DEFAULT_COMMON_DETAIL_TYPE]: {
    minHeightPaperPrimaryDetails: '120px',
    tableContainerClassName: DEFAULT_CLASSNAME_TABLE_CONTAINER_MD_SIZE,
    paperPrimaryClassName: DEFAULT_CLASSNAME_PAPER_PRIMARY_MD_HEIGHT_LAYOUT_PRIMARY,
    paperSecondaryClassName: DEFAULT_CLASSNAME_PAPER_SECONDARY_SM_HEIGHT_LAYOUT_PRIMARY,
    getConfigurationDetails: getTransactionProDetailsConfig,
  },
  [DEFAULT_HANDOVER_DETAIL_TYPE]: {
    minHeightPaperPrimaryDetails: '60px',
    paperPrimaryClassName: DEFAULT_CLASSNAME_PAPER_PRIMARY_SM_HEIGHT_LAYOUT_PRIMARY,
    paperSecondaryClassName: DEFAULT_CLASSNAME_PAPER_SECONDARY_MD_HEIGHT_LAYOUT_PRIMARY,
    tableContainerClassName: DEFAULT_CLASSNAME_TABLE_CONTAINER_CONSTTANT_HEIGHT_MD_SIZE,
    getConfigurationDetails: getTransactionProDetailsHandoverConfig,
  },
};

export const getTransactionDetailsConfigurationWithSubTypeDetais = (subDetailsType) => {
  if (!allSubDetailsType.includes(subDetailsType) || !subDetailsType)
    return relationalConfigurationSubDetailsTypeWithComponentConfiguration[
      DEFAULT_COMMON_DETAIL_TYPE
    ];

  return relationalConfigurationSubDetailsTypeWithComponentConfiguration[subDetailsType];
};

const defaultAssetSalesHistoryListConfig = {
  parentStateName: NAME_STORE.SALES,
  childrenListStateName: SLICE_NAME_SALES_QUOTATION_HISTORY,
  requestStatusPendingGetList: STATUS_REQUEST_HISTORY_SALES_QUOTATION_PENDING,
  requestStatusFailedGetList: STATUS_REQUEST_HISTORY_SALES_QUOTATION_FAILED,
  requestStatusKeyName: DEFAULT_KEY_NAME_STATUS_REQUEST,
  uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_SALES_QUOTATION_ID,
  isNotUseObjectToGetHistoryList: false,
  identity: DEFAULT_KEY_NAME_SALES_QUOTATION_ID_API_REF,
  uniqueParamsGetHistoryLink: DEFAULT_KEY_NAME_SALES_QUOTATION_ID_API_REF,
  title: 'header.sales-quotation',
  labelContact: 'label.contact-customer',
  labelAgent: 'label.sales-agent',
  labelMemo: 'label.memo',
  correspondHistoryKeyNamesWithIdentity: {
    [DEFAULT_KEY_NAME_MODUL_ID_API_REF]: DEFAULT_KEY_NAME_SALES_QUOTATION_ID_API_REF,
  },
  urlDetailsTemplate: REACT_APP_SALES_SALES_QUOTATION_DETAILS_TEMPLATE_URL,
  keyDateType: [
    DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
    DEFAULT_KEY_NAME_UPDATED_AT_API,
    DEFAULT_KEY_NAME_EXPIRED_DATE_API_REF,
  ],
  keyCurrencyType: [DEFAULT_KEY_NAME_AMOUNT_REF_API],
  getStatusTransaction: (t) => salesQoutationStatus(t),
  statusTransaction: salesQuotationPlainStatus,
  getListHistoryAction: getHistorySalesQuotation,
  keyAmount: DEFAULT_KEY_NAME_AMOUNT_REF_API,
  keyTransactionNumber: DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF,
  keyUpdatedAt: DEFAULT_KEY_NAME_UPDATED_AT_API,
  keyUpdatedBy: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
  keyStatusTransaction: DEFAULT_KEY_NAME_STATUS_REF_API,
  keyTransactionDate: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
  keyExpiredDate: DEFAULT_KEY_NAME_EXPIRED_DATE_API_REF,
  keyProduct: DEFAULT_KEY_NAME_PRODUCT_API,
  keyContact: DEFAULT_KEY_NAME_CUSTOMER_NAME_API_REF,
  keyContactAddress: DEFAULT_KEY_NAME_CUSTOMER_ADDRESS_API_REF,
  keyAgent: DEFAULT_KEY_NAME_SALES_AGENT_NAME_API_REF,
  keyMemo: DEFAULT_KEY_NAME_DESCRIPTION_API_REF,
  showAmountTransactionLeftSide: true,
  showAmountChildrenLeftSide: false,
  typographyAmountChildrenLeftSide: 'typography.product-total',
  usageTableFooterChildrenTransaction: false,
  usageAdditionalDiscountValue: false,
  keyDiscountValue: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
  keyDiscountPercentage: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
  keyDiscountNominal: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
  formatterDiscountValue: (discountValue) =>
    `${formatHelp.currencyWithAutoComma(Number(discountValue))}%`,
};

const defaultAssetPurchaseHistoryListConfig = {
  parentStateName: NAME_STORE.PURCHASE,
  childrenListStateName: SLICE_NAME_QUOTATION_REQUEST_HISTORY,
  requestStatusPendingGetList: STATUS_REQUEST_HISTORY_QUOTATION_REQUEST_PENDING,
  requestStatusFailedGetList: STATUS_REQUEST_HISTORY_QUOTATION_REQUEST_FAILED,
  requestStatusKeyName: DEFAULT_KEY_NAME_STATUS_REQUEST,
  uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_QUOTATION_ID,
  isNotUseObjectToGetHistoryList: false,
  identity: DEFAULT_KEY_NAME_QUOTATION_REQUEST_ID_API_REF,
  uniqueParamsGetHistoryLink: DEFAULT_KEY_NAME_QUOTATION_REQUEST_ID_API_REF,
  title: 'header.quotation-request',
  labelContact: 'label.contact-supplier',
  labelAgent: 'label.sales-agent',
  labelMemo: 'label.memo',
  urlDetailsTemplate: REACT_APP_PURCHASE_QUOTATION_REQUEST_DETAILS_TEMPLATE_URL,
  keyNameChildrenTransactionData: DEFAULT_KEY_NAME_PRODUCT_API,
  keyDateType: [
    DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
    DEFAULT_KEY_NAME_UPDATED_AT_API,
    DEFAULT_KEY_NAME_EXPIRED_DATE_API_REF,
  ],
  keyCurrencyType: [DEFAULT_KEY_NAME_AMOUNT_REF_API],
  getStatusTransaction: (t) => purchaseQuotationRequestStatus(t),
  statusTransaction: purchaseQuotationRequestPlainStatus,
  getListHistoryAction: getHistoryQuotationRequest,
  keyAmount: DEFAULT_KEY_NAME_AMOUNT_REF_API,
  keyTransactionNumber: DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF,
  keyUpdatedAt: DEFAULT_KEY_NAME_UPDATED_AT_API,
  keyUpdatedBy: DEFAULT_KEY_NAME_UPDATED_BY_NAME_API,
  keyStatusTransaction: DEFAULT_KEY_NAME_STATUS_REF_API,
  keyTransactionDate: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
  keyExpiredDate: DEFAULT_KEY_NAME_EXPIRED_DATE_API_REF,
  keyProduct: DEFAULT_KEY_NAME_PRODUCT_API,
  keyContact: DEFAULT_KEY_NAME_SUPPLIER_NAME_API_REF,
  keyContactAddress: DEFAULT_KEY_NAME_SUPPLIER_ADDRESS_API_REF,
  keyAgent: null,
  keyMemo: DEFAULT_KEY_NAME_DESCRIPTION_API_REF,
  showAmountTransactionLeftSide: true,
  showAmountChildrenLeftSide: false,
  typographyAmountChildrenLeftSide: 'typography.product-total',
  usageTableFooterChildrenTransaction: true,
  columnsTableFooterChildrenTransaction: (t) => columnsTableFooterProductDataList(t),
  usageAdditionalDiscountValue: false,
  keyDiscountValue: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
  keyDiscountPercentage: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
  keyDiscountNominal: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
  formatterDiscountValue: (discountValue) =>
    `${formatHelp.currencyWithAutoComma(Number(discountValue))}%`,
};

export const defaultTransactionProHistoryListConfig = {
  [DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_TYPE]: {
    ...defaultAssetPurchaseHistoryListConfig,
    correspondHistoryKeyNamesWithIdentity: {
      [DEFAULT_KEY_NAME_MODUL_ID_API_REF]: DEFAULT_KEY_NAME_QUOTATION_REQUEST_ID_API_REF,
    },
    columnsTableHeaderChildrenTransaction: (t) => columnsTableHeaderProductDataListV2(t),
    generateChildrenTransactionData: ({ historyData }) => {
      const { updateProductData } = generateDataNValidationUpdateProductQuotationRequestV2(
        historyData,
        null,
        false,
        DEFAULT_KEY_NAME_PRODUCT_API,
      );

      return updateProductData;
    },
    parentMenuURL: REACT_APP_PURCHASE_QUOTATION_REQUEST_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_ORDER_TYPE]: {
    ...defaultAssetPurchaseHistoryListConfig,
    childrenListStateName: SLICE_NAME_PURCHASE_ORDER_HISTORY,
    requestStatusPendingGetList: STATUS_REQUEST_HISTORY_PURCHASE_ORDER_PENDING,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_ORDER_ID,
    identity: DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_API_REF,
    uniqueParamsGetHistoryLink: DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_API_REF,
    title: 'header.purchase-order',
    urlDetailsTemplate: REACT_APP_PURCHASE_PURCHASE_ORDER_DETAILS_TEMPLATE_URL,
    getStatusTransaction: (t) => purchaseOrderStatus(t),
    statusTransaction: purchaseOrderPlainStatus,
    getListHistoryAction: getHistoryPurchaseOrder,
    correspondHistoryKeyNamesWithIdentity: {
      [DEFAULT_KEY_NAME_MODUL_ID_API_REF]: DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_API_REF,
    },
    columnsTableHeaderChildrenTransaction: (t) => columnsTableHeaderProductDataListV2(t),
    generateChildrenTransactionData: ({ historyData }) => {
      const { childrenTransactionData } = generateDataNValidationUpdatePurchaseOrderProductV2({
        purchaseOrderDataSelected: historyData,
        customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
        keynamePurchaseOrderProduct: DEFAULT_KEY_NAME_PRODUCT_API,
        addNewRowPurchaseOrderProduct: false,
      });

      return childrenTransactionData;
    },
    parentMenuURL: REACT_APP_PURCHASE_PURCHASE_ORDER_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE]: {
    ...defaultAssetPurchaseHistoryListConfig,
    childrenListStateName: SLICE_NAME_PURCHASE_DELIVERY_HISTORY_DETAILS,
    requestStatusPendingGetList: STATUS_REQUEST_HISTORY_PURCHASE_DELIVERY_PENDING,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_DELIVERY_ID,
    identity: DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF,
    uniqueParamsGetHistoryLink: DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF,
    title: 'header.purchase-delivery',
    urlDetailsTemplate: REACT_APP_PURCHASE_PURCHASE_DELIVERY_DETAILS_TEMPLATE_URL,
    getStatusTransaction: (t) => purchaseDeliveryStatus(t),
    statusTransaction: purchaseDeliveryPlainStatus,
    getListHistoryAction: getPurchaseDeliveryHistoryDetails,
    correspondHistoryKeyNamesWithIdentity: {
      [DEFAULT_KEY_NAME_MODUL_ID_API_REF]: DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF,
    },
    keyNameChildrenTransactionData: DEFAULT_KEY_NAME_PRODUCT_API,
    columnsTableHeaderChildrenTransaction: (t) =>
      columnsTableHeaderPurchaseDeliveryProductDataListV2(t),
    generateChildrenTransactionData: ({ historyData }) => {
      const { childrenTransactionData } = generateDataNValidationUpdateProductPurchaseDeliveryV3({
        purchaseDeliveryDataSelected: historyData,
        customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
      });

      return childrenTransactionData;
    },
    usageTableFooterChildrenTransaction: false,
    showAmountTransactionLeftSide: false,
    showAmountChildrenLeftSide: true,
    parentMenuURL: REACT_APP_PURCHASE_PURCHASE_DELIVERY_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_INVOICE_TYPE]: {
    ...defaultAssetPurchaseHistoryListConfig,
    childrenListStateName: SLICE_NAME_PURCHASE_INVOICE_HISTORY,
    requestStatusPendingGetList: STATUS_REQUEST_HISTORY_PURCHASE_INVOICE_PENDING,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_INVOICE_ID,
    identity: DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF,
    uniqueParamsGetHistoryLink: DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF,
    title: 'header.purchase-invoice',
    urlDetailsTemplate: REACT_APP_PURCHASE_PURCHASE_INVOICE_DETAILS_TEMPLATE_URL,
    getStatusTransaction: (t) => purchaseInvoiceStatus(t),
    statusTransaction: purchaseInvoicePlainStatus,
    getListHistoryAction: getHistoryPurchaseInvoice,
    correspondHistoryKeyNamesWithIdentity: {
      [DEFAULT_KEY_NAME_MODUL_ID_API_REF]: DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF,
    },
    generateChildrenTransactionData: ({ historyData }) => {
      const { childrenTransactionData } =
        generateDataNValidationUpdateProductPurchaseInvoiceFromDetailPurchaseInvoiceV2({
          purchaseInvoiceDetails: historyData,
          customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
        });

      return childrenTransactionData;
    },
    columnsTableHeaderChildrenTransaction: (t) => columnsTableHeaderProductDataListV2(t),
    columnsTableFooterChildrenTransaction: (t) => columnsTableFooterProductPurchaseInvoiceList(t),
    usageAdditionalDiscountValue: true,
    parentMenuURL: REACT_APP_PURCHASE_PURCHASE_INVOICE_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_PAYMENT_TYPE]: {
    ...defaultAssetPurchaseHistoryListConfig,
    childrenListStateName: SLICE_NAME_PURCHASE_PAYMENT_HISTORY,
    requestStatusPendingGetList: STATUS_REQUEST_HISTORY_PURCHASE_PAYMENT_PENDING,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_PAYMENT_ID,
    identity: DEFAULT_KEY_NAME_PURCHASE_PAYMENT_ID_API_REF,
    uniqueParamsGetHistoryLink: DEFAULT_KEY_NAME_PURCHASE_PAYMENT_ID_API_REF,
    title: 'header.purchase-payment',
    urlDetailsTemplate: REACT_APP_PURCHASE_PURCHASE_PAYMENT_DETAILS_TEMPLATE_URL,
    getStatusTransaction: null,
    statusTransaction: null,
    getListHistoryAction: getHistoryPurchasePayment,
    keyStatusTransaction: null,
    correspondHistoryKeyNamesWithIdentity: {
      [DEFAULT_KEY_NAME_MODUL_ID_API_REF]: DEFAULT_KEY_NAME_PURCHASE_PAYMENT_ID_API_REF,
    },
    keyNameChildrenTransactionData: DEFAULT_KEY_NAME_INVOICE_PAYMENT_API_REF,
    columnsTableHeaderChildrenTransaction: (t) => columnsTableHeaderInvoicePurchasePaymentV2(t),
    columnsTableFooterChildrenTransaction: (t) => columnsTableFooterInvoicePurchasePaymentList(t),
    usageTableFooterChildrenTransaction: true,
    generateChildrenTransactionData: ({ historyData }) => {
      const { childrenTransactionData } = generateDataNValidationUpdateInvoicePPFromDetailPPV2({
        purchasePaymentOld: historyData,
        customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
        keynameInvoicePurchasePayment: DEFAULT_KEY_NAME_INVOICE_PAYMENT_API_REF,
      });

      return childrenTransactionData;
    },
    parentMenuURL: REACT_APP_PURCHASE_PURCHASE_PAYMENT_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_RETURN_TYPE]: {
    ...defaultAssetPurchaseHistoryListConfig,
    childrenListStateName: SLICE_NAME_PURCHASE_RETURN_HISTORY,
    requestStatusPendingGetList: REQUEST_STATUS_HISTORY_PURCHASE_RETURN_LOADING,
    requestStatusFailedGetList: REQUEST_STATUS_HISTORY_PURCHASE_RETURN_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_RETURN_ID,
    identity: DEFAULT_KEY_NAME_PURCHASE_RETURN_ID_API_REF,
    uniqueParamsGetHistoryLink: DEFAULT_KEY_NAME_PURCHASE_RETURN_ID_API_REF,
    getListHistoryAction: getPurchaseReturnHistory,
  },
  [DEFAULT_TRANSACTION_PURCHASE_RECEIVABLE_PAYMENT_TYPE]: {
    ...defaultAssetPurchaseHistoryListConfig,
    childrenListStateName: SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_HISTORY,
    requestStatusPendingGetList: REQUEST_STATUS_HISTORY_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
    requestStatusFailedGetList: REQUEST_STATUS_HISTORY_PURCHASE_RECEIVABLE_PAYMENT_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_RECEIVABLE_PAYMENT_ID,
    identity: DEFAULT_KEY_NAME_RECEIVABLE_PAYMENT_ID_API_REF,
    uniqueParamsGetHistoryLink: DEFAULT_KEY_NAME_RECEIVABLE_PAYMENT_ID_API_REF,
    getListHistoryAction: getPurchaseReceivablePaymentHistory,
  },
  [DEFAULT_TRANSACTION_PURCHASE_DEBIT_NOTE_TYPE]: {
    ...defaultAssetPurchaseHistoryListConfig,
    childrenListStateName: SLICE_NAME_PURCHASE_DEBIT_NOTE_HISTORY,
    requestStatusPendingGetList: STATUS_REQUEST_HISTORY_PURCHASE_DEBIT_NOTE_PENDING,
    requestStatusFailedGetList: STATUS_REQUEST_HISTORY_PURCHASE_DEBIT_NOTE_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_DEBITE_NOTE_ID,
    identity: DEFAULT_KEY_NAME_DEBIT_NOTE_ID_API_REF,
    uniqueParamsGetHistoryLink: DEFAULT_KEY_NAME_DEBIT_NOTE_ID_API_REF,
    getListHistoryAction: getPurchaseDebitNoteHistory,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_QUOTATION_TYPE]: {
    ...defaultAssetSalesHistoryListConfig,
    keyNameChildrenTransactionData: DEFAULT_KEY_NAME_PRODUCT_API,
    columnsTableHeaderChildrenTransaction: (t) => columnsTableHeaderProductDataListV2(t),
    columnsTableFooterChildrenTransaction: (t) => columnsTableFooterProductDataList(t),
    usageTableFooterChildrenTransaction: true,
    generateChildrenTransactionData: ({ historyData }) => {
      const { updateProductData } = generateDataNValidationUpdateProductSQ(
        historyData,
        null,
        false,
        DEFAULT_KEY_NAME_PRODUCT_API,
      );

      return updateProductData;
    },
    parentMenuURL: REACT_APP_SALES_SALES_QUOTATION_URL,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_ORDER_TYPE]: {
    ...defaultAssetSalesHistoryListConfig,
    childrenListStateName: SLICE_NAME_SALES_ORDER_HISTORY_DETAILS,
    requestStatusPendingGetList: STATUS_REQUEST_DETAIL_HISTORY_SALES_ORDER_PENDING,
    requestStatusFailedGetList: STATUS_REQUEST_DETAIL_HISTORY_SALES_ORDER_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_SALES_ORDER_ID,
    identity: DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF,
    uniqueParamsGetHistoryLink: DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF,
    title: 'header.sales-order',
    correspondHistoryKeyNamesWithIdentity: {
      [DEFAULT_KEY_NAME_MODUL_ID_API_REF]: DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF,
    },
    urlDetailsTemplate: REACT_APP_SALES_SALES_ORDER_DETAILS_TEMPLATE_URL,
    getStatusTransaction: (t) => salesOrderStatus(t),
    statusTransaction: salesOrderPlainStatus,
    getListHistoryAction: getSalesOrderHistoryDetails,
    keyNameChildrenTransactionData: DEFAULT_KEY_NAME_PRODUCT_API,
    columnsTableHeaderChildrenTransaction: (t) => columnsTableHeaderProductDataListV2(t),
    columnsTableFooterChildrenTransaction: (t) => columnsTableFooterProductDataList(t),
    usageTableFooterChildrenTransaction: true,
    generateChildrenTransactionData: ({ historyData }) => {
      const { childrenTransactionData } =
        generateDataNValidationUpdateProductSalesOrderFromSalesOrderDetailsV2({
          salesOrderDetails: historyData,
          customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
          amountNewRow: 0,
          useNewRow: false,
          keyNameProductSalesOrder: DEFAULT_KEY_NAME_PRODUCT_API,
        });

      return childrenTransactionData;
    },
    parentMenuURL: REACT_APP_SALES_SALES_ORDER_URL,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE]: {
    ...defaultAssetSalesHistoryListConfig,
    childrenListStateName: SLICE_NAME_SALES_DELIVERY_HISTORY_DETAILS,
    requestStatusPendingGetList: STATUS_REQUEST_DETAIL_HISTORY_SALES_DELIVERY_PENDING,
    requestStatusFailedGetList: STATUS_REQUEST_DETAIL_HISTORY_SALES_DELIVERY_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_SALES_DELIVERY_ID,
    identity: DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF,
    uniqueParamsGetHistoryLink: DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF,
    title: 'header.sales-delivery',
    correspondHistoryKeyNamesWithIdentity: {
      [DEFAULT_KEY_NAME_MODUL_ID_API_REF]: DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF,
    },
    urlDetailsTemplate: REACT_APP_SALES_SALES_DELIVERY_DETAILS_TEMPLATE_URL,
    getStatusTransaction: (t) => salesDeliveryStatus(t),
    statusTransaction: salesDeliveryPlainStatus,
    getListHistoryAction: getSalesDeliveryHistoryDetails,
    keyNameChildrenTransactionData: DEFAULT_KEY_NAME_PRODUCT_API,
    columnsTableHeaderChildrenTransaction: (t) =>
      columnsTableHeaderSalesDeliveryProductDataListV2(t),
    generateChildrenTransactionData: ({ historyData }) => {
      const { childrenTransactionData } =
        generateDataNValidationUpdateProductSalesDeliveryFromSalesDeliveryDetailsV2({
          salesDeliveryDetails: historyData,
          customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
        });

      return childrenTransactionData;
    },
    showAmountTransactionLeftSide: false,
    showAmountChildrenLeftSide: true,
    parentMenuURL: REACT_APP_SALES_SALES_DELIVERY_URL,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_INVOICE_TYPE]: {
    ...defaultAssetSalesHistoryListConfig,
    childrenListStateName: SLICE_NAME_SALES_INVOICE_HISTORY,
    requestStatusPendingGetList: STATUS_REQUEST_HISTORY_SALES_INVOICE_PENDING,
    requestStatusFailedGetList: STATUS_REQUEST_HISTORY_SALES_INVOICE_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_SALES_INVOICE_ID,
    identity: DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF,
    uniqueParamsGetHistoryLink: DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF,
    title: 'header.sales-invoice',
    correspondHistoryKeyNamesWithIdentity: {
      [DEFAULT_KEY_NAME_MODUL_ID_API_REF]: DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF,
    },
    urlDetailsTemplate: REACT_APP_SALES_SALES_INVOICE_DETAILS_TEMPLATE_URL,
    getStatusTransaction: (t) => salesInvoiceStatus(t),
    statusTransaction: salesInvoicePlainStatus,
    getListHistoryAction: getHistorySalesInvoice,
    keyNameChildrenTransactionData: DEFAULT_KEY_NAME_PRODUCT_API,
    columnsTableHeaderChildrenTransaction: (t) => columnsTableHeaderProductDataListV2(t),
    columnsTableFooterChildrenTransaction: (t) => columnsTableFooterProductSalesInvoiceList(t),
    usageTableFooterChildrenTransaction: true,
    usageAdditionalDiscountValue: true,
    generateChildrenTransactionData: ({ historyData }) => {
      const { childrenTransactionData } = generateDataNValidationUpdateProductSalesInvoiceV2({
        salesInvoiceDataSelected: historyData,
        customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
        useAddNewRow: false,
      });

      return childrenTransactionData;
    },
    parentMenuURL: REACT_APP_SALES_SALES_INVOICE_URL,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_PAYMENT_TYPE]: {
    ...defaultAssetSalesHistoryListConfig,
    childrenListStateName: SLICE_NAME_SALES_PAYMENT_HISTORY,
    requestStatusPendingGetList: STATUS_REQUEST_HISTORY_SALES_PAYMENT_PENDING,
    requestStatusFailedGetList: STATUS_REQUEST_HISTORY_SALES_PAYMENT_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_SALES_PAYMENT_ID,
    identity: DEFAULT_KEY_NAME_SALES_PAYMENT_ID_API_REF,
    uniqueParamsGetHistoryLink: DEFAULT_KEY_NAME_SALES_PAYMENT_ID_API_REF,
    title: 'header.sales-payment',
    correspondHistoryKeyNamesWithIdentity: {
      [DEFAULT_KEY_NAME_MODUL_ID_API_REF]: DEFAULT_KEY_NAME_SALES_PAYMENT_ID_API_REF,
    },
    urlDetailsTemplate: REACT_APP_SALES_SALES_PAYMENT_DETAILS_TEMPLATE_URL,
    getStatusTransaction: null,
    statusTransaction: null,
    getListHistoryAction: getHistorySalesPayment,
    keyStatusTransaction: null,
    keyNameChildrenTransactionData: DEFAULT_KEY_NAME_INVOICE_PAYMENT_API_REF,
    columnsTableHeaderChildrenTransaction: (t) => columnsTableHeaderInvoiceSalesPaymentV2(t),
    columnsTableFooterChildrenTransaction: (t) => columnsTableFooterInvoiceSalesPaymentList(t),
    usageTableFooterChildrenTransaction: true,
    generateChildrenTransactionData: ({ historyData }) => {
      const { childrenTransactionData } =
        generateDataNValidationUpdateInvoiceSalesPaymentFromDetailSPv2({
          salesPaymentDetails: historyData,
          customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
          keyNameInvoiceSalesPayment: DEFAULT_KEY_NAME_INVOICE_PAYMENT_API_REF,
        });

      return childrenTransactionData;
    },
    parentMenuURL: REACT_APP_SALES_SALES_PAYMENT_URL,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_RETURN_TYPE]: {
    ...defaultAssetSalesHistoryListConfig,
    childrenListStateName: SLICE_NAME_SALES_RETURN_HISTORY,
    requestStatusPendingGetList: REQUEST_STATUS_HISTORY_SALES_RETURN_LOADING,
    requestStatusFailedGetList: REQUEST_STATUS_HISTORY_SALES_RETURN_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_SALES_RETURN_ID,
    isNotUseObjectToGetHistoryList: false,
    uniqueParamsGetHistoryLink: DEFAULT_KEY_NAME_SALES_RETURN_ID_API_REF,
    getListHistoryAction: getSalesReturnHistory,
  },
  [DEFAULT_TRANSACTION_SALES_PAYABLE_PAYMENT_TYPE]: {
    ...defaultAssetSalesHistoryListConfig,
    childrenListStateName: SLICE_NAME_SALES_PAYABLE_PAYMENT_HISTORY,
    requestStatusPendingGetList: REQUEST_STATUS_HISTORY_SALES_PAYABLE_PAYMENT_LOADING,
    requestStatusFailedGetList: REQUEST_STATUS_HISTORY_SALES_PAYABLE_PAYMENT_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_PAYABLE_PAYMENT_ID,
    isNotUseObjectToGetHistoryList: false,
    uniqueParamsGetHistoryLink: DEFAULT_KEY_NAME_PAYABLE_PAYMENT_ID_API_REF,
    getListHistoryAction: getSalesPayablePaymentHistory,
  },
  [DEFAULT_TRANSACTION_SALES_CREDIT_NOTE_TYPE]: {
    ...defaultAssetSalesHistoryListConfig,
    childrenListStateName: SLICE_NAME_SALES_CREDIT_NOTE_HISTORY,
    requestStatusPendingGetList: STATUS_REQUEST_HISTORY_SALES_CREDIT_NOTE_PENDING,
    requestStatusFailedGetList: STATUS_REQUEST_HISTORY_SALES_CREDIT_NOTE_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_CREDIT_NOTE_ID,
    isNotUseObjectToGetHistoryList: false,
    identity: DEFAULT_KEY_NAME_CREDIT_NOTE_ID_API_REF,
    uniqueParamsGetHistoryLink: DEFAULT_KEY_NAME_CREDIT_NOTE_ID_API_REF,
    getListHistoryAction: getSalesCreditNoteHistory,
  },
};

export const defaultTransactionHandoverHistoryListConfig = {
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE]: {
    ...defaultTransactionProHistoryListConfig[DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE],
    parentStateName: ROOT_STORE_NAME_MINUTES_OF_HANDOVER,
    childrenListStateName: SLICE_NAME_HANDOVER_PURCHASE_DELIVERY_HISTORY,
    requestStatusKeyName: DEFAULT_KEY_NAME_REQUEST_STATUS,
    requestStatusPendingGetList: REQUEST_STATUS_HISTORY_HANDOVER_PURCHASE_DELIVERY_PENDING,
    requestStatusFailedGetList: REQUEST_STATUS_HISTORY_HANDOVER_PURCHASE_DELIVERY_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_DELIVERY_HANDOVER_ID,
    isNotUseObjectToGetHistoryList: false,
    uniqueParamsGetHistoryLink: DEFAULT_KEY_NAME_PURCHASE_HANDOVER_ID_API_REF,
    getListHistoryAction: (params) =>
      minutesOfHandoverDetails({
        actionName: 'history',
        paramsMinutesOfHandoverDetails: params,
      }),
  },
  [DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE]: {
    ...defaultTransactionProHistoryListConfig[DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE],
    parentStateName: ROOT_STORE_NAME_MINUTES_OF_HANDOVER,
    childrenListStateName: SLICE_NAME_HANDOVER_SALES_DELIVERY_HISTORY,
    requestStatusKeyName: DEFAULT_KEY_NAME_REQUEST_STATUS,
    requestStatusPendingGetList: REQUEST_STATUS_HISTORY_HANDOVER_SALES_DELIVERY_PENDING,
    requestStatusFailedGetList: REQUEST_STATUS_HISTORY_HANDOVER_SALES_DELIVERY_FAILED,
    uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_SALES_DELIVERY_HANDOVER_ID,
    isNotUseObjectToGetHistoryList: false,
    uniqueParamsGetHistoryLink: DEFAULT_KEY_NAME_SALES_HANDOVER_ID_API_REF,
    getListHistoryAction: (params) =>
      minutesOfHandoverDetails({
        minutesHandoverType: MINUTES_OF_HANDOVER_TYPE_SALES_DELIVERY,
        actionName: 'history',
        paramsMinutesOfHandoverDetails: params,
      }),
  },
};

const correspondAPIrefWithTransactionType = {
  [DEFAULT_TRANSACTION_SALES_QUOTATION_API_REF_TYPE]:
    DEFAULT_TRANSACTION_SALES_SALES_QUOTATION_TYPE,
  [DEFAULT_TRANSACTION_SALES_ORDER_API_REF_TYPE]: DEFAULT_TRANSACTION_SALES_SALES_ORDER_TYPE,
  [DEFAULT_TRANSACTION_SALES_DELIVERY_API_REF_TYPE]: DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE,
  [DEFAULT_TRANSACTION_SALES_INVOICE_API_REF_TYPE]: DEFAULT_TRANSACTION_SALES_SALES_INVOICE_TYPE,
  [DEFAULT_TRANSACTION_SALES_PAYMENT_API_REF_TYPE]: DEFAULT_TRANSACTION_SALES_SALES_PAYMENT_TYPE,
  [DEFAULT_TRANSACTION_SALES_RETURN_API_REF_TYPE]: DEFAULT_TRANSACTION_SALES_SALES_RETURN_TYPE,
  [DEFAULT_TRANSACTION_SALES_PAYABLE_PAYMENT_API_REF_TYPE]:
    DEFAULT_TRANSACTION_SALES_PAYABLE_PAYMENT_TYPE,
  [DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_API_REF_TYPE]:
    DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_TYPE,
  [DEFAULT_TRANSACTION_PURCHASE_ORDER_API_REF_TYPE]:
    DEFAULT_TRANSACTION_PURCHASE_PURCHASE_ORDER_TYPE,
  [DEFAULT_TRANSACTION_PURCHASE_DELIVERY_API_REF_TYPE]:
    DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE,
  [DEFAULT_TRANSACTION_PURCHASE_INVOICE_API_REF_TYPE]:
    DEFAULT_TRANSACTION_PURCHASE_PURCHASE_INVOICE_TYPE,
  [DEFAULT_TRANSACTION_PURCHASE_PAYMENT_API_REF_TYPE]:
    DEFAULT_TRANSACTION_PURCHASE_PURCHASE_PAYMENT_TYPE,
  [DEFAULT_TRANSACTION_PURCHASE_RETURN_API_REF_TYPE]:
    DEFAULT_TRANSACTION_PURCHASE_PURCHASE_RETURN_TYPE,
  [DEFAULT_TRANSACTION_PURCHASE_RECEIVABLE_PAYMENT_API_REF_TYPE]:
    DEFAULT_TRANSACTION_PURCHASE_RECEIVABLE_PAYMENT_TYPE,
};

const correspondAPIrefWithHandoverTransactionType = {
  [DEFAULT_TRANSACTION_SALES_DELIVERY_API_REF_TYPE]: DEFAULT_TRANSACTION_TYPE_SALES_HANDOVER,
  [DEFAULT_TRANSACTION_PURCHASE_DELIVERY_API_REF_TYPE]: DEFAULT_TRANSACTION_TYPE_PURCHASE_HANDOVER,
};

export const getDefaultTransactionProHistoryConfig = (subTransactionType, isHandover = false) => {
  const usagedTransactionListConf = isHandover
    ? defaultTransactionHandoverHistoryListConfig
    : defaultTransactionProHistoryListConfig;

  const usagedCorrespondAPIwithTransactionType = isHandover
    ? correspondAPIrefWithHandoverTransactionType
    : correspondAPIrefWithTransactionType;

  const allKeyNamesDefaultTransactionHistoryList = Object.keys(usagedTransactionListConf);
  const correspondOnAPIrefNameKeys = Object.keys(usagedCorrespondAPIwithTransactionType);

  if (
    (!allKeyNamesDefaultTransactionHistoryList.includes(subTransactionType) &&
      !correspondOnAPIrefNameKeys.includes(subTransactionType)) ||
    !subTransactionType
  ) {
    subTransactionType = allKeyNamesDefaultTransactionHistoryList[0];
  }

  if (correspondOnAPIrefNameKeys.includes(subTransactionType)) {
    subTransactionType = usagedCorrespondAPIwithTransactionType[subTransactionType];
  }

  return usagedTransactionListConf[subTransactionType];
};

export const defaultAssetJournalEntryDetails = {
  parentStateName: ROOT_STORE_NAME_JOURNAL_ENTRY,
  childrenDetailsStateName: SLICE_NAME_JOURNAL_ENTRY_DETAIL,
  requestStatusPendingGetDetails: STATUS_REQUEST_DETAIL_JOURNAL_ENTRY_PENDING,
  requestStatusKeyName: DEFAULT_KEY_NAME_STATUS_REQUEST,
  uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_TRANSACTION_ID,
  uniqueParamsGetDetails: DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF,
  journalListKeyName: DEFAULT_KEY_NAME_JOURNAL_ENTRY_LIST_API_REF,
  isNotUseObjectToGetDetails: true,
  actionGetDetails: detailJournalEntry,
};

function formulaCountAdditionalCost({
  allAmountTransaction = 0,
  discountNominal = 0,
  expenseValue = 0,
  downPaymentValue = 0,
}) {
  return Number(
    Number(allAmountTransaction) -
      Number(discountNominal) +
      Number(expenseValue) -
      Number(downPaymentValue),
  ).toFixed(2);
}

function formulaCountDiscountCost({
  transactionData = [],
  allAmountTransaction = 0,
  discountTypeKeyName = DEFAULT_KEY_NAME_DISCOUNT_TYPE_API_REF,
  discountNominalKeyName = DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
  discountPercentageKeyName = DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
}) {
  if (!Array.isArray(transactionData) || isEmpty(transactionData[0])) {
    return {
      [discountNominalKeyName]: 0,
      [discountPercentageKeyName]: 0,
    };
  }

  transactionData = transactionData[0];

  const discountType = transactionData[discountTypeKeyName];
  let discountNominal = transactionData[discountNominalKeyName],
    discountPercentage = transactionData[discountPercentageKeyName];

  if (discountType === DEFAULT_DISCOUNT_NOMINAL_TYPE) {
    discountPercentage = (Number(discountNominal) / Number(allAmountTransaction)) * 100;
  } else if (discountType === DEFAULT_DISCOUNT_PERCENTAGE_TYPE) {
    discountNominal = (Number(discountPercentage) * Number(allAmountTransaction)) / 100;
  }

  // changes of both when maximum discount
  discountPercentage = discountPercentage <= 100 ? discountPercentage : 100;
  discountNominal =
    discountNominal <= allAmountTransaction ? discountNominal : allAmountTransaction;

  return {
    [discountNominalKeyName]: discountNominal,
    [discountPercentageKeyName]: discountPercentage,
  };
}

export const defaultAdditionalSettingWarehouseConfig = {
  [DEFAULT_TRANSACTION_PURCHASE_TYPE]: [
    {
      // warehouse default
      parentStateName: NAME_STORE.WAREHOUSE,
      childrenListStateName: SLICE_NAME_SETTINGS_WAREHOUSE_DATA,
      keyUniqueName: DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF,
      keyNameSubResponseData: DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF,
      defaultKeyInputName: DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF,
      defaultKeyInputContainerName: DEFAULT_KEY_NAME_WAREHOUSE_ID_CONTAINER,
      indexSelected: 0,
      action: getWarehouseListWithDynamicStore,
      additionalParams: {
        [DEFAULT_KEY_NAME_WAREHOUSE_TYPE_API_REF]: [WAREHOUSE_MAIN_TYPE],
      },
      isAddDefaultInput: ({ transactionData = [] }) => {
        return (
          isEmpty(transactionData) || !transactionData[0][DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF]
        );
      },
      isListData: true,
    },
  ],
  [DEFAULT_TRANSACTION_SALES_TYPE]: [
    {
      // warehouse default
      parentStateName: NAME_STORE.WAREHOUSE,
      childrenListStateName: SLICE_NAME_SETTINGS_WAREHOUSE_DATA,
      keyUniqueName: DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF,
      keyNameSubResponseData: DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF,
      defaultKeyInputName: DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF,
      defaultKeyInputContainerName: DEFAULT_KEY_NAME_WAREHOUSE_ID_CONTAINER,
      indexSelected: 0,
      action: getWarehouseListWithDynamicStore,
      additionalParams: {
        [DEFAULT_KEY_NAME_WAREHOUSE_TYPE_API_REF]: [WAREHOUSE_MAIN_TYPE],
      },
      isAddDefaultInput: ({ transactionData = [] }) => {
        return (
          isEmpty(transactionData) || !transactionData[0][DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF]
        );
      },
      isListData: true,
    },
  ],
};

const defaultAccountKeyInputNamePurchaseSalesTransaction = [
  DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_API_REF,
  DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_API_REF,
  DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_API_REF,
];

const defaultAccountContainerKeyInputNamePurchaseSalesTransaction = [
  DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_CONTAINER,
  DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_CONTAINER,
  DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_CONTAINER,
];

export const defaultAccountAdditionalCostConfig = {
  [DEFAULT_TRANSACTION_SALES_TYPE]: [
    // discount, other cost, down payment
    {
      parentStateName: ROOT_STORE_NAME_ACCOUNT,
      childrenListStateName: SLICE_NAME_ACCOUNT_DATA,
      action: getAccountList,
      keyNameSubResponseData: DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF,
      keyUniqueName: Array(3).fill(DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF),
      defaultKeyInputName: defaultAccountKeyInputNamePurchaseSalesTransaction,
      defaultKeyInputContainerName: defaultAccountContainerKeyInputNamePurchaseSalesTransaction,
      isMultipleInput: true,
      uniqueResponseKeyname: Array(3).fill(DEFAULT_KEY_NAME_ACCOUNT_NAME_API_REF),
      correspondingResponseValue: [
        DEFAULT_ACCOUNT_NAME_DISKON_PENJUALAN,
        DEFAULT_ACCOUNT_OTHER_INCOMES_NAME,
        DEFAULT_ACCOUNT_NAME_CASH,
      ],
      isAddDefaultInput: ({ transactionData = [] }) => {
        return (
          isEmpty(transactionData) ||
          Object.keys(
            objHelper.filteringExistedValueV2({
              obj: transactionData[0],
              allowKeys: defaultAccountKeyInputNamePurchaseSalesTransaction,
            }),
          ) < defaultAccountKeyInputNamePurchaseSalesTransaction.length
        );
      },
      getAdditionalParams: ({ transactionData = [] }) => {
        if (isEmpty(transactionData)) return null;

        return {
          [DEFAULT_KEY_NAME_ACCOUNT_NAME_API_REF]: arrHelp.substituteBooleanItem({
            arr: objHelper.filteringExistedValueV2({
              obj: transactionData[0],
              convert2booleanArray: true,
              allowKeys: defaultAccountKeyInputNamePurchaseSalesTransaction,
            }),
            substituteFalseItems: [
              DEFAULT_ACCOUNT_NAME_DISKON_PENJUALAN,
              DEFAULT_ACCOUNT_OTHER_INCOMES_NAME,
              DEFAULT_ACCOUNT_NAME_CASH,
            ],
            substituteTrueItems: [],
          }),
        };
      },
    },
  ],
  [DEFAULT_TRANSACTION_PURCHASE_TYPE]: [
    // discount, other cost, down payment
    {
      parentStateName: ROOT_STORE_NAME_ACCOUNT,
      childrenListStateName: SLICE_NAME_ACCOUNT_DATA,
      action: getAccountList,
      keyNameSubResponseData: DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF,
      keyUniqueName: Array(3).fill(DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF),
      defaultKeyInputName: defaultAccountKeyInputNamePurchaseSalesTransaction,
      defaultKeyInputContainerName: defaultAccountContainerKeyInputNamePurchaseSalesTransaction,
      isMultipleInput: true,
      uniqueResponseKeyname: Array(3).fill(DEFAULT_KEY_NAME_ACCOUNT_NAME_API_REF),
      correspondingResponseValue: [
        DEFAULT_ACCOUNT_NAME_PURCHASE_DISCOUNT,
        DEFAULT_ACCOUNT_OTHER_EXPENSES_NAME,
        DEFAULT_ACCOUNT_NAME_CASH,
      ],
      isAddDefaultInput: ({ transactionData = [] }) => {
        return (
          isEmpty(transactionData) ||
          Object.keys(
            objHelper.filteringExistedValueV2({
              obj: transactionData[0],
              allowKeys: defaultAccountKeyInputNamePurchaseSalesTransaction,
            }),
          ) < defaultAccountKeyInputNamePurchaseSalesTransaction.length
        );
      },
      getAdditionalParams: ({ transactionData = [] }) => {
        if (isEmpty(transactionData)) return null;

        return {
          [DEFAULT_KEY_NAME_ACCOUNT_NAME_API_REF]: arrHelp.substituteBooleanItem({
            arr: objHelper.filteringExistedValueV2({
              obj: transactionData[0],
              convert2booleanArray: true,
              allowKeys: defaultAccountKeyInputNamePurchaseSalesTransaction,
            }),
            substituteFalseItems: [
              DEFAULT_ACCOUNT_NAME_PURCHASE_DISCOUNT,
              DEFAULT_ACCOUNT_OTHER_EXPENSES_NAME,
              DEFAULT_ACCOUNT_NAME_CASH,
            ],
            substituteTrueItems: [],
          }),
        };
      },
    },
  ],
  [DEFAULT_TRANSACTION_EXPENSE_TYPE]: [
    {
      // discount
      parentStateName: NAME_STORE.ACCOUNT,
      childrenListStateName: SLICE_NAME_ACCOUNT_DATA,
      keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
      keyNameSubResponseData: DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF,
      defaultKeyInputName: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_API_REF,
      defaultKeyInputContainerName: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_CONTAINER,
      indexSelected: 0,
      action: getAccountList,
      additionalParams: {
        [DEFAULT_KEY_NAME_ACCOUNT_NAME_API_REF]: DEFAULT_ACCOUNT_NAME_CASH,
      },
      isAddDefaultInput: ({ transactionData = [] }) => {
        return (
          isEmpty(transactionData) ||
          !transactionData[0][DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_API_REF]
        );
      },
      isListData: true,
    },
  ],
};

const clearDataProductSupplierAction = {
  isClearData: true,
  action: clearDataProductSlice,
  additionalParams: DEFAULT_PAYLOAD_STORAGE_PRODUCT_DATA_BY_SUPPLIER,
};

export const defaultOnDependenciesListDetailsAttachmentAction = {
  getAction: (prevActionResponse) => listDetailsAttachment(prevActionResponse),
  parentState: ROOT_STORE_NAME_ATTACHMENT,
  childrenState: SLICE_NAME_LIST_DETAILS_ATTACHMENT,
  keyNameRequestStatus: DEFAULT_KEY_NAME_REQUEST_STATUS,
  requestStatusSuccess: REQUEST_STATUS_LIST_DETAILS_ATTACHMENT_SUCCESS,
  requestStatusPending: REQUEST_STATUS_LIST_DETAILS_ATTACHMENT_PENDING,
  requestStatusFailed: REQUEST_STATUS_LIST_DETAILS_ATTACHMENT_FAILED,
  keynameForDependedResponse: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
  keynamesRequestRequirement: [DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF],
};

export const defaultTransactionProCreateUpdateConfig = {
  [DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_TYPE]: {
    defaultTransctionNumberCode: DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_QUOTATION_REQUEST,
    allowKeyInputTransactionData: allowKeysDataQuotationRequest,
    allowKeyInputProductTransctionData: allowKeysDataProductQuotationRequest,
    allowKeyInputChildrenTransactionData: allowKeysDataProductQuotationRequest,
    keyNameReferenceNumber: DEFAULT_KEY_NAME_REFERENCE_NUMBER,
    keyNameReferenceNumberContainer: DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER,
    keyNameTransactionDate: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
    keyNameAccountingPeriod: DEFAULT_KEY_NAME_ACCOUNTING_PERIOD,
    keyNameDiscountType: DEFAULT_KEY_NAME_DISCOUNT_TYPE_API_REF,
    keyNameDiscountPercentage: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
    keyNameDiscountNominal: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
    keyNameDiscountAccountID: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_API_REF,
    keyNameDiscountAccountIDcontainer: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_CONTAINER,
    keyNameDownPaymentAccountID: DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_API_REF,
    keyNameDownPaymentAccountIDcontainer: DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_CONTAINER,
    keyNameDownPaymentValue: DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE,
    keyNameExpenseAccountID: DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_API_REF,
    keyNameExpenseAccountIDcontainer: DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_CONTAINER,
    keyNameExpenseValue: DEFAULT_KEY_NAME_EXPENSE_VALUE,
    keyNameMaximumDownPaymentValue: defaultKeyNameMaximumDownPaymentValue,
    minimumLengthChildrenTransaction: 1,
    uniqueKeyNameChildrenTransaction: DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
    statusFreeUpdateInputTransaction: [DEFAULT_STATUS_PURCHASE_QUOTATION_REQUEST_OPEN],
    errorMessagePrimaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.primary',
    errorMessageSecondaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.secondary',
    errorMessagePrimaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.primary',
    errorMessageSecondaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.secondary',
    errorMessagePrimaryInvalidForm: 'base.message.error.invalid-form.primary',
    errorMessageSecondaryInvalidForm: 'base.message.error.invalid-form.secondary',
    relationalKeyDiscountTypeNvalue: {
      [DEFAULT_DISCOUNT_PERCENTAGE_TYPE]: {
        keyInputName: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
        maximumValue: 100,
        representationDataType: DEFAULT_REPRESENTATION_DATA_NUMBER_TYPE,
      },
      [DEFAULT_DISCOUNT_NOMINAL_TYPE]: {
        keyInputName: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
        keyNameMaximumValue: DEFAULT_KEY_NAME_AMOUNT_CONST,
        representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
      },
    },
    isAdditionalCostShow: true,
    additionalCostFormSchema: additionalCostPurchaseForm,
    isMinutesHandoverShow: false,
    isFormStatusShow: false,
    actionAddTransaction: addQuotationRequestV2,
    actionUpdateTransaction: updateQuotationRequest,
    statusRequestAddPending: STATUS_REQUEST_ADD_QUOTATION_REQUEST_PENDING,
    statusRequestAddSuccess: STATUS_REQUEST_ADD_QUOTATION_REQUEST_SUCCESS,
    statusRequestUpdatePending: STATUS_REQUEST_UPDATE_QUOTATION_REQUEST_PENDING,
    statusRequestUpdateSuccess: STATUS_REQUEST_UPDATE_QUOTATION_REQUEST_SUCCESS,
    generateCreateDataNvalidationTransaction: ({ amount, companyUserData }) =>
      generateDataNValidationAddQuotationRequestV2({
        amount,
        companyUserData,
        customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
        customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
      }),
    generateCreateDataNvalidationChildrenTransaction: ({ amount }) =>
      generateDataNValidationAddProductQR(
        amount,
        DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
        DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
      ),
    generalizeDataAddTransaction: generalizeDataAddQuotationRequest,
    generalizeDataUpdateTransaction: generalizeDataUpdateQuotationRequestV2,
    primaryAddUpdateDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          placeholder: 'placeholder.input-auto',
          inputName: 'transaction_number',
          storeName: ROOT_STORE_NAME_REFERENCE_NUMBER,
          childrenStoreName: SLICE_NAME_REFERENCE_NUMBER_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_REFERENCE_NUMBER,
          keyInputContainerName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: referenceNumberList,
            additionalParamsForAction: {},
            removeDataAction: clearReferenceNumber,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['code', 'format'],
                ['code'],
                formatHelp.formatTransactionNumber,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'code',
          },
          inputProps: {
            maxLength: DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE,
          },
          isDisabled: ({
            actionMenu,
            parentStateList = {},
            transactionData,
            statusFreeUpdateInputTransaction = [],
          }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE ||
              parentStateList.statusRequest ===
                STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_PENDING ||
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                !statusFreeUpdateInputTransaction.includes(status))
            );
          },
          isUseTextInput: true,
          isRequired: true,
        },
        {
          label: 'label.transaction-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: 'transaction_date',
          keyInputName: 'transaction_date',
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
          isAddOneDayForMinDate: false,
          isRequired: true,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
        {
          label: 'label.expired-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: 'expired_date',
          keyInputName: 'expired_date',
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: 'transaction_date',
          isAddOneDayForMinDate: false,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
      ],
      [
        {
          label: 'label.contact-supplier',
          placeholder: 'label.contact-supplier',
          inputName: 'contact_name',
          storeName: NAME_STORE.CONTACT,
          childrenStoreName: SLICE_NAME_CONTACT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_SUPPLIER_CONTAINER_ID_API_REF,
          statusRequestListPending: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: contactList,
            additionalParamsForAction: {
              type: ['is_supplier'],
            },
            removeDataAction: clearContactData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              type: ['is_supplier'],
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['contact_code', 'contact_name'],
                ['contact_name'],
                formatHelp.contactCodeWithName,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'contact_name',
          },
          isUseAdditionalButtonNotFound: true,
          additionalButtonNotFoundProps: {
            textNotFound: 'button.plus-add-new-contact',
            keyNameIsNew: DEFAULT_KEY_NAME_IS_NEW_CONTACT_DATA,
            keynameSwitchDialog: 'contact-data',
            newPageStatus: DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA,
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
        {
          label: 'label.supplier-address',
          placeholder: 'label.supplier-address',
          inputName: 'contact_address',
          keyInputName: 'supplier_address',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          gridProps: {
            xs: 8,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
      ],
    ],
    secondaryAddUpdateDataSchema: [
      [
        {
          label: 'label.total',
          name: 'amount',
          inputName: 'amount',
          keyInputName: 'amount',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
          isTypography: true,
        },
      ],
      [
        // tagAutocompleteInputProps,
        {
          usageInputLabel: false,
          keyInputName: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DROPZONE,
          additionalDropzoneProps: {
            generalizeRequestAction: (attachmentFile, relationValueToOtherAttachment) => {
              return generalizeAttachmentRequest({
                attachmentFile,
                relationValueToOtherAttachment,
                featureDocument: DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_TYPE,
              });
            },
          },
          gridContainerProps: {
            width: 'calc(100% - 2px)',
          },
        },
        {
          label: 'label.memo',
          placeholder: 'label.memo',
          inputName: 'memo',
          keyInputName: 'description',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          additionalOutlinedInput: {
            rows: 3,
            multiline: true,
            className: 'none-padding',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    inputValueChanges: {
      [DEFAULT_KEY_NAME_REFERENCE_NUMBER]: {
        generateChanges: ({ newValue }) => {
          return {
            resultChanges: {
              [DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER]: {
                code: newValue,
              },
              [DEFAULT_KEY_NAME_REFERENCE_NUMBER]: newValue,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_PRICE_API_REF]: {
        generateChanges: ({
          newValue,
          transactionData = [],
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const amountTransactionData = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const { quantity, amount: amountPast } = selectedChildrenTransactionData;

          const amountChildrenTransactionWithoutPast = amountTransactionData - amountPast;
          const currentAmountSelectedChildrenTransaction = Number(quantity) * Number(newValue);

          const { discount_nominal, discount_percentage } = formulaCountDiscountCost({
            allAmountTransaction:
              amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction,
            transactionData,
          });

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction:
              amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: currentAmountSelectedChildrenTransaction,
            },
            transactionDataChanges: {
              amount: Number(newAmountTransaction),
              amount_const: Number(newAmountTransaction),
              discount_nominal: Number(discount_nominal) || '',
              discount_percentage: Number(discount_percentage) || '',
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_QUANTITY_API_REF]: {
        generateChanges: ({
          newValue,
          transactionData = [],
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const amountTransactionData = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const {
            price,
            amount: amountPast,
            quantity_comparison,
          } = selectedChildrenTransactionData;

          const amountChildrenTransactionWithoutPast = amountTransactionData - amountPast;
          const currentAmountSelectedChildrenTransaction = Number(price) * Number(newValue);

          const { discount_nominal, discount_percentage } = formulaCountDiscountCost({
            allAmountTransaction:
              amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction,
            transactionData,
          });

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction:
              amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: currentAmountSelectedChildrenTransaction,
              quantity_origin: Number(quantity_comparison) * Number(newValue),
            },
            transactionDataChanges: {
              amount: Number(newAmountTransaction),
              amount_const: Number(newAmountTransaction),
              discount_nominal: Number(discount_nominal) || '',
              discount_percentage: Number(discount_percentage) || '',
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          let newDiscountNominal = Number(amountChildrenTransaction) * (Number(newValue) / 100);

          newDiscountNominal = Number(newDiscountNominal).toFixed(2);

          const { expense_value, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: newDiscountNominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
              [DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF]: Number(newDiscountNominal),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: newValue,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          const newDiscountPercentage = amountChildrenTransaction
            ? Number((Number(newValue) / Number(amountChildrenTransaction)) * 100).toFixed(2)
            : 0;

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
              [DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF]: Number(newDiscountPercentage),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_EXPENSE_VALUE]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { discount_nominal, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: newValue,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { discount_nominal, expense_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: newValue,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
            },
          };
        },
      },
    },
    autocompleteValueChanges: {
      [DEFAULT_KEY_NAME_PRODUCT_ID_API_REF]: {
        isCanChangeAllState: true,
        generateChanges: ({
          newValue,
          transactionData = [],
          transactionValidation,
          childrenTransactionData = [],
          childrenTransactionValidation,
          idChildrenTransaction = '',
        }) => {
          const {
            product_description,
            purchase_price,
            unit,
            product_unit,
            // quantity_written,
          } = newValue || {};

          const quantity = !isEmpty(newValue) ? 1 : 0;

          // amount current selected transaction data
          const amount =
            Number(purchase_price) && Number(quantity)
              ? Number(purchase_price) * Number(quantity)
              : DEFAULT_CALCULATION_NUMBER;

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const sumAmountsChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const {
            discount_percentage,
            discount_nominal,
            expense_value,
            down_payment_value,
            discount_type,
          } = transactionData[0];

          const { amount: amountChildrenTransaction } = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          let newAmountTransactionData = !isEmpty(newValue)
            ? Number(sumAmountsChildrenTransaction) -
              Number(amountChildrenTransaction) +
              Number(amount)
            : Number(sumAmountsChildrenTransaction) - Number(amountChildrenTransaction);

          let discountNominal =
            discount_type === DEFAULT_DISCOUNT_NOMINAL_TYPE
              ? discount_nominal
              : (Number(discount_percentage) / Number(100)) * Number(newAmountTransactionData);

          discountNominal = Number(discountNominal).toFixed(2);

          if (Number(discountNominal) > Number(newAmountTransactionData))
            discountNominal = Number(newAmountTransactionData);

          let discountPercentage =
            discount_type === DEFAULT_DISCOUNT_PERCENTAGE_TYPE
              ? discount_percentage
              : newAmountTransactionData
              ? (Number(discountNominal) / Number(newAmountTransactionData)) * 100
              : 0;

          discountPercentage = Number(discountPercentage).toFixed(2);

          const expenseValue = Number(newAmountTransactionData) <= 0 ? '' : expense_value;

          const downPaymentValue = Number(newAmountTransactionData) <= 0 ? '' : down_payment_value;

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: newAmountTransactionData,
            discountNominal,
            expenseValue,
            downPaymentValue,
          });

          const childrenTransactionDataChanges = {
            price: Number(purchase_price) || '',
            product_description: product_description || '',
            quantity: Number(quantity) || '',
            quantity_origin: Number(quantity) || '',
            quantity_comparison: 1,
            unit_origin: !hasImplementedUnitProductUnit ? product_unit : unit || '',
            unit_destination: !hasImplementedUnitProductUnit ? product_unit : unit || '',
            amount: Number(amount) || '',
          };

          const transactionDataChanges = {
            amount: Number(newAmountTransaction),
            discount_nominal: Number(discountNominal) || '',
            discount_percentage: Number(discountPercentage) || '',
            expense_value: expenseValue,
            down_payment_value: downPaymentValue,
            amount_const: Number(newAmountTransactionData),
          };

          transactionData = [
            {
              ...transactionData[0],
              ...transactionDataChanges,
            },
          ];

          childrenTransactionData = arrHelp.combineDataObjInArray(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
            childrenTransactionDataChanges,
          );

          if (!isEmpty(childrenTransactionValidation)) {
            const isErrorValidation = !Number(amount);
            const selectedChildrenTransactionValidation = arrHelp.filterObjDataWithID(
              childrenTransactionValidation,
              'id',
              idChildrenTransaction,
            );

            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              selectedChildrenTransactionValidation,
              [DEFAULT_KEY_NAME_QUANTITY_API_REF, DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF],
              true,
              isErrorValidation,
            );

            childrenTransactionValidation = arrHelp.combineDataObjInArray(
              childrenTransactionValidation,
              'id',
              idChildrenTransaction,
              newValidation,
            );
          }

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
      [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF]: {
        isHitEndpoint: false,
        isCanChangeAllState: false,
        generateChanges: ({ newValue }) => {
          const supplierAddress = newValue
            ? newValue[DEFAULT_KEY_NAME_BILLING_ADDRESS_API_REF] || ''
            : '';

          return {
            resultChanges: {
              supplier_address: supplierAddress,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF]: {
        ...getChangesAutocompleteForUnitProductTransactionPropertiesPrimary({}),
      },
    },
    columnsTableHead: (t) => columnsTableHeaderAddUpdateSalesQuotationV2(t),
    gridHeaderChildrenTransaction: gridHeaderProductQuotationRequest,
    gridCollapseChildrenTransaction: gridCollapseProductQuotationRequest,
    dependingKeyNameTransactionValidation: ['transaction_date', 'expired_date'], // validation form on transaction
    anotherKeyNameTransactionDataDepends: [
      DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
      'transaction_date',
    ], // validation form on data transaction
    keyNameBypassChildrenTransactionValidation: [
      'product_id',
      'quantity',
      DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
    ],
    checkingIsEmptyChildrenTransactionKeyNameBypass: [
      [null, ''],
      DEFAULT_EMPTY_CALCULATIONS_NUMBER,
      [null, ''],
    ],
    keyNameCheckingValidationInputBypass: [
      'quantity',
      'price',
      DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
    ],
    keyNameCheckingStatusChildrenTransaction: ['product_id'],
    menuActionUpdateProps: {
      [DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_QUOTATION_ID]: {
        getAction: (paramsRouter) =>
          getDetailQuotationRequest(
            paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_QUOTATION_ID],
          ),
        // dependencies action when after getting primary action (above action)
        onDependenciesActions: [defaultOnDependenciesListDetailsAttachmentAction],
        parentStateName: NAME_STORE.PURCHASE,
        childrenStateName: SLICE_NAME_QUOTATION_REQUEST_DETAIL,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNValidationUpdateQuotationRequestV2({
            quotationRequestDataSelected: resultGetAction,
            ...params,
            customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: (resultGetAction) => {
          const { updateProductData, updateProductValidation } =
            generateDataNValidationUpdateProductQuotationRequestV2(resultGetAction);

          return {
            [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: updateProductData,
            [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION]: updateProductValidation,
          };
        },
      },
    },
    additionalSettingDefaultInputActionMenuAdd: [
      clearDataProductSupplierAction,
      ...defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_PURCHASE_TYPE],
    ],
    additionalSettingDefaultInputActionMenuUpdate: [
      clearDataProductSupplierAction,
      ...defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_PURCHASE_TYPE],
    ],
    generateDataForSettingDefaultInputActionMenuAdd: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    generateDataForSettingDefaultInputActionMenuUpdate: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    additionalPropsSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        generateDataNValidationProfile: generateDataNValidationAddProfile,
        isDisabledContactType: [DEFAULT_CONTACT_IS_SUPPLIER_TYPE],
      },
    },
    additionalActionSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        parentStateName: NAME_STORE.CONTACT,
        childrenStateName: SLICE_NAME_TEMP_ADD_CONTACT_DATA,
        generalizeForState: ({
          resultSwitchDialog = {},
          keyStateContainer,
          keyValidationContainer,
          selectedIndex = 0,
          ...stateDataNvalidation
        }) => {
          let {
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          } = stateDataNvalidation;

          if (!isEmpty(resultSwitchDialog)) {
            const { id } = selectedStateContainer[selectedIndex];

            selectedStateContainer = arrHelp.combineDataObjInArray(
              selectedStateContainer,
              'id',
              id,
              {
                [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF]:
                  resultSwitchDialog[DEFAULT_KEY_NAME_CONTACT_ID_API_REF],
                [DEFAULT_KEY_NAME_SUPPLIER_CONTAINER_ID_API_REF]: resultSwitchDialog,
              },
            );

            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              selectedValidationContainer[0],
              [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF],
              true,
              false,
            );

            selectedValidationContainer = arrHelp.combineDataObjInArray(
              selectedValidationContainer,
              'id',
              id,
              newValidation,
            );
          }

          return {
            ...stateDataNvalidation,
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          };
        },
      },
    },
    parentMenuURL: REACT_APP_PURCHASE_QUOTATION_REQUEST_URL,
    handleDeleteRow: {
      childrenTransactionData: ({
        transactionData,
        childrenTransactionData,
        childrenTransactionValidation,
        childrenTransactionID = '',
      }) => {
        const {
          childrenTransactionData: currChildrenTransactionData,
          childrenTransactionValidation: currChildrenTransactionValidation,
        } = filteringChildrenTransactionData({
          childrenTransactionData,
          childrenTransactionValidation,
          childrenTransactionDataID: childrenTransactionID,
        });

        return {
          childrenTransactionData: currChildrenTransactionData,
          childrenTransactionValidation: currChildrenTransactionValidation,
          transactionData: [
            {
              ...transactionData[0],
              ...formulaHelpers.calculateDiscountAndTotalOfChildrenData({
                parentData: transactionData[0],
                childrenData: currChildrenTransactionData,
              }),
            },
          ],
        };
      },
    },
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_ORDER_TYPE]: {
    defaultTransctionNumberCode: DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_ORDER,
    allowKeyInputTransactionData: allowKeysPurchaseOrderData,
    allowKeyInputProductTransctionData: allowKeysProductPurchaseOrderData,
    allowKeyInputChildrenTransactionData: allowKeysProductPurchaseOrderData,
    keyNameReferenceNumber: DEFAULT_KEY_NAME_REFERENCE_NUMBER,
    keyNameReferenceNumberContainer: DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER,
    keyNameTransactionDate: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
    keyNameAccountingPeriod: DEFAULT_KEY_NAME_ACCOUNTING_PERIOD,
    keyNameDiscountType: DEFAULT_KEY_NAME_DISCOUNT_TYPE_API_REF,
    keyNameDiscountPercentage: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
    keyNameDiscountNominal: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
    keyNameDiscountAccountID: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_API_REF,
    keyNameDiscountAccountIDcontainer: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_CONTAINER,
    keyNameDownPaymentAccountID: DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_API_REF,
    keyNameDownPaymentAccountIDcontainer: DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_CONTAINER,
    keyNameDownPaymentValue: DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE,
    keyNameExpenseAccountID: DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_API_REF,
    keyNameExpenseAccountIDcontainer: DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_CONTAINER,
    keyNameExpenseValue: DEFAULT_KEY_NAME_EXPENSE_VALUE,
    keyNameMaximumDownPaymentValue: defaultKeyNameMaximumDownPaymentValue,
    minimumLengthChildrenTransaction: 1,
    uniqueKeyNameChildrenTransaction: DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
    statusFreeUpdateInputTransaction: [DEFAULT_STATUS_PURCHASE_ORDER_OPEN],
    errorMessagePrimaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.primary',
    errorMessageSecondaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.secondary',
    errorMessagePrimaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.primary',
    errorMessageSecondaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.secondary',
    errorMessagePrimaryInvalidForm: 'base.message.error.invalid-form.primary',
    errorMessageSecondaryInvalidForm: 'base.message.error.invalid-form.secondary',
    relationalKeyDiscountTypeNvalue: {
      [DEFAULT_DISCOUNT_PERCENTAGE_TYPE]: {
        keyInputName: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
        maximumValue: 100,
        representationDataType: DEFAULT_REPRESENTATION_DATA_NUMBER_TYPE,
      },
      [DEFAULT_DISCOUNT_NOMINAL_TYPE]: {
        keyInputName: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
        keyNameMaximumValue: DEFAULT_KEY_NAME_AMOUNT_CONST,
        representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
      },
    },
    isAdditionalCostShow: true,
    additionalCostFormSchema: additionalCostPurchaseForm,
    isMinutesHandoverShow: false,
    isFormStatusShow: false,
    actionAddTransaction: addPurchaseOrderV2,
    actionUpdateTransaction: updatePurchaseOrder,
    statusRequestAddPending: STATUS_REQUEST_ADD_PURCHASE_ORDER_PENDING,
    statusRequestAddSuccess: STATUS_REQUEST_ADD_PURCHASE_ORDER_SUCCESS,
    statusRequestUpdatePending: STATUS_REQUEST_UPDATE_PURCHASE_ORDER_PENDING,
    statusRequestUpdateSuccess: STATUS_REQUEST_UPDATE_PURCHASE_ORDER_SUCCESS,
    generateCreateDataNvalidationTransaction: ({ amount, companyUserData }) =>
      generateDataNValidationAddPurchaseOrderV2({
        amount,
        companyUserData,
        customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
        customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
      }),
    generateCreateDataNvalidationChildrenTransaction: ({ amount }) =>
      generateDataNValidationAddProductPO(
        amount,
        DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
        DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
      ),
    generalizeDataAddTransaction: generalizeDataAddPurchaseOrder,
    generalizeDataUpdateTransaction: generalizeDataUpdatePurchaseOrder,
    primaryAddUpdateDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          placeholder: 'placeholder.input-auto',
          inputName: 'transaction_number',
          storeName: ROOT_STORE_NAME_REFERENCE_NUMBER,
          childrenStoreName: SLICE_NAME_REFERENCE_NUMBER_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_REFERENCE_NUMBER,
          keyInputContainerName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultGridPropsSecondary,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: referenceNumberList,
            additionalParamsForAction: {},
            removeDataAction: clearReferenceNumber,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['code', 'format'],
                ['value'],
                formatHelp.formatTransactionNumber,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'code',
          },
          inputProps: {
            maxLength: DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE,
          },
          isDisabled: ({
            actionMenu,
            parentStateList = {},
            transactionData,
            statusFreeUpdateInputTransaction = [],
          }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE ||
              parentStateList.statusRequest ===
                STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_PENDING ||
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                !statusFreeUpdateInputTransaction.includes(status))
            );
          },
          isUseTextInput: true,
          isRequired: true,
        },
        {
          label: 'label.quotation-number',
          placeholder: 'label.quotation-number',
          inputName: 'quotation_number',
          keyInputName: 'quotation_request_id',
          gridProps: defaultGridPropsSecondary,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          storeName: NAME_STORE.PURCHASE,
          childrenStoreName: SLICE_NAME_QUOTATION_REQUEST_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_QUOTATION_REQUEST_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_QUOTATION_REQUEST_ID_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_QUOTATION_REQUEST_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          isRequired: true,
          // getAdditionalParamsForAction: ({ transactionData = [] }) => {
          //     if (isEmpty(transactionData)) return {}

          //     const { supplier_id_container } = transactionData[0]

          //     return {
          //         supplier_name: !isEmpty(supplier_id_container) ?
          //             supplier_id_container.contact_name: '',
          //     }
          // },
          autocompleteProps: {
            fetchAction: getListQuotationRequest,
            additionalParamsForAction: {
              status: [DEFAULT_STATUS_SALES_QUOTATION_OPEN],
            },
            removeDataAction: clearQuotationRequestData,
            additionalParamsForScrollAction: {
              status: [DEFAULT_STATUS_SALES_QUOTATION_OPEN],
              isRewriteAll: false,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getOptionLabel(options, 'transaction_no'),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'transaction_no',
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                !statusFreeUpdateInputTransaction.includes(status)) ||
              actionMenu === DEFAULT_ACTION_MENU_UPDATE
            );
          },
          inputProps: {
            maxLength: DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE,
          },
          isUseTextInput: false,
        },
        {
          label: 'label.transaction-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: 'transaction_date',
          keyInputName: 'transaction_date',
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultGridPropsSecondary,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
          isAddOneDayForMinDate: false,
          isRequired: true,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
        {
          label: 'label.expired-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: 'expired_date',
          keyInputName: 'expired_date',
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultGridPropsSecondary,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: 'transaction_date',
          isAddOneDayForMinDate: true,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
        {
          label: 'label.warehouse',
          placeholder: 'label.warehouse',
          inputName: 'warehouse_name',
          keyInputName: 'warehouse_id',
          gridProps: defaultGridPropsSecondary,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          storeName: NAME_STORE.WAREHOUSE,
          childrenStoreName: SLICE_NAME_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_WAREHOUSE_ID_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          autocompleteProps: {
            fetchAction: getWarehouseListWithDynamicStore,
            additionalParamsForAction: DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE,
            removeDataAction: clearWarehouseDataSlice,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            additionalParamsForRemoveDataAction: DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE,
            getOptionLabel: (options) =>
              autocompleteHelper.getOptionLabel(options, 'warehouse_name'),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'warehouse_name',
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
          isUseTextInput: false,
        },
      ],
      [
        {
          label: 'label.contact-supplier',
          placeholder: 'label.contact-supplier',
          inputName: 'contact_name',
          storeName: NAME_STORE.CONTACT,
          childrenStoreName: SLICE_NAME_CONTACT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_SUPPLIER_CONTAINER_ID_API_REF,
          statusRequestListPending: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: contactList,
            additionalParamsForAction: {
              type: ['is_supplier'],
            },
            removeDataAction: clearContactData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              type: ['is_supplier'],
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['contact_code', 'contact_name'],
                ['contact_name'],
                formatHelp.contactCodeWithName,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'contact_name',
          },
          isUseAdditionalButtonNotFound: true,
          additionalButtonNotFoundProps: {
            textNotFound: 'button.plus-add-new-contact',
            keyNameIsNew: DEFAULT_KEY_NAME_IS_NEW_CONTACT_DATA,
            keynameSwitchDialog: 'contact-data',
            newPageStatus: DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA,
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
        {
          label: 'label.supplier-address',
          placeholder: 'label.supplier-address',
          inputName: 'contact_address',
          keyInputName: 'supplier_address',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          gridProps: {
            xs: 8,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
      ],
    ],
    secondaryAddUpdateDataSchema: [
      [
        {
          label: 'label.total',
          name: 'amount',
          inputName: 'amount',
          keyInputName: 'amount',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
          isTypography: true,
        },
      ],
      [
        // tagAutocompleteInputProps,
        {
          usageInputLabel: false,
          keyInputName: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DROPZONE,
          additionalDropzoneProps: {
            generalizeRequestAction: (attachmentFile, relationValueToOtherAttachment) => {
              return generalizeAttachmentRequest({
                attachmentFile,
                relationValueToOtherAttachment,
                featureDocument: DEFAULT_TRANSACTION_PURCHASE_PURCHASE_ORDER_TYPE,
              });
            },
          },
          gridContainerProps: {
            width: 'calc(100% - 2px)',
          },
        },
        {
          label: 'label.memo',
          placeholder: 'label.memo',
          inputName: 'memo',
          keyInputName: 'description',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          additionalOutlinedInput: {
            rows: 3,
            multiline: true,
            className: 'none-padding',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    inputValueChanges: {
      [DEFAULT_KEY_NAME_REFERENCE_NUMBER]: {
        generateChanges: ({ newValue }) => {
          return {
            resultChanges: {
              [DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER]: {
                value: newValue,
              },
              [DEFAULT_KEY_NAME_REFERENCE_NUMBER]: newValue,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_PRICE_API_REF]: {
        generateChanges: ({
          newValue,
          transactionData = [],
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const amountTransactionData = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const { quantity, amount: amountPast } = selectedChildrenTransactionData;

          const amountChildrenTransactionWithoutPast = amountTransactionData - amountPast;
          const currentAmountSelectedChildrenTransaction = Number(quantity) * Number(newValue);

          const { discount_nominal, discount_percentage } = formulaCountDiscountCost({
            allAmountTransaction:
              amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction,
            transactionData,
          });

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction:
              amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: currentAmountSelectedChildrenTransaction,
            },
            transactionDataChanges: {
              amount: Number(newAmountTransaction),
              amount_const: Number(newAmountTransaction),
              discount_nominal: Number(discount_nominal) || '',
              discount_percentage: Number(discount_percentage) || '',
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_QUANTITY_API_REF]: {
        generateChanges: ({
          newValue,
          transactionData = [],
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const amountTransactionData = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const {
            price,
            amount: amountPast,
            quantity_comparison,
          } = selectedChildrenTransactionData;

          const amountChildrenTransactionWithoutPast = amountTransactionData - amountPast;
          const currentAmountSelectedChildrenTransaction = Number(price) * Number(newValue);

          const { discount_nominal, discount_percentage } = formulaCountDiscountCost({
            allAmountTransaction:
              amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction,
            transactionData,
          });

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction:
              amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: currentAmountSelectedChildrenTransaction,
              quantity_origin: Number(quantity_comparison) * newValue,
            },
            transactionDataChanges: {
              amount: Number(newAmountTransaction),
              amount_const: Number(newAmountTransaction),
              discount_nominal: Number(discount_nominal) || '',
              discount_percentage: Number(discount_percentage) || '',
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          let newDiscountNominal = Number(amountChildrenTransaction) * (Number(newValue) / 100);

          newDiscountNominal = Number(newDiscountNominal).toFixed(2);

          const { expense_value, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: newDiscountNominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
              [DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF]: Number(newDiscountNominal),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: newValue,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          const newDiscountPercentage = amountChildrenTransaction
            ? Number((Number(newValue) / Number(amountChildrenTransaction)) * 100).toFixed(2)
            : 0;

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
              [DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF]: Number(newDiscountPercentage),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_EXPENSE_VALUE]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { discount_nominal, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: newValue,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { discount_nominal, expense_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: newValue,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
            },
          };
        },
      },
    },
    autocompleteValueChanges: {
      [DEFAULT_KEY_NAME_PRODUCT_ID_API_REF]: {
        isCanChangeAllState: true,
        generateChanges: ({
          newValue,
          transactionData = [],
          transactionValidation,
          childrenTransactionData = [],
          childrenTransactionValidation,
          idChildrenTransaction = '',
        }) => {
          const { product_description, purchase_price, unit, product_unit } = newValue || {};

          const quantity = !isEmpty(newValue) ? 1 : 0;

          // amount current selected transaction data
          const amount =
            Number(purchase_price) && Number(quantity)
              ? Number(purchase_price) * Number(quantity)
              : DEFAULT_CALCULATION_NUMBER;

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const sumAmountsChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const {
            discount_type,
            discount_percentage,
            discount_nominal,
            expense_value,
            down_payment_value,
          } = transactionData[0];

          const { amount: amountChildrenTransaction } = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          let newAmountTransactionData = !isEmpty(newValue)
            ? Number(sumAmountsChildrenTransaction) -
              Number(amountChildrenTransaction) +
              Number(amount)
            : Number(sumAmountsChildrenTransaction) - Number(amountChildrenTransaction);

          let discountNominal =
            discount_type === DEFAULT_DISCOUNT_NOMINAL_TYPE
              ? discount_nominal
              : (Number(discount_percentage) / Number(100)) * Number(newAmountTransactionData);

          discountNominal = Number(discountNominal).toFixed(2);

          if (Number(discountNominal) > Number(newAmountTransactionData))
            discountNominal = Number(newAmountTransactionData);

          let discountPercentage =
            discount_type === DEFAULT_DISCOUNT_PERCENTAGE_TYPE
              ? discount_percentage
              : newAmountTransactionData
              ? (Number(discountNominal) / Number(newAmountTransactionData)) * 100
              : 0;

          discountPercentage = Number(discountPercentage).toFixed(2);

          const expenseValue = Number(newAmountTransactionData) <= 0 ? '' : expense_value;

          const downPaymentValue = Number(newAmountTransactionData) <= 0 ? '' : down_payment_value;

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: newAmountTransactionData,
            discountNominal,
            expenseValue,
            downPaymentValue,
          });

          const childrenTransactionDataChanges = {
            price: Number(purchase_price) || '',
            product_description: product_description || '',
            quantity: Number(quantity) || '',
            quantity_origin: Number(quantity) || '',
            quantity_comparison: 1,
            unit_destination: !hasImplementedUnitProductUnit ? product_unit || '' : unit || '',
            unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit || '',
            amount: Number(amount) || '',
          };

          const transactionDataChanges = {
            amount: Number(newAmountTransaction),
            discount_nominal: Number(discountNominal) || '',
            discount_percentage: Number(discountPercentage) || '',
            expense_value: expenseValue,
            down_payment_value: downPaymentValue,
            amount_const: Number(newAmountTransactionData),
          };

          transactionData = [
            {
              ...transactionData[0],
              ...transactionDataChanges,
            },
          ];

          childrenTransactionData = arrHelp.combineDataObjInArray(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
            childrenTransactionDataChanges,
          );

          if (!isEmpty(childrenTransactionValidation)) {
            const isErrorValidation = !Number(amount);
            const selectedChildrenTransactionValidation = arrHelp.filterObjDataWithID(
              childrenTransactionValidation,
              'id',
              idChildrenTransaction,
            );

            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              selectedChildrenTransactionValidation,
              ['quantity', 'unit_destination'],
              true,
              isErrorValidation,
            );

            childrenTransactionValidation = arrHelp.combineDataObjInArray(
              childrenTransactionValidation,
              'id',
              idChildrenTransaction,
              newValidation,
            );
          }

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
      [DEFAULT_KEY_NAME_QUOTATION_REQUEST_ID_API_REF]: {
        isHitEndpoint: true,
        action: getDetailQuotationRequest,
        actionRemove: () =>
          clearDataPurchaseSlice(DEFAULT_PAYLOAD_STORAGE_QUOTATION_REQUEST_DETAIL),
        storeName: NAME_STORE.PURCHASE,
        childrenSliceName: SLICE_NAME_QUOTATION_REQUEST_DETAIL,
        generateParamsAction: ({ newAutocompleteChanges = {} }) => {
          return newAutocompleteChanges[DEFAULT_KEY_NAME_QUOTATION_REQUEST_ID_API_REF] || '';
        },
        generateChanges: ({ resultGetAction, companyUserData, currentTransactionData }) => {
          const { transactionData, transactionValidation } =
            generateDataNValidationInitialPurchaseOrderFromQuotationRequestV2({
              quotationRequestDataSelected: resultGetAction,
              companyUserData,
              currentPurchaseOrderData: currentTransactionData,
              customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
              customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
            });

          const { childrenTransactionData, childrenTransactionValidation } =
            generateDataNValidationInitialProductPOFromQuotationRequestV2({
              quotationRequestDataSelected: resultGetAction,
              customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
              customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
            });

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
      [DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF]: {
        isCanChangeAllState: true,
        generateChanges: ({ newValue, transactionData, transactionValidation }) => {
          if (!isEmpty(transactionValidation)) {
            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              transactionValidation[0],
              [DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF],
              true,
              isEmpty(newValue),
            );

            transactionValidation = [newValidation];
          }

          const { childrenTransactionData, childrenTransactionValidation } =
            generateDataNValidationAddProductPOV2({
              amount: 2,
              customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
              customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
            });

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
      [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF]: {
        isHitEndpoint: false,
        isCanChangeAllState: false,
        generateChanges: ({ newValue }) => {
          const supplierAddress = newValue
            ? newValue[DEFAULT_KEY_NAME_BILLING_ADDRESS_API_REF] || ''
            : '';

          return {
            resultChanges: {
              supplier_address: supplierAddress,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF]: {
        ...getChangesAutocompleteForUnitProductTransactionPropertiesPrimary({}),
      },
    },
    columnsTableHead: (t) => columnsTableHeaderAddUpdateSalesQuotationV2(t),
    gridHeaderChildrenTransaction: gridHeaderProductPurchaseOrder,
    gridCollapseChildrenTransaction: gridCollapseProductPurchaseOrder,
    dependingKeyNameTransactionValidation: ['transaction_date', 'expired_date'], // validation form on transaction
    anotherKeyNameTransactionDataDepends: [
      DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
      'transaction_date',
    ], // validation form on data transaction
    keyNameBypassChildrenTransactionValidation: [
      'product_id',
      'quantity',
      DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
    ],
    checkingIsEmptyChildrenTransactionKeyNameBypass: [
      [null, ''],
      DEFAULT_EMPTY_CALCULATIONS_NUMBER,
      [null, ''],
    ],
    keyNameCheckingValidationInputBypass: [
      'quantity',
      'price',
      DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
    ],
    keyNameCheckingStatusChildrenTransaction: ['product_id'],
    menuActionAddProps: {
      [DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_QUOTATION_REQUEST_DATA]: {
        getAction: (searchParams) =>
          getDetailQuotationRequest(searchParams[DEFAULT_KEY_NAME_QUOTATION_REQUEST_ID_API_REF]),
        parentStateName: NAME_STORE.PURCHASE,
        childrenStateName: SLICE_NAME_QUOTATION_REQUEST_DETAIL,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNValidationInitialPurchaseOrderFromQuotationRequestV2({
            quotationRequestDataSelected: resultGetAction,
            ...params,
            customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: ({ resultGetAction }) =>
          generateDataNValidationInitialProductPOFromQuotationRequestV2({
            quotationRequestDataSelected: resultGetAction,
            customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
          }),
      },
    },
    menuActionUpdateProps: {
      [DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_ORDER_ID]: {
        getAction: (paramsRouter) =>
          getDetailPurchaseOrder({
            purchase_order_id: paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_ORDER_ID],
          }),
        // dependencies action when after getting primary action (above action)
        onDependenciesActions: [defaultOnDependenciesListDetailsAttachmentAction],
        parentStateName: NAME_STORE.PURCHASE,
        childrenStateName: SLICE_NAME_DETAIL_PURCHASE_ORDER,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNValidationUpdatePurchasePurchaseOrderV2({
            purchaseOrderDataSelected: resultGetAction,
            ...params,
            customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: (resultGetAction) => {
          const { productData, productValidation } =
            generateDataNValidationUpdateProductPO(resultGetAction);

          return {
            [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: productData,
            [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION]: productValidation,
          };
        },
      },
    },
    additionalSettingDefaultInputActionMenuAdd: [
      clearDataProductSupplierAction,
      ...defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_PURCHASE_TYPE],
      ...defaultAdditionalSettingWarehouseConfig[DEFAULT_TRANSACTION_PURCHASE_TYPE],
    ],
    additionalSettingDefaultInputActionMenuUpdate: [
      clearDataProductSupplierAction,
      ...defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_PURCHASE_TYPE],
      ...defaultAdditionalSettingWarehouseConfig[DEFAULT_TRANSACTION_PURCHASE_TYPE],
    ],
    generateDataForSettingDefaultInputActionMenuAdd: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    generateDataForSettingDefaultInputActionMenuUpdate: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    additionalPropsSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        generateDataNValidationProfile: generateDataNValidationAddProfile,
        isDisabledContactType: [DEFAULT_CONTACT_IS_SUPPLIER_TYPE],
      },
    },
    additionalActionSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        parentStateName: NAME_STORE.CONTACT,
        childrenStateName: SLICE_NAME_TEMP_ADD_CONTACT_DATA,
        generalizeForState: ({
          resultSwitchDialog = {},
          keyStateContainer,
          keyValidationContainer,
          selectedIndex = 0,
          ...stateDataNvalidation
        }) => {
          let {
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          } = stateDataNvalidation;

          if (!isEmpty(resultSwitchDialog)) {
            const { id } = selectedStateContainer[selectedIndex];

            selectedStateContainer = arrHelp.combineDataObjInArray(
              selectedStateContainer,
              'id',
              id,
              {
                [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF]:
                  resultSwitchDialog[DEFAULT_KEY_NAME_CONTACT_ID_API_REF],
                [DEFAULT_KEY_NAME_SUPPLIER_CONTAINER_ID_API_REF]: resultSwitchDialog,
              },
            );

            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              selectedValidationContainer[0],
              [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF],
              true,
              false,
            );

            selectedValidationContainer = arrHelp.combineDataObjInArray(
              selectedValidationContainer,
              'id',
              id,
              newValidation,
            );
          }

          return {
            ...stateDataNvalidation,
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          };
        },
      },
    },
    parentMenuURL: REACT_APP_PURCHASE_PURCHASE_ORDER_URL,
    handleDeleteRow: {
      childrenTransactionData: ({
        transactionData,
        childrenTransactionData,
        childrenTransactionValidation,
        childrenTransactionID = '',
      }) => {
        const {
          childrenTransactionData: currChildrenTransactionData,
          childrenTransactionValidation: currChildrenTransactionValidation,
        } = filteringChildrenTransactionData({
          childrenTransactionData,
          childrenTransactionValidation,
          childrenTransactionDataID: childrenTransactionID,
        });

        return {
          childrenTransactionData: currChildrenTransactionData,
          childrenTransactionValidation: currChildrenTransactionValidation,
          transactionData: [
            {
              ...transactionData[0],
              ...formulaHelpers.calculateDiscountAndTotalOfChildrenData({
                parentData: transactionData[0],
                childrenData: currChildrenTransactionData,
              }),
            },
          ],
        };
      },
    },
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE]: {
    defaultTransctionNumberCode: DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_DELIVERY,
    allowKeyInputTransactionData: allowKeysDataPurchaseDelivery,
    allowKeyInputProductTransctionData: allowKeysDataProductPurchaseDelivery,
    allowKeyInputChildrenTransactionData: allowKeysDataProductPurchaseDelivery,
    keyNameReferenceNumber: DEFAULT_KEY_NAME_REFERENCE_NUMBER,
    keyNameReferenceNumberContainer: DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER,
    keyNameTransactionDate: DEFAULT_KEY_NAME_DELIVERY_DATE_API_REF,
    keyNameAccountingPeriod: DEFAULT_KEY_NAME_ACCOUNTING_PERIOD,
    minimumLengthChildrenTransaction: 1,
    uniqueKeyNameChildrenTransaction: DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
    statusFreeUpdateInputTransaction: [DEFAULT_STATUS_PURCHASE_DELIVERY_OPEN],
    errorMessagePrimaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.primary',
    errorMessageSecondaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.secondary',
    errorMessagePrimaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.primary',
    errorMessageSecondaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.secondary',
    errorMessagePrimaryInvalidForm: 'base.message.error.invalid-form.primary',
    errorMessageSecondaryInvalidForm: 'base.message.error.invalid-form.secondary',
    isAdditionalCostShow: false,
    isMinutesHandoverShow: true,
    isFormStatusShow: false,
    actionAddTransaction: addPurchaseDeliveryV2,
    actionUpdateTransaction: updatePurchaseDelivery,
    statusRequestAddPending: STATUS_REQUEST_ADD_PURCHASE_DELIVERY_PENDING,
    statusRequestAddSuccess: STATUS_REQUEST_ADD_PURCHASE_DELIVERY_SUCCESS,
    statusRequestUpdatePending: STATUS_REQUEST_UPDATE_PURCHASE_DELIVERY_PENDING,
    statusRequestUpdateSuccess: STATUS_REQUEST_UPDATE_PURCHASE_DELIVERY_SUCCESS,
    generateCreateDataNvalidationTransaction: ({ amount, companyUserData }) =>
      generateDataNValidationAddPurchaseDeliveryV2({
        amount,
        companyUserData,
        customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
        customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
      }),
    generateCreateDataNvalidationChildrenTransaction: ({ amount }) =>
      generateDataNValidationAddProductPD(
        amount,
        DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
        DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
      ),
    generalizeDataAddTransaction: generalizeDataAddPurchaseDelivery,
    generalizeDataUpdateTransaction: generalizeDataUpdatePurchaseDelivery,
    primaryAddUpdateDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          placeholder: 'placeholder.input-auto',
          inputName: 'transaction_number',
          storeName: ROOT_STORE_NAME_REFERENCE_NUMBER,
          childrenStoreName: SLICE_NAME_REFERENCE_NUMBER_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_REFERENCE_NUMBER,
          keyInputContainerName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: referenceNumberList,
            additionalParamsForAction: {},
            removeDataAction: clearReferenceNumber,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['code', 'format'],
                ['value'],
                formatHelp.formatTransactionNumber,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'code',
          },
          inputProps: {
            maxLength: DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE,
          },
          isDisabled: ({
            actionMenu,
            parentStateList = {},
            transactionData,
            statusFreeUpdateInputTransaction = [],
          }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE ||
              parentStateList.statusRequest ===
                STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_PENDING ||
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                !statusFreeUpdateInputTransaction.includes(status))
            );
          },
          isUseTextInput: true,
          isRequired: true,
        },
        {
          label: 'label.order-number',
          placeholder: 'placeholder.select-order-number',
          inputName: 'order_number',
          keyInputName: 'purchase_order_id',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          storeName: NAME_STORE.PURCHASE,
          childrenStoreName: SLICE_NAME_PURCHASE_ORDER_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_PURCHASE_ORDER_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          autocompleteProps: {
            fetchAction: getListPurchaseOrder,
            additionalParamsForAction: {
              status: [
                DEFAULT_STATUS_SALES_QUOTATION_OPEN,
                DEFAULT_STATUS_SALES_DELIVERY_PARTIALLY_SENT,
              ],
            },
            removeDataAction: clearPurchaseOrderData,
            additionalParamsForScrollAction: {
              status: [
                DEFAULT_STATUS_SALES_QUOTATION_OPEN,
                DEFAULT_STATUS_SALES_DELIVERY_PARTIALLY_SENT,
              ],
              isRewriteAll: false,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getOptionLabel(options, 'purchase_order_no'),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'purchase_order_no',
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                !statusFreeUpdateInputTransaction.includes(status)) ||
              actionMenu === DEFAULT_ACTION_MENU_UPDATE
            );
          },
          isUseTextInput: false,
          isRequired: true,
        },
        {
          label: 'label.transaction-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: 'delivery_date',
          keyInputName: 'delivery_date',
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
          isAddOneDayForMinDate: false,
          isRequired: true,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
        {
          label: 'label.warehouse',
          placeholder: 'label.warehouse',
          inputName: 'warehouse_name',
          keyInputName: 'warehouse_id',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          storeName: NAME_STORE.WAREHOUSE,
          childrenStoreName: SLICE_NAME_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_WAREHOUSE_ID_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          autocompleteProps: {
            fetchAction: getWarehouseListWithDynamicStore,
            additionalParamsForAction: DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE,
            removeDataAction: clearWarehouseDataSlice,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            additionalParamsForRemoveDataAction: DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE,
            getOptionLabel: (options) =>
              autocompleteHelper.getOptionLabel(options, 'warehouse_name'),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'warehouse_name',
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status, purchase_order_id } = transactionData[0];

            return (
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                !statusFreeUpdateInputTransaction.includes(status)) ||
              actionMenu === DEFAULT_ACTION_MENU_UPDATE ||
              (actionMenu === DEFAULT_ACTION_MENU_ADD && purchase_order_id)
            );
          },
          isUseTextInput: false,
        },
      ],
      [
        {
          label: 'label.contact-supplier',
          placeholder: 'label.contact-supplier',
          inputName: 'contact_name',
          storeName: NAME_STORE.CONTACT,
          childrenStoreName: SLICE_NAME_CONTACT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_SUPPLIER_CONTAINER_ID_API_REF,
          statusRequestListPending: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: contactList,
            additionalParamsForAction: {
              type: ['is_supplier'],
            },
            removeDataAction: clearContactData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              type: ['is_supplier'],
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['contact_code', 'contact_name'],
                ['contact_name'],
                formatHelp.contactCodeWithName,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'contact_name',
          },
          isUseAdditionalButtonNotFound: true,
          additionalButtonNotFoundProps: {
            textNotFound: 'button.plus-add-new-contact',
            keyNameIsNew: DEFAULT_KEY_NAME_IS_NEW_CONTACT_DATA,
            keynameSwitchDialog: 'contact-data',
            newPageStatus: DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA,
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status, purchase_order_id } = transactionData[0];

            return (
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                !statusFreeUpdateInputTransaction.includes(status)) ||
              (actionMenu === DEFAULT_ACTION_MENU_ADD && purchase_order_id) ||
              actionMenu === DEFAULT_ACTION_MENU_UPDATE
            );
          },
        },
        {
          label: 'label.supplier-address',
          placeholder: 'label.supplier-address',
          inputName: 'contact_address',
          keyInputName: 'supplier_address',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          gridProps: {
            xs: 8,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
      ],
    ],
    secondaryAddUpdateDataSchema: [
      [],
      [
        // tagAutocompleteInputProps,
        {
          label: 'label.memo',
          placeholder: 'label.memo',
          inputName: 'memo',
          keyInputName: 'description',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          additionalOutlinedInput: {
            rows: 3,
            multiline: true,
            className: 'none-padding',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
        {
          usageInputLabel: false,
          keyInputName: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DROPZONE,
          additionalDropzoneProps: {
            generalizeRequestAction: (attachmentFile, relationValueToOtherAttachment) => {
              return generalizeAttachmentRequest({
                attachmentFile,
                relationValueToOtherAttachment,
                featureDocument: DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE,
              });
            },
          },
          gridContainerProps: {
            width: 'calc(100% - 2px)',
          },
        },
      ],
    ],
    inputValueChanges: {
      [DEFAULT_KEY_NAME_REFERENCE_NUMBER]: {
        generateChanges: ({ newValue }) => {
          return {
            resultChanges: {
              [DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER]: {
                value: newValue,
              },
              [DEFAULT_KEY_NAME_REFERENCE_NUMBER]: newValue,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_QUANTITY_API_REF]: {
        generateChanges: ({
          newValue,
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const { quantity_comparison } = selectedChildrenTransactionData;

          const { quantity_available_const } = selectedChildrenTransactionData;
          const quantity_available = Number(quantity_available_const) - Number(newValue);

          return {
            resultChanges: {
              quantity_available,
              quantity_origin: Number(newValue) * Number(quantity_comparison),
            },
          };
        },
      },
    },
    autocompleteValueChanges: {
      [DEFAULT_KEY_NAME_PRODUCT_ID_API_REF]: {
        generateChanges: ({ newValue }) => {
          let unit = '',
            description = '',
            quantity = '',
            quantity_available_const = 0;

          if (!isEmpty(newValue)) {
            unit = newValue.unit;

            quantity_available_const = Number(newValue.quantity_written);
          }

          return {
            resultChanges: {
              unit,
              unit_origin: unit,
              unit_destination: unit,
              description,
              quantity,
              quantity_origin: quantity,
              quantity_available_const,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_API_REF]: {
        isHitEndpoint: true,
        action: getDetailPurchaseOrderV2,
        actionRemove: () => clearDataPurchaseSlice(DEFAULT_PAYLOAD_STORAGE_PURCHASE_ORDER_DETAIL),
        storeName: NAME_STORE.PURCHASE,
        childrenSliceName: SLICE_NAME_DETAIL_PURCHASE_ORDER,
        generateParamsAction: ({ newAutocompleteChanges = {} }) => {
          return newAutocompleteChanges[DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_API_REF] || '';
        },
        generateChanges: ({ resultGetAction, companyUserData, currentTransactionData }) => {
          const { transactionData, transactionValidation } =
            generateDataNValidationInitialPurchaseDeliveryFromPurchaseOrderV2({
              selectedPurchaseOrderData: resultGetAction,
              companyUserData,
              currentPurchaseDeliveryData: currentTransactionData,
              customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
              customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
            });

          const { childrenTransactionData, childrenTransactionValidation } =
            generateDataNValidationInitialProductPurchaseDeliveryFromPurchaseOrderV2({
              selectedPurchaseOrderData: resultGetAction,
              customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
              customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
            });

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
      [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF]: {
        isHitEndpoint: false,
        isCanChangeAllState: false,
        generateChanges: ({ newValue }) => {
          const supplierAddress = newValue
            ? newValue[DEFAULT_KEY_NAME_BILLING_ADDRESS_API_REF] || ''
            : '';

          return {
            resultChanges: {
              supplier_address: supplierAddress,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF]: {
        ...getChangesAutocompleteForUnitProductTransactionPropertiesPrimary({
          subTransactionType: DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE,
        }),
      },
    },
    // columnsTableHead: ,
    gridHeaderChildrenTransaction: gridHeaderProductPurchaseDelivery,
    gridCollapseChildrenTransaction: gridCollapseProductPurchaseDelivery,
    dependingKeyNameTransactionValidation: [DEFAULT_KEY_NAME_DELIVERY_DATE_API_REF, 'expired_date'], // validation form on transaction
    anotherKeyNameTransactionDataDepends: [
      DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
      DEFAULT_KEY_NAME_DELIVERY_DATE_API_REF,
    ], // validation form on data transaction
    keyNameBypassChildrenTransactionValidation: [
      'product_id',
      'quantity',
      DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
    ],
    checkingIsEmptyChildrenTransactionKeyNameBypass: [
      [null, ''],
      DEFAULT_EMPTY_CALCULATIONS_NUMBER,
      [null, ''],
    ],
    keyNameCheckingValidationInputBypass: [
      'quantity',
      'price',
      DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
    ],
    keyNameCheckingStatusChildrenTransaction: ['product_id'],
    menuActionAddProps: {
      [DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_PURCHASE_ORDER_DATA]: {
        getAction: (searchParams) =>
          getDetailPurchaseOrder({
            purchase_order_id: searchParams[DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_API_REF],
          }),
        parentStateName: NAME_STORE.PURCHASE,
        childrenStateName: SLICE_NAME_DETAIL_PURCHASE_ORDER,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNValidationInitialPurchaseDeliveryFromPurchaseOrderV2({
            selectedPurchaseOrderData: resultGetAction,
            ...params,
            customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: ({ resultGetAction }) =>
          generateDataNValidationInitialProductPurchaseDeliveryFromPurchaseOrderV2({
            selectedPurchaseOrderData: resultGetAction,
            customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
          }),
      },
    },
    menuActionUpdateProps: {
      [DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_DELIVERY_ID]: {
        getAction: (paramsRouter) =>
          getDetailPurchaseDelivery(paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_DELIVERY_ID]),
        // dependencies action when after getting primary action (above action)
        onDependenciesActions: [defaultOnDependenciesListDetailsAttachmentAction],
        parentStateName: NAME_STORE.PURCHASE,
        childrenStateName: SLICE_NAME_PURCHASE_DELIVERY_DETAIL,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNValidationUpdatePurchaseDeliveryV2({
            purchaseDeliveryDataSelected: resultGetAction,
            ...params,
            customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: (resultGetAction) => {
          const { updateProductData, updateProductValidation } =
            generateDataNValidationUpdateProductPurchaseDeliveryV2(resultGetAction);

          return {
            [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: updateProductData,
            [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION]: updateProductValidation,
          };
        },
      },
    },
    additionalSettingDefaultInputActionMenuAdd: [
      clearDataProductSupplierAction,
      ...defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_PURCHASE_TYPE],
      ...defaultAdditionalSettingWarehouseConfig[DEFAULT_TRANSACTION_PURCHASE_TYPE],
    ],
    additionalSettingDefaultInputActionMenuUpdate: [
      clearDataProductSupplierAction,
      ...defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_PURCHASE_TYPE],
      ...defaultAdditionalSettingWarehouseConfig[DEFAULT_TRANSACTION_PURCHASE_TYPE],
    ],
    additionalPropsSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        generateDataNValidationProfile: generateDataNValidationAddProfile,
        isDisabledContactType: [DEFAULT_CONTACT_IS_SUPPLIER_TYPE],
      },
    },
    additionalActionSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        parentStateName: NAME_STORE.CONTACT,
        childrenStateName: SLICE_NAME_TEMP_ADD_CONTACT_DATA,
        generalizeForState: ({
          resultSwitchDialog = {},
          keyStateContainer,
          keyValidationContainer,
          selectedIndex = 0,
          ...stateDataNvalidation
        }) => {
          let {
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          } = stateDataNvalidation;

          if (!isEmpty(resultSwitchDialog)) {
            const { id } = selectedStateContainer[selectedIndex];

            selectedStateContainer = arrHelp.combineDataObjInArray(
              selectedStateContainer,
              'id',
              id,
              {
                [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF]:
                  resultSwitchDialog[DEFAULT_KEY_NAME_CONTACT_ID_API_REF],
                [DEFAULT_KEY_NAME_SUPPLIER_CONTAINER_ID_API_REF]: resultSwitchDialog,
              },
            );

            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              selectedValidationContainer[0],
              [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF],
              true,
              false,
            );

            selectedValidationContainer = arrHelp.combineDataObjInArray(
              selectedValidationContainer,
              'id',
              id,
              newValidation,
            );
          }

          return {
            ...stateDataNvalidation,
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          };
        },
      },
    },
    generateDataForSettingDefaultInputActionMenuAdd: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    generateDataForSettingDefaultInputActionMenuUpdate: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    parentMenuURL: REACT_APP_PURCHASE_PURCHASE_DELIVERY_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_INVOICE_TYPE]: {
    defaultTransctionNumberCode: DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_INVOICE,
    allowKeyInputTransactionData: allowKeysDataPurchaseInvoice,
    allowKeyInputProductTransctionData: allowKeysDataProductPurchaseInvoice,
    allowKeyInputChildrenTransactionData: allowKeysDataProductPurchaseInvoice,
    keyNameReferenceNumber: DEFAULT_KEY_NAME_REFERENCE_NUMBER,
    keyNameReferenceNumberContainer: DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER,
    keyNameTransactionDate: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
    keyNameAccountingPeriod: DEFAULT_KEY_NAME_ACCOUNTING_PERIOD,
    keyNameDiscountType: DEFAULT_KEY_NAME_DISCOUNT_TYPE_API_REF,
    keyNameDiscountPercentage: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
    keyNameDiscountNominal: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
    keyNameDiscountAccountID: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_API_REF,
    keyNameDiscountAccountIDcontainer: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_CONTAINER,
    keyNameDownPaymentAccountID: DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_API_REF,
    keyNameDownPaymentAccountIDcontainer: DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_CONTAINER,
    keyNameDownPaymentValue: DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE,
    keyNameExpenseAccountID: DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_API_REF,
    keyNameExpenseAccountIDcontainer: DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_CONTAINER,
    keyNameExpenseValue: DEFAULT_KEY_NAME_EXPENSE_VALUE,
    keyNameMaximumDownPaymentValue: defaultKeyNameMaximumDownPaymentValue,
    minimumLengthChildrenTransaction: 1,
    uniqueKeyNameChildrenTransaction: DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
    statusFreeUpdateInputTransaction: [DEFAULT_STATUS_PURCHASE_INVOICE_OPEN],
    errorMessagePrimaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.primary',
    errorMessageSecondaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.secondary',
    errorMessagePrimaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.primary',
    errorMessageSecondaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.secondary',
    errorMessagePrimaryInvalidForm: 'base.message.error.invalid-form.primary',
    errorMessageSecondaryInvalidForm: 'base.message.error.invalid-form.secondary',
    relationalKeyDiscountTypeNvalue: {
      [DEFAULT_DISCOUNT_PERCENTAGE_TYPE]: {
        keyInputName: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
        maximumValue: 100,
        representationDataType: DEFAULT_REPRESENTATION_DATA_NUMBER_TYPE,
      },
      [DEFAULT_DISCOUNT_NOMINAL_TYPE]: {
        keyInputName: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
        keyNameMaximumValue: DEFAULT_KEY_NAME_AMOUNT_CONST,
        representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
      },
    },
    isAdditionalCostShow: true,
    // indicate for additional cost is combined
    additionalCostCombinedOnFormSchema: true,
    isFormStatusShow: true,
    // showing floading from status
    showFormStatusFloating: true,
    // isAdditionalCostShow: true,
    // additionalCostFormSchema: additionalCostPurchaseForm,
    isMinutesHandoverShow: false,
    // isFormStatusShow: true,
    actionAddTransaction: addPurchaseInvoiceV2,
    actionUpdateTransaction: updatePurchaseInvoice,
    statusRequestAddPending: STATUS_REQUEST_ADD_PURCHASE_INVOICE_PENDING,
    statusRequestAddSuccess: STATUS_REQUEST_ADD_PURCHASE_INVOICE_SUCCESS,
    statusRequestUpdatePending: STATUS_REQUEST_UPDATE_PURCHASE_INVOICE_PENDING,
    statusRequestUpdateSuccess: STATUS_REQUEST_UPDATE_PURCHASE_INVOICE_SUCCESS,
    generateCreateDataNvalidationTransaction: ({ amount, companyUserData }) =>
      generateDataNValidationAddPurchaseInvoiceV2({
        amount,
        companyUserData,
        customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
        customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
      }),
    generateCreateDataNvalidationChildrenTransaction: ({ amount }) =>
      generateDataNValidationAddProductPurchaseInvoice(
        amount,
        DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
        DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
      ),
    generalizeDataAddTransaction: generalizeDataAddPurchaseInvoice,
    generalizeDataUpdateTransaction: generalizeDataUpdatePurchaseInvoice,
    primaryAddUpdateDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          placeholder: 'placeholder.input-auto',
          inputName: 'transaction_number',
          storeName: ROOT_STORE_NAME_REFERENCE_NUMBER,
          childrenStoreName: SLICE_NAME_REFERENCE_NUMBER_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_REFERENCE_NUMBER,
          keyInputContainerName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultGridPropsSecondary,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: referenceNumberList,
            additionalParamsForAction: {},
            removeDataAction: clearReferenceNumber,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['code', 'format'],
                ['value'],
                formatHelp.formatTransactionNumber,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'code',
          },
          inputProps: {
            maxLength: DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE,
          },
          isDisabled: ({
            actionMenu,
            parentStateList = {},
            transactionData,
            statusFreeUpdateInputTransaction = [],
          }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE ||
              parentStateList.statusRequest ===
                STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_PENDING ||
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                !statusFreeUpdateInputTransaction.includes(status))
            );
          },
          isUseTextInput: true,
          isRequired: true,
        },
        {
          label: 'label.order-number',
          placeholder: 'label.order-number',
          inputName: DEFAULT_KEY_NAME_MODUL_ID,
          keyInputName: DEFAULT_KEY_NAME_MODUL_ID,
          gridProps: defaultGridPropsSecondary,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyUniqueName: DEFAULT_KEY_NAME_MODUL_ID,
          keyInputContainerName: DEFAULT_KEY_NAME_MODUL_ID_CONTAINER,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          autocompleteProps: {
            getOptionLabel: (options) =>
              autocompleteHelper.getOptionLabel(options, 'transaction_no'),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            keyNameFetchInput: 'transaction_no',
          },
          isDisabled: ({ actionMenu }) => {
            return !!actionMenu || true;
          },
          isUseTextInput: false,
          isGridShow: ({ transactionData = [] }) => {
            if (isEmpty(transactionData)) return false;

            return (
              transactionData[0][DEFAULT_KEY_NAME_MODUL_TYPE_API_REF] ===
                modulTypeSalesInvoice.order ||
              transactionData[0][DEFAULT_KEY_NAME_MODUL_TYPE_API_REF] ===
                modulTypeSalesInvoice.delivery
            );
          },
        },
        {
          label: 'label.transaction-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: 'transaction_date',
          keyInputName: 'transaction_date',
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultGridPropsSecondary,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
          isAddOneDayForMinDate: false,
          isRequired: true,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
        {
          label: 'label.expired-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: 'expired_date',
          keyInputName: 'expired_date',
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultGridPropsSecondary,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: 'transaction_date',
          isAddOneDayForMinDate: true,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
        {
          label: 'label.warehouse',
          placeholder: 'label.warehouse',
          inputName: 'warehouse_name',
          keyInputName: 'warehouse_id',
          gridProps: defaultGridPropsSecondary,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          storeName: NAME_STORE.WAREHOUSE,
          childrenStoreName: SLICE_NAME_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_WAREHOUSE_ID_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          autocompleteProps: {
            fetchAction: getWarehouseListWithDynamicStore,
            additionalParamsForAction: DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE,
            removeDataAction: clearWarehouseDataSlice,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            additionalParamsForRemoveDataAction: DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE,
            getOptionLabel: (options) =>
              autocompleteHelper.getOptionLabel(options, 'warehouse_name'),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'warehouse_name',
          },
          isDisabled: ({ actionMenu, transactionData }) => {
            const { modul_type } = transactionData[0];

            return (
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                modul_type !== modulTypeSalesInvoice.manual) ||
              modul_type === modulTypeSalesInvoice.order ||
              modul_type === modulTypeSalesInvoice.delivery
            );
          },
          isUseTextInput: false,
        },
      ],
      [
        {
          label: 'label.contact-supplier',
          placeholder: 'label.contact-supplier',
          inputName: 'contact_name',
          storeName: NAME_STORE.CONTACT,
          childrenStoreName: SLICE_NAME_CONTACT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_SUPPLIER_CONTAINER_ID_API_REF,
          statusRequestListPending: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: contactList,
            additionalParamsForAction: {
              type: ['is_supplier'],
            },
            removeDataAction: clearContactData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              type: ['is_supplier'],
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['contact_code', 'contact_name'],
                ['contact_name'],
                formatHelp.contactCodeWithName,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'contact_name',
          },
          isUseAdditionalButtonNotFound: true,
          additionalButtonNotFoundProps: {
            textNotFound: 'button.plus-add-new-contact',
            keyNameIsNew: DEFAULT_KEY_NAME_IS_NEW_CONTACT_DATA,
            keynameSwitchDialog: 'contact-data',
            newPageStatus: DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA,
          },
          isDisabled: ({ actionMenu, transactionData }) => {
            const { modul_type } = transactionData[0];

            return (
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                modul_type !== modulTypeSalesInvoice.manual) ||
              modul_type === modulTypeSalesInvoice.order ||
              modul_type === modulTypeSalesInvoice.delivery
            );
          },
        },
        {
          label: 'label.supplier-address',
          placeholder: 'label.supplier-address',
          inputName: 'contact_address',
          keyInputName: 'supplier_address',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          gridProps: {
            xs: 8,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isDisabled: ({ actionMenu, transactionData }) => {
            const { modul_type } = transactionData[0];

            return (
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                modul_type !== modulTypeSalesInvoice.manual) ||
              modul_type === modulTypeSalesInvoice.order ||
              modul_type === modulTypeSalesInvoice.delivery
            );
          },
        },
      ],
    ],
    secondaryAddUpdateDataSchema: [
      [
        // {
        //     label: 'label.on-behalf',
        //     placeholder: 'label.on-behalf',
        //     inputName: 'on_behalf',
        //     keyInputName: 'on_behalf',
        //     inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        //     isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
        //         const { status } = transactionData[0]

        //         return actionMenu === DEFAULT_ACTION_MENU_UPDATE && !statusFreeUpdateInputTransaction.includes(status)
        //     },
        // },
        {
          label: 'label.subtotal',
          name: 'amount_const',
          typographyLabelProps: {
            variant: 'boldIndicator2',
          },
          typographyValueProps: {
            variant: 'body8',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
          isTypography: true,
          emptyValueSubstitute: 0,
        },
        ...arrHelp.joinAtIndex({
          arr: additionalCostPurchaseForm,
          joinIndex: 0,
          itemJoin: totalAfterDiscountSchema,
        }),
        {
          representationDataType: DEFAULT_REPRESENTATION_DATA_HORIZONTAL_LINE_TYPE,
        },
        {
          label: 'label.total',
          name: 'amount',
          inputName: 'amount',
          keyInputName: 'amount',
          typographyLabelProps: {
            variant: 'boldIndicator2',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
          isTypography: true,
        },
      ],
      [
        // tagAutocompleteInputProps,
        {
          usageInputLabel: false,
          keyInputName: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DROPZONE,
          additionalDropzoneProps: {
            generalizeRequestAction: (attachmentFile, relationValueToOtherAttachment) => {
              return generalizeAttachmentRequest({
                attachmentFile,
                relationValueToOtherAttachment,
                featureDocument: DEFAULT_TRANSACTION_PURCHASE_PURCHASE_INVOICE_TYPE,
              });
            },
          },
          gridContainerProps: {
            width: 'calc(100% - 2px)',
          },
        },
        {
          label: 'label.memo',
          placeholder: 'label.memo',
          inputName: 'memo',
          keyInputName: 'description',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          additionalOutlinedInput: {
            rows: 3,
            multiline: true,
            className: 'none-padding',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    inputValueChanges: {
      [DEFAULT_KEY_NAME_REFERENCE_NUMBER]: {
        generateChanges: ({ newValue }) => {
          return {
            resultChanges: {
              [DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER]: {
                value: newValue,
              },
              [DEFAULT_KEY_NAME_REFERENCE_NUMBER]: newValue,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_PRICE_API_REF]: {
        generateChanges: ({
          newValue,
          transactionData = [],
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const amountTransactionData = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const { quantity, amount: amountPast } = selectedChildrenTransactionData;

          const amountChildrenTransactionWithoutPast = amountTransactionData - amountPast;
          const currentAmountSelectedChildrenTransaction = Number(quantity) * Number(newValue);

          const amountChildrenTransaction =
            amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction;

          const { discount_nominal, discount_percentage } = formulaCountDiscountCost({
            allAmountTransaction: amountChildrenTransaction,
            transactionData,
          });

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: currentAmountSelectedChildrenTransaction,
            },
            transactionDataChanges: {
              amount: Number(newAmountTransaction),
              amount_const: Number(amountChildrenTransaction),
              discount_nominal: Number(discount_nominal) || '',
              discount_percentage: Number(discount_percentage) || '',
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_QUANTITY_API_REF]: {
        generateChanges: ({
          newValue,
          transactionData = [],
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const amountTransactionData = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const {
            price,
            amount: amountPast,
            quantity_comparison,
          } = selectedChildrenTransactionData;

          const amountChildrenTransactionWithoutPast = amountTransactionData - amountPast;
          const currentAmountSelectedChildrenTransaction = Number(price) * Number(newValue);

          const amountChildrenTransaction =
            amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction;

          const { discount_nominal, discount_percentage } = formulaCountDiscountCost({
            allAmountTransaction: amountChildrenTransaction,
            transactionData,
          });

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: currentAmountSelectedChildrenTransaction,
              quantity_origin: Number(quantity_comparison) * Number(newValue),
            },
            transactionDataChanges: {
              amount: Number(newAmountTransaction),
              amount_const: Number(amountChildrenTransaction),
              discount_nominal: Number(discount_nominal) || '',
              discount_percentage: Number(discount_percentage) || '',
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          let newDiscountNominal = Number(amountChildrenTransaction) * (Number(newValue) / 100);

          newDiscountNominal = Number(newDiscountNominal).toFixed(2);

          const { expense_value, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: newDiscountNominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
              [DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF]: Number(newDiscountNominal),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: newValue,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          const newDiscountPercentage = amountChildrenTransaction
            ? Number((Number(newValue) / Number(amountChildrenTransaction)) * 100).toFixed(2)
            : 0;

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
              [DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF]: Number(newDiscountPercentage),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_EXPENSE_VALUE]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { discount_nominal, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: newValue,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { discount_nominal, expense_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: newValue,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
            },
          };
        },
      },
    },
    autocompleteValueChanges: {
      [DEFAULT_KEY_NAME_PRODUCT_ID_API_REF]: {
        isCanChangeAllState: true,
        generateChanges: ({
          newValue,
          transactionData = [],
          transactionValidation,
          childrenTransactionData = [],
          childrenTransactionValidation,
          idChildrenTransaction = '',
        }) => {
          const { product_description, purchase_price, unit, product_unit } = newValue || {};

          const quantity = !isEmpty(newValue) ? 1 : 0;

          // amount current selected transaction data
          const amount =
            Number(purchase_price) && Number(quantity)
              ? Number(purchase_price) * Number(quantity)
              : DEFAULT_CALCULATION_NUMBER;

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const sumAmountsChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const {
            discount_type,
            discount_percentage,
            discount_nominal,
            expense_value,
            down_payment_value,
          } = transactionData[0];

          const { amount: amountChildrenTransaction } = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const newAmountTransactionData = !isEmpty(newValue)
            ? Number(sumAmountsChildrenTransaction) -
              Number(amountChildrenTransaction) +
              Number(amount)
            : Number(sumAmountsChildrenTransaction) - Number(amountChildrenTransaction);

          let discountNominal =
            discount_type === DEFAULT_DISCOUNT_NOMINAL_TYPE
              ? discount_nominal
              : (Number(discount_percentage) / Number(100)) * Number(newAmountTransactionData);

          discountNominal = Number(discountNominal).toFixed(2);

          if (Number(discountNominal) > Number(newAmountTransactionData))
            discountNominal = Number(newAmountTransactionData);

          let discountPercentage =
            discount_type === DEFAULT_DISCOUNT_PERCENTAGE_TYPE
              ? discount_percentage
              : newAmountTransactionData
              ? (Number(discountNominal) / Number(newAmountTransactionData)) * 100
              : 0;

          discountPercentage = Number(discountPercentage).toFixed(2);

          const expenseValue = Number(newAmountTransactionData) <= 0 ? '' : expense_value;

          const downPaymentValue = Number(newAmountTransactionData) <= 0 ? '' : down_payment_value;

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: newAmountTransactionData,
            discountNominal,
            expenseValue,
            downPaymentValue,
          });

          const childrenTransactionDataChanges = {
            ...newValue,
            price: Number(purchase_price) || '',
            product_description: product_description || '',
            quantity: Number(quantity) || '',
            quantity_origin: Number(quantity) || '',
            quantity_available_const: Infinity,
            quantity_comparison: 1,
            unit: !hasImplementedUnitProductUnit ? product_unit || '' : unit || '',
            unit_destination: !hasImplementedUnitProductUnit ? product_unit : unit || '',
            unit_origin: !hasImplementedUnitProductUnit ? product_unit : unit || '',
            amount: Number(amount) || '',
          };

          const transactionDataChanges = {
            amount: Number(newAmountTransaction),
            discount_nominal: Number(discountNominal) || '',
            discount_percentage: Number(discountPercentage) || '',
            expense_value: expenseValue,
            down_payment_value: downPaymentValue,
            amount_const: Number(newAmountTransactionData),
          };

          transactionData = [
            {
              ...transactionData[0],
              ...transactionDataChanges,
            },
          ];

          childrenTransactionData = arrHelp.combineDataObjInArray(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
            childrenTransactionDataChanges,
          );

          if (!isEmpty(childrenTransactionValidation)) {
            const isErrorValidation = !Number(amount);
            const selectedChildrenTransactionValidation = arrHelp.filterObjDataWithID(
              childrenTransactionValidation,
              'id',
              idChildrenTransaction,
            );

            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              selectedChildrenTransactionValidation,
              ['quantity'],
              true,
              isErrorValidation,
            );

            childrenTransactionValidation = arrHelp.combineDataObjInArray(
              childrenTransactionValidation,
              'id',
              idChildrenTransaction,
              newValidation,
            );
          }

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
      [DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF]: {
        isCanChangeAllState: true,
        generateChanges: ({ newValue, transactionData, transactionValidation }) => {
          if (!isEmpty(transactionValidation)) {
            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              transactionValidation[0],
              [DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF],
              true,
              isEmpty(newValue),
            );

            transactionValidation = [newValidation];
          }

          const { childrenTransactionData, childrenTransactionValidation } =
            generateDataNValidationAddProductPurchaseInvoiceV2({
              amount: 2,
              customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
              customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
            });

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
      [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF]: {
        isHitEndpoint: false,
        isCanChangeAllState: false,
        generateChanges: ({ newValue }) => {
          const supplierAddress = newValue
            ? newValue[DEFAULT_KEY_NAME_BILLING_ADDRESS_API_REF] || ''
            : '';

          return {
            resultChanges: {
              supplier_address: supplierAddress,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF]: {
        ...getChangesAutocompleteForUnitProductTransactionPropertiesPrimary({}),
      },
    },
    columnsTableHead: (t) => columnsTableHeaderAddUpdateSalesQuotationV2(t),
    gridHeaderChildrenTransaction: gridHeaderProductPurchaseInvoice,
    gridCollapseChildrenTransaction: gridCollapseProductPurchaseInvoice,
    dependingKeyNameTransactionValidation: ['transaction_date', 'expired_date'], // validation form on transaction
    anotherKeyNameTransactionDataDepends: [
      DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
      'transaction_date',
    ], // validation form on data transaction
    keyNameBypassChildrenTransactionValidation: [
      'product_id',
      'quantity',
      DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
    ],
    checkingIsEmptyChildrenTransactionKeyNameBypass: [
      [null, ''],
      DEFAULT_EMPTY_CALCULATIONS_NUMBER,
      [null, ''],
    ],
    keyNameCheckingValidationInputBypass: [
      'quantity',
      'price',
      DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
    ],
    keyNameCheckingStatusChildrenTransaction: ['product_id'],
    menuActionAddProps: {
      [DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_PURCHASE_ORDER_DATA]: {
        getAction: (searchParams) =>
          getDetailPurchaseOrder({
            purchase_order_id: searchParams[DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_API_REF],
          }),
        parentStateName: NAME_STORE.PURCHASE,
        childrenStateName: SLICE_NAME_DETAIL_PURCHASE_ORDER,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNValidationAddPurchaseInvoiceFromPurchaseOrderV2({
            purchaseOrderData: resultGetAction,
            ...params,
            customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: ({ resultGetAction }) =>
          generateDataNValidationAddProductPurchaseInvoiceFromPurchaseOrderV2({
            productPurchaseOrder: resultGetAction,
            customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
          }),
      },
      [DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_PURCHASE_DELIVERY_DATA]: {
        getAction: (searchParams) =>
          getDetailPurchaseDelivery(searchParams[DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF]),
        parentStateName: NAME_STORE.PURCHASE,
        childrenStateName: SLICE_NAME_PURCHASE_DELIVERY_DETAIL,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNValidationAddPurchaseInvoiceFromPurchaseDeliveryV2({
            purchaseDeliveryData: resultGetAction,
            ...params,
            customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: ({ resultGetAction }) =>
          generateDataNValidationAddProductPurchaseInvoiceFromPurchaseDeliveryV2({
            productPurchaseDelivery: resultGetAction,
            customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
          }),
      },
    },
    menuActionUpdateProps: {
      [DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_INVOICE_ID]: {
        getAction: (paramsRouter) =>
          getDetailPurchaseInvoice({
            purchase_invoice_id: paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_INVOICE_ID],
          }),
        // dependencies action when after getting primary action (above action)
        onDependenciesActions: [defaultOnDependenciesListDetailsAttachmentAction],
        parentStateName: NAME_STORE.PURCHASE,
        childrenStateName: SLICE_NAME_DETAIL_PURCHASE_INVOICE,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNValidationUpdatePurchaseInvoiceFromDetailPurchaseInvoiceV2({
            detailPurchaseInvoice: resultGetAction,
            ...params,
            customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: (resultGetAction) => {
          const { productPurchaseInvoiceData, productPurchaseInvoiceValidation } =
            generateDataNValidationUpdateProductPurchaseInvoiceFromDetailPurchaseInvoice(
              resultGetAction,
            );

          return {
            [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: productPurchaseInvoiceData,
            [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION]: productPurchaseInvoiceValidation,
          };
        },
      },
    },
    additionalSettingDefaultInputActionMenuAdd: [
      clearDataProductSupplierAction,
      ...defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_PURCHASE_TYPE],
      ...defaultAdditionalSettingWarehouseConfig[DEFAULT_TRANSACTION_PURCHASE_TYPE],
    ],
    additionalSettingDefaultInputActionMenuUpdate: [
      clearDataProductSupplierAction,
      ...defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_PURCHASE_TYPE],
      ...defaultAdditionalSettingWarehouseConfig[DEFAULT_TRANSACTION_PURCHASE_TYPE],
    ],
    generateDataForSettingDefaultInputActionMenuAdd: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    generateDataForSettingDefaultInputActionMenuUpdate: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    additionalPropsSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        generateDataNValidationProfile: generateDataNValidationAddProfileForIsCustomer,
        isDisabledContactType: [DEFAULT_CONTACT_IS_SUPPLIER_TYPE],
      },
    },
    additionalActionSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        parentStateName: NAME_STORE.CONTACT,
        childrenStateName: SLICE_NAME_TEMP_ADD_CONTACT_DATA,
        generalizeForState: ({
          resultSwitchDialog = {},
          keyStateContainer,
          keyValidationContainer,
          selectedIndex = 0,
          ...stateDataNvalidation
        }) => {
          let {
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          } = stateDataNvalidation;

          if (!isEmpty(resultSwitchDialog)) {
            const { id } = selectedStateContainer[selectedIndex];

            selectedStateContainer = arrHelp.combineDataObjInArray(
              selectedStateContainer,
              'id',
              id,
              {
                [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF]:
                  resultSwitchDialog[DEFAULT_KEY_NAME_CONTACT_ID_API_REF],
                [DEFAULT_KEY_NAME_SUPPLIER_CONTAINER_ID_API_REF]: resultSwitchDialog,
              },
            );

            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              selectedValidationContainer[0],
              [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF],
              true,
              false,
            );

            selectedValidationContainer = arrHelp.combineDataObjInArray(
              selectedValidationContainer,
              'id',
              id,
              newValidation,
            );
          }

          return {
            ...stateDataNvalidation,
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          };
        },
      },
    },
    parentMenuURL: REACT_APP_PURCHASE_PURCHASE_INVOICE_URL,
    handleDeleteRow: {
      childrenTransactionData: ({
        transactionData,
        childrenTransactionData,
        childrenTransactionValidation,
        childrenTransactionID = '',
      }) => {
        const {
          childrenTransactionData: currChildrenTransactionData,
          childrenTransactionValidation: currChildrenTransactionValidation,
        } = filteringChildrenTransactionData({
          childrenTransactionData,
          childrenTransactionValidation,
          childrenTransactionDataID: childrenTransactionID,
        });

        return {
          childrenTransactionData: currChildrenTransactionData,
          childrenTransactionValidation: currChildrenTransactionValidation,
          transactionData: [
            {
              ...transactionData[0],
              ...formulaHelpers.calculateDiscountAndTotalOfChildrenData({
                parentData: transactionData[0],
                childrenData: currChildrenTransactionData,
              }),
            },
          ],
        };
      },
    },
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_PAYMENT_TYPE]: {
    defaultTransctionNumberCode: DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_PAYMENT,
    allowKeyInputTransactionData: allowKeysDataPurchasePayment,
    allowKeyInputChildrenTransactionData: allowKeysDataInvoiceOnPurchasePayment,
    keyNameReferenceNumber: DEFAULT_KEY_NAME_PURCHASE_PAYMENT_NO_API_REF,
    keyNameReferenceNumberContainer: DEFAULT_KEY_NAME_PURCHASE_PAYMENT_NO_CONTAINER,
    keyNameTransactionDate: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
    keyNameAccountingPeriod: DEFAULT_KEY_NAME_TODAYS_DATE,
    minimumLengthChildrenTransaction: 1,
    uniqueKeyNameChildrenTransaction: DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF,
    errorMessagePrimaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.primary',
    errorMessageSecondaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.secondary',
    errorMessagePrimaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.primary',
    errorMessageSecondaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.secondary',
    errorMessagePrimaryInvalidForm: 'base.message.error.invalid-form.primary',
    errorMessageSecondaryInvalidForm: 'base.message.error.invalid-form.secondary',
    isAdditionalCostShow: false,
    isMinutesHandoverShow: false,
    isFormStatusShow: false,
    generateCreateDataNvalidationTransaction: ({ amount }) =>
      generateDataNValidationAddPurchasePaymentV2({
        amount,
        // companyUserData,
        customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
        customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
      }),
    generateCreateDataNvalidationChildrenTransaction: ({ amount }) =>
      generateDataNValidationAddInvoicePP(
        amount,
        DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
        DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
      ),
    primaryAddUpdateDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          placeholder: 'placeholder.input-auto',
          inputName: 'transaction_number',
          storeName: ROOT_STORE_NAME_REFERENCE_NUMBER,
          childrenStoreName: SLICE_NAME_REFERENCE_NUMBER_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_PURCHASE_PAYMENT_NO_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_PURCHASE_PAYMENT_NO_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: referenceNumberList,
            additionalParamsForAction: {},
            removeDataAction: clearReferenceNumber,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['code', 'format'],
                ['value'],
                formatHelp.formatTransactionNumber,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'code',
          },
          inputProps: {
            maxLength: DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE,
          },
          isDisabled: ({ actionMenu, parentStateList }) => {
            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE ||
              parentStateList.statusRequest === STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_PENDING
            );
          },
          isUseTextInput: true,
          isRequired: true,
        },
        {
          label: 'label.transaction-date',
          placeholder: 'label.transaction-date',
          inputName: 'transaction_date',
          keyInputName: 'transaction_date',
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
          isAddOneDayForMinDate: false,
          isRequired: true,
        },
        {
          label: 'label.expired-date',
          placeholder: 'label.expired-date',
          inputName: 'expired_date',
          keyInputName: 'expired_date',
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: 'transaction_date',
          isAddOneDayForMinDate: true,
        },
        {
          label: 'label.pay-from',
          placeholder: 'label.pay-from',
          inputName: 'account_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          storeName: NAME_STORE.ACCOUNT,
          childrenStoreName: SLICE_NAME_ACCOUNT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_ACCOUNT_ID_CONTAINER,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          statusRequestListPending: STATUS_REQUEST_LIST_ACCOUNT_DATA_PENDING,
          autocompleteProps: {
            ...defaultAccountAutocompleteProps,
            additionalParamsForAction: {
              category_name: ACCOUNT_CATEGORY_NAME_KAS_N_BANK,
            },
            additionalParamsForScrollAction: {
              category_name: ACCOUNT_CATEGORY_NAME_KAS_N_BANK,
              isRewriteAll: false,
            },
          },
          isRequired: true,
        },
      ],
      [
        {
          label: 'label.contact-supplier',
          placeholder: 'label.contact-supplier',
          inputName: 'contact_name',
          storeName: NAME_STORE.CONTACT,
          childrenStoreName: SLICE_NAME_CONTACT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_SUPPLIER_CONTAINER_ID_API_REF,
          statusRequestListPending: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: contactList,
            additionalParamsForAction: {
              type: ['is_supplier'],
            },
            removeDataAction: clearContactData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              type: ['is_supplier'],
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['contact_code', 'contact_name'],
                ['contact_name'],
                formatHelp.contactCodeWithName,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'contact_name',
          },
          isUseAdditionalButtonNotFound: true,
          additionalButtonNotFoundProps: {
            textNotFound: 'button.plus-add-new-contact',
            keyNameIsNew: DEFAULT_KEY_NAME_IS_NEW_CONTACT_DATA,
            keynameSwitchDialog: 'contact-data',
            newPageStatus: DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA,
          },
          isDisabled: ({ actionMenu }) => {
            return actionMenu === DEFAULT_ACTION_MENU_UPDATE;
          },
          isRequired: true,
        },
        {
          label: 'label.supplier-address',
          placeholder: 'label.supplier-address',
          inputName: 'contact_address',
          keyInputName: 'supplier_address',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          gridProps: {
            xs: 8,
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryAddUpdateDataSchema: [
      [
        {
          label: 'label.total',
          name: 'amount',
          inputName: 'amount',
          keyInputName: 'amount',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
          isTypography: true,
        },
      ],
      [
        // tagAutocompleteInputProps,
        {
          usageInputLabel: false,
          keyInputName: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DROPZONE,
          additionalDropzoneProps: {
            generalizeRequestAction: (attachmentFile, relationValueToOtherAttachment) => {
              return generalizeAttachmentRequest({
                attachmentFile,
                relationValueToOtherAttachment,
                featureDocument: DEFAULT_TRANSACTION_PURCHASE_PURCHASE_PAYMENT_TYPE,
              });
            },
          },
          gridContainerProps: {
            width: 'calc(100% - 2px)',
          },
        },
        {
          label: 'label.memo',
          placeholder: 'label.memo',
          inputName: 'memo',
          keyInputName: 'description',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          additionalOutlinedInput: {
            rows: 3,
            multiline: true,
            className: 'none-padding',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    inputValueChanges: {
      [DEFAULT_KEY_NAME_PURCHASE_PAYMENT_NO_API_REF]: {
        generateChanges: ({ newValue }) => {
          return {
            resultChanges: {
              [DEFAULT_KEY_NAME_PURCHASE_PAYMENT_NO_CONTAINER]: {
                value: newValue,
              },
              [DEFAULT_KEY_NAME_PURCHASE_PAYMENT_NO_API_REF]: newValue,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_AMOUNT_INPUT]: {
        generateChanges: ({
          newValue,
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          childrenTransactionData = arrHelp.combineDataObjInArray(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
            {
              [DEFAULT_KEY_NAME_AMOUNT_INPUT]: newValue,
            },
          );

          const amountInputChildrenTransaction = arrHelp.sumValueArrV2(
            arrHelp.mappingChildrenKeyOnObject(
              childrenTransactionData,
              DEFAULT_KEY_NAME_AMOUNT_INPUT,
            ),
          );

          const latestInvoiceTransactionDate = arrHelp.getLatestDate({
            arr: arrHelp.filteringExistedValue(
              childrenTransactionData,
              DEFAULT_KEY_NAME_AMOUNT_INPUT,
              DEFAULT_EMPTY_CALCULATIONS_NUMBER,
            ),
            dateKeyname: 'transaction_date',
          });

          return {
            transactionDataChanges: {
              amount: amountInputChildrenTransaction,
              [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]: new Date(latestInvoiceTransactionDate),
            },
          };
        },
      },
    },
    autocompleteValueChanges: {
      [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF]: {
        isHitEndpoint: true,
        action: getListPurchaseInvoiceWithContactID,
        actionRemove: clearPurchaseInvoceListWithContactID,
        storeName: NAME_STORE.CONTACT,
        childrenSliceName: SLICE_NAME_PURCHASE_INVOICE_WITH_CONTACT_ID,
        generateParamsAction: ({ newAutocompleteChanges = {} }) => {
          return {
            ...DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION_SECONDARY,
            contact_id: newAutocompleteChanges[DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF] || '',
            status: [
              DEFAULT_STATUS_SALES_INVOICE_OPEN,
              DEFAULT_STATUS_SALES_INVOICE_PARTIALLY_PAID,
            ],
          };
        },
        generateChanges: ({
          newValue,
          resultGetAction,
          currentTransactionData,
          transactionValidation,
          companyUserData,
        }) => {
          const { id: idTransactionData } = currentTransactionData[0];

          const purchaseInvoiceData = isEmpty(resultGetAction)
            ? []
            : resultGetAction[DEFAULT_KEY_NAME_RESPONSE_DATA_API_REF];

          let newTransactionData = cloneDeep(currentTransactionData),
            newTransactionValidation = cloneDeep(transactionValidation);

          if (!isEmpty(transactionValidation)) {
            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              newTransactionValidation[0],
              [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF],
              true,
              isEmpty(newValue),
            );

            newTransactionValidation = [newValidation];
          }

          const { childrenTransactionData, childrenTransactionValidation } =
            generateDataNValidationAddInvoiceFromListPIV2({
              purchaseInvoiceData,
              customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
              customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
            });

          let sumAmountInputInvoiceSalesPayment = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            DEFAULT_KEY_NAME_AMOUNT_INPUT,
          );
          if (Array.isArray(sumAmountInputInvoiceSalesPayment)) {
            sumAmountInputInvoiceSalesPayment = arrHelp.sumValueArrV2(
              sumAmountInputInvoiceSalesPayment,
            );

            newTransactionData = arrHelp.combineDataObjInArray(
              newTransactionData,
              'id',
              idTransactionData,
              {
                amount: sumAmountInputInvoiceSalesPayment,
              },
            );
          }

          if (!isEmpty(currentTransactionData)) {
            const supplierAddress = newValue
              ? newValue[DEFAULT_KEY_NAME_BILLING_ADDRESS_API_REF] || ''
              : '';

            const latestInvoiceTransactionDate = cloneDeep(purchaseInvoiceData).sort(
              (purchaseInvoice, nextPurchaseInvoice) =>
                new Date(nextPurchaseInvoice[DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF]) -
                new Date(purchaseInvoice[DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF]),
            )[0];

            const minDateInput = getAccountingPeriodCompareWithDocumentTransactionDate({
              companyUserData,
              documentData: latestInvoiceTransactionDate,
            });

            newTransactionData = arrHelp.combineDataObjInArray(
              newTransactionData,
              'id',
              idTransactionData,
              {
                supplier_address: supplierAddress,
                [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]: isEmpty(purchaseInvoiceData)
                  ? new Date()
                  : new Date(minDateInput),
              },
            );
          }

          if (isEmpty(newValue)) {
            newTransactionData = arrHelp.combineDataObjInArray(
              newTransactionData,
              'id',
              idTransactionData,
              {
                [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]: new Date(),
              },
            );
          }

          return {
            transactionData: newTransactionData,
            transactionValidation: newTransactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
    },
    gridHeaderChildrenTransaction: gridHeaderInvoiceOnPurchasePayment,
    gridCollapseChildrenTransaction: gridCollapseInvoiceOnPurchasePayment,
    dependingKeyNameTransactionValidation: ['transaction_date', 'expired_date'], // validation form on transaction
    anotherKeyNameTransactionDataDepends: [
      DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
      'transaction_date',
    ], // validation form on data transaction
    keyNameBypassChildrenTransactionValidation: ['purchase_invoice_id', 'amount_input'],
    checkingIsEmptyChildrenTransactionKeyNameBypass: [
      [null, ''],
      DEFAULT_EMPTY_CALCULATIONS_NUMBER,
    ],
    keyNameCheckingValidationInputBypass: ['amount_input', 'price'],
    keyNameCheckingStatusChildrenTransaction: ['purchase_invoice_id'],
    menuActionUpdateProps: {
      [DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_PAYMENT_ID]: {
        getAction: (paramsRouter) =>
          detailPurchasePayment(paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_PAYMENT_ID]),
        // dependencies action when after getting primary action (above action)
        onDependenciesActions: [defaultOnDependenciesListDetailsAttachmentAction],
        parentStateName: NAME_STORE.PURCHASE,
        childrenStateName: NAME_STATE_DETAIL_PURCHASE_PAYMENT,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNValidationUpdatePurchasePaymentFromPPDetailV2({
            purchasePaymentOld: resultGetAction,
            ...params,
            customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: (resultGetAction) =>
          generateDataNValidationUpdateInvoicePPFromDetailPPV2({
            purchasePaymentOld: resultGetAction,
            customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
          }),
        // usage for getting actions when update action, must array
        additionalActionsMenuUpdate: [
          {
            getAction: (paramsGetAction) =>
              getListPurchaseInvoiceWithContactID({
                ...paramsGetAction,
                ...DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION_SECONDARY,
                status: [
                  DEFAULT_STATUS_SALES_INVOICE_OPEN,
                  DEFAULT_STATUS_SALES_INVOICE_PARTIALLY_PAID,
                ],
              }),
            generateParamsAction: ({ transactionData }) => {
              if (
                isEmpty(transactionData) ||
                !Array.isArray(transactionData) ||
                !transactionData.length
              )
                return {};

              return {
                [DEFAULT_KEY_NAME_CONTACT_ID_API_REF]:
                  transactionData[0][DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF],
              };
            },
            parentStateName: NAME_STORE.CONTACT,
            childrenStateName: SLICE_NAME_PURCHASE_INVOICE_WITH_CONTACT_ID,
            keyNameResultActionStore: SLICE_NAME_PURCHASE_INVOICE_WITH_CONTACT_ID,
          },
        ],
        // you must return transactionData, transactionValidation, childrenTransactionData, and childrenTransactionValidation
        // usage for adding state after additional actions for update
        generateStateDataNvalidationAssetsFromAdditionalActions: ({
          transactionData,
          transactionValidation,
          childrenTransactionData,
          childrenTransactionValidation,
          resultAdditionalActions,
        }) => {
          let newChildrenTransactionData = childrenTransactionData,
            newChildrenTransactionValidation = childrenTransactionValidation;

          if (
            !isEmpty(resultAdditionalActions[SLICE_NAME_PURCHASE_INVOICE_WITH_CONTACT_ID]) &&
            Array.isArray(
              resultAdditionalActions[SLICE_NAME_PURCHASE_INVOICE_WITH_CONTACT_ID].data,
            ) &&
            resultAdditionalActions[SLICE_NAME_PURCHASE_INVOICE_WITH_CONTACT_ID].data.length
          ) {
            const {
              childrenTransactionData: childrenTransactionDataGen,
              childrenTransactionValidation: childrenTransactionValidationGen,
            } = generateDataNvalidationInvoicePurchasePaymentFromPurchaseInvoiceList({
              currentInvoicePurchasePaymentData: childrenTransactionData,
              currentInvoicePurchasePaymentValidation: childrenTransactionValidation,
              customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
              customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
              invoiceList:
                resultAdditionalActions[SLICE_NAME_PURCHASE_INVOICE_WITH_CONTACT_ID].data,
            });

            newChildrenTransactionData = childrenTransactionDataGen;
            newChildrenTransactionValidation = childrenTransactionValidationGen;
          }

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData: newChildrenTransactionData,
            childrenTransactionValidation: newChildrenTransactionValidation,
          };
        },
      },
    },
    additionalSettingDefaultInputActionMenuAdd: [
      {
        parentStateName: NAME_STORE.ACCOUNT,
        childrenListStateName: SLICE_NAME_ACCOUNT_DATA,
        keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
        keyNameSubResponseData: DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF,
        defaultKeyInputName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
        defaultKeyInputContainerName: DEFAULT_KEY_NAME_ACCOUNT_ID_CONTAINER,
        indexSelected: 0,
        action: getAccountList,
        additionalParams: {
          [DEFAULT_KEY_NAME_CATEGORY_NAME_API_REF]: ACCOUNT_CATEGORY_NAME_CASH_N_BANK,
        },
        isAddDefaultInput: ({ transactionData = [] }) => {
          return (
            isEmpty(transactionData) || !transactionData[0][DEFAULT_KEY_NAME_ACCOUNT_ID_CONTAINER]
          );
        },
        isListData: true,
      },
    ],
    additionalSettingDefaultInputActionMenuUpdate: [
      {
        parentStateName: NAME_STORE.ACCOUNT,
        childrenListStateName: SLICE_NAME_ACCOUNT_DATA,
        keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
        keyNameSubResponseData: DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF,
        defaultKeyInputName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
        defaultKeyInputContainerName: DEFAULT_KEY_NAME_ACCOUNT_ID_CONTAINER,
        indexSelected: 0,
        action: getAccountList,
        additionalParams: {
          [DEFAULT_KEY_NAME_CATEGORY_NAME_API_REF]: ACCOUNT_CATEGORY_NAME_CASH_N_BANK,
        },
        isAddDefaultInput: ({ transactionData = [] }) => {
          return (
            isEmpty(transactionData) || !transactionData[0][DEFAULT_KEY_NAME_ACCOUNT_ID_CONTAINER]
          );
        },
        isListData: true,
      },
    ],
    additionalPropsSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        generateDataNValidationProfile: generateDataNValidationAddProfile,
        isDisabledContactType: [DEFAULT_CONTACT_IS_SUPPLIER_TYPE],
      },
    },
    additionalActionSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        parentStateName: NAME_STORE.CONTACT,
        childrenStateName: SLICE_NAME_TEMP_ADD_CONTACT_DATA,
        generalizeForState: ({
          resultSwitchDialog = {},
          keyStateContainer,
          keyValidationContainer,
          selectedIndex = 0,
          ...stateDataNvalidation
        }) => {
          let {
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          } = stateDataNvalidation;

          if (!isEmpty(resultSwitchDialog)) {
            const { id } = selectedStateContainer[selectedIndex];

            selectedStateContainer = arrHelp.combineDataObjInArray(
              selectedStateContainer,
              'id',
              id,
              {
                [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF]:
                  resultSwitchDialog[DEFAULT_KEY_NAME_CONTACT_ID_API_REF],
                [DEFAULT_KEY_NAME_SUPPLIER_CONTAINER_ID_API_REF]: resultSwitchDialog,
              },
            );

            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              selectedValidationContainer[0],
              [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF],
              true,
              false,
            );

            selectedValidationContainer = arrHelp.combineDataObjInArray(
              selectedValidationContainer,
              'id',
              id,
              newValidation,
            );
          }

          return {
            ...stateDataNvalidation,
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          };
        },
      },
    },
    generateDataForSettingDefaultInputActionMenuAdd: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    generateDataForSettingDefaultInputActionMenuUpdate: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    parentMenuURL: REACT_APP_PURCHASE_PURCHASE_PAYMENT_URL,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_QUOTATION_TYPE]: {
    defaultTransctionNumberCode: DEFAULT_TRANSACTION_NUMBER_CODE_SALES_QUOTATION,
    allowKeyInputTransactionData: allowKeysDataSalesQuotation,
    allowKeyInputProductTransctionData: allowKeysDataProductSalesQuotation,
    allowKeyInputChildrenTransactionData: allowKeysDataProductSalesQuotation,
    keyNameReferenceNumber: DEFAULT_KEY_NAME_REFERENCE_NUMBER,
    keyNameReferenceNumberContainer: DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER,
    keyNameTransactionDate: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
    keyNameAccountingPeriod: DEFAULT_KEY_NAME_ACCOUNTING_PERIOD,
    keyNameDiscountType: DEFAULT_KEY_NAME_DISCOUNT_TYPE_API_REF,
    keyNameDiscountPercentage: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
    keyNameDiscountNominal: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
    keyNameDiscountAccountID: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_API_REF,
    keyNameDiscountAccountIDcontainer: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_CONTAINER,
    keyNameDownPaymentAccountID: DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_API_REF,
    keyNameDownPaymentAccountIDcontainer: DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_CONTAINER,
    keyNameDownPaymentValue: DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE,
    keyNameExpenseAccountID: DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_API_REF,
    keyNameExpenseAccountIDcontainer: DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_CONTAINER,
    keyNameExpenseValue: DEFAULT_KEY_NAME_EXPENSE_VALUE,
    keyNameMaximumDownPaymentValue: defaultKeyNameMaximumDownPaymentValue,
    minimumLengthChildrenTransaction: 1,
    uniqueKeyNameChildrenTransaction: DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
    statusFreeUpdateInputTransaction: [DEFAULT_STATUS_SALES_QUOTATION_OPEN],
    errorMessagePrimaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.primary',
    errorMessageSecondaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.secondary',
    errorMessagePrimaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.primary',
    errorMessageSecondaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.secondary',
    errorMessagePrimaryInvalidForm: 'base.message.error.invalid-form.primary',
    errorMessageSecondaryInvalidForm: 'base.message.error.invalid-form.secondary',
    relationalKeyDiscountTypeNvalue: {
      [DEFAULT_DISCOUNT_PERCENTAGE_TYPE]: {
        keyInputName: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
        maximumValue: 100,
        representationDataType: DEFAULT_REPRESENTATION_DATA_NUMBER_TYPE,
      },
      [DEFAULT_DISCOUNT_NOMINAL_TYPE]: {
        keyInputName: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
        keyNameMaximumValue: DEFAULT_KEY_NAME_AMOUNT_CONST,
        representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
      },
    },
    isAdditionalCostShow: true,
    // isMinutesHandoverShow: true,
    // isFormStatusShow: true,
    additionalFetchProductList: {
      type: ['is_purchased'],
    },
    generateCreateDataNvalidationTransaction: ({ amount, companyUserData }) =>
      generateDataNValidationAddSalesQuotationV2({
        amount,
        companyUserData,
        customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
        customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
      }),
    generateCreateDataNvalidationChildrenTransaction: ({ amount }) =>
      generateDataNValidationAddProductSQ(
        amount,
        DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
        DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
      ),
    generalizeDataAddTransaction: generalizeDataAddSalesQuotation,
    primaryAddUpdateDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          placeholder: 'placeholder.input-auto',
          inputName: 'transaction_number',
          storeName: ROOT_STORE_NAME_REFERENCE_NUMBER,
          childrenStoreName: SLICE_NAME_REFERENCE_NUMBER_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_REFERENCE_NUMBER,
          keyInputContainerName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: referenceNumberList,
            additionalParamsForAction: {},
            removeDataAction: clearReferenceNumber,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['code', 'format'],
                ['code'],
                formatHelp.formatTransactionNumber,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'code',
          },
          inputProps: {
            maxLength: DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE,
          },
          isDisabled: ({
            actionMenu,
            parentStateList = {},
            transactionData,
            statusFreeUpdateInputTransaction = [],
          }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE ||
              parentStateList.statusRequest ===
                STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_PENDING ||
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                !statusFreeUpdateInputTransaction.includes(status))
            );
          },
          isUseTextInput: true,
        },
        {
          label: 'label.transaction-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: 'transaction_date',
          keyInputName: 'transaction_date',
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
          isAddOneDayForMinDate: false,
          isRequired: true,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
        {
          label: 'label.expired-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: 'expired_date',
          keyInputName: 'expired_date',
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          keyNameMinDate: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
          isAddOneDayForMinDate: false,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
      ],
      [
        {
          label: 'label.contact-customer',
          placeholder: 'placeholder.select-contact-customer',
          inputName: 'contact_name',
          storeName: NAME_STORE.CONTACT,
          childrenStoreName: SLICE_NAME_CONTACT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_CUSTOMER_CONTAINER_ID_API_REF,
          statusRequestListPending: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: contactList,
            additionalParamsForAction: {
              type: ['is_customer'],
            },
            removeDataAction: clearContactData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              type: ['is_customer'],
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['contact_code', 'contact_name'],
                ['contact_name'],
                formatHelp.contactCodeWithName,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'contact_name',
          },
          isUseAdditionalButtonNotFound: true,
          additionalButtonNotFoundProps: {
            textNotFound: 'button.plus-add-new-customer',
            keyNameIsNew: DEFAULT_KEY_NAME_IS_NEW_CONTACT_DATA,
            keynameSwitchDialog: 'contact-data',
            newPageStatus: DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA,
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
        {
          label: 'label.customer-address',
          placeholder: 'placeholder.customer-address',
          inputName: 'contact_address',
          keyInputName: 'customer_address',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          gridProps: defaultNineGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
      ],
    ],
    secondaryAddUpdateDataSchema: [
      [
        {
          label: 'label.salesman-constant',
          placeholder: 'placeholder.salesman-constant',
          inputName: 'sales_agent_name',
          storeName: NAME_STORE.CONTACT,
          childrenStoreName: SLICE_NAME_CONTACT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputName: 'sales_agent_id',
          keyInputContainerName: 'sales_agent_id_container',
          statusRequestListPending: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: contactList,
            additionalParamsForAction: {
              type: ['is_employee'],
            },
            removeDataAction: clearContactData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              type: ['is_employee'],
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['contact_code', 'contact_name'],
                ['contact_name'],
                formatHelp.contactCodeWithName,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'contact_name',
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
        {
          label: 'label.total',
          name: 'amount',
          inputName: 'amount',
          keyInputName: 'amount',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
          isTypography: true,
        },
      ],
      [
        // tagAutocompleteInputProps,
        {
          usageInputLabel: false,
          keyInputName: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DROPZONE,
          additionalDropzoneProps: {
            generalizeRequestAction: (attachmentFile, relationValueToOtherAttachment) => {
              return generalizeAttachmentRequest({
                attachmentFile,
                relationValueToOtherAttachment,
                featureDocument: DEFAULT_TRANSACTION_SALES_SALES_QUOTATION_TYPE,
              });
            },
          },
          gridContainerProps: {
            width: 'calc(100% - 2px)',
          },
        },
        {
          label: 'label.memo',
          placeholder: 'placeholder.memo',
          inputName: 'memo',
          keyInputName: 'description',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          additionalOutlinedInput: {
            rows: 3,
            multiline: true,
            className: 'none-padding',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    inputValueChanges: {
      [DEFAULT_KEY_NAME_REFERENCE_NUMBER]: {
        generateChanges: ({ newValue }) => {
          return {
            resultChanges: {
              [DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER]: {
                code: newValue,
              },
              [DEFAULT_KEY_NAME_REFERENCE_NUMBER]: newValue,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_PRICE_API_REF]: {
        generateChanges: ({
          newValue,
          transactionData = [],
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const amountTransactionData = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const { quantity, amount: amountPast } = selectedChildrenTransactionData;

          const amountChildrenTransactionWithoutPast = amountTransactionData - amountPast;
          const currentAmountSelectedChildrenTransaction = Number(quantity) * Number(newValue);

          const { discount_nominal, discount_percentage } = formulaCountDiscountCost({
            allAmountTransaction:
              amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction,
            transactionData,
          });

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction:
              amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: currentAmountSelectedChildrenTransaction,
            },
            transactionDataChanges: {
              amount: Number(newAmountTransaction),
              amount_const: Number(newAmountTransaction),
              discount_nominal: Number(discount_nominal) || '',
              discount_percentage: Number(discount_percentage) || '',
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_QUANTITY_API_REF]: {
        generateChanges: ({
          newValue,
          transactionData = [],
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const amountTransactionData = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const {
            price,
            amount: amountPast,
            quantity_comparison,
          } = selectedChildrenTransactionData;

          const amountChildrenTransactionWithoutPast = amountTransactionData - amountPast;
          const currentAmountSelectedChildrenTransaction = Number(price) * Number(newValue);

          const { discount_nominal, discount_percentage } = formulaCountDiscountCost({
            allAmountTransaction:
              amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction,
            transactionData,
          });

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction:
              amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: currentAmountSelectedChildrenTransaction,
              quantity_origin: quantity_comparison * Number(newValue),
            },
            transactionDataChanges: {
              amount: Number(newAmountTransaction),
              amount_const: Number(newAmountTransaction),
              discount_nominal: Number(discount_nominal) || '',
              discount_percentage: Number(discount_percentage) || '',
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          let newDiscountNominal = Number(amountChildrenTransaction) * (Number(newValue) / 100);

          newDiscountNominal = Number(newDiscountNominal).toFixed(2);

          const { expense_value, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: newDiscountNominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
              [DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF]: Number(newDiscountNominal),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: newValue,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          const newDiscountPercentage = amountChildrenTransaction
            ? Number((Number(newValue) / Number(amountChildrenTransaction)) * 100).toFixed(2)
            : 0;

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
              [DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF]: Number(newDiscountPercentage),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_EXPENSE_VALUE]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { discount_nominal, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: newValue,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { discount_nominal, expense_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: newValue,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
            },
          };
        },
      },
    },
    autocompleteValueChanges: {
      [DEFAULT_KEY_NAME_PRODUCT_ID_API_REF]: {
        isCanChangeAllState: true,
        generateChanges: ({
          newValue,
          transactionData = [],
          transactionValidation,
          childrenTransactionData = [],
          childrenTransactionValidation,
          idChildrenTransaction = '',
        }) => {
          const quantityConstKeyname = DEFAULT_KEY_NAME_QUANTITY_REAL_API_REF;
          const { product_description, sales_price, unit, product_unit, is_stock_monitored } =
            newValue || {};

          const quantityConst = !isEmpty(newValue) ? newValue[quantityConstKeyname] : 0;

          const quantity_available_const =
            !isEmpty(newValue) || (!is_stock_monitored && is_stock_monitored !== undefined)
              ? quantityConst !== undefined
                ? Number(quantityConst)
                : DEFAULT_QUANTITY_AVAILABLE_STOCK_NOT_MONITORED_PRODUCT
              : DEFAULT_QUANTITY_AVAILABLE_STOCK_MONITORED_PRODUCT;

          const quantity =
            Number(quantityConst) || (!is_stock_monitored && is_stock_monitored !== undefined)
              ? DEFAULT_QUANTITY_AVAILABLE_STOCK_NOT_MONITORED_PRODUCT
              : DEFAULT_QUANTITY_AVAILABLE_STOCK_MONITORED_PRODUCT;

          // amount current selected transaction data
          const amount =
            Number(sales_price) && Number(quantity)
              ? Number(sales_price) * Number(quantity)
              : DEFAULT_CALCULATION_NUMBER;

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const sumAmountsChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const {
            discount_percentage,
            discount_nominal,
            expense_value,
            down_payment_value,
            discount_type,
          } = transactionData[0];

          const { amount: amountChildrenTransaction } = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          let newAmountTransactionData = !isEmpty(newValue)
            ? Number(sumAmountsChildrenTransaction) -
              Number(amountChildrenTransaction) +
              Number(amount)
            : Number(sumAmountsChildrenTransaction) - Number(amountChildrenTransaction);

          let discountNominal =
            discount_type === DEFAULT_DISCOUNT_NOMINAL_TYPE
              ? discount_nominal
              : (Number(discount_percentage) / Number(100)) * Number(newAmountTransactionData);

          discountNominal = Number(discountNominal).toFixed(2);

          if (Number(discountNominal) > Number(newAmountTransactionData))
            discountNominal = Number(newAmountTransactionData);

          let discountPercentage =
            discount_type === DEFAULT_DISCOUNT_PERCENTAGE_TYPE
              ? discount_percentage
              : newAmountTransactionData
              ? (Number(discountNominal) / Number(newAmountTransactionData)) * 100
              : 0;

          discountPercentage = Number(discountPercentage).toFixed(2);

          const expenseValue = Number(newAmountTransactionData) <= 0 ? '' : expense_value;

          const downPaymentValue = Number(newAmountTransactionData) <= 0 ? '' : down_payment_value;

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: newAmountTransactionData,
            discountNominal,
            expenseValue,
            downPaymentValue,
          });

          const usagedUnitProduct =
            hasImplementedUnitProductUnit && unit ? unit : product_unit || '';

          const quantityAvailableWithUnit = usagedUnitProduct
            ? `${quantity_available_const} ${usagedUnitProduct}`
            : '';

          const childrenTransactionDataChanges = {
            price: Number(sales_price) || '',
            product_description: product_description || '',
            quantity: Number(quantity) || '',
            quantity_origin: Number(quantity) || '',
            quantity_comparison: 1,
            unit_destination: usagedUnitProduct,
            unit_origin: usagedUnitProduct,
            quantity_available_with_unit: quantityAvailableWithUnit,
            amount: Number(amount) || '',
            quantity_const: quantityConst,
          };

          const transactionDataChanges = {
            amount: Number(newAmountTransaction),
            discount_nominal: Number(discountNominal) || '',
            discount_percentage: Number(discountPercentage) || '',
            expense_value: expenseValue,
            down_payment_value: downPaymentValue,
            amount_const: Number(newAmountTransactionData),
          };

          transactionData = [
            {
              ...transactionData[0],
              ...transactionDataChanges,
            },
          ];

          childrenTransactionData = arrHelp.combineDataObjInArray(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
            childrenTransactionDataChanges,
          );

          if (!isEmpty(childrenTransactionValidation)) {
            const isErrorValidation = !Number(amount);
            const selectedChildrenTransactionValidation = arrHelp.filterObjDataWithID(
              childrenTransactionValidation,
              'id',
              idChildrenTransaction,
            );

            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              selectedChildrenTransactionValidation,
              ['quantity'],
              true,
              isErrorValidation,
            );

            childrenTransactionValidation = arrHelp.combineDataObjInArray(
              childrenTransactionValidation,
              'id',
              idChildrenTransaction,
              newValidation,
            );
          }

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
      [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF]: {
        isHitEndpoint: false,
        isCanChangeAllState: false,
        generateChanges: ({ newValue }) => {
          const customerAddress = newValue
            ? newValue[DEFAULT_KEY_NAME_SHIPPING_ADDRESS_API_REF] || ''
            : '';

          return {
            resultChanges: {
              customer_address: customerAddress,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF]: {
        ...getChangesAutocompleteForUnitProductTransactionPropertiesPrimary({
          transactionType: DEFAULT_TRANSACTION_SALES_TYPE,
          subTransactionType: DEFAULT_TRANSACTION_SALES_SALES_QUOTATION_TYPE,
        }),
      },
    },
    columnsTableHead: (t) => columnsTableHeaderAddUpdateSalesQuotationV2(t),
    gridHeaderChildrenTransaction: gridHeaderProductSalesQuotation,
    gridCollapseChildrenTransaction: gridCollapseProductSalesQuotation,
    dependingKeyNameTransactionValidation: ['transaction_date', 'expired_date'], // validation form on transaction
    anotherKeyNameTransactionDataDepends: [
      DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
      'transaction_date',
    ], // validation form on data transaction
    keyNameBypassChildrenTransactionValidation: [
      'product_id',
      'quantity',
      DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
    ],
    checkingIsEmptyChildrenTransactionKeyNameBypass: [
      [null, ''],
      DEFAULT_EMPTY_CALCULATIONS_NUMBER,
      [null, ''],
    ],
    keyNameCheckingValidationInputBypass: [
      'quantity',
      'price',
      DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
    ],
    keyNameCheckingStatusChildrenTransaction: ['product_id'],
    menuActionUpdateProps: {
      [DEFAULT_KEY_NAME_PARAMS_URL_SALES_QUOTATION_ID]: {
        getAction: (paramsRouter) =>
          getDetailSalesQuotation(paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_SALES_QUOTATION_ID]),
        // dependencies action when after getting primary action (above action)
        onDependenciesActions: [defaultOnDependenciesListDetailsAttachmentAction],
        parentStateName: NAME_STORE.SALES,
        childrenStateName: SLICE_NAME_SALES_QUOTATION_DETAILS,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNValidationUpdateSalesQuotationV2({
            salesQuotationDataSelected: resultGetAction,
            ...params,
            customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: (resultGetAction) => {
          const { updateProductData, updateProductValidation } =
            generateDataNValidationUpdateProductSQ(resultGetAction);

          return {
            [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: updateProductData,
            [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION]: updateProductValidation,
          };
        },
      },
    },
    additionalSettingDefaultInputActionMenuAdd: [
      clearDataProductSupplierAction,
      ...defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_SALES_TYPE],
    ],
    additionalSettingDefaultInputActionMenuUpdate: [
      clearDataProductSupplierAction,
      ...defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_SALES_TYPE],
    ],
    generateDataForSettingDefaultInputActionMenuAdd: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    generateDataForSettingDefaultInputActionMenuUpdate: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    additionalPropsSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        generateDataNValidationProfile: generateDataNValidationAddProfileForIsCustomer,
        isDisabledContactType: [DEFAULT_CONTACT_IS_CUSTOMER_TYPE],
      },
    },
    additionalActionSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        parentStateName: NAME_STORE.CONTACT,
        childrenStateName: SLICE_NAME_TEMP_ADD_CONTACT_DATA,
        generalizeForState: ({
          resultSwitchDialog = {},
          keyStateContainer,
          keyValidationContainer,
          selectedIndex = 0,
          ...stateDataNvalidation
        }) => {
          let {
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          } = stateDataNvalidation;

          if (!isEmpty(resultSwitchDialog)) {
            const { id } = selectedStateContainer[selectedIndex];

            selectedStateContainer = arrHelp.combineDataObjInArray(
              selectedStateContainer,
              'id',
              id,
              {
                [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF]:
                  resultSwitchDialog[DEFAULT_KEY_NAME_CONTACT_ID_API_REF],
                [DEFAULT_KEY_NAME_CUSTOMER_CONTAINER_ID_API_REF]: resultSwitchDialog,
              },
            );

            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              selectedValidationContainer[0],
              [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF],
              true,
              false,
            );

            selectedValidationContainer = arrHelp.combineDataObjInArray(
              selectedValidationContainer,
              'id',
              id,
              newValidation,
            );
          }

          return {
            ...stateDataNvalidation,
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          };
        },
      },
    },
    parentMenuURL: REACT_APP_SALES_QUOTATION_URL,
    handleDeleteRow: {
      childrenTransactionData: ({
        transactionData,
        childrenTransactionData,
        childrenTransactionValidation,
        childrenTransactionID = '',
      }) => {
        const {
          childrenTransactionData: currChildrenTransactionData,
          childrenTransactionValidation: currChildrenTransactionValidation,
        } = filteringChildrenTransactionData({
          childrenTransactionData,
          childrenTransactionValidation,
          childrenTransactionDataID: childrenTransactionID,
        });

        return {
          childrenTransactionData: currChildrenTransactionData,
          childrenTransactionValidation: currChildrenTransactionValidation,
          transactionData: [
            {
              ...transactionData[0],
              ...formulaHelpers.calculateDiscountAndTotalOfChildrenData({
                parentData: transactionData[0],
                childrenData: currChildrenTransactionData,
              }),
            },
          ],
        };
      },
    },
  },
  [DEFAULT_TRANSACTION_SALES_SALES_ORDER_TYPE]: {
    defaultTransctionNumberCode: DEFAULT_TRANSACTION_NUMBER_CODE_SALES_ORDER,
    allowKeyInputTransactionData: allowKeysSalesOrderData,
    allowKeyInputProductTransctionData: allowKeysProductSalesOrderData,
    allowKeyInputChildrenTransactionData: allowKeysProductSalesOrderData,
    keyNameReferenceNumber: DEFAULT_KEY_NAME_REFERENCE_NUMBER,
    keyNameReferenceNumberContainer: DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER,
    keyNameTransactionDate: DEFAULT_KEY_NAME_DELIVERY_DATE_API_REF,
    keyNameAccountingPeriod: DEFAULT_KEY_NAME_ACCOUNTING_PERIOD,
    keyNameDiscountType: DEFAULT_KEY_NAME_DISCOUNT_TYPE_API_REF,
    keyNameDiscountPercentage: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
    keyNameDiscountNominal: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
    keyNameDiscountAccountID: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_API_REF,
    keyNameDiscountAccountIDcontainer: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_CONTAINER,
    keyNameDownPaymentAccountID: DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_API_REF,
    keyNameDownPaymentAccountIDcontainer: DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_CONTAINER,
    keyNameDownPaymentValue: DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE,
    keyNameExpenseAccountID: DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_API_REF,
    keyNameExpenseAccountIDcontainer: DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_CONTAINER,
    keyNameExpenseValue: DEFAULT_KEY_NAME_EXPENSE_VALUE,
    keyNameMaximumDownPaymentValue: defaultKeyNameMaximumDownPaymentValue,
    minimumLengthChildrenTransaction: 1,
    uniqueKeyNameChildrenTransaction: DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
    statusFreeUpdateInputTransaction: [DEFAULT_STATUS_SALES_ORDER_OPEN],
    errorMessagePrimaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.primary',
    errorMessageSecondaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.secondary',
    errorMessagePrimaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.primary',
    errorMessageSecondaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.secondary',
    errorMessagePrimaryInvalidForm: 'base.message.error.invalid-form.primary',
    errorMessageSecondaryInvalidForm: 'base.message.error.invalid-form.secondary',
    relationalKeyDiscountTypeNvalue: {
      [DEFAULT_DISCOUNT_PERCENTAGE_TYPE]: {
        keyInputName: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
        maximumValue: 100,
        representationDataType: DEFAULT_REPRESENTATION_DATA_NUMBER_TYPE,
      },
      [DEFAULT_DISCOUNT_NOMINAL_TYPE]: {
        keyInputName: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
        keyNameMaximumValue: DEFAULT_KEY_NAME_AMOUNT_CONST,
        representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
      },
    },
    isAdditionalCostShow: true,
    generateCreateDataNvalidationTransaction: ({ amount, companyUserData }) =>
      generateDataNValidationAddSalesOrderV2({
        amount,
        companyUserData,
        customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
        customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
      }),
    generateCreateDataNvalidationChildrenTransaction: ({ amount }) =>
      generateDataNValidationAddProductSalesOrderV2({
        amount,
        customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
        customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
      }),
    generalizeDataAddTransaction: generalizeDataAddSalesOrder,
    primaryAddUpdateDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          placeholder: 'placeholder.input-auto',
          inputName: 'transaction_number',
          storeName: ROOT_STORE_NAME_REFERENCE_NUMBER,
          childrenStoreName: SLICE_NAME_REFERENCE_NUMBER_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_REFERENCE_NUMBER,
          keyInputContainerName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultSecondPointQuarterGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: referenceNumberList,
            additionalParamsForAction: {},
            removeDataAction: clearReferenceNumber,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['code', 'format'],
                ['value'],
                formatHelp.formatTransactionNumber,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'code',
          },
          inputProps: {
            maxLength: DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE,
          },
          isDisabled: ({
            actionMenu,
            parentStateList = {},
            transactionData,
            statusFreeUpdateInputTransaction = [],
          }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE ||
              parentStateList.statusRequest ===
                STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_PENDING ||
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                !statusFreeUpdateInputTransaction.includes(status))
            );
          },
          isUseTextInput: true,
        },
        {
          label: 'label.quotation-number',
          placeholder: 'placeholder.select-quotation-number',
          inputName: 'quotation_number',
          keyInputName: 'sales_quotation_id',
          gridProps: defaultSecondPointQuarterGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          storeName: NAME_STORE.SALES,
          childrenStoreName: SLICE_NAME_SALES_QUOTATION_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_SALES_QUOTATION_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_SALES_QUOTATION_ID_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_SALES_QUOTATION_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          getAdditionalParamsForAction: ({ transactionData = [] }) => {
            if (isEmpty(transactionData)) return {};

            const { customer_id_container, sales_quotation_id } = transactionData[0];

            if (sales_quotation_id && customer_id_container) return {};

            return {
              customer_name: !isEmpty(customer_id_container)
                ? customer_id_container.contact_name
                : '',
            };
          },
          autocompleteProps: {
            fetchAction: getListSalesQuotation,
            additionalParamsForAction: {
              status: [DEFAULT_STATUS_SALES_QUOTATION_OPEN],
            },
            removeDataAction: clearSalesQuotationData,
            additionalParamsForScrollAction: {
              status: [DEFAULT_STATUS_SALES_QUOTATION_OPEN],
              isRewriteAll: false,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getOptionLabel(options, 'transaction_no'),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'transaction_no',
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
          inputProps: {
            maxLength: DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE,
          },
          isUseTextInput: false,
          isRequired: true,
        },
        {
          label: 'label.transaction-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: 'transaction_date',
          keyInputName: 'transaction_date',
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultSecondPointQuarterGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
          isAddOneDayForMinDate: false,
          isRequired: true,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
        {
          label: 'label.expired-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: 'expired_date',
          keyInputName: 'expired_date',
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultSecondPointQuarterGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: 'transaction_date',
          isAddOneDayForMinDate: true,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
        {
          label: 'label.warehouse',
          placeholder: 'placeholder.select-warehouse',
          inputName: 'warehouse_name',
          keyInputName: 'warehouse_id',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          storeName: NAME_STORE.WAREHOUSE,
          childrenStoreName: SLICE_NAME_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_WAREHOUSE_ID_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          autocompleteProps: {
            fetchAction: getWarehouseListWithDynamicStore,
            additionalParamsForAction: DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE,
            removeDataAction: clearWarehouseDataSlice,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            additionalParamsForRemoveDataAction: DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE,
            getOptionLabel: (options) =>
              autocompleteHelper.getOptionLabel(options, 'warehouse_name'),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'warehouse_name',
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
          isUseTextInput: false,
        },
      ],
      [
        {
          label: 'label.contact-customer',
          placeholder: 'placeholder.select-contact-customer',
          inputName: 'contact_name',
          storeName: NAME_STORE.CONTACT,
          childrenStoreName: SLICE_NAME_CONTACT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_CUSTOMER_CONTAINER_ID_API_REF,
          statusRequestListPending: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultFourthPointHalfGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: contactList,
            additionalParamsForAction: {
              type: ['is_customer'],
            },
            removeDataAction: clearContactData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              type: ['is_customer'],
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['contact_code', 'contact_name'],
                ['contact_name'],
                formatHelp.contactCodeWithName,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'contact_name',
          },
          isUseAdditionalButtonNotFound: true,
          additionalButtonNotFoundProps: {
            textNotFound: 'button.plus-add-new-customer',
            keyNameIsNew: DEFAULT_KEY_NAME_IS_NEW_CONTACT_DATA,
            keynameSwitchDialog: 'contact-data',
            newPageStatus: DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA,
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
        {
          label: 'label.customer-address',
          placeholder: 'placeholder.customer-address',
          inputName: 'contact_address',
          keyInputName: 'customer_address',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          gridProps: defaultSeventPointHalfGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
      ],
    ],
    secondaryAddUpdateDataSchema: [
      [
        {
          label: 'label.salesman-constant',
          placeholder: 'placeholder.salesman-constant',
          inputName: 'sales_agent_name',
          storeName: NAME_STORE.CONTACT,
          childrenStoreName: SLICE_NAME_CONTACT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputName: 'sales_agent_id',
          keyInputContainerName: 'sales_agent_id_container',
          statusRequestListPending: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: contactList,
            additionalParamsForAction: {
              type: ['is_employee'],
            },
            removeDataAction: clearContactData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              type: ['is_employee'],
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['contact_code', 'contact_name'],
                ['contact_name'],
                formatHelp.contactCodeWithName,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'contact_name',
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
        {
          label: 'label.total',
          name: 'amount',
          inputName: 'amount',
          keyInputName: 'amount',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
          isTypography: true,
        },
      ],
      [
        // tagAutocompleteInputProps,
        {
          usageInputLabel: false,
          keyInputName: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DROPZONE,
          additionalDropzoneProps: {
            generalizeRequestAction: (attachmentFile, relationValueToOtherAttachment) => {
              return generalizeAttachmentRequest({
                attachmentFile,
                relationValueToOtherAttachment,
                featureDocument: DEFAULT_TRANSACTION_SALES_SALES_ORDER_TYPE,
              });
            },
          },
          gridContainerProps: {
            width: 'calc(100% - 2px)',
          },
        },
        {
          label: 'label.memo',
          placeholder: 'placeholder.memo',
          inputName: 'memo',
          keyInputName: 'description',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          additionalOutlinedInput: {
            rows: 3,
            multiline: true,
            className: 'none-padding',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    menuActionAddProps: {
      [DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_QUOTATION_DATA]: {
        getAction: (searchParams) =>
          getDetailSalesQuotation(searchParams[DEFAULT_KEY_NAME_SALES_QUOTATION_ID_API_REF]),
        parentStateName: NAME_STORE.SALES,
        childrenStateName: SLICE_NAME_SALES_QUOTATION_DETAILS,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNValidationSalesOrderFromSalesQuotationDetailsV2({
            salesQuotationDetails: resultGetAction,
            ...params,
            customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: ({ resultGetAction }) =>
          generateDataNValidationProductSalesOrderFromSalesQuotationDetailsV2({
            salesQuotationDetails: resultGetAction,
            customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
          }),
      },
    },
    inputValueChanges: {
      [DEFAULT_KEY_NAME_REFERENCE_NUMBER]: {
        generateChanges: ({ newValue }) => {
          return {
            resultChanges: {
              [DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER]: {
                value: newValue,
              },
              [DEFAULT_KEY_NAME_REFERENCE_NUMBER]: newValue,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_PRICE_API_REF]: {
        generateChanges: ({
          newValue,
          transactionData = [],
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const amountTransactionData = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const { quantity, amount: amountPast } = selectedChildrenTransactionData;

          const amountChildrenTransactionWithoutPast = amountTransactionData - amountPast;
          const currentAmountSelectedChildrenTransaction = Number(quantity) * Number(newValue);

          const { discount_nominal, discount_percentage } = formulaCountDiscountCost({
            transactionData,
            allAmountTransaction:
              amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction,
          });

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction:
              amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: currentAmountSelectedChildrenTransaction,
            },
            transactionDataChanges: {
              amount: Number(newAmountTransaction),
              amount_const: Number(newAmountTransaction),
              discount_nominal: Number(discount_nominal) || '',
              discount_percentage: Number(discount_percentage) || '',
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_QUANTITY_API_REF]: {
        generateChanges: ({
          newValue,
          transactionData = [],
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const amountTransactionData = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const {
            price,
            amount: amountPast,
            quantity_comparison,
          } = selectedChildrenTransactionData;

          const amountChildrenTransactionWithoutPast = amountTransactionData - amountPast;
          const currentAmountSelectedChildrenTransaction = Number(price) * Number(newValue);

          const { discount_nominal, discount_percentage } = formulaCountDiscountCost({
            transactionData,
            allAmountTransaction:
              amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction,
          });

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction:
              amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: currentAmountSelectedChildrenTransaction,
              quantity_origin: Number(newValue) * quantity_comparison,
            },
            transactionDataChanges: {
              amount: Number(newAmountTransaction),
              amount_const: Number(newAmountTransaction),
              discount_nominal: Number(discount_nominal) || '',
              discount_percentage: Number(discount_percentage) || '',
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          let newDiscountNominal = Number(amountChildrenTransaction) * (Number(newValue) / 100);

          newDiscountNominal = Number(newDiscountNominal).toFixed(2);

          const { expense_value, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: newDiscountNominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
              [DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF]: Number(newDiscountNominal),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: newValue,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          const newDiscountPercentage = amountChildrenTransaction
            ? Number((Number(newValue) / Number(amountChildrenTransaction)) * 100).toFixed(2)
            : 0;

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
              [DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF]: Number(newDiscountPercentage),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_EXPENSE_VALUE]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { discount_nominal, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: newValue,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { discount_nominal, expense_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: newValue,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
            },
          };
        },
      },
    },
    autocompleteValueChanges: {
      [DEFAULT_KEY_NAME_PRODUCT_ID_API_REF]: {
        isCanChangeAllState: true,
        generateChanges: ({
          newValue,
          transactionData = [],
          transactionValidation = [],
          childrenTransactionData = [],
          childrenTransactionValidation = [],
          idChildrenTransaction = '',
        }) => {
          const quantityConstKeyname = DEFAULT_KEY_NAME_QUANTITY_REAL_API_REF;

          const { product_description, sales_price, unit, product_unit, is_stock_monitored } =
            newValue || {};

          const quantityConst = !isEmpty(newValue) ? newValue[quantityConstKeyname] : 0;

          const quantity_const =
            !isEmpty(newValue) || (!is_stock_monitored && is_stock_monitored !== undefined)
              ? newValue[quantityConstKeyname] !== undefined
                ? Number(newValue[quantityConstKeyname])
                : DEFAULT_QUANTITY_AVAILABLE_STOCK_NOT_MONITORED_PRODUCT
              : DEFAULT_QUANTITY_AVAILABLE_STOCK_MONITORED_PRODUCT;

          const quantity =
            Number(quantityConst) || (!is_stock_monitored && is_stock_monitored !== undefined)
              ? DEFAULT_QUANTITY_AVAILABLE_STOCK_NOT_MONITORED_PRODUCT
              : DEFAULT_QUANTITY_AVAILABLE_STOCK_MONITORED_PRODUCT;

          // amount current selected transaction data
          const amount =
            Number(sales_price) && Number(quantity)
              ? Number(sales_price) * Number(quantity)
              : DEFAULT_CALCULATION_NUMBER;

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const sumAmountsChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const {
            discount_percentage,
            discount_nominal,
            expense_value,
            down_payment_value,
            discount_type,
          } = transactionData[0];

          const { amount: amountChildrenTransaction } = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          let newAmountTransactionData = !isEmpty(newValue)
            ? Number(sumAmountsChildrenTransaction) -
              Number(amountChildrenTransaction) +
              Number(amount)
            : Number(sumAmountsChildrenTransaction) - Number(amountChildrenTransaction);

          let discountNominal =
            discount_type === DEFAULT_DISCOUNT_NOMINAL_TYPE
              ? discount_nominal
              : (Number(discount_percentage) / Number(100)) * Number(newAmountTransactionData);

          discountNominal = Number(discountNominal).toFixed(2);

          if (Number(discountNominal) > Number(newAmountTransactionData))
            discountNominal = Number(newAmountTransactionData);

          let discountPercentage =
            discount_type === DEFAULT_DISCOUNT_PERCENTAGE_TYPE
              ? discount_percentage
              : newAmountTransactionData
              ? (Number(discountNominal) / Number(newAmountTransactionData)) * 100
              : 0;

          discountPercentage = Number(discountPercentage).toFixed(2);

          const expenseValue = Number(newAmountTransactionData) <= 0 ? '' : expense_value;

          const downPaymentValue = Number(newAmountTransactionData) <= 0 ? '' : down_payment_value;

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: newAmountTransactionData,
            discountNominal,
            expenseValue,
            downPaymentValue,
          });

          const usagedUnitProduct =
            hasImplementedUnitProductUnit && unit ? unit : product_unit || '';

          const quantityAvailableWithUnit = usagedUnitProduct
            ? `${quantity_const} ${usagedUnitProduct}`
            : '';

          const childrenTransactionDataChanges = {
            price: Number(sales_price) || '',
            description: product_description || '',
            quantity: Number(quantity) || '',
            quantity_origin: Number(quantity) || '',
            quantity_comparison: 1,
            unit: usagedUnitProduct,
            unit_destination: usagedUnitProduct,
            unit_origin: usagedUnitProduct,
            quantity_available_with_unit: quantityAvailableWithUnit,
            amount: Number(amount) || '',
            quantity_const,
          };

          const transactionDataChanges = {
            amount: Number(newAmountTransaction),
            discount_nominal: Number(discountNominal) || '',
            discount_percentage: Number(discountPercentage) || '',
            expense_value: expenseValue,
            down_payment_value: downPaymentValue,
            amount_const: Number(newAmountTransactionData),
          };

          transactionData = [
            {
              ...transactionData[0],
              ...transactionDataChanges,
            },
          ];

          childrenTransactionData = arrHelp.combineDataObjInArray(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
            childrenTransactionDataChanges,
          );

          if (!isEmpty(childrenTransactionValidation)) {
            const isErrorValidation = !Number(amount);
            const selectedChildrenTransactionValidation = arrHelp.filterObjDataWithID(
              childrenTransactionValidation,
              'id',
              idChildrenTransaction,
            );

            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              selectedChildrenTransactionValidation,
              ['quantity'],
              true,
              isErrorValidation,
            );

            childrenTransactionValidation = arrHelp.combineDataObjInArray(
              childrenTransactionValidation,
              'id',
              idChildrenTransaction,
              newValidation,
            );
          }

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
      [DEFAULT_KEY_NAME_SALES_QUOTATION_ID_API_REF]: {
        isHitEndpoint: true,
        action: getDetailSalesQuotation,
        actionRemove: () => clearDataSalesSlice(DEFAULT_PAYLOAD_STORAGE_SALES_QUOTATION_DETAIL),
        storeName: NAME_STORE.SALES,
        childrenSliceName: SLICE_NAME_SALES_QUOTATION_DETAIL,
        generateParamsAction: ({ newAutocompleteChanges = {} }) => {
          return newAutocompleteChanges[DEFAULT_KEY_NAME_SALES_QUOTATION_ID_API_REF] || '';
        },
        generateChanges: ({ resultGetAction, companyUserData, currentTransactionData }) => {
          const { transactionData, transactionValidation } =
            generateDataNValidationSalesOrderFromSalesQuotationDetailsV2({
              salesQuotationDetails: resultGetAction,
              companyUserData,
              currentSalesOrderData: currentTransactionData,
              customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
              customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
            });

          const { childrenTransactionData, childrenTransactionValidation } =
            generateDataNValidationProductSalesOrderFromSalesQuotationDetailsV2({
              salesQuotationDetails: resultGetAction,
              customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
              customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
            });

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
      [DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF]: {
        isCanChangeAllState: true,
        generateChanges: ({ newValue, transactionData, transactionValidation }) => {
          if (!isEmpty(transactionValidation)) {
            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              transactionValidation[0],
              [DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF],
              true,
              isEmpty(newValue),
            );

            transactionValidation = [newValidation];
          }

          const { childrenTransactionData, childrenTransactionValidation } =
            generateDataNValidationAddProductSalesOrderV2({
              amount: 2,
              customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
              customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
            });

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
      [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF]: {
        isHitEndpoint: false,
        isCanChangeAllState: false,
        generateChanges: ({ newValue }) => {
          const customerAddress = newValue
            ? newValue[DEFAULT_KEY_NAME_SHIPPING_ADDRESS_API_REF] || ''
            : '';

          return {
            resultChanges: {
              customer_address: customerAddress,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF]: {
        ...getChangesAutocompleteForUnitProductTransactionPropertiesPrimary({
          transactionType: DEFAULT_TRANSACTION_SALES_TYPE,
          subTransactionType: DEFAULT_TRANSACTION_SALES_SALES_ORDER_TYPE,
        }),
      },
    },
    columnsTableHead: (t) => columnsTableHeaderAddUpdateSalesQuotationV2(t),
    gridHeaderChildrenTransaction: gridHeaderProductSalesOrder,
    gridCollapseChildrenTransaction: gridCollapseProductSalesOrder,
    dependingKeyNameTransactionValidation: ['transaction_date', 'expired_date'], // validation form on transaction
    anotherKeyNameTransactionDataDepends: [
      DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
      'transaction_date',
    ], // validation form on data transaction
    keyNameBypassChildrenTransactionValidation: [
      'product_id',
      'quantity',
      DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
    ],
    checkingIsEmptyChildrenTransactionKeyNameBypass: [
      [null, ''],
      DEFAULT_EMPTY_CALCULATIONS_NUMBER,
      [null, ''],
    ],
    keyNameCheckingValidationInputBypass: [
      'quantity',
      'price',
      DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
    ],
    keyNameCheckingStatusChildrenTransaction: ['product_id'],
    menuActionUpdateProps: {
      [DEFAULT_KEY_NAME_PARAMS_URL_SALES_ORDER_ID]: {
        getAction: (paramsRouter) =>
          getDetailSalesOrder({
            [DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF]:
              paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_SALES_ORDER_ID],
          }),
        // dependencies action when after getting primary action (above action)
        onDependenciesActions: [defaultOnDependenciesListDetailsAttachmentAction],
        parentStateName: NAME_STORE.SALES,
        childrenStateName: SLICE_NAME_SALES_ORDER_DETAILS,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNValidationUpdateSalesOrderFromSalesOrderDetailsV2({
            salesOrderDetails: resultGetAction,
            ...params,
            customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: (resultGetAction) =>
          generateDataNValidationUpdateProductSalesOrderFromSalesOrderDetailsV2({
            salesOrderDetails: resultGetAction,
            customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
          }),
      },
    },
    additionalSettingDefaultInputActionMenuAdd: [
      clearDataProductSupplierAction,
      ...defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_SALES_TYPE],
      ...defaultAdditionalSettingWarehouseConfig[DEFAULT_TRANSACTION_SALES_TYPE],
    ],
    additionalSettingDefaultInputActionMenuUpdate: [
      clearDataProductSupplierAction,
      ...defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_SALES_TYPE],
      ...defaultAdditionalSettingWarehouseConfig[DEFAULT_TRANSACTION_SALES_TYPE],
    ],
    generateDataForSettingDefaultInputActionMenuAdd: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    generateDataForSettingDefaultInputActionMenuUpdate: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    additionalPropsSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        generateDataNValidationProfile: generateDataNValidationAddProfileForIsCustomer,
        isDisabledContactType: [DEFAULT_CONTACT_IS_CUSTOMER_TYPE],
      },
    },
    additionalActionSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        parentStateName: NAME_STORE.CONTACT,
        childrenStateName: SLICE_NAME_TEMP_ADD_CONTACT_DATA,
        generalizeForState: ({
          resultSwitchDialog = {},
          keyStateContainer,
          keyValidationContainer,
          selectedIndex = 0,
          ...stateDataNvalidation
        }) => {
          let {
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          } = stateDataNvalidation;

          if (!isEmpty(resultSwitchDialog)) {
            const { id } = selectedStateContainer[selectedIndex];

            selectedStateContainer = arrHelp.combineDataObjInArray(
              selectedStateContainer,
              'id',
              id,
              {
                [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF]:
                  resultSwitchDialog[DEFAULT_KEY_NAME_CONTACT_ID_API_REF],
                [DEFAULT_KEY_NAME_CUSTOMER_CONTAINER_ID_API_REF]: resultSwitchDialog,
              },
            );

            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              selectedValidationContainer[0],
              [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF],
              true,
              false,
            );

            selectedValidationContainer = arrHelp.combineDataObjInArray(
              selectedValidationContainer,
              'id',
              id,
              newValidation,
            );
          }

          return {
            ...stateDataNvalidation,
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          };
        },
      },
    },
    parentMenuURL: REACT_APP_SALES_SALES_ORDER_URL,
    handleDeleteRow: {
      childrenTransactionData: ({
        transactionData,
        childrenTransactionData,
        childrenTransactionValidation,
        childrenTransactionID = '',
      }) => {
        const {
          childrenTransactionData: currChildrenTransactionData,
          childrenTransactionValidation: currChildrenTransactionValidation,
        } = filteringChildrenTransactionData({
          childrenTransactionData,
          childrenTransactionValidation,
          childrenTransactionDataID: childrenTransactionID,
        });

        return {
          childrenTransactionData: currChildrenTransactionData,
          childrenTransactionValidation: currChildrenTransactionValidation,
          transactionData: [
            {
              ...transactionData[0],
              ...formulaHelpers.calculateDiscountAndTotalOfChildrenData({
                parentData: transactionData[0],
                childrenData: currChildrenTransactionData,
              }),
            },
          ],
        };
      },
    },
  },
  [DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE]: {
    defaultTransctionNumberCode: DEFAULT_TRANSACTION_NUMBER_CODE_SALES_DELIVERY,
    allowKeyInputTransactionData: allowKeysSalesDeliveryData,
    allowKeyInputProductTransctionData: allowKeysProductSalesDeliveryData,
    allowKeyInputChildrenTransactionData: allowKeysProductSalesDeliveryData,
    keyNameReferenceNumber: DEFAULT_KEY_NAME_REFERENCE_NUMBER,
    keyNameReferenceNumberContainer: DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER,
    minimumLengthChildrenTransaction: 1,
    uniqueKeyNameChildrenTransaction: DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
    statusFreeUpdateInputTransaction: [DEFAULT_STATUS_SALES_DELIVERY_OPEN],
    errorMessagePrimaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.primary',
    errorMessageSecondaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.secondary',
    errorMessagePrimaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.primary',
    errorMessageSecondaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.secondary',
    errorMessagePrimaryInvalidForm: 'base.message.error.invalid-form.primary',
    errorMessageSecondaryInvalidForm: 'base.message.error.invalid-form.secondary',
    isMinutesHandoverShow: true,
    generateCreateDataNvalidationTransaction: ({ amount, companyUserData }) =>
      generateDataNValidationAddSalesDeliveryV2({
        amount,
        companyUserData,
        customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
        customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
      }),
    generateCreateDataNvalidationChildrenTransaction: ({ amount }) =>
      generateDataNValidationAddProductSalesDeliveryV2({
        amount,
        customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
        customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
      }),
    generalizeDataAddTransaction: generalizeDataAddSalesDelivery,
    primaryAddUpdateDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          placeholder: 'placeholder.input-auto',
          inputName: 'transaction_number',
          storeName: ROOT_STORE_NAME_REFERENCE_NUMBER,
          childrenStoreName: SLICE_NAME_REFERENCE_NUMBER_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_REFERENCE_NUMBER,
          keyInputContainerName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: referenceNumberList,
            additionalParamsForAction: {},
            removeDataAction: clearReferenceNumber,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['code', 'format'],
                ['value'],
                formatHelp.formatTransactionNumber,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'code',
          },
          inputProps: {
            maxLength: DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE,
          },
          isDisabled: ({
            actionMenu,
            parentStateList = {},
            transactionData,
            statusFreeUpdateInputTransaction = [],
          }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE ||
              parentStateList.statusRequest ===
                STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_PENDING ||
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                !statusFreeUpdateInputTransaction.includes(status)) ||
              actionMenu === DEFAULT_ACTION_MENU_UPDATE
            );
          },
          isUseTextInput: true,
        },
        {
          label: 'label.order-number',
          placeholder: 'placeholder.select-order-number',
          inputName: DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF,
          storeName: NAME_STORE.SALES,
          childrenStoreName: SLICE_NAME_SALES_ORDER_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_SALES_ORDER_ID_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_SALES_ORDER_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          getAdditionalParamsForAction: ({ transactionData = [] }) => {
            if (isEmpty(transactionData)) return {};

            const { customer_id_container } = transactionData[0];

            return {
              customer_name: !isEmpty(customer_id_container)
                ? customer_id_container.contact_name
                : '',
            };
          },
          autocompleteProps: {
            fetchAction: getListSalesOrder,
            additionalParamsForAction: {
              status: [DEFAULT_STATUS_SALES_ORDER_OPEN, DEFAULT_STATUS_SALES_ORDER_PARTIALLY_SENT],
            },
            removeDataAction: clearDataSalesSlice,
            additionalParamsForRemoveDataAction: DEFAULT_PAYLOAD_STORAGE_LIST_SALES_ORDER,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              status: [DEFAULT_STATUS_SALES_ORDER_OPEN, DEFAULT_STATUS_SALES_ORDER_PARTIALLY_SENT],
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getOptionLabel(options, DEFAULT_KEY_NAME_SALES_ORDER_NO_API_REF),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'code',
          },
          inputProps: {
            maxLength: DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE,
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                !statusFreeUpdateInputTransaction.includes(status)) ||
              actionMenu === DEFAULT_ACTION_MENU_UPDATE
            );
          },
          isUseTextInput: true,
          isRequired: true,
        },
        {
          label: 'label.transaction-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: DEFAULT_KEY_NAME_DELIVERY_DATE_API_REF,
          keyInputName: DEFAULT_KEY_NAME_DELIVERY_DATE_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
          isAddOneDayForMinDate: false,
          isRequired: true,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
        {
          label: 'label.warehouse',
          placeholder: 'placeholder.select-warehouse',
          inputName: DEFAULT_KEY_NAME_WAREHOUSE_NAME_API_REF,
          keyInputName: DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          storeName: NAME_STORE.WAREHOUSE,
          childrenStoreName: SLICE_NAME_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_WAREHOUSE_ID_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          autocompleteProps: {
            fetchAction: getWarehouseListWithDynamicStore,
            additionalParamsForAction: DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE,
            removeDataAction: clearWarehouseDataSlice,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            additionalParamsForRemoveDataAction: DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE,
            getOptionLabel: (options) =>
              autocompleteHelper.getOptionLabel(options, DEFAULT_KEY_NAME_WAREHOUSE_NAME_API_REF),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: DEFAULT_KEY_NAME_WAREHOUSE_NAME_API_REF,
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status, sales_order_id } = transactionData[0];

            return (
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                !statusFreeUpdateInputTransaction.includes(status)) ||
              actionMenu === DEFAULT_ACTION_MENU_UPDATE ||
              (actionMenu === DEFAULT_ACTION_MENU_ADD && sales_order_id)
            );
          },
          isUseTextInput: false,
        },
      ],
      [
        {
          label: 'label.contact-customer',
          placeholder: 'placeholder.select-contact-customer',
          inputName: DEFAULT_KEY_NAME_CONTACT_NAME_API_REF,
          storeName: NAME_STORE.CONTACT,
          childrenStoreName: SLICE_NAME_CONTACT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_CUSTOMER_CONTAINER_ID_API_REF,
          statusRequestListPending: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: contactList,
            additionalParamsForAction: {
              type: [DEFAULT_CONTACT_IS_CUSTOMER_TYPE],
            },
            removeDataAction: clearContactData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              type: [DEFAULT_CONTACT_IS_CUSTOMER_TYPE],
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                [DEFAULT_KEY_NAME_CONTACT_CODE_API_REF, DEFAULT_KEY_NAME_CONTACT_NAME_API_REF],
                [DEFAULT_KEY_NAME_CONTACT_NAME_API_REF],
                formatHelp.contactCodeWithName,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: DEFAULT_KEY_NAME_CONTACT_NAME_API_REF,
          },
          isUseAdditionalButtonNotFound: true,
          additionalButtonNotFoundProps: {
            textNotFound: 'button.plus-add-new-customer',
            keyNameIsNew: DEFAULT_KEY_NAME_IS_NEW_CONTACT_DATA,
            keynameSwitchDialog: 'contact-data',
            newPageStatus: DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA,
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status, sales_order_id } = transactionData[0];

            return (
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                !statusFreeUpdateInputTransaction.includes(status)) ||
              (actionMenu === DEFAULT_ACTION_MENU_ADD && sales_order_id) ||
              actionMenu === DEFAULT_ACTION_MENU_UPDATE
            );
          },
        },
        {
          label: 'label.customer-address',
          placeholder: 'placeholder.customer-address',
          inputName: 'contact_address',
          keyInputName: DEFAULT_KEY_NAME_CUSTOMER_ADDRESS_API_REF,
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          gridProps: defaultNineGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                !statusFreeUpdateInputTransaction.includes(status)) ||
              actionMenu === DEFAULT_ACTION_MENU_UPDATE
            );
          },
        },
      ],
    ],
    secondaryAddUpdateDataSchema: [
      [
        {
          label: 'label.salesman-constant',
          placeholder: 'placeholder.salesman-constant',
          inputName: DEFAULT_KEY_NAME_SALES_AGENT_NAME_API_REF,
          storeName: NAME_STORE.CONTACT,
          childrenStoreName: SLICE_NAME_CONTACT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_SALES_AGENT_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_SALES_AGENT_ID_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: contactList,
            additionalParamsForAction: {
              type: [DEFAULT_CONTACT_IS_EMPLOYEE_TYPE],
            },
            removeDataAction: clearContactData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              type: [DEFAULT_CONTACT_IS_EMPLOYEE_TYPE],
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                [DEFAULT_KEY_NAME_CONTACT_CODE_API_REF, DEFAULT_KEY_NAME_CONTACT_NAME_API_REF],
                [DEFAULT_KEY_NAME_CONTACT_NAME_API_REF],
                formatHelp.contactCodeWithName,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: DEFAULT_KEY_NAME_CONTACT_NAME_API_REF,
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
      ],
      [
        // tagAutocompleteInputProps,
        {
          usageInputLabel: false,
          keyInputName: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DROPZONE,
          additionalDropzoneProps: {
            generalizeRequestAction: (attachmentFile, relationValueToOtherAttachment) => {
              return generalizeAttachmentRequest({
                attachmentFile,
                relationValueToOtherAttachment,
                featureDocument: DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE,
              });
            },
          },
          gridContainerProps: {
            width: 'calc(100% - 2px)',
          },
        },
        {
          label: 'label.memo',
          placeholder: 'placeholder.memo',
          inputName: 'memo',
          keyInputName: 'description',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          additionalOutlinedInput: {
            rows: 3,
            multiline: true,
            className: 'none-padding',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    inputValueChanges: {
      [DEFAULT_KEY_NAME_REFERENCE_NUMBER]: {
        generateChanges: ({ newValue }) => {
          return {
            resultChanges: {
              [DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER]: {
                value: newValue,
              },
              [DEFAULT_KEY_NAME_REFERENCE_NUMBER]: newValue,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_QUANTITY_API_REF]: {
        generateChanges: ({
          newValue,
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const { quantity_comparison } = selectedChildrenTransactionData;

          const { quantity_available_const } = selectedChildrenTransactionData;
          const quantity_available = Number(quantity_available_const) - Number(newValue);

          return {
            resultChanges: {
              quantity_available,
              quantity_origin: Number(newValue) * Number(quantity_comparison),
            },
          };
        },
      },
    },
    autocompleteValueChanges: {
      [DEFAULT_KEY_NAME_PRODUCT_ID_API_REF]: {
        isCanChangeAllState: true,
        generateChanges: ({
          newValue,
          transactionData,
          transactionValidation,
          childrenTransactionData,
          childrenTransactionValidation,
          idChildrenTransaction,
        }) => {
          let unit = '',
            description = '',
            quantity = '',
            quantity_available_const = 0,
            quantityAvailableWithUnit = '';

          if (!isEmpty(newValue)) {
            const quantityConstKeyname = DEFAULT_KEY_NAME_QUANTITY_REAL_API_REF;

            const { product_unit, is_stock_monitored } = newValue;

            unit = !hasImplementedUnitProductUnit ? product_unit : newValue.unit;
            quantity_available_const = Number(newValue[quantityConstKeyname]) || 0;

            quantity =
              Number(quantity_available_const) ||
              (!is_stock_monitored && is_stock_monitored !== undefined)
                ? DEFAULT_QUANTITY_AVAILABLE_STOCK_NOT_MONITORED_PRODUCT
                : DEFAULT_QUANTITY_AVAILABLE_STOCK_MONITORED_PRODUCT;

            if (!is_stock_monitored) quantity_available_const = Infinity;

            quantityAvailableWithUnit = `${quantity_available_const} ${unit}`;
          }

          const childrenTransactionDataChanges = {
            unit,
            unit_destination: unit,
            unit_origin: unit,
            description,
            quantity: Number(quantity) || '',
            quantity_origin: Number(quantity) || '',
            quantity_comparison: 1,
            quantity_available_const,
            quantity_available_with_unit: quantityAvailableWithUnit,
          };

          childrenTransactionData = arrHelp.combineDataObjInArray(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
            childrenTransactionDataChanges,
          );

          if (!isEmpty(childrenTransactionValidation)) {
            const isErrorValidation = !Number(quantity);

            const selectedChildrenTransactionValidation = arrHelp.filterObjDataWithID(
              childrenTransactionValidation,
              'id',
              idChildrenTransaction,
            );

            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              selectedChildrenTransactionValidation,
              ['quantity', DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF],
              true,
              isErrorValidation,
            );

            childrenTransactionValidation = arrHelp.combineDataObjInArray(
              childrenTransactionValidation,
              'id',
              idChildrenTransaction,
              newValidation,
            );
          }

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
      [DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF]: {
        isHitEndpoint: true,
        action: getDetailSalesOrder,
        actionRemove: () => clearDataSalesSlice(DEFAULT_PAYLOAD_STORAGE_SALES_ORDER_DETAILS),
        storeName: NAME_STORE.SALES,
        childrenSliceName: SLICE_NAME_SALES_ORDER_DETAILS,
        generateParamsAction: ({ newAutocompleteChanges = {} }) => {
          return {
            [DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF]:
              newAutocompleteChanges[DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF] || '',
          };
        },
        generateChanges: ({
          newValue,
          resultGetAction,
          companyUserData,
          currentTransactionData,
        }) => {
          if (isEmpty(newValue)) {
            resultGetAction = null;
          }

          let { transactionData, transactionValidation } =
            generateDataNValidationSalesDeliveryFromSalesOrderDetailsV2({
              salesOrderDetails: resultGetAction,
              companyUserData,
              currentSalesDeliveryData: currentTransactionData,
              customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
              customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
            });

          if (isEmpty(resultGetAction) || isEmpty(newValue)) {
            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              transactionValidation[0],
              [DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF],
              true,
            );

            transactionValidation = [newValidation];
          }

          const { childrenTransactionData, childrenTransactionValidation } =
            generateDataNValidationProductSalesDeliveryFromSalesOrderDetailsV2({
              salesOrderDetails: resultGetAction,
              customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
              customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
            });

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
      [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF]: {
        isHitEndpoint: false,
        isCanChangeAllState: false,
        generateChanges: ({ newValue }) => {
          const customerAddress = newValue
            ? newValue[DEFAULT_KEY_NAME_SHIPPING_ADDRESS_API_REF] || ''
            : '';

          return {
            resultChanges: {
              customer_address: customerAddress,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF]: {
        ...getChangesAutocompleteForUnitProductTransactionPropertiesPrimary({
          transactionType: DEFAULT_TRANSACTION_SALES_TYPE,
          subTransactionType: DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE,
        }),
      },
    },
    gridHeaderChildrenTransaction: gridHeaderProductSalesDelivery,
    gridCollapseChildrenTransaction: gridCollapseProductSalesDelivery,
    dependingKeyNameTransactionValidation: [DEFAULT_KEY_NAME_DELIVERY_DATE_API_REF], // validation form on transaction
    anotherKeyNameTransactionDataDepends: [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF], // validation form on data transaction
    keyNameBypassChildrenTransactionValidation: [
      'product_id',
      'quantity',
      DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
    ],
    checkingIsEmptyChildrenTransactionKeyNameBypass: [
      [null, ''],
      DEFAULT_EMPTY_CALCULATIONS_NUMBER,
      [null, ''],
    ],
    keyNameCheckingValidationInputBypass: ['quantity', DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF],
    keyNameCheckingStatusChildrenTransaction: ['product_id'],
    menuActionAddProps: {
      [DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_ORDER_DATA]: {
        getAction: (searchParams) =>
          getDetailSalesOrder({
            [DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF]:
              searchParams[DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF],
          }),
        parentStateName: NAME_STORE.SALES,
        childrenStateName: SLICE_NAME_SALES_ORDER_DETAILS,
        generateDataNvalidationTransaction: ({ resultGetAction, companyUserData }) =>
          generateDataNValidationSalesDeliveryFromSalesOrderDetailsV2({
            salesOrderDetails: resultGetAction,
            companyUserData,
            customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: ({ resultGetAction }) =>
          generateDataNValidationProductSalesDeliveryFromSalesOrderDetailsV2({
            salesOrderDetails: resultGetAction,
            customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
          }),
      },
    },
    menuActionUpdateProps: {
      [DEFAULT_KEY_NAME_PARAMS_URL_SALES_DELIVERY_ID]: {
        getAction: (paramsRouter) =>
          getSalesDeliveryDetails({
            [DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF]:
              paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_SALES_DELIVERY_ID],
          }),
        // dependencies action when after getting primary action (above action)
        onDependenciesActions: [defaultOnDependenciesListDetailsAttachmentAction],
        parentStateName: NAME_STORE.SALES,
        childrenStateName: SLICE_NAME_SALES_DELIVERY_DETAILS,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNValidationUpdateSalesDeliveryFromSalesDeliveryDetailsV2({
            salesDeliveryDetails: resultGetAction,
            ...params,
            customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: (resultGetAction) =>
          generateDataNValidationUpdateProductSalesDeliveryFromSalesDeliveryDetailsV2({
            salesDeliveryDetails: resultGetAction,
            customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
          }),
      },
    },
    additionalSettingDefaultInputActionMenuAdd: [
      clearDataProductSupplierAction,
      ...defaultAdditionalSettingWarehouseConfig[DEFAULT_TRANSACTION_SALES_TYPE],
    ],
    additionalSettingDefaultInputActionMenuUpdate: [
      clearDataProductSupplierAction,
      ...defaultAdditionalSettingWarehouseConfig[DEFAULT_TRANSACTION_SALES_TYPE],
    ],
    generateDataForSettingDefaultInputActionMenuAdd: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    generateDataForSettingDefaultInputActionMenuUpdate: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    additionalPropsSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        generateDataNValidationProfile: generateDataNValidationAddProfileForIsCustomer,
        isDisabledContactType: [DEFAULT_CONTACT_IS_CUSTOMER_TYPE],
      },
    },
    additionalActionSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        parentStateName: NAME_STORE.CONTACT,
        childrenStateName: SLICE_NAME_TEMP_ADD_CONTACT_DATA,
        generalizeForState: ({
          resultSwitchDialog = {},
          keyStateContainer,
          keyValidationContainer,
          selectedIndex = 0,
          ...stateDataNvalidation
        }) => {
          let {
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          } = stateDataNvalidation;

          if (!isEmpty(resultSwitchDialog)) {
            const { id } = selectedStateContainer[selectedIndex];

            selectedStateContainer = arrHelp.combineDataObjInArray(
              selectedStateContainer,
              'id',
              id,
              {
                [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF]:
                  resultSwitchDialog[DEFAULT_KEY_NAME_CONTACT_ID_API_REF],
                [DEFAULT_KEY_NAME_CUSTOMER_CONTAINER_ID_API_REF]: resultSwitchDialog,
              },
            );

            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              selectedValidationContainer[0],
              [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF],
              true,
              false,
            );

            selectedValidationContainer = arrHelp.combineDataObjInArray(
              selectedValidationContainer,
              'id',
              id,
              newValidation,
            );
          }

          return {
            ...stateDataNvalidation,
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          };
        },
      },
    },
    parentMenuURL: REACT_APP_SALES_SALES_DELIVERY_URL,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_INVOICE_TYPE]: {
    defaultTransctionNumberCode: DEFAULT_TRANSACTION_NUMBER_CODE_SALES_INVOICE,
    allowKeyInputTransactionData: allowKeysDataSalesInvoice,
    allowKeyInputProductTransctionData: allowKeysDataProduct,
    allowKeyInputChildrenTransactionData: allowKeysDataProduct,
    keyNameReferenceNumber: DEFAULT_KEY_NAME_REFERENCE_NUMBER,
    keyNameReferenceNumberContainer: DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER,
    keyNameTransactionDate: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
    keyNameAccountingPeriod: DEFAULT_KEY_NAME_ACCOUNTING_PERIOD,
    keyNameDiscountType: DEFAULT_KEY_NAME_DISCOUNT_TYPE_API_REF,
    keyNameDiscountPercentage: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
    keyNameDiscountNominal: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
    keyNameDiscountAccountID: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_API_REF,
    keyNameDiscountAccountIDcontainer: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_CONTAINER,
    keyNameDownPaymentAccountID: DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_API_REF,
    keyNameDownPaymentAccountIDcontainer: DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_CONTAINER,
    keyNameDownPaymentValue: DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE,
    keyNameExpenseAccountID: DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_API_REF,
    keyNameExpenseAccountIDcontainer: DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_CONTAINER,
    keyNameExpenseValue: DEFAULT_KEY_NAME_EXPENSE_VALUE,
    keyNameMaximumDownPaymentValue: defaultKeyNameMaximumDownPaymentValue,
    minimumLengthChildrenTransaction: 1,
    uniqueKeyNameChildrenTransaction: DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
    statusFreeUpdateInputTransaction: [DEFAULT_STATUS_SALES_INVOICE_OPEN],
    errorMessagePrimaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.primary',
    errorMessageSecondaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.secondary',
    errorMessagePrimaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.primary',
    errorMessageSecondaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.secondary',
    errorMessagePrimaryInvalidForm: 'base.message.error.invalid-form.primary',
    errorMessageSecondaryInvalidForm: 'base.message.error.invalid-form.secondary',
    relationalKeyDiscountTypeNvalue: {
      [DEFAULT_DISCOUNT_PERCENTAGE_TYPE]: {
        keyInputName: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
        maximumValue: 100,
        representationDataType: DEFAULT_REPRESENTATION_DATA_NUMBER_TYPE,
      },
      [DEFAULT_DISCOUNT_NOMINAL_TYPE]: {
        keyInputName: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
        keyNameMaximumValue: DEFAULT_KEY_NAME_AMOUNT_CONST,
        representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
      },
    },
    isAdditionalCostShow: true,
    // indicate for additional cost is combined
    additionalCostCombinedOnFormSchema: true,
    isFormStatusShow: true,
    // showing floading from status
    showFormStatusFloating: true,
    generateCreateDataNvalidationTransaction: ({ amount, companyUserData }) =>
      generateDataNValidationAddSalesInvoiceV2({
        amount,
        companyUserData,
        customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
        customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
      }),
    generateCreateDataNvalidationChildrenTransaction: ({ amount }) =>
      generateDataNValidationAddProductSIv2({
        amount,
        customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
        customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
      }),
    generalizeDataAddTransaction: generalizeDataAddSalesInvoice,
    primaryAddUpdateDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          placeholder: 'placeholder.input-auto',
          inputName: 'transaction_number',
          storeName: ROOT_STORE_NAME_REFERENCE_NUMBER,
          childrenStoreName: SLICE_NAME_REFERENCE_NUMBER_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_REFERENCE_NUMBER,
          keyInputContainerName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultSecondPointQuarterGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: referenceNumberList,
            additionalParamsForAction: {},
            removeDataAction: clearReferenceNumber,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['code', 'format'],
                ['value'],
                formatHelp.formatTransactionNumber,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'code',
          },
          inputProps: {
            maxLength: DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE,
          },
          isDisabled: ({
            actionMenu,
            parentStateList = {},
            transactionData,
            statusFreeUpdateInputTransaction = [],
          }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE ||
              parentStateList.statusRequest ===
                STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_PENDING ||
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                !statusFreeUpdateInputTransaction.includes(status))
            );
          },
          isUseTextInput: true,
        },
        {
          label: 'label.order-number',
          inputName: DEFAULT_KEY_NAME_MODUL_ID_CONTAINER,
          keyInputName: DEFAULT_KEY_NAME_MODUL_ID_CONTAINER,
          gridProps: defaultSecondPointQuarterGridProps,
          keyInputContainerName: DEFAULT_KEY_NAME_MODUL_ID_CONTAINER,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          autocompleteProps: {
            getOptionLabel: (options) =>
              autocompleteHelper.getOptionLabel(options, 'transaction_no'),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: false,
          },
          isDisabled: ({ actionMenu }) => {
            return actionMenu === DEFAULT_ACTION_MENU_UPDATE || true;
          },
          isRequired: true,
          isGridShow: ({ transactionData = [] }) => {
            if (isEmpty(transactionData)) return false;

            return (
              transactionData[0][DEFAULT_KEY_NAME_MODUL_TYPE_API_REF] ===
              modulTypeSalesInvoice.order
            );
          },
        },
        {
          label: 'label.delivery-number',
          inputName: DEFAULT_KEY_NAME_MODUL_ID_CONTAINER,
          keyInputName: DEFAULT_KEY_NAME_MODUL_ID_CONTAINER,
          gridProps: defaultSecondPointQuarterGridProps,
          keyInputContainerName: DEFAULT_KEY_NAME_MODUL_ID_CONTAINER,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          autocompleteProps: {
            getOptionLabel: (options) =>
              autocompleteHelper.getOptionLabel(options, 'transaction_no'),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: false,
          },
          isDisabled: ({ actionMenu }) => {
            return actionMenu === DEFAULT_ACTION_MENU_UPDATE || true;
          },
          isRequired: true,
          isGridShow: ({ transactionData = [] }) => {
            if (isEmpty(transactionData)) return false;

            return (
              transactionData[0][DEFAULT_KEY_NAME_MODUL_TYPE_API_REF] ===
              modulTypeSalesInvoice.delivery
            );
          },
        },
        {
          label: 'label.transaction-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
          keyInputName: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultSecondPointQuarterGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
          isAddOneDayForMinDate: false,
          isRequired: true,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
        {
          label: 'label.expired-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: DEFAULT_KEY_NAME_EXPIRED_DATE_API_REF,
          keyInputName: DEFAULT_KEY_NAME_EXPIRED_DATE_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultSecondPointQuarterGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
          isAddOneDayForMinDate: true,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
        },
        {
          label: 'label.warehouse',
          placeholder: 'placeholder.select-warehouse',
          inputName: DEFAULT_KEY_NAME_WAREHOUSE_NAME_API_REF,
          keyInputName: DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          storeName: NAME_STORE.WAREHOUSE,
          childrenStoreName: SLICE_NAME_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_WAREHOUSE_ID_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          autocompleteProps: {
            fetchAction: getWarehouseListWithDynamicStore,
            additionalParamsForAction: DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE,
            removeDataAction: clearWarehouseDataSlice,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            additionalParamsForRemoveDataAction: DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE,
            getOptionLabel: (options) =>
              autocompleteHelper.getOptionLabel(options, DEFAULT_KEY_NAME_WAREHOUSE_NAME_API_REF),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: DEFAULT_KEY_NAME_WAREHOUSE_NAME_API_REF,
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status, modul_type } = transactionData[0];

            return (
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                !statusFreeUpdateInputTransaction.includes(status)) ||
              modul_type === modulTypeSalesInvoice.order ||
              modul_type === modulTypeSalesInvoice.delivery
            );
          },
          isUseTextInput: false,
        },
      ],
      [
        {
          label: 'label.contact-customer',
          placeholder: 'placeholder.select-contact-customer',
          inputName: DEFAULT_KEY_NAME_CONTACT_NAME_API_REF,
          storeName: NAME_STORE.CONTACT,
          childrenStoreName: SLICE_NAME_CONTACT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_CUSTOMER_CONTAINER_ID_API_REF,
          statusRequestListPending: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultFourthPointHalfGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: contactList,
            additionalParamsForAction: {
              type: ['is_customer'],
            },
            removeDataAction: clearContactData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              type: ['is_customer'],
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                [DEFAULT_KEY_NAME_CONTACT_CODE_API_REF, DEFAULT_KEY_NAME_CONTACT_NAME_API_REF],
                [DEFAULT_KEY_NAME_CONTACT_NAME_API_REF],
                formatHelp.contactCodeWithName,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: DEFAULT_KEY_NAME_CONTACT_NAME_API_REF,
          },
          isUseAdditionalButtonNotFound: true,
          additionalButtonNotFoundProps: {
            textNotFound: 'button.plus-add-new-customer',
            keyNameIsNew: DEFAULT_KEY_NAME_IS_NEW_CONTACT_DATA,
            keynameSwitchDialog: 'contact-data',
            newPageStatus: DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA,
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status, modul_type } = transactionData[0];

            return (
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                !statusFreeUpdateInputTransaction.includes(status)) ||
              modul_type === modulTypeSalesInvoice.order ||
              modul_type === modulTypeSalesInvoice.delivery
            );
          },
        },
        {
          label: 'label.customer-address',
          placeholder: 'placeholder.customer-address',
          inputName: DEFAULT_KEY_NAME_CUSTOMER_ADDRESS_API_REF,
          keyInputName: DEFAULT_KEY_NAME_CUSTOMER_ADDRESS_API_REF,
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          gridProps: defaultSeventPointHalfGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status, modul_type } = transactionData[0];

            return (
              (actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
                !statusFreeUpdateInputTransaction.includes(status)) ||
              modul_type === modulTypeSalesInvoice.order ||
              modul_type === modulTypeSalesInvoice.delivery
            );
          },
        },
      ],
    ],
    secondaryAddUpdateDataSchema: [
      [
        {
          label: 'label.subtotal',
          name: 'amount_const',
          typographyLabelProps: {
            variant: 'boldIndicator2',
          },
          typographyValueProps: {
            variant: 'body8',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
          isTypography: true,
          emptyValueSubstitute: 0,
        },
        ...arrHelp.joinAtIndex({
          arr: defaultFormAdditionalCost,
          joinIndex: 0,
          itemJoin: totalAfterDiscountSchema,
        }),
        {
          representationDataType: DEFAULT_REPRESENTATION_DATA_HORIZONTAL_LINE_TYPE,
        },
        {
          label: 'label.total',
          name: 'amount',
          inputName: 'amount',
          keyInputName: 'amount',
          typographyLabelProps: {
            variant: 'boldIndicator2',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
          isTypography: true,
        },
      ],
      [
        tagAutocompleteInputProps,
        {
          label: 'label.salesman-constant',
          placeholder: 'placeholder.salesman-constant',
          inputName: 'sales_agent_name',
          storeName: NAME_STORE.CONTACT,
          childrenStoreName: SLICE_NAME_CONTACT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputName: 'sales_agent_id',
          keyInputContainerName: 'sales_agent_id_container',
          statusRequestListPending: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: contactList,
            additionalParamsForAction: {
              type: ['is_employee'],
            },
            removeDataAction: clearContactData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              type: ['is_employee'],
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['contact_code', 'contact_name'],
                ['contact_name'],
                formatHelp.contactCodeWithName,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'contact_name',
          },
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
          ...defaultAdditionalGridPropsSecondarySchemaInput,
        },
        {
          label: 'label.on-behalf',
          placeholder: 'placeholder.on-behalf',
          inputName: DEFAULT_KEY_NAME_ON_BEHALF_API_REF,
          keyInputName: DEFAULT_KEY_NAME_ON_BEHALF_API_REF,
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isDisabled: ({ actionMenu, transactionData, statusFreeUpdateInputTransaction = [] }) => {
            const { status } = transactionData[0];

            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE &&
              !statusFreeUpdateInputTransaction.includes(status)
            );
          },
          ...defaultAdditionalGridPropsSecondarySchemaInput,
        },
        {
          label: 'label.memo',
          placeholder: 'label.memo',
          inputName: 'memo',
          keyInputName: 'description',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          additionalOutlinedInput: {
            rows: 2,
            multiline: true,
            className: 'none-padding',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
        {
          usageInputLabel: false,
          keyInputName: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DROPZONE,
          additionalDropzoneProps: {
            generalizeRequestAction: (attachmentFile, relationValueToOtherAttachment) => {
              return generalizeAttachmentRequest({
                attachmentFile,
                relationValueToOtherAttachment,
                featureDocument: DEFAULT_TRANSACTION_SALES_SALES_INVOICE_TYPE,
              });
            },
          },
          gridContainerProps: {
            width: 'calc(100% - 2px)',
          },
        },
      ],
    ],
    inputValueChanges: {
      [DEFAULT_KEY_NAME_REFERENCE_NUMBER]: {
        generateChanges: ({ newValue }) => {
          return {
            resultChanges: {
              [DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER]: {
                value: newValue,
              },
              [DEFAULT_KEY_NAME_REFERENCE_NUMBER]: newValue,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_PRICE_API_REF]: {
        generateChanges: ({
          newValue,
          transactionData = [],
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const amountTransactionData = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const { quantity, amount: amountPast } = selectedChildrenTransactionData;

          const amountChildrenTransactionWithoutPast = amountTransactionData - amountPast;
          const currentAmountSelectedChildrenTransaction = Number(quantity) * Number(newValue);

          const amountChildrenTransaction =
            amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction;

          const { discount_nominal, discount_percentage } = formulaCountDiscountCost({
            allAmountTransaction: amountChildrenTransaction,
            transactionData,
          });

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: currentAmountSelectedChildrenTransaction,
            },
            transactionDataChanges: {
              amount: Number(newAmountTransaction),
              amount_const: Number(amountChildrenTransaction),
              discount_nominal: Number(discount_nominal) || '',
              discount_percentage: Number(discount_percentage) || '',
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_QUANTITY_API_REF]: {
        generateChanges: ({
          newValue,
          transactionData = [],
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const amountTransactionData = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const {
            price,
            amount: amountPast,
            quantity_comparison,
          } = selectedChildrenTransactionData;

          const amountChildrenTransactionWithoutPast = amountTransactionData - amountPast;
          const currentAmountSelectedChildrenTransaction = Number(price) * Number(newValue);

          const amountChildrenTransaction =
            amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction;

          const { discount_nominal, discount_percentage } = formulaCountDiscountCost({
            allAmountTransaction: amountChildrenTransaction,
            transactionData,
          });

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: currentAmountSelectedChildrenTransaction,
              quantity_origin: Number(newValue) * Number(quantity_comparison),
            },
            transactionDataChanges: {
              amount: Number(newAmountTransaction),
              amount_const: Number(amountChildrenTransaction),
              discount_nominal: Number(discount_nominal) || '',
              discount_percentage: Number(discount_percentage) || '',
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          let newDiscountNominal = Number(amountChildrenTransaction) * (Number(newValue) / 100);

          newDiscountNominal = Number(newDiscountNominal).toFixed(2);

          const { expense_value, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: newDiscountNominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
              [DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF]: Number(newDiscountNominal),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: newValue,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          const newDiscountPercentage = amountChildrenTransaction
            ? Number((Number(newValue) / Number(amountChildrenTransaction)) * 100).toFixed(2)
            : 0;

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
              [DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF]: Number(newDiscountPercentage),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_EXPENSE_VALUE]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { discount_nominal, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: newValue,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { discount_nominal, expense_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: newValue,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
            },
          };
        },
      },
    },
    autocompleteValueChanges: {
      [DEFAULT_KEY_NAME_PRODUCT_ID_API_REF]: {
        isCanChangeAllState: true,
        generateChanges: ({
          newValue,
          transactionData = [],
          transactionValidation = [],
          childrenTransactionData = [],
          childrenTransactionValidation = [],
          idChildrenTransaction = '',
        }) => {
          const quantityConstKeyname = DEFAULT_KEY_NAME_QUANTITY_REAL_API_REF;

          const { product_description, sales_price, unit, product_unit, is_stock_monitored } =
            newValue || {};

          const quantityConst = !isEmpty(newValue) ? newValue[quantityConstKeyname] : 0;

          const quantity_available_const =
            !isEmpty(newValue) || (!is_stock_monitored && is_stock_monitored !== undefined)
              ? newValue[quantityConstKeyname] !== undefined
                ? Number(newValue[quantityConstKeyname])
                : DEFAULT_QUANTITY_AVAILABLE_STOCK_NOT_MONITORED_PRODUCT
              : DEFAULT_QUANTITY_AVAILABLE_STOCK_MONITORED_PRODUCT;

          const quantity =
            Number(quantityConst) || (!is_stock_monitored && is_stock_monitored !== undefined)
              ? DEFAULT_QUANTITY_AVAILABLE_STOCK_NOT_MONITORED_PRODUCT
              : DEFAULT_QUANTITY_AVAILABLE_STOCK_MONITORED_PRODUCT;

          // amount current selected transaction data
          const amount =
            Number(sales_price) && Number(quantity)
              ? Number(sales_price) * Number(quantity)
              : DEFAULT_CALCULATION_NUMBER;

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const sumAmountsChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const {
            discount_percentage,
            discount_nominal,
            expense_value,
            down_payment_value,
            discount_type,
          } = transactionData[0];

          let amountChildrenTransaction = 0;

          if (!isEmpty(childrenTransactionData)) {
            amountChildrenTransaction = arrHelp.filterObjDataWithID(
              childrenTransactionData,
              'id',
              idChildrenTransaction,
            ).amount;
          }

          const newAmountTransactionData = !isEmpty(newValue)
            ? Number(sumAmountsChildrenTransaction) -
              Number(amountChildrenTransaction) +
              Number(amount)
            : Number(sumAmountsChildrenTransaction) - Number(amountChildrenTransaction);

          let discountNominal =
            discount_type === DEFAULT_DISCOUNT_NOMINAL_TYPE
              ? discount_nominal
              : (Number(discount_percentage) / Number(100)) * Number(newAmountTransactionData);

          discountNominal = Number(discountNominal).toFixed(2);

          if (Number(discountNominal) > Number(newAmountTransactionData))
            discountNominal = Number(newAmountTransactionData);

          let discountPercentage =
            discount_type === DEFAULT_DISCOUNT_PERCENTAGE_TYPE
              ? discount_percentage
              : newAmountTransactionData
              ? (Number(discountNominal) / Number(newAmountTransactionData)) * 100
              : 0;

          discountPercentage = Number(discountPercentage).toFixed(2);

          const expenseValue = Number(newAmountTransactionData) <= 0 ? '' : expense_value;

          const downPaymentValue = Number(newAmountTransactionData) <= 0 ? '' : down_payment_value;

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: newAmountTransactionData,
            discountNominal,
            expenseValue,
            downPaymentValue,
          });

          const usagedUnitProduct =
            hasImplementedUnitProductUnit && unit ? unit : product_unit || '';

          const quantityAvailableWithUnit = usagedUnitProduct
            ? `${quantity_available_const} ${usagedUnitProduct}`
            : '';

          const childrenTransactionDataChanges = {
            price: Number(sales_price) || '',
            description: product_description || '',
            quantity: Number(quantity) || '',
            quantity_origin: Number(quantity) || '',
            quantity_comparison: 1,
            unit: usagedUnitProduct,
            unit_destination: usagedUnitProduct,
            unit_origin: usagedUnitProduct,
            quantity_available_with_unit: quantityAvailableWithUnit,
            amount: Number(amount) || '',
            quantity_available_const,
          };

          const transactionDataChanges = {
            amount: Number(newAmountTransaction),
            discount_nominal: Number(discountNominal) || '',
            discount_percentage: Number(discountPercentage) || '',
            expense_value: expenseValue,
            down_payment_value: downPaymentValue,
            amount_const: Number(newAmountTransactionData),
          };

          transactionData = [
            {
              ...transactionData[0],
              ...transactionDataChanges,
            },
          ];

          childrenTransactionData = arrHelp.combineDataObjInArray(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
            childrenTransactionDataChanges,
          );

          if (!isEmpty(childrenTransactionValidation)) {
            const isErrorValidation = !Number(quantity);

            const selectedChildrenTransactionValidation = arrHelp.filterObjDataWithID(
              childrenTransactionValidation,
              'id',
              idChildrenTransaction,
            );

            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              selectedChildrenTransactionValidation,
              ['quantity', DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF],
              true,
              isErrorValidation,
            );

            childrenTransactionValidation = arrHelp.combineDataObjInArray(
              childrenTransactionValidation,
              'id',
              idChildrenTransaction,
              newValidation,
            );
          }

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
      [DEFAULT_KEY_NAME_SALES_QUOTATION_ID_API_REF]: {
        isHitEndpoint: true,
        action: getDetailSalesQuotation,
        actionRemove: () => clearDataSalesSlice(DEFAULT_PAYLOAD_STORAGE_SALES_QUOTATION_DETAIL),
        storeName: NAME_STORE.SALES,
        childrenSliceName: SLICE_NAME_SALES_QUOTATION_DETAIL,
        generateParamsAction: ({ newAutocompleteChanges = {} }) => {
          return newAutocompleteChanges[DEFAULT_KEY_NAME_SALES_QUOTATION_ID_API_REF] || '';
        },
        generateChanges: ({ resultGetAction, companyUserData, currentTransactionData }) => {
          const { transactionData, transactionValidation } =
            generateDataNValidationSalesOrderFromSalesQuotationDetailsV2({
              salesQuotationDetails: resultGetAction,
              companyUserData,
              currentSalesOrderData: currentTransactionData,
              customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
              customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
            });

          const { childrenTransactionData, childrenTransactionValidation } =
            generateDataNValidationProductSalesOrderFromSalesQuotationDetailsV2({
              salesQuotationDetails: resultGetAction,
              customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
              customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
            });

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
      [DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF]: {
        isCanChangeAllState: true,
        generateChanges: ({ newValue, transactionData, transactionValidation }) => {
          if (!isEmpty(transactionValidation)) {
            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              transactionValidation[0],
              [DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF],
              true,
              isEmpty(newValue),
            );

            transactionValidation = [newValidation];
          }

          const { childrenTransactionData, childrenTransactionValidation } =
            generateDataNValidationAddProductSIv2({
              amount: 2,
              customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
              customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
            });

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
      [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF]: {
        isHitEndpoint: false,
        isCanChangeAllState: false,
        generateChanges: ({ newValue }) => {
          const customerAddress = newValue
            ? newValue[DEFAULT_KEY_NAME_SHIPPING_ADDRESS_API_REF] || ''
            : '';

          return {
            resultChanges: {
              customer_address: customerAddress,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF]: {
        ...getChangesAutocompleteForUnitProductTransactionPropertiesPrimary({
          transactionType: DEFAULT_TRANSACTION_SALES_TYPE,
          subTransactionType: DEFAULT_TRANSACTION_SALES_SALES_INVOICE_TYPE,
        }),
      },
    },
    menuActionAddProps: {
      [DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_ORDER_DATA]: {
        getAction: (searchParams) =>
          getDetailSalesOrder({
            [DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF]:
              searchParams[DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF],
          }),
        parentStateName: NAME_STORE.SALES,
        childrenStateName: SLICE_NAME_SALES_ORDER_DETAILS,
        generateDataNvalidationTransaction: ({ resultGetAction, companyUserData }) =>
          generateDataNValidationSalesInvoiceFromSalesOrderDetailsV2({
            salesOrderDetails: resultGetAction,
            companyUserData,
            customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: ({ resultGetAction }) =>
          generateDataNValidationProductSalesInvoiceFromSalesOrderDetailsV2({
            salesOrderDetails: resultGetAction,
            customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
          }),
      },
      [DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_DELIVERY_DATA]: {
        getAction: (searchParams) =>
          getSalesDeliveryDetails({
            [DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF]:
              searchParams[DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF],
          }),
        parentStateName: NAME_STORE.SALES,
        childrenStateName: SLICE_NAME_SALES_DELIVERY_DETAILS,
        generateDataNvalidationTransaction: ({ resultGetAction, companyUserData }) =>
          generateDataNValidationSalesInvoiceFromSalesDeliveryDetailsV2({
            salesDeliveryDetails: resultGetAction,
            companyUserData,
            customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: ({ resultGetAction }) =>
          generateDataNValidationProductSalesInvoiceFromSalesDeliveryDetailsV2({
            salesDeliveryDetails: resultGetAction,
            customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
          }),
      },
    },
    gridHeaderChildrenTransaction: gridHeaderProductSalesInvoice,
    gridCollapseChildrenTransaction: gridCollapseProductSalesInvoice,
    dependingKeyNameTransactionValidation: ['transaction_date', 'expired_date'], // validation form on transaction
    anotherKeyNameTransactionDataDepends: [
      DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
      'transaction_date',
    ], // validation form on data transaction
    keyNameBypassChildrenTransactionValidation: [
      'product_id',
      'quantity',
      'price',
      DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
    ],
    checkingIsEmptyChildrenTransactionKeyNameBypass: [
      [null, ''],
      DEFAULT_EMPTY_CALCULATIONS_NUMBER,
      DEFAULT_EMPTY_CALCULATIONS_NUMBER,
      [null, ''],
    ],
    keyNameCheckingValidationInputBypass: [
      'quantity',
      'price',
      DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF,
    ],
    keyNameCheckingStatusChildrenTransaction: ['product_id'],
    menuActionUpdateProps: {
      [DEFAULT_KEY_NAME_PARAMS_URL_SALES_INVOICE_ID]: {
        getAction: (paramsRouter) =>
          getDetailSalesInvoice({
            [DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF]:
              paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_SALES_INVOICE_ID],
          }),
        // dependencies action when after getting primary action (above action)
        onDependenciesActions: [defaultOnDependenciesListDetailsAttachmentAction],
        parentStateName: NAME_STORE.SALES,
        childrenStateName: SLICE_NAME_SALES_INVOICE_DETAILS,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNValidationUpdateSalesInvoiceV2({
            salesInvoiceDataSelected: resultGetAction,
            ...params,
            customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: (resultGetAction) =>
          generateDataNValidationUpdateProductSalesInvoiceV2({
            salesInvoiceDataSelected: resultGetAction,
            customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
          }),
      },
    },
    additionalSettingDefaultInputActionMenuAdd: [
      clearDataProductSupplierAction,
      ...defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_SALES_TYPE],
      ...defaultAdditionalSettingWarehouseConfig[DEFAULT_TRANSACTION_SALES_TYPE],
    ],
    additionalSettingDefaultInputActionMenuUpdate: [
      clearDataProductSupplierAction,
      ...defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_SALES_TYPE],
      ...defaultAdditionalSettingWarehouseConfig[DEFAULT_TRANSACTION_SALES_TYPE],
    ],
    generateDataForSettingDefaultInputActionMenuAdd: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    generateDataForSettingDefaultInputActionMenuUpdate: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    additionalPropsSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        generateDataNValidationProfile: generateDataNValidationAddProfileForIsCustomer,
        isDisabledContactType: [DEFAULT_CONTACT_IS_CUSTOMER_TYPE],
      },
    },
    additionalActionSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        parentStateName: NAME_STORE.CONTACT,
        childrenStateName: SLICE_NAME_TEMP_ADD_CONTACT_DATA,
        generalizeForState: ({
          resultSwitchDialog = {},
          keyStateContainer,
          keyValidationContainer,
          selectedIndex = 0,
          ...stateDataNvalidation
        }) => {
          let {
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          } = stateDataNvalidation;

          if (!isEmpty(resultSwitchDialog)) {
            const { id } = selectedStateContainer[selectedIndex];

            selectedStateContainer = arrHelp.combineDataObjInArray(
              selectedStateContainer,
              'id',
              id,
              {
                [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF]:
                  resultSwitchDialog[DEFAULT_KEY_NAME_CONTACT_ID_API_REF],
                [DEFAULT_KEY_NAME_CUSTOMER_CONTAINER_ID_API_REF]: resultSwitchDialog,
              },
            );

            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              selectedValidationContainer[0],
              [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF],
              true,
              false,
            );

            selectedValidationContainer = arrHelp.combineDataObjInArray(
              selectedValidationContainer,
              'id',
              id,
              newValidation,
            );
          }

          return {
            ...stateDataNvalidation,
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          };
        },
      },
    },
    parentMenuURL: REACT_APP_SALES_SALES_INVOICE_URL,
    handleDeleteRow: {
      childrenTransactionData: ({
        transactionData,
        childrenTransactionData,
        childrenTransactionValidation,
        childrenTransactionID = '',
      }) => {
        const {
          childrenTransactionData: currChildrenTransactionData,
          childrenTransactionValidation: currChildrenTransactionValidation,
        } = filteringChildrenTransactionData({
          childrenTransactionData,
          childrenTransactionValidation,
          childrenTransactionDataID: childrenTransactionID,
        });

        return {
          childrenTransactionData: currChildrenTransactionData,
          childrenTransactionValidation: currChildrenTransactionValidation,
          transactionData: [
            {
              ...transactionData[0],
              ...formulaHelpers.calculateDiscountAndTotalOfChildrenData({
                parentData: transactionData[0],
                childrenData: currChildrenTransactionData,
              }),
            },
          ],
        };
      },
    },
  },
  [DEFAULT_TRANSACTION_SALES_SALES_PAYMENT_TYPE]: {
    defaultTransctionNumberCode: DEFAULT_TRANSACTION_NUMBER_CODE_SALES_PAYMENT,
    allowKeyInputTransactionData: allowKeysPaymentData,
    allowKeyInputProductTransctionData: allowKeysInvoiceData,
    allowKeyInputChildrenTransactionData: allowKeysInvoiceData,
    keyNameReferenceNumber: DEFAULT_KEY_NAME_SALES_PAYMENT_NO_API_REF,
    keyNameReferenceNumberContainer: DEFAULT_KEY_NAME_SALES_PAYMENT_NO_CONTAINER,
    uniqueKeyNameChildrenTransaction: DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF,
    errorMessagePrimaryMinimumLengthChildrenTransaction:
      'base.message.error.invoice-cannot-less-one.primary',
    errorMessageSecondaryMinimumLengthChildrenTransaction:
      'base.message.error.invoice-cannot-less-one.secondary',
    errorMessagePrimaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-invoice-data.primary',
    errorMessageSecondaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-invoice-data.secondary',
    errorMessagePrimaryInvalidForm: 'base.message.error.invalid-form.primary',
    errorMessageSecondaryInvalidForm: 'base.message.error.invalid-form.secondary',
    generateCreateDataNvalidationTransaction: ({ amount, companyUserData }) =>
      generateDataNValidationAddPaymentSalesPaymentV2({
        amount,
        companyUserData,
        customDataKey: keyNameTransactionData,
        customValidationKey: keyNameTransactionValidation,
      }),
    generateCreateDataNvalidationChildrenTransaction: ({ amount }) =>
      generateDataNValidationAddInvoiceSalesPaymentV2({
        amount,
        customDataKey: keyNameProductTransactionData,
        customValidationKey: keyNameProductTransactionValidation,
      }),
    generalizeDataAddTransaction: generalizeDataAddSalesPayment,
    primaryAddUpdateDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          placeholder: 'placeholder.input-auto',
          inputName: 'transaction_number',
          storeName: ROOT_STORE_NAME_REFERENCE_NUMBER,
          childrenStoreName: SLICE_NAME_REFERENCE_NUMBER_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_SALES_PAYMENT_NO_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_SALES_PAYMENT_NO_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultSecondPointQuarterGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: referenceNumberList,
            additionalParamsForAction: {},
            removeDataAction: clearReferenceNumber,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['code', 'format'],
                ['value'],
                formatHelp.formatTransactionNumber,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'code',
          },
          inputProps: {
            maxLength: DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE,
          },
          isDisabled: ({ actionMenu, parentStateList = {} }) => {
            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE ||
              parentStateList.statusRequest === STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_PENDING
            );
          },
          isUseTextInput: true,
        },
        {
          label: 'label.transaction-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: 'transaction_date',
          keyInputName: 'transaction_date',
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultSecondPointQuarterGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
          isAddOneDayForMinDate: false,
          isRequired: true,
        },
        {
          label: 'label.expired-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: 'expired_date',
          keyInputName: 'expired_date',
          keyNameMinDate: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
          isAddOneDayForMinDate: true,
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultSecondPointQuarterGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
        {
          label: 'label.pay-to',
          placeholder: 'placeholder.pay-to',
          inputName: 'account_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          storeName: NAME_STORE.ACCOUNT,
          childrenStoreName: SLICE_NAME_ACCOUNT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_ACCOUNT_ID_CONTAINER,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          statusRequestListPending: STATUS_REQUEST_LIST_ACCOUNT_DATA_PENDING,
          autocompleteProps: {
            ...defaultAccountAutocompleteProps,
            additionalParamsForAction: {
              category_name: ACCOUNT_CATEGORY_NAME_KAS_N_BANK,
            },
            additionalParamsForScrollAction: {
              category_name: ACCOUNT_CATEGORY_NAME_KAS_N_BANK,
              isRewriteAll: false,
            },
          },
          isRequired: true,
        },
      ],
      [
        {
          label: 'label.contact-customer',
          placeholder: 'placeholder.select-contact-customer',
          inputName: 'contact_name',
          storeName: NAME_STORE.CONTACT,
          childrenStoreName: SLICE_NAME_CONTACT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_CUSTOMER_CONTAINER_ID_API_REF,
          statusRequestListPending: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: contactList,
            additionalParamsForAction: {
              type: ['is_customer'],
            },
            removeDataAction: clearContactData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              type: ['is_customer'],
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['contact_code', 'contact_name'],
                ['contact_name'],
                formatHelp.contactCodeWithName,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'contact_name',
          },
          isUseAdditionalButtonNotFound: true,
          additionalButtonNotFoundProps: {
            textNotFound: 'button.plus-add-new-customer',
            keyNameIsNew: DEFAULT_KEY_NAME_IS_NEW_CONTACT_DATA,
            keynameSwitchDialog: 'contact-data',
            newPageStatus: DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA,
          },
          isDisabled: ({ actionMenu }) => {
            return actionMenu === DEFAULT_ACTION_MENU_UPDATE;
          },
          isRequired: true,
        },
        {
          label: 'label.customer-address',
          placeholder: 'placeholder.customer-address',
          inputName: 'contact_address',
          keyInputName: 'customer_address',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          gridProps: defaultNineGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryAddUpdateDataSchema: [
      [
        {
          label: 'label.total',
          inputName: 'total',
          name: 'amount',
          keyInputName: 'amount',
          isTypography: true,
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
        },
      ],
      [
        // tagAutocompleteInputProps,
        {
          usageInputLabel: false,
          keyInputName: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DROPZONE,
          additionalDropzoneProps: {
            generalizeRequestAction: (attachmentFile, relationValueToOtherAttachment) => {
              return generalizeAttachmentRequest({
                attachmentFile,
                relationValueToOtherAttachment,
                featureDocument: DEFAULT_TRANSACTION_SALES_SALES_PAYMENT_TYPE,
              });
            },
          },
          gridContainerProps: {
            width: 'calc(100% - 2px)',
          },
        },
        {
          label: 'label.memo',
          placeholder: 'placeholder.memo',
          inputName: 'memo',
          keyInputName: 'description',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          additionalOutlinedInput: {
            rows: 3,
            multiline: true,
            className: 'none-padding',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    inputValueChanges: {
      [DEFAULT_KEY_NAME_SALES_PAYMENT_NO_API_REF]: {
        generateChanges: ({ newValue }) => {
          return {
            resultChanges: {
              [DEFAULT_KEY_NAME_SALES_PAYMENT_NO_CONTAINER]: {
                value: newValue,
              },
              [DEFAULT_KEY_NAME_SALES_PAYMENT_NO_API_REF]: newValue,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_AMOUNT_INPUT]: {
        generateChanges: ({
          newValue,
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          childrenTransactionData = arrHelp.combineDataObjInArray(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
            {
              [DEFAULT_KEY_NAME_AMOUNT_INPUT]: newValue,
            },
          );

          const amountInputChildrenTransaction = arrHelp.sumValueArrV2(
            arrHelp.mappingChildrenKeyOnObject(
              childrenTransactionData,
              DEFAULT_KEY_NAME_AMOUNT_INPUT,
            ),
          );

          const latestInvoiceTransactionDate = arrHelp.getLatestDate({
            arr: arrHelp.filteringExistedValue(
              childrenTransactionData,
              DEFAULT_KEY_NAME_AMOUNT_INPUT,
              DEFAULT_EMPTY_CALCULATIONS_NUMBER,
            ),
            dateKeyname: 'transaction_date',
          });

          return {
            transactionDataChanges: {
              amount: amountInputChildrenTransaction,
              [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]: new Date(latestInvoiceTransactionDate),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_QUANTITY_API_REF]: {
        generateChanges: ({
          newValue,
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const { quantity_available_const } = selectedChildrenTransactionData;
          const quantity_available = Number(quantity_available_const) - Number(newValue);

          return {
            resultChanges: {
              quantity_available,
            },
          };
        },
      },
    },
    autocompleteValueChanges: {
      [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF]: {
        isHitEndpoint: true,
        action: getListSalesInvoiceWithContactID,
        actionRemove: () => clearSalesInvoiceListWithContactID(),
        storeName: NAME_STORE.CONTACT,
        childrenSliceName: SLICE_NAME_CONTACT_SALES_INVOICE_LIST_WITH_CONTACT_ID,
        generateParamsAction: ({ newAutocompleteChanges = {} }) => {
          return {
            ...DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION_SECONDARY,
            [DEFAULT_KEY_NAME_CONTACT_ID_API_REF]:
              newAutocompleteChanges[DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF] || '',
            status: [
              DEFAULT_STATUS_SALES_INVOICE_OPEN,
              DEFAULT_STATUS_SALES_INVOICE_PARTIALLY_PAID,
            ],
          };
        },
        generateChanges: ({
          newValue,
          resultGetAction,
          currentTransactionData,
          transactionValidation = [],
          companyUserData,
        }) => {
          const { id: idTransactionData } = currentTransactionData[0];

          const salesInvoiceList = isEmpty(resultGetAction)
            ? []
            : resultGetAction[DEFAULT_KEY_NAME_RESPONSE_DATA_API_REF];

          let newTransactionData = cloneDeep(currentTransactionData),
            newTransactionValidation = cloneDeep(transactionValidation);

          if (!isEmpty(transactionValidation)) {
            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              newTransactionValidation[0],
              [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF],
              true,
              isEmpty(newValue),
            );

            newTransactionValidation = [newValidation];
          }

          const { childrenTransactionData, childrenTransactionValidation } =
            generateDataNValidationAddInvoiceFromListSIv2({
              salesInvoiceList,
              customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
              customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
            });

          let sumAmountInputInvoiceSalesPayment = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            DEFAULT_KEY_NAME_AMOUNT_INPUT,
          );
          if (Array.isArray(sumAmountInputInvoiceSalesPayment)) {
            sumAmountInputInvoiceSalesPayment = arrHelp.sumValueArrV2(
              sumAmountInputInvoiceSalesPayment,
            );

            newTransactionData = arrHelp.combineDataObjInArray(
              newTransactionData,
              'id',
              idTransactionData,
              {
                amount: sumAmountInputInvoiceSalesPayment,
              },
            );
          }

          if (!isEmpty(currentTransactionData)) {
            const latestInvoiceTransaction = cloneDeep(salesInvoiceList).sort(
              (salesInvoice, nextSalesInvoice) =>
                new Date(nextSalesInvoice[DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF]) -
                new Date(salesInvoice[DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF]),
            )[0];

            const minDateInput = getAccountingPeriodCompareWithDocumentTransactionDate({
              companyUserData,
              documentData: latestInvoiceTransaction,
            });

            const customerAddress = newValue
              ? newValue[DEFAULT_KEY_NAME_SHIPPING_ADDRESS_API_REF] || ''
              : '';

            newTransactionData = arrHelp.combineDataObjInArray(
              newTransactionData,
              'id',
              idTransactionData,
              {
                customer_address: customerAddress,
                [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]: isEmpty(salesInvoiceList)
                  ? new Date()
                  : new Date(minDateInput),
              },
            );
          }

          if (isEmpty(newValue)) {
            newTransactionData = arrHelp.combineDataObjInArray(
              newTransactionData,
              'id',
              idTransactionData,
              {
                [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]: new Date(),
              },
            );
          }

          return {
            transactionData: newTransactionData,
            transactionValidation: newTransactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
    },
    gridHeaderChildrenTransaction: gridHeaderInvoiceSalesPayment,
    gridCollapseChildrenTransaction: gridCollapseInvoiceSalesPayment,
    dependingKeyNameTransactionValidation: ['transaction_date', 'expired_date'], // validation form on transaction
    anotherKeyNameTransactionDataDepends: [
      DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
      'transaction_date',
    ], // validation form on data transaction
    keyNameBypassChildrenTransactionValidation: ['sales_invoice_no', 'amount_input'],
    checkingIsEmptyChildrenTransactionKeyNameBypass: [
      [null, ''],
      DEFAULT_EMPTY_CALCULATIONS_NUMBER,
    ],
    keyNameCheckingValidationInputBypass: ['amount_input', 'price'],
    keyNameCheckingStatusChildrenTransaction: ['sales_invoice_no'],
    menuActionUpdateProps: {
      [DEFAULT_KEY_NAME_PARAMS_URL_SALES_PAYMENT_ID]: {
        getAction: (paramsRouter) =>
          getDetailSalesPayment(paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_SALES_PAYMENT_ID]),
        // dependencies action when after getting primary action (above action)
        onDependenciesActions: [defaultOnDependenciesListDetailsAttachmentAction],
        parentStateName: NAME_STORE.SALES,
        childrenStateName: SLICE_NAME_SALES_PAYMENT_DETAIL,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNValidationUpdateSalesPaymentFromDetailSPv2({
            salesPaymentDetails: resultGetAction,
            ...params,
            customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: (resultGetAction) =>
          generateDataNValidationUpdateInvoiceSalesPaymentFromDetailSPv2({
            salesPaymentDetails: resultGetAction,
            customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
          }),
        // usage for getting actions when update action, must array
        additionalActionsMenuUpdate: [
          {
            getAction: (paramsGetAction) =>
              getListSalesInvoiceWithContactID({
                ...paramsGetAction,
                ...DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION_SECONDARY,
                status: [
                  DEFAULT_STATUS_SALES_INVOICE_OPEN,
                  DEFAULT_STATUS_SALES_INVOICE_PARTIALLY_PAID,
                ],
              }),
            generateParamsAction: ({ transactionData }) => {
              if (
                isEmpty(transactionData) ||
                !Array.isArray(transactionData) ||
                !transactionData.length
              )
                return {};

              return {
                [DEFAULT_KEY_NAME_CONTACT_ID_API_REF]:
                  transactionData[0][DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF],
              };
            },
            parentStateName: NAME_STORE.CONTACT,
            childrenStateName: SLICE_NAME_CONTACT_SALES_INVOICE_LIST_WITH_CONTACT_ID,
            keyNameResultActionStore: SLICE_NAME_CONTACT_SALES_INVOICE_LIST_WITH_CONTACT_ID,
          },
        ],
        // you must return transactionData, transactionValidation, childrenTransactionData, and childrenTransactionValidation
        // usage for adding state after additional actions for update
        generateStateDataNvalidationAssetsFromAdditionalActions: ({
          transactionData,
          transactionValidation,
          childrenTransactionData,
          childrenTransactionValidation,
          resultAdditionalActions,
        }) => {
          let newChildrenTransactionData = childrenTransactionData,
            newChildrenTransactionValidation = childrenTransactionValidation;

          if (
            !isEmpty(
              resultAdditionalActions[SLICE_NAME_CONTACT_SALES_INVOICE_LIST_WITH_CONTACT_ID],
            ) &&
            Array.isArray(
              resultAdditionalActions[SLICE_NAME_CONTACT_SALES_INVOICE_LIST_WITH_CONTACT_ID].data,
            ) &&
            resultAdditionalActions[SLICE_NAME_CONTACT_SALES_INVOICE_LIST_WITH_CONTACT_ID].data
              .length
          ) {
            const {
              childrenTransactionData: childrenTransactionDataGen,
              childrenTransactionValidation: childrenTransactionValidationGen,
            } = generateDataNvalidationInvoiceSalesPaymentFromSalesInvoiceList({
              currentInvoiceSalesPaymentData: childrenTransactionData,
              currentInvoiceSalesPaymentValidation: childrenTransactionValidation,
              customDataKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
              customValidationKey: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
              invoiceList:
                resultAdditionalActions[SLICE_NAME_CONTACT_SALES_INVOICE_LIST_WITH_CONTACT_ID].data,
            });

            newChildrenTransactionData = childrenTransactionDataGen;
            newChildrenTransactionValidation = childrenTransactionValidationGen;

            const { id: idTransactionData } = transactionData[0];

            let sumAmountInputInvoiceSalesPayment = arrHelp.mappingChildrenKeyOnObject(
              newChildrenTransactionData,
              DEFAULT_KEY_NAME_AMOUNT_INPUT,
            );
            if (Array.isArray(sumAmountInputInvoiceSalesPayment)) {
              sumAmountInputInvoiceSalesPayment = arrHelp.sumValueArrV2(
                sumAmountInputInvoiceSalesPayment,
              );

              transactionData = arrHelp.combineDataObjInArray(
                transactionData,
                'id',
                idTransactionData,
                {
                  amount: sumAmountInputInvoiceSalesPayment,
                },
              );
            }
          }

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData: newChildrenTransactionData,
            childrenTransactionValidation: newChildrenTransactionValidation,
          };
        },
      },
    },
    additionalSettingDefaultInputActionMenuAdd: [
      {
        // discount
        parentStateName: NAME_STORE.ACCOUNT,
        childrenListStateName: SLICE_NAME_ACCOUNT_DATA,
        keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
        keyNameSubResponseData: DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF,
        defaultKeyInputName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
        defaultKeyInputContainerName: DEFAULT_KEY_NAME_ACCOUNT_ID_CONTAINER,
        indexSelected: 0,
        action: getAccountList,
        additionalParams: {
          [DEFAULT_KEY_NAME_CATEGORY_NAME_API_REF]: ACCOUNT_CATEGORY_NAME_CASH_N_BANK,
        },
        isAddDefaultInput: ({ transactionData = [] }) => {
          return (
            isEmpty(transactionData) || !transactionData[0][DEFAULT_KEY_NAME_ACCOUNT_ID_CONTAINER]
          );
        },
        isListData: true,
      },
    ],
    additionalSettingDefaultInputActionMenuUpdate: [
      {
        // discount
        parentStateName: NAME_STORE.ACCOUNT,
        childrenListStateName: SLICE_NAME_ACCOUNT_DATA,
        keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
        keyNameSubResponseData: DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF,
        defaultKeyInputName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
        defaultKeyInputContainerName: DEFAULT_KEY_NAME_ACCOUNT_ID_CONTAINER,
        indexSelected: 0,
        action: getAccountList,
        additionalParams: {
          [DEFAULT_KEY_NAME_CATEGORY_NAME_API_REF]: ACCOUNT_CATEGORY_NAME_CASH_N_BANK,
        },
        isAddDefaultInput: ({ transactionData = [] }) => {
          return (
            isEmpty(transactionData) || !transactionData[0][DEFAULT_KEY_NAME_ACCOUNT_ID_CONTAINER]
          );
        },
        isListData: true,
      },
    ],
    generateDataForSettingDefaultInputActionMenuAdd: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    generateDataForSettingDefaultInputActionMenuUpdate: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    additionalPropsSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        generateDataNValidationProfile: generateDataNValidationAddProfileForIsCustomer,
        isDisabledContactType: [DEFAULT_CONTACT_IS_CUSTOMER_TYPE],
      },
    },
    additionalActionSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        parentStateName: NAME_STORE.CONTACT,
        childrenStateName: SLICE_NAME_TEMP_ADD_CONTACT_DATA,
        generalizeForState: ({
          resultSwitchDialog = {},
          keyStateContainer,
          keyValidationContainer,
          selectedIndex = 0,
          ...stateDataNvalidation
        }) => {
          let {
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          } = stateDataNvalidation;

          if (!isEmpty(resultSwitchDialog)) {
            const { id } = selectedStateContainer[selectedIndex];

            selectedStateContainer = arrHelp.combineDataObjInArray(
              selectedStateContainer,
              'id',
              id,
              {
                [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF]:
                  resultSwitchDialog[DEFAULT_KEY_NAME_CONTACT_ID_API_REF],
                [DEFAULT_KEY_NAME_CUSTOMER_CONTAINER_ID_API_REF]: resultSwitchDialog,
              },
            );

            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              selectedValidationContainer[0],
              [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF],
              true,
              false,
            );

            selectedValidationContainer = arrHelp.combineDataObjInArray(
              selectedValidationContainer,
              'id',
              id,
              newValidation,
            );
          }

          return {
            ...stateDataNvalidation,
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          };
        },
      },
    },
    parentMenuURL: REACT_APP_SALES_SALES_PAYMENT_URL,
  },
  // expense menu
  [DEFAULT_TRANSACTION_EXPENSE_TYPE]: {
    defaultTransctionNumberCode: DEFAULT_TRANSACTION_NUMBER_CODE_EXPENSE,
    allowKeyInputTransactionData: allowKeysExpenseData,
    allowKeyInputChildrenTransactionData: allowKeysDestinationAccountData,
    keyNameReferenceNumber: DEFAULT_KEY_NAME_REFERENCE_NUMBER,
    keyNameReferenceNumberContainer: DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER,
    keyNameTransactionDate: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
    keyNameAccountingPeriod: DEFAULT_KEY_NAME_ACCOUNTING_PERIOD,
    keyNameDiscountType: DEFAULT_KEY_NAME_DISCOUNT_TYPE_API_REF,
    keyNameDiscountPercentage: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
    keyNameDiscountNominal: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
    keyNameDiscountAccountID: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_API_REF,
    keyNameDiscountAccountIDcontainer: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_CONTAINER,
    keyNameMaximumDownPaymentValue: DEFAULT_KEY_NAME_AMOUNT_REF_API,
    minimumLengthChildrenTransaction: 1,
    uniqueKeyNameParentTransaction: DEFAULT_KEY_NAME_ORIGIN_ACCOUNT_ID_API_REF,
    uniqueKeyNameChildrenTransaction: DEFAULT_KEY_NAME_DESTINATION_ACCOUNT_ID_API_REF,
    errorMessagePrimaryMinimumLengthChildrenTransaction:
      'base.message.error.destination-account-cannot-less-one.primary',
    errorMessageSecondaryMinimumLengthChildrenTransaction:
      'base.message.error.destination-account-cannot-less-one.secondary',
    errorMessagePrimaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-destination-account.primary',
    errorMessageSecondaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-destination-account.secondary',
    errorMessagePrimaryDuplicatedChildrenNParentTransaction:
      'base.message.error.origin-and-destination-account-cannot-same.primary',
    errorMessageSecondaryDuplicatedChildrenNParentTransaction:
      'base.message.error.origin-and-destination-account-cannot-same.secondary',
    errorMessagePrimaryInvalidForm: 'base.message.error.invalid-form.primary',
    errorMessageSecondaryInvalidForm: 'base.message.error.invalid-form.secondary',
    relationalKeyDiscountTypeNvalue: {
      [DEFAULT_DISCOUNT_PERCENTAGE_TYPE]: {
        keyInputName: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
        maximumValue: 100,
        representationDataType: DEFAULT_REPRESENTATION_DATA_NUMBER_TYPE,
      },
      [DEFAULT_DISCOUNT_NOMINAL_TYPE]: {
        keyInputName: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
        keyNameMaximumValue: DEFAULT_KEY_NAME_AMOUNT_CONST,
        representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
      },
    },
    isAdditionalCostShow: true,
    splicingAdditionalCostInput: [0, 1],
    isMinutesHandoverShow: false,
    isFormStatusShow: false,
    generateCreateDataNvalidationTransaction: ({ amount, companyUserData }) =>
      generateDataNValidationAddExpense({
        amount,
        companyUserData,
        customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
        customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
      }),
    generateCreateDataNvalidationChildrenTransaction: ({ amount }) =>
      generateDataNValidationAddDestinationAccount(
        amount,
        DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
        DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
      ),
    primaryAddUpdateDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          placeholder: 'placeholder.input-auto',
          inputName: 'transaction_number',
          storeName: ROOT_STORE_NAME_REFERENCE_NUMBER,
          childrenStoreName: SLICE_NAME_REFERENCE_NUMBER_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_REFERENCE_NUMBER,
          keyInputContainerName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultGridPropsSecondary,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: referenceNumberList,
            additionalParamsForAction: {},
            removeDataAction: clearReferenceNumber,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['code', 'format'],
                ['value'],
                formatHelp.formatTransactionNumber,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'code',
          },
          inputProps: {
            maxLength: DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE,
          },
          isDisabled: ({ actionMenu, parentStateList = {} }) => {
            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE ||
              parentStateList.statusRequest === STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_PENDING
            );
          },
          isUseTextInput: true,
          isRequired: true,
        },
        {
          label: 'label.transaction-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: 'transaction_date',
          keyInputName: 'transaction_date',
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultGridPropsSecondary,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: '',
          isRequired: true,
        },
        {
          label: 'label.contact',
          placeholder: 'placeholder.select-contact',
          inputName: 'contact_name',
          storeName: NAME_STORE.CONTACT,
          childrenStoreName: SLICE_NAME_CONTACT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_CONTACT_ID_CONTAINER_API_REF,
          statusRequestListPending: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultGridPropsSecondary,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: contactList,
            additionalParamsForAction: {},
            removeDataAction: clearContactData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['contact_code', 'contact_name'],
                ['contact_name'],
                formatHelp.contactCodeWithName,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'contact_name',
          },
          isUseAdditionalButtonNotFound: true,
          additionalButtonNotFoundProps: {
            textNotFound: 'button.plus-add-new-contact',
            keyNameIsNew: DEFAULT_KEY_NAME_IS_NEW_CONTACT_DATA,
            keynameSwitchDialog: 'contact-data',
            newPageStatus: DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA,
          },
        },
        {
          label: 'label.origin-account',
          placeholder: 'placeholder.select-account',
          inputName: 'account_name',
          keyInputName: DEFAULT_KEY_NAME_ORIGIN_ACCOUNT_ID_API_REF,
          gridProps: defaultGridPropsSecondary,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          storeName: NAME_STORE.ACCOUNT,
          childrenStoreName: SLICE_NAME_ACCOUNT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_ORIGIN_ACCOUNT_ID_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_ACCOUNT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          autocompleteProps: {
            ...defaultAccountAutocompleteProps,
            additionalParamsForAction: {
              category_name: ACCOUNT_CATEGORY_NAME_KAS_N_BANK,
            },
            additionalParamsForScrollAction: {
              category_name: ACCOUNT_CATEGORY_NAME_KAS_N_BANK,
              isRewriteAll: false,
            },
          },
          isUseTextInput: false,
          isRequired: true,
        },
      ],
    ],
    secondaryAddUpdateDataSchema: [
      [
        {
          label: 'label.total',
          name: 'amount',
          inputName: 'amount',
          keyInputName: 'amount',
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
          isTypography: true,
        },
      ],
      [
        tagAutocompleteInputProps,
        {
          usageInputLabel: false,
          keyInputName: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DROPZONE,
          additionalDropzoneProps: {
            generalizeRequestAction: (attachmentFile, relationValueToOtherAttachment) => {
              return generalizeAttachmentRequest({
                attachmentFile,
                relationValueToOtherAttachment,
                featureDocument: DEFAULT_TRANSACTION_EXPENSE_API_REF_TYPE,
              });
            },
          },
          gridContainerProps: {
            width: 'calc(100% - 2px)',
          },
        },
        {
          label: 'label.memo',
          placeholder: 'label.memo',
          inputName: 'memo',
          keyInputName: 'description',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          additionalOutlinedInput: {
            rows: 3,
            multiline: true,
            className: 'none-padding',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    inputValueChanges: {
      [DEFAULT_KEY_NAME_REFERENCE_NUMBER]: {
        generateChanges: ({ newValue }) => {
          return {
            resultChanges: {
              [DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER]: {
                value: newValue,
              },
              [DEFAULT_KEY_NAME_REFERENCE_NUMBER]: newValue,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_AMOUNT_REF_API]: {
        generateChanges: ({
          newValue,
          transactionData = [],
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const amountTransactionData = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { amount: amountPast } = selectedChildrenTransactionData;

          const amountChildrenTransactionWithoutPast = amountTransactionData - amountPast;
          const currentAmountSelectedChildrenTransaction = Number(newValue);

          const { discount_nominal, discount_percentage } = formulaCountDiscountCost({
            allAmountTransaction:
              amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction,
            transactionData,
          });

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction:
              amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction,
            discountNominal: discount_nominal,
          });

          return {
            resultChanges: {
              amount: currentAmountSelectedChildrenTransaction,
            },
            transactionDataChanges: {
              amount: Number(newAmountTransaction),
              amount_const: Number(newAmountTransaction),
              discount_nominal: Number(discount_nominal) || '',
              discount_percentage: Number(discount_percentage) || '',
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          let newDiscountNominal = Number(amountChildrenTransaction) * (Number(newValue) / 100);

          newDiscountNominal = Number(newDiscountNominal).toFixed(2);

          const { expense_value, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: newDiscountNominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
              [DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF]: Number(newDiscountNominal),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: newValue,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          const newDiscountPercentage = amountChildrenTransaction
            ? Number((Number(newValue) / Number(amountChildrenTransaction)) * 100).toFixed(2)
            : 0;

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
              [DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF]: Number(newDiscountPercentage),
            },
          };
        },
      },
    },
    autocompleteValueChanges: {
      [DEFAULT_KEY_NAME_DESTINATION_ACCOUNT_ID_API_REF]: {
        generateChanges: ({
          newValue,
          transactionData = [],
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const { account_description } = newValue || {};

          // amount current selected transaction data
          const amount = DEFAULT_CALCULATION_NUMBER;

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const sumAmountsChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const { amount: amountChildrenTransaction } = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          let newAmountTransactionData = !isEmpty(newValue)
            ? Number(sumAmountsChildrenTransaction) -
              Number(amountChildrenTransaction) +
              Number(amount)
            : Number(sumAmountsChildrenTransaction) - Number(amountChildrenTransaction);

          const expenseValue = Number(newAmountTransactionData) <= 0 ? '' : expense_value;

          const downPaymentValue = Number(newAmountTransactionData) <= 0 ? '' : down_payment_value;

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: newAmountTransactionData,
            expenseValue,
            downPaymentValue,
          });

          return {
            resultChanges: {
              description: account_description || '',
              amount: Number(amount) || '',
            },
            transactionDataChanges: {
              amount: Number(newAmountTransaction),
              amount_const: Number(newAmountTransactionData),
            },
          };
        },
      },
    },
    gridHeaderChildrenTransaction: gridHeaderDestinationAccountOnExpense,
    // dependingKeyNameTransactionValidation: ['transaction_date', 'expired_date'],        // validation form on transaction
    anotherKeyNameTransactionDataDepends: ['accounting_period', 'transaction_date'], // validation form on data transaction
    keyNameBypassChildrenTransactionValidation: ['account_destination_id'],
    checkingIsEmptyChildrenTransactionKeyNameBypass: [
      [null, ''],
      DEFAULT_EMPTY_CALCULATIONS_NUMBER,
    ],
    keyNameCheckingValidationInputBypass: [],
    keyNameCheckingStatusChildrenTransaction: ['account_destination_id', 'account_origin_id'],
    menuActionAddProps: {},
    menuActionUpdateProps: {
      [DEFAULT_KEY_NAME_PARAMS_URL_EXPENSE_ID]: {
        getAction: (paramsRouter) =>
          getExpenseDetail(paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_EXPENSE_ID]),
        parentStateName: NAME_STORE.EXPENSE,
        childrenStateName: SLICE_NAME_EXPENSE_DETAIL,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNValidationUpdateExpenseFromDetailExpense({
            detailExpense: resultGetAction,
            ...params,
            customDataKey: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            customValidationKey: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: (resultGetAction) => {
          const { destinationAccountData, destinationAccountValidation } =
            generateDataNValidationUpdateDestinationAccountFromDetailExpense(resultGetAction);

          return {
            [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: destinationAccountData,
            [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION]: destinationAccountValidation,
          };
        },
      },
    },
    additionalSettingDefaultInputActionMenuAdd: [
      ...defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_EXPENSE_TYPE],
      {
        // account origin
        parentStateName: NAME_STORE.ACCOUNT,
        childrenListStateName: SLICE_NAME_ACCOUNT_DATA,
        keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
        keyNameSubResponseData: DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF,
        defaultKeyInputName: DEFAULT_KEY_NAME_ORIGIN_ACCOUNT_ID_API_REF,
        defaultKeyInputContainerName: DEFAULT_KEY_NAME_ORIGIN_ACCOUNT_ID_CONTAINER,
        indexSelected: 0,
        action: getAccountList,
        additionalParams: {
          [DEFAULT_KEY_NAME_ACCOUNT_NAME_API_REF]: DEFAULT_ACCOUNT_NAME_CASH,
        },
        isAddDefaultInput: ({ transactionData = [] }) => {
          return (
            isEmpty(transactionData) ||
            !transactionData[0][DEFAULT_KEY_NAME_ORIGIN_ACCOUNT_ID_API_REF]
          );
        },
        isListData: true,
      },
    ],
    additionalSettingDefaultInputActionMenuUpdate: [
      ...defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_EXPENSE_TYPE],
    ],
    generateDataForSettingDefaultInputActionMenuAdd: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    generateDataForSettingDefaultInputActionMenuUpdate: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    additionalPropsSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        generateDataNValidationProfile: generateDataNValidationAddProfile,
        isDisabledContactType: [DEFAULT_CONTACT_IS_SUPPLIER_TYPE],
      },
    },
    additionalActionSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        parentStateName: NAME_STORE.CONTACT,
        childrenStateName: SLICE_NAME_TEMP_ADD_CONTACT_DATA,
        generalizeForState: ({
          resultSwitchDialog = {},
          keyStateContainer,
          keyValidationContainer,
          selectedIndex = 0,
          ...stateDataNvalidation
        }) => {
          let {
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          } = stateDataNvalidation;

          if (!isEmpty(resultSwitchDialog)) {
            const { id } = selectedStateContainer[selectedIndex];

            selectedStateContainer = arrHelp.combineDataObjInArray(
              selectedStateContainer,
              'id',
              id,
              {
                [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF]:
                  resultSwitchDialog[DEFAULT_KEY_NAME_CONTACT_ID_API_REF],
                [DEFAULT_KEY_NAME_SUPPLIER_CONTAINER_ID_API_REF]: resultSwitchDialog,
              },
            );

            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              selectedValidationContainer[0],
              [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF],
              true,
              false,
            );

            selectedValidationContainer = arrHelp.combineDataObjInArray(
              selectedValidationContainer,
              'id',
              id,
              newValidation,
            );
          }

          return {
            ...stateDataNvalidation,
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          };
        },
      },
    },
    parentMenuURL: REACT_APP_EXPENSE_URL,
    handleDeleteRow: {
      childrenTransactionData: ({
        transactionData,
        childrenTransactionData,
        childrenTransactionValidation,
        childrenTransactionID = '',
      }) => {
        const {
          childrenTransactionData: currChildrenTransactionData,
          childrenTransactionValidation: currChildrenTransactionValidation,
        } = filteringChildrenTransactionData({
          childrenTransactionData,
          childrenTransactionValidation,
          childrenTransactionDataID: childrenTransactionID,
        });

        return {
          childrenTransactionData: currChildrenTransactionData,
          childrenTransactionValidation: currChildrenTransactionValidation,
          transactionData: [
            {
              ...transactionData[0],
              ...formulaHelpers.calculateDiscountAndTotalOfChildrenData({
                parentData: transactionData[0],
                childrenData: currChildrenTransactionData,
              }),
            },
          ],
        };
      },
    },
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_RETURN_TYPE]: {
    defaultTransctionNumberCode: DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_RETURN,
    allowKeyInputTransactionData: allowKeysDataPurchaseReturn,
    allowKeyInputChildrenTransactionData: allowKeysDataProductPurchaseReturn,
    keyNameReferenceNumber: DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF,
    keyNameReferenceNumberContainer: DEFAULT_KEY_NAME_TRANSACTION_NO_CONTAINER,
    keyNameTransactionDate: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
    keyNameAccountingPeriod: DEFAULT_KEY_NAME_ACCOUNTING_PERIOD,
    keyNameDiscountType: DEFAULT_KEY_NAME_DISCOUNT_TYPE_API_REF,
    keyNameDiscountPercentage: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
    keyNameDiscountNominal: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
    keyNameDiscountAccountID: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_API_REF,
    keyNameDiscountAccountIDcontainer: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_CONTAINER,
    keyNameDownPaymentAccountID: DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_API_REF,
    keyNameDownPaymentAccountIDcontainer: DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_CONTAINER,
    keyNameDownPaymentValue: DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE,
    keyNameExpenseAccountID: DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_API_REF,
    keyNameExpenseAccountIDcontainer: DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_CONTAINER,
    keyNameExpenseValue: DEFAULT_KEY_NAME_EXPENSE_VALUE,
    keyNameMaximumDownPaymentValue: defaultKeyNameMaximumDownPaymentValue,
    minimumLengthChildrenTransaction: 1,
    uniqueKeyNameChildrenTransaction: DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
    statusFreeUpdateInputTransaction: [DEFAULT_STATUS_PURCHASE_RETURN_OPEN],
    errorMessagePrimaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.primary',
    errorMessageSecondaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.secondary',
    errorMessagePrimaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.primary',
    errorMessageSecondaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.secondary',
    errorMessagePrimaryInvalidForm: 'base.message.error.invalid-form.primary',
    errorMessageSecondaryInvalidForm: 'base.message.error.invalid-form.secondary',
    relationalKeyDiscountTypeNvalue: {
      [DEFAULT_DISCOUNT_PERCENTAGE_TYPE]: {
        keyInputName: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
        maximumValue: 100,
        representationDataType: DEFAULT_REPRESENTATION_DATA_NUMBER_TYPE,
      },
      [DEFAULT_DISCOUNT_NOMINAL_TYPE]: {
        keyInputName: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
        keyNameMaximumValue: DEFAULT_KEY_NAME_AMOUNT_CONST,
        representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
      },
    },
    splicingAdditionalCostInput: [0, 1],
    isAdditionalCostShow: true,
    // indicate for additional cost is combined
    additionalCostCombinedOnFormSchema: true,
    isFormStatusShow: true,
    statusInputFormSchema: [
      {
        label: 'label.status',
        inputType: DEFAULT_INPUT_TYPE_CHECKBOX_WITH_LABEL,
        keyInputName: DEFAULT_KEY_NAME_IS_PAID_OFF_API_REF,
        labelChecked: 'typography.paid-off-settled',
        labelUnChecked: 'typography.paid-off-settled',
        isDisabled: ({ transactionData }) => {
          if (isEmpty(transactionData)) return true;

          const { purchase_invoice_id, amount, invoice_total } = transactionData[0];

          return !purchase_invoice_id || Number(amount) <= Number(invoice_total);
        },
      },
    ],
    // showing floating from status
    showFormStatusFloating: true,
    // isAdditionalCostShow: true,
    // additionalCostFormSchema: additionalCostPurchaseForm,
    isMinutesHandoverShow: false,
    // isFormStatusShow: true,
    generateCreateDataNvalidationTransaction: ({ amount }) =>
      generateDataNvalidationAddPurchaseReturn({
        amount,
        dataKeyname: DEFAULT_KEY_NAME_TRANSACTION_DATA,
        validationKeyname: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
      }),
    generateCreateDataNvalidationChildrenTransaction: ({ amount }) =>
      generateDataNvalidationAddProductPurchaseReturn({
        amount,
        dataKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
        validationKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
      }),
    generalizeDataAddTransaction: () => null,
    primaryAddUpdateDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          placeholder: 'placeholder.input-auto',
          inputName: 'transaction_no',
          storeName: ROOT_STORE_NAME_REFERENCE_NUMBER,
          childrenStoreName: SLICE_NAME_REFERENCE_NUMBER_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_TRANSACTION_NO_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultSecondPointQuarterGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: referenceNumberList,
            additionalParamsForAction: {},
            removeDataAction: clearReferenceNumber,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['code', 'format'],
                ['value'],
                formatHelp.formatTransactionNumber,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'code',
          },
          inputProps: {
            maxLength: DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE,
          },
          isDisabled: ({ actionMenu, parentStateList = {} }) => {
            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE ||
              parentStateList.statusRequest === STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_PENDING
            );
          },
          isUseTextInput: true,
          isRequired: true,
        },
        {
          label: 'label.invoice-number',
          placeholder: 'placeholder.choose-invoice-no',
          inputName: DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF,
          storeName: ROOT_STORE_NAME_PURCHASE,
          childrenStoreName: SLICE_NAME_DATA_PURCHASE_INVOICE,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF,
          statusRequestListPending: STATUS_REQUEST_LIST_PURCHASE_INVOICE_PENDING,
          keyInputName: DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF,
          gridProps: defaultSecondPointQuarterGridProps,
          keyInputContainerName: DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_CONTAINER,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          getAdditionalParamsForAction: ({ transactionData }) => {
            const { supplier_id_container } = transactionData[0];

            if (isEmpty(supplier_id_container)) return {};

            return {
              supplier_name: supplier_id_container.contact_name,
            };
          },
          autocompleteProps: {
            fetchAction: getListPurchaseInvoice,
            additionalParamsForAction: {
              is_return_allowed: 1,
            },
            removeDataAction: clearPurchaseInvoiceData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              is_return_allowed: 1,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getOptionLabel(options, 'purchase_invoice_no'),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'transaction_no',
          },
          isDisabled: ({ actionMenu }) => {
            return actionMenu === DEFAULT_ACTION_MENU_UPDATE;
          },
          isRequired: true,
        },
        {
          label: 'label.transaction-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
          keyInputName: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultSecondPointQuarterGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
          keyNameMaxDate: 'today',
          isAddOneDayForMinDate: false,
          isRequired: true,
          isDisabled: () => {
            return false;
          },
        },
      ],
      [
        {
          label: 'label.contact-supplier',
          placeholder: 'placeholder.select-contact-supplier',
          inputName: DEFAULT_KEY_NAME_CONTACT_NAME_API_REF,
          storeName: NAME_STORE.CONTACT,
          childrenStoreName: SLICE_NAME_CONTACT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_SUPPLIER_CONTAINER_ID_API_REF,
          statusRequestListPending: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultFourthPointHalfGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: contactList,
            additionalParamsForAction: {
              type: ['is_supplier'],
            },
            removeDataAction: clearContactData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              type: ['is_supplier'],
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                [DEFAULT_KEY_NAME_CONTACT_CODE_API_REF, DEFAULT_KEY_NAME_CONTACT_NAME_API_REF],
                [DEFAULT_KEY_NAME_CONTACT_NAME_API_REF],
                formatHelp.contactCodeWithName,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: DEFAULT_KEY_NAME_CONTACT_NAME_API_REF,
          },
          isUseAdditionalButtonNotFound: true,
          additionalButtonNotFoundProps: {
            textNotFound: 'button.plus-add-new-contact',
            keyNameIsNew: DEFAULT_KEY_NAME_IS_NEW_CONTACT_DATA,
            keynameSwitchDialog: 'contact-data',
            newPageStatus: DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA,
          },
          isDisabled: ({ actionMenu, transactionData }) => {
            const { purchase_invoice_id } = transactionData[0];

            return actionMenu === DEFAULT_ACTION_MENU_UPDATE || !!purchase_invoice_id;
          },
        },
        {
          label: 'label.supplier-address',
          placeholder: 'placeholder.supplier-address',
          inputName: DEFAULT_KEY_NAME_SUPPLIER_ADDRESS_API_REF,
          keyInputName: DEFAULT_KEY_NAME_SUPPLIER_ADDRESS_API_REF,
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          gridProps: defaultSeventPointHalfGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isDisabled: ({ actionMenu }) => {
            return actionMenu === DEFAULT_ACTION_MENU_UPDATE;
          },
        },
      ],
    ],
    secondaryAddUpdateDataSchema: [
      [
        {
          label: 'label.current-invoice-amount',
          useTemplateLabel: false,
          labelTranslationKeynameProps: ['invoiceNumber'],
          templateLabelKeyname: 'purchase_invoice_no',
          name: 'invoice_total',
          typographyLabelProps: {
            variant: 'boldIndicator2',
          },
          typographyValueProps: {
            variant: 'body8',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
          isTypography: true,
          emptyValueSubstitute: 0,
        },
        {
          representationDataType: DEFAULT_REPRESENTATION_DATA_HORIZONTAL_LINE_TYPE,
        },
        {
          label: 'label.subtotal',
          name: 'amount_const',
          typographyLabelProps: {
            variant: 'boldIndicator2',
          },
          typographyValueProps: {
            variant: 'body8',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
          isTypography: true,
          emptyValueSubstitute: 0,
        },
        // {
        //     label: 'label.discount-per-item',
        //     name: 'discountPerItem',
        //     typographyValueProps: {
        //         variant: 'body6',
        //     },
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
        //     isTypography: true,
        //     emptyValueSubstitute: 0,
        // },
        {
          label: 'label.additional-discount',
          name: 'discount_nominal',
          typographyValueProps: {
            variant: 'body6',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
          isTypography: true,
          emptyValueSubstitute: 0,
        },
        // {
        //     isTypography: true,
        //     label: 'label.total-after-discount',
        //     keyNameValue: 'totalAfterDiscount',
        //     typographyValueProps: {
        //         variant: 'body6',
        //     },
        //     isCalculateBasedKeyname: true,
        //     calculateKeynames: defaultKeynamesCalculationTotalAfterDiscountReturnVersion,
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
        //     emptyValueSubstitute: 0,
        // },
        {
          representationDataType: DEFAULT_REPRESENTATION_DATA_HORIZONTAL_LINE_TYPE,
        },
        {
          isTypography: true,
          label: 'label.total',
          name: 'amount',
          typographyLabelProps: {
            variant: 'boldIndicator2',
          },
          calculateKeynames: defaultKeynamesCalculationTotalAfterDiscountReturnVersion,
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
          emptyValueSubstitute: 0,
        },
      ],
      [
        // tagAutocompleteInputProps,
        {
          label: 'label.memo',
          placeholder: 'label.memo',
          inputName: 'memo',
          keyInputName: 'description',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          additionalOutlinedInput: {
            rows: 2,
            multiline: true,
            className: 'none-padding',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
        {
          usageInputLabel: false,
          keyInputName: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DROPZONE,
          additionalDropzoneProps: {
            generalizeRequestAction: (attachmentFile, relationValueToOtherAttachment) => {
              return generalizeAttachmentRequest({
                attachmentFile,
                relationValueToOtherAttachment,
                featureDocument: DEFAULT_TRANSACTION_SALES_SALES_INVOICE_TYPE,
              });
            },
          },
          gridContainerProps: {
            width: 'calc(100% - 2px)',
          },
        },
      ],
    ],
    inputValueChanges: {
      [DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF]: {
        generateChanges: ({ newValue }) => {
          return {
            resultChanges: {
              [DEFAULT_KEY_NAME_TRANSACTION_NO_CONTAINER]: {
                value: newValue,
              },
              [DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF]: newValue,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_PRICE_API_REF]: {
        generateChanges: ({
          newValue,
          transactionData = [],
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const amountTransactionData = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const { quantity, amount: amountPast } = selectedChildrenTransactionData;

          const amountChildrenTransactionWithoutPast = amountTransactionData - amountPast;
          const currentAmountSelectedChildrenTransaction = Number(quantity) * Number(newValue);

          const amountChildrenTransaction =
            amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction;

          const { discount_nominal, discount_percentage } = formulaCountDiscountCost({
            allAmountTransaction: amountChildrenTransaction,
            transactionData,
          });

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: currentAmountSelectedChildrenTransaction,
            },
            transactionDataChanges: {
              amount: Number(newAmountTransaction),
              amount_const: Number(amountChildrenTransaction),
              discount_nominal: Number(discount_nominal) || '',
              discount_percentage: Number(discount_percentage) || '',
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_QUANTITY_API_REF]: {
        generateChanges: ({
          newValue,
          transactionData = [],
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const amountTransactionData = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const {
            price,
            amount: amountPast,
            quantity_comparison,
          } = selectedChildrenTransactionData;

          const amountChildrenTransactionWithoutPast = amountTransactionData - amountPast;
          const currentAmountSelectedChildrenTransaction = Number(price) * Number(newValue);

          const amountChildrenTransaction =
            amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction;

          const { discount_nominal, discount_percentage } = formulaCountDiscountCost({
            allAmountTransaction: amountChildrenTransaction,
            transactionData,
          });

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: currentAmountSelectedChildrenTransaction,
              quantity_origin: Number(quantity_comparison) * Number(newValue),
            },
            transactionDataChanges: {
              amount: Number(newAmountTransaction),
              amount_const: Number(amountChildrenTransaction),
              discount_nominal: Number(discount_nominal) || '',
              discount_percentage: Number(discount_percentage) || '',
            },
          };
        },
      },
      // [DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF]: {
      //     generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [], }) => {
      //         const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(childrenTransactionData, 'amount')
      //         const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction)

      //         let newDiscountNominal = Number(amountChildrenTransaction) * (Number(newValue) / 100)

      //         newDiscountNominal = Number(newDiscountNominal).toFixed(2)

      //         const {
      //             expense_value,
      //             down_payment_value,
      //         } = transactionData[0]

      //         const newAmountTransaction = formulaCountAdditionalCost({
      //             allAmountTransaction: amountChildrenTransaction,
      //             discountNominal: newDiscountNominal,
      //             expenseValue: expense_value,
      //             downPaymentValue: down_payment_value,
      //         })

      //         return {
      //             resultChanges: {
      //                 amount: Number(newAmountTransaction),
      //                 [DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF]: Number(newDiscountNominal),
      //             },
      //         }
      //     }
      // },
      // [DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF]: {
      //     generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [], }) => {
      //         const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(childrenTransactionData, 'amount')
      //         const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction)

      //         const {
      //             expense_value,
      //             down_payment_value,
      //         } = transactionData[0]

      //         const newAmountTransaction = formulaCountAdditionalCost({
      //             allAmountTransaction: amountChildrenTransaction,
      //             discountNominal: newValue,
      //             expenseValue: expense_value,
      //             downPaymentValue: down_payment_value,
      //         })

      //         const newDiscountPercentage = amountChildrenTransaction ?
      //             Number((Number(newValue) / Number(amountChildrenTransaction)) * 100).toFixed(2) : 0

      //         return {
      //             resultChanges: {
      //                 amount: Number(newAmountTransaction),
      //                 [DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF]: Number(newDiscountPercentage),
      //             },
      //         }
      //     }
      // },
      [DEFAULT_KEY_NAME_EXPENSE_VALUE]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { discount_nominal, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: newValue,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { discount_nominal, expense_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: newValue,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
            },
          };
        },
      },
    },
    autocompleteValueChanges: {
      [DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF]: {
        isHitEndpoint: true,
        changesTransactionData: true,
        action: getDetailPurchaseInvoice,
        actionRemove: () =>
          clearDataPurchaseSlice(DEFAULT_PAYLOAD_STORAGE_PURCHASE_INVOICE_DETAILS),
        storeName: ROOT_STORE_NAME_PURCHASE,
        childrenSliceName: SLICE_NAME_DETAIL_PURCHASE_INVOICE,
        generateParamsAction: ({ newAutocompleteChanges = {} }) => {
          return {
            [DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF]:
              newAutocompleteChanges[DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF],
          };
        },
        generateChanges: ({ resultGetAction, companyUserData, currentTransactionData }) => {
          const { transactionData, transactionValidation } =
            generateDataNvalidationPurchaseReturnFromPurchaseInvoiceDetails({
              purchaseInvoiceDetails: resultGetAction,
              companyUserData,
              currentPurchaseReturnData: currentTransactionData,
              dataKeyname: DEFAULT_KEY_NAME_TRANSACTION_DATA,
              validationKeyname: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
            });

          const { childrenTransactionData, childrenTransactionValidation } =
            generateDataNvalidationProductPurchaseInvoiceFromPurchaseInvoiceDetails({
              purchaseInvoiceDetails: resultGetAction,
              dataKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
              validationKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
            });

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
      [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF]: {
        isHitEndpoint: false,
        isCanChangeAllState: false,
        generateChanges: ({ newValue }) => {
          const supplierAddress = newValue
            ? newValue[DEFAULT_KEY_NAME_SHIPPING_ADDRESS_API_REF] || ''
            : '';

          return {
            resultChanges: {
              supplier_address: supplierAddress,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF]: {
        ...getChangesAutocompleteForUnitProductTransactionPropertiesPrimary({
          transactionType: DEFAULT_TRANSACTION_PURCHASE_TYPE,
          subTransactionType: DEFAULT_TRANSACTION_PURCHASE_PURCHASE_RETURN_TYPE,
        }),
      },
    },
    menuActionAddProps: {},
    gridHeaderChildrenTransaction: gridHeaderProductPurchaseReturn,
    gridCollapseChildrenTransaction: gridCollapseProductPurchaseReturn,
    dependingKeyNameTransactionValidation: ['transaction_date'], // validation form on transaction
    anotherKeyNameTransactionDataDepends: [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF], // validation form on data transaction
    keyNameBypassChildrenTransactionValidation: ['quantity', 'price'],
    checkingIsEmptyChildrenTransactionKeyNameBypass: [
      DEFAULT_EMPTY_CALCULATIONS_NUMBER,
      DEFAULT_EMPTY_CALCULATIONS_NUMBER,
    ],
    keyNameCheckingValidationInputBypass: ['quantity'],
    keyNameCheckingStatusChildrenTransaction: ['product_id'],
    menuActionUpdateProps: {
      [DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_RETURN_ID]: {
        getAction: (paramsRouter) =>
          getPurchaseReturnDetails({
            [DEFAULT_KEY_NAME_PURCHASE_RETURN_ID_API_REF]:
              paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_RETURN_ID],
            [DEFAULT_KEY_NAME_SHOW_ALL_PRODUCT_API_REF]: 1,
          }),
        // dependencies action when after getting primary action (above action)
        onDependenciesActions: [defaultOnDependenciesListDetailsAttachmentAction],
        parentStateName: ROOT_STORE_NAME_PURCHASE,
        childrenStateName: SLICE_NAME_PURCHASE_RETURN_DETAILS,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNvalidationUpdatePurchaseReturnFromPurchaseReturnDetails({
            purchaseReturnDetails: resultGetAction,
            ...params,
            dataKeyname: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            validationKeyname: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: (resultGetAction) =>
          generateDataNvalidationUpdateProductPurchaseReturnFromPurchaseReturnDetails({
            purchaseReturnDetails: resultGetAction,
            dataKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
            validationKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
          }),
      },
    },
    additionalSettingDefaultInputActionMenuAdd: [
      // defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_SALES_TYPE][0],
    ],
    additionalSettingDefaultInputActionMenuUpdate: [
      // defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_SALES_TYPE][0],
    ],
    generateDataForSettingDefaultInputActionMenuAdd: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    generateDataForSettingDefaultInputActionMenuUpdate: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    additionalPropsSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        generateDataNValidationProfile: generateDataNValidationAddProfile,
        isDisabledContactType: [DEFAULT_CONTACT_IS_SUPPLIER_TYPE],
      },
    },
    additionalActionSwitchDialog: {
      [DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA]: {
        parentStateName: NAME_STORE.CONTACT,
        childrenStateName: SLICE_NAME_TEMP_ADD_CONTACT_DATA,
        generalizeForState: ({
          resultSwitchDialog = {},
          keyStateContainer,
          keyValidationContainer,
          selectedIndex = 0,
          ...stateDataNvalidation
        }) => {
          let {
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          } = stateDataNvalidation;

          if (!isEmpty(resultSwitchDialog)) {
            const { id } = selectedStateContainer[selectedIndex];

            selectedStateContainer = arrHelp.combineDataObjInArray(
              selectedStateContainer,
              'id',
              id,
              {
                [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF]:
                  resultSwitchDialog[DEFAULT_KEY_NAME_CONTACT_ID_API_REF],
                [DEFAULT_KEY_NAME_SUPPLIER_CONTAINER_ID_API_REF]: resultSwitchDialog,
              },
            );

            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              selectedValidationContainer[0],
              [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF],
              true,
              false,
            );

            selectedValidationContainer = arrHelp.combineDataObjInArray(
              selectedValidationContainer,
              'id',
              id,
              newValidation,
            );
          }

          return {
            ...stateDataNvalidation,
            [keyStateContainer]: selectedStateContainer,
            [keyValidationContainer]: selectedValidationContainer,
          };
        },
      },
    },
    parentMenuURL: REACT_APP_PURCHASE_RETURN_URL,
    handleDeleteRow: {
      childrenTransactionData: ({
        transactionData,
        childrenTransactionData,
        childrenTransactionValidation,
        childrenTransactionID = '',
      }) => {
        const {
          childrenTransactionData: currChildrenTransactionData,
          childrenTransactionValidation: currChildrenTransactionValidation,
        } = filteringChildrenTransactionData({
          childrenTransactionData,
          childrenTransactionValidation,
          childrenTransactionDataID: childrenTransactionID,
        });

        return {
          childrenTransactionData: currChildrenTransactionData,
          childrenTransactionValidation: currChildrenTransactionValidation,
          transactionData: [
            {
              ...transactionData[0],
              ...formulaHelpers.calculateDiscountAndTotalOfChildrenData({
                parentData: transactionData[0],
                childrenData: currChildrenTransactionData,
              }),
            },
          ],
        };
      },
    },
  },
  [DEFAULT_TRANSACTION_PURCHASE_RECEIVABLE_PAYMENT_TYPE]: {
    defaultTransctionNumberCode: DEFAULT_TRANSACTION_NUMBER_CODE_RECEIVABLE_PAYMENT,
    allowKeyInputTransactionData: allowKeysDataPurchaseReceivablePayment,
    allowKeyInputProductTransctionData: allowKeysDataDebitNotePurchaseReceivablePayment,
    allowKeyInputChildrenTransactionData: allowKeysDataDebitNotePurchaseReceivablePayment,
    keyNameReferenceNumber: DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF,
    keyNameReferenceNumberContainer: DEFAULT_KEY_NAME_TRANSACTION_NO_CONTAINER,
    uniqueKeyNameChildrenTransaction: DEFAULT_KEY_NAME_DEBIT_NOTE_ID_API_REF,
    // message not handled yet
    errorMessagePrimaryMinimumLengthChildrenTransaction:
      'base.message.error.invoice-cannot-less-one.primary',
    errorMessageSecondaryMinimumLengthChildrenTransaction:
      'base.message.error.invoice-cannot-less-one.secondary',
    errorMessagePrimaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.primary',
    errorMessageSecondaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.secondary',
    errorMessagePrimaryInvalidForm: 'base.message.error.invalid-form.primary',
    errorMessageSecondaryInvalidForm: 'base.message.error.invalid-form.secondary',
    generateCreateDataNvalidationTransaction: ({ amount, companyUserData }) =>
      generateDataNvalidationAddPurchaseReceivablePayment({
        amount,
        companyUserData,
        dataKeyname: DEFAULT_KEY_NAME_TRANSACTION_DATA,
        validationKeyname: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
      }),
    generateCreateDataNvalidationChildrenTransaction: () =>
      generateDataNvalidationAddDebitNotePurchaseReceivablePayment({
        amount: 0,
        dataKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
        validationKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
      }),
    generalizeDataAddTransaction: generalizeDataAddSalesPayment,
    primaryAddUpdateDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          placeholder: 'placeholder.input-auto',
          inputName: 'transaction_number',
          storeName: ROOT_STORE_NAME_REFERENCE_NUMBER,
          childrenStoreName: SLICE_NAME_REFERENCE_NUMBER_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_TRANSACTION_NO_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultSecondPointQuarterGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: referenceNumberList,
            additionalParamsForAction: {},
            removeDataAction: clearReferenceNumber,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['code', 'format'],
                ['value'],
                formatHelp.formatTransactionNumber,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'code',
          },
          inputProps: {
            maxLength: DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE,
          },
          isDisabled: ({ actionMenu, parentStateList = {} }) => {
            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE ||
              parentStateList.statusRequest === STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_PENDING
            );
          },
          isUseTextInput: true,
        },
        {
          label: 'label.transaction-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: 'transaction_date',
          keyInputName: 'transaction_date',
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultSecondPointQuarterGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
          keyNameMaxDate: DEFAULT_KEY_NAME_TODAY,
          isAddOneDayForMinDate: false,
          isRequired: true,
        },
        {
          label: 'label.recipient-account',
          placeholder: 'placeholder.choose-account',
          inputName: 'account_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          storeName: NAME_STORE.ACCOUNT,
          childrenStoreName: SLICE_NAME_ACCOUNT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
          keyInputName: 'account_receivable',
          keyInputContainerName: 'account_receivable_container',
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          statusRequestListPending: STATUS_REQUEST_LIST_ACCOUNT_DATA_PENDING,
          autocompleteProps: {
            ...defaultAccountAutocompleteProps,
            additionalParamsForAction: {
              category_name: ACCOUNT_CATEGORY_NAME_KAS_N_BANK,
            },
            additionalParamsForScrollAction: {
              category_name: ACCOUNT_CATEGORY_NAME_KAS_N_BANK,
              isRewriteAll: false,
            },
          },
          isRequired: true,
        },
      ],
      [
        {
          label: 'label.contact-supplier',
          placeholder: 'placeholder.choose-supplier',
          inputName: 'contact_name',
          storeName: NAME_STORE.CONTACT,
          childrenStoreName: SLICE_NAME_CONTACT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_SUPPLIER_CONTAINER_ID_API_REF,
          statusRequestListPending: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultFourthPointHalfGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: contactList,
            additionalParamsForAction: {
              type: ['is_supplier'],
            },
            removeDataAction: clearContactData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              type: ['is_supplier'],
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['contact_code', 'contact_name'],
                ['contact_name'],
                formatHelp.contactCodeWithName,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'contact_name',
          },
          isUseAdditionalButtonNotFound: false,
          isDisabled: ({ actionMenu }) => {
            return actionMenu === DEFAULT_ACTION_MENU_UPDATE;
          },
          isRequired: true,
        },
        {
          label: 'label.supplier-address',
          placeholder: 'placeholder.supplier-address',
          inputName: 'supplier_address',
          keyInputName: 'supplier_address',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          gridProps: defaultSeventPointHalfGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryAddUpdateDataSchema: [
      [
        {
          label: 'label.total',
          inputName: 'total',
          name: 'amount',
          keyInputName: 'amount',
          isTypography: true,
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
        },
      ],
      [
        // tagAutocompleteInputProps,
        {
          usageInputLabel: false,
          keyInputName: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DROPZONE,
          additionalDropzoneProps: {
            generalizeRequestAction: (attachmentFile, relationValueToOtherAttachment) => {
              return generalizeAttachmentRequest({
                attachmentFile,
                relationValueToOtherAttachment,
                featureDocument: DEFAULT_TRANSACTION_SALES_SALES_PAYMENT_TYPE,
              });
            },
          },
          gridContainerProps: {
            width: 'calc(100% - 2px)',
          },
        },
        {
          label: 'label.memo',
          placeholder: 'placeholder.memo',
          inputName: 'memo',
          keyInputName: 'description',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          additionalOutlinedInput: {
            rows: 3,
            multiline: true,
            className: 'none-padding',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    inputValueChanges: {
      [DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF]: {
        generateChanges: ({ newValue }) => {
          return {
            resultChanges: {
              [DEFAULT_KEY_NAME_TRANSACTION_NO_CONTAINER]: {
                value: newValue,
              },
              [DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF]: newValue,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_AMOUNT_INPUT]: {
        generateChanges: ({
          newValue,
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const amountInputChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            DEFAULT_KEY_NAME_AMOUNT_INPUT,
          );

          const sumAllAmountsInputChildrenTransaction = arrHelp.sumValueArrV2(
            amountInputChildrenTransaction,
          );

          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const { amount_input: currentSelectedAmountInput, amount_available_const } =
            selectedChildrenTransactionData;

          const amountTransactionData =
            Number(sumAllAmountsInputChildrenTransaction) -
            Number(currentSelectedAmountInput) +
            Number(newValue);

          return {
            transactionDataChanges: {
              amount: amountTransactionData,
            },
            resultChanges: {
              amount_available: Number(amount_available_const) - Number(newValue),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_QUANTITY_API_REF]: {
        generateChanges: ({
          newValue,
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const { quantity_available_const } = selectedChildrenTransactionData;
          const quantity_available = Number(quantity_available_const) - Number(newValue);

          return {
            resultChanges: {
              quantity_available,
            },
          };
        },
      },
    },
    autocompleteValueChanges: {
      [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF]: {
        isHitEndpoint: true,
        action: getPurchaseDebitNoteList,
        actionRemove: () =>
          clearDataPurchaseSlice(DEFAULT_PAYLOAD_STORAGE_PURCHASE_DEBIT_NOTE_LIST),
        storeName: ROOT_STORE_NAME_PURCHASE,
        childrenSliceName: SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST,
        generateParamsAction: ({ newAutocompleteChanges = {} }) => {
          return {
            ...DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION_SECONDARY,
            [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF]:
              newAutocompleteChanges[DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF] || '',
            status: [
              DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_OPEN,
              DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_PARTIALLY_PAID,
            ],
          };
        },
        generateChanges: ({
          newValue,
          resultGetAction,
          currentTransactionData,
          transactionValidation = [],
          companyUserData,
        }) => {
          const { id: idTransactionData } = currentTransactionData[0];

          const debitNoteList = isEmpty(resultGetAction)
            ? []
            : resultGetAction[DEFAULT_KEY_NAME_RESPONSE_DATA_API_REF];

          let newTransactionData = cloneDeep(currentTransactionData),
            newTransactionValidation = cloneDeep(transactionValidation);

          if (!isEmpty(transactionValidation)) {
            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              newTransactionValidation[0],
              [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF],
              true,
              isEmpty(newValue),
            );

            newTransactionValidation = [newValidation];
          }

          const { childrenTransactionData, childrenTransactionValidation } =
            generateDataNvalidationAddDebitNotePurchaseReceivablePaymentFromDebitNoteList({
              debitNoteList,
              dataKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
              validationKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
            });

          let sumAmountInputeDebitNotePurchaseReceivablePayment =
            arrHelp.mappingChildrenKeyOnObject(
              childrenTransactionData,
              DEFAULT_KEY_NAME_AMOUNT_INPUT,
            );
          if (Array.isArray(sumAmountInputeDebitNotePurchaseReceivablePayment)) {
            sumAmountInputeDebitNotePurchaseReceivablePayment = arrHelp.sumValueArrV2(
              sumAmountInputeDebitNotePurchaseReceivablePayment,
            );

            newTransactionData = arrHelp.combineDataObjInArray(
              newTransactionData,
              'id',
              idTransactionData,
              {
                amount: sumAmountInputeDebitNotePurchaseReceivablePayment,
              },
            );
          }

          if (!isEmpty(currentTransactionData)) {
            const supplierAddress = newValue
              ? newValue[DEFAULT_KEY_NAME_SHIPPING_ADDRESS_API_REF] || ''
              : '';

            const isEmptyDebitNoteList = isEmpty(debitNoteList);

            const latestDebitNoteTransaction = !isEmptyDebitNoteList
              ? cloneDeep(debitNoteList).sort(
                  (debitNote, nextDebitNote) =>
                    new Date(nextDebitNote[DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF]) -
                    new Date(debitNote[DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF]),
                )[0]
              : [];

            const minDateInput = getAccountingPeriodCompareWithDocumentTransactionDate({
              companyUserData,
              documentData: latestDebitNoteTransaction,
            });

            newTransactionData = arrHelp.combineDataObjInArray(
              newTransactionData,
              'id',
              idTransactionData,
              {
                supplier_address: supplierAddress,
                [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]: isEmptyDebitNoteList
                  ? new Date()
                  : new Date(minDateInput),
              },
            );
          }

          if (isEmpty(newValue)) {
            newTransactionData = arrHelp.combineDataObjInArray(
              newTransactionData,
              'id',
              idTransactionData,
              {
                [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]: new Date(),
              },
            );
          }

          return {
            transactionData: newTransactionData,
            transactionValidation: newTransactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
    },
    gridHeaderChildrenTransaction: gridHeaderDebitNotePurchaseReceivablePayment,
    gridCollapseChildrenTransaction: gridCollapseDebitNotePurchaseReceivablePayment,
    dependingKeyNameTransactionValidation: ['transaction_date'], // validation form on transaction
    anotherKeyNameTransactionDataDepends: [
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF, DEFAULT_KEY_NAME_TODAY],
    ], // validation form on data transaction
    keyNameBypassChildrenTransactionValidation: ['debit_note_id', 'amount_input'],
    checkingIsEmptyChildrenTransactionKeyNameBypass: [
      [null, ''],
      DEFAULT_EMPTY_CALCULATIONS_NUMBER,
    ],
    keyNameCheckingValidationInputBypass: ['amount_input'],
    keyNameCheckingStatusChildrenTransaction: ['debit_note_id'],
    menuActionUpdateProps: {
      [DEFAULT_KEY_NAME_PARAMS_URL_RECEIVABLE_PAYMENT_ID]: {
        getAction: (paramsRouter) => {
          return getPurchaseReceivablePaymentDetails({
            [DEFAULT_KEY_NAME_RECEIVABLE_PAYMENT_ID_API_REF]:
              paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_RECEIVABLE_PAYMENT_ID],
          });
        },
        // dependencies action when after getting primary action (above action)
        onDependenciesActions: [defaultOnDependenciesListDetailsAttachmentAction],
        parentStateName: ROOT_STORE_NAME_PURCHASE,
        childrenStateName: SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_DETAILS,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNvalidationUpdatePurchaseReceivablePayment({
            purchaseReceivablePaymentDetails: resultGetAction,
            ...params,
            dataKeyname: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            validationKeyname: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: (resultGetAction) =>
          generateDataNvalidationUpdateDebitNotePurchaseReceivablePayment({
            purchaseReceivablePaymentDetails: resultGetAction,
            dataKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
            validationKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
          }),
        // usage for getting actions when update action, must array
        additionalActionsMenuUpdate: [
          {
            getAction: (paramsGetAction) =>
              getPurchaseDebitNoteList({
                ...paramsGetAction,
                ...DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION_SECONDARY,
                status: [
                  DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_OPEN,
                  DEFAULT_STATUS_PURCHASE_DEBIT_NOTE_PARTIALLY_PAID,
                ],
              }),
            generateParamsAction: ({ transactionData }) => {
              if (
                isEmpty(transactionData) ||
                !Array.isArray(transactionData) ||
                !transactionData.length
              )
                return {};

              return {
                [DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF]:
                  transactionData[0][DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF],
              };
            },
            parentStateName: ROOT_STORE_NAME_PURCHASE,
            childrenStateName: SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST,
            keyNameResultActionStore: SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST,
          },
        ],
        // you must return transactionData, transactionValidation, childrenTransactionData, and childrenTransactionValidation
        // usage for adding state after additional actions for update
        generateStateDataNvalidationAssetsFromAdditionalActions: ({
          transactionData,
          transactionValidation,
          childrenTransactionData,
          childrenTransactionValidation,
          resultAdditionalActions,
        }) => {
          let newChildrenTransactionData = childrenTransactionData,
            newChildrenTransactionValidation = childrenTransactionValidation;

          if (
            !isEmpty(resultAdditionalActions[SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST]) &&
            Array.isArray(resultAdditionalActions[SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST].data) &&
            resultAdditionalActions[SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST].data.length
          ) {
            const {
              childrenTransactionData: childrenTransactionDataGen,
              childrenTransactionValidation: childrenTransactionValidationGen,
            } = generateDataNvalidationUpdateDebitNotePurchaseReceivablePaymentFromDebitNoteList({
              debitNoteList: resultAdditionalActions[SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST].data,
              currentDebitNotePurchasePaymentData: childrenTransactionData,
              currentDebitNotePurchasePaymentValidation: childrenTransactionValidation,
              dataKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
              validationKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
            });

            newChildrenTransactionData = childrenTransactionDataGen;
            newChildrenTransactionValidation = childrenTransactionValidationGen;
          }

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData: newChildrenTransactionData,
            childrenTransactionValidation: newChildrenTransactionValidation,
          };
        },
      },
    },
    additionalSettingDefaultInputActionMenuAdd: [
      {
        // account receivable
        parentStateName: ROOT_STORE_NAME_ACCOUNT,
        childrenListStateName: SLICE_NAME_ACCOUNT_DATA,
        keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
        keyNameSubResponseData: DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF,
        defaultKeyInputName: DEFAULT_KEY_NAME_ACCOUNT_RECEIVABLE_API_REF,
        defaultKeyInputContainerName: DEFAULT_KEY_NAME_ACCOUNT_RECEIVABLE_CONTAINER,
        indexSelected: 0,
        action: getAccountList,
        additionalParams: {
          [DEFAULT_KEY_NAME_CATEGORY_NAME_API_REF]: ACCOUNT_CATEGORY_NAME_CASH_N_BANK,
        },
        isAddDefaultInput: ({ transactionData = [] }) => {
          return (
            isEmpty(transactionData) ||
            !transactionData[0][DEFAULT_KEY_NAME_ACCOUNT_RECEIVABLE_CONTAINER]
          );
        },
        isListData: true,
      },
    ],
    additionalSettingDefaultInputActionMenuUpdate: [
      {
        // account receivable
        parentStateName: ROOT_STORE_NAME_ACCOUNT,
        childrenListStateName: SLICE_NAME_ACCOUNT_DATA,
        keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
        keyNameSubResponseData: DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF,
        defaultKeyInputName: DEFAULT_KEY_NAME_ACCOUNT_RECEIVABLE_API_REF,
        defaultKeyInputContainerName: DEFAULT_KEY_NAME_ACCOUNT_RECEIVABLE_CONTAINER,
        indexSelected: 0,
        action: getAccountList,
        additionalParams: {
          [DEFAULT_KEY_NAME_CATEGORY_NAME_API_REF]: ACCOUNT_CATEGORY_NAME_CASH_N_BANK,
        },
        isAddDefaultInput: ({ transactionData = [] }) => {
          return (
            isEmpty(transactionData) ||
            !transactionData[0][DEFAULT_KEY_NAME_ACCOUNT_RECEIVABLE_CONTAINER]
          );
        },
        isListData: true,
      },
    ],
    generateDataForSettingDefaultInputActionMenuAdd: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    generateDataForSettingDefaultInputActionMenuUpdate: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    parentMenuURL: REACT_APP_PURCHASE_RECEIVABLE_PAYMENT_URL,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_RETURN_TYPE]: {
    defaultTransctionNumberCode: DEFAULT_TRANSACTION_NUMBER_CODE_SALES_RETURN,
    allowKeyInputTransactionData: allowKeysDataSalesReturn,
    allowKeyInputChildrenTransactionData: allowKeysDataProductSalesReturn,
    keyNameReferenceNumber: DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF,
    keyNameReferenceNumberContainer: DEFAULT_KEY_NAME_TRANSACTION_NO_CONTAINER,
    keyNameTransactionDate: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
    keyNameAccountingPeriod: DEFAULT_KEY_NAME_ACCOUNTING_PERIOD,
    keyNameDiscountType: DEFAULT_KEY_NAME_DISCOUNT_TYPE_API_REF,
    keyNameDiscountPercentage: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
    keyNameDiscountNominal: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
    keyNameDiscountAccountID: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_API_REF,
    keyNameDiscountAccountIDcontainer: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_CONTAINER,
    keyNameDownPaymentAccountID: DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_API_REF,
    keyNameDownPaymentAccountIDcontainer: DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_CONTAINER,
    keyNameDownPaymentValue: DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE,
    keyNameExpenseAccountID: DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_API_REF,
    keyNameExpenseAccountIDcontainer: DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_CONTAINER,
    keyNameExpenseValue: DEFAULT_KEY_NAME_EXPENSE_VALUE,
    keyNameMaximumDownPaymentValue: defaultKeyNameMaximumDownPaymentValue,
    minimumLengthChildrenTransaction: 1,
    uniqueKeyNameChildrenTransaction: DEFAULT_KEY_NAME_PRODUCT_ID_API_REF,
    statusFreeUpdateInputTransaction: [DEFAULT_STATUS_SALES_RETURN_OPEN],
    errorMessagePrimaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.primary',
    errorMessageSecondaryMinimumLengthChildrenTransaction:
      'base.message.error.product-cannot-less-one.secondary',
    errorMessagePrimaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.primary',
    errorMessageSecondaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-product-data.secondary',
    errorMessagePrimaryInvalidForm: 'base.message.error.invalid-form.primary',
    errorMessageSecondaryInvalidForm: 'base.message.error.invalid-form.secondary',
    relationalKeyDiscountTypeNvalue: {
      [DEFAULT_DISCOUNT_PERCENTAGE_TYPE]: {
        keyInputName: DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF,
        maximumValue: 100,
        representationDataType: DEFAULT_REPRESENTATION_DATA_NUMBER_TYPE,
      },
      [DEFAULT_DISCOUNT_NOMINAL_TYPE]: {
        keyInputName: DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF,
        keyNameMaximumValue: DEFAULT_KEY_NAME_AMOUNT_CONST,
        representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
      },
    },
    splicingAdditionalCostInput: [0, 1],
    isAdditionalCostShow: true,
    // indicate for additional cost is combined
    additionalCostCombinedOnFormSchema: true,
    isFormStatusShow: true,
    statusInputFormSchema: [
      {
        label: 'label.status',
        inputType: DEFAULT_INPUT_TYPE_CHECKBOX_WITH_LABEL,
        keyInputName: DEFAULT_KEY_NAME_IS_PAID_OFF_API_REF,
        labelChecked: 'typography.paid-off-settled',
        labelUnChecked: 'typography.paid-off-settled',
        isDisabled: ({ transactionData }) => {
          if (isEmpty(transactionData)) return true;

          const { sales_invoice_id, amount, invoice_total } = transactionData[0];

          return !sales_invoice_id || Number(amount) <= Number(invoice_total);
        },
      },
    ],
    // showing floating from status
    showFormStatusFloating: true,
    // isAdditionalCostShow: true,
    // additionalCostFormSchema: additionalCostPurchaseForm,
    isMinutesHandoverShow: false,
    // isFormStatusShow: true,
    generateCreateDataNvalidationTransaction: ({ amount }) =>
      generateDataNvalidationAddSalesReturn({
        amount,
        dataKeyname: DEFAULT_KEY_NAME_TRANSACTION_DATA,
        validationKeyname: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
      }),
    generateCreateDataNvalidationChildrenTransaction: ({ amount }) =>
      generateDataNvalidationAddProductSalesReturn({
        amount,
        dataKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
        validationKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
      }),
    generalizeDataAddTransaction: () => null,
    primaryAddUpdateDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          placeholder: 'placeholder.input-auto',
          inputName: 'transaction_no',
          storeName: ROOT_STORE_NAME_REFERENCE_NUMBER,
          childrenStoreName: SLICE_NAME_REFERENCE_NUMBER_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_TRANSACTION_NO_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultSecondPointQuarterGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: referenceNumberList,
            additionalParamsForAction: {},
            removeDataAction: clearReferenceNumber,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['code', 'format'],
                ['value'],
                formatHelp.formatTransactionNumber,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'code',
          },
          inputProps: {
            maxLength: DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE,
          },
          isDisabled: ({ actionMenu, parentStateList = {} }) => {
            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE ||
              parentStateList.statusRequest === STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_PENDING
            );
          },
          isUseTextInput: true,
          isRequired: true,
        },
        {
          label: 'label.invoice-number',
          placeholder: 'placeholder.choose-invoice-no',
          inputName: DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF,
          storeName: ROOT_STORE_NAME_SALES,
          childrenStoreName: SLICE_NAME_SALES_INVOICE_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF,
          statusRequestListPending: STATUS_REQUEST_LIST_SALES_INVOICE_PENDING,
          keyInputName: DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF,
          gridProps: defaultSecondPointQuarterGridProps,
          keyInputContainerName: DEFAULT_KEY_NAME_SALES_INVOICE_ID_CONTAINER,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          getAdditionalParamsForAction: ({ transactionData }) => {
            const { customer_id_container } = transactionData[0];

            if (isEmpty(customer_id_container)) return {};

            return {
              customer_name: customer_id_container.contact_name,
            };
          },
          autocompleteProps: {
            fetchAction: getListSalesInvoice,
            additionalParamsForAction: {
              is_return_allowed: 1,
            },
            removeDataAction: clearSalesInvoiceData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              is_return_allowed: 1,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getOptionLabel(options, 'sales_invoice_no'),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'sales_invoice_no',
          },
          isDisabled: ({ actionMenu }) => {
            return actionMenu === DEFAULT_ACTION_MENU_UPDATE;
          },
          isRequired: true,
        },
        {
          label: 'label.transaction-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
          keyInputName: DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultSecondPointQuarterGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
          keyNameMaxDate: DEFAULT_KEY_NAME_TODAY,
          isAddOneDayForMinDate: false,
          isRequired: true,
          isDisabled: () => {
            return false;
          },
        },
      ],
      [
        {
          label: 'label.customer',
          placeholder: 'placeholder.choose-customer',
          inputName: DEFAULT_KEY_NAME_CONTACT_NAME_API_REF,
          storeName: NAME_STORE.CONTACT,
          childrenStoreName: SLICE_NAME_CONTACT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_CUSTOMER_CONTAINER_ID_API_REF,
          statusRequestListPending: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultFourthPointHalfGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: contactList,
            additionalParamsForAction: {
              ...additionalParamsGetContactCustomer,
            },
            removeDataAction: clearContactData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              ...additionalParamsGetContactCustomer,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                [DEFAULT_KEY_NAME_CONTACT_CODE_API_REF, DEFAULT_KEY_NAME_CONTACT_NAME_API_REF],
                [DEFAULT_KEY_NAME_CONTACT_NAME_API_REF],
                formatHelp.contactCodeWithName,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: DEFAULT_KEY_NAME_CONTACT_NAME_API_REF,
          },
          isUseAdditionalButtonNotFound: false,
          // additionalButtonNotFoundProps: {
          //     textNotFound: 'button.plus-add-new-contact',
          //     keyNameIsNew: DEFAULT_KEY_NAME_IS_NEW_CONTACT_DATA,
          //     keynameSwitchDialog: 'contact-data',
          //     newPageStatus: DEFAULT_STATUS_PAGE_ADD_CONTACT_DATA,
          // },
          isDisabled: ({ actionMenu, transactionData }) => {
            const { sales_invoice_id } = transactionData[0];

            return actionMenu === DEFAULT_ACTION_MENU_UPDATE || !!sales_invoice_id;
          },
        },
        {
          label: 'label.customer-address',
          placeholder: 'placeholder.customer-address',
          inputName: DEFAULT_KEY_NAME_CUSTOMER_ADDRESS_API_REF,
          keyInputName: DEFAULT_KEY_NAME_CUSTOMER_ADDRESS_API_REF,
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          gridProps: defaultSeventPointHalfGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          isDisabled: ({ actionMenu }) => {
            return actionMenu === DEFAULT_ACTION_MENU_UPDATE;
          },
        },
      ],
    ],
    secondaryAddUpdateDataSchema: [
      [
        {
          label: 'label.current-invoice-amount',
          useTemplateLabel: false,
          labelTranslationKeynameProps: ['invoiceNumber'],
          templateLabel: '{label} {sales_invoice_no}',
          templateLabelKeyname: 'sales_invoice_no',
          name: 'invoice_total',
          typographyLabelProps: {
            variant: 'boldIndicator2',
          },
          typographyValueProps: {
            variant: 'body8',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
          isTypography: true,
          emptyValueSubstitute: 0,
        },
        {
          representationDataType: DEFAULT_REPRESENTATION_DATA_HORIZONTAL_LINE_TYPE,
        },
        {
          label: 'label.subtotal',
          name: 'amount_const',
          typographyLabelProps: {
            variant: 'boldIndicator2',
          },
          typographyValueProps: {
            variant: 'body8',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
          isTypography: true,
          emptyValueSubstitute: 0,
        },
        // {
        //     label: 'label.discount-per-item',
        //     name: 'discountPerItem',
        //     typographyValueProps: {
        //         variant: 'body6',
        //     },
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
        //     isTypography: true,
        //     emptyValueSubstitute: 0,
        // },
        {
          label: 'label.additional-discount',
          name: 'discount_nominal',
          typographyValueProps: {
            variant: 'body6',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
          isTypography: true,
          emptyValueSubstitute: 0,
        },
        // {
        //     isTypography: true,
        //     label: 'label.total-after-discount',
        //     keyNameValue: 'totalAfterDiscount',
        //     typographyValueProps: {
        //         variant: 'body6',
        //     },
        //     isCalculateBasedKeyname: true,
        //     calculateKeynames: defaultKeynamesCalculationTotalAfterDiscountReturnVersion,
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
        //     emptyValueSubstitute: 0,
        // },
        {
          representationDataType: DEFAULT_REPRESENTATION_DATA_HORIZONTAL_LINE_TYPE,
        },
        {
          label: 'label.total',
          name: 'amount',
          inputName: 'amount',
          keyInputName: 'amount',
          typographyLabelProps: {
            variant: 'boldIndicator2',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
          isTypography: true,
        },
        // {
        //     label: 'label.on-behalf',
        //     placeholder: 'placeholder.on-behalf',
        //     inputName: DEFAULT_KEY_NAME_ON_BEHALF_API_REF,
        //     keyInputName: DEFAULT_KEY_NAME_ON_BEHALF_API_REF,
        //     inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        //     isDisabled: ({ actionMenu }) => {
        //         return actionMenu === DEFAULT_ACTION_MENU_UPDATE
        //     },
        // },
        // {
        //     label: 'label.total',
        //     name: 'amount',
        //     inputName: 'amount',
        //     keyInputName: 'amount',
        //     representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
        //     isTypography: true,
        // },
      ],
      [
        // tagAutocompleteInputProps,
        {
          usageInputLabel: false,
          keyInputName: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DROPZONE,
          additionalDropzoneProps: {
            generalizeRequestAction: (attachmentFile, relationValueToOtherAttachment) => {
              return generalizeAttachmentRequest({
                attachmentFile,
                relationValueToOtherAttachment,
                featureDocument: DEFAULT_TRANSACTION_SALES_SALES_RETURN_TYPE,
              });
            },
          },
          gridContainerProps: {
            width: 'calc(100% - 2px)',
          },
        },
        {
          label: 'label.memo',
          placeholder: 'label.memo',
          inputName: 'memo',
          keyInputName: 'description',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          additionalOutlinedInput: {
            rows: 3,
            multiline: true,
            className: 'none-padding',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    inputValueChanges: {
      [DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF]: {
        generateChanges: ({ newValue }) => {
          return {
            resultChanges: {
              [DEFAULT_KEY_NAME_TRANSACTION_NO_CONTAINER]: {
                value: newValue,
              },
              [DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF]: newValue,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_PRICE_API_REF]: {
        generateChanges: ({
          newValue,
          transactionData = [],
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const amountTransactionData = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const { quantity, amount: amountPast } = selectedChildrenTransactionData;

          const amountChildrenTransactionWithoutPast = amountTransactionData - amountPast;
          const currentAmountSelectedChildrenTransaction = Number(quantity) * Number(newValue);

          const amountChildrenTransaction =
            amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction;

          const { discount_nominal, discount_percentage } = formulaCountDiscountCost({
            allAmountTransaction: amountChildrenTransaction,
            transactionData,
          });

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: currentAmountSelectedChildrenTransaction,
            },
            transactionDataChanges: {
              amount: Number(newAmountTransaction),
              amount_const: Number(amountChildrenTransaction),
              discount_nominal: Number(discount_nominal) || '',
              discount_percentage: Number(discount_percentage) || '',
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_QUANTITY_API_REF]: {
        generateChanges: ({
          newValue,
          transactionData = [],
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );

          const amountTransactionData = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { expense_value, down_payment_value } = transactionData[0];

          const {
            price,
            amount: amountPast,
            quantity_comparison,
          } = selectedChildrenTransactionData;

          const amountChildrenTransactionWithoutPast = amountTransactionData - amountPast;
          const currentAmountSelectedChildrenTransaction = Number(price) * Number(newValue);

          const amountChildrenTransaction =
            amountChildrenTransactionWithoutPast + currentAmountSelectedChildrenTransaction;

          const { discount_nominal, discount_percentage } = formulaCountDiscountCost({
            allAmountTransaction: amountChildrenTransaction,
            transactionData,
          });

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: currentAmountSelectedChildrenTransaction,
              quantity_origin: Number(quantity_comparison) * Number(newValue),
            },
            transactionDataChanges: {
              amount: Number(newAmountTransaction),
              amount_const: Number(amountChildrenTransaction),
              discount_nominal: Number(discount_nominal) || '',
              discount_percentage: Number(discount_percentage) || '',
            },
          };
        },
      },
      // [DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF]: {
      //     generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [], }) => {
      //         const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(childrenTransactionData, 'amount')
      //         const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction)

      //         let newDiscountNominal = Number(amountChildrenTransaction) * (Number(newValue) / 100)

      //         newDiscountNominal = Number(newDiscountNominal).toFixed(2)

      //         const {
      //             expense_value,
      //             down_payment_value,
      //         } = transactionData[0]

      //         const newAmountTransaction = formulaCountAdditionalCost({
      //             allAmountTransaction: amountChildrenTransaction,
      //             discountNominal: newDiscountNominal,
      //             expenseValue: expense_value,
      //             downPaymentValue: down_payment_value,
      //         })

      //         return {
      //             resultChanges: {
      //                 amount: Number(newAmountTransaction),
      //                 [DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF]: Number(newDiscountNominal),
      //             },
      //         }
      //     }
      // },
      // [DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF]: {
      //     generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [], }) => {
      //         const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(childrenTransactionData, 'amount')
      //         const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction)

      //         const {
      //             expense_value,
      //             down_payment_value,
      //         } = transactionData[0]

      //         const newAmountTransaction = formulaCountAdditionalCost({
      //             allAmountTransaction: amountChildrenTransaction,
      //             discountNominal: newValue,
      //             expenseValue: expense_value,
      //             downPaymentValue: down_payment_value,
      //         })

      //         const newDiscountPercentage = amountChildrenTransaction ?
      //             Number((Number(newValue) / Number(amountChildrenTransaction)) * 100).toFixed(2) : 0

      //         return {
      //             resultChanges: {
      //                 amount: Number(newAmountTransaction),
      //                 [DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF]: Number(newDiscountPercentage),
      //             },
      //         }
      //     }
      // },
      [DEFAULT_KEY_NAME_EXPENSE_VALUE]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { discount_nominal, down_payment_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: newValue,
            downPaymentValue: down_payment_value,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE]: {
        generateChanges: ({ newValue, transactionData = [], childrenTransactionData = [] }) => {
          const allAmountChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            'amount',
          );
          const amountChildrenTransaction = arrHelp.sumValueArrV2(allAmountChildrenTransaction);

          const { discount_nominal, expense_value } = transactionData[0];

          const newAmountTransaction = formulaCountAdditionalCost({
            allAmountTransaction: amountChildrenTransaction,
            discountNominal: discount_nominal,
            expenseValue: expense_value,
            downPaymentValue: newValue,
          });

          return {
            resultChanges: {
              amount: Number(newAmountTransaction),
            },
          };
        },
      },
    },
    autocompleteValueChanges: {
      [DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF]: {
        isHitEndpoint: true,
        action: getDetailSalesInvoice,
        actionRemove: () => clearDataSalesSlice(DEFAULT_PAYLOAD_STORAGE_SALES_INVOICE_DETAILS),
        storeName: ROOT_STORE_NAME_SALES,
        childrenSliceName: SLICE_NAME_SALES_INVOICE_DETAILS,
        generateParamsAction: ({ newAutocompleteChanges = {} }) => {
          return pick(newAutocompleteChanges, [DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF]);
        },
        generateChanges: ({ resultGetAction, companyUserData, currentTransactionData }) => {
          const { transactionData, transactionValidation } =
            generateDataNvalidationSalesReturnFromSalesInvoiceDetails({
              salesInvoiceDetails: resultGetAction,
              companyUserData,
              currentSalesReturnData: currentTransactionData,
              dataKeyname: DEFAULT_KEY_NAME_TRANSACTION_DATA,
              validationKeyname: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
            });

          const { childrenTransactionData, childrenTransactionValidation } =
            generateDataNvalidationProductSalesReturnFromSalesInvoiceDetails({
              salesInvoiceDetails: resultGetAction,
              dataKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
              validationKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
            });

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
      [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF]: {
        isHitEndpoint: false,
        isCanChangeAllState: false,
        generateChanges: ({ newValue }) => {
          const customerAddress = newValue
            ? newValue[DEFAULT_KEY_NAME_SHIPPING_ADDRESS_API_REF] || ''
            : '';

          return {
            resultChanges: {
              customer_address: customerAddress,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF]: {
        ...getChangesAutocompleteForUnitProductTransactionPropertiesPrimary({
          transactionType: DEFAULT_TRANSACTION_SALES_TYPE,
          subTransactionType: DEFAULT_TRANSACTION_SALES_SALES_RETURN_TYPE,
        }),
      },
    },
    menuActionAddProps: {},
    gridHeaderChildrenTransaction: gridHeaderProductPurchaseReturn,
    gridCollapseChildrenTransaction: gridCollapseProductPurchaseReturn,
    dependingKeyNameTransactionValidation: ['transaction_date'], // validation form on transaction
    anotherKeyNameTransactionDataDepends: [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF], // validation form on data transaction
    keyNameBypassChildrenTransactionValidation: ['quantity', 'price'],
    checkingIsEmptyChildrenTransactionKeyNameBypass: [
      DEFAULT_EMPTY_CALCULATIONS_NUMBER,
      DEFAULT_EMPTY_CALCULATIONS_NUMBER,
    ],
    keyNameCheckingValidationInputBypass: ['quantity'],
    keyNameCheckingStatusChildrenTransaction: ['product_id'],
    menuActionUpdateProps: {
      [DEFAULT_KEY_NAME_PARAMS_URL_SALES_RETURN_ID]: {
        getAction: (paramsRouter) =>
          getSalesReturnDetails({
            [DEFAULT_KEY_NAME_SALES_RETURN_ID_API_REF]:
              paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_SALES_RETURN_ID],
            [DEFAULT_KEY_NAME_SHOW_ALL_PRODUCT_API_REF]: 1,
          }),
        // dependencies action when after getting primary action (above action)
        onDependenciesActions: [defaultOnDependenciesListDetailsAttachmentAction],
        parentStateName: ROOT_STORE_NAME_SALES,
        childrenStateName: SLICE_NAME_SALES_RETURN_DETAILS,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNvalidationUpdateSalesReturnFromSalesReturnDetails({
            salesReturnDetails: resultGetAction,
            ...params,
            dataKeyname: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            validationKeyname: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: (resultGetAction) =>
          generateDataNvalidationUpdateProductSalesReturnFromSalesReturnDetails({
            salesReturnDetails: resultGetAction,
            dataKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
            validationKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
          }),
      },
    },
    additionalSettingDefaultInputActionMenuAdd: [
      // defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_SALES_TYPE][0],
    ],
    additionalSettingDefaultInputActionMenuUpdate: [
      // defaultAccountAdditionalCostConfig[DEFAULT_TRANSACTION_SALES_TYPE][0],
    ],
    generateDataForSettingDefaultInputActionMenuAdd: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    generateDataForSettingDefaultInputActionMenuUpdate: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    additionalPropsSwitchDialog: {},
    additionalActionSwitchDialog: {},
    parentMenuURL: REACT_APP_SALES_RETURN_URL,
    handleDeleteRow: {
      childrenTransactionData: ({
        transactionData,
        childrenTransactionData,
        childrenTransactionValidation,
        childrenTransactionID = '',
      }) => {
        const {
          childrenTransactionData: currChildrenTransactionData,
          childrenTransactionValidation: currChildrenTransactionValidation,
        } = filteringChildrenTransactionData({
          childrenTransactionData,
          childrenTransactionValidation,
          childrenTransactionDataID: childrenTransactionID,
        });

        return {
          childrenTransactionData: currChildrenTransactionData,
          childrenTransactionValidation: currChildrenTransactionValidation,
          transactionData: [
            {
              ...transactionData[0],
              ...formulaHelpers.calculateDiscountAndTotalOfChildrenData({
                parentData: transactionData[0],
                childrenData: currChildrenTransactionData,
              }),
            },
          ],
        };
      },
    },
  },
  [DEFAULT_TRANSACTION_SALES_PAYABLE_PAYMENT_TYPE]: {
    defaultTransctionNumberCode: DEFAULT_TRANSACTION_NUMBER_CODE_PAYABLE_PAYMENT,
    allowKeyInputTransactionData: allowKeysDataSalesPayablePayment,
    allowKeyInputProductTransctionData: allowKeysDataCreditNoteSalesPayablePayment,
    allowKeyInputChildrenTransactionData: allowKeysDataCreditNoteSalesPayablePayment,
    keyNameReferenceNumber: DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF,
    keyNameReferenceNumberContainer: DEFAULT_KEY_NAME_TRANSACTION_NO_CONTAINER,
    uniqueKeyNameChildrenTransaction: DEFAULT_KEY_NAME_CREDIT_NOTE_ID_API_REF,
    // message not handled yet
    errorMessagePrimaryMinimumLengthChildrenTransaction:
      'base.message.error.credit-note-cannot-less-one.primary',
    errorMessageSecondaryMinimumLengthChildrenTransaction:
      'base.message.error.credit-note-cannot-less-one.secondary',
    errorMessagePrimaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-credit-note-data.primary',
    errorMessageSecondaryDuplicatedChildrenTransaction:
      'base.message.error.duplicated-credit-note-data.secondary',
    errorMessagePrimaryInvalidForm: 'base.message.error.invalid-form.primary',
    errorMessageSecondaryInvalidForm: 'base.message.error.invalid-form.secondary',
    generateCreateDataNvalidationTransaction: ({ amount, companyUserData }) =>
      generateDataNvalidationAddSalesPayablePayment({
        amount,
        companyUserData,
        dataKeyname: DEFAULT_KEY_NAME_TRANSACTION_DATA,
        validationKeyname: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
      }),
    generateCreateDataNvalidationChildrenTransaction: () =>
      generateDataNvalidationAddCreditNoteSalesPayablePayment({
        amount: 0,
        dataKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
        validationKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
      }),
    generalizeDataAddTransaction: generalizeDataAddSalesPayablePayment,
    primaryAddUpdateDataGridSchema: [
      [
        {
          label: 'label.transaction-number',
          placeholder: 'placeholder.input-auto',
          inputName: 'transaction_number',
          storeName: ROOT_STORE_NAME_REFERENCE_NUMBER,
          childrenStoreName: SLICE_NAME_REFERENCE_NUMBER_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_REFERENCE_NUMBER_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_TRANSACTION_NO_CONTAINER,
          statusRequestListPending: STATUS_REQUEST_LIST_REFERENCE_NUMBER_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultSecondPointQuarterGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: referenceNumberList,
            additionalParamsForAction: {},
            removeDataAction: clearReferenceNumber,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['code', 'format'],
                ['value'],
                formatHelp.formatTransactionNumber,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'code',
          },
          inputProps: {
            maxLength: DEFAULT_MAX_LENGTH_NUMBER_REFERENCE_WITH_CODE,
          },
          isDisabled: ({ actionMenu, parentStateList = {} }) => {
            return (
              actionMenu === DEFAULT_ACTION_MENU_UPDATE ||
              parentStateList.statusRequest === STATUS_REQUEST_DETAIL_REFERENCE_NUMBER_DATA_PENDING
            );
          },
          isUseTextInput: true,
        },
        {
          label: 'label.transaction-date',
          placeholder: 'placeholder.date-picker-default-uppercase',
          inputName: 'transaction_date',
          keyInputName: 'transaction_date',
          inputType: DEFAULT_INPUT_TYPE_DATE_PICKER,
          gridProps: defaultSecondPointQuarterGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          keyNameMinDate: DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
          keyNameMaxDate: DEFAULT_KEY_NAME_TODAY,
          isAddOneDayForMinDate: false,
          isRequired: true,
        },
        {
          label: 'label.payment-account',
          placeholder: 'placeholder.choose-account',
          inputName: 'account_name',
          gridProps: defaultGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          storeName: NAME_STORE.ACCOUNT,
          childrenStoreName: SLICE_NAME_ACCOUNT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
          keyInputName: 'account_payable',
          keyInputContainerName: 'account_payable_container',
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          statusRequestListPending: STATUS_REQUEST_LIST_ACCOUNT_DATA_PENDING,
          autocompleteProps: {
            ...defaultAccountAutocompleteProps,
            additionalParamsForAction: {
              category_name: ACCOUNT_CATEGORY_NAME_KAS_N_BANK,
            },
            additionalParamsForScrollAction: {
              category_name: ACCOUNT_CATEGORY_NAME_KAS_N_BANK,
              isRewriteAll: false,
            },
          },
          isRequired: true,
        },
      ],
      [
        {
          label: 'label.contact-customer',
          placeholder: 'placeholder.choose-customer',
          inputName: 'contact_name',
          storeName: NAME_STORE.CONTACT,
          childrenStoreName: SLICE_NAME_CONTACT_DATA,
          keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
          keyUniqueName: DEFAULT_KEY_NAME_CONTACT_ID_API_REF,
          keyInputName: DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF,
          keyInputContainerName: DEFAULT_KEY_NAME_CUSTOMER_CONTAINER_ID_API_REF,
          statusRequestListPending: STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
          inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
          gridProps: defaultFourthPointHalfGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
          autocompleteProps: {
            fetchAction: contactList,
            additionalParamsForAction: {
              ...additionalParamsGetContactCustomer,
            },
            removeDataAction: clearContactData,
            additionalParamsForScrollAction: {
              isRewriteAll: false,
              ...additionalParamsGetContactCustomer,
            },
            getOptionLabel: (options) =>
              autocompleteHelper.getInputValue(
                options,
                ['contact_code', 'contact_name'],
                ['contact_name'],
                formatHelp.contactCodeWithName,
              ),
            isOptionEqualToValue: (option, value) => isEqual(option, value),
            isUseFetchOnInput: true,
            keyNameFetchInput: 'contact_name',
          },
          isUseAdditionalButtonNotFound: false,
          isDisabled: ({ actionMenu }) => {
            return actionMenu === DEFAULT_ACTION_MENU_UPDATE;
          },
          isRequired: true,
        },
        {
          label: 'label.customer-address',
          placeholder: 'placeholder.customer-address',
          inputName: 'customer_address',
          keyInputName: 'customer_address',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          gridProps: defaultSeventPointHalfGridProps,
          representationDataType: DEFAULT_REPRESENTATION_DATA_COMMON_TYPE,
        },
      ],
    ],
    secondaryAddUpdateDataSchema: [
      [
        {
          label: 'label.total',
          inputName: 'total',
          name: 'amount',
          keyInputName: 'amount',
          isTypography: true,
          representationDataType: DEFAULT_REPRESENTATION_DATA_CURRENCY_AUTO_COMMA_TYPE,
        },
      ],
      [
        // tagAutocompleteInputProps,
        {
          usageInputLabel: false,
          keyInputName: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
          inputType: DEFAULT_INPUT_TYPE_DROPZONE,
          additionalDropzoneProps: {
            generalizeRequestAction: (attachmentFile, relationValueToOtherAttachment) => {
              return generalizeAttachmentRequest({
                attachmentFile,
                relationValueToOtherAttachment,
                featureDocument: DEFAULT_TRANSACTION_SALES_PAYABLE_PAYMENT_API_REF_TYPE,
              });
            },
          },
          gridContainerProps: {
            width: 'calc(100% - 2px)',
          },
        },
        {
          label: 'label.memo',
          placeholder: 'placeholder.memo',
          inputName: 'memo',
          keyInputName: 'description',
          inputType: DEFAULT_INPUT_TYPE_COMMON_INPUT,
          additionalOutlinedInput: {
            rows: 3,
            multiline: true,
            className: 'none-padding',
          },
          representationDataType: DEFAULT_REPRESENTATION_DATA_BOX_TYPE,
        },
      ],
    ],
    inputValueChanges: {
      [DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF]: {
        generateChanges: ({ newValue }) => {
          return {
            resultChanges: {
              [DEFAULT_KEY_NAME_TRANSACTION_NO_CONTAINER]: {
                value: newValue,
              },
              [DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF]: newValue,
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_AMOUNT_INPUT]: {
        generateChanges: ({
          newValue,
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const amountInputChildrenTransaction = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            DEFAULT_KEY_NAME_AMOUNT_INPUT,
          );

          const sumAllAmountsInputChildrenTransaction = arrHelp.sumValueArrV2(
            amountInputChildrenTransaction,
          );

          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const { amount_input: currentSelectedAmountInput, amount_available_const } =
            selectedChildrenTransactionData;

          const amountTransactionData =
            Number(sumAllAmountsInputChildrenTransaction) -
            Number(currentSelectedAmountInput) +
            Number(newValue);

          return {
            transactionDataChanges: {
              amount: amountTransactionData,
            },
            resultChanges: {
              amount_available: Number(amount_available_const) - Number(newValue),
            },
          };
        },
      },
      [DEFAULT_KEY_NAME_QUANTITY_API_REF]: {
        generateChanges: ({
          newValue,
          childrenTransactionData = [],
          idChildrenTransaction = '',
        }) => {
          const selectedChildrenTransactionData = arrHelp.filterObjDataWithID(
            childrenTransactionData,
            'id',
            idChildrenTransaction,
          );

          const { quantity_available_const } = selectedChildrenTransactionData;
          const quantity_available = Number(quantity_available_const) - Number(newValue);

          return {
            resultChanges: {
              quantity_available,
            },
          };
        },
      },
    },
    autocompleteValueChanges: {
      [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF]: {
        isHitEndpoint: true,
        action: getSalesCreditNoteList,
        actionRemove: () =>
          clearDataPurchaseSlice(DEFAULT_PAYLOAD_STORAGE_PURCHASE_DEBIT_NOTE_LIST),
        storeName: ROOT_STORE_NAME_SALES,
        childrenSliceName: SLICE_NAME_SALES_CREDIT_NOTE_LIST,
        generateParamsAction: ({ newAutocompleteChanges = {} }) => {
          return {
            ...DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION_SECONDARY,
            [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF]:
              newAutocompleteChanges[DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF] || '',
            status: [
              DEFAULT_STATUS_SALES_CREDIT_NOTE_OPEN,
              DEFAULT_STATUS_SALES_CREDIT_NOTE_PARTIALLY_PAID,
            ],
          };
        },
        generateChanges: ({
          newValue,
          resultGetAction,
          currentTransactionData,
          transactionValidation = [],
          companyUserData,
        }) => {
          const { id: idTransactionData } = currentTransactionData[0];

          const creditNoteList = isEmpty(resultGetAction)
            ? []
            : resultGetAction[DEFAULT_KEY_NAME_RESPONSE_DATA_API_REF];

          let newTransactionData = cloneDeep(currentTransactionData),
            newTransactionValidation = cloneDeep(transactionValidation);

          if (!isEmpty(transactionValidation)) {
            const { newValidation } = checkValidation.changeOppositeStatusValidation(
              newTransactionValidation[0],
              [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF],
              true,
              isEmpty(newValue),
            );

            newTransactionValidation = [newValidation];
          }

          const { childrenTransactionData, childrenTransactionValidation } =
            generateDataNvalidationAddCreditNoteSalesPayablePaymentFromCreditNoteList({
              creditNoteList,
              dataKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
              validationKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
            });

          let sumAmountInputCreditNoteSalesPayablePayment = arrHelp.mappingChildrenKeyOnObject(
            childrenTransactionData,
            DEFAULT_KEY_NAME_AMOUNT_INPUT,
          );
          if (Array.isArray(sumAmountInputCreditNoteSalesPayablePayment)) {
            sumAmountInputCreditNoteSalesPayablePayment = arrHelp.sumValueArrV2(
              sumAmountInputCreditNoteSalesPayablePayment,
            );

            newTransactionData = arrHelp.combineDataObjInArray(
              newTransactionData,
              'id',
              idTransactionData,
              {
                amount: sumAmountInputCreditNoteSalesPayablePayment,
              },
            );
          }

          if (!isEmpty(currentTransactionData)) {
            const custometAddress = newValue
              ? newValue[DEFAULT_KEY_NAME_SHIPPING_ADDRESS_API_REF] || ''
              : '';

            const latestCreditNoteTransaction = cloneDeep(creditNoteList).sort(
              (creditNote, nextCreditNote) =>
                new Date(nextCreditNote[DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF]) -
                new Date(creditNote[DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF]),
            )[0];

            const minDateInput = getAccountingPeriodCompareWithDocumentTransactionDate({
              companyUserData,
              documentData: latestCreditNoteTransaction,
            });

            newTransactionData = arrHelp.combineDataObjInArray(
              newTransactionData,
              'id',
              idTransactionData,
              {
                customer_address: custometAddress,
                [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]: isEmpty(creditNoteList)
                  ? new Date()
                  : new Date(minDateInput),
              },
            );
          }

          if (isEmpty(newValue)) {
            newTransactionData = arrHelp.combineDataObjInArray(
              newTransactionData,
              'id',
              idTransactionData,
              {
                [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]: new Date(),
              },
            );
          }

          return {
            transactionData: newTransactionData,
            transactionValidation: newTransactionValidation,
            childrenTransactionData,
            childrenTransactionValidation,
          };
        },
      },
    },
    gridHeaderChildrenTransaction: gridHeaderCreditNoteSalesPayablePayment,
    gridCollapseChildrenTransaction: gridCollapseCreditNoteSalesPayablePayment,
    dependingKeyNameTransactionValidation: ['transaction_date'], // validation form on transaction
    anotherKeyNameTransactionDataDepends: [
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF, DEFAULT_KEY_NAME_TODAY],
    ], // validation form on data transaction
    keyNameBypassChildrenTransactionValidation: ['credit_note_id', 'amount_input'],
    checkingIsEmptyChildrenTransactionKeyNameBypass: [
      [null, ''],
      DEFAULT_EMPTY_CALCULATIONS_NUMBER,
    ],
    keyNameCheckingValidationInputBypass: ['amount_input'],
    keyNameCheckingStatusChildrenTransaction: ['credit_note_id'],
    menuActionUpdateProps: {
      [DEFAULT_KEY_NAME_PARAMS_URL_PAYABLE_PAYMENT_ID]: {
        getAction: (paramsRouter) => {
          return getSalesPayablePaymentDetails({
            [DEFAULT_KEY_NAME_PAYABLE_PAYMENT_ID_API_REF]:
              paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_PAYABLE_PAYMENT_ID],
          });
        },
        // dependencies action when after getting primary action (above action)
        onDependenciesActions: [defaultOnDependenciesListDetailsAttachmentAction],
        parentStateName: ROOT_STORE_NAME_SALES,
        childrenStateName: SLICE_NAME_SALES_PAYABLE_PAYMENT_DETAILS,
        generateDataNvalidationTransaction: ({ resultGetAction, ...params }) =>
          generateDataNvalidationUpdateSalesPayablePayment({
            salesPayablePaymentDetails: resultGetAction,
            ...params,
            dataKeyname: DEFAULT_KEY_NAME_TRANSACTION_DATA,
            validationKeyname: DEFAULT_KEY_NAME_TRANSACTION_VALIDATION,
          }),
        generateDataNvalidationChildrenTransaction: (resultGetAction) =>
          generateDataNvalidationUpdateCreditNoteSalesPayablePayment({
            salesPayablePaymentDetails: resultGetAction,
            dataKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
            validationKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
          }),
        // usage for getting actions when update action, must array
        additionalActionsMenuUpdate: [
          {
            getAction: (paramsGetAction) =>
              getSalesCreditNoteList({
                ...paramsGetAction,
                ...DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION_SECONDARY,
                status: [
                  DEFAULT_STATUS_SALES_CREDIT_NOTE_OPEN,
                  DEFAULT_STATUS_SALES_CREDIT_NOTE_PARTIALLY_PAID,
                ],
              }),
            generateParamsAction: ({ transactionData }) => {
              if (
                isEmpty(transactionData) ||
                !Array.isArray(transactionData) ||
                !transactionData.length
              )
                return {};

              return {
                [DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF]:
                  transactionData[0][DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF],
              };
            },
            parentStateName: ROOT_STORE_NAME_SALES,
            childrenStateName: SLICE_NAME_SALES_CREDIT_NOTE_LIST,
            keyNameResultActionStore: SLICE_NAME_SALES_CREDIT_NOTE_LIST,
          },
        ],
        // you must return transactionData, transactionValidation, childrenTransactionData, and childrenTransactionValidation
        // usage for adding state after additional actions for update
        generateStateDataNvalidationAssetsFromAdditionalActions: ({
          transactionData,
          transactionValidation,
          childrenTransactionData,
          childrenTransactionValidation,
          resultAdditionalActions,
        }) => {
          let newChildrenTransactionData = childrenTransactionData,
            newChildrenTransactionValidation = childrenTransactionValidation;

          if (
            !isEmpty(resultAdditionalActions[SLICE_NAME_SALES_CREDIT_NOTE_LIST]) &&
            Array.isArray(resultAdditionalActions[SLICE_NAME_SALES_CREDIT_NOTE_LIST].data) &&
            resultAdditionalActions[SLICE_NAME_SALES_CREDIT_NOTE_LIST].data.length
          ) {
            const {
              childrenTransactionData: childrenTransactionDataGen,
              childrenTransactionValidation: childrenTransactionValidationGen,
            } = generateDataNvalidationUpdateCreditNoteSalesPayablePaymentFromCreditNoteList({
              creditNoteList: resultAdditionalActions[SLICE_NAME_SALES_CREDIT_NOTE_LIST].data,
              currentCreditNoteSalesPaymentData: childrenTransactionData,
              currentCreditNoteSalesPaymentValidation: childrenTransactionValidation,
              dataKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
              validationKeyname: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION,
            });

            newChildrenTransactionData = childrenTransactionDataGen;
            newChildrenTransactionValidation = childrenTransactionValidationGen;
          }

          return {
            transactionData,
            transactionValidation,
            childrenTransactionData: newChildrenTransactionData,
            childrenTransactionValidation: newChildrenTransactionValidation,
          };
        },
      },
    },
    additionalSettingDefaultInputActionMenuAdd: [
      {
        // account payable
        parentStateName: ROOT_STORE_NAME_ACCOUNT,
        childrenListStateName: SLICE_NAME_ACCOUNT_DATA,
        keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
        keyNameSubResponseData: DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF,
        defaultKeyInputName: DEFAULT_KEY_NAME_ACCOUNT_PAYABLE_API_REF,
        defaultKeyInputContainerName: DEFAULT_KEY_NAME_ACCOUNT_PAYABLE_CONTAINER,
        indexSelected: 0,
        action: getAccountList,
        additionalParams: {
          [DEFAULT_KEY_NAME_CATEGORY_NAME_API_REF]: ACCOUNT_CATEGORY_NAME_CASH_N_BANK,
        },
        isAddDefaultInput: ({ transactionData = [] }) => {
          return (
            isEmpty(transactionData) ||
            !transactionData[0][DEFAULT_KEY_NAME_ACCOUNT_PAYABLE_CONTAINER]
          );
        },
        isListData: true,
      },
    ],
    additionalSettingDefaultInputActionMenuUpdate: [
      {
        // account payable
        parentStateName: ROOT_STORE_NAME_ACCOUNT,
        childrenListStateName: SLICE_NAME_ACCOUNT_DATA,
        keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
        keyNameSubResponseData: DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF,
        defaultKeyInputName: DEFAULT_KEY_NAME_ACCOUNT_PAYABLE_API_REF,
        defaultKeyInputContainerName: DEFAULT_KEY_NAME_ACCOUNT_PAYABLE_CONTAINER,
        indexSelected: 0,
        action: getAccountList,
        additionalParams: {
          [DEFAULT_KEY_NAME_CATEGORY_NAME_API_REF]: ACCOUNT_CATEGORY_NAME_CASH_N_BANK,
        },
        isAddDefaultInput: ({ transactionData = [] }) => {
          return (
            isEmpty(transactionData) ||
            !transactionData[0][DEFAULT_KEY_NAME_ACCOUNT_PAYABLE_CONTAINER]
          );
        },
        isListData: true,
      },
    ],
    generateDataForSettingDefaultInputActionMenuAdd: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    generateDataForSettingDefaultInputActionMenuUpdate: (
      resultDefaultInput,
      childrenTransactionData,
    ) => {
      return {
        [DEFAULT_KEY_NAME_TRANSACTION_DATA]: [
          {
            ...resultDefaultInput,
          },
        ],
        [DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA]: childrenTransactionData,
      };
    },
    parentMenuURL: REACT_APP_SALES_PAYABLE_PAYMENT_URL,
  },
};

export const getTransactionProCreateUpdateConfig = (subTransactionType) => {
  const keysTransactionProCreateUpdate = Object.keys(defaultTransactionProCreateUpdateConfig);

  if (!keysTransactionProCreateUpdate.includes(subTransactionType))
    subTransactionType = keysTransactionProCreateUpdate[0];

  return defaultTransactionProCreateUpdateConfig[subTransactionType];
};

export const defaultFormMinutesHandover = [
  {
    label: 'label.bast-uppercase',
    inputType: DEFAULT_INPUT_TYPE_CHECKBOX_WITH_LABEL,
    keyInputName: DEFAULT_KEY_NAME_IS_SENT_API_REF,
    labelChecked: 'typography.received',
    labelUnChecked: 'typography.received',
  },
];

export const defaultFormStatus = [
  {
    label: 'label.status',
    inputType: DEFAULT_INPUT_TYPE_CHECKBOX_WITH_LABEL,
    keyInputName: DEFAULT_KEY_NAME_IS_PAID_OFF_API_REF,
    labelChecked: 'typography.mark-paid-off',
    labelUnChecked: 'typography.mark-paid-off',
  },
];

export const defaultFormAdditionalCostOnExpense = [
  {
    storeName: NAME_STORE.ACCOUNT,
    childrenStoreName: SLICE_NAME_ACCOUNT_DATA,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    keyUniqueName: DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF,
    keyInputName: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_API_REF,
    keyInputContainerName: DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_CONTAINER,
    inputType: DEFAULT_INPUT_TYPE_AUTOCOMPLETE,
    statusRequestListPending: STATUS_REQUEST_LIST_ACCOUNT_DATA_PENDING,
    label: 'label.discount',
    placeholderPrimary: 'placeholder.calculation-number-default',
    placeholder: 'placeholder.choose-discount-account',
    keyNameStateCollapse: 'isCollapseOpenDiscount',
    collapseName: 'discount',
    isUseCurrencyConversion: true,
    accountAutocompleteProps: {
      ...defaultAccountAutocompleteProps,
    },
    isDisabled: ({ actionMenu, transactionData }) => {
      const { transaction_date, accounting_period } = transactionData[0];

      return actionMenu === DEFAULT_ACTION_MENU_UPDATE && transaction_date < accounting_period;
    },
  },
];

export const keyNameConfigurationCreate = 'configurationCreate';
export const keyNameConfigurationUpdate = 'configurationUpdate';

export const defaultTransactionProMinutesHandoverCreateUpdateConfig = {
  [DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE]: {
    parentStateName: ROOT_STORE_NAME_MINUTES_OF_HANDOVER,
    childrenDetailsStateName: SLICE_NAME_HANDOVER_SALES_DELIVERY_DETAILS,
    [keyNameConfigurationCreate]: {
      ...defaultTransactionProDetailsConfig[DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE],
      generateLeftButtonLink: (salesDeliveryDetails) => {
        if (isEmpty(salesDeliveryDetails)) return '';

        return strHelp.templateString(
          REACT_APP_SALES_SALES_DELIVERY_DETAILS_TEMPLATE_URL,
          salesDeliveryDetails,
        );
      },
      generateAfterActionSuccessLink: (paramsRouter) => {
        if (isEmpty(paramsRouter)) return REACT_APP_SALES_URL;

        const salesDeliveryID = objHelper.findAllValuesByKey(
          paramsRouter,
          DEFAULT_KEY_NAME_PARAMS_URL_SALES_DELIVERY_ID,
        );

        const templateObj = {
          [DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF]: !isEmpty(salesDeliveryID)
            ? salesDeliveryID[0]
            : null,
        };

        return strHelp.templateString(
          REACT_APP_SALES_SALES_DELIVERY_DETAILS_TEMPLATE_URL,
          templateObj,
        );
      },
      defaultLinkNavigate: REACT_APP_SALES_URL,
    },
    [keyNameConfigurationUpdate]: {
      parentStateName: ROOT_STORE_NAME_MINUTES_OF_HANDOVER,
      childrenDetailsStateName: SLICE_NAME_HANDOVER_SALES_DELIVERY_DETAILS,
      requestStatusPendingGetDetails: STATUS_REQUEST_DETAILS_HANDOVER_SALES_DELIVERY_PENDING,
      uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_SALES_DELIVERY_HANDOVER_ID,
      uniqueParamsGetDetails: DEFAULT_KEY_NAME_SALES_HANDOVER_ID_API_REF,
      actionGetDetails: minutesOfHandoverDetails,
      parentAdditionalStateName: NAME_STORE.SALES,
      childrenAdditionalStateName: SLICE_NAME_SALES_DELIVERY_DETAILS,
      keyNameAdditionalBodyData: DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF,
      isInterruptMinutesHandoverData: true,
      additionalAction: getSalesDeliveryDetails,
      onDependenciesActions: [defaultOnDependenciesListDetailsAttachmentAction],
      generateLeftButtonLink: (salesDeliveryDetails) => {
        if (isEmpty(salesDeliveryDetails)) return '';

        return strHelp.templateString(
          REACT_APP_SALES_SALES_DELIVERY_HANDOVER_DETAILS_TEMPLATE_URL,
          salesDeliveryDetails,
        );
      },
      generalizeParamsRoute: (paramsRouter) => {
        if (isEmpty(paramsRouter)) return {};

        return {
          [DEFAULT_KEY_NAME_SALES_HANDOVER_ID_API_REF]:
            paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_SALES_DELIVERY_HANDOVER_ID],
          [DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF]:
            paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_SALES_DELIVERY_ID],
        };
      },
      generateParamsGetDetails: (currParams) => {
        if (isEmpty(currParams)) return {};

        return {
          paramsMinutesOfHandoverDetails: {
            [DEFAULT_KEY_NAME_SALES_HANDOVER_ID_API_REF]:
              currParams[DEFAULT_KEY_NAME_SALES_HANDOVER_ID_API_REF],
          },
          minutesHandoverType: MINUTES_OF_HANDOVER_TYPE_SALES_DELIVERY,
        };
      },
      generateAfterActionSuccessLink: (paramsRouter) => {
        if (isEmpty(paramsRouter)) return REACT_APP_SALES_URL;

        const salesDeliveryID = objHelper.findAllValuesByKey(
          paramsRouter,
          DEFAULT_KEY_NAME_PARAMS_URL_SALES_DELIVERY_ID,
        );
        const salesHandoverDeliveryID = objHelper.findAllValuesByKey(
          paramsRouter,
          DEFAULT_KEY_NAME_PARAMS_URL_SALES_DELIVERY_HANDOVER_ID,
        );

        const templateObj = {
          [DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF]: !isEmpty(salesDeliveryID)
            ? salesDeliveryID[0]
            : null,
          [DEFAULT_KEY_NAME_SALES_HANDOVER_ID_API_REF]: !isEmpty(salesHandoverDeliveryID)
            ? salesHandoverDeliveryID[0]
            : null,
        };

        return strHelp.templateString(
          REACT_APP_SALES_SALES_DELIVERY_DETAILS_TEMPLATE_URL,
          templateObj,
        );
      },
    },
    parentMenuURL: REACT_APP_SALES_SALES_DELIVERY_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE]: {
    parentStateName: ROOT_STORE_NAME_MINUTES_OF_HANDOVER,
    childrenDetailsStateName: SLICE_NAME_HANDOVER_PURCHASE_DELIVERY_DETAILS,
    [keyNameConfigurationCreate]: {
      ...defaultTransactionProDetailsConfig[DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE],
      generateLeftButtonLink: (purchaseDeliveryDetails) => {
        if (isEmpty(purchaseDeliveryDetails)) return '';

        return strHelp.templateString(
          REACT_APP_PURCHASE_PURCHASE_DELIVERY_DETAILS_TEMPLATE_URL,
          purchaseDeliveryDetails,
        );
      },
      generateAfterActionSuccessLink: (paramsRouter) => {
        if (isEmpty(paramsRouter)) return REACT_APP_SALES_URL;

        const purchaseDeliveryID = objHelper.findAllValuesByKey(
          paramsRouter,
          DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_DELIVERY_ID,
        );

        const templateObj = {
          [DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF]: !isEmpty(purchaseDeliveryID)
            ? purchaseDeliveryID[0]
            : null,
        };

        return strHelp.templateString(
          REACT_APP_PURCHASE_PURCHASE_DELIVERY_DETAILS_TEMPLATE_URL,
          templateObj,
        );
      },
      defaultLinkNavigate: REACT_APP_PURCHASE_URL,
    },
    [keyNameConfigurationUpdate]: {
      parentStateName: ROOT_STORE_NAME_MINUTES_OF_HANDOVER,
      childrenDetailsStateName: SLICE_NAME_HANDOVER_PURCHASE_DELIVERY_DETAILS,
      requestStatusPendingGetDetails: STATUS_REQUEST_DETAILS_HANDOVER_PURCHASE_DELIVERY_PENDING,
      uniqueParamsUrl: DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_DELIVERY_HANDOVER_ID,
      uniqueParamsGetDetails: DEFAULT_KEY_NAME_PURCHASE_HANDOVER_ID_API_REF,
      actionGetDetails: minutesOfHandoverDetails,
      parentAdditionalStateName: NAME_STORE.PURCHASE,
      childrenAdditionalStateName: SLICE_NAME_PURCHASE_DELIVERY_DETAIL,
      keyNameAdditionalBodyData: DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF,
      isInterruptMinutesHandoverData: true,
      additionalAction: getDetailPurchaseDelivery,
      onDependenciesActions: [defaultOnDependenciesListDetailsAttachmentAction],
      generateLeftButtonLink: (purchaseDeliveryDetails) => {
        if (isEmpty(purchaseDeliveryDetails)) return '';

        return strHelp.templateString(
          REACT_APP_PURCHASE_PURCHASE_DELIVERY_HANDOVER_DETAILS_TEMPLATE_URL,
          purchaseDeliveryDetails,
        );
      },
      generalizeParamsAdditionalAction: (generalizedParamsRoute) => {
        return generalizedParamsRoute[DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF];
      },
      generalizeParamsRoute: (paramsRouter) => {
        if (isEmpty(paramsRouter)) return {};

        return {
          [DEFAULT_KEY_NAME_PURCHASE_HANDOVER_ID_API_REF]:
            paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_DELIVERY_HANDOVER_ID],
          [DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF]:
            paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_DELIVERY_ID],
        };
      },
      generateParamsGetDetails: (currParams) => {
        if (isEmpty(currParams)) return {};

        return {
          paramsMinutesOfHandoverDetails: {
            [DEFAULT_KEY_NAME_PURCHASE_HANDOVER_ID_API_REF]:
              currParams[DEFAULT_KEY_NAME_PURCHASE_HANDOVER_ID_API_REF],
          },
          minutesHandoverType: MINUTES_OF_HANDOVER_TYPE_PURCHASE_DELIVERY,
        };
      },
      generateAfterActionSuccessLink: (paramsRouter) => {
        if (isEmpty(paramsRouter)) return REACT_APP_PURCHASE_URL;

        const purchaseDeliveryID = objHelper.findAllValuesByKey(
          paramsRouter,
          DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_DELIVERY_ID,
        );
        const purchaseHandoverDeliveryID = objHelper.findAllValuesByKey(
          paramsRouter,
          DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_DELIVERY_HANDOVER_ID,
        );

        const templateObj = {
          [DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF]: !isEmpty(purchaseDeliveryID)
            ? purchaseDeliveryID[0]
            : null,
          [DEFAULT_KEY_NAME_PURCHASE_HANDOVER_ID_API_REF]: !isEmpty(purchaseHandoverDeliveryID)
            ? purchaseHandoverDeliveryID[0]
            : null,
        };

        return strHelp.templateString(
          REACT_APP_PURCHASE_PURCHASE_DELIVERY_DETAILS_TEMPLATE_URL,
          templateObj,
        );
      },
    },
    parentMenuURL: REACT_APP_PURCHASE_PURCHASE_DELIVERY_URL,
  },
};

export const getTransactionProMinutesHandoverConfiguration = (
  subTransactionType = DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE,
) => {
  const keyTransactionConfigurationMinutesHandover = Object.keys(
    defaultTransactionProMinutesHandoverCreateUpdateConfig,
  );

  if (!keyTransactionConfigurationMinutesHandover.includes(subTransactionType))
    subTransactionType = keyTransactionConfigurationMinutesHandover[0];

  return defaultTransactionProMinutesHandoverCreateUpdateConfig[subTransactionType];
};

export const defaultTransactionProInvoicePayment = {
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_INVOICE_TYPE]: {
    parentStateDetailsName: NAME_STORE.PURCHASE,
    childrenStateDetailsName: SLICE_NAME_DETAIL_PURCHASE_INVOICE,
    paramsReferenceNumberDetails: {
      code: DEFAULT_TRANSACTION_NUMBER_CODE_PURCHASE_PAYMENT,
    },
    actionAddTransactionPayment: addPurchasePaymentV2,
    statusRequestAddPaymentPending: STATUS_REQUEST_ADD_PURCHASE_PAYMENT_PENDING,
    statusRequestAddPaymentSuccess: STATUS_REQUEST_ADD_PURCHASE_PAYMENT_SUCCESS,
    keyNameTransactionPaymentNumber: 'purchase_payment_no',
    keyNameTransactionPaymentNumberContainer: 'purchase_payment_no_container',
    propsForDialogTransactionPayment: {
      subTransactionType: DEFAULT_TRANSACTION_PURCHASE_PURCHASE_INVOICE_TYPE,
      initialPaymentData: initialPurchasePaymentData,
      initialInvoiceData: initialPurchaseInvoiceData,
      allowKeysInvoiceData: allowKeysPurchaseInvoiceData,
      allowKeysPaymentData: allowKeysPurchasePaymentData,
      generateDataNValidationPayment: () => generateDataNValidationAddPaymentPP(),
      generateDataNValidationInvoice: () => generateDataNValidationAddInvoicePP(),
      generalizeDataForAction: generalizeDataAddPurchasePayment,
      generateDataNvalidationPaymentFromInvoiceDetails: (
        purchaseInvoiceDetails,
        currentPurchasePaymentData,
        currentPurchasePaymentValidation,
      ) =>
        generateDataNvalidationAddPurchasePaymentFromPurchaseInvoiceDetails({
          purchaseInvoiceDetails,
          currentPurchasePaymentData,
          currentPurchasePaymentValidation,
          dataKeyname: 'paymentData',
          validationKeyname: 'paymentValidation',
        }),
      minimumTransactionDateKeyname: DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
    },
    keyNameOnTranslationTitle: DEFAULT_KEY_NAME_PURCHASE_INVOICE_NO_API_REF,
    restPaymentCounterKeyname: 'amount_available',
  },
  [DEFAULT_TRANSACTION_PURCHASE_DEBIT_NOTE_TYPE]: {
    parentStateDetailsName: ROOT_STORE_NAME_PURCHASE,
    childrenStateDetailsName: SLICE_NAME_PURCHASE_DEBIT_NOTE_DETAILS,
    paramsReferenceNumberDetails: {
      code: DEFAULT_TRANSACTION_NUMBER_CODE_RECEIVABLE_PAYMENT,
      isShowMessage: false,
    },
    actionAddTransactionPayment: createPurchaseReceivablePayment,
    statusRequestAddPaymentPending: REQUEST_STATUS_CREATE_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
    statusRequestAddPaymentSuccess: REQUEST_STATUS_CREATE_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS,
    keyNameTransactionPaymentNumber: 'transaction_no',
    keyNameTransactionPaymentNumberContainer: 'transaction_no_container',
    propsForDialogTransactionPayment: {
      subTransactionType: DEFAULT_TRANSACTION_PURCHASE_DEBIT_NOTE_TYPE,
      initialPaymentData: initialDataPurchaseReceivablePayment,
      initialInvoiceData: initialDataDebitNotePurchaseReceivablePayment,
      allowKeysInvoiceData: allowKeysDataDebitNotePurchaseReceivablePayment,
      allowKeysPaymentData: allowKeysDataPurchaseReceivablePayment,
      generateDataNValidationPayment: (companyUserData) =>
        generateDataNvalidationAddPurchaseReceivablePaymentWithWrapperAccountReceivable({
          companyUserData,
          dataKeyname: 'paymentData',
          validationKeyname: 'paymentValidation',
        }),
      generateDataNValidationInvoice: () =>
        generateDataNvalidationAddDebitNotePurchaseReceivablePayment({
          dataKeyname: 'invoiceData',
          validationKeyname: 'invoiceValidation',
        }),
      generateDataNvalidationPaymentFromInvoiceDetails: (
        invoiceDetails,
        currentPurchaseReceivablePaymentData,
        currentPurchaseReceivablePaymentValidation,
        companyUserData,
      ) =>
        generateDataNvalidationAddPurchaseReceivablePaymentFromDebitNoteDetails({
          debitNoteDetails: invoiceDetails,
          currentPurchaseReceivablePaymentData,
          currentPurchaseReceivablePaymentValidation,
          dataKeyname: 'paymentData',
          validationKeyname: 'paymentValidation',
          companyUserData,
        }),
      generateDataNvalidationInvoiceFromInvoiceDetails: (invoiceDetails) =>
        generateDataNvalidationAddDebitNotePurchaseReceivablePaymentFromDebitNoteList({
          debitNoteList: [invoiceDetails],
          dataKeyname: 'invoiceData',
          validationKeyname: 'invoiceValidation',
          fillAmountInputFull: false,
        }),
      generalizeDataForAction: (
        purchaseReceivablePaymentData,
        debitNotePurchaseReceivablePaymentData,
      ) =>
        generalizeDataAddPurchaseReceivablePayment(
          purchaseReceivablePaymentData,
          debitNotePurchaseReceivablePaymentData,
          true,
        ),
      customHeaderTranslation: 'header.receivable-payment',
      customAccountLabel: 'label.recipient-account',
      maximumTransactionDateKeyname: DEFAULT_KEY_NAME_TODAY,
      minimumTransactionDateKeyname: DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
      usageAttachment: true,
      attachmentKeyname: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
      additionalDropzoneProps: {
        generalizeRequestAction: (attachmentFile, relationValueToOtherAttachment) => {
          return generalizeAttachmentRequest({
            attachmentFile,
            relationValueToOtherAttachment,
            featureDocument: DEFAULT_TRANSACTION_PURCHASE_RECEIVABLE_PAYMENT_TYPE,
          });
        },
      },
    },
    keyNameOnTranslationTitle: DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF,
    restPaymentCounterKeyname: 'amount_available',
  },
  [DEFAULT_TRANSACTION_SALES_SALES_INVOICE_TYPE]: {
    parentStateDetailsName: NAME_STORE.SALES,
    childrenStateDetailsName: SLICE_NAME_SALES_INVOICE_DETAILS,
    paramsReferenceNumberDetails: {
      code: DEFAULT_TRANSACTION_NUMBER_CODE_SALES_PAYMENT,
    },
    actionAddTransactionPayment: addSalesPayment,
    statusRequestAddPaymentPending: STATUS_REQUEST_ADD_SALES_PAYMENT_PENDING,
    statusRequestAddPaymentSuccess: STATUS_REQUEST_ADD_SALES_PAYMENT_SUCCESS,
    keyNameTransactionPaymentNumber: 'sales_payment_no',
    keyNameTransactionPaymentNumberContainer: 'sales_payment_no_container',
    propsForDialogTransactionPayment: {
      subTransactionType: DEFAULT_TRANSACTION_SALES_SALES_INVOICE_TYPE,
      initialPaymentData: initialSalesPaymentData,
      initialInvoiceData: initialSalesInvoiceData,
      allowKeysInvoiceData: allowKeysSalesInvoiceData,
      allowKeysPaymentData: allowKeysSalesPaymentData,
      generateDataNValidationPayment: () => generateDataNValidationAddPaymentSalesPayment(),
      generateDataNValidationInvoice: () => generateDataNValidationAddInvoiceSalesPayment(),
      generalizeDataForAction: generalizeDataAddSalesPayment,
      minimumTransactionDateKeyname: DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
    },
    keyNameOnTranslationTitle: DEFAULT_KEY_NAME_SALES_INVOICE_NO_API_REF,
    restPaymentCounterKeyname: 'amount_available',
  },
  [DEFAULT_TRANSACTION_SALES_CREDIT_NOTE_TYPE]: {
    parentStateDetailsName: ROOT_STORE_NAME_SALES,
    childrenStateDetailsName: SLICE_NAME_SALES_CREDIT_NOTE_DETAILS,
    paramsReferenceNumberDetails: {
      code: DEFAULT_TRANSACTION_NUMBER_CODE_PAYABLE_PAYMENT,
      isShowMessage: false,
    },
    actionAddTransactionPayment: createSalesPayablePayment,
    statusRequestAddPaymentPending: REQUEST_STATUS_CREATE_SALES_PAYABLE_PAYMENT_LOADING,
    statusRequestAddPaymentSuccess: REQUEST_STATUS_CREATE_SALES_PAYABLE_PAYMENT_SUCCESS,
    keyNameTransactionPaymentNumber: 'transaction_no',
    keyNameTransactionPaymentNumberContainer: 'transaction_no_container',
    propsForDialogTransactionPayment: {
      subTransactionType: DEFAULT_TRANSACTION_SALES_CREDIT_NOTE_TYPE,
      initialPaymentData: initialDataSalesPayablePayment,
      initialInvoiceData: initialDataCreditNoteSalesPayablePayment,
      allowKeysInvoiceData: allowKeysDataDebitNotePurchaseReceivablePayment,
      allowKeysPaymentData: allowKeysDataCreditNoteSalesPayablePayment,
      generateDataNValidationPayment: (companyUserData) =>
        generateDataNvalidationAddSalesPayablePaymentWithWrappingKeyname({
          companyUserData,
          dataKeyname: 'paymentData',
          validationKeyname: 'paymentValidation',
        }),
      generateDataNValidationInvoice: () =>
        generateDataNvalidationAddCreditNoteSalesPayablePayment({
          dataKeyname: 'invoiceData',
          validationKeyname: 'invoiceValidation',
        }),
      generateDataNvalidationPaymentFromInvoiceDetails: (
        creditNoteDetails,
        currentSalesPayablePaymentData,
        currentSalesPayablePaymentValidation,
        companyUserData,
      ) =>
        generateDataNvalidationAddSalesPayablePaymentFromCreditNoteDetails({
          creditNoteDetails,
          companyUserData,
          currentSalesPayablePaymentData,
          currentSalesPayablePaymentValidation,
          dataKeyname: 'paymentData',
          validationKeyname: 'paymentValidation',
        }),
      generateDataNvalidationInvoiceFromInvoiceDetails: (creditNoteDetails) =>
        generateDataNvalidationAddCreditNoteSalesPayablePaymentFromCreditNoteList({
          creditNoteList: [creditNoteDetails],
          dataKeyname: 'invoiceData',
          validationKeyname: 'invoiceValidation',
          fillAmountInputFull: false,
        }),
      generalizeDataForAction: (salesPayablePaymentData, creditNoteSalesPayablePaymentData) =>
        generalizeDataAddSalesPayablePayment(
          salesPayablePaymentData,
          creditNoteSalesPayablePaymentData,
          true,
        ),
      customHeaderTranslation: 'header.payable-payment',
      customAccountLabel: 'label.recipient-account',
      maximumTransactionDateKeyname: DEFAULT_KEY_NAME_TODAY,
      minimumTransactionDateKeyname: DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
      usageAttachment: true,
      attachmentKeyname: DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
      additionalDropzoneProps: {
        generalizeRequestAction: (attachmentFile, relationValueToOtherAttachment) => {
          return generalizeAttachmentRequest({
            attachmentFile,
            relationValueToOtherAttachment,
            featureDocument: DEFAULT_TRANSACTION_SALES_PAYABLE_PAYMENT_API_REF_TYPE,
          });
        },
      },
    },
    keyNameOnTranslationTitle: DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF,
    restPaymentCounterKeyname: 'amount_available',
  },
};

export const getDefaultTransactionProInvoicePayment = (
  subTransactionType = DEFAULT_TRANSACTION_PURCHASE_PURCHASE_INVOICE_TYPE,
) => {
  const allAvailableSubTransaction = Object.keys(defaultTransactionProInvoicePayment);

  if (!allAvailableSubTransaction.includes(subTransactionType) || !subTransactionType)
    subTransactionType = allAvailableSubTransaction[0];

  return defaultTransactionProInvoicePayment[subTransactionType];
};

export const defaultActionTransactionProAddConfiguration = {
  [DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_TYPE]: {
    parentState: NAME_STORE.PURCHASE,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_ADD_QUOTATION_REQUEST_PENDING,
    statusRequestFailed: STATUS_REQUEST_ADD_QUOTATION_REQUEST_FAILED,
    generateActionBody: generalizeDataAddQuotationRequest,
    action: addQuotationRequestV2,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_ORDER_TYPE]: {
    parentState: NAME_STORE.PURCHASE,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_ADD_PURCHASE_ORDER_PENDING,
    statusRequestFailed: STATUS_REQUEST_ADD_PURCHASE_ORDER_FAILED,
    generateActionBody: generalizeDataAddPurchaseOrder,
    action: addPurchaseOrderV2,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE]: {
    parentState: NAME_STORE.PURCHASE,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_ADD_PURCHASE_DELIVERY_PENDING,
    statusRequestFailed: STATUS_REQUEST_ADD_PURCHASE_DELIVERY_FAILED,
    generateActionBody: generalizeDataAddPurchaseDelivery,
    action: addPurchaseDeliveryV2,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_INVOICE_TYPE]: {
    parentState: NAME_STORE.PURCHASE,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_ADD_PURCHASE_INVOICE_PENDING,
    statusRequestFailed: STATUS_REQUEST_ADD_PURCHASE_INVOICE_FAILED,
    generateActionBody: generalizeDataAddPurchaseInvoice,
    action: addPurchaseInvoiceV2,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_PAYMENT_TYPE]: {
    parentState: NAME_STORE.PURCHASE,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_ADD_PURCHASE_PAYMENT_PENDING,
    statusRequestFailed: STATUS_REQUEST_ADD_PURCHASE_PAYMENT_FAILED,
    generateActionBody: generalizeDataAddPurchasePayment,
    action: addPurchasePaymentV2,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_QUOTATION_TYPE]: {
    parentState: NAME_STORE.SALES,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_ADD_SALES_QUOTATION_PENDING,
    statusRequestFailed: STATUS_REQUEST_ADD_SALES_QUOTATION_FAILED,
    generateActionBody: generalizeDataAddSalesQuotation,
    action: addSalesQuotation,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_ORDER_TYPE]: {
    parentState: NAME_STORE.SALES,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_ADD_SALES_ORDER_PENDING,
    statusRequestFailed: STATUS_REQUEST_ADD_SALES_ORDER_FAILED,
    generateActionBody: generalizeDataAddSalesOrder,
    action: addSalesOrder,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE]: {
    parentState: NAME_STORE.SALES,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_ADD_SALES_DELIVERY_PENDING,
    statusRequestFailed: STATUS_REQUEST_ADD_SALES_DELIVERY_FAILED,
    generateActionBody: generalizeDataAddSalesDelivery,
    action: addSalesDelivery,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_INVOICE_TYPE]: {
    parentState: NAME_STORE.SALES,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_ADD_SALES_INVOICE_PENDING,
    statusRequestFailed: STATUS_REQUEST_ADD_SALES_INVOICE_FAILED,
    generateActionBody: generalizeDataAddSalesInvoice,
    action: addSalesInvoiceV2,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_PAYMENT_TYPE]: {
    parentState: NAME_STORE.SALES,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_ADD_SALES_PAYMENT_PENDING,
    statusRequestFailed: STATUS_REQUEST_ADD_SALES_PAYMENT_FAILED,
    generateActionBody: generalizeDataAddSalesPayment,
    action: addSalesPayment,
  },
  [DEFAULT_TRANSACTION_EXPENSE_TYPE]: {
    parentState: NAME_STORE.EXPENSE,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_ADD_EXPENSE_PENDING,
    statusRequestFailed: STATUS_REQUEST_ADD_EXPENSE_FAILED,
    generateActionBody: generalizeDataAddExpense,
    action: addExpense,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_RETURN_TYPE]: {
    parentState: ROOT_STORE_NAME_PURCHASE,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: REQUEST_STATUS_CREATE_PURCHASE_RETURN_PENDING,
    statusRequestFailed: REQUEST_STATUS_CREATE_PURCHASE_RETURN_FAILED,
    generateActionBody: generalizeDataAddPurchaseReturn,
    action: createPurchaseReturn,
  },
  [DEFAULT_TRANSACTION_PURCHASE_RECEIVABLE_PAYMENT_TYPE]: {
    parentState: ROOT_STORE_NAME_PURCHASE,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: REQUEST_STATUS_CREATE_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
    statusRequestFailed: REQUEST_STATUS_CREATE_PURCHASE_RECEIVABLE_PAYMENT_FAILED,
    generateActionBody: generalizeDataAddPurchaseReceivablePayment,
    action: createPurchaseReceivablePayment,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_RETURN_TYPE]: {
    parentState: ROOT_STORE_NAME_SALES,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: REQUEST_STATUS_CREATE_SALES_RETURN_PENDING,
    statusRequestFailed: REQUEST_STATUS_CREATE_SALES_RETURN_FAILED,
    generateActionBody: generalizeDataAddSalesReturn,
    action: createSalesReturn,
  },
  [DEFAULT_TRANSACTION_SALES_PAYABLE_PAYMENT_TYPE]: {
    parentState: ROOT_STORE_NAME_SALES,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: REQUEST_STATUS_CREATE_SALES_PAYABLE_PAYMENT_LOADING,
    statusRequestFailed: REQUEST_STATUS_CREATE_SALES_PAYABLE_PAYMENT_FAILED,
    generateActionBody: generalizeDataAddSalesPayablePayment,
    action: createSalesPayablePayment,
  },
};

export const defaultActionTransactionProUpdateConfiguration = {
  [DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_TYPE]: {
    parentState: NAME_STORE.PURCHASE,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_UPDATE_QUOTATION_REQUEST_PENDING,
    statusRequestFailed: STATUS_REQUEST_UPDATE_QUOTATION_REQUEST_FAILED,
    generateActionBody: generalizeDataUpdateQuotationRequestV2,
    action: updateQuotationRequest,
    generateLinkDetails: (paramsRouter) => {
      return strHelp.templateString(REACT_APP_PURCHASE_QUOTATION_REQUEST_DETAILS_TEMPLATE_URL, {
        [DEFAULT_KEY_NAME_QUOTATION_REQUEST_ID_API_REF]: !isEmpty(paramsRouter)
          ? paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_QUOTATION_ID]
          : '',
      });
    },
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_ORDER_TYPE]: {
    parentState: NAME_STORE.PURCHASE,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_UPDATE_PURCHASE_ORDER_PENDING,
    statusRequestFailed: STATUS_REQUEST_UPDATE_PURCHASE_ORDER_FAILED,
    generateActionBody: generalizeDataUpdatePurchaseOrder,
    action: updatePurchaseOrder,
    generateLinkDetails: (paramsRouter) => {
      return strHelp.templateString(REACT_APP_PURCHASE_PURCHASE_ORDER_DETAILS_TEMPLATE_URL, {
        [DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_API_REF]: !isEmpty(paramsRouter)
          ? paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_ORDER_ID]
          : '',
      });
    },
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE]: {
    parentState: NAME_STORE.PURCHASE,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_UPDATE_PURCHASE_DELIVERY_PENDING,
    statusRequestFailed: STATUS_REQUEST_UPDATE_PURCHASE_DELIVERY_FAILED,
    generateActionBody: generalizeDataUpdatePurchaseDelivery,
    action: updatePurchaseDelivery,
    generateLinkDetails: (paramsRouter) => {
      return strHelp.templateString(REACT_APP_PURCHASE_PURCHASE_DELIVERY_DETAILS_TEMPLATE_URL, {
        [DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF]: !isEmpty(paramsRouter)
          ? paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_DELIVERY_ID]
          : '',
      });
    },
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_INVOICE_TYPE]: {
    parentState: NAME_STORE.PURCHASE,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_UPDATE_PURCHASE_INVOICE_PENDING,
    statusRequestFailed: STATUS_REQUEST_UPDATE_PURCHASE_INVOICE_FAILED,
    generateActionBody: generalizeDataUpdatePurchaseInvoice,
    action: updatePurchaseInvoice,
    generateLinkDetails: (paramsRouter) => {
      return strHelp.templateString(REACT_APP_PURCHASE_PURCHASE_INVOICE_DETAILS_TEMPLATE_URL, {
        [DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF]: !isEmpty(paramsRouter)
          ? paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_INVOICE_ID]
          : '',
      });
    },
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_PAYMENT_TYPE]: {
    parentState: NAME_STORE.PURCHASE,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_UPDATE_PURCHASE_PAYMENT_PENDING,
    statusRequestFailed: STATUS_REQUEST_UPDATE_PURCHASE_PAYMENT_FAILED,
    generateActionBody: generalizeDataUpdatePurchasePayment,
    action: updatePurchasePayment,
    generateLinkDetails: (paramsRouter) => {
      return strHelp.templateString(REACT_APP_PURCHASE_PURCHASE_PAYMENT_DETAILS_TEMPLATE_URL, {
        [DEFAULT_KEY_NAME_PURCHASE_PAYMENT_ID_API_REF]: !isEmpty(paramsRouter)
          ? paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_PAYMENT_ID]
          : '',
      });
    },
  },
  [DEFAULT_TRANSACTION_SALES_SALES_QUOTATION_TYPE]: {
    parentState: NAME_STORE.SALES,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_UPDATE_SALES_QUOTATION_PENDING,
    statusRequestFailed: STATUS_REQUEST_UPDATE_SALES_QUOTATION_FAILED,
    generateActionBody: generalizeDataUpdateSalesQuotation,
    action: updateSalesQuotation,
    generateLinkDetails: (paramsRouter) => {
      return strHelp.templateString(REACT_APP_SALES_SALES_QUOTATION_DETAILS_TEMPLATE_URL, {
        [DEFAULT_KEY_NAME_SALES_QUOTATION_ID_API_REF]: !isEmpty(paramsRouter)
          ? paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_SALES_QUOTATION_ID]
          : '',
      });
    },
  },
  [DEFAULT_TRANSACTION_SALES_SALES_ORDER_TYPE]: {
    parentState: NAME_STORE.SALES,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_UPDATE_SALES_ORDER_PENDING,
    statusRequestFailed: STATUS_REQUEST_UPDATE_SALES_ORDER_FAILED,
    generateActionBody: generalizeDataUpdateSalesOrder,
    action: updateSalesOrder,
    generateLinkDetails: (paramsRouter) => {
      return strHelp.templateString(REACT_APP_SALES_SALES_ORDER_DETAILS_TEMPLATE_URL, {
        [DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF]: !isEmpty(paramsRouter)
          ? paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_SALES_ORDER_ID]
          : '',
      });
    },
  },
  [DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE]: {
    parentState: NAME_STORE.SALES,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_UPDATE_SALES_DELIVERY_PENDING,
    statusRequestFailed: STATUS_REQUEST_UPDATE_SALES_DELIVERY_FAILED,
    generateActionBody: generalizeDataUpdateSalesDelivery,
    action: updateSalesDelivery,
    generateLinkDetails: (paramsRouter) => {
      return strHelp.templateString(REACT_APP_SALES_SALES_DELIVERY_DETAILS_TEMPLATE_URL, {
        [DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF]: !isEmpty(paramsRouter)
          ? paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_SALES_DELIVERY_ID]
          : '',
      });
    },
  },
  [DEFAULT_TRANSACTION_SALES_SALES_INVOICE_TYPE]: {
    parentState: NAME_STORE.SALES,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_UPDATE_SALES_INVOICE_PENDING,
    statusRequestFailed: STATUS_REQUEST_UPDATE_SALES_INVOICE_FAILED,
    generateActionBody: generalizeDataUpdateSalesInvoice,
    action: updateSalesInvoice,
    generateLinkDetails: (paramsRouter) => {
      return strHelp.templateString(REACT_APP_SALES_SALES_INVOICE_DETAILS_TEMPLATE_URL, {
        [DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF]: !isEmpty(paramsRouter)
          ? paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_SALES_INVOICE_ID]
          : '',
      });
    },
  },
  [DEFAULT_TRANSACTION_SALES_SALES_PAYMENT_TYPE]: {
    parentState: NAME_STORE.SALES,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_UPDATE_SALES_PAYMENT_PENDING,
    statusRequestFailed: STATUS_REQUEST_UPDATE_SALES_PAYMENT_FAILED,
    generateActionBody: generalizeDataUpdateSalesPayment,
    action: updateSalesPayment,
    generateLinkDetails: (paramsRouter) => {
      return strHelp.templateString(REACT_APP_SALES_SALES_PAYMENT_DETAILS_TEMPLATE_URL, {
        [DEFAULT_KEY_NAME_SALES_PAYMENT_ID_API_REF]: !isEmpty(paramsRouter)
          ? paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_SALES_PAYMENT_ID]
          : '',
      });
    },
  },

  [DEFAULT_TRANSACTION_EXPENSE_TYPE]: {
    parentState: NAME_STORE.EXPENSE,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: STATUS_REQUEST_UPDATE_EXPENSE_PENDING,
    statusRequestFailed: STATUS_REQUEST_UPDATE_EXPENSE_FAILED,
    generateActionBody: generalizeDataUpdateExpense,
    action: updateExpense,
    generateLinkDetails: (paramsRouter) => {
      return strHelp.templateString(REACT_APP_EXPENSE_DETAILS_TEMPLATE_URL, {
        [DEFAULT_KEY_NAME_EXPENSE_ID_API_REF]: !isEmpty(paramsRouter)
          ? paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_EXPENSE_ID]
          : '',
      });
    },
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_RETURN_TYPE]: {
    parentState: ROOT_STORE_NAME_PURCHASE,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: REQUEST_STATUS_UPDATE_PURCHASE_RETURN_PENDING,
    statusRequestFailed: REQUEST_STATUS_UPDATE_PURCHASE_RETURN_FAILED,
    generateActionBody: generalizeDataUpdatePurchaseReturn,
    action: updatePurchaseReturn,
    generateLinkDetails: (paramsRouter) => {
      return strHelp.templateString(REACT_APP_PURCHASE_RETURN_DETAILS_TEMPLATE_URL, {
        [DEFAULT_KEY_NAME_PURCHASE_RETURN_ID_API_REF]: !isEmpty(paramsRouter)
          ? paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_RETURN_ID]
          : '',
      });
    },
  },
  [DEFAULT_TRANSACTION_PURCHASE_RECEIVABLE_PAYMENT_TYPE]: {
    parentState: ROOT_STORE_NAME_PURCHASE,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: REQUEST_STATUS_UPDATE_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
    statusRequestFailed: REQUEST_STATUS_UPDATE_PURCHASE_RECEIVABLE_PAYMENT_FAILED,
    generateActionBody: generalizeDataUpdatePurchaseReceivablePayment,
    action: updatePurchaseReceivablePayment,
    generateLinkDetails: (paramsRouter) => {
      return strHelp.templateString(REACT_APP_PURCHASE_RECEIVABLE_PAYMENT_DETAILS_TEMPLATE_URL, {
        [DEFAULT_KEY_NAME_RECEIVABLE_PAYMENT_ID_API_REF]: !isEmpty(paramsRouter)
          ? paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_RECEIVABLE_PAYMENT_ID]
          : '',
      });
    },
  },
  [DEFAULT_TRANSACTION_SALES_SALES_RETURN_TYPE]: {
    parentState: ROOT_STORE_NAME_SALES,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: REQUEST_STATUS_UPDATE_SALES_RETURN_PENDING,
    statusRequestFailed: REQUEST_STATUS_UPDATE_SALES_RETURN_FAILED,
    generateActionBody: generalizeDataUpdateSalesReturn,
    action: updateSalesReturn,
    generateLinkDetails: (paramsRouter) => {
      return strHelp.templateString(REACT_APP_SALES_RETURN_DETAILS_TEMPLATE_URL, {
        [DEFAULT_KEY_NAME_SALES_RETURN_ID_API_REF]: !isEmpty(paramsRouter)
          ? paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_SALES_RETURN_ID]
          : '',
      });
    },
  },
  [DEFAULT_TRANSACTION_SALES_PAYABLE_PAYMENT_TYPE]: {
    parentState: ROOT_STORE_NAME_SALES,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    statusRequestPending: REQUEST_STATUS_UPDATE_SALES_PAYABLE_PAYMENT_PENDING,
    statusRequestFailed: REQUEST_STATUS_UPDATE_SALES_PAYABLE_PAYMENT_FAILED,
    generateActionBody: generalizeDataUpdateSalesPayablePayment,
    action: updateSalesPayablePayment,
    generateLinkDetails: (paramsRouter) => {
      return strHelp.templateString(REACT_APP_SALES_PAYABLE_PAYMENT_DETAILS_TEMPLATE_URL, {
        [DEFAULT_KEY_NAME_PAYABLE_PAYMENT_ID_API_REF]: !isEmpty(paramsRouter)
          ? paramsRouter[DEFAULT_KEY_NAME_PARAMS_URL_PAYABLE_PAYMENT_ID]
          : '',
      });
    },
  },
};

const relationalActionTransactionProAddUpdateConfiguration = {
  [DEFAULT_ACTION_MENU_ADD]: defaultActionTransactionProAddConfiguration,
  [DEFAULT_ACTION_MENU_UPDATE]: defaultActionTransactionProUpdateConfiguration,
};

export const getDefaultActionTransactionProAddUpdateConfiguration = ({
  actionMenu = DEFAULT_ACTION_MENU_ADD,
  subTransactionType = DEFAULT_TRANSACTION_SALES_SALES_QUOTATION_TYPE,
}) => {
  const keysRelationalActionTransaction = Object.keys(
    relationalActionTransactionProAddUpdateConfiguration,
  );

  if (!keysRelationalActionTransaction.includes(actionMenu)) {
    actionMenu = keysRelationalActionTransaction[0];
  }

  const keysDefaultActionAddConfiguration = Object.keys(
    defaultActionTransactionProAddConfiguration,
  );
  const keysDefaultActionUpdateConfiguration = Object.keys(
    defaultActionTransactionProUpdateConfiguration,
  );

  if (
    !keysDefaultActionAddConfiguration.includes(subTransactionType) ||
    !keysDefaultActionUpdateConfiguration.includes(subTransactionType)
  ) {
    subTransactionType = keysDefaultActionAddConfiguration[0];
  }

  return relationalActionTransactionProAddUpdateConfiguration[actionMenu][subTransactionType];
};

export const defaultTransactionProDeleteConfig = {
  [DEFAULT_TRANSACTION_SALES_SALES_QUOTATION_TYPE]: {
    parentStateName: NAME_STORE.SALES,
    childrenDetailsStateName: SLICE_NAME_SALES_QUOTATION_DETAILS,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    requestStatusLoading: STATUS_REQUEST_DELETE_SALES_QUOTATION_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DELETE_SALES_QUOTATION_SUCCESS,
    action: deleteSalesQuotation,
    getDeleteParams: ({ transactionDetails }) => {
      if (isEmpty(transactionDetails)) return '';

      return {
        ...transactionDetails,
        [DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF]:
          transactionDetails[DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF] || '',
      };
    },
    navigationAfterSuccess: REACT_APP_SALES_QUOTATION_URL,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_ORDER_TYPE]: {
    parentStateName: NAME_STORE.SALES,
    childrenDetailsStateName: SLICE_NAME_SALES_ORDER_DETAILS,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    requestStatusLoading: STATUS_REQUEST_DELETE_SALES_ORDER_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DELETE_SALES_ORDER_SUCCESS,
    action: deleteSalesOrder,
    getDeleteParams: ({ transactionDetails }) => {
      if (isEmpty(transactionDetails)) return '';

      return {
        ...transactionDetails,
        [DEFAULT_KEY_NAME_SALES_ORDER_NO_API_REF]:
          transactionDetails[DEFAULT_KEY_NAME_SALES_ORDER_NO_API_REF] || '',
      };
    },
    navigationAfterSuccess: REACT_APP_SALES_SALES_ORDER_URL,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE]: {
    parentStateName: NAME_STORE.SALES,
    childrenDetailsStateName: SLICE_NAME_SALES_DELIVERY_DETAILS,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    requestStatusLoading: STATUS_REQUEST_DELETE_SALES_DELIVERY_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DELETE_SALES_DELIVERY_SUCCESS,
    action: deleteSalesDelivery,
    getDeleteParams: ({ transactionDetails }) => {
      if (isEmpty(transactionDetails)) return '';

      return {
        ...transactionDetails,
        [DEFAULT_KEY_NAME_SALES_DELIVERY_NO_API_REF]:
          transactionDetails[DEFAULT_KEY_NAME_SALES_DELIVERY_NO_API_REF] || '',
      };
    },
    navigationAfterSuccess: REACT_APP_SALES_SALES_DELIVERY_URL,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_INVOICE_TYPE]: {
    parentStateName: NAME_STORE.SALES,
    childrenDetailsStateName: SLICE_NAME_SALES_INVOICE_DETAILS,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    requestStatusLoading: STATUS_REQUEST_DELETE_SALES_INVOICE_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DELETE_SALES_INVOICE_SUCCESS,
    action: deleteSalesInvoice,
    getDeleteParams: ({ transactionDetails }) => {
      if (isEmpty(transactionDetails)) return '';

      return {
        ...transactionDetails,
        [DEFAULT_KEY_NAME_SALES_INVOICE_NO_API_REF]:
          transactionDetails[DEFAULT_KEY_NAME_SALES_INVOICE_NO_API_REF] || '',
      };
    },
    navigationAfterSuccess: REACT_APP_SALES_INVOICE_URL,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_PAYMENT_TYPE]: {
    parentStateName: NAME_STORE.SALES,
    childrenDetailsStateName: SLICE_NAME_SALES_PAYMENT_DETAIL,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    requestStatusLoading: STATUS_REQUEST_DELETE_SALES_PAYMENT_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DELETE_SALES_PAYMENT_SUCCESS,
    action: deleteSalesPayment,
    getDeleteParams: ({ transactionDetails }) => {
      if (isEmpty(transactionDetails)) return '';

      return {
        ...transactionDetails,
        [DEFAULT_KEY_NAME_SALES_PAYMENT_NO_API_REF]:
          transactionDetails[DEFAULT_KEY_NAME_SALES_PAYMENT_NO_API_REF] || '',
      };
    },
    navigationAfterSuccess: REACT_APP_SALES_PAYMENT_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_TYPE]: {
    parentStateName: NAME_STORE.PURCHASE,
    childrenDetailsStateName: SLICE_NAME_QUOTATION_REQUEST_DETAIL,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    requestStatusLoading: STATUS_REQUEST_DELETE_QUOTATION_REQUEST_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DELETE_QUOTATION_REQUEST_SUCCESS,
    action: deleteQuotationRequest,
    getDeleteParams: ({ transactionDetails }) => {
      if (isEmpty(transactionDetails)) return '';

      return {
        ...transactionDetails,
        [DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF]:
          transactionDetails[DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF] || '',
      };
    },
    navigationAfterSuccess: REACT_APP_PURCHASE_QUOTATION_REQUEST_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_ORDER_TYPE]: {
    parentStateName: NAME_STORE.PURCHASE,
    childrenDetailsStateName: SLICE_NAME_DETAIL_PURCHASE_ORDER,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    requestStatusLoading: STATUS_REQUEST_DELETE_PURCHASE_ORDER_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DELETE_PURCHASE_ORDER_SUCCESS,
    action: deletePurchaseOrder,
    getDeleteParams: ({ transactionDetails }) => {
      if (isEmpty(transactionDetails)) return '';

      return {
        ...transactionDetails,
        [DEFAULT_KEY_NAME_PURCHASE_ORDER_NO_API_REF]:
          transactionDetails[DEFAULT_KEY_NAME_PURCHASE_ORDER_NO_API_REF] || '',
      };
    },
    navigationAfterSuccess: REACT_APP_PURCHASE_PURCHASE_ORDER_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE]: {
    parentStateName: NAME_STORE.PURCHASE,
    childrenDetailsStateName: SLICE_NAME_PURCHASE_DELIVERY_DETAIL,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    requestStatusLoading: STATUS_REQUEST_DELETE_PURCHASE_DELIVERY_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DELETE_PURCHASE_DELIVERY_SUCCESS,
    action: deletePurchaseDelivery,
    getDeleteParams: ({ transactionDetails }) => {
      if (isEmpty(transactionDetails)) return '';

      return {
        ...transactionDetails,
        [DEFAULT_KEY_NAME_PURCHASE_DELIVERY_NO_API_REF]:
          transactionDetails[DEFAULT_KEY_NAME_PURCHASE_DELIVERY_NO_API_REF] || '',
      };
    },
    navigationAfterSuccess: REACT_APP_PURCHASE_DELIVERY_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_INVOICE_TYPE]: {
    parentStateName: NAME_STORE.PURCHASE,
    childrenDetailsStateName: SLICE_NAME_DETAIL_PURCHASE_INVOICE,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    requestStatusLoading: STATUS_REQUEST_DELETE_PURCHASE_INVOICE_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DELETE_PURCHASE_INVOICE_SUCCESS,
    action: deletePurchaseInvoice,
    getDeleteParams: ({ transactionDetails }) => {
      if (isEmpty(transactionDetails)) return '';

      return {
        ...transactionDetails,
        [DEFAULT_KEY_NAME_PURCHASE_INVOICE_NO_API_REF]:
          transactionDetails[DEFAULT_KEY_NAME_PURCHASE_INVOICE_NO_API_REF] || '',
      };
    },
    navigationAfterSuccess: REACT_APP_PURCHASE_PURCHASE_INVOICE_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_PAYMENT_TYPE]: {
    parentStateName: NAME_STORE.PURCHASE,
    childrenDetailsStateName: SLICE_NAME_PURCHASE_PAYMENT_DETAIL,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    requestStatusLoading: STATUS_REQUEST_DELETE_PURCHASE_PAYMENT_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DELETE_PURCHASE_PAYMENT_SUCCESS,
    action: deletePurchasePayment,
    getDeleteParams: ({ transactionDetails }) => {
      if (isEmpty(transactionDetails)) return '';

      return {
        ...transactionDetails,
        [DEFAULT_KEY_NAME_PURCHASE_PAYMENT_NO_API_REF]:
          transactionDetails[DEFAULT_KEY_NAME_PURCHASE_PAYMENT_NO_API_REF] || '',
      };
    },
    navigationAfterSuccess: REACT_APP_PURCHASE_PURCHASE_PAYMENT_URL,
  },
  [DEFAULT_TRANSACTION_EXPENSE_TYPE]: {
    parentStateName: NAME_STORE.EXPENSE,
    childrenDetailsStateName: SLICE_NAME_EXPENSE_DETAIL,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    requestStatusLoading: STATUS_REQUEST_DELETE_EXPENSE_PENDING,
    requestStatusSuccess: STATUS_REQUEST_DELETE_EXPENSE_SUCCESS,
    action: deleteExpense,
    getDeleteParams: ({ transactionDetails }) => {
      if (isEmpty(transactionDetails)) return '';

      return {
        ...transactionDetails,
        [DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF]:
          transactionDetails[DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF] || '',
      };
    },
    navigationAfterSuccess: REACT_APP_EXPENSE_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_RETURN_TYPE]: {
    parentStateName: ROOT_STORE_NAME_PURCHASE,
    childrenDetailsStateName: SLICE_NAME_PURCHASE_RETURN_DETAILS,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    requestStatusLoading: REQUEST_STATUS_DELETE_PURCHASE_RETURN_PENDING,
    requestStatusSuccess: REQUEST_STATUS_DELETE_PURCHASE_RETURN_SUCCESS,
    action: deletePurchaseReturn,
    getDeleteParams: ({ transactionDetails }) => {
      if (isEmpty(transactionDetails)) return '';

      return {
        ...transactionDetails,
        [DEFAULT_KEY_NAME_PURCHASE_RETURN_ID_API_REF]:
          transactionDetails[DEFAULT_KEY_NAME_PURCHASE_RETURN_ID_API_REF] || '',
      };
    },
    navigationAfterSuccess: REACT_APP_PURCHASE_RETURN_URL,
  },
  [DEFAULT_TRANSACTION_PURCHASE_RECEIVABLE_PAYMENT_TYPE]: {
    parentStateName: ROOT_STORE_NAME_PURCHASE,
    childrenDetailsStateName: SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_DETAILS,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    requestStatusLoading: REQUEST_STATUS_DELETE_PURCHASE_RECEIVABLE_PAYMENT_LOADING,
    requestStatusSuccess: REQUEST_STATUS_DELETE_PURCHASE_RECEIVABLE_PAYMENT_SUCCESS,
    action: deletePurchaseReceivablePayment,
    getDeleteParams: ({ transactionDetails }) => {
      if (isEmpty(transactionDetails)) return '';

      return {
        ...transactionDetails,
        [DEFAULT_KEY_NAME_RECEIVABLE_PAYMENT_ID_API_REF]:
          transactionDetails[DEFAULT_KEY_NAME_RECEIVABLE_PAYMENT_ID_API_REF] || '',
      };
    },
    navigationAfterSuccess: REACT_APP_PURCHASE_RECEIVABLE_PAYMENT_URL,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_RETURN_TYPE]: {
    parentStateName: ROOT_STORE_NAME_SALES,
    childrenDetailsStateName: SLICE_NAME_SALES_RETURN_DETAILS,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    requestStatusLoading: REQUEST_STATUS_DELETE_SALES_RETURN_PENDING,
    requestStatusSuccess: REQUEST_STATUS_DELETE_SALES_RETURN_SUCCESS,
    action: deleteSalesReturn,
    getDeleteParams: ({ transactionDetails }) => {
      if (isEmpty(transactionDetails)) return '';

      return {
        ...transactionDetails,
        [DEFAULT_KEY_NAME_SALES_RETURN_ID_API_REF]:
          transactionDetails[DEFAULT_KEY_NAME_SALES_RETURN_ID_API_REF] || '',
      };
    },
    navigationAfterSuccess: REACT_APP_SALES_RETURN_URL,
  },
  [DEFAULT_TRANSACTION_SALES_PAYABLE_PAYMENT_TYPE]: {
    parentStateName: ROOT_STORE_NAME_SALES,
    childrenDetailsStateName: SLICE_NAME_SALES_PAYABLE_PAYMENT_DETAILS,
    keyNameRequestStatus: DEFAULT_KEY_NAME_STATUS_REQUEST,
    requestStatusLoading: REQUEST_STATUS_DELETE_SALES_PAYABLE_PAYMENT_PENDING,
    requestStatusSuccess: REQUEST_STATUS_DELETE_SALES_PAYABLE_PAYMENT_SUCCESS,
    action: deleteSalesPayablePayment,
    getDeleteParams: ({ transactionDetails }) => {
      if (isEmpty(transactionDetails)) return '';

      return {
        ...transactionDetails,
        [DEFAULT_KEY_NAME_PAYABLE_PAYMENT_ID_API_REF]:
          transactionDetails[DEFAULT_KEY_NAME_PAYABLE_PAYMENT_ID_API_REF] || '',
      };
    },
    navigationAfterSuccess: REACT_APP_SALES_PAYABLE_PAYMENT_URL,
  },
};

export const getDefaultTransactionProDeleteConfiguration = (
  subTransactionType = DEFAULT_TRANSACTION_SALES_SALES_QUOTATION_TYPE,
) => {
  const allAvailableSubTransaction = Object.keys(defaultTransactionProDeleteConfig);

  if (!allAvailableSubTransaction.includes(subTransactionType) || !subTransactionType)
    subTransactionType = allAvailableSubTransaction[0];

  return defaultTransactionProDeleteConfig[subTransactionType];
};

export const defaultTransactionProPrintConfig = {
  [DEFAULT_TRANSACTION_PURCHASE_QUOTATION_REQUEST_TYPE]: {
    parentStateName: ROOT_STORE_NAME_PURCHASE,
    childrenStateName: SLICE_NAME_QUOTATION_REQUEST_DETAIL,
    keyNameChildrenData: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    printTitle: 'header.quotation-request',
    primarySubtitleKeyName: 'company_name',
    secondarySubtitleKeyName: 'company_address',
    generalizeData: ({
      childrenState = {},
      companyUserData = {},
      keyNameChildrenData = DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    }) => {
      if (isEmpty(childrenState) || isEmpty(companyUserData)) return;

      const { company_name, address: company_address, business_type } = companyUserData;

      const { discount_nominal, discount_percentage, amount } = childrenState;

      let productPurchaseQuotationRequest =
        childrenState[DEFAULT_KEY_NAME_QUOTATION_REQUEST_PRODUCT_REF_API];
      if (
        !isEmpty(productPurchaseQuotationRequest) &&
        Array.isArray(productPurchaseQuotationRequest)
      ) {
        productPurchaseQuotationRequest = productPurchaseQuotationRequest.map(
          (singleProductPurchaseQuotationRequest) => {
            const { price, quantity } = singleProductPurchaseQuotationRequest;

            let amount = Number(price) * Number(quantity);

            if (Number.isNaN(amount)) amount = 0;

            return {
              ...singleProductPurchaseQuotationRequest,
              amount,
            };
          },
        );
      }

      childrenState = omit(childrenState, [DEFAULT_KEY_NAME_QUOTATION_REQUEST_PRODUCT_REF_API]);

      return {
        company_name: company_name ? `${business_type}  ${company_name.toUpperCase()}` : '',
        company_address: company_address || '',
        ...childrenState,
        discount_percentage: discount_percentage ? `${Number(discount_percentage)}%` : '0%',
        [keyNameChildrenData]: productPurchaseQuotationRequest,
        amount: Number(amount) - Number(discount_nominal),
      };
    },
    isRightTitleShow: true,
    columnsTableRightTitle: columnsTableKeyValuePrintPurchaseQuotationRequest,
    columnsTableChildrenTransaction: (t) => columnsTableProductPurchaseQuotationRequestDetails(t),
    columnsTableFooterChildrenTransaction: (t) =>
      columnsTableFooterProductPurchaseQuotationRequestDetails(t),
    columnsTableFooter: (t) => columnsTableFooterPurchaseQuotationRequestDetails(t),
    isSignatureShow: true,
    additionalRowHeaderFooter: 14,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_ORDER_TYPE]: {
    parentStateName: ROOT_STORE_NAME_PURCHASE,
    childrenStateName: SLICE_NAME_DETAIL_PURCHASE_ORDER,
    keyNameChildrenData: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    printTitle: 'header.purchase-order',
    primarySubtitleKeyName: 'company_name',
    secondarySubtitleKeyName: 'company_address',
    generalizeData: ({
      childrenState = {},
      companyUserData = {},
      keyNameChildrenData = DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    }) => {
      if (isEmpty(childrenState) || isEmpty(companyUserData)) return;

      const { company_name, address: company_address, business_type } = companyUserData;

      const { discount_nominal, discount_percentage, amount } = childrenState;

      let productPurchaseOrderData = childrenState[DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_REF_API];
      if (!isEmpty(productPurchaseOrderData) && Array.isArray(productPurchaseOrderData)) {
        productPurchaseOrderData = productPurchaseOrderData.map(
          (singleProductPurchaseOrderData) => {
            const { price, quantity } = singleProductPurchaseOrderData;

            let amount = Number(price) * Number(quantity);

            if (Number.isNaN(amount)) amount = 0;

            return {
              ...singleProductPurchaseOrderData,
              amount,
            };
          },
        );
      }

      childrenState = omit(childrenState, [DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_REF_API]);

      return {
        company_name: company_name ? `${business_type}  ${company_name.toUpperCase()}` : '',
        company_address: company_address || '',
        ...childrenState,
        discount_percentage: discount_percentage ? `${Number(discount_percentage)}%` : '0%',
        [keyNameChildrenData]: productPurchaseOrderData,
        amount: Number(amount) - Number(discount_nominal),
      };
    },
    isRightTitleShow: true,
    columnsTableRightTitle: columnsTableKeyValuePrintPurchaseOrderDetails,
    columnsTableChildrenTransaction: (t) => columnsTableProductPurchaseOrderDetails(t),
    columnsTableFooterChildrenTransaction: (t) => columnsTableFooterProductPurchaseOrderDetails(t),
    columnsTableFooter: (t) => columnsTableFooterPurchaseOrderDetails(t),
    isSignatureShow: true,
    additionalRowHeaderFooter: 14,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_DELIVERY_TYPE]: {
    parentStateName: ROOT_STORE_NAME_PURCHASE,
    childrenStateName: SLICE_NAME_PURCHASE_DELIVERY_DETAIL,
    keyNameChildrenData: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    printTitle: 'header.purchase-delivery',
    primarySubtitleKeyName: 'company_name',
    secondarySubtitleKeyName: 'company_address',
    generalizeData: ({
      childrenState = {},
      companyUserData = {},
      keyNameChildrenData = DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    }) => {
      if (isEmpty(childrenState) || isEmpty(companyUserData)) return;

      const { company_name, address: company_address, business_type } = companyUserData;

      let productPurchaseDeliveryData = childrenState[DEFAULT_KEY_NAME_PRODUCT_API];
      if (!isEmpty(productPurchaseDeliveryData) && Array.isArray(productPurchaseDeliveryData)) {
        productPurchaseDeliveryData = productPurchaseDeliveryData.map(
          (singleProductPurchaseDeliveryData) => {
            const { price, quantity } = singleProductPurchaseDeliveryData;

            let amount = Number(price) * Number(quantity);

            if (Number.isNaN(amount)) amount = 0;

            return {
              ...singleProductPurchaseDeliveryData,
              amount,
            };
          },
        );
      }

      childrenState = omit(childrenState, [DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_REF_API]);

      return {
        company_name: company_name ? `${business_type}  ${company_name.toUpperCase()}` : '',
        company_address: company_address || '',
        ...childrenState,
        [keyNameChildrenData]: productPurchaseDeliveryData,
      };
    },
    isRightTitleShow: true,
    columnsTableRightTitle: columnsTableKeyValuePrintPurchaseDeliveryDetails,
    columnsTableChildrenTransaction: (t) => columnsTableProductPurchaseDeliveryDetails(t),
    columnsTableFooterChildrenTransaction: (t) =>
      columnsTableFooterProductPurchaseDeliveryDetails(t),
    columnsTableFooter: (t) => columnsTableFooterPurchaseDeliveryDetails(t),
    isSignatureShow: true,
    additionalRowHeaderFooter: 14,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_PAYMENT_TYPE]: {
    parentStateName: ROOT_STORE_NAME_PURCHASE,
    childrenStateName: NAME_STATE_DETAIL_PURCHASE_PAYMENT,
    keyNameChildrenData: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    printTitle: 'header.purchase-payment',
    primarySubtitleKeyName: 'company_name',
    secondarySubtitleKeyName: 'company_address',
    generalizeData: ({
      childrenState = {},
      companyUserData = {},
      keyNameChildrenData = DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    }) => {
      if (isEmpty(childrenState) || isEmpty(companyUserData)) {
        return {
          ...companyUserData,
          ...childrenState,
        };
      }

      const { company_name, address: company_address, business_type } = companyUserData;

      return {
        company_name: company_name ? `${business_type}  ${company_name.toUpperCase()}` : '',
        company_address: company_address || '',
        ...childrenState,
        [keyNameChildrenData]: childrenState[DEFAULT_KEY_NAME_PURCHASE_INVOICE_PAYMENT_API_REF],
      };
    },
    isRightTitleShow: true,
    columnsTableRightTitle: columnsTableKeyValuePrintPurchasePaymentDetails,
    columnsTableChildrenTransaction: (t) => columnsTableInvoicePurchasePaymentDetails(t),
    columnsTableFooterChildrenTransaction: (t) =>
      columnsTableFooterInvoicePurchasePaymentDetails(t),
    columnsTableFooter: (t) => columnsTableFooterPurchasePaymentDetails(t),
    isSignatureShow: true,
    additionalRowHeaderFooter: 14,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_INVOICE_TYPE]: {
    parentStateName: NAME_STORE.PURCHASE,
    childrenStateName: SLICE_NAME_DETAIL_PURCHASE_INVOICE,
    keyNameChildrenData: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    printTitle: 'header.purchase-invoice',
    primarySubtitleKeyName: 'company_name',
    secondarySubtitleKeyName: 'company_address',
    generalizeData: ({
      childrenState = {},
      companyUserData = {},
      keyNameChildrenData = DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    }) => {
      if (isEmpty(childrenState) || isEmpty(companyUserData)) return;

      const { company_name, address: company_address, business_type } = companyUserData;

      const { amount, amount_available } = childrenState;

      let paid = Number(amount) - Number(amount_available);
      if (Number.isNaN(paid)) paid = 0;

      const total = calculateTotalFromDetails({
        details: childrenState,
        totalKeyname: 'amount',
      });

      let amount_return = Number(amount) < Number(paid) ? Number(total) - Number(paid) : 0;

      if (Number.isNaN(amount_return)) amount_return = 0;

      let productSalesInvoice = childrenState[DEFAULT_KEY_NAME_PRODUCT_API];
      if (!isEmpty(productSalesInvoice) && Array.isArray(productSalesInvoice)) {
        productSalesInvoice = productSalesInvoice.map((singleProductSalesInvoice) => {
          const { price, quantity } = singleProductSalesInvoice;

          let amount = Number(price) * Number(quantity);

          if (Number.isNaN(amount)) amount = 0;

          return {
            ...singleProductSalesInvoice,
            amount,
          };
        });
      }

      childrenState = omit(childrenState, [DEFAULT_KEY_NAME_PRODUCT_API]);

      return {
        company_name: company_name ? `${business_type}  ${company_name.toUpperCase()}` : '',
        company_address: company_address || '',
        ...childrenState,
        amount_return,
        paid,
        amount: total,
        [keyNameChildrenData]: productSalesInvoice,
        ...parseAdditionalCostData({
          details: childrenState,
        }),
      };
    },
    isRightTitleShow: true,
    columnsTableRightTitle: columnsTableKeyValuePrintPurchaseInvoice,
    columnsTableChildrenTransaction: (t) => columnsTableProductPurchaseInvoiceDetails(t),
    columnsTableFooterChildrenTransaction: (t) =>
      columnsTableFooterProductPurchaseInvoiceDetails(t),
    columnsTableFooter: (t) => columnsTableFooterPurchaseInvoiceDetails(t),
    isSignatureShow: true,
    additionalRowHeaderFooter: 14,
    usagePrintThermal: true,
    foreignKeyPrintThermalTransactionBasic: transactionInvoiceTypePurchaseInvoice,
  },
  // few duplicated from purchase quotation request props
  [DEFAULT_TRANSACTION_PURCHASE_PURCHASE_RETURN_TYPE]: {
    parentStateName: ROOT_STORE_NAME_PURCHASE,
    childrenStateName: SLICE_NAME_PURCHASE_RETURN_DETAILS,
    keyNameChildrenData: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    printTitle: 'header.purchase-return',
    primarySubtitleKeyName: 'company_name',
    secondarySubtitleKeyName: 'company_address',
    generalizeData: ({
      childrenState = {},
      companyUserData = {},
      keyNameChildrenData = DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    }) => {
      if (isEmpty(childrenState) || isEmpty(companyUserData)) return;

      const { company_name, address: company_address, business_type } = companyUserData;

      const { discount_nominal, discount_percentage, amount } = childrenState;

      let productPurchaseReturn = childrenState[DEFAULT_KEY_NAME_PURCHASE_RETURN_PRODUCT_API_REF];
      if (!isEmpty(productPurchaseReturn) && Array.isArray(productPurchaseReturn)) {
        productPurchaseReturn = productPurchaseReturn.map((singleProductPurchaseReturn) => {
          const { price, quantity } = singleProductPurchaseReturn;

          let amount = Number(price) * Number(quantity);

          if (Number.isNaN(amount)) amount = 0;

          return {
            ...singleProductPurchaseReturn,
            amount,
          };
        });
      }

      childrenState = omit(childrenState, [DEFAULT_KEY_NAME_PURCHASE_RETURN_PRODUCT_API_REF]);

      return {
        company_name: company_name ? `${business_type}  ${company_name.toUpperCase()}` : '',
        company_address: company_address || '',
        ...childrenState,
        discount_percentage: discount_percentage ? `${Number(discount_percentage)}%` : '0%',
        [keyNameChildrenData]: productPurchaseReturn,
        amount: Number(amount) - Number(discount_nominal),
      };
    },
    isRightTitleShow: true,
    columnsTableRightTitle: columnsTableKeyValuePrintPurchaseQuotationRequest,
    columnsTableChildrenTransaction: (t) => columnsTableProductPurchaseQuotationRequestDetails(t),
    columnsTableFooterChildrenTransaction: (t) =>
      columnsTableFooterProductPurchaseQuotationRequestDetails(t),
    columnsTableFooter: (t) => columnsTableFooterPurchaseQuotationRequestDetails(t),
    isSignatureShow: true,
    additionalRowHeaderFooter: 14,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_QUOTATION_TYPE]: {
    parentStateName: ROOT_STORE_NAME_SALES,
    childrenStateName: SLICE_NAME_SALES_QUOTATION_DETAIL,
    keyNameChildrenData: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    printTitle: 'header.sales-quotation',
    primarySubtitleKeyName: 'company_name',
    secondarySubtitleKeyName: 'company_address',
    generalizeData: ({
      childrenState = {},
      companyUserData = {},
      keyNameChildrenData = DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    }) => {
      if (isEmpty(childrenState) || isEmpty(companyUserData)) return;

      const { company_name, address: company_address, business_type } = companyUserData;

      const { discount_nominal, discount_percentage, amount } = childrenState;

      let productSalesQuotation = childrenState[DEFAULT_KEY_NAME_PRODUCT_SALES_QUOTATION_DETAILS];
      if (!isEmpty(productSalesQuotation) && Array.isArray(productSalesQuotation)) {
        productSalesQuotation = productSalesQuotation.map((singleProductSalesQuotation) => {
          const { price, quantity } = singleProductSalesQuotation;

          let amount = Number(price) * Number(quantity);

          if (Number.isNaN(amount)) amount = 0;

          return {
            ...singleProductSalesQuotation,
            amount,
          };
        });
      }

      childrenState = omit(childrenState, [DEFAULT_KEY_NAME_QUOTATION_REQUEST_PRODUCT_REF_API]);

      return {
        company_name: company_name ? `${business_type}  ${company_name.toUpperCase()}` : '',
        company_address: company_address || '',
        ...childrenState,
        discount_percentage: discount_percentage ? `${Number(discount_percentage)}%` : '0%',
        [keyNameChildrenData]: productSalesQuotation,
        amount: Number(amount) - Number(discount_nominal),
      };
    },
    isRightTitleShow: true,
    columnsTableRightTitle: columnsTableKeyValuePrintSalesQuotation,
    columnsTableChildrenTransaction: (t) => columnsTableProductSalesQuotationDetails(t),
    columnsTableFooterChildrenTransaction: (t) => columnsTableFooterProductSalesQuotationDetails(t),
    columnsTableFooter: (t) => columnsTableFooterSalesQuotationDetails(t),
    isSignatureShow: true,
    additionalRowHeaderFooter: 14,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_ORDER_TYPE]: {
    parentStateName: ROOT_STORE_NAME_SALES,
    childrenStateName: SLICE_NAME_SALES_ORDER_DETAILS,
    keyNameChildrenData: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    printTitle: 'header.sales-order',
    primarySubtitleKeyName: 'company_name',
    secondarySubtitleKeyName: 'company_address',
    generalizeData: ({
      childrenState = {},
      companyUserData = {},
      keyNameChildrenData = DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    }) => {
      if (isEmpty(childrenState) || isEmpty(companyUserData)) return;

      const { company_name, address: company_address, business_type } = companyUserData;

      const { discount_nominal, discount_percentage, amount } = childrenState;

      let productSalesOrder = childrenState[DEFAULT_KEY_NAME_PRODUCT_SALES_ORDER_DETAILS];
      if (!isEmpty(productSalesOrder) && Array.isArray(productSalesOrder)) {
        productSalesOrder = productSalesOrder.map((singleProductSalesOrder) => {
          const { price, quantity } = singleProductSalesOrder;

          let amount = Number(price) * Number(quantity);

          if (Number.isNaN(amount)) amount = 0;

          return {
            ...singleProductSalesOrder,
            amount,
          };
        });
      }

      childrenState = omit(childrenState, [DEFAULT_KEY_NAME_QUOTATION_REQUEST_PRODUCT_REF_API]);

      return {
        company_name: company_name ? `${business_type}  ${company_name.toUpperCase()}` : '',
        company_address: company_address || '',
        ...childrenState,
        discount_percentage: discount_percentage ? `${Number(discount_percentage)}%` : '0%',
        [keyNameChildrenData]: productSalesOrder,
        amount: Number(amount) - Number(discount_nominal),
      };
    },
    isRightTitleShow: true,
    columnsTableRightTitle: columnsTableKeyValuePrintSalesOrderDetails,
    columnsTableChildrenTransaction: (t) => columnsTableProductSalesOrderDetails(t),
    columnsTableFooterChildrenTransaction: (t) => columnsTableFooterProductSalesOrderDetails(t),
    columnsTableFooter: (t) => columnsTableFooterSalesOrderDetails(t),
    isSignatureShow: true,
    additionalRowHeaderFooter: 14,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_DELIVERY_TYPE]: {
    parentStateName: ROOT_STORE_NAME_SALES,
    childrenStateName: SLICE_NAME_SALES_DELIVERY_DETAILS,
    keyNameChildrenData: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    printTitle: 'header.sales-delivery',
    primarySubtitleKeyName: 'company_name',
    secondarySubtitleKeyName: 'company_address',
    generalizeData: ({
      childrenState = {},
      companyUserData = {},
      keyNameChildrenData = DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    }) => {
      if (isEmpty(childrenState) || isEmpty(companyUserData)) {
        return {
          ...childrenState,
          ...companyUserData,
        };
      }

      const { company_name, address: company_address, business_type } = companyUserData;

      let productSalesDeliveryData = childrenState[DEFAULT_KEY_NAME_PRODUCT_API];
      if (!isEmpty(productSalesDeliveryData) && Array.isArray(productSalesDeliveryData)) {
        productSalesDeliveryData = productSalesDeliveryData.map(
          (singleProductSalesDeliveryData) => {
            const { price, quantity } = singleProductSalesDeliveryData;

            let amount = Number(price) * Number(quantity);

            if (Number.isNaN(amount)) amount = 0;

            return {
              ...singleProductSalesDeliveryData,
              amount,
            };
          },
        );
      }

      return {
        company_name: company_name ? `${business_type}  ${company_name.toUpperCase()}` : '',
        company_address: company_address || '',
        ...childrenState,
        [keyNameChildrenData]: productSalesDeliveryData,
      };
    },
    isRightTitleShow: true,
    columnsTableRightTitle: columnsTableKeyValuePrintSalesDeliveryDetails,
    columnsTableChildrenTransaction: (t) => columnsTableProductSalesDeliveryDetails(t),
    columnsTableFooterChildrenTransaction: (t) => columnsTableFooterProductSalesDeliveryDetails(t),
    columnsTableFooter: (t) => columnsTableFooterSalesDeliveryDetails(t),
    isSignatureShow: true,
    additionalRowHeaderFooter: 14,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_INVOICE_TYPE]: {
    parentStateName: NAME_STORE.SALES,
    childrenStateName: SLICE_NAME_SALES_INVOICE_DETAILS,
    keyNameChildrenData: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    printTitle: 'header.sales-invoice',
    primarySubtitleKeyName: 'company_name',
    secondarySubtitleKeyName: 'company_address',
    generalizeData: ({
      childrenState = {},
      companyUserData = {},
      keyNameChildrenData = DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    }) => {
      if (isEmpty(childrenState) || isEmpty(companyUserData)) return;

      const { company_name, address: company_address, business_type } = companyUserData;

      const { amount, amount_available, amount_return = 0 } = childrenState;

      const total = calculateTotalFromDetails({
        details: childrenState,
        totalKeyname: 'amount',
      });

      let paid = Number(amount) - Number(amount_available);
      if (Number.isNaN(paid)) paid = 0;

      let productSalesInvoice = childrenState[DEFAULT_KEY_NAME_PRODUCT_API];
      if (!isEmpty(productSalesInvoice) && Array.isArray(productSalesInvoice)) {
        productSalesInvoice = productSalesInvoice.map((singleProductSalesInvoice) => {
          const { price, quantity } = singleProductSalesInvoice;

          let amount = Number(price) * Number(quantity);

          if (Number.isNaN(amount)) amount = 0;

          return {
            ...singleProductSalesInvoice,
            amount,
          };
        });
      }

      childrenState = omit(childrenState, [DEFAULT_KEY_NAME_PRODUCT_API]);

      return {
        company_name: company_name ? `${business_type}  ${company_name.toUpperCase()}` : '',
        company_address: company_address || '',
        ...childrenState,
        amount_return,
        amount: total,
        paid,
        [keyNameChildrenData]: productSalesInvoice,
        ...parseAdditionalCostData({
          details: childrenState,
        }),
      };
    },
    isRightTitleShow: true,
    columnsTableRightTitle: columnsTableKeyValuePrintSalesInvoice,
    columnsTableChildrenTransaction: (t) => columnsTableProductSalesInvoiceDetails(t),
    columnsTableFooterChildrenTransaction: (t) => columnsTableFooterProductSalesInvoiceDetails(t),
    columnsTableFooter: (t) => columnsTableFooterSalesInvoiceDetails(t),
    isSignatureShow: true,
    additionalRowHeaderFooter: 20,
    usagePrintThermal: true,
    foreignKeyPrintThermalTransactionBasic: transactionInvoiceTypeSalesInvoice,
  },
  [DEFAULT_TRANSACTION_SALES_SALES_PAYMENT_TYPE]: {
    parentStateName: ROOT_STORE_NAME_SALES,
    childrenStateName: SLICE_NAME_SALES_PAYMENT_DETAIL,
    keyNameChildrenData: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    printTitle: 'header.sales-payment',
    primarySubtitleKeyName: 'company_name',
    secondarySubtitleKeyName: 'company_address',
    generalizeData: ({
      childrenState = {},
      companyUserData = {},
      keyNameChildrenData = DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    }) => {
      if (isEmpty(childrenState) || isEmpty(companyUserData)) {
        return {
          ...companyUserData,
          ...childrenState,
        };
      }

      const { company_name, address: company_address, business_type } = companyUserData;

      return {
        company_name: company_name ? `${business_type}  ${company_name.toUpperCase()}` : '',
        company_address: company_address || '',
        ...childrenState,
        [keyNameChildrenData]: childrenState[DEFAULT_KEY_NAME_SALES_INVOICE_PAYMENT_DETAILS],
      };
    },
    isRightTitleShow: true,
    columnsTableRightTitle: columnsTableKeyValuePrintSalesPaymentDetails,
    columnsTableChildrenTransaction: (t) => columnsTableInvoiceSalesPaymentDetails(t),
    columnsTableFooterChildrenTransaction: (t) => columnsTableFooterInvoiceSalesPaymentDetails(t),
    columnsTableFooter: (t) => columnsTableFooterSalesPaymentDetails(t),
    isSignatureShow: true,
    additionalRowHeaderFooter: 14,
  },
  // a few duplicated from sales quotation props
  [DEFAULT_TRANSACTION_SALES_SALES_RETURN_TYPE]: {
    parentStateName: ROOT_STORE_NAME_SALES,
    childrenStateName: SLICE_NAME_SALES_RETURN_DETAILS,
    keyNameChildrenData: DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    printTitle: 'header.sales-return',
    primarySubtitleKeyName: 'company_name',
    secondarySubtitleKeyName: 'company_address',
    generalizeData: ({
      childrenState = {},
      companyUserData = {},
      keyNameChildrenData = DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA,
    }) => {
      if (isEmpty(childrenState) || isEmpty(companyUserData)) return;

      const { company_name, address: company_address, business_type } = companyUserData;

      const { discount_nominal, discount_percentage, amount } = childrenState;

      let productSalesReturn = childrenState[DEFAULT_KEY_NAME_SALES_RETURN_PRODUCT_API_REF];
      if (!isEmpty(productSalesReturn) && Array.isArray(productSalesReturn)) {
        productSalesReturn = productSalesReturn.map((singleProductSalesReturn) => {
          const { price, quantity } = singleProductSalesReturn;

          let amount = Number(price) * Number(quantity);

          if (Number.isNaN(amount)) amount = 0;

          return {
            ...singleProductSalesReturn,
            amount,
          };
        });
      }

      childrenState = omit(childrenState, [DEFAULT_KEY_NAME_QUOTATION_REQUEST_PRODUCT_REF_API]);

      return {
        company_name: company_name ? `${business_type}  ${company_name.toUpperCase()}` : '',
        company_address: company_address || '',
        ...childrenState,
        discount_percentage: discount_percentage ? `${Number(discount_percentage)}%` : '0%',
        [keyNameChildrenData]: productSalesReturn,
        amount: Number(amount) - Number(discount_nominal),
      };
    },
    isRightTitleShow: true,
    columnsTableRightTitle: columnsTableKeyValuePrintSalesQuotation,
    columnsTableChildrenTransaction: (t) => columnsTableProductSalesQuotationDetails(t),
    columnsTableFooterChildrenTransaction: (t) => columnsTableFooterProductSalesQuotationDetails(t),
    columnsTableFooter: (t) => columnsTableFooterSalesQuotationDetails(t),
    isSignatureShow: true,
    additionalRowHeaderFooter: 14,
  },
};

export const getDefaultTransactionProPrintConfiguration = (
  subTransactionType = DEFAULT_TRANSACTION_SALES_SALES_INVOICE_TYPE,
) => {
  const allKeysDefaultTransactionProPrint = Object.keys(defaultTransactionProPrintConfig);

  if (!subTransactionType || !allKeysDefaultTransactionProPrint.includes(subTransactionType))
    subTransactionType = allKeysDefaultTransactionProPrint[0];

  return defaultTransactionProPrintConfig[subTransactionType];
};

const defaultTransactionProUtils = {
  generateLinkDetails: () => '',
  typographyTranslation: '-',
  isDisabled: true,
};

export const additionalTransactionProUtils = {
  [DEFAULT_TRANSACTION_ASSET_DEPRECIATION_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_ASSET_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      const queryStringParams = objectToQueryString({
        [DEFAULT_KEY_NAME_ASSET_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
        statusPage: DEFAULT_STATUS_PAGE_DETAIL_ASSET_MANAGEMENT,
      });

      return formatHelp.combineBaseUrlWithSearchParams(REACT_APP_DATA_ASSET_URL, queryStringParams);
    },
    typographyTranslation: 'typography.asset-depreciation-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_ASSET_ENTRY_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_ASSET_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      const queryStringParams = objectToQueryString({
        [DEFAULT_KEY_NAME_ASSET_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
        statusPage: DEFAULT_STATUS_PAGE_DETAIL_ASSET_MANAGEMENT,
      });

      return formatHelp.combineBaseUrlWithSearchParams(REACT_APP_DATA_ASSET_URL, queryStringParams);
    },
    typographyTranslation: 'typography.asset-entry-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_ASSET_RESIDUAL_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_ASSET_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      const queryStringParams = objectToQueryString({
        [DEFAULT_KEY_NAME_ASSET_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
        statusPage: DEFAULT_STATUS_PAGE_DETAIL_ASSET_MANAGEMENT,
      });

      return formatHelp.combineBaseUrlWithSearchParams(REACT_APP_DATA_ASSET_URL, queryStringParams);
    },
    typographyTranslation: 'typography.asset-residual-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_BEGINNING_BALANCE_API_REF]: {
    generateLinkDetails: () => {
      return REACT_APP_DATA_ACCOUNT_BEGINNING_BALANCE_URL;
    },
    typographyTranslation: 'typography.beginning-balance-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_CLOSING_BOOK_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_CLOSING_BOOK_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      const queryStringParams = objectToQueryString({
        [DEFAULT_KEY_NAME_CLOSING_BOOK_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
        statusPage: DEFAULT_STATUS_PAGE_DETAIL_CLOSING_BOOK,
      });

      return formatHelp.combineBaseUrlWithSearchParams(
        REACT_APP_SETTING_CLOSING_BOOK_URL,
        queryStringParams,
      );
    },
    typographyTranslation: 'typography.closing-book-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_EXPENSE_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_EXPENSE_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      return strHelp.templateString(REACT_APP_EXPENSE_DETAILS_TEMPLATE_URL, {
        ...accountTransactionData,
        [DEFAULT_KEY_NAME_EXPENSE_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
      });
    },
    typographyTranslation: 'typography.expense-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_JOURNAL_ENTRY_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      const queryStringParams = objectToQueryString({
        [DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
        statusPage: DEFAULT_STATUS_PAGE_DETAIL_LEDGER_JOURNAL_ENTRY,
      });

      return formatHelp.combineBaseUrlWithSearchParams(
        // changes route from ledger/journal-entry cause that route not existed
        REACT_APP_DATA_GENERAL_JOURNAL_URL,
        queryStringParams,
      );
    },
    typographyTranslation: 'typography.journal-entry-with-transaction-number',
    isDisabled: true,
  },
  [DEFAULT_TRANSACTION_PAYMENT_CASH_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      const queryStringParams = objectToQueryString({
        [DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
        statusPage: DEFAULT_STATUS_PAGE_DETAIL_CASH_PAYMENT,
        selectedTab: DEFAULT_TAB_NAME_CASH_BANK_CASH_PAYMENT,
      });

      return formatHelp.combineBaseUrlWithSearchParams(
        REACT_APP_CASH_BANK_BASE_URL,
        queryStringParams,
      );
    },
    typographyTranslation: 'typography.cash-payment-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_PURCHASE_DELIVERY_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      return strHelp.templateString(REACT_APP_PURCHASE_PURCHASE_DELIVERY_DETAILS_TEMPLATE_URL, {
        ...accountTransactionData,
        [DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF]:
          accountTransactionData[hiddenUniqueKeyName],
      });
    },
    typographyTranslation: 'typography.purchase-delivery-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_PURCHASE_HANDOVER_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_PURCHASE_HANDOVER_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      const purchaseDeliveryID =
        accountTransactionData[DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF];
      const purchaseHandoverDeliveryID = accountTransactionData[hiddenUniqueKeyName];

      if (!purchaseDeliveryID) {
        return strHelp.templateString(
          REACT_APP_PURCHASE_PURCHASE_DELIVERY_HANDOVER_DETAILS_TEMPLATE_V2_URL,
          {
            ...accountTransactionData,
            [DEFAULT_KEY_NAME_PURCHASE_HANDOVER_ID_API_REF]: purchaseHandoverDeliveryID,
          },
        );
      }

      return strHelp.templateString(
        REACT_APP_PURCHASE_PURCHASE_DELIVERY_HANDOVER_DETAILS_TEMPLATE_URL,
        {
          ...accountTransactionData,
          [DEFAULT_KEY_NAME_PURCHASE_HANDOVER_ID_API_REF]: purchaseHandoverDeliveryID,
          [DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF]: purchaseDeliveryID,
        },
      );
    },
    typographyTranslation: 'typography.purchase-delivery-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_PURCHASE_INVOICE_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      return strHelp.templateString(REACT_APP_PURCHASE_PURCHASE_INVOICE_DETAILS_TEMPLATE_URL, {
        ...accountTransactionData,
        [DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
      });
    },
    typographyTranslation: 'typography.purchase-invoice-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_PURCHASE_ORDER_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      return strHelp.templateString(REACT_APP_PURCHASE_PURCHASE_ORDER_DETAILS_TEMPLATE_URL, {
        ...accountTransactionData,
        [DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
      });
    },
    typographyTranslation: 'typography.purchase-order-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_PURCHASE_PAYMENT_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_PURCHASE_PAYMENT_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      return strHelp.templateString(REACT_APP_PURCHASE_PURCHASE_PAYMENT_DETAILS_TEMPLATE_URL, {
        ...accountTransactionData,
        [DEFAULT_KEY_NAME_PURCHASE_PAYMENT_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
      });
    },
    typographyTranslation: 'typography.purchase-payment-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_RECEIPT_CASH_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_RECEIPT_CASH_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      const queryStringParams = objectToQueryString({
        [DEFAULT_KEY_NAME_RECEIPT_CASH_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
        statusPage: DEFAULT_STATUS_PAGE_DETAIL_CASH_PAYMENT,
        selectedTab: DEFAULT_TAB_NAME_CASH_BANK_CASH_PAYMENT,
      });

      return formatHelp.combineBaseUrlWithSearchParams(
        REACT_APP_CASH_BANK_BASE_URL,
        queryStringParams,
      );
    },
    typographyTranslation: 'typography.cash-receipt-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_SALES_DELIVERY_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      return strHelp.templateString(REACT_APP_SALES_SALES_DELIVERY_DETAILS_TEMPLATE_URL, {
        ...accountTransactionData,
        [DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
      });
    },
    typographyTranslation: 'typography.sales-delivery-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_SALES_INVOICE_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      return strHelp.templateString(REACT_APP_SALES_SALES_INVOICE_DETAILS_TEMPLATE_URL, {
        ...accountTransactionData,
        [DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
      });
    },
    typographyTranslation: 'typography.sales-invoice-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_SALES_ORDER_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      return strHelp.templateString(REACT_APP_SALES_SALES_ORDER_DETAILS_TEMPLATE_URL, {
        ...accountTransactionData,
        [DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
      });
    },
    typographyTranslation: 'typography.sales-order-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_SALES_PAYMENT_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_SALES_PAYMENT_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      return strHelp.templateString(REACT_APP_SALES_SALES_PAYMENT_DETAILS_TEMPLATE_URL, {
        ...accountTransactionData,
        [DEFAULT_KEY_NAME_SALES_PAYMENT_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
      });
    },
    typographyTranslation: 'typography.sales-payment-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_STOCK_ADJUSTMENT_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_STOCK_ADJUSTMENT_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      return strHelp.templateString(REACT_APP_DATA_STOCK_ADJUSTMENT_DETAILS_TEMPLATE_URL, {
        ...accountTransactionData,
        [DEFAULT_KEY_NAME_STOCK_ADJUSTMENT_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
      });
    },
    typographyTranslation: 'typography.stock-adjustment-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_TRANSFER_CASH_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_TRANSFER_CASH_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      const queryStringParams = objectToQueryString({
        [DEFAULT_KEY_NAME_TRANSFER_CASH_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
        statusPage: DEFAULT_STATUS_PAGE_DETAIL_CASH_BANK_CASH_TRANSFER,
        selectedTab: DEFAULT_TAB_NAME_CASH_BANK_CASH_TRANSFER,
      });

      return formatHelp.combineBaseUrlWithSearchParams(
        REACT_APP_CASH_BANK_BASE_URL,
        queryStringParams,
      );
    },
    typographyTranslation: 'typography.cash-transfer-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_TRANSFER_CASH_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      return strHelp.templateString(REACT_APP_CASH_BANK_CASH_TRANSFER_DETAILS_TEMPLATE_URL, {
        ...accountTransactionData,
        [DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
      });
    },
    typographyTranslation: 'typography.cash-transfer-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_RECEIPT_CASH_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      return strHelp.templateString(REACT_APP_CASH_BANK_CASH_RECEIPT_DETAILS_TEMPLATE_URL, {
        ...accountTransactionData,
        [DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
      });
    },
    typographyTranslation: 'typography.cash-receipt-with-transaction-number',
    isDisabled: false,
  },
  [DEFAULT_TRANSACTION_PAYMENT_CASH_API_REF_TYPE]: {
    generateLinkDetails: ({
      accountTransactionData,
      hiddenUniqueKeyName = DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF,
    }) => {
      if (isEmpty(accountTransactionData) || !accountTransactionData[hiddenUniqueKeyName])
        return '';

      return strHelp.templateString(REACT_APP_CASH_BANK_CASH_PAYMENT_DETAILS_TEMPLATE_URL, {
        ...accountTransactionData,
        [DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF]: accountTransactionData[hiddenUniqueKeyName],
      });
    },
    typographyTranslation: 'typography.cash-payment-with-transaction-number',
    isDisabled: false,
  },
};

export const getAdditionalTransactionProUtils = ({
  transactionType = DEFAULT_TRANSACTION_ASSET_DEPRECIATION_API_REF_TYPE,
}) => {
  if (!(transactionType in additionalTransactionProUtils)) return defaultTransactionProUtils;

  return additionalTransactionProUtils[transactionType];
};
