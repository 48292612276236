import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  LOCAL_STORAGE_CLOSING_BOOK_DATA,
  LOCAL_STORAGE_TEMP_CLOSING_BOOK_PROCESS,
} from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  SLICE_NAME_CLOSING_BOOK_DATA,
  SLICE_NAME_CLOSING_BOOK_DETAILS,
  SLICE_NAME_TEMP_PROCESS_CLOSING_BOOK,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_CREATE_CLOSING_BOOK_DATA_FAILED,
  STATUS_REQUEST_CREATE_CLOSING_BOOK_DATA_PENDING,
  STATUS_REQUEST_CREATE_CLOSING_BOOK_DATA_SUCCESS,
  STATUS_REQUEST_DETAILS_CLOSING_BOOK_DATA_FAILED,
  STATUS_REQUEST_DETAILS_CLOSING_BOOK_DATA_PENDING,
  STATUS_REQUEST_DETAILS_CLOSING_BOOK_DATA_SUCCESS,
  STATUS_REQUEST_LIST_CLOSING_BOOK_DATA_FAILED,
  STATUS_REQUEST_LIST_CLOSING_BOOK_DATA_PENDING,
  STATUS_REQUEST_LIST_CLOSING_BOOK_DATA_SUCCESS,
  STATUS_REQUEST_PROCESS_CLOSING_BOOK_DATA_FAILED,
  STATUS_REQUEST_PROCESS_CLOSING_BOOK_DATA_PENDING,
  STATUS_REQUEST_PROCESS_CLOSING_BOOK_DATA_SUCCESS,
  STATUS_REQUEST_REVERT_CLOSING_BOOK_DATA_FAILED,
  STATUS_REQUEST_REVERT_CLOSING_BOOK_DATA_PENDING,
  STATUS_REQUEST_REVERT_CLOSING_BOOK_DATA_SUCCESS,
} from '../../utils/constants/store.constant';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import closingBookActions from './closing-book.action';

const initialState = {
  [SLICE_NAME_CLOSING_BOOK_DATA]: LocalStorage.get(LOCAL_STORAGE_CLOSING_BOOK_DATA),
  [SLICE_NAME_TEMP_PROCESS_CLOSING_BOOK]: LocalStorage.get(LOCAL_STORAGE_TEMP_CLOSING_BOOK_PROCESS),
  [SLICE_NAME_CLOSING_BOOK_DETAILS]: null,
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
};

const closingBookSlice = createSlice({
  name: NAME_STORE.CLOSING_BOOK,
  initialState,
  reducers: {
    closingBookActions,
    clearDataClosingBookSlice: (state, action) => {
      const { sliceName, localstorageName } = action.payload;

      if (localstorageName) {
        if (LocalStorage.get(localstorageName)) {
          LocalStorage.remove(localstorageName);
        }
      }

      return {
        ...state,
        [sliceName]: null,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      [SLICE_NAME_CLOSING_BOOK_DATA]: LOCAL_STORAGE_CLOSING_BOOK_DATA,
      [SLICE_NAME_TEMP_PROCESS_CLOSING_BOOK]: LOCAL_STORAGE_TEMP_CLOSING_BOOK_PROCESS,
    }),
  },
  extraReducers: (builder) => {
    // list closing book
    builder.addCase(closingBookActions.getClosingBookList.fulfilled, (state, action) => {
      state[SLICE_NAME_CLOSING_BOOK_DATA] = action.payload[SLICE_NAME_CLOSING_BOOK_DATA];
      state.statusRequest = STATUS_REQUEST_LIST_CLOSING_BOOK_DATA_SUCCESS;
    }),
      builder.addCase(closingBookActions.getClosingBookList.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_CLOSING_BOOK_DATA_PENDING;
      }),
      builder.addCase(closingBookActions.getClosingBookList.rejected, (state) => {
        state[SLICE_NAME_CLOSING_BOOK_DATA] = null;
        state.statusRequest = STATUS_REQUEST_LIST_CLOSING_BOOK_DATA_FAILED;
      }),
      // refresh list closing book
      builder.addCase(closingBookActions.getClosingBookListRefresh.fulfilled, (state, action) => {
        state[SLICE_NAME_CLOSING_BOOK_DATA] = action.payload[SLICE_NAME_CLOSING_BOOK_DATA];
        state.statusRequest = STATUS_REQUEST_LIST_CLOSING_BOOK_DATA_SUCCESS;
      }),
      builder.addCase(closingBookActions.getClosingBookListRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_CLOSING_BOOK_DATA_PENDING;
      }),
      builder.addCase(closingBookActions.getClosingBookListRefresh.rejected, (state) => {
        state[SLICE_NAME_CLOSING_BOOK_DATA] = null;
        state.statusRequest = STATUS_REQUEST_LIST_CLOSING_BOOK_DATA_FAILED;
      }),
      // revert closing book
      builder.addCase(closingBookActions.closingBookRevert.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_REVERT_CLOSING_BOOK_DATA_SUCCESS;
      }),
      builder.addCase(closingBookActions.closingBookRevert.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_REVERT_CLOSING_BOOK_DATA_PENDING;
      }),
      builder.addCase(closingBookActions.closingBookRevert.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_REVERT_CLOSING_BOOK_DATA_FAILED;
      }),
      // process closing book
      builder.addCase(closingBookActions.closingBookProcess.fulfilled, (state, action) => {
        state[SLICE_NAME_TEMP_PROCESS_CLOSING_BOOK] =
          action.payload[SLICE_NAME_TEMP_PROCESS_CLOSING_BOOK];
        state.statusRequest = STATUS_REQUEST_PROCESS_CLOSING_BOOK_DATA_SUCCESS;

        // break rule, change on future
        LocalStorage.set(
          LOCAL_STORAGE_TEMP_CLOSING_BOOK_PROCESS,
          action.payload[SLICE_NAME_TEMP_PROCESS_CLOSING_BOOK],
        );
      }),
      builder.addCase(closingBookActions.closingBookProcess.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_PROCESS_CLOSING_BOOK_DATA_PENDING;
      }),
      builder.addCase(closingBookActions.closingBookProcess.rejected, (state) => {
        state[SLICE_NAME_TEMP_PROCESS_CLOSING_BOOK] = null;
        state.statusRequest = STATUS_REQUEST_PROCESS_CLOSING_BOOK_DATA_FAILED;
      }),
      // detail closing book
      builder.addCase(closingBookActions.getClosingBookDetails.fulfilled, (state, action) => {
        state[SLICE_NAME_CLOSING_BOOK_DETAILS] = action.payload[SLICE_NAME_CLOSING_BOOK_DETAILS];
        state.statusRequest = STATUS_REQUEST_DETAILS_CLOSING_BOOK_DATA_SUCCESS;
      }),
      builder.addCase(closingBookActions.getClosingBookDetails.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAILS_CLOSING_BOOK_DATA_PENDING;
      }),
      builder.addCase(closingBookActions.getClosingBookDetails.rejected, (state) => {
        state[SLICE_NAME_CLOSING_BOOK_DETAILS] = null;
        state.statusRequest = STATUS_REQUEST_DETAILS_CLOSING_BOOK_DATA_FAILED;
      }),
      // create closing book
      builder.addCase(closingBookActions.closingBookCreate.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_CREATE_CLOSING_BOOK_DATA_SUCCESS;
      }),
      builder.addCase(closingBookActions.closingBookCreate.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_CREATE_CLOSING_BOOK_DATA_PENDING;
      }),
      builder.addCase(closingBookActions.closingBookCreate.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_CREATE_CLOSING_BOOK_DATA_FAILED;
      });
  },
});

export const { actions, reducer } = closingBookSlice;

export const { clearDataClosingBookSlice } = actions;

export default reducer;
