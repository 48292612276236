import { createAsyncThunk } from '@reduxjs/toolkit';

import attachmentServices from '../../services/API/attachment/attachment.service';
import {
  SLICE_NAME_LIST_DETAILS_ATTACHMENT,
  SLICE_NAME_TEMP_CREATE_ATTACHMENT,
} from '../../utils/constants/store.constant';
import {
  creatorAddActionWithStoreDataOnSlice,
  creatorDeleteActionV2,
  creatorListDetailAction,
} from '../creator-action/creator-action.helper';

// action for create attachment
export const createAttachment = createAsyncThunk(
  'attachment/create',
  async (bodyCreateAttachment, thunkAPI) => {
    return await creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      bodyCreateAttachment,
      attachmentServices.createAttachment,
      SLICE_NAME_TEMP_CREATE_ATTACHMENT,
      '',
      '',
    );
  },
);

// action for listing detail attachment
export const listDetailsAttachment = createAsyncThunk(
  'attachment/list-detail',
  async (paramsListDetailsAttachment, thunkAPI) => {
    return await creatorListDetailAction({
      thunkAPI,
      paramsDetail: paramsListDetailsAttachment,
      serviceFunc: attachmentServices.listDetailsAttachment,
      nameKeySlicePayload: SLICE_NAME_LIST_DETAILS_ATTACHMENT,
    });
  },
);

// action for delete attachment
export const deleteAttachment = createAsyncThunk(
  'attachment/delete',
  async (paramsDeleteAttachment, thunkAPI) => {
    return await creatorDeleteActionV2({
      thunkAPI,
      paramsDeleteAction: paramsDeleteAttachment,
      serviceFunc: attachmentServices.deleteAttachment,
      isMessageShow: false,
    });
  },
);

// handling delete batch for attachment
export const deleteBatchAttachment = createAsyncThunk(
  'attachment/delete-batch',
  async (paramsDeleteBatchAttachment, thunkAPI) => {
    return creatorDeleteActionV2({
      thunkAPI,
      paramsDeleteAction: paramsDeleteBatchAttachment,
      serviceFunc: attachmentServices.deleteBatchAttachment,
      isMessageShow: false,
    });
  },
);

const attachmentActions = {
  createAttachment,
  listDetailsAttachment,
  deleteAttachment,
  deleteBatchAttachment,
};

export default attachmentActions;
