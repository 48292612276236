import {
  FORMAT_MAPPING_TRANSLATION_COLUMN as FORMAT_MAPPING_TRANSLATION_COLUMN_TABLE_KEY_VALUE,
  FORMAT_PERIOD_DATE_COLUMN,
} from '../../../components/table/TableKeyValuePureMarkup/TableKeyValuePureMarkup';
import {
  FORMAT_COMMON_COLUMN,
  FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
  FORMAT_DATE_COLUMN,
  FORMAT_MAPPING_TRANSLATION_COLUMN,
} from '../../../components/table-row/TableRowPureMarkup/TableRowPureMarkup';
import { mappingMinutesHandoverStatus } from '../label-value/minutes-of-handover-status';
import { mappingSalesDeliveryStatus, mappingSalesInvoiceStatus } from '../label-value/sales-status';

// column sales delivery list that handling table head with translation
export const columnsTableSalesDelivery = (t) => {
  return [
    {
      name: 'delivery_date',
      name_input: 'from_date',
      label: t('dashboard.sales.sales-delivery.table.header-column.transaction-date'),
      placeholder: t('dashboard.sales.sales-delivery.input.search-transaction-date.placeholder'),
      width: 90,
    },
    {
      name: 'sales_delivery_no',
      name_input: 'transaction_no',
      label: t('table.header-column.transaction-no'),
      placeholder: t('dashboard.sales.sales-delivery.input.search-transaction-no.placeholder'),
      width: 130,
    },
    {
      name: 'customer_name',
      name_input: 'customer_name',
      label: t('dashboard.sales.sales-delivery.table.header-column.customer'),
      placeholder: t('dashboard.sales.sales-delivery.input.search-customer.placeholder'),
      width: 135,
    },
    {
      name: 'amount',
      label: t('dashboard.sales.sales-delivery.table.header-column.nominal'),
      align: 'right',
      width: 150,
    },
    {
      name: 'status',
      name_input: 'status',
      label: t('table.header-column.delivery-status'),
      placeholder: t('dashboard.sales.sales-delivery.input.search-status.placeholder'),
      width: 225,
      minWidth: 230,
      maxWidth: 235,
    },
    {
      name: 'sales_handover_status',
      name_input: 'sales_handover_status',
      label: t('table.header-column.bast-uppercase'),
      placeholder: t('placeholder.choose-status'),
      width: 225,
      minWidth: 230,
      maxWidth: 235,
    },
    {
      name: 'status_invoice',
      name_input: 'status_invoice',
      label: t('table.header-column.invoice-status'),
      placeholder: t('placeholder.choose-status'),
      width: 225,
      minWidth: 230,
      maxWidth: 235,
    },
  ];
};

export const columnsTableSalesDeliveryPDFprint = (t) => {
  return [
    {
      name: 'delivery_date',
      label: t('dashboard.sales.sales-delivery.table.header-column.transaction-date'),
      style: {
        width: 200,
        position: 'unset',
      },
      formatData: FORMAT_DATE_COLUMN,
    },
    {
      name: 'sales_delivery_no',
      label: t('table.header-column.transaction-no'),
      style: {
        position: 'unset',
        width: 130,
      },
      formatData: FORMAT_COMMON_COLUMN,
    },
    {
      name: 'customer_name',
      label: t('dashboard.sales.sales-delivery.table.header-column.customer'),
      style: {
        width: 135,
        position: 'unset',
      },
      formatData: FORMAT_COMMON_COLUMN,
    },
    {
      name: 'amount',
      label: t('dashboard.sales.sales-delivery.table.header-column.nominal'),
      style: {
        width: 150,
        position: 'unset',
      },
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'status',
      label: t('table.header-column.delivery-status'),
      style: {
        width: 225,
        position: 'unset',
      },
      formatData: FORMAT_MAPPING_TRANSLATION_COLUMN,
      mappingTranslation: mappingSalesDeliveryStatus,
    },
    {
      name: 'sales_handover_status',
      label: t('table.header-column.bast-uppercase'),
      style: {
        width: 225,
        position: 'unset',
      },
      formatData: FORMAT_MAPPING_TRANSLATION_COLUMN,
      mappingTranslation: mappingMinutesHandoverStatus,
    },
    {
      name: 'status_invoice',
      label: t('table.header-column.invoice-status'),
      style: {
        width: 225,
        position: 'unset',
      },
      formatData: FORMAT_MAPPING_TRANSLATION_COLUMN,
      mappingTranslation: mappingSalesInvoiceStatus,
      substituteNotExistValue: t('label.sales-invoice-status.open'),
    },
  ];
};

// columns table sales delivery print
export const columnsTableKeyValuePrintIndexSalesDelivery = [
  {
    header: 'dashboard.sales.sales-delivery.table.header-column.transaction-date',
    valueKeyName: 'search_from_date',
    valueKeyNameSecondary: 'search_to_date',
    formatValue: FORMAT_PERIOD_DATE_COLUMN,
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'table.header-column.transaction-no',
    valueKeyName: 'search_sales_order_no',
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'dashboard.sales.sales-delivery.table.header-column.customer',
    valueKeyName: 'search_customer_name',
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'table.header-column.delivery-status',
    valueKeyName: 'search_status',
    formatValue: FORMAT_MAPPING_TRANSLATION_COLUMN_TABLE_KEY_VALUE,
    mappingTranslation: mappingSalesDeliveryStatus,
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'table.header-column.bast-uppercase',
    valueKeyName: 'search_sales_handover_status',
    formatValue: FORMAT_MAPPING_TRANSLATION_COLUMN_TABLE_KEY_VALUE,
    mappingTranslation: mappingMinutesHandoverStatus,
    showOnlyWhenExistedValue: true,
  },
  {
    header: 'table.header-column.invoice-status',
    valueKeyName: 'search_status_invoice',
    formatValue: FORMAT_MAPPING_TRANSLATION_COLUMN_TABLE_KEY_VALUE,
    mappingTranslation: mappingSalesInvoiceStatus,
    showOnlyWhenExistedValue: true,
  },
];

export const columnsSearchSalesDeliveryFormatDate = [
  'from_date',
  'to_date',
  'transaction_date',
  'delivery_date',
];

export const columnsSearchSalesDeliveryFormatStatus = [
  'status',
  'sales_handover_status',
  'status_invoice',
];

export const columnsSearchSalesDeliveryFormatAction = ['action'];

export const columnsSearchSalesDeliveryFormatLink = ['sales_delivery_no'];

export const columnsDataSalesDeliveryFormatCalculationNumber = ['amount'];
