import { isEmpty } from 'lodash';
import { v4 as uuidV4 } from 'uuid';

import { DEFAULT_DISCOUNT_PERCENTAGE_TYPE } from '../../default/discount-type.default';
import { DEFAULT_COMPARISON_VALUE_NUMBER } from '../../default/number.default';
import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF,
  DEFAULT_KEY_NAME_ATTACHMENT_API_REF,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_REF_API,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';
import arrHelp from '../../helpers/array.helpers';
import objHelper from '../../helpers/object.helper';
import parseResponseHelpers from '../../helpers/parse-response.helpers';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import { generalizeAttachmentFromDetails } from './initial-add-attachment';
import {
  generalizeDataAddPurchaseOrder,
  generateDataNValidationAddProductPO,
} from './initial-add-purchase-order';
import {
  initialProductData,
  initialProductValidation,
  initialPurchaseData,
  initialPurchaseValidation,
  purchaseOrderCalculationNumberKeyNames,
} from './initial-data-purchase-order';

/**
 *
 * @param { object } purchaseOrderDataSelected data that selected for purchase order
 * @param { string } customDataKey returning name key of data purchase on purchase order
 * @param { string } customValidationKey returning name key of validation purchase on purchase order
 * @returns
 *      generated data and validation purchase order for updating data
 */
export const generateDataNValidationUpdatePurchasePO = (
  purchaseOrderDataSelected,
  customDataKey = 'purchaseData',
  customValidationKey = 'purchaseValidation',
) => {
  const usedID = arrHelp.generateArrWithFunc(1, uuidV4);

  const {
    transaction_date,
    expired_date,
    supplier_id,
    supplier_name,
    purchase_order_id,
    purchase_order_no,
    description: product_description,
    quotation_request_id,
    quotation_request_code,
    transaction_no,
    contact_code,
  } = purchaseOrderDataSelected;

  purchaseOrderDataSelected = objHelper.changeFormatValue(
    purchaseOrderDataSelected,
    purchaseOrderCalculationNumberKeyNames,
    [(value) => Number(value) || ''],
  );

  const purchaseData = usedID.map((id) => {
    return {
      ...initialPurchaseData('', id),
      ...purchaseOrderDataSelected,
      supplier_id: supplier_id || null,
      supplier_name: supplier_name || '',
      supplier_id_container: {
        supplier_id: supplier_id || null,
        supplier_name: supplier_name || '',
        contact_name: supplier_name || '',
        contact_code,
      },
      transaction_date: transaction_date ? new Date(transaction_date) : null,
      expired_date: expired_date ? new Date(expired_date) : null,
      purchase_order_no,
      reference_number: purchase_order_id,
      reference_number_container: {
        purchase_order_id,
        value: purchase_order_no || '',
      },
      quotation_request_id: quotation_request_id || null,
      quotation_request_id_container: {
        quotation_request_id: quotation_request_id || null,
        quotation_request_code: quotation_request_code || transaction_no,
        transaction_no,
      },
      product_description: product_description || '',
      discount_account_id_container: {
        account_id: purchaseOrderDataSelected.discount_account_id || '',
        account_name: purchaseOrderDataSelected.discount_account_name || '',
        account_code: purchaseOrderDataSelected.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: purchaseOrderDataSelected.expense_account_id || '',
        account_name: purchaseOrderDataSelected.expense_account_name || '',
        account_code: purchaseOrderDataSelected.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: purchaseOrderDataSelected.down_payment_account_id || '',
        account_name: purchaseOrderDataSelected.down_payment_account_name || '',
        account_code: purchaseOrderDataSelected.down_payment_account_code || '',
      },
    };
  });

  const purchaseValidation = usedID.map((id) => {
    return initialPurchaseValidation('', id);
  });

  return {
    [customDataKey]: purchaseData,
    [customValidationKey]: purchaseValidation,
  };
};

/**
 *
 * @param { object } purchaseOrderDataSelected data that selected for purchase order
 * @param { string } customDataKey returning name key of data purchase on purchase order
 * @param { string } customValidationKey returning name key of validation purchase on purchase order
 * @returns
 *      generated data and validation purchase order for updating data
 */
export const generateDataNValidationUpdatePurchasePurchaseOrderV2 = ({
  purchaseOrderDataSelected,
  companyUserData = {},
  customDataKey = 'purchaseData',
  customValidationKey = 'purchaseValidation',
}) => {
  const usedID = arrHelp.generateArrWithFunc(1, uuidV4);

  const {
    transaction_date,
    expired_date,
    supplier_id,
    supplier_name,
    purchase_order_id,
    purchase_order_no,
    description: product_description,
    quotation_request_id,
    quotation_request_code,
    transaction_no,
    contact_code,
    discount_type,
    warehouse_id,
    warehouse_name,
  } = purchaseOrderDataSelected;

  purchaseOrderDataSelected = objHelper.changeFormatValue(
    purchaseOrderDataSelected,
    purchaseOrderCalculationNumberKeyNames,
    [(value) => Number(value) || ''],
  );

  const purchaseData = usedID.map((id) => {
    return {
      ...initialPurchaseData('', id),
      ...purchaseOrderDataSelected,
      attachment: generalizeAttachmentFromDetails({
        dataDetails: purchaseOrderDataSelected,
      }),
      accounting_period: !isEmpty(companyUserData)
        ? companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF]
        : null,
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
      supplier_id: supplier_id || null,
      supplier_name: supplier_name || '',
      supplier_id_container: {
        supplier_id: supplier_id || null,
        supplier_name: supplier_name || '',
        contact_name: supplier_name || '',
        contact_code,
      },
      transaction_date: transaction_date ? new Date(transaction_date) : null,
      expired_date: expired_date ? new Date(expired_date) : null,
      purchase_order_no,
      reference_number: purchase_order_id,
      reference_number_container: {
        purchase_order_id,
        value: purchase_order_no || '',
      },
      quotation_request_id: quotation_request_id || null,
      quotation_request_id_container: {
        quotation_request_id: quotation_request_id || null,
        quotation_request_code: quotation_request_code || transaction_no,
        transaction_no,
      },
      warehouse_id_container: {
        warehouse_id,
        warehouse_name,
      },
      product_description: product_description || '',
      discount_type: discount_type ? discount_type : DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
      discount_account_id_container: {
        account_id: purchaseOrderDataSelected.discount_account_id || '',
        account_name: purchaseOrderDataSelected.discount_account_name || '',
        account_code: purchaseOrderDataSelected.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: purchaseOrderDataSelected.expense_account_id || '',
        account_name: purchaseOrderDataSelected.expense_account_name || '',
        account_code: purchaseOrderDataSelected.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: purchaseOrderDataSelected.down_payment_account_id || '',
        account_name: purchaseOrderDataSelected.down_payment_account_name || '',
        account_code: purchaseOrderDataSelected.down_payment_account_code || '',
      },
      ...parseResponseHelpers.tagContainerInputFromDetails(purchaseOrderDataSelected),
    };
  });

  const purchaseValidation = usedID.map((id) => {
    return initialPurchaseValidation('', id);
  });

  return {
    [customDataKey]: purchaseData,
    [customValidationKey]: purchaseValidation,
  };
};

/**
 *
 * @param { object } purchaseOrderDataSelected data that purchase order selected
 * @param { string } customDataKey name key of data that for returning
 * @param { string } customValidationKey name key of validation data for returing value
 * @returns
 *      object that represent data and validation for product purchase order
 */
export const generateDataNValidationUpdateProductPO = (
  purchaseOrderDataSelected,
  customDataKey = 'productData',
  customValidationKey = 'productValidation',
) => {
  const purchaseOrderProduct =
    purchaseOrderDataSelected[DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_REF_API];
  const purchaseOrderProductLength = purchaseOrderProduct ? purchaseOrderProduct.length : 0;

  const usedID = arrHelp.generateArrWithFunc(purchaseOrderProductLength, uuidV4);

  let productData = usedID.map((id, index) => {
    const {
      product_id,
      product_name,
      quantity,
      quantity_origin,
      price,
      description: product_description,
      product_unit,
      unit_destination,
      unit_origin,
    } = purchaseOrderProduct[index];

    const amount = Number(quantity) * Number(price);

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    return {
      ...initialProductData('', id),
      ...purchaseOrderProduct[index],
      product_id_container: {
        product_id: product_id || null,
        product_name: product_name || '',
        ...purchaseOrderProduct[index],
      },
      quantity: Number(quantity),
      amount: Number(amount),
      product_description: product_description || '',
      quantity_available: Number(quantity),
      unit_destination: !hasImplementedUnitProductUnit ? product_unit : unit_destination,
      unit_origin: !hasImplementedUnitProductUnit ? product_unit : unit_origin,
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantity),
        value_comparison: quantityComparison,
      },
    };
  });

  const { productData: productDataGen, productValidation: productValidationGen } =
    generateDataNValidationAddProductPO(1);

  let productValidation = usedID.map((id) => {
    return initialProductValidation('', id);
  });

  productData = productData.concat(productDataGen);
  productValidation = productValidation.concat(productValidationGen);

  return {
    [customDataKey]: productData,
    [customValidationKey]: productValidation,
  };
};

/**
 *
 * @param { object } purchaseOrderDataSelected          data that purchase order selected
 * @param { string } customDataKey                      name key of data that for returning
 * @param { string } customValidationKey                name key of validation data for returing value
 * @param { string } keynamePurchaseOrderProduct        keyname for getting product of purchase order
 * @param { bool }   addNewRowPurchaseOrderProduct      determine add new row on product purchase order or not
 * @returns
 *      object that represent data and validation for product purchase order
 */
export const generateDataNValidationUpdatePurchaseOrderProductV2 = ({
  purchaseOrderDataSelected,
  customDataKey = 'productData',
  customValidationKey = 'productValidation',
  keynamePurchaseOrderProduct = DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_REF_API,
  addNewRowPurchaseOrderProduct = true,
}) => {
  const purchaseOrderProducts = purchaseOrderDataSelected[keynamePurchaseOrderProduct];

  const purchaseOrderProductLength = purchaseOrderProducts ? purchaseOrderProducts.length : 0;

  const usedID = arrHelp.generateArrWithFunc(purchaseOrderProductLength, uuidV4);

  let productData = usedID.map((id, index) => {
    const {
      product_id,
      product_name,
      quantity,
      quantity_origin,
      price,
      description: product_description,
      product_unit,
      unit_destination,
      unit_origin,
    } = purchaseOrderProducts[index];

    const amount = Number(quantity) * Number(price);

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    return {
      ...initialProductData('', id),
      ...purchaseOrderProducts[index],
      product_id_container: {
        product_id: product_id || null,
        product_name: product_name || '',
        ...purchaseOrderProducts[index],
      },
      quantity: Number(quantity),
      amount: Number(amount),
      product_description: product_description || '',
      quantity_available: Number(quantity),
      unit_destination: !hasImplementedUnitProductUnit ? product_unit : unit_destination,
      unit_origin: !hasImplementedUnitProductUnit ? product_unit : unit_origin,
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantity),
        value_comparison: quantityComparison,
      },
    };
  });

  const { productData: productDataGen, productValidation: productValidationGen } =
    generateDataNValidationAddProductPO(1);

  let productValidation = usedID.map((id) => {
    return initialProductValidation('', id);
  });

  if (addNewRowPurchaseOrderProduct) {
    productData = productData.concat(productDataGen);
    productValidation = productValidation.concat(productValidationGen);
  }

  return {
    [customDataKey]: productData,
    [customValidationKey]: productValidation,
  };
};

export const generalizeDataUpdatePurchaseOrder = (purchaseData, productData) => {
  const { purchase_order_id } =
    Array.isArray(purchaseData) && purchaseData.length ? purchaseData[0] : purchaseData;

  const generalizedForUpdate = generalizeDataAddPurchaseOrder(purchaseData, productData);

  return {
    ...generalizedForUpdate,
    purchase_order_id,
  };
};
