import * as React from 'react';
function SvgPurchasePaymentMenu(props) {
  return (
    <svg width={136} height={80} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#purchase-payment-menu_svg__filter0_d_5586_193223)'>
        <path
          d='M119.057 5.213c1.246 0 2.501.485 3.455 1.457a5.044 5.044 0 011.425 3.524l-.001 57.825a5.07 5.07 0 01-1.424 3.528A4.843 4.843 0 01119.057 73H29.133a4.846 4.846 0 01-3.455-1.453 5.064 5.064 0 01-1.428-3.528l.002-57.823a5.042 5.042 0 011.427-3.529 4.842 4.842 0 013.452-1.454h89.926z'
          fill='#CEE2F2'
        />
        <path
          d='M118.498 5H72.276v67.841h46.222c3.177 0 5.752-2.41 5.752-5.384V10.384C124.249 7.411 121.674 5 118.498 5z'
          fill='#BCDBF4'
        />
        <path
          d='M52.248 57.963H36.665v2.182h15.583v-2.182zM72.01 57.963H56.423v2.182H72.01v-2.182zM91.763 57.963H76.178v2.182h15.585v-2.182zM111.523 57.963H95.941v2.182h15.582v-2.182z'
          fill='#03628A'
        />
        <path d='M111.518 39.087H36.665v8.728h74.853v-8.728z' fill='#fff' />
        <path d='M111.529 39.106H72.276v8.727h39.253v-8.727z' fill='#F4EFEF' />
        <path
          d='M77.795 42.627h-4.278v1.641h4.278v-1.64zM93.032 42.627H80.197v1.641h12.835v-1.64zM108.272 42.627H95.437v1.641h12.835v-1.64zM123.937 17.544H24.25v15.51h99.687v-15.51z'
          fill='#03628A'
        />
        <path d='M123.887 17.545h-51.61v15.511h51.61v-15.51z' fill='#045A7E' />
        <path
          d='M5.898 38.87c0 1.03-1.04 1.869-2.323 1.869-1.284 0-2.325-.838-2.325-1.869 0-1.033 1.041-1.87 2.325-1.87 1.282 0 2.323.837 2.323 1.87zM38.363 41.314l-18.075 4.939 7.081 16.784 18.075-4.939-7.081-16.784z'
          fill='#FF8E31'
        />
        <path
          d='M48.884 68.415H18.597c-.96 0-1.865-.3-2.547-.849-.679-.549-1.054-1.276-1.054-2.05v-.078c0-.658.283-1.304.793-1.818.514-.512 1.237-.873 2.037-1.015l25.377-4.493c.602-.106 1.196.2 1.329.682.132.483-.248.963-.85 1.07l-25.377 4.493c-.31.053-.578.188-.777.387a.976.976 0 00-.304.694v.077c0 .296.143.574.402.783.26.208.606.325.97.325h30.287c.616 0 1.114.4 1.114.896 0 .495-.497.896-1.113.896z'
          fill='#F2F4F7'
        />
        <path
          d='M19.386 64.144c-.537 0-1.011-.314-1.098-.756l-4.582-23.094a1.055 1.055 0 00-.465-.668 1.579 1.579 0 00-.894-.265H3.575c-.616 0-1.116-.401-1.116-.896s.5-.899 1.116-.899h8.771c.857 0 1.688.249 2.34.695.651.446 1.087 1.07 1.22 1.75l4.581 23.093c.096.49-.318.95-.925 1.028-.06.008-.117.011-.176.011z'
          fill='#F2F4F7'
        />
        <path
          d='M24.514 69.885c0 1.581-1.593 2.86-3.553 2.86-1.962 0-3.555-1.279-3.555-2.86 0-1.58 1.593-2.858 3.555-2.858 1.96 0 3.553 1.278 3.553 2.858z'
          fill='#34495E'
        />
        <path
          d='M22.735 69.885c0 .79-.794 1.43-1.774 1.43-.983 0-1.775-.64-1.775-1.43 0-.788.792-1.428 1.775-1.428.98 0 1.774.64 1.774 1.428z'
          fill='#F2F4F7'
        />
        <path
          d='M49.998 69.885c0 1.581-1.592 2.86-3.554 2.86-1.961 0-3.552-1.279-3.552-2.86 0-1.58 1.591-2.858 3.552-2.858 1.962 0 3.554 1.278 3.554 2.858z'
          fill='#34495E'
        />
        <path
          d='M48.22 69.885c0 .79-.796 1.43-1.776 1.43s-1.776-.64-1.776-1.43c0-.788.796-1.428 1.776-1.428s1.777.64 1.777 1.428zM49.614 46.66a1.477 1.477 0 00-.928-.31H14.802l3.672 18.38v.002l30.494-5.4c.282-.049.543-.173.74-.368a.935.935 0 00.29-.661V47.405c0-.27-.129-.54-.384-.745zm-28.56 15.836a.953.953 0 01-.133.008c-.4 0-.753-.233-.818-.562L17.489 48.86c-.072-.364.235-.707.688-.765.45-.059.878.19.952.553l2.612 13.083c.073.364-.234.707-.687.765zm4.333-.768a.918.918 0 01-.118.007c-.406 0-.762-.24-.82-.574L22.27 48.848c-.065-.364.25-.703.703-.755.458-.053.875.202.94.567l2.178 12.313c.065.365-.25.703-.705.755zm4.33-.768a1.18 1.18 0 01-.1.005c-.413 0-.771-.249-.822-.588l-1.743-11.542c-.056-.365.27-.7.723-.744.456-.038.868.216.925.582l1.742 11.544c.055.366-.268.698-.724.743zm4.33-.768a1.226 1.226 0 01-.083.002c-.422 0-.783-.258-.824-.603l-1.308-10.772c-.045-.367.288-.694.746-.73.463-.031.863.233.907.6l1.308 10.773c.043.367-.29.694-.746.73zm4.325-.768l-.06.002c-.432 0-.795-.269-.826-.622l-.87-10.003c-.032-.368.311-.687.77-.714.462-.019.853.252.886.62l.87 10.004c.032.369-.314.687-.77.713zm4.322-.77h-.032c-.444 0-.812-.282-.83-.641l-.435-9.233c-.016-.37.34-.68.799-.694.463-.022.843.274.861.642l.435 9.233c.018.37-.34.68-.797.694zm5.147-1.437c0 .37-.372.668-.83.668-.459 0-.83-.298-.83-.668v-8.462c0-.37.371-.668.83-.668.458 0 .83.298.83.668v8.462z'
          fill='#F2F4F7'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M118.521 5.43c1.231.005 2.176.246 2.785.493l.013-.006-.538 1.709a6.06 6.06 0 00-2.254-.463v2.85l.074.03c.043.017.089.035.134.05 2.131.777 3.083 1.777 3.083 3.202 0 1.548-1.172 2.751-3.291 3.14v1.283h-1.535V16.57h-.019c-1.315 0-2.649-.296-3.271-.604l.499-1.746c.673.327 1.735.592 2.785.605V11.66c-1.924-.703-3.116-1.709-3.116-3.085 0-1.45 1.14-2.628 3.116-3.01V4.282h1.535v1.147zM128.25 11c0-5.521-4.703-10-10.5-10s-10.5 4.479-10.5 10 4.703 10 10.5 10 10.5-4.479 10.5-10zm-1.878 0c0-4.534-3.861-8.21-8.622-8.21-4.761 0-8.621 3.676-8.621 8.21 0 4.534 3.86 8.211 8.621 8.211 4.761 0 8.622-3.677 8.622-8.21zm-7.851 1.345c.486.32.712.666.712 1.129 0 .456-.239.832-.712 1.073v-2.202zm-2.073-3.98c0 .396.169.68.538.939V7.496c-.363.228-.538.549-.538.87z'
          fill='#039855'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M117.75 2.79c4.761 0 8.622 3.676 8.622 8.21 0 4.534-3.861 8.211-8.622 8.211-4.761 0-8.621-3.677-8.621-8.211 0-4.534 3.86-8.21 8.621-8.21zm.771 2.64c1.231.005 2.176.246 2.785.493l.013-.006-.538 1.709a6.06 6.06 0 00-2.254-.463v2.85l.074.03c.043.017.089.035.134.05 2.131.777 3.083 1.777 3.083 3.202 0 1.548-1.172 2.751-3.291 3.14v1.283h-1.535V16.57h-.019c-1.315 0-2.649-.296-3.271-.604l.499-1.746c.673.327 1.735.592 2.785.605V11.66c-1.924-.703-3.116-1.709-3.116-3.085 0-1.45 1.14-2.628 3.116-3.01V4.282h1.535v1.147z'
          fill='#FEC84B'
        />
        <path
          d='M116.986 9.304c-.369-.26-.538-.543-.538-.938 0-.321.175-.642.538-.87v1.808zM118.521 12.345c.486.32.712.666.712 1.129 0 .456-.239.832-.712 1.073v-2.202z'
          fill='#FEC84B'
        />
      </g>
      <defs>
        <filter
          id='purchase-payment-menu_svg__filter0_d_5586_193223'
          x={0.25}
          y={0}
          width={135}
          height={80}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_193223' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_193223' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgPurchasePaymentMenu;
