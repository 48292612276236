import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_DISCOUNT_PERCENTAGE_TYPE } from '../../default/discount-type.default';
import validation from '../../helpers/validation.helpers';

const initialID = uuidv4();

/**
 * amount           total for payable all products
 * amount_const     maximum input for downpayment
 */
export const plainSalesReturnDataWithoutID = {
  customer_id: '',
  customer_id_container: '',
  customer_name: '',
  customer_address: '',
  transaction_no: '',
  transaction_no_container: null,
  sales_invoice_no: '',
  sales_invoice_id: '',
  sales_invoice_id_container: '',
  transaction_date: '',
  accounting_period: null,
  today: '',
  amount: 0,
  amount_const: 0,
  invoice_total: 0,
  description: '',
  discount_account_id: '',
  discount_account_id_container: '',
  discount_type: DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
  discount_percentage: '',
  discount_nominal: '',
  expense_account_id: '',
  expense_account_id_container: '',
  expense_value: '',
  down_payment_account_id: '',
  down_payment_account_id_container: '',
  down_payment_value: '',
  on_behalf: '',
  attachment: [],
  uuid_attachment: null,
  minDateInputRef: null,
  is_paid_off: false,
  tag: [],
  tagContainer: [],
  tagNameInput: '',
};

export const plainProductSalesReturnDataWithoutID = {
  product_id: '',
  product_id_container: '',
  price: '',
  description: '',
  unit_origin: '',
  unit_destination: '',
  quantity_origin: '',
  quantity: '',
  quantity_const: 0,
  quantity_available_with_unit: '',
  amount: 0,
};

export const plainSalesReturnValidationWithoutID = {
  customer_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  customer_address: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  transaction_no: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  sales_invoice_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  transaction_date: {
    error: false,
    isValid: (value, minDateInputRef) => {
      if (minDateInputRef) {
        return moment(new Date(minDateInputRef)).isSameOrBefore(moment(new Date(value)), 'D');
      }

      return validation.notnull(value);
    },
  },
  amount: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const plainProductSalesReturnValidationWithoutID = {
  product_id: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  price: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  description: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
  unit_destination: {
    error: false,
    isValid: (value) => validation.notnull(value),
  },
  quantity: {
    error: false,
    isValid: (value) => validation.isNumberGreaterOrEqualThan(value, 1),
  },
  amount: {
    error: false,
    isValid: (value) => validation.nullable(value),
  },
};

export const initialDataSalesReturn = (newID = '') => {
  return {
    id: newID || initialID,
    ...plainSalesReturnDataWithoutID,
    transaction_date: new Date(),
    today: new Date(),
  };
};

export const initialValidationSalesReturn = (newID = '') => {
  return {
    id: newID || initialID,
    ...plainSalesReturnValidationWithoutID,
  };
};

export const initialDataProductSalesReturn = (newID = '') => {
  return {
    id: newID || initialID,
    ...plainProductSalesReturnDataWithoutID,
  };
};

export const initialValidationProductSalesReturn = (newID = '') => {
  return {
    id: newID || initialID,
    ...plainProductSalesReturnValidationWithoutID,
  };
};

export const allowKeysDataSalesReturn = [
  'customer_id',
  'customer_address',
  'sales_invoice_id',
  'transaction_no',
  'transaction_date',
  'description',
  'uuid_attachment',
  'discount_account_id',
  'discount_type',
  'discount_nominal',
  'discount_percentage',
  'expense_account_id',
  'expense_value',
  'down_payment_account_id',
  'down_payment_value',
  'product',
  'is_paid_off',
  'tag',
];

export const allowKeysDataProductSalesReturn = [
  'product_id',
  'price',
  'description',
  'unit_origin',
  'unit_destination',
  'quantity_origin',
  'quantity',
];

export const relationalAdditionalCostValueNaccountForSalesReturn = {
  discount_percentage: ['discount_account_id', 'discount_type'],
  expense_value: 'expense_account_id',
  down_payment_value: 'down_payment_account_id',
};

export const salesReturnCalculationNumberKeyNames = [
  'discount_percentage',
  'discount_nominal',
  'discount_percentage',
  'expense_value',
  'down_payment_value',
  'amount',
];

export const productSalesReturnCalculationNumberKeyNames = ['quantity', 'price', 'amount'];
