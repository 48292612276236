import { createAsyncThunk } from '@reduxjs/toolkit';

import contactServices from '../../services/API/contact/contact.service';
import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  CONTACT_ASYNC_THUNK_TYPE,
  CONTACT_BASE_ACTION_TYPE,
} from '../../utils/constants/action-type.constant';
import {
  LOCAL_STORAGE_CONTACT_DATA,
  LOCAL_STORAGE_PURCHASE_INVOICE_FROM_CONTACT_DATA,
  LOCAL_STORAGE_SALES_INVOICE_FROM_CONTACT_DATA,
} from '../../utils/constants/storage.constant';
import {
  SLICE_NAME_CONTACT_DATA,
  SLICE_NAME_DETAIL_CONTACT,
  SLICE_NAME_TEMP_ADD_CONTACT_DATA,
  STATUS_REQUEST_LIST_CONTACT_DATA_FAILED,
  STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
  STATUS_REQUEST_LIST_CONTACT_DATA_SUCCESS,
} from '../../utils/constants/store.constant';
import {
  DEFAULT_ACTION_SECONDARY_PARAMS,
  DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
} from '../../utils/default/params.default';
import objHelper from '../../utils/helpers/object.helper';
import {
  creatorAddActionWithStoreDataOnSlice,
  creatorDetailAction,
  creatorListAction,
  creatorListActionDynamicSliceNameNproperties,
} from '../creator-action/creator-action.helper';
import messageHelper from '../message/message.helper';

/**
 * contact actions
 *
 */

export const contactList = createAsyncThunk(
  CONTACT_ASYNC_THUNK_TYPE.list,
  async (
    {
      requestStatusPending = STATUS_REQUEST_LIST_CONTACT_DATA_PENDING,
      requestStatusSuccess = STATUS_REQUEST_LIST_CONTACT_DATA_SUCCESS,
      requestStatusFailed = STATUS_REQUEST_LIST_CONTACT_DATA_FAILED,
      slicePayloadKeyname = SLICE_NAME_CONTACT_DATA,
      localStorageName = LOCAL_STORAGE_CONTACT_DATA,
      paginatePageParams = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      ...params
    } = {},
    thunkAPI,
  ) => {
    return await creatorListActionDynamicSliceNameNproperties({
      thunkAPI,
      params,
      apiService: contactServices.contactList,
      localStorageName,
      paginatePageParams,
      requestStatusPending,
      requestStatusSuccess,
      requestStatusFailed,
      uniqueKeyname: 'contact_id',
      slicePayloadKeyname,
      errorMessage: 'error.list-data.contact-data',
    });
  },
);

// get refresh data list with current search
export const getContactListRefresh = createAsyncThunk(
  CONTACT_ASYNC_THUNK_TYPE.listRefresh,
  async (discardKeySearch = [], thunkAPI) => {
    const currentDataContact = LocalStorage.get(LOCAL_STORAGE_CONTACT_DATA);

    // get filtering data from prev search
    let filterDataSearch = objHelper.filterKeyObj(currentDataContact, discardKeySearch, [
      'per_page',
      'current_page',
      'search_contact_code',
      'search_contact_name',
      'search_phone_number',
    ]);

    // get data for next search
    const filterDataNextSearch = objHelper.filterKeyObj(filterDataSearch, [
      'per_page',
      'current_page',
    ]);

    // formalize data for search in service
    filterDataSearch = {
      ...filterDataSearch,
      page: filterDataSearch.current_page,
      paginate: filterDataSearch.per_page,
      contact_code: filterDataSearch.search_contact_code,
      contact_name: filterDataSearch.search_contact_name,
      phone_number: filterDataSearch.search_phone_number,
    };

    // get data that only existed value
    filterDataSearch = objHelper.filteringExistedValue(filterDataSearch);

    // removing unused key for now search data
    filterDataSearch = objHelper.filterKeyObj(filterDataSearch, [
      'current_page',
      'search_contact_code',
      'search_contact_name',
      'search_phone_number',
    ]);

    try {
      const response = await contactServices.contactList(filterDataSearch);

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      let { data: contactData, sucess } = response.data;

      if (!sucess || !Array.isArray(contactData.data)) {
        contactData = {
          ...contactData,
          data: [],
        };
      }

      // adding search cache data from previous search
      const dataResponseContactWithSearch = Object.assign(contactData, { ...filterDataNextSearch });

      // adding data into local storage
      LocalStorage.set(LOCAL_STORAGE_CONTACT_DATA, dataResponseContactWithSearch);

      return { contactData: dataResponseContactWithSearch };
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.contact-data',
          'error.list-data.contact-data',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.list-data.contact-data',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// single detail contact
export const getDetailContact = createAsyncThunk(
  CONTACT_ASYNC_THUNK_TYPE.detail,
  async (paramsGetDetailContact, thunkAPI) => {
    return await creatorDetailAction(
      thunkAPI,
      paramsGetDetailContact,
      contactServices.getDetailContact,
      '',
      SLICE_NAME_DETAIL_CONTACT,
      'error.detail.contact-data',
    );
  },
);

export const addContact = createAsyncThunk(
  CONTACT_ASYNC_THUNK_TYPE.add,
  async (bodyDataAddContact, thunkAPI) => {
    const { contact_name } = bodyDataAddContact;

    return await creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      bodyDataAddContact,
      contactServices.addContact,
      SLICE_NAME_TEMP_ADD_CONTACT_DATA,
      'success.add.contact-data',
      'error.add.contact-data',
      {},
      { contact_name },
    );
  },
);

export const updateContact = createAsyncThunk(
  CONTACT_ASYNC_THUNK_TYPE.update,
  async (updateContactData, thunkAPI) => {
    const { contact_name } = updateContactData;

    try {
      const response = await contactServices.updateContact(updateContactData);

      if (!response) {
        throw { response };
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.update.contact-data',
          'success.update.contact-data',
          {},
          { contact_name },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.update.contact-data',
          {},
          { contact_name },
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.update.contact-data',
          false,
          {},
          { contact_name },
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.update.contact-data',
          'error.update.contact-data',
          false,
          {},
          { contact_name },
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// delete contact
export const deleteContact = createAsyncThunk(
  CONTACT_ASYNC_THUNK_TYPE.delete,
  async (dataDeleteContact, thunkAPI) => {
    const { contact_name } = dataDeleteContact;

    try {
      const response = await contactServices.deleteContact(dataDeleteContact);

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.delete.contact-data',
          'success.delete.contact-data',
          {},
          { contact_name },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.delete.contact-data',
          {},
          { contact_name },
        );
      }

      // get current contact list
      const contactData = LocalStorage.get(LOCAL_STORAGE_CONTACT_DATA);

      let page = 1;
      let paginate = 10;

      if (contactData) {
        const { current_page, per_page, data } = contactData;

        page = current_page;
        paginate = per_page;

        // get previous data when current data is null
        if (data.length === 1) {
          page = page - 1;
        }
      }

      await thunkAPI.dispatch(
        contactList({
          page,
          paginate,
        }),
      );

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.delete.contact-data',
          'error.delete.contact-data',
          false,
          { contact_name },
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.delete.contact-data',
          false,
          { contact_name },
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// getting list purchase invoice with contact id
export const getListPurchaseInvoiceWithContactID = createAsyncThunk(
  `${CONTACT_BASE_ACTION_TYPE}/purchase-invoice-list`,
  async (paramsListPurchaseInvoice, thunkAPI) => {
    return creatorListAction(
      thunkAPI,
      paramsListPurchaseInvoice,
      contactServices.getListPurchaseInvoiceWithContactID,
      LOCAL_STORAGE_PURCHASE_INVOICE_FROM_CONTACT_DATA,
      'purchaseInvoiceListWithContactID',
      'purchase_invoice_id',
      'error.list-data.purchase-invoice-with-contact-id',
      {},
      DEFAULT_ACTION_SECONDARY_PARAMS,
    );
  },
);

// getting list sales invoice with contact id
export const getListSalesInvoiceWithContactID = createAsyncThunk(
  `${CONTACT_BASE_ACTION_TYPE}/sales-invoice-list`,
  async (paramsListSalesInvoice, thunkAPI) => {
    return creatorListAction(
      thunkAPI,
      paramsListSalesInvoice,
      contactServices.getListSalesInvoiceWithContactID,
      LOCAL_STORAGE_SALES_INVOICE_FROM_CONTACT_DATA,
      'salesInvoiceListWithContactID',
      'sales_invoice_id',
      'error.list-data.sales-invoice-with-contact-id',
      {},
      DEFAULT_ACTION_SECONDARY_PARAMS,
    );
  },
);
