import * as React from 'react';
function SvgSale(props) {
  return (
    <svg width={49} height={33} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#sale_svg__filter0_d_5693_194724)'>
        <path
          d='M43.523 10.905l-6.58-9.13C36.6 1.299 36.06 1 35.52 1H3.308C2.03 1 1 2.253 1 3.804v19.332c0 1.551 1.031 2.804 2.308 2.804h32.211c.54 0 1.08-.298 1.424-.775l6.58-9.13c1.08-1.372 1.08-3.639 0-5.13zm-5.45 5.13c-1.13 0-2.063-1.133-2.063-2.505 0-1.372.933-2.506 2.063-2.506 1.129 0 2.062 1.134 2.062 2.506 0 1.372-.933 2.506-2.062 2.506z'
          fill='#3DB39E'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.943 1.776l6.58 9.128c1.08 1.492 1.08 3.76 0 5.132l-6.58 9.129c-.343.477-.884.775-1.424.775H19.414V1h16.105c.54 0 1.08.298 1.424.776zM36.01 13.53c0 1.372.933 2.506 2.063 2.506 1.129 0 2.062-1.134 2.062-2.506 0-1.372-.933-2.506-2.062-2.506-1.13 0-2.063 1.134-2.063 2.506z'
        fill='#37A18E'
      />
      <g opacity={0.1} fill='#000'>
        <path d='M4.83 25.701c-1.277 0-2.308-1.253-2.308-2.804V3.506c0-1.074.491-1.97 1.228-2.446h-.393c-1.277 0-2.308 1.253-2.308 2.804v19.331C1.049 24.747 2.08 26 3.357 26h32.211c.344 0 .688-.12.982-.358H4.83v.06zM37.73 15.976c.097 0 .245.06.392.06 1.13 0 2.062-1.134 2.062-2.506 0-1.372-.933-2.506-2.062-2.506-.393 0-.786.179-1.13.418.934.238 1.67 1.193 1.67 2.446 0 .895-.393 1.61-.933 2.088z' />
      </g>
      <path
        d='M11.41 14.007a2.426 2.426 0 00-.54-.716c-.246-.179-.491-.358-.737-.418-.245-.119-.54-.179-.835-.238-.294-.06-.54-.12-.736-.18-.246-.059-.393-.119-.54-.238-.148-.06-.197-.179-.197-.358 0-.12.05-.179.098-.239.05-.06.148-.119.295-.179s.295-.06.54-.06c.295 0 .59.06.933.18.295.12.638.238.982.477l.688-2.029c-.344-.238-.786-.417-1.228-.537a5.437 5.437 0 00-1.375-.179c-.687 0-1.326.12-1.768.358a2.506 2.506 0 00-1.08 1.015c-.245.417-.344.895-.344 1.432 0 .477.05.835.197 1.133.147.299.344.537.54.716.246.18.491.358.737.418.294.12.54.179.834.298.246.06.54.12.737.18.245.059.393.119.54.238.147.06.196.179.196.358 0 .12-.049.179-.098.239s-.147.119-.294.179c-.148.06-.295.06-.54.06-.393 0-.786-.06-1.179-.24-.393-.119-.786-.298-1.08-.536l-.737 2.088a3.74 3.74 0 001.326.597c.54.179 1.08.238 1.67.238.736 0 1.325-.119 1.767-.417a2.505 2.505 0 001.08-1.015c.246-.417.344-.895.344-1.432 0-.537-.049-.895-.196-1.193zM14.552 9.354l-2.995 8.353h2.357l.442-1.492h2.602l.442 1.492h2.406L16.86 9.354h-2.308zm.491 4.892l.688-2.088.687 2.088h-1.375z'
        fill='#FFC612'
      />
      <path
        d='M22.556 9.354h-2.308v8.353h5.45v-2.148h-3.142V9.354zM28.645 15.618v-1.133h2.897v-2.029h-2.897v-1.014h3.29V9.354h-5.598v8.353h5.745v-2.089h-3.437z'
        fill='#ECB509'
      />
      <defs>
        <filter
          id='sale_svg__filter0_d_5693_194724'
          x={0}
          y={0}
          width={51.333}
          height={32.94}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5693_194724' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5693_194724' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgSale;
