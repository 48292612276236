import * as React from 'react';
function SvgContactDataMenu(props) {
  return (
    <svg width={73} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#contact-data-menu_svg__filter0_d_5539_189027)'>
        <path
          d='M8.823 1C7.6 1 6.607 1.885 6.607 2.978v64.044C6.607 68.115 7.6 69 8.823 69h8.736V1H8.823z'
          fill='#FFD15D'
        />
        <path
          d='M57.981 1H17.559v68h40.422c1.224 0 2.216-.885 2.216-1.978V2.978C60.197 1.886 59.205 1 57.98 1z'
          fill='#273B7A'
        />
        <path
          d='M59.054 1.028H37.157v67.945h21.898c.662 0 1.2-.885 1.2-1.977V3.004c0-1.091-.538-1.976-1.2-1.976zM3.32 8.995h7.133c.821 0 1.487-.594 1.487-1.328 0-.733-.666-1.327-1.487-1.327H3.321c-.822 0-1.488.594-1.488 1.327S2.5 8.995 3.321 8.995zM10.453 14.149H3.321c-.822 0-1.488.594-1.488 1.328 0 .733.666 1.327 1.488 1.327h7.132c.821 0 1.487-.594 1.487-1.327 0-.734-.666-1.328-1.487-1.328zM10.453 21.958H3.321c-.822 0-1.488.595-1.488 1.328 0 .733.666 1.328 1.488 1.328h7.132c.821 0 1.487-.595 1.487-1.328 0-.733-.666-1.328-1.487-1.328zM10.453 29.768H3.321c-.822 0-1.488.594-1.488 1.328 0 .733.666 1.327 1.488 1.327h7.132c.821 0 1.487-.594 1.487-1.328 0-.733-.666-1.327-1.487-1.327zM10.453 37.577H3.321c-.822 0-1.488.595-1.488 1.328 0 .733.666 1.328 1.488 1.328h7.132c.821 0 1.487-.595 1.487-1.328 0-.733-.666-1.328-1.487-1.328zM10.453 45.386H3.321c-.822 0-1.488.595-1.488 1.328 0 .733.666 1.328 1.488 1.328h7.132c.821 0 1.487-.595 1.487-1.328 0-.733-.666-1.328-1.487-1.328zM10.453 53.196H3.321c-.822 0-1.488.594-1.488 1.328 0 .733.666 1.327 1.488 1.327h7.132c.821 0 1.487-.594 1.487-1.327 0-.734-.666-1.328-1.487-1.328zM10.453 61.005H3.321c-.822 0-1.488.595-1.488 1.328 0 .733.666 1.328 1.488 1.328h7.132c.821 0 1.487-.595 1.487-1.328 0-.733-.666-1.328-1.487-1.328z'
          fill='#121149'
        />
        <path
          d='M63.91 7.667h-3.713v11.605h3.713c1.062 0 1.924-.769 1.924-1.717v-8.17c0-.949-.862-1.718-1.924-1.718z'
          fill='#BD3423'
        />
        <path
          d='M63.91 19.272h-3.713v11.605h3.713c1.062 0 1.924-.77 1.924-1.718v-8.17c0-.948-.862-1.717-1.924-1.717z'
          fill='#02BA88'
        />
        <path
          d='M63.91 30.877h-3.713v11.605h3.713c1.062 0 1.924-.77 1.924-1.718v-8.17c0-.948-.862-1.717-1.924-1.717z'
          fill='#0995D7'
        />
        <path
          d='M63.91 42.482h-3.713v11.605h3.713c1.062 0 1.924-.77 1.924-1.718V44.2c0-.948-.862-1.717-1.924-1.717z'
          fill='#79C9F9'
        />
        <path
          d='M36.493 41.902l3.62-2.427v-3.003c-.287.173-.517.404-.804.635-1.609 1.444-3.16 1.213-3.16 1.213-.92 0-2.299-.924-2.873-1.386-.173-.116-.288-.231-.46-.347v2.888l3.677 2.427z'
          fill='#F6C358'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M37 38.28v3.282l3.113-2.087v-3.003a4.087 4.087 0 00-.546.418c-.083.071-.167.144-.258.217-.863.775-1.71 1.067-2.309 1.172z'
          fill='#F2B22C'
        />
        <path
          d='M32.644 25.553h2.24c.23 0 .403-.058.575-.115a13.34 13.34 0 001.551-.578c.46-.173.92-.173 1.38 0l2.355.809c1.61.578 1.724 2.08 1.724 2.08v4.217s.23-1.445.92-2.6c.172-.289.287-.636.287-.924V24.86s-.23-1.79-1.954-2.195c0 0 .69-1.502-1.149-1.907h-6.78c-2.471 0-4.54 2.022-4.54 4.564v2.715c0 .578.173 1.156.575 1.618.287.346.575.924.632 1.733v-3.64c.46-2.021 2.184-2.195 2.184-2.195z'
          fill='#313C45'
        />
        <path
          d='M36.206 38.898s1.551.23 3.16-1.213c.23-.231.517-.405.805-.636l.057-.058c.69-.462 2.069-1.79 2.356-4.968v-4.217s-.172-1.502-1.724-2.08l-2.47-.866a1.894 1.894 0 00-1.38 0c-.517.173-1.149.404-1.551.578a2.034 2.034 0 01-.575.115h-2.24s-1.724.231-2.184 2.195v4.391s.057 3.466 2.356 4.968c0 0 .057 0 .057.058.173.115.288.23.46.346.575.405 1.896 1.387 2.873 1.387z'
          fill='#FCD462'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M39.366 37.685c-.886.795-1.754 1.082-2.356 1.18V24.86c.46-.173.92-.173 1.38 0l2.47.867c1.552.577 1.724 2.08 1.724 2.08v4.216c-.287 3.178-1.666 4.506-2.356 4.968l-.057.058c-.106.085-.211.162-.314.237-.177.13-.345.252-.49.399z'
          fill='#FACB48'
        />
        <path d='M37.7 42.71l-.747 1.04.69 4.16 1.608-3.004-1.55-2.195z' fill='#B6B9C7' />
        <path d='M35.402 47.91l.632-4.16-.747-1.04-1.552 2.196 1.667 3.004z' fill='#D5D6DB' />
        <path d='M35.344 42.942l-.057.057 1.206-1.675-1.149 1.618z' fill='#D5D6DB' />
        <path
          d='M37.7 47.563l-.575-3.64.632-.924-1.264-1.675L35.287 43l.632.925-.632 3.697 1.206 2.137 1.207-2.137v-.058z'
          fill='#E56353'
        />
        <path
          d='M36.494 41.324L37.758 43l1.379 1.965 2.93-5.373-1.953-.693-3.62 2.426z'
          fill='#B7CBD7'
        />
        <path
          d='M35.287 43l.057-.058 1.15-1.618-3.62-2.426-1.954.693 2.93 5.373 1.437-1.965z'
          fill='#E1E6E9'
        />
        <path
          d='M36.494 49.758h13.158c.804 0 1.494-.75 1.322-1.56l-.632-3.985a2.884 2.884 0 00-1.954-2.311l-6.32-2.31'
          fill='#1D394F'
        />
        <path
          d='M37.7 47.62l-1.206 2.138.115-.115 5.746-5.257-.69-1.387h2.069l-1.667-3.408-2.93 5.373L37.7 47.62z'
          fill='#1A435F'
        />
        <path
          d='M36.493 49.758L30.92 39.591l-6.32 2.31c-1.035.347-1.782 1.272-1.954 2.312l-.633 3.986c-.114.808.518 1.56 1.322 1.56H36.494z'
          fill='#3A556A'
        />
        <path
          d='M36.493 49.758l-1.206-2.137-1.437-2.657-2.93-5.373L29.253 43h2.069l-.69 1.387 5.747 5.257.114.115z'
          fill='#2F4859'
        />
      </g>
      <defs>
        <filter
          id='contact-data-menu_svg__filter0_d_5539_189027'
          x={0.833}
          y={0}
          width={72}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5539_189027' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5539_189027' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgContactDataMenu;
