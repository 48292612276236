import { createSlice } from '@reduxjs/toolkit';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  LOCAL_STORAGE_ALL_NOTIFICATION_LIST,
  LOCAL_STORAGE_INVITATION_NOTIFICATION_LIST,
} from '../../utils/constants/storage.constant';
import {
  REQUEST_STATUS_COUNT_UNREAD_NOTIFICATIONS_FAILED,
  REQUEST_STATUS_COUNT_UNREAD_NOTIFICATIONS_PENDING,
  REQUEST_STATUS_COUNT_UNREAD_NOTIFICATIONS_SUCCESS,
  ROOT_STORE_NAME_NOTIFICATION,
  SLICE_NAME_ALL_NOTIFICATIONS_ALL_LIST,
  SLICE_NAME_INVITATION_NOTIFICATION_LIST,
  SLICE_NAME_UNREAD_NOTIFICATION_COUNT,
  STATUS_REQUEST_BASE_IDDLE,
} from '../../utils/constants/store.constant';
import { getRelationalNotificationTypeWithNotificationActionListConfiguration } from '../../utils/default/notification-config.default';
import { NOTIFICATION_ALL_TYPE } from '../../utils/default/notification-type.default';
import {
  DEFAULT_KEY_NAME_NOTIFICATION_TYPE,
  DEFAULT_KEY_NAME_REQUEST_STATUS,
  DEFAULT_KEY_NAME_UNREAD_API_REF,
} from '../../utils/default/object-keyname.default';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import notificationActions from './notification.action';

const initialNoficationState = {
  [SLICE_NAME_UNREAD_NOTIFICATION_COUNT]: 0,
  [SLICE_NAME_ALL_NOTIFICATIONS_ALL_LIST]: LocalStorage.get(LOCAL_STORAGE_ALL_NOTIFICATION_LIST),
  [SLICE_NAME_INVITATION_NOTIFICATION_LIST]: LocalStorage.get(
    LOCAL_STORAGE_INVITATION_NOTIFICATION_LIST,
  ),
};

const notificationSlice = createSlice({
  name: ROOT_STORE_NAME_NOTIFICATION,
  initialState: {
    ...initialNoficationState,
    [DEFAULT_KEY_NAME_REQUEST_STATUS]: STATUS_REQUEST_BASE_IDDLE,
  },
  reducers: {
    ...sliceReduceHelper.commonSliceReducers(initialNoficationState, {
      [SLICE_NAME_ALL_NOTIFICATIONS_ALL_LIST]: LOCAL_STORAGE_ALL_NOTIFICATION_LIST,
      [SLICE_NAME_INVITATION_NOTIFICATION_LIST]: LOCAL_STORAGE_INVITATION_NOTIFICATION_LIST,
    }),
  },
  extraReducers: (builder) => {
    // get unread notifications
    builder.addCase(notificationActions.getUnreadNotifications.fulfilled, (state, action) => {
      const containerUnreadNotification = action.payload[SLICE_NAME_UNREAD_NOTIFICATION_COUNT];
      state[SLICE_NAME_UNREAD_NOTIFICATION_COUNT] =
        typeof containerUnreadNotification === 'object' && !!containerUnreadNotification
          ? containerUnreadNotification[DEFAULT_KEY_NAME_UNREAD_API_REF]
          : 0;

      state[DEFAULT_KEY_NAME_REQUEST_STATUS] = REQUEST_STATUS_COUNT_UNREAD_NOTIFICATIONS_SUCCESS;
    }),
      builder.addCase(notificationActions.getUnreadNotifications.pending, (state) => {
        state[DEFAULT_KEY_NAME_REQUEST_STATUS] = REQUEST_STATUS_COUNT_UNREAD_NOTIFICATIONS_PENDING;
      }),
      builder.addCase(notificationActions.getUnreadNotifications.rejected, (state) => {
        state[SLICE_NAME_UNREAD_NOTIFICATION_COUNT] = 0;
        state[DEFAULT_KEY_NAME_REQUEST_STATUS] = REQUEST_STATUS_COUNT_UNREAD_NOTIFICATIONS_FAILED;
      });
    // list notifications
    builder.addCase(notificationActions.getNotificationList.fulfilled, (state, action) => {
      const notificationType =
        action.meta.arg[DEFAULT_KEY_NAME_NOTIFICATION_TYPE] || NOTIFICATION_ALL_TYPE;

      const { childrenStateName, requestStatusSuccess } =
        getRelationalNotificationTypeWithNotificationActionListConfiguration(notificationType);

      state[childrenStateName] = action.payload[childrenStateName];
      state[DEFAULT_KEY_NAME_REQUEST_STATUS] = requestStatusSuccess;
    }),
      builder.addCase(notificationActions.getNotificationList.pending, (state, action) => {
        const notificationType =
          action.meta.arg[DEFAULT_KEY_NAME_NOTIFICATION_TYPE] || NOTIFICATION_ALL_TYPE;

        const { requestStatusPending } =
          getRelationalNotificationTypeWithNotificationActionListConfiguration(notificationType);

        state[DEFAULT_KEY_NAME_REQUEST_STATUS] = requestStatusPending;
      }),
      builder.addCase(notificationActions.getNotificationList.rejected, (state, action) => {
        const notificationType =
          action.meta.arg[DEFAULT_KEY_NAME_NOTIFICATION_TYPE] || NOTIFICATION_ALL_TYPE;

        const { childrenStateName, requestStatusFailed } =
          getRelationalNotificationTypeWithNotificationActionListConfiguration(notificationType);

        state[childrenStateName] = null;
        state[DEFAULT_KEY_NAME_REQUEST_STATUS] = requestStatusFailed;
      });
  },
});

export const { actions, reducer } = notificationSlice;

export default reducer;
