import { blue as blueMuiColor } from '@mui/material/colors';

const blue = {
  ...blueMuiColor,
  B25: '#f5faff',
  B50: '#eff8ff',
  B100: '#d1e9ff',
  B200: '#b2ddff',
  B300: '#84caff',
  B400: '#0052cc',
  // duplicated from designer
  // B400: '#53B1FD',
  B500: '#2e90fa',
  B552: '#217fce',
  B600: '#1570ef',
  B700: '#175cd3',
  B800: '#1849a9',
  B900: '#194185',
  B943: '#0e538c',
};

export default blue;
