import { isEmpty } from 'lodash';

import { DEFAULT_SEARCH_INPUT } from '../../../components/table-head/TableHeadSearchMain/TableHeadSearchMain';
import {
  FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
  REPRESENTATION_DATA_LINK_TYPE,
} from '../../../components/table-row/TableRowPrimary/TableRowPrimary';
import { listProductCategory } from '../../../store/product/product.action';
import { clearProductCategory } from '../../../store/product/product.slice';
import { getWarehouseListWithDynamicStore } from '../../../store/warehouse/warehouse.action';
import { clearWarehouseDataSlice } from '../../../store/warehouse/warehouse.slice';
import {
  DEFAULT_CASHIER_ROLE,
  DEFAULT_USER_ROLE,
  DEFAULT_USER_ROLE_ADMINISTRATOR_CAPITALIZE,
  DEFAULT_USER_ROLE_CASHIER_CAPITALIZE,
  DEFAULT_USER_ROLE_OWNER_CAPITALIZE,
  DEFAULT_USER_ROLE_USER_CAPITALIZE,
} from '../..//default/user-roles.default';
import { REACT_APP_PRODUCT_DETAILS_TEMPALTE_URL } from '../../configs/url.config';
import {
  PRODUCT_DETAIL_PERMISSION_V2,
  PRODUCT_READ_PURCHASE_DATA_PERMISSION_V2,
} from '../../constants/permissions.constant';
import { LOCAL_STORAGE_PRODUCT_CATEGORY } from '../../constants/storage.constant';
import {
  NAME_STORE,
  SLICE_NAME_PRODUCT_CATEGORY_DATA,
  SLICE_NAME_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE,
  STATUS_REQUEST_LIST_PRODUCT_CATEGORY_PENDING,
  STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_PENDING,
} from '../../constants/store.constant';
import {
  DEFAULT_PRODUCT_CATEGORY_LIST_SEARCH_PARAMS,
  DEFAULT_SETTINGS_WAREHOUSE_SEARCH_FE_PARAMS,
} from '../../default/params.default';
import { DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE } from '../../default/payload-storage.default';
import { permissionFeatureType } from '../../default/permission.default';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';
import arrHelp from '../../helpers/array.helpers';
import strHelp from '../../helpers/string.helpers';

import {
  primaryTableDataGreyBackgroundNgreyTopBottomBorder,
  secondarySubtitleCalculationNumberParagraph,
} from './additional-styles';

export function generateLinkProductDetails(singleProductData) {
  if (isEmpty(singleProductData)) return '';

  return strHelp.templateString(REACT_APP_PRODUCT_DETAILS_TEMPALTE_URL, singleProductData);
}

// column product list that handling table head with translation
export const columnsTableHeaderProductDataList = (t, role) => {
  return [
    {
      name: 'product_code',
      name_input: 'product_code',
      label: t('table.header-column.code'),
      placeholder: t('dashboard.product.product-data.input.search-product-code.placeholder'),
      minWidth: 70,
      width: 100,
      limitWord: 15,
      // configuration for header search table
      inputName: 'product_code',
      inputType: DEFAULT_SEARCH_INPUT.commonInput,
    },
    {
      name: 'product_name',
      name_input: 'product_name',
      label: t('table.header-column.name'),
      placeholder: t('dashboard.product.product-data.input.search-product-name.placeholder'),
      width: 110,
      minWidth: 100,
      limitWord: 35,
      representationDataType: REPRESENTATION_DATA_LINK_TYPE,
      generateLink: generateLinkProductDetails,
      // configuration for header search table
      inputName: 'product_name',
      inputType: DEFAULT_SEARCH_INPUT.commonInput,
    },
    {
      name: 'category_name',
      name_input: 'category_name',
      label: t('table.header-column.category'),
      placeholder: t('dashboard.product.product-data.input.search-product-category.placeholder'),
      minWidth: 130,
      width: 100,
      limitWord: 25,
      align: 'left',
      // configuration for header search table
      columnName: 'category_name',
      inputName: 'category_name',
      inputType: DEFAULT_SEARCH_INPUT.autocompleteFetchAPIInput,
      fetchAction: listProductCategory,
      additionalParamsForAction: LOCAL_STORAGE_PRODUCT_CATEGORY,
      removeDataAction: clearProductCategory,
      additionalParamsForRemoveDataAction: LOCAL_STORAGE_PRODUCT_CATEGORY,
      keyNameFetchInput: 'category_name',
      searchKeyFromAPIData: DEFAULT_PRODUCT_CATEGORY_LIST_SEARCH_PARAMS,
      requestStatusLoading: STATUS_REQUEST_LIST_PRODUCT_CATEGORY_PENDING,
      parentStoreDataKeyName: NAME_STORE.PRODUCT,
      childrenStoreDataKeyName: SLICE_NAME_PRODUCT_CATEGORY_DATA,
      additionalParamsForScrollAction: { ...LOCAL_STORAGE_PRODUCT_CATEGORY, isRewriteAll: false },
      identity: 'category_name',
      containerName: 'category_name_container',
      containerSelectedName: 'category_name',
      selectedKeyName: 'category_name',
    },
    {
      usageMultiple2Dnames: true,
      multiple2Dnames: [
        [
          {
            name: 'quantity_real',
            representationDataProps: {
              flex: 'auto',
            },
            substituteNotExistValue: 0,
            formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          },
        ],
      ],
      name: 'warehouse_name',
      name_input: 'warehouse_id',
      label: t('table.header-column.stock'),
      placeholder: t('placeholder.select-warehouse'),
      minWidth: 130,
      width: 50,
      limitWord: 10,
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      // configuration for header search table
      inputName: 'warehouse_id',
      inputType: DEFAULT_SEARCH_INPUT.autocompleteFetchAPIInput,
      fetchAction: getWarehouseListWithDynamicStore,
      additionalParamsForAction: DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE,
      removeDataAction: clearWarehouseDataSlice,
      additionalParamsForRemoveDataAction: DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE,
      keyNameFetchInput: 'warehouse_name',
      searchKeyFromAPIData: DEFAULT_SETTINGS_WAREHOUSE_SEARCH_FE_PARAMS,
      requestStatusLoading: STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_PENDING,
      parentStoreDataKeyName: NAME_STORE.WAREHOUSE,
      childrenStoreDataKeyName: SLICE_NAME_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE,
      additionalParamsForScrollAction: {
        ...DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE,
        isRewriteAll: false,
      },
      identity: 'warehouse_id',
      containerName: 'warehouse_id_container',
      containerSelectedName: 'warehouse_id',
      selectedKeyName: 'warehouse_id',
    },
    {
      name: 'purchase_price',
      label: t('dashboard.product.product-data.table.header-column.purchase-price'),
      minWidth: 75,
      width: 100,
      limitWord: 20,
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'sales_price',
      label: t('dashboard.product.product-data.table.header-column.sales-price'),
      minWidth: 75,
      width: 100,
      limitWord: 20,
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    // make decision for show data or hide data base on role
    role === DEFAULT_USER_ROLE || role === DEFAULT_CASHIER_ROLE
      ? {
          width: 0,
          substituteNotExistValue: '',
        }
      : {
          name: 'price_average',
          label: t('table.header-column.cost-off-goods-sold'),
          minWidth: 75,
          width: 100,
          limitWord: 20,
          align: 'right',
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
        },
    // make decision for show data or hide data base on role
    role === DEFAULT_USER_ROLE || role === DEFAULT_CASHIER_ROLE
      ? {
          width: 0,
          substituteNotExistValue: '',
        }
      : {
          name: 'price_last_buy',
          label: t('table.header-column.last-purchase-price'),
          minWidth: 75,
          width: 100,
          limitWord: 20,
          align: 'right',
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
        },
  ];
};

// header column for table list product basic app
export const columnsTableHeaderProductDataListBasicApp = (t) => {
  return [
    {
      name: 'product_name',
      label: t('table.header-column.product-name'),
      width: 275,
    },
    {
      name: 'product_quantity',
      label: t('table.header-column.product-quantity'),
      width: 100,
      minWidth: 70,
      align: 'center',
    },
    {
      name: 'product_unit',
      label: t('table.header-column.product-unit'),
      width: 100,
    },
    {
      name: 'product_price',
      label: t('table.header-column.product-price'),
      width: 150,
      align: 'right',
    },
    {
      name: 'product_amount',
      label: t('table.header-column.product-amount'),
      width: 150,
      align: 'right',
    },
    {
      name: 'action',
      label: '',
      align: 'right',
      width: 25,
    },
  ];
};

// enhancement on table header product data list
export const columnsTableHeaderProductDataListV2 = (t) => {
  return [
    {
      multipleNames: [
        {
          name: 'product_name',
        },
        {
          name: 'product_description',
        },
      ],
      label: t('table.header-column.product-name'),
      isUseMultipleNames: true,
      width: 250,
    },
    {
      name: 'quantity',
      label: t('table.header-column.product-quantity'),
      align: 'left',
      width: 50,
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: !hasImplementedUnitProductUnit ? 'product_unit' : 'unit_destination',
      label: t('table.header-column.product-unit'),
      align: 'left',
      width: 25,
      minWidth: 40,
    },
    {
      name: 'price',
      label: t('table.header-column.product-price'),
      align: 'right',
      width: 75,
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'amount',
      label: t('table.header-column.amount'),
      align: 'right',
      width: 100,
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
  ];
};

// enhancement on table header product data in purchase delivery detail
export const columnsTableHeaderProductPurchaseDeliveryDataListV2 = (t) => {
  return [
    {
      multipleNames: [
        {
          name: 'product_name',
        },
        {
          name: 'description',
        },
      ],
      label: t('table.header-column.product-name'),
      isUseMultipleNames: true,
      width: 250,
    },
    {
      name: 'quantity',
      label: t('table.header-column.product-quantity'),
      align: 'left',
      width: 50,
    },
    {
      name: !hasImplementedUnitProductUnit ? 'product_unit' : 'unit_destination',
      label: t('table.header-column.product-unit'),
      align: 'left',
      width: 25,
    },
  ];
};

// header column for table list product basic app without action
export const columnsTableHeaderProductDataListBasicAppWithoutAction = (t) => {
  const currColumnTableHeader = columnsTableHeaderProductDataListBasicApp(t);

  let removeActionColumn = arrHelp.filterObjKeyWithValueExisted({
    arr: currColumnTableHeader,
    keysFilter: ['name'],
    valuesFilter: ['action'],
    isValueOnlyInclude: false,
  });

  // change on alignment product quantity and change width to 50
  return arrHelp.combineDataObjInArray(removeActionColumn, 'name', 'product_quantity', {
    align: 'right',
    width: 50,
  });
};

export const columnsTableFooterProductDataList = (t) => {
  return [
    [
      {
        isEmptyTableData: true,
        colSpan: 1,
      },
      {
        isLabel: true,
        label: t('typography.total'),
        colSpan: 2,
        typographyStyle: secondarySubtitleCalculationNumberParagraph,
        tableDataStyle: primaryTableDataGreyBackgroundNgreyTopBottomBorder,
      },
      {
        isValue: true,
        valueKeyName: 'amount',
        colSpan: 2,
        tableDataStyle: primaryTableDataGreyBackgroundNgreyTopBottomBorder,
        substituteNotExistValue: 0,
        typographyStyle: secondarySubtitleCalculationNumberParagraph,
      },
    ],
  ];
};

export const columnsPrice = ['purchase_price', 'sales_price', 'price_average', 'price_last_buy'];

export const columnsTableHeaderProductDataListForUserNcashierRole = (t) => {
  return [
    {
      name: 'product_code',
      name_input: 'product_code',
      label: t('table.header-column.code'),
      placeholder: t('dashboard.product.product-data.input.search-product-code.placeholder'),
      minWidth: 20,
      width: 50,
      limitWord: 15,
      // configuration for header search table
      inputName: 'product_code',
      inputType: DEFAULT_SEARCH_INPUT.commonInput,
    },
    {
      name: 'product_name',
      name_input: 'product_name',
      label: t('table.header-column.name'),
      placeholder: t('dashboard.product.product-data.input.search-product-name.placeholder'),
      width: 220,
      minWidth: 200,
      limitWord: 35,
      representationDataType: REPRESENTATION_DATA_LINK_TYPE,
      representationDataProps: {
        permissionProps: {
          permissionsRequired: [PRODUCT_DETAIL_PERMISSION_V2],
          permissionType: permissionFeatureType,
        },
      },
      generateLink: (valueSingleRowData) => {
        if (isEmpty(valueSingleRowData)) return '';

        return strHelp.templateString(REACT_APP_PRODUCT_DETAILS_TEMPALTE_URL, valueSingleRowData);
      },
      // configuration for header search table
      inputName: 'product_name',
      inputType: DEFAULT_SEARCH_INPUT.commonInput,
    },
    {
      name: 'category_name',
      name_input: 'category_name',
      label: t('table.header-column.category'),
      placeholder: t('dashboard.product.product-data.input.search-product-category.placeholder'),
      minWidth: 70,
      width: 100,
      limitWord: 25,
      align: 'left',
      // configuration for header search table
      columnName: 'category_name',
      inputName: 'category_name',
      inputType: DEFAULT_SEARCH_INPUT.autocompleteFetchAPIInput,
      fetchAction: listProductCategory,
      additionalParamsForAction: LOCAL_STORAGE_PRODUCT_CATEGORY,
      removeDataAction: clearProductCategory,
      additionalParamsForRemoveDataAction: LOCAL_STORAGE_PRODUCT_CATEGORY,
      keyNameFetchInput: 'category_name',
      searchKeyFromAPIData: DEFAULT_PRODUCT_CATEGORY_LIST_SEARCH_PARAMS,
      requestStatusLoading: STATUS_REQUEST_LIST_PRODUCT_CATEGORY_PENDING,
      parentStoreDataKeyName: NAME_STORE.PRODUCT,
      childrenStoreDataKeyName: SLICE_NAME_PRODUCT_CATEGORY_DATA,
      additionalParamsForScrollAction: { ...LOCAL_STORAGE_PRODUCT_CATEGORY, isRewriteAll: false },
      identity: 'category_name',
      containerName: 'category_name_container',
      containerSelectedName: 'category_name',
      selectedKeyName: 'category_name',
    },
    {
      usageMultiple2Dnames: true,
      multiple2Dnames: [
        [
          {
            name: 'quantity_real',
            representationDataProps: {
              flex: 'auto',
            },
            substituteNotExistValue: 0,
            formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
          },
        ],
      ],
      name: 'warehouse_name',
      name_input: 'warehouse_id',
      label: t('table.header-column.warehouse-stock'),
      placeholder: t('placeholder.select-warehouse'),
      minWidth: 70,
      width: 100,
      limitWord: 10,
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
      // configuration for header search table
      inputName: 'warehouse_id',
      inputType: DEFAULT_SEARCH_INPUT.autocompleteFetchAPIInput,
      fetchAction: getWarehouseListWithDynamicStore,
      additionalParamsForAction: DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE,
      removeDataAction: clearWarehouseDataSlice,
      additionalParamsForRemoveDataAction: DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE,
      keyNameFetchInput: 'warehouse_name',
      searchKeyFromAPIData: DEFAULT_SETTINGS_WAREHOUSE_SEARCH_FE_PARAMS,
      requestStatusLoading: STATUS_REQUEST_LIST_SETTINGS_WAREHOUSE_DATA_PENDING,
      parentStoreDataKeyName: NAME_STORE.WAREHOUSE,
      childrenStoreDataKeyName: SLICE_NAME_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE,
      additionalParamsForScrollAction: {
        ...DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE,
        isRewriteAll: false,
      },
      identity: 'warehouse_id',
      containerName: 'warehouse_id_container',
      containerSelectedName: 'warehouse_id',
      selectedKeyName: 'warehouse_id',
    },
    {
      name: 'sales_price',
      label: t('dashboard.product.product-data.table.header-column.sales-price'),
      minWidth: 50,
      width: 75,
      limitWord: 20,
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
  ];
};

export const columnsTableHeaderProductDataListForOwnerRole = (t) => {
  return [
    ...arrHelp.joinAtIndex({
      arr: columnsTableHeaderProductDataListForUserNcashierRole(t),
      joinIndex: 3,
      itemJoin: [
        {
          name: 'purchase_price',
          label: t('dashboard.product.product-data.table.header-column.purchase-price'),
          minWidth: 50,
          width: 75,
          limitWord: 20,
          align: 'right',
          formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
        },
      ],
    }),
    {
      name: 'price_last_buy',
      label: t('table.header-column.last-purchase-price'),
      minWidth: 50,
      width: 75,
      limitWord: 20,
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
    {
      name: 'price_average',
      label: t('table.header-column.cost-off-goods-sold'),
      minWidth: 50,
      width: 75,
      limitWord: 20,
      align: 'right',
      formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    },
  ];
};

export const relationalColumnsTableProductListWithUserRole = {
  [DEFAULT_USER_ROLE_CASHIER_CAPITALIZE]: (t) =>
    columnsTableHeaderProductDataListForUserNcashierRole(t),
  [DEFAULT_USER_ROLE_USER_CAPITALIZE]: (t) =>
    columnsTableHeaderProductDataListForUserNcashierRole(t),
  [DEFAULT_USER_ROLE_OWNER_CAPITALIZE]: (t) => columnsTableHeaderProductDataListForOwnerRole(t),
  [DEFAULT_USER_ROLE_ADMINISTRATOR_CAPITALIZE]: (t) =>
    columnsTableHeaderProductDataListForOwnerRole(t),
};

export const getColumnsTableProductRelationalWithUserRole = (
  userRole = DEFAULT_USER_ROLE_OWNER_CAPITALIZE,
) => {
  if (!Object.keys(relationalColumnsTableProductListWithUserRole).includes(userRole))
    userRole = Object.keys(relationalColumnsTableProductListWithUserRole)[0];

  return relationalColumnsTableProductListWithUserRole[userRole];
};

/**
 * get columns for table product with permissions
 * @param { function } t translation function
 * @returns
 */
export const columnsTableProductWithPermissions = [
  {
    permissionRequired: [PRODUCT_READ_PURCHASE_DATA_PERMISSION_V2],
    generateColumnsTable: (t) => columnsTableHeaderProductDataListForOwnerRole(t),
  },
  {
    permissionRequired: [],
    generateColumnsTable: (t) => columnsTableHeaderProductDataListForUserNcashierRole(t),
  },
];

export const columnsTableProductWithAction = [
  {
    name: 'product_name',
    labelTranslation: 'table.header-column.product-name',
    width: 110,
    minWidth: 100,
  },
  {
    name: 'action',
    labelTranslation: '',
    width: 10,
  },
];

export const columnsTableHeaderProductDataListV3 = [
  {
    name: 'product_code',
    labelTranslation: 'table.header-column.product-code',
    width: 40,
    minWidth: 30,
  },
  {
    name: 'product_name',
    labelTranslation: 'table.header-column.name',
    width: 200,
    minWidth: 150,
  },
  {
    name: 'product_quantity',
    labelTranslation: 'table.header-column.qty',
    align: 'right',
    width: 40,
    minWidth: 30,
    substituteNotExistValue: 0,
  },
  {
    name: 'purchase_price',
    align: 'right',
    formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    labelTranslation: 'table.header-column.purchase-price',
    width: 75,
    minWidth: 60,
  },
  {
    name: 'sales_price',
    align: 'right',
    formatData: FORMAT_CURRENCY_AUTO_COMMA_COLUMN,
    labelTranslation: 'table.header-column.sales-price',
    width: 75,
    minWidth: 60,
  },
  {
    name: 'category_name',
    labelTranslation: 'table.header-column.category',
    width: 110,
    minWidth: 100,
  },
];
