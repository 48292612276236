import {
  LOCAL_STORAGE_ACCOUNT_DATA_ALTERNATIVE,
  LOCAL_STORAGE_ACCOUNT_TRANSACTION,
  LOCAL_STORAGE_ASSET_CATEGORY,
  LOCAL_STORAGE_BILLING_INVOICE_DETAILS,
  LOCAL_STORAGE_CHART_DASHBOARD_PRO_APP_SEARCH_PARAMS,
  LOCAL_STORAGE_CONTACT_DATA,
  LOCAL_STORAGE_MANAGEMENT_USERS,
  LOCAL_STORAGE_MANAGEMENT_USERS_DETAILS,
  LOCAL_STORAGE_PRODUCT_BY_SUPPLIER,
  LOCAL_STORAGE_PRODUCT_CATEGORY,
  LOCAL_STORAGE_PRODUCT_DATA_ALTERNATIVE,
  LOCAL_STORAGE_PRODUCT_IMPORTING_PROGRESS,
  LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_LIST,
  LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_LIST_PDF_PRINT,
  LOCAL_STORAGE_PURCHASE_DELIVERY_PDF_PRINT,
  LOCAL_STORAGE_PURCHASE_INVOICE_PDF_PRINT,
  LOCAL_STORAGE_PURCHASE_ORDER_PDF_PRINT,
  LOCAL_STORAGE_PURCHASE_PAYMENT_PDF_PRINT,
  LOCAL_STORAGE_PURCHASE_RECEIVABLE_PAYMENT_LIST_PDF_PRINT,
  LOCAL_STORAGE_PURCHASE_RETURN_LIST_PDF_PRINT,
  LOCAL_STORAGE_QUOTATION_REQUEST_DETAIL,
  LOCAL_STORAGE_QUOTATION_REQUEST_PRINT_PDF,
  LOCAL_STORAGE_REPORT_BASIC_PRODUCT_PRICE_LIST,
  LOCAL_STORAGE_REPORT_BASIC_PURCHASE_LIST,
  LOCAL_STORAGE_REPORT_BASIC_SALES_EXTRACTION_LIST,
  LOCAL_STORAGE_REPORT_BASIC_SALES_LIST,
  LOCAL_STORAGE_REPORT_BASIC_STOCK_LIST,
  LOCAL_STORAGE_SALES_CREDIT_NOTE_LIST_PRINT_PDF,
  LOCAL_STORAGE_SALES_DELIVERY_PRINT_PDF,
  LOCAL_STORAGE_SALES_INVOICE_DETAIL,
  LOCAL_STORAGE_SALES_INVOICE_PRINT_PDF,
  LOCAL_STORAGE_SALES_ORDER,
  LOCAL_STORAGE_SALES_ORDER_PRINT_PDF,
  LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_LIST_PRINT_PDF,
  LOCAL_STORAGE_SALES_PAYMENT_PRINT_PDF,
  LOCAL_STORAGE_SALES_QUOTATION_DETAIL,
  LOCAL_STORAGE_SALES_QUOTATION_PRINT_PDF,
  LOCAL_STORAGE_SALES_RETURN_LIST_PRINT_PDF,
  LOCAL_STORAGE_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE,
  LOCAL_STORAGE_SETTINGS_WAREHOUSE_PRODUCT_DATA_AUTOCOMPLETE,
  LOCAL_STORAGE_STOCK_ADJUSTMENT_LIST,
  LOCAL_STORAGE_STOCK_TRANSFER_LIST,
  LOCAL_STORAGE_USER_COMPANY,
} from '../constants/storage.constant';
import {
  SLICE_NAME_ACCOUNT_TRANSACTION,
  SLICE_NAME_ASSET_CATEGORY_DATA,
  SLICE_NAME_BILLING_INVOICE_DETAILS,
  SLICE_NAME_CHART_DASHBOARD_PRO_APP_SEARCH_PARAMS,
  SLICE_NAME_COMPANY_LIST_AUTOCOMPLETE,
  SLICE_NAME_CONTACT_DATA,
  SLICE_NAME_DATA_PRODUCT_BY_SUPPLIER,
  SLICE_NAME_DATA_PRODUCT_IMPORTING_PROGRESS,
  SLICE_NAME_DATA_PRODUCT_UNIT_LIST,
  SLICE_NAME_DATA_PURCHASE_INVOICE_PDF_PRINT,
  SLICE_NAME_DETAIL_PURCHASE_INVOICE,
  SLICE_NAME_DETAIL_PURCHASE_ORDER,
  SLICE_NAME_HANDOVER_PURCHASE_DELIVERY_DETAILS,
  SLICE_NAME_HANDOVER_SALES_DELIVERY_DETAILS,
  SLICE_NAME_IMPORT_BANK_STATEMENTS_PROGRESS,
  SLICE_NAME_IMPORT_EXPENSE_PROGRESS,
  SLICE_NAME_IMPORT_JOURNAL_ENTRY_PROGRESS,
  SLICE_NAME_IMPORT_PURCHASE_INVOICE,
  SLICE_NAME_IMPORT_SALES_INVOICE_PROGRESS,
  SLICE_NAME_LIST_DETAILS_ATTACHMENT,
  SLICE_NAME_MANAGEMENT_USER_DETAILS,
  SLICE_NAME_MANAGEMENT_USERS_LIST_REGULAR,
  SLICE_NAME_PRODUCT_CATEGORY_DATA,
  SLICE_NAME_PRODUCT_DATA_ALTERNATIVE,
  SLICE_NAME_PRODUCT_MUTATION_LIST,
  SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST,
  SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST_PDF_PRINT,
  SLICE_NAME_PURCHASE_DELIVERY_DATA_PDF_PRINT,
  SLICE_NAME_PURCHASE_ORDER_DATA_PDF_PRINT,
  SLICE_NAME_PURCHASE_PAYMENT_DATA_PDF_PRINT,
  SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_LIST_PDF_PRINT,
  SLICE_NAME_PURCHASE_RETURN_LIST_PDF_PRINT,
  SLICE_NAME_QUOTATION_REQUEST_DATA_PDF_PRINT,
  SLICE_NAME_QUOTATION_REQUEST_DETAIL,
  SLICE_NAME_REPORT_BASIC_PRODUCT_PRICE_LIST,
  SLICE_NAME_REPORT_BASIC_PURCHASE_LIST,
  SLICE_NAME_REPORT_BASIC_SALES_EXTRACTION_LIST,
  SLICE_NAME_REPORT_BASIC_SALES_LIST,
  SLICE_NAME_REPORT_BASIC_STOCK_LIST,
  SLICE_NAME_SALES_CREDIT_NOTE_PRINT_PDF_LIST,
  SLICE_NAME_SALES_DELIVERY_PRINT_PDF_DATA,
  SLICE_NAME_SALES_INVOICE_DETAILS,
  SLICE_NAME_SALES_INVOICE_PRINT_PDF_DATA,
  SLICE_NAME_SALES_ORDER_DATA,
  SLICE_NAME_SALES_ORDER_DETAILS,
  SLICE_NAME_SALES_ORDER_PRINT_PDF_DATA,
  SLICE_NAME_SALES_PAYABLE_PAYMENT_PRINT_PDF_LIST,
  SLICE_NAME_SALES_PAYMENT_PRINT_PDF_DATA,
  SLICE_NAME_SALES_QUOTATION_DETAIL,
  SLICE_NAME_SALES_QUOTATION_PRINT_PDF_DATA,
  SLICE_NAME_SALES_RETURN_PRINT_PDF_LIST,
  SLICE_NAME_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE,
  SLICE_NAME_SETTINGS_WAREHOUSE_PRODUCT_DATA_AUTOCOMPLETE,
  SLICE_NAME_STOCK_ADJUSTMENT_DETAILS,
  SLICE_NAME_STOCK_ADJUSTMENT_LIST,
  SLICE_NAME_STOCK_TRANSFER_DETAILS,
  SLICE_NAME_STOCK_TRANSFER_LIST,
  SLICE_NAME_TEMP_ADD_PURCHASE_INVOICE,
  SLICE_NAME_TEMP_ADD_SALES_INVOICE,
  SLICE_NAME_TEMP_UPDATE_PURCHASE_INVOICE,
  SLICE_NAME_TEMP_UPDATE_SALES_INVOICE,
  SLICE_NAME_USER_COMPANY,
} from '../constants/store.constant';
import { sliceReduceHelper } from '../helpers/slice-reducer.helper';

import {
  accountCategoryListPrintPayloadAction,
  accountDataListPrintPayloadAction,
  assetCategoryListPrintPayloadAction,
  assetManagementListPrintPayloadAction,
  contactDataPrintPayloadAction,
  productCategoryListPrintPayloadAction,
  productDataListPrintPayloadAction,
  stockAdjustmentListPrintPayloadAction,
  stockTransferListPrintPayloadAction,
  transactionListPrintPayloadAction,
} from './payload-action.default';

// payload for list account alternative
export const DEFAULT_PAYLOAD_STORAGE_ACCOUNT_LIST_ALTERNATIVE = {
  sliceName: 'accountDataAlternative',
  localStorageName: LOCAL_STORAGE_ACCOUNT_DATA_ALTERNATIVE,
};

// payload for stock adjustment
export const DEFAULT_PAYLOAD_STORAGE_STOCK_ADJUSTMENT = {
  sliceName: SLICE_NAME_STOCK_ADJUSTMENT_LIST,
  localStorageName: LOCAL_STORAGE_STOCK_ADJUSTMENT_LIST,
};

// payload for stock adjustment details
export const DEFAULT_PAYLOAD_STORAGE_STOCK_ADJUSTMENT_DETAILS = {
  sliceName: SLICE_NAME_STOCK_ADJUSTMENT_DETAILS,
};

// payload for warehouse autocomplete
export const DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_AUTOCOMPLETE = {
  sliceName: SLICE_NAME_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE,
  localStorage: LOCAL_STORAGE_SETTINGS_WAREHOUSE_DATA_AUTOCOMPLETE,
};

// payload for list warehouse product on autocomplete
export const DEFAULT_PAYLOAD_STORAGE_WAREHOUSE_PRODUCT_AUTOCOMPLETE = {
  sliceName: SLICE_NAME_SETTINGS_WAREHOUSE_PRODUCT_DATA_AUTOCOMPLETE,
  localStorageName: LOCAL_STORAGE_SETTINGS_WAREHOUSE_PRODUCT_DATA_AUTOCOMPLETE,
};

// payload for stock transfer
export const DEFAULT_PAYLOAD_STORAGE_STOCK_TRANSFER = {
  sliceName: SLICE_NAME_STOCK_TRANSFER_LIST,
  localStorageName: LOCAL_STORAGE_STOCK_TRANSFER_LIST,
};

// payload for stock transfer details
export const DEFAULT_PAYLOAD_STORAGE_STOCK_TRANSFER_DETAILS = {
  sliceName: SLICE_NAME_STOCK_TRANSFER_DETAILS,
};

// payload for management user list
export const DEFAULT_PAYLOAD_STORAGE_MANAGEMENT_USER_LIST_REGULAR = {
  sliceName: SLICE_NAME_MANAGEMENT_USERS_LIST_REGULAR,
  localStorageName: LOCAL_STORAGE_MANAGEMENT_USERS,
};

// payload for handover purchase delivery
export const DEFAULT_PAYLOAD_STORAGE_HANDOVER_PURCHASE_DELIVERY_DETAILS = {
  sliceName: SLICE_NAME_HANDOVER_PURCHASE_DELIVERY_DETAILS,
};

// payload for handover sales delivery
export const DEFAULT_PAYLOAD_STORAGE_HANDOVER_SALES_DELIVERY_DETAILS = {
  sliceName: SLICE_NAME_HANDOVER_SALES_DELIVERY_DETAILS,
};

// payload product data alternative
export const DEFAULT_PAYLOAD_STORAGE_PRODUCT_DATA_ALTERNATIVE = {
  sliceName: SLICE_NAME_PRODUCT_DATA_ALTERNATIVE,
  localStorageName: LOCAL_STORAGE_PRODUCT_DATA_ALTERNATIVE,
};

// payload product data by supplier
export const DEFAULT_PAYLOAD_STORAGE_PRODUCT_DATA_BY_SUPPLIER = {
  sliceName: SLICE_NAME_DATA_PRODUCT_BY_SUPPLIER,
  localStorageName: LOCAL_STORAGE_PRODUCT_BY_SUPPLIER,
};

// payload for purchase invoice
export const DEFAULT_PAYLOAD_STORAGE_PURCHASE_INVOICE_DETAILS = {
  sliceName: SLICE_NAME_DETAIL_PURCHASE_INVOICE,
};

// payload on sales invoice details
export const DEFAULT_PAYLOAD_STORAGE_SALES_INVOICE_DETAILS = {
  sliceName: SLICE_NAME_SALES_INVOICE_DETAILS,
  localStorage: LOCAL_STORAGE_SALES_INVOICE_DETAIL,
};

// payload on add temp sales invoice
export const DEFAULT_PAYLOAD_STORAGE_SALES_INVOICE_TEMP_ADD = {
  sliceName: SLICE_NAME_TEMP_ADD_SALES_INVOICE,
};

// payload on update temp sales invoice
export const DEFAULT_PAYLOAD_STORAGE_SALES_INVOICE_TEMP_UPDATE = {
  sliceName: SLICE_NAME_TEMP_UPDATE_SALES_INVOICE,
};

// payload on add temp purchase invoice
export const DEFAULT_PAYLOAD_STORAGE_PURCHASE_INVOICE_TEMP_ADD = {
  sliceName: SLICE_NAME_TEMP_ADD_PURCHASE_INVOICE,
};

// payload on update temp sales invoice update
export const DEFAULT_PAYLOAD_STORAGE_PURCHASE_INVOICE_TEMP_UPDATE = {
  sliceName: SLICE_NAME_TEMP_UPDATE_PURCHASE_INVOICE,
};

// payload on storage report basic purchase list
export const DEFAULT_PAYLOAD_STORAGE_REPORT_BASIC_PURCHASE_LIST = {
  sliceName: SLICE_NAME_REPORT_BASIC_PURCHASE_LIST,
  localStorageName: LOCAL_STORAGE_REPORT_BASIC_PURCHASE_LIST,
};

// payload on storage report basic sales list
export const DEFAULT_PAYLOAD_STORAGE_REPORT_BASIC_SALES_LIST = {
  sliceName: SLICE_NAME_REPORT_BASIC_SALES_LIST,
  localStorageName: LOCAL_STORAGE_REPORT_BASIC_SALES_LIST,
};

// payload on storage report basic stock product list
export const DEFAULT_PAYLOAD_STORAGE_REPORT_BASIC_STOCK_PRODUCT_LIST = {
  sliceName: SLICE_NAME_REPORT_BASIC_STOCK_LIST,
  localStorageName: LOCAL_STORAGE_REPORT_BASIC_STOCK_LIST,
};

// payload on storage report basic product price list
export const DEFAULT_PAYLOAD_STORAGE_REPORT_BASIC_PRODUCT_PRICE_LIST = {
  sliceName: SLICE_NAME_REPORT_BASIC_PRODUCT_PRICE_LIST,
  localStorageName: LOCAL_STORAGE_REPORT_BASIC_PRODUCT_PRICE_LIST,
};

// payload on storage report basic sales extraction list
export const DEFAULT_PAYLOAD_STORAGE_REPORT_BASIC_SALES_EXTRACTION_LIST = {
  sliceName: SLICE_NAME_REPORT_BASIC_SALES_EXTRACTION_LIST,
  localStorageName: LOCAL_STORAGE_REPORT_BASIC_SALES_EXTRACTION_LIST,
};

// payload product category data list
export const DEFAULT_PAYLOAD_STORAGE_PRODUCT_CATEGORY_DATA_LIST = {
  sliceName: SLICE_NAME_PRODUCT_CATEGORY_DATA,
  localStorageName: LOCAL_STORAGE_PRODUCT_CATEGORY,
};

// payload sales quotation detail
export const DEFAULT_PAYLOAD_STORAGE_SALES_QUOTATION_DETAIL = {
  sliceName: SLICE_NAME_SALES_QUOTATION_DETAIL,
  localStorageName: LOCAL_STORAGE_SALES_QUOTATION_DETAIL,
};

// payload quotation request detail
export const DEFAULT_PAYLOAD_STORAGE_QUOTATION_REQUEST_DETAIL = {
  sliceName: SLICE_NAME_QUOTATION_REQUEST_DETAIL,
  localStorageName: LOCAL_STORAGE_QUOTATION_REQUEST_DETAIL,
};

// payload purchase order detail
export const DEFAULT_PAYLOAD_STORAGE_PURCHASE_ORDER_DETAIL = {
  sliceName: SLICE_NAME_DETAIL_PURCHASE_ORDER,
  localStorageName: '',
};

// payload on list sales order
export const DEFAULT_PAYLOAD_STORAGE_LIST_SALES_ORDER = {
  sliceName: SLICE_NAME_SALES_ORDER_DATA,
  localStorage: LOCAL_STORAGE_SALES_ORDER,
};

// payload on sales order details
export const DEFAULT_PAYLOAD_STORAGE_SALES_ORDER_DETAILS = {
  sliceName: SLICE_NAME_SALES_ORDER_DETAILS,
};

// payload for account transaction list
export const DEFAULT_PAYLOAD_STORAGE_ACCOUNT_TRANSACTION_LIST = {
  sliceName: SLICE_NAME_ACCOUNT_TRANSACTION,
  localStorageName: LOCAL_STORAGE_ACCOUNT_TRANSACTION,
};

// payload for contact autocomplete
export const DEFAULT_PAYLOAD_STORAGE_CONTACT_AUTOCOMPLETE = {
  sliceName: SLICE_NAME_CONTACT_DATA,
  localStorage: LOCAL_STORAGE_CONTACT_DATA,
};

// payload for chart dashboard pro search params
export const DEFAULT_PAYLOAD_STORAGE_CHART_DASHBOARD_PROFESSIONAL_SEARCH_PARAMS = {
  sliceName: SLICE_NAME_CHART_DASHBOARD_PRO_APP_SEARCH_PARAMS,
  localStorageName: LOCAL_STORAGE_CHART_DASHBOARD_PRO_APP_SEARCH_PARAMS,
};

// user company autocomplete liser
export const DEFAULT_PAYLOAD_STORAGE_USER_COMPANY_LIST_AUTOCOMPLETE = {
  sliceName: SLICE_NAME_COMPANY_LIST_AUTOCOMPLETE,
};

// management users details
export const DEFAULT_PAYLOAD_STORAGE_MANAGEMENT_USERS_DETAILS = {
  sliceName: SLICE_NAME_MANAGEMENT_USER_DETAILS,
  localStorageName: LOCAL_STORAGE_MANAGEMENT_USERS_DETAILS,
};

// list details attachment
export const DEFAULT_PAYLOAD_STORAGE_ATTACHMENT_LIST_DETAILS = {
  sliceName: SLICE_NAME_LIST_DETAILS_ATTACHMENT,
};

// product unit list
export const DEFAULT_PAYLOAD_STORAGE_PRODUCT_UNIT_LIST = {
  sliceName: SLICE_NAME_DATA_PRODUCT_UNIT_LIST,
};

export const DEFAULT_PAYLOAD_STORAGE_PURCHASE_DEBIT_NOTE_LIST = {
  sliceName: SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST,
  localStorageName: LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_LIST,
};

// asset category
export const DEFAULT_PAYLOAD_STORAGE_ASSET_CATEGORY_LIST = {
  sliceName: SLICE_NAME_ASSET_CATEGORY_DATA,
  localStorageName: LOCAL_STORAGE_ASSET_CATEGORY,
};

// billing invoice details
export const DEFAULT_PAYLOAD_BILLING_INVOICE_DETAILS = {
  sliceName: SLICE_NAME_BILLING_INVOICE_DETAILS,
  localStorageName: LOCAL_STORAGE_BILLING_INVOICE_DETAILS,
};

// product mutation list
export const DEFAULT_PAYLOAD_PRODUCT_MUTATION_LIST = {
  sliceName: SLICE_NAME_PRODUCT_MUTATION_LIST,
};

// purchase quotation
export const DEFAULT_PAYLOAD_STORAGE_PURCHASE_QUOTATION_REQUEST_LIST_PRINT_PDF = {
  sliceName: SLICE_NAME_QUOTATION_REQUEST_DATA_PDF_PRINT,
  localStorageName: LOCAL_STORAGE_QUOTATION_REQUEST_PRINT_PDF,
};

// purchase order
export const DEFAULT_PAYLOAD_STORAGE_PURCHASE_ORDER_LIST_PRINT_PDF = {
  sliceName: SLICE_NAME_PURCHASE_ORDER_DATA_PDF_PRINT,
  localStorageName: LOCAL_STORAGE_PURCHASE_ORDER_PDF_PRINT,
};

// purchase delivery
export const DEFAULT_PAYLOAD_STORAGE_PURCHASE_DELIVERY_LIST_PRINT_PDF = {
  sliceName: SLICE_NAME_PURCHASE_DELIVERY_DATA_PDF_PRINT,
  localStorageName: LOCAL_STORAGE_PURCHASE_DELIVERY_PDF_PRINT,
};

// purchase invoice
export const DEFAULT_PAYLOAD_STORAGE_PURCHASE_INVOICE_LIST_PRINT_PDF = {
  sliceName: SLICE_NAME_DATA_PURCHASE_INVOICE_PDF_PRINT,
  localStorageName: LOCAL_STORAGE_PURCHASE_INVOICE_PDF_PRINT,
};

// purchase payment
export const DEFAULT_PAYLOAD_STORAGE_PURCHASE_PAYMENT_LIST_PRINT_PDF = {
  sliceName: SLICE_NAME_PURCHASE_PAYMENT_DATA_PDF_PRINT,
  localStorageName: LOCAL_STORAGE_PURCHASE_PAYMENT_PDF_PRINT,
};

// purchase return
export const DEFAULT_PAYLOAD_STORAGE_PURCHASE_RETURN_LIST_PRINT_PDF = {
  sliceName: SLICE_NAME_PURCHASE_RETURN_LIST_PDF_PRINT,
  localStorageName: LOCAL_STORAGE_PURCHASE_RETURN_LIST_PDF_PRINT,
};

// purchase debit note
export const DEFAULT_PAYLOAD_STORAGE_PURCHASE_DEBIT_NOTE_LIST_PRINT_PDF = {
  sliceName: SLICE_NAME_PURCHASE_DEBIT_NOTE_LIST_PDF_PRINT,
  localStorageName: LOCAL_STORAGE_PURCHASE_DEBIT_NOTE_LIST_PDF_PRINT,
};

// purchase receivable payment
export const DEFAULT_PAYLOAD_STORAGE_PURCHASE_RECEIVABLE_PAYMENT_LIST_PRINT_PDF = {
  sliceName: SLICE_NAME_PURCHASE_RECEIVABLE_PAYMENT_LIST_PDF_PRINT,
  localStorageName: LOCAL_STORAGE_PURCHASE_RECEIVABLE_PAYMENT_LIST_PDF_PRINT,
};

// sales quotation
export const DEFAULT_PAYLOAD_STORAGE_SALES_QUOTATION_LIST_PRINT_PDF = {
  sliceName: SLICE_NAME_SALES_QUOTATION_PRINT_PDF_DATA,
  localStorageName: LOCAL_STORAGE_SALES_QUOTATION_PRINT_PDF,
};

// sales order
export const DEFAULT_PAYLOAD_STORAGE_SALES_ORDER_LIST_PRINT_PDF = {
  sliceName: SLICE_NAME_SALES_ORDER_PRINT_PDF_DATA,
  localStorageName: LOCAL_STORAGE_SALES_ORDER_PRINT_PDF,
};

// sales delivery
export const DEFAULT_PAYLOAD_STORAGE_SALES_DELIVERY_LIST_PRINT_PDF = {
  sliceName: SLICE_NAME_SALES_DELIVERY_PRINT_PDF_DATA,
  localStorageName: LOCAL_STORAGE_SALES_DELIVERY_PRINT_PDF,
};

// sales invoice
export const DEFAULT_PAYLOAD_STORAGE_SALES_INVOICE_LIST_PRINT_PDF = {
  sliceName: SLICE_NAME_SALES_INVOICE_PRINT_PDF_DATA,
  localStorageName: LOCAL_STORAGE_SALES_INVOICE_PRINT_PDF,
};

// sales payment
export const DEFAULT_PAYLOAD_STORAGE_SALES_PAYMENT_LIST_PRINT_PDF = {
  sliceName: SLICE_NAME_SALES_PAYMENT_PRINT_PDF_DATA,
  localStorageName: LOCAL_STORAGE_SALES_PAYMENT_PRINT_PDF,
};

// sales return
export const DEFAULT_PAYLOAD_STORAGE_SALES_RETURN_LIST_PRINT_PDF = {
  sliceName: SLICE_NAME_SALES_RETURN_PRINT_PDF_LIST,
  localStorageName: LOCAL_STORAGE_SALES_RETURN_LIST_PRINT_PDF,
};

// sales credit note
export const DEFAULT_PAYLOAD_STORAGE_SALES_CREDIT_NOTE_LIST_PRINT_PDF = {
  sliceName: SLICE_NAME_SALES_CREDIT_NOTE_PRINT_PDF_LIST,
  localStorageName: LOCAL_STORAGE_SALES_CREDIT_NOTE_LIST_PRINT_PDF,
};

// sales payable payment
export const DEFAULT_PAYLOAD_STORAGE_SALES_PAYABLE_PAYMENT_LIST_PRINT_PDF = {
  sliceName: SLICE_NAME_SALES_PAYABLE_PAYMENT_PRINT_PDF_LIST,
  localStorageName: LOCAL_STORAGE_SALES_PAYABLE_PAYMENT_LIST_PRINT_PDF,
};

// contact list print
export const payloadStorageContactListPrint = sliceReduceHelper.linkedPayloadAction2PayloadStorage(
  contactDataPrintPayloadAction,
);

// product data print
export const payloadStorageProductDataListPrint =
  sliceReduceHelper.linkedPayloadAction2PayloadStorage(productDataListPrintPayloadAction);

// product category print
export const payloadStorageProductCategoryListPrint =
  sliceReduceHelper.linkedPayloadAction2PayloadStorage(productCategoryListPrintPayloadAction);

// account data print
export const payloadStorageAccountDataListPrint =
  sliceReduceHelper.linkedPayloadAction2PayloadStorage(accountDataListPrintPayloadAction);

// account category print
export const payloadStorageAccountCategoryListPrint =
  sliceReduceHelper.linkedPayloadAction2PayloadStorage(accountCategoryListPrintPayloadAction);

// asset management print
export const payloadStorageAssetManagementListPrint =
  sliceReduceHelper.linkedPayloadAction2PayloadStorage(assetManagementListPrintPayloadAction);

// asset category print
export const payloadStorageAssetCategoryListPrint =
  sliceReduceHelper.linkedPayloadAction2PayloadStorage(assetCategoryListPrintPayloadAction);

export const payloadStorageStockAdjustmentListPrint =
  sliceReduceHelper.linkedPayloadAction2PayloadStorage(stockAdjustmentListPrintPayloadAction);

// stock transfer list print
export const payloadStorageStockTransferListPrint =
  sliceReduceHelper.linkedPayloadAction2PayloadStorage(stockTransferListPrintPayloadAction);

// transaction list print
export const payloadStorageTransactionListPrint =
  sliceReduceHelper.linkedPayloadAction2PayloadStorage(transactionListPrintPayloadAction);

// user company list
export const payloadStorageUserCompanyList = sliceReduceHelper.generatePayloadStorage(
  SLICE_NAME_USER_COMPANY,
  LOCAL_STORAGE_USER_COMPANY,
);

// product importing progress
export const PAYLOAD_STORAGE_DATA_PRODUCT_IMPORTING_PROGRESS =
  sliceReduceHelper.generatePayloadStorage(
    SLICE_NAME_DATA_PRODUCT_IMPORTING_PROGRESS,
    LOCAL_STORAGE_PRODUCT_IMPORTING_PROGRESS,
  );

// purchase invoice import progress
export const PAYLOAD_STORAGE_PURCHASE_INVOICE_IMPORT_PROGRESS =
  sliceReduceHelper.generatePayloadStorage(SLICE_NAME_IMPORT_PURCHASE_INVOICE);

// sales invoice import progress
export const PAYLOAD_STORAGE_SALES_INVOICE_IMPORT_PROGRESS =
  sliceReduceHelper.generatePayloadStorage(SLICE_NAME_IMPORT_SALES_INVOICE_PROGRESS);

// expense import progress
export const PAYLOAD_STORAGE_EXPENSE_IMPORT_PROGRESS = sliceReduceHelper.generatePayloadStorage(
  SLICE_NAME_IMPORT_EXPENSE_PROGRESS,
);

// journal entry import progress
export const PAYLOAD_STORAGE_JOURNAL_ENTRY_IMPORT_PROGRESS =
  sliceReduceHelper.generatePayloadStorage(SLICE_NAME_IMPORT_JOURNAL_ENTRY_PROGRESS);

// bank statements import progress
export const PAYLOAD_STORAGE_DATA_BANK_STATEMENTS_IMPORT_PROGRESS =
  sliceReduceHelper.generatePayloadStorage(SLICE_NAME_IMPORT_BANK_STATEMENTS_PROGRESS);
