import * as React from 'react';
function SvgClosingBookSettingMenu(props) {
  return (
    <svg width={69} height={76} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#closing-book-setting-menu_svg__filter0_d_5586_193684)'>
        <path
          d='M3.33 10.852h4.06V69H3.33c-.997 0-1.812-.663-1.812-1.475V12.33c0-.811.815-1.475 1.812-1.475v-.002z'
          fill='#4D4D4D'
        />
        <path d='M61.982 10.852H7.087V69h54.895V10.852z' fill='#E84F4F' />
        <path d='M7.088 10.851l52.941-2.666v58.147L7.088 69V10.851z' fill='#CCC' />
        <path d='M7.088 10.851l49.685-5.245v58.148L7.088 68.999V10.851z' fill='#E6E6E6' />
        <path d='M7.088 10.851l46.168-7.817v58.148L7.088 69V10.851z' fill='#F8F8F8' />
        <path d='M7.088 10.851L49.31 1v58.148L7.088 68.999V10.851z' fill='#E84F4F' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M49.31 1L28.199 5.926v58.147l21.11-4.925V1z'
          fill='#E04242'
        />
        <path
          d='M28.643 17.765c8.824-.879 15.976 5.66 15.976 14.607 0 8.946-7.152 16.912-15.976 17.79-8.823.88-15.976-5.66-15.976-14.606s7.153-16.911 15.976-17.79z'
          fill='#CF4444'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M44.62 32.372c0-8.946-7.153-15.486-15.977-14.607-.164.017-.327.035-.49.057v32.382c.163-.011.326-.025.49-.041 8.824-.88 15.976-8.845 15.976-17.79z'
          fill='#CA3E3E'
        />
      </g>
      <defs>
        <filter
          id='closing-book-setting-menu_svg__filter0_d_5586_193684'
          x={0.518}
          y={0}
          width={68.465}
          height={76}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={3} dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5586_193684' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_5586_193684' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgClosingBookSettingMenu;
