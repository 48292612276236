export const styles = (theme) => {
  return {
    outlinedInputDateRangePicker: {
      paddingRight: '0px',
      backgroundColor: 'white',
      '&.Mui-focused #clearButtonDateRangePicker, &:hover #clearButtonDateRangePicker': {
        visibility: 'visible',
        right: '-24px',
        zIndex: 7,
        background: 'white',
        padding: '3px',
      },
      '&.Mui-focused #eventButtonDateRangePicker, &:hover #eventButtonDateRangePicker': {
        paddingLeft: '0px',
      },
      '& .MuiInputAdornment-root': {
        marginLeft: '-24px',
      },
    },
    clearButton: {
      padding: 0,
      visibility: 'hidden',
    },
    definedRange: {
      '&.rdrDefinedRangesWrapper': {
        width: 'auto',
        maxWidth: '338px',
        [theme.breakpoints.up('sm')]: {
          width: '160px',
          padding: '12px 16px',
        },
      },
    },
    definedRangeMobile: {
      '&.rdrDefinedRangesWrapper': {
        width: 'auto',
        maxWidth: '338px',
        padding: '12px 16px 0px',
        [theme.breakpoints.up('sm')]: {
          width: '160px',
          padding: '12px 16px',
        },
      },
      '& .rdrStaticRanges': {
        overflowX: 'auto',
        flexDirection: 'row',
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'column',
        },
      },
      '& span.rdrStaticRangeLabel': {
        width: 'max-content',
        [theme.breakpoints.up('sm')]: {
          width: 'auto',
        },
      },
    },
    dateRange: {
      '& .rdrDateDisplayWrapper': {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'block',
        },
      },
      '& .rdrMonthAndYearWrapper': {
        height: '50px',
        paddingTop: '0px',
        width: 'auto',
        overflowX: 'auto',
        [theme.breakpoints.up('sm')]: {
          paddingTop: '10px',
          height: '60px',
          width: '351.97px',
        },
      },
      '& .rdrNextPrevButton': {
        minWidth: '25px',
      },
      '& .rdrWeekDays,.rdrDays': {
        width: '332px',
        '@media (min-width: 333px)': {
          width: 'auto',
        },
      },
      '& .rdrMonth': {
        width: 'auto',
        overflow: 'auto',
        flexDirection: 'row',
        [theme.breakpoints.up('sm')]: {
          width: '27.667em',
        },
      },
    },
  };
};
